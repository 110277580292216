import { useTranslation } from 'react-i18next'
import { Container } from 'src/components/layouts'
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { Content } from 'src/components/layouts'
import COLORS from 'src/components/legacy/common/colors.json'
import ContactInfo from '../ContactInfo'
import {
  AssumptionWrapper,
  ContentWrapper,
  DivWrapper,
  ErrorText,
  HeaderToggleWrapper,
  OrganizationWrap,
  SelectWrapper,
  ToggleButtonWrapper,
  ToolbarWrapper
} from '../styles'
import Select from 'src/components/legacy/components/select/select'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import moment from 'moment/moment'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import _get from 'lodash/get'
import { MAX_LENGTH_OF_NAME, getStatusOptionList } from '../helper'
import {
  getConsultationTestName
} from './helperFunction'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ContactDivWrap } from '../styles'
import TextInput from 'src/components/legacy/components/modal-text-input'
import _keys from "lodash/keys";
import Selectrix from 'src/denali-ui/components/Selectrix'
import { SEARCH_AGGREGATE, SEARCH_BUILDINGS } from 'src/pages/buildingSetup/graphql/queries'
import { NewStyles } from 'src/NewStyles'
import { accessControlFunc } from 'src/components/accessControl'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import { useQuery } from 'src/hooks/APIHooks'
import {GET_BUILDINGDETAILS_BY_ID_func  } from "../graphql";


const ConsultationHeadSection = (props) => {
  const {
    showAssumptionBtn = false,
    values,
    setFieldValue,
    buildingsList,
    errors,
    handleFormSubmit,
    assumptionValues,
    preCheckShowAssumptions,
    isSubmit,
    organizationId
  } = props || {}
  const [t] = useTranslation()
  const navigate = useNavigate()
  const { width } = useBreakPoint()
  const [buildings, setBuildings] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [locationId, setLocationId] = useState(location)
  const userAccess = useSelector(selectUserAccess)
  const [offeringDDLData, setOfferingDDLData] = useState([])
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const {refetch:refetchBuildingsByIds} = useQuery({
    query:null
  })

  useEffect(() => {
    if (Array.isArray(buildingsList)) {
      const list = buildingsList?.map((building) => ({
        key: building?.key,
        value: building?.value,
        searchableValue: building?.value
      }))
      setBuildings(list)
      if (locationId) {
        const building = buildingsList?.find(
          (building) => building?.key === locationId
        )
        onBuildingChangeSelect(building || '')
      }
    }
  }, [buildingsList, locationId])

  useEffect(() => {
    setIsValid(_keys(props?.errors)?.length)
  }, [props])


  useEffect(() => {
    // Set the date values if it is not set
    // We have to use this form values in the API input variables
    if (values === null)
      return
    if (!values?.rangeFrom) {
      setFieldValue(
        'rangeFrom',
        moment(moment().format(DATE_FORMAT)).format(DATE_FORMAT)
      )
    }
    if (!values?.rangeTo) {
      setFieldValue(
        'rangeTo',
        moment(moment().format(DATE_FORMAT)).format(DATE_FORMAT)
      )
    }
    if (!values?.status) {
      setFieldValue('status', getStatusOptionList(t)[0]?.value)
    }

    if (values?.building?.length > 0 && isInitialLoad) {
      refetchBuildingsByIds(null, null, GET_BUILDINGDETAILS_BY_ID_func(values?.building)).then(res => {
        if (res?.data && !res.data.errors) {
          const buildings = Object.values(res.data)


          setOfferingDDLData(state =>
            [...state,
            ...buildings.reduce((acc: [any], b: any): [any] => {
              const data = {
                key: b.id,
                label: b.name,
                address: b.address
              }
              if (!(state.some(building => building.key === b.id))) {
                acc.push(data)
              }
              return acc;
            }
              , []) as any[]
            ]
          )


        }
      })
      setIsInitialLoad(false)
    }

  }, [values])

  useEffect(() => {
    setIsSubmitting(isSubmit)
  }, [isSubmit])


  const onBuildingChangeSelect = ({ key = '', value = '' } = {}) => {
    if (key) {
      setFieldValue('location', {
        locationName: value,
        locationId: key
      })
    }
  }

  const onOrganizationChange = (e, child) => {
    if (e.target.value !== values.organization) {
      setFieldValue("organization", e.target.value)
      setFieldValue("organizationName", child.props.children)
      setFieldValue("building", [])
    }
  }

  const getOrgsQueryResponseMap = (response, organizationId) => {
    const idListIndex = response.findIndex(e => e.name === "accountIds")
    const nameTermsIndex = response.findIndex(e => e.name === "accountTerms")
    const results = response && idListIndex !== -1 && nameTermsIndex !== -1 && response[nameTermsIndex].result.buckets.map((e, i) =>  ({
        searchableValue: e.key,
        value: response[idListIndex].result.buckets[i].key,
        children: e.key,
        selected: response[idListIndex].result.buckets[i].key === organizationId
      })
    )

    return results.sort((a, b) => {
      const nameA = a.searchableValue.toUpperCase()
      const nameB = b.searchableValue.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }      
      return 0
    })
  }

  const pageTitle = () => {
    return (
      <HeaderToggleWrapper>
        <ToggleButtonHorizontal
          name="visibleToCustomer"
          checked={values?.visibility}
          onChange={(e) => setFieldValue('visibility', e?.target?.checked)}
          testId={getConsultationTestName('visibility')}
        />
        <label>{"Externally Visible"}</label>
      </HeaderToggleWrapper>
    )
  }

  const toolbar = () => (
    <ToolbarWrapper className="toolbar-buttons">
      <button 
        className={`primary ${isSubmitting ? 'wait' : ''}`} 
        disabled={isValid}
        data-testid={getConsultationTestName('save-button')} 
        onClick={handleFormSubmit}>
        {isSubmitting ? <div className="spinner" /> : t('common:Save')}
      </button>
      <button
        className="secondary"
        onClick={() => {
          navigate('/reports')
        }}
        data-testid={getConsultationTestName('cancel-button')}
      >
        {t('common:Cancel')}
      </button>
    </ToolbarWrapper>
  )

  const onChangeDate = (path) => (date) => {
    date = date.target
      ? date.target.value
      : moment(date).format(DATE_FORMAT)
    setFieldValue(path, date)
  }

  const onBuildinghangeSelect = () => {
    return (e) => {
      const newBuildingIds = e.target.value
      setFieldValue('building', newBuildingIds ?? [])
    }
  }
  const getBuildingsQueryResponseMap = (values) => (response) => {

    setOfferingDDLData(state =>
      [...state,
      ...response.reduce((acc, b) => {
        const data = {
          key: b.id,
          label: b.name,
          address: b.address
        }
        if (!(state.some(building => building.key === b.id))) {
          acc.push(data)
        }
        return acc;
      }
        , [])
      ]
    )
    return response && Array.isArray(response) ?  response.map((e, i) =>  ({
      searchableValue: e.name,
      value: e.id,
      children: e.name,
      selected: values?.building.includes(e.id)
    })
  ) : []
  }
  return (
    <NewStyles>
    <Container
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      width={width}
    >
      <ToggleButtonWrapper>
        {accessControlFunc({
            id: 'tc.pages.documents-and-reports.show-public-toggle',
            userAccess
          }) && pageTitle()}
        {toolbar()}
      </ToggleButtonWrapper>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        background={COLORS.WHITE}
      >
        <ContentWrapper>
          <div>
            <div style={{width:'100%'}} data-testid={getConsultationTestName('name')} >
              <TextInput
                id={"consult-name"}
                labelText={`${'Consultation Title'}`}
                defaultValue={values?.consultationTitle}
                placeholder="Consultation"
                name="consultationTitle"
                onChange={({
                  target: { value, name }
                }: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(name, value)
                }}
                hasError={errors?.['consultationTitle']}
                isRequired={true}
                errorMessage={errors?.['consultationTitle']}
                maxLength={MAX_LENGTH_OF_NAME}
              />
            </div>
              <OrganizationWrap building-width={values?.building?.length <= 2 ? "300px" : values?.building?.length > 5 ? "600px" : `${values?.building?.length * 100}px`}>
                <SelectWrapper width={width} className="org-dropdown">
                  <label className={`${errors?.organization ? 'error-text' : ''}`}>{`${t('common:Organization')}*`}</label>
                  <Selectrix
                    onChange={(value, child) => onOrganizationChange(value, child)}
                    query={SEARCH_AGGREGATE}
                    fullWidth={true}
                    multiple={false}
                    value={values?.organizationName}
                    dataPath={"data.searchBuildings.aggregateItems"}
                    containerWidth={true}
                    getQueryResponseMap={values => getOrgsQueryResponseMap(values, values.organization)}
                    selectClassName={'orgSelectInput'}
                    searchPlaceHolder="Type to find an organization..."
                    testName={`consultations_organizationdetails_addorganization`}
                    querySearchField={['accountName']}
                    aggregates={[{ field: "accountName", type: "terms", name: "accountTerms" }, { field: "accountId", type: "terms", name: "accountIds" }]}
                    showCutomOption={true}
                    showNoResultsMessage={false}
                    disabled={true}
                  />

                  {errors?.organization && <ErrorText>
                    {errors?.organization}
                  </ErrorText>}
                </SelectWrapper>
                <div className='building-dropdown'>
                  <label className={`${errors?.building ? 'error-text' : ''}`}>{`${t('opportunities:Building')}*`}</label>
                  {values?.building?.length > 0 && <>
                    <SelectrixWrapper
                      className={'location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select hideArrow'}
                      placeholder={""}
                      placeHolderInside={true}
                      defaultValue={values?.building}
                      inputPlaceholder="Find a building..."
                      multiple={true}
                      name="buildingIds"
                      onChange={e => {
                        const newBuildingIds = e.map(e => e.key)
                        setFieldValue('building', newBuildingIds ?? [])
                      }
                      }
                      options={offeringDDLData}
                      searchable={true}
                      searchBoxInside={true}
                      searchIndex={false}
                      stayOpen={true}
                      testName={getConsultationTestName('building')}
                    />
                  </>}
                  <Selectrix
                    allowCheckAll={false}
                    onChange={onBuildinghangeSelect()}
                    value={values?.building}
                    query={SEARCH_BUILDINGS}
                    graphQLInputs={{ filter: { accountId: { eq: organizationId } }, sort: { field: "name", direction: "asc" } }}
                    fullWidth={true}
                    multiple={true}
                    containerWidth={true}
                    getQueryResponseMap={getBuildingsQueryResponseMap(values)}
                    selectClassName={'orgSelectInput'}
                    placeholder={"-- Select One or More --"}
                    searchPlaceHolder="Type to find a Building..."
                    showNoResultsMessage={false}
                    testName={getConsultationTestName('building')}
                  />
                  {errors?.building && <ErrorText>
                    {errors?.building}
                  </ErrorText>}
                </div>
              </OrganizationWrap>
            <DivWrapper>
              <div data-testid={getConsultationTestName('start-date')}>
                <label>{'Start Date'}:*</label>
                <ModalDatePickerInput
                  date={
                    values?.rangeFrom
                      ? values?.rangeFrom
                      : ''
                  }
                  onChange={
                    onChangeDate('rangeFrom')
                  }
                  isRequired={true}
                  hasError={Boolean(
                    errors?.rangeFrom
                  )}
                  errorMessage={errors?.rangeFrom}
                />
              </div>
              <div>
                <span className="date-picker-separator">to</span>
              </div>
              <div data-testid={getConsultationTestName('end-date')}>
                <label>{'End Date'}:*</label>
                <ModalDatePickerInput
                  date={
                    values?.rangeTo
                      ? values?.rangeTo
                      : ''
                  }
                  onChange={
                    onChangeDate('rangeTo')
                  }
                  isRequired={true}
                  hasError={Boolean(
                    errors?.rangeTo
                  )}
                  errorMessage={errors?.rangeTo}
                />
              </div>
            </DivWrapper>
            <SelectWrapper width={width}>
              <label>{'Status'}</label>
              <Select
                selectedItem={getStatusOptionList(t)?.find((option)=>option.key===values?.status)?.value||values?.status}
                placeholder={t('common:SelectOne')}
                className="dropdown-select"
                options={getStatusOptionList(t)}
                onChange={(event) => {                          
                  setFieldValue('status', event)
                }}
                testName={getConsultationTestName('status')}
              />
            </SelectWrapper>
          </div>
          <ContactDivWrap>
            <ContactInfo 
              values={values} 
              errors={errors}
              testName={getConsultationTestName('contanct-info-')}
              setFieldValue={setFieldValue} />
          </ContactDivWrap>
        </ContentWrapper>
        {showAssumptionBtn && (
          <AssumptionWrapper>
            <span>Add Assumption Values for below graphs</span>
            <button 
              disabled={!values?.building} 
              onClick={() => preCheckShowAssumptions(true)}
              data-testid={getConsultationTestName('assumption')}
              className="secondary">
              {assumptionValues !== null ? 'Edit/View Assumption Values' :  'Add Assumption Values'}</button>
          </AssumptionWrapper>
        )}
      </Content>
    </Container>
    </NewStyles>
  )
}

export default ConsultationHeadSection
