import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TINY, EXTRA_SMALL } from 'src/hooks/breakPoint'
import Dropdown from 'src/components/legacy/components/dropdown'

class TopNavigation extends PureComponent {
  constructor(props) {
    super(props)
  }

  static propTypes = {
    size: PropTypes.string,
    customClassNames: PropTypes.string
  }

  render() {
    const isSmallScreen = [TINY, EXTRA_SMALL].includes(this.props.size)
    const styleNames = isSmallScreen ? 'nav-dropdown small' : 'nav-dropdown'

    return <Dropdown.Box {...this.props} styleNames={styleNames} />
  }
}

export default TopNavigation
