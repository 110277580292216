export const SEARCH_NOTIFICATIONS_ACTIVE = `
  query USER_NOTIFICATIONS (
    $userId: ID!
    $nextToken:String
    $filter:ModelNotificationActiveFilterInput
    ) {
      listNotificationActiveByUser(userId: $userId,filter:$filter,nextToken:$nextToken, isActiveNtypeAppCreatedAt: {beginsWith: {app: "tc", isActive: 1, ntype: "push"}}, limit: 50,sortDirection: DESC) {
        items {
          accountId
          createdAt
          buildingId
          deviceId
          id
          nBody
          nSubType
          ntype
          app
          salesOfficeId
          updatedAt
          userId
          status
        }
        nextToken
      }
      
}`

export const UPDATE_NOTIFICATIONS_ACTIVE = `
mutation updateNotificationActive($input: UpdateNotificationActiveInput!) {
  updateNotificationActive(input: $input){
    id
  }
}`

export const CLEAR_ALL_NOTIFICATIONS = /* GraphQL */ `
  mutation clearNotification($input: AWSJSON!) {
    clearNotification(body: $input)
  }
`

export const DELETE_NOTIFICATIONS_ACTIVE_func = (id, ntype) => `
mutation deleteNotificationActive {
  deleteNotificationActive(input: {id: "${id}",ntype: "${ntype}"}){
    id
  }
}`

export const UPDATE_NOTIFICATIONS_ACTIVE_func = ({
  id,
  isActive = 1,
  ntype,
  app,
  notificationStatus,
  createdAt
}) => `
mutation updateNotificationActive {
  updateNotificationActive(input: {id: "${id}",ntype: "${ntype}",app: "${app}",isActive: ${isActive},createdAt: "${createdAt}", status: "${notificationStatus}" }){
    id
  }
}`

export const GET_BUILDING_BY_ID_func = (ids) => `
query getBuildingByIds{
  ${ids.map(
    (id, key) => `b${key}:getBuilding(id: "${id}") {
    name
    id
  }`
  )}
}`

export const GET_USER_BY_ID_func = (ids) => `
query getUserByIds{
  ${ids.map(
    (id, key) => `b${key}:getUser(id: "${id}") {
    firstName
    lastName
    email
    id
  }`
  )}
}`

export const GET_ISSUE_BY_ID_func = (ids) => `
query getIssueByIds{
  ${ids.map(
    (id, key) => `b${key}:getIssue(id: "${id}") {
    title
    id
  }`
  )}
}`

export const GET_OPPORTUNITY_BY_ID_func = (ids) => `
query getOpportunityByIds{
  ${ids.map(
    (id, key) => `b${key}:getOpportunity(id: "${id}") {
    title
    id
  }`
  )}
}`

export const GET_TOTAL_UNREAD_NOTIFICATIONS_COUNT = `
query GET_TOTAL_UNREAD_NOTIFICATIONS( $userId: ID!) {
  searchNotificationActives(filter: {status: {eq: "new"}, userId: {eq: $userId}}, aggregates: {field: userId, name: "uniqueNew", type: terms}) {
    aggregateItems {
      result {
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            doc_count
            key
          }
        }
        ... on SearchableAggregateScalarResult {
          __typename
          value
        }
      }
    }
    nextToken
    total
  }

}

`
