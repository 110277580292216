import { type ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faFileLines, faTachographDigital, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import styles from "./table.module.scss"
import { consultationType, documentType, energyUsageReportType } from 'src/pages/documentsAndReports/helper'

export const IconCell = ({iconType, children, iconSize=IconSize.normal}:IconCellProps )=> {
  const color = getIconColor(iconType);
  const icon = getIcon(iconType);

  return (
    <div className={styles.iconCell}>
      <div className={styles.iconCellIcon} data-icon-color={color} data-size={iconSize}>
        <FontAwesomeIcon icon={icon} />
      </div>
      {children &&
        <div>
          {children}
        </div>
      }
    </div>
  )
}

export type IconCellProps = {
  iconType:string,
  children?:ReactNode,
  iconSize?:IconSize
}

export enum IconSize {
  normal='normal',
  fortyFour='fortyFour',
}

function getIcon(iconType) {
  switch (iconType) {
    case documentType:
      return faFileLines;
    case consultationType:
      return faUserGroup;
    case energyUsageReportType:
      return faBolt;
    case 'SC/SC+':
    case 'CM':
    case 'Symbio 700':
    case 'Ensemble':
    case 'Symbio 800':
    case 'SCG':
    case 'deviceUnavailable':
      return faTachographDigital;
  }
  return faFileLines;
}

function getIconColor(iconType) {
  switch (iconType) {
    case documentType:
      return 'purple';
    case consultationType:
      return 'pink';
    case energyUsageReportType:
      return 'blue';
    case 'SC/SC+':
      return 'blue-duo';
    case 'CM':
      return 'purple-duo';
    case 'Symbio 700':
      return 'pink-duo';
    case 'Ensemble':
      return 'turquoise-duo';
    case 'Symbio 800':
      return 'green-duo';
    case 'SCG':
      return 'gold-duo';
    case 'deviceUnavailable':
      return 'red-duo';
  }
  return '';
}