import { useBreakPoint } from 'src/hooks/breakPoint'
import { Container } from './layouts'

type ContainerProps = {
  maxWidth?: number
  xlColumns?: number
  lColumns?: number
  sColumns?: number
  mColumns?: number
  overflow?: string
  background?: string
  placeItems?: string
  padding?: string
  children: React.ReactNode
}
const ContainerWrapper = (props: ContainerProps) => {
  const { width } = useBreakPoint()
  const {
    xlColumns,
    sColumns,
    lColumns,
    overflow,
    mColumns,
    placeItems,
    background,
    padding,
    children
  } = props
  return (
    <Container
      overflow={overflow}
      background={background}
      sColumns={sColumns}
      mColumns={mColumns}
      lColumns={lColumns}
      xlColumns={xlColumns}
      width={width}
      placeItems={placeItems}
      padding={padding}
    >
      {children}
    </Container>
  )
}

export default ContainerWrapper
