import Modal from 'src/components/legacy/components/modal/modal'
import {
  IconButtons,
  WidgetsLink,
  Wrapper,
  BuildingNavigationWrapper,
  IconWrapper
} from './style'
import {
  WidgetsContextProvider,
  useWidgetsContext
} from 'src/pages/widgets/WidgetsContextProvider'
import { ACTIONS } from 'src/constants/actions'
import BuildingNavigator from 'src/components/buildingNavigation'
import AddWidgetsList from './AddWidgetsList'
import WidgetCreate from 'src/pages/widgets/widget-library/WidgetCreate'
import WidgetTypePicker from 'src/pages/widgets/widget-picker/WidgetTypePicker'
import { useNavigate } from 'react-router-dom'
import translate, { TranslateComponent } from 'src/common/translations'
import { useState } from 'react'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { setWidgetModal } from 'src/redux/slicers/dashboard'
import { useDispatch } from 'react-redux'

const AddWidgets = ({ handleModalClose }) => {
  const [isTileView, setIsTileView] = useState(false)
  const dispatch = useDispatch()
  const { openWidgetPicker, setOpenWidgetPicker, isOpenCreateWidget, setMode } =
    useWidgetsContext() || {}
  const navigate = useNavigate()
  const optionsTranslated = {
    createNew: translate('Create new widget'),
    widgetBuild: translate('Widget Builder'),
  }
  const openCreateWidgetModalConfig = {
    gray: true,
    className: `widget-tile-view-modal custom-wide-modal`,
    isHideWhiteBackground: true,
    heading: "Add widget",
    handleClose: () => {
      handleModalClose()
    },

    footer: (
      <>
        <button
          className="primary"
          onClick={() => {
            setOpenWidgetPicker(true)
            trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_CREATE_WIDGET, {
              location: 'Dashboards'
            })
            setMode(ACTIONS.ADD)
          }}
        >
          {optionsTranslated.createNew}
        </button>
        <WidgetsLink
          onClick={() => {
            navigate('/widget-library')
            dispatch(setWidgetModal(false))
          }}
        >
           
          <TranslateComponent>
            Create, copy, edit, and delete widgets
          </TranslateComponent>
          <br />
          <TranslateComponent> in the</TranslateComponent>{' '}
          <span><TranslateComponent>Widget Library</TranslateComponent></span>
        </WidgetsLink>
      </>
    )
  }

  const pickereWidgetModalConfig = {
    gray: true,
    className: 'wide-modal',
    isHideWhiteBackground: true,
    heading: optionsTranslated.widgetBuild,
    handleClose: () => {
      setOpenWidgetPicker(false)
    }
  }

  return (
    <Modal {...openCreateWidgetModalConfig}>
      <Wrapper>
        <BuildingNavigationWrapper>
          <BuildingNavigator isShowBuildingPicker={true}/>
        </BuildingNavigationWrapper>
        <IconWrapper>
          <IconButtons
            isGrey={false}
            onClick={() => setIsTileView(false)}
            className="view icon-listview"
          />
          <IconButtons
            isGrey={true}
            onClick={() => setIsTileView(true)}
            className="view icon-gridview"
          />
        </IconWrapper>
      </Wrapper>
      <AddWidgetsList
        isTileView={isTileView}
        handleAddNewWidget={() => {
          setOpenWidgetPicker(true)
          setMode(ACTIONS.ADD)
        }}
        handleClose={openCreateWidgetModalConfig.handleClose}
      />

      {openWidgetPicker ? (
        <Modal {...pickereWidgetModalConfig}>
          <WidgetTypePicker />
        </Modal>
      ) : null}

      {isOpenCreateWidget && (
        <WidgetCreate handleModalClose={handleModalClose} />
      )}
    </Modal>
  )
}

const WidgetWrapper = ({ handleModalClose }) => {
  return (
    <WidgetsContextProvider>
      <AddWidgets handleModalClose={handleModalClose} />
    </WidgetsContextProvider>
  )
}

export default WidgetWrapper
