import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import './truck-roll-kpi-table.scss'
import { TranslateComponent } from 'src/common/translations'

const MIN_NUMBER = 0
const MAX_NUMBER = 10000000

export function TruckRollKpiTableRow({
  month,
  year,
  actual,
  goal,
  shortMonthKeys,
  minNumber = MIN_NUMBER,
  maxNumber = MAX_NUMBER,
  onChange
}) {
  const { t } = useTranslation()

  const onChangeField = (field) => (event) => {
    const { value } = event.target
    const re = /^\d*\.?\d*$/
    if (!re.test(value)) {
      return
    }
    if (value < minNumber || value > maxNumber) {
      return
    }
    onChange({
      value: value !== undefined && value !== '' ? value : undefined,
      year,
      monthIndex: shortMonthKeys.findIndex((m) => m === month),
      field
    })
  }

  const shortMonths= {
		"jan": "Jan " + year,
		"feb": "Feb " + year,
		"mar": "Mar " + year,
		"apr": "Apr " + year,
		"may": "May " + year,
		"jun": "Jun " + year,
		"jul": "Jul " + year,
		"aug": "Aug " + year,
		"sep": "Sep " + year,
		"oct": "Oct " + year,
		"nov": "Nov " + year,
		"dec": "Dec " + year
	}

  return (
    <div className="trkt-row">
      <span>
        <TranslateComponent>{shortMonths[month]}</TranslateComponent>
      </span> 
      <input
        value={(actual !== undefined ? actual : '').toString()}
        id={`actual-${month}-${year}`}
        onChange={onChangeField('actual')}
      />
      <input
        value={(goal !== undefined ? goal : '').toString()}
        id={`goal-${month}-${year}`}
        onChange={onChangeField('goal')}
      />
    </div>
  )
}

TruckRollKpiTableRow.propTypes = {
  month: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  actual: PropTypes.number,
  goal: PropTypes.number,
  shortMonthKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  minNumber: PropTypes.number,
  maxNumber: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

export default TruckRollKpiTableRow
