import { StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

export const styles = StyleSheet.create({
	equipmentsWrapper: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		fontSize: 10,
		color: colors.TBA_DARK_GREY
	},
	equipment: {
		fontWeight: "bold"
	}
})
