import styled, { css } from 'styled-components'
import grid from 'src/denali-ui/legacy/common_file/styles/grid'
import colors from 'src/components/layouts/colors.json'

export const BaselineContainer = styled.div`
  .custom-object-picker {
    width: 240px;

    li {
      background-color: white;
      margin-left: 0px;
    }
  }

  .hide-picker {
    display: none;
  }

  .selected-equipment-container-edit {
    border: 1px solid black;
    height: 500px;
    padding: 10px;

    p {
      font-size: 12px;
    }
  }
`

export const TabsWrapper = styled.div`
  width: 98%;
  margin: 0 auto;
  li[data-testid*='tab'] {
    margin: 0 5px;
    border: none;
    background-color: ${colors.TBA_LIGHT_GREY};
    color: #292323;
    font-weight: 600;
    border-radius: 5px 5px 0 0 !important;
    justify-content: flex-start;
    font-size: 13px;
    padding-left: 15px;
  }
  li[data-testid*='tab'].active {
    background-color: #fff;
    color: #000;
  }

  th {
    padding: 5px;
    font-size: 14px;
    font-weight: 700;
  }

  td {
    padding: 5px;
  }
`

export const TableWrapper = styled.div`
  ul {
    min-width: 100px;
  }
`

export const DataPickerWrapper = styled.div`
  align-items: flex-start;
  margin:10px;
  margin-top: 12px;
  margin-bottom:0px;
  margin-left: 0px;
  display: flex;
  
  &.seperator_container{
    align-items: center;
    margin: 0px 12px 0px 10px;
    display: flex;
  }
  .seperator-exclusions {
    margin-left:10px;
    margin-right: 5px;
    position: relative;
    top:10px;
    font-size: 20px;
  }

  .seperator-box {
    padding: 1.4%;
  }

  .seperator {
    margin-left:5px;
    margin-right: 5px;
    position: relative;
    top:-6px;
    font-size: 20px;
  }
  
  .mini-error-text{
		padding-left: 30px !important;
	}

  ${grid('screen-max-small')}{ 
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    align-items: unset;
    gap: 0px;
  }
`

export const TextSection = styled.div`
  margin-top: 12px;
  font-size: 12px;

  .info-text {
    margin: 0;
    font-size: 12px;
  }
`

export const InfoText = styled.p`
  margin: 0;
  font-size: 12px;
`

export const ExlusionContainer = styled.div`
  .exclusion-row-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    button {
      background: ${colors.TBA_DARKER_GREY};
      color: white;
      width: 100px;
      border-radius: 0px;
      height: 25px;
      margin-top: 20px;

      &:hover {
        background: ${colors.TBA_DARK_GREY};
        color: white;
      }
    }

    .buttons-container {
      svg {
        height: 12px;
        width: 12px;
        margin-left: 10px;
      }
    }

    .modal-text-wrapper {
      margin: 0;
      flex-grow: 1;
    }

    .date-container {
      .modal-date-input {
        width: 180px;
      }
    }

    .modal-datepicker-wrapper {
      margin: 0px;
    }

    .exclusion-name-wrapper {
      flex-grow: 1;
    }
  }
`

export const NameLabel = styled.span`
  font-weight: 800;
  ${({ hasError }) =>
    hasError &&
    css`
      color: ${colors.RED};
    `}
`

export const FormErrorMessage = styled.span`
  color: ${colors.RED};
  font-size: 11px;
`

export const ExclutionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Input = styled.input`
  width: 100%;
  ${({ hasError }) =>
    hasError &&
    css`
      color: #262626;
      background: ${colors.RED_1};
      border-color: ${colors.RED};
      font-size: 16px;
      letter-spacing: 0.32px;
      line-height: 15px;
    `}
`
export const BaslineWrapper = styled.div`
  & .modal-text-wrapper .custom-label {
    padding: 15px 0 0 0px !important;
  }
  & .meter-data-text {
    font-size: 12px;
    font-weight: bold;
  }
  & .date-availability {
    font-size: 12px;
    margin-top: 0px;
  }
`
export const ExclusionModalBody = styled.div`
  display: grid;
  grid-template-columns: 200px 200px;
`

export const SelectedBuildingWrapper = styled.div``
