import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'

export const KeyStripBlockStyle = styled.div`
	position: relative;
	padding: 10px 20px 20px;
	background: ${colors.WHITE};

	.additional {
		height: auto;
		min-height: 22px;
		line-height: 20px;
		margin-right: 15px;
		background-color: ${colors.TBA_LIGHTER_GREY};
		transition: background-color 100ms linear;

		&:hover {
			background-color: ${colors.TBA_LIGHT2_GREY};
		}
	}

	.icon-edit {
		color: ${colors.TBA_DARKER_GREY};
	}

	.edit {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 16px;
		padding: 10px 10px 5px 5px;
	}

	.energy-metric-list-container {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin-right: -20px;
	}

	.cell {
		padding: 0 20px 0 0;
		margin-top: 14px;
		min-height: 84px;
		height: auto;

	}

	.item {
		min-height: 100px;
		border-radius: 6px;
		background-image: linear-gradient(to bottom, ${colors.BLUE_10} 0%, ${colors['BLUE-6']} 56%, ${colors['BLUE-6']});
	}

	.placeholder .item {
        position: relative;
		text-transform: uppercase;
		text-align: center;
		font-weight: 900;
		font-size: 18px;
        width: 260px;

		.icon-energy-roi {
			// position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(0%) translateY(50%);
			color: ${colors['BLUE-7']};
			z-index: 1;
			font-size: 50px;
		}

		.text {
			// position: absolute;
			top: 50%;
			transform: translateY(-70%);
			width: 100%;
			text-align: center;
			z-index: 2;
		}
	}

	.settings {
		font-weight: bold;
	    font-size: 13px;
	    line-height: 15px;
	    letter-spacing: 0.26px;
		min-height: 24px;
		margin-bottom: 6px;

		span {
			font-size: 14px;
		}
		> div {
			display: inline;
		}

		.period {
			margin-left: 14px;
			font-weight: 900;

			> span {
				font-weight: normal;
				font-size: inherit;
			}
		}
	}
    @media (max-width: 1473px) {
        .cell-5 {display: none}
    }
    @media (max-width: 1193px) {
        .cell-4 {display: none;}
    }
    @media (max-width: 913px) {
        .cell-3 {display: none;}
    }
    @media (max-width: 633px) {
        .cell-2 {display: none;}
    }

`

export const KeyEnergyMetricDisplayStyle = styled.div`
.kpi-energy-metric-display{
height: 85px;
background-color: ${colors.WHITE};
border-radius: 10px;
box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.65);
display: flex;
width: 260px;

.kpi-energy-metric-builder & {
	background-color: transparent;
	box-shadow: none;

	.end-cap {
		display: none;
	}
	.data {
		color: ${colors.TBA_DARK_GREY};

		.value {
			margin: 2px 20px;
		}

		.missing-data-block {
			font-size: 16px;
		}
	}
}

.end-cap {
	background-image: linear-gradient(to bottom, ${colors.BLUE_10} 0%, ${colors['BLUE-6']} 56%, ${colors['BLUE-6']});
	border-radius: 10px 0 0 10px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1 0 auto;
	text-align: center;
	width: 52px;
	height: 100%;

	[class^="icon-"] {
		position: relative;

		&::before {
			color: ${colors['BLUE-7']};
			font-size: 30px;
		}

		span {
			width: 30px;
			display: block;
			background: radial-gradient(circle, ${colors['BLUE-6']}, transparent 18px);
			height: 30px;
			border-radius: 30px;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			opacity: 0.5;
			bottom: 0;
			margin: auto;
		}
	}



}

.data {
	text-transform: none;
	text-align: center;
	padding: 5px;
	width: 100%;
	align-self: stretch;
	overflow: auto;

	.title {
		font-weight: 900;
		font-size: 14px;
		white-space: nowrap;

		.conditional-tooltip, & + .value .conditional-tooltip {
			display: flex;
			text-align: center;
			align-items: center;
			justify-content: center;
		}
	}
	.value {
		font-weight: 900;
		font-size: 26px;
		margin: 6px 0;
		line-height: 1;

		span {
			font-weight: bold;
			font-size: 18px;
			line-height: 12px;
			vertical-align: bottom;
		}

		.conditional-tooltip {
			.conditional-tooltip__base--position-top {
				margin-bottom: -6px;
			}
		}
	}
	.bottom-text {
		font-size: 14px;
	}

	.missing-data-block {
		margin-top: 25px;
		text-transform: uppercase;
		text-wrap: nowrap;
		padding-left: 10px;
	}

	.icon-indicator-arrow {
		display: inline-block;
		font-size: 18px;
		vertical-align: bottom;
		padding: 0 6px;
		line-height: 1.2;
		&::before {
			color: ${colors.BLUE_7};
			vertical-align: text-top;
		}
		&.down {
			transform: rotate(180deg);
		}
	}
}

.missing-data-block {
	position: relative;
	width: 100%;
	height: auto;
	align-items: center;
	font-weight: 900;
	font-size: 18px;
	//width: 160px;

	[class^="icon-"] {
		// position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(0%) translateY(50%);
		color: ${colors.TBA_LIGHT_GREY};
		z-index: 1;
		font-size: 50px;
	}

	.text {
		// position: absolute;
		top: 50%;
		transform: translateY(-70%);
		width: 100%;
		text-align: center;
		z-index: 2;
		text-overflow: ellipsis;
  	        overflow: hidden;
  	        white-space: nowrap;
	}
}

.missing-cost-estimator-block {
	padding: 4px 14px;
	font-size: 14px;
	font-weight: bold;
	text-align: left;

	a {
		color: inherit;
		text-decoration: underline;
	}
}
.missing-comparison-period-block,
.missing-comparison-period-block-sm {
	font-weight: bold;
	color: ${colors.BLACK};
}

.missing-comparison-period-block {
	padding: 0 30px;
	font-size: 15px;

}
.missing-comparison-period-block-sm {
	padding: 4px 14px;
	font-size: 14px;
	text-align: left;
}

.calculating-block {
	padding: 8px 25px 0;

	.subtitle {
		font-style: italic;
		font-weight: bold;
		margin-top: 8px;
	}
}
.kpi-block-error {
	display: flex;
	align-items: center;
	padding: 8px;
	font-size: 14px;
	color: ${colors.RED};

	.icon-error {
		margin: 0 9px;
		font-size: 22px;
	}
	.subtitle {
		font-style: italic;
		font-weight: bold;
	}
}
}
.kpi-energy-metric-display-error{
	border: 2px solid ${colors.RED};
	.end-cap {
		background-image: linear-gradient(to bottom, ${colors.PINK} 0%, ${colors.PINK} 56%, ${colors.PINK});
	}
}

`

export const ChartLink = styled.a`
    text-decoration: underline;
    color: black;
`

export const MissingDataText = styled.span`
    font-weight: normal;
    padding-left: 14px;
`

export const StyledIcon = styled.span`
    padding-right: 5px;
`