import { PROPERTY_COMBINATION, TargetKeyType } from './constants'
import { EquipmentPropertyAndCharacteristics } from './equipmentPropertiesAndCharacteristics'
import { getConditionsForStatusCalc } from './helper'
import {
  GroupPropertyModel,
  GroupPropertyDetailsModel,
  PropertyModel
} from './model'
import { RulesStatusChecker } from './rulesStatusChecker'

class GroupProperty extends GroupPropertyModel {
  constructor(
    groupTargetKey: any,
    mappingStatus: boolean,
    targetKeyDetails: GroupPropertyDetailsModel[]
  ) {
    super()

    this.groupTargetKey = groupTargetKey ?? ''

    this.mappingStatus = mappingStatus ?? false

    this.targetKeyDetails = targetKeyDetails ?? []
  }
}

class GroupPropertyDetails extends GroupPropertyDetailsModel {
  constructor(
    groupTargetKeys: string,
    condition?: PROPERTY_COMBINATION,
    targetKeyPositionAtGroup = 0,
    propertyDetails?: PropertyModel,
    depth?: number
  ) {
    super()

    this.groupTargetKeys = groupTargetKeys ?? null
    this.condition = condition ?? PROPERTY_COMBINATION.AND
    this.targetKeyPositionAtGroup = targetKeyPositionAtGroup ?? null
    this.depth = depth ?? 0

    // Copy property details into this
    Object.assign(this, propertyDetails)
  }
}

class PropertyDetails extends PropertyModel {
  constructor(targetKey: string, targetKeyType: TargetKeyType) {
    super()

    this.targetKey = targetKey

    this.targetkeyType = targetKeyType
  }
}

export class ATStatusChecker {
  #atStatus = true

  #atInitalizedConfig = {}

  #statusCheckerInstance: RulesStatusChecker
  #propsAndCharInstance: EquipmentPropertyAndCharacteristics

  #characteristicsInGroupedProperties: GroupPropertyDetails[] = []

  constructor(
    propsAndChar: EquipmentPropertyAndCharacteristics,
    statusChecker: RulesStatusChecker,
    atConfigObject: any = {},
    configExtractorFn: any,
    public log = (key: string, value: any) => null
  ) {
    const relatedAtConfigObject =
      this.#collectRelatedPropertiesAndCharacteristics(
        atConfigObject,
        configExtractorFn
      )
    // For Debugging purpose
    log('validataionConfigMapped', relatedAtConfigObject)
    this.#statusCheckerInstance = statusChecker
    this.#propsAndCharInstance = propsAndChar
    this.#atInitalizedConfig = this.#test(relatedAtConfigObject)[0]
  }

  getConfigDetailsForUI() {
    return this.#atInitalizedConfig
  }

  getATStatus(): boolean {
    return this.#atStatus
  }

  #collectRelatedPropertiesAndCharacteristics(rules, extractor): any {
    const relatedKeys = getConditionsForStatusCalc(rules.cond, extractor)
    // For Debugging purpose
    this.log('validataionConfigMappedConditionsKey', relatedKeys)
    return this.#pickAssociatedConditions(relatedKeys, rules)
  }

  #pickAssociatedConditions(releatedKeys = [], rules) {
    return releatedKeys.reduce((av, key) => {
      const value = rules[key]
      if (value) av[key] = { ...value }
      return av
    }, {})
  }

  #test(atConfigObject): [any, boolean] {
    const conditionKeys = Object.keys(atConfigObject)

    const atConfigDetails = {
      [TargetKeyType.PROPERTIES]: [],
      [TargetKeyType.CHARACTERISTICS]: [],
      [TargetKeyType.GROUPS]: []
    }

    for (const key of conditionKeys) {
      const keyObject = atConfigObject[key] ?? null

      if (keyObject === null) continue

      const { prp = null, grp = null, ch = null } = keyObject

      const props = this.#handleProperty(prp)
      const char = this.#handleCharacteristics(ch)
      const group = this.#handleGroups(grp)

      props && atConfigDetails.properties.push(...props)
      char && atConfigDetails.characteristics.push(...char)

      if (group) {
        atConfigDetails.groups.push(...group)

        const groupedChar = this.#handleCharacteristicsFromGroupedProperties(
          char,
          this.#characteristicsInGroupedProperties
        )
        groupedChar && atConfigDetails.characteristics.push(...groupedChar)

        this.#characteristicsInGroupedProperties = []
      }
    }

    const isEmpty = conditionKeys.length === 0

    // if no keys available, so unable to validate and returns false as run status
    return [atConfigDetails, isEmpty ? false : this.#atStatus]
  }

  #handleProperty(targetKeys: string[] = []) {
    if (!Array.isArray(targetKeys)) return null

    const [allProperties, mappingStatus] =
      this.#initalizeTargetKeysDetailsAndMappingStatus(
        targetKeys,
        TargetKeyType.PROPERTIES,
        false
      )

    this.#updateATStatus(mappingStatus)

    return allProperties.sort((a, b) =>
      a?.displayName > b?.displayName ? 1 : -1
    )
  }

  #handleCharacteristics(targetKeys: string[] = []) {
    if (!Array.isArray(targetKeys)) return null

    const [allCharacteristics, mappingStatus] =
      this.#initalizeTargetKeysDetailsAndMappingStatus(
        targetKeys,
        TargetKeyType.CHARACTERISTICS,
        false
      )

    this.#updateATStatus(mappingStatus)

    return allCharacteristics.sort((a, b) =>
      a?.displayName > b?.displayName ? 1 : -1
    )
  }

  #handleCharacteristicsFromGroupedProperties(
    characteristcs: GroupPropertyDetails[] = [],
    groupedCharacteristcs: GroupPropertyDetails[] = []
  ) {
    return groupedCharacteristcs.filter(
      (gCh) => !characteristcs.some((ch) => ch.targetKey === gCh.targetKey)
    )
  }

  #handleGroups(targetKeys: string[] = []) {
    if (!Array.isArray(targetKeys)) return null

    // returns only available properties
    return targetKeys.reduce((av, targetKey) => {
      const gProp = this.#handleGroupByReponseType(targetKey)

      // Allowing all gModel regardless of targetKeyDetails availability
      // if (gProp?.targetKeyDetails?.length === 0) return av

      // TODO : check null

      av.push(gProp)

      return av
    }, [])
  }

  #handleGroupByReponseType(targetKeys = '') {
    const isJson = this.#isObject(targetKeys)

    // Going forward, grouped properties will be given in JSON format instead of a string.
    // Until Chiller's grouped properties are replaced with the new format (JSON), we have to support the string format as well.
    return isJson
      ? this.#createGroupedPropertiesFromJSON(targetKeys)
      : this.#createGroupedProperties(targetKeys)
  }

  #createGroupedPropertiesFromJSON(targetKeys: any = {}, depth = 0) {
    const isNonEmptyObject = this.#isObject(targetKeys, true)

    if (!isNonEmptyObject) return []

    const targetKeyDetails: GroupPropertyDetails[] = []

    const { rel = 'or', keys = [] } = targetKeys || {}

    const isAnyTrue = rel === 'or'

    let statusOfAllTargetKeys = null

    const setMappingStatus = (mappingStatus) => {
      if (
        isAnyTrue &&
        (statusOfAllTargetKeys === null || !statusOfAllTargetKeys)
      ) {
        statusOfAllTargetKeys = mappingStatus
      }

      if (
        !isAnyTrue &&
        (statusOfAllTargetKeys === null || statusOfAllTargetKeys)
      ) {
        statusOfAllTargetKeys = mappingStatus
      }
    }

    for (let index = 0; index < keys.length; index++) {
      let key = keys[index]

      const isString = typeof key === 'string'

      if (!isString) {
        const [nestedTargetKeyDetails, nestedMappingStatus] =
          this.#createGroupedPropertiesFromJSON(key, depth + 1) as [
            null | GroupPropertyDetails[],
            boolean
          ]

        if (nestedTargetKeyDetails?.length) {
          targetKeyDetails.push(...nestedTargetKeyDetails)

          setMappingStatus(nestedMappingStatus)
        }

        continue
      }

      // Grouped properties may contain characteristics
      // Check every targetkey whether it is property or characteristics
      // Characteristis are prefixed with `ch|` indentifer
      const [ch, tKey] = key.split('ch|')

      const isCharacteristics = ch === ''

      key = isCharacteristics ? tKey : key

      const [properties, mStatus] =
        this.#initalizeTargetKeysDetailsAndMappingStatus(
          [key],
          isCharacteristics
            ? TargetKeyType.CHARACTERISTICS
            : TargetKeyType.GROUPS,
          false
        )

      properties.forEach((propertyDetails) => {
        const details = new GroupPropertyDetails(
          key,
          isAnyTrue ? PROPERTY_COMBINATION.OR : PROPERTY_COMBINATION.AND,
          index,
          propertyDetails,
          depth
        )
        // Characteristics are also pushed along with other property details, but they will be skipped from appearing on the UI under properties table by UI logic.
        targetKeyDetails.push(details)

        // Store characteristics in grouped properties that will be merged into the characteristics array to make them appear under the characteristics tab on the UI
        if (isCharacteristics) {
          this.#characteristicsInGroupedProperties.push(details)
        }
      })

      setMappingStatus(mStatus)
    }

    const mappingStatus =
      statusOfAllTargetKeys === null ? false : !!statusOfAllTargetKeys

    // when the depth is > 0 means - handling the nested groups
    if (depth) {
      // Sort, index them and return
      targetKeyDetails
        .sort((a, b) => (a?.displayName > b?.displayName ? 1 : -1))
        .forEach((d, i) => {
          d.targetKeyPositionAtGroup = i
        })
      return [targetKeyDetails, mappingStatus]
    } else {
      // Update AT status before returing the grouped properties
      this.#updateATStatus(mappingStatus)

      return new GroupProperty(targetKeys, mappingStatus, targetKeyDetails)
    }
  }

  #createGroupedProperties(targetKeysStr = '') {
    const orArr = this.#splitTargetKeyByCondition(targetKeysStr)

    let mappingStatus = false

    const targetKeyDetails = []

    for (let index = 0; index < orArr.length; index++) {
      const key = orArr[index]

      if (key === '') continue

      const isAnd = key.includes('~')

      const keys = isAnd ? this.#splitTargetKeyByCondition(key, '~') : [key]

      const [properties, mStatus] =
        this.#initalizeTargetKeysDetailsAndMappingStatus(
          keys,
          TargetKeyType.GROUPS,
          false
        )

      properties
        .sort((a, b) => (a?.displayName > b?.displayName ? 1 : -1))
        .forEach((propertyDetails, index) => {
          targetKeyDetails.push(
            new GroupPropertyDetails(
              key,
              isAnd ? PROPERTY_COMBINATION.AND : PROPERTY_COMBINATION.OR,
              index,
              propertyDetails
            )
          )
        })

      if (!mappingStatus && mStatus) {
        mappingStatus = mStatus
      }
    }

    this.#updateATStatus(mappingStatus)

    return new GroupProperty(targetKeysStr, mappingStatus, targetKeyDetails)
  }

  #splitTargetKeyByCondition(targetKey = '', splitBy = '|') {
    return targetKey.split(splitBy)
  }

  #initalizeTargetKeysDetailsAndMappingStatus(
    targetKeys: string[] = [],
    type: TargetKeyType,
    isAnyTrue = true
  ): [any[], boolean] {
    let statusOfAllTargetKeys = null

    const properties = targetKeys.reduce((av, targetKey) => {
      const mappingStatus =
        this.#statusCheckerInstance.getStatusByTargetkeyWithType(
          targetKey,
          type
        )

      // [TODO : Check the flow] No rules found or unable to validate the given targetKey, so skipping the flow of execution
      // if (mappingStatus === null) return av

      let targetKeySourceDetails = []

      if (type === TargetKeyType.PROPERTIES || type === TargetKeyType.GROUPS)
        targetKeySourceDetails =
          this.#propsAndCharInstance.getActiveEquipmentPropertyDetailByTargetKey(
            targetKey
          )

      if (type === TargetKeyType.CHARACTERISTICS)
        targetKeySourceDetails =
          this.#propsAndCharInstance.getActiveEquipmentCharacteristicsDetailByTargetKey(
            targetKey
          )

      if (!targetKeySourceDetails?.length) {
        if (!isAnyTrue) statusOfAllTargetKeys = false
        return av
      }

      const { valueMappingDetails, ...sourceDetails } =
        targetKeySourceDetails.at(0)

      const propertyDetails = new PropertyDetails(targetKey, type)

      // Fill details on property details

      propertyDetails.hasSourceDetails = true

      propertyDetails.sourceDetails = sourceDetails

      propertyDetails.valueMappingDetails = valueMappingDetails

      propertyDetails.ruleDetails =
        this.#statusCheckerInstance.getRulesDetailsByTargetKey(targetKey)

      propertyDetails.mappingStatus = mappingStatus

      propertyDetails.displayName = sourceDetails?.tisDisplayName ?? ''

      if (
        isAnyTrue &&
        (statusOfAllTargetKeys === null || !statusOfAllTargetKeys)
      ) {
        statusOfAllTargetKeys = mappingStatus
      }

      if (
        !isAnyTrue &&
        (statusOfAllTargetKeys === null || statusOfAllTargetKeys)
      ) {
        statusOfAllTargetKeys = mappingStatus
      }

      av.push(propertyDetails)

      return av
    }, [])

    // When statusOfAllTargetKeys is null means, no properties / characteristcs details are available to test, So return false
    // Bz given properties are required for AT validataion status. it is supposed to be in the data list
    return [
      properties,
      statusOfAllTargetKeys === null ? false : !!statusOfAllTargetKeys
    ]
  }

  #updateATStatus(mappingStatus: boolean) {
    if (this.#atStatus) this.#atStatus = mappingStatus
  }

  #isObject(value: any, checkIsNotEmpty?: boolean): boolean {
    const isObject = Object.prototype.toString.call(value) === '[object Object]'

    if (checkIsNotEmpty && isObject) {
      return !!Object.keys(value).length
    }

    return isObject
  }
}
