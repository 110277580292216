import { findRequiredDetailsByKey } from '../helper'
import { requiredProperties } from './constants'

import { ATManager } from '../atManager'
import { ApplicabilityCheck } from '../applicabilityCheck'
import { RulesStatusChecker } from '../rulesStatusChecker'
import { ATStatusChecker } from '../atStatusChecker'
import { EquipmentDetails } from './equipmentDetails'
import { EquipmentPropertyAndCharacteristics } from '../equipmentPropertiesAndCharacteristics'

export function handleAHUStatus(
  configs,
  characteristicsList,
  dataMappingProperties
) {
  const hasConfig = !!configs.length
  const hasCharacteristic = !!characteristicsList.length

  // Return defaults when required data is not available
  if (!hasCharacteristic || !hasConfig) {
    return {
      tests: [],
      activeTests: 0
    }
  }

  // [ Note ]
  // Below `EquipmentPropertyAndCharacteristics`and `EquipmentConditionGenderator` should be moved to top of the equipment page when associated equipments are going to be implemented
  // Bz initialzing instances on every change would impact the performace

  const propsAndCharsInst = new EquipmentPropertyAndCharacteristics()

  propsAndCharsInst.setActiveEquipmentCharacteristics(characteristicsList)

  propsAndCharsInst.setActiveEquipmentProperties(dataMappingProperties)

  // Extract required details from characteristics
  const requiredDetails = findRequiredDetailsByKey(
    propsAndCharsInst.getActiveEquiomentAllCharacteristics(),
    requiredProperties
  )

  // generate chiller details with conditions
  const equipmentInst = new EquipmentDetails(requiredDetails)

  const atManager = new ATManager<EquipmentDetails>(equipmentInst)

  let automatedTests = atManager.init(configs, (applicabilityCheckConfig) => {
    const { isApplicable } = new ApplicabilityCheck(
      applicabilityCheckConfig,
      characteristicsList
    )

    return isApplicable
  })

  automatedTests = atManager.checkATStatus(automatedTests, (automatedTest) => {
    const { validationObject } = automatedTest

    // TODO [Remove] :  For debuggig prupose
    const log = (key, value): any => (automatedTest[key] = value)

    let rulesStatusCheckerInst = new RulesStatusChecker(
      propsAndCharsInst,
      validationObject?.rules || {},
      automatedTest?.getConditionDetails,
      log
    )

    let atStatusCheckerInst = new ATStatusChecker(
      propsAndCharsInst,
      rulesStatusCheckerInst,
      validationObject,
      automatedTest?.getConditionDetails,
      log
    )

    automatedTest.uiProperties = atStatusCheckerInst.getConfigDetailsForUI()
    automatedTest.mappingStatus = atStatusCheckerInst.getATStatus()

    // Free up memory
    // testValidator = null
    rulesStatusCheckerInst = null
    atStatusCheckerInst = null

    return automatedTest
  })

  automatedTests = atManager.sortByDisplayName(automatedTests)

  return atManager.getResult(automatedTests)
}
