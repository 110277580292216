import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment/moment'
import { parse, format, differenceInMonths, isValid } from 'date-fns';
import { getSearchParams } from "src/common/helperFunctions.js"
import { useAppSelector } from 'src/redux/store'
import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import _setWith from "lodash/setWith";
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { DATE_FORMAT, DATE_FORMAT_SLASH, MONTH_YEAR_FORMAT } from 'src/components/legacy/common/time-helpers'
import {
    CREATE_ENERGY_USAGE_REPORT,
    UPDATE_ENERGY_USAGE_REPORT
} from '../graphql'
import COLORS from 'src/denali-ui/styles/colors/colors.json'
import { ACTIONS } from 'src/constants'
import { getEUReportFormValues, validationSchemaEUReport, getEUReportUpsertInput, Summary } from '../helper'
import PAGE_NAMES from 'src/components/legacy/common/pages'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { EUWrapper, ReportAddPage } from '../styles'
import EUReportHeadSection from './EUReportHeadSection'
import EUReportBodySection from './EUReportBodySection'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { listUtilityDataByBuilding } from '../../../pages/energyIntensity/graphql'
import { listBenchMarkersByBuilding } from '../../../pages/energyIntensity/graphql'
import { GET_ENERGY_USAGE_REPORT } from '../graphql'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
const EditEnergyUsageReport = (props) => {
    const { mode } = props || {}
    const { id } = useParams()
    const { organizationId, buildingId: location } = getSearchParams()
    const navigate = useNavigate()
    const userInfo: any = useAppSelector(selectUserInfo)
    const [isSubmit, setIsSubmit] = useState(false)
    const [fetchedReport, setFetchedReport] = useState(null)
    const [EUReportInitialValues, setEUReportInitialValues] = useState(null)
    const [upsertReportId, setUpsertReportId] = useState(null)
    const [isLoading, setIsLoading] = useState([ACTIONS.EDIT].includes(props?.mode))
    const [barChartData, setBarChartData] = useState({})
    const [isTriggerValidation, setIsTriggerValidation] = useState( mode === ACTIONS.EDIT)
    const [pieChartData, setPieChartData] = useState({
        pieData: [],
        showPieChart: false
    })
    const [subSectionVisbility, setSubSectionVisbility] = useState({
        showBarChart: true,
        showPieChart: true,
        showHighlight: true
    })
    const [selectedBenchMark, setSelectedBenchMark] = useState("")
    const [benchMarkDetails, setBenchMarkDetails] = useState({
        benchMarkName: "",
        benchMarkValue: ""
    })
    const [dataError, setDataError] = useState(false)
    const [noDataError, setNoDataError] = useState(false)
    const [reloadCount, setReloadCount] = useState(0)

    const { data: utilityData, refetch: refetchUtilityData } = useQuery(
        {
            query: listUtilityDataByBuilding,
            dataPath: 'data.listUtilityDataByBuilding.items',
            variables: { buildingId: location },
            disableInitialLoad: true
        }
    )

    const { data: benchMarkerData, refetch: refetcBenchMarkerData, loading: benchmarksLoading } = useQuery(
        {
            query: listBenchMarkersByBuilding,
            variables: { buildingId: location },
            disableInitialLoad: true,
            errorPolicy: 'all',
            dataPath: 'data.listBenchMarkersByBuilding.items'
        }
    )

    const { loading, refetch: refetchEnergyUsageReportDetail } = useQuery({
        query: GET_ENERGY_USAGE_REPORT,
        disableInitialLoad: true,
        dataPath: 'data.getEnergyUsageReport',
        onSuccess: (euReport) => {
            try {
                if (euReport) {
                    const parsedEUReportData = {
                        ...euReport,
                        chartSection: euReport?.chartSection ? JSON.parse(euReport?.chartSection) : {},
                        highlightSection: euReport?.highlightSection ? JSON.parse(euReport?.highlightSection) : {},
                        summarySection: euReport?.summarySection ? JSON.parse(euReport?.summarySection) : {}
                    }
                    const formValues = getEUReportFormValues(mode, parsedEUReportData, location ?? '', userInfo ?? '')
                    setFetchedReport(formValues)
                    setEUReportInitialValues(formValues)
                    setSelectedBenchMark(parsedEUReportData?.chartSection?.barChartData?.benchMarkId)
                    setBenchMarkDetails({
                        ...benchMarkDetails,
                        benchMarkValue: _find(benchMarkerData, { id: parsedEUReportData?.chartSection?.barChartData?.benchMarkId })?.value,
                        benchMarkName: _find(benchMarkerData, { id: parsedEUReportData?.chartSection?.barChartData?.benchMarkId })?.name
                    })
                    setBarChartData(formValues?.barChartData)
                    setPieChartData({
                        pieData: formValues?.pieChartData?.pieData,
                        showPieChart: formValues?.pieChartData?.showPieChart
                    })
                    setSubSectionVisbility({
                        showBarChart: parsedEUReportData?.chartSection?.barChartData?.showBarChart,
                        showPieChart: parsedEUReportData?.chartSection?.pieChartData?.showPieChart,
                        showHighlight: parsedEUReportData?.highlightSection?.showHighlights
                    })
                    setIsLoading(false)
                }
            } catch (error) {

            }

        }
    })

    useEffect(() => {
        if (mode === ACTIONS.EDIT && id) {
            refetchEnergyUsageReportDetail({ id: id })
        }
    }, [id])

    useEffect(() => {
        if (mode === ACTIONS.EDIT && fetchedReport?.buildingId) {
            refetchUtilityData({ buildingId: fetchedReport?.buildingId })
            refetcBenchMarkerData({ buildingId: fetchedReport?.buildingId })
        }
    }, [fetchedReport])

    useEffect(() => {
        if (location) {
            refetchUtilityData({ buildingId: location })
            refetcBenchMarkerData({ buildingId: location })
        }
    }, [location])


    useEffect(() => {
        if (selectedBenchMark && benchMarkerData) {
            const barChartData = _cloneDeep(formik?.values?.barChartData)
            const benchMarkValue = _find(benchMarkerData, { id: selectedBenchMark })?.value
            const benchMarkName = _find(benchMarkerData, { id: selectedBenchMark })?.name
            const { euChart, ecChart } = (barChartData && barChartData?.barData) || {};
            if (euChart || ecChart) {
                const chartEUIndex = _findIndex(euChart, { chartType: "Industry EU Average Index" })
                const chartECIndex = _findIndex(ecChart, { chartType: "ECI Estimated BenchMark" })
                euChart[chartEUIndex] = {
                    name: benchMarkName,
                    value: benchMarkValue,
                    color: COLORS.TEAL_400,
                    chartType: "Industry EU Average Index"
                }
                ecChart[chartECIndex] = {
                    ...ecChart[chartECIndex],
                    name: `Estimated ${benchMarkName}`
                }
                const updatedBarChartData = {
                    ...barChartData,
                    barData: {
                        ...barChartData.barData,
                        euChart,
                        ecChart
                    }
                }
                setBarChartData({
                    ...updatedBarChartData
                })
                formik?.setFieldValue('barChartData', { ...updatedBarChartData })
            }
        }
    }, [selectedBenchMark])

    // Get the filtered data based on date ranges
    const getFilteredData = (sDate, eDate, data) => {
        // Bar chart date update on the start date & end date range
        const startDate = sDate
        const endDate = eDate

        // Formatted date
        const parsedStartDt = parse(startDate, DATE_FORMAT_SLASH, new Date(startDate))
        const parsedEndDt = parse(endDate, DATE_FORMAT_SLASH, new Date(endDate))
        if (!isValid(new Date(parsedStartDt)) || !isValid(new Date(parsedEndDt))) return
        const formattedStartDate = format(parsedStartDt, MONTH_YEAR_FORMAT)
        const formattedEndDate = format(parsedEndDt, MONTH_YEAR_FORMAT)

        // Combine year & month and add it in a new key (date) for comparison
        const dateMapped = data.map(data => _setWith(data, 'date', format(parse(`${data.year}-${data.month}`, MONTH_YEAR_FORMAT, new Date(`${data.year}-${data.month}`)), MONTH_YEAR_FORMAT), data))

        // Filter the date based on the selected start & end date
        const filteredData = dateMapped.filter(data => {
            const utilityDate = format(data?.date, MONTH_YEAR_FORMAT)
            if (differenceInMonths(utilityDate, formattedStartDate) >= 0 && differenceInMonths(formattedEndDate, utilityDate) >= 0) {
                return data
            }
        })
        return filteredData
    }

    const updateReportData = (data) => {
        if (!data || !formik?.values?.startDate || !formik?.values?.endDate) return
        // Get the filtered data
        const updatedData = getFilteredData(formik?.values?.startDate, formik?.values?.endDate, data)

        // Update the Bar and Piechart local state and in formik
        const barChartData = frameBarChartData(updatedData, formik?.values?.endDate)
        const pieChartData = framePieChartData(updatedData)
        setBarChartData(barChartData)
        setPieChartData(pieChartData)
        formik?.setFieldValue('barChartData', barChartData)
        formik?.setFieldValue('pieChartData', pieChartData)

        // Update the PieChart summary text
        pieChartSummarySetter(pieChartData, updatedData)
    }

    const handleToggle = (e, type, index) => {
        const barData = _get(barChartData, `barData`, {})
        const cuuBarData = _get(barChartData, `barData.${type}`, {})
        const barChartContent = [...cuuBarData]
        if (!e?.target?.checked) {
            barChartContent[index] = {
                ...cuuBarData[index],
                value: 0
            }
            const updatedChartValue = {
                ...barChartData,
                barData: {
                    ...barData,
                    [`${type}`]: barChartContent
                }
            }
            setBarChartData(updatedChartValue)
            formik?.setFieldValue('barChartData', updatedChartValue)
        } else {

            let usageData = { barData: null }
            // Get the filtered data
            const updatedData = getFilteredData(formik?.values?.startDate, formik?.values?.endDate, utilityData)
            usageData = frameBarChartData(updatedData, formik?.values?.endDate)
           
            barChartContent[index] = {
                ...usageData?.barData[`${type}`][index],
            }
            const updatedChartValue = {
                ...barChartData,
                barData: {
                    ...barData,
                    [`${type}`]: barChartContent
                }
            }
            setBarChartData(updatedChartValue)           
            formik?.setFieldValue('barChartData', updatedChartValue)
            
        }
    }

    const calculateECIEstimate = (eci, eui, benchmark) => {
        return eci * (benchmark / eui)
    }

    const benchMarkValue = _find(benchMarkerData, { id: selectedBenchMark })?.value ?? 0

    const frameBarChartData = (data, endDate) => {

        // Formatted date
        const parsedEndDt = parse(endDate, DATE_FORMAT_SLASH, new Date(endDate))
        if(!isValid(new Date(parsedEndDt))) return
        const formattedEndDate = format(parsedEndDt, MONTH_YEAR_FORMAT)

        // Combine year & month and add it in a new key (date) for comparison
        const dateMapped = data?.map(data => _setWith(data, 'date', format(parse(`${data.year}-${data.month}`, MONTH_YEAR_FORMAT, new Date(`${data.year}-${data.month}`)), MONTH_YEAR_FORMAT), data))

        const getSelectedData = _find(dateMapped, { date: formattedEndDate })

        setDataError(!getSelectedData || getSelectedData?.eui === 0 || getSelectedData?.eci === 0)
        const benchMarkName =_find(benchMarkerData, { id: selectedBenchMark })?.name

        return {
            barData: {
                euChart: [{
                    name: "Energy Usage Index",
                    value: getSelectedData?.eui,
                    color: COLORS.GREEN_400
                },
                {
                    name: selectedBenchMark ? benchMarkName : "",
                    chartType: "Industry EU Average Index",
                    value: benchMarkValue,
                    color: COLORS.TEAL_400
                }],
                ecChart: [{
                    name: "Energy Cost Index",
                    value: getSelectedData?.eci,
                    color: COLORS.GREEN_400
                },
                {
                    name: selectedBenchMark ? `Estimated ${benchMarkName}` : "",
                    chartType: "ECI Estimated BenchMark",
                    value: benchMarkValue ? calculateECIEstimate(getSelectedData?.eci, getSelectedData?.eui, benchMarkValue) : 0,
                    color: COLORS.TEAL_400
                }]
            },
            showBarChart: subSectionVisbility.showBarChart,
            benchMarkId: selectedBenchMark
        }
    }

    const framePieChartData = (data) => {
        if(!data) return
        const usageIndexData = data?.reduce((acc, curr) => {
            return {
                consumptionCost: acc.consumptionCost + (curr.electricUtilityData?.consumptionCost ?? 0),
                totalDemandCost: acc.totalDemandCost + (curr.electricUtilityData?.totalDemandCost ?? 0),
                fees: acc.fees + (curr.electricUtilityData?.fees ?? 0),
                totalCost: acc.totalCost + (curr.gasUtilityData?.totalCost ?? 0)
            };
        }, {
            consumptionCost: 0,
            totalDemandCost: 0,
            fees: 0,
            totalCost: 0
        })
        return {
            pieData: [
                { "name": "Electric Consumption", "value": usageIndexData?.consumptionCost, "label": "Electric Consumption", "color": COLORS.GREEN_400 },
                { "name": "Electric Demand", "value": usageIndexData?.totalDemandCost, "label": "Electric Demand", "color": COLORS.GREEN_500 },
                { "name": "Electric Fees", "value": usageIndexData?.fees, "label": "Electric Fees", "color": COLORS.BLUE_500 },
                { "name": "Gas", "value": usageIndexData?.totalCost, "label": "Gas Label", "color": COLORS.TEAL_400 }
            ],
            showPieChart: subSectionVisbility?.showPieChart
        }
    }

    const pieChartSummarySetter = (data, report = []) => {
        const sum = data?.pieData?.reduce(([total, demand], curr) => curr.name === "Electric Demand" ? [total + curr.value, demand + curr.value] : [total + curr.value, demand], [0, 0])
        const demandPercent = sum && sum[0] && sum[1] ? (sum[1] / sum[0]) * 100 : 0
        const currentEUI = formik?.values?.barChartData?.barData?.euChart ? _get(formik?.values?.barChartData?.barData?.euChart[0], 'value', 0) : 0
        
        const summaryData = Summary
            .replace("<start date>", moment(formik?.values?.startDate).format(DATE_FORMAT))
            .replace("<end date>", moment(formik?.values?.endDate).format(DATE_FORMAT))
            .replace("<current EUI>", currentEUI)
            .replace("<demand percent>", demandPercent ? `${demandPercent.toFixed(1)}%` : "N/A")
            .replace("<industry avg EUI>", benchMarkDetails?.benchMarkValue ? (benchMarkDetails?.benchMarkValue).toString() : "N/A")
            .replace("<benchmark name>", benchMarkDetails?.benchMarkName ? benchMarkDetails?.benchMarkName : "N/A")

        formik?.setFieldValue("summary", summaryData)
    }

    const benchMarksOptionList = () => {
        if (benchMarkerData && benchMarkerData.length) {
            return benchMarkerData.map(({ id, name }) => ({ key: id, value: name }))
        } else {
            return []
        }
    }

    useEffect(() => {
        if(mode !== ACTIONS.EDIT)
            setSelectedBenchMark(benchMarkerData?.length ? benchMarkerData[0]?.id : "")
    }, [benchMarkerData])
    
    const onChangeBenchMark = ({ key, value }) => {
        setIsTriggerValidation(true)
        setSelectedBenchMark(key)
        setBenchMarkDetails({
            ...benchMarkDetails,
            benchMarkValue: _find(benchMarkerData, { id: key })?.value,
            benchMarkName: _find(benchMarkerData, { id: key })?.name
        })
        const eui = formik?.values?.barChartData?.barData?.euChart ? _get(formik?.values?.barChartData?.barData?.euChart[0], 'value', 0) : 0
        const eci = formik?.values?.barChartData?.barData?.ecChart ? _get(formik?.values?.barChartData?.barData?.ecChart[0], 'value', 0) : 0
        const eciEstimatedValue = calculateECIEstimate(eci, eui, _find(benchMarkerData, { id: key })?.value)

        const barData = _get(barChartData, `barData`, {})
        const cuuBarData = _get(barChartData, `barData.ecChart`, {})
        const barChartContent = [...cuuBarData]

        barChartContent[1] = {
            ...cuuBarData[1],
            value: eciEstimatedValue
        }

        const updatedBarData = {
            ...barData,
            ecChart: barChartContent
        }

        setBarChartData({
            ...barChartData,
            barData: updatedBarData,
            benchMarkId: key
        })

        formik?.setFieldValue('barChartData', { ...barChartData, barData: updatedBarData, benchMarkId: key })
    }

    const { onSubmit: upsertEnergyUsageReport } = useMutation({
        query: [ACTIONS.ADD].includes(mode) ? CREATE_ENERGY_USAGE_REPORT : UPDATE_ENERGY_USAGE_REPORT,
        dataPath: [ACTIONS.ADD, ACTIONS.COPY].includes(mode) ? 'data.createEnergyUsageReport' : 'data.updateEnergyUsageReport'
    })

    useEffect(() => {
        if (mode === ACTIONS.ADD) {
            const formValues = getEUReportFormValues(mode, null, location ?? '', userInfo ?? '', utilityData)
            setEUReportInitialValues(formValues)
        }
    }, [mode, utilityData])

    useEffect(() => {
        if (!upsertReportId) return
        navigate(`/energy-reports/${upsertReportId}?reportType=EnergyUsageReport`, {
            state: { mode: ACTIONS.VIEW, id: upsertReportId }
        })
    }, [upsertReportId])

    const handleUpsert = async (formValues) => {
        setIsSubmit(true)
        const input = getEUReportUpsertInput(mode, formValues)
        let reportId = null
        try {
            // Upsert Energy Usage Report
            const response = await upsertEnergyUsageReport({ input }).then((res) => {
                reportId = res.id
                return res.id
            })
            if (response) {
                setIsSubmit(false)
            }
            return reportId
        } catch (error) {
            setIsSubmit(false)
        }
    }

    const submitEUReportForm = async (values) => {
        const reportId = await handleUpsert(values)
        setUpsertReportId(reportId)
    }

    const formik = useFormik({
        initialValues: EUReportInitialValues,
        validationSchema: validationSchemaEUReport,
        validateOnChange: isTriggerValidation,
        enableReinitialize: true,
        onSubmit: submitEUReportForm
    })

    const handleFormSubmit = () => {
        setIsTriggerValidation(true)
        formik.handleSubmit()
        if(mode == ACTIONS.ADD)
        {
            trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.SAVE_REPORT, {type:"Create", title:formik?.values?.reportTitle})
        }
        else if(mode == ACTIONS.EDIT)
        {
            trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.SAVE_REPORT, {type:"Edit", title:formik?.values?.reportTitle})
        }
        else if(mode == ACTIONS.COPY)
        {
            trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.SAVE_REPORT, {type:"Copy", title:formik?.values?.reportTitle})
        }
    }

    return (
        <>
            {
                isLoading ? (<Spinner />) :
                    (
                        <>
                            <EUWrapper>
                                <EUReportHeadSection
                                    formik={formik}
                                    handleFormSubmit={handleFormSubmit}
                                    isSubmit={isSubmit}
                                    buildingId={location || formik?.values?.buildingId}
                                    setBarChartData={setBarChartData}
                                    setPieChartData={setPieChartData}
                                    frameBarChartData={frameBarChartData}
                                    framePieChartData={framePieChartData}
                                    data={utilityData}
                                    pieChartSummarySetter={pieChartSummarySetter}
                                    dataError={dataError}
                                    noDataError={noDataError}
                                    setNoDataError={setNoDataError}
                                    pieChartData={pieChartData}
                                    getFilteredData={getFilteredData}
                                    setIsTriggerValidation={setIsTriggerValidation}
                                />

                            </EUWrapper>
                            <ReportAddPage>
                                <div className="report-add-page">
                                    <EUReportBodySection
                                        formik={formik}
                                        handleFormSubmit={handleFormSubmit}
                                        isSubmit={isSubmit}
                                        mode={mode}
                                        handleToggle={handleToggle}
                                        barChartData={barChartData}
                                        pieChartData={pieChartData}
                                        handleSubSectionVisbility={setSubSectionVisbility}
                                        subSectionVisbility={subSectionVisbility}
                                        report={utilityData}
                                        benchMarksOptionList={benchMarksOptionList}
                                        onChangeBenchMark={onChangeBenchMark}
                                        selectedBenchMark={selectedBenchMark}
                                        benchMarkDetails={benchMarkDetails}
                                        pieChartSummarySetter={pieChartSummarySetter}
                                        dataError={dataError}
                                        noDataError={noDataError}
                                        reloadCount={reloadCount}
                                        setReloadCount={setReloadCount}
                                        getFilteredData={getFilteredData}
                                        setIsTriggerValidation={setIsTriggerValidation}
                                    />
                                </div>
                            </ReportAddPage>
                        </>
                    )
            }
        </>
    )

}

export default EditEnergyUsageReport