import i18next from "i18next"
export const TARGET_MARKERS_NONE = ":target-marker/disabled"
export const TARGET_MARKERS_CONSTANT = ":target-marker/constant"
export const TARGET_MARKERS_CALCULATED = ":target-marker/calculated"
export const TARGET_MARKERS_APPROPRIATE = ":target-marker/appropriate"

export const TARGET_MARKERS = [TARGET_MARKERS_NONE, TARGET_MARKERS_CONSTANT, TARGET_MARKERS_CALCULATED]
export const ENERGY_TARGET_MARKERS = [TARGET_MARKERS_NONE, TARGET_MARKERS_CONSTANT, TARGET_MARKERS_APPROPRIATE]

export const TARGET_MARKERS_CALCULATED_OPTION = [
	":sc/avg-last-30-days",
	":sc/avg-30-days-same-time"
]

export const formatTargetMarkers = (id) => {
    return i18next.t(`widget-builder>${id}`, { nsSeparator: ">" }) || id
}
