import React, { PureComponent } from 'react'
import './index.scss'
import moment from 'moment-timezone'
import DatePicker from '../datepicker/datepicker.jsx'
import { bool, string, func, object } from 'prop-types'
import classNames from 'classnames'
import i18next from 'i18next'

class DatePickerInput extends PureComponent {
  static propTypes = {
    className: string,
    date: string,
    disableAfterGivenDate: object,
    disableBeforeGivenDate: object,
    disabled: bool,
    format: string,
    isClock: bool,
    loading: bool,
    onChange: func,
    outerErrorMessage: string,
    timezone: string,
    selectMonth: bool,
    inputDate: string
  }

  static defaultProps = {
    className: 'reactDatePickerInput',
    disabled: false,
    disablePast: false,
    end: moment(),
    inputDate: '',
    format: 'MM-DD-YYYY',
    future: false,
    isClock: false,
    onChange: () => {},
    outerErrorMessage: '',
    past: false,
    start: moment(),
    startDate: moment(),
    timezone: 'America/Chicago',
    selectMonth: false
  }

  state = {
    inputValue: moment(),
    hasErr: false,
    isFocused: false,
    errorMessage: '',
    startDate: null
  }

  // TODO: watch this, as it may break other uses of datepicker
  static getDerivedStateFromProps = (
    {
      date,
      format,
      timezone,
      disableBeforeGivenDate,
      disableAfterGivenDate,
      startDate: startDateProp
    },
    { startDate: startDateState, inputValue, isFocused }
  ) => {
    const newDate = isFocused ? inputValue : date
    const newStartDate = DatePickerInput.parseDate(newDate, {
      format,
      timezone
    })

    const updatedStartDate = !startDateState ? startDateProp : startDateState

    return {
      inputValue: isFocused
        ? inputValue
        : moment.tz(date, format, timezone).format(format),
      startDate: newStartDate || updatedStartDate,
      hasErr: !newStartDate && newDate != '',
      errorMessage: !newStartDate
        ? i18next.t('components:datepickerInput>EnterValidDateFormat', {
            format
          })
        : '',
      disableBeforeGivenDate,
      disableAfterGivenDate
    }
  }

  static parseDate(value, { format, timezone }) {
    return moment(value, format, true).isValid()
      ? moment.tz(value, format, timezone)
      : null
  }

  static isDateFormatCorrect(value, format) {
    return !moment(value, format, true).parsingFlags().unusedTokens.length
  }

  handleChange = (...allArgs) => {
    const event = allArgs[0]
    this.setState({
      inputValue: event.target.value
    })
  }

  handleDatePickerChange = (momentDate) => {
    const { timezone, format, onChange } = this.props
    const dateClickedMoment = moment.tz(momentDate, timezone)
    const value = dateClickedMoment ? dateClickedMoment.format(format) : ''
    this.setState({
      inputValue: value,
      startDate: dateClickedMoment
    })
    onChange(dateClickedMoment)
  }

  handleBlur = (event) => {
    const { hasErr, startDate } = this.state

    this.setState({
      isFocused: false
    })

    hasErr ? this.resetInputValue() : this.props.onChange(startDate, event)
  }

  handleFocus = () => {
    this.setState({
      isFocused: true
    })
  }

  resetInputValue = () => {
    const { date, timezone, format } = this.props
    this.setState({
      hasErr: false,
      errorMessage: '',
      inputValue: moment.tz(date, format, timezone).format(format)
    })
  }

  handleKeyPress = (event) => {
    const { hasErr, startDate } = this.state
    event.key === 'Enter' && !hasErr && this.props.onChange(startDate, event)
  }

  render = () => {
    const {
      date,
      timezone,
      selectMonth,
      inputDate,
      onChange,
      isClock,
      disabled,
      format,
      outerErrorMessage,
      outerError,
      className,
      disablePast,
      loading,
      disableBeforeGivenDate,
      disableAfterGivenDate
    } = this.props
    const { inputValue, hasErr, errorMessage, startDate } = this.state
    return (
      <div className={className}>
        <div
          className={classNames('datepicker-input-react', {
            'datepicker-input-react--disabled': disabled
          })}
        >
          <div className="react-picker-input">
            <input
              className={classNames('datepicker-input', {
                hasErr: hasErr || Boolean(outerErrorMessage) || Boolean(outerError),
                disabled
              })}
              type="text"
              onChange={this.handleChange}
              disabled={disabled || selectMonth}
              value={date ? inputValue : ''}
              onFocus={this.handleFocus}
              id={inputDate}
              onBlur={this.handleBlur}
              onKeyPress={this.handleKeyPress}
            />
          </div>
          <DatePicker
            handleRangeChange={this.handleDatePickerChange}
            initialRange={{
              start: moment.tz(startDate, format, timezone) || moment(),
              end: moment.tz(startDate, format, timezone) || moment()
            }}
            rangeSelection={{ past: false, future: false }}
            timezone={timezone}
            isClock={isClock}
            disablePast={disablePast}
            loading={loading}
            selectMonth={selectMonth}
            update={onChange}
            inputDate={inputDate}
            disableBeforeGivenDate={disableBeforeGivenDate}
            disableAfterGivenDate={disableAfterGivenDate}
            disabled={disabled}
          />
        </div>
        {(hasErr || Boolean(outerErrorMessage)) && (
          <span className="react-picker-input-txt hasErr">
            {errorMessage || outerErrorMessage}
          </span>
        )}
      </div>
    )
  }
}

export default DatePickerInput
