import React, { useEffect } from "react"
import * as d3 from "d3"
import ResponsiveWrapper from "../helpers/ResponsiveWrapper"
import { useBreakPoint } from 'src/hooks/breakPoint'

export const PieChart = props => {
	const {
		data, 
		width: w,
		height: h,
		valueFormatter,
		parentWidth,
		labelFontSize,
		isLabelWeight = false,
		id
	} = props

	const width = w ? w : parentWidth
	const height = h ? h : useBreakPoint()?.width > 1200 ? 390 : parentWidth

	useEffect(() => {
		const totalValue = data && data.length > 0 && data.reduce((acc, curr) => {
			acc += curr.value
			return acc
		}, 0)
		const dataWithCalculatedPercents = data && data.map(d => ({ ...d, percent: (d.value / totalValue) * 100 }))
		drawChart(dataWithCalculatedPercents, width, (dataWithCalculatedPercents.length - (dataWithCalculatedPercents.filter(d => d.percent < 10).length)))
	}, [data, width])

	function drawChart(dataWithCalculatedPercents, width, indexLessThan8) {
		// Remove the old svg
		d3.select(`#pie-container${id ? id : ""}`)
			.select("svg")
			.remove()

		// Create new svg
		const svg = d3
			.select(`#pie-container${id ? id : ""}`)
			.append("svg")
			.style("overflow", "visible")
			.attr("width", width)
			.attr("height", height)
			.append("g")
			.attr("transform", `translate(${width / 2}, ${height / 2})`)

		const arcGenerator = d3
			.arc()
			// Can add if we want to make an arc
			.innerRadius(0)
			.outerRadius(width / 5)

		const pieGenerator = d3
			.pie()
			.padAngle(0)
			.value(d => d.value)

		const arc = svg
			.selectAll()
			.data(pieGenerator(dataWithCalculatedPercents))
			.enter()

		// Append arcs
		arc
			.append("path")
			.attr("d", arcGenerator)
			.style("fill", d => d.data.color)
			.style("stroke", "#ffffff")
			.style("stroke-width", 1)

		// Append text labels
		arc
			.append("text")
			.attr("transform", d => {
				const c = arcGenerator.centroid(d),
					x = c[0],
					y = c[1],
					// pythagorean theorem for hypotenuse
					h = Math.sqrt(x * x + y * y)
				return `translate(${((x / h * ((width / 4) + 15)) + ((d.endAngle + d.startAngle) / 2 > Math.PI ? -25 : 25)) + Number(d.data.percent < 10 ? (indexLessThan8 - (d.index)) * (labelFontSize ? parseInt(labelFontSize, 10) + 3 : 15) : 0)},${
					(y / h * ((width / 4) + 5)) + Number(d.data.percent < 10 ? -(d.index - indexLessThan8) * (labelFontSize ? parseInt(labelFontSize, 10) + 3 : 15) : 0)})`
			})
			.attr("text-anchor", d =>
				// are we past the center?
				(d.endAngle + d.startAngle) / 2 > Math.PI
					? "end" : "start"
			)
			.attr("font-size", labelFontSize ? labelFontSize : "12px")
			.attr("font-weight", isLabelWeight ? "600" : "normal")
			.text(d => d.data.percent < 10 ? `${d.data.label} \xa0 ${typeof valueFormatter === "function" ? valueFormatter(d.value) : d.value} \xa0 ${`${Number(d.data.percent).toFixed(2)}%`}` : d.data.label)
			.append("tspan")
			.attr("x", "0")
			.attr("dy", "1em")
			.text(d => d.data.percent < 10 ? "" : typeof valueFormatter === "function" ? valueFormatter(d.value) : d.value)
			.append("tspan")
			.attr("x", "0")
			.attr("dy", "1em")
			.text(d => d.data.percent < 10 ? "" : `${Number(d.data.percent).toFixed(2)}%`)
		arc.append("line")
			.style("stroke", "black")
			.style("stroke-width", 1)
			.attr("x1", d => {
				const c = arcGenerator.centroid(d),
					x = c[0],
					y = c[1],
					h = Math.sqrt(x * x + y * y)
				return c[0] + ((x / h * ((width / 6) + 10) - c[0]) / 2)
			})
			.attr("y1", d => {
				const c = arcGenerator.centroid(d),
					x = c[0],
					y = c[1],
					h = Math.sqrt(x * x + y * y)
				return c[1] + ((y / h * ((width / 6) + 10) - c[1]) / 2)
			})
			.attr("x2", d => {
				const c = arcGenerator.centroid(d),
					x = c[0],
					y = c[1],
					h = Math.sqrt(x * x + y * y)
				return x / h * ((width / 4) + 5)
			})
			.attr("y2", d => {
				const c = arcGenerator.centroid(d),
					x = c[0],
					y = c[1],
					h = Math.sqrt(x * x + y * y)
				return (y / h * ((width / 4) + 5)) + Number(d.data.percent < 10 ? (indexLessThan8 - d.index) * (labelFontSize ? parseInt(labelFontSize, 10) + 3 : 15) : 0)
			})
		arc.append("line")
			.style("stroke", "black")
			.style("stroke-width", 1)
			.attr("x1", d => {
				const c = arcGenerator.centroid(d),
					x = c[0],
					y = c[1],
					h = Math.sqrt(x * x + y * y)
				return x / h * ((width / 4) + 5)
			})
			.attr("y1", d => {
				const c = arcGenerator.centroid(d),
					x = c[0],
					y = c[1],
					h = Math.sqrt(x * x + y * y)
				return (y / h * ((width / 4) + 5)) + Number(d.data.percent < 10 ? (indexLessThan8 - d.index) * (labelFontSize ? parseInt(labelFontSize, 10) + 3 : 15) : 0)
			})
			.attr("x2", d => {
				const c = arcGenerator.centroid(d),
					x = c[0],
					y = c[1],
					h = Math.sqrt(x * x + y * y)
				return ((x / h * ((width / 4) + 10)) + ((d.endAngle + d.startAngle) / 2 > Math.PI ? -25 : 25)) + Number(d.data.percent < 10 ? (indexLessThan8 - (d.index)) * (labelFontSize ? parseInt(labelFontSize, 10) + 3 : 15) : 0)
			})
			.attr("y2", d => {
				const c = arcGenerator.centroid(d),
					x = c[0],
					y = c[1],
					h = Math.sqrt(x * x + y * y)
				return (y / h * ((width / 4) + 5)) + Number(d.data.percent < 10 ? (indexLessThan8 - d.index) * (labelFontSize ? parseInt(labelFontSize, 10) + 3 : 15) : 0)
			})
	}

	return <div id={`pie-container${id ? id : ""}`} />
}

export default ResponsiveWrapper(PieChart)
