import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class PaginationPage extends Component {
  static propTypes = {
    displayOffsets: PropTypes.array,
    currentOffset: PropTypes.number,
    className: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    displayOffsets: [],
    currentOffset: null,
    className: ''
  }

  handleItemClick = (offset) => () => {
    const { onChange, currentOffset } = this.props
    if (currentOffset !== offset) {
      onChange(offset)
    }
  }

  render() {
    const { displayOffsets, currentOffset, className } = this.props
    return (
      <div className={className}>
        {displayOffsets &&
          displayOffsets.length > 1 &&
          displayOffsets.map((item, index, offsets) => (
            <span key={index}>
              {index > 0 &&
                offsets[index - 1].offsetValue != item.offsetValue - 1 && (
                  <span className="pagination-control">...</span>
                )}
              <span
                className={classNames('pagination-control', {
                  'current-offset': item.offsetValue === currentOffset
                })}
                onClick={this.handleItemClick(item.offsetValue)}
              >
                {item.label}
              </span>
            </span>
          ))}
      </div>
    )
  }
}

export default PaginationPage
