import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import slideToggle from "src/components/legacy/components/decorators/slideToggle"
import { ConsultationToggleBoxSection } from "./consultation-toggle-box-new"
import "./consultation-toggle-box-new.ts"

class ConsultationToggleBox extends PureComponent {
	static propTypes = {
		children: PropTypes.node,
		onChangeToggleButton: PropTypes.func,
		onChangeTitle: PropTypes.func,
		checkedToggleButton: PropTypes.bool,
		t: PropTypes.func.isRequired,
		translationLabel: PropTypes.string.isRequired,
		title: PropTypes.string,
		showExpandIcon: PropTypes.bool,
		errors: PropTypes.object,
		customClass: PropTypes.string
	}

	static defaultProps = {
		opened: true,
		showExpandIcon: true,
		checkedToggleButton: false,
		title: "",
		errors: undefined,
	}

	render() {
		const { children, showExpandIcon, title, customClass } = this.props
		const { opened, height, overflow } = this.state

		return 	<ConsultationToggleBoxSection className={`consultation-toggle-box ${opened ? "expanded" : ""}`}>
			<div className="consultation-toggle-header" data-testid="consultation-toggle">
				<label className="consultation-label">
					{showExpandIcon && <div className="icon-expand" onClick={this.toggleBox} />}
					{<span className="label">{title}</span>}
				</label>
			</div>
			<fieldset
				style={{ height, overflow }}
				ref={el => this.toggleBoxBody = el}
				className="consultation-body"
			>
				{children && <div className={`consultation-body-inner ${customClass ? customClass : ""}`}>{children}</div>}
			</fieldset>
		</ConsultationToggleBoxSection>
	}
}

// variable SlideToggleConsultationToggleBox needs for testing
export const SlideToggleConsultationToggleBox = slideToggle(ConsultationToggleBox)

export default (SlideToggleConsultationToggleBox)
