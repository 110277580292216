import PropTypes from 'prop-types'
import { Image } from '@react-pdf/renderer'
import PDFBlockWrapper from '../pdf-block-wrapper'
import { CHART } from '../../chart-image/common'
import PDFImage from '../../../../../components/print-components/pdf-images-block/image'

const PdfSavedBeenDoneBlocks = ({
  chartImage,
  image,
  title,
  blockName,
  mode
}) => {
  if (!chartImage && !image) {
    return <></>
  }
  const content =
    mode === CHART ? (
      <>{chartImage && <Image source={chartImage} />}</>
    ) : (
      <>{image && <PDFImage img={{ imageUrl: image }} withBorder={false} />}</>
    )

  return (
    <PDFBlockWrapper title={title || blockName}>
      {content}
    </PDFBlockWrapper>
  )
}

PdfSavedBeenDoneBlocks.propTypes = {
  mode: PropTypes.string,
  chartImage: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  blockName: PropTypes.string.isRequired
}

export default PdfSavedBeenDoneBlocks
