import Chart from './Chart'
import BaseSetup from 'src/chart-library/BaseSetup/BaseSetup'
import {LegendWrapper, TraneLegend, TraneActionText, IdentifiedLegend, IdentifiedActionText} from './styles'

const ActionTracking = (props: any) => {
  return (
    <>
      <BaseSetup props={props}>
        <Chart series={props?.series} />
      </BaseSetup>
      <LegendWrapper>
        <div>
          <TraneLegend />
          <TraneActionText>Trane Action</TraneActionText>
        </div>
        <div>
          <IdentifiedLegend />
          <IdentifiedActionText>Identified Action</IdentifiedActionText>
        </div>
      </LegendWrapper>
    </>
  )
}

export default ActionTracking
