import { formatCurrencyToStandardPrecision } from './standard-precision-format'

export const CURRENCY = new Map([['USD', 'USD - US Dollar']])

export function formatCurrencyName(id) {
  return CURRENCY.get(id) || id
}

export const CURRENCY_OPTION_LIST = Array.from(CURRENCY, ([key, value]) => ({
  key,
  value
}))

export function formatCurrency(value, currency = 'USD') {
  if (value || isFinite(value)) {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency,
      maximumFractionDigits: 20,
      minimumFractionDigits: 0
    })
  }
}

export const removeCurrencyLabel = (value = '') => value.replace(/\$|,/g, '')

export const formatCurrencyPrecision = (currency) =>
  formatCurrencyToStandardPrecision(currency)
