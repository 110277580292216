export const MARGIN = {
  left: 1
}

export const HEIGHT = 55

export const axisTopTickHeight = 18

export const axisBottomTickHeight = 8

export const axisLabelHeight = 32

export const axisTickStrokeWidth = 1

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
