import {
    SCALE_BAND,
    SCALE_LINEAR,
    SCALE_TIME
  } from 'src/chart-library/Utils/Scales/constant'
  import { scaleTimePadding } from './scaleTime'
  import { scaleBandPadding } from './scaleBand'
  import { scaleLinearPadding } from './scaleLinear'
  
  export const ticksTextPaddingPicker = {
    [SCALE_TIME]: scaleTimePadding,
    [SCALE_BAND]: scaleBandPadding,
    [SCALE_LINEAR]: scaleLinearPadding
  }
  