import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ErrorDialog from 'src/components/legacy/components/error-dialog/error-dialog.jsx'
import { DebounceInput } from 'src/components/legacy/components/debounce-input'
import { transformEQViewDetailsData } from '../EquipmentDetailsParser'
import {
  GET_SEARCH_EQUIPMENTS,
  LIST_EQUIPMENTS_BY_DEVICE_AND_BY_EQUIPMENT_TYPE,
  LIST_EQUIPMENTS_BY_DEVICE_AND_BY_EQUIPMENT_TYPE_NIAGARA
} from '../../graphql/queries'
import {
  UPDATE_EQUIPMENT,
  COPY_CHARACTER_DATA_MAP
} from '../../graphql/mutations'
import { EQUIPMENT_NAME_MAX_LENGTH } from '../constants'
import '../Characteristics/Characteristics-legacyStyle.scss'
import colors from 'src/components/legacy/common/colors.json'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import Label from 'src/components/legacy/components/label/label'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import { trackEvent } from '../../../../amplitude'
import { USER_EVENTS } from '../../../../amplitude-categories/findings/user-events'
import { DETAILS_TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import translate, { TranslateComponent } from '../../../../common/translations'

const NONE = 'None'
const DefinitionTab = styled.div`
  .definition-value {
    margin-top: 5px;
  }
`
const ErrorDiv = styled.div`
  .input-label {
    ${(props) =>
      props.error &&
      `color: ${colors.RED}
   `}
  }
  .input-field {
    ${(props) =>
      props.error &&
      `border-color: ${colors.RED};
			background:${colors.RED_5};
			border-width: 2px;
		`}
  }
`
const CopyFrom = styled.div`
  padding-left: 20px;
  width: 50%;
`

Definitions.propTypes = {
  equipment: PropTypes.object,
  refetchEquipmentById: PropTypes.func
}
let isEquipmentNameExist = true

export default function Definitions({ equipment, refetchEquipmentById ,refetchCharacteristics}) {
  const [t] = useTranslation()
  const transformedData = transformEQViewDetailsData(equipment)
  const {
    equipmentName,
    equipmentType,
    automationSystem,
    dataSourceType,
    dataSourceName,
    buildingId,
    id,
    type,
    serial,
    deviceId,
    copyMappingEquipmentId
  } = transformedData.EQDetails
  const [editableValues, setEditableValues] = useState({
    equipmentName: equipmentName || '',
    copyFromOption: false,
    copyFromEquipmentId: null,
    copyFromEquipmentName: null
  })
  const [isErrorDialogOpened, toggleErrorDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [equipErrorMessage, setEquipErrorMessage] = useState('')
  const [isFormValid, setFormValid] = useState(true)
  const isNAX = equipment?.device?.type === "NAX"
  useEffect(() => {
    trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.VIEW_EQUIPMENT_DEFINITION)
  },[])
  const isUpdateButtonEnabled =
    equipmentName !== editableValues.equipmentName ||
    (editableValues.copyFromOption &&
      (editableValues.copyFromEquipmentId !== null || (isNAX && editableValues.niagaraCopyFromEquipmentId)))

  const { onSubmit: updateEquipment, loading: updateEquipmentInProcess } =
    useMutation({
      query: UPDATE_EQUIPMENT,
      dataPath: 'data.updateEquipment'
    })
  const { onSubmit: onUpdateCopyCharacteristicDataMapping } = useMutation({
    query: COPY_CHARACTER_DATA_MAP,
    dataPath: 'data.copyEquipmentDataMapping'
  })
  const { refetch: refetchEquipmentName } = useQuery({
    query: GET_SEARCH_EQUIPMENTS,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchEquipment'
  })

  const { data: equipmentData } = useQuery({
    query: LIST_EQUIPMENTS_BY_DEVICE_AND_BY_EQUIPMENT_TYPE,
    variables: {
      deviceId: deviceId,
      dataSourceType: dataSourceType,
      type: type,
      id: id
    },
    errorPolicy: 'all',
    dataPath: 'data.listEquipmentsByDeviceAndByEquipmentType'
  })

  const { data: niagaraEquipmentData } = useQuery({
    query: LIST_EQUIPMENTS_BY_DEVICE_AND_BY_EQUIPMENT_TYPE_NIAGARA,
    variables: {
      deviceId: deviceId,
      type: type,
    },
    disableInitialLoad: !isNAX,
    errorPolicy: 'all',
    dataPath: 'data.listEquipmentsByDeviceAndByEquipmentType'
  })

  const copyFromOptions = useMemo(() => {
    if (equipmentData) {
      const copyMappingEquipment = equipmentData?.items
      .filter((filterData) => filterData.id !== id)
      .map((i) => {
        return { key: i.id, label: i.name }
      })
      const filterCopyMappingEquipment = copyMappingEquipment?.filter((item) => {
        return item.key === copyMappingEquipmentId
      })

      setEditableValues({
        ...editableValues,
        copyFromEquipmentId: filterCopyMappingEquipment[0]?.key,
        copyFromEquipmentName: filterCopyMappingEquipment[0]?.key
      })
      
      return equipmentData?.items
        .filter((filterData) => filterData.id !== id)
        .map((i) => {
          return { key: i.id, label: i.name }
        })
    }
  }, [equipmentData, id, dataSourceType, type])

  const niagaraCopyFromOptions = useMemo(() => {
    if (niagaraEquipmentData && isNAX) {
      const copyMappingEquipment = niagaraEquipmentData?.items
      .filter((filterData) => filterData.id !== id)
      .map((i) => {
        return { key: i.id, label: i.name }
      })
      const filterCopyMappingEquipment = copyMappingEquipment?.filter((item) => {
        return item.key === copyMappingEquipmentId
      })
      setEditableValues(currentValues => ({
        ...currentValues,
        niagaraCopyFromEquipmentId: filterCopyMappingEquipment[0]?.key,
        niagaraCopyFromEquipmentName: filterCopyMappingEquipment[0]?.key
      }))
      
      return niagaraEquipmentData?.items
        .filter((filterData) => filterData.id !== id)
        .map((i) => {
          return { key: i.id, label: i.name }
        })
    }
  }, [niagaraEquipmentData, id, type])

  const handleCloseErrorDialog = useCallback(() => {
    setErrorMessage('')
    toggleErrorDialog(!isErrorDialogOpened)
  }, [setErrorMessage, toggleErrorDialog, isErrorDialogOpened])

  const handleEquipmentName = (ev) => {
    const input = ev.target.value
    let isValid = true;
    setEditableValues({ ...editableValues, equipmentName: input })
    if(!input?.trim()){
      isValid = false;
      setFormValid(isValid)
      setEquipErrorMessage(
        t('errors:thisFieldIsRequired', {
          field: t('equipment-setup:EquipmentName')
        })
      )
      return;
    }
     isValid = !(input.length > EQUIPMENT_NAME_MAX_LENGTH)
    if (!isValid) {
      setEquipErrorMessage(
        t('errors:maxAllowedFieldLength', {
          field: t('equipment-setup:EquipmentName'),
          number: EQUIPMENT_NAME_MAX_LENGTH
        })
      )
    } else {
      setEquipErrorMessage('')
    }
    setFormValid(isValid)
  }

  const checkEquipmentNameExistOnSave = async () => {
    const checkEquipmentNameExistList = await refetchEquipmentName({
      buildingId: buildingId,
      name: editableValues?.equipmentName,
      limit: 1
    })
    if (
      checkEquipmentNameExistList?.total !== null &&
      checkEquipmentNameExistList?.total > 0
    ) {
      setErrorMessage('TIS equipment name already exists')
      isEquipmentNameExist = true
      toggleErrorDialog(!isErrorDialogOpened)
    } else {
      isEquipmentNameExist = false
    }
    return isEquipmentNameExist
  }
  const handleUpdateEquipmentName = async (equipmentName) => {
    let equipmentResponse
    await updateEquipment({
      input: {
        id: id,
        name: equipmentName
      }
    }).then((resData) => {
      equipmentResponse = resData
    })
    trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.EDIT_EQUIPMENT_DEFINITION, {"equipment id":id, "new name":equipmentName, type:"Equipment Name"})
    return equipmentResponse
  }
  const handleUpdateCopyCharacteristic = async (mutationData) => {
    let responseData
    await onUpdateCopyCharacteristicDataMapping({
      input: JSON.stringify(mutationData)
    }).then((res) => {
      responseData = res
    })
    console.log(mutationData,responseData)
    trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.EDIT_EQUIPMENT_DEFINITION, {"equipment id":mutationData.data.defaultEquipmentId, type:"Copy Characteristic"})
    return responseData
  }

  const handleRefetch = () => {
    refetchEquipmentById()
    refetchCharacteristics(true)
    setEditableValues({
      ...editableValues,
      copyFromOption: false,
      copyFromEquipmentId: null,
      copyFromEquipmentName: null,
      niagaraCopyFromEquipmentId: null,
      niagaraCopyFromEquipmentName: null,
    })
  }

  const onUpdateEquipment = async () => {
    const checkEquipmentNameExist =
      equipmentName !== editableValues?.equipmentName &&
      (await checkEquipmentNameExistOnSave())
    if (checkEquipmentNameExist) {
      return
    }
    (equipmentName !== editableValues?.equipmentName) && await handleUpdateEquipmentName(editableValues.equipmentName).then(
        (res) => {
          if (res?.id) {
            handleRefetch()
          } else {
            toggleErrorDialog(!isErrorDialogOpened)
          }
        }
      )
    let mutationBody = {
      Type: 'Update',
      data: {
        sourceEquipmentId: !isNAX ? editableValues?.copyFromEquipmentId : editableValues?.niagaraCopyFromEquipmentId,
        destinationEquipmentId: id,
        equipmentType: type,
        sourceDeviceId: serial,
        buildingId: buildingId,
        defaultEquipmentId: id
      }
    }
    editableValues?.copyFromOption &&
      (await handleUpdateCopyCharacteristic(mutationBody).then(async (response) => {
        if (response) {
          await updateEquipment({
            input: {
              id: id,
              copyMappingEquipmentId: !isNAX ? editableValues?.copyFromEquipmentId : editableValues?.niagaraCopyFromEquipmentId
            }
          })
          handleRefetch()
        } else {
          toggleErrorDialog(!isErrorDialogOpened)
        }
      }))
  }

  const optionsTranslated = {
    selectOne: "--"+translate("Select One")+"--",
    findAnEquipment: translate("Find an Equipment...")
  }

  return (
    <DefinitionTab data-testid={`${DETAILS_TEXT_NAME_PREFIX("Definitions")}`}>
      <div className="rx-grid rx-grid-small-spacing rx-grid-fit rx-justify-end">
        <button
        data-testid={`${DETAILS_TEXT_NAME_PREFIX("Definitions_save_button")}`}
          disabled={
            !isUpdateButtonEnabled || !isFormValid || updateEquipmentInProcess
          }
          className="primary"
          onClick={onUpdateEquipment}
        >
          <TranslateComponent>Save</TranslateComponent>
        </button>
      </div>
      {
        <div className="rx-grid rx-grid-small-spacing rx-grid-fit">
          <div className="rx-col-6">
            <div className="rx-grid rx-grid-small-spacing rx-grid-fit">
              <div className="rx-col-12">
             <label><TranslateComponent>{"TIS Equipment Type"}</TranslateComponent>:</label>
                <div className="definition-value">{equipmentType}</div>
              </div>
              <div className="rx-col-12">
                <label><TranslateComponent>{"Default Automation System"}</TranslateComponent>:</label>
                <div className="definition-value">
                  {automationSystem ? automationSystem : NONE}
                </div>
              </div>
              <div className="rx-col-12">
                <label><TranslateComponent>{"Default Data Source Type"}</TranslateComponent>:</label>
                <div className="definition-value">
                  {dataSourceType ? dataSourceType : NONE}
                </div>
              </div>
            </div>
          </div>

          <div className="rx-col-6">
            <div className="rx-grid rx-grid-small-spacing rx-grid-fit">
              <div className="rx-col-12">
                <label><TranslateComponent>{"Default Data Source"}</TranslateComponent>:</label>
                <div className="definition-value">
                  {dataSourceName ? dataSourceName : <TranslateComponent>{NONE}</TranslateComponent>}
                </div>
              </div>
              <div className="rx-col-12">
                <ErrorDiv
                  error={
                    !editableValues.equipmentName?.trim() || editableValues.equipmentName?.trim().length >
                    EQUIPMENT_NAME_MAX_LENGTH
                  }
                >
                  <label className="input-label">
                    <TranslateComponent>{"Equipment Name"}</TranslateComponent>:
                  </label>
                  <div className="definition-value">
                    <DebounceInput
                      value={editableValues.equipmentName}
                      className="input-field"
                      onChange={(event) => handleEquipmentName(event)}
                      debounceTimeout={300}
                      type="text"
                    />
                    <div className="input-label"><TranslateComponent>{equipErrorMessage}</TranslateComponent></div>
                    {/* {equipmentName} */}
                  </div>
                </ErrorDiv>
              </div>
              <div className="rx-col-12">
                <Checkbox
                  label={t('equipment-setup:CopyCharacteristicsAndDataMap')}
                  id="copy-characteristics"
                  isChecked={editableValues.copyFromOption}
                  toggleCheck={() => {
                    setEditableValues({
                      ...editableValues,
                      copyFromOption: !editableValues.copyFromOption
                    })
                  }}
                  disabled={!(!isNAX ? copyFromOptions : niagaraCopyFromOptions)?.length}
                />
                <CopyFrom>
                  <Label text={t('equipment-setup:FromEquipment')} />
                  <SelectrixWrapper
                    className="selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select"
                    onChange={({ key }) => {
                      setEditableValues({
                        ...editableValues,
                        copyFromEquipmentId: !isNAX ? key : undefined,
                        copyFromEquipmentName: !isNAX ? key : undefined,
                        niagaraCopyFromEquipmentId: isNAX ? key : undefined,
                        niagaraCopyFromEquipmentName: isNAX ? key : undefined
                      })
                    }}
                    options={!isNAX ? copyFromOptions : niagaraCopyFromOptions}
                    placeholder={optionsTranslated.selectOne}
                    inputPlaceholder={optionsTranslated.findAnEquipment}
                    searchable={false}
                    defaultValue={!isNAX ? editableValues.copyFromEquipmentName : editableValues.niagaraCopyFromEquipmentName}
                    disabled={!editableValues.copyFromOption}
                  />
                </CopyFrom>
              </div>
            </div>
          </div>
        </div>
      }
      {isErrorDialogOpened && (
        <ErrorDialog
          title={t('errors:Error')}
          handleClose={handleCloseErrorDialog}
          text={errorMessage || t('errors:unexpected-error')}
        />
      )}
    </DefinitionTab>
  )
}
