import { clone } from 'src/components/legacy/common/helpers'
import { WIDGET_TYPES } from 'src/constants'


const { 
  WEATHER,
  IMAGE_TEXT,
  CUSTOM_KPI,
  LINE,
  GAUGE,
  BAR,
  SINGLE_COMPARISON_BAR,
  DUAL_COMPARISON_BAR,
  ORB,
  ENERGY_CONSUMPTION,
  ENERGY_CONSUMPTION_NEW,
  ENERGY_COST,
  ENERGY_DEMAND,
  ENERGY_STAR_SCORE
} = WIDGET_TYPES

const templateList = (t) => [
  {
    name: LINE,
    title: "Trends Line Chart",
    description: "View trends for a meter or piece of equipment in a line chart. Overlay previous time period, if desired.",
    widgetCategory: "Custom Equipment Widget"
  },
  {
    name: BAR,
    title: "Trends Bar Chart",
    description: "View trends for a meter or piece of equipment in a bar chart. Display benchmark or target marker, if desired.",
    widgetCategory: "Custom Equipment Widget"
  },
  {
    name: SINGLE_COMPARISON_BAR,
    title: "Time Comparison Bar Chart",
    description: "Compare a meter or piece of equipment to itself over time in a bar chart. Display benchmark or target marker, if desired.",
    widgetCategory: "Custom Equipment Widget"
  },
  {
    name: DUAL_COMPARISON_BAR,
    title: "Data Comparison Bar Chart",
    description: "Compare two meters or pieces of equipment to each other a bar chart. Display benchmark or target marker, if desired.",
    widgetCategory: "Custom Equipment Widget"
  },
  {
    name: ENERGY_CONSUMPTION,
    title: "Baseline Comparison",
    description: "View actual energy usage compared to predicted energy usage. Highlight energy savings or waste.",
    widgetCategory: "Energy Widget"
  },
  {
    name: ENERGY_CONSUMPTION_NEW,
    title: "Energy Consumption",
    description: "Compare the energy consumed by a location to itself over time in a bar chart. Display benchmark or target marker if desired.",
    widgetCategory: "Energy Widget"
  },
  {
    name: ENERGY_COST,
    title: "Energy Cost",
    description: "Compare the cost of energy consumed by a location or meter to previous time periods. Display benchmark or target marker if desired.",
    widgetCategory: "Energy Widget"
  },
  {
    name: ENERGY_DEMAND,
    title: "Energy Demand",
    description: "Compare the energy demand to the maximum demand reading for a selected time period.",
    widgetCategory: "Energy Widget"
  },
  {
    name: GAUGE,
    title: "Data Monitoring Gauge",
    description: "View data for a meter or piece of equipment in a gauge. Highlight thresholds to monitor performance.",
    widgetCategory: "Custom Equipment Widget"
  },
  {
    name: ORB,
    title: "Data Monitoring Orb",
    description: "View data for a meter or piece of equipment in an orb. Highlight thresholds to monitor performance.",
    widgetCategory: "Custom Equipment Widget"
  },
  {
    name: WEATHER,
    title: "Weather",
    description: "View the current weather and temperature forecast for a location.",
    widgetCategory: "Weather"
  },
  {
    name: IMAGE_TEXT,
    title: "Image/Text Link",
    description: "View image and/or text. Link to a Trane Connect page or to another website.",
    widgetCategory: "Image"
  },
  {
    name: ENERGY_STAR_SCORE,
    title: "Energy Star Score",
    description: "View the Energy Star score for a location. Measure a location's energy efficiency against comparable sized locations.",
    widgetCategory: "Energy Widget"
  },
  {
    name: CUSTOM_KPI,
    title: "Custom KPI",
    description: "Widget that highlights specific benefits of using Trane offerings.",
    widgetCategory: "Custom Equipment Widget"
  }
]

export default {
  getAll(t) {
    return templateList(t)
  },

  getSelectBoxList(t) {
    return clone(templateList(t)).sort((a, b) =>
      a.description.localeCompare(b.description)
    )
  }
}
