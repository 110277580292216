import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'

import PDFHeader from '../../../../components/print-components/pdf-header'
import PDFFooter from '../../../../components/print-components/pdf-footer'
import PDFContact from '../../../../components/print-components/pdf-contact'
import PDFSummary from '../../../../components/print-components/pdf-summary/pdf-summary'
import PDFReportTitle from '../../../../components/print-components/pdf-report-title'
import { View, Image, Text, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  pieImage: { width: '340px', height: '170px' },
  euiBarImage: { width: '60%', height: '190px' },
  eciBarImage: { width: '60%', height: '190px' },
  summary: { width: '260px' },
  content: { flexDirection: 'row', alignItems: 'center' },
  impact: {
    margin: '10px 10px 0 0',
    width: '175px',
    height: '100px',
    fontSize: '12px'
  },
  chartTitle: { width: '50%', fontSize: '12px', fontWeight: 'bold' }
})

export const PDFEnergyUsageReport = ({
  report: {
    title,
    startDate,
    endDate,
    summarySection,
    chartSection,
    locationName,
    organizationName,
    phoneNumber,
    contact,
    email,
    pieImgURL,
    bar1ImgURL,
    bar2ImgURL,
    impact1ImgURL,
    impact2ImgURL,
    impact3ImgURL
  },
  isFooterCount
}) => {
  const parsedSummary: any = JSON.parse(summarySection?.summary)
  const summary = useMemo(
    () => ({
      title: summarySection?.summaryTitle || '',
      text:
        typeof parsedSummary === 'string'
          ? parsedSummary
          : parsedSummary?.blocks.length
          ? parsedSummary?.blocks
          : ''
    }),
    [parsedSummary]
  )
  const dates = useMemo(
    () =>
      `${moment(startDate).format(DATE_FORMAT)} - ${moment(endDate).format(
        DATE_FORMAT
      )}`,
    [startDate, endDate]
  )
  const EUITitle = chartSection?.barChartData?.euiTitle
  const ECITitle = chartSection?.barChartData?.eciTitle

  return (
    <>
      <PDFHeader
        isFixed={true}
        locationNames={[locationName]}
        organizationName={organizationName}
        itemName={title}
      />
      <PDFReportTitle name={title} dates={dates} />
      <View style={styles.content}>
        {chartSection?.pieChartData?.showPieChart && (
          <PDFSummary summary={summary} />
        )}
        {pieImgURL && <Image src={pieImgURL} style={styles.pieImage} />}
      </View>
      <View style={styles.content}>
        {EUITitle && bar1ImgURL && (
          <Text style={styles.chartTitle}>{EUITitle}</Text>
        )}
        {ECITitle && bar2ImgURL && (
          <Text style={styles.chartTitle}>{ECITitle}</Text>
        )}
      </View>
      <View style={styles.content}>
        {bar1ImgURL && <Image src={bar1ImgURL} style={styles.euiBarImage} />}
        {bar2ImgURL && <Image src={bar2ImgURL} style={styles.eciBarImage} />}
      </View>
      <View style={styles.content}>
        {impact1ImgURL && <Image src={impact1ImgURL} style={styles.impact} />}
        {impact2ImgURL && <Image src={impact2ImgURL} style={styles.impact} />}
        {impact3ImgURL && <Image src={impact3ImgURL} style={styles.impact} />}
      </View>
      <PDFContact phone={phoneNumber} contact={contact} email={email} />
      <PDFFooter isCountPage={isFooterCount} />
    </>
  )
}

PDFEnergyUsageReport.propTypes = {
  isFooterCount: PropTypes.bool,
  report: PropTypes.shape({
    organization: PropTypes.string,
    location: PropTypes.string,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    summarySection: PropTypes.object,
    chartSection: PropTypes.object,
    contact: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string
  }).isRequired
}

export default PDFEnergyUsageReport
