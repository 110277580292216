import { useQuery } from './APIHooks'
import {useState } from 'react'

export const usePaginateQuery = ({
    query,
    variables,
  errorPolicy,
    dataPath
}) => {
const [data,setData] = useState([])
const [nextToken,setDataNextToken] = useState()
const {
  refetch:refetchData,
   loading
} = useQuery({
  query,
  disableInitialLoad: true,
  errorPolicy: errorPolicy || 'all',
  dataPath
})

    const getData = async ({ refetchVariables, nextToken }) => {
        const data = await refetchData({ ...refetchVariables, nextToken })
        if (Array.isArray(data?.items)) {
          if (data.nextToken !== null) {
            setData((Data) => [...Data, ...data.items])
            setDataNextToken(data.nextToken)
            getData({ refetchVariables, nextToken: data.nextToken })
          } else {
            setData((Data) => [...Data, ...data.items])
            setDataNextToken(data.nextToken)
          }
        }
      }
      const refetch = (refetchVariables) => {
        setData([])
      getData({...(refetchVariables ? {refetchVariables} :  {refetchVariables : variables}),nextToken})
      }

      return {refetch,loading,data}

}
