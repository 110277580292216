export const SALES_OFFICES = 'salesOffices'
export const ALL_SALES_OFFICES = 'allSalesOffices'
export const ORGANIZATIONS = 'organizations'
export const LOCATION = 'location'
export const LOCATIONS = 'locations'
export const ORGANIZATION = 'organization'
export const SALES_OFFICE = 'sales-office'
export const SUPER_ADMIN = "SuperAdmin"
export const SORT_BY = {
  SALES_OFFICES,
  ALL_SALES_OFFICES,
  ORGANIZATIONS,
  LOCATIONS
}

export default SORT_BY
