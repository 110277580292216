import { Button } from "@aws-amplify/ui-react";
import { TranslateComponent } from 'src/common/translations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindow } from 'src/denali-components/lib/pro-solid-svg-icons'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { faServer } from "@fortawesome/free-solid-svg-icons";
import styles from './device-connect.module.scss'

export const DeviceConnectActions = ({ row, selectedConnect, showServiceToolsOptions, openVPNAllowedTypes, serviceToolButtonHandler, nonSecClick }) => {
  const device = row.device;
  
  if(!device.remoteAccessStatus) {
    return (
      <Button
        className={styles.button}
        type="button"
        disabled={true}
        onClick={() => null}
        data-testid={`inactiveConnect-${device.id}`}
      >
        <TranslateComponent>Connect</TranslateComponent>
      </Button>
    )
  }

  if(device.type === 'SEC') {
    // See comment in src/pages/connectToADevice/index.jsx
    // at the time of migration the old component says: "We are yet to get requiremnet for Mobile App button click, it doesn't do anything as of now."
    return (
      <Button
        className={styles.button}
        type="button"
        disabled={true}
        onClick={() => null}
        data-testid={`mobileApp-${device.id}`}
      >
        <TranslateComponent>Mobile App</TranslateComponent>
      </Button>
    );
  }

  if(showServiceToolsOptions && openVPNAllowedTypes?.includes(device?.type)) {
    return (
      <>
    
        <Button
          className={styles.button}
          key={`${device.id}-connect-button-${ selectedConnect === device.id ? 'active' : 'inactive' }`}
          onClick={()=>nonSecClick(device)}
          data-testid={`scWeb-${device.id}`}
        >
          {
            selectedConnect === device.id
              ? <TranslateComponent>Connecting...</TranslateComponent>
              : <TranslateComponent>Connect</TranslateComponent>
          }
        </Button>
        <Button
          className={styles.button}
          data-testid={`serviceTools-${device.id}`}
          onClick={() => {
            serviceToolButtonHandler(device);
            trackEvent(USER_EVENTS.REMOTE_ACCESS.events.CLICK_CONNECT_SERVICE_TOOLS);
          }}
        >
          <TranslateComponent>Service Tools</TranslateComponent>
        </Button>
      </>
    )
  }

  return (
    <Button
      key={`${device.id}-connect-button-${ selectedConnect === device.id ? 'active' : 'inactive' }`}
      type="button"
      onClick={()=>nonSecClick(device)}
      data-testid={`scWeb-${device.id}`}
    >
      {
        selectedConnect === device.id
          ? <TranslateComponent>Connecting...</TranslateComponent>
          : <TranslateComponent>Connect</TranslateComponent>
        }
    </Button>
  )
}