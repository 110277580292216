import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { AlertWrapper, AlertIcon, AlertMessage } from './alert-notification.sc'

class AlertNotification extends PureComponent {
  static propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.string,
    className: PropTypes.string
  }
  static defaultProps = {}

  render() {
    const { className, message, icon } = this.props

    return (
      <AlertWrapper className={className}>
        {icon && <AlertIcon className={`icon icon-${icon}`} />}
        {message && <AlertMessage>{message}</AlertMessage>}
      </AlertWrapper>
    )
  }
}

export default AlertNotification
