import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './toolbar.scss'

export default class Toolbar extends PureComponent {
  static propTypes = {
    children: PropTypes.node
  }

  toggleToolbar = () => {
    // This is the best way I could think of to do this without interacting with the parent
    // In order to keep this component as standalone as possible
    Array.from(document.getElementsByClassName('wrapper')).map((e) =>
      e !== this.refs.wrapper && e.className.includes('expanded')
        ? (e.className = 'wrapper')
        : null
    )

    this.refs.wrapper.className = this.refs.wrapper.className.includes(
      'expanded'
    )
      ? 'wrapper'
      : 'wrapper expanded'
  }
  render() {
    return (
      <div className="main-react-mobile-toolbar">
        <div ref="wrapper" className="wrapper">
          <div className="toolbar-buttons">{this.props.children}</div>
          <button className="right-tab handle" onClick={this.toggleToolbar}>
            <span className="icon icon-menu" />
          </button>
        </div>
      </div>
    )
  }
}
