import i18next from "i18next"
import PropTypes from "prop-types"
import { StyleSheet, View, Text } from "@react-pdf/renderer"
import PDFTitle from "../pdf-header/pdf-title"
import PDFKPIBlock from "../pdf-kpi-block"
import PDFPriorityStatusBlock from "../pdf-priority-status-block"
import RichText from "../pdf-rich-text"
import PDFImagesBlock from "../pdf-images-block"
// import { FindingsTable } from "react/screens/findings/findings-list-page/blocks/findings-table"

export const styles = StyleSheet.create({
	findingWrapper: {
		maxWidth: "600px"
	},
})

const PDFFindingsBlock = ({ findings = [] }) => findings.length
	? <>
		{findings?.map(({ name, nextStep, assignedTo, priority, status, settings, images }, key) => 
		<View style={styles.findingWrapper} key={key} wrap={true}>
			<PDFTitle
				pageTitle="Issue/Finding"
				itemTitle={name}
				// breakProp={true} // Getting download issue when enable it
				/>
			<PDFKPIBlock kpi={settings?.kpi} />
			<RichText note={settings?.description} />
			{settings?.images?.length >= 1 && <PDFImagesBlock images={settings.images} />}
			{images?.length >= 1 && <PDFImagesBlock images={images} />}
			{/* <PDFPriorityStatusBlock assignedTo={FindingsTable?.getAssignedToUser((assignedTo || {}))} priority={priority} nextStep={`${i18next.t(`components:nextStepsFinding>${nextStep}`)}`} status={i18next.t(`components:findingStatuses>${status}`)} /> */}
			{key < (findings.length - 1) ? <Text>{"\n"}</Text> : null}
		</View>)}
	</>
	: null

PDFFindingsBlock.propTypes = {
	findings: PropTypes.array
}

export default PDFFindingsBlock
