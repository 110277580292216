import clsx from 'clsx'
import { scaleOrdinal } from 'd3'
import { useMemo, useRef } from 'react'

import { useTooltip } from '../Tooltip'
import { defaultTootipFormatter } from './stackedBarTooltip'
import StackedRect from './StackedRectUi'

import { StackedBarUiCompProps, Stacks } from './types'

const defaultColors = ['steelblue']

const defaultData: Stacks[] = []

export default function StackedBarUi(props: StackedBarUiCompProps) {
  const {
    barGap = 0,
    barsInACategory = 1,
    className,
    data = defaultData,
    isHorizontal = false,
    xScale,
    yScale,
    xPosition = 0,
    yPosition = 0,
    TextComp,
    textProps,
    textFormater,
    enableTooltip,
    tooltipDispatchEventName = '',
    tooltipFormatter = defaultTootipFormatter,
    hideBarText = false,
    tooltipTriggerPoint = 'item' // todo : decide the trigger point once tooltip is stable
  } = props

  const tooltipRef = useRef({})

  // --------------------- handle category axis domain values ---------------------

  // if cateogry axis doesnt have data, default index of series data will be used as category
  const category = useMemo(() => {
    if (!yScale || !xScale) return Array.from(Array(data.length), (_, i) => i)

    const domain = (isHorizontal ? yScale : xScale).domain()

    return domain.length ? domain : Array.from(Array(data.length), (_, i) => i)
  }, [yScale, xScale, isHorizontal, data])

  // --------------------- handle tooltip ---------------------

  const [tooltipDispatch] = useTooltip({
    dispatchEventName: tooltipDispatchEventName,
    disable: !enableTooltip,
    formatter: tooltipFormatter
  })

  // --------------------- render phase ---------------------
  const categoryAsKey = category.toString()

  return (
    <g
      className={clsx('t-chart-stackedbar', className)}
      transform={`translate(${xPosition || 0}, ${yPosition || 0})`}
    >
      {(data || []).map((stacks: Stacks, index) => {
        return (
          <g
            className="t-chart-stackedbar-row"
            key={'stackedbar-row-key-' + index + '-' + categoryAsKey}
          >
            {category.map((d, i) => {
              const stack = stacks[i]

              if (!stack) return null

              return (
                <StackedRect
                  tooltipRef={tooltipRef}
                  key={
                    'stackedbar-rect-key-' +
                    stack.max +
                    '-index-' +
                    stack.categoryIndex
                  }
                  column={stack}
                  xScale={xScale}
                  yScale={yScale}
                  barsCount={barsInACategory}
                  order={stack.dataIndex}
                  gap={barGap}
                  isHorizontal={isHorizontal}
                  TextComp={TextComp}
                  textProps={textProps}
                  textFormater={textFormater}
                  category={category}
                  tooltipDispatch={tooltipDispatch}
                  hideText={hideBarText}
                  tooltipTriggerPoint={tooltipTriggerPoint}
                />
              )
            })}
          </g>
        )
      })}
    </g>
  )
}

/* 

TODO : In future impl

Cacluation to support for inverted axis

 // Invert-y
              const x = xScale(d[0]);
              const y = yScale(axisD) as number;
              const width = x - xScale(d[1]);
              const height = yScale.bandwidth();


                <rect
                  data-value={d[1]}
                  key={cKey}
                  data-key={cKey}
                  x={x - width}
                  y={y}
                  width={width}
                  height={height}
                  fill={"currentColor"}
                />



*/
