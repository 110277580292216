import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import { withTranslation } from 'react-i18next'
import { Button } from 'src/components/inputs/button'
// components
import Table from 'src/components/layouts/Table'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip.jsx'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import AssociationDialog from './association-dialog'
import AssociationDetails from './association-details'
import { ACTIONS } from 'src/constants/actions'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { DETAILS_TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import { TranslateComponent } from '../../../../common/translations'

class AssociatedEquipment extends PureComponent {
  static propTypes = {
    equipment: PropTypes.object,
    isFetchingAssociation: PropTypes.bool,
    equipmentAssociations: PropTypes.any,
    deleteEquipmentAssociation: PropTypes.func,
    t: PropTypes.func,
    refetchAssociation: PropTypes.any,
    optionsTranslated: PropTypes.any,
    automationColumn: PropTypes.any
  }

  state = {
    mode: '',
    association: {},
    confirm: null,
    error: null,
    objectId: '',
    details: {}
  }

  defaultSort = {
    field: 'association',
    ascending: true
  }

  // get automationColumn() {
  //   return [
  //     {
  //       displayName: 'Association',
  //       sortable: true,
  //       key: 'association',
  //       name: 'association'
  //     },
  //     {
  //       displayName: 'Equipment type',
  //       sortable: true,
  //       key: 'unitTypePresentationName',
  //       name: 'unitTypePresentationName'
  //     },
  //     {
  //       displayName: 'TIS equipment',
  //       sortable: true,
  //       key: 'equipmentName',
  //       name: 'equipmentName'
  //     },
  //     { displayName: '', key: 'Action', name: 'Action', align: 'right' }
  //   ]
  // }

  getActionButton = (id) => {
    return (
      <>
        <Button
          type={'link'}
          onClick={() => this.switchModewithID(id.objectId, ACTIONS.VIEW)}
        >
          <TranslateComponent>View details</TranslateComponent>
        </Button>
        {/* <button style= {{"border" : "none", "background" : "none" }}onClick={() => this.switchModewithType(id.objectId,id, ACTIONS.EDIT)}> <span style={{fontSize: "13px"}} className="icon icon-edit"/></button> */}
        <ConditionalTooltip
          type={ConditionalTooltip.TYPE.ICON}
          align={ConditionalTooltip.ALIGN.CENTER}
          content={this.props.optionsTranslated.editTooltip}
        >
          <button
            className="icon-button icon-edit"
            onClick={() => {
              this.switchModewithType(id.objectId, id, ACTIONS.EDIT)
              trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_EDIT_ASSOCIATION)}
            }
          />
        </ConditionalTooltip>
        <ConditionalTooltip
          type={ConditionalTooltip.TYPE.ICON}
          align={ConditionalTooltip.ALIGN.CENTER}
          content={this.props.optionsTranslated.deleteTooltip}
        >
          <button
            className="icon-button icon-trash2"
            onClick={() => {
              this.switchModewithType(id.objectId, id, ACTIONS.DELETE)
              trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_DELETE_ASSOCIATION)}
            }
          />
        </ConditionalTooltip>
      </>
    )
  }

  get filteredRow() {
    let rows = null
    if (this.props.equipmentAssociations?.EquipmentAssociations?.length > 0) {
      rows = this.props.equipmentAssociations?.EquipmentAssociations?.map(
        (id) => ({
          id: id.objectId,
          association: id.association,
          unitTypePresentationName: id.unitTypePresentationName,
          equipmentName: id.equipmentName,
          Action: this.getActionButton(id)
        })
      )
    }
    return rows
  }

  commonColumnComponent(heading, columns) {
    const fieldValue = _get(columns, heading.field, '')

    return (
      <ConditionalTooltip
        type={ConditionalTooltip.TYPE.ICON}
        conditional={true}
        content={fieldValue}
      />
    )
  }

  switchMode = (association, mode, objectId) => {
    this.setState({ association, mode, objectId })
  }

  switchModewithID = (objectId, mode) => {
    this.setState({ objectId, mode })
  }
  switchModewithType = (objectId, details, mode) => {
    this.setState({ objectId, details, mode })
  }

  deleteAssociation = (association) => {
    const { equipment: { equipmentName } = {} } = this.props
    this.setState({
      confirm: {
        title: 'Delete Association',
        text: `Are you sure you want to delete association between ${equipmentName} and ${association.equipmentName}?`,
        confirmText: 'Yes',
        cancelText: 'No'
      }
    })
  }

  onCloseDialog = (field) => () => {
    this.setState({ [field]: null })
  }

  prepareInitialValues() {
    const { association } = this.state
    return {
      association: _get(association, 'association'),
      equipmentType: _get(association, 'unitType'),
      equipmentId: _get(association, 'objectId')
    }
  }

  render() {
    const { mode, details, confirm, error } = this.state

    return (
      <div className="esdp-associated-equipment-tab" data-testid={`${DETAILS_TEXT_NAME_PREFIX()}_associatedEpTab`}>
        <div className="toolbar-buttons" style={{ paddingLeft: '91%' }}>
          <button style={{'height':'100%','line-height': '20px'}}
          data-testid={`${DETAILS_TEXT_NAME_PREFIX("addAssociation_button")}`}
            className="action small"
            onClick={() => {this.switchMode({}, ACTIONS.ADD), trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_ADD_ASSOCIATION)}}
          >
            <TranslateComponent>ADD ASSOCIATION</TranslateComponent>
          </button>
        </div>

        <Table
         pageName = {DETAILS_TEXT_NAME_PREFIX("equipment-list")}
          rows={this.filteredRow}
          columns={this.props.automationColumn}
          className="equipment-list"
        />

        <StandardDialogs
          confirm={confirm}
          error={error}
          onCloseDialog={this.onCloseDialog}
        />

        {(mode === ACTIONS.ADD ||
          mode === ACTIONS.EDIT ||
          mode === ACTIONS.DELETE) && (
          <AssociationDialog
            currentEquipment={this.props.equipment}
            mode={mode}
            details={details}
            initialValues={this.prepareInitialValues()}
            toggleAssociationModal={() => this.switchMode({}, '')}
            refetchAssociation={this.props.refetchAssociation}
            refetchEquipmentById={this.props.refetchEquipmentById}
            tableData={this.filteredRow}
          />
        )}

        {mode === ACTIONS.VIEW && (
          <AssociationDetails
            associatedEquipmentId={this.state.objectId}
            toggleDetailsModal={() => this.switchMode({}, '')}
            defaultDimensionGroup={this.props.defaultDimensionGroup}
            equipmentCharacteristicMetaData={this.props.equipmentCharacteristicMetaData}
            equipmentPropertyMetaData={this.props.equipmentPropertyMetaData}
          />
        )}
      </div>
    )
  }
}

export default withTranslation()(AssociatedEquipment)
