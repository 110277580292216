import moment from 'moment'
import { useMemo } from 'react'

// Following config for demo purpose. It should be updated as per chart feature like building data, EC, ED, ectc...
const defaultChartConfigInTimeline = {
  width: '100%',
  height: 55,
  legends: null,
  container: {},
  canvas: {
    canvasDetails: {
      0: {
        renderAreaShade: {
          fill: 'transparent',
          opacity: '0'
        },
        tooltip: {
          type: 'x', // x, xy - xy not yet supported
          referenceLineV: true,
          hide: true
        }
      }
    }
  }
}

function disableAxis(axes = []) {
  return axes.map((v) => ({
    ...v,
    axis: {
      ...v.axis,
      visibleAt: [],
      hideTicks: true,
      hideLine: []
    }
  }))
}

export function useTimelineChartConfig({
  xAxis = [],
  yAxis = [],
  series,
  ...rest
}) {
  return useMemo(() => {
    return {
      ...defaultChartConfigInTimeline,
      dataLastUpdatedAt: moment().unix(),
      x: disableAxis(xAxis),
      y: disableAxis(yAxis),
      series,
      ...rest
    }
  }, [series])
}
