import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { WidgetCardWrapper, Title, ParaName, ListItem } from './style'
import {
  CREATE_DASHBOARD_WIDGET,
  DELETE_DASHBOARD_WIDGET,
  GET_DASHBOARD_WIDGET,
  UPDATE_DASHBOARD
} from '../../graphql'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { getSearchParams } from 'src/common/helperFunctions.js'
import {
  selectIsDashboardWidgetAdded,
  setDashboardWidgetAddedState,
  selectDashboardValues,
  setDashboardValues,
  selectDashboardIdForWidget,
  getDashboards,
  selectDashboards
} from 'src/redux/slicers/dashboard'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Modal from 'src/components/legacy/components/modal/modal'
import { ACTIONS } from 'src/constants/actions'
import { useWidgetsContext } from 'src/pages/widgets/WidgetsContextProvider'
import WidgetCreate from 'src/pages/widgets/widget-library/WidgetCreate'
import widgetTemplate from 'src/pages/widgets/widget-template'
import cloneDeep from 'lodash/cloneDeep'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useMultipleBuildingsAccess } from 'src/hooks/multipleBuildingAccess'
import { WidgetCard } from './WidgetCard'
import { WidgetNames } from 'src/pages/widgets/helpers'
import { AddChartToDashboardModal } from 'src/pages/buildingData/AddToDashboardModal'
import './styles.scss'
import { ReactSortable } from 'react-sortablejs'
import debounce from 'lodash/debounce'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { WIDGET_TYPES } from 'src/constants'
import { getValidWidgetOrderValue } from '../../helper'
import { TranslateComponent } from 'src/common/translations'

const DashboardCardWidget = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const {
    setOpenWidgetPicker,
    isOpenCreateWidget,
    setMode,
    mode,
    setIsOpenCreateWidget,
    setSelectedWidget,
    setWidgetDetails,
    setWidgetId,
    widgetId,
    widgetDetails,
    setSelectedBuildingChart
  } = useWidgetsContext() || {}

  const params = useParams()
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsDashboardWidgetAdded)
  const { data: dashboardValues = {} } = useSelector(selectDashboardValues)
  const { data: dashboards } = useSelector(selectDashboards)
  const dashboardIdForWidget = useSelector(selectDashboardIdForWidget)
  const [isOpen, setIsOpen] = useState(false)
  const [isCopyOpen, setIsCopyOpen] = useState(false)
  const [isEditSucessOpen, setIsEditSucessOpen] = useState(false)
  const [selectedData, setSelectedData] = useState<any>()
  const [t] = useTranslation()
  const [widgetItem, setWidgetItem] = useState(null)
  // to keep edited building charts also in UI, no need to call dashboard all the time, whever there is
  // edit in building data chart
  const [allBuildingCharts, setAllBuildingCharts] = useState([])
  const [buildingDataWidget, setBuildingDataWidget] = useState(null)
  const [widgets, setWidgets] = useState([])
  const [sortedWidgets, setSortedWidgets] = useState(null)
  const { setSeletectedBuildings: setSeletectedBuildingsToken } =
    useMultipleBuildingsAccess()

  const { isDashboardWidgetAdded } = useSelector(
    (state: any) => state?.dashboard
  )
  const [showBuildingDataDashboardModal, setshowBuildingDataDashboardModal] =
    useState(false)

  const { buildingId } = getSearchParams()
  const {
    data: userData,
    refetch,
    loading
  } = useQuery({
    query: GET_DASHBOARD_WIDGET,
    variables: { id: dashboardIdForWidget || '' },
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data.getDashboard'
  })

  const { onSubmit: createDashboardWidget } = useMutation({
    query: CREATE_DASHBOARD_WIDGET,
    dataPath: 'data.createDashboardWidget'
  })

  const { onSubmit: updateDashboard } = useMutation({
    query: UPDATE_DASHBOARD,
    dataPath: 'data.updateDashboard'
  })

  const handleRemoveUrlParams = () => {
    const param1 = searchParams.get('organization')
    const param2 = searchParams.get('location')
    if (param1 || param2) {
      // 👇️ delete each query param
      searchParams.delete('organization')
      searchParams.delete('location')
      setSearchParams(searchParams)
    }
    setWidgetItem(null)
  }

  useEffect(() => {
    if (!isOpenCreateWidget) handleRemoveUrlParams()
  }, [isOpenCreateWidget])

  useEffect(() => {
    if (dashboardIdForWidget) {
      setWidgets([])
      setSortedWidgets([])
      refetch()
    }
  }, [dashboardIdForWidget])

  useEffect(() => {
    if (isDashboardWidgetAdded) {
      refetch()
      dispatch(setDashboardWidgetAddedState({ loading: false }))
    }
  }, [isDashboardWidgetAdded])

  useEffect(() => {
    if (widgetId) {
      if (mode === ACTIONS.COPY) {
        createDashboardWidget({
          input: { dashboardID: dashboardIdForWidget, widgetID: widgetId }
        }).then((res) => {
          const order = JSON.parse(dashboardValues?.widgetOrder)
          const indexForPosition = order.findIndex(
            (e) => e.id === widgetItem.id
          )
          const indexToUse =
            indexForPosition !== -1 ? indexForPosition : order.length
          const newOrderUpdated = [...order]
          newOrderUpdated.splice(indexToUse + 1, 0, {
            ...order?.[indexToUse],
            id: res.id
          })
          updateDashboard({
            input: {
              id: dashboardIdForWidget,
              widgetOrder: JSON.stringify(newOrderUpdated)
            }
          }).then((e) => {
            setDashboardValues({
              type: 'Success',
              data: {
                ...dashboardValues,
                widgetOrder: JSON.stringify(newOrderUpdated)
              }
            })
          })
        })
      }
      handleRemoveUrlParams()

      if (mode === ACTIONS.COPY) {
        setIsCopyOpen(!isCopyOpen)
      }
      if (mode === ACTIONS.EDIT) {
        setIsEditSucessOpen(!isEditSucessOpen)
      }
    }
  }, [widgetId])

  useEffect(() => {
    if (isLoading) {
      refetch()
      dispatch(setDashboardWidgetAddedState({ loading: false }))
    }
  }, [isLoading])

  const { onSubmit: deleteDashboardWidgetMutation } = useMutation({
    query: DELETE_DASHBOARD_WIDGET,
    dataPath: 'data.deleteDashboardWidget'
  })

  const onDeleteWidgetDashboard = async () => {
    const input = {
      id: selectedData?.id
    }
    if (
      selectedData?.id &&
      selectedData.widget.widgetType === WIDGET_TYPES.MULTIPLE_LINES
    ) {
      const parsedBuildingDataCharts =
        userData?.buildingDataCharts && JSON.parse(userData?.buildingDataCharts)
      const updatedBuildingDataChart = parsedBuildingDataCharts.filter(
        (widget) => widget.id !== selectedData?.id
      )
      const order = JSON.parse(dashboardValues?.widgetOrder)
      if (order && order.length) {
        const indexForPosition = order.findIndex(
          (e) => e.id === selectedData?.id
        )
        if (indexForPosition !== -1) {
          order.splice(indexForPosition, 1)
        }
        await updateDashboard({
          input: {
            id: dashboardIdForWidget,
            buildingDataCharts: JSON.stringify([...updatedBuildingDataChart]),
            widgetOrder: JSON.stringify(order)
          }
        })
      }
    } else {
      const res = await deleteDashboardWidgetMutation({ input })
      const order = JSON.parse(dashboardValues?.widgetOrder)
      if (order && order.length) {
        const indexForPosition = order.findIndex((e) => e.id === res.id)
        if (indexForPosition !== -1) {
          order.splice(indexForPosition, 1)
        }
        await updateDashboard({
          input: {
            id: dashboardIdForWidget,
            widgetOrder: JSON.stringify(order)
          }
        })
      }
    }
    refetch()
    setIsOpen(!isOpen)
  }

  function buildindDataChartDataTransformer(data = []) {
    // Default model of widget, transforming building data chart details to widget to use same widget functionality
    const model = ({
      accountId = '',
      buildingId = '',
      chartId = '',
      description = '',
      id = '',
      name = '',
      timePeriod = '',
      userId = ''
    }) => ({
      widget: {
        id,
        accountId,
        buildingId,
        chartId,
        description,
        name,
        timePeriod,
        userId: userId,
        widgetType: WIDGET_TYPES.MULTIPLE_LINES
      },
      id
    })

    return data.map(model)
  }

  const initializeData = async () => {
    if (userData) {
      try {
        // retrieve the size for widgets
        const widgetOrder = JSON.parse(getValidWidgetOrderValue(userData?.widgetOrder))
        const parsedOrder = widgetOrder?.filter((f) => f?.id)
        let parsedBuildingDataCharts =
          userData?.buildingDataCharts &&
          JSON.parse(userData?.buildingDataCharts)

        parsedBuildingDataCharts = Array.isArray(parsedBuildingDataCharts)
          ? buildindDataChartDataTransformer(parsedBuildingDataCharts)
          : []

        const allWidgets = [
          ...(userData?.widgets?.items || []),
          ...(parsedBuildingDataCharts || [])
        ]
        // set token for all the building ids where the widgets are created
        const buildingIds = allWidgets
          ?.filter((x) => x)
          .map((x) => x?.widget?.buildingId)
        const filterBuildingIds = [
          ...buildingIds?.filter((id) => id),
          buildingId
        ]
        // set token of multiple buildings in widgets
        // sets the claims as empty for now - we have to lookin after [ TO-DO]
        await setSeletectedBuildingsToken([...new Set(filterBuildingIds)], [])
        const sortedWidgets =
          (Array.isArray(parsedOrder) &&
            parsedOrder?.map((e) => ({
              id: e.id,
              height: e.height || 300,
              width: e.width || 300
            }))) ||
          []
        allWidgets
          ?.filter((x) => x !== null)
          ?.forEach((e) => {
            const index = sortedWidgets.findIndex((x) => e.id === x.id)
            if (index === -1) {
              sortedWidgets.push({ ...e })
            } else {
              sortedWidgets[index] = { ...sortedWidgets[index], ...e }
            }
          })

        setAllBuildingCharts(parsedBuildingDataCharts)
        setWidgets(sortedWidgets)
        setSortedWidgets(
          sortedWidgets?.map((e) => ({
            id: e.id,
            height: e.height || 300,
            width: e.width || 300
          }))
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  const updateWidgetSortingFunc = useCallback(
    debounce((data) => {
      const dataToSave =
        data.map((e) => ({
          id: e.id,
          height: e.height,
          width: e.width
        })) ?? []
      if (dataToSave?.length === 0) return
      // While delete dashboard unwantedly call this update dashboard query. So we have restricted
      const isDashboardPresent = dashboards
        ?.map((f) => f?.id)
        .includes(dashboardIdForWidget)
      if (!isDashboardPresent) return
      updateDashboard({
        input: {
          id: dashboardIdForWidget,
          widgetOrder: JSON.stringify(dataToSave)
        }
      }).then(() => {
        dispatch(
          setDashboardValues({
            type: 'Success',
            data: {
              ...dashboardValues,
              widgetOrder: JSON.stringify(dataToSave)
            }
          })
        )
      })

      setSortedWidgets(dataToSave)
    }, 1000),
    [dashboardIdForWidget]
  )

  useEffect(() => {
    try {
      const widgetStringOrder = dashboardValues?.widgetOrder || '[]'
      if (
        (dashboardValues && sortedWidgets && !dashboardValues.widgetOrder) ||
        (sortedWidgets &&
          JSON.stringify(sortedWidgets.map((e) => e.id)) !=
            JSON.stringify(JSON.parse(widgetStringOrder).map((e) => e.id))) ||
        (sortedWidgets &&
          JSON.stringify(sortedWidgets.map((e) => e.id)) !=
            JSON.stringify(widgets.map((e) => e.id)))
      ) {
        updateWidgetSortingFunc(widgets)
      }
    } catch (error) {}
  }, [widgets])

  useEffect(() => {
    initializeData()
  }, [userData])

  const modalConfig = {
    heading: 'Remove widget',
    buttons: [
      {
        text: "Yes",
        handleClick: () => {
          onDeleteWidgetDashboard(),
            trackEvent(USER_EVENTS.DASHBOARDS.events.REMOVE_WIDGET)
        },
        type: 'confirm'
      },
      {
        text: "No",
        handleClick: () => setIsOpen(!isOpen),
        type: 'cancel'
      }
    ],
    handleClose: () => setIsOpen(!isOpen)
  }
  const modalCopyConfig = {
    heading: 'Success',
    buttons: [
      {
        text: "Close",
        handleClick: () => {
          setIsCopyOpen(false)
          setIsEditSucessOpen(false)
          refetch()
        },
        type: 'cancel'
      }
    ],
    handleClose: () => {
      setIsCopyOpen(false)
      setIsEditSucessOpen(false)
      refetch()
    }
  }

  const toggleBuildingDataDashboardModal = () => {
    setshowBuildingDataDashboardModal(!showBuildingDataDashboardModal)
  }

  const refreshBuildingDataWidget = (updatedWidgetDetails) => {
    if (updatedWidgetDetails) {
      const allBCharts = [...allBuildingCharts]
      const index = allBCharts?.findIndex((x) => x?.id === updatedWidgetDetails?.id)
      if (index !== -1) {
        allBCharts[index] = updatedWidgetDetails
        setAllBuildingCharts(allBCharts)
        const selectedBChart = allBCharts?.find(
          (x) => x?.id === buildingDataWidget?.id
        )
        setSelectedBuildingChart(selectedBChart)
        setshowBuildingDataDashboardModal(false)
        setBuildingDataWidget(null)
      }
    }
  }

  const setWidget = (item) => {
    const widgets = widgetTemplate
      .getAll(t)
      .map(({ title, description, name, widgetCategory }) => ({
        title,
        widgetCategory,
        type: name,
        value: title,
        key: name,
        name,
        desc: description
      }))
    const filterData = widgets.find(
      (it) => it?.name === item?.widget?.widgetType
    )
    setSelectedWidget(filterData)
    const copyWidgetData = cloneDeep(item?.widget)
    setWidgetDetails(copyWidgetData)
  }

  const handleDashboardWidgetUpsert = (mode, item) => {
    if (item?.widget?.widgetType === WIDGET_TYPES.MULTIPLE_LINES) {
      const selectedBuildingDataChart = allBuildingCharts?.find(
        (x) => x?.id === item?.id
      )
      setBuildingDataWidget(selectedBuildingDataChart || item)
      setSelectedData(item)
      setshowBuildingDataDashboardModal(true)
    } else {
      setWidgetItem(item)
      setSearchParams(
        `?organization=${item?.widget?.accountId}&location=${item?.widget?.buildingId}`
      )
      setOpenWidgetPicker(true)
      setSelectedData(item)
      setIsOpenCreateWidget(true)
      setWidget(item)
      setMode(mode)
    }
  }

  const items = [
    {
      actionName: 'Edit',
      actionIcon: 'icon-gear2',
      onclick: (item) => {
        handleDashboardWidgetUpsert(ACTIONS.EDIT, item)
        trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_EDIT_WIDGET, {
          location: 'Dashboard'
        })
      }
    },
    {
      actionName: 'Copy',
      actionIcon: 'icon-duplicate',
      onclick: (item) => {
        handleDashboardWidgetUpsert(ACTIONS.COPY, item)
        trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_COPY_WIDGET, {
          location: 'Dashboard'
        })
      }
    },
    {
      actionName: 'Delete',
      actionIcon: 'icon-trash2',
      onclick: (item: any) => {
        setSelectedData(item)
        setIsOpen(!isOpen)
        trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_REMOVE_WIDGET, {
          location: 'Dashboard'
        })
      }
    }
  ]

  const handleResize = (obj) => {
    const index = widgets.findIndex((e) => e.id === obj.id)
    const clonedWidgets = [...widgets]

    clonedWidgets[index] = {
      ...clonedWidgets[index],
      height: obj.height,
      width: obj.width
    }

    setWidgets(clonedWidgets)

    updateDashboard({
      input: {
        id: dashboardIdForWidget,
        widgetOrder: JSON.stringify(
          clonedWidgets.map((e) => ({
            id: e.id,
            width: e.width,
            height: e.height
          }))
        ) // JSON.stringify({...resizeState, ...obj})
      }
    })
  }

  const getDimension = (width, height) => {
    const rowValue = width > 0 ? width / 300 : 1
    const columnValue = height > 0 ? height / 300 : 1
    return { selectedRow: rowValue, selectedColumn: columnValue }
  }

  return (
    <WidgetCardWrapper>
      <ReactSortable
        list={widgets?.filter((x) => x?.widget)}
        setList={setWidgets}
        direction="horizontal"
        handle=".selectable-drag-and-drop"
        easing="cubic-bezier(1, 0, 0, 1)"
        animation={150}
        dragoverBubble={true}
        ghostClass="sortable-ghost"
        onSort={() =>
          trackEvent(USER_EVENTS.DASHBOARDS.events.REPOSITION_WIDGET)
        }
      >
        {widgets
          ?.filter((x) => x?.widget)
          ?.map((item) => (
            <WidgetCard
              key={item?.id}
              height={item.height}
              width={item.width}
              menuItems={items}
              widgetDetails={item}
              handleResize={handleResize}
              selectedDimen={getDimension(item.width, item.height)}
            ></WidgetCard>
          ))}
      </ReactSortable>
      {isOpen ? (
        <Modal {...modalConfig}>
          <div>
            <p>
              <TranslateComponent>Are you sure you want to remove widget</TranslateComponent>{' '}
              <strong>{selectedData?.widget?.name}</strong> <TranslateComponent>from your</TranslateComponent>{' '}
              <strong>{dashboardValues?.name}</strong>?
            </p>
            {
              <p>
                <TranslateComponent>Note: This action only removes the widget from this particular
                dashboard. The widget will still be available in your Widget
                Library</TranslateComponent>
              </p>
            }
          </div>
        </Modal>
      ) : null}
      {isCopyOpen || isEditSucessOpen ? (
        <Modal {...modalCopyConfig}>
          {mode === ACTIONS.COPY && (
            <div>
              <p><TranslateComponent>You have created a copy of</TranslateComponent>{`${
                selectedData?.widget?.name || ''
              }`}<TranslateComponent> widget.</TranslateComponent></p>
            </div>
          )}
          {mode === ACTIONS.EDIT && (
            <div>
              <p><TranslateComponent>You have updated </TranslateComponent>{`${widgetDetails?.name || ''}`}<TranslateComponent> widget.</TranslateComponent></p>
            </div>
          )}
        </Modal>
      ) : null}
      {isOpenCreateWidget && widgetItem && <WidgetCreate />}

      {showBuildingDataDashboardModal && (
        <AddChartToDashboardModal
          dashBoardId={params.id}
          widgetDetails={buildingDataWidget}
          mode={ACTIONS.EDIT}
          refreshBuildingDataWidget={refreshBuildingDataWidget}
          toggleAddDashboardModal={toggleBuildingDataDashboardModal}
        />
      )}
    </WidgetCardWrapper>
  )
}

export default DashboardCardWidget
