
export const GET_DATA_CHARACTERISTICS = `query GET_DATA_CHARACTERISTICS($id: ID!) {
  getPropertyMappingByEquipmentID(equipmentId: $id, propertyType: {eq: "Characteristic"},limit: 1000) {
    items {
      id
      sourceDisplayName
      sourceUri
      sourceDeviceId
      tisDisplayName
      sourceMeasureUnit
      targetMeasureUnit
      targetMeasureType
      propertyType
      creationType 
      value
      targetKey
      targetDataGroup
      targetDataType
      maxValue
      minValue
      sourceDataGroup
    }
  }
}

`

export const getPropsByEquipmentType = /* GraphQL */ `
  query PropertiesSearch($filter: SearchablePropertyMetadataFilterInput) {
    searchPropertyMetadata(limit: 1000, filter: $filter) {
      items {
        tisDisplayName
        targetKey
        propertyType
        id
        sourceKey
        targetDataType
        targetDataGroup
        targetMeasureType
        targetMeasureUnit
        targetMeasureUnitDescription
        tisDisplayName
      }
      total
    }
  }
`
