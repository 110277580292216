import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import AssociatedEquipment from './associated-equipment'
import {
  LIST_CHILD_EQUIPMENT_DETAILS,
  getEquipmentDetailsbyId
} from '../../graphql/queries'
import {
  UPDATE_EQUIPMENT_ASSOCIATION
} from '../../graphql/mutations'
import _isEmpty from 'lodash/isEmpty'

import { transformData } from '../EquipmentDetailsParser'
import './index.scss'
import { ACTIONS } from 'src/constants/actions'
import { getSearchParams } from "src/common/helperFunctions.js"
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { useAPIPagination } from 'src/hooks/APIPaginationHook'
import translate, { TranslateComponent, fetchTranslate } from '../../../../common/translations'
import { useDispatch } from 'react-redux'
import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
import { useSelector } from 'react-redux'

export default function AssociatedEquipmentWrapper({
  defaultDimensionGroup,
  equipmentCharacteristicMetaData,
  equipmentPropertyMetaData
}) {
  const appDispatch = useDispatch()
  const {equipment: equipmentId,buildingId} = getSearchParams()
  const [currentEquipment,setCurrentEquipment] = useState()
  const [equipmentIdToFetchChildren,setEquipmentIdToFetchChildren] = useState(equipmentId)
  const [associationUpdate,setAssociationUpdate] = useState(false)
  const [listofParents, setlistofParents] = useState([])
  const [listOfChildren,setListOfChildren] = useState([])
  const [allChildrenRefetchedAfterAssociationUpdate,setAllChildrenRefetchedAfterAssociationUpdate] = useState(null)
  const [finalTranslatedData, setFinalTranslatedData] = useState({
    EquipmentAssociations : []
  })
  let selectedLanguage = useSelector((state) => state?.appData?.selectedLanguage)
  selectedLanguage = selectedLanguage ? selectedLanguage : 'en'
  const [selectedMode,setSelectedMode] = useState()
  let equipmentParentId = ''

  // TO GET PARENTEQUIPMENTDETAILS
  const {
    data: parentDetails = null,
    parentLoading,
    refetch: refetchparentDetails
  } = useQuery({
    query: getEquipmentDetailsbyId,
    disableInitialLoad: true,
    variables: { id: equipmentParentId },
    dataPath: 'data.getEquipment'
  })

  const {
    data: currentEquipmentDetails = null,
    refetch: refetchCurrentEquipmentById
  } = useQuery({
    query: getEquipmentDetailsbyId,
    disableInitialLoad: true,
    variables: { id: equipmentId },
    dataPath: 'data.getEquipment'
  })


  const [
    loadingData,
    refetchListchildDetailsForParentUpdate,
    updatedChidrenOfParentEquipment
  ] = useAPIPagination(
    LIST_CHILD_EQUIPMENT_DETAILS,
    'data.listEquipmentsByBuildingAndActiveAndType'
  )


  useEffect(() => {
    trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.VIEW_ASSOCIATED_EQUIPMENT)
  }, [])

  const updateParent = async (parentIds, selectedMode) => {
    // in case delete is performed for 1 item only
    if (!parentIds && ACTIONS.DELETE === selectedMode) {
      refetchCurrentEquipmentById?.()
      setlistofParents([])
      setEquipmentAssociations(transformData([]))
      return
    }

    const refetchParent = async (item) => {
      // Performs Parent refetch
      let parent_ID = { id: item }
      await refetchparentDetails(parent_ID)
    }

    const EQParentIds = parentIds?.split('#')
    const refetchParentList = async () => {
      if (EQParentIds) {
        for (const item of EQParentIds) {
          equipmentParentId = item
          await refetchParent(item)
        }
      }
      // await refetchEquipmentById()
    }
    await refetchParentList();
 
  }
  const [modifiedParentId, setmodifiedParentId] = useState('')

  const listChildDetailsFunc = async ({nextToken = "",associationUpdate = false,eqId} = {}) => {
    const data = await refetchListchildDetails({ parentId: (eqId || equipmentId),buildingId,...(nextToken ? {nextToken} : {}) })
    if(data.nextToken !== null){
      setListOfChildren(children => [...children,...data.items.filter(item => !children.some(child => child.id === item.id))])
      setAllChildrenRefetchedAfterAssociationUpdate(associationUpdate ? false : null)
      listChildDetailsFunc({nextToken : data.nextToken,associationUpdate})
    }else{
      setListOfChildren(children => [...children,...data.items])
      setAllChildrenRefetchedAfterAssociationUpdate(associationUpdate ? true : null)
    }      
  }
  // Updating EQ Association after create/edit association
  const updateEQAssociation = async (
    updatedParentID,
    modifiedParentId,
    isParent,
    selectedMode
  ) => {
    if (isParent) {
      updateParent(updatedParentID, selectedMode)
      modifiedParentId && selectedMode === ACTIONS.EDIT  && refetchListchildDetailsForParentUpdate({ parentId:modifiedParentId,buildingId})
    } else {
      setListOfChildren([])
      setAllChildrenRefetchedAfterAssociationUpdate(false)
      setEquipmentIdToFetchChildren(equipmentId)
      setAssociationUpdate(true)
    }
    setSelectedMode(selectedMode)
    setmodifiedParentId(modifiedParentId)
  }

  const { onSubmit: updateEquipmentAssociation } = useMutation({
    query: UPDATE_EQUIPMENT_ASSOCIATION,
    dataPath: 'data.updateEquipment'
  })

  // // TO GET CHILDEQUIPMENTDETAILS
  // const {
  //   data: childDetails = null,
  //   childLoading,
  //   refetch: refetchchildDetails
  // } = useQuery({
  //   query: GET_CHILD_EQUIPMENTDETAILS,
  //   disableInitialLoad: true,
  //   variables: { parentId: equipmentId },
  //   dataPath: 'data.searchEquipment.items'
  // })

  const {
    listchildLoading,
    refetch: refetchListchildDetails
  } = useQuery({
    query: LIST_CHILD_EQUIPMENT_DETAILS,
    disableInitialLoad: true,
    variables: { parentId: equipmentId,buildingId },
    dataPath: 'data.listEquipmentsByBuildingAndActiveAndType'
  })

  useEffect(() => {
    (async () =>  {
    if(allChildrenRefetchedAfterAssociationUpdate && !listOfChildren?.length){
      await updateEquipmentAssociation({
        input: {
          id: equipmentIdToFetchChildren,
          isParent : false
        }
      })
    }       
  //   if(allChildrenRefetchedAfterAssociationUpdate){
  //   if (ACTIONS.DELETE === selectedMode) {
  //     setEquipmentAssociations(transformData([...(listOfChildren.map(c => ({c,relation:"Child"}) )), ...listofParents]))
  //   }
  // }
  })()
  },[selectedMode,listofParents,listOfChildren,allChildrenRefetchedAfterAssociationUpdate])

  useEffect(() => {
    if (equipmentId) {
      refetchCurrentEquipmentById()
      listChildDetailsFunc()
    }
    // eslint-disable-next-line
  }, [equipmentId])

  useEffect(() => {
    if (associationUpdate && equipmentIdToFetchChildren) {
      listChildDetailsFunc({associationUpdate:true,eqId:equipmentIdToFetchChildren})
      setAssociationUpdate(false)
    }
    // eslint-disable-next-line
  }, [equipmentIdToFetchChildren,associationUpdate])

  useEffect(() => {
    if ( currentEquipmentDetails ) {
      updateParent(currentEquipmentDetails?.parentId)
      setCurrentEquipment(currentEquipmentDetails)
    }
    // eslint-disable-next-line
  }, [currentEquipmentDetails])


  useEffect(() => {
    if(selectedMode === ACTIONS.EDIT && modifiedParentId && !loadingData && updatedChidrenOfParentEquipment?.length === 0){
      updateEquipmentAssociation({
        input: {
          id: modifiedParentId,
          isParent : false
        }
      })
    }
  },[loadingData,updatedChidrenOfParentEquipment,modifiedParentId,selectedMode])

  // Combine the output data into one object
  const [EquipmentAssociations, setEquipmentAssociations] = useState([])
  useEffect(() => {
    if (!parentLoading && !listchildLoading) {
      let parentList = listofParents?.filter(
        (element) => element.id !== modifiedParentId
      )
      if (!_isEmpty(parentDetails)) {
        if (
          parentList?.filter((el) => el.id === parentDetails?.id)?.length <= 0
        ) {
          parentList = [
            ...parentList,
            Object?.assign(parentDetails, { relation: 'Parent' })
          ]
          setlistofParents(parentList)
        } else {
          setlistofParents(parentList)
        }
      }
      let childDetailsNew = []
      if (!_isEmpty(listOfChildren)) {
        childDetailsNew = listOfChildren?.map((item) => {
          return { ...item, relation: 'Child' }
        })
      }
      let EqAssociations = []

      if (!_isEmpty(parentList) || !_isEmpty(childDetailsNew)) {
        EqAssociations = parentList
          ? [...childDetailsNew, ...parentList]
          : childDetailsNew
        setEquipmentAssociations(transformData(EqAssociations))
      }
      if (_isEmpty(parentList) && _isEmpty(childDetailsNew)) {
        setEquipmentAssociations([])
      }
    }
  }, [parentDetails, listOfChildren, parentLoading, listchildLoading])

  // changing the fetchedData to translate using the bulk translation method
  useEffect(()=>{
    const fetchTranslations = async () => {
      const values = await EquipmentAssociations
      const translations = await Promise.all(values?.EquipmentAssociations?.map(async (v) => {
        if (selectedLanguage !== 'en' && v?.association && v?.unitTypePresentationName ) {
          const translatedAssociation = await fetchTranslate(v?.association, selectedLanguage)
          const translatedType = await fetchTranslate(v?.unitTypePresentationName, selectedLanguage)
          return { ...v, unitTypePresentationName: translatedType, association: translatedAssociation }
        }
        return { ...v }
      }))
      setFinalTranslatedData({EquipmentAssociations: translations})
    }
    if(selectedLanguage!=='en' && EquipmentAssociations?.EquipmentAssociations)
      fetchTranslations()
    else
      setFinalTranslatedData({EquipmentAssociations: EquipmentAssociations?.EquipmentAssociations})
  }, [EquipmentAssociations, selectedLanguage])


  const optionsTranslated = {
    deleteTooltip : translate("Delete Association"),
    editTooltip : translate("Edit Association"),
    addAssociation : translate("Add Association")
  }

  const automationColumn =  [
      {
        displayName: translate('Association'),
        sortable: true,
        key: 'association',
        name: 'association'
      },
      {
        displayName: translate('Equipment Type'),
        sortable: true,
        key: 'unitTypePresentationName',
        name: 'unitTypePresentationName'
      },
      {
        displayName: translate('TIS Equipment'),
        sortable: true,
        key: 'equipmentName',
        name: 'equipmentName'
      },
      { displayName: '', key: 'Action', name: 'Action', align: 'right' }
    ]

  return (
    <AssociatedEquipment
      isFetchingAssociation={parentLoading && listchildLoading}
      equipmentAssociations={finalTranslatedData}
      equipment={currentEquipment}
      refetchAssociation={updateEQAssociation}
      refetchEquipmentById={refetchCurrentEquipmentById}
      defaultDimensionGroup={defaultDimensionGroup}
      equipmentCharacteristicMetaData={equipmentCharacteristicMetaData}
      equipmentPropertyMetaData={equipmentPropertyMetaData}
      optionsTranslated={optionsTranslated}
      automationColumn={automationColumn}
    />
  )
}
