import TrendBarSampleImage from 'src/assets/images/bar-sample.png'
import TrendLineSampleImage from 'src/assets/images/line-sample.png'
import TimeComparisionBarSampleImage from 'src/assets/images/single-comparison-bar-sample.png'
import BaselineComparisonBarImage from 'src/assets/images/bar-sample.png'
import EnergyDemandWidgetImage from 'src/assets/images/energy-demand-sample.png'
import CustomKPIImage from 'src/assets/images/custom-kpi-sample.png'
import OrbWidgetImage from 'src/assets/images/orb-sample.png'
import GaugeWidgetImage from 'src/assets/images/gauge-sample.png'
import EnergyCost from 'src/assets/images/energy-cost-sample.png'
import styled from 'styled-components'
import colors from 'src/components/layouts/colors.json'
import IconSVG from 'src/components/Icon'
import React from 'react'
import '../WidgetPreviewModal/styles.scss'
import { ORB, GAUGE } from 'src/constants'
import translate from '../../../common/translations'


const WarningContainer = styled.div`
  color: ${colors.RED};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border: 2px solid ${colors.RED};
  font-size: 14px;
  margin-bottom: 20px;
  span {
    margin-left: 10px;
  }
`

const TitleContainer = styled.div`
  height: 30px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 800;
  font-size: 14px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: ${colors.TBA_MEDIUM_GREEN};
  color: ${colors.WHITE};
`

const PreviewContainer = styled.div`
  width: 300px;
  height: 300px;
`

const WidgetChartPreviewContainer = styled.div`
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SampleImage = styled.img`
  margin: 0 auto;
  display: flex;
  width: 300px;
  height: 300px;
`

const ChartContainer = styled.div`
  border: 1px solid ${colors.TBA_LIGHT_GREY};
  padding: 10px;
  height: 300px;
  width: 300px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

const sampleImageSrc = {
  'trend-bar-chart': TrendBarSampleImage,
  'trend-line-chart': TrendLineSampleImage,
  'time-comparision-bar-chart': TimeComparisionBarSampleImage,
  'baseline-comparison-bar': BaselineComparisonBarImage,
  'energy-demand-widget': EnergyDemandWidgetImage,
  'energy-cost': EnergyCost,
  'custom-kpi': CustomKPIImage,
  [ORB]: OrbWidgetImage,
  [GAUGE]: GaugeWidgetImage,
}

export const WidgetPreviewWrapper = ({
  error = false,
  widgetName,
  chartType = 'trend-bar-chart',
  children,
  isPreview = false,
  isWidth = false,
  type=''
}) => {
  return (
    <WidgetChartPreviewContainer
      className={isPreview ? 'widget-list-preview' : ''}
    >
      {error ? (
        <div>
          <WarningContainer>
            <IconSVG
              name="error"
              margin="0px 0px 0px 10px"
              color={colors.RED}
              width="35px"
            />
            <span>
              {translate("A sample image is shown. Please correct the indicated errors to see a preview.")}
            </span>
          </WarningContainer>
          <SampleImage src={sampleImageSrc[chartType]} alt="sample-image" />
        </div>
      ) : (
        <PreviewContainer
          className={isPreview ? 'widget-list-preview-container' : ''}
        >
          <TitleContainer type={type} isWidth={isWidth}>{widgetName}</TitleContainer>
          <ChartContainer type={type} isWidth={isWidth}>{children}</ChartContainer>
        </PreviewContainer>
      )}
    </WidgetChartPreviewContainer>
  )
}
