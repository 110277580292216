import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import _cloneDeep from 'lodash/cloneDeep'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import Spinner from 'src/components/legacy/components/spinner/spinner'

import './custom-dropdown-tree-select.scss'

const SELECT_ALL = 'selectAll'

const prepareData = (data, selectAll, selectAllEntity, t) => {
  const cloned = _cloneDeep(data)

  if (!selectAll) {
    return cloned
  }

  return [
    {
      label: selectAllEntity
        ? `${t('common:All')} ${selectAllEntity}`
        : t('common:SelectAll'),
      value: SELECT_ALL,
      className: 'select-all',
      checked: cloned.every(({ checked }) => checked)
    },
    ...cloned
  ]
}

export function CheckboxSelect({
  data,
  isLoading,
  className,
  selectAll,
  selectAllEntity,
  onChange,
  hideSearch,
  hideTags,
  texts,
  ...props
}) {
  const [t] = useTranslation()
  const [newData, setNewData] = useState([])

  useEffect(() => {
    data && setNewData(prepareData(data, selectAll, selectAllEntity, t))
  }, [data])

  const handleChange = (currentNode) => {
    const { value, checked } = currentNode
    let response = []

    if (value === SELECT_ALL) {
      const updatedNewData = newData.map((d) => ({ ...d, checked }))

      setNewData(updatedNewData)
      response = (updatedNewData || [])
        .slice(1)
        .filter(({ checked }) => checked)
        .map((i) => i.value)
    } else {
      const updatedNewData = newData.map((d) => ({
        ...d,
        checked: value === d.value ? checked : d.checked
      }))

      if (updatedNewData[0].value === SELECT_ALL) {
        const slicedData = updatedNewData.slice(1)

        // Update Selected All field if all items are selected
        setNewData([
          {
            ...updatedNewData[0],
            checked: slicedData.every(({ checked }) => checked)
          },
          ...slicedData
        ])
      } else {
        setNewData(updatedNewData)
      }

      response = (updatedNewData || [])
        .filter(({ checked, value }) => checked && value !== SELECT_ALL)
        .map((i) => i.value)
    }

    // send response to parent component
    onChange(response)
  }

  const getSelectedItemText = (data) => {
    const selectedItemsLength = (
      data.length && selectAll ? data.slice(1) : data
    ).filter(({ checked }) => checked).length

    if (data[0]?.value === SELECT_ALL && data[0]?.checked) {
      return selectAllEntity
        ? `${t('common:All')} ${selectAllEntity}`
        : t('common:SelectAll')
    } else if (selectedItemsLength === 1) {
      const checkedItem = data.find(({ checked }) => checked)

      return checkedItem.label
    } else {
      return `${selectedItemsLength} ${t('common:Selected')}`
    }
  }

  const renderDropdownTreeSelect = useMemo(
    () => (
      <DropdownTreeSelect
        {...props}
        texts={{
          ...texts,
          ...(hideTags && { placeholder: getSelectedItemText(newData) })
        }}
        data={newData}
        onChange={handleChange}
      />
    ),
    [newData]
  )

  const wrapperClasses = useMemo(
    () =>
      `${className} ${hideSearch ? 'hide-search' : ''} ${
        hideTags ? 'hide-tags' : ''
      } ${!props.inlineSearchInput ? 'tag-search' : ''}`,
    [className, hideSearch, hideTags, props.inlineSearchInput]
  )

  return (
    <div className={`dropdown-tree-wrapper ${wrapperClasses}`}>
      {/* {isLoading
			? <Spinner className="dropdown-tree-spinner" />
			: <></> Below code (renderDropdownTreeSelect) should be here once loader will be enabled
		} */}
      {renderDropdownTreeSelect}
    </div>
  )
}

CheckboxSelect.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ),
  texts: PropTypes.object,
  isLoading: PropTypes.bool,
  selectAll: PropTypes.bool,
  selectAllEntity: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inlineSearchInput: PropTypes.bool,
  hideSearch: PropTypes.bool,
  hideTags: PropTypes.bool
}

CheckboxSelect.defaultProps = {
  data: [],
  texts: {},
  isLoading: false,
  selectAll: false,
  selectAllEntity: '',
  className: '',
  inlineSearchInput: true,
  hideSearch: true,
  hideTags: true
}

export default CheckboxSelect
