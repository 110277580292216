import * as d3 from 'd3'
import {
  tickFormatter,
  ticksGenerator
} from 'src/chart-library/CommonComponents/Axis/helper'
import { SCALE_LINEAR } from 'src/chart-library/Utils/Scales/constant'
import { max } from 'lodash'
import { calculateDecimals } from 'src/chart-library/Utils/Scales/ScaleLinear/utils'
import { getTextDimensions } from '../../helper'

export const scaleLinearPadding = (obj: any = {}) => {
  let paddingObject: any = {}
  let axisPadding = 0
  const { scale, tickCount, notD3, dimensionTickCount, format, isX } = obj
  let ticks =
    ticksGenerator(
      scale,
      tickCount,
      SCALE_LINEAR,
      notD3,
      null,
      dimensionTickCount
    ) || []

  const extent = d3.extent(ticks)
  const decimalCount = calculateDecimals(
    extent?.[0],
    extent?.[1],
    dimensionTickCount
  )
  const formattingDetails = {
    decimalCount
  }
  ticks = ticks?.map((text, index) => {
    // More information about tick that will be shared to formatt function if provided
    const tickInfo = {
      index,
      isFirst: index === 0,
      isLast: ticks?.length === index + 1,
      value: text
    }

    const newText = tickFormatter(
      format,
      text,
      SCALE_LINEAR,
      formattingDetails,
      tickInfo
    )
    const textDimensions = getTextDimensions(newText)
    return {
      text: newText,
      textDimensions
    }
  })
  let ticksPadding = max(
    ticks?.map((obj: any) => obj?.textDimensions?.[isX ? 'height' : 'width'])
  )
  ticksPadding = ticksPadding > 0 ? ticksPadding : 0

  axisPadding = axisPadding + ticksPadding

  paddingObject = {
    textSize: axisPadding
  }

  return paddingObject
}
