import { clipUUID } from '../../../../../../chart-library/CommonComponents/Clip'
import { Tooltip } from '../../../../../../chart-library/CommonComponents/Tooltip'
import { useMemo, useRef } from 'react'
import { useOptions } from './useOptions'
import Axes from './Axes'
import BaseWrapper from '../../../../../../chart-library/CommonComponents/BaseWrapper'
import Svg from '../../../../../../chart-library/CommonComponents/Svg'
import TooltipRenderer from './tooltipTemplate'
import Series from './Series'
import { data, costToDateData, averageLineData } from './mockData'
import './styles.scss'

const axisData = ['Aug', 'Sep', 'Nov', 'Dec', 'Jan']

export default function EngeryCostChart({ stackedBarData }) {
  const eventName = useMemo(() => clipUUID('engery-cost-stacked-bar'), [])
  const plotineEventName = useMemo(() => clipUUID('energy-cost-plot-line'), [])

  const options = useOptions(
    stackedBarData?.axisData || [],
    0,
    stackedBarData?.maxBarValue
  )

  // provide boundary of tooltip to position properly
  const svgRef: any = useRef(null)

  return (
    <BaseWrapper options={options} className="engery-cost-chart-comp">
      <Svg ref={svgRef}>
        <Axes />
        <Series
          series={stackedBarData?.data || []}
          tooltipEventName={eventName}
          plotlineTooltipEventName={plotineEventName}
          plotLine={stackedBarData?.plotLine}
          hidePlotLine={stackedBarData?.hidePlotLine}
          costToDateSeries={stackedBarData?.costToDateData || []}
        />
      </Svg>
      {/*  For plotline */}
      <Tooltip
        tooltipClassName="ec-tooltip-bg"
        listenEventName={plotineEventName}
        boundary={svgRef}
      />
      {/* For stacked chart */}
      <Tooltip
        tooltipClassName="ec-tooltip-bg"
        listenEventName={eventName}
        boundary={svgRef}
        TooltipRenderer={TooltipRenderer}
      />
    </BaseWrapper>
  )
}
