import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import DialogPolyfill from 'dialog-polyfill'
import ReactDOM from 'react-dom'

class Dialog extends PureComponent {
  static propTypes = {
    animationType: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
  }

  static defaultProps = {
    animationType: 'slide',
    className: ''
  }

  constructor(props) {
    super(props)
    this.el = document.createElement('dialog')
    this.el.className = `${this.props.className} ${this.props.animationType}`

    this.anchor = document.body
  }

  componentDidMount = () => {
    window.addEventListener('keyup', this.escapeClose)
    this.anchor.appendChild(this.el)

    // for testing dialog window with polyfill
    if (typeof jest !== 'undefined') {
      delete window.HTMLDialogElement
    }

    DialogPolyfill.registerDialog(this.el)
    this.el.showModal()
  }

  componentWillUnmount = () => {
    window.removeEventListener('keyup', this.escapeClose)
    this.el.addEventListener('animationend', () =>
      this.anchor.removeChild(this.el)
    )
    this.el.classList.add('hide')
  }

  escapeClose = (e) => {
    if (e.which === 27) {
      this.props.handleClose()
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default Dialog
