import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'formik'

export default class EditableViewArray extends PureComponent {
  static propTypes = {
    readOnly: PropTypes.bool,
    ItemComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    AddItemButtonComponent: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func
    ])
  }

  renderArray = (arrayHelpers) => {
    const { ItemComponent, AddItemButtonComponent, ...rest } = this.props
    const items = rest.value || []

    return (
      <Fragment>
        {items.map((item, index) => (
          <ItemComponent
            key={index}
            item={item}
            index={index}
            arrayHelpers={arrayHelpers}
          />
        ))}
        {AddItemButtonComponent && (
          <AddItemButtonComponent arrayHelpers={arrayHelpers} />
        )}
      </Fragment>
    )
  }

  render() {
    const { readOnly, ItemComponent, ...rest } = this.props
    const items = rest.value || []

    return readOnly ? (
      items.map((item, index) => (
        <ItemComponent key={index} item={item} index={index} />
      ))
    ) : (
      <FieldArray name={rest.name} render={this.renderArray} />
    )
  }
}
