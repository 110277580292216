
import styled from "styled-components"

export const TagItem = styled.li`
  background-color: #f4f4f4;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  width: 100%;
  display: flex;
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  height: 18px;
  letter-spacing: 0.24px;
  line-height: 19px;
  line-height: normal;
  padding: 0 10px 0 8px;
  position: relative;
`

export const TagText = styled.span`
  line-height: 18px;
  max-width: calc(100% - 18px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`