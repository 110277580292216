import LanguagePicker from './LanguagePicker'
import { useTranslation } from 'react-i18next'
import { StyledFooterLogo, StyledFooter } from './style'
import Logo from 'src/assets/images/tt_logo_tm_color_rgb.png'
import { TranslateComponent } from 'src/common/translations'
const Footer = ({termsUrl}) => {
  const [t] = useTranslation()
  return (
    <StyledFooter>
      <div className="inner-footer">
        <LanguagePicker hpFooterStyle={'language-picker'} />
        <small>
          <br />©{new Date().getFullYear()} Trane |&nbsp;
          <a
            href="https://www.tranetechnologies.com/privacy-policy.html"
            target="_blank"
            rel="noreferrer"
          >
            <TranslateComponent>Privacy Policy</TranslateComponent>
          </a>{' '}
          |&nbsp;
          <a
            href="https://tranetechnologies.com/terms-of-use.html"
            target="_blank"
            rel="noreferrer"
          >
            <TranslateComponent>Terms of Use</TranslateComponent>
          </a> {' '}
          |&nbsp;
          <a
            href={termsUrl}
            target="_blank"
            rel="noreferrer"
          >
            <TranslateComponent>License Agreement</TranslateComponent>
          </a>
        </small>
        <StyledFooterLogo>
          <img src={Logo} alt="Logo" width="150" height={50} />
        </StyledFooterLogo>
      </div>
    </StyledFooter>
  )
}

export default Footer
