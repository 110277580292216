import clsx from 'clsx'
import { useRef } from 'react'
import { AxisDiM } from '../../Utils/defaults'
import useAxis from './useAxis'
import './styles.scss'

export default function Axis({
  axisPosition,
  axisScale,
  className,
  dimention = AxisDiM.x,
  hide = false,
  hideAixsTickLabel = false,
  hideAxisLine = false,
  hideAxisTick = false,
  hideAxisTickLine = false,
  hideEndTickValue = false,
  hideStartAndEndTickValues = false,
  hideStartTickValue = false,
  lastUpdatedAt = 0,
  offset = 0,
  xPosition = 0,
  yPosition = 0,
  ...props
}: AxisComponentProps) {
  const groupElRef = useRef(null)

  useAxis({
    el: groupElRef,
    axisType: axisPosition || '',
    scale: axisScale,
    lastUpdatedAt,
    props: {
      axisPosition,
      axisScale,
      dimention,
      lastUpdatedAt,
      xPosition,
      yPosition,
      ...props
    }
  })

  const xOffset = dimention === AxisDiM.x ? offset : 0
  const yOffset = dimention === AxisDiM.y ? offset : 0

  return (
    <g
      ref={groupElRef}
      className={clsx(className, {
        'hide-axis-line': hideAxisLine,
        'hide-axis-tick-end': hideEndTickValue || hideStartAndEndTickValues,
        'hide-axis-tick-label': hideAixsTickLabel,
        'hide-axis-tick-line': hideAxisTickLine,
        'hide-axis-tick-start': hideStartTickValue || hideStartAndEndTickValues,
        'hide-axis-tick': hideAxisTick,
        'hide-axis': hide
      })}
      transform={`translate(${(xPosition || 0) + yOffset}, ${
        (yPosition || 0) + xOffset
      })`}
    ></g>
  )
}
