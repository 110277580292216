import { useState, useEffect } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'
import { useFormik } from 'formik'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { CreateEditModalContainer } from './styles'
import { useMutation } from 'src/hooks/APIHooks'
import { UPDATE_PROPERTY_META_DATA, CREATE_PROPERTY_META_DATA } from './graphql'

export const CreateEditPropertyMetadata = ({
  propertyMetadataDetails,
  toggleCreateEditModal,
  updateListOnCreateUpdate
}) => {
  const [formikErrors, setFormikErrors] = useState({})

  const [formvalues, setFormvalues] = useState(null)

  const { onSubmit: createPropertyMetadata } = useMutation({
    query: CREATE_PROPERTY_META_DATA,
    dataPath: 'data.createPropertyMetadata'
  })

  const { onSubmit: updatePropertyMetadata } = useMutation({
    query: UPDATE_PROPERTY_META_DATA,
    dataPath: 'data.updatePropertyMetadata'
  })

  const handleSubmit = async (formValue) => {
    try {
      const input = {
        ...formValue
      }
      if (propertyMetadataDetails === null) {
        const response = await createPropertyMetadata({ input })
        if (response?.data?.createPropertyMetadata) {
          updateListOnCreateUpdate?.(
            response?.data?.createPropertyMetadata,
            false
          )
        }
      } else {
        input.id = propertyMetadataDetails?.id
        const response = await updatePropertyMetadata({ input })
        if (response?.data?.updatePropertyMetadata) {
          updateListOnCreateUpdate?.(
            response?.data?.updatePropertyMetadata,
            false
          )
        }
      }
    } catch (error) {}
  }

  const formik = useFormik({
    initialValues: { ...formvalues },
    onSubmit: handleSubmit,
    enableReinitialize: true
  })

  const submitForm = () => {
    setFormikErrors(formik?.errors)
    formik?.handleSubmit()
  }

  const { setFieldValue, values, errors } = formik

  useEffect(() => {
    setFormvalues(propertyMetadataDetails || null)
  }, [propertyMetadataDetails])

  const handleInputChange = (name, value) => {
    setFieldValue(name, value)
  }

  const propertyMetadataModalConfig = {
    gray: true,
    className: 'property-metadata-modal',
    isHideWhiteBackground: true,
    heading:
      propertyMetadataDetails === null
        ? 'Create Property Metadata'
        : 'Update Property Metadata',
    handleClose: toggleCreateEditModal,
    formIsErrored: Object.keys(formik?.errors)?.length > 0,
    buttons: [
      {
        text: propertyMetadataDetails === null ? 'Create' : 'Update',
        handleClick: submitForm,
        type: 'save',
        disabled: !values?.tisDisplayName || values?.tisDisplayName?.length === 0
      },
      {
        text: 'Cancel',
        handleClick: toggleCreateEditModal,
        type: 'cancel'
      }
    ]
  }

  return (
    <Modal {...propertyMetadataModalConfig}>
      <CreateEditModalContainer>
        <TextInput
          labelText="Property Display Name"
          defaultValue={values?.tisDisplayName}
          name="tisDisplayName"
          onChange={({ target: { value, name } }) => {
            handleInputChange(name, value)
          }}
          hasError={errors?.['tisDisplayName']}
          isRequired={true}
          errorMessage={errors?.['tisDisplayName']}
          maxLength={30}
        />
        <TextInput
          labelText="Source key"
          defaultValue={values?.sourceKey}
          name="sourceKey"
          onChange={({ target: { value, name } }) => {
            handleInputChange(name, value)
          }}
          hasError={errors?.['sourceKey']}
          isRequired={false}
          errorMessage={errors?.['sourceKey']}
          maxLength={30}
        />
        <TextInput
          labelText="Target Key"
          defaultValue={values?.targetKey}
          name="targetKey"
          onChange={({ target: { value, name } }) => {
            handleInputChange(name, value)
          }}
          isRequired={false}
          hasError={errors?.['targetKey']}
          errorMessage={errors?.['targetKey']}
          maxLength={30}
        />
        <TextInput
          labelText="Haystack"
          defaultValue={values?.haystack}
          name="haystack"
          onChange={({ target: { value, name } }) => {
            handleInputChange(name, value)
          }}
          isRequired={false}
          hasError={errors?.['haystack']}
          errorMessage={errors?.['haystack']}
          maxLength={30}
        />
        <TextInput
          labelText="Brick"
          defaultValue={values?.brick}
          name="brick"
          isRequired={false}
          onChange={({ target: { value, name } }) => {
            handleInputChange(name, value)
          }}
          hasError={errors?.['brick']}
          errorMessage={errors?.['brick']}
          maxLength={30}
        />
      </CreateEditModalContainer>
    </Modal>
  )
}
