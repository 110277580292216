import { format } from 'date-fns'
import { max } from 'lodash'
import store from 'src/redux/store';

// Details: Get Date difference in days based on array of date strings
// @ticks --> ["2023-12-20T12:12:00",....]
export const getDateDiffInDays = (ticks: any) => {
  // Convert date strings to Date objects
  const dates = ticks.map((d: any) => new Date(d))

  // Find min and max dates
  const min: any = new Date(Math.min(...dates))
  const max: any = new Date(Math.max(...dates))

  // Calculate the difference in days
  const timeDiff = max - min
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))

  return daysDiff
}

// Details: Default Date-Time formatter for Axis Label
// @val --> Tick Value from ScaleTime
// @dateDiff --> Difference between min and max dates of scale Time in days
// @index --> tick index number
export const defaultdateTimeFormatter = (
  val: any,
  dateDiff: number,
  index: number,
  isGTYear = false
) => {
  const date = new Date(val)
  const day = date.getDate()
  const hour = date.getHours()
  const month = date.getMonth()
  const minutes = date.getMinutes()

  const isMidNight = hour === 0 && minutes === 0
  const isYearStart = month === 0 && day === 1

  let formattedDate = ''
  let selectedLanguage = store?.getState().appData?.selectedLanguage;
  selectedLanguage = selectedLanguage ? selectedLanguage : "en";

  if (dateDiff <= 15) {
    if (index === 0) {
      formattedDate = `${date.toLocaleDateString(selectedLanguage, {
        month: 'short',
        day: '2-digit'
      })}`
    } else if (index > 0 && isMidNight) {
      formattedDate = `${date.toLocaleDateString(selectedLanguage, {
        month: 'short',
        day: '2-digit'
      })}`
    } else if (index > 0 && !isMidNight) {
      formattedDate = `${hour < 10 ? 0 : ''}${hour}:${
        minutes < 10 ? 0 : ''
      }${minutes}`
    }
  } else if (dateDiff > 15 && dateDiff <= 365) {
    if (isYearStart) {
      formattedDate = `${date.toLocaleDateString(selectedLanguage, {
        month: 'short',
        year: 'numeric'
      })}`
    } else {
      formattedDate = `${date.toLocaleDateString(selectedLanguage, {
        month: 'short',
        day: '2-digit'
      })}`
    }
  } else {
    if (!isGTYear) {
      formattedDate = `${date.toLocaleDateString(selectedLanguage, {
        month: 'short',
        year: 'numeric'
      })}`
    } else {
      formattedDate = `${date.toLocaleDateString(selectedLanguage, {
        year: 'numeric'
      })}`
    }
  }

  return formattedDate
}

export const defaultDateFormatterTitle = (date: any) => {
  const dt = new Date(date);
  const formattedTitle = format(dt, 'EEE MMM dd yyyy')
  return formattedTitle
}

// Details: SVG may contain multiple charts grouped by g
// On Mouse Move (SVG) we will identify if the pointer position is over
// the chart render area (Ex: excluding the axis etc.,)
// Also identifies which group it is over and provide us the
// adjusted pointer x and y position
//  @e --> svg event element
//  @updatePointerPosition --> callback function to utilise the positions
//  @chartInstanceId --> unique chart instance ID created by base setup
// Additional Info: Need to add rect inside each group element (Check - plotAreaID)
export const pointerPositionOverRenderArea = (
  e: any,
  updatePointerCoordinates: any,
  chartInstanceId: string
) => {
  const canvasesRenderArea = plotAreaID(chartInstanceId, '')
  const rects = document.querySelectorAll(`[id^="${canvasesRenderArea}"]`)
  const { clientX, clientY } = e

  const canvasesPosition: any = {}
  rects?.forEach((rect: any) => {
    const rectDimensions: any = rect?.getBoundingClientRect()
    const rectX = Math.floor(rectDimensions?.x)
    const rectY = Math.ceil(rectDimensions?.y)
    const isMouseOverRect =
      clientX >= rectX &&
      clientX <= +rectX + +rectDimensions?.width &&
      clientY >= rectY &&
      clientY <= +rectY + +rectDimensions?.height
    const adjustedX = clientX - rectX
    const adjustedY = clientY - rectY
    const id = rect.getAttribute('id') || ''
    const canvasIndex = id?.[id?.length - 1]
    if (isMouseOverRect) {
      canvasesPosition[canvasIndex] = {
        x: adjustedX,
        y: adjustedY
      }
    }
  })
  const biggestKey = max(Object.keys(canvasesPosition) || []) || ''

  const zNearestCoordinates = canvasesPosition?.[biggestKey]
  if (zNearestCoordinates) {
    updatePointerCoordinates({
      coordinates: zNearestCoordinates,
      canvas: biggestKey,
      chartInstanceId
    })
  } else {
    updatePointerCoordinates(null)
  }
}

export const plotAreaID = (chartInstanceId: string, canvasIndex: any) => {
  return `plot-area-${chartInstanceId}-${canvasIndex}`
}

export const roundTime = (startDate, date, interval) => {
  const intM = interval / 60
  const sDate: any = new Date(startDate)
  const cDate: any = new Date(date)
  cDate.setSeconds(0)

  const roundedMinutes = Math.round(cDate.getMinutes() / intM) * intM
  cDate.setMinutes(roundedMinutes)

  const diffInMin = (cDate - sDate) / (1000 * 60)

  const index = Math.floor(diffInMin / intM)

  return { index, cDate }
}

export const getBandWidth = (scale: any) => {
  return scale?.bandwidth ? scale?.bandwidth() : 0
}