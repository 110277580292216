import _get from "lodash/get"
import _minBy from "lodash/minBy"
import _maxBy from "lodash/maxBy"
import _truncate from "lodash/truncate"
import { FIELD_TYPES } from "../../../components/legacy/common/pdf"
import PropTypes from "prop-types"
import { StyleSheet, Text, View } from "@react-pdf/renderer"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { formatStatus, formatNextStep } from "../../../components/legacy/common/finding"
import { DATE_FORMAT } from "../../../components/legacy/common/time-helpers"
import PDFTable from "../../../components/print-components/pdf-table"
import PDFHeader from "../../../components/print-components/pdf-header"
import PDFTitle from "../../../components/print-components/pdf-header/pdf-title"
import PDFFooter from "../../../components/print-components/pdf-footer"
import RichText from "../../../components/print-components/pdf-rich-text"
import colors from "../../../components/legacy/common/colors.json"
import { TranslateComponent } from "src/common/translations"

export const findingStyles = StyleSheet.create({
	flexColumn: {
		display: "flex",
		flexDirection: "column",
	},
	additional: {
		flexWrap: "wrap",
		padding: 8,
		margin: 5,
		width: "85%",
		backgroundColor: colors.WHITE,
		border: `1 solid ${colors.TBA_DARK_GREY}`,
		borderBottom: `4 solid ${colors.TBA_DARK_GREY}`,
	},
	additionalValue: {
		fontWeight: "bold",
		color: colors.TABLE_CONTENT_COLOR
	},
	name: {
		fontWeight: "bold",
	},
	equipment: {
		fontStyle: "italic"
	},
	totalEquipment: {
		paddingTop: 10,
		fontStyle: "italic"
	},
	dateWrapper: {
		fontSize: 10,
		color: colors.TBA_DARK_GREY,
		textAlign: "right",
		fontWeight: "bold"
	}
})

export const headings = t => [
	{
		heading: "opportunities:FindingName",
		field: "title",
		width: "16.6%",
		fieldType: FIELD_TYPES.COMPONENT,
		component: (heading, column) => {
			const fieldValue = _get(column, heading.field, "");
			return <Text style={findingStyles.name}>{fieldValue}</Text>
		}
	},
	{
		heading: "opportunities:Date",
		field: "creationDate",
		width: "12%",
		fieldType: FIELD_TYPES.COMPONENT,
		component: (heading, column) => <Text>{column?.creationDate ? moment(column.creationDate).format("MM/DD/YYYY") : ""}</Text>
	},
	{
		heading: "opportunities:AboutThisFinding",
		field: "comment",
		fieldType: FIELD_TYPES.COMPONENT,
		component: (headings, columns) => {
			const content = columns?.comment ? JSON.parse(_get(columns, "comment", "")) : null;
			const blocks = content?.blocks ?? [];
			const entityMap = content?.entityMap ?? {};
			const note = { blocks: [] };
			let count = 0;
			
			for (let i = 0; i < blocks.length; i++) {
			  if (blocks[i]?.text) {
				count += blocks[i].text.length;
				
				// reset all styles except ordered and unordered lists
				const type = ["unordered-list-item", "ordered-list-item"].includes(blocks[i].type) ? blocks[i].type : "unstyled";
				const clearStyles = { inlineStyleRanges: [], type };
				
				if (count < 200) {
				  note.blocks.push({ ...blocks[i], ...clearStyles });
				} else {
				  note.blocks.push({
					...blocks[i],
					text: _truncate(blocks[i].text, { length: 200 - (count - blocks[i].text.length), separator: " " }),
					...clearStyles
				  });
				  note['entityMap'] = entityMap;
				  break;
				}
			  }
			}
			
			if (!note || !note?.blocks || note?.blocks?.length === 0) {
			  if (content) {
				let result =''
				if (Array.isArray(content)) {
					result = content?.map?.(it => it.text)?.join() ;
				  } else {
					result = content;
				  }
				note.blocks = [{ key: "637gr", text: result || '', type: "unstyled", depth: 0, inlineStyleRanges: [], entityRanges: [], data: [] }];
				note['entityMap'] = {};
			  }
			}
			return <View style={findingStyles.flexColumn}><RichText note={note} /></View>;
		  },
		width: "33.3%"
	},
	{
		heading: "opportunities:LocationEquipment",
		width: "21.3%",
		fieldType: FIELD_TYPES.COMPONENT,
		component: (headings, columns) => {
			const tisObjects = columns?.equipments?.items?.map(e => e?.equipment).filter(e => !!e) ?? []
			const { name, postalCode, address, address2 } = columns?.building ?? {}
			return <View>
				<Text>{name} {(address || address2) && `${postalCode??''} ${address ?? ''} ${address2 ?? ''}`}</Text>
				{tisObjects?.length && tisObjects?.slice(0, 3)?.map?.(({ type, name }, index) => <Text style={findingStyles?.equipment} key={index}>{`${type} > ${name}`}</Text>)}
				{tisObjects?.length > 3 && <Text style={findingStyles.totalEquipment}>({tisObjects.length}) {t("common:TotalPiecesOfEquip")}</Text>}
			</View>
		}
	},
	{
		heading: "",
		width: "16.6%",
		hideHeading: true,
		fieldType: FIELD_TYPES.COMPONENT,
		component: (heading, column) => {
			const priority = column?.priority ? _get(column, "priority", false) : ""
			const status = column?.status ? _get(column, "status", false) : ""
			const nextStep = column?.nextStep ? _get(column, "nextStep", false) : ""

			return <>
				{(!priority && !status && !nextStep) ? <></> : <View style={{ ...findingStyles.flexColumn, ...findingStyles.additional }}>
					{priority ? <View>
						<Text>{t("opportunities:Priority")}:</Text>
						<Text style={findingStyles.additionalValue}>{priority}</Text>
					</View> : <Text />}
					{formatStatus(status) && <View>
						<Text>{t("opportunities:Status")}:</Text>
						<Text style={findingStyles.additionalValue}>{formatStatus(status)}</Text>
					</View>}
					{formatNextStep(nextStep) && <View>
						<Text>{t("opportunities:NextStep")}:</Text>
						<Text style={findingStyles.additionalValue}>{formatNextStep(nextStep)}</Text>
					</View>}
				</View>}
			</>
		}
	},
]

const PdfFindingList = ({ headerLocation, headerOrganization, isFooterCount, columns, sortedBy, filteredBy, showNotes, t }) => {
	return <>
		<PDFHeader
			isFixed={true}
			locationNames={[headerLocation]}
			organizationName={headerOrganization}
		/>
		<Text fixed={true} style={findingStyles.dateWrapper}>
			<Text>{`${moment(_minBy(columns, "creationDate")?.creationDate).format(DATE_FORMAT)} - ${moment(_maxBy(columns, "creationDate")?.creationDate).format(DATE_FORMAT)}`}</Text>
		</Text>
		<PDFTitle
			pageTitle={"Issues/Findings List"}
		/>
		<PDFFooter isCountPage={isFooterCount} />
		<PDFTable columns={columns}
			headings={headings(t)}
			sortedBy={sortedBy}
			filteredBy={filteredBy}
			showNotes={showNotes}
			withTranslation={true}
		/>
	</>
}
PdfFindingList.propTypes = {
	headerLocation: PropTypes.string.isRequired,
	isFooterCount: PropTypes.bool,
	columns: PropTypes.array.isRequired,
	filteredBy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
	sortedBy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
	showNotes: PropTypes.bool.isRequired,
	t: PropTypes.func.isRequired
}

export default withTranslation()(PdfFindingList)
