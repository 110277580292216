import { StyleSheet } from "@react-pdf/renderer"

export const summaryStyles = StyleSheet.create({
	summaryWrapper: {
		marginBottom: 20,
		fontSize: 10,
		width: 260
	},
	summaryTitle: {
		marginBottom: 7,
		fontSize: 12,
		fontWeight: "bold",
	},
})
