import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  TableStyle,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableBody,
  TableRow,
  TableCell,
  ArrowSpan,
  HeaderDiv
} from './styles'
import Icon from 'src/components/Icon'
import {
  PaginationContainer,
  ViewText,
  PaginationLeftContainer,
  PaginationNumbers,
  // PaginationNumber,
  PaginationNumberButton,
  PaginationRightContainer,
  PaginationPageSelectContainer,
  PaginationPageLabelContainer
} from './pagination-styles'
import { Checkbox } from '../../inputs/checkbox'
import { useTheme } from '@aws-amplify/ui-react'
import { TranslateComponent } from '../../../common/translations'

// TODO: The isError prop isn't currently working/matching new error styles.
const Table = ({
  columns,
  rows,
  total, //Total is a predicted/known value of number of items and on load all items need not be loaded.This is done  to achive on demand load
  // or lazy loading.Example: total can be 100, and on load 10 items can be loaded, and then based on different events like click on
  //pagination further items can be loaded untill all 100 items are loaded.
  emptyMessage,
  tableName,
  isError,
  paginationEnabled,
  paginationRowOptions,
  onPaginationChanges,
  selectedItemsCountPerPage,
  selectedPage,
  onSortChange,
  currentSortedField = null,
  currentSortedOrder = 'asc',
  header,
  cellHeight,
  newTheme,
  className,
  fixedLayout,
  pageName
}) => {
  const { tokens } = useTheme()
  const theme = tokens.components[newTheme ? '_newTable' : '_table']
  const [sortField, setSortField] = useState(currentSortedField)
  const [ascending, setSortAscendingDescending] = useState(
    currentSortedOrder === 'asc' ? true : false
  )
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(
    paginationEnabled &&
      paginationRowOptions &&
      paginationRowOptions.length &&
      (selectedItemsCountPerPage || paginationRowOptions[0])
  )

  const [currentPage, setCurrentPage] = useState(selectedPage || 0)
  // Finds the default sortable column (first present). -1 if there is none.
  useEffect(() => {
    if (currentSortedField) {
      return
    }
    const sortField =
      columns.findIndex((c) => c.defaultSortable) !== -1
        ? columns[columns.findIndex((c) => c.defaultSortable)].name
        : columns.findIndex((c) => c.sortable) !== -1
        ? columns[columns.findIndex((c) => c.sortable)].name
        : null
    setSortField(sortField)
  }, [columns, currentSortedField])

  useEffect(() => {
    currentSortedOrder &&
      setSortAscendingDescending(currentSortedOrder === 'asc' ? true : false)
  }, [currentSortedOrder])

  useEffect(() => {
    setCurrentPage(selectedPage || 0)
  }, [selectedPage])

  useEffect(() => {
    setSelectedRowsPerPage(
      paginationEnabled &&
        paginationRowOptions &&
        paginationRowOptions.length &&
        (selectedItemsCountPerPage || paginationRowOptions[0])
    )
  }, [selectedItemsCountPerPage])

  const sortedRows =
    !(typeof onSortChange === 'function') && sortField
      ? rows?.sort((a, b) => {
          if (columns.find((c) => c.name === sortField)?.sortFn) {
            return sortField.sortFn(a, b)
          }
          const sortValue = columns.find((c) => c.name === sortField)?.sortValue
            ? columns.find((c) => c.name === sortField).sortValue
            : sortField
          return ascending
            ? a[sortValue]?.localeCompare(b[sortValue])
            : b[sortValue]?.localeCompare(a[sortValue])
        })
      : rows
  const finalRows = total
    ? sortedRows
    : paginationEnabled && selectedRowsPerPage
    ? sortedRows?.slice(
        currentPage * selectedRowsPerPage,
        currentPage * selectedRowsPerPage + selectedRowsPerPage
      )
    : sortedRows

  let pageCount = 1
  if (selectedRowsPerPage && !isNaN(selectedRowsPerPage)) {
    if (total && !isNaN(total)) {
      pageCount = Math.ceil(total / selectedRowsPerPage)
    } else {
      pageCount =
        rows?.length > 0
          ? Math.ceil(rows.length / selectedRowsPerPage)
          : pageCount
    }
  }

  const renderHeader = () => {
    return (
      <HeaderDiv cellHeight={cellHeight} theme={theme}>
        {header}
      </HeaderDiv>
    )
  }

  const renderColumns = (theme, cellHeight, tokens) => {
    return (
      <TableHead isError={isError} theme={theme} cellHeight={cellHeight}>
        <TableHeadRow>
          {columns.map((column, index) => {
            return column.headerComponent ? (
              column.headerComponent(column)
            ) : (
              <TableHeadCell
                theme={theme}
                key={`${tableName}HeadRow${index}`}
                boldName={sortField === column.name}
                header={header}
                align={column.align}
                onClick={() => {
                  if (column.sortable) {
                    if (sortField === column.name) {
                      setSortAscendingDescending(!ascending)
                      onSortChange?.({
                        field: column.name,
                        direction: ascending ? 'desc' : 'asc'
                      })
                    } else {
                      setSortField(column.name)
                      onSortChange?.({ field: column.name, direction: 'asc' })
                    }
                  }
                }}
                className={column.hoverOnlyVisible && 'hoverOnlyHeader'}
              >
                {column.displayName !== null ? column.displayName : column.name}
                {column.sortable && sortField === column.name ? (
                  <ArrowsForSorting
                    tokens={tokens}
                    active={!ascending ? 'descending' : 'ascending'}
                  />
                ) : null}
              </TableHeadCell>
            )
          })}
        </TableHeadRow>
      </TableHead>
    )
  }

  const renderRows = (theme, cellHeight, tokens) => {
    return (
      <TableBody theme={theme}>
        {finalRows?.length === 0 ? (
          <TableRow cellHeight={cellHeight} theme={theme}>
            <TableCell
              id="no-records-row"
              cellHeight={cellHeight}
              colSpan={columns.length}
              theme={theme}
            >
              <TranslateComponent>{emptyMessage || 'No records found'}</TranslateComponent>
            </TableCell>
          </TableRow>
        ) : (
          finalRows?.map((rowData, rowIndex) => {
            return (
              <>
                <Row
                  cellHeight={cellHeight}
                  rowData={rowData}
                  rowIndex={rowIndex}
                  tableName={tableName}
                  columns={columns}
                  key={`${tableName}row${rowIndex}page${currentPage}`}
                  theme={theme}
                  tokens={tokens}
                  fixedLayout={fixedLayout}
                />
              </>
            )
          })
        )}
      </TableBody>
    )
  }

  return (
    <TableContainer className={className}>
      <div className="table-wrapper">
        {header ? renderHeader() : ''}
        <TableStyle
          isError={isError}
          header={header}
          theme={theme}
          fixedLayout={fixedLayout}
        >
          {renderColumns(theme, cellHeight, tokens)}
          {renderRows(theme, cellHeight, tokens)}
        </TableStyle>
      </div>
      {paginationEnabled && pageCount ? (
        <PaginationContainer>
          <PaginationLeftContainer>
            <ViewText><TranslateComponent>View</TranslateComponent></ViewText>
            <PaginationNumbers>
              {paginationRowOptions.map((e, key) =>
                selectedRowsPerPage === e ? (
                  <PaginationNumberButton
                    data-testid={`${pageName}_pagination_${e}perpage_button`}
                    size="small"
                    variant="link"
                    disabled={true}
                    theme={theme}
                    key={key}
                  >
                    {' '}
                    {e}{' '}
                  </PaginationNumberButton>
                ) : (
                  <PaginationNumberButton
                    data-testid={`${pageName}_pagination_${e}perpage_button`}
                    key={key}
                    size="small"
                    variant="link"
                    onClick={() => {
                      setSelectedRowsPerPage(e)
                      setCurrentPage(0)
                      onPaginationChanges?.({
                        currentPage: 0,
                        selectedRowsPerPage: e
                      })
                    }}
                  >
                    {' '}
                    {e}{' '}
                  </PaginationNumberButton>
                )
              )}
            </PaginationNumbers>
          </PaginationLeftContainer>
          <PaginationRightContainer>
            <PaginationPageLabelContainer>
              <TranslateComponent>Page</TranslateComponent> {currentPage + 1}  <TranslateComponent>Of</TranslateComponent> {pageCount}
            </PaginationPageLabelContainer>
            <PaginationPageSelectContainer
              data-testid={`${pageName}_pagination_paginationarrow_iconbutton`}
            >
              <Icon
                name="First"
                color={tokens.colors.GREY_7}
                height="100%"
                viewBox="0 0 1024 1024"
                onClick={() => {
                  if (currentPage !== -0) {
                    setCurrentPage(0)
                    onPaginationChanges?.({
                      currentPage: 0,
                      selectedRowsPerPage
                    })
                  }
                }}
              />
              <Icon
                name="back"
                color={tokens.colors.GREY_7}
                height="18px"
                width="12px"
                onClick={() => {
                  if (currentPage !== 0) {
                    const page = currentPage - 1
                    setCurrentPage(page)
                    onPaginationChanges?.({
                      currentPage: page,
                      selectedRowsPerPage
                    })
                  }
                }}
              />
              <Icon
                name="back"
                color={tokens.colors.GREY_7}
                height="18px"
                width="12px"
                style={{ transform: 'rotate(180deg)' }}
                onClick={() => {
                  if (currentPage < pageCount - 1) {
                    const page = currentPage + 1
                    setCurrentPage(page)
                    onPaginationChanges?.({
                      currentPage: page,
                      selectedRowsPerPage
                    })
                  }
                }}
              />
              <Icon
                name="Last"
                color={tokens.colors.GREY_7}
                height="100%"
                viewBox="0 0 1024 1024"
                onClick={() => {
                  if (currentPage < pageCount - 1) {
                    setCurrentPage(pageCount - 1)
                    onPaginationChanges?.({
                      currentPage: pageCount - 1,
                      selectedRowsPerPage
                    })
                  }
                }}
              />
            </PaginationPageSelectContainer>
          </PaginationRightContainer>
        </PaginationContainer>
      ) : (
        ''
      )}
    </TableContainer>
  )
}

const ArrowsForSorting = ({ active, tokens }) => {
  return (
    <ArrowSpan>
      {active === 'ascending' ? (
        <Icon
          name="arrow-up"
          color={tokens.colors.WHITE}
          width="9px"
          height="9px"
        />
      ) : (
        <Icon
          name="arrow-down"
          color={tokens.colors.WHITE}
          width="9px"
          height="9px"
        />
      )}
    </ArrowSpan>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      subTableData: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
    })
  ),
  emptyMessage: PropTypes.string,
  tableName: PropTypes.string,
  isError: PropTypes.bool,
  paginationEnabled: PropTypes.bool,
  paginationRowOptions: PropTypes.array
}

Table.defaultProps = {
  paginationEnabled: true,
  paginationRowOptions: [30, 60, 90],
  selectedItemsCountPerPage: 30
}

const Row = ({
  rowData,
  rowIndex,
  tableName,
  columns,
  tokens,
  theme,
  cellHeight,
  fixedLayout,
  indent = 0
}) => {
  const [subTableOpen, setSubTableState] = useState(false)
  const { subTableData } = rowData

  const renderCell = (data, columnKey, column, columnIndex, sub) => {
    return (
      <TableCell
        cellHeight={cellHeight}
        className={column.hoverOnlyVisible && 'hoverOnly'}
        maxWidth={column && column.maxWidth}
        align={column.align}
        key={`${tableName}tablecell${columnIndex}`}
        theme={theme}
        fixedLayout={fixedLayout}
        style={{
          paddingLeft:
            columnIndex === 0 ? `${!indent ? 10 : indent * 30}px` : undefined
        }}
      >
        <span>
          {data.rowCheckboxEnabled && columnIndex === 0 ? (
            <span style={{ marginRight: '10px' }}>
              <Checkbox
                key={`LocationTableCheckbox${data.organizationId}`}
                checked={data.rowCheckboxChecked}
                intermediate={data.rowCheckboxIntermediate}
                onClick={() => data.rowCheckboxOnClick()}
                testName={`${pageName}_buildinglist_buildingrow`}
              />
            </span>
          ) : (
            ''
          )}
          {sub && columnIndex === 0 ? (
            <Icon
              name="Down"
              color={tokens.colors.TBA_DARKER_GREY}
              height="100%"
              viewBox="0 0 1024 1024"
              width="15px"
              transition={'all 0.4s ease'}
              cursor="pointer"
              margin="0 5px 0 0"
              top="2px"
              position="relative"
              transform={subTableOpen ? null : 'rotate(-90deg)'}
              onClick={() => setSubTableState(!subTableOpen)}
            />
          ) : (
            ''
          )}
          {column?.cellComponent && typeof column.cellComponent === 'function'
            ? column.cellComponent(data, column, columnKey, columnIndex, sub)
            : data[columnKey]}
        </span>
      </TableCell>
    )
  }

  return (
    <>
      <TableRow
        cellHeight={cellHeight}
        key={`${tableName}row${rowIndex}`}
        className="tableBodyRow"
        theme={theme}
      >
        {columns.map((column, cellIndex) => {
          return renderCell(
            rowData,
            column.key,
            column,
            cellIndex,
            subTableData
          )
        })}
      </TableRow>
      {subTableData?.length && subTableOpen
        ? subTableData.map((subRowObject, subRowIndex) => {
            return (
              <Row
                cellHeight={cellHeight}
                rowData={subRowObject}
                rowIndex={subRowIndex}
                tableName={tableName}
                columns={columns}
                key={`${tableName}_row_${subRowIndex}_indent_${indent + 1}`}
                theme={theme}
                tokens={tokens}
                indent={indent + 1}
                fixedLayout={fixedLayout}
              />
            )
          })
        : null}
    </>
  )
}

export default Table
