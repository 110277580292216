// Components \\
import styled, { css, createGlobalStyle } from 'styled-components'

import icons from '../../common_file/emerald-icons.json'
import Emerald from '../../assets/fonts/Emerald.woff'

export const GlobalFontFace = createGlobalStyle`
	@font-face {
		font-family: "Emerald";
		src: local("Emerald"), local("Emerald"),
		url(${Emerald}) format('woff');
		font-weight: normal;
		font-style: normal;
	}
`
const iconsList = (props) => {
  return `.I_${props.type}::before {
    content: "${icons[props.type]}";
  }`
}

export const IconContainer = styled.span`
  color: ${(props) =>
    props.disabled && props.disabled ? '#CCCCCC' : props.color};
  font-size: ${(props) => props.size && props.size};
  margin: ${(props) => props.margin && props.margin};
  padding: ${(props) => props.padding && props.padding};
  text-decoration: ${(props) => props.decoration && props.decoration};
  ${(props) => props.transform && `transform: ${props.transform};`}
  transition: ${(props) => props.transition && props.transition};
  display: ${(props) => props.display && props.display};
  height: ${(props) => props.height && props.height};
  width: ${(props) => props.width && props.width};
  float: ${(props) => props.float && props.float};
  line-height: ${(props) => props.line && props.line};
  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`}
	${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
	${(props) => props.verticalAlign && `vertical-align: ${props.verticalAlign};`}
	${(props) => props.textAlign && `text-align: ${props.textAlign};`}
	${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
	${(props) =>
    props &&
    props.hover &&
    css`
      &:hover {
        color: ${(props) =>
          props.disabled && props.disabled ? '#CCCCCC' : props.hover};
      }
    `}
	cursor:${(props) => props.cursor && props.cursor};
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Emerald' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &${(props) => props.type && iconsList(props)}
    .icon.icon-loader.icon-loader--spin {
    display: inline-block;
    animation: rotate 1.2s infinite linear;
  }
`
