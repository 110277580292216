import React, { useEffect, useState } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { HeaderBlock, HeaderItem } from './styles'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import CharacteristicCurveHistoryDetailsTable from './CharacteristicCurveHistoryDetailsTable'
import moment from 'moment'
import { TEXT_DATE_TIME_FORMAT } from 'src/components/legacy/common/time-helpers'
import { frameHistoryChangesData } from '../helper'
import { GET_CHARACTERISTIC_CURVE } from '../../graphql/queries'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import translate from '../../../../common/translations'
import _isEmpty from 'lodash/isEmpty'

const CharacteristicCurveHistoryDetails = ({
  changes,
  closeDialog,
  isUpdating = false,
  revertCurveFromHistory,
  curveHistoryId
}) => {
  const [t] = useTranslation()
  const [changeDetails, setChangeDetails] = useState([])
  const columns = changes?.map((change) => ({
    setting: change.fieldName,
    previous: change.oldValue,
    current: change.newValue
  }))
  const {
    data: curveEditedData,
    refetch: fetchCharacteristicCurve,
    loading: loadingCharacteristicCurveDetails
  } = useQuery({
    query: GET_CHARACTERISTIC_CURVE,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getCharacteristicCurve'
  })

  useEffect(() => {
    if (curveEditedData) {
      const changedValue = curveEditedData?.changedValue
      if (!_isEmpty(changedValue)) {
        const changedValueJson = JSON.parse(changedValue)
        const changeDetailsList = frameHistoryChangesData(changedValueJson)
        setChangeDetails(changeDetailsList)
      }
    }
  }, [curveEditedData])

  useEffect(() => {
    if (curveHistoryId) {
      fetchCharacteristicCurve({ id: curveHistoryId })
    }
  }, [curveHistoryId])
  const dateTime = curveEditedData?.createdAt
    ? moment(curveEditedData.createdAt).format(TEXT_DATE_TIME_FORMAT)
    : ''
  const onClose = () => {
    closeDialog()
  }

  const onRevert = () => {
    revertCurveFromHistory(curveEditedData)
  }

  const Curve = {
    "CurveName" : translate("Curve Name"),
    "UpdatedBy" : translate("Updated By"),
    "DateAndTime" : translate("Date and Time")
  }

  const modalConfig = {
    gray: true,
    heading: t('equipment-setup:CharacteristicCurveHistory>CurveHistoryDetail'),
    loading: isUpdating,
    className: 'wide-modal',
    buttons: [
      {
        text: 'Revert',
        handleClick: onRevert,
        type: 'confirm',
        disabled: isUpdating
      },
      {
        text: t('common:Close'),
        handleClick: onClose,
        type: 'cancel',
        disabled: isUpdating
      }
    ].filter(Boolean),
    handleClose: onClose
  }
  return (
    <Modal {...modalConfig}>
      {loadingCharacteristicCurveDetails || isUpdating ? (
        <Spinner />
      ) : (
        <>
          <HeaderBlock>
            <HeaderItem>
              <label>
                {(Curve.CurveName)}
              </label>
              <div>{curveEditedData?.name || ''}</div>
            </HeaderItem>
            <HeaderItem>
              <label>
                {(Curve.UpdatedBy)}
              </label>
              <div>{curveEditedData?.updatedBy || curveEditedData?.createdBy || '' }</div>
            </HeaderItem>
            <HeaderItem>
              <label>
                {(Curve.DateAndTime)}
              </label>
              <div>{dateTime}</div>
            </HeaderItem>
          </HeaderBlock>
          <CharacteristicCurveHistoryDetailsTable
            changeDetails={changeDetails}
          />
        </>
      )}
    </Modal>
  )
}

CharacteristicCurveHistoryDetails.propTypes = {
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      oldValue: PropTypes.string,
      newValue: PropTypes.string
    })
  ),
  closeDialog: PropTypes.func,
  curveName: PropTypes.string,
  isUpdating: PropTypes.bool,
  revertCurveFromHistory: PropTypes.func,
  updatedBy: PropTypes.string,
  updatedOn: PropTypes.string
}

export default CharacteristicCurveHistoryDetails
