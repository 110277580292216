/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxisDiM } from '../../../chart-library/Utils/defaults'
import { useChartContext } from '../../../chart-library/CommonComponents/BaseWrapper'
import { useCallback, useMemo } from 'react'

export function useConfig() {
  const chart = useChartContext()

  const grid = chart.grid
  const axis = chart.axis
  const scale = chart.scale

  const canvas = useMemo(() => grid.getGrid(0), [grid.lastUpdatedAt])!

  // xAxis
  const xAxis = useMemo(() => axis.getAxis(AxisDiM.x, 0), [axis.lastUpdatedAt])!

  const xScale = useMemo(
    () => scale.getScale(AxisDiM.x, 0),
    [scale.lastUpdatedAt]
  )!

  // yAxis
  const yAxis = useMemo(() => axis.getAxis(AxisDiM.y, 0), [axis.lastUpdatedAt])!

  const yScale = useMemo(() => {
    const y = scale.getScale(AxisDiM.y, 0)

    if (!y) return y

    y.fn.paddingInner(0.2)
    y.fn.paddingOuter(0.5)

    return y
  }, [scale.lastUpdatedAt])!

  const getSavingsXYposition = useCallback(
    (savingsData) => {
      return {
        goalX: scale.lastUpdatedAt ? xScale.fn(savingsData.goal) : 0,
        totalX: scale.lastUpdatedAt ? xScale.fn(savingsData.total) : 0,
        y: scale.lastUpdatedAt ? yScale.fn('Upper') : 0
      }
    },
    [scale.lastUpdatedAt]
  )

  return {
    axis,
    AxisDiM,
    axisUpdatedAt: axis.lastUpdatedAt,
    canvas,
    getSavingsXYposition,
    gridUpdatedAt: chart.grid.lastUpdatedAt,
    isInitialized: !(!scale.lastUpdatedAt || !axis.lastUpdatedAt),
    scale,
    scaleUpdatedAt: scale.lastUpdatedAt,
    xAxis,
    xScale,
    yAxis,
    yScale
  }
}
