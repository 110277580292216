import memoize from 'memoize-one'
import i18next from 'i18next'
import _uniqBy from 'lodash/uniqBy'

export const SELECT_ALL = { key: 'all', value: 'Select All' }
export const CLEAR_ALL = { key: 'clear', value: 'Clear All' }
export const EQUIPMENT_LENGTH_WITH_CLEAR_ALL = 4

export const getEquipmentSelectOptions = memoize((equipments, tisObjectIds) => {
  const selectAllTypes = getAllTypesSelectOptions(equipments)
  if (equipments.length !== tisObjectIds.length) {
    // If not all item selected we should add 'select all' option
    if (tisObjectIds.length >= EQUIPMENT_LENGTH_WITH_CLEAR_ALL) {
      // If selected more than four item we should add 'clear all' option
      return [...equipments, ...selectAllTypes, SELECT_ALL, CLEAR_ALL]
    }
    return [...equipments, ...selectAllTypes, SELECT_ALL]
  }
  if (tisObjectIds.length >= EQUIPMENT_LENGTH_WITH_CLEAR_ALL) {
    return [...equipments, CLEAR_ALL]
  }
  return equipments
})

export const getAllTypesSelectOptions = (equipments) => {
  return getAllEquipmentTypes(equipments).map((type) => ({
    key: type,
    value: `${i18next.t('common:SelectAll')} ${type}`
  }))
}

export const getAllEquipmentTypes = (equipments) => {
  return _uniqBy(equipments, 'type')
    .map(({ type }) => type)
    .sort((a, b) => a.localeCompare(b))
}
