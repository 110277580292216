import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Select from 'src/components/legacy/components/select/select'

import { StatusEditWrapper } from './Styles'
import translate from 'src/common/translations'

function StatusEdit(props) {
  const {
    statuses,
    status,
    onChangeStatus,
    nextSteps,
    nextStep,
    onChangeNextStep
  } = props
  const optionsTranslated = {
    status: translate("Status"),
    nextStep: translate("Next step"),
  }

  return (
    <StatusEditWrapper>
      <div className="statuses" data-testid="status">
        <div className="horizontal-control-block right">
          <label>{optionsTranslated.status}:</label>
          <Select
            options={statuses}
            onChange={onChangeStatus}
            selectedItem={status}
          />
        </div>
        <div className="horizontal-control-block right">
          <label>{optionsTranslated.nextStep}:</label>
          <Select
            options={nextSteps}
            onChange={onChangeNextStep}
            selectedItem={nextStep}
          />
        </div>
      </div>
    </StatusEditWrapper>
  )
}

StatusEdit.propTypes = {
  statuses: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  nextStep: PropTypes.string.isRequired,
  nextSteps: PropTypes.array.isRequired,
  onChangeNextStep: PropTypes.func.isRequired
}

export default StatusEdit
