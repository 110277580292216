export const PAGE_SIZE = "Letter"

export const FIELD_TYPES = {
	STRING: "string",
	COMPONENT: "component",
}

export const PDF_PAGE_TYPES = {
	TABLE: "table",
	CUSTOM_PAGE: "custom_page",
	EU_REPORT: "eu_report"
}
