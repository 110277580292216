// React/Redux Libraries \\
import React, { Component } from "react"
import PropTypes, { node, string, bool } from "prop-types"
import styled from "styled-components"
import InlineHelp from "../inline-help/inline-help"

export const ActionsToolBar = styled.div`
		background: ${props => props.background || "transparent"};
		padding: ${props => props.padding || "0 0 10px 0"};
		border: ${props => props.border || "none"};
		border-bottom: ${props => props.borderBottom || "none"};
		border-top: ${props => props.borderTop || "none"};

		@media all and (max-width: 768px) {
			display: flex;
			justify-content: ${props => props.alignment || "space-between"};
			align-items: flex-end;
		}
		@media all and (min-width: 769px) {
			display: flex;
			flex-wrap: wrap;
	    align-items: flex-end;
	    justify-content: ${props => props.alignment || "space-between"};
		}
`

export const Title = styled.h1`
	font-size: ${props => props.titleSize || "18px"};
	font-weight: bold;
	padding: 0;
	margin: 0;
	padding-right: 10px;
`

export const Action = styled.div`
	font-size: 14px;
	padding-top: 1px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	button {
		margin-left: 5px;
	}
	h1,h2,h3 {
		margin: 0 10px;
	}
`

export class ActionToolBar extends Component {
	static propTypes = {

		/** Padding for the inside of the toolbar. Use standard CSS declaration for padding. */
		padding: string,

		/** Define background for the toolbar. Use standard CSS declaration for background. */
		background: string,

		/** Define border for entire toolbar. Use standard CSS declaration for border. (By default there is no border defined.) */
		border: string,

		/** Override bottom border, or just set bottom border if no border is defined.  Use standard CSS declaration for border. */
		borderBottom: string,

		/** Override top border, or just set top border if no border is defined. Use standard CSS declaration for border. */
		borderTop: string,

		/** Override the default H1 size (18px). Use standard CSS declaration for H1 size. */
		titleSize: string,

		/** Text for the page/toolbar title */
		title: string,

		/** Flex-box CSS declaration for justify-content of the toolbar div. Default is 'space-between' */
		alignment: string,

		/** Nodes for the 'action' part of the Action toolbar. */
		children: node,

		/** If user has edit permissions show, else return null. */
		canEdit: PropTypes.oneOfType([string, bool]),

		/** If help text is required. */
		inlineHelpText: PropTypes.array
	}

	render() {
		const { title, children, canEdit, inlineHelpText } = this.props
		if (canEdit === false) {
			return null
		}

		return (
			<ActionsToolBar background={this.props.background} padding={this.props.padding} border={this.props.border} borderBottom={this.props.borderBottom} borderTop={this.props.borderTop} alignment={this.props.alignment}>
				<Title titleSize={this.props.titleSize}>
					{title} {inlineHelpText && <InlineHelp inlineHelpText={inlineHelpText} />}
				</Title>
				<Action>
					{children}
				</Action>
			</ActionsToolBar>
		)
	}
}

export default ActionToolBar
