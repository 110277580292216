import { Image, Text, View, StyleSheet } from "@react-pdf/renderer"
import moment from "moment"
import PropTypes from "prop-types"

import { footerStyles } from "./pdf-footer.styles"

const PDFFooter = ({ isCountPage }) => {
	const { wrapper, footer, licenseWrapper, pageCount, logo, copyRight, textWrap, textWrapper, imageWrapper} = footerStyles
	const currentYear = moment().year()
	
	return	(
		<View style={footer} fixed={true}>
			<View style={wrapper}>
				<View>
					<View style={licenseWrapper}>
						{isCountPage && <Text style={pageCount} render={({ pageNumber, totalPages }) => (
							`${pageNumber} of ${totalPages} |`
						)} fixed={true} />}
						<View style={copyRight}>
						{isCountPage && <Image style={imageWrapper} src={require(`../../../assets/images/copyright-symbol.png`)} /> }
						</View>
						<View>
						    <Text style={textWrap}>{currentYear} Trane Technologies. All Rights Reserved.</Text>
							<Text style={textWrapper}>Confidential and proprietary information of Trane U.S. Inc.</Text>
							</View>
					</View>
				</View>
				<Image style={logo} src={require(`../../../assets/images/tt_logo_tm_gray.png`)} />
			</View>
		</View>
	)
}

PDFFooter.propTypes = {
	isCountPage: PropTypes.bool,
}

PDFFooter.defaultProps = {
	isCountPage: false
}

export default PDFFooter
