import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import ContainerWrapper from 'src/components/Container'
import PageHeader from '../../components/pageHeaderNew/header'
import { PAGE_TITLE } from './constants'
import {
  ToolBarWrapper,
  CustomWrapper,
  ContentWrap,
  ElectricalDemandMainContent,
  ElectricalDemandChartSection,
  ChartAreaWrapper,
  NoDataMessage
} from './styles.js'
import { useQuery } from 'src/hooks/APIHooks'
import { getSearchParams } from '../../common/helperFunctions.js'
import ElectricalDemandLineChart from './ElectricalDemandLineChart'
import ElectricalDemandHeatChart from './ElectricalDemandHeatChart'
import {
  WarningContainer,
  ChartSelectButton,
  EnergyConsumptionContainerDiv,
  HeaderResultBlock,
  ResultTitle
} from 'src/pages/energyConsumption/styles.js'
import { EquipmentListSelector } from 'src/components/equipmentsListSelector'
import { Block } from 'src/components/layouts'
import colors from 'src/components/layouts/colors.json'
import ChartDateControls from 'src/components/chartDateControls/chartdatecontrols'
import { BACKEND_DATE_FORMAT, TEXT_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import Icon from 'src/denali-ui/components/Icon/index'
import { TranslateComponent } from 'src/common/translations'
import { useGetChartData } from 'src/hooks/chartDataHook'
import { LIST_EVENT_MARKERS_BY_ACCOUNT } from '../eventMarkers/graphql'
import { HeaderNoResultBlock, ChartHeaderWrapper, DataAvailableInfo } from './styles.js'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { getEquipment } from '../energyCostSummary/graphql'
import translate from "src/common/translations"
import  withFeatureRestrictions from 'src/common/featureRestrictionHOC'



const chartOptions = {
  line: 'line',
  heat: 'heat'
}

const ElectricalDemandContainer = () => {
  const {
    getChartData,
    getChartDataByTimeSlider,
    chartData,
    chartDataByTimeSlider,
    resetChartData,
    loadingChartData
  } = useGetChartData()
  const { width } = useBreakPoint()
  const [selectedChart, setSelectedChart] = useState(chartOptions.line)
  const [selectedEquipment, setSelectedEquipment] = useState(null)
  const [markers, setMarkers] = useState([])
  const [lineChartData, setLineChartData] = useState(null)
  const [heatChartData, setHeatChartData] = useState(null)
  const [timeSliderStartTime, setTimeSliderStartTime] = useState(null)
  const [timeSliderEndTime, setTimeSliderEndTime] = useState(null)
  const [peakDemandValue, setPeakDemandValue] = useState(0)
  const [peakDemandTime, setPeakDemandTime] = useState('')
  const [equipmentCreatedDate, setEquipmentCreatedDate] = useState('')

  const [searchParams] = useSearchParams()
  const [meterAvailability, setMeterAvailability] = useState(true)

  const { buildingId, organizationId, equipment } = getSearchParams()
  const [chartDates, setChartDates] = useState({
    chartStartDate: moment().subtract(1, 'months').format(BACKEND_DATE_FORMAT),
    chartEndDate: moment().format(BACKEND_DATE_FORMAT),
    dateError: false,
    activeRange: '1M'
  })

  const { data: markersData, refetch: refetcMarkersData } = useQuery({
    query: LIST_EVENT_MARKERS_BY_ACCOUNT,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listEventMarkersByAccount.items'
  })
  
  const optionsTranslated = {
    data: translate("Data Available"),
  }

  const { data: Equipment, refetch: refetchEquipment } = useQuery({
    query: getEquipment,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getEquipment'
  })

  // If query parameters change, force the chart data to reset.
  useEffect(() => {
    resetChartData()
  }, [searchParams])

  useEffect(() => {
    if (buildingId) {
      setPeakDemandValue(0)
      setPeakDemandTime('')
      setLineChartData(null)
      setHeatChartData(null)
      setTimeSliderStartTime(null)
      setTimeSliderEndTime(null)
      setMeterAvailability(true)
      setSelectedEquipment(null)
      refetcMarkersData({
        accountId: organizationId,
        filter: { buildingIds: { contains: buildingId } }
      })
    }
  }, [buildingId, organizationId, searchParams])

  useEffect(() => {
    if (markersData) {
      setMarkers(markersData)
    }
  }, [markersData])

   // Called when there is no meters in the building
   const equipmentsAvailableCheck = (check) => {
    setMeterAvailability(check)
  }

  const onEquipmentChange = (equipmentDetails) => {
    setSelectedEquipment(equipmentDetails)
  }

  const changeChartSelection = () => {
    if (selectedChart === chartOptions.line) {
      setSelectedChart(chartOptions.heat)
      trackEvent(USER_EVENTS.CHARTS.events.CHANGE_CHART, {mode:"Heat"})
    } else {
      setSelectedChart(chartOptions.line)
      trackEvent(USER_EVENTS.CHARTS.events.CHANGE_CHART, {mode:"Line"})
    }
  }

  useEffect(() => {
    if (selectedEquipment) {
      // Call getEquipment API To Get Data Available Date
      refetchEquipment({
        id: selectedEquipment?.key
      })
      const chartDataRequest = {
        startDate: chartDates?.chartStartDate,
        endDate: chartDates?.chartEndDate,
        category: 'Equipment', // equipment or building level data needed
        chartType: 'Line',
        weatherProps: ['TemperatureF'],
        chartDetails: [
          {
            buildingId: buildingId,
            equipmentDetails: [
              {
                type: selectedEquipment.type,
                eId: [{ id: selectedEquipment.key }],
                pId: selectedEquipment.type === 'VirtualMeter' ? ["InstantaneousPower"] : ["RealPower"]
              }
            ]
          }
        ]
      }
      getChartData(chartDataRequest)
    }
  }, [selectedEquipment, chartDates])

  useEffect(() => {
    if (Equipment) {
      setEquipmentCreatedDate(
        moment(Equipment?.createdAt)?.format(TEXT_DATE_FORMAT)
      )
    }
  }, [Equipment])

  useEffect(() => {
    if (chartData && chartData?.data?.length > 0) {
      setLineChartData(chartData)
      setHeatChartData(chartData)
    }
  }, [chartData])

  useEffect(() => {
    const startTime = new Date(timeSliderStartTime).getTime()
    const endTime = new Date(timeSliderEndTime).getTime()
    getChartDataByTimeSlider(startTime, endTime)
  }, [timeSliderStartTime, timeSliderEndTime])

  useEffect(() => {
    if (chartDataByTimeSlider?.data?.length > 0)
      setLineChartData(chartDataByTimeSlider)
  }, [chartDataByTimeSlider])

  const onChartControlUpdate = ({
    startDate,
    endDate,
    endError,
    startError,
    activeRange
  }) => {
    setChartDates({
      chartStartDate: startDate,
      chartEndDate: endDate,
      dateError: startError || endError,
      activeRange
    })
  }

  const updatePeakDemand = (maxPeakDemand) => {
    if (maxPeakDemand?.value && +maxPeakDemand.value) {
      setPeakDemandValue(+maxPeakDemand.value)
      setPeakDemandTime(
        moment(maxPeakDemand.timeStamp).format('MMM DD, YYYY h:mm A')
      )
    } else {
      setPeakDemandValue(0)
      setPeakDemandTime('')
    }
  }

  return (
    <ContainerWrapper
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding="0"
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <PageHeader
          pageTitle={<TranslateComponent>{PAGE_TITLE}</TranslateComponent>}
          titleTools={
            <ToolBarWrapper>{/* ActionToolBar Here */}</ToolBarWrapper>
          }
        />
      </Content>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        {buildingId ? meterAvailability ? (
          <ElectricalDemandMainContent>
            <EnergyConsumptionContainerDiv>
              <ContentWrap>
                <div className="pd-equip">
                  <EquipmentListSelector
                    testName="electrical-demand"
                    equipmentTypes={['ElectricMeter', 'VirtualMeter']}
                    buildingId={buildingId}
                    onChange={onEquipmentChange}
                    equipmentsAvailableCheck={equipmentsAvailableCheck}
                    defaultEquipmentId={equipment}
                  />
                </div>
                <Block
                  display="flex"
                  align="flex-end"
                  justify="space-between"
                  padding="5px 0px"
                  borderBottom={`2px solid ${colors.TBA_DARKER_GREY}`}
                >
                  <Block padding="0px 0px 0px 20px">
                    <Block display="flex">
                      <ChartSelectButton
                        testName="electrical-demand-line"
                        onClick={changeChartSelection}
                        isSelected={selectedChart === chartOptions.line}
                      >
                        <span className="icon icon-linewidget" />
                        <span className="chart-select-name">LINE</span>
                      </ChartSelectButton>
                      <ChartSelectButton
                        testName="electrical-demand-heat"
                        onClick={changeChartSelection}
                        isSelected={selectedChart === chartOptions.heat}
                      >
                        <span className="icon icon-heat-map" />
                        <span className="chart-select-name">HEAT</span>
                      </ChartSelectButton>
                    </Block>
                  </Block>
                  <CustomWrapper>
                    <Block display="flex" padding="0px 0px 0px 20px">
                      {selectedChart === chartOptions.line ? (
                        <HeaderResultBlock testName="electrical-demand-total">
                          <ResultTitle>Peak Energy Demand</ResultTitle>
                          <h3>
                            <span className="peak-value">
                              {peakDemandValue
                                ? peakDemandValue.toFixed(2)
                                : '0.00'}
                            </span>
                            <span className="unit">kW</span>
                          </h3>
                          <div>
                            <span>{peakDemandTime}</span>
                          </div>
                        </HeaderResultBlock>
                      ) : (
                        <HeaderNoResultBlock></HeaderNoResultBlock>
                      )}
                    </Block>
                  </CustomWrapper>
                </Block>
              </ContentWrap>
            </EnergyConsumptionContainerDiv>
            <ChartAreaWrapper>
              <ChartHeaderWrapper>
                <ChartDateControls
                  // @ts-ignore
                  testName="electrical-demand"
                  entity='electrical-demand'
                  onUpdate={onChartControlUpdate}
                  rangesList={[
                    '2D',
                    '3D',
                    '5D',
                    '1W',
                    '1M',
                    '3M',
                    '6M',
                    'YTD',
                    '1Y'
                  ]}
                />
                <DataAvailableInfo>
                  <span>
                    <strong>{optionsTranslated.data}:</strong>
                  </span>
                  <div>
                    <span>{`${equipmentCreatedDate} - ${moment().format(TEXT_DATE_FORMAT)}`}</span>
                  </div>
                </DataAvailableInfo>
              </ChartHeaderWrapper>
              {selectedChart === chartOptions.line ? (
                <ElectricalDemandChartSection>
                  <ElectricalDemandLineChart
                    loadingChartData={loadingChartData}
                    chartData={lineChartData}
                    timeSliderChartData={chartData}
                    chartDates={chartDates}
                    markers={markers}
                    updatePeakDemand={updatePeakDemand}
                    setTimeSliderStartTime={setTimeSliderStartTime}
                    setTimeSliderEndTime={setTimeSliderEndTime}
                    selectedEquipment={selectedEquipment}
                  />
                </ElectricalDemandChartSection>
              ) : (
                <ElectricalDemandHeatChart
                  chartData={heatChartData}
                  selectedEquipment={selectedEquipment}
                  unit={'kW'}
                  loadingChartData={loadingChartData}
                />
              )}
            </ChartAreaWrapper>
          </ElectricalDemandMainContent>
        ) : (
          <NoDataMessage>
            No meters available in the selected building.
          </NoDataMessage>
        ) : (
          <ContentWrap>
            <Block display="flex" justify="center">
              {organizationId ? (
                <WarningContainer>
                  <Icon
                    name="Warning"
                    width="25px"
                    height="25px"
                    margin="0px 10px"
                    color={colors.RED}
                  />
                  <p>
                    {
                      <TranslateComponent>
                        You have chosen an organization, please specify a
                        location.
                      </TranslateComponent>
                    }
                  </p>
                </WarningContainer>
              ) : (
                <></>
              )}
            </Block>
          </ContentWrap>
        )}
      </Content>
    </ContainerWrapper>
  )
}
export default withFeatureRestrictions(
  ElectricalDemandContainer
)
