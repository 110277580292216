import { useEffect, useState, useMemo } from 'react'
import widgetTemplate from '../widget-template'
import { useTranslation } from 'react-i18next'
import Select from 'src/components/legacy/components/select/select'
import { WidgetTypePickerStyle } from '../styles'
import WidgetSampleImagePreview from './WidgetSampleImagePreview'
import Modal from 'src/components/legacy/components/modal/modal'
import ContainerWrapper from 'src/components/Container'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { useWidgetsContext } from '../WidgetsContextProvider'
import translate, { TranslateComponent } from 'src/common/translations'
import { ENERGY_WIDGETS, CUSTOM_EQUIPMENT_WIDGETS, ENERGY_STAR_SCORE } from 'src/constants'
import config from 'src/aws-exports.js'
import { cloneDeep } from "lodash"

const WidgetTypePicker = () => {
  const [energyWidgetTypes, setEnergyWidgetsTypes] = useState([])
  const [customWidgetTypes, setCustomWidgetTypes] = useState([])
  const [nonCategoryWidgetTypes, setNonCategoryWidgetTypes] = useState([])
  const [openPreview, isOpenPreview] = useState(false)
  const envType = cloneDeep(config)?.appEnvironment?.BUILD_ENV || 'dev'

  // These shall be removed once all widgets relased to stg
  const releaseWidgets = [
    'bar',
    'single-comparison-bar',
    'dual-comparison-bar',
    'custom-kpi',
    'weather',
    'orb',
    'image-text',
    'energy-demand',
    // 'energy-consumption',
    // 'line',
    // 'energy-consumption-new',
    // 'energy-cost',
    // 'gauge',
    // 'energy-star-score'
  ]

  const {
    selectedWidget,
    setSelectedWidget,
    setWidgetBuilding,
    setIsOpenCreateWidget,
    setOpenWidgetPicker
  } = useWidgetsContext()
  const [t] = useTranslation()
  const { width } = useBreakPoint()

  const optionsTranslated = {
    preview: translate('Preview'),
    select: translate('SELECT'),
    viewLarger: translate('VIEW LARGER'),
    selectWidget: 'Select Widget Type',
    energyWidget: translate('Energy Widget'),
    monitorEnergy: translate('Monitor energy cost, demand, gas consumption, and create baseline comparisons.'),
    selectEnergy: translate('Select Energy Widget'),
    customWidget: translate('Custom Equipment Widget'),
    choosePoint: translate('Choose any point coming from your equipment and display data on a custom widget.'),
    selectCustom: translate('Select Custom Widget')
  }

  const openWidgetPreviewModalConfig = {
    gray: true,
    removeFooter: true,
    heading: optionsTranslated.preview,
    handleClose: () => {
      setSelectedWidget(null)
      isOpenPreview(false)
    },
    className: 'widget-preview'
  }

  useEffect(() => {
    const widgets = widgetTemplate
      .getAll(t)
      .map(({ title, description, name, widgetCategory }) => ({
        title,
        widgetCategory,
        type: name,
        value: title,
        key: name,
        name,
        desc: description
      }))
    setEnergyWidgetsTypes(
      widgets.filter(
        ({ type }) => ENERGY_WIDGETS.includes(type) && allowedFeature(type)
      )
    )
    setCustomWidgetTypes(
      widgets.filter(
        ({ type }) =>
          CUSTOM_EQUIPMENT_WIDGETS.includes(type) && allowedFeature(type)
      )
    )
    setNonCategoryWidgetTypes(
      widgets.filter(
        ({ type }) =>
          !CUSTOM_EQUIPMENT_WIDGETS.includes(type) &&
          !ENERGY_WIDGETS.includes(type) &&
          allowedFeature(type) &&
          type !== ENERGY_STAR_SCORE
      )
    )
  }, [])

  const allowedFeature = (type) => type // shall check on Feature permissions
  

  const CustomWidgetTypeCard = ({
    generalTitle,
    categoryDescription,
    selectLabel,
    widgetTypes,
    isRequired,
  }) => {
    const [activeWidget, setActiveWidget] = useState(widgetTypes[0])
    const onUpdateFields = (activeWidget) => {
      setWidgetBuilding(null)
      setActiveWidget(activeWidget)
    }
    const handleOpenPreview = (activeWidget) => {
      setSelectedWidget(activeWidget)
      isOpenPreview(true)
    }
    const openWidgetCreation = (activeWidget) => {
      setSelectedWidget(activeWidget)
      setIsOpenCreateWidget(true)
      setOpenWidgetPicker(false)
    }
    const widgetValues = useMemo(
      () =>
        widgetTypes.map((widget, key) => ({
          key,
          value: widget.value
        })),
      [widgetTypes]
    )
    const type = activeWidget?.type
    return (
      <Content
        xlColumn={6}
        lColumn={6}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <div className="widget-type-card list">
          <div className="widget-type-info">
            <div className="widget-type-header">
              <h2 className="widget-header-ellipsis" title={generalTitle}>{generalTitle}</h2>
              <p className="widget-description-ellipsis" title={categoryDescription}>{categoryDescription}</p>
              <label>{selectLabel && isRequired? selectLabel+ "*": selectLabel}:</label>
              <Select
                options={widgetValues}
                onChange={(index) => {
                  setTimeout(() => {
                    onUpdateFields(widgetTypes[index])
                  }, 150);
                }}
                selectedItem={activeWidget?.value}
              />
            </div>
            <div className="widget-type-body" style={activeWidget?.value==='Energy Demand'?{width: "105%"}: {}}>
              <div className="widget-type-text">
                <div onClick={()=>openWidgetCreation(activeWidget)}>
                  <p className="widget-type-description-ellipsis" title={translate(activeWidget?.desc)}> <TranslateComponent>{activeWidget?.desc}</TranslateComponent></p>
                </div>
                <div className="widget-type-menu">
                  <button
                    className="primary small btn-width-alignment"
                    onClick={() => {openWidgetCreation(activeWidget)}}
                  >
                    {optionsTranslated.select}
                  </button>
                  <button
                    className="anchor-link"
                    onClick={() => handleOpenPreview(activeWidget)}
                    onKeyDown={() => handleOpenPreview(activeWidget)}
                  >
                    {optionsTranslated.viewLarger}
                  </button>
                </div>
              </div>
              <div
                className="widget-type-image"
                onClick={() => handleOpenPreview(activeWidget)}
                onKeyDown={() => handleOpenPreview(activeWidget)}
              >
                {activeWidget ? (
                  <img
                    alt=""
                    src={require(`src/assets/images/${type}-icon.png`)}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Content>
    )
  }

  const NonCustomWidgetTypeCard = ({ title, description, activeWidget }) => {
    const handleOpenPreview = (activeWidget) => {
      setSelectedWidget(activeWidget)
      isOpenPreview(true)
    }
    const openWidgetCreation = (activeWidget) => {
      setWidgetBuilding(null)
      setSelectedWidget(activeWidget)
      setIsOpenCreateWidget(true)
      setOpenWidgetPicker(false)
    }
    const type = activeWidget?.type
    return (
      <Content
        xlColumn={6}
        lColumn={6}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <div className="widget-type-card">
          <div className="widget-type-info">
            <div className="widget-type-text">
              <div onClick={()=>openWidgetCreation(activeWidget)}>
                <h2>{translate(title)}</h2>
                <p>{translate(description)}</p>
              </div>
              <div className="widget-type-menu">
                <button
                  className="primary small btn-width-alignment"
                  onClick={() => {openWidgetCreation(activeWidget)}}
                >
                  {optionsTranslated.select}
                </button>
                <button
                  className="anchor-link"
                  onClick={() => handleOpenPreview(activeWidget)}
                  onKeyDown={() => handleOpenPreview(activeWidget)}
                >
                  {optionsTranslated.viewLarger}
                </button>
              </div>
            </div>
            <div
              className="widget-type-image"
              onClick={() => handleOpenPreview(activeWidget)}
              onKeyDown={() => handleOpenPreview(activeWidget)}
            >
              <img alt="" src={require(`src/assets/images/${type}-icon.png`)} />
            </div>
          </div>
        </div>
      </Content>
    )
  }

  return (
    <WidgetTypePickerStyle>
      <h3><TranslateComponent>Select Widget Type</TranslateComponent>:</h3>
      <div className="widget-type-picker">
        <div className="widget-type-cards">
          <ContainerWrapper
            sColumns={12}
            mColumns={12}
            lColumns={12}
            xlColumns={12}
          >
            <CustomWidgetTypeCard
              generalTitle={optionsTranslated.energyWidget}
              categoryDescription={optionsTranslated.monitorEnergy}
              selectLabel={optionsTranslated.selectEnergy}
              isRequired={true}
              widgetTypes={energyWidgetTypes}
            />
            <CustomWidgetTypeCard
              generalTitle={optionsTranslated.customWidget}
              categoryDescription={optionsTranslated.choosePoint}
              selectLabel={optionsTranslated.selectCustom}
              isRequired={true}
              widgetTypes={customWidgetTypes}
            />
            {nonCategoryWidgetTypes.map((nonCategory) => {
              return (
                <NonCustomWidgetTypeCard
                  title={nonCategory.title}
                  description={nonCategory.desc}
                  key={nonCategory.key}
                  activeWidget={nonCategory}
                />
              )
            })}
          </ContainerWrapper>
        </div>
      </div>
      {openPreview && (
        <Modal {...openWidgetPreviewModalConfig}>
          <WidgetSampleImagePreview type={selectedWidget?.type} />
        </Modal>
      )}
    </WidgetTypePickerStyle>
  )
}

export default WidgetTypePicker
