import { Button, Radio, RadioGroupField } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import styles from './table.module.scss'
import { Dispatch, SetStateAction, useState } from 'react'
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  type FloatingRootContext,
  offset,
  type ReferenceType,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole
} from '@floating-ui/react'

export const SortMenu = ({
  header,
  handleSort,
  selectedColumn,
  isAscending
}: { 
  header: {
    key: string
    title: string
  }[],
  handleSort: (column: number, ascending: boolean) => void,
  selectedColumn: number,
  isAscending: boolean
}) => {
  // current strategy for the custom sort options is combining the column number with the ascending boolean.
  const defaultState = '0-true'
  const currentSelection = `${selectedColumn}-${isAscending}`
  const headerData = header.flatMap(mapHeaderData);

  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(currentSelection)
  const { refs, floatingStyles, context } = setupFloating(isOpen, setIsOpen)
  const { getReferenceProps, getFloatingProps } = setupInteractions(context)

  const getDisplayLabel = (val: string) => {
    const entry = headerData.find(
      (item: { value: string }) => item.value === val
    )
    return `${entry.title} ${entry.ascending ? '(Ascending)' : '(Descending)'}`
  }

  const handleRadioSelection = (val: string) => {
    const [col, asc] = val.split('-')
    handleSort(Number(col), asc === 'true')
  }

  return (
    <div>
      {currentSelection !== defaultState ? (
        <Button
          size="small"
          onClick={() => {
            setValue(defaultState)
            handleSort(0, true)
          }}
        >
          {getDisplayLabel(currentSelection)}
          <FontAwesomeIcon icon={faCircleXmark} />
        </Button>
      ) : (
        <Button
          size="small"
          ref={refs.setReference}
          {...getReferenceProps()}
          data-testid={`tableSortButton-Sort`}
        >
          Sort
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      )}

      {isOpen && (
        <FloatingFocusManager context={context} closeOnFocusOut={true}>
          <div
            className={styles.menu}
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={floatingStyles}
          >
            <RadioGroupField
              label={'Sort'}
              labelHidden={true}
              name={'Sort'}
              value={value}
              onChange={(event) => {
                setValue(event.target.value)
                handleRadioSelection(event.target.value)
                setIsOpen(false)
              }}
            >
              {headerData.map((option) => (
                <Radio
                  key={option.value}
                  value={option.value}
                  data-testid={`tableSortRadio-${option.value}-${option?.title}`}
                >
                  {getDisplayLabel(option.value)}
                </Radio>
              ))}
            </RadioGroupField>
          </div>
        </FloatingFocusManager>
      )}
    </div>
  )
}

function setupFloating(
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
) {
  return useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(12), flip(), shift()]
  })
}

function setupInteractions(context: FloatingRootContext<ReferenceType>) {
  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)
  return useInteractions([click, dismiss, role])
}

const mapHeaderData = (header, index) => {
  if (header.key === 'actions') return []

  const headerData = [
    {
      columnKey: index,
      ascending: true,
      key: header.key,
      title: header.title,
      value: `${index}-true`
    },
    {
      columnKey: index,
      ascending: false,
      key: header.key,
      title: header.title,
      value: `${index}-false`
    }
  ]

  return headerData
}
