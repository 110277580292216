import EnergyCostSummaryContainer from './EnergyCostSummaryContainer.jsx'
import { EnergyCostSummaryContextProvider } from './EnergyCostSummaryContextProvider'

export const EnergyCostSummary = () => {
  return (
    <EnergyCostSummaryContextProvider>
      <EnergyCostSummaryContainer />
    </EnergyCostSummaryContextProvider>
  )
}
