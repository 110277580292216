import React, { useEffect, useState, useCallback } from 'react'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import PageHeader from '../../components/pageHeaderNew/header'
import { PAGE_TITLE, EUI_CHART_INITIAL_VIEW } from './constants'
import {
  ToolBarWrapper,
  ActionButton,
  EIContainer,
  MarkerDIV,
  ChartDIV,
  EnergyIntensityContainerWrapper
} from './styles'
import { useQuery } from 'src/hooks/APIHooks'
import {
  getSearchParams,
  getSearchParamsString
} from '../../common/helperFunctions'
import ChartTypeSelector from 'src/components/chart-type-selector/chart-type-selector'
import { Drawer, DrawerSection, NotDrawer } from 'src/components/drawer/drawer'
import {
  DATE_FORMAT,
  BACKEND_DATE_FORMAT
} from 'src/components/legacy/common/time-helpers'
import DataChart from './DataChart'
import moment from 'moment'
import { listUtilityDataByBuilding } from './graphql'
import Markers from './Markers'
import ChartDateControls from 'src/components/chartDateControls/chartdatecontrols'
import { useNavigate } from 'react-router-dom'
import { GlobalStyle } from 'src/components/legacy/components/global-reset/global-reset'
import ChartMinMaxSettings from '../../components/chart-min-max-settings'
import {
  Error,
  ErrorContainer,
  ErrorIcon,
  TextWrapper
} from '../utilityBillData/styles'

const EnergyIntensityContainerMain = () => {
  const { width } = useBreakPoint()
  const searchParams = getSearchParams()
  const navigate = useNavigate()
  const buildingId = searchParams?.buildingId
  const organizationId = searchParams?.organizationId

  const [viewConfig, setViewConfig] = useState(EUI_CHART_INITIAL_VIEW)
  const [drawerIsOpen, setDrawerIsOpen] = useState(true)
  const [markers, setMarkers] = useState([])
  const [benchmarks, setBenchmarks] = useState([])
  const toggleDrawer = () => setDrawerIsOpen((drawerIsOpen) => !drawerIsOpen)
  const [chartMinMax, setChartMinMax] = useState({
    section1min: 0,
    section1max: 100,
    section2min: 0,
    section2max: 100
  })
  const [customMinMax, setCustomMinMax] = useState({
    section1min: 0,
    section1max: 100,
    section2min: 0,
    section2max: 100
  })
  const [isDefaultYaxis, setIsDefaultYaxis] = useState(true)
  const [chartViewType, setChartViewType] = useState('line')
  const [toggleBenchmarks, setToggleBenchmarks] = useState()

  const settingsConfig = {
    heading: 'Chart Settings',
    subHeader: 'Add/Edit Building Area',
    inputHeading: 'Total Building Area',
    inputMessage: 'Do not include non-heated/air-conditioned spaces',
    chartPage: true,
    sectionHeader: 'Edit Axis Ranges',
    sections: [
      {
        key: 'section1',
        name: 'Left Outer Axis',
        displayDecimal: false,
        fields: {
          min: 0,
          max: 0
        },
        errors: null
      },
      {
        key: 'section2',
        name: 'Right Outer Axis',
        displayDecimal: true,
        fields: {
          min: 0,
          max: 0
        },
        errors: null
      }
    ]
  }

  const [chartDates, setChartDates] = useState(null)

  const { data: utilityData, refetch: refetchUtilityData } = useQuery({
    query: listUtilityDataByBuilding,
    dataPath: 'data.listUtilityDataByBuilding.items',
    variables: { accountId: organizationId },
    disableInitialLoad: true
  })

  useEffect(() => {
    if (buildingId) {
      const filter = { month: { between: [1, 12] } }
      refetchUtilityData({ filter, buildingId }).then((res) => {
        const dates = res
          .map((e) => moment([e.year, e.month - 1, 1]))
          .sort((a, b) => (a.valueOf() > b.valueOf() ? 1 : -1))
        // TODO: Update start and end date from url to the chart dates
        const dateToUse = {
          chartStartDate: dates.length
            ? dates[0].startOf('month').format(BACKEND_DATE_FORMAT)
            : moment()
                .subtract(1, 'year')
                .add(1, 'day')
                .format(BACKEND_DATE_FORMAT),
          chartEndDate: dates.length
            ? dates[dates.length - 1].endOf('month').format(BACKEND_DATE_FORMAT)
            : moment().format(BACKEND_DATE_FORMAT),
          dateError: false
        }
        setChartDates(dateToUse)
      })
    }
  }, [buildingId])

  const onChartControlUpdate = ({
    startDate,
    endDate,
    endError,
    startError
  }) => {
    setChartDates({
      chartStartDate: startDate,
      chartEndDate: endDate,
      dateError: startError || endError
    })
  }
  const onMarkersUpdate = (markersData) => {
    setMarkers(markersData)
  }
  const onBenchmarksUpdate = (benchmarksData) => {
    setBenchmarks(benchmarksData)
  }

  const navigateToCreateReport = () => {
    const search = getSearchParamsString()
    navigate(`/reports/energy-reports/add?${search}&reportType=EnergyUsageReport`)
  }
  const updateSettingsConfig = (values, isSaved) => {
    setCustomMinMax(values)
    if (isSaved) {
      setIsDefaultYaxis(false)
    }
  }

  const updateChartMinMax = useCallback((values) => {
    setChartMinMax(values)
  }, [])

  const showBenchmarks = useCallback((props) => {
    setToggleBenchmarks(props?.map(m=>m?.text?.toLowerCase()))
  }, [])

  return (
    <EnergyIntensityContainerWrapper testName="energy-intensity-chart-page">
      <GlobalStyle />
      {!buildingId && organizationId ? (
        <ErrorContainer>
          <Error>
            <ErrorIcon className="icon icon-error" />
            <TextWrapper className="text-container">
              Organizational EUI/ECI indices are not yet available, but coming
              soon! Please select a building to see its EUI/ECI score.
            </TextWrapper>
          </Error>
        </ErrorContainer>
      ) : (
        <>
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <PageHeader
              pageTitle={<div>{PAGE_TITLE}</div>}
              titleTools={
                <ToolBarWrapper testName="energy-intensity-toolbar">
                  <ChartTypeSelector
                    viewConfig={viewConfig}
                    setViewConfig={setViewConfig}
                    types={['line', 'bar']}
                    series={['eui', 'eci']}
                    onChangeChartViewType={(chartView) =>
                      setChartViewType(chartView)
                    }
                  />
                  <ActionButton
                    className="action medium"
                    onClick={navigateToCreateReport}
                  >
                    CREATE REPORT
                  </ActionButton>
                  <ChartMinMaxSettings
                    locationId={buildingId}
                    settingsConfig={settingsConfig}
                    update={updateSettingsConfig}
                    initialSectionValues={
                      isDefaultYaxis ? chartMinMax : customMinMax
                    }
                  ></ChartMinMaxSettings>
                </ToolBarWrapper>
              }
            />
          </Content>
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            {chartDates && (
              <EIContainer
                testName="energy-intensity-container"
                key={buildingId}
              >
                <Drawer drawerIsOpen={drawerIsOpen} toggleDrawer={toggleDrawer} testName='energy-intersity'>
                  <DrawerSection>
                    <MarkerDIV>
                      <Markers
                        chartDates={chartDates}
                        onMarkersUpdate={onMarkersUpdate}
                        onBenchmarksUpdate={onBenchmarksUpdate}
                        toggleBenchmarks={toggleBenchmarks?.join(',')}
                      />
                    </MarkerDIV>
                  </DrawerSection>
                </Drawer>
                <ChartDIV testName='energy-intersity-chart-container'>
                  <NotDrawer>
                    <ChartDateControls
                      testName='energy-intersity'
                      startDate={chartDates?.chartStartDate}
                      endDate={chartDates?.chartEndDate}
                      startDisableAfterGivenDate={chartDates?.chartEndDate}
                      endDisableBeforeGivenDate={chartDates?.chartStartDate}
                      onUpdate={onChartControlUpdate}
                      rangesList={['1W', '1M', '3M', '6M', 'YTD', '1Y']}
                      selectMonth={true}
                    />
                    <DataChart
                      markers={markers}
                      benchmarks={benchmarks}
                      chartDates={chartDates}
                      data={utilityData}
                      isDefaultYaxis={isDefaultYaxis}
                      customMinMax={customMinMax}
                      updateChartMinMax={updateChartMinMax}
                      chartViewType={chartViewType}
                      showBenchmarks={showBenchmarks}
                      toggleBenchmarks={toggleBenchmarks?.join(',')}

                    />
                  </NotDrawer>
                </ChartDIV>
              </EIContainer>
            )}
          </Content>
        </>
      )}
    </EnergyIntensityContainerWrapper>
  )
}

export default EnergyIntensityContainerMain
