import styled, { css } from 'styled-components'

const TOOLTIP_ARROW_SHIFT = '8px'
const TOOLTIP_ARROW_HEIGHT_NUM = 7
const TOOLTIP_ARROW_SHIFT_NUM = 5

const bottomPlacement = (arrowLoc) => {
  switch (arrowLoc) {
    case 'left':
    case 'right':
      return '50%'
    case 'bottom':
    case 'left-bottom':
    case 'right-bottom':
    case 'center-bottom':
      return '100%'
    default:
      return 'unset'
  }
}

const topPlacement = (arrowLoc) => {
  switch (arrowLoc) {
    case 'center-top':
      return '100%'
    case 'left':
    case 'right':
      return '50%'
    case 'left-top':
    case 'right-top':
      return '100%'
    default:
      return 'unset'
  }
}

const sidePlacement = (arrowLoc) => {
  switch (arrowLoc) {
    case 'center-top':
    case 'center-bottom':
      return 'left: 50%'
    case 'left':
      return 'left: 100%'
    case 'right':
      return 'left: 0%'
    case 'bottom':
      return 'left: 50%'
    case 'left-bottom':
      return 'right: 3px'
    case 'right-bottom':
      return 'left: 8px'
    case 'left-top':
      return 'right: 5px'
    case 'right-top':
      return 'left: 5px'
    default:
      return 'unset'
  }
}

const postion = (arrowLoc) => {
  switch (arrowLoc) {
    case 'center-top':
    case 'left-top':
    case 'right-top':
      return '0%'
    case 'bottom':
    case 'left-bottom':
    case 'right-bottom':
    case 'center-bottom':
      return '100%'
    default:
      return 'unset'
  }
}

const transform = (arrowLoc) => {
  switch (arrowLoc) {
    case 'left':
      return 'translate(-25%,-50%) rotate(270deg)'
    case 'right':
      return 'translate(-70%,-50%) rotate(90deg)'
    case 'right-top':
    case 'right-bottom':
      return 'translateX(-20%)'
    case 'left-top':
    case 'left-bottom':
      return 'translateX(10%)'
    default:
      return 'translateX(-50%)'
  }
}

export const StyledTooltip = styled.div`
  box-sizing: border-box;
  font-family: Emerald;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0.24px;
  position: absolute;
  z-index: 20;
  white-space: nowrap;
  color: rgb(30, 30, 30);
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  padding: 8px;
  line-height: 18px;
  text-align: left;
  background: rgb(249, 250, 252);
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
      white-space: normal;
      word-wrap: break-word;
    `};
  ${(props) =>
    props.type === 'dark' &&
    css`
      color: rgb(255, 255, 255);
      background-color: rgb(60, 60, 60);
      &:after {
        display: none;
      }
    `}
  ${(props) =>
    props.textWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

    ${(props) =>
    props.position === 'left-top' &&
    css`
      bottom: 100%;
      margin-bottom: ${TOOLTIP_ARROW_SHIFT};
      right: 50%;
      margin-right: -${TOOLTIP_ARROW_SHIFT_NUM + TOOLTIP_ARROW_HEIGHT_NUM + 'px'};
    `}

    ${(props) =>
    props.position === 'right-top' &&
    css`
      bottom: 100%;
      margin-bottom: ${TOOLTIP_ARROW_SHIFT};
      left: 50%;
      margin-left: -${TOOLTIP_ARROW_SHIFT_NUM + TOOLTIP_ARROW_HEIGHT_NUM + 'px'};
    `}

    ${(props) =>
    props.position === 'center-top' &&
    css`
      bottom: 100%;
      margin-bottom: ${TOOLTIP_ARROW_SHIFT};
      left: 50%;
      transform: translate(-50%, 0);
    `}

    ${(props) =>
    props.position === 'left' &&
    css`
      top: 50%;
      transform: translate(0, -50%);
      right: 100%;
      margin-right: ${TOOLTIP_ARROW_SHIFT};
    `}

    ${(props) =>
    props.position === 'right' &&
    css`
      top: 50%;
      transform: translate(0, -50%);
      left: 100%;
      margin-left: ${TOOLTIP_ARROW_SHIFT};
    `}

    ${(props) =>
    props.position === 'left-bottom' &&
    css`
      top: 100%;
      margin-top: ${TOOLTIP_ARROW_SHIFT};
      right: 50%;
      margin-right: -${TOOLTIP_ARROW_SHIFT_NUM + TOOLTIP_ARROW_HEIGHT_NUM + 'px'};
    `}

    ${(props) =>
    props.position === 'right-bottom' &&
    css`
      top: 100%;
      margin-top: ${TOOLTIP_ARROW_SHIFT};
      left: 50%;
      margin-left: -${TOOLTIP_ARROW_SHIFT_NUM + TOOLTIP_ARROW_HEIGHT_NUM + 'px'};
    `}

    ${(props) =>
    props.position === 'center-bottom' &&
    css`
      top: 100%;
      margin-top: ${TOOLTIP_ARROW_SHIFT};
      left: 50%;
      transform: translate(-50%, 0);
    `}
`

export const TooltipContainer = styled.span`
  box-sizing: border-box;
  position: relative;
  padding: ${(props) => (props.paddingWrapper ? props.paddingWrapper : '2px')};
  margin: ${(props) => props.marginWrapper && props.marginWrapper};

  & ${StyledTooltip} {
    display: none;
  }
  :hover {
    ${StyledTooltip} {
      display: inline;
    }
  }
`

export const TooltipArrow = styled.span`
  position: absolute;
  box-sizing: border-box;
  ${(props) => sidePlacement(props.position)};
  bottom: ${(props) => bottomPlacement(props.position)};
  top: ${(props) => topPlacement(props.position)};
  transform: ${(props) => transform(props.position)};
  overflow: hidden;
  width: 20px;
  height: 10px;
  &:after {
    content: '';
    position: absolute;
    top: ${(props) => postion(props.position)};
    width: 9px;
    height: 9px;
    background-color: ${(props) =>
      props.type === 'dark' ? 'rgb(60, 60, 60)' : 'rgb(249, 250, 252)'};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    transform: translate(50%, -50%) rotate(45deg);
  }
  :hover {
    ${StyledTooltip} {
      display: inline;
    }
  }
`
