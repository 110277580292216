import { useState, useCallback, useEffect } from 'react'
import { EquipmentSetupList } from './EquipmentSetupList'
import { RadioButtons } from './styles'
import { Button } from 'src/components/inputs/button'
import RadioButton from 'src/denali-ui/components/Radio'
import { TIS_EQUIPMENT_LIST, AUTOMATION_SYSTEM_LIST ,TEXT_NAME_PREFIX} from 'src/pages/equipmentSetup/constants'
import { LoadTime } from 'src/components/loadTime'
import PageHeader from '../../components/pageHeaderNew/header'
import { CreateEquipment } from './CreateEquipment/create-equipment'
import { CreateNiagaraEquipment } from './CreateEquipment/create-niagara-equipment'
import { getSearchParams } from './../../common/helperFunctions.js'
import translate, { TranslateComponent } from 'src/common/translations'
import { AccessControl } from 'src/components/accessControl'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { GET_CREATE_EQUIPMENT_DROPDOWN_DETAILS } from './graphql/queries'
import { useQuery } from 'src/hooks/APIHooks'

export const EquipmentSetup = () => {

  const {buildingId} = getSearchParams()

  const [selectedListType, setListType] = useState(TIS_EQUIPMENT_LIST)
  const [loadTime, setLoadtime] = useState(0)
  const [equipmentsList, setEquipmentsList] = useState([])
  const [isRefreshEquipmentList, setIsRefreshEquipmentList] = useState(false)
  const [isNaxConnectivity, setIsNaxConnectivity] = useState(false)
  const onChangeRadio = useCallback((event: any) => {
    setListType(event.target.value)
    if(event.target.value == "AutomationSystemList")
    {
      trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.VIEW_AUTOMATION_SYSTEM_LIST)
    }
      else if (event.target.value == "TISEquipmentList")
    {
      trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.VIEW_TIS_EQUIPMENT_LIST)
    }
  }, [])
  const [isShowCreateEquipment, setIsShowCreateEquipment] = useState(false)
  const [isShowCreateNiagaraEquipment, setIsShowCreateNiagaraEquipment] = useState(false)
  
  const { data: buildingData, refetch:getBuildingData } = useQuery({
    query: GET_CREATE_EQUIPMENT_DROPDOWN_DETAILS,
    variables: { buildingId: buildingId },
    errorPolicy: 'all',
    dataPath: 'data.getBuilding.devices.items',
    disableInitialLoad: false
  })

  useEffect(()=>{
    getBuildingData()
  },[buildingId])

  useEffect(()=>{
    if(buildingData)
    {
      // const result = buildingData.map(({type})=>type)
      // const checkAll = result.every( (val,i,arr)=> val == arr[0] ) //Checks if all values are same
      // if(result[0]=="NAX")  //checkAll && result[0]=="NAX"   
      setIsNaxConnectivity(buildingData?.[0]?.type=="NAX")
    }
  },[buildingData])

  const handleCreateTISEquipment = () => {
    if(isNaxConnectivity)
    {
      setIsShowCreateNiagaraEquipment(true)
    }

    if(!isNaxConnectivity)
    {
      setIsShowCreateEquipment(true)
    }

    setIsRefreshEquipmentList(false)
  }
  const handleCreateEquipmentClose = (isRefresh: boolean) => {
    setIsShowCreateNiagaraEquipment(false)
    setIsShowCreateEquipment(false)
    setIsRefreshEquipmentList(isRefresh)
  }
  const getEquipmentsList = (data) => {
    setEquipmentsList(data)
  }

  const optionsTranslated = {
    createTIS: translate("Create TIS Equipment"),
    selectOne: translate("—Select One—"),
    create : "Create TIS Equipment",
    created: "TIS Equipment Objects Created",
    selectPlaceholder : "— " + translate('Select One') + " —",
    findEquipment : translate("Find an Equipment")+"...",
    findSource: translate("Find a Data Source"),
    toolTipContent: translate("Determines the default starting point for selecting source properties on the Data Mapping tab for the new object (e.g. MP581-AHU01 would be the default data source for the IS Air Handler named AHU-01). If you are mapping properties from multiple sources, select the one that matches the most properties on the new object."),
    copyCharacteristics : translate("Copy characteristics & data map") ,
    choices : 'Optional - Choices made here will apply to all Default Data Source selections',
    toolTip : translate("Default Data Sources that are already mapped will not show up when this checkbox is checked")
  }



  return (
    <div>
      <PageHeader
        pageTitle={<TranslateComponent>Equipment Setup</TranslateComponent>}
        titleTools={
          <AccessControl id="tc.pages.equipment-setup.add">
            <Button testName={TEXT_NAME_PREFIX("create_equip")} disabled={!buildingId} onClick={() => {handleCreateTISEquipment(), trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_CREATE_TIS_EQUIPMENT)}}>
              {optionsTranslated.createTIS}
            </Button>
          </AccessControl>
        }
      />
      <RadioButtons>
        <RadioButton
        testName={TEXT_NAME_PREFIX("TIS Equipment")}
          label={translate("TIS Equipment List")}
          labelPosition="right"
          className="equipment-radio"
          name="list-type"
          onChange={onChangeRadio}
          value={TIS_EQUIPMENT_LIST}
          checked={selectedListType === TIS_EQUIPMENT_LIST}
        />
        <RadioButton
        testName={TEXT_NAME_PREFIX("automation system")}
          label={translate("Automation System List")}
          labelPosition="right"
          className="equipment-radio"
          name="list-type"
          onChange={onChangeRadio}
          value={AUTOMATION_SYSTEM_LIST}
          checked={selectedListType === AUTOMATION_SYSTEM_LIST}
        />
        <LoadTime
          styles={{ position: 'relative', top: '-1px' }}
          loadTime={loadTime}
        />
      </RadioButtons>

      <EquipmentSetupList
        isRefreshEquipmentList={isRefreshEquipmentList}
        setLoadtime={setLoadtime}
        equipmentListType={selectedListType}
        getEquipments={getEquipmentsList}
        isNAX={buildingData ? buildingData?.[0]?.type=="NAX" : false}
      />
      {isShowCreateEquipment && (
        <CreateEquipment
          buildingId={buildingId}
          handleCreateEquipmentClose={handleCreateEquipmentClose}
          equipmentsList={equipmentsList}
          optionsTranslated={optionsTranslated}
        />
      )}
      {isShowCreateNiagaraEquipment && (
        <CreateNiagaraEquipment
          buildingId={buildingId}
          handleCreateEquipmentClose={handleCreateEquipmentClose}
          equipmentsList={equipmentsList}
        />
      )}
    </div>
  )
}
