import { useEffect, useRef, useState } from "react";
import styles from "./media.module.scss"

export const VimeoEmbed = ({id, title, style=VimeoEmbedStyles.default, setPlayer=(player)=>{}, urlParams='', aspectRatio=16/9}) => {
  const playerRef = useRef();
  const playerUrlParams = getPlayerUrlParams(style, urlParams);

  if (window?.['Vimeo']) {
    useEffect(() => setPlayer( new window['Vimeo'].Player(playerRef?.current) ), []);
  }


  return (
    <>
      <div className={styles.vimeoEmbed} data-style={style} style={{aspectRatio}}>
        <iframe ref={playerRef} src={`https://player.vimeo.com/video/${id}?${playerUrlParams}`}
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title={title}
          data-testid="vimeoIframe"
        />
      </div>
    </>
  )
}

export enum VimeoEmbedStyles {
  default='default',
  backgroundCoverLoop='backgroundCoverLoop',
  backgroundCoverPreview='backgroundCoverPreview',
}

const getPlayerUrlParams = (style, urlParams) => {
  // https://developer.vimeo.com/player/sdk/embed
  
  const base = `${urlParams ? urlParams + '&' : ''}&badge=0&player_id=0&app_id=58479`;

  if(style === VimeoEmbedStyles.backgroundCoverLoop) {
    return `${base}&autopause=0&autoplay=1&background=1`;
  }
  
  return base;
}