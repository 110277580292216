import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import ObjectPicker from './ObjectPicker'
import {
  GET_BUILDING_EQUIPMENT_TYPES,
  getEquipmentsByBuildingIDAndType,
  GET_EQUIPMENT_PROPERTIES_BY_ID,
  SEARCH_BUILDINGS_BY_ACCOUNT,
  GET_PROPERTIES_SYMBOL
} from './graphql'
import { useTranslation } from 'react-i18next'
import IconSVG from 'src/components/Icon/index.js'
import { ACTIONS } from 'src/constants/actions'
import { EQUIPMENT, PROPERTY, resizePaneDrag } from './helpers'
import colors from 'src/components/legacy/common/colors.json'
import {
  Wrapper,
  StyledDivFoot,
  BottonSection,
  BottomHead,
  BottomList,
  BottomListItem,
  BottomText,
  ErrorText,
  StyleSeperator
} from './styles'
import { useMultipleBuildingsAccess } from 'src/hooks/multipleBuildingAccess'
import { useWidgetsContext } from '../../pages/widgets/WidgetsContextProvider'
import { getSearchParams } from '../../common/helperFunctions'
import { useDispatch } from 'react-redux'
import { newRefreshToken } from '../../redux/slicers/appData'
import translate, { TranslateComponent } from '../../common/translations'

const ENERGY_METER_TYPES = ['GAS', 'ElectricMeter', 'VirtualMeter']
const ObjectPickerWrapper = ({
  objects,
  update,
  buildingSelectLimit,
  equipmentSelectLimit,
  propertyUOMLimit = 4,
  mode,
  claims,
  initialValues,
  error,
  baselineEquipments = [],
  isEnergyWidget = false,
  orgIdFromParent,
  considerOrgIdFromParent = false
}) => {
  const [buildingsCheckList, setBuildingsCheckList] = useState([])
  const [equipmentTypesCheckList, setEquipmentTypesCheckList] = useState([])
  const [equipmentsCheckList, setEquipmentsCheckList] = useState([])
  const [equipmentPropertiesCheckList, setEquipmentPropertiesCheckList] =
    useState([])
  const dispatch = useDispatch()
  const [checkedValues, setCheckedValues] = useState([])
  const [rootData, setRootData] = useState(initialValues || [])
  const [isRootDataLimit, setIsRootDataLimit] = useState(false)
  const [selectedBuilding, setSelectedBuilding] = useState(null)
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null)
  const [selectedEquipment, setSelectedEquipment] = useState(null)
  const [step, setStep] = useState(-1)
  const searchParams = getSearchParams()
  const organizationId = searchParams?.organizationId
  const { setSeletectedBuildings, unAuthorizedBuildingsList, fetchingToken } =
    useMultipleBuildingsAccess()
  const [buildingIds, setBuildingIds] = useState(null)
  const [errorText, setErrorText] = useState(null)
  const [initialOrgId, setInitialOrgId] = useState(null)
  const [propertySymbolsValues, setPropertySymbolsValues] = useState([])
  const [t] = useTranslation()
  const firstRef = useRef()
  const secondRef = useRef()
  const seperatorRef = useRef()
  const [errors, setErrors] = useState(error)

  const { setIsBuildingChange } = useWidgetsContext() || {}

  const {
    data: accountData,
    refetch: refetchAccount,
    loading: buildingsLoading
  } = useQuery({
    query: SEARCH_BUILDINGS_BY_ACCOUNT,
    dataPath: 'data.searchBuildings.items',
    disableInitialLoad: true,
    errorPolicy: 'ignore'
  })

  const { refetch: refetchEquipmentTypes, loading: equipmentTypesLoading } =
    useQuery({
      query: GET_BUILDING_EQUIPMENT_TYPES,
      dataPath: 'data.getBuildingEquipmentTypes',
      disableInitialLoad: true
    })

  const { refetch: refetchEquipments, loading: equipmentsLoading } = useQuery({
    query: getEquipmentsByBuildingIDAndType,
    dataPath: 'data.listEquipmentsByBuildingAndActiveAndType.items',
    disableInitialLoad: true
  })

  const { refetch: getEquipmentPropertiesQuery, loading: loadingProperties } =
    useQuery({
      query: GET_EQUIPMENT_PROPERTIES_BY_ID,
      dataPath: 'data.getPropertyMappingByEquipmentID.items',
      disableInitialLoad: true
    })

  const {
    data: propertySymbols,
    refetch: getPropertySymbol,
    loading: loadingPropertySymbols
  } = useQuery({
    query: GET_PROPERTIES_SYMBOL,
    dataPath: 'data.listConfigs.items',
    disableInitialLoad: true
  })

  // If the parent component wants to refresh object picker, pass new objects list ( basically just spread and pass)
  const memoizedObjects = useMemo(() => objects, [])

  useEffect(() => {
    if (objects.includes(PROPERTY)) {
      getPropertySymbol({ filter: { type: { eq: 'Dimension' } } })
    }
  }, [])

  useEffect(() => {
    if (propertySymbols && propertySymbols?.length > 0) {
      try {
        const parsedSymbols = propertySymbols.map((x) =>
          JSON.parse(JSON.parse(x.value))
        )
        setPropertySymbolsValues(parsedSymbols)
      } catch (error) {
        console.log(error)
      }
    }
  }, [propertySymbols])

  useEffect(() => {
    if (objects?.length) {
      setStep(objects.findIndex((step) => step))
    }
  }, [memoizedObjects])

  useEffect(() => {
    if (unAuthorizedBuildingsList?.length > 0) {
      setErrorText('Unauthorized Building Selection')
    } else {
      setErrorText(null)
    }
  }, [unAuthorizedBuildingsList])

  useEffect(() => {
    setIsBuildingChange?.(fetchingToken)
  }, [fetchingToken])

  useEffect(() => {
    if (firstRef?.current && secondRef?.current) {
      resizePaneDrag(firstRef, secondRef, seperatorRef, checkedValues)
    }
  }, [
    firstRef?.current,
    secondRef?.current,
    seperatorRef?.current,
    checkedValues?.length
  ])

  useEffect(() => {
    if (buildingIds) {
      setSeletectedBuildings(buildingIds)
    } else {
      setSeletectedBuildings([])
    }
  }, [buildingIds])

  useEffect(() => {
    if ([ACTIONS.EDIT, ACTIONS.COPY].includes(mode) && initialValues) {
      setInitialOrgId(
        initialValues?.length
          ? initialValues[0]?.accountId ?? null
          : null || null
      )
      // this is to handle edit, if we have some initial data to set
      // Assuming that Equipmetns list is alwasys last steps in this
      // If it differes, have to handle step number dynamically
      // set building info from edit data
      const editData = initialValues[0]

      setSelectedBuilding({
        accountId: editData?.accountId,
        accountName: editData?.accountName,
        id: editData?.buildingId,
        name: editData?.buildingName,
        checked: true
      })
      setSelectedEquipmentType({
        name: editData.equipmentFamily,
        familyType: editData.equipmentType ,
        checked: true
      })
      getEquipments(initialValues[0], true)
      getEquipmentProperties(initialValues[0], true)

      if (objects.includes(PROPERTY)) {
        setStep(editData?.equipmentId ? 4 : 1)
      } else {
        setStep(editData?.equipmentId ? 3 : 1)
      }
    }
  }, [])

  useEffect(() => {
    if (accountData?.length) {
      const editData =
        [ACTIONS.EDIT, ACTIONS.COPY].includes(mode) && initialValues
          ? initialValues[0]
          : {}

      // Set Account name only at edit
      if (Object.keys(editData)?.length > 0) {
        setSelectedBuilding({
          ...selectedBuilding,
          accountName: accountData?.[0]?.accountName || ''
        })
      }

      setBuildingsCheckList(
        accountData
          .map((a) => ({
            name: a.name,
            id: a.id,
            checked: editData?.buildingId === a?.id,
            accountName: a.accountName,
            accountId: a.accountId,
            salesOfficeId: a.salesOfficeId,
            salesOfficeName: a.salesOfficeName,
            timeZone: a.tz
          }))
          .sort((a, b) => a?.name?.localeCompare(b?.name))
      )
    }
  }, [accountData])

  useEffect(() => {
    if (orgIdFromParent) {
      setBuildingsCheckList([])
      const filter = { accountId: { eq: orgIdFromParent }, isActive: { eq: 1 } }
      refetchAccount({filter})
    } else if (considerOrgIdFromParent) {
      setBuildingsCheckList([])
    } else if (organizationId || (!organizationId && initialOrgId)) {
      const orgId = organizationId ? organizationId : initialOrgId
      const filter = { accountId: { eq: orgId }, isActive: { eq: 1 } }
      refetchAccount({filter})
    }
  }, [organizationId, initialOrgId, orgIdFromParent])

  useEffect(() => {
    setCheckedValues(rootData)
    update(rootData)
  }, [rootData])

  // Gets equipments types for a given building
  const getEquipmentTypes = async (buildingId) => {
    await dispatch(
      newRefreshToken(
        [buildingId],
        null,
        null,
        claims ? claims : null
      )
    )
    try {
      const requestBody = { requestBody: JSON.stringify([buildingId]) }
      const equipmentTypes =  await refetchEquipmentTypes(requestBody)
      const res = JSON.parse(equipmentTypes)
      if (res && res.statusCode === 200) {
        const currentBuildingEqTypes = rootData
          .filter((item) => item.buildingId === buildingId)
          .map((item) => item.equipmentFamily)
        const equipmentTypes = Object.keys(res.body?.EquipmentType)?.map(
          (type, i) => ({
            name: type,
            id: i,
            data: "EquipmentTypes",
            checked: currentBuildingEqTypes.includes(type),
            familyType: Object.values(res.body?.EquipmentType)[i]
          })
        )
        const equipmentTypesByWidgets = isEnergyWidget
          ? equipmentTypes?.filter((e) =>
              ENERGY_METER_TYPES.includes(e?.familyType)
            )
          : equipmentTypes
        setEquipmentTypesCheckList(
          equipmentTypesByWidgets.sort((a, b) =>
            a?.name?.localeCompare(b?.name)
          )
        )
      }
    } catch (error) {}
  }

  // Get equipments for given building and equipment type
  const getEquipments = async (equipmentInfo, isInitialEdit) => {
    const buildingId = isInitialEdit
      ? equipmentInfo?.buildingId
      : selectedBuilding.id
    const equipmentType = isInitialEdit
      ? equipmentInfo.equipmentType
      : equipmentInfo.familyType 
    const familyType = isInitialEdit
      ? equipmentInfo.equipmentFamily
      : equipmentInfo.name
    const equipments = await refetchEquipments({
      buildingId: buildingId,
      type: equipmentType
    })
    // If equipmentid presents in rootdata, which means a equipment is selected already
    // This will work for both edit and create
    // We may have to change a logic a bit, if we have to support more than 1 equipment selection
    const equipmentId = rootData.find((x) => x.equipmentId)
    const currentEquipments = rootData
      .filter(
        (item) =>
          item.buildingId === buildingId && item.equipmentFamily === familyType
      )
      .map((item) => item.equipmentId)
    if (equipments?.length) {
      setEquipmentsCheckList(
        equipments
          .map((a) => ({
            name: a.name,
            id: a.id,
            checked: currentEquipments.includes(a.id),
            disabled: (() => {
              // This will run only for Baseline object picker
              // Have to disable equipments, which have baselines already
              if (baselineEquipments.length > 0) {
                return baselineEquipments.includes(a.id)
              } else {
                return (
                  rootData?.length >= equipmentSelectLimit &&
                  !currentEquipments.includes(a.id) &&
                  equipmentId
                )
              }
            })() 
          }))
          .sort((a, b) => a?.name?.localeCompare(b?.name))
      )
    }
  }

  const getEquipmentProperties = async (equipmentInfo, isInitialEdit) => {
    if (equipmentInfo?.id) {
      try {
        const equipmentId = isInitialEdit
          ? equipmentInfo?.equipmentId
          : equipmentInfo?.id
        const equipmentProperties = await getEquipmentPropertiesQuery({
          equipmentId: equipmentId
        })
        let currentProperties = []
        if (rootData?.length > 0) {
          currentProperties = rootData[0].propertiesList?.map((x) => x.id)
        }
        if (equipmentProperties?.length > 0) {
          const mappedProperties = equipmentProperties
            .map((a) => ({
              name: a.tisDisplayName || a.targetKey,
              key: a.targetKey,
              id: a.id,
              targetMeasureUnit: a.targetMeasureUnit,
              symbol:
                propertySymbolsValues.find(
                  (x) => x.UoM === a.targetMeasureUnit
                ) || '',
              checked: currentProperties.includes(a.id),
              disabled:
                currentProperties.length >= equipmentSelectLimit &&
                !item.checked
              // Filter analog and state properties not needed for Chart data
            }))
            .filter((x) => x.targetMeasureUnit !== 'noUnit')
            .sort((a, b) => a?.key?.localeCompare(b?.key))
          // Display only props with names or target keys
          setEquipmentPropertiesCheckList(
            mappedProperties.filter((x) => x.name !== null)
          )
        }
      } catch (error) {
        setEquipmentPropertiesCheckList([])
      }
    }
  }

  const updateBuilding = async (buildingInfo) => {
    if (buildingInfo) {
      const itemsList = [...buildingsCheckList]
      let alreadyCheckedIndex = itemsList.findIndex((i) => i.checked)
      const index = itemsList.findIndex((i) => i.id === buildingInfo.id)
      if (!objects.includes(EQUIPMENT)) {
        if (buildingSelectLimit >= 2) {
          itemsList[index].checked = !buildingInfo.checked
        } else if (buildingSelectLimit === 1) {
          if (alreadyCheckedIndex !== -1) {
            itemsList[alreadyCheckedIndex].checked = false
          }
          if (alreadyCheckedIndex !== index) {
            itemsList[index].checked = !buildingInfo.checked
          }
        }
        const selected = itemsList
          .filter((v) => v.checked)
          .map((item) => {
            return {
              buildingId: item.id,
              buildingName: item.name,
              accountName: item.accountName,
              accountId: item.accountId,
              salesOfficeName: item.salesOfficeName,
              salesOfficeId: item.salesOfficeId,
              timeZone: item?.tz
            }
          })
        setCheckedValues(selected)
        setRootData(selected)
        const bIds = [...buildingIds ?? [],...selected?.map((m) => m?.buildingId) ?? []]
        setBuildingIds(bIds)
      } else {
        const bIds = [...buildingIds ?? [],...[buildingInfo.id]]
        setBuildingIds(bIds)
        setSelectedBuilding(buildingInfo)
        await getEquipmentTypes(buildingInfo.id)
        setStep(2)
      }
      setBuildingsCheckList(itemsList)
    }
  }

  const updateEquipmentType = async (equipmentTypeInfo) => {
    if (equipmentTypeInfo) {
      setSelectedEquipmentType(equipmentTypeInfo)
      await getEquipments(equipmentTypeInfo)
      setStep(3)
    }
  }

  const updateEquipment = async (equipmentInfo) => {
    if (equipmentInfo) {
      const itemsList = [...equipmentsCheckList]
      let alreadyCheckedIndex = itemsList.findIndex((i) => i.checked)
      const index = itemsList.findIndex((i) => i.id === equipmentInfo.id)
      let toRootData = [...rootData]
      if (equipmentSelectLimit >= 2) {
        itemsList[index].checked = !equipmentInfo.checked
      } else if (equipmentSelectLimit === 1) {
        if (alreadyCheckedIndex !== -1) {
          itemsList[alreadyCheckedIndex].checked = false
        }
        if (alreadyCheckedIndex !== index) {
          itemsList[index].checked = !equipmentInfo.checked
        }
      }
      if (!objects.includes(PROPERTY)) {
        const selectedRootElement = {
          equipmentId: itemsList[index].id,
          equipmentName: itemsList[index].name,
          checked: itemsList[index].checked,
          buildingId: selectedBuilding?.id,
          buildingName: selectedBuilding?.name,
          accountName: selectedBuilding?.accountName,
          accountId: selectedBuilding?.accountId,
          salesOfficeName: selectedBuilding?.salesOfficeName,
          timeZone: selectedBuilding?.timeZone,
          salesOfficeId: selectedBuilding?.salesOfficeId,
          equipmentType: selectedEquipmentType?.familyType,
          equipmentFamily: selectedEquipmentType?.name
        }

        if (selectedRootElement?.checked) {
          toRootData.push(selectedRootElement)
        } else if (!selectedRootElement?.checked) {
          toRootData = rootData.filter(
            (i) => i.equipmentId !== selectedRootElement.equipmentId
          )
        }
        setRootData(toRootData)
        const rootDataLimit = toRootData.length >= equipmentSelectLimit
        setIsRootDataLimit(rootDataLimit)
        setEquipmentsCheckList(
          itemsList.map((item) => ({
            ...item,
            disabled: (() => {
              // This will run only for Baseline object picker
              // Have to disable equipments, which have baselines already
              if (baselineEquipments.length > 0) {
                return (
                  baselineEquipments.includes(item.id) ||
                  (rootDataLimit && !item.checked)
                )
              } else {
                return rootDataLimit && !item.checked
              }
            })()
          }))
        )
      } else {
        // If it is not equipment unselect, go to next step
        if (alreadyCheckedIndex !== -1) {
          setEquipmentsCheckList(
            itemsList.map((item) => ({
              ...item,
              checked: false,
              disabled: false
            }))
          )
          setRootData([])
          setSelectedEquipment(null)
        } else {
          setEquipmentsCheckList(
            itemsList.map((item) => ({
              ...item,
              disabled: !item.checked
            }))
          )
          setSelectedEquipment(equipmentInfo)
          await getEquipmentProperties(equipmentInfo)
          setStep(4)
        }
      }
    }
  }

  const updateProperty = (propertyInfo) => {
    try {
      if (propertyInfo) {
        const propertiesList = [...equipmentPropertiesCheckList]
        let currentPropertyIndex = propertiesList.findIndex(
          (i) => i.id === propertyInfo.id
        )
        if (propertiesList[currentPropertyIndex]?.checked) {
          // if already checked, remove from selected list
          propertiesList[currentPropertyIndex].checked = false
        } else {
          propertiesList[currentPropertyIndex].checked = true
        }

        const selectedPropertiesList = propertiesList.filter((x) => x.checked)

        const selectedRootElement = {
          equipmentId: selectedEquipment.id,
          equipmentName: selectedEquipment.name,
          checked: true,
          buildingId: selectedBuilding?.id,
          buildingName: selectedBuilding?.name,
          accountName: selectedBuilding?.accountName,
          accountId: selectedBuilding?.accountId,
          equipmentType: selectedEquipmentType?.familyType,
          equipmentFamily: selectedEquipmentType?.name,
          propertiesList: selectedPropertiesList
        }
        setRootData([selectedRootElement])
        const propertyUniqueUOMs = [
          ...new Set(
            selectedPropertiesList
              ?.map((x) => {
                return x?.symbol?.UoM
              })
              .filter((x) => x && x !== '')
          )
        ]
        const isLimitReached = propertyUniqueUOMs?.length >= propertyUOMLimit
        setEquipmentPropertiesCheckList(
          propertiesList.map((item) => ({
            ...item,
            disabled:
              isLimitReached &&
              !item.checked &&
              !propertyUniqueUOMs.includes(item?.symbol?.UoM)
          }))
        )
      }
    } catch (error) {
      setEquipmentPropertiesCheckList([])
    }
  }

  const handleEquipmentPropertiesBackButton = () => {
    const currentEquipmentId = selectedEquipment?.id
    setEquipmentsCheckList((equipmentsCheckList) =>
      equipmentsCheckList.map((equipment) => {
        return {
          ...equipment,
          checked: currentEquipmentId.includes(equipment.id)
        }
      })
    )
    setStep(3)
  }

  const handleEquipmentBackButton = async () => {
    if (initialValues && equipmentTypesCheckList.length === 0) {
      await getEquipmentTypes(initialValues?.[0].buildingId)
    }
    checkEquipmentTypes()
    setEquipmentsCheckList([])
    setStep(2)
  }

  const handleEquipmentTypesBackButton = () => {
    const currentBuildingIds = rootData.map((item) => item.buildingId)
    setBuildingsCheckList((buildingsCheckList) =>
      buildingsCheckList.map((building) => {
        return {
          ...building,
          checked: currentBuildingIds.includes(building.id)
        }
      })
    )
    setStep(1)
  }

  const checkEquipmentTypes = () => {
    const currentBuildingEqTypes = rootData
      .filter((item) => item.buildingId === selectedBuilding?.id)
      .map((item) => item.equipmentFamily)
    setEquipmentTypesCheckList((equipmentsCheckList) =>
      equipmentsCheckList.map((eq) => {
        return {
          ...eq,
          checked: currentBuildingEqTypes.includes(eq.name)
        }
      })
    )
  }

  const removeSelection = (c) => {
    if (objects.includes(PROPERTY)) {
      setRootData([])
      setStep(1)
    } else if (objects.includes(EQUIPMENT)) {
      setRootData((rootData) =>
        rootData.filter(
          (item) =>
            c.buildingId !== item.building && c.equipmentId !== item.equipmentId
        )
      )
      setEquipmentsCheckList((equipmentsCheckList) =>
        equipmentsCheckList.map((item) => ({
          ...item,
          checked: c.equipmentId === item.id ? false : item.checked,
          disabled: false
        }))
      )
      setEquipmentTypesCheckList((equipmentTypesCheckList) =>
        equipmentTypesCheckList.map((item) => ({
          ...item,
          checked: c.equipmentFamily === item.name ? false : item.checked
        }))
      )
    } else {
      setRootData((rootData) =>
        rootData.filter((item) => c.buildingId !== item.buildingId)
      )
      setBuildingsCheckList((buildingsCheckList) =>
        buildingsCheckList.map((item) => ({
          ...item,
          checked: c.buildingId === item.id ? false : item.checked
        }))
      )
    }
  }
  const buildingNamesTranslate = `${selectedBuilding?.name || ''} ${translate("of")} ${selectedBuilding?.accountName || ''}`
  const EquipmentNameTranslate = `${translate(selectedEquipmentType?.name + ' at') || translate('at')} ${selectedBuilding?.name || ''} ${translate('of')} ${selectedBuilding?.accountName || ''}`
  useEffect(()=>{
    setErrors(buildingsCheckList.filter((item)=>item.checked===true))
  },[buildingsCheckList])

  return (
    <Wrapper error={error && (errors[0]?.checked !=undefined && errors[0]?.checked==true? false: true)}>
      <>
        {buildingsLoading ||
        equipmentTypesLoading ||
        equipmentsLoading ||
        loadingProperties ||
        loadingPropertySymbols ? (
          <Spinner />
        ) : (
          <>
            <>
              {step === 1 && (
                <ObjectPicker
                  selectedDivRef={firstRef}
                  values={buildingsCheckList}
                  update={(item) => updateBuilding(item)}
                  error={error}
                  dataHeader={<TranslateComponent>{"BUILDINGS"}</TranslateComponent>}
                  isFilteredBy={true}
                />
              )}
              {step === 2 && (
                <ObjectPicker
                  selectedDivRef={firstRef}
                  update={(item) => updateEquipmentType(item)}
                  values={equipmentTypesCheckList}
                  error={error}
                  navigationHeader={
                    <div
                      onClick={handleEquipmentTypesBackButton}
                      onKeyDown={handleEquipmentTypesBackButton}
                    >
                      <IconSVG
                        name={'left-caret'}
                        margin="0px 4px"
                        color={colors.WHITE}
                        hover={colors.WHITE}
                        width="15px"
                      />
                      <span> <TranslateComponent>All Buildings</TranslateComponent></span>
                    </div>
                  }
                  dataHeader={
                    <span> <TranslateComponent>{"EQUIPMENT TYPES"}</TranslateComponent></span>
                  }
                  buildingName={`${buildingNamesTranslate}`}
                  isFilteredBy={true}
                  type={"EquipmentTypes"}
                />
              )}
              {step === 3 && (
                <ObjectPicker
                  selectedDivRef={firstRef}
                  update={(item) => updateEquipment(item)}
                  values={equipmentsCheckList}
                  error={error}
                  navigationHeader={
                    <div
                      onClick={handleEquipmentBackButton}
                      onKeyDown={handleEquipmentBackButton}
                    >
                      <IconSVG
                        name={'left-caret'}
                        margin="0px 4px"
                        color={colors.WHITE}
                        hover={colors.WHITE}
                        width="15px"
                      />
                      <span>
                         <TranslateComponent>{"All Equipment Types"}</TranslateComponent>
                      </span>
                    </div>
                  }
                  dataHeader={
                    <span><TranslateComponent>{"EQUIPMENT"}</TranslateComponent></span>
                  }
                  buildingName={`${EquipmentNameTranslate}`}
                  isFilteredBy={true}
                />
              )}
              {step === 4 && (
                <ObjectPicker
                  selectedDivRef={firstRef}
                  update={(item) => updateProperty(item)}
                  values={equipmentPropertiesCheckList}
                  error={error}
                  navigationHeader={
                    <div
                      onClick={handleEquipmentPropertiesBackButton}
                      onKeyDown={handleEquipmentPropertiesBackButton}
                    >
                      <IconSVG
                        name={'left-caret'}
                        margin="0px 4px"
                        color={colors.WHITE}
                        hover={colors.WHITE}
                        width="15px"
                      />
                      <span><TranslateComponent >{"All Equipments"}</TranslateComponent></span>
                    </div>
                  }
                  dataHeader={<span><TranslateComponent>{`Property`}</TranslateComponent></span>}
                  buildingName={`${selectedEquipmentType?.name || ''} at ${
                    selectedBuilding?.name || ''
                  } of ${selectedBuilding?.accountName || ''}`}
                  isFilteredBy={true}
                  type={"Properties"}
                />
              )}
            </>
            {checkedValues?.length > 4 && (
              <StyleSeperator id="separator" ref={seperatorRef}>
                <div></div>
                <div></div>
              </StyleSeperator>
            )}
            <StyledDivFoot ref={secondRef}>
              {checkedValues?.length ? (
                <BottonSection>
                  <BottomHead> <TranslateComponent>Selected</TranslateComponent>:</BottomHead>
                  <BottomList>
                    {checkedValues.map((c, i) => (
                      <BottomListItem key={`close-${i}`}>
                        <IconSVG
                          onClick={() => {
                            removeSelection(c)
                          }}
                          name="close-circle"
                          color={colors.TBA_DARKER_GREY}
                          margin="2px 4px 0px 0px"
                          width="14px"
                          height="14px"
                        />
                        <BottomText>
                          <span>
                            <b><TranslateComponent>{c.equipmentName}</TranslateComponent></b>
                            {objects.includes(EQUIPMENT) ? <TranslateComponent> in </TranslateComponent> : ''}
                            {c.buildingName} <TranslateComponent> of </TranslateComponent>{c.accountName}
                          </span>
                        </BottomText>
                      </BottomListItem>
                    ))}
                  </BottomList>
                </BottonSection>
              ) : (
                ''
              )}
            </StyledDivFoot>
          </>
        )}
      </>
    </Wrapper>
  )
}

export default React.memo(ObjectPickerWrapper)
