import styles from './card-horizontal.module.scss'

export const CardHorizontal = ({
  color=CardHorizontalColor.default,
  icon=null,
  image=null,
  backgroundImage=null,
  backgroundSize='contain',
  backgroundColor=null,
  backgroundPosition=null,
  gradient=null,
  button=null,
  children=null,
  testId=''
}) => {
  return (
    <div
      className={styles.cardHorizontal}
      data-color={color}
      data-testid={testId || "cardHorizontal"}
      style={{
        background: gradient
      }}
    >
      <div
        className={styles.inner}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize,
          backgroundColor,
          backgroundPosition,
        }}
      >
        { (icon || image) && (
          <div className={styles.media}>
            { icon && <div className={styles.icon}>{icon}</div> }
            { image && <div className={styles.image}>{image}</div> }
          </div>
        )}
        <div>
          {children}
        </div>
        {button}
      </div>
    </div>
  )
}

export enum CardHorizontalColor {
  default='default',
  lightBlue='lightBlue',

}