import styled, { css } from 'styled-components'
import colors from 'src/components/layouts/colors.json'

export const ActionButton = styled.button`
  margin-left: auto;
  margin-left: 10px;
`

export const ToolBarWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 8px;
  }
`

export const EnergyConsumptionContainerDiv = styled.div`
  background-color: ${colors.WHITE};
  .chart-select-name {
    font-size: 14px;
    margin-top: 5px;
  }
`

export const ChartSelectButton = styled.div.attrs((props) => ({
  'data-testid': `${props?.testName}-button` || 'chart-select-button'
}))`
  background: ${colors.TBA_LIGHT_GREY};
  cursor: pointer;
  color: ${colors.WHITE};
  width: 60px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid ${colors.TBA_DARKER_GREY};
  ${(props) =>
    props.isSelected &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      background: ${colors.TBA_DARKER_GREY};
    `}
  margin: 0px 10px 10px 0px;
`

export const HeaderResultBlock = styled.div.attrs((props) => ({
  'data-testid': props?.testName || 'total-header'
}))`
  background-color: ${(props) =>
    props.color ? props.color : 'rgb(153, 153, 153)'};
  border-radius: 6px;
  margin: 0px 20px;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 148px;
  -webkit-box-pack: center;
  place-content: center;
  padding: 10px 10px;
  margin-bottom: 5px;
  min-width: 175px;
  .title {
    font-weight: 900;
    margin-bottom: 10px;
    font-size: 15px;
  }

  .spinner{
    color: white;
  }

  .value {
    font-size: 24px;
    font-weight: 800;
  }
  .unit {
    font-size: 1rem;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: normal;
  }

  .dollar {
    font-size: 24px;
    font-weight: 600;
  }

  .peak-value {
    font-size: 2.21em;
    font-weight: 800;
  }
`

export const ResultTitle = styled.p`
  font-weight: 900;
  margin-bottom: 10px;
  margin: 0px 0px 5px;
  font-size: 14;
  line-height: 1px;
`

export const WarningContainer = styled.div.attrs((props) => ({
  'data-testid': props?.testName || 'organization-selected-message'
}))`
  border: 2px solid ${colors.RED};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  color: ${colors.RED};
`

export const ChartAreaWrapper = styled.div`
  padding: 0px 10px 10px 10px;
  background-color: ${colors.WHITE};
  position: relative;
`

export const ChartControlContainer = styled.div`
  display: flex;
  > div:nth-child(1) {
    width: 80%;
  }
  > div:nth-child(2) {
    position: absolute;
    top: 0px;
    z-index: 1;
    right: 20px;
  }
`

export const NoDataMessage = styled.p.attrs((props) => ({
  'data-testid': props?.testName || 'no-data-message'
}))`
  font-weight: 700;
  font-size: 14px;
  padding: 20px;
  border: 1px solid lightgray;
`

export const DataRangeAvailableContainer = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  margin-top: -5px;
  maring-botton: 10px;
  justify-content: end;
`
