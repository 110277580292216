import styled from 'styled-components'
import colors from 'src/components/layouts/colors.json'

export const StyledAside = styled.aside`
  cursor: pointer;
  left: -179px;
  margin-right: -179px;
  position: relative;
  z-index: 13;
  flex-basis: 233px;
  width: 233px;
  margin-right: -179px;
  box-shadow: 3px 0 6px rgb(0 0 0 / 10%);
  min-height: calc(100vh - 94px);
  height: auto;
  background: ${(props) => props.bg};
  will-change: transform;
  transition: left 0.5s;
  &.open {
    left: 0;
    width: 233px;
    .nav-toggle-tooltip {
      display: none;
    }
  }
`

export const StyledView = styled.div`
  background: ${(props) => props.bg};
  height: 100vh;
  position: relative;
`

export const NavItem = styled.div`
  border-bottom: 1px solid #367326;
  border-top: 1px solid #61ae34;
  cursor: default;
`
export const Nav = styled.div`
  background: #4c912d;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const IconHolder = styled.div`
  font-size: 22px;
  line-height: 54px;
  width: 54px;
  text-align: center;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
`

export const StyledTextSpan = styled.div`
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: 14px;
    height: 100%;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    line-height: 20px;
    width: 100px;
    display: ${(props)=>props.display}
}
`

export const StyledHeaderSection = styled.div`
  align-items: center;
  background: #4c912d;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 54px;
  line-height: 20px;
  margin: 0;
  width: 100%;
}
`

export const TextSpanFlex = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  height: 100%;
  // max-width: 195px;
  svg {
    width: 34px;
    height: 12px;
    transition: all 0.4s ease;
  }
`

export const StyledNavPageLink = styled.div`
  a{
    color: #000;
    height: 24px;
    line-height: 24px;
    padding: 0 10px 0 44px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    text-decoration:none;
    overflow: hidden;
    &.active {
      font-weight: 700;
      background: white;
  }
  }
  &:hover {
      font-weight: 700;
  }
  button{
    display:block;
    margin: 0px 10px 0px 44px;
    background-color: #eee;
    border-radius: 6px;
    color: #000;
    font-size: 10px;
    font-weight: 900;
    height: 22px;
    letter-spacing: .27px;
    line-height: 12px;
    min-width: 36px;
    padding: 0 10px;
    text-transform: uppercase;
  }
  i{
    left: 25px;
    position: absolute;
    top: 6px;
  }
  i.active{
    color: ${colors.GREEN}
  }
}
`

export const StyledNavUL = styled.div`
  margin: 0;
  padding: 0;
  &:last-child {
    padding-bottom: 10px;
  }
  li {
    position: relative;
  }
`

export const SubNavDiv = styled.div`
  background: #f5f5f5;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s;
  &.open {
    max-height: ${({ childrencount }) => childrencount * 24 + 10}px;
    transition: max-height 0.4s;
  }
`

export const UiToggleWrapper = styled.div`
  padding: 8px 8px 30px;
  text-align: right;

  &.open {
    padding: 12px 12px 30px;
    text-align: right
  }
`

export const UiToggleButton = styled.button`

  /* collapsed sidebar is effectively 54px wide, actually it's 233 with a -179 left position. */

  display: inline-flex;
  padding: 10px;
  color: #fff;
  border: none;
  width: auto;
  border-radius: 14px;
  background: linear-gradient(213deg, rgba(255, 255, 255, 0.60) -18.29%, rgba(255, 255, 255, 0.00) 112.55%);
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
  }

  .amplify-switchfield {
    flex-grow: 1;
    display: none;
  }

  .amplify-switch-label {
    padding: 0;
    margin-left: 8px
  }

  .amplify-switch-track {
    background: rgba(255, 255, 255, 0.30);
  }

  label {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 18px !important; /* 128.571% */
  }

  &.open {
    display: flex;
    width: 100%;
    padding: 14px 16px;

    .amplify-switchfield {
      display: inline-block;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
`