import { useFormikContext } from '../MultiTabTorm'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { DataComparisonBarWidgetPreview } from 'src/pages/dashboards/components/DashboardCardWidget/DataComparisonBarWidgetPreview'
import { WidgetPreviewWrapper } from '../widgetPreviewWrapper'
import { WidgetChartContainer } from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { useMemo } from 'react'

const DataComparisonBarPreviewWrapper = () => {
  const formik = useFormikContext()
  const { values } = formik
  const { objectPickerSelectedValue, formikErrors } = useWidgetsContext()

  const getEquipmentList = () => {
    return (
      objectPickerSelectedValue &&
      objectPickerSelectedValue?.map((selectedValue) => {
        return {
          buildingName: selectedValue?.buildingName,
          equipmentName: selectedValue?.equipmentName,
          accountName: selectedValue?.accountName
        }
      })
    )
  }

  const getPreviewDetails = useMemo(() => ({
    widgetName: values?.name,
    widgetDiscription: values?.description,
    buildingId: values?.widgetBuilding,
    equipmentList: getEquipmentList(),
    equipmentId: objectPickerSelectedValue?.[0]?.equipmentId,
    property: [values?.leftProperty, values?.rightProperty],
    selectedTimePeriod: values?.timePeriod?.split('/')?.[1] || '',
    equipmentType: objectPickerSelectedValue?.[0].equipmentType,
    leftProperty: values?.leftProperty,
    leftUnitsOfMeasure: values?.leftUnitsOfMeasure || '',
    leftSymbol: values?.leftSymbol || '',
    equipmentId2: objectPickerSelectedValue?.[1]?.equipmentId ?? '',
    equipmentType2: objectPickerSelectedValue?.[1]?.equipmentType ?? '',
    rightProperty: values?.rightProperty,
    rightUnitsOfMeasure: values?.rightUnitsOfMeasure || '',
    rightSymbol: values?.rightSymbol || '',
    selectedAggrValue: values?.value?.split('/')?.[1] || '',
    currentLineColor: values.lineColor,
    previousPeriodLineColor: values.overlayLineColor,
    buildingName: objectPickerSelectedValue?.[0].buildingName,
    equipmentName: objectPickerSelectedValue?.[0].equipmentName,
    leftBarColor: values?.leftBarColor,
    rightBarColor: values?.rightBarColor,
    leftMarkerLabel: values?.leftMarkerLabel,
    rightMarkerLabel: values?.rightMarkerLabel,
    leftMarkerConstant: values?.leftMarkerConstant,
    rightMarkerConstant: values?.rightMarkerConstant,
    leftBenchmarkColor: values?.leftBenchmarkColor,
    rightBenchmarkColor: values?.rightBenchmarkColor,
    leftTargetMarker: values?.leftTargetMarker?.split('/')[1] || '',
    rightTargetMarker: values?.rightTargetMarker?.split('/')[1] || '',
    timeInterval: values?.timeInterval?.split('/')?.[1] || '',
    timeZone: objectPickerSelectedValue?.[0]?.timeZone,
  }), [values, objectPickerSelectedValue])

  return (
    <WidgetPreviewWrapper
      widgetName={values?.name}
      error={Object.keys(formikErrors)?.length > 0}
      chartType="bar-chart"
    >
      <WidgetChartContainer>
        <DataComparisonBarWidgetPreview widgetDetails={getPreviewDetails} />
      </WidgetChartContainer>
    </WidgetPreviewWrapper>
  )
}

export default DataComparisonBarPreviewWrapper
