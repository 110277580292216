import {
  tickFormatter,
  ticksGenerator
} from 'src/chart-library/CommonComponents/Axis/helper'
import { max } from 'lodash'
import { SCALE_TIME } from 'src/chart-library/Utils/Scales/constant'
import { getAlternateNumbers, getTextDimensions } from '../../helper'
import { getDateDiffInDays } from 'src/chart-library/Charts/XYChart/helper'
import { differenceInYears, isValid } from 'date-fns'

export const scaleTimePadding = (obj: any = {}) => {
  let paddingObject: any = {}
  let axisPadding = 0
  const {
    scale,
    tickCount,
    notD3,
    dimensionTickCount,
    format,
    isX,
    d3Ticks,
    adjustmentScale,
    availableSpace = {}
  } = obj
  let ticks =
    ticksGenerator(
      scale,
      tickCount,
      SCALE_TIME,
      notD3,
      null,
      dimensionTickCount,
      d3Ticks,
      adjustmentScale
    ) || []

  const dateDiff = getDateDiffInDays(ticks)
  let isGTYear = false
  const fDate = new Date(ticks?.[0])
  const sDate = new Date(ticks?.[1])
  if (isValid(fDate) && isValid(sDate)) {
    const diffYears = differenceInYears(sDate, fDate)
    if (diffYears >= 1) {
      isGTYear = true
    } 
  }
  ticks = ticks?.map((text, index) => {
    // More information about tick that will be shared to formatt function if provided
    const tickInfo = {
      index,
      isFirst: index === 0,
      isLast: ticks?.length === index + 1,
      value: text
    }

    const newText = tickFormatter(format, text, SCALE_TIME, {
      dateDiff,
      index,
      isGTYear
    }, tickInfo)
    const textDimensions = getTextDimensions(newText)
    return {
      text,
      formattedText: newText,
      textDimensions
    }
  })

  const dimension = availableSpace?.[isX ? 'width' : 'height']
  let tickLength = ticks?.length;
  let step = dimension / tickLength
  let i = 0;
  let withinStep = ticks?.every((obj) => (obj?.textDimensions?.[isX ? 'width' : 'height'] + (obj?.textDimensions?.[isX ? 'width' : 'height'] * 0.2) < step))
  while (!withinStep) {
    ticks = getAlternateNumbers(ticks)
    tickLength = ticks?.length
    step = dimension / tickLength
    withinStep = ticks?.every((obj) => (obj?.textDimensions?.[isX ? 'width' : 'height'] + (obj?.textDimensions?.[isX ? 'width' : 'height'] * 0.2) < step))
    if (i === 9) {
      withinStep = true
    }
    i++
  }
  let ticksPadding = max(
    ticks?.map((obj: any) => obj?.textDimensions?.[!isX ? 'width' : 'height'])
  )
  ticksPadding = ticksPadding > 0 ? ticksPadding : 0

  axisPadding = axisPadding + ticksPadding

  paddingObject = {
    tickValues: ticks,
    textSize: axisPadding
  }

  return paddingObject
}
