import { SCALE_LINEAR } from 'src/chart-library/Utils/Scales/constant'
import moment from 'moment/moment'
import XYChart from 'src/chart-library/Charts/XYChart'
import { WidgetChartTitleComponent } from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { CustomKPITrendTopPreview } from '../../../widgets/widget-library/customKPIWidget/CustomKPITrendTopPreview'
import CustomKPIPointPreview from '../../../widgets/widget-library/customKPIWidget/CustomKPIPointPreview'
import { CustomKPITrendBottom } from '../../../widgets/widget-library/customKPIWidget/CustomKPITrendBottom'
import colors from 'src/components/layouts/colors.json'
import { timeFormat } from 'd3-time-format'
import * as d3 from 'd3'
import {
  getGoalType,
  TREND_MODE,
  getValuesForTrend
} from '../../../widgets/widget-library/customKPIWidget/helper'
import { Block } from 'src/components/layouts'
import { useMemo } from 'react'

export const CustomKPITrendsWidgetPreview = ({ widgetDetails }) => {
  const { chartData, timePeriod, trendData, trendUpIsGood } = widgetDetails
  const { lastDataWithBothActualAndGoal } = getValuesForTrend(
    trendData,
    timePeriod
  )
  const { value } = lastDataWithBothActualAndGoal
  const goal = getGoalType(
    trendUpIsGood,
    value?.actual,
    value?.goal,
    TREND_MODE
  )
  const { allData, seriesData } = useMemo(() => {
    const actualData = []
    const goalData = []
    let beatingGoal = []
    let missedGoal = []
    const totalData = []
    const allData = []
    if (chartData?.length !== 0) {
      chartData?.data.map((actual) => {
        actualData.push(actual[0] ? actual[0] : null)
        goalData.push(actual[1] ? actual[1] : null)
      })
      beatingGoal = chartData?.data
      missedGoal = chartData?.data
    }
    totalData.push(actualData)
    totalData.push(goalData)
    totalData.push(beatingGoal)
    totalData.push(missedGoal)
    allData.push(...actualData, ...goalData)
    const data = {}
    totalData?.forEach((element, index) => {
      data[index + 1] = {
        data: element
      }
    })
    return {
      allData,
      seriesData: data
    }
  }, [chartData?.data])

  const maxValue = Math.max(...(allData || []))

  function monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    )
  }
  function getXAxisTicksAmount(cols, [startDate, endDate]) {
    const standardTicksAmount = cols === 1 ? 8 : 16
    const intervalInMonth =
      monthDiff(new Date(startDate), new Date(endDate)) || 1
    return intervalInMonth < standardTicksAmount
      ? intervalInMonth
      : standardTicksAmount
  }
  const getLegends = () => {
    return [
      {
        id: 1,
        name: 'Actual',
        seriesKey: 1,
        elementProps: {
          stroke: colors.BLUE,
          strokeWidth: 2
        },
        properties: {
          noBreak: true
        },
        xAxisKey: 'x1',
        yAxisKey: 'y1',
        canvasIndex: '0',
        dashType: 'solid',
        shape: 'Line',
        show: true,
        visible: true,
        type: 'line'
      },
      {
        id: 2,
        name: 'Goal',
        seriesKey: 2,
        elementProps: {
          stroke: colors.GREEN_5,
          strokeWidth: 2
        },
        xAxisKey: 'x1',
        yAxisKey: 'y1',
        canvasIndex: '0',
        dashType: 'solid',
        properties: {
          noBreak: true
        },
        shape: 'Line',
        show: true,
        visible: true,
        type: 'line'
      },
      {
        id: 3,
        name: 'Beating Goal',
        seriesKey: 3,
        elementProps: {
          fill:
            widgetDetails?.selectedKpiMeasurement !== 'energyStarScore'
              ? colors.RED_1
              : colors.TBA_LIGHT_GREEN,
          stroke: 'none'
        },
        xAxisKey: 'x1',
        yAxisKey: 'y1',
        canvasIndex: '0',
        properties: {
          noBreak: true
        },
        show: true,
        visible: true,
        type: 'areaDual',
        areaIndex: 0
      },
      {
        id: 4,
        name: 'Missed Goal',
        seriesKey: 4,
        elementProps: {
          fill:
            widgetDetails?.selectedKpiMeasurement !== 'energyStarScore'
              ? colors.TBA_LIGHT_GREEN
              : colors.RED_1,
          stroke: 'none'
        },
        xAxisKey: 'x1',
        yAxisKey: 'y1',
        canvasIndex: '0',
        properties: {
          noBreak: true
        },
        show: true,
        visible: true,
        type: 'areaDual',
        areaIndex: 1
      }
    ]
  }

  const lMonthValue = moment.monthsShort();
  const lMonths = {
    "Jan" : lMonthValue[0], "Feb" : lMonthValue[1], "Mar" : lMonthValue[2], "Apr" : lMonthValue[3], "May" : lMonthValue[4], 
    "Jun" : lMonthValue[5], "Jul" : lMonthValue[6], "Aug" : lMonthValue[7], "Sep" : lMonthValue[8], "Oct" : lMonthValue[9], 
    "Nov" : lMonthValue[10], "Dec" : lMonthValue[11]
  }

  const createXAxis = () => {
    return [
      {
        key: 'x1',
        scale: {
          props: {
            type: 'scaleTime' // scaleTime
          },
          categories: (chartData && chartData?.timestamp) || [],
          scaleSettings: {}
        },
        axis: {
          visibleAt: ['bottomBottom'],
          hideTicks: false,
          hideLine: true,
          format: (val: any) => { return lMonths[timeFormat('%b')(val)] }, // %b - returns abbreviated month name from d3-time-format
        },
        grid: [
          {
            stroke: 'gray',
            includeAxis: false
          }
        ]
      }
    ]
  }

  const createYAxis = () => {
    return [
      {
        key: 'y1',
        scale: {
          props: {
            rangeFixed: true,
            defaultRange: [0, maxValue],
            type: 'scaleLinear'
          }
        },
        axis: {
          hideTicks: true,
          hideLine: true,
          visibleAt: ['leftLeft'], // Optional,
          notD3: true,
          // format: (val: any) => Math.round(val)
        },
        grid: [
          {
            stroke: 'gray',
            includeAxis: true
          }
        ]
      }
    ]
  }
  const getConfig = () => {
    let chartConfigData = {}
    if (chartData.length !== 0) {
      chartConfigData = {
        canvas: {
          canvasDetails: {
            0: {
              renderAreaShade: {
                fill: colors.WHITE,
                opacity: '0.1'
              },
              tooltip: {
                type: 'x',
                referenceLineV: true
              }
            }
          }
        },
        x: createXAxis(),
        y: createYAxis(),
        series: {
          types: getLegends(),
          data: seriesData
        },
        width: '100%',
        height: 140,
        dataLastUpdatedAt: moment().unix()
      }
    }
    return chartConfigData
  }

  return (
    <Block height = "100%">
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName=""
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDiscription}
        widgetProperty={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      {widgetDetails.type !== 'point' && (
        <>
          <CustomKPITrendTopPreview widgetDetails={widgetDetails} goal={goal} />
          <XYChart {...getConfig()} />
          <CustomKPITrendBottom widgetDetails={widgetDetails} />
        </>
      )}

      {widgetDetails.type === 'point' && (
        <CustomKPIPointPreview widgetDetails={widgetDetails} />
      )}
    </Block>
  )
}
