import React from 'react'
import Label from '../label/label'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './index.scss'
import i18next from 'i18next'
import { TranslateComponent } from '../../../../common/translations'

const TYPE_TEXT = 'text'

const ModalTextInput = ({
  defaultValue,
  value,
  hasError,
  isValid,
  labelText,
  isRequired,
  isDisabled,
  remark,
  tooltip,
  tooltipMultiline,
  units,
  inputClass,
  type = TYPE_TEXT,
  onChange,
  onKeyPress,
  onKeyUp,
  onBlur,
  errorMessage,
  testName='',
  maxLength = null,
  customLabelClass,
  labelWithPowerValue,
  ...rest
}) => {
  const inputProps = {
    className: classNames(`modal-text-input ${inputClass}`, {
      'has-error': hasError
    }),
    type,
    [defaultValue ? 'defaultValue' : 'value']: defaultValue
      ? defaultValue
      : value,
    disabled: isDisabled,
    onChange,
    onKeyPress,
    onKeyUp,
    maxLength: maxLength,
    onBlur,
    ...rest
  }
  const defaultErrorText = `${labelText} is required.`

  return (
    <div className="modal-text-wrapper">
     {labelText &&  <Label
        text={
          <span className={classNames({ 'has-error': hasError })}>
           { labelText && <TranslateComponent>{labelText}</TranslateComponent> }
            {Boolean(isRequired) && '*'}
          </span>
        }
        disabled={isDisabled}
        customClass={customLabelClass}
      />}

      {labelWithPowerValue && <Label
        text={
          
          <span className={classNames({ 'has-error': hasError })}>
          {labelWithPowerValue!=''? labelWithPowerValue: " "}
           {Boolean(isRequired) && '*'}
         </span>
        }
        disabled={isDisabled}
        customClass={customLabelClass}
      />}
      <div>
        <input data-testid={testName} {...inputProps} />
        {units && (
          <span
            className={classNames('units', { 'units--disabled': isDisabled })}
          >
            {units}
          </span>
        )}
        {remark && (
          <span
            className={classNames('remark', { 'remark--disabled': isDisabled })}
          >
            {remark}
          </span>
        )}
        {tooltip ? (
          <button
            className={classNames(
              'marker-icon icon-infocircle tooltip-container',
              { 'tooltip-container--multiline': tooltipMultiline }
            )}
          >
            <div className="tooltip right">{tooltip}</div>
          </button>
        ) : null}
        {/* {hasError && <span className="icon icon-error" />} */}
        {/* {isValid && <span className="icon icon-check" />} */}
      </div>
      {hasError && (
        <span className="mini-error-text">
          <TranslateComponent>{errorMessage || defaultErrorText}</TranslateComponent>
        </span>
      )}
    </div>
  )
}

ModalTextInput.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasError: PropTypes.bool,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  inputClass: PropTypes.string,
  customLabelClass: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
  remark: PropTypes.string,
  tooltip: PropTypes.string,
  units: PropTypes.string,
  tooltipMultiline: PropTypes.bool,
  // pass in { errorMessage } to override default error text
  errorMessage: PropTypes.string
}

ModalTextInput.defaultProps = {
  hasError: false,
  labelText: '',
  isRequired: true,
  isDisabled: false,
  inputClass: '',
  customLabelClass: '',
  defaultValue: '',
  onKeyPress: () => {},
  onKeyUp: () => {},
  onBlur: () => {}
}

export default ModalTextInput
