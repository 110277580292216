import styled from 'styled-components'
import colors from 'src/components/layouts/colors.json'
import Spinner from 'src/components/legacy/components/spinner/spinner'

export const TabsWrapper = styled.div`
  width: 98%;
  margin: 0 auto;
  li {
    margin: 0 5px;
    border: none;
    background-color: #ccc;
    color: #292323;
    font-weight: 600;
    border-radius: 5px 5px 0 0 !important;
    justify-content: flex-start;
    font-size: 13px;
    padding-left: 15px;
  }
  .active {
    background-color: #fff;
    color: #000;
  }
`
export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  border: 1px solid gray;
  margin: 10px auto;
  background-color: #fff;
  padding: 10px;
`

export const LeftSection = styled.div`
  width: 49%;
  background-color: #fff;
  display: flex;
  align-items: center;
`

export const RightSection = styled.div`
  width: 51%;
  height: 100%;
`

export const ImageDiv = styled.div`
  align-items: center;
  justify-content: center;
  background: #eee;
  border: 1px solid #ccc;
  display: flex;
  height: 110px;
  width: 180px;
  margin: 10px;
`

export const DetailTable = styled.div`
  table {
    tbody {
      tr {
        td:first-child {
          font-weight: 600;
          padding: 2px 0 2px 7px;
          color: #000;
        }
        td:last-child {
          padding-left: 15px;
        }
      }
    }
  }
`
export const SpinnerWrapper = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 80vh;
`
export const EQTable = styled.div`
  table {
    thead {
      tr {
        background-color: #467e26;
        color: white;
      }
    }
    tbody {
      tr:nth-child(odd) {
        background-color: #ffffff;
      }
      tr:nth-child(even) {
        background-color: #eeeeee;
      }
    }
  }
`

export const ErrorText = styled.span`
  font-weight: bold;
  font-size: 13px;
  color: #d52b23;
`

export const LoadFactoryButton = styled.button`
  background-color: ${colors.TBA_DARK2_GREY};
  padding: 5px 10px;
  font-size: 0.75rem;
  font-weight: 900;
  margin-top: 5px;
  color: ${colors.WHITE};
`

export const SerialNumberInput = styled.input`
  height: 22px;
  padding-left: 4px;
  width: 123px;
`

export const SerialNumberActionButton = styled.button`
  ${(props: any) =>
    props.cancel === true
      ? `background: ${colors.TBA_DARKER_GREY}`
      : `background: ${colors.TBA_DARK_GREEN}`};
  ${(props: any) =>
    props.disabled === true && `background: ${colors.TBA_LIGHTER_GREY}`};
  ${(props: any) =>
    props.disabled === true
      ? `color: : ${colors.TBA_DARK_GREY}`
      : `color:${colors.WHITE}`};
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  height: 22px;
  padding: 2px 5px;
  letter-spacing: 0.36px;
  margin-left: 7px;
  border-radius: 3px;
  line-height: 14px;
  width: 59px;
  text-transform: none;
`

export const NoteText = styled.p`
  color: #666;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 13px;
  margin-top: 5px;
`
export const ResizableSpinner = styled(Spinner)`
  display: inline-flex;
  height: unset;
  margin: unset;
  padding: unset;
  border: unset;
  border-radius: unset;
  font-size: ${(props) => props.$size || '1em'};
  .spin-wheel {
    font-size: inherit;
  }
`
