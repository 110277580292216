import React, { useEffect, useMemo, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import _find from "lodash/find"
import TreeViewNode from "./tree-view-node.sc"
import { TreeViewStyled } from "./styles"
import
{
	COMPONENTS,
	PROP_NAMES
} from "./constants"

/**
 * Tree View Shared Component
 */
export const TreeView = (
	{
		children,
		className,
		components,
		data,
		expandedNodes,
		multiSelect,
		propNames,
		selectable,
		selectedNodes,
		onSelect,
		onToggle,
	}
) => {
	const [_selectedNodes, _setSelectedNodes] = useState([])
	const [_expandedNodes, _setExpandedNodes] = useState([])
	const _components = useMemo(
		() => ({
			...COMPONENTS,
			...components
		}),
		[components]
	)

	const _propNames = useMemo(
		() => ({
			...PROP_NAMES,
			...propNames
		}),
		[propNames]
	)
	const _data = useMemo(
		() => {
			const mapData = tree => tree?.map(nodeData => {
				const children = mapData(nodeData?.[_propNames.children])
				const isSomeChildExpanded = children?.some(({ expanded }) => expanded)
				const isSomeChildSelected = children?.some(({ selected }) => selected)
				const isSelected = Boolean(
					_selectedNodes?.find(selectedNode => _find([nodeData], selectedNode))
				)
				// marks parent node as expanded when it is expanded via expandedNodes list or its child is expanded or selected
				const isExpanded = Boolean(_expandedNodes?.find(expandedNode => _find([nodeData], expandedNode)))
					|| isSomeChildExpanded || isSomeChildSelected || false

				return {
					originalData: nodeData,
					...nodeData,
					expanded: isExpanded,
					selected: isSelected,
					[_propNames.children]: children
				}
			}) ?? null

			return mapData(data)
		},
		[data, _expandedNodes, _selectedNodes]
	)
	useEffect(
		() => {
			_setSelectedNodes(selectedNodes)
		},
		[selectedNodes]
	)

	useEffect(
		() => {
			_setExpandedNodes(expandedNodes)
		},
		[expandedNodes]
	)

	const onSelectHandler = bag => {
		const isSelected = bag?.data?.selected
		const newSelectedNode = {
			[_propNames.id]: bag?.data?.[_propNames.id]
		}
		if (onSelect && onSelect(bag) === false) {
			return false
		}
		if (multiSelect) {
			if (isSelected) {
				// add it
				_setSelectedNodes([
					..._selectedNodes,
					newSelectedNode
				])
			} else {
				// remove it
				_setSelectedNodes(
					_selectedNodes
						.filter(node => !_find([bag?.data], node))
				)
			}
		} else {
			// store new one selected
			_setSelectedNodes(isSelected ? [newSelectedNode] : [])
		}
	}

	const onToggleHandler = bag => {
		const isExpanded = bag?.data?.expanded
		const newExpandedNode = {
			[_propNames.id]: bag?.data?.[_propNames.id]
		}

		if (isExpanded) {
			_setExpandedNodes([
				..._expandedNodes,
				newExpandedNode
			])
		} else {
			_setExpandedNodes(
				_expandedNodes
					.filter(node => !(_find([bag?.data], node)))
			)
		}

		onToggle && onToggle(bag)
	}

	return (
		<TreeViewStyled className={classNames("tree-view", className)}>
			{
				children
					? children
					: _data?.map((childData, index) => (
						<TreeViewNode
							key={childData[_propNames?.id] ?? index}
							data={childData}
							components={_components}
							propNames={_propNames}
							expanded={childData.expanded}
							selected={childData.selected}
							selectable={selectable}
							multiSelect={multiSelect}
							onToggle={onToggleHandler}
							onSelect={onSelectHandler}
						/>
					)) ?? null
			}
		</TreeViewStyled>
	)
}


/**
 * The Node Data
 * @typedef {object} Node
 * @property {string|number} id - unique id
 * @property {string} label - label
 * @property {Node[]} children
 */

/**
 * The Node Bag
 * @typedef {object} Bag
 * @property bag.data {Node} - contains node data
 * @property bag.data.expanded {boolean} - whether the node is expanded
 * @property bag.data.selected {boolean} - whether the node is selected
 */

TreeView.propTypes = {

	/**
	 * The content of the component
	 */
	children: PropTypes.node,

	/**
	 * Custom classes
	 */
	className: PropTypes.string,

	/**
	 * Render components (controlled)
	 */
	components: PropTypes.shape({

		/**
		 * The component used to render expand/collapse/last icon
		 *
		 * @params {Bag}
		 * @returns {ReactNodeLike}
		 */
		ExpandIcon: PropTypes.func,

		/**
		 * The component used to render node label
		 *
		 * @params {Bag}
		 * @returns {ReactNodeLike}
		 */
		Label: PropTypes.func,

		/**
		 * The component used to render node label
		 *
		 * @params {Bag}
		 * @returns Boolean
		 */
		 IsExpandableNode: PropTypes.func
	}),

	/**
	 * Tree data
	 *
	 * By default takes array of objects like
	 * ```
	 * {
	 * 	id: string|number,
	 * 	label: string,
	 * 	children: [...]
	 * }
	 * ```
	 * Property names can be configured via `propNames` property
	 */
	data: PropTypes.arrayOf(PropTypes.object),

	/**
	 * Collection of expanded nodes (controlled)
	 *
	 * Each item of collection can be presented as part of original node
	 *
	 * i.e. `[{id: 1}, {label: "Node1"}, {id: 1, label: "Node 1"}]`
	 */
	expandedNodes: PropTypes.arrayOf(PropTypes.object),

	/**
	 * Collection of selected nodes (controlled)
	 *
	 * When `multiSelect = false` it uses only the first item
	 *
	 * Each item of collection can be presented as part of original node
	 *
	 * i.e. `[{id: 1}, {label: "Node1"}, {id: 1, label: "Node 1"}]`
	 */
	selectedNodes: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.object)
	]),

	/**
	 * Allows to select nodes
	 *
	 * The expanding behaviour will be available only for expand icon
	 */
	selectable: PropTypes.bool,

	/**
	 * Allows to select more than one node
	 */
	multiSelect: PropTypes.bool,

	/**
	 * Used to provide custom props names
	 */
	propNames: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		label: PropTypes.string,
		children: PropTypes.string,
	}),

	/**
	 * On toggle node callback

	 * Takes the bag object of expanded/collapsed node
	 * @params {Bag} bag - contains expanded/collapsed node props
	 */
	onToggle: PropTypes.func,

	/**
	 * On select node callback
	 *
	 * Takes the bag object of selected/unselected node
	 * When returns `false` will be prevented selecting node
	 * @params {Bag} bag - contains selected/unselected node props
	 */
	onSelect: PropTypes.func,
}

TreeView.defaultProps = {
	children: null,
	className: "",
	components: null,
	data: [],
	expandedNodes: [],
	selectedNodes: [],
	selectable: false,
	multiSelect: false,
	propNames: null,
	onSelect: null,
	onToggle: null
}

export default TreeView
