import React from 'react'
import { node, string, func } from 'prop-types'
import styled from 'styled-components'
import * as Color from '../../color.js'
import * as Spacing from '../../spacing.js'
import { Button } from '../../inputs/button'

export const CardTheme = {
  elevated: {
    light: {
      background: Color.WHITE,
      border: Color.LIGHT_BORDER,
      shadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)'
    }
  },
  outlined: {
    light: {
      background: Color.WHITE,
      border: Color.LIGHT_BORDER,
      shadow: false
    },
    dark: {
      background: Color.DARK_GREY_BG_1,
      border: Color.HAIRLINE,
      shadow: false
    }
  },
  selected: {
    light: {
      background: Color.WHITE,
      border: Color.PRIMARY_BLUE,
      shadow:
        '0px 0px 5px 0px rgba(15, 51, 95, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2)'
    },
    dark: {
      background: Color.PRIMARY_BLUE,
      border: Color.PRIMARY_BLUE,
      shadow: false
    }
  }
}

export const CardStyles = styled.article`
  background: ${(props) => CardTheme[props.type][props.mode].background};
  border-radius: 6px;
  border: 1px solid ${(props) => CardTheme[props.type][props.mode].border};
  box-shadow: ${(props) =>
    CardTheme[props.type][props.mode].shadow &&
    CardTheme[props.type][props.mode].shadow};
  width: 100%;
`

export const CardHeader = styled.header`
  padding: ${Spacing.SPACING_06};
`

export const Header = styled.h1`
  color: ${Color.DARK_GREY};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 15px;
  margin: 0;
`

export const SubHeader = styled.p`
  color: ${Color.GREY};
  font-family: ArialMT;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin: ${Spacing.SPACING_04} 0 0;
`

export const CardFooter = styled.footer`
  display: flex;
  justify-content: center;
  padding: ${Spacing.SPACING_06};
`

export const Card = (props) => {
  const { type, mode, children, header, subHeader, action, actionText } = props
  return (
    <CardStyles type={type ? type : 'outlined'} mode={mode ? mode : 'light'}>
      {(header || subHeader) && (
        <CardHeader>
          <Header>{header}</Header>
          {subHeader && <SubHeader>{subHeader}</SubHeader>}
        </CardHeader>
      )}
      {children}
      {action && (
        <CardFooter>
          <Button width="100%" onClick={action}>
            {actionText}
          </Button>
        </CardFooter>
      )}
    </CardStyles>
  )
}

Card.propTypes = {
  /** Type of card, example: "elevated", "outlined", "selected" */
  type: string,
  /** Mode of app, example: "light", "dark" */
  mode: string,
  /** React node passed inside of article element */
  children: node,
  /** header text for card */
  header: string,
  /** Subheader text for card */
  subheader: string,
  /** action to pass to onClick method of button inside of card */
  action: func,
  /** Node to pass into button element inside of card */
  actionText: node
}
