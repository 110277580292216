import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { getField } from 'src/components/legacy/common/helpers'
import _set from 'lodash/set'
import _get from 'lodash/get'
import moment from 'moment'
import { offerings } from 'src/components/legacy/common/offerings'
import {
  VALIDATION_ERRORS,
  reSubDomain,
  MAX_SUBDOMAIN_LENGTH,
  POSTAL_CODE_REGEXPS_BY_COUNTRY_CODE
} from './validation.js'
import { DEVICES_WITH_UPPERCASE_SERIAL } from 'src/components/legacy/common/connection-types'

export const FIELD_RESTRICTIONS = {
  locationName: {
    maxLength: 200
  },
  city: {
    maxLength: 50
  },
  deviceName: {
    maxLength: 100
  },
  deviceIdentifier: {
    maxLength: 128
  }
}

export class LocationManagementDetailsForm extends PureComponent {
  static propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    t: PropTypes.func
  }

  static getWarningMessage({ warningMessage, requiredFields = [] }) {
    return (
      <span className="location-management-warning-message">
        {warningMessage ? warningMessage : null}
        {requiredFields?.length ? (
          <>
            <span>
              The following information has not been completed. This data should
              be provided for all portions of the application to work correctly.
            </span>
            <span className="description">
              {requiredFields.map((field, index) => (
                <span key={index}>{field}</span>
              ))}
            </span>
          </>
        ) : null}
      </span>
    )
  }

  static getWarnings(values) {
    const warnings = []

    if (!values.floorAreaSquareFeet) {
      warnings.push('Building Area')
    }

    return warnings.length ? warnings : null
  }

  onSubmit = (values) => {
    const { t } = this.props
    const requiredFields = LocationManagementDetailsForm.getWarnings(values)
    let warningMessage = ''
    const devices = getField(values, 'locationConnectivity.devices') || []
    const lowerCaseSerialWarning = devices.some(
      (item) =>
        DEVICES_WITH_UPPERCASE_SERIAL.includes(item?.device?.deviceType) &&
        item?.device?.identifier.toUpperCase() !== item?.device?.identifier
    )
    if (lowerCaseSerialWarning) {
      warningMessage = (
        <span>
          {t(
            'building-configuration:BuildingSetupPage>Connectivity>serialNumbersNotification',
            {
              deviceTypes: DEVICES_WITH_UPPERCASE_SERIAL.map((item) =>
                t(`connect-device:deviceTypes>${item}`)
              ).join(', ')
            }
          )}
        </span>
      )
    }

    this.props.onSubmit(values, {
      warningMessage:
        (requiredFields || warningMessage) &&
        LocationManagementDetailsForm.getWarningMessage({
          warningMessage,
          requiredFields
        }),
      initialValues: this.props.initialValues
    })
  }

  validate = (values) => {
    const errors = {}
    const REQUIRED_DEVICE_IDENTIFIER =
      'Serial Number is required to add a device'

    const devices = getField(values, 'locationConnectivity.devices') || []
    devices.forEach((device, i) => {
      const isAllDeviceInputsEmpty = [
        'deviceType',
        'deviceName',
        'identifier'
      ].every(
        (field) =>
          _get(
            values,
            `locationConnectivity.devices[${i}].device.${field}`,
            ''
          ) === ''
      )
      if (
        !device.device.identifier &&
        (device.device.duiId || !isAllDeviceInputsEmpty)
      ) {
        // when user did not provide Hardware Serial Number for existing device there is validation error
        // or
        // when user did not provide Hardware Serial Number for new device
        // and all fields are not empty there is validation error
        _set(
          errors,
          `locationConnectivity.devices[${i}].device.identifier`,
          `${
            device.device.deviceType === 'ES' ? 'Ensemble' : 'Hardware'
          } ${REQUIRED_DEVICE_IDENTIFIER}`
        )
      }
    })

    const offeringSourceMaps = getField(values, 'offeringSourceMaps') || []
    offeringSourceMaps.forEach((offering, i) => {
      if (
        offering.checked &&
        offering.shortName === offerings.tenantServices.shortName
      ) {
        if (
          offering.startDate &&
          offering.expirationDate &&
          moment(offering.expirationDate).startOf('day') <
            moment(offering.startDate).startOf('day')
        ) {
          _set(
            errors,
            `offeringSourceMaps[${i}].startDate`,
            VALIDATION_ERRORS.offeringSourceMaps.startDate.earlier
          )
          _set(
            errors,
            `offeringSourceMaps[${i}].expirationDate`,
            VALIDATION_ERRORS.offeringSourceMaps.startDate.earlier
          )
        }
        if (
          offering.shortName === offerings.tenantServices.shortName &&
          typeof offering.subdomain !== 'undefined' &&
          (!offering.subdomain.length ||
            offering.subdomain.length > MAX_SUBDOMAIN_LENGTH ||
            !reSubDomain.test(offering.subdomain))
        ) {
          _set(
            errors,
            `offeringSourceMaps[${i}].subdomain`,
            VALIDATION_ERRORS.offeringSourceMaps.subdomain.invalid
          )
        }
      }
    })
    return errors
  }

  validationSchema = () => {
    const { t } = this.props

    return Yup.object().shape({
      locationName: Yup.string()
        .max(
          FIELD_RESTRICTIONS.locationName.maxLength,
          t('errors:maxAllowedFieldLength', {
            field: 'Building Name',
            number: FIELD_RESTRICTIONS.locationName.maxLength
          })
        )
        .required(VALIDATION_ERRORS.locationName),
      address: Yup.object().shape({
        line1: Yup.string().required(VALIDATION_ERRORS.address.line1),
        countryCode: Yup.string().required(
          VALIDATION_ERRORS.address.countryCode
        ),
        region: Yup.string()
          // regionCode can't be undefined and empty string. But it can be null since there are countries without regions.
          .test(
            'testRegionCode',
            VALIDATION_ERRORS.address.regionCode,
            (value) => value !== undefined && value !== ''
          )
          .nullable(),
        postalCode: Yup.string()
          .test(
            'testPostalCode',
            VALIDATION_ERRORS.address.postalCode,
            function (value) {
              const { countryCode } = this.parent
              const noPostalCode =
                POSTAL_CODE_REGEXPS_BY_COUNTRY_CODE[countryCode] === null

              if (value === '' && noPostalCode) {
                return true
              } else if (value && noPostalCode) {
                const defaultPostalCodeRegExp =
                  POSTAL_CODE_REGEXPS_BY_COUNTRY_CODE.DEFAULT
                return defaultPostalCodeRegExp.test(value)
              }

              const postalCodeRegExp =
                POSTAL_CODE_REGEXPS_BY_COUNTRY_CODE[countryCode] ||
                POSTAL_CODE_REGEXPS_BY_COUNTRY_CODE.DEFAULT

              return (
                value !== undefined &&
                value !== null &&
                postalCodeRegExp.test(value)
              )
            }
          )
          .nullable(),
        city: Yup.string()
          .max(
            FIELD_RESTRICTIONS.city.maxLength,
            t('errors:maxAllowedFieldLength', {
              field: 'City',
              number: FIELD_RESTRICTIONS.city.maxLength
            })
          )
          .required(VALIDATION_ERRORS.address.city)
      }),
      floorAreaSquareFeet: Yup.mixed().test(
        'floorAreaSquareFeet',
        VALIDATION_ERRORS.floorArea,
        (value) =>
          value === undefined ||
          value === '' ||
          (!Number.isNaN(value) && Number(value) >= 0)
      ),
      organizationId: Yup.string().required(VALIDATION_ERRORS.organizationId),
      salesOffice: Yup.object().shape({
        tisTraneOfficeId: Yup.string().required(
          VALIDATION_ERRORS.salesOffice.tisTraneOfficeId
        )
      }),
      locationConnectivity: Yup.object().shape({
        devices: Yup.array().of(
          Yup.object().shape({
            device: Yup.lazy((device) =>
              Yup.object().shape({
                deviceType: Yup.string(),
                deviceName: Yup.string()
                  .nullable()
                  .max(
                    FIELD_RESTRICTIONS.deviceName.maxLength,
                    t('errors:maxAllowedFieldLength', {
                      field:
                        device.deviceType === 'ES' ? 'Name' : 'Device Name',
                      number: FIELD_RESTRICTIONS.deviceName.maxLength
                    })
                  ),
                identifier: Yup.string().max(
                  FIELD_RESTRICTIONS.deviceIdentifier.maxLength,
                  () =>
                    t('errors:maxAllowedFieldLength', {
                      field:
                        device.deviceType === 'ES'
                          ? 'Ensemble Serial Number'
                          : 'Hardware Serial Number',
                      number: FIELD_RESTRICTIONS.deviceIdentifier.maxLength
                    })
                )
              })
            )
          })
        )
      }),
      customerCRMSiteId: Yup.mixed()
        .nullable()
        .test(
          'customerCRMSiteId',
          VALIDATION_ERRORS.customerCRMSiteId,
          (value) =>
            value === undefined ||
            value === null ||
            value === '' ||
            (!Number.isNaN(value) &&
              Number(value) > 0 &&
              Math.floor(value) == value)
        )
    })
  }

  render() {
    const { initialValues } = this.props
    return (
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={this.onSubmit}
        validationSchema={this.validationSchema}
        validate={this.validate}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize
      >
        {(props) => (
          <>
            <Form>{this.props.children(props)}</Form>
          </>
        )}
      </Formik>
    )
  }
}

export default (LocationManagementDetailsForm)
