import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Text, View } from "@react-pdf/renderer"
import { formatSavingsView, formatPrice } from "../../legacy/common/opportunity"
import { formatCurrency } from "../../legacy/common/currency"
import { round } from "../../legacy/common/helpers"
import { costOfWaitingValue } from "../../legacy/common/opportunity"

import { costSavingStyles } from "./pdf-cost-saving-block.styles"

const { costSaving, costSavingLabel, costSavingValue, costSavingPeriod, costSavingDate, potentialCostSaving } = costSavingStyles
const MAX_WORD_LENGTH = 10
const lineBreakValue = value => value.split("").map((v, i) => (++i % MAX_WORD_LENGTH) ? v : `\n${v}`).join("")

const PDFCostSaving = ({ label, value, period }) => <View style={costSaving}>
	<Text style={costSavingLabel}>{label}</Text>
	<Text style={costSavingPeriod}>{period}</Text>
	<Text style={costSavingValue}>{lineBreakValue(value)}</Text>
</View>

PDFCostSaving.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	period: PropTypes.string.isRequired,
}

const displayCostSavings = obj => Boolean(obj && obj.enabled && obj.value)

const PDFCostSavingBlock = ({ costSavings }) => {
	const [t] = useTranslation()
	const costOfWaiting = costSavings && costOfWaitingValue(costSavings)

	return <View style={costSavingStyles.wrapper}>
		{ costSavings ? <>
			{displayCostSavings(costSavings.savings) && <PDFCostSaving
				label="Energy Savings"
				value={formatCurrency(costSavings.savings.value)}
				period={formatSavingsView(costSavings.savings.tag, t)} />}
			{displayCostSavings(costSavings.costToImplement) && <PDFCostSaving
				label="Price"
				value={formatCurrency(costSavings.costToImplement.value)}
				period={formatPrice(costSavings.costToImplement.priceType, t)} />}
			{displayCostSavings(costSavings.paybackCalculation) && <PDFCostSaving
				label="Simple Payback"
				value={`${round(costSavings.paybackCalculation.value, 2).toLocaleString()} ${t("common:yrs")}`}
				period={formatPrice(costSavings.costToImplement.priceType, t)} />}
			{displayCostSavings(costSavings.roiCalculation) && <PDFCostSaving
				label="Simple ROI"
				value={`${round(costSavings.roiCalculation.value, 2).toLocaleString()} %`}
				period={formatPrice(costSavings.costToImplement.priceType, t)} />}

			{costOfWaiting && costOfWaiting.value
			&& <View style={potentialCostSaving}>
				<Text style={costSavingLabel}>If implemented on</Text>
				<Text style={costSavingDate}>{costOfWaiting.date}</Text>
				<Text style={costSavingPeriod}>Potential Savings By Now</Text>
				<Text style={costSavingValue}>{formatCurrency(round(costOfWaiting.value, 0))}</Text>
			</View>}
		</> : null}
	</View>
}

PDFCostSavingBlock.propTypes = {
	costSavings: PropTypes.object.isRequired,
}

export default PDFCostSavingBlock
