import { clone } from 'ramda'

import { BaseSvg } from './BaseSvg'
import gridPosition from '../Utils/gridPosition'

const GRID_KEY_PREFIX = 'g'

export class BaseGrid extends BaseSvg {
  private gridCache: BaseCache<GridDetail> = {}

  private activeGridKey: BaseActiveKey = {}

  private gridChangeLog: BaseLog = {}

  private grids: InputGrid[] = []

  protected prepareGrids(gridsFromOptions: InputGrid[]) {
    const isEqual = this.isConfigEqual(this.grids, gridsFromOptions)

    // console.log("prepare grids:", isEqual);

    if (!isEqual) {
      this.grids = clone(gridsFromOptions)
      this.initGrids()
    } else {
      this.gridChangeLog = {}
    }

    // console.log("BaseGrid : gridCache -", this.gridCache);
  }

  protected getGrid(gridIndex: number): GridDetail | undefined {
    return this.gridCache[GRID_KEY_PREFIX + gridIndex]
  }

  protected getAllGrid(): BaseCache<GridDetail> {
    return this.gridCache
  }

  protected getGridChangeLog(gridIndex: number): string | undefined {
    return this.gridChangeLog[GRID_KEY_PREFIX + gridIndex]
  }

  protected getAllGridChangeLog(): BaseLog {
    return this.gridChangeLog
  }

  protected resetGridChangeLog() {
    this.gridChangeLog = {}
  }

  protected handleGridResize() {
    this.checkGrid()
  }

  protected initGrids() {
    this.activeGridKey = {}
    this.checkGrid()
    this.refineGrids()
  }

  private checkGrid() {
    const svgElSize = this.svgSize
    this.grids.forEach((grid: InputGrid, index) => {
      this.setActiveGridkey(index)

      const gridPos = gridPosition(svgElSize, grid)
      this.setGridToScaleCache(index, gridPos)

      this.logGrid(GRID_KEY_PREFIX + index, 'changed')
    })
  }

  private setGridToScaleCache(gridIndex: number, gridPosition: GridPosition) {
    this.gridCache[GRID_KEY_PREFIX + gridIndex] = gridPosition
  }

  private refineGrids() {
    const keys = Object.keys(this.gridCache)

    keys.forEach((key) => {
      if (this.activeGridKey[key]) return

      delete this.gridCache[key]
      this.logGrid(key, 'removed')
    })
  }

  private setActiveGridkey(index: number) {
    this.activeGridKey[GRID_KEY_PREFIX + index] = true
  }

  private logGrid(gridKey: string, actionType: string) {
    this.gridChangeLog[gridKey] = actionType
  }

  protected resetGrid() {
    this.gridCache = {}

    this.activeGridKey = {}

    this.grids = []

    this.gridChangeLog = {}
  }
}
