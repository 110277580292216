import React, { useEffect, useState } from 'react'
import AlertNotification from 'src/components/legacy/components/alert-notification'
import { useSelector } from 'react-redux'
import { selectUserPermissions } from 'src/redux/slicers/appData'
import { REPORT_EXCEPTION_ISSUE } from 'src/components/legacy/common/pages'
import translate from './common/translations'

function ErrorFallback() {
  const [shouldReportIssue, setShouldReportIssue] = useState(false)
  //TODO: commented below because selectUserPermissions will no longer be a thing.
  // const pages = useSelector(selectUserPermissions)

  const optionsTranslated = {
    //There isn't a translation in errors for the following >>
    support: translate('errors:SupportPage'),

  }
  const supportPageText = <span>{optionsTranslated.support}</span>

  //Support page text may be not working correctly
  const messageToDisplay = shouldReportIssue ? (
      `The application has encountered an internal error. The error has been
      logged automatically for review by the development team. If you encounter
      this message more than once, please follow the link to the
      ${supportPageText} and add some details about what happened right before
      the error - this will help us address the issue.`)
   : (
    'The application has encountered an internal error.'
  )
  //TODO: commented below because selectUserPermissions will no longer be a thing.
  // useEffect(() => {
  //   setShouldReportIssue(
  //     Boolean(pages?.isPermittedAction(REPORT_EXCEPTION_ISSUE))
  //   )
  // }, [pages])

  return (
    <section className="exception-error">
      <AlertNotification icon="error" message={messageToDisplay} />
    </section>
  )
}

export default React.memo(ErrorFallback)
