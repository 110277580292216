import {
  RefObject,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { debounce } from 'lodash'
import './SVGBaseWrapper.css'
import clsx from 'clsx'
import { useDynamicActions } from './BaseSetup'

const SVGBaseWrapper = (props: any) => {
  const {
    width,
    height,
    container = {},
    dimensionDebounceValue = 100
  } = props
  const chartRef: RefObject<HTMLDivElement> = useRef(null)
  const [called, setCalled] = useState<any>(false)
  const actions: any = useDynamicActions()

  const updateDimensions = useCallback((chartWrapper: any, called: boolean) => {
    const height = chartWrapper?.offsetHeight
    const width = chartWrapper?.offsetWidth
    const svgDimensions = { width, height }
    actions?.updateSVGDimensions({
      svgDimensions,
    })
    if (!called) {
      setCalled(true)
    }
  }, [])

  useEffect(() => {
    // Initial call to set dimensions
    updateDimensions(chartRef.current, called)
  }, [])

  const debouncedUpdateDimensions = useCallback(
    debounce(
      (refElement, called) => updateDimensions(refElement, called),
      dimensionDebounceValue
    ),
    [updateDimensions, dimensionDebounceValue, called]
  )

  useEffect(() => {
    // Watch for changes in the wrapper div and update dimensions accordingly
    const resizeObserver = new ResizeObserver(() =>
      debouncedUpdateDimensions(chartRef.current, called)
    )
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current)
    }
    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      resizeObserver.disconnect()
    }
  }, [chartRef.current])

  const defaultDimensions = useMemo(() => {
    const style: any = {}
    if (width && height) {
      style.width = width
      style.height = height
    }

    return style
  }, [height, width])

  return (
    <div
      style={defaultDimensions}
      {...container?.attributes}
      className={clsx(container?.className, 'svgBaseWrapper')}
      ref={chartRef}
    >
      {called ? <>{props.children}</> : <></>}
    </div>
  )
}

export default memo(SVGBaseWrapper)
