import { anyPass, clone, is, isEmpty, isNil } from 'ramda'
import BaseAxis from './BaseAxis'
import { AxisDiM } from '../Utils/defaults'
import { axisModel, gridModel } from './model'

export default class BaseOptions extends BaseAxis {
  private optionsCache: Options | EmptyObject = {}

  private options: Options = {}

  private baseOptions: InputOptions = {
    xAxis: [],
    yAxis: [],
    grid: [gridModel()]
  }

  protected prepareAndInitalizeOptions(options: Options | EmptyObject) {
    const isEqual = this.isConfigEqual(this.optionsCache, options)

    this.optionsCache = clone(options)

    this.options = options || {}

    if (isEqual) {
      // console.log('Options are not changed. No updates will be performed.')
      return
    }

    this.handleGrid()
    this.handleAxis()
    this.initBaseConfig()

    // console.log("baseOptions :", this.baseOptions);
  }

  private initBaseConfig() {
    const { grid, xAxis, yAxis } = this.baseOptions

    this.prepareGrids(grid)
    this.prepareScales({ xAxis, yAxis })
    this.prepareAxis({ xAxis, yAxis })
  }

  private handleGrid() {
    const grid = this.options.grid

    const isEmptyGrid = anyPass([isEmpty, isNil])(grid)
    if (isEmptyGrid) {
      return
    }

    let updatedGrid = []

    if (Array.isArray(grid)) {
      updatedGrid = grid.map(gridModel)
    } else if (is(Object, grid)) {
      updatedGrid = [gridModel(grid)]
    } else {
      console.log(`Invalid grid option is provided. Please update options.`)
      return
    }

    this.baseOptions.grid = updatedGrid
  }

  private handleAxis() {
    this.setAxisModel(this.options.xAxis, AxisDiM.x)
    this.setAxisModel(this.options.yAxis, AxisDiM.y)
  }

  private setAxisModel(axes: Axis | Axis[] | undefined, diM: AxisDimention) {
    const isEmptyOption = anyPass([isEmpty, isNil])(axes)

    if (isEmptyOption) {
      console.log(`No ${diM} axis is provided. please update options.`)
      return
    }

    let updatedAxes = []

    if (Array.isArray(axes)) {
      updatedAxes = axes.map((axis) => axisModel({ ...axis, diM }))
    } else if (is(Object, axes)) {
      updatedAxes = [axisModel({ ...axes, diM })]
    } else {
      console.log(`Invalid ${diM} axis is provided. Please update options.`)
      return
    }

    const key = diM + 'Axis'

    //@ts-ignore
    this.baseOptions[key] = updatedAxes
  }

  protected resetOptions() {
    this.optionsCache = {}

    this.options = {}

    this.baseOptions = {
      xAxis: [],
      yAxis: [],
      grid: [gridModel()]
    }
  }
}
