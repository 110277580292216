import React from 'react'
import PropTypes from 'prop-types'
import * as markerjs2 from 'markerjs2'
import { dataURLtoFile } from '../../../legacy/common/helpers'
import COLORS from '../../../legacy/common/colors.json'
import './image-editor-wrapper.scss'

// marker js library requires raw svg as a template string
const ArrowRight = `<svg width="24" height="24" viewBox="0 0 24 24">
<path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
</svg>`

const ImageEditorWrapper = ({
  openImageEditor,
  imageSrc,
  cancelChanges,
  uploadFile,
  originalImageUrl,
  previousMarkerState,
  setOriginalUrl,
  setPreviousMarkerState
}) => {
  const imageEditor = React.useRef(null)

  React.useEffect(() => {
    if (!originalImageUrl) {
      setOriginalUrl(imageSrc)
    }
    openImageEditor && showMarkerArea()
  }, [openImageEditor])

  function showMarkerArea() {
    if (imageEditor.current !== null) {
      const markerArea = new markerjs2.MarkerArea(imageEditor.current)
      markerjs2.ArrowMarker.icon = ArrowRight
      markerArea.availableMarkerTypes = [
        'CalloutMarker',
        'FrameMarker',
        'EllipseFrameMarker',
        markerjs2.ArrowMarker,
        'LineMarker',
        'FreehandMarker',
        'TextMarker'
      ]
      markerArea.settings.displayMode = 'popup'
      markerArea.settings.defaultColorSet = [
        COLORS.WHITE,
        COLORS.TBA_LIGHT3_GREY,
        COLORS.TBA_DARKER_GREY,
        COLORS.BLACK,
        COLORS.BLUE_12,
        COLORS.RED_6
      ]
      markerArea.settings.defaultFontFamilies = [
        "Times, 'Times New Roman', serif",
        'Lato-Black, Lato'
      ]
      markerArea.renderAtNaturalSize = true
      markerArea.renderImageType = 'image/jpeg'
      markerArea.renderImageQuality = 0.3
      markerArea.uiStyleSettings.toolbarStyleColorsClassName = 'marker-tools-bg'
      markerArea.uiStyleSettings.toolbarButtonStyleColorsClassName =
        'marker-toolbox-button'
      markerArea.uiStyleSettings.toolbarActiveButtonStyleColorsClassName =
        'marker-tools-active'
      markerArea.uiStyleSettings.toolbarOverflowBlockStyleColorsClassName =
        'marker-tools-bg'
      markerArea.uiStyleSettings.toolboxColor = COLORS.TBA_DARKER_GREY
      markerArea.uiStyleSettings.toolboxAccentColor = COLORS.WHITE
      markerArea.uiStyleSettings.toolboxStyleColorsClassName = 'marker-tools-bg'
      markerArea.uiStyleSettings.toolboxButtonRowStyleColorsClassName =
        'marker-tools-bg'
      markerArea.uiStyleSettings.toolboxPanelRowStyleColorsClassName =
        'marker-tools-panel'
      markerArea.uiStyleSettings.toolboxButtonStyleColorsClassName =
        'marker-toolbox-button'
      markerArea.uiStyleSettings.toolboxActiveButtonStyleColorsClassName =
        'marker-tools-active'
      // attach an event handler to assign annotated image back to our image element
      markerArea.addRenderEventListener((dataUrl, state) => {
        if (imageEditor.current) {
          // used setTimeout to avoid svg error in markerjs
          setTimeout(() => {
            uploadFile(dataURLtoFile(dataUrl))
            // uploadFile(dataURLtoBlob(dataUrl))
          }, 0)
          setPreviousMarkerState(state)
        }
      })
      markerArea.addCloseEventListener(() => {
        // used setTimeout to avoid svg error in markerjs
        setTimeout(() => {
          cancelChanges()
        }, 0)
      })
      markerArea.show()
      if (previousMarkerState) {
        markerArea.restoreState(previousMarkerState)
      }
    }
  }
  return (
    <img
      src={originalImageUrl || imageSrc}
      ref={imageEditor}
      className="marker-image"
    />
  )
}

export default ImageEditorWrapper

ImageEditorWrapper.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  openImageEditor: PropTypes.bool.isRequired,
  originalImageUrl: PropTypes.string,
  previousMarkerState: PropTypes.object,
  cancelChanges: PropTypes.func.isRequired,
  setOriginalUrl: PropTypes.func,
  setPreviousMarkerState: PropTypes.func,
  uploadFile: PropTypes.func.isRequired
}

ImageEditorWrapper.defaultProps = {
  imageSrc: '',
  openImageEditor: false,
  originalImageUrl: '',
  previousMarkerState: null
}
