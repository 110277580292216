import { useEffect, useState } from 'react'
import DataComparisonBarWidgetChart from 'src/pages/widgets/widget-library/dataComparisonBarChartWidget/DataComparisonBarWidgetChart'
import { useWidgetChartDataHook } from 'src/hooks/widgetChartDataHook'
import { aggreagatorSelection, getLatestDisplayName } from 'src/common/chartHelperFunctions'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  WidgetChartTitleComponent,
  WidgetChartFooterComponent,
  NoWidgetDataAvailable
} from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import moment from 'moment/moment'
import { getStartAndEndDates } from 'src/pages/dashboards/helper'
import { isValidUOMForGetChartData } from "../../helper";

const timeComparisionAggreagation = {
  'current-day': '15-minutes',
  'current-week': 'daily',
  'current-month': 'monthly',
  'current-quarter': 'quarterly',
  'current-year': 'yearly'
}

const barMaximumTimeAndAggr = (selectedTime) => {
  switch (selectedTime) {
    case 'peak-last-30-days':
      return { timePeriod: 'past-30-days', aggregation: 'sum' }
    case 'peak-last-12-months':
      return { timePeriod: 'past-30-days', aggregation: 'sum' }
    case '':
      return { timePeriod: 'past-30-days', aggregation: 'sum' }
    case 'peak-past-30-days':
      return { timePeriod: 'past-30-days', aggregation: 'sum' }
    case 'peak-past-30-days':
      return { timePeriod: 'past-30-days', aggregation: 'sum' }
    default:
      return { timePeriod: null, aggregation: null }
  }
}

export const DataComparisonBarWidgetPreview = ({ widgetDetails }) => {
  const selectedPeriod = widgetDetails?.selectedTimePeriod
  const {
    timePeriod: barMaxSelectedTime,
    aggregation: barMaxSelectedAggregationValue
  } = barMaximumTimeAndAggr(widgetDetails?.barMaxCalculate)

  const selectedAggrValue = widgetDetails?.selectedAggrValue

  const dataAggregation = timeComparisionAggreagation[selectedPeriod]

  const [barChartData, setBarChartData] = useState({})

  const [isDataAvailable, setIsDataAvailable] = useState(false)

  const [barMaxCalculatedValue, setBarMaxCalculatedValue] = useState(0)

  const [leftBenchMarkTarget, setLeftBenchMarkTarget] = useState(0)

  const [rightBenchMarkTarget, setRightBenchMarkTarget] = useState(0)

  const { widgetChartData, getWidgetChartData, loadingWidgetChartData } =
    useWidgetChartDataHook(selectedPeriod, ':none', dataAggregation, widgetDetails?.timeZone)


  const {
    widgetChartData: maxBarWidgetData,
    getWidgetChartData: getMaxbarWidgetData,
    loadingWidgetChartData: maxBarWidgetDataLoading
  } = useWidgetChartDataHook(barMaxSelectedTime, ':none', 'daily', widgetDetails?.timeZone)

  // Get avg past 30 days of data for Bench Marker Target
  const {
    widgetChartData: markerWidgetData,
    getWidgetChartData: getMarkerWidgetData,
    loadingWidgetChartData: markerWidgetDataLoading
  } = useWidgetChartDataHook('past-30-days', ':none', 'daily', widgetDetails?.timeZone)

  const getAverageValue = (data) => {
    try {
      const sumValue = data?.reduce((sum, { value = 0 }) => {
        const timeValue = value === null ? 0 : value
        return sum + Number(parseFloat(timeValue).toFixed(2))
      }, 0)
      return Number(sumValue / data?.length || 0).toFixed(2)
    } catch (error) {
      return 0
    }
  }

  const getPlotLinesRightBarData = (values) => {
    const targetMarker = widgetDetails?.rightTargetMarker
    if (targetMarker?.includes('constant')) {
      return {
        name: widgetDetails?.rightMarkerLabel || '',
        plotLineValue: Number(widgetDetails?.rightMarkerConstant || 0),
        plotLineColor: widgetDetails?.rightBenchmarkColor,
        measurement: widgetDetails?.rightSymbol
      }
    } else if (targetMarker?.includes('calculated')) {
      return {
        name: widgetDetails?.rightMarkerLabel || '',
        plotLineValue: getAverageValue(values) || 0,
        plotLineColor: widgetDetails?.rightBenchmarkColor,
        measurement: widgetDetails?.rightSymbol
      }
    } else {
      return null
    }
  }

  const getPlotLinesLeftBarData = (values) => {
    const targetMarker = widgetDetails?.leftTargetMarker
    if (targetMarker?.includes('constant')) {
      return {
        name: widgetDetails?.leftMarkerLabel || '',
        plotLineValue: Number(widgetDetails?.leftMarkerConstant || 0),
        plotLineColor: widgetDetails?.leftBenchmarkColor,
        measurement: widgetDetails?.leftSymbol
      }
    } else if (targetMarker?.includes('calculated')) {
      return {
        name: widgetDetails?.leftMarkerLabel || '',
        plotLineValue: getAverageValue(values),
        plotLineColor: widgetDetails?.leftBenchmarkColor,
        measurement: widgetDetails?.leftSymbol
      }
    } else {
      return null
    }
  }

  const getXLabelName = (pastPeriod = false) => {
    const selectedPeriod = widgetDetails?.selectedTimePeriod
    switch (selectedPeriod) {
      case 'current-day': {
        const { startDate, endDate } = getStartAndEndDates(
          selectedPeriod,
          pastPeriod,
          'DD/MM',
          widgetDetails?.timeZone
        )
        return startDate
      }
      case 'current-week': {
        const { startDate, endDate } = getStartAndEndDates(
          selectedPeriod,
          pastPeriod,
          'DD/MM',
          widgetDetails?.timeZone
        )
        return `${startDate}-${endDate}`
      }
      case 'current-month': {
        const { startDate } = getStartAndEndDates(
          selectedPeriod,
          pastPeriod,
          'MMM',
          widgetDetails?.timeZone
        )
        return startDate
      }
      case 'current-quarter': {
        const { startDate } = getStartAndEndDates(selectedPeriod, pastPeriod, null, widgetDetails?.timeZone)
        const currentQuarter = `Q${moment(startDate)?.quarter()}-${moment(
          startDate
        ).format('YYYY')}`
        return currentQuarter
      }
      case 'current-year': {
        const { startDate } = getStartAndEndDates(
          selectedPeriod,
          pastPeriod,
          'YYYY',
          widgetDetails?.timeZone
        )
        return startDate
      }
      default:
        return moment().format('DD/MM')
    }
  }

  const aggrBarDataValues = (data) => {
    const finalValue = Number(
      aggreagatorSelection(selectedAggrValue, data, 0, data?.length - 1)
    ).toFixed(2)
    return finalValue
  }

  const formatTimebarData = (leftBarData, rightBarData) => {
    return [
      {
        displayName: selectedAggrValue === 'latest' ? getLatestDisplayName(leftBarData, selectedAggrValue) : getXLabelName(),
        name: `${getXLabelName()}-L`,
        value: aggrBarDataValues(leftBarData),
        color: widgetDetails?.leftBarColor,
        measurement: widgetDetails?.leftSymbol
      },
      {
        displayName: selectedAggrValue === 'latest' ? getLatestDisplayName(rightBarData, selectedAggrValue) : getXLabelName(),
        name: `${getXLabelName()}-R`,
        value: aggrBarDataValues(rightBarData),
        color: widgetDetails?.rightBarColor,
        measurement: widgetDetails?.rightSymbol
      }
    ]
  }



  const formatBarChartData = (leftBarData, rightBarData) => {
    const barData = {
      barData: formatTimebarData(leftBarData, rightBarData),
      barPlotLinesData: [
        getPlotLinesLeftBarData(leftBarData),
        getPlotLinesRightBarData(rightBarData)
      ]
    }
    setBarChartData(barData)
  }
  

  useEffect(() => {
    try {
      // if either of data fetch is true, just returns -> no plotting until we get all required data
      if (
        loadingWidgetChartData ||
        maxBarWidgetDataLoading ||
        markerWidgetDataLoading
      ) {
        return
      }

      const isDataAvailable = widgetChartData?.data?.some(x => x?.isDataAvailable)
      if(isDataAvailable){
        setIsDataAvailable(isDataAvailable)
      } else {
        setIsDataAvailable(isDataAvailable)
        return
      }

      if (
        widgetDetails?.leftTargetMarker?.includes('calculated') &&
        markerWidgetData?.data?.length > 0
      ) {
        const leftMarkerTarget = aggrBarDataValues(
          markerWidgetData?.data?.[0]?.values || []
        )
        setLeftBenchMarkTarget(leftMarkerTarget)
      }

      if (
        widgetDetails?.rightTargetMarker?.includes('calculated') &&
        markerWidgetData?.data?.length > 0
      ) {
        const rightMarkerTarget = aggrBarDataValues(
          markerWidgetData?.data?.[0]?.values || []
        )
        setLeftBenchMarkTarget(rightMarkerTarget)
      }

      if (
        widgetDetails?.barMax?.includes('calculated') &&
        maxBarWidgetData?.data?.length > 0
      ) {
        const maxBarValue = aggrBarDataValues(
          maxBarWidgetData?.data?.[0]?.values || []
        )
        setBarMaxCalculatedValue(maxBarValue)
      }

      if (widgetChartData && widgetChartData?.data?.length > 0) {
        const chartLeftBarData =
          widgetChartData?.data?.[0] || {}
        const chartRightBarData =
          widgetChartData?.data?.[0] || {}
        formatBarChartData(
          chartLeftBarData?.values?.[0],
          chartRightBarData?.values?.[0]
        )
      }
    } catch (error) {}
  }, [
    widgetChartData,
    maxBarWidgetData,
    markerWidgetData,
    loadingWidgetChartData,
    maxBarWidgetDataLoading,
    markerWidgetDataLoading
  ])

  const initializeData = async () => {
    const widgetInfo = {
      comparePointTo: ':none',
      chartType: 'Line',
      interval: 1440,
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      equipmentType2: widgetDetails?.equipmentType2,
      equipmentId2: widgetDetails?.equipmentId2,
      leftProperty: widgetDetails?.leftProperty,
      rightProperty: widgetDetails?.rightProperty,
      selectedAggrValue: selectedAggrValue,
      buildingId: widgetDetails?.buildingId,
      propertiesList: widgetDetails?.property
    }
    if (isValidUOMForGetChartData(widgetDetails?.leftUnitsOfMeasure) && isValidUOMForGetChartData(widgetDetails?.rightUnitsOfMeasure)) {
      widgetInfo["uomDetails"] = { [widgetDetails?.leftProperty]: widgetDetails?.leftUnitsOfMeasure, [widgetDetails?.rightProperty]: widgetDetails?.rightUnitsOfMeasure }
    }
    getWidgetChartData(widgetInfo)
  }

  const initializeDataForBarMax = async () => {
    const widgetInfo = {
      comparePointTo: ':none',
      chartType: 'Line',
      interval: 1440,
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: barMaxSelectedAggregationValue,
      buildingId: widgetDetails?.buildingId,
      propertiesList: widgetDetails?.property
    }
    if (isValidUOMForGetChartData(widgetDetails?.unitsOfMeasure)) {
      widgetInfo["uomDetails"] = { [widgetDetails?.property]: widgetDetails?.unitsOfMeasure }
    }
    getMaxbarWidgetData(widgetInfo)
  }

  const initializeDataForMarker = async () => {
    const widgetInfo = {
      comparePointTo: ':none',
      chartType: 'Line',
      interval: 1440,
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: 'sum',
      buildingId: widgetDetails?.buildingId,
      propertiesList: widgetDetails?.property
    }
    if (isValidUOMForGetChartData(widgetDetails?.unitsOfMeasure)) {
      widgetInfo["uomDetails"] = { [widgetDetails?.property]: widgetDetails?.unitsOfMeasure }
    }
    getMarkerWidgetData(widgetInfo)
  }

  useEffect(() => {
    if (widgetDetails?.property) {
      initializeData()

      // Max bar calculations proeprty data
      if (widgetDetails?.barMax?.includes('calculated')) {
        initializeDataForBarMax()
      } else {
        setBarMaxCalculatedValue(widgetDetails?.barMaxConstantValue)
      } 

      // Target marker property data
      if (
        widgetDetails?.leftTargetMarker?.includes('calculated') ||
        widgetDetails?.rightTargetMarker?.includes('calculated')
      ) {
        initializeDataForMarker()
      }

      if( widgetDetails?.leftTargetMarker?.includes('constant') ) {
        setLeftBenchMarkTarget(widgetDetails?.leftMarkerConstant || 0)
      }

      if( widgetDetails?.rightTargetMarker?.includes('constant') ) {
        setRightBenchMarkTarget(widgetDetails?.rightMarkerConstant || 0)
      }
    }
  }, [widgetDetails])

  return loadingWidgetChartData || maxBarWidgetDataLoading || markerWidgetDataLoading ? (
    <Spinner />
  ) : barChartData && Object.keys(barChartData)?.length > 0 && isDataAvailable ? (
    <>
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName={widgetDetails?.equipmentName}
        equipmentType={widgetDetails?.equipmentType}
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDiscription}
        property={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      <DataComparisonBarWidgetChart data={barChartData} />
      <WidgetChartFooterComponent
        aggregatorName={selectedAggrValue}
        selectedPeriod={selectedPeriod}
        comparisonBar={true}
        aggragatorInterval={dataAggregation}
      />
    </>
  ) : (
    <NoWidgetDataAvailable
      buildingName={widgetDetails?.buildingName}
      equipmentName={widgetDetails?.equipmentName}
      equipmentType={widgetDetails?.equipmentType}
      comparisonBar={true}
      aggregatorName={selectedAggrValue}
      selectedPeriod={selectedPeriod}
      dataAggregation={dataAggregation}
      widgetType="icon-linewidget"
      equipmentDetails={widgetDetails?.equipmentList}
      widgetDescription={widgetDetails?.widgetDiscription}
      timeZone={widgetDetails?.timeZone}
      accountName={widgetDetails?.accountName}
    />
  )
}
