import React from 'react'
import { useTranslation } from 'react-i18next'
import { TranslateComponent } from 'src/common/translations'

import './truck-roll-kpi-table.scss'

export function TruckRollKpiTableHeader() {
  const { t } = useTranslation()

  return (
    <div className="trkt-titles-row" style={{width: "100%"}}>
      <span><TranslateComponent>Month</TranslateComponent></span>
      <span><TranslateComponent>Actual</TranslateComponent></span>
      <span><TranslateComponent>Goal</TranslateComponent></span>
    </div>
  )
}

export default TruckRollKpiTableHeader
