import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Select from 'src/components/legacy/components/select/select'
import CheckboxSelect from 'src/components/legacy/components/custom-dropdown-tree-select/checkbox-select'
import Search from 'src/components/legacy/components/search/search'
import Label from 'src/components/legacy/components/label/label'
import { withTranslation } from 'react-i18next'
import './filter.scss'
import i18next from 'i18next'

export const FILTER_TYPE_CHECKBOX = 'checkbox'
export const FILTER_TYPE_SELECT = 'select'

class Filter extends PureComponent {
  static propTypes = {
    selectedFilter: PropTypes.string,
    filterOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ),
    searchLabel: PropTypes.string,
    isShowSearchLabel: PropTypes.bool,
    searchText: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onChangeFilter: PropTypes.func,
    onChangeSearch: PropTypes.func,
    filterType: PropTypes.string,
    testName: PropTypes.string,
    t: PropTypes.func.isRequired
  }

  static defaultProps = {
    selectedFilter: null,
    filterOptions: [],
    searchLabel: '',
    isShowSearchLabel: true,
    searchText: '',
    searchPlaceholder: `${i18next.t('common:Find')}...`,
    description: null,
    disabled: false,
    className: '',
    onChangeFilter: () => {},
    onChangeSearch: () => {},
    filterType: FILTER_TYPE_SELECT
  }

  state = {
    selectedFilter: this.props.selectedFilter,
    searchText: this.props.searchText
  }

  componentDidUpdate(prevProps) {
    const { selectedFilter, searchText } = this.props

    if (prevProps.selectedFilter !== selectedFilter) {
      this.onChangeFilter(selectedFilter)
    }

    if (
      prevProps.searchText !== searchText &&
      this.state.searchText !== searchText
    ) {
      this.onChangeSearch(searchText)
    }
  }

  onChangeFilter = (selectedFilter) => {
    this.setState({ selectedFilter })

    this.props.onChangeFilter(selectedFilter)
  }

  onChangeSearch = (searchText) => {
    this.setState({ searchText })

    this.props.onChangeSearch(searchText)
  }

  renderFilter = () => {
    const { filterType, filterOptions, disabled, t } = this.props
    const { selectedFilter } = this.state

    if (FILTER_TYPE_CHECKBOX === filterType) {
      return (
        <CheckboxSelect
          className="checkbox-select"
          data={filterOptions}
          onChange={(values = []) => {
            this.onChangeFilter(values)
          }}
          selectAllEntity={t('opportunities:Statuses')}
          selectAll={true}
        />
      )
    } else if (FILTER_TYPE_SELECT === filterType) {
      const filterItem =
        filterOptions.find((i) => i.key === selectedFilter) || filterOptions[0]

      return (
        <Select
          selectedItem={filterItem.value}
          onChange={this.onChangeFilter}
          options={filterOptions}
          isDisabled={disabled}
        />
      )
    } else {
      return null
    }
  }

  render() {
    const { searchText } = this.state
    const {
      filterOptions,
      searchPlaceholder,
      searchLabel,
      isShowSearchLabel,
      filterLabel,
      description,
      disabled,
      className,
      t,
      testName
    } = this.props
    const classes = {
      'filter-component--disabled': disabled
    }

    return (
      <div className={classNames('filter-component', className, classes)}>
        {isShowSearchLabel && (
          <Label
            text={searchLabel ? searchLabel : `${t('common:Filter')}:`}
            disabled={disabled}
          />
        )}
        {(filterOptions.length || null) && this.renderFilter()}
        {filterLabel && <Label text={filterLabel} disabled={disabled} />}
        <Search
          value={searchText}
          placeholder={searchPlaceholder}
          onChange={this.onChangeSearch}
          disabled={disabled}
          testName={testName}
        />
        {description && (
          <span className="filter-component__description">{description}</span>
        )}
      </div>
    )
  }
}

export default withTranslation()(Filter)
