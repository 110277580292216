import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'

export interface RectClipCompProps {
  uuid: number | string
  x: number
  y: number
  width?: number | string
  height?: number | string
  clipPathProps?: any
  parentRef?: any
  [key: string]: any
}

export default function RectClip(props: RectClipCompProps) {
  const {
    uuid,
    x,
    y,
    width = '100%',
    height = '100%',
    clipPathProps,
    // Provide parent Ref to scale clip while resizing the parent
    parentRef = {},
    ...rectProps
  } = props

  const [resize, setResize] = useState(true)

  useEffect(() => {
    if (!parentRef?.current) return () => null

    const listener = debounce(() => {
      setResize((s) => !s)
    }, 150)

    const resizeObserver = new ResizeObserver(listener)

    if (parentRef?.current) {
      resizeObserver.observe(parentRef.current)
    }

    return () => parentRef?.current && resizeObserver.disconnect()
  }, [parentRef?.current])

  return (
    <defs>
      <clipPath {...clipPathProps} id={`${uuid}`}>
        <rect
          x={resize ? x : '' + x}
          y={y}
          width={width}
          height={height}
          {...rectProps}
        />
      </clipPath>
    </defs>
  )
}

export function clipUUID(prefix: string) {
  return prefix + '-' + Math.floor(Math.random() * 10000)
}
