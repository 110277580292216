import PropTypes from "prop-types"
import { Text, View } from "@react-pdf/renderer"
import _get from "lodash/get"
import { FIELD_TYPES } from "../../legacy/common/pdf"

import { tableStyles } from "./pdf-table.styles"
import i18next from "i18next"

const PDFTable = ({ headings, columns, columnRender, sortedBy, filteredBy, showNotes, showHeader, rowDataColor = null }) => <>
	{showHeader && (sortedBy || filteredBy) && <View style={tableStyles.header}>
		{sortedBy || filteredBy ? <View style={tableStyles.filteringWrapper}>
			{sortedBy && <View style={tableStyles.filteringBlock}>
				<Text style={tableStyles.filteringTitle}>{`${i18next.t("common:SortedBy")}:`}</Text>
				<Text style={tableStyles.filteringValue}>{sortedBy}</Text>
			</View>}
			{filteredBy && <View style={tableStyles.filteringBlock}>
				<Text style={tableStyles.filteringTitle}>{`${i18next.t("common:FilteredBy")}:`}</Text>
				<Text style={tableStyles.filteringValue}>{filteredBy}</Text>
			</View>}
		</View> : <Text />}
	</View>}

	<View style={tableStyles.table}>
		<View style={tableStyles.headingRow} fixed={true}>
			{headings?.map((h, index) => (<View key={`${index}_${h.heading}`} style={{ ...tableStyles.headingColumn, width: h.width ? h.width : "auto" }}>
				{!h.hideHeading && <Text>{`${i18next.t(h.heading)}`}</Text>}
			</View>))}
		</View>
		{columns?.map((column, index) => <View key={index + column.name} style={{ ...tableStyles.row, ...(index % 2 !== 0 && tableStyles.rowEven) }} wrap={false}>
			<View style={{ ...tableStyles.rowData, color: rowDataColor ? rowDataColor : tableStyles.rowData.color }}>
				{headings?.map((h, index) => <View key={index} style={{ ...tableStyles.column, width: h.width ? h.width : "auto" }}>
					{h.fieldType === FIELD_TYPES.COMPONENT ? ((h.component && h.component(h, column)) || (columnRender && columnRender(h, column))) : <Text>{_get(column, h.field, "")}</Text>}
				</View>)}
			</View>
			{showNotes && <View style={tableStyles.noteSection}>
				<Text>{`${i18next.t("common:Notes")}:`}</Text>
			</View>}
		</View>)}
	</View>
</>

PDFTable.propTypes = {
	headings: PropTypes.arrayOf(PropTypes.shape({

		/* TODO need to check all passed props */
		/** table header title */
		heading: PropTypes.node,

		// Columns configuration props

		/** the prop name that used to get value from the columns data */
		field: PropTypes.string,

		/** used to render different types of column */
		fieldType: PropTypes.oneOf(Object.values(FIELD_TYPES)),

		/**
		 * Called on cell render of current column. Have higher priority than propTypes.columnRender
		 * @params {Object} heading The object contains the header configuration of current column. See propTypes.heading
		 * @params {Object} columns The object contains the column data
		 * @returns {PropTypes.node} The content that rendered in the column
		 */
		component: PropTypes.func,
	})).isRequired,
	columns: PropTypes.array,
	columnRender: PropTypes.func,
	sortedBy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	filteredBy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	showNotes: PropTypes.bool,
	showHeader: PropTypes.bool,
}

PDFTable.defaultProps = {
	columns: [],
	sortedBy: false,
	filteredBy: false,
	showNotes: false,
	showHeader: true,
	withTranslation: false,
}

export default PDFTable

