import WidgetsContainer from './WidgetsContainer'
import { WidgetsContextProvider } from './WidgetsContextProvider'

export const Widgets = () => {
  return (
    <WidgetsContextProvider>
      <WidgetsContainer />
    </WidgetsContextProvider>
  )
}
