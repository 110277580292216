import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Layout } from '../Layout'
import { pageRoutes } from './pages.js'
import { PageNotFound } from './pagNotFound'
import ErrorFallback from '../errorBoundary'
import DashboardWrapper from './wrapper'
import { RouteWrapper } from 'src/denali-components/Page/RouteWrapper'

const router = (signOut: any, termsUrl: any) =>
  createBrowserRouter([
    {
      path: '/',
      element: <Layout signOut={signOut} termsUrl={termsUrl}/>,
      errorElement: <ErrorFallback signOut={signOut} />,
      children: [
        {
          path: '/',
          element: <DashboardWrapper />,
          errorElement: <ErrorFallback signOut={signOut} />
        },
        {
          path: '/TannerTest',
          element: <ErrorFallback signOut={signOut} />,
          errorElement: <ErrorFallback signOut={signOut} />
        },
        {
          path: '*',
          element: <PageNotFound />,
          errorElement: <ErrorFallback signOut={signOut} />
        },
        ...pageRoutes.map((e) => ({
          path: e.path,
          element: <RouteWrapper key={e.path}>{e.element && React.cloneElement(e.element)}</RouteWrapper>,
          errorElement: <ErrorFallback signOut={signOut} />,
          handle: e.handle,
        }))
      ]
    }
  ])

const PageComponent = ({ signOut, termsUrl }) => {
  return <RouterProvider fallbackElement={<></>} router={router(signOut, termsUrl)} />
}

export const Page = React.memo(PageComponent)
