import clsx from 'clsx'
import BarRect from './BarRect'
import { BarCompProps, BarItem, Bars } from '../../Types/component/bar'

const defaultColor = 'steelblue'

const defaultData: Bars[] = []

export default function BarChart(props: BarCompProps) {
    const {
        className,
        barData = defaultData,
        measurement = null,
        isHorizontal = false,
        isInsideBar = false,
        xScale,
        yScale,
        enableToolTip = true,
        setTooltip
    } = props

    // --------------------- render phase ---------------------

    return (
        <>
            {(barData || [])?.map((barItem: BarItem, index) => {
                let xValue = 0
                let yValue = 0
                let heightValue = 0
                let widthValue = 0
                if (isHorizontal) {
                    xValue = barItem?.value > 0 ? xScale?.fn(0) : xScale?.fn(0) - (xScale?.fn(0) - xScale?.fn(barItem?.value))
                    yValue = yScale?.fn(barItem?.name) || 0
                    heightValue = yScale?.fn?.bandwidth()
                    widthValue = barItem?.value > 0 ? xScale?.fn(barItem?.value) - xScale?.fn(0) : xScale?.fn(0) - xScale?.fn(barItem?.value)
                } else {
                    if (isInsideBar) {
                        const xValueOffset = (xScale?.fn.bandwidth() - (xScale?.fn.bandwidth() / 2)) / 2
                        xValue = xScale?.fn(barItem?.name) + xValueOffset || 0
                        yValue = barItem?.value > 0 ? yScale?.fn(0) - (yScale?.fn(0) - yScale?.fn(barItem?.value)) : yScale?.fn(0)
                        heightValue = barItem?.value > 0 ? yScale?.fn(0) - yScale?.fn(barItem?.value) : yScale?.fn(barItem?.value) - yScale?.fn(0)
                        widthValue = xScale?.fn.bandwidth() / 2
                    } else {
                        xValue = xScale?.fn(barItem?.name) || 0
                        yValue = barItem?.value > 0
                            ? yScale?.fn(0) -
                            (yScale?.fn(0) - yScale?.fn(barItem?.value))
                            : yScale?.fn(0)
                        heightValue = barItem?.value > 0
                            ? yScale?.fn(0) - yScale?.fn(barItem?.value)
                            : yScale?.fn(barItem?.value) - yScale?.fn(0)
                        widthValue = xScale?.fn.bandwidth()
                    }

                }
                return (
                    <BarRect
                        key={`rect-${index}`}
                        x={xValue}
                        y={yValue}
                        width={widthValue}
                        height={heightValue}
                        color={barItem?.color ?? defaultColor}
                        onMouseEnter={(event) => {
                            if (!enableToolTip) return
                            setTooltip({
                                x: event?.clientX,
                                y: event?.clientY,
                                index: index,
                                item: {
                                    ...barItem, ...{
                                        name: barItem?.displayName ?? barItem?.name,
                                        measurement: barItem?.measurement ? barItem?.measurement : measurement ? measurement : ""
                                    }
                                }
                            })
                        }}
                        onMouseLeave={() => {
                            if (!enableToolTip) return
                            setTooltip(null)
                        }}
                    />
                )
            })}
        </>
    )
}
