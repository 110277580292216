/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo } from 'react'
import { useChartContext } from '../../../../../chart-library/CommonComponents/BaseWrapper'
import { AxisDiM } from '../../../../../chart-library/Utils/defaults'
import { getMinValue } from '../../../../../chart-library/CommonComponents/Bar/helper';
import BarChart from '../../../../../chart-library/CommonComponents/Bar';
import Grid from 'src/chart-library/CommonComponents/Grids/Grid';


export default function Series(props: any) {
  const chart = useChartContext()

  const grid = chart?.grid
  const axis = chart?.axis
  const scale = chart?.scale
  const series = props?.series?.barData ?? []
  const measurement = props?.series?.measurement ?? null

  // API flow - 2
  // Once Data is recevied from API. set x axis min & max value to render ticks based on API reponse
  // or you can use min & max value config in useOptions to set x axis values
  useEffect(() => {
    const minValue = getMinValue(series)
    if (series?.length) {
      scale.setDomain(AxisDiM?.y, 0, [minValue > 0 ? 0 : minValue, Math.max(...series?.map(({ value }) => value))], true)
    }
  }, [series])

  const canvas = useMemo(() => grid?.getGrid(0), [grid?.lastUpdatedAt])!

  const xScale = useMemo(
    () => {
      const x = scale?.getScale(AxisDiM?.x, 0)
      x?.fn?.padding(0.5)
      return x
    },
    [scale?.lastUpdatedAt]
  )!

  const yScale = useMemo(
    () => scale?.getScale(AxisDiM?.y, 0)
    ,
    [scale?.lastUpdatedAt]
  )!

  if (!grid?.lastUpdatedAt || !scale?.lastUpdatedAt || !axis?.lastUpdatedAt) {
    return null
  }

  return (
    <>
     {measurement && <text x1={"100%"} y={"98%"} x={"25"}>{`(${measurement})`}</text>}     
      <g transform={`translate(${canvas?.x} ${canvas?.y})`}>
        <Grid
          scale={yScale?.fn}
          width={canvas?.width}
          height={canvas?.height}
          includeAxis={true}
          opacity={0.3}
          type={'y'}
          tickCount={3}
        />
        <BarChart measurement={measurement} barData={series} xScale={xScale} yScale={yScale} enableToolTip={true} setTooltip={props?.setTooltip} />
      </g>
    </>
  )
}

