import { memo } from 'react'
import PropTypes from 'prop-types'
import { FileWrapper } from './styles'
import { useAttachment } from 'src/hooks/attachment'

const File = (props) => {
  const { description, label, fileFullName, buildingId, name } = props
  const { downloadFileFunc } = useAttachment()

  return (
    <FileWrapper>
      <div className="file-view" data-testid="file-view">
        <label>{label}</label>
        <button
          onClick={() => downloadFileFunc(name, buildingId)}
        >
          {fileFullName ? fileFullName : ''}
        </button>
        <span> {description}</span>
      </div>
    </FileWrapper>
  )
}

File.propTypes = {
  fileId: PropTypes.number.isRequired,
  file: PropTypes.object.isRequired,
  fileFullName: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  buildingId: PropTypes.string
}
File.defaultProps = {
  label: ''
}

export default memo(File)
