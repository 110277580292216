import React from 'react'
import Label from '../label/label'
import classNames from 'classnames'
import './index.scss'
import Select from '../select/select'
import PropTypes from 'prop-types'
import i18next from 'i18next'

const ModalSelectInput = ({
  placeholder,
  loading,
  hasError,
  labelText,
  isRequired,
  isDisabled,
  inputClass,
  onChange,
  selectedItem,
  options,
  icon
}) => (
  <div className={`modal-select-wrapper ${isDisabled ? 'disabled' : ''}`}>
    <Label
      text={
        <span className={classNames({ 'has-error': hasError })}>
          {labelText}
          {Boolean(isRequired) && '*'}
        </span>
      }
    />
    <div>
      <Select
        className={classNames(`modal-select-input ${inputClass}`, {
          'has-error': hasError
        })}
        placeholder={placeholder}
        selectedItem={selectedItem}
        onChange={onChange}
        options={options}
        isDisabled={isDisabled}
        icon={icon}
        loading={loading}
      />
      {hasError && <span className="icon icon-error" />}
    </div>
    {hasError && (
      <span className="mini-error-text">
        {`${i18next.t('errors:field-is-required', { label: labelText })}.`}
      </span>
    )}
  </div>
)

ModalSelectInput.propTypes = {
  hasError: PropTypes.bool,
  labelText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  inputClass: PropTypes.string,
  onChange: PropTypes.func,
  selectedItem: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

ModalSelectInput.defaultProps = {
  hasError: false,
  labelText: '',
  isRequired: true,
  isDisabled: false,
  inputClass: ''
}

export default ModalSelectInput
