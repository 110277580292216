export const LIST_CONTRACTS_BY_ACCOUNT = `
  query LIST_CONTRACTS_BY_ACCOUNT($id: ID!,$nextToken: String) {
    listContractByAccount(accountId: $id,nextToken: $nextToken, limit: 1000,filter: {status: {ne: Deleted}}) {
        items {
          accountId
          accountMgr
          buildingCount
          deviceCount
          id
          notes
          receipients
          serviceNumber
          status
          stream
          termsAccepted
          billingSalesOfficeCode
          coachingServicesValue
          activateForLaterDate
          analyticsStartDate
          primaryBuilding
          csTermsAccepted
          totalBaselineReportCount
          additionalContractNumbers
          declinedReason
          userId
        }
        nextToken
      }
  }
`

export const LIST_ATTACHMENTS_BY_CONTRACT = `
  query listAttachmentsByContract($id: ID!) {
    listAttachmentsByContract(contractId: $id) {
      items {
      name
      id
      sizeInBytes
      description
      title
      }
      }
  }
`

export const LIST_OFFERINGS_BY_CONTRACT = `
  query LIST_OFFERINGS_BY_CONTRACT($id: ID!) {
    listOfferingsByContract(contractId: $id, limit: 1000,filter: {status: {ne: Deleted}}) {
      items {
        contractId
        accountId
        buildingId
        code
        createdAt
        description
        endDate
        gracePeriod
        name
        subscriptionCode
        id
        startDate
        status
        typeId
        type
        updatedAt
      }
      nextToken
      }
  }
`

export const LIST_OFFERINGS_BY_ACCOUNT = `
query LIST_OFFERINGS_BY_ACCOUNT($id: ID!,$nextToken: String) {
  listOfferingsByAccount(nextToken: $nextToken,accountId: $id, limit: 1000,filter: {status: {ne: Deleted}}) {
    items {
      contractId
      accountId
      buildingId
      code
      createdAt
      description
      endDate
      gracePeriod
      status
      name
      subscriptionCode
      id
      startDate
      typeId
      type
    }
    nextToken
    }
}`

export const LIST_OFFERINGS_BY_BUILDING = `
query LIST_OFFERINGS_BY_BUILDING($id: ID!) {
listOfferingsByBuilding(buildingId: $id, limit: 1000) {
  items {
    code
    id
    name
    isActive
  }
}
}
`

export const GET_BUILDING_BY_ID_func = (ids) => `
query getBuildingByIds{
  ${ids.map(
    (id, key) => `b${key}:getBuilding(id: "${id}") {
    name
    accountName
    id
  }`
  )}
}`

export const GET_BUILDINGDETAILS_BY_ID_func = (ids) => `
query getBuildingByIds{
  ${ids.map(
    (id, key) => `b${key}:getBuilding(id: "${id}") {
    address
    crmCustId
    name
    id
  }`
  )}
}`

export const GET_OFFERINGS_BY_CONTRACT_func = (ids) => `
query getOfferingsByContractId{
  ${ids.map(
    (
      id,
      key
    ) => `contract${key}:listOfferingsByContract(contractId: "${id}", limit: 1000) {
    items {
      contractId
      accountId
      buildingId
      code
      createdAt
      description
      endDate
      gracePeriod
      name
      subscriptionCode
      id
      startDate
      typeId
      type
      updatedAt
    }
  }`
  )}
}`

export const GET_CONTRACT_BY_ID = `query GET_CONTRACT_BY_ID($id:ID!) {
  getContract(id:$id) {
      accountId
      accountMgr
      buildingCount
      deviceCount
      id
      notes
      receipients
      serviceNumber
      status
      stream
      termsAccepted
      billingSalesOfficeCode
      coachingServicesValue
      activateForLaterDate
      analyticsStartDate
      primaryBuilding
      csTermsAccepted
      totalBaselineReportCount
      additionalContractNumbers
    }
}`

export const DELETE_OFFERING = /* GraphQL */ `
  mutation deleteOffering($input: DeleteOfferingInput!) {
    deleteOffering(input: $input) {
      id
    }
  }
`
export const DELETE_CONTRACT = /* GraphQL */ `
  mutation deleteContract($input: DeleteContractInput!) {
    deleteContract(input: $input) {
      id
    }
  }
`

export const UPDATE_OFFERING = `
mutation UPDATE_OFFERING(
  $input: UpdateOfferingInput!
) {
updateOffering(input: $input) {
  id
}
}
`

export const GET_PRE_SIGNED_URL = /* GraphQL */ `
  mutation GET_PRE_SIGNED_URL($input: AWSJSON!) {
    getPreSignedUrl(body: $input)
  }
`

export const CREATE_ATTACHMENT = /* GraphQL */ `
  mutation CREATE_ATTACHMENT($input: CreateAttachmentInput!) {
    createAttachment(input: $input) {
      contractId
      id
      buildingId
      name
      title
    }
  }
`

export const DELETE_ATTACHMENT = /* GraphQL */ `
  mutation DELETE_ATTACHMENT($input: DeleteAttachmentInput!) {
    deleteAttachment(input: $input) {
      id
    }
  }
`

export const CREATE_ENTRY_WITH_ATTACHMENT = /* GraphQL */ `
  mutation createEntryWithAttachment($input: AWSJSON!) {
    createEntryWithAttachment(body: $input)
  }
`

export const UPDATE_ATTACHMENT = /* GraphQL */ `
  mutation UPDATE_ATTACHMENT($input: UpdateAttachmentInput!) {
    updateAttachment(input: $input) {
      id
    }
  }
`

export const CONFIG = `query configByType($type:String!) {
  configByType(type: $type) {
    items{
      value
      type
      name
    }
  }
}`
