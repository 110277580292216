import moment from 'moment'
import colors from 'src/components/layouts/colors.json'

const FULL_MONTH_YEAR_FORMAT = 'MMMM YYYY'
const MONTH_YEAR_FORMAT = 'MMM YYYY'
export const euiMeasurement = 'KBtu/ft²/year'
export const eciMeasurement = '$/ft²/year'

export const ChartDataTransformation = (chartData, startDate, endDate) => {
    const eciList = []
    const euiList = []
    const dateX = []
    const eciBarData = []
    const euiBarData = []
    if(startDate && endDate && chartData){
        const start = new Date(startDate)
        const end = new Date(endDate)
        while(start < end) {
        const filterChartData = chartData.filter((data) => {
            return (data?.month === start?.getMonth() + 1  && data?.year === start?.getFullYear())
        });
        let eciVal = null
        let euiVal = null
        if (filterChartData?.length) {
            eciVal = filterChartData[0]?.eci
            euiVal = filterChartData[0]?.eui
        } 
        eciList.push(eciVal)
        euiList.push(euiVal)
        dateX.push(Date.parse(start))
        euiBarData.push({ 
            displayName: moment(Date.parse(start)).format(FULL_MONTH_YEAR_FORMAT),
            name: moment(Date.parse(start)).format(MONTH_YEAR_FORMAT),
            values: { name: `EUI (${euiMeasurement})`, value: euiVal, color: colors.BLUE }
        })
        eciBarData.push({ 
            displayName: moment(Date.parse(start)).format(FULL_MONTH_YEAR_FORMAT),
            name: moment(Date.parse(start)).format(MONTH_YEAR_FORMAT),
            values: { name: `ECI (${eciMeasurement})`, value: eciVal, color: colors.GREEN }
        })
        start.setMonth(start.getMonth() + 1);
        }
    }
    return {
        eciList,
        euiList,
        dateX,
        euiBarData,
        eciBarData
    }
  }