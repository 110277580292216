import React, { PureComponent } from 'react'
import { findDOMNode } from 'react-dom'
import {
  addOutsideClick,
  removeOutsideClick
} from 'src/components/legacy/common/helpers.js'

export default function ClickOutside(WrappedComponent) {
  return class extends PureComponent {
    
    constructor(props) {
      super(props)
      this.clickOutsideRef = React.createRef()
      this.state = {
        open: props.open || false
      }
    }
    

    componentWillUnmount() {
      removeOutsideClick()
    }

    handleClickOutside = (event) => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({ open: false })
        removeOutsideClick()
      }
    }

    toggleOpen = () => {
      const open = !this.state.open
      this.setState({ open })
      if (open) {
        // listen to outside clicks when component is in open state
        addOutsideClick(this.handleClickOutside)
      } else {
        // remove listener when as it's not needed for closed component
        removeOutsideClick()
      }
    }

    setWrapperRef = (component) => {
      this.wrapperRef = findDOMNode(component)
    }
    setClickOutsideRef = (component) => {
      this.clickOutsideRef = component
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          open={this.state.open}
          toggleOpen={this.toggleOpen}
          refFunc={this.setWrapperRef}
        />
      )
    }
  }
}
