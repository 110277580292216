import moment from "moment"

/**
 * @typedef {Object} DateRange
 * @property {moment} startDate - Date range start date
 * @property {moment} endDate - Date range end date
 */

/**
 * Helper function that converts STP to date range
 * @param {string} stp - Standard Time Period (STP) string, starts with ":stp/:
 * @param {moment} [now] - Current date, moment js instance
 * @returns {DateRange}
 */
export function getStpDateRange(stp, now = moment()) {
	if (!STP_MAPPING.hasOwnProperty(stp)) {
		throw new Error(
			`Unknown standard time period - ${stp}. Supported time periods: ${[...Object.keys(STP_MAPPING)].join(", ")}`
		)
	}

	const dateRange = STP_MAPPING[stp](now)
	return dateRange ? {
		startDate: dateRange[0],
		endDate: dateRange[1],
	} : null
}

const STP_MAPPING = {
	":stp/current-day": now => STP_MAPPING_HELPERS.getCurrent(now, "day"),
	":stp/current-week": now => STP_MAPPING_HELPERS.getCurrent(now, "week"),
	":stp/current-month": now => STP_MAPPING_HELPERS.getCurrent(now, "month"),
	":stp/current-quarter": now => STP_MAPPING_HELPERS.getCurrent(now, "quarter"),
	":stp/current-year": now => STP_MAPPING_HELPERS.getCurrent(now, "year"),

	":stp/current-quarter-previous-year": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-quarter", "year"),

	":stp/comparison-day": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-day", "day"),
	":stp/comparison-week": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-week", "week"),
	":stp/comparison-month": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-month", "month"),
	":stp/comparison-quarter": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-quarter", "quarter"),
	":stp/comparison-year": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-year", "year"),

	":stp/past-hour": now => STP_MAPPING_HELPERS.getPastHours(now, 1),
	":stp/past-4-hours": now => STP_MAPPING_HELPERS.getPastHours(now, 4),
	":stp/past-12-hours": now => STP_MAPPING_HELPERS.getPastHours(now, 12),
	":stp/past-24-hours": now => STP_MAPPING_HELPERS.getPastHours(now, 24),
	":stp/past-3-days": now => STP_MAPPING_HELPERS.getPast(now, 3, "days"),
	":stp/past-7-days": now => STP_MAPPING_HELPERS.getPast(now, 7, "days"),
	":stp/past-14-days": now => STP_MAPPING_HELPERS.getPast(now, 14, "days"),
	":stp/past-30-days": now => STP_MAPPING_HELPERS.getPast(now, 30, "days"),
	":stp/past-90-days": now => STP_MAPPING_HELPERS.getPast(now, 90, "days"),
	":stp/past-3-months": now => STP_MAPPING_HELPERS.getPast(now, 3, "months"),
	":stp/past-6-months": now => STP_MAPPING_HELPERS.getPast(now, 6, "months"),
	":stp/past-11-months": now => STP_MAPPING_HELPERS.getPast(now, 11, "months"),
	":stp/past-12-months": now => STP_MAPPING_HELPERS.getPastByDay(now, 12, "months"),

	":stp/previous-day": now => STP_MAPPING_HELPERS.getPrevious(now, "day"),
	":stp/previous-week": now => STP_MAPPING_HELPERS.getPrevious(now, "week"),
	":stp/previous-month": now => STP_MAPPING_HELPERS.getPrevious(now, "month"),
	":stp/previous-quarter": now => STP_MAPPING_HELPERS.getPrevious(now, "quarter"),
	":stp/previous-quarter-to-date": now => STP_MAPPING_HELPERS.getPrevious(now, "quarter", false),
	":stp/previous-year": now => STP_MAPPING_HELPERS.getPrevious(now, "year"),
	":stp/previous-year-full": now => STP_MAPPING_HELPERS.getPrevious(now, "year"),

	":stp/today-previous-day-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-day", "day"),
	":stp/today-previous-week-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-day", "week"),
	":stp/today-previous-month-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-day", "month"),
	":stp/today-previous-year-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-day", "year"),
	":stp/today-previous-day-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/current-day", "day"),
	":stp/today-previous-week-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/current-day", "week"),
	":stp/today-previous-month-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/current-day", "month"),
	":stp/today-previous-year-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/current-day", "year"),

	":stp/past-3-days-week-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/past-3-days", "week"),
	":stp/past-3-days-month-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/past-3-days", "month"),
	":stp/past-3-days-year-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/past-3-days", "year"),
	":stp/past-3-days-week-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/past-3-days", "week"),
	":stp/past-3-days-month-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/past-3-days", "month"),
	":stp/past-3-days-year-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/past-3-days", "year"),

	":stp/past-7-days-week-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/past-7-days", "week"),
	":stp/past-7-days-month-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/past-7-days", "month"),
	":stp/past-7-days-year-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/past-7-days", "year"),
	":stp/past-7-days-week-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/past-7-days", "week"),
	":stp/past-7-days-month-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/past-7-days", "month"),
	":stp/past-7-days-year-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/past-7-days", "year"),

	":stp/current-month-previous-year": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/current-month", "year"),
	":stp/current-month-previous-year-full": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/current-month", "year", "month"),
	":stp/past-3-months-year-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/past-3-months", "year"),
	":stp/past-3-months-year-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/past-3-months", "year", "month"),
	":stp/past-6-months-year-compared": now => STP_MAPPING_HELPERS.getPreviousTimeCompared(now, ":stp/past-6-months", "year"),
	":stp/past-6-months-year-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/past-6-months", "year", "month"),

	":stp/previous-previous-quarter-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/previous-quarter", "quarter"),
	":stp/previous-quarter-year-full-compared": now => STP_MAPPING_HELPERS.getPreviousTimeComparedFull(now, ":stp/previous-quarter", "year", "quarter"),

	":stp/previous-past-hour": now => STP_MAPPING_HELPERS.getPreviousPastHours(now, 1),
	":stp/previous-past-24-hours": now => STP_MAPPING_HELPERS.getPreviousPastHours(now, 24),
	":stp/previous-past-7-days": now => STP_MAPPING_HELPERS.getPreviousPast(now, 7, "days"),
	":stp/previous-past-14-days": now => STP_MAPPING_HELPERS.getPreviousPast(now, 14, "days"),
	":stp/previous-past-30-days": now => STP_MAPPING_HELPERS.getPreviousPast(now, 30, "days"),
	":stp/previous-past-90-days": now => STP_MAPPING_HELPERS.getPreviousPast(now, 90, "days"),
	":stp/previous-past-12-months": now => STP_MAPPING_HELPERS.getPreviousPast(now, 12, "months"),

	":none": () => null,
}

const STP_MAPPING_HELPERS = {
	getCurrent: (now, period) => ([now.clone().startOf(period), now.clone()]),
	getPastHours: (now, value) => ([now.clone().subtract(value, "hour"), now.clone()]),
	getPreviousPastHours: (now, value) => ([now.clone().subtract(value * 2, "hour"), now.clone().subtract(value, "hour")]),
	getPast: (now, value, period) => ([now.clone().startOf(period).subtract(value - 1, period), now.clone()]),
	getPastByDay: (now, value, period) => ([now.clone().startOf("day").subtract(value, period), now.clone()]),
	getPreviousPast: (now, value, period) => ([now.clone().startOf(period).subtract(2 * value - 1, period), now.clone().subtract(value, period)]),
	getPrevious: (now, period, full = true) => ([
		now.clone().subtract(1, period).startOf(period),
		full ? now.clone().subtract(1, period).endOf(period) : now.clone().subtract(1, period),
	]),
	getPreviousTimeCompared: (now, stp, period) => (STP_MAPPING[stp](now.clone().subtract(1, period))),
	getPreviousTimeComparedFull: (now, stp, period, endOfPeriod = period) => {
		const [startDay, endDay] = STP_MAPPING[stp](now.clone().subtract(1, period))
		return [
			startDay,
			endDay.endOf(endOfPeriod),
		]
	},
}
