import { PDF_PAGE_TYPES } from 'src/components/legacy/common/pdf'
import PDFGenerator from 'src/components/print-components/pdf-generator'
import PdfConsultationDetailView from './pdf-consultation-detail-view'

const ConsultationDetailDownload = (props) => {
  const { downloadData } = props
  const chartImages = null
  return (
    <>
        <PDFGenerator
          fileName={downloadData?.name}
          pageType={PDF_PAGE_TYPES.CUSTOM_PAGE}
          isAutoDownload={props?.isAutoDownload ?? false}
        >
        <PdfConsultationDetailView
				opportunities={downloadData?.opportunities}
				consultation={downloadData}
				isFooterCount={true}
				chartImage={chartImages}
        headerLocation={downloadData?.locations?.map(m=>m?.locationName)?.join(", ")}
        headerOrganization={downloadData?.account?.name}
			/>
        </PDFGenerator>
    </>
  )
}

export default ConsultationDetailDownload
