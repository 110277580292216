import { useState, useEffect } from 'react'
import ContainerWrapper from 'src/components/Container'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import WeatherWidgetForm from './WeatherWidgetForm'
import { GET_BUILDINGS_BY_ACCOUNTS } from '../../../connectToADevice/graphql/queries'
import { useQuery } from 'src/hooks/APIHooks'
import ObjectPickerWrapper from 'src/components/object-picker'
import {BUILDING} from 'src/components/object-picker/helpers.js'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { getWeatherDetails } from '../../graphql'
import { getFormattedResponseData } from '../../helpers'
import { getSearchParams } from "src/common/helperFunctions.js"
import { newRefreshToken } from 'src/redux/slicers/appData'
import { useDispatch, useSelector} from 'react-redux'


const WeatherWidget = ({ setWidgetFormik }) => {
  const { width } = useBreakPoint()
  const {organizationId} = getSearchParams()
  const dispatch = useDispatch()
  const {
    setWidgetBuilding,
    isLoading,
    widgetDetails,
    setWidgetBuildingDetails,
    setIsLocationLoading,
    objectPickerError,
    setObjectPickerSelectedValue,
    setWidgetSalesOfficeId,
    mode
  } = useWidgetsContext()
  const { data: accountData, refetch: refetchAccount } = useQuery({
    query: GET_BUILDINGS_BY_ACCOUNTS,
    dataPath: 'data.listBuildingsByAccount.items',
    disableInitialLoad: true
  })

  const accountId = useSelector(
    (state: any) => state?.buildingPicker?.selectedOrganization
  )

  const { refetch: refetchGetWeatherDetails } = useQuery({
    query: getWeatherDetails,
    dataPath: 'data',
    disableInitialLoad: true,
    onSuccess: (response) => {
      const listWeatherTimeSeriesData = response?.listWeatherTimeSeriesData?.items ?? []
      const buildingDetails = response?.getBuilding ?? {}
      const weatherData = JSON.parse(listWeatherTimeSeriesData?.[0]?.data ?? "")
      const weatherUpdatedAt = listWeatherTimeSeriesData?.[0]?.dateTime
      const widgetBuildingInfo = {
        weatherInfo: {
          temperature_f: weatherData?.TemperatureF,
          description: weatherData?.Description ?? '',
          icon: weatherData?.Icon,
          weatherUpdatedAt: weatherUpdatedAt
        },
        city: buildingDetails?.city,
        state: buildingDetails?.state,
        postalCode: buildingDetails?.postalCode

      }
      setWidgetBuildingDetails(widgetBuildingInfo)
      setIsLocationLoading(false)
    }
  })

  useEffect(() => {
    if (accountData?.length) {
      handleGetLocationDetails(widgetDetails?.widgetBuildingId)
    }
  }, [accountData])

  useEffect(() => {
    if (organizationId) {
      refetchAccount({ id: organizationId })
    }
  }, [organizationId])

  const handleGetLocationDetails = async (id) => {
    if (!id) return
    setIsLocationLoading(true)
    await dispatch<any>(newRefreshToken([id], null, null))
    refetchGetWeatherDetails({ buildingId: id })
  }
  const handleGetBuidlingDetails = (id) => {
    if (id) {
      handleGetLocationDetails(id)
    } else {
      setWidgetBuildingDetails(null)
    }
  }
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <ContainerWrapper
          sColumns={12}
          mColumns={12}
          lColumns={12}
          xlColumns={12}
          background="#eee"
        >
          <Content
            xlColumn={4}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
            background="#fff"
          >
              <ObjectPickerWrapper
                objects={["", BUILDING]}
                initialValues={getFormattedResponseData(mode, widgetDetails, true)}
                update={(items) => {
                  if (items?.length > 0) {
                    const pickerValue = items[0] ?? {}
                    setObjectPickerSelectedValue(items)
                    handleGetBuidlingDetails(pickerValue?.buildingId)
                    setWidgetBuilding({
                      id: pickerValue?.buildingId,
                      name: pickerValue?.buildingName
                    })
                    setWidgetSalesOfficeId(pickerValue?.salesOfficeId)
                  } else {
                    setObjectPickerSelectedValue(null)
                    handleGetBuidlingDetails(null)
                    setWidgetBuilding(null)
                    setWidgetSalesOfficeId(null)
                  }
                }}
                buildingSelectLimit={1}
                mode={mode}
                error={objectPickerError}
                orgIdFromParent={accountId}
              />
          </Content>
          <Content
            xlColumn={8}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <WeatherWidgetForm setWidgetFormik={setWidgetFormik} />
          </Content>
        </ContainerWrapper>
      )}
    </>
  )
}

export default WeatherWidget
