import AccountInformation from './account-information'
import {
  SEARCH_AGGREGATE,
  SEARCH_SALES_OFFICES
} from '../../graphql/queries'
import { CREATE_ACCOUNT } from '../../graphql/mutations'
import { useMutation } from 'src/hooks/APIHooks'
import translate,{TranslateComponent} from 'src/common/translations'

const AccountInformationWithData = ({ formProps, pageName }) => {
  const { data: newAccount, onSubmit: createAccount } = useMutation({
    query: CREATE_ACCOUNT,
    disableInitialLoad: true,
    dataPath: 'data.createAccount'
  })

  const translatedPlaceholder = {
    searchPlaceHolder : translate("Type to find an Organization")+"...",
    placeHolder : translate("--Select One--"),
    officeSearch: translate("Find an Office")+"...",
  }

  return (
    <AccountInformation
      newAccount={newAccount}
      createAccount={createAccount}
      setValues={formProps.setValues}
      values={formProps.values}
      searchAccounts={SEARCH_AGGREGATE}
      searchSalesOffices={SEARCH_SALES_OFFICES}
      pageName={pageName}
      searchPlaceHolder={translatedPlaceholder.searchPlaceHolder}
      placeHolder={translatedPlaceholder.placeHolder}
      officeSearch={translatedPlaceholder.officeSearch}
    />
  )
}

export default AccountInformationWithData
