import EnergyIntensityContainerMain from './EnergyIntensityContainerMain'
import { EngergyIntensityContextProvider } from './EnergyIntensityContextProvider'

export const EnergyIntensity = () => {
  return (
    <EngergyIntensityContextProvider>
      <EnergyIntensityContainerMain />
    </EngergyIntensityContextProvider>
  )
}
