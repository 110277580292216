import { useEffect, useState } from 'react'
import EnergyCostChart from 'src/pages/widgets/widget-library/energyWidgets/cost/EngeryCostChart'
import {
  aggreagatorSelection
} from 'src/common/chartHelperFunctions'
import {
  EnergyCostLegendsContainer,
  EnergyCostLegend,
  SimpleColorComponent,
  LegendColorContainer,
  EnergyCostLegendLabel
} from '../style'
import { useTranslation } from 'react-i18next'
import translate, { TranslateComponent } from 'src/common/translations'

const stackedBarColors = {
  consumption: '#fb9a09',
  demand: '#00b9e4',
  monthlyFee: '#61ae34',
  compareConsumption: '#b06c06',
  compareDemand: '#0082a0',
  compareMonthlyFee: '#447a24',
  todateConsumption: '#eeeeee',
  todateDemand: '#acacac',
  todateMonthlyFee: '#838383'
}

export const EnergyCostStackedBar = ({
  energyCostData,
  compareEnergyCostData,
  dataAggregation,
  selectedTimePeriod,
  compareSelectedTimePeriod,
  getXLabelName,
  plotlineDetails,
  widgetDetails,
  compareInnerBarEnergyCost
}) => {
  const [stackedBarData, setStackedBarData] = useState([])

  const [t] = useTranslation()

  // Get grouped bars - monthly bars only
  const getMonthlyInnnerBarValues = (barLength) => {
    try {
      if (
        compareEnergyCostData?.isDataAvailable &&
        compareInnerBarEnergyCost?.isDataAvailable
      ) {
        const consumptionCostInnerValue = aggreagatorSelection(
          'sum',
          compareInnerBarEnergyCost?.consumptionCost?.values,
          0,
          compareInnerBarEnergyCost?.consumptionCost?.values?.length - 1
        )
        const lastMonthIndex = compareEnergyCostData?.length - 1
        demandCostInnerValue =
          compareEnergyCostData?.demandCost?.values[lastMonthIndex]?.value
        additionalCostInnerValue =
          compareEnergyCostData?.additionalCost?.values[lastMonthIndex]?.value

        // Filling both compare and current, that is how stacked bars are plotted
        // SO fill dummy values
        // Fill only last index -1 bar, since we have inner for compare of last data
        return [
          {
            type: 'stackedbar',
            name: 'Electric Monthly Fee',
            color: stackedBarColors.todateMonthlyFee,
            stackBy: compareSelectedTimePeriod, // stack by property is responsible for grouping to create stack formats.
            data: Array(dataLength)
              .fill()
              .map((x, index) => {
                if (index === dataLength - 1) {
                  return additionalCostInnerValue
                }
              })
          },
          {
            type: 'stackedbar',
            name: 'Electric Demand',
            color: stackedBarColors.todateDemand,
            stackBy: compareSelectedTimePeriod,
            data: Array(dataLength)
              .fill()
              .map((x, index) => {
                if (index === dataLength - 1) {
                  return demandCostInnerValue
                }
              })
          },
          {
            type: 'stackedbar',
            name: 'Electric Consumption',
            color: stackedBarColors.todateConsumption,
            stackBy: compareSelectedTimePeriod,
            data: Array(dataLength)
              .fill()
              .map((x, index) => {
                if (index === dataLength - 1) {
                  return consumptionCostInnerValue
                }
              })
          },
          {
            type: 'stackedbar',
            name: 'Electric Monthly Fee',
            color: stackedBarColors.todateMonthlyFee,
            stackBy: selectedTimePeriod, // stack by property is responsible for grouping to create stack formats.
            data: Array(dataLength).fill(0)
          },
          {
            type: 'stackedbar',
            name: 'Electric Demand',
            color: stackedBarColors.todateDemand,
            stackBy: selectedTimePeriod,
            data: Array(dataLength).fill(0)
          },
          {
            type: 'stackedbar',
            name: 'Electric Consumption',
            color: stackedBarColors.todateConsumption,
            stackBy: selectedTimePeriod,
            data: Array(dataLength).fill(0)
          }
        ]
      }
    } catch (error) {
      console.log(error)
    }
  }

  // All other types
  const getInnerBarValuesIndividualBar = () => {
    try {
      if (compareEnergyCostData && compareInnerBarEnergyCost) {
        // calculate sum till current energy cost index values -> which equals to till date of current in previous period

        const consumptionCostInnerValue = aggreagatorSelection(
          'sum',
          compareInnerBarEnergyCost?.consumptionCost?.values?.[0],
          0,
          compareInnerBarEnergyCost?.consumptionCost?.values?.[0]?.length
        )

        const demandCostInnerValue = aggreagatorSelection(
          'sum',
          compareEnergyCostData?.demandCost?.values,
          0,
          compareEnergyCostData?.demandCost?.values?.length
        )

        const additionalCostInnerValue = aggreagatorSelection(
          'sum',
          compareEnergyCostData?.additionalCost?.values,
          0,
          compareEnergyCostData?.additionalCost?.values.length
        )

        // Filling both compare and current, that is how stacked bars are plotted
        // SO fill dummy values
        // Fill only last index -1 bar, since we have inner for compare of last data
        return [
          {
            type: 'stackedbar',
            name: 'Electric Consumption',
            color: stackedBarColors.todateConsumption,
            stackBy: compareSelectedTimePeriod,
            data: [consumptionCostInnerValue, 0]
          },
          {
            type: 'stackedbar',
            name: 'Electric Demand',
            color: stackedBarColors.todateDemand,
            stackBy: compareSelectedTimePeriod,
            data: [demandCostInnerValue, 0]
          },
          {
            type: 'stackedbar',
            name: 'Electric Monthly Fee',
            color: stackedBarColors.todateMonthlyFee,
            stackBy: compareSelectedTimePeriod, // stack by property is responsible for grouping to create stack formats.
            data: [additionalCostInnerValue, 0]
          },
          {
            type: 'stackedbar',
            name: 'Electric Consumption',
            color: stackedBarColors.todateConsumption,
            stackBy: selectedTimePeriod,
            data: [0, 0]
          },
          {
            type: 'stackedbar',
            name: 'Electric Demand',
            color: stackedBarColors.todateDemand,
            stackBy: selectedTimePeriod,
            data: [0, 0]
          },
          {
            type: 'stackedbar',
            name: 'Electric Monthly Fee',
            color: stackedBarColors.todateMonthlyFee,
            stackBy: selectedTimePeriod, // stack by property is responsible for grouping to create stack formats.
            data: [0, 0]
          }
        ]
      }
    } catch (error) {
      console.log(error)
    }
  }

  const findMaxBarValueGroup = (allBarData, plotLineValue = 0) => {
    try {
      const findSingleGroupMaxArray = (barData) => {
        const dataLength = barData[0]?.data?.length
        const totalValuesArray = Array(dataLength).fill(0)
        for (let i = 0; i < barData?.length; i++) {
          for (let j = 0; j < barData[i].data?.length; j++) {
            totalValuesArray[j] = totalValuesArray[j] + barData[i]?.data[j]
          }
        }
        const maxValue = Math.max(...totalValuesArray)
        return maxValue
      }

      const currentGroupMaxValue = findSingleGroupMaxArray(
        allBarData?.filter((x) => x?.stackBy === selectedTimePeriod)
      )
      const compareGroopMaxValue = findSingleGroupMaxArray(
        allBarData?.filter((x) => x?.stackBy === selectedTimePeriod)
      )
      const maxValue = Math.max(
        currentGroupMaxValue,
        compareGroopMaxValue,
        plotLineValue
      )
      return maxValue
    } catch (error) { }
  }

  const findMaxBarValueSingleBar = (barData, plotLineValue = 0) => {
    const compareBarArray = barData
      ?.filter((x) => x?.stackBy === compareSelectedTimePeriod)
      ?.map((x) => x?.data?.[0])
    const currentBarArray = barData
      ?.filter((x) => x?.stackBy === selectedTimePeriod)
      ?.map((x) => x?.data?.[0])
    const compareValuesSum = compareBarArray?.reduce((a, b) => a + b, 0)
    const currentValuesSum = currentBarArray?.reduce((a, b) => a + b, 0)
    const maxValue = Math.max(
      compareValuesSum,
      currentValuesSum,
      plotLineValue || 0
    )
    return maxValue
  }

  const getPlotLineDetails = (barData) => {
    try {
      if (!plotlineDetails?.benchMarker?.includes('disabled')) {
        let plotLineValue = 0
        const dataLength = barData[0]?.data?.length
        if (plotlineDetails?.benchMarker?.includes('appropriate')) {
          const totalValuesArray = Array(dataLength).fill(0)
          for (let i = 0; i < barData?.length; i++) {
            for (let j = 0; j < barData[i].data?.length; j++) {
              totalValuesArray[j] = totalValuesArray[j] + barData[i]?.data[j]
            }
          }
          const finalSum = totalValuesArray.reduce((a, b) => a + b, 0)
          plotLineValue = finalSum / dataLength || 0
        } else {
          plotLineValue = plotlineDetails?.benchMarkerValue
        }

        return {
          stroke: plotlineDetails?.color,
          strokeWidth: 2,
          value: plotLineValue,
          hideTooltip: false,
          name: {
            hide: true,
            text: plotlineDetails?.label ?? '',
            fontSize: 11,
            fontColor: '#000000'
          }
        }
      }
    } catch (error) { }
  }

  const formStackedBarData = () => {
    const stackedBarData = []
    let innerStackedbarData = []
    // Grouped stacked bar, here either 3 bars or 6 bars
    if (
      selectedTimePeriod === 'past-3-days' ||
      selectedTimePeriod === 'past-6-days'
    ) {
      // stacked bar currently will not support daywise view
      // May be we have to do in future
    } else if (
      selectedTimePeriod === 'past-3-months' ||
      selectedTimePeriod === 'past-6-months'
    ) {
      const consumptionCost = energyCostData?.consumptionCost?.values
      const demandCost = energyCostData?.demandCost?.values
      const additionalCost = energyCostData?.additionalCost?.values

      // Compare bar chart details added
      if (compareEnergyCostData?.isDataAvailable) {
        const consumptionCost = compareEnergyCostData?.consumptionCost?.values
        const demandCost = compareEnergyCostData?.demandCost?.values
        const additionalCost = compareEnergyCostData?.additionalCost?.values

        stackedBarData.push(
          {
            type: 'stackedbar',
            name: 'Electric Monthly Fee',
            color: stackedBarColors.compareMonthlyFee,
            stackBy: compareSelectedTimePeriod,
            data: additionalCost?.map((x) => x?.value || 0)
          },
          {
            type: 'stackedbar',
            name: 'Electric Demand',
            color: stackedBarColors.compareDemand,
            stackBy: compareSelectedTimePeriod,
            data: demandCost?.map((x) => x?.value || 0)
          },
          {
            type: 'stackedbar',
            name: 'Electric Consumption',
            color: stackedBarColors.compareConsumption,
            stackBy: compareSelectedTimePeriod,
            data: consumptionCost?.map((x) => x?.value || 0)
          }
        )

        innerStackedbarData = getMonthlyInnnerBarValues(consumptionCost?.length)
      }

      stackedBarData.push(
        {
          type: 'stackedbar',
          name: 'Electric Monthly Fee',
          color: stackedBarColors.monthlyFee,
          stackBy: selectedTimePeriod,
          data: additionalCost?.map((x) => Number(x?.value || 0))
        },
        {
          type: 'stackedbar',
          name: 'Electric Demand',
          color: stackedBarColors.demand,
          stackBy: selectedTimePeriod,
          data: demandCost?.map((x) => Number(x?.value || 0))
        },
        {
          type: 'stackedbar',
          name: 'Electric Consumption',
          color: stackedBarColors.consumption,
          stackBy: selectedTimePeriod,
          data: consumptionCost?.map((x) => Number(x?.value || 0))
        }
      )

      const stackedBarAxisData = consumptionCost?.map((x) => x?.timeStamp)

      const plotLine = getPlotLineDetails(
        stackedBarData?.filter((x) => x?.stackBy === selectedTimePeriod)
      )

      const maxBarValue = findMaxBarValueGroup(stackedBarData, plotLine?.value)

      setStackedBarData({
        data: stackedBarData,
        costToDateData: innerStackedbarData,
        axisData: stackedBarAxisData,
        plotLine,
        hidePlotLine: plotLine ? false : true,
        maxBarValue
      })
    } else {
      // individual bars
      // Without compare 1 only one bar
      // WIth compare only 2 bars

      if (compareEnergyCostData) {
        const consumptionCostValue = aggreagatorSelection(
          'sum',
          compareEnergyCostData?.consumptionCost?.values,
          0,
          compareEnergyCostData?.consumptionCost?.values?.length
        )
        const demandCostValue = aggreagatorSelection(
          'sum',
          compareEnergyCostData?.demandCost?.values,
          0,
          compareEnergyCostData?.demandCost?.values?.length
        )
        const additionalCostValue = aggreagatorSelection(
          'sum',
          compareEnergyCostData?.additionalCost?.values,
          0,
          compareEnergyCostData?.additionalCost?.values?.length - 1
        )
        stackedBarData.push(
          {
            type: 'stackedbar',
            name: 'Electric Demand',
            color: stackedBarColors.compareDemand,
            stackBy: compareSelectedTimePeriod,
            data: [additionalCostValue]
          },
          {
            type: 'stackedbar',
            name: 'Electric Monthly Fee',
            color: stackedBarColors.compareMonthlyFee,
            stackBy: compareSelectedTimePeriod,
            data: [demandCostValue]
          },
          {
            type: 'stackedbar',
            name: 'Electric Consumption',
            color: stackedBarColors.compareConsumption,
            stackBy: compareSelectedTimePeriod,
            data: [consumptionCostValue]
          }
        )

        innerStackedbarData = getInnerBarValuesIndividualBar()
      }

      const consumptionCostValue = aggreagatorSelection(
        'sum',
        energyCostData?.consumptionCost?.values,
        0,
        energyCostData?.consumptionCost?.values?.length
      )
      const demandCostValue = aggreagatorSelection(
        'sum',
        energyCostData?.demandCost?.values,
        0,
        energyCostData?.demandCost?.values?.length
      )
      const additionalCostValue = aggreagatorSelection(
        'sum',
        energyCostData?.additionalCost?.values,
        0,
        energyCostData?.additionalCost?.values?.length
      )
      stackedBarData.push(
        {
          type: 'stackedbar',
          name: 'Electric Monthly Fee',
          color: stackedBarColors.monthlyFee,
          stackBy: selectedTimePeriod,
          data: [demandCostValue]
        },
        {
          type: 'stackedbar',
          name: 'Electric Demand',
          color: stackedBarColors.demand,
          stackBy: selectedTimePeriod,
          data: [additionalCostValue]
        },
        {
          type: 'stackedbar',
          name: 'Electric Consumption',
          color: stackedBarColors.consumption,
          stackBy: selectedTimePeriod,
          data: [consumptionCostValue]
        }
      )

      const stackedBarAxisData = [getXLabelName()]

      const plotLine = getPlotLineDetails(stackedBarData)

      const maxBarValue = findMaxBarValueSingleBar(
        stackedBarData,
        plotLine?.value
      )

      setStackedBarData({
        data: stackedBarData,
        axisData: stackedBarAxisData,
        costToDateData: innerStackedbarData,
        plotLine,
        hidePlotLine: plotLine ? false : true,
        maxBarValue
      })
    }
  }

  useEffect(() => {
    try {
      formStackedBarData()
    } catch (error) {
      console.log(error)
    }
  }, [energyCostData, compareEnergyCostData, compareInnerBarEnergyCost])

  const compareLegendLabel = `${t(
    `time>${`:stp/${widgetDetails?.comparedTo}`}`,
    {
      nsSeparator: '>'
    }
  )}`

  const currentLegendLabel = `${t(`time>${`${widgetDetails?.timePeriod}`}`, {
    nsSeparator: '>'
  })}`

  return (
    <>
      <EnergyCostChart stackedBarData={stackedBarData} />
      {widgetDetails?.comparedTo !== ':none' && (
        <Legends
          currentLabel={currentLegendLabel}
          compareLabel={compareLegendLabel}
        />
      )}
    </>
  )
}

const Legends = ({ currentLabel, compareLabel }) => {
  return (
    <EnergyCostLegendsContainer>
      <EnergyCostLegend>
        <LegendColorContainer>
          <SimpleColorComponent
            color={stackedBarColors.compareConsumption}
          ></SimpleColorComponent>
          <SimpleColorComponent
            color={stackedBarColors.compareDemand}
          ></SimpleColorComponent>
          <SimpleColorComponent
            color={stackedBarColors.compareMonthlyFee}
          ></SimpleColorComponent>
        </LegendColorContainer>
        <EnergyCostLegendLabel title={translate(compareLabel)}>
          <TranslateComponent>{compareLabel}</TranslateComponent>
        </EnergyCostLegendLabel>
      </EnergyCostLegend>
      <EnergyCostLegend>
        <LegendColorContainer>
          <SimpleColorComponent
            color={stackedBarColors.consumption}
          ></SimpleColorComponent>
          <SimpleColorComponent
            color={stackedBarColors.demand}
          ></SimpleColorComponent>
          <SimpleColorComponent
            color={stackedBarColors.monthlyFee}
          ></SimpleColorComponent>
        </LegendColorContainer>
        <EnergyCostLegendLabel title={translate(currentLabel)}>
          <TranslateComponent>{currentLabel}</TranslateComponent>
        </EnergyCostLegendLabel>
      </EnergyCostLegend>
    </EnergyCostLegendsContainer>
  )
}
