import { useEffect, useState } from 'react'
import { useWidgetChartDataHook } from 'src/hooks/widgetChartDataHook'
import { aggreagatorSelection } from 'src/common/chartHelperFunctions'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  WidgetChartTitleComponent,
  WidgetChartFooterComponent,
  NoWidgetDataAvailable
} from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import GaugeChart from 'src/pages/widgets/widget-library/dataMonitoringGauge/GaugeChart'
import { format } from 'd3'
import last from 'lodash/last'
import colors from 'src/components/layouts/colors.json'
import { isValidUOMForGetChartData } from "../../helper";

const timeComparisionAggreagation = {
  'current-day': '15-minutes',
  'current-week': 'daily',
  'current-month': 'monthly',
  'current-quarter': 'quarterly',
  'current-year': 'yearly'
}

export const DataMonitoringGaugeWidgetPreview = ({ widgetDetails }) => {
  const selectedPeriod = widgetDetails?.selectedTimePeriod

  const selectedAggrValue = widgetDetails?.selectedAggrValue

  const dataAggregation = timeComparisionAggreagation[selectedPeriod]

  const [gaugeData, setGaugeData] = useState({})
  const [gaugeCalculatedMax, setGaugeCalculatedMax] = useState(0)

  const barMaximumTimeAndAggr = (selectedTime) => {
    switch (selectedTime) {
      case 'peak-last-30-days':
        return { timePeriod: 'past-30-days', aggregation: 'max' }
      case 'peak-last-12-months':
        return { timePeriod: 'past-30-days', aggregation: 'max' }
      case 'peak-mtd':
        return { timePeriod: 'current-month', aggregation: 'max' }
      case 'peak-ytd':
        return { timePeriod: 'current-year', aggregation: 'max' }
      case 'sum-mtd':
        return { timePeriod: 'current-month', aggregation: 'sum' }
      case 'sum-today':
        return { timePeriod: 'current-day', aggregation: 'sum' }
      default:
        return { timePeriod: null, aggregation: null }
    }
  }

  const {
    timePeriod: barMaxSelectedTime,
    aggregation: barMaxSelectedAggregationValue
  } = barMaximumTimeAndAggr(widgetDetails?.gaugeMaxTimePeriod)

  const { widgetChartData, getWidgetChartData, loadingWidgetChartData } =
    useWidgetChartDataHook(selectedPeriod, ':none', dataAggregation, widgetDetails?.timeZone)

  const {
    widgetChartData: maxBarWidgetData,
    getWidgetChartData: getMaxbarWidgetData,
    loadingWidgetChartData: maxBarWidgetDataLoading
  } = useWidgetChartDataHook(barMaxSelectedTime, ':none', 'daily', widgetDetails?.timeZone)

  const initializeDataForBarMax = () => {
    const widgetInfo = {
      comparePointTo: ':none',
      chartType: 'Line',
      interval: 1440,
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: barMaxSelectedAggregationValue,
      buildingId: widgetDetails?.buildingId,
      propertiesList: [widgetDetails?.property]
    }
    if (isValidUOMForGetChartData(widgetDetails?.unitsOfMeasure)) {
      widgetInfo["uomDetails"] = { [widgetDetails?.property]: widgetDetails?.unitsOfMeasure }
    }
    getMaxbarWidgetData(widgetInfo)
  }

  useEffect(() => {
    if (widgetDetails?.property) {
      initializeData()
      // Max Gauge calculations proeprty data
      if (widgetDetails?.guageMaximumType?.includes('calculated')) {
        initializeDataForBarMax()
      } else {
        setGaugeCalculatedMax(+widgetDetails?.gaugeMax)
      }
    }
  }, [widgetDetails])

  const getMaximumBarValue = () => {
    if (widgetDetails?.guageMaximumType?.includes('calculated')) {
      if (maxBarWidgetData?.data?.length > 0) {
        const data = maxBarWidgetData?.data?.[0]?.values?.[0] || []
        const maxBarValue = Number(
          aggreagatorSelection(
            barMaxSelectedAggregationValue,
            data || [],
            0,
            data?.length - 1
          )
        ).toFixed(2)
        return maxBarValue ? +maxBarValue : 0
      }
    } else {
      return +widgetDetails?.gaugeMax
    }
  }

  const aggrBarDataValues = (data) => {
    const finalValue = Number(
      aggreagatorSelection(selectedAggrValue, data, 0, data?.length - 1)
    ).toFixed(2)
    return finalValue
  }

  const valueResolution = (value, resolution = '1') => {
    const exp = Math.pow(10, -Math.round(Math.log10(Number(resolution))))
    const splits = resolution.split('.')
    const precision = splits.length == 2 ? splits[1].length : 0
    return format(`${`.${precision}f`}`)(Math.round(value * exp) / exp)
  }

  const deriveGaugueSegments = (thresholds, widgetData) => {
    let sectors = [],
      tickData = [],
      valueRange
    const getThresholdValue = (tag) => {
      return (value) =>
        tag === ':std-threshold/percentage'
          ? (value * widgetData.max) / 100
          : value
    }
    if (thresholds.tag !== ':std-threshold/no-threshold') {
      valueRange = widgetData.max - widgetData.min
      sectors = ['thresholdOne', 'thresholdTwo'].map((tName) => {
        const threshold = thresholds[tName]
        if (thresholds.tag === ':std-threshold/percentage') {
          threshold.percentage = Math.max(
            0,
            ((widgetData.max * threshold.value) / 100 - widgetData.min) /
              valueRange
          )
        } else {
          threshold.percentage = Math.max(
            0,
            (Math.min(threshold.value, widgetData.max) - widgetData.min) /
              valueRange
          )
        }
        return threshold
      })
    }
    const thresholdValue = getThresholdValue(thresholds.tag)
    if (
      sectors.length &&
      sectors.every(({ value }) => thresholdValue(value) <= widgetData.min)
    ) {
      sectors = [last(sectors)]
    } else if (
      !sectors.some(({ value }) => thresholdValue(value) <= widgetData.min)
    ) {
      sectors.unshift({
        percentage: 0,
        color: thresholds.baseColor,
        value: widgetData.min
      })
    }
    tickData = sectors.map((d, i, all) => ({
      start: d.percentage,
      end: i === all.length - 1 ? 1 : all[i + 1].percentage,
      startValue: valueResolution(
        i === 0 ? widgetData.min : thresholdValue(d.value),
        '1'
      ),
      endValue: valueResolution(
        i === all.length - 1
          ? widgetData.max
          : thresholdValue(all[i + 1].value),
        '1'
      ),
      color: d.color
    }))
    tickData = tickData.filter(
      (data) =>
        +data.startValue <= +data.endValue && +data.startValue <= widgetData.max
    )
    tickData = tickData.map((data) => {
      return {
        ...data,
        startValue: +data.startValue,
        endValue:
          +data.endValue <= widgetData.max ? +data.endValue : widgetData.max
      }
    })
    return tickData
  }

  const formatGaugeChartData = (aggrData) => {
    const aggrValue = aggrBarDataValues(aggrData?.values && aggrData?.values[0])
    const thresholds = {
      tag: widgetDetails?.thresholdsValue,
      baseColor: widgetDetails?.baselineColorValue,
      thresholdOne: {
        value: +widgetDetails?.threshold1ConstantValue,
        color: widgetDetails?.threshold1Value
      },
      thresholdTwo: {
        value: +widgetDetails?.threshold2ConstantValue,
        color: widgetDetails?.threshold2Value
      }
    }
    const widgetData = {
      min: +widgetDetails?.gaugeMin,
      max: getMaximumBarValue()
    }
    const tickData = deriveGaugueSegments(thresholds, widgetData)
    const gaugeData = {
      minlimit: widgetData?.min,
      maxlimit: widgetData?.max,
      colorRange: tickData?.map((data) => {
        const segment = {
          limit: data?.endValue,
          color: data?.color,
          label: `${data?.startValue} - ${data?.endValue} ${widgetDetails?.symbol}`,
          d3Symbol: 'symbolSquare',
          labelElProps: {
            color: colors.BLACK
          }
        }
        return segment
      }),
      pointer: {
        value: aggrValue,
        label: [`${aggrValue}`, widgetDetails?.symbol],
        labelProps: [
          // style for value at label[0]
          {
            fontWeight: 'bold',
            fontFamily: 'Arial'
          },
          // style for value at label[1]
          {
            dy: '1.5em',
            fontFamily: 'Arial'
          }
        ]
      }
    }
    setGaugeData(gaugeData)
  }

  useEffect(() => {
    try {
      // if either of data fetch is true, just returns -> no plotting until we get all required data
      if (loadingWidgetChartData || maxBarWidgetDataLoading) {
        return
      }

      const isDataAvailable = widgetChartData?.data?.some(
        (x) => x?.isDataAvailable
      )

      if (
        widgetChartData &&
        widgetChartData?.data?.length > 0 &&
        isDataAvailable
      ) {
        const orbData = widgetChartData?.data[0] || {}
        formatGaugeChartData(orbData)
      }
    } catch (error) {}
  }, [widgetChartData, loadingWidgetChartData, maxBarWidgetData])

  const initializeData = async () => {
    const widgetInfo = {
      comparePointTo: ':none',
      chartType: 'Line',
      interval: 1440,
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: selectedAggrValue,
      buildingId: widgetDetails?.buildingId,
      propertiesList: [widgetDetails?.property]
    }
    if (isValidUOMForGetChartData(widgetDetails?.unitsOfMeasure)) {
      widgetInfo["uomDetails"] = { [widgetDetails?.property]: widgetDetails?.unitsOfMeasure }
    }
    getWidgetChartData(widgetInfo)
  }

  return loadingWidgetChartData ? (
    <Spinner />
  ) : gaugeData && gaugeData?.pointer?.value ? (
    <>
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName={widgetDetails?.equipmentName}
        equipmentType={widgetDetails?.equipmentType}
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDiscription}
        widgetProperty={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      <GaugeChart data={gaugeData} />
      <WidgetChartFooterComponent
        aggregatorName={selectedAggrValue}
        selectedPeriod={selectedPeriod}
        comparisonBar={true}
        aggragatorInterval={dataAggregation}
      />
    </>
  ) : (
    <NoWidgetDataAvailable
      buildingName={widgetDetails?.buildingName}
      equipmentName={widgetDetails?.equipmentName}
      equipmentType={widgetDetails?.equipmentType}
      aggregatorName={selectedAggrValue}
      selectedPeriod={selectedPeriod}
      dataAggregation={dataAggregation}
      widgetType="icon-linewidget"
      equipmentDetails={widgetDetails?.equipmentList}
      widgetDescription={widgetDetails?.widgetDiscription}
      timeZone={widgetDetails?.timeZone}
      accountName={widgetDetails?.accountName}
    />
  )
}
