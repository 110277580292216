import styled from 'styled-components'

import { createFormControlStyle } from '../form-control'
import { scrollbar } from '../common'
import CheckboxInput from '../../components/Checkbox'
import FormControl from '../../components/FromControl'
import Option from '../../components/Option'

const dropDwonIconStyles = createFormControlStyle({ icon: true })

export const FormControlRoot = styled(FormControl)`
  .with-tags {
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    height: ${({ tagContainerHeight, makeTagContainerAutoHeight }) =>
      makeTagContainerAutoHeight ? 'auto' : tagContainerHeight ?? '72px'};
    overflow: auto;
    ${({ disabled }) => disabled && ' opacity: 0.4;'}
    ${scrollbar}
  }
`

export const SearchOption = styled(Option)`
  font-size: 14px;
  font-style: italic;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  input {
    color: #707070;
  }
`

export const NoResultsMatchOption = styled(Option)`
  color: #555555;
  font-size: 14px;
  ${(props) => props.fullWidth && `width:${props.containerWidth || '100%'}`}
`

export const SelectAllOption = styled(Option)`
  align-items: center;
  border: 1px solid #d4d4d4;
  border-left: none;
  border-right: none;
  display: inline-flex;
  justify-content: space-between;
  padding: 0;
  position: sticky;
  top: 54px;
  left: 0;
  right: 0;
  z-index: 2;
`

export const SelectAllCheckbox = styled(CheckboxInput)`
  display: flex;
  flex: 1;
  padding: 10px;
  align-items: center;
  font-size: 16px;
`

export const ViewCount = styled.span`
  color: #707070;
  font-size: 14px;
  padding: 10px;
`

export const Tag = styled.span`
  background: #206fcf;
  border-radius: 12px;
  color: #ffffff;
  font-size: 14px;
  padding: 4px 8px;
  text-align: center;
`

export const Placeholder = styled.span`
  align-self: center;
`

export const ListItem = styled(Option)`
  padding-left: 0;
  white-space: nowrap;
`

export const IconRoot = styled.div`
  ${dropDwonIconStyles}
  padding: 0 12px;
  align-self: center;
  ${({ disabled }) => disabled && ' opacity: 0.4;'}
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
`
