import { Block } from 'src/components/layouts'
import Icon from 'src/denali-ui/components/Icon/index'
import { WarningContainer } from '../styles'
import COLORS from 'src/components/legacy/common/colors.json'
import { TranslateComponent } from '../../../common/translations'
import {
  VALUE_ASSUMPTION_DEFAULT,
  KPIAPINames
} from '../AssumptionValues/helpers'
import {
  aggregateDataByItemStatus,
  aggregateDataByKPI,
  getCalculationFormulas
} from '../consultation-blocks/chart-image/common'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import { Pattern } from "src/chart-library/Charts/ActionTracking/Pattern"

const legend = {
  clickable: false
}

export const dataMapped = (opportunityForGraph) => {
  return {
    id: opportunityForGraph?.id,
    name: opportunityForGraph?.title,
    type: opportunityForGraph?.type || 'opportunity',
    creationDate: opportunityForGraph?.creationDate,
    proposalDate: null,
    lastModifiedDate: opportunityForGraph?.updatedAt,
    energySavings: {
      savings: {
        value: opportunityForGraph?.energySavings,
        tag: opportunityForGraph?.energySavingsUnit,
        enabled: opportunityForGraph?.energySavingsDisplay,
        __typename: 'Savings'
      },
      costToImplement: {
        value: 0,
        enabled: opportunityForGraph?.energySavingsDisplay,
        __typename: 'CostToImplement'
      },
      lifecycle: {
        value: '0',
        tag: 'years',
        __typename: 'Lifecycle'
      },
      __typename: 'CostSavings'
    },
    kpi: [
      {
        custom: null,
        name: 'energyUsage',
        priority: opportunityForGraph?.impactEnergy,
        value: opportunityForGraph?.impactEnergyDisplay,
        savings: Number(opportunityForGraph?.impactEnergyValue),
        timePeriod: opportunityForGraph?.impactEnergyValueType,
        __typename: 'KPI'
      },
      {
        custom: null,
        name: 'performance',
        priority: opportunityForGraph?.impactPerformance,
        value: opportunityForGraph?.impactPerformanceDisplay,
        savings: Number(opportunityForGraph?.impactPerformanceValue),
        timePeriod: opportunityForGraph?.impactPerformanceValueType,
        __typename: 'KPI'
      },
      {
        custom: null,
        name: 'reliability',
        priority: opportunityForGraph?.impactReliability,
        value: opportunityForGraph?.impactReliabilityDisplay,
        savings: Number(opportunityForGraph?.impactReliabilityValue),
        timePeriod: opportunityForGraph?.impactReliabilityValueType,
        __typename: 'KPI'
      },
      {
        custom: null,
        name: 'comfort',
        priority: opportunityForGraph?.impactComfort,
        value: opportunityForGraph?.impactComfortDisplay,
        savings: Number(opportunityForGraph?.impactComfortValue),
        timePeriod: opportunityForGraph?.impactComfortValueType,
        __typename: 'KPI'
      },
      {
        custom: null,
        name: 'compliance',
        priority: opportunityForGraph?.impactCompliance,
        value: opportunityForGraph?.impactComplianceDisplay,
        savings: Number(opportunityForGraph?.impactComplianceValue),
        timePeriod: opportunityForGraph?.impactComplianceValueType,
        __typename: 'KPI'
      },
      {
        custom: 1,
        name: opportunityForGraph?.impactCustom1Name,
        priority: opportunityForGraph?.impactCustom1,
        value: opportunityForGraph?.impactCustom1Display,
        savings: Number(opportunityForGraph?.impactCustom1Value),
        timePeriod: opportunityForGraph?.impactCustom1ValueType,
        __typename: 'KPI'
      },
      {
        custom: 2,
        name: opportunityForGraph?.impactCustom2Name,
        priority: opportunityForGraph?.impactCustom2,
        value: opportunityForGraph?.impactCustom2Display,
        savings: Number(opportunityForGraph?.impactCustom2Value),
        timePeriod: opportunityForGraph?.impactCustom2ValueType,
        __typename: 'KPI'
      }
    ],
    history: [
      {
        status: 'validation',
        createdByTimestamp: '2022-11-01T17:38:25.888Z',
        __typename: 'HistoryStatus'
      }
    ],
    status: opportunityForGraph?.status,
    visibleToCustomer: opportunityForGraph?.isVisible,
    __typename: 'OpportunityItemWithHistory'
  }
}

/**
 *
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} acc  we will checking if acc is in range of startDate and endDate
 */
export const isDateInBW = (startDate, endDate, acc) => {
  const accTime = new Date(acc).getTime()
  const from = new Date(startDate).getTime()
  const to = new Date(endDate).getTime()
  return accTime >= from && accTime <= to
}

export const initialKpiData = (opportunityListData, startDate, endDate) => {
  opportunityListData = opportunityListData
    .filter((it) => it?.creationDate)
    .filter((it) => {
      return isDateInBW(startDate, endDate, it?.creationDate)
    })

  const frameData = []
  const frameData1 = []

  opportunityListData
    ?.filter(
      (item) =>
        item?.status === 'onSiteResolved' ||
        item?.status === 'remoteResolved' ||
        item?.status === 'complete' ||
        item?.status === 'validation'
    )
    ?.forEach((item) => {
      const kpiDataObj = dataMapped(item);
      frameData?.push(kpiDataObj)
    })

  opportunityListData?.forEach((item) => {
    const kpiDataObj =  dataMapped(item) ;
    

    frameData1?.push({ ...kpiDataObj, ...item })
  })
  
  return { whatWeSaved: frameData, whatWeHaveDone: frameData1 }
}

export const formatAssumptionValues = (assumptionValues) => {
  try {
    const defaultValuesFormat = VALUE_ASSUMPTION_DEFAULT()

    const modifiedKPI = defaultValuesFormat?.kpi?.map((kpiDetails) => {
      if (kpiDetails?.custom === 1) {
        kpiDetails.name = '1'
      }
      if (kpiDetails?.custom === 2) {
        kpiDetails.name = '2'
      }
      const kpiAPINames = KPIAPINames[kpiDetails?.name];
      kpiDetails.high.value = assumptionValues[kpiAPINames?.Values?.high]
      kpiDetails.medium.value = assumptionValues[kpiAPINames?.Values?.medium]
      kpiDetails.low.value = assumptionValues[kpiAPINames?.Values?.low]
      kpiDetails.high.timePeriod =
        assumptionValues[kpiAPINames?.timePeriod?.high]
      kpiDetails.medium.timePeriod =
        assumptionValues[kpiAPINames?.timePeriod?.medium]
      kpiDetails.low.timePeriod = assumptionValues[kpiAPINames?.timePeriod?.low]
    })
    defaultValuesFormat.goalAmount = assumptionValues?.goalAmount
    defaultValuesFormat.avoidedTruckRollAmount =
      assumptionValues?.avoidedTruckRollAmount
    return { ...defaultValuesFormat, ...modifiedKPI }
  } catch (error) {
    // for now we are sending it empty object but we need to take care of this incase if graphql fails
    return undefined
  }
}

export const formatedData1 = (assumptionValues) => [
  {
    key: 'energyUsage',
    name: 'Energy',
    color: '#61ae34',
    stackBy: 'props',
    data: [],
    legend
  },
  {
    key: 'performance',
    name: 'Performance',
    color: '#00aeef',
    stackBy: 'props',
    data: [],
    legend
  },
  {
    key: 'reliability',
    name: 'Reliability',
    color: '#3a77b3',
    stackBy: 'props',
    data: [],
    legend
  },
  {
    key: 'comfort',
    name: 'Comfort',
    color: '#2c10eb',
    stackBy: 'props',
    data: [],
    legend
  },
  {
    key: 'compliance',
    name: 'Compliance',
    color: '#7340a4',
    stackBy: 'props',
    data: [],
    legend
  },
  {
    key: 'custom',
    name: 'Custom',
    color: '#bf5de6',
    stackBy: 'props',
    data: [],
    legend
  },
  {
    name: 'Avoid Truck Rolls',
    stackBy: 'props',
    legend: {
      clickable: false,
      iconProps: {
        fill: '#367326'
      }
    },
    data: [
      {
        value: assumptionValues?.avoidedTruckRollAmount,
        color: '#367326'
      },
      0,
      0
    ]
  }
]

export const getChartData = ({
  data,
  opportunityListData,
  issuesListData,
  renderKpiData,
  rangeFrom,
  rangeTo,
  assumptionValues
}) => {
  let result = null
  if (renderKpiData) {
    const calculationFormulas = getCalculationFormulas(renderKpiData)
    result = aggregateDataByKPI({
      data: initialKpiData(
        [...opportunityListData, ...issuesListData],
        rangeFrom,
        rangeTo
      )?.whatWeSaved,
      startDate: moment(rangeFrom).format('MM-DD-YYYY'),
      endDate: moment(rangeTo).format('MM-DD-YYYY'),
      calculationFormulas
    })
    const data1 = cloneDeep(data)
    const truckVal = data1[data1.length - 1]?.data[0]
    truckVal.value = result?.truckRolls

    const rr = data1?.map((item) => {
      return result?.kpi?.hasOwnProperty(item?.key)
        ? { ...item, data: [0, result?.kpi?.[item?.key], 0] }
        : { ...item }
    })
    const formatedGoalData = {
      goal: assumptionValues?.goalAmount,
      total: result?.totalSavings
    }
    return result ? { chartData: rr, formatedGoalData } : null
  }
  return null
}

export const chartConfigActionTracking = {
  canvas: {
    canvasDetails: {
      0: {
        renderAreaShade: {
          fill: '#d9d2c3',
          opacity: '0.2'
        }
      }
    }
  },
  x: [
    {
      key: 'x',
      scale: {
        props: {
          type: 'scaleBand'
        },
        scaleSettings: {
          paddingInner: 0.4,
          paddingOuter: 0.15
        },
        categories: [
          'FINDINGS/ISSUES FOUND',
          'FINDINGS/ISSUES RESOLVED',
          'OPPORTUNITIES IDENTIFIED',
          'OPPORTUNITIES IMPLEMENTED'
        ]
      },
      axis: {
        visibleAt: ['bottomBottom']
      }
    }
  ],
  y: [
    {
      key: 'y',
      scale: {
        props: {
          type: 'scaleLinear'
        }
      },
      axis: {
        hideTicks: true,
        hideLabel: true,
        visibleAt: ['leftLeft']
      }
    }
  ],
  series: {
    customData: {
      'FINDINGS/ISSUES FOUND': {
        color: COLORS.CHART_GREY,
        hoverColor: COLORS.TBA_DARKER_GREY,
        title: 'FOUND',
        data: []
      },
      'FINDINGS/ISSUES RESOLVED': {
        color: COLORS.TBA_DARK_GREEN,
        hoverColor: COLORS.TBA_GREEN,
        title: 'RESOLVED',
        data: []
      },
      'OPPORTUNITIES IDENTIFIED': {
        color: COLORS.CHART_GREY,
        hoverColor: COLORS.TBA_DARKER_GREY,
        title: 'IDENTIFIED',
        data: []
      },
      'OPPORTUNITIES IMPLEMENTED': {
        color: COLORS.TBA_DARK_GREEN,
        hoverColor: COLORS.TBA_GREEN,
        title: 'IMPLEMENTED',
        data: []
      }
    }
  },
  width: '100%',
  height: 425
}

export const getActionChartData = (
  data,
  renderKpiData,
  opportunityListData,
  issuesListData,
  rangeFrom,
  rangeTo
) => {
  let result = null
  if (renderKpiData) {
    const calculationFormulas = getCalculationFormulas(renderKpiData)
    result = aggregateDataByItemStatus({
      data: initialKpiData(
        [...opportunityListData, ...issuesListData],
        rangeFrom,
        rangeTo
      )?.whatWeHaveDone,
      startDate: moment().format('MM-DD-YYYY'),
      endDate: moment().format('MM-DD-YYYY'),
      calculationFormulas
    })
    const data1 = cloneDeep(data)
    const {
      findingsFound,
      findingsResolved,
      opportunitiesIdentified,
      opportunitiesImplemented
    } = result || {}
    findingsFound?.forEach((item) => {
      data1?.series?.customData['FINDINGS/ISSUES FOUND']?.data.push({...item, ...(!item?.isVisible && {Pattern : Pattern})})
    })

    findingsResolved?.forEach((item) => {
      data1?.series?.customData['FINDINGS/ISSUES RESOLVED']?.data.push({...item, ...(!item?.isVisible && {Pattern : Pattern})})
    })

    opportunitiesIdentified?.forEach((item) => {
      data1?.series?.customData['OPPORTUNITIES IDENTIFIED']?.data.push({...item, ...(!item?.isVisible && {Pattern : Pattern})})
    })

    opportunitiesImplemented?.forEach((item) => {
      data1?.series?.customData['OPPORTUNITIES IMPLEMENTED']?.data.push({...item, ...(!item?.isVisible && {Pattern : Pattern})})
    })
    return result ? { chartActionData: data1 } : null
  }
  return null
}

export const Error_Message_KeyMatricsChart = (
  <div>
    <Block display="flex" justify="center">
      <WarningContainer>
        <Icon
          name="Warning"
          width="25px"
          height="25px"
          margin="0px 10px"
          color={COLORS.RED}
        />
        <p>
          {
            <TranslateComponent>
              The 'What We've Saved' chart uses the Value Assumptions for
              estimation. Please set up the Value Assumptions to enable this
              chart.
            </TranslateComponent>
          }
        </p>
      </WarningContainer>
    </Block>
  </div>
)

export const Error_Message_ActionTackingChart = (
  <div>
    <Block display="flex" justify="center">
      <WarningContainer>
        <Icon
          name="Warning"
          width="25px"
          height="25px"
          margin="0px 10px"
          color={COLORS.RED}
        />
        <p>
          {
            <TranslateComponent>
              The 'What We've Done' chart uses the Value Assumptions for
              estimation. Please set up the Value Assumptions to enable this
              chart.
            </TranslateComponent>
          }
        </p>
      </WarningContainer>
    </Block>
  </div>
)
export const Error_Message_OpportunityAndIssues_Missing = (
  <div>
    <Block display="flex" justify="center">
      <WarningContainer>
        <Icon
          name="Warning"
          width="25px"
          height="25px"
          margin="0px 10px"
          color={COLORS.RED}
        />
        <p>
          {
            <TranslateComponent>
              "No Findings or Opportunities are logged between the start and end
              dates for this consultation."
            </TranslateComponent>
          }
        </p>
      </WarningContainer>
    </Block>
  </div>
)

export const checkActionChartLength = (chartActionData) => {
  let flag = false
  const customData = _get(chartActionData, 'series.customData', {})
  Object.keys(customData)?.forEach((key) => {
    const data = _get(customData, `${key}.data`, [])
    const dataLength = data.length
    if (dataLength > 0) {
      flag = true
    }
  })
  return flag
}

export const getConsultationTestName = (id) => `Consultation${id ? `_${id}` : ""}`
