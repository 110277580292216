
import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'
export const screen_max_extra_small_max_width= "633px";
export const screen_max_small_max_width= "913px";

export const ConsultationToggleBoxSection = styled.section`
	border-radius: 3px;

	@media (max-width: ${screen_max_extra_small_max_width}) {
		margin: 20px 10px;
      }
	& .consultation-toggle-header {
		display: flex;
		justify-content: space-between;
		min-height: 46px;
		padding: 20px 20px 8px;
		border-bottom: 1px solid ${colors.TBA_LIGHTER_GREY};

		// @include screen-max-extra-small {
		// 	flex-direction: column;
		// }

		@media (max-width: ${screen_max_extra_small_max_width}) {
			flex-direction: column;
      	}

		& .toggle-horizontal input:checked + div + span {
			color: black
		}
	}

	& .consultation-label {
		display: flex;
		align-items: center;

		> .icon-expand {
			align-self: center;
			line-height: 0.8;
			margin-right: 5px;
			transition-duration: 400ms;
			transition-property: transform;
			transform: rotate(0);
			cursor: pointer;
		}

		input {
			width: 100%;
		}
		@media (max-width: ${screen_max_extra_small_max_width}) {
			margin-bottom: 7px;
      	}
		// @include screen-max-extra-small {
		// 	margin-bottom: 7px;
		// }
	}

	& .consultation-body {
		margin: 0;
		padding: 0;
		border: 0;
		transition: all 0.4s ease;

		& .consultation-body-inner {
			padding: 20px;
			max-width: 1220px;

			@media (max-width: ${screen_max_extra_small_max_width}) {
				padding: 8px 10px;
      		}
			// @include screen-max-extra-small {
			// 	padding: 8px 10px;
			// }

			& .chart-wrapper {
				min-height: 404px;

				& .chart {
					& .what-we-have-done {
						& .legend {
							& .legend-item {
								foreignObject {
									input[type=checkbox] {
										opacity: 1 !important;
										cursor: not-allowed;
										width: 16px;
										top: -2px;
									}
								}
							}
						}
					}
				}
			}

			& .upload-bounding-area,
			& .section-spinner {
				height: 404px;
			}

			& .note {
				font-style: italic;
				color: ${colors.TBA_DARKER_GREY};
			}
		}

		& .pie-chart-styles {
			display: flex;
			align-items: center;
			justify-content: space-around; 
		}

		& .toolbar {
			justify-content: start;
			margin: 0;
		}
	}

	&.expanded {
		& .consultation-label {
			> .icon-expand {
				transform: rotate(180deg);
			}
		}
	}

	& .consultation-title-block {
		position: relative;
		padding-right: 8px;
		min-width: 214px;
		@media (max-width: ${screen_max_extra_small_max_width}) {
			min-width: 160px;
      		}

		label {
			margin-left: 9px;
		}
	}

	& .rdw-editor-main {
		color: ${colors.TBA_DARKER_GREY};
	}
`


