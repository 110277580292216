
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { useSelector } from 'react-redux'
import { selectUserInfo } from 'src/redux/slicers/appData'
import 'react-phone-number-input/style.css'
import Modal from 'src/components/legacy/components/modal/modal'
import { useAlarmContext } from './AlarmContextProvider'
import {
  UPDATE_SUBSCRIBER,
  GET_BUILDING_BY_ID,
  DELETE_SUBSCRIBER
} from './graphql'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import { useEffect, useState } from 'react'
import Spinner from 'src/components/Spinner'
import { Block } from 'src/components/layouts'
import { AlarmLabel, PriorityInput, IconButton } from './styles'
import translate, { TranslateComponent } from 'src/common/translations'
import { Tag } from 'src/denali-ui/components/Tag'
import { SAVE_SUBSCRIBER } from './graphql'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { useTranslation } from 'react-i18next'
import { EVENT_CLASSES } from './constants'
import {getCriteriaType,isNaxCriteriaType,isNaxType,isSCGType,isConnectivityModule,isSelectedDeviceNax} from './helpers'


const SetNotification = ({ buildingId }) => {
  const {
    setMode,
    selectedSubscribers,
    getNotifications,
    setSelectedSubscribers,
    testName,
    subscribers,
    subscribersRawData
  } = useAlarmContext()
  const [priority, setPriority] = useState<any>([
    {
      value: ''
    }
  ])
  const { t } = useTranslation()
  const user = useSelector(selectUserInfo)
  const [loading, setLoading] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState(null)
  const [selectedEventCategories, setSelectedEventCategories] = useState({})
  const [events, setEvents] = useState([])
  const [dialogTypes, setDialogTypes] = useState(null)
  const { onSubmit: updateSubscriber } = useMutation({
    query: UPDATE_SUBSCRIBER,
    dataPath: 'data.updateNotificationSetting',
    onError: (error: any) => {
      console.log(error)
      setLoading(false)
    }
  })

  const { onSubmit: deleteSubscriber } = useMutation({
    query: DELETE_SUBSCRIBER
  })

  useEffect(() => {
    if (selectedDevice) {
      const device = deviceData.find((_device) => _device.id === selectedDevice)
      let _events = device.eventCategories
        ? Object.values(JSON.parse(device.eventCategories))
        : events
      if (isSCGType(device.type) || isConnectivityModule(device.type)) {
        _events = [...EVENT_CLASSES.map((e,index) => ({"name":e,"key":index + 1}))]
      }
      setEvents(_events)
      if (isNaxType(device.type)) {
        const subscibedDevice = selectedEventCategories?.[device.id]
        if (subscibedDevice) {
          setPriority(subscibedDevice.eventsSelected.map((e) => ({ value: e })))
        }
      }
    }
  }, [selectedDevice])

  useEffect(() => {
    if (selectedSubscribers?.length === 1) {
      const selectedSubcriberExistingDetails = subscribers.find(
        (sub) => sub.contactId === selectedSubscribers[0]?.contactId
      )
      if (selectedSubcriberExistingDetails) {
        const data = selectedSubcriberExistingDetails.subscribedToObj?.reduce(
          (acc, subVal) => {
            if (subVal.deviceId) {
              acc[subVal.deviceId] = {
                deviceId: subVal.deviceId,
                isNaxCriteriaType: isNaxCriteriaType(subVal.criteriaType),
                eventsSelected: [
                  ...(acc[subVal.deviceId]?.eventsSelected || []),
                  ...subVal.priorities?.split(',')
                ],
                type: subVal.deviceData.type,
                device_category: [
                  ...(acc[subVal.deviceId]?.device_category || []),
                  ...subVal.priorities
                    ?.split(',')
                    ?.reduce((acc2, val, index) => {
                      acc2.push({
                        displayName: `${
                          subVal.deviceData?.name || subVal.deviceData?.serial
                        } - ${
                          isSCGType(subVal.deviceData.type) || isConnectivityModule(subVal.deviceData.type) ? 
                          (!isNaN(parseInt(val)) ? EVENT_CLASSES[parseInt(val) - 1 ] : val) : val
                        }`,
                        eventName: isNaxType(subVal.deviceData.type)
                          ? index
                          :  val
                      })
                      return acc2
                    }, [])
                ]
              }
            }
            return acc
          },
          {}
        )
        setSelectedEventCategories(
          Object.values(data).reduce((acc, d: any): any => {
            d.eventsSelected?.length > 0 && (acc[d.deviceId] = d)
            return acc
          }, {})
        )
      }
    }
  }, [subscribers])

  const { data: deviceData } = useQuery({
    query: GET_BUILDING_BY_ID,
    variables: { id: buildingId },
    errorPolicy: 'all',
    dataPath: 'data.getBuilding.devices.items'
  })

  const optionsTranslated = {
    setAlarm: translate('Set Alarm Notifications'),
    // save: translate('Save'),
    // cancel: translate('Cancel'),
    noResult: translate('No results. Change or clear your text above.'),
    selectOne: translate('Select One or More'),
    selectDevice : translate("Select a device"),
    alarmType : translate("Find alarm Type"),
    selectAll : translate("Select All")
  }

  const handleSetNotification = () => {
    setLoading(true)
    if (selectedSubscribers?.length === 1) {
      Promise.all(
        selectedSubscribers.map((subscribe: any) => {
          return Promise.all(
            Object.values(selectedEventCategories).map((eventCat: any) => {
              const existingNotificationSetting = subscribersRawData.find(
                (sub) =>
                  sub.deviceId === eventCat.deviceId &&
                  sub.contactId === subscribe?.contactId
              )
              if (existingNotificationSetting?.id) {
                if (
                  !eventCat.eventsSelected ||
                  eventCat.eventsSelected.length === 0
                ) {
                  return deleteSubscriber({
                    input: { id: existingNotificationSetting.id }
                  })
                } else {
                  return updateSubscriber({
                    input: {
                      contactId: existingNotificationSetting.contactId,
                      id: existingNotificationSetting.id,
                      buildingId,
                      deviceId: eventCat.deviceId,
                      settings: [
                        {
                          criteriaType: getCriteriaType(eventCat.type),
                          nStatus: true,
                          nType: 'email',
                          nPriority: eventCat.eventsSelected
                        }
                      ]
                    }
                  })
                }
              } else {
                return saveSubscriber({
                  input: {
                    contactId: subscribe?.contactId,
                    buildingId,
                    deviceId: eventCat.deviceId,
                    userId: user.id,
                    type: 'alarm',
                    settings: [
                      {
                        criteriaType: getCriteriaType(eventCat.type),
                        nStatus: true,
                        nType: 'email',
                        nPriority: eventCat.eventsSelected
                      }
                    ]
                  }
                })
              }
            })
          ).then((res) => res)
        })
      ).then(
        () => {
          setSelectedSubscribers([])
          getNotifications()
          setMode(null)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    } else {
      const description = (
        <>
          <TranslateComponent>
          You will be updating the alarm notifications for the following
          subscribers.
          </TranslateComponent>
          <ul>
            {selectedSubscribers.map((subscriber) => (
              <li key={subscriber.id}>
                {subscriber.firstName
                  ? `${subscriber.firstName}  ${subscriber.lastName} `
                  : ''}
              </li>
            ))}
          </ul>
          <TranslateComponent>
          This will override any previously set alarm notifications, are you
          sure you want to proceed?
          </TranslateComponent>
        </>
      )
      setDialogTypes({
        confirm: {
          title: 'Confirm Alarm Notifications',
          description,
          handleConfirm: () => {
            Promise.all(
              selectedSubscribers.map((subscribe: any) => {
                const notificationToDelete = subscribersRawData.filter(
                  (sub) =>
                    sub.deviceId !== null &&
                    sub.contactId === subscribe?.contactId &&
                    !Object.values(selectedEventCategories).some(
                      (eventCat: any) => sub.deviceId === eventCat.deviceId
                    )
                )
                return Promise.all([
                  ...(notificationToDelete?.map((n) =>
                    deleteSubscriber({ input: { id: n.id } })
                  ) || []),
                  ...Object.values(selectedEventCategories).map(
                    (eventCat: any) => {
                      const existingNotificationSetting =
                        subscribersRawData.find(
                          (sub) =>
                            sub.deviceId === eventCat.deviceId &&
                            sub.contactId === subscribe?.contactId
                        )
                      if (existingNotificationSetting?.id) {
                        if (
                          !eventCat.eventsSelected ||
                          eventCat.eventsSelected.length === 0
                        ) {
                          return deleteSubscriber({
                            input: { id: existingNotificationSetting.id }
                          })
                        }
                        return updateSubscriber({
                          input: {
                            contactId: existingNotificationSetting?.contactId,
                            id: existingNotificationSetting?.id,
                            buildingId,
                            deviceId: eventCat.deviceId,
                            settings: [
                              {
                                criteriaType: getCriteriaType(eventCat.type),
                                nStatus: true,
                                nType: 'email',
                                nPriority: eventCat.eventsSelected
                              }
                            ]
                          }
                        })
                      } else {
                        return saveSubscriber({
                          input: {
                            contactId: subscribe?.contactId,
                            buildingId,
                            deviceId: eventCat.deviceId,
                            userId: user.id,
                            type: 'alarm',
                            settings: [
                              {
                                criteriaType: getCriteriaType(eventCat.type),
                                nStatus: true,
                                nType: 'email',
                                nPriority: eventCat.eventsSelected
                              }
                            ]
                          }
                        })
                      }
                    }
                  )
                ]).then((res) => res)
              })
            ).then(
              () => {
                setSelectedSubscribers([])
                getNotifications()
                setMode(null)
              },
              (error) => {
                console.log(error)
                setLoading(false)
              }
            )
          },
          handleCancel: () => {
            setLoading(false)
            setDialogTypes(null)
          }
        }
      })
    }
  }
  const closeNotification = () => {
    setMode(null)
  }

  const { onSubmit: saveSubscriber } = useMutation({
    query: SAVE_SUBSCRIBER,
    dataPath: 'data.createNotificationSetting'
  })

  const buildingDevicesOptions = (buildingDeviceData) => {
    return buildingDeviceData?.map((device) => ({
      key: device.id,
      label: device.name || device.serial
    }))
  }

  const getAlarmList = (events) => {
    return events?.map((event) => ({
      key: event.key || event.name,
      label: event.name
    }))
  }

  const onChangeAlarm = (e) => {
    if (e) {
      const device = deviceData.find((_device) => _device.id === selectedDevice)
      const eventsSelected = e?.map((a) => a.key)
      setSelectedEventCategories((state) => ({
        ...state,
        [selectedDevice]: {
          deviceId: selectedDevice,
          eventsSelected,
          type: device.type,
          device_category: e?.reduce((acc, val) => {
            acc.push({
              displayName: `${device.name || device.serial} - ${val.label}`,
              eventName: val.label
            })
            return acc
          }, [])
        }
      }))
    } else {
      setSelectedEventCategories([])
    }
  }

  const getSelectedEventsForDevice = (device) => {
    const _device: any = Object.values(selectedEventCategories)?.find(
      (e: any) => e.deviceId === device
    )
    return _device?.eventsSelected?.map((i) => isNaN(i) ?   i.trim() : parseInt(i)) || []
  }

  const onRemove = (event) => {
    const item = selectedEventCategories[event.selectedDevice]
    const eventName = event.eventName
    if (isNaxType(item.type)) {
      removePriority(eventName, event.selectedDevice)
      return
    }
    setSelectedEventCategories((state) => ({
      ...state,
      [event.selectedDevice]: {
        ...item,
        eventsSelected: item.eventsSelected.filter(
          (event) => event !== eventName
        ),
        device_category: item.device_category.filter(
          (dc) => dc.displayName !== event.title
        )
      }
    }))
  }

  const onChangeDevice = (e) => {
    if (e) {
      const selectedDevice = e?.key
      setSelectedDevice(selectedDevice)
      setEvents([])
    }
  }

  const addPriority = () => {
    const newPriority = [...priority]
    newPriority.push({
      value: ''
    })
    setPriority(newPriority)
  }
  const removePriority = (index: number, selectedDevice: string) => {
    const newPriority = [...priority]
    const priorityValue = newPriority[index]
    newPriority.splice(index, 1)
    setPriority(newPriority?.length === 0 ? [{ value: '' }] : newPriority)
    const item = selectedEventCategories[selectedDevice]
    const eventName = index
    setSelectedEventCategories((state) => ({
      ...state,
      ...(isNaxType(item.type) && {
        [selectedDevice]: {
          ...item,
          eventsSelected: item.eventsSelected.filter(
            (e) => e !== priorityValue?.value
          ),
          device_category: item.device_category.filter(
            (dc) => dc.eventName !== eventName
          )
        }
      })
    }))
  }
  const handlePriority = (value, index, selectedDevice) => {
    if (Number(value) < 0 || Number(value) > 255) value = ''
    const newPriority = [...priority]
    newPriority[index] = { value }
    setPriority(newPriority)
    const device = deviceData.find((_device) => _device.id === selectedDevice)
    setSelectedEventCategories((state) => ({
      ...state,
      [selectedDevice]: {
        deviceId: selectedDevice,
        type: device.type,
        eventsSelected: newPriority.reduce((acc, val) => {
          val.value && acc.push(val.value)
          return acc
        }, []),
        device_category: newPriority?.reduce((acc, val, index) => {
          val.value &&
            acc.push({
              displayName: `${device.name || device.serial} - ${val.value}`,
              eventName: index
            })
          return acc
        }, [])
      }
    }))
  }

  const modalConfig = {
    heading: "Set Alarm Notifications",
    buttons: [
      {
        text: "SAVE",
        handleClick: () => handleSetNotification(),
        type: 'confirm',
        disabled: loading
      },
      {
        text: "CANCEL",
        handleClick: closeNotification,
        type: 'cancel',
        disabled: loading
      }
    ],
    handleClose: closeNotification
  }
  return (
    <Modal testName={testName} {...modalConfig}>
      {loading && <Spinner />}
      <AlarmLabel>
        <strong>
          <TranslateComponent>
            Choose which of the following notifications to send to the selected
            subscriber(s)
          </TranslateComponent>
        </strong>{' '}
        <TranslateComponent>(Note: Alarm notifications WILL NOT show up in the Trane Connect
        notifications window at this time.)"
        </TranslateComponent>
      </AlarmLabel>
      <Block margin="0 0 8px 0" maxHeight="200px" overflow="scroll">
        {Object.values(selectedEventCategories)?.length > 0 &&
          Object.values(selectedEventCategories).map((val: any) => {
            return val.device_category.map((dc) => (
              <Tag
                key={dc}
                value={{
                  title: dc.displayName,
                  eventName: dc.eventName,
                  selectedDevice: val.deviceId
                }}
                onRemove={onRemove}
              />
            ))
          })}
      </Block>

      <Block padding="0 0 8px 0">
        <SelectrixWrapper
          className={
            'selectrix-wrapper selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select device-select'
          }
          placeholder={'— ' + optionsTranslated.selectOne + ' —'}
          inputPlaceholder={optionsTranslated.selectDevice}
          notFoundPrompt={optionsTranslated.noResult}
          searchable={true}
          searchIndex={false}
          searchBoxInside={true}
          options={buildingDevicesOptions(deviceData)}
          selectAllButton={true}
          defaultValue={selectedDevice}
          onChange={onChangeDevice}
        />
      </Block>
      {selectedDevice &&
        (isSelectedDeviceNax(deviceData,selectedDevice) ? (
          priority?.map((p: any, key: number) => (
            <PriorityInput key={key}>
              <label>{t('common:Priority')}</label>
              <input
                onChange={(ev) =>
                  handlePriority(ev.target.value, key, selectedDevice)
                }
                type="text"
                value={p.value}
              />
              <span>(0-255)</span>
              {key !== 0 && (
                <IconButton
                  color="#d52b1e"
                  onClick={() => removePriority(key, selectedDevice)}
                >
                  <i className={`icon icon-close-circle`} />
                </IconButton>
              )}
              {key === 0 || key !== priority.length ? (
                <IconButton color="#367326" onClick={addPriority}>
                  <i className={`icon icon-addcircle`} />
                </IconButton>
              ) : null}
            </PriorityInput>
          ))
        ) : (
          <SelectrixWrapper
            className={
              'selectrix-wrapper selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select alarm-select'
            }
            placeholder={'— ' + optionsTranslated.selectOne + ' —'}
            inputPlaceholder={optionsTranslated.alarmType}
            notFoundPrompt={optionsTranslated.noResult}
            searchable={true}
            searchIndex={false}
            searchBoxInside={true}
            stayOpen={true}
            selectAllButton={true}
            multiple={true}
            options={getAlarmList(events)}
            defaultValue={getSelectedEventsForDevice(selectedDevice)}
            onChange={onChangeAlarm}
            selectAll={optionsTranslated.selectAll}
          />
        ))}
      <StandardDialogs confirm={dialogTypes?.confirm} />
    </Modal>
  )
}
export default SetNotification
