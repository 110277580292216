import { Content } from 'src/components/layouts/'
import WidgetsList from './WidgetsList'
import { useBreakPoint } from 'src/hooks/breakPoint'
import ContainerWrapper from 'src/components/Container'
import PageHeader from '../../components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import WidgetTypePicker from './widget-picker/WidgetTypePicker'
import { useWidgetsContext } from './WidgetsContextProvider'
import Modal from 'src/components/legacy/components/modal/modal'
import { useTranslation } from 'react-i18next'
import WidgetCreate from './widget-library/WidgetCreate'
import { ACTIONS } from 'src/constants/actions'
import { HeaderButtons, IconButton } from './styles'
import translate, { TranslateComponent } from 'src/common/translations'
import { useState } from 'react'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

const WidgetsContainer = () => {
  const { width } = useBreakPoint()
  const {
    openWidgetPicker,
    setOpenWidgetPicker,
    setSelectedWidget,
    setWidgetBuilding,
    isOpenCreateWidget,
    setMode,
    setWidgetDetails,
    setWidgetBuildingDetails,
    setIsLocationLoading
  } = useWidgetsContext()
  const [t] = useTranslation()
  const[isTileView,setIsTileView] = useState(false)
  const[isWidgetLoading,setIsWidgetLoading] = useState(false)

  const optionsTranslated = {
    createNew: translate('Create new widget')
  }

  const openWidgetPickerModalConfig = {
    gray: true,
    removeFooter: true,
    className: 'wide-modal',
    heading: translate("Widget Builder"),
    handleClose: () => {
      setWidgetBuilding(null)
      setSelectedWidget(null)
      setOpenWidgetPicker(false)
      setWidgetDetails(null)
      setWidgetBuildingDetails(null)
      setIsLocationLoading(false)
    }
  }

  const handleAddNewWidget = () => {
    setOpenWidgetPicker(true)
    setMode(ACTIONS.ADD)
  }
  return (
    <>
      <ContainerWrapper sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <PageHeader
            pageTitle={<TranslateComponent>Widget Library</TranslateComponent>}
            titleTools={
              <HeaderButtons>
                <IconButton isGrey={false} onClick={() => setIsTileView(false)} className="view icon-listview" />
                <IconButton isGrey={true} onClick={() => setIsTileView(true)} className="view icon-gridview" />

                <Button
                  onClick={() => {
                    handleAddNewWidget()
                    trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_CREATE_WIDGET, {location:"Widget Library"})
                  }}
                  disabled={isWidgetLoading}
                >
                  {optionsTranslated.createNew}
                </Button>
              </HeaderButtons>
            }
          />
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <WidgetsList handleAddNewWidget={handleAddNewWidget} setIsWidgetLoading={setIsWidgetLoading} isTileView={isTileView}/>
          {openWidgetPicker ? (
            <Modal {...openWidgetPickerModalConfig}>
              <WidgetTypePicker />
            </Modal>
          ) : null}
          {isOpenCreateWidget && <WidgetCreate />}
        </Content>
      </ContainerWrapper>
    </>
  )
}

export default WidgetsContainer
