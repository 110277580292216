import PropTypes from 'prop-types'
import { SearchB } from 'src/components/legacy/components//search/UI/search-b'
import { trace } from 'console'
import translate from 'src/common/translations'

function BodyFilter(props) {
  const { searchText, handleChange } = props
  const onClear = () => {
    handleChange('')
  }
  const optionsTranslated = {
    start: translate('Start typing to search...'),
  }

  return (
    <div className="bn-body-filter">
      <SearchB
        value={searchText}
        placeholder={optionsTranslated.start}
        onChange={handleChange}
        onClear={onClear}
        onUnMount={onClear}
      />
    </div>
  )
}

BodyFilter.propTypes = {
  searchText: PropTypes.string,
  handleChange: PropTypes.func
}

BodyFilter.defaultProps = {
  searchText: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChange: () => {}
}

export default BodyFilter
