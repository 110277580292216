import styled from 'styled-components'

export const styledNewAccountDialog = styled.div`
  &.newOrganizationDialog {
    label,
    input {
      width: 100%;
    }

    .modal-container {
      .confirmationText {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        font-style: italic;
      }
    }
  }
`
