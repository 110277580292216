import { createSlice } from '@reduxjs/toolkit'
import { DashboardTypes, State } from '../types'
import { AppDispatch, TStore } from '../store'
import { GET_DASHBOARDS } from 'src/pages/dashboards/components/CreateDashboard/graphql'
import { API } from 'aws-amplify'
import { GET_DASHBOARD } from 'src/pages/dashboards/graphql'
import { booleanToString } from 'src/utils/commonMethods'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { getUserSettingByUserId  } from 'src/common/userSettingAPI'

export const initialValues = {
  name: '',
  Description: '',
  isDefault: '0',
  isPublic: '0',
  isKeyMetric: '0',
  widgetOrder: null
}

const initialState: DashboardTypes = {
  dashboards: {
    data: [],
    loading: false,
    error: false
  },
  showCreate: false,
  values: initialValues,
  stepType: 'perconfigured-dashboard',
  actionType: '',
  selectedDashboardId: '',
  dashboardIdForWidget: '',
  dashboardPath: '',
  dashboardValues: {
    data: null,
    error: false,
    loading: false
  },
  isDashboardWidgetAdded: false,
  isWidgetModalOpen: false
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboards: (state, action) => {
      const { type = 'Sucesss', data = [] } = action.payload
      switch (type) {
        case 'Loading':
          state.dashboards = {
            loading: true,
            data: [],
            error: false
          }
          break
        case 'Success':
          state.dashboards = {
            loading: false,
            data,
            error: false
          }
          break
        case 'Failure':
          state.dashboards = {
            loading: false,
            data: [],
            error: true
          }
          break
      }
    },
    setDashboardValues: (state, action) => {
      const { type = 'Sucesss', data = null } = action.payload
      switch (type) {
        case 'Loading':
          state.dashboardValues = {
            loading: true,
            data: null,
            error: false
          }
          break
        case 'Success':
          state.dashboardValues = {
            loading: false,
            data,
            error: false
          }
          break
        case 'Failure':
          state.dashboardValues = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setDashboardStep: (state, action) => {
      const { stepType } = action.payload
      state.stepType = stepType
    },
    setDashboard: (state, action) => {
      const { actionType, data, dashboardId = '' } = action.payload
      switch (actionType) {
        case 'Create':
          state.actionType = ''
          state.showCreate = true
          state.values = initialValues
          state.selectedDashboardId = ''
          state.stepType = 'perconfigured-dashboard'
          break
        case 'Copy':
        case 'Edit':
          state.actionType = actionType
          state.showCreate = true
          state.values = data
          state.selectedDashboardId = dashboardId
          state.stepType = 'blank-dashboard'
          break
        case 'Create-Blank':
          state.actionType = actionType
          state.showCreate = true
          state.values = initialValues
          state.selectedDashboardId = dashboardId
          state.stepType = 'blank-dashboard'
          break
        case 'Close':
          state.actionType = ''
          state.showCreate = false
          state.values = initialValues
          state.selectedDashboardId = ''
          state.stepType = 'perconfigured-dashboard'
          break
      }
    },
    setDashboardWidgetAddedState: (state, action) => {
      state.isDashboardWidgetAdded = action?.payload?.loading
    },
    setDashboardPath: (state, action) => {
      state.dashboardPath = action?.payload?.dashboardPath
    },
    setDashboardIdForWidget: (state, action) => {
      state.dashboardIdForWidget = action?.payload?.dashboardId
    },
    setWidgetModal: (state, action)=>{
      state.isWidgetModalOpen = action?.payload
    }
  }
})

export const {
  setDashboard,
  setDashboards,
  setDashboardStep,
  setDashboardValues,
  setDashboardWidgetAddedState,
  setDashboardPath,
  setDashboardIdForWidget,
  setWidgetModal
} = dashboardSlice.actions

export const getDashboards = () => {
  return async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch(
        setDashboards({
          type: 'Loading'
        })
      )
      const state: State = getState()
      const variables = {
        userId: state.appData.userInfo?.data?.id,
        limit: 100
      }
      if (state.appData?.userInfo?.data?.id) {
        // default dashboard data, get from user settings table
        // Since we have done all using dashboard table, we are calling and setting it here to have less impact
        const userDashboardSettings = await getUserSettingByUserId?.(
          state.appData.userInfo?.data?.id, 'Dashboard'
        )
        const userDefaultDashboardId = userDashboardSettings?.settings?.id
        
        const apiData: any = await API.graphql({
          query: GET_DASHBOARDS,
          variables
        })
        const data = apiData?.data?.listDashboardsByUser?.items?.map((item) => {
          const {
            name,
            Description = '',
            isKeyMetric,
            isPublic,
            id,
            keyMetricaccountId,
            keyMetricbuildingId,
            keyMetric1Display,
            keyMetric1Name,
            keyMetric1UoM,
            keyMetric2Display,
            keyMetric2Name,
            keyMetric2UoM,
            keyMetric3Display,
            keyMetric3Name,
            keyMetric3UoM,
            keyMetric4Display,
            keyMetric4Name,
            keyMetric4UoM,
            keyMetric5Display,
            keyMetric5Name,
            keyMetric5UoM,
            keyMetricComparedTo,
            keyMetricTimePeriod,
            widgetOrder,
            buildingDataCharts
          } = item

          const isDefault = id === userDefaultDashboardId ? "1" : "0"

          let data = {
            name,
            Description,
            isDefault,
            isKeyMetric: booleanToString(isKeyMetric),
            isPublic: booleanToString(isPublic),
            id,
            // keeping the user settings record id for dashboard here, so that we don't need to call in other places while update
            userSettingsId: isDefault === "1" ? userDashboardSettings?.id : null,
            widgetOrder,
            buildingDataCharts
          }
          if (keyMetricaccountId && keyMetricbuildingId) {
            data = {
              ...data,
              ...{
                accountId: keyMetricaccountId,
                buildingId: keyMetricbuildingId,
                keyMetricsStripList: [
                  {
                    keyMetric: keyMetric1Name,
                    uom: keyMetric1UoM,
                    isEnabled: keyMetric1Display
                  },
                  {
                    keyMetric: keyMetric2Name,
                    uom: keyMetric2UoM,
                    isEnabled: keyMetric2Display
                  },
                  {
                    keyMetric: keyMetric3Name,
                    uom: keyMetric3UoM,
                    isEnabled: keyMetric3Display
                  },
                  {
                    keyMetric: keyMetric4Name,
                    uom: keyMetric4UoM,
                    isEnabled: keyMetric4Display
                  },
                  {
                    keyMetric: keyMetric5Name,
                    uom: keyMetric5UoM,
                    isEnabled: keyMetric5Display
                  }
                ],
                keyMetricComparedTo,
                keyMetricTimePeriod
              }
            }
          }
          return data
        })
        const dashboardList: any = {
          dashboards: data
        }
        localStorage.setItem('dashboards', JSON.stringify(dashboardList))
        dispatch(
          setDashboards({
            type: 'Success',
            data
          })
        )
      }
    } catch {
      dispatch(
        setDashboards({
          type: 'Failure',
          data: []
        })
      )
    }
  }
}

export const getDashboard = (id: string) => {
  if (!id || id === 'undefined') return
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(
        setDashboardValues({
          type: 'Loading'
        })
      )
      const variables = {
        id
      }
      if (id) {
        const apiData: any = await API.graphql({
          query: GET_DASHBOARD,
          variables
        })
        const values = apiData?.data?.getDashboard
        if (values) {
          const {
            name,
            Description,
            isDefault,
            isKeyMetric,
            isPublic,
            id,
            widgetOrder,
            buildingDataCharts
          } = values
          const data = {
            name,
            Description,
            isDefault: booleanToString(isDefault),
            isKeyMetric: booleanToString(isKeyMetric),
            isPublic: booleanToString(isPublic),
            id,
            widgetOrder,
            buildingDataCharts
          }
          trackEvent(USER_EVENTS.DASHBOARDS.events.VIEW_DASHBOARD, {
            id: id,
            'enable key metrics': booleanToString(isKeyMetric)
              ? isKeyMetric
              : '',
            'is public': booleanToString(isPublic) ? isPublic : ''
          })
          dispatch(
            setDashboardValues({
              type: 'Success',
              data
            })
          )
        }
      }
    } catch {
      dispatch(
        setDashboardValues({
          type: 'Failure'
        })
      )
    }
  }
}

export const selectShowCreateDashboard = (state: TStore) =>
  state.dashboard.showCreate
export const selectDashboardStep = (state: TStore) => state.dashboard.stepType
export const selectDashboard = (state: TStore) => state.dashboard
export const selectDashboards = (state: TStore) => state.dashboard.dashboards
export const selectDashboardValues = (state: TStore) =>
  state.dashboard.dashboardValues
export const selectDashboardPath = (state: TStore) =>
  state.dashboard.dashboardPath
export const selectDashboardIdForWidget = (state: TStore) =>
  state.dashboard.dashboardIdForWidget
export const selectIsDashboardWidgetAdded = (state: TStore) =>
  state.dashboard.isDashboardWidgetAdded
export const setIsWidgetModalOpen = (state: TStore) =>
  state.dashboard.isWidgetModalOpen
