/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxisDiM } from '../../../chart-library/Utils/defaults'
import { useMemo } from 'react'
import Axis from '../../../chart-library/CommonComponents/AxisNative'
import Grid from '../../../chart-library/CommonComponents/Grids/Grid'
import { timeFormat } from 'd3'
import { yAxisVisibleTicks } from './axisTickValues'
import { calculateTickValuesFromDateRange, toDates } from './helpers'
import { useConfig } from './useConfig'

const yAxisticks = toDates(yAxisVisibleTicks)

export default function Axes() {
  const {
    xAxis,
    xScale,
    yAxis,
    yScale,
    canvas,
    scaleUpdatedAt,
    axisUpdatedAt,
    isInitialized
  } = useConfig()

  const [ticks, formatter] = useMemo(() => {
    if (!xScale) {
      return [[], null]
    }

    const domain = xScale.fn.domain()
    const start = domain[0]
    const end = domain[domain.length - 1]

    return calculateTickValuesFromDateRange(start, end)
  }, [xScale, axisUpdatedAt, scaleUpdatedAt])

  if (!isInitialized) {
    return null
  }

  return (
    <>
      <Axis
        tickSizeOuter={0}
        textProps={{
          breakLabelTextBy: ','
        }}
        dimention={AxisDiM.x}
        axisPosition={xAxis.type}
        axisScale={xScale.fn}
        xPosition={xAxis.x}
        yPosition={xAxis.y}
        lastUpdatedAt={scaleUpdatedAt + axisUpdatedAt}
        tickFormat={formatter as any}
        tickValues={toDates(ticks as any)}
        key="1"
      />

      <Axis
        tickSizeOuter={0}
        dimention={AxisDiM.y}
        axisPosition={yAxis.type}
        axisScale={yScale.fn}
        xPosition={yAxis.x}
        yPosition={yAxis.y}
        lastUpdatedAt={scaleUpdatedAt + axisUpdatedAt}
        tickValues={yAxisticks}
        tickFormat={timeFormat('%I:%M %p')}
        key="2"
      />
      <Grid
        scale={yScale.fn}
        width={canvas.width}
        tickValues={yAxisticks}
        includeAxis={true}
        opacity={0.2}
        strokeWidth={0.5}
        type={'y'}
        transform={`translate(${yAxis.x} ${yAxis.y + 3})`}
      />
    </>
  )
}
