import { setGlobalError } from '../redux/slicers/globalErrorSlice';
import store from '../redux/store';

export const stitchedAWSJSONQuery = async queryFunction => {
  return await queryFunction.then(res => {
    if (res.errors) {
      store.dispatch(setGlobalError({error: res.errors?.[0]?.message || "We have encountered an error. Please try again or contact support."}))
      throw new Error(res.errors?.[0]?.message || "We have encountered an error. Please try again or contact support.")
    }
    Object.keys(res.data).forEach(e => {
      if (typeof res.data[e] === "string") {
        // Likely an AWSJSON query so we will parse as so. If we do have string responses eventually, we may need to add a try catch here.
        const parsedResponse = JSON.parse(res.data[e])
        if (parsedResponse.statusCode >= 300) {
          store.dispatch(setGlobalError({error: parsedResponse.body}))
          throw new Error(parsedResponse.body)
        }
      } else {
        // We don't currently do anything for other types because errors are handled at a data level. See a few lined above. This is a placeholder if we need to.
      }
      
    })
    return res
  }).catch(res => {
    if (res.errors) {
      store.dispatch(setGlobalError({error: res.errors?.[0]?.message || "We have encountered an error. Please try again or contact support."}))
      throw new Error(res.errors?.[0]?.message || "We have encountered an error. Please try again or contact support.")
    }
  })
}



