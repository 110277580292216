

import { PageContainer, SorryMessage, MessageText, NavigationLink, MessageTextContainer, SpanBack } from "./style"
import { useNavigate, useLocation } from "react-router"
import { TranslateComponent } from "src/common/translations";
import { getBrowserHistory } from 'src/components/legacy/common/history'

const ResourceNotFound  = () => {

    const navigate = useNavigate();
    const userHistory = getBrowserHistory()

    const doesAnyHistoryEntryExist = userHistory

    const handleGoBack = () => {
        // If any histroy exists go back, otherwise back to home
        // User lands a page first time and he don't have access to the resource
        // This doesAnyHistoryEntryExist will be true
        if(doesAnyHistoryEntryExist){
            navigate(-2)
        } else {
            navigate("/")
        }
    }

    return (
        <PageContainer height={window.outerHeight-60}>
            <PageContainer>
                <SorryMessage>Sorry, You don't have access to this resource</SorryMessage>
                <MessageTextContainer>
                    <MessageText>Oops! This page doesn't exist</MessageText>
                     <MessageText>Please go <NavigationLink to="/">Home</NavigationLink></MessageText>
                    {/* <MessageText>Please go <SpanBack onClick={handleGoBack} >back</SpanBack> or <NavigationLink to="/">navigate home</NavigationLink></MessageText> */}
                </MessageTextContainer>
            </PageContainer>    
        </PageContainer>
    )
    
}


export default ResourceNotFound