import { createContext, useContext, useState } from 'react'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { ACTIONS, IModalActionType } from 'src/constants'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import { getInitialOpportunity } from './helpers'

const OpportunityContext = createContext<any>(null)

export const OpportunityContextProvider = ({ children }: any) => {
  const [mode, setMode] = useState(ACTIONS.VIEW)
  const { buildingId, organizationId: accountId } = getSearchParams()
  const [reloadEvents, setReloadEvents] = useState(false)
  const [modalType, setModalType] = useState<IModalActionType>('')
  const userInfo = useSelector(selectUserInfo)
  const currentDate = moment().format(DATE_FORMAT)
  const getUserName = () => {
    return `${userInfo?.lastName} ${userInfo?.firstName}`
  }
  const userName = getUserName()
  const [error, setError] = useState<{ isError: boolean; errorMsg: string }>({
    errorMsg: '',
    isError: false
  })
  const [opportunity, setOpportunity] = useState(
    getInitialOpportunity(accountId, buildingId, currentDate, userName)
  )
  const [isDeletedDone, setIsDeletedDone] = useState(false)
  const [opportunityId, setOpportunityId] = useState(null)
  const [opportunityInputValues, setOpportunityInputValues] = useState(null)
  const [dataList, setDataList] = useState([])
  const [filteredData, setFilteredData] = useState([])
  return (
    <OpportunityContext.Provider
      value={{
        mode,
        setMode,
        buildingId,
        accountId,
        reloadEvents,
        setReloadEvents,
        modalType,
        setModalType,
        setOpportunity,
        opportunity,
        isDeletedDone,
        setIsDeletedDone,
        opportunityId,
        setOpportunityId,
        currentDate,
        userName,
        opportunityInputValues,
        setOpportunityInputValues,
        dataList,
        setDataList,
        filteredData,
        setFilteredData,
        error,
        setError
      }}
    >
      {children}
    </OpportunityContext.Provider>
  )
}

export const useOpportunityContext = () => useContext(OpportunityContext)
