import moment from 'moment'
import { EquipmentTypeList } from 'src/pages/equipmentSetup/CreateEquipment/constants'

export const groupByAnalyticId = (settingsList) => {
  try {
    const testNamesAndAnalyticIds = []
    settingsList?.map((setting) => {
      const testNameIndex = testNamesAndAnalyticIds?.findIndex(
        (x) => x?.analyticId === setting?.analyticId
      )
      if (testNameIndex === -1) {
        const limitsAndParameter = []
        limitsAndParameter.push({ ...setting })
        testNamesAndAnalyticIds?.push({
          name: setting?.analyticId,
          analyticId: setting?.analyticId,
          equipmentType: setting?.familyId,
          limitsAndParameter: limitsAndParameter
        })
      } else {
        const limitsAndParameter =
          testNamesAndAnalyticIds[testNameIndex]?.limitsAndParameter || []
        limitsAndParameter.push({ ...setting })
        testNamesAndAnalyticIds[testNameIndex].limitsAndParameter =
          limitsAndParameter
      }
    })
    return testNamesAndAnalyticIds
  } catch (e) {
    console.log(e)
    return []
  }
}

export const formatSettingSummary = (settings) => {
  try {
    const settingsArray = []
    let equipmentsList = [
      { equipmentType: 'VRF-IDU', uiName: 'VRF Indoor Unit' },
      { equipmentType: 'VRF-ODU', uiName: 'VRF Outdoor Unit' },
      { equipmentType: 'VRF-System', uiName: 'VRF System' }
    ]

    for (const key in EquipmentTypeList) {
      const equipment = EquipmentTypeList[key]
      equipmentsList.push({
        equipmentType: equipment?.type,
        uiName: key
      })
    }
    const timeFormat = 'MMM DD, YYYY hh:mm A'
    for (const key in settings) {
      const testItem = settings[key] || {}
      const splitKeys = key.split('|')
      const displayName = splitKeys[2]?.replace('XX', '')
      const equipmentTypeUIName =
        equipmentsList?.find((x) => x?.equipmentType === splitKeys[0])
          ?.uiName || splitKeys[0]
      const settingItem = {
        key,
        name: splitKeys[1] || '',
        displayName: displayName,
        equipmentTypeUIName: equipmentTypeUIName,
        equipmentType: splitKeys[0],
        apiDisplayName: splitKeys[2],
        updatedAt: moment(testItem?.updatedAt).format(timeFormat),
        updatedBy: testItem?.updatedBy,
        isSuppressed: testItem?.is_suppressed === 1,
        isDefault: testItem?.is_default === 1
      }
      settingsArray.push(settingItem)
    }
    return settingsArray
  } catch (e) {
    return []
  }
}


export const formatLimits = () => {}
