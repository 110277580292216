import { Content, Container } from 'src/components/layouts'
import { useBreakPoint } from 'src/hooks/breakPoint'
import COLORS from 'src/components/legacy/common/colors.json'
import TextEditor from 'src/components/text-editor'
import _get from 'lodash/get'
import ImageUploader from 'src/components/imageuploader/imageuploader'
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import {
  ConsultationOpportunityError,
  DisableDiv,
  Input,
  OrganizationWrap,
  SectionHighlight,
  SectionToggleWrapper,
  SelectWrapper,
  TableWrapper,
  TextEditorWrap,
  ToggleButtonWrapperEnd,
  ToolbarWrapper,
  DateRangeContainer
} from '../styles'
import { TEXT_EDITOR_TOOLBAR_OPTIONS } from 'src/components/legacy/common/opportunity.js'
import Select from 'src/components/legacy/components/select/select'
import { ChangeEvent, useEffect, useState } from 'react'
import OpportunityListForConsultation from '../../opportunities/OpportunityListForConsultation'
import Highlights from '../consultation-blocks/highlights'
import { useTranslation } from 'react-i18next'
import {
  IMAGE_UPLOAD,
  MAX_LENGTH_OF_NAME,
  getVisualizationList,
  getVisualizationOptionList
} from '../helper'
import { useNavigate } from 'react-router'
import { ACTIONS } from 'src/constants'
import { OpportunityContextProvider } from 'src/pages/opportunities/OpportunityContextProvider'
import TextInput from 'src/components/legacy/components/modal-text-input'
import _keys from 'lodash/keys'
import WhatWeSaved from 'src/pages/documentsAndReports/WhatWeSaved'
import {
  Error_Message_KeyMatricsChart,
  Error_Message_ActionTackingChart,
  Error_Message_OpportunityAndIssues_Missing,
  formatedData1,
  getChartData,
  chartConfigActionTracking,
  getActionChartData,
  checkActionChartLength
} from './helperFunction'
import ActionTracking from 'src/chart-library/Charts/ActionTracking'
import { useQuery } from 'src/hooks/APIHooks'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { SEARCH_ISSUES_BY_BUILDING_IDS } from 'src/pages/opportunities/graphql'
import { getConsultationTestName } from './helperFunction'


const ConsultationBodySection = (props) => {
  const {
    setFieldValue,
    values,
    handleFormSubmit,
    errors,
    isSubmit,
    mode,
    location,
    assumptionValues,
    kpiData,
    editKpiData,
    buildingsList
  } = props || {}
  const { width } = useBreakPoint()
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [chartData, setChartData] = useState([])
  const [formatedGoalData, setFormatedGoalData] = useState({})
  const [chartActionData, setChartActionData] = useState([])
  const [opportunityListData, setOpportunityListData] = useState<any>([])
  const [issuesListData, setIssuesListData] = useState<any>([])
  const searchParams = getSearchParams()
  const buildingId = searchParams?.buildingId
  const organizationId = searchParams?.organizationId

  const { highlights } = values || {}

  const {
    theBigPictureDetails,
    rangeFrom,
    rangeTo
  } = values || {}

  const renderKpiData = kpiData || editKpiData;
  
  const onUpdateFields = (fields) => (values) => {
    setFieldValue(fields, values)
  }

  const {
    data: issuesFindingsList,
    refetch: refetchSearchIssuesFindingsList,
  } = useQuery({
    query: SEARCH_ISSUES_BY_BUILDING_IDS,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchIssues.items'
  })

  const buildingIds = (values?.building) || []

  useEffect(() => {
    if(buildingIds && buildingIds.length >0){
      const variables = {
        filter: {
          or: buildingIds?.map(id => {
            return {
              "buildingId": { "eq": id }
            }
          })
        },
        limit: 1000,
        sort: [
          {
            "direction": "asc",
            "field": "title"
          }
        ]
      }
    refetchSearchIssuesFindingsList(variables)
    }else {
      setIssuesListData([])
    }
  }, [buildingIds])

  useEffect(() => {
    setIssuesListData(
      mode === ACTIONS.EDIT ? issuesFindingsList
        ?.filter((it) => it?.opportunities?.items?.length === 0)
        ?.map((it) => ({ ...it, type: 'finding' })) || [] : 
      issuesFindingsList
        ?.filter((it) => (it?.opportunities?.items?.length === 0) && (it?.isVisible === true))
        ?.map((it) => ({ ...it, type: 'finding' })) || []
    );
  }, [issuesFindingsList]);

  const toolbar = () => (
    <ToolbarWrapper className="toolbar-buttons">
      <button
        className={`primary ${isSubmitting ? 'wait' : ''}`}
        disabled={isValid}
        onClick={handleFormSubmit}
      >
        {isSubmitting ? <div className="spinner" /> : t('common:Save')}
      </button>
      <button
        className="secondary"
        onClick={() => {
          navigate(-1)
        }}
      >
        {t('common:Cancel')}
      </button>
    </ToolbarWrapper>
  )
  
  const handleCheckBoxClick = (data) => {
    const items = selectedItems ?? []
    const index = items?.findIndex((i) => i?.key === data?.id)
    if (index === -1) {
      setSelectedItems([
        ...selectedItems,
        { key: data?.id, value: data?.title, buildingId }
      ])
    } else {
      setSelectedItems(items?.filter((f) => f?.key !== data?.id))
    }
  }

  useEffect(() => {
    setIsSubmitting(isSubmit)
  }, [isSubmit])

  useEffect(() => {
    setIsValid(_keys(props?.errors)?.length)
    setSelectedItems(values?.opportunityList ?? [])
  }, [props])

  useEffect(() => {
    if (selectedItems?.length) setFieldValue('opportunityList', selectedItems)
  }, [selectedItems])

  const pictureDetails =
    typeof theBigPictureDetails === 'string' && theBigPictureDetails
      ? JSON?.parse(theBigPictureDetails)
      : theBigPictureDetails
  const textContent = pictureDetails || ''

  const handleOpportunityListData = (data) => {
    setOpportunityListData(mode === ACTIONS.EDIT ? data : data?.filter((it)=> it?.isVisible === true))
  }

  useEffect(() => {
    /** Config For Key Metric Chart */
    const { chartData = [], formatedGoalData = {} } = 
          getChartData({data:formatedData1(assumptionValues),renderKpiData, rangeFrom, rangeTo,opportunityListData,issuesListData,assumptionValues}) || {}

    /** Config For Action Tracking Chart */
    const { chartActionData = [] } =
          getActionChartData(chartConfigActionTracking,renderKpiData,opportunityListData,issuesListData, rangeFrom, rangeTo) || {}
    setChartData(chartData)
    setFormatedGoalData(formatedGoalData)
    setChartActionData(chartActionData)
  }, [assumptionValues, renderKpiData, rangeFrom, rangeTo,opportunityListData,issuesListData])
  

  return (
    <Container
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      width={width}
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        background={COLORS.WHITE}
      >
        <OrganizationWrap sm-gap={10}>
          <div data-testid={getConsultationTestName('big-picture-wrapper')}>
            <TextInput
              labelText={`${'Section Title'}`}
              defaultValue={values?.sectionTitleBigPicture}
              name="sectionTitleBigPicture"
              placeholder="The Big Picture"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                setFieldValue(name, value)
              }}
              hasError={errors?.['sectionTitleBigPicture']}
              isRequired={true}
              errorMessage={errors?.['sectionTitleBigPicture']}
              maxLength={MAX_LENGTH_OF_NAME}
            />
          </div>
          <div />
          <SectionToggleWrapper>
            <label>{'Visible'}</label>
            <ToggleButtonHorizontal
              name="visibleToCustomer"
              checked={values?.theBigPictureDisplay}
              onChange={(e) =>
                setFieldValue('theBigPictureDisplay', e?.target?.checked)
              }
              testId={getConsultationTestName('big-picture-display-')}
            />
          </SectionToggleWrapper>
        </OrganizationWrap>
        <hr />
        <TextEditorWrap>
          {(theBigPictureDetails || theBigPictureDetails === null) && (
            <TextEditor
              content={textContent || ''}
              textEditorToolbarOptions={TEXT_EDITOR_TOOLBAR_OPTIONS}
              changeContent={onUpdateFields('theBigPictureDetails')}
              isStripPastedStyles={false}
            />
          )}
        </TextEditorWrap>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        background={COLORS.WHITE}
      >
        <div>
          <OrganizationWrap sm-gap={10}>
            <div data-testid={getConsultationTestName('high-light-title')}>
              <TextInput
                labelText={`${'Section Title'}`}
                defaultValue={values?.sectionTitleHighlight}
                name="sectionTitleHighlight"
                placeholder="Highlight Title"
                onChange={({
                  target: { value, name }
                }: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(name, value)
                }}
                hasError={errors?.['sectionTitleHighlight']}
                isRequired={true}
                errorMessage={errors?.['sectionTitleHighlight']}
                maxLength={MAX_LENGTH_OF_NAME}
              />
            </div>
            <div />
            <SectionToggleWrapper>
              <label>{'Visible'}</label>
              <ToggleButtonHorizontal
                name="visibleToCustomer"
                checked={values?.showHighlight}
                onChange={(e) =>
                  setFieldValue('showHighlight', e?.target?.checked)
                }
                testId={getConsultationTestName('high-light-')}
              />
            </SectionToggleWrapper>
          </OrganizationWrap>
          <hr />
          <SectionHighlight>
            <Highlights
              fieldName="impactText"
              highlights={highlights}
              onChange={(field, index, value) => {
                setFieldValue(`highlights`, field)
              }}
              errors={errors}
            />
          </SectionHighlight>
        </div>
      </Content>
      {/* image uploader sction ************************************************************************ */}
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        background={COLORS.WHITE}
      >
        <OrganizationWrap sm-gap={10} md-gap={20}>
          <div data-testid={getConsultationTestName('what-we-saved-title')}>
            <TextInput
              labelText={`${'Section Title'}`}
              defaultValue={values?.sectionTitleWhatWeHaveSaved}
              name="sectionTitleWhatWeHaveSaved"
              placeholder="What We've Saved"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                setFieldValue(name, value)
              }}
              hasError={errors?.['sectionTitleWhatWeHaveSaved']}
              isRequired={true}
              errorMessage={errors?.['sectionTitleWhatWeHaveSaved']}
              maxLength={MAX_LENGTH_OF_NAME}
            />
            <DateRangeContainer className='label-container'>
            <div className='label-dateRange'>Date Range</div>
            <div className='label-dateRange'>{`${rangeFrom} - ${rangeTo}`}</div>
            </DateRangeContainer>
          </div>
          
          <SelectWrapper width={width} className='sc-margin'>
            <label>{'Choose Vizualization'}</label>
            <Select
              placeholder={'common:SelectOne'}
              className="dropdown-select"
              options={getVisualizationList(t)}
              selectedItem={values?.whatWeHaveSavedSelect}
              onChange={(values) => {
                setFieldValue('whatWeHaveSavedSelect', values)
              }}
              testName={getConsultationTestName('what-we-saved')}
            />
          </SelectWrapper>
          <SectionToggleWrapper>
            <label>{'Visible'}</label>
            <ToggleButtonHorizontal
              name="visibleToCustomer"
              checked={values?.whatWeHaveSavedDisplay}
              onChange={(e) => {
                setFieldValue('whatWeHaveSavedDisplay', e?.target?.checked)
              }}
              testId={getConsultationTestName('what-we-saved-')}
            />
          </SectionToggleWrapper>
        </OrganizationWrap>
        {assumptionValues  ? (
          <div>
            {(values?.whatWeHaveSavedSelect === 'Key Metric Savings')&& (
              chartData?.length ? (<WhatWeSaved
              chartData={chartData}
              chartGoalData={formatedGoalData}
            />
          ): (
            Error_Message_OpportunityAndIssues_Missing
            ))}
                
          </div>
        ) : (
          values?.whatWeHaveSavedSelect === 'Key Metric Savings' &&
          Error_Message_KeyMatricsChart
        )}
        <hr />
        {values?.whatWeHaveSavedSelect === IMAGE_UPLOAD && (
          <DisableDiv isDisable={location ? false : true}>
            <ImageUploader
              imageId={values?.whatWeHaveSavedImageDetails?.imageId}
              update={(fields) => {
                setFieldValue('whatWeHaveSavedImageDetails', fields)
                setFieldValue('whatWeHaveSavedImageId', fields?.imageId)
              }}
              imageUrl={values?.whatWeHaveSavedImageDetails?.imageUrl}
              annotationsEnabled={false}
              locationId={location}
            />
          </DisableDiv>
        )}
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        background={COLORS.WHITE}
      >
        <OrganizationWrap sm-gap={10} md-gap={20}>
          <div>
            <TextInput
              labelText={`${'Section Title'}`}
              defaultValue={values?.sectionTitleWhereWeHaveBeen}
              placeholder="What We've Been"
              name="sectionTitleWhereWeHaveBeen"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                setFieldValue(name, value)
              }}
              hasError={errors?.['sectionTitleWhereWeHaveBeen']}
              isRequired={true}
              errorMessage={errors?.['sectionTitleWhereWeHaveBeen']}
              maxLength={MAX_LENGTH_OF_NAME}
            />
          </div>
          <div />
          <SectionToggleWrapper>
            <label>{'Visible'}</label>
            <ToggleButtonHorizontal
              name="visibleToCustomer"
              checked={values?.whereHaveYouBeenDisplay}
              onChange={(e) =>
                setFieldValue('whereHaveYouBeenDisplay', e?.target?.checked)
              }
              testId={getConsultationTestName('where-we-have-been-')}
            />
          </SectionToggleWrapper>
        </OrganizationWrap>
        <hr />
        <DisableDiv isDisable={location ? false : true}>
          <ImageUploader
            imageId={values?.whereHaveYouBeenImageDetails?.imageId}
            update={(fields) => {
              setFieldValue('whereHaveYouBeenImageDetails', fields)
              setFieldValue('whereHaveYouBeenImageId', fields?.imageId)
            }}
            imageUrl={values?.whereHaveYouBeenImageDetails?.imageUrl}
            annotationsEnabled={false}
            locationId={location}
          />
        </DisableDiv>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        background={COLORS.WHITE}
      >
        <OrganizationWrap sm-gap={10} md-gap={20}>
          <div data-testid="what-we-have-done-title">
            <TextInput
              labelText={`${'Section Title'}`}
              defaultValue={values?.sectionTitleWhatWeHaveDone}
              placeholder="What We've Done"
              name="sectionTitleWhatWeHaveDone"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                setFieldValue(name, value)
              }}
              hasError={errors?.['sectionTitleWhatWeHaveDone']}
              isRequired={true}
              errorMessage={errors?.['sectionTitleWhatWeHaveDone']}
              maxLength={MAX_LENGTH_OF_NAME}
            />
            <DateRangeContainer className='label-container'>
            <div className='label-dateRange'>Date Range</div>
            <div className='label-dateRange'>{`${rangeFrom} - ${rangeTo}`}</div>
            </DateRangeContainer>
          </div>
          <SelectWrapper width={width} className='sc-margin'>
            <label>{'Choose Vizualization'}</label>
            <Select
              placeholder={'common:SelectOne'}
              className="dropdown-select"
              selectedItem={values?.whatWeHaveDone}
              options={getVisualizationOptionList(t)}
              onChange={(values) => {
                setFieldValue('whatWeHaveDone', values)
              }}
              testName={getConsultationTestName('what-we-have-done')}
            />
          </SelectWrapper>
          <SectionToggleWrapper>
            <label>{'Visible'}</label>
            <ToggleButtonHorizontal
              name="visibleToCustomer"
              checked={values?.whatWeHaveDoneDisplay}
              onChange={(e) =>
                setFieldValue('whatWeHaveDoneDisplay', e?.target?.checked)
              }
              testId={getConsultationTestName('what-we-have-done-')}
            />
          </SectionToggleWrapper>
        </OrganizationWrap>
        {assumptionValues ? (
           <div data-testid="action-tracking-wrapper">
            
           {(values?.whatWeHaveDone === 'Action Tracking')&& (
             checkActionChartLength(chartActionData) ?(<ActionTracking {...chartActionData} />): (
           Error_Message_OpportunityAndIssues_Missing
           ))}
              
         </div>
        ) : (
          values?.whatWeHaveDone === 'Action Tracking' &&
          Error_Message_ActionTackingChart
        )}
        <hr />
        {values?.whatWeHaveDone === IMAGE_UPLOAD && (
          <DisableDiv isDisable={location ? false : true} data-testid="what-we-have-done">
            <ImageUploader
              imageId={values?.whatWeHaveDoneImageDetails?.imageId}
              update={(fields) => {
                setFieldValue('whatWeHaveDoneImageDetails', fields)
                setFieldValue('whatWeHaveDoneImageId', fields?.imageId)
              }}
              imageUrl={values?.whatWeHaveDoneImageDetails?.imageUrl}
              annotationsEnabled={false}
              locationId={location}
            />
          </DisableDiv>
        )}
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        background={COLORS.WHITE}
      >
        <OrganizationWrap sm-gap={10}>
          <div data-testid="where-we-go-title">
            <TextInput
              labelText={`${'Section Title'}`}
              defaultValue={values?.sectionTitleWhereWeGoFromHere}
              placeholder="Where We Go From Here"
              name="sectionTitleWhereWeGoFromHere"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                setFieldValue(name, value)
              }}
              hasError={errors?.['sectionTitleWhereWeGoFromHere']}
              isRequired={true}
              errorMessage={errors?.['sectionTitleWhereWeGoFromHere']}
              maxLength={MAX_LENGTH_OF_NAME}
            />
          </div>
          <div />
          <SectionToggleWrapper>
            <label>{'Visible'}</label>
            <ToggleButtonHorizontal
              name="visibleToCustomer"
              checked={values?.whereWeGoFromHereDisplay}
              onChange={(e) =>
                setFieldValue('whereWeGoFromHereDisplay', e?.target?.checked)
              }
              testId={getConsultationTestName('where-we-go-')}
            />
          </SectionToggleWrapper>
        </OrganizationWrap>
        <hr />
        <TableWrapper data-testid={getConsultationTestName("opportunity-list-wrapper")}>
          <OpportunityContextProvider>
            <span className="note">
              {
                'Please select a maximum of 5 opportunities to highlight as next steps.'
              }
            </span>
            <OpportunityListForConsultation
              enableRowCheckbox={true}
              handleCheckBoxClick={handleCheckBoxClick}
              enableShowPagination={true}
              selectedItems={selectedItems}
              isFromConsultation={true}
              handleOpportunityListData={handleOpportunityListData}
              buildingIds={(values?.building) || []}
              buildingsList={buildingsList}
            />
          </OpportunityContextProvider>
        </TableWrapper>
        {errors?.opportunityLimitMsg && (
          <ConsultationOpportunityError>
            <div className="error-message">
              <span className="icon icon-error" />
              <span className="error-text">{errors?.opportunityLimitMsg}</span>
            </div>
          </ConsultationOpportunityError>
        )}
      </Content>
      <ToggleButtonWrapperEnd>{toolbar()}</ToggleButtonWrapperEnd>
    </Container>
  )
}

export default ConsultationBodySection
