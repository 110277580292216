import WeatherWidgetPreview from './WeatherWidgetPreview'
import ImageTextWidgetPreview from './ImageTextWidgetPreview'
import { TrendLineWidgetPreview } from './TrendLineWidgetPreview'
import { TrendBarWidgetPreview } from './TrendBarWidgetPreview'
import { TimeComparisionBarWidgetPreview } from './TimeComparisionBarWidgetPreview'
import { DataComparisonBarWidgetPreview } from './DataComparisonBarWidgetPreview'
import { BaselineComparisonWidgetPreview } from './BaselineComparisonWidgetPreview'
import { CustomKPITrendsWidgetPreview } from './CustomKPITrendsWidgetPreview'
import { DataMonitoringOrbWidgetPreview } from './DataMonitoringOrbWidgetPreview'
import { DataMonitoringGaugeWidgetPreview } from './DataMonitoringGaugeWidgetPreview'
import { EnergyCostWidgetPreview } from './EnergyCostWidgetPreview'
import {
  POINT_MODE,
  TREND_MODE,
  getGoalType,
  getTrendDataBasedOnTimePeriod
} from '../../../widgets/widget-library/customKPIWidget/helper'
import { getKPITrendData } from '../../../widgets/helpers'
import { EnergyConsumptionWidgetPreview } from './EnergyConsumptionWidgetPreview'
import { BuildingDataChartPreview } from './BuildingDataChartPreview'
import { EnergyDemandWidgetPreview } from './EnergyDemandWidgetPreview'
import { MULTIPLE_LINES, WIDGET_TYPES } from 'src/constants'
import React, { useMemo } from 'react'
import { useWidgetsContext } from 'src/pages/widgets/WidgetsContextProvider'
import { TARGET_COLOR } from 'src/pages/widgets/colors'

const {
  WEATHER,
  IMAGE_TEXT,
  CUSTOM_KPI,
  LINE,
  GAUGE,
  BAR,
  SINGLE_COMPARISON_BAR,
  DUAL_COMPARISON_BAR,
  ORB,
  ENERGY_CONSUMPTION,
  ENERGY_CONSUMPTION_NEW,
  ENERGY_COST,
  ENERGY_DEMAND,
  ENERGY_STAR_SCORE
} = WIDGET_TYPES

const componentMap = {
  [IMAGE_TEXT]: ImageTextWidgetPreview,
  [WEATHER]: WeatherWidgetPreview,
  [LINE]: TrendLineWidgetPreview,
  [BAR]: TrendBarWidgetPreview,
  [SINGLE_COMPARISON_BAR]: TimeComparisionBarWidgetPreview,
  [DUAL_COMPARISON_BAR]: DataComparisonBarWidgetPreview,
  [ENERGY_CONSUMPTION]: BaselineComparisonWidgetPreview,
  [CUSTOM_KPI]: CustomKPITrendsWidgetPreview,
  [ENERGY_CONSUMPTION_NEW]: EnergyConsumptionWidgetPreview,
  [ENERGY_DEMAND]: EnergyDemandWidgetPreview,
  [MULTIPLE_LINES]: BuildingDataChartPreview,
  [ORB]: DataMonitoringOrbWidgetPreview,
  [GAUGE]: DataMonitoringGaugeWidgetPreview,
  [ENERGY_COST]: EnergyCostWidgetPreview
  // [CUSTOM_KPI]: CustomKPI,
  // [BAR]: TrendBarChart,
  // [DUAL_COMPARISON_BAR]: DataComparisonBarChart,
  // [ENERGY_CONSUMPTION]: BaseLineComparison,
  // [ENERGY_CONSUMPTION_NEW]: EnergyConsumption,
}

const WidgetsPreview = (widget) => {
  const { allDimensions } = useWidgetsContext() || {}

  const type = widget?.widget?.widgetType
  const dimension = widget?.dimension

  if (!componentMap[type]) {
    return <>Preivew yet to be developed.</>
  }

  const getEquipmentList = (equipmentList, buildingName = null) => {
    return (
      equipmentList &&
      equipmentList?.map((equipment) => {
        return {
          buildingName: buildingName ?? equipment?.buildingName,
          equipmentName: equipment?.name
        }
      })
    )
  }

  const formatEnergyDemandWidget = (widget) => {
    const { widgeEquipment1Id, widgeEquipment1Name, widgeEquipment1Type } =
      widget
    const equipments = [
      {
        name: widgeEquipment1Name
      }
    ]
    const widgetDetails = widget?.energyDemandWidget
    return {
      buildingId: widget?.buildingId,
      equipmentId: widgeEquipment1Id,
      equipmentList: getEquipmentList(equipments, widget?.building?.name),
      property:
        widgeEquipment1Type === 'VirtualMeter'
          ? 'InstantaneousPower'
          : 'RealPower',
      comparePointTo: widgetDetails?.comparedTo,
      selectedTimePeriod: widgetDetails?.timePeriod?.split('/')?.[1],
      equipmentType: widgeEquipment1Type,
      rateIncrease: widgetDetails?.rateIncrease || [],
      symbol: allDimensions?.find((x) => x?.UoM?.toLowerCase() === 'kw')?.Symbol || '',
      unitsOfMeasure: 'kW',
      timeInterval: '15-minutes',
      currentDemandColor: widgetDetails?.colourConfig?.currentDemand,
      peakDemandColor: widgetDetails?.colourConfig?.peakDemand,
      isShowRateIncrease: widgetDetails?.isRateIncrease,
      rateIncreaseOverlayColor: widgetDetails?.colourConfig?.overlayDemand,
      buildingName: widget?.building.name,
      accountName: widget?.building?.accountName,
      equipmentName: widgeEquipment1Name,
      timeZone: widget?.building?.tz
    }
  }

  const formatEnergyConsumptionWidget = (widget) => {
    const { widgeEquipment1Id, widgeEquipment1Name, widgeEquipment1Type } =
      widget
    return {
      widgetName: widget?.name,
      buildingId: widget?.buildingId,
      equipmentId: widgeEquipment1Id,
      property:
        widgeEquipment1Type === 'VirtualMeter'
          ? 'TotalConsumption'
          : 'ConsumptionInterval',
      selectedTimePeriod:
        widget?.energyConsumptionWidget?.timePeriod?.split('/')?.[1] || '',
      equipmentType: widgeEquipment1Type,
      unitsOfMeasure: widget?.energyConsumptionWidget?.uom,
      symbol:
        allDimensions?.find(
          (x) => x?.UoM?.toLowerCase() === widget?.energyConsumptionWidget?.uom?.toLowerCase() || 
          x?.Name?.toLowerCase() === widget?.energyConsumptionWidget?.uom?.toLowerCase()
        )?.Symbol || '',
      selectedAggrValue: 'sum',
      buildingName: widget?.building?.name,
      accountName: widget?.building?.accountName,
      equipmentName: widgeEquipment1Name,
      toDateBarColor: widget?.energyConsumptionWidget?.colourConfig?.toDate,
      leftBarColor: widget?.energyConsumptionWidget?.colourConfig?.leftBar,
      rightBarColor: widget?.energyConsumptionWidget?.colourConfig?.rightBar,
      benchMarker: widget?.energyConsumptionWidget?.benchMark?.benchmarkMarker,
      benchmarkColor:
        widget?.energyConsumptionWidget?.colourConfig?.rightBenchmark ?? TARGET_COLOR,
      benchMarkerLabel:
        widget?.energyConsumptionWidget?.benchMark?.benchmarkLabel ?? '',
      benchMarkerValue:
        widget?.energyConsumptionWidget?.benchMark?.benchmarkValue ?? '',
      isShowTemperature: widget?.energyConsumptionWidget?.showTemperature,
      comparedTo:
        widget?.energyConsumptionWidget?.comparedTo?.split('/')?.[1] || ':none',
      timeZone: widget?.building?.tz
    }
  }

  const formatBaselineComparison = (widget) => {
    const { widgeEquipment1Id, widgeEquipment1Name, widgeEquipment1Type } =
      widget
    const equipments = [
      {
        name: widgeEquipment1Name
      }
    ]
    const baselineComparisonDetails = widget?.baselineComparision
    return {
      widgetName: widget?.name,
      buildingId: widget?.buildingId,
      equipmentList: getEquipmentList(equipments, widget?.building?.name),
      equipmentId: widgeEquipment1Id,
      property: 'InstantaneousPower',
      selectedTimePeriod:
        baselineComparisonDetails?.timePeriod?.split('/')?.[1] || '',
      equipmentType: widgeEquipment1Type,
      unitsOfMeasure: 'kiloWattHour',
      symbol:
        allDimensions?.find((x) => x?.UoM?.toLowerCase() === 'kilowatthour')?.Symbol || '',
      selectedAggrValue: 'sum',
      buildingName: widget?.building?.name,
      accountName: widget?.building?.accountName,
      equipmentName: widgeEquipment1Name,
      baselineId: widget?.baselineId,
      timeZone: widget?.building?.tz
    }
  }

  const formatTimeComparisonBarData = (widget) => {
    const timeComparisonBarDetails = widget?.equipmentTimeComparisionBarWidget
    const { widgeEquipment1Id, widgeEquipment1Name, widgeEquipment1Type } =
      widget
    const equipments = [
      {
        name: widgeEquipment1Name
      }
    ]
    return {
      widgetName: widget?.name,
      buildingId: widget?.buildingId,
      equipmentList: getEquipmentList(equipments, widget?.building?.name),
      equipmentId: widgeEquipment1Id,
      property: timeComparisonBarDetails?.property?.property,
      selectedTimePeriod:
        timeComparisonBarDetails?.timePeriod?.split('/')?.[1] || '',
      equipmentType: widgeEquipment1Type,
      unitsOfMeasure: timeComparisonBarDetails?.property.uom,
      symbol:
        allDimensions?.find(
          (x) => x?.UoM?.toLowerCase() === timeComparisonBarDetails?.property.uom?.toLowerCase() ||
          x?.Name?.toLowerCase() === timeComparisonBarDetails?.property.uom?.toLowerCase()
        )?.Symbol || '',
      selectedAggrValue: timeComparisonBarDetails?.value?.split('/')?.[1] || '',
      buildingName: widget?.building?.name,
      accountName: widget?.building?.accountName,
      equipmentName: widgeEquipment1Name,
      outerBarColor: timeComparisonBarDetails?.colourConfig?.leftOuterBar,
      innerBarColor: timeComparisonBarDetails?.colourConfig?.leftInnerBar,
      leftBarColor: timeComparisonBarDetails?.colourConfig?.leftOuterBar,
      leftBenchmarkColor: timeComparisonBarDetails?.colourConfig?.leftBenchmark ?? TARGET_COLOR,
      leftMarkerConstant:
        timeComparisonBarDetails?.leftbarBenchmark?.benchmarkValue,
      leftMarkerLabel:
        timeComparisonBarDetails?.leftbarBenchmark?.benchmarkLabel,
      leftTargetMarker:
        timeComparisonBarDetails?.leftbarBenchmark?.benchmarkMarker?.split(
          '/'
        )?.[1] || '',
      leftTargetMarkerCalculate:
        timeComparisonBarDetails?.leftbarBenchmark?.benchmarkCalculation,
      rightBarColor: timeComparisonBarDetails?.colourConfig?.rightBar,
      rightBenchmarkColor:
        timeComparisonBarDetails?.colourConfig?.rightBenchmark ?? TARGET_COLOR,
      rightMarkerConstant:
        timeComparisonBarDetails?.rightbarBenchmark?.benchmarkValue,
      rightMarkerLabel:
        timeComparisonBarDetails?.rightbarBenchmark?.benchmarkLabel,
      rightTargetMarker:
        timeComparisonBarDetails?.rightbarBenchmark?.benchmarkMarker.split(
          '/'
        )?.[1] || '',
      rightTargetMarkerCalculate:
        timeComparisonBarDetails?.rightbarBenchmark?.benchmarkCalculation,
      benchmarkColor: timeComparisonBarDetails?.colourConfig?.leftBenchmark ?? TARGET_COLOR,
      barCalculatedValue: timeComparisonBarDetails?.barMaximumType,
      barMin: timeComparisonBarDetails?.barMinimum,
      barMax: timeComparisonBarDetails?.barMaxType?.split('/')?.[1],
      barMaxConstantValue: timeComparisonBarDetails?.barMaximumValue,
      timeZone: widget?.building?.tz
    }
  }

  const formatTrendBarData = (widget) => {
    const trendBarDetails = widget?.equipmentTrendBarWidget
    const { widgeEquipment1Id, widgeEquipment1Name, widgeEquipment1Type } =
      widget
    const equipments = [
      {
        name: widgeEquipment1Name
      }
    ]
    const benchMark = trendBarDetails?.benchMark
    return {
      widgetName: widget?.name,
      buildingId: widget?.buildingId,
      equipmentList: getEquipmentList(equipments, widget?.building?.name),
      equipmentId: widgeEquipment1Id,
      property: trendBarDetails?.property?.property,
      selectedTimePeriod: trendBarDetails?.timePeriod?.split('/')?.[1] || '',
      equipmentType: widgeEquipment1Type,
      unitsOfMeasure: trendBarDetails?.property?.uom,
      symbol:
        allDimensions?.find((x) => x.UoM?.toLowerCase() === trendBarDetails?.property?.uom?.toLowerCase() ||
        x?.Name?.toLowerCase() === trendBarDetails?.property?.uom?.toLowerCase())
          ?.Symbol || '',
      selectedAggrValue: trendBarDetails?.value?.split('/')?.[1] || '',
      barColor: trendBarDetails?.colourConfig?.leftBar,
      benchmarkColor: trendBarDetails?.colourConfig?.leftBenchmark ?? TARGET_COLOR,
      buildingName: widget?.building?.name,
      accountName: widget?.building?.accountName,
      equipmentName: widgeEquipment1Name,
      benchMarkTargetLabel: benchMark?.benchmarkLabel,
      markerConstantValue: benchMark?.benchmarkValue,
      targetMarker: benchMark?.benchmarkMarker?.split('/')[1] || '',
      timeInterval: trendBarDetails?.timeInterval?.split('/')?.[1] || '',
      timeZone: widget?.building?.tz
    }
  }

  const formatTrendLineData = (widget) => {
    const trendLineDetails = widget?.equipmentTrendLineWidget
    const { widgeEquipment1Id, widgeEquipment1Name, widgeEquipment1Type } =
      widget
    const equipments = [
      {
        name: widgeEquipment1Name
      }
    ]
    return {
      buildingId: widget?.buildingId,
      equipmentId: widgeEquipment1Id,
      equipmentList: getEquipmentList(equipments, widget?.building?.name),
      property: trendLineDetails?.property?.property,
      comparePointTo: trendLineDetails?.comparePoint,
      selectedTimePeriod: trendLineDetails?.timePeriod,
      equipmentType: widgeEquipment1Type,
      unitsOfMeasure: trendLineDetails?.property?.uom,
      symbol:
        allDimensions?.find((x) => x?.UoM?.toLowerCase() === trendLineDetails?.property?.uom?.toLowerCase() ||
        x?.Name?.toLowerCase() === trendLineDetails?.property?.uom?.toLowerCase()) 
          ?.Symbol || '',
      selectedAggrValue: trendLineDetails?.value,
      currentLineColor: trendLineDetails?.colourConfig?.line,
      previousPeriodLineColor: trendLineDetails?.colourConfig?.overlayLine,
      buildingName: widget?.building?.name,
      accountName: widget?.building?.accountName,
      equipmentName: widgeEquipment1Name,
      timeZone: widget?.building?.tz
    }
  }

  const formatEnergyCostBarData = (widget) => {
    const energyCostWidgetDetails = widget?.energyCostWidget
    const { widgeEquipment1Id, widgeEquipment1Name, widgeEquipment1Type } =
      widget
    const equipments = [
      {
        name: widgeEquipment1Name
      }
    ]
    return {
      widgetName: widget?.name,
      buildingId: widget?.buildingId,
      equipmentList: getEquipmentList(equipments, widget?.building?.name),
      equipmentId: widgeEquipment1Id,
      equipmentType: widgeEquipment1Type,
      benchMarker:
        energyCostWidgetDetails?.benchMark?.benchmarkMarker?.split('/')?.[1] ||
        '',
      benchMarkerLabel: energyCostWidgetDetails?.benchMark?.benchmarkLabel ?? '',
      benchMarkerValue: energyCostWidgetDetails?.benchMark?.benchmarkValue ?? '',
      benchmarkColor: energyCostWidgetDetails?.colourConfig?.rightBenchmark ?? TARGET_COLOR,
      comparedTo:
        energyCostWidgetDetails?.comparedTo.split('/')?.[1] || ':none',
      leftBarColor: energyCostWidgetDetails?.colourConfig?.leftBar,
      timePeriod: energyCostWidgetDetails?.timePeriod,
      rightBarColor: energyCostWidgetDetails?.colourConfig?.rightBar,
      toDateBarColor: energyCostWidgetDetails?.colourConfig?.toDate,
      isShowEnergyCostSegmentation:
        energyCostWidgetDetails?.showEnergyCostSegmentation,
      timeZone: widget?.building?.tz
    }
  }

  const formatDataComparisonBarData = (widget) => {
    const dataBarDetails = widget?.equipmentDataComparisionBarWidget
    const {
      widgeEquipment1Id,
      widgeEquipment1Name,
      widgeEquipment1Type,
      widgeEquipment2Name
    } = widget
    const equipments = [
      {
        name: widgeEquipment1Name,
        buildingName: widget?.building?.name
      },
      {
        name: widgeEquipment2Name,
        buildingName: widget?.widgetBuildingTarget?.name
      }
    ]
    return {
      widgetName: widget?.name,
      buildingId: widget?.buildingId,
      equipmentList: getEquipmentList(equipments, widget?.building?.name),
      equipmentId: widgeEquipment1Id,
      property: [
        dataBarDetails?.leftBarProperty?.property,
        dataBarDetails?.rightBarProperty?.property
      ],
      selectedTimePeriod: dataBarDetails?.timePeriod?.split('/')?.[1] || '',
      equipmentType: widgeEquipment1Type,
      leftUnitsOfMeasure: dataBarDetails?.leftBarProperty?.uom || '',
      leftSymbol:
        allDimensions?.find(
          (x) => x?.UoM?.toLowerCase() === dataBarDetails?.leftBarProperty?.uom?.toLowerCase() || 
          x?.Name?.toLowerCase() === dataBarDetails?.leftBarProperty?.uom?.toLowerCase()
        )?.Symbol || '',
      rightUnitsOfMeasure: dataBarDetails?.rightBarProperty?.uom || '',
      rightSymbol:
        allDimensions?.find(
          (x) => x?.UoM?.toLowerCase() === dataBarDetails?.rightBarProperty?.uom?.toLowerCase() || 
          x?.Name?.toLowerCase() === dataBarDetails?.rightBarProperty?.uom?.toLowerCase()
        )?.Symbol || '',
      selectedAggrValue: dataBarDetails?.value?.split('/')?.[1] || '',
      buildingName: widget?.building?.name,
      accountName: widget?.building?.accountName,
      equipmentName: widgeEquipment1Name,
      leftBarColor: dataBarDetails?.colourConfig?.leftBar,
      rightBarColor: dataBarDetails?.colourConfig?.rightBar,
      leftMarkerLabel: dataBarDetails?.leftBarProperty?.benchmarkLabel,
      rightMarkerLabel: dataBarDetails?.rightBarProperty?.benchmarkLabel,
      leftMarkerConstant: dataBarDetails?.leftBarProperty?.benchmarkValue,
      rightMarkerConstant: dataBarDetails?.rightBarProperty?.benchmarkValue,
      leftBenchmarkColor: dataBarDetails?.colourConfig?.leftBenchmark ?? TARGET_COLOR,
      rightBenchmarkColor: dataBarDetails?.colourConfig?.rightBenchmark ?? TARGET_COLOR,
      leftTargetMarker:
        dataBarDetails?.leftBarProperty?.benchmarkMarker?.split('/')[1] || '',
      rightTargetMarker:
        dataBarDetails?.rightBarProperty?.benchmarkMarker?.split('/')[1] || '',
      timeInterval: dataBarDetails?.timeInterval?.split('/')?.[1] || '',
      timeZone: widget?.building?.tz
    }
  }

  const formatCustomKPIChartData = (widget) => {
    const customKPIData = widget?.equipmentCustomKpiWidget
    const trendUpIsGood = customKPIData?.kpiMeasurement === 'energyStarScore'
    const type = customKPIData?.kpiTrend === null ? POINT_MODE : TREND_MODE
    const selectedTimePeriod =
      type === POINT_MODE
        ? customKPIData?.timePeriod?.split('/')?.[1] || ''
        : customKPIData?.kpiDisplayDate?.split('/')?.[1] || ''
    return {
      widgetName: widget?.name,
      name: widget?.name,
      buildingId: widget?.buildingId,
      equipmentList: [
        {
          buildingName: widget?.building?.name,
          accountName: widget?.building?.accountName,
          equipmentName: ''
        }
      ],
      selectedKpiMeasurement: customKPIData?.kpiMeasurement,
      isPointInTime: customKPIData?.kpiTrend === null,
      previousPeroid: customKPIData?.kpiPreviousPeriod,
      currentPeriod: customKPIData?.kpiCurrentPeriod,
      goalCurrentPeriod: customKPIData?.kpiGoal,
      trendUpIsGood: trendUpIsGood,
      goalType: getGoalType(
        trendUpIsGood,
        customKPIData?.kpiCurrentPeriod,
        customKPIData?.kpiGoal
      ),
      type: type,
      trendData: getKPITrendData(customKPIData?.kpiTrend),
      chartData: customKPIData?.kpiTrend?.length
        ? getTrendDataBasedOnTimePeriod(
            getKPITrendData(customKPIData?.kpiTrend),
            selectedTimePeriod
          )
        : [],
      selectedTimePeriod: selectedTimePeriod,
      timePeriod: customKPIData?.kpiDisplayDate,
      timeZone: widget?.building?.tz
    }
  }

  const formatORBChartData = (widget) => {
    const orbDetails = widget?.equipmentDataMonitorOrbWidget
    const colourConfig = widget?.equipmentDataMonitorOrbWidget?.colourConfig
    const { widgeEquipment1Id, widgeEquipment1Name, widgeEquipment1Type } =
      widget
    return {
      widgetName: widget?.name,
      buildingId: widget?.buildingId,
      equipmentId: widgeEquipment1Id,
      equipmentType: widgeEquipment1Type,
      equipmentList: [
        {
          buildingName: widget?.building?.name,
          accountName: widget?.building?.accountName,
          equipmentName: widgeEquipment1Name
        }
      ],
      property: orbDetails?.property?.property,
      selectedTimePeriod: orbDetails?.timePeriod?.split('/')?.[1] || '',
      unitsOfMeasure: orbDetails?.property?.uom,
      symbol:
        allDimensions?.find((x) => x?.UoM?.toLowerCase() === orbDetails?.property?.uom?.toLowerCase() || 
        x?.Name?.toLowerCase() === orbDetails?.property?.uom?.toLowerCase())
          ?.Symbol || '',
      selectedAggrValue: orbDetails?.Value?.split('/')?.[1] || '',
      baselineColorValue: colourConfig?.baseline,
      threshold1ConstantValue: colourConfig?.threshold1Value,
      threshold1Value: colourConfig?.threshold1,
      threshold2ConstantValue: colourConfig?.threshold2Value,
      threshold2Value: colourConfig?.threshold2,
      thresholdsValue: colourConfig?.thresholdOption,
      timeZone: widget?.building?.tz
    }
  }

  const formatGaugeChartData = (widget) => {
    const gaugeDetails = widget?.equipmentDataMonitorGuageWidget
    const colourConfig = widget?.equipmentDataMonitorGuageWidget?.colourConfig
    const { widgeEquipment1Id, widgeEquipment1Name, widgeEquipment1Type } =
      widget
    const details = {
      widgetName: widget?.name,
      buildingId: widget?.buildingId,
      equipmentId: widgeEquipment1Id,
      equipmentType: widgeEquipment1Type,
      equipmentList: [
        {
          buildingName: widget?.building?.name,
          accountName: widget?.building?.accountName,
          equipmentName: widgeEquipment1Name
        }
      ],
      property: gaugeDetails?.property?.property,
      selectedTimePeriod: gaugeDetails?.timePeriod?.split('/')?.[1] || '',
      unitsOfMeasure: gaugeDetails?.property?.uom,
      symbol:
        allDimensions?.find((x) => x?.UoM?.toLowerCase() === gaugeDetails?.property?.uom?.toLowerCase() || 
        x?.Name?.toLowerCase() === gaugeDetails?.property?.uom?.toLowerCase())
          ?.Symbol || '',
      selectedAggrValue: gaugeDetails?.needleValue?.split('/')?.[1] || '',
      baselineColorValue: colourConfig?.baseline,
      threshold1ConstantValue: colourConfig?.threshold1Value,
      threshold1Value: colourConfig?.threshold1,
      threshold2ConstantValue: colourConfig?.threshold2Value,
      threshold2Value: colourConfig?.threshold2,
      thresholdsValue: colourConfig?.thresholdOption,
      gaugeMin: gaugeDetails?.guageMinimum,
      gaugeMax: gaugeDetails?.guageMaximumValue,
      guageMaximumType: gaugeDetails?.guageMaximumType?.split('/')?.[1] || '',
      gaugeMaxTimePeriod:
        gaugeDetails?.guageMaximumCalculationType?.split('/')?.[1] || '',
      timeZone: widget?.building?.tz
    }
    return details
  }

  const formatWidgetDataByType = useMemo(() => {
    try {
      const widgetDetails = widget?.widget
      const widgetType = widgetDetails?.widgetType
      switch (widgetType) {
        case BAR:
          return formatTrendBarData(widgetDetails)
        case DUAL_COMPARISON_BAR:
          return formatDataComparisonBarData(widgetDetails)
        case LINE:
          return formatTrendLineData(widgetDetails)
        case SINGLE_COMPARISON_BAR:
          return formatTimeComparisonBarData(widgetDetails)
        case SINGLE_COMPARISON_BAR:
          return formatTimeComparisonBarData(widgetDetails)
        case ENERGY_CONSUMPTION:
          return formatBaselineComparison(widgetDetails)
        case ENERGY_CONSUMPTION_NEW:
          return formatEnergyConsumptionWidget(widgetDetails)
        case ENERGY_DEMAND:
          return formatEnergyDemandWidget(widgetDetails)
        case MULTIPLE_LINES:
          return widgetDetails
        case CUSTOM_KPI:
          return formatCustomKPIChartData(widgetDetails)
        case ORB:
          return formatORBChartData(widgetDetails)
        case GAUGE:
          return formatGaugeChartData(widgetDetails)
        case ENERGY_COST:
          return formatEnergyCostBarData(widgetDetails)
        default:
          return widgetDetails
      }
    } catch {
      return null
    }
  }, [widget?.widget])

  const SelectedComponent = componentMap[type]

  return (
    <SelectedComponent
      dimension={dimension}
      widgetDetails={formatWidgetDataByType}
    />
  )
}

export default React.memo(WidgetsPreview)
