import { PageDetail } from './Detail'
import { ACTIONS } from 'src/constants'
import PageList from './List'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import Modal from 'src/components/legacy/components/modal/modal'
import { useMutation } from 'src/hooks/APIHooks'
import { DELETE_GROUP } from './graphql'
import { ConfirmationText } from 'src/components/layouts'
import { TranslateComponent } from 'src/common/translations'

const HelperObj = {
  CONFIRMATION_TYPES: {
    DELETE: 'delete',
    CREATE: 'create',
    UPDATE: 'update',
    EMPTY: ''
  }
}

const PageContainer = ({
  rows,
  responseTime,
  setMode,
  mode,
  setReloadEvents,
  deleteId,
  setShowDeleteDialog,
  showDeleteDialog,
  input,
  showConfirmation,
  confirmationType,
  setConfirmationType,
  setShowConfirmation,
  reloadEvents,
  setDeleteId,
  setInput,
  initialValues
}) => {
  const { onSubmit: deleteMutation } = useMutation({
    query: DELETE_GROUP,
    onSuccess: () => {
      setReloadEvents(true)
      setShowConfirmation(true)
    }
  })
  const deleteHandler = () => {
    setConfirmationType(HelperObj.CONFIRMATION_TYPES.DELETE)
    deleteMutation({ input: { name: deleteId } })
    hideDeleteDialog()
  }
  const hideDeleteDialog = () => {
    setShowDeleteDialog(false)
  }
  const deleteModalConfig = {
    heading: 'Delete Group',
    buttons: [
      { text: 'Yes', handleClick: deleteHandler, type: 'valid' },
      { text: 'No', handleClick: hideDeleteDialog, type: 'cancel' }
    ],
    handleClose: hideDeleteDialog
  }
  const hideConfirmationDialog = () => {
    setShowConfirmation(false)
  }
  const confirmationConfig = {
    heading: (() => {
      switch (confirmationType) {
        case HelperObj.CONFIRMATION_TYPES.DELETE:
          return 'Group Deleted'
        case HelperObj.CONFIRMATION_TYPES.CREATE:
          return 'Group Created'
        case HelperObj.CONFIRMATION_TYPES.UPDATE:
          return 'Group Updated'
      }
    })(),
    buttons: [
      { text: 'Close', handleClick: hideConfirmationDialog, type: 'cancel' }
    ],
    handleClose: hideConfirmationDialog,
    autoClose: 8000
  }
  return (
    <Container
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding="0"
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageHeader
          pageTitle={<div>Groups</div>}
          titleTools={
            <Button
              onClick={() => setMode(ACTIONS.ADD)}
            >
              <TranslateComponent>Create Group</TranslateComponent> 
            </Button>
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageList
          responseTime={responseTime}
          rows={rows}
          setMode={(e) => setMode(e)}
          setInput={(e) => setInput(e)}
          reloadEvents={reloadEvents}
          setReloadEvents={(e) => setReloadEvents(e)}
          setDeleteId={(e) => setDeleteId(e)}
          setShowDeleteDialog={(e) => setShowDeleteDialog(e)}
        />
      </Content>
      {mode && (
        <PageDetail
          mode={mode}
          setMode={(e) => setMode(e)}
          input={input}
          setInput={(e) => setInput(e)}
          initialValues={initialValues}
          setReloadEvents={(e) => setReloadEvents(e)}
        />
      )}
      {showConfirmation ? (
        <Modal {...confirmationConfig}>
          <ConfirmationText>
            {(() => {
              switch (confirmationType) {
                case HelperObj.CONFIRMATION_TYPES.DELETE:
                  return 'You have successfully deleted: '
                case HelperObj.CONFIRMATION_TYPES.CREATE:
                  return 'You have successfully created: '
                case HelperObj.CONFIRMATION_TYPES.UPDATE:
                  return 'You have successfully updated: '
              }
            })()}
            {input?.name}
          </ConfirmationText>
        </Modal>
      ) : null}
      {showDeleteDialog ? (
        <Modal {...deleteModalConfig}>
          <ConfirmationText>
            Are you sure you want to delete group: {input?.name}
          </ConfirmationText>
        </Modal>
      ) : null}
    </Container>
  )
}

export default PageContainer
