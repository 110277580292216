import { useFormikContext } from '../MultiTabTorm'
import ColorInput from 'src/components/color-input/ColorInput'
import { BenchMarkCompareStyledWrapper, PanelLabel, TabWrapper } from '../../styles'
import { TARGET_MARKERS_NONE } from 'src/common/target-markers'
import translate from 'src/common/translations'


const DataComparisonBarChartColors = () => {
  const formik = useFormikContext()
  const { values, setFieldValue } = formik
  const handleSelectColorPicker = (fieldName: string, color: any) => {
    setFieldValue(fieldName, color)
  }

  const optionsTranslated = {
    leftBar: translate("Left Bar"),
    rightBar: translate("Right Bar"),
  }

  return (
    <TabWrapper>
        <BenchMarkCompareStyledWrapper>
          <div className='item'>
            <PanelLabel>{optionsTranslated.leftBar}</PanelLabel>
            <ColorInput
            label={"Bar Color"}
            isRequired={true}
            backgroundColor={values?.leftBarColor}
            update={(color)=> handleSelectColorPicker('leftBarColor', color)}
            />
            {
              values?.leftTargetMarker !== TARGET_MARKERS_NONE && <ColorInput
              label={`Benchmark/Target Color`}
              isRequired={true}
              backgroundColor={values?.leftBenchmarkColor}
              update={(color)=> handleSelectColorPicker('leftBenchmarkColor', color)}
              />
            }
          </div>
          <br/>
          <div className='vertical'></div>
          <div className='item'>
            <PanelLabel>{optionsTranslated.rightBar}</PanelLabel>
            <ColorInput
            label={"Bar Color"}
            isRequired={true}
            backgroundColor={values?.rightBarColor}
            update={(color)=> handleSelectColorPicker('rightBarColor', color)}
            />
            {
              values?.rightTargetMarker !== TARGET_MARKERS_NONE && <ColorInput
              label={`Benchmark/Target Color`}
              isRequired={true}
              backgroundColor={values?.rightBenchmarkColor}
              update={(color)=> handleSelectColorPicker('rightBenchmarkColor', color)}
              />
            }
          </div>
        </BenchMarkCompareStyledWrapper>
    </TabWrapper>
  )
}

export default DataComparisonBarChartColors
