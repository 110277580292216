export const ORB = 'orb'
export const GAUGE = 'gauge'
export const IMAGE_TEXT = 'image-text'
export const BAR = 'bar'
export const SINGLE_COMPARISON_BAR = 'single-comparison-bar'
export const DUAL_COMPARISON_BAR = 'dual-comparison-bar'
export const LINE= 'line'
export const WEATHER= 'weather'
export const ENERGY_CONSUMPTION = 'energy-consumption'
export const ENERGY_CONSUMPTION_NEW = 'energy-consumption-new'
export const ENERGY_COST = 'energy-cost'
export const ENERGY_STAR_SCORE = 'energy-star-score'
export const MULTIPLE_LINES = 'multiple-lines'
export const ENERGY_DEMAND = 'energy-demand'
export const CUSTOM_KPI = 'custom-kpi'

export const WIDGET_TYPES = {
    ORB,
    GAUGE,
    IMAGE_TEXT,
    BAR,
    SINGLE_COMPARISON_BAR,
    DUAL_COMPARISON_BAR,
    LINE,
    WEATHER,
    ENERGY_CONSUMPTION,
    ENERGY_CONSUMPTION_NEW,
    ENERGY_COST,
    ENERGY_STAR_SCORE,
    MULTIPLE_LINES,
    ENERGY_DEMAND,
    CUSTOM_KPI
  }

export const WIDGET_ALL_TYPES = [
    ORB,
    GAUGE,
    IMAGE_TEXT,
    BAR,
    SINGLE_COMPARISON_BAR,
    DUAL_COMPARISON_BAR,
    LINE,
    WEATHER,
    ENERGY_CONSUMPTION,
    ENERGY_CONSUMPTION_NEW,
    ENERGY_COST,
    ENERGY_STAR_SCORE,
    MULTIPLE_LINES,
    ENERGY_DEMAND,
    CUSTOM_KPI
]

export const CUSTOM_EQUIPMENT_WIDGETS = [
  LINE,
  BAR,
  SINGLE_COMPARISON_BAR,
  DUAL_COMPARISON_BAR,
  GAUGE,
  ORB,
  CUSTOM_KPI
]

export const ENERGY_WIDGETS = [
  ENERGY_CONSUMPTION,
  ENERGY_CONSUMPTION_NEW,
  ENERGY_COST,
  // TE-19250 We don’t have the data integration to Energy Star system from US government working right now
  // Because we don’t know what might break if we make changes to API, we should just hide the option from the widget selection menu
  // "energy-star-score",
  ENERGY_DEMAND
]