

export const listIssueFindingsOrganization = `
query issuesByAccount($accountId: ID!) {
  searchBuildings(filter: {accountId: {eq: $accountId}}, limit: 1000) {
    items {
      id
      name
    }
  }
  searchIssues(filter: {accountId: {eq: $accountId}}, limit: 1000) {
    items {
      id
      title
      creationDate
      priority
      status
      nextStep
      assignedTo
      targetDate
      buildingId
      isVisible
      equipments(limit: 1000) {
        items {
          equipment {
            id
            name
            type
          }
        }
      }
      attachments {
        items {
          id
          name
          title
          description
          displayName
          attachmentOrder
          buildingId
          category
        }
      }
    }
  }
}`


export const listIssueFindingsByBuilding = /* GraphQL */ `
query issuesByBuilding($buildingId: ID!) {
  getBuilding(id: $buildingId) {
    accountId
    accountName
    id
    name
    postalCode
    address
    address2
  }
  searchIssues(filter: {buildingId: {eq: $buildingId}}, limit: 1000) {
    items {
      id
      title
      creationDate
      priority
      status
      nextStep
      assignedTo
      targetDate
      foundDuring
      buildingId
      comment
      isVisible
      equipments(limit: 1000) {
        items {
          equipment {
            id
            name
            type
          }
        }
      }
      opportunities (limit: 1000){
        items{
          id
          createdAt
        }
      }
    }
  }
}`

export const listIssueFindingsByMultipleBuildings = /* GraphQL */ `
  query issuesByMultipleBuildings($filter: SearchableIssueFilterInput, $limit: Int, $sort: [SearchableIssueSortInput]) {
    searchIssues(filter: $filter, limit: $limit, sort: $sort) {
      items {
        id
        title
        creationDate
        priority
        status
        nextStep
        assignedTo
        targetDate
        foundDuring
        buildingId
        comment
        isVisible
        equipments(limit: 1000) {
          items {
            equipment {
              id
              name
              type
            }
          }
        }
        opportunities(limit: 1000) {
          items {
            id
            createdAt
          }
        }
      }
    }
  }
`

export const GET_ANALYTIC_SERVICEADVISORY = /* GraphQL */`
  query getAnalyticServiceAdvisory($serviceAdvisoryId: ID!) {
    getAnalyticServiceAdvisory(id: $serviceAdvisoryId) {
      buildingId
      equipment {
        name
      }
      impactComfort
      impactEnergy
      impactReliability
      status
      exceptionName
      suggestion {
        suggestion
        testName
      }
    }
  }
`
export const GET_BUILDING_DETAILS = `
query getBuildingDetails($buildingId: ID!) {
  getBuilding(id: $buildingId) {
    id
    accountId
    accountName
    name
  }
}
`

export const CREATE_FINDING_QUICK_ADD = /* GraphQL */ `
  mutation CreateQuickIssue($input: CreateIssueInput!) {
    createIssue(input: $input) {
      id
    }
  }
`
export const UPDATE_FINDING = /* GraphQL */ `
  mutation updateIssue($input: UpdateIssueInput!) {
    updateIssue(input: $input) {
      id
    }
  }
`
export const DELETE_FINDING = /* GraphQL */ `
  mutation deleteIssue($input: DeleteIssueInput!) {
    deleteIssue(input: $input) {
      id
    }
  }
`
export const CREATE_BULK_EQUIPMENT_ISSUES = /* GraphQL */ `
  mutation createEquipmentAssociation(
    $input: AWSJSON!
  ) {
    createEquipmentAssociation(body: $input)
  }
`
export const DELETE_EQUIPMENT_ISSUE = /* GraphQL */ `
  mutation deleteEquipmentIssue($input: DeleteEquipmentIssueInput!) {
    deleteEquipmentIssue(input: $input) {
      id
    }
  }
`

export const GET_FINDING_BY_ID = /* GraphQL */ `
  query getIssue($id: ID!) {
    getIssue(id: $id) {
      accountId
      assignedTo
      chartLink
      buildingId
      comment
      creationDate
      createdBy
      foundDuring
      id
      impactComfort
      impactComfortDisplay
      impactCompliance
      impactComplianceDisplay
      impactCustom1
      impactCustom1Display
      impactCustom1Name
      impactCustom2
      impactCustom2Display
      impactCustom2Name
      impactEnergy
      impactEnergyDisplay
      impactPerformance
      impactPerformanceDisplay
      impactReliability
      impactReliabilityDisplay
      impactComplianceValueType
      impactComplianceValue
      impactComfortValue
      impactComfortValueType
      impactCustom1Value
      impactCustom1ValueType
      impactCustom2Value
      impactCustom2ValueType
      impactEnergyValue
      impactEnergyValueType
      impactPerformanceValue
      impactPerformanceValueType
      impactReliabilityValue
      impactReliabilityValueType
      isVisible
      linkDescription
      priority
      status
      nextStep
      targetDate
      title
      updatedAt
      userId
      equ : equipments(limit:10000) {
        items {
          id
          equipment {
            id
            name
            type
          }
        }
      }
      user {
        email
        firstName
      }
      opportunities {
        items {
          id
          opportunity {
            id
            title
          }
        }
      }
      attachments {
        items {
          id
          buildingId
          name
          displayName
          type
          description
          title
          category
          attachmentOrder
        }
      }
    }
  }
`
