import { is } from 'ramda'
import React, { useCallback } from 'react'
import { tooltipEventDispatcher } from './tooltipEventDispatcher'

export default function useTooltip({
  dispatchEventName = null,
  disable = false,
  formatter = null
}) {
  const dispatch = useCallback(
    (
      event: React.MouseEvent,
      uuid: string,
      isActive: boolean,
      data: TooltipData[] | any
    ) => {
      if (dispatchEventName && !disable)
        tooltipEventDispatcher(
          event,
          dispatchEventName,
          uuid,
          isActive,
          is(Function, formatter) ? formatter(data) : data
        )
    },
    [dispatchEventName, disable]
  )

  return [dispatch]
}
