import { Fragment, useEffect, useMemo } from 'react'
import {
  useDynamicActions,
  selectSVGDimensions,
  useSelector
} from './BaseSetup'

const ChartSetup = (props: any) => {
  const config = useMemo(() => props?.props, [props?.props])
  const actions = useDynamicActions()
  const svgDimensions = useSelector(selectSVGDimensions)
  const { updateChartInstanceID, updateConfig, canvasChangeUpdateScale } =
    actions || {}

  // Assign chart ID
  useEffect(() => {
    updateChartInstanceID(props?.chartID)
  }, [])

  useEffect(() => {
    if (svgDimensions?.height) {
      const queryString = `#XY-${props?.chartID} > .XY-chart-legend`
      const legendWrapper: any = document?.querySelector(queryString)
      if (legendWrapper) {
        legendWrapper.style.maxHeight = `${svgDimensions?.height}px`
      }
    }
  }, [svgDimensions?.height, props?.chartID])

  useEffect(() => {
    if (svgDimensions?.height && svgDimensions?.width) {
      updateConfig(config)
    }
  }, [config, svgDimensions?.height, svgDimensions?.width])

  useEffect(() => {
    if (svgDimensions?.height && svgDimensions?.width) {
      canvasChangeUpdateScale(config)
    }
  }, [svgDimensions?.height, svgDimensions?.width])

  return (
    <Fragment>
      {props.children}
    </Fragment>
  )
}

export default ChartSetup
