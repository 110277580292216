import IconSymbol from './components/IconSymbol'
import { Legend, LegendsGroup } from './types'

const containerDefaults: any = {
  containerClassName: '',
  containerStyle: null
}

const iconDefaults: any = {
  icon: null,
  iconProps: {},
  iconContainerClassName: ''
}

const legendDefaults: any = {
  isGroup: false,
  Template: null,
  disabled: false,
  clickable: true,
  hide: false
}

const textDefaults: any = {
  text: null,
  textClassName: '',
  textStyle: null
}

type LegendItemModel = Partial<Legend>

export function legendGroupItemModel(
  props: LegendItemModel | null = {}
): Legend {
  return {
    ...containerDefaults,
    ...iconDefaults,
    ...legendDefaults,
    ...textDefaults,
    text: props?.text || '',
    uuid: props?.uuid || '__group_item_' + Math.round(Math.random() * 10000000),
    ...props,
    isGroup: true,
    clickable: false,
    Icon: null
  }
}

export function legendItemModel(props: LegendItemModel = {}): Legend {
  return {
    ...containerDefaults,
    ...iconDefaults,
    ...legendDefaults,
    ...textDefaults,
    Icon: IconSymbol,
    text: props.text || '',
    uuid: props.uuid || '__legend_item_' + Math.round(Math.random() * 10000000),
    ...props
  }
}

export function legendGroupModel(
  legendItems: Legend[] = [],
  groupItem: Legend | null = null
): LegendsGroup {
  return {
    uuid: '__group_' + Math.round(Math.random() * 10000000),
    group: legendGroupItemModel(groupItem),
    items: legendItems.map(legendItemModel)
  }
}
