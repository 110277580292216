import LoadAnalysisContainer from './LoadAnalysisContainer'
import { LoadAnalysisContextProvider } from './LoadAnalysisContextProvider'

export const LoadAnalysis = () => {
  return (
    <LoadAnalysisContextProvider>
      <LoadAnalysisContainer />
    </LoadAnalysisContextProvider>
  )
}
