import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./building-selector.module.scss"
import { faBuildings } from "../lib/pro-solid-svg-icons";
import { getColor } from "../helpers";

export const OrganizationIcon = ({name=''}) => {
  const color = getColor(name);
  return (
    <div className={styles.buildingIcon} data-color={color}>
      <FontAwesomeIcon icon={faBuildings} />
    </div>
  )
}
