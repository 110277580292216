import React, { useEffect, useState } from 'react'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import { useTranslation } from 'react-i18next'
import {
  BaseLineComparisonInputWrapper,
  BaselineCreateMessageContainer
} from '../../styles'
import { useQuery } from 'src/hooks/APIHooks'
import {
  listBaselinesByAccount,
  listBaselinesByBuilding
} from 'src/pages/baselines/graphql'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { ACTIONS } from 'src/constants'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { Link } from 'react-router-dom'
import translate, { TranslateComponent } from 'src/common/translations'

const BaseLineComparisonInput = () => {
  const [t] = useTranslation()
  const [baseLineList, setBaseLineList] = useState([])
  const { buildingId, organizationId } = getSearchParams()
  const {
    setSelectedBaseLine,
    selectedBaseLine,
    widgetDetails,
    mode,
    objectPickerError
  } = useWidgetsContext()
  const {
    data: baselinesListData,
    refetch: refetchBaselinesList,
    responseTime
  } = useQuery(
    !buildingId
      ? {
          query: listBaselinesByAccount,
          dataPath: 'data.listBaselinesByAccount.items',
          variables: { accountId: organizationId },
          disableInitialLoad: true,
          errorPolicy: 'ignore'
        }
      : {
          query: listBaselinesByBuilding,
          dataPath: 'data.listBaselinesByBuilding.items',
          variables: { buildingId },
          disableInitialLoad: true,
          errorPolicy: 'ignore'
        }
  )

  useEffect(() => {
    if (buildingId) {
      refetchBaselinesList({ buildingId })
    } else if (organizationId) {
      refetchBaselinesList({ accountId: organizationId })
    }
  }, [buildingId, organizationId])

  useEffect(() => {
    if (baselinesListData?.length) {
      setBaseLineList(
        baselinesListData?.map((b) => ({
          key: b?.id,
          value: b?.name
        }))
      )
      if ([ACTIONS.EDIT, ACTIONS.COPY].includes(mode)) {
        if (widgetDetails) {
          const baselineId = widgetDetails?.baselineId
          setSelectedBaseLine(
            baselinesListData?.find((b) => b?.id === baselineId) ?? null
          )
        }
      }else{
        setSelectedBaseLine(
          baselinesListData?.[0] ?? null
        )
      }
    }
  }, [baselinesListData])

  return (
    <BaseLineComparisonInputWrapper>
      <SelectFormInput
        fieldsetClass="inline-2"
        labelName={"Widget Target"}
        optionsList={baseLineList}
        placeholder={'— Select One —'}
        update={(value) => {
          setSelectedBaseLine(
            baselinesListData?.find((b) => b?.id === value) ?? null
          )
        }}
        errorItem={objectPickerError ? "This value is required": null}
        selectedItem={selectedBaseLine?.name ?? ''}
      />
      <BaselineCreateMessageContainer>
        <span className="info-message">{translate("Don't see the chart you want?")}</span>
        <span className="link-message">
        <TranslateComponent>Go to the</TranslateComponent>{' '}
          <Link
            to={`/baseline-library?organization=${organizationId}&location=${buildingId}`}
          >
            <TranslateComponent>Baseline Library</TranslateComponent>
          </Link>{' '}
          <TranslateComponent>to create a baseline and Energy consumption chart.</TranslateComponent>
        </span>
      </BaselineCreateMessageContainer>
    </BaseLineComparisonInputWrapper>
  )
}

export default BaseLineComparisonInput
