import { PRIMARY_OFFERINGS } from "../common/primary-offerings"
import { PRIMARY_OFFERING } from "../common/properties"
import { DEFAULT_PROPERTIES_TO_SEND_BY_EVENT } from "../common"

export const USER_EVENTS = {
	UTILITY_BILLS: {
		categoryName: "Utility Bills",
		events: {
			SELECT_UTILITY_BILL_DATA_NAV_OPTION: "Select Utility Bill Data Nav Option",
			OPEN_UTILITY_BILL_DATA_PAGE: "Open Utility Bill Data Page",
			LOAD_UTILITY_BILL_DATA_PAGE: "Load Utility Bill Data Page",
			CHANGE_BILL_TAB: "Change Bill Tab",
			CLICK_IMPORT_BUTTON: "Click Import Button",
			DOWNLOAD_UPLOAD_TEMPLATE: "Download Upload Template",
			UPLOAD_BILL_DATA: "Upload Bill Data",
			DELETE_ROW: "Single Delete Utility Bill Data",
			DELETE_MULTIPLE_ROWS: "Multiple Delete Utility Bill Data",
			DOWNLOAD_UTILITY_BILL_DATA: "Download Utility Bill Data",
			CREATE_REPORT: "Click Create Report",
			VIEW_CHART: "Click View Chart",
		}
	}
}

export const PROPERTIES_TO_SEND_BY_EVENT = {
	[USER_EVENTS.UTILITY_BILLS.events.SELECT_UTILITY_BILL_DATA_NAV_OPTION]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT},
	[USER_EVENTS.UTILITY_BILLS.events.OPEN_UTILITY_BILL_DATA_PAGE]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT},
	[USER_EVENTS.UTILITY_BILLS.events.LOAD_UTILITY_BILL_DATA_PAGE]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT},
	[USER_EVENTS.UTILITY_BILLS.events.CHANGE_BILL_TAB]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT },
	[USER_EVENTS.UTILITY_BILLS.events.CLICK_IMPORT_BUTTON]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT },
	[USER_EVENTS.UTILITY_BILLS.events.DOWNLOAD_UPLOAD_TEMPLATE]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT },
	[USER_EVENTS.UTILITY_BILLS.events.UPLOAD_BILL_DATA]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT },
	[USER_EVENTS.UTILITY_BILLS.events.DELETE_ROW]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT },
	[USER_EVENTS.UTILITY_BILLS.events.DELETE_MULTIPLE_ROWS]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT },
	[USER_EVENTS.UTILITY_BILLS.events.DOWNLOAD_UTILITY_BILL_DATA]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT },
	[USER_EVENTS.UTILITY_BILLS.events.CREATE_REPORT]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT },
	[USER_EVENTS.UTILITY_BILLS.events.VIEW_CHART]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT },
}
