import styled, { css } from 'styled-components'
import colors from 'src/components/layouts/colors.json'
import style from '../equipmentSetup/EquipmentDetails/Characteristics/style'

export const WidgetTypePickerStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  width: auto;
  width: initial;

  & > h3 {
    margin-top: 0;
  }

  & .modal-panel {
    padding: 0px;
  }

  & .widget-type-picker {
    justify-content: space-around;
    border: 1px solid ${colors.TBA_DARK_GREY};
    border-radius: 2px;
    background-color: ${colors.TBA_LIGHTEST_GREY};
    overflow-y: auto;
    overflow-x: hidden;

    & .widget-type-cards {
      & > section {
        margin: 20px;
        padding: 0px;
        column-gap: 0px;
      }
    }

    & .widget-type-card {
      width: 360px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      box-sizing: content-box;
      min-height: 172px;
      @media all and (max-width: 519px) {
        width: 90%;
      }
      @media all and (max-width: 899px) {
        width: 90%;
      }
      @media all and (max-width: 709px) {
        width: 90%;
      }
    }

    & .widget-type-info {
      position: relative;
      z-index: 1;
      width: 100%;
      border: 1px solid ${colors.TBA_DARK_GREY};
      border-radius: 6px;
      background-color: ${colors.WHITE};
      box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-flow: row;
      align-items: flex-start;
      padding: 16px;
      box-sizing: border-box;
    }

    & .widget-type-image {
      max-height: 153px;
      cursor: pointer;

      @media all and (max-width: 519px) {
        & {
          display: none;
        }
      }
    }

    & .widget-type-text {
      margin-right: 16px;
      min-height: 146px;
      max-height: 300px;
      height: 146px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      width: 100%;
      position: relative;

      @media all and (max-width: 519px) {
        & {
          margin-right: 0;
          height: 160px;
        }
      }

      & h2 {
        margin-bottom: 5px;
        margin-top: 3px;
      }

      & p {
        margin-top: 0;
        font-size: 12px;
      }

      & > div:first-child {
        cursor: pointer;
      }

      & .widget-type-description-ellipsis{
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
    }

    & .widget-type-menu {
      position: relative;
      z-index: 0;
      display: flex;
      width: 100%;
      height: 22px;
      margin: 0 auto;
      margin-top: -1px;
      background-color: ${colors.WHITE};
      justify-content: space-between;
      align-items: center;

      & .anchor-link {
        font-size: 10px;
        font-weight: 900;
        text-decoration: underline;
        text-transform: uppercase;
        color: ${colors.TBA_DARKER_GREY};
        cursor: pointer;
        border: none;
        background-color: transparent;
      }

      & button {
        margin: 0;
      }

      & .btn-width-alignment {
        width: auto;
        white-space: nowrap;
      }
    }

    & .widget-type-card.list {
      & .widget-type-info {
        flex-flow: column;
      }

      & .widget-type-header {
        width: 100%;
        margin-bottom: 10px;

        & h2 {
          margin: 5px 0 3px 0;
        }
        & p {
          margin-top: 0;
          font-size: 12px;
        }
        & .widget-header-ellipsis{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        & .widget-description-ellipsis{
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }
      }

      & .widget-type-image {
        width: 50%;
      }

      & .widget-type-body {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        width: 100%;
      }

      & .widget-type-text {
        width: 58%;

        & > div:first-child {
          margin-right: 10px;
        }
      }

      & .widget-type-menu {
        margin: 0;
      }
    }
  }

  dialog.x-wide {
    width: 832px;
    $margin: calc((100% - #{$widget-card-width} * 2) / 2.3);

    .dialog-body {
      padding-bottom: 20px;
    }

    & .widget-type-card {
      padding: 20px 0 0 $margin;

      &:last-child {
        padding-bottom: 20px;
      }
    }
  }
`

export const WidgetPreivewStyleWrapper = styled.div`
        & .sample-image {
            display: flex;
            height:100%;
            justify-content: center;
        }
    }
`

export const TabsWrapper = styled.div`
  width: 98%;
  margin: 0 auto;
  li {
    margin: 0 5px;
    border: none;
    background-color: ${colors.TBA_LIGHT_GREY};
    color: ${colors.RAISIN_BLACK};
    font-weight: 600;
    border-radius: 5px 5px 0 0 !important;
    justify-content: flex-start;
    font-size: 13px;
    padding-left: 15px;
  }
  .active {
    background-color: ${colors.WHITE};
    color: ${colors.BLACK};
  }
  li.error {
    color: ${colors.RED};
  }
`

export const WidgetCreateWrapper = styled.div`
  & > section {
    padding: 0px;
    column-gap: 0px;
  }

  & article:first-child {
    padding: 10px;
  }
  & .modal-text-wrapper .custom-label {
    padding: 0px !important;
  }
`
export const TabWrapper = styled.div`
    min-height: 480px;
    & > div {
        margin-bottom: 16px;
    }
    & > span {
        margin-bottom: 16px;
    }
    & > legend{
        font-size: 13px;
        font-weight: 700;
        letter-spacing: .26px;
        line-height: 15px;
        padding: 0;
        width: 100%;
        }
    }

    & .section-spinner {
        height: 26px;
        width: 10px;
        margin: 0;

        & .icon.spin-wheel {
          font-size: 18px;
        }
    }
`

export const WidgetLinkTestWrapper = styled.div`
  display: flex;
  margin-top: -10px;
  & .tip {
    margin: 0;
    padding: 0;
    color: ${colors.TBA_DARK_GREY};
    font-size: 10px;
    font-style: italic;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.2px;
  }
`
export const RadioButtonWrapper = styled.div`
  & > fieldset {
    padding: 0;
    margin-top: 16px;
  }
`
export const CustomKPIRadioButtonWrapper = styled.div`
  fieldset{
    display: block;
  }
  & > fieldset {
    padding: 0;
    margin-top: 16px;
  }
`
export const ErrorText = styled.label`
    {
        color:${colors.RED};\
        font-size: 10px;
        font-style: italic;
        height: 16px;
        letter-spacing: 0.2px;
        line-height: 16px;
        width: 100%
    }
`

export const ImageUploaderWrapper = styled.div`
  & .upload-bounding-area {
    margin-bottom: 0px;
  }
`

export const PanelLabel = styled.label`
  display: block;
  height: 16px;
  width: 110%;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 15px;
  & > div {
    text-transform: capitalize;
    display: inline-block;
  }
`
export const PanelHr = styled.hr`
  position: relative;
  top: 2px;
  border: none;
  height: 2px;
  background: black;
  margin-bottom: 10px;
`

export const ImagePreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 495px;
  & .tab-preview {
    .img-box {
      margin-top: 60px;
    }
    .error-message {
      background: ${colors.WHITE};
      border: 2px solid ${colors.RED};
      display: inline-flex;
      padding: 10px;
      .icon-error {
        font-size: 22px;
        margin-right: 9px;
      }
      .error-text {
        color: ${colors.RED};
        font-size: 12px;
        font-style: italic;
        font-weight: 700;
        letter-spacing: 0.26px;
        line-height: 15px;
        margin-left: 9px;
        margin-top: 4px;
        text-align: left;
      }
    }

    .preview-container {
      text-align: center;
      width: 320px;

      .error-message {
        width: 100%;
        margin: 20px 0 50px 0;
      }

      .cell {
        min-width: 292px;
        margin: calc(20px + 50px + 46px) auto 0;
      }
    }

    .widget {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
      background: ${colors.WHITE};
      border: 1px solid ${colors.TBA_DARK_GREY};
      border-radius: 6px;
      box-shadow: 3px 4px 4px #00000033;
      box-sizing: content-box;
      overflow: hidden;

      .widget-header {
        display: flex;
        align-items: center;
        height: 35px;
        padding: 0 10px 0 16px;
        background: ${colors.TBA_MEDIUM_GREEN};
        color: ${colors.WHITE};
        border-radius: 5px 5px 0px 0px;
        border-bottom: 1px solid ${colors.TBA_DARK_GREY};
      }

      .widget-title {
        margin-right: auto;
        pointer-events: none;
      }

      .widget-content {
        height: calc(100% - 36px);
        box-sizing: border-box;
        padding: 8px 16px;
        overflow: hidden;
        position: relative;

        .container-building {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .location-label {
            text-align: left;
            margin-right: 50px;
            font-size: 12px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .icon-location {
            font-size: 16px;
            margin-right: 5px;
          }
        }
        .container-image {
          text-align: center;
          margin-bottom: 10px;
          background-color: ${colors.TBA_LIGHT2_GREY};
          position: relative;

          .current-label {
            margin-top: 50px;
            font-size: 14px;
            font-weight: 700;
          }
          .image-section {
            display: flex;
            align-items: center;
            img {
              margin-right: 10px;
              margin-left: 10px;
            }

            .metric-value {
              font-size: 32px;
              font-weight: bold;
            }
            .metric {
              font-size: 16px;
              margin-top: 10px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`

export const CustomKPIConfigurationWrapper = styled.div`
  min-height: 500px;
  & .kpi-configuration-main {
    display: flex;
  }
  & label.error {
    color: ${colors.RED};
  }
  & .select-wrapper.error {
    background: ${colors.RED};
  }
  & input.error {
    background: ${colors.RED_1};
  }
  & p.error {
    color: ${colors.RED};
    font-size: 10px;
  }

  & legend {
    font-size: 14px;
  }
  .show-point-label {
    display: flex;
  }
  & .select-wrapper {
    width: 250px;
  }
  & label {
    padding: 0;
    margin-top: 16px;
  }
  & .period-inputs {
    & input {
      width: 250px;
      margin-left: 2px;
    }
  }

  & .icon-container {
    margin-left: 25px;
    & label {
      font-size: 13px;
      font-family: 'Lato', sans-serif;
    }
    & .upload-bounding-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 140px;
      width: 220px;
      padding: 0 8px;
      background-color: ${colors.TBA_LIGHTEST_GREY};
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 1px solid ${colors.TBA_DARK_GREY};
      border-radius: 2px;
      cursor: pointer;
    }
    & .upload-bounding-area > div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background-color: ${colors.BLUE};

      & > span {
        color: ${colors.WHITE};
        font-size: 90px;
      }
    }
  }

  & .kpi-table {
    width: 480px;
    margin-top: 16px;
    @media all and (max-width: 519px) {
      display: contents;
    }
    @media all and (max-width: 899px) {
      display: contents;
    }
    @media all and (max-width: 709px) {
      display: contents;
    }
  }
`
export const HeaderButtons = styled.div`
  display: flex;
`
export const IconButton = styled.button<{ isGrey: boolean }>`
  width: 40px;
  font-size: 25px;
  ${(props) => (props?.isGrey ? 'color:#666' : '')};
  border: none;
`
export const CreateWidgetButton = styled.button`
  margin-bottom: 5px;
`
export const WidgetListWrapper = styled.div`
  & .custom-component {
    display: flex;
    .data-span {
      margin-left: 5px;
    }
  }
  & .action{
    cursor: pointer;
    text-decoration: underline;
  }
    table tbody td:nth-child(5){
      .conditional-tooltip{

    .conditional-tooltip__children--content  { 
      text-decoration: underline!important;
    }
  }
  }
  }
`

export const DataMonitoringColorsWrapper = styled.div`
  & .tab-page-content {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2px;
  }
  & label.error {
    color: ${colors.RED};
  }
  & .select-wrapper.error {
    background: ${colors.RED};
  }
  & input.error {
    background: ${colors.RED_1};
  }
  & p.error {
    color: ${colors.RED};
    font-size: 10px;
  }
  & .colors-tab-main {
    min-height: 480px;
    display: flex;
  }
  & fieldset {
    border: 0;
    margin: 9px 0 0;
    padding: 0 0px 12px;
  }
  & .color-input {
    border: 1px solid #999;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    height: 26px;
    width: 40px;
  }
  .threshold-item {
    align-items: flex-end;
    display: flex;
    clear: both;
    margin-top: 5px;
    & input {
      width: 60px;
      margin-left: 10px;
      margin-top: -20px;
    }
    & span {
      margin-left: 10px;
    }
  }
`
export const BaseLineConfigurationWrapper = styled.div`
  & .configuration-tab-main {
    min-height: 500px;
    display: flex;
  }
  fieldset {
    border: 0;
    margin: 9px 0 0;
    padding: 0 6px 9px;
  }
  & .selected-label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 26px;
    overflow: hidden;
    padding: 0 8px;
    text-overflow: ellipsis;
    // white-space: nowrap;
    max-width: 450px;
  }
`
export const DataMonitoringConfigurationWrapper = styled.div`
  width: 100%;

  & .configuration-tab-main {

    div:first-child {
      width: 100%;
    }

    min-height: 500px;
    display: flex;
    width: 100%;

    .select-wrapper {
      max-width: 230px;
      min-width: 230px;
    }

  }
  .inline-control {
    display: flex;
    @media all and (max-width: 519px) {
      display: grid;
    }
    @media all and (max-width: 899px) {
      display: grid;
    }
    @media all and (max-width: 709px) {
      display: grid;
    }
  }
  .max-content {
    grid-row: row;
    margin-top: 10px;
    margin-left: 8px;
  }
  .gauge-min {
    margin-top: 5px;
  }
  & label.error {
    color: ${colors.RED};
  }
  & .select-wrapper.error {
    background: ${colors.RED};
  }
  & input.error {
    background: ${colors.RED_1};
  }
  & p.error {
    color: ${colors.RED};
    font-size: 10px;
  }
  & .tab-page-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2px;
  }
  fieldset {
    border: 0;
    margin: 9px 0 0;
    padding: 0 6px 9px;
  }

  fieldset.inline-2 {
    float: left;
    width: 50%;
  }
  .row-half-container {
    align-items: center;
    display: flex;
    width: 100%;

    .error-text-box {
      background: ${colors.LIGHT_RED};
      border: 2px solid ${colors.RED};
    }

    & > * {
      margin-right: 9px;
    }
    & span {
      font-weight: bold;
    }
    &.half-items {
      & > .half-items {
        max-width: 50%;
        margin-left: 0;
      }
      & input {
        margin-top: 15px;
        width: 200px;
      }
    }

    & input {
      width: 110px;
    }
  }
`

export const BenchMarkStyleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap;
  fieldset {
    div {
      max-width: 160px;
      min-width: 120px;
    }
    input {
      width: 100%;
    }
  }
  fieldset:nth-child(2) {
    width: 20%;
    padding-left: 0px;
  }
  fieldset:nth-child(3) {
    width: 43%;
  }
  label + input {
    margin-left: 0px;
  }

  @media all and (max-width: 519px) {
    display: grid;
    fieldset {
      div {
        max-width: 200px;
      }
    }
    fieldset:nth-child(2) {
      width: 210px;
      margin-left: 5px;
    }
    fieldset:nth-child(3) {
      width: 215px;
    }
  }
  @media all and (max-width: 899px) {
    display: grid;
    fieldset {
      div {
        max-width: 200px;
      }
    }
    fieldset:nth-child(2) {
      width: 210px;
      margin-left: 5px;
    }
    fieldset:nth-child(3) {
      width: 215px;
    }
  }
  @media all and (max-width: 709px) {
    display: grid;
    fieldset {
      div {
        max-width: 200px;
      }
    }
    fieldset:nth-child(2) {
      width: 210px;
      margin-left: 5px;
    }
    fieldset:nth-child(3) {
      width: 215px;
    }
  }
`

export const BenchMarkCompareStyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  & .item {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
    .multiple-right-object {
      margin-top: -30px;
    }
    fieldset {
      div {
        max-width: 160px;
        min-width: 120px;
      }
      input {
        width: 100%;
        margin-top: 16px;
      }
    }
    fieldset:nth-child(2) {
      width: 20%;
      padding-left: 0px;
    }
    fieldset:nth-child(3) {
      width: 38%;
    }
    fieldset:nth-child(4) {
      width: 100%;
    }
    label + input {
      margin-left: 0px;
      width: 90%;
    }
    label {
      max-width: 300px;
      min-width: 240px;
      @media all and (max-width: 1195px) {
        max-width: 100%;
        min-width: 100%;
      }
      @media all and (max-width: 519px) {
        max-width: 300px;
        min-width: 300px;
      }
      @media all and (max-width: 899px) {
        max-width: 300px;
        min-width: 300px;
      }
      @media all and (max-width: 709px) {
        max-width: 300px;
        min-width: 300px;
      }
    }
  }
  & .vertical {
    margin-left: 5px;
    border: 1px solid black;
    margin-right: 12px;
  }

  @media all and (max-width: 519px) {
    display: contents;
    .vertical {
      border: none;
    }
  }
  @media all and (max-width: 899px) {
    display: contents;
    .vertical {
      border: none;
    }
  }
  @media all and (max-width: 709px) {
    display: contents;
    .vertical {
      border: none;
    }
  }
`

export const WidgetPropertyUomWrapper = styled.div<{ propertyValue: string }>`
  ${(props: any) =>
    props.propertyValue === 'leftProperty' || props.propertyValue === 'rightProperty'
      ? css`
          display: block;
        `
      : css`
          display: flex;
        `}
  @media all and (max-width: 519px) {
    display: grid;
  }
  @media all and (max-width: 899px) {
    display: grid;
  }
  @media all and (max-width: 709px) {
    display: grid;
  }
`
export const BaseLineComparisonInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  fieldset {
    border: 0;
    margin: 9px 0 0;
    padding: 0 6px 9px;
  }
`

export const BaselineCreateMessageContainer = styled.div`
  line-height: 1.4;
  a {
    color: black;
  }
  .info-message {
    font-weight: 600;
    display: inline-block;
    width: 100%;
  }
  .link-message {
    display: block;
  }
`

export const TimeComparisonBarChartConfigStyles = styled.div`
  fieldset {
    border: 0;
    margin: 9px 0 0;
    padding: 0 6px 9px;
  }

  .tab-page-content {
    height: 480px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2px;
    @media all and (max-width: 519px) {
      height: 1050px;
    }
    @media all and (max-width: 899px) {
      height: 1050px;
    }
    @media all and (max-width: 709px) {
      height: 1050px;
    }

    & .select-wrapper {
      max-width: 230px;
      min-width: 230px;
    }

    .inline-2 {
      float: left;
      margin-bottom: 9px;
      width: 50%;
      &.benchmark-input {
        & input {
          min-width: 210px;
          max-width: 210px;
          margin-left: -1px;
        }
      }
    }

    .row-half-container {
      width: 100%;
      display: flex;
      align-items: center;

      .error-text-box {
        background: ${colors.LIGHT_RED};
        border: 2px solid ${colors.RED};
      }

      & > * {
        margin-right: 6px;
      }

      &.half-items {
        & > * {
          flex: 1 0 auto;
        }
        .max-select {
          margin-left: -6px;
        }
        & > .half-items {
          max-width: 50%;
          margin-left: 0;
        }

        & .input-max {
          & .select-wrapper {
            margin-top: 8px;
          }
          & input {
            max-width: 200px;
            min-width: 200px;
            margin-top: 8px;
            &.error {
              margin-top: 20px;
            }
          }
          @media all and (max-width: 519px) {
            display: inherit;
            margin-left: -2px;
          }
          @media all and (max-width: 899px) {
            display: inherit;
            margin-left: -2px;
          }
          @media all and (max-width: 709px) {
            display: inherit;
            margin-left: -2px;
          }
        }
      }
      @media all and (max-width: 519px) {
        display: inherit;
      }
      @media all and (max-width: 899px) {
        display: inherit;
      }
      @media all and (max-width: 709px) {
        display: inherit;
      }
    }
    .inline-2-container {
      float: left;
      width: 50%;
      .thirds-items {
        display: flex;
      }
      &:nth-child(odd):not(.no-line) {
        border-left: 2px solid ${colors.BLACK};
      }

      & .row-thirds-container {
        width: 100%;
        display: flex;
        align-items: center;

        & > * {
          margin-right: 9px;
        }

        & .one-third-items {
          flex: 1 0 auto;
          width: calc(100% / 3 * 1);
          & .select-wrapper {
            max-width: 100px;
            min-width: 100px;
            margin-left: 30px;
            @media all and (max-width: 519px) {
              margin-left: -20px;
            }
            @media all and (max-width: 899px) {
              margin-left: -20px;
            }
            @media all and (max-width: 709px) {
              margin-left: -20px;
            }
          }
          & input {
            margin-top: 15px;
            margin-left: 20px;
            &.error {
              margin-top: 25px;
            }
            @media all and (max-width: 519px) {
              margin-left: -20px;
              margin-bottom: 10px;
            }
            @media all and (max-width: 899px) {
              margin-left: -20px;
              margin-bottom: 10px;
            }
            @media all and (max-width: 709px) {
              margin-left: -20px;
              margin-bottom: 10px;
            }
          }
          & p {
            margin-left: 20px;
            @media all and (max-width: 519px) {
              margin-left: -20px;
            }
            @media all and (max-width: 899px) {
              margin-left: -20px;
            }
            @media all and (max-width: 709px) {
              margin-left: -20px;
            }
          }
        }

        & .two-third-items {
          flex: 1 0 auto;
          width: calc(100% / 3 * 2 - 9px);
          & .select-wrapper {
            max-width: 100px;
            min-width: 100px;
          }
        }

        .benchmark {
          margin-left: -6px;
          flex-flow: column;
          align-items: flex-start;
          & > * {
            margin-right: -50px;
            max-width: 50px;
            min-width: 100px;
          }
        }

        & input {
          max-width: 200px;
        }
      }
    }

    @media all and (max-width: 519px) {
      .inline-2-container {
        float: none;
        &:nth-child(odd):not(.no-line) {
          border-left: none;
        }
        & label {
          width: 200px;
        }
      }
    }
    @media all and (max-width: 899px) {
      .inline-2-container {
        float: none;
        &:nth-child(odd):not(.no-line) {
          border-left: none;
        }
        & label {
          width: 200px;
        }
      }
    }
    @media all and (max-width: 709px) {
      .inline-2-container {
        float: none;
        &:nth-child(odd):not(.no-line) {
          border-left: none;
        }
        & label {
          width: 200px;
        }
      }
    }

    @media all and (max-width: 915px) {
      .inline-2-container {
        &:nth-child(odd):not(.no-line) {
          border-left: none;
        }
        .benchmark-input {
          & input {
            max-width: 225px;
            min-width: 225px;
          }
        }
      }
      .row-half-container {
        .input-max {
          margin-left: -140px;
        }
      }
    }

    @media all and (max-width: 1195px) {
      .inline-2-container {
        &:nth-child(odd):not(.no-line) {
          border-left: none;
        }
        .benchmark-input {
          & input {
            max-width: 225px;
            min-width: 225px;
          }
        }
      }
    }
  }
`
export const EnergyConsumptionConfigStyles = styled.div`
  fieldset {
    border: 0;
    margin: 9px 0 0;
    padding: 0 6px 9px;
  }
  .tip {
    color: #999;
    font-size: 10px;
    font-style: italic;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 12px;
    padding-top: 0px;
  }

  .tab-page-content {
    height: 480px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2px;
    @media all and (max-width: 519px) {
      height: 1050px;
    }
    @media all and (max-width: 899px) {
      height: 1050px;
    }
    @media all and (max-width: 709px) {
      height: 1050px;
    }

    .disable-controls {
      pointer-events: none;
      opacity: 0.4;
    }

    & .select-wrapper {
      max-width: 220px;
      min-width: 220px;
    }

    .benchmark-inputs {
      display: flex;
      flex-wrap: wrap;
      & input {
        min-width: 220px;
        max-width: 220px;
        margin-top: 5px;
        @media all and (max-width: 519px) {
          margin-left: 5px;
        }
      }
      @media all and (max-width: 519px) {
        display: grid;
      }
      @media all and (max-width: 899px) {
        display: grid;
      }
      @media all and (max-width: 709px) {
        display: grid;
      }
    }

    .benchmark-label {
      min-width: 220px;
      max-width: 220px;
    }
  }
`
export const EnergyCostConfigStyles = styled.div`
  fieldset {
    border: 0;
    margin: 9px 0 0;
    padding: 0 6px 9px;
  }
  .tip {
    color: #999;
    font-size: 10px;
    font-style: italic;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 12px;
    padding-top: 0px;
  }

  .tab-page-content {
    height: 480px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2px;
    @media all and (max-width: 519px) {
      height: 1050px;
    }
    @media all and (max-width: 899px) {
      height: 1050px;
    }
    @media all and (max-width: 709px) {
      height: 1050px;
    }

    .disable-controls {
      pointer-events: none;
      opacity: 0.4;
    }

    & .select-wrapper {
      max-width: 230px;
      min-width: 230px;
    }

    .benchmark-inputs {
      display: flex;
      flex-wrap: wrap;
      & input {
        min-width: 220px;
        max-width: 220px;
        margin-top: 5px;
        @media all and (max-width: 519px) {
          margin-left: 5px;
        }
      }
      @media all and (max-width: 519px) {
        display: grid;
      }
      @media all and (max-width: 899px) {
        display: grid;
      }
      @media all and (max-width: 709px) {
        display: grid;
      }
    }

    .benchmark-label {
      min-width: 220px;
      max-width: 220px;
    }
  }
`
export const EnergyDemandConfigStyles = styled.div`
  fieldset {
    border: 0;
    margin: 9px 0 0;
    padding: 0 6px 9px;
  }
  .tip {
    color: #999;
    font-size: 10px;
    font-style: italic;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 12px;
    padding-top: 0px;
  }

  .rate-increase-block {
    margin-left: 10px;
    .rate-increase-item {
      display: flex;
      margin-bottom: 10px;

      .label {
        width: 25%;

        > div {
          line-height: 28px;
          font-size: 12px;
          font-style: italic;
          font-weight: bold;
          color: $tba-dark-grey;
        }
      }

      .rate-increases-timepickers,
      .rate-increases-timepickers-labels {
        display: flex;
        width: 70%;
        max-width: 270px;

        > div {
          width: 50%;
          max-width: 170px;
          margin: 0 5px;
        }
      }

      .buttons {
        height: 28px;
        line-height: 32px;
        align-self: flex-end;
        padding-left: 10px;

        .icon {
          cursor: pointer;
          font-size: 1.4em;
          color: $tba-darker-grey;
          margin-left: 10px;
        }
      }

      .timepicker-react {
        display: flex;
        align-items: center;
      }
    }
  }

  .tab-page-content {
    height: 480px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2px;
    @media all and (max-width: 519px) {
      height: 1050px;
    }
    @media all and (max-width: 899px) {
      height: 1050px;
    }
    @media all and (max-width: 709px) {
      height: 1050px;
    }

    .disable-controls {
      pointer-events: none;
      opacity: 0.4;
    }

    & .select-wrapper {
      max-width: 220px;
      min-width: 220px;
    }

    .benchmark-inputs {
      display: flex;
      flex-wrap: wrap;
      & input {
        min-width: 220px;
        max-width: 220px;
        margin-top: 5px;
        @media all and (max-width: 519px) {
          margin-left: 5px;
        }
      }
      @media all and (max-width: 519px) {
        display: grid;
      }
      @media all and (max-width: 899px) {
        display: grid;
      }
      @media all and (max-width: 709px) {
        display: grid;
      }
    }

    .benchmark-label {
      min-width: 220px;
      max-width: 220px;
    }
  }
`
export const DataComparisonBarChartConfigStyles = styled.div`
  fieldset {
    border: 0;
    margin: 9px 0 0;
    padding: 0 6px 9px;
  }

  .tab-page-content {
    height: 480px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2px;


    @media all and (max-width: 519px) {
      height: 1350px;
    }
    @media all and (max-width: 899px) {
      height: 1350px;
    }
    @media all and (max-width: 709px) {
      height: 1350px;
    }

    & .select-wrapper {
      max-width: 220px;
      min-width: 220px;
    }

    .inline-2 {
      float: left;
      margin-bottom: 9px;
      width: 50%;
      &.benchmark-input {
        & input {
          min-width: 210px;
          max-width: 210px;
          margin-left: -1px;
        }
      }
      &.property-uom {
        margin-left: -5px;
        margin-bottom: -10px;
        margin-top: -5px;
      }
      &.multiple-object-property-uom {
        margin-left: -5px;
        margin-bottom: -10px;
        margin-top: 10px;
      }
      &.select-more-equipment {
        min-width: 200px;
        max-width: 200px;
        margin-top: -5px;
      }
    }

    .row-half-container {
      width: 100%;
      display: flex;
      align-items: center;

      .error-text-box {
        background: ${colors.LIGHT_RED};
        border: 2px solid ${colors.RED};
      }

      & > * {
        margin-right: 9px;
      }

      &.half-items {
        & > * {
          flex: 1 0 auto;
        }
        .max-select {
          margin-left: -6px;
        }
        & > .half-items {
          max-width: 50%;
          margin-left: 0;
        }

        & .input-max {
          & .select-wrapper {
            margin-top: 8px;
          }
          & input {
            max-width: 200px;
            min-width: 200px;
            margin-top: 8px;
            &.error {
              margin-top: 20px;
            }
          }
          @media all and (max-width: 519px) {
            display: inherit;
            margin-left: -2px;
          }
          @media all and (max-width: 899px) {
            display: inherit;
            margin-left: -2px;
          }
          @media all and (max-width: 709px) {
            display: inherit;
            margin-left: -2px;
          }
        }
      }
      @media all and (max-width: 519px) {
        display: inherit;
      }
      @media all and (max-width: 899px) {
        display: inherit;
      }
      @media all and (max-width: 709px) {
        display: inherit;
      }
    }
    .inline-2-container {
      float: left;
      width: 50%;
      .target-label {
        margin-bottom: -15px;
      }
      .single-object {
        margin-bottom: 10px;
        margin-top: 0px;
      }
      .multiple-right-object {
        margin-bottom: 8px;
        margin-top: 0px;
      }
      .thirds-items {
        display: flex;
      }
      &:nth-child(even):not(.no-line) {
        border-left: 2px solid ${colors.BLACK};
      }

      & .row-thirds-container {
        width: 100%;
        display: flex;
        align-items: center;

        & > * {
          margin-right: 9px;
        }

        & .one-third-items {
          flex: 1 0 auto;
          width: calc(100% / 3 * 1);
          & .select-wrapper {
            max-width: 100px;
            min-width: 100px;
            margin-left: 30px;
            @media all and (max-width: 519px) {
              margin-left: -20px;
            }
            @media all and (max-width: 899px) {
              margin-left: -20px;
            }
            @media all and (max-width: 709px) {
              margin-left: -20px;
            }
          }
          & input {
            margin-top: 15px;
            margin-left: 20px;
            &.error {
              margin-top: 25px;
            }
            @media all and (max-width: 519px) {
              margin-left: -20px;
              margin-bottom: 10px;
            }
            @media all and (max-width: 899px) {
              margin-left: -20px;
              margin-bottom: 10px;
            }
            @media all and (max-width: 709px) {
              margin-left: -20px;
              margin-bottom: 10px;
            }
          }
          & p {
            margin-left: 20px;
            @media all and (max-width: 519px) {
              margin-left: -20px;
            }
            @media all and (max-width: 899px) {
              margin-left: -20px;
            }
            @media all and (max-width: 709px) {
              margin-left: -20px;
            }
          }
        }

        & .two-third-items {
          flex: 1 0 auto;
          width: calc(100% / 3 * 2 - 9px);
          & .select-wrapper {
            max-width: 100px;
            min-width: 100px;
          }
        }

        .benchmark {
          margin-left: -6px;
          flex-flow: column;
          align-items: flex-start;
          & > * {
            margin-right: -50px;
            max-width: 50px;
            min-width: 100px;
          }
        }

        & input {
          max-width: 200px;
        }
      }
    }

    @media all and (max-width: 519px) {
      .inline-2-container {
        float: none;
        &:nth-child(even):not(.no-line) {
          border-left: none;
        }
        & label {
          width: 200px;
        }
      }
    }
    @media all and (max-width: 899px) {
      .inline-2-container {
        float: none;
        &:nth-child(even):not(.no-line) {
          border-left: none;
        }
        & label {
          width: 200px;
        }
      }
    }
    @media all and (max-width: 709px) {
      .inline-2-container {
        float: none;
        &:nth-child(even):not(.no-line) {
          border-left: none;
        }
        & label {
          width: 200px;
        }
      }
    }

    @media all and (max-width: 915px) {
      .inline-2-container {
        &:nth-child(even):not(.no-line) {
          border-left: none;
        }
        .benchmark-input {
          & input {
            max-width: 225px;
            min-width: 225px;
          }
        }
      }
      .row-half-container {
        .input-max {
          margin-left: -140px;
        }
      }
    }

    @media all and (max-width: 1195px) {
      .inline-2-container {
        &:nth-child(even):not(.no-line) {
          border-left: none;
        }
        .benchmark-input {
          & input {
            max-width: 225px;
            min-width: 225px;
          }
        }
      }
    }
  }
`
export const StyledTextWrapper = styled.p`
  :before {
    padding-right: 10px;
  }
`
export const WidgetListWrappers = styled.div`
  & .custom-component {
    display: flex;
    flex-direction: column .data-span {
      margin-left: 5px;
    }
  }
`
export const CustomKPIWidgetTopSection = styled.div`
  display: grid;
  grid-template-columns: 4;
  grid-template-rows: 2;
  gap: 5px;
  margin-left: 20px;
  & .item1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.BLUE};
    border-radius: 5px 0px 0px 0px;
    & > span {
      color: ${colors.WHITE};
      font-size: 40px;
      /* font-size: 50px; */
    }
  }
  & .item2 {
    grid-column-start: 2;
    grid-column-end: 5;
    background-color: ${colors.BLUE};
    border-radius: 0px 5px 0px 0px;
    display: flex;
    align-items: center;
    & > span {
      display: flex;
      justify-content: flex-start;
      color: ${colors.WHITE};
      font-weight: 900;
      text-transform: uppercase;
      margin-left: 5px;
      /* font-size: 12px; */
    }
  }
  & .item3 {
    grid-column-start: 2;
    grid-column-end: 4;
    background-color: ${colors.TBA_LIGHT_GREEN};
    display: flex;
    align-items: center;
    & > span {
      color: ${colors.WHITE};
      font-weight: 900;
      text-transform: uppercase;
      margin-left: 5px;
    }
  }
  & .item4 {
    background-color: ${colors.GREEN_5};
    /* display: flex; */
    text-align: center;
    align-items: center;
    justify-content: center;
    & > span {
      display: flex;
      /* max-width: 20px; */
      text-align: center;
      align-items: center;
      justify-content: center;
      color: ${colors.WHITE};
      font-weight: bold;
      line-height: 10px;
    }
  }
`

export const CustomKPIWidgetColorSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: center;
  & .main-div {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  & .commonBox {
    display: flex;
    height: 12px;
    width: 12px;
    background-color: red;
  }
  & .commonText {
    margin-left: 5px;
    font-weight: bold;
  }
`

export const CustomKPITrendBottomSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
`
export const CheckBoxWrapper = styled.div`
.checkbox-group{
  margin-top:10px;
}
input[type=checkbox]{
  opacity: 1;
  position: relative;
  accent-color:#367326;
}
input:focus{
box-shadow: none;
}
`