import * as d3 from "d3";
import { isNumber } from 'lodash'

export type d3Properties = keyof typeof d3

export const d3Picker = (property: d3Properties) => {
    return (d3 as any)[property]
}

export const isNumberAndIsNotaNaN = (val: any) => {
    return isNumber(val) && !isNaN(val)
}