import { useEffect, useMemo, useState } from 'react'
import { Accordion } from 'src/components/layouts/Accordion'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { GET_CONNECTION_TYPES, GET_BUILDING_BY_ID } from '../graphql/queries'
import { useParams } from 'react-router-dom'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { Block, StyledContainer } from 'src/components/layouts'
import { Button } from 'src/components/inputs/button'
import { parseSalesOffice } from '../helpers'
import Icon from 'src/components/Icon'
import GeoLocationDetails from './GeoLocationDetails'
import BuildingForm from '../createBuilding/form'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import { useTranslation } from 'react-i18next'
import { FIELD_RESTRICTIONS } from '../createBuilding/form'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  StyledLabel,
  StyledInfo,
  StyledTitle,
  StyledDetails,
  StyledNoOfferingsDiv,
  StyledCustomLink
} from './style'
import classNames from 'classnames'
import { ACTIONS, ERROR_HEADERS } from '../createBuilding/constants'
import {
  CREATE_BUILDING,
  UPDATE_BUILDING,
  CREATE_DEVICE,
  UPDATE_DEVICE,
  UPDATE_UTILITY_BILL
} from '../graphql/mutations'
import {
  parseRequest,
  prepareInitialValues,
  parseDeviceRequest
} from '../createBuilding/parseReqRes'
import { allTisOfferings } from '../createBuilding/mock'
import { getMessage } from '../createBuilding/constants'
import EditableAccountInformation from './sections/withAccountInformation'
import { Connectivity as EditableConnectivity } from './sections/connectivity'
import InfoDialog from 'src/components/legacy/components/error-dialog/error-dialog'
import {
  GET_LOCATION_TIMEZONE,
  GET_DEVICE_ASSOCIATION,
  CHECK_FOR_DUPLICATE_BUILDING_NAME,
  SEARCH_BUILDINGS_SALES_OFFICE_DETAILS
} from '../graphql/queries'
import { getField, strToBool } from 'src/components/legacy/common/helpers'
import { useNavigate } from 'react-router-dom'
import { isValidValue, SerialNumberConversion } from './helper'
import TermsConditionsBuildingSetup from './TermsAndConditionModal'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import { getConnectionTypeObj } from 'src/components/legacy/common/connection-types'
import _isEmpty from 'lodash/isEmpty'
import { newRefreshToken, getSession } from 'src/redux/slicers/appData'
import { useDispatch, useSelector } from 'react-redux'
// import { selectUserPermissions } from 'src/redux/slicers/appData'
import { AccessControl } from 'src/components/accessControl'
import { selectUserRoles } from 'src/redux/slicers/appData'
import { openVPNURL } from 'src/aws-exports'
import { v4 as uuid4 } from 'uuid'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate, { TranslateComponent } from 'src/common/translations'
import { SEARCH_CONTACTS,SAVE_SUBSCRIBER } from 'src/pages/alarmNotifications/graphql'
import config from 'src/aws-exports.js'
import { formatConnectivityTypeOptions } from './helper'

const defaultErrorDetails = { error: false, header: null, message: null }
let defaultApiError = false
let actionType = ''
const pageName = () => {
  switch (actionType) {
    case 'create':
      return 'createbuilding'
    case 'edit':
      return 'editbuilding'
    case 'default':
      return 'buildingdetails'
    default:
      return 'buildingdetails'
  }
}

const AccountInfoContent = ({ data }) => {
  return (
    <>
      <Block padding={'0 0 1rem 0'}>
        <StyledLabel><TranslateComponent>Organization/Customer Name</TranslateComponent></StyledLabel>
        <StyledInfo>
          {isValidValue(data.accountName) ? data.accountName : '--'}
        </StyledInfo>
      </Block>
      <Block padding={'0 0 1rem 0'}>
        <StyledLabel><TranslateComponent>Office Name</TranslateComponent></StyledLabel>
        <StyledInfo>{parseSalesOffice(data)}</StyledInfo>
      </Block>
      <Block padding={'0 0 1rem 0'}>
        <StyledLabel><TranslateComponent>Customer CRM Site ID</TranslateComponent></StyledLabel>
        <StyledInfo>
          {isValidValue(data.crmCustId) ? data.crmCustId : '--'}
        </StyledInfo>
      </Block>
    </>
  )
}

const ConnectivityContent = ({ data, readOnly }) => {
  return data?.devices?.items
    ?.filter((device) => device.type !== 'BCU')
    .map((item) => {
      if (_isEmpty(item)) return
      const serialTitle = getConnectionTypeObj(item?.type)?.title ?? ''
      const serialNumber = item?.serial.replace('-local', '')
      return (
        <div className="connectivity__row" key={item?.serial}>
          <Block display="flex" flexDirection="column" width="100%">
            <Block padding={'0 0.5rem 1rem 0'}>
              <StyledLabel><TranslateComponent>Connection Type</TranslateComponent></StyledLabel>
              <StyledInfo>{item?.uiDisplayName}</StyledInfo>
            </Block>
            <Block padding={'0 0.5rem 1rem 0'}>
              <StyledLabel><TranslateComponent>{serialTitle}</TranslateComponent></StyledLabel>
              <StyledInfo>{serialNumber}</StyledInfo>
            </Block>
            <Block padding={'0 0.5rem 1rem 0'}>
              <StyledLabel><TranslateComponent>Device Name</TranslateComponent></StyledLabel>
              <StyledInfo>{item?.name}</StyledInfo>
            </Block>
            <Block padding={'0 0.5rem 1rem 0'}>
              {readOnly && item?.type !== 'ES' && (
                <div className="reg-status">
                  <div className="reg-status__label"><TranslateComponent>Registration status</TranslateComponent></div>
                  <div className="reg-status__message-wrapper">
                    <span
                      className={
                        'reg-status__message reg-status__message--registered'
                      }
                    >
                      <TranslateComponent>Registered</TranslateComponent>
                    </span>

                    <ConditionalTooltip
                      type={ConditionalTooltip.TYPE.DEFAULT}
                      content={
                        <div style={{ width: '206px' }}>
                          <TranslateComponent>{
                            'This device has been successfully registered with this building.'
                          }</TranslateComponent>
                        </div>
                      }
                      align={ConditionalTooltip.ALIGN.CENTER}
                      position={ConditionalTooltip.POSITION.BOTTOM}
                      multiline={true}
                      width="auto"
                      testName={`${pageName()}_connectivity_registration`}
                    >
                      <span className="icon icon-infocircle" />
                    </ConditionalTooltip>
                  </div>
                </div>
              )}
            </Block>
          </Block>
        </div>
      )
    })
}

const OfferingsContent = () => {
  return (
    <StyledNoOfferingsDiv>
      <TranslateComponent>No offerings enabled for this building.</TranslateComponent>
    </StyledNoOfferingsDiv>
  )
}

const OfferingsHeader = () => {
  return (
    <Block display="flex" justify="space-between">
      <span><TranslateComponent>Offerings</TranslateComponent></span>
      <StyledCustomLink>
      <TranslateComponent>To view or activate, go the</TranslateComponent>{' '}
        <a
          data-testid={`${pageName()}_offeringdetails_contractandoffering_link`}
          href="/contracts-and-offerings"
        >
           <TranslateComponent>Contracts &amp; Offerings Page</TranslateComponent>
        </a>
      </StyledCustomLink>
    </Block>
  )
}

const AccountInformation = ({ data, readOnly, translatedHeader }: any) => (
  <Accordion
    data={[
      {
        defaultOpen: readOnly,
        header: translatedHeader,
        testName: `${pageName()}_accountinfoheader`,
        iconPos: 'before',
        content: <AccountInfoContent data={data} />
      }
    ]}
  />
)
const Connectivity = ({ data, readOnly, optionsTranslated }: any) => (
  <Accordion
    data={[
      {
        defaultOpen: readOnly,
        header: optionsTranslated,
        testName: `${pageName()}_connectivityinfoheader`,
        iconPos: 'before',
        content: (
          <div className="connectivity">
            <ConnectivityContent readOnly={readOnly} data={data} />{' '}
          </div>
        )
      }
    ]}
  />
)
const Offerings = ({ readOnly }: any) => (
  <Accordion
    data={[
      {
        defaultOpen: readOnly,
        header: <OfferingsHeader />,
        testName: `${pageName()}_offeringinfoheader`,
        iconPos: 'before',
        content: (
          <>
            <OfferingsContent />{' '}
          </>
        )
      }
    ]}
  />
)

export const BuildingDetails = ({ action }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectUserInfo)
  // TODO: No longer doing permissions this way
  // const permissions = useSelector(selectUserPermissions)
  const { id: buildingIdParam } = useParams()
  const [buildingId, setBuildingId] = useState(buildingIdParam)
  const { organizationId: orgId } = getSearchParams()
  const navigate = useNavigate()
  const isCreate = () => action === ACTIONS.CREATE
  const [readOnly, setReadonly] = useState(!isCreate())
  const [countries, setCountries] = useState([])
  const [selectedCountryCode, setSelectedCountryCode] = useState('')
  const [regions, setRegions] = useState({})
  const [errorDetails, setErrorDetails] = useState<any>(defaultErrorDetails)
  const [showTermsConditionsModal, setShowTermsConditionsModal] =
    useState(false)
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(false)

  const userRoles = useSelector(selectUserRoles)

  useEffect(() => {
    if (action === 'edit') setReadonly(false)
  }, [action])

  useEffect(() => {
    if (!buildingId && buildingIdParam) {
      setBuildingId(buildingIdParam)
    }
  }, [buildingIdParam])

  useEffect(() => {
    actionType = action === 'create' || action === 'edit' ? action : 'default'
    if (buildingId) {
      dispatch<any>(newRefreshToken([buildingId], null, null, ["AttachmentAdmin"]))
    }
  }, [buildingId])

  const { refetch: reCheckForDuplicateBuildingName } = useQuery({
    query: CHECK_FOR_DUPLICATE_BUILDING_NAME,
    disableInitialLoad: true,
    dataPath: 'data.checkDuplicateBuildingName'
  })

  const { refetch: searchContacts } = useQuery({
    query: SEARCH_CONTACTS,
    disableInitialLoad: true,
    dataPath: 'data.searchContacts.items'
  })

  const { onSubmit: saveSubscriber } = useMutation({
    query: SAVE_SUBSCRIBER,
    dataPath: 'data.createNotificationSetting'
  })

  const {
    refetch: refetchDeviceAssociation,
    loading: loadingDeviceAssociation
  } = useQuery({
    query: GET_DEVICE_ASSOCIATION,
    disableInitialLoad: true,
    dataPath: 'data.getDeviceAssociation'
  })

  const { refetch: searchBuildings, loading: loadingSearchBuildings } =
    useQuery({
      query: SEARCH_BUILDINGS_SALES_OFFICE_DETAILS,
      disableInitialLoad: true,
      dataPath: 'data.searchBuildings.items'
    })

  const {
    data: buildingData,
    refetch: refetchBuildingById,
    loading: loadingBuildingDetails
  } = useQuery({
    query: GET_BUILDING_BY_ID,
    variables: { id: buildingId },
    errorPolicy: 'all',
    dataPath: 'data.getBuilding',
    disableInitialLoad: action === ACTIONS.CREATE
  })

  const { refetch: addDeviceToBuilding, loading: loadingAddDeviceCheck } =
    useQuery({
      query: CREATE_DEVICE,
      dataPath: 'data.createDevice',
      disableInitialLoad: true
    })

  const { refetch: updateDeviceToBuilding, loading: loadingUpdateDeviceCheck } =
    useQuery({
      query: UPDATE_DEVICE,
      dataPath: 'data.UPDATEDevice',
      disableInitialLoad: true
    })

  const { onSubmit: onCreateBuilding, loading: loadingCreateBuilding } =
    useMutation({
      query: CREATE_BUILDING,
      dataPath: 'data.createBuilding'
    })

  const { onSubmit: onUpdateBuilding, loading: loadingUpdateBuilding } =
    useMutation({
      query: UPDATE_BUILDING,
      dataPath: 'data.updateBuilding'
    })

  const { onSubmit: updateUtilityBillData } = useMutation({
    query: UPDATE_UTILITY_BILL,
    dataPath: 'data.utilityDataUploader'
  })

  const { refetch: refetchLocationTimezone, loading: loadingLocationTimezone } =
    useQuery({
      query: GET_LOCATION_TIMEZONE,
      disableInitialLoad: true,
      errorPolicy: 'all',
      dataPath: 'data.getLocationTimezone'
    })

    const {
      data: connectionTypes,
      loading: loadingConnectionTypes
    } = useQuery({
      query: GET_CONNECTION_TYPES,
      disableInitialLoad: false,
      dataPath: 'data.configByType.items',
      variables: {
        type: 'DeviceType'
      }
    })
  
    const connectionTypesList = useMemo(
      () => formatConnectivityTypeOptions(connectionTypes),
      [connectionTypes]
    )

  const closeTermsModal = (): void => {
    setFormData(null)
    setShowTermsConditionsModal(false)
  }

  const checkDeviceSerialNumberExistOnSave = async (connectivityData) => {
    defaultApiError = false
    try {
      const devicedNeedAssociationCheck = []
      let associatedBuidingName
      connectivityData?.map((connectivity) => {
        const deviceExistingAsMultiple = connectivityData.filter(
          (connection) => {
            const connectionIdentifier = connection?.device?.deviceType === 'SCG'
            ? /-local$/.test(connection?.device?.identifier)
            ? `${SerialNumberConversion(connection?.device?.identifier, true)}-local`
            : `${SerialNumberConversion(connection?.device?.identifier, false)}-local`
            : SerialNumberConversion(connection?.device?.identifier, false)
            const connectivityDeviceIdentifier = connectivity?.device?.deviceType === 'SCG'
            ? /-local$/.test(connectivity?.device?.identifier)
            ? `${SerialNumberConversion(connectivity?.device?.identifier, true)}-local`
            : `${SerialNumberConversion(connectivity?.device?.identifier, false)}-local`
            : SerialNumberConversion(connectivity?.device?.identifier, false)
            return connectionIdentifier === connectivityDeviceIdentifier
          }
        )
        if (deviceExistingAsMultiple.length > 1) {
          defaultApiError = true
          setErrorDetails({
            error: true,
            header: ERROR_HEADERS.API_ERROR,
            message: getMessage(
              'LOCATION_SUMMARY_CREATION_ERROR',
              'deviceAssociatedWithSameBuilding',
              { serialNumber: connectivity.device.identifier },
              action
            )
          })
        } else {
          devicedNeedAssociationCheck.push({
            deviceId: connectivity.device.identifier,
            deviceType: connectivity?.device?.deviceType
          })
        }
      })
      const deviceCheckResults = await Promise.all(
        devicedNeedAssociationCheck?.map((device) => {
          const identifierNumber = device?.deviceType === 'SCG'
          ? /-local$/.test(device?.deviceId)
          ? `${SerialNumberConversion(device?.deviceId, true)}-local`
          : `${SerialNumberConversion(device?.deviceId, false)}-local`
          : SerialNumberConversion(device?.deviceId, false)
          return refetchDeviceAssociation({ deviceId: identifierNumber })
        }
        )
      )

      for (let i = 0; i < deviceCheckResults?.length; i++) {
        const checkResult = deviceCheckResults[i]
        const parseCheckResult = JSON.parse(checkResult)
        const associatedBuilding =
          parseCheckResult.device.data?.buildings?.find(
            (x) => x?.active === '1'
          )
        const associatedToABuidingId = associatedBuilding?.id

        if (associatedToABuidingId) {
          if (action === ACTIONS.CREATE) {
            defaultApiError = true
            associatedBuidingName = associatedBuilding?.name || ''
            setErrorDetails({
              error: true,
              header: ERROR_HEADERS.API_ERROR,
              message: getMessage(
                'LOCATION_SUMMARY_CREATION_ERROR',
                'deviceAssociatedWithDifferentBuilding',
                {
                  buildingName: associatedBuidingName,
                  serialNumber: parseCheckResult?.device?.data?.serial || ''
                },
                action
              )
            })
            break
          } else {
            if (associatedToABuidingId !== buildingId) {
              defaultApiError = true
              associatedBuidingName = associatedBuilding?.name || ''
              setErrorDetails({
                error: true,
                header: ERROR_HEADERS.API_ERROR,
                message: getMessage(
                  'LOCATION_SUMMARY_CREATION_ERROR',
                  'deviceAssociatedWithDifferentBuilding',
                  {
                    buildingName: associatedBuidingName,
                    serialNumber: parseCheckResult?.device?.data?.serial || ''
                  },
                  action
                )
              })
              break
            }
          }
        }
      }
      return defaultApiError
    } catch (error) {
      // on error, we don't let the device to add as of now
      return true
    }
  }

  // This method will register and unregister a device for Open VPN remote connection
  const setupDeviceForOpenVPN = async (devices: any) => {
    try {
      const token: any = await getSession(true)
      const activeDevices =
        devices?.filter((x) => x?.isActive)?.map((x) => x?.serial?.toUpperCase()) || []
      const inactiveDevices =
        devices?.filter((x) => !x?.isActive)?.map((x) => x?.serial?.toUpperCase()) || []
      const apiData = {
        activeDevices,
        inactiveDevices
      }
      const url = `${openVPNURL}/setup`
      const result = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        },
        body: JSON.stringify(apiData)
      })
    } catch (e) {}
  }

  const onCreateBuildingFormSubmit = async (data) => {
    try {
      const deviceDuplicateError = await checkDeviceSerialNumberExistOnSave(
        data.locationConnectivity.devices
      )
      if (deviceDuplicateError) {
        return
      }
      let checkIfBuildingNameExists = false
      if (!(buildingData?.name?.trim() === data.locationName.trim())) {
        const duplicateNameResponse = await reCheckForDuplicateBuildingName({
          accountId: data.organizationId,
          buildingName: data.locationName.trim()
        })
        checkIfBuildingNameExists = strToBool(
          JSON.parse(duplicateNameResponse)?.status
        )
        if (checkIfBuildingNameExists) {
          let deviceTypeToBeAssociated = 'SC'
          const devices = data.locationConnectivity.devices
          if (devices?.length > 0) {
            deviceTypeToBeAssociated =
              devices.length > 1 ? 'SC' : devices[0].device.deviceType
          }
          setErrorDetails({
            error: true,
            header: ERROR_HEADERS.API_ERROR,
            message: getMessage(
              'LOCATION_SUMMARY_CREATION_ERROR',
              'buildingNameExists',
              {
                connectivity: deviceTypeToBeAssociated,
                account: data.accountName
              },
              action
            )
          })
          return
        }
      }
      setLoading(true)
      if (action === ACTIONS.CREATE) {
        const input = await parseRequest(
          data,
          regions,
          async (input) => await refetchLocationTimezone(input)
        )
        const uuid = uuid4();
        const createBuildingresponse = await onCreateBuilding({ input: {...input,id : uuid,buildingId : uuid} })
        if (createBuildingresponse?.id) {
          await dispatch<any>(
            newRefreshToken([createBuildingresponse?.id], null, null, ["AttachmentAdmin"])
          ).then(async (res) => {
            setFormData(null)
            const deviceRequest = parseDeviceRequest({
              ...data,
              id: createBuildingresponse.id
            })
            const subscriber = config?.appEnvironment?.DEFAULT_SUBCRIBER_FOR_BUILDING
            if(subscriber){
            searchContacts({filter: { emailId: { eq: subscriber } }}).then(data => {
              const contactId = data?.[0]?.id;
              if(contactId){
                const input = {
                  contactId,
                  buildingId : createBuildingresponse.id,
                  type: "alarm",
                  userId:user.id
                }
                saveSubscriber({ input })
              }
            } )
            }
            if (deviceRequest?.length > 0) {
              setupDeviceForOpenVPN(deviceRequest)

              const createDeviceresponse = await Promise.all(
                deviceRequest?.map((req) => {
                  const uniqueId = uuid4();
                  const serialNumber = req?.type === 'SCG'
                  ? /-local$/.test(req?.serial)
                    ? `${SerialNumberConversion(req?.serial, true)}-local`
                    : `${SerialNumberConversion(req?.serial, false)}-local`
                  : SerialNumberConversion(req?.serial, false)
                  return addDeviceToBuilding({
                    input: {
                      ...req,
                      serial: serialNumber,
                      deviceId: uniqueId, id: uniqueId }
                  })
                })
              )
              if (!createDeviceresponse.error) {
                setReadonly(true)
                setBuildingId(createBuildingresponse.id)
                navigate(
                  `/building-setup/${createBuildingresponse.id}?organization=${createBuildingresponse.accountId}`
                )
                refetchBuildingById({ id: createBuildingresponse.id })
              }
            } else {
              setReadonly(true)
              setBuildingId(createBuildingresponse.id)
              navigate(
                `/building-setup/${createBuildingresponse.id}?organization=${createBuildingresponse.accountId}`
              )
              refetchBuildingById({ id: createBuildingresponse.id })
            }
          })
        }
      } else {
        const input = await parseRequest(data, regions, (input) =>
          refetchLocationTimezone(input)
        )

        const salesOfficeDetails = await searchBuildings({
          filter: { salesOfficeId: { eq: input?.salesOfficeId } },
          limit: 1000
        })
        input.salesOfficeName = salesOfficeDetails?.[0]?.salesOfficeName
        input.salesOfficeCode = salesOfficeDetails?.[0]?.salesOfficeCode
        input.salesOfficeDistrict = salesOfficeDetails?.[0]?.salesOfficeDistrict

        const updateBuildingresponse = await onUpdateBuilding({
          input: { id: buildingId, ...input }
        })
        if (updateBuildingresponse?.id) {
          trackEvent(USER_EVENTS.BUILDING_SETUP.events.SAVE_BUILDING, {"location name":input.name, "account name": input.accountName, "sales office": input.salesOfficeName, mode:"Edit"})
          let deviceRequest = parseDeviceRequest({
            ...data,
            id: updateBuildingresponse.id
          })
          if (!(buildingData?.floorArea == data.floorAreaSquareFeet)) {
            const inputJSON = {
              deleteKeys: [],
              buildingId: buildingId,
              action: 'updatefloorarea',
              fileName: '',
              floorArea: input?.floorArea || 0
            }
            // Needs to update ECI/EUI on floor area update
            await updateUtilityBillData({
              requestBody: JSON.stringify(inputJSON)
            })
          }
          const devicesToEdit =
            (deviceRequest && deviceRequest?.map((d) => d.id)) || []
          const devicesToRemove =
            buildingData?.devices?.items
              ?.filter((d) => !devicesToEdit.includes(d.id))
              // Can't update connection status, so remove from object
              ?.map(({ connectionStatus, ...d }) => ({ ...d, isActive: 0 })) ||
            []
          deviceRequest =
            devicesToRemove && devicesToRemove.length
              ? deviceRequest.concat(devicesToRemove)
              : deviceRequest
          if (deviceRequest?.length > 0) {
            setupDeviceForOpenVPN(deviceRequest)
            const updateDeviceresponse = await Promise.all(
              deviceRequest?.map((req) => {
                const serialNumber = req.type === 'SCG'
                ? /-local$/.test(req?.serial)
                ? `${SerialNumberConversion(req?.serial, true)}-local`
                : `${SerialNumberConversion(req?.serial, false)}-local`
                : SerialNumberConversion(req?.serial, false)
                if (req.id) {
                  return updateDeviceToBuilding({ input: req, serial: serialNumber })
                } else {
                  const uniqueId = uuid4();
                  return addDeviceToBuilding({
                    input: { ...req, serial: serialNumber, deviceId: uniqueId, id: uniqueId }
                  })
                }
              })
            )
            if (!updateDeviceresponse.error) {
              setReadonly(true)
              navigate(
                `/building-setup/${updateBuildingresponse.id}?organization=${updateBuildingresponse.accountId}`
              )
              refetchBuildingById({ id: updateBuildingresponse.id })
            }
          } else {
            setReadonly(true)
            navigate(
              `/building-setup/${updateBuildingresponse.id}?organization=${updateBuildingresponse.accountId}`
            )
            refetchBuildingById({ id: updateBuildingresponse.id })
          }
        }
      }
      setLoading(false)
    } catch (error) {
    }
  }

  const onSubmitForm = async (data) => {
    if (action === ACTIONS.CREATE) {
      setShowTermsConditionsModal(true)
      setFormData(data)
    } else {
      onCreateBuildingFormSubmit(data)
    }
  }

  const toggleErrorsDialog = () => setErrorDetails(defaultErrorDetails)
  const onAction = (action) => {
    let url = '/building-setup/'
    // const orgId = searchParams.get('organization')
    if (action === 'cancel') {
      url = isCreate()
        ? `${url}${orgId ? `?organization=${orgId}` : ''}`
        : `${url}${buildingId}${orgId ? `?organization=${orgId}` : ''}`
    } else if (action === 'edit') {
      actionType = 'edit'
      url = `${url}${buildingId}/edit${orgId ? `?organization=${orgId}` : ''}`
    }
    navigate(url)
  }

  const toolbarButtons = ({
    setReadonly,
    readOnly,
    props,
    locationManagementAction = true
  }) => (
    <div className="toolbar-buttons">
      {!readOnly && (
        <Button
          className={classNames('secondary')}
          type="reset"
          onClick={() => {
            props.handleReset()
            setReadonly(true)
            onAction('cancel')
            actionType = ''
          }}
          testName={`${pageName()}_cancela${pageName()}_cancel`}
        >
          <TranslateComponent>CANCEL</TranslateComponent> 
        </Button>
      )}
      {readOnly ? (
        <>
          {locationManagementAction && (
            <Button
              className="action small"
              type="button"
              onClick={() => {
                onAction('edit')
                actionType = 'edit'
              }}
              testName={`${pageName()}_buildingdetails_edit`}
            >
              <span className="icon icon-edit" />
              <TranslateComponent>EDIT</TranslateComponent>
            </Button>
          )}
        </>
      ) : (
        <Button
          className="primary"
          type="submit"
          disabled={
            !props.isValid ||
            !props.dirty ||
            loadingUpdateBuilding ||
            loadingCreateBuilding
          }
          testName={`${pageName()}_savea${pageName()}_save`}
        >
          <TranslateComponent>SAVE</TranslateComponent>
        </Button>
      )}
    </div>
  )

  const termsandconditionsText = {
    Title:"Create Building Terms and Conditions",
    Subtitle:translate("One of the following conditions must be true to continue:"),
    Term1:translate("This Building Automation System being used on this site is Trane Tracer SC v4.2 or newer. The customer of this facility has agreed to the end user license agreement (EULA) and authorizes the use of Remote Access and Trane to collect/store data from this site. "),			
    ConfirmText:translate("I confirm one of the above conditions is true"),
    Cancel : "Cancel",
    Save : "Save",
    Confirm : translate("Confirm")
  }

  const optionsTranslated = {
    accountHeader : translate("Account Information"),
    connectionHeader : translate("Connectivity"),
    removeHeader: translate("Remove Device")
  }

  // return loading ? <Spinner page /> : <></> Below return code should be here once loader will be enabled ( have to check & remove 'buildingData &&' this condition as well )
  return loading ? (
    <Spinner />
  ) : readOnly ? (
    buildingData && (
      <StyledContainer>
        <Block
          display="flex"
          justify="space-between"
          padding="0 0 8px 0"
          align="center"
        >
          <StyledTitle>{buildingData?.name}</StyledTitle>
          <div>
            <AccessControl
              id={[
                `tc.pages.building-setup.edit`,
                `tc.pages.building-setup.Application.edit`,
                `tc.pages.building-setup.${buildingData.id}.edit`,
                `tc.pages.building-setup.${buildingData.salesOfficeId}.edit`,
                `tc.pages.building-setup.${buildingData.accountId}.edit`
              ].concat(
                buildingData?.groupAdmin?.map(
                  (e) => `tc.pages.building-setup.${e}.edit`
                )
              )}
            >
              <Button
                onClick={() => {
                  setReadonly(false)
                  onAction('edit')
                  actionType = 'edit'
                }}
                testName={`${pageName()}_editbuildingdetails_edit`}
              >
                <Icon
                  name="edit"
                  width="10px"
                  height="10px"
                  color="white"
                  style={{ padding: '0 2px 0 0' }}
                />{' '}
                <TranslateComponent>EDIT</TranslateComponent>
              </Button>
            </AccessControl>
          </div>
        </Block>
        <div
          className={classNames('location-details', {
            'edit-mode': readOnly
          })}
        >
          <div className="details-main">
            <div className="section">
              <GeoLocationDetails
                building={buildingData}
                readOnly={readOnly}
                action={action}
                pageName={pageName()}
              />
              <StyledDetails>
                <Block margin="20px" background="#fff" radius="3px">
                  <AccountInformation
                    action={action}
                    data={buildingData}
                    readOnly={readOnly}
                    translatedHeader= {optionsTranslated.accountHeader}
                  />
                </Block>
                <Block margin="20px" background="#fff" radius="3px">
                  <Connectivity data={buildingData} readOnly={readOnly} optionsTranslated={optionsTranslated.connectionHeader} />
                </Block>
                <Block margin="20px" background="#fff" radius="3px">
                  <Offerings
                    action={action}
                    data={buildingData}
                    readOnly={readOnly}
                  />
                </Block>
              </StyledDetails>
            </div>
          </div>
        </div>
      </StyledContainer>
    )
  ) : (
    ((buildingId && buildingData) || !buildingId) && (
      <BuildingForm
        initialValues={prepareInitialValues(
          allTisOfferings,
          action,
          buildingData,
          countries,
          regions
        )}
        onSubmit={onSubmitForm}
        t={t}
      >
        {(props) => {
          const isAnyCMDevice =
            props.values.locationConnectivity.length > 0
              ? props.values.locationConnectivity.some(
                  (connectivity) => connectivity?.device?.deviceType === 'CM'
                )
              : false
          const locationConnectivity =
            getField(props.values, 'locationConnectivity.devices') || []
            action === ACTIONS.EDIT && !props.touched.customerCRMSiteId && props.setTouched(
              {
                ...props.touched,
                customerCRMSiteId: true
              })
          const markAddressFieldsAsTouched = () => {
            props.setTouched(
              {
                ...props.touched,
                address: {
                  ...props.touched.address,
                  country: true,
                  regionCode: true,
                  postalCode: true,
                  city: true
                }
              },
              true
            )
          }
          return (
            <StyledContainer>
              <div
                className={classNames('location-details', {
                  'edit-mode': readOnly
                })}
              >
                <div className="details-header">
                  <EditableView
                    type={EditableView.COMPONENT_TYPE.INPUT}
                    className="location required"
                    labelName={
                      readOnly ? (
                        <span className="icon-location" />
                      ) : (
                        'Building Name'
                      )
                    }
                    readOnly={readOnly}
                    value={props.values.locationName}
                    name="locationName"
                    onBlur={() => {
                      props.setFieldValue(
                        'locationName',
                        props.values.locationName.trim()
                      )
                    }}
                    maxLength={FIELD_RESTRICTIONS.locationName.maxLength + 1}
                    testName={`${pageName()}_building name_add building name`}
                  />
                  {toolbarButtons({ readOnly, props, setReadonly })}
                </div>
                <div className="details-main">
                  <div className="section">
                    <GeoLocationDetails
                      markAddressFieldsAsTouched={markAddressFieldsAsTouched}
                      selectedCountryCode={selectedCountryCode}
                      setSelectedCountryCode={setSelectedCountryCode}
                      countries={countries}
                      regions={regions}
                      setCountries={setCountries}
                      setRegions={setRegions}
                      formProps={props}
                      building={buildingData}
                      readOnly={readOnly}
                      pageName={pageName()}
                      action={action}
                    />
                    <StyledDetails>
                      <Block
                        position="static"
                        margin="20px"
                        background="#fff"
                        radius="3px"
                      >
                        <EditableAccountInformation
                          formProps={props}
                          pageName={pageName()}
                        />
                      </Block>
                      <Block
                        position="static"
                        margin="20px"
                        background="#fff"
                        radius="3px"
                      >
                        <EditableConnectivity
                          readOnly={readOnly || isAnyCMDevice}
                          locationConnectivity={locationConnectivity}
                          buildingData={buildingData}
                          updateDeviceToBuilding={updateDeviceToBuilding}
                          addDeviceToBuilding={addDeviceToBuilding}
                          refetchBuildingById={refetchBuildingById}
                          setupDeviceForOpenVPN={setupDeviceForOpenVPN}
                          userRoles={userRoles}
                          setReadonly={setReadonly}
                          connectionTypesList={connectionTypesList}
                          //TODO modified below because we no longer do permissions this way.
                          permissions={
                            () => true /*permissions?.isPermittedAction*/
                          }
                          pageName={pageName()}
                          removeModalHeader={optionsTranslated.removeHeader}
                        />
                      </Block>
                      {/* <Block margin="20px" background="#fff" radius="3px">
                                            <Offerings data={buildingData} readOnly={readOnly} />
                                        </Block> */}
                    </StyledDetails>
                  </div>
                </div>
                <div className="details-footer">
                  {toolbarButtons({ readOnly, props, setReadonly })}
                </div>
                {errorDetails.error && (
                  <InfoDialog
                    title={errorDetails.header}
                    handleClose={toggleErrorsDialog}
                    text={errorDetails.message}
                  />
                )}
                {showTermsConditionsModal && (
                  <TermsConditionsBuildingSetup
                    formData={formData}
                    closeModal={closeTermsModal}
                    handleSubmit={onCreateBuildingFormSubmit}
                    pageName={pageName()}
                    termsandconditionsText={termsandconditionsText}
                  />
                )}
              </div>
            </StyledContainer>
          )
        }}
      </BuildingForm>
    )
  )
}
