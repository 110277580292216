import { isFunction } from 'lodash'
import {
  LEFT_LEFT,
  RIGHT_LEFT,
  horizontalSides,
  BOTTOM_BOTTOM,
  BOTTOM_TOP,
  LEFT_RIGHT,
  RIGHT_RIGHT,
  TOP_BOTTOM,
  TOP_TOP,
  SPACE_BEFORE_AXIS_NAME,
  SPACE_BEFORE_TICK_TEXT,
  SPACE_BEFORE_SYMBOL
} from './constants'
import { defaultdateTimeFormatter } from 'src/chart-library/Charts/XYChart/helper'
import { isNumberAndIsNotaNaN } from 'src/chart-library/Utils/common'
import {
  SCALE_LINEAR,
  SCALE_TIME
} from 'src/chart-library/Utils/Scales/constant'
import { getNumberIfUndefined } from 'src/chart-library/BaseSetup/helper'
import { filterArray } from 'src/chart-library/BaseSetup/PaddingSetup/helper'
import { isNumeric } from '../Bar/helper'

export const ticksPositioning = (
  side = '',
  scale: any,
  val: any,
  hideTicks: any,
  tickSize: number,
  tickPadding: number,
  scaleType: any,
  adjustmentScale: any,
  tilted: any
) => {
  const tranform = isFunction(scale)
    ? horizontalSides?.includes(side)
      ? `translate(${
          scale(val) + (scaleType === 'scaleBand' ? scale?.bandwidth() / 2 : 0)
        },0)`
      : `translate(0,${
          scale(val) + (scaleType === 'scaleBand' ? scale?.bandwidth() / 2 : 0)
        })`
    : ''
  let x2
  let y2
  const xy = `${(hideTicks ? 0 : tickSize) + tickPadding}`
  let x
  let y
  let textAnchor = ''
  const adjustmentRequired = scaleType === SCALE_TIME && adjustmentScale
  let adjustmentValue
  if (adjustmentRequired) {
    const adjustmentScaleBand = adjustmentScale?.gScale
    if (adjustmentScaleBand) {
      const stepValue = adjustmentScaleBand?.step()
      adjustmentValue = isNumeric(stepValue / 2) ? stepValue / 2 : 0
    }
  }
  if (side === LEFT_LEFT || side === RIGHT_LEFT) {
    x2 = `-${tickSize}`
    x = '-' + xy
    textAnchor = 'end'
    y = adjustmentValue
  }
  if (side === LEFT_RIGHT || side === RIGHT_RIGHT) {
    x2 = `${tickSize}`
    x = xy
    textAnchor = 'start'
    y = adjustmentValue
  }
  if (side === BOTTOM_BOTTOM || side === TOP_BOTTOM) {
    y2 = `${tickSize}`
    y = xy
    textAnchor = tilted ? 'end' : 'middle'
    x = adjustmentValue
  }
  if (side === BOTTOM_TOP || side === TOP_TOP) {
    y2 = `-${tickSize}`
    y = '-' + xy
    x = adjustmentValue
    textAnchor = 'middle'
  }

  return { tranform, x, y, textAnchor, x2, y2 }
}

export const ticksGenerator = (
  scale,
  tickCount,
  scaleType,
  notD3,
  tickValues,
  dimensionBasedTickCount,
  d3Ticks = null,
  adjustmentScale: any = null
) => {
  if (tickValues?.length > 0) {
    return tickValues
  } else {
    if (scaleType === SCALE_LINEAR) {
      if (notD3) {
        const domain = scale?.domain()
        const finalTickCount = tickCount ? tickCount : dimensionBasedTickCount
        return customTickValuesNumber(finalTickCount, domain)
      } else {
        return generated3Ticks(tickValues, scale, tickCount, dimensionBasedTickCount)
      }
    } else if (scaleType === SCALE_TIME) {
      let ticks = []
      if (scale?.ticks) {
        if (d3Ticks) {
          ticks = scale?.ticks(d3Ticks)
        } else {
          ticks = scale?.ticks()
        }
      }
      return ticks
    } else {
      return generated3Ticks(tickValues, scale, tickCount, dimensionBasedTickCount)
    }
  }
}

export const generated3Ticks = (tickValues, scale, tickCount, dimensionBasedTickCount) => {
  return tickValues
      ? tickValues
      : isFunction(scale?.ticks)
      ? scale?.ticks(tickCount ? tickCount : dimensionBasedTickCount)
      : []
}

export const customTickValuesNumber = (tickCount, domain) => {
  const tickValues = []
  if (isNumberAndIsNotaNaN(domain?.[1]) && isNumberAndIsNotaNaN(domain?.[0])) {
    const step = (domain?.[1] - domain?.[0]) / (tickCount - 1)
    for (let i = 0; i < tickCount; i++) {
      tickValues.push(domain?.[0] + i * step)
    }
  }
  return tickValues
}

export const tickFormatter = (
  format,
  val,
  scaleType,
  formattingDetails: any = {},
  tickInfo
) => {
  if (scaleType === SCALE_TIME) {
    const dateDiff = formattingDetails?.dateDiff
    const index = formattingDetails?.index
    const isGTYear = formattingDetails?.isGTYear
    if (isFunction(format)) {
      return format(val, tickInfo)
    } else {
      return defaultdateTimeFormatter(val, dateDiff, index, isGTYear)
    }
  } else if (scaleType === SCALE_LINEAR) {
    const decimalCount = formattingDetails?.decimalCount
    const decCorrectedValue = parseFloat(
      parseFloat(val)?.toFixed(
        decimalCount === 0 ? decimalCount : decimalCount || 1
      )
    )
    return isFunction(format)
      ? format(val, tickInfo)
      : decCorrectedValue >= 10000 || decCorrectedValue <= -10000
      ? nFormatter(decCorrectedValue)?.toString()
      : decCorrectedValue?.toString()
  } else {
    return isFunction(format) ? format(val, tickInfo) : val?.toString()
  }
}

export const symbolPositioning = (obj: any = {}) => {
  const { isX, side, spaceDetails = {} } = obj
  if (!isX) {
    const symbolDetails = spaceDetails?.symbol || {}
    const styles: any = {
      y: -(SPACE_BEFORE_SYMBOL + getNumberIfUndefined(symbolDetails?.height)),
      dominantBaseline: 'hanging'
    }
    if (side === LEFT_LEFT || side === RIGHT_LEFT) {
      styles.x = -(
        SPACE_BEFORE_SYMBOL + getNumberIfUndefined(symbolDetails?.width)
      )
    }
    if (side === LEFT_RIGHT || side === RIGHT_RIGHT) {
      styles.x = SPACE_BEFORE_SYMBOL
    }
    return styles
  } else {
    return {}
  }
}

export const axisNamePositioning = (obj: any = {}) => {
  const { side, paddingWidth, paddingHeight, spaceDetails = {} } = obj
  const spaceInBet =
    (spaceDetails?.textSize > 0 ? SPACE_BEFORE_TICK_TEXT : 0) +
    (spaceDetails?.axisName > 0 ? SPACE_BEFORE_AXIS_NAME : 0)
  const distanceFromAxis =
    getNumberIfUndefined(spaceDetails?.tickSize) +
    getNumberIfUndefined(spaceDetails?.textSize)

  const axisNameStyles: any = {
    x: undefined,
    y: 0,
    textAnchor: 'middle'
  }

  if (side === 'bottomBottom' || side === 'topBottom') {
    if (paddingWidth) {
      axisNameStyles.x = paddingWidth / 2
      axisNameStyles.dominantBaseline = 'hanging'
      axisNameStyles.y = spaceInBet + distanceFromAxis
    }
  }
  if (side === 'bottomTop' || side === 'topTop') {
    if (paddingWidth) {
      axisNameStyles.x = paddingWidth / 2
      axisNameStyles.y = -(spaceInBet + distanceFromAxis)
    }
  }
  if (side === LEFT_LEFT || side === RIGHT_LEFT) {
    const y = paddingHeight / 2
    const x = -(spaceInBet + distanceFromAxis)
    axisNameStyles.transform = `translate(${x}, ${y}) rotate(-90)`
  }
  if (side === LEFT_RIGHT || side === RIGHT_RIGHT) {
    const y = paddingHeight / 2
    const x = spaceInBet + distanceFromAxis
    axisNameStyles.transform = `translate(${x}, ${y}) rotate(90)`
  }

  return axisNameStyles
}

export const axisNameTextFormatter = (obj: any = {}) => {
  const { side, name = {} } = obj

  let axisNameText = ''
  if (horizontalSides?.includes(side)) {
    axisNameText = `${name?.text || ''} ${
      name?.symbol ? `(${name?.symbol || ''})` : ''
    }`
  } else {
    axisNameText = `${name?.symbol ? `(${name?.symbol || ''})` : ''}`
  }

  return axisNameText
}

const nFormatter = (num, digits = 1) => {
  const isNegative = num < 0
  num = Math.abs(num) // Convert negative number to positive for formatting
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/
  const lookupsFiltered = lookup?.filter((item) => num >= item.value)
  const item = lookupsFiltered?.[lookupsFiltered?.length - 1]

  if (item) {
    const formattedNum = (num / item?.value)
      ?.toFixed(digits)
      ?.replace(regexp, '')
    return isNegative
      ? '-' + formattedNum.concat(item?.symbol)
      : formattedNum.concat(item?.symbol)
  } else {
    return '0'
  }
}
