import PropTypes from "prop-types"
import { PAGE_SIZE } from "../../legacy/common/pdf"
import { Document, Page } from "@react-pdf/renderer"

import { documentStyles } from "./pdf-document.styles"

const PDFDocument = ({ children }) => (
	<Document>
		<Page size={PAGE_SIZE} wrap={true} style={documentStyles.page}>
			{children}
		</Page>
	</Document>
)

PDFDocument.propTypes = {
	children: PropTypes.node.isRequired,
}

export default PDFDocument
