export const CREATE_USER = `
    mutation CREATE_USER(
        $input: CreateUserInput!
    ) {
        createUser(input: $input) {
            id
        }
    }
`

export const CREATE_BULK_CUSTOM_USER_ACCESS = `
    mutation CREATE_BULK_CUSTOM_USER_ACCESS(
        $deleteItems: [String]
        $addItems: [AWSJSON]
        $userId: String!
    ) {
        mutateBulkCustomUserAccess(deleteItems: $deleteItems, addItems: $addItems, userId: $userId)
    }
`

export const CREATE_CUSTOM_USER_ACCESS = `
    mutation CREATE_CUSTOM_USER_ACCESS(
        $appId: String!
        $resource: String!
        $resType: String!
        $roleId: String!
        $userId: String!
    ) {
        createCustomUserAccess(appId: $appId, resource: $resource, resType: $resType, roleId: $roleId, userId: $userId)
    }
`

export const CREATE_CUSTOM_USER_ACCESS_func = inputArray => `
    mutation CREATE_CUSTOM_USER_ACCESS {
    ${inputArray.map((e, i) => `mutation${i}: createCustomUserAccess(appId: "${e.appId}", resource: "${e.resource}", resType: "${e.resType}", roleId: "${e.roleId}", userId: "${e.userId}", roleType: "${e.type}")`)} 
    }
`

export const DELETE_CUSTOM_USER_ACCESS_func = inputArray => `
    mutation CREATE_CUSTOM_USER_ACCESS {
    ${inputArray.map((e, i) => `mutation${i}: deleteCustomUserAccess(appId: "${e.appId}", resource: "${e.resource}", resType: "${e.resType}", roleId: "${e.roleId}", userId: "${e.userId}", userAccessId: "${e.userAccessId}")`)} 
    }
`

export const DELETE_CUSTOM_USER_ACCESS = `
    mutation DELETE_CUSTOM_USER_ACCESS(
        $appId: String!
        $resource: String!
        $resType: String!
        $roleId: String!
        $userId: String!
        $userAccessId: String!
    ) {
        deleteCustomUserAccess(appId: $appId, resource: $resource, resType: $resType, roleId: $roleId, userId: $userId, userAccessId: $userAccessId)
    }
`

export const CREATE_OR_EDIT_B2C_COGNITO_USER = /* GraphQL - Creates new user */ `
    mutation CREATE_OR_EDIT_B2C_COGNITO_USER(
        $firstName: String!
        $lastName: String!
        $email: String!
        $userId: String
    ) {
        createNewUser(firstName: $firstName, lastName:$lastName, email: $email, userId: $userId) 
    }
`

export const updateUser = /* GraphQL - Updates the user details */ `
    mutation  UpdateUser(
        $input: UpdateUserInput!
    ) {
        updateUser(input: $input) {
            id
        }
    }
`

export const deleteUser = /* GraphQL - Delete the user */ `
    mutation  DeleteUser(
        $id: ID!
    ) {
        deleteUser(input: {id: $id}) {
            id
        }
    }
`

export const updateUserAttributes = /* GraphQL - Delete the user */ `
mutation UPDATE_USER_ATTRIBUTES($requestedAccess: String!) {
    updateUserAccessControl(requestedAccess: $requestedAccess)
  }
`

