export default {
	PROPERTIES: {
		WEATHER: { relationship: "weatherSourceV2", propertyName: "temperature" },
		CONSUMPTION: { propertyName: "BaselineActualFiltered" },
		BASELINE_PREDICTION: { propertyName: "BaselinePrediction" },
		BASELINE_DELTA: { propertyName: "BaselineDelta" },
		BASELINE_SAVINGS: { propertyName: "BaselineSavings" },
		CONSUMPTION_COST: { propertyName: "ConsumptionCost" },
		BASELINE_COST_PREDICTION: { propertyName: "BaselineCostPrediction" },
		BASELINE_COST_DELTA: { propertyName: "BaselineCostDelta" },
		BASELINE_COST_SAVINGS: { propertyName: "BaselineCostSavings" },
		REAL_POWER: { propertyName: "RealPower" },
		SUMMARY: { propertyName: "TimesliceConsumptionFiltered" },
		ELECTRIC_INTENSITY: { propertyName: "EnergyUsageIndex" },
		COST_INDEX: { propertyName: "EnergyCostIndex" },
		TOTAL_ELECTRIC_CONSUMPTION: { propertyName: "TotalElectricConsumption" },
		TOTAL_GAS_CONSUMPTION: { propertyName: "TotalGasConsumption" },
		TOTAL_CONSUMPTION: { propertyName: "TotalConsumption" },
		INSTANTANEOUS_POWER: { propertyName: "InstantaneousPower" }
	},
	AGGREGATIONS: {
		MAX: "MAX",
		MIN: "MIN",
		RANGE: "RANGE",
		SUM: "SUM",
		LATEST: "LATEST"
	},
	INTERVALS: {
		"15MIN": "15MIN",
		MONTH: "MONTH",
		DAY: "DAY",
		HOUR: "HOUR"
	},
	UOM: {
		BTU: { name: "btu" },
		KBTU: { name: "kiloBtu" },
		THERM: { name: "therm" },
		MBTU: { name: "megaBtu" },
		KWH: { name: "kiloWattHour" },
		MWH: { name: "megaWattHour" },
		KW: { name: "kiloWatt" },
		KWHPSF: { name: "kiloWattHoursPerSquareFoot" },
		USDPSF: { name: "energyCostPerSquareFoot" }
	},
	TYPES: {
		ELECTRIC_METER: "ElectricMeter",
		GAS_METER: "GAS",
		VIRTUAL_METER: "VirtualMeter",
		ALL: "ALL"
	},
	HYDRATION: {
		COPY_FORWARD: "copyforward"
	},
	RATIOS: {
		feetCubedToBtu: 1037,
		kwhToBtu: 3412.142,
	},
	LOCATION_TYPE: "Location",
	TIS_OBJECT_GROUP_TYPE: "TisObjectType",
	TIS_OBJECT_TYPE: "TisObject",
	ELECTRIC_METER_ID: 9
}
