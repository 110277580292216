export const CREATE_CONTACT = `
mutation createContact($input: CreateContactInput!) {
  createContact(input: $input){
    id
  }
}`

export const UPDATE_CONTACT = `
mutation updateContact($input: UpdateContactInput!) {
  updateContact(input: $input){
    id
  }
}`

export const SEARCH_CONTACTS = `
  query searchContacts($filter: SearchableContactFilterInput!) {
    searchContacts(filter:$filter, limit: 1000) {
    items {
      emailId
      firstName
      lastName
      phoneNumber
      id
      notificationSettings{
        items{
          buildingId
        }
      }
    }
  }
}`

export const GET_SUBSCRIBERS = `
query listNotificationSettingByBuilding(
  $buildingId: ID!
  $limit: Int
) {
  listNotificationSettingByBuilding(
    buildingId: $buildingId,
    limit: $limit
    type: {eq: "alarm"}
    ) {
      items {
        type
        id
        buildingId
        deviceId
        contactId
        contact{
          emailId
          lastName
          phoneNumber
          firstName
        }
        settings {
          criteriaType
          nType
          nPriority
        }
      }
    }
  }  
`

export const DELETE_SUBSCRIBER = `
  mutation deleteNotificationSetting($input: DeleteNotificationSettingInput!) {
    deleteNotificationSetting(input: $input) {
      id
    }
  }
`

export const SAVE_SUBSCRIBER = `
  mutation createNotificationSetting($input: CreateNotificationSettingInput!) {
    createNotificationSetting(input: $input) {
      id
    }
  }
`
export const UPDATE_SUBSCRIBER = `
mutation updateNotificationSetting($input: UpdateNotificationSettingInput!) {
  updateNotificationSetting(input: $input) {
    id
  }
}`

export const GET_ALARM_SUBSCRIBER_BY_BUILDINGID = `
query getBuilding($id: ID!) {
  getBuilding(id: $id) {
    alarmVisible
    name
  }
}`

export const UPDATE_ALARM_SUBSCRIBER_BY_BUILDINGID = `
mutation updateBuilding($input: UpdateBuildingInput!) {
  updateBuilding(input: $input) {
    id
    alarmVisible
  }
}`


export const GET_BUILDING_BY_ID = `query GET_BUILDING_BY_ID($id: ID!) {
  getBuilding(id: $id) {
        devices(filter: {isActive: {eq: 1},type : {ne: "BCU"},and:{type : {ne: "ES"}}}) {
          items {
              name
              type
              uiDisplayName
              eventCategories
              serial
              id
              deviceId
          }
        }
      }
    }
`

export const GET_DEVICE_BY_ID = `query GET_DEVICE_BY_ID($id: ID!) {
  getDevice(id: $id) {
    name
    serial
    id
    type
  }
    }
`