import translate, { TranslateComponent } from 'src/common/translations'
import { CustomKPITrendBottomSection } from '../../styles'
import { useTranslation } from 'react-i18next'

export const CustomKPITrendBottom = ({ widgetDetails }) => {
  const [t] = useTranslation()
  const TIME_TRANSLATE = {
    ":stp/all-time-available": translate("All Time Available"),
    ":stp/last-12-months": translate("Last 12 Months"),
    ":stp/year-to-date": translate("Year to Date")
  }

  return (
    <CustomKPITrendBottomSection>
      {TIME_TRANSLATE[widgetDetails?.timePeriod]}
    </CustomKPITrendBottomSection>
  )
}
