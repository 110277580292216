import { interpolateString, select } from 'd3'
import { ColorRange, pointerData } from './types'

export const defaultColorRange: ColorRange = {
  limit: null,
  color: 'steelblue'
}

export function fillColorRange(
  minlimit = 0,
  colorRange: ColorRange[] = [],
  isInvalidMinMax = false
) {
  // if min and max props are invalid input, return default
  if (isInvalidMinMax) {
    return [{ ...defaultColorRange }]
  }

  // if min and max props are valid input, do the process
  const sortedRange = colorRange
    .slice()
    .sort((a: ColorRange, b: ColorRange) => (a.limit || 0) - (b.limit || 0))

  const data = sortedRange.reduce(
    (av, range) => {
      if (range.limit === null) return av

      const newRange = {
        ...defaultColorRange,
        ...range,
        limit: range.limit - av.maxlimit
      }

      if (range.limit > av.maxlimit) {
        av.maxlimit = range.limit
      }

      av.data.push(newRange)

      return av
    },
    {
      maxlimit: minlimit,
      data: []
    }
  ).data

  // if given colorRange doesnt have any valid value, return default
  if ((Array.isArray(data) && data.length === 0) || isInvalidMinMax) {
    return [{ ...defaultColorRange }]
  }

  return data
}

export function rad2Deg(rdn: number) {
  return rdn * (180 / Math.PI)
}

export function deg2rad(deg: number) {
  return (deg * Math.PI) / 180
}

export function renderNeedle(
  el: any,
  data: pointerData[] = [],
  {
    pointerColor,
    guageMinMaxScale,
    startAngle,
    uuid,
    outerRadius,
    minlimit,
    maxlimit
  }: any
) {
  const width = outerRadius * 2

  const d = {
    width: 0.045 * width,
    height: 0.4 * width + (0.05 * width) / 2,
    end: 0.0038 * width,
    fill: pointerColor
  }

  select(el)
    .selectAll('polyline')
    .data(data)
    .join('polyline')
    .attr('filter', `url(#drop-shadow-${uuid})`)
    .attr('stroke', pointerColor)
    .attr('fill', pointerColor)
    .attr('points', function () {
      return `-${
        d.width / 2
      },0 -${d.end},-${d.height} -${d.end / 2},-${d.height + d.end / 2} 0,-${d.height + d.end * 0.7} ${d.end / 2},-${d.height + d.end / 2} ${d.end},-${d.height} ${d.width / 2},0`
    })
    .transition()
    .duration(1000)
    .attrTween('transform', function (pointer) {
      const value = pointer.value || minlimit

      const pointerValue =
        value < minlimit ? minlimit : value > maxlimit ? maxlimit : value

      const deg = rad2Deg(guageMinMaxScale(pointerValue))

      const rotate = interpolateString(
        `rotate(${startAngle})`,
        `rotate(${deg})`
      )

      return rotate
    })
}

export function defaultTootipFormatter(data: ColorRange): TooltipData[] {
  return [
    {
      items: [
        {
          icon: true,
          iconOptions: {
            color: data.color,
            d3Symbol: data.d3Symbol,
            size: data.symbolSize
          },
          content: data.label || data.limit,
          contentOptions: data.labelElProps,
          sourceData: { ...data }
        }
      ]
    }
  ]
}
