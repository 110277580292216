import * as yup from 'yup'

const REQUIRED = 'required'
const schema = () =>
  yup.object().shape({
    name: yup.string().required(REQUIRED),
    appId: yup.string().required(REQUIRED),
    type: yup.string().required(REQUIRED),
  })

export default schema
