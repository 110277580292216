import { Heading } from '@aws-amplify/ui-react'
import styles from './page.module.scss'
import { TranslateComponent } from 'src/common/translations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrid2 } from '../lib/pro-solid-svg-icons'
import { Banner as DenaliBanner } from '../Banner/Banner'

export const Page = ({
  children,
  title,
  icon=<FontAwesomeIcon icon={faGrid2} />,
  iconColor=IconColor.default,
  backgroundColor=BackgroundColor.default,
  actions=null,
  showHeading=true,
  testid='',
}) => {
  return (
    <>
    <DenaliBanner content="You are using the beta version of the new design, and some features may not be available yet." />
    <main className={styles.page} data-background={backgroundColor} data-testid={testid}>
    {showHeading && 
      <header>
        <div className={styles.pageIcon} data-color={iconColor}>
          {icon}
        </div>
        <div className={styles.left}>
          <Heading level={1} className={`h4 ${styles.pageHeader}`} data-testid="pageHeader">
            <TranslateComponent>{title}</TranslateComponent>
          </Heading>
        </div>
        <div className={styles.right}>
          { actions }
        </div>
      </header>}
      { children }
    </main>
    </>
  )
}

export enum IconColor {
  default='default',
  purple='purple',
  blue='blue',
  orange='orange'
}

export enum BackgroundColor {
  default='default',
  alt='alt',
  //TODO: to remove
  purple='purple'
}