import { useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectUiMode, setUiMode } from 'src/redux/slicers/appData';
import { UiMode } from 'src/redux/types/AppTypes';
import styles from './banner.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSparkles } from 'src/denali-components/lib/pro-solid-svg-icons'
import { TranslateComponent } from 'src/common/translations'
import { identifyEvent } from 'src/amplitude.js'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

export const Banner = ({ content }) => {
  const banner = useRef(null);
  const uiMode = useSelector(selectUiMode);
  const dispatch = useDispatch();
  const isChecked = (uiMode === UiMode.denali);
  const toggleUiMode = () => {
    dispatch(setUiMode(isChecked ? UiMode.standard : UiMode.denali));
    identifyEvent({"user interface": isChecked ? 'legacy' : 'denali' })
    trackEvent(USER_EVENTS.NAVIGATION.events.CLICK_CHANGE_UI,{"changed to": isChecked ? 'legacy' : 'denali' })
  };

  return (
    <aside className={`denali-banner ${styles.banner}`} ref={banner} data-testid="banner">
      <div className={styles.content}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faSparkles} width={14} height={14} size="lg"/>
          <svg width="0" height="0">
                <linearGradient id="linear-gradient" gradientTransform="rotate(100)">
                <stop stopColor="#33F0F6" offset="-0.2327" />
                <stop stopColor="#1E00FF" offset="1.5028" />
                </linearGradient>
          </svg>
        </div>
        <p className={styles.copy}>
          <TranslateComponent>{content}</TranslateComponent>
        </p> 
        <a href="#" onClick={(e) => { e.preventDefault(); toggleUiMode(); }}>
          <TranslateComponent>Switch to the old design</TranslateComponent>
        </a>
      </div>
    </aside>
  )
}