import React, { useState, useEffect, useRef } from "react"

export default ChartComponent => props => {
	const [containerWidth, containerWidthSetter] = useState(null)
	const chartContainer = useRef(null)

	useEffect(() => {
		fitParentContainer()
		window.addEventListener("resize", () => fitParentContainer())
		return () => {
			window.removeEventListener("resize", () => fitParentContainer())
		}
	}, [])

	const fitParentContainer = () => {
		const currentContainerWidth = chartContainer && chartContainer.current
			?.getBoundingClientRect().width
		const shouldResize = containerWidth !== currentContainerWidth

		if (shouldResize) {
			containerWidthSetter(currentContainerWidth)
		}
	}

	const renderChart = () => {
		const parentWidth = containerWidth

		return (
			<ChartComponent {...props} parentWidth={parentWidth} />
		)
	}
	const shouldRenderChart = containerWidth !== null

	return (
		<div
			ref={
				chartContainer
			}
			className="Responsive-wrapper"
		>
			{shouldRenderChart && renderChart()}
		</div>
	)
}
