import { AxisTypes } from '../../Types/axis'
import { scales } from './constant'

export const createScale = (
  scaleType: AxisTypes,
  domain: any = [],
  range: any = [],
  additionalProps: any = {}
) => {
  let scale = scales?.[scaleType](domain, range)

  // Apply additional properties required for scale
  // They can be unique or common
  // properties of d3
  scale = applyAdditionalPropsToScale(scale, additionalProps)
  return scale
}

export const applyAdditionalPropsToScale = (
  scale: any,
  additionalProps: any = {}
) => {
  Object.keys(additionalProps)?.forEach((prop: any) => {
    if (scale?.[prop]) {
      scale[prop](additionalProps[prop])
    }
  })
  return scale
}
