import { createContext, useContext, useState } from 'react'
import { getSearchParams } from "src/common/helperFunctions.js"


import { IModalActionType } from 'src/constants'

const PortfolioScorecardContext = createContext<any>(null)

export const PortfolioScorecardContextProvider = ({ children }: any) => {
  const {buildingId, organizationId: accountId} = getSearchParams()
  const [mode, setMode] = useState(null)
  const [reloadEvents, setReloadEvents] = useState(false)
  const [isBaseLineAdding, setIsBaseLineAdding] = useState(false)
  const [meterTotalAllBuildings, setMeterTotalAllbuilding] = useState([])
  const [modalType, setModalType] = useState<IModalActionType>('')

  return (
    <PortfolioScorecardContext.Provider
      value={{
        mode,
        setMode,
        reloadEvents,
        setReloadEvents,
        isBaseLineAdding,
        setIsBaseLineAdding,
        setModalType,
        modalType,
        buildingId,
        accountId,
        meterTotalAllBuildings, 
        setMeterTotalAllbuilding
      }}
    >
      {children}
    </PortfolioScorecardContext.Provider>
  )
}

export const usePortfolioScorecardContext = () => useContext(PortfolioScorecardContext)
