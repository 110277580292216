import { useTranslation } from 'react-i18next'
import { useFormikContext } from '../../MultiTabTorm'
import ColorInput from 'src/components/color-input/ColorInput'
import { BenchMarkCompareStyledWrapper, TabWrapper } from '../../../styles'

const EnergyDemandColors = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, setFieldValue } = formik
  const handleSelectColorPicker = (fieldName: string, color: any) => {
    setFieldValue(fieldName, color)
  }

  return (
    <TabWrapper>
        <BenchMarkCompareStyledWrapper>
          <div className='item'>
            <ColorInput
            label={`Current Demand Line Color`}
            isRequired={true}
            backgroundColor={values?.currentDemandColor}
            update={(color)=> handleSelectColorPicker('currentDemandColor', color)}
            />
            <ColorInput
            label={`Peak Demand Line Color`}
            isRequired={true}
            backgroundColor={values?.peakDemandColor}
            update={(color)=> handleSelectColorPicker('peakDemandColor', color)}
            />
          </div>          
          <br/>
          <div className='vertical'></div>
          <div className='item'>
            <ColorInput
            label={`Rate Increase Overlay Color`}
            isRequired={true}
            backgroundColor={values?.rateIncreaseOverlayColor}
            update={(color)=> handleSelectColorPicker('rateIncreaseOverlayColor', color)}
            />
          </div>
        </BenchMarkCompareStyledWrapper>
    </TabWrapper>
  )
}

export default EnergyDemandColors
