import { Trans } from 'react-i18next'
import { Label } from './style.js'
import { Highlighter } from 'src/components/utilities/Highlighter'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  RecentSearchBuildingNavigationBodyItem,
  RecentlyViewedLabel
} from '../style.js'
import { TranslateComponent } from 'src/common/translations'

const renderItem = ({ item, style, index, searchText, onClick }: any) => {
  const {primary, secondary, id, showHoverLabel, icon, disable, selected } = item;
  const withIcon = Boolean(icon)
  const clsSelected = selected ? 'selected' : ''
  const clsActive = '';
  const clsDisabled = disable ? 'disabled' : ''
  const allItemActive = id === 'all' ? 'all' : ''
  const clsLevel = 'level-0'
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`item ${clsLevel} ${clsSelected} ${clsActive} ${allItemActive} ${clsDisabled}`}
      key={`level-item-${id}-${index}`}
      onClick={() => onClick(item)}
      style={style}
    >
      <Label className={`label ${!withIcon ? 'no-icon' : ''}`}>
        {showHoverLabel ? (
          <span className="tooltip-container">
            <span
              className={`tooltip hover-tooltip center-${
                index === 0 ? 'bottom' : 'top'
              }`}
            >
            {item?.primary}
            </span>
          </span>
        ) : (
          <></>
        )}
        {/* Clock icon currently which will change after the new design */}
        {withIcon && <i className="icon icon-clock" />}
        {primary && <Highlighter text={primary} textToHighlight={searchText} />}
      </Label>
      {secondary && <Highlighter text={secondary} textToHighlight={searchText} isTranslate={secondary?.includes("Organization") ? true: false} />}
      {/* {allItemActive && (
        <div className="subtitle">
          {' '}
          <Trans i18nKey={subtitle} />
        </div>
      )} */}
    </div>
  )
}

export const RecentSearchedItems = ({
  loading,
  onClick,
  items,
  searchText
}) => {
  if (loading) {
    return <Spinner />
  }
  return (
    <RecentSearchBuildingNavigationBodyItem className="bn-body-items ">
      <RecentlyViewedLabel><TranslateComponent> Recently Viewed</TranslateComponent></RecentlyViewedLabel>
      {items?.map((item, index) =>
        renderItem({ item, index, searchText, onClick })
      )}
    </RecentSearchBuildingNavigationBodyItem>
  )
}
