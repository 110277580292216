import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import classNames from 'classnames'

class DatePickerSelectorColumn extends PureComponent {
  static propTypes = {
    displayBase: PropTypes.instanceOf(moment),
    handleSelection: PropTypes.func.isRequired,
    today: PropTypes.instanceOf(moment),
    view: PropTypes.oneOf(['month', 'year'])
  }

  handleDateClick = () => this.props.handleSelection(this.props.value)

  render = () => {
    const { value, view, displayBase, today } = this.props

    return (
      <td
        onClick={this.handleDateClick}
        className={classNames('datepicker-month', {
          'datepicker-current-column': value.isSame(today, view),
          'datepicker-selected-column': value.isSame(displayBase, view)
        })}
      >
        {view === 'month' ? value.format('MMM') : value.format('YYYY')}
      </td>
    )
  }
}

export default DatePickerSelectorColumn
