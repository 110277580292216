import styled from 'styled-components'
import COLORS from 'src/components/legacy/common/colors.json'
// TODO move color codes to variables once this UX is good to go live.
export const StyledSearchB = styled.div`
  && {
    background: ${COLORS.WHITE};
  }
  && .search-box {
    padding: 14px 18px;
    background: none;
    .clear {
      right: 24px;
    }
    input {
      background: #f4f4f4;
    }
  }
`
