import { useTranslation } from 'react-i18next'
import { EQUIPMENT_TYPES, SITE_OBJECT, TIS_OBJECT_CREATED_WARNING_PERIOD} from '../../equipmentSetup/constants'
import _find from "lodash/find"
import { TranslateComponent } from 'src/common/translations'


const RenderSuccessModalView = ({values,multipleDataSources,copyCharastersticsList,isNaxConnectivity,equipmentsList,getEquipmentTypeName}) =>{
    const [t] = useTranslation()
    return(<div className="equipment-created-info">
    <h2><TranslateComponent>The following TIS Equipment Objects have been created</TranslateComponent></h2>
    {values.copyCharacteristics && values.copyCharacteristicsFromEquipmentName && <div className="info-text">
      <TranslateComponent>Using characteristics and data map from </TranslateComponent> <strong>{_find(copyCharastersticsList, { key: values.copyCharacteristicsFromEquipmentName })?.label}</strong>
    </div>}
    <ul className="equipment-list">
      {!multipleDataSources && ((isNaxConnectivity && values.sourceTypeId === SITE_OBJECT) || EQUIPMENT_TYPES.LOAD_VALVE !== getEquipmentTypeName(values))
        ? <li key="created-equipment-name">{values.equipmentName}</li>
        : values?.defaultDataSourceEquipmentNames?.map((equipmentName, index) => (<li key={index}>{equipmentName}</li>))
      }
    </ul>
    <div className="info-text"><TranslateComponent>{`New objects may take up to ${TIS_OBJECT_CREATED_WARNING_PERIOD} hours to appear on the Raw Data Report.`}</TranslateComponent></div>
    <div className="info-text"> <TranslateComponent>If you’d like to edit these, please go to</TranslateComponent> <a href="/equipment-setup"><TranslateComponent>Equipment Setup</TranslateComponent></a>.</div>
 
  </div>) 
  }

  export default RenderSuccessModalView