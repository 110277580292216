import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import validationSchema from './formValidator'
import { useQuery } from 'src/hooks/APIHooks'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import styled from 'styled-components'
import { pages } from 'src/pages/pages.js'
import { useMutation } from '../../../hooks/APIHooks'
import { ACTIONS } from 'src/constants'
import IconSvg from "src/components/Icon"
import Select from 'src/components/legacy/components/select/select'
import { GET_PERMISSION_BY_ID, LIST_APPLICATIONS, CREATE_PERMISSION, UPDATE_PERMISSION } from '../queries'

const ButtonWrapper = styled.span`
  padding-top: 24px;
`

export const PermissionManagementAdd = () => {
  const {id} = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [mode, setMode] = useState(location?.state?.mode ? location?.state?.mode : id ? ACTIONS.VIEW : ACTIONS.ADD)
  const { data: permissionData, refetch: refetchPermissionData } = useQuery({
    query: GET_PERMISSION_BY_ID,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getPermission'
  })
  const { onSubmit: createPermission } = useMutation({
    query: CREATE_PERMISSION
  })

  const { onSubmit: updatePermission } = useMutation({
    query: UPDATE_PERMISSION
  })


  useEffect(() => {
    const getPermissionDataFunc = async () => {
      if (!permissionData && id) {
        await refetchPermissionData({id: id})
      }
    }
    getPermissionDataFunc()
  }, [id])

  useEffect(() => {
    if (permissionData) {
      formik.setFieldValue("name", permissionData.name)
      formik.setFieldValue("appId", permissionData.appId )
      formik.setFieldValue("type", permissionData.type )
      formik.setFieldValue("description", permissionData.description )
      formik.setFieldValue("resourceTypes", JSON.stringify(permissionData?.resourceTypes || "") )
    }
  }, [permissionData])

  const {data: applicationList} = useQuery({
    query: LIST_APPLICATIONS,
    errorPolicy: 'all',
    dataPath: 'data.listApplications.items'
  })

  const permissionInput = {
    appId: null, 
    description: "", 
    name: "", 
    type: null,
    resourceTypes: "",
  }

  useEffect(() => {
    navigate(location.pathname, { replace: true })
  }, [])

  const formik = useFormik({
    initialValues: permissionInput,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: async (values: any) => {
      let resourceTypes = []
      try {
        resourceTypes = JSON.parse(values.resourceTypes);
      } catch (e) {
        // Just won't save tasks currently. Would like to change the component of tasks to be more fitting.
      }
      if (mode === ACTIONS.ADD) {
        await createPermission({
          input: {
            appId: values.appId,
            description: values.description,
            name: values.name,
            type: values.type,
            resourceTypes
          }
        }).then(res => {
          if (res?.data?.createPermission?.id) {            
            setMode(ACTIONS.VIEW)
            navigate(pages["Permission Management/:id"].href.replace(":id", res?.data?.createPermission?.id))
          }
        })
      } else {
        await updatePermission({input: {
          id: permissionData.id,
          appId: values.appId,
          description: values.description,
          name: values.name,
          type: values.type,
          resourceTypes
        }
        }).then(res => {
          if (res?.data?.updatePermission?.id) {
            setMode(ACTIONS.VIEW)
            navigate(pages["Permission Management/:id"].href.replace(":id", res?.data?.updatePermission?.id))
          }
        })
      }
    }
  })

  const { values, errors } = formik
  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageHeader
          pageTitle={[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <>
              <TextInput
                style={{width: "260px"}}
                labelText="Permission Name"
                defaultValue={values?.name}
                name="name"
                onChange={(e => formik.setFieldValue("name", e.target.value))}
                hasError={errors?.['name']}
                errorMessage={"The permission name is required."}
            />
            </> : values?.name
          }
          titleTools={[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ?
            <ButtonWrapper>
              <Button type="primary" onClick={() => {
                formik.handleSubmit()
              }
                }>
                Save
              </Button>
              <Button type="secondary" onClick={() => {
                if (mode === ACTIONS.EDIT) {
                  setMode(ACTIONS.VIEW)
                  formik.setFieldValue("name", permissionData.name)
                  formik.setFieldValue("appId", permissionData.appId )
                  formik.setFieldValue("type", permissionData.type )
                  formik.setFieldValue("description", permissionData.description )
                } else { 
                  navigate(pages["Permission Management"].href)
                }
              }}>
                Cancel
              </Button>
          </ButtonWrapper>
           : <IconSvg
              name={"edit"}
              margin="0px 0px 0px 10px"
              color="#666"
              hover="#666"
              width="15px"
              onClick={() => {
                setMode(ACTIONS.EDIT)
              }}
            />
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <div>Application: </div>
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <Select
        placeholder={"- Select Application -"}
        selectedItem={applicationList?.filter(i => i.id === values?.appId)?.length ? applicationList.find(i => i.id === values?.appId).name : values.appId }
        onChange={(value) => {
          formik.setFieldValue("selectedPermissions", [])
          formik.setFieldValue(
            'appId',
            value
          )
        }}
        options={applicationList?.map(app => ({
          key: app.id,
          value: app.name
        }) || []
        )}
      /> 
      : applicationList?.filter(i => i.id === values?.appId)?.length ? applicationList.find(i => i.id === values?.appId).name : values.appId }
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <div>Permission Type: </div>
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <Select
        placeholder={"- Select Permission Type -"}
        selectedItem={values.type}
        onChange={(value) => {
          formik.setFieldValue("selectedPermissions", [])
          formik.setFieldValue(
            'type',
            value
          )
        }}
        options={["API", "UI"].map(t => ({
          key: t,
          value: t
        })
        )}
      /> 
      : values.type }

      </Content>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <div>Resource types in array format: </div>
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <TextInput
                style={{width: "260px"}}
                defaultValue={values?.resourceTypes}
                onChange={(e => formik.setFieldValue("resourceTypes", e.target.value))}
                isRequired={false}
                hasError={false}
            />
      : values.resourceTypes }
      
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      <div>Permission description: </div>
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <TextInput
                style={{width: "260px"}}
                defaultValue={values?.description}
                onChange={(e => formik.setFieldValue("description", e.target.value))}
                isRequired={false}
                hasError={false}
            />
      : values.description }
      </Content>
    </Container>
  )
}
