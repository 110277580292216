import { useTranslation } from 'react-i18next'
import { ChangeEvent, useEffect, useState } from 'react'
import {
  PanelLabel,
  EnergyConsumptionConfigStyles,
  RadioButtonWrapper
} from '../../../styles'
import { useFormikContext } from '../../MultiTabTorm'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import {
  getDisplayItems,
  getEnergyUOMList,
  TIME_PAST_7_DAYS,
  TIME_PAST_3_DAYS,
  comparisonList,
  getTimePeriodListComparator
} from '../../../helpers'
import { convertStringToValidNumber } from 'src/components/legacy/common/helpers.js'
import { formatOverlayTimePeriod } from 'src/common/overlay-time-period'
import {
  ENERGY_TARGET_MARKERS,
  TARGET_MARKERS_CONSTANT,
  formatTargetMarkers
} from 'src/common/target-markers'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { RadioGroup } from 'src/components'
import * as Yup from 'yup'
import i18next from 'i18next'
import translate, { TranslateComponent } from 'src/common/translations'
const WIDGET_NAME_MAX_LENGTH = 80
export const EnergyConsumptionConfigurationSchema = Yup.object().shape({
  benchMarkerValue: Yup.string().test(
    'testBenchMarkerValue',
    "This value is required.",
    testBenchMarkerValue
  )
})

function testBenchMarkerValue(value) {
  const {
    parent: { benchMarker }
  } = this
  return !(benchMarker === TARGET_MARKERS_CONSTANT && value === undefined)
}

export const EnergyConsumptionConfiguration = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, errors, setFieldValue } = formik
  const [timeComparedToList, setTimeComparedToList] = useState([])

  const handleInputChange = (key, value) => {
    setFieldValue(key, value)
  }
  const getOptions = (options = []) => {
    const radioFields = [
      { label: "Yes", value: '1' },
      { label: "No", value: '0' }
    ].map((option: any, index: number) => ({
      ...option,
      htmlFor: options?.[index]
    }))
    return radioFields
  }

  const getTimeComparisonList = (value)=>{
    const comparison = comparisonList.find(el=>el.key === value)
    setTimeComparedToList(comparison.dependencies)
  }

  useEffect(() => {
    getTimeComparisonList(values?.timePeriod)
  }, [])

  return (
    <EnergyConsumptionConfigStyles>
      <div className="tab-page-content">
        <SelectFormInput
          labelName={'Unit of Measure'}
          optionsList={getEnergyUOMList(t)}
          placeholder={'— Select One —'}
          update={(value) => {
            setFieldValue('unitsOfMeasure', value)
          }}
          errorItem={errors?.unitsOfMeasure}
          selectedItem={values?.unitsOfMeasure}
        />
        <SelectFormInput
          labelName={'Time Period'}
          optionsList={getTimePeriodListComparator(comparisonList)}
          placeholder={'— Select One —'}
          update={(value) => {
            setFieldValue('timePeriod', value)
            getTimeComparisonList(value)
            setFieldValue('comparedTo', ':none')
          }}
          errorItem={errors?.timePeriod}
          selectedItem={t(`time>${values?.timePeriod}`, {
            nsSeparator: '>'
          })}
        />
        <SelectFormInput
          labelName={'Compared To'}
          optionsList={timeComparedToList}
          placeholder={'— Select One —'}
          update={(value) => {
            setFieldValue('comparedTo', value)
          }}
          errorItem={errors?.comparedTo}
          selectedItem={t(`time>${values?.comparedTo}`, {
            nsSeparator: '>'
          })}
        />

        <div className="benchmark-inputs">
          <SelectFormInput
            labelName={'BenchMark/TargetMarker'}
            optionsList={getDisplayItems(
              ENERGY_TARGET_MARKERS,
              formatTargetMarkers
            )}
            placeholder={'— Select One —'}
            update={(value) => {
              setFieldValue('benchMarkerValue', '')
              setFieldValue('benchMarkerLabel', '')
              setFieldValue('benchMarker', value)
            }}
            errorItem={errors?.benchMarker}
            selectedItem={formatOverlayTimePeriod(values?.benchMarker)}
          />
          {values?.benchMarker === TARGET_MARKERS_CONSTANT && (
            <TextInput
              defaultValue={convertStringToValidNumber(
                values?.benchMarkerValue
              )}
              value={convertStringToValidNumber(values?.benchMarkerValue)}
              name="benchMarkerValue"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                const re = /^[0-9\b]+$/
                if (value === '' || re.test(value)) {
                  handleInputChange(name, value)
                }
              }}
              hasError={errors?.['benchMarkerValue']}
              isRequired={false}
              errorMessage={errors?.['benchMarkerValue']}
              maxLength={WIDGET_NAME_MAX_LENGTH}
            />
          )}
        </div>
        {values?.benchMarker === TARGET_MARKERS_CONSTANT && (
          <fieldset>
            <TextInput
              inputClass="benchmark-label"
              labelText={'Benchmark/TargetLabel'}
              defaultValue={values?.benchMarkerLabel}
              name="benchMarkerLabel"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(name, value)
              }}
              hasError={errors?.['benchMarkerLabel']}
              isRequired={false}
              errorMessage={errors?.['benchMarkerLabel']}
              maxLength={WIDGET_NAME_MAX_LENGTH}
            />
          </fieldset>
        )}
        <div
          className={
            [TIME_PAST_3_DAYS, TIME_PAST_7_DAYS].includes(values?.timePeriod)
              ? ''
              : 'disable-controls'
          }
        >
          <RadioButtonWrapper>
            <RadioGroup
              label={`Show Temperature?`}
              handleSelection={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(name, value)
              }}
              radioKey="isShowTemperature"
              options={getOptions(['new-browser-yes', 'new-browser-no'])}
              selectedValue={values?.isShowTemperature}
            />
          </RadioButtonWrapper>
          {[TIME_PAST_3_DAYS, TIME_PAST_7_DAYS].includes(values?.timePeriod) && <fieldset>
            <PanelLabel className="tip">
              <TranslateComponent>Temperature is only displayed at daily granularity</TranslateComponent>
            </PanelLabel>
          </fieldset>
          }
        </div>
      </div>
    </EnergyConsumptionConfigStyles>
  )
}

export default EnergyConsumptionConfiguration
