import { useEffect, useState } from 'react'
import Table from 'src/components/Table/clientSideTable'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { TEXT_DATE_TIME_FORMAT } from 'src/components/legacy/common/time-helpers'
import translate from 'src/common/translations'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { CurvesListWrapper, ViewDetailsWrapper } from './styles'
import CharacteristicCurveHistoryDetails from './CharacteristicCurveHistoryDetails'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { useMutation } from 'src/hooks/APIHooks'
import { useSelector } from 'react-redux'
import { deriveRevertInputs } from '../helper'
import {
  UPDATE_CHARACTERISTIC_CURVE,
  CREATE_CHARACTERISTIC_CURVE
} from '../../graphql/mutations'
import { TranslateComponent } from '../../../../common/translations'

const CharacteristicCurveHistory = ({
  setMode,
  setRowData,
  rowData,
  characteristicCurveslist,
  setReloadEvents
}) => {
  const searchParams = getSearchParams()
  const buildingId = searchParams?.buildingId
  const organizationId = searchParams?.organizationId
  const [curveHistoryList, setCurveHistoryList] = useState([])
  const [showCurveHistoryDetails, setShowCurveHistoryDetails] = useState(false)
  const [historyRowData, setHistoryRowData] = useState({})
  const [isUpdating, setIsUpdating] = useState(false)
  const [t] = useTranslation()
  const userInfo = useSelector(selectUserInfo)
  const optionsTranslated = {
    updatedAt: translate('Date And Time'),
    updatedBy: translate('Updated By'),
    action: ''
  }

  const {
    onSubmit: updateCharacteristicCurve,
    loading: loadingUpdateCharacteristicCurve
  } = useMutation({
    query: UPDATE_CHARACTERISTIC_CURVE,
    dataPath: 'data.updateCharacteristicCurve',
    errorPolicy: 'global'
  })

  const {
    onSubmit: createCharacteristicCurveMutation,
    loading: loadingCreateCharacteristicCurve
  } = useMutation({
    query: CREATE_CHARACTERISTIC_CURVE,
    dataPath: 'data.createCharacteristicCurve',
    errorPolicy: 'global'
  })

  useEffect(() => {
    if (characteristicCurveslist && characteristicCurveslist.length > 0) {
      const selectedCurveId = rowData?.id || ''
      setCurveHistoryList(
        selectedCurveId
          ? characteristicCurveslist
              ?.filter((history) => history.parentCurveId === selectedCurveId)
              ?.map((d) => ({
                ...d,
                createdAt: d?.createdAt
                  ? moment(d.createdAt).format(TEXT_DATE_TIME_FORMAT)
                  : '',
                updatedAtEpoch: d.createdAt
                  ? `${new Date(d.createdAt).valueOf()}`
                  : '',
                action: <ViewDetailsLink />
              }))
          : []
      )
    } else {
      setCurveHistoryList([])
    }
  }, [characteristicCurveslist])

  const ViewDetailsLink = () => {
    return (
      <ViewDetailsWrapper>
        <button className="link-button"><TranslateComponent>VIEW DETAILS</TranslateComponent></button>
      </ViewDetailsWrapper>
    )
  }

  const nameHeadings = [
    {
      title: optionsTranslated.updatedAt,
      key: 'createdAt',
      maxWidth: '200px',
      sortField: 'updatedAtEpoch'
    },
    {
      title: optionsTranslated.updatedBy,
      key: 'createdBy',
      maxWidth: '300px',
      customComponent: function (props) {
        return props?.updatedBy || props?.createdBy
      }
    },
    {
      title: optionsTranslated.action,
      key: 'action',
      maxWidth: '120px',
      onDataClick: (data) => {
        setShowCurveHistoryDetails(true)
        setHistoryRowData(data)
      }
    }
  ]

  const editCharacteristicCurve = async (curveEditedData) => {
    const { editQueryInput, editHistoryInput } = deriveRevertInputs(
      curveEditedData,
      rowData.id,
      userInfo,
      buildingId
    )
    await updateCharacteristicCurve({ input: editQueryInput })
    await createCharacteristicCurveMutation({ input: editHistoryInput })
    setShowCurveHistoryDetails(false)
    setReloadEvents(true)
  }

  return (
    <CurvesListWrapper>
      <Table
        key={`CharacteristicCurvesTable-${organizationId} ${buildingId}`}
        rows={curveHistoryList}
        header={nameHeadings}
        search={true}
        searchFields={['createdAt', 'createdBy']}
        rowControl={[]}
        columnOrderDown={false}
      />
      {showCurveHistoryDetails && (
        <CharacteristicCurveHistoryDetails
          curveHistoryId={historyRowData?.id}
          closeDialog={() => setShowCurveHistoryDetails(false)}
          revertCurveFromHistory={editCharacteristicCurve}
          isUpdating={
            loadingCreateCharacteristicCurve || loadingUpdateCharacteristicCurve
          }
        />
      )}
    </CurvesListWrapper>
  )
}

export default CharacteristicCurveHistory
