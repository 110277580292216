export const LIST_USERTYPES = /* GraphQL */ `
  query LIST_USERTYPES {
    listUserTypes {
      items {
        authUserTypes
        createdAt
        description
        id
        name
        updatedAt
        userTypeId
      }
    }
  }
`

export const CREATE_USERTYPE = /* GraphQL */ `
  mutation CREATE_USERTYPE($input: CreateUserTypeInput!) {
    createUserType(input: $input) {
      id
    }
  }
`

export const UPDATE_USERTYPE = /* GraphQL */ `
  mutation UPDATE_USERTYPE($input: UpdateUserTypeInput!) {
    updateUserType(input: $input) {
      id
    }
  }
`

export const DELETE_USERTYPE = /* GraphQL */ `
  mutation DELETE_USERTYPE($input: DeleteUserTypeInput!) {
    deleteUserType(input: $input) {
      id
    }
  }
`


