import colors from 'src/components/layouts/colors.json'

export const KPI_LEVEL_LIST = ["high", "medium", "low"]

export const KPI_LIST = [
    { name: "energyUsage", value: false, priority: KPI_LEVEL_LIST[2]},
    { name: "comfort", value: false, priority: KPI_LEVEL_LIST[2] },
    { name: "reliability", value: false, priority: KPI_LEVEL_LIST[2] },
    { name: "performance", value: false, priority: KPI_LEVEL_LIST[2] },
    { name: "compliance", value: false, priority: KPI_LEVEL_LIST[2] },
    { name: "", value: false, priority: KPI_LEVEL_LIST[2], custom: 1 },
    { name: "", value: false, priority: KPI_LEVEL_LIST[2], custom: 2 }
]

const VALUE_ASSUMPTION_KPI_IMPACTS = {
    low: {
        value: undefined,
        timePeriod: "one-time"
    },
    medium: {
        value: undefined,
        timePeriod: "one-time"
    },
    high: {
        value: undefined,
        timePeriod: "one-time"
    }
}

const KPI_ORDER = new Map([
    ["energyUsage", 0],
    ["performance", 1],
    ["reliability", 2],
    ["comfort", 3],
    ["compliance", 4],
    ["", 5]
])

export const VALUE_ASSUMPTION_KPI = KPI_LIST?.map(({ name, custom }) => ({
    name,
    custom,
    ...VALUE_ASSUMPTION_KPI_IMPACTS
}))?.sort((a, b) => KPI_ORDER?.get(a?.name) - KPI_ORDER?.get(b?.name))


export const VALUE_ASSUMPTION_DEFAULT = () => {
    return {
        lastEdited: "",
        lastEditedBy: "",
        avoidedTruckRollAmount: 500,
        kpi: VALUE_ASSUMPTION_KPI?.map(({ name, low, medium, high, custom }) => {
            const isEnergyUsage = name === "energyUsage"
    
            return {
                name,
                low: {
                    value: isEnergyUsage ? 100 : 500,
                    timePeriod: isEnergyUsage ? "per-year" : low?.timePeriod
                },
                medium: {
                    value: isEnergyUsage ? 300 : 500,
                    timePeriod: isEnergyUsage ? "per-year" : medium?.timePeriod
                },
                high: {
                    value: isEnergyUsage ? 500 : 500,
                    timePeriod: isEnergyUsage ? "per-year" : high?.timePeriod
                },
                custom
            }
        }),
        goalAmount: 10000,
    }
}


export const KPI_SETTINGS = () => {
    return {
        "comfort": {
            icon: "comfort-impact",
            name: "Comfort",
            iconChar: "", // used for PDFs
            color: colors.COMFORT,
        },
        "reliability": {
            icon: "reliability-impact",
            name: "Reliability",
            iconChar: "", // used for PDFs
            color: colors.RELIABILITY,
        },
        "performance": {
            icon: "performance-impact",
            name: "Performance",
            iconChar: "", // used for PDFs
            color: colors.PERFORMANCE,
        },
        "energyUsage": {
            icon: "energy-impact",
            name: "Energy",
            iconChar: "", // used for PDFs
            color: colors.ENERGY_USAGE,
        },
        "compliance":{
            icon: "compliance-impact",
            name: "Compliance",
            iconChar: "", // used for PDFs
            color: colors.COMPLIANCE,
        }
    }
}

export const TIME_PERIOD_OPTION_LIST = [
    { key: "one-time", value: "OneTime" },
    { key: "per-month", value: "PerMonth" },
    { key: "per-year", value: "PerYear" },
]


export const TIME_PERIOD_OPTION_LIST_DISPLAY = {
    "one-time":"OneTime",
    "per-month": "PerMonth",
    "per-year": "PerYear"
}

export const MODES = {
    ADD: "add",
    EDIT: "edit",
    VIEW: "view",
    
}


export const KPIAPINames = {
    "comfort":{
       "Values":{
            "high": "impactHighComfortValue",
            "medium":"impactMediumComfortValue",
            "low":"impactLowComfortValue",
       },
       "timePeriod":{
            "high": "impactHighComfortUnit",
            "medium":"impactMediumComfortUnit",
            "low":"impactLowComfortUnit",
       }
    },
    "performance":{
        "Values":{
             "high": "impactHighPerformanceValue",
             "medium":"impactMediumPerformanceValue",
             "low":"impactLowPerformanceValue",
        },
        "timePeriod":{
             "high": "impactHighPerformanceUnit",
             "medium":"impactMediumPerformanceUnit",
             "low":"impactLowPerformanceUnit",
        }
     },
     "reliability":{
        "Values":{
             "high": "impactHighReliabilityValue",
             "medium":"impactMediumReliabilityValue",
             "low":"impactLowReliabilityValue",
        },
        "timePeriod":{
             "high": "impactHighReliabilityUnit",
             "medium":"impactMediumReliabilityUnit",
             "low":"impactLowReliabilityUnit",
        }
     },
     "energyUsage":{
        "Values":{
             "high": "impactHighEnergyValue",
             "medium":"impactMediumEnergyValue",
             "low":"impactLowEnergyValue",
        },
        "timePeriod":{
             "high": "impactHighEnergyUnit",
             "medium":"impactMediumEnergyUnit",
             "low":"impactLowEnergyUnit",
        }
     },
     "compliance":{
        "Values":{
             "high": "impactHighComplianceValue",
             "medium":"impactMediumComplianceValue",
             "low":"impactLowComplianceValue",
        },
        "timePeriod":{
             "high": "impactHighComplianceUnit",
             "medium":"impactMediumComplianceUnit",
             "low":"impactLowComplianceUnit",
        }
     },
     "custom1":{
        "Values":{
             "high": "impactHighCustom1Value",
             "medium":"impactMediumCustom1Value",
             "low":"impactLowCustom1Value",
        },
        "timePeriod":{
             "high": "impactHighCustom1Unit",
             "medium":"impactMediumCustom1Unit",
             "low":"impactLowCustom1Unit",
        }
     },
     "custom2":{
        "Values":{
             "high": "impactHighCustom2Value",
             "medium":"impactMediumCustom2Value",
             "low":"impactLowCustom2Value",
        },
        "timePeriod":{
             "high": "impactHighCustom2Unit",
             "medium":"impactMediumCustom2Unit",
             "low":"impactLowCustom2Unit",
        }
     },


}