/**
 * ICON BUTTON
 * Inherits button design from button theme
 */
import { css } from 'styled-components'
import { SystemThemeProps } from '../../utils'
import { createButtonStyle } from '../button/createStyle'

// ---------------------------------------------- Others ----------------------------------------------

const buttonStyleFn = createButtonStyle({ size: false, hover: false })

// ---------------------------------------------- Style Creator ----------------------------------------------

/**
 * Method to create styles dynamically for icon button
 *
 * @export
 * @return {*}
 */
export function createIconButtonStyle() {
  return ({ theme, size, ...rest }) => {
    const iconButtonTheme = theme[SystemThemeProps.ICON_BUTTON]

    // inherits style from button
    const buttonCss = buttonStyleFn({ theme, ...rest })

    // if size is invalid value or not present, fallback to default size (medium) instead of throwing error
    const btnSize = size && iconButtonTheme['size'][size] ? size : 'medium'

    const iconCss = iconButtonTheme['size'][btnSize]

    return css`
      ${buttonCss}
      flex: 0 0 auto;
      > * {
        width: 1em;
        height: 1em;
      }
      ${iconCss}
    `
  }
}
