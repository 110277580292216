/**
 * Option
 */
import { css } from 'styled-components'
import { SystemThemeProps } from '../../utils'
import { onFocus, onHover, onDisabled } from '../common/element-actions'

// ---------------------------------------------- Others ----------------------------------------------

const onBase = (base) => css`
  font-size: 1rem;
  display: inline-flex;
  padding: 8px 10px;
  cursor: pointer;
  outline: none;
  color: initial;
  ${base}
`

const onSelected = ({ hover, foucs, ...rest }) => css`
  &.selected {
    ${rest}
    ${onHover(hover)}
    ${onFocus(hover)}
  }
`

const onOmit = ({ hover, foucs, ...rest }) => css`
  &.omit {
    cursor: initial;
    ${rest}
    ${onHover(hover)}
    ${onFocus(foucs)}
  }
`

// ---------------------------------------------- Style Creator ----------------------------------------------

/**
 * Method to create styles dynamically for Option
 *
 * @export
 * @return {*}
 */
export function createOptionStyle(requiredStyle) {
  requiredStyle = {
    base: true,
    disabled: true,
    focus: true,
    hover: true,
    omit: true,
    selected: true,
    ...requiredStyle
  }

  return function optionStyleFn({ theme }) {
    const optionTheme = theme[SystemThemeProps.OPTION]

    const { root, focus, hover, selected, omit, disabled } = optionTheme

    const baseCss = requiredStyle.base && onBase(root)

    const disabledCss = requiredStyle.disabled && onDisabled(disabled)
    const focusCss = requiredStyle.focus && onFocus(focus)
    const hoverCss = requiredStyle.hover && onHover(hover)
    const omitCss = requiredStyle.omit && onOmit(omit)
    const selectedCss = requiredStyle.selected && onSelected(selected)

    return css`
      ${baseCss}
      ${disabledCss}
      ${focusCss}
      ${hoverCss}
      ${omitCss}
      ${selectedCss}
    `
  }
}
