import styles from "./building-selector.module.scss"
import { BuildingIcon } from "./BuildingIcon";
import { useDispatch } from "react-redux";
import { setSelectedBuilding } from "src/redux/slicers/buildingPicker";

export const BuildingsPopupItem = ({ building, setBuildingsPopupOpen, selectedBuilding }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    setBuildingsPopupOpen(false);
    dispatch(setSelectedBuilding({
      id: building.id,
      primary: building.primary,
      secondary: building.secondary,
      icon: building.icon,
      type: building.type,
      accountId: building.accountId,
    }));
  }

  return (
    <li className={selectedBuilding?.id === building.id ? styles.active : ''}>
      <button onClick={onClick} data-testid={`buildingSelectorBuildingItem-${building.id}`}>
        <div className={styles.buildingIcon}>
          <BuildingIcon name={building.primary} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>
          { building.primary }
          </div>
          <div className={styles.address}>
            { building.secondary }
          </div>
        </div>
      </button>
    </li>
  )
}