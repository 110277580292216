import clsx from 'clsx'
import './styles.scss'

interface CricleWithTextProps {
  className?: string
  circleProps?: any
  color?: string
  hideCircleText?: boolean
  radius: number
  text?: any
  textClassName?: string
  x: number
  y: number
}

/**
 * Circle Component with Text or other content support
 *
 * @param props
 * @returns
 *
 * Defaults
 *
 *  className: '';
 *  color: "Green";
 *  hideCircleText: false;
 *  radius: 0;
 *  text: '';
 *  textClassName: ''
 *  x: empty
 *  y: empty
 */
export default function CircleWithText({
  className,
  circleProps,
  color = 'green',
  hideCircleText = false,
  radius = 0,
  text = '',
  textClassName = '',
  x,
  y,
  ...rest
}: CricleWithTextProps) {
  const widthAndHeight = radius * 2

  const foreignObjectPos = -radius

  const showCircle =
    (text !== '' || text !== undefined || text !== null) && !hideCircleText

  return (
    <g transform={`translate(${x} ${y})`} {...rest}>
      <circle
        className={clsx('t-chart-circle', className)}
        x={0}
        y={0}
        r={radius}
        fill={color}
        {...circleProps}
      />
      {showCircle && (
        <foreignObject
          x={foreignObjectPos}
          y={foreignObjectPos}
          width={widthAndHeight}
          height={widthAndHeight}
          pointerEvents="none"
        >
          <div className={clsx('t-chart-circle-center-content', textClassName)}>
            <div className="text">{text}</div>
          </div>
        </foreignObject>
      )}
    </g>
  )
}
