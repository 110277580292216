import { createContext, useContext, useState } from 'react'
import { IModalActionType } from 'src/constants'
export const initialValues = {
  emailId: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  contactId: ''
}

const DashboardContext = createContext<any>(null)

const errorCodes = [{ stripNumber: 0, errorCode: '' }, { stripNumber: 1, errorCode: '' }, 
{ stripNumber: 2, errorCode: '' }, { stripNumber: 3, errorCode: '' }, { stripNumber: 4, errorCode: '' }]

export const DashboardContextProvider = ({ children }: any) => {
  const [confirmationModalType, setConfirmationModalType] =
    useState<IModalActionType>('')
  const [keyMetricsError, setKeyMetricsError] = useState(errorCodes)
  const [isMissingDataStatuses, setIsMissingDataStatuses] = useState(false)
  return (
    <DashboardContext.Provider
      value={{
        confirmationModalType,
        setConfirmationModalType,
        keyMetricsError,
        setKeyMetricsError,
        isMissingDataStatuses,
        setIsMissingDataStatuses
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () => useContext(DashboardContext)
