import { useEffect, useState, useRef } from 'react'
import translate from 'src/common/translations'
import { useQuery } from 'src/hooks/APIHooks'
import Select from 'src/components/legacy/components/select/select'
import styled from 'styled-components'
import colors from 'src/components/layouts/colors.json'
import { useAPIPagination } from 'src/hooks/APIPaginationHook'

export const ListBuildingEquipments = /* GraphQL */ `
  query listBuildingEquipments($id: ID!, $nextToken: String) {
    getBuilding(id: $id) {
      equipments(
        filter: { isActive: { eq: 1 }, isTIS: { eq: 1 } }
        nextToken: $nextToken
        limit: 1000
      ) {
        items {
          key: id
          value: name
          family
          type
        }
        nextToken
      }
    }
  }
`

export const GET_BUILDING_METER_TOTAL_EQUIPMENT = `query GET_BUILDING_METER_TOTAL_EQUIPMENT($body: AWSJSON) {
  getBuildingTotalMeterEquipment(body: $body)
}
`

const EquipmentSelectorContainer = styled.div`
  .dropdown-select {
    margin-top: -30px;
    min-width: 300px;
    height: 37px;
    background: ${colors.TBA_LIGHTER_GREY};

    .select-wrapper .control {
      height: 37px;
    }

    .control-label {
      font-size: 14px;
      height: 36px;
    }
  }
`

export const EquipmentListSelector = ({
  buildingId,
  equipmentTypes = ['All'],
  onChange,
  firstSortValue,
  defaultEquipmentId,
  equipmentsAvailableCheck,
  testName = ''
}) => {
  const equipmentListRef = useRef()
  equipmentListRef.current = {}

  const [equipmentsListForDisplay, setEquipmentsListForDisplay] = useState([])

  const [selectedEquipment, setSelectedEquipment] = useState('')
  const [selectedDisplay, setSelectedDisplay] = useState(null)
  const [buildingMeterTotalId, setBuildingMeterTotalId] = useState(null)

  const optionsTranslated = {
    select: translate('Select Equipment')
  }

  const [loadingEquipmentsList, refetchEquipmentsList, equipmentsList] =
    useAPIPagination(ListBuildingEquipments, 'data.getBuilding.equipments')

  const {
    data: buildingMeterTotalEquipment,
    refetch: getBuildingTotalMeterEquipment,
    loading: loadingBuildingTotal
  } = useQuery({
    query: GET_BUILDING_METER_TOTAL_EQUIPMENT,
    dataPath: 'data.getBuildingTotalMeterEquipment',
    disableInitialLoad: true
  })

  const initializeData = async (buildingId) => {
    if (buildingId) {
      setEquipmentsListForDisplay([])
      await getBuildingTotalMeterEquipment({
        body: JSON.stringify({ bId: buildingId })
      })
      refetchEquipmentsList({ id: buildingId })
    }
  }

  useEffect(() => {
    initializeData(buildingId)
  }, [buildingId])

  useEffect(() => {
    equipmentListRef.current.equipmentsList = equipmentsList
    equipmentListRef.current.loadingEquipmentsList = loadingEquipmentsList
    if (equipmentsList && equipmentsList.length > 0) {
      const res = JSON.parse(buildingMeterTotalEquipment)
      const buildingMeterTotalEquipmentList = res?.body?.equipments || []
      if (buildingMeterTotalEquipmentList?.length > 0) {
        setBuildingMeterTotalId(buildingMeterTotalEquipmentList[0]?.id)
      }
      let sortedEquipmentList = equipmentsList.sort((a, b) =>
        a?.value?.toLowerCase() > b?.value?.toLowerCase() ? 1 : -1
      )
      if (firstSortValue) {
        sortedEquipmentList = sortedEquipmentList.sort((a, b) =>
          a?.value === 'Building Meter Total' ? -1 : 1
        )
      }
      let filterEquipmentList = []
      if (equipmentTypes.includes('All')) {
        filterEquipmentList = sortedEquipmentList
      } else {
        filterEquipmentList = sortedEquipmentList.filter((x) =>
          equipmentTypes.includes(x?.type)
        )
      }
      if (filterEquipmentList?.length > 0) {
        // checks for default equipment id, passed from parent, if so select that equipment
        let currentSelectedEquipment = filterEquipmentList?.find(
          (x) => x?.key === defaultEquipmentId
        )

        // No Default is passed, check for building meter total availability
        if (
          !currentSelectedEquipment &&
          buildingMeterTotalEquipmentList?.length > 0
        ) {
          currentSelectedEquipment = filterEquipmentList?.find(
            (x) => x?.key === buildingMeterTotalEquipmentList[0]?.id
          )
        }

        // If not building meter total, select first one as selected equipment
        if (!currentSelectedEquipment) {
          currentSelectedEquipment = filterEquipmentList?.[0]
        }
        equipmentsAvailableCheck?.(true)
        setEquipmentsListForDisplay(filterEquipmentList)
        setSelectedEquipment(currentSelectedEquipment?.key || '')
        onChange?.(currentSelectedEquipment)
      } else {
        equipmentsAvailableCheck?.(false)
      }
    } else {
      // We have this timeout for the below scenario
      // When the equipments length is 0, still query is fetching
      // Don't want to pass flag as false, if we pass then select component will be unmounted, no further calls will happen
      // So we need wait to make sure the building has 0 equipments
      setTimeout(function () {
        !equipmentListRef.current.loadingEquipmentsList &&
          equipmentListRef.current.equipmentsList?.length === 0 &&
          equipmentsAvailableCheck?.(false)
      }, 3000)
    }
  }, [equipmentsList, buildingMeterTotalEquipment, loadingEquipmentsList])

  useEffect(() => {
    const selectedEq = equipmentsListForDisplay?.find(
      (x) => x.key === selectedEquipment
    )
    let selected = selectedEq?.value
    selected =
      selected &&
      buildingMeterTotalId &&
      buildingMeterTotalId === selectedEq.key
        ? selected + ' (building total)'
        : selected
    setSelectedDisplay(selected)
  }, [equipmentsListForDisplay, selectedEquipment])

  const onChangeOption = (selectedId) => {
    const selectedEquipDetails = equipmentsListForDisplay?.find(
      (x) => x.key === selectedId
    )
    setSelectedEquipment(selectedId)
    selectedEquipDetails && onChange?.(selectedEquipDetails)
  }

  return (
    <EquipmentSelectorContainer>
      <Select
        testName={testName}
        options={equipmentsListForDisplay}
        placeholder={optionsTranslated.select}
        className="dropdown-select"
        showOptionsIcon={true}
        selectedItemIcon={
          equipmentsListForDisplay?.find((x) => x.key === selectedEquipment)
            ?.type
        }
        selectedItem={selectedDisplay}
        onChange={(event) => onChangeOption(event)}
        isUserdefinedOptions={true}
      />
    </EquipmentSelectorContainer>
  )
}
