import { useTranslation } from 'react-i18next'
import { useFormikContext } from '../MultiTabTorm'
import ColorInput from 'src/components/color-input/ColorInput'
import { BenchMarkCompareStyledWrapper, PanelLabel, TabWrapper } from '../../styles'
import { TARGET_MARKERS_NONE } from 'src/common/target-markers'
import { TranslateComponent } from 'src/common/translations'


const TimeComparisonBarChartColors = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, setFieldValue } = formik
  const handleSelectColorPicker = (fieldName: string, color: any) => {
    setFieldValue(fieldName, color)
  }

  return (
    <TabWrapper>
        <BenchMarkCompareStyledWrapper>
          <div className='item'>
            <PanelLabel><TranslateComponent>Left Bar (Past Period)</TranslateComponent></PanelLabel>
            <ColorInput
            label={`Outer Bar Color (Entire Period)`}
            isRequired={true}
            backgroundColor={values?.outerBarColor}
            update={(color)=> handleSelectColorPicker('outerBarColor', color)}
            />
            <ColorInput
            label={`Inner Bar Color (To Date/Time Period)`}
            isRequired={true}
            backgroundColor={values?.innerBarColor}
            update={(color)=> handleSelectColorPicker('innerBarColor', color)}
            />
            {
              values?.leftTargetMarker !== TARGET_MARKERS_NONE && <ColorInput
              label={`Benchmark/Target Color`}
              isRequired={true}
              backgroundColor={values?.leftBenchmarkColor}
              update={(color)=> handleSelectColorPicker('leftBenchmarkColor', color)}
              />
            }
          </div>          
          <br/>
          <div className='vertical'></div>
          <div className='item'>
            <PanelLabel><TranslateComponent>Right Bar (Current Period)</TranslateComponent></PanelLabel>
            <ColorInput
            label={`Bar Color`}
            isRequired={true}
            backgroundColor={values?.rightBarColor}
            update={(color)=> handleSelectColorPicker('rightBarColor', color)}
            />
            {
              values?.rightTargetMarker !== TARGET_MARKERS_NONE && <ColorInput
              label={`Benchmark/Target Color`}
              isRequired={true}
              backgroundColor={values?.rightBenchmarkColor}
              update={(color)=> handleSelectColorPicker('rightBenchmarkColor', color)}
              />
            }
          </div>
        </BenchMarkCompareStyledWrapper>
    </TabWrapper>
  )
}

export default TimeComparisonBarChartColors
