import { useEffect, useState, ReactNode, Dispatch, SetStateAction } from 'react'
import { NavLink } from 'react-router-dom'
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  flip,
  useInteractions,
  useDismiss,
  useRole,
  useFocus,
  useHover,
  type FloatingContext
} from '@floating-ui/react'
import { trackEvent } from 'src/amplitude.js'
import { type MenuItem } from './MainNavigation'
import styles from './sidebar.module.scss'
import { TranslateComponent } from 'src/common/translations'
import config from 'src/aws-exports.js'

interface NavigationItemSingleProps {
  item: MenuItem
  menuIsOpen?: boolean
  tooltip?: boolean
  forceHoverState?: boolean
  children?: ReactNode
  close?: () => void
}

export const NavigationItemSingle = ({
  item,
  menuIsOpen = true,
  tooltip = true,
  forceHoverState,
  children,
  close = () => {
    // do nothing
  }
}: NavigationItemSingleProps) => {
  if (!item) return

  const [isOpen, setIsOpen] = useState(false)
  const { refs, floatingStyles, context } = setupFloating(isOpen, setIsOpen)
  const { getReferenceProps, getFloatingProps } = setupInteractions(context)

  useEffect(() => {
    if (isOpen && item.analytics) trackEvent(item.analytics)
  }, [isOpen])

  return (
    <li
      className={`${item.bottomDivider ? styles.bottomDivider : ''} ${
        item.topDivider ? styles.topDivider : ''
      }`}
    >
      <NavLink
        to={getHref(item)}
        onClick={(event) => {
          close()
          if (item?.onClick) {
            item.onClick(event)
          }
        }}
        ref={refs.setReference}
        target={getTarget(item)}
        {...getReferenceProps()}
        aria-label={item.name}
        data-testid={`navSingle ${item.name}`}
        className={
          ({ isActive }) => (isActive ? styles.selectedRoute : '') ||
          forceHoverState ? styles.selectedRoute : ''
        }
      >
        {item.icon && <div className={styles.icon}>{item.icon}</div>}
        {menuIsOpen && item.name && (
          <span className={styles.title}>
            <TranslateComponent className={styles.title}>
              {item.name}
            </TranslateComponent>
          </span>
        )}
        {menuIsOpen && children}

        {item.iconRight && <div className={styles.icon}>{item.iconRight}</div>}
      </NavLink>
      {tooltip && !menuIsOpen && isOpen && (
        <div
          className={styles.tooltip}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          data-testid={`navSingleTooltip ${item.name}`}
        >
          <TranslateComponent>{item.name}</TranslateComponent>
        </div>
      )}
    </li>
  )
}

const getHref = (item: MenuItem) => {
  const href = item?.href || item?.links?.[0]?.href || ''
  return item?.type === 'commandCenter'
    ? config?.appEnvironment?.externalLinks?.commandCenterBaseURL + href
    : href
}

const getTarget = (item: MenuItem) => {
  if (item?.target) {
    return item.target
  }
  return item?.type === 'commandCenter' ? '_blank' : ''
}

function setupFloating(
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
) {
  return useFloating({
    placement: 'right',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(18), flip(), shift()]
  })
}

function setupInteractions(context: FloatingContext) {
  const hover = useHover(context)
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)
  return useInteractions([hover, focus, dismiss, role])
}
