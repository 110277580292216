import React from "react"
import PropTypes from "prop-types"
import { SiteDataCollectionNotificationsSettingsDialog } from "./site-data-collection-notification-settings"
import { IntelligentServicesNotificationsSettingsDialog } from "./intelligent-services-notification-settings"

export const NotificationsSettingsDialog = ({ categoryKey, ...rest }) => {
	switch (categoryKey) {
		case "dataloss":
			return <SiteDataCollectionNotificationsSettingsDialog {...rest} />
		case "intelligentServices":
			return <IntelligentServicesNotificationsSettingsDialog {...rest} />
		default:
			return null
	}
}

NotificationsSettingsDialog.propTypes = {
	categoryKey: PropTypes.string,
}
