import React from 'react'
import styled from 'styled-components'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import COLORS from 'src/components/legacy/common/colors.json'
import { useTranslation } from 'react-i18next'

const ShowDisabledLocations = styled.div`
  border-top: 1px solid ${COLORS.TBA_DARK2_GREY};
  border-bottom: 1px solid ${COLORS.TBA_DARK2_GREY};
  padding: 10px 15px;
`

const BodyShowDisabledLocations = ({ isChecked, onChange = () => {} }) => {
  const [t] = useTranslation()
  return (
    <ShowDisabledLocations className="show-disabled-locations">
      <Checkbox
        id="show_disabled_locations"
        label="Show Disabled Buildings"
        isChecked={isChecked}
        toggleCheck={(e) => {
          onChange(e.target.checked)
        }}
      />
    </ShowDisabledLocations>
  )
}

export default BodyShowDisabledLocations
