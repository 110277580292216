import { useFormikContext } from '../MultiTabTorm'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { BaselineComparisonWidgetPreview } from 'src/pages/dashboards/components/DashboardCardWidget/BaselineComparisonWidgetPreview'
import { WidgetPreviewWrapper } from './../widgetPreviewWrapper'
import { WidgetChartContainer } from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { useMemo } from 'react'

export const BaselineComparisonWidgetPreviewWrapper = () => {
  const formik = useFormikContext()
  const { values } = formik
  const { formikErrors, selectedBaseLine } = useWidgetsContext()
  const { baselineEquipment } = selectedBaseLine || {}
 
  const getEquipmentList = () => {
    return [
      {
        buildingName: baselineEquipment?.building?.name,
        equipmentName: baselineEquipment?.name,
        accountName: baselineEquipment?.building?.accountName
      }
    ]
  }

  const getPreviewDetails = useMemo(() => ({
    widgetName: values?.name,
    buildingId: baselineEquipment?.building?.id,
    equipmentList: getEquipmentList(),
    equipmentId: selectedBaseLine?.equipmentId,
    selectedTimePeriod: values?.timePeriod?.split('/')?.[1] || '',
    equipmentType: baselineEquipment?.type,
    unitsOfMeasure: 'kWh',
    symbol: 'kWh',
    selectedAggrValue: 'sum',
    buildingName: baselineEquipment?.building?.name,
    equipmentName: baselineEquipment?.name,
    widgetDescription: values?.description ?? '',
    baselineId: values?.baselineId,
    timeZone: baselineEquipment?.building?.timeZone,
  }),[values, baselineEquipment])

  return (
    <WidgetPreviewWrapper
      widgetName={values?.name}
      error={Object.keys(formikErrors)?.length > 0}
      chartType="baseline-comparison-bar"
    >
      <WidgetChartContainer>
        <div style={{height: 270}}>
          <BaselineComparisonWidgetPreview
            widgetDetails={getPreviewDetails}
          />
        </div>
      </WidgetChartContainer>
    </WidgetPreviewWrapper>
  )
}
