import { useMemo, useRef, useState } from 'react'

import './styles.scss'
import { useOptions } from './useOptions'
import Axes from './Axes'
import BaseWrapper from '../../../../../chart-library/CommonComponents/BaseWrapper'
import Series from './Series'
import Svg from '../../../../../chart-library/CommonComponents/Svg'
import { Tooltip } from '../../../../../chart-library/CommonComponents/Bar/constants'
import ToolTip from '../../../../../chart-library/CommonComponents/Bar/Tooltip/Tooltip'

export default function DataComparisonBarWidgetChart(props) {
  // API flow - 1
  // Once Data is recevied from API. Pass this to this memo, which returns series data required for bar
  const barChartData = useMemo(
    () => props?.data,
    [props?.data]
  )
  const [tooltip, setTooltip] = useState<Tooltip | null>(null);
  const options = useOptions(barChartData)

  // provide boundary of tooltip to position properly
  const svgRef: any = useRef(null)

  return (
    <BaseWrapper options={options} className='bar-chart-dimention'>
      <Svg ref={svgRef}>
        {barChartData?.barData && <>
          <Axes data={barChartData}/>
          <Series options={options} series={barChartData} setTooltip={setTooltip} />
        </>}
      </Svg>
      {tooltip !== null ? <ToolTip tooltip={tooltip} /> : null}
    </BaseWrapper>
  )
}
