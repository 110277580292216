//import moment from 'moment'
import { getMoment } from "./MomentUtils"

const moment = getMoment()

export const ISO_FORMAT = 'YYYY-MM-DD'
export const getPropByPath = (
  object: any,
  path: any = [],
  defaultValue: any
) => {
  const _path = Array.isArray(path) ? path : path.split('.')
  if (object && _path.length)
    return getPropByPath(object[_path.shift()], _path, defaultValue)
  return object === undefined ? defaultValue : object
}

export const formattedDate = ({ date, locale, options }: any) =>
  new Date(date).toLocaleDateString(
    locale || 'en-us',
    options || { year: 'numeric', month: 'short', day: 'numeric' }
  )
export const tranformDateToISO = (date: any) => moment(date).format(ISO_FORMAT)
export const ASC = 'Asc'
export const DESC = 'Desc'

/**
 *
 * @param sortField
 * @param sortOrder
 * @param data
 * @returns
 */

export const sortDataByName = (
  sortField: string,
  sortOrder: string,
  data: any,
  columnData?: any
) => {
  if (!sortField) return
  const isDateField = columnData?.find(
    (columnObj: any) => columnObj?.id === sortField
  )?.date
  const sorted = [...data].sort((a, b) => {
    if (isDateField) {
      const dateA = new Date(a.name).getTime()
      const dateB = new Date(b.name).getTime()
      if (sortOrder === ASC) {
        return (dateA || -Infinity) - (dateB || -Infinity)
      } else {
        return (dateB || -Infinity) - (dateA || -Infinity)
      }
    } else {
      const aValue =
        a[sortField] === null || a[sortField] === undefined ? '' : a[sortField]
      const bValue =
        b[sortField] === null || b[sortField] === undefined ? '' : b[sortField]
      return (
        aValue?.toString()?.localeCompare(bValue?.toString(), 'en', {
          numeric: true
        }) * (sortOrder === ASC ? 1 : -1)
      )
    }
  })
  return sorted
}
