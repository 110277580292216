import React, { useState,useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { createOptionStyle } from '../../styles/styleCreators'
import Checkbox from '../Checkbox'

const OptionRoot = styled.li(createOptionStyle)

const Option = React.forwardRef(function Option(inProps, ref) {
  const {
    CheckboxProps,
    children,
    className,
    disabled,
    multiple,
    omit,
    selected,
    intermediate,
    startAdornment,
    hideCheckbox,
    onClick,
    ...rest
  } = inProps

  const [selectedState, setSelectedState] = useState(selected)
  const isSelected = `${
    selectedState && !disabled ? 'selected' : false
  } ${omit} ${intermediate}`
  
  useEffect(() => {
    setSelectedState(selected)
  },[selected])

  return (
    <OptionRoot
      {...rest}
      className={`${className} ${isSelected}`}
      disabled={disabled}
      omit={omit}
      ref={ref}
      onClick={(e) => {
        if (!disabled) {
           if (multiple) {
          onClick(e)
          setSelectedState(!selectedState)
        } else {
          onClick(e)
        }
        }
      }}
    >
      {startAdornment}
      {multiple && !hideCheckbox && (
        <Checkbox
          intermediate={intermediate}
          checked={selectedState}
          disabled={disabled}
          {...CheckboxProps}
        />
      )}
      {children}
    </OptionRoot>
  )
})

Option.propTypes = {
  /**
   * The option element to populate within select.
   */
  children: PropTypes.any,

  /**
   * If true, the option is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * If true, the checkbox will be removed for multiple select.
   */
  hideCheckbox: PropTypes.bool,

  /**
   * If true, the option will be static element, does not act for any default action (click, focus, styles...) which option has.
   */
  omit: PropTypes.bool,

  /**
   * If true, the option is selected.
   */
  selected: PropTypes.bool,

  /**
   * This value will be exposed via onChange handler if this option is selected
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

Option.defaultProps = {
  CheckboxProps: {},
  disabled: false,
  hideCheckbox: false,
  omit: false,
  selected: false,
  startAdornment: null
}

export default React.memo(Option)
