import styled from "styled-components"
import colors from 'src/components/legacy/common/colors.json'


export const StyledButtonsContent = styled.div`
  text-align:right;
  & :first-child{
    margin-right:8px;
  }
`


export const StyledContainer = styled.div`
  width:50%;
  margin-right:20px;
  span.error{
    color: #d52b1e;
  }
`

export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled.span`
  padding-top: 24px;
`

export const SeasonSelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
`

export const SeasonDateWrapper = styled.div`
  margin-top: 6px;
  margin-right: 20px;
  display: flex;
  vertical-align: middle;
  align-items: center;
`

export const FormSubHeader = styled.div`
color: #000;
    font-size: 16px;
    font-weight: 700;
    height: 20px;
    letter-spacing: -.24px;
    line-height: 20px;
    margin: 15px 0;
    padding: 0 10px 0 5px;
`

export const CheckboxContainer = styled.div`
  width: 15%;
  align-self: flex-start;
  margin: 10px 0px;
`

export const DateDL = styled.dl`
  display: flex;
  flex-flow: wrap;
  width: 35em;
  margin: 0px 1em;
`

export const DateDT = styled.dt`
  flex-basis: 20%;
  padding: 2px 4px;
  font-weight: bold;
  text-transform: capitalize;
`

export const DateDD = styled.dd`
  flex-basis: 70%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin: 0px;
  padding: 2px 4px;
`

export const TimeOfUseDesc = styled.div`
  width: 50%;
`

export const UploadContainer = styled.div`
	.instructions {
		width: 100%;
		margin-bottom: 20px;
	}

	.upload-instructions {
		width: 80%
	}

	.button-container {
		width: 20%;
		.download {
			float: right
		}
	}

	span .link{
		margin-top: 10px;
	}

	.duplicate-error {
		display: flex;
		border: 2px solid ${colors.RED};
		width: 70%;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		margin-top: 20px;
		 p {
			color: ${colors.RED};
			font-weight:700;
			font-sizr: 12px;
			margin:5px;
		 }
	}
`


export const BottomList = styled.ul`
  background-color: #f4f4f4;
  padding: 0 0 16px;
  flex: 1;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;
  line-height: 20px;
`

export const BottomListItem = styled.li`
  background-color: #f4f4f4;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  width: 100%;
  display: flex;
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  height: 18px;
  letter-spacing: 0.24px;
  line-height: 19px;
  line-height: normal;
  padding: 0 10px 0 8px;
  position: relative;
`

export const BottomHead = styled.h3`
  background-color: #f4f4f4;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.24px;
  line-height: 19px;
  margin: 0;
  padding: 0 10px;
`

export const BottomText = styled.span`
  line-height: 18px;
  max-width: calc(100% - 18px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledTagContainer = styled.div`
padding-bottom:4px;
  .selectrix-wrapper.selectrix-wrapper--type-autocomplete .react-selectrix .rs-body{
    display:none;
  }
  .selectrix-wrapper.selectrix-wrapper--type-autocomplete .react-selectrix .rs-header .rs-arrow-wrapper{
    display:none;
  }
  span.error{
    color: #d52b1e;
  }
`

export const InputField = styled.div`
width: 100%;
.input-field {
  width: 100%;
}
`

export const MinInputField = styled.div`
width: 50%;
.input-field {
  width: 100%;
}
`