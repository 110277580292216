export const light = {
  backgroundColor: '{colors.neutral.90}',
  borderColor: 'transparent',
  color: '{colors.white}',
  fontSize: '{fontSizes.medium}',
  lineHeight: 'normal',
  fontWeight: '{fontWeights.medium}',
  borderRadius: '{fontSizes.medium}',
  paddingBlockStart: '12px',
  paddingBlockEnd: '12px',
  paddingInlineStart: '24px',
  paddingInlineEnd: '24px',
  transitionDuration: '100ms',

  _hover: {
    backgroundColor: '{colors.neutral.70}',
    color: '{colors.white}',
    borderColor: 'transparent',
  },
  _focus: {
    backgroundColor: '{colors.neutral.70}',
    color: '{colors.white}',
    borderColor: 'transparent',
  },
  _disabled: {
    backgroundColor: '{colors.neutral.10}',
    color: '{colors.neutral.50}',
    borderColor: 'transparent',
  },
  _active: {
    backgroundColor: '{colors.neutral.70}',
    color: '{colors.white}',
    borderColor: '{transparent}',
  },

  transparent: {
    background: 'rgba(255, 255, 255, 0.05)',
    border: '2px solid rgba(255, 255, 255, 0.20);',
    paddingBlockStart: '10px',
    paddingBlockEnd: '10px',
    paddingInlineStart: '22px',
    paddingInlineEnd: '22px',

    disabled: {
      background: 'rgba(255, 255, 255, 0.05)',
      foreground: '{colors.neutral.40}'
    }
  },

  inverse: {
    background: '{colors.white}',
    foreground: '{colors.neutral.90}',
  },

  gray: {
    background: '{colors.neutral.20}',
    foreground: '{colors.neutral.90}',

    hover: {
      backgroundColor: '{colors.neutral.20}',
      foreground: '{colors.neutral.60}',
    },

    focus: {
      backgroundColor: '{colors.neutral.20}',
      foreground: '{colors.neutral.50}',
    },
  },

  green: {
    background: 'linear-gradient(94deg, #45FF9B -17.08%, #5EFF5E 53.65%)',
    foreground: '#192805',
    shadow: '0px 0px 35.5px 0px rgba(78, 255, 111, 0.40)',

    hover: {
      backgroundColor: 'linear-gradient(94deg, #45FF9B -17.08%, #5EFF5E 53.65%)',
      foreground: '#34510E',
    },

    focus: {
      backgroundColor: 'linear-gradient(94deg, #45FF9B -17.08%, #5EFF5E 53.65%)',
      foreground: '#436713',
    },
  },

  red: {
    background: '{colors.red.lightest}',
    foreground: '{colors.red.50}',

    hover: {
      background: '{colors.red.50}',
      foreground: '{colors.white}',
    },

    focus: {
      background: '{colors.red.50}',
      foreground: '{colors.white}',
    },
  },

  primary: {},

  menu: {
    _hover: {
      backgroundColor: '{colors.neutral.20}',
      color: '{colors.neutral.90}',
      borderColor: 'transparent',
    },
    _focus: {
      backgroundColor: '{colors.neutral.20}',
      color: '{colors.neutral.90}',
      borderColor: 'transparent',
    },
    _active: {
      backgroundColor: '{colors.neutral.20}',
      color: '{colors.neutral.90}',
      borderColor: 'transparent',
    },
  },

  small: {
    paddingBlockStart: '12px',
    paddingBlockEnd: '12px',
    paddingInlineStart: '16px',
    paddingInlineEnd: '16px',
    borderRadius: '999px',
  },

  link: {
    backgroundColor: 'transparent',
    color: '{colors.font.primary}',

    hover: {
      backgroundColor: '{colors.neutral.10}',
      color: '{colors.font.primary}',
    },

    focus: {
      backgroundColor: '{colors.neutral.10}',
      color: '{colors.font.primary}',
    }
  }
}