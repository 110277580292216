import styles from "./building-selector.module.scss"
import { Chips } from "../Table/Chips";
import { Chip, ChipColor } from "../Table/Chip";
import { TranslateComponent } from "src/common/translations";
import { OrganizationIcon } from "./OrganizationIcon";
import { useDispatch } from "react-redux";
import { setSelectedBuilding } from "src/redux/slicers/buildingPicker";
import {
  SEARCH_BUILDINGS,
  type OrganizationId,
} from 'src/components/buildingNavigation/graphql'
import { API } from "aws-amplify";
import { type GraphQLOptions } from "@aws-amplify/api-graphql/lib-esm/types/index.d"
import { accountMapping, AccountMappingBuilding, AccountMappingOrganization } from "src/components/buildingNavigation/helper";

export const OrganizationsPopupItem = ({ organization, setOrganizationPopupOpen, showBuildingCount, selectedOrganization }) => {
  const dispatch = useDispatch();
  const onClick = getOnClickHandler(organization, setOrganizationPopupOpen, dispatch);

  return (
    <li className={selectedOrganization?.id === organization.id ? styles.active : ''}>

      <button onClick={onClick} data-testid={`buildingSelectorOrgItem-${organization.id}`}>
        <div className={styles.organizationIcon}>
          <OrganizationIcon name={organization.primary} />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>
            { organization.primary }
          </div>

          {showBuildingCount && organization.buildings &&
            <div className={styles.count}>
              <Chips>
                <OrganizationBuildingChip organization={organization} />
              </Chips>
            </div>
          }
        </div>
      </button>

    </li>
  )
}

const getOnClickHandler = (organization, setOrganizationPopupOpen, dispatch) => {
  return () => {
    dispatch(setSelectedBuilding({
      ...organization,
      skipTokensWhileLoading: true
    }));
    setOrganizationPopupOpen(false);

    getFullOrg(organization.id).then(organization => {
      // Load the full org because buildings may not be fully counted from the API limit 
      // of 1000 without a specific ID to narrow it down in the full popup list.
      organization?.buildings?.length === 1
        ? dispatch(setSelectedBuilding(organization?.buildings[0]))
        : dispatch(setSelectedBuilding(organization));
    })
  }
}

const OrganizationBuildingChip = ({ organization }) => {
  if(!organization?.buildings?.length) return;
  const isSingular = (organization.buildings.length === 1);
  const text = `${organization.buildings.length} ${ isSingular ? 'building' : 'buildings' }`;

  return (
    <Chip color={ChipColor.darkGreen}>
      <TranslateComponent>{ text }</TranslateComponent>
    </Chip>
  )
}

const getFullOrg = async (organizationId:OrganizationId):Promise<AccountMappingOrganization> => {
  try {
    const options:GraphQLOptions = {
      query: SEARCH_BUILDINGS,
      variables: {
        filter: {
          or: [
            { accountId: { eq: organizationId } },
          ]
        },
        limit: 1000
      }
    }
    const apiData: any = await API.graphql(options);
    const accountsData = accountMapping(apiData?.data?.searchBuildings?.items, true);
    return Object.values(accountsData)?.[0] as AccountMappingOrganization;
  } catch (error) {
    console.error(error)
  }
}