import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import translate, { TranslateComponent } from 'src/common/translations'
import { RadioGroup } from 'src/components'
import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ActiveSuppressionsList from './active-suppressions-list/index'
import SuppressionsHistoryList from './history-suppressions-list'
import {
  DATE_FORMAT,
  BACKEND_DATE_FORMAT
} from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import Label from 'src/components/legacy/components/label/label'
import DateInput from 'src/components/legacy/components/datepicker-input'
import { SearchDates, AutomatedTestSuppressionsDiv } from './styles'
import { useQuery } from 'src/hooks/APIHooks'
import {
  getTestActiveSuppressions,
  getSuppressionHistory,
  ListBuildingEquipments,
} from './graphql'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { formatSuppressionsHistory, getEquipmentName } from './helper'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { useAPIPagination } from 'src/hooks/APIPaginationHook'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/RootState'
import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
import { useAppDispatch } from 'src/redux/store'
import  withFeatureRestrictions from 'src/common/featureRestrictionHOC'

const AutomatedTestSuppressionsContainer = () => {
  const { buildingId } = getSearchParams()
  const [searchParams] = useSearchParams()
  const [selectedListType, setListType] = useState('activeSuppressions')
  const [fromDate, setfromDate] = useState(moment().subtract(1, 'year'))
  const [toDate, settoDate] = useState(moment().format())
  const [dateRangeIsIncorrect, setDateRangeIsIncorrect] = useState(false)
  const [suppressionHistoryList, setSuppressionHistoryList] = useState([])
  const [suppressionHistoryLists, setSuppressionHistoryLists] = useState([])
  const [equipmentData, setEquipmentData] = useState([])
  const [buildingName, setBuildingName] = useState('')
  const { t } = useTranslation()
  const DEFAULT_LIST_TYPE = 'activeSuppressions'
  const HISTORY_LIST_TYPE = 'suppressionsHistory'
  const ERROR_MESSAGES = {
    invalidDate: translate("End Date can't be earlier than Start Date")
  }
  const [suppressionHistoryListToTranslate, setSuppressionHistoryListToTranslate] = useState([])
  const appDispatch = useAppDispatch()

  const optionsTranslated = { 
    selectBuildingAbove: translate('Select a building above to view automated test suppressions'),
  }

  const OPTIONS = [
    {
      label: 'Active Suppressions',
      value: DEFAULT_LIST_TYPE,
      htmlFor: 'Active-Suppressions'
    },
    {
      label: 'Suppression History',
      value: HISTORY_LIST_TYPE,
      htmlFor: 'Suppression-History'
    }
  ]

  const { translatedJSON } = useSelector((state : RootState) => state?.translationCombinedSlice)

  useEffect(() => {
    appDispatch(translateJSON({ json: suppressionHistoryListToTranslate }) as any);
  }, [suppressionHistoryListToTranslate])

  useEffect(() => {
    if (translatedJSON) {
      const translatedFinalSuppressionHistoryList = suppressionHistoryList.map( (suppression, index) => {
        return ({
          ...suppression,
          displayName: translatedJSON[index]?.displayName,
          equipTypeUI: translatedJSON[index]?.equipType
        })
      })
      setSuppressionHistoryList(translatedFinalSuppressionHistoryList?.sort((a, b) => a?.displayName?.localeCompare(b?.displayName)))
    }
  }, [translatedJSON, suppressionHistoryListToTranslate])

  const [loadingEquipmentData, refetchEquipmentsList,  equipmentsList ] = useAPIPagination(
    ListBuildingEquipments,
    'data.getBuilding.equipments'
  )

  useEffect(() => {
      if (equipmentsList && equipmentsList?.length) {
        setEquipmentData(equipmentsList)
        setBuildingName(equipmentsList[0]?.building?.name)

      }
  }, [equipmentsList])


  const {
    refetch: refetchSuppressionHistory,
    loading: loadingSuppressionHistory
  } = useQuery({
    query: getSuppressionHistory,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listSettingsByBuilding.items'
  })

  useEffect(() => {
      setEquipmentData([])
  }, [buildingId, selectedListType, searchParams])

  const getSettingsByBuilding = async (buildingId) => {
    try {
      const input = {
        req_bid: buildingId,
        req_type: 'sp',
        isSummary: 0
      }
      const suppressionHistory = await refetchSuppressionHistory({
        id: buildingId
      })
      const finalSummary = formatSuppressionsHistory(
        suppressionHistory
      )
      // filter active suppression based on current date
      const suppresionHistoryByDate = finalSummary?.filter((x) => {
          return (moment(x?.endDate).isSameOrAfter(moment(fromDate)) &&
            moment(x?.endDate).isSameOrBefore(moment(toDate))) ||
          (x?.endDate !== 'Indefinitely' && x?.type === 'TestSuppression')
      })

      // setSuppressionHistoryList(suppresionHistoryByDate)
      const suppresionHistoryToTranslate = suppresionHistoryByDate.map((item)=>({
        displayName: item?.displayName,
        equipType: item?.equipmentTypeUIName
      }))
      setSuppressionHistoryList(suppresionHistoryByDate)
      setSuppressionHistoryListToTranslate(suppresionHistoryToTranslate)
    } catch (e) {}
  }

  const addingEquipmentName = () => {
    let testObject;
    const testArray = []
    const timeFormat = 'MMM DD, YYYY'
    suppressionHistoryList?.map((x) => {
      if (x?.suppressionLevel === "Equipment") {
        testObject = {
          ...x,
          Name: getEquipmentName(equipmentData, x?.equipmentId),
          updatedAt: moment(x?.updatedAt).format(timeFormat),
          startDate: moment(x?.startDate).format(timeFormat),
          endDate: x?.endDate
            ? moment(x?.endDate).format(timeFormat)
            : 'Indefinitely',
        }
      } else {
        testObject = {
          ...x,
          Name: buildingName ? buildingName : '--',
          updatedAt: moment(x?.updatedAt).format(timeFormat),
          startDate: moment(x?.startDate).format(timeFormat),
          endDate: x?.endDate
            ? moment(x?.endDate).format(timeFormat)
            : 'Indefinitely',
        }
      }
      testArray.push(testObject)
    })
   setSuppressionHistoryLists(testArray)
  }

  useEffect( () => {
    if (selectedListType === HISTORY_LIST_TYPE) {
      addingEquipmentName()
    }
  }, [suppressionHistoryList, equipmentData])

    const getSettingsQuery = async () => {
    setEquipmentData([])
    await Promise.all([refetchEquipmentsList({id: buildingId}), getSettingsByBuilding(buildingId)])
  }

  useEffect(() => {
    if (selectedListType === HISTORY_LIST_TYPE) {
      setSuppressionHistoryLists([])
      getSettingsQuery()
    }
  }, [buildingId, selectedListType, fromDate, toDate, searchParams ])

  const handleListTypeChange = (name, value) => {
    setListType(value)
    if(value === "suppressionsHistory")
      {
        trackEvent(USER_EVENTS.BUILDING_DATA.events.VIEW_SUPPRESSION_HISTORY)
      }
    else if (value === "activeSuppressions")
      {
        trackEvent(USER_EVENTS.BUILDING_DATA.events.VIEW_ACTIVE_SUPPRESSIONS)
      }
  }

  const handleInputChange = (name, value) => {
    let errorDate = false
    if (name === 'fromDate') {
      setfromDate(value)
      errorDate = moment(value, DATE_FORMAT).isAfter(
        moment(toDate, DATE_FORMAT)
      )
      setDateRangeIsIncorrect(errorDate)
    } else if (name === 'toDate') {
      settoDate(value)
      errorDate = moment(value, DATE_FORMAT).isBefore(
        moment(fromDate, DATE_FORMAT)
      )
      setDateRangeIsIncorrect(errorDate)
    }
  }


  return (
   <AutomatedTestSuppressionsDiv>
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        padding="0"
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <PageHeader
            pageTitle={
              <TranslateComponent>
                Automated Test Suppressions
              </TranslateComponent>
            }
          />
        </Content>
        {buildingId && (
          <>
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <div className="table-top-content">
                <div className="radio-option">
                  <RadioGroup
                    className="radio-option"
                    options={OPTIONS}
                    handleSelection={({ target: { value, name } }) => {
                      handleListTypeChange(name, value)
                    }}
                    radioKey="listRadioGroup"
                    selectedValue={selectedListType}
                  />
                </div>
                {selectedListType === HISTORY_LIST_TYPE && (
                  <SearchDates>
                    <div className="date-input">
                      <Label text={'From'} isColonRequired={true} />
                      <DateInput
                        date={moment(fromDate).format(DATE_FORMAT)}
                        format={DATE_FORMAT}
                        onChange={(value: any) =>
                          handleInputChange(
                            'fromDate',
                            moment(value).format('MM/DD/YYYY')
                          )
                        }
                      />
                    </div>
                    <div className="date-input">
                      <Label text={'To'} isColonRequired={true} />
                      <DateInput
                        date={moment(toDate).format(DATE_FORMAT)}
                        format={DATE_FORMAT}
                        onChange={(value: any) =>
                          handleInputChange(
                            'toDate',
                            moment(value).format('MM/DD/YYYY')
                          )
                        }
                        outerErrorMessage={
                          dateRangeIsIncorrect ? ERROR_MESSAGES.invalidDate : ''
                        }
                      />
                    </div>
                  </SearchDates>
                )}
              </div>
            </Content>
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              {selectedListType === DEFAULT_LIST_TYPE ? (
                <ActiveSuppressionsList />
              ) : (
                <SuppressionsHistoryList
                  suppressionHistoryList={suppressionHistoryLists}
                  loading={loadingSuppressionHistory || loadingEquipmentData}
                />
              )}
            </Content>
          </>
        )}
      </Container>
      {!buildingId && (
        <div>
          <h2>{optionsTranslated.selectBuildingAbove}</h2>
        </div>
      )}
    </AutomatedTestSuppressionsDiv>
  )
}


export const AutomatedTestSuppressions = withFeatureRestrictions(
  AutomatedTestSuppressionsContainer
)

 
