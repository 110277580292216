/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxisDiM } from '../../../../../../chart-library/Utils/defaults'
import { useChartContext } from '../../../../../../chart-library/CommonComponents/BaseWrapper'
import { useCallback, useMemo } from 'react'

export function useConfig() {
  const chart = useChartContext()

  const axis = chart.axis
  const scale = chart.scale
  const canvas = chart.grid.getGrid(0)

  // xAxis
  const xAxis = useMemo(() => axis.getAxis(AxisDiM.x, 0), [axis.lastUpdatedAt])!

  const xScale = useMemo(() => {
    const x = scale.getScale(AxisDiM.x, 0)

    // adjust padding input (0 to 1) to control the size and gap between bars
    x && x.fn.padding(0.3)

    return x
  }, [scale.lastUpdatedAt])!

  // yAxis
  const yAxis = useMemo(() => axis.getAxis(AxisDiM.y, 0), [axis.lastUpdatedAt])!

  const yScale = useMemo(
    () => scale.getScale(AxisDiM.y, 0),
    [scale.lastUpdatedAt]
  )!

  const updateXDomain = useCallback(
    (domain) => {
      if (scale.lastUpdatedAt) scale.setDomain(AxisDiM.x, 0, domain, true)
    },
    [scale.lastUpdatedAt]
  )

  if (!scale.lastUpdatedAt || !axis.lastUpdatedAt) {
    return { isInitialized: false }
  }

  return {
    canvas,
    axis,
    xAxis,
    yAxis,
    scale,
    xScale,
    yScale,
    scaleUpdatedAt: scale.lastUpdatedAt,
    axisUpdatedAt: axis.lastUpdatedAt,
    gridUpdatedAt: chart.grid.lastUpdatedAt,
    updateXDomain,
    isInitialized: true,
    AxisDiM
  }
}
