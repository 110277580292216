import PropTypes from "prop-types"
import { Text, View } from "@react-pdf/renderer"
import { styles } from "./pdf-priority-status-block.styles"
import i18next from "i18next"
const {
	info,
	infoLine,
	infoTitle,
	infoValue,
	priorityStyle,
	priorityStatus,
} = styles

const PDFPriorityStatusBlock = ({ priority, status, nextStep, assignedTo }) =>
	<View style={info} wrap={false}>
		{priority ? <View style={priorityStyle}>
			<Text>{`${i18next.t("opportunities:Priority")}:`}<Text style={priorityStatus}>{priority}</Text></Text>
		</View> : null}
		<View>
			{status ? <View style={infoLine}>
				<Text style={infoTitle}>{`${i18next.t("opportunities:Status")}:`}</Text>
				<Text style={infoValue}>{status}</Text>
			</View> : null}
			{nextStep ? <View style={infoLine}>
				<Text style={infoTitle}>{`${i18next.t("opportunities:NextStep")}:`}</Text>
				<Text style={infoValue}>{nextStep}</Text>
			</View> : null}
			{assignedTo ? <View style={infoLine}>
				<Text style={infoTitle}>{`${i18next.t("opportunities:AssignedTo")}:`}</Text>				
				<Text style={infoValue}>{typeof assignedTo === "string" ? assignedTo : assignedTo.firstName ? `${assignedTo.firstName} ${assignedTo.lastName}` : assignedTo.username}</Text>
			</View> : null}
		</View>
	</View>

PDFPriorityStatusBlock.propTypes = {
	priority: PropTypes.string,
	status: PropTypes.string,
	nextStep: PropTypes.string,
	assignedTo: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		username: PropTypes.string
	})
}

export default PDFPriorityStatusBlock
