import { ExpandIconStyled } from "./styles"
import React from "react"

export const COMPONENTS = {
	ExpandIcon: bag => {
		const { propNames, data, expanded } = bag

		return data?.[propNames?.children]
			? (
				<ExpandIconStyled
					className="icon icon-expand"
					expanded={expanded}
				/>
			)
			: null
	},
	Label: bag => {
		const { propNames, data } = bag

		return data?.[propNames.label]
	},
	IsExpandableNode: bag => {
		const { propNames, data } = bag
		return Boolean(Array.isArray(data?.[propNames?.children]) && data?.[propNames?.children].length)
	},
}

export const PROP_NAMES = {
	id: "id",
	label: "label",
	children: "children"
}

export default {
	COMPONENTS,
	PROP_NAMES
}
