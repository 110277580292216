import React, { useState } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import Selectrix from "src/denali-ui/components/Selectrix"
import "./select-service-provider-buildings.scss"
import { LIST_PERMISSIONS_BY_APP_ID } from "../../queries"
import NewStyles from "../../../../NewStyles"
import { useEffect } from "react"

export function SelectPermissions({ title, initialValues, description, buttonTitle, onButtonClick, sideText, graphQLInputs }) {

	const [selectedOptions, setSelectedOptions] = useState(initialValues)
	const getQueryResponseMap = (values) => (response) => response.map((res) => ({
		value: res.id,
		children: res.name,
		selected: values.some(e => e.id === res.id),
		...res
	}))


	useEffect(() => {
		setSelectedOptions(initialValues.map(i => ({...i, value: i.id})))
	}, [initialValues])

	return <div className="contractor-company-building-wrapper">
		<legend className="contractor-company-building-title">{title}</legend>
		<div className="contractor-company-building-block">		
			<div className="contractor-company-selectrix-box">
				{
					<NewStyles>
						<Selectrix 
						onChange={(e, child) => {
							const selectedOptionsNew = [...selectedOptions]
							const index = selectedOptionsNew.findIndex(e => e.id === child.props.value)
							if (index === -1) {
								selectedOptionsNew.push({id: child.props.value, name: child.props.children, ...child.props})
							} else {
								selectedOptionsNew.splice(index, 1)
							}							
							onButtonClick(selectedOptionsNew)
							return selectedOptionsNew
						  }}
						query={LIST_PERMISSIONS_BY_APP_ID}
						graphQLInputs={graphQLInputs}
						fullWidth={true}
						multiple={true}
						value={selectedOptions.map(e => e.id)}
						allowCheckAll={false}
						getQueryResponseMap={getQueryResponseMap(selectedOptions)}
						sort={true}
						placeholder={"Find a Permission..."}
						searchPlaceHolder="Find Permission..."
					/>
					</NewStyles>
				}
				<p className="ccb-description">{description}</p>
			</div>
			<div className="ccd-actions">
				<p className="ccd-create-new">
						{sideText}
				</p>
			</div>
		</div>
	</div>
}
SelectPermissions.propTypes = {
	buildings: PropTypes.array,
	description: PropTypes.string.isRequired,
	buttonTitle: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
}

export default SelectPermissions
