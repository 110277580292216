import React, { useRef, useEffect } from 'react'
import { array, func, string, bool } from 'prop-types'
import { FixedSizeTree as Tree } from 'react-vtree'
import BodyItem from './body-item'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { BuildingNavigationBodyItem } from '../style'
const BN_ROW_HEIGHT = 40
const BN_BODY_ITEMS_HEIGHT = 12 * BN_ROW_HEIGHT
// TODO: Constant for the responsiveness
// const BN_HEADER_HEIGHT = 40
// const BN_SORT_BY_HEIGHT = 40
// const BN_FILTER_HEIGHT = 32
// const BN_PAGER_HEIGHT = 61
// const BN_SCROLL_ANIMATION_DURATION = 400

const TreeView = (props) => {
  const treeRef = useRef()
  const {
    items = [],
    loading,
    onClick,
    searchText,
    allowDisabledLocations
  } = props
  useEffect(() => {
    if (treeRef.current) {
      treeRef.current.recomputeTree({
        refreshNodes: true,
        useDefaultOpenness: true
      })
    }
  }, [searchText])

  function* treeWalker() {
    const stack = []
    items.forEach((d, index) => {
      stack.push({
        nestingLevel: 0,
        node: d,
        index
      })
    })
    while (stack.length !== 0) {
      const { node, nestingLevel, index } = stack.pop()
      const { id, buildings = [], ...rest } = node
      const isOpened = yield {
        defaultHeight: BN_ROW_HEIGHT, // mandatory
        id: id?.toString(), // mandatory
        isLeaf: buildings?.length === 0,
        isOpenByDefault: node.defaultExpand, // mandatory
        nestingLevel,
        item: {
          ...rest,
          buildings: buildings,
          id
        },
        onClick,
        index,
        searchText
      }

      if (buildings.length !== 0 && isOpened) {
        for (let i = buildings.length - 1; i >= 0; i--) {
          stack.push({
            nestingLevel: nestingLevel + 1,
            node: buildings[i],
            index
          })
        }
      }
    }
  }

  // TODO: Need to work on this
  // const calculatedHeight = () => {
  // 	const totalItems = items.filter(({ defaultExpand = false }) => defaultExpand === true).flatMap(({ nested = [], ...rest }) => [...nested, rest])
  // 	const totalHeight = (totalItems.length ? totalItems.length : items.length) * BN_ROW_HEIGHT
  // 	const calculatedHeight = isMobile
  // 		? window.innerHeight - BN_HEADER_HEIGHT - BN_SORT_BY_HEIGHT - BN_FILTER_HEIGHT - BN_PAGER_HEIGHT
  // 		: BN_BODY_ITEMS_HEIGHT
  // 	return (totalHeight < calculatedHeight) ? totalHeight : calculatedHeight
  // }

  if (loading) {
    return <Spinner />
  }

  return (
    <BuildingNavigationBodyItem
      className="bn-body-items"
      allowDisabledLocations={allowDisabledLocations}
    >
      <Tree
        ref={treeRef}
        treeWalker={treeWalker}
        itemSize={BN_ROW_HEIGHT}
        height={items.length ? BN_BODY_ITEMS_HEIGHT : 0}
        width="100%"
      >
        {BodyItem}
      </Tree>
    </BuildingNavigationBodyItem>
  )
}

TreeView.defaultProps = {
  items: [],
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  searchText: ''
}

TreeView.propTypes = {
  items: array,
  loading: bool,
  onClick: func,
  searchText: string
}

export default TreeView
