import { useEffect, useState } from 'react'
import { isFunction } from './validators'

const ownerDocument = (node) => (node && node.ownerDocument) || document

function nextItem(list, item, disableItemLoop) {
  if (list === item) {
    return list.firstChild
  }
  if (item && item.nextElementSibling) {
    return item.nextElementSibling
  }
  return disableItemLoop ? null : list.firstChild
}

function previousItem(list, item, disableItemLoop) {
  if (list === item) {
    return disableItemLoop ? list.firstChild : list.lastChild
  }
  if (item && item.previousElementSibling) {
    return item.previousElementSibling
  }
  return disableItemLoop ? null : list.lastChild
}

function initMoveFocus(skipOptionCheckFn) {
  const defaultSkipOptionCheckFn = (nextEl) => {
    return nextEl.disabled || nextEl.getAttribute('aria-disabled') === 'true'
  }

  const disableValidator = isFunction(skipOptionCheckFn)
    ? skipOptionCheckFn
    : defaultSkipOptionCheckFn

  return function moveFocus(
    list,
    currentFocus,
    disableItemLoop,
    disableSkipItem,
    traversalFunction
  ) {
    let wrappedOnce = false
    let nextFocus = traversalFunction(
      list,
      currentFocus,
      currentFocus ? disableItemLoop : false
    )

    while (nextFocus) {
      // Prevent infinite loop.
      if (nextFocus === list.firstChild) {
        if (wrappedOnce) {
          return false
        }
        wrappedOnce = true
      }

      const nextFocusDisabled = disableSkipItem
        ? false
        : disableValidator(nextFocus)

      if (!nextFocus.hasAttribute('tabindex') || nextFocusDisabled) {
        // Move to the next element.
        nextFocus = traversalFunction(list, nextFocus, disableItemLoop)
      } else {
        nextFocus.focus()
        return true
      }
    }
    return false
  }
}

function useListNavigation(props) {
  const {
    autoFocusFirstOption = true,
    disableItemLoop = true,
    disableSkipItem = false,
    onKeyDown,
    skipItemValidator
  } = props

  const moveFocus = initMoveFocus(skipItemValidator)

  const [listContainerRef, setListContainerRef] = useState(null)

  const handleKeyDown = (event) => {
    const list = listContainerRef

    if (!list) {
      event.preventDefault()
      return
    }

    const key = event.key

    const currentFocus = ownerDocument(list).activeElement

    if (key === 'ArrowDown') {
      // Prevent scroll of the page
      event.preventDefault()
      moveFocus(list, currentFocus, disableItemLoop, disableSkipItem, nextItem)
    } else if (key === 'ArrowUp') {
      event.preventDefault()
      moveFocus(
        list,
        currentFocus,
        disableItemLoop,
        disableSkipItem,
        previousItem
      )
    } else if (key === 'Home') {
      event.preventDefault()
      moveFocus(list, null, disableItemLoop, disableSkipItem, nextItem)
    } else if (key === 'End') {
      event.preventDefault()
      moveFocus(list, null, disableItemLoop, disableSkipItem, previousItem)
    }

    if (onKeyDown) {
      onKeyDown(event)
    }
  }

  useEffect(() => {
    if (autoFocusFirstOption && listContainerRef) {
      // lets move focus to first option
      listContainerRef.focus()
      handleKeyDown(new KeyboardEvent('keydown', { key: 'ArrowDown' }))
    }
  }, [autoFocusFirstOption, listContainerRef])

  return [setListContainerRef, handleKeyDown]
}

export default useListNavigation
