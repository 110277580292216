export const dark = {
  background: '{colors.neutral.80}',
  foreground: '{colors.white}',
  accent: '{colors.neutral.60}',
  activeBackground: '{colors.neutral.70}',
  dividerColor: '{colors.neutral.60}',
  hint: '{colors.neutral.40}',
  uiTrack: '{colors.gradients.pink.60}'
};

export const light = dark;