import styled from 'styled-components/macro'
import colors from 'src/components/legacy/common/colors.json'

export const HyperLinkCell = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`


export const SerialWarningComponent = styled.div`
display: flex;
align-items: center;
font-weight: 700;
color: ${colors.RED};`

export const TableWrapper = styled.div`
  .table-wrapper {
    /* height: calc(100vh - 255px);
        overflow: auto; */
    table {
      thead {
        /* position: sticky;
                top: 0;
                z-index: 1; */
        tr {
          th {
            &:first-child {
              width: 250px;
            }
          }
        }
      }
      tbody {
        tr {
          #no-records-row {
            text-align: center;
          }
        }
      }
    }
  }
`

export const NoLocation = styled.div`
  padding-left: 23px;
`
