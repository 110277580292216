import { useState, useCallback, useEffect } from 'react'
import MultiTabForm from '../MultiTabTorm'
import { TabsWrapper } from '../../styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import { WidgetOverview, WidgetOverviewSchema, WidgetBuildingSchema } from '../WidgetOverview'
import WidgetPreview from '../preview/WidgetPreview'
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../graphql'
import { useMutation } from 'src/hooks/APIHooks'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import {
  CELSIUS,
  MODAL_TYPE,
  WIDGET_TABS_NAME,
  getWeatherWidgetUpsertMutationInputs,
  widgetLoctionDefaultValue
} from '../../helpers'
import { ACTIONS } from 'src/constants/actions'
import { useTranslation } from 'react-i18next'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate from 'src/common/translations'

const tabsConfig = (errors) => [
  {
    title: WIDGET_TABS_NAME.OVERVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.OVERVIEW,
    formikErrors: errors,
    validationSchema: WidgetOverviewSchema,
    component: <WidgetOverview />
  },
  {
    title: WIDGET_TABS_NAME.PREVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.PREVIEW,
    component: <WidgetPreview />
  }
]

const WeatherWidgetForm = ({ setWidgetFormik }) => {
  const [t] = useTranslation()
  const {
    widgetBuilding,
    setOpenWidgetPicker,
    buildingId,
    organizationId,
    userInfo,
    setReloadEvents,
    setIsLoading,
    mode,
    widgetDetails,
    setWidgetDetails,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setModalType,
    formikErrors,
    setIsSubmitted,
    setIsFormValidationAlone,
    isFormValidationAlone,
    setWidgetId,
    widgetSalesOfficeId,
    isFooterChecked
  } = useWidgetsContext()
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: [ACTIONS.ADD,ACTIONS.COPY].includes(mode) ? CREATE_WIDGET : UPDATE_WIDGET,
    refreshTokenBuildingVariable: "input.buildingId",
    onError: (error) => {
      setError(error)
      setIsLoading(false)
    },
    onSuccess: (success) => {
      if([ACTIONS.ADD].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Create", type: "Weather"})
      }
      else if([ACTIONS.COPY].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Copy", type: "Weather"})
      }
      else{
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Edit", type: "Weather"})
      }
    }
  })

  const submitForm = async (values) => {
    if (!widgetBuilding?.id || isFormValidationAlone) return
    setIsLoading(true)
    const inputs = getWeatherWidgetUpsertMutationInputs(
      values,
      buildingId,
      organizationId,
      widgetSalesOfficeId,
      userInfo,
      widgetBuilding?.id,
      mode
    )
    await upsertWidgetMutation({ input: inputs }).then((response) => {
      const widgetId =
      [ACTIONS.ADD,ACTIONS.COPY].includes(mode)
          ? response.data?.createWidget?.id
          : response.data?.updateWidget?.id
      if (widgetId) {
        setIsLoading(false)
        setWidgetDetails(inputs)
        setOpenWidgetPicker(false)
        setReloadEvents(true)
        setModalType(MODAL_TYPE.SUCCESS)
        setSelectedWidget(null)
        setIsOpenCreateWidget(isFooterChecked ?? false)
        setWidgetId(widgetId)
      }
    })
  }
  const [activeTab, setActiveTab] = useState(0)
  const onSelectTab = useCallback((selectedTab: any, selectedTitle: any) => {
    setActiveTab(selectedTab)
    if (selectedTitle === WIDGET_TABS_NAME.PREVIEW){
      setIsFormValidationAlone(true)
      setIsSubmitted(false)
    }
  }, [])
  const copyof = translate("Copy of")
  useEffect(() => {
    switch (mode) {
      case ACTIONS.ADD:
        setFormValues({
          id: '',
          name: '',
          description: '',
          link: '',
          locationDetails: widgetLoctionDefaultValue(t),
          isNewWindow: true,
          metricConversion: true,
          widgetBuilding:''
        })
        break
      case ACTIONS.EDIT:
      case ACTIONS.COPY:
        setFormValues({
          id: mode === ACTIONS.EDIT ? widgetDetails?.id : '',
          name: mode === ACTIONS.EDIT ? widgetDetails?.name : `${copyof} ${widgetDetails?.name}`,
          description:  widgetDetails?.description ?? '',
          link: widgetDetails?.widgetLink,
          locationDetails: widgetLoctionDefaultValue(t),
          isNewWindow: widgetDetails?.isNewWindow,
          metricConversion: widgetDetails?.weatherWidget?.metricConversion === CELSIUS ? false : true,
          widgetBuilding: widgetDetails?.widgetBuildingId
        })
        break
    }
  }, [mode, copyof])

  return (
    <MultiTabForm
      initialValues={formValues}
      onSubmit={submitForm}
      schemas={[WidgetOverviewSchema,WidgetBuildingSchema]}
      setWidgetFormik={setWidgetFormik}
    >
      <TabsWrapper>
        <Tabs
          defaultActiveTab={activeTab}
          activeTab={activeTab}
          onSelect={onSelectTab}
        >
          {tabsConfig(formikErrors)?.map((item: any, key: number) => {
            return (
              <Tab
                title={item.title}
                disabled={item.disabled}
                key={key}
                formikErrors={item?.formikErrors}
                validationSchema={item?.validationSchema}
              >
                {item.component}
              </Tab>
            )
          })}
        </Tabs>
      </TabsWrapper>
      <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
    </MultiTabForm>
  )
}

export default WeatherWidgetForm
