// FILE CONTAINS STATIC APPLICABILITY CONFIG UNTIL THIS CONFIG IS PROVIDED BY API
// TODO : [REMOVE FILE] - Once the config is available on API response

// Below are scenario based config and applicable to `ChillerCompOilTempHigh` only
export const staticApplicabilityCheckConfg = {
  ChillerCompOilTempHigh: {
    And: {
      CompressorType: [
        {
          mandatoryConditional: 'mandatory',
          operator: {
            contains: ['Centrifugal']
          },
          depsCheck: {
            And: {
              CondenserType: {
                mandatoryConditional: 'mandatory',
                operator: {
                  contains: ['Water Cooled']
                }
              }
            }
          }
        },
        {
          mandatoryConditional: 'mandatory',
          operator: {
            contains: ['Helical Rotary']
          },
          depsCheck: {
            And: {
              CondenserType: {
                mandatoryConditional: 'mandatory',
                operator: {
                  contains: ['Air Cooled']
                }
              },
              CircuitCount: {
                mandatoryConditional: 'mandatory',
                operator: {
                  eq: 2
                }
              }
            }
          }
        }
      ]
    }
  }
}
