import { isNumeric } from '../helper'
import './styles.scss'

const ToolTip = ({ tooltip, isGroupBar = false }) => {
  const data = tooltip?.item ?? null
  const dataValue = (d) => (isNumeric(d) ? Number(d)?.toFixed(2) : d)
  const isPlot = data?.isPlot ?? false
  const isShowSingleBarTooltip = data?.isSingleBarTooltip ?? false
  return (
    <>
      {!isGroupBar || isPlot || isShowSingleBarTooltip ? (
        <div
          className="base_tooltip"
          style={{ top: tooltip.y, left: tooltip.x }}
        >
          <span className="base_tooltip__title">
            {data?.displayName ? data?.displayName : data?.name}
          </span>
          <hr />
          <div className="div_flex">
            <div
              className="color-item"
              style={{ backgroundColor: data?.color }}
            ></div>
            {data?.chartType === 'ECI Estimated BenchMark' ||
            data?.chartType === 'Energy Cost Index' ? (
              <div>
                {`$${dataValue(data?.value)}`}
                {data?.measurement && ` $(${data?.measurement})`}
              </div>
            ) : (
              <div>
                {dataValue(data?.value)}
                {data?.measurement && ` (${data?.measurement})`}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="base_tooltip"
          style={{ top: tooltip.y, left: tooltip.x }}
        >
          <span className="base_tooltip__title">
            {data?.displayName ? data?.displayName : data?.name}
          </span>
          <hr />
          {data?.values
            ?.filter((f) => !f?.isSkip ?? false)
            .map((element, index) => {
              return (
                <div key={index} className="grid-container">
                  <div className="grid-left-item">
                    <div className="div_flex">
                      <div
                        className="color-item"
                        style={{ backgroundColor: element?.color }}
                      ></div>
                      <div>
                        {`${element?.name ? `${element?.name}: ` : ''}`}
                      </div>
                    </div>
                  </div>
                  <div className="grid-right-item">
                    <div>
                      <span className="value">{`${dataValue(
                        element?.value
                      )} `}</span>
                      <span className="value">
                        {data?.measurement && ` (${data?.measurement})`}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      )}
    </>
  )
}

export default ToolTip