import TraneTheme from 'src/denali-ui/components/ThemeProvider'
import {
  GlobalStyle,
  NewGlobalStyles
} from 'src/components/legacy/components/global-reset/global-reset'

export const NewStyles = (props) => {
  return (
    <TraneTheme>
      <GlobalStyle />
      <NewGlobalStyles />
      {props.children}
    </TraneTheme>
  )
}

export default NewStyles
