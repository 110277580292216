import { TargetKeyType, VALIDATOR_RULE, WHEN_OPERATOR } from './constants'
import {
  EquipmentPropertyAndCharacteristics,
  PropertyMappingDetails
} from './equipmentPropertiesAndCharacteristics'
import { getConditionsForStatusCalc } from './helper'
import { PropertyRuleModel } from './model'
import type { Multiple_Rule } from './type'

interface PropertyRules {
  [k: string]: PropertyRule
}

/**
 * This class is responsible for initialzing values to the model
 *
 * @class PropertyRule
 * @extends {PropertyRuleModel}
 */
class PropertyRule extends PropertyRuleModel {
  constructor(targetKey, ruleDefinition, isDependency = false) {
    super()
    this.#init(targetKey, ruleDefinition, isDependency)
  }

  #init(targetKey, ruleDefinition, isDependency) {
    const { opt, cst, nps, cT, eq, nEq, opr, isApply, type } = ruleDefinition

    // Assign targetkey of the property
    this.targetKey = targetKey

    // Assign type of the property (default  TargetKeyType.PROPERTIES )
    this.targetKeyType =
      type === 'ch' ? TargetKeyType.CHARACTERISTICS : TargetKeyType.PROPERTIES

    // Assign equipment type
    this.equipment = cT ?? 'Active'

    // It may available in some of the rules to apply rules for calculated properties
    this.isApply = Boolean(isApply ?? false)

    // Assign the status of rule whether it's defined
    this.isOptionalRuleDefined = this.#isRuleDefined(opt)

    // Assign the status of rule whether it's defined
    this.isConstantRuleDefined = this.#isRuleDefined(cst)

    // Assign the status of rule whether it's defined
    this.isNoPropertySelectedRuleDefined = this.#isRuleDefined(nps)

    // Assign the optional rule if defined
    this.optionalRule = this.isOptionalRuleDefined
      ? this.#resolveDependency(opt)
      : null

    // Assign the constant rule if defined
    this.constantRule = this.isConstantRuleDefined
      ? this.#resolveDependency(cst)
      : null

    // Assign NPS rule if defined
    this.noPropertySelectedRule = this.isNoPropertySelectedRuleDefined
      ? this.#resolveDependency(nps)
      : null

    // Valid if either of the rules is defined
    this.hasAnyValidRule =
      this.isOptionalRuleDefined ||
      this.isConstantRuleDefined ||
      this.isNoPropertySelectedRuleDefined

    // Assign the dependent status whether the property is a dependent of any property
    this.isDependent =
      this.#isObject(opt, true) ||
      this.#isObject(cst, true) ||
      this.#isObject(nps, true)

    this.hasOptionalDepedendency =
      this.#isObject(opt, true) && this.#isRuleDefined(opt)

    this.hasConstantDepedendency =
      this.#isObject(cst, true) && this.#isRuleDefined(cst)

    this.hasNoPropertySelectedDepedendency =
      this.#isObject(nps, true) && this.#isRuleDefined(nps)

    // Assign the depenedency status whether the property is a dependency
    this.isDependency = isDependency

    this.whenOperator = opr ?? WHEN_OPERATOR.OR

    this.eq = eq ?? null

    this.nEq = nEq ?? null

    // Store the source config
    this.sourceDefinition = { ...ruleDefinition }
  }

  #resolveDependency(rule) {
    if (!this.#isObject(rule)) {
      return rule
    }
    const { wh = {}, opr, eq, nEq } = rule

    return Object.keys(wh).reduce((av, targetKey) => {
      const tRule = wh[targetKey]

      if (!av[targetKey] && tRule) {
        av[targetKey] = new PropertyRule(
          // tKey
          targetKey,
          // tKey data
          {
            ...tRule,
            opr,
            eq,
            nEq
          },
          // set as dependency
          true
        )
      }
      return av
    }, {})
  }
  /**
   * Strictly checks the rule is defined or not
   *
   * i.e Rules contains empty object, it returns false
   *
   * @param rule
   * @returns
   */
  #isRuleDefined(rule): boolean {
    rule = rule ?? false

    // if undefined | null
    if (rule === false) return false
    // if object
    else if (this.#isObject(rule, true)) {
      // if rules.wh is object and whether the dependencies are defined, if not returns false
      return this.#isObject(rule?.wh, true)
    } else return rule !== ''
  }

  #isObject(value: any, checkIsNotEmpty?: boolean): boolean {
    const isObject = Object.prototype.toString.call(value) === '[object Object]'

    if (checkIsNotEmpty && isObject) {
      return !!Object.keys(value).length
    }

    return isObject
  }
}

/**
 * This class is reponsible for extracting all the properties with rules from the config and creates model for each of them
 *
 * @class NormalizeRules
 */
class NormalizeRules {
  normalize(
    rules,
    extractor,
    log = (key: string, value: any) => null
  ): PropertyRules {
    const isChiller = Array.isArray(rules)
    const relatedKeys = getConditionsForStatusCalc(rules.cond ?? [], extractor)

    // For Debugging purpose
    log('validataionRulesMappedConditionsKey', relatedKeys)

    const relatedRules = this.#pickRules(relatedKeys, rules, isChiller)

    const flattenedRules = this.#flatRules(relatedRules)
    return this.#initPropertyRuleModel(flattenedRules)
  }

  #pickRules(releatedKeys = [], rules, isChiller) {
    if (isChiller) {
      return (rules || []).reduce(
        (av, targetKeyWithRules) => {
          // targetKeyWithRules should be string
          if (typeof targetKeyWithRules !== 'string') return av

          const [targetKey, cst, nps] = targetKeyWithRules.split('|')

          if (!av.all[targetKey]) {
            av.all[targetKey] = {
              cst: +cst,
              nps: +nps
            }
          }
          return av
        },
        { all: {} }
      )
    }

    return releatedKeys.reduce((av, key) => {
      const value = rules[key]

      if (value) {
        av[key] = { ...value }
      }

      return av
    }, {})
  }

  #flatRules(rules = {}) {
    return Object.keys(rules).reduce((av, key) => {
      const targetKeysWithRuleDefinition = rules[key] // key: all, cktInst, cmpInst, etc...

      if (rules[key]) {
        av = Object.assign(av, targetKeysWithRuleDefinition)
      }
      return av
    }, {})
  }

  #initPropertyRuleModel(flattenedRules = {}): PropertyRules {
    const models: PropertyRules = {}

    for (const targetKey in flattenedRules) {
      if (!models[targetKey]) {
        models[targetKey] = new PropertyRule(
          targetKey,
          flattenedRules[targetKey]
        )
      }
    }

    return models
  }
}

/**
 * This class handles below tasks
 *
 *  - Extracts and Creates rules model for each the property using `NormalizeRules` class
 *
 *  - Uses the `EquipmentPropertyAndCharacteristics` instance to get property / characteristcs details
 *
 *  - Validate the mapping status of a targetKey
 *
 * @export
 * @class RulesStatusChecker
 */
export class RulesStatusChecker {
  #rules: PropertyRules = {}
  #rulesNormalizerInstance: NormalizeRules
  #propsAndCharInstance: EquipmentPropertyAndCharacteristics

  constructor(
    equipmentPropsAndChar: EquipmentPropertyAndCharacteristics,
    rulesConfig,
    extractorFn,
    public log = (key: string, value: any) => null
  ) {
    this.#rulesNormalizerInstance = new NormalizeRules()
    this.#propsAndCharInstance = equipmentPropsAndChar

    this.#init(rulesConfig, extractorFn)
  }

  #init(rulesConfig, extractorFn) {
    this.#rules = this.#rulesNormalizerInstance.normalize(
      rulesConfig,
      extractorFn,
      this.log
    )

    // For Debugging purpose
    this.log('validataionRulesMapped', Object.assign({}, this.#rules))
  }

  getRulesDetailsByTargetKey(targetKey) {
    return this.#rules[targetKey]
  }

  getStatusByTargetkeyWithType(
    targetKey: string,
    targetKeyType: TargetKeyType
  ): boolean | null {
    if (!targetKey || !targetKeyType) return null

    // validate properties
    if (targetKeyType === TargetKeyType.PROPERTIES) {
      const propertyRule = this.#rules[targetKey]

      return propertyRule
        ? this.#validateRuleForProperty(propertyRule)
        : this.#isNoRulePropertyMappingValid(targetKey)
    }

    // validate grouped properties
    if (targetKeyType === TargetKeyType.GROUPS) {
      const propertiesDetails =
        this.#propsAndCharInstance.getActiveEquipmentPropertyDetailByTargetKey(
          targetKey
        )

      return propertiesDetails?.length
        ? this.#isAnyTrue(propertiesDetails, (propertyDetails) =>
            this.#isGroupPropertyMappingValid(propertyDetails)
          )
        : false
    }

    // validate characteristics
    if (targetKeyType === TargetKeyType.CHARACTERISTICS) {
      const propertyRule = this.#rules[targetKey]

      return propertyRule
        ? this.#validateRuleForProperty(propertyRule)
        : this.#isNoRuleCharacteristicMappingValid(targetKey)
    }

    return null
  }

  /**
   * Note
   *
   *  - As of now, rules are defined to Properties & characteristics only. This menthod handles  validation for property & characteristics only.
   *  - In future, if rules are introduced for grouped properties, Need to improve the below code to support it
   *
   * @param propertyWithRule
   * @returns
   */
  #validateRuleForProperty(propertyRule: PropertyRule): boolean {
    const {
      hasAnyValidRule,
      hasOptionalDepedendency,
      hasConstantDepedendency,
      hasNoPropertySelectedDepedendency,
      targetKey,
      targetKeyType,
      equipment
    } = propertyRule

    // Returns true as there is no valid rule check and condisering this propery as non-rule property
    if (!hasAnyValidRule) {
      return true // this.#isNoRulePropertyMappingValid(targetKey)
    }

    const dependencyPropertyRulesResult = {}

    if (hasOptionalDepedendency) {
      const optionalRule = this.#resolveDependency(propertyRule, 'optionalRule')

      // Don't store when the expected property details are not available. The reasons may include:
      // - The property may not be mapped to the equipment
      // - There may be a key name mismatch between the AT config and the equipment properties
      if (optionalRule !== 'DETAILS_NOT_FOUND')
        dependencyPropertyRulesResult['optionalRule'] = optionalRule
    }

    if (hasConstantDepedendency) {
      const constantRule = this.#resolveDependency(propertyRule, 'constantRule')

      // Returns false when the expected property details are not available. The reasons may include:
      // - The property may not be mapped to the equipment
      // - There may be a key name mismatch between the AT config and the equipment properties
      if (constantRule === 'DETAILS_NOT_FOUND') return false
      else dependencyPropertyRulesResult['constantRule'] = constantRule
    }

    if (hasNoPropertySelectedDepedendency) {
      const noPropertySelectedRule = this.#resolveDependency(
        propertyRule,
        'noPropertySelectedRule'
      )

      // Returns false when the expected property details are not available. The reasons may include:
      // - The property may not be mapped to the equipment
      // - There may be a key name mismatch between the AT config and the equipment properties
      if (noPropertySelectedRule === 'DETAILS_NOT_FOUND') return false
      else
        dependencyPropertyRulesResult['noPropertySelectedRule'] =
          noPropertySelectedRule
    }

    let propertiesDetails = []

    if (targetKeyType === TargetKeyType.PROPERTIES) {
      // As current property doen't have any dependencies, so apply rules against the property details and determine the status
      propertiesDetails =
        this.#propsAndCharInstance.getEquipmentPropertyDetailByEquipmentType(
          equipment,
          targetKey
        ) as any[]
    } else if (targetKeyType === TargetKeyType.CHARACTERISTICS) {
      // As current property doen't have any dependencies, so apply rules against the property details and determine the status
      propertiesDetails =
        this.#propsAndCharInstance.getEquipmentCharacteristicsDetailByEquipmentType(
          equipment,
          targetKey
        ) as any[]
    }

    // Returns false when the expected property details are not available. The reasons may include:
    // - The property may not be mapped to the equipment
    // - There may be a key name mismatch between the AT config and the equipment properties
    if (!propertiesDetails?.length) {
      return false
    }

    return this.#isAnyTrue(propertiesDetails, (propertyDetails) =>
      this.#isPropertyMappingValid(propertyDetails, {
        ...propertyRule,
        ...dependencyPropertyRulesResult
      } as PropertyRule)
    )
  }

  #resolveDependency(
    propertyRule: PropertyRule,
    ruleKey: 'optionalRule' | 'constantRule' | 'noPropertySelectedRule'
  ): null | boolean | 'DETAILS_NOT_FOUND' {
    const selectedRule = propertyRule[ruleKey] || {}

    // By default, the returnValue is null means no rule is checked yet.
    // If any rule is applied, the result will be assigned to `returnValue`
    let returnValue = null

    for (const Key in selectedRule as Multiple_Rule) {
      const currentPropertyRule = selectedRule[Key] as PropertyRule
      const {
        hasAnyValidRule,
        whenOperator,
        equipment,
        targetKey,
        targetKeyType,
        eq,
        nEq,
        hasOptionalDepedendency,
        hasConstantDepedendency,
        hasNoPropertySelectedDepedendency
      } = currentPropertyRule as PropertyRule

      // Move to next property when current property doesn't have any valid rule to check
      if (!hasAnyValidRule) {
        continue
      }

      const dependencyPropertyRulesResult = {}

      if (hasOptionalDepedendency) {
        const optionalRule = this.#resolveDependency(
          currentPropertyRule,
          'optionalRule'
        )

        // Don't store the optionalRule when the expected property details are not available. The reasons may include:
        // - The property may not be mapped to the equipment
        // - There may be a key name mismatch between the AT config and the equipment properties
        if (optionalRule !== 'DETAILS_NOT_FOUND')
          dependencyPropertyRulesResult['optionalRule'] = optionalRule
      }

      if (hasConstantDepedendency) {
        const constantRule = this.#resolveDependency(
          currentPropertyRule,
          'constantRule'
        )

        // Breaks the loop when the expected property details are not available. The reasons may include:
        // - The property may not be mapped to the equipment
        // - There may be a key name mismatch between the AT config and the equipment properties
        if (constantRule === 'DETAILS_NOT_FOUND') {
          returnValue = 'DETAILS_NOT_FOUND'
          break
        } else dependencyPropertyRulesResult['constantRule'] = constantRule
      }

      if (hasNoPropertySelectedDepedendency) {
        const noPropertySelectedRule = this.#resolveDependency(
          currentPropertyRule,
          'noPropertySelectedRule'
        )

        // Breaks the loop when the expected property details are not available. The reasons may include:
        // - The property may not be mapped to the equipment
        // - There may be a key name mismatch between the AT config and the equipment properties
        if (noPropertySelectedRule === 'DETAILS_NOT_FOUND') {
          returnValue = 'DETAILS_NOT_FOUND'
          break
        } else
          dependencyPropertyRulesResult['noPropertySelectedRule'] =
            noPropertySelectedRule
      }

      let propertiesDetails = []

      if (targetKeyType === TargetKeyType.PROPERTIES) {
        // As current property doen't have any dependencies, so apply rules against the property details and determine the status
        propertiesDetails =
          this.#propsAndCharInstance.getEquipmentPropertyDetailByEquipmentType(
            equipment,
            targetKey
          ) as any[]
      } else if (targetKeyType === TargetKeyType.CHARACTERISTICS) {
        // As current property doen't have any dependencies, so apply rules against the property details and determine the status
        propertiesDetails =
          this.#propsAndCharInstance.getEquipmentCharacteristicsDetailByEquipmentType(
            equipment,
            targetKey
          ) as any[]
      }

      // Returns true when the expected property details are available. The reasons may include for returning false:
      // - The property may not be mapped to the equipment
      // - There may be a key name mismatch between the AT config and the equipment properties
      if (propertiesDetails?.length) {
        const isValid = this.#isAnyTrue(propertiesDetails, (propertyDetails) =>
          this.#isPropertyMappingValid(propertyDetails, {
            ...currentPropertyRule,
            ...dependencyPropertyRulesResult
          } as PropertyRule)
        )

        returnValue = isValid
      } else {
        returnValue = 'DETAILS_NOT_FOUND'
      }

      // break after any rule becomes valid
      if (whenOperator === WHEN_OPERATOR.OR && returnValue === true) {
        // here, returnValue is true
        returnValue = eq
        break
      }

      // break after any rule becomes invalid
      if (whenOperator === WHEN_OPERATOR.AND) {
        if (returnValue === false) {
          // here returnValue is false
          returnValue = nEq
          break
        }

        // assign eq to return value when rules become valid.
        if (returnValue === true) {
          returnValue = eq
        }
      }
    }

    // Returns true when `returnValue === null` as there is no valid rule to check and condisering the propery as non-rule property
    return returnValue === null ? true : returnValue
  }

  #isNoRuleCharacteristicMappingValid(targetKey: string) {
    const propertiesDetails =
      this.#propsAndCharInstance.getActiveEquipmentCharacteristicsDetailByTargetKey(
        targetKey
      )

    return propertiesDetails?.length
      ? this.#isAnyTrue(propertiesDetails, (propertyDetails) =>
          this.#isCharacteristicsMappingValid(propertyDetails)
        )
      : false
  }

  #isCharacteristicsMappingValid(characteristics: any) {
    const { isValueMapped } = characteristics?.valueMappingDetails

    // For Characteristics, rules will not be applied
    // Returns the status based on the isValueMapped property
    return !!isValueMapped
  }

  #isGroupPropertyMappingValid(propertyDetails: any) {
    const {
      isConstantMapped,
      isNoPropertySelectedMapped,
      isSourceKeyMapped,
      isCalculatedValue
    } = propertyDetails?.valueMappingDetails

    // Returns true when the property is a calculated property
    if (isCalculatedValue) {
      return true
    }

    // For Grouped properties, rules will not be applied
    // Returns the status based on the mapped value

    //  when there is no rule defined in the API response, a property is neither allowed to set constant nor NPS.
    if (isConstantMapped) return false
    else if (isNoPropertySelectedMapped) return false
    else if (isSourceKeyMapped) return true
    return false
  }

  /**
   * This memthod checks the status of a property that doesn't have any rule
   *
   * This means that when there is no rule defined in the API response, a property is neither allowed to set constant nor NPS.
   *
   * @param targetKey
   * @returns
   */
  #isNoRulePropertyMappingValid(targetKey: string) {
    const propertiesDetails =
      this.#propsAndCharInstance.getActiveEquipmentPropertyDetailByTargetKey(
        targetKey
      )

    return propertiesDetails?.length
      ? this.#isAnyTrue(propertiesDetails, (propertyDetails) =>
          this.#isGroupPropertyMappingValid(propertyDetails)
        )
      : false
  }

  #isPropertyMappingValid(propertyDetails: any, propertyRule: PropertyRule) {
    const {
      isConstantMapped,
      isNoPropertySelectedMapped,
      isSourceKeyMapped,
      isCalculatedValue,
      value
    } = propertyDetails?.valueMappingDetails as PropertyMappingDetails

    const {
      isOptionalRuleDefined,
      optionalRule,
      isConstantRuleDefined,
      constantRule,
      isNoPropertySelectedRuleDefined,
      noPropertySelectedRule,
      isApply
    } = propertyRule

    // Returns true when the property is a calculated property and isApply is false
    // isApply flag to skip below condition and to execute next line of codes
    if (!isApply && isCalculatedValue) {
      return true
    }

    const isConstantValid = this.#isMappingValidByRule(
      isConstantRuleDefined,
      isConstantMapped,
      constantRule,
      value,
      isOptionalRuleDefined,
      optionalRule
    )

    if (isConstantValid !== null) return isConstantValid

    const isNoPropertySelectedValid = this.#isMappingValidByRule(
      isNoPropertySelectedRuleDefined,
      isNoPropertySelectedMapped,
      noPropertySelectedRule,
      value,
      isOptionalRuleDefined,
      optionalRule
    )

    if (isNoPropertySelectedValid !== null) return isNoPropertySelectedValid

    // Returns true when source key is mapped to a property
    if (isSourceKeyMapped) {
      return true
    }

    // Returns false when none of the above conditions met
    return false
  }

  #isMappingValidByRule(
    isRuleDefined,
    isValueMapped,
    rule,
    value,
    isOptionalRuleDefined = false,
    optionalRule = null
  ): boolean | null {
    const isString = typeof rule === 'string'

    // Returns true when an optional rule is defined (as 1) and either a value is not mapped to a property/characteristic or a value is mapped without any rules
    if (
      isOptionalRuleDefined &&
      optionalRule === VALIDATOR_RULE.OK &&
      (!isValueMapped || !isRuleDefined)
    )
      return true

    // Returns below values based on the defined condition
    if (isRuleDefined && isValueMapped) {
      // false - rule [expected value from API] and value is not matched
      if (isString && rule !== value) return false
      // false - rule [NOT OK | N/A] and value is mapped
      else if (!isString && rule !== VALIDATOR_RULE.OK) return false
      // true - rule [OK] and value is mapped
      else return true
    }

    return null
  }

  /**
   * Method to check properties that returns true if any meets the rules
   *
   * @param propertiesDetails
   * @param callbackFn
   * @returns
   */
  #isAnyTrue(propertiesDetails: any[], callbackFn: any): boolean {
    return propertiesDetails.some((propertyDetails) =>
      callbackFn(propertyDetails)
    )
  }
}
