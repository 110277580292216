import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { RectClip, clipUUID } from '../Clip'

function Svg(props: React.PropsWithChildren<any>, ref: any) {
  const uuid = useMemo(() => clipUUID('svg'), [])
  const refSvg = useRef(null)

  useImperativeHandle(ref, () => refSvg.current, [])

  return (
    <svg
      ref={refSvg}
      width="100%"
      height="100%"
      {...props}
      clipPath={`url(#clip-${uuid})`}
    >
      {props.children}
      <RectClip x={0} y={0} uuid={'clip-' + uuid} parentRef={refSvg} />
    </svg>
  )
}

export default forwardRef(Svg)
