import { useState, useEffect } from "react"
import { VALUE_ASSUMPTION_DEFAULT, MODES, KPIAPINames } from "./helpers"
import { ValueAssumptionsEdit } from "./AssumptionValuesEdit"
import { ValueAssumptionsView } from "./AssumptionValuesView"
import { Container, Content } from 'src/components/layouts'
import Header from 'src/components/pageHeader/header'
import { AssumptionsDetailsParentContainer } from "./style"
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { TranslateComponent } from "src/common/translations"


const AddValueAssumptions = ({ formValues,
                                setFieldValue,
                                organizationName,
                                buildingNames,
                                setShowAssumptions, 
                                setAssumptionValuesToParent, 
                                assumptionValuesFromParent,
                                showLoader,
                                hanldeKpi }) => {

    const [mode, setMode] = useState(MODES.EDIT)
    const [assumptionValuesForThisComponent, setAssumptionValuesForThisComponent] = useState(assumptionValuesFromParent || {})

    const MAX_PAGE_WIDTH = 1220

    const width = window.innerWidth

    const formatAPIValuesForUI = () => {
        try {
            const defaultValuesFormat = VALUE_ASSUMPTION_DEFAULT()
            
            const modifiedKPI = defaultValuesFormat?.kpi?.map((kpiDetails)=> {
                if(kpiDetails?.custom === 1) {
                    kpiDetails.name = "custom1"
                } 
                if(kpiDetails?.custom === 2){
                    kpiDetails.name = "custom2"
                }
                const kpiAPINames = KPIAPINames[kpiDetails?.name]
                kpiDetails.high.value = assumptionValuesFromParent[kpiAPINames?.Values?.high]
                kpiDetails.medium.value = assumptionValuesFromParent[kpiAPINames?.Values?.medium]
                kpiDetails.low.value = assumptionValuesFromParent[kpiAPINames?.Values?.low]
                kpiDetails.high.timePeriod = assumptionValuesFromParent[kpiAPINames?.timePeriod?.high]
                kpiDetails.medium.timePeriod = assumptionValuesFromParent[kpiAPINames?.timePeriod?.medium]
                kpiDetails.low.timePeriod = assumptionValuesFromParent[kpiAPINames?.timePeriod?.low]
            })
            defaultValuesFormat.goalAmount = assumptionValuesFromParent?.goalAmount
            defaultValuesFormat.avoidedTruckRollAmount = assumptionValuesFromParent?.avoidedTruckRollAmount
            setAssumptionValuesForThisComponent({ ...defaultValuesFormat, ...modifiedKPI})
        } catch(error) {
            handleSetDefaultValueAssumption()
        }
    }

    useEffect(()=> {
        if(assumptionValuesFromParent === null || assumptionValuesFromParent?.goalAmount === undefined) {
            handleSetDefaultValueAssumption()
        } else {
            formatAPIValuesForUI()
        }
    },[])


    const formatFormValuesForAPI = (valueAssumptions) => {
        const values = {
            "goalAmount": valueAssumptions?.goalAmount,
            "avoidedTruckRollAmount": valueAssumptions?.avoidedTruckRollAmount
        }
        // Revisit this logic to simplify later
        valueAssumptions?.kpi.map((kpiDetails)=> {
            if(kpiDetails?.custom === 1) {
                kpiDetails.name = "custom1"
            } 
            if(kpiDetails?.custom === 2){
                kpiDetails.name = "custom2"
            }
            const kpiAPINames = KPIAPINames[kpiDetails?.name]
            values[kpiAPINames.Values.high] = kpiDetails?.high?.value
            values[kpiAPINames.Values.medium] = kpiDetails?.medium?.value
            values[kpiAPINames.Values.low] = kpiDetails?.low?.value
            values[kpiAPINames.timePeriod.high] = kpiDetails?.high?.timePeriod
            values[kpiAPINames.timePeriod.medium] = kpiDetails?.medium?.timePeriod
            values[kpiAPINames.timePeriod.low] = kpiDetails?.low?.timePeriod
        })
        return values
    }

    // This just saved in the state, and change the mode to VIEW
    const onAssumptionsSave = (valueAssumptions) => {
        changeModeTo(MODES.VIEW)
        setAssumptionValuesForThisComponent(valueAssumptions)
    }

    // Save assumptions to consultation
    const addAssumptionsToConsultation = () => {
        setAssumptionValuesToParent(formatFormValuesForAPI(assumptionValuesForThisComponent))
        setFieldValue("whatWeHaveSavedSelect","Key Metric Savings")
        setFieldValue("whatWeHaveDone","Action Tracking")
        hanldeKpi(assumptionValuesForThisComponent)
        setShowAssumptions(false)
    }

    const changeModeTo = (mode) => {
        setMode(mode)
    }

    const LabelBlank = ({
        labelText,
        value,
        className
    }) => (
            <div className={className}>
                <label>{labelText}</label>
                <span><strong>{value}</strong> </span>
            </div>
    )

    const formatOrganizationOrBuildings = (organizationName, buildings ) => {
        // return `${organizationName}, ${buildings.map(b => b.locationName).join(", ")}`
        return `${organizationName ? organizationName: ""}, ${buildings ? buildings : ""}`
    }

    // Delete sets the assumption values to null
    const deleteAssumptionValues = () => {
        setAssumptionValuesToParent(null)
        setShowAssumptions(false)
    }


    const handleSetDefaultValueAssumption = () => {
        const values = VALUE_ASSUMPTION_DEFAULT()
        setAssumptionValuesForThisComponent({...values})
    }

    return (
        <>
        {
            showLoader ? <Spinner/> : <AssumptionsDetailsParentContainer sColumns={12} mColumns={12} lColumns={12} xlColumns={12} width={width} maxWidth={`${MAX_PAGE_WIDTH}px`}>
            <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} width={width} border={"none"}>
                <div className="consultation-assumption-value-header">
                    <Header 
                    className="header-text"
                        hideToolBar={true}
                        pageTitle={<TranslateComponent>{`Value Assumptions and Goals for ${formValues?.consultationTitle}`}</TranslateComponent>}
                    >
                    </Header>
                    {mode === MODES.VIEW && <div className="toolbar-assumption-values">
                            <span className="icon icon-edit tooltip-container" onClick={() => changeModeTo(MODES.EDIT)}><div className="tooltip dark left-top">Edit Value Assumptions</div></span>
                            {/* eslint-disable-next-line no-console */}
                            <span className="icon icon-trash2 tooltip-container" onClick={deleteAssumptionValues}><div className="tooltip dark left-top">Delete Value Assumptions</div></span>
                            </div>}
                </div>
                
            </Content>
            <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} width={width} border={"none"} className="value-assumption-page">
                <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12} width={width} maxWidth={`${MAX_PAGE_WIDTH}px`} className="value-assumption-tbp-view-header">
                    <Content xlColumn={8} lColumn={7} mColumn={7} sColumn={12} border={"none"} width={width} className="created-info">
                        <LabelBlank className="created-info-item" labelText="Organization or Building Name:" 
                        value={formatOrganizationOrBuildings(organizationName, buildingNames)} />
                        <LabelBlank className="created-info-item" labelText="Date Range:" value={`${formValues?.rangeFrom} - ${formValues?.rangeTo}`} />
                        <LabelBlank className="created-info-item" labelText="Status:" value={formValues?.status} />
                    </Content>
                </Container>
                <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} width={width} border={"none"}>
                    {
                    mode === "edit" ? 
                    <ValueAssumptionsEdit 
                    valueAssumptions={assumptionValuesForThisComponent} 
                    onAssumptionsSave={onAssumptionsSave}
                    handleSetDefaultValueAssumption={handleSetDefaultValueAssumption}
                    setShowAssumtions={setShowAssumptions}
                    width={width}
                    /> : 
                    <ValueAssumptionsView 
                    valueAssumptions={assumptionValuesForThisComponent}
                    addAssumptionsToConsultation={addAssumptionsToConsultation}
                    setShowAssumtions={setShowAssumptions}
                    width={width} />
                }
            </Content>
            </Content>
            </AssumptionsDetailsParentContainer>
        }
        
            
        </>
    )
    
}


export default AddValueAssumptions