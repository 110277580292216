import React, {useEffect, useState} from 'react'
import { Content } from '../../../components/layouts'
import Table from 'src/components/Table/clientSideTable'
import { LIST_GROUPS } from '../graphql/queries'
import { useQuery } from 'src/hooks/APIHooks'
import { accessControlFunc } from 'src/components/accessControl'
import { useSelector } from 'react-redux'
import { selectUserAccess } from '../../../redux/slicers/appData'



import '../createUser/user-management.scss'
import { TranslateComponent } from '../../../common/translations'

export const BuildingsByNameTable = ({
  width,
  resource,
  resourceControl
}) => {
  const [rows, setRows] = useState([])

  const tableHeaders = [
    {
      title: <TranslateComponent>{`${resource} Name`}</TranslateComponent>,
      key: 'name'
    }
  ]

  const {
    data: data1,
    responseTime: responseTime1
  } = useQuery({
    query: LIST_GROUPS,
    variables: {filter: {resourceType: {eq: resource}}},
    errorPolicy: 'all',
    dataPath: `data.listGroups.items`
  })

  useEffect(() => {
    setRows(data1)
  }, [data1])

  const userAccess = useSelector(selectUserAccess)

  return (
    <Content
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      border="none"
      width={width}
    >
      <Table
        key={`${resource}ByName`}
        header={tableHeaders}
        rows={rows || []}
        loadTime={responseTime1}
        search={true}
        searchFields={['name']}
        rowCheckbox={d => {
          return name === "Application" ? true : accessControlFunc({id: [`tc.pages.user-admin.Application`, `tc.pages.user-admin.${d.name}`], userAccess})
      }}
        checkboxChecked={(e) =>  Boolean(resourceControl[0][resource]?.[e.name])}
        checkboxClick={(e) => {
          const newResources = {...resourceControl[0][resource]}
          Boolean(newResources[e.name]) ? delete newResources[e.name] : newResources[e.name] = {name: e.name, applications: {} }
          resourceControl[1]({...resourceControl[0], [resource]: newResources})
        }}
      />
    </Content>
  )
}

BuildingsByNameTable.propTypes = {}

export default BuildingsByNameTable
