import moment from 'moment'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'

export const setEndDateForEnergyCostPages = (activeRange) => {
  switch (activeRange) {
    case '1M':
      return {
        startDate: moment()
          .subtract(1, 'month')
          .startOf('month')
          .format(BACKEND_DATE_FORMAT),
        endDate: moment()
          .subtract(1, 'month')
          .endOf('month')
          .format(BACKEND_DATE_FORMAT)
      }
    case '3M':
      return {
        startDate: moment()
          .subtract(3, 'months')
          .startOf('month')
          .format(BACKEND_DATE_FORMAT),
        endDate: moment()
          .subtract(1, 'months')
          .endOf('month')
          .format(BACKEND_DATE_FORMAT)
      }
    case '6M':
      return {
        startDate: moment()
          .subtract(6, 'month')
          .startOf('month')
          .format(BACKEND_DATE_FORMAT),
        endDate: moment()
          .subtract(1, 'month')
          .endOf('month')
          .format(BACKEND_DATE_FORMAT)
      }
    case '1Y':
      return {
        startDate: moment()
          .subtract(1, 'year')
          .startOf('month')
          .format(BACKEND_DATE_FORMAT),
        endDate: moment()
          .subtract(1, 'month')
          .endOf('month')
          .format(BACKEND_DATE_FORMAT)
      }
    default:
      return {
        startDate: moment()
          .subtract(1, 'month')
          .startOf('month')
          .format(BACKEND_DATE_FORMAT),
        endDate: moment()
          .subtract(1, 'month')
          .endOf('month')
          .format(BACKEND_DATE_FORMAT)
      }
  }
}

export const handleAdjustRange = (maxDate, type, entityExist) => {
  switch (type) {
    case '1D':
      return {
        active: false,
        type: type,
        date: moment.utc(maxDate).startOf('day')
      }
    case '2D':
      return {
        active: false,
        type: type,
        date: moment
          .utc(maxDate)
          .subtract(2, 'day')
          .add(1, 'day')
          .startOf('day')
      }
    case '3D':
      return {
        active: false,
        type: type,
        date: moment
          .utc(maxDate)
          .subtract(3, 'day')
          .add(1, 'day')
          .startOf('day')
      }
    case '5D':
      return {
        active: false,
        type: type,
        date: moment
          .utc(maxDate)
          .subtract(5, 'day')
          .add(1, 'day')
          .startOf('day')
      }
    case '1W':
      return {
        active: false,
        type: type,
        date: moment
          .utc(maxDate)
          .subtract(1, 'week')
          .add(1, 'day')
          .startOf('day')
      }
    case '1M':
      return {
        active: false,
        type: type,
        date: entityExist 
        ? moment.utc(maxDate).subtract(1, 'month').add(1, 'day')
        : moment.utc(maxDate).subtract(1, 'month')
      }
    case '3M':
      return {
        active: false,
        type: type,
        date: entityExist
        ? moment.utc(maxDate).subtract(3, 'months').add(1, 'day')
        : moment.utc(maxDate).subtract(3, 'months')
      }
    case '6M':
      return {
        active: false,
        type: type,
        date: entityExist
        ? moment.utc(maxDate).subtract(6, 'months').add(1, 'day')
        : moment.utc(maxDate).subtract(6, 'months')
      }
    case 'YTD':
      return {
        active: false,
        type: type,
        date: moment.utc(maxDate).startOf('year')
      }
    case '1Y':
      return {
        active: false,
        type: type,
        date: entityExist
        ? moment.utc(maxDate).subtract(1, 'year').add(1, 'day')
        : moment.utc(maxDate).subtract(1, 'year')
      }
    case 'ALL':
      return {
        active: false,
        type: 'ALL',
        date: null
      }
    default:
      return {}
  }
}
