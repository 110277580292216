import { useEffect, useState } from 'react'
import Table from 'src/components/Table/clientSideTable'
import moment from 'moment'
import { TEXT_DATE_TIME_FORMAT } from 'src/components/legacy/common/time-helpers'
import translate from 'src/common/translations'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { CurvesListWrapper } from './styles'
import CharacteristicCurveHistoryDetails from './CharacteristicCurveHistoryDetails'


const CharacteristicCurveHistoryDetailsTable = ({ changeDetails }) => {
  const searchParams = getSearchParams()
  const buildingId = searchParams?.buildingId
  const organizationId = searchParams?.organizationId
  const [rows, setRows] = useState([])
  

  const optionsTranslated = {
    setting: translate('Setting'),
    previousValue: translate('Previous Value'),
    currentValue: translate('Value Changed To')
  }
  const name = translate('Name')
  const minValueXAxis = translate ('X Axis Range Min Value')
  const maxValueXAxis = translate ('X Axis Range Max Value')
  const minValueYAxis = translate ('Y Axis Range Min Value')
  const maxValueYAxis = translate ('Y Axis Range Max Value')
  const runAnalytic = translate ('Enable Automated Test')
  const points = translate ('Points')
  const xAxisTargetKey = translate ('X Axis')

  const CHANGE_PROPERTY_NAMES = {
    name: name,
    minValueXAxis: minValueXAxis,
    maxValueXAxis: maxValueXAxis,
    minValueYAxis: minValueYAxis,
    maxValueYAxis: maxValueYAxis,
    points: points,
    runAnalytic:runAnalytic,
    xAxisTargetKey: xAxisTargetKey
  }
  const framePointsChangeDisplay = (points) => {
    return (
      <>
        {points?.map((point, index) => (
          <div key={index}>{`X: ${point.X}, Y:${point.Y}`}</div>
        ))}
      </>
    )
  }

  const frameDisplayFormat = (setting, value) => {
    switch (setting) {
      case 'points':
        return framePointsChangeDisplay(value)
        break
      case 'runAnalytic':
        return value ? 'Yes' : 'No'
        break
      default:
        return value
    }
  }

  useEffect(() => {
    if (changeDetails && changeDetails.length > 0) {
      setRows(
        changeDetails
          ? changeDetails?.map((change) => {
              return {
                setting:
                  CHANGE_PROPERTY_NAMES[change.setting] ?? change.setting,
                previousValue: frameDisplayFormat(
                  change.setting,
                  change.previousValue
                ),
                currentValue: frameDisplayFormat(
                  change.setting,
                  change.currentValue
                )
              }
            })
          : []
      )
    } else {
      setRows([])
    }
  }, [changeDetails, name,points,minValueXAxis])

  const nameHeadings = [
    {
      title: optionsTranslated.setting,
      key: 'setting',
      maxWidth: '30%'
    },
    {
      title: optionsTranslated.previousValue,
      key: 'previousValue',
      maxWidth: '35%'
    },
    {
      title: optionsTranslated.currentValue,
      key: 'currentValue',
      maxWidth: '35%'
    }
  ]

  return (
    <CurvesListWrapper>
      <Table
        key={`CharacteristicCurvesTable-${organizationId} ${buildingId}`}
        rows={rows}
        header={nameHeadings}
        search={true}
        searchFields={['setting', 'previousValue']}
        rowControl={[]}
      />
    </CurvesListWrapper>
  )
}

export default CharacteristicCurveHistoryDetailsTable
