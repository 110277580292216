import styled from 'styled-components'
import colors from 'src/components/layouts/colors.json'
export const ActionButton = styled.button`
	margin-left: auto;
	margin-left:10px;
`

export const ElectricalDemandMainContent = styled.div`
width: 100%;
`
export const ElectricalDemandChartSection = styled.div`
width: 100%;
padding: 20px;
`

export const ToolBarWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 8px;
  }
`
export const CustomWrapper = styled.div`

padding-bottom: 10px;
margin-top:-30px;
 
`
export const ContentWrap = styled.div`
background: ${colors.WHITE};
    width: 100%;
    border-bottom: 2px solid rgb(102, 102, 102);
    min-height: 160px;
    .pd-equip{
      padding-left: 20px;
    }
 
`

export const ChartAreaWrapper = styled.div`
    padding: 0px 20px;
    background-color: ${colors.WHITE}
`

export const HeaderNoResultBlock = styled.div`
  min-height: 148px;
`

export const DataAvailableInfo = styled.div`
margin-left: 40px;
`
export const ChartHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  > div:nth-child(1) {
    width: calc(100% - 200px);
  }
`

export const NoDataMessage = styled.p`
  font-weight: 700;
  font-size: 14px;
  padding: 20px;
  border: 1px solid lightgray;
`