import { scaleSequential, piecewise } from 'd3'
import { is } from 'ramda'

const defaultColors = ['steelblue', 'steelblue']

export function aggregateByAxisValue({
  data = [],
  xScale,
  yScale,
  colorScale,
  width = 0,
  height = 0
}: HeatMapAggregator): AggregatedHeatMap {
  const isArray = Array.isArray(data)
  const length = isArray ? data.length : 0

  // if invalid data or no data, retuns default {}
  if (length === 0 || !xScale || !yScale) return {}

  const aggregate: AggregatedHeatMap = {}

  let timeout = 16

  // use simple loop with mininal code change
  for (let i = 0; i < length; i++) {
    const d = data[i]

    if (!d || d.length === 0) continue

    const xValue = d[0]
    const yValue = d[1]

    const x = xScale(xValue)

    const y = yScale(yValue)

    if (!aggregate[xValue]) {
      aggregate[xValue] = {
        key: '_heatMap_parent_' + xValue,
        value: [],
        timeout: (timeout += 4)
      }

      // Increase timeout on every 28 days
      if (i % 5 === 0) {
        timeout = 16
      }
    }

    // Check xy coords are avilable or not
    if (x !== undefined && y !== undefined) {
      const valueAt = d[2]
      const value = typeof valueAt === 'object' ? valueAt?.value : valueAt
      const color =
        typeof valueAt === 'object' ? valueAt?.color : colorScale(+value)

      aggregate[xValue].value.push({
        uuid: '_heatMap_child_' + i + '_x_' + x + '_y_' + y,
        x,
        y,
        width,
        height,
        value: value ?? 'NaN', // if value is either undefined or null, assign NaN as string to let the user know
        color: color === undefined || color === null ? '#ffffff' : color,
        nonPrimaryData: d.slice(3),
        data: [...d]
      } as HeatMapData)
    }
  }

  return aggregate
}

export function heatMapDataTransformer({
  xScale,
  xScaleType,
  yScale,
  yScaleType,
  colorScale,
  data = []
}: HeatMapDataTransformer) {
  if (
    xScaleType !== 'scaleBand' ||
    yScaleType !== 'scaleBand' ||
    xScale?.bandwidth === undefined ||
    yScale?.bandwidth === undefined
  ) {
    console.log(
      'Either of provided scales are not band scales. Please provide band scales.'
    )
  }

  const width = xScale.bandwidth()
  const height = yScale.bandwidth()

  return aggregateByAxisValue({
    data,
    xScale,
    yScale,
    colorScale,
    width,
    height
  })
}

export function getColorScale(
  colorScale,
  colors = defaultColors,
  min = 0,
  max = 1
) {
  if (is(Function, colorScale)) {
    return colorScale
  } else {
    return scaleSequential([min, max], piecewise(colors))
  }
}
