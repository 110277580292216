import PropTypes from 'prop-types'

import { FindingHeadLineWrapper } from '../../../styles'

export function FindingHeadlinesView({ name, headlines }) {
  return (
    <FindingHeadLineWrapper>
      <h3>{name}</h3>
      <div className="finding-headlines">
        {headlines.map(({ title, description }, key) => (
          <div key={key} className="finding-headline">
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </FindingHeadLineWrapper>
  )
}

FindingHeadlinesView.propTypes = {
  name: PropTypes.string.isRequired,
  headlines: PropTypes.array
}

FindingHeadlinesView.defaultProps = {
  headlines: []
}
