import SeriesRenderer from "./SeriesRenderer";
import AxisGridRenderer from "./AxisGridRenderer";
import { memo, useMemo } from "react";
import ToolTipRenderer from "./ToolTipRenderer";

const Chart = (props: any) => {
  const canvasIndex = useMemo(() => props?.canvasIndex, [props?.canvasIndex]);

  return (
    <>
      <AxisGridRenderer canvasIndex={canvasIndex} />
      <SeriesRenderer canvasIndex={canvasIndex} setTooltip={props?.setTooltip} />
      <ToolTipRenderer canvasIndex={canvasIndex} />
    </>
  );
};

export default memo(Chart);
