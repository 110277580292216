import OpportunityContainer from './OpportunityContainer'
import { OpportunityContextProvider } from './OpportunityContextProvider'

export const Opportunities = ({ action }) => {
  return (
    <OpportunityContextProvider>
      <OpportunityContainer action={action} />
    </OpportunityContextProvider>
  )
}
