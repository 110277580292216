
import IconSVG from 'src/components/Icon/index.js'
import colors from 'src/components/legacy/common/colors.json'
import { TagItem,TagText } from './style'

type TagValue = {
    title:string,
    children?:any,
    [x: string]: any;
}

type ITag = {
    removable?:boolean,
    onRemove?:any,
    value:TagValue

}


export const Tag = ({
    removable =  true,
    value,
    onRemove
}:ITag) => {
    return <TagItem key={`close-`}>
   {removable && <IconSVG
      onClick={() => {
        onRemove?.(value)
      }}
      value = {value.title}
      name="close-circle"
      color={colors.TBA_DARKER_GREY}
      margin="2px 4px 0px 0px"
      width="14px"
      height="14px"
    /> }
    <TagText>
      {value.children ||  value.title}
    </TagText>
  </TagItem>
}
