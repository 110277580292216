import React, { useEffect, useState } from 'react'

import { isBoolean, isEqual, isFunction } from '../../utils'
import {
  useFormControl,
  useConnectPropsWithContext
} from './formControlContext'
import formControlStateFn, { defaultFieldStates } from './formControlState'

function updateFormFieldState(inState) {
  const state = Array.isArray(inState) ? inState : []
  return [...defaultFieldStates, ...state]
}

// --------------------------------------------- form state handler hook ---------------------------------------------

function useFormFieldState(props) {
  // merge all props (from component) with context props
  const allProps = useConnectPropsWithContext(props)

  const {
    disabled,
    focused: focusedProp,
    onBlur: onBlurProp,
    onClick: onClickProp,
    onFocus: onFocusProp,
    states
  } = allProps

  const onClickElementRef = React.useRef()
  const [focused, setFocused] = useState(false)
  const formControlCtx = useFormControl()

  // if focuse is controlled by user
  useEffect(() => {
    if (isBoolean(focusedProp) && !isEqual(focusedProp, focused)) {
      setFocused(focusedProp)
    }
  }, [focusedProp])

  // compose form control state using formControlStateFn
  const formControlState = formControlStateFn({
    props: { ...allProps, focused },
    states: updateFormFieldState(states)
  })

  // create class based on form control state (disabled / error / focused / etc...)
  const formControlStateClass = formControlState

  // if input is disabled, trigger blur
  useEffect(() => {
    if (!formControlCtx && disabled && focused) {
      setFocused(false)
      if (onBlurProp) {
        onBlurProp()
      }
    }
  }, [formControlCtx, disabled, focused, onBlurProp])

  // handle focus
  const onFocus = (event) => {
    if (disabled) {
      event.stopPropagation()
      return
    }

    if (isFunction(onFocusProp)) {
      onFocusProp(event)
    } else {
      setFocused(true)
    }
  }

  // handle blur
  const onBlur = (event) => {
    if (isFunction(onBlurProp)) {
      onBlurProp(event)
    } else {
      setFocused(false)
    }
  }

  // handle foucs on click
  const onClick = (event) => {
    if (onClickElementRef.current && event.currentTarget === event.target) {
      onClickElementRef.current.focus()
    }

    if (isFunction(onClickProp)) {
      onClickProp(event)
    }
  }

  return {
    onClickElementRef,
    ...allProps,
    onFocus,
    onBlur,
    onClick,
    focused,
    formControlState,
    formControlStateClass
  }
}

export default useFormFieldState
