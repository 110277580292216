import { createContext, useContext, useState } from 'react'
import { IFinding } from './types'

import { IModalActionType } from 'src/constants'
const IssuesFindingsContext = createContext<any>(null)

export const IssuesFindingsContextProvider = ({ children }: any) => {
  const [mode, setMode] = useState(null)
  const [reloadEvents, setReloadEvents] = useState(false)
  const [isFindingAdding, setIsFindingAdding] = useState(false)
  const [modalType, setModalType] = useState<IModalActionType>('')
  const [finding, setFinding] = useState<IFinding>(null)
  return (
    <IssuesFindingsContext.Provider
      value={{
        mode,
        setMode,
        reloadEvents,
        setReloadEvents,
        isFindingAdding,
        setIsFindingAdding,
        setModalType,
        modalType,
        setFinding,
        finding
      }}
    >
      {children}
    </IssuesFindingsContext.Provider>
  )
}

export const useIssuesFindingsContext = () => useContext(IssuesFindingsContext)
