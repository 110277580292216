import styled from 'styled-components'
import { Button } from 'src/components/inputs/button'

export const PropertyMetaDataContainer = styled.div``

export const CreateEditModalContainer = styled.div`
  & .modal-text-wrapper .custom-label {
    padding: 0px !important;
  }
`

export const CreateButton = styled(Button)`
  margin-bottom: 10px;
  float: right !important;
`

export const ExtraInputStyles = styled.span`
  
  input {
    font-feature-settings: inherit;
    background: rgb(255, 255, 255);
    font-family: inherit;
    font-size: 16px;
    font-stretch: inherit;
    font-style: inherit;
    font-variant: inherit;
    margin-right: 10px;
    font-weight: inherit;
    height: auto;
    line-height: inherit;
    width: 300px;
  }
  p {
    top: 12px;
    right: 15px;
  }
`
