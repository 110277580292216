import Findings from 'src/models/findings'
import { ACTIONS } from 'src/constants'
import {
  DATE_FORMAT,
  BACKEND_DATE_FORMAT
} from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'
import _isArray from "lodash/isArray"

export const updateFinding = (finding, mode, userName, currentDate) => {
  const findingObj = new Findings()
  findingObj.findingId = finding.id
  findingObj.name =
    mode === ACTIONS.COPY ? 'Copy of ' + finding.title : finding.title
  findingObj.status = finding.status
  findingObj.priority = finding.priority
  findingObj.nextStep = finding.nextStep
  findingObj.userName = finding.userId
  findingObj.changeAuthor = false
  findingObj.assignedTo = { key: finding.assignedTo }
  findingObj.foundDuring = finding.foundDuring
  findingObj.opportunities = finding?.opportunities?.items?.reduce(
    (acc, cur) => {
      if (cur?.opportunity?.id) {
        const o = {
          issueOpportunityId: cur?.id,
          opportunityId: cur?.opportunity?.id,
          name: cur?.opportunity?.title
        }
        acc.push(o)
      }
      return acc
    },
    []
  )
  findingObj.createdBy = finding.createdBy
  findingObj.visibleToCustomer = finding.isVisible
  findingObj.organization.organizationId = finding.accountId
  findingObj.organization.organizationName =
    finding?.building?.accountName ?? ''
  findingObj.location.locationId = finding.buildingId
  findingObj.location.locationName = finding.building?.name ?? ''
  findingObj.tisObjects =
    finding.equ?.items?.map((eq) => {
      return {
        equipmentIssueId: eq?.id,
        tisObjectId: eq?.equipment?.id,
        tisObjectName: eq?.equipment?.name,
        tisObjectType: eq?.equipment?.type
      }
    }) ?? []
  const fileDetail = finding?.attachments?.items?.find(
    (a) => a?.category === FileUploadType.ADD_FILES
  )
  const settings = _cloneDeep(findingObj.settings)
  settings.file = !fileDetail?.id
    ? {}
    : {
        id: fileDetail?.id,
        name: fileDetail?.name,
        buildingId: fileDetail?.buildingId,
        description: fileDetail?.description,
        displayName: fileDetail?.displayName
      }

  settings.images = []
  settings.proposalDate = [ACTIONS.EDIT, ACTIONS.VIEW, ACTIONS.COPY].includes(
    mode
  )
    ? !finding.creationDate
      ? null
      : moment(finding.creationDate).format(DATE_FORMAT)
    : currentDate
  settings.proposedBy = [ACTIONS.EDIT, ACTIONS.VIEW, ACTIONS.COPY].includes(
    mode
  )
    ? finding.createdBy
      ? finding.createdBy
      : null
    : userName
  settings.targetDate = !finding.targetDate
    ? null
    : moment(finding.targetDate).format(DATE_FORMAT)
  settings.description = JSON.parse(finding.comment)
  settings.chartLink = finding.chartLink
  settings.descriptionLink = finding.linkDescription
  settings.kpi[0].value = finding.impactEnergyDisplay
  settings.kpi[0].priority = finding.impactEnergyDisplay
    ? finding.impactEnergy
    : settings.kpi[0].priority
  settings.kpi[0].savings = finding.impactEnergyDisplay
    ? finding.impactEnergyValue
    : settings.kpi[0].savings
  settings.kpi[0].timePeriod = finding.impactEnergyDisplay
    ? finding.impactEnergyValueType
    : settings.kpi[0].timePeriod
  settings.kpi[1].value = finding.impactPerformanceDisplay
  settings.kpi[1].priority = finding.impactPerformanceDisplay
    ? finding.impactPerformance
    : settings.kpi[1].priority
  settings.kpi[1].savings = finding.impactPerformanceDisplay
    ? finding.impactPerformanceValue
    : settings.kpi[1].savings
  settings.kpi[1].timePeriod = finding.impactPerformanceDisplay
    ? finding.impactPerformanceValueType
    : settings.kpi[1].timePeriod
  settings.kpi[2].value = finding.impactReliabilityDisplay
  settings.kpi[2].priority = finding.impactReliabilityDisplay
    ? finding.impactReliability
    : settings.kpi[2].priority
  settings.kpi[2].savings = finding.impactReliabilityDisplay
    ? finding.impactReliabilityValue
    : settings.kpi[2].savings
  settings.kpi[2].timePeriod = finding.impactReliabilityDisplay
    ? finding.impactReliabilityValueType
    : settings.kpi[2].timePeriod
  settings.kpi[3].value = finding.impactComfortDisplay
  settings.kpi[3].priority = finding.impactComfortDisplay
    ? finding.impactComfort
    : settings.kpi[3].priority
  settings.kpi[3].savings = finding.impactComfortDisplay
    ? finding.impactComfortValue
    : settings.kpi[3].savings
  settings.kpi[3].timePeriod = finding.impactComfortDisplay
    ? finding.impactComfortValueType
    : settings.kpi[3].timePeriod
  settings.kpi[4].value = finding.impactComplianceDisplay
  settings.kpi[4].priority = finding.impactComplianceDisplay
    ? finding.impactCompliance
    : settings.kpi[4].priority
  settings.kpi[4].savings = finding.impactComplianceDisplay
    ? finding.impactComplianceValue
    : settings.kpi[4].savings
  settings.kpi[4].timePeriod = finding.impactComplianceDisplay
    ? finding.impactComplianceValueType
    : settings.kpi[4].timePeriod
  settings.kpi[5].value = finding.impactCustom1Display
  settings.kpi[5].priority = finding.impactCustom1Display
    ? finding.impactCustom1
    : settings.kpi[5].priority
  settings.kpi[5].savings = finding.impactCustom1Display
    ? finding.impactCustom1Value
    : settings.kpi[5].savings
  settings.kpi[5].timePeriod = finding.impactCustom1Display
    ? finding.impactCustom1ValueType
    : settings.kpi[5].timePeriod
  settings.kpi[5].name = finding.impactCustom1Display
    ? finding.impactCustom1Name
    : ''
  settings.kpi[6].value = finding.impactCustom2Display
  settings.kpi[6].priority = finding.impactCustom2Display
    ? finding.impactCustom2
    : settings.kpi[6].priority
  settings.kpi[6].savings = finding.impactCustom2Display
    ? finding.impactCustom2Value
    : settings.kpi[6].savings
  settings.kpi[6].timePeriod = finding.impactCustom2Display
    ? finding.impactCustom2ValueType
    : settings.kpi[6].timePeriod
  settings.kpi[6].name = finding.impactCustom2Display
    ? finding.impactCustom2Name
    : ''
  findingObj.settings = _cloneDeep(settings)
  return findingObj
}

const getUserName = (userInfo) => {
  return `${userInfo?.lastName} ${userInfo?.firstName}`
}
export const FormatComment = (text) => {
  const formatComment = {"entityMap":{},"blocks":[{"depth":0,"data":{},"inlineStyleRanges":[],"text":`${text??''}`,"type":"unstyled","key":"dv1s7","entityRanges":[]}]}
  return JSON.stringify(formatComment) 
}

export const frameMutationInput = (values, userInfo, mode) => {
  const {
    name,
    priority,
    status,
    foundDuring,
    nextStep,
    location,
    organization,
    assignedTo,
    settings: {
      targetDate,
      kpi,
      chartLink,
      descriptionLink,
      description,
      proposalDate,
      proposedBy
    },
    visibleToCustomer
  } = values
  const input = {
    accountId: organization?.organizationId,
    buildingId: location?.locationId,
    title: name,
    comment: typeof description === 'string'
        ? JSON.stringify({
            blocks: [
              {
                key: '637gr',
                text: description,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {}
              }
            ],
            entityMap: {}
          })
        : JSON.stringify(description),
    priority,
    status,
    nextStep,
    foundDuring,
    assignedTo: assignedTo?.key ? assignedTo?.key : '',
    isVisible: visibleToCustomer,
    userId: userInfo?.id ? userInfo.id : '',
    createdBy: proposedBy ? proposedBy : getUserName(userInfo),
    creationDate: proposalDate
      ? moment(proposalDate).format(BACKEND_DATE_FORMAT)
      : moment().format(BACKEND_DATE_FORMAT),
    targetDate: targetDate
      ? moment(targetDate).format(BACKEND_DATE_FORMAT)
      : null,
    chartLink,
    linkDescription: descriptionLink,
    impactEnergyDisplay: kpi[0]?.value,
    impactEnergy: kpi[0]?.priority,
    impactEnergyValue: kpi[0]?.savings,
    impactEnergyValueType: kpi[0]?.timePeriod,
    impactPerformanceDisplay: kpi[1]?.value,
    impactPerformance: kpi[1]?.priority,
    impactPerformanceValue: kpi[1]?.savings,
    impactPerformanceValueType: kpi[1]?.timePeriod,
    impactReliabilityDisplay: kpi[2]?.value,
    impactReliability: kpi[2]?.priority,
    impactReliabilityValue: kpi[2]?.savings,
    impactReliabilityValueType: kpi[2]?.timePeriod,
    impactComfortDisplay: kpi[3]?.value,
    impactComfort: kpi[3]?.priority,
    impactComfortValue: kpi[3]?.savings,
    impactComfortValueType: kpi[3]?.timePeriod,
    impactComplianceDisplay: kpi[4]?.value,
    impactCompliance: kpi[4]?.priority,
    impactComplianceValue: kpi[4]?.savings,
    impactComplianceValueType: kpi[4]?.timePeriod,
    impactCustom1Display: kpi[5]?.value,
    impactCustom1: kpi[5]?.priority,
    impactCustom1Value: kpi[5]?.savings,
    impactCustom1ValueType: kpi[5]?.timePeriod,
    impactCustom1Name: kpi[5]?.name,
    impactCustom2Display: kpi[6]?.value,
    impactCustom2: kpi[6]?.priority,
    impactCustom2Value: kpi[6]?.savings,
    impactCustom2ValueType: kpi[6]?.timePeriod,
    impactCustom2Name: kpi[6]?.name
  }
  if (mode === ACTIONS.EDIT) {
    input['id'] = values.findingId
  }
  return input
}
export const getEquipmentsForUpsert = (issue, tisObjects, mode) => {
  // Getting existing equipments Ids
  const existingEquipmentIds =
    issue?.tisObjects?.map((tisObject) => tisObject?.tisObjectId) ?? []
  // Getting new equipments Ids
  const newEquipmentIds =
    tisObjects?.map((tisObject) => tisObject?.tisObjectId) ?? []
  // Getting new equipments List
  const newEquipmentList =
    mode === ACTIONS.COPY || mode === ACTIONS.ADD
      ? tisObjects
      : tisObjects?.filter(
          (o) => !existingEquipmentIds?.includes(o?.tisObjectId)
        ) ?? []
  // Getting deleting equipments List
  const deleteEquipmentList =
    mode === ACTIONS.COPY
      ? []
      : issue?.tisObjects?.filter(
          (finding) => !newEquipmentIds.includes(finding?.tisObjectId)
        ) ?? []
  return { newEquipmentList, deleteEquipmentList }
}
export const getImagesForUpsert = (existingImages, images, mode) => {
  // Getting new images Ids
  const newIds = images?.filter((n) => n?.imageId).map((i) => i?.imageId)
  // Getting existing images Ids
  const existingIds = existingImages?.map((i) => i?.imageId)
  // Getting new images List
  const newImageList =
    mode === ACTIONS.COPY
      ? images
      : images?.filter((n) => (!existingIds.includes(n?.imageId) && n?.imageId) || (!n?.name && !n?.imageId && (n?.title || n?.caption)))
  // Getting updating images List
  const updateImageList =
    mode === ACTIONS.COPY
      ? []
      : images?.filter((n) => n?.hasOwnProperty('originalImageId') && (n?.originalImageId === n?.imageId || !n?.imageId))
  const updateIds = images
    ?.filter((n) => n?.hasOwnProperty('originalImageId') && !n?.imageId)
    .map((u) => u?.originalImageId)
  let deleteImageList = existingImages?.filter(
    (n) => !newIds.includes(n?.imageId) && !updateIds.includes(n?.imageId)
  )
  // Getting image ( delete image alone )
  const deleteImageAlone = images?.filter(
    (n) => n?.hasOwnProperty('originalImageId') && !n?.imageId
  )
  // Getting deleting images List
  deleteImageList =
    mode === ACTIONS.COPY ? [] : [...deleteImageList, ...deleteImageAlone]
  return {
    newList: newImageList,
    deleteList: deleteImageList,
    updateList: updateImageList
  }
}

export const getFileForUpsert = (existingFile, currentFile, mode) => {
  // Getting new file
  const newFile =
    mode === ACTIONS.COPY
      ? currentFile
      : existingFile?.name !== currentFile?.name
      ? currentFile
      : {}
  // Getting deleting file
  const deleteFile =
    mode === ACTIONS.COPY
      ? {}
      : existingFile?.name !== currentFile?.name
      ? existingFile
      : {}
  // Getting updating file
  const updateFile =
    mode === ACTIONS.COPY
      ? {}
      : currentFile?.name !== newFile?.name &&
        currentFile?.name !== deleteFile?.name
      ? currentFile
      : {}
  return { newFile, deleteFile, updateFile }
}

export const FileUploadType = {
  ADD_FILES: 'Add Files',
  ADD_IMAGES: 'Add Images'
}

export const decodeURLSearchParams = (paramVal: any) => {
  return paramVal === null ? [] : decodeURI(paramVal)?.split(",") || []
}
 
export const encodeURISearchParams = (paramVal: Array<string>) => {
  const list = _isArray(paramVal) ? paramVal : []
  return encodeURI(list?.join(","))
}

export const deriveTestName = (id?:string) =>   `IssuesAndFindings${id ? `_${id}` : ""}`
