import * as d3 from 'd3'
import { Fragment, memo, useMemo } from 'react'
import {
  selectChartInstanceId,
  selectInternalLegend,
  selectSeriesTypes,
  useSelector
} from '../../BaseSetup/BaseSetup'
import { plotAreaID } from './helper'
import { rendererPicker } from './Renderers/picker'
import OrderModifierSetup from './OrderModifierSetup'

const SeriesRenderer = (props: any) => {
  const { canvasIndex } = props
  const seriesTypes = useSelector(selectSeriesTypes)
  const chartInstanceId = useSelector(selectChartInstanceId)
  const internalLegend = useSelector(selectInternalLegend)

  const groupedTypes = useMemo(() => {
    const filtered = seriesTypes?.filter(
      (obj: any) => {
        const show = internalLegend?.isInternal ? !(internalLegend?.hiddenLegends?.[obj?.id]) : obj?.show
        return obj?.canvasIndex === canvasIndex && show
      }
    )
    const groupedTypes = Array.from(
      d3.group(filtered, (d: any) => d?.type),
      ([key, value]) => ({ key, value })
    )
    return groupedTypes
  }, [seriesTypes, canvasIndex, Object.keys(internalLegend?.hiddenLegends), internalLegend?.isInternal])

  return (
    <>
      {groupedTypes?.map((obj: any, i: any) => {
        const RendererComp = rendererPicker(obj?.key)
        const types = obj?.value || []
        if (!RendererComp) {
          return <Fragment key={i}></Fragment>
        }
        return (
          <g
            key={i}
            clipPath={
              obj?.key === 'plotLine'
                ? ''
                : `url(#${plotAreaID(chartInstanceId, canvasIndex)})`
            }
          >
            <RendererComp
              key={`${i}-${obj?.key}`}
              types={types}
              canvasIndex={canvasIndex}
              setTooltip={props?.setTooltip}
            />
          </g>
        )
      })}
      <OrderModifierSetup canvasIndex={canvasIndex} />
    </>
  )
}

export default memo(SeriesRenderer)
