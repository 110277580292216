import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import RectClip, { clipUUID, RectClipCompProps } from './RectClip'

interface GroupAndClipCompProps {
  clipName: string
  gx?: number
  gy?: number
  children?: any
  disableClip?: boolean
  rectClipProps?: Omit<RectClipCompProps, 'uuid' | 'x' | 'y'>
  [key: string]: any
}
function GroupAndClip(
  {
    clipName,
    gx,
    gy,
    children,
    rectClipProps,
    disableClip = false,
    ...rest
  }: GroupAndClipCompProps,
  ref: any
) {
  const clipUUId = useMemo(() => clipUUID(clipName), [clipName])

  const gRef = useRef(null)

  useImperativeHandle(ref, () => gRef.current, [])

  return (
    <g
      className="series"
      transform={`translate(${gx} ${gy})`}
      clipPath={`url(#${clipUUId})`}
      ref={gRef}
      {...rest}
    >
      {children}
      {!disableClip && (
        <RectClip
          uuid={clipUUId}
          x={0}
          y={0}
          parentRef={gRef}
          {...rectClipProps}
        />
      )}
    </g>
  )
}

export default forwardRef(GroupAndClip)
