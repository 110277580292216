/**
 * fn to publish event with given value
 *
 * @param {*} event
 * @param {*} value
 * @param {*} name
 * @param {*} [callback=() => null]
 */
export default function eventPub(
  event,
  value,
  name = '',
  callback = () => null
) {
  const nativeEvent = event.nativeEvent || event
  const clonedEvent = new nativeEvent.constructor(nativeEvent.type, nativeEvent)

  Object.defineProperty(clonedEvent, 'target', {
    writable: true,
    value: { value, name }
  })
  callback(clonedEvent)
}
