export const tableHeadings = [
    {
      title: "Property Name",
      key: 'tisDisplayName',
      width:'200px',
      canSort:false,
    },
    {
    title: "Source Key",
    key: 'sourceKey',
    width: '200px',
    canSort: true
    },
    {
    title: "Target Key",
    key: 'targetKey',
    width: '200px',
    canSort: true
    },
    {
    title: "Heystack",
    key: 'haystack',
    width: '200px',
    canSort: true
    },
    {
    title: "Brick",
    key: 'brick',
    width: '200px',
    canSort: false
    }
    
 ]