import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

/**
 * If you want to use the component with Formik you just need set "name" and "handleChange" props
 * <ToggleButtonHorizontal name={"name_of_field_in_form"} checked={name_of_field_in_form} onChange={handleChange} />
 * @param off - text before checkbox
 * @param on - text after checkbox
 * @param checked
 * @param disabled
 * @param name - name of field in form
 * @param id - id for input tag
 * @param onChange - the method which returns event of input (event.target.checked here you can check if checkbox checked)
 * @returns {*}
 */

function ToggleButtonHorizontal({
  off,
  on,
  checked,
  disabled,
  name,
  onChange,
  testName='',
  id,
  testId
}) {
  return (
    <div data-testid={`${testName}-toggle-hotizontal`}className="toggle-horizontal">
      <label>
        {off && <span>{off}</span>}
        <input
          id={id}
          type="checkbox"
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          data-testid={testId}
        />
        <div />
        {on && <span>{on}</span>}
      </label>
    </div>
  )
}

ToggleButtonHorizontal.propTypes = {
  off: PropTypes.string,
  on: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  onChange: PropTypes.func.isRequired
}
ToggleButtonHorizontal.defaultProps = {
  off: '',
  on: '',
  name: '',
  id: '',
  checked: false,
  disabled: false
}

export default ToggleButtonHorizontal
