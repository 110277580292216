import translate, { TranslateComponent } from 'src/common/translations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseyeArrow } from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import OpportunityList from 'src/pages/opportunities/OpportunityList'
import OpportunityListPdfButton from 'src/pages/opportunities/OpportunityDetail/pdf/pdf-button'
import { CSVButton } from 'src/denali-components/Table/CSVButton'
import { CreateOpportunityButton } from 'src/denali-pages/Opportunities/CreateOpportunityButton'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'

/**
 * OpportunitiesContainer
 * View replacement for src/pages/opportunities/OpportunityContainer.tsx
 */

export const OpportunitiesContainer = ({
  accountId,
  buildingId,
  dataList,
  getCSVOpportunitiesData,
  getLocationName,
  getOrganizationName,
  handleCreateOpportunityClicked,
  handleGetOpportunitiesRecords,
  loading,
  locationId,
  locationName,
  opportunities,
  organizationName,
  uiMode
}) => {
  return (
    <Page
      title={translate('Opportunities')}
      icon={<FontAwesomeIcon icon={faBullseyeArrow} />}
      iconColor={IconColor.orange}
      actions={[
        <Tooltip key="downloadPDF">
          <TooltipTrigger>
            <OpportunityListPdfButton
              key={'downloadPdf'}
              loading={loading}
              locationName={locationName}
              organizationName={organizationName}
              allOrganizations={false}
              opportunities={opportunities}
              activeSalesOffice={locationId}
              uiMode={uiMode}
            />
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>Download PDF</TranslateComponent>
          </TooltipContent>
        </Tooltip>,
        <Tooltip key="downloadCSV">
          <TooltipTrigger>
            <CSVButton
              key="downloadCsv"
              filteredData={opportunities}
              dataList={dataList}
              locationId={locationId}
              getCSVOpportunitiesData={getCSVOpportunitiesData}
              getLocationName={getLocationName}
              getOrganizationName={getOrganizationName}
            />
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>Download CSV</TranslateComponent>
          </TooltipContent>
        </Tooltip>,
        <CreateOpportunityButton
          key={'create'}
          amplifyButton
          buildingId={buildingId}
          accountId={accountId}
          handleCreateOpportunityClicked={handleCreateOpportunityClicked}
        />
      ]}
    >
      <OpportunityList
        uiMode={uiMode}
        handleGetOpportunitiesRecords={handleGetOpportunitiesRecords}
        noTableDataAction={
          <CreateOpportunityButton
            key={'create'}
            amplifyButton
            buildingId={buildingId}
            accountId={accountId}
            handleCreateOpportunityClicked={handleCreateOpportunityClicked}
          />
        }
      />
    </Page>
  )
}

export default OpportunitiesContainer
