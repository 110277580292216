import { useFormikContext } from '../../MultiTabTorm'
import { useWidgetsContext } from '../../../WidgetsContextProvider'
import { EnergyDemandWidgetPreview } from 'src/pages/dashboards/components/DashboardCardWidget/EnergyDemandWidgetPreview'
import { WidgetPreviewWrapper } from '../../widgetPreviewWrapper'
import styled from 'styled-components'
import React, { useMemo } from 'react'


const EnergyDemandWidgetContainer = styled.div`
height: 270px`

export const EnergyDemandWidgetPreviewWrapper = () => {
  const formik = useFormikContext()
  const { values } = formik
  const { objectPickerSelectedValue, formikErrors } = useWidgetsContext()

  const getEquipmentList = () => {
    return (
      objectPickerSelectedValue &&
      objectPickerSelectedValue?.map((selectedValue) => {
        return {
          buildingName: selectedValue?.buildingName,
          equipmentName: selectedValue?.equipmentName,
          accountName: selectedValue?.accountName
        }
      })
    )
  }

  const getPreviewDetails = useMemo(() => ({
    buildingId: values?.widgetBuilding,
    equipmentId: objectPickerSelectedValue?.[0]?.equipmentId,
    widgetDescription: values?.description ?? '',
    equipmentList: getEquipmentList(),
    property: objectPickerSelectedValue?.[0].equipmentType === 'VirtualMeter' ? 'InstantaneousPower' : 'RealPower',
    comparePointTo: values?.comparedTo,
    selectedTimePeriod: values?.timePeriod?.split('/')?.[1],
    equipmentType: objectPickerSelectedValue?.[0].equipmentType,
    unitsOfMeasure: 'kw',
    currentLineColor: values.lineColor,
    timeInterval: '15-minutes',
    currentDemandColor: values?.currentDemandColor,
    peakDemandColor: values?.peakDemandColor,
    rateIncrease: values?.rateIncrease,
    isShowRateIncrease: values?.isRateIncrease === "0" ? false : true,
    rateIncreaseOverlayColor: values?.rateIncreaseOverlayColor,
    buildingName: objectPickerSelectedValue?.[0]?.buildingName,
    timeZone: objectPickerSelectedValue?.[0]?.timeZone,
    equipmentName: objectPickerSelectedValue?.[0]?.equipmentName
  }), [values, objectPickerSelectedValue])

  return (
    <WidgetPreviewWrapper
      widgetName={values?.name}
      error={Object.keys(formikErrors)?.length > 0}
      chartType="energy-demand-widget"
    >
      <EnergyDemandWidgetContainer>
        <EnergyDemandWidgetPreview widgetDetails={getPreviewDetails} />
      </EnergyDemandWidgetContainer>
    </WidgetPreviewWrapper>
  )
}
