import { useFormik } from 'formik'
import ConsultationHeadSection from './ConsultationHeadSection'
import ConsultationBodySection from './ConsultationBodySection'
import { Wrapper } from '../styles'
import {
  CREATE_ATTACHMENT,
  CREATE_CONSULTATION,
  CREATE_CONSULTATION_ASSUMPTIONS,
  CREATE_ENTRY_WITH_ATTACHMENT,
  DELETE_ATTACHMENT,
  SEARCH_BUILDINGS_BY_ACCOUNT_ID,
  GET_CONSULTATION,
  UPDATE_CONSULTATION,
  GET_CONSULTATION_ASSUMPTION_VALUES,
  listConsultations,
  DELETE_CONSULTATION_VALUE_ASSUMPTIONS,
  UPDATE_CONSULTATION_ASSUMPTIONS
} from '../graphql'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getSearchParams } from "src/common/helperFunctions.js"
import { useEffect, useState, useRef, useCallback } from 'react'
import moment from 'moment'
import {
  BACKEND_DATE_TIME_FORMAT_TZ
} from 'src/components/legacy/common/time-helpers'
import { ACTIONS } from 'src/constants'
import { useAttachment } from '../../../hooks/attachment'
import { CHART_IMAGE_PATHS, getFormValues, getUpsertInput, validationSchemaConsultation } from '../helper'
import  AddValueAssumptions  from "../AssumptionValues/index"
import { useMemo } from 'react'
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'
import { CREATE_BULK_EQUIPMENT_OPPORTUNITY } from '../../opportunities/graphql'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import isArray from 'lodash/isArray'
import { MODES } from '../constants'
import PAGE_NAMES from 'src/components/legacy/common/pages'
import { useDispatch } from 'react-redux'
import { newRefreshToken } from 'src/redux/slicers/appData'
import {formatAssumptionValues} from './helperFunction'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { updateFileNameWithEpoch } from 'src/common/helperFunctions.js'

const Consultation = (props) => {
  const {organizationId, buildingId: location} = getSearchParams()
  const params = useParams()
  const id = params?.id ?? null
  const navigate = useNavigate()
  const {search} = useLocation();
  const [isSubmit, setIsSubmit] = useState(false)
  const [consultationInitialValues, setConsultationInitialValues] = useState(null)
  const [showAssumptions, setShowAssumptions] = useState(false)
  const [assumptionValues, setAssumptionValues] = useState(null)
  const [createImages, setCreateImages] = useState(null)
  const [deleteImages, setDeleteImages] = useState([])
  const [imageDetail, setImageDetail] = useState(null)
  const [isLoading, setIsLoading] = useState([ACTIONS.EDIT, ACTIONS.COPY].includes(props?.mode))
  const [showRecentValueConfirmModal, setShowRecentValueConfirmModal] = useState(false)
  const [recentAssumptionsPerBuilding, setRecentAssumptionsPerBuilding] = useState([])
  const [reviewModalShownBuildingList, setReviewModalShownBuildingList] = useState([])
  const [assumptionToReuse, setAssumptionToReuse] = useState(null)
  const [upsertConsultationId, setUpsertConsultationId] = useState(null);
  const [assumptionModalReopen, setAssumptionModalReopen] = useState<any>([])
  const [kpiData,setKpiData] = useState()
  const [editKpiData, setEditKpiData] = useState()
  const dispatch = useDispatch()
  
  const { mode } = props || {}

  const { downloadFileURL,copyFileFunc } = useAttachment()

  const { onSubmit: createBulkEquipmentOpportunity } = useMutation({
    query: CREATE_BULK_EQUIPMENT_OPPORTUNITY,
    onError: (error) => {
      console.log('Consulation Bulk Opportunity Create',error)
    }
  })

  const { data: buildingsSearchList, refetch: refetchSearchBuildingsList } = useQuery({
    query: SEARCH_BUILDINGS_BY_ACCOUNT_ID,
    variables: { filter: {accountId: {eq: organizationId}}, limit: 1000 },
    disableInitialLoad: true,
    dataPath: 'data.searchBuildings.items',
    onSuccess: d => {
      if (d?.length) {
        formik.setFieldValue("organizationName", d[0].accountName)
      }
    }
  })

  const {
    data: consultationList,
    refetch: refetchConsultationList,
    loading: consultationListLoading
  } = useQuery({
    query: listConsultations,
    dataPath: 'data',
    variables: {
      accountId :organizationId,
      filter: {
        accountId: { eq: organizationId ? organizationId : "" },
        limit: 1000
      },
    },
    disableInitialLoad: true
  })

  const { data: recentAssumptionsValuesAPIResult, loading: loadingRecentValueAssumptions, refetch: fetchRecentAssumptionValues } = useQuery({
    query: GET_CONSULTATION_ASSUMPTION_VALUES,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getConsultationValueAssumption'
  })

  const { onSubmit: deleteConsultationValueAssumption } = useMutation({
    query: DELETE_CONSULTATION_VALUE_ASSUMPTIONS,
    dataPath: 'data.deleteConsultationValueAssumption'
  })

  const { onSubmit: upsertConsultation } = useMutation({
    query: [ACTIONS.ADD, ACTIONS.COPY].includes(mode) ? CREATE_CONSULTATION : UPDATE_CONSULTATION,
    dataPath: [ACTIONS.ADD, ACTIONS.COPY].includes(mode) ? 'data.createConsultation' : 'data.updateConsultation'
  })

  const { onSubmit: createAssumptionValuesMutation } = useMutation({
    query: CREATE_CONSULTATION_ASSUMPTIONS,
    dataPath: 'data.createConsultationValueAssumption'
  })


  const { onSubmit: updateAssumptionValuesMutation } = useMutation({
    query: UPDATE_CONSULTATION_ASSUMPTIONS,
    dataPath: 'data.updateConsultationValueAssumption'
  })

  const { onSubmit: createAttachmentMutation } = useMutation({
    query: CREATE_ATTACHMENT
  })

  const { onSubmit: createEntryWithAttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })

  const { onSubmit: deleteAttachmentMutation } = useMutation({
    query: DELETE_ATTACHMENT
  })


  const {
    refetch: refetchConsultationDetails,
  } = useQuery({
    query: GET_CONSULTATION,
    variables: { id },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getConsultation',
    onSuccess: (data) => {
      if (data) {
        (async () => {
          await fetchingConsultationWithImages(data)
        })()
      }
    }
  })

  useEffect(() => {
    // sets recent assumption values for value assumptions
    // If the user chose to go with reuse
    recentAssumptionsValuesAPIResult && setAssumptionValues(recentAssumptionsValuesAPIResult)
  },[recentAssumptionsValuesAPIResult])

  useEffect(() => {
    setAssumptionValues(null)
    setImageDetail(null)
    setAssumptionToReuse(null)
    setCreateImages([])
    setDeleteImages([])
    if(mode === ACTIONS.ADD){
      const formValues = getFormValues(mode, null, organizationId ?? '', location ?? [])
      setConsultationInitialValues(formValues)
    }
    if ([ACTIONS.EDIT,ACTIONS.COPY].includes(mode)) {
      refetchConsultationDetails({id})
    }   
  }, [mode])

  const fetchImage = useCallback(
    async (image) => {
      const img = {
        original: null,
        imageId: image?.id,
        originalImageId: image?.id,
        originalTitle: image?.title,
        description: image?.description,
        name: image?.name,
        buildingId: image?.buildingId,
        title: image?.title,
        caption: image?.description,
        category: image?.category,
        imageUrl: null,
        displayName: image?.displayName,
        attachmentOrder: image?.attachmentOrder
      }
      if (image?.name && image?.buildingId) {
        await downloadFileURL(image?.name, image?.buildingId).then((res) => {
          img.imageUrl = res
          img.original = res
        })
      }
      return img
    },
    [imageDetail]
  )

  const fetchingConsultationWithImages = async (data) => {
    if (data) {
      const attachments = data?.attachments?.items ?? []
      const consultationImages = []
      setIsLoading(true)
      for (const cur of attachments) {
        setImageDetail(cur)
        const img = await fetchImage(cur)
        consultationImages.push(img)
      }
      const formValues = getFormValues(mode, { ...data, attachments: consultationImages }, organizationId, location)
      if ([ACTIONS.EDIT, ACTIONS.COPY].includes(mode)) {
        if (formValues?.assumptionValueId) {
          //@ts-ignore
          setAssumptionValues(formValues?.assumptionValue)
          // setting KPI data in case of edit/copy
          const formateData = formatAssumptionValues(formValues?.assumptionValue)
          setEditKpiData(formateData)
        }
      }
      setConsultationInitialValues(formValues)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (organizationId) {
      refetchSearchBuildingsList({ filter: {accountId: {eq: organizationId}}, limit: 1000 })
      setRecentAssumptionsPerBuilding([])
      refetchConsultationList({
        accountId :organizationId,
        filter: {
          accountId: { eq: organizationId }
        }
      })
    }
  }, [organizationId])

  useEffect(() => {
    if([ACTIONS.EDIT,ACTIONS.COPY].includes(mode)){
      if (consultationInitialValues && consultationInitialValues?.organization) {
        const orgId = consultationInitialValues?.organization ?? null
        if(orgId){
          refetchSearchBuildingsList({ filter: {accountId: {eq: orgId}}, limit: 1000 })
        }
      }
    }
   }, [consultationInitialValues])


  useEffect(()=>{
    // This provider keeps recent assumption value ids per building
    // can be used in assumption values page, to get recent assumption values
    if (consultationList) {
      const consultationsList = consultationList?.searchConsultations?.items ?? []
      if (consultationsList?.length > 0) {
        try {
          const assumptionsPerBuilding = []

          consultationsList?.map((listItem) => {
            const buildingIds = listItem?.buildingIds
            buildingIds?.map((buildingId) => {
              const buildingIndex = assumptionsPerBuilding?.findIndex(x => x.buildingId === buildingId)
              if (buildingIndex === -1) {
                // @ts-ignore
                const assumptionValues = listItem?.assumptionValue
                if (assumptionValues !== null) {
                  const lastActivityTimeStamp = assumptionValues?.updatedAt ? assumptionValues?.updatedAt : assumptionValues?.createdAt
                  const assumptionValuesItem = {
                    buildingId: buildingId,
                    recentAssumptionValueId: assumptionValues?.id,
                    lastActivityTimeStamp
                  }
                  assumptionsPerBuilding.push(assumptionValuesItem)
                }
              } else {
                if (listItem?.assumptionValue !== null) {
                  const newAssumptionValues = listItem?.assumptionValue
                  const existingAssumptionItem = assumptionsPerBuilding[buildingIndex]
                  const newAssumptionValuesLastActivity = newAssumptionValues?.updatedAt ? newAssumptionValues?.updatedAt : newAssumptionValues?.createdAt

                  if (moment.utc(newAssumptionValuesLastActivity)?.valueOf() > moment.utc(existingAssumptionItem?.lastActivityTimeStamp)?.valueOf()) {
                    assumptionsPerBuilding[buildingIndex].recentAssumptionValueId = newAssumptionValues?.id
                    assumptionsPerBuilding[buildingIndex].lastActivityTimeStamp = newAssumptionValuesLastActivity
                  }
                }
              }
            })
          })
          setRecentAssumptionsPerBuilding(assumptionsPerBuilding)
        } catch (error) {
          console.log(error)
          setRecentAssumptionsPerBuilding([])
        }
      }
    }
 },[consultationList])

 

  const updateAssumptionValues = async (assumptionValueId) => {
    try {
      let selectedBuildingList = []
        if(Array.isArray(formik?.values?.building)) {
          selectedBuildingList = formik?.values?.building
        } else {
          selectedBuildingList.push(formik?.values?.building)
        }
      const inputVariables = {
        accountId: formik?.values?.organization,
        buildingIds: selectedBuildingList,
        id: assumptionValueId,
        ...assumptionValues
      }
      const input = {...inputVariables}
      delete input.createdAt
      delete input.updatedAt
      const updateAssumptionValueId = await updateAssumptionValuesMutation({ input })
      return updateAssumptionValueId?.id
    } catch(error) {
      console.log("update Assumption values error handling here")
    }
}

  const createAssumtionValues = async () => {
        let selectedBuildingList = []
        if(Array.isArray(formik?.values?.building)) {
          selectedBuildingList = formik?.values?.building
        } else {
          selectedBuildingList.push(formik?.values?.building)
        }
        try {
          const inputVariables = {
            accountId: formik?.values?.organization,
            buildingIds: selectedBuildingList,
            ...assumptionValues
          }
          const input = {...inputVariables}
          delete input.id
          delete input.createdAt
          delete input.updatedAt
          await dispatch<any>(newRefreshToken(input.buildingIds || [], [input.accountId], null, ["AttachmentAdmin", "DocumentAdmin", "EnergyUsageReportAdmin", "ConsultationUser", "ConsultationAdmin"]))
          const assumptionValueId = await createAssumptionValuesMutation({ input })
          return assumptionValueId?.id
        } catch(error) {
          console.log("add Assumption values error handling here")
        }
  }

  const consultationRef = useRef()

  const handleUpsertImage = async (formValues, consultationId, orderNo, category, existingImageId) => {
    // Create New Attachment
    const locationId = isArray(formValues?.building) ? formValues?.building[0] ?? '' : formValues?.building
    let image = null
    let title = ''
    switch (category) {
      case CHART_IMAGE_PATHS.WHAT_WE_HAVE_SAVED:
        image = formValues?.whatWeHaveSavedImageDetails
        title = formValues?.sectionTitleWhatWeHaveSaved
        break;
      case CHART_IMAGE_PATHS.WHERE_WE_HAVE_BEEN:
        image = formValues?.whereHaveYouBeenImageDetails
        title = formValues?.sectionTitleWhereWeHaveBeen
        break;
      case CHART_IMAGE_PATHS.WHAT_WE_HAVE_DONE:
        image = formValues?.whatWeHaveDoneImageDetails
        title = formValues?.sectionTitleWhatWeHaveDone
        break;

    }
    if (!image || !locationId) return
    if (existingImageId !== image?.imageId) {
      if (image?.imageId) {
        let fineName = image?.name
        if(mode === ACTIONS.COPY && image?.buildingId){                
          fineName = updateFileNameWithEpoch(image?.displayName)
          await copyFileFunc(image?.name, image?.buildingId, locationId, fineName)
        }
        await createAttachmentMutation({
          input: {
            buildingId: locationId,
            description: image?.caption ? image?.caption : '',
            name: fineName,
            attachedAt: moment().format(BACKEND_DATE_TIME_FORMAT_TZ),
            consultationId: consultationId,
            sizeInBytes: image?.size,
            type: image?.type ?? "image",
            category,
            displayName: title,
            title,
            attachmentOrder: orderNo
          }
        })

        // S3 Bucket Create Images
        const consultationCreateImages = createImages
        consultationCreateImages.push({
          buildingId: locationId,
          name: fineName
        })
        setCreateImages(consultationCreateImages)
      }
      //Delete Existing Attachment
      if (existingImageId) {
        await deleteAttachmentMutation({
          input: {
            id: existingImageId
          }
        })

        // S3 Bucket Delete Images
        const consultationDeleteImages = deleteImages
        const existingImageName = formValues?.existingAttachments?.find(e => e?.imageId === existingImageId)?.name ?? ''
        consultationDeleteImages.push({
          attachmentId: existingImageId,
          buildingId: locationId,
          name: existingImageName
        })
        setDeleteImages(consultationDeleteImages)
      }
    }
  }
  const handleImageUpsert = async (formValues, consultationId) => {
    // What We Have Saved Image
    const existingSavedImgId = formValues?.existingAttachments?.find(f => f?.category === CHART_IMAGE_PATHS.WHAT_WE_HAVE_SAVED)?.imageId ?? null
    await handleUpsertImage(formValues, consultationId, 1, CHART_IMAGE_PATHS.WHAT_WE_HAVE_SAVED, existingSavedImgId)
    // Where Have You Been Image
    const existingBeenImgId = formValues?.existingAttachments?.find(f => f?.category === CHART_IMAGE_PATHS.WHERE_WE_HAVE_BEEN)?.imageId ?? null
    await handleUpsertImage(formValues, consultationId, 2, CHART_IMAGE_PATHS.WHERE_WE_HAVE_BEEN, existingBeenImgId)
    // What We Have Done Image
    const existingDoneImgId = formValues?.existingAttachments?.find(f => f?.category === CHART_IMAGE_PATHS.WHAT_WE_HAVE_DONE)?.imageId ?? null
    await handleUpsertImage(formValues, consultationId, 3, CHART_IMAGE_PATHS.WHAT_WE_HAVE_DONE, existingDoneImgId)
    // S3 Bucket Upsert
    await handleS3BucketUpsert()
   
  }
  const handleS3BucketUpsert = async () => {
    // Prepare Attachment Properties For Upsert on S3 Bucket
    const attachmentBody = {
      modelType: 'Consultation',
      attachments: {}
    }

    if (createImages?.length)
      attachmentBody.attachments['create'] = createImages
    if (deleteImages?.length)
      attachmentBody.attachments['delete'] = deleteImages
    // Upsert on S3 bucket
    if (createImages?.length || deleteImages?.length) {
      await createEntryWithAttachmentMutation({
        input: JSON.stringify(attachmentBody)
      })
    }
  }
  const handleCreateOpportunity = async (consultationId, formValues) => {
    const accountId = formValues?.organization
    const locationId = isArray(formValues?.building) ? formValues?.building[0] ?? '' : formValues?.building
    const existingOpportunityIds = formValues?.existingOpportunityList?.map(e => e?.key) ?? []
    const newOpportunityList = formValues?.opportunityList?.filter(o => !existingOpportunityIds?.includes(o?.key))

    if (newOpportunityList?.length > 0) {
      const insertInput = {
        equipmentList: [],
        opportunityList: newOpportunityList?.map(i=>i?.key),
        modelType: 'ConsultationToOpportunities',
        modelId: consultationId,
        action: 'Create',
        buildingId: locationId,
        accountId
      }
      await createBulkEquipmentOpportunity({
        input: JSON.stringify(insertInput)
      })
    }
  }
  const handleDeleteOpportunity = async (consultationId, formValues) => {
    const accountId = formValues?.organization
    const locationId = isArray(formValues?.building) ? formValues?.building[0] ?? '' : formValues?.building
    const opportunityIds = formValues?.opportunityList?.map(e => e?.key) ?? []

    const deleteOpportunityList = formValues?.existingOpportunityList
                                            ?.filter(o => !opportunityIds?.includes(o?.key) || !(formValues?.building?.includes(o?.buildingId)))
    if (deleteOpportunityList?.length > 0) {
      const deleteInput = {
        equipmentList: [],
        opportunityList: deleteOpportunityList?.map(i=>i?.key),
        modelType: 'ConsultationToOpportunities',
        modelId: consultationId,
        action: 'Delete',
        buildingId: locationId,
        accountId
      }
      await createBulkEquipmentOpportunity({
        input: JSON.stringify(deleteInput)
      })
    }
  }
  const handleUpsert = async (formValues) => {
  if (!formValues?.organization || 
    (isArray(formValues?.building) && formValues?.building?.length === 0) || 
    (!isArray(formValues?.building) && !formValues?.building)) return
   setCreateImages([])
   setDeleteImages([])
   setIsSubmit(true)
   const input = getUpsertInput(mode, formValues)
   let assumptionValueId;
   let consultationId = null
    try {
      if ([ACTIONS.ADD, ACTIONS.COPY].includes(mode)) {
        if (assumptionValues) {
          assumptionValueId = await createAssumtionValues()
          input.assumptionValueId = assumptionValueId
        }
      }
      if (mode === ACTIONS.EDIT) {
          if (assumptionValues) {
            // if presents, update value assumption
            if (input?.assumptionValueId) {
              assumptionValues['id'] = input?.assumptionValueId
              await dispatch<any>(newRefreshToken(input.buildingIds || [], [input.accountId], null, ["AttachmentAdmin", "DocumentAdmin", "EnergyUsageReportAdmin", "ConsultationUser", "ConsultationAdmin"]))
              await updateAssumptionValues(assumptionValues)
            } else {
              await dispatch<any>(newRefreshToken(input.buildingIds || [], [input.accountId], null, ["AttachmentAdmin", "DocumentAdmin", "EnergyUsageReportAdmin", "ConsultationUser", "ConsultationAdmin"]))
              assumptionValueId = await createAssumtionValues()
              input.assumptionValueId = assumptionValueId
            }
          } else {
            // if not, it means deleted, so delete assumption and update consulation assumption value id to null
            if(input?.assumptionValueId) {
              await dispatch<any>(newRefreshToken(input.buildingIds || [], [input.accountId], null, ["AttachmentAdmin", "DocumentAdmin", "EnergyUsageReportAdmin", "ConsultationUser", "ConsultationAdmin"]))
              await deleteConsultationValueAssumption({ input: { id: input.assumptionValueId } })
              input.assumptionValueId = null
            }
          }
      }
      // // Upsert Consultation
      const response = await dispatch<any>(newRefreshToken(input.buildingIds || [], [input.accountId], null, ["AttachmentAdmin", "DocumentAdmin", "EnergyUsageReportAdmin", "ConsultationUser", "ConsultationAdmin"])).then(async () => await upsertConsultation({ input }).then((res) =>{
        consultationId = res.id
        return res.id
      }))
      if (response) {
        // Upsert Consultation Images
        await handleImageUpsert(formValues, response)
        // Create Consultation Opportunities
        await handleCreateOpportunity(response, formValues)
         // Delete Consultation Opportunities
        await handleDeleteOpportunity(response, formValues)
        setIsSubmit(false)
      }
      return consultationId
    } catch (error) {
      setIsSubmit(false)
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (!upsertConsultationId) return
    navigate({
      pathname: `/Consultations/${upsertConsultationId}`,
      search
    }, { state: { mode: ACTIONS.VIEW, id: upsertConsultationId } })
  }, [upsertConsultationId])



  const submitConsultationForm = async (values) => {
    const consultationId = await handleUpsert(values)
    setUpsertConsultationId(consultationId)
  }

  const formik = useFormik({
    initialValues: consultationInitialValues,
    validationSchema: validationSchemaConsultation,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: submitConsultationForm
  })

  useEffect(() => {
    if (formik?.values?.organization) {
      refetchSearchBuildingsList({ filter: {accountId: {eq: formik?.values?.organization}}, limit: 1000 })
      setRecentAssumptionsPerBuilding([])
      refetchConsultationList({
        accountId :formik?.values?.organization,
        filter: {
          accountId: { eq: formik?.values?.organization }
        }
      })
    }
  }, [formik?.values?.organization])

  const handleFormSubmit = () => {
    formik.handleSubmit()
    if(mode == ACTIONS.ADD)
      {
        trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.SAVE_CONSULTATION, {type:"Create", title:formik?.values?.consultationTitle})
      }
    else if(mode == ACTIONS.EDIT)
      {
        trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.SAVE_CONSULTATION, {type:"Edit", title:formik?.values?.consultationTitle})
      }
    else if(mode == ACTIONS.COPY)
      {
        trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.SAVE_CONSULTATION, {type:"Copy", title:formik?.values?.consultationTitle})
      }
  }
  
  const selectedBuildingNames = () => {
    // Concat all the selected building names and return the value
    let selectedBuildingNames = '';
    if(formik?.values !== null && formik?.values?.building) {
      let selectedBuildingList = []
      // If one building is selected it comes only string, more than one building, comes as array of buildingIds
      if(Array.isArray(formik?.values?.building)) {
        selectedBuildingList = formik?.values?.building
      } else {
        selectedBuildingList.push(formik?.values?.building)
      }
      selectedBuildingList.forEach(element => {
        const buildingsList = consultationList?.listBuildingsByAccount?.items ?? []
        const selectedBuilding = buildingsList?.find((x)=> x.id === element)
        if(selectedBuilding) {
          selectedBuildingNames += selectedBuilding?.name + ", " || ''
        }
      });
      return selectedBuildingNames.substring(0, selectedBuildingNames.length - 1)
    }
  }

  const openAssumptionValuesWithRecent = async () => {
    try{
        //@ts-ignore
        const recentAssumptionValueId = consultationRef?.current?.recentAssumptionValueId
        const variables = {
          id: recentAssumptionValueId
        }
        setReviewModalShownBuildingList([...assumptionModalReopen])
        //@ts-ignore
      const result = await fetchRecentAssumptionValues(variables)
      result && setAssumptionValues(result)
     
      setReviewModalShownBuildingList([...assumptionModalReopen])
    } catch(error){ 
      console.log(error)
    } finally {
      setShowAssumptions(true)
      setShowRecentValueConfirmModal(false)
    }
  }


  const openAssumptionValuesWithDefault = () => {
    setReviewModalShownBuildingList([...assumptionModalReopen])
    setAssumptionValues(null)
    setShowAssumptions(true)
    setShowRecentValueConfirmModal(false)
  }

  const closeModalDialog = () => {
    consultationRef.current = undefined;
    setShowRecentValueConfirmModal(false)
  }

  const modalConfig = useMemo(
    () => ({
      heading: "Confirmation",
      buttons:[
              {
                text: "YES, START WITH COPY",
                handleClick: openAssumptionValuesWithRecent,
                type: 'valid'
              },
              {
                text: "NO, START WITH DEFAULT",
                handleClick: openAssumptionValuesWithDefault,
                className: 'secondary',
                type: 'valid'
              },
              {
                text: "CANCEL",
                handleClick: closeModalDialog,
                type: 'cancel'
              }
            ],
      handleClose: closeModalDialog
    }),
    []
  )

  const getRecentAssumptionModalTitle = () => {
    try{
      const selectedBuildingName = assumptionToReuse?.buildingName
      const seletectedOrgName = formik?.values?.organizationName
      const createdDate = moment(assumptionToReuse?.lastactivityTimeStamp).format("MM/DD/YYYY")
      const title = `Would you like to copy the value assumption used for 
                    ${selectedBuildingName} and/or ${seletectedOrgName} that was used for the last consultation created on ${createdDate}?`
      return title
    } catch(error){

    }
  }

  const checkBuildingHasRecentAssumptions = (selectedBuildingList) => {
    try {

      // // For edit mode don't show the recent value assumptions modal
      if(mode === ACTIONS.EDIT && assumptionValues ){
        return []
      }
    // if both the selected building has assumptions, select the most recent one
    // If the building recent assuption is shown already don't show that again
    // Can optimize this method later
    if(mode === ACTIONS.ADD && assumptionValues=== null){
    const buildingListRemaining = selectedBuildingList.filter((buildingId)=> {
      const checkAlreadyModalShown = reviewModalShownBuildingList.find(x => x === buildingId)
      if(checkAlreadyModalShown){
        return false
      } else {
        return true
      }
    })

    const hasAssumptions = []
    buildingListRemaining?.forEach( (buildingId) => {
      const selectedBuildingHasAssumption = recentAssumptionsPerBuilding?.find(x=> x.buildingId === buildingId)
      const buildingsList = consultationList?.listBuildingsByAccount?.items ?? []
      const buildingName = buildingsList.find(x=> x.id === buildingId)?.name
      if(selectedBuildingHasAssumption){
        if(hasAssumptions.length === 0) {
          hasAssumptions.push({...selectedBuildingHasAssumption, buildingName})
        } else {
          // HasAssumption array will have always 1 element with latest
          // Replace whichever is latest based on last activity time stamp
          if(hasAssumptions[0].lastActivityTimeStamp < selectedBuildingHasAssumption.lastActivityTimeStamp) {
             hasAssumptions[0] = selectedBuildingHasAssumption
          }
        }
      }
    })
    // setReviewModalShownBuildingList([...selectedBuildingList])
    setAssumptionToReuse(hasAssumptions[0])
    return hasAssumptions 
    }} catch(error){
      return []
    }
  }

  const preCheckShowAssumptions = (flagCheck) => {
      try {
        let selectedBuildingIdsList = [];
        if(flagCheck) {
          if(Array.isArray(formik?.values?.building)) {
            selectedBuildingIdsList = formik?.values?.building
          } else {
            selectedBuildingIdsList.push(formik?.values?.building)
          }
          const selectedBuildingHasAssumption = checkBuildingHasRecentAssumptions(selectedBuildingIdsList)
          if(selectedBuildingHasAssumption && selectedBuildingHasAssumption?.length > 0)  {
            
          setAssumptionModalReopen(selectedBuildingIdsList)
          //@ts-ignore
          consultationRef.current = {
            recentAssumptionValueId: selectedBuildingHasAssumption[0]?.recentAssumptionValueId
          }
          
            setShowRecentValueConfirmModal(true)
          } else {
            setShowAssumptions(true)
          }
        } else {
          setShowAssumptions(false)
        }
      } catch(error){
        setShowAssumptions(flagCheck)
      }
  }

  const hanldeKpi=(kpi)=>{
 setKpiData(kpi)
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) :
        (<Wrapper>
          {
            showAssumptions ?
              <AddValueAssumptions
                formValues={formik?.values}
                setFieldValue={formik?.setFieldValue}
                setShowAssumptions={setShowAssumptions}
                organizationName={formik?.values?.organizationName}
                buildingNames={selectedBuildingNames()}
                setAssumptionValuesToParent={setAssumptionValues}
                assumptionValuesFromParent={assumptionValues}
                hanldeKpi = {hanldeKpi}
                showLoader={consultationListLoading || loadingRecentValueAssumptions} /> :
              <>
                <ConsultationHeadSection
                  showAssumptionBtn={true}
                  values={formik?.values}
                  setFieldValue={formik?.setFieldValue}
                  buildingsList={buildingsSearchList}
                  handleFormSubmit={handleFormSubmit}
                  preCheckShowAssumptions={preCheckShowAssumptions}
                  errors={formik?.errors}
                  assumptionValues={assumptionValues}
                  isSubmit={isSubmit}
                  organizationId = {organizationId}
                />
                <hr />
                <ConsultationBodySection
                  values={formik?.values}
                  setFieldValue={formik?.setFieldValue}
                  handleFormSubmit={handleFormSubmit}
                  errors={formik?.errors}
                  isSubmit={isSubmit}
                  mode={mode}
                  kpiData={kpiData}
                  editKpiData={editKpiData}
                  assumptionValues={assumptionValues}
                  location={isArray(formik?.values?.building) ? formik?.values?.building[0] : null}
                  buildingsList={buildingsSearchList}
                />
                {showRecentValueConfirmModal && (
                  <Modal {...modalConfig}>
                    <ConfirmationText>
                      {getRecentAssumptionModalTitle()}
                    </ConfirmationText>
                  </Modal>
                )}

              </>
          }
        </Wrapper>)}
    </>
  )
}

export default Consultation
