import { useState, useCallback, useEffect } from 'react'
import MultiTabForm from '../MultiTabTorm'
import { TabsWrapper } from '../../styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import { WidgetOverview, WidgetOverviewSchema, WidgetBuildingSchema } from '../WidgetOverview'
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../graphql'
import { useMutation } from 'src/hooks/APIHooks'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import {
  MODAL_TYPE,
  MaxConstant,
  LATEST_VALUE,
  THRESHOLD_CONSTANT,
  TimePeriodCurrentDay,
  getGaugeWidgetUpsertMutationInputs,
  WIDGET_TABS_NAME
} from '../../helpers'
import { ACTIONS } from 'src/constants/actions'
import DataMonitoringGaugeColors, { GaugeColorsSchema } from './DataMonitoringGaugeColors'
import DataMonitoringGaugeConfiguration, { GaugeConfigSchema } from './DataMonitoringGaugeConfiguartion'
import { BASE_COLOR, THRESHOLD1_COLOR, THRESHOLD2_COLOR } from '../../colors'
import { DataMonitoringGaugePreviewWrapper } from './DataMonitoringGaugePreviewWrapper'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate from 'src/common/translations'

const tabsConfig = (errors) => [
  {
    title: WIDGET_TABS_NAME.OVERVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.OVERVIEW,
    formikErrors: errors,
    validationSchema: WidgetOverviewSchema,
    component: <WidgetOverview />
  },
  {
    title: WIDGET_TABS_NAME.CONFIGURATION,
    disabled: false,
    key: WIDGET_TABS_NAME.CONFIGURATION,
    formikErrors: errors,
    validationSchema: GaugeConfigSchema,
    component: <DataMonitoringGaugeConfiguration />
  },
  {
    title: WIDGET_TABS_NAME.COLORS,
    disabled: false,
    key: WIDGET_TABS_NAME.COLORS,
    formikErrors: errors,
    validationSchema: GaugeColorsSchema,
    component: <DataMonitoringGaugeColors />
  },
  {
    title: WIDGET_TABS_NAME.PREVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.PREVIEW,
    formikErrors: errors,
    component: <DataMonitoringGaugePreviewWrapper />
  }
]

const DataMonitoringGaugeWidgetForm = ({ setWidgetFormik }) => {
  const {
    widgetBuilding,
    setOpenWidgetPicker,
    userInfo,
    setReloadEvents,
    setIsLoading,
    mode,
    widgetDetails,
    setWidgetBuilding,
    setWidgetDetails,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setModalType,
    formikErrors,
    objectPickerSelectedValue,
    setIsFormValidationAlone,
    setIsSubmitted,
    isFormValidationAlone,
    setWidgetId,
    isFooterChecked
  } = useWidgetsContext()
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: [ACTIONS.ADD,ACTIONS.COPY].includes(mode) ? CREATE_WIDGET : UPDATE_WIDGET,
    refreshTokenBuildingVariable: "input.buildingId",
    onError: (error) => {
      setError(error)
      setIsLoading(false)
    },
    onSuccess: (success) => {
      if([ACTIONS.ADD].includes(mode))
        {
          trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Create", type: "Data Monitoring Gauge"})
        }
      else if([ACTIONS.COPY].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Copy", type: "Data Monitoring Gauge"})
      }
      else{
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Edit", type: "Data Monitoring Gauge"})
      }
    }
  })

  const submitForm = async (values) => {
    if(objectPickerSelectedValue?.length === 0 || !widgetBuilding || isFormValidationAlone) return
    setIsLoading(true)
    const inputs = getGaugeWidgetUpsertMutationInputs(
      values,
      userInfo,
      objectPickerSelectedValue,
      mode
    )
    await upsertWidgetMutation({ input: inputs }).then((response) => {
      const widgetId =
      [ACTIONS.ADD,ACTIONS.COPY].includes(mode)
          ? response.data?.createWidget?.id
          : response.data?.updateWidget?.id
      if (widgetId) {
        setIsLoading(false)
        setWidgetDetails(inputs)
        setOpenWidgetPicker(false)
        setReloadEvents(true)
        setModalType(MODAL_TYPE.SUCCESS)
        setSelectedWidget(null)
        setIsOpenCreateWidget(isFooterChecked ?? false)
        setWidgetId(widgetId)
      }
    })
  }
  const [activeTab, setActiveTab] = useState(0)
  const onSelectTab = useCallback((selectedTab: any, selectedTitle: any) => {
    setActiveTab(selectedTab)
    if (selectedTitle === WIDGET_TABS_NAME.PREVIEW) {
      setIsFormValidationAlone(true)
      setIsSubmitted(false)
    }
  }, [])
  const copyof = translate("Copy of")
  useEffect(() => {
    switch (mode) {
      case ACTIONS.ADD:
        setFormValues({
          id: '',
          name: '',
          description: '',
          link: '',
          isNewWindow: true,
          widgetBuilding:'',
          property:'',
          unitsOfMeasure:'',
          symbol: '',
          needleValue: LATEST_VALUE,
          timePeriod:TimePeriodCurrentDay,
          gaugeMin:'0',
          gaugeMax:MaxConstant,
          gaugeConstantValue:'',
          gaugeCalculatedValue:'',
          baselineColorValue: BASE_COLOR,
          thresholdsValue: THRESHOLD_CONSTANT,
          threshold1Value: THRESHOLD1_COLOR,
          threshold1ConstantValue:'0',
          threshold2Value: THRESHOLD2_COLOR,
          threshold2ConstantValue:'0'
        })
        break
      case ACTIONS.EDIT:
      case ACTIONS.COPY:
        setFormValues({
          id: mode === ACTIONS.EDIT ? widgetDetails?.id : '',
          name: mode === ACTIONS.EDIT ? widgetDetails?.name : `${copyof} ${widgetDetails?.name}`,
          description: widgetDetails?.description ?? '',
          link: widgetDetails?.widgetLink,
          isNewWindow: widgetDetails?.isNewWindow,
          property:widgetDetails?.equipmentDataMonitorGuageWidget?.property?.property,
          unitsOfMeasure:widgetDetails?.equipmentDataMonitorGuageWidget?.property?.uom,
          symbol:widgetDetails?.equipmentDataMonitorGuageWidget?.property?.symbol,
          needleValue: widgetDetails?.equipmentDataMonitorGuageWidget?.needleValue,
          timePeriod:widgetDetails?.equipmentDataMonitorGuageWidget?.timePeriod,
          gaugeMin:(widgetDetails?.equipmentDataMonitorGuageWidget?.guageMinimum)?.toString(),
          gaugeMax:widgetDetails?.equipmentDataMonitorGuageWidget?.guageMaximumType,
          gaugeConstantValue:widgetDetails?.equipmentDataMonitorGuageWidget?.guageMaximumValue,
          gaugeCalculatedValue:widgetDetails?.equipmentDataMonitorGuageWidget?.guageMaximumCalculationType,
          baselineColorValue: widgetDetails?.equipmentDataMonitorGuageWidget?.colourConfig?.baseline,
          thresholdsValue: widgetDetails?.equipmentDataMonitorGuageWidget?.colourConfig?.thresholdOption,
          threshold1Value: widgetDetails?.equipmentDataMonitorGuageWidget?.colourConfig?.threshold1,
          threshold1ConstantValue:widgetDetails?.equipmentDataMonitorGuageWidget?.colourConfig?.threshold1Value,
          threshold2Value: widgetDetails?.equipmentDataMonitorGuageWidget?.colourConfig?.threshold2,
          threshold2ConstantValue:widgetDetails?.equipmentDataMonitorGuageWidget?.colourConfig?.threshold2Value
        })
        setTimeout(() => {
          setWidgetBuilding({ id: widgetDetails?.buildingId })
        }, 100)
        break
    }
  }, [mode, copyof])

  const displayForm = () => {
    return mode !== ACTIONS.ADD ? formValues?.name : true
  }

  return (
    <>
      {displayForm() &&
        <MultiTabForm
          initialValues={formValues}
          onSubmit={submitForm}
          schemas={[WidgetOverviewSchema, WidgetBuildingSchema, GaugeConfigSchema, GaugeColorsSchema]}
          setWidgetFormik={setWidgetFormik}
        >
          <TabsWrapper>
            <Tabs
              defaultActiveTab={activeTab}
              activeTab={activeTab}
              onSelect={onSelectTab}
            >
              {tabsConfig(formikErrors)?.map((item: any, key: number) => {
                return (
                  <Tab
                    title={item.title}
                    disabled={item.disabled}
                    key={key}
                    formikErrors={item?.formikErrors}
                    validationSchema={item?.validationSchema}
                  >
                    {item.component}
                  </Tab>
                )
              })}
            </Tabs>
          </TabsWrapper>
          <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
        </MultiTabForm>
      }
    </>
  )
}

export default DataMonitoringGaugeWidgetForm
