import { createContext, useContext, useEffect, useState } from 'react'
import { selectDashboards } from 'src/redux/slicers/dashboard';
import { useSelector } from 'react-redux';
import { GET_PUBLIC_DASHBOARDS } from './graphql';
import { mutation } from '../../helper'
const DashboardContext = createContext<any>(null)
export const DashboardContextProvider = ({ children }: any) => {
  const [dashboardType, setDashboardType] = useState("1")
  const [modalType, setModalType] = useState<any>("")
  const {data: dashboards } = useSelector(selectDashboards)
  const dashboardLists = localStorage.getItem('dashboards')
  ? JSON.parse(localStorage.getItem('dashboards'))['dashboards']
  : dashboards
  const [publicDashboards, setPublicDashboards] = useState([])
  const [preConfigSelection, setPreConfigSelection] = useState({
    publicDashboardId: "",
    preConfiguredDashboardId: "",
    myDashboardId: "",
  })
 
  const findDashboardInfo = (dashboardId:string) => {
   return  dashboardLists.find((data:any) => data.name === dashboardId) || {}
  }
  const getPublicDashboards = async() => {
    try{
      const {data = {
        listDashboards:{
          items:[]
        }
      }}  = await mutation({
        query: GET_PUBLIC_DASHBOARDS
      })
      setPublicDashboards(data?.listDashboards?.items)
    }catch(e){
      setPublicDashboards([])
    }
  }
  useEffect(() => {
    getPublicDashboards()
  },[])
 
  return (
    <DashboardContext.Provider
      value={{
        setDashboardType, 
        dashboardType,
        modalType, 
        setModalType,
        preConfigSelection,
        setPreConfigSelection,
        findDashboardInfo,
        publicDashboards,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}
export const useDashboardContext = () => useContext(DashboardContext)
