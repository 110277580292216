import { PanelLabel } from '../../styles'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import Icon from 'src/denali-ui/components/Icon'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { useEffect, useState } from 'react'
import { useFormikContext } from '../MultiTabTorm'
import { TEXT_DATE_TIME_FORMAT } from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import { NO_IMAGE, WidgetNames } from '../../helpers'
import CONFIGURATION from 'src/config'
import { ParaName } from 'src/pages/dashboards/components/DashboardCardWidget/style'
import { ACTIONS } from 'src/constants/actions'
import { useAttachment } from 'src/hooks/attachment'
import translate, { TranslateComponent } from 'src/common/translations'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'

const PreviewWithData = () => {
  const { widgetBuildingDetails, selectedWidget, widgetBuilding, mode } =
    useWidgetsContext()
  const { downloadFileURL } = useAttachment()
  const formik = useFormikContext()
  const [buildingName, setBuildingName] = useState('')
  const [imgURL, setImgURL] = useState(null)
  const [imgTitle, setImgTitle] = useState('')
  const [imgLoading, setImgLoading] = useState(false)
  const [updatedDate, setUpdatedDate] = useState(null)
  const { values, setFieldValue } = formik
  useEffect(() => {
    if (widgetBuildingDetails) {
      handleWeatherWidgetContent()
    } else {
      setImgURL(null)
      setUpdatedDate(null)
      setImgTitle('')
      setBuildingName('')
    }
  }, [widgetBuildingDetails])

  useEffect(() => {
    try {
      (async () => {
        const image = values?.image ?? null
        if ([ACTIONS.EDIT,ACTIONS.COPY].includes(mode) && selectedWidget?.type === WidgetNames.ImageText) {
          if (!values?.buildingId || !image?.name || (image?.imageUrl && image?.imageUrl !== NO_IMAGE) || (image?.original && image?.original !== NO_IMAGE)) return
          setImgLoading(true)
          await downloadFileURL(
            image?.name,
            values?.buildingId
          ).then((res) => {
            setImgLoading(false)
            setTimeout(() => {
              setFieldValue('image', { ...image, imageUrl: res, original: res })
              setFieldValue('imageUrl', res ?? '')
            }, 50);
          })
        }
      })()
    } catch (error) {
      setImgLoading(false)
    }
  }, [])  

  const handleWeatherWidgetContent = () => {
    if (selectedWidget?.type === WidgetNames.Weather) {
      const { weatherInfo, state, city, postalCode } = widgetBuildingDetails
      setBuildingName(`${city}, ${state}, ${postalCode}`)
      if (weatherInfo) {
        const lDateFormatbyLocale = getDateFormatByLocale(TEXT_DATE_TIME_FORMAT) //'MMM D, YYYY h:mm A'
        const url = `${CONFIGURATION?.weatherIconS3Url}/${weatherInfo?.icon}`
        setImgURL(url)
        setImgTitle(weatherInfo?.description)
        setUpdatedDate(
          moment(
            weatherInfo?.weatherUpdatedAt,
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ).format(lDateFormatbyLocale ? lDateFormatbyLocale : TEXT_DATE_TIME_FORMAT)
        )
      }
    }
  }
  const getMetricValue = () => {
    const value = widgetBuildingDetails?.weatherInfo?.temperature_f
    return `${
      getMetricType() ? Math.round(value) : Math.round(((value - 32) * 5) / 9)
    }°`
  }
  const getMetricType = () => {
    return typeof values.metricConversion === 'boolean'
      ? values.metricConversion
      : values.metricConversion === '0'
      ? false
      : true
  }
  
  const showDescription = (widgetDescription) => {
    return widgetDescription?.split('').map((item, index) => (index % 20 === 0 && index !== 0 ? item : item)).join('');
  }
  const dataUpdated =translate("Data Updated")
  const imageTranslate = translate(imgTitle)
  const lDateFormatbyLocale = getDateFormatByLocale(TEXT_DATE_TIME_FORMAT) //'MMM D, YYYY h:mm A'

  return (
    <>
      <>
        <div className="preview-container">
          <div className="cell weather-widget">
            <figure className="widget">
              <figcaption className="widget-header">
                <ParaName>{values?.name}</ParaName>
              </figcaption>
              <div className="widget-content">
                {[WidgetNames.Weather].includes(selectedWidget?.type) && (
                  <>
                    <div className="container-building">
                      <span className="icon-location"></span>
                      <PanelLabel className="location-label">
                        {buildingName}
                      </PanelLabel>                  
                      <ConditionalTooltip
                        type={ConditionalTooltip.TYPE.ICON}
                        content= {<div>
                          <p style={{ whiteSpace: 'pre-wrap', width: 150 }}>{showDescription(values?.description)}</p>
                          <p>{`${dataUpdated} : ${moment().format(lDateFormatbyLocale ? lDateFormatbyLocale : TEXT_DATE_TIME_FORMAT)}`}</p> 
                        </div>}
                      >
                        <Icon
                          width="15px"
                          height="15px"
                          name="infocircle2"
                          color="#bfbfbf"
                        />
                      </ConditionalTooltip>
                    </div>
                    {widgetBuildingDetails?.weatherInfo && (
                      <>
                        <div className="container-image">
                          <span className="current-label"><TranslateComponent>CURRENTLY</TranslateComponent> :</span>
                          <div className="image-section">
                            <img
                              width={150}
                              height={150}
                              alt="preview"
                              src={imgURL}
                              title={imageTranslate}
                            />
                            <span className="metric-value">
                              {getMetricValue()}
                            </span>
                            <span className="metric">{`${
                              getMetricType() ? 'F' : 'C'
                            }`}</span>
                          </div>
                        </div>
                        <PanelLabel className="updated-label"><TranslateComponent>Updated </TranslateComponent><div>{` : ${updatedDate}`}</div></PanelLabel>
                      </>
                    )}
                  </>
                )}
                {[WidgetNames.ImageText].includes(selectedWidget?.type) && (
                  <>
                    <div className="container-building">
                      <span className="icon-location"></span>
                      <PanelLabel className="location-label">
                        {widgetBuilding?.name}
                      </PanelLabel>
                      <ConditionalTooltip
                        type={ConditionalTooltip.TYPE.ICON}
                        content= {<div>
                          <p style={{ whiteSpace: 'pre-wrap', width: 150 }}>{showDescription(values?.description)}</p>
                          <p>{`${dataUpdated} : ${moment().format(lDateFormatbyLocale ? lDateFormatbyLocale : TEXT_DATE_TIME_FORMAT)}`}</p>                 
                        </div>}
                      >
                        <Icon
                          width="15px"
                          height="15px"
                          name="infocircle2"
                          color="#bfbfbf"
                        />
                      </ConditionalTooltip>
                    </div>
                    <PanelLabel>{values?.imageTitle}</PanelLabel>
                    {imgLoading && <span className="spinner" />}
                    {!imgLoading && values?.image?.imageUrl && (
                      <img
                        width={150}
                        height={150}
                        alt="preview"
                        src={values?.image?.imageUrl}
                      />
                    )}
                    <PanelLabel>{values?.imageDescription}</PanelLabel>
                  </>
                )}
              </div>
            </figure>
          </div>
        </div>
      </>
    </>
  )
}

export default PreviewWithData
