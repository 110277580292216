import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// import { useTranslation } from "react-i18next"
// import Search from "src/denali-ui/components/Search"
import Icon from '../../../components/Icon'
import pages from '../../pages.js'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import {
  GET_USER_BY_ID,
  SEARCH_SALESOFFICES,
  SEARCH_CONTRACTOR_COMPANIES,
  SEARCH_ACCOUNTS,
  SEARCH_BUILDINGS_SOLO,
  SEARCH_BUILDINGS,
  LIST_GROUPS,
  LIST_CUSTOM_USER_ACCESS_BY_USER
} from '../graphql/queries'
import { useMutation, useQuery } from '../../../hooks/APIHooks'
import Table from 'src/components/Table/clientSideTable.js'
import { USERTYPES } from '../components/userType'
import { getRoleNameMap } from '../components/newRolesAndDescriptions'
import { Button as ButtonNew, Card, Modal } from '@trane/trane-components/dist'
import { useNavigate, useParams } from 'react-router-dom'
import { parseUserData } from 'src/parseUserData'
import { NewStyles } from 'src/NewStyles'
import Select from 'src/denali-ui/components/Select'
import Option from 'src/denali-ui/components/Option'
import { setGlobalError } from 'src/redux/slicers/globalErrorSlice'
import { useAppDispatch } from 'src/redux/store'
import { ErrorText } from '../../documentsAndReports/styles'
import { AccessControl } from 'src/components/accessControl'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { updateUser } from '../graphql/mutations'
import translate, { TranslateComponent } from '../../../common/translations'

const CardSubTitleNew = styled.h2`
  color: #1e1e1e;
  font-size: 14px;
  line-height: 20px;
  padding-right: 21px;
  font-family: ArialMT;
  font-weight: Bold;
`

const CardBody = styled.div`
  padding-left: 20px;
`

const FieldText = styled.span`
  font-size: 14px;
`
const FieldLabel = styled.span`
  color: rgb(54, 54, 54);
  line-height: 22px;
  font-size: 14px;
  font-weight: bold;
`

const getOptions = (userAccess) =>
  [
    userAccess?.['ApplicationGroup'] ? 'Application Access' : null,
    userAccess?.['Building'] ? 'Building Access' : null,
    userAccess?.['Account'] ? 'Organization Access' : null,
    userAccess?.['SalesOffice'] ? 'Service Team Access' : null,
    userAccess?.['ContractorCompany'] ? 'Contractor Company Access' : null,
    userAccess?.['BuildingGroup'] ? 'Group Access' : null
  ].filter((x) => x !== null)

export const UserManagementDetails = ({ newUser }) => {
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState('')
  const [deactivateUserDialog, setDeactivateUserDialog] = useState(false)
  const [reactivateUserDialog, setReactivateUserDialog] = useState(false)
  const [userAsset, setUserAsset] = useState({ name: '', value: '' })
  const [userAccess, setUserAccess] = useState(null)
  const [isExtraRoles, setIsExtraRoles] = useState(false)
  const [rows, setRows] = useState({})
  const [loadedAccess, setLoadedAccess] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const { id: userId } = useParams()
  const [User, setUser] = useState(null)
  const dispatch = useAppDispatch()

  const { onSubmit: editUser } = useMutation({
    query: updateUser,
    dataPath: 'data',
    onSuccess: (data) => {
      // Need to have timeout due to delay in data actually being updated.
      setTimeout(() => {
        refetchUser({ id: userId })
      }, 500)
    }
  })

  const optionsTranslated = {
    name: translate('Name'),
    role: translate('Role'),
    application: translate('Application'),
    type: translate('Type')
  }

  const { data: UserToBeParsed, refetch: refetchUser } = useQuery({
    query: GET_USER_BY_ID,
    variables: { id: userId },
    errorPolicy: 'all',
    dataPath: 'data.getUser'
  })

  const { data: UserAccessData, refetch: fetchUserAccesses } = useQuery({
    query: LIST_CUSTOM_USER_ACCESS_BY_USER,
    variables: { id: userId },
    errorPolicy: 'all'
  })

  const { data: buildingData, refetch: refetchBuildings } = useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    errorPolicy: 'all'
  })

  const { data: salesOfficeData, refetch: refetchSalesOffice } = useQuery({
    query: SEARCH_SALESOFFICES,
    disableInitialLoad: true,
    errorPolicy: 'all'
  })

  const { data: contractorCompanyData, refetch: refetchContractorCompanies } =
    useQuery({
      query: SEARCH_CONTRACTOR_COMPANIES,
      disableInitialLoad: true,
      errorPolicy: 'all'
    })

  const { data: accountData, refetch: refetchAccounts } = useQuery({
    query: SEARCH_ACCOUNTS,
    disableInitialLoad: true,
    errorPolicy: 'all'
  })

  const { data: groupData, refetch: refetchGroups } = useQuery({
    query: LIST_GROUPS,
    disableInitialLoad: true,
    errorPolicy: 'all'
  })

  const { data: groupAppData, refetch: refetchAppGroups } = useQuery({
    query: LIST_GROUPS,
    disableInitialLoad: true,
    errorPolicy: 'all'
  })

  useEffect(() => {
    if (UserToBeParsed) {
      UserToBeParsed && setUser(parseUserData(UserToBeParsed))
    }
  }, [UserToBeParsed])

  useEffect(() => {
    if (!userAccess && UserAccessData) {
      try {
        const accessObj = JSON.parse(
          JSON.parse(UserAccessData.data.listCustomUserAccessByUser).body
        )
        const access = accessObj?.items.reduce((accum, curr) => {
          if (accum[curr.resType]) {
            if (accum[curr.resType][curr.resource]) {
              accum[curr.resType][curr.resource].roles.push({
                id: curr?.role?.id,
                name: curr?.role?.name,
                application: curr?.app?.name,
                type: curr?.role?.type
              })
            } else {
              accum[curr.resType][curr.resource] = {
                name: null,
                roles: [
                  {
                    id: curr?.role?.id,
                    name: curr?.role?.name,
                    application: curr?.app?.name,
                    type: curr?.role?.type
                  }
                ]
              }
            }
          } else {
            accum[curr.resType] = {
              [curr.resource]: {
                name: null,
                roles: [
                  {
                    id: curr?.role?.id,
                    name: curr?.role?.name,
                    application: curr?.app?.name,
                    type: curr?.role?.type
                  }
                ]
              }
            }
          }
          return accum
        }, {})
        setIsExtraRoles(Boolean(accessObj?.remainingCount > 0))
        setUserAccess(access)
        setSelectedFilter(
          access?.['ApplicationGroup']
            ? 'Application Access'
            : access?.['Building']
            ? 'Building Access'
            : access?.['Account']
            ? 'Organization Access'
            : access?.['SalesOffice']
            ? 'Service Team Access'
            : access?.['ContractorCompany']
            ? 'Contractor Company Access'
            : 'Group Access'
        )
      } catch (e) {
        dispatch(setGlobalError({ error: `${e}` }))
      }
    }
  }, [UserAccessData])

  useEffect(() => {
    const setRowsFunc = async () => {
      if (userAccess && Object.keys(userAccess)?.length) {
        if (
          selectedFilter === 'Building Access' &&
          userAccess?.['Building'] &&
          !buildingData
        ) {
          const clonedUserAccess = JSON.parse(JSON.stringify(userAccess))
          await refetchBuildings({
            limit: 1000,
            filter: {
              or: Object.keys(userAccess['Building']).map((e) => ({
                id: { eq: e }
              }))
            }
          }).then((e) => {
            const data = e?.data?.searchBuildings?.items
            const keys = Object.keys(clonedUserAccess['Building'])
            for (let i = 0; i < keys.length; i++) {
              const index = data?.length
                ? data.findIndex((x) => x.id === keys[i])
                : null
              clonedUserAccess['Building'][keys[i]].name =
                index !== null && index !== -1
                  ? e.data.searchBuildings.items[index].buildingName
                  : keys[i]
            }
          })
          const newRows = Object.keys(clonedUserAccess['Building']).map(
            (k) => ({
              name: clonedUserAccess['Building'][k].name,
              subData: clonedUserAccess['Building'][k].roles.map((e) => ({
                role: e.name,
                type: <TranslateComponent>{e.type}</TranslateComponent>,
                application: e.application
              }))
            })
          )
          setUserAccess(clonedUserAccess)
          setRows({ ...rows, 'Building Access': newRows })
        } else if (
          selectedFilter === 'Service Team Access' &&
          userAccess?.['SalesOffice'] &&
          !salesOfficeData
        ) {
          const clonedUserAccess = JSON.parse(JSON.stringify(userAccess))
          await refetchSalesOffice({
            limit: 1000,
            filter: {
              or: Object.keys(userAccess['SalesOffice']).map((e) => ({
                id: { eq: e }
              }))
            }
          }).then((e) => {
            const data = e?.data?.searchSalesOffices?.items
            console.log('clonedUserAccess :', data)

            const keys = Object.keys(clonedUserAccess['SalesOffice'])
            for (let i = 0; i < keys.length; i++) {
              const index = data?.length
                ? data.findIndex((x) => x.id === keys[i])
                : null

              const salesOffice =
                e?.data?.searchSalesOffices?.items[index] ?? {}

              const code = salesOffice.code ?? ''
              const district = salesOffice.district ?? ''
              const name =
                code +
                (code ? ', ' : '') +
                (salesOffice.name ?? '') +
                (district ? ', ' : '') +
                district

              clonedUserAccess['SalesOffice'][keys[i]].name =
                index !== null && index !== -1 ? name : keys[i]
            }
          })
          const newRows = Object.keys(clonedUserAccess['SalesOffice']).map(
            (k) => ({
              name: clonedUserAccess['SalesOffice'][k].name,
              subData: clonedUserAccess['SalesOffice'][k].roles.map((e) => ({
                role:e.name,
                type: <TranslateComponent>{e.type}</TranslateComponent>,
                application: e.application
              }))
            })
          )
          setUserAccess(clonedUserAccess)
          setRows({ ...rows, 'Service Team Access': newRows })
        } else if (
          selectedFilter === 'Contractor Company Access' &&
          userAccess?.['ContractorCompany'] &&
          !contractorCompanyData
        ) {
          const clonedUserAccess = JSON.parse(JSON.stringify(userAccess))
          await refetchContractorCompanies({
            limit: 1000,
            filter: {
              or: Object.keys(userAccess['ContractorCompany']).map((e) => ({
                id: { eq: e }
              }))
            }
          }).then((e) => {
            const data = e?.data?.searchContractorCompanies?.items
            const keys = Object.keys(clonedUserAccess['ContractorCompany'])
            for (let i = 0; i < keys.length; i++) {
              const index = data?.length
                ? data.findIndex((x) => x.id === keys[i])
                : null
              clonedUserAccess['ContractorCompany'][keys[i]].name =
                index !== null && index !== -1
                  ? e.data.searchContractorCompanies.items[index].name
                  : keys[i]
            }
          })
          const newRows = Object.keys(clonedUserAccess['SalesOffice']).map(
            (k) => {
              const salesOffice = clonedUserAccess['SalesOffice'][k] ?? {}

              const code = salesOffice.code ?? ''
              const name = code + code ? ' ' : '' + salesOffice.name ?? ''

              return {
                name,
                subData: salesOffice.roles.map((e) => ({
                  role: e.name,
                  type: <TranslateComponent>{e.type}</TranslateComponent>,
                  application: e.application
                }))
              }
            }
          )
          setUserAccess(clonedUserAccess)
          setRows({ ...rows, 'Contractor Company Access': newRows })
        } else if (
          selectedFilter === 'Organization Access' &&
          userAccess?.['Account'] &&
          !accountData
        ) {
          const clonedUserAccess = JSON.parse(JSON.stringify(userAccess))
          await refetchAccounts({
            limit: 1000,
            filter: {
              or: Object.keys(userAccess['Account']).map((e) => ({
                id: { eq: e }
              }))
            }
          }).then(async (e) => {
            const data = e?.data?.searchAccounts?.items
            const keys = Object.keys(clonedUserAccess['Account'])
            for (let i = 0; i < keys.length; i++) {
              const index = data?.length
                ? data.findIndex((x) => x.id === keys[i])
                : null
              if (index === null || index === -1) {
                await refetchBuildings({
                  filter: { accountId: { eq: keys[i] } },
                  limit: 1
                }).then((res) => {
                  clonedUserAccess['Account'][keys[i]].name = res?.data
                    ?.searchBuildings?.items?.[0]
                    ? res?.data?.searchBuildings?.items?.[0].accountName
                    : keys[i]
                })
              } else {
                clonedUserAccess['Account'][keys[i]].name =
                  index !== null && index !== -1
                    ? e.data.searchAccounts.items[index].name
                    : keys[i]
              }
            }
          })
          const newRows = Object.keys(clonedUserAccess['Account']).map((k) => ({
            name:clonedUserAccess['Account'][k].name,
            subData: clonedUserAccess['Account'][k].roles.map((e) => ({
              role: e.name,
              type: <TranslateComponent>{e.type}</TranslateComponent>,
              application: e.application
            }))
          }))
          setUserAccess(clonedUserAccess)
          setRows({ ...rows, 'Organization Access': newRows })
        } else if (
          selectedFilter === 'Group Access' &&
          userAccess?.['BuildingGroup'] &&
          !groupData
        ) {
          // Note: Groups do not have an id. primary key is the name.
          // BuildingGroup is hardcoded currently as it is the resource we care about
          const clonedUserAccess = JSON.parse(JSON.stringify(userAccess))
          await refetchGroups({
            limit: 1000,
            filter: {
              or: Object.keys(userAccess['BuildingGroup']).map((e) => ({
                name: { eq: e }
              }))
            }
          }).then((e) => {
            const data = e?.data?.listGroups?.items
            const keys = Object.keys(clonedUserAccess['BuildingGroup'])
            for (let i = 0; i < keys.length; i++) {
              const index = data?.length
                ? data.findIndex((x) => x.name === keys[i])
                : null
              clonedUserAccess['BuildingGroup'][keys[i]].name =
                index !== null && index !== -1
                  ? e.data.listGroups.items[index].name
                  : keys[i]
            }
          })
          const newRows = Object.keys(clonedUserAccess['BuildingGroup']).map(
            (k) => ({
              name:clonedUserAccess['BuildingGroup'][k].name,
              subData: clonedUserAccess['BuildingGroup'][k].roles.map((e) => ({
                role:e.name,
                type: <TranslateComponent>{e.type}</TranslateComponent>,
                application: e.application
              }))
            })
          )
          setUserAccess(clonedUserAccess)
          setRows({ ...rows, 'Group Access': newRows })
        } else if (
          selectedFilter === 'Application Access' &&
          userAccess?.['ApplicationGroup'] &&
          !groupAppData
        ) {
          // Note: Groups do not have an id. primary key is the name.
          // ApplicationGroup is hardcoded currently as it is the resource we care about
          const clonedUserAccess = JSON.parse(JSON.stringify(userAccess))
          await refetchAppGroups({
            limit: 1000,
            filter: {
              or: Object.keys(userAccess['ApplicationGroup']).map((e) => ({
                name: { eq: e }
              }))
            }
          }).then((e) => {
            const data = e?.data?.listGroups?.items
            const keys = Object.keys(clonedUserAccess['ApplicationGroup'])
            for (let i = 0; i < keys.length; i++) {
              const index = data?.length
                ? data.findIndex((x) => x.name === keys[i])
                : null
              clonedUserAccess['ApplicationGroup'][keys[i]].name =
                index !== null && index !== -1
                  ? e.data.listGroups.items[index].name
                  : keys[i]
            }
          })
          const newRows = Object.keys(clonedUserAccess['ApplicationGroup']).map(
            (k) => ({
              name: clonedUserAccess['ApplicationGroup'][k].name,
              subData: clonedUserAccess['ApplicationGroup'][k].roles.map(
                (e) => ({
                  role: e.name,
                  type: <TranslateComponent>{e.type}</TranslateComponent>,
                  application: e.application
                })
              )
            })
          )
          setUserAccess(clonedUserAccess)
          setRows({ ...rows, 'Application Access': newRows })
        }
      }
    }
    setRowsFunc()
  }, [userAccess, selectedFilter])

  const [tableOrgList, setTableOrgList] = useState([])
  const [filteredTableOrgList, setFilteredTableOrgList] = useState([])

  const standardHeadings = [
    {
      name: 'name',
      title: optionsTranslated.name,
      key: 'name',
      maxWidth: '120px'
    },
    {
      name: 'role',
      title: optionsTranslated.role,
      key: 'role',
      maxWidth: '120px',
      disabled: true
    },
    {
      name: 'application',
      title: optionsTranslated.application,
      key: 'application',
      maxWidth: '120px',
      disabled: true
    },
    {
      name: 'type',
      title: optionsTranslated.type,
      key: 'type',
      maxWidth: '120px',
      disabled: true
    },
    {
      name: 'padding',
      title: '',
      key: 'padding',
      maxWidth: '120px',
      disabled: true
    }
  ]
  return (
    <NewStyles>
      {deactivateUserDialog ? (
        <Modal
          destructive={true}
          header={<TranslateComponent>Deactivate User</TranslateComponent>}
          action={() => {
            editUser({
              input: {
                id: User?.id,
                isActive: 0
              }
            })
            setDeactivateUserDialog(false)
            trackEvent(USER_EVENTS.ADMIN_TOOLS.events.SAVE_USER, {
              mode: 'DEACTIVATE'
            })
          }}
          actionText={<TranslateComponent>Confirm</TranslateComponent>}
          secondaryAction={() => setDeactivateUserDialog(false)}
          secondaryActionText={<TranslateComponent>Close</TranslateComponent>}
          overlay={true}
        >
          <p><TranslateComponent>{`Are you sure you wish to deactivate ${User.firstName} ${User.lastName}'s account?`}</TranslateComponent></p>
        </Modal>
      ) : (
        ''
      )}
          
      {reactivateUserDialog ? (
        <Modal
          destructive={false}
          header={<TranslateComponent>Activate User</TranslateComponent>}
          action={() => {
            editUser({
              input: {
                id: User?.id,
                isActive: 1
              }
            })
            setReactivateUserDialog(false)
            trackEvent(USER_EVENTS.ADMIN_TOOLS.events.SAVE_USER, {
              mode: 'ACTIVATE'
            })
          }}
          actionText={<TranslateComponent>Confirm</TranslateComponent>}
          secondaryAction={() => setReactivateUserDialog(false)}
          secondaryActionText={<TranslateComponent>Close</TranslateComponent>}
          overlay={true}
        >
          <p><TranslateComponent>{`Are you sure you wish to activate ${User.firstName} ${User.lastName}'s account?`}</TranslateComponent></p>
        </Modal>
      ) : (
        ''
      )}
      <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
        {User && (
          <>
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%'
                }}
              >
                <span
                  style={{
                    fontSize: '21px',
                    fontWeight: 'bold'
                  }}
                >
                  {`${User.firstName} ${User.lastName}`}
                </span>
              </div>
            </Content>
            <Content
              xlColumn={4}
              lColumn={4}
              mColumn={4}
              sColumn={12}
              border="none"
            >
              <Card
                header={
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <TranslateComponent>Personal Info</TranslateComponent>
                    <AccessControl id="tc.pages.user-management.edit">
                      <a
                        onClick={() => {
                          navigate(`/user-management/edit/personal/${userId}`)
                          trackEvent(
                            USER_EVENTS.ADMIN_TOOLS.events.CLICK_EDIT_USER
                          )
                        }}
                      >
                        <Icon name="edit" color="#1E1E1E" />
                      </a>
                    </AccessControl>
                  </span>
                }
              >
                <CardBody>
                  <CardSubTitleNew><TranslateComponent>Email</TranslateComponent></CardSubTitleNew>
                  <div>
                    <FieldText>{User.email}</FieldText>
                  </div>
                  <br />
                  <CardSubTitleNew><TranslateComponent>Secondary Email</TranslateComponent></CardSubTitleNew>
                  <div>
                    <FieldText>
                      {User.secondaryEmail && User.secondaryEmail !== "" ? User.secondaryEmail : <TranslateComponent>Not set</TranslateComponent>}
                    </FieldText>
                  </div>
                  <br />
                  {/* <CardSubTitleNew>User Type</CardSubTitleNew>
                    <div>
                      {User.userType?.userType ? (
                        <FieldText>
                          {USERTYPES[User.userType.userType]}
                        </FieldText>
                      ) : (
                        <FieldText>No user type assigned</FieldText>
                      )}
                    </div>
                    <br /> */}
                  <CardSubTitleNew><TranslateComponent>{userAsset.name}</TranslateComponent></CardSubTitleNew>
                  <div>
                    <FieldText><TranslateComponent>{userAsset.value}</TranslateComponent></FieldText>
                  </div>
                  <br />
                  <div>
                    <AccessControl id="tc.pages.user-management.activate">
                      {User.isActive === 1 ? (
                        <ButtonNew
                          type="text"
                          size="small"
                          onClick={() => {
                            setDeactivateUserDialog(true)
                            trackEvent(
                              USER_EVENTS.ADMIN_TOOLS.events
                                .CLICK_DEACTIVATE_USER,
                              { location: 'User Screen' }
                            )
                          }}
                        >
                          {/* Needs some styling tweaks to Icon and button */}
                          {/* <Icon
                            name="inactivate-user"
                            padding="0px 8px 0px 0px"
                            color="#206FCF"
                          /> */}
                         <TranslateComponent>Deactivate User</TranslateComponent> 
                        </ButtonNew>
                      ) : (
                        <ButtonNew
                          type="text"
                          size="small"
                          onClick={() => {
                            setReactivateUserDialog(true)
                            trackEvent(
                              USER_EVENTS.ADMIN_TOOLS.events
                                .CLICK_DEACTIVATE_USER,
                              { location: 'User Screen' }
                            )
                          }}
                        >
                          {/* Needs some styling tweaks to Icon and button */}
                          {/* <Icon
                          name="inactivate-user"
                          padding="0px 8px 0px 0px"
                          color="#206FCF"
                        /> */}
                          <TranslateComponent>Activate User</TranslateComponent>
                        </ButtonNew>
                      )}
                    </AccessControl>
                  </div>
                  <br />
                </CardBody>
              </Card>
            </Content>
            <Content
              xlColumn={8}
              lColumn={8}
              mColumn={8}
              sColumn={12}
              border="none"
            >
              {
                <>
                  <span
                    style={{
                      display: 'inline-flex',
                      width: '100%',
                      'justify-content': 'space-between',
                      'align-items': 'baseline'
                    }}
                  >
                    <Select
                      onChange={(e) => setSelectedFilter(e.target.value)}
                      placeholder={<TranslateComponent>Default state</TranslateComponent>}
                      popoverConfig={{
                        strategy: 'fixed'
                      }}
                      value={<TranslateComponent>{selectedFilter}</TranslateComponent>}
                    >
                      {getOptions(userAccess).map((value) => (
                        <Option key={value} value={value}>
                          <TranslateComponent>{value}</TranslateComponent>
                        </Option>
                      ))}
                    </Select>
                    {isExtraRoles ? (
                      <div>
                        <ErrorText><TranslateComponent>
                          This user has extra access that is not viewable with
                          your access levels.
                        </TranslateComponent></ErrorText>
                      </div>
                    ) : (
                      ''
                    )}
                    <span
                      style={{
                        display: 'flex'
                      }}
                    >
                      {User.userType ? (
                        <AccessControl id="tc.pages.user-management.edit">
                          <a
                            onClick={() =>
                              navigate(
                                `/user-management/edit/locations/${userId}`
                              )
                            }
                          >
                            <Icon name="edit" color="#1E1E1E" />
                          </a>
                        </AccessControl>
                      ) : (
                        ''
                      )}
                    </span>
                  </span>
                  <Table
                    rows={rows?.[selectedFilter] || []}
                    header={standardHeadings}
                  />
                </>
              }
            </Content>
            {newUser && newUser === 'true' ? (
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                border="none"
              >
                {/* TODO: Replace the inline styles below with a shared header component */}
                <div
                  style={{
                    display: 'flex',
                    width: '100%'
                  }}
                >
                  <span>
                    <span style={{ margin: '0px 10px 0px 0px' }}>
                      {/* Need to review background color for text buttons */}
                      <ButtonNew
                        type="text"
                        size="default"
                        onClick={() =>
                          navigate(`/${pages['User Management'].href}`)
                        }
                      >
                        {/* We need an icon component in the new component library as this one looks off when vertically centered */}
                        <Icon name="left-caret" color="#206FCF" />
                        <span><TranslateComponent>Back to All Users</TranslateComponent></span>
                      </ButtonNew>
                    </span>

                    <ButtonNew
                      type="primary"
                      size="default"
                      onClick={() =>
                        navigate(`/${pages['User Management/add'].href}`)
                      }
                    >
                      <TranslateComponent>Create New User</TranslateComponent>
                    </ButtonNew>
                  </span>
                </div>
              </Content>
            ) : (
              ''
            )}
          </>
        )}
      </Container>
    </NewStyles>
  )
}

UserManagementDetails.propTypes = {}

export default UserManagementDetails
