// Buildings By Name
export const GET_BUILDINGS = /* GraphQL */ `
  query SearchBuildingsList(
    $filter: SearchableBuildingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchBuildings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        state
        address
        city
        name
        accountId
        salesOfficeId
        salesOfficeName
        accountName
        contractorCompanyId
        contractorCompanyName
        id
        postalCode
        country
        isActive
      }
    }
  }
`

export const SEARCH_SALESOFFICES = `
query SearchSalesOffices (
  $filter: SearchableSalesOfficeFilterInput
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: [SearchableSalesOfficeSortInput]
)
{
searchSalesOffices  (
filter: $filter      
limit: $limit
nextToken: $nextToken
from: $from
sort: $sort
) {
    nextToken
    total
    items {
      id
      name
      code
      buildings {
        items {
          id
          name
        }
      }
    }
  }
}
`

export const SEARCH_CONTRACTOR_COMPANIES = `
query SearchContractorCompanies (
  $filter: SearchableContractorCompanyFilterInput
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: [SearchableContractorCompanySortInput]
)
{
searchContractorCompanies  (
filter: $filter      
limit: $limit
nextToken: $nextToken
from: $from
sort: $sort
) {
    nextToken
    total
    items {
      id
      name
    }
  }
}
`

export const SEARCH_BUILDINGS = `query SEARCH_BUILDINGS(
  $filter: SearchableBuildingFilterInput
  $limit: Int
  $nextToken: String
  $sort: [SearchableBuildingSortInput]
) {
    searchBuildings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        state
        address
        city
        name
        accountId
        accountName
        salesOfficeId
        salesOfficeName
        contractorCompanyId
        contractorCompanyName
        id
        postalCode
        country
        isActive
        groupUser
        groupAdmin
      }
      nextToken
      total
    }
  }`

export const ADD_SALES_OFFICE = /* GraphQL */ `
    mutation createSalesOffice($input: CreateSalesOfficeInput!) {
      createSalesOffice(input: $input) {
            id
        }
  } 
`

export const DELETE_SALES_OFFICE = /* GraphQL */ `
    mutation deleteSalesOffice($input: DeleteSalesOfficeInput!) {
      deleteSalesOffice(input: $input) {
            id
        }
  } 
`

export const DELETE_CONTRACTOR_COMPANY = /* GraphQL */ `
    mutation deleteContractorCompany($input: DeleteContractorCompanyInput!) {
      deleteContractorCompany(input: $input) {
            id
        }
  } 
`

export const ADD_CONTRACTOR_COMPANY = /* GraphQL */ `
    mutation createContractorCompany($input: CreateContractorCompanyInput!) {
      createContractorCompany(input: $input) {
            id
        }
  } 
`

export const UPDATE_CONTRACTOR_COMPANY = /* GraphQL */ `
    mutation updateContractorCompany($input: UpdateContractorCompanyInput!) {
      updateContractorCompany(input: $input) {
            id
        }
  } 
`

export const UPDATE_SALES_OFFICE = /* GraphQL */ `
    mutation updateSalesOffice($input: UpdateSalesOfficeInput!) {
      updateSalesOffice(input: $input) {
            id
        }
  } 
`

export const UPDATE_BUILDING = /* GraphQL */ `
    mutation updateBuilding($input: UpdateBuildingInput!) {
        updateBuilding(input: $input) {
            id
        }
  } 
`

export const GET_SALES_OFFICE_BY_ID = `
  query GET_SALES_OFFICE_BY_ID($id: ID!) {
    getSalesOffice(id: $id) {
      id
      code
      type
      name
      district
    }
  }
`

export const GET_CONTRACTOR_COMPANY_BY_ID = `
  query GET_CONTRACTOR_COMPANY_BY_ID($id: ID!) {
    getContractorCompany(id: $id) {
      id
      name
      type
    }
  }
`