import { createContext, useContext, useState } from 'react'
import { IModalActionType } from 'src/constants'
import { getSearchParams } from "src/common/helperFunctions.js"

const EnergyCostSummaryContext = createContext<any>(null)

export const EnergyCostSummaryContextProvider = ({ children }: any) => {
  const {buildingId, organizationId: accountId} = getSearchParams()
  const [mode, setMode] = useState(null)
  const [reloadEvents, setReloadEvents] = useState(false)
  const [isBaseLineAdding, setIsBaseLineAdding] = useState(false)
  const [modalType, setModalType] = useState<IModalActionType>('')

  return (
    <EnergyCostSummaryContext.Provider
      value={{
        mode,
        setMode,
        reloadEvents,
        setReloadEvents,
        isBaseLineAdding,
        setIsBaseLineAdding,
        setModalType,
        modalType,
        buildingId,
        accountId
      }}
    >
      {children}
    </EnergyCostSummaryContext.Provider>
  )
}

export const useEnergyCostSummaryContext = () => useContext(EnergyCostSummaryContext)
