import { useEffect, useState } from 'react'
import TextInput from 'src/components/legacy/components/modal-text-input'
import {
  FormulaEditorWrapper,
  CalculatedPointsFormWrapper,
  UOMWrapper,
  SelectWrapper,
  Input
} from './styles'
import { FormulaEditor } from './formula-editor'
import translate from 'src/common/translations'
import Label from 'src/components/legacy/components/label/label'
import RadioButton from 'src/denali-ui/components/Radio'
import Select from 'src/components/legacy/components/select/select'
import ExpressionUtil from './CalculationPointUtil'

export const CalculatedPointsOverviewForm = ({
  formik,
  modifyObject,
  stagedObjects,
  unstageObjects,
  setIsFormulaValid,
  availableUniqueUOMs
}) => {
  const { setFieldValue, values, errors } = formik
  const [calcFormulaError, setCalcFormulaError] = useState('')
  const [uomOptionsState, setUOMOptionsState] = useState({
    uomOptions: [],
    selectedUOM: values?.uom || ''
  })

  const optionsTranslated = {
    calcPoint: translate('Calculated Point Name'),
    calcUnit: translate('Calculated Point Unit of Measure*')
  }

  useEffect(() => {
    if (modifyObject && Object.keys(modifyObject).length > 0) {
      handleUOMChange(modifyObject?.uom)
      updateUOMOptions(modifyObject?.properties || [], modifyObject?.uom)
    }
  }, [modifyObject])

  const updateUOMOptions = (properties, selectedUOM) => {
    // Keeping the prop list in form values, to identifiy the equipment id from property
    setFieldValue('propertiesList', properties)
    const options = properties.map((x) => x?.symbol || '') || []
    const uniqueOptions = [...new Set(options)]
    let currentSelectedUOM = ''
    let uomOptions = [
      ...uniqueOptions,
      ...uniqueOptions.map((option) => `${option} / sq ft`),
      ...uniqueOptions.map((option) => `${option} / sq m`)
    ]?.sort((a, b) => a?.localeCompare(b))
    const uomSelection = selectedUOM || uomOptions.selectedUOM
    const selectedUOMIndex = uomOptions.findIndex((x) => x === uomSelection)
    if (selectedUOMIndex === -1) {
      currentSelectedUOM = uomOptions[0]
    } else {
      currentSelectedUOM = uomOptions[selectedUOMIndex]
    }
    setFieldValue('uom', currentSelectedUOM)
    uomOptions = uomOptions.map((x) => {
      return { key: x, value: x }
    })
    setUOMOptionsState({ uomOptions, selectedUOM: currentSelectedUOM })
  }

  const onUpdateTokenInput = ({ properties, calcFormula }) => {
    if (
      !ExpressionUtil.validate(
        calcFormula,
        properties.map((x) => `${x.propertyName}_${x.identifier}`)
      )
    ) {
      setCalcFormulaError('Invalid Formula')
      setIsFormulaValid(false)
    } else {
      setCalcFormulaError('')
      setIsFormulaValid(true)
    }
    updateUOMOptions(properties, values?.uom)
    setFieldValue('formula', calcFormula)
    return calcFormula
  }

  const onBlurTokenInput = () => {}

  // Called when custom or predefined UOM radio button change
  const onChangeUOM = (event) => {
    if (event.target.name === 'custom-uom') {
      setFieldValue('uom', 'custom')
    } else {
      setFieldValue('uom', 'uom')
    }
  }

  const handleUOMChange = (selectedItem) => {
    setFieldValue('uom', selectedItem)
    setUOMOptionsState({
      ...uomOptionsState,
      selectedUOM: selectedItem
    })
  }

  const handleInputChange = (name, value) => {
    setFieldValue(name, value)
  }

  return (
    <CalculatedPointsFormWrapper>
      <TextInput
        labelText={optionsTranslated.calcPoint}
        defaultValue={values?.name}
        name="name"
        onChange={({ target: { value, name } }) => {
          handleInputChange(name, value)
        }}
        hasError={errors?.['name']}
        isRequired={true}
        errorMessage={errors?.['name']}
        maxLength={30}
      />
      <FormulaEditorWrapper>
        <FormulaEditor
          hasValid={!errors?.['formula']}
          hasError={errors?.['formula'] || calcFormulaError !== ''}
          modifyObject={modifyObject}
          onChange={onUpdateTokenInput}
          onBlur={onBlurTokenInput}
          stagedObjects={stagedObjects}
          errorMessage={
            errors?.['formula'] ? errors?.['formula'] : calcFormulaError
          }
          unstageObjects={unstageObjects}
        />
      </FormulaEditorWrapper>
      <p className="calc-directions-text">
        Any numbers and the following symbols and words can be used to create
        your custom point formula:<span> + – * / ( ) π √ ^ % </span>You can move
        points in formulas by dragging them.
      </p>
      <Label text={optionsTranslated.calcUnit}></Label>
      <p className="calc-directions-text">
        Select a unit of measure or enter a custom unit of measure to be used
        for this calculated point.
      </p>
      <UOMWrapper>
        <RadioButton
          label=""
          labelPosition="right"
          className="equipment-radio"
          name="uom"
          onChange={onChangeUOM}
          checked={values?.uom !== 'custom'}
        />
        <SelectWrapper>
          <Select
            options={uomOptionsState.uomOptions}
            placeholder="--"
            className="dropdown-select"
            selectedItem={uomOptionsState.selectedUOM}
            disabled={
              values?.uom === 'custom' || availableUniqueUOMs?.length >= 4
            }
            onChange={handleUOMChange}
          />
        </SelectWrapper>
        <RadioButton
          label=""
          labelPosition="right"
          name="custom-uom"
          onChange={onChangeUOM}
          checked={values?.uom === 'custom'}
        />
        <Input
          type="text"
          value={values?.['customUOM']}
          name="customUOM"
          onChange={({ target: { value, name } }) => {
            handleInputChange(name, value)
          }}
          autoComplete="off"
          hasError={errors?.['customUOM']}
          isRequired={true}
          errorMessage={errors?.['customUOM']}
          maxLength={30}
          disabled={
            values?.uom !== 'custom' || availableUniqueUOMs?.length >= 4
          }
        />
      </UOMWrapper>
      {availableUniqueUOMs?.length >= 4 && values?.uom === 'custom' && (
        <p class="calc-uom-text">
          {' '}
          Only 4 UOMs are allowed, You can't create custom unit of measure{' '}
        </p>
      )}
      <p class="calc-tip-text">
        Tip: Calculated points are saved with the Building Data chart. They
        cannot be used across charts. If you want to re-use a calculated point,
        you will need to modify the chart and then use the “Save as New Chart”
        button.
      </p>
    </CalculatedPointsFormWrapper>
  )
}
