export enum M_DIRECTION {
  LEFT = 'Left',
  RIGHT = 'Right'
}

export class MouseDirection {
  private mouseDirection = M_DIRECTION.LEFT

  private lastX = 0

  private mousePosition(e: any) {
    if (e.pageX < this.lastX) {
      this.mouseDirection = M_DIRECTION.LEFT
    } else if (e.pageX > this.lastX) {
      this.mouseDirection = M_DIRECTION.RIGHT
    } else {
      this.mouseDirection = M_DIRECTION.LEFT
    }

    this.lastX = e.pageX
  }

  onMouseIn(e: any) {
    this.mousePosition(e)
    return this.mouseDirection
  }

  onMouseOut() {
    this.mouseDirection = M_DIRECTION.LEFT
    this.lastX = 0
  }
}
