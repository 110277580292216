import { genArr, Util } from '../helper'

export enum EQUIPMENT_AT_COND {
  CKT_CNT = 'cktCnt',
  CKT_INST = 'cktInst',
  CMP_CNT = 'cmpCnt',
  CMP_INST = 'cmpInst',
  CMP_TYPE = 'cmpType',
  CND_TYPE = 'cndType'
}

// =========================================================================================================

interface BaseProperties<T = any> {
  instance?: number | string | null
  instanceName?: string | null
  parentConditionName?: string | null
  conditionName: string
  type?: 'Circuit' | 'Compressor' | 'CompressorType' | 'CondenserType'
  getConditionDetails?: (condition: string) => T[]
}

interface Compressor extends BaseProperties<Compressor> {
  cktInst: Circuit[]
  cmpInst: Compressor[]
  cmpType: BaseProperties[]
  cndType: BaseProperties[]
}

interface Circuit extends BaseProperties<Circuit> {
  cktInst: Circuit
  cmpCnt: BaseProperties
  cmpInst: Compressor[]
  cmpType: BaseProperties[]
  cndType: BaseProperties[]
}

// =========================================================================================================

class EquipmentProperties extends Util<
  Circuit | Compressor | BaseProperties,
  EQUIPMENT_AT_COND
> {
  protected cktInstInfo = {}
  protected cmpInstInfo = {}
  protected cmpTypeInfo = {}
  protected cndTypeInfo = {}

  // Internal properties
  protected get cktInst(): Circuit[] {
    return Object.values(this.cktInstInfo)
  }

  protected get cmpInst(): Compressor[] {
    return Object.values(this.cmpInstInfo)
  }

  protected get cmpType(): BaseProperties<any>[] {
    return Object.values(this.cmpTypeInfo)
  }

  protected get cndType(): BaseProperties<any>[] {
    return Object.values(this.cndTypeInfo)
  }

  protected get cktCnt(): BaseProperties<any> {
    const len = this.circuits.length

    return {
      conditionName: len
        ? this.getKey(EQUIPMENT_AT_COND.CKT_CNT, this.circuits.length)
        : ''
    }
  }

  protected get cktTotalCount(): number {
    return this.circuits.length
  }

  protected get isMultipleCircuit(): boolean {
    return this.cktTotalCount > 1
  }

  protected get isMultipleCompressor(): boolean {
    return this.cmpCnt > 1
  }

  protected get hasCircuits(): boolean {
    return !!this.cktTotalCount
  }

  protected get hasCompressors(): boolean {
    return !!this.cmpCnt
  }

  protected get cmpCnt(): number {
    return this.compressors.length
  }

  protected get isSingleCircuitWithSingleCompressor(): boolean {
    return this.cktTotalCount === 1 && this.compressors.length === 1
  }

  // public apis
  public get circuits(): Circuit[] {
    return Object.values(this.cktInstInfo)
  }

  public get compressors(): Compressor[] {
    return Object.values(this.cmpInstInfo)
  }

  public get compressorType(): BaseProperties<any>[] {
    return Object.values(this.cmpTypeInfo)
  }

  public get condenserType(): BaseProperties<any>[] {
    return Object.values(this.cndTypeInfo)
  }

  public get equipment(): any {
    const _self = this as EquipmentProperties
    return {
      isMultipleCircuit: this.isMultipleCircuit,
      isMultipleCompressor: this.isMultipleCompressor,
      isSingleCircuitWithSingleCompressor:
        this.isSingleCircuitWithSingleCompressor,
      circuitCount: this.cktTotalCount,
      compressorCount: this.cmpCnt,
      hasCircuits: this.hasCircuits,
      hasCompressors: this.hasCompressors,
      getConditionDetails: (key) => _self.findByCond(_self)(key)
    }
  }
}

class EquipmentCharacteristicsModel extends EquipmentProperties {
  private getCompByCircuit(key: string) {
    return Object.values(this.cmpInstInfo).filter(
      (cmp: Compressor) => cmp.parentConditionName === key
    ) as Compressor[]
  }

  protected circuitComponentModel(index): Circuit {
    const _self = this as EquipmentCharacteristicsModel

    const key = _self.getKey(EQUIPMENT_AT_COND.CKT_INST, index)

    // Circuit has Circuit specific details and it's compressor details
    const circuit: Circuit = {
      instance: index,

      get instanceName() {
        return _self.isMultipleCircuit ? index || '' : ''
      },

      parentConditionName: null,

      // conditionName: key,

      get conditionName(): string {
        return _self.isSingleCircuitWithSingleCompressor
          ? _self.getKey(EQUIPMENT_AT_COND.CKT_INST, '0')
          : key
      },

      type: 'Circuit',

      get cktInst() {
        return _self.cktInstInfo[key]
      },

      // Available instances may belong to multiple Circuit's, So return instances belong to a specific CKT
      get cmpCnt() {
        const len = _self.getCompByCircuit(key).length

        return {
          conditionName: len ? _self.getKey(EQUIPMENT_AT_COND.CMP_CNT, len) : ''
        }
      },

      get cmpInst() {
        // Available instances may belong to multiple Circuit's, So return instances belong to a specific CKT
        return _self.getCompByCircuit(key)
      },

      get cmpType() {
        return _self.cmpType
      },

      get cndType() {
        return _self.cndType
      }
    }

    return Object.assign(circuit, {
      getConditionDetails: _self.findByCond(circuit)
    })
  }

  // Compressor has compressor specific details and it's parent details
  protected compressorComponentModel(cktInst, cmpInst): Compressor {
    const _self = this as EquipmentCharacteristicsModel

    const cktKey = _self.getKey(EQUIPMENT_AT_COND.CKT_INST, cktInst)

    const key = _self.getKey(EQUIPMENT_AT_COND.CMP_INST, cmpInst)

    const compressor: Compressor = {
      get instance(): string {
        return _self.isSingleCircuitWithSingleCompressor ? '1' : cmpInst
      },

      get instanceName(): string {
        return _self.isSingleCircuitWithSingleCompressor
          ? ''
          : _self.isMultipleCompressor
          ? cmpInst || ''
          : ''
      },

      get conditionName(): string {
        return _self.isSingleCircuitWithSingleCompressor
          ? _self.getKey(EQUIPMENT_AT_COND.CMP_INST, 1)
          : key
      },

      parentConditionName: cktKey,

      type: 'Compressor',

      get cmpInst(): any {
        return _self.cmpInstInfo[key]
      },

      get cktInst(): any {
        return _self.cktInstInfo[cktKey]
      },

      get cmpType(): any[] {
        return _self.cmpType
      },

      get cndType(): any[] {
        return _self.cndType
      }
    }

    return Object.assign(compressor, {
      getConditionDetails: _self.findByCond(compressor)
    })
  }

  // Condenser Type belongs to Equipment level, so all of Equipment level properties will be avaiable
  protected condenserTypeModel(condType: string): BaseProperties {
    const _self = this as EquipmentCharacteristicsModel

    const key = _self.getKey(EQUIPMENT_AT_COND.CND_TYPE, condType)

    const conderserType: BaseProperties = {
      instance: condType,

      instanceName: condType || '',

      parentConditionName: null,

      conditionName: key,

      type: 'CondenserType'
    }

    return Object.assign(conderserType, {
      getConditionDetails: _self.findByCond(_self)
    })
  }

  // Compressor Type belongs to Equipment level, so all of Equipment level properties will be avaiable
  protected compressorTypeModel(cmpType: string): BaseProperties {
    const _self = this as EquipmentCharacteristicsModel

    const key = _self.getKey(EQUIPMENT_AT_COND.CMP_TYPE, cmpType)

    const compressorType: BaseProperties = {
      instance: cmpType,

      instanceName: cmpType,

      parentConditionName: null,

      conditionName: key,

      type: 'CompressorType'
    }

    return Object.assign(compressorType, {
      getConditionDetails: _self.findByCond(_self)
    })
  }
}

// =========================================================================================================

/**
 * EquipmentDetails
 *
 * NOTE : THIS CLASS USES GETTER OFTEN TO CREATE BI-DIRECTIONAL RELATIONSHIP BETWEEN PROPERTIES.
 *
 * This class does below process
 *
 *  - binds given input details
 *  - process the details further to generate keys
 *  - On demand (during access of a property) it calculates and returns output
 *
 *
 *
 * Structure :
 *    - EquipmentDetails  : Initalize the input details by creating model for each input and stores
 *    - EquipmentCharacteristicsModel : Contains model
 *    - EquipmentProperties           : Contains both internal and public properties which is being used across the class
 *    - Util                        : Utility methods
 *
 */
export class EquipmentDetails extends EquipmentCharacteristicsModel {
  static INSTANCE_NAMES = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G']

  constructor(details) {
    super()
    this.init(details)
  }

  private init({
    circuitCount = 0,
    compressorCount = {},
    condenserType = '',
    compressorType = ''
  }) {
    this.setCircuit(circuitCount)

    Object.keys(compressorCount).forEach((key) => {
      const compCount = compressorCount[key] ?? 0
      const ckt = +key.replace('CompressorCountCKT', '')

      this.setCompressors(compCount, ckt)
    })

    this.setCondenserType(condenserType)

    this.setCompressorType(compressorType)
  }

  private setCircuit(count: number) {
    genArr(count, (index) => {
      const key = this.getKey(EQUIPMENT_AT_COND.CKT_INST, index)

      if (!this.cktInstInfo[key]) {
        this.cktInstInfo[key] = this.circuitComponentModel(index)
      }
    })
  }

  private setCompressors(count: number, ckt: number) {
    genArr(count, (i) => this.setCompressor(ckt, i))
  }

  private setCompressor(cktInst: number, cmpInst: number | string) {
    const instance = cktInst + EquipmentDetails.INSTANCE_NAMES[cmpInst]
    const key = this.getKey(EQUIPMENT_AT_COND.CMP_INST, instance)

    if (!this.cmpInstInfo[key]) {
      this.cmpInstInfo[key] = this.compressorComponentModel(cktInst, instance)
    }
  }

  private setCondenserType(condType: string) {
    const key = this.getKey(EQUIPMENT_AT_COND.CND_TYPE, condType)

    if (!this.cndTypeInfo[key] && condType) {
      this.cndTypeInfo[key] = this.condenserTypeModel(condType)
    }
  }

  private setCompressorType(cmpType: string) {
    const key = this.getKey(EQUIPMENT_AT_COND.CMP_TYPE, cmpType)

    if (!this.cmpTypeInfo[key] && cmpType) {
      this.cmpTypeInfo[key] = this.compressorTypeModel(cmpType)
    }
  }
}
