import { useWidgetsContext } from '../WidgetsContextProvider'
import CustomKPI from './customKPIWidget/CustomKPI'
import DataMonitoringGaugeWidget from './dataMonitoringGauge/DataMonitoringGaugeWidget'
import ImageTextWidget from './imageTextWidget/ImageTextWidget'
import WeatherWidget from './weatherWidget/WeatherWidget'
import TrendLineChart from './trendLineChartWidget/TrendLineChart'
import TrendBarChart from './trendBarChartWidget/TrendBarChart'
import TimeComarisonBarChart from './timeComparisonBarChartWidget/TimeComparisonBarChart'
import DataComparisonBarChart from './dataComparisonBarChartWidget/DataComparisonBarChart'
import DataMonitoringOrbWidget from './dataMonitoringOrb/DataMonitoringOrbWidget'
import BaseLineComparison from './baseLineComparison/BaseLineComparison'
import EnergyConsumption from './energyWidgets/consumption/EnergyConsumption'
import EnergyCost from './energyWidgets/cost/EnergyCost'
import EnergyDemand from './energyWidgets/demand/EnergyDemand'
import { WIDGET_TYPES } from 'src/constants'


const { 
  WEATHER,
  IMAGE_TEXT,
  CUSTOM_KPI,
  LINE,
  GAUGE,
  BAR,
  SINGLE_COMPARISON_BAR,
  DUAL_COMPARISON_BAR,
  ORB,
  ENERGY_CONSUMPTION,
  ENERGY_CONSUMPTION_NEW,
  ENERGY_COST,
  ENERGY_DEMAND,
  ENERGY_STAR_SCORE
} = WIDGET_TYPES

const componentMap = {
  [IMAGE_TEXT]: ImageTextWidget,
  [WEATHER]: WeatherWidget,
  [CUSTOM_KPI]: CustomKPI,
  [LINE]: TrendLineChart,
  [BAR]: TrendBarChart,
  [SINGLE_COMPARISON_BAR]: TimeComarisonBarChart,
  [DUAL_COMPARISON_BAR]: DataComparisonBarChart,
  [GAUGE]: DataMonitoringGaugeWidget,
  [ORB]: DataMonitoringOrbWidget,
  [ENERGY_CONSUMPTION]: BaseLineComparison,
  [ENERGY_CONSUMPTION_NEW]: EnergyConsumption,
  [ENERGY_COST]: EnergyCost,
  [ENERGY_DEMAND]: EnergyDemand
}

const ComponentSelectForWidget = ({ setWidgetFormik }) => {
  const { selectedWidget } = useWidgetsContext()
  if (!componentMap[selectedWidget?.type]) {
    return null
  }

  const SelectedComponent = componentMap[selectedWidget?.type]

  return <SelectedComponent setWidgetFormik={setWidgetFormik} />
}

export default ComponentSelectForWidget
