import { AxisDiM } from '../Utils/defaults'

export const axisCacheModel = (): AxisDetail => ({
  type: 'axisBottom',
  x: null,
  y: null,
  gridIndex: 0,
  axis: {}
})

export const scaleCacheModel = (): ScaleDetail => ({
  type: null,
  min: null,
  max: null,
  data: [],
  fn: null
})

export const axisModel = (axis?: any): InputAxis => {
  return {
    gridIndex: 0,
    type: 'value',
    diM: AxisDiM.x,
    data: [],
    opposite: false,
    tickInside: false,
    ...axis
  }
}

export const gridModel = (grid?: any): InputGrid => {
  return {
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
    width: 'auto',
    height: 'auto',
    ...grid
  }
}
