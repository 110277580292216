import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Field, connect, Formik } from 'formik'
import _get from 'lodash/get'
import { DebounceInput } from 'src/components/legacy/components/debounce-input'
import Select from 'src/components/legacy/components/select/select'
import FilterableSelect from 'src/components/legacy/components/filterable-select/filterable-select'
import  SelectrixWrapper  from 'src/components/legacy/components/selectrix-wrapper/selectrix-wrapper'
import classNames from 'classnames'
import { getField } from 'src/components/legacy/common/helpers'
import _isEmpty from 'lodash/isEmpty'
import './editable-view.scss'
import translate, { TranslateComponent } from 'src/common/translations'

export const DASH_DASH_PLACEHOLDER = '--'

const ErrorMessage = ({ errorMessage }) => {
  return <div className="edit-view__error"><TranslateComponent>{errorMessage}</TranslateComponent></div>
}

class GroupComponent extends PureComponent {
  render() {
    const {
      className,
      disabled,
      isFocused,
      labelName,
      readOnly,
      showErrors,
      formik,
      component,
      validate,
      testName
    } = this.props
    const isDisabled = disabled && !readOnly
    const errorMessage =
      ((!formik.validateOnChange && formik.validateOnBlur && !isFocused) ||
        formik.validateOnChange) &&
      validate(formik)
    const classes = {
      'read-mode': readOnly,
      'edit-mode': !readOnly,
      'edit-view--disabled': isDisabled,
      'validation-error': showErrors && errorMessage
    }

    return (
      <div
        className={classNames(
          'edit-view edit-view--group-type',
          className,
          classes
        )}
      >
        {labelName !== null && (
          <label className="edit-view__label">
            <span className="edit-view__label-name"><TranslateComponent>{labelName}</TranslateComponent></span>
          </label>
        )}
        <div className="edit-view__fields">{component(this.props)}</div>
        {showErrors && <ErrorMessage errorMessage={errorMessage} />}
      </div>
    )
  }
}

class InputComponent extends PureComponent {
  static INPUT_TYPE = {
    TEXT: 'text',
    NUMBER: 'number'
  }

  /* Specific props. Other props described in EditableView component */
  static propTypes = {
    /** Postpones the call of onChange handler */
    debounceTimeout: PropTypes.number,

    /** Input type, e.g. "text", "number."*/
    inputType: PropTypes.oneOf(Object.values(InputComponent.INPUT_TYPE))
  }

  static defaultProps = {
    debounceTimeout: 300,
    inputType: InputComponent.INPUT_TYPE.TEXT
  }

  onChange = (event) => {
    // Converts value to number for convenience. As result formik.values[name] will contain valid number or empty string.
    // The empty string is not work well with Yup.number() scheme since it leads to validation type error
    // Consider to use Yup.string() instead Yup.number() with custom .test()
    const value =
      event.target.type === InputComponent.INPUT_TYPE.NUMBER &&
      event.target.value !== ''
        ? Number(event.target.value)
        : event.target.value

    this.props.onChange(value)
  }

  render() {
    const {
      style,
      className,
      disabled,
      debounceTimeout,
      isFocused,
      inputType,
      showErrorsOnUntouched,
      labelName,
      readOnly,
      showErrors,
      formik,
      name,
      value,
      onBlur,
      onFocus,
      maxLength,
      placeholder,
      testName
    } = this.props
    const isDisabled = disabled && !readOnly
    const errorMessage =
      ((!formik.validateOnChange && formik.validateOnBlur && !isFocused) ||
        formik.validateOnChange) &&
      (showErrorsOnUntouched || getField(formik.touched, name)) &&
      getField(formik.errors, name)
    const classes = {
      'read-mode': readOnly,
      'edit-mode': !readOnly,
      'edit-view--disabled': isDisabled,
      'validation-error': showErrors && errorMessage
    }

    return (
      <div
        className={classNames(
          'edit-view edit-view--type-input',
          className,
          classes
        )}
      >
        {labelName !== null && (
          <label className="edit-view__label" htmlFor={name}>
            <span className="edit-view__label-name">
              {typeof labelName === 'object' ? <>{labelName}</> : <TranslateComponent>{labelName}</TranslateComponent>}
            </span>
          </label>
        )}
        <div className="edit-view__value"><TranslateComponent>{value}</TranslateComponent></div>
        <div className="edit-view__field">
          <DebounceInput
            style={style}
            className="edit-view__input remove_arrow"
            debounceTimeout={debounceTimeout}
            disabled={isDisabled}
            id={name}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={this.onChange}
            onFocus={onFocus}
            type={inputType}
            value={value ?? ''} // `null` and `undefined` values not applicable here since component can be switched from controlled to uncontrolled
            placeholder={placeholder}
            testName={testName}
          />
        </div>
        {showErrors && <ErrorMessage errorMessage={errorMessage} />}
      </div>
    )
  }
}

class SelectComponent extends React.PureComponent {
  static propTypes = {
    options: PropTypes.array
  }

  onChange = (newKey) => {
    const { options, value: oldValue, onChange } = this.props
    const newValue = options.find(({ key }) => key === newKey)?.value

    // prevents to call onChange handler when the new value is equal the old one
    if (oldValue !== newValue) {
      onChange(newKey)
    }
  }

  render() {
    const {
      className,
      disabled,
      isFocused,
      showErrorsOnUntouched,
      labelName,
      readOnly,
      options,
      placeholder,
      includePlaceholderOption,
      showErrors,
      formik,
      name,
      value,
      onBlur,
      onFocus,
      testName,
      isUserdefinedOptions = true
    } = this.props
    const isDisabled = disabled && !readOnly
    const errorMessage =
      ((!formik.validateOnChange && formik.validateOnBlur && !isFocused) ||
        formik.validateOnChange) &&
      (showErrorsOnUntouched || getField(formik.touched, name)) &&
      getField(formik.errors, name)
    const classes = {
      'read-mode': readOnly,
      'edit-mode': !readOnly,
      'edit-view--disabled': isDisabled,
      'validation-error': showErrors && errorMessage
    }

    return (
      <div
        className={classNames(
          'edit-view edit-view--type-select',
          className,
          classes
        )}
      >
        {labelName !== null && (
          <label className="edit-view__label" htmlFor={name}>
            <span className="edit-view__label-name">
            {typeof labelName === 'object' ? <>{labelName}</> : <TranslateComponent>{labelName}</TranslateComponent>}
            </span>
          </label>
        )}
        <div className="edit-view__value"><TranslateComponent>{value}</TranslateComponent></div>
        <div className="edit-view__field">
          <Select
            options={options}
            placeholder={placeholder}
            includePlaceholderOption={includePlaceholderOption}
            selectedItem={value}
            onChange={this.onChange}
            onClose={onBlur}
            onOpen={onFocus}
            isDisabled={isDisabled}
            testName={testName}
            isUserdefinedOptions={isUserdefinedOptions}
          />
        </div>
        {showErrors && <ErrorMessage errorMessage={
              getField(formik.touched, name)
                ? getField(formik.errors, name)
                : null
            } />}
      </div>
    )
  }
}

class FilterableSelectComponent extends React.PureComponent {
  static propTypes = {
    options: PropTypes.array,
    itemLabel: PropTypes.string,
    searchPlaceholder: PropTypes.string
  }

  render() {
    const {
      className,
      disabled,
      labelName,
      showErrorsOnUntouched,
      readOnly,
      options,
      selectedItem,
      searchPlaceholder,
      itemLabel,
      showErrors,
      formik,
      name,
      value,
      onBlur,
      onChange
    } = this.props
    const isDisabled = disabled && !readOnly
    const classes = {
      'read-mode': readOnly,
      'edit-mode': !readOnly,
      'edit-view--disabled': isDisabled,
      'validation-error':
        (showErrorsOnUntouched || getField(formik.touched, name)) &&
        getField(formik.errors, name)
    }

    return (
      <div
        className={classNames(
          'edit-view edit-view--type-filterable-select',
          className,
          classes
        )}
        onBlur={onBlur}
      >
        {labelName !== null && (
          <label className="edit-view__label" htmlFor={name}>
            <span className="edit-view__label-name"><TranslateComponent>{labelName}</TranslateComponent></span>
          </label>
        )}
        <div className="edit-view__value">
          {options &&
            getField(
              options.find(({ key }) => (selectedItem || value) === key),
              'value'
            )}
        </div>
        <div className="edit-view__field">
          <FilterableSelect
            disabled={isDisabled}
            options={options}
            selectedItem={selectedItem || value}
            onChange={onChange}
            searchPlaceholder={searchPlaceholder}
            itemLabel={itemLabel}
          />
        </div>
        {showErrors && (
          <ErrorMessage
            errorMessage={
              getField(formik.touched, name)
                ? getField(formik.errors, name)
                : null
            }
          />
        )}
      </div>
    )
  }
}

class SelectrixComponent extends React.PureComponent {
  static propTypes = {
    options: PropTypes.array
  }

  render() {
    const {
      className,
      disabled,
      labelName,
      showErrorsOnUntouched,
      readOnly,
      options,
      selectedItem,
      placeholder,
      inputPlaceholder,
      defaultValue,
      showErrors,
      formik,
      isFocused,
      name,
      value,
      onBlur,
      onChange,
      testName
    } = this.props
    const isDisabled = disabled && !readOnly
    const errorMessage =
      ((!formik.validateOnChange && formik.validateOnBlur && !isFocused) ||
        formik.validateOnChange) &&
      (showErrorsOnUntouched || getField(formik.touched, name)) &&
      getField(formik.errors, name)
    const classes = {
      'read-mode': readOnly,
      'edit-mode': !readOnly,
      'edit-view--disabled': isDisabled,
      'validation-error': showErrors && errorMessage
    }
    const handleSelectrixBlur = (e) => {
      if (e.relatedTarget !== null) {
        return
      }
      onBlur(e)
    }

    return (
      <div
        className={classNames(
          'edit-view edit-view--type-filterable-selectrix',
          className,
          classes
        )}
        onBlur={handleSelectrixBlur}
      >
        {labelName !== null && (
          <label className="edit-view__label" htmlFor={name}>
            <span className="edit-view__label-name"><TranslateComponent>{labelName}</TranslateComponent></span>
          </label>
        )}
        <div className="edit-view__value">
          {options &&
            getField(
              options.find(({ key }) => (selectedItem || value) === key),
              'value'
            )}
        </div>
        <div className="edit-view__field">
          <SelectrixWrapper
            className={className}
            disabled={isDisabled}
            options={options}
            onChange={onChange}
            defaultValue={defaultValue}
            searchable={true}
            searchBoxInside={true}
            placeholder={placeholder}
            inputPlaceholder={inputPlaceholder}
            testName={testName}
          />
        </div>
        {showErrors && <ErrorMessage  errorMessage={
              getField(formik.touched, name)
                ? getField(formik.errors, name)
                : null
            } />}
      </div>
    )
  }
}

class CustomComponent extends PureComponent {
  render() {
    const {
      className,
      disabled,
      isFocused,
      showErrorsOnUntouched,
      labelName,
      readOnly,
      component,
      showErrors,
      formik,
      name,
      value,
      inputId,
      hideValue
    } = this.props
    const isDisabled = disabled && !readOnly
    const errorMessage =
      ((!formik.validateOnChange && formik.validateOnBlur && !isFocused) ||
        formik.validateOnChange) &&
      (showErrorsOnUntouched || getField(formik.touched, name)) &&
      getField(formik.errors, name)
    const classes = {
      'read-mode': readOnly,
      'edit-mode': !readOnly,
      'edit-view--disabled': isDisabled,
      'validation-error': showErrors && errorMessage
    }
    return (
      <div
        className={classNames(
          'edit-view edit-view--type-custom',
          className,
          classes
        )}
      >
        {labelName !== null && (
          <label
            className="edit-view__label"
            {...(inputId && { htmlFor: inputId })}
          >
            <span className="edit-view__label-name"><TranslateComponent>{labelName}</TranslateComponent></span>
          </label>
        )}
        {!hideValue && <div className="edit-view__value">{value}</div>}
        <div className="edit-view__field">{component(this.props)}</div>
        {showErrors && <ErrorMessage errorMessage={errorMessage} />}
      </div>
    )
  }
}

class EditableViewComponent extends PureComponent {
  static COMPONENT_TYPE = {
    DEFAULT: 'default',
    INPUT: 'input',
    SELECT: 'select',
    FILTERABLE_SELECT: 'filterable_select',
    MULTI_FIELD: 'multi_field',
    GROUP: 'group',
    CUSTOM: 'custom',
    SELECTRIX: 'selectrix'
  }

  static INPUT_TYPE = InputComponent.INPUT_TYPE

  static propTypes = {
    /** Custom class names */
    className: PropTypes.string,

    /** Component to render inside */
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),

    /** Sets disabled state. Works only in edit mode*/
    disabled: PropTypes.bool,

    /** Input id */
    inputId: PropTypes.string,

    /** Label name */
    labelName: PropTypes.node,

    /** Input name */
    name: PropTypes.string,

    /** Switches between read-only and edit mode */
    readOnly: PropTypes.bool,

    /** Whether to show errors */
    showErrors: PropTypes.bool,

    /** Whether to show errors on the untouched field */
    showErrorsOnUntouched: PropTypes.bool,

    /** Type of EditableView */
    type: PropTypes.oneOf(Object.values(EditableViewComponent.COMPONENT_TYPE)),

    /** Input value */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object
    ]),

    /** Validate handler */
    validate: PropTypes.func,

    /** Default is true. Use this option to tell run validations on change events */
    validateOnChange: PropTypes.bool,

    /** Default is false. Run validation for the rendered input before they went changed, disable validation on a first change before a blur */
    validateOnRender: PropTypes.bool,

    /**data test id  name */
    testName: PropTypes.string
  }

  static defaultProps = {
    type: EditableViewComponent.COMPONENT_TYPE.DEFAULT,
    readOnly: false,
    disabled: false,
    labelName: null,
    showErrors: true,
    validate: () => {},
    allowOnChangeDirty: false,
    validateOnChange: true,
    validateOnRender: false,
    showErrorsOnUntouched: false
  }

  state = {
    isFocused: false
  }

  onFocus = (onFocusHandler) => () => {
    const { formik, name, validateOnRender, validateOnChange } = this.props
    if (
      !_isEmpty(formik) &&
      validateOnRender &&
      !validateOnChange &&
      _get(formik.touched, name)
    ) {
      formik.setFieldTouched(name, false)
    }

    this.setState({ isFocused: true })

    typeof onFocusHandler !== 'undefined' && onFocusHandler()
  }

  onBlur =
    (onBlurHandler = () => {}) =>
    (event) => {
      const { formik, name } = this.props
      const value = _get(formik.values, name)

      if (!_isEmpty(formik) && !_get(formik.touched, name)) {
        formik.setFieldTouched(name, true)
      }

      !_isEmpty(formik) && formik.setFieldValue(name, value)

      // formik.handleBlur is used to trigger field validation
      // if event is not provided validation is triggered manually for all form
      event ? formik.handleBlur(event) : formik.validateForm()

      this.setState({ isFocused: false })

      onBlurHandler(event, { name, value, formik })
    }

  onChange =
    (onChangeHandler = () => {}) =>
    (value) => {
      const { formik, name, validateOnChange, allowOnChangeDirty } = this.props

      if (formik.validateOnChange && validateOnChange) {
        if (!_isEmpty(formik) && !_get(formik.touched, name)) {
          formik.setFieldTouched(name, true)
        }
      }

      // prevents to call onChangeHandler
      // and running redundant validation
      // when the prev and next values are the same...with a little bypass logic for Data Mapping Array Property
      if (value === _get(formik.values, name) && !allowOnChangeDirty) {
        return
      }

      // sets field changed status when it has been changed by user
      formik.setStatus({
        ...(formik.status || {}),
        changed: {
          [name]: true
        }
      })

      !_isEmpty(formik) && formik.setFieldValue(name, value)

      onChangeHandler(value, formik)
    }

  multiFieldComponent = () => {
    const { className, labelName, readOnly, ...rest } = this.props
    const innerProps = { ...rest, readOnly }

    return (
      <div
        className={classNames('edit-view multi-field', className, {
          'read-mode': readOnly,
          'edit-mode': !readOnly
        })}
      >
        {labelName !== null && (
          <label className="edit-view__label">
            <span className="edit-view__label-name"><TranslateComponent>{labelName}</TranslateComponent></span>
          </label>
        )}
        <div className="edit-view__fields">
          <Field {...innerProps} />
        </div>
      </div>
    )
  }

  componentDidMount() {
    const { formik, name, validateOnRender, readOnly } = this.props
    if (
      !_isEmpty(formik) &&
      !readOnly &&
      validateOnRender &&
      !_get(formik.touched, name) &&
      _get(formik.values, name)
    ) {
      formik.setFieldTouched(name, true)
    }
  }

  render() {
    const {
      type,
      onBlur: onBlurHandler,
      onChange: onChangeHandler,
      onFocus: onFocusHandler,
      ...rest
    } = this.props
    const { isFocused } = this.state
    const props = {
      ...rest,
      isFocused,
      onFocus: this.onFocus(onFocusHandler),
      onBlur: this.onBlur(onBlurHandler),
      onChange: this.onChange(onChangeHandler)
    }

    switch (type) {
      case EditableViewComponent.COMPONENT_TYPE.DEFAULT: {
        return <CustomComponent {...props} />
      }
      case EditableViewComponent.COMPONENT_TYPE.INPUT: {
        return <InputComponent {...props} />
      }
      case EditableViewComponent.COMPONENT_TYPE.SELECT: {
        return <SelectComponent {...props} />
      }
      case EditableViewComponent.COMPONENT_TYPE.FILTERABLE_SELECT: {
        return <FilterableSelectComponent {...props} />
      }
      case EditableViewComponent.COMPONENT_TYPE.MULTI_FIELD: {
        return this.multiFieldComponent()
      }
      case EditableViewComponent.COMPONENT_TYPE.GROUP: {
        return <GroupComponent {...props} />
      }
      case EditableViewComponent.COMPONENT_TYPE.SELECTRIX: {
        return <SelectrixComponent {...props} />
      }
    }
  }
}

export const EditableView = connect(EditableViewComponent)
export const EditableViewWithFormik = (props) => (
  <Formik>{() => <EditableView {...props} />}</Formik>
)
export default EditableView
