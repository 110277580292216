import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useQuery } from 'src/hooks/APIHooks'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { pages } from 'src/pages/pages.js'
import { useMutation } from '../../../hooks/APIHooks'
import { ACTIONS } from 'src/constants'
import IconSvg from 'src/components/Icon'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import { Fees } from './fees'
import {
  FlexDivHeader,
  DateDivBox,
  DateLabel,
  ViewDateLabel,
  DateCheckbox,
  StyledTable,
  RateSectionLabelSubLabels,
  RateFormWrapper,
  RateFormLine,
  BoldUnitLabel,
  DollarInputSpanWrapper,
  RateNameStyledSpan,
  StyledTr
} from './styles.js'
import {
  CREATE_CHARGE,
  GET_RATE_STRUCTURE_BY_ID,
  UPDATE_CHARGE,
  GET_CHARGE_BY_ID
} from './queries'
import moment from 'moment'
import DateInput from 'src/components/legacy/components/datepicker-input'
import { DATE_FORMAT_DASH } from 'src/components/legacy/common/time-helpers.js'
import { AccessControl } from 'src/components/accessControl'
import validationSchema from './formValidator'
import Modal from 'src/components/legacy/components/modal/modal'
import { ConfirmationText } from 'src/components/layouts'
import { DELETE_CHARGE } from '../createCharge/queries'
import { setRateStructureSaleOfficeId } from 'src/redux/slicers/buildingPicker'
import { useDispatch } from 'react-redux'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

export const CreateCharge = () => {
  const { chargeId: id, rateStructureId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [chargeType, setChargeType] = useState(null)
  const [tOfUPeriods, setTOfUPeriods] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [promptDialog, setPromptDialog] = useState(null)

  const [mode, setMode] = useState(
    location?.state?.mode
      ? location?.state?.mode
      : id
      ? ACTIONS.VIEW
      : ACTIONS.ADD
  )

  const { data: rateStructureData, refetch: refetchRateStructure } = useQuery({
    query: GET_RATE_STRUCTURE_BY_ID,
    disableInitialLoad: true,
    dataPath: 'data.getRateStructure'
  })

  const { data: chargeData, refetch: refetchChargeData } = useQuery({
    query: GET_CHARGE_BY_ID,
    disableInitialLoad: true,
    dataPath: 'data.getCharge'
  })

  const { onSubmit: createCharge } = useMutation({
    query: CREATE_CHARGE,
    onSuccess: () => {
      navigate(pages['Rate Structure Library'].href, {
        state: {
          createdRateStructureChargeEntity: `${
            values.startDate ? values.startDate : 'Indefinite'
          } to ${values.endDate ? values.endDate : 'Indefinite'}`
        }
      })
    }
  })

  const { onSubmit: updateCharge } = useMutation({
    query: UPDATE_CHARGE,
    onSuccess: () => {
      navigate(pages['Rate Structure Library'].href, {
        state: {
          updatedRateStructureChargeEntity: `${
            values.startDate ? values.startDate : 'Indefinite'
          } to ${values.endDate ? values.endDate : 'Indefinite'}`
        }
      })
    }
  })

  const { onSubmit: deleteChargeeMutation } = useMutation({
    query: DELETE_CHARGE,
    onSuccess: async () => {
      setDeleteModal(false)
      navigate(pages['Rate Structure Library'].href, {
        state: {
          deletedRateStructureChargeEntity: `${
            values.startDate ? values.startDate : 'Indefinite'
          } to ${values.endDate ? values.endDate : 'Indefinite'}`
        }
      })
    }
  })

  const chargeInput = {
    startDate: null,
    endDate: null,
    fixedFees: [{ unit: null, rate: null, name: null }],
    variableFees: [{ unit: null, rate: null, name: null }],
    seasonRates: {}
  }

  const setSeasonsFunc = (res) => {
    const seasonsToUse = {}
    const listOfRates = []
    res.seasonalDefinitions.forEach((e) => {
      seasonsToUse[e.name] = {}
      if (res.isTimeSchedule) {
        if (e.weekdaySchedule) {
          const weekdayScheduleObj = JSON.parse(e.weekdaySchedule)
          Object.keys(weekdayScheduleObj).forEach((x) => {
            if (!seasonsToUse[e.name][weekdayScheduleObj[x]]) {
              seasonsToUse[e.name][weekdayScheduleObj[x]] = {}
              if (!listOfRates.includes(weekdayScheduleObj[x])) {
                listOfRates.push(weekdayScheduleObj[x])
              }
            }
          })
        }
        if (e.weekendSchedule) {
          const weekendScheduleObj = JSON.parse(e.weekendSchedule)
          Object.keys(weekendScheduleObj).forEach((x) => {
            if (!seasonsToUse[e.name][weekendScheduleObj[x]]) {
              seasonsToUse[e.name][weekendScheduleObj[x]] = {}
              if (!listOfRates.includes(weekendScheduleObj[x])) {
                listOfRates.push(weekendScheduleObj[x])
              }
            }
          })
        }
      } else {
        if (!listOfRates.includes('none')) {
          listOfRates.push('none')
        }
        seasonsToUse[e.name]['none'] = {}
      }
    })
    setChargeType(res.type)
    setTOfUPeriods(listOfRates)
    return seasonsToUse
  }

  useEffect(() => {
    const callFunc = async () => {
      await refetchRateStructure({ id: rateStructureId }).then((res) => {
        const seasonsToUse = setSeasonsFunc(res)
        formik.setFieldValue('seasonRates', seasonsToUse)
      })
    }
    if (rateStructureId && !rateStructureData) {
      callFunc()
    }
  }, [rateStructureId])

  useEffect(() => {
    if (chargeData && chargeData?.salesOfficeId) {
      dispatch(
        setRateStructureSaleOfficeId({
          type: 'Success',
          salesOfficeId: chargeData?.salesOfficeId
        })
      )
    }
  }, [chargeData])

  useEffect(() => {
    const callFunc = async () => {
      await refetchChargeData({ id }).then((res) => {
        const seasonsToUse = setSeasonsFunc(res?.rateStructure)
        res?.rates?.forEach((e, i) => {
          if (
            seasonsToUse[e?.season ? e.season : 'noSeasonalVariation'][
              e?.timeOfUseScheduleType ? e.timeOfUseScheduleType : 'none'
            ]
          ) {
            seasonsToUse[e?.season ? e.season : 'noSeasonalVariation'][
              e?.timeOfUseScheduleType ? e.timeOfUseScheduleType : 'none'
            ].consumptionPrice = e.consumptionPrice
            seasonsToUse[e?.season ? e.season : 'noSeasonalVariation'][
              e?.timeOfUseScheduleType ? e.timeOfUseScheduleType : 'none'
            ].demandMin = e.demandMin
            seasonsToUse[e?.season ? e.season : 'noSeasonalVariation'][
              e?.timeOfUseScheduleType ? e.timeOfUseScheduleType : 'none'
            ].demandPrice = e.demandPrice
          }
        })

        const variableFees = res.variableFees.map((e) => ({
          rate: e.rate,
          unit: e.rateType,
          name: e.name
        }))

        const fixedFees = res.fixedFees.map((e) => ({
          rate: e.amount,
          unit: e.amountType,
          name: e.name
        }))

        formik.setValues({
          startDate: res.startDate
            ? moment(res.startDate).format('MM-DD-YYYY')
            : null,
          endDate: res.endDate
            ? moment(res.endDate).format('MM-DD-YYYY')
            : null,
          fixedFees: fixedFees.length
            ? fixedFees
            : [{ unit: null, rate: null, name: null }],
          variableFees: variableFees.length
            ? variableFees
            : [{ unit: null, rate: null, name: null }],
          seasonRates: seasonsToUse
        })
      })
    }
    if (id && !chargeData) {
      callFunc()
    }
  }, [id])

  const formik = useFormik({
    initialValues: chargeInput,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: validationSchema(),
    onSubmit: async (values: any) => {
      if (mode === ACTIONS.ADD) {
        const input = {
          endDate: values.endDate
            ? moment(values.endDate, 'MM-DD-YYYY').format()
            : undefined,
          startDate: values.startDate
            ? moment(values.startDate, 'MM-DD-YYYY').format()
            : undefined,
          rateStructureId: rateStructureId
            ? rateStructureId
            : chargeData.rateStructureId,
          salesOfficeId: rateStructureData.salesOfficeId,
          variableFees: values.variableFees
            .filter((e) => e.name !== '' && e.rate !== null)
            .map((e) => ({ rate: e.rate, rateType: e.unit, name: e.name })),
          rates: Object.keys(values.seasonRates).reduce((accum, curr) => {
            const valuesToAdd = Object.keys(values.seasonRates[curr]).map(
              (e) => {
                return {
                  demandMin: values?.seasonRates?.[curr]?.[e]?.demandMin,
                  consumptionPrice:
                    values?.seasonRates?.[curr]?.[e]?.consumptionPrice,
                  demandPrice: values?.seasonRates?.[curr]?.[e]?.demandPrice,
                  season: curr === 'noSeasonalVariation' ? undefined : curr,
                  timeOfUseScheduleType: e === 'none' ? undefined : e
                }
              }
            )
            return accum.concat(valuesToAdd)
          }, []),
          fixedFees: values.fixedFees
            .filter((e) => e.name !== '' && e.rate !== null)
            .map((e) => ({ amount: e.rate, amountType: e.unit, name: e.name }))
        }
        if (
          input.rates.findIndex((e) => {
            return chargeType === 'electric'
              ? !e.demandPrice || !e.consumptionPrice
              : !e.consumptionPrice
          }) !== -1
        ) {
          setPromptDialog(input)
        } else {
          createCharge({ input })
        }
        trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.SAVE_CHARGES, {mode:"CREATE", "start date":input.startDate, "end date":input.endDate, id:input.rateStructureId})
      } else if (mode === ACTIONS.EDIT) {
        const input = {
          endDate: values.endDate
            ? moment(values.endDate, 'MM-DD-YYYY').format()
            : null,
          startDate: values.startDate
            ? moment(values.startDate, 'MM-DD-YYYY').format()
            : null,
          id,
          rateStructureId: rateStructureId
            ? rateStructureId
            : chargeData.rateStructureId,
          salesOfficeId: chargeData?.salesOfficeId,
          variableFees: values.variableFees.map((e) => ({
            rate: e.rate,
            rateType: e.unit,
            name: e.name
          })),
          rates: Object.keys(values.seasonRates).reduce((accum, curr) => {
            const valuesToAdd = Object.keys(values.seasonRates[curr]).map(
              (e) => {
                return {
                  demandMin: values?.seasonRates?.[curr]?.[e]?.demandMin,
                  consumptionPrice:
                    values?.seasonRates?.[curr]?.[e]?.consumptionPrice,
                  demandPrice: values?.seasonRates?.[curr]?.[e]?.demandPrice,
                  season: curr === 'noSeasonalVariation' ? undefined : curr,
                  timeOfUseScheduleType: e === 'none' ? undefined : e
                }
              }
            )
            return accum.concat(valuesToAdd)
          }, []),
          fixedFees: values.fixedFees.map((e) => ({
            amount: e.rate,
            amountType: e.unit,
            name: e.name
          }))
        }
        updateCharge({ input })
        trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.SAVE_CHARGES, {mode:"EDIT", "start date":input.startDate, "end date":input.endDate, id:input.rateStructureId})
      }
    }
  })
  const { values, errors } = formik
  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        {deleteModal && (
          <Modal
            heading="Delete Rate Structure Charge"
            buttons={[
              {
                text: 'Yes',
                handleClick: () => deleteChargeeMutation({ input: { id: id } }),
                type: 'valid'
              },
              {
                text: 'No',
                handleClick: () => setDeleteModal(false),
                type: 'cancel'
              }
            ]}
            handleClose={() => setDeleteModal(false)}
          >
            <ConfirmationText>
              {`Are you sure you would like to delete this charge for the time period of ${
                values.startDate ? values.startDate : 'Indefinite'
              } to ${values.endDate ? values.endDate : 'Indefinite'}?`}
            </ConfirmationText>
          </Modal>
        )}
        {promptDialog && (
          <Modal
            heading="Warning!"
            buttons={[
              {
                text: 'SAVE',
                handleClick: () => createCharge({ input: promptDialog }),
                type: 'valid'
              },
              {
                text: 'CONTINUE EDITING',
                handleClick: () => setPromptDialog(null),
                type: 'cancel'
              }
            ]}
            handleClose={() => setPromptDialog(null)}
          >
            <ConfirmationText>
              {`You have not filled in all the charges. All charges need to be filled in order to generate energy cost charts. If a cost is not applicable to this rate structure, please enter a 0.`}
              <br />
              <br />
              {`Are you sure you wish to proceed?`}
            </ConfirmationText>
          </Modal>
        )}
        <PageHeader
          pageTitle={
            <>
              <FlexDivHeader>
                Rate Period &nbsp;
                {rateStructureData?.name ? (
                  <RateNameStyledSpan>{`for ${rateStructureData.name}`}</RateNameStyledSpan>
                ) : chargeData?.rateStructure?.name ? (
                  <RateNameStyledSpan>{`for ${chargeData.rateStructure.name}`}</RateNameStyledSpan>
                ) : (
                  ''
                )}
                {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
                  <>
                    <DateDivBox>
                      <DateLabel>Start Date:</DateLabel>
                      <DateInput
                        date={values?.startDate ? values?.startDate : ''}
                        disabled={!values.startDate}
                        name="startDate"
                        labelText={'Start Date:'}
                        selectMonth={true}
                        format={DATE_FORMAT_DASH}
                        outerError={errors.startDate}
                        onChange={(date) => {
                          formik.setFieldValue(
                            'startDate',
                            moment(date, 'YYYY-MM-DD')
                              .startOf('month')
                              .format('MM-DD-YYYY')
                          )
                        }}
                      />
                      <DateCheckbox>
                        <Checkbox
                          label={'Indefinite'}
                          isChecked={!values.startDate}
                          id={'startDateIndefiniteBox'}
                          toggleCheck={() => {
                            if (values.startDate) {
                              formik.setFieldValue('startDate', null)
                            } else {
                              formik.setFieldValue(
                                'startDate',
                                moment().startOf('month').format('MM-DD-YYYY')
                              )
                            }
                          }}
                        />
                      </DateCheckbox>
                    </DateDivBox>
                    <DateDivBox>
                      <DateLabel>End Date:</DateLabel>
                      <DateInput
                        date={values?.endDate ? values?.endDate : ''}
                        disabled={!values.endDate}
                        name="endDate"
                        labelText={'Start Date:'}
                        selectMonth={true}
                        outerError={errors.startDate}
                        onChange={(date) => {
                          formik.setFieldValue(
                            'endDate',
                            moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY')
                          )
                        }}
                      />
                      <DateCheckbox>
                        <Checkbox
                          label={'Indefinite'}
                          isChecked={!values.endDate}
                          id={'endDateIndefiniteBox'}
                          toggleCheck={() => {
                            if (values.endDate) {
                              formik.setFieldValue('endDate', null)
                            } else {
                              formik.setFieldValue(
                                'endDate',
                                moment().endOf('month').format('MM-DD-YYYY')
                              )
                            }
                          }}
                        />
                      </DateCheckbox>
                    </DateDivBox>
                  </>
                ) : (
                  <ViewDateLabel>{`${
                    values.startDate ? values.startDate : 'INDEFINITE'
                  } - ${
                    values.endDate ? values.endDate : 'INDEFINITE'
                  }`}</ViewDateLabel>
                )}
              </FlexDivHeader>
              {
                <p className="error">
                  <>{errors.startDate && errors.startDate}</>
                </p>
              }
            </>
          }
          titleTools={
            [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
              <span>
                <Button type="primary" onClick={formik.handleSubmit}>
                  Save
                </Button>
                <Button
                  type="secondary"
                  onClick={() => {
                    navigate(pages['Rate Structure Library'].href)
                  }}
                >
                  Cancel
                </Button>
              </span>
            ) : (
              <span>
                <AccessControl id="tc.pages.rate-structure-library.charge.edit">
                  <IconSvg
                    name={'edit'}
                    margin="0px 0px 0px 10px"
                    color="#666"
                    hover="#666"
                    width="15px"
                    onClick={() => {
                      setMode(ACTIONS.EDIT)
                    }}
                  />
                </AccessControl>
                <AccessControl id="tc.pages.rate-structure-library.charge.edit">
                  <IconSvg
                    name={'trash2'}
                    margin="0px 0px 0px 10px"
                    color="#666"
                    hover="#666"
                    width="15px"
                    onClick={() => {
                      setDeleteModal(true)
                    }}
                  />
                </AccessControl>
              </span>
            )
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <h2>{`${chargeType === 'electric' ? 'Electricity' : 'Gas'} Rates`}</h2>
        <div>
          <StyledTable>
            <thead>
              <tr>
                <th />
                {Object.keys(values.seasonRates).map((e) => (
                  <th key={`head-${e}`}>
                    {e !== 'noSeasonalVariation' ? e : 'NO SEASONAL VARIATION'}
                  </th>
                ))}
              </tr>
            </thead>
            {/* {Object.keys(values.seasonRates).map(e => )} */}
            <tbody data-testid="rate-charge-periods">
              {tOfUPeriods.map((x, index) => (
                <StyledTr index={index} key={`rowhead-${x}`}>
                  <td>
                    {/* <RateSectionLabels> */}
                    <RateSectionLabelSubLabels touLabels={tOfUPeriods.length}>
                      {chargeType === 'electric' ? (
                        <>
                          <div className="firstLabelHeader">
                            <p>{x !== 'none' ? x : ''}</p>
                          </div>
                          <div />
                          <div>
                            <div className="firstLabel">
                              <p>Demand</p>
                            </div>
                            <div className="lastLabel">
                              <p>Consumption</p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </RateSectionLabelSubLabels>
                    {/* </RateSectionLabels> */}
                  </td>
                  {Object.keys(values.seasonRates).map((e) => (
                    <td key={`season-${x}-${e}`}>
                      {values.seasonRates[e][x] ? (
                        <RateFormWrapper>
                          <div>
                            {chargeType === 'electric' ? (
                              <RateFormLine>
                                <span>min</span>
                                &nbsp;
                                {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
                                  <input
                                    disabled={false}
                                    onChange={({ target: { value } }) => {
                                      const newSeasonRates = JSON.parse(
                                        JSON.stringify(values.seasonRates)
                                      )
                                      newSeasonRates[e][x].demandMin = value
                                      formik.setFieldValue(
                                        'seasonRates',
                                        newSeasonRates
                                      )
                                    }}
                                    type="number"
                                    value={
                                      values.seasonRates?.[e]?.[x]?.demandMin
                                    }
                                    style={{ width: '50px' }}
                                  />
                                ) : (
                                  values.seasonRates?.[e]?.[x]?.demandMin
                                )}
                                &nbsp;
                                <span>kW</span>
                                &nbsp; &nbsp;
                                {/* <InputFieldDollarSign style={{"position": "relative", "left":"15px"}}>$</span> */}
                                {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
                                  <DollarInputSpanWrapper>
                                    <input
                                      disabled={false}
                                      onChange={({ target: { value } }) => {
                                        const newSeasonRates = JSON.parse(
                                          JSON.stringify(values.seasonRates)
                                        )
                                        newSeasonRates[e][x].demandPrice = value
                                        formik.setFieldValue(
                                          'seasonRates',
                                          newSeasonRates
                                        )
                                      }}
                                      type="number"
                                      value={
                                        values.seasonRates?.[e]?.[x]
                                          ?.demandPrice
                                      }
                                    />
                                  </DollarInputSpanWrapper>
                                ) : values.seasonRates?.[e]?.[x]
                                    ?.demandPrice ? (
                                  `$${values.seasonRates?.[e]?.[x]?.demandPrice}`
                                ) : (
                                  'N/A'
                                )}
                                &nbsp;
                                <BoldUnitLabel>per kW</BoldUnitLabel>
                              </RateFormLine>
                            ) : (
                              <></>
                            )}
                            <RateFormLine bottom={true}>
                              {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
                                <DollarInputSpanWrapper>
                                  <input
                                    disabled={false}
                                    onChange={({ target: { value } }) => {
                                      const newSeasonRates = JSON.parse(
                                        JSON.stringify(values.seasonRates)
                                      )
                                      newSeasonRates[e][x].consumptionPrice =
                                        value
                                      formik.setFieldValue(
                                        'seasonRates',
                                        newSeasonRates
                                      )
                                    }}
                                    type="number"
                                    value={
                                      values.seasonRates?.[e]?.[x]
                                        ?.consumptionPrice
                                    }
                                    // style={{width: "40px"}}
                                  />{' '}
                                </DollarInputSpanWrapper>
                              ) : values.seasonRates?.[e]?.[x]
                                  ?.consumptionPrice ? (
                                `$${values.seasonRates?.[e]?.[x]?.consumptionPrice}`
                              ) : (
                                'N/A'
                              )}
                              &nbsp;
                              <BoldUnitLabel>
                                {chargeType === 'electric'
                                  ? 'per kWh'
                                  : 'mmBTU'}
                              </BoldUnitLabel>
                            </RateFormLine>
                          </div>
                        </RateFormWrapper>
                      ) : (
                        <RateFormWrapper key={`${e}${x}formWrapper`}>
                          <div>
                            <RateFormLine>N/A</RateFormLine>
                            <RateFormLine bottom={true}>
                              {`No ${x} time period for this rate structure.`}
                            </RateFormLine>
                          </div>
                        </RateFormWrapper>
                      )}
                    </td>
                  ))}
                </StyledTr>
              ))}
            </tbody>
          </StyledTable>
        </div>
        {
          <p className="error">
            <>
              {errors.seasons &&
                'Selected seasons must add up to one complete year, or, "No Seasonal Variation" must be selected.'}
            </>
          </p>
        }
        <br />
        {chargeType === 'electric' ? (
          <>
            <h2>Variable Riders & Fees</h2>
            {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
              <Fees
                type={'variable'}
                values={values.variableFees}
                error={errors.variableFees}
                setValue={(x, y) => formik.setFieldValue(x, y)}
              />
            ) : values.variableFees?.filter((e) => e.rate || e.unit || e.name)
                .length ? (
              <Fees
                mode="view"
                type={'variable'}
                values={values.variableFees?.filter(
                  (e) => e.rate || e.unit || e.name
                )}
                error={errors.demandRatchet}
                setValue={(x, y) => formik.setFieldValue(x, y)}
              />
            ) : (
              'No variable fees set.'
            )}
            {
              <p className="error">
                <>
                  {errors.variableFees &&
                    'Please enter all fields for variable fees.'}
                </>
              </p>
            }
          </>
        ) : (
          <></>
        )}
        <h2>Fixed Fees & Taxes</h2>
        {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? (
          <Fees
            type={'fixed'}
            values={values.fixedFees}
            error={errors.fixedFees}
            setValue={(x, y) => formik.setFieldValue(x, y)}
          />
        ) : values.fixedFees?.filter((e) => e.rate || e.unit || e.name)
            .length ? (
          <Fees
            mode="view"
            type={'fixed'}
            values={values.fixedFees?.filter((e) => e.rate || e.unit || e.name)}
            error={errors.demandRatchet}
            setValue={(x, y) => formik.setFieldValue(x, y)}
          />
        ) : (
          'No fixed fees set.'
        )}
        {
          <p className="error">
            <>{errors.fixedFees && 'Please enter all fields for fixed fees.'}</>
          </p>
        }
      </Content>
    </Container>
  )
}

export default CreateCharge
