import IssuesFindingsContainer from './IssuesFindingsContainer'
import { IssuesFindingsContextProvider } from './IssuesFindingsContextProvider'

export const IssuesFindings = () => {
  return (
    <IssuesFindingsContextProvider>
      <IssuesFindingsContainer />
    </IssuesFindingsContextProvider>
  )
}
