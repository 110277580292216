import { PDF_PAGE_TYPES } from 'src/components/legacy/common/pdf'
import PDFGenerator from 'src/components/print-components/pdf-generator'
import {PDFEnergyUsageReport} from './EnergyUsageReportPDF'

const EnergyUsageReportDownload = (props) => {
  const { downloadData, handleDownloadClick, downloadFileName } = props
  const chartImages = null
  return (
    <>
        <PDFGenerator
          fileName={`${downloadFileName} - ${downloadData?.title}`}
          pageType={PDF_PAGE_TYPES.CUSTOM_PAGE}
          report={downloadData}
          handleDownloadClick={handleDownloadClick}
        >
            <PDFEnergyUsageReport 
                isFooterCount={true}
                report={downloadData}
            />
        </PDFGenerator>
    </>
  )
}

export default EnergyUsageReportDownload
