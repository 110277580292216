import { NOTIFICATION_SUB_TYPE } from './constants'

/**
 * Check notification belongs to given type
 *
 * @param notification
 * @param types
 * @returns bool
 */
export function isValidNotificationType(
  notification: any,
  types = []
): boolean {
  return !!notification?.nSubType && types.includes(notification?.nSubType)
}

/**
 * Parse and Pick prop from body of Notification data
 *
 * @param notification
 * @param prop
 * @returns any | undefined
 */
export function valueFromBody(
  notification: any,
  prop: string
): any | undefined {
  const nBody = notification.nBody

  const isBodyContentAvaiable = typeof nBody === 'string' && !!nBody.length

  const body = isBodyContentAvaiable ? JSON.parse(nBody) : ({} as any)

  return body[prop]
}

/**
 * Form name from response
 *
 * @param value any
 * @returns string
 */
export function createName(value: any): string {
  const { firstName, lastName, email } = value as any

  const isFirstNameAvailable = (firstName || '').length > 0
  const isLastNameAvailable = (lastName || '').length > 0
  const isMailAvailable = (email || '').length > 0

  if (isFirstNameAvailable && isLastNameAvailable)
    return firstName + ' ' + lastName

  if (isFirstNameAvailable && !isLastNameAvailable) return firstName

  if (!isFirstNameAvailable && isLastNameAvailable) return lastName

  return isMailAvailable ? email : ''
}

/**
 * function adds type specific properties required for Template
 *
 * @param notification
 * @returns
 */
export function typeSpecificProperties(notification: any): any {
  const { nBody } = notification
  const subType = notification?.nSubType

  if (NOTIFICATION_SUB_TYPE.ISSUE === subType) {
    notification['actionLinkText'] = 'Finding'
    notification['actionLinkUrl'] = notification.issueId
      ? '/findings/' + notification.issueId
      : '#'
  } else if (NOTIFICATION_SUB_TYPE.OPPORTUNITY === subType) {
    notification['actionLinkText'] = 'Opportunity'
    notification['actionLinkUrl'] = notification.opportunityId
      ? '/opportunities/' + notification.opportunityId
      : '#'
  } else if (NOTIFICATION_SUB_TYPE.OFFERING_REQUEST === subType) {
    const {
      id
    } = nBody ? JSON.parse(nBody) : {} as any
    notification['actionLinkText'] = 'View Request Form'
    notification['actionLinkUrl'] = id
    ? '/contracts-and-offerings/' + id
    : '#'
  }

  return notification
}

/**
 * function to replace the Text by subType for Template
 *
 * @param subType
 * @param text
 * @param props
 */
export function replaceTextBySubType(subType, text = '', props) {
  if (
    NOTIFICATION_SUB_TYPE.ISSUE === subType ||
    NOTIFICATION_SUB_TYPE.OPPORTUNITY === subType
  ) {
    const {
      assigneeName = '',
      assignorName = '',
      issueName = '',
      opportunityName = ''
    } = props

    const isIssue = NOTIFICATION_SUB_TYPE.ISSUE === subType

    // Update assigne name
    text = text?.replace('{{assignee}}', assigneeName)

    // Update assignor name
    text = text?.replace('{{assignor}}', assignorName)

    // Update assignor name
    text = isIssue
      ? text?.replace('{{IssueName}}', issueName)
      : text?.replace('{{OpportunityName}}', opportunityName)
  } else if (NOTIFICATION_SUB_TYPE.DATA_LOSS === subType) {
    const { buildingName } = props

    text = text.replace('{{buildingId}}', buildingName)
  }

  return text
}
