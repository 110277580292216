import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef
} from 'react'

import { canvasChangeUpdateScale } from './UpdateMethods/Canvas/update'
import { updateConfig } from './UpdateMethods/Config/update'
import {
  updateChartInstanceID,
  legendInteractedUpdate,
  updateSVGDimensions,
  updateMousePosition,
  updateTooltipDetails
} from './UpdateMethods/updateMethods'
import SVGBaseWrapper from './SVGBaseWrapper'
import ChartSetup from './ChartSetup'
import { getInitailState } from './helper'
import LegendSetup from '../Charts/XYChart/LegendSetup/LegendSetup'

// Context for passing the store actions
const ActionsContext = createContext(null)
const DataContext = createContext(null)
const ToolTipContext = createContext(null)

export const useDynamicActions: any = () => useContext(ActionsContext)
export const useDataContext: any = () => useContext(DataContext)
export const useTooltipContext: any = () => useContext(ToolTipContext)

const BaseSetup = (props: any) => {
  const { width, height, container = {} } = props?.props || {}
  const [state, setState] = useState(getInitailState())

  const actions = useMemo(() => {
    const actions = {
      updateChartInstanceID: updateChartInstanceID(setState),
      updateSVGDimensions: updateSVGDimensions(setState),
      updateMousePosition: updateMousePosition(setState),
      updateTooltipDetails: updateTooltipDetails(setState),
      legendInteractedUpdate: legendInteractedUpdate(setState),
      updateConfig: updateConfig(setState),
      canvasChangeUpdateScale: canvasChangeUpdateScale(setState)
    }
    return actions
  }, [setState])

  const dataValue: any = useMemo(() => {
    return {
      chartInstanceId: state?.chartInstanceId,
      chartInstanceIdT: state?.chartInstanceIdT,
      svgDimensions: state?.svgDimensions,
      dataLastUpdatedAt: state?.dataLastUpdatedAt,
      axisCanvas: state?.axisCanvas,
      canvasDetails: state?.canvasDetails,
      canvas: state?.canvas,
      paddingDetails: state?.paddingDetails,
      seriesData: state?.seriesData,
      seriesTypes: state?.seriesTypes,
      axes: state?.axes,
      generatedScales: state?.generatedScales,
      internalLegend: state?.internalLegend,
      axisCanvasT: state?.axisCanvasT,
      svgDimensionsT: state?.svgDimensionsT,
      dataLastUpdatedAtT: state?.dataLastUpdatedAtT,
      canvasDetailsT: state?.canvasDetailsT,
      canvasT: state?.canvasT,
      seriesDataT: state?.seriesDataT,
      seriesTypesT: state?.seriesTypesT,
      axesT: state?.axesT,
      generatedScalesT: state?.generatedScalesT,
      paddingDetailsT: state?.paddingDetailsT,
      internalLegendT: state?.internalLegendT,
    }
  }, [
    state?.chartInstanceIdT,
    state?.svgDimensionsT,
    state?.dataLastUpdatedAtT,
    state?.axisCanvasT,
    state?.canvasDetailsT,
    state?.canvas,
    state?.seriesDataT,
    state?.seriesTypesT,
    state?.axesT,
    state?.generatedScalesT,
    state?.paddingDetailsT,
    state?.internalLegendT,
  ])

  const tooltipContext: any = useMemo(() => {
    return {
      mousePosition: state?.mousePosition,
      tooltipDetails: state?.tooltipDetails,
      mousePositionT: state?.mousePositionT,
      tooltipDetailsT: state?.tooltipDetailsT
    }
  }, [state?.mousePositionT, state?.tooltipDetailsT])

  return (
    <ActionsContext.Provider value={actions}>
      <DataContext.Provider value={dataValue}>
        <ToolTipContext.Provider value={tooltipContext}>
          <SVGBaseWrapper container={container} width={width} height={height}>
            <LegendSetup props={props?.props || {}}>
              <ChartSetup props={props?.props || {}} chartID={props?.chartID}>
                {props.children}
              </ChartSetup>
            </LegendSetup>
          </SVGBaseWrapper>
        </ToolTipContext.Provider>
      </DataContext.Provider>
    </ActionsContext.Provider>
  )
}

export default BaseSetup

export const selectChartInstanceId = 'chartInstanceId'
export const selectSVGDimensions = 'svgDimensions'
export const selectAxisCanvas = 'axisCanvas'
export const selectCanvasDetails = 'canvasDetails'
export const selectCanvas = 'canvas'
export const selectScales = 'scales'
export const selectAxes = 'axes'
export const selectGrids = 'grids'
export const selectPlotLines = 'plotLines'
export const selectPlotBands = 'plotBands'
export const selectGeneratedScales = 'generatedScales'
export const selectSeriesTypes = 'seriesTypes'
export const selectSeriesData = 'seriesData'
export const selectSeriesDataLastUpdated = 'dataLastUpdatedAt'
export const selectMousePosition = 'mousePosition'
export const selectTooltipDetails = 'tooltipDetails'
export const selectPaddingDetails = 'paddingDetails'
export const selectInternalLegend = 'internalLegend'

export const useSelector = (property) => {
  const state = useDataContext()
  const [selectedValue, setSelectedValue] = useState(
    () => state?.[`${property}`]
  )
  const timestampRef = useRef(state[`${property}T`])

  useEffect(() => {
    if (state[`${property}T`] !== timestampRef.current) {
      setSelectedValue(state?.[property])
      timestampRef.current = state[`${property}T`]
    }
  }, [state?.[`${property}T`]])

  return selectedValue
}
