import clsx from 'clsx'
import './LinePath.css'
import { SOLID_LINE } from './constants'
import { AddSVGProps } from '../../Types/common'

const LinePath = (props: AddSVGProps<any, SVGRectElement>) => {
  const {
    d,
    className = undefined,
    fill = 'none',
    stroke = 'black',
    strokeWidth = 3,
    strokeDasharray = undefined,
    strokeLinecap = undefined,
    dashType = SOLID_LINE,
    clipPath = undefined,
    dataTestID = undefined,
    ...elementProps
  } = props

  return (
    <path
      d={d}
      className={clsx(`line${dashType}`, className)}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap={strokeLinecap}
      clipPath={clipPath}
      data-testid={dataTestID}
      {...elementProps}
    />
  )
}

export default LinePath
