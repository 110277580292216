import styled from 'styled-components'
import colors from '../../components/legacy/common/colors.json'
import { TreeView } from "src/components/legacy/components/tree-view-sc/tree-view.sc"
import EditableView from 'src/components/legacy/components/editable-view/editable-view'

export const StyledTree = styled(TreeView)`
  overflow: auto;
  width: auto;
`
export const DataSourceEditableView = styled(EditableView)`
  .edit-view__field {
    height: auto;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #666;
  width: 97%;
  margin: 0 auto;
  h1 {
    margin: 10px 0 10px 0;
    padding: 0;
    font-size: 18px;
  }
  button {
    margin-right: 10px;
    height: 25px;
  }
`

export const LabelFormat = props => {
  return <div style={{ display: "flex", alignItems: "center" }}>
    {
      !props.treeChildren?.length && (
        //<PointStatusStyled color={props.color} />
        <></>
      )
    }
    <div>{props.label}</div>
  </div>
}

export const RadioButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-top: 13px;
  // margin-left: 20px;
  margin-bottom: 11px;
  label {
    > span {
      input:checked ~ svg {
        .input-circle {
          fill: #4c912d;
        }
      }
    }
    font-size: 13px;
    span:last-child {
      font-size: 12px;
    }
  }
`
