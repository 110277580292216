//
const moveTo = (x: any, y: any) => `M${x},${y} `
// const drawHorizontalLine = (dx: any) => `h${dx} `;
const drawVerticalLine = (dy: any) => `v${dy} `
const reveseDrawHorizontalLine = (dx: any) => `h-${dx} `
const reverseDrawVerticalLine = (dy: any) => `v-${dy} `
const closePath = () => `Z`

const bottomRightRadius = `a5,5 0 0 0 5,-5`
const topRightRadius = `a5,5 0 0 0 -5,-5 `
const topLeftRadius = `a5,5 0 0 0 -5,5`
const bottomLeftRadius = `a5,5 0 0 0 5,5`

export function savingsRect(
  x: number,
  y: number,
  width: any,
  height: any,
  parentWidth: number
) {
  const centerofWidth = width / 2

  const right = leftPostionAfterArrow(centerofWidth, x, parentWidth)
  const left = leftPostionAfterArrow(centerofWidth, 0, x)

  const hRight = right + (centerofWidth - left)
  const hLeft = left + (centerofWidth - right)

  const path =
    moveTo(x, y) +
    `l  ${hRight <= 10 ? hRight : 10} -10 h${hRight > 10 ? hRight - 10 : 0}` +
    (hRight > 10 ? bottomRightRadius : 'v-5') +
    reverseDrawVerticalLine(height) +
    topRightRadius +
    reveseDrawHorizontalLine(width) +
    (hLeft >= 10 ? topLeftRadius : `a5,5 0 0 0 -1,1`) +
    drawVerticalLine(height) +
    (hLeft >= 10 ? bottomLeftRadius : 'v5') +
    `h${hLeft >= 10 ? hLeft - 10 : 0}` +
    closePath()

  return {
    x: x - hLeft,
    y: y - height - 15,
    path
  }
}

function leftPostionAfterArrow(
  width: number,
  x: number,
  xContainerSize: number
) {
  const remainingValue = xContainerSize - x

  let pos = 0

  if (remainingValue >= width) {
    pos = width
  } else {
    pos = remainingValue >= 0 ? remainingValue : 0
  }

  return pos
}
