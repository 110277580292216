import { Button } from "@aws-amplify/ui-react"
import translate from "src/common/translations"
import { Dialog } from "src/denali-components/Dialog/Dialog"
import { useQuery } from "src/hooks/APIHooks"
import { GET_DOCUMENT_BY_ID, type FullDocument } from "src/pages/documentsAndReports/graphql"
import { Loading } from "src/denali-components/Table/Loading"
import { DocumentSummary } from "./DocumentSummary"
import { DocumentAttachments } from "./DocumentAttachments"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { useLocation, useNavigate } from "react-router"
import { useSelector } from "react-redux"
import { selectUserInfo } from "src/redux/slicers/appData"
import { MODAL_TYPE } from 'src/constants'
import { useDocumentsAndReportsContext } from 'src/pages/documentsAndReports/DocumentsAndReportsContextProvider'

// migrated from src/pages/documentsAndReports/DocumentDetailsModal.tsx
export const DocumentModal = ({setIsModalOpen, documentId, downloadFile, rowData}) => {
  const navigate = useNavigate();
  const {search} = useLocation();
  const userInfo = useSelector(selectUserInfo)
  const { data, loading }:{data:FullDocument, loading:boolean} = useQuery({
    query: GET_DOCUMENT_BY_ID,
    variables: { id: documentId },
    disableInitialLoad: false,
    errorPolicy: 'all',
    dataPath: 'data.getDocument'
  });
  const deleteDocument = getDeleteCallback(rowData, setIsModalOpen);

  return (
    <Dialog
      title={translate('Document Details')}
      headerActions={
        <>
          <Button
            className="button-gray button-icon-left"
            // Todo: This string comparison copied from src/pages/documentsAndReports/DocumentDetailsModal.tsx
            // This creates issues with name changes and security access.
            // The API is also not validating access. This should use userId and check on the backend.
            // Flagged for Cole, direction was to leave as-is for now and come back to it.
            disabled={!(data?.contact ===  `${userInfo?.firstName} ${userInfo?.lastName}`)}
            onClick={()=>  navigate({
              pathname: `/reports/edit/${documentId}`,
              search
            })}
          >
            <FontAwesomeIcon icon={faPen} />
            Edit
          </Button>
          <Button
            className="button-red button-icon-left"
            // Todo: See note about security issue with string comparison above.
            disabled={!(data?.contact ===  `${userInfo?.firstName} ${userInfo?.lastName}`)}
            onClick={() => deleteDocument()}
          >
            <FontAwesomeIcon icon={faTrashCan} />
            Delete
          </Button>
        </>
      }
      width='920px'
      onClose={()=>setIsModalOpen(false)}
    >
      {
        loading
          ? <Loading />
          : <>
              {/* { JSON.stringify(data) } */}
              <DocumentSummary document={data}/>
              <DocumentAttachments document={data} downloadFile={downloadFile} />
            </>
        }
    </Dialog>
  )
}

const getDeleteCallback = (rowData, setIsModalOpen) => {
  const { 
    setDocReportRow,
    setModalType,
  } = useDocumentsAndReportsContext()

  return () => {
    setDocReportRow(rowData)
    setModalType(MODAL_TYPE.CONFIRMATION);
    setIsModalOpen(false);
  }
}