import Table from '../../../../components/Table/clientSideTable'
import { columnsForAllTests, columnForViewTestModal } from '../constants'
import { getSettingLevel,getEquipmentTypeDetails } from '../helpers'
import {
  Container,
  TableHeaderWrapper,
  WrapperText,
  LabelWrapper,
  ValueWrapper,
  TableHeader
} from './Styled'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { getTestSettingsSummary } from 'src/pages/automatedTestSettings/graphql'
import IconSVG from 'src/components/Icon'
import colors from 'src/components/legacy/common/colors.json'
import Modal from 'src/components/legacy/components/modal/modal'
import moment from 'moment'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  BACKEND_DATE_FORMAT
} from 'src/components/legacy/common/time-helpers'
import translate, { TranslateComponent, fetchTranslate } from 'src/common/translations'
import { useSelector } from 'react-redux'


// This will be useful only for Detection rates
const limitDisplayNames = {
  redLimit: 'Critical Limit',
  yellowLimit: 'Cautionary Limit'
}


export default function HistoryTableSection({
  selectedKey,
  buildingId,
  buildingName,
  organizationName,
  startDate,
  endDate,
  setHistoryData,
  equipmentDetail
}) {
  const [testHistory, setTestHistory] = useState<any>({})
  const [rowData, setRowData] = useState<any>([])
  const [showModal, setShowModal] = useState(null)
  const [modalViewData, setModalViewData] = useState<any>({})
  const [options, setOptions] = useState([])
  let selectedLanguage = useSelector((state: any) => state?.appData?.selectedLanguage)
  selectedLanguage = selectedLanguage ? selectedLanguage : 'en'

  const {
    data: automatedSettingsSummary,
    refetch: refetchAutomatedSettingsSummary,
    loading: loadingAutomatedSettingsSummary
  } = useQuery({
    query: getTestSettingsSummary,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getATSettings'
  })

  const getSettingsByHistory = async (buildingId) => {
    try {
      const input = {
        req_bid: buildingId,
        req_type: 'ht',
        histStDt: moment(startDate).locale('en').format(BACKEND_DATE_FORMAT),
        histEndDt: moment(endDate).add(1, 'days').locale('en').format(BACKEND_DATE_FORMAT),
        ht_type: 'all',
        ...(selectedKey !== 'allTests' && { at_key: selectedKey })
      }
      const summaryData = await refetchAutomatedSettingsSummary({
        body: JSON.stringify(input)
      })
      const parsedData = JSON.parse(summaryData)
      setTestHistory(parsedData)
    } catch (e) {}
  }
  useEffect(() => {
    if (buildingId) {
      getSettingsByHistory(buildingId)
    }
  }, [buildingId, selectedKey, startDate, endDate])

  useEffect(() => {
    let rowData = testHistory?.settings
    if (!equipmentDetail?.key) {
      rowData = rowData?.filter((it) => it.stType != 'E')
    }
    
    const modifyRows = async () => {
      try {
        const equipmentTypeDetails = getEquipmentTypeDetails()
        const modifiedrowData = await Promise.all(
          rowData?.map(async (it) => {
            const translatedText = selectedLanguage !== 'en' && it?.atName
              ? await fetchTranslate(it?.atName?.replace('XX', ''), selectedLanguage)
              : it?.atName?.replace('XX', '')
            const getSettings =  getSettingLevel(it?.stType)
            const translatedSettingsLevel = selectedLanguage !== 'en' && getSettings
              ? await fetchTranslate(getSettings, selectedLanguage)
              : getSettings 
      return {
        automatedTest: translatedText,
        updatedBy: it?.updatedBy,
        dateAndTime: moment(it?.updatedAt).format('MMMM DD, YYYY, h:mm:ss A'),
        settingLevel: translatedSettingsLevel,
        rawComments: it?.comments,
        displayName: it?.displayName || limitDisplayNames[it?.name] || it?.name,
        checkPtValue: it?.checkPtValue,
        value: it?.intValue || it?.floatValue || it?.stringValue,
        comments: it?.comments ? (
          <IconSVG
            key={`connectivity-icon-${it.comments}`}
            name={'check'}
            color={colors.BLACK}
            hover={colors.BLACK}
            height="12px"
            width="12px"
          />
        ) : (
          ''
        ),
        building: it?.buildingId === buildingId ? buildingName : '',
        equipment: equipmentTypeDetails?.find(f=>f?.equipmentType === it?.familyId)?.uiName ?? it?.familyId,
        organization: it?.buildingId === buildingId ? organizationName : ''
      }
    })
        )
        setRowData(modifiedrowData)
      } catch (error) {
        console.error('Error translating rows: ', error)
        setRowData([])
      }
    }

    if (rowData?.length > 0) {
      modifyRows()
    } else {
      setRowData([])
    }

  }, [testHistory])

  useEffect(() => {
    const uniqueFamilyIds = [
      ...new Set(testHistory?.settings?.map((it) => it.familyId))
    ].map((familyId) => ({
      name: familyId,
      value: familyId
    }))
    setOptions(uniqueFamilyIds)
  }, [testHistory?.settings?.length])

  const filterOption = (options) => {
    return options
  }

  const handleModal = () => {
    setShowModal(!showModal)
  }

  const updateRows = (rows) => {
    return rows?.length > 0 &&
    setHistoryData({
      keys: columns.filter((c) => !/view/.test(c.key)),
      values: rows.map((r) => ({ ...r, comments: r.rawComments }))
    })
  }

  const columns = tableConfig(handleModal)
  const modalTableColumns = modalTableConfig()

  function modalTableConfig() {
    return columnForViewTestModal.map((c) => {
      return { 
        ...c, 
        title: translate(c.title),
        customComponent: (row) => {
          return (
            <div>
              {c.title === 'PARAMETER NAME' ? translate(row.displayName) : (c.title === 'PREVIOUS VALUE' ? translate(`${row.value}`) : translate(`${row.checkPtValue}`))}
            </div>
          )
        }
      }
    })
  }
  

  const testSettingsModalConfig = {
    gray: true,
    className: 'wide-modal',
    isHideWhiteBackground: false,
    heading: "Automated Test History Detail",
    handleClose: handleModal,
    buttons: [
      {
        text: 'Cancel',
        handleClick: handleModal,
        type: 'valid'
      }
    ]
  }

  const renderModal = () => {
    return (
      <Modal {...testSettingsModalConfig}>
        <TableHeaderWrapper>
          <WrapperText>
            <div>
              <LabelWrapper><TranslateComponent>Test name</TranslateComponent></LabelWrapper>
            </div>
            <div>
              <ValueWrapper><TranslateComponent>{`${modalViewData?.automatedTest?.replace('XX','')}`}</TranslateComponent></ValueWrapper>
            </div>
          </WrapperText>
          <WrapperText>
            <div>
              <LabelWrapper><TranslateComponent>Updated By</TranslateComponent></LabelWrapper>
            </div>
            <div>
              <ValueWrapper>{`${modalViewData?.updatedBy}`}</ValueWrapper>
            </div>
          </WrapperText>
        </TableHeaderWrapper>
        <TableHeaderWrapper>
          <WrapperText>
            <div>
              <LabelWrapper><TranslateComponent>Date And Time</TranslateComponent></LabelWrapper>
            </div>
            <div>
              <ValueWrapper>{`${modalViewData?.dateAndTime}`}</ValueWrapper>
            </div>
          </WrapperText>
          <WrapperText>
            <div>
              <LabelWrapper><TranslateComponent>Setting Level</TranslateComponent></LabelWrapper>
            </div>
            <div>
              <ValueWrapper>
                <TranslateComponent>{getSettingLevel(modalViewData?.settingLevel)}</TranslateComponent>
              </ValueWrapper>
            </div>
          </WrapperText>
        </TableHeaderWrapper>
        <TableHeaderWrapper>
          {modalViewData?.settingLevel === 'Building' ? (
            <WrapperText>
              <div>
                <LabelWrapper><TranslateComponent>Building:</TranslateComponent></LabelWrapper>
              </div>
              <div>
                <ValueWrapper>{`${modalViewData?.building}`}</ValueWrapper>
              </div>
            </WrapperText>
          ) : modalViewData?.settingLevel === 'Equipment' ? (
            <WrapperText>
              <div>
                <LabelWrapper><TranslateComponent>Equipment:</TranslateComponent></LabelWrapper>
              </div>
              <div>
                <ValueWrapper><TranslateComponent>{`${equipmentDetail?.value}`}</TranslateComponent></ValueWrapper>
              </div>
            </WrapperText>
          ) : null}
        </TableHeaderWrapper>
        <hr />

        <TableHeader><TranslateComponent>Changes</TranslateComponent></TableHeader>
        <Container className="gap-start-end">
          <Table
            key={`ats-history-table-123`}
            rows={[modalViewData]}
            header={modalTableColumns}
            fixedLayout={false}
          />
        </Container>
        <WrapperText>
          <div>
            <LabelWrapper><TranslateComponent>Comments</TranslateComponent></LabelWrapper>
          </div>
          <div>
            <ValueWrapper>{modalViewData?.rawComments}</ValueWrapper>
          </div>
        </WrapperText>
      </Modal>
    )
  }

  function tableConfig(handleModal) {
    return columnsForAllTests.map((c) => {
      if (c.title === 'View' || c.title === 'Automated Test' || c.title === 'Setting Level') {
        return {
          ...c,
          title: translate(c.title),
          customComponent: (row) => {
            if(c.title === 'View'){
              return (
                <a
                  onClick={() => {
                    setModalViewData(row)
                    handleModal()
                  }}
                >
                  <TranslateComponent>View Details</TranslateComponent>
                </a>
              )
            }
            else{
              return <TranslateComponent>{`${c.title === 'Automated Test' ? row.automatedTest : row.settingLevel}`}</TranslateComponent>
            }
          }
        }
      }
      return { ...c, title: translate(c.title)}
    })
  }
  
  const filterData = useMemo(
    () => {
      return [
        {
          label: 'All Equipment Types',
          key: 'equipment',
          id: 'typeSelectorFilter',
          defaultLabel: 'All Types',
          selectedValue: 'default',
          options: filterOption(options)
        }
      ]
    }
  ,[selectedKey,options])

  return loadingAutomatedSettingsSummary ? (
    <Spinner />
  ) : (
    <Container className="gap-start-end">
       <Table
        updateRows={rows => updateRows(rows)}
        key={`ats-history-table-123`}
        rows={rowData || []}
        header={columns}
        fixedLayout={false}
        search={true}
        isFilterChange={true}
        searchFields={['automatedTest', 'updatedBy','equipment']}
        filtersList={
          selectedKey === 'allTests'
            ? filterData
            : null
        }
      />
      {showModal && renderModal()}
    </Container>
  )
}
