import moment from "moment"
import { getField } from "./helpers"

export const filterByDate = (array, dateField, period) => {
	let currentDate = moment()
	switch (period) {
		case "last-week":
			currentDate = currentDate.subtract(7, "days")
			break
		case "last-month":
			currentDate = currentDate.subtract(1, "months")
			break
		case "last-3-months":
			currentDate = currentDate.subtract(3, "months")
			break
		case "last-6-months":
			currentDate = currentDate.subtract(6, "months")
			break
		case "last-year":
			currentDate = currentDate.subtract(1, "years")
			break
		default:
			break
	}
	return array.filter(item => {
		const field = moment(getField(item, dateField))
		return field > currentDate
	})
}

export const isDateInRange = (startDate, endDate, currentDate) => moment(currentDate).isBetween(startDate, endDate)

export const shortMonthKeys = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
