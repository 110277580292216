import {
  SCALE_BAND,
  SCALE_LINEAR,
  SCALE_TIME
} from 'src/chart-library/Utils/Scales/constant'

export const filterArray = (arr: any = [], maxCount: any = 3) => {
  let filteredArr = []
  const step = Math.ceil(arr?.length / (maxCount + 1))
  for (let i = 1; i <= maxCount; i++) {
    filteredArr.push(arr?.[i * step - 1])
  }
  filteredArr = filteredArr?.filter((val) => val)
  return filteredArr
}

export const getAlternateNumbers = (array = []) => {
  const result = [];
  for (let i = 0; i < array.length; i += 2) {
      result.push(array?.[i]);
  }
  return result;
}

export const calculateTickCountBasedOnDimension = (
  pDimension: any,
  scaleType: any = ''
) => {
  const dimension = isFinite(parseFloat(pDimension))
    ? parseFloat(pDimension)
    : 0
  let tickCount: any = (Math.floor(dimension / 45) * 45) / 45
  tickCount =
    tickCount === 0
      ? 1
      : tickCount < 3
      ? 3
      : tickCount > 12 && scaleType === SCALE_LINEAR
      ? 12
      : tickCount > 12 && scaleType === SCALE_TIME
      ? 12
      : tickCount > 16 && scaleType === SCALE_BAND
      ? 16
      : tickCount
  tickCount = parseInt(tickCount)
  return tickCount
}

export const getTextDimensions = (
  text = '',
  fontSize = 12,
  fontFamily = 'Lato, sans-serif'
) => {
  // Create a temporary SVG element
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
  document.body.appendChild(svg)

  // Create a text element
  const textElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'text'
  )

  fontSize && textElement.setAttribute('font-size', `${fontSize}`)
  fontFamily && textElement.setAttribute('font-family', fontFamily)
  textElement.textContent = text

  // Append text element to SVG
  svg.appendChild(textElement)

  // Get text dimensions
  const bbox = textElement.getBBox()
  const width = bbox.width
  const height = bbox.height

  // Remove SVG from the body
  document.body.removeChild(svg)

  return { width: width, height: height }
}

export const calculateTiltedTextHeight = (width) => {
  const angle = Math.PI / 4 // 45 degrees in radians
  const height = width * Math.tan(angle)
  return height
}
