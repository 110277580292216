import React from "react"
import styled from "styled-components"
import { bool, func, string, node } from "prop-types"

import colors from 'src/components/layouts/colors.json'

const DrawerContent = styled.div<{ isOpen: boolean }>`
	display: block;

	>div {
		border-top: 1px solid ${colors.TBA_LIGHT_GREY};
	}

	>div:first-child {
		border-top: none;
	}

	${({ isOpen }) => isOpen ? `
		display: block;
	` : `
		display: none;
	`}
`

DrawerContent.propTypes = { isOpen: bool }

const DrawerToggle = styled.button.attrs<{isOpen:boolean, toggleOpen: () => void}>(({ isOpen, toggleOpen }) => ({
	onClick: toggleOpen,
	className: isOpen ? "icon-left-caret" : "icon-right-caret",
}))<{size: string, isOpen: boolean, toggleOpen:() => void}>`
	border-radius: 10px;
	background-color: ${colors.WHITE};
	display: flex;
	padding: 2px;
	margin-top: 40px;
	margin-right: -10px;
	border: 1px solid ${colors.TBA_LIGHT_GREY};
	z-index: 4;
	color: ${colors.TBA_LIGHT_GREY};
	${({ size }) => size === "small" && `
		height: 21px;
		width: 21px; 
		min-width: 21px;
		font-weight: 300;
	`}
	${({ isOpen }) => isOpen ? `
		padding: 4px 0px 0px 3px;
	` : `
		padding: 4px 0px 0px 5px;
	`}
`

const DrawerContainer = styled.div.attrs((props: any) => ({
	'data-testid': `${props?.testName}-drawer`
  }))`
	display: flex;
	/* height: 100%; */
	background-color: ${colors.TBA_LIGHTEST_GREY};
	border-right: 1px solid ${colors.TBA_LIGHT_GREY};
`

export const Drawer = ({ drawerIsOpen, toggleDrawer, testName='',  children = <></> }) => (
	// @ts-ignore
	<DrawerContainer testName={testName}>
		<DrawerContent isOpen={drawerIsOpen}>
			{children}
		</DrawerContent>
		<DrawerToggle isOpen={drawerIsOpen} toggleOpen={toggleDrawer} size="small" />
	</DrawerContainer>
)


Drawer.propTypes = { children: node }

export const NotDrawer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 20px;
`

export const DrawerSection = styled.div`
	width: 250px;
	padding: 10px;
	box-sizing: border-box;
	border-top: 1px solid ${colors.TBA_LIGHT_GREY};
	/* padding-right: 30px; /// likely we will need a better solution for handling padding. Right now we have 
	to handle in child components to allow for scroll css bar on overflow to line up with edge of drawer */
	
`
