import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePickerDay from './datepicker-day'

const DatePickerWeek = ({
  days,
  today,
  displayBase,
  range,
  rangeSelection,
  handleDateClick,
  disablePast,
  disableBeforeGivenDate,
  disableAfterGivenDate
}) => (
  <tr className="datepicker-week">
    {days.map((day) => (
      <DatePickerDay
        key={day.format('YYYY-MM-DD')}
        day={day}
        displayBase={displayBase}
        selectedStart={range.start}
        selectedEnd={range.end}
        today={today}
        rangeSelection={rangeSelection}
        handleDateClick={handleDateClick}
        disablePast={disablePast}
        disableBeforeGivenDate={disableBeforeGivenDate}
        disableAfterGivenDate={disableAfterGivenDate}
      />
    ))}
  </tr>
)

DatePickerWeek.propTypes = {
  days: PropTypes.array,
  today: PropTypes.string,
  handleDateClick: PropTypes.func,
  rangeSelection: PropTypes.shape({
    past: PropTypes.bool,
    future: PropTypes.bool
  }),
  disableBeforeGivenDate: PropTypes.object,
  disableAfterGivenDate: PropTypes.object
}

DatePickerWeek.defaultProps = {
  days: [],
  today: moment().format('YYYY-MM-DD'),
  displayBase: moment().startOf('month')
}

export default DatePickerWeek
