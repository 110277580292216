import { SystemThemeProps } from '../../utils'

// component styles
import { mergeTheme as buttonFn } from '../../styles/button'
import { mergeTheme as formControlFn } from '../../styles/form-control'
import { mergeTheme as iconButtonFn } from '../../styles/icon-button'
import { mergeTheme as optionFn } from '../../styles/option'

// ui system styles
import { mergeTheme as typographyFn } from '../../ui-system/typography'
import { mergeTheme as spacingThemeMerge } from '../../ui-system/space'
import { mergeTheme as breakpointsFn } from '../../ui-system/breakpoints'

// ---------------------------------------------- System Theme mapper ----------------------------------------------

const systemThemeMapper = {
  [SystemThemeProps.BOX]: spacingThemeMerge,
  [SystemThemeProps.BREAKPOINTS]: breakpointsFn,
  [SystemThemeProps.BUTTON]: buttonFn,
  [SystemThemeProps.FORM_CONTROL]: formControlFn,
  [SystemThemeProps.ICON_BUTTON]: iconButtonFn,
  [SystemThemeProps.OPTION]: optionFn,
  [SystemThemeProps.TYPOGRPAHY]: typographyFn
}

// ---------------------------------------------- Theme resolver ----------------------------------------------

export default function createTheme(userTheme = {}) {
  return Object.keys(systemThemeMapper).reduce((av, cv) => {
    const composeFn = systemThemeMapper[cv]

    if (typeof composeFn === 'function') {
      av[cv] = composeFn(userTheme[cv] || null, userTheme, av)
    }

    return av
  }, {})
}
