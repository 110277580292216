import { useTranslation } from 'react-i18next'
import { ChangeEvent, useEffect, useState } from 'react'
import { PanelLabel, EnergyCostConfigStyles, RadioButtonWrapper } from '../../../styles'
import { useFormikContext } from '../../MultiTabTorm'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import {
  getDisplayItems,
  getTimeCompareList,
  getTimeComparedToList,
  TIME_CURRENT_DAY,
  TIME_PAST_3_DAYS,
  TIME_PAST_7_DAYS
} from '../../../helpers'
import { convertStringToValidNumber } from 'src/components/legacy/common/helpers.js'
import { formatOverlayTimePeriod } from 'src/common/overlay-time-period'
import {
  ENERGY_TARGET_MARKERS,
  TARGET_MARKERS_CONSTANT,
  formatTargetMarkers
} from 'src/common/target-markers'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { RadioGroup } from 'src/components'
import * as Yup from 'yup'
import i18next from 'i18next'
import translate from 'src/common/translations'
const WIDGET_NAME_MAX_LENGTH = 80

export const NONE = ':none'

export const EnergyCostConfigurationSchema = Yup.object().shape({
  benchMarkerValue: Yup.string()
  .test(
    'testBenchMarkerValue',
    "This value is required.",
    testBenchMarkerValue
  )
})

function testBenchMarkerValue(value) {
  const {
    parent: { benchMarker }
  } = this
  return !(
    benchMarker === TARGET_MARKERS_CONSTANT && value === undefined
  )
}

export const EnergyCostConfiguration = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, errors, setFieldValue } = formik
  const [timeComparedToList, setTimeComparedToList] = useState([])

  const handleInputChange = (key, value) => {
    setFieldValue(key, value)
  }
  const getOptions = (options = []) => {
    const radioFields = [
      { label: "Yes", value: '1' },
      { label: "No", value: '0' }
    ].map((option: any, index: number) => ({
      ...option,
      htmlFor: options?.[index]
    }))
    return radioFields
  }

  useEffect(() => {
    setTimeComparedToList(getTimeComparedToList(t, values?.timePeriod))
  }, [])
  

  return (
    <EnergyCostConfigStyles>
      <div className="tab-page-content">
        <SelectFormInput
          labelName={"Time Period"}
          optionsList={getTimeCompareList(t)}
          placeholder={"— Select One —"}
          update={(value) => {
            setFieldValue('timePeriod', value)
            setFieldValue('comparedTo', NONE)
            setTimeComparedToList(getTimeComparedToList(t, value))
          }}
          errorItem={errors?.timePeriod}
          selectedItem={t(`time>${values?.timePeriod}`, {
            nsSeparator: '>'
          })}
        />
        <PanelLabel className='tip'>{translate("To enable cost segmentation viewing, select a period greater than 7 days")}</PanelLabel>
        <SelectFormInput
          labelName={"Compared to"}
          optionsList={timeComparedToList}
          placeholder={"— Select One —"}
          update={(value) => {
            setFieldValue('comparedTo', value)
          }}
          errorItem={errors?.comparedTo}
          selectedItem={t(`time>${values?.comparedTo}`, {
            nsSeparator: '>'
          })}
        />

        <div className='benchmark-inputs'>
          <SelectFormInput
            labelName={"Benchmark/Target Marker"}
            optionsList={getDisplayItems(
              ENERGY_TARGET_MARKERS,
              formatTargetMarkers
            )}
            placeholder={"— Select One —"}
            update={(value) => {
              setFieldValue('benchMarkerValue', '')
              setFieldValue('benchMarkerLabel', '')
              setFieldValue('benchMarker', value)
            }}
            errorItem={errors?.benchMarker}
            selectedItem={formatOverlayTimePeriod(
              values?.benchMarker
            )}
          />
          {values?.benchMarker === TARGET_MARKERS_CONSTANT &&
            <TextInput
              defaultValue={convertStringToValidNumber(
                values?.benchMarkerValue
              )}
              value={convertStringToValidNumber(
                values?.benchMarkerValue
              )}
              name="benchMarkerValue"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                const re = /^[0-9\b]+$/
                if (value === '' || re.test(value)) {
                  handleInputChange(name, value)
                }
              }}
              hasError={errors?.['benchMarkerValue']}
              isRequired={false}
              errorMessage={errors?.['benchMarkerValue']}
              maxLength={WIDGET_NAME_MAX_LENGTH}
            />
          }
        </div>
        {values?.benchMarker === TARGET_MARKERS_CONSTANT &&
          <fieldset>
            <TextInput
              inputClass='benchmark-label'
              labelText={"Benchmark/Target Label"}
              defaultValue={values?.benchMarkerLabel}
              name="benchMarkerLabel"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(name, value)
              }}
              hasError={errors?.['benchMarkerLabel']}
              isRequired={false}
              errorMessage={errors?.['benchMarkerLabel']}
              maxLength={WIDGET_NAME_MAX_LENGTH}
            />
          </fieldset>
        }
        <div className={![TIME_CURRENT_DAY, TIME_PAST_3_DAYS,TIME_PAST_7_DAYS].includes(values?.timePeriod) ? '' : 'disable-controls'}>
          <RadioButtonWrapper>
            <RadioGroup
              label={`${translate("View energy costs broken down by segmentation?")}`}
              handleSelection={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(name, value)
              }}
              radioKey="isShowEnergyCostSegmentation"
              options={getOptions(['new-browser-yes', 'new-browser-no'])}
              selectedValue={values?.isShowEnergyCostSegmentation}
            />

          </RadioButtonWrapper>
          <fieldset>
            <PanelLabel className='tip'>{translate("When enabling the segmentation viewing, Current Day, Past 3 Days and Past 7 Days options are disabled and your colors are preselected")}</PanelLabel>
          </fieldset>
        </div>
      </div>
    </EnergyCostConfigStyles>
  )
}

export default EnergyCostConfiguration
