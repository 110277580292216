import { createContext, useContext, useState } from 'react'
import { getSearchParams } from "src/common/helperFunctions.js"
import { IDocReportRow } from './types'
import { IModalActionType } from 'src/constants'

const DocumentsAndReportsContext = createContext<any>(null)

export const DocumentsAndReportsContextProvider = ({ children }: any) => {
  const [mode, setMode] = useState(null)
  const [reloadEvents, setReloadEvents] = useState(false)
  const [isDocumentAdding, setIsDocumentAdding] = useState(false)
  const [docReportRow, setDocReportRow] = useState<IDocReportRow>(null)
  const [modalType, setModalType] = useState<IModalActionType>('')
  const [documentModalView, setDocumentModalView] = useState(false)
  const [consultationId, setConsultationId] = useState<IDocReportRow>(null)
  const [reportId, setReportId] = useState<IDocReportRow>(null)

  const [error, setError] = useState<{ isError: boolean; errorMsg: string }>({
    errorMsg: '',
    isError: false
  })

  return (
    <DocumentsAndReportsContext.Provider
      value={{
        mode,
        setMode,
        reloadEvents,
        setReloadEvents,
        isDocumentAdding,
        setIsDocumentAdding,
        docReportRow,
        setDocReportRow,
        modalType,
        setModalType,
        documentModalView,
        setDocumentModalView,
        consultationId,
        setConsultationId,
        reportId,
        setReportId,
        error,
        setError
      }}
    >
      {children}
    </DocumentsAndReportsContext.Provider>
  )
}

export const useDocumentsAndReportsContext = () =>
  useContext(DocumentsAndReportsContext)
