import styled from 'styled-components'
export const TrendlineContainer = styled.div`
  height: calc(100% - 64px);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 5px;
 .item1 {
    grid-column-start: 1;
    grid-column-end: 3;
    background-color: rgb(29, 184, 221);
    text-align: center;
    font-size: 100px;
    color: white;
    border-top-left-radius:10px;
}
  .item2 {
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row: span 2;
    background-color:rgb(213, 43, 30);
    text-align: center;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    color: rgb(255, 255, 255);
    font-weight: 900;
    font-size: 29px;
    line-height: 28px;
  }
  .item3 {
    background-color: rgb(29, 184, 221);
    display:flex;
    align-items:center;
    justify-content:center;
    text-align: center;
    grid-column-end: 3;
    grid-column-start: 1;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 11px;
    padding: 5px;
  }
  .row-2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows:auto;
    grid-row-gap: 10px;
    grid-column-start: 1;
    grid-column-end: 7;
    gap: 4px;
    grid-row: 3 / 20;
}
 
  .previous {
    background-color: lightgray;
   text-align:center;
   font-weight: bold;
    font-size: 13px;
   
   
    
  }
  .current {
    background-color: yellow;
   text-align:center;
   font-weight: bold;
    font-size: 13px;
    
  }
  .goal {
    background-color: lightgray;
   text-align:center;
   font-weight: bold;
    font-size: 13px;
    
  }
  .previous,.current,.goal{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

  }
  .date-container{
    font-size:12px;
    max-width: 110px;
  }
  .kpi-values{
    font-weight: 900;
    font-size: 21px;
  }
`