import { RBox, RBush } from '../Rbush'
import { Point } from './seriesPoints'
import { SeriesPointsCollection } from './seriesPointsCollection'

export interface BoxPoint<T, V> extends Point<T, V> {
  box?: RBox
}

export class SeriesExtentPoints<T, V> {
  private readonly id =
    '__extent__rbush_' + Math.ceil(Math.random() * 1000000000000)

  private disabled = false

  private boxData: any = {}

  private base: SeriesPointsCollection<T, V>

  private rBush: RBush<RBox<V>>

  private minX: T | number | null = null

  private maxX: T | number | null = null

  private cachedValue: any[] = []

  constructor(base: SeriesPointsCollection<T, V>) {
    this.rBush = new RBush<RBox<V>>()
    this.base = base
    this.base._internal_register_extent_points_series(this.id, this)
  }

  insert(key: T, value: V, box?: RBox) {
    this.resetXvaluesToDefault()

    // Round off the decimal values
    key = parseFloat(Number(key).toFixed(3)) as any

    this.add(key, value, box)
    this.base._internal_insert_item(key)
  }

  insertAll(points: BoxPoint<T, V>[] = []) {
    points.forEach((point) => {
      if (point.key !== undefined && point.key !== null) {
        this.insert(point.key, point.value, point.box)
      }
    })
  }

  remove(key: T) {
    this.resetXvaluesToDefault()

    // Round off the decimal values
    key = parseFloat(Number(key).toFixed(3)) as any
    this.delete(key)
    this.base._internal_remove_item(key)
  }

  removeAll(points: BoxPoint<T, V>[] = []) {
    points.forEach((point) => {
      if (point.key !== undefined && point.key !== null) {
        this.remove(point.key)
      }
    })
  }

  show() {
    this.disabled = false
  }

  hide() {
    this.disabled = true
  }

  destroy() {
    this.clear()
    this.boxData = {}
    this.base._internal_unregister_extent_points_series(this.id)
    this.resetXvaluesToDefault()
  }

  clear() {
    // Clear all box items before removing other data. It reduces the looping in rBush
    this.rBush.clear()

    Object.keys(this.boxData).forEach((key: any) => {
      this.remove(key)
    })
  }

  _internal_query(key: T): V[] {
    if (this.disabled) return []

    // Optimization : return cached value if key value falls between minX and maxX
    if (this.maxX !== null && this.minX !== null) {
      if (this.minX <= key && key <= this.maxX) {
        return this.cachedValue || []
      }
    }

    this.resetXvaluesToDefault()

    return this.searchBoxItem(key)
  }

  private searchBoxItem(key: any) {
    const result = this.rBush
      .search({
        minX: key,
        minY: 0,
        maxX: key,
        maxY: 0
      })
      .map((box) => {
        this.minX = Math.max(box.x, this.minX as any)
        this.maxX = Math.min(box.maxX, this.maxX as any)
        return box.data
      })

      this.cachedValue = result

    return result
  }

  private add(key: T, value: V, box: RBox = null) {
    const k = '_' + key

    const isBox = this.isBoxItem(box)

    if (!isBox) return

    if (!this.boxData[k]) this.boxData[k] = []

    const boxPoint = this.boxItem(box, value)

    this.rBush.insert(boxPoint)
    this.boxData[k].push(boxPoint)
  }

  private boxItem(box: RBox, value: V): RBox {
    return {
      ...box,
      data: value,
      rId: '__rbush_box_point_' + Math.ceil(Math.random() * 1000000000000)
    }
  }

  private delete(key: T) {
    const boxes = this.boxData['_' + key]

    if (Array.isArray(boxes)) {
      boxes.forEach((box) => {
        this.rBush.remove(box)
      })
    }

    delete this.boxData['_' + key]
  }

  private isBoxItem(box: RBox) {
    if (!box) return false

    return (
      this.isValidInput(box.x) &&
      this.isValidInput(box.y) &&
      this.isValidInput(box.maxX) &&
      this.isValidInput(box.maxY)
    )
  }

  private isValidInput(value: any) {
    return value !== undefined || value !== null || value !== ''
  }

  private resetXvaluesToDefault() {
    this.minX = this.maxX = null
    this.cachedValue = [];
  }
}
