import { useEffect, useMemo, useState } from 'react'
import {
  selectCanvasDetails,
  selectGeneratedScales,
  selectSeriesData,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
import GroupBarChart from 'src/chart-library/CommonComponents/Bar/groupBar'
import { euiMeasurement, eciMeasurement } from "src/pages/energyIntensity/helpers";
import colors from 'src/components/layouts/colors.json'

const ComparisonBarRenderer = (props: any) => {
  const { types = [], canvasIndex = '', setTooltip } = props
  const[barChartData,setBarChartData] = useState([])
  const generatedScales = useSelector(selectGeneratedScales)
  const seriesData = useSelector(selectSeriesData)
  const canvasDetails: any = useSelector(selectCanvasDetails)
  const { paddingHeight } = useMemo(() => {
    const canvasDim = canvasDetails?.[canvasIndex]
    return {
      paddingHeight: canvasDim?.height
    }
  }, [canvasDetails, canvasIndex])

  const { xScaleObj, yScaleObj, y2ScaleObj, renderChartData } = useMemo(() => {
    let xScaleObj = null
    let yScaleObj = null
    let y2ScaleObj = null
    let renderChartData = []
    {
      types?.map((obj: any, i: number) => {
        const chartData = seriesData?.[obj?.seriesKey]?.data ?? []
        xScaleObj = generatedScales?.[obj?.xAxisKey]
        yScaleObj = generatedScales?.["y1"]
        y2ScaleObj = generatedScales?.["y2"]
        const formationChartData = chartData?.map(m => ({ displayName: m?.displayName, name: m?.name, values: [m?.values] }))
        if (renderChartData?.length === 0) {
          renderChartData = formationChartData
        } else {
          renderChartData?.forEach(element => {
            const renderData = formationChartData?.find(f => f?.name === element?.name)
            element.values = [...element?.values, ...renderData?.values]
          });
        }
      })
    }
    return {
      xScaleObj,
      yScaleObj,
      y2ScaleObj,
      renderChartData
    }
  }, [types,generatedScales])

  useEffect(() => {
    const euiMeasurementValue = `EUI (${euiMeasurement})`
    const eciMeasurementValue = `ECI (${eciMeasurement})`
    renderChartData?.forEach(element => {
      const values = element?.values ?? []
      if (values?.length > 0) {
        const names = values?.map(m => m?.name) ?? []
        if (!names?.includes(euiMeasurementValue)) {
          element.values = [{ name: euiMeasurementValue, value: 0, color: colors.BLUE, isSkip:true }, ...element?.values]
        }
        if (!names?.includes(eciMeasurementValue)) {
          element.values = [...element?.values, { name: eciMeasurementValue, value: 0, color: colors.GREEN, isSkip:true }]
        }
      }
    });
    setBarChartData(renderChartData)
  }, [renderChartData])
  



  return (
    <>
      <g>
        {barChartData?.length > 0 && <GroupBarChart barData={barChartData} canvasHeight={paddingHeight} xScale={xScaleObj?.gScale} yScale={yScaleObj?.gScale} yScale2={y2ScaleObj?.gScale} yScale2BarPosition={1} enableToolTip={true} setTooltip={setTooltip} />}
      </g>

    </>
  )
}

export default ComparisonBarRenderer
