import translate, { TranslateComponent } from 'src/common/translations'
import { PanelLabel } from '../../styles'
import Select from 'src/components/legacy/components/select/select'
const CustomKPISelect = ({
  selectedItem,
  labelName,
  optionsList,
  placeholder,
  setFieldValue,
  fieldName,
  errorItem,
  selectedTransName,
  transFunc,
  isRequired=false
}) => {
  const handleSetFieldValueChange = (key, value) => {
    setFieldValue(key, value)
  }
  return (
    <>
      <PanelLabel className={errorItem ? 'error' : ''}><TranslateComponent>{labelName}</TranslateComponent>{Boolean(isRequired) && '*'}</PanelLabel>
      <Select
        className={errorItem ? 'error' : ''}
        options={optionsList}
        placeholder={placeholder}
        onChange={(value) => {
          handleSetFieldValueChange(fieldName, value)
        }}
        selectedItem={transFunc(`${selectedTransName}>${selectedItem}`, {
          nsSeparator: '>'
        })}
      />
      <p className="error">{errorItem && translate(errorItem)}</p>
    </>
  )
}
export default CustomKPISelect
