import React, { useEffect, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { getPropByPath } from 'src/utils'
import { eventPub, useDebounce } from '../../utils'
import FormHelperText from '../FormHelperText'
import InputLabel from '../InputLabel'
import Option from '../Option'
import Search from '../Search'
import SelectInput from '../SelectInput'

import {
  FormControlRoot,
  NoResultsMatchOption,
  Placeholder,
  SearchOption,
  SelectAllCheckbox,
  SelectAllOption,
  Tag,
  ViewCount
} from '../../styles/selectrix-and-tree'
import { useInView } from 'react-intersection-observer'
import { useQuery } from 'src/hooks/APIHooks'
import {
  createWildCardFilter,
  createWildCardFilterMultiple
} from 'src/components/layouts/Table/wildCardFilter'
import translate, { TranslateComponent } from '../../../common/translations'

// ---------------------------------------------- Support UI Comonents ----------------------------------------------

function renderTagView(items = [], placeholder) {
  if (!items?.length) return <Placeholder>{placeholder}</Placeholder>
  return items.map((item) => <Tag key={item}>{item}</Tag>)
}

const Item = (props) => {
  const {
    intersectionRef,
    searchableValue,
    value,
    searchValue = '',
    children,
    index,
    selected,
    ...rest
  } = props
  // const sValue = String(searchValue.toLowerCase()).trim();

  // if (!searchableValue.toLowerCase().includes(sValue)) {
  //   listRef.removeItem(value);
  //   return null;
  // }
  // listRef.addItem(value);

  return (
    <Option
      key={value}
      value={value}
      ref={intersectionRef}
      {...{ ...rest, ...rest?.props }}
      selected={selected}
    >
      {children || value}
    </Option>
  )
}

// ---------------------------------------------- Configs ----------------------------------------------

// const listItemActivityTracker = {
//   /**
//    * To check a list item meets search text
//    *
//    * if Yes,
//    *          In addItem fn, Item id will be added and set it as true to show the item
//    *
//    * if No,
//    *          In removeItem fn, Item id will be set as false to hide the item
//    */
//   searchedItems: {},
//   addItem(val) {
//     if (!this.searchedItems[val]) {
//       this.searchedItems[val] = true;
//     }
//   },
//   removeItem(val) {
//     if (this.searchedItems[val]) {
//       this.searchedItems[val] = false;
//     }
//   }
// };

const defaultSelectProps = {
  enableMenuScroll: true,
  menuHeight: '292px',
  multiple: true
}

// ---------------------------------------------- Selectrix ----------------------------------------------

const Selectrix = React.forwardRef(function Selectrix(inProps, ref) {
  const {
    debounceTime,
    enableTagView,
    helperText,
    HelperTextProps,
    id,
    InputLabelProps,
    label,
    name,
    onChange = () => null,
    options = [],
    SearchProps = {},
    value = [],
    multiple,
    query,
    testName,
    searchQuery,
    graphQLInputs = {},
    customRenderValue,
    ...rest
  } = inProps

  // const listRef = useRef({ ...listItemActivityTracker }).current;
  const { ref: intersectionRef, inView } = useInView()
  const searchRef = useRef(null)
  const [sortedOptions, setSortedOptions] = useState(options)
  const [displayOptions, setDisplayOptions] = useState(
    options.slice(0, rest.itemsPerPage)
  )
  const [searchedOptions, setSearchedOptions] = useState(options)
  const [currentPage, setCurrentPage] = useState(1)
  const [fetchControls, setFetchControls] = useState({
    ...graphQLInputs,
    limit: rest.itemsPerPage || 30,
    nextToken: null,
    ...(rest.sort
      ? {
          sort: {
            field: rest.sort.field || 'name',
            direction: rest.sort.direction || 'asc'
          }
        }
      : {})
  })
  // ---------------------------------- Select All ----------------------------------

  const allOptionsValue = options.map((option) => option.value)

  const handleSelectAllChecked = useCallback(
    (event) => {
      const isAllSelected = event.target.checked

      const output = isAllSelected ? allOptionsValue : []

      eventPub(event, output, name, onChange)
    },
    [allOptionsValue]
  )

  // ---------------------------------- Search functionality ----------------------------------

  const [isEmpty, setIsEmpty] = useState(false)

  const [searchValue, setSearchValue] = useState('')

  // Note :
  // getRawData - contains actual response from API, which can be used to get other information from response instead of data only
  // Here, It is used to get next token from API response if nextToken props is passed
  const { refetch, getRawData } = useQuery({
    query: query,
    disableInitialLoad: true,
    variables: { ...fetchControls },
    errorPolicy: 'all',
    dataPath: rest.dataPath || 'data'
  })

  // Note : getRawDataForSearch - will be useful in future if the search API supports next token to load more data
  const { refetch: reFetchOnSearch, getRawData: getRawDataForSearch } =
    useQuery({
      query: searchQuery || query,
      disableInitialLoad: true,
      variables: { ...fetchControls },
      errorPolicy: 'all',
      dataPath: rest.dataPath || 'data'
    })

  const debouncedSetSearchValueFn = useDebounce(setSearchValue, debounceTime)

  const handleSearchChange = (event) => {
    debouncedSetSearchValueFn(event.target.value)
  }

  const updateOptions = (data, from = null, reset) => {
    if (data) {
      const apiData =
        from === 'onScroll'
          ? getRawData()
          : from === 'onSearch'
          ? getRawDataForSearch()
          : null

      // Supporting additional capability to get nextToken from any level of api response.
      const nextToken =
        apiData && rest?.nextTokenPath
          ? getPropByPath(apiData, rest?.nextTokenPath || [])
          : rest.dataPath
          ? data
          : data[Object.keys(data)[0]]?.nextToken || ''
      const _data = rest.dataPath
        ? data
        : data[Object.keys(data)[0]]?.items || []
      setDisplayOptions((options) => [
        ...(!reset ? options : []),
        ...(rest.getQueryResponseMap?.(_data) || _data)
      ])
      setFetchControls((controls) => ({ ...controls, nextToken }))
    }
  }

  const handlePaginate = () => {
    if (displayOptions.length !== sortedOptions.length) {
      const _options = searchedOptions.slice(
        currentPage * rest.itemsPerPage,
        currentPage === 0
          ? rest.itemsPerPage
          : currentPage * rest.itemsPerPage + rest.itemsPerPage
      )
      setDisplayOptions((__options) => [...__options, ..._options])
      setCurrentPage((page) => page + 1)
    }
  }

  useEffect(() => {
    setIsEmpty(!((displayOptions || []).length > 0))
  }, [displayOptions])

  useEffect(() => {
    if (!query && options?.length > 0)
      setDisplayOptions(options.slice(0, rest.itemsPerPage))
  }, [options, query])

  useEffect(() => {
    if (!query) {
      if (rest.sort) {
        const { sort } = rest
        let sortedOptions = options
        if (typeof sort === Boolean) {
          sortedOptions = options.sort((a, b) =>
            a['value'].localeCompare(b['value'])
          )
        } else {
          const { field = 'value', direction = 'asc' } = sort
          sortedOptions =
            direction === 'asc'
              ? options.sort((a, b) => a[field].localeCompare(b[field]))
              : options.sort((a, b) => b[field].localeCompare(a[field]))
        }
      }
      setSortedOptions(sortedOptions)
      const _options = sortedOptions.slice(
        currentPage * rest.itemsPerPage,
        currentPage === 0
          ? rest.itemsPerPage
          : currentPage * rest.itemsPerPage + rest.itemsPerPage
      )
      setDisplayOptions(_options)
    }
  }, [])

  useEffect(() => {
    const onViewChange = async () => {
      if (inView) {
        if (!query) {
          handlePaginate()
        } else {
          let data
          if (searchValue.trim()) {
            data = await reFetchOnSearch({
              ...fetchControls,
              filter: fetchControls.filter
                ? {
                    and: [
                      fetchControls.filter,
                      Array.isArray(rest.querySearchField)
                        ? createWildCardFilterMultiple({
                            attrNames: rest.querySearchField,
                            value: searchValue ?? '',
                            isRequiredQueryInputName: rest?.isRequiredQueryInputName,
                          })
                        : createWildCardFilter({
                            attrName: rest.querySearchField,
                            value: searchValue ?? '',
                            isRequiredQueryInputName: rest?.isRequiredQueryInputName
                          })
                    ]
                  }
                : Array.isArray(rest.querySearchField)
                ? createWildCardFilterMultiple({
                    attrNames: rest.querySearchField,
                    value: searchValue ?? '',
                    isRequiredQueryInputName: rest?.isRequiredQueryInputName
                  })
                : createWildCardFilter({
                    attrName: rest.querySearchField,
                    value: searchValue ?? '',
                    isRequiredQueryInputName: rest?.isRequiredQueryInputName
                  }),
              aggregates: rest.aggregates ? rest.aggregates : undefined
            })
          } else {
            data = await refetch({
              ...fetchControls,
              aggregates: rest.aggregates ? rest.aggregates : undefined
            })
          }
          updateOptions(data, 'onScroll')
        }
      }
    }
    onViewChange()
  }, [inView])

  useEffect(() => {
    const runSearch = async () => {
      if (searchValue.trim()) {
        if (query) {
          const data = await reFetchOnSearch({
            ...fetchControls,
            nextToken: null,
            filter: fetchControls.filter
              ? {
                  and: [
                    fetchControls.filter,
                    Array.isArray(rest.querySearchField)
                      ? createWildCardFilterMultiple({
                          attrNames: rest.querySearchField,
                          value: searchValue ?? '',
                          isRequiredQueryInputName: rest?.isRequiredQueryInputName || false
                        })
                      : createWildCardFilter({
                          attrName: rest.querySearchField,
                          value: searchValue ?? '',
                          isRequiredQueryInputName: rest?.isRequiredQueryInputName || false
                        })
                  ]
                }
              : Array.isArray(rest.querySearchField)
              ? createWildCardFilterMultiple({
                  attrNames: rest.querySearchField,
                  value: searchValue ?? '',
                  isRequiredQueryInputName: rest?.isRequiredQueryInputName
                })
              : createWildCardFilter({
                  attrName: rest.querySearchField,
                  value: searchValue ?? '',
                  isRequiredQueryInputName: rest?.isRequiredQueryInputName
                }),
            aggregates: rest.aggregates ? rest.aggregates : undefined
          })
          updateOptions(data, 'onSearch', true)
        } else {
          const searchOptions = options.filter((option) => {
            const valueToSearch = option.searchableValue || option.value
            return valueToSearch
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          })
          setSearchedOptions(searchOptions.slice(0, rest.itemsPerPage))
        }
      } else {
        if (query) {
          const data = await refetch({
            ...fetchControls,
            nextToken: null,
            aggregates: rest.aggregates ? rest.aggregates : undefined
          })
          updateOptions(data, 'onScroll', true)
        } else {
          setSearchedOptions(sortedOptions)
        }
      }
      setCurrentPage(1)
    }
    runSearch()
  }, [searchValue])

  useEffect(() => {
    setDisplayOptions(searchedOptions.slice(0, rest.itemsPerPage))
  }, [searchedOptions])

  useEffect(() => {
    searchRef.current?.focus()
  }, [searchRef.current])

  useEffect(() => {
    const resetQuery = async () => {
      if (rest.reset && query) {
        const resetFetchControls = {
          limit: rest.itemsPerPage || 30,
          nextToken: null,
          ...(rest.sort
            ? {
                sort: {
                  field: rest.sort.field || 'name',
                  direction: rest.sort.direction || 'asc'
                }
              }
            : {})
        }
        const data = await refetch({
          ...resetFetchControls,
          aggregates: rest.aggregates ? rest.aggregates : undefined
        })
        updateOptions(data, 'onScroll', true)
        rest.afterReset?.(data)
      }
    }
    resetQuery()
  }, [rest.reset])

  const renderValue = customRenderValue
    ? customRenderValue
    : enableTagView
    ? renderTagView
    : null

  return (
    <FormControlRoot
      data-testid={`${testName || 'tc'}_dropdown`}
      {...{ ...defaultSelectProps, ...inProps }}
      ref={ref}
    >
      {label && (
        <InputLabel htmlFor={id} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}
      <SelectInput
        onClose={() => {
          setSearchValue('')
          rest.onCustomClose?.()
        }}
        onOpen={() => {
          rest.onCustomOpen?.()
        }}
        className={`input-section ${rest.selectClassName}`}
        value={value}
        KeyCodes={[27]}
        // {...{ ...defaultSelectProps, multiple }}
        // containerWidth={rest.containerWidth}
        placeholder={rest.placeHolder || translate('Select one or more')}
        renderValue={renderValue}
        RenderValueProps={{
          className: enableTagView ? 'with-tags' : ''
        }}
      >
        <SearchOption
          hideCheckbox
          key="search-placeholder"
          value="search-placeholder"
          omit
        >
          <Search
            autoComplete="off"
            fullWidth
            name="selectrix-search"
            placeholder={rest.searchPlaceHolder || rest.placeHolder || 'Search'}
            {...SearchProps}
            onChange={handleSearchChange}
            value={searchValue}
            type="search"
            ref={searchRef}
            testName={testName}
          />
        </SearchOption>
        {rest.showNoResultsMessage && isEmpty && (
          <NoResultsMatchOption
            // containerWidth={rest.containerWidth}
            fullWidth
            hideCheckbox
            key="no-results-found"
            value="no-results-found"
            omit
          >
           <TranslateComponent>{`No results match `}</TranslateComponent> {`"`}{searchValue}{`"`}
          </NoResultsMatchOption>
        )}
        {!isEmpty && multiple && rest.allowCheckAll && (
          <SelectAllOption
            hideCheckbox
            key="select-all"
            value="select-all"
            omit
          >
            <SelectAllCheckbox
              disabled={!allOptionsValue?.length}
              label="Select All"
              checked={allOptionsValue?.length === value?.length}
              onChange={handleSelectAllChecked}
            />
            <ViewCount>{(value || [])?.length} Selected</ViewCount>
          </SelectAllOption>
        )}

        {rest.showCutomOption &&
          rest.customOption &&
          rest.customOption(searchValue, displayOptions)}
        {displayOptions.map((item, index) => (
          <Item
            intersectionRef={
              displayOptions?.length >= rest.itemsPerPage &&
              index === displayOptions?.length - 1
                ? intersectionRef
                : null
            }
            key={item.value}
            searchableValue={item.searchableValue || item.value}
            searchValue={searchValue}
            // listRef={listRef}
            value={item.value}
            {...item}
            multiple={multiple}
          />
        ))}
      </SelectInput>
      {helperText && (
        <FormHelperText {...HelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControlRoot>
  )
})

// ---------------------------------------------- PropTypes ----------------------------------------------

Selectrix.propTypes = {
  /**
   * If true, the width of the popover will automatically be set according to the items inside the menu,
   * otherwise it will be at least the width of the select input.
   */
  autoWidth: PropTypes.bool,

  /**
   * Input change - debounce time
   */
  debounceTime: PropTypes.number,

  /**
   * If true, the component is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * If true, the component input will use tags to display selected values.
   */
  enableTagView: PropTypes.bool,

  /**
   * If true, the input will indicate an error.
   */
  error: PropTypes.bool,

  /**
   * If true, the input will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,

  /**
   * Props applied to the InputLabel element.
   */
  HelperTextProps: PropTypes.object,

  /**
   * The helper text content.
   */
  helperText: PropTypes.any,

  /**
   * The id of the input element.
   */
  id: PropTypes.string,

  /**
   * Dropdown Icon for this component.
   */
  icon: PropTypes.node,

  /**
   * Props applied to the InputLabel element.
   */
  InputLabelProps: PropTypes.object,

  /**
   * The label content.
   */
  label: PropTypes.any,

  /**
   * If true, the height of the input will automatically be set according to the selected items (tags) inside the input,
   * otherwise it will be at least the height of the select input.
   */
  makeTagContainerAutoHeight: PropTypes.bool,

  /**
   * If true, value must be an array and the menu will support multiple selections.
   */
  multiple: PropTypes.bool,

  /**
   * Name attribute of the input element.
   */
  name: PropTypes.string,

  /**
   * Callback fired when the value is changed.
   */
  onChange: PropTypes.func,

  /**
   * Type of the input element.
   */
  open: PropTypes.bool,

  /**
   * The Option compoent props to populate the select with. Refer Option component.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      // Allows to use customized node instead of Tags.
      children: PropTypes.node,

      // Props (hideCheckbox, disabled, omit) of Option component . Refer Option component.
      props: PropTypes.object,

      // Value of Option component.
      value: PropTypes.any.isRequired
    })
  ),

  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder: PropTypes.string,

  /**
   * Popover config to place dropdown menu.
   * For more options, refer popper.js (https://popper.js.org/docs/v2/constructors/#options)
   */
  popoverConfig: PropTypes.object,

  /**
   * It prevents the user from changing the value of the field
   */
  readOnly: PropTypes.bool,

  /**
   * Props applied to the clickable div element where selected value displayed.
   */
  RenderValueProps: PropTypes.object,

  /**
   * Render the selected value.
   */
  renderValue: PropTypes.func,

  /**
   * Set Tag Input view height (i.e 78px )
   */
  tagContainerHeight: PropTypes.string,

  /**
   * Props applied to the SearchProps element.
   */
  SearchProps: PropTypes.object,

  /**
   * The value of the input element, required for a controlled component.
   */
  value: PropTypes.any,

  /**
   * This value is the number of items to be fetched / displayed everytime user is done with viewing the current fetched/displayed items
   */
  ItemsPerPage: PropTypes.number,

  /**
   * graphql query to be executed to fetch data on demand
   */
  query: PropTypes.any,

  /**
* graphql query to be executed to fetch data on search , If search query is not present
"query" is called
*/
  searchQuery: PropTypes.any,

  /**
   * The data to be extracted from query response in order to populate each list item
   *
   * must return a Option compatible value like below ,Please refer Option component for the shape of input
   * it accepts
   * {
   * children:optional, // if not provided ,value will be rendered
   * value:required,
   * searchableValue:optional // if not provided ,search will happen on value field
   * }
   */
  getQueryResponseMap: PropTypes.func,

  // if boolean true is provided ,default values {field:"name",direction:"asc" will be considered
  sort: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      field: PropTypes.string,
      direction: PropTypes.oneOf(['asc', 'desc'])
    })
  ]),

  //The field in appsync query against which search has to be performed,by default "name"

  querySearchField: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  showNoResultsMessage: PropTypes.bool
}

// ---------------------------------------------- Default Valies ----------------------------------------------

Selectrix.defaultProps = {
  autoWidth: false,
  debounceTime: 500,
  disabled: false,
  enableTagView: false,
  error: false,
  fullWidth: false,
  makeTagContainerAutoHeight: false,
  readOnly: false,
  itemsPerPage: 30,
  querySearchField: 'name',
  sort: true,
  showNoResultsMessage: true,
  allowCheckAll: true
}

export default React.memo(Selectrix)
