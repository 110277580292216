import { useEffect, useState } from 'react'
import XYChart from 'src/chart-library/Charts/XYChart'
import { useWidgetChartDataHook } from 'src/hooks/widgetChartDataHook'
import colors from 'src/components/layouts/colors.json'
import { useMemo } from 'react'
import { SCALE_LINEAR } from 'src/chart-library/Utils/Scales/constant'
import moment from 'moment/moment'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { useDimensionsHook } from 'src/hooks/dimensionHook'
import { WidgetNames } from 'src/pages/widgets/helpers'
import {
  formatDataForWidgetChart,
  getDefaultAggregation
} from 'src/common/chartHelperFunctions'
import {
  WidgetChartTitleComponent,
  WidgetChartFooterComponent,
  NoWidgetDataAvailable
} from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { WidgetPreviewChartParentContainer } from './style'
import {
  SimpleLegend,
  createSimpleLegendModel
} from '../../../../chart-library/CommonComponents/Legend'
import { isValidUOMForGetChartData } from '../../helper'
import { useWidgetsContext } from 'src/pages/widgets/WidgetsContextProvider'

const getSimpleLegendsText = (selectedPeriod) => {
  try {
    switch (selectedPeriod) {
      case 'current-day':
        return {
          current: 'Today',
          previous: 'Yesterday'
        }
      case 'current-week':
        return {
          current: 'Current Week',
          previous: 'Previous Week'
        }
      case 'current-month':
        return {
          current: 'Current Month',
          previous: 'Previous Month'
        }
      case 'past-hour': {
        return {
          current: 'Past Hour',
          previous: 'Previous Hour'
        }
      }
      case 'current-quarter':
        const currentQuarter = moment().quarter()
        const previousQuarter = moment().subtract(1, 'quarters').quarter()
        const currentYearQ = moment().format('YYYY')
        const previousQuarterYear = moment()
          .subtract(1, 'quarters')
          .format('YYYY')

        return {
          current: `Q${currentQuarter} ${currentYearQ}`,
          previous: `Q${previousQuarter} ${previousQuarterYear}`
        }
      case 'current-year':
        const currentYear = moment().format('YYYY')
        const previousYear = moment().subtract(1, 'years').format('YYYY')
        return {
          current: currentYear,
          previous: previousYear
        }
      case 'past-24-hours':
        return {
          current: 'Past 24 Hours',
          previous: 'Previous 24 Hours'
        }
      case 'past-7-days':
      case 'past-14-days':
      case 'past-30-days':
      case 'past-90-days':
        const days = parseFloat(selectedPeriod?.match(/[\d\.]+/))
        return {
          current: `Past ${days} Days`,
          previous: `Previous ${days} Days`
        }
      case 'past-12-months':
        return {
          current: `Past 12 Months`,
          previous: `Previous 12 Months`
        }
      default:
        return {}
    }
  } catch (error) {
    return {}
  }
}

export const TrendLineWidgetPreview = ({ widgetDetails }) => {
  const [isDataAvailable, setIsDataAvailable] = useState(false)

  const { allDimensions } = useWidgetsContext() || {}

  const selectedPeriod =
    widgetDetails?.selectedTimePeriod?.split('/')?.[1] || ''

  const selectedAggrValue =
    widgetDetails?.selectedAggrValue?.split('/')?.[1] || ''

  const defaultDataAggregation = getDefaultAggregation(selectedPeriod)

  // For Trendline, no quartely aggregation, so make it monthly
  const dataAggregation =
    defaultDataAggregation === 'quarterly' ? 'monthly' : defaultDataAggregation

  const [aggrChartData, setAggrChartData] = useState([])

  const [propertySymbolsValues, setPropertySymbolsValues] = useState([])

  const { widgetChartData, getWidgetChartData, loadingWidgetChartData } =
    useWidgetChartDataHook(
      selectedPeriod,
      widgetDetails?.comparePointTo,
      dataAggregation,
      widgetDetails?.timeZone
    )

  useEffect(() => {
    if (widgetChartData && widgetChartData?.data?.length > 0) {
      let formattedTimeStamps = []

      // Any of of props has valid data
      const isDataAvailable = widgetChartData?.data?.some(
        (x) => x?.isDataAvailable
      )
      // If no data available, just return from here, no need to calculate and all
      if (isDataAvailable) {
        setIsDataAvailable(isDataAvailable)
      } else {
        setIsDataAvailable(isDataAvailable)
        return
      }

      if (widgetChartData?.data?.length === 1) {
        const chartData = widgetChartData?.data[0]
        const aggrChartDataValues = formatDataForWidgetChart(
          chartData?.values?.[0] || [],
          selectedAggrValue,
          dataAggregation,
          selectedPeriod,
          widgetDetails?.timeZone
        )
        const newValuesArray = { ...chartData, values: aggrChartDataValues }

        formattedTimeStamps = aggrChartDataValues?.map((x) => x?.timeStamp)
        setAggrChartData({
          data: [newValuesArray],
          timeStamps: formattedTimeStamps
        })
      } else {
        const chartDataPrevious =
          widgetChartData?.data?.filter(
            (x) => x.isPreviousPeriod === true
          )?.[0] || {}
        const chartDataCurrent =
          widgetChartData?.data?.filter(
            (x) => x.isPreviousPeriod !== true
          )?.[0] || {}
        const aggrChartDataPresent = formatDataForWidgetChart(
          chartDataCurrent?.values?.[0] || [],
          selectedAggrValue,
          dataAggregation,
          selectedPeriod,
          widgetDetails?.timeZone
        )
        const aggrChartDataPrevious = formatDataForWidgetChart(
          chartDataPrevious?.values?.[0] || [],
          selectedAggrValue,
          dataAggregation,
          selectedPeriod,
          widgetDetails?.timeZone
        )

        const currentTimestamps = aggrChartDataPresent?.map((x) => x.timeStamp)
        const previousTimestamps = aggrChartDataPrevious?.map(
          (x) => x.timeStamp
        )
        if (currentTimestamps?.length >= previousTimestamps?.length) {
          formattedTimeStamps = currentTimestamps
        } else {
          formattedTimeStamps = previousTimestamps
        }

        const newValuesArrayCurrent = [
          {
            ...chartDataCurrent,
            values: aggrChartDataPresent || []
          }
        ]
        const newValuesArrayPrevious = [
          {
            ...chartDataPrevious,
            values: aggrChartDataPrevious || []
          }
        ]

        setAggrChartData({
          data: [...newValuesArrayCurrent, ...newValuesArrayPrevious],
          timeStamps: formattedTimeStamps
        })
      }
    }
  }, [widgetChartData])

  const initializeData = async () => {
    if (widgetDetails?.equipmentId && widgetDetails?.equipmentType) {
      const widgetInfo = {
        comparePointTo: widgetDetails?.comparePointTo,
        chartType: 'Line',
        interval: 1440, // 1 day
        equipmentType: widgetDetails?.equipmentType,
        equipmentId: widgetDetails?.equipmentId,
        selectedAggrValue: selectedAggrValue,
        buildingId: widgetDetails?.buildingId,
        propertiesList: [widgetDetails?.property]
      }
      if (isValidUOMForGetChartData(widgetDetails?.unitsOfMeasure)) {
        widgetInfo['uomDetails'] = {
          [widgetDetails?.property]: widgetDetails?.unitsOfMeasure
        }
      }
      getWidgetChartData(widgetInfo)
    }
  }

  useEffect(() => {
    if (widgetDetails?.property) {
      initializeData()
    }
  }, [widgetDetails])

  const symbolDetails = useMemo(() => {
    if (propertySymbolsValues && propertySymbolsValues?.length > 0) {
      const currentPropertySymbol = propertySymbolsValues.find(
        (x) => x.Name === widgetDetails?.unitsOfMeasure
      )
      return currentPropertySymbol
    }
  }, [propertySymbolsValues])

  useEffect(() => {
    if (allDimensions && allDimensions?.length > 0) {
      try {
        setPropertySymbolsValues(allDimensions)
      } catch (error) {
        console.log(error)
      }
    }
  }, [allDimensions])

  const getLegends = () => {
    return aggrChartData?.data?.map((x, index) => {
      return {
        id: index + 1,
        name: `${
          x.isPreviousPeriod ? 'previous-' : 'current-'
        } widgetDetails?.property`,
        seriesKey: index + 1,
        elementProps: {
          stroke: `${
            x?.isPreviousPeriod
              ? widgetDetails?.previousPeriodLineColor
              : widgetDetails?.currentLineColor
          }`,
          strokeWidth: 2
        },
        xAxisKey: 'x1',
        yAxisKey: 'y1',
        canvasIndex: '0',
        dashType: 'solid',
        properties: {},
        settings: {
          curve: {
            type: 'curveCardinal',
            settings: {
              tension: 0.5
            }
          }
        },
        shape: 'Line',
        show: true,
        visible: true,
        type: 'line',
        tooltipDataModelFormatter: ({ value }) => ({
          label: null,
          value: `${value} ${symbolDetails?.Symbol || ''}`
        })
      }
    })
  }

  const getData = () => {
    const data = {}
    aggrChartData &&
      aggrChartData?.data?.map((element, index) => {
        data[index + 1] = {
          data: element.values?.map((x) =>
            Number(x?.aggrValue || x?.value || null)
          )
        }
      })
    return data
  }

  const createXAxis = () => {
    return [
      {
        key: 'x1',
        scale: {
          props: {
            type: 'scaleBand' // scaleTime
          },
          categories: aggrChartData?.timeStamps || [],
          scaleSettings: {}
        },
        axis: {
          visibleAt: ['bottomBottom'],
          hideTicks: false,
          hideLine: false
          // name: {
          //   symbol: 'Date',
          //   text: 'Date/Time',
          //   alignment: 'middle'
          // }
        },
        grid: []
      }
    ]
  }

  const createYAxis = () => {
    return [
      {
        key: 'y1',
        scale: {
          props: {
            rangeFixed: true,
            type: SCALE_LINEAR,
            paddingNotRequired: false,
            padding: 10
          }
        },
        axis: {
          name: {
            symbol: symbolDetails?.Symbol
            // text: symbolDetails?.Name,
            // alignment: 'start'
          },
          hideTicks: false,
          visibleAt: ['leftLeft'], // Optional,
          notD3: true
          // format: (value) => parseFloat(value)?.toFixed(0),
        },
        grid: [
          {
            stroke: 'gray',
            includeAxis: true
          }
        ]
      }
    ]
  }

  const chartConfigData = {
    canvas: {
      canvasDetails: {
        0: {
          renderAreaShade: {
            fill: colors.WHITE,
            opacity: '0.1'
          },
          tooltip: {
            type: 'x',
            referenceLineV: true,
            tooltipTemplateProps: {
              className: 'dashboard-widget-tooltip-line-series'
            }
          }
        }
      }
    },
    x: createXAxis(),
    y: createYAxis(),
    series: {
      types: getLegends(),
      data: getData()
    },
    width: '100%',
    height: '100%',
    dataLastUpdatedAt: moment().unix()
  }

  const simpleLegends = useMemo(() => {
    if (widgetDetails?.comparePointTo !== ':none') {
      const legendText = getSimpleLegendsText(selectedPeriod)
      return [
        createSimpleLegendModel({
          text: legendText?.current,
          iconProps: {
            symbol: 'symbolSquare',
            fill: widgetDetails?.currentLineColor,
            size: 92
          }
        }),
        createSimpleLegendModel({
          text: legendText?.previous,
          iconProps: {
            symbol: 'symbolSquare',
            fill: widgetDetails?.previousPeriodLineColor,
            size: 92
          }
        })
      ]
    }

    return []
  }, [widgetDetails])

  return loadingWidgetChartData ? (
    <Spinner />
  ) : aggrChartData?.data?.length > 0 &&
    Object.keys(chartConfigData?.series.data)?.length > 0 &&
    isDataAvailable ? (
    <>
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName={widgetDetails?.equipmentName}
        equipmentType={widgetDetails?.equipmentType}
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDescription}
        widgetProperty={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      <WidgetPreviewChartParentContainer
        showLegends={widgetDetails?.comparePointTo !== ':none'}
        widgetType={WidgetNames.TrendLineChart}
      >
        <XYChart {...chartConfigData} />
      </WidgetPreviewChartParentContainer>
      <SimpleLegend
        className="dashboard-widget-simple-legend"
        hide={simpleLegends.length === 0}
        isStandaloneLegend
        legends={simpleLegends}
      />
      <WidgetChartFooterComponent
        aggregatorName={selectedAggrValue}
        selectedPeriod={selectedPeriod}
        aggragatorInterval={dataAggregation}
        isDefaultForText={true}
      />
    </>
  ) : (
    <NoWidgetDataAvailable
      buildingName={widgetDetails?.buildingName}
      equipmentName={widgetDetails?.equipmentName}
      equipmentType={widgetDetails?.equipmentType}
      aggregatorName={selectedAggrValue}
      selectedPeriod={selectedPeriod}
      dataAggregation={dataAggregation}
      widgetType="icon-linewidget"
      equipmentDetails={widgetDetails?.equipmentList}
      widgetDescription={widgetDetails?.widgetDescription}
      timeZone={widgetDetails?.timeZone}
      accountName={widgetDetails?.accountName}
      aggragatorInterval={dataAggregation}
      isDefaultForText={true}
    />
  )
}
