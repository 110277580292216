/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react'
import Axis from '../../../../../chart-library/CommonComponents/AxisNative'
import { useChartContext } from '../../../../../chart-library/CommonComponents/BaseWrapper'
import { AxisDiM } from '../../../../../chart-library/Utils/defaults'

export default function Axes() {
  const chart = useChartContext()

  const axis = chart?.axis
  const scale = chart?.scale

  // xAxis
  const xAxis = useMemo(
    () => axis?.getAxis(AxisDiM?.x, 0),
    [axis?.lastUpdatedAt]
  )!

  const xScale = useMemo(
    () => scale?.getScale(AxisDiM?.x, 0),
    [scale?.lastUpdatedAt]
  )!

  // yAxis
  const yAxis = useMemo(
    () => axis?.getAxis(AxisDiM?.y, 0),
    [axis?.lastUpdatedAt]
  )!

  const yScale = useMemo(
    () => scale?.getScale(AxisDiM?.y, 0),
    [scale?.lastUpdatedAt]
  )!

  if (!scale?.lastUpdatedAt || !axis?.lastUpdatedAt) {
    return null
  }

  return (
    <>
      <Axis
        dimention={AxisDiM?.x}
        axisPosition={xAxis?.type}
        axisScale={xScale?.fn}
        xPosition={xAxis?.x}
        offset={20}
        hideAxisTick
        yPosition={yScale?.fn(0)}
        lastUpdatedAt={scale?.lastUpdatedAt + axis?.lastUpdatedAt}
      />
      <Axis
        dimention={AxisDiM?.x}
        axisPosition={xAxis?.type}
        axisScale={xScale?.fn}
        hideAxisLine
        xPosition={xAxis?.x}
        yPosition={xAxis?.y}
        lastUpdatedAt={scale?.lastUpdatedAt + axis?.lastUpdatedAt}
      />
      <Axis
        dimention={AxisDiM?.y}
        axisPosition={yAxis?.type}
        axisScale={yScale?.fn}
        xPosition={yAxis?.x}
        yPosition={yAxis?.y}
        lastUpdatedAt={scale?.lastUpdatedAt + axis?.lastUpdatedAt}
        tickCount={6}
        tickSizeOuter={0}
        tickFormat={(x) => `${Number.parseInt(x)}`}
        hideAxisLine
        hideAxisTickLine
      />
    </>
  )
}
