import { API } from 'aws-amplify'
import { useState } from 'react'

import { GET_OPPORTUNITY_BY_ID_func } from '../../../graphql'
import { NOTIFICATION_SUB_TYPE } from '../constants'
import { isValidNotificationType, valueFromBody } from '../helpers'

export function useOpportunityDetails() {
  const [uniqueOpportunityIds, setUniqueOpportunityIds] = useState({})

  const newOpportunityIds = []

  // find new opportunity ids
  // ====================================================================================================
  function findNewOpportunityIds(notification: any) {
    const isValid = isValidNotificationType(notification, [
      NOTIFICATION_SUB_TYPE.OPPORTUNITY
    ])

    if (!isValid) return

    const opportunityId = valueFromBody(notification, 'id')

    const isIssueExisting = uniqueOpportunityIds[opportunityId] !== undefined

    // Don't store if id exists
    if (!isIssueExisting && opportunityId) {
      uniqueOpportunityIds[opportunityId] = true

      newOpportunityIds.push(opportunityId)
    }
  }

  // Api to get new issue details
  // ====================================================================================================
  async function apiGetOpportunityByIds() {
    let data = null

    if (newOpportunityIds.length) {
      try {
        const response = (await API.graphql({
          query: GET_OPPORTUNITY_BY_ID_func(newOpportunityIds)
        })) as any

        data = response?.data || {}
      } catch (error) {
        console.log('API Rejection error', error)
      }
    }

    // Resolve the function regardless of success or failure of request
    return await Promise.resolve({
      requestFrom: 'OPPORTUNITY_DATA',
      data
    })
  }

  // Update the response with new properties
  // ====================================================================================================
  function addOpportunityNamePropertyToNotificationsForOpportunity(
    notifications = [],
    opportunityNames = {}
  ) {
    if (!opportunityNames || Object.keys(opportunityNames).length === 0) {
      // Exceptional cases (API fails to return data, but id should be in notifications)
      if (newOpportunityIds.length) {
        const ids = newOpportunityIds.reduce((av, id) => {
          av[id] = true

          return av
        }, {})

        notifications.forEach((n) => {
          const opportunityId = valueFromBody(n, 'id')

          if (ids[opportunityId]) {
            n['opportunityId'] = opportunityId
          }
        })
      }

      return notifications
    }

    const _oppertunityNames = Object.values(opportunityNames).reduce(
      (acc, val: any) => {
        if(val?.id){
         acc[val.id] = val.title
        }
        return acc
      },
      {}
    )

    return notifications.map((notification) => {
      const opportunityId = valueFromBody(notification, 'id')

      return {
        ...notification,
        opportunityId,
        opportunityName: _oppertunityNames[opportunityId]
      }
    })
  }

  // Reset interal state
  // ====================================================================================================
  function updateState(reset: boolean) {
    const data = newOpportunityIds.slice()

    // Reset or update stored opportunity list
    setUniqueOpportunityIds((opportunityIds) => ({
      ...(!reset ? opportunityIds : {}),
      ...data
    }))

    newOpportunityIds.length = 0
  }

  return {
    findNewOpportunityIds,
    apiGetOpportunityByIds,
    addOpportunityNamePropertyToNotificationsForOpportunity,
    updateState
  }
}
