import { useState, useEffect } from 'react'
import { getTranslation } from '../redux/slicers/translationSlice';
import store, { useAppDispatch } from '../redux/store';

const CustomTerminologyCache = {}//{ "ROI": { "en": "ROI", "fr": "ROI 2" } }

// Custom hook for translation
export const translate = (text: string) => {
  const dispatch = useAppDispatch();
  const [translatedText, setTranslatedText] = useState(text)
  let selectedLanguage = store?.getState().appData?.selectedLanguage;
  selectedLanguage = selectedLanguage ? selectedLanguage : "en";

  useEffect(() => {
    const fetchTranslation = async () => {
      const response = await fetchTranslate(text, selectedLanguage);
      setTranslatedText(response);
    };
    fetchTranslation();
  }, [dispatch, text, selectedLanguage]);

  return translatedText;
};

const ongoingRequests = {};
export const fetchTranslate = async (text, language = "") => {
  if (!text || text === "" || language === "en") { //To avoid the translation of undefined or null text, skip the English language.
    return text;
  } else {
    // Check if the text is already cached in Custom Terminology list
    if (CustomTerminologyCache[text] && CustomTerminologyCache[text][language]) {
      return CustomTerminologyCache[text][language];
    } else {
      // Check if there is an ongoing request for the same text
      if (ongoingRequests[text]) {
        return ongoingRequests[text];
      } else {
        // If no cached or ongoing request, make a new translation request
        const dispatch = store.dispatch;
        // Store the promise in the ongoingRequests object
        ongoingRequests[text] = dispatch<any>(getTranslation(text)).then((result) => {
          const translatedText = result?.payload || text;
          // Remove the promise from ongoingRequests after it resolves
          delete ongoingRequests[text];
          return translatedText;
        });
      }
      return ongoingRequests[text];
    }
  }
};

// Functional component, can be used like <TranslateComponent>text</TranslateComponent> **USE TRANSLATECOMPONENT OVER TRANSLATE ANY RETURN STATEMENT TO NOT BREAK DUE TO HOOKS**
export const TranslateComponent = props => {
  const text = translate(props.children)
  return <>{text}</>
}

export default translate