// import { diff } from 'just-diff'
import { emptyArrayifNotArray, newDateString } from '../../helper'
import { updateCanvasDetails } from '../Canvas/update'
import { updateSeriesData, updateSeriesTypes } from '../updateMethods'
import { shouldUpdateScale } from '../Scale/helper'
import { updateScale } from '../Scale/update'
import { updateAfterPadding } from '../Padding/update'
import { isEqual } from "lodash";
// Config Change Detected - Will update State
export const updateConfig = (setState) => (config) => {
  setState((state) => {
    // Initiate State
    let newState = {
      ...(state || {}),
      internalLegend: {
        ...(state?.internalLegend || {}),
        isInternal: Boolean(config?.legends)
      }
    }

    // Pre work
    const stateTypes = (state?.seriesTypes || [])?.map((obj: any) => {
      const type = {
        ...(obj || {})
      }

      if (type?.lastUpdatedAt) {
        delete type.lastUpdatedAt
      }

      return type
    })
    const xAxes = emptyArrayifNotArray(config?.x)
    const yAxes = emptyArrayifNotArray(config?.y)
    const axes = [...xAxes, ...yAxes]
    const stateAxes = emptyArrayifNotArray(newState?.axes)

    // Check Differences
    const canvasDiff = isEqual(newState?.canvas, config?.canvas)
    const typesDiff = isEqual(stateTypes, config?.series?.types)
    const dataDiff = isEqual(
      newState?.seriesData,
      config?.series?.data
    )
    const axisDiff = isEqual(stateAxes, axes)

    // Update Canvas
    if (!canvasDiff) {
      const newStateUpdated = updateCanvasDetails(newState, config) || {}
      newState = {
        ...newStateUpdated
      }
    }

    // Update Types
    if (!typesDiff) {
      const newStateUpdated = updateSeriesTypes(newState, config?.series?.types)
      newState = {
        ...newStateUpdated
      }
    }

    // Update data
    if (!dataDiff) {
      const newStateUpdated = updateSeriesData(newState, {
        data: config?.series?.data,
        dataLastUpdatedAt: config?.series?.dataLastUpdatedAt
      })
      newState = {
        ...newStateUpdated
      }
    }

    if (!axisDiff) {
      newState = {
        ...newState,
        axes: axes,
        axesT: newDateString()
      }
    }

    // Update
    if (
      !canvasDiff ||
      !typesDiff ||
      !dataDiff ||
      !axisDiff
    ) {
      const updatedScale: any = {}
      const updatedAxisCanvas: any = {}

      const updateDetails = (obj: any = {}) => {
        const { key: namedKey, scale } = obj
        const key = namedKey
        const axisCanvas = {
          axisKey: key,
          canvasIndex: !obj?.canvasIndex ? '0' : obj?.canvasIndex,
          scaleType: scale?.props?.type
        }
        const pAxisCanvas = newState?.axisCanvas?.[key]

        const pAxisObj: any =
          newState?.axis?.find((obj: any) => obj?.key === key) || {}
        const isUpdateScale =
          shouldUpdateScale(pAxisObj, obj) ||
          !canvasDiff ||
          !typesDiff ||
          !dataDiff

        const isACEq =
          pAxisCanvas?.[key]?.canvasIndex === axisCanvas?.canvasIndex
        if (isUpdateScale) {
          updatedScale[key] = obj
        }
        if (!isACEq) {
          updatedAxisCanvas[key] = axisCanvas
        }
      }

      axes?.forEach((obj: any) => {
        updateDetails(obj)
      })

      newState = {
        ...newState,
        axisCanvas: updatedAxisCanvas,
        axisCanvasT: newDateString()
      }
      const scaleState = updateScale(newState, updatedScale)
      newState = {
        ...newState,
        ...scaleState
      }

      const updatedStateAfterPadding = updateAfterPadding(newState, config)

      newState = {
        ...updatedStateAfterPadding
      }
    }

    return newState
  })
}
