import { CLIENTS, PRIMARY_OFFERINGS } from "./primary-offerings"
import { PRIMARY_OFFERING, CLIENT } from "./properties"

export const DEFAULT_PROPERTIES_TO_SEND_BY_EVENT = {
	//[PRIMARY_OFFERING]: PRIMARY_OFFERINGS.SHARED,
	//[CLIENT]: CLIENTS.CLIENTTAG
}

const getCurrentPageName = () => typeof window !== "undefined" ? window.location.pathname.split("/")[1] : null

export const ADDITIONAL_PROPERTIES_TO_SEND = {
	// For the "Previous Page" property, we ONLY want the first part of the URL after trane.com/.
	// https://tranetis.jira.com/wiki/spaces/GAS/pages/841252894/Alpha+Team+Amplitude+Event+Tracking
	PreviousPage: () => getCurrentPageName()
}
