import moment from "moment"
import { ACTIONS } from "src/constants/actions"
import * as yup from 'yup'
import { DATE_FORMAT, BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import i18next from "i18next"
import { isValidPhoneNumber } from "react-phone-number-input"
import isArray from 'lodash/isArray'
import { isEmpty } from "lodash";
import { imageCategory } from "./constants"


export const documentTypeLists = [
	"Coaching Services",
	"Quote/Estimate",
	"Design Documents",
	"Service Advisory",
	"Equipment Notes",
	"Performance",
	"Other",
	"Digital Inspections" 
]

export const consultationType = 'Intelligent Services Consultation'
export const energyUsageReportType = 'Energy Usage Report'
export const documentType = 'Document'

export const Summary = "Per the utility billing data ranging from <start date> through <end date>, the initial utility analysis findings are:\nThe building has an EUI of <current EUI> compared to the industry average of <industry avg EUI>. This indicated that the building has <a strong potential to reduce energy usage> <is performing as an Energy Star building today>. Potential savings to achieve <benchmark name> would be approximately <current energy spend minus industry average> per year.\nDemand makes up <demand percent> of the overall utility spend. Demand limiting and/or \"peak shaving\" should be a primary focus to reduce energy costs. A <reduction percent> reduction would drive a <savings estimate> per year savings in addition to the consumption savings."

export const documentTypeList =(t)=>(
     {
		"Coaching Services": t("reports:types>CoachingServices"),
		"Quote/Estimate": t("reports:types>Quote/Estimate"),
		"Design Documents": t("reports:types>DesignDocuments"),
		"Service Advisory": t("reports:types>ServiceAdvisory"),
		"Equipment Notes": t("reports:types>EquipmentNotes"),
		"Performance": t("reports:types>Performance"),
		"Other": t("reports:types>Other"),
		"Digital Inspections": ("Digital Inspections"), 
	})

export const documentStatusList = (t)=>({
		"Draft": t("reports:statuses>draft"),
		"Complete": t("reports:statuses>complete")
	})

export const getAttachedDetails=(newFiles,attachments,buildingId)=>{
		for (let i = 0; i < newFiles.length; i++) {
			try{
		  const { file, loading, error } = newFiles[i];
		  if (attachments.findIndex((a) => a?.title === file?.title) !== -1) {
			const index = attachments?.findIndex((a) => a?.title === file?.title)
			attachments[index] = {
			  id: attachments[index]?.id || "",
			  documentId: attachments[index]?.documentId || "",
			  timestamp: moment().valueOf(),
			  buildingId: buildingId,
			  fileName: file?.name,
			  title: file?.title,
			  filePath: `${buildingId}/${file?.name}`,
			  fileSize: file?.size,
			  type: file?.type,
			  isNew: true,
			  loading,
			  error: error || null,
			  customClasses: loading ? 'loading' : error ? 'error' : '',
			  lastModified: file?.lastModified,
			  lastModifiedDate: file?.lastModifiedDate,
			  webkitRelativePath: file?.webkitRelativePath
			}
		  } else {
			attachments.push({
			  timestamp: moment().valueOf(),
			  buildingId: buildingId,
			  fileName: file?.name || "",
			  title: file?.title,
			  filePath: `${buildingId}/${file?.name}`,
			  fileSize: file?.size,
			  type: file?.type,
			  isNew: true,
			  loading,
			  error: error || null,
			  customClasses: loading ? 'loading' : error ? 'error' : '',
				lastModified: file?.lastModified,
				lastModifiedDate: file?.lastModifiedDate,
				webkitRelativePath: file?.webkitRelativePath
			})
		  }
		}
		  catch (e) {}
		}
	
	return attachments; 
	  
}

const STATUS = new Map([
	["inProgress", "In Progress"],
	["collectingData", "Collecting Data"],
	["readyForReview", "Ready for Review"],
	["archive", "Archive"]
])

export const STATUS_OPTION_LIST = Array.from(STATUS, ([key, value]) => ({ key, value }))

export function getStatusOptionList(t) {
	if (!t) {
		return STATUS_OPTION_LIST
	}

	return Array.from(STATUS.keys(), key => ({ key, value: t(`components:statuses>${key}`) || key }))
}

const VISUALIZATION = new Map([
	["Action Tracking", "Action Tracking"],
	["Image Upload", "Image Upload"]
])

export const VISUALIZATION_OPTION_LIST = Array.from(VISUALIZATION, ([key, value]) => ({ key, value }))
export function getVisualizationOptionList(t) {
	if (!t) {
		return VISUALIZATION_OPTION_LIST
	}
	return Array.from(VISUALIZATION.keys(), key => ({ key, value: `${key}` || key }))
}
const VISUALIZATION_ENERGY = new Map([
	["Key Metric Savings", "Key Metric Savings"],
	["Image Upload", "Image Upload"]
])
export const VISUALIZATION_ENERGY_OPTION_LIST = Array.from(VISUALIZATION_ENERGY, ([key, value]) => ({ key, value }))
export function getVisualizationList(t) {
	if (!t) {
		return VISUALIZATION_ENERGY_OPTION_LIST
	}
	return Array.from(VISUALIZATION_ENERGY.keys(), key => ({ key, value: `${key}` || key }))
}

export const CHART_IMAGE_PATHS = {
	WHAT_WE_HAVE_SAVED : "whatWeHaveSaved",
	WHERE_WE_HAVE_BEEN : "whereWeHaveBeen",
	WHAT_WE_HAVE_DONE  : "whatWeHaveDone"
}

export const IMAGE = "IMAGE"
export const CHART = "CHART"
export const IMAGE_UPLOAD = "Image Upload"
export const KEY_METRIC_SAVINGS = "Key Metric Savings"
export const ACTION_TRACKING = "Action Tracking"

export const highlights = [
	{
	  title: '',
	  impactAmount: '',
	  impactAmountType: 'usd',
	  impactText: '',
	  impactType: 'comfort-impact'
	},
	{
	  title: '',
	  impactAmount: '',
	  impactAmountType: 'usd',
	  impactText: '',
	  impactType: 'comfort-impact'
	},
	{
	  title: '',
	  impactAmount: '',
	  impactAmountType: 'usd',
	  impactText: '',
	  impactType: 'comfort-impact'
	}
  ]

export const energyUsageReportHighlights = [
	{
		title: "",
		impactText: "",
		icon: "home-electricity-use",
		impactType: 'home-electricity-use'
	},
	{
		title: "",
		impactText: "",
		icon: "home-electricity-use",
		impactType: 'home-electricity-use'
	},
	{
		title: "",
		impactText: "",
		icon: "home-electricity-use",
		impactType: 'home-electricity-use'
	}
]

export const getHighLight = (fetchedDocument) => {
	if (!fetchedDocument) return []
	const data = Object.keys(fetchedDocument).filter((it) =>
		it.includes('highlight') && !it.includes('highlightTitle')
	)
	return data?.map((item) => ({ ...fetchedDocument[item], impactText: fetchedDocument[item]?.impactText ? JSON.parse(fetchedDocument[item]?.impactText) : '',
	value: fetchedDocument[item]?.impactText ? JSON.parse(fetchedDocument[item]?.impactText) : '' }))
}

export const TEXT_EDITOR_TOOLBAR_OPTIONS = {
	options: ['blockType', 'textAlign', 'list', 'link', 'inline'],
	fontSize: {
	  options: [8, 9, 10, 11, 12, 14, 16, 18, 24]
	},
	inline: {
	  options: ['bold', 'italic', 'underline', 'superscript', 'subscript']
	},
	link: {
	  options: ['link']
	},
	list: {
	  options: ['unordered', 'ordered', 'indent', 'outdent']
	},
	textAlign: {
	  options: ['left', 'center', 'right']
	}
}

export const HIGHLIGHTS_TEXT_EDITOR_TOOLBAR_OPTIONS = {
	options: ['blockType', 'inline', 'list', 'link'],
	inline: {
		options: ['bold', 'italic', 'underline']
	},
	link: {
		options: ['link', 'unlink']
	},
	list: {
		options: ['unordered', 'ordered']
	},
}

export const getEUReportFormValues = (mode, fetchedDocument, location, userInfo, utilityData = null) => {
	let startDt = null
	let endDt = null
	const validUtilityData = utilityData?.filter(f => f?.eui !== 0) ?? []
	if (validUtilityData && validUtilityData?.length) {
		const maxDt = Math.max(...validUtilityData.map(o => new Date(o?.year, o?.month, 0)), 0)
		endDt = moment(new Date(maxDt)).endOf("month").format(DATE_FORMAT)
		startDt = moment(new Date(maxDt)).subtract(11, "months").startOf("month").format(DATE_FORMAT)
	}
	const values = {
		// Header Section
		reportTitle: '',
		reportId: '',
		visibility: true,
		buildingId: location,
		startDate: startDt ? startDt : moment(moment().format(DATE_FORMAT)).format(DATE_FORMAT),
		endDate: endDt ? endDt : moment(moment().format(DATE_FORMAT)).format(DATE_FORMAT),
		status: 'In Progress',
		contact: '',
		phoneNumber: userInfo?.phoneNumber || '',
		email: '',
		summaryTitle: 'Energy Usage Breakdown',
		summary: null,

		// PieChart Section
		pieChartData: {
			pieData : [],
			showPieChart: false
		},

		// BarChart Section
		barChartData: {
			barData: [],
			showBarChart: false
		},
		euiTitle: 'Energy Usage Index',
		eciTitle: 'Energy Cost Index',

		// Highlights Section
		highlightTitle: 'Expected Impact if Improved to Industry Average',
		showHighlights: true,
		highlights: energyUsageReportHighlights,
		highlightsError: {},
		diagnostic: null,
	}

	if (mode === ACTIONS.EDIT) {
		values.reportTitle = fetchedDocument?.title,
		values.reportId = fetchedDocument?.id,
		values.visibility = fetchedDocument?.visibility,
		values.buildingId = fetchedDocument?.buildingId,
		values.startDate = moment(fetchedDocument?.startDate).format(DATE_FORMAT),
		values.endDate = moment(fetchedDocument?.endDate).format(DATE_FORMAT),
		values.status = fetchedDocument?.status,
		values.contact = fetchedDocument?.contact,
		values.phoneNumber = fetchedDocument?.phoneNumber,
		values.email = fetchedDocument?.email,
		values.highlightTitle = fetchedDocument?.highlightSection?.highlightTitle,
		values.showHighlights= fetchedDocument?.highlightSection?.showHighlights,
		values.highlights = fetchedDocument?.highlightSection?.highlights,
		values.summary = fetchedDocument?.summarySection?.summary ? JSON.parse(fetchedDocument?.summarySection?.summary ?? '') : '',
		values.summaryTitle = fetchedDocument?.summarySection?.summaryTitle,
		values.pieChartData = fetchedDocument?.chartSection?.pieChartData,
		values.barChartData = fetchedDocument?.chartSection?.barChartData,
		values.euiTitle= fetchedDocument?.chartSection?.barChartData?.euiTitle,
		values.eciTitle= fetchedDocument?.chartSection?.barChartData?.eciTitle
	} 
	else if (mode === ACTIONS.ADD) {
		values.contact = `${userInfo?.firstName} ${userInfo?.lastName}`,
		values.email = userInfo?.email
	}
	return values
}

export const getFormValues = (mode, fetchedDocument, organizationId, location) => {
	const values = {

		// Header Section
		consultationId: null,
		opportunityLimitMsg:'',
		consultationTitle: "Consultation",
		visibility: true,
		building: isArray(location) ? location : [location],
		organization: organizationId,
		rangeFrom: moment().format(DATE_FORMAT),
		rangeTo: moment().format(DATE_FORMAT),
		status: 'In Progress',
		contact: '',
		phoneNumber: '',
		email: '',
		sectionTitleHighlight:'Highlight Title',

		// What We Have Saved
		whatWeHaveSavedSelect: KEY_METRIC_SAVINGS,
		whatWeHaveSavedDisplay: true,
		sectionTitleWhatWeHaveSaved: "What We've Saved",
		whatWeHaveSavedImageDetails: null,
		whatWeHaveSavedImageId: null,

		// What We Have Done
		whatWeHaveDone: ACTION_TRACKING,
		whatWeHaveDoneDisplay: true,
		sectionTitleWhatWeHaveDone: "What We've Done",
		whatWeHaveDoneImageDetails: null,
		whatWeHaveDoneImageId: null,

		//Where We Have Been
		whereHaveYouBeenDisplay: true,
		sectionTitleWhereWeHaveBeen: "Where We've Been",
		whereHaveYouBeenImageDetails: null,
		whereHaveYouBeenImageId: null,

		//The Big Picture
		sectionTitleBigPicture: "The Big Picture",
		theBigPictureDisplay: true,
		theBigPictureDetails: null,

		// Where We Go From Here
		whereWeGoFromHereDisplay: true,
		sectionTitleWhereWeGoFromHere: "Where We Go From Here",
		opportunityList: [],
		existingOpportunityList: [],

		// Highlights
		showHighlight: true,
		highlights: highlights,
		highlightsError: {},

		attachments: [],
		existingAttachments: [],

		//Assumptions
		assumptionValueId: null,
		assumptionValue: null
	}
	if (mode === ACTIONS.EDIT || mode === ACTIONS.COPY) {
		values.building = isArray(fetchedDocument?.buildingIds) ? fetchedDocument?.buildingIds : [fetchedDocument?.buildingIds] ,
		values.organization = fetchedDocument?.accountId,
		//Header Section
		values.consultationId = mode === ACTIONS.EDIT ? fetchedDocument?.id : null,
		values.consultationTitle = mode === ACTIONS.COPY ? `Copy of ${fetchedDocument?.title}` : fetchedDocument?.title
		values.visibility = fetchedDocument?.visibility
		values.building = fetchedDocument?.buildingIds,
		values.organization = fetchedDocument?.accountId,
		values.rangeFrom = moment(fetchedDocument?.startDate).format(DATE_FORMAT),
		values.rangeTo = moment(fetchedDocument?.endDate).format(DATE_FORMAT),
		values.status = fetchedDocument?.status,
		values.contact = fetchedDocument?.contact,
		values.phoneNumber = fetchedDocument?.phoneNumber,
		values.email = fetchedDocument?.email,
		values.sectionTitleHighlight = fetchedDocument?.highlightTitle,

		// What We Have Saved
		values.whatWeHaveSavedSelect = fetchedDocument?.whatWeHaveSavedType
		values.whatWeHaveSavedDisplay = fetchedDocument?.whatWeHaveSavedDisplay
		values.sectionTitleWhatWeHaveSaved = fetchedDocument?.whatWeHaveSavedTitle
		values.whatWeHaveSavedImageId = fetchedDocument?.whatWeHaveSavedImageId
		values.whatWeHaveSavedImageDetails = fetchedDocument?.attachments?.find(f=>f?.category === CHART_IMAGE_PATHS.WHAT_WE_HAVE_SAVED ),

		// What We Have Done
		values.whatWeHaveDone = fetchedDocument?.whatWeHaveDoneType,
		values.whatWeHaveDoneDisplay = fetchedDocument?.whatWeHaveDoneDisplay,
		values.sectionTitleWhatWeHaveDone = fetchedDocument?.whatWeHaveDoneTitle,
		values.whatWeHaveDoneImageId = fetchedDocument?.whatWeHaveDoneImageId,
		values.whatWeHaveDoneImageDetails = fetchedDocument?.attachments?.find(f=>f?.category === CHART_IMAGE_PATHS.WHAT_WE_HAVE_DONE ),


		//Where We Have Been
		values.whereHaveYouBeenDisplay = fetchedDocument?.whereHaveYouBeenDisplay,
		values.sectionTitleWhereWeHaveBeen = fetchedDocument?.whereHaveYouBeenTitle,
		values.whereHaveYouBeenImageId = fetchedDocument?.whereHaveYouBeenImageId,
		values.whereHaveYouBeenImageDetails = fetchedDocument?.attachments?.find(f=>f?.category === CHART_IMAGE_PATHS.WHERE_WE_HAVE_BEEN ),


		//The Big Picture
		values.sectionTitleBigPicture = fetchedDocument?.theBigPictureTitle,
		values.theBigPictureDisplay = fetchedDocument?.theBigPictureDisplay,
		values.theBigPictureDetails = JSON.parse(fetchedDocument?.theBigPictureDetailsJson),

		// Where We Go From Here
		values.whereWeGoFromHereDisplay = fetchedDocument?.whereWeGoFromHereDisplay,
		values.sectionTitleWhereWeGoFromHere = fetchedDocument?.whereWeGoFromHereTitle,

		// Highlights
		values.showHighlight = fetchedDocument?.showHighlight
		values.highlights = getHighLight(fetchedDocument)

		//Attachment
		values.attachments = fetchedDocument?.attachments
		values.existingAttachments = mode === ACTIONS.COPY ? [] : fetchedDocument?.attachments

		//Opportunities
		values.opportunityList = fetchedDocument?.opportunities?.items?.map(o => ({ key: o?.opportunity?.id, value: o?.opportunity?.title, buildingId: o?.opportunity?.buildingId}))
		values.existingOpportunityList = mode === ACTIONS.COPY ? [] : fetchedDocument?.opportunities?.items?.map(o => ({ key: o?.opportunity?.id, value: o?.opportunity?.title, buildingId: o?.opportunity?.buildingId }))

		//Assumptions
		values.assumptionValueId = fetchedDocument?.assumptionValueId
		values.assumptionValue = fetchedDocument?.assumptionValue
	}
	return values
}

export const getEUReportUpsertInput = (mode, formValues) => {
	const hlSection = {
		highlights: formValues?.highlights,
		highlightTitle: formValues?.highlightTitle,
		showHighlights: formValues?.showHighlights
	}

	const barChartSection = {
		...formValues?.barChartData,
		euiTitle: formValues?.euiTitle,
		eciTitle: formValues?.eciTitle
	}

	const chartData = {
		pieChartTitle: formValues?.pieChartTitle,
		pieChartData: formValues?.pieChartData,
		
		barChartData: barChartSection
	}

	const summarySec = {
		summary: JSON.stringify(formValues?.summary) || "",
		summaryTitle: formValues?.summaryTitle
	}

	const input = {
		highlightSection: JSON.stringify(hlSection),
		title: formValues?.reportTitle,
		visibility: formValues?.visibility,
		buildingId: formValues?.buildingId,
		accountId: formValues?.organization,
		contact: formValues?.contact || "",
		email: formValues?.email || "",
		startDate: moment(formValues?.startDate).format(BACKEND_DATE_FORMAT),
		endDate: moment(formValues?.endDate).format(BACKEND_DATE_FORMAT),
		phoneNumber: formValues?.phoneNumber || "",
		status: formValues?.status,
		chartSection: JSON.stringify(chartData),
		summarySection: JSON.stringify(summarySec)
	}
	if (mode === ACTIONS.EDIT)
		input["id"] = formValues?.reportId
	return input
}

const editorDefaultValue = {
	blocks: [],
	entityMap: {}
}

export const getUpsertInput = (mode,formValues) => {
	const highlightData = formValues?.highlights?.reduce(
		(acc, curr, index) => ({
			...acc,
			['highlight' + (index + 1)]: curr?.impactAmountType && (curr?.impactAmount ? {
				title: curr?.title,
				impactAmount: curr?.impactAmount,
				impactAmountType: curr?.impactAmountType,
				impactText: JSON.stringify(isEmpty(curr?.impactText) ? editorDefaultValue : curr?.impactText),
				impactType: curr?.impactType
			} :
				{
					title: curr?.title,
					impactAmountType: curr?.impactAmountType,
					impactText: JSON.stringify(isEmpty(curr?.impactText) ? editorDefaultValue : curr?.impactText),
					impactType: curr?.impactType
				})
		}),
		{}
	)
	const input = {
		...highlightData,
		highlightTitle: formValues?.sectionTitleHighlight,
		assumptionValueId: formValues?.assumptionValueId,
		showHighlight:formValues?.showHighlight,
		title: formValues?.consultationTitle,
		visibility:formValues?.visibility,
		accountId:formValues?.organization,
		buildingIds: formValues?.building,
		contact: formValues?.contact || "",
		email: formValues?.email || "",
		startDate: moment(formValues?.rangeFrom).format(BACKEND_DATE_FORMAT),
		endDate: moment(formValues?.rangeTo).format(BACKEND_DATE_FORMAT),
		phoneNumber: formValues?.phoneNumber || "",
		status: formValues?.status,
		
		theBigPictureDetailsJson: JSON.stringify(formValues?.theBigPictureDetails ?? editorDefaultValue),
		theBigPictureDisplay:formValues?.theBigPictureDisplay,
		theBigPictureTitle: formValues?.sectionTitleBigPicture,

		whatWeHaveDoneTitle: formValues?.sectionTitleWhatWeHaveDone,
		whatWeHaveDoneDisplay:formValues?.whatWeHaveDoneDisplay,
		whatWeHaveDoneType:formValues?.whatWeHaveDone,

		whatWeHaveSavedTitle: formValues?.sectionTitleWhatWeHaveSaved,
		whatWeHaveSavedDisplay:formValues?.whatWeHaveSavedDisplay,
		whatWeHaveSavedType:formValues?.whatWeHaveSavedSelect,

		whereHaveYouBeenDisplay:formValues?.whereHaveYouBeenDisplay,
		whereHaveYouBeenTitle: formValues?.sectionTitleWhereWeHaveBeen,
		whereWeGoFromHereTitle: formValues?.sectionTitleWhereWeGoFromHere,
		whereWeGoFromHereDisplay:formValues?.whereWeGoFromHereDisplay
	  }
	if (mode === ACTIONS.EDIT)
		input["id"] = formValues?.consultationId
	return input
}
export const MAX_LENGTH_OF_PROPOSED = 50
export const MAX_LENGTH_OF_NAME = 200
export const MAX_SIZE_OF_NUMBERS = 10000000
export const MAX_LENGTH_OF_IMPACT_AMOUNT = 11

export const validationSchemaEUReport = () => {
	const schema = yup.object().shape({
		reportTitle: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Report Title',
			number: MAX_LENGTH_OF_NAME
		})),
		email: yup.string().max(100, i18next.t("errors:maxAllowedEmail", {
			field: 'Email',
			number: 100
		})).test(
			'testEmail',
			i18next.t('errors:emailAddressIsNotValid'),
			testEmail
		),
		phoneNumber: yup.string().test(
			'testPhone',
			i18next.t('errors:PhoneNumberIsNotValid'),
			testPhone
		),
		summaryTitle: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Title',
			number: MAX_LENGTH_OF_NAME
		})),
		euiTitle: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Title',
			number: MAX_LENGTH_OF_NAME
		})),
		eciTitle: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Title',
			number: MAX_LENGTH_OF_NAME
		})),
		highlightTitle: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Title',
			number: MAX_LENGTH_OF_NAME
		})),
		startDate:yup.string()
		.test(
		  'validateEUDateFormat',
		  `Please enter a valid date in the format ${DATE_FORMAT}`,
		  testDateFormat
		),
		endDate:yup.string()
		.test(
		  'validateEUDateFormat',
		  `Please enter a valid date in the format ${DATE_FORMAT}`,
		  testDateFormat
		),
	})
	return schema
}

export const validationSchemaConsultation = () => {
	const schema = yup.object().shape({
		consultationTitle: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Consultation Title',
			number: MAX_LENGTH_OF_NAME
		  })),
		organization:yup.string().required(i18next.t("errors:organizationIsRequired")),
		building: yup.array().test(
			'testBuilding',
			i18next.t('errors:buildingIsRequired'),
			testBuilding
		  ),
		  highlights: yup.array().test(
			'testhighlights',
			'',
			testhighlights
		  ),
		email: yup.string().max(100, i18next.t("errors:maxAllowedEmail", {
			field: 'Email',
			number: 100
		  })).test(
			'testEmail',
			i18next.t('errors:emailAddressIsNotValid'),
			testEmail
		  ),
		phoneNumber: yup.string().test(
			'testPhone',
			i18next.t('errors:PhoneNumberIsNotValid'),
			testPhone
		  ),
		sectionTitleBigPicture: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Title',
			number: MAX_LENGTH_OF_NAME
		  })),
		sectionTitleHighlight: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Title',
			number: MAX_LENGTH_OF_NAME
		  })),
		sectionTitleWhatWeHaveSaved: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Title',
			number: MAX_LENGTH_OF_NAME
		  })),
		sectionTitleWhereWeHaveBeen: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Title',
			number: MAX_LENGTH_OF_NAME
		  })),
		sectionTitleWhatWeHaveDone: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Title',
			number: MAX_LENGTH_OF_NAME
		  })),
		sectionTitleWhereWeGoFromHere: yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_LENGTH_OF_NAME, i18next.t("errors:maxAllowedFieldLength", {
			field: 'Title',
			number: MAX_LENGTH_OF_NAME
		  })),
		rangeFrom:yup.string()
		.test(
		  'testDateFormat',
		  `Please enter a valid date in the format ${DATE_FORMAT}`,
		  testDateFormat
		),
		rangeTo:yup.string()
		.test(
		  'testDateFormat',
		  `Please enter a valid date in the format ${DATE_FORMAT}`,
		  testDateFormat
		),
		opportunityLimitMsg: yup.string()
		.test(
		  'testOpportunityLimit',
		  i18next.t('errors:ReachedMaximumNumberOfSelections'),
		  testOpportunityLimit
		)
	})
	return schema
}
function testOpportunityLimit(value) {
	const {
		parent: { opportunityList }
	  } = this
	  return !(
		opportunityList?.length > 5
	  )
}
function testBuilding(value) {
	const {
		createError
	} = this
	if (value === "" || value === undefined || !value?.length) {
		return createError({ message: i18next.t("errors:buildingIsRequired") });
	}
	return true
}
function testhighlights(value){
	const {
		createError
	} = this
	let index = 0
	const highlightErrors = value?.map(({ impactAmount,impactAmountType,impactText,impactType,title }) => {
		let error=''
		index++
		if (Number(impactAmount) > MAX_SIZE_OF_NUMBERS || String(impactAmount).length > MAX_LENGTH_OF_IMPACT_AMOUNT) {
			error = i18next.t("errors:enterValidNumber",{maxNumber: MAX_SIZE_OF_NUMBERS})
		}
		return { impactAmount, impactAmountType, impactText, impactType, title, error, index }
	})
    const isValid = highlightErrors.some(h => h.error)
	if (isValid) {
		return createError({ message: JSON.stringify(highlightErrors) });
	}
	return true
}
function testEmail(value) {
	const regex =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i // eslint-disable-line
    const isValid = regex.test(value) || value === '' || !value
	return isValid
}
function testPhone(value) {
	const {
		createError
	} = this
	if (value) {
		if (value !== "" && !isValidPhoneNumber(value)) {
			return createError({ message: i18next.t("errors:PhoneNumberIsNotValid") });
		}
	}
	return true
}
export const isDateFormatCorrect = (value, format) => {
    return (
      moment(value, format).format(format) === value ||
      !value ||
      value.length === 0
    )
  }
function testDateFormat(value) {
	const { createError } = this

	let rangeFrom = null
	let rangeTo = null

	if (this.type === "testDateFormat") {
		rangeFrom = this.parent.rangeFrom
		rangeTo = this.parent.rangeTo
	} else if (this.type === "validateEUDateFormat") {
		rangeFrom = this.parent.startDate
		rangeTo = this.parent.endDate
	}
	const rangeFromTS = rangeFrom && rangeFrom.length ? new Date(rangeFrom).getTime() : new Date(null).getTime()
	const rangeToTS = rangeTo && rangeTo.length ? new Date(rangeTo).getTime() : new Date().getTime()
	const isRangeFromDateCorrect = isDateFormatCorrect(value, DATE_FORMAT)
	if (!value) {
		return createError({ message: i18next.t("errors:dateIsRequired") });
	}

	if (!isRangeFromDateCorrect) {
		return createError({ message: i18next.t("errors:enterValidDate", { dateFormat: DATE_FORMAT }) });
	}

	if (rangeFromTS > rangeToTS) {
		return createError({ message: i18next.t("errors:enterValidStartDate") });
	}

	if (rangeToTS < rangeFromTS) {
		return createError({ message: i18next.t("errors:enterValidEndDate") });
	}
	return true;
}


export const handleConsultatoinOptionSectionTitle = (
	imageCategory,
	whatWeHaveSavedTitle,
	whereHaveYouBeenTitle,
	whatWeHaveDoneTitle
) => {
	switch(imageCategory) {
		case 'whatWeHaveDone':
			return whatWeHaveDoneTitle
		case 'whatWeHaveSaved':
			return whatWeHaveSavedTitle
		case 'whereWeHaveBeen':
			return whereHaveYouBeenTitle
		default:
			return ''
	}
}

export const fetchConsultationWithImages = async (data, fetchImage) => {
	try {
    const imageData = []
    const imageCategoryArray = []
    const OpportunityData = [];
    const consultationData = data
    const whereWeGoFromHere = {
      enable: consultationData?.whereWeGoFromHereDisplay,
      title: consultationData?.whereWeGoFromHereTitle
    }
    let img;
    if (consultationData?.attachments?.items.length === 0) {
      for (const category of imageCategory) {
        img = {
          mode: "IMAGE",
          original: null,
          enabled: true,
          category: category,
          title: handleConsultatoinOptionSectionTitle(
            category,
            consultationData?.whatWeHaveSavedTitle,
            consultationData?.whereHaveYouBeenTitle,
            consultationData?.whatWeHaveDoneTitle
            )
        }
        consultationData[category] = img
        imageData.push(img) 
      }
    }
    if (consultationData?.attachments?.items.length > 0) {
        for (const image of consultationData?.attachments?.items) {
          if (imageCategory.includes(image.category)) {
            img = await fetchImage(image)
            img.mode = image?.type?.includes("image") ? "IMAGE" : "CHART"
            consultationData[image.category] = img
            imageCategoryArray.push(image.category)
          }
          imageData.push(img)
      }
      if (imageCategoryArray?.length < 3 ) {
        const imageList = imageCategory.filter((el) => !imageCategoryArray.includes(el))
        for (const imageElement of imageList) {
          img = {
            mode: "IMAGE",
            original: null,
            enabled: true,
            category: imageElement,
            title: handleConsultatoinOptionSectionTitle(
              imageElement,
              consultationData?.whatWeHaveSavedTitle,
              consultationData?.whereHaveYouBeenTitle,
              consultationData?.whatWeHaveDoneTitle
              )
          }
          consultationData[imageElement] = img
          imageData.push(img)
        }
      }
      }
      for (const opportunity of consultationData?.opportunities?.items) {
          const testObject = opportunity["opportunity"]
          const opportunityObject = {
            name: testObject?.title,
            opportunityId: testObject?.id,
            settings: {
              costSavings: {
                savings: {
                          value: testObject?.energySavings,
                          tag: testObject?.energySavingsUnit,
                      },
                      costToImplement: {
                        value: testObject?.price,
                        enabled: testObject?.priceDisplay,
                        priceType: testObject?.priceUnit,
                      },
                      lifecycle: {
                        value: "0",
                        tag: "years",
                      },
                  },
                  kpi: [
                    {
                      custom: null,
                      name: "energyUsage",
                      priority: testObject?.impactEnergy,
                      value: testObject?.impactEnergyDisplay,
                      savings: null,
                      timePeriod: null,
                    },
                    {
                      custom: null,
                      name: "comfort",
                      priority: testObject?.impactComfort,
                      value: testObject?.impactComfortDisplay,
                      savings: null,
                      timePeriod: null,
                    },
                    {
                      custom: null,
                      name: "reliability",
                      priority: testObject?.impactReliability,
                      value: testObject?.impactReliabilityDisplay,
                      savings: null,
                      timePeriod: null,
                    },
                    {
                      custom: null,
                      name: "performance",
                      priority: testObject?.impactPerformance,
                      value: testObject?.impactPerformanceDisplay,
                      savings: null,
                      timePeriod: null,
                    },
                    {
                        custom: null,
                        name: "compliance",
                        priority: testObject?.impactCompliance,
                        value: testObject?.impactComplianceDisplay,
                        savings: null,
                        timePeriod: null,
                    },
                    {
                        custom: 1,
                        name: testObject?.impactCustom1Name ? testObject?.impactCustom1Name : 'custom1',
                        priority: testObject?.impactCustom1,
                        value: testObject?.impactCustom1Display,
                        savings: null,
                        timePeriod: null,
                    },
                    {
                        custom: 2,
                        name: testObject?.impactCustom2Name ? testObject?.impactCustom2Name : 'custom2',
                        priority: testObject?.impactCustom2,
                        value: testObject?.impactCustom2Display,
                        savings: null,
                        timePeriod: null,
                    }
                ],
            }
          }
          OpportunityData.push(opportunityObject)
      }
      consultationData.name = consultationData.title
      consultationData.rangeFrom = consultationData.startDate
      consultationData.rangeTo = consultationData.endDate
      consultationData['theBigPictureValueDetails']= {
        value: JSON.parse(consultationData.theBigPictureDetailsJson)
      }   
      consultationData.attachments.items = imageData
      consultationData.opportunities = OpportunityData
      consultationData['whereWeGoFromHere'] = whereWeGoFromHere
      consultationData.highlights = getHighLight(consultationData)
      return consultationData
	} catch(error){
		
	}
  }

  export const getDocTestName = (id) => `Documents${id ? `_${id}` : ""}`

	export const getDateRange = (selectedValue) => {
		const now = moment();
		let startDate;
		switch (selectedValue) {
			case 'Ended in the last week':
			 case 'In the last week':
				startDate = moment().subtract(1, 'weeks');
				break;
			case 'Ended in the last month':
			 case 'In the last month':
				startDate = moment().subtract(1, 'months');
				break;
			case 'Ended in the last 3 months':
			 case 'In the last 3 months':	
				startDate = moment().subtract(3, 'months');
				break;
			case 'Ended in the last 6 months':
				case 'In the last 6 months':
				startDate = moment().subtract(6, 'months');
				break;
			case 'Ended in the last year':
				case 'In the last year':
				startDate = moment().subtract(1, 'years');
				break;
			default:
				startDate = null;
		}
		return { startDate: moment(startDate), endDate: now};
	};
	