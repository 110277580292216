import { useEffect, useState } from 'react'

export const TINY = 'TINY'
export const EXTRA_SMALL = 'EXTRA_SMALL'
export const SMALL = 'SMALL'
export const MEDIUM = 'MEDIUM'
export const LARGE = 'LARGE'
export const EXTRA_LARGE = 'EXTRA_LARGE'

const breakPoints = {
  SCREEN_TINY_MAX_WIDTH: '353px',
  SCREEN_EXTRA_SMALL_MIN_WIDTH: '354px',
  SCREEN_MAX_EXTRA_SMALL_MAX_WIDTH: '633px',
  SCREEN_SMALL_MIN_WIDTH: '634px',
  SCREEN_MAX_SMALL_MAX_WIDTH: '913px',
  SCREEN_MEDIUM_MIN_WIDTH: '914px',
  SCREEN_MAX_MEDIUM_MAX_WIDTH: '1193px',
  SCREEN_LARGE_MIN_WIDTH: '1194px',
  SCREEN_MAX_LARGE_MAX_WIDTH: '1473px',
  SCREEN_EXTRA_LARGE_MIN_WIDTH: '1474px'
}

export const getWindowSize = (width) => {
  switch (true) {
    case width < parseInt(breakPoints.SCREEN_TINY_MAX_WIDTH):
      return TINY
    case width < parseInt(breakPoints.SCREEN_MAX_EXTRA_SMALL_MAX_WIDTH):
      return EXTRA_SMALL
    case width < parseInt(breakPoints.SCREEN_MAX_SMALL_MAX_WIDTH):
      return SMALL
    case width < parseInt(breakPoints.SCREEN_MAX_MEDIUM_MAX_WIDTH):
      return MEDIUM
    case width < parseInt(breakPoints.SCREEN_MAX_LARGE_MAX_WIDTH):
      return LARGE
    default:
      return EXTRA_LARGE
  }
}

export const useBreakPoint = () => {
  const [point, setPoint] = useState(getWindowSize(window.innerWidth))
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      setPoint(getWindowSize(width))
      setWidth(width)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return { point, width }
}
