import initMergeThemeFn from '../common/themeMapper'

// ---------------------------------------------- Default FC Styles ----------------------------------------------

export const defaultStyle = {
  base: {
    disabled: {
      background: '#e2e2e2',
      borderColor: '#e2e2e2',
      color: '#999999'
    },
    error: {
      background: '#fbebea',
      borderColor: '#991909'
    },
    focused: {
      borderColor: '#206fcf'
    }
  },
  label: {
    disabled: {
      color: '#999999'
    },
    error: {
      color: '#991909'
    },
    focused: {
      color: '#206fcf'
    }
  },
  helperText: {
    disabled: {
      // TODO : Remove - if not required for future.
      // Dont show disabled color for helperText
      color: '#999999'
    },
    error: {
      color: '#991909'
    },
    focused: {
      // TODO : Remove - if not required for future.
      // Dont show focused color for helperText
      // color: "#206fcf"
    }
  }
}

// ---------------------------------------------- Theme Mapper ----------------------------------------------

export const mergeTheme = initMergeThemeFn({ defaultStyle, propResolver: {} })
