import { StyleSheet } from "@react-pdf/renderer"
import colors from 'src/components/legacy/common/colors.json'

export const titleStyles = StyleSheet.create({
	titleWrapper: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingTop: 10,
		paddingBottom: 8
	},
	sTitle: {
		maxWidth: "70%",
		color: colors.ORANGE_5,
		fontWeight: "bold",
		fontSize: 14,
	},
	sDates: {
		maxWidth: "30%",
		color: colors.TBA_DARKER_GREY,
		fontSize: 10,
	},
})
