import React from 'react'
import { useTranslation } from 'react-i18next'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'

const DefaultDataSourcePropertySelect = ({
  defaultDataSourceEquipmentNames,
  defaultDataSourcePropertyId,
  dataSourceProperties,
  setSelectedProperty,
  subPropertyOptions,
  setSelectedSubProperty,
  selectedSubProperty
}) => {
  const [t] = useTranslation()
  
  return (
    <>
      <EditableView
        type={EditableView.COMPONENT_TYPE.SELECT}
        labelName={t(
          'equipment-setup:CreateEquipment>DefaultDataSourceProperty'
        )}
        className="default-data-source-property required"
        disabled={!defaultDataSourceEquipmentNames.length}
        name="defaultDataSourcePropertyId"
        value={
          dataSourceProperties.find(
            ({ key }) => key === defaultDataSourcePropertyId
          )?.value
        }
        onChange={(
          defaultDataSourcePropertyId,
          { values, setValues }
        ) => {
          setTimeout(() => {
            setValues({
              ...values,
              defaultDataSourcePropertyId,
            })
            setSelectedProperty(defaultDataSourcePropertyId)
          })
        }}
        options={dataSourceProperties}
      />
      {Boolean(subPropertyOptions.length) && (<EditableView
					type={EditableView.COMPONENT_TYPE.SELECT}
					labelName={t("equipment-setup:DataMapping:EditProperty>SubProperty")}
					className="default-data-source-subproperty required"
					name="arrayIndex"
					value={subPropertyOptions.find(({ key }) => key === selectedSubProperty)?.value}
					options={subPropertyOptions}
          onChange={(
            arrayIndex,
            { values, setValues }
          ) => {
            setTimeout(() => {
              setValues({
                ...values,
                arrayIndex,
              })
              setSelectedSubProperty(arrayIndex)
            })
          }}
				/>)}
    </>
  )
}
export default DefaultDataSourcePropertySelect
