import * as yup from 'yup'
import {phoneRegExp} from "src/common/helperFunctions"
import { useTranslation } from 'react-i18next'
export const getSchema = () => {
  const [t] = useTranslation()
  const schema = yup.object().shape({
    emailId: yup
      .string()
      .email(t('errors:emailAddressIsNotValid'))
      .required(t('errors:emailIsRequired')),
    firstName: yup
      .string()
      .required(t('errors:firstNameIsRequired'))
      .max(50, t('errors:maxAllowedFirstName', { number: 50 })),
    lastName: yup
      .string()
      .required(t('errors:lastNameIsRequired'))
      .max(50, t('errors:maxAllowedLastName', { number: 50 })),
    phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid')
  })
  return schema
}
