import React, { useEffect, useState, Fragment } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { WIDGET_TYPES } from 'src/constants'
import {
  //ParaName,
  RowCount,
  //Title,
  WidgetFilters,
  WidgetLibrary
} from './styles'
import WidgetsPreview from 'src/pages/dashboards/components/DashboardCardWidget/WidgetsPreview'
import { Block } from '@trane/trane-components/dist/simple/layout/index.js'
import * as Spacing from '@trane/trane-components/dist/spacing.js'
import { BiSearchAlt } from 'react-icons/bi/index.esm.js'
import translate from 'src/common/translations'
import Select from 'src/components/legacy/components/select/select'
import { Container, Content } from 'src/components/layouts'
import { useBreakPoint } from 'src/hooks/breakPoint'
import {PaginationIcon,PaginationText} from 'src/components/Table/components'
import {
  WidgetNames,
  WIDGETS_TILE_FILTERING_LIST,
  widgetTileFilteringOptionList,
  widgetTileSortingOptionList,
  getLocationOrOrganizationIdByRedux
} from '../helpers'
import {
  Title,
  WidgetCardStyles,
  ParaName,
  WidgetCardContentStyle,
  WidgetCardStylesContainer
} from 'src/pages/dashboards/components/DashboardCardWidget/style'
import { orderBy, isEmpty } from 'lodash'
import _uniqBy from 'lodash/uniqBy'
import { newRefreshToken } from 'src/redux/slicers/appData'
import { useDispatch, useSelector } from 'react-redux'
import FirstIcon from '@trane/trane-components/dist/assets/icons/Arrow/First.svg'
import FirstDisabledIcon from '@trane/trane-components/dist/assets/icons/Arrow/First_Disabled.svg'
import BackIcon from '@trane/trane-components/dist/assets/icons/Arrow/Back.svg'
import BackDisabledIcon from '@trane/trane-components/dist/assets/icons/Arrow/Back_Disabled.svg'
import NextIcon from '@trane/trane-components/dist/assets/icons/Arrow/Next.svg'
import NextDisabledIcon from '@trane/trane-components/dist/assets/icons/Arrow/Next_Disabled.svg'
import LastIcon from '@trane/trane-components/dist/assets/icons/Arrow/Last.svg'
import LastDisabledIcon from '@trane/trane-components/dist/assets/icons/Arrow/Last_Disabled.svg'
import { DebounceInput } from 'src/components/legacy/components/debounce-input'
import {
  selectBuilding
} from 'src/redux/slicers/buildingPicker'

const WidgetsTileList = (props) => {
  const {
    WidgetsList,
    handleWidgetCopy = null,
    handleWidgetEdit = null,
    handleWidgetDelete = null,
    handleAddWidget = null,
    isDashboardWidgetsTile = false
  } = props
  const MINIMUM_ROW_COUNT = 10;
  const selected = useSelector(selectBuilding) || null
  const [buildingId, setBuildingId] = useState(null)
  const [organizationId, setOrganizationId] = useState(null)
  const [widgets, setWidgets] = useState([])
  const [visibleWidgets, setVisibleWidgets] = useState([])
  const [page, setPage] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [totalItems, setTotalItems] = useState(0)
  const [sortItem, setSortItem] = useState('widgetName')
  const [filterItem, setFilterItem] = useState('All')
  const [filterList, setFilterList] = useState([])
  const { width } = useBreakPoint()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const optionsTranslated = {
    search: translate('Search'),
    copy: translate('Copy'),
    edit: translate('Edit'),
    delete: translate('Delete'),
    page: translate('Page '),
    of: translate(' of '),
    widgetshown: translate('widgets shown'),
    noRecords: translate('No records found'),
    sort: translate('Sort'),
    filter: translate('Filter'),
    addToDashboard: translate('Add to Dashboard'),
    widgets: translate('widgets')
  }

  const pages = Math.ceil(
    (widgets?.length || 0) / MINIMUM_ROW_COUNT
  )
  useEffect(() => {
    if(selected){
      const { buildingId, organizationId } = getLocationOrOrganizationIdByRedux(selected)
      setBuildingId(buildingId)
      setOrganizationId(organizationId)
    }
  }, [selected])

  useEffect(() => {
    if (WidgetsList) {
      const data = handleSortingAndFilterWidgets(
        WidgetsList,
        'widgetName',
        filterItem,
        searchText
      )
      setWidgets(data)
      const widgetTypesList = !_isEmpty(WidgetsList)
      ? WidgetsList?.map((widget) => ({
            name: widget?.widgetType,
            value: widget?.widgetType
        }))
      : []
      const uniqueTypeList = _uniqBy(widgetTypesList,"name")?.map((x) => {
        return {
          name: x?.name,
          value: x?.name
        }
      })
      setFilterList(uniqueTypeList)
    }
  }, [WidgetsList])

  useEffect(() => {
    setTotalItems(widgets?.length)
    setPage(0)
    setVisibleWidgets([])
  }, [widgets])

  useEffect(() => {
    const refresTokenForWidgets = async() => {
      const reset = buildingId ? 
       widgets?.[0]?.buildingId !== buildingId 
      :
      organizationId  ? 
      widgets?.[0]?.accountId !== organizationId : 
      true
      if((reset )){
        setVisibleWidgets([])
        return;
      }
      const widgetsToDisplay = widgets.slice(page * 10,((page + 1) * 10))
      const buildingIds = widgetsToDisplay.reduce((acc,widget) => {
        if(widget.buildingId  &&  !acc.includes(widget.buildingId)){
          acc.push(widget.buildingId);
          return acc
        }else{
          return acc
        }
      },[])
      if(buildingIds?.length > 0){
        await dispatch(newRefreshToken(buildingIds,null, null,[]))
      } 
    setVisibleWidgets(widgetsToDisplay)
  }
  refresTokenForWidgets()
  }, [widgets,page,organizationId])


  const handleSortingAndFilterWidgets = (
    data,
    sortingBy,
    filterBy,
    searchBy
  ) => {
    let widgetsData = []
    let withValueDataList = []
    let withoutValueDataList = []
    let sortList = []
    let withNameSortList = []
    switch (sortingBy) {
      case 'widgetName':
        widgetsData = orderBy(data, ['name'], ['asc'])
        break
      case 'description':
        withValueDataList = data?.filter((d) => !isEmpty(d?.description))
        withoutValueDataList = data?.filter((d) => isEmpty(d?.description))
        sortList = orderBy(withValueDataList, [sortingBy], ['asc'])
        withNameSortList = orderBy(
          withoutValueDataList,
          [sortingBy, 'name'],
          ['asc']
        )
        widgetsData = [...sortList, ...withNameSortList]
        break
      case 'targetedObjectType':
        const buildingsEquipmentsSortList = orderBy(
          data,
          (b) => [
            b?.widgetBuildingTarget?.name,
            b?.widgeEquipment1Name,
            b?.widgeEquipment2Name
          ],
          ['asc']
        )
        widgetsData = buildingsEquipmentsSortList
        break
      case 'newestToOldest':
        withValueDataList = data?.filter((d) => !isEmpty(d?.creationDate))
        withoutValueDataList = data?.filter((d) => isEmpty(d?.creationDate))
        sortList = withValueDataList
          ?.map((d) => ({ ...d, creationDate: new Date(d?.creationDate) }))
          ?.sort(function (a, b) {
            return a?.creationDate - b?.creationDate
          })
        withNameSortList = orderBy(withoutValueDataList, ['name'], ['asc'])
        widgetsData = [...sortList?.reverse(), ...withNameSortList]
        break
      case 'oldestToNewest':
        withValueDataList = data?.filter((d) => !isEmpty(d?.creationDate))
        withoutValueDataList = data?.filter((d) => isEmpty(d?.creationDate))
        sortList = withValueDataList
          ?.map((d) => ({ ...d, creationDate: new Date(d?.creationDate) }))
          ?.sort(function (a, b) {
            return a?.creationDate - b?.creationDate
          })
        withNameSortList = orderBy(withoutValueDataList, ['name'], ['asc'])
        widgetsData = [...sortList, ...withNameSortList]
        break
      default:
        widgetsData = orderBy(data, ['name'], ['asc'])
    }

    const getWidgetType = WIDGETS_TILE_FILTERING_LIST?.find(
      (f) => f?.transName === filterBy
    )?.widgetType
    const filtersWidgets =
      filterBy === 'All'
        ? widgetsData
        : widgetsData?.filter((f) => f?.widgetType === getWidgetType)
    const searchWidgets = filtersWidgets?.filter(
      (f) =>
        f?.name?.toLowerCase()?.includes(searchBy?.toLowerCase()) ||
        f?.description?.toLowerCase()?.includes(searchBy?.toLowerCase()) ||
        f?.widgetTargetType?.toLowerCase()?.includes(searchBy?.toLowerCase())
    )
    return searchWidgets
  }

  const pageOptions = [
    {
      icon: FirstIcon,
      disabledicon: FirstDisabledIcon,
      action: () => setPage(0),
      disabled: (!widgets?.length) || page === 0
    },
    {
      icon: BackIcon,
      disabledicon: BackDisabledIcon,
      action: () => setPage(page => page - 1),
      disabled: (!widgets?.length) || page === 0
    },
    {
      icon: NextIcon,
      disabledicon: NextDisabledIcon,
      action: () => setPage(page => page + 1),
      disabled: (!widgets?.length) || page === pages - 1
    },
    {
      icon: LastIcon,
      disabledicon: LastDisabledIcon,
      action: () => setPage(pages - 1),
      disabled: (!widgets?.length) || page === pages - 1
    }
  ]

  return (
    <>
      <WidgetFilters>
        <Fragment>
          <Container
            className="inline-edit-form"
            sColumns={1}
            mColumns={4}
            lColumns={6}
            xlColumns={12}
            width={width}
          >
            <Content
              xlColumn={3}
              lColumn={3}
              mColumn={3}
              sColumn={3}
              width={width}
              border="none"
            >
              <div>
                <label>{optionsTranslated.sort}:</label>
                <Select
                  options={widgetTileSortingOptionList(t)}
                  onChange={(e) => {
                    setSortItem(e)
                    const data = handleSortingAndFilterWidgets(
                      WidgetsList,
                      e,
                      filterItem,
                      searchText
                    )
                    setWidgets(data)
                  }}
                  selectedItem={t(`widgets:${sortItem}`)}
                />
              </div>
            </Content>
            <Content
              xlColumn={3}
              lColumn={3}
              mColumn={3}
              sColumn={3}
              width={width}
              border="none"
            >
              <div>
                <label>{optionsTranslated.filter}:</label>
                <Select
                  options={widgetTileFilteringOptionList(t, filterList)}
                  onChange={(e) => {
                    setFilterItem(e)
                    const data = handleSortingAndFilterWidgets(
                      WidgetsList,
                      sortItem,
                      e,
                      searchText
                    )
                    setWidgets(data)
                  }}
                  selectedItem={
                    filterItem === 'All'
                      ? filterItem
                      : t(`widgets:${filterItem}`)
                  }
                />
              </div>
            </Content>
            <Content
              xlColumn={3}
              lColumn={3}
              mColumn={3}
              sColumn={3}
              width={width}
              border="none"
            >
              <Block margin={`0 ${Spacing.SPACING_05} 0 0`}>
                <DebounceInput
                className = "widgetSearch"
                debounceTimeout={500}
                  onChange={(e) => {
                    const searchValue = e?.target?.value
                    setSearchText(searchValue)
                    const data = handleSortingAndFilterWidgets(
                      WidgetsList,
                      sortItem,
                      filterItem,
                      searchValue
                    )
                    setWidgets(data)
                  }}
                  value={searchText}
                  placeholder={optionsTranslated.search}
                  icon={<BiSearchAlt />}
                />
              </Block>
            </Content>
            <Content
              xlColumn={3}
              lColumn={3}
              mColumn={3}
              sColumn={3}
              width={width}
              border="none"
            >
              {!isDashboardWidgetsTile && (
                <RowCount>
                  {`${totalItems || widgets?.length} ` + optionsTranslated.widgets}
                </RowCount>
              )}
            </Content>
          </Container>
        </Fragment>
      </WidgetFilters>
      {visibleWidgets?.length > 0 && (
        <WidgetLibrary>
          <div className="widget-library">
            {visibleWidgets
              ?.filter(
                (x) =>
                  x?.id !== null &&
                  x?.widgetType !== WIDGET_TYPES.MULTIPLE_LINES
              )
              .map((item,index,arr) => {
                // we need this only for Energy consumption and Energy cost
                // Only bar charts which has Tooltip
                let comparedTo = ':none'

                if (item?.widgetType === WidgetNames?.EnergyConsumption) {
                  comparedTo =
                    item?.energyConsumptionWidget?.comparedTo?.split('/')?.[1] || ':none'
                }
                if (item?.widgetType === WidgetNames?.EnergyCost) {
                  comparedTo =
                    item?.energyCostWidgetDetails?.comparedTo.split('/')?.[1] || ':none'
                }
                return (
                  <div key={item?.id}>
                    <div className="grid">
                      <div className="item">
                        <WidgetCardStylesContainer
                          key={item?.id}
                          cardWidth={300}
                          cardHeight={300}
                          widgetType={item?.widgetType}
                          showLegends={comparedTo !== ':none'}
                        >
                          <WidgetCardStyles key={item?.id}>
                            <Title>
                              <ParaName>{item?.name}</ParaName>
                            </Title>
                            <WidgetCardContentStyle
                              widgetType={item?.widgetType}
                            >
                              <WidgetsPreview widget={item} />
                            </WidgetCardContentStyle>
                          </WidgetCardStyles>
                        </WidgetCardStylesContainer>
                        <div className="buttons">
                          <button
                            className="icon-infocircle tooltip-container"
                            disabled={!item?.description}
                          >
                            <div
                              class-medium="description.length > 30"
                              className="tooltip right-top"
                            >
                              <div className="wrap-text">
                                {item?.description}
                              </div>
                            </div>
                          </button>
                          {!isDashboardWidgetsTile ? (
                            <>
                              <button
                                className="icon-duplicate tooltip-container"
                                onClick={() => {
                                  handleWidgetCopy(item)
                                }}
                              >
                                <div className="tooltip dark center-top">
                                  {optionsTranslated.copy}
                                </div>
                              </button>
                              <button
                                className="icon-edit tooltip-container"
                                onClick={() => {
                                  handleWidgetEdit(item)
                                }}
                              >
                                <div className="tooltip dark center-top">
                                  {optionsTranslated.edit}
                                </div>
                              </button>
                              <button
                                className="icon-trash2 tooltip-container"
                                onClick={() => {
                                  handleWidgetDelete(item)
                                }}
                              >
                                <div className="tooltip dark center-top">
                                  {optionsTranslated.delete}
                                </div>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="small primary"
                                onClick={() => {
                                  handleAddWidget(item?.id)
                                }}
                              >
                                {optionsTranslated.addToDashboard}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
          <Block width="auto" display="flex" flexDirection="row" align="center" justify="flex-end">
          {totalItems > MINIMUM_ROW_COUNT && (
            <>
              <PaginationText weight="100">
                {optionsTranslated.page} {`${page + 1}`}{' '}
                {optionsTranslated.of} {`${pages}`}
              </PaginationText>
              {pageOptions.map((option, key) => {
                if (!option.disabled) {
                  return (
                    <PaginationIcon
                      key={`Table-PageOption-${key}`}
                      onClick={option.action}
                    >
                      <img src={option.icon} alt="before" />
                    </PaginationIcon>
                  )
                }
                return (
                  <PaginationIcon
                    key={`Table-PageOption-${key}`}
                    disabled={true}
                  >
                    <img src={option.disabledicon} alt="next" />
                  </PaginationIcon>
                )
              })}
            </>
          )}
        </Block>
        </WidgetLibrary>
      )}
    </>
  )
}

export default React.memo(WidgetsTileList)
