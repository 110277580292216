import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import { CURRENCY_OPTION_LIST, formatCurrencyName, formatCurrency } from "src/components/legacy/common/currency"
import { MAX_LENGTH_OF_HIGHLIGHT } from "src/components/legacy/common/consultation"
import { MODES } from "../../constants"
import { numberInputValidation } from "src/components/legacy/common/validators"
import Select from 'src/components/legacy/components/select/select'
import { TEXT_EDITOR_TOOLBAR_OPTIONS } from 'src/components/legacy/common/opportunity.js'
import COLORS from 'src/components/legacy/common/colors.json'
import IconSvg from "src/components/Icon"
import TextEditor from 'src/components/text-editor'
import { HighlightSection } from './highlights'
import translate from "src/common/translations"
import { getConsultationTestName} from '../../consultation/helperFunction'

const TITLE = "title"
const IMPACT_AMOUNT = "impactAmount"
const IMPACT_AMOUNT_TYPE = "impactAmountType"
const VALUE = "value"
const IMPACT_TYPE = "impactType"

const iconList = ["comfort-impact", "reliability-impact", "performance-impact", "energy-impact", "compliance-impact", "truck-rolls-impact"]

const Highlights = (props) => {
	const[highlightsData,setHighlightsData] = useState([])
	const optionsTranslated = {
		transTitle: translate("Title")
	}
	const showHighlightFunc = ({ title, impactAmount, value }) => {
		return title?.length || impactAmount || value?.blocks?.map(({ text }) => text)?.join("")
	}
	const changeHighlights = (field, index, value) => {
		const { highlights, onChange } = props

		const newHighlights = [...highlights]
		newHighlights[index] = { ...newHighlights[index], ...{ [field]: value } }

		onChange(newHighlights)
	}


	const onChangeHighlights = (field, index) => ({ target: { value } }) => {
		changeHighlights(field, index, value)
	}



	   const { highlights, mode, errors, fieldName } = props

	   useEffect(() => {
		if(errors && errors["highlights"]){
			setHighlightsData(JSON.parse(errors["highlights"]))
		}
	   }, [errors])
	   
	   useEffect(() => {
			setHighlightsData(highlights)
	   }, [highlights])
	   
	   
		return <>
			{mode === MODES.VIEW
				? highlightsData?.filter(showHighlightFunc).map(({ title, impactAmount, impactAmountType, value, impactType, error = '' }, i) => <HighlightSection className="cs-highlight view_mode" key={`highlight-${i}`}>
					<div className="cs-highlight-box cs-highlight-view-name">
						<div className="highlight-overflow-text">{title}</div>
					</div>
					<IconSvg
						name={impactType}
						color={COLORS.ORANGE_6}
						hover={COLORS.ORANGE_6}
						float="center"
						width="60px"
						height="60px"
						viewBox="0 0 60 70"
					/>
					<div className="cs-highlight-view-body">
						<div className="cs-highlight-view-box cs-highlight-view-amount">
							<span>{impactAmount && formatCurrency(impactAmount, impactAmountType === 'USD - US Dollar' ? "usd" : impactAmountType)}</span>
						</div>
						<div className="cs-highlight-view-box">
							<TextEditor
								content={value}
								isViewMode={true}
							/>
						</div>
					</div>
				</HighlightSection>)
				: highlightsData?.map(({ title, impactAmount, impactAmountType, value, impactType, impactText, error = '' }, i) => {
					const order = i + 1
					impactAmountType = impactAmountType?.toUpperCase()

					return <HighlightSection className="cs-highlight" key={`highlight-${i}`} data-testid={getConsultationTestName(`highlight-${i}`)}>
						<div className="cs-highlight-box">
							<div className="box-title">{`Highlight ${order}`}</div>
						</div>
						<div className="cs-highlight-box cs-highlight-name">
							<label>{optionsTranslated.transTitle}</label>
							<input
								name={`highlights[${order}].title`}
								type="text"
								value={title}
								onChange={onChangeHighlights(TITLE, i)}
								autoComplete="off"
								maxLength={MAX_LENGTH_OF_HIGHLIGHT}
							/>
						</div>
						<div className={`cs-highlight-box ${error ? "invalid" : ""}`} >
							<label>{'Impact Amount $'}</label>
							<div className="cs-highlight-currency">
								<input
									name={`highlights[${order}].impactAmount`}
									type="number"
									value={impactAmount}
									onKeyPress={event => numberInputValidation(event)}
									onChange={onChangeHighlights(IMPACT_AMOUNT, i)}
									autoComplete="off"
								/>
								<Select
									selectedItem={formatCurrencyName(impactAmountType)}
									onChange={value => changeHighlights(impactAmountType, i, value)}
									options={CURRENCY_OPTION_LIST}
								/>
							</div>
							{error && <p className="error">{error}</p>}
						</div>
						<div className="cs-highlight-box">

							<div className="carousel-container">
								<div className="icons-wrapper">
									<IconSvg
										type="left-caret"
										name="left-caret"
										height="20px"
										width="20px"
										color={COLORS.GREY}
										hover={COLORS.GREY}
										onClick={() => {
											const oldVal = impactType
											const oldIndex = iconList.findIndex(e => e === oldVal)
											changeHighlights(IMPACT_TYPE, i, oldIndex === 0 ? iconList[iconList.length - 1] : iconList[oldIndex - 1])
										}}
									/>
									<IconSvg
										name={impactType}
										// cursor="auto"
										color={COLORS.ORANGE_6}
										hover={COLORS.ORANGE_6}
										width="50px"
										height="50px"
										viewBox="0 0 65 65"
									/>
									<IconSvg
										type="right-caret"
										name="right-caret"
										height="20px"
										width="20px"
										color={COLORS.GREY}
										hover={COLORS.GREY}
										onClick={() => {
											const oldVal = impactType
											const oldIndex = iconList.findIndex(e => e === oldVal)
											changeHighlights(IMPACT_TYPE, i, (oldIndex === (iconList.length - 1)) ? iconList[0] : iconList[oldIndex + 1])
										}}
									/>
								</div>
							</div>
							<div className="text-editor">
								<TextEditor
									content={fieldName ? impactText : value}
									textEditorToolbarOptions={TEXT_EDITOR_TOOLBAR_OPTIONS}
									changeContent={value => changeHighlights(fieldName ? fieldName : VALUE, i, value)}
								/>
							</div>
						</div>
					</HighlightSection>
				})}
		</>
}

Highlights.propTypes = {
	highlights: PropTypes.array.isRequired,
	onChange: PropTypes.func,
	mode: PropTypes.string,
	fieldName: PropTypes.string,
	errors: PropTypes.array,
}

Highlights.defaultProps = {
	errors: null,
	fieldName: null
}

export default Highlights;
