export const dark = {
  background: '{colors.white}',
  foreground: '{colors.neutral.50}',
  headline: '{colors.neutral.90}',
  iconBackground: '{colors.neutral.10}',
  iconForeground: '{colors.neutral.50}',
  boxShadow: '0px 4px 29.6px 0px rgba(0, 0, 0, 0.02)',

};

export const light = dark;