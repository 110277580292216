import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import reduxThunk from 'redux-thunk'
import logger from 'redux-logger'
import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from "react-redux";
import { RootState } from './RootState'
import reducer from './reducer'
const isDev = false // process.env.NODE_ENV !== "production"
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false
  }),
  reduxThunk
]
const store = configureStore({
  reducer,
  middleware: isDev ? [...middleware, logger] : middleware,
  devTools: isDev
})

export type AppDispatch = typeof store.dispatch
export type TStore = ReturnType<typeof store.getState>
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store
