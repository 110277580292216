import { useEffect, useState } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'

import { ColorPickerWrapper } from './styles'
import { COLORPICKER_PRIMARY_COLORS, COLORPICKER_SECONDARY_COLORS } from 'src/pages/widgets/helpers'

const ColorPicker = ({ selectedColor, handleSelectColorPicker, handleClose, name='' }) => {
  const [colorPallete, setColorPallete] = useState([])
  const [selectColor, setSelectColor] = useState('')

  useEffect(() => {
    handleGenerateColorsPallete()
  }, [])

  useEffect(() => {
   if(!selectColor)return
    setColorPallete(
      colorPallete?.map((c) => ({
        color: c?.color,
        selected: selectColor === c?.color
      }))
    )
  }, [selectColor])

  const handleGenerateColorsPallete = () => {
    const pallete = []
    COLORPICKER_PRIMARY_COLORS?.forEach((element) => {
      pallete.push({
        color: element,
        selected: selectedColor?.toLocaleLowerCase() === element?.toLocaleLowerCase()
      })
    })
    COLORPICKER_SECONDARY_COLORS?.forEach((element) => {
      pallete.push({
        color: element,
        selected: selectedColor?.toLocaleLowerCase() === element?.toLocaleLowerCase()
      })
    })
    setColorPallete(pallete)
  }
  const openCreateWidgetModalConfig = {
    gray: true,
    removeFooter: true,
    isColorPickerModal: true,
    isHideWhiteBackground: true,
    heading: `Select a ${name} color`, //this shall be widget.type/label
    handleClose: () => {
      handleClose()
    }
  }
  return (
    <Modal {...openCreateWidgetModalConfig}>
      <ColorPickerWrapper>
        <div className="palette">
          {colorPallete?.map((item, index) => (
            <div
              className={`color-item ${item?.selected ? 'selected' : ''}`}
              style={{ backgroundColor: item?.color }}
              key={index}
              onClick={() => {
                handleSelectColorPicker(item?.color)
                setSelectColor(item?.color)
              }}
            ></div>
          ))}
        </div>
      </ColorPickerWrapper>
    </Modal>
  )
}

export default ColorPicker
