import { createBrowserHistory } from 'history'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import { getAllowedQueryParams } from 'src/components/legacy/common/permissions/permissions.jsx'
import PAGE_NAMES from 'src/components/legacy/common/pages'

export const { stringify, parse } = queryString

let history
try {
  history = createBrowserHistory({
    basename: window.BASEPATH
  })
} catch {
  history = {
    location: {
      pathname: ''
    },
    push: () => {},
    replace: () => {}
  }
}

const preventAutoAllOrganizations = {
  [PAGE_NAMES.BUILDING_SETUP]: true,
  [PAGE_NAMES.ALARM_NOTIFICATION]: true,
  [PAGE_NAMES.FINDINGS]: true,
  [PAGE_NAMES.OPPORTUNITIES]: true,
  [PAGE_NAMES.BUILDING_EVALUATION]: true
}

export function getAllowedParams() {
  return getAllowedQueryParams(
    history.location.pathname.replace(/^\//, '').replace(/\/$/, '')
  )
}

export function allowAutoset() {
  return !preventAutoAllOrganizations[
    history.location.pathname.replace(/^\//, '').replace(/\/$/, '')
  ]
}

function isAllowed(param) {
  return (
    getAllowedQueryParams('*')?.includes(param) ||
    getAllowedParams().includes(param)
  )
}

export function makeParams(
  paramsToSet = {},
  paramsToUnset = [],
  filterByAllowed = false
) {
  const existing = parse(history.location.search)
  if (
    getAllowedParams().includes('allOrganizations') &&
    (paramsToSet.allOrganizations || isEmpty(existing)) &&
    (paramsToSet.allOrganizations || allowAutoset())
  ) {
    paramsToSet = { allOrganizations: true }
  }
  paramsToUnset.forEach((param) => delete existing[param])
  const newParams = { ...existing, ...paramsToSet }
  if (filterByAllowed) {
    Object.keys(newParams)
      .filter((p) => !isAllowed(p))
      .forEach((p) => delete newParams[p])
  }
  const newParamsString = stringify(newParams)
  if (!newParamsString && !history.location.search) {
    return false
  }

  return newParamsString
}

function pushOrReplaceWithParams(
  pushOrReplace,
  path,
  paramsToSet = {},
  paramsToUnset = [],
  state
) {
  const search = makeParams(paramsToSet, paramsToUnset)
  const toSet = {}
  if (search) {
    toSet.search = search
  }

  if (history.location.pathname !== path) {
    toSet.pathname = path
  }

  if (state) {
    toSet.state = state
  }

  if (Object.keys(toSet).length > 0) {
    history[pushOrReplace](toSet)
  }
}

export function navigateWithParams(...args) {
  pushOrReplaceWithParams('push', ...args)
}

export function redirectWithParams(...args) {
  pushOrReplaceWithParams('replace', ...args)
}

export function setParams(
  paramsToSet = {},
  paramsToUnset = [],
  state,
  filterByAllowed = true
) {
  const search = makeParams(paramsToSet, paramsToUnset, filterByAllowed)
  // Added the filter below because search wasn't including undefined variables when wanting to clear a param.
  // TODO: we can clear the paramsToUnset and just use undefined value instead.
  if (search) {
    history.replace({ search }, state)
  }

  return search
}

export function getParams() {
  return parse(history.location.search)
}

export function clearHistoryState() {
  history.replace({ ...history.location, state: undefined })
}


export function getBrowserHistory() {
  return history
}

history.setParams = setParams
history.getParams = getParams
history.clearHistoryState = clearHistoryState
history.redirectWithParams = redirectWithParams
history.navigateWithParams = navigateWithParams
history.getBrowserHistory = getBrowserHistory

export default history
