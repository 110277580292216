import styled, { keyframes } from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'

export const rotate = keyframes`
	to {transform: rotate(360deg);}
`

export const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledSpinner = styled.div`
  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    width: ${(props) => (props.page ? '135px' : '20px')};
    height: ${(props) => (props.page ? '135px' : '20px')};
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 1px solid ${colors.TBA_DARK_GREY};
    border-top-color: ${ colors.TBA_DARK2_GREY};
    border-right-color: ${colors.TBA_DARKER_GREY};
    border-bottom-color: ${ colors.TBA_MEDIUM_DARK_GREY};
    animation: ${rotate} 0.6s linear infinite;
  }
`
