export const TIS_EQUIPMENT_LIST = 'TISEquipmentList'
export const AUTOMATION_SYSTEM_LIST = 'AutomationSystemList'
export const DATA_SOURCE_TYPES = {
  VAS: 'VAV Air System (VAS )',
  VRF: 'VRF System'
}

export const TEXT_NAME_PREFIX = (id?:string) =>   `equipSetup${id ? `_${id}` : ""}`

export const DETAILS_TEXT_NAME_PREFIX = (id?:string) =>   `equipDetails${id ? `_${id}` : ""}`

export const GROUP_BY = {
  DataSourceType: 'dataSourceType',
  DataSourceName: 'dataSourceName'
}
export const CREATION_TYPE = {
  Auto: 'Auto',
  User: 'User'
}
export const CHILLER_GROUP_CATEGORIES = [
  'Chilled Water System',
  'Chiller Plant'
]
export const OPTIONS = {
  APPEND: 'append',
  PREPEND: 'prepend'
}
export const SITE_OBJECT = 'Site Object'
export const TIS_OBJECT = 'Tis Object'
export const TIS_OBJECT_CREATED_WARNING_PERIOD = 2

export const TOOLTIP_HORIZONTAL_POSITION_CENTER = 'center'

export const SUMMIT_TYPES = ['CM', 'SCG', 'CS', 'SC/BCU']

export const NAX_TYPES = ['NAX']

export const EQUIPMENT_TYPES = {
  AIR_HANDLING_UNIT: 'Air Handling Unit',
  ANALOG_POINT: 'Analog Point',
  CHILLER: 'Chiller',
  ELECTRIC_METER: 'Electric Meter',
  ENVIRONMENT_METER: 'Environment Meter',
  GAS_METER: 'Gas Meter',
  LIQUID_FLOW_METER: 'Liquid Flow Meter',
  LOAD_VALVE: 'Load Valve',
  THERMAL_ENERGY_METER: 'Thermal Energy Meter',
  VIRTUAL_METER: 'Meter - Virtual'
}

export const NONE = 'None'
export const NAME = 'name'
export const DATA_SOURCE_NAME = 'dataSourceName'
export const AUTOMATION_ID_LENGTH = 1
export const EMPTY_ARRAY = 0
export const SORT_TYPE = {
   ASC: 'asc', DESC: 'desc'
   }
