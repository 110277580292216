import styled, { css } from "styled-components"
import Select from 'src/components/legacy/components/select/select'
import IconSVG from 'src/components/Icon/index.js'
import COLORS from 'src/components/legacy/common/colors.json'


const FeeBody = styled.td`
  min-width: 880px;
  width: 80%;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
`

const FlexDiv = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const PaddedField = styled.div`
  margin: 0px 20px 0px 0px;
`

export const Input = styled.input`
  ${({ hasError }) =>
    hasError &&
    css`
      color: #262626;
      background: ${COLORS.RED_1};
      border-color: ${COLORS.RED};
    `}

    &::-webkit-inner-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    } 
    &::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
`

export const Input2 = styled.input.attrs({ type: 'numeric' })`
  ${({ hasError }) =>
    hasError &&
    css`
      color: #262626;
      background: ${COLORS.RED_1};
      border-color: ${COLORS.RED};
    `}
`



export const Fees = ({values, setValue, type, mode, error}) => values.map((e, i) => {
const canError = Boolean(e.unit || e.rate || e.name)
return <FeeBody key={`${type}-fee-${i}`}>
    <div data-testid={`rate-charge-create-${type}`}>
      <FlexDiv>
        {mode === "view" ? <PaddedField>{e?.name || "N/A"}</PaddedField> : <PaddedField>
          Name:
          &nbsp;&nbsp;
          <Input 
          onChange={({
            target: { value }
          }) => {
              const copiedValues = JSON.parse(JSON.stringify(values))
              copiedValues[i].name = value
              setValue(type === "variable" ? "variableFees" : "fixedFees", copiedValues)
          }
          }
          type="text"
          hasError={canError && error && !e.name}
          value={e?.name ? e.name : ""}
          />
        </PaddedField>}
        {mode === "view" ? <PaddedField>{e?.rate ? `${e?.unit === "$ per month" ? "$" : ""}${e?.rate}${e?.unit === "% monthly" ? "%" : ""}` : "N/A"}</PaddedField> : <PaddedField>
          {type === "variable" ? "Rate:" : "Amount:"}
          &nbsp;&nbsp;
          <Input
          key={`${type}-fee-${i}`}
          onChange={({
            target: { value }
          }) => {
            const copiedValues = JSON.parse(JSON.stringify(values))
            copiedValues[i].rate = value
            setValue(type === "variable" ? "variableFees" : "fixedFees", copiedValues)
          }}
          type="number"
          hasError={canError && error && !e.rate}
          value={e?.rate ? e.rate : ""}
          />
        </PaddedField>}
        {mode === "view" ? <PaddedField>{e?.unit || "N/A"}</PaddedField> : <PaddedField>
          <Select
            testName='rate-variable-unit'
            options={type === "variable" ? [
              {key: "per kW", value: "per kW"},
              {key: "per kWh", value: "per kWh"},
              {key: "per kVA", value: "per kVA"},
          ] : [
            {key: "% monthly", value: "% monthly"},
            {key: "$ per month", value: "$ per month"},
          ]}
            placeholder="Select a type"
            onChange={(index) => {
              const copiedValues = JSON.parse(JSON.stringify(values))
              copiedValues[i].unit = index
              setValue(type === "variable" ? "variableFees" : "fixedFees", copiedValues)
            }}
            status={canError && error && !e.unit ? "error" : undefined}
            selectedItem={e?.unit ? e.unit : undefined}
          />
        </PaddedField>}
        {mode !== "view"
        ? i + 1 === values.length ? <IconSVG
          key={Math.random()}
          onClick={() => {
            const copiedValues = JSON.parse(JSON.stringify(values))
            copiedValues.push({unit: null, rate: null, name: null})
            setValue(type === "variable" ? "variableFees" : "fixedFees", copiedValues)
          }}
          name="addcircle"
          color={"rgb(97, 174, 52)"}
          width="2em"
          height="2em"
        />
        : <IconSVG
        key={Math.random()}
        onClick={() => {
          const copiedValues = JSON.parse(JSON.stringify(values))
          copiedValues.splice(i, 1);
          setValue(type === "variable" ? "variableFees" : "fixedFees", copiedValues)
        }}
        name="deletecircle"
        color={"rgb(213, 43, 30)"}
        width="2em"
        height="2em"
      /> : ""
      }
      </FlexDiv>
    </div>
  </FeeBody>
}
)
  