import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './redux/store'
import { Provider } from 'react-redux'
import { Amplify, Auth } from 'aws-amplify'
import config from './aws-exports'
import { ThemeProvider, Authenticator } from '@aws-amplify/ui-react'
import {AmazonAIPredictionsProvider} from "@aws-amplify/predictions"
import { theme, denaliTheme } from './theme'
import '@aws-amplify/ui-react/styles.css'
import './global.css'
import './index.scss'
import { useSelector } from 'react-redux'
import { selectUiMode } from './redux/slicers/appData'
import { UiMode } from './redux/types/AppTypes'

// errorMessage is needed for if a user is disabled via cognito and not via user dynamo fields
const errorMessage = new URLSearchParams(window.location.search).get("error_description")

Amplify.configure(config)

Amplify.configure({
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession()
      return {
        Authorization: session.getIdToken().getJwtToken()
      }
    }
  }
})

Amplify.addPluggable(new AmazonAIPredictionsProvider())

const AppWrapper = () => {
  const uiMode = useSelector(selectUiMode)
  return (
    <ThemeProvider theme={uiMode === UiMode.denali ? denaliTheme : theme} colorMode="system">
      <Authenticator.Provider>
        <div id="app">
          <App errorMessage={errorMessage} />
        </div>
      </Authenticator.Provider>
    </ThemeProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
      <AppWrapper />
    </Provider>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
