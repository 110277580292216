import { useEffect, useRef, useState } from 'react'
import defaultLocationImage from 'src/assets/images/location-images/default_location.png'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import makeAsyncScriptLoader from 'react-async-script'
import { Block } from 'src/components/layouts'
import config from 'src/config'
import { EditableGeoLocationWithData } from './withCountriesAndRegions'
import { StyledGeoBlock, StyledLabel, StyledInfo } from './style'
import './legacyStyle.scss'
import { isValidValue } from './helper'

import {createMap } from 'maplibre-gl-js-amplify'
import 'maplibre-gl/dist/maplibre-gl.css'
import maplibregl from 'maplibre-gl'
import { TranslateComponent } from 'src/common/translations'
import store from 'src/redux/store';
import { CovertSquareFeetToMetre } from '../createBuilding/parseReqRes'

export const GeoDetails = ({ building }) => {
  const profileSettings = store?.getState().appData?.userInfo?.data?.profileSettings;
  const UOM = profileSettings ? JSON.parse(profileSettings)?.unitOfMeasurement === "si" ? "SI" : "IP" : "IP"
  return (
    <Block display="flex" flexWrap="wrap">
      <Block padding={'0 1rem 1rem 0'} flex="0 0 100%" width="100%">
        <StyledLabel><TranslateComponent>Street Address</TranslateComponent></StyledLabel>
        <StyledInfo>
          {isValidValue(building.address) ? building.address : '--'}
        </StyledInfo>
      </Block>
      <Block padding={'0 1rem 1rem 0'} flex="0 0 100%" width="100%">
        <StyledLabel><TranslateComponent>Country</TranslateComponent></StyledLabel>
        <StyledInfo>
          {isValidValue(building.country) ? building.country : '--'}
        </StyledInfo>
      </Block>
      <Block padding={'0 1rem 1rem 0'} flex="0 0 50%" width="50%">
        <StyledLabel><TranslateComponent>State/Province</TranslateComponent></StyledLabel>
        <StyledInfo>
          {isValidValue(building.state) ? building.state : '--'}
        </StyledInfo>
      </Block>
      <Block padding={'0 1rem 1rem 0'} flex="0 0 50%" width="50%">
        <StyledLabel><TranslateComponent>Zip/Postal Code</TranslateComponent></StyledLabel>
        <StyledInfo>
          {isValidValue(building.postalCode) ? building.postalCode : '--'}
        </StyledInfo>
      </Block>
      <Block padding={'0 1rem 1rem 0'} flex="0 0 50%" width="50%">
        <StyledLabel><TranslateComponent>City</TranslateComponent></StyledLabel>
        <StyledInfo>
          {isValidValue(building.city) ? building.city : '--'}
        </StyledInfo>
      </Block>
      <Block padding={'0 1rem 1rem 0'} flex="0 0 25%" width="50%">
        <StyledLabel><TranslateComponent>Building Area</TranslateComponent></StyledLabel>
        <StyledInfo>
          <TranslateComponent>
            {
              isValidValue(building.floorArea) ? UOM === 'SI' ? CovertSquareFeetToMetre(Number(building.floorArea)) + ' Square Meters' : building.floorArea + ' Square Feet' : '--'
            }
          </TranslateComponent>
          {/* <TranslateComponent>{`${
          isValidValue(building.floorArea) ? building.floorArea : '--'
        } Square Feet`}</TranslateComponent> */}
        </StyledInfo>
      </Block>
    </Block>
  )
}

export const Map = (props) => {
  return <div ref={props.ref}>map</div>
}

const GeoLocationDetails = ({
  formProps,
  building,  
  readOnly,
  pageName,
  ...props
}: any) => {
 

useEffect(() => {
  if (building) {
    const { lat, long } = building

    const initializeMap = async () => {
      const mapView = await createMap({
        container: 'mapView',
        center: [long,lat],
        zoom: 14
      })
      new maplibregl.Marker().setLngLat([long, lat]).addTo(mapView)
    }
    initializeMap();      
  } 
}, [building])

   

  return building && readOnly ? (
    <>
      <StyledGeoBlock>
        <Block flex="0 1 0" padding="8px">
          <img src={defaultLocationImage} alt="location" />
        </Block>
        <Block flex="1" padding="8px">
          <GeoDetails building={building} />
        </Block>
        <Block
          flex="1"
          padding="8px"
          data-testid={`${pageName}_googlemap_panel-frame`}
        >         
        <Block id='mapView'  height="100%">
        </Block>
        </Block>
      </StyledGeoBlock>
    </>
  ) : (
    !readOnly && (
      <EditableGeoLocationWithData
        {...props}
        readOnly={readOnly}
        values={formProps.values}
        setValues={formProps.setValues}
        pageName={pageName}
      />
    )
  )
}

export default GeoLocationDetails
