// TODO: Go through colors one more time, make sure you have them all.
// ------- Primary Color ------- \\
export const PRIMARY_BLUE = '#206FCF'
export const DARK_BLUE = '#00326C'
export const TRANE_ORANGE = '#FF3100'
export const PRIMARY_BG = '#F5F6FA'
export const ZEBRA_STRIPE_BG = '#FAFAFA'
export const DM_BLUE = '#6FB0FF'
export const DARK_GREY_BG_1 = '#262626'
export const DARK_GREY_BG_2 = '#1D1D1D'
export const DARK_GREY_BG_3 = '#111111'
export const WHITE = '#FFFFFF'
export const BLACK = '#000000' // ------- Secondary Color ------- \\

export const HIGHLIGHT = '#E6EFFF' // ------- Status Color ------- \\

export const SUCCESS_GREEN = '#1F7D19'
export const LIGHT_GREEN_BG = '#E6F4E6'
export const WARNING_ORANGE = '#BE5104'
export const LIGHT_ORANGE_BG = '#FEF9F6'
export const ERROR_RED = '#991909'
export const ERROR_RED_HOVER = '#781307'
export const LIGHT_PINK_BG = '#FBEBEA'
export const DARK_ERROR_RED = '#E7270D'
export const DARK_ERROR_RED_HOVER = '#CD210A'
export const LIGHT_BLUE_BG = '#F2F8FD' // ------- Gray Color ------- \\

export const TEXT_SECONDARY_1 = '#EEEEEE'
export const TEXT_HOVER_1 = '#CCCCCC'
export const TEXT_SECONDARY_2 = '#707070'
export const TEXT_HOVER_2 = '#666666'
export const HAIRLINE = '#3C3C3C'
export const LIGHT_BORDER = '#D8D8D8'
export const INPUT_BACKGROUND = '#F9FAFC'
export const DISABLED_BG = '#E2E2E2'
export const DISABLED_TEXT = '#999999'
export const TERTIARY_HOVER = '#F4F4F4' // ------- Text Color ------- \\

export const DARK_GREY = '#1E1E1E'
export const MED_GREY = '#555555'
export const GREY = '#707070'
export const SNOW = '#FAFAFA'
export const DM_MED_GREY = '#CCCCCC'
export const DM_GREY = '#B5B5B5' // ------- Graph Color ------- \\

export const GENERAL = '#459CDA'
export const EUI = '#2C557E'
export const ECI = '#009292'
export const PREDICTIVE_ENERGY = '#D38003'
export const PEAK_DEMAND = '#D38003'
export const CONSUMPTION = '#00A2C7'
export const ENERGY_STAR = '#0094E4'
export const TEMPERATURE = '#FFF10B'
export const ENERGY_OVERAGE = '#FEA7AA'
export const TOTAL_SAVINGS = '#3C3C3C'
export const ENERGY_SAVINGS = '#89C506'
export const ELECTRIC_MONTHLY = '#61AE34' // ------- Tertiary Color ------- \\

export const PURPLE_100 = '#FCF1FF'
export const PURPLE_200 = '#F9DAFF'
export const PURPLE_300 = '#F38CFF'
export const PURPLE_400 = '#E99CFF'
export const PURPLE_500 = '#9D4FB3'
export const PURPLE_600 = '#6F377F'
export const PURPLE_700 = '#401F49'
export const YELLOW_100 = '#FFFDE3'
export const YELLOW_200 = '#FFFBB3'
export const YELLOW_300 = '#FFF876'
export const YELLOW_400 = '#FFF13B'
export const YELLOW_500 = '#C5A400'
export const YELLOW_600 = '#6D3415'
export const YELLOW_700 = '#514300'
export const ORANGE_100 = '#FFF8E4'
export const ORANGE_200 = '#FFEDB2'
export const ORANGE_300 = '#FFDF77'
export const ORANGE_400 = '#FFCD3B'
export const ORANGE_500 = '#C68100'
export const ORANGE_600 = '#8D5B00'
export const ORANGE_700 = '#513400'
export const RED_100 = '#FFEAE5'
export const RED_200 = '#FFC385'
export const RED_300 = '#FF9278'
export const RED_400 = '#FF613D'
export const RED_500 = '#C31200'
export const RED_600 = '#8B0900'
export const RED_700 = '#500500'
export const GREEN_100 = '#F2FAE8'
export const GREEN_200 = '#DBF3BE'
export const GREEN_300 = '#BFE98A'
export const GREEN_400 = '#A0DB56'
export const GREEN_500 = '#538E09'
export const GREEN_600 = '#3A6503'
export const GREEN_700 = '#213A01'
export const TEAL_100 = '#F1FCFF'
export const TEAL_200 = '#DAF9FF'
export const TEAL_300 = '#BCF4FF'
export const TEAL_400 = '#9DEBFF'
export const TEAL_500 = '#4F9EB3'
export const TEAL_600 = '#377080'
export const TEAL_700 = '#114049'
export const BLUE_100 = '#E3E9F0'
export const BLUE_200 = '#B3C4D7'
export const BLUE_300 = '#769487'
export const BLUE_400 = '#3A6495'
export const BLUE_500 = '#001849'
export const BLUE_600 = '#000E32'
export const BLUE_700 = '#00081C'
export const GREY_100 = '#F6F6F6'
export const GREY_200 = '#E6E6E6'
export const GREY_300 = '#D2D2D2'
export const GREY_400 = '#BCBCBC'
export const GREY_500 = '#6F6F6F'
export const GREY_600 = '#4D4D4D'
export const GREY_700 = '#2C2C2C'
