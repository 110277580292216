export const formValues = {
  locationTypeName: 'Facility',
  locationConnectivity: {
    __typename: 'LocationConnectivity',
    locationId: 1508,
    status: 'Disconnected',
    devices: [
      {
        __typename: 'LocationDetailedDevice',
        device: {
          __typename: 'LocationDevice',
          deviceType: 'CM',
          deviceName: 'AMC 600 North Michigan 9 - 2565',
          identifier: '609c1bd9-5292-46aa-8a03-ccc6826c2b60',
          duiId: 329526,
          automationSystemId: 4368
        },
        status: {
          __typename: 'LocationDeviceStatus',
          lastTimestamp: '2023-02-09T15:30:00Z',
          connected: false,
          active: false
        }
      }
    ]
  },

  offeringSourceMaps: [
    {
      startDate: '03/02/2023',
      expirationDate: '01/01/2024',
      contractNumber: null,
      checked: true,
      tisOfferingId: 3,
      tisOfferingName: 'Building Performance',
      shortName: 'BP',
      description: 'This is Building Performance offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Building Performance',
      __typename: 'Offering',
      tisOfferingSourceMapId: 2246,
      type: 'TisBusinessOffering',
      locationId: 1508,
      subdomain: null
    },
    {
      startDate: '01/01/2022',
      expirationDate: '12/31/2022',
      contractNumber: 'AMC22OPTIM',
      checked: true,
      tisOfferingId: 3,
      tisOfferingName: 'Building Performance',
      shortName: 'BP',
      description: 'This is Building Performance offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Building Performance',
      __typename: 'Offering',
      tisOfferingSourceMapId: 27542,
      type: 'TisBusinessOffering',
      locationId: 1508,
      subdomain: null
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: null,
      checked: true,
      tisOfferingId: 8,
      tisOfferingName: 'Active Monitoring',
      shortName: 'AM',
      description: 'This is Active Monitoring offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Active Monitoring',
      __typename: 'Offering',
      tisOfferingSourceMapId: 2247,
      type: 'TisBusinessOffering',
      locationId: 1508,
      subdomain: null
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: '',
      checked: false,
      tisOfferingId: 9,
      tisOfferingName: 'Alarm Notification',
      shortName: 'AN',
      description: 'This is Alarm offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Alarm Notification'
    },
    {
      startDate: '03/02/2023',
      expirationDate: '01/01/2024',
      contractNumber: null,
      checked: true,
      tisOfferingId: 10,
      tisOfferingName: 'Energy Performance',
      shortName: 'EP',
      description: 'This is Energy Performance offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Energy Performance',
      __typename: 'Offering',
      tisOfferingSourceMapId: 34627,
      type: 'TisBusinessOffering',
      locationId: 1508,
      subdomain: null
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2024',
      contractNumber: '',
      checked: false,
      tisOfferingId: 11,
      tisOfferingName: 'Tenant Services',
      shortName: 'TS',
      description: 'Tenant Services',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Tenant Services',
      subdomain: ''
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: '',
      checked: false,
      tisOfferingId: 12,
      tisOfferingName: 'GridFlex',
      shortName: 'GF',
      description: 'This Offering is for Grid Services for SC+ devices',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'GridFlex'
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: '',
      checked: false,
      tisOfferingId: 13,
      tisOfferingName: 'Remote Access',
      shortName: 'RA',
      description: 'This is RemoteAccess Only offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Remote Access'
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: '',
      checked: false,
      tisOfferingId: 15,
      tisOfferingName: 'Connected Analytics Package',
      shortName: 'CAP',
      description: 'This is Connected Analytics Package offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Connected Analytics Package'
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: '',
      checked: false,
      tisOfferingId: 16,
      tisOfferingName: 'Digital Inspection for Chillers',
      shortName: 'DI-CH',
      description: 'This is Digital Inspection for Chillers type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Digital Inspection for Chillers'
    }
  ],

  offeringSourceMapsByName: [
    {
      __typename: 'Offering',
      shortName: 'BP'
    },
    {
      __typename: 'Offering',
      shortName: 'AM'
    },
    {
      __typename: 'Offering',
      shortName: 'BP'
    },
    {
      __typename: 'Offering',
      shortName: 'EP'
    }
  ],

  tisObjectTypeGroupNames: ['CPLProgram', 'VAS', 'VAV-BOX', 'AHU'],
  weather: {
    __typename: 'Weather',
    condition: null,
    stationKey: 'KMDW',
    iconFileName: null,
    temperature: null,
    visibilityMI: null,
    humidity: null,
    pressureIN: null,
    windMPH: {
      __typename: 'ValueWithSymbol',
      value: '11.5',
      symbol: 'mph'
    }
  },
  boundToWxStation: true,
  createdBy: {
    __typename: 'User',
    firstName: null,
    lastName: null,
    username: 'infa'
  },
  createdByTimestamp: '2014-10-09T02:28:05Z',
  createdByUser: 'infa',
  sourceEnvironment: 'prod',
  statusName: 'TisAudited',
  connectivityTypes: 'CM',
  weatherData: 'Yes',
  offeringsString: 'BP, AM, BP, EP',
  createdOn: 'October 9, 2014'
}

export const countries = [
  {
    countryId: 1,
    countryName: 'India',
    countryCode: 'IND',
    countryDescription: 'India'
  },
  {
    countryId: 2,
    countryName: 'Chile',
    countryCode: 'CHL',
    countryDescription: 'Chile'
  },
  {
    countryId: 3,
    countryName: 'Israel',
    countryCode: 'ISR',
    countryDescription: 'Israel'
  },
  {
    countryId: 4,
    countryName: 'United Kingdom',
    countryCode: 'GBR',
    countryDescription: 'United Kingdom of Great Britain and Northern Ireland'
  },
  {
    countryId: 5,
    countryName: 'United States',
    countryCode: 'USA',
    countryDescription: 'United States of America'
  },
  {
    countryId: 6,
    countryName: 'Taiwan',
    countryCode: 'TWN',
    countryDescription: 'Taiwan, Province of China'
  },
  {
    countryId: 7,
    countryName: 'Haiti',
    countryCode: 'HTI',
    countryDescription: 'Haiti'
  },
  {
    countryId: 8,
    countryName: 'Estonia',
    countryCode: 'EST',
    countryDescription: 'Estonia'
  },
  {
    countryId: 9,
    countryName: 'Heard Island and McDonald Islands',
    countryCode: 'HMD',
    countryDescription: 'Heard Island and McDonald Islands'
  },
  {
    countryId: 10,
    countryName: 'Nigeria',
    countryCode: 'NGA',
    countryDescription: 'Nigeria'
  },
  {
    countryId: 11,
    countryName: 'Saint Vincent and the Grenadines',
    countryCode: 'VCT',
    countryDescription: 'Saint Vincent and the Grenadines'
  },
  {
    countryId: 12,
    countryName: 'Turks and Caicos Islands',
    countryCode: 'TCA',
    countryDescription: 'Turks and Caicos Islands'
  },
  {
    countryId: 13,
    countryName: 'Togo',
    countryCode: 'TGO',
    countryDescription: 'Togo'
  },
  {
    countryId: 14,
    countryName: 'Switzerland',
    countryCode: 'CHE',
    countryDescription: 'Switzerland'
  },
  {
    countryId: 15,
    countryName: 'Montenegro',
    countryCode: 'MNE',
    countryDescription: 'Montenegro'
  },
  {
    countryId: 16,
    countryName: 'Cocos (Keeling) Islands',
    countryCode: 'CCK',
    countryDescription: 'Cocos (Keeling) Islands'
  },
  {
    countryId: 17,
    countryName: 'Egypt',
    countryCode: 'EGY',
    countryDescription: 'Egypt'
  },
  {
    countryId: 18,
    countryName: 'Ukraine',
    countryCode: 'UKR',
    countryDescription: 'Ukraine'
  },
  {
    countryId: 19,
    countryName: 'Sint Maarten',
    countryCode: 'SXM',
    countryDescription: 'Sint Maarten'
  },
  {
    countryId: 20,
    countryName: 'Turkmenistan',
    countryCode: 'TKM',
    countryDescription: 'Turkmenistan'
  },
  {
    countryId: 21,
    countryName: 'Nepal',
    countryCode: 'NPL',
    countryDescription: 'Nepal'
  },
  {
    countryId: 22,
    countryName: 'Hong Kong',
    countryCode: 'HKG',
    countryDescription: 'Hong Kong'
  },
  {
    countryId: 23,
    countryName: 'Brunei Darussalam',
    countryCode: 'BRN',
    countryDescription: 'Brunei Darussalam'
  },
  {
    countryId: 24,
    countryName: 'Kyrgyzstan',
    countryCode: 'KGZ',
    countryDescription: 'Kyrgyzstan'
  },
  {
    countryId: 25,
    countryName: 'Moldova',
    countryCode: 'MDA',
    countryDescription: 'Moldova'
  },
  {
    countryId: 26,
    countryName: 'Falkland Islands',
    countryCode: 'FLK',
    countryDescription: 'Falkland Islands'
  },
  {
    countryId: 27,
    countryName: 'Swaziland',
    countryCode: 'SWZ',
    countryDescription: 'Swaziland'
  },
  {
    countryId: 28,
    countryName: 'Curaçao',
    countryCode: 'CUW',
    countryDescription: 'Curaçao'
  },
  {
    countryId: 29,
    countryName: 'Australia',
    countryCode: 'AUS',
    countryDescription: 'Australia'
  },
  {
    countryId: 30,
    countryName: 'Venezuela',
    countryCode: 'VEN',
    countryDescription: 'Venezuela'
  },
  {
    countryId: 31,
    countryName: 'United States Minor Outlying Islands',
    countryCode: 'UMI',
    countryDescription: 'United States Minor Outlying Islands'
  },
  {
    countryId: 32,
    countryName: 'Guatemala',
    countryCode: 'GTM',
    countryDescription: 'Guatemala'
  },
  {
    countryId: 33,
    countryName: 'Guyana',
    countryCode: 'GUY',
    countryDescription: 'Guyana'
  },
  {
    countryId: 34,
    countryName: 'Slovenia',
    countryCode: 'SVN',
    countryDescription: 'Slovenia'
  },
  {
    countryId: 35,
    countryName: 'Bahamas',
    countryCode: 'BHS',
    countryDescription: 'Bahamas'
  },
  {
    countryId: 36,
    countryName: 'Italy',
    countryCode: 'ITA',
    countryDescription: 'Italy'
  },
  {
    countryId: 37,
    countryName: 'Cuba',
    countryCode: 'CUB',
    countryDescription: 'Cuba'
  },
  {
    countryId: 38,
    countryName: 'Ireland',
    countryCode: 'IRL',
    countryDescription: 'Ireland'
  },
  {
    countryId: 39,
    countryName: 'Northern Mariana Islands',
    countryCode: 'MNP',
    countryDescription: 'Northern Mariana Islands'
  },
  {
    countryId: 40,
    countryName: 'Equatorial Guinea',
    countryCode: 'GNQ',
    countryDescription: 'Equatorial Guinea'
  },
  {
    countryId: 41,
    countryName: 'Marshall Islands',
    countryCode: 'MHL',
    countryDescription: 'Marshall Islands'
  },
  {
    countryId: 42,
    countryName: 'Zambia',
    countryCode: 'ZMB',
    countryDescription: 'Zambia'
  },
  {
    countryId: 43,
    countryName: 'Saint Barthélemy',
    countryCode: 'BLM',
    countryDescription: 'Saint Barthélemy'
  },
  {
    countryId: 44,
    countryName: 'Costa Rica',
    countryCode: 'CRI',
    countryDescription: 'Costa Rica'
  },
  {
    countryId: 45,
    countryName: 'Guinea-Bissau',
    countryCode: 'GNB',
    countryDescription: 'Guinea-Bissau'
  },
  {
    countryId: 46,
    countryName: 'Georgia',
    countryCode: 'GEO',
    countryDescription: 'Georgia'
  },
  {
    countryId: 47,
    countryName: 'Syrian Arab Republic',
    countryCode: 'SYR',
    countryDescription: 'Syrian Arab Republic'
  },
  {
    countryId: 48,
    countryName: 'Lithuania',
    countryCode: 'LTU',
    countryDescription: 'Lithuania'
  },
  {
    countryId: 49,
    countryName: 'Côte d Ivoire',
    countryCode: 'CIV',
    countryDescription: 'Côte d Ivoire'
  },
  {
    countryId: 50,
    countryName: 'Cayman Islands',
    countryCode: 'CYM',
    countryDescription: 'Cayman Islands'
  },
  {
    countryId: 51,
    countryName: 'Christmas Island',
    countryCode: 'CXR',
    countryDescription: 'Christmas Island'
  },
  {
    countryId: 52,
    countryName: 'Mauritania',
    countryCode: 'MRT',
    countryDescription: 'Mauritania'
  },
  {
    countryId: 53,
    countryName: 'Romania',
    countryCode: 'ROU',
    countryDescription: 'Romania'
  },
  {
    countryId: 54,
    countryName: 'Saint Pierre and Miquelon',
    countryCode: 'SPM',
    countryDescription: 'Saint Pierre and Miquelon'
  },
  {
    countryId: 55,
    countryName: 'Martinique',
    countryCode: 'MTQ',
    countryDescription: 'Martinique'
  },
  {
    countryId: 56,
    countryName: 'Nicaragua',
    countryCode: 'NIC',
    countryDescription: 'Nicaragua'
  },
  {
    countryId: 57,
    countryName: 'Angola',
    countryCode: 'AGO',
    countryDescription: 'Angola'
  },
  {
    countryId: 58,
    countryName: 'Gabon',
    countryCode: 'GAB',
    countryDescription: 'Gabon'
  },
  {
    countryId: 59,
    countryName: 'Croatia',
    countryCode: 'HRV',
    countryDescription: 'Croatia'
  },
  {
    countryId: 60,
    countryName: 'Mongolia',
    countryCode: 'MNG',
    countryDescription: 'Mongolia'
  },
  {
    countryId: 61,
    countryName: 'American Samoa',
    countryCode: 'ASM',
    countryDescription: 'American Samoa'
  },
  {
    countryId: 62,
    countryName: 'Poland',
    countryCode: 'POL',
    countryDescription: 'Poland'
  },
  {
    countryId: 63,
    countryName: 'Cook Islands',
    countryCode: 'COK',
    countryDescription: 'Cook Islands'
  },
  {
    countryId: 64,
    countryName: 'New Caledonia',
    countryCode: 'NCL',
    countryDescription: 'New Caledonia'
  },
  {
    countryId: 65,
    countryName: 'Algeria',
    countryCode: 'DZA',
    countryDescription: 'Algeria'
  },
  {
    countryId: 66,
    countryName: 'Fiji',
    countryCode: 'FJI',
    countryDescription: 'Fiji'
  },
  {
    countryId: 67,
    countryName: 'San Marino',
    countryCode: 'SMR',
    countryDescription: 'San Marino'
  },
  {
    countryId: 68,
    countryName: 'Montserrat',
    countryCode: 'MSR',
    countryDescription: 'Montserrat'
  },
  {
    countryId: 69,
    countryName: 'Kenya',
    countryCode: 'KEN',
    countryDescription: 'Kenya'
  },
  {
    countryId: 70,
    countryName: 'Canada',
    countryCode: 'CAN',
    countryDescription: 'Canada'
  },
  {
    countryId: 71,
    countryName: 'Uzbekistan',
    countryCode: 'UZB',
    countryDescription: 'Uzbekistan'
  },
  {
    countryId: 72,
    countryName: 'Kuwait',
    countryCode: 'KWT',
    countryDescription: 'Kuwait'
  },
  {
    countryId: 73,
    countryName: 'Saint Helena, Ascension and Tristan da Cunha',
    countryCode: 'SHN',
    countryDescription: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    countryId: 74,
    countryName: 'Barbados',
    countryCode: 'BRB',
    countryDescription: 'Barbados'
  },
  {
    countryId: 75,
    countryName: 'Cabo Verde',
    countryCode: 'CPV',
    countryDescription: 'Cabo Verde'
  },
  {
    countryId: 76,
    countryName: 'South Korea',
    countryCode: 'KOR',
    countryDescription: 'South Korea'
  },
  {
    countryId: 77,
    countryName: 'Congo-West',
    countryCode: 'COG',
    countryDescription: 'Congo'
  },
  {
    countryId: 78,
    countryName: 'Papua New Guinea',
    countryCode: 'PNG',
    countryDescription: 'Papua New Guinea'
  },
  {
    countryId: 79,
    countryName: 'Saint Martin',
    countryCode: 'MAF',
    countryDescription: 'Saint Martin'
  },
  {
    countryId: 80,
    countryName: 'French Southern Territories',
    countryCode: 'ATF',
    countryDescription: 'French Southern Territories'
  },
  {
    countryId: 81,
    countryName: 'Macao',
    countryCode: 'MAC',
    countryDescription: 'Macao'
  },
  {
    countryId: 82,
    countryName: 'Åland Islands',
    countryCode: 'ALA',
    countryDescription: 'Åland Islands'
  },
  {
    countryId: 83,
    countryName: 'Mexico',
    countryCode: 'MEX',
    countryDescription: 'Mexico'
  },
  {
    countryId: 84,
    countryName: 'Armenia',
    countryCode: 'ARM',
    countryDescription: 'Armenia'
  },
  {
    countryId: 85,
    countryName: 'Tokelau',
    countryCode: 'TKL',
    countryDescription: 'Tokelau'
  },
  {
    countryId: 86,
    countryName: 'Saint Kitts and Nevis',
    countryCode: 'KNA',
    countryDescription: 'Saint Kitts and Nevis'
  },
  {
    countryId: 87,
    countryName: 'Malta',
    countryCode: 'MLT',
    countryDescription: 'Malta'
  },
  {
    countryId: 88,
    countryName: 'Virgin Islands (British)',
    countryCode: 'VGB',
    countryDescription: 'Virgin Islands (British)'
  },
  {
    countryId: 89,
    countryName: 'Portugal',
    countryCode: 'PRT',
    countryDescription: 'Portugal'
  },
  {
    countryId: 90,
    countryName: 'Guadeloupe',
    countryCode: 'GLP',
    countryDescription: 'Guadeloupe'
  },
  {
    countryId: 91,
    countryName: 'North Korea',
    countryCode: 'PRK',
    countryDescription: 'North Korea'
  },
  {
    countryId: 92,
    countryName: 'Ghana',
    countryCode: 'GHA',
    countryDescription: 'Ghana'
  },
  {
    countryId: 93,
    countryName: 'Mali',
    countryCode: 'MLI',
    countryDescription: 'Mali'
  },
  {
    countryId: 94,
    countryName: 'Nauru',
    countryCode: 'NRU',
    countryDescription: 'Nauru'
  },
  {
    countryId: 95,
    countryName: 'Suriname',
    countryCode: 'SUR',
    countryDescription: 'Suriname'
  },
  {
    countryId: 96,
    countryName: 'Antigua and Barbuda',
    countryCode: 'ATG',
    countryDescription: 'Antigua and Barbuda'
  },
  {
    countryId: 97,
    countryName: 'Mozambique',
    countryCode: 'MOZ',
    countryDescription: 'Mozambique'
  },
  {
    countryId: 98,
    countryName: 'South Sudan',
    countryCode: 'SSD',
    countryDescription: 'South Sudan'
  },
  {
    countryId: 99,
    countryName: 'Hungary',
    countryCode: 'HUN',
    countryDescription: 'Hungary'
  },
  {
    countryId: 100,
    countryName: 'Tanzania',
    countryCode: 'TZA',
    countryDescription: 'Tanzania'
  },
  {
    countryId: 101,
    countryName: 'Panama',
    countryCode: 'PAN',
    countryDescription: 'Panama'
  },
  {
    countryId: 102,
    countryName: 'Aruba',
    countryCode: 'ABW',
    countryDescription: 'Aruba'
  },
  {
    countryId: 103,
    countryName: 'Cambodia',
    countryCode: 'KHM',
    countryDescription: 'Cambodia'
  },
  {
    countryId: 104,
    countryName: 'Iceland',
    countryCode: 'ISL',
    countryDescription: 'Iceland'
  },
  {
    countryId: 105,
    countryName: 'Pitcairn',
    countryCode: 'PCN',
    countryDescription: 'Pitcairn'
  },
  {
    countryId: 106,
    countryName: 'Indonesia',
    countryCode: 'IDN',
    countryDescription: 'Indonesia'
  },
  {
    countryId: 107,
    countryName: 'Madagascar',
    countryCode: 'MDG',
    countryDescription: 'Madagascar'
  },
  {
    countryId: 108,
    countryName: 'Pakistan',
    countryCode: 'PAK',
    countryDescription: 'Pakistan'
  },
  {
    countryId: 109,
    countryName: 'Palestine, State of',
    countryCode: 'PSE',
    countryDescription: 'Palestine, State of'
  },
  {
    countryId: 110,
    countryName: 'Jersey',
    countryCode: 'JEY',
    countryDescription: 'Jersey'
  },
  {
    countryId: 111,
    countryName: 'China',
    countryCode: 'CHN',
    countryDescription: 'China'
  },
  {
    countryId: 112,
    countryName: 'Seychelles',
    countryCode: 'SYC',
    countryDescription: 'Seychelles'
  },
  {
    countryId: 113,
    countryName: 'Bonaire, Sint Eustatius and Saba',
    countryCode: 'BES',
    countryDescription: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    countryId: 114,
    countryName: 'Samoa',
    countryCode: 'WSM',
    countryDescription: 'Samoa'
  },
  {
    countryId: 115,
    countryName: 'Jamaica',
    countryCode: 'JAM',
    countryDescription: 'Jamaica'
  },
  {
    countryId: 116,
    countryName: 'Guernsey',
    countryCode: 'GGY',
    countryDescription: 'Guernsey'
  },
  {
    countryId: 117,
    countryName: 'Iran',
    countryCode: 'IRN',
    countryDescription: 'Iran'
  },
  {
    countryId: 118,
    countryName: 'Slovakia',
    countryCode: 'SVK',
    countryDescription: 'Slovakia'
  },
  {
    countryId: 119,
    countryName: 'Burkina Faso',
    countryCode: 'BFA',
    countryDescription: 'Burkina Faso'
  },
  {
    countryId: 120,
    countryName: 'Puerto Rico',
    countryCode: 'PRI',
    countryDescription: 'Puerto Rico'
  },
  {
    countryId: 121,
    countryName: 'Netherlands',
    countryCode: 'NLD',
    countryDescription: 'Netherlands'
  },
  {
    countryId: 122,
    countryName: 'Luxembourg',
    countryCode: 'LUX',
    countryDescription: 'Luxembourg'
  },
  {
    countryId: 123,
    countryName: 'Senegal',
    countryCode: 'SEN',
    countryDescription: 'Senegal'
  },
  {
    countryId: 124,
    countryName: 'Norfolk Island',
    countryCode: 'NFK',
    countryDescription: 'Norfolk Island'
  },
  {
    countryId: 125,
    countryName: 'Afghanistan',
    countryCode: 'AFG',
    countryDescription: 'Afghanistan'
  },
  {
    countryId: 126,
    countryName: 'Uruguay',
    countryCode: 'URY',
    countryDescription: 'Uruguay'
  },
  {
    countryId: 127,
    countryName: 'Lebanon',
    countryCode: 'LBN',
    countryDescription: 'Lebanon'
  },
  {
    countryId: 128,
    countryName: 'Trinidad and Tobago',
    countryCode: 'TTO',
    countryDescription: 'Trinidad and Tobago'
  },
  {
    countryId: 129,
    countryName: 'Anguilla',
    countryCode: 'AIA',
    countryDescription: 'Anguilla'
  },
  {
    countryId: 130,
    countryName: 'Virgin Islands (U.S.)',
    countryCode: 'VIR',
    countryDescription: 'Virgin Islands (U.S.)'
  },
  {
    countryId: 131,
    countryName: 'New Zealand',
    countryCode: 'NZL',
    countryDescription: 'New Zealand'
  },
  {
    countryId: 132,
    countryName: 'Monaco',
    countryCode: 'MCO',
    countryDescription: 'Monaco'
  },
  {
    countryId: 133,
    countryName: 'British Indian Ocean Territory',
    countryCode: 'IOT',
    countryDescription: 'British Indian Ocean Territory'
  },
  {
    countryId: 134,
    countryName: 'Somalia',
    countryCode: 'SOM',
    countryDescription: 'Somalia'
  },
  {
    countryId: 135,
    countryName: 'Wallis and Futuna',
    countryCode: 'WLF',
    countryDescription: 'Wallis and Futuna'
  },
  {
    countryId: 136,
    countryName: 'Réunion',
    countryCode: 'REU',
    countryDescription: 'Réunion'
  },
  {
    countryId: 137,
    countryName: 'Libya',
    countryCode: 'LBY',
    countryDescription: 'Libya'
  },
  {
    countryId: 138,
    countryName: 'Western Sahara',
    countryCode: 'ESH',
    countryDescription: 'Western Sahara'
  },
  {
    countryId: 139,
    countryName: 'Oman',
    countryCode: 'OMN',
    countryDescription: 'Oman'
  },
  {
    countryId: 140,
    countryName: 'Thailand',
    countryCode: 'THA',
    countryDescription: 'Thailand'
  },
  {
    countryId: 141,
    countryName: 'Sri Lanka',
    countryCode: 'LKA',
    countryDescription: 'Sri Lanka'
  },
  {
    countryId: 142,
    countryName: 'Greenland',
    countryCode: 'GRL',
    countryDescription: 'Greenland'
  },
  {
    countryId: 143,
    countryName: 'Paraguay',
    countryCode: 'PRY',
    countryDescription: 'Paraguay'
  },
  {
    countryId: 144,
    countryName: 'El Salvador',
    countryCode: 'SLV',
    countryDescription: 'El Salvador'
  },
  {
    countryId: 145,
    countryName: 'Macedonia',
    countryCode: 'MKD',
    countryDescription: 'Macedonia'
  },
  {
    countryId: 146,
    countryName: 'Belarus',
    countryCode: 'BLR',
    countryDescription: 'Belarus'
  },
  {
    countryId: 147,
    countryName: 'Bahrain',
    countryCode: 'BHR',
    countryDescription: 'Bahrain'
  },
  {
    countryId: 148,
    countryName: 'Sweden',
    countryCode: 'SWE',
    countryDescription: 'Sweden'
  },
  {
    countryId: 149,
    countryName: 'Bulgaria',
    countryCode: 'BGR',
    countryDescription: 'Bulgaria'
  },
  {
    countryId: 150,
    countryName: 'Micronesia',
    countryCode: 'FSM',
    countryDescription: 'Micronesia'
  },
  {
    countryId: 151,
    countryName: 'Norway',
    countryCode: 'NOR',
    countryDescription: 'Norway'
  },
  {
    countryId: 152,
    countryName: 'Cameroon',
    countryCode: 'CMR',
    countryDescription: 'Cameroon'
  },
  {
    countryId: 153,
    countryName: 'Mauritius',
    countryCode: 'MUS',
    countryDescription: 'Mauritius'
  },
  {
    countryId: 154,
    countryName: 'Vanuatu',
    countryCode: 'VUT',
    countryDescription: 'Vanuatu'
  },
  {
    countryId: 155,
    countryName: 'Benin',
    countryCode: 'BEN',
    countryDescription: 'Benin'
  },
  {
    countryId: 156,
    countryName: 'Djibouti',
    countryCode: 'DJI',
    countryDescription: 'Djibouti'
  },
  {
    countryId: 157,
    countryName: 'Mayotte',
    countryCode: 'MYT',
    countryDescription: 'Mayotte'
  },
  {
    countryId: 158,
    countryName: 'Austria',
    countryCode: 'AUT',
    countryDescription: 'Austria'
  },
  {
    countryId: 159,
    countryName: 'Iraq',
    countryCode: 'IRQ',
    countryDescription: 'Iraq'
  },
  {
    countryId: 160,
    countryName: 'Gibraltar',
    countryCode: 'GIB',
    countryDescription: 'Gibraltar'
  },
  {
    countryId: 161,
    countryName: 'South Georgia and the South Sandwich Islands',
    countryCode: 'SGS',
    countryDescription: 'South Georgia and the South Sandwich Islands'
  },
  {
    countryId: 162,
    countryName: 'Dominican Republic',
    countryCode: 'DOM',
    countryDescription: 'Dominican Republic'
  },
  {
    countryId: 163,
    countryName: 'Comoros',
    countryCode: 'COM',
    countryDescription: 'Comoros'
  },
  {
    countryId: 164,
    countryName: 'Czech Republic',
    countryCode: 'CZE',
    countryDescription: 'Czech Republic'
  },
  {
    countryId: 165,
    countryName: 'Yemen',
    countryCode: 'YEM',
    countryDescription: 'Yemen'
  },
  {
    countryId: 166,
    countryName: 'Chad',
    countryCode: 'TCD',
    countryDescription: 'Chad'
  },
  {
    countryId: 167,
    countryName: 'Kazakhstan',
    countryCode: 'KAZ',
    countryDescription: 'Kazakhstan'
  },
  {
    countryId: 168,
    countryName: 'France',
    countryCode: 'FRA',
    countryDescription: 'France'
  },
  {
    countryId: 169,
    countryName: 'Bouvet Island',
    countryCode: 'BVT',
    countryDescription: 'Bouvet Island'
  },
  {
    countryId: 170,
    countryName: 'Philippines',
    countryCode: 'PHL',
    countryDescription: 'Philippines'
  },
  {
    countryId: 171,
    countryName: 'Latvia',
    countryCode: 'LVA',
    countryDescription: 'Latvia'
  },
  {
    countryId: 172,
    countryName: 'Central African Republic',
    countryCode: 'CAF',
    countryDescription: 'Central African Republic'
  },
  {
    countryId: 173,
    countryName: 'Argentina',
    countryCode: 'ARG',
    countryDescription: 'Argentina'
  },
  {
    countryId: 174,
    countryName: 'Turkey',
    countryCode: 'TUR',
    countryDescription: 'Turkey'
  },
  {
    countryId: 175,
    countryName: 'Solomon Islands',
    countryCode: 'SLB',
    countryDescription: 'Solomon Islands'
  },
  {
    countryId: 176,
    countryName: 'Brazil',
    countryCode: 'BRA',
    countryDescription: 'Brazil'
  },
  {
    countryId: 177,
    countryName: 'French Guiana',
    countryCode: 'GUF',
    countryDescription: 'French Guiana'
  },
  {
    countryId: 178,
    countryName: 'Liberia',
    countryCode: 'LBR',
    countryDescription: 'Liberia'
  },
  {
    countryId: 179,
    countryName: 'Faroe Islands',
    countryCode: 'FRO',
    countryDescription: 'Faroe Islands'
  },
  {
    countryId: 180,
    countryName: 'Belize',
    countryCode: 'BLZ',
    countryDescription: 'Belize'
  },
  {
    countryId: 181,
    countryName: 'Eritrea',
    countryCode: 'ERI',
    countryDescription: 'Eritrea'
  },
  {
    countryId: 182,
    countryName: 'Rwanda',
    countryCode: 'RWA',
    countryDescription: 'Rwanda'
  },
  {
    countryId: 183,
    countryName: 'Jordan',
    countryCode: 'JOR',
    countryDescription: 'Jordan'
  },
  {
    countryId: 184,
    countryName: 'Burundi',
    countryCode: 'BDI',
    countryDescription: 'Burundi'
  },
  {
    countryId: 185,
    countryName: 'Honduras',
    countryCode: 'HND',
    countryDescription: 'Honduras'
  },
  {
    countryId: 186,
    countryName: 'Maldives',
    countryCode: 'MDV',
    countryDescription: 'Maldives'
  },
  {
    countryId: 187,
    countryName: 'Serbia',
    countryCode: 'SRB',
    countryDescription: 'Serbia'
  },
  {
    countryId: 188,
    countryName: 'Bosnia and Herzegovina',
    countryCode: 'BIH',
    countryDescription: 'Bosnia and Herzegovina'
  },
  {
    countryId: 189,
    countryName: 'Palau',
    countryCode: 'PLW',
    countryDescription: 'Palau'
  },
  {
    countryId: 190,
    countryName: 'Russian Federation',
    countryCode: 'RUS',
    countryDescription: 'Russian Federation'
  },
  {
    countryId: 191,
    countryName: 'Ethiopia',
    countryCode: 'ETH',
    countryDescription: 'Ethiopia'
  },
  {
    countryId: 192,
    countryName: 'Congo-Democratic',
    countryCode: 'COD',
    countryDescription: 'Congo'
  },
  {
    countryId: 193,
    countryName: 'Gambia',
    countryCode: 'GMB',
    countryDescription: 'Gambia'
  },
  {
    countryId: 194,
    countryName: 'Cyprus',
    countryCode: 'CYP',
    countryDescription: 'Cyprus'
  },
  {
    countryId: 195,
    countryName: 'Liechtenstein',
    countryCode: 'LIE',
    countryDescription: 'Liechtenstein'
  },
  {
    countryId: 196,
    countryName: 'Grenada',
    countryCode: 'GRD',
    countryDescription: 'Grenada'
  },
  {
    countryId: 197,
    countryName: 'Zimbabwe',
    countryCode: 'ZWE',
    countryDescription: 'Zimbabwe'
  },
  {
    countryId: 198,
    countryName: 'Ecuador',
    countryCode: 'ECU',
    countryDescription: 'Ecuador'
  },
  {
    countryId: 199,
    countryName: 'Morocco',
    countryCode: 'MAR',
    countryDescription: 'Morocco'
  },
  {
    countryId: 200,
    countryName: 'Antarctica',
    countryCode: 'ATA',
    countryDescription: 'Antarctica'
  },
  {
    countryId: 201,
    countryName: 'Niger',
    countryCode: 'NER',
    countryDescription: 'Niger'
  },
  {
    countryId: 202,
    countryName: 'Myanmar',
    countryCode: 'MMR',
    countryDescription: 'Myanmar'
  },
  {
    countryId: 203,
    countryName: 'Viet Nam',
    countryCode: 'VNM',
    countryDescription: 'Viet Nam'
  },
  {
    countryId: 204,
    countryName: 'Saint Lucia',
    countryCode: 'LCA',
    countryDescription: 'Saint Lucia'
  },
  {
    countryId: 205,
    countryName: 'Tunisia',
    countryCode: 'TUN',
    countryDescription: 'Tunisia'
  },
  {
    countryId: 206,
    countryName: 'Japan',
    countryCode: 'JPN',
    countryDescription: 'Japan'
  },
  {
    countryId: 207,
    countryName: 'Guinea',
    countryCode: 'GIN',
    countryDescription: 'Guinea'
  },
  {
    countryId: 208,
    countryName: 'Saudi Arabia',
    countryCode: 'SAU',
    countryDescription: 'Saudi Arabia'
  },
  {
    countryId: 209,
    countryName: 'Malaysia',
    countryCode: 'MYS',
    countryDescription: 'Malaysia'
  },
  {
    countryId: 210,
    countryName: 'Botswana',
    countryCode: 'BWA',
    countryDescription: 'Botswana'
  },
  {
    countryId: 211,
    countryName: 'Peru',
    countryCode: 'PER',
    countryDescription: 'Peru'
  },
  {
    countryId: 212,
    countryName: 'Lesotho',
    countryCode: 'LSO',
    countryDescription: 'Lesotho'
  },
  {
    countryId: 213,
    countryName: 'Sao Tome and Principe',
    countryCode: 'STP',
    countryDescription: 'Sao Tome and Principe'
  },
  {
    countryId: 214,
    countryName: 'Germany',
    countryCode: 'DEU',
    countryDescription: 'Germany'
  },
  {
    countryId: 215,
    countryName: 'Spain',
    countryCode: 'ESP',
    countryDescription: 'Spain'
  },
  {
    countryId: 216,
    countryName: 'Azerbaijan',
    countryCode: 'AZE',
    countryDescription: 'Azerbaijan'
  },
  {
    countryId: 217,
    countryName: 'Svalbard and Jan Mayen',
    countryCode: 'SJM',
    countryDescription: 'Svalbard and Jan Mayen'
  },
  {
    countryId: 218,
    countryName: 'Namibia',
    countryCode: 'NAM',
    countryDescription: 'Namibia'
  },
  {
    countryId: 219,
    countryName: 'Tajikistan',
    countryCode: 'TJK',
    countryDescription: 'Tajikistan'
  },
  {
    countryId: 220,
    countryName: 'Qatar',
    countryCode: 'QAT',
    countryDescription: 'Qatar'
  },
  {
    countryId: 221,
    countryName: 'French Polynesia',
    countryCode: 'PYF',
    countryDescription: 'French Polynesia'
  },
  {
    countryId: 222,
    countryName: 'Guam',
    countryCode: 'GUM',
    countryDescription: 'Guam'
  },
  {
    countryId: 223,
    countryName: 'Denmark',
    countryCode: 'DNK',
    countryDescription: 'Denmark'
  },
  {
    countryId: 224,
    countryName: 'Andorra',
    countryCode: 'AND',
    countryDescription: 'Andorra'
  },
  {
    countryId: 225,
    countryName: 'Malawi',
    countryCode: 'MWI',
    countryDescription: 'Malawi'
  },
  {
    countryId: 226,
    countryName: 'Timor-Leste',
    countryCode: 'TLS',
    countryDescription: 'Timor-Leste'
  },
  {
    countryId: 227,
    countryName: 'Kiribati',
    countryCode: 'KIR',
    countryDescription: 'Kiribati'
  },
  {
    countryId: 228,
    countryName: 'Holy See',
    countryCode: 'VAT',
    countryDescription: 'Holy See'
  },
  {
    countryId: 229,
    countryName: 'Bermuda',
    countryCode: 'BMU',
    countryDescription: 'Bermuda'
  },
  {
    countryId: 230,
    countryName: 'Niue',
    countryCode: 'NIU',
    countryDescription: 'Niue'
  },
  {
    countryId: 231,
    countryName: 'Bangladesh',
    countryCode: 'BGD',
    countryDescription: 'Bangladesh'
  },
  {
    countryId: 232,
    countryName: 'Greece',
    countryCode: 'GRC',
    countryDescription: 'Greece'
  },
  {
    countryId: 233,
    countryName: 'Colombia',
    countryCode: 'COL',
    countryDescription: 'Colombia'
  },
  {
    countryId: 234,
    countryName: 'Bhutan',
    countryCode: 'BTN',
    countryDescription: 'Bhutan'
  },
  {
    countryId: 235,
    countryName: 'Bolivia',
    countryCode: 'BOL',
    countryDescription: 'Bolivia'
  },
  {
    countryId: 236,
    countryName: 'Uganda',
    countryCode: 'UGA',
    countryDescription: 'Uganda'
  },
  {
    countryId: 237,
    countryName: 'Albania',
    countryCode: 'ALB',
    countryDescription: 'Albania'
  },
  {
    countryId: 238,
    countryName: 'United Arab Emirates',
    countryCode: 'ARE',
    countryDescription: 'United Arab Emirates'
  },
  {
    countryId: 239,
    countryName: 'Sudan',
    countryCode: 'SDN',
    countryDescription: 'Sudan'
  },
  {
    countryId: 240,
    countryName: 'Isle of Man',
    countryCode: 'IMN',
    countryDescription: 'Isle of Man'
  },
  {
    countryId: 241,
    countryName: 'Singapore',
    countryCode: 'SGP',
    countryDescription: 'Singapore'
  },
  {
    countryId: 242,
    countryName: 'Tuvalu',
    countryCode: 'TUV',
    countryDescription: 'Tuvalu'
  },
  {
    countryId: 243,
    countryName: 'Tonga',
    countryCode: 'TON',
    countryDescription: 'Tonga'
  },
  {
    countryId: 244,
    countryName: 'Finland',
    countryCode: 'FIN',
    countryDescription: 'Finland'
  },
  {
    countryId: 245,
    countryName: 'Lao Peoples Democratic Republic',
    countryCode: 'LAO',
    countryDescription: 'Lao Peoples Democratic Republic'
  },
  {
    countryId: 246,
    countryName: 'South Africa',
    countryCode: 'ZAF',
    countryDescription: 'South Africa'
  },
  {
    countryId: 247,
    countryName: 'Belgium',
    countryCode: 'BEL',
    countryDescription: 'Belgium'
  },
  {
    countryId: 248,
    countryName: 'Sierra Leone',
    countryCode: 'SLE',
    countryDescription: 'Sierra Leone'
  },
  {
    countryId: 249,
    countryName: 'Dominica',
    countryCode: 'DMA',
    countryDescription: 'Dominica'
  },
  {
    countryId: 250,
    countryName: 'Netherlands Antilles',
    countryCode: 'ANT',
    countryDescription: 'Netherlands Antilles'
  }
]

export const regions = [
  {
    regionId: 1,
    regionName: 'Louisiana',
    regionCode: 'LA',
    regionDescription: 'Louisiana',
    countryId: 5
  },
  {
    regionId: 2,
    regionName: 'Wyoming',
    regionCode: 'WY',
    regionDescription: 'Wyoming',
    countryId: 5
  },
  {
    regionId: 3,
    regionName: 'Illinois',
    regionCode: 'IL',
    regionDescription: 'Illinois',
    countryId: 5
  },
  {
    regionId: 4,
    regionName: 'Connecticut',
    regionCode: 'CT',
    regionDescription: 'Connecticut',
    countryId: 5
  },
  {
    regionId: 5,
    regionName: 'Maryland',
    regionCode: 'MD',
    regionDescription: 'Maryland',
    countryId: 5
  },
  {
    regionId: 6,
    regionName: 'Ohio',
    regionCode: 'OH',
    regionDescription: 'Ohio',
    countryId: 5
  },
  {
    regionId: 7,
    regionName: 'Mississippi',
    regionCode: 'MS',
    regionDescription: 'Mississippi',
    countryId: 5
  },
  {
    regionId: 8,
    regionName: 'Iowa',
    regionCode: 'IA',
    regionDescription: 'Iowa',
    countryId: 5
  },
  {
    regionId: 9,
    regionName: 'Georgia',
    regionCode: 'GA',
    regionDescription: 'Georgia',
    countryId: 5
  },
  {
    regionId: 10,
    regionName: 'South Carolina',
    regionCode: 'SC',
    regionDescription: 'South Carolina',
    countryId: 5
  },
  {
    regionId: 11,
    regionName: 'Massachusetts',
    regionCode: 'MA',
    regionDescription: 'Massachusetts',
    countryId: 5
  },
  {
    regionId: 12,
    regionName: 'Idaho',
    regionCode: 'ID',
    regionDescription: 'Idaho',
    countryId: 5
  },
  {
    regionId: 13,
    regionName: 'Pennsylvania',
    regionCode: 'PA',
    regionDescription: 'Pennsylvania',
    countryId: 5
  },
  {
    regionId: 14,
    regionName: 'Hawaii',
    regionCode: 'HI',
    regionDescription: 'Hawaii',
    countryId: 5
  },
  {
    regionId: 15,
    regionName: 'District of Columbia',
    regionCode: 'DC',
    regionDescription: 'District of Columbia',
    countryId: 5
  },
  {
    regionId: 16,
    regionName: 'Arkansas',
    regionCode: 'AR',
    regionDescription: 'Arkansas',
    countryId: 5
  },
  {
    regionId: 17,
    regionName: 'Washington',
    regionCode: 'WA',
    regionDescription: 'Washington',
    countryId: 5
  },
  {
    regionId: 18,
    regionName: 'Maine',
    regionCode: 'ME',
    regionDescription: 'Maine',
    countryId: 5
  },
  {
    regionId: 19,
    regionName: 'Alabama',
    regionCode: 'AL',
    regionDescription: 'Alabama',
    countryId: 5
  },
  {
    regionId: 20,
    regionName: 'Rhode Island',
    regionCode: 'RI',
    regionDescription: 'Rhode Island',
    countryId: 5
  },
  {
    regionId: 21,
    regionName: 'West Virginia',
    regionCode: 'WV',
    regionDescription: 'West Virginia',
    countryId: 5
  },
  {
    regionId: 22,
    regionName: 'North Carolina',
    regionCode: 'NC',
    regionDescription: 'North Carolina',
    countryId: 5
  },
  {
    regionId: 23,
    regionName: 'New Jersey',
    regionCode: 'NJ',
    regionDescription: 'New Jersey',
    countryId: 5
  },
  {
    regionId: 24,
    regionName: 'New York',
    regionCode: 'NY',
    regionDescription: 'New York',
    countryId: 5
  },
  {
    regionId: 25,
    regionName: 'Vermont',
    regionCode: 'VT',
    regionDescription: 'Vermont',
    countryId: 5
  },
  {
    regionId: 26,
    regionName: 'South Dakota',
    regionCode: 'SD',
    regionDescription: 'South Dakota',
    countryId: 5
  },
  {
    regionId: 27,
    regionName: 'Nebraska',
    regionCode: 'NE',
    regionDescription: 'Nebraska',
    countryId: 5
  },
  {
    regionId: 28,
    regionName: 'Alaska',
    regionCode: 'AK',
    regionDescription: 'Alaska',
    countryId: 5
  },
  {
    regionId: 29,
    regionName: 'Virginia',
    regionCode: 'VA',
    regionDescription: 'Virginia',
    countryId: 5
  },
  {
    regionId: 30,
    regionName: 'Colorado',
    regionCode: 'CO',
    regionDescription: 'Colorado',
    countryId: 5
  },
  {
    regionId: 31,
    regionName: 'Delaware',
    regionCode: 'DE',
    regionDescription: 'Delaware',
    countryId: 5
  },
  {
    regionId: 32,
    regionName: 'Oklahoma',
    regionCode: 'OK',
    regionDescription: 'Oklahoma',
    countryId: 5
  },
  {
    regionId: 33,
    regionName: 'Missouri',
    regionCode: 'MO',
    regionDescription: 'Missouri',
    countryId: 5
  },
  {
    regionId: 34,
    regionName: 'Wisconsin',
    regionCode: 'WI',
    regionDescription: 'Wisconsin',
    countryId: 5
  },
  {
    regionId: 35,
    regionName: 'Texas',
    regionCode: 'TX',
    regionDescription: 'Texas',
    countryId: 5
  },
  {
    regionId: 36,
    regionName: 'California',
    regionCode: 'CA',
    regionDescription: 'California',
    countryId: 5
  },
  {
    regionId: 37,
    regionName: 'Minnesota',
    regionCode: 'MN',
    regionDescription: 'Minnesota',
    countryId: 5
  },
  {
    regionId: 38,
    regionName: 'Utah',
    regionCode: 'UT',
    regionDescription: 'Utah',
    countryId: 5
  },
  {
    regionId: 39,
    regionName: 'New Mexico',
    regionCode: 'NM',
    regionDescription: 'New Mexico',
    countryId: 5
  },
  {
    regionId: 40,
    regionName: 'Arizona',
    regionCode: 'AZ',
    regionDescription: 'Arizona',
    countryId: 5
  },
  {
    regionId: 41,
    regionName: 'Tennessee',
    regionCode: 'TN',
    regionDescription: 'Tennessee',
    countryId: 5
  },
  {
    regionId: 42,
    regionName: 'Florida',
    regionCode: 'FL',
    regionDescription: 'Florida',
    countryId: 5
  },
  {
    regionId: 43,
    regionName: 'Kentucky',
    regionCode: 'KY',
    regionDescription: 'Kentucky',
    countryId: 5
  },
  {
    regionId: 44,
    regionName: 'Indiana',
    regionCode: 'IN',
    regionDescription: 'Indiana',
    countryId: 5
  },
  {
    regionId: 45,
    regionName: 'North Dakota',
    regionCode: 'ND',
    regionDescription: 'North Dakota',
    countryId: 5
  },
  {
    regionId: 46,
    regionName: 'New Hampshire',
    regionCode: 'NH',
    regionDescription: 'New Hampshire',
    countryId: 5
  },
  {
    regionId: 47,
    regionName: 'Kansas',
    regionCode: 'KS',
    regionDescription: 'Kansas',
    countryId: 5
  },
  {
    regionId: 48,
    regionName: 'Montana',
    regionCode: 'MT',
    regionDescription: 'Montana',
    countryId: 5
  },
  {
    regionId: 49,
    regionName: 'Oregon',
    regionCode: 'OR',
    regionDescription: 'Oregon',
    countryId: 5
  },
  {
    regionId: 50,
    regionName: 'Nevada',
    regionCode: 'NV',
    regionDescription: 'Nevada',
    countryId: 5
  },
  {
    regionId: 51,
    regionName: 'Michigan',
    regionCode: 'MI',
    regionDescription: 'Michigan',
    countryId: 5
  }
]

export const selectedValue = {
  address: {
    __typename: 'Address',
    addressTypeName: 'Location',
    city: 'Chicago',
    country: 'United States',
    countryCode: 'USA',
    countryDescription: 'United States of America',
    countryId: 5,
    line1: '600 N. Michigan Ave',
    postalCode: '60611',
    region: 'Illinois',
    regionCode: 'IL',
    regionDescription: 'Illinois',
    regionId: 3,
    locationAddressString: '600 N. Michigan Ave'
  },
  locationTypeName: 'Facility',
  locationConnectivity: {
    __typename: 'LocationConnectivity',
    locationId: 1508,
    status: 'Disconnected',
    devices: [
      {
        __typename: 'LocationDetailedDevice',
        device: {
          __typename: 'LocationDevice',
          deviceType: 'CM',
          deviceName: 'AMC 600 North Michigan 9 - 2565',
          identifier: '609c1bd9-5292-46aa-8a03-ccc6826c2b60',
          duiId: 329526,
          automationSystemId: 4368
        },
        status: {
          __typename: 'LocationDeviceStatus',
          lastTimestamp: '2023-02-09T15:30:00Z',
          connected: false,
          active: false
        }
      }
    ]
  },
  salesOffice: {
    __typename: 'SalesOffice',
    countryCode: 'USA',
    district: 'SERVICE STRATEGIC RELATIONSHIPS',
    officeCode: 'P7',
    officeName: 'SERVICE STRATEGIC RELATIONSHIPS',
    tisTraneOfficeId: 122
  },
  offeringSourceMaps: [
    {
      startDate: '03/02/2023',
      expirationDate: '01/01/2024',
      contractNumber: null,
      checked: true,
      tisOfferingId: 3,
      tisOfferingName: 'Building Performance',
      shortName: 'BP',
      description: 'This is Building Performance offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Building Performance',
      __typename: 'Offering',
      tisOfferingSourceMapId: 2246,
      type: 'TisBusinessOffering',
      locationId: 1508,
      subdomain: null
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: null,
      checked: true,
      tisOfferingId: 8,
      tisOfferingName: 'Active Monitoring',
      shortName: 'AM',
      description: 'This is Active Monitoring offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Active Monitoring',
      __typename: 'Offering',
      tisOfferingSourceMapId: 2247,
      type: 'TisBusinessOffering',
      locationId: 1508,
      subdomain: null
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: '',
      checked: false,
      tisOfferingId: 9,
      tisOfferingName: 'Alarm Notification',
      shortName: 'AN',
      description: 'This is Alarm offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Alarm Notification'
    },
    {
      startDate: '03/02/2023',
      expirationDate: '01/01/2024',
      contractNumber: null,
      checked: true,
      tisOfferingId: 10,
      tisOfferingName: 'Energy Performance',
      shortName: 'EP',
      description: 'This is Energy Performance offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Energy Performance',
      __typename: 'Offering',
      tisOfferingSourceMapId: 34627,
      type: 'TisBusinessOffering',
      locationId: 1508,
      subdomain: null
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2024',
      contractNumber: '',
      checked: false,
      tisOfferingId: 11,
      tisOfferingName: 'Tenant Services',
      shortName: 'TS',
      description: 'Tenant Services',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Tenant Services',
      subdomain: ''
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: '',
      checked: false,
      tisOfferingId: 12,
      tisOfferingName: 'GridFlex',
      shortName: 'GF',
      description: 'This Offering is for Grid Services for SC+ devices',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'GridFlex'
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: '',
      checked: false,
      tisOfferingId: 13,
      tisOfferingName: 'Remote Access',
      shortName: 'RA',
      description: 'This is RemoteAccess Only offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Remote Access'
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: '',
      checked: false,
      tisOfferingId: 15,
      tisOfferingName: 'Connected Analytics Package',
      shortName: 'CAP',
      description: 'This is Connected Analytics Package offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Connected Analytics Package'
    },
    {
      startDate: '03/02/2023',
      expirationDate: '03/02/2023',
      contractNumber: '',
      checked: false,
      tisOfferingId: 16,
      tisOfferingName: 'Digital Inspection for Chillers',
      shortName: 'DI-CH',
      description: 'This is Digital Inspection for Chillers type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Digital Inspection for Chillers'
    }
  ],
  organizationId: '6734',
  locationName: 'AMC 600 North Michigan 9 - 2565',
  locationTags: null,
  locationLatitude: 41.892858,
  locationLongitude: -87.624869,
  locationTimeZone: 'America/Chicago',
  floorAreaUnits: 'squareFeet',
  floorArea: '',
  activeStatusState: 'Active',
  __typename: 'Location',
  offeringSourceMapsByName: [
    {
      __typename: 'Offering',
      shortName: 'BP'
    },
    {
      __typename: 'Offering',
      shortName: 'AM'
    },
    {
      __typename: 'Offering',
      shortName: 'EP'
    }
  ],
  floorAreaSquareFeet: 1,
  tisObjectTypeGroupNames: ['CPLProgram', 'VAS', 'VAV-BOX', 'AHU'],
  weather: {
    __typename: 'Weather',
    condition: null,
    stationKey: 'KMDW',
    iconFileName: null,
    temperature: null,
    visibilityMI: null,
    humidity: null,
    pressureIN: null,
    windMPH: {
      __typename: 'ValueWithSymbol',
      value: '8.05',
      symbol: 'mph'
    }
  },
  accountName: 'AMC Theatres',
  boundToWxStation: true,
  createdBy: {
    __typename: 'User',
    firstName: null,
    lastName: null,
    username: 'infa'
  },
  createdByTimestamp: '2014-10-09T02:28:05Z',
  createdByUser: 'infa',
  facilityId: 1705,
  locationId: 1508,
  salesOfficeId: null,
  sourceEnvironment: 'prod',
  statusName: 'TisAudited',
  customerCRMSiteId: 387007,
  connectivityTypes: 'CM',
  weatherData: 'Yes',
  offeringsString: 'BP, AM, EP',
  createdOn: 'October 9, 2014'
}

export const allTisOfferings = {
  byId: {
    1: {
      tisOfferingId: 1,
      tisOfferingName: 'Tis Analytics',
      shortName: 'TA',
      description: 'This is Tis Analytics offering type (Internal Process)',
      tisOfferingType: 'TisFeatures',
      activeStatus: false,
      name: 'Tis Analytics'
    },
    2: {
      tisOfferingId: 2,
      tisOfferingName: 'TraneConnect',
      shortName: 'TC',
      description: 'This is TraneConnect Only offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: false,
      name: 'TraneConnect'
    },
    3: {
      tisOfferingId: 3,
      tisOfferingName: 'Building Performance',
      shortName: 'BP',
      description: 'This is Building Performance offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Building Performance'
    },
    5: {
      tisOfferingId: 5,
      tisOfferingName: 'Energy Performance',
      shortName: 'EP',
      description: 'This is Energy Performance offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Energy Performance'
    },
    6: {
      tisOfferingId: 6,
      tisOfferingName: 'Alarm Notification',
      shortName: 'AN',
      description: 'This is Alarm offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Alarm Notification'
    },
    8: {
      tisOfferingId: 8,
      tisOfferingName: 'Tis ServiceAdvisory',
      shortName: 'SA',
      description:
        'This is Tis ServiceAdvisory offering type (Internal Process)',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: false,
      name: 'Tis ServiceAdvisory'
    },
    9: {
      tisOfferingId: 9,
      tisOfferingName: 'Active Monitoring',
      shortName: 'AM',
      description: 'This is Active Monitoring offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Active Monitoring'
    },
    10: {
      tisOfferingId: 10,
      tisOfferingName: 'Eview',
      shortName: 'Eview',
      description: 'This is Eview offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: false,
      name: 'Eview'
    },
    11: {
      tisOfferingId: 11,
      tisOfferingName: 'Tenant Services',
      shortName: 'TS',
      description: 'Tenant Services',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Tenant Services'
    },
    12: {
      tisOfferingId: 12,
      tisOfferingName: 'GridFlex',
      shortName: 'GF',
      description: 'This Offering is for Grid Services for SC+ devices',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'GridFlex'
    },
    22: {
      tisOfferingId: 22,
      tisOfferingName: 'Remote Access',
      shortName: 'RA',
      description: 'This is RemoteAccess Only offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Remote Access'
    },
    23: {
      tisOfferingId: 23,
      tisOfferingName: 'ResposiveControl',
      shortName: 'RC',
      description: 'This is Responsive Controls offering type',
      tisOfferingType: 'Trial',
      activeStatus: false,
      name: 'ResposiveControl'
    },
    24: {
      tisOfferingId: 24,
      tisOfferingName: 'Connected Analytics Package',
      shortName: 'CAP',
      description: 'This is Connected Analytics Package offering type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Connected Analytics Package'
    },
    25: {
      tisOfferingId: 25,
      tisOfferingName: 'Digital Inspection for Chillers',
      shortName: 'DI-CH',
      description: 'This is Digital Inspection for Chillers type',
      tisOfferingType: 'TisBusinessOffering',
      activeStatus: true,
      name: 'Digital Inspection for Chillers'
    }
  }
}
