import { quantile } from 'd3'
import { assocPath, dissocPath, path } from 'ramda'
import { useEffect } from 'react'
import { RectClip, clipUUID } from '../Clip'
import { StackedRectUiCompProps } from './types'

export default function StackedRectUi({
  xScale,
  yScale,
  barsCount = 1,
  order = 1,
  category = [],
  isHorizontal,
  column,
  gap = 0,
  TextComp,
  textProps,
  textFormater,
  tooltipDispatch,
  tooltipRef,
  color,
  hideText = false,
  tooltipTriggerPoint
}: StackedRectUiCompProps) {
  const d = column

  const categoryName = category[d.categoryIndex]

  // TODO : refine it
  d['category'] = categoryName
  d['color'] = d.color

  let x, y, width, height

  const barCount = barsCount > 0 ? barsCount : 1

  const bandWidth = (isHorizontal ? yScale : xScale).bandwidth()

  let barGap = 0

  // In order to support comparision data, need to find no of bars in a catgory
  // based on bars count, split the width, render the bars at right position with given gap
  barGap = quantile([0, bandWidth], gap) / 2
  barGap = barGap < 0 ? 0 : barGap

  if (isHorizontal) {
    x = xScale(d.min) || 0
    y = yScale(category[d.categoryIndex]) || d.categoryIndex

    width = xScale(d.max) - xScale(d.min)
    height = bandWidth

    height = height / barCount
    y = y + barGap + height * order
    height -= barGap + barGap
  } else {
    //  vertical
    x = xScale(category[d.categoryIndex]) || d.categoryIndex
    y = yScale(d.max) || 0

    width = bandWidth
    height = yScale(d.min) - yScale(d.max)

    width = width / barCount
    x = x + barGap + width * order
    width -= barGap + barGap
  }

  const groupKey: any = `stacked-rect-group-${x}-${y}-${d.min}-${d.max}`
  const rectKey: any = `stacked-rect-${x}-${y}-${d.min}-${d.max}`

  const isTextCompProvided = !!TextComp

  const clipUUId = clipUUID(groupKey)

  //TODO: Temp solution for tooltip.
  // After mount, store values into tooltipRef
  useEffect(() => {
    if (categoryName !== undefined && order !== undefined && d) {
      tooltipRef.current = assocPath(
        [categoryName, '' + order, '' + d?.stackOrder],
        d,
        tooltipRef.current
      )
    }

    return () => {
      tooltipRef.current = dissocPath(
        [categoryName, '' + order, '' + d?.stackOrder],
        tooltipRef.current
      )
    }
  }, [])

  const propPath =
    tooltipTriggerPoint === 'item'
      ? [categoryName, '' + order, '' + d?.stackOrder]
      : [categoryName, '' + order]

  return (
    <g
      className="t-chart-stackedbar-column"
      key={groupKey}
      data-key={groupKey}
      clipPath={`url(#${clipUUId})`}
    >
      <rect
        onMouseOver={(event) =>
          tooltipDispatch(
            event,
            rectKey,
            true,
            path(propPath, tooltipRef.current)
          )
        }
        onMouseLeave={(event) => tooltipDispatch(event, rectKey, false, [])}
        className="t-chart-stackedbar-rect"
        key={rectKey}
        data-key={rectKey}
        x={x}
        y={y}
        width={width}
        height={height}
        fill={d?.active?.color || d?.color}
      />
      {!isTextCompProvided && !hideText && (
        <text
          className="t-chart-stackedbar-text"
          dominantBaseline="central"
          textAnchor="middle"
          transform={`translate(${x + width / 2} ${y + height / 2}) `}
          fill="#eee"
          stroke="#5470c6"
          strokeWidth="1"
          paintOrder="stroke"
          pointerEvents="none"
          strokeMiterlimit="1"
          {...textProps}
          style={{
            fontSize: '12px',
            fontFamily: 'Arial',
            ...textProps?.style
          }}
        >
          {textFormater
            ? textFormater(Math.round(d.max - d.min), d)
            : Math.round(d.max - d.min)}
        </text>
      )}
      {isTextCompProvided && !hideText && (
        <foreignObject
          width={width}
          height={height}
          transform={`translate(${x} ${y})`}
        >
          <TextComp data={d} />
        </foreignObject>
      )}
      <RectClip uuid={clipUUId} x={x} y={y} width={width} height={height} />
    </g>
  )
}
