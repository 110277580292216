import { StyleSheet, Font } from "@react-pdf/renderer"


Font.register({
	family: "Lato",
	fonts: [
		{ src: require('src/common/assets/fonts/Lato-Regular.ttf') },
		{ src: require('src/common/assets/fonts/Lato-Italic.ttf'), fontStyle: "italic" },
		{ src: require('src/common/assets/fonts/Lato-Bold.ttf'), fontWeight: "bold" },
		{ src: require('src/common/assets/fonts/Lato-BoldItalic.ttf'), fontStyle: "italic", fontWeight: "bold" },
	] })

Font.register({ family: "Emerald", src: require('src/common/assets/fonts/Emerald.woff') })

export const documentStyles = StyleSheet.create({
	page: {
		paddingTop: 30,
		paddingBottom: 90,
		paddingHorizontal: 30,
		fontFamily: "Lato",
	},
})