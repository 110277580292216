import React, { PureComponent } from 'react'
import moment from 'moment-timezone'
import { func, bool, string, shape, instanceOf, object } from 'prop-types'
import i18next from 'i18next'
import classNames from 'classnames'
import DatePickerCalendarView from './datepicker-calendar-view'
import DatePickerSelector from './datepicker-selector'
import './datepicker.scss'
import translate,{ TranslateComponent } from 'src/common/translations'

class DatePicker extends PureComponent {
  static propTypes = {
    handleRangeChange: func,
    initialRange: shape({
      start: instanceOf(moment),
      end: instanceOf(moment)
    }),
    rangeSelection: shape({
      past: bool,
      future: bool
    }),
    timezone: string,
    isClock: bool,
    loading: bool,
    disableBeforeGivenDate: object,
    disableAfterGivenDate: object,
    alternate: bool,
    inputDate: string,
    selectMonth: bool,
    dateType: string,
    disabled: bool
  }

  static defaultProps = {
    handleRangeChange: () => {},
    rangeSelection: {
      past: true,
      future: true
    },
    timezone: 'UTC',
    inputDate: '',
    isClock: false,
    disablePast: false,
    loading: false,
    selectMonth: false,
    dateType: '',
    disabled: false
  }

  static ELEMENT_HEIGHT = 282

  state = {
    isOpen: false,
    displayBase:
      this.props.initialRange && this.props.initialRange.start
        ? this.props.initialRange.start
        : moment.tz(this.props.timezone).startOf('day'),
    view: 'day'
  }

  datepickerElement = React.createRef()

  componentDidMount = () => {
    this.props.selectMonth && this.setState({ view: 'month' })
    window.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleOutsideClick)
  }

  handleOutsideClick = (e) => {
    if (
      !this.datepickerElement.current.contains(e.target) &&
      !e.target.classList.contains('datepicker-month')
    ) {
      this.setState({ isOpen: false })
    }
  }

  /**
   * toggleCalender - Handles opening and closing calendar
   */
  toggleCalendar = (e) => {
    if (this.props.disabled) {
      return
    }
    e && e.preventDefault()
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  }

  /**
   * changeView - Changes between DatePicker views (calendar, month, and year selector)
   *
   * @param {String} view									Name of view to switch to
   * @param {Moment}	newBaseDate		If base date is being altered, it can be passed in here
   */
  changeView = (view, newBaseDate) => {
    const newState = { view }

    if (newBaseDate) {
      if (typeof newBaseDate === 'string') {
        newState.displayBase = moment(newBaseDate)
      } else {
        newState.displayBase = newBaseDate
      }
    }
    this.setState(newState)
  }

  changeBaseDate = (newBaseDate) => this.setState({ displayBase: newBaseDate })

  render = () => {
    const { displayBase, isOpen, view } = this.state
    const {
      dateType,
      timezone,
      selectMonth,
      inputDate,
      rangeSelection,
      handleRangeChange,
      initialRange,
      isClock,
      className,
      disablePast,
      loading,
      disableBeforeGivenDate,
      disableAfterGivenDate,
      alternate,
      update,
      disabled
    } = this.props

    const isLeftSide =
      this.datepickerElement.current &&
      window.innerWidth -
        this.datepickerElement.current.getBoundingClientRect().right >
        270
    const isRightSide =
      this.datepickerElement.current &&
      this.datepickerElement.current.getBoundingClientRect().left > 270

    const viewPortH = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
    const viewTop =
      this.datepickerElement.current &&
      viewPortH <
        this.datepickerElement.current.getBoundingClientRect().bottom +
          DatePicker.ELEMENT_HEIGHT

    let side = 'center'
    if (isLeftSide) {
      side = 'left'
    } else if (isRightSide) {
      side = 'right'
    }

    return (
      <div
        className={classNames(
          'datepicker-react',
          { alternate, disabled },
          `${className}`
        )}
        ref={this.datepickerElement}
      >
        {!isClock ? (
          !loading ? (
            <div
              data-testid="icon-calendar"
              className="icon icon-calendar datepicker-trigger"
              onClick={this.toggleCalendar}
            />
          ) : (
            <div className="icon spinner" />
          )
        ) : (
          <div
            data-testid="icon-clock"
            className="icon icon-clock datepicker-trigger"
            onClick={this.toggleCalendar}
          />
        )}

        <div
          className={classNames(
            'datepicker-calendar',
            { 'is-open': isOpen, top: viewTop },
            `${side}`
          )}
        >
          <span className="datepicker-label">
            {rangeSelection.past || rangeSelection.future
              ? <TranslateComponent>Select date range:</TranslateComponent>
              : <TranslateComponent>Select a date</TranslateComponent>}:
          </span>
          <DatePickerCalendarView
            shouldShow={view === 'day'}
            displayBase={displayBase}
            rangeSelection={rangeSelection}
            timezone={timezone}
            initialRange={initialRange}
            handleRangeChange={handleRangeChange}
            changeBaseDate={this.changeBaseDate}
            changeView={this.changeView}
            toggleCalendar={this.toggleCalendar}
            disablePast={disablePast}
            disableBeforeGivenDate={disableBeforeGivenDate}
            disableAfterGivenDate={disableAfterGivenDate}
          />
          {/* This should be re-worked to more efficiently handle month view vs full calendar */}
          <DatePickerSelector
            shouldShow={view === 'month' || view === 'year'}
            displayBase={displayBase}
            timezone={timezone}
            changeView={this.changeView}
            changeBaseDate={this.changeBaseDate}
            toggleCalendar={this.toggleCalendar}
            update={update}
            inputDate={inputDate}
            selectMonth={selectMonth}
            view={view}
            dateType={dateType}
          />
        </div>
      </div>
    )
  }
}

export default DatePicker
