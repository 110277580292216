import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export class RequireRole extends Component {
  static ALL_REQUIRED = 'ALL_REQUIRED'
  static ONE_REQUIRED = 'ONE_REQUIRED'

  static propTypes = {
    render: PropTypes.func.isRequired,
    renderNoPermission: PropTypes.func,
    userRoles: PropTypes.arrayOf(PropTypes.string),
    requiredRoles: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([
          RequireRole.ALL_REQUIRED,
          RequireRole.ONE_REQUIRED
        ]),
        roles: PropTypes.arrayOf(PropTypes.string)
      })
    )
  }

  static defaultProps = {
    render: () => {},
    renderNoPermission: () => null,
    userRoles: [],
    requiredRoles: []
  }

  /**
   * hasRequiredRoles - Returns whether or not authenticated user has required roles
   *
   * @returns {Boolean}
   */
  hasRequiredRoles = () =>
    !this.props.requiredRoles ||
    this.props.requiredRoles.filter((roleRuleGroup) => {
      const filteredRoles = roleRuleGroup.roles.filter((roleRule) =>
        this.props.userRoles.includes(roleRule)
      )

      switch (roleRuleGroup.type) {
        case RequireRole.ALL_REQUIRED:
          return filteredRoles.length === roleRuleGroup.roles.length
        case RequireRole.ONE_REQUIRED:
          return filteredRoles.length > 0
        default:
          return false
      }
    }).length === this.props.requiredRoles.length

  render = () => {
    const { render, renderNoPermission } = this.props

    return <>{this.hasRequiredRoles() ? render() : renderNoPermission()}</>
  }
}

export const mapStateToProps = ({ user }) => ({
  userRoles:
    user && user.userInfo && user.userInfo.roles ? user.userInfo.roles : []
})

export default connect(mapStateToProps)(RequireRole)
