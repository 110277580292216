import clsx from 'clsx'
import * as d3 from 'd3'
import { IconSymbolCompProps } from '../types'

const availableSymbols = {
  symbolAsterisk: true,
  symbolCircle: true,
  symbolCross: true,
  symbolDiamond: true,
  symbolDiamond2: true,
  symbolPlus: true,
  symbolSquare: true,
  symbolSquare2: true,
  symbolStar: true,
  symbolTimes: true,
  symbolTriangle: true,
  symbolTriangle2: true,
  symbolWye: true
}

export default function IconSymbol(props: IconSymbolCompProps) {
  const {
    className = '',
    fill,
    pathProps = {},
    size = 64,
    stroke,
    svgProps = {},
    symbol = 'symbolCircle'
  } = props

  if (!availableSymbols[symbol]) {
    return null
  }

  const path = d3
    .symbol()
    .type((d3 as any)[symbol])
    .size(size)()

  const center = Math.floor(Math.sqrt(Math.abs(size)))

  return (
    <svg
      width="100%"
      height="100%"
      className={clsx('t-chart-legend-icon-symbol', className)}
      {...svgProps}
    >
      <g transform={`translate(${center} ${center})`}>
        <path d={path as any} stroke={stroke} fill={fill} {...pathProps} />
      </g>
    </svg>
  )
}
