import PropTypes from "prop-types"
import { Text, View } from "@react-pdf/renderer"
import PDFImage from "./image"
import { imagesStyles } from "./pdf-images-block.styles"

const { imageCaption, imageTitle } = imagesStyles

// Use randomId to prevent getting image from cache, as it's get from cache with no-cors response headers
const PDFImagesBlock = ({ images = [] }) => images?.length
	? <View>
		{images?.length && <>
			{images?.map(({ imageId, caption, title, original }) =>
				imageId && original ? <View key={imageId}>
					<PDFImage img={{ imageUrl: original, caption }} withBorder={false} />
					<Text style={imageCaption}><Text style={imageTitle}>{title}: </Text>{caption}</Text>
				</View> : null
			)}
		</>}
	</View>
	: null

PDFImagesBlock.propTypes = {
	images: PropTypes.array,
}

export default PDFImagesBlock
