import { useEffect, useMemo, useState } from 'react'
import {
  DashboardTitle,
  ToolBarWrapper,
  ActionBlock,
  IconButton,
  Wrapper,
  WidgetLink
} from './style'
import { Button } from 'src/components/inputs/button'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip.jsx'
import { useDashboardContext } from '../DashboardContextProvider'
import {
  DASHBOARD_HELP,
  DEFAULT_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_CONFIRMATION
} from '../DashboardContainer'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPE } from 'src/constants'
import {
  selectDashboards,
  getDashboards,
  setDashboard,
  selectDashboardPath,
  setDashboardIdForWidget,
  setIsWidgetModalOpen,
  setWidgetModal
} from 'src/redux/slicers/dashboard'
import { GET_DASHBOARD, UPDATE_DASHBOARD } from '../../graphql'
import { useNavigate, useParams } from 'react-router-dom'
import { stringToBoolean } from 'src/utils/commonMethods'
import { getCopyDashboardInputData, mutation } from '../../helper'
import AddWidgets from './AddWidget'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import translate, { TranslateComponent } from 'src/common/translations'
import { useQuery } from 'src/hooks/APIHooks'
import { selectUserInfo } from 'src/redux/slicers/appData'
import {
  createUserSettings,
  updateUserSettings
} from 'src/common/userSettingAPI'

const ToolBar = () => {
  const { setConfirmationModalType } = useDashboardContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [updating, setUpdating] = useState(false)
  const { data: dashboards } = useSelector(selectDashboards)
  const dashboardPath = useSelector(selectDashboardPath)
  const userInfo = useSelector(selectUserInfo)
  const dashboardLists = localStorage.getItem('dashboards')
    ? JSON.parse(localStorage.getItem('dashboards'))['dashboards']
    : dashboards
  const defaultDashboard: any = dashboardLists?.find(
    (dashboard: any) => dashboard.isDefault === '1'
  )
  const openAddWidget = useSelector(setIsWidgetModalOpen)

  const optionsTranslated = {
    createEdit: translate(' Create and edit widgets '),
    inThe: translate(' in the '),
    widgetLib: translate('Widget Library'),
    addWidget: translate('Add Widget')
  }
  if (!dashboardPath && id) {
    dispatch(
      setDashboardIdForWidget({
        dashboardId: id
      })
    )
  }

  const dashboardId = dashboardPath ? dashboardPath?.split('/')?.pop() : id
  const dashboardValues = dashboardLists.find((it) => it?.id === dashboardId)

  const updateDashboard = async (variables) => {
    try {
      await mutation({
        query: UPDATE_DASHBOARD,
        variables
      })
    } catch (e) {
      console.log(e)
      throw e
    }
  }
  const { refetch: getDashboard } = useQuery({
    query: GET_DASHBOARD,
    disableInitialLoad: true,
    dataPath: 'data.getDashboard',
    errorPolicy: 'all'
  })

  const updateDashboardToDefault = async () => {
    setUpdating(true)
    try {
      // find the existing user setting for dashboard
      // find the existing user setting for dashboard
      const userSettingsId = dashboardLists?.find(
        (x: any) => x?.userSettingsId !== null
      )?.userSettingsId
      // update newly created dashboard as default one
      const settings = { id: dashboardValues?.id }
      if (userSettingsId) {
        await updateUserSettings(userSettingsId, settings)
      } else {
        // created default dashboard for this user
        await createUserSettings('Dashboard', userInfo?.id, settings)
      }
      dispatch<any>(getDashboards())
      setConfirmationModalType(DEFAULT_DASHBOARD_SUCCESS)
    } catch {
      setConfirmationModalType(MODAL_TYPE.ERROR)
    }
    setUpdating(false)
  }

  const copyofTranslate = translate('Copy of')
  const copyDashboard = async () => {
    await getDashboard({ id }).then((dashboard) => {
      if (dashboard) {
        const inputData = getCopyDashboardInputData(dashboard, copyofTranslate)
        dispatch(
          setDashboard({
            actionType: 'Copy',
            data: inputData,
            dashboardId: id
          })
        )
      }
    })
  }
  const pageActions = useMemo(() => {
    return [
      // {
      //     icon: "shared",
      //     tooltipContent: "dashboard:GetURLToShareDisabled",
      //     disabled:true,
      //     handleClick: () => {
      //         // setConfirmationModalType(DELETE_DASHBOARD_CONFIRMATION)
      //     }
      // },
      {
        icon: `favorite ${dashboardValues?.isDefault === '1' ? 'default' : ''}`,
        tooltipContent:
          dashboardValues?.isDefault === '1' ? (
            <TranslateComponent>
              This is the Default Dashboard
            </TranslateComponent>
          ) : (
            <TranslateComponent>Set as Default</TranslateComponent>
          ),
        disabled: updating || dashboardValues?.isDefault === '1',
        handleClick: () => {
          updateDashboardToDefault(),
            trackEvent(USER_EVENTS.DASHBOARDS.events.SET_DEFAULT_DASHBOARD, {
              'new default': dashboardValues?.name ? dashboardValues?.name : '',
              'old default': defaultDashboard?.name
                ? defaultDashboard?.name
                : ''
            })
        }
      },
      {
        icon: 'duplicate',
        tooltipContent: <TranslateComponent>Copy Dashboard</TranslateComponent>,
        handleClick: () => {
          copyDashboard()
          trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_COPY_DASHBOARD)
        }
      },
      {
        icon: 'gear2',
        tooltipContent: <TranslateComponent>Edit Dashboard</TranslateComponent>,
        handleClick: () => {
          const { name, Description, isKeyMetric, isDefault, isPublic } =
            dashboardValues
          dispatch(
            setDashboard({
              actionType: 'Edit',
              data: {
                name,
                Description,
                isKeyMetric,
                isDefault,
                isPublic
              },
              dashboardId: dashboardValues?.id
            })
          )
          trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_EDIT_DASHBOARD)
        }
      },
      {
        icon: 'trash2',
        tooltipContent: (
          <TranslateComponent>Delete Dashboard</TranslateComponent>
        ),
        handleClick: () => {
          setConfirmationModalType(DELETE_DASHBOARD_CONFIRMATION)
          trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_DELETE_DASHBOARD)
        }
      }
    ]
  }, [dashboardValues, dispatch])

  const handleModalClose = () => {
    dispatch(setWidgetModal(false))
  }

  return (
    <ToolBarWrapper>
      <DashboardTitle>
        {dashboardValues?.name}
        <IconButton onClick={() => setConfirmationModalType(DASHBOARD_HELP)}>
          <i className={`icon icon-help`} />
        </IconButton>
      </DashboardTitle>
      <ActionBlock>
        <WidgetLink
          onClick={() => {
            navigate('/widget-library')
          }}
        >
          {' '}
          {optionsTranslated.createEdit}
          <br />
          {optionsTranslated.inThe}
          <span>{optionsTranslated.widgetLib}</span>
        </WidgetLink>
        <Button
          onClick={() => {
            dispatch(setWidgetModal(!openAddWidget))
            trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_ADD_WIDGET, {
              location: 'Dashboard'
            })
          }}
        >
          {optionsTranslated.addWidget}
        </Button>
        {pageActions?.map((action: any, index: number) => {
          return (
            <Wrapper key={index}>
              <ConditionalTooltip
                type={ConditionalTooltip?.TYPE?.ICON}
                align={ConditionalTooltip?.ALIGN?.CENTER}
                content={action?.tooltipContent}
              >
                <IconButton
                  key={index}
                  onClick={action?.handleClick}
                  disabled={action?.disabled}
                >
                  <i className={`icon icon-${action?.icon}`} />
                </IconButton>
              </ConditionalTooltip>
            </Wrapper>
          )
        })}
      </ActionBlock>
      {openAddWidget && <AddWidgets handleModalClose={handleModalClose} />}
    </ToolBarWrapper>
  )
}
export default ToolBar
