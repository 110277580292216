import { useEffect, useMemo } from 'react'
import debounceCoreFn from 'lodash.debounce'
import { isFunction } from './validators'

export const debounce = debounceCoreFn

export default function useDebounce(callback, debounceTime = 300, deps = []) {
  callback = isFunction(callback) ? callback : () => {}

  const debounceCallback = useMemo(
    () => debounceCoreFn(callback, debounceTime),
    []
  )

  useEffect(() => {
    return () => {
      if (debounceCallback) debounceCallback.cancel()
    }
  }, deps)

  return debounceCallback
}
