import amplitudeJs from "amplitude-js";
//Code to import config info about your app
// import { env } from "services/configs";
import { PROPERTIES_TO_SEND_BY_EVENT } from "src/amplitude-categories"
import store from "src/redux/store.ts"
import { amplitudeKey } from "./aws-exports";
import { getSearchParams } from 'src/common/helperFunctions.js'


// TODO: Need to handle api key in other environments
const API_KEY = amplitudeKey // Changed in aws-exports files respectively, not currently setup for test (same as dev)

const amplitude = amplitudeJs.getInstance()

amplitude.init(API_KEY, null, {
// optional configuration options
  includeUtm: false,
  includeGclid: false,
  includeReferrer: false,
});
const identifyEvent = (properties) =>
{
    const userDataToSend = {
        "user id": store?.getState()?.appData?.userInfo?.data?.id,
        "user type": store?.getState()?.appData?.userInfo?.data?.type?.name,
        "role names": store?.getState()?.appData?.roles,
        "client id": "Trane Connect",
        "job title": store?.getState()?.appData?.userInfo?.data?.jobTitle,
        "primary sales office" : store?.getState()?.appData?.userInfo?.data?.primarySalesOfficeId,
        "version":process.env?.REACT_APP_AWS_COMMIT_ID ? process.env?.REACT_APP_AWS_COMMIT_ID.substring(0, 7): '',
    }
    amplitude.setUserProperties(properties ? properties : userDataToSend)
    amplitude.setUserId(store?.getState()?.appData?.userInfo?.data?.id)
}

const trackEvent = (event, options = {}) => {

    const {
        buildingId,
        organizationId
      } = getSearchParams()
    const userDataToSend = {
        "building id": buildingId ? buildingId : "",
        //Potential to have issues due to state if links change after changing building
        "building name": store?.getState()?.buildingPicker?.selectedBuilding?.primary ? store?.getState()?.buildingPicker?.selectedBuilding?.primary : "", 
        "organization id": organizationId ? organizationId : "",
        "client id": "Trane Connect",
    }  
    const mergedProperties = {...PROPERTIES_TO_SEND_BY_EVENT[event] || {}, ...userDataToSend}
    amplitude.logEvent(event, {
        ...Object
            .keys(mergedProperties || {})
            .reduce((result, key) => typeof mergedProperties[key] === "function"
                ? { ...result, [key]: mergedProperties[key]() }
                : { ...result, [key]: mergedProperties[key] },
                {}),
        ...options
    })
}

/*const trackPerformanceEvent = (event, options = {}) => {
    const userDataToSend = {
        userId: store?.getState()?.appData?.userInfo?.data?.id,
        userTypeId: store?.getState()?.appData?.userInfo?.data?.userTypeId,
        userTypeName: store?.getState()?.appData?.userInfo?.data?.type?.name,
        userRoles: store?.getState()?.appData?.roles
    }  
    const mergedProperties = {...PROPERTIES_TO_SEND_BY_EVENT[event] || {}, ...userDataToSend}
    amplitudePerformance.logEvent(event, {
        ...Object
            .keys(mergedProperties || {})
            .reduce((result, key) => typeof mergedProperties[key] === "function"
                ? { ...result, [key]: mergedProperties[key]() }
                : { ...result, [key]: mergedProperties[key] },
                {}),
        ...options
    })
}*/


export { amplitude, trackEvent, identifyEvent };