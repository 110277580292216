import styled from "styled-components"


export const StyledFlexDiv = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	& > * {
		width: 50%;
	  }
`

export const SecondDivContainer = styled.div`
	padding-left: 16px;
`