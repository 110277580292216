import React from 'react'
import { object, string } from 'prop-types'
import { Highlighter } from 'src/components/utilities/Highlighter'
import { ORGANIZATION, SALES_OFFICE } from './../constants'
import { useMemo } from 'react'
const BuildingName = ({ item, textToHighlight }) => {
  const { id, primary, type, disabled = false } = item
  const text = useMemo(() => {
    if ([ORGANIZATION, SALES_OFFICE].includes(type)) return primary
    else
      return id === 'all'
        ? primary
        : disabled
        ? primary + ' - Disabled'
        : `${primary}`
  }, [type, primary])
  return <Highlighter textToHighlight={textToHighlight} text={text} />
}
BuildingName.propTypes = {
  item: object,
  textToHighlight: string
}

export default BuildingName
