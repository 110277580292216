import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  flip,
  useInteractions,
  useDismiss,
  useRole,
  useHover,
  useFocus,
  safePolygon,
  type FloatingContext
} from '@floating-ui/react'
import { trackEvent } from 'src/amplitude.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from 'src/denali-components/lib/pro-solid-svg-icons'
import { type MenuItem } from './MainNavigation'
import styles from './sidebar.module.scss'
import { TranslateComponent } from 'src/common/translations'
import { NavigationItem } from './NavigationItem'
import { useLocation, useNavigate } from 'react-router'

export const NavigationItemWithChildren = ({
  item,
  menuIsOpen = true
}: {
  item: MenuItem
  menuIsOpen?: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { refs, floatingStyles, context } = setupFloating(isOpen, setIsOpen)
  const { getReferenceProps, getFloatingProps } = setupInteractions(context)
  const history = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen && item?.analytics) trackEvent(item?.analytics)
  }, [isOpen])

  const isChildSelected = useMemo(
    () =>
      item?.links?.some((link) =>
        history.pathname.toLowerCase().includes(link?.href?.toLowerCase())
      ),
    [history.pathname]
  )

  if(!item) return <></>
  return (
    <li
      className={
        styles.mainNavLink + (item.bottomDivider ? styles.bottomDivider : '')
      }
      {...getReferenceProps()}
    >
      <button
        ref={refs.setReference}
        aria-label={item.name}
        data-testid={`navWithChildren ${item.name}`}
        data-selected={isChildSelected}
        className={styles.navButtonWithChildren}
        onClick={(event) => {
          setIsOpen(false)
          if (item?.links?.[0]?.onClick) {
            item?.links?.[0].onClick(event)
          }
          navigate(item?.links?.[0].href)
        }}
      >
        <div className={styles.icon}>{item.icon}</div>
        {menuIsOpen && (
          <div className={styles.title}>
            <TranslateComponent>{item.name}</TranslateComponent>
          </div>
        )}
        {menuIsOpen && (
          <FontAwesomeIcon icon={faChevronRight} className={styles.fadeIn} />
        )}
      </button>
      {isOpen && (
        <div
          className={styles.popupWrapper}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          data-testid={`navWithChildrenPopup ${item.name}`}
        >
          <div className={styles.popup}>
            <ul>
              {item?.links?.map((item, i) => (
                <NavigationItem
                  key={i}
                  item={item}
                  i={i}
                  menuIsOpen={true}
                  close={() => setIsOpen(false)}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </li>
  )
}

function setupFloating(
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
) {
  return useFloating({
    placement: 'right-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(0), flip(), shift()]
  })
}

function setupInteractions(context: FloatingContext) {
  const hover = useHover(context, {
    handleClose: safePolygon()
  })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)
  return useInteractions([hover, focus, dismiss, role])
}
