import React from "react"
import moment from "moment"
import styled from "styled-components"
import Button from "src/denali-ui/components/Button"
import TraneTheme from "src/denali-ui/components/ThemeProvider"


export const Image1 = styled.img`
	height: auto;
	width: calc(100% - 500px);
	min-width: 993px;
	position: relative;
	vertical-align: middle;
	min-height: 100vh;
`

export const Image2 = styled.img`
	width: calc(100% - 500px);
	min-width: 500px;
    top: calc(50% - (100vh / 2));
	position: absolute;
	vertical-align: middle;
	height: 100%;
	left: 0px;
`

export const BackgroundBrandingContainer = styled.div`
background: #00abec;
height: 100%;
-webkit-animation: fadeIn 1s;
animation: fadeIn 1s;
width: 100%;
z-index: 0;
position: fixed;
overflow: hidden;
`

export const Panel = styled.div`
border-left: 1px solid #fff;
display: block;
background: #fff;
float: right;
height: 100%;
overflow-x: hidden;
overflow-y: auto;
position: fixed;
right: 0;
width: 500px;
z-index: 1;
padding-left: 50px;
padding-right: calc(500px - 50px - 378px);
padding-top: 144px;
`

export const AzurePageMimicHeader = styled.div`
height: 24px;
color: rgb(30, 30, 30);
font-size: 16px;
font-family: HelveticaNeue-Bold,Arial, sans-serif;
font-weight: bold;
line-height: 24px;
padding-bottom: 7px;
margin-top: 0;
margin-bottom: 1rem;
`

export const AzurePageMimicBodyText = styled.div`
color: #1e1e1e;
font-family: Arial;
font-size: 16px;
line-height: 22px;
`

export const ButtonWrapper = styled.div`
margin-top: 57px;
text-align: end;
button {
	width: 40%;
}
& button:nth-child(2){
    float: right;
	margin-left: 20px;
  }
`

const TermsAndConditionsLink = styled.a`
	color: #206fcf
`

// // Keeping the following because we will need to imlement and the styling is done

// const TermsAndConditionsScreen = props => {
// 	const { instance, accounts } = useMsal()

// 	const acceptTermsAndConditions = async () => {
// 		const { userData: prevUserData } = props
// 		// get current value/obj and push new data on before setting
// 		const newUserData = {
// 			"acceptedStatus": true,
// 			"version": getConfig("termsAndConditionsCurrentVersion"),
// 			"date": moment().format()
// 		}

// 		if (!prevUserData.termsAcceptance) {
// 			prevUserData.termsAcceptance = []
// 		}
// 		// put new terms object into [0] index in array, to align with checks
// 		prevUserData.termsAcceptance.unshift(newUserData)
// 		await props.updateUserData(prevUserData, [])
// 	}

// 	const cancelTermsAndConditions = async () => {
// 		const { userData: prevUserData } = props
// 		// get current value/obj and push new data on before setting
// 		const newUserData = {
// 			"acceptedStatus": false,
// 			"version": getConfig("termsAndConditionsCurrentVersion"),
// 			"date": moment().format()
// 		}

// 		if (!prevUserData.termsAcceptance) {
// 			prevUserData.termsAcceptance = []
// 		}
// 		// put new terms object into [0] index in array, to align with checks
// 		prevUserData.termsAcceptance.unshift(newUserData)

// 		await props.updateUserData(prevUserData)
// 		const logoutRequest = {
// 			account: instance.getAccountByHomeId(accounts[0])
// 		}
// 		instance.logoutRedirect(logoutRequest)
// 		// TODO Add logout logic here when they decline terms
// 	}

// 	return (
// 		<Provider store={store}>
// 			<TraneTheme>
// 				{/* <div className={classNames("login-page", { "beta": isBeta() })}> */}
// 				<BackgroundBrandingContainer>
// 					<Image1 id="background_background_image" data-tenant-branding-background="true" src="https://corpextdev.blob.core.windows.net/root/images/leftImage.jpg" alt="Illustration" aria-hidden="true" />
// 					<Image2 id="background_background_image_float" src="https://corpextdev.blob.core.windows.net/root/images/TraneLogoCircles.svg" aria-hidden="true" />
// 				</BackgroundBrandingContainer>
// 				<Panel>
// 					<AzurePageMimicHeader>
// 						Terms & Conditions
// 					</AzurePageMimicHeader>
// 					<AzurePageMimicBodyText>
// 						In order to continue, you must accept and agree to be bound by the Trane Connect <TermsAndConditionsLink
// 							href="https://s3.amazonaws.com/prod.tis.traneconnect.eula/EULA.pdf"
// 							target="_blank" rel="noreferrer"
// 						>
// 							End-User License Agreement Terms and Conditions
//                                                                                        </TermsAndConditionsLink>.
// 					</AzurePageMimicBodyText>
// 					<ButtonWrapper>
// 						<Button variant="secondary" size="medium" onClick={() => cancelTermsAndConditions()}>
// 							Cancel
// 						</Button>
// 						<Button variant="primary" size="medium" onClick={() => acceptTermsAndConditions()}>
// 							I Agree
// 						</Button>
// 					</ButtonWrapper>
// 				</Panel>
// 			</TraneTheme>
// 		</Provider>
// 	)
// }


// export default TermsAndConditionsScreen

