import React, { useRef } from "react"
import Modal from 'src/components/legacy/components/modal/modal'
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Formik } from "formik"
import _pick from "lodash/pick"
import { hasEmptyProperties, setRequiredFieldsTouched } from "src/components/legacy/common/helpers"
import { dialogsConfig, TSDialog, BPEPDialog, BPEPApproveDialog, OEDialog } from "./common"
import { ActivateTenantServices } from "./tenant-services"
import { BPEPPerformanceRequestDialog } from "./bp-ep-performance"
// import { BPEPApproveDeclineDialog } from "./bp-ep-aprove"
import { BPEPApproveDeclineDialog } from "./approval"
import {AcceptDeclineValidate} from './approval/formValidator.js'
import { OpticsEvaluationDialog } from "./optics-evaluation"
import "./styles.scss"

export function OfferingDialog({ values: initialValues, loading, handleCancel, handleActivate, handleDecline = () => {}, type, locationInfo, organizationId, salesOfficeId, currentUser }) {
	const dialog = dialogsConfig.get(type)
	const [t] = useTranslation()
	const modalElement = useRef()
	const renderForm = (formProps = {}) => {
		const {
			values,
			setFieldTouched,
			handleSubmit,
			isSubmitting,
			setSubmitting,
			validateForm
		} = formProps

		const validateFormAnd = async (operation, ...args) => {
			validateForm().then(errors => {
				setRequiredFieldsTouched(errors, setFieldTouched)
				if (hasEmptyProperties(errors)) {
					operation(...args)
				} else {
					setSubmitting(false)
				}
			})
		}

		const submit = () => validateFormAnd(handleSubmit)
		const decline = () => validateFormAnd(handleDecline, values)
		const propsToPass = {
			values,
			type,
			handleSubmitForm: submit,
			locationInfo,
			organizationId,
			salesOfficeId,
			currentUser,
			modalElement: modalElement.current && modalElement.current.el,
			..._pick(formProps, ["touched", "errors", "setFieldValue","setValues", "setFieldTouched"])
		}
		return <Modal
			ref={modalElement}
			className={`offering-dialog ${dialog.className}`}
			gray={true}
			formHasRequiredFields={true}
			heading={dialog.title(t)}
			buttons={[
				{ type: "cancel", text: t("common:Cancel"), handleClick: handleCancel },
				...dialog.declineButtonText ? [{ type: "decline", text: dialog.declineButtonText(t), handleClick: decline }] : [],
				{
					type: "confirm",
					text: (dialog.confirmButtonText && dialog.confirmButtonText(t)) || t("common:Activate"),
					handleClick: submit,
					buttonType: "submit",
					disabled: isSubmitting
				}
			]}

			handleClose={handleCancel}
			loading={loading}
		       >
			{type == TSDialog && <ActivateTenantServices {...propsToPass} />}
			{type == BPEPDialog && <BPEPPerformanceRequestDialog {...propsToPass} />}
			{type == BPEPApproveDialog && <BPEPApproveDeclineDialog {...propsToPass} />}
			{type == OEDialog && <OpticsEvaluationDialog {...propsToPass} />}
			{/* More dialogs here in future */}
		</Modal>
	}
	return <Formik
		enableReinitialize={true}
		initialValues={initialValues}
		onSubmit={handleActivate}
		render={renderForm}
		validate={AcceptDeclineValidate}
		isInitialValid={true}
	       />
}

OfferingDialog.propTypes = {
	values: PropTypes.object,
	handleCancel: PropTypes.func.isRequired,
	handleActivate: PropTypes.func.isRequired,
	currentUser: PropTypes.string.isRequired,
	handleDecline: PropTypes.func,
	type: PropTypes.oneOf([TSDialog, BPEPDialog, BPEPApproveDialog, OEDialog]),
	loading: PropTypes.bool
}

export default OfferingDialog
