// React/Redux Libraries \\
import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import IconSet from '../../assets/icon-set'

export const StyledSVG = styled.svg`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${(props) => (props.transform ? `transform: ${props.transform};` : '')}
  ${(props) => (props.transition ? `transition: ${props.transition};` : '')}
  &:hover path {
    fill: ${(props) => (props.hover ? props.hover : props.color)};
  }
  ${(props) => props.height && `height:${props.height}`};
  ${(props) => props.width && `width:${props.width}`};
  ${(props) => props.padding && `padding:${props.padding}`};
  ${(props) => props.margin && `margin:${props.margin}`};
`

export const getSvg = (icon, extraProps) => {
  const find = (iconEl) =>
    iconEl.properties.name
      .toLowerCase()
      .split('Icons__')
      .includes(icon.toLowerCase()) ||
    iconEl.icon.tags
      ?.map((icon) => icon.toLowerCase().split('icons__')[1])
      .includes(icon)
  const currentIcon = IconSet.icons.find(find)
  const renderPath = (iconObj) => (path, index) => {
    const attrs = (iconObj.attrs && iconObj.attrs[index]) || {}
    return <path {...extraProps} key={index} d={path} {...attrs} />
  }
  if (currentIcon) {
    return currentIcon.icon.paths.map(renderPath(currentIcon.icon))
  }
  // eslint-disable-next-line no-console
  console.warn(`icon ${icon} does not exist.`)
  return null
}
const Icon = React.forwardRef(
  (
    {
      name,
      title,
      width,
      height,
      padding,
      transform,
      transition,
      margin,
      className,
      color,
      onClick,
      disabled,
      hover,
      component = 'span',
      viewBox = '0 0 1024 1024',
      size,
      extraStyles
    },
    ref
  ) => {
    const cursor = onClick ? (!disabled ? 'pointer' : 'not-allowed') : 'arrow'
    const onClickHandler = !disabled ? onClick : undefined

    const get = () => (
      <StyledSVG
        name={name}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        color={color}
        cursor={cursor}
        hover={disabled ? '#CCCCCC' : hover}
        height={height}
        transform={transform}
        transition={transition}
        width={width}
        padding={padding}
        margin={margin}
        style={extraStyles}
      >
        {title && <title>{title}</title>}
        {getSvg(name, { fill: disabled ? '#CCCCCC' : color })}
      </StyledSVG>
    )
    const getSpan = () => {
      return (
        <span
          as={component}
          css={{ fontSize: size }}
          onClick={onClickHandler && onClickHandler}
          className={className}
          ref={ref}
        >
          {get()}
        </span>
      )
    }
    return getSpan()
  }
)

Icon.propTypes = {
  /**
   * name of icon
   */
  name: PropTypes.string,

  /**
   * color of icon
   */
  color: PropTypes.string,

  /**
   * The size of the component. It uses font-size for the size (default 15px).
   * It accepts any font-size value with units.
   */
  size: PropTypes.string,

  /**
   * set height
   */
  height: PropTypes.string,

  /**
   * set width
   */
  width: PropTypes.string,

  /**
   * set classname
   */
  className: PropTypes.string,

  /**
   * action of icon component
   */
  onClick: PropTypes.func,

  /**
   * disabled icon
   */
  disabled: PropTypes.bool,

  /**
   * set hover style
   */
  hover: PropTypes.string,

  /**
   * component
   */
  component: PropTypes.elementType,

  /**
   * set title
   */
  title: PropTypes.string,

  /**
   * sets extra styles
   */
  extraStyles: PropTypes.object
}

Icon.defaultProps = {
  color: '#666666',
  size: 'unset',
  height: '1em',
  width: '1em',
  className: '',
  attr: undefined,
  onClick: undefined,
  name: '',
  disabled: false,
  extraStyles: {}
}
export default Icon
