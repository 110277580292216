export const getTestActiveSuppressions = /* GraphQL */ `
  query getATSettings($body: AWSJSON!) {
    getATSettings(body: $body)
  }
`



export const getSuppressionHistory = `
query listSettingsByBuilding($id: ID!) {
  listSettingsByBuilding(buildingId: $id) {
items {
  analyticId
  buildingId
  equipmentId
  familyId
  startDate
  endDate
}
  }
}`


export const ListBuildingEquipments = /* GraphQL */ `
  query listBuildingEquipments($id: ID!, $nextToken: String) {
    getBuilding(id: $id) {
      id 
      name
      equipments(
        filter: { isActive: { eq: 1 }, isTIS: { eq: 1 } }
        nextToken: $nextToken
        limit: 1000
      ) {
        items {
          id
          name
          building {
            name
          }
        }
        nextToken
      }
    }
  }
`