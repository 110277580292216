import { API } from 'aws-amplify'
import { useState } from 'react'

import { GET_ISSUE_BY_ID_func } from '../../../graphql'
import { NOTIFICATION_SUB_TYPE } from '../constants'
import { isValidNotificationType, valueFromBody } from '../helpers'

export function useIssuesFindinsgDetails() {
  const [uniqueIssueIds, setUniqueIssueIds] = useState({})

  const newIssueIds = []

  // find new issue ids
  // ====================================================================================================
  function findNewIssueIds(notification: any) {
    const isValid = isValidNotificationType(notification, [
      NOTIFICATION_SUB_TYPE.ISSUE
    ])

    if (!isValid) return

    const issueId = valueFromBody(notification, 'id')

    const isIssueExisting = uniqueIssueIds[issueId] !== undefined

    // Don't store if id exists
    if (!isIssueExisting && issueId) {
      uniqueIssueIds[issueId] = true

      newIssueIds.push(issueId)
    }
  }

  // Api to get new issue details
  // ====================================================================================================
  async function apiGetIssueByIds() {
    let data = null

    if (newIssueIds.length) {
      try {
        const response = (await API.graphql({
          query: GET_ISSUE_BY_ID_func(newIssueIds)
        })) as any

        data = response?.data || {}
      } catch (error) {
        console.log('API Rejection error', error)
      }
    }

    // Resolve the function regardless of success or failure of request
    return await Promise.resolve({
      requestFrom: 'ISSUE_DATA',
      data
    })
  }

  // Update the response with new properties
  // ====================================================================================================
  function addIssueNamePropertyToNotificationsForIssuesAndFindings(
    notifications = [],
    issueNames = {}
  ) {
    if (!issueNames || Object.keys(issueNames).length === 0) {
      // Exceptional cases (API fails to return data, but id should be in notifications)
      if (newIssueIds.length) {
        const ids = newIssueIds.reduce((av, id) => {
          av[id] = true

          return av
        }, {})

        notifications.forEach((n) => {
          const issueId = valueFromBody(n, 'id')

          if (ids[issueId]) {
            n['issueId'] = issueId
          }
        })
      }

      return notifications
    }

    const _issuenames = Object.values(issueNames).reduce((acc, val: any) => {
      if(val?.id){
      acc[val.id] = val.title
      }
      return acc
    }, {})

    return notifications.map((notification) => {
      const issueId = valueFromBody(notification, 'id')

      return {
        ...notification,
        issueId,
        issueName: _issuenames[issueId]
      }
    })
  }

  // Reset interal state
  // ====================================================================================================
  function updateState(reset: boolean) {
    const data = newIssueIds.slice()

    // Reset or update stored user list
    setUniqueIssueIds((issueIds) => ({
      ...(!reset ? issueIds : {}),
      ...data
    }))

    newIssueIds.length = 0
  }

  return {
    findNewIssueIds,
    apiGetIssueByIds,
    addIssueNamePropertyToNotificationsForIssuesAndFindings,
    updateState
  }
}
