import { useState, useEffect, useMemo } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'
import TextInput from 'src/components/legacy/components/modal-text-input'
import styled from 'styled-components'
import Select from 'src/components/legacy/components/select/select'
import { getConnectionTypeObj } from 'src/components/legacy/common/connection-types'
import { GET_CONNECTION_TYPES, GET_DEVICE_ASSOCIATION } from '../../graphql/queries'
import { useQuery } from 'src/hooks/APIHooks'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import uuid from 'react-uuid'
import { TranslateComponent } from 'src/common/translations'
import translate from '../../../../common/translations'
import { formatConnectivityTypeOptions } from '../helper'

export const DeviceMigrationModalContainer = styled.div`
  & .modal-text-wrapper .custom-label {
    padding: 0px !important;
  }

  p {
    font-weight: 700;
  }

  .select-wrapper {
    margin-bottom: 10px;
  }
`

export const ErrorMessage = styled.p`
  color: red;
`

export const DeviceMigrationModal = ({
  deviceDetails,
  updateDeviceToBuilding,
  addDeviceToBuilding,
  refetchBuildingById,
  toggleMigrationModal,
  setupDeviceForOpenVPN,
  buildingData
}) => {
  const [loading, setLoading] = useState(false)

  const [deviceValues, setDeviceValues] = useState({
    deviceName: deviceDetails?.deviceName || '',
    serialNumber: '',
    deviceType: deviceDetails?.deviceType
  })

  const [deviceAssociation, setDeviceAssociation] = useState(null)

  const handleInputChange = (name, value) => {
    setDeviceValues({ ...deviceValues, [name]: value })
  }

  const {
    refetch: refetchDeviceAssociation,
    loading: loadingDeviceAssociation
  } = useQuery({
    query: GET_DEVICE_ASSOCIATION,
    disableInitialLoad: true,
    dataPath: 'data.getDeviceAssociation'
  })

  const {
    data: connectionTypes,
    loading: loadingConnectionTypes
  } = useQuery({
    query: GET_CONNECTION_TYPES,
    disableInitialLoad: false,
    dataPath: 'data.configByType.items',
    variables: {
      type: 'DeviceType'
    }
  })

  const connectionTypesList = useMemo(
    () => formatConnectivityTypeOptions(connectionTypes),
    [connectionTypes]
  )


  const checkDeviceExistence = async () => {
    try {
      const serialNumber = deviceValues?.deviceType === 'SCG'
      ? `${deviceValues?.serialNumber?.toUpperCase()}-local`
      : deviceValues?.serialNumber?.toUpperCase()
      const deviceAssociation = await refetchDeviceAssociation({
        deviceId: serialNumber
      })
      const parsedResponse = JSON.parse(deviceAssociation)
      const device = parsedResponse?.device?.data || []
      const activeDeviceAssociation = device?.buildings?.find(
        (x) => x?.active == 1
      )
      // if the active is 1, this device has valid association
      if (activeDeviceAssociation) {
        setDeviceAssociation(activeDeviceAssociation)
        return {
          deviceActive: true
        }
      } else {
        setDeviceAssociation(null)
        const isAssociatedSameBuilding = device?.buildings?.find(
          (x) => x?.id === buildingData?.id
        )
        return {
          deviceActive: false,
          id: isAssociatedSameBuilding?.device_id
        }
      }
    } catch (error) {
      return true
    }
  }

  const registerOpenVPN = async (newSerial, oldSerial) => {
    try {
      const allActiveDevicesSerials = buildingData?.devices?.items?.map((x) => {
        return {
          serial: x?.serial,
          isActive: x?.isActive
        }
      })
      // Find the index of old serial device
      const findIndexReplaceDevice = allActiveDevicesSerials?.findIndex(
        (x) => x?.serial === oldSerial
      )

      // make in inactive
      allActiveDevicesSerials[findIndexReplaceDevice].isActive = 0

      // Add the newly replaced device to this list for setup
      allActiveDevicesSerials.push({
        serial: newSerial,
        isActive: 1
      })

      await setupDeviceForOpenVPN?.(allActiveDevicesSerials)
    } catch (e) {}
  }

  const replaceDevice = async () => {
    try {
      setLoading(true)
      const isDeviceAssciated = await checkDeviceExistence()
      if (!isDeviceAssciated?.deviceActive) {
        const devices = buildingData?.devices?.items || []
        const selectedDeviceDetails = devices?.find(
          (x) => x?.id === deviceDetails?.deviceId
        )
        if (selectedDeviceDetails) {
          let input = {
            id: selectedDeviceDetails?.id,
            isActive: 0
          }
          await updateDeviceToBuilding({ input })
          // if same building, make in inactive to active
          if (isDeviceAssciated && isDeviceAssciated?.id) {
            let input = {
              id: isDeviceAssciated?.id,
              isActive: 1
            }
            await updateDeviceToBuilding({ input })
          } else {
            input = {
              id: uuid(),
              buildingId: buildingData?.id,
              deviceId: selectedDeviceDetails?.deviceId,
              isActive: 1,
              name: deviceValues?.deviceName,
              serial: deviceValues?.deviceType === 'SCG'
              ? deviceValues?.serialNumber?.slice(-6) === '-local'
              ? `${deviceValues.serialNumber?.slice(0, -6).toUpperCase()}-local`
              : `${deviceValues?.serialNumber?.toUpperCase()}-local`
              : deviceValues?.serialNumber?.toUpperCase(),
              uiDisplayName:
                getConnectionTypeObj(deviceValues?.deviceType,connectionTypesList)?.value || '',
              uiShortDisplayName: deviceValues?.deviceType,
              type: deviceValues?.deviceType
            }
            await addDeviceToBuilding({ input })
          }
          registerOpenVPN(deviceValues?.serialNumber?.toUpperCase(), deviceDetails?.identifier)
          await refetchBuildingById()
          toggleMigrationModal()
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
    }
  }

  const propertyMetadataModalConfig = {
    gray: true,
    className: 'device-migration-modal',
    isHideWhiteBackground: true,
    heading: translate('Device Migration'),
    handleClose: toggleMigrationModal,
    buttons: [
      {
        text: 'Replace',
        handleClick: replaceDevice,
        type: 'save',
        disabled:
          deviceValues?.deviceName?.length <= 0 ||
          deviceValues?.serialNumber?.length <= 0 ||
          loading
      },
      {
        text: 'Cancel',
        handleClick: toggleMigrationModal,
        type: 'cancel'
      }
    ]
  }

  return (
    <Modal {...propertyMetadataModalConfig}>
      {loadingDeviceAssociation|| loadingConnectionTypes || loading ? (
        <Spinner />
      ) : (
        <DeviceMigrationModalContainer>
          <p><TranslateComponent>Connectivity Type</TranslateComponent></p>
          <Select
            options={connectionTypesList}
            onChange={(value) => {
              handleInputChange('deviceType', value)
            }}
            selectedItem={
              getConnectionTypeObj(deviceValues?.deviceType,connectionTypesList)?.value || ''
            }
          />
          <TextInput
            labelText="Hardware Serial Number"
            defaultValue={deviceValues?.serialNumber}
            name="serialNumber"
            onChange={({ target: { value, name } }) => {
              setDeviceAssociation(null)
              handleInputChange(name, value)
            }}
            isRequired={true}
            maxLength={30}
          />
          <TextInput
            labelText="Device Name"
            defaultValue={deviceValues?.deviceName}
            name="deviceName"
            onChange={({ target: { value, name } }) => {
              handleInputChange(name, value)
            }}
            isRequired={true}
            maxLength={30}
          />
          {deviceAssociation && (
            <ErrorMessage>
              Device serial number: <b>{deviceValues?.serialNumber}</b>{' '}
              associated with Building: <b>{deviceAssociation?.name || ''}</b>
            </ErrorMessage>
          )}
        </DeviceMigrationModalContainer>
      )}
    </Modal>
  )
}
