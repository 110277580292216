import styled from 'styled-components'
const screen_max_extra_small_max_width = '633px'
export const PageTitleWrapper = styled.div`
&& .page-title-wrapper {
	display: flex;
	flex-direction: column;
	width: 450px;
	padding: 0;

	& .page-title {
		height: 25px;
		overflow: hidden;
		width: 100%;
	}

	@media (max-width: ${screen_max_extra_small_max_width}) { 
		width: 200px;
		& .page-title { height: 75px }
	}

}

`
