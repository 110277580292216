import { useState,useEffect } from "react"
import { useQuery } from "src/hooks/APIHooks"
import {CONFIG} from "../queries"
import {CONFIG_TYPES} from "./contracts-and-offerings"


export const useConfig = () => {
    const [offerings,setOfferings] = useState([])
    const [contracts,setContracts] = useState([])
    const [config,setConfig] = useState([])
    const {refetch} = useQuery({
        query :CONFIG,
        disableInitialLoad:true,
        dataPath:"data.configByType.items"
    })

    useEffect(() => {
        (async()=> {
            const configData =  await Promise.all(CONFIG_TYPES.map(type => refetch({type})))
             const data = configData.reduce((acc,configValues) => {
                const eachConfigData = configValues.reduce((acc1,val) => {
                    if(acc1[val?.type]){
                        acc1[val.type].push(JSON.parse(val.value))
                    }else{
                        acc1[val.type] = [JSON.parse(val.value)]
                    }
                    return acc1
                 },{})
                 acc = {...acc,...eachConfigData}
                return acc
             },{})
             setConfig(data)
             setOfferings(data["Offering"])
             setContracts(data["Contract"])
        })()
    },[])
    return {config,offerings,contracts}
}