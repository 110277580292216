import React from 'react'
import PropTypes from 'prop-types'
import './tooltip.scss'

export const TOOLTIP_TYPE_DEFAULT = 'default'
export const TOOLTIP_TYPE_ICON = 'icon'
export const TOOLTIP_HORIZONTAL_POSITION_LEFT = 'left'
export const TOOLTIP_HORIZONTAL_POSITION_RIGHT = 'right'
export const TOOLTIP_HORIZONTAL_POSITION_CENTER = 'center'

/**
 * Tooltip - Component designed to handle icon/navigational and default tooltip types
 *
 * { componentName } is passed in as a string/[component-named] class name that we use to define all the element's styles in tooltip.css
 * { children } (.tooltip-anchor) will be the element(s) whose hover state will make the tooltip display
 * { content } will be the text/etc that is displayed in the tooltip body
 */
const Tooltip = ({
  componentName,
  type,
  children,
  content,
  SpanOrDiv,
  horizontalPosition,
  contentIsSafeHTML,
  showArrow,
  separateBlock
}) => {
  // for now, simplifying the way styles are organized and applied, instead of needing to pass in 3 separate style directives
  const anchorClassName = `${componentName}-tooltip-anchor`
  const renderTooltip = () =>
    separateBlock ? (
      <>
        <SpanOrDiv className={`tooltip-anchor ${anchorClassName}`}>
          {children}
        </SpanOrDiv>
        <SpanOrDiv
          className={`tooltip-base ${
            type || TOOLTIP_TYPE_DEFAULT
          } ${horizontalPosition}`}
        >
          {contentIsSafeHTML ? (
            <SpanOrDiv dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            content
          )}
          {showArrow && (
            <SpanOrDiv
              className={`tooltip-arrow ${
                type || TOOLTIP_TYPE_DEFAULT
              } ${horizontalPosition}`}
            />
          )}
        </SpanOrDiv>
      </>
    ) : (
      <SpanOrDiv className={`tooltip-anchor ${anchorClassName}`}>
        {children}
        <SpanOrDiv
          className={`tooltip-base ${
            type || TOOLTIP_TYPE_DEFAULT
          } ${horizontalPosition}`}
        >
          {contentIsSafeHTML ? (
            <SpanOrDiv dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            content
          )}
          {showArrow && (
            <SpanOrDiv
              className={`tooltip-arrow ${
                type || TOOLTIP_TYPE_DEFAULT
              } ${horizontalPosition}`}
            />
          )}
        </SpanOrDiv>
      </SpanOrDiv>
    )

  return content ? renderTooltip() : children
}

Tooltip.propTypes = {
  /** this prop is used to define specific styles */
  componentName: PropTypes.string,

  /** needs documentation */
  type: PropTypes.string,

  /** content prop is whatever will be displayed inside tooltip */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /** needs documentation */
  children: PropTypes.node,

  /** needs documentation */
  SpanOrDiv: PropTypes.string,
  horizontalPosition: PropTypes.string,
  contentIsSafeHTML: PropTypes.bool,
  showArrow: PropTypes.bool,
  separateBlock: PropTypes.bool
}

Tooltip.defaultProps = {
  componentName: '',
  type: TOOLTIP_TYPE_DEFAULT,
  SpanOrDiv: 'div',
  horizontalPosition: TOOLTIP_HORIZONTAL_POSITION_CENTER,
  contentIsSafeHTML: false,
  showArrow: true,
  separateBlock: false
}

export default Tooltip
