/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from 'react'
import Axis from '../../CommonComponents/AxisNative'
import { useChartContext } from '../../CommonComponents/BaseWrapper'
import { AxisDiM } from '../../Utils/defaults'

export default function Axes({ xtickLabel = { prefix: false, label: "", isShowFormatterSymbol: false } }) {
  const chart = useChartContext()

  const axis = chart.axis
  const scale = chart.scale

  // xAxis
  const xAxis = useMemo(() => axis.getAxis(AxisDiM.x, 0), [axis.lastUpdatedAt])!

  const xScale = useMemo(
    () => {
      return scale.getScale(AxisDiM.x, 0)
    },
    [scale.lastUpdatedAt]
  )!

  // yAxis
  const yAxis = useMemo(() => axis.getAxis(AxisDiM.y, 0), [axis.lastUpdatedAt])!

  const yScale = useMemo(
    () => scale.getScale(AxisDiM.y, 0),
    [scale.lastUpdatedAt]
  )!

  if (!scale.lastUpdatedAt || !axis.lastUpdatedAt) {
    return null
  }

  return (
    <>
      <Axis
        dimention={AxisDiM.y}
        axisPosition={yAxis.type}
        axisScale={yScale.fn}
        xPosition={yAxis.x}
        hideAxisLine
        hideAxisTick
        yPosition={yAxis.y}
        lastUpdatedAt={scale.lastUpdatedAt + axis.lastUpdatedAt}
      />
      <Axis
        dimention={AxisDiM.y}
        axisPosition={yAxis.type}
        axisScale={yScale.fn}
        hideAxisTick
        xPosition={xScale.fn(0)}
        yPosition={yAxis.y}
        offset={15}
        lastUpdatedAt={scale.lastUpdatedAt + axis.lastUpdatedAt}
      />
      <Axis
        dimention={AxisDiM.x}
        axisPosition={xAxis.type}
        axisScale={xScale.fn}
        xPosition={xAxis.x}
        yPosition={xAxis.y}
        tickCount={6}
        lastUpdatedAt={scale.lastUpdatedAt + axis.lastUpdatedAt}
        tickFormat={x => xtickLabel.isShowFormatterSymbol ? xtickLabel.prefix ? `${xtickLabel.label}${parseFloat(x)}` : `${parseFloat(x)}${xtickLabel.label}` : `${parseFloat(x)}`}
      />
    </>
  )
}
