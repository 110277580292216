import styled from 'styled-components'

export const StyledHeader = styled.div`
  align-items: center;
  background: linear-gradient(
    90deg,
    ${(props) => props.bg1},
    ${(props) => props.bg2}
  );
  border-bottom: 4px solid ${(props) => props.bg1};
  height: 55px;
  justify-content: space-between;
  width: 100%;
  display: flex;
`

export const SelectWrapper = styled.div`
  margin-right: 30px;
`
