import { Fragment, useEffect, useMemo } from 'react'
import { useLegendUiContext } from 'src/chart-library/CommonComponents/Legend/provider/LegendProvider'
import { useDynamicActions } from 'src/chart-library/BaseSetup/BaseSetup'
import useXYLegend from '../useXYLegend'

const LegendSetup = (props: any) => {
  const { selectedLegendItem } = useLegendUiContext()

  // Initialise/update legends
  const legendState: any = useXYLegend(props?.props?.series?.types)

  const actions = useDynamicActions()
  const { legendInteractedUpdate } = actions || {}

  useEffect(() => {
    const selectedItem = selectedLegendItem?.[legendState?.id]
    if (selectedItem) {
      legendInteractedUpdate({
        selectedLegendItem: selectedItem
      })
    }
  }, [legendState?.id, selectedLegendItem])

  return <Fragment>{props.children}</Fragment>
}

export default LegendSetup
