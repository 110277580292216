import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import Header, { selectLabel } from './header'
import './wrapper.scss'
import 'src/components/legacy/components/filterable-select/filterable-select.scss'
import { CSSTransition } from 'react-transition-group'
import { BuildingNavigationBody } from './style'
import { withTranslation } from 'react-i18next'
import { TranslateComponent } from 'src/common/translations'

export function SelectWrapper(props) {
  const { open, loading, type, children, wrapperRef } = props
  const classNames = [
    'bn-wrapper',
    ...(open ? ['opened'] : []),
    ...(loading ? ['loading'] : []),
    ...(type ? [type] : [])
  ]

  return (
    <div className={classNames.join(' ')} ref={wrapperRef}>
      {children}
    </div>
  )
}

// SelectWrapper.propTypes = {
//   open: PropTypes.bool,
//   loading: PropTypes.bool,
//   type: PropTypes.string,
//   children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
//   wrapperRef: PropTypes.any
// }

export class NestedSelect extends PureComponent {
  static displayName = 'NestedSelect'
  // static propTypes = {
  //   selected: PropTypes.shape({
  //     id: PropTypes.string,
  //     primary: PropTypes.string,
  //     secondary: PropTypes.string,
  //     icon: PropTypes.string
  //   }),
  //   loading: PropTypes.bool,
  //   type: PropTypes.oneOf(['small', 'large']),
  //   children: React.Component,
  //   toggleOpen: PropTypes.any.isRequired,
  //   open: PropTypes.bool,
  //   wrapperRef: PropTypes.any
  // }

  render() {
    const {
      loading,
      type,
      children,
      open,
      toggleOpen,
      wrapperRef,
      selected,
      missingRecent,
      missingType,
      t
    } = this.props
    const headerLabel = selectLabel(selected, t, /* missingRecent, loading */)
    return (
      <div className={"picker-flex-wrapper"}>
      <SelectWrapper
        open={open}
        loading={loading}
        type={type}
        wrapperRef={wrapperRef}
      >
        <Header loading={loading} label={headerLabel} onClick={toggleOpen} />
        <CSSTransition
          component="div"
          className="select-item-panel-parent"
          transitionName="slide-in"
          transitionEnter={true}
          transitionLeave={true}
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}
          timeout={100}
        >
          {open ? (
            <BuildingNavigationBody className="bn-body">
              {children}
            </BuildingNavigationBody>
          ) : (
            <></>
          )}
        </CSSTransition>
      </SelectWrapper>
        {open && !loading && missingRecent && <div className={"picker-no-history-error"}>
          <TranslateComponent>{`You do not have any recently selected ${missingType.includes("location") && missingType.includes("organization") ? "building/account" : missingType.includes("sales-office") ? "sales office" : "building"}. Please enter the name of the ${missingType.includes("location") && missingType.includes("organization") ? "building/account" : missingType.includes("sales-office") ? "sales office" : "building"} you wish to select in the search box.`}</TranslateComponent>
        </div>}
      </div>
    )
  }
}

export default withTranslation()(NestedSelect)
