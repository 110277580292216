import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'

import Label from 'src/components/legacy/components/label/label'

export const StyledInput = styled.input`
  width: 45%;
`

export const StyledAddOfferings = styled.div`
  background: none;
  border: none;
  color: #206fcf;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.36px;
  padding-bottom: 4px;
  width: 27%;
  text-align: right;
  cursor: pointer;
`
export const StyledErrorDiv = styled.div`
color: red;
padding: 5px;
`

export const StyledOfferingsLabel = styled(Label)`
  width: 100%;
`
export const ModalFieldTitle = styled.p`
  font-weight: 600;
`
export const StyledButtonsContent = styled.div`
  text-align: right;
  & :first-child {
    margin-right: 8px;
  }
`

export const EditActiveStyledContainer = styled.div`
  width: 50%;
  margin-right: 20px;
  .selectrix-wrapper.selectrix-wrapper--type-autocomplete.editActive
    .react-selectrix
    .rs-body {
    display: none;
  }
  .selectrix-wrapper.selectrix-wrapper--type-autocomplete.editActive
    .react-selectrix
    .rs-header
    .rs-arrow-wrapper {
    display: none;
  }
  span.error {
    color: #d52b1e;
  }
`

export const StyledContainer = styled.div`
  width: 50%;
  margin-right: 20px;
  span .error {
    color: #d52b1e;
  }
`

export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled.span`
  padding-top: 24px;
`

export const SeasonSelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
`

export const SeasonDateWrapper = styled.div`
  margin-top: 6px;
  margin-right: 20px;
  display: flex;
  vertical-align: middle;
  align-items: center;
`

export const FormSubHeader = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 700;
  height: 20px;
  letter-spacing: -0.24px;
  line-height: 20px;
  margin: 15px 0;
  padding: 0 10px 0 5px;
`

export const CheckboxContainer = styled.div`
  width: 15%;
  align-self: flex-start;
  margin: 10px 0px;
`

export const DateDL = styled.dl`
  display: flex;
  flex-flow: wrap;
  width: 35em;
  margin: 0px 1em;
`

export const DateDT = styled.dt`
  flex-basis: 20%;
  padding: 2px 4px;
  font-weight: bold;
  text-transform: capitalize;
`

export const DateDD = styled.dd`
  flex-basis: 70%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin: 0px;
  padding: 2px 4px;
`

export const TimeOfUseDesc = styled.div`
  width: 50%;
`

export const UploadContainer = styled.div`
  .instructions {
    width: 100%;
    margin-bottom: 20px;
  }

  .upload-instructions {
    width: 80%;
  }

  .button-container {
    width: 20%;
    .download {
      float: right;
    }
  }

  span .link {
    margin-top: 10px;
  }

  .duplicate-error {
    display: flex;
    border: 2px solid ${colors.RED};
    width: 70%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    p {
      color: ${colors.RED};
      font-weight: 700;
      font-sizr: 12px;
      margin: 5px;
    }
  }
`

export const StepTitle = styled.h4`
  margin: 0 0 15px;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
`

export const CRMSiteIDDescription = styled.div`
  margin-bottom: 20px;
`
export const CRMSiteIdDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const BuildingCRMSiteIdDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

export const MinInputField = styled.div`
  width: 50%;
  .input-field {
    width: 100%;
  }
`
