import { useEffect, useState } from 'react'
import { ToolBar } from './components'
import { Block } from 'src/components/layouts'
import { SEARCH_BUILDINGS } from './graphql/queries'
import { BuildingsList } from './components/BuildingsList'
import {
  parseSalesOffice,
  parseSeachFieldsReq,
  parseSeachFieldsRes,
  parseOfferingsString
} from './helpers'
import { useQuery } from 'src/hooks/APIHooks'
import PageListFilter from 'src/components/legacy/components/page-list-filter/page-list-filter'
import { createWildCardFilterMultiple } from 'src/components/layouts/Table/wildCardFilter'
import { LoadTime } from 'src/components/loadTime'
import PageHeader from '../../components/pageHeaderNew/header'
import translate, { TranslateComponent } from 'src/common/translations'
import { getSearchParams } from "src/common/helperFunctions.js"


// The filter should only filter the items in the table based on data displayed within the table.
const SEARCH_FIELDS = [
  'name',
  'offeringCodes',
  'state',
  'deviceConnectivity',
  'salesOfficeCode',
  'salesOfficeName',
  'salesOfficeDistrict'
]

const tableName = 'Buildings'

const defaultDisplayControls = {
  limit: 30,
  from: 0,
  page: 0,
  sort: {
    direction: 'asc',
    field: 'name'
  },
  searchText: ''
}
const pageName = 'BuildingSetup'

export const BuildingSetup = () => {
  const params = getSearchParams()
  const [buildings, setBuildings] = useState({})
  const [buildingsState] = useState<any>({})
  const [accountId, setAccountId] = useState(null)
  const [buildingId, setBuildingId] = useState(null)
  const [displayControls, setDisplayControls] = useState(defaultDisplayControls)
  const [loadTime, setLoadtime] = useState(0)
  const [refetchCount, setRefetchCount] = useState(0)

  const optionsTranslated = {
    findBuilding: translate("Find a building")+"..."
  }

  const {
    data: buildingsData,
    refetch,
    responseTime
  } = useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    variables: {
      filter: params.buildingId ? {id: {eq: params.buildingId}} : { accountId: { eq: params.organizationId } }
    },
    errorPolicy: 'all',
    dataPath: 'data.searchBuildings'
  })

  useEffect(()=>{
    if (buildingsData?.total === null || buildingsData === null) {
        if(refetchCount < 1){
          refetch()
          setRefetchCount(refetchCount + 1)
        }
    }
    }, [buildingsData])

  useEffect(() => {
    const buildingDataToShow = buildingsData?.items?.reduce((acc, building) => {
      acc[building?.id] = {
        id: building?.id,
        accountId: building?.accountId,
        isActive: building?.isActive,
        checked: false,
        locationName: building?.name,
        salesOffice: parseSalesOffice(building),
        state: building?.state || '',
        connectivityTypes: building?.deviceConnectivity,
        offeringsString: parseOfferingsString(building),
        weatherData: building?.weatherData || '',
        createdByUser: building?.createdByUser || '',
        createdByTimestamp: building?.createdAt || '',
        tags: ''
      }
      return acc
    }, {})
    if (buildingDataToShow) {
      setLoadtime(responseTime)
      setBuildings(buildingDataToShow)
    }
  }, [buildingsData])

  useEffect(() => {
    setAccountId(params.organizationId)
    setBuildingId(params.buildingId)
  }, [params.organizationId, params.buildingId])

  useEffect(() => {
    if (accountId) {
      setDisplayControls({ ...defaultDisplayControls })
    }
  }, [accountId, buildingId])

  useEffect(() => {
    if (accountId) {
      const { from, limit, sort, searchText } = displayControls
      const searchFilter = { from, limit, sort }
      const accountFilter = buildingId ? { id: { eq: buildingId }, isActive: { eq: 1}} : accountId ? { accountId: { eq: accountId },isActive: { eq: 1} } : {}
      const wildCardFilter = searchText?.trim()
        ? createWildCardFilterMultiple({
            attrNames: SEARCH_FIELDS,
            value: searchText
          })
        : {}
      refetch({
        ...searchFilter,
        filter: { ...accountFilter, ...wildCardFilter }
      })
    }
  }, [displayControls])

  const onSortChange = ({ field, direction }) => {
    setDisplayControls((state) => ({
      ...state,
      ...defaultDisplayControls,
      sort: {
        direction,
        field: parseSeachFieldsReq(field)
      }
    }))
  }

  const onBuildingSelect = (id) => {
    setBuildings((buildings) => {
      const selectedBuilding = buildings[id]
      return {
        ...buildings,
        [id]: {
          ...selectedBuilding,
          checked: !selectedBuilding?.checked
        }
      }
    })
  }

  return (
    <div>
      <>
        <PageHeader
          pageTitle={<TranslateComponent>Building Setup</TranslateComponent>}
          titleTools={<ToolBar />}
        />
        <Block display={'flex'} align={'baseline'}>
          <PageListFilter
            // Filter Properties
            onChange={(props) =>
              setDisplayControls(() => ({
                ...defaultDisplayControls,
                searchText: props?.searchText
              }))
            }
            searchTextLabel={optionsTranslated.findBuilding}
            includeAllOrganizationItem={true}
            allItemsLabel="All Tags"
            // Counter Properties
            totalCountLabel={tableName}
            totalCount={buildingsData?.total ?? 0}
            displayControls={displayControls}
            testName={`${pageName}_buildingsearch_findabuilding`}
          />
          <LoadTime
            loadTime={loadTime}
            testName={`${pageName}_loadtimedetails_loadtime_text`}
          />
        </Block>
        <BuildingsList
          buildingsState={buildingsState}
          sortDirection={displayControls?.sort?.direction}
          sortField={parseSeachFieldsRes(displayControls?.sort?.field)}
          onSortChange={onSortChange}
          total={buildingsData?.total || 1}
          displayControls={displayControls}
          setDisplayControls={setDisplayControls}
          buildings={buildings}
          onBuildingSelect={onBuildingSelect}
        />
      </>
    </div>
  )
}
