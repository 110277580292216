import { useState, useEffect } from 'react'
import { node, string, arrayOf, shape, bool, oneOfType } from 'prop-types'
import { Block } from '..'
import { useTheme } from '@aws-amplify/ui-react'
import {
  AccordionArticle,
  AccordionStyles,
  ArticleContent,
  ArticleHeader,
  ArticleHeaderText
} from './style'
import Icon from 'src/components/Icon'

export const Accordion = (props) => {
  const { data } = props
  const [openArticle, setOpenArticle] = useState(data.defaultOpen ? 0 : null)
  const { tokens }: any = useTheme()
  // TODO: add ability to close and open and leave that way
  // TODO: add animation to ease slide open/close
  // TODO: add ellipsis component to expand text
  useEffect(() => {
    setOpenArticle(0)
  }, [data.defaultOpen])
  return (
    <AccordionStyles colors={tokens.colors}>
      {data.map((article, key) => {
        return (
          <AccordionArticle
            key={`Accordion-Article-${key}`}
            colors={tokens.colors}
          >
            <ArticleHeader
              spaces={tokens.space}
              onClick={
                openArticle === key
                  ? () => setOpenArticle(null)
                  : () => setOpenArticle(key)
              }
              data-testid={`${article.testName}_toggleicon`}
            >
              {article.iconPos === 'before' &&
                (openArticle === key ? (
                  <Icon
                    width="13px"
                    name="toggle-up"
                    height="100%"
                    viewBox="0 0 1024 1024"
                    margin="0 5px 0 0"
                  />
                ) : (
                  <Icon
                    width="13px"
                    name="toggle-down"
                    height="100%"
                    viewBox="0 0 1024 1024"
                    margin="0 5px 0 0"
                  />
                ))}
              <ArticleHeaderText colors={tokens.colors}>
                {article.header}
              </ArticleHeaderText>
              <Block
                cursor="pointer"
                display="flex"
                align="center"
                justify="flex-end"
              >
                {!article.iconPos &&
                  (openArticle === key ? (
                    <Icon
                      width="13px"
                      name="toggle-up"
                      height="100%"
                      viewBox="0 0 1024 1024"
                    />
                  ) : (
                    <Icon
                      width="13px"
                      name="toggle-down"
                      height="100%"
                      viewBox="0 0 1024 1024"
                    />
                  ))}
              </Block>
            </ArticleHeader>
            {openArticle === key && (
              <ArticleContent colors={tokens.colors} spaces={tokens.space}>
                {article.content}
              </ArticleContent>
            )}
          </AccordionArticle>
        )
      })}
    </AccordionStyles>
  )
}

Accordion.propTypes = {
  /** An array of objects, each containing a header string and content */
  data: arrayOf(
    shape({
      header: oneOfType([string, node]),
      content: node.isRequired,
      multiple: bool,
      defaultOpen: bool,
      iconPos: string,
      testName: string
    })
  ).isRequired
}
