/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxisDiM } from '../../../chart-library/Utils/defaults'
import { useChartContext } from '../../../chart-library/CommonComponents/BaseWrapper'
import { useCallback, useMemo } from 'react'

export function useConfig() {
  const chart = useChartContext()

  const axis = chart.axis
  const scale = chart.scale
  const canvas = chart.grid.getGrid(0)

  // xAxis
  const xAxis = useMemo(() => axis.getAxis(AxisDiM.x, 0), [axis.lastUpdatedAt])!

  const xScale = useMemo(
    () => scale.getScale(AxisDiM.x, 0),
    [scale.lastUpdatedAt]
  )!

  // yAxis
  const yAxis = useMemo(() => axis.getAxis(AxisDiM.y, 0), [axis.lastUpdatedAt])!

  const yScale = useMemo(
    () => scale.getScale(AxisDiM.y, 0),
    [scale.lastUpdatedAt]
  )!

  return {
    axis,
    axisUpdatedAt: axis.lastUpdatedAt,
    canvas,
    gridUpdatedAt: chart.grid.lastUpdatedAt,
    isInitialized: !(!scale.lastUpdatedAt || !axis.lastUpdatedAt),
    scale,
    scaleUpdatedAt: scale.lastUpdatedAt,
    xAxis,
    xScale,
    yAxis,
    yScale
  }
}
