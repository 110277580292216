import styled from 'styled-components'

export const PaginationContainer = styled.div`
  width: 100%;
  position: relative;
  letter-spacing: 0.24px;
  font-family: Arial;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
`
export const PaginationLeftContainer = styled.div`
  margin-left: 10px;
  margin-bottom: 7px;
`

export const ViewText = styled.span`
  margin-right: 10px;
`

export const PaginationNumbers = styled.span``

export const PaginationNumber = styled.span``

export const PaginationNumberButton = styled.button`
  padding: 0px 10px;
  margin: 0px 2px 0px 2px;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `border-bottom: 2px solid; border-radius: 0px; color: ${props.theme.head.background};`}
  ${(props) => !props.disabled && 'color: #8e8e8e;'}
`

export const PaginationRightContainer = styled.div`
  align-self: center;
  margin-right: 10px;
  margin-bottom: 5px;
`

export const PaginationPageSelectContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100px;
  vertical-align: middle;
  svg {
    cursor: pointer;
  }
`

export const PaginationPageLabelContainer = styled.span`
  margin-right: 26px;
  font-size: 14px;
`
