import { useMemo } from 'react'
import { StyledFieldSet, StyledFieldSetWrapper } from './RadioStyles'
import translate, { TranslateComponent } from '../../common/translations'

type IRadioTypes = {
  label?: string
  labelComponent?: any
  options: IRadioOption[]
  handleSelection: any
  radioKey: string
  selectedValue: any
  disabled?: boolean
  isPaddingRequired?: boolean
  className?: string
}

type IRadioOption = {
  label: string
  value: string
  htmlFor: string
}

const RadioGroup = (props: IRadioTypes) => {
  const {
    options = [],
    label,
    handleSelection,
    radioKey,
    selectedValue,
    labelComponent = null,
    disabled = false,
    className = ''
  } = props
  return (
    <StyledFieldSet className={props?.isPaddingRequired ? 'radio-group' : ''}>
      {labelComponent ? labelComponent : <legend><TranslateComponent>{label}</TranslateComponent></legend>}
      <StyledFieldSetWrapper className={className}>
        {options.map((option, key: number) => {
          const checked = useMemo(() => {
            return selectedValue == option.value
          }, [selectedValue])
          return (
            <div key={key}>
              <input
                name={radioKey}
                id={option.htmlFor}
                type="radio"
                onChange={handleSelection}
                value={option.value}
                checked={checked}
                disabled={disabled}
              />
              <label htmlFor={option.htmlFor} className="secondary">
                <TranslateComponent>{option.label}</TranslateComponent>
              </label>
            </div>
          )
        })}
      </StyledFieldSetWrapper>
    </StyledFieldSet>
  )
}

export default RadioGroup
