import createRBTree from 'functional-red-black-tree'
import { T } from 'ts-toolbelt'
import { MouseDirection, M_DIRECTION } from './mouseDirection'
import { SeriesExtentPoints } from './seriesExtentPoints'
import { SeriesPoints } from './seriesPoints'

interface SeriesPointIntance<T, V> {
  [i: string]: SeriesPoints<T, V>
}

interface SeriesExtentPointsInstance<T, V> {
  [i: string]: SeriesExtentPoints<T, V>
}

export class SeriesPointsCollection<T, V> extends MouseDirection {
  RBT: createRBTree.Tree<T, V>

  private dir: M_DIRECTION = M_DIRECTION.LEFT

  private pointsInstance: SeriesPointIntance<T, V> = {}

  private extentPointsInstance: SeriesExtentPointsInstance<T, V> = {}

  private cachedIterator: any = null

  private lastPostedKey: T | null = null

  private keys: any = {}

  private isMoveInprogress = false

  private density: null | number = null

  private nextRnage: number | null = null

  private prevRange: number | null = null

  private isMouseOut = false

  private subscriper = (item: V[]) => {}

  constructor(compareFn?: (a: any, b: any) => number) {
    super()

    this.RBT = createRBTree<T, V>(compareFn)
  }

  init(isExtent: boolean) {
    return isExtent
      ? new SeriesExtentPoints<T, V>(this)
      : new SeriesPoints<T, V>(this)
  }

  subscribe(callback: (item: V[]) => void) {
    this.subscriper = callback
  }

  mouseIn(e: any, pointerX: T) {
    // Reset props when mouse in to canvas
    if (this.isMouseOut) {
      super.onMouseOut()
      this.resetToDefault()
      this.isMouseOut = false
    }
    const dir = super.onMouseIn(e)
    this.density === null && this.maxMoveableRangeOfIterator()
    this.search(pointerX, dir)
  }

  mouseOut() {
    this.isMouseOut = true

    // reset data when mouse moves out of the area.
    this.subscriper([])
  }

  destroy() {
    this.dir = M_DIRECTION.LEFT
    this.pointsInstance = {}
    this.keys = {}
    this.cachedIterator = null
    // *Should be reseted only when component unmounts
    // Need to check the usage when using with context : TODO
    this.RBT = {} as any
  }

  _internal_register_points_series(id: any, instance: SeriesPoints<T, V>) {
    this.pointsInstance[id] = instance
  }

  // Range element : bar, plot line etc...
  _internal_register_extent_points_series(
    id: any,
    instance: SeriesExtentPoints<T, V>
  ) {
    this.extentPointsInstance[id] = instance
  }

  _internal_unregister_points_series(id: any) {
    delete this.pointsInstance[id]
  }

  _internal_unregister_extent_points_series(id: any) {
    delete this.extentPointsInstance[id]
  }

  _internal_insert_item(pointerX: T) {
    const k = '_' + pointerX

    // Store the key
    if (!this.keys[k]) {
      this.keys[k] = 0
    }

    // Increment when duplicate values are inserted with same key
    this.keys[k] += 1

    // Allow add only one item per key
    if (this.keys[k] === 1)
      this.RBT = this.RBT.insert(pointerX, 'inserted' as any)

    this.resetToDefault()
  }

  _internal_remove_item(pointerX: T) {
    const k = '_' + pointerX
    const occurance = this.keys[k]

    // When multiple values are in same key, decrement the count
    if (occurance > 1) {
      this.keys[k] -= 1
    } else if (occurance) {
      // Delete the instane only when there is no item
      delete this.keys[k]
      this.RBT = this.RBT.remove(pointerX)
    }

    this.resetToDefault()
  }

  private resetToDefault() {
    this.dir = M_DIRECTION.LEFT
    this.isMoveInprogress = false
    this.density =
      this.lastPostedKey =
      this.cachedIterator =
      this.prevRange =
      this.nextRnage =
        null
  }

  private maxMoveableRangeOfIterator() {
    const begin = this.RBT.begin.key as number
    const end = this.RBT.end.key as number

    if (begin !== undefined && end !== undefined) {
      const size = this.RBT.length
      this.density = ((end - begin) / size) * 10
    }
  }

  private search(pointerX: T, direction: any) {
    if (direction !== this.dir) {
      this.dir = direction
    }

    const isResetWhenHoverRangeNotContinues =
      this.lastPostedKey === null
        ? false
        : Math.abs((pointerX as number) - (this.lastPostedKey as number)) >
          Math.max(this.density, 2)

    if (isResetWhenHoverRangeNotContinues) {
      this.resetToDefault()
    }

    if (this.isMoveInprogress) {
      return []
    }

    if (this.cachedIterator) {
      this.isMoveInprogress = true

      try {
        this.moveTo(pointerX, this.cachedIterator)
      } catch (error) {
        console.log(
          'Unable to move to pointer position due to max call exceeded.'
        )
      }

      this.isMoveInprogress = false

      return
    }

    const iterator =
      this.dir === M_DIRECTION.RIGHT
        ? this.RBT.le(pointerX)
        : this.RBT.ge(pointerX)

    this.isMoveInprogress = true

    this.interate(iterator)

    this.isMoveInprogress = false
  }

  private interate(iterator: any) {
    if (!iterator.valid && !iterator.hasNext && !iterator.hasPrev) {
      return []
    }

    // Valid iterator, so store it for next activity
    this.cachedIterator = iterator

    this.post(iterator.key as any)
  }

  private post(pointerX: T) {
    let rangeData = []

    rangeData = this.queryAllExtentPoint(pointerX)

    if (this.lastPostedKey !== pointerX || !rangeData.length) {
      const q = this.queryAllPoint(pointerX)

      this.lastPostedKey = pointerX

      this.subscriper([...q, ...rangeData] as any[])
    }
  }

  private queryAllExtentPoint(pointerX: T): V[] {
    return this.queryAll(pointerX, this.extentPointsInstance)
  }

  private queryAllPoint(pointerX: T): V[] {
    return this.queryAll(pointerX, this.pointsInstance)
  }

  private queryAll(
    pointerX: T,
    instances: SeriesPointIntance<T, V> | SeriesExtentPointsInstance<T, V>
  ): V[] {
    const instancesId = Object.keys(instances)
    return instancesId.reduce((av: any, iId) => {
      const instance = instances[iId]

      if (instance) {
        const data = instance._internal_query(pointerX as any)
        av.push(...data)
      }

      return av
    }, [])
  }

  private moveTo(pointerX: T, iterator: any) {
    const isValid = iterator.valid
    const hasPrev = iterator.hasPrev
    const hasNext = iterator.hasNext

    const isLeft = this.dir === M_DIRECTION.LEFT
    const isRight = this.dir === M_DIRECTION.RIGHT

    if (!isValid) return

    const iPointerPosition = iterator.key

    if (
      (isLeft && iPointerPosition <= pointerX) ||
      (isRight && iPointerPosition >= pointerX)
    ) {
      // publish the data
      this.post(iPointerPosition)
      return
    }

    // find the pointer's next & prev range to proceed further
    this.range(iterator)

    // Don't allow to change the iterator pointer if there is no prev or next is null (esp at initial load)
    // and pointer position are within extent
    if (
      (this.prevRange === null || this.prevRange < pointerX) &&
      (this.nextRnage === null || pointerX <= this.nextRnage)
    ) {
      return
    }

    if (isLeft && hasPrev) {
      iterator.prev()

      // publish the data
      this.post(iPointerPosition)
    }

    if (isRight && hasNext) {
      iterator.next()

      // publish the data
      this.post(iPointerPosition)
    }

    if ((isLeft && !hasPrev) || (isRight && !hasNext)) {
      return
    }

    // Recursivly move to pointer position
    this.moveTo(pointerX, iterator)
  }

  private range(iterator: any) {
    const hasPrev = iterator.hasPrev
    const hasNext = iterator.hasNext
    const iIndex = iterator.index
    const iPointerPosition = iterator.key

    this.prevRange = hasPrev
      ? (this.getIndexValue(iIndex - 1) + iPointerPosition) / 2
      : null
    this.nextRnage = hasNext
      ? (this.getIndexValue(iIndex + 1) + iPointerPosition) / 2
      : null
  }

  private getIndexValue(idx: number): T | null {
    const iterator = this.RBT.at(idx)

    return iterator.valid ? iterator.key ?? null : null
  }
}
