import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  createButtonStyle,
  createFormControlStyle
} from '../../styles/styleCreators'
import { eventPub, SystemThemeProps, ThemeMode } from '../../utils'
import FormControl from '../FromControl'
import FormHelperText from '../FormHelperText'
import Input from '../Input'
import InputLabel from '../InputLabel'
import SelectInput from '../SelectInput'
import styled from 'styled-components'
import Icon from '../Icon'

const FormControlRoot = styled(FormControl)`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '250px')};
`

const getPropStyle = ({ theme, variant, dark }, prop, fallback = '') => {
  const mode = dark ? ThemeMode.DARK : ThemeMode.LIGHT
  const btnTheme = theme && theme[SystemThemeProps.BUTTON]
  const variantStyle = btnTheme && btnTheme[variant || 'primary'][mode]
  return variantStyle[prop] || fallback
}

const styleOptions = {
  base: false,
  disabled: false,
  focus: false,
  hover: false,
  shadow: false,
  size: false
}

const buttonBaseStyle = createButtonStyle({
  ...styleOptions,
  base: true,
  disabled: true,
  focus: true,
  hover: true
})

const buttonFocusedStyle = createButtonStyle({
  ...styleOptions,
  focus: true
})

const buttonDisabledStyle = createButtonStyle({
  ...styleOptions,
  disabled: true
})

const Select = styled(SelectInput)`
  ${buttonBaseStyle}

  svg {
    fill: ${(props) => getPropStyle(props, 'color', 'initial')};
  }

  &.focused {
    ${buttonFocusedStyle}
  }

  &.error {
    background-color: ${(props) =>
      getPropStyle(props, 'backgroundColor', 'initial')};
  }

  &.disabled {
    ${buttonDisabledStyle}
    svg {
      fill: ${(props) => getPropStyle(props, 'disabled', {}).color};
    }
  }

  border: inherit;
  border-radius: inherit;
  border-bottom-right-radius: 0;
  border-color: ${(props) => getPropStyle(props, 'backgroundColor', '#cccccc')};
  border-top-right-radius: 0;
  margin: -1px 0 -1px -1px;
  padding: 0;
  flex: 1 0 40%;
  column-gap: initial;

  .view {
    padding: 8px;
  }
`

const InputCompo = styled(Input)`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  &.disabled {
    box-shadow: none;
    pointer-events: none;
  }
`

const iconStyle = createFormControlStyle({ icon: true })

const IconRoot = styled.span`
  ${iconStyle}
  padding-left: 0;
`

const EndIcon = styled(Icon)`
  width: 1em;
`

const defaultSelectProps = {
  enableMenuScroll: true,
  menuHeight: '174px'
}

const Search = React.forwardRef(function Search(inProps, ref) {
  const {
    className,
    children,
    debounceTime,
    disabled,
    enableSelect = true,
    endAdornment,
    error,
    focused,
    fullWidth,
    helperText,
    HelperTextProps = {},
    id,
    inputComponent,
    InputLbaelProps = {},
    label,
    name,
    onChange = () => null,
    onEnter = () => null,
    onIconClick = () => null,
    onSelectChange = () => null,
    required,
    SearchIconProps = {},
    SelectProps = {},
    selectValue,
    selectVariant = 'primary',
    startAdornment,
    testName,
    value: valueProp,
    ...inputProps
  } = inProps

  const formContextProps = {
    disabled,
    error,
    focused,
    required,
    fullWidth
  }

  const InputComponent = enableSelect ? InputCompo : Input

  const [value, setValue] = useState(valueProp || '')

  useEffect(() => {
    if (value !== valueProp) setValue(valueProp || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProp])

  const handleValueChange = (event) => {
    setValue(event.target.value)
    onChange(event)
  }

  const handleEvents = (callback) => (event) => {
    eventPub(event, value, name, callback)
  }

  const handleOnEnter = (event) => {
    if (event.keyCode !== 13) return

    handleEvents(onEnter)(event)
  }

  const componentProps = (function () {
    const endAdornmentIcon = (
      <IconRoot onClick={handleEvents(onIconClick)}>
        {endAdornment}
        {!endAdornment && (
          <EndIcon size="1em" name="Search" {...SearchIconProps} />
        )}
      </IconRoot>
    )

    const commonProps = {
      ...inputProps,
      endAdornment: endAdornmentIcon,
      id,
      name,
      ref,
      fullWidth,
      onChange: handleValueChange,
      onkeydown: handleOnEnter,
      value
    }

    if (!enableSelect) return commonProps

    return {
      ...commonProps,
      startAdornment: (
        <Select
          {...{ ...defaultSelectProps, ...SelectProps }}
          RenderValueProps={{
            ...SelectProps?.RenderValueProps,
            className: `view ${SelectProps?.RenderValueProps?.className}`
          }}
          value={selectValue}
          onChange={onSelectChange}
          variant={selectVariant}
        >
          {children}
        </Select>
      )
    }
  })()

  return (
    <FormControlRoot {...formContextProps} className={className}>
      {label && (
        <InputLabel htmlFor={id} {...InputLbaelProps}>
          {label}
        </InputLabel>
      )}

      <InputComponent
        {...componentProps}
        className="input-section"
        data-testid={`${testName}_input`}
      />
      {helperText && (
        <FormHelperText {...HelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControlRoot>
  )
})

Search.propTypes = {
  /**
   * The Option compoent to populate the select with. Refer Option component.
   */
  children: PropTypes.node,

  /**
   * If true, the component is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * If true, Search field will have select along with input.
   */
  enableSelect: PropTypes.bool,

  /**
   * End InputAdornment for this component. Default is Search Icon.
   * Icon can be changed by passing svg icon
   */
  endAdornment: PropTypes.node,

  /**
   * If true, the input will indicate an error.
   */
  error: PropTypes.bool,

  /**
   * If true, the search field will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,

  /**
   * Props applied to the InputLabel element.
   */
  HelperTextProps: PropTypes.object,

  /**
   * The helper text content.
   */
  helperText: PropTypes.any,

  /**
   * The id of the input element.
   */
  id: PropTypes.string,

  /**
   * The component used for the input element.
   */
  inputComponent: PropTypes.elementType,

  /**
   * Props applied to the InputLabel element.
   */
  InputLabelProps: PropTypes.object,

  /**
   * The label content.
   */
  label: PropTypes.any,

  /**
   * Name attribute of the input element.
   */
  name: PropTypes.string,

  /**
   * Callback fired when the value of input is changed.
   */
  onChange: PropTypes.func,

  /**
   * Callback fired when enter key pressed on input element.
   */
  onEnter: PropTypes.func,

  /**
   * Callback fired when search icon being cliked.
   */
  onIconClick: PropTypes.func,

  /**
   * Callback fired when the value of select is changed.
   */
  onSelectChange: PropTypes.func,

  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder: PropTypes.string,

  /**
   * It prevents the user from changing the value of the field
   */
  readOnly: PropTypes.bool,

  /**
   * Props applied to the Search Icon. Refer Icon component api.
   */
  SearchIconProps: PropTypes.object,

  /**
   * Props applied to the Select element. Refer SelectInput component api.
   */
  SelectProps: PropTypes.object,

  /**
   * The value of the select element, required for a controlled component.
   */
  selectValue: PropTypes.any,

  /**
   * Applies the select styles.
   */
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['primary', 'secondary']),
    PropTypes.string
  ]),

  /**
   * Start InputAdornment for this component.
   */
  startAdornment: PropTypes.node,

  /**
   * Type of the input element.
   */
  type: PropTypes.string,

  /**
   * The value of the input element, required for a controlled component.
   */
  value: PropTypes.any,

  testName: PropTypes.string
}

Search.defaultProps = {
  debounceTime: 500,
  disabled: false,
  enableSelect: false,
  error: false,
  fullWidth: false,
  inputComponent: 'input',
  readOnly: false,
  type: 'text'
}

export default Search

export { default as Option } from '../Option'
