import React from 'react'
import PropTypes from 'prop-types'
import  { TranslateComponent } from 'src/common/translations'


import './checkbox.scss'

export default class Checkbox extends React.PureComponent {
  static propTypes = {
    isChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    toggleCheck: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    customClassName: PropTypes.string,
    testName: PropTypes.string,
    labelToggle: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    customClassName: ''
  }

  render() {
    const {
      disabled,
      isChecked,
      label,
      toggleCheck,
      id,
      isLoading,
      customClassName,
      testName='',
      labelToggle
    } = this.props
    return (
      <div className={`checkbox-container ${customClassName}`}>
        {isLoading ? (
          <span className="spinner" />
        ) : (
          <input
            type="checkbox"
            data-testid={`${testName || 'tc'}_checkbox`}
            checked={isChecked}
            disabled={disabled}
            onChange={toggleCheck}
            id={id}
          />
        )}
        <label className="checkbox-label" htmlFor={id} onClick={labelToggle ? toggleCheck : undefined}>
         <TranslateComponent>{label}</TranslateComponent>
        </label>
      </div>
    )
  }
}
