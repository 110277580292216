// Default Options.
import { useMemo } from 'react'

// Developer can set min & max / category data later, once it receives from API using setDomian
export function useOptions(categories, yAxisMin, yAxisMax): Options {
  return useMemo<Options>(
    () => ({
      xAxis: {
        type: 'scaleBand',
        data: categories
      },
      yAxis: {
        type: 'scaleLinear',
        min: yAxisMin,
        max: yAxisMax
      },
      grid: [{ left: '40px', right: '30px', top: '30px', bottom: '110px' }]
    }),
    [categories, yAxisMin, yAxisMax]
  )
}
