import { quantile } from 'd3'

export default function gridPosition(
  containerRect: any,
  positions: any,
  margin: any = [0, 0, 1, 0]
): GridPosition {
  const containerWidth = containerRect.width
  const containerHeight = containerRect.height
  let left = parsePercent(positions.left, containerWidth)
  let top = parsePercent(positions.top, containerHeight)
  const right = parsePercent(positions.right, containerWidth)
  const bottom = parsePercent(positions.bottom, containerHeight)
  let width = parsePercent(positions.width, containerWidth)
  let height = parsePercent(positions.height, containerHeight)
  const verticalMargin = margin[2] + margin[0]
  const horizontalMargin = margin[1] + margin[3]

  if (isNaN(width)) {
    width = containerWidth - right - horizontalMargin - left
  }

  if (isNaN(height)) {
    height = containerHeight - bottom - verticalMargin - top
  }

  if (isNaN(left)) {
    left = containerWidth - right - width - horizontalMargin
  }

  if (isNaN(top)) {
    top = containerHeight - bottom - height - verticalMargin
  } // Align left and top

  // TODO : later it(trbl) will be supproted
  switch (positions.left || positions.right) {
    case 'center':
      left = containerWidth / 2 - width / 2 - margin[3]
      break

    case 'right':
      left = containerWidth - width - horizontalMargin
      break
  }

  switch (positions.top || positions.bottom) {
    case 'middle':
    case 'center':
      top = containerHeight / 2 - height / 2 - margin[0]
      break

    case 'bottom':
      top = containerHeight - height - verticalMargin
      break
  } // If something is wrong and left, top, width, height are calculated as NaN

  left = left || 0
  top = top || 0

  if (isNaN(width)) {
    // Width may be NaN if only one value is given except width
    width = containerWidth - horizontalMargin - left - (right || 0)
  }

  if (isNaN(height)) {
    // Height may be NaN if only one value is given except height
    height = containerHeight - verticalMargin - top - (bottom || 0)
  }

  const rect: any = BoundingRect(
    left + margin[3],
    top + margin[0],
    width,
    height
  )

  // console.log("Rect boundyr :", rect);

  return {
    ...rect,
    margin: margin
  }
}

function BoundingRect(x: number, y: number, width: number, height: number) {
  if (width < 0) {
    x = x + width
    width = -width
  }
  if (height < 0) {
    y = y + height
    height = -height
  }

  return {
    x,
    y,
    width,
    height
  }
  /*   this.x = x;
  this.y = y;
  this.width = width;
  this.height = height; */
}

function convertToPixel(range: any[], position: number) {
  return quantile(range, position) || 0
}

export function parsePercent(
  percent: number | string | undefined,
  all: number
) {
  // TODO : allow margins to use trbl properties later. For now it allows interger values
  switch (percent) {
    case 'center':
    case 'middle':
      percent = '50%'
      break

    case 'left':
    case 'top':
      percent = '0%'
      break

    case 'right':
    case 'bottom':
      percent = '100%'
      break
  }

  if (typeof percent === 'string') {
    if (String(percent).includes('%')) {
      return (parseFloat(percent) / 100) * all
    }

    return parseFloat(percent)
  }

  return percent == null ? NaN : +percent
}

/* 
  
    if (aspect != null) {
      // If width and height are not given
      // 1. Graph should not exceeds the container
      // 2. Aspect must be keeped
      // 3. Graph should take the space as more as possible
      // FIXME
      // Margin is not considered, because there is no case that both
      // using margin and aspect so far.
      if (isNaN(width) && isNaN(height)) {
        if (aspect > containerWidth / containerHeight) {
          width = containerWidth * 0.8;
        } else {
          height = containerHeight * 0.8;
        }
      } // Calculate width or height with given aspect
  
      if (isNaN(width)) {
        width = aspect * height;
      }
  
      if (isNaN(height)) {
        height = width / aspect;
      }
    } // If left is not specified, calculate left from right and width
  
  
  
  */
