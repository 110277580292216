import { selectUserInfo, setUserInfo } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import Modal from 'src/components/legacy/components/modal/modal'
import Select from 'src/components/legacy/components/select/select'
import styled from 'styled-components'
import { useFormik } from 'formik'
import validationSchema from "./formValidator"
import Icon from "src/components/Icon"
import { useState } from 'react'
import PhoneInput from "react-phone-number-input"
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { SEARCH_SALESOFFICES, UPDATE_OWN_SELF } from './queries'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import { GET_USER_BY_ID } from 'src/graphql/userQueries'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useDispatch } from 'react-redux'
import { parseUserData } from 'src/parseUserData'
import { setSelectedLanguage } from 'src/redux/slicers/appData'
import { clearTranslations } from 'src/redux/slicers/translationSlice'
import translate from "src/common/translations"

const OtherInputWrapper = styled.div`
    margin-top: 4px;
    text-align: right;
    color: #666;
    display: block;
    font-size: 14px;
    font-weight: 700;
    // May change width if switch to columns
    width: 50%;
`

const StyledIntroText = styled.h3`
  margin-bottom: 48px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
`

const FieldHeader = styled.label`
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 20px;
`

const GrayText = styled.p`
  color: #666;
  display: block;
  font-size: 14px;
  font-weight: 700;
`

const ProfileHelpLink = styled.a`
    cursor: pointer;
    text-decoration: underline;
`

const jobTitles = {
  "VPorFacilities": "VP or Director of Facilities",
  "VPorMaintenance": "VP or Director of Maintenance",
  "buildingSuperintendent": "Building Superintendent",
  "facilityBuildingEngineer": "Facility/Building Engineer",
  "maintenanceEngineer": "Maintenance Engineer",
  "buildingOwner": "Building Owner",
  "energyManager": "Energy Manager",
  "propertyManager": "Property Manager",
  "operationsManager": "Operations Manager",
  "facilityManager": "Facility Manager",
  "assetManager": "Asset Manager",
  "assistantDirector": "Assistant Director",
  "assistantPropertyManager": "Assistant Property Manager",
  "facilitiesBuildingSupervisorCoordinator": "Facilities/Building Supervisor/Coordinator",
  "environmentalServicesSupervisorCoordinator": "Environmental Services Supervisor/Coordinator",
  "HVACControlsTechnician": "HVAC/Controls Technician",
  "maintenanceTechnician": "Maintenance Technician",
  "buildingOperator": "Building Operator",
  "other": "Other"
}
const jobTitlesTraneEmployee = {
  "accountManager": "Account Manager",
  "areaServiceManager": "Area Service Manager",
  "accountEngineer": "Account Engineer",
  "optimizationEngineer": "Optimization Engineer",
  "energyEngineer": "Energy Engineer",
  "controlsTechnician": "Controls Technician",
  "contractorTechnician": "Contractor Technician",
  "mechanicalTechnician": "Mechanical Technician",
  "remoteServiceSpecialist": "Remote Service Specialist",
  "resourceCoordinatorEstimator": "Resource Coordinator Estimator",
  "tisManagement": "TIS Management",
  "otherTraneManagement": "Other Trane Management",
  "softwareDeveloperQa": "Software Developer/QA",
  "softwarePoUxScrum": "Software PO/UX/Scrum",
  "other": "Other"
}
const industriesList = {
  "commercialRealEstate": "Commercial Real Estate",
  "dataCenter": "Data Center",
  "government": "Government",
  "healthcare": "Healthcare",
  "higherEducation": "Higher Education",
  "hospitality": "Hospitality",
  "indoorAgriculture": "Indoor Agriculture",
  "k12": "K-12",
  "retail": "Retail",
  "other": "Other"
}

const languages = {
"en": "English",
"af": "Afrikaans",
"sq": "Albanian",
"am": "Amharic",
"ar": "Arabic",
"hy": "Armenian",
"az": "Azerbaijani",
"bn": "Bengali",
"bs": "Bosnian",
"bg": "Bulgarian",
"ca": "Catalan",
"zh": "Chinese (Simplified)",
"zh-TW": "Chinese (Traditional)",
"hr": "Croatian",
"cs": "Czech",
"da": "Danish",
"fa-AF": "Dari",
"nl": "Dutch",
"et": "Estonian",
"fa": "Farsi (Persian)",
"tl": "Filipino, Tagalog",
"fi": "Finnish",
"fr": "French",
"fr-CA": "French (Canada)",
"ka": "Georgian",
"de": "German",
"el": "Greek",
"gu": "Gujarati",
"ht": "Haitian Creole",
"ha": "Hausa",
"he": "Hebrew",
"hi": "Hindi",
"hu": "Hungarian",
"is": "Icelandic",
"id": "Indonesian",
"ga": "Irish",
"it": "Italian",
"ja": "Japanese",
"kn": "Kannada",
"kk": "Kazakh",
"ko": "Korean",
"lv": "Latvian",
"lt": "Lithuanian",
"mk": "Macedonian",
"ms": "Malay",
"ml": "Malayalam",
"mt": "Maltese",
"mr": "Marathi",
"mn": "Mongolian",
"no": "Norwegian (Bokmål)",
"ps": "Pashto",
"pl": "Polish",
"pt": "Portuguese (Brazil)",
"pt-PT": "Portuguese (Portugal)",
"pa": "Punjabi",
"ro": "Romanian",
"ru": "Russian",
"sr": "Serbian",
"si": "Sinhala",
"sk": "Slovak",
"sl": "Slovenian",
"so": "Somali",
"es": "Spanish",
"es-MX": "Spanish (Mexico)",
"sw": "Swahili",
"sv": "Swedish",
"ta": "Tamil",
"te": "Telugu",
"th": "Thai",
"tr": "Turkish",
"uk": "Ukrainian",
"ur": "Urdu",
"uz": "Uzbek",
"vi": "Vietnamese",
"cy": "Welsh"
}
const unitOfMeasurement = {
  "si": "International System (meter, kilogram)",
  "im": "Imperial (foot, inch; ounce, pound, gallon)"
}
const createSelectorOptions = (input) => Object.keys(input).map((k) => ({
    key: k,
    value: input[k]
  })
  )

export const Profile = ({ closeModal }) => {
  const user = useSelector(selectUserInfo)
  const [editUserNameEnabled, setEditUserNameEnabled] = useState(false)
  const { user: cognitoUser } = useAuthenticator()
  const dispatch = useDispatch()
  const changeLanguage = (language) => {
      dispatch(setSelectedLanguage(language))
  };
  const optionsTranslated = {
    welcome1: translate("Welcome to "),
    welcome2: translate("your single source for building information! In order to best optimize your experience, please let us know a little bit about you."),
    hi: translate("Hi"),
    firstName: translate("First Name"),
    lastName: translate("Last Name"),
    jobTitle: translate("Job Title"),
    chooseClose: translate("choose closest to your title"),
    other: translate("Other"),
    primarySales: translate("Primary Sales Office"),
    industry: translate("Industry"),
    phoneNum: translate("Phone Number"),
    emailAddr: translate("Email Address"),
    toChange: translate("To change your email address in TraneConnect, please fill out a support request by clicking"),
    here: translate("here"),
    save: translate("Save"),
    cancel: translate("Cancel"),
    language: translate("Language"),
    uom: translate("Units of Measurement")
  }
  const getButtons = (submitForm, isFormValid = false) => {
    return [
          {
            text: optionsTranslated.save,
            type: 'submit',
            handleClick: () => {submitForm()
              dispatch(clearTranslations())
              changeLanguage(values.profileSettings.language)
            },
            disabled: !isFormValid
          },
          {
            text: optionsTranslated.cancel,
            type: 'cancel',
            handleClick: () => closeModal()
          }
        ]
  }
  const { data: salesOfficeData = [] } = useQuery({
    query: SEARCH_SALESOFFICES,
    variables: { limit: 1000 },
    disableInitialLoad: user.type?.name !== "Internal",
    errorPolicy: 'all',
    dataPath: 'data.searchSalesOffices.items'
  })

  const { refetch: refetchUser } = useQuery({
    query: GET_USER_BY_ID,
    variables: { id: cognitoUser?.attributes?.["custom:userId"] || '' },
    disableInitialLoad: true,
    onSuccess: (rawUserInfo) => {
      const data = parseUserData(rawUserInfo)
      dispatch(
        setUserInfo({
          data
        })
      )
    },
    onError: (error) => {
      console.log(error)
    },
    errorPolicy: 'ignore',
    dataPath: 'data.getUser'
  })

  const { onSubmit: updateOwnUser } = useMutation({
    query: UPDATE_OWN_SELF,
    dataPath: 'data',
    onSuccess: (data) => {
      refetchUser({ id: cognitoUser?.attributes?.["custom:userId"] || '' })
    }
  })

  //TODO add job title, industry, and smsNumber to user obj and remove from the state setter here
  const formik = useFormik({
    initialValues: {...user, profileSettings: user.profileSettings ?JSON.parse(user.profileSettings) : {}},
    validationSchema: validationSchema(user),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const input = {...values}
      updateOwnUser({requestBody: JSON.stringify(input)})
      closeModal()
    }
  })
  const { values, errors } = formik
  const modalConfig = {
    gray: true,
    heading: `${user?.firstName} ${user?.lastName}'s Profile`,
    className: 'wide-modal note-details-modal',
    buttons: getButtons(formik.handleSubmit, formik.isValid),
    handleClose: () => closeModal(),
    formHasRequiredFields: true
  }

  return <Modal {...modalConfig} >
    <>
        <StyledIntroText style={{marginBottom: "24px", fontWeight: "normal"}}>
          {
          !editUserNameEnabled ? <>
          {optionsTranslated.hi} {user?.firstName},
          <Icon name="edit" 
            color="#1E1E1E"
            margin="0px 0px 0px 10px"
            onClick={() => setEditUserNameEnabled(true)}
            height="100%"
            width="15px"
          />
          </> :
          <>
          <div>
          <FieldHeader>{optionsTranslated.firstName}*</FieldHeader>
          <br />
            <input
              type="text"
              value={values.firstName}
              onChange={event => formik.setFieldValue(
                'firstName',
                event.target.value
              )}
            />
          </div>
          <br/>
          <div>
          <FieldHeader>{optionsTranslated.lastName}*</FieldHeader>
          <br/>
            <input
              type="text"
              value={values.lastName}
              onChange={event => formik.setFieldValue(
                'lastName',
                event.target.value
              )}
            />
          </div>
          </>
          }
        </StyledIntroText>
        <StyledIntroText>{optionsTranslated.welcome1}Traneconnect, {optionsTranslated.welcome2}</StyledIntroText>
        <FieldHeader>{optionsTranslated.jobTitle} ({optionsTranslated.chooseClose})*</FieldHeader>
          <Select
            placeholder={"- Select One -"}
            selectedItem={values?.type?.name === "Internal" ? jobTitlesTraneEmployee?.[values?.["jobTitle"]] : jobTitles?.[values?.["jobTitle"]]}
            onChange={(value) => {
              formik.setFieldValue(
                'jobTitle',
                value
              )
            }}
            options={values?.type?.name === "Internal" ? createSelectorOptions(jobTitlesTraneEmployee) : createSelectorOptions(jobTitles)}
          />
          {values["jobTitle"] && (values?.["jobTitle"] === "other" || (values?.type?.name === "Internal" ? !jobTitlesTraneEmployee?.[values?.["jobTitle"]] : !jobTitles?.[values?.["jobTitle"]])) && <OtherInputWrapper>
            {optionsTranslated.other} &nbsp;
            <input
              type="text"
              value={values["jobTitle"]}
              onChange={(event) => formik.setFieldValue(
                'jobTitle',
                event.target.value
              )
            }
            />
          </OtherInputWrapper>
          }
          {<p className="error"><>{errors["jobTitle"] && "Job title is required."}</></p> }
        <br />
          {
          values?.type?.name === "Internal" && <>
          <FieldHeader>{optionsTranslated.primarySales}*</FieldHeader>
          <div style={{minWidth: "200px", maxWidth: "200px"}}>
          <SelectrixWrapper
            defaultValue={values["primarySalesOfficeId"]}
            inputPlaceholder={"Find an Office..."}
            placeholder={"- Select One -"}
            selectedItem={null}
            onChange={(value) => {
              formik.setFieldValue(
                'primarySalesOfficeId',
                value.key
              )
            }}
            options={salesOfficeData?.map(s => ({key: s.id, label: s.name}))}
            className="selectrix-wrapper selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select user-list"
            searchable={true}
            searchBoxInside={true}
            searchIndex={false}
            lifo={true}
          />
          </div>
          {<p className="error"><>{errors["primarySalesOfficeId"] && "Primary Sales Office is required."}</></p> }
          <br />
          </>
          }
          {
            values?.type?.name !== "Internal" && <>
            <FieldHeader>{optionsTranslated.industry}*</FieldHeader>
            <Select
            placeholder={"- Select One -"}
            selectedItem={industriesList?.[values?.["industry"]]}
            onChange={(value) => {
              formik.setFieldValue(
                'industry',
                value
              )
            }}
            options={createSelectorOptions(industriesList)}
          />
          </>
          }
          {values?.type?.name !== "Internal" && values["industry"] && (values?.["industry"] === "other" || ( !industriesList?.[values?.["industry"]])) && <OtherInputWrapper>
            {optionsTranslated.other} &nbsp;
            <input
              type="text"
              value={values["industry"]}
              onChange={(event) => formik.setFieldValue(
                'industry',
                event.target.value
              )}
            />
          </OtherInputWrapper>
          }
          {<p className="error"><>{errors["industry"] && "Industry is required."}</></p> }
          {
          <>
          <br />
          <FieldHeader>{optionsTranslated.language}</FieldHeader>
          <Select
            placeholder={"- Select One -"}
            selectedItem={languages?.[values?.["profileSettings"].language]}
            onChange={(value) => {
              formik.setFieldValue(
                'profileSettings.language',
                value
              )
            }}
            options={createSelectorOptions(languages)}
            isUserdefinedOptions={true}
          />
          <br />
          </>
          }
          {
          <>
          <FieldHeader>{optionsTranslated.uom}</FieldHeader>
          <Select
            placeholder={"- Select One -"}
            selectedItem={unitOfMeasurement?.[values?.["profileSettings"].unitOfMeasurement]}
            onChange={(value) => {
              formik.setFieldValue(
                'profileSettings.unitOfMeasurement',
                value
              )
            }}
            options={createSelectorOptions(unitOfMeasurement)}
          />
          <br />
          </>
          }
          <div>
          <FieldHeader>{optionsTranslated.phoneNum}</FieldHeader>
          <br/>
            <PhoneInput
								name="phoneNumber"
								placeholder="+99 999 999 9999"
								value={values["phoneNumber"] || ""}
                style={{display: "inline-flex"}}
								onChange={value => {
									formik.setFieldValue(
                    "phoneNumber",
                    value
                  )
								}}
							/>              
          </div>
          <br />
          <FieldHeader>{optionsTranslated.emailAddr}:</FieldHeader>
          <br />
          <GrayText style={{fontStyle: "italic", marginBottom: "24px"}}>{user.email}</GrayText>
          {/* TODO: Implement the link below */}
          <GrayText>{optionsTranslated.toChange} <ProfileHelpLink>{optionsTranslated.here}</ProfileHelpLink>.</GrayText>
          </>
      </Modal>

  
}

export default Profile
