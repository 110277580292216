import { ChangeEvent, useEffect } from 'react'
import {
  RadioButtonWrapper,
  TabWrapper,
  WidgetLinkTestWrapper,
  PanelLabel,
  PanelHr
} from '../styles'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import { RadioGroup } from 'src/components'
import { useFormikContext } from './MultiTabTorm'
import { useTranslation } from 'react-i18next'
import TextInput from 'src/components/legacy/components/modal-text-input'
import * as Yup from 'yup'
import { useWidgetsContext } from '../WidgetsContextProvider'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { widgetLoctionDefaultValue, WidgetNames, getEnergyWidgetLinkList, isCorrectUrl, openLink } from '../helpers'
import Select from 'src/components/legacy/components/select/select'
import i18next from 'i18next'
import translate, { TranslateComponent } from 'src/common/translations'
const WIDGET_NAME_MAX_LENGTH = 80
const WIDGET_DESCRIPTION_MAX_LENGTH = 250

export const WidgetOverviewSchema = Yup.object().shape({
  name: Yup.string().required("name is a required field").max(WIDGET_NAME_MAX_LENGTH, `Widget Name cannot be greater than ${WIDGET_NAME_MAX_LENGTH} characters`),
  
  description: Yup.string().max(WIDGET_DESCRIPTION_MAX_LENGTH, `Widget Description cannot be greater than ${WIDGET_DESCRIPTION_MAX_LENGTH} characters`)
})
export const WidgetBuildingSchema = Yup.object().shape({
  widgetBuilding: Yup.string().required('The widget building is required.')
})
export const BaselineTargetObjectSchema = Yup.object().shape({
  baselineId: Yup.string().required("This value is required.")
})

export const WidgetOverview = () => {
  const formik = useFormikContext()
  const { selectedWidget, isLocationLoading, widgetBuilding  } = useWidgetsContext()
  const { values, errors, setFieldValue, touched } = formik
  const [t] = useTranslation()
  const handleInputChange = (key, value) => {
    setFieldValue(key, value)
  }
  const getOptions = (options = []) => {
    const radioFields = [
      { label: "Yes", value: '1' },
      { label: "No", value: '0' }
    ].map((option: any, index: number) => ({
      ...option,
      htmlFor: options?.[index]
    }))
    return radioFields
  }
  const getMetricOptions = (options = []) => {
    const radioFields = [
      { label: "Celsius", value: '0' },
      { label: "Fahrenheit", value: '1' }
    ].map((option: any, index: number) => ({
      ...option,
      htmlFor: options?.[index]
    }))
    return radioFields
  }
  const { widgetBuildingDetails, selectedBaseLine } = useWidgetsContext()




  useEffect(() => {
    if (widgetBuildingDetails) {
      if (selectedWidget?.type === WidgetNames.Weather) {
        const { city, state, postalCode } = widgetBuildingDetails
        handleInputChange('locationDetails', `${city ?? ''}, ${state ?? ''}, ${postalCode ?? ''}`)     
      }
    } else {
      handleInputChange('locationDetails',widgetLoctionDefaultValue(t))
    }
  }, [widgetBuildingDetails])
  
  useEffect(() => {
    if(selectedBaseLine){
      setFieldValue("baselineId", selectedBaseLine?.id)
    }
  }, [selectedBaseLine])
  
  const isEnergyWidget = () => [WidgetNames.EnergyConsumption,WidgetNames.EnergyCost,WidgetNames.EnergyDemand].includes(selectedWidget?.type)
  return (
    <TabWrapper>
      <TextInput
        labelText={"Widget Name"}
        value={values?.name}
        name="name"
        onChange={({
          target: { value, name }
        }: ChangeEvent<HTMLInputElement>) => {
          formik.setFieldTouched('name')
          handleInputChange(name, value)
        }}
        hasError={touched?.name && errors?.['name']?errors?.['name']: null}
        isRequired={true}
        errorMessage={errors?.['name']}
        maxLength={WIDGET_NAME_MAX_LENGTH}
        autoComplete= "off"
      />
      <TextAreaInput
        labelText={"Widget Description"}
        defaultValue={values?.description}
        name="description"
        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
          handleInputChange('description', value)
        }}
        isRequired={false}
        hasError={errors?.['description']}
        errorMessage={errors?.['description']}
        hideErrorIcon={false}
        maxLength={WIDGET_DESCRIPTION_MAX_LENGTH}
      />
      {selectedWidget?.type === WidgetNames.Weather && (
        <>
          <PanelLabel>{<TranslateComponent>Widget Location Display</TranslateComponent>}</PanelLabel>
          {isLocationLoading ? <Spinner /> : <div><TranslateComponent>{values?.locationDetails}</TranslateComponent></div>}
          <RadioButtonWrapper>
            <RadioGroup
              label={"Metric Conversion"}
              handleSelection={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(name, value)
              }}
              radioKey="metricConversion"
              options={getMetricOptions(['new-celsius', 'new-fahrenheit'])}
              selectedValue={values?.metricConversion}
            />
          </RadioButtonWrapper>
          <PanelHr />
        </>
      )}
      {isEnergyWidget() &&
        <>
          <PanelLabel>{<TranslateComponent>Widget Link</TranslateComponent>}</PanelLabel> 
          <Select
            selectedItem={t(`widget-builder:${values?.widgetLinkType}`)}
            onChange={(value) => setFieldValue('widgetLinkType', value)}
            options={getEnergyWidgetLinkList(t, selectedWidget?.type)}
          />
        </>
      }
      {((isEnergyWidget() && values?.widgetLinkType === 'CustomURL') || (!isEnergyWidget() && selectedWidget?.type !== WidgetNames.BaseLineComparison)) &&
        <>
          <TextInput
            labelText={`${isEnergyWidget() ? 'Custom URL' : 'Widget Link'}`}
            defaultValue={values?.link}
            name="link"
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
              handleInputChange('link', value)
            }}
            hasError={errors?.['link']}
            isRequired={false}
            errorMessage={errors?.['link']}
            autoComplete= "off"
          />
          <WidgetLinkTestWrapper>
            <p className="tip"><TranslateComponent>Copy the site address from the address bar of your browser</TranslateComponent></p>
            <button
              className="secondary small"
              type="button"
              onClick={() => {
                // Link should open in new tab/window irresprctive of new window selection in create/edit mode
                if (values?.link) {
                  openLink(values?.link)
                }
              }}
            >
              <TranslateComponent>TEST LINK</TranslateComponent>
            </button>
          </WidgetLinkTestWrapper>
        </>
      }
      <RadioButtonWrapper>
        <RadioGroup
          label={"Open link in a new browser window?"}
          handleSelection={({
            target: { value, name }
          }: ChangeEvent<HTMLInputElement>) => {
            handleInputChange(name, value)
          }}
          radioKey="isNewWindow"
          options={getOptions(['new-browser-yes', 'new-browser-no'])}
          selectedValue={values?.isNewWindow}
        />
      </RadioButtonWrapper>
    </TabWrapper>
  )
}
