import styled from 'styled-components'

export const MapContainer = styled.div`
  border-left: 1px solid gray;
  padding-left: 20px;
  height: 100%;
  h2 {
    margin: 5px 0 10px 0;
  }
  button {
    cursor: pointer;
  }

  .map-download-button {
    font-family: inherit;
    color: var(--amplify-components-button-color);
    border-radius: 6px;
    padding: 4px 10px;
    border: 1px solid var(--amplify-components-button-border-color);
    cursor: pointer;
    background-color: var(--amplify-components-button-background-color);
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 0.75rem;
    text-transform: uppercase;
    margin-left: 25px;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 12px;
    font-weight: 700;
    font-size: 11px;

    &.disabled {
      pointer-events: none;
      background: lightgray;
    }
  }
`
export const Status = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid gray;
  width: fit-content;
  svg {
    position: relative;
  }
  button {
    margin-left: 25px;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    height: 12px;
    font-weight: 700;
    font-size: 11px;
  }
`

export const StatusText = styled.p`
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  margin-left: 10px;
`

export const LastUpdatedLabel = styled.div`
  margin: 5px 0;
  font-size: 13px;
  > span {
    font-weight: 600;
    margin-right: 15px;
  }
`

export const LastUpdatedValue = styled.div`
  font-size: 13px;
  > span {
    margin-right: 15px;
    display: inline-block;
    width: 165px;
  }
`

export const DownloadSuccessNote = styled.p`
  margin: 3px 0px;
  color: #666;
  font-style: italic;
`

export const TimestampContainer = styled.div`
  display: flex;
`

export const UpdatedTimeMessage = styled.div`
  padding: 10px 0px;
  margin-right: 30px;
`

export const MessageLabel = styled.p`
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  margin: 0px;
  letter-spacing: 0.26px;
  line-height: 15px;
`

export const MessageValueLabel = styled.p`
  font-size: 14px;
  margin: 2px 0px;
  font-wight: 500;
  line-height: 16px;
`
