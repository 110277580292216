import React from "react"
import Select from 'src/components/legacy/components/select/select'
import PropTypes from "prop-types"


const Comparator = props => {
	const { className, comparators = [], style, labelText, selected, onChange, testName } = props
	const styles = className !== "chart-setting" ? "marginLeft: \"10px\"" : ""
	return (
		<>
			{comparators.length >= 2 && (
				<div style={{ styles, ...style }} className={className}>
					<label>{labelText}</label>
					<Select isUserdefinedOptions={true} testName={testName}  options={comparators} selectedItem={selected ? selected.value : null} onChange={onChange} />
				</div>
			)}
		</>
	)
}

Comparator.propTypes = {
	comparators: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	labelText: PropTypes.string.isRequired,
	style: PropTypes.object,
	className: PropTypes.string
}

export default Comparator
