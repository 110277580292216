import { Text, View } from "@react-pdf/renderer"
import PropTypes from "prop-types"
import { titleStyles } from "./pdf-consultation-title.styles"

const PDFConsultationTitle = ({ title, dates }) => {
	const { sDates, sTitle, titleWrapper } = titleStyles

	return	(
		<View style={titleWrapper}>
			<Text style={sTitle}>{title}</Text>
			<Text style={sDates}>{dates}</Text>
		</View>
	)
}

PDFConsultationTitle.propTypes = {
	title: PropTypes.string.isRequired,
	dates: PropTypes.string.isRequired,
}

export default PDFConsultationTitle
