import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { TextEditorWrapper } from './styles'

const toolbarOptions = {
  options: ['blockType', 'fontSize', 'inline', 'link', 'textAlign', 'list'],
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24]
  }
}

const emptyState = {
  blocks: [
    {
      key: '637gr',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ],
  entityMap: {}
}

const TextEditor = ({
  content,
  changeContent,
  editorStyle,
  toolbarCustomButtons,
  placeholder,
  isViewMode = false,
  textEditorToolbarOptions = toolbarOptions,
  isStripPastedStyles = true
}) => {
  let state = emptyState;
  typeof content === 'string'
    ? (state.blocks[0].text = content)
    : (state = content)
  return (
    <TextEditorWrapper data-testid="text-editor-wrapper">
      <Editor
        initialContentState={state}
        toolbarClassName="toolbar"
        wrapperClassName="text-editor"
        editorClassName={!isViewMode && 'text-area'}
        onContentStateChange={changeContent}
        toolbar={textEditorToolbarOptions}
        readOnly={isViewMode}
        toolbarHidden={isViewMode}
        editorStyle={editorStyle}
        toolbarCustomButtons={toolbarCustomButtons}
        placeholder={placeholder}
        stripPastedStyles={isStripPastedStyles}
      />
    </TextEditorWrapper>
  )
}

export default TextEditor
