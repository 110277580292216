import { diff } from 'just-diff'

export default class Base {
  isConfigEqual(prev: any, next: any): boolean {
    return diff(prev, next).length === 0
  }

  configDiff(prev: any, next: any): any[] {
    return diff(prev, next)
  }
}
