export const requiredProperties = [
  {
    key: 'targetKey',
    contains: ['CircuitCount'],
    callback: (accumulator, currentValue) => {
      accumulator['circuitCount'] ?? (accumulator['circuitCount'] = 0)

      accumulator['circuitCount'] += +currentValue.value ?? 0

      return accumulator
    }
  },
  {
    key: 'targetKey',
    contains: ['CompressorCountCKT1', 'CompressorCountCKT2'],
    callback: (accumulator, currentValue) => {
      accumulator['compressorCount'] ?? (accumulator['compressorCount'] = {})

      accumulator['compressorCount'][currentValue.targetKey] =
        currentValue.value || 0

      return accumulator
    }
  }
]
