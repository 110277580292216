import styled, { css } from 'styled-components'
import Spinner from '../../legacy/components/spinner'
import Icon from '../Icon'

export const CustomIcon = styled(Icon)`
  position: relative;
  margin: 0px 6px 0px 0;
  top: 2px;
`

export const Container = styled.div`
  ${(props) => props.vertical && `display: flex;`}
`

export const TabList = styled.ul.attrs((props) => ({
  'data-testid': props?.testName
}))`
  box-sizing: border-box;
  position: relative;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  ${(props) => props.vertical && `flex-direction: column`};
  ${(props) => props.contextualTabs && `width: 90%`};
  ${(props) => props.customStyle && props.customStyle}
`

export const TabLink = styled.li`
  box-sizing: border-box;
  list-style: none;
  display: flex;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  color: ${(props) =>
    props.disabled
      ? '#999999'
      : props.active
      ? '#ffffff'
      : props.invalid
      ? '#991909'
      : props.vertical
      ? '#555555'
      : '#206fcf'};
  background-color: ${(props) =>
    props.loading || props.disabled
      ? '#e2e2e2'
      : props.active
      ? '#206fcf'
      : 'white'};
  align-items: center;
  justify-content: center;
  border: 1px solid #d4d4d4;
  border-right: 0;
  height: 37px;
  flex-grow: 1;
  ${(props) =>
    props.contextualTabs &&
    css`
      margin-right: 33px;
    `}
  ${(props) => props.customStyle && props.customStyle}
  ${(props) =>
    !props.vertical &&
    !props.contextualTabs &&
    css`
      &:first-of-type {
        border-radius: 6px 0px 0px 6px;
      }
      &:last-of-type {
        border-right: ${(props) =>
          !props.disabled &&
          !props.loading &&
          !props.active &&
          '1px solid #d4d4d4'};
        border-radius: 0px 6px 6px 0px;
      }
    `}    

${(props) =>
    props.active &&
    !props.contextualTabs &&
    css`
      border: 1px solid #206fcf;
    `}
${(props) =>
    (props.loading || props.disabled) &&
    !props.contextualTabs &&
    css`
      border: 1px solid #cccccc;
    `}

${(props) =>
    props.vertical &&
    css`
      width: 213px;
      height: 50px;
      border: 1px solid #ffffff;
      border-radius: 6px;
      justify-content: start;
      padding: 0 20px;
      font-size: 16px;
      /* font-family: NeueHaasUnicaW1G-Regular; */
      font-weight: normal;
      letter-spacing: 0.3px;
    `}

${(props) =>
    props.contextualTabs &&
    css`
      box-sizing: border-box;
      position: relative;
      top: 1px;
      height: 36px;
      border-radius: 6px 12px 0px 0px;
      justify-content: start;
      padding: 0 0 0 12px;
      border-left: ${(props) =>
        !props.disabled && !props.loading && props.active
          ? '1px solid #206fcf'
          : '1px solid #d4d4d4'};
      border-top: ${(props) =>
        !props.disabled && !props.loading && props.active
          ? '1px solid #206fcf'
          : '1px solid #d4d4d4'};
      border: ${(props) => (props.disabled || props.loading) && 'none'};
      border-bottom: none;
      &:first-of-type {
        margin-left: 10px;
      }
      ::before {
        content: ' ';
        display: inline-block;
        box-sizing: border-box;
        position: absolute;
        width: 40px;
        height: 36px;
        top: ${(props) => (props.disabled || props.loading ? '0px' : '-1px')};
        right: 0;
        background: ${(props) =>
          props.loading || props.disabled
            ? '#e2e2e2'
            : props.active
            ? '#206fcf'
            : 'white'};
        border-radius: 0px 6px 0px 0px;
        transform-origin: top right;
        -ms-transform: skew(43deg, 0deg);
        -webkit-transform: skew(43deg, 0deg);
        transform: skew(43deg, 0deg);
        border: ${(props) =>
          !props.disabled && !props.loading && props.active
            ? '1px solid #206fcf'
            : '1px solid #d4d4d4'};
        border-left: none;
        border-bottom: none;
        border: ${(props) => (props.disabled || props.loading) && 'none'};
      }
    `}
`
export const SpinnerContainer = styled(Spinner).attrs({
  spinnerColor: '#999999',
  type: 'section'
})`
  position: absolute;
  font-size: 20px;
  background-color: transparent;
  border-radius: 6px 12px 0 0;
  border: none;
  margin: 0;
  padding: 0;
  opacity: 0.8;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const ActiveTabContent = styled.div`
  background-color: #ffffff;
  border-radius: 0 0 6px 6px;
  padding: 16px;
  margin: 0px 5px 0px 5px;
  ${(props) => props.customStyle && props.customStyle}
`

export const ActiveTabContentContext = styled.div`
  box-sizing: border-box;
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid #cccccc;
  border-radius: 6px;
  ${(props) => props.customStyle && props.customStyle}
`
