export const GET_BUILDING_DETAILS = /* GraphQL */ `
  query getBuildingDetails($id: ID!) {
    getBuilding(id: $id) {
      buildingId
      name
      accountName
      tz
    }
  }
`

export const GET_AUTOMATED_TEST_SETTINGS_BY_BUILDING_ID = /* GraphQL */ `
  query listSettingsByBuilding($id: ID!) {
    listSettingsByBuilding(buildingId: $id, limit: 1000) {
      items {
        analyticId
        buildingId
        category
        equipmentId
        familyId
        floatValue
        id
        intValue
        isActive
        type
        subType
        stringValue
        name
        listValues
        accountId
      }
    }
  }
`

export const GET_AUTOMATED_TEST_SETTINGS_BY_EQUIPMENT_ID = /* GraphQL */ `
  query listSettingsByEquipmentType($equipmentId: ID!) {
    listSettingsByEquipmentType(equipmentId: $equipmentId, limit: 1000) {
      items {
        analyticId
        buildingId
        category
        equipmentId
        familyId
        floatValue
        id
        intValue
        isActive
        type
        subType
        stringValue
        name
        listValues
        accountId
      }
    }
  }
`

// getting equipment list
export const ListBuildingEquipments = `query GET_EQUIPMENT_LIST($id: ID!, $nextToken: String) {
  searchEquipment(
    filter: {
      buildingId: {eq: $id},
      isActive: {eq: 1},
      type: {ne: "None"},
      isTIS: {eq: 1} 
    },
    nextToken: $nextToken
    limit: 1000
    ){
    items {
      key: id
      value: name
      label: name
      family: type
    }
    nextToken
    total
  }
}

`

export const ListBuiildingsByAccount = `query listBuildingsByAccount($id: ID!) {
  listBuildingsByAccount(accountId: $id,limit:200) {
      items {
      value: name,
      label: name,
      key: id
      }
    }
  
}
`

export const getTestSettingsSummary = /* GraphQL */ `
  query getATSettings($body: AWSJSON!) {
    getATSettings(body: $body)
  }
`

export const createATSettings = `
mutation createSetting($input: CreateSettingInput!) {
  createSetting(input: $input) {
    id
  }
}`

export const updateATSettings = `
mutation updateSetting($input: UpdateSettingInput!) {
  updateSetting(input: $input) {
    id
  }
}`

export const getSuggestionByTestName = `
query listExceptionSuggestions($filter: ModelExceptionSuggestionFilterInput) {
  listExceptionSuggestions(filter: $filter, limit: 1000) {
    items {
      suggestion
      testName
      id
      equipmentType
    }
  }
}`
