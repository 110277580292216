import { Link } from 'react-router-dom'
import { Block } from 'src/components/layouts'

export const PageNotFound = () => (
  <Block padding={'0 0 0 12px'}>
    <h3 style={{ lineHeight: 'normal' }}>
      The page you are looking for is not available. The location of the
      item may have changed. If you have used a bookmark or shared hyperlink for
      navigation, select the page from the left sidebar and bookmark the page
      location or <Link to="/">click here </Link> to go to Home page.
    </h3>
  </Block>
)
