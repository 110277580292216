import { ChangeEvent } from 'react'
import { PanelLabel } from '../../styles'
import { convertStringToValidNumber } from 'src/components/legacy/common/helpers.js'
import translate, { TranslateComponent } from 'src/common/translations'

const CustomTextbox = ({
  errorItem,
  fieldName,
  labelName,
  setFieldValue,
  selectedItem,
  isRequired=false
}) => {
  const handleSetFieldValueChange = (key, value) => {
    setFieldValue(key, value)
  }
  return (
    <>
      <PanelLabel className={errorItem ? 'error' : ''}><TranslateComponent>{labelName}</TranslateComponent>{Boolean(isRequired)? "*": ""}</PanelLabel>
      <input
        value={convertStringToValidNumber(selectedItem)}
        maxLength={80}
        className={errorItem ? 'error' : ''}
        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
          const re = /^[0-9\b]+$/
          if (value === '' || re.test(value)) {
            handleSetFieldValueChange(fieldName, value)
          }
        }}
      />
      <p className="error">{errorItem && translate(errorItem)}</p>
    </>
  )
}
export default CustomTextbox
