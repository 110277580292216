import colors from 'src/components/layouts/colors.json'
import XYChart from 'src/chart-library/Charts/XYChart'
import { SCALE_LINEAR } from 'src/chart-library/Utils/Scales/constant'
import { SOLID_LINE } from 'src/chart-library/CommonComponents/LinePath/constants'
import moment from 'moment/moment'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { format } from 'date-fns'
import { findMaxValueObject } from './helpers'
import TimelineWithChart from './Timeline'
import './styles.scss'
import { useMemo } from 'react'

const propertyAxis = {
  InstantaneousPower: {
    axis: 'y1',
    color: colors.BLUE,
    UoM: 'kW'
  },
  RealPower: {
    axis: 'y1',
    color: colors.BLUE,
    UoM: 'kW'
  },
  TemperatureF: {
    axis: 'y2',
    color: colors.YELLOW,
    UoM: 'F'
  }
}
const ElectricalDemandLineChart = ({
  chartData,
  timeSliderChartData,
  loadingChartData,
  chartDates,
  markers,
  updatePeakDemand,
  setTimeSliderStartTime,
  setTimeSliderEndTime,
  selectedEquipmentId
}) => {
  const getPeakDemand = (chartData) => {
    let maxPeakObject = {
      timeStamp: moment(new Date()).format('YYYY-MM-DD HH:mm'),
      value: null
    }
    if (chartData?.data?.length) {
      const filterData = chartData?.data?.filter((property) => {
        if (
          ['InstantaneousPower', 'RealPower'].includes(property?.propertyKey)
        ) {
          return property
        }
      })
      if (filterData.length) {
        maxPeakObject = findMaxValueObject(filterData[0]?.values[0])
      }
    }
    return maxPeakObject
  }

  const getPeakDemandValue = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData

    const maxPeakDemand = lineChartData && getPeakDemand(lineChartData)
    const peakDemandValue = maxPeakDemand?.value ? +maxPeakDemand?.value : 0
    if (!isTimeSliderChartConfig) updatePeakDemand(maxPeakDemand)
    return peakDemandValue
  }

  const startDate = chartDates?.chartStartDate
    ? Date.parse(chartDates?.chartStartDate)
    : null
  const endDate = chartDates?.chartEndDate
    ? Date.parse(chartDates?.chartEndDate)
    : null
  const plotLines = markers?.map((marker, index) => {
    return {
      id: `pl-${index}`,
      axisKey: 'x1',
      stroke: 'lightgray',
      strokeWidth: 3,
      hideTooltip: true,
      value: Date.parse(marker.markerDate),
      dashType: 'solid',
      type: 'plotLine',
      show: true,
      visible: true,
      name: marker?.name,
      canvasIndex: '0',
      text: {
        text: marker?.name,
        dominantBaseline: 'middle',
        fontSize: 11,
        fontColor: 'gray',
        background: {
          fill: '#F5F5F5',
          fillOpacity: '0.3',
          stroke: 'gray',
          strokeWidth: '1'
        }
      }
    }
  })
  const createXAxis = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    return [
      {
        key: 'x1',
        scale: {
          props: {
            type: 'scaleTime'
            // defaultRange: [startDate, endDate]
          },
          categories: lineChartData?.timeStamps || [],
          scaleSettings: {}
        },
        axis: {
          visibleAt: ['bottomBottom'],
          hideTicks: true,
          hideLine: false,
          name: {
            symbol: 'Date'
            // text: '',
            // alignment: 'middle' // start middle end --> default to Middle
          }
        }
      }
    ]
  }

  const createYAxis = (isTimeSliderChartConfig) => {
    return [
      {
        key: 'y1',
        scale: {
          props: {
            rangeFixed: true,
            type: SCALE_LINEAR
          }
        },
        axis: {
          name: {
            symbol: 'kW',
            text: 'Daily Demand',
            alignment: 'start'
          },
          hideLine: true,
          hideTicks: true,
          notD3: true,
          // format: (value) => parseFloat(value)?.toFixed(0),
          visibleAt: ['leftLeft'] // Optional,
        }
      },
      {
        key: 'y2',
        scale: {
          props: {
            rangeFixed: true,
            type: SCALE_LINEAR
          }
        },
        axis: {
          name: {
            symbol: 'F'
            // text: 'Temperature',
            // alignment: 'start'
          },
          notD3: true,
          hideLine: true,
          hideTicks: true,
          visibleAt: ['rightRight']
        },
        grid: [
          {
            stroke: 'lightgray',
            includeAxis: true
          }
        ]
      }
    ]
  }

  const getLegends = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    const legends = []
    for (let i = 0; i < lineChartData?.data?.length; i++) {
      legends.push({
        id: i + 1,
        name: lineChartData?.data[i].propertyName,
        seriesKey: i + 1,
        elementProps: {
          stroke: propertyAxis[lineChartData?.data[i].propertyKey]?.color,
          strokeWidth: 2
        },
        xAxisKey: 'x1',
        yAxisKey: propertyAxis[lineChartData?.data[i].propertyKey]?.axis,
        canvasIndex: '0',
        dashType: 'solid',
        properties: {},
        settings: {
          curve: {
            type: 'curveCardinal',
            settings: {
              tension: 0.5
            }
          }
        },
        stroke: 'lightgray',
        shape: 'Line',
        show: true,
        visible: true,
        type: 'line',
        tooltipDataModelFormatter: ({ value, xValue }) => ({
          value: `${Number(value).toFixed(2)} ${
            propertyAxis[lineChartData?.data[i].propertyKey].UoM
          }`,
          title: format(new Date(xValue), 'EEEE, MMMM, do yyyy, h:mm a')
        })
      })
    }
    legends.push({
      id: 'PeakDemand',
      axisKey: 'y1',
      stroke: 'orange',
      strokeWidth: 3,
      value: getPeakDemandValue(isTimeSliderChartConfig),
      dashType: SOLID_LINE,
      canvasIndex: '0',
      tooltipDataModelFormatter: ({ value, ...props }) => ({
        ...props,
        value: value + ' kW'
      }),
      text: {
        hide: true, // hide label
        fontColor: 'orange'
      },
      name: 'Peak Demand',
      show: true,
      visible: true,
      considerPlotLine: true,
      considerPLLegend: true,
      type: 'plotLine'
    })
    legends.push(...(plotLines || []))
    return legends
  }
  const getData = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    const data = {}
    lineChartData &&
      lineChartData.data &&
      lineChartData?.data?.map((element, index) => {
        if (
          element?.propertyKey?.toLowerCase()?.includes('temp') &&
          isTimeSliderChartConfig
        )
          return
        data[index + 1] = {
          data: element.values[0].map((x) =>
            x?.value && Number(x?.value) ? Number(x?.value) : null
          )
        }
      })
    return data
  }
  const chartConfigData = (isTimeSliderChartConfig = false) => {
    return {
      canvas: {
        canvasDetails: {
          0: {
            renderAreaShade: {
              fill: colors.WHITE,
              opacity: '1'
            },
            tooltip: {
              type: 'x', // x, xy - xy not yet supported
              referenceLineV: true
            }
          }
        }
      },
      x: createXAxis(isTimeSliderChartConfig),
      y: createYAxis(isTimeSliderChartConfig),
      series: {
        types: getLegends(isTimeSliderChartConfig),
        data: getData(isTimeSliderChartConfig)
      },
      width: '100%',
      height: 525,
      // Required to set below config when displaying timeline
      // svgPadding: { bottom: 120 },
      // container: {
      //   className: 'electrical-demand-line-chart-wrapper'
      // },
      dataLastUpdatedAt: moment().unix(),
      legends: {
        className: 'electrical-demand-chart-legend'
      }
    }
  }

  // Integration team has to pass start & end date and a listener to revice the current selected date time from timeline
  // Based on recevied date range from timeline, need to filter the data and pass to main chart.
  // timeline chart data should not be filtered based on timeline date range.
  const timelineProps = useMemo(() => {
    return {
      trackStartTime: '01-01-2023',
      trackEndTime: '06-30-2023',
      getBrushPosition: (d) => {}
    }
  }, [])

  // Integration team has to pass required data that needs to be rendered in timeline chart
  // Display only lines, area charts, hide other elements like axis, tooltip, legend, markers etc...
  const timelineChartConfigData = useMemo(
    () => ({
      ...{ ...chartConfigData(true) },
      height: 55,
      legends: null,
      svgPadding: { top: 0, right: 0, bottom: 0, left: 0 },
      container: {},
      canvas: {
        canvasDetails: {
          0: {
            renderAreaShade: {
              fill: 'transparent',
              opacity: '0'
            },
            tooltip: {
              type: 'x', // x, xy - xy not yet supported
              referenceLineV: true,
              hide: true
            }
          }
        }
      }
    }),
    [timeSliderChartData]
  )

  return loadingChartData || !chartData || selectedEquipmentId === null ? (
    <Spinner />
  ) : chartConfigData()?.series.types?.length > 0 ? (
    <div data-testid="electrical-demand-heat-chart">
      <XYChart {...chartConfigData()}>
        <TimelineWithChart
          // hideChart
          startTime={chartDates?.chartStartDate}
          endTime={chartDates?.chartEndDate}
          getSelctedRange={(d) => {
            setTimeSliderStartTime(d?.startTime)
            setTimeSliderEndTime(d?.endTime)
          }}
          xAxis={timelineChartConfigData?.x ?? []}
          yAxis={timelineChartConfigData?.y ?? []}
          series={timelineChartConfigData?.series ?? {}}
          className={'electrical-demand-time-slider'}
        />
      </XYChart>
    </div>
  ) : (
    <p>No Data</p>
  )
}
export default ElectricalDemandLineChart
