import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
  color: #000;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const StyledSearch = styled.span`
  background: #ccc; // should this be themed ?
  border: 1px solid #7d7d7d;
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  height: ${(props: any) => props.width || 'auto'};
  line-height: 16px;
  margin-left: 0;
  padding: 3px 4px;
  position: relative;
  width: ${(props: any) => props.width || 'auto'};
  & input {
    box-sizing: border-box;
    width: 100%;
    background: #f9fafc;
    border: 1px solid #7d7d7d;
    border-radius: 9px;
    font-family: Archivo Narrow, sans-serif;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    line-height: 14px;
    padding: 1px 6px;
    vertical-align: top;
    &:focus {
      background: #fff;
      border-color: #6b77c7;
    }
  }
`
