import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import Container from 'src/components/Container'
import { Block } from 'src/components/layouts'
import Content from 'src/components/Content'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _slice from 'lodash/slice'
import Label from 'src/components/legacy/components/label/label'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import Checkbox from 'src/denali-ui/components/Checkbox'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import AttachFiles from '../../add/AttachFiles'
import { ACTIONS } from 'src/constants'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import {valueWithinMinMax} from "../../editActive/helpers";
import {
  hasCMSA
} from "src/pages/contracts-and-offerings/common/contracts-and-offerings"


export function BPEPApproveDeclineDialog({
  values = {},
  errors = {},
  setFieldValue,
  setValues
}) {
  const [offeringDDLData, setOfferingDDLData] = useState([])
  useEffect(() => {
    setOfferingDDLData((state) => [
      ...state,
      ...values?.buildings.reduce((acc, b) => {
        const data = {
          key: b.id,
          label: b.name
        }
        if (!state.some((building) => building.key === b.id)) {
          acc.push(data)
        }
        return acc
      }, [])
    ])
  }, [])
  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <>
          <Label text={<span>Offering activation request for:</span>} />
          <div>
            {values?.buildingDetails &&
              Object.values(values.buildingDetails)?.length > 0 &&
              Object.values(values.buildingDetails).map((buildingDetail) => (
                <div key={buildingDetail.id}>
                  {' '}
                  {`${buildingDetail.name} at ${buildingDetail.address}, CRM Site ID:${buildingDetail.crmCustId} `}
                </div>
              ))}
          </div>
        </>
      </Content>
      <Content xlColumn={6} lColumn={6} mColumn={12} sColumn={12} border="none">
        <>
          <Label
            text={
              <span className={errors.numberOfDevices && 'error'}>
                Device Count*
              </span>
            }
          />
          <div>
            <input
              type="number"
              value={values?.numberOfDevices}
              onChange={(e) => {
                const value = valueWithinMinMax(e.target.value)
                setFieldValue('numberOfDevices', value)
              }}
            />
          </div>
          {errors.numberOfDevices && (
            <p className="error">
              <>{errors.numberOfDevices}</>
            </p>
          )}
        </>
      </Content>
      <Content xlColumn={6} lColumn={6} mColumn={12} sColumn={12} border="none">
        <>
          <Label
            text={
              <span className={errors.buildingCount && 'error'}>
                Building Invoice Count*
              </span>
            }
          />
          <div>
            <input
              type="number"
              value={values?.buildingCount}
              onChange={(e) => {
                const value = valueWithinMinMax(e.target.value)
                setFieldValue('buildingCount', value)
              }}
            />
          </div>
          {errors.buildingCount && (
            <p className="error">
              <>{errors.buildingCount}</>
            </p>
          )}
        </>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <>
          <Label text={<span>Offerings:</span>} />
          <div>{values.name}</div>{' '}
        </>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        {' '}
        <>
          <Label text={<span>Business Stream</span>} />
          <div>{values.stream}</div>
        </>
      </Content>

      <Content xlColumn={6} lColumn={6} mColumn={12} sColumn={12} border="none">
        <>
          <Label text={<span>Contract Start Date*</span>} />
          <div>
            <ModalDatePickerInput
              date={
                values.startDate &&
                moment(values.startDate).format('MM/DD/YYYY')
              }
              onChange={(e) => {
                setValues((values) => ({
                  ...values,
                  startDate: moment(e).format('MM/DD/YYYY'),
                  ...(!values.activateForLaterDate
                    ? { analyticsStartDate: moment(e).format('MM/DD/YYYY') }
                    : {})
                }))
              }}
              hasError={Boolean(errors?.startDate)}
              errorMessage={errors?.startDate}
            />
          </div>
        </>
      </Content>
      <Content xlColumn={6} lColumn={6} mColumn={12} sColumn={12} border="none">
        <>
          <Label text={<span>Expiration Date*</span>} />
          <div>
            <ModalDatePickerInput
              date={
                values.endDate && moment(values.endDate).format('MM/DD/YYYY')
              }
              onChange={(e) => {
                setFieldValue('endDate', moment(e).format('MM/DD/YYYY'))
              }}
              // isRequired={false}
              // labelText={`${t('opportunities:CreatedDate')}*`}
              hasError={Boolean(errors?.endDate)}
              errorMessage={errors?.endDate}
            />
          </div>
        </>
      </Content>
      <Content xlColumn={6} lColumn={6} mColumn={12} sColumn={12} border="none">
        <>
          <Checkbox
            checked={values.activateForLaterDate}
            onClick={() => {
              setValues((values) => ({
                ...values,
                ...(!values.activateForLaterDate
                  ? { analyticsStartDate: values.startDate }
                  : {}),
                activateForLaterDate: !values.activateForLaterDate
              }))
              setFieldValue(
                'activateForLaterDate',
                !values.activateForLaterDate
              )
            }}
            label={
              <span className={errors.agreement && 'error'}>
                Activate analytics later than contract start date?
              </span>
            }
          />
        </>
      </Content>
      <Content xlColumn={6} lColumn={6} mColumn={12} sColumn={12} border="none">
        <>
          <Label text={<span>Analytics Start Date</span>} />
          <div>
            <ModalDatePickerInput
              disabled={!values.activateForLaterDate}
              date={
                !values.activateForLaterDate
                  ? values?.startDate &&
                    moment(values.startDate).format('MM/DD/YYYY')
                  : values?.analyticsStartDate &&
                    moment(values.analyticsStartDate).format('MM/DD/YYYY')
              }
              onChange={(e) => {
                setFieldValue(
                  'analyticsStartDate',
                  moment(e).format('MM/DD/YYYY')
                )
              }}
              // isRequired={false}
              // labelText={`${t('opportunities:CreatedDate')}*`}
              hasError={Boolean(errors?.analyticsStartDate)}
              errorMessage={errors?.analyticsStartDate}
            />
          </div>
        </>
      </Content>
      <Content xlColumn={6} lColumn={6} mColumn={12} sColumn={12} border="none">
        <>
          <Label text={<span>Contract Primary Building*</span>} />
          <div>
            <SelectrixWrapper
						className={`location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select`}
						placeholder={"-Select One-"}
						placeHolderInside={true}
            defaultValue={values.primaryBuilding}
            inputPlaceholder="Find a building..."
						multiple={false}
						onChange={e => {
              setFieldValue("primaryBuilding", e.key)
            }
            }
						options={offeringDDLData.filter(({ key }) => values.buildingIds.includes(key))}
						searchable={true}
						searchBoxInside={true}
						searchIndex={false}
						stayOpen={false}
					/>
          </div>
          {errors.primaryBuilding && <p className="error"><>{errors.primaryBuilding}</></p>}
        </>
      </Content>
      <Content xlColumn={6} lColumn={6} mColumn={12} sColumn={12} border="none">
        {/Independent/i.test(values.stream) ? (
          <>
            <Label
              text={
                <span className={errors.serviceContractNumber && 'error'}>
                  Purchase Order Number*
                </span>
              }
            />
            <div>
              <input
              maxLength={50}
                value={values?.serviceNumber}
                onChange={(e) => {
                  setFieldValue("serviceNumber", e.target.value)
                }}
              />
            </div>
            {errors.serviceContractNumber && (
              <p className="error">
                <>{errors.serviceContractNumber}</>
              </p>
            )}
          </>
        ) : (
          !(values.offeringCodes?.length === 1 && hasCMSA(values.offeringCodes)) && 
          (<>
            <Label
              text={
                <span className={errors.serviceContractNumber && 'error'}>
                  Service Contract Number*
                </span>
              }
            />
            <div>
              <input
              maxLength={50}
                value={values?.serviceNumber}
                onChange={(e) => {
                  setFieldValue("serviceNumber", e.target.value)
                }}
              />
            </div>
            {errors.serviceContractNumber && (
              <p className="error">
                <>{errors.serviceContractNumber}</>
              </p>
            )}
          </>)
        )}
      </Content>
      { values.offeringCodes?.includes("CAPxM&V") &&
      (<Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
       <><Label text={
              <span className={errors.pactContractNumber && "error"}>
                PACT Contract Number *
              </span>
            } />
              <div>
                <input maxLength={50} value={values.pactContractNumber || ""} onChange={e => {
                  setFieldValue("pactContractNumber", e.target.value)
                }} />
              </div>
              {errors.pactContractNumber && <p className="error"><>{errors.pactContractNumber}</></p>}
            </>
      </Content>)}
      {(values.offeringCodes?.length === 1 && hasCMSA(values.offeringCodes)) &&
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <><Label text={
              <span className={errors.mechanicalServiceAgreementNumber && "error"}>
                Mechanical Service Agreement Number *
              </span>
            } />
              <div>
                <input value={values.mechanicalServiceAgreementNumber || ""} onChange={e => {
                 setFieldValue("mechanicalServiceAgreementNumber", e.target.value)
                }} />
              </div>
              {errors.mechanicalServiceAgreementNumber && <p className="error"><>{errors.mechanicalServiceAgreementNumber}</></p>}
            </> </Content>}
        {values.offeringCodes.includes("BR") &&
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <><Label text={
              <span className={errors.totalBaselineReportCount && "error"}>
                Total Baseline Report Count *
              </span>
            } />
              <div>
                <input value={values.totalBaselineReportCount || ""} onChange={e => {
                  const value = valueWithinMinMax(e.target.value)
                  setFieldValue("totalBaselineReportCount", value)
                }} />
              </div>
              {errors.totalBaselineReportCount && <p className="error"><>{errors.totalBaselineReportCount}</></p>}
            </></Content>}


        {values.offeringCodes.includes("CS") &&
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <><Label text={
              <span className={errors.coachingServicesValue && "error"}>
                Coaching Service Value *
              </span>
            } />
              <div>
                <input maxLength={10} value={values.coachingServicesValue || ""} onChange={e => {
                  setFieldValue("coachingServicesValue", e.target.value)
                }} />
              </div>
              {errors.coachingServicesValue && <p className="error"><>{errors.coachingServicesValue}</></p>}
            </></Content>
        }
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <>
          <Label text={<span>Download Attachments:</span>} />
          <AttachFiles 
          mode = {ACTIONS.VIEW}
          values={values}
          organizationId ={values.accountId}
          />
        </>
      </Content>
     
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <>
          <Label text={<span>Supporting Notes:</span>} />
          <div>{`${values.notes} `}</div>
        </>
      </Content>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <>
          <Label text={<span>Account Manager:</span>} />
          <div>{values.accountMgr}</div>
        </>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <>
          <Label text={<span>Billing Sales Office Code</span>} />
          <div>
            <input
              value={values?.billingSalesOfficeCode}
              onChange={(e) => {
                setFieldValue("billingSalesOfficeCode", e.target.value)
              }}
            />
          </div>
        </>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <>
          <Label text={<span>Additional Notification Recipients:</span>} />
          <div>{values.receipients}</div>
        </>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        {
          <TextAreaInput
            labelText={'Why Request was Declined'}
            defaultValue={values?.declinedReason}
            name="Why Request was Declined"
            onChange={({ target: { value } }) => {
              setFieldValue("declinedReason", value)
            }}
            isRequired={false}
          />
        }
      </Content>
    </Container>
  )
}

BPEPApproveDeclineDialog.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  // touched: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // setFieldTouched: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  // locationInfo: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired
}
