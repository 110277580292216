const grid = (input) => {
  return gridList[input]
}

export default grid

//Screen Variables
const screenValues = {
  'screen-tiny-max-width': '353px',
  'screen-extra-small-min-width': '354px',
  'screen-max-extra-small-max-width': '633px',
  'screen-small-min-width': '634px',
  'screen-max-small-max-width': '913px',
  'screen-medium-min-width': '914px',
  'screen-max-medium-max-width': '1193px',
  'screen-large-min-width': '1194px',
  'screen-max-large-max-width': '1473px',
  'screen-extra-large-min-width': '1474px'
}

const gridList = {
  'screen-tiny': `@media (max-width: ${screenValues['screen-tiny-max-width']})`,
  'screen-extra-small': `@media (min-width: ${screenValues['screen-extra-small-min-width']})`,
  'screen-max-extra-small': `@media (max-width: ${screenValues['screen-max-extra-small-max-width']})`,
  'screen-small': `@media (min-width: ${screenValues['screen-small-min-width']})`,
  'screen-max-small': `@media (max-width: ${screenValues['screen-max-small-max-width']})`,
  'screen-medium': `@media (min-width: ${screenValues['screen-medium-min-width']})`,
  'screen-max-medium': `@media (max-width: ${screenValues['screen-max-medium-max-width']})`,
  'screen-large': `@media (min-width: ${screenValues['screen-large-min-width']})`,
  'screen-max-large': `@media (max-width: ${screenValues['screen-max-large-max-width']})`,
  'screen-extra-large': `@media (min-width: ${screenValues['screen-extra-large-min-width']})`,
  'screen-extra-small-to-max-small': `@media (min-width: ${screenValues['screen-extra-small-min-width']}) and (max-width: ${screenValues['screen-max-extra-small-max-width']})`,
  'screen-small-to-max-small': `@media (min-width: ${screenValues['screen-small-min-width']}) and (max-width: ${screenValues['screen-max-small-max-width']})`,
  'screen-medium-to-max-medium': `@media (min-width: ${screenValues['screen-medium-min-width']}) and (max-width: ${screenValues['screen-max-medium-max-width']})`,
  'screen-large-to-max-large': `@media (min-width: ${screenValues['screen-large-min-width']}) and (max-width: ${screenValues['screen-max-large-max-width']})`
}
