import { StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

export const tableStyles = StyleSheet.create({
	table: {},
	row: {
		paddingTop: 6,
		paddingLeft: 5,
		paddingBottom: 8
	},
	rowData: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		fontSize: 8,
		color: colors.TABLE_CONTENT_COLOR,
	},
	noteSection: {
		paddingTop: 2,
		paddingBottom: 4,
		paddingLeft: 4,
		fontSize: 8,
		fontStyle: "italic",
		color: colors.TBA_DARKER_GREY,
	},
	rowEven: {
		backgroundColor: colors.TBA_LIGHTEST_GREY,
	},
	headingRow: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		paddingTop: 6,
		paddingLeft: 5,
		paddingBottom: 6,
		backgroundColor: colors.TBA_LIGHTEST_GREY,
		fontSize: 8,
		color: colors.TABLE_CONTENT_COLOR
	},
	column: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		paddingLeft: 4,
	},
	headingColumn: {
		paddingLeft: 4,
	},
	header: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingTop: 6,
		paddingBottom: 6,
		borderBottom: `1 solid ${colors.GREY_6}`,
	},
	filteringWrapper: {
		display: "flex",
		flexDirection: "row",
	},
	filteringBlock: {
		display: "flex",
		flexDirection: "row",
		marginRight: 20,
	},
	filteringTitle: {
		fontSize: 9,
		color: colors.TBA_DARKER_GREY,
		marginRight: 2,
	},
	filteringValue: {
		fontSize: 9,
		color: colors.GREY_4,
		fontWeight: "bold",
	},
	count: {
		fontSize: 9,
		color: colors.GREY_4,
		fontWeight: "bold",
	}
})
