export function svgToPng(svg) {
	if (!svg) {
		return
	}
	const { width } = svg.getBoundingClientRect()
	svg.setAttribute("width", width)
	const svgString = new XMLSerializer().serializeToString(svg)

	const img = document.createElement("img")
	img.setAttribute("src", `data:image/svg+xml;base64,${btoa(svgString)}`)
	img.setAttribute("width", width)

	return new Promise(resolve => {
		img.onload = () => {
			const canvas = document.createElement("canvas")
			canvas.width = img.naturalWidth
			canvas.height = img.naturalHeight
			canvas?.getContext("2d")?.drawImage(img, 0, 0)

			resolve(canvas.toDataURL("image/png"))
		}
	}).finally(() => svg.setAttribute("width", "100%"))
}


export const PdfDownloadDetailViewPages = {
	OPPORTUNITY: 'Opportunity',
	FINDING: 'Finding'
}
