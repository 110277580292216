import React, { useState, useEffect,useRef } from "react"
import { useTranslation } from "react-i18next"
import _get from "lodash/get"
import Modal from 'src/components/legacy/components/modal/modal'
import "./index.scss"
import {SiteDataCollectionNotificationsSettingsDialogItem} from "./site-data-collection-notification-settings-item"
import {SiteDataCollectionNotificationsSettingsDialogItemClosed} from "./site-data-collection-notification-settings-item-closed"
import { Button } from 'src/components/inputs/button'
import {
	DELETE_SUBSCRIBER,
} from './mutations'
import { useMutation } from '../../../hooks/APIHooks'


export const getQueryResponseMap = (response) =>  {
	const responseReduceObj = response.reduce((accum, curr) => {
		if (!accum[curr.accountId]) {
			accum[curr.accountId] = {
				disabled: false,
				name: curr.accountName,
				children: curr.accountName,
				value: curr.accountId,
				id: curr.accountId,
				type: "organization",
				subData: [{
					name: curr.name,
					children: curr.name,
					accountName: curr.accountName,
					accountId: curr.accountId,
					salesOfficeId: curr.salesOfficeId,
					salesOfficeName: curr.salesOfficeName,
					id: curr.id,
					value: curr.id,
					disabled: false,
					type: "building",
				}]
			}
		 } else {
			accum[curr.accountId].subData.push({
				name: curr.name,
				children: curr.name,
				id: curr.id,
				value: curr.id,
				accountName: curr.accountName,
				accountId: curr.accountId,
				salesOfficeId: curr.salesOfficeId,
				salesOfficeName: curr.salesOfficeName,
				disabled: false,
				type: "building",
			})
		}
		if (!accum[curr.salesOfficeId]) {
			accum[curr.salesOfficeId] = {
				name: curr.salesOfficeName,
				children: curr.salesOfficeName,
				value: curr.salesOfficeId,
				id: curr.salesOfficeId,
				accountId: curr.accountId,
				salesOfficeId: curr.salesOfficeId,
				type: "salesOffice",
				subData: [{
					name: curr.name,
					children: curr.name,
					id: curr.id,
					accountName: curr.accountName,
					accountId: curr.accountId,
					salesOfficeId: curr.salesOfficeId,
					salesOfficeName: curr.salesOfficeName,
					value: curr.id,
					disabled: false,
					type: "building",
				}]
			}
		 } else {
			accum[curr.salesOfficeId].subData.push({
				name: curr.name,
				children: curr.name,
				id: curr.id,
				accountId: curr.accountId,
				accountName: curr.accountName,
				accountId: curr.accountId,
				salesOfficeId: curr.salesOfficeId,
				salesOfficeName: curr.salesOfficeName,
				salesOfficeId: curr.salesOfficeId,
				value: curr.id,
				disabled: false,
				type: "building",
			})
		}
		return accum
	}, [])

	const dataToUse =  Object.keys(responseReduceObj).map(e => {
		return ({
			...responseReduceObj[e]
		})
	})
	return dataToUse
}

export const SiteDataCollectionNotificationsSettingsDialog = ({
	onClose,
	onSave,
	values
}) => {
	const [t] = useTranslation()
	const listRef = useRef()
	const [open,setOpen] = useState(false)
	const [dataCollectionNotifications, setDataCollectionNotifications] = useState([])
	const [deleteSetting, setDeleteSetting] = useState(null)
	const { onSubmit: deleteNotificationSetting, loading } = useMutation({
		query: DELETE_SUBSCRIBER,
		dataPath: 'data',
	  })

	useEffect(() => {
		if (values?.length) {
			let cleanedNotifications = values.reduce((accum, curr) => {
			if (accum.findIndex(e => e.name === curr.name) === -1) {
				accum.push({
					...curr, 
					ids: [curr.id],
					buildingIds: curr.buildingId ? [curr.buildingId] : [],
					accountIds: curr.accountId ? [curr.accountId] : [],
					salesOfficeIds: curr.salesOfficeId ? [curr.salesOfficeId] : [],
					notifications: [curr]
				})
			} else {
				const index = accum.findIndex(e => e.name === curr.name)
				accum[index].ids.push(curr.id)
				accum[index].notifications.push(curr)
				if (curr.buildingId) {
					accum[index].buildingIds.push(curr.buildingId)
				}
				if (curr.accountId) {
					accum[index].accountIds.push(curr.accountId)
				}
				if (curr.salesOfficeId) {
					accum[index].salesOfficeIds.push(curr.salesOfficeId)
				}
			}
			return accum
			}, []);
			cleanedNotifications = cleanedNotifications.sort((a, b) => a?.name.localeCompare(b?.name))
			setDataCollectionNotifications(cleanedNotifications)
		} else {
			setDataCollectionNotifications([])
		}
	}, [values])

	useEffect(() => {
 if(open){
	listRef.current.scrollTop = listRef.current.scrollHeight
 }else {
	listRef.current.scrollTop = 0
 }
	},[open])

	const getModalConfigDelete = () => ({
		gray: true,
		heading: "Remove Data Loss Notification Parameter",
		buttons: [
			{
				text: t('common:Confirm'),
				handleClick: async () => {
					await Promise.all(deleteSetting.ids.map(e => deleteNotificationSetting({input: {id: e}}))).then(async () => {
						await onSave().then(() => {
							setDeleteSetting(null)
						})
					})
				},
				type: 'save',
				disabled: loading
			},
			{
				text: t("common:Cancel"),
				handleClick: () => setDeleteSetting(null),
				type: "cancel",
			},
		],
		handleClose: () => setDeleteSetting(null),
		className: "notifications-settings-dialog",
	})

	const getModalConfig = () => ({
		gray: true,
		formHasRequiredFields: true,
		heading: "Data Loss Notification Parameters",
		buttons: [
			{
				text: t("common:Close"),
				handleClick: onClose,
				type: "cancel",
			},
		],
		handleClose: onClose,
		className: "notifications-settings-dialog",
	})
	const dataCollectionNotificationsNames = dataCollectionNotifications.map(e => e.name)
	return (
		<>
			{deleteSetting ? <Modal {...getModalConfigDelete()}>
				Are you sure you'd like to remove {deleteSetting?.name || "the selected notification parameter"}?
		</Modal>
			: <Modal {...getModalConfig()}>
			<div className="rx-grid rx-grid-fit-y">
				<div className="rx-col-12">
					<span className="description-text grey">
						A data loss notification parameter is triggered when data stops flowing from a building to the cloud. Name your notification then choose your notification timeframe and which buildings you wish to be notified about.
					</span>
				</div>
			</div>
			<section className="rx-grid list" ref = {listRef}>
				
				<br/>
				<br/>
				<br/>
				{dataCollectionNotifications?.map((e, i) => {
					return e.open 
					? <SiteDataCollectionNotificationsSettingsDialogItem 
					key={e.name} 
					onSave={e => {
						onSave(e)
						setOpen(false)
					}} 
					notification={e}
					dataCollectionNotificationsNames={dataCollectionNotificationsNames}
					first={Boolean(i === 0)}
					last={Boolean(i === dataCollectionNotifications?.length - 1)}
					onCancel={() => {
						const newData = [...dataCollectionNotifications]
						if (newData[i]?.ids?.length) {
							newData[i].open = false
							setDataCollectionNotifications(newData)
						} else {
							newData.splice(i, 1)
							setDataCollectionNotifications(newData)
						}
						setOpen(false)
					}}
					/> 
					: <SiteDataCollectionNotificationsSettingsDialogItemClosed key={`${e.name}-closed`} notification={e} onEdit={() => {
						const newData = [...dataCollectionNotifications]
						newData[i].open = true
						setDataCollectionNotifications(newData)
					}} 
					first={Boolean(i === 0)}
					last={Boolean(i === dataCollectionNotifications?.length - 1)}
					onDelete={() => {
						const newData = [...dataCollectionNotifications]
						setDeleteSetting(newData[i])
					}} 
					/>
				})}
				<div className="rx-new-row" />
				
			</section>
				{dataCollectionNotifications.some(e => e.open === true) 
					? ""
					:<div>
						<Button onClick={() => {
						const newData = [...dataCollectionNotifications]
						newData.push({open: true})
						setDataCollectionNotifications(newData)
						setOpen(true)
					}}>{dataCollectionNotifications?.length ? "Add Another" : "Add Data Loss Notification"}</Button>
					</div>
				}
				
		</Modal>
			}
		</>
	)
}

SiteDataCollectionNotificationsSettingsDialog.propTypes = {

}
