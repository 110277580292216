import { isNumber, isObject } from '../../utils'

// --------------------------------------------- default breakpoints theme props ---------------------------------------------

const defaults = ['0', '600px', '840px', '1220px']

defaults.xs = defaults[0]
defaults.sm = defaults[1]
defaults.md = defaults[2]
defaults.lg = defaults[3]

// ---------------------------------------------- Theme Mapper ----------------------------------------------

export function mergeTheme(breakpoints, _userTheme, _newTheme) {
  if (!breakpoints) return defaults

  if (Array.isArray(breakpoints)) {
    breakpoints[0] !== undefined && (breakpoints.xs = getValue(breakpoints[0]))
    breakpoints[1] !== undefined && (breakpoints.sm = getValue(breakpoints[1]))
    breakpoints[2] !== undefined && (breakpoints.md = getValue(breakpoints[2]))
    breakpoints[3] !== undefined && (breakpoints.lg = getValue(breakpoints[3]))

    return breakpoints
  }

  if (isObject(breakpoints)) {
    return Object.keys(breakpoints).reduce((av, key, idx, arr) => {
      const val = breakpoints[key]

      if (val !== undefined && val !== null && val !== '') {
        av.push(getValue(val))

        av[key] = getValue(val)
      }
      if (arr.length === idx + 1 && av.length === 0) {
        return defaults
      }
      return av
    }, [])
  }

  return defaults
}

function getValue(val) {
  if (isNumber(val)) {
    return +val === 0 ? val : val + 'px'
  }
  return val
}
