import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import './conditional-tooltip.scss'
import DOMPurify from 'dompurify'

const InjectHTML = ({ className, value }) => {
  const sanitizedData = DOMPurify?.sanitize?.(value)
  if (!sanitizedData) {
    return null
  }
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: sanitizedData }}
    />
  )
}

/**
 * May be used as wrapper for ConditionalTooltip component within flexbox layout
 * to provide correct work of ellipsis
 */
export const TooltipFlexWrapperStyled = styled('div')`
  max-width: 100%;
  min-width: 0%;
`

/**
 * ConditionalTooltip - Component designed to handle icon/navigational and default tooltip types.
 * @see Style Guide https://tranetis.jira.com/wiki/spaces/EM/pages/130089394/Pop-Up+Tooltip#Pop-Up/Tooltip-DefaultPop-Up/Tooltip
 */
export class ConditionalTooltip extends PureComponent {
  static TYPE = {
    DEFAULT: 'default',
    ICON: 'icon'
  }

  static POSITION = {
    BOTTOM: 'bottom',
    LEFT: 'left',
    RIGHT: 'right',
    TOP: 'top',
    CENTER: 'center'
  }

  static ALIGN = {
    START: 'start',
    CENTER: 'center',
    END: 'end'
  }

  static propTypes = {
    /**
     * the alignment of the tooltip in relation to the associated children element.
     * provides additional class like tooltip-base--align-{align}
     */
    align: PropTypes.oneOf(Object.values(ConditionalTooltip.ALIGN)),

    /**
     * the position of the tooltip in relation to the associated children element.
     * provides additional class like tooltip-base--position-{position}
     */
    position: PropTypes.oneOf(Object.values(ConditionalTooltip.POSITION)),

    /**
     * applies styles according to icon/navigational or default tooltip type
     * provides additional class like tooltip-base--type-{type}
     */
    type: PropTypes.oneOf(Object.values(ConditionalTooltip.TYPE)),

    /**
     * the associated element whose hover state will make the tooltip display
     */
    children: PropTypes.node,

    /**
     * Custom class names
     */
    className: PropTypes.string,

    /**
     * defines a part of class name like conditional-tooltip--{componentName}
     * @example conditional-tooltip--some-class
     */
    componentName: PropTypes.string,

    /**
     * the content of the tooltip
     */
    content: PropTypes.node,

    /**
     * defines if the tooltip will be shown only on the text truncation of associated children
     */
    conditional: PropTypes.bool,

    /**
     * defines if the tooltip content will be wrapped
     */
    multiline: PropTypes.bool,

    /**
     * custom node to render tooltip inside this node
     */
    renderNode: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.instanceOf(Element)
    ]),

    /**
     * Width of tooltip base
     */
    width: PropTypes.string,

    injectHtml: PropTypes.bool,

    testName: PropTypes.string
  }

  static defaultProps = {
    align: ConditionalTooltip.ALIGN.CENTER,
    children: '',
    className: '',
    componentName: '',
    conditional: false,
    content: '',
    multiline: false,
    position: ConditionalTooltip.POSITION.TOP,
    type: ConditionalTooltip.TYPE.DEFAULT,
    renderNode: null,
    injectHtml: false
  }

  state = {
    isShown: false
  }

  tooltipContainerRef = React.createRef()
  ellipsisWrapperRef = React.createRef()
  childrenContentRef = React.createRef()

  componentWillUnmount() {
    document.removeEventListener('wheel', this.onMouseWheel)
  }

  onMouseWheel = () => {
    this.toggleTooltip(false)
  }

  onMouseEnter = () => {
    this.toggleTooltip(
      this.props.conditional ? this.isChildrenTruncated() : true,
      () => {
        document.addEventListener('wheel', this.onMouseWheel)
      }
    )
  }

  onMouseLeave = () => {
    this.toggleTooltip(false, () => {
      document.removeEventListener('wheel', this.onMouseWheel)
    })
  }

  toggleTooltip = (isShown, onSetState = () => {}) => {
    if (isShown !== this.state.isShown) {
      this.setState({ isShown }, onSetState)
    }
  }

  isChildrenTruncated = () => {
    const ellipsis = this.ellipsisWrapperRef.current
    const { scrollWidth } = ellipsis

    let childrenWidth = parseFloat(
      this.childrenContentRef.current.getBoundingClientRect().width
    )
    const parentWidth = Math.ceil(
      parseFloat(ellipsis.getBoundingClientRect().width)
    )

    // Chrome rounds the element.scrollWidth down.
    // Thus, the width of children content greater than the scrollWidth of its parent.
    // As result we will not see tooltip.
    // On the other hand.
    // IE and Edge count wrong width of children content when it is truncated with ellipsis.
    // Thus, the width of children content less than scrollWidth of its parent.
    // As result we will not see tooltip.
    childrenWidth = Math.max(childrenWidth, scrollWidth)

    return parentWidth < childrenWidth
  }

  getTooltipStyles = () => {
    const { current } = this.tooltipContainerRef

    if (current) {
      const childrenRect = current.getBoundingClientRect()
      const { top, left, width, height } = childrenRect

      return { top, left, width, height }
    } else {
      return {}
    }
  }

  render() {
    const {
      align,
      children,
      className,
      componentName,
      content,
      multiline,
      position,
      type,
      renderNode,
      width,
      injectHtml,
      testName
    } = this.props
    const { isShown } = this.state

    // for now, simplifying the way styles are organized and applied, instead of needing to pass in 3 separate style directives
    const anchorClassName = `conditional-tooltip--${componentName}`

    return (
      <div
        data-testid={`${testName}_tooltip`}
        ref={this.tooltipContainerRef}
        className={classNames('conditional-tooltip', className, {
          [anchorClassName]: Boolean(componentName)
        })}
      >
        <div
          className="conditional-tooltip__children-wrapper"
          onMouseOver={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <div
            ref={this.ellipsisWrapperRef}
            className="conditional-tooltip__ellipsis-wrapper"
          >
            <div
              ref={this.childrenContentRef}
              className="conditional-tooltip__children--content"
            >
              {children}
            </div>
          </div>

          {isShown && content !== null && (
            <ConditionalTooltipBase
              align={align}
              position={position}
              isShown={isShown}
              content={content}
              type={type}
              multiline={multiline}
              getTooltipStyles={this.getTooltipStyles}
              renderNode={renderNode}
              width={width}
              injectHtml={injectHtml}
            />
          )}
        </div>
      </div>
    )
  }
}

export class ConditionalTooltipBase extends PureComponent {
  static propTypes = {
    /**
     * the alignment of the tooltip in relation to the associated children element.
     * provides additional class like tooltip-base--align-{align}
     */
    align: PropTypes.oneOf(Object.values(ConditionalTooltip.ALIGN)),

    /**
     * the content of the tooltip
     */
    content: PropTypes.node,

    /**
     *  Used to retrieve styles
     *  @returns {object}
     *  @property {string} top - tooltip left postion
     *  @property {string} left - tooltip right postion
     *  @property {string} width - tooltip anchor width
     *  @property {string} height - tooltip anchor height
     */
    getTooltipStyles: PropTypes.func,

    /**
     * whether to show tooltip
     */
    isShown: PropTypes.bool,

    /**
     * defines if the tooltip content will be wrapped
     */
    multiline: PropTypes.bool,

    /**
     * the position of the tooltip in relation to the associated children element.
     * provides additional class like tooltip-base--position-{position}
     */
    position: PropTypes.oneOf(Object.values(ConditionalTooltip.POSITION)),

    /**
     * custom node to render tooltip inside this node
     */
    renderNode: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.instanceOf(Element)
    ]),

    /**
     * applies styles according to icon/navigational or default tooltip type
     * provides additional class like tooltip-base--type-{type}
     */
    type: PropTypes.oneOf(Object.values(ConditionalTooltip.TYPE)),

    /**
     * Width of tooltip base
     */
    width: PropTypes.string
  }

  static defaultProps = {
    align: ConditionalTooltip.ALIGN.CENTER,
    content: '',
    getTooltipStyles: () => {},
    isShown: false,
    multiline: false,
    position: ConditionalTooltip.POSITION.TOP,
    renderNode: null,
    type: ConditionalTooltip.TYPE.DEFAULT,
    width: null,
    injectHtml: false
  }

  render() {
    const {
      align,
      content,
      getTooltipStyles,
      isShown,
      multiline,
      position,
      renderNode,
      type,
      width,
      injectHtml
    } = this.props

    const style = getTooltipStyles()
    const baseClassName = `conditional-tooltip__base conditional-tooltip__base--type-${type} conditional-tooltip__base--align-${align} conditional-tooltip__base--position-${position}`
    return ReactDOM.createPortal(
      <div style={style} className="conditional-tooltip__portal">
        <div
          className={classNames(baseClassName, {
            'conditional-tooltip__base--hidden': !isShown,
            'conditional-tooltip__base--multiline': multiline
          })}
          style={{ width }}
        >
          {injectHtml ? (
            <InjectHTML
              className="conditional-tooltip__wrapper"
              value={content}
            />
          ) : (
            <div className="conditional-tooltip__wrapper">{content}</div>
          )}
          <div className={`conditional-tooltip__arrow ${type}`} />
        </div>
      </div>,
      renderNode || document.body
    )
  }
}
