import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import Modal from 'src/components/legacy/components/modal/modal'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import CharacteristicCurveDialog from './CharacteristicCurveDialog'
import { Button } from 'src/components/inputs/button'
import Label from 'src/components/legacy/components/label/label'
import { CharacteristicsCurveWrapper } from './styles'
import { ACTIONS } from 'src/constants'
import { useTranslation } from 'react-i18next'
import CharacteristicCurvesList from './CharacteristicCurvesList'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  GET_CHARACTERISTIC_CURVES,
  GET_CIRCUIT_COUNT
} from '../../graphql/queries'
import { UPDATE_CHARACTERISTIC_CURVE } from '../../graphql/mutations'
import CharacteristicCurveHistory from './CharacteristicCurveHistory'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import translate, { TranslateComponent } from '../../../../common/translations'

const CharacteristicCurves = ({ equipment, reloadCharacteriticCurves }) => {
  const [showCurveHistory, setShowCurveHistory] = useState(false)
  const [reloadEvents, setReloadEvents] = useState(false)
  const [actionMode, setActionMode] = useState(null)
  const [rowData, setRowData] = useState(null)
  const [enableAutomatedTestCount, setEnableAutomatedTestCount] = useState(null)
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)
  const [t] = useTranslation()
  const {
    data: characteristicCurveslist = [],
    refetch: listCharacteristicCurves,
    loading: loadingListCharacteristicCurves
  } = useQuery({
    query: GET_CHARACTERISTIC_CURVES,
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data.listCharacteristicCurveByEquipment.items'
  })
  const {
    data: circuitList = [],
    refetch: listCircuitCount,
    loading: loadingListCircuitCount
  } = useQuery({
    query: GET_CIRCUIT_COUNT,
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data.getPropertyMappingByEquipmentID.items'
  })
  const {
    onSubmit: updateCharacteristicCurve,
    loading: loadingUpdateCharacteristicCurve
  } = useMutation({
    query: UPDATE_CHARACTERISTIC_CURVE,
    dataPath: 'data.updateCharacteristicCurve',
    errorPolicy: 'global'
  })
  const CurveTranslate = {
    "GoBackToActiveCurves" : translate("Back to Active Curves"),
    "CharacteristicCurveHistoryLabel" : translate("Characteristic Curve History") + (":")
  }
  useEffect(() => {
    trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.VIEW_CHARACTERISTICS_CURVE)
  }, [])
  useEffect(() => {
    if (equipment?.building?.id && equipment?.id) {
      listCharacteristicCurves({ id: equipment?.id })
      listCircuitCount({ id: equipment?.id })
    }
  }, [equipment])

  useEffect(() => {
    // will reload when GCPM loaded successfully 
    if (equipment?.id && reloadCharacteriticCurves) {
      listCharacteristicCurves({ id: equipment.id })
    }
  }, [reloadCharacteriticCurves])

  useEffect(() => {
    if (reloadEvents) {
      setReloadEvents(false)
      if (equipment?.id) {
        listCharacteristicCurves({ id: equipment.id })
      }
    }
  }, [reloadEvents])

  useEffect(() => {
    const testCount = characteristicCurveslist
      ?.filter(
        (characteristicsCurve) =>
          characteristicsCurve.runAnalytic && characteristicsCurve.isActive
      )
      ?.map((item) => `${item.curveType}  ${item.instance}`)
    setEnableAutomatedTestCount(testCount || [])
  }, [characteristicCurveslist])

  // Delete validation
  const deleteCurveFormValidationSchema = yup.object().shape({
    comments: yup.string().trim().required()
  })

  const handleCreateCharacteristicCurve = () => {
    trackEvent(
      USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_CREATE_CHARACTERTISTICS_CURVE
    )
    setRowData(null)
    setActionMode(ACTIONS.ADD)
  }

  // Delete Modal config
  const createDeleteCurveModalConfig = ({
    onSubmit,
    onClose,
    translate,
    isValid
  }) => {
    return {
      heading: translate('equipment-setup:CharacteristicsCurves>DeleteCurve'),
      buttons: [
        {
          text: translate('common:Yes'),
          handleClick: onSubmit,
          type: 'valid',
          buttonType: 'submit',
          disabled: !isValid
        },
        {
          text: translate('common:No'),
          handleClick: onClose,
          type: 'cancel'
        }
      ],
      handleClose: onClose
    }
  }
  const toggleCurveHistory = (selectedItem) => {
    showCurveHistory(true)
  }
  const handleDeleteModal = (
    { characteristicCurveId = null } = {},
    isDeleteModalOpened = true
  ) => {
    setIsDeleteModalOpened(false)
  }
  return (
    <CharacteristicsCurveWrapper>
      <div className="header">
        {!showCurveHistory ? (
          <>
            <div className="title">
              <Label text="Characteristics Curves" />
            </div>
            <Button onClick={handleCreateCharacteristicCurve}>
              <TranslateComponent>Create Curve</TranslateComponent>
            </Button>
          </>
        ) : (
          <div className="title">
            <Label
              text={(
                CurveTranslate.CharacteristicCurveHistoryLabel  +  rowData?.name 
                )}
            />
            <button
              className="go-back-link-button"
              onClick={() => setShowCurveHistory(false)}
            >
              {(CurveTranslate.GoBackToActiveCurves)}
            </button>
          </div>
        )}
      </div>
      <div>
        {!showCurveHistory ? (
          <>
            {loadingListCharacteristicCurves ? (
              <Spinner />
            ) : (
              <CharacteristicCurvesList
                equipment={equipment}
                characteristicCurveslist={characteristicCurveslist}
                setMode={setActionMode}
                setRowData={setRowData}
                setIsDeleteModalOpened={setIsDeleteModalOpened}
                setShowCurveHistory={setShowCurveHistory}
              />
            )}
          </>
        ) : (
          <CharacteristicCurveHistory
            rowData={rowData}
            setMode={setActionMode}
            setRowData={setRowData}
            characteristicCurveslist={characteristicCurveslist}
            setReloadEvents={setReloadEvents}
          />
        )}
        {actionMode && (
          <CharacteristicCurveDialog
            mode={actionMode}
            setMode={setActionMode}
            enableAutomatedTestCount={enableAutomatedTestCount}
            circuitList={circuitList || []}
            equipment={equipment}
            setReloadEvents={setReloadEvents}
            rowData={rowData}
          />
        )}
        {/* Delete curve - Modal */}
        {isDeleteModalOpened && (
          <Formik
            initialValues={{ comments: '' }}
            validateOnMount={true}
            validationSchema={deleteCurveFormValidationSchema}
            onSubmit={async ({ comments }) => {
              const input = {
                id: rowData.id,
                isActive: 0,
                comments
              }
              await updateCharacteristicCurve({ input })
              setIsDeleteModalOpened(false)
              setReloadEvents(true)
              trackEvent(
                USER_EVENTS.EQUIPMENT_SETUP.events.DELETE_CHARACTERISTIC,
                { comments: comments }
              )
            }}
          >
            {(formikProps) => (
              <Modal
                {...createDeleteCurveModalConfig({
                  isValid: formikProps.isValid,
                  onClose: () => handleDeleteModal({}, false),
                  onSubmit: formikProps.handleSubmit,
                  translate: t
                })}
                loading={loadingUpdateCharacteristicCurve}
              >
                <Form>
                  <div style={{ marginBottom: '10px' }}>
                    {t(
                      'equipment-setup:CharacteristicsCurves>DeleteCurveConfirmText'
                    )}
                  </div>
                  <TextAreaInput
                    labelText="Comments"
                    value={formikProps.values.comments}
                    onChange={formikProps.handleChange}
                    isRequired={true}
                    inputProps={{
                      name: 'comments'
                    }}
                  />
                </Form>
              </Modal>
            )}
          </Formik>
        )}
      </div>
    </CharacteristicsCurveWrapper>
  )
}

export default CharacteristicCurves
