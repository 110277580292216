import AxisGridRenderer from '../XYChart/AxisGridRenderer'
import { memo, useMemo, useRef } from 'react'
import Series from './Series'
import ChartSVG from '../XYChart/ChartSVG'
import {
  selectCanvasDetails,
  selectChartInstanceId,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
import { isEmpty } from 'ramda'
import { plotAreaID } from '../XYChart/helper'
import ToolTipRenderer from './tooltipTemplate'
import { clipUUID } from 'src/chart-library/CommonComponents/Clip'
import { Tooltip } from 'src/chart-library/CommonComponents/Tooltip'
import './styles.scss'

const Chart = (props: any) => {
  const eventName = useMemo(() => clipUUID('action-tracking-chart-tooltip'), [])
  // provide boundary of tooltip to position properly
  const containerRef: any = useRef(null)

  const canvasIndex = 0
  const canvasDetails = useSelector(selectCanvasDetails)
  const chartInstanceId = useSelector(selectChartInstanceId)
  const canvasObject = canvasDetails?.[`${canvasIndex}`]

  if (isEmpty(canvasDetails)) {
    return <></>
  }
  return (
    <>
      <ChartSVG>
        <g
          ref={containerRef}
          key={`canvas-${canvasIndex}`}
          style={{
            transform: `translate(${canvasObject?.transformX}px,${canvasObject?.transformY}px)`
          }}
        >
          <clipPath id={`${plotAreaID(chartInstanceId, canvasIndex)}`}>
            <rect
              x={0}
              y={0}
              width={canvasObject?.width}
              height={canvasObject?.height}
              fill={'transparent'}
            />
          </clipPath>
          <rect
            id={`${plotAreaID(chartInstanceId, canvasIndex)}`}
            x={0}
            y={0}
            width={canvasObject?.width}
            height={canvasObject?.height}
            fill={'transparent'}
          />
          <AxisGridRenderer canvasIndex={canvasIndex} />
          <Series series={props?.series} tooltipDispatchEventName={eventName} />
        </g>
      </ChartSVG>
      <Tooltip
        tooltipClassName="action-tracking-tooltip-bg"
        listenEventName={eventName}
        boundary={containerRef}
        TooltipRenderer={ToolTipRenderer}
        offset={3}
      />
    </>
  )
}

export default memo(Chart)
