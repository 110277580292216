import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from 'src/components/legacy/components/modal/modal'
import WarningsDialog from 'src/components/legacy/components/error-dialog/error-dialog'
import get from 'lodash/get'
import find from 'lodash/find'
import './style.js'
import { v4 as uuid4 } from 'uuid'
import { TranslateComponent } from 'src/common/translations'

export default class NewOrganizationDialog extends PureComponent {
  static propTypes = {
    organizationName: PropTypes.string,
    organizations: PropTypes.object,
    isCreatingOrganization: PropTypes.bool,
    closeDialog: PropTypes.bool,
    className: PropTypes.string,
    creatingOrganizationError: PropTypes.any,
    toggleCreateModal: PropTypes.func,
    addNewOrganization: PropTypes.func,
    sideEffectFunction: PropTypes.func
  }

  static defaultProps = {
    organizationName: ''
  }

  state = {
    isErrorsDialogOpen: false
  }

  onAddOrganization = async () => {
    const { organizationName } = this.props
    const uuid = uuid4();
    await this.props.createAccount({ input: { name: organizationName,id : uuid,accountId:uuid  } }).then(res => {
      this.props.responseFunc(res)
    })
  }

  toggleWarningsDialog = (errorMessage = null) => {
    if (typeof errorMessage !== 'string') {
      errorMessage = ''
    }
    this.setState({
      isErrorsDialogOpen: !this.state.isErrorsDialogOpen,
      errorMessage
    })
  }

  handleCancel = () => {
    this.props.toggleCreateModal()
  }

  render() {
    const { className, isCreatingOrganization, organizationName } = this.props
    const { isErrorsDialogOpen, errorMessage } = this.state

    const modalConfig = {
      heading: 'Create Organization',
      buttons: [
        {
          text: 'Yes',
          handleClick: this.onAddOrganization,
          type: 'confirm',
          disabled: isCreatingOrganization
        },
        { text: 'No', handleClick: this.handleCancel, type: 'cancel' }
      ],
      handleClose: this.handleCancel,
      className,
      loading: isCreatingOrganization
    }
    return (
      <Modal {...modalConfig}>
        <div className="confirmationText">
          <TranslateComponent>{"Are you sure you want to create the Organization/Customer Name "}</TranslateComponent> "
          {organizationName}"?
        </div>
        {isErrorsDialogOpen && (
          <WarningsDialog
            title="API error"
            handleClose={this.toggleWarningsDialog}
            text={errorMessage}
          />
        )}
      </Modal>
    )
  }
}
