import { DashboardContextProvider } from "./CreateDashBoardProvider"
import CreateDashboardDialog from "./CreateDashboardDialog"
const CreateDashboard = () => {
  return(
    <DashboardContextProvider>
        <CreateDashboardDialog />
    </DashboardContextProvider>
  )
}

export default CreateDashboard

