import React from 'react'
import { DefaultHighlightMarker } from './style'
import { TranslateComponent } from 'src/common/translations'

const Highlighter = ({
  text, // the actual text in which to look for searched text to highlight
  textToHighlight, // text to highlight
  CustomHighLightMarker, // a styled component / DOMelement or a react element which returns markup on how to highlight the searched text egs : returns <strong>searchedtext</strong> , <i>searchedtext</i>
  isTranslate = false
}: any) => {
  if (!textToHighlight) {
    return <>{!isTranslate ? <>{text}</> : <TranslateComponent>{text}</TranslateComponent>}</>
  }
  const parts = text?.split(new RegExp(`(${textToHighlight.replace(/([.?*+^$[\]\\(){}|-])/gi, "\\$1")})`, 'gi'))
  const Marker = CustomHighLightMarker || DefaultHighlightMarker
  return (
    <span>
      {parts?.map((part, i) =>
        part.toLowerCase() !== textToHighlight.toLowerCase() ? (
          <span key={i}>{part.includes('Organization')? <TranslateComponent>{part}</TranslateComponent>: part}</span>
        ) : (
          <Marker key={i}>{part}</Marker>
        )
      )}
    </span>
  )
}

export default Highlighter
