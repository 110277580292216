import { useEffect, useRef, useReducer } from 'react'
import { useQuery } from 'src/hooks/APIHooks'

function reducer(state, action) {
  switch (action.type) {
    // Future purpose
    case 'set_data':
      return {
        ...state,
        data: action.data || []
      }

    // Future purpose
    case 'set_loading_status':
      return {
        ...state,
        isLoading: action.isLoading || false
      }

    // by default, assigns the data from action into state
    default:
      return { ...state, ...action }
  }
}

export const useAPIPagination = (query, dataPath) => {
  const queryResultRef = useRef([])
  const queryVariablesRef = useRef({})

  const [state, dispatch] = useReducer(reducer, { data: [], isLoading: false })

  const { refetch: refetchData, data: queryResponse } = useQuery({
    query: query,
    dataPath: dataPath,
    disableInitialLoad: true
  })

  const resetRef = () => {
    queryVariablesRef.current = {}
    queryResultRef.current = []
  }

  const setQueryVariablesRef = (varaiables) => {
    queryVariablesRef.current = varaiables
  }

  const setQueryResultRef = (data = []) => {
    queryResultRef.current = [...queryResultRef.current, ...data]
  }

  const initApiRequest = (varaiables) => {
    resetRef()
    setQueryVariablesRef(varaiables)
    refetchData(varaiables)
  }

  useEffect(() => {
    try {
      // Store the data in ref to avoid multiple rendering
      setQueryResultRef(
        queryResponse && queryResponse?.items?.length > 0
          ? queryResponse?.items
          : []
      )

      if (queryResponse && queryResponse?.nextToken !== null) {
        refetchData({
          ...queryVariablesRef.current,
          nextToken: queryResponse?.nextToken
        })
      } else {
        // On success of the last request, load data & set the loading status as false
        dispatch({
          isLoading: false,
          data: [...queryResultRef.current]
        })
      }
    } catch (error) {
      // Reset to default if any error
      dispatch({ isLoading: false, data: [] })
    }
  }, [queryResponse])

  const getData = (variables) => {
    try {
      // Reset state on new req
      dispatch({ isLoading: true, data: [] })

      initApiRequest(variables)
    } catch (error) {
      // Reset to default if any error
      dispatch({ isLoading: false, data: [] })
    }
  }

  return [state.isLoading, getData, state.data]
}
