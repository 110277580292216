export const ATTACHMENT_CATEGORIES = {
  ADD_IMAGES: 'Add Images',
  ADD_FILES: 'Add Files'
}

export const updateFileNameWithEpoch = (filename) => {
  const ext = filename?.substr(filename.lastIndexOf('.') + 1, filename.length)
  const name = filename?.substr(0, filename.lastIndexOf('.'))
  let appendEpoch
  if (name && ext) {
    appendEpoch = name + '_' + Date.now() + '.' + ext
  } else {
    appendEpoch = ext + '_' + Date.now()
  }
  return appendEpoch
}
export const phoneRegExp = /^\+[1-9]{1}[0-9]{3,14}$/i 
export const validatePhoneNumber = (phoneNumber = '') => {
  const isValid = phoneRegExp.test(phoneNumber.trim());
  return isValid;
}

export const validateEmail = (email = '') =>{
  let regex =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i // eslint-disable-line
  const isValid = regex.test(email.trim());
  return isValid
}

export const sortHelper = (list) => {
  return [...list]?.map(item => ({...item,value: item.value.trim().replace(/\s+/g, ' ')
        }))?.sort((a, b) => a.value.localeCompare(b.value,'en', { numeric: true }));
}


export const getSearchParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const buildingId = urlParams.get('location')
  const organizationId = urlParams.get('organization')
  const salesOffice = urlParams.get('salesOffice') // added this only to get GEN4 url corrected in Denali
  const salesOfficeId = urlParams.get('salesOfficeId')
  const equipment = urlParams.get('equipment')
  const tisObjectId = urlParams.get("object")
  const reportType = urlParams.get("reportType")
  const sid = urlParams.get("sid")
  const chartImage = urlParams.get("chartImage")
  const chartTitle = urlParams.get("chartTitle")
  const chartLink = urlParams.get("chartLink")
  const chartId = urlParams.get("chartId")
  const startDate = urlParams.get("startDate")
  const endDate = urlParams.get("endDate")
  const tab = urlParams.get("tab")
  const consultationId = urlParams.get("consultationId")

  return {
    tab,
    buildingId, 
    organizationId, 
    salesOfficeId, 
    salesOffice,
    equipment, 
    tisObjectId, 
    reportType, 
    sid, 
    chartImage, 
    chartTitle, 
    chartLink, 
    chartId,
    startDate,
    endDate,
    consultationId
  }
}

export const getSearchParamsString = () => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.toString()
}


export const getbuildingIdsFromToken = (token) => {
  try {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const bIds = JSON.parse(JSON.parse(jsonPayload)['bIds'])
    const aIds =  JSON.parse(JSON.parse(jsonPayload)['aIds'])
    const sIds =  JSON.parse(JSON.parse(jsonPayload)['sIds'])
    const isSuperAdmin = JSON.parse(jsonPayload)?.["cognito:groups"]?.includes("SuperAdmin")
    const isSuperUser = isSuperAdmin || JSON.parse(jsonPayload)?.["cognito:groups"]?.includes("SuperUser")
    return { bIds, aIds, sIds, isSuperAdmin, isSuperUser };
  } catch(error) {
    console.log("JSON parse issue")
  }
}

export const getUniqueItems =  list => {
  if(!Array.isArray(list)){
    return list
  }
  return Array.from(new Set(list))
}

// Deep compare two objects/Array of objects are equals
// If not returns false
export const deepCompareObjects = (object1, object2) => {
		const obj1Keys = Object.keys(object1)
		const obj2Keys = Object.keys(object2)

		if (obj1Keys.length !== obj2Keys.length) {
			return false
		}

		for (const key of obj1Keys) {
			const value1 = object1[key]
			const value2 = object2[key]

			const isObjects = isObject(value1) && isObject(value2)

            if ((isObjects && !this.deepCompareObjects(value1, value2))
			|| (!isObjects && value1 !== value2)) {
				return false
			}
		}
		return true
	}

export const handleSplitingElements = (arr, size) => {
  const res = arr?.reduce((acc, curr, i) => {
    if (!(i % size)) {    // if index is 0 or can be divided by the `size`...
      acc.push(arr.slice(i, i + size));   // ..push a chunk of the original array to the accumulator
    }
    return acc;
  }, []);
  return res
}


