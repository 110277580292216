export const dark = {
  background: '{colors.white}',
  foreground: '{colors.neutral.50}',
  headline: '{colors.neutral.90}',
  iconBackground: '{colors.black}',

  lightBlue: {
    background: 'linear-gradient(180deg, rgba(40, 85, 249, 0.1) 0%, rgba(40, 85, 249, 0) 60%), #FFFFFF',
    foreground: '{colors.neutral.50}',
    headline: '{colors.neutral.90}',
    boarderImageSource: 'linear-gradient(180deg, rgba(39, 86, 249, 0.1) 0%, rgba(39, 86, 249, 0) 100%)',
    boxShadow: '0px 4px 42.5px 0px #0000000A',
    iconBackground: 'radial-gradient(85.72% 85.72% at 54.14% -0.7%, #3347F6 0%, #0085FF 100%)',
    iconBoxShadow: '0px 0px 21.67px 0px #1B3FFB4D'
  }
};

export const light = dark;