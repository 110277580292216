import initMergeThemeFn from '../common/themeMapper'

// ---------------------------------------------- Default Button Styles ----------------------------------------------

export const defaultStyle = {
  root: {},
  hover: {
    background: '#daf9ff'
  },
  focus: {
    background: '#daf9ff'
  },
  selected: {
    background: '#d1e1f5cc',
    hover: {
      background: '#d1e1f5'
    },
    focus: {
      background: '#d1e1f5'
    }
  },
  omit: {
    background: 'inherit',
    hover: {
      background: 'inherit'
    }
  },
  disabled: {
    background: '#e2e2e294',
    hover: {
      background: '#e2e2e294'
    }
  }
}

// ---------------------------------------------- Theme Mapper ----------------------------------------------

export const mergeTheme = initMergeThemeFn({ defaultStyle, propResolver: {} })
