import { API } from 'aws-amplify'
import { useState } from 'react'
import { GET_USER_BY_ID_func } from '../../../graphql'
import { NOTIFICATION_SUB_TYPE } from '../constants'
import { createName, isValidNotificationType, valueFromBody } from '../helpers'

export function useUserDetails() {
  const [uniqueUserIds, setUniqueUserIds] = useState({})

  const newUserIds = []

  // find new user ids (i.e assigne or assignor)
  // ====================================================================================================
  function findNewAssigneeAndAssignorIdsForIssuesAndFindings(
    notification: any
  ) {
    const isValid = isValidNotificationType(notification, [
      NOTIFICATION_SUB_TYPE.ISSUE
    ])

    if (!isValid) return

    const assignee = valueFromBody(notification, 'assignee')
    const assignor = valueFromBody(notification, 'assignor')

    // FOR ASSIGNEE

    const isAssigneeExisting = uniqueUserIds[assignee] !== undefined

    // if existing, data is available already, don't cache
    if (!isAssigneeExisting && assignee) {
      uniqueUserIds[assignee] = true

      newUserIds.push(assignee)
    }

    // FOR ASSIGNOR

    const isAssignorExisting = uniqueUserIds[assignor] !== undefined

    // if existing, data is available already, don't cache
    if (!isAssignorExisting && assignor) {
      uniqueUserIds[assignor] = true
      newUserIds.push(assignor)
    }
  }

  // Api to get new buildings name
  // ====================================================================================================
  async function apiGetUserByIds() {
    let data = null

    if (newUserIds.length) {
      try {
        const response = (await API.graphql({
          query: GET_USER_BY_ID_func(newUserIds)
        })) as any

        data = response?.data || {}
      } catch (error) {
        console.log('API Rejection error', error)
      }
    }

    // Resolve the function regardless of success or failure of request
    return await Promise.resolve({
      requestFrom: 'USER_DATA',
      data
    })
  }

  // Update the response with new properties
  // ====================================================================================================
  function addAssigneeAndAssignorNamePropertyToNotificationsForIssuesAndFindings(
    notifications = [],
    userNames = {}
  ) {
    if (!userNames || Object.keys(userNames).length === 0) {
      // Exceptional cases
      if (newUserIds.length) {
        const ids = newUserIds.reduce((av, id) => {
          av[id] = true

          return av
        }, {})

        notifications.map((n) => {
          const assignee = valueFromBody(n, 'assignee')
          const assignor = valueFromBody(n, 'assignor')

          if (ids[assignee]) {
            n['assignee'] = assignee
          }

          if (ids[assignor]) {
            n['assignor'] = assignor
          }

          return n
        })
      }

      return notifications
    }

    const usernames = Object.values(userNames).reduce((acc, val: any) => {
      val ? acc[val.id] = createName(val) : null;
      return acc
    }, {})

    return notifications.map((notification) => {
      const assignee = valueFromBody(notification, 'assignee')
      const assignor = valueFromBody(notification, 'assignor')

      return {
        ...notification,
        assignee,
        assignor,
        assigneeName: usernames[assignee],
        assignorName: usernames[assignor]
      }
    })
  }

  // Reset interal state
  // ====================================================================================================
  function updateState(reset: boolean) {
    const data = newUserIds.slice()

    // Reset or update stored user list
    setUniqueUserIds((userIds) => ({
      ...(!reset ? userIds : {}),
      ...data
    }))

    newUserIds.length = 0
  }

  return {
    findNewAssigneeAndAssignorIdsForIssuesAndFindings,
    apiGetUserByIds,
    addAssigneeAndAssignorNamePropertyToNotificationsForIssuesAndFindings,
    updateState
  }
}
