import { TranslateComponent } from "src/common/translations"

export const DateRange = ({start, end}:DateRangeProps) => {
  return (
    <div className='body-md'>
      {
        start && <span className="noWrap">
            <TranslateComponent>{start}</TranslateComponent>
            {end && <span> - </span> }
        </span>
      }
      {
        end && <span className="noWrap">
          <TranslateComponent>{end}</TranslateComponent>
        </span>
      }
    </div>
  )
}

export type DateRangeProps = {
  start?: string,
  end?: string
}