import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'

export const ErrorWrapper = styled.div`
  padding: 10px;
  background: white;
  border: 2px solid ${colors?.RED};
  color: ${colors?.RED};
  margin: 1rem 0;
  font-weight: bold;
`
