// Default Options.

import { SOLID_LINE } from "../../../../../chart-library/CommonComponents/LinePath/constants";
import { getBarDataCategory, getMaxValue, getMinValue } from "../../../../../chart-library/CommonComponents/Bar/helper";
import _isEmpty from 'lodash/isEmpty'

export function useOptions(barChartData): Options {
  const data = barChartData?.barData
  const plotLineData = barChartData?.plotLineData
  let plotLineDetails = null
  const plotLineValue = plotLineData?.plotLineValue
  if(plotLineData) {
    plotLineDetails = {
      "name": !_isEmpty(plotLineData?.name) ? plotLineData?.name : 'Benchmark',
      "stroke": plotLineData?.plotLineColor,
      "strokeWidth": 5,
      "value": plotLineValue,
      "dashType": SOLID_LINE,
      text: {
        hide: plotLineData?.plotHeaderLabelHide ?? true
      }
    }
  }
  const minValue = getMinValue(data)
  const maxValue = getMaxValue(data) 
  return {
    xAxis: {
      type: "scaleBand",
      data: getBarDataCategory(data)
    },
    yAxis: {
      type: "scaleLinear",
      min: minValue > 0 ? 0 : minValue,
      max: plotLineValue > maxValue ? plotLineValue : maxValue,
    },
    plotAxisType: "y",
    plotLineConfig: plotLineData && plotLineDetails ,
    grid: [{ left: "60px", right: "0px", top: "20px", bottom: "50px" }]
  };
}
