import { AxisTypes } from "../../Types/axis";

export const gridLinePosition = (
  type: AxisTypes,
  scale: any,
  val: any,
  width: number,
  height: number
) => {
  let x2;
  let y2;
  let transform;
  if (type === "x") {
    if (scale) {
      transform = `translate(${scale(val)},0)`;
    }
    y2 = height;
  }
  if (type === "y") {
    if (scale) {
      transform = `translate(0,${scale(val)})`;
    }
    x2 = width;
  }

  return {
    x2,
    y2,
    transform,
  };
};
