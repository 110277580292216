const PRECISION = 1

export const UOM_STRINGS = { 'si': 'SI', 'im': 'IP'}

export const siUnitMapping = new Map([
    ['current', 'ampere'],
    ['energy_electrical', 'kiloWattHour'],
    ['energy_heating', 'kiloWattHour'],
    ['enthalpy', 'kiloJoulesPerKilogramDryAir'],
    ['flow_fluidic', 'litersPerSecond'],
    ['flow_gaseous', 'litersPerSecond'],
    ['frequency', 'hertz'],
    ['hz', 'hertz'],
    ['kbtu/ft²', 'kiloWatt'],
    ['lb/gal', 'kiloGramsPerCubicMeter'],
    ['length', 'meter'],
    ['power_cooling', 'kiloWatt'],
    ['power_electrical', 'kiloWatt'],
    ['power_heating', 'kiloWatt'],
    ['ppm', 'partsPerMillion'],
    ['pressure_fluidic', 'kiloPascal'],
    ['pressure_gaseous', 'pascal'],
    ['pressure_gaseous_duct', 'pascal'],
    ['pressure_gaseous_building', 'pascal'],
    ['psi', 'psi'],
    ['reactive_power', 'voltAmpereReactive'],
    ['relative_humidity', 'percentRelativeHumidity'],
    ['rotational_speed', 'revolutionsPerMinute'],
    ['temperature', 'celsius'],
    ['temperature_delta', 'deltaDegreesCelsius'],
    ['volume', 'cubicMeters'],
    ['volume_fluidic', 'cubicMetersPerSecond'],
    ['volume_gaseous', 'cubicMeters'],
    ['voltage', 'volt'],
    ['kw/ton', 'kiloWatt']
])

export const roundOffConvertedValues = (val, precision = PRECISION) => {
    return Number(val.toFixed(precision))
}

export const ConvertConstantToSI = (val, unitIn, unitCategory, IsReverseUnit = false) => {
    const unitOut = siUnitMapping.get(unitCategory)
    if(IsReverseUnit){
        return convertUnits(val, unitOut, unitIn, unitCategory)
    }else{
        return convertUnits(val, unitIn, unitOut, unitCategory)
    }
}

export function convertUnits(val, unitIn, unitOut, unitCategory) {

    const conversionFactors = {
        'percent': {
            'percentOpen': 1,
            'percent': 1,
            'percentCool': 1,
            'percentHeat': 1
        },
        'time': {
            'second': 1,
            'milliSeconds': 1e-3,
            'minute': 60,
            'hour': 3600,
            'day': 86400,
            'week': 604800,
            'month': 2628000, // Average month duration
            'year': 31536000
        },
        'voltage': {
            'milliVolt': 1e-3,
            'volt': 1,
            'kiloVolt': 1e3,
            'megaVolt': 1e6
        },
        'temperature': {
            'celsius': 1,
            'fahrenheit': 1, // Special conversion handled separately
            'kelvin': 1,
            'degreeCelsius': 1,
            'degreeFahrenheit': 1,
            'degreeKelvin': 1
        },
        'temperature_delta': {
            'deltaDegreesFahrenheit': 1,
            'deltaDegreesCelsius': 1,
            'deltaDegreesKelvin': 1
        },
        'pressure_fluidic': {
            'feetOfWater': 1 / 2.30947,
            'poundsForcePerSquareInch': 1,
            'kilogramsForcePerSquareCentimeter': 1 / 0.070307,
            'kiloPascal': 1 / 6.89475728,
            'milliBar': 1 / 68.9476,
            'inchOfMercury': 1 / 2.03602,
            'centimetersOfMercury': 1 / 5.1714924102396,
            'inchesOfMercury': 1 / 2.03602,
            'centimetersOfWater': 1 / 70.307,
            'millimetersOfWater': 1 / 703.06957722714,
            'bar': 1 / 0.0689476,
            'millimeterOfMercury': 1 / 51.7149
        },
        'relative_humidity': {
            'percentRelativeHumidity': 1
        },
        'flow_fluidic': {
            'cubicMetersPerSecond': 1 / 6.30902e-5,
            'gallonPerMinutePerTon': 1,
            'imperialGallonsPerMinute': 1 / 0.832674,
            'cubicMetersPerHour': 1 / 0.227125,
            'gallonsPerHour': 1 / 60,
            'litersPerSecond': 1 / 0.0630902,
            'litersPerHour': 1 / 227.125,
            'cubicFeetPerHour': 1 / 8.02083,
            'cubicFeetPerSquareFoot': 1 / 7.48052,
            'milliLittersPerSecond': 1 / 63.090196403439,
            'cubicFeetPerSecond': 1 / 0.00222801,
            'cubicMetersPerMinute': 1 / 0.00378541,
            'litersPerMinute': 1 / 3.78541,
            'gallonsPerMinute': 1
        },
        'power_heating': {
            'kiloBtusPerHour': 1,
            'btusPerHour': 1e-3,
            'kiloWatt': 1 / 0.293
        },
        'current': {
            'milliAmpere': 1e-3,
            'ampere': 1
        },
        'power_cooling': {
            'tonRefrigeration': 1,
            'tonHour': 1,
            'kiloWatt': 1 / 3.5168528
        },
        'frequency': {
            'hertz': 1,
            'kiloHertz': 1e3,
            'cyclesPerMinute': 1 / 60,
            'megaHertz': 1e6,
            'cyclesPerHour': 1 / 3600
        },
        'enthalpy': {
            'btusPerPound': 1,
            'kiloCaloriesPerKilogramDryAir': 1,
            'joulesPerKilogramDryAir': 1,
            'btusPerPoundDryAir': 1,
            'kiloJoulesPerKilogramDryAir': 1 / 2.326
        },
        'psi': {
            'psi': 1
        },
        'rotational_speed': {
            'revolutionsPerMinute': 1
        },
        'power_electrical': {
            'kiloWatt': 1e3,
            'megaWatt': 1e6,
            'horsepower': 745.7,
            'pferdeStärke': 735.5,
            'watt': 1,
            'milliWatt': 1e-3,
            'btu': 0.293,
            'kiloBtu': 293.07
        },
        'pressure_gaseous_duct': {
            'inchesOfWater': 1,
            'pascal': 1 / 248.843
        },
        'pressure_gaseous_building': {
            'inchesOfWater': 1,
            'pascal': 1 / 248.843
        },
        'flow_gaseous': {
            'cubicFeetPerMinute': 1,
            'cfm/100': 1 / 100,
            'kiloCubicFeetPerMinute': 1e3,
            'cubicMetersPerHour': 1 / 1.6988,
            'litersPerSecond': 1 / 0.471947
        },
        'energy_electrical': {
            'kiloWattHour': 1,
            'energyCostPerSquareFoot': 1,
            'joule': 2.77778e-7,
            'megaWattHour': 1e3,
            'megaJoule': 277.778,
            'wattHour': 1e-3,
            'kiloBtu': 0.2930,
            'btu': 0.000293,  // Added conversion factor for kilowatt-hour to Btu
            'therm': 0.0341296,  // Added conversion factor for kilowatt-hour to therm
            'megaBtu': 293,  // Added conversion factor for kilowatt-hour to megaBtu
            'kiloTherm': 0.0341296e-3,  // Added conversion factor for kilowatt-hour to kiloTherm
            'megaTherm': 0.0341296e-6  // Added conversion factor for kilowatt-hour to megaTherm
        },
        'ppm': {
            'partsPerMillion': 1,
            'partsPerBillion': 1e-3
        },
        'volume_gaseous': {
            'hundredCubicFeet': 1,
            'cubicMeters': 1 / 2.83168
        },
        'energy_heating': {
            'kiloBtu': 1,
            'megaBtu': 1e3,
            'therm': 100,
            'btu': 1 / 1e3,
            'kiloJoule': 1 / 1.05506,
            'megaWattHour': 3412,
            'kiloWattHour': 3.412
        },
        'pressure_gaseous': {
            'poundsForcePerSquareInch': 1,
            'pascal': 0.0001450377
        },
        'volume': {
            'cubicFeet': 1,
            'cubicMeters': 1 / 0.0283168
        },
        'mbh': {
            'kgallons': 1
        },
        'kbtu/ft²': {
			'kiloBtuPerSquareFoot': 1,
            'kiloBtusPerHour': 1,
            'btusPerHour': 1,
            'kiloWatt': 1 / 0.293
        },
        'volume_fluidic': {
			'kgallons': 1,
            'gallonsPerMinute': 1,
            'gallonPerMinutePerTon': 1,
            'cubicMetersPerSecond': 1 / 0.00006309
        },
        'hz': {
            'hertz': 1,
            'kiloHertz': 1e3,
            'cyclesPerMinute': 1 / 60,
            'megaHertz': 1e6,
            'cyclesPerHour': 1 / 3600
        },
        'lb/gal': {
			'poundsPerGallon': 1,
            'poundsForcePerSquareInch': 1,
            'kiloVoltAmpereReactive': 1,
            'voltAmpereReactive': 1,
            'megaVoltAmpereReactive': 1e3,
            'kiloGramsPerCubicMeter': 1 / 119.826
        },
        'reactive_power': {
            'poundsForcePerSquareInch': 1,
            'kiloVoltAmpereReactive': 1,
            'voltAmpereReactive': 1,
            'megaVoltAmpereReactive': 1e3
        },
        'length': {
            'foot': 1,
            'meter': 1 / 0.3048,
            'centimetre': 100 / 0.3048,
            'centimeter': 100 / 0.3048,
            'metre': 1 / 0.3048
        },
        'kw/ton': {
            'kiloWattPerTon': 1,
            'kiloWatt': 1 / 3.517
        }
    }

    function convertTemperature(value, fromUnit, toUnit) {
        if (fromUnit === 'celsius') {
            if (toUnit === 'fahrenheit' ) {
                return (value * 9 / 5) + 32
            } else if (toUnit === 'kelvin') {
                return value + 273.15
            }
        } else if (fromUnit === 'fahrenheit') {
            if (toUnit === 'celsius') {
                return (value - 32) * 5 / 9
            } else if (toUnit === 'kelvin') {
                return (value - 32) * 5 / 9 + 273.15
            }
        } else if (fromUnit === 'kelvin') {
            if (toUnit === 'celsius') {
                return value - 273.15
            } else if (toUnit === 'fahrenheit') {
                return (value - 273.15) * 9 / 5 + 32
            }
        } else if (fromUnit === 'deltaDegreesCelsius' || fromUnit === 'deltaDegreesKelvin') {
            if (toUnit === 'deltaDegreesFahrenheit') {
                return value * 9 / 5
            } else if (toUnit === 'deltaDegreesKelvin' || toUnit === 'deltaDegreesCelsius') {
                return value
            }
        } else if (fromUnit === 'deltaDegreesFahrenheit') {
            if (toUnit === 'deltaDegreesCelsius' || toUnit === 'deltaDegreesKelvin') {
                return value * 5 / 9
            }
        }
        return value
    }

    if (unitIn in conversionFactors['temperature'] && unitOut in conversionFactors['temperature']) {
        return convertTemperature(val, unitIn, unitOut)
    }

    if (unitIn in conversionFactors['temperature_delta'] && unitOut in conversionFactors['temperature_delta']) {
        return convertTemperature(val, unitIn, unitOut)
    }

    if (!(unitCategory in conversionFactors)) {
        console.log("convert_units: Category not present", unitCategory)
    } else {
        const units = conversionFactors[unitCategory]
        if (unitIn in units && unitOut in units) {
            return val * units[unitIn] / units[unitOut]
        } else {
            console.log(`convert_units: Units not present in category ${unitCategory} ${unitIn} to ${unitOut} not supported`)
        }
    }

    return val
}

