import { useState, useCallback, useEffect } from 'react'
import MultiTabForm from '../MultiTabTorm'
import { TabsWrapper } from '../../styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import { WidgetBuildingSchema, WidgetOverview, WidgetOverviewSchema } from '../WidgetOverview'
import {
  ImageWidgetConfiguration,
  ImageWidgetConfigurationSchema
} from './ImageWidgetConfiguration'
import WidgetPreview from '../preview/WidgetPreview'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../graphql'
import { useMutation } from 'src/hooks/APIHooks'
import {
  MODAL_TYPE,
  NO_IMAGE,
  WIDGET_TABS_NAME,
  getFileForUpsert,
  getImageWidgetCreateMutationInputs
} from '../../helpers'
import { useAttachment } from 'src/hooks/attachment'
import {
  CREATE_ATTACHMENT,
  CREATE_ENTRY_WITH_ATTACHMENT,
  UPDATE_ATTACHMENT,
  DELETE_ATTACHMENT
} from 'src/common/queries/attachment'
import { ACTIONS } from 'src/constants/actions'
import _isEmpty from 'lodash/isEmpty'
import { updateFileNameWithEpoch } from 'src/common/helperFunctions.js'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate from 'src/common/translations'

const tabsConfig = (errors) => [
  {
    title: WIDGET_TABS_NAME.OVERVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.OVERVIEW,
    formikErrors: errors,
    validationSchema: WidgetOverviewSchema,
    component: <WidgetOverview />
  },
  {
    title: WIDGET_TABS_NAME.CONFIGURATION,
    disabled: false,
    key: WIDGET_TABS_NAME.CONFIGURATION,
    formikErrors: errors,
    validationSchema: ImageWidgetConfigurationSchema,
    component: <ImageWidgetConfiguration />
  },
  {
    title: WIDGET_TABS_NAME.PREVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.PREVIEW,
    component: <WidgetPreview />
  }
]

const ImageTextWidgetForm = ({ setWidgetFormik }) => {
  const {
    widgetBuilding,
    setWidgetBuilding,
    setIsLoading,
    setReloadEvents,
    setOpenWidgetPicker,
    setIsOpenCreateWidget,
    organizationId,
    widgetSalesOfficeId,
    widgetDetails,
    mode,
    userInfo,
    formikErrors,
    setWidgetDetails,
    setModalType,
    setSelectedWidget,
    setIsSubmitted,
    setIsFormValidationAlone,
    isFormValidationAlone,
    setWidgetId,
    isFooterChecked
  } = useWidgetsContext()
  const [activeTab, setActiveTab] = useState(0)
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const { downloadFileURL,copyFileFunc } = useAttachment()
  const onSelectTab = useCallback((selectedTab: any, selectedTitle: any) => {
    setActiveTab(selectedTab)
    if (selectedTitle === WIDGET_TABS_NAME.PREVIEW){
      setIsFormValidationAlone(true)
      setIsSubmitted(false)
    }
  }, [])

  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: [ACTIONS.ADD,ACTIONS.COPY].includes(mode) ? CREATE_WIDGET : UPDATE_WIDGET,
    refreshTokenBuildingVariable: "input.buildingId",
    onError: (error) => {
      setError(error)
      setIsLoading(false)
    },
    onSuccess: (success) => {
      if([ACTIONS.ADD].includes(mode))
        {
          trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Create", type: "Image/Text Link"})
        }
      else if([ACTIONS.COPY].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Copy", type: "Image/Text Link"})
      }
      else{
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Edit", type: "Image/Text Link"})
      }
    }
  })

  const { onSubmit: createAttachmentMutation } = useMutation({
    query: CREATE_ATTACHMENT
  })

  const { onSubmit: createEntryWithAttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })

  const { onSubmit: updateAttachmentMutation } = useMutation({
    query: UPDATE_ATTACHMENT
  })

  const { onSubmit: deleteAttachmentMutation } = useMutation({
    query: DELETE_ATTACHMENT
  })

  const handleUpsertAttachment = async (newFile,deleteFile,values,widgetId) =>{
    const attachmentBody = {
      modelType: 'Widget',
      attachments: {}
    }
    // Adding Image
    if (newFile && values?.image?.imageUrl) {
      let fileName =  newFile?.name
      if ((mode === ACTIONS.COPY && newFile?.buildingId) || (mode === ACTIONS.EDIT && newFile?.buildingId !== widgetDetails?.buildingId)) {
        fileName = updateFileNameWithEpoch(newFile?.displayName)
        await copyFileFunc(newFile?.name, widgetDetails?.buildingId, newFile?.buildingId, fileName)
      }
      await createAttachmentMutation({
        input: {
          buildingId: newFile?.buildingId,
          name: fileName,
          widgetId,
          sizeInBytes: newFile?.size,
          type: newFile?.type,
          displayName: newFile?.displayName
        }
      })
      attachmentBody.attachments['create'] = [
        {
          buildingId: newFile?.buildingId,
          name: fileName
        }
      ]
    }
    //Delete Image
    if (deleteFile?.imageId) {
      await deleteAttachmentMutation({
        input: {
          id: deleteFile?.imageId
        }
      })
      attachmentBody.attachments['delete'] = [
        {
          attachmentId: deleteFile?.imageId,
          buildingId: deleteFile?.buildingId,
          name: deleteFile?.name
        }
      ]
    }
    //Update S3
    if(!_isEmpty(attachmentBody?.attachments)){
      await createEntryWithAttachmentMutation({
        input: JSON.stringify(attachmentBody)
      })
    }   
  }
  const handleUpsert = async (fileUpsertDetails,inputs,values) =>{
    let widgetId = null
    const newFile = fileUpsertDetails?.newFile ?? null
    const updateFile = fileUpsertDetails?.updateFile ?? null
    const deleteFile = fileUpsertDetails?.deleteFile ?? null
    await upsertWidgetMutation({ input: inputs }).then(async (response) => {
      widgetId =
      [ACTIONS.ADD,ACTIONS.COPY].includes(mode)
          ? response.data?.createWidget?.id
          : response.data?.updateWidget?.id
      if (true) {
        
        if(widgetDetails?.buildingId === values?.widgetBuilding){ 
          // Create Attachments For Same building 
          if(newFile)
            newFile['buildingId'] = widgetBuilding?.id
          if(deleteFile)
            deleteFile['buildingId'] = widgetBuilding?.id
          await handleUpsertAttachment(newFile, deleteFile, values, widgetId)
        }else{
          // Create Attachments For Different building 
         const widgetImg = widgetDetails?.widgetImage?.items[0] ?? null
          if(updateFile){
            if(widgetImg){
              const newImgFile = {
                buildingId: widgetBuilding?.id,
                name: widgetImg?.name,
                size: widgetImg?.sizeInBytes,
                type: widgetImg?.type,
                displayName: widgetImg?.displayName
              }
              const deleteExistingFile = {
                imageId: widgetImg?.id,
                buildingId: widgetDetails?.buildingId,
                name: widgetImg?.name
              }
              await handleUpsertAttachment(newImgFile, deleteExistingFile, values, widgetId)
            }           
          }else{
            if(newFile)
              newFile['buildingId'] = widgetBuilding?.id
            if(deleteFile)
              deleteFile['buildingId'] = widgetDetails?.buildingId
            if(newFile || deleteFile){
              await handleUpsertAttachment(newFile, deleteFile, values, widgetId)
            }
          }
        }
       }
    })
    return widgetId
  }

  const submitForm = async (values) => {
    if (!widgetBuilding?.id || isFormValidationAlone) return
    const fileUpsertDetails = getFileForUpsert(
      formValues['image'] ?? null,
      values.image,
      mode
    )

    setIsLoading(true)
    const inputs = getImageWidgetCreateMutationInputs(
      values,
      widgetBuilding?.id,
      organizationId,
      widgetSalesOfficeId,
      userInfo,
      mode
    )
    const widgetId = await handleUpsert(fileUpsertDetails,inputs,values)
    if(widgetId){
      setIsLoading(false)
      setWidgetDetails(inputs)
      setOpenWidgetPicker(false)
      setReloadEvents(true)
      setModalType(MODAL_TYPE.SUCCESS)
      setSelectedWidget(null)
      setIsOpenCreateWidget(isFooterChecked ?? false)
      setWidgetId(widgetId)
    }
    
  }
  const copyof = translate("Copy of")
  useEffect(() => {
    switch (mode) {
      case ACTIONS.ADD:
        setFormValues({
          id: '',
          name: '',
          description: '',
          link: '',
          isNewWindow: true,
          widgetBuilding:''
        })
        break
      case ACTIONS.EDIT:
      case ACTIONS.COPY:
        const widgetImage = widgetDetails?.widgetImage?.items
          ? widgetDetails?.widgetImage?.items[0]
          : null
        const img = {
          name: widgetImage?.name,
          displayName: widgetImage?.displayName,
          imageId: widgetImage?.id,
          // NO_IMAGE Is Used For Skip Configuration Validation Schema While Page Will Load ON Edit Or Copy Mode
          imageUrl: widgetImage?.name ? NO_IMAGE : '',
          original: widgetImage?.name ? NO_IMAGE : null
        }
        setFormValues({
          image: { ...img },
          imageUrl: img.imageUrl,
          buildingId: widgetImage?.buildingId,
          id: mode === ACTIONS.EDIT ? widgetDetails?.id : '',
          name: mode === ACTIONS.EDIT ? widgetDetails?.name : `${copyof} ${widgetDetails?.name}`,
          description: widgetDetails?.description ?? '',
          link: widgetDetails?.widgetLink,
          isNewWindow: widgetDetails?.isNewWindow,
          imageDescription: widgetDetails?.imageAdditionalText ?? '',
          imageTitle: widgetDetails?.imageText ?? '',
          widgetBuilding: widgetDetails?.widgetBuildingId
        })
        setWidgetBuilding({ id: widgetDetails?.buildingId, name:widgetDetails?.building?.name })
        break
    }
  }, [mode, copyof])
  const displayForm = () => {
    return mode !== ACTIONS.ADD ? formValues?.name : true
  }

  return (
    <>
      {displayForm() && (
        <MultiTabForm
          initialValues={formValues}
          onSubmit={submitForm}
          schemas={[WidgetOverviewSchema, WidgetBuildingSchema, ImageWidgetConfigurationSchema]}
          setWidgetFormik={setWidgetFormik}
        >
          <TabsWrapper>
            <Tabs
              defaultActiveTab={activeTab}
              activeTab={activeTab}
              onSelect={onSelectTab}
            >
              {tabsConfig(formikErrors).map((item: any, key: number) => {
                return (
                  <Tab
                    title={item.title}
                    disabled={item.disabled}
                    key={key}
                    formikErrors={item?.formikErrors}
                    validationSchema={item?.validationSchema}
                  >
                    {item.component}
                  </Tab>
                )
              })}
            </Tabs>
          </TabsWrapper>
          <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
        </MultiTabForm>
      )}
    </>
  )
}

export default ImageTextWidgetForm
