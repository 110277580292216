import { ACTIONS } from './constants'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import { DEVICES_WITH_UPPERCASE_SERIAL } from 'src/components/legacy/common/connection-types'
import moment from 'moment'
import { getField } from 'src/components/legacy/common/helpers'

export const DATE_FORMAT = 'MM/DD/YYYY'

export const BUILDING_AREA_UNITS_CONVERSION = {
  squareMetre(value) {
    return value * 10.7639
  }
}

export const mapLocationDetailsRequest = (values) => {
  values = { ...values }

  // Delete redundant fields which are used on UI side
  delete values.connectivityTypes
  delete values.weatherData
  delete values.weather
  delete values.offeringsString
  delete values.createdOn
  delete values.locationTags
  delete values.floorArea

  values.offeringSourceMaps = (values.offeringSourceMaps || [])
    .filter((offering) => offering.checked)
    .map(
      ({
        name,
        shortName,
        activeStatus,
        startDate,
        expirationDate,
        subdomain
      }) => ({
        name,
        shortName,
        startDate: moment(startDate, DATE_FORMAT),
        expirationDate: moment(expirationDate, DATE_FORMAT),
        activeStatus,
        ...(subdomain ? { subdomain } : {})
      })
    )

  values.salesOffice = getField(values, 'salesOffice.tisTraneOfficeId')
    ? values.salesOffice
    : null
  values.locationTimeZone = values.locationTimeZone || moment.tz.guess()
  values.organizationId = Number(values.organizationId)

  values.locationId && (values.locationId = Number(values.locationId))
  values.floorAreaSquareFeet = values.floorAreaSquareFeet
    ? Number(values.floorAreaSquareFeet)
    : 0
  if (
    values.floorAreaSquareFeet &&
    typeof BUILDING_AREA_UNITS_CONVERSION[values.floorAreaUnits] === 'function'
  ) {
    values.floorAreaSquareFeet =
      Math.round(
        BUILDING_AREA_UNITS_CONVERSION[values.floorAreaUnits](
          values.floorAreaSquareFeet
        ) * 10000
      ) / 10000
  }
  delete values.floorAreaUnits
  return values
}

export const prepareBeforeSave = (values) => {
  const { newLocationId } = this
  // excludes all empty devices that was added by user
  const devices = _get(values, 'locationConnectivity.devices', [])
    .map((device) => _omit(device, 'status.active'))
    .map((device) => _omit(device, 'new'))
    .map((device) => _omit(device, 'registered'))
    .map((device) => {
      if (
        DEVICES_WITH_UPPERCASE_SERIAL.includes(device?.device?.deviceType) &&
        device?.device?.identifier.toUpperCase() !== device?.device?.identifier
      ) {
        device.device.identifier = device.device.identifier.toUpperCase()
      }
      return device
    })
    .filter(
      ({ device: { deviceType, identifier } }) => deviceType && identifier
    )
  const locationConnectivity = { devices }
  const locationId = values.locationId || newLocationId
  const salesOffice = _omit(values.salesOffice, '__typename')
  const variables = mapLocationDetailsRequest({
    ...values,
    locationConnectivity,
    locationId,
    salesOffice
  })
  return variables
}

export const skipTermsAndSubmit = () => {
  const { editAction, updateLocationDetails, addNewLocation } = this.props
  const { values } = this.state?.building
  const variables = prepareBeforeSave(values)

  editAction === ACTIONS.EDIT
    ? updateLocationDetails({ variables })
    : addNewLocation({ variables })

  // this.trackOnSubmit(values, initialValues)

  // this.setState({ warningMessage })
}

export const onSubmitForm = () => {
  const { editAction } = this.props
  if (!editAction) {
    return null
  }
  // this.setState({ building: { values, warningMessage, initialValues } })
  editAction === ACTIONS.EDIT
    ? this.skipTermsAndSubmit()
    : this.setState({ showTermsConditionsModal: true })
}
