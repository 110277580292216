import { memo, useEffect } from 'react'
import { plotAreaID } from './helper'
import {
  selectChartInstanceId,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'

// Enhancement is in progress. Currently static
const OrderModifierSetup = (props: any) => {
  const { canvasIndex } = props
  const chartInstanceId = useSelector(selectChartInstanceId)

  const plotLines = `plot-line-${plotAreaID(chartInstanceId, canvasIndex)}`
  const plotBands = `plot-band-${plotAreaID(chartInstanceId, canvasIndex)}`

  return <g clipPath={`url(#${plotAreaID(chartInstanceId, canvasIndex)})`}>
    <use xlinkHref={`#${plotBands}`} />
    <use xlinkHref={`#${plotLines}`} />
  </g>
}

export default memo(OrderModifierSetup)
