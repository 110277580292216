import React from 'react'
import './spinner.scss'

const Spinner = ({ className }) => (
  <div className={`section-spinner ${className}`}>
    <span className="icon icon-loader spin-wheel" />
  </div>
)

export default Spinner
