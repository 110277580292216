import { useEffect, useState } from "react";
import CurveChart from "./CurveChart";

export default function CharacteristicsCurve({
    onPointSelection,
    characteristicCurvePointList,
    onPointsUpdate,
    xAxisRange,
    yAxisRange
}) {
    const [points, setPoints] = useState([])
    useEffect(() => {
        const points = characteristicCurvePointList?.filter(point => point.curveXAxisValue && point.curveYAxisValue)
                        .map(point => [point.curveXAxisValue, point.curveYAxisValue])
        setPoints(points)
    }, [characteristicCurvePointList])
    return <CurveChart 
        onPointSelection={onPointSelection}
        onPointsUpdate={onPointsUpdate}
        dataPoints={points || []}
        xAxisRange={[xAxisRange.from, xAxisRange.to]}
        yAxisRange={[yAxisRange.from, yAxisRange.to]}
     />
}