import { useState, useCallback, useEffect } from 'react'
import MultiTabForm from '../../MultiTabTorm'
import { TabsWrapper } from '../../../styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import { EnergyDemandWidgetPreviewWrapper } from './EnergyDemandWidgetPreviewWrapper'
import {
  WidgetBuildingSchema,
  WidgetOverview,
  WidgetOverviewSchema
} from '../../WidgetOverview'
import {
  EnergyDemandConfigurationSchema
} from './EnergyDemandConfiguration'
import { useWidgetsContext } from '../../../WidgetsContextProvider'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import {
  MODAL_TYPE,
  NONE,
  TIME_PAST_HOUR,
  WIDGET_TABS_NAME,
  getEnergyDemandWidgetUpsertMutationInputs,
  isValidRateIncrease
} from '../../../helpers'
import { ACTIONS } from 'src/constants/actions'
import EnergyDemandColors from './EnergyDemandColors'
import {
  BAR3_COLOR,
  BAR1_COLOR,
  TARGET_COLOR
} from '../../../colors'
import { useMutation } from 'src/hooks/APIHooks'
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../../graphql'
import { EnergyDemandConfiguration } from './EnergyDemandConfiguration'
import { v4 as uuidv4 } from 'uuid'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate from 'src/common/translations'

const tabsConfig = (errors) => [
  {
    title: WIDGET_TABS_NAME.OVERVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.OVERVIEW,
    formikErrors: errors,
    validationSchema: WidgetOverviewSchema,
    component: <WidgetOverview />
  },
  {
    title: WIDGET_TABS_NAME.CONFIGURATION,
    disabled: false,
    key: WIDGET_TABS_NAME.CONFIGURATION,
    formikErrors: errors,
    validationSchema: EnergyDemandConfigurationSchema,
    component: <EnergyDemandConfiguration />
  },
  {
    title: WIDGET_TABS_NAME.COLORS,
    disabled: false,
    key: WIDGET_TABS_NAME.COLORS,
    formikErrors: errors,
    component: <EnergyDemandColors />
  },
  {
    title: WIDGET_TABS_NAME.PREVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.PREVIEW,
    formikErrors: errors,
    component: <EnergyDemandWidgetPreviewWrapper />
  }
]

const EnergyDemandForm = ({ setWidgetFormik }) => {
  const {
    widgetBuilding,
    setOpenWidgetPicker,
    userInfo,
    setReloadEvents,
    setIsLoading,
    mode,
    widgetDetails,
    setWidgetDetails,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setModalType,
    formikErrors,
    objectPickerSelectedValue,
    setWidgetBuilding,
    setIsFormValidationAlone,
    setIsSubmitted,
    isFormValidationAlone,
    setWidgetId,
    isFooterChecked
  } = useWidgetsContext()
  const [activeTab, setActiveTab] = useState(0)
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const onSelectTab = useCallback((selectedTab: any, selectedTitle: any) => {
    setActiveTab(selectedTab)
    if (selectedTitle === WIDGET_TABS_NAME.PREVIEW){
      setIsFormValidationAlone(true)
      setIsSubmitted(false)
    }
  }, [])
  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: [ACTIONS.ADD,ACTIONS.COPY].includes(mode) ? CREATE_WIDGET : UPDATE_WIDGET,
    refreshTokenBuildingVariable: "input.buildingId",
    onError: (error) => {
      setError(error)
      setIsLoading(false)
    },
    onSuccess: (success) => {
      if([ACTIONS.ADD].includes(mode))
        {
          trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Create", type: "Energy Demand"})
        }
      else if([ACTIONS.COPY].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Copy", type: "Energy Demand"})
      }
      else{
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Edit", type: "Energy Demand"})
      }
    }
  })


  const submitForm = async (values,{setFieldValue}) => {
    if (objectPickerSelectedValue?.length === 0 || !widgetBuilding?.id || isValidRateIncrease(values?.rateIncrease, values?.isRateIncrease, setFieldValue) || isFormValidationAlone) return 
    setIsLoading(true)
    const inputs = getEnergyDemandWidgetUpsertMutationInputs(
      values,
      userInfo,
      objectPickerSelectedValue,
      mode
    )
    await upsertWidgetMutation({ input: inputs }).then((response) => {
      const widgetId =
      [ACTIONS.ADD,ACTIONS.COPY].includes(mode)
          ? response.data?.createWidget?.id
          : response.data?.updateWidget?.id
      if (widgetId) {
        setIsLoading(false)
        setWidgetDetails(inputs)
        setOpenWidgetPicker(false)
        setReloadEvents(true)
        setModalType(MODAL_TYPE.SUCCESS)
        setSelectedWidget(null)
        setIsOpenCreateWidget(isFooterChecked ?? false)
        setWidgetId(widgetId)
      }
    })
  }

  const getRateIncrease = (data) =>{
    const rateIncreaseList = []
    data?.forEach(rate => {
      rateIncreaseList.push({ name: rate?.name, startTime: rate?.rateIncreaseStart, endTime: rate?.rateIncreaseEnd, key: uuidv4() })
    })
    return rateIncreaseList
  }
  const copyof = translate("Copy of")
  useEffect(() => {
    switch (mode) {
      case ACTIONS.ADD:
        setFormValues({
          id: '',
          name: '',
          description: '',
          link: '',
          widgetLinkType: 'None',
          isNewWindow: true,
          widgetBuilding: '',
          timePeriod:TIME_PAST_HOUR,
          comparedTo:NONE,
          isRateIncrease: false,
          currentDemandColor: BAR1_COLOR,
          peakDemandColor: TARGET_COLOR,
          rateIncreaseOverlayColor: BAR3_COLOR,
          rateIncreaseErrorMsg:'',
          rateIncrease: []
        })
        break
      case ACTIONS.EDIT:
      case ACTIONS.COPY:
        setFormValues({
          id: mode === ACTIONS.EDIT ? widgetDetails?.id : '',
          name: mode === ACTIONS.EDIT ? widgetDetails?.name : `${copyof} ${widgetDetails?.name}`,
          description: widgetDetails?.description ?? '',
          link: widgetDetails?.customUrl,
          widgetLinkType:widgetDetails?.widgetLink,
          isNewWindow: widgetDetails?.isNewWindow,
          timePeriod: widgetDetails?.energyDemandWidget?.timePeriod,
          comparedTo: widgetDetails?.energyDemandWidget?.comparedTo,
          isRateIncrease: widgetDetails?.energyDemandWidget?.isRateIncrease,
          rateIncrease: getRateIncrease(widgetDetails?.energyDemandWidget?.rateIncrease ?? []),
          currentDemandColor: widgetDetails?.energyDemandWidget?.colourConfig?.currentDemand,
          peakDemandColor: widgetDetails?.energyDemandWidget?.colourConfig?.peakDemand,
          rateIncreaseOverlayColor: widgetDetails?.energyDemandWidget?.colourConfig?.overlayDemand,
          rateIncreaseErrorMsg:''
        })
        setWidgetBuilding({ id: widgetDetails?.buildingId })
        break
    }
  }, [mode, copyof])

  const displayForm = () => {
    return mode !== ACTIONS.ADD ? formValues?.name : true
  }

  return (
    <>
      {displayForm() && (
        <MultiTabForm
          initialValues={formValues}
          onSubmit={submitForm}
          schemas={[
            WidgetOverviewSchema,
            WidgetBuildingSchema,
            EnergyDemandConfigurationSchema
          ]}
          setWidgetFormik={setWidgetFormik}
        >
          <TabsWrapper>
            <Tabs
              defaultActiveTab={activeTab}
              activeTab={activeTab}
              onSelect={onSelectTab}
            >
              {tabsConfig(formikErrors).map((item: any, key: number) => {
                return (
                  <Tab
                    title={item.title}
                    disabled={item.disabled}
                    key={key}
                    formikErrors={item?.formikErrors}
                    validationSchema={item?.validationSchema}
                  >
                    {item.component}
                  </Tab>
                )
              })}
            </Tabs>
          </TabsWrapper>
          <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
        </MultiTabForm>
      )}
    </>
  )
}

export default EnergyDemandForm
