import PageHeader from '../../../components/pageHeaderNew/header'
import HistoryTitleSection from './components/HistoryTitleSection'
import HistoryFilterSection from './components/HistoryFilterSection'
import HistoryTableSection from './components/HistoryTableSection'
import {
  ContentWrapper,
  Container,
  HistorySharedStyles
} from './components/Styled'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from 'src/hooks/APIHooks'
import { GET_BUILDING_DETAILS } from 'src/pages/automatedTestSettings/graphql'
import { getSearchParams } from '../../../common/helperFunctions'
import moment from 'moment'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import { CSVLink } from 'react-csv'
import { getCSVForATHistory } from './helpers'
import { useDispatch } from 'react-redux'
import { setAutomatedSettingEquipment } from 'src/redux/slicers/buildingPicker'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { useSearchParams } from 'react-router-dom'
import translate, { TranslateComponent } from 'src/common/translations'

export default function History() {
  const [denaliSelectorSearchParams] = useSearchParams()
  const selectedAt = useSelector(
    (state: any) => state?.buildingPicker?.selctedAtHistory
  )
  const {automatedSettingEquipment} = useSelector((state: any) => state?.buildingPicker)
  const searchParams = getSearchParams()
  const buildingId = searchParams?.buildingId
  const [selectedKey, setSelectedKey] = useState(selectedAt)
  const [startDate, setStartDate] = useState(moment().startOf('year').format(DATE_FORMAT))
  const currentDate = moment().format(DATE_FORMAT)
  const [historyData, setHistoryData] = useState<any>({ keys: [], values: [] })
  const [endDate, setEndDate] = useState(currentDate)
  const dispatch = useDispatch()
  const { data: buildingDetails, refetch: getBuildingDetails } = useQuery({
    query: GET_BUILDING_DETAILS,
    variables: { id: buildingId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getBuilding'
  })

  useEffect(() => {
    if (buildingId) {
      getBuildingDetails()
    }
  }, [buildingId, denaliSelectorSearchParams])

  useEffect(() => {
    trackEvent(USER_EVENTS.BUILDING_DATA.events.VIEW_HISTORY_AUTOMATED_TEST)
    return(() => {
      dispatch(setAutomatedSettingEquipment(null))
    })
   },[])

  const dateAndTime =translate("Date And Time")
  const automatedTest =translate("Automated Test")
  const updatedBy =translate("Updated By")
  const organization =translate("Organization")
  const building =translate("Building")
  const equipmwnt =translate("Equipment")
  const settingLevel =translate("Setting Level")
  const comments =translate('Comments')

  const getCSV = getCSVForATHistory(historyData)
   const titleTranslateArray = [dateAndTime,automatedTest,updatedBy,organization,building,equipmwnt,settingLevel,comments]

  const titleTranslate = getCSV?.map((item, index)=> index===0 ? titleTranslateArray: item)
   
  return (
    <HistorySharedStyles>
      <Container>
        <PageHeader
          hideLine
          pageTitle={<div><TranslateComponent>Automated Test Settings History</TranslateComponent></div>}
          titleTools={
            historyData?.keys?.length > 0 && (
              <CSVLink
                data={titleTranslate}
                filename={`${historyData.values?.[0].automatedTest}.csv`}
                className="csv-link"
              >
                <div className="ats-title-action-toolbar">
                  <button onClick={()=>{trackEvent(USER_EVENTS.BUILDING_DATA.events.EXPORT_AUTOMATED_TEST_SETTINGS)}} className="action small margin-right">
                    <TranslateComponent>Export Data</TranslateComponent>
                  </button>
                </div>
              </CSVLink>
            )
          }
        />
        <ContentWrapper className="gap-top-bottom">
          <HistoryTitleSection
            buildingName={buildingDetails?.name}
            organizationName={buildingDetails?.accountName}
            equipmentDetail= {automatedSettingEquipment}
          />
          <HistoryFilterSection
            setSelectedKey={setSelectedKey}
            selectedKey={selectedKey}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            buildingId={buildingDetails?.buildingId}
            equipmentDetail= {automatedSettingEquipment}
          />
          <HistoryTableSection
            setHistoryData={setHistoryData}
            selectedKey={selectedKey}
            buildingId={buildingDetails?.buildingId}
            buildingName={buildingDetails?.name}
            organizationName={buildingDetails?.accountName}
            startDate={startDate}
            endDate={endDate}
            equipmentDetail= {automatedSettingEquipment}
          />
        </ContentWrapper>
      </Container>
    </HistorySharedStyles>
  )
}
