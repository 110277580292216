import { useMemo, useRef, useState } from 'react'
import { useOptions } from './useOptions'
import Axes from './Axes'
import BaseWrapper from '../../CommonComponents/BaseWrapper'
import Series from './Series'
import Svg from '../../CommonComponents/Svg'
import { Tooltip } from '../../CommonComponents/Bar/constants'
import ToolTip from '../../CommonComponents/Bar/Tooltip/Tooltip'
import "./styles.scss"

export default function EnergyUsageReportChart(props) {

  // API flow - 1
  // Once Data is recevied from API. Pass this to this memo, which returns series data required for bar
  const barChartData = useMemo(
    () => props?.data ?? [],
    [props?.data]
  )
  const [tooltip, setTooltip] = useState<Tooltip | null>(null);
  const options = useOptions(barChartData, props?.isSmallWindow)

  const svgRef: any = useRef(null)

  return (
    <BaseWrapper options={options} className={props.className ? props.className : 'horizontal-bar-chart-dimention'}>
      <Svg ref={svgRef}>
        {barChartData?.barData?.length &&
          <>
            <Axes xtickLabel={props.xtickLabel} />
            <Series series={barChartData} setTooltip={setTooltip} />
          </>

        }
      </Svg>
      {tooltip !== null ? <ToolTip tooltip={tooltip} /> : null}
    </BaseWrapper>
  )
}
