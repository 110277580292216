import { createContext, useContext, useEffect, useState } from 'react'
import { getSearchParams } from 'src/common/helperFunctions.js'

const AutomatedTestSettingsContext = createContext<any>(null)

export const AutomatedTestSettingsContextProvider = ({ children }: any) => {
  const [mode, setMode] = useState(null)
  const [tisObjectIdCtx, setTisObjectId] = useState(null)
  const [buildingIdCtx, setBuildingId] = useState(null)
  const { tisObjectId, buildingId } = getSearchParams()
  useEffect(() => {
    setBuildingId(buildingId)
    setTisObjectId(tisObjectId)
  }, [buildingId, tisObjectId])

  return (
    <AutomatedTestSettingsContext.Provider
      value={{
        mode,
        setMode,
        buildingId:buildingIdCtx,
        tisObjectId:tisObjectIdCtx
      }}
    >
      {children}
    </AutomatedTestSettingsContext.Provider>
  )
}

export const useAutomatedTestSettingsContext = () =>
  useContext(AutomatedTestSettingsContext)
