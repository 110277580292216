import { useEffect } from 'react'
import {
  selectAxes,
  selectGeneratedScales,
  selectSeriesData,
  useSelector
} from '../../../BaseSetup/BaseSetup'
import { useSyncSeriesPointsForTooltip } from '../../../CommonComponents/TooltipNew'
import Path from '../../../CommonComponents/LinePath/LinePath'
import { generatorFunction } from '../../../Shapes/picker'
import { SCALE_BAND, SCALE_TIME } from '../../../Utils/Scales/constant'
import { isNumber } from 'lodash'

const isNil = (val) => val === undefined || val === null

// TODO:
// Used same functionality of line data point function as areaRange has more configuration on handling xy data
// let the developer to integarte it to pass data to tooltip layer
const dataPointFunction = (
  scaleType,
  scale: any,
  d: any = [],
  bandWidth = 0
) => {
  if (scaleType === SCALE_TIME) {
    return scale(new Date(d))
  } else if (scaleType === SCALE_BAND) {
    return scale(d) + bandWidth
  } else {
    return scale(isNumber(d) ? d : null)
  }
}

function areaTooltipModel(data, tooltipDataModel) {
  const {
    xScale = () => null,
    yScale = () => null,
    xscaleType,
    yscaleType,
    xData = [],
    yData = [],
    obj = {}
  } = data

  const {
    name,
    elementProps: { stroke = 'steelblue' },
    tooltipDataModelFormatter = () => null
  } = obj

  const bandWidth = xscaleType === 'scaleBand' ? xScale?.bandwidth() / 2 : 0

  return xData.reduce((av, xVal, i) => {
    const yVal = yData[i]

    const x = dataPointFunction(xscaleType, xScale, xVal, bandWidth)
    const y = dataPointFunction(yscaleType, yScale, yVal, bandWidth)
    const convertedN = parseFloat(x?.toFixed(3))
    if (isNil(x) || isNil(y)) return av

    const defaultProps = {
      label: name,
      value: yVal,
      title: xVal,
      x: convertedN,
      y,
      groupBy: 'title',
      color: stroke,
      xValue: xVal,
      yValue: yVal,
      higlightDots: true,
      // no of dots of a data point
      highlightDots: [
        {
          x,
          y,
          color: stroke
        }
      ]
    }

    const formatter = tooltipDataModelFormatter({
      ...defaultProps,
      ...obj
    })

    av.push(
      tooltipDataModel(x, {
        // Default model values
        ...defaultProps,

        // user modified values
        ...formatter
      })
    )

    return av
  }, [])
}

const AreaRenderer = (props: any) => {
  const { types = [] } = props
  const generatedScales = useSelector(selectGeneratedScales)
  const seriesData = useSelector(selectSeriesData)
  const axes = useSelector(selectAxes)

  const { addPoints, deletePoints, createTooltipPointModel } =
    useSyncSeriesPointsForTooltip()

  useEffect(() => {
    const inputModel = types?.reduce((av = [], obj: any, i: number) => {
      const xObject = (axes || [])?.find(
        (axesObj: any) => axesObj?.key === obj?.xAxisKey
      )
      const yObject = (axes || [])?.find(
        (axesObj: any) => axesObj?.key === obj?.yAxisKey
      )
      const gXScaleObj = generatedScales?.[obj?.xAxisKey]
      const gYScaleObj = generatedScales?.[obj?.yAxisKey]
      const yData = seriesData?.[obj?.seriesKey]?.data
      const xscaleType = xObject?.scale?.props?.type
      const yscaleType = yObject?.scale?.props?.type
      const xScale = gXScaleObj?.gScale
      const yScale = gYScaleObj?.gScale
      const xData = xObject?.scale?.categories

      if (obj?.type !== 'area') return av

      const lineModel = areaTooltipModel(
        {
          obj,
          xscaleType,
          yscaleType,
          xData,
          yData,
          xScale,
          yScale,
          invert: false,
          settings: obj?.settings,
          type: obj?.type,
          min: gYScaleObj?.domain?.[0]
        },
        createTooltipPointModel
      )

      return av.concat(lineModel)
    }, [])

    inputModel.length && addPoints(inputModel)

    return () => {
      inputModel.length && deletePoints(inputModel)
    }
  }, [generatedScales, types])

  return (
    <>
      {types?.map((obj: any, i: number) => {
        const xObject = (axes || [])?.find(
          (axesObj: any) => axesObj?.key === obj?.xAxisKey
        )
        const yObject = (axes || [])?.find(
          (axesObj: any) => axesObj?.key === obj?.yAxisKey
        )
        const gXScaleObj = generatedScales?.[obj?.xAxisKey]
        const gYScaleObj = generatedScales?.[obj?.yAxisKey]
        const yData = seriesData?.[obj?.seriesKey]?.data
        const xscaleType = xObject?.scale?.props?.type
        const yscaleType = yObject?.scale?.props?.type
        const xScale = gXScaleObj?.gScale
        const yScale = gYScaleObj?.gScale
        const xData = xObject?.scale?.categories
        const d = generatorFunction(obj?.type, {
          xscaleType,
          yscaleType,
          xData,
          yData,
          xScale,
          yScale,
          invert: false,
          settings: obj?.settings,
          properties: obj?.properties,
          type: obj?.type,
          min: gYScaleObj?.domain?.[0]
        })
        const lineD = generatorFunction('line', {
          xscaleType,
          yscaleType,
          xData,
          yData,
          xScale,
          yScale,
          invert: false,
          settings: obj?.settings,
          properties: obj?.properties,
          type: 'line',
          min: gYScaleObj?.domain?.[0]
        })
        return (
          <>
            <Path
              key={`area-${i}`}
              fill={obj?.elementProps?.fill}
              fillOpacity={obj?.elementProps?.fillOpacity || 0.3}
              d={d}
              stroke={'none'}
              strokeWidth={0}
            />
            <Path
              key={`line-${i}`}
              dashType={obj?.dashType}
              stroke={obj?.elementProps?.stroke}
              strokeWidth={obj?.elementProps?.strokeWidth}
              d={lineD}
            />
          </>
        )
      })}
    </>
  )
}

export default AreaRenderer
