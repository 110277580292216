import { memo, useEffect, useState } from 'react'
import { func, array, number } from 'prop-types'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import {
  TIME_FORMAT
} from '../../../../../components/legacy/common/time-helpers'
import TimePicker from '../../../../../components/time-picker/time-picker'
import { PanelLabel } from '../../../styles'
import { hasEmptyRangeItem, hasEndTimeBeforeStart, overlappingRangePeriods } from 'src/pages/widgets/helpers'
import { TranslateComponent } from 'src/common/translations'

const RATE_INCREASE_ITEM = {
  name: 'Rate increase 1',
  startTime: null,
  endTime: null
}

const RateIncreaseRange = (props) => {
  const [rateIncreases, setRateIncreases] = useState([])

  useEffect(() => {
    const { rateIncreaseChange, rateIncreasesData } = props

    if (!rateIncreasesData?.length) {
      const newRateIncreases = [{ ...RATE_INCREASE_ITEM, key: uuidv4() }]
      setRateIncreases(newRateIncreases)
      rateIncreaseChange(newRateIncreases)
    } else {
      setRateIncreases(rateIncreasesData)
    }
  }, [])



  const updateRateIncreaseNames = (rateIncreases) => {
    return rateIncreases?.map((el, i) => ({
      ...el,
      name: `Rate increase ${i + 1}`
    }))
  }



  const handleTimeRangeChange = (index, item) => {
    return (value: any) => {
      const { rateIncreaseChange, minuteStep } = props
      const rateIncreaseItem = rateIncreases[index]

      if (value !== null) {
        let minute = value.get('minute')
        if (minute % minuteStep !== 0) {
          minute = Math.floor(minute / minuteStep) * minuteStep
          value?.set({ minute })
        }
      }

      if (rateIncreaseItem) {
        const newRateIncreases = rateIncreases?.map((el, i) => {
          if (i === index && el.hasOwnProperty(item)) {
            el[item] =
              value ?? {} instanceof moment ? value?.format(TIME_FORMAT) : null
          }

          return el
        })

        setRateIncreases(newRateIncreases)
        rateIncreaseChange(newRateIncreases)
      }
    }
  }

  const addRateIncreaseTimeRange = () => {
    const { rateIncreaseChange, showError } = props
    const isEndTimeBeforeStart = hasEndTimeBeforeStart(rateIncreases)
    const isEmptyRangeItem = hasEmptyRangeItem(rateIncreases)
    const isOverlappingRangePeriods = overlappingRangePeriods(rateIncreases)

    if (isEmptyRangeItem || isOverlappingRangePeriods || isEndTimeBeforeStart) {
      if (isEmptyRangeItem) {
        showError('timeRequired')
      }
      if (isOverlappingRangePeriods) {
        showError('periodsOverlapping')
      }
      if (isEndTimeBeforeStart) {
        showError('endTimeBeforeStart')
      }
    } else {
      const newRateIncreases = [
        ...rateIncreases,
        {
          ...RATE_INCREASE_ITEM,
          name: `Rate increase ${rateIncreases.length + 1}`,
          key: uuidv4()
        }
      ]

      setRateIncreases(newRateIncreases)
	  showError('')
      rateIncreaseChange(newRateIncreases)
    }
  }
  const removeRateIncreaseTimeRange = (index) => {
    const { rateIncreaseChange } = props

    let newRateIncreases = rateIncreases?.filter((el, i) => i !== index)
    newRateIncreases = updateRateIncreaseNames(newRateIncreases)

    setRateIncreases(newRateIncreases)
    rateIncreaseChange(newRateIncreases)
  }

  return (
    <div className="rate-increase-block">
      <div className="rate-increase-item">
        <div className="label">
          <PanelLabel><TranslateComponent>Rate Increase</TranslateComponent></PanelLabel>
        </div>
        <div className="rate-increases-timepickers-labels">
          <div className="start-time">
            <PanelLabel><TranslateComponent>Rate Increase Start</TranslateComponent>*</PanelLabel>
          </div>
          <div className="end-time">
            <PanelLabel><TranslateComponent>Rate Increase End</TranslateComponent>*</PanelLabel>
          </div>
        </div>
      </div>

      {rateIncreases.map((el, i) => (
        <div key={`${el.key}`} className="rate-increase-item">
          <div className="label">
            <div><TranslateComponent>{el.name}</TranslateComponent></div>
          </div>
          <div className="rate-increases-timepickers">
            <div className={`start-time ${el.startTime ? 'filled' : ''}`}>
              <TimePicker
                minuteStep={props?.minuteStep?.toString()}
                format={TIME_FORMAT}
                onChange={handleTimeRangeChange(i, 'startTime')}
                defaultValue={
                  el.startTime ? moment(el.startTime, TIME_FORMAT) : void 0
                }
              />
            </div>
            <div className={`end-time ${el.endTime ? 'filled' : ''}`}>
              <TimePicker
                minuteStep={props?.minuteStep?.toString()}
                format={TIME_FORMAT}
                onChange={handleTimeRangeChange(i, 'endTime')}
                defaultValue={
                  el.endTime ? moment(el.endTime, TIME_FORMAT) : void 0
                }
              />
            </div>
          </div>

          <div className="buttons">
            {rateIncreases.length > 1 && (
              <span
                className="icon icon-delete-circle"
                onClick={() => removeRateIncreaseTimeRange(i)}
              />
            )}
            {(rateIncreases.length === 1 ||
              (rateIncreases.length - 1 === i && rateIncreases.length < 4)) && (
              <span
                className="icon icon-addcircle"
                onClick={() => addRateIncreaseTimeRange()}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

RateIncreaseRange.propTypes = {
  rateIncreaseChange: func.isRequired,
  showError: func,
  rateIncreasesData: array,
  minuteStep: number
}
RateIncreaseRange.defaultProps = {
  minuteStep: 15
}
export default memo(RateIncreaseRange)
