import * as yup from 'yup'
const REQUIRED = 'required'
const schema = (user) => yup.object().shape({
  jobTitle: yup.string().required(REQUIRED),
  firstName: yup.string().required(REQUIRED),
  lastName: yup.string().required(REQUIRED),
  industry: user?.type?.name === "BUILDING_OWNER" ? yup.string().required(REQUIRED) : yup.string().notRequired(),
  defaultUniqueAssetId: user?.type?.name === "TRANE_OR_INDEPENDENT" ? yup.string().required(REQUIRED) : yup.string().notRequired()
})

export default schema
