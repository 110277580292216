import colors from 'src/components/layouts/colors.json'
import XYChart from 'src/chart-library/Charts/XYChart'
import { SCALE_LINEAR } from 'src/chart-library/Utils/Scales/constant'
import moment from 'moment/moment'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import TimelineWithChart from './Timeline'
import { orderBy } from 'lodash'
import './styles.scss'
import { useMemo } from 'react'

const propertyAxis = {
  InstantaneousPower: {
    axis: 'y1',
    color: colors.BLUE
  },
  RealPower: {
    axis: 'y1',
    color: colors.BLUE
  }
}

function LoadAnalysisTooltipTemplate({ data, info }) {
  // data prop is alway array of object. Extracting first data from data prop
  if (!data.length) return null

  const { date, title, value } = data[0] || {}
  const { activeLocation = {}, activeObject = {}, symbol, max = 0 } = info || {}
  return (
    <div>
      <strong>
        {activeLocation ? activeLocation.name : ''}
        <br />
        {activeObject ? activeObject.value : ''}
      </strong>
      <hr />
      <div style={{ float: 'right' }}>
        <strong>{Math.round(value)}</strong>
      </div>
      <strong>
        Demand &ndash; Electric {symbol === 'kW' ? '(kW)' : '(kBTUs)'}
      </strong>
      <div></div>
      <div style={{ float: 'right' }}>
        <strong>{(Math.round(title * 10) / 10).toFixed(1)}</strong>
      </div>
      <strong>Time (%)</strong>
      <div></div>
      <br />
      For {(Math.round(title * 10) / 10).toFixed(1)}% of the time, Demand is
      higher
      <br />
      than {Math.round(value)} {symbol === 'kW' ? 'kW' : 'kBTUs'}.<br />
      <br />
      <strong>
        Reducing peak demand by {Math.round(max - value)}{' '}
        {symbol === 'kW' ? 'kW' : 'kBTUs'} will impact
        <br />
        site operations {(Math.round(title * 10) / 10).toFixed(1)}% of the time.
      </strong>
      <br />
      <br />
      Electricity Demand of {Math.round(value)}{' '}
      {symbol === 'kW' ? 'kW' : 'kBTUs'} occurred
      <br />
      at {date && format(new Date(date), 'h:mm a')} on{' '}
      {date && format(new Date(date), 'MM-dd-yyyy')}.
    </div>
  )
}

const LoadAnalysisChart = ({
  chartData,
  timeSliderChartData,
  loadingChartData,
  chartDates,
  unit,
  activeObject,
  activeLocation,
  setTimeSliderStartTime,
  selectedEquipment,
  setTimeSliderEndTime
}) => {
  const [peakDemand, setPeakDemand] = useState(null)
  const [categories, setCategories] = useState([])
  const [sortedData, setSortedData] = useState([])
  useEffect(() => {
    let dataLength =
      (chartData?.timeStamps && chartData?.timeStamps?.length) || 1
    setCategories(
      chartData?.timeStamps?.map((point, i) => (i / dataLength) * 100) || []
    )
    const getPeakDemand = (chartData) => {
      let maxPeakObject = {
        timeStamp: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        value: null
      }
      if (chartData?.data?.length) {
        const filterData = chartData?.data?.filter((property) => {
          if (['InstantaneousPower','RealPower'].includes(property?.propertyKey)) {
            return property
          }
        })
        if (
          filterData.length > 0 &&
          filterData[0] &&
          filterData[0].values &&
          filterData[0].values[0].length > 0
        ) {
          const data = filterData[0]?.values[0]?.map((dataPoint) => {
            return {
              timeStamp: dataPoint?.timeStamp,
              value:
                dataPoint?.value && Number(dataPoint?.value)
                  ? Number(dataPoint?.value)
                  : Number.NEGATIVE_INFINITY
            }
          })
          const sortedData = orderBy(data, ['value'], ['desc'])
          setSortedData(sortedData)
          maxPeakObject =
            sortedData && sortedData[0] ? sortedData[0] : maxPeakObject
        }
      }
      return maxPeakObject
    }
    const maxPeakDemand = chartData && getPeakDemand(chartData)
    const peakDemandValue = maxPeakDemand?.value
      ? +maxPeakDemand?.value : 0
    setPeakDemand(peakDemandValue)
  }, [chartData, unit])

  const createXAxis = () => {
    return [
      {
        key: 'x1',
        scale: {
          props: {
            type: 'scaleLinear',
            paddingNotRequired: true,
            defaultRange: [0, 100]
          },
          categories: categories,
          scaleSettings: {}
        },
        axis: {
          visibleAt: ['bottomBottom'],
          hideTicks: true,
          hideLine: false,
          format: (value) => parseFloat(value)?.toFixed(0),
          name: {
            symbol: '% of time'
          }
        }
      }
    ]
  }

  const createYAxis = () => {
    return [
      {
        key: 'y1',
        scale: {
          props: {
            rangeFixed: true,
            type: SCALE_LINEAR
          }
        },
        axis: {
          name: {
            symbol: unit
          },
          hideLine: true,
          hideTicks: true,
          notD3: true,
          visibleAt: ['leftLeft'] // Optional,
        },
        grid: [
          {
            stroke: 'lightgray',
            includeAxis: true
          }
        ]
      }
    ]
  }

  const getLegends = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    const legends = []
    for (let i = 0; i < lineChartData?.data?.length; i++) {
      legends.push({
        id: i + 1,
        name: lineChartData?.data[i].propertyName,
        seriesKey: i + 1,
        elementProps: {
          stroke: propertyAxis[lineChartData?.data[i].propertyKey]?.color,
          strokeWidth: 1,
          fill: colors.BLUE,
          fillOpacity: 0.9
        },
        xAxisKey: 'x1',
        yAxisKey: propertyAxis[lineChartData?.data[i].propertyKey]?.axis,
        canvasIndex: '0',
        dashType: 'solid',
        properties: {},
        settings: {
          curve: {
            type: 'curveLinear',
            settings: {
              alpha: 0.5
            }
          },
          digits: {
            value: 3
          }
        },
        stroke: 'lightgray',
        shape: 'Area',
        show: true,
        visible: true,
        type: 'area',
        tooltipDataModelFormatter: (props) => {
          /* 
          Here you have the flexibility to modify or add any required properties for a custom tooltip template. 
          The properties returned will be available within the tooltip template. 
          For instance, if certain details are not available in the data, you can inject them into the tooltip template, such as location, units, and other relevant information.
         */
          const xValueIndex = categories && categories?.indexOf(props?.xValue)
          props.date = sortedData[xValueIndex]?.timeStamp
          return props
        }
      })
    }
    return legends
  }
  const getData = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    const data = {}
    // To be discussed if the max can be used from y-axis max value
    // let max = 0
    lineChartData &&
      lineChartData.data &&
      lineChartData?.data?.map((element, index) => {
        data[index + 1] = {
          data: element.values[0]
            .map((x) =>
              x?.value && Number(x?.value)
                ? Number(x?.value)
                : null
            )
            ?.sort((a, b) => b - a)
        }
      })
    return data
  }
  const chartConfigData = (isTimeSliderChartConfig = false) => {
    return {
      canvas: {
        canvasDetails: {
          0: {
            renderAreaShade: {
              fill: colors.WHITE,
              opacity: '1'
            },
            tooltip: {
              type: 'x', // x, xy - xy not yet supported
              referenceLineV: true,
              template: ({ data }) => (
                <LoadAnalysisTooltipTemplate
                  data={data}
                  info={{
                    activeObject: activeObject,
                    activeLocation: activeLocation,
                    symbol: unit,
                    max: peakDemand
                  }}
                />
              ),
              hide: false
            }
          }
        }
      },
      x: createXAxis(),
      y: createYAxis(),
      series: {
        types: getLegends(isTimeSliderChartConfig),
        data: getData(isTimeSliderChartConfig)
      },
      legends: {
        className: 'engery-load-analysis-chart-legend'
      },
      width: '100%',
      height: 500,
      dataLastUpdatedAt: moment().unix()
    }
  }

  // Integration team has to pass required data that needs to be rendered in timeline chart
  // Display only lines, area charts, hide other elements like axis, tooltip, legend, markers etc...
  const timelineChartConfigData = useMemo(
    () => ({
      ...{ ...chartConfigData(true) },
      height: 55,
      width: '85%',
      legends: null,
      svgPadding: { top: 0, right: 0, bottom: 40, left: 0 },
      container: {},
      canvas: {
        canvasDetails: {
          0: {
            renderAreaShade: {
              fill: 'transparent',
              opacity: '0'
            },
            tooltip: {
              type: 'x', // x, xy - xy not yet supported
              referenceLineV: true,
              hide: true
            }
          }
        }
      }
    }),
    [timeSliderChartData]
  )

  return loadingChartData || !chartData || selectedEquipment === null ? (
    <Spinner />
  ) : chartConfigData()?.series.types?.length > 0 ? (
    <div testName="load-analysis-chart" data-testid="load-analysis-chart">
      <XYChart {...chartConfigData()}></XYChart>
      <TimelineWithChart
        startTime={chartDates?.chartStartDate}
        endTime={chartDates?.chartEndDate}
        getSelctedRange={(d) => {
          setTimeSliderStartTime(d?.startTime)
          setTimeSliderEndTime(d?.endTime)
        }}
        xAxis={timelineChartConfigData?.x ?? []}
        yAxis={timelineChartConfigData?.y ?? []}
        series={timelineChartConfigData?.series ?? {}}
        width={timelineChartConfigData?.width}
        className={'load-analysis-time-slider'}
      />
    </div>
  ) : (
    <p>No Data</p>
  )
}
export default LoadAnalysisChart
