export const CREATE_NOTE = /* GraphQL */ `
  mutation CREATE_NOTE($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
    }
  }
`

export const DELETE_NOTE = /* GraphQL */ `
  mutation DELETE_NOTE($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      id
    }
  }
`

export const UPDATE_NOTE = /* GraphQL */ `
  mutation UPDATE_NOTE($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      id
    }
  }
`

export const CREATE_NOTE_HISTORY = /* GraphQL */ `
  mutation CREATE_NOTE_HISTORY($input: CreateHistoryInput!) {
    createHistory(input: $input) {
      id
    }
  }
`

export const GET_PRE_SIGNED_URL = /* GraphQL */ `
  mutation GET_PRE_SIGNED_URL($input: AWSJSON!) {
    getPreSignedUrl(body: $input)
  }
`

export const CREATE_ATTACHMENT = /* GraphQL */ `
  mutation CREATE_ATTACHMENT($input: CreateAttachmentInput!) {
    createAttachment(input: $input) {
      attachedAt
      buildingId
      createdAt
      description
      historyId
      id
      issueId
      name
      noteId
      opportunityId
      sizeInBytes
      title
      type
      updatedAt
      url
    }
  }
`

export const DELETE_ATTACHMENT = /* GraphQL */ `
  mutation DELETE_ATTACHMENT($input: DeleteAttachmentInput!) {
    deleteAttachment(input: $input) {
      buildingId
      id
      name
      title
    }
  }
`
export const UPDATE_ATTACHMENT = /* GraphQL */ `
  mutation UPDATE_ATTACHMENT($input: UpdateAttachmentInput!) {
    updateAttachment(input: $input) {
      id
    }
  }
`

export const CREATE_ENTRY_WITH_ATTACHMENT = /* GraphQL */ `
  mutation createEntryWithAttachment($input: AWSJSON!) {
    createEntryWithAttachment(body: $input)
  }
`

export const getEquipmentDetailsbyId = /* GraphQL */ `
  query getEquipmentDetailsbyId($id: ID!) {
    getEquipment(id: $id) {
      id
      name #TIS EQUIPMENT  DATA ASSOCISTION
      uri
      building {
        id
        name
       
      }
    }
  }
`
