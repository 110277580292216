import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './TargetPathView.scss'

const TIS_OBJECT_ICONS_LIST = new Map([
  ['ElectricMeter', 'electric'],
  ['GAS', 'gas'],
  ['VAV-BOX', 'vavbox'],
  ['VAS', 'vas'],
  ['Chiller', 'chiller'],
  ['PumpBank', 'pumpbank'],
  ['LoadValve', 'loadvalves'],
  ['EnvironmentMeter', 'environment-meter'],
  ['FAN', 'fan'],
  ['Pump', 'pump'],
  ['AHU', 'ahu'],
  ['Circuit', 'circuit'],
  ['WaterFlow', 'water'],
  ['BinaryPoints', 'binarypoint'],
  ['SCU', 'spacecomfortunit'],
  ['Points', 'analogpoint']
])

const TargetPathItem = ({ name, type }) => (
  <div>
    <span className={`icon icon-${type}`} />
    <span>{name}</span>
  </div>
)

TargetPathItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default class TargetPath extends PureComponent {
  static propTypes = {
    organizationName: PropTypes.string.isRequired,
    locationName: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    tisObjects: PropTypes.array
  }

  render() {
    const { organizationName, locationName, tisObjects }: any = this.props
    return (
      <div className="data-source" data-testid="target-path-view">
        <TargetPathItem type="organization" name={organizationName} />

        {locationName ? (
          Array.isArray(locationName) ? (
            locationName.map((loc) => (
              <TargetPathItem type="location" key={loc} name={loc} />
            ))
          ) : (
            <TargetPathItem type="location" name={locationName} />
          )
        ) : null}

        {tisObjects &&
          tisObjects.length > 0 &&
          tisObjects.map(({ tisObjectType, tisObjectName }, index) => (
            <TargetPathItem
              key={index}
              type={TIS_OBJECT_ICONS_LIST.get(tisObjectType) || 'other'}
              name={tisObjectName}
            />
          ))}
      </div>
    )
  }
}
