import { createPortal } from "react-dom"
import { Dialog } from "./Dialog"
import { useEffect, useState } from "react";
import styles from './dialog.module.scss';
import { VimeoEmbed, VimeoEmbedStyles } from "../Media/VimeoEmbed";
import { Button, ButtonGroup, Flex, Heading } from "@aws-amplify/ui-react";
import { Chip, ChipWeight } from "../Table/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRepeat, faBuildings, faGrid2, faSparkles } from "../lib/pro-solid-svg-icons";
import { faPause, faPlay, faXmark } from "@fortawesome/free-solid-svg-icons";
import translate, { TranslateComponent } from "src/common/translations";
import { selectDismissedOnboarding, setDismissedOnboarding } from "src/redux/slicers/appData";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const DialogWelcome = () => {
  const dismissed = useSelector(selectDismissedOnboarding);
  const [isOpen, setIsOpen] = useState(true);
  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isAtBoundary, setIsAtBoundary] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const dispatch = useDispatch();

  setUpVideoPlayerListeners(player, activeTabIndex, setIsPlaying, setIsAtBoundary);

  if(dismissed || !isOpen) return null;
  return (
    <>
      {
        createPortal(
          <Dialog
            {...{isOpen, showHeader: false, className: styles.welcome}}
            onClose={() => dispatch(setDismissedOnboarding(true))}
            data-testid="welcomeDialog"
          >
            <div className={styles.welcomeLeft}>
              <Flex height='100%' direction='column' justifyContent='space-between'>
                <Flex direction='column' gap={16}>
                  <div>
                    <Chip weight={ChipWeight.w600}>
                      <TranslateComponent>New</TranslateComponent>
                    </Chip>
                  </div>
                  <Heading level={1} className="m-0">
                    <span className="text-highlight-pink"><TranslateComponent>Introducing</TranslateComponent></span>
                    <TranslateComponent> the Updated Connect</TranslateComponent>
                  </Heading>
                  <p className="body-lg">
                    <TranslateComponent>Check out our new side menu—simpler, faster, and easier to navigate.</TranslateComponent>
                  </p>
                </Flex>
                <Flex direction='column' gap={40}>
                  <Tabs {...{activeTabIndex, setActiveTabIndex, player}} />
                  <ButtonGroup direction='column'gap={8}>
                    <Button
                      className={styles.welcomeContinue}
                      onClick={() => dispatch(setDismissedOnboarding(true))}
                      data-testid="welcomeContinue"
                    >
                      <TranslateComponent>Continue to Trane Connect</TranslateComponent>
                    </Button>
                    <Button variation='link' className="button-link-secondary" onClick={()=>setIsOpen(false)} data-testid="welcomeSkip">
                      <TranslateComponent>Skip for now</TranslateComponent>
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Flex>
            </div>
            <div className={styles.welcomeRight}>
              <CloseButton closeAndSave={() => dispatch(setDismissedOnboarding(true))} />
              <PlayPauseButton {...{isPlaying, isAtBoundary, player, activeTabIndex}}/>
              <VimeoEmbed
                id='1014456204'
                title='Trane Connect Design Modernization Tutorial'
                style={VimeoEmbedStyles.backgroundCoverLoop}
                setPlayer={setPlayer}
                urlParams="h=75b5232463&transparent=0"
                aspectRatio={325.17/388.02}
              />
            </div>
          </Dialog>
          , document.getElementById('app')
        )
      }
    </>
  )
}

const Tabs = ({activeTabIndex, setActiveTabIndex, player}) => {
  return (
    <ButtonGroup direction='column' gap={6} className={styles.featureButtons}>
      <Button
        justifyContent='flex-start'
        className={`${styles.featurePink} ${activeTabIndex === 0 ? styles.active : ''}`}
        onClick={() => onClickTab(0, setActiveTabIndex, player)}
        data-active={activeTabIndex === 0}
        data-testid="tab0"
      >
        <span className={styles.featureButtonIcon}><FontAwesomeIcon icon={faSparkles} /></span>
        <TranslateComponent>Legacy Menu Settings</TranslateComponent>
      </Button>
      <Button
        justifyContent='flex-start'
        className={`${styles.featureRed} ${activeTabIndex === 1 ? styles.active : ''}`}
        onClick={() => onClickTab(1, setActiveTabIndex, player)}
        data-active={activeTabIndex === 1}
        data-testid="tab1"
      >
        <span className={styles.featureButtonIcon}><FontAwesomeIcon icon={faBuildings} /></span>
        <TranslateComponent>Building and Organizations</TranslateComponent>
      </Button>
      <Button
        justifyContent='flex-start'
        className={`${styles.featureBlue}
        ${activeTabIndex === 2 ? styles.active : ''}`}
        onClick={() => onClickTab(2, setActiveTabIndex, player)}
        data-active={activeTabIndex === 2}
        data-testid="tab2"
      >
        <span className={styles.featureButtonIcon}><FontAwesomeIcon icon={faGrid2} /></span>
        <TranslateComponent>Introducing the new UI</TranslateComponent>
      </Button>
    </ButtonGroup>
  );
}

const CloseButton = ({closeAndSave}) => {
  return (
    <Button
      className={styles.close}
      aria-label={translate('Close')}
      onClick={closeAndSave}
      data-testid="welcomeClose"
    >
      <FontAwesomeIcon icon={faXmark} />
    </Button>
  );
}

const setUpVideoPlayerListeners = (player, activeTabIndex, setIsPlaying, setIsAtBoundary) => {
  useEffect(() => {
    player?.off('timeupdate'); // reset Vimeo event callback with proper state references
    player?.on('timeupdate', (event) => onTimeUpdate(event, activeTabIndex, player, setIsAtBoundary));
    player?.off('play');
    player?.on('play', () => {
      setIsPlaying(true);
      setIsAtBoundary(false);
    });
    player?.off('pause');
    player?.on('pause', () => setIsPlaying(false));
  }, [player, activeTabIndex]);
}

const PlayPauseButton = ({isPlaying, isAtBoundary, player, activeTabIndex}) => {
  if(isAtBoundary) {
    const replayTimes = [1.8, 11.8, 21.61];
    const onReplay = () => {
      player?.setCurrentTime(replayTimes[activeTabIndex]);
      player?.play();
    }
    if(activeTabIndex === 2) return <></>;
    return (
      <Button className={styles.replay} onClick={onReplay} data-testid="welcomeReplay">
        <FontAwesomeIcon icon={faArrowsRepeat} /> <TranslateComponent>Replay</TranslateComponent>
      </Button>
    );
  }
  if(isPlaying) {
    return (
      <Button className={styles.playPause} onClick={() => player?.pause()} data-testid="welcomePause">
        <FontAwesomeIcon icon={faPause} /> <TranslateComponent>Pause</TranslateComponent>
      </Button>
    );
  }

  return (
    <Button className={styles.playPause} onClick={() => player?.play()} data-testid="welcomePlay">
      <FontAwesomeIcon icon={faPlay} /> <TranslateComponent>Play</TranslateComponent>
    </Button>
  );
}

// https://developer.vimeo.com/player/sdk/reference#timeupdate
const onTimeUpdate = ({seconds}, activeTabIndex, player, setIsAtBoundary) => {
  if((activeTabIndex === 0) && (seconds >= 9.4)) {
    player?.pause();
    player?.setCurrentTime(9.41);
    setIsAtBoundary(true);
  }else if((activeTabIndex === 1) && ((seconds < 9.4) || (seconds >= 21.6))) {
    player?.pause();
    player?.setCurrentTime(21.61);
    setIsAtBoundary(true);
  }else if((activeTabIndex === 2) && ((seconds < 21.6) || (seconds >= 23.5))) {
    player?.pause();
    // Vimeo embed background=1 required to remove all controls, forcing a loop which doesn't fire player.on('ended')
    // player.on('timeupdate') and player.pause() may lag behind because of the async calls.
    // stopping a few milliseconds for the the last frame and using setCurrentTime prevents a flicker from video frame 1 showing.
    player?.setCurrentTime(23.5);
    setIsAtBoundary(true);
  }
}

const onClickTab = (index, setActiveTabIndex, player) => {
  setActiveTabIndex(index);
  if(index === 0) {
    player?.setCurrentTime(0);
  }else if(index === 1) {
    player?.setCurrentTime(9.41);
  }else if(index === 2) {
    player?.setCurrentTime(21.61);
  }
  player?.play();
}