import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { AxisRangeWrapper } from "./styles"
import { TranslateComponent } from "../../../../common/translations"

export const AxisRange = ({ range: { from, to }, onChange, onBlur, onFocus }) => {
	const [t] = useTranslation()
	return (
		<AxisRangeWrapper>
			<div className="range-component">
				<label htmlFor="range-from">{<TranslateComponent>Range from:</TranslateComponent>}</label>
				<input
					id="range-from"
					type="number"
					name="rangeFrom"
					value={from}
					onChange={event => onChange({ from: Number(event.target.value), to })}
					onBlur={onBlur}
					onFocus={onFocus}
				/>
				<label htmlFor="range-to">{<TranslateComponent>To:</TranslateComponent>}</label>
				<input
					id="range-to"
					type="number"
					name="rangeTo"
					value={to}
					onChange={event => onChange({ from, to: Number(event.target.value) })}
					onBlur={onBlur}
					onFocus={onFocus}
				/>
			</div>
		</AxisRangeWrapper>
	)
}

AxisRange.propTypes = {
	range: PropTypes.shape({
		from: PropTypes.number,
		to: PropTypes.number,
	}),
	onChange: PropTypes.func,
}

export default AxisRange
