
export const GET_PRE_SIGNED_URL = /* GraphQL */ `
  mutation GET_PRE_SIGNED_URL($input: AWSJSON!) {
    getPreSignedUrl(body: $input)
  }
`



export const UPLOAD_UTILITY_BILL = /* GraphQL */ `
  mutation utilityDataUploader($requestBody: AWSJSON!) {
    utilityDataUploader(requestBody: $requestBody)
  }
`




