/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Grid from '../../../chart-library/CommonComponents/Grids/Grid'
import Axis from '../../../chart-library/CommonComponents/AxisNative'
import { GroupAndClip } from '../../../chart-library/CommonComponents/Clip'
import { StackedBar } from '../../../chart-library/CommonComponents/StackedBar'
import { useConfig } from './useConfig'
import { format } from 'd3'

function multiFormat(label) {
  return String(label).length > 5 ? format('~s')(label) : label
}

export default function Canvas({ series = [], tooltipEventName }) {
  const {
    AxisDiM,
    axisUpdatedAt,
    canvas,
    isInitialized,
    scaleUpdatedAt,
    xAxis,
    xScale,
    yAxis,
    yScale,
    scale
  } = useConfig()

  if (!isInitialized) {
    return null
  }

  return (
    <>
      <Axis
        dimention={AxisDiM.x}
        axisPosition={xAxis.type}
        axisScale={xScale.fn}
        xPosition={xAxis.x}
        yPosition={xAxis.y}
        lastUpdatedAt={axisUpdatedAt + scaleUpdatedAt}
        textProps={{
          breakLabelTextBy: '-',
          enableAutoGroupForDateLabel: true
        }}
      />
      <Axis
        tickFormat={multiFormat}
        hideAxisLine
        hideAxisTickLine
        dimention={AxisDiM.y}
        axisPosition={yAxis.type}
        axisScale={yScale.fn}
        xPosition={yAxis.x}
        yPosition={yAxis.y}
        lastUpdatedAt={axisUpdatedAt + scaleUpdatedAt}
      />
      <Grid
        scale={yScale.fn}
        width={canvas.width}
        height={canvas.height}
        tickCount={yScale.fn.ticks().length}
        includeAxis={true}
        opacity={0.2}
        strokeWidth={0.5}
        type={'y'}
        transform={`translate(${canvas.x} ${canvas.y})`}
      />
      <GroupAndClip clipName="stacked-bar-clip" gx={canvas.x} gy={canvas.y}>
        <StackedBar
          getMinMax={([min, max]) => {
            const [yMin, yMax] = yScale.fn.domain()
            if (min !== yMin || max !== yMax)
              scale.setDomain('y', 0, [min || 0, max || 0], true)
          }}
          barGap={0.1}
          hideBarText
          data={series}
          xScale={xScale.fn}
          yScale={yScale.fn}
          enableTooltip
          textFormater={(value) => `$ ${value}`}
          tooltipDispatchEventName={tooltipEventName}
          tooltipFormatter={null}
          tooltipTriggerPoint={'axis'}
        />
      </GroupAndClip>
    </>
  )
}
