import * as d3 from 'd3'
import { Fragment, useEffect, useMemo } from 'react'
import {
  selectAxes,
  selectAxisCanvas,
  selectGeneratedScales,
  selectSeriesData,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
import { getBandWidth } from '../helper'
import { isNumber } from 'lodash'
import { applyAdditionalPropsToScale } from 'src/chart-library/Utils/Scales/createScale'
import { useSyncSeriesPointsForTooltip } from 'src/chart-library/CommonComponents/TooltipNew'
import { isNil } from 'ramda'
import { SCALE_BAND, SCALE_TIME } from 'src/chart-library/Utils/Scales/constant'

const dataPointFunction = (
  scaleType,
  scale: any,
  d: any = [],
  bandWidth = 0
) => {
  if (scaleType === SCALE_TIME) {
    return scale(new Date(d))
  } else if (scaleType === SCALE_BAND) {
    return scale(d) + bandWidth
  } else {
    return scale(isNumber(d) ? d : null)
  }
}

function columnNewTooltipModel(data, tooltipDataModel) {
  const {
    xScale = () => null,
    yScale = () => null,
    subScaleX = () => null,
    xscaleType,
    xData = [],
    yData = [],
    obj = {}
  } = data

  const {
    name,
    elementProps: { fill = 'steelblue' },
    tooltipDataModelFormatter = () => null
  } = obj

  const modifiedXScale =
    xScale && xscaleType === SCALE_BAND
      ? xScale?.copy()?.paddingInner(0)?.paddingOuter(0)?.padding(0)?.align(0)
      : xScale

  const maxX = xscaleType === SCALE_BAND ? xScale.bandwidth() : undefined
  return xData.reduce((av, xVal, i) => {
    const yVal = yData?.[i]
    const x = dataPointFunction(xscaleType, xScale, xVal)
    const height = Math.abs(yScale(yVal) - yScale(0))
    const convertedN = parseFloat(x?.toFixed(3))

    const width = getBandWidth(subScaleX)

    if (isNil(x) || isNil(yVal)) return av

    const defaultProps = {
      // Label of the data point
      label: name,
      // value that will be displayed against the label
      value: yVal,
      // title by default x value (usually date time )
      title: xVal,
      // x position
      x: convertedN,
      y: height,
      // by default 'title' means based on x axis date will be grouped and displays it
      groupBy: 'title',
      color: fill,

      // Atcual value from the data for the end user to coustimize the value property via tooltipDataModelFormatter
      // i.e tooltipDataModelFormatter : (props)=> ({...props, value: props.yValue + "kw"})
      xValue: xVal,
      yValue: yVal
    }

    const formatter = tooltipDataModelFormatter({
      ...defaultProps,
      ...obj
    })

    av.push(
      tooltipDataModel(
        x,
        {
          // Default model values
          ...defaultProps,

          // user modified values
          ...formatter,
          higlightDots: true
        },
        {
          x: convertedN,
          y: 0,
          maxX: convertedN + width || convertedN,
          maxY: 0
        }
      )
    )

    return av
  }, [])
}

const ColumnNewRenderer = (props: any) => {
  const { types = [], canvasIndex } = props
  const { addPoints, deletePoints, createTooltipPointModel } =
    useSyncSeriesPointsForTooltip(true)
  const generatedScales = useSelector(selectGeneratedScales)
  const seriesData = useSelector(selectSeriesData)
  const axes = useSelector(selectAxes)
  const axisCanvas: any = useSelector(selectAxisCanvas)

  const canvasAxes: any = Object.values(axisCanvas || {})?.find((obj: any) => {
    const isCurrentCanvas =
      obj?.canvasIndex === canvasIndex && obj?.scaleType === 'scaleBand'
    return isCurrentCanvas
  })

  const { subScaleX, xScale = {} } = useMemo(() => {
    const legendTypes = types?.map((obj: any) => obj?.name)
    const scaleBandKey: any = canvasAxes
    const xScale = generatedScales?.[scaleBandKey?.axisKey]
    const xScaleProps =
      (axes || [])?.find((obj: any) => obj?.key === scaleBandKey?.axisKey) || {}
    let subScaleX = null
    if (
      legendTypes?.length > 0 &&
      typeof xScale?.gScale?.bandwidth === 'function'
    ) {
      subScaleX = d3
        .scaleBand()
        ?.domain(legendTypes)
        ?.range([0, xScale?.gScale?.bandwidth()])
      subScaleX = applyAdditionalPropsToScale(
        subScaleX,
        xScaleProps?.scale?.subScaleSettings
      )
    }
    return {
      subScaleX,
      xScale
    }
  }, [types, canvasAxes, generatedScales, axes])

  useEffect(() => {
    const inputModel = types?.reduce((av = [], obj: any, i: number) => {
      const xObject = (axes || [])?.find(
        (axesObj: any) => axesObj?.key === obj?.xAxisKey
      )
      const yObject = (axes || [])?.find(
        (axesObj: any) => axesObj?.key === obj?.yAxisKey
      )
      const gYScaleObj = generatedScales?.[obj?.yAxisKey]
      const yData = seriesData?.[obj?.seriesKey]?.data
      const xscaleType = xObject?.scale?.props?.type
      const yscaleType = yObject?.scale?.props?.type
      const yScale = gYScaleObj?.gScale
      const xData = xObject?.scale?.categories

      if (obj?.type !== 'columnNew') return av

      const columnNewModel = columnNewTooltipModel(
        {
          obj,
          xscaleType,
          yscaleType,
          xData,
          yData,
          xScale: xScale?.gScale,
          subScaleX,
          yScale,
          invert: false,
          settings: obj?.settings,
          type: obj?.type,
          min: gYScaleObj?.domain?.[0]
        },
        createTooltipPointModel
      )

      return av.concat(columnNewModel)
    }, [])

    inputModel?.length && addPoints(inputModel)

    return () => {
      inputModel.length && deletePoints(inputModel)
    }
  }, [subScaleX, xScale, types])

  if (!canvasAxes || !subScaleX || !xScale?.gScale) return <></>

  return (
    <>
      {types?.map((obj: any, typeIndex: number) => {
        const customSettings = obj?.customSettings
        const yData = seriesData?.[obj?.id]?.data || []
        const yScale = generatedScales?.[obj?.yAxisKey]?.gScale

        return (
          <g key={typeIndex}>
            {(xScale?.domain || [])?.map((name: string, xIndex: number) => {
              let value = yData?.[xIndex]
              let x =
                subScaleX && xScale?.gScale
                  ? xScale?.gScale(name) + subScaleX(obj?.name)
                  : null
              let y = yScale ? (value < 0 ? yScale(0) : yScale(value)) : null
              let height = Math.abs(yScale(value) - yScale(0))
              let width = getBandWidth(subScaleX)

              if (customSettings) {
                if (customSettings?.customRenderer) {
                  const obj = {
                    xScale: xScale?.gScale,
                    yScale,
                    subScaleX,
                    x,
                    y,
                    height,
                    width,
                    value: yData?.[xIndex]
                  }
                  const customValues = customSettings?.customRenderer(obj)
                  value = customValues?.cValue ? customValues?.cValue : value
                  x =
                    customValues?.cX === 0 || customValues?.cX
                      ? customValues?.cX
                      : x
                  y =
                    customValues?.cY === 0 || customValues?.cY
                      ? customValues?.cY
                      : y
                  height =
                    customValues?.cHeight === 0 || customValues?.cHeight
                      ? customValues?.cHeight
                      : height
                  width =
                    customValues?.cWidth === 0 || customValues?.cWidth
                      ? customValues?.cWidth
                      : width
                }
              }

              if (isNumber(x) && isNumber(y)) {
                return (
                  <rect
                    key={`${xIndex}-${typeIndex}`}
                    x={x}
                    y={y}
                    height={height}
                    width={width}
                    fill={obj?.elementProps?.fill}
                  />
                )
              } else {
                return <Fragment key={`${xIndex}-${typeIndex}`}></Fragment>
              }
            })}
          </g>
        )
      })}
    </>
  )
}

export default ColumnNewRenderer
