import moment from 'moment'
import { EquipmentTypeList } from 'src/pages/equipmentSetup/CreateEquipment/constants'

const getEquipmentsTypeList = () => {
  const equipmentsList = [
    { equipmentType: 'VRF-IDU', uiName: 'VRF Indoor Unit' },
    { equipmentType: 'VRF-ODU', uiName: 'VRF Outdoor Unit' },
    { equipmentType: 'VRF-System', uiName: 'VRF System' }
  ]
  for (const key in EquipmentTypeList) {
    const equipment = EquipmentTypeList[key]
    equipmentsList.push({
      equipmentType: equipment?.type,
      uiName: key
    })
  }
  return equipmentsList
}
export const formatActiveSuppressions = (settings) => {
  try {
    const suppressionsArray = []
    const equipmentsList = getEquipmentsTypeList()
    const timeFormat = 'MMM DD, YYYY'
    for (const key in settings) {
      const firstItem = settings[key] || {}
      const splitKeys = firstItem?.atKey.split('|')
      const displayName = splitKeys[2]
      const equipmentTypeUIName =
      equipmentsList?.find((x) => x?.equipmentType === splitKeys[0])
        ?.uiName || splitKeys[0]
      const suppressionItem = {
        key,
        name: splitKeys[1] || '',
        displayName: displayName?.replace('XX', ''),
        equipmentTypeUIName,
        equipType: splitKeys[0],
        updatedAt: moment(firstItem?.updatedAt).format(timeFormat),
        updatedBy: firstItem?.updatedBy,
        startDate: moment(firstItem?.startDate).format(timeFormat),
        endDate: firstItem?.endDate
          ? moment(firstItem?.endDate).format(timeFormat)
          : 'Indefinitely',
        suppressionLevel: firstItem?.spLevel === 'E' ? 'Equipment' : 'Building',
        equipmentId: firstItem?.equipmentId
      }
      suppressionsArray.push(suppressionItem)
    }
    return suppressionsArray
  } catch (e) {
    return []
  }
}

export const formatSuppressionsHistory = (history) => {
  try {
    const suppressionHistoryArray = []
    const equipmentsList = getEquipmentsTypeList()
    const timeFormat = 'MMM DD, YYYY'
    history?.map((x) => {
      const equipmentTypeUIName =
      equipmentsList?.find((e) => e?.equipmentType === x?.familyId)
        ?.uiName || x?.familyId
      const suppressionItem = {
        displayName: x?.analyticId?.replace('XX', ''),
        equipmentTypeUIName,
        equipType: x?.familyId,
        updatedAt: moment(x?.updatedAt).locale('en').format(timeFormat),
        updatedBy: x?.updatedBy,
        startDate: moment(x?.startDate).locale('en').format(timeFormat),
        endDate: x?.endDate
          ? moment(x?.endDate).locale('en').format(timeFormat)
          : 'Indefinitely',
        suppressionLevel: x?.equipmentId !== 'NA' ? 'Equipment' : 'Building',
        equipmentId: x?.equipmentId
      }
      suppressionHistoryArray.push(suppressionItem)
    })

    return suppressionHistoryArray
  } catch (e) {
    return []
  }
}

export const getEquipmentName = (equipmentList, equipId) => {
    const filterEquipment = equipmentList?.filter((eq) => {
      return eq?.id === equipId
    })
    return filterEquipment.length ? filterEquipment[0]?.name : '--'
}
