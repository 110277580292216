import React, { Fragment, PureComponent } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import _get from 'lodash/get'
import TranslateComponent from 'src/common/translations'
import { DebounceInput } from 'src/components/legacy/components/debounce-input'
import DateInput from 'src/components/legacy/components/datepicker-input'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import ToggleBox from 'src/components/legacy/components/toggle-box'
import EditableViewArray from 'src/components/legacy/buildingSetup/common/editable-view-array'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import { getConfig } from 'src/config'
import { offerings } from 'src/components/legacy/common/offerings'

export const DATE_FORMAT = 'MM/DD/YYYY'
export const DEFAULT_DATE = moment()

const ensembleServicesPrefix = getConfig('externalLinks.ensembleServicesPrefix')
const ensembleServicesDomain = getConfig('externalLinks.ensembleServicesDomain')

export class Offerings extends PureComponent {
  static propTypes = {
    readOnly: PropTypes.bool,
    offeringSourceMaps: PropTypes.array,
    contractsAndOffering: PropTypes.bool
  }

  static defaultProps = {
    readOnly: false
  }

  static needFormatDateString(value = '') {
    return value.includes('T')
  }

  static getFormattedDate(value) {
    if (value) {
      return typeof value === 'string'
        ? (Offerings.needFormatDateString(value) &&
            moment(value).format(DATE_FORMAT)) ||
            value
        : moment(value).format(DATE_FORMAT)
    }
  }

  static isOfferingChecked(item = {}) {
    return item.checked || item.checked === undefined
  }

  setDateValue = (onBlur, setFieldValue, name) => (value) => {
    onBlur()
    setFieldValue(name, Offerings.getFormattedDate(value))
  }

  setCheckedValue = (setFieldValue, name, value) => () => {
    setFieldValue(name, !value)
  }

  onChangeSubdomainInput = (onChange) => (event) => {
    onChange(event.target.value)
  }

  dateInput = ({
    disabled,
    name,
    value,
    onBlur,
    formik: { setFieldValue, errors }
  }) => (
    <DateInput
      disabled={disabled}
      format={DATE_FORMAT}
      date={
        value
          ? Offerings.getFormattedDate(value)
          : Offerings.getFormattedDate(DEFAULT_DATE)
      }
      onChange={this.setDateValue(onBlur, setFieldValue, name)}
      outerErrorMessage={_get(errors, name, '')}
    />
  )

  checkBox =
    (tisOfferingName, index) =>
    ({ disabled, name, value, formik: { setFieldValue } }) =>
      (
        <Checkbox
          disabled={disabled}
          isChecked={value}
          label={tisOfferingName}
          toggleCheck={this.setCheckedValue(setFieldValue, name, value)}
          id={`offerings-checkbox-${index}`}
        />
      )

  subdomainInput = ({ name, value, onBlur, onChange, onFocus }) => (
    <span className="edit-view__nowrap">
      {ensembleServicesPrefix}
      <DebounceInput
        id={name}
        className="edit-view__input"
        type="text"
        name={name}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={this.onChangeSubdomainInput(onChange)}
        debounceTimeout={300}
      />
      {ensembleServicesDomain}
    </span>
  )

  ItemComponent = React.memo(
    (props) => {
      const { item, index } = props
      const { readOnly } = this.props
      const isChecked = Offerings.isOfferingChecked(item)
      const tisOfferingName = item.name || item.tisOfferingName
      const isEnsembleCloud =
        item.shortName === offerings.tenantServices.shortName

      return (
        <Fragment key={index}>
          {isEnsembleCloud && (
            <>
              <div className="offering__row">
                <EditableView
                  className="offering-label"
                  disabled={!isEnsembleCloud}
                  labelName={readOnly ? tisOfferingName : null}
                  readOnly={readOnly}
                  hideValue={readOnly}
                  component={this.checkBox(tisOfferingName, index)}
                  name={`offeringSourceMaps.${index}.checked`}
                  value={isChecked || false}
                  showErrors={false}
                />
                {isChecked && !isEnsembleCloud && (
                  <>
                    <EditableView
                      className="offering-dates"
                      disabled={true}
                      readOnly={readOnly}
                      labelName="Start Date"
                      value={Offerings.getFormattedDate(item.startDate)}
                      component={this.dateInput}
                      name={`offeringSourceMaps.${index}.startDate`}
                      showErrors={false}
                    />
                    <EditableView
                      className="offering-dates"
                      disabled={true}
                      readOnly={readOnly}
                      labelName="Expiration Date*"
                      value={Offerings.getFormattedDate(item.expirationDate)}
                      component={this.dateInput}
                      name={`offeringSourceMaps.${index}.expirationDate`}
                      showErrors={false}
                    />
                  </>
                )}
              </div>

              {isChecked &&
                item.shortName === offerings.tenantServices.shortName && (
                  <div className="offering__row second__row" key={`${index}_`}>
                    <EditableView
                      className="subdomain"
                      readOnly={readOnly}
                      labelName="Subdomain"
                      value={
                        readOnly
                          ? `${ensembleServicesPrefix}${item.subdomain}${ensembleServicesDomain}`
                          : item.subdomain
                      }
                      component={this.subdomainInput}
                      name={`offeringSourceMaps.${index}.subdomain`}
                      validateOnChange={false}
                    />
                    <EditableView
                      className="offering-dates"
                      readOnly={readOnly}
                      labelName="Start Date"
                      value={Offerings.getFormattedDate(item.startDate)}
                      component={this.dateInput}
                      name={`offeringSourceMaps.${index}.startDate`}
                      showErrors={false}
                    />
                    <EditableView
                      className="offering-dates"
                      readOnly={readOnly}
                      labelName="Expiration Date*"
                      value={Offerings.getFormattedDate(item.expirationDate)}
                      component={this.dateInput}
                      name={`offeringSourceMaps.${index}.expirationDate`}
                      showErrors={false}
                    />
                  </div>
                )}
            </>
          )}
        </Fragment>
      )
    },
    (prevProps, nextProps) =>
      prevProps.index === nextProps.index && prevProps.item === nextProps.item
  )

  offeringsSectionLink = this.props.contractsAndOffering ? (
    <TranslateComponent >
      To view or activate, go the{' '}
      <Link to="/contracts-and-offerings">Contracts & Offerings Page</Link>
    </TranslateComponent>
  ) : null

  render() {
    const { readOnly, offeringSourceMaps } = this.props

    return (
      <>
        <ToggleBox
          label="Offerings"
          className="transparent"
          opened={true}
          showOnlyLabel={true}
          customAction={this.offeringsSectionLink}
        >
          <div className={'offering'}>
            {!readOnly || (offeringSourceMaps && offeringSourceMaps.length) ? (
              <EditableViewArray
                readOnly={readOnly}
                value={offeringSourceMaps}
                name="offeringSourceMaps"
                ItemComponent={this.ItemComponent}
              />
            ) : (
              <div className={'no-data-section'}>
                No offerings enabled for this building.
              </div>
            )}
          </div>
        </ToggleBox>
      </>
    )
  }
}
