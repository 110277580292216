import { createContext, useContext, useState } from 'react'
import { getSearchParams } from "src/common/helperFunctions.js"

import { IModalActionType } from 'src/constants'
const BaseLinesContext = createContext<any>(null)

export const BaseLinesContextProvider = ({ children }: any) => {
  const {buildingId, organizationId: accountId} = getSearchParams()
  const [mode, setMode] = useState(null)
  const [reloadEvents, setReloadEvents] = useState(false)
  const [isBaseLineAdding, setIsBaseLineAdding] = useState(false)
  const [modalType, setModalType] = useState<IModalActionType>('')
  const [baseLine, setBaseLine] = useState<any>(null)
  const [showBaselineModal, setShowBaselineModal] = useState(false)
  const [baselines,setBaselines] = useState([])
  const [selectedEquipmentDetails, setSelectedEquipmentDetails]= useState({})
  const [dataAvailablityDates, setDataAvailablityDates] = useState({
    startDate: '',
    endDate: ''
  })
  return (
    <BaseLinesContext.Provider
      value={{
        mode,
        setMode,
        reloadEvents,
        setReloadEvents,
        isBaseLineAdding,
        setIsBaseLineAdding,
        setModalType,
        modalType,
        setBaseLine,
        baseLine,
        buildingId,
        accountId,
        showBaselineModal,
        setShowBaselineModal,
        baselines,
        setBaselines,
        selectedEquipmentDetails,
        setSelectedEquipmentDetails,
        dataAvailablityDates,
        setDataAvailablityDates,
        testName : "baseLine-events"
      }}
    >
      {children}
    </BaseLinesContext.Provider>
  )
}

export const useBaseLinesContext = () => useContext(BaseLinesContext)
