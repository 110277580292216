import PropTypes from 'prop-types'

import { AlertWrapper, AlertIcon, AlertMessage } from './style'

/*
types:
	primary
	secondary
	success
	danger
	warning
	info
	light
	dark
*/

const AlertNotification = (props) => {
  const { className = '', message = '', icon } = props

  return (
    <AlertWrapper className={className}>
      {icon && <AlertIcon className={`icon icon-${icon}`} />}
      {message && <AlertMessage>{message}</AlertMessage>}
    </AlertWrapper>
  )
}

export default AlertNotification
