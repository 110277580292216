import styled from 'styled-components'

export const ColorPickerWrapper = styled.div`
.palette {
  display: flex;
  flex-wrap: wrap;

  .color-item {
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    height: 22px;
    margin: 0 6px 6px 0;
    width: 22px;

    
}
.color-item.selected {
  border: 1px solid #000;
  box-shadow: 0 0 4px 1px rgba(0,0,0,.6);
}
.color-item:hover {
  box-shadow: 0 0 4px 0 rgba(0,0,0,.6);
}
}
` 