import { ATManager } from '../atManager'
import { EquipmentDetails } from '../chiller/equipmentDetails'

export function handleOtherStatus(configs) {
  const hasConfig = !!configs.length
  // Return defaults when required data is not available
  if (!hasConfig) {
    return {
      tests: [],
      activeTests: 0
    }
  }

  const equipmentInst = new EquipmentDetails({})

  const atManager = new ATManager<EquipmentDetails>(equipmentInst)

  const automatedTests = atManager.init(configs, () => {
    return true
  })

  return atManager.getResult(automatedTests)
}
