import moment from "moment"


export const ACTIONS = {
    ADD: 'Add',
    VIEW: 'View',
    EDIT: 'Edit'
}


export const getFormattedResponseData = (objectPickerValues) => {
        if(objectPickerValues) {
        const formatData = {
            ...objectPickerValues,
            checked: true,
        }
        // returning as array to inline with rootdata from object-picker
        return [formatData]
    }
}


export const verifyExclusion = (name, start, end) => {
	const errors = {}
	if (name === "") {
		errors.name = "The name field is required."
	}
	if (moment(moment(start)).isAfter(moment(end))) {
		errors.startPeriod = true
		errors.endPeriod = true
	}
	return errors
}


export const startDateErrorMessages = (start, end, startRange, defaultDates) => {
	if (start && end) {
		if (startRange) {
			if ((moment(start, DATE_FORMAT_DASH).isBefore(moment(startRange, BACKEND_DATE_FORMAT))) && !defaultDates) {
				return `Earliest date with valid Baseline data is ${moment(startRange, BACKEND_DATE_FORMAT).format(DATE_FORMAT_DASH)}.`
			}
		}
		if (moment(start, DATE_FORMAT_DASH).isAfter(moment(end, DATE_FORMAT_DASH))) {
			return "Start date must be before end."
		}
		if (moment(start, DATE_FORMAT_DASH).isAfter(moment(end, DATE_FORMAT_DASH).subtract(14, "d"))) {
			return "Start date must be atleast 14 days before end date."
		}
	}
}

export const endDateErrorMessages = (start, end, endRange, defaultDates) => {
	if (start && end) {
		if (endRange) {
			if ((moment(end, DATE_FORMAT_DASH).isAfter(moment(endRange, BACKEND_DATE_FORMAT))) && !defaultDates) {
				return `Latest date with valid Baseline data is ${moment(endRange, BACKEND_DATE_FORMAT).format(DATE_FORMAT_DASH)}.`
			}
		}
		if (moment(end, DATE_FORMAT_DASH).isBefore(moment(start, DATE_FORMAT_DASH))) {
			return "End date must be before start."
		}
		if (moment(end, DATE_FORMAT_DASH).isBefore(moment(start, DATE_FORMAT_DASH).add(14, "d"))) {
			return "Start date must be atleast 14 days before end date."
		}
	}
}