import styled, { css } from 'styled-components'
import COLORS from 'src/components/legacy/common/colors.json'

export const ActionButton = styled.button`
  margin-left: auto;
  margin-left: 10px;
`

export const ToolBarWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 8px;
  }
`

export const PortfolioContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  background-color: #fff;
  border-bottom: 1px solid #999;
  border-left: 1px solid #999;
  border-right: 1px solid #999;
`

export const PortfolioHeader = styled.div`
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * {
    height: 100%;
    vertical-align: middle;
  }
`
export const EPTitle = styled.div`
  margin-bottom: 10px;
`

export const PortfolioContentWrapper = styled.div`
  background-color: #fff;
  padding: 16px;
`
export const Title = styled.div`
  font-size: 21px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.31px;
  text-overflow: ellipsis;
  white-space: nowrap;
  .tooltip-anchor {
    display: inline-flex;
    margin-left: 0.825rem;
    font-size: 0.825rem;
  }
`
export const ChartHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  > div:nth-child(1) {
    width: calc(100% - 200px);
  }
  // > div:nth-child(2) {
  //   position: absolute;
  //   top: 0px;
  //   z-index: 100;
  //   right: 20px;
  // }
`

export const DataAvailableInfo = styled.div`
  margin-left: 40px;
`

export const EnergyPerformanceHeaderTiles = styled.div`
  white-space: nowrap;
  .chartTiles {
    height: 100%;
    display: flex;
    flex-direction: row;
    max-width: 250px;
  }
  .chartTiles_tile {
    background-color: #999;
    border-radius: 10px;
    color: #fff;
    padding: 16px;
    text-align: center;
  }
  .chartTiles_tile_heading {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .chartTiles_tile_reading > * {
    font-size: 32px;
    font-weight: 900;
  }
  .chartTiles > * + * {
    margin-top: 10px;
  }
`

export const PortfolioBuildingsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PortfolioBuildingWrapper = styled.div<{
  availableTypesForTooltTip: number
}>`
  width: 100%;
  background-color: ${COLORS.TBA_LIGHTEST_GREY};
  border: 1px solid ${COLORS.TBA_LIGHT_GREY};
  border-radius: 2px;
  display: flex;
  height: 58px;
  .legendContainer {
    width: 540px;
    margin: 15px auto 0 auto;
  }
  .progressContainer {
    padding-left: 15px;
    .progressLabel {
      float: left;
      color: ${COLORS.TBA_DARK_GREY};
      padding-right: 5px;
    }
  }
  .locationTotal {
    padding: 20px 30px 0 30px;
    font-weight: bold;
    font-size: 20px;
    height: 56px;
    min-width: 180px;
    text-align: right;
  }
  .locationGraph {
    flex: 1;

    .tooltip-base {
      ${(props: any) => {
        if (props?.availableTypesForTooltTip === 4) {
          return css`
            height: ${35 * props?.availableTypesForTooltTip}px;
            .tooltip-arrow.default {
              bottom: ${8 * props?.availableTypesForTooltTip};
            }
          `
        }
        if (props?.availableTypesForTooltTip === 3) {
          return css`
            height: 120px;
            .tooltip-arrow.default {
              bottom: 9px;
            }
          `
        }
        if (props?.availableTypesForTooltTip === 2) {
          return css`
            height: 90px;
            .tooltip-arrow.default {
              bottom: 23px;
            }
          `
        }
        if (
          props?.availableTypesForTooltTip === 1 ||
          props?.availableTypesForTooltTip === 0
        ) {
          return css`
            height: 75px;
            .tooltip-arrow.default {
              bottom: 30px;
            }
          `
        }
      }}
    }
  }
  .locationTitle {
    padding: 4px;
    font-size: 1em;
    font-weight: bold;
    height: 24px;

    a {
      color: black;
    }
    .locationTitleText {
      text-decoration: underline;
    }
  }
  .locationError {
    height: 30px;
    font-weight: bold;
    width: 100%;
    padding-left: 9px;
    .error {
      color: ${COLORS.RED};
    }
    .icon-error {
      font-size: 1.8em;
      margin-right: 5px;
    }
  }
  .locationBar {
    height: 30px;
    width: 90%;

    .locationDemandCostBar {
      background: ${COLORS.BLUE};
    }
    .locationGasCostBar {
      background: ${COLORS.YELLOW};
    }
    .locationMonthlyFeeBar {
      background: ${COLORS.GREEN};
    }
    .locationConsumptionCostBar {
      background: ${COLORS.ORANGE};
    }
    > * {
      height: 30px;
      float: left;
    }
  }
`

export const PortfolioTicks = styled.div`
  display: flex;
  font-size: 0.8em;
  .tick {
    flex: 1;
    border-left: 1px solid ${COLORS.TBA_LIGHT_GREY};
    padding-left: 4px;
    overflow: hidden;
  }
  .tickSpacer {
    width: 180px;
  }
`
