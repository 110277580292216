import {
  selectChartInstanceId,
  selectSVGDimensions,
  useDynamicActions,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
// import { pointerPositionOverRenderArea } from './helper'
import { useCallback } from 'react'

const ChartSVG = (props: any) => {
  const actions = useDynamicActions()
  const svgDimensions = useSelector(selectSVGDimensions)
  const chartInstanceId = useSelector(selectChartInstanceId)
  const { updateMousePosition } = actions || {}
  const updatePointerCoordinates = useCallback((updateObj: any) => {
    updateMousePosition(updateObj)
  }, [])

  if (!(svgDimensions?.width > 0) && !(svgDimensions?.height > 0)) {
    return <></>
  }
  return (
    <svg
      id={chartInstanceId}
      width={"100%"}
      height={"100%"}
      style={{ overflow: 'visible' }}
      // onMouseMove={(
      //   e // Under Development for tooltip
      // ) =>
      //   pointerPositionOverRenderArea(
      //     e,
      //     updatePointerCoordinates,
      //     chartInstanceId
      //   )
      // }
      // onMouseLeave={() => updateMousePosition(null)}
    >
      <g>
        {props?.children}
      </g>
    </svg>
  )
}

export default ChartSVG
