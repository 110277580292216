export function defaultTootipFormatter(data: any): TooltipData[] {
  return [
    {
      items: [
        {
          content: `${data?.active?.name} - ${data?.active?.value}`
        }
      ]
    }
  ]
}
