import styled from 'styled-components'
import colors from 'src/components/layouts/colors.json'

export const LegendWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 30px;
margin-top: 15px;
`
export const TraneLegend = styled.div`
background-color: ${colors.TBA_DARK_GREEN};
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
`
export const TraneActionText = styled.span`
  font-size: 13px;
`
export const IdentifiedActionText = styled.span`
  font-size: 13px;
`
export const IdentifiedLegend = styled.div`
background-color: ${colors.TBA_DARK_GREY};
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;
`