import { useRef, useState } from 'react'
import ClickOutside from 'src/components/legacy/components/decorators/click-outside'
import { Wrapper } from './style'

const MENU_BUTTON_CLASSNAME = 'hamburger-small'

const HamburgerMenu = ({ toggleOpen, children, disabled, open, refFunc }) => {
  const refs = useRef(null)
  const [menuFullHeight, setMenuFullHeight] = useState()

  const handleMenu = () => {
    refFunc(refs.current)
    !disabled && toggleOpen()
  }

  /* Calculate actual height of menu content for slidedown transition effect
   ** based on changing max-height of area (100% doesn't work)
   */
  const getMenuFullHeight = (node) => {
    const height = node && node.getBoundingClientRect().height
    setMenuFullHeight(height)
  }

  const openMenu = open ? 'opened' : ''
  const menuHeight = openMenu ? menuFullHeight || 0 : 0

  return (
    <Wrapper>
      <div className={`hamburger-menu ${openMenu}`} ref={refs}>
        <button
          disabled={disabled}
          onClick={handleMenu}
          className={`${MENU_BUTTON_CLASSNAME} ${openMenu}`}
        >
          <span className="icon icon-menu" />
        </button>
        <div
          style={{ maxHeight: `${menuHeight}px` }}
          onClick={() => toggleOpen()}
        >
          <ul ref={getMenuFullHeight}>{children}</ul>
        </div>
      </div>
    </Wrapper>
  )
}

export default ClickOutside(HamburgerMenu)
