export const GET_USER_BY_ID = `
query GET_USER_BY_ID($id: ID!) {
  getUser(id: $id) {
    id
    email
    firstName
    lastName
    updatedAt
    defaultUniqueAssetId
    createdAt
    userTypeId
    jobTitle
    industry
    phoneNumber
    primarySalesOfficeId
    profileSettings
    type {
      name
      id
      authUserTypes
    }
  }
}
`

export const GET_UI_ACCESS_TOKENS = `
query GET_UI_ACCESS_TOKENS {
    getUIAccessTokens
}
`

export const GET_EULA_VERSION = `
query GET_EULA_VERSION($body: AWSJSON) {
  getLatestFile(body: $body)
}
`
