import { getSession } from 'src/redux/slicers/appData'
import { openVPNURL } from '../../aws-exports'

const fetchGetAPI = async (url, method, data) => {
  try {
    const token = await getSession(true)
    const result = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      },
      ...(method === 'POST'
        ? {
            body: JSON.stringify(data)
          }
        : {})
    })
    const resultDetails = await result.json()
    return resultDetails
  } catch (e) {
    return null
  }
}

export const getHostName = async (serialNumber) => {
  try {
    const url = `${openVPNURL}/${serialNumber}/hostname`
    const hostnameDetails = await fetchGetAPI(url, 'GET')
    return hostnameDetails?.hostName || null
  } catch (e) {
    return null
  }
}

export const connectToDevice = async (serialNumber, hostName) => {
  try {
    const url = `${openVPNURL}/${serialNumber}/connect`
    const data = {
      hostName: hostName
    }
    const connectionDetails = await fetchGetAPI(url, 'POST', data)
    return connectionDetails
  } catch (e) {
    return null
  }
}

export const disconnectUser = async (userMailId, hostName) => {
  try {
    const url = `${openVPNURL}/${userMailId}/disconnectUser`
    const data = {
      hostName: hostName
    }
    const connectionDetails = await fetchGetAPI(url, 'POST', data)
    return connectionDetails
  } catch (e) {
    return null
  }
}

export const getConnectedHost = async (email = '') => {
  try {
    const url = `${openVPNURL}/${email}/hostname`
    const connectedHostDetails = await fetchGetAPI(url, 'GET')
    return connectedHostDetails
  } catch (e) {
    return null
  }
}
