import { Text, View } from "@react-pdf/renderer"
import PropTypes from "prop-types"
import moment from "moment"
import { DATE_FORMAT } from "../../legacy/common/time-helpers"
import { headerStyles } from "./pdf-header.styles"

const PDFTitle = ({ itemTitle, pageTitle, breakProp, proposalDate }) => {
	const { date, titles, pTitle, iTitle, titleWrapper, titlesWrapper, dateWrapper } = headerStyles

	return	(
		<View style={titleWrapper} break={breakProp}>
			<View style={titles}>
				<View style={titlesWrapper}>
					<Text style={pTitle}>{pageTitle}{itemTitle && ":"}</Text>
					<Text style={iTitle}>{itemTitle}</Text>
				</View>
				{proposalDate && <View style={dateWrapper}>
					<Text style={date}>{moment(proposalDate).format(DATE_FORMAT)}</Text>
				</View>}
			</View>
		</View>
	)
}

PDFTitle.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	itemTitle: PropTypes.string,
	breakProp: PropTypes.bool,
	proposalDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
}

PDFTitle.defaultProps = {
	itemTitle: "",
	breakProp: false
}


export default PDFTitle
