import { useCallback, useEffect, useState } from 'react'
import { Page } from './pages'
import Modal from 'src/components/legacy/components/modal/modal'
import initLanguageSetup, { setCorrectLanguage } from './i18next.setup.js'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { getSearchParams } from 'src/common/helperFunctions.js'
import {
  GET_USER_BY_ID,
  GET_UI_ACCESS_TOKENS,
  GET_EULA_VERSION
} from './graphql/userQueries'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { LoadTimeContext } from 'src/context'
import { parseUserData } from 'src/parseUserData'
import { isUndefined } from 'src/components/legacy/common/helpers'
import { validate as isValidUUID } from 'uuid'
import {
  selectUserInfo,
  setUserInfo,
  selectLanguage,
  setUserAccess,
  setUserRoles,
  selectUserRoles,
  setUiMode,
  setDismissedOnboarding,
  setSelectedLanguage
} from './redux/slicers/appData'
import { getDashboards } from './redux/slicers/dashboard'
import { clearErrors, selectError } from './redux/slicers/globalErrorSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Auth } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Hub } from 'aws-amplify'
import { APP_ID, signOutUrl, staticFilesPath } from './aws-exports'
import ErrorDialog from 'src/components/legacy/components/error-dialog/error-dialog.jsx'
import UnauthorizedUserScreen from './pages/unauthorizedUser'
import { trackEvent, identifyEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import store from './redux/store'
import { UPDATE_USER_SESSION } from './graphql/userMutations'
import { UiMode } from './redux/types/AppTypes'
import Button from 'src/denali-ui/components/Button'
import { UPDATE_OWN_SELF } from './components/Header/components/queries'

const App = ({ errorMessage }) => {
  // The following lines are required due to bfcache (back/forward browser cache). Without them, signing out and pressing back will bring the user back to the logged in state.
  window.addEventListener('unload', function () {})
  window.addEventListener('beforeunload', function () {})
  const [errorMessageState, setErrorMessageState] = useState(errorMessage)
  const language = useSelector(selectLanguage)
  const globalError = useSelector(selectError)
  const userRoles = useSelector(selectUserRoles)
  const [page, setPage] = useState<string>('loading')
  const [userSignedInState, setUserSignedInState] = useState('checking')
  const customProvider = 'b2c'
  const [firstSignIn, setFirstSignIn] = useState(false)
  const [showInvalidIdMessage, setShowInvalidIdMessage] = useState(false)
  const [shouldSendAmplitude, setShouldSendAmplitude] = useState(false)
  Hub.listen('auth', (data) => {
    if (data.payload.event === 'customState_failure') {
      if (data.payload.data.message == 'invalid_grant') {
        setErrorMessageState(
          `We have encountered an error. Please check your application navigation url. If a bookmark is used to navigate to the app, please ensure the bookmark points to ${window.location.origin}`
        )
      } else {
        setErrorMessageState(data.payload.data.message)
      }
      setPage('error')
    } else if (data.payload.event === 'signIn_failure') {
      setPage('error')
    } else if (data.payload.event === 'signIn') {
      setFirstSignIn(true)
    } else if (data.payload.event === 'updateUserAttributes_failure') {
      Auth.signOut()
    }
  })

  useEffect(() => {
    const authFunc = async () => {
      await Auth.currentSession().catch((err) => {
        // Throwing a log here just in case we have an error we don't handle correctly.
        if (err === 'No current user') {
          setUserSignedInState('not signed in')
        }
      })
    }
    authFunc()
  }, [])

  useEffect(() => {
    if (userSignedInState === 'not signed in' && page === 'loading') {
      if (!errorMessageState) {
        localStorage.setItem('beforeAuthURL', window.location.href)
        Auth.federatedSignIn({
          customProvider: customProvider
        })
      } else {
        setPage('error')
      }
    }
  }, [userSignedInState])

  const { user, signOut } = useAuthenticator()
  const [loadContext, setLoadContext] = useState<any>([])
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)
  const {
    data: userData,
    refetch,
    loading: loadingGetUser
  } = useQuery({
    query: GET_USER_BY_ID,
    variables: { id: user?.attributes?.['custom:userId'] || '' },
    disableInitialLoad: true,
    onSuccess: (rawUserInfo) => {
      const data = parseUserData(rawUserInfo)
      if (rawUserInfo?.profileSettings) {
        try {
          const settings = JSON.parse(rawUserInfo?.profileSettings)
          if (settings.language) {
            dispatch(setSelectedLanguage(settings.language))
          }
        } catch (e) {
          console.log(e)
        }
      }
      dispatch(
        setUserInfo({
          data
        })
      )
      if (data?.id) dispatch<any>(getDashboards())
    },
    errorPolicy: 'ignore',
    dataPath: 'data.getUser'
  })

  const { onSubmit: updateOwnUser } = useMutation({
    query: UPDATE_OWN_SELF,
    dataPath: 'data',
    onSuccess: (data) => {
      refetch()
    }
  })

  const { refetch: refreshUserSession, loading: loadingUserSessionUpdate } =
    useQuery({
      query: UPDATE_USER_SESSION,
      disableInitialLoad: true
    })

  const {
    data: eulaData,
    refetch: refreshEULA,
    loading: loadingEULA
  } = useQuery({
    query: GET_EULA_VERSION,
    variables: { body: JSON.stringify({ prefix: 'traneconnect/EULA/' }) },
    dataPath: 'data.getLatestFile'
  })
  const {
    data: userAccesses,
    refetch: userAccessRefetch,
    loading: userAccessesLoading
  } = useQuery({
    query: GET_UI_ACCESS_TOKENS,
    disableInitialLoad: true,
    onSuccess: (userAccessInfo) => {
      try {
        const parsedData = JSON.parse(JSON.parse(userAccessInfo))
        const parsedBody = JSON.parse(parsedData.body)
        const items = parsedBody?.data?.listUserAccessesByUserId?.items || []
        const UIPermMap = new Map([])
        const ListOfRoles = []

        for (let i = 0; i < items.length; i++) {
          if (ListOfRoles.indexOf(items[i].role?.name) === -1) {
            ListOfRoles.push(items[i].role?.name)
          }
          for (let j = 0; j < items[i].role.permissions.items.length; j++) {
            if (
              !UIPermMap.has(items[i].role.permissions.items[j].permission.name)
            ) {
              UIPermMap.set(
                items[i].role.permissions.items[j].permission.name.replace(
                  '[resource]',
                  items[i].resource
                ),
                true
              )
            }
          }
        }
        dispatch(setUserAccess(UIPermMap))
        dispatch(setUserRoles(ListOfRoles))
        if (
          UIPermMap.has('tc.poc.clockwork-theme') &&
          localStorage.getItem('uiMode') !== 'legacy'
        ) {
          dispatch(setUiMode(UiMode.denali))
          identifyEvent({ 'user interface': 'denali' })
          trackEvent(USER_EVENTS.NAVIGATION.events.CLICK_CHANGE_UI, {
            'changed to': 'denali'
          })
        }
        const dismissedOnboardingKey = localStorage.getItem(
          'dismissedOnboarding'
        )
        dispatch(setDismissedOnboarding({ key: dismissedOnboardingKey }))
      } catch (error) {}
    },
    errorPolicy: 'ignore',
    dataPath: 'data.getUIAccessTokens'
  })

  useEffect(() => {
    const firstSignInFunc = async () => {
      await refreshUserSession().then(async (res) => {
        if (res?.data?.updateUserSession) {
          const cognitoUser = await Auth.currentAuthenticatedUser()
          const currentSession = await Auth.currentSession()
          await cognitoUser.refreshSession(
            currentSession['refreshToken'],
            async (err, session) => {
              if (!err) {
                await refetch()
                await userAccessRefetch()
                setShouldSendAmplitude(true)
                setFirstSignIn(false)
              } else {
                console.log(err)
              }
            }
          )
        } else {
          setPage('error')
          setErrorMessageState(
            "Unable to update user's access. Please try again or contact support."
          )
        }
      })
    }

    const checkOrgAndLocationIds = () => {
      // need to remove this check after sometime, once all users are started using Denali platform
      const { buildingId, organizationId, salesOffice } = getSearchParams()
      // if it is dashboard page, check for dashboard id first, it will not have location or org
      if (window.location?.pathname?.includes('/dashboard')) {
        const dashboardId = window.location.pathname?.split('/')?.[2] || ''
        if (dashboardId && !isValidUUID(dashboardId)) {
          setShowInvalidIdMessage(true)
          return
        }
      }
      if (window.location?.pathname?.includes('/building-setup')) {
        const buildingId = window.location.pathname?.split('/')?.[2] || ''
        if (buildingId && buildingId !== 'add' && !isValidUUID(buildingId)) {
          setShowInvalidIdMessage(true)
          return
        }
      }
      if (buildingId && !isValidUUID(buildingId)) {
        setShowInvalidIdMessage(true)
        return
      }
      if (organizationId && !isValidUUID(organizationId)) {
        setShowInvalidIdMessage(true)
        return
      }
      if (salesOffice && !isValidUUID(salesOffice)) {
        setShowInvalidIdMessage(true)
        return
      }
    }

    const callUserRefetch = async () => {
      if (user && eulaData) {
        const apps =
          (user?.['signInUserSession']?.idToken?.payload?.apps &&
            JSON.parse(user?.['signInUserSession']?.idToken?.payload?.apps)) ||
          []
        if (firstSignIn) {
          firstSignInFunc()
        } else {
          if (page !== 'error') {
            if (!apps.includes(APP_ID)) {
              setPage('error')
              setErrorMessageState(
                'You do not have access to this application.'
              )
            } else {
              if (!userInfo && !firstSignIn && !loadingGetUser) {
                await refetch().then((u) => {
                  if (!u) {
                    setPage('error')
                  }
                })
              }
              if (userInfo && page !== 'error') {
                try {
                  if (userInfo.profileSettings) {
                    const EULAData = JSON.parse(JSON.parse(eulaData).body)
                    const profileSettings = JSON.parse(userInfo.profileSettings)
                    if (
                      profileSettings?.tc?.EULA &&
                      profileSettings.tc.EULA.file === EULAData.Key &&
                      profileSettings.tc.EULA.date === EULAData.LastModified
                    ) {
                      setPage('main')
                      checkOrgAndLocationIds()
                    } else {
                      setPage('termsAndConditions')
                    }
                  } else {
                    setPage('termsAndConditions')
                  }
                } catch (e) {
                  console.log(e)
                }
              }
              if (!userAccesses && !firstSignIn && !userAccessesLoading) {
                await userAccessRefetch()
              }
            }
          }
        }
      }
    }

    callUserRefetch()
  }, [user, userInfo, firstSignIn, eulaData])

  const navigateToBeforeAuthURL = () => {
    const beforeAuthURL = localStorage.getItem('beforeAuthURL')
    if (beforeAuthURL) {
      window.location.href = beforeAuthURL
    }
  }

  const momoizedSignOutFn = useCallback(async () => {
    signOut()
  }, [signOut])

  useEffect(() => {
    if (!firstSignIn && userData && userRoles?.length) {
      const userDataToSend = {
        'user id': store?.getState()?.appData?.userInfo?.data?.id,
        'user type': store?.getState()?.appData?.userInfo?.data?.type?.name,
        'role names': userRoles,
        'client id': 'Trane Connect',
        'job title': store?.getState()?.appData?.userInfo?.data?.jobTitle,
        'primary sales office':
          store?.getState()?.appData?.userInfo?.data?.primarySalesOfficeId,
        version: process.env?.REACT_APP_AWS_COMMIT_ID
          ? process.env?.REACT_APP_AWS_COMMIT_ID.substring(0, 7)
          : ''
      }
      identifyEvent(userDataToSend)
      if (shouldSendAmplitude) {
        trackEvent(USER_EVENTS.LOGIN.events.USER_LOGIN)
        setShouldSendAmplitude(false)
        navigateToBeforeAuthURL()
      }
    }
  }, [userData, userRoles, firstSignIn, shouldSendAmplitude])

  const [loadedLanguages, setLoadedLanguages] = useState(false)

  const closeInvalidIdModal = () => {
    setShowInvalidIdMessage(false)
  }

  useEffect(() => {
    const setUpDefaults = async () => {
      await initLanguageSetup()
      await setCorrectLanguage()
    }
    setUpDefaults().then(() => setLoadedLanguages(true))
  }, [])

  if (
    !loadedLanguages ||
    isUndefined(userInfo) ||
    firstSignIn ||
    loadingUserSessionUpdate
  )
    return <Spinner />

  return page === 'main' && !userAccessesLoading ? (
    <>
      {showInvalidIdMessage && (
        <Modal
          heading="Item Not Found"
          buttons={[
            {
              type: 'confirm',
              text: 'Close',
              handleClick: closeInvalidIdModal
            }
          ]}
          handleClose={closeInvalidIdModal}
        >
          {/* using <a> tag, since this is outside router, as we may remove this after sometime, not to spend much time here */}
          <div>
            The page you are looking for is not available. The location of the
            item may have changed. If you have used a bookmark or shared
            hyperlink for navigation, select the page from the left sidebar and
            bookmark the page location or <a href="/">click here </a> to go to
            Home page.
          </div>
        </Modal>
      )}
      <LoadTimeContext.Provider value={{ loadContext, setLoadContext }}>
        {globalError && (
          <ErrorDialog
            handleClose={() => dispatch<any>(clearErrors())}
            text={globalError.error}
          />
        )}
        <Page
          termsUrl={`${staticFilesPath}/${
            JSON.parse(JSON.parse(eulaData).body).Key
          }`}
          key={language}
          signOut={momoizedSignOutFn}
        />
      </LoadTimeContext.Provider>
    </>
  ) : page === 'termsAndConditions' ? (
    <UnauthorizedUserScreen
      textHeader={'Terms & Conditions'}
      textDescription={
        <>
          In order to continue, you must accept and agree to be bound by the
          Trane Connect{' '}
          <a
            href={`${staticFilesPath}/${
              JSON.parse(JSON.parse(eulaData).body).Key
            }`}
            target="_blank"
            rel="noreferrer"
          >
            End-User License Agreement Terms and Conditions
          </a>
          .
        </>
      }
      customButtons={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            variant="secondary"
            size="medium"
            onClick={() =>
              user
                ? signOut()
                : () => {
                    window.location.href = signOutUrl
                  }
            }
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="medium"
            onClick={() => {
              const EULAData = JSON.parse(JSON.parse(eulaData).body)
              const profileSettings = {
                ...(userInfo.profileSettings
                  ? JSON.parse(userInfo.profileSettings)
                  : {})
              }
              profileSettings.tc = { ...(userInfo.profileSettings?.tc || {}) }
              profileSettings.tc.EULA = {
                file: EULAData.Key,
                date: EULAData.LastModified,
                dateSigned: new Date().valueOf()
              }
              const data = {
                ...userData,
                profileSettings: { ...profileSettings }
              }
              updateOwnUser({ requestBody: JSON.stringify(data) })
            }}
          >
            I Agree
          </Button>
        </div>
      }
      logoutHandler={
        user
          ? signOut
          : () => {
              window.location.href = signOutUrl
            }
      }
    />
  ) : page === 'error' ? (
    <UnauthorizedUserScreen
      textHeader={errorMessageState ? 'Error' : undefined}
      textDescription={errorMessageState ? errorMessageState : undefined}
      logoutHandler={
        user
          ? signOut
          : () => {
              window.location.href = signOutUrl
            }
      }
    />
  ) : (
    <Spinner />
  )
}

export default App
