import React from 'react'
import PropTypes from 'prop-types'

const HeadlineView = ({ title, description, children, className }) => (
  <div className={className}>
    <h3>{title}</h3>
    {description && <p>{description}</p>}
    {children && <div>{children}</div>}
  </div>
)

HeadlineView.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}

export default HeadlineView
