export const GET_PROPERTY_META_DATA = `
query listPropertyMetadata($filter: ModelPropertyMetadataFilterInput
    $nextToken: String
   ) {
    listPropertyMetadata(
      filter: $filter
      limit:1000
      nextToken: $nextToken
    ) {
      items {
        id
        brick
        isMappable
        isNonStandard
        isRDR
        pId
        sourceKey
        targetKey
        tisDisplayName
        haystack
      }
      nextToken
    }
  }
  `

export const SEARCH_PROPERTY_META_DATA = `
    query searchPropertyMetadata($filter: SearchablePropertyMetadataFilterInput, $nextToken: String) {
      searchPropertyMetadata(filter: $filter
        limit:1000, nextToken: $nextToken) {
        items {
          id
          brick
          isMappable
          isNonStandard
          isRDR
          pId
          sourceKey
          targetKey
          tisDisplayName
          haystack
        }
        nextToken
      }
    }`

export const UPDATE_PROPERTY_META_DATA = `
  mutation updatePropertyMetadata($input: UpdatePropertyMetadataInput!) {
    updatePropertyMetadata(input: $input) {
      id
      brick
      isMappable
      isNonStandard
      isRDR
      pId
      sourceKey
      targetKey
      tisDisplayName
      haystack
    }
  }
`

export const CREATE_PROPERTY_META_DATA = `
  mutation createPropertyMetadata($input: CreatePropertyMetadataInput!) {
    createPropertyMetadata(input: $input) {
      id
      brick
      isMappable
      isNonStandard
      isRDR
      pId
      sourceKey
      targetKey
      tisDisplayName
      haystack
    }
  }
`

export const DELETE_PROPERTY_META_DATA = `
  mutation deletePropertyMetadata($input: DeletePropertyMetadataInput!) {
    deletePropertyMetadata(input: $input) {
      id
    }
  }
`
