import { useState } from 'react'
import { useBreakPoint } from 'src/hooks/breakPoint'
import translate from 'src/common/translations'
import Modal from 'src/components/legacy/components/modal/modal'
import ContainerWrapper from 'src/components/Container'
import { Content } from 'src/components/layouts/'
import TextInput from 'src/components/legacy/components/modal-text-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import { SaveChartModalContainer } from './styles'
import { RadioGroup } from 'src/components'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  CREATE_NEW_BUILDING_DATA_CHART,
  UPDATE_BUILDING_DATA_CHART
} from './graphql'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import { ACTIONS } from 'src/constants'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'

export const SaveChartModal = ({
  toggleSaveChartModal,
  chartDetails,
  refreshChartList,
  calculatedPointsList,
  objectPickerSelection,
  availableCharts,
  setSuccessNotification,
  setOrRemoveDefaultHandler,
  isDefaultChartSelected,
  accountId,
  mode
}) => {
  const optionsTranslated = {
    editBuilding: translate('Edit Building Data Chart'),
    saveNew: translate('Save as new Building Data Chart'),
    save: translate('Save'),
    create: translate('Create'),
    cancel: translate('Cancel')
  }
  const { width } = useBreakPoint()

  const userInfo = useSelector(selectUserInfo)

  const {
    onSubmit: createBuildingDataChart,
    loading: loadingCreateBuildingChart
  } = useMutation({
    query: CREATE_NEW_BUILDING_DATA_CHART,
    dataPath: 'data.createBuildingDataChart'
  })

  const {
    onSubmit: updateBuildingDataChart,
    loading: loadingUpdateBuildingChart
  } = useMutation({
    query: UPDATE_BUILDING_DATA_CHART,
    dataPath: 'data.updateBuildingDataChart'
  })

  const getChartName = () => {
    if (mode === ACTIONS.ADD) {
      return `Copy of ${chartDetails?.name}`
    } else {
      return chartDetails?.name || ''
    }
  }

  const [chartValues, setChartValues] = useState({
    name: chartDetails ? getChartName() : '',
    description: chartDetails?.description || '',
    defaultChart: isDefaultChartSelected || 0,
    sharable: chartDetails?.sharable || 0
  })

  const [errors, setErrors] = useState({})

  const handleInputChange = (name, value) => {
    setChartValues({ ...chartValues, [name]: value })
    if (name === 'name') {
      const isSameExists = availableCharts?.find((x) => x?.name === value)
      if (isSameExists) {
        setErrors({ name: 'A chart with this name already exists' })
      } else if (value === '') {
        setErrors({ name: "Chart name can't be empty" })
      } else {
        setErrors({})
      }
    }
  }

  const checkAndUpdateDefaultChanged = async () => {
    const currentDefault = chartValues.defaultChart == 1
    // default field changed or not
    // call user setting only when there is a change in default field
    if (
      (isDefaultChartSelected && !currentDefault) ||
      (!isDefaultChartSelected && currentDefault)
    ) {
      setOrRemoveDefaultHandler()
    }
  }

  const handleSaveChart = async () => {
    try {
      let chartMutationResponse
      const propertiesList = {
        properties: objectPickerSelection || [],
        calculatedProperties: calculatedPointsList || []
      }
      const input = {
        ...chartValues,
        sharable: chartValues.sharable == 1 ? true : false,
        accountId: accountId,
        properties: JSON.stringify(propertiesList),
        userId: userInfo?.id
      }
      if (chartDetails && chartDetails?.id && mode === ACTIONS.EDIT) {
        await checkAndUpdateDefaultChanged()
        input.id = chartDetails?.id
        delete input.userId
        chartMutationResponse = await updateBuildingDataChart({ input })
      } else {
        chartMutationResponse = await createBuildingDataChart({ input })
      }
      if (chartMutationResponse?.id) {
        const currentDefault = chartValues.defaultChart == 1
        currentDefault && await setOrRemoveDefaultHandler(chartMutationResponse?.id, currentDefault)
        refreshChartList?.(chartMutationResponse?.id)
        setSuccessNotification({
          type: 'SaveChart',
          message: `You have sucessfully ${
            mode === ACTIONS.ADD ? 'created' : 'updated'
          } your ${chartValues?.name || ''}`,
          title: 'Success'
        })
        trackEvent(
          USER_EVENTS.UTILITY_MANAGEMENT.events.SAVE_BUILDING_DATA_CHART
        )
      }
    } catch (error) {
      console.log(error)
    } finally {
      toggleSaveChartModal()
    }
  }

  const saveChartModalConfig = {
    gray: true,
    isHideWhiteBackground: true,
    heading:
      mode === ACTIONS.EDIT
        ? optionsTranslated.editBuilding
        : optionsTranslated.saveNew,
    handleClose: toggleSaveChartModal,
    buttons: [
      {
        text:
          mode === ACTIONS.EDIT
            ? optionsTranslated.save
            : optionsTranslated.create,
        handleClick: handleSaveChart,
        type: 'save',
        disabled:
          chartValues.name.length <= 0 ||
          errors?.['name'] ||
          loadingCreateBuildingChart ||
          loadingUpdateBuildingChart
      },
      {
        text: optionsTranslated.cancel,
        handleClick: toggleSaveChartModal,
        type: 'cancel'
      }
    ]
  }

  const radioOptions = (htmlName) => [
    { label: 'Yes', value: 1, htmlFor: `${htmlName}Yes}` },
    { label: 'No', value: 0, htmlFor: `${htmlName}No}` }
  ]

  return (
    <Modal testName={'saveChart-events'} {...saveChartModalConfig}>
      {
        <ContainerWrapper
          sColumns={12}
          mColumns={12}
          lColumns={12}
          xlColumns={12}
          padding={'0px'}
          background="#eee"
        >
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
            padding={'0px'}
            background="#fff"
          >
            {loadingCreateBuildingChart || loadingUpdateBuildingChart ? (
              <Spinner />
            ) : (
              <SaveChartModalContainer>
                <TextInput
                  labelText="Data Chart Name"
                  defaultValue={chartValues?.name}
                  name="name"
                  onChange={({ target: { value, name } }) =>
                    handleInputChange(name, value)
                  }
                  hasError={errors?.['name']}
                  maxLength={250}
                  errorMessage={errors?.['name']}
                />
                <TextAreaInput
                  labelText={'Data Chart Description'}
                  defaultValue={chartValues?.description}
                  name="description"
                  onChange={({ target: { value } }) => {
                    handleInputChange('description', value)
                  }}
                  isRequired={false}
                  hasError={errors?.['description']}
                  errorMessage={errors?.['description']}
                  hideErrorIcon={false}
                  maxLength={3000}
                />
                <RadioGroup
                  isPaddingRequired={true}
                  label="Make this my default building data chart?"
                  handleSelection={({ target: { value, name } }) => {
                    handleInputChange(name, value)
                  }}
                  name="defaultChart"
                  radioKey="defaultChart"
                  options={radioOptions('defaultChart')}
                  selectedValue={chartValues?.['defaultChart']}
                />
                <br />
                <RadioGroup
                  isPaddingRequired={true}
                  label="Share this building data chart, letting everyone in this organization view, edit, copy, and delete it?"
                  handleSelection={({ target: { value, name } }) => {
                    handleInputChange(name, value)
                  }}
                  name="sharable"
                  radioKey="sharable"
                  options={radioOptions('sharable')}
                  selectedValue={chartValues?.['sharable']}
                />
              </SaveChartModalContainer>
            )}
          </Content>
        </ContainerWrapper>
      }
    </Modal>
  )
}
