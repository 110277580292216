import styles from './card-video.module.scss'

export const CardVideo = ({
  color=CardVideoColor.default,
  icon=null,
  backgroundImage=null,
  backgroundSize='contain',
  backgroundColor=null,
  backgroundPosition=null,
  gradient=null,
  tags=null,
  button=null,
  children=null,
  testId='',
  onClick,
}) => {
  return (
    <div
      className={styles.cardVideo}
      data-color={color}
      data-testid={testId !== '' ? "cardVideo" : testId}
      style={{ backgroundColor}}
      onClick={onClick}
    >
      <div className={styles.inner} >
        <div className={styles.image}
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize,
            backgroundColor,
            backgroundPosition,
          }}
        > 
          <div className={styles.tags}>
            {tags}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.copy}>
          {children}
          </div>
          <div className={styles.icon}>{icon}</div>
        </div>
      </div>
        {button}
    </div>
    
  )
}

export enum CardVideoColor {
  default='default',

}