import * as d3 from 'd3'
import { isFunction, isNumber } from 'lodash'
import { ScaleType } from '../../Types/scale'
import { SCALE_BAND, SCALE_TIME } from '../../Utils/Scales/constant'
import { d3Picker, d3Properties } from '../../Utils/common'
import { getBandWidth } from 'src/chart-library/Charts/XYChart/helper'

export const areaDualGenerator = (generatorObj: any = {}) => {
  const {
    xscaleType,
    yscaleType,
    xData = [],
    yData = [],
    xScale,
    yScale,
    invert = false,
    settings = {},
    properties = {},
    type = '',
    startFromBegining = false,
    gYScaleObj = {},
    series = {},
    clipPath = false
  } = generatorObj
  let bandWidth = 0
  const height = gYScaleObj?.range?.[0]
  if (xscaleType === 'scaleBand' && !startFromBegining) {
    bandWidth += getBandWidth(xScale) / 2
  }
  const defaultSettings: any = {
    ...settings
  }
  let xValues = xData;
  let yValues = yData;
  if (properties?.noBreak) {
    const newXValues = []
    const newYValues = []
    xData?.forEach((value: any, i: number) => {
      const yVal = yData?.[i]
      let isValidValue = true
      if (clipPath) {
        const index = series?.areaIndex === 0 ? 1 : 0
        isValidValue = isNumber(yData?.[i]?.[index]) && !isNaN(yData?.[i]?.[index])
      } else {
        const index = series?.areaIndex
        isValidValue = isNumber(yData?.[i]?.[index]) && !isNaN(yData?.[i]?.[index])
      }
      if (isValidValue) {
        newXValues.push(value)
        newYValues.push(yVal)
      }
    })
    xValues = newXValues
    yValues = newYValues
  }
  if (!defaultSettings?.['defined']) {
    defaultSettings.defined = {
      value: (d: any, i: number) =>
      isNumber(yValues?.[i]?.[0]) && !isNaN(yValues?.[i]?.[0]) && isNumber(yValues?.[i]?.[1]) && !isNaN(yValues?.[i]?.[0])
    }
  }
  if (isFunction(yScale) && isFunction(xScale) && isNumber(height)) {
    let dataPoints: any = []
    if (clipPath) {
      dataPoints = [
        dataPointFunction(xscaleType, xScale, xValues, bandWidth),
        dataPointFunction(yscaleType, yScale, yValues, 0, true, series?.areaIndex === 0 ? 1 : 0),
        0
      ]
    } else {
      dataPoints = [
        dataPointFunction(xscaleType, xScale, xValues, bandWidth),
        dataPointFunction(yscaleType, yScale, yValues, 0, true, series?.areaIndex),
        height
      ]
    }
    if (invert) {
      // Need to define
      // dataPoints = reverse(dataPoints);
    }
    const area: any = d3.area(...dataPoints)
    const newArea: any = applyAdditionalProperties(area, defaultSettings)

    return newArea(xValues)
  } else {
    return ''
  }
}

const dataPointFunction = (
  scaleType: ScaleType,
  scale: any,
  data: any = [],
  bandWidth = 0,
  range = false,
  index: any = undefined
) => {
  if (scaleType === SCALE_TIME) {
    return (d: any, i: number) => scale(new Date(data[i]))
  } else if (scaleType === SCALE_BAND) {
    return (d: any, i: number) =>
      scale(range ? data?.[i]?.[index] : data[i]) + bandWidth
  } else {
    return (d: any, i: number) => scale(range ? data?.[i]?.[index] : data[i])
  }
}

const applyAdditionalProperties = (line: any, settings: any) => {
  Object.keys(settings)?.forEach((prop: any) => {
    if (line[prop]) {
      if (settings[prop]?.value) {
        line[prop](settings[prop]?.value)
      } else {
        const type: d3Properties = settings[prop]?.type
        const additionalProp: any = d3Picker(type)
        if (settings[prop]?.settings) {
          Object.keys(settings[prop]?.settings)?.forEach((prop: any) => {
            if (additionalProp[prop]) {
              additionalProp[prop](additionalProp[prop])
            }
          })
        }
        line[prop](additionalProp)
      }
    }
  })
  return line
}
export const applyAdditionalPropertiesToShape = (
  d3shape: any,
  settings: any
) => {
  Object.keys(settings)?.forEach((prop: any) => {
    if (d3shape[prop]) {
      if (settings[prop]?.value) {
        d3shape[prop](settings[prop]?.value)
      } else {
        const type: d3Properties = settings[prop]?.type
        const additionalProp: any = d3Picker(type)
        if (settings[prop]?.settings) {
          Object.keys(settings[prop]?.settings)?.forEach((prop: any) => {
            if (additionalProp[prop]) {
              additionalProp[prop](additionalProp[prop])
            }
          })
        }
        d3shape[prop](additionalProp)
      }
    }
  })
  return d3shape
}
