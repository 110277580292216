import React from "react"
import _get from "lodash/get"
import Label from 'src/components/legacy/components/label/label'
import "./index.scss"
import styled from "styled-components"
import { notifyOptions } from "./site-data-collection-notification-settings-item"
import IconSVG from 'src/components/Icon/index.js'

const StyledFlexDiv = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`

export const StyledSection = styled.div`
	width: 100%;
	${props => props.first ? "border-top: 1px black solid;" : ""}
	border-bottom: 1px black solid;
	${props => props.last ? "margin-bottom: 20px;" : ""}
	${props => props.first ? "margin-top: 20px;" : ""}
`



export const SiteDataCollectionNotificationsSettingsDialogItemClosed = ({notification, onEdit, onDelete, first, last}) => {

	const arr = []
	if (notification?.salesOfficeIds?.length) {
		arr.push(`${notification?.salesOfficeIds?.length} Sales Offices selected`)
	}
	if (notification?.accountIds?.length) {
		arr.push(`${notification?.accountIds?.length} Organizations selected`)
	}
	if (notification?.buildingIds?.length) {
		arr.push(`${notification?.buildingIds?.length} Buildings selected`)
	}
	return (
			<>
			<StyledSection first={first} last={last}>
			<StyledFlexDiv>
				<span>
					<div className="rx-col-12 rx-col-sm-5">
						<Label text={notification.name || "N/A"} /> <span>{notification?.settings?.length && notification?.settings[0]?.nMin ? `${notifyOptions.find(e => e.conversion === notification?.settings[0]?.nMin).value}` : ""}</span>
					</div>
					<div className="rx-col-12 rx-col-sm-5">
							<span>{arr && arr.length ? arr.join(", ") : ""}</span>
					</div>
				</span>
				<span>
					<IconSVG
                      onClick={() => {
                        onEdit()
                      }}
                      name="edit"
                      color="black"
                      margin="24px 4px 0px 0px"
                      width="20px"
                      height="20px"
                    />
					<IconSVG
                      onClick={() => {
                        onDelete()
                      }}
                      name="trash2"
                      color="black"
                      margin="24px 4px 0px 0px"
                      width="20px"
                      height="20px"
                    />
				</span>
			</StyledFlexDiv>
			</StyledSection>
			</>
	)
}

SiteDataCollectionNotificationsSettingsDialogItemClosed.propTypes = {
}
