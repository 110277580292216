import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@react-pdf/renderer'
import i18next from 'i18next'
import moment from 'moment'
import PDFHeader from '../../../components/print-components/pdf-header'
import PDFTitle from '../../../components/print-components/pdf-header/pdf-title'
import PDFFooter from '../../../components/print-components/pdf-footer'
import RichText from '../../../components/print-components/pdf-rich-text'
import { DATE_FORMAT } from '../../../components/legacy/common/time-helpers'
import PDFKPIBlock from '../../../components/print-components/pdf-kpi-block'
import PDFPriorityStatusBlock from '../../../components/print-components/pdf-priority-status-block'
import PDFEquipmentBlock from '../../../components/print-components/pdf-equipment-block'
import PDFImagesBlock from '../../../components/print-components/pdf-images-block'
import { styles } from './pdf-finding-detail-view.styles'
const { dateWrapper } = styles

const PdfFindingDetailView = ({
  downloadData,
  isFooterCount,
  headerLocation,
  headerOrganization
}) => {
  const {
    creationDate,
    tisObjects,
    name,
    status,
    priority,
    nextStep = '',
    assignedTo,
    settings
  } = downloadData
  const { description, kpi, proposalDate, images } = settings

  return (
    <>
      <PDFHeader
        isFixed={true}
        locationNames={[headerLocation]}
        organizationName={headerOrganization}
      />
      {(proposalDate || creationDate) && (
        <Text fixed={true} style={dateWrapper}>
          <Text>
            {moment(proposalDate || creationDate).format(DATE_FORMAT)}
          </Text>
        </Text>
      )}
      <PDFTitle
        pageTitle={('Issue/Finding')}
        itemTitle={name}
      />
      <PDFEquipmentBlock equipments={tisObjects} />
      <PDFKPIBlock kpi={kpi} />
      <RichText note={description} />
      {images.length >= 1 && <PDFImagesBlock images={images} />}
      <PDFPriorityStatusBlock
        nextStep={i18next.t(`components:nextStepsFinding>${nextStep}`)}
        priority={priority}
        status={i18next.t(`components:findingStatuses>${status}`)}
        assignedTo={assignedTo?.key}
      />
      <PDFFooter isCountPage={isFooterCount} />
    </>
  )
}

PdfFindingDetailView.propTypes = {
  downloadData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    settings: PropTypes.shape({
      description: PropTypes.object.isRequired,
      kpi: PropTypes.array.isRequired,
      images: PropTypes.array.isRequired
    }),
    status: PropTypes.string.isRequired,
    priority: PropTypes.string.isRequired,
    nextStep: PropTypes.string.isRequired
  }),
  isFooterCount: PropTypes.bool,
  headerLocation: PropTypes.string.isRequired,
}

export default PdfFindingDetailView
