export const LEFT_LEFT = "leftLeft";
export const LEFT_RIGHT = "leftRight";
export const RIGHT_LEFT = "rightLeft";
export const RIGHT_RIGHT = "rightRight";

export const BOTTOM_BOTTOM = "bottomBottom";
export const BOTTOM_TOP = "bottomTop";
export const TOP_TOP = "topTop";
export const TOP_BOTTOM = "topBottom";

export const verticalSides = [LEFT_LEFT, LEFT_RIGHT, RIGHT_LEFT, RIGHT_RIGHT];
export const horizontalSides = [BOTTOM_BOTTOM, BOTTOM_TOP, TOP_TOP, TOP_BOTTOM];

export const SPACE_BEFORE_AXIS_NAME = 10;
export const SPACE_BEFORE_TICK_TEXT = 5;
export const SPACE_BEFORE_SYMBOL = 5;