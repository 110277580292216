import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import DatePickerSelectorColumn from './datepicker-selector-column'

const DatePickerSelectorRow = ({
  columns,
  today,
  displayBase,
  view,
  handleSelection
}) => (
  <tr className="datepicker-selector-row">
    {columns.map((column) => (
      <DatePickerSelectorColumn
        key={`col-${column.format('MM-DD-YYYY')}`}
        today={today}
        displayBase={displayBase}
        view={view}
        handleSelection={handleSelection}
        value={column}
      />
    ))}
  </tr>
)

DatePickerSelectorRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
  displayBase: PropTypes.instanceOf(moment),
  handleSelection: PropTypes.func.isRequired,
  today: PropTypes.instanceOf(moment),
  view: PropTypes.oneOf(['month', 'year'])
}

DatePickerSelectorRow.defaultProps = {
  columns: [],
  displayBase: moment.tz('UTC'),
  handleSelection: () => {},
  today: moment.tz('UTC'),
  view: 'month'
}

export default DatePickerSelectorRow
