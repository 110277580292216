/**
 * This object is shared with typography component to add font family support for all components based on prop (fontFamily)
 *
 * This object must be sync with font-family names in ./font-style.css file
 */

export const fontFamily = {
  primary: 'Arial',
  secondary: 'NeueHaasUnica-Regular',
  secondaryBold: 'NeueHaasUnica-Bold'
}
