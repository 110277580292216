// Default Options.

import { SOLID_LINE } from "../../../../../chart-library/CommonComponents/LinePath/constants";
import { getBarDataCategory, getMaxValue, getMinValue } from "../../../../../chart-library/CommonComponents/Bar/helper";
import _isEmpty from 'lodash/isEmpty'

export function useOptions(barChartData): Options {
  const barMaxValue = barChartData?.maximumBarValue ?? 0
  const data = barChartData?.barData
  const plotLinesData = barChartData?.barPlotLinesData
  const plotLineDetails = []
  plotLinesData?.forEach(plot => {
    plotLineDetails.push(!plot ? null : {
      "name": !_isEmpty(plot?.name) ? plot?.name : 'Benchmark',
      "stroke": plot?.plotLineColor,
      "strokeWidth": 5,
      "value": plot?.plotLineValue,
      "dashType": SOLID_LINE,
      text:{
        hide: plot?.plotHeaderLabelHide ?? true
      }
    })
  });
  const plotMaxValue = getMaxValue(plotLineDetails)
  const minValue = getMinValue(data)
  const maxValue = getMaxValue(data)
  return {
    xAxis: {
      type: "scaleBand",
      data: getBarDataCategory(data)
    },
    yAxis: {
      type: "scaleLinear",
      min: minValue > 0 ? 0 : minValue,
      max: barMaxValue && barMaxValue > plotMaxValue ? barMaxValue : plotMaxValue > maxValue ? plotMaxValue : maxValue,
    },
    plotAxisType: "y",
    plotLineConfig: plotLineDetails,
    grid: [{ left: "60px", right: "0px", top: "20px", bottom: "30px" }]
  };
}
