import { css } from 'styled-components'

/**
 * Method to generate styles for hover actions
 *
 * @param {*} { bgColor, moreStyles }
 * @return {*}
 */
export const onHover = (hover) => {
  return css`
    :hover,
    &.hovered {
      ${hover};
    }
  `
}

/**
 * Method to generate styles for disabled actions
 *
 * @param {*} { bgColor, moreStyles }
 * @return {*}
 */
export const onDisabled = (disabled) => {
  return css`
    :disabled,
    &[disabled],
    &.disabled {
      pointer-events: none;
      ${disabled}
    }
  `
}

/**
 * Method to generate styles for focus actions
 *
 * @param {*} { bgColor, moreStyles }
 * @return {*}
 */
export const onFocus = (focus) => {
  return css`
    :focus,
    &.focused {
      ${focus}
    }
  `
}
