import styled from 'styled-components'
import { TEXT_HOVER_2 } from 'src/components/color'
export const StyledFieldSet = styled.fieldset`
  border: none;
`
export const StyledFieldSetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .select-wrapper{
    min-width: 250px;
    max-width: 250px;
  }
  .dashboardSelectInput{
    margin-top:8px;
  }
`
export const Caption = styled.span`
  color: ${TEXT_HOVER_2};
  display: block;
  font-size: 11px;
  font-style: italic;
  letter-spacing: normal;
  padding: 0;
`
export const DashboardWrapper = styled.div`
  & .modal-text-wrapper .custom-label {
    padding: 0px !important;
  }
  margin-left: 10px;
`
export const SelectHolder = styled.div`
margin: 1rem 0;
  label{
    font-weight: 600;
    font-size: 1.2em;
    padding-bottom: 10px;
  }
`

export const DashboardCreateWrapper = styled.div`
  display: flex;
  gap: 10px;
`
export const DashboardObjectPickerWrapper = styled.div`
  width: 40%;
  .orgSelectInput {
    z-index: 100;
  }
`
