import { isNil } from 'ramda'
import { useEffect } from 'react'
import {
  selectAxes,
  selectGeneratedScales,
  selectScales,
  selectSeriesData,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
import Path from 'src/chart-library/CommonComponents/LinePath/LinePath'
import { generatorFunction } from 'src/chart-library/Shapes/picker'
import { SCALE_BAND, SCALE_TIME } from 'src/chart-library/Utils/Scales/constant'
import { useSyncSeriesPointsForTooltip } from '../../../CommonComponents/TooltipNew'
import { isNumber } from 'lodash'

const dataPointFunction = (
  scaleType,
  scale: any,
  d: any = [],
  bandWidth = 0
) => {
  if (scaleType === SCALE_TIME) {
    return scale(new Date(d))
  } else if (scaleType === SCALE_BAND) {
    return scale(d) + bandWidth
  } else {
    return scale(isNumber(d) ? d : null)
  }
}

function areaRangeTooltipModel(data, tooltipDataModel) {
  const {
    xScale = () => null,
    yScale = () => null,
    xscaleType,
    yscaleType,
    xData = [],
    yData = [],
    obj = {}
  } = data

  const {
    name,
    elementProps: { stroke = 'steelblue' },
    tooltipDataModelFormatter = () => null
  } = obj

  const bandWidth = xscaleType === 'scaleBand' ? xScale?.bandwidth() / 2 : 0

  return xData.reduce((av, xVal, i) => {
    const yVal = yData?.[i]

    const x = dataPointFunction(xscaleType, xScale, xVal, bandWidth)
    const yMin = dataPointFunction(yscaleType, yScale, yVal?.[0], bandWidth)
    const yMax = dataPointFunction(yscaleType, yScale, yVal?.[1], bandWidth)
    const convertedN = parseFloat(x?.toFixed(3))
    // if any of the value is undefined or null, these value will be rejected for tooltip
    // Developer can decide this functionality
    if (isNil(x) || isNil(yMin) || isNil(yMax)) return av

    const defaultProps = {
      // Label of the data point
      label: name,

      // value that will be displayed against the label
      value: yVal[0] + '/' + yVal[1],

      // title by default x value (usually date time )
      title: xVal,

      // x position
      x: convertedN,

      // y position - used to place the tooltip at y axis
      y: Math.min(yMin, yMax),

      // by default 'title' means based on x axis date will be grouped and displays it
      groupBy: 'title',
      color: stroke,

      // Atcual value from the data for the end user to coustimize the value property via tooltipDataModelFormatter
      // i.e tooltipDataModelFormatter : (props)=> ({...props, value: props.yValue + "kw"})
      xValue: xVal,
      yValue: yVal, // here it's array of value (min & max)
      higlightDots: true,

      // no of dots of a data point
      highlightDots: [
        {
          x: x,
          y: yMin,
          color: stroke
        },
        {
          x: x,
          y: yMax,
          color: stroke
        }
      ]
    }

    const formatter = tooltipDataModelFormatter({
      ...defaultProps,
      ...obj
    })

    av.push(
      tooltipDataModel(x, {
        // Default model values
        ...defaultProps,

        // user modified values
        ...formatter
      })
    )

    return av
  }, [])
}

const AreaRangeRenderer = (props: any) => {
  const { types = [] } = props
  const axes = useSelector(selectAxes)
  const generatedScales = useSelector(selectGeneratedScales)
  const seriesData = useSelector(selectSeriesData)

  const { addPoints, deletePoints, createTooltipPointModel } =
    useSyncSeriesPointsForTooltip()

  useEffect(() => {
    const inputModel = types?.reduce((av = [], obj: any, i: number) => {
      const xObject = (axes || [])?.find(
        (axesObj: any) => axesObj?.key === obj?.xAxisKey
      )
      const yObject = (axes || [])?.find(
        (axesObj: any) => axesObj?.key === obj?.yAxisKey
      )
      const gXScaleObj = generatedScales?.[obj?.xAxisKey]
      const gYScaleObj = generatedScales?.[obj?.yAxisKey]
      const yData = seriesData?.[obj?.seriesKey]?.data
      const xscaleType = xObject?.scale?.props?.type
      const yscaleType = yObject?.scale?.props?.type
      const xScale = gXScaleObj?.gScale
      const yScale = gYScaleObj?.gScale
      const xData = xObject?.scale?.categories

      if (obj?.type !== 'areaRange') return av

      const lineModel = areaRangeTooltipModel(
        {
          obj,
          xscaleType,
          yscaleType,
          xData,
          yData,
          xScale,
          yScale,
          invert: false,
          settings: obj?.settings,
          type: obj?.type,
          min: gYScaleObj?.domain?.[0]
        },
        createTooltipPointModel
      )

      return av.concat(lineModel)
    }, [])

    inputModel.length && addPoints(inputModel)

    return () => {
      inputModel.length && deletePoints(inputModel)
    }
  }, [generatedScales, types])

  return (
    <>
      {types?.map((obj: any, i: number) => {
        const xObject = (axes || [])?.find(
          (axesObj: any) => axesObj?.key === obj?.xAxisKey
        )
        const yObject = (axes || [])?.find(
          (axesObj: any) => axesObj?.key === obj?.yAxisKey
        )
        const gXScaleObj = generatedScales?.[obj?.xAxisKey]
        const gYScaleObj = generatedScales?.[obj?.yAxisKey]
        const yData = seriesData?.[obj?.seriesKey]?.data
        const xscaleType = xObject?.scale?.props?.type
        const yscaleType = yObject?.scale?.props?.type
        const xScale = gXScaleObj?.gScale
        const yScale = gYScaleObj?.gScale
        const xData = xObject?.scale?.categories
        const d = generatorFunction(obj?.type, {
          xscaleType,
          yscaleType,
          xData,
          yData,
          xScale,
          yScale,
          invert: false,
          settings: obj?.settings,
          properties: obj?.properties,
          type: obj?.type,
          min: gYScaleObj?.domain?.[0]
        })
        return (
          <Path key={i} dashType={obj?.dashType} {...obj?.elementProps} d={d} />
        )
      })}
    </>
  )
}

export default AreaRangeRenderer
