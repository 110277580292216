import { useEffect, useState, useMemo } from 'react'
import { useGetChartData } from './chartDataHook'
import { getStartAndEndDates } from 'src/pages/dashboards/helper'
import {
  getBackendAggrName,
  getAggreationTime
} from 'src/common/chartHelperFunctions'
// The Hook, we are planning to format data based on comparison
// Time formattting and everything
// All the widgets chart should able to use this hook to get data

export const useWidgetChartDataHook = (
  selectedTimePeriod,
  comparePointTo,
  dataAggregation,
  timeZone
) => {
  const [loadingWidgetChartData, setLoadingWidgetChartData] = useState(false)

  const [widgetChartData, setWidgetChartData] = useState([])

  const {
    getChartData: getCurrentPeriodData,
    chartData: currentPeriodChartData,
    loadingChartData: currentPeriodLoadingChartData
  } = useGetChartData()

  const {
    getChartData: getPreviousPeriodChartData,
    chartData: previousPeriodChartData,
    loadingChartData: previousPeriodLoadingChartData
  } = useGetChartData()

  const { startDate, endDate } = useMemo(
    () => getStartAndEndDates(selectedTimePeriod, false, null, timeZone),
    [selectedTimePeriod]
  )

  const { startDate: previousStartDate, endDate: previousEndDate } = useMemo(
    () => getStartAndEndDates(selectedTimePeriod, true, null, timeZone),
    [selectedTimePeriod]
  )

  const combineChartAggrData = (currentData, previousData) => {
    const currentDataFormatted = formatAggregatedData(currentData)
    const previousDataFormatted = formatAggregatedData(previousData, true)

    const finalData = {
      timeStamps: currentPeriodChartData?.timeStamps || [],
      data: [...currentDataFormatted, ...previousDataFormatted]
    }
    setWidgetChartData(finalData)
  }

  const combineSeriesData = (currentData, previousData = []) => {
    const currentDataFormatted = [...currentData]
    const previousDataFormatted = [...previousData]
    if (previousDataFormatted?.length > 0) {
      previousDataFormatted[0].isPreviousPeriod = true
    }

    const finalData = {
      timeStamps: currentPeriodChartData?.timeStamps || [],
      data: [...currentDataFormatted, ...previousDataFormatted]
    }
    setWidgetChartData(finalData)
  }

  const formatAggregatedData = (data, isPreviousPeriod) => {
    let allAggrData = []
    for (const aggregator in data) {
      const apiData = data[aggregator]
      const formattedData = apiData?.map((x) => ({
        ...x,
        isPreviousPeriod,
        aggregator
      }))
      allAggrData.push(...formattedData)
    }
    return allAggrData
  }

  const resetWidgetChartData = () => {
    setWidgetChartData([])
  }

  useEffect(() => {
    // if it is not daily, which means we have requested 15-mins series data and aggr happens in UI
    if (dataAggregation === 'hourly' || dataAggregation === '15-minutes') {
      if (
        comparePointTo === ':none' &&
        currentPeriodChartData &&
        currentPeriodChartData?.data?.length > 0
      ) {
        combineSeriesData(currentPeriodChartData?.data)
      } else if (currentPeriodChartData && previousPeriodChartData) {
        if (
          currentPeriodChartData?.data?.length > 0 &&
          previousPeriodChartData?.data?.length > 0
        ) {
          combineSeriesData(
            currentPeriodChartData?.data,
            previousPeriodChartData?.data
          )
        } else if (currentPeriodChartData?.data?.length > 0) {
          combineSeriesData(currentPeriodChartData?.data)
        } else if (previousPeriodChartData?.data?.length > 0) {
          combineSeriesData(null, previousPeriodChartData?.data)
        }
      }
    } else {
      if (
        comparePointTo === ':none' &&
        currentPeriodChartData &&
        Object.keys(currentPeriodChartData?.aggregatedData)?.length > 0
      ) {
        combineChartAggrData(currentPeriodChartData?.aggregatedData)
      } else if (currentPeriodChartData && previousPeriodChartData) {
        if (
          Object.keys(currentPeriodChartData?.aggregatedData)?.length > 0 &&
          Object.keys(previousPeriodChartData?.aggregatedData)?.length > 0
        ) {
          combineChartAggrData(
            currentPeriodChartData.aggregatedData,
            previousPeriodChartData?.aggregatedData
          )
        } else if (
          Object.keys(currentPeriodChartData?.aggregatedData)?.length > 0
        ) {
          combineChartAggrData(currentPeriodChartData.aggregatedData, null)
        } else if (
          Object.keys(previousPeriodChartData?.aggregatedData)?.length > 0
        ) {
          combineChartAggrData(null, previousPeriodChartData?.aggregatedData)
        }
      }
    }
  }, [currentPeriodChartData, previousPeriodChartData])

  useEffect(() => {
    if (!(currentPeriodLoadingChartData || previousPeriodLoadingChartData)) {
      setLoadingWidgetChartData(false)
    }
  }, [currentPeriodLoadingChartData, previousPeriodLoadingChartData])

  const getPropertyIdsArray = (
    dataAggregation,
    propertyList,
    aggregatorFunctionName
  ) => {
    if (dataAggregation === 'hourly' || dataAggregation === '15-minutes') {
      return propertyList
    } else {
      return [
        {
          [aggregatorFunctionName]: propertyList || []
        }
      ]
    }
  }

  const getChartDataRequestFormat = (
    currentTimePeriod = true,
    widgetDetails
  ) => {
    const aggregatorFunctionName =
      getBackendAggrName[widgetDetails?.selectedAggrValue]
    const chartDataRequest = {
      startDate: currentTimePeriod ? startDate : previousStartDate,
      endDate: currentTimePeriod ? endDate : previousEndDate,
      category: 'Equipment',
      interval:
        (dataAggregation === 'hourly' || dataAggregation === '15-minutes')
          ? 15
          : 1440,
      chartType: widgetDetails?.chartType,
      chartDetails: [
        {
          buildingId: widgetDetails?.buildingId,
          equipmentDetails: [
            {
              type: widgetDetails?.equipmentType,
              eId: [{ id: widgetDetails?.equipmentId }],
              pId: getPropertyIdsArray(
                dataAggregation,
                widgetDetails?.propertiesList || [],
                aggregatorFunctionName
              )
            }
          ]
        }
      ],
      aggrFunctionName : aggregatorFunctionName
    }
    if(widgetDetails?.uomDetails)
      chartDataRequest.chartDetails[0].equipmentDetails[0]["uom"] = widgetDetails?.uomDetails
    return chartDataRequest
  }

  const getWidgetChartData = async (widgetDetails) => {
    try {
      setLoadingWidgetChartData(true)
      const chartCurrentPeriodRequest = getChartDataRequestFormat(
        true,
        widgetDetails
      )
      getCurrentPeriodData(chartCurrentPeriodRequest)
      if (comparePointTo === ':previous') {
        const chartPreviousPeriodRequest = getChartDataRequestFormat(
          false,
          widgetDetails
        )
        getPreviousPeriodChartData(chartPreviousPeriodRequest)
      }
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  return {
    loadingWidgetChartData,
    getWidgetChartData,
    widgetChartData,
    resetWidgetChartData
  }
}
