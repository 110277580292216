import { useEffect, useState } from 'react'
import OrbChart from 'src/pages/widgets/widget-library/dataMonitoringOrb/OrbChart/index.tsx'
import { useWidgetChartDataHook } from 'src/hooks/widgetChartDataHook'
import { aggreagatorSelection } from 'src/common/chartHelperFunctions'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  WidgetChartTitleComponent,
  WidgetChartFooterComponent,
  NoWidgetDataAvailable
} from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { isValidUOMForGetChartData } from "../../helper";

const timeComparisionAggreagation = {
  'current-day': '15-minutes',
  'current-week': 'daily',
  'current-month': 'monthly',
  'current-quarter': 'quarterly',
  'current-year': 'yearly'
}

export const DataMonitoringOrbWidgetPreview = ({ widgetDetails }) => {
  const selectedPeriod = widgetDetails?.selectedTimePeriod

  const selectedAggrValue = widgetDetails?.selectedAggrValue

  const dataAggregation = timeComparisionAggreagation[selectedPeriod]

  const [orbData, setOrbData] = useState({})

  const { widgetChartData, getWidgetChartData, loadingWidgetChartData } =
    useWidgetChartDataHook(selectedPeriod, ':none', dataAggregation, widgetDetails?.timeZone)

  const aggrBarDataValues = (data) => {
    const finalValue = Number(
      aggreagatorSelection(selectedAggrValue, data, 0, data?.length - 1)
    ).toFixed(2)
    return finalValue
  }

  const determineColor = (value) => {
    if (widgetDetails.thresholdsValue === ':std-threshold/no-threshold') {
      return widgetDetails?.baselineColorValue
    }
  
    if (value > (parseFloat(widgetDetails.threshold2ConstantValue) || 0)) {
      return widgetDetails?.threshold2Value
    }
  
    if (value > (parseFloat(widgetDetails.threshold1ConstantValue) || 0)) {
      return widgetDetails.threshold1Value
    }
    return widgetDetails?.baselineColorValue
  }

  const formatOrbChartData = (aggrData) => {
    const aggrValue = aggrBarDataValues(aggrData?.values && aggrData?.values[0])
    const orbData = {
      data: {
        orb: aggrValue,
        orbUnit: widgetDetails?.symbol
      },
      color: determineColor(aggrValue)
    }
    setOrbData(orbData)
  }

  useEffect(() => {
    try {
      // if either of data fetch is true, just returns -> no plotting until we get all required data
      if (loadingWidgetChartData) {
        return
      }

      const isDataAvailable = widgetChartData?.data?.some(
        (x) => x?.isDataAvailable
      )

      if (
        widgetChartData &&
        isDataAvailable &&
        widgetChartData?.data?.length > 0
      ) {
        const orbData = widgetChartData?.data[0] || {}
        formatOrbChartData(orbData)
      }
    } catch (error) {}
  }, [widgetChartData, loadingWidgetChartData])

  const initializeData = async () => {
    const widgetInfo = {
      comparePointTo: ':none',
      chartType: 'Line',
      interval: 1440,
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: selectedAggrValue,
      buildingId: widgetDetails?.buildingId,
      propertiesList: [widgetDetails?.property]
    }
    if (isValidUOMForGetChartData(widgetDetails?.unitsOfMeasure)) {
      widgetInfo["uomDetails"] = { [widgetDetails?.property]: widgetDetails?.unitsOfMeasure }
    }
    getWidgetChartData(widgetInfo)
  }

  useEffect(() => {
    if (widgetDetails?.property) {
      initializeData()
    }
  }, [widgetDetails])

  return loadingWidgetChartData ? (
    <Spinner />
  ) : orbData && orbData?.data?.orb ? (
    <>
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName={widgetDetails?.equipmentName}
        equipmentType={widgetDetails?.equipmentType}
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDiscription}
        widgetProperty={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      <OrbChart
        data={orbData?.data}
        color={orbData?.color}
        // isLargeSize={true} - use this to change font size
      />
      <WidgetChartFooterComponent
        aggregatorName={selectedAggrValue}
        selectedPeriod={selectedPeriod}
        comparisonBar={true}
        aggragatorInterval={dataAggregation}
      />
    </>
  ) : (
    <NoWidgetDataAvailable
      buildingName={widgetDetails?.buildingName}
      equipmentName={widgetDetails?.equipmentName}
      equipmentType={widgetDetails?.equipmentType}
      aggregatorName={selectedAggrValue}
      selectedPeriod={selectedPeriod}
      dataAggregation={dataAggregation}
      widgetType="icon-linewidget"
      equipmentDetails={widgetDetails?.equipmentList}
      widgetDescription={widgetDetails?.widgetDiscription}
      timeZone={widgetDetails?.timeZone}
      accountName={widgetDetails?.accountName}
    />
  )
}
