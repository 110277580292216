import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'
import {
  PanelLabel,
  EnergyDemandConfigStyles,
  RadioButtonWrapper
} from '../../../styles'
import { useFormikContext } from '../../MultiTabTorm'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import {
  getEnergyDemandCompareToList,
  getEnergyDemandTimePeriodList,
  isValidRateIncrease
} from '../../../helpers'
import {
  TARGET_MARKERS_CONSTANT,
} from 'src/common/target-markers'
import { RadioGroup } from 'src/components'
import * as Yup from 'yup'
import i18next from 'i18next'
import RateIncreaseRange from './RateIncrease'
import _isEmpty from 'lodash/isEmpty'
import translate, { TranslateComponent } from 'src/common/translations'

export const EnergyDemandConfigurationSchema = Yup.object().shape({
  benchMarkerValue: Yup.string().test(
    'testBenchMarkerValue',
    "This value is required.",
    testBenchMarkerValue
  ),
  rateIncreaseErrorMsg: Yup.string().test(
    'testRateIncreaseValue',
    '',
    testRateIncreaseValue
  )
})

function testRateIncreaseValue(value) {
  const {
    createError
  } = this
  const { isRateIncrease } = this?.parent
  if (isRateIncrease === true || isRateIncrease === '1') {
    if (value === 'timeRequired') {
      return createError({ message: "Time is required." });
    }
    if (value === 'periodsOverlapping') {
      return createError({ message: "Rate periods cannot overlap" });
    }
    if (value === 'endTimeBeforeStart') {
      return createError({ message: "Start time must be before end time" });
    }
  }

  return true;
}

function testBenchMarkerValue(value) {
  const {
    parent: { benchMarker }
  } = this
  return !(benchMarker === TARGET_MARKERS_CONSTANT && value === undefined)
}

export const EnergyDemandConfiguration = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, errors, setFieldValue } = formik

  const handleInputChange = (key, value) => {
    setFieldValue(key, value)
  }
  const getOptions = (options = []) => {
    const radioFields = [
      { label: "Yes", value: '1' },
      { label: 'No', value: '0' }
    ].map((option: any, index: number) => ({
      ...option,
      htmlFor: options?.[index]
    }))
    return radioFields
  }

  return (
    <EnergyDemandConfigStyles>
      <div className="tab-page-content">
        <SelectFormInput
          labelName={"Time Period"}
          optionsList={getEnergyDemandTimePeriodList(t)}
          placeholder={'— Select One —'}
          update={(value) => {
            setFieldValue('timePeriod', value)
          }}
          errorItem={errors?.timePeriod}
          selectedItem={t(`time>${values?.timePeriod}`, {
            nsSeparator: '>'
          })}
        />
        <PanelLabel className="tip">
        <TranslateComponent>To enable cost segmentation viewing, select a period greater than 7 days</TranslateComponent>
        </PanelLabel>
        <SelectFormInput
          labelName={"Compared to"}
          optionsList={getEnergyDemandCompareToList(t)}
          placeholder={'— Select One —'}
          update={(value) => {
            setFieldValue('comparedTo', value)
          }}
          errorItem={errors?.comparedTo}
          selectedItem={t(`time>${values?.comparedTo}`, {
            nsSeparator: '>'
          })}
        />
        <RadioButtonWrapper>
          <RadioGroup
            label={`Show Periods with Rate Increases?`}
            handleSelection={({
              target: { value, name }
            }: ChangeEvent<HTMLInputElement>) => {
              isValidRateIncrease(values?.rateIncrease, values?.isRateIncrease, setFieldValue)
              handleInputChange(name, value)
            }}
            radioKey="isRateIncrease"
            options={getOptions(['new-browser-yes', 'new-browser-no'])}
            selectedValue={values?.isRateIncrease}
          />
        </RadioButtonWrapper>
        <div
          className={(values?.isRateIncrease === true || values?.isRateIncrease === '1') ? '' : 'disable-controls'}
        >
          <RateIncreaseRange
            showError={(fieldName) => {
              setTimeout(() => {                
                setFieldValue('rateIncreaseErrorMsg', fieldName)
              }, 10)
            }}
            rateIncreasesData={values?.rateIncrease}
            rateIncreaseChange={(t) => {
              isValidRateIncrease(t, values?.isRateIncrease, setFieldValue)
              setFieldValue('rateIncrease',t)
            }}
          />
          <fieldset>
            <p className='error'><TranslateComponent>{errors?.rateIncreaseErrorMsg}</TranslateComponent></p>
          </fieldset>
        </div>
      </div>
    </EnergyDemandConfigStyles>
  )
}

export default EnergyDemandConfiguration
