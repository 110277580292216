import PropTypes from 'prop-types'
import React, { memo } from 'react'

import { createButtonStyle } from '../../styles/styleCreators'
import { shouldForwardProp } from '../../utils'
import { typographyFontFamily } from '../../ui-system/typography'
import color from '../../ui-system/color'

// --------------------------------------------- Create Base Button ---------------------------------------------
const styles = createButtonStyle()

const ButtonRoot = shouldForwardProp('button', [
  'backgroundColor',
  'bg',
  'color',
  'dark',
  'endIcon',
  'fontFamily',
  'fullWidth',
  'size',
  'startIcon',
  'variant'
])`
  ${typographyFontFamily}
  ${styles}
  ${color}
`
// --------------------------------------------- Component ---------------------------------------------

const Button = React.forwardRef(function Button(inProps, ref) {
  const { children, startIcon, endIcon } = inProps
  return (
    <ButtonRoot fontFamily="primary" ref={ref} {...inProps}>
      {startIcon}
      {children}
      {endIcon}
    </ButtonRoot>
  )
})

// --------------------------------------------- Prop validation ---------------------------------------------

Button.propTypes = {
  /**
   * The component used for the root node.
   */
  as: PropTypes.elementType,

  /**
   * Shorthand prop of backgroundColor to change background color of button.
   */
  bg: PropTypes.string,

  /**
   * The backgorund color of the component.
   */
  backgroundColor: PropTypes.string,

  /**
   * The content of the component.
   */
  children: PropTypes.node,

  /**
   * The color of the component.
   */
  color: PropTypes.string,

  /**
   * Allows to set dark theme version of button
   */
  dark: PropTypes.bool,

  /**
   * If true, the component is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * Element placed after the children.
   */
  endIcon: PropTypes.node,

  /**
   * If true, the button will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,

  /**
   * The size of the component.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),

  /**
   * Element placed before the children.
   */
  startIcon: PropTypes.node,

  /**
   * Applies the theme button styles.
   */
  variant: PropTypes.oneOfType([
    PropTypes.oneOf([
      'primary',
      'secondary',
      'tertiary',
      'outlined',
      'text',
      'link'
    ]),
    PropTypes.string
  ])
}

Button.defaultProps = {
  dark: false,
  disabled: false,
  endIcon: null,
  fullWidth: false,
  size: 'medium',
  startIcon: null,
  variant: 'primary'
}

export default memo(Button)
