import { Text, View } from "@react-pdf/renderer"
import PropTypes from "prop-types"
import { bigPictureStyles } from "./pdf-big-picture.styles"
import { formatCurrency } from "src/components/legacy/common/currency"
import RichText from '../../../../../components/print-components/pdf-rich-text'
import COLORS from 'src/components/legacy/common/colors.json'
import PDFIconSVG from '../pdf-icon'

const PDFBigPicture = ({ bigPicture: { title, value }, highlights }) => {
	const { bigPictureWrapper, consultationTexts, consultationHighLights, sHeadline, bigPictureEditorWrapper, sHighlight, sHighlightFirst, sHighlightTitle, sCurrency, sHighlightContent, sHighlightIcon } = bigPictureStyles
	const showHighlightFunc = ({ title, impactAmount, value, impactText }) => {
    try {
      const impactTextObj = JSON.parse(impactText)

      return (
        title?.length ||
        impactAmount ||
        value?.blocks?.map(({ text }) => text)?.join('') ||
        impactTextObj?.blocks?.map(({ text }) => text)?.join('')
      )
    } catch (error) {
      console.error('Invalid JSON:', impactText)
      return false
    }
  }
	const bigPictureTexts = (title || value) && <View style={consultationTexts}>
		<Text style={sHeadline}>{title}</Text>
		<View style={bigPictureEditorWrapper}>
			<RichText note={value} />
		</View>
	</View>

	const highlightSection = <View style={consultationHighLights} wrap={false}>
		{highlights.filter(showHighlightFunc).map(({ title, impactAmount, value, impactAmountType, impactText, impactType }, key) => (
			<View key={key} style={key ? sHighlight : { ...sHighlight, ...sHighlightFirst }}>
				<View style={sHighlightTitle}>
          <View style={sHighlightIcon}>
            <PDFIconSVG
              name={impactType}
              color={COLORS.WHITE}
              hover={COLORS.WHITE}
              float="center"
              width="60px"
              height="60px"
              viewBox="0 0 60 70"
            />
          </View>
					<Text>{title}</Text>
				</View>
				<View style={sHighlightContent}>
					<Text style={sCurrency}>{impactAmount && formatCurrency(impactAmount, impactAmountType === 'USD - US Dollar' ? "usd" : impactAmountType)}</Text>
					<RichText note={value} />
					<RichText note={JSON.parse(impactText)} />
				</View>
			</View>
		))}
	</View>

	return	(
		<View style={bigPictureWrapper}>
			{bigPictureTexts}
			{highlightSection}
		</View>
	)
}

PDFBigPicture.propTypes = {
	bigPicture: PropTypes.shape({
		headline: PropTypes.string,
		summary: PropTypes.string,
		bullets: PropTypes.array,
		highlights: PropTypes.array
	}).isRequired
}

PDFBigPicture.defaultProps = {
	bullets: [],
	highlights: []
}

export default PDFBigPicture
