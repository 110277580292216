export const CONFIRMATION = 'confirmation'
export const SUCCESS = 'success'
export const OPPORTUNITY_SUCCESS = 'opportunity_success'
export const ERROR = 'error'
export const CREATE = 'create'
export const UPDATE = 'update'
export const DELETE_SUCCESS = 'delete'


export const MODAL_TYPE = {
  CONFIRMATION,
  SUCCESS,
  OPPORTUNITY_SUCCESS,
  ERROR,
  CREATE,
  UPDATE,
  DELETE_SUCCESS
}

export type IModalActionType =
  | 'confirmation'
  | 'success'
  | 'opportunity_success'
  | ''
  | 'error'
