import * as yup from 'yup'
const REQUIRED = 'required'
const schema = yup.object().shape({
  // Part 1
  name: yup.string().required(REQUIRED),
  comment: yup.string().notRequired(),
  type: yup.string().required(REQUIRED),
  visibleToCustomer: yup.bool().oneOf([true, false], REQUIRED),
  allowCustomerContribution: yup.bool().oneOf([true, false], REQUIRED)
})

export default schema
