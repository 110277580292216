function generateKey(
  index = 0,
  conditionNames = [],
  getCond,
  output = [],
  prefix = ''
) {
  const conditionName = conditionNames[index]

  if (!conditionName && prefix) {
    output.push(prefix)
    return
  }

  const conditionDetails: any[] = getCond(conditionName)

  for (const cDetail of conditionDetails) {
    const cName = cDetail?.conditionName ?? ''
    const newGetCond = cDetail?.getConditionDetails ?? getCond

    generateKey(
      index + 1,
      conditionNames,
      newGetCond,
      output,
      prefix === '' ? cName : prefix + '|' + cName
    )
  }
}

export function findRequiredDetailsByKey(
  keyValuePairData = {},
  properteis = []
) {
  /* Sample return data
  
        const keys = {
  
          targetKey: {
            CircuitCount : {
              0: true,
            },
            CompressorCountCKT1: {
              1: true
            },
            CompressorCountCKT2: {
              1: true
            }
          },
          [anykey]: {
  
          }
        }
      
      */

  const keys = properteis.reduce((av, cv, idx) => {
    // Create object for each keys
    av[cv.key] = av[cv.key] ?? (av[cv.key] = {})

    const keyList = av[cv.key]

    const propertyName = cv.contains || []

    propertyName.forEach((prop) => {
      // Create object for each property
      keyList[prop] = keyList[prop] ?? (keyList[prop] = {})

      if (!keyList[prop][idx]) {
        keyList[prop][idx] = true
      }
    })

    return av
  }, {})

  let av = {}

  for (const key in keys) {
    const targetKeys = keys[key]

    for (const targetKey in targetKeys) {
      const detail = keyValuePairData[targetKey]

      if (detail === undefined) continue

      const indices = targetKeys[targetKey] ?? null

      if (indices !== null) {
        Object.keys(indices).forEach((index) => {
          // Get config by index
          const propConfig = properteis[index] ?? null

          propConfig?.callback && (av = propConfig.callback(av, detail))
        })
      }
    }
  }

  return av
}

export function findRequiredDetails(data, properteis = []) {
  /* Sample return data
  
        const keys = {
  
          targetKey: {
            CircuitCount : {
              0: true,
            },
            CompressorCountCKT1: {
              1: true
            },
            CompressorCountCKT2: {
              1: true
            }
          },
          [anykey]: {
  
          }
        }
      
      */

  const keys = properteis.reduce((av, cv, idx) => {
    // Create object for each keys
    av[cv.key] = av[cv.key] ?? (av[cv.key] = {})

    const keyList = av[cv.key]

    const propertyName = cv.contains || []

    propertyName.forEach((prop) => {
      // Create object for each property
      keyList[prop] = keyList[prop] ?? (keyList[prop] = {})

      if (!keyList[prop][idx]) {
        keyList[prop][idx] = true
      }
    })

    return av
  }, {})

  const ch = {}

  let av = {}

  for (const key in keys) {
    const targetKeys = keys[key]

    for (const targetKey in targetKeys) {
      const detail = ch[targetKey]

      if (!detail) continue

      const indices = targetKeys[targetKey] ?? null

      if (indices !== null) {
        Object.keys(indices).forEach((index) => {
          // Get config by index
          const propConfig = properteis[index] ?? null

          propConfig?.callback && (av = propConfig.callback(av, detail))
        })
      }
    }
  }

  return data.reduce((av, cv) => {
    for (const prop in keys) {
      // Check prop is in response or not
      const value = cv[prop]

      if (value !== undefined) {
        // Returns property value list associated to the prop { CKTCount: {0: true, 1: true}, COMPCount: {0:true, 1: true}}
        const props = keys[prop]

        // Returns property position in given soruce property list { 0:true, 1: true  }
        const indices = props[value] ?? null

        if (indices !== null) {
          Object.keys(indices).forEach((index) => {
            // Get config by index
            const propConfig = properteis[index] ?? null

            propConfig?.callback && (av = propConfig.callback(av, cv))
          })
        }
      }
    }

    return av
  }, {})
}

export function getConditionsForStatusCalc(
  configConditions = [],
  atConditionFn = (key: string) => []
) {
  return configConditions.reduce(
    (av, cv = '') => {
      generateKey(0, cv.split('|'), atConditionFn, av)

      return av
    },
    // default condition that applies for all tests
    ['all']
  )
}

export function genArr(count, callback) {
  const arr = []

  for (let i = 1; i <= Number(count); i++) {
    arr.push(callback ? callback(i) : i)
  }

  return arr
}

export class Util<T, AT_COND> {
  protected findByCond(scope: any): (cond: string) => Array<T> {
    return (cond) => {
      const condValue = scope[cond]

      // if given condition is not in scope, return []
      if (!condValue) return []

      const value = Array.isArray(condValue) ? condValue : [condValue]

      return value
    }
  }

  protected getKey(key: AT_COND, value: string | number): string {
    return key + '_' + String(value).replaceAll(' ', '')
  }
}
