export const PAGE_TITLE = 'Opportunities'
export const CREATE_OPPORTUNITY = 'Create Opportunity'
export const SEARCH_FIELD = 'opportunityName'
export const ROW_CONTROL_TEXT = {
  View: 'View',
  Edit: 'Edit',
  Delete: 'Delete',
  Copy: 'Copy'
}
export const CONFIRMATION = 'confirmation'
export const SUCCESS = 'success'
export const OPPORTUNITY_SUCCESS = 'opportunity_success'

export const MODAL_TYPE = {
  CONFIRMATION,
  SUCCESS,
  OPPORTUNITY_SUCCESS
}

export const PAGE_TYPE = {
  LIST: 'List', 
  ADD: 'Add',
  EDIT: 'Edit',
  COPY: 'Copy',
  VIEW: 'View'
}
