import PropTypes from 'prop-types'
import React, { memo } from 'react'
import styled from 'styled-components'

import { createFormControlStyle } from '../../styles/styleCreators'
import { useFormFieldState } from '../FromControl'

const FormHelperTextRoot = styled.p`
  color: #555555;
  font-size: 0.875rem;
  margin: 3px 0 0;
  text-align: left;
  ${createFormControlStyle({ helperText: true })}
`

const FormHelperText = React.forwardRef(function FormHelperText(inProps, ref) {
  const { children, className, formControlStateClass, FormHelperTextProps } =
    useFormFieldState(inProps)

  return (
    <FormHelperTextRoot
      {...FormHelperTextProps}
      className={`${className ? `${className} ` : ''} ${
        formControlStateClass ? formControlStateClass : ''
      }`}
      ref={ref}
    >
      {children}
    </FormHelperTextRoot>
  )
})

FormHelperText.propTypes = {
  /**
   *  Children / text content of the component.
   */
  children: PropTypes.node,

  /**
   * If true, color of the element will indicate disabled variant.
   */
  disabled: PropTypes.bool,

  /**
   * If true, color of the element will indicate an error variant.
   */
  error: PropTypes.bool,

  /**
   * If true, color of the element will indicate an focused variant.
   */
  focused: PropTypes.bool
}

FormHelperText.defaultProps = {
  disabled: false,
  error: false,
  focused: false
}

export default memo(FormHelperText)
