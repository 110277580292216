import { useState } from 'react'
import translate, { TranslateComponent } from 'src/common/translations'
import Select from 'src/components/legacy/components/select/select'
import styled from 'styled-components'

export const PanelLabel = styled.label`
  display: block;
  height: 16px;
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 15px;
  & > div {
    text-transform: capitalize;
    display: inline-block;
  }
`

const SelectFormInput = ({
  selectedItem,
  fieldsetClass = '',
  labelName,
  optionsList,
  placeholder,
  update,
  errorItem,
  search=false,
  searchPlaceholder='',
  loading = false
}) => {
  const optionTranslated = {
    labelName: `${labelName ? translate(labelName) + '*' : ''}`,
    searchPlaceholder: searchPlaceholder ?  translate(searchPlaceholder) : ''
  }

   return (
    <fieldset className={fieldsetClass}>
      <PanelLabel className={errorItem ? 'error' : ''}>{optionTranslated.labelName}</PanelLabel>
       {loading ? <span className='spinner' /> : <Select
         search={search}
         className={errorItem ? 'error' : ''}
         options={optionsList}
         placeholder={placeholder}
         onChange={(value) => {
           update(value)
         }}
         searchPlaceholder={optionTranslated.searchPlaceholder}
         selectedItem={selectedItem}
       />}
      <p className="error">{typeof errorItem=='string' && <TranslateComponent>{errorItem}</TranslateComponent>}</p>
    </fieldset>
  )
}
export default SelectFormInput
