import { Button } from 'src/components/inputs/button'
import { Link } from 'react-router-dom'
import { StyledSearch } from './style'
import { Block } from 'src/components/layouts'
import { AccessControl } from 'src/components/accessControl'
import { TranslateComponent } from 'src/common/translations'

export const Title = () => {
  return (
    <>
      <h1>Building Setup</h1>
    </>
  )
}

export const Search = ({ onChange, placeholder }: any) => {
  return (
    <StyledSearch>
      <input
        type="text"
        placeholder={placeholder || 'search...'}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      />
    </StyledSearch>
  )
}

export const ToolBar = () => {
  const pageName = 'BuildingSetup'
  return (
    <Block>
      <AccessControl id="tc.pages.building-setup.add">
        <Link to={'add'}>
          <Button testName={`${pageName}_createabuilding_createbuilding`}>
            <TranslateComponent>Create Building </TranslateComponent> 
          </Button>
        </Link>
      </AccessControl>
    </Block>
  )
}
