import React, { Component } from 'react'
import PropTypes from 'prop-types'
import defaultLocationImage from 'src/assets/images/location-images/default_location.png'
import defaultgoogleMapImage from 'src/assets/images/location-images/default-g-maps.png'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import _get from 'lodash/get'
import { getField } from 'src/components/legacy/common/helpers'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import PlacesAutocompleteWrapper from 'src/components/legacy/buildingSetup/common/places-autocomplete-wrapper'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import { sort } from 'src/components/legacy/common/helpers'
import { FIELD_RESTRICTIONS } from '../createBuilding/form'
import { ACTIONS } from '../createBuilding/constants'
import { POSTAL_CODE_REGEXPS_BY_COUNTRY_CODE } from '../createBuilding/validation'
import './legacyStyle.scss'
import translate, { TranslateComponent } from 'src/common/translations'
import { useEffect, useState } from 'react'

import {createMap } from 'maplibre-gl-js-amplify'
import 'maplibre-gl/dist/maplibre-gl.css'
import { Geo } from "aws-amplify"
import styled from 'styled-components'
import maplibregl from 'maplibre-gl'
import { useTranslation } from 'react-i18next'

export const buildingAreaUnits = [
  { id: 'squareMetre', name: 'Square Meters' },
  { id: 'squareFeet', name: 'Square Feet' }
]

export const defaultBuildingAreaUnits = buildingAreaUnits[1]


const SuggestionsList = styled.ul`
    list-style-type: none;
    padding: 0;
    background-color:  #f5f5f5 ;
    border: 1px solid ;
    border-radius: 2px;
    box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: -1px;
    width: 32vW;
    position: absolute;
    z-index: 10;
`;
 

const SuggestionItem = styled.li`
    padding: 8px;
    text-align: left;
    cursor: pointer;
    &:hover {
        background-color: #f0f0f0;
    }
    &.address-suggestion-item-active {
        background-color: ${props => props.theme.tbaGreen};
    }
`;


export const GeoMap = (props) => {

 const buildingAreaUnits = [
    { id: 'squareMetre', name: translate('Square Meters') },
    { id: 'squareFeet', name: translate('Square Feet') }
  ]
  
  const PLACEHOLDER = '--'

  const { t } = useTranslation()

  const { countries, pageName, setValues, values, updateRegions, readOnly, regions } = props

  const countryPlaceHolder = {
    country : translate("Find a country..."),
    state : translate("Find a state..."),
    selectOne : translate("--Select One--"),
    noResultFound : translate("No results. Change or clear your text above.")
  }

  const onCountrySelect = (setValues, values) => {
    //this.setIsFromFillAddress(false)
    return ({ key, value }) => {
      updateRegions(key)
      setValues({
        ...values,
        address: {
          ...values.address,
          countryCode: key,
          country: value,
          regionFullName: '',
          region: '',
          ...(POSTAL_CODE_REGEXPS_BY_COUNTRY_CODE[key] === null && {
            postalCode: ''
          })
        }
      })
    }
  }

  const countrySelect = ({
    countryplaceHolder = countryPlaceHolder,
    disabled,
    inputId,
    onBlur,
    onFocus,
    formik: {
      values,
      values: {
        address: { country }
      },
      setValues
    }
  }) => {
    const { countries, pageName } = props
    const sortedCountries = sort(countries, 'countryName')
    const options = sortedCountries?.map((country) => ({
      key: country.countryCode,
      value: country.countryName
    }))
    const defaultValue =
      (
        options.find(
          (countryDetails) => `${countryDetails?.value}` === country
        ) || {}
      ).key || false

    return (
      <SelectrixWrapper
        arrow={true}
        className="country selectrix-wrapper--type-autocomplete"
        customKeys={{ key: 'key', label: 'value' }}
        defaultValue={defaultValue}
        disabled={disabled}
        disableStateVal={true}
        id={inputId}
        inputPlaceholder={countryplaceHolder.country}
        notFoundPrompt={countryPlaceHolder.noResultFound}
        onChange={onCountrySelect(setValues, values)}
        onClose={onBlur}
        onOpen={onFocus}
        options={options}
        placeholder={countryPlaceHolder.selectOne}
        searchable={true}
        searchBoxInside={true}
        searchIndex={false}
        testName={`${pageName}_locationdetails_countryselect`}
      />
    )
  }

  const countryName = (countries?.find((country) => country.countryName === values?.address?.country) || {})?.countryName || ''



  const onStateSelect = (setValues, values) => {
    return ({ key, value }) => {
      setValues({
        ...values,
        address: {
          ...values.address,
          regionFullName: value,
          region: key
        }
      })
    }
  }

  const stateSelect = ({
    statePlaceholder = countryPlaceHolder,
    inputId,
    onBlur,
    onFocus,
    formik: {
      values,
      values: {
        address: { country }
      },
      setValues
    }
  }) => {
    const { regions, pageName } = props
    const sortedRegions = sort(regions, 'regionName')
    const options = sortedRegions.map((region) => ({
      key: region.regionCode,
      value: region.regionName
    }))
    const defaultValue =
      (
        options.find(
          (regionDetails) => `${regionDetails?.key}` === values?.address?.region
        ) || {}
      ).key || false

    return (
      <SelectrixWrapper
        arrow={true}
        className="state selectrix-wrapper--type-autocomplete"
        customKeys={{ key: 'key', label: 'value' }}
        defaultValue={defaultValue}
        disabled={!(country && options.length)}
        disableStateVal={true}
        id={inputId}
        inputPlaceholder={statePlaceholder.state}
        notFoundPrompt={statePlaceholder.noResultFound}
        onChange={onStateSelect(setValues, values)}
        onClose={onBlur}
        onOpen={onFocus}
        options={options}
        placeholder={countryPlaceHolder.selectOne}
        searchable={true}
        searchBoxInside={true}
        searchIndex={false}
        testName={`${pageName}_locationdetails_stateselect`}
      />
    )
  }

  const regionName = (regions?.find((region) => region.regionName === values?.address?.region) || {}).regionName || ''


  const floorAreaComponent = ({ onFocus, onBlur, readOnly, formik: { values } }) => {

    return (
      <>
        <EditableView
          type={EditableView.COMPONENT_TYPE.INPUT}
          className="floor-area-input"
          labelName={null}
          readOnly={readOnly}
          value={
            readOnly
              ? (values.floorAreaSquareFeet &&
                Number(values.floorAreaSquareFeet)) ||
              PLACEHOLDER
              : values.floorAreaSquareFeet
          }
          name="floorAreaSquareFeet"
          showErrors={false}
          onBlur={onBlur}
          onFocus={onFocus}
          testName={`${pageName}_locationdetails_addfloorareasquarefeet`}
        />
        <EditableView
          type={EditableView.COMPONENT_TYPE.SELECT}
          className="floor-area-units"
          labelName={null}
          readOnly={readOnly}
          name="floorAreaUnits"
          value={
            getField(
              buildingAreaUnits?.find(({ id }) => id === values?.floorAreaUnits),
              'name'
            ) || defaultBuildingAreaUnits.name
          }
          options={buildingAreaUnits?.map((unit) => ({
            key: unit.id,
            value: unit.name
          }))}
          showErrors={false}
          onBlur={onBlur}
          onFocus={onFocus}
          testName={`${pageName}_locationdetails_selectfloorareaunits`}
        />
      </>
    )
  }

  const [map, setMap] = useState(null);
  const [place, setPlace] = useState(`${props?.values?.address?.line1 ? props?.values?.address?.line1 : ""}` +
  `${props?.values?.address?.city ? `, ${props?.values?.address?.city}` : ""}` +
  `${props?.values?.address?.region ? `, ${props?.values?.address?.region}` : ""}` +
  `${props?.values?.address?.country ? `, ${props?.values?.address?.country}` : ""}
  `);
  const [autoSuggestions, setAutoSuggestions] = useState([]);
  const [marker, setMarker] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  useEffect(() => {

    const initializeMap = async () => {
      try {
        const coordinates = [values?.locationLongitude, values?.locationLatitude]
        const map = await createMap({
          container: 'map',
          center: coordinates,
          zoom: 16
        });
        setMap(map);  
  
        const newMarker = new maplibregl.Marker().setLngLat(coordinates).addTo(map);
        setMarker(newMarker);
  
        if(values?.locationLatitude && values?.locationLongitude)
        setIsMapLoaded(true)
      } catch(error){
        console.log(error);
      }
    };

    initializeMap();
    return () => {
      if (map) {
        map.remove();
      }
    };
  }, []);


  useEffect(() => {

    if (props?.values?.address?.countryCode)
      updateRegions(props?.values?.address?.countryCode)

  }, [props?.values?.address?.countryCode])



  const updateMap = async (searchText) => {
    if (!searchText) return;
    const results = await Geo.searchByText(searchText);
    if (results) {
      const line1 = `${results[0]?.addressNumber ? `${results[0]?.addressNumber} ` : "" }${results[0]?.street ? `${results[0]?.street}` : "" }`;
      const postalCode = results[0]?.postalCode?.replace(" ", "-");
      const city = results[0]?.municipality;
      const regionCodeIndex = regions?.findIndex(region => region.regionName === results[0]?.region)
      const region = regionCodeIndex !== -1 ? regions[regionCodeIndex].regionCode : null
      const regionFullName = results[0]?.region
      const countryNameIndex = countries?.findIndex(country => country?.countryCode === results[0]?.country)
      const country = countryNameIndex !== -1 ? countries[countryNameIndex]?.countryName : null;
      const countryCode = results[0]?.country;
      const countryDescription = "";
      const locationLongitude = results[0]?.geometry?.point?.[0];
      const locationLatitude = results[0]?.geometry?.point?.[1];
      const locationTimeZone = results[0]?.timeZone
      setValues({
        ...values,
        address: {
          ...values.address,
          line1: line1 != "" ? line1 : null,
          postalCode: postalCode,
          city: city,
          region: region,
          regionFullName: regionFullName,
          country: country,
          countryCode: countryCode,
          countryDescription: countryDescription
        },
        locationLatitude: locationLatitude,
        locationLongitude: locationLongitude,
        locationTimeZone: locationTimeZone
      })


      const coordinates = results[0]?.geometry?.point || [null, null];
      if (map) {
        map.setCenter(coordinates);
        map.setZoom(16);
        if (marker) {
          marker.remove();
        }
        const newMarker = new maplibregl.Marker().setLngLat(coordinates).addTo(map);
        setMarker(newMarker);
        setIsMapLoaded(true)
      }
      
    }
  };


  const fetchSuggestions = async (searchText) => {
    if (!searchText) {
      setAutoSuggestions([]);
      return;
    }
    const autosuggestion = await Geo.searchForSuggestions(searchText);
    setAutoSuggestions(autosuggestion || []);
  };

  const handleSuggestionClick = (suggestion) => {
    setAutoSuggestions([]);
    updateMap(suggestion.text);
  };

  const handleInputChange = (event) => {
    const value = event || ""
    setPlace(value);
    setValues({
      ...values,
      address: {
        ...values.address,
        line1: value,
      }
    })
    fetchSuggestions(value);
  };

  const handleInputBlur = () => {
    // blur is invoked before suggestions click , timeout is to invoke click event first.
    setTimeout(() => {
      setAutoSuggestions([]);
      if(!values.address.line){
        setValues({
          ...values,
          address: {
            ...values.address,
            line1: place,
          }
        })
      }
    }, 300);
   
  };

  const country = translate("Find a country...")

  return (
    <div className="details-info-header">
      <img
        className="location-image"
        src={defaultLocationImage}
        alt="Location"
      />
      <div className="address-block">
        <div className='search-suggestions-block edit-view street'>
      <EditableView
          className="address required"
          type={EditableView.COMPONENT_TYPE.INPUT}
          inputId="addressInput"
          labelName="Street Address"
          onChange = {handleInputChange}
          onBlur={handleInputBlur} 
          name="address.line1"
          readOnly={readOnly}
          searchPlaceholder="Search Address..."
          value={values?.address.line1}
        />
         {autoSuggestions.length > 0 && (
            <SuggestionsList>
              {autoSuggestions.map((suggestion, index) => (
                <SuggestionItem key={index} onClick={() => handleSuggestionClick(suggestion)}>
                  {suggestion.text}
                </SuggestionItem>
              ))}
            </SuggestionsList>
          )}
          </div>

        <EditableView
          className="country required"
          component={countrySelect}
          inputId="countryselect"
          labelName="Country"
          name="address.countryCode"
          readOnly={readOnly}
          searchPlaceholder={country}
          value={countryName}
        />

        <EditableView
          className="state required"
          component={stateSelect}
          inputId="stateselect"
          disabled={!(values?.address?.countryCode && regions?.length)}
          labelName="State/Province"
          name="address.regionCode"
          readOnly={readOnly}
          searchPlaceholder="Find a state/province..."
          value={readOnly && !regionName ? PLACEHOLDER : regionName}
        />


        <EditableView
          type={EditableView.COMPONENT_TYPE.INPUT}
          className="zip required"
          labelName="Zip/Postal Code"
          readOnly={readOnly}
          name="address.postalCode"
          value={values?.address?.postalCode}
          testName={`${pageName}_locationdetails_addpostalcode`}
        />

        <EditableView
          type={EditableView.COMPONENT_TYPE.INPUT}
          className="city required"
          labelName="City"
          readOnly={readOnly}
          name="address.city"
          value={values?.address?.city}
          maxLength={FIELD_RESTRICTIONS.city.maxLength + 1}
          debounceTimeout={1000}
          testName={`${pageName}_locationdetails_addcity`}
        />

        <EditableView
          type={EditableView.COMPONENT_TYPE.GROUP}
          component={floorAreaComponent}
          className="floor-area"
          labelName="Building Area"
          readOnly={readOnly}
          validate={(form) => {
            let error = null
            if (
              (getField(form.touched, 'floorAreaSquareFeet') &&
                getField(form.errors, 'floorAreaSquareFeet')) ||
              (getField(form.touched, 'floorAreaUnits') &&
                getField(form.errors, 'floorAreaUnits'))
            ) {
              error = getField(form.errors, 'floorAreaSquareFeet')
            }
            return error
          }}
        />
      </div>
      <div className="map-block">
            <div
                id="mapView" 
                style={{
                    height:  '0',
                    width:  '0',
                    margin: 'auto',
                    visibility: 'hidden'
                }}
            ></div>
           <div
                id="map" 
                style={{
                    height: isMapLoaded ? '100%' : '0',
                    margin: 'auto',
                    visibility: isMapLoaded ? 'visible' : 'hidden'
                }}
            ></div>
            {!isMapLoaded && (
              <div className='maps-place'>
                <div className="maps-place-empty">
                    <img className="map-image" src={defaultgoogleMapImage} alt="map" />
                </div>
                </div>
            )}
        </div>
    </div>
  );
}




export default ((props) =>
   <GeoMap {...props}/>
)
