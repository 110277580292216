import styled from 'styled-components'

export const TooltipCombineComponent = styled.div`
font-size: 10px;
font-weight: 500;
text-overflow: ellipsis;
width: 100%
padding:2px
`
export const TooltipBoldComponent = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-overflow: ellipsis;
  width: 100%;
  `
  export const PropertyComponent = styled.div`
  margin-top:5px
  `