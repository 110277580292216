import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TranslateComponent } from 'src/common/translations'

class SelectItem extends Component {
  static propTypes = {
    handleMenuItemClick: PropTypes.func,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Typically an ID
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    itemEndIcon: PropTypes.string,
    showOptionsIcon: PropTypes.bool,
    getItemIcon: PropTypes.func,
    isUserdefinedOptions: PropTypes.bool
  }

  handleClick = () => {
    const { value, isDisabled, handleMenuItemClick } = this.props
    !isDisabled && handleMenuItemClick(value)
  }

  render = () => {
    const {
      title,
      isDisabled,
      type,
      getItemIcon,
      showOptionsIcon = false, isUserdefinedOptions = false,
      itemEndIcon
    } = this.props
    return (
      <div
        className={classNames('select-menu-item', { disabled: isDisabled })}
        onClick={this.handleClick}
      >
        {showOptionsIcon && type && getItemIcon?.(type, true)}
        <span>{isUserdefinedOptions ? title : <TranslateComponent>{title}</TranslateComponent>}</span>
        {itemEndIcon && (
          <span className={`icon ${itemEndIcon} select-menu-item-end-icon`} />
        )}
      </div>
    )
  }
}

export default SelectItem
