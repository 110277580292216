export const findMaxValueObject = data => {
    if(data.length === 0) return null;

    let maxValueObject = data[0];
    let maxValue = +maxValueObject.value;
    for(let i=1; i<data.length; i++){
        const currentValue = +(data[i].value);
        if(currentValue > maxValue){
            maxValue = currentValue
            maxValueObject = data[i]
        }
    }
    return maxValueObject;
}

export const findMinMaxValuesObject = data => {
    if(data.length === 0) return null;

    let maxValueObject = data[0];
    let minValueObject = data[0];
    let maxValue = +maxValueObject.value;
    let minValue = +minValueObject.value;
    for(let i=1; i<data.length; i++){
        const currentValue = +(data[i].value);
        if(currentValue > maxValue){
            maxValue = currentValue
            maxValueObject = data[i]
        }else  if(currentValue < minValue){
            minValue = currentValue
            minValueObject = data[i]
        }
    }
    return [minValueObject, maxValueObject];
}