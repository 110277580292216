import { useState, useCallback, useEffect } from 'react'
import MultiTabForm from '../MultiTabTorm'
import { TabsWrapper } from '../../styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import {
  WidgetOverview,
  WidgetOverviewSchema,
  WidgetBuildingSchema
} from '../WidgetOverview'
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../graphql'
import { useMutation } from 'src/hooks/APIHooks'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import {
  MODAL_TYPE,
  LATEST_VALUE,
  THRESHOLD_CONSTANT,
  TimePeriodCurrentDay,
  getOrbWidgetUpsertMutationInputs,
  WIDGET_TABS_NAME
} from '../../helpers'
import { ACTIONS } from 'src/constants/actions'
import DataMonitoringOrbColors, {
  OrbColorsSchema
} from './DataMonitoringOrbColors'
import DataMonitoringOrbConfiguration, {
  OrbConfigSchema
} from './DataMonitoringOrbConfiguartion'
import { BASE_COLOR, THRESHOLD1_COLOR, THRESHOLD2_COLOR } from '../../colors'
import { DataMonitoringOrbPreviewWrapper } from './DataMonitoringOrbPreviewWrapper.jsx'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate from 'src/common/translations'

const tabsConfig = (errors) => [
  {
    title: WIDGET_TABS_NAME.OVERVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.OVERVIEW,
    formikErrors: errors,
    validationSchema: WidgetOverviewSchema,
    component: <WidgetOverview />
  },
  {
    title: WIDGET_TABS_NAME.CONFIGURATION,
    disabled: false,
    key: WIDGET_TABS_NAME.CONFIGURATION,
    formikErrors: errors,
    validationSchema: OrbConfigSchema,
    component: <DataMonitoringOrbConfiguration />
  },
  {
    title: WIDGET_TABS_NAME.COLORS,
    disabled: false,
    key: WIDGET_TABS_NAME.COLORS,
    formikErrors: errors,
    validationSchema: OrbColorsSchema,
    component: <DataMonitoringOrbColors />
  },
  {
    title: WIDGET_TABS_NAME.PREVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.PREVIEW,
    formikErrors: errors,
    component: <DataMonitoringOrbPreviewWrapper />
  },
]

const DataMonitoringOrbWidgetForm = ({ setWidgetFormik }) => {
  const {
    widgetBuilding,
    setOpenWidgetPicker,
    userInfo,
    setReloadEvents,
    setIsLoading,
    mode,
    widgetDetails,
    setWidgetDetails,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setModalType,
    formikErrors,
    objectPickerSelectedValue,
    setIsFormValidationAlone,
    setIsSubmitted,
    setWidgetBuilding,
    isFormValidationAlone,
    setWidgetId,
    isFooterChecked
  } = useWidgetsContext()
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: [ACTIONS.ADD, ACTIONS.COPY].includes(mode)
      ? CREATE_WIDGET
      : UPDATE_WIDGET,
    refreshTokenBuildingVariable: 'input.buildingId',
    onError: (error) => {
      setError(error)
      setIsLoading(false)
    },
    onSuccess: (success) => {
      if([ACTIONS.ADD].includes(mode))
        {
          trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Create", type: "Data Monitoring Orb"})
        }
      else if([ACTIONS.COPY].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Copy", type: "Data Monitoring Orb"})
      }
      else{
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Edit", type: "Data Monitoring Orb"})
      }
    }
  })  

  const submitForm = async (values) => {
    if (!widgetBuilding || isFormValidationAlone) return
    setIsLoading(true)
    const inputs = getOrbWidgetUpsertMutationInputs(
      values,
      userInfo,
      objectPickerSelectedValue,
      mode
    )
    await upsertWidgetMutation({ input: inputs }).then((response) => {
      const widgetId = [ACTIONS.ADD, ACTIONS.COPY].includes(mode)
        ? response.data?.createWidget?.id
        : response.data?.updateWidget?.id
      if (widgetId) {
        setIsLoading(false)
        setWidgetDetails(inputs)
        setOpenWidgetPicker(false)
        setReloadEvents(true)
        setModalType(MODAL_TYPE.SUCCESS)
        setSelectedWidget(null)
        setIsOpenCreateWidget(isFooterChecked ?? false)
        setWidgetId(widgetId)
      }
    })
  }
  const [activeTab, setActiveTab] = useState(0)
  const onSelectTab = useCallback((selectedTab: any, selectedTitle: any) => {
    setActiveTab(selectedTab)
    if (selectedTitle === WIDGET_TABS_NAME.PREVIEW) {
      setIsFormValidationAlone(true)
      setIsSubmitted(false)
    }
  }, [])
  const copyof = translate("Copy of")
  useEffect(() => {
    switch (mode) {
      case ACTIONS.ADD:
        setFormValues({
          id: '',
          name: '',
          description: '',
          link: '',
          isNewWindow: true,
          widgetBuilding: '',
          property: '',
          unitsOfMeasure: '',
          symbol: '',
          needleValue: LATEST_VALUE,
          timePeriod: TimePeriodCurrentDay,
          baselineColorValue: BASE_COLOR,
          thresholdsValue: THRESHOLD_CONSTANT,
          threshold1Value: THRESHOLD1_COLOR,
          threshold1ConstantValue: '0',
          threshold2Value: THRESHOLD2_COLOR,
          threshold2ConstantValue: '0'
        })
        break
      case ACTIONS.EDIT:
      case ACTIONS.COPY:
        setFormValues({
          id: mode === ACTIONS.EDIT ? widgetDetails?.id : '',
          name:
            mode === ACTIONS.EDIT
              ? widgetDetails?.name
              : `${copyof} ${widgetDetails?.name}`,
          description: widgetDetails?.description ?? '',
          link: widgetDetails?.widgetLink,
          isNewWindow: widgetDetails?.isNewWindow,
          property:
            widgetDetails?.equipmentDataMonitorOrbWidget?.property?.property,
          unitsOfMeasure:
            widgetDetails?.equipmentDataMonitorOrbWidget?.property?.uom,
          symbol: widgetDetails?.equipmentDataMonitorOrbWidget?.property?.symbol,
          needleValue: widgetDetails?.equipmentDataMonitorOrbWidget?.Value,
          timePeriod: widgetDetails?.equipmentDataMonitorOrbWidget?.timePeriod,
          baselineColorValue:
            widgetDetails?.equipmentDataMonitorOrbWidget?.colourConfig
              ?.baseline,
          thresholdsValue:
            widgetDetails?.equipmentDataMonitorOrbWidget?.colourConfig
              ?.thresholdOption,
          threshold1Value:
            widgetDetails?.equipmentDataMonitorOrbWidget?.colourConfig
              ?.threshold1,
          threshold1ConstantValue:
            widgetDetails?.equipmentDataMonitorOrbWidget?.colourConfig
              ?.threshold1Value,
          threshold2Value:
            widgetDetails?.equipmentDataMonitorOrbWidget?.colourConfig
              ?.threshold2,
          threshold2ConstantValue:
            widgetDetails?.equipmentDataMonitorOrbWidget?.colourConfig
              ?.threshold2Value
        })
        setTimeout(() => {
          setWidgetBuilding({ id: widgetDetails?.buildingId })
        }, 100)
        break
    }
  }, [mode, copyof])

  const displayForm = () => {
    return mode !== ACTIONS.ADD ? formValues?.name : true
  }

  return (
    <>
      {displayForm() && (<MultiTabForm
        initialValues={formValues}
        onSubmit={submitForm}
        schemas={[
          WidgetOverviewSchema,
          WidgetBuildingSchema,
          OrbConfigSchema,
          OrbColorsSchema
        ]}
        setWidgetFormik={setWidgetFormik}
      >
        <TabsWrapper>
          <Tabs
            defaultActiveTab={activeTab}
            activeTab={activeTab}
            onSelect={onSelectTab}
            vertical={false}
          >
            {tabsConfig(formikErrors)?.map((item: any, key: number) => {
              return (
                <Tab
                  title={item.title}
                  disabled={item.disabled}
                  key={key}
                  formikErrors={item?.formikErrors}
                  validationSchema={item?.validationSchema}
                >
                  {item.component}
                </Tab>
              )
            })}
          </Tabs>
        </TabsWrapper>
        <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
      </MultiTabForm>)
      }
    </>
  )
}

export default DataMonitoringOrbWidgetForm
