import { Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react'
import { TranslateComponent } from 'src/common/translations'

export const CreateReportButton = ({
  optionsList,
  handleDropClick,
  checkDisableReport,
  buildingId
}) => {
  return (
    <Menu
      key="create"
      trigger={
        <MenuButton data-testid="createReportMenu">
          <TranslateComponent>Create Report</TranslateComponent>
        </MenuButton>
      }
    >
      {optionsList.map(({ key, value }) => {
        return (
          <MenuItem
            key={key}
            onClick={() => handleDropClick(value)}
            isDisabled={!checkDisableReport(key, buildingId)}
            data-testid={`pageHeaderMenu-${key}`}
          >
            <TranslateComponent>{value}</TranslateComponent>
          </MenuItem>
        )
      })}
    </Menu>
  )
}
