import { createContext, useContext, useMemo, useState } from 'react'

interface TooltipLinkPointerContext {
  event: any
  isIn: boolean | null
  x: number | null
  name: string | null
  setCursorStatus: (status: {
    event: any
    isIn: boolean | null
    x: number | null
    name: string | null
  }) => void
}

const defaultState = {
  event: null,
  isIn: null,
  x: null,
  name: null,
  setCursorStatus: () => {}
}

const TooltipLinkPointerContext =
  createContext<TooltipLinkPointerContext>(defaultState)

export function useTooltipLinkPointerContext() {
  return useContext<TooltipLinkPointerContext>(TooltipLinkPointerContext)
}

export default function TooltipLinkPointerProvider({ children }) {
  const [sync, setSync] = useState<TooltipLinkPointerContext>(defaultState)

  const value: TooltipLinkPointerContext = useMemo(() => {
    return {
      ...sync,
      setCursorStatus: setSync as any
    }
  }, [sync])

  return (
    <TooltipLinkPointerContext.Provider value={value}>
      {children}
    </TooltipLinkPointerContext.Provider>
  )
}
