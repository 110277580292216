/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo } from 'react'
import { useChartContext } from '../../CommonComponents/BaseWrapper'
import { AxisDiM } from '../../Utils/defaults'
import { getMinValue } from '../../CommonComponents/Bar/helper';
import BarChart from '../../CommonComponents/Bar';
import Grid from 'src/chart-library/CommonComponents/Grids/Grid';

export default function Series(props: any) {
  const chart = useChartContext()

  const grid = chart?.grid
  const axis = chart?.axis
  const scale = chart?.scale
  const series = props?.series?.barData ?? []
  const measurement = props?.series?.measurement ?? null

  // API flow - 2
  // Once Data is recevied from API. set x axis min & max value to render ticks based on API reponse
  // or you can use min & max value config in useOptions to set x axis values
  useEffect(() => {
    const minValue = getMinValue(series)
    const maxValue = Math.max(...series?.map(({ value }) => value ?? 0))
    if (series?.length) {
      scale.setDomain(AxisDiM?.x, 0, [minValue > 0 ? 0 : minValue, maxValue > 0 ? maxValue : 1000], true)
    }
  }, [series])

  const canvas = useMemo(() => grid?.getGrid(0), [grid?.lastUpdatedAt])!

  const xScale = useMemo(
    () => {
      const x = scale?.getScale(AxisDiM.x, 0)
      return x
    },
    [scale?.lastUpdatedAt]
  )!

  const yScale = useMemo(
    () => {
      const y = scale?.getScale(AxisDiM.y, 0)
      y?.fn?.padding(0.5)
      return y
    }
    ,
    [scale?.lastUpdatedAt]
  )!

  if (!grid?.lastUpdatedAt || !scale?.lastUpdatedAt || !axis?.lastUpdatedAt) {
    return null
  }

  return (
    <>
      <g transform={`translate(${canvas?.x} ${canvas?.y})`}>
        <Grid
          scale={xScale?.fn}
          width={canvas?.width}
          height={canvas?.height}
          includeAxis={true}
          opacity={0.3}
          type={'x'}
          tickCount={6}
        />
        <BarChart measurement={measurement} isHorizontal={true} barData={series} xScale={xScale} yScale={yScale} enableToolTip={true} setTooltip={props?.setTooltip} />
      </g>
    </>
  )
}

