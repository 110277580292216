import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'

class DatePickerDay extends PureComponent {
  static propTypes = {
    day: PropTypes.instanceOf(moment),
    displayBase: PropTypes.instanceOf(moment),
    today: PropTypes.string,
    selectedStart: PropTypes.instanceOf(moment),
    selectedEnd: PropTypes.instanceOf(moment),
    rangeSelection: PropTypes.shape({
      past: PropTypes.bool,
      future: PropTypes.bool
    }),
    disableBeforeGivenDate: PropTypes.object,
    disableAfterGivenDate: PropTypes.object
  }

  static defaultProps = {
    selectedStart: moment().startOf('day'),
    selectedEnd: moment().endOf('day')
  }

  selectDay = () =>
    this.props.handleDateClick(this.props.day.format('YYYY-MM-DD'))

  render = () => {
    const {
      day,
      displayBase,
      today,
      rangeSelection,
      selectedStart,
      selectedEnd,
      disablePast,
      disableBeforeGivenDate,
      disableAfterGivenDate
    } = this.props
    if (disablePast) {
      if (moment(today, 'YYYY-MM-DD').isAfter(day)) {
        return <td className={'range-day-disabled'}>{day.format('D')}</td>
      } else {
        return (
          <td
            onClick={this.selectDay}
            className={classNames({
              'range-today': day.format('YYYY-MM-DD') === today,
              'range-in-band': day.isBetween(selectedStart, selectedEnd, '()'),
              'range-extreme range-start':
                day.isSame(selectedStart, 'day') &&
                !day.isSame(selectedEnd, 'day'),
              'range-extreme range-end':
                day.isSame(selectedEnd, 'day') &&
                !day.isSame(selectedStart, 'day'),
              'range-extreme':
                day.isSame(selectedStart, 'day') &&
                day.isSame(selectedEnd, 'day'),
              'range-other-month': day.month() !== displayBase.month(),
              'range-day-disabled':
                (!selectedEnd &&
                  selectedStart &&
                  selectedStart.isAfter(day, 'day')) ||
                (!selectedEnd &&
                  !rangeSelection.future &&
                  day.isSameOrAfter(moment.tz(today, day.tz()), 'day')) ||
                (!selectedEnd &&
                  !rangeSelection.past &&
                  day.isBefore(moment.tz(today, day.tz()), 'day'))
            })}
          >
            {day.format('D')}
          </td>
        )
      }
    } else {
      return (
        <td
          onClick={this.selectDay}
          className={classNames({
            'range-today': day.format('YYYY-MM-DD') === today,
            'range-in-band': day.isBetween(selectedStart, selectedEnd, '()'),
            'range-extreme range-start':
              day.isSame(selectedStart, 'day') &&
              !day.isSame(selectedEnd, 'day'),
            'range-extreme range-end':
              day.isSame(selectedEnd, 'day') &&
              !day.isSame(selectedStart, 'day'),
            'range-extreme':
              day.isSame(selectedStart, 'day') &&
              day.isSame(selectedEnd, 'day'),
            'range-other-month': day.month() !== displayBase.month(),
            'range-day-disabled':
              (!selectedEnd &&
                selectedStart &&
                selectedStart.isAfter(day, 'day')) ||
              (!selectedEnd &&
                !rangeSelection.future &&
                day.isSameOrAfter(moment.tz(today, day.tz()), 'day')) ||
              (!selectedEnd &&
                !rangeSelection.past &&
                day.isBefore(moment.tz(today, day.tz()), 'day')) ||
              (disableBeforeGivenDate &&
                day.isBefore(disableBeforeGivenDate)) ||
              (disableAfterGivenDate && day.isAfter(disableAfterGivenDate))
          })}
        >
          {day.format('D')}
        </td>
      )
    }
  }
}

export default DatePickerDay
