import React, { useState, useMemo, useRef, useEffect } from 'react'
import { Form, Formik } from 'formik'
import Modal from 'src/components/legacy/components/modal/modal'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import Label from 'src/components/legacy/components/label/label'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { ACTIONS } from 'src/constants'
import { curveTypes, CURVE_TYPES, CURVE_TYPES_BY_NUMBER } from './constants'
import * as Yup from 'yup'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import CharacteristicCurveEntryView from './CharacteristicCurveEntryView'
import {
  UPDATE_CHARACTERISTIC_CURVE,
  CREATE_CHARACTERISTIC_CURVE
} from '../../graphql/mutations'
import AxisRange from './AxisRange'
import { CurveDialogWrapper, CurveTypeIconWrapper, LabelConditionalTooltip } from './styles'
import RunAnalyticsCheckBox from './RunAnalyticsCheckBox'
import { GET_CHARACTERISTIC_CURVE } from '../../graphql/queries'
import { formCreateCharacteristicInput, getEditDifferences } from '../helper'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate, { TranslateComponent } from '../../../../common/translations'

const CharacteristicCurveDialog = ({
  mode,
  setMode,
  circuitList,
  enableAutomatedTestCount,
  equipment,
  setReloadEvents,
  rowData
}) => {
  const [enableAutomatedTestWarning, setEnableAutomatedTestWarning] =
    useState(false)
  const [
    enableAutomatedTestWarningMessage,
    setEnableAutomatedTestWarningMessage
  ] = useState('')
  const [t] = useTranslation()
  const numOfCircuits = circuitList[0]?.value && parseInt(circuitList[0]?.value)
  const noCircuitFound =
    _isEmpty(circuitList) || !circuitList[0]?.value || !numOfCircuits
  const dialogRef = useRef()
  const DEFAULT_X_AXIS = 'ChillerCurrentEnteringDrawRla'
  const DEFAULT_X_AXIS_SINGLE_CIRCUIT = 'ChillerLoadResult'
  const userInfo = useSelector(selectUserInfo)
  const [showerror, setShowerror] = useState({
    previousClick: '',
    err: false
  })
  const curvesCircuitsOptions = []
  if (numOfCircuits) {
    for (let i = 0; i < numOfCircuits; i++) {
      curvesCircuitsOptions.push({ key: i + 1, value: `CKT${i + 1}` })
    }
  }
  const isSingleCircuitChiller = numOfCircuits === 1
  const XAxisOptions = useMemo(
    () =>
      isSingleCircuitChiller
        ? [
            {
              key: 'ChillerCurrentEnteringDrawRla',
              value: t(
                'equipment-setup:CharacteristicsCurves>XAxisParameter>ChillerCurrentEnteringDrawRla',
                { circuitNumber: 1 }
              )
            },
            {
              key: 'ChillerLoadResult',
              value: t(
                'equipment-setup:CharacteristicsCurves>XAxisParameter>ChillerLoadResult'
              )
            }
          ]
        : [],
    [isSingleCircuitChiller, t]
  )

  const {
    onSubmit: createCharacteristicCurveMutation,
    loading: loadingCreateCharacteristicCurve
  } = useMutation({
    query: CREATE_CHARACTERISTIC_CURVE,
    dataPath: 'data.createCharacteristicCurve',
    errorPolicy: 'global'
  })

  const {
    data: characteristicCurveData,
    refetch: fetchCharacteristicCurve,
    loading: loadingCharacteristicCurveDetails
  } = useQuery({
    query: GET_CHARACTERISTIC_CURVE,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getCharacteristicCurve'
  })

  const {
    onSubmit: updateCharacteristicCurve,
    loading: loadingUpdateCharacteristicCurve
  } = useMutation({
    query: UPDATE_CHARACTERISTIC_CURVE,
    dataPath: 'data.updateCharacteristicCurve',
    errorPolicy: 'global'
  })

  useEffect(() => {
    if ((mode === ACTIONS.COPY || mode === ACTIONS.EDIT) && rowData?.id) {
      fetchCharacteristicCurve({ id: rowData.id })
    }
  }, [mode])

  const initialValues = useMemo(() => {
    const {
      name = '',
      tisObjectId = '',
      curveType,
      maxValueYAxis,
      minValueYAxis,
      maxValueXAxis,
      minValueXAxis,
      runAnalytic,
      xAxisTargetKey,
      characteristicCurveDatas,
      id = 0
    } = characteristicCurveData || {}
    let points = []
    if (mode !== ACTIONS.ADD) {
      points = characteristicCurveDatas?.map((point) => JSON.parse(point))
    }

    return {
      name: (mode === ACTIONS.COPY ? `Copy of ${name}` : name) || '',
      curveType: curveType || '',
      tisObjectId,
      characteristicCurveId: id,
      circuitNumber: 1,
      yAxisRange: maxValueYAxis
        ? { from: minValueYAxis, to: maxValueYAxis }
        : { from: 0, to: 10 },
      xAxis: xAxisTargetKey
        ? xAxisTargetKey
        : isSingleCircuitChiller
        ? DEFAULT_X_AXIS_SINGLE_CIRCUIT
        : DEFAULT_X_AXIS,
      xAxisRange: maxValueXAxis
        ? { from: minValueXAxis, to: maxValueXAxis }
        : { from: 0, to: 100 },
      uomX: '%',
      uomY: '° F',
      runAnalytic:
        mode === ACTIONS.EDIT ? (runAnalytic === 1 ? true : false) : false,
      characteristicCurvePointList:
        points?.length > 0
          ? points
          : [
              {
                curveXAxisValue: '',
                curveYAxisValue: ''
              },
              {
                curveXAxisValue: '',
                curveYAxisValue: ''
              }
            ]
    }
  }, [characteristicCurveData, isSingleCircuitChiller, curvesCircuitsOptions])
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('equipment-setup:RequiredField')),
    curveType: Yup.string().required(t('equipment-setup:RequiredField')),
    xAxis: Yup.string().required(t('equipment-setup:RequiredField')),
    xAxisRange: Yup.object()
      .shape({
        from: Yup.number(),
        to: Yup.number()
      })
      .test(
        'xAxisRange',
        t('equipment-setup:CharacteristicsCurves>WrongRange'),
        ({ from, to }) => from < to
      ),
    yAxisRange: Yup.object()
      .shape({
        from: Yup.number(),
        to: Yup.number()
      })
      .test(
        'yAxisRange',
        t('equipment-setup:CharacteristicsCurves>WrongRange'),
        ({ from, to }) => from < to
      ),
    characteristicCurvePointList: Yup.array()
      .of(
        Yup.object().shape({
          curveXAxisValue: Yup.string().required(
            t('equipment-setup:RequiredField')
          ),
          curveYAxisValue: Yup.string().required(
            t('equipment-setup:RequiredField')
          )
        })
      )
      .min(2)
  })

  const handleSaveClick = async (values) => {
    const input = formCreateCharacteristicInput(
      values,
      equipment?.building?.id,
      equipment?.id,
      userInfo,
      mode
    )
    if (mode === ACTIONS.ADD || mode === ACTIONS.COPY) {
      const characteristicCurveId = await createCharacteristicCurveMutation({
        input
      })
      setMode(null)
      setReloadEvents(true)
    } else if (mode === ACTIONS.EDIT) {
      const oldInput = formCreateCharacteristicInput(
        initialValues,
        equipment?.building?.id,
        equipment?.id,
        userInfo,
        mode
      )
      const theDifferences = getEditDifferences(input, oldInput)
      if (!_isEmpty(theDifferences)) {
        const historyInput = {
          ...input,
          parentCurveId: values.characteristicCurveId,
          changedValue: JSON.stringify(theDifferences),
          isActive: 0
        }
        input['id'] = values.characteristicCurveId
        await updateCharacteristicCurve({ input })
        await createCharacteristicCurveMutation({ input: historyInput })
      }
      setMode(null)
      setReloadEvents(true)
    }
    if(mode === ACTIONS.ADD)
    {
      trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.SAVE_CHARACTERISTICS_CURVE, {mode: "CREATE", name:values.name, type: values.curveType})
    }
    else if(mode === ACTIONS.COPY)
    {
      trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.SAVE_CHARACTERISTICS_CURVE, {mode: "COPY", name:values.name, type: values.curveType})
    }
    else if(mode === ACTIONS.EDIT)
    {
      trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.SAVE_CHARACTERISTICS_CURVE, {mode: "EDIT", name:values.name, type: values.curveType})
    }
  }
  const loadDefaultValues = (values) => {
    // console.log(values)  //placeholder
  }
  const handleCCClose = () => {
    setMode(null)
  }
  const modalConfig = (values, isValid) => ({
    gray: true,
    heading: `${mode === ACTIONS.ADD ? 'Create' : mode} Characteristic Curve`,
    className: 'characteristic-curve-modal',
    loading:
      loadingCharacteristicCurveDetails || loadingCreateCharacteristicCurve,
    buttons: [
      {
        text: t('equipment-setup:CharacteristicsCurves>CopyFrom'),
        type: 'alternative',
        handleClick: () => {},
        disabled: true
      },
      {
        text: t('equipment-setup:CharacteristicsCurves>LoadDefaultValues'),
        type: 'alternative',
        handleClick: loadDefaultValues.bind(this, values),
        disabled: !values.curveType || !values.circuitNumber
      },
      { text: t('common:Cancel'), handleClick: handleCCClose, type: 'cancel' },
      {
        text: t('common:Save'),
        handleClick: handleSaveClick.bind(this, values),
        type: 'confirm',
        disabled: noCircuitFound || !isValid
      }
    ],
    formHasRequiredFields: true,
    handleClose: handleCCClose
  })

  const validateEnableAutomatedTestoption = (event, values, setFieldValue) => {
    if (event.target.checked) {
      const curve = values?.curveType
      const circuitNumber = values?.circuitNumber
      validation(event, setFieldValue, curve, circuitNumber)
    } else {
      setFieldValue('runAnalytic', event.target.checked)
    }
  }

  const getCircuitName = (circuitNumber) => {
    const option = curvesCircuitsOptions.find(
      (option) => option.key === circuitNumber
    )
    return option ? option.value.replace('CKT', '') : ''
  }

  const validation = (event, setFieldValue, curve, circuitNumber) => {
    const filteredTests = enableAutomatedTestCount.filter((c) =>
      c.includes(`${curve}  CKT${circuitNumber}`)
    )
    if (filteredTests.length > 0) {
      const warningTxt =
        curve === CURVE_TYPES.CONDENSER_APPROACH_TEMPERATURE
          ? 'condenser'
          : 'evaporator'
      setEnableAutomatedTestWarningMessage(
        `only one per ${warningTxt} type curve, per circuit, is supported`
      )
      setFieldValue('runAnalytics', false)
      setEnableAutomatedTestWarning(!enableAutomatedTestWarning)
    } else {
      setFieldValue('runAnalytic', event.target.checked)
    }
  }

  const toggleAutomatedTestDialog = () => {
    setEnableAutomatedTestWarning(!enableAutomatedTestWarning)
  }
  const enableAutomatedTestModalConfig = {
    heading: translate('Warning'),
    buttons: [
      {
        text: translate('Close'),
        handleClick: toggleAutomatedTestDialog,
        type: 'cancel'
      }
    ],
    handleClose: toggleAutomatedTestDialog
  }

  return (
    <>
      <Formik
        onSubmit={handleSaveClick}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({
          values,
          isValid,
          errors,
          setFieldTouched,
          setFieldValue,
          setValues
        }) => (
          <CurveDialogWrapper>
            <Modal {...modalConfig(values, isValid, errors)} ref={dialogRef}>
              {loadingCreateCharacteristicCurve ||
              loadingCharacteristicCurveDetails ? (
                <Spinner />
              ) : (
                <>
                  <Form>
                    <div className="rx-grid rx-grid-fit">
                      <div className="rx-col-6 left-column">
                        <EditableView
                          type={EditableView.COMPONENT_TYPE.INPUT}
                          className="name required"
                          labelName={'Name'}
                          name="name"
                          value={values.name}
                        />
                        <EditableView
                          type={EditableView.COMPONENT_TYPE.SELECT}
                          className="curve-type"
                          labelName={
                            <div className="rx-grid rx-grid-bleed">
                              <div>
                                <Label
                                  text={'Curve Type*'}
                                />
                              </div>
                              <LabelConditionalTooltip
                                className="curve-icon-div rx-col-nogrow"
                                conditional={false}
                                content={<TranslateComponent>A Chiller can only have one Evaporator Approach Temperature and one Condenser Approach Temperature curve per circuit.</TranslateComponent>}
                                align={LabelConditionalTooltip.ALIGN.CENTER}
                                position={LabelConditionalTooltip.POSITION.RIGHT}
                                multiline={true}
                                renderNode={_get(dialogRef, 'current.el', null)}
                              >
                                <div className="marker-icon icon-infocircle" />
                              </LabelConditionalTooltip>
                            </div>
                          }
                          name="curveType"
                          placeholder={'— Select One —'}
                          value={values.curveType}
                          onChange={(curveType) => {
                            setTimeout(() => {
                              setFieldValue('curveType', curveType)
                              setFieldValue('runAnalytic', false)
                            })
                          }}
                          options={curveTypes}
                          disabled={mode === ACTIONS.EDIT}
                          isUserdefinedOptions={false}
                        />
                        {noCircuitFound ? (
                          <div className="rx-grid">
                            <div className="rx-col-12">
                              <Label
                                text={`${t(
                                  'equipment-setup:CharacteristicsCurves>CircuitNumber'
                                )}*`}
                              />
                              <p>
                                <Label
                                  text="No circuit associated"
                                  disabled={true}
                                />
                              </p>
                            </div>
                          </div>
                        ) : curvesCircuitsOptions.length === 0 ? (
                          <Spinner />
                        ) : (
                          <EditableView
                            type={EditableView.COMPONENT_TYPE.SELECT}
                            className="circuits-number required"
                            labelName={t(
                              'equipment-setup:CharacteristicsCurves>CircuitNumber'
                            )}
                            name="circuitNumber"
                            placeholder={'— Select One —'}
                            onChange={(circuitNumber) => {
                              setTimeout(() => {
                                setFieldValue('circuitNumber', circuitNumber)
                                setFieldValue('runAnalytic', false)
                              })
                            }}
                            value={ characteristicCurveData?.instance 
                              ? characteristicCurveData.instance 
                              : curvesCircuitsOptions.find(
                                  (circuit) => circuit.key === values.circuitNumber
                                )?.value
                            }
                            options={curvesCircuitsOptions}
                            disabled={mode === ACTIONS.EDIT}
                            isUserdefinedOptions={false}
                          />
                        )}

                        <EditableView
                          name="yAxisRange"
                          className="y-axis"
                          component={({
                            formik: {
                              values: { curveType, yAxisRange }
                            },
                            onChange,
                            onBlur,
                            onFocus
                          }) => (
                            <>
                              <div>
                                <Label
                                  text={`${t(
                                    'equipment-setup:CharacteristicsCurves>YAxis'
                                  )}*: ${curveType || ''}`}
                                />
                              </div>
                              <AxisRange
                                range={yAxisRange}
                                onChange={onChange}
                                onBlur={onBlur}
                                onFocus={onFocus}
                              />
                            </>
                          )}
                        />
                        <EditableView
                          type={EditableView.COMPONENT_TYPE.GROUP}
                          name="xAxisRange"
                          className="x-axis"
                          component={({
                            formik: {
                              values: { circuitNumber, xAxis, xAxisRange }
                            }
                          }) => {
                            xAxis =
                              xAxis.charAt(0) === '@' ? xAxis.slice(1) : xAxis
                            return (
                              <>
                                {!isSingleCircuitChiller ? (
                                  <div>
                                    <Label
                                      text={<div>
                                        <TranslateComponent>X Axis*: </TranslateComponent>
                                        <TranslateComponent>
                                          {`Ckt ${getCircuitName(
                                            characteristicCurveData?.instance
                                              ? parseInt(characteristicCurveData.instance.replace('CKT', ''))
                                              : circuitNumber
                                          )} Current Draw (%RLA)`}
                                        </TranslateComponent>
                                      </div>}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <Label
                                      text={`X Axis*: ${t(
                                        'equipment-setup:CharacteristicsCurves>XAxisParameter>ChillerLoadResult'
                                      )}`}
                                    />
                                  </div>
                                )}
                                <EditableView
                                  name="xAxisRange"
                                  className="x-axis-range"
                                  component={({
                                    onChange,
                                    onBlur,
                                    onFocus
                                  }) => (
                                    <AxisRange
                                      range={xAxisRange}
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      onFocus={onFocus}
                                    />
                                  )}
                                />
                              </>
                            )
                          }}
                        />
                        <div className="selectrix-container">
                          <RunAnalyticsCheckBox
                            validateEnableAutomatedTestoption={
                              validateEnableAutomatedTestoption
                            }
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>
                      <div className="rx-col-6 right-column">
                        <CharacteristicCurveEntryView
                          dialogRef={dialogRef}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      </div>
                    </div>
                  </Form>
                  {showerror.err && (
                    <div className="charcteristic__model-error">
                      {t(
                        'equipment-setup:CharacteristicsCurves>loadDefaultValuesErrMsg'
                      )}
                    </div>
                  )}
                </>
              )}
            </Modal>
          </CurveDialogWrapper>
        )}
      </Formik>

      {enableAutomatedTestWarning && (
        <Modal {...enableAutomatedTestModalConfig}>
          <div>
            <TranslateComponent>
              {`Run Automated Test option can be enabled, ${enableAutomatedTestWarningMessage}`}
            </TranslateComponent>
          </div>
        </Modal>
      )}
    </>
  )
}

export default CharacteristicCurveDialog
