import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import {
  LIST_APPLICATIONS
} from './queries'
import { useQuery } from 'src/hooks/APIHooks'
import { Button } from 'src/components/inputs/button'
import { NewStyles } from 'src/NewStyles'
import translate, { TranslateComponent } from '../../common/translations'
import { ApplicationPermissions } from './applicationPermissions'
import { Accordion } from '@trane/trane-components/dist'
import { useNavigate } from 'react-router'
import { pages } from 'src/pages/pages.js'
import { ACTIONS } from 'src/constants'

export const PermissionManagement = () => {
  const navigate = useNavigate()

  const {data: applicationList} = useQuery({
    query: LIST_APPLICATIONS,
    errorPolicy: 'all',
    dataPath: 'data.listApplications.items'
  })
  const optionsTranslated = {
    createPerm: translate("Create Permission")
  }
  const accordionData = applicationList?.map(app => ({header: app.name, content: <ApplicationPermissions appId={app.id} />}))

  return (
    <NewStyles>
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        padding="0"
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <PageHeader
            pageTitle={<TranslateComponent>Permission Management</TranslateComponent>}
            titleTools={
              <Button onClick={() => navigate(
                pages['Permission Management/Add'].href,
                {state:{mode:ACTIONS.ADD}}
              )}>
                {optionsTranslated.createPerm}
              </Button>
            }
          />
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <Accordion data={accordionData || []} />
        </Content>
      </Container>
    </NewStyles>
  )
}
