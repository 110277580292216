import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './selected-items.scss'

export default class SelectedItems extends PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onRemoveItem: PropTypes.func.isRequired
  }

  render() {
    const { items, onRemoveItem } = this.props

    return (
      <div className="selected-items">
        {items.length ? (
          <>
            <h3>Selected:</h3>
            {items.map(({ key, value }) => (
              <div key={key} className="selected-item">
                <span
                  onClick={() => onRemoveItem({ key, value })}
                  className="icon icon-close-circle"
                />
                <span className="name">{value}</span>
              </div>
            ))}
          </>
        ) : null}
      </div>
    )
  }
}
