import { SeriesPointsCollection } from './seriesPointsCollection'

export interface Point<T, V> {
  key: T
  value: V
}

export class SeriesPoints<T, V> {
  private readonly id = '__rbt_' + Math.ceil(Math.random() * 1000000000000)

  private disabled = false

  private data: any = {}

  private base: SeriesPointsCollection<T, V>

  constructor(base: SeriesPointsCollection<T, V>) {
    this.base = base
    this.base._internal_register_points_series(this.id, this)
  }

  insert(key: T, value: V) {
    // Round off the decimal values
    key = parseFloat(Number(key).toFixed(3)) as any

    this.add(key, value)
    this.base._internal_insert_item(key)
  }

  insertAll(points: Point<T, V>[] = []) {
    points.forEach((point) => {
      if (point.key !== undefined && point.key !== null) {
        this.insert(point.key, point.value)
      }
    })
  }

  remove(key: T) {
    // Round off the decimal values
    key = parseFloat(Number(key).toFixed(3)) as any

    this.delete(key)
    this.base._internal_remove_item(key)
  }

  removeAll(points: Point<T, V>[] = []) {
    points.forEach((point) => {
      if (point.key !== undefined && point.key !== null) {
        this.remove(point.key)
      }
    })
  }

  show() {
    this.disabled = false
  }

  hide() {
    this.disabled = true
  }

  destroy() {
    this.clear()
    this.data = {}
    this.base._internal_unregister_points_series(this.id)
  }

  clear() {
    Object.keys(this.data).forEach((key: any) => {
      this.base._internal_remove_item(key)
      this.delete(key)
    })
  }

  _internal_query(key: T): V[] {
    if (this.disabled) return []

    return this.data['_' + key] || []
  }

  private add(key: T, value: V) {
    const k = '_' + key

    if (!this.data[k]) this.data[k] = []

    this.data[k].push(value)
  }

  private delete(key: T) {
    delete this.data['_' + key]
  }
}
