import styled from 'styled-components/macro'

export const MainContainer = styled.div``

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  button {
    border-radius: 0;
    height: 30px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .filter-label,
  .counter {
    font-weight: 600;
  }
  .filter-section {
    margin-left: -6px;
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .counter {
    font-style: italic;
  }
  .control-label,
  .icon-expand {
    color: #444444 !important;
  }
`

export const TableSection = styled.div`
  min-height: 100px;
  margin-top: 13px;
  table {
    tbody {
      tr {
        #no-records-row {
          text-align: center;
        }
      }
    }
    .tooltip-overflow {
      position: absolute;
      margin-top: 0px;
      margin-left: 2px;
    }
    .tooltip-wrapper {
      position: relative;
      &:hover {
        .tooltip-base {
          display: inline-block;
        }
      }
      .tooltip-anchor {
        white-space: nowrap;
        overflow-x: hidden !important;
        text-overflow: ellipsis;
      }

      button {
        display: block;
        color: $tba-green;
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
        border: none;
      }
    }

    .header-center {
      display: flex;
      align-items: center;
    }
    .table-header-spinner {
      &.section-spinner {
        height: 5px;
        padding: 1px;
        margin-left: 2px;
        background: transparent;
        border: none;
        width: 20px;
        .spin-wheel {
          font-size: 15px;
          border: none;
          color: white;
        }
      }
    }
  }
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  z-index: 99;
  width: 96%;
  height: 17vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HyperLinkCell = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const TableWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  .table-wrapper {
    /* height: calc(100vh - 255px);
        overflow: auto; */
    table {
      thead {
        /* position: sticky;
                top: 0;
                z-index: 1; */
        tr {
          th {
            &:first-child {
              width: 250px;
            }
          }
        }
      }
      tbody {
        tr {
          #no-records-row {
            text-align: center;
          }
        }
      }
    }
  }
`

export const TableStyle = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0px;
  border: 1px solid var(--amplify-components-table-border);
  width: 100%;

  thead {
    background: var(--amplify-components-table-head-background);
    height: auto;
    font-size: 12px;
    text-transform: uppercase;
    tr {
      th {
        color: var(--amplify-components-table-head-cell-color);
        padding: var(--amplify-components-table-head-cell-spaces-top)
          var(--amplify-components-table-head-cell-spaces-right)
          var(--amplify-components-table-head-cell-spaces-bottom) 0;
        cursor: pointer;
        vertical-align: middle;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  tbody {
    font-size: 12px;
    height: auto;
    color: var(--amplify-components-table-row-color);
    border-top: 1px solid var(--amplify-components-table-row-border);
    border-bottom: 1px solid var(--amplify-components-table-row-border);

    tr:nth-child(2n) {
      background-color: #ffffff;
      height: 30px;
      td {
        color: var(--amplify-components-table-cell-color);
        vertical-align: middle;
        border-top: 1px solid var(--amplify-components-table-cell-border);
        text-align: left;
        padding: 4px 18px 4px 0px;
      }
    }

    tr:nth-child(2n + 1) {
      background-color: #eeeeee !important;
      height: 30px;

      td {
        color: var(--amplify-components-table-cell-color);
        vertical-align: middle;
        border-top: 1px solid var(--amplify-components-table-cell-border);
        text-align: left;
        padding: 4px 18px 4px 0px;
      }
    }

    .name-block {
      display: inline-block;
      max-width: 94%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

export const LabelDiv = styled.div`
  width: 58%;
  margin-top: -23px;
  float: right;
`

export const StyledLabel = styled.label`
  height: 20px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.24px;
  line-height: 20px;
  align-self: flex-end;
  margin-left: 10px;
`
