/**
 * Decorator for extending React Component with slideUp/slideDown functionality
 *
 * This decorator needs ref element (this.toggleBoxBody)
 * and should be referenced to node wrapper which should be toggled
 *
 * Also extends parent class with toggleBox function (this.toggleBox) and
 * opened, height, overflow state
 *
 * @param ReactComponent
 * @returns {{defaultProps, new(*=): SlideToggle, prototype: SlideToggle}}
 */

export default function slideToggle(ReactComponent) {
  return class SlideToggle extends ReactComponent {
    static defaultProps = {
      opened: false,
      duration: 400,
      ...ReactComponent.defaultProps
    }

    constructor(props) {
      super(props)

      this.state = {
        ...this.state,
        opened: Boolean(this.props.opened),
        height: 0,
        overflow: this.props.opened ? null : 'hidden'
      }
    }

    animationFrame = null

    componentDidMount() {
      const { opened } = this.state
      if (opened) {
        this.setState({
          overflow: null,
          height: null
        })
      }
    }
    componentWillUnmount() {
      cancelAnimationFrame(this.animationFrame)
    }

    toggleBox = () => {
      const opened = !this.state.opened

      this.setState({ opened })
      this.slideToggle(opened)
    }

    slideToggle(opened) {
      const { duration } = this.props
      const { height: stateHeight } = this.state
      const height = this.toggleBoxBody.scrollHeight

      cancelAnimationFrame(this.animationFrame)

      if (opened) {
        this.slideDown(duration, height, stateHeight)
      } else {
        this.slideUp(duration, height, stateHeight)
      }
    }

    slideUp(duration, height, stateHeight) {
      let startTime

      if (stateHeight && stateHeight < height) {
        duration = (duration / height) * stateHeight
        height = stateHeight
      }

      const slideUpStep = (timestamp, dist, duration) => {
        const runtime = timestamp - startTime
        const progress = Math.min(runtime / duration, 1)

        this.setState({ height: Number(dist - dist * progress) })

        if (runtime < duration) {
          this.animationFrame = requestAnimationFrame((timestamp) =>
            slideUpStep(timestamp, dist, duration)
          )
        }
      }

      this.animationFrame = requestAnimationFrame((timestamp) => {
        startTime = timestamp
        slideUpStep(timestamp, height, duration)
        this.setState({ overflow: 'hidden' })
      })
    }

    slideDown(duration, height, stateHeight) {
      let startTime

      let runtimeIndex = 0
      if (stateHeight && stateHeight > 0) {
        runtimeIndex = (duration / height) * stateHeight
      }

      const slideDownStep = (timestamp, dist, duration) => {
        const runtime = timestamp - startTime + runtimeIndex
        const progress = Math.min(runtime / duration, 1)

        this.setState({ height: Number((dist * progress).toFixed(2)) })

        if (runtime < duration) {
          this.animationFrame = requestAnimationFrame((timestamp) =>
            slideDownStep(timestamp, dist, duration)
          )
        } else {
          this.setState({
            overflow: null,
            height: null
          })
        }
      }

      this.animationFrame = requestAnimationFrame((timestamp) => {
        startTime = timestamp
        slideDownStep(timestamp, height, duration)
        this.setState({ overflow: 'hidden' })
      })
    }
  }
}
