import { DashboardContextProvider } from './components/DashboardContextProvider'
import DashboardContainer from './components/DashboardContainer'
import { useDispatch } from 'react-redux'
import { setDashboard } from 'src/redux/slicers/dashboard'
import { CreateBtn } from './styles'
import { TranslateComponent } from 'src/common/translations'
export const Dashboard = () => {
    return (<DashboardContextProvider>
        <DashboardContainer />
    </DashboardContextProvider>)
}

export const Dashboards = () => {
    const dispatch = useDispatch();
    const createDashboard = () => {
        dispatch(setDashboard({
            actionType: "Create"
          }))
    }
    return (<div>
        <h2><TranslateComponent>You have not created a dashboard.</TranslateComponent></h2>
        <h2><TranslateComponent>Click here to create one now.</TranslateComponent></h2>
        <CreateBtn onClick={createDashboard}><TranslateComponent>Create Dashboard</TranslateComponent></CreateBtn>
    </div>)
}

