import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'

export const WhereWeGoFromHereWrapper = styled.section`
 && .opportunity-block123 {
	min-width: 140px;
	padding: 10px 20px 26px 10px;
	font-size: 14px;
	display: flex;
	flex-flow: column;
	align-content: space-between;
	flex: auto;
	background: $white;
	border-radius: 6px;
	border: 1px solid ${colors.TBA_LIGHTER_GREY};
	position: relative;
	overflow-wrap: break-word;

	& .label {
		font-size: 14px;
		font-style: italic;
		color: ${colors.BLACK};
		display: block;
	}
	& .title {
		a {
			font-size: 18px;
			color: #455fd9;
			line-height: 22px;
			font-family: "ArialMT";
			text-decoration: none
		}
		padding-bottom: 20px;
	}
	& .text-block {
		p {
			margin: 0;
			font-size: 21px;
			font-weight: bold;
			padding-bottom: 4px;
		}
		padding-bottom: 20px;
	}
	& .kpi-block {
		&>div {
			display: flex;
			padding-bottom: 20px;
		}
		p {
			margin: 0;
		}
		& .kpi-priority {
			font-weight: bold;
		}
		& .kpi-icon-block {
			width: 20%;
			display: flex;
			justify-content: center;
		}
		& .kpi-name-block {
			padding-left: 10px;
			width: 80%;
			& .text-block {
				padding: 0;
			}
			p {
				font-size: 16px;
			}
			span {
				font-style: normal;
			}
		}
	}
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 10.65px;
		bottom: 0;
		left: 0;
		border-radius: 0 0 6px 6px;
		background: ${colors.GREY};
	}

}

`




