import Container from 'src/components/Container'
import { Block } from 'src/components/layouts'
import {EditActiveStyledContainer,
  FormSubHeader,StyledAddOfferings,StyledOfferingsLabel,
  StyledButtonsContent, MinInputField,
StyledInput
} from './styles'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {validate} from './formValidator.js'
import { useQuery } from 'src/hooks/APIHooks'
import { useLocation, useNavigate,useParams } from "react-router-dom"
import { pages } from 'src/pages/pages.js'
import { useMutation } from '../../../hooks/APIHooks'
import { ACTIONS } from 'src/constants'
import AttachFiles from '../add/AttachFiles'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import Label from "src/components/legacy/components/label/label"
import moment from 'moment'
import { UPDATE_CONTRACT
  ,CREATE_OFFERING,UPDATE_OFFERING, 
  GET_OFFERINGS_BY_BUILDING_func,
  GET_CONTRACT_BY_ID } from './queries'
import {LIST_OFFERINGS_BY_CONTRACT,GET_BUILDINGDETAILS_BY_ID_func} from "../queries"
import { AddBuildingModal } from './AddBuildingModal'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import Modal from 'src/components/legacy/components/modal/modal'
import {
  filterOfferings,
  getDefautContractPeriod,
  getExpirationDateByContractPeriod,
  isOfferingDisabled,
  getOfferingDetailsBySubscriptionName,
  STATUS_TO_SAVE,
  ACCEPTED_FILE_TYPES
} from "src/pages/contracts-and-offerings/common/contracts-and-offerings"
import {BuildingsList} from "./BuildingsList"
import { useTranslation } from 'react-i18next'
import { TranslateComponent } from 'src/common/translations'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import Tooltip from 'src/components/legacy/components/tooltip/tooltip'
import {
  DATE_FORMAT_DASH1,
  isValid,
  utcTimeEndOfTheDay,
  utcTimeStartOfTheDay
} from 'src/components/legacy/common/time-helpers'
import { CREATE_ATTACHMENT,CREATE_ENTRY_WITH_ATTACHMENT,
  DELETE_ATTACHMENT,UPDATE_ATTACHMENT } from '../queries'
  import { getUniqueItems } from 'src/common/helperFunctions'
  import{useConfig} from "src/pages/contracts-and-offerings/common/config"
  import {
    hasCMSA
  } from "src/pages/contracts-and-offerings/common/contracts-and-offerings"
  import {valueWithinMinMax} from "./helpers";
import { AddOfferingsModal } from './AddOfferingsModal'




export const EditActiveContract = ({initialValues = null}) => {
  const { id: contractId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [mode, setMode] = useState(ACTIONS.EDIT)
  const [doneSaving, setDoneSaving] = useState(false)
  // const [type, setType] = useState(paramType)
  const [offeringDDLData, setOfferingDDLData] = useState([])
  const [showAddOfferingModal, setShowAddOfferingModal] = useState(false)
	const [showAddBuildingModal, setShowAddBuildingModal] = useState(false)
	const [showAddOfferingSuccess, setShowAddOfferingSuccess] = useState(false)
	const [showAddingSuccess, setShowAddingSuccess] = useState(false)
  const [contractOfferings,setContractOfferings] = useState(null)
  const [confirm, setConfirm] = useState(null)
  const [dialogTypes,setDialogTypes] = useState(null)
  const [selectedBuildings, setSelectedBuildings] = useState([])
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false)
  const {offerings:offeringsConfig,contracts:contractsConfig} = useConfig()
  const {
    refetch: refetchContractById,
    loading:loadingContractById
  } = useQuery({
    query:GET_CONTRACT_BY_ID,
    disableInitialLoad:true,
    dataPath:"data.listContracts.items"
  })

  const {
    refetch: refetchOfferingsByContract
  } = useQuery({
    query:LIST_OFFERINGS_BY_CONTRACT,
    disableInitialLoad:true,
    dataPath:"data.listOfferingsByContract.items"
  })

  useEffect(() => {
    if (doneSaving) {
      navigate(pages["Contracts & Offerings"].href)
    }
  }, [doneSaving])

  useEffect(() => {
    if(contractId){
      (async () => {
       const contractAndOfferings = await Promise.all([
        refetchContractById({id:contractId}),
        refetchOfferingsByContract({id:contractId})
       ])
       setContractOfferings({contract : contractAndOfferings[0][0],offerings :contractAndOfferings[1]})
      })()
      
    }
  }, [contractId])


  
  const {refetch:refetchBuildingsByIds} = useQuery({
    query:null
  })

  const {refetch:refetchOfferingsByBuilding} = useQuery({
    query:null
  })




  const { onSubmit: createOffering, loading: isLoadingCreateOffering} = useMutation({
    query: CREATE_OFFERING,
    dataPath: 'data.createOffering'
  })

  const { onSubmit: updateContract, loading: isLoadingUpdateContract } = useMutation({
    query: UPDATE_CONTRACT,
    dataPath: 'data.updateContract'
  })

  const { onSubmit: updateOffering, loading: isLoadingUpdateOffering } = useMutation({
    query: UPDATE_OFFERING,
    dataPath: 'data.updateOffering'
  })

  const { onSubmit: createAttachmentMutation } = useMutation({
    query: CREATE_ATTACHMENT
  })

  const { onSubmit: deleteAttachmentMutation } = useMutation({
    query: DELETE_ATTACHMENT
  })

  const { onSubmit: updateAttachmentMutation } = useMutation({
    query: UPDATE_ATTACHMENT
  })

  const { onSubmit: createEntryWithAttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })
  const combinedLoading = 
        isLoadingUpdateContract || isLoadingUpdateOffering || isLoadingCreateOffering

  const onDeleteAttachment = (fileId, fileName) => {
    formik.setFieldValue(
      'attachments',
      values.attachments.filter(
        (attachment) => !(attachment.fileName === fileName)
      )
    )
  }


const showConfirm = (fileId, fileName) => {
  setConfirm({
    title: t('notes:DeleteFile'),
    text: t('notes:DeleteFileConfirmation', { fileName }),
    className: 'confirm-delete-dialog',
    confirmText: t('common:confirmText'),
    cancelText: t('common:cancelText'),
    handleConfirm: () => {
      setConfirm(null)
      onDeleteAttachment(fileId, fileName)
    }
  })
}



  useEffect(() => {
    if(mode === ACTIONS.EDIT && initialValues){
      refetchBuildingsByIds(null,null, GET_BUILDINGDETAILS_BY_ID_func(values.buildingIds)).then(res => {
        if(res?.data && !res.data.errors){
        const buildings = Object.values(res.data)
        

        setOfferingDDLData(state  => 
          [...state,
        ...buildings.reduce((acc:[any],b:any):[any] => {
          const data =  {
            key:b.id,
            label:b.name,
            crmCustId:b.crmCustId,
            address:b.address
          }
          if(!(state.some(building => building.key === b.id))){
          acc.push(data)
          }
          return acc;
        }
       ,[] ) as any[]
      ]
        )


    }
        })
      }
    },[mode,initialValues]
    
    )



  const handleAddOffering = e => {
		e.preventDefault()
		setShowAddOfferingModal(true)
	}
	const handleAddBuilding = e => {
		e.preventDefault()
		setShowAddBuildingModal(true)
	}

	// Handle modal close option
	const handleCloseAddOfferingModal = () => {
		setShowAddOfferingModal(false)
	}
	const handleCloseAddBuildingModal = () => {
		setShowAddBuildingModal(false)
	}
  const onCloseDialog = field => () => {
    setDialogTypes({
      [field] : null
    })
    if (isUpdateSuccess){
      setDoneSaving(true)
    }
	}

  const showAddOfferingSuccessModal = () => (
		<Modal
			gray={true}
			className="add-offering-success-modal"
			heading="Offerings Added"
			handleClose={() => {
        setShowAddOfferingSuccess(false)
        handleCloseAddOfferingModal()
      }}
			buttons={[{
				text: "Close",
				handleClick: () => {
          setShowAddOfferingSuccess(false)
          handleCloseAddOfferingModal()
        }
			}]}
		>
			<fieldset>
				<p className="">The Selected Offerings have been added to the contract and applied to all specified buildings</p>
			</fieldset>
		</Modal>
	)



	const showAddBuildingSuccessModel = () => (<Modal
		gray={false}
		className="add-offering-success-modal"
		heading={'Buildings Added'}
		handleClose={() => {
      setShowAddingSuccess(false)
      handleCloseAddBuildingModal()
    }}
		buttons={[{
			text: "Close",
			handleClick: () => {
        setShowAddingSuccess(false)
      handleCloseAddBuildingModal()
      }
		}]}
	>
		<fieldset>
			<p className=""> {selectedBuildings?.length} Building(s) have been added to your contract. Selected offerings will be applied to the newly added buildings. </p>
		</fieldset>
	</Modal>)



  const formik = useFormik({
    initialValues : {
      ...initialValues,
      "newOfferingsToBuildinsMap":initialValues.offeringsToBuildinsMap.map(off => ({...off,checkboxCheckedEnabled:true}))
    },
    enableReinitialize: true,
    validateOnMount:true,
    validateOnChange: true,
    validate,
    onSubmit: async (values: any) => {
      const newBuildings = values.newOfferingsToBuildinsMap.filter(_new  =>  _new.checkboxCheckedEnabled && !(values.offeringsToBuildinsMap.some(existing => _new.buildingId === existing.buildingId && _new.name === existing.name))
        )
      if(newBuildings?.length > 0){
      const {data : existingOfferingsOfSelectedBuildingsData} = 
        await refetchOfferingsByBuilding(null,null, GET_OFFERINGS_BY_BUILDING_func(getUniqueItems(newBuildings.map(n => n.buildingId))))        

        const existingOfferingsOfSelectedBuildings:any = Object.values(existingOfferingsOfSelectedBuildingsData).reduce((acc:any,off:any) => 
        {
          const data = off.items.map((_off:any) =>  ({name : _off.name,
            buildingId: _off.buildingId
          })
          )
          acc = acc.concat(data)
         return acc
        }
          ,[]) 

          const duplicateOfferingsforBuildings = newBuildings.reduce((acc, value) => {
            const currentBuildingOfferings = existingOfferingsOfSelectedBuildings.filter((off: any) => off.buildingId === value.buildingId)
            const duplicates = currentBuildingOfferings.reduce((dupAcc,off) => {
              if (!['CS', 'BR'].includes(off?.name)){
             if( off.name === value.name ||   isOfferingDisabled(currentBuildingOfferings.map(off => off.name), value.name)){
             !(dupAcc.some(a => a.name === value.name && a.buildingId === value.buildingId)) && dupAcc.push(value)
              return dupAcc
             }
            }
            return dupAcc
              
            },[])
              acc = duplicates?.length > 0 ? acc.concat(
                duplicates
              ) : acc;
            return acc
          }, [])
        if (duplicateOfferingsforBuildings?.length > 0) {
          const errorText = <>
            Duplicate active offering found for the following locations:
            <ul >
              {duplicateOfferingsforBuildings?.map(
                duplicateOfferingsforBuilding =>
                (<li key={duplicateOfferingsforBuilding.buildingId}>{`${offeringDDLData.find(off => off.key === duplicateOfferingsforBuilding.buildingId)?.label} - ${duplicateOfferingsforBuilding.name}`}</li>)
                )
              }
                
            </ul>
          </>
          setDialogTypes({
            error: {
              text: errorText
            },
            onCloseDialog: () => () => setDialogTypes(null)
          })
          return;
        }
      }

      const inputData = {
        id:values.contractId,
        accountId:values.accountId,
        deviceCount: parseInt(values.numberOfDevices),
        buildingCount: parseInt(values.buildingCount),
        stream: values.businessStream,
        serviceNumber: values.serviceContractNumber,
        status: STATUS_TO_SAVE.APPROVED,
          endDate: utcTimeEndOfTheDay(getExpirationDateByContractPeriod({contractStartDate:values.startDate,
            contractPeriod : values.length,
            expDate:values.expirationDate
          })),
        coachingServicesValue: values.coachingServicesValue,
        csTermsAccepted: values.csTermsAccepted,
        primaryBuilding: values.primaryBuilding,
        totalBaselineReportCount: values.totalBaselineReportCount,
        additionalContractNumbers:JSON.stringify({
          mechanicalServiceAgreementNumber : values.mechanicalServiceAgreementNumber,
          pactContractNumber: values.pactContractNumber
        })

        // primaryBuilding: values.primaryBuilding

      }
      await updateContract({input: inputData}).then(async res => {
        if(!res.errors){
          setIsUpdateSuccess(true)
          setDialogTypes({
            notification: {
              title: "Success!",
              text: "Your edits have been saved and the contract request has been updated!",
            }
          })
          const saveAttachements  = () => values.attachments
          .filter((a) => !a.id)
          .map((a) =>
            createAttachmentMutation({
              input: {
                attachedAt: new Date(a.timestamp).toISOString(),
                buildingId: values.accountId,
                contractId:res.id,
                description: a.description,
                name: a.fileName,
                title: a.title,
                sizeInBytes: a.fileSize,
                userId: values.currentUserId,
                type: a.type
              }
            })
          )

          const updateAttachements  = () => values.attachments
          .filter((a) => a.id)
          .reduce((acc,value) => {
            const existingAttachmnent  = values.originalAttachments.find(a => a.id === value.id)
            if(existingAttachmnent?.description !== value.description)
           acc.push( updateAttachmentMutation({
              input: {
                description: value.description,
                id:value.id
              }
            })
           )
           return acc;
          },[]
          )

          const deleteAttachements  = () => values.originalAttachments
          .filter((oa) => !(values.attachments.find(a => a.id === oa.id)))
          .map(attachment  => deleteAttachmentMutation({
            input: {
              id:attachment.id
            }
          }))
        const {newOfferingsToBuildinsMap,offeringsToBuildinsMap} = values

          const offeringsToDelete = offeringsToBuildinsMap.filter(mapping => 
            !(newOfferingsToBuildinsMap.some(newMapping => newMapping.buildingId === mapping.buildingId &&
              newMapping.name === mapping.name && newMapping.checkboxCheckedEnabled)
              )
            );
          const offeringsToUpdate = offeringsToBuildinsMap.filter(mapping => 
            (newOfferingsToBuildinsMap.some(newMapping => newMapping.buildingId === mapping.buildingId &&
              newMapping.name === mapping.name &&  newMapping.checkboxCheckedEnabled)
              )
            )?.map(offering => ({
              id:offering.id,
              status: STATUS_TO_SAVE.APPROVED,
              isActive: 1,
              startDate: utcTimeStartOfTheDay(values.startDate),
              endDate: utcTimeEndOfTheDay(getExpirationDateByContractPeriod({contractStartDate:values.startDate,
                contractPeriod : values.length,
                expDate:values.expirationDate
              }))
            }))
          const offeringsToCreate = newOfferingsToBuildinsMap.filter(newMapping => 
            !(offeringsToBuildinsMap.some(mapping => newMapping.buildingId === mapping.buildingId &&
              newMapping.name === mapping.name && newMapping.checkboxCheckedEnabled) 
              ) && newMapping.checkboxCheckedEnabled
            )?.map(offering => {
              const offeringDetails = getOfferingDetailsBySubscriptionName(contractsConfig,offeringsConfig,offering.name)
              return {buildingId: offering.buildingId,
              accountId: values.accountId,
              contractId: res.id,
              name:  offeringDetails?.Name || offering.name,
                code: offeringDetails?.Code || offering.name,
                subscriptionCode:offering.name,
              description: offeringDetails?.Description || "",
              analyticsStartDate: utcTimeStartOfTheDay(values.analyticsStartDate || values.startDate),
              startDate: utcTimeStartOfTheDay(values.startDate),
              endDate: utcTimeEndOfTheDay(getExpirationDateByContractPeriod({contractStartDate:values.startDate,
                contractPeriod : values.length,
                expDate:values.expirationDate
              })),
              gracePeriod: offeringDetails?.GracePeriod || 30, //Unsure on this
              status: STATUS_TO_SAVE.APPROVED,
              isActive: 1,
              type: "Building",
              typeId: "NA",
            }
            })
          Promise.all([ 
            ...(offeringsToCreate.map(async e => await createOffering({input: e}))),
            ...(offeringsToDelete.map(offeringDelete => updateOffering({input: {id: offeringDelete.id,status:STATUS_TO_SAVE.DELETED,isActive:0}}))),
            ...(offeringsToUpdate.map(offeringInput => updateOffering({input:offeringInput})))
          ]
          ).then(pres => {
            Promise.all([...saveAttachements(),
              ...updateAttachements(),
              ...deleteAttachements()
            ]).then((response) =>
            Promise.all(
              response.map((r) => {
                return r?.data?.createAttachment?.id
                  ? createEntryWithAttachmentMutation({
                      input: JSON.stringify({
                        modelType: 'Contract',
                        attachments: {
                          create: [
                            {
                              buildingId:
                                r.data.createAttachment.buildingId,
                                contractId:
                                r.data.createAttachment.contractId,
                              name: r.data.createAttachment.name
                            }
                          ]
                        }
                      })
                    })
                  : ''
              })
            ).then(() => setDoneSaving(true))
            )
          })
        }
        } )
      }
  })

  const { values, errors } = formik

  const handleOfferingCancel = async () => {
   return  await Promise.all(await values?.offeringDetails?.map(async e => await updateOffering({
      input: {
        id: e?.id,
        status: STATUS_TO_SAVE.CANCELLED,
        isActive:0
      }
    })))

  }
  const handleContractCancel = async () => {
    return await updateContract({
      input: {
        id: values?.contractId,
        status: STATUS_TO_SAVE.CANCELLED
      }
    })
  }

  const handleRemoveOffering = useCallback(
    (newOfferingNames, removedOffering) => {
      setConfirm({
        title: "Remove Offering",

        text: `Are you sure you want to remove: ${removedOffering}?`,

        description: `Removing this offering will apply to all ${offeringDDLData?.length} buildings in this contract.`,

        confirmText: "Remove Offering",

        cancelText: t("common:Cancel"),

        handleConfirm: () => {
          setConfirm(null)

          updateOfferings(newOfferingNames)
        },

        handleCancel: () => setConfirm(null),
      })
    },
    [setConfirm, offeringDDLData, t]
  )

  const updateOfferings = useCallback(
    (newOfferingNames) => {
      formik.setFieldValue('offerings', newOfferingNames)
      for (const offering of newOfferingNames) {
        const defaultContractPeriodKey = getDefautContractPeriod(
          contractsConfig,
          offering.key
        )
        defaultContractPeriodKey
          ? formik.setValues({
              ...values,
              offerings: newOfferingNames,
              length: defaultContractPeriodKey
            })
          : formik.setFieldValue('offerings', newOfferingNames)
      }
      const modifiedExistingOfferingsToBuildinsMap =
        values?.newOfferingsToBuildinsMap?.filter((offering) =>
          newOfferingNames?.includes(offering.name)
        )
      formik.setFieldValue(
        'newOfferingsToBuildinsMap',
        modifiedExistingOfferingsToBuildinsMap
      )
      setConfirm({
        title: 'Offering Removed',
        text: `Changes will be applied when the updated contract request is saved.`,
        isHideConfirmButton: true,
        cancelText: t('common:Close'),
        handleCancel: () => {
          setConfirm(null)
        }
      })
    },
    [formik, contractsConfig, values]
  )

  const handleOfferingChange = useCallback(
    (selectedOfferings) => {
      const newOfferingNames = selectedOfferings?.map((item) => item.key)
      if (newOfferingNames?.length < values?.offerings?.length) {
        const removedOffering = values?.offerings?.find(
          (item) => !newOfferingNames?.includes(item)
        )

        handleRemoveOffering(newOfferingNames, removedOffering)
      } else {
        updateOfferings(newOfferingNames)
      }
    },
    [values.offerings, handleRemoveOffering, updateOfferings]
  )


  return (
    <EditActiveStyledContainer>
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageHeader
          pageTitle={`Edit Contract Request: Contract ${values.serviceContractNumber}`}
          titleTools={
            <>
              <button className="cancel-contract-btn" onClick={e => {
                 setConfirm({
                  title: 'Confirm Cancellation of Contract',
                  text: `Are you sure you want to cancel offerings for contract ${values?.serviceContractNumber ? `:${values?.serviceContractNumber}` : ''} ?`,
                  description: `Cancelling the contract will disable all offerings for all buildings on this contract.`,
                  confirmText: 'Cancel Contract',
                  cancelText: t('common:Cancel'),
                  handleConfirm: async () => {
                   setConfirm(null)
                   const res:any = await Promise.all([handleContractCancel(),handleOfferingCancel()])
                   const errors = res.flat()?.find(_data =>  !_data);
                   if(!errors){
                     setConfirm({
                       title: 'Cancellation Confirmation',
                       text: `${values?.serviceContractNumber ? `${values?.serviceContractNumber}` : ''}: has been cancelled!`,
                       isHideConfirmButton: true,
                       cancelText: t('common:Close'),
                       handleCancel: () => {
                         setConfirm(null)
                         navigate(pages["Contracts & Offerings"].href)
                       }
                     })
                   }
                  },
                  handleCancel: () => {
                    setConfirm(null)
                  }
                })
              }}>
                <span className="close-button icon icon-close" />{"Cancel Contract"}
              </button>
            </>
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
<h3>
  <TranslateComponent>
  Buildings and Offerings
  </TranslateComponent>
</h3>
        </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><StyledOfferingsLabel text={
              <Block display={"flex"} justify={"space-between"}>
              <span className={errors.offerings && "error"}>
                Offering(s)*
              </span>
              <StyledAddOfferings onClick={e => handleAddOffering(e)}>
                + Add Offerings
              </StyledAddOfferings>
              </Block>
            }/>
              <div>
                <SelectrixWrapper
                  className={
                    'editActive location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select'
                  }
                  placeHolderInside={true}
                  defaultValue={values.offerings}
                  multiple={true}
                  onChange={handleOfferingChange}
                  options={filterOfferings(contractsConfig, values.offerings)}
                  searchable={false}
                  searchBoxInside={false}
                  searchIndex={false}
                  stayOpen={true}
                />
                {/* {<p className="error"><>{errors.utilityId && "Utility Company is required."}</></p> } */}
                {errors.offerings && (
                  <p className="error">
                    <>{errors.offerings}</>
                  </p>
                )}
              </div>

          </>
          : <><label>
            {/* {`Utility Company: `} */}
            </label>
            {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
        }
        </Content>
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
<h4>
  <TranslateComponent>
 Select Offerings
  </TranslateComponent>
</h4>
<span>
<TranslateComponent>
Use checkboxes to enable/disable offerings for buildings in this contract.
  </TranslateComponent>
</span>
        </Content>
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><StyledOfferingsLabel text={
              <Block display={"flex"} justify={"space-between"}>
              <span>
              </span>
              <StyledAddOfferings onClick={e => handleAddBuilding(e)}>
                + Add Buildings
              </StyledAddOfferings>
              </Block>
            }/>
        <div>
        <BuildingsList
        formik ={formik}
        offeringsToBuildinsMap = {values.offeringsToBuildinsMap}
        newOfferingsToBuildinsMap ={values.newOfferingsToBuildinsMap}
    buildings ={offeringDDLData}
    config = {contractsConfig}
    />
    {errors.buildingIds && <p className="error"><>{errors.buildingIds}</></p>}
          </div>
          </>
          : <><label>
            {/* {`Utility Company: `} */}
            </label>
            {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
        }
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <h3>
            <TranslateComponent>
              Contract Information
            </TranslateComponent>
          </h3>
        </Content>
        
        <Content
          xlColumn={6}
          lColumn={6}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <><Label text={
            <>
            <span>
              Building Invoice Count
            </span>
            <Tooltip
              horizontalPosition="left"
              content="This is the number of buildings that the customer will be invoiced for. Building count will default to the number of buildings selected above."
              SpanOrDiv="span"
              width="200px"
              size="12px"
            >
            <span className="icon icon-infocircle" />
            </Tooltip>
            </>
          } />
            <div>
              <StyledInput type="number" value={values.buildingCount} onChange={e => {
                const value = valueWithinMinMax(e.target.value)
                formik.setFieldValue("buildingCount", value)
              }} />
            </div>
          </>
        </Content>
        <Content
          xlColumn={6}
          lColumn={6}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <><Label text={
            <span>
             Device Count
            </span>
          } />
            <div>
              <StyledInput type="number" value={values.numberOfDevices} onChange={e => {
                const value = valueWithinMinMax(e.target.value)
                formik.setFieldValue("numberOfDevices", value)
              }} />
            </div>
          </>
        </Content>
        
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
              <span>
                Business Stream
              </span>
            }/>
        <div>
        {values.businessStream}
          {/* {<p className="error"><>{errors.utilityId && "Utility Company is required."}</></p> } */}
          </div>
          </>
          : <><label>
            {/* {`Utility Company: `} */}
            </label>
            {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
        }
        </Content>


        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
              <span>
                Contract Start Date*
              </span>
            }/>
        <div>
        {values.startDate}
          </div>
          </>
          : <><label>
            {/* {`Utility Company: `} */}
            </label>
            {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
        }
        </Content>
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      <><Label text={
              <span>
                Expiration Date*
              </span>
            }/>
        <div>
          <ModalDatePickerInput 
            date={values.expirationDate}
            onChange={e => {
              formik.setValues(
                {
                  ...values,
                  "expirationDate": moment(e).format("MM/DD/YYYY"),
                  "length" : "custom"
                }
              )
            }}
            // isRequired={false}
            // labelText={`${t('opportunities:CreatedDate')}*`}
            hasError={Boolean(errors.expirationDate)}
            errorMessage={errors.expirationDate}
          />
          </div>
          </>
        </Content>
        {/independent/i.test(values.businessStream) ? <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <><Label text={
              <span className={errors.serviceContractNumber && "error"}>
                Purchase Order Number*
              </span>
            }/>
            <div>
              <input value={values.serviceContractNumber || ""} maxLength={50}  onChange={e => {
                formik.setFieldValue("serviceContractNumber", e.target.value)
              }} />
            </div>
            </>
            {errors.serviceContractNumber && <p className="error"><>{errors.serviceContractNumber}</></p>}
      </Content>:
      !(values.offerings?.length === 1 && hasCMSA(values.offerings)) ? 
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      <><Label text={
        <span className={errors.serviceContractNumber && "error"}>
          Service Contract Number*
        </span>
      }/>
      <div>
        <input maxLength={50}  value={values.serviceContractNumber || ""} onChange={e => {
          formik.setFieldValue("serviceContractNumber", e.target.value)
        }} />
      </div>
      {errors.serviceContractNumber && <p className="error"><>{errors.serviceContractNumber}</></p>}
      </></Content>
      : null}
      {(/independent/i.test(values.businessStream) || !(values.offerings?.length === 1 && hasCMSA(values.offerings))) &&
        <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
       Please enter the appropriate Service Contract Number or Project ID (CAP Pro only). The form will not be approved until validated. For Independent offices, Purchase Order document is required below. 
      </Content>}
      {values.offerings.includes("CAPxM&V") &&
      <Content
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      border="none"
    >
      <><Label text={
        <span className={errors.pactContractNumber && "error"}>
          PACT Contract Number*
        </span>
      }/>
      <div>
        <input value={values.pactContractNumber || ""} onChange={e => {
          formik.setFieldValue("pactContractNumber", e.target.value)
        }} />
      </div>
      {errors.pactContractNumber && <p className="error"><>{errors.pactContractNumber}</></p>}
      </></Content> }
       {(values.offerings?.length === 1 && hasCMSA(values.offerings)) &&
       <Content
       xlColumn={12}
       lColumn={12}
       mColumn={12}
       sColumn={12}
       border="none"
     >
       <><Label text={
        <span className={errors.mechanicalServiceAgreementNumber && "error"}>
          Mechanical Service Agreement Number*
         </span>
       }/>
       <MinInputField>
         <input value={values.mechanicalServiceAgreementNumber || ""}
         className="input-field"
         onChange={e => {
           formik.setFieldValue("mechanicalServiceAgreementNumber", e.target.value)
         }} />
       </MinInputField>
       {errors.mechanicalServiceAgreementNumber && <p className="error"><>{errors.mechanicalServiceAgreementNumber}</></p>}
       </> </Content>}
         {
          (values.offerings.includes("BR") || values.offerings.includes("CS")) && 
          <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      <FormSubHeader>Offering Details</FormSubHeader>
      </Content>
         }

{values.offerings.includes("BR") &&
      <Content
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      border="none"
    >
      <><Label text={
        <span className={errors.totalBaselineReportCount && "error"}>
          Total Baseline Report Count
        </span>
      }/>
      <div>
        <input value={values.totalBaselineReportCount || ""} onChange={e => {
          const value = valueWithinMinMax(e.target.value)
          formik.setFieldValue("totalBaselineReportCount", value)
        }} />
      </div>
      {errors.totalBaselineReportCount && <p className="error"><>{errors.totalBaselineReportCount}</></p>}
      </></Content> }


      {values.offerings.includes("CS") &&
      <Content
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      border="none"
    >
      <><Label text={
        <span className={errors.coachingServicesValue && "error"}>
          Coaching Service Value
        </span>
      }/>
      <div>
        <input value={values.coachingServicesValue || ""} maxLength={10} onChange={e => {
          formik.setFieldValue("coachingServicesValue", e.target.value)
        }} />
      </div>
      {errors.coachingServicesValue && <p className="error"><>{errors.coachingServicesValue}</></p>}
      </></Content> }

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <AttachFiles 
          setFieldValue ={formik.setFieldValue}
          values={formik.values}
          organizationId={values.accountId}
          onDeleteAttachment={(id, name) => showConfirm(id, name)}
          proposalFileTypes={ACCEPTED_FILE_TYPES}
          />
      </Content>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
              <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        {
          [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><TextAreaInput
          maxLength={2000} 
            labelText={"Supporting Notes"}
            defaultValue={values?.supportingNotes}
            name="Supporting Notes"
            onChange={({
              target: { value }
            }) => {
              formik.setFieldValue("supportingNotes", value)
            }}
            isRequired={false}
          />2000 characters max</> : values?.supportingNotes
        }
      </Content>
      
        {<p className="error"><>{errors.seasons && "Selected seasons must add up to one complete year, or, \"No Seasonal Variation\" must be selected."}</></p> }
        <br />
      </Content>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <><Label text={
              <span className={errors.accountManager && "error"}>
                Account Manager
              </span>
            }/>
            <div>
             {values.accountManager || ""}
            </div>
            </>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <><Label text={
          <>
              <span>
                Billing Sales Office Code
              </span>
              <Tooltip
                      horizontalPosition="left"
                      content="If the contract sales office differs from the requester's default office, enter an Alternate Sales Office Code."
                      SpanOrDiv="span"
                      width="200px"
                      size="12px"
                    >
                      <span className="icon icon-infocircle" />
                    </Tooltip>
              </>
            }/>
            <div>
             {values.billingSalesOfficeCode}
            </div>
            </>
      </Content>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <><Label text={
          <>
              <span>
                Additional Notification Recipients
              </span>
              <Tooltip
              horizontalPosition="left"
              content="Any recipients thay you would like to receive notifications about this contract request. Emails must be from the Trane internal team."
              SpanOrDiv="span"
              width="200px"
              size="12px"
              >
              <span className="icon icon-infocircle" />
              </Tooltip>
              </>
            }/>
            <div>
              {values.additionalRecipients}
            </div>
            </>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
              <span>
                Primary Building For Contract
              </span>
            }/>
        <div>
          <SelectrixWrapper
						// appendTagPrompt="Create Utility Company “{searchtext}”"
						className={`location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select`}
						placeholder={"-Select One-"}
						placeHolderInside={true}
            defaultValue={values.primaryBuilding}
						inputPlaceholder="Find a building..."
						multiple={false}
						onChange={e => {
              formik.setFieldValue("primaryBuilding", e.key)
            }
            }
						options={offeringDDLData.filter(({ key }) => values.buildingIds.includes(key))}
						searchable={true}
						searchBoxInside={true}
						searchIndex={false}
						stayOpen={false}
						// tags={true}
						// validationWithRegisterCase={true}
					/>
         {errors.primaryBuilding && <p className="error"><>{errors.primaryBuilding}</></p>}
          </div>
          </>
          : <><label>
            {/* {`Utility Company: `} */}
            </label>
            {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
        }
      </Content>
      <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} border={"none"}>
          <StyledButtonsContent>
            <Button type="secondary" onClick={() => {
              navigate(pages["Contracts & Offerings"].href)
            }}>
              Cancel
            </Button>
            <Button  type="primary" onClick={ async () => {
              // formik.handleSubmit()
              const _errors = await formik.validateForm({...values})
      if (Object.keys(_errors)?.length > 0) {
        return
      }
              setDialogTypes({
                confirm: {
                  title: "Confirm Offering Edits",
                  description: "Are you sure you want to save the following changes?",
                  handleConfirm: () => formik.handleSubmit(),
                  handleCancel: () => setDialogTypes(null)
                }
              })
            }
          }
            >
              Save
            </Button>
          </StyledButtonsContent>
        </Content>
    </Container>
    {showAddOfferingModal && <AddOfferingsModal values = {values} 
    onConfirm = {() => setShowAddOfferingSuccess(true)}
    contractsConfig={contractsConfig}
    offeringDDLData ={offeringDDLData}
    formik ={formik}
    handleCancel = {handleCloseAddOfferingModal}
    />}
      {showAddBuildingModal && <AddBuildingModal values = {values} 
      setOfferingDDLData = {setOfferingDDLData}
       onConfirm = {() => setShowAddingSuccess(true)}
    offeringDDLData ={offeringDDLData}
    formik ={formik}
    handleCancel = {handleCloseAddBuildingModal}
    selectedBuildings={selectedBuildings}
    setSelectedBuildings={setSelectedBuildings}
    config = {contractsConfig}
    />}
    {showAddOfferingSuccess && showAddOfferingSuccessModal()}
    {showAddingSuccess && showAddBuildingSuccessModel()}
    <StandardDialogs error={dialogTypes?.error} onCloseDialog = {onCloseDialog} confirm={dialogTypes?.confirm || confirm} notification={dialogTypes?.notification} isLoading={combinedLoading} />
    </EditActiveStyledContainer>
  )
}
