import styled from 'styled-components'

export const HistorySharedStyles = styled.div`
  .row-gap {
    row-gap: 16px;
  }

  .gap-start-end {
    padding-left: 16px;
    padding-right: 16px;
  }

  .gap-top-bottom {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .no-gap {
    gap: 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContentWrapper = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 1px solid lightgray;
  row-gap: 16px;
  display: flex;
  flex-direction: column;
`

export const TitleSection = styled.div`
  display: flex;
  column-gap: 20px;
  .title-block {
    font-weight: 700;
    h4 {
      margin: 0 0 5px -0;
    }
  }
`

export const Line = styled.div`
  height: 1px;
  border-top: 1px solid lightgray;
`

export const FilterSection = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  font-weight: 700;

  .filter-item {
    display: flex;
    column-gap: 10px;
    align-items: center;

    /* .modal-datepicker-wrapper .date-container {
      width: 60%;
    } */
  }

  .modal-datepicker-wrapper {
    margin: 0;
    .date-container {
      width: 60%;
    }
  }

  .search-filter {
    .toolbar-search {
      margin: 0 0 0 -8px;
    }
    .search-box {
      max-width: none;
      border-left: none;
      /* border-left-color: transparent; */
      width: 208px;
    }
  }

  .back-to-all-tests {
    font-weight: 900;
    letter-spacing: 0.36px;
    line-height: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
`
export const TableHeaderWrapper = styled.div`
margin-bottom: 20px;
display: flex;
`
export const WrapperText = styled.div`
width: 300px;
flex-direction: column;
    display: flex;
    padding: 5px;
`
export const LabelWrapper = styled.div`
font-size: 13px;
    font-weight: 700;
    letter-spacing: .26px;
    line-height: 15px;
`
export const ValueWrapper = styled.div`
font-size: 12px;
    font-weight: 400;
    line-height: 18px;
`
export const TableHeader = styled.div`
    margin-top: 20px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .26px;
    line-height: 15px;
    padding: 5px;
`