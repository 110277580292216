import { useTranslation } from 'react-i18next'
import { useFormikContext } from '../../MultiTabTorm'
import ColorInput from 'src/components/color-input/ColorInput'
import { BenchMarkCompareStyledWrapper, PanelLabel, TabWrapper } from '../../../styles'
import { TARGET_MARKERS_NONE } from 'src/common/target-markers'
import { TranslateComponent } from 'src/common/translations'


const EnergyConsumptionColors = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, setFieldValue } = formik
  const handleSelectColorPicker = (fieldName: string, color: any) => {
    setFieldValue(fieldName, color)
  }

  return (
    <TabWrapper>
        <BenchMarkCompareStyledWrapper>
          <div className='item'>
            <PanelLabel><TranslateComponent>Left Bar (Past Period)</TranslateComponent></PanelLabel>
            <ColorInput
            label={`Bar Color`}
            isRequired={true}
            backgroundColor={values?.leftBarColor}
            update={(color)=> handleSelectColorPicker('leftBarColor', color)}
            />
            <ColorInput
            label={`To-Date Bar Color`}
            isRequired={true}
            backgroundColor={values?.toDateBarColor}
            update={(color)=> handleSelectColorPicker('toDateBarColor', color)}
            />
          </div>          
          <br/>
          <div className='vertical'></div>
          <div className='item'>
            <PanelLabel><TranslateComponent>Right Bar (Current Period)</TranslateComponent></PanelLabel>
            <ColorInput
            label={`Bar Color`}
            isRequired={true}
            backgroundColor={values?.rightBarColor}
            update={(color)=> handleSelectColorPicker('rightBarColor', color)}
            />
            {
              values?.benchMarker !== TARGET_MARKERS_NONE && <ColorInput
              label={"Benchmark/Target Color"}
              isRequired={true}
              backgroundColor={values?.benchmarkColor}
              update={(color)=> handleSelectColorPicker('benchmarkColor', color)}
              />
            }
          </div>
        </BenchMarkCompareStyledWrapper>
    </TabWrapper>
  )
}

export default EnergyConsumptionColors
