import { StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

export const headerStyles = StyleSheet.create({
	headerWrapper: {
		fontSize: 10,
		borderBottom: `4 solid ${colors.TBA_DARK_GREY}`,
		paddingBottom: 5,
		marginBottom: 10
	},
	titleWrapper: {
		fontSize: 10,
	},
	logo: {
		width: 120,
		height: 40,
	},
	header: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-end",
		lineHeight: 1.3,
	},
	locationNameText: {
		fontSize: 10,
		fontWeight: "bold",
		color: colors.TBA_DARK_GREY,
	},
	intelligentServices: {
		fontSize: 10,
		fontWeight: "bold",
		color: colors.TBA_DARK_GREY,
	},
	info: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		maxWidth: "75%"
	},
	titlesWrapper: {
		maxWidth: "75%",
		display: "flex",
		flexDirection: "row",
	},
	dateWrapper: {
		maxWidth: "30%",
		marginLeft: "auto",
		marginTop: "auto",
	},
	date: {
		color: colors.TBA_DARKER_GREY,
		fontSize: 10,
		fontWeight: "normal",
	},
	titles: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 10,
		marginBottom: 10,
		fontSize: 14,
		fontWeight: "bold",
	},
	pTitle: {
		paddingRight: 6,
		color: colors.ORANGE_5,
	},
	iTitle: {
		color: colors.BLACK,
	},
	name: {
		color: colors.ORANGE_5,
	}
})
