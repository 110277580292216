import * as Yup from 'yup'
import {
    SITE_OBJECT,
    EQUIPMENT_TYPES,
    NONE,
    AUTOMATION_ID_LENGTH
} from '../../equipmentSetup/constants';
import { isNum, isDefined } from 'src/components/legacy/common/helpers'
import { isFloat } from "src/components/legacy/common/validators"
import _isEmpty from 'lodash/isEmpty'
export const EQUIPMENT_NAME_MAX_LENGTH = 75
export const getValidationSchema = ({
    t,
    isNaxConnectivity,
    equipmentsList,
    equipmentTypeList,
    dataSourcePropertyList
}) =>
Yup.object().shape({
    sourceTypeId: Yup.string(),
    equipmentTypeName: Yup.string().required(
      t('equipment-setup:RequiredField')
    ),
    defaultAutomationSystemNames: Yup.array().when('equipmentTypeName', {
      is: (equipmentTypeName) =>
        equipmentTypeName !== EQUIPMENT_TYPES.VIRTUAL_METER,
      then: () => Yup.array()
      .of(Yup.string())
      .when(['sourceTypeId'], {
        is: (sourceTypeId) => sourceTypeId === SITE_OBJECT,
        then: () =>
          Yup.array()
            .of(Yup.string())
            .test(
              'testuniqAutomationSystemNames',
              t('equipment-setup:InValidSelection'),
              (value) =>
                testuniqAutomationSystemNames(value, { isNaxConnectivity })
            )
            .test(
              'testdefaultAutomationSystemNames',
              t('equipment-setup:RequiredField'),
              (value) => testdefaultAutomationSystemNames(value)
            ),
        otherwise: () =>
          Yup.array()
            .of(Yup.string())
            .test(
              'testdefaultAutomationSystemNames',
              t('equipment-setup:RequiredField'),
              (value) => testdefaultAutomationSystemNames(value)
            )
      })
      }),
    ...(!isNaxConnectivity && {
      defaultDataSourceTypeName: Yup.string().when('equipmentTypeName', {
        is: (equipmentTypeName) =>
          equipmentTypeName !== EQUIPMENT_TYPES.VIRTUAL_METER,
        then: () => Yup.string().required(t('equipment-setup:RequiredField'))
      })
    }),
    ...(!isNaxConnectivity && {
      defaultDataSourceEquipmentNames:  Yup.array().when('equipmentTypeName', {
        is: (equipmentTypeName) =>
          equipmentTypeName !== EQUIPMENT_TYPES.VIRTUAL_METER,
        then: () => Yup.array()
        .of(Yup.string())
        .test(
          'testDefaultDataSourceEquipmentName',
          t('equipment-setup:RequiredField'),
          testDefaultDataSourceEquipmentName
        )
      })
    }),
    parentEquipmentTypeName: Yup.string().test(
      'testParentEquipmentTypeName',
      t('equipment-setup:RequiredField'),
      testParentEquipmentTypeName
    ),
    parentEquipmentName: Yup.string().test(
      'testParentEquipmentName',
      t('equipment-setup:RequiredField'),
      testParentEquipmentName
    ),
    copyCharacteristicsFromEquipmentName: Yup.string().test(
      'testCopyCharacteristicsFromEquipmentName',
      t('equipment-setup:RequiredField'),
      testCopyCharacteristicsFromEquipmentName
    ),
    appendPrependText: Yup.string().max(
      EQUIPMENT_NAME_MAX_LENGTH,
      t('errors:maxAllowedFieldLength', {
        field: t('equipment-setup:CreateEquipment>AppendToTisEquipmentName'),
        number: EQUIPMENT_NAME_MAX_LENGTH
      })
    ),
    equipmentName: Yup.string()
      .test(
        'testEquipmentName',
        t('equipment-setup:RequiredField'),
        testEquipmentName.bind(null, { isNaxConnectivity })
      )
      .test(
        'testUniqEquipmentName',
        t('equipment-setup:NameAlreadyExists'),
        (value) => testUniqEquipmentName(value, { equipmentsList })
      )
      .max(
        EQUIPMENT_NAME_MAX_LENGTH,
        t('errors:maxAllowedFieldLength', {
          field: t('equipment-setup:EquipmentName'),
          number: EQUIPMENT_NAME_MAX_LENGTH
        })
        ),
        condenserType: Yup.string().test("testCondenserType", t("equipment-setup:RequiredField"), testChillerSpecificValue.bind(null, { equipmentTypeList, validate: testRequiredChillerValue })),
        compressorType: Yup.string().test("testCompressorType", t("equipment-setup:RequiredField"), testChillerSpecificValue.bind(null, { equipmentTypeList, validate: testRequiredChillerValue })),
        // chillerType: Yup.string().test("testChillerType", t("equipment-setup:RequiredField"), testChillerSpecificValue.bind(null, { tisEquipmentTypes, validate: testRequiredChillerValue })),
        compressor1ANominalCapacity: getCompressorNominalCapacityValidator(t).test("testCompressor1ANominalCapacity", t("equipment-setup:RequiredField"), testChillerSpecificValue.bind(null, { equipmentTypeList, validate: testCompressor1ANominalCapacity })),
        compressor1BNominalCapacity: getCompressorNominalCapacityValidator(t).test("testCompressor1BNominalCapacity", t("equipment-setup:RequiredField"), testChillerSpecificValue.bind(null, { equipmentTypeList, validate: testCompressor1BNominalCapacity })),
        compressor1CNominalCapacity: getCompressorNominalCapacityValidator(t),
        compressor2ANominalCapacity: getCompressorNominalCapacityValidator(t).test("testCompressor2ANominalCapacity", t("equipment-setup:RequiredField"), testChillerSpecificValue.bind(null, { equipmentTypeList, validate: testCompressor2ANominalCapacity })),
        compressor2BNominalCapacity: getCompressorNominalCapacityValidator(t).test("testCompressor2BNominalCapacity", t("equipment-setup:RequiredField"), testChillerSpecificValue.bind(null, { equipmentTypeList, validate: testCompressor2BNominalCapacity })),
        compressor2CNominalCapacity: getCompressorNominalCapacityValidator(t),
        defaultDataSourcePropertyId: Yup.string().when(["equipmentTypeName", "defaultDataSourceTypeName", "defaultDataSourceEquipmentNames"], {
          is: (equipmentTypeName, defaultDataSourceTypeName, defaultDataSourceEquipmentNames) =>
          equipmentTypeName === EQUIPMENT_TYPES.ANALOG_POINT && !isNaxConnectivity
            && isDefined(defaultDataSourceTypeName)
            && isDefined(defaultDataSourceEquipmentNames),
          then: () => Yup.string().required(t("equipment-setup:RequiredField")),
        }),
        arrayIndex: Yup.string().when(["equipmentTypeName", "defaultDataSourceTypeName", "defaultDataSourceEquipmentNames", "defaultDataSourcePropertyId"], {
          is: (equipmentTypeName, defaultDataSourceTypeName, defaultDataSourceEquipmentNames, defaultDataSourcePropertyId) =>
            equipmentTypeName === EQUIPMENT_TYPES.ANALOG_POINT
            && [defaultDataSourceTypeName, defaultDataSourceEquipmentNames, defaultDataSourcePropertyId].every(isDefined)
            && dataSourcePropertyList?.find(({ sourceDisplayName }) => sourceDisplayName === defaultDataSourcePropertyId)?.subPropertyDisplayName !== null,
          then: () => Yup.string().required(t("equipment-setup:RequiredField")),
        }),
        dimension: Yup.string().when(["defaultDataSourcePropertyId", "equipmentTypeName"], {
          is: (defaultDataSourcePropertyId, equipmentTypeName) => equipmentTypeName !== EQUIPMENT_TYPES.LOAD_VALVE && isDefined(defaultDataSourcePropertyId),
          then: () => Yup.string().required(t("equipment-setup:RequiredField")),
        }),
        sourceUOM: Yup.string().when(["dimension"], {
          is: dimension => isDefined(dimension),
          then: () => Yup.string().required(t("equipment-setup:RequiredField")),
        })
})

function testuniqAutomationSystemNames(value, { isNaxConnectivity }) {
  if (isNaxConnectivity) {
    return value.length === new Set(value).size
  }
  return true
}
function testDefaultDataSourceEquipmentName(value) {
  let { defaultDataSourceTypeName } = this?.parent
  let cond =
    !_isEmpty(this?.parent?.defaultDataSourceTypeName) && value?.length > 0
  return defaultDataSourceTypeName === NONE ? true : cond
}
function testdefaultAutomationSystemNames(value) {
  return value.length > 0
}
function testParentEquipmentTypeName(value) {
  const { equipmentTypeName, overrideParentEquipment } = this?.parent

  if (!overrideParentEquipment) {
    return true
  }

  return equipmentTypeName ? Boolean(value) : true
}
function testParentEquipmentName(value) {
  const { parentEquipmentTypeName, overrideParentEquipment } = this?.parent

  if (!overrideParentEquipment) {
    return true
  }

  return parentEquipmentTypeName ? Boolean(value) : true
}
function testCopyCharacteristicsFromEquipmentName(value) {
  const {
    parent: { equipmentTypeName, copyCharastersticsList,copyCharacteristics }
  } = this

  if(!copyCharacteristics){
    return true
  }
  if (copyCharastersticsList && !Object.values(copyCharastersticsList)?.length > 0) {
    return true
  }


  return equipmentTypeName ? Boolean(value) : true
}
function testUniqEquipmentName(value, { equipmentsList }) {
  const isDuplicate = equipmentsList?.filter(equipment => equipment?.creationType === 'User').some(
    ({ name: objectName }) => objectName?.toLowerCase() === value?.toLowerCase()
  )
  return !isDuplicate
}
function testEquipmentName({ isNaxConnectivity }, value, context) {
  const {
    parent: {
      defaultDataSourceTypeName,
      defaultDataSourceEquipmentNames,
      overrideEquipmentName,
      defaultAutomationSystemNames,
      sourceTypeId
    }
  } = context

  if (!value) {
    return false
  }

  if (isNaxConnectivity && sourceTypeId == SITE_OBJECT) {
    return defaultAutomationSystemNames.length > AUTOMATION_ID_LENGTH
      ? true
      : Boolean(value)
  }

  if (defaultDataSourceTypeName !== 'None' && !overrideEquipmentName) {
    return true
  }

  return defaultDataSourceEquipmentNames ? Boolean(value) : true
}

function testChillerSpecificValue({ tisEquipmentTypes, validate }, value, context) {
	if (!checkIfEquipmentIsChillerType({ equipmentTypeId: context.parent.equipmentTypeName, tisEquipmentTypes })) {
		return true
	}

	return validate(value, context)
}

function checkIfEquipmentIsChillerType({ equipmentTypeId }) {
	return equipmentTypeId && (EQUIPMENT_TYPES.CHILLER === equipmentTypeId)
}

function testRequiredChillerValue(value) {
	return Boolean(value)
}

function getCompressorNominalCapacityValidator(t) {
	const MIN = 0
	const MAX = 5000

	return Yup.number()
		.min(MIN, t("equipment-setup:AllowedValues", { min: MIN, max: MAX }))
		.max(MAX, t("equipment-setup:AllowedValues", { min: MIN, max: MAX }))
		.test("testNoMoreThanTwoDigitsRightOfDecimal", t("equipment-setup:NoMoreThanTwoDigitsRightOfDecimalAllowed"), value => {
			if (isFloat(value)) {
				return value.toString().split(".")[1].length <= 2
			}

			return true
		})
}

function testCompressor1ANominalCapacity(_, context) {
	const { parent: { compressor1ANominalCapacity } } = context

	return isNum(compressor1ANominalCapacity)
}

function testCompressor1BNominalCapacity(_, context) {
	const { parent: { compressor1BNominalCapacity, compressor1CNominalCapacity } } = context

	if (compressor1CNominalCapacity >= 0 && !isNum(compressor1BNominalCapacity)) {
		return false
	} else {
		return true
	}
}

function testCompressor2ANominalCapacity(_, context) {
	const { parent: { compressor2ANominalCapacity, compressor2BNominalCapacity, compressor2CNominalCapacity } } = context

	if ((compressor2BNominalCapacity >= 0 || compressor2CNominalCapacity >= 0) && !isNum(compressor2ANominalCapacity)) {
		return false
	} else {
		return true
	}
}

function testCompressor2BNominalCapacity(_, context) {
	const { parent: { compressor2BNominalCapacity, compressor2CNominalCapacity } } = context

	if (compressor2CNominalCapacity >= 0 && !isNum(compressor2BNominalCapacity)) {
		return false
	} else {
		return true
	}
}

