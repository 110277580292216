import { useEffect, useState } from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  flip,
  useInteractions,
  useDismiss,
  useRole,
  useHover,
  useFocus,
  safePolygon
} from '@floating-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faClapperboardPlay,
  faGearComplex,
  faSparkles
} from 'src/denali-components/lib/pro-solid-svg-icons'
import styles from './sidebar.module.scss'
import { Button, useTheme } from '@aws-amplify/ui-react'
import { StyledHeader } from 'src/components/Header/style'
import Profile from 'src/components/Header/components/profile'
import { zendeskHelper } from 'src/common/zendesk-helper'
import { createPortal } from 'react-dom'
import {
  faBell,
  faCircleUser,
  faHand,
  faScroll,
  faShare,
  faShield
} from '@fortawesome/free-solid-svg-icons'
import { MenuItem } from './MainNavigation'
import { NotificationsDisplay } from './NotificationsDisplay'
import { NavigationItem } from './NavigationItem'
import { UserNameBubble } from '../UserNameBubble/UserNameBubble'
import { useSelector } from 'react-redux'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { useTranslation } from 'react-i18next'
import { GET_TOTAL_UNREAD_NOTIFICATIONS_COUNT } from 'src/components/top-menu/graphql'
import { useQuery } from 'src/hooks/APIHooks'

export const UserMenu = ({
  menuIsOpen,
  signOut
}: {
  menuIsOpen: boolean
  signOut: void
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { refs, floatingStyles, context } = setupFloating(isOpen, setIsOpen)
  const { getReferenceProps, getFloatingProps } = setupInteractions(context)
  const [showProfileDialog, setShowProfileDialog] = useState(false)
  const { tokens } = useTheme()
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const user = useSelector(selectUserInfo)
  const jobTitle = getJobTitle(user)
  const {
    data: notificationCount,
    refetch: refetchTotalUnreadNotificationsCount
  } = useQuery({
    query: GET_TOTAL_UNREAD_NOTIFICATIONS_COUNT,
    variables: { userId: user.id },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchNotificationActives.total'
  })
  useEffect(() => {
    if (!user.id) return

    // Prefill the user details on the Zendesk Request/feeback support Bot
    zendeskHelper.identify(
      user.firstName ?? '',
      user.lastName ?? '',
      user.email ?? ''
    )
    refetchTotalUnreadNotificationsCount({ userId: user.id })
  }, [user])

  return (
    <div
      className={styles.userMenu}
      {...getReferenceProps()}
      ref={refs.setReference}
      data-testid="userMenu"
    >
      <div className={styles.userMenuButtons}>
        <Button
          className={styles.userButton}
          onClick={openProfile(setIsOpen, setShowProfileDialog)}
          data-testid="userMenuProfileSummary"
        >
          <UserNameBubble
            firstName={user.firstName}
            lastName={user.lastName}
            subLine={jobTitle}
            showFullName={menuIsOpen}
            onDark={true}
            isSmall={true}
          />
        </Button>
        <Button
          className={styles.notificationButton}
          onClick={openNotifications(setIsOpen, setIsNotificationsOpen)}
          data-testid="userMenuNotificationsSummary"
        >
          <FontAwesomeIcon icon={faBell} />
        </Button>
        <Button
          className={styles.helpButton}
          onClick={openHelp(setIsOpen)}
          data-testid="userMenuHelpSummary"
        >
          <FontAwesomeIcon icon={faHand} />
        </Button>
      </div>
      {isOpen && (
        <div
          className={styles.popupWrapper}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          data-testid="userMenuPopup"
        >
          <div className={styles.popup}>
            <ul>
              {getPagesList(
                setIsOpen,
                setShowProfileDialog,
                setIsNotificationsOpen,
                notificationCount,
                signOut
              ).map((item, i) => (
                <NavigationItem key={i} item={item} i={i} />
              ))}
            </ul>
            <div className={styles.copyright}>
              ©{new Date().getFullYear()} Trane
            </div>
          </div>
        </div>
      )}
      <NotificationsDisplay
        isOpen={isNotificationsOpen}
        setIsOpen={setIsNotificationsOpen}
      />
      {showProfileDialog &&
        createPortal(
          <StyledHeader
            bg1={tokens.colors.TBA_GREEN}
            bg2={tokens.colors.GREEN_4}
          >
            <Profile
              closeModal={() => {
                setShowProfileDialog(false)
              }}
            />
          </StyledHeader>,
          document.getElementById('app')
        )}
    </div>
  )
}

function getJobTitle(user) {
  const [t] = useTranslation()
  const titleOther = t(`user:jobTitles>${user.jobTitle}`)
  const titleTrane = t(`user:jobTitlesTraneEmployee>${user.jobTitle}`)
  return user.type.name === 'Trane Employee'
    ? titleTrane !== ''
      ? titleTrane
      : titleOther // For Trane Employees, in case type/title don't match up, try the other combination
    : titleOther !== ''
    ? titleOther
    : titleTrane // For Other Employees, in case type/title don't match up, try the other combination
}

function openProfile(setIsOpen, setShowProfileDialog) {
  return (event) => {
    event.preventDefault()
    setIsOpen(false)
    setShowProfileDialog(true)
  }
}

function openNotifications(setIsOpen, setIsNotificationsOpen) {
  return (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsOpen(false)
    setIsNotificationsOpen(true)
  }
}

export function openHelp(setIsOpen) {
  return (event) => {
    event.preventDefault()
    setIsOpen(false)
    zendeskHelper.openWidget(true)
  }
}

function getPagesList(
  setIsOpen,
  setShowProfileDialog,
  setIsNotificationsOpen,
  notificationCount,
  signOut
): MenuItem[] {
  return [
    {
      name: 'My Profile',
      icon: <FontAwesomeIcon icon={faCircleUser} />,
      analytics: '',
      links: [],
      onClick: openProfile(setIsOpen, setShowProfileDialog)
    },
    {
      name: notificationCount
        ? 'Notifications' + ` (${notificationCount})`
        : `Notifications`,
      icon: <FontAwesomeIcon icon={faBell} />,
      analytics: '',
      links: [],
      onClick: openNotifications(setIsOpen, setIsNotificationsOpen)
    },
    // {
    //   name: 'Settings',
    //   icon: <FontAwesomeIcon icon={faGearComplex} />,
    //   analytics: '',
    //   links: [],
    //   onClick: () => {}
    // },
    // {
    //   name: 'What’s new',
    //   icon: <FontAwesomeIcon icon={faSparkles} />,
    //   analytics: '',
    //   links: [],
    //   onClick: () => {}
    // },
    {
      name: 'Help & Support',
      icon: <FontAwesomeIcon icon={faHand} />,
      analytics: '',
      onClick: openHelp(setIsOpen)
      // subMenu: [
      //   {
      //     name: 'About Trane Connect',
      //     icon: <FontAwesomeIcon icon={faCircleInfo} />,
      //     analytics: '',
      //     links: [],
      //     onClick: () => {}
      //   },
      //   {
      //     name: 'Help Video Library',
      //     icon: <FontAwesomeIcon icon={faClapperboardPlay} />,
      //     analytics: '',
      //     links: [],
      //     onClick: () => {}
      //   },
      //   {
      //     name: 'Give Feedback',
      //     icon: <FontAwesomeIcon icon={faMessage} />,
      //     analytics: '',
      //     links: [],
      //     onClick: (event) => {
      //       event.preventDefault();
      //       setIsOpen(false);
      //       zendeskHelper.openWidget();
      //     }
      //   },
      //   {
      //     name: 'Request Help',
      //     icon: <FontAwesomeIcon icon={faHand} />,
      //     analytics: '',
      //     links: [],
      //     onClick: (event) => {
      //       event.preventDefault();
      //       setIsOpen(false);
      //       zendeskHelper.openWidget();
      //     }
      //   },
      // ],
    },
    {
      name: 'Privacy Policy',
      icon: <FontAwesomeIcon icon={faShield} />,
      href: 'https://www.tranetechnologies.com/privacy-policy.html',
      target: '_blank'
    },
    {
      name: 'Terms of Use',
      icon: <FontAwesomeIcon icon={faScroll} />,
      href: 'https://tranetechnologies.com/terms-of-use.html',
      target: '_blank',
      bottomDivider: true
    },
    {
      name: 'Log out',
      icon: <FontAwesomeIcon icon={faShare} flip="horizontal" />,
      analytics: '',
      links: [],
      onClick: signOut
    }
  ]
}

function setupFloating(isOpen, setIsOpen) {
  return useFloating({
    placement: 'right-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(0), flip(), shift()]
  })
}

function setupInteractions(context) {
  const hover = useHover(context, {
    handleClose: safePolygon()
  })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)
  return useInteractions([hover, focus, dismiss, role])
}
