import React, { useState, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Spinner from "src/components/legacy/components/spinner/spinner"
import _get from 'lodash/get'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'src/hooks/APIHooks'
import { useParams } from 'react-router-dom'
import domToImage from "dom-to-image"
import { useBreakPoint } from 'src/hooks/breakPoint'
import { BarChartBlock, BarChartColumn, BarChartContainer, BoldText, DivSpacer, MainBlock, PlotHolder, ReportAddPage, SectionHighlight, TextEditorWrapperDiv } from '../styles' // need to change to reportwrapper
import TextEditor from 'src/components/text-editor'
import EnergyUsageReportChart from '../../../chart-library/Charts/EnergyUsageReportChart'
import PieChart from '../../../chart-library/Charts/EnergyUsageReportChart/pieChart/pie-chart'
import { formatDollarLabel } from '../../../chart-library/Charts/EnergyUsageReportChart/pieChart/helperFunctions'
import { energyUsageReportType } from '../helper'
import EnergyUsageReportDownload from './EUReportDownload/EnergyUsageReportDownload'
import { MODES } from "../constants"
import { LegendWrapper, LegendContainer, LegendItem } from './EUHightlightsBlock/highlights'
import Highlights from './EUHightlightsBlock'
import Header from 'src/components/legacy/components/header/header'
import Toolbar from 'src/components/legacy/components/toolbar/toolbar'
import { useDocumentsAndReportsContext } from '../DocumentsAndReportsContextProvider'
import { ACTIONS } from 'src/constants'
import { GET_ENERGY_USAGE_REPORT, GET_BUILDING_DETAILS } from '../graphql'
import { MODAL_TYPE } from '../../opportunities/constants'
import translate from 'src/common/translations'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import moment from "moment"
import { AccessControl, accessControlFunc } from "src/components/accessControl"
import ErrorAlert from "src/components/ErrorAlert"
import { selectUserAccess } from "src/redux/slicers/appData"
import { useSelector } from "react-redux"

const ViewEnergyUsageReport = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [energyUsageReportDetails, setenergyUsageReportDetails] = useState<any>({})
    const [energyReport, setEnergyReport] = useState<any>({})
    const [barChartDetails, setBarChartDetails] = useState<any>({})
    const { setDocReportRow, setMode, setModalType, error, setError } = useDocumentsAndReportsContext()
    const [t] = useTranslation()
    const { width } = useBreakPoint()
    const userAccess = useSelector(selectUserAccess)
    const optionsTranslated = {
        createNew: translate("Create New"),
        copyEUR: translate('Copy Energy Usage Report'),
        editEUR: translate('Edit Energy Usage Report'),
        deleteEUR: translate('Delete Energy Usage Report'),
        createdBy: translate('Created By'),
        createdDate: translate('Created Date'),
        download: translate('Download'),
        visible: translate('Visible'),
        notVisible: translate('Not Visible To Customer'),
        downloadFileName: translate('Energy Usage Report')
    }

    const { loading, refetch: refetchEnergyUsageReportDetail } = useQuery({
        query: GET_ENERGY_USAGE_REPORT,
        disableInitialLoad: true,
        dataPath: 'data.getEnergyUsageReport',
        onSuccess: (euReport) => {
            console.log({euReport});
            
            if (euReport) {
                if (
                    !euReport?.visibility &&
                    !accessControlFunc({
                      id: 'tc.pages.documents-and-reports.view-private',
                      userAccess
                    })
                  ) {
                    setError({
                      errorMsg: 'The Energy Usage Report you are looking for is not accessible.',
                      isError: true
                    })
                  }
                const parsedEUReportData = {
                    ...euReport,
                    chartSection: euReport?.chartSection ? JSON.parse(euReport?.chartSection) : {},
                    highlightSection: euReport?.highlightSection ? JSON.parse(euReport?.highlightSection) : {},
                    summarySection: euReport?.summarySection ? JSON.parse(euReport?.summarySection) : {}
                }
                setenergyUsageReportDetails(parsedEUReportData)
                setEnergyReport(parsedEUReportData)
            }else{
                setError({
                    errorMsg: 'The Energy Usage Report you are looking for does not exist.',
                    isError: true
                  })
            }
        },
        onError:()=>{
            setError({
                errorMsg: 'The Energy Usage Report you are looking for does not exist.',
                isError: true
              })
        }
    })

    const { data: buildingDetails, refetch: refetchBuildingDetail } = useQuery({
        query: GET_BUILDING_DETAILS,
        dataPath: 'data.getBuilding',
        disableInitialLoad: true,
    })

    useEffect(() => {
        if (energyUsageReportDetails?.buildingId) {
            refetchBuildingDetail({ id: energyUsageReportDetails?.buildingId })
        }
    }, [energyUsageReportDetails?.buildingId])

    useEffect(() => {
        if (id) {
            setMode(ACTIONS.VIEW)
            refetchEnergyUsageReportDetail({ id: id })
        }
    }, [id])

    useEffect(() => {
        const barChartData = _get(energyUsageReportDetails?.chartSection, 'barChartData', {})
        if (barChartData?.barData) {
            setBarChartDetails(barChartData)
        }
    }, [energyUsageReportDetails?.chartSection?.barChartData])


    const handleToggle = (e, type, index) => {
        const barData = _get(barChartDetails, `barData`, {})
        const cuuBarData = _get(barChartDetails, `barData.${type}`, {})
        const barChartContent = [...cuuBarData]
        if (!e.target.checked) {
            barChartContent[index] = {
                ...cuuBarData[index],
                value: 0
            }
            setBarChartDetails({
                ...barChartDetails,
                barData: {
                    ...barData,
                    [`${type}`]: barChartContent
                }
            })
        } else {
            const usageData = _get(energyUsageReportDetails?.chartSection, 'barChartData', {})
            barChartContent[index] = {
                ...usageData?.barData[`${type}`][index]
            }
            setBarChartDetails({
                ...barChartDetails,
                barData: {
                    ...barData,
                    [`${type}`]: barChartContent
                }
            })
        }
    }

    const handleDelete = () => {
        setDocReportRow({ id: energyUsageReportDetails?.id, title: energyUsageReportDetails?.title, filterType: energyUsageReportType, accountId: buildingDetails?.accountId, buildingId: energyUsageReportDetails?.buildingId })
        setMode(ACTIONS.DELETE)
        setModalType(MODAL_TYPE.CONFIRMATION)
    }

    const getBarchartFormattedData = (bData) => {
        return {
            barData: [...bData]
        }
    }

    const displayPDFDownload = () => {
        return (
            energyReport && <EnergyUsageReportDownload downloadFileName={optionsTranslated.downloadFileName} downloadData={energyReport} handleDownloadClick={convertChartToImage} />
        )
    }

    const newReport = {
        ...energyReport,
        locationName: buildingDetails && buildingDetails?.name,
        organizationName: buildingDetails && buildingDetails?.accountName
    }

    const convertChartToImage = async () => {
        const pieImg = document.getElementById("pieChart")
        const barImg1 = document.getElementById("EUIDiv")
        const barImg2 = document.getElementById("ECIDiv")
        const impact1 = document.getElementById("highlight-0")
        const impact2 = document.getElementById("highlight-1")
        const impact3 = document.getElementById("highlight-2")

        pieImg && await domToImage.toPng(pieImg, { quality: 1.00 })
            .then(dataUrl => {
                newReport.pieImgURL = dataUrl
            })

        barImg1 && await domToImage.toPng(barImg1, { quality: 1.00 })
            .then(dataUrl => {
                newReport.bar1ImgURL = dataUrl
            })

        barImg2 && await domToImage.toPng(barImg2, { quality: 1.00 })
            .then(dataUrl => {
                newReport.bar2ImgURL = dataUrl
            })

        impact1 && await domToImage.toPng(impact1, { quality: 1.00 })
            .then(dataUrl => {
                newReport.impact1ImgURL = dataUrl
            })

        impact2 && await domToImage.toPng(impact2, { quality: 1.00 })
            .then(dataUrl => {
                newReport.impact2ImgURL = dataUrl
            })

        impact3 && await domToImage.toPng(impact3, { quality: 1.00 })
            .then(dataUrl => {
                newReport.impact3ImgURL = dataUrl
            })

        setEnergyReport(newReport)
    }

    const companySection = (
        <div className="company-section no-border flex">
            <div className="details column">
                <p className="desc">Organization and Building Name:&nbsp; <BoldText>{buildingDetails && buildingDetails?.accountName}</BoldText>,&nbsp;<BoldText>{buildingDetails && buildingDetails?.name} </BoldText></p>
                <p className="periods">
                    {t("reports:ReportingPeriod")}
                    &nbsp;
                    <strong>{moment(energyUsageReportDetails?.startDate).format(DATE_FORMAT)} - {moment(energyUsageReportDetails?.endDate).format(DATE_FORMAT)}</strong>
                </p>
                <p className="status">
                    {t("reports:Status")}:
                    &nbsp;
                    <strong>{energyUsageReportDetails?.status}</strong>
                </p>
            </div>
            <div className="contacts column">
                <strong>{energyUsageReportDetails?.contact}</strong>
                <span>{energyUsageReportDetails?.phoneNumber}</span>
                <span className="contact-email">{energyUsageReportDetails?.email}</span>
            </div>
        </div>
    )
    const isShowTextSection = useMemo(() => (energyUsageReportDetails?.summarySection?.summaryTitle || "").length || (energyUsageReportDetails?.summarySection?.summary || "").length, [energyUsageReportDetails])
    const EUBarChartxtickLabelProps = {
        euxtickLabel: {
            prefix: false,
            isShowFormatterSymbol: false,
            label: "kw"
        },
        ecxtickLabel: {
            prefix: true,
            isShowFormatterSymbol: true,
            label: "$"
        }
    }

    const textSection = isShowTextSection
        ? (
            <>
                <div className="text-section column">
                    {energyUsageReportDetails?.chartSection?.pieChartData?.showPieChart && <>
                        <header>
                            <h2>{energyUsageReportDetails?.summarySection?.summaryTitle}</h2>
                        </header>
                        <MainBlock>
                            <TextEditorWrapperDiv>
                                <TextEditor
                                    content={JSON.parse(energyUsageReportDetails?.summarySection?.summary)}
                                    isViewMode={true}
                                />
                            </TextEditorWrapperDiv>
                            <PlotHolder id="piePlotForDisplay">
                                {energyUsageReportDetails?.chartSection?.pieChartData.pieData ? <PieChart
                                    id="piePlotForDisplay"
                                    data={energyUsageReportDetails?.chartSection?.pieChartData.pieData}
                                    valueFormatter={d => formatDollarLabel(d)}
                                />
                                    : ""}
                            </PlotHolder>
                        </MainBlock>
                    </>
                    }
                </div>
                {barChartDetails && barChartDetails?.showBarChart && (barChartDetails?.barData?.["euChart"]?.length || barChartDetails?.barData?.["ecChart"]?.length)
                    ? <DivSpacer>
                        <BarChartColumn>
                            <header>
                                <h2>{energyUsageReportDetails?.chartSection?.barChartData?.euiTitle}</h2>
                            </header>
                            <BarChartBlock id="EUIDivView">
                                <EUChartContent
                                    id="EUIDivView"
                                    barChartDetails={barChartDetails}
                                    handleToggle={handleToggle}
                                    getBarchartFormattedData={getBarchartFormattedData}
                                    chartKey="euChart"
                                    xtickLabel={EUBarChartxtickLabelProps.euxtickLabel}
                                    width={width}
                                    axisLabel={{ xAxis: () => <>kBTU/ft<sup>2</sup>/year</> }}
                                />
                            </BarChartBlock>
                        </BarChartColumn>
                        <BarChartColumn>
                            <header>
                                <h2>{energyUsageReportDetails?.chartSection?.barChartData?.eciTitle}</h2>
                            </header>
                            <BarChartBlock id="ECIDivView">
                                <EUChartContent
                                    id="ECIDivView"
                                    barChartDetails={{...barChartDetails, barData: {...barChartDetails.barData, ecChart : barChartDetails?.barData['ecChart'].map(it => ({...it, chartType: barChartDetails?.eciTitle})) }}}
                                    handleToggle={handleToggle}
                                    getBarchartFormattedData={getBarchartFormattedData}
                                    chartKey="ecChart"
                                    xtickLabel={EUBarChartxtickLabelProps.ecxtickLabel}
                                    width={width}
                                    axisLabel={{ xAxis: () => <>$/ft<sup>2</sup>/year</> }}
                                />
                            </BarChartBlock>
                        </BarChartColumn>
                    </DivSpacer>
                    : ""}
            </>
        )
        : null

    const toolbar = useMemo(() => (
        <div className="toolbar">
            <div className="toolbar-buttons">
                {displayPDFDownload()}
            </div>
        </div>
    ), [newReport])

    if(error?.isError){
        return <ErrorAlert errorMsg={error?.errorMsg} /> 
      }

    return (
        energyUsageReportDetails?.id ? (
            <>
                <ReportAddPage>
                    <div className="report-add-page">
                        <Header
                            hideToolBar={false}
                            pageTitle={energyUsageReportDetails?.title}
                            titleTools={
                                <>    
                                        <span
                                            className={`icon-${
                                                energyUsageReportDetails?.visibility
                                                  ? 'visible green'
                                                  : 'hidden'}
                                                   tooltip-container`}
                                        >
                                            <div className="tooltip center-top">
                                                {energyUsageReportDetails?.visibility
                                                    ? optionsTranslated.visible
                                                    : optionsTranslated.notVisible}
                                            </div>
                                        </span>
                                </>
                            }
                        >
                            <Toolbar>
                                <>
                                    <AccessControl id="tc.pages.documents-and-reports.energy-usage.add">
                                        <button
                                            className="action small"
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/reports/energy-reports/add`,
                                                    search: `?organization=${buildingDetails?.accountId}${buildingDetails?.id ? `&location=${buildingDetails?.id}` : ""}&reportType=EnergyUsageReport`,
                                                })
                                            }
                                        >
                                            {optionsTranslated.createNew}
                                        </button>
                                    </AccessControl>
                                    {displayPDFDownload()}
                                    <AccessControl id="tc.pages.documents-and-reports.energy-usage.copy">
                                        <span
                                            className="icon icon-duplicate tooltip-container"
                                            onClick={() => {
                                                console.log('EUR Copy Functionality Yet To Develop')
                                            }}
                                        >
                                            <div className="tooltip dark left-top">
                                                {optionsTranslated.copyEUR}
                                            </div>
                                        </span>
                                    </AccessControl>
                                    <AccessControl id="tc.pages.documents-and-reports.energy-usage.edit">
                                        <span
                                            className="icon icon-edit tooltip-container"
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/reports/energy-reports/edit/${energyUsageReportDetails?.id}`,
                                                    search: `reportType=EnergyUsageReport`
                                                })
                                            }
                                        >
                                            <div className="tooltip dark left-top">
                                                {optionsTranslated.editEUR}
                                            </div>
                                        </span>
                                    </AccessControl>
                                    <AccessControl id="tc.pages.documents-and-reports.energy-usage.delete">
                                        <span
                                            className="icon icon-trash2 tooltip-container"
                                            onClick={() => handleDelete()}
                                        >
                                            <div className="tooltip dark left-top">
                                                {optionsTranslated.deleteEUR}
                                            </div>
                                        </span>
                                    </AccessControl>
                                </>
                            </Toolbar>
                        </Header>
                        {companySection}
                        {textSection}
                        <div className="text-section column">
                            {energyUsageReportDetails?.highlightSection && energyUsageReportDetails?.highlightSection?.showHighlights &&
                                <>
                                    <header>
                                        <h2>{energyUsageReportDetails?.highlightSection?.highlightTitle}</h2>
                                    </header>
                                    <SectionHighlight>
                                        <Highlights highlights={[{ ...energyUsageReportDetails?.highlightSection.highlights[0] }, { ...energyUsageReportDetails?.highlightSection.highlights[1] }, { ...energyUsageReportDetails?.highlightSection.highlights[2] }] || []} mode={MODES.VIEW} />
                                    </SectionHighlight>
                                </>
                            }
                        </div>
                        {toolbar}
                        {/* BELOW IS JUST FOR PDF CHART GENERATION */}
                        <div style={{ position: "absolute", "opacity": 0, pointerEvents: "none" }}>
                            {energyUsageReportDetails?.chartSection?.pieChartData?.showPieChart &&
                                <div
                                    style={{
                                        width: "1100px",
                                        paddingLeft: "350px",
                                        paddingRight: "350px",
                                        paddingTop: "100px",
                                        paddingBottom: "100px",
                                    }}
                                    id="pieChart"
                                >
                                    {energyUsageReportDetails?.chartSection?.pieChartData.pieData ?
                                        <PieChart
                                            id="pieChart"
                                            labelFontSize="26px"
                                            width={600}
                                            isLabelWeight={true}
                                            data={energyUsageReportDetails?.chartSection?.pieChartData.pieData}
                                            valueFormatter={d => formatDollarLabel(d)}
                                        /> : ""}

                                </div>
                            }
                            {barChartDetails?.showBarChart &&
                                <>
                                    <BarChartContainer
                                        id="EUIDiv"
                                    >
                                        <EUChartContent
                                            id="EUIDiv"
                                            barChartDetails={barChartDetails}
                                            handleToggle={handleToggle}
                                            getBarchartFormattedData={getBarchartFormattedData}
                                            chartKey="euChart"
                                            xtickLabel={EUBarChartxtickLabelProps.euxtickLabel}
                                            width={width}
                                            axisLabel={{ xAxis: () => <>kBTU/ft<sup>2</sup>/year</> }}
                                            isPDF={true}
                                        />
                                    </BarChartContainer>
                                    <BarChartContainer
                                        id="ECIDiv"
                                    >
                                        <EUChartContent
                                            id="ECIDiv"
                                            barChartDetails={barChartDetails}
                                            handleToggle={handleToggle}
                                            getBarchartFormattedData={getBarchartFormattedData}
                                            chartKey="ecChart"
                                            xtickLabel={EUBarChartxtickLabelProps.ecxtickLabel}
                                            width={width}
                                            axisLabel={{ xAxis: () => <>$/ft<sup>2</sup>/year</> }}
                                            isPDF={true}
                                        />
                                    </BarChartContainer>
                                </>
                            }
                        </div>
                    </div>
                </ReportAddPage>
            </>) :
            (<>
                <Spinner />
            </>)
    )
}

const EUChartContent = ({ barChartDetails, getBarchartFormattedData, handleToggle, id, isPDF = false, chartKey, xtickLabel, width = 0, axisLabel = null }) => {
    return (
        <div className="Chart">
            {
                barChartDetails && barChartDetails?.barData?.[chartKey] &&
                <>
                    <EnergyUsageReportChart
                        data={getBarchartFormattedData(barChartDetails?.barData?.[chartKey])}
                        className={isPDF ? "view-pdf-horizontal-bar-chart-dimention" : width > 700 ? 'horizontal-bar-chart-dimention' : 'small-view-horizontal-bar-chart-dimention'}
                        xtickLabel={xtickLabel}
                        isSmallWindow={width < 700}
                    />
                    <p style={{ textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>{axisLabel?.xAxis()}</p>
                </>
            }
            {
                barChartDetails && barChartDetails?.barData?.[chartKey] &&
                <LegendContainer className="legend-container">
                    {
                        barChartDetails?.barData?.[chartKey]?.map(((item, i) =>item?.name && <LegendItem className="legend-item" key={item?.name}>
                            <div className={`${i === 1 ? 'benchmark-checkbox-color' : 'bar-checkbox-color'}`}>
                                <input type="checkbox" id={`${id}-${item.name}`} checked={item.value} onClick={(e) => handleToggle(e, chartKey, i)} />
                                <LegendWrapper className="legend-wrapper" htmlFor={`${id}-${item.name}`}>
                                    {item.name.replace("Index", "")}
                                </LegendWrapper>
                            </div>
                        </LegendItem>
                        ))
                    }
                </LegendContainer>
            }
        </div>)
}

export default ViewEnergyUsageReport