import { Content } from 'src/components/layouts/'
import BaseLinesList from './BaseLinesList'
import { useTranslation } from 'react-i18next'
import { useBreakPoint } from 'src/hooks/breakPoint'
import ContainerWrapper from 'src/components/Container'
import PageHeader from '../../components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import { useBaseLinesContext } from './BaseLinesContextProvider'
import { PAGE_TITLE } from './constants'
import { ACTIONS, MODAL_TYPE } from 'src/constants'
import { ToolBarWrapper } from './styles'
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'
import { useMemo, useState } from 'react'
import { useMutation } from 'src/hooks/APIHooks'
import { DELETE_BASELINE } from './graphql'
import translate, { TranslateComponent } from 'src/common/translations'
import  BaseLineModal  from "./BaseLineCreationAndEdit/BaseLineModal"
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import  withFeatureRestrictions from 'src/common/featureRestrictionHOC'


const BaseLinesContainer = () => {
  const [t] = useTranslation()
  const { width } = useBreakPoint()
  
  const optionsTranslated = {
    delete: translate("Delete BaseLine"),
  }

  const { setReloadEvents, modalType, setModalType, baseLine, setBaselines, setMode, showBaselineModal, setShowBaselineModal, testName } =
    useBaseLinesContext()
  const renderConfirmationText = (modalType) => {
    switch (modalType) {
      case MODAL_TYPE.CONFIRMATION:
        return t('common:DeleteConfirm', { item: baseLine?.name })
      default:
        return t('common:DeleteNotification', { name: baseLine?.name })
    }
  }
  const hideDeleteDialog = () => {
    setModalType('')
  }

  const { onSubmit: deleteBaseLine } = useMutation({
    query: DELETE_BASELINE,
    onSuccess: () => {
      setReloadEvents(true)
      setModalType(MODAL_TYPE.SUCCESS)
      trackEvent(USER_EVENTS.BASELINE_LIBRARY.events.DELETE_BASELINE)
    }
  })

  const deleteBaseLineHandler = () => {
    deleteBaseLine({ input: { id: baseLine?.id } })
  }

  const modalConfig = useMemo(
    () => ({
      heading: optionsTranslated.delete,
      buttons:
        modalType === MODAL_TYPE.CONFIRMATION
          ? [
              {
                text: t('common:Yes'),
                handleClick: deleteBaseLineHandler,
                type: 'valid'
              },
              {
                text: t('common:No'),
                handleClick: hideDeleteDialog,
                type: 'cancel'
              }
            ]
          : [
              {
                text: t('common:Close'),
                handleClick: hideDeleteDialog,
                type: 'cancel'
              }
            ],
      handleClose: hideDeleteDialog
    }),
    [modalType]
  )  
 
  const handleGetBaseLineRecords = (rows) =>{
    setBaselines(rows)
  }
  
  return (
    <ContainerWrapper
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding="0"
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <PageHeader
          pageTitle={<TranslateComponent>{PAGE_TITLE}</TranslateComponent>}
          titleTools={
            <ToolBarWrapper>
                <Button
                    onClick={() => {
                    setMode(ACTIONS.ADD)
                    setShowBaselineModal(!showBaselineModal)
                    trackEvent(USER_EVENTS.BASELINE_LIBRARY.events.CLICK_CREATE_BASELINE)
                    }}
                >
                  <TranslateComponent>CREATE BASELINE</TranslateComponent>
                </Button>
            </ToolBarWrapper>
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <BaseLinesList handleGetBaseLineRecords={handleGetBaseLineRecords}/>
      </Content>
      {[MODAL_TYPE.CONFIRMATION, MODAL_TYPE.SUCCESS].includes(modalType) ? (
        <Modal testName = {testName} {...modalConfig}>
          <ConfirmationText>
            {renderConfirmationText(modalType)}
          </ConfirmationText>
        </Modal>
      ) : null}
      {showBaselineModal && <BaseLineModal  />}
    </ContainerWrapper>
  )
}


export default withFeatureRestrictions(
  BaseLinesContainer
)
