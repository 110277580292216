import { useState, useEffect, useMemo } from 'react'
import EnergyConsumptionWidget from 'src/pages/widgets/widget-library/energyWidgets/consumption/EnergyConsumptionChart'
import {
  aggreagatorSelection,
  formatDataForWidgetChart
} from 'src/common/chartHelperFunctions'
import { isArray } from 'lodash'
import moment from 'moment'
import {
  SimpleLegend,
  createSimpleLegendModel
} from '../../../../../chart-library/CommonComponents/Legend'
import { useTranslation } from 'react-i18next'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'

export const EnergyCostBar = ({
  energyCostData,
  compareEnergyCostData,
  rightBarColor,
  leftBarColor,
  toDateBarColor,
  selectedTimePeriod,
  compareSelectedTimePeriod,
  dataAggregation,
  currentDayData,
  previousDayData,
  getXLabelName,
  plotlineDetails,
  widgetDetails,
  compareInnerBarEnergyCost
}) => {
  const [barChartData, setBarChartData] = useState({})

  const [t] = useTranslation()

  const getInsideBarDataValue = (barData) => {
    try {
      // Inner bar data, current period data of previous data
      // Example we are reuqesting data Lastmonth vs Current month
      // Last month have 31 data, since it is Jan for example
      // But current month today date is 20, we will have only 20 data
      // Inner data is Jan 1 - Jan 20 data

      if (previousDayData?.innerTotal) {
        // current-day, 3-days, 7-days will come here
        return [
          {
            name: barData[0]?.name,
            value: previousDayData?.innerTotal,
            color: toDateBarColor
          }
        ]
      } else {
        if (compareEnergyCostData && compareInnerBarEnergyCost) {
          const demandValuesTilldate =
            aggreagatorSelection(
              'sum',
              compareEnergyCostData?.demandCost?.values,
              0,
              compareEnergyCostData?.demandCost?.values?.length
            ) || 0

          const additionalCostTillDate =
            aggreagatorSelection(
              'sum',
              compareEnergyCostData?.additionalCost?.values,
              0,
              energyCostData?.additionalCost?.values?.length
            ) || 0

          const consumptionCostTillDate =
            aggreagatorSelection(
              'sum',
              compareInnerBarEnergyCost?.consumptionCost?.values?.[0],
              0,
              energyCostData?.consumptionCost?.values?.[0]?.length
            ) || 0

          const totalCostTillDate =
            demandValuesTilldate +
            additionalCostTillDate +
            consumptionCostTillDate

          return [
            {
              name: barData[0]?.name,
              value: totalCostTillDate,
              color: toDateBarColor
            }
          ]
        }
      }
      return [
        {
          name: getXLabelName(true),
          value: aggrBarDataValues(innerBarData),
          color: toDateBarColor
        }
      ]
    } catch (error) {}
  }

  // Get grouped bars - monthly bars only
  const getMonthlyInnerBarValues = (barData) => {
    try {
      if (previousDayData?.innerTotal) {
        return [
          {
            name: barData[barData?.length - 1]?.name,
            displayName: barData[barData?.length - 1]?.name,
            values: [
              { value: previousDayData?.innerTotal, color: toDateBarColor },
              { value: 0, color: toDateBarColor, isSkip: true }
            ]
          }
        ]
      }
      if (compareEnergyCostData) {
        // takes the current month
        // calculate days so far complete in current month
        // sum those values in previous data to get inner bar value
        const monthStartDate = moment().startOf('month')
        const currentDay = moment()
        let currentMonthDaysSofar = currentDay?.diff(monthStartDate, 'days')
        currentMonthDaysSofar =
          currentMonthDaysSofar === 0 ? 1 : currentMonthDaysSofar

        const startDateKey = `${moment()
          .startOf('month')
          .format('YYYY-MM-DD')} 00:00`

        const currentMonthStartIndexInArray =
          compareEnergyCostData?.totalCost?.values?.findIndex(
            (x) => x?.timeStamp === startDateKey
          )

        const totalCostTillDate = aggreagatorSelection(
          'sum',
          compareEnergyCostData?.totalCost?.values,
          currentMonthStartIndexInArray,
          currentMonthStartIndexInArray + currentMonthDaysSofar
        )

        return [
          {
            name: barData[barData?.length - 1]?.name,
            displayName: barData[barData?.length - 1]?.name,
            values: [
              { value: totalCostTillDate, color: toDateBarColor },
              { value: 0, color: toDateBarColor, isSkip: true }
            ]
          }
        ]
      }
    } catch (error) {
      console.log(error)
    }
  }

  const aggrBarDataValues = (data) => {
    let finalValue = 0
    if (data?.length > 0) {
      data.map((x) => {
        if (x?.isDataAvailable) {
          finalValue =
            finalValue +
            Number(
              aggreagatorSelection(
                'sum',
                x?.values?.[0],
                0,
                x?.values?.[0]?.length - 1
              )
            )
        }
      })
    }
    return finalValue
  }

  const getPlotLineDetails = (barData) => {
    try {
      if (!plotlineDetails?.benchMarker?.includes('disabled')) {
        let plotLineValue = 0
        const dataLength = barData?.length
        if (
          plotlineDetails?.benchMarker?.includes('appropriate') &&
          barData?.length > 0
        ) {
          // if its an array grouped bar
          if (isArray(barData[0].values)) {
            let sum = 0
            for (let i = 0; i < barData?.length; i++) {
              sum +=
                barData?.[i]?.values?.[0]?.value +
                barData?.[i]?.values?.[1]?.value
            }
            plotLineValue = sum / (dataLength * 2)
          } else {
            const sum = barData.reduce((a, b) => a + Number(b?.value), 0)
            plotLineValue = sum / dataLength
          }
        } else {
          plotLineValue = plotlineDetails?.benchMarkerValue
        }

        return {
          plotLableName: plotlineDetails?.label,
          plotLineColor: plotlineDetails?.color,
          plotLineValue: plotLineValue,
          plotHeaderLabelHide: true
        }
      }
    } catch (error) {}
  }

  const getBarValues = (compareData, currentData, isGroupBar = false) => {
    if (currentData || compareData) {
      const arrayToLoop = currentData || compareData
      if (currentData && currentData?.length === 1) {
        if (compareData) {
          return [
            {
              name: currentData.timeStamp,
              value: currentData,
              color: rightBarColor,
              isSingleBarTooltip: true
            }
          ]
        } else {
          return [
            {
              name: currentData.timeStamp,
              value: currentData,
              color: rightBarColor,
              isSingleBarTooltip: true
            }
          ]
        }
      } else {
        if (compareData || isGroupBar) {
          const compareBarData = compareData ?? []
          return arrayToLoop?.map((x, index) => {
            return {
              displayName: x?.timeStamp,
              name: `${x?.timeStamp}-Compare`,
              values: [
                {
                  value: compareBarData[index]?.value ?? 0, color: leftBarColor, name: compareBarData[index]?.formatDateValue ?? '',
                  isSingleBarTooltip: true },
                {
                  value: x?.value, color: rightBarColor, name: x?.formatDateValue ?? '',
                  isSingleBarTooltip: true }
              ]
            }
          })
        } else {
          return arrayToLoop?.map((x, index) => {
            return {
              name: x?.formatDateValue,
              displayName: `${x?.formatDateValue}`,
              value: x?.value,
              color: rightBarColor
            }
          })
        }
      }
    }
  }

  const combineCompareAndCurrent = () => {
    try {
      if (selectedTimePeriod === 'current-day') {
        if (previousDayData || currentDayData) {
          const resultBarData = []
          const isPreviousData = previousDayData?.label
          if (isPreviousData) {
            resultBarData?.push({
              name: `${previousDayData?.label}-Compare`,
              displayName: `${previousDayData?.label}`,
              value: previousDayData?.costTotal,
              color: leftBarColor
            })
          }
          resultBarData?.push({
            name: currentDayData?.label,
            value: currentDayData?.costTotal,
            color: rightBarColor
          })
          return resultBarData
        }
      } else if (
        selectedTimePeriod === 'past-3-days' ||
        selectedTimePeriod === 'past-7-days'
      ) {
        const lDateFormat = getDateFormatByLocale("MM/DD")
        const energyCostDataList = energyCostData?.consumptionCost?.values?.[0] ?? []
        const currentBar = formatDataForWidgetChart(
          energyCostDataList,
          'sum',
          dataAggregation,
          selectedTimePeriod,
          widgetDetails?.timeZone
        )
        const currentBarList = currentBar?.map((c,i)=>({
          ...c,
          formatDateValue: moment(energyCostDataList[i]?.timeStamp).format(lDateFormat ? lDateFormat : "MM/DD")
        }))
        const compareEnergyCostDataList = compareEnergyCostData?.consumptionCost?.values?.[0] ?? []
        const compareBar = formatDataForWidgetChart(
          compareEnergyCostDataList,
          'sum',
          dataAggregation,
          selectedTimePeriod,
          widgetDetails?.timeZone
        )
       const compareBarList = compareBar?.map((c,i)=>({
        ...c,
        formatDateValue: moment(compareEnergyCostDataList[i]?.timeStamp).format(lDateFormat ? lDateFormat : "MM/DD")
      }))
      currentBarList[currentBarList?.length - 1].value =
          currentDayData?.costTotal || 0
        return getBarValues(compareBarList, currentBarList, compareSelectedTimePeriod)
      } else if (
        selectedTimePeriod === 'past-3-months' ||
        selectedTimePeriod === 'past-6-months'
      ) {
        return getBarValues(
          compareEnergyCostData?.totalCost?.values?.map(m=>({
            ...m,
            formatDateValue: m?.timeStamp
          })),
          energyCostData?.totalCost?.values?.map(m=>({
            ...m,
            formatDateValue: m?.timeStamp
          })),
          compareSelectedTimePeriod
        )
      } else {
        const resultBarData = []
        const currentBarValue = aggreagatorSelection(
          'sum',
          energyCostData?.totalCost?.values,
          0,
          energyCostData?.totalCost?.values?.length
        )
        const compareBarValue = aggreagatorSelection(
          'sum',
          compareEnergyCostData?.totalCost?.values,
          0,
          compareEnergyCostData?.totalCost?.values?.length
        )

        if (compareEnergyCostData) {
          resultBarData.push({
            name: `${getXLabelName(true)}-Compare`,
            displayName: getXLabelName(true),
            value: compareBarValue,
            color: leftBarColor
          })
        }

        resultBarData?.push({
          name: getXLabelName(),
          displayName: getXLabelName(),
          value: currentBarValue,
          color: rightBarColor
        })

        return resultBarData
      }
    } catch (error) {
      console.log(error)
    }
  }

  const formatBarData = () => {
    try {
      const barDataValues = combineCompareAndCurrent()
      const plotLines = getPlotLineDetails(barDataValues)
      let innerBar = []
      let isGroupBar = false
      if (selectedTimePeriod.includes('past') && compareEnergyCostData) {
        isGroupBar = true
      }

      if (isGroupBar) {
        innerBar = getMonthlyInnerBarValues(barDataValues) || []
      } else {
        innerBar = getInsideBarDataValue(barDataValues) || []
      }
      const barDataObject = {
        measurement: '$',
        barData: isGroupBar ? [] : barDataValues,
        groupBarData: isGroupBar ? barDataValues : [],
        groupInsideBarData: isGroupBar ? innerBar : [],
        insideBarData: isGroupBar ? [] : innerBar,
        plotLineData: plotLines,
        widgetType: "EnergyCost"
      }
      setBarChartData(barDataObject)
    } catch (error) {
      console.log(error)
    }
  }

  const simpleLegends = useMemo(() => {
    if (widgetDetails?.comparedTo !== ':none') {
      const compareLegendLabel = `${t(
        `time>${`:stp/${widgetDetails?.comparedTo}`}`,
        {
          nsSeparator: '>'
        }
      )}`

      const currentLegendLabel = `${t(
        `time>${`${widgetDetails?.timePeriod}`}`,
        {
          nsSeparator: '>'
        }
      )}`

      return [
        createSimpleLegendModel({
          text: compareLegendLabel || '',
          iconProps: {
            symbol: 'symbolSquare',
            fill: widgetDetails?.leftBarColor, //widgetDetails?.currentDemandColor,
            size: 92
          }
        }),
        createSimpleLegendModel({
          text: currentLegendLabel || '',
          iconProps: {
            symbol: 'symbolSquare',
            fill: widgetDetails?.rightBarColor, //widgetDetails?.peakDemandColor,
            size: 92
          }
        })
      ]
    }
    return []
  }, [widgetDetails?.comparedTo])

  useEffect(() => {
    if (
      energyCostData ||
      compareEnergyCostData ||
      currentDayData ||
      previousDayData
    ) {
      formatBarData()
    }
  }, [energyCostData, compareEnergyCostData, currentDayData, previousDayData, compareInnerBarEnergyCost])

  return barChartData?.barData?.length > 0 ||
    barChartData?.groupBarData?.length > 0 ? (
    <>
      <EnergyConsumptionWidget data={barChartData} />
      <SimpleLegend
        className="dashboard-widget-simple-legend"
        isStandaloneLegend
        hide={simpleLegends?.length === 0}
        legends={simpleLegends}
      />
    </>
  ) : (
    <p>No data</p>
  )
}
