export const GET_DEVICES_BY_ID = `query GET_BUILDING_BY_ID($id: ID!) {
    getBuilding(id: $id) {
          name
          id
          devices(filter: {isActive: {eq: 1}}) {
            items {
                name
                uiDisplayName
                id
                serial
            }
          }
        }
      }
  `

export const REPROCESS_ANALYTICS = /* GraphQL */ `
  mutation reprocessAnalytics($requestBody: AWSJSON!) {
    reprocessAnalytics(requestBody: $requestBody)
  }
`

export const REPROCESS_DATA_COLLECTION = /* GraphQL */ `
  mutation reprocessIngestion($request: AWSJSON!) {
    reprocessIngestion(request: $request)
  }
`

export const SEARCH_BUILDINGS = `query SEARCH_BUILDINGS(
  $filter: SearchableBuildingFilterInput
  $limit: Int
  $nextToken: String
) {
    searchBuildings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        accountId
        accountName
        id
      }
      nextToken
      total
    }
  }`
