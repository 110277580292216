import React, { PureComponent } from 'react'
import Modal from '../../../legacy/components/modal/modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './general-modal.scss'

export default class GeneralModal extends PureComponent {
  static modalModes = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    COPY: 'COPY'
  }

  static propTypes = {
    className: PropTypes.string,
    showObjectPicker: PropTypes.bool,
    toggleModal: PropTypes.func,
    tabs: PropTypes.array,
    hasError: PropTypes.bool,
    showPanelOne: PropTypes.bool
  }

  static defaultProps = {
    showObjectPicker: false,
    showPanelOne: false
  }

  state = {
    errors: this.props.errors || [],
    showTab: 0
  }

  getDialogComponent() {
    return document.getElementsByClassName('main-general-modal')[0]
  }
  getFormPanel = () => (
    <div className="general-modal">
      <div className="inner-form-panel">asdads</div>
    </div>
  )

  getTabs = () => {
    const { tabs, hasError } = this.props
    const reducedTabs = tabs.reduce(
      (accum, tab, index) => {
        accum.headers.push(
          <span
            key={index}
            onClick={() => {
              this.setState({ showTab: index })
            }}
            className={classNames('tab-header', {
              'has-error': hasError,
              'selected-tab-header': index === this.state.showTab
            })}
          >
            {hasError && <span className="icon icon-error" />}
            {tab.header}
          </span>
        )
        accum.contents.push(
          <div
            key={`${index}-content`}
            className={classNames('tab-content', {
              'selected-tab-content': index === this.state.showTab
            })}
            ref="tab-content"
          >
            {tab.content}
          </div>
        )
        return accum
      },
      { headers: [], contents: [] }
    )

    return (
      <React.Fragment>
        {reducedTabs.headers}
        {reducedTabs.contents}
      </React.Fragment>
    )
  }
  render() {
    const {
      toggleModal,
      className,
      heading,
      buttons,
      panelOneClass,
      panelTwoClass,
      showObjectPicker,
      showPanelOne
    } = this.props

    const modalConfig = {
      gray: true,
      heading,
      buttons,
      handleClose: toggleModal,
      className
    }

    return (
      <Modal {...modalConfig}>
        <div className="content">
          {showPanelOne ? (
            <div className={`modal-panel-inner ${panelOneClass}`}>
              {showObjectPicker ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    border: '1px solid red'
                  }}
                />
              ) : null}
            </div>
          ) : null}
          <div className={`modal-panel-inner form-panel ${panelTwoClass}`}>
            {this.getTabs()}
          </div>
        </div>
      </Modal>
    )
  }
}
