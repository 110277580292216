import styled, { keyframes, css } from 'styled-components'
import Box from '../Box'
import Icon from '../Icon'

const toastInOut = keyframes`
from {
    opacity: 0;
  }
  to {
    opacity: 1;
    /* transform: translateX(0); */
  }
`

const slideFromRight = keyframes`
from {
    transform: translateX(10em);
  }
  to {
    transform: translateX(0);
  }
`

const slideFromBottom = keyframes`
from{
 transform: translateY(3in);

}`
export const PageWrapper = styled(Box)`
  // position: fixed;
  ${(props) =>
    props.vertical &&
    props.vertical === 'top' &&
    css`
      top: 70px;
    `}
  ${(props) =>
    props.vertical &&
    props.vertical === 'bottom' &&
    css`
      bottom: 70px;
    `}
  z-index: 10;
  left: 50px;
  right: 50px;
  /* top-center */

  /* ${(props) => {
    props.showSnack === 'show' && css``
  }}
  visibility: ${(props) =>
    props.showSnack === 'show' ? 'visible' : 'hidden'}; */
`

export const PageNotification = styled(Box)`
  padding: 19px 20px 19px 20px;
  border-radius: 10px;
  margin-bottom: 1rem;
  ${(props) =>
    props.animation &&
    props.animation === 'slide' &&
    css`
      animation-name: ${toastInOut};
    `}
  ${(props) =>
    props.animation &&
    props.animation === 'slide-right' &&
    css`
      animation-name: ${slideFromRight};
    `}
    ${(props) =>
    props.animation &&
    props.animation === 'slide-bottom' &&
    css`
      animation-name: ${slideFromBottom};
    `}
  animation-duration: 0.4s;

  background: white;
  border: 1px solid grey;

  ${(props) =>
    props.type === 'info' &&
    css`
      background: #f2f8fd;
      border: 1px solid #206fcf;
    `}
  ${(props) =>
    props.type &&
    props.type === 'error' &&
    css`
      background: #fbebea;
      border: 1px solid #991909;
    `}
    ${(props) =>
    props.type &&
    props.type === 'success' &&
    css`
      background: #e6f4e6;
      border: 1px solid #1f7d19;
    `}
    ${(props) =>
    props.type &&
    props.type === 'warning' &&
    css`
      background: #fef9f6;
      border: 1px solid #be5104;
    `} /* opacity: 0.9; */
`

export const NotificationHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 22px;
`
export const StyledIcon = styled(Icon)``
export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Notify = styled.div`
  /* border: 1px solid red; */
`
export const HeaderTitle = styled.div`
  flex: 1;
  font-weight: bold;
  ${(props) =>
    props.type === 'info' &&
    css`
      color: #206fcf;
    `}
  ${(props) =>
    props.type &&
    props.type === 'error' &&
    css`
      color: #991909;
    `}
    ${(props) =>
    props.type &&
    props.type === 'success' &&
    css`
      color: #1f7d19;
    `}
    ${(props) =>
    props.type &&
    props.type === 'warning' &&
    css`
      color: #be5104;
    `}
`
export const CloseEvent = styled(Icon)`
  /* margin-right: 0;
   */
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const NotificationText = styled.div`
  padding-left: 27px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.6;
  text-align: justify;
  padding-right: inherit;

  ${(props) =>
    props.slide &&
    css`
      white-space: normal;
    `}
  ${(props) =>
    !props.slide &&
    css`
      white-space: nowrap;
    `}
`
