import IconSvg from 'src/components/legacy/components/icon-svg/icon'
import { KPI_SETTINGS, TIME_PERIOD_OPTION_LIST, TIME_PERIOD_OPTION_LIST_DISPLAY } from "./helpers"
import colors from 'src/components/legacy/common/colors.json'
import Select from 'src/components/legacy/components/select/select'
import { KPIImpactContainer } from "./style"
import { formatCurrency } from 'src/components/legacy/common/currency'

export const KPIImpact = ({ onChange, kpiImpact: { low, medium, high, name: kpiName }, error, mode }) => {
    const kpiSettings = KPI_SETTINGS()
    const { icon = "", name = "" } = kpiSettings[kpiName] || {}

    const selectbox = ({ value = "", timePeriod = "" }, field, error) => {
        const timePeriodOptions = TIME_PERIOD_OPTION_LIST.map(({ key, value }) => ({ key, value: value }))
        return (
            <div className={`select-impact ${field}`}>
                <p className="title-select">{`Impacts${field}`}</p>
                <span className="currency">$</span>
                    <input
                        type="number"
                        className={`${error ? "invalid" : ""}`}
                        onChange={({ target }) => onChange(target.value ? Number(target.value) : undefined, `${field}.value`)}
                        value={value} />
                    {error && <p className="error">{error}</p>}
                
                <Select
                    options={timePeriodOptions}
                    selectedItem={`${TIME_PERIOD_OPTION_LIST_DISPLAY[timePeriod]}` || timePeriodOptions[0].value}
                    onChange={val => onChange(val, `${field}.timePeriod`)}
                    />
            </div>
        )
    }

    const viewContent = ({ value, timePeriod }, field) => {
        return (
            <div className={`view-impact ${field}`}>
                <p className="title-view">{`Impacts${field}`}</p>
                <span className="content-view">{`${formatCurrency(value)} ${TIME_PERIOD_OPTION_LIST_DISPLAY[timePeriod]}`}</span>
            </div>
        )
    }
    
    return (
        <KPIImpactContainer>
            { mode === "view" ? 
            <>
                <div className={`title-value ${kpiName || "custom"}`}>
                    <IconSvg
                        name={icon || "truck-rolls-impact"}
                        color={colors.ORANGE_6}
                        hover={colors.ORANGE_6}
                        width="30px"
                        height="30px"
                        viewBox="0 0 65 65"
                        className="kpi-icons"
                        cursor="auto"
                    />
                    <span>{name || "Custom Impact"}</span>
                </div>
                <div className="impact-list">
                    {viewContent(low, "low")}
                    {viewContent(medium, "medium")}
                    {viewContent(high, "high")}
                </div>
            </> : <>
            <div className={`title-value ${kpiName || "custom"}`}>
                <IconSvg
                    name={icon || "truck-rolls-impact"}
                    color={colors.ORANGE_6}
                    hover={colors.ORANGE_6}
                    width="30px"
                    height="30px"
                    viewBox="0 0 65 65"
                    className="kpi-icons"
                    cursor="auto"
                />
                <span>{`${name || "Custom"} Impact`}</span>
            </div>
            <div className="impact-list">
                {selectbox(low, "low", error.low)}
                {selectbox(medium, "medium", error.medium)}
                {selectbox(high, "high", error.high)}
            </div></>}
            
        </KPIImpactContainer>
    )
}
