import { useEffect, useState, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import ContainerWrapper from 'src/components/Container'
import PageHeader from '../../components/pageHeaderNew/header'
import { PAGE_TITLE } from './constants'
import { EquipmentListSelector } from 'src/components/equipmentsListSelector'
import { EnergyConsumptionLineChart } from './EnergyConsumptionLineChart'
import { EnergyConsumptionHeatMapChart } from './EnergyConsuptionHeatMap'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import ChartDateControls from 'src/components/chartDateControls/chartdatecontrols'
import { Link } from 'react-router-dom'
import COLORS from 'src/components/legacy/common/colors.json'
import ChartSettings from 'src/components/chart-settings-sc/chart-settings'
import { TEXT_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import {
  ToolBarWrapper,
  WarningContainer,
  ChartSelectButton,
  EnergyConsumptionContainerDiv,
  HeaderResultBlock,
  ChartAreaWrapper,
  ChartControlContainer,
  NoDataMessage,
  DataRangeAvailableContainer
} from './styles.js'
import { useQuery } from 'src/hooks/APIHooks'
import Select from 'src/components/legacy/components/select/select'
import { getSearchParams } from '../../common/helperFunctions.js'
import { useSelector } from 'react-redux'
import { selectUserInfo } from 'src/redux/slicers/appData'
import Icon from 'src/denali-ui/components/Icon/index'
import colors from 'src/components/layouts/colors.json'
import { Block } from 'src/components/layouts'
import { TranslateComponent } from 'src/common/translations'
import { aggrDataFormatter, formatData } from 'src/common/chartHelperFunctions'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { LIST_EVENT_MARKERS_BY_ACCOUNT } from '../eventMarkers/graphql'
import { endDateDecider } from './helper.js'
import { isValidUOMForGetChartData } from "src/pages/dashboards/helper";
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import  withFeatureRestrictions from 'src/common/featureRestrictionHOC'



export const chartDateFormat = 'YYYY-MM-DDTHH:mm:ss'

const listBaselinesByBuilding = /* GraphQL */ `
  query listBaselinesByBuilding(
    $buildingId: ID!
    $filter: ModelBaselineFilterInput
  ) {
    listBaselinesByBuilding(
      buildingId: $buildingId
      filter: $filter
      limit: 1000
    ) {
      items {
        name
        id
        equipmentId
        startPeriod
        endPeriod
      }
    }
  }
`

export const GET_BUILDING_DETAILS = /* GraphQL */ `
  query getBuildingDetails($buildingId: ID!) {
    getBuilding(id: $buildingId) {
      buildingId
      name
      accountName
      tz
    }
  }
`

const GET_CHART_DATA = /* GraphQL */ `
  query getChartData($body: AWSJSON) {
    getChartData(body: $body)
  }
`

export const getEquipment = /* GraphQL */ `
  query getEquipment($id: ID!) {
    getEquipment(id: $id) {
      createdAt
    }
  }
`

const chartOptions = {
  line: 'line',
  heat: 'heat'
}

const unitMappingDisplay = {
  '($) Dollars': '$',
  kBTUs: 'kBTUs',
  kiloWattHour: 'kWh'
}

const unitsOfMeasure = {
  dollars: '($) Dollars',
  kW: 'kiloWattHour',
  kBTUs: 'kBTUs'
}

const EnergyConsumptionContainer = () => {
  // Lot of room for optimization here, will do later once all bugs are fixed.
  const [totalEnergyConsumed, setTotalEnergyConsumed] = useState(0)
  const [predictedTotalEnergy, setPredictedTotalEnergy] = useState(0)
  const [totalEnergyConsumedPrevious, setTotalEnergyConsumedPrevious] =
    useState(0)

  const [totalEnergyConsumedCost, setTotalEnergyConsumedCost] = useState(0)
  const [predictedTotalEnergyCost, setPredictedTotalEnergyCost] = useState(0)
  const [totalEnergyConsumedPreviousCost, setTotalEnergyConsumedPreviousCost] =
    useState(0)

  const { width } = useBreakPoint()
  const [canChartShown, setCanChartShown] = useState(false)
  const [selectedChart, setSelectedChart] = useState(chartOptions.line)
  const [selectedEquipment, setSelectedEquipment] = useState(null)
  const [consumptionChartData, setConsumptionChartData] = useState(null)
  const [currentPeriodChartData, setCurrentPeriodChartData] = useState(null)
  const [previousPeriodChartData, setPreviousPeriodChartData] = useState(null)
  const [energyConsumption15MinsData, setEnergyConsumption15MinsData] =
    useState(null)
  const [heatMapChartData, setHeatMapChartData] = useState(null)
  const [chartDataForDisplay, setChartDataForDisplay] = useState(null)
  const [energyConsumptionLineChartData, setEnergyConsumptionLineChartData] =
    useState(null)
  const [unit, setUnit] = useState(unitsOfMeasure.kW)
  const [compareTo, setCompareTo] = useState('none')
  const [compareToOptions, setCompareToOptions] = useState([])
  const [markers, setMarkers] = useState([])
  const [timeSliderStartTime, setTimeSliderStartTime] = useState(null)
  const [timeSliderEndTime, setTimeSliderEndTime] = useState(null)

  const [dataAvailabilityDates, setDataAvailablityDates] = useState({
    startDate: '',
    endDate: ''
  })

  const [meterAvailability, setMeterAvailability] = useState(true)

  const [dataAvailablityForChart, setDataAvailabilityForChart] = useState(true)

  const [searchParams] = useSearchParams()
  const { buildingId, organizationId, equipment } = getSearchParams()

  const energyConsumptionPropertyName =
    selectedEquipment?.type === 'VirtualMeter'
      ? 'TotalConsumption'
      : 'ConsumptionInterval'

  const { refetch: getChartDataForBuilding, loading: loadingChartData } =
    useQuery({
      query: GET_CHART_DATA,
      dataPath: 'data.getChartData',
      disableInitialLoad: true
    })

  const { data: buildingDetails, refetch: refetchBuildingDetails } = useQuery({
    query: GET_BUILDING_DETAILS,
    dataPath: 'data.getBuilding',
    variables: { buildingId },
    disableInitialLoad: true
  })

  const { data: baselinesList, refetch: refetchBaselinesList } = useQuery({
    query: listBaselinesByBuilding,
    dataPath: 'data.listBaselinesByBuilding.items',
    variables: { buildingId },
    disableInitialLoad: true
  }) 

  const [chartDates, setChartDates] = useState({
    chartStartDate: moment().subtract(1, 'month').format(BACKEND_DATE_FORMAT),
    chartEndDate: moment().subtract(1, 'days').format(BACKEND_DATE_FORMAT),
    activeRange: '1M',
    dateError: false
  })

  const { data: markersData, refetch: refetcMarkersData } = useQuery({
    query: LIST_EVENT_MARKERS_BY_ACCOUNT,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listEventMarkersByAccount.items'
  })

  const {
    data: Equipment,
    refetch: refetchEquipment,
    loading: loadingEquipmentDetails
  } = useQuery({
    query: getEquipment,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getEquipment'
  })

  const unitsOfMeasureOptions = useMemo(() => {
    if (selectedChart === chartOptions?.line) {
      const options = { ...unitsOfMeasure }
      return options
    } else {
      const options = { ...unitsOfMeasure }
      delete options.dollars
      return options
    }
  }, [selectedChart])

  const userInfo = useSelector(selectUserInfo)

  const onEquipmentChange = (equipmentDetails) => {
    setSelectedEquipment(equipmentDetails)

    // reset some state variables
    setDataAvailablityDates({
      startDate: '',
      endDate: ''
    })
    setTotalEnergyConsumed(0)
    setTotalEnergyConsumedPrevious(0)
    setPredictedTotalEnergy(0)
    setTotalEnergyConsumedCost(0)
    setTotalEnergyConsumedPreviousCost(0)
    setPredictedTotalEnergyCost(0)
    setSelectedChart(chartOptions.line)
    setCompareTo('none')
    // get equipment created date, to set data availability
    if (equipmentDetails?.type === 'ElectricMeter') {
      setUnit(unitsOfMeasure?.kW)
    } else {
      setUnit(unitsOfMeasure?.kBTUs)
    }
    // to get data availability
    refetchEquipment({ id: equipmentDetails?.key })
  }

  const updateUOM = (item) => {
    setUnit(unitsOfMeasure[item])
    trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CHANGE_CHART_UNITS,{uom:unitsOfMeasure[item]})
  }

  const updateCompareTo = (item) => {
    setCompareTo(compareToOptions[item])
    trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CHANGE_CHART_COMPARE,{"compare to":compareToOptions[item]})
  }

  const findVariancePercentage = (value1, value2) => {
    return (1 - value1 / value2) * 100
  }

  useEffect(() => {
    if (Equipment) {
      setDataAvailablityDates({
        startDate: moment(Equipment?.createdAt)?.format(TEXT_DATE_FORMAT),
        endDate: moment().format(TEXT_DATE_FORMAT)
      })
    }
  }, [Equipment])

  const selectedEquipmentBaselineDetails = useMemo(() => {
    const dateFormat = 'MMM DD, yyyy'

    const Options = {
      none: 'none',
      previousYear: 'previous year',
      baseline: 'baseline'
    }
    const equipmentBaseline = baselinesList?.find(
      (x) => x.equipmentId === selectedEquipment?.key
    )

    if (equipmentBaseline) {
      setCompareToOptions(Options)
      return {
        baselineStartDate: moment(equipmentBaseline?.startPeriod).format(
          dateFormat
        ),
        baselineEndDate: moment(equipmentBaseline?.endPeriod).format(dateFormat)
      }
    } else {
      delete Options.baseline
      if (compareTo === 'baseline') {
        setCompareTo('none')
      }
      setCompareToOptions(Options)
      return null
    }
  }, [selectedEquipment])

  const formatMinMaxTemperatureData = (data) => {
    if (Object.keys(data)?.length > 0) {
      const details = {
        propertyName: 'Temperature',
        propertyKey: 'Temperature',
        isDataAvailable: true
      }
      const temperatureValues = []
      for (const id in data) {
        const equipmentData = data[id]
        details.equipmentId = id
        const maxTempValues = equipmentData?.max?.values
        const minTempValues = equipmentData?.min?.values

        let currentTimestamp = moment(
          chartDates?.chartStartDate,
          chartDateFormat
        )
        let endTimestamp = moment(
          chartDates?.chartEndDate,
          chartDateFormat
        ).add(1, 'days')

        while (currentTimestamp.isSameOrBefore(endTimestamp)) {
          const dateKey = currentTimestamp.format('YYYY-MM-DD')
          const maxTempValue =
            Number(maxTempValues?.[dateKey]?.[0]?.[0]) || null
          const minTempValue =
            Number(minTempValues?.[dateKey]?.[0]?.[0]) || null
          temperatureValues.push({
            timeStamp: currentTimestamp.format(chartDateFormat),
            value: [maxTempValue, minTempValue]
          })
          currentTimestamp = currentTimestamp.add(1440, 'minutes')
        }
      }
      details.values = [temperatureValues]
      return details
    }
  }

  // Color code to show total savings in overall result
  const totalSavingsOrOverage = useMemo(() => {
    let color, variance, variancePercentage
    if (unitMappingDisplay[unit] === '$') {
      if (compareTo === 'baseline') {
        variance = predictedTotalEnergyCost - totalEnergyConsumedCost
        variancePercentage = findVariancePercentage(
          totalEnergyConsumedCost,
          predictedTotalEnergyCost
        )
        color = variance > 0 ? COLORS.GREEN : COLORS.RED
      } else if (compareTo === 'previous year') {
        variance = totalEnergyConsumedPreviousCost - totalEnergyConsumedCost
        color = variance > 0 ? COLORS.GREEN : COLORS.RED
        variancePercentage = findVariancePercentage(
          totalEnergyConsumedPreviousCost,
          totalEnergyConsumedCost
        )
      } else {
        color = COLORS.GRAY
      }
    } else {
      if (compareTo === 'baseline') {
        variance = predictedTotalEnergy - totalEnergyConsumed
        variancePercentage = findVariancePercentage(
          totalEnergyConsumed,
          predictedTotalEnergy
        )
        color = variance > 0 ? COLORS.GREEN : COLORS.RED
      } else if (compareTo === 'previous year') {
        variance = totalEnergyConsumedPrevious - totalEnergyConsumed
        color = variance > 0 ? COLORS.GREEN : COLORS.RED
        variancePercentage = findVariancePercentage(
          totalEnergyConsumedPrevious,
          totalEnergyConsumed
        )
      } else {
        color = COLORS.GRAY
      }
    }

    return {
      color,
      variance: variance?.toFixed(2),
      variancePercentage: variancePercentage?.toFixed(2)
    }
  }, [consumptionChartData, compareTo, unit])

  useEffect(() => {
    convert15MinsDatabasedOnUnit(energyConsumption15MinsData)
  }, [unit])

  // index property is missed in baseline data, so push the index props
  const formatBaselineDataWithIndex = (baselineData) => {
    const newBaselineData = {}
    for (const id in baselineData) {
      const equipmentData = baselineData[id]
      const properties = {}
      const existingPropertiesObj = equipmentData?.properties || {}
      for (const propertyKey in existingPropertiesObj) {
        const index = existingPropertiesObj[propertyKey]
        properties[propertyKey] = {
          index: index,
          name: propertyKey
        }
      }
      newBaselineData[id] = {
        properties,
        values: equipmentData?.values
      }
    }
    return newBaselineData
  }

  const filterValuesOnlyBetweenStartAndEndDates = (data) => {
    try {
      const newData = [...data]
      const currentValues = data[0]?.values[0]
      const correctedValues = currentValues?.filter((x) => {
        const valueDate = moment(
          moment(x?.timeStamp).format(BACKEND_DATE_FORMAT)
        )
        const startDate = moment(chartDates?.chartStartDate)
        const endDate = moment(chartDates?.chartEndDate)
        return (
          valueDate.isSameOrAfter(startDate) &&
          valueDate.isSameOrBefore(endDate)
        )
      })
      newData[0].values[0] = correctedValues
      return newData
    } catch (e) {
      return data
    }
  }

  const format15MinsConsumptionData = (consumption15minsData) => {
    try {
      let baselineWithIndex = consumption15minsData
      if (selectedEquipment?.type === 'ElectricMeter') {
        baselineWithIndex = formatBaselineDataWithIndex(consumption15minsData)
      }

      const baselineDataCurrent = formatData(
        baselineWithIndex || [],
        chartDates?.chartStartDate,
        chartDates?.chartEndDate,
        buildingDetails?.tz || moment.tz.guess(),
        '15m'
      )

      // formatdata adds one more date for proper check, so we have to remove that date in heat map
      // This is a temporary will only, actual fix has be done in Format data
      // have to revisit the method after Aug release [ 2024 ]
      const baselineCorrectedData =
        filterValuesOnlyBetweenStartAndEndDates(baselineDataCurrent)
      return baselineCorrectedData
    } catch (error) {}
  }

  const getConsumption15MinsData = async () => {
    try {
      if (selectedEquipment) {
        const chartDataRequestFormat = {
          startDate: moment(chartDates?.chartStartDate).format(
            BACKEND_DATE_FORMAT
          ),
          endDate: moment(chartDates?.chartEndDate).format(BACKEND_DATE_FORMAT),
          category: 'equipment', // equipment or building level data needed
          chartType: 'line',
          building: {
            id: buildingId,
            pId: [],
            type: ''
          },
          equipment: [
            {
              eId: [{ id: selectedEquipment?.key }],
              type: selectedEquipment?.type,
              pId:
                selectedEquipment?.type !== 'ElectricMeter'
                  ? [energyConsumptionPropertyName]
                  : []
            }
          ],
          baseline: {
            eId: selectedEquipment?.key,
            pId:
              selectedEquipment?.type === 'ElectricMeter'
                ? [energyConsumptionPropertyName]
                : []
          },
          equipmentType: selectedEquipment?.type,
          weather: {},
          settings: []
        }
        const uom = getUOMValue(unit)
        const energyUom = uom === 'usd' ? 'kiloBtu' : uom
        if (isValidUOMForGetChartData(uom) && selectedEquipment?.type !== 'ElectricMeter') {
          chartDataRequestFormat.equipment[0]["uom"] = { [energyConsumptionPropertyName]: energyUom }
        }
        if (isValidUOMForGetChartData(uom) && selectedEquipment?.type === 'ElectricMeter') {
          chartDataRequestFormat.baseline["uom"] = { [energyConsumptionPropertyName]: energyUom }
        }
        const requestBody = { body: JSON.stringify(chartDataRequestFormat) }
        const chartData = await getChartDataForBuilding(requestBody)
        const parsedChartData = JSON.parse(chartData)
        // Gas meter 15-mins data, request and response is different
        const responseData =
          selectedEquipment?.type !== 'ElectricMeter'
            ? {
                [selectedEquipment?.key]:
                  parsedChartData?.body?.data[selectedEquipment?.key]
              }
            : parsedChartData?.body?.baselineData
        const baseline15MinsData = format15MinsConsumptionData(responseData)
        setEnergyConsumption15MinsData(baseline15MinsData)
        convert15MinsDatabasedOnUnit(baseline15MinsData)
      }
    } catch (error) {
      setDataAvailabilityForChart(false)
    }
  }

  const getUOMValue = (uom) => {
    switch (uom) {
      case 'kBTUs':
        return 'kiloBtu'
      case '$':
      case '($) Dollars':
        return 'usd'
      case 'kWh':
      case 'kiloWattHour':
        return 'kiloWattHour'
      default:
        return ''
    }
  }

  const convert15MinsDatabasedOnUnit = (data) => {
    try {
      if (data?.[0]?.values?.[0]?.length > 0) {
        const responseData = JSON.parse(JSON.stringify(data))
        setHeatMapChartData(null)
        const selectedUnit = unitMappingDisplay[unit]
        const values = responseData?.[0]?.values?.[0]
        const convertedData = values?.map((x) => {
          let value
          if (
            selectedUnit === 'kBTUs' || selectedUnit === 'kWh'
          ) {
            value = x?.value ? Number(x?.value)?.toFixed(2) : null
          }  else {
            value = x?.value !== null ? x?.value : null
          }
          return {
            timeStamp: x?.timeStamp,
            value
          }
        })
        responseData[0].values[0] = convertedData
        setHeatMapChartData(responseData)
      }
    } catch (error) {
      setHeatMapChartData(null)
    }
  }

  const getPreviousPeriodChartData = async () => {
    try {
      if (selectedEquipment) {
        const endDate = endDateDecider(
          chartDates?.chartStartDate,
          chartDates?.chartEndDate,
          BACKEND_DATE_FORMAT
        )
        const chartDataRequestFormat = {
          startDate: moment(chartDates?.chartStartDate)
            .subtract(1, 'years')
            .format(BACKEND_DATE_FORMAT),
          endDate: moment(endDate)
            .subtract(1, 'years')
            .format(BACKEND_DATE_FORMAT),
          category: 'equipment', // equipment or building level data needed
          chartType: 'line',
          building: {
            id: buildingId,
            pId: [],
            type: ''
          },
          equipment: [
            {
              eId: [{ id: selectedEquipment?.key }],
              type: selectedEquipment?.type,
              pId: [
                {
                  sum:
                    [
                      energyConsumptionPropertyName,
                      'ConsumptionCost',
                      'BaselinePredictionCost'
                    ] || []
                }
              ]
            }
          ],
          equipmentType: selectedEquipment?.type,
          weather: {},
          settings: [],
          buildingDateTime: '2024-01-08 23:26:43'
        }
        const uom = getUOMValue(unit)
        const energyUom = uom === 'usd' ? 'kiloBtu' : uom        
        if (isValidUOMForGetChartData(uom)) {
          chartDataRequestFormat.equipment[0]["uom"] = { [energyConsumptionPropertyName]: energyUom, 'ConsumptionCost': "usd", 'BaselinePredictionCost': "usd" }
        }
        const requestBody = { body: JSON.stringify(chartDataRequestFormat) }
        const chartData = await getChartDataForBuilding(requestBody)
        const parsedChartData = JSON.parse(chartData)
        setPreviousPeriodChartData(parsedChartData)
      }
    } catch (error) {
      setDataAvailabilityForChart(false)
    }
  }

  const getCurrentPeriodChartData = async () => {
    try {
      if (selectedEquipment) {
        const endDate = endDateDecider(
          chartDates?.chartStartDate,
          chartDates?.chartEndDate,
          BACKEND_DATE_FORMAT
        )
        const chartDataRequestFormat = {
          startDate: chartDates?.chartStartDate,
          endDate: endDate,
          category: 'equipment', // equipment or building level data needed
          chartType: 'line',
          building: {
            id: buildingId,
            pId: [],
            type: ''
          },
          equipment: [
            {
              eId: [{ id: selectedEquipment?.key }],
              type: selectedEquipment?.type,
              pId: [
                {
                  sum: [
                    'BaselinePrediction',
                    energyConsumptionPropertyName,
                    'ConsumptionCost',
                    'BaselinePredictionCost'
                  ]
                }
              ]
            }
          ],
          equipmentType: selectedEquipment?.type,
          weather: { min: ['TemperatureF'], max: ['TemperatureF'] },
          settings: [],
          buildingDateTime: '2024-01-08 23:26:43'
        }
        const uom = getUOMValue(unit)
        const energyUom = uom === 'usd' ? 'kiloBtu' : uom
        if (isValidUOMForGetChartData(uom)) {
          chartDataRequestFormat.equipment[0]["uom"] = { 'BaselinePrediction': energyUom, [energyConsumptionPropertyName]: energyUom, 'ConsumptionCost': 'usd', 'BaselinePredictionCost': 'usd' }
        }
        const requestBody = { body: JSON.stringify(chartDataRequestFormat) }
        const chartData = await getChartDataForBuilding(requestBody)
        const parsedChartData = JSON.parse(chartData)
        setCurrentPeriodChartData(parsedChartData)
      }
    } catch (error) {
      setDataAvailabilityForChart(false)
    }
  }

  const chartDataToDisplay = () => {
    try {
      if (consumptionChartData) {
        if (unit === unitsOfMeasure?.dollars) {
          const consumptionData = { ...consumptionChartData }
          if (compareTo === 'none') {
            const chartData = {
              data: [
                consumptionData?.tempetature,
                consumptionData?.actualEnergyCost
              ],
              timestamps: consumptionData?.timestamps
            }
            setChartDataForDisplay(chartData)
            setEnergyConsumptionLineChartData(chartData)
          } else if (compareTo === 'baseline') {
            const baselinePredictionDataAvailable =
              consumptionData?.predictedEnergyCost?.isDataAvailable
            const chartData = {
              data: [
                consumptionData?.tempetature,
                consumptionData?.actualEnergyCost,
                ...(baselinePredictionDataAvailable
                  ? [
                      consumptionData?.predictedEnergyCost,
                      consumptionData?.energyCostSavingsVsPrediction,
                      consumptionData?.energyCostOverageVsPrediction,
                      consumptionData?.totalSavingsCostPredictionTillDate
                    ]
                  : [])
              ],
              timestamps: consumptionData?.timestamps
            }
            setChartDataForDisplay(chartData)
            setEnergyConsumptionLineChartData(chartData)
          } else {
            const previousYearDataAvailable =
              consumptionData?.actualEnergyCostPrevious?.isDataAvailable
            const chartData = {
              data: [
                consumptionData?.tempetature,
                consumptionData?.actualEnergyCost,
                ...(previousYearDataAvailable
                  ? [
                      consumptionData?.actualEnergyCostPrevious,
                      consumptionData?.energyCostSavingsVsPrevious,
                      consumptionData?.energyOverageVsPrevious,
                      consumptionData?.totalSavingsCostPreviousTillDate
                    ]
                  : [])
              ],
              timestamps: consumptionData?.timestamps
            }
            setChartDataForDisplay(chartData)
            setEnergyConsumptionLineChartData(chartData)
          }
        } else {
          const consumptionData = { ...consumptionChartData }
          if (compareTo === 'none') {
            const chartData = {
              data: [
                consumptionData?.tempetature,
                consumptionData?.actualConsumption
              ],
              timestamps: consumptionData?.timestamps
            }
            setChartDataForDisplay(chartData)
            setEnergyConsumptionLineChartData(chartData)
          } else if (compareTo === 'baseline') {
            const baselinePredictionDataAvailable =
              consumptionData?.baselinePrediction?.isDataAvailable
            const chartData = {
              data: [
                consumptionData?.tempetature,
                consumptionData?.actualConsumption,
                ...(baselinePredictionDataAvailable
                  ? [
                      consumptionData?.baselinePrediction,
                      consumptionData?.energySavingsVsPrediction,
                      consumptionData?.energyOverageVsPrediction,
                      consumptionData?.totalSavingsPredictionTillDate
                    ]
                  : [])
              ],
              timestamps: consumptionData?.timestamps
            }
            setChartDataForDisplay(chartData)
            setEnergyConsumptionLineChartData(chartData)
          } else {
            const previousYearDataAvailable =
              consumptionData?.actualConsumptionPrevious?.isDataAvailable
            const chartData = {
              data: [
                consumptionData?.tempetature,
                consumptionData?.actualConsumption,
                ...(previousYearDataAvailable
                  ? [
                      consumptionData?.actualConsumptionPrevious,
                      consumptionData?.energySavingsVsPrevious,
                      consumptionData?.energyOverageVsPrevious,
                      consumptionData?.totalSavingsPreviousTillDate
                    ]
                  : [])
              ],
              timestamps: consumptionData?.timestamps
            }
            setChartDataForDisplay(chartData)
            setEnergyConsumptionLineChartData(chartData)
          }
        }
      }
    } catch (error) {
      setDataAvailabilityForChart(false)
      console.log(error)
    }
  }

  useEffect(() => {
    // get all event markers for selected building
    refetcMarkersData({
      accountId: organizationId,
      filter: { buildingIds: { contains: buildingId } }
    })
    refetchBuildingDetails()
    resetAllData()
    setSelectedEquipment(null)
  }, [buildingId])

  useEffect(() => {
    if (markersData) {
      setMarkers(markersData)
    }
  }, [markersData])

  useEffect(() => {
    const filter = { equipmentId: { eq: selectedEquipment?.id } }
    setCanChartShown(false)
    setChartDataForDisplay(null)
    setEnergyConsumptionLineChartData(null)
    refetchBaselinesList({ buildingId, filter })
  }, [buildingId, selectedEquipment])

  useEffect(() => {
    chartDataToDisplay()
  }, [consumptionChartData, compareTo, unit])

  const resetAllData = () => {
    setCurrentPeriodChartData(null)
    setPreviousPeriodChartData(null)
    setChartDataForDisplay(null)
    setEnergyConsumptionLineChartData(null)
    setConsumptionChartData(null)
    setCanChartShown(false)
    setEnergyConsumption15MinsData(null)
    // setChartDates({
    //   chartStartDate: moment().subtract(1, 'month').format(BACKEND_DATE_FORMAT),
    //   chartEndDate: moment().format(BACKEND_DATE_FORMAT),
    //   // activeRange: '1M',
    //   dateError: false
    // })
    setMeterAvailability(true)
  }

  useEffect(() => {
    if (chartDates?.dateError) {
      return
    }
    resetAllData()
    getCurrentPeriodChartData()
    getConsumption15MinsData()
    getPreviousPeriodChartData()
  }, [
    selectedEquipment,
    chartDates?.chartStartDate,
    chartDates?.chartEndDate,
    chartDates?.dateError,
    unit
  ])

  // find delta and savings from actual and prediction
  const calculateConsumptionProperties = (
    baselineData,
    baselineDataPrevious
  ) => {
    // All the calculations done in this method
    // May be we can seperate some calculations to another method for simplication and maintanance
    try {
      const baselinePrediction =
        baselineData?.find((x) => x.propertyKey === 'BaselinePrediction') || {}
      baselinePrediction.propertyName = 'Predicted Energy'

      const actualConsumption =
        baselineData?.find(
          (x) => x.propertyKey === energyConsumptionPropertyName
        ) || {}
      actualConsumption.propertyName = 'Actual Energy'

      const actualConsumptionPrevious =
        baselineDataPrevious?.find(
          (x) => x.propertyKey === energyConsumptionPropertyName
        ) || {}
      actualConsumptionPrevious.propertyName = 'Previous Year'
      actualConsumptionPrevious.propertyKey = 'PreviousYear'

      const actualEnergyCost =
        baselineData?.find((x) => x.propertyKey === 'ConsumptionCost') || {}
      actualEnergyCost.propertyName = 'Actual Energy'

      const actualEnergyCostPrevious =
        baselineDataPrevious?.find(
          (x) => x.propertyKey === 'ConsumptionCost'
        ) || {}
      actualEnergyCostPrevious.propertyName = 'Previous Year'
      actualEnergyCostPrevious.propertyKey = 'PreviousYear'

      const predictedEnergyCost =
        baselineData?.find((x) => x.propertyKey === 'BaselinePredictionCost') ||
        {}
      predictedEnergyCost.propertyName = 'Predicted Energy'

      const actualConsumptionValues = actualConsumption?.values?.[0]

      const predictedEnergyValues = baselinePrediction?.values?.[0]

      const actualConsumptionCostValues = actualEnergyCost?.values?.[0]

      const predictedEnergyCostValues = predictedEnergyCost?.values?.[0]

      const actualConsumptionCostValuesPrevious =
        actualEnergyCostPrevious?.values?.[0]

      const actualConsumptionValuesPrevious =
        actualConsumptionPrevious?.values?.[0]

      // Data availability check flag
      baselinePrediction.isDataAvailable =
        predictedEnergyValues?.some((x) => x?.value !== null) || false

      actualConsumption.isDataAvailable =
        actualConsumptionValues?.some((x) => x?.value !== null) || false

      actualConsumptionPrevious.isDataAvailable =
        actualConsumptionValuesPrevious?.some((x) => x?.value !== null) || false

      actualEnergyCost.isDataAvailable =
        actualConsumptionCostValues?.some((x) => x?.value !== null) || false

      predictedEnergyCost.isDataAvailable =
        predictedEnergyCostValues?.some((x) => x?.value !== null) || false

      actualEnergyCostPrevious.isDataAvailable =
        actualConsumptionCostValuesPrevious?.some((x) => x?.value !== null) ||
        false

      const energySavingsVsPrediction = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Energy Savings',
        propertyKey: 'EnergySavings',
        values: [[]]
      }

      const energyOverageVsPrediction = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Energy Overage',
        propertyKey: 'EnergyOverage',
        values: [[]]
      }

      const energyCostSavingsVsPrediction = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Energy Savings',
        propertyKey: 'EnergySavings',
        values: [[]]
      }

      const energyCostOverageVsPrediction = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Energy Overage',
        propertyKey: 'EnergyOverage',
        values: [[]]
      }

      const energySavingsVsPrevious = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Energy Savings',
        propertyKey: 'EnergySavings',
        values: [[]]
      }

      const energyOverageVsPrevious = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Energy Overage',
        propertyKey: 'EnergyOverage',
        values: [[]]
      }

      const energyCostSavingsVsPrevious = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Energy Savings',
        propertyKey: 'EnergySavings',
        values: [[]]
      }

      const energyCostOverageVsPrevious = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Energy Overage',
        propertyKey: 'EnergyOverage',
        values: [[]]
      }

      const totalSavingsPredictionTillDate = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Total Savings',
        propertyKey: 'TotalSavings',
        values: [[]]
      }

      const totalSavingsPreviousTillDate = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Total Savings',
        propertyKey: 'TotalSavings',
        values: [[]]
      }

      const totalSavingsCostPredictionTillDate = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Total Savings',
        propertyKey: 'TotalSavings',
        values: [[]]
      }

      const totalSavingsCostPreviousTillDate = {
        equipmentId: baselinePrediction?.equipmentId,
        propertyName: 'Total Savings',
        propertyKey: 'TotalSavings',
        values: [[]]
      }

      let totalPredictedEnergy = 0

      let totalEnergyConsumed = 0

      let totalPredictedEnergyCost = 0

      let totalEnergyConsumedCost = 0

      let totalEnergyConsumedPrevious = 0

      let totalEnergyConsumedCostPrevious = 0

      let totalSavingsTillDateVsPrediction = 0

      let totalSavingsTillDateVsPrevious = 0

      let totalSavingsCostTillDateVsPrediction = 0

      let totalSavingsCostTillDateVsPrevious = 0

      for (let i = 0; i < actualConsumptionValues?.length; i++) {
        const timeStamp = actualConsumptionValues[i].timeStamp

        const actualEnergyValue = actualConsumptionValues[i].value
        const predictedEnergyValue = predictedEnergyValues[i].value
        const actualEnergyValuePrevious =
          actualConsumptionValuesPrevious?.[i]?.value || null
        const actualEnergyCostValue = actualConsumptionCostValues?.[i]?.value
        const predictedEnergyCostValue = predictedEnergyCostValues?.[i]?.value
        const actualEnergyValueCostPrevious =
          actualConsumptionCostValuesPrevious?.[i]?.value || null

        totalEnergyConsumed = actualEnergyValue + totalEnergyConsumed

        totalEnergyConsumedCost =
          actualEnergyCostValue + totalEnergyConsumedCost

        totalPredictedEnergy = predictedEnergyValue + totalPredictedEnergy

        totalPredictedEnergyCost =
          predictedEnergyCostValue + totalPredictedEnergyCost

        totalEnergyConsumedPrevious =
          actualEnergyValuePrevious + totalEnergyConsumedPrevious

        totalEnergyConsumedCostPrevious =
          actualEnergyValueCostPrevious + totalEnergyConsumedCostPrevious

        const predictionDelta =
          predictedEnergyValue !== null
            ? predictedEnergyValue - actualEnergyValue
            : null

        const predictionDeltaCost =
          predictedEnergyCostValue !== null
            ? predictedEnergyCostValue - actualEnergyCostValue
            : null

        totalSavingsTillDateVsPrediction =
          predictionDelta + totalSavingsTillDateVsPrediction

        totalSavingsCostTillDateVsPrediction =
          predictionDeltaCost + totalSavingsCostTillDateVsPrediction

        energySavingsVsPrediction.values[0].push({
          timeStamp,
          value: predictionDelta > 0 ? predictionDelta : null
        })

        energyOverageVsPrediction.values[0].push({
          timeStamp,
          value: predictionDelta > 0 ? null : predictionDelta
        })

        totalSavingsPredictionTillDate.values[0].push({
          timeStamp,
          value: totalSavingsTillDateVsPrediction
        })

        energyCostOverageVsPrediction.values[0].push({
          timeStamp,
          value: predictionDeltaCost > 0 ? null : predictionDeltaCost
        })

        energyCostSavingsVsPrediction.values[0].push({
          timeStamp,
          value: predictionDeltaCost > 0 ? predictionDeltaCost : null
        })

        totalSavingsCostPredictionTillDate.values[0].push({
          timeStamp,
          value: totalSavingsCostTillDateVsPrediction
        })

        const actualDelta =
          actualEnergyValuePrevious !== null
            ? actualEnergyValuePrevious - actualEnergyValue
            : null

        const actualDeltaCost =
          actualEnergyValueCostPrevious !== null
            ? actualEnergyValueCostPrevious - actualEnergyCost
            : null

        totalSavingsTillDateVsPrevious =
          actualDelta + totalSavingsTillDateVsPrevious

        totalSavingsCostTillDateVsPrevious =
          actualDeltaCost + totalSavingsCostTillDateVsPrevious

        energySavingsVsPrevious.values[0].push({
          timeStamp,
          value: actualDelta > 0 ? actualDelta : null
        })

        energyOverageVsPrevious.values[0].push({
          timeStamp,
          value: actualDelta > 0 ? null : actualDelta
        })

        totalSavingsPreviousTillDate.values[0].push({
          timeStamp,
          value: totalSavingsTillDateVsPrevious
        })

        energyCostSavingsVsPrevious.values[0].push({
          timeStamp,
          value: actualDeltaCost > 0 ? null : actualDeltaCost
        })

        energyCostOverageVsPrevious.values[0].push({
          timeStamp,
          value: actualDeltaCost > 0 ? null : actualDeltaCost
        })

        totalSavingsCostPreviousTillDate.values[0].push({
          timeStamp,
          value: totalSavingsCostTillDateVsPrevious
        })
      }

      setTotalEnergyConsumed((Math.round(totalEnergyConsumed * 1e2 ) / 1e2) || 0)
      setPredictedTotalEnergy((Math.round(totalPredictedEnergy * 1e2 ) / 1e2) || 0)
      setTotalEnergyConsumedPrevious((Math.round(totalEnergyConsumedPrevious * 1e2 ) / 1e2) || 0)

      // Energy Cost values
      setTotalEnergyConsumedCost((Math.round(totalEnergyConsumedCost * 1e2 ) / 1e2) || 0)
      setPredictedTotalEnergyCost((Math.round(totalPredictedEnergyCost * 1e2 ) / 1e2) || 0)
      setTotalEnergyConsumedPreviousCost((Math.round(totalEnergyConsumedCostPrevious * 1e2 ) / 1e2) || 0)

      return {
        baselinePrediction,
        actualConsumption,
        actualConsumptionPrevious,
        energySavingsVsPrediction,
        energyOverageVsPrediction,
        energySavingsVsPrevious,
        energyOverageVsPrevious,
        totalSavingsPredictionTillDate,
        totalSavingsPreviousTillDate,
        predictedEnergyCost,
        actualEnergyCost,
        actualEnergyCostPrevious,
        energyCostOverageVsPrediction,
        energyCostSavingsVsPrediction,
        energyCostSavingsVsPrevious,
        energyCostOverageVsPrevious,
        totalSavingsCostPredictionTillDate,
        totalSavingsCostPreviousTillDate
      }
    } catch (error) {
      setDataAvailabilityForChart(false)
      console.log(error)
    }
  }

  const checkDataAvailablity = (baselineData, temperatureData) => {
    const actualConsumption = baselineData?.find(
      (x) => x.propertyKey === energyConsumptionPropertyName
    )

    const temperatureDataAvailability = temperatureData?.values?.[0]?.some(
      (x) => x?.value?.[0] || x?.value?.[1]
    )

    const actualConsumptionAvailable = actualConsumption?.isDataAvailable

    setDataAvailabilityForChart(
      actualConsumptionAvailable || temperatureDataAvailability
    )

    return actualConsumptionAvailable
  }

  // Called when there is no meters in the building
  const equipmentsAvailableCheck = (check) => {
    setMeterAvailability(check)
  }

  useEffect(() => {
    try {
      if (currentPeriodChartData && previousPeriodChartData) {
        const temperatureData = formatMinMaxTemperatureData(
          currentPeriodChartData?.body?.data || {}
        )

        const endDate = endDateDecider(
          chartDates?.chartStartDate,
          chartDates?.chartEndDate,
          BACKEND_DATE_FORMAT
        )

        const baselineDataCurrent =
          aggrDataFormatter(
            currentPeriodChartData?.body?.agg_data || {},
            chartDates?.chartStartDate,
            endDate,
            buildingDetails?.tz || moment.tz.guess(),
            '1d'
          )?.sum || []

        const baselineDataPrevious =
          aggrDataFormatter(
            previousPeriodChartData?.body?.agg_data || {},
            moment(chartDates?.chartStartDate).subtract(1, 'years'),
            moment(endDate).subtract(1, 'years'),
            buildingDetails?.tz || moment.tz.guess(),
            '1d'
          )?.sum || []

        checkDataAvailablity(baselineDataCurrent, temperatureData)

        const calcProps = calculateConsumptionProperties(
          baselineDataCurrent,
          baselineDataPrevious
        )

        const timestamps = calcProps?.actualConsumption?.values?.[0]?.map(
          (x) => x?.timeStamp
        )

        const tempetature = temperatureData

        const calculatedChartProperties = {
          ...calcProps,
          timestamps,
          tempetature
        }
        setConsumptionChartData(calculatedChartProperties)
        setCanChartShown(true)
      }
    } catch (error) {
      setCanChartShown(true)
      setDataAvailabilityForChart(false)
      console.log(error)
    }
  }, [currentPeriodChartData, previousPeriodChartData])

  const getTimeStamps = (finalData) => {
    try {
      //values from non aggr data
      const values = finalData?.data?.[0]?.values?.[0] || []

      if (values?.length > 0) {
        return values?.map((x) => x?.timeStamp)
      } else {
        const aggrValuesObject = finalData?.aggregatedData || {}
        //values from aggr data
        for (const aggrFunctionName in aggrValuesObject) {
          const aggrValues =
            aggrValuesObject[aggrFunctionName]?.[0]?.values?.[0] || []
          return aggrValues?.map((x) => x?.timeStamp) || []
        }
      }
    } catch (error) {
      return []
    }
  }

  const getChartDataByTimeSlider = (startTime, endTime) => {
    try {
      const lineChartData = chartDataForDisplay
      if (lineChartData) {
        const data = lineChartData?.data ?? []

        const filteredata =
          data?.map((m) => ({
            ...m,
            values: m?.values?.map((v, i) =>
              v?.filter(
                (f) =>
                  new Date(f?.timeStamp).getTime() >= startTime &&
                  new Date(f?.timeStamp).getTime() <= endTime
              )
            )
          })) ?? []
        const timeSliderChartData = { data: filteredata }
        setEnergyConsumptionLineChartData({
          ...timeSliderChartData,
          timestamps: getTimeStamps(timeSliderChartData)
        })
      } else {
        setEnergyConsumptionLineChartData(null)
      }
    } catch (error) {
      setEnergyConsumptionLineChartData(null)
    }
  }

  useEffect(() => {
    const startTime = new Date(timeSliderStartTime).getTime()
    const endTime = new Date(timeSliderEndTime).getTime()
    getChartDataByTimeSlider(startTime, endTime)
  }, [timeSliderStartTime, timeSliderEndTime])

  const changeChartSelection = () => {
    if (selectedChart === chartOptions.line) {
      // As we don't have cost for 15-mins data, if the $ option is selected in unit
      // set back to other units based on type
      if (unitMappingDisplay[unit] === '$') {
        selectedEquipment?.type === 'ElectricMeter'
          ? setUnit(unitsOfMeasureOptions?.kW)
          : setUnit(unitsOfMeasureOptions?.kBTUs)
      }
      setSelectedChart(chartOptions.heat)
      trackEvent(USER_EVENTS.CHARTS.events.CHANGE_CHART, {mode:"Heat"})
    } else {
      setSelectedChart(chartOptions.line)
      trackEvent(USER_EVENTS.CHARTS.events.CHANGE_CHART, {mode:"Line"})
    }
  }

  const getUnitConvertedValues = (energyValue) => {
    const energyData = getValidValue(energyValue)
    const selectedUnit = unitMappingDisplay[unit]
    const meterType = selectedEquipment?.type
    if (
      meterType === 'ElectricMeter' &&
      selectedUnit === 'kBTUs'
    ) {
      return Number(energyData)
    } else if (
      meterType !== 'ElectricMeter' &&
      selectedUnit === 'kWh'
    ) {
      return Math.round(Number(energyData))
    } else {
      return Math.round(Number(energyData))
    }
  }

  const onChartControlUpdate = ({
    startDate,
    endDate,
    endError,
    startError,
    activeRange
  }) => {
    setChartDates({
      chartStartDate: startDate,
      chartEndDate: endDate,
      dateError: startError || endError,
      activeRange
    })
  }

  const getValidValue = (value) => {
    return isNaN(value) ? 0 : value
  }
  
  return (
    <ContainerWrapper
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding="0"
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <PageHeader
          pageTitle={<TranslateComponent>{PAGE_TITLE}</TranslateComponent>}
          titleTools={
            <ToolBarWrapper>{/* ActionToolBar Here */}</ToolBarWrapper>
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        border="none"
      >
        {buildingId ? (
          meterAvailability ? (
            <>
              <EnergyConsumptionContainerDiv>
                <EquipmentListSelector
                  testName="energy-consumption"
                  equipmentTypes={['GAS', 'ElectricMeter', 'VirtualMeter']}
                  buildingId={buildingId}
                  onChange={onEquipmentChange}
                  equipmentsAvailableCheck={equipmentsAvailableCheck}
                  defaultEquipmentId={equipment}
                />
                <Block
                  display="flex"
                  align="flex-end"
                  justify="space-between"
                  padding="5px 10px"
                  borderBottom={`2px solid ${colors.TBA_DARKER_GREY}`}
                >
                  <Block>
                    <Block display="flex">
                      <ChartSelectButton
                        testName="energy-consumption-line"
                        onClick={changeChartSelection}
                        isSelected={selectedChart === chartOptions.line}
                      >
                        <span className="icon icon-linewidget" />
                        <span className="chart-select-name">LINE</span>
                      </ChartSelectButton>
                      <ChartSelectButton
                        testName="energy-consumption-heat"
                        onClick={changeChartSelection}
                        isSelected={selectedChart === chartOptions.heat}
                      >
                        <span className="icon icon-heat-map" />
                        <span className="chart-select-name">HEAT</span>
                      </ChartSelectButton>
                    </Block>
                  </Block>

                  <Block display="flex" position="relative">
                    {compareTo === 'baseline' && (
                      <HeaderResultBlock testName="energy-consumption-total">
                        <span className="title">Total Predicted Energy</span>
                        {(!canChartShown || selectedEquipment === null) ? <span className="spinner" /> : <>
                          <span className="value">
                            {unitMappingDisplay[unit] === '$' && (
                              <span className="unit dollar">
                                {unitMappingDisplay[unit]}
                              </span>
                            )}
                            <span>
                              {Number(
                                getUnitConvertedValues(
                                  unitMappingDisplay[unit] === '$'
                                    ? predictedTotalEnergyCost
                                    : predictedTotalEnergyCost
                                )
                              )}
                            </span>
                            {unitMappingDisplay[unit] !== '$' && (
                              <span className="unit">
                                {unitMappingDisplay[unit]}
                              </span>
                            )}
                          </span>
                        </>}

                      </HeaderResultBlock>
                    )}
                    {compareTo === 'previous year' && (
                      <HeaderResultBlock testName="energy-consumption-total">
                        <span className="title">previous year</span>
                        {(!canChartShown || selectedEquipment === null) ? <span className="spinner" /> : <>
                          <span className="value">
                            {unitMappingDisplay[unit] === '$' && (
                              <span className="unit dollar">
                                {unitMappingDisplay[unit]}
                              </span>
                            )}
                            <span>
                              {Number(
                                getUnitConvertedValues(
                                  unitMappingDisplay[unit] === '$'
                                    ? totalEnergyConsumedPreviousCost
                                    : totalEnergyConsumedPrevious
                                )
                              )}
                            </span>
                            {unitMappingDisplay[unit] !== '$' && (
                              <span className="unit">
                                {unitMappingDisplay[unit]}
                              </span>
                            )}
                          </span>
                        </>}

                      </HeaderResultBlock>
                    )}

                    <HeaderResultBlock
                      testName="energy-consumption-total"
                      color={totalSavingsOrOverage?.color}
                    >
                      <span className="title">Total Energy Consumed</span>
                      {(!canChartShown || selectedEquipment === null) ? <span className="spinner" /> : <>
                        <span className="value">
                          {unitMappingDisplay[unit] === '$' && (
                            <span className="unit dollar">
                              {unitMappingDisplay[unit]}
                            </span>
                          )}
                          <span>
                            {Number(
                              getUnitConvertedValues(
                                unitMappingDisplay[unit] === '$'
                                  ? totalEnergyConsumedCost
                                  : totalEnergyConsumed
                              )
                            )}
                          </span>
                          {unitMappingDisplay[unit] !== '$' && (
                            <span className="unit">
                              {unitMappingDisplay[unit]}
                            </span>
                          )}
                        </span>
                      </>}

                    </HeaderResultBlock>
                  </Block>
                </Block>
              </EnergyConsumptionContainerDiv>
              <ChartAreaWrapper>
                <ChartControlContainer>
                  <div>
                    <ChartDateControls
                      testName="energy-consumption"
                      onUpdate={onChartControlUpdate}
                      rangesList={['1M', '3M', '6M', 'YTD', '1Y']}
                      entity="energy-consumption"
                      endDisableAfterGivenDate={moment().subtract(1, 'days')}
                      startDisableAfterGivenDate={moment().subtract(1, 'days')}
                      startDate={chartDates.chartStartDate}
                      activeRange={chartDates?.activeRange}
                      endDate={chartDates.chartEndDate}
                    />
                    {!loadingEquipmentDetails && (
                      <DataRangeAvailableContainer>
                        Data range available: {dataAvailabilityDates?.startDate}
                        - {dataAvailabilityDates?.endDate}
                      </DataRangeAvailableContainer>
                    )}
                  </div>

                  <div className="chart-settings">
                    <ChartSettings testName="energy-consumption">
                      {selectedChart === chartOptions.line ? (
                        <div className="control-group">
                          <div className="unit-picker bottom-space">
                            <label>{'Compared To'}:</label>
                            <Select
                              testName="energy-consumption-compare"
                              options={compareToOptions}
                              onChange={updateCompareTo}
                              selectedItem={compareTo}
                            />
                          </div>
                          <div className="unit-picker bottom-space">
                            <label>{'Units'}:</label>
                            <Select
                              testName="energy-consumption-unit"
                              options={unitsOfMeasureOptions}
                              onChange={updateUOM}
                              selectedItem={unit}
                            />
                          </div>
                          {compareTo !== 'none' && (
                            <div>
                              <span className="variance-label">Variance:</span>
                              <span>{`${getValidValue(totalSavingsOrOverage?.variancePercentage)}% (${getValidValue(totalSavingsOrOverage?.variance)})`}</span>
                            </div>
                          )}

                          {selectedEquipmentBaselineDetails && (
                            <div>
                              <span className="equipment-name">
                                {selectedEquipment?.value}
                              </span>
                              <span className="baseline-date">
                                {
                                  selectedEquipmentBaselineDetails?.baselineStartDate
                                }
                                -{' '}
                                {
                                  selectedEquipmentBaselineDetails?.baselineEndDate
                                }
                              </span>
                              <span>
                                Edit this baseline on the{' '}
                                <Link to="/baseline-library">
                                  Baseline library
                                </Link>{' '}
                                page
                              </span>
                            </div>
                          )}
                          {!selectedEquipmentBaselineDetails && (
                            <div>
                              <span className="equipment-name">
                                {selectedEquipment?.value}
                              </span>
                              <span>
                                Create a baseline on the{' '}
                                <Link to="/baseline-library">
                                  Baseline Libary
                                </Link>{' '}
                                page.
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="control-group">
                          <div className="unit-picker bottom-space">
                            <label>{'Units'}:</label>
                            <Select
                              testName="energy-consumption-unit"
                              options={unitsOfMeasureOptions}
                              onChange={updateUOM}
                              selectedItem={unit}
                            />
                          </div>
                        </div>
                      )}
                    </ChartSettings>
                  </div>
                </ChartControlContainer>

                {selectedChart === chartOptions.line ? (
                  !canChartShown ? (
                    <Spinner />
                  ) : (
                    <EnergyConsumptionLineChart
                      testName="energy-consumption-chart"
                      width={width - 390}
                      chartData={energyConsumptionLineChartData}
                      timeSliderChartData={chartDataForDisplay}
                      isDataAvailableForChart={dataAvailablityForChart}
                      eventMarkers={markers}
                      compareTo={compareTo}
                      unit={unitMappingDisplay[unit]}
                      meterType={selectedEquipment?.type}
                      chartDates={chartDates}
                      setTimeSliderStartTime={setTimeSliderStartTime}
                      setTimeSliderEndTime={setTimeSliderEndTime}
                      energyConsumptionPropertyName={
                        energyConsumptionPropertyName
                      }
                    />
                  )
                ) : loadingChartData ? (
                  <Spinner />
                ) : (
                  <EnergyConsumptionHeatMapChart
                    chartData={{ data: heatMapChartData }}
                    unit={unitMappingDisplay[unit]}
                    meterType={selectedEquipment?.type}
                  />
                )}
              </ChartAreaWrapper>
            </>
          ) : (
            <NoDataMessage>
              No meters available in the selected building.
            </NoDataMessage>
          )
        ) : (
          organizationId && (
            <Block display="flex" justify="center">
              <WarningContainer>
                <Icon
                  name="Warning"
                  width="25px"
                  height="25px"
                  margin="0px 10px"
                  color={colors.RED}
                />
                <p>
                  {
                    <TranslateComponent>
                      You have chosen an organization, please specify a
                      location.
                    </TranslateComponent>
                  }
                </p>
              </WarningContainer>
            </Block>
          )
        )}
      </Content>
    </ContainerWrapper>
  )
}

export default withFeatureRestrictions(
  EnergyConsumptionContainer
)
