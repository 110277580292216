import styled, { css } from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'

export const AutomatedSettingsListContainer = styled.div`
  .automated-tests-settings-list {
    tr th:last-child(-1) {
      background: red;
      text-align: center;
    }

    td {
      padding: 2px 0px;
    }

    td: first-child {
      padding-left: calc(16px);
      padding-right: calc(16px);
      text-align: left;
      text-wrap: wrap;
      
    }

    td: last-child {
      padding-right: calc(16px);
    }

    th: nth-last-child(2) {
      text-align: center;
    }

    td {
      text-align: center;
    }

    button {
      border: none;
      margin-left: 10px;
      // height: fit-content;
    }
  }
`

export const AtsSearchAndFilter = styled.div`
  display: flex;
  margin: 0;

  .select-block {
    padding-left: 0;
    margin-right: 20px;

    .active-item-container {
      padding-bottom: 10px;
    }
  }
`

// Modal styles

export const TestSettingsModalContainer = styled.div`
  background: white;
  max-height: 500px;
  margin: 1px;
  padding: 10px;
  overflow: auto;
`

export const InformationText = styled.span`
  font-style: italic;
  font-weight: 400;
`

export const Title = styled.p`
  font-size: 15px;
  display: inline-block;
  flex-shrink: 0;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 15px;
  margin-bottom: 3px;
  padding-right: 6px;
`

export const SubTitle = styled.p`
  display: inherit;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.26px;
  margin-bottom: 2px;
  line-height: 15px;
  padding-right: 6px;
  text-transform: capitalize;
  ${(props) =>
    props?.noCapitals === true &&
    css`
      text-transform: none;
    `}
`

export const SettingModalHeaderContainer = styled.div`
  display: flex;

  fieldset {
    margin: 0;
    padding: 0;
  }

  .header-item {
    margin: 5px 0px;
    padding-right: 20px;
  }

  .suppression-edit-spinner {
    height: 30px;
    border: none;
    width: 80px;
    span {
      font-size: 10px;
    }
  }
`

export const CPTypeSelector = styled.div`
  .select-wrapper {
    max-width: 280px;
  }
  border-top: 1px solid lightgray;
  margin: 10px 5px;
  padding: 10px 5px;
  border-bottom: 1px solid lightgray;
`

export const ControlsSection = styled.div`
  ${(props) =>
    props?.isSuppressed &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  margin: 5px 0px;

  button {
    border: none;
    margin-left: 10px;
  }
`

export const ControlContainer = styled.div`
  ${(props) =>
    props?.controlType === 'parameterEdit' &&
    css`
      display: flex;
      width: fit-content;
      align-items: center;
    `}
  border: 1px solid darkgray;
  padding: 10px;
  border-radius: 5px;
  user-select: none;

  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input {
    width: 50px;
    margin-right: 5px;
    -webkit-appearance: none;
    ${(props) =>
      props?.isModified &&
      css`
        background: #ffff9a;
      `}
  }

  textarea {
    width: 100%;
  }

  .comments {
    width: 100%;
  }

  .units {
    font-weight: 700;
    text-transform: capitalize;
    margin-left: 5px;
  }

  .icon-infocircle {
    margin: 5px;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0px;
  min-height: 30px;

  ${(props) =>
    props.justfifyContent &&
    css`
      justify-content: ${props?.justfifyContent};
    `}
`

export const RangeInfoText = styled.span`
  color: #666;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 12px;
  margin-left: 5px;
`

export const TooltipContainer = styled.div`
  display: flex;
`

export const ValidationErrorContainer = styled.div`
  color: red;
  font-weight: 500;
  height: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  .error {
    font-size: 13px;
  }
`

export const ApplyNewChangesNextButton = styled.div`
  margin-top: 5;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const TextInput = styled.input`
  ${(props) =>
    props?.hasError
      ? css`
          border: 2px solid red;
        `
      : ''}
`

export const LevelIdenfifier = styled.span`
  color: #666;
  font-style: italic;
  margin-left: 12px;
`

export const SettingsAccordionContainer = styled.div`
  margin-top: 13px;

  .settings-items {
    padding-left: 10px;
    margin-top: 5px;
    padding-bottom: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
  }

  .settings-items-comments {
    padding: 0;
    margin: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  button {
    border: none;
    margin-left: 10px;
  }

  .icon-expand {
    margin-right: 5px;
    transition-property: transform;
    transition-duration: 0.4s;
  }

  .closed {
    transform: rotate(0deg);
  }

  .open {
    transform: rotate(180deg);
  }
`

export const ParameterEditContainer = styled.div`
  border: 1px solid darkgray;
  padding: 2px;
`

export const SettingsNavigateButton = styled.div`
  display: flex;

  height: 30px;
  margin-top: 20px;
  width: fit-content;
  align-items: center;
  color: white;
  padding: 10px;
  font-size: 14px;

  &.next-button {
    background-color: green;
    float: right;
  }

  &.back-button {
    background: gray;
    float: left;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .next {
    transform: rotate(260deg);
    margin-left: 5px;
  }

  .back {
    transform: rotate(90deg);
    margin-right: 5px;
  }
`

export const GridRow = styled.div`
  ${(props) =>
    props?.header
      ? css`
          background: green;
          color: white;
        `
      : css`
          background: white;
          color: black;
        `}
  display: grid;
  grid-template-columns: auto 200px 300px;
  border: 1px solid darkgray;
  border-bottom: none;

  display: grid;
  min-height: 30px;
  text-align: left;
  padding: 2px 10px;

  .header {
    font-weight: 700;
  }

  :nth-child(2n + 1) {
    background-color: #f1f0f0;
  }

  :last-child {
    border-bottom: 1px solid darkgray;
  }

  :first-child {
    margin-top: 10px;
  }
`

export const SuppressionEditContainer = styled.div`
  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

export const RadioGroupContainer = styled.div`
  fieldset {
    margin: 0;
    padding: 0;
  }
  .entity-radio-group {
    display: inline;
  }
`

export const VerticalLine = styled.div`
  min-height: 40px;
  border-right: 1px solid gray;
  width: 10px;
  margin: 10px;
`

export const AutomatedTestSettingsWrapper = styled.div`
  button {
    border: none;
  }

  .parameters-header {
    &__title {
      font-size: 15px;

      .custom-label {
        font-size: 15px;
      }
    }
    & .test-name-wrapper {
      .title {
        & .conditional-tooltip {
          display: inline-flex;
          margin-left: 5px;
          font-size: 13px;
          cursor: pointer;
        }
      }
    }

    &__test-name {
      max-width: 200px;
    }

    &__divider {
      padding-right: 7px;
    }
  }
  .italic {
    font-style: italic;
  }
  .light {
    font-weight: 300;
  }
  .custom-label + fieldset {
    margin-top: 0;
  }
  &.wide-modal {
    width: 860px;
  }
  .modal-container {
    &.is-gray {
      .modal-header {
        min-height: 40px;
        height: auto;
        padding: 0 30px 0 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .modal-panel {
        padding: 0;
        background-color: transparent;
        .panel {
          margin: 0;
          padding: 6px 6px 6px 12px;

          .scroll {
            overflow-x: hidden;
            overflow-y: auto;
            max-height: calc(100vh - 360px);

            &::-webkit-scrollbar-track:vertical {
              border: 1px solid $tba-dark-grey;
            }
          }

          h3 {
            font-weight: bold;
            font-size: 16px;
            margin: 5px 0 0 5px;
            i {
              font-weight: normal;
            }
          }
          h4 {
            margin: 0;
            font-size: 13px;

            &.disabled {
              color: $tba-dark-grey;
            }
          }
          hr {
            border: none;
            height: 1px;
            background-color: ${colors.TBA_LIGHT_GREY};

            &.section-divider {
              margin: 10px 0;
            }
          }

          .shift-to-top {
            margin-top: -5px;
          }

          .flex-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .grow {
              flex: 1 1 auto;
            }
            .right {
              align-self: flex-end;
            }
          }
          .space-between {
            justify-content: space-between;
          }
          .modal-select-wrapper {
            & + .modal-radiogroup-wrapper {
              margin-left: 20px;
            }
            .select-wrapper {
              width: 250px;
              .modal-select-input {
                width: auto;
              }
            }
          }
          .modal-radiogroup-wrapper .radio-group li {
            margin-bottom: 0;
          }
          .modal-text-wrapper {
            width: auto;
            .modal-text-input {
              width: auto;
            }
          }
          .modal-textarea-wrapper {
            margin-top: 10px;
          }
          .apply-to-options {
            margin-top: 10px;

            .options-list {
              .custom-label {
                padding-right: 0;
              }
              .select-block {
                margin-bottom: 8px;
                justify-content: flex-start;
              }
              .rs-toggle {
                max-height: none;
              }
            }

            .modal-radiogroup-wrapper .radio-group {
              border-right: 1px solid ${colors.TBA_DARKER_GREY};
              padding-right: 20px;
            }
          }
          .modal-text-input.modified {
            background-color: $yellow-1;
          }
          .modal-text-input.invalid-error {
            background: $pink;
            border: 2px solid $red;
          }
          .limits .modal-text-wrapper .modal-text-input {
            width: 50px;
          }
          .run-times .invalid-error-text {
            font-family: 'Lato', sans-serif;
            font-weight: bold;
            font-style: italic;
            font-size: 13px;
            color: #d52b23;
            letter-spacing: 0.34px;
            line-height: 15px;
          }

          .run-times .message {
            color: rgb(102, 102, 102);
            font-size: 12px;
            font-family: 'Lato', sans-serif;
            font-weight: bold;
            font-style: italic;
            letter-spacing: 0.2px;
            line-height: 12px;
          }

          .run-times .modal-text-wrapper,
          .run-times .modal-time-wrapper,
          .ranges .modal-text-wrapper {
            clear: both;
            margin-left: 0;
            margin-bottom: 15px;
            .modal-text-input {
              width: 40px;
            }
          }
          .modal-block.run-times .input-field {
            .modal-text-wrapper,
            .modal-time-wrapper {
              margin-bottom: 5px;
            }
            margin-bottom: 15px;
          }
          .select-dropdown {
            width: 100%;
          }
          .select-block {
            float: left;
            margin-top: 20px;
          }
          .modal-block {
            padding: 10px 10px 0;
            background-color: white;
            border-radius: 4px;
            border: 1px solid $tba-dark-grey;
            position: relative;
            width: 100%;
            &.limits,
            &.run-times.basic {
              .custom-layout {
                display: flex;
                .modal-select-wrapper {
                  margin-right: 20px;
                }
                .input-block {
                  &::before {
                    content: '';
                    display: block;
                    border-left: solid 1px ${colors.TBA_LIGHT_GREY};
                    width: 20px;
                  }
                  flex: 1 1 auto;
                  margin-bottom: 10px;
                }
              }
            }
            &.run-times {
              margin-bottom: 15px;
              .input-block {
                display: flex;
                .modal-time-wrapper {
                  margin-right: 50px;
                  margin-top: 10px;
                }
              }
            }
            &.limit {
              border: none;
              border-radius: 0;
              border-bottom: 1px solid $tba-dark-grey;
              margin-top: 0;
              display: flex;
              flex-flow: row wrap;
              h4,
              .range-selector-wrapper {
                flex: 0 0 100%;
              }
              .modal-text-wrapper {
                flex: 0 0 40%;
                &:nth-child(2n) {
                  margin-right: 10px;
                }
              }
              &:last-child {
                border-bottom: none;
                padding-bottom: 10px;
              }
            }
          }

          .generic-empty-table {
            height: auto;

            .generic-empty-table__heading {
              font-size: 13px;
            }
          }

          .half {
            display: flex;
            flex-direction: column;
            float: left;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 50%;
            & + .half {
              padding-right: 0;
            }
            .modal-block {
              height: 100%;
              padding: 6px 12px;
            }
          }

          .toggle-box-container {
            margin-top: 10px;
          }

          fieldset:last-child {
            padding-bottom: 0;
          }
        }

        .modal-button-row {
          padding: 0;
          border: none;

          .modal-button {
            margin-top: 20px;
            margin-bottom: 0;
          }
        }
      }
      .content {
        border: 0;
      }
      .item-label {
        display: none;
      }
      button.marker-icon {
        background: transparent;
        border: transparent;
      }
    }
    .modal-button-row {
      justify-content: flex-start;
      .modal-button.type-reset {
        background-color: $tba-medium-grey;
        color: $white;
        margin-left: 0;
        margin-right: auto;

        &:hover {
          background-color: $tba-dark-grey;
        }
        &:active {
          background-color: ${colors.TBA_DARKER_GREY};
        }
      }
      .modal-button.type-confirm {
        margin-left: auto;
      }
    }
  }

  .toggle-box-label__advanced-settings {
    display: flex;

    .conditional-tooltip {
      margin-left: 5px;
    }
  }
`

export const LimitsWrapper = styled.div`
  .modal-block {
    padding: 10px 10px 0;
    background-color: white;
    border-radius: 4px;
    border: 1px solid ${colors.TBA_DARKER_GREY};
    position: relative;
    width: 100%;
    &.limits, &.run-times.basic {
      .custom-layout {
        display: flex;
        .modal-select-wrapper {
          margin-right: 20px;
        }
        .input-block {
          &::before {
            content: "";
            display: block;
            border-left: solid 1px ${colors.TBA_LIGHT_GREY};
            width: 20px;
          }
          flex: 1 1 auto;
          margin-bottom: 10px;
        }
      }
    }
    &.run-times {
      margin-bottom: 15px;
      .input-block {
        display: flex;
        .modal-time-wrapper {
          margin-right: 50px;
          margin-top: 10px;
        }
      }
    }
    &.limit {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid ${colors.TBA_DARK_GREY};
      margin-top: 0;
      display: flex;
      flex-flow: row wrap;
      h4,
      .range-selector-wrapper {
        flex: 0 0 100%;
      }
      .modal-text-wrapper{
        flex: 0 0 40%;
        margin-bottom: 0px;
        .custom-label{
          padding: 0px;
        }
        &:nth-child(2n) {
          margin-right: 10px;
        }
      }
      &:last-child {
        border-bottom: none;
        padding-bottom: 10px;
      }
    }
  }

  .generic-empty-table {
    height: auto;

    .generic-empty-table__heading {
      font-size: 13px;
    }
  }

  .half {
    display: flex;
    flex-direction: column;
    float: left;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
    & + .half {
      padding-right: 0;
    }
    .modal-block {
      height: 100%;
      padding: 6px 12px;
    }
  }

  .toggle-box-container {
    margin-top: 10px;
  }

  fieldset:last-child {
    padding-bottom: 0;
  }
  }

  .modal-button-row {
  padding: 0;
  border: none;

  .modal-button {
    margin-top: 20px;
    margin-bottom: 0;
  }
  }
  }
  .limits .modal-text-wrapper .modal-text-input {
    width: 50px;
  }
  button.marker-icon {
    background: transparent;
    border: transparent;
  }
`

export const SuppressionNoteText = styled.span`
font-size: 10px;
display: inline-block;
opacity: 1;`
