import { StyleSheet, View, Text, Link } from "@react-pdf/renderer"
import redraft from "redraft"
import get from "lodash/get"
import colors from "../../legacy/common/colors.json"

const styles = StyleSheet.create({
	heading: {
		marginBottom: 4,
		fontWeight: 700,
		lineHeight: 1.35
	},
	text: {
		marginBottom: 8,
		lineHeight: 1.45,
	},
	list: {
		marginBottom: 8,
		marginLeft: 6,
	},
	listItem: {
		marginBottom: 4,
	},
	listItemText: {
		lineHeight: 1.45,
	},
	fontSize: {
		fontSize: 10
	}
})

const Heading = ({ children, textAlign, fontSize }) => <View>
	<Text style={{ fontSize, textAlign, ...styles.heading }}>{children}</Text>
</View>

const UnorderedList = ({ children, depth, key }) => <View style={styles.list}>{children}</View>

const UnorderedListItem = ({ children, textAlign }) => <View style={styles.listItem}>
	<Text style={{ textAlign, ...styles.listItemText, ...styles.fontSize }}>
		• &nbsp;<Text>{children}</Text>
	</Text>
</View>

const OrderedList = ({ children, depth }) => <View style={styles.list}>{children}</View>

const OrderedListItem = ({ children, index, textAlign }) => <View style={styles.listItem}>
	<Text style={{ textAlign, ...styles.listItemText, ...styles.fontSize }}>
		{index + 1}. &nbsp;<Text>{children}</Text>
	</Text>
</View>

const FontSize = ({ children, fontSize }) => <Text style={{ fontSize }}>{children}</Text>

const renderers = {
	inline: {
		BOLD: (children, { key }) => <Text key={`bold-${key}`} style={{ fontWeight: 700, color: colors.BLACK }}>{children}</Text>,
		ITALIC: (children, { key }) => <Text key={`italic-${key}`} style={{ fontStyle: "italic" }}>{children}</Text>,
		UNDERLINE: (children, { key }) => <Text key={`underline-${key}`} style={{ textDecoration: "underline" }}>{children}</Text>,
		STRIKETHROUGH: (children, { key }) => <Text key={`strikethrough-${key}`} style={{ textDecoration: "line-through" }}>{children}</Text>,
		"fontsize-8": (children, { key }) => <FontSize fontSize={8} key={`strikethrough-${key}`}>{children}</FontSize>,
		"fontsize-9": (children, { key }) => <FontSize fontSize={9} key={`strikethrough-${key}`}>{children}</FontSize>,
		"fontsize-10": (children, { key }) => <FontSize fontSize={10} key={`strikethrough-${key}`}>{children}</FontSize>,
		"fontsize-11": (children, { key }) => <FontSize fontSize={11} key={`strikethrough-${key}`}>{children}</FontSize>,
		"fontsize-12": (children, { key }) => <FontSize fontSize={12} key={`strikethrough-${key}`}>{children}</FontSize>,
		"fontsize-14": (children, { key }) => <FontSize fontSize={14} key={`strikethrough-${key}`}>{children}</FontSize>,
		"fontsize-16": (children, { key }) => <FontSize fontSize={16} key={`strikethrough-${key}`}>{children}</FontSize>,
		"fontsize-18": (children, { key }) => <FontSize fontSize={18} key={`strikethrough-${key}`}>{children}</FontSize>,
		"fontsize-24": (children, { key }) => <FontSize fontSize={24} key={`strikethrough-${key}`}>{children}</FontSize>
	},
	blocks: {
		unstyled: (children, { keys, data }) => children.map((child, index) => {
			const textAlign = get(data[index], "text-align")
			return (<View key={keys[index]}>
				<Text style={{ textAlign, ...styles.text, ...styles.fontSize }}>{child}</Text>
			</View>)
		}),
		"header-one": (children, { keys, data }) => children.map((child, index) => <Heading key={keys[index]} textAlign={get(data[index], "text-align")} fontSize={22}>{child}</Heading>),
		"header-two": (children, { keys, data }) => children.map((child, index) => <Heading key={keys[index]} textAlign={get(data[index], "text-align")} fontSize={18}>{child}</Heading>),
		"header-three": (children, { keys, data }) => children.map((child, index) => <Heading key={keys[index]} textAlign={get(data[index], "text-align")} fontSize={16}>{child}</Heading>),
		"header-four": (children, { keys, data }) => children.map((child, index) => <Heading key={keys[index]} textAlign={get(data[index], "text-align")} fontSize={12}>{child}</Heading>),
		"header-five": (children, { keys, data }) => children.map((child, index) => <Heading key={keys[index]} textAlign={get(data[index], "text-align")} fontSize={10}>{child}</Heading>),
		"header-six": (children, { keys, data }) => children.map((child, index) => <Heading key={keys[index]} textAlign={get(data[index], "text-align")} fontSize={8}>{child}</Heading>),
		"unordered-list-item": (children, { depth, keys, data }) => <UnorderedList key={keys[keys.length - 1]} depth={depth}>
			{children.map((child, index) => {
				const textAlign = get(data[index], "text-align")
				return <UnorderedListItem key={keys[index]} textAlign={textAlign}>{child}</UnorderedListItem>
			})}
		</UnorderedList>,
		"ordered-list-item": (children, { depth, keys, data }) => <OrderedList key={keys.join("|")} depth={depth}>
			{children.map((child, index) => {
				const textAlign = get(data[index], "text-align")
				return <OrderedListItem key={keys[index]} index={index} textAlign={textAlign}>{child}</OrderedListItem>
			})}
		</OrderedList>
	},
	entities: {
		LINK: (children, data, { key }) => (
			<Link key={key} src={data.url}>
				{children}
			</Link>
		),
	},
}

const PDFRichText = ({ note }) => redraft(note, renderers, { blockFallback: "unstyled" })

export default PDFRichText
