import { useMemo, useState, useEffect, useRef } from 'react'
// components
import Modal from 'src/components/legacy/components/modal/modal'
import Spinner from '../../components/legacy/components/spinner/spinner'
import { getSession } from 'src/redux/slicers/appData'
import {
  StrongText,
  SecionHeader,
  LinkText,
  OpenVPNModalContainer
} from './styles'
import { DEVICE_CONNECTION } from './index'
import copy from 'copy-to-clipboard'
import Icon from 'src/denali-ui/components/Icon/index'
import colors from 'src/components/legacy/common/colors.json'
import { useMutation } from 'src/hooks/APIHooks'
import translate, { TranslateComponent } from '../../common/translations'

export const GET_PRE_SIGNED_URL = /* GraphQL */ `
  mutation GET_PRE_SIGNED_URL($input: AWSJSON!) {
    getPreSignedUrl(body: $input)
  }
`

const POLLING_TIME = 1000

const INSTALLER_VERSION = '2.7.111'

export const OpenVPNModals = ({
  connectivityDetails,
  toggleOpenVPNModal,
  connectedTimestamp,
  deviceConnectionStatus,
  downloadInstallerHandler,
  disconnectDevice,
  refreshOpenVPNButtonClick,
  loading
}) => {
  const [fileLoading, setFileLoading] = useState(false)
  const [connectedTime, setConnectedTime] = useState(connectedTimestamp)

  const optionsTranslatedForDownload = {
    openVpn: translate('Open the Open VPN application'),
    select: translate('Select'),
    connectAs: translate('Connect as '),
    clickRefresh: translate('Click on this refresh button '),
    refresh: translate('Refresh '),
    after: translate(' after 10 seconds.'),
    connect : translate("Connect to Open VPN"),
  }

  const optionsTranslatedForNoConnection = {
    deviceDetails: translate('Device details'),
    orHigher: translate(' or higher'),
  }

  const { onSubmit: getPreSignedUrlMutation } = useMutation({
    query: GET_PRE_SIGNED_URL
  })

  const [tokenCopiedToClipboard, setTokenCopiedToClipboard] = useState(false)

  const [ipCopiedToClipboard, setIpCopiedToClipboard] = useState(false)

  let intervalId = useRef(null)

  const formatDuration = (seconds) => {
    if (isNaN(seconds)) {
      return '00:00:00' // Return a default value if the input is not a number
    }
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secondsRemaining = seconds % 60
    return [hours, minutes, secondsRemaining]
      .map((unit) => String(unit).padStart(2, '0'))
      .join(':')
  }

  const increaseConnectionTimer = () => {
    setTimeout(() => {
      const duration = connectedTime + 1
      setConnectedTime(duration)
    }, POLLING_TIME)
  }

  useEffect(() => {
    if (connectedTime !== null) {
      increaseConnectionTimer()
    }
  }, [connectedTime])

  const copyTokenToClipBoard = async () => {
    const token = await getSession(true)
    copy(token)
    setTokenCopiedToClipboard(true)
  }

  const downloadButtonClick = () => {
    downloadInstallerHandler()
  }

  useEffect(() => {
    if (
      deviceConnectionStatus === DEVICE_CONNECTION.tunnelEstablished &&
      connectedTimestamp !== null
    ) {
      setConnectedTime(connectedTimestamp || 0)
    }
  }, [connectedTimestamp, deviceConnectionStatus])

  const downloadInstallerAction = async () => {
    try {
      setFileLoading(true)
      return await getPreSignedUrlMutation({
        input: `{"action": "DOWNLOAD", "key": "TraneConnectInstaller.exe", "buildingId": "assets" }`
      }).then(async (res) => {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
          headers: { 'Content-Type': '' }
        }
        return await fetch(
          JSON.parse(res.data.getPreSignedUrl).body,
          requestOptions
        )
          .then(async (response) => {
            const responseBlob = await response.blob()
            setFileLoading(false)
            return saveAs(responseBlob, 'TraneConnectInstaller.exe')
          })
          .catch((error) => console.log('error', error))
      })
    } catch (error) {
      setFileLoading(false)
      console.log('installer download error')
    }
  }

  const UserConnectedToDifferentServer = () => {
    return <NoConnectionEstablished differentServerConnected={true} />
  }

  const DownloadModalContent = () => {
    return (
      <div>
        <StrongText>
          <TranslateComponent>For your security, there is a new process to download and access the installer.</TranslateComponent>
          <br />
          <TranslateComponent>To successfully update your installer:</TranslateComponent>
        </StrongText>
        <p></p>
        <ol>
          <li><TranslateComponent>From your Windows Programs, Access Acecto Defendpoint Programs and Features Uninstall any instance of Open VPN Client or Trane Connect Client.</TranslateComponent></li>
          <li>
            <LinkText onClick={copyTokenToClipBoard}>
              <TranslateComponent>Copy the installer Key</TranslateComponent>
            </LinkText>
            {tokenCopiedToClipboard && (
              <span style={{ color: 'green' }}>
                <strong><TranslateComponent>Key Copied to Clipboard..!!</TranslateComponent></strong>
              </span>
            )}
          </li>
          <li>
            <div>
              <button
                type="text"
                className="download-installer"
                onClick={() => {
                  downloadInstallerAction()
                }}
              >
                <LinkText><TranslateComponent>Download the Trane Connect Installer</TranslateComponent></LinkText>
              </button>
              {fileLoading && (
                <Spinner className="installer-download-spinner" />
              )}
              <TranslateComponent>to a known location on the PC.</TranslateComponent>
            </div>
          </li>
          <li><TranslateComponent>Access the Download location and Right-click on "TraneConnectInstaller.exe" file. Select "Run Elevated with Defendpoint"</TranslateComponent></li>
          <li><TranslateComponent>During the installation process, you will be prompted to enter the Installer Key that is copied to your clipboard. Paste that Installer Key into the field and proceed.</TranslateComponent></li>
        </ol>
        <p className="steps-title"><TranslateComponent>Once the installer has been downloaded and authenticated...</TranslateComponent></p>
        <ol>
          <li>{optionsTranslatedForDownload.openVpn}</li>
          <li>
            {optionsTranslatedForDownload.select}{' '}
            <strong>{connectivityDetails?.hostName}</strong>
          </li>
          <li>
            {optionsTranslatedForDownload.connectAs}{' '}
            <strong>{connectivityDetails?.userMail}</strong>
          </li>
          <li>
            {optionsTranslatedForDownload.clickRefresh}{' '}
            <button
              style={{
                color: 'white',
                background: 'green',
                padding: '2px 4px'
              }}
              onClick={refreshOpenVPNButtonClick}
            >
              {optionsTranslatedForDownload.refresh}
            </button>{' '}
            {optionsTranslatedForDownload.after}
          </li>
        </ol>
      </div>
    )
  }

  const copyToClipboard = () => {
    try {
      setIpCopiedToClipboard(true)
      copy(connectivityDetails?.ipAddress)
    } catch (e) {}
  }

  const NoConnectionEstablished = ({ differentServerConnected = false }) => {
    return (
      <>
        {differentServerConnected && (
          <div>
            <p className="padding-5 font-13">
              {optionsTranslatedForNoConnection.deviceDetails}
            </p>
            <span className="padding-5 font-13">
              {connectivityDetails?.deviceName &&
                `${connectivityDetails?.deviceName} | `}
            </span>
            <span className="font-13">{connectivityDetails?.serialNumber}</span>
          </div>
        )}
        <span className="icon icon-unavailable-x" />
        <span className="padding-5 connection-status-text">
          <TranslateComponent>No Connection established</TranslateComponent>
        </span>
        {differentServerConnected && (
          <div style={{ marginTop: 5, color: colors.RED }}>
            <TranslateComponent>You are currently connected to a different server in the Open VPN application. To connect to this device's server, disconnect the existing server in Open VPN and connect using the</TranslateComponent>
            <span style={{ color: 'green' }}>
              {connectivityDetails?.hostName}
            </span>
            <TranslateComponent>server.</TranslateComponent>
          </div>
        )}
        <div>
          <p className="steps-title">
            <TranslateComponent>Steps to make Open VPN Connection:</TranslateComponent>
          </p>
          <ol>
            <li>
              <TranslateComponent>{`Open the Open VPN application - must use version ${INSTALLER_VERSION} or higher`}</TranslateComponent>{' '}
            </li>
            <li>
              {optionsTranslatedForDownload.select}{' '}
              <strong>{connectivityDetails?.hostName}</strong>
            </li>
            <li>
              {optionsTranslatedForDownload.connectAs}{' '}
              <strong>{connectivityDetails?.userMail}</strong>
            </li>
            <li>
              {optionsTranslatedForDownload.clickRefresh}{' '}
              <button
                style={{
                  color: 'white',
                  background: 'green',
                  padding: '2px 4px'
                }}
                onClick={refreshOpenVPNButtonClick}>
                {optionsTranslatedForDownload.refresh}
              </button>{' '}
              {optionsTranslatedForDownload.after}
            </li>
          </ol>
          <div style={{ fontSize: '13px', fontWeight: 500 }}>
            <TranslateComponent>Ensure you have </TranslateComponent>
            <span>
              <strong>
                {optionsTranslatedForDownload.openVpn} {INSTALLER_VERSION}
              </strong>
              {optionsTranslatedForNoConnection.orHigher}
            </span>
            <TranslateComponent>. If your current version is out of date, you will need to uninstall and download the new "TraneConnectInstaller.exe"</TranslateComponent>
          </div>
        </div>
        <button onClick={downloadButtonClick} className="action-btn secondary">
          <TranslateComponent>DOWNLOAD INSTALLER</TranslateComponent>
        </button>
      </>
    )
  }

  const ConnectionInitializedModalContent = () => {
    return (
      <div>
        <p className="padding-5 font-13">
          {optionsTranslatedForNoConnection.deviceDetails}
        </p>
        <span className="padding-5 font-13">
          {connectivityDetails?.deviceName &&
            `${connectivityDetails?.deviceName} | `}
        </span>
        <span className="font-13"> {connectivityDetails?.serialNumber}</span>
        <div className="padding-5">
          {deviceConnectionStatus === DEVICE_CONNECTION.tunnelEstablished ? (
            <>
              <div>
                <span className="icon icon-check" />
                <span className="padding-5 connection-status-text">
                  <TranslateComponent> Connection established</TranslateComponent>
                </span>
                <span>{formatDuration(connectedTime)}</span>
              </div>
              <div>
                <span>{connectivityDetails?.ipAddress} | </span>
                <span>{connectivityDetails?.hostName}</span>
              </div>
              <button
                onClick={copyToClipboard}
                className="copy-ip-address-btn"
                type="primary"
              >
                <TranslateComponent>Copy IP Address</TranslateComponent>
              </button>
              {ipCopiedToClipboard && (
                <span style={{ color: 'green', marginLeft: '2px' }}>
                  <strong><TranslateComponent>Copied..!!</TranslateComponent></strong>
                </span>
              )}

              {connectivityDetails?.deviceType === 'SCG' && (
                <div>
                  <p className="steps-title">
                    <TranslateComponent>To connect to Tracer Summit Application:</TranslateComponent>
                  </p>
                  <ol>
                    <li><TranslateComponent>Start Tracer Summit Application.</TranslateComponent></li>
                    <li><TranslateComponent>Manually enter the IP address listed above into Tracer Summit for the BBMD IP Address.</TranslateComponent></li>
                    <li><TranslateComponent>Enter the UDP port number for the BCU into Tracer Summit (You may need to replace the pre-populated UDP port number)</TranslateComponent></li>
                    <li><TranslateComponent>Select finish to connect</TranslateComponent></li>
                  </ol>
                </div>
              )}
              <div>
                <strong><TranslateComponent>Note: </TranslateComponent></strong>
                <TranslateComponent>The Open VPN client will need to disconnected manually from the server.</TranslateComponent>
              </div>
            </>
          ) : (
            <NoConnectionEstablished />
          )}
        </div>
      </div>
    )
  }

  const SomethingWentWrongError = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div>
          <Icon
            name="Warning"
            width="30px"
            height="30px"
            margin="4px 12px 0px 0px"
            color={colors.RED}
          />
        </div>
        <div style={{ display: 'flex', color: colors.RED }}>
          <TranslateComponent>Device is not currently connected through Open VPN. Please try connecting via The Trane Web UI if possible. If you are looking to connect to TU tools, you can connect directly via the TU site.</TranslateComponent>
        </div>
      </div>
    )
  }

  const modalBodyContent = useMemo(() => {
    switch (deviceConnectionStatus) {
      case DEVICE_CONNECTION.deviceNotConnected:
      case DEVICE_CONNECTION?.error:
        return <SomethingWentWrongError />
      case DEVICE_CONNECTION.tunnelEstablished:
      case DEVICE_CONNECTION.tunnelNotEstablished:
        return <ConnectionInitializedModalContent />
      case DEVICE_CONNECTION.download:
        return <DownloadModalContent />
      case DEVICE_CONNECTION.userConnectedDifferentServer:
        return <UserConnectedToDifferentServer />
      default:
        return <SomethingWentWrongError />
    }
  }, [
    deviceConnectionStatus,
    ipCopiedToClipboard,
    tokenCopiedToClipboard,
    connectedTime,
    loading
  ])

  const modelHeadings = useMemo(() => {
    switch (deviceConnectionStatus) {
      case DEVICE_CONNECTION.download:
        return 'Download Open VPN Installer'
      default:
        return connectivityDetails?.buildingName
          ? `${connectivityDetails?.buildingName}: ${optionsTranslatedForDownload.connect}`
          : "Connecting"
    }
  }, [loading])

  const modelConfig = useMemo(
    () => ({
      heading: modelHeadings,
      isHeaderTranslate : false,
      buttons: [
        ...(deviceConnectionStatus === DEVICE_CONNECTION.tunnelEstablished ||
        deviceConnectionStatus ===
          DEVICE_CONNECTION.userConnectedDifferentServer
          ? [
              {
                text: 'Disconnect',
                handleClick: disconnectDevice,
                type: 'valid'
              }
            ]
          : []),
        {
          text: <TranslateComponent>Close</TranslateComponent>,
          handleClick: toggleOpenVPNModal,
          type: 'cancel'
        }
      ],
      handleClose: toggleOpenVPNModal
    }),
    [
      deviceConnectionStatus,
      tokenCopiedToClipboard,
      ipCopiedToClipboard,
      loading
    ]
  )

  return (
    <Modal {...modelConfig}>
      {loading ? (
        <Spinner />
      ) : (
        <OpenVPNModalContainer>{modalBodyContent}</OpenVPNModalContainer>
      )}
    </Modal>
  )
}
