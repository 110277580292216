import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Table from 'src/components/Table/clientSideTable'
import moment from 'moment'
import { Button } from 'src/components/inputs/button'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import { verifyExclusion } from './helpers.js'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import {
  ExclutionTitleContainer,
  DataPickerWrapper,
  ExlusionContainer,
  Input,
  NameLabel,
  FormErrorMessage,
  TableWrapper
} from './styles'
import IconSVG from 'src/components/Icon'
import colors from 'src/components/legacy/common/colors.json'
import { DATE_FORMAT_DASH } from '../../../components/legacy/common/time-helpers.js'

export const BaselineTimeFrameAndExclusions = ({
  exclusions = [],
  updateExclusionList,
  activeTab
}) => {
  const [currentExclusionsValues, setCurrentExclusionValues] = useState()

  const [exclusionsList, setExclusionsList] = useState(exclusions)
  const [errors, setErrors] = useState({})
  const [selectedExclusionIndex, setSelectedExclusionIndex] = useState(-1)

  const setExclusionDefaultValues = () => {
    const initialExclusionValues = {
      name: '',
      startPeriod: moment().format(DATE_FORMAT_DASH),
      endPeriod: moment().format(DATE_FORMAT_DASH)
    }
    setCurrentExclusionValues({ ...initialExclusionValues })
    setSelectedExclusionIndex(-1)
    setErrors({})
  }

  const handleUpdateRowClose = () => {
    setExclusionDefaultValues()
  }

  useEffect(() => {
    setExclusionDefaultValues()
  }, [activeTab])

  const addExclusions = (event) => {
    try {
      const valueErrors = verifyExclusion(
        currentExclusionsValues.name,
        currentExclusionsValues.startPeriod,
        currentExclusionsValues.endPeriod
      )
      if (valueErrors && Object.keys(valueErrors)?.length > 0) {
        setErrors(valueErrors)
      } else {
        const exclusionsStateList = exclusionsList
        if (selectedExclusionIndex !== -1) {
          exclusionsStateList[selectedExclusionIndex] = currentExclusionsValues
        } else {
          currentExclusionsValues.id = exclusionsList?.length
          exclusionsStateList.push(currentExclusionsValues)
        }
        setExclusionsList([...exclusionsStateList])
        updateExclusionList(exclusionsStateList)
        setExclusionDefaultValues()
      }
    } catch (error) {
      console.log(error)
    } finally {
      event?.preventDefault()
      event?.stopPropagation()
    }
  }

  const handleInputChange = (event) => {
    const value = event?.target?.value || ''
    if (value !== '') {
      delete errors.name
      setErrors({ ...errors })
    }
    setCurrentExclusionValues({ ...currentExclusionsValues, name: value })
  }

  const onChangeDate = (path) => (date) => {
    date = date.target ? date.target.value : moment(date).format(DATE_FORMAT_DASH)
    setCurrentExclusionValues({ ...currentExclusionsValues, [path]: date })
    if (errors?.startPeriod || errors?.endPeriod) {
      delete errors.startPeriod
      delete errors.endPeriod
      setErrors({ ...errors })
    }
  }

  const handleEditExclusions = (data) => {
    setSelectedExclusionIndex(data?.id)
    setCurrentExclusionValues(data)
  }

  const handleDeleteExclusions = (data) => {
    const exclusionsStateList = exclusionsList
    const newExclusionsList = exclusionsStateList
      .filter((x) => x.id !== data.id)
      .map((x, index) => {
        return {
          ...x,
          id: index
        }
      })
    setExclusionsList([...newExclusionsList])
    updateExclusionList(newExclusionsList)
  }

  const tableHeading = [
    {
      title: 'NAME',
      key: 'name',
      maxWidth: '50px',
      canSort: false
    },
    {
      title: 'START DATE',
      key: 'startPeriod',
      maxWidth: '100px',
      canSort: false
    },
    {
      title: 'END DATE',
      key: 'endPeriod',
      maxWidth: '100px',
      alignRight: true,
      canSort: false
    }
  ]

  return (
    <ExlusionContainer>
      <ExclutionTitleContainer>
        <h3>Exclusions</h3>
      </ExclutionTitleContainer>
      <div className="exclusion-row-container">
        <div className="exclusion-name-wrapper">
          <NameLabel hasError={errors?.['name']}>Name*</NameLabel>
          <Input
            type="text"
            value={currentExclusionsValues?.name || ''}
            onChange={handleInputChange}
            autoComplete="off"
            hasError={errors?.['name']}
            isRequired={true}
            errorMessage={errors?.['name']}
            maxLength={30}
          />
          {errors?.['name'] && (
            <FormErrorMessage>Exclusion name is required</FormErrorMessage>
          )}
        </div>

        <DataPickerWrapper className='seperator_container'>
          <div>
            <ModalDatePickerInput
              labelText="Start Date"
              date={
                currentExclusionsValues?.startPeriod
                  ? currentExclusionsValues?.startPeriod
                  : ''
              }
              onChange={onChangeDate('startPeriod')}
              isRequired={true}
              hasError={Boolean(errors?.startPeriod)}
              errorMessage="Start date must come before end date."
            />
          </div>
          <div>
            <span className="seperator-exclusions">-</span>
          </div>
          <div>
            <ModalDatePickerInput
              labelText="End Date"
              date={
                currentExclusionsValues?.endPeriod
                  ? currentExclusionsValues?.endPeriod
                  : ''
              }
              onChange={onChangeDate('endPeriod')}
              isRequired={true}
              hasError={Boolean(errors?.endPeriod)}
              errorMessage="End date must come after start date."
            />
          </div>
        </DataPickerWrapper>
        <div className="buttons-container">
          {selectedExclusionIndex === -1 ? (
            <Button onClick={addExclusions}>ADD</Button>
          ) : (
            <>
              <Button onClick={addExclusions}>UPDATE</Button>
              <IconSVG
                name="close"
                color={colors.TBA_DARK2_GREY}
                height="16px"
                size="13px"
                onClick={handleUpdateRowClose}
              />
            </>
          )}
        </div>
      </div>

      <TableWrapper>
        <Table
          header={tableHeading}
          rows={exclusionsList}
          search={false}
          width={300}
          rowControl={[
            {
              text: 'Edit',
              action: handleEditExclusions
            },
            {
              text: 'Delete',
              action: handleDeleteExclusions
            }
          ]}
        />
      </TableWrapper>
    </ExlusionContainer>
  )
}

BaselineTimeFrameAndExclusions.propTypes = {
  exclusions: PropTypes.array,
  updateExclusionList: PropTypes.func
}
