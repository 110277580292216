import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import StandardDialogs from '../legacy/components/standard-dialogs'
import { ConditionalTooltip } from '../legacy/components/tooltip/conditional-tooltip'
import { useAttachment } from '../../hooks/attachment'
import { FileUploadWrapper } from './styles'
import { updateFileNameWithEpoch } from 'src/common/helperFunctions.js'


import translate, { TranslateComponent } from '../../common/translations'

export const FILE_MODEL = PropTypes.shape({
  id: PropTypes.string,
  description: PropTypes.string
})

export const STATUS = {
  LOADED: 'loaded', // file was added to input
  UPLOADING: 'uploading', // in progress with upload file to server
  UPLOADED: 'uploaded', // file was uploaded
  ERROR: 'error' // error uploading file
}

export const FILE_MAX_SIZE = 30000000
export const MAX_FILE_NAME = 20

const PROPOSAL_TYPE_FILE = [
  'doc',
  'dot',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'docb',
  'xls',
  'xlt',
  'xlsx',
  'xlsm',
  'xltx',
  'xltm',
  'xlsb',
  'ppt',
  'pot',
  'pptx',
  'pptm',
  'potx',
  'potm',
  'ppsx',
  'ppsm',
  'sldx',
  'sldm',
  'jpg',
  'png',
  'gif',
  'tif',
  'eps',
  'bmp',
  'svg',
  'webp',
  'aif',
  'm4a',
  'mp3',
  'mpa',
  'wav',
  'wma',
  'm4v',
  'mov',
  'mp4',
  'mpg',
  'wmv',
  'pdf',
  'txt',
  'rtf',
  'csv',
  'jpeg'
]

const FileUploader = (props) => {
  const defaultState = {
    status: null,
    fileName: null,
    error: null,
    confirm: null,
    errorMessage: ''
  }

  const initialId = props.file?.id
  const [state, setState] = useState({ ...defaultState })
  const [fileContent, setFileContent] = useState(null)
  const { addFileFunc } = useAttachment()
  const {
    onFileChange,
    clearStateOnUpload,
    location,
    titleText,
    descriptionText,
    fileTypes,
    componentName,
    file: { description },
    maxDescriptionLength
  } = props

  useEffect(() => {
    setState({
      ...state,
      status: props?.file?.name ? STATUS.UPLOADED : null,
      fileName: props?.file?.displayName
    })
  }, [])

  const optionsTranslated = {
    fileType: translate('This file will not be uploaded. File type is not allowed. Recommended file types include .pdf, .doc, .xls, .png, .mp4 and .jpg.'),
    bigFile: translate('File size is too large. Upload the file less than 50 MB in size'),
    smallFile: translate('File is too small. Please choose a file with not 0 size.'),
    error: translate('There was an error. Please try again.'),
    removeFile: translate('Remove file'),
    confirmRemoveFile: translate('Are you sure you want to remove this file?'),
    yes: translate('Yes'),
    no: translate('No'),
    file: translate('File'),
    chooseFile: translate('Choose File...'),
    uploadFile: translate('Upload File'),
    fileDesc: translate('File Description'),
  }
  const clearState = () => {
    setState({ ...defaultState })
  }

  const onInputFileChange = (e) => {
    const file = e.target.files[0]
    setState({ ...state, errorMessage: '' })
    if (file) {
      props.onFileSelection && props.onFileSelection(file)
      handleFileBeforeUpload(file)
    }
  }

  const onDescriptionChange = ({ target: { value } }) =>
    value.length <= props.fieldMaxLength &&
    onFileChange({ ...props.file, ...{ description: value } })

  const handleFileBeforeUpload = (file) => {
    if (!isAllowedFileType(file.name)) {
      setState({ ...state, error: { text: optionsTranslated.fileType} })
    } else if (hasAllowedSize(file)) {
      setState({
        ...state,
        status: STATUS.LOADED,
        name: file.name,
        fileName: file.name
      })
      Object.defineProperty(file, 'displayName', {
        writable: true,
        value: file?.name
      })
      Object.defineProperty(file, 'name', {
        writable: true,
        value: updateFileNameWithEpoch(file?.name)
      })
      setFileContent(file)
    } else {
      // add ability to add new file
      if (file.size > FILE_MAX_SIZE) {
        setState({ ...state, error: { text: optionsTranslated.bigFile } })
      }
      if (file.size < 1) {
        setState({ ...state, error: { text: optionsTranslated.smallFile } })
      }
    }
  }

  const hasAllowedSize = (file) => {
    return file.size < FILE_MAX_SIZE && file.size >= 1
  }

  const beforeUploading = () => {
    isNewFileLoaded() ? replaceFile() : uploadFile()
  }

  // this check is used to avoid deleting original file id in case editing will be canceled
  const isNewFileLoaded = () => {
    const { id } = props.file
    return id && id != initialId
  }

  const replaceFile = () => {
    const fileIdToDelete = props.file.id
    return uploadFile().then(() => {
      if (initialId !== fileIdToDelete) {
        return removeFile(fileIdToDelete)
      }
    })
  }

  const uploadFile = () => {
    setState({ ...state, status: STATUS.UPLOADING })
    onFileChange({ ...props.file, loading: true })

    return addFileFunc(fileContent, location)
      .then((res) => {
        onFileChange({
          name: fileContent.name,
          displayName: fileContent?.displayName,
          url: res?.url,
          loading: false,
          sizeInBytes: fileContent?.size,
          type: fileContent?.type,
          description: props?.file?.description
        })
        setState({ ...state, status: STATUS.UPLOADED })
        clearStateOnUpload && clearState()
      })
      .catch(() => {
        setState({
          ...state,
          status: STATUS.ERROR,
          errorMessage: optionsTranslated.error
        })
        onFileChange({ ...props.file, loading: false })
      })
  }

  const removeFile = (id) => {
    props.deleteFile(id).catch((error) => setState({ ...state, error }))
  }

  const clearData = () => {
    setState({
      confirm: {
        title: optionsTranslated.removeFile,
        text: optionsTranslated.confirmRemoveFile,
        handleConfirm: () => {
          const { id } = props.file
          // remove only not submitted files
          if (initialId != id) {
            // removeFile(id)  // Need to get the removeFile functionality
          }
          setState({
            ...state,
            status: null,
            name: '',
            fileName: '',
            confirm: null
          })

          onFileChange({
            ...props.file,
            ...{ id: null, description: '', name: '' },
            ...(state.saveFullName ? { fileFullName: '' } : {})
          })
        },
        handleCancel: () => {
          setState({ ...state, confirm: null })
        },
        confirmText: optionsTranslated.yes,
        cancelText: optionsTranslated.no
      }
    })
    return false
  }

  const onCloseDialog = (field) => () => {
    setState({ ...state, [field]: null })
  }

  const isAllowedFileType = (fileName) => {
    const { fileTypes } = props
    return fileTypes
      ? fileTypes.some(
          (extension) => extension === fileName.split('.').pop().toLowerCase()
        )
      : true
  }

  const { status, fileName, errorMessage, error, confirm } = state

  return (
    <FileUploadWrapper>
      <div className={`uploading-file ${componentName}`} data-testid={`uploading-file ${componentName}`}>
        <div>
          <label className="uploading-file-title">{titleText ? titleText : optionsTranslated.file}</label>
          <div>
            <label
              htmlFor="file-upload"
              className={classNames('file', {
                'selected': status === STATUS.UPLOADING
              })}
            >
              {optionsTranslated.chooseFile}
            </label>
            <input
              id="file-upload"
              type="file"
              accept={`.${fileTypes.join(', .')}`}
              onChange={onInputFileChange}
              onClick={(event) => {
                event.target.value = null
              }}
              hidden={true}
              disabled={status === STATUS.UPLOADING || !location}
            />
            {status && (
              <span
                className={classNames('filename', {
                  'uploading': status === STATUS.UPLOADING
                })}
              >
                <ConditionalTooltip
                  type={ConditionalTooltip.TYPE.ICON}
                  conditional={false}
                  content={fileName}
                  align={ConditionalTooltip.ALIGN.START}
                >
                  {fileName}
                </ConditionalTooltip>
              </span>
            )}

            {status === STATUS.LOADED && (
              <button
                className="secondary small upload-button"
                type="button"
                onClick={beforeUploading}
              >
                {optionsTranslated.uploadFile}
              </button>
            )}

            {status === STATUS.UPLOADING && (
              <button
                className="secondary small upload-button disabled"
                type="button"
              >
                <span className="spinner" />
              </button>
            )}

            {status === STATUS.UPLOADED && (
              <button
                className="icon-btn red remove-icon"
                onClick={clearData}
                type="button"
              >
                <span className="icon-trash2" />
              </button>
            )}

            {status === STATUS.ERROR && (
              <button className="secondary small error-upload" type="button">
                <span className="icon icon-error" />
              </button>
            )}

            {errorMessage && (
              <div className="error-message">
                <span className="error-text">{errorMessage}</span>
              </div>
            )}
          </div>
        </div>
        <div>
          <label htmlFor="description">{descriptionText ? descriptionText : optionsTranslated.fileDesc}</label>
          <input
            name="description"
            disabled={!fileName}
            type="text"
            value={description}
            onChange={onDescriptionChange}
            maxLength={maxDescriptionLength}
          />
        </div>

        <StandardDialogs
          error={error}
          confirm={confirm}
          onCloseDialog={onCloseDialog}
        />
      </div>
    </FileUploadWrapper>
  )
}

FileUploader.defaultProps = {
  fieldMaxLength: 140,
  fileTypes: PROPOSAL_TYPE_FILE,
  maxDescriptionLength: 255,
  clearStateOnUpload: false,
  saveFullName: false
}

FileUploader.propTypes = {
  file: FILE_MODEL.isRequired,
  onFileChange: PropTypes.func.isRequired,
  onFileSelection: PropTypes.func,
  titleText: PropTypes.string,
  descriptionText: PropTypes.string,
  fieldMaxLength: PropTypes.number,
  fileTypes: PropTypes.array,
  componentName: PropTypes.string,
  clearStateOnUpload: PropTypes.bool,
  saveFullName: PropTypes.bool,
  maxDescriptionLength: PropTypes.number
  // from store
}

export default FileUploader
