import { useState, useMemo, useEffect } from 'react'
import { useLegendApiContext } from 'src/chart-library/CommonComponents/Legend/provider/LegendApiProvider'

const legendModelMapper = (legend: any = {}, gen) => {
  return legend?.name
    ? gen({
        text: legend.name,
        iconProps: {
          fill: legend.elementProps?.stroke || legend.elementProps?.fill || legend?.stroke,
          symbol: 'symbolSquare',
          ...(legend?.iconProps || {})
        },
        legendObject: {
          ...(legend || {})
        },
        groupBy: legend?.legendGroupName || 'DefaultGroupBy'
      })
    : null
}

export default function useXYLegend(types) {
  const [clickedItems, setClickedItems] = useState([])
  // Legend Context that provides required Api to communicate with legend state
  const legendApi = useLegendApiContext()

  const legendState = useMemo(() => legendApi.init(), [legendApi])

  // following fn is responsible for creating legends from data
  // This will run whenever new data is passed and create legend model for each item
  const legends = useMemo(() => {
    // Items - store each lenged
    // keys - for look-up purpose to check the availability of legends
    const initial = { items: [], keys: {}, group: {} }

    // isWrappedByLegendProvider is false means the chart is used without legend wrapper
    // if false - don't perform anything
    if (!legendApi?.isWrappedByLegendProvider) {
      return types
    }

    return types?.reduce(
      (av, legend) => {
        const dontAdd = legend?.type === 'plotLine' ? legend?.considerPLLegend : true
        if(!legend?.show) return av
        if(!dontAdd) return av
        const l = legendModelMapper(legend, legendApi.createLegendModel)
        if (l) {
          av.items.push(l)
          av.keys[l.text] = true
          av.group[l.groupBy] = { ...legend.legendGroup }
        }
        return av
      },

      initial
    )
  }, [legendApi, types])

  useEffect(() => {
    if (legends?.items) {
      // Create group of legends with title if provided and post to legendApi
      const group = Object.entries(legends?.group)
      const _clickedItems = []

      const legend = group?.map(([name, options]: any[]) => {
        const groupItem =
          name !== 'DefaultGroupBy'
            ? {
                text: name,
                ...options
              }
            : null

        const legendItems = legends?.items?.filter((i) => i.groupBy === name)

        // check a legend item is disabled / clicked already. if so, mark it as disable and store it
        legendItems?.forEach((item) => {
          const isClicked = clickedItems?.some(
            (clickedItem) => clickedItem.text === item.text
          )
          item.disabled = isClicked

          isClicked && _clickedItems.push({ ...item })
        })

        return legendApi?.createLegendGroupModel(legendItems, groupItem)
      })

      // Check existing clicked items are in new legend data
      const isAllItemsAvaiable = clickedItems?.every(
        (clickedItem) => legends?.keys[clickedItem.text]
      )

      // if any of clicked item is not in new legend data, replace the old clicked items
      if (!isAllItemsAvaiable) {
        setClickedItems(_clickedItems)
      }


      legendState?.postLegendDetails(legend)
    }
  }, [legends])

  // free up memory when component unmounds
  useEffect(() => {
    const legendStateId = legendState?.id
    return () => legendApi.unmount(legendStateId)
  }, [])

  useEffect(() => {
    // Method to listen on all selected items
    legendState.listenToGetAllSelectedItem(setClickedItems)
  }, [legendState])

  return legendState
}
