import { Fragment, memo, useMemo } from 'react'
import Axis from 'src/chart-library/CommonComponents/Axis/Axis'
import {
  BOTTOM_TOP,
  RIGHT_LEFT,
  RIGHT_RIGHT,
  BOTTOM_BOTTOM
} from 'src/chart-library/CommonComponents/Axis/constants'
import { Sides } from 'src/chart-library/Types/axis'
import { SCALE_BAND, SCALE_LINEAR, SCALE_TIME } from 'src/chart-library/Utils/Scales/constant'
import Grid from 'src/chart-library/CommonComponents/Grids/Grid'
import {
  selectAxes,
  selectAxisCanvas,
  selectCanvasDetails,
  selectChartInstanceId,
  selectGeneratedScales,
  selectPaddingDetails,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
import PlotBand from 'src/chart-library/CommonComponents/PlotBand/PlotBand'
import { plotAreaID } from './helper'

const AxisGridRenderer = (props: any) => {
  const { canvasIndex } = props
  const axes: any = useSelector(selectAxes)
  const generatedScales: any = useSelector(selectGeneratedScales)
  const canvasDetails: any = useSelector(selectCanvasDetails)
  const axisCanvas: any = useSelector(selectAxisCanvas)
  const paddingDetails: any = useSelector(selectPaddingDetails);
  const chartInstanceId = useSelector(selectChartInstanceId)
  const { paddingWidth, paddingHeight } = useMemo(() => {
    const canvasDim = canvasDetails?.[canvasIndex]
    return {
      paddingHeight: canvasDim?.height,
      paddingWidth: canvasDim?.width
    }
  }, [canvasDetails, canvasIndex])

  return (
    <>
      {axes
        ?.filter((obj: any) => {
          const aCanvasIndex = axisCanvas?.[obj?.key]?.canvasIndex || '0'
          return parseInt(aCanvasIndex) === parseInt(canvasIndex)
        })
        ?.map((axesObj: any, i: number) => {
          const key = axesObj?.key
          const type = key?.includes('x') ? 'x' : 'y'

          const spaceDetails = paddingDetails?.[key];
          const grid = axesObj?.grid
          const scaleObj = axesObj?.scale
          const axisObj = axesObj?.axis
          const scaleDetails = generatedScales?.[key]
          const tickValues = Array.isArray(spaceDetails?.tickValues) && spaceDetails?.tickValues?.map((obj) => obj?.text);

          const plotBandArray = axesObj?.plotBands

          const adjustmentScaleKey = axesObj?.scale?.scaleBand || ""
          const adjustmentScale = generatedScales?.[adjustmentScaleKey]

          if (scaleDetails?.gScale) {
            return (
              <Fragment key={i}>
                {grid?.map((gridObj: any = {}, j: number) => {
                  return (
                    <Grid
                      key={`${i}-${j}`}
                      scale={scaleDetails?.gScale}
                      type={type}
                      width={paddingWidth}
                      height={paddingHeight}
                      tickValues={tickValues}
                      scaleType={scaleObj?.props?.type}
                      notD3={axisObj?.notD3}
                      d3Ticks={axesObj?.scale?.d3Ticks}
                      adjustmentScale={adjustmentScale}
                      {...gridObj}
                    />
                  )
                })}

                {axisObj?.visibleAt?.map((side: Sides, j: number) => {
                  let height
                  let width
                  if (type === 'x') {
                    height =
                      side === BOTTOM_BOTTOM || side === BOTTOM_TOP
                        ? paddingHeight
                        : 0
                  }
                  if (type === 'y') {
                    width =
                      side === RIGHT_LEFT || side === RIGHT_RIGHT
                        ? paddingWidth
                        : 0
                  }
                  return (
                    <Axis
                      key={`${i}-${j}`}
                      scaleType={scaleObj?.props?.type}
                      scale={scaleDetails?.gScale}
                      side={side}
                      height={height}
                      width={width}
                      paddingHeight={paddingHeight}
                      paddingWidth={paddingWidth}
                      tickValues={tickValues}
                      spaceDetails={spaceDetails}
                      d3Ticks={axesObj?.scale?.d3Ticks}
                      adjustmentScale={adjustmentScale}
                      {...axisObj}
                    />
                  )
                })}

                <g id={`plot-band-${plotAreaID(chartInstanceId, canvasIndex)}`} clipPath={`url(#${plotAreaID(chartInstanceId, canvasIndex)})`}>
                  {plotBandArray?.map((plotObj: any = {}, j: number) => {
                    return (
                      <PlotBand
                        key={`${i}-${j}`}
                        axisType={type}
                        scale={scaleDetails?.gScale}
                        width={paddingWidth}
                        height={paddingHeight}
                        plotObj={plotObj}
                      />
                    )
                  })}
                </g>
              </Fragment>
            )
          } else {
            return <Fragment key={i}></Fragment>
          }
        })}
    </>
  )
}

export default memo(AxisGridRenderer)
