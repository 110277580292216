import styled from 'styled-components'

export const DatePickerWrapper = styled.div`
  display: flex;
  margin: 20px 0px;
  .date-seperator {
    margin-top: 13px;
    padding: 10px;
  }
`

export const ReprocessContainer = styled.div`
margin-top: 15px;

legend {
  font-size: 14px;
  margin-top: 10px;
}


.reprocess-radio {
  margin-left: 10px;
}`


export const DeviceListContainer = styled.div`
width: 430px;
margin: 20px 0px;
p {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
}`
