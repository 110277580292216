import LineRenderer from "./LineRenderer";
import AreaRenderer from "./AreaRenderer";
import AreaRangeRenderer from "./AreaRangeRenderer";
import ColumnRenderer from "./ColumnRenderer";
import ComparisonBarRenderer from "./ComparisonBarRenderer";
import AreaDualRenderer from "./AreaDualRenderer";
import ColumnNewRenderer from "./ColumnNewRenderer";
import PlotLineRenderer from "./PlotLineRenderer";
import DraggableLineRenderer from './DraggableLineRenderer'

export const rendererComponents: any = {
  line: LineRenderer,
  area: AreaRenderer,
  areaRange: AreaRangeRenderer,
  areaDual: AreaDualRenderer,
  column: ColumnRenderer,
  comparisonBar: ComparisonBarRenderer,
  columnNew: ColumnNewRenderer,
  plotLine: PlotLineRenderer,
  dragLine: DraggableLineRenderer
};

export const rendererPicker = (type = '') => {
  const Renderer: any = rendererComponents?.[type]
  if (Renderer) {
    return Renderer
  } else {
    console.log(`Shape: ${type} is not configured`)
    return
  }
}
