import { memo, useMemo } from 'react'
import * as d3 from 'd3'
import usePlotBandTooltip from './usePlotlBandTooltip'

const PlotBand = (props: any) => {
  const { axisType = '', scale, width, height, plotObj, id } = props
  const from: any = { x: 0, y: 0 }
    const to: any = { x: 0, y: 0 }

  const path = useMemo(() => {
    const path = d3?.path()
    if (scale) {
      if (axisType === 'y') {
        from.y = scale(plotObj?.from)
        to.y = scale(plotObj?.to)
        to.x = width
        path.moveTo(0, scale(plotObj?.from))
        path.lineTo(0, scale(plotObj?.to))
        path.lineTo(width, scale(plotObj?.to))
        path.lineTo(width, scale(plotObj?.from))
        path.closePath()
      }
      if (axisType === 'x') {
        from.x = scale(plotObj?.from)
        to.x = scale(plotObj?.to)
        to.y = height
        path.moveTo(scale(plotObj?.from), 0)
        path.lineTo(scale(plotObj?.to), 0)
        path.lineTo(scale(plotObj?.to), height)
        path.lineTo(scale(plotObj?.from), height)
        path.closePath()
      }
    }

    return path
  }, [plotObj, width, height, scale, axisType])

  usePlotBandTooltip({
    from: from,
    to: to,
    props: { ...plotObj }
  })

  return (
    <g id={id}>
      <path
        d={path?.toString()}
        fill={plotObj?.fill}
        fillOpacity={plotObj?.fillOpacity || 0.2}
      />
    </g>
  )
}

export default memo(PlotBand)
