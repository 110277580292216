import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  getEquipmentDetailsbyId,
  GET_CHILD_EQUIPMENTDETAILS
} from '../../graphql/queries'
import { useQuery } from 'src/hooks/APIHooks'
import _isEmpty from 'lodash/isEmpty'
import { EQTable } from '../style'

// components
import ToggleBox from 'src/components/legacy/components/toggle-box/'
import Table from 'src/components/layouts/Table'
import { transformData } from '../EquipmentDetailsParser'
import translate, { TranslateComponent } from '../../../../common/translations'

AssociationDetailsAssociatedEquipment.propTypes = {
  equipmentDetails: PropTypes.any
}

export default function AssociationDetailsAssociatedEquipment({
  equipmentDetails
}) {
  const parentId = equipmentDetails?.EQDetails?.parentId
  const equipmentId = equipmentDetails?.EQDetails?.id
  const [parentEquipmentLists, setParentEquipmentLists] = useState([])

  // TO GET PARENTEQUIPMENTDETAILS
  const {
    data: parentDetails,
    parentLoading,
    refetch: refetchparentDetails
  } = useQuery({
    query: getEquipmentDetailsbyId,
    disableInitialLoad: true,
    variables: { id: parentId },
    dataPath: 'data.getEquipment'
  })

  useEffect(() => {
    let parentList = []
    const getParentList = async () => {
      const checkIsMultipleParent = parentId?.includes('#')
      if (checkIsMultipleParent) {
        const getEquipmentWithParentIds = parentId?.split('#')
        getEquipmentWithParentIds.map(
          async (parent) =>
            await refetchparentDetails({ id: parent }).then((data) => {
              if (data) {
                parentList.push(data)
              } else {
                parentList = []
              }
            })
        )
        setParentEquipmentLists(parentList)
      } else {
        await refetchparentDetails({ id: parentId }).then((data) => {
          if (data) {
            parentList.push(data)
          } else {
            parentList = []
          }
        })
      }
    }
    if (parentId !== 'NA' && parentId !== null && parentId !== '') {
      getParentList()
    }
    setParentEquipmentLists(parentList)
  }, [parentId])

  // TO GET CHILDEQUIPMENTDETAILS
  const {
    data: childDetails,
    childLoading,
    refetch: refetchchildDetails
  } = useQuery({
    query: GET_CHILD_EQUIPMENTDETAILS,
    disableInitialLoad: true,
    variables: { parentId: equipmentId },
    dataPath: 'data.searchEquipment.items'
  })

  useEffect(() => {
    if (equipmentId) {
      refetchchildDetails()
    }
    // eslint-disable-next-line
  }, [equipmentId])

  // Combine the output data into one object
  const [EquipmentAssociations, setEquipmentAssociations] = useState([])

  useEffect(() => {
    if (!parentLoading && !childLoading) {
      let parentDetailsList = []
      if (!_isEmpty(parentEquipmentLists)) {
        parentDetailsList = parentEquipmentLists?.map((item) => ({
          ...item,
          relation: 'Parent'
        }))
      }

      let children = []
      if (Array.isArray(childDetails)) {
        children = childDetails?.map((item) => ({ ...item, relation: 'Child' }))
      }

      let EqAssociations = []
      if (
        (!_isEmpty(children) && Array.isArray(children)) ||
        !_isEmpty(parentDetailsList)
      ) {
        EqAssociations = parentDetailsList
          ? [...children, ...parentDetailsList]
          : children
      }

      setEquipmentAssociations(transformData(EqAssociations))
    }
  }, [parentDetails, childDetails, parentLoading, childLoading])

  const automationColumn = [
    {
      maxWidth: '35%',
      displayName: translate('TIS equipment'),
      sortable: true,
      key: 'equipmentName',
      name: 'equipmentName'
    },
    {
      maxWidth: '45%',
      displayName: translate('Equipment type'),
      sortable: true,
      key: 'unitTypePresentationName',
      name: 'unitTypePresentationName'
    },
    {
      maxWidth: '20%',
      displayName: translate('Association'),
      sortable: true,
      key: 'association',
      name: 'association'
    }
  ]
  const [filteredRowData, setfilteredRow] = useState([])
  useEffect(() => {
    const l_data = EquipmentAssociations?.EquipmentAssociations
    if (l_data !== undefined && l_data.length > 0) {
      const filteredRow = l_data?.map((id) => ({
        association: <TranslateComponent>{id?.association || ''}</TranslateComponent>,
        unitTypePresentationName: <TranslateComponent>{id?.unitTypePresentationName || ''}</TranslateComponent>,
        equipmentName: id?.equipmentName || ''
      }))
      setfilteredRow(filteredRow)
    }
  }, [EquipmentAssociations])

  return (
    <ToggleBox
      label="Associated Equipment"
      className="transparent"
      showOnlyLabel
      opened
    >
      <EQTable>
        <Table
          paginationEnabled={false}
          pageCount={0}
          rows={filteredRowData}
          columns={automationColumn}
          className="equipment-list"
        />
      </EQTable>
    </ToggleBox>
  )
}
