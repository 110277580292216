import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'

export const FileWrapper = styled.div`
&& .file-view {
	display: flex;
	flex-flow: column;
	align-items: flex-end;
	word-break: break-all;
	
	button {
		background: none!important;
		border: none;
		padding: 0!important;
		font-family: arial, sans-serif;
		color: ${colors.BLACK};
		text-decoration: underline;
		cursor: pointer;
	  }
}
`