import * as Yup from 'yup'
import TextInput from 'src/components/legacy/components/modal-text-input'
import Label from 'src/components/legacy/components/label/label'
import { useTranslation } from 'react-i18next'
import ImageUploader from 'src/components/imageuploader/imageuploader'
import { ChangeEvent, useEffect, useState } from 'react'
import { useFormikContext } from '../MultiTabTorm'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { ErrorText, ImageUploaderWrapper, TabWrapper } from '../../styles'
import { useAttachment } from 'src/hooks/attachment'
import { ACTIONS } from 'src/constants'
import { NO_IMAGE } from '../../helpers'
import translate, { TranslateComponent } from 'src/common/translations'

const ErrorMessage = ({errorMessage}) => <ErrorText>{translate(errorMessage)}</ErrorText>

export const ImageWidgetConfigurationSchema = Yup.object().shape({
    imageTitle: Yup.string().test(
      'testImageTitleConfigValue',
      'Image/Text Link widget must include either text or an image or both.',
      testImageTitleConfigValue
    ),
    imageUrl: Yup.string().test(
      'testImageUrlConfigValue',
      'Image/Text Link widget must include either text or an image or both.',
      testImageUrlConfigValue
    ),
    imageDescription: Yup.string().test(
      'testImageDescConfigValue',
      'Image/Text Link widget must include either text or an image or both.',
      testImageDescConfigValue
    )
  })
  function testImageTitleConfigValue(value) {
    const {
      parent: { imageUrl, imageDescription}
    } = this
    return (value || imageUrl || imageDescription)
  }
  function testImageUrlConfigValue(value) {
    const {
      parent: { imageTitle, imageDescription}
    } = this
    return (value || imageTitle || imageDescription)
  }
  function testImageDescConfigValue(value) {
    const {
      parent: { imageUrl, imageTitle}
    } = this
    return (value || imageUrl || imageTitle)
  }
  
export const ImageWidgetConfiguration = () => {
    const formik = useFormikContext()
    const { widgetBuilding, mode } = useWidgetsContext()
    const { downloadFileURL } = useAttachment()
    const [loading, setLoading] = useState(false)
    const { values, errors, setFieldValue } = formik
    const [t] = useTranslation()
    const handleInputChange = (key, value) => {
      setFieldValue(key, value)
    }

  useEffect(() => {
    try {
      (async () => {
        const image = values?.image ?? null
        if ([ACTIONS.EDIT,ACTIONS.COPY].includes(mode)) {
          if (!values?.buildingId || !image?.name || (image?.imageUrl && image?.imageUrl !== NO_IMAGE) || (image?.original && image?.original !== NO_IMAGE)) return
          setLoading(true)
          await downloadFileURL(
            image?.name,
            values?.buildingId
          ).then((res) => {
            setLoading(false)
            setTimeout(() => {
              setFieldValue('image', { ...image, imageUrl: res, original: res })
              setFieldValue('imageUrl', res ?? '')
            }, 50);
          })
        }
      })()
    } catch (error) {
      setLoading(false)
    }
  }, [])    
    
    return (
      <TabWrapper>
        <Label text={"Link widget must include link or an image or both text and image."} />
        <TextInput
          labelText={"Widget Text (appears above image)"}
          defaultValue={values?.imageTitle}
          name="imageTitle"
          onChange={({
            target: { value, name }
          }: ChangeEvent<HTMLInputElement>) => {
            handleInputChange(name, value)
          }}
          hasError={errors?.['imageTitle']}
          isRequired={false}
          errorMessage={errors?.['imageTitle']}
        />
        <ImageUploaderWrapper className={!widgetBuilding?.id ? 'disable-control' : ''}>
           <legend className={`custom-label ${errors.imageUrl ? 'invalid' : ''}`}><TranslateComponent>Widget Image</TranslateComponent></legend>
            <ImageUploader
              imageId={values.image?.imageId}
              update={(fields) => {
                setFieldValue('image', fields)
                setFieldValue('imageUrl', fields?.imageUrl)
              }}
              imageUrl={values?.image?.imageUrl}
              imageName={values?.image?.displayName}
              annotationsEnabled={false}
              locationId={widgetBuilding?.id}
              loading={loading}
            />
          {
            errors.imageUrl && (
              <ErrorMessage errorMessage={errors.imageUrl} />
            )
          }
        </ImageUploaderWrapper>
        <TextInput
          labelText={"Additional Widget Text (appears below image)"}
          defaultValue={values?.imageDescription}
          name="imageDescription"
          onChange={({
            target: { value, name }
          }: ChangeEvent<HTMLInputElement>) => {
            handleInputChange(name, value)
          }}
          hasError={errors?.['imageDescription']}
          isRequired={false}
          errorMessage={errors?.['imageDescription']}
        />
      </TabWrapper>
    )
  }