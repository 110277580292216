import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Select from 'src/components/legacy/components/select/select'
import {
  TIME_VALUES_LIST,
  formatTimeValues
} from 'src/components/legacy/common/opportunity.js'
import { v4 as uuidv4 } from 'uuid'
import { getField, isNumberValue } from 'src/components/legacy/common/helpers.js'

import { TimeIntervalWrapper } from './styles.js'

export function TimeIntervalSelector({
  name,
  label,
  tagList,
  time = { value: '', tag: '' },
  formatTagList,
  onChange,
  validation
}) {
  const [t] = useTranslation()

  const update = (field) => (value) => {
    const currenTime = { [field]: value }
    const finalTime = { ...time, ...currenTime }
    onChange(finalTime)
  }

  const editable = Boolean(onChange)
  const tagOptionsList = tagList.map((key) => ({
    key,
    description: t(`common:${formatTagList(key)}`)
  }))
  return (
    <TimeIntervalWrapper>
      <div className={`time-interval-selector ${!editable && 'view'}`}>
        {editable ? (
          <Fragment>
            {label && <label htmlFor={name}>{label}</label>}
            <div
              className={`${getField(validation, 'error') ? 'invalid' : ''}`}
            >
              <input
                value={time?.value || ''}
                name={name}
                step="any"
                onChange={({ target }) => {
                  const value = target.value
                  if(isNumberValue(value)){
                    update('value')(Number(value))
                  }
                }}
              />
              <Select
                options={tagOptionsList}
                onChange={update('tag')}
                selectedItem={formatTagList(time?.tag)}
              />
            </div>
            {getField(validation, 'error') && (
              <p className="error">{validation?.error}</p>
            )}
          </Fragment>
        ) : (
          <div>
            {label && <label>{label}</label>}
            <span>
              {time?.value} {formatTagList(time?.tag)}
            </span>
          </div>
        )}
      </div>
    </TimeIntervalWrapper>
  )
}
TimeIntervalSelector.propTypes = {
  name: PropTypes.string, // used as input name attr
  label: PropTypes.string,
  tagList: PropTypes.array,
  time: PropTypes.shape({
    tag: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  formatTagList: PropTypes.func,
  onChange: PropTypes.func, // if onChange prop missing, component is considered to be in view mode,
  validation: PropTypes.object
}

TimeIntervalSelector.defaultProps = {
  name: uuidv4(),
  tagList: TIME_VALUES_LIST,
  formatTagList: formatTimeValues
}

export default TimeIntervalSelector
