export const GET_BUILDINGS_BY_ACCOUNT_ID = `query GET_BUILDINGS_BY_ACCOUNT_ID($id: ID!) {
    listBuildingsByAccount(accountId: $id, limit: 10000) {
      items {
        key: id
        value: name
        floorArea
        salesOfficeId
        }
      }
    }
  `

  export const ListBuildingEquipments = /* GraphQL */ `
    query listBuildingEquipments($id: ID!) {
        getBuilding(id: $id) {
        equipments(filter: { isActive: { eq: 1 } }, limit: 10000) {
            items {
            key: id
            value: name
            family
            type
            }
        }
        }
    }
`

export const GET_BUILDING_BY_ID = `query GET_BUILDING_BY_ID($id: ID!) {
  getBuilding(id: $id) {
        key: id
        value: name
        floorArea
        salesOfficeId
      }
  }
`


export const UPDATE_BUILDING = `mutation UPDATE_BUILDING(
  $input: UpdateBuildingInput!
) {
  updateBuilding(input: $input) {
    floorArea
    name
    id
    accountId
    accountName
  }
}`


export const GET_BUILDING_METER_TOTAL_EQUIPMENT = `query GET_BUILDING_METER_TOTAL_EQUIPMENT($body: AWSJSON) {
    getBuildingTotalMeterEquipment(body: $body)
  }
`
export const getAssociatedMeterChargeData = `query getAssociatedMeterChargeData($body: AWSJSON) {
    getAssociatedMeterChargeData(body: $body)
  }
`

