import React from "react"
import Documents from "./AddDocuments"
import EditEnergyUsageReport from "./EUReport/EditEUReport"
import { getSearchParams } from "src/common/helperFunctions.js"

const CreateEditDocumentAndReport = ({ mode }) => {
	const { reportType } = getSearchParams()

    switch (reportType) {
		case "VariableRefrigerantFlowReport": // VRF Report
			// return <ViewVRFReport {...{ report, ...props }} setNotificationDialog={setNotificationDialog} />
		case "EnergyUsageReport": // Energy Usage
			return <EditEnergyUsageReport mode={mode} testParam="test params" />
		default:
			return <Documents mode={mode} />
	}
}

export default CreateEditDocumentAndReport