import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// import VideoLightbox from "../../../legacy/components/videos/lightbox"

import resource from '../../common/resource.js'
import { uri } from '../../common/helpers.js'

import './thumbnail.scss'
import { withTranslation } from 'react-i18next'

export class VideoThumbnail extends React.PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    thumbnail: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    length: PropTypes.string,
    // for exceptions in the video-library instance | TODO: is there a better way?
    parentComponent: PropTypes.string,
    // for aws URL -- to adjust to expanding video things with not-ideally-scalable beginning point -- only need to pass in if not TC
    productName: PropTypes.string,
    page: PropTypes.string,
    t: PropTypes.func,
    isPortal: PropTypes.bool
  }

  static defaultProps = {
    // default to /connect/ dir in AWS, which holds all the Trane Connect videos, so we don't have to change all formerly-built video imports
    productName: 'connect',
    isPortal: false
  }

  state = {
    isVideoOpen: false,
    // init with empty string; will be reset with URL (string) for each video thumbnail asset in componentDidMount
    thumbnail: ''
  }

  showVideo = () => {
    this.setState({ isVideoOpen: true })
  }

  hideVideo = () => {
    this.setState({ isVideoOpen: false })
  }

  async componentDidMount() {
    const { t, productName, thumbnail } = this.props
    const res = await resource(
      uri`/api/ui-protected-assets/temp-url?key=controls-videos/${(
        t(productName) || productName
      )
        .replace(/\s+/g, '-')
        .toLowerCase()}/thumbnails/${thumbnail}`
    ).get()
    this.setState({ thumbnail: res.url })
  }

  render() {
    const {
      src,
      title,
      description,
      parentComponent,
      length,
      productName,
      t,
      isPortal
    } = this.props

    return (
      <React.Fragment>
        {
          this.state.isVideoOpen && <></>
          //  <VideoLightbox close={this.hideVideo} src={src} productName={productName} isPortal={isPortal}/>
        }
        <a
          onClick={this.showVideo}
          className={`video-thumbnail ${
            parentComponent == 'video-library' && 'video-library'
          }`}
        >
          <div
            className="video-thumbnail-thumbnail"
            style={
              this.state.thumbnail
                ? { backgroundImage: `url(${this.state.thumbnail})` }
                : {}
            }
          >
            {length && <div className="video-thumbnail-length">{length}</div>}
          </div>
          {parentComponent !== 'video-library' && (
            <div className="video-thumbnail-info">
              <div className="video-thumbnail-title">{t(title) || title}</div>
              <div className="video-thumbnail-description">
                {t(description) || description}
              </div>
            </div>
          )}
        </a>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ ractiveApp: { page } }) => ({ page })

export default (connect(mapStateToProps, {}), withTranslation())(VideoThumbnail)
