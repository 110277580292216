import React from 'react'
import { AddSVGProps } from '../../Types/common'
import clsx from 'clsx'
import { SOLID_LINE } from '../LinePath/constants'
import './Line.css'

interface Point {
  x?: number
  y?: number
}

export type LineProps = {
  /** className to apply to line element. */
  className?: string
  /** reference to line element. */
  innerRef?: React.Ref<SVGLineElement>
  /** fill color applied to line element. */
  fill?: string
  /** Starting x,y point of the line. */
  from?: Point
  /** Ending x,y point of the line. */
  to?: Point
  stroke?: string
  dashType?: string
}

const Line = ({
  from = { x: 0, y: 0 },
  to = { x: 1, y: 1 },
  fill = 'transparent',
  stroke = 'black',
  dashType = SOLID_LINE,
  className,
  innerRef,
  onMouseEnter = null,
  onMouseLeave = null,
  ...restProps
}: AddSVGProps<LineProps, SVGLineElement>) => {
  const isRectilinear = from.x === to.x || from.y === to.y
  return (
    <line
      ref={innerRef}
      className={clsx(`line${dashType}`, className)}
      x1={from.x}
      y1={from.y}
      x2={to.x}
      y2={to.y}
      fill={fill}
      stroke={stroke}
      shapeRendering={isRectilinear ? 'crispEdges' : 'auto'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...restProps}
    />
  )
}

export default Line
