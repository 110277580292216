import { StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

export const titleStyles = StyleSheet.create({
	titleWrapper: {
		marginBottom: 20,
	},
	titleStyle: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingTop: 10,
		paddingBottom: 8,
		borderBottom: `1 solid ${colors.TBA_DARKER_GREY}`,
	},
	sTitle: {
		maxWidth: "60%",
		fontWeight: "bold",
		fontSize: 14,
	},
	sDates: {
		maxWidth: "40%",
		fontSize: 10,
	},
	equipmentsWrapper: {
		display: "flex",
		flexDirection: "row",
		paddingHorizontal: 4,
		paddingVertical: 6,
		fontSize: 10,
		backgroundColor: colors.TBA_LIGHTER_GREY,
	},
	equipmentsBody: {
		marginLeft: 2,
		fontWeight: "bold",
	},
})
