import styled from "styled-components"

export const Container = styled.div`
	margin: auto;
	height: 16px;
	font-size: 12px;
	text-align: center;
`

export const Color = styled.div`
	float: left;
	background-color: ${props => props.color.substr(0, 1) === "#" || props.color.substr(0, 3) === "rgb" ? "" : "#"}${props => props.color};
	height: 10px;
	width: 10px;
	margin-top: 3px;
	margin-right: 5px;
	border-radius: 5px;
`

export const Label = styled.div`
	float: left;
	height: 14px;
	margin: 1px auto 0 auto;
`

export const ElementContainer = styled.div`
	margin: 0 8px 0 8px;
	display: inline-block;
	cursor: pointer;
	opacity: ${props => props.disabledItems.includes(props.item.key) ? "0.5" : "1"};
`