export const light = {
  icon: {
    backgroundColor: '{colors.neutral.90}',
  },
  button: {
    color: '{colors.white}',
    focusOutlineColor: '{colors.blue.50}',
    focusedBeforeBorderColor: 'transparent',
    focusedBeforeBoxShadow: 'transparent',
  },
  buttonBefore: {
    backgroundColor: '{colors.neutral.20}',
    borderWidth: '0',
    width: '1.25rem',
    height: '1.25rem'
  }
}