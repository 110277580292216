import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import {
  isProduction,
  isProductionOrStaging,
  isBeta,
  isStaging
} from '../config'
import PAGE_NAMES, {
  ROLES,
  BUILDING_NAV,
  NOTIFICATIONS,
  IMAGE_EDITOR,
  APPLICATIONS,
  REPORT_EXCEPTION_ISSUE
} from '../pages'
import { WIDGET_ALL_TYPES } from 'src/constants'
// import { // usageTracker, USER_EVENTS } from "src/components/legacy/common/usage-tracking"
// import { PRIMARY_OFFERINGS } from "common/usage-tracking/common/primary-offerings"

// const { BUILDING_SETUP, UTILITY_BILLS, TENANT_SERVICES, NOTES, BUILDING_EVALUATION, DASHBOARDS, OPPORTUNITIES, BASELINE_LIBRARY, CONSULTATIONS,
// 	ISSUES_FINDINGS, REMOTE_ACCESS, AUTOMATED_TESTS, AUTOMATED_TEST_SUPPRESSION, LOAD_ANALYSIS, EQUIPMENT_SETUP,
// 	SERVICE_PROVIDERS, ORGANIZATION_MANAGEMENT, AUTOMATED_INSPECTIONS, ENERGY_INTENSITY, PORTFOLIO_SCORECARD, ENERGY_COST_SUMMARY, ENERGY_CONSUMPTION, ELECTRICAL_DEMAND, BUILDING_DATA } = USER_EVENTS

// Environments
const DEV = 'dev'
const STG = 'stg'
const BETA_STG = 'beta-stg'
const PROD = 'prod'
const BETA_PROD = 'beta-prod'
const ALL_ENVS = [DEV, STG, BETA_STG, PROD, BETA_PROD]
const isDev = !isProductionOrStaging()
const isOnlyDev = !isProductionOrStaging() && !isBeta()
function getCurrentEnv() {
  if (isStaging?.()) {
    return isBeta?.() ? BETA_STG : STG
  } else if (isProduction?.()) {
    return isBeta?.() ? BETA_PROD : PROD
  } else {
    return DEV
  }
}

export const MAIL_DOMAINS = [
  '@trane.com',
  '@irco.com',
  '@tranetechnologies.com',
  '@contractor.tranetechnologies.com',
  '@boland.com',
  '@damuth.com',
  '@tranebelgium.com'
]
export const isTraneUser = (email) =>
  // check if user from Trane/IR
  MAIL_DOMAINS.some((domain) => email?.toLowerCase().endsWith(domain))
const PAGES_SETTINGS = new Map([
  [
    PAGE_NAMES.DASHBOARDS,
    {
      trackingFunc() {
        // // usageTracker.trackEvent(DASHBOARDS.categoryName, DASHBOARDS.events.SELECT_DASHBOARD_NAV_ITEM)
      }
    }
  ],
  [
    PAGE_NAMES.WIDGET_LIBRARY,
    {
      transKey: 'dashboard:WidgetLibrary',
      trackingFunc() {
        // usageTracker.trackEvent(DASHBOARDS.categoryName, DASHBOARDS.events.SELECT_WIDGET_LIBRARY_NAV_ITEM)
      },
      allowedQueryParams: ['organization']
    }
  ],
  [
    PAGE_NAMES.OPPORTUNITIES,
    {
      transKey: 'opportunities:Opportunities',
      trackingFunc() {
        // usageTracker.trackEvent(OPPORTUNITIES.categoryName, OPPORTUNITIES.events.SELECT_OPPORTUNITIES_NAV_ITEM)
      },
      allowedQueryParams: [
        'organization',
        'location',
        'salesOffice',
        'startDate',
        'endDate',
        'locationIds',
        'showStatuses'
      ]
    }
  ],
  [
    PAGE_NAMES.KPI_OPPORTUNITY_REPORT,
    {
      transKey: 'building-evaluation:AnalyticFinding'
    }
  ],
  [
    PAGE_NAMES.KPI_OPPORTUNITY_REPORT,
    {
      allowedQueryParams: ['organization', 'location', 'diagnostic']
    }
  ],
  [
    PAGE_NAMES.FINDINGS,
    {
      transKey: 'opportunities:Issues/Findings',
      trackingFunc() {
        // usageTracker.trackEvent(OPPORTUNITIES.categoryName, ISSUES_FINDINGS.events.SELECT_ISSUES_FINDINGS_NAV_ITEM)
      },
      allowedQueryParams: [
        'allOrganizations',
        'organization',
        'location',
        'salesOffice',
        'startDate',
        'endDate',
        'locationIds',
        'showStatuses'
      ]
    }
  ],
  [
    `${PAGE_NAMES.FINDINGS}/add`,
    {
      allowedQueryParams: [
        'organization',
        'location',
        'equipment',
        'serviceAdvisoryType',
        'description',
        'chartName',
        'chartImage',
        'chartLink',
        'multipleExceptions'
      ]
    }
  ],
  [
    PAGE_NAMES.INTELLIGENT_SERVICE_CONTRACTS,
    {
      transKey: 'opportunities:IntelligentServicesContracts',
      allowedQueryParams: ['allOrganizations', 'organization', 'location']
    }
  ],
  [
    PAGE_NAMES.BUILDING_EVALUATION,
    {
      transKey: 'building-evaluation:BuildingEvaluation',
      trackingFunc() {
        // usageTracker.trackEvent(BUILDING_EVALUATION.categoryName, BUILDING_EVALUATION.events.SELECT_BUILDING_EVAL_NAV_ITEM)
      },
      allowedQueryParams: [
        'allOrganizations',
        'organization',
        'location',
        'salesOffice',
        'diagnostic'
      ]
    }
  ],
  [
    PAGE_NAMES.AUTOMATED_INSPECTIONS,
    {
      transKey: 'automated-inspections:AutomatedInspections',
      trackingFunc() {
        // usageTracker.trackEvent(AUTOMATED_INSPECTIONS.categoryName, AUTOMATED_INSPECTIONS.events.SELECT_AUTOMATED_INSPECTIONS_NAV_ITEM)
      },
      allowedQueryParams: [
        'organization',
        'location',
        'salesOffice',
        'diagnostic'
      ]
    }
  ],
  [
    PAGE_NAMES.AUTOMATED_INSPECTION_DETAILS,
    {
      allowedQueryParams: ['organization', 'location', 'diagnostic']
    }
  ],
  [
    PAGE_NAMES.PORTFOLIO_SCORECARD,
    {
      transKey: 'energy-performance:PortfolioScorecard',
      trackingFunc() {
        // usageTracker.trackEvent(PORTFOLIO_SCORECARD.categoryName, PORTFOLIO_SCORECARD.events.SELECT_PORTFOLIO_SCORECARD_NAV_OPTION)
      },
      allowedQueryParams: ['organization', 'location']
    }
  ],
  [
    PAGE_NAMES.ENERGY_COST_SUMMARY,
    {
      transKey: 'energy-performance:EnergyCostSummary',
      trackingFunc() {
        // usageTracker.trackEvent(ENERGY_COST_SUMMARY.categoryName, ENERGY_COST_SUMMARY.events.SELECT_ENERGY_COST_SUMMARY_NAV_OPTION)
      },
      allowedQueryParams: ['object', 'location', 'organization']
    }
  ],
  [
    PAGE_NAMES.ENERGY_CONSUMPTION,
    {
      transKey: 'energy-performance:EnergyConsumption',
      trackingFunc() {
        // usageTracker.trackEvent(ENERGY_CONSUMPTION.categoryName, ENERGY_CONSUMPTION.events.SELECT_ENERGY_CONSUMPTION_NAV_ITEM)
      },
      allowedQueryParams: [
        'object',
        'location',
        'organization',
        'type',
        'comparator',
        'unitOfMeasure',
        'initialLoad'
      ]
    }
  ],
  [
    PAGE_NAMES.ELECTRICAL_DEMAND,
    {
      transKey: 'energy-performance:ElectricalDemand',
      trackingFunc() {
        // usageTracker.trackEvent(ELECTRICAL_DEMAND.categoryName, ELECTRICAL_DEMAND.events.SELECT_ENERGY_DEMAND_NAV_ITEM)
      },
      allowedQueryParams: ['object', 'location', 'organization']
    }
  ],
  [
    PAGE_NAMES.ENERGY_HEAT_MAP,
    {
      allowedQueryParams: ['object', 'location', 'organization']
    }
  ],
  [
    PAGE_NAMES.LOAD_ANALYSIS,
    {
      transKey: 'energy-performance:LoadAnalysis',
      trackingFunc() {
        // usageTracker.trackEvent(LOAD_ANALYSIS.categoryName, LOAD_ANALYSIS.events.SELECT_LOAD_ANALYSIS_NAV_ITEM)
      },
      allowedQueryParams: ['object', 'location', 'organization']
    }
  ],
  [
    PAGE_NAMES.RATE_STRUCTURE_LIBRARY,
    {
      transKey: 'Rate Structure Library',
      allowedQueryParams: ['salesOffice']
    }
  ],
  [
    PAGE_NAMES.PORTFOLIO_SCORECARD,
    {
      transKey: 'Portfolio Scorecard',
      allowedQueryParams: ['location', 'organization']
    }
  ],
  [
    PAGE_NAMES.ELECTRIC_RATES,
    {
      allowedQueryParams: ['salesOffice']
    }
  ],
  [
    PAGE_NAMES.ELECTRIC_CHARGES,
    {
      allowedQueryParams: ['initialLoad']
    }
  ],
  [
    PAGE_NAMES.UTILITY_BILL_DATA,
    {
      transKey: 'Utility Bill Data',
      trackingFunc() {
        // usageTracker.trackEvent(UTILITY_BILLS.categoryName, UTILITY_BILLS.events.SELECT_UTILITY_BILL_DATA_NAV_OPTION)
      },
      allowedQueryParams: ['object', 'location', 'organization']
    }
  ],
  [
    PAGE_NAMES.ENERGY_INTENSITY,
    {
      transKey: 'Energy Intensity',
      trackingFunc() {
        // usageTracker.trackEvent(ENERGY_INTENSITY.categoryName, ENERGY_INTENSITY.events.SELECT_ENERGY_INTENSITY_NAV_ITEM)
      },
      allowedQueryParams: ['object', 'location', 'organization']
    }
  ],
  [
    PAGE_NAMES.REPORTS,
    {
      transKey: 'reports:DocumentReports',
      allowedQueryParams: [
        'organization',
        'location',
        'salesOffice',
        'reportType'
      ]
    }
  ],
  [
    PAGE_NAMES.BUILDING_DATA,
    {
      transKey: 'building-performance:BuildingData',
      trackingFunc() {
        // usageTracker.trackEvent(BUILDING_DATA.categoryName, BUILDING_DATA.events.SELECT_BUILDING_DATA_NAV_ITEM)
      },
      allowedQueryParams: ['organization', 'trendChart']
    }
  ],
  [
    PAGE_NAMES.AUTOMATED_TEST_SETTINGS,
    {
      transKey: 'building-performance:AutomatedTestSettings',
      trackingFunc() {
        // usageTracker.trackEvent(AUTOMATED_TESTS.categoryName, AUTOMATED_TESTS.events.CLICK_ATS_NAV_ITEM)
      },
      allowedQueryParams: [
        'object',
        'location',
        'organization',
        'automatedTest'
      ]
    }
  ],
  [
    PAGE_NAMES.AUTOMATED_TEST_SETTINGS_HISTORY,
    {
      allowedQueryParams: [
        'object',
        'location',
        'organization',
        'automatedTest'
      ]
    }
  ],
  [
    PAGE_NAMES.AUTOMATED_TEST_SUPPRESSIONS,
    {
      transKey: 'building-performance:AutomatedTestSuppressions',
      trackingFunc() {
        // usageTracker.trackEvent(AUTOMATED_TEST_SUPPRESSION.categoryName, AUTOMATED_TEST_SUPPRESSION.events.CLICK_ATS_HISTORY_NAV_ITEM)
      },
      allowedQueryParams: ['location', 'organization']
    }
  ],
  [
    PAGE_NAMES.TENANT_SERVICES,
    {
      transKey: 'facility-management:TenantServices',
      trackingFunc() {
        // usageTracker.trackEvent(TENANT_SERVICES.categoryName, TENANT_SERVICES.events.SELECT_TENANT_SERVICES_NAV_ITEM)
      },
      allowedQueryParams: ['allOrganizations', 'organization']
    }
  ],
  [
    PAGE_NAMES.CONNECT_DEVICE,
    {
      transKey: 'connect-device:ConnectToDevice',
      trackingFunc() {
        // usageTracker.trackEvent(REMOTE_ACCESS.categoryName, REMOTE_ACCESS.events.SELECT_CONNECT_TO_A_DEVICE_NAV_ITEM)
      },
      allowedQueryParams: ['organization', 'location', 'salesOffice']
    }
  ],
  [
    PAGE_NAMES.MANAGE_DEVICES,
    {
      transKey: 'connect-device:ManageDevices'
    }
  ],
  [
    PAGE_NAMES.DEVICE_DETAILS,
    {
      allowedQueryParams: ['organization', 'location']
    }
  ],
  [
    PAGE_NAMES.BUILDING_HOME_PAGE,
    {
      transKey: 'Building Home Page',
      allowedQueryParams: ['organization', 'location']
    }
  ],
  [
    PAGE_NAMES.BUILDING_SETUP,
    {
      transKey: 'building-configuration:BuildingSetup',
      trackingFunc() {
        // usageTracker.trackEvent(BUILDING_SETUP.categoryName, BUILDING_SETUP.events.SELECT_BUILDING_SETUP_NAV_ITEM)
      },
      allowedQueryParams: ['allOrganizations', 'organization', 'location']
    }
  ],
  [
    PAGE_NAMES.ALARM_NOTIFICATION,
    {
      transKey: 'Alarm Notification',
      allowedQueryParams: [
        'organization',
        'location',
        'salesOffice',
        'locationIds'
      ]
    }
  ],
  [
    PAGE_NAMES.EQUIPMENT_SETUP,
    {
      transKey: 'building-configuration:EquipmentSetup',
      trackingFunc() {
        // usageTracker.trackEvent(EQUIPMENT_SETUP.categoryName, EQUIPMENT_SETUP.events.SELECT_EQUIPMENT_SETUP_NAV_OPTION)
      },
      allowedQueryParams: ['organization', 'location']
    }
  ],
  [
    PAGE_NAMES.EQUIPMENT_LEVEL_AGGREGATION,
    {
      allowedQueryParams: ['organization', 'location', 'diagnostic']
    }
  ],
  [
    PAGE_NAMES.NOTES,
    {
      transKey: 'common:Notes',
      trackingFunc() {
        // usageTracker.trackEvent(NOTES.categoryName, NOTES.events.SELECT_NOTES_NAV_ITEM)
      },
      allowedQueryParams: ['organization', 'location', 'noteType', 'q']
    }
  ],
  [
    `${PAGE_NAMES.NOTES}/add`,
    {
      allowedQueryParams: ['organization', 'location', 'equipment']
    }
  ],
  [
    PAGE_NAMES.METER_SETTINGS_NEW,
    {
      transKey: 'Meter Settings New',
      allowedQueryParams: ['organization', 'location']
    }
  ],
  [
    PAGE_NAMES.EVENT_MARKERS,
    {
      transKey: 'building-configuration:EventMarkers',
      allowedQueryParams: ['organization', 'location']
    }
  ],
  [
    PAGE_NAMES.BASELINE_LIBRARY,
    {
      transKey: 'building-configuration:BaselineLibrary',
      trackingFunc() {
        // usageTracker.trackEvent(BASELINE_LIBRARY.categoryName, BASELINE_LIBRARY.events.SELECT_BASELINE_LIBRARY_NAV_ITEM)
      },
      allowedQueryParams: ['organization', 'location']
    }
  ],
  [
    PAGE_NAMES.RC_CUSTOMER_DASHBOARD,
    {
      transKey: 'Customer Dashboard',
      allowedQueryParams: ['organization', 'location']
    }
  ],
  [
    PAGE_NAMES.RC_ADMINISTRATION,
    {
      transKey: 'Administration',
      allowedQueryParams: ['organization', 'location']
    }
  ],
  [
    PAGE_NAMES.RC_AGENT_CONFIGURATION,
    {
      transKey: 'Agent Configuration'
    }
  ],
  [
    PAGE_NAMES.RC_DATA_VALIDATION,
    {
      transKey: 'Data Validation',
      allowedQueryParams: ['organization', 'location']
    }
  ],
  [
    PAGE_NAMES.USER_MANAGEMENT,
    {
      transKey: 'admin-tools:UserManagement'
    }
  ],
  [
    PAGE_NAMES.USER_MANAGEMENT_FULL,
    {
      transKey: 'admin-tools:UserManagementFull'
    }
  ],
  [
    PAGE_NAMES.USER_MANAGEMENT_NEW,
    {
      // TODO: Add translation key
      transKey: 'User Management New'
    }
  ],
  [
    PAGE_NAMES.USER_MANAGEMENT_SUPPORT,
    {
      // TODO: Add translation key
      transKey: 'User Management (Tech Support)'
    }
  ],
  [
    PAGE_NAMES.USER_MANAGEMENT_CREATE_USER,
    {
      transKey: 'admin-tools:UserManagementCreateUser'
    }
  ],
  [
    PAGE_NAMES.USER_MANAGEMENT_EDIT_USER,
    {
      transKey: 'User Management New Edit User'
    }
  ],
  [
    PAGE_NAMES.USER_MANAGEMENT_USER_DETAILS_PAGE,
    {
      transKey: 'admin-tools:UserManagementUserDetailsPage'
    }
  ],
  [
    PAGE_NAMES.CC_4_USER_MANAGEMENT,
    {
      transKey: 'admin-tools:CC4UserManagement'
    }
  ],
  [
    PAGE_NAMES.SERVICE_COMPANIES,
    {
      transKey: 'admin-tools:ServiceProviderManagement',
      trackingFunc() {
        // usageTracker.trackEvent(SERVICE_PROVIDERS.categoryName, SERVICE_PROVIDERS.events.SELECT_SERVICE_PROVIDER_MANAGEMENT_NAV_ITEM)
      }
    }
  ],
  [
    PAGE_NAMES.ORGANIZATION_MANAGEMENT,
    {
      transKey: 'admin-tools:OrganizationManagement',
      trackingFunc() {
        // usageTracker.trackEvent(ORGANIZATION_MANAGEMENT.categoryName, ORGANIZATION_MANAGEMENT.events.SELECT_ORGANIZATION_MANAGEMENT_NAV_ITEM)
      }
    }
  ],
  [
    PAGE_NAMES.CONTRACTS_AND_OFFERINGS,
    {
      transKey: 'contracts-and-offerings:contractsAndOfferings',
      allowedQueryParams: [
        'organization',
        'location',
        'salesOffice',
        'offeringRequestId',
        'dialogType'
      ]
    }
  ],
  [
    PAGE_NAMES.PRODUCT_ANNOUNCEMENTS,
    {
      transKey: 'announcements-management:ProductAnnouncements'
    }
  ],
  [
    PAGE_NAMES.COMMAND_CENTER,
    {
      transKey: 'building-performance:CommandCenter'
    }
  ],
  [
    PAGE_NAMES.GRIDFLEX_PARTICIPATING_BUILDINGS,
    {
      transKey: 'grid-services:ParticipatingBuildings',
      trackingFunc() {
        // usageTracker.trackEvent("", "Select Participating Buildings Nav Option", { "Primary Offering": PRIMARY_OFFERINGS["GRIDFLEX"], "Previous Page": window.location.pathname.replace("/gridflex", "").split("/")[1] })
      }
    }
  ],
  [
    PAGE_NAMES.EVENT_DETAILS,
    {
      allowedQueryParams: ['initialLoad']
    }
  ],
  [
    PAGE_NAMES.GRIDFLEX_EVENT_DETAILS,
    {
      allowedQueryParams: ['initialLoad']
    }
  ],
  [
    PAGE_NAMES.GRIDFLEX_PROGRAMS,
    {
      transKey: 'grid-services:Programs',
      trackingFunc() {
        // usageTracker.trackEvent("", "Select Programs Nav Option", { "Primary Offering": PRIMARY_OFFERINGS["GRIDFLEX"], "Previous Page": window.location.pathname.replace("/gridflex", "").split("/")[1] })
      }
    }
  ],
  [
    PAGE_NAMES.GRIDFLEX_DAY_AHEAD_OFFERING,
    {
      allowedQueryParams: ['location']
    }
  ],
  [
    PAGE_NAMES.DAY_AHEAD_OFFERING,
    {
      allowedQueryParams: ['location']
    }
  ],
  [
    PAGE_NAMES.DEVICE_MANAGEMENT,
    {
      allowedQueryParams: ['organization']
    }
  ],
  [
    PAGE_NAMES.GRIDFLEX_DEVICE_MANAGEMENT,
    {
      transKey: 'grid-services:DeviceManagement',
      trackingFunc() {
        // usageTracker.trackEvent("", "Select Device Management Nav Option", { "Primary Offering": PRIMARY_OFFERINGS["GRIDFLEX"], "Previous Page": window.location.pathname.replace("/gridflex", "").split("/")[1] })
      },
      allowedQueryParams: ['organization']
    }
  ],
  [
    PAGE_NAMES.SHARE_MY_DATA,
    {
      allowedQueryParams: ['authorizationCode', 'state', 'location']
    }
  ],
  [
    PAGE_NAMES.GRIDFLEX_SHARE_MY_DATA,
    {
      transKey: 'grid-services:ShareMyData',
      trackingFunc() {
        // usageTracker.trackEvent("", "Select Share My Data Nav Option", { "Primary Offering": PRIMARY_OFFERINGS["GRIDFLEX"], "Previous Page": window.location.pathname.replace("/gridflex", "").split("/")[1] })
      },
      allowedQueryParams: ['authorizationCode', 'state', 'location']
    }
  ],
  [
    PAGE_NAMES.DIGITAL_MAINTENANCE,
    {
      transKey: 'digital-maintenance:DigitalMaintenance',
      trackingFunc() {
        // usageTracker.trackEvent("", "Select digital-maintenance:DigitalMaintenance Nav Option", { "Primary Offering": PRIMARY_OFFERINGS["GRIDFLEX"], "Previous Page": window.location.pathname.replace("/spectacle", "").split("/")[1] })
      }
    }
  ]
])
const getPageItemConfig = (page) => PAGES_SETTINGS.get(page)
export const getMainPageTitle = (page) =>
  getPageItemConfig(page)?.transKey || null
export const getAllowedQueryParams = (page) =>
  page === '*'
    ? ['startDate', 'endDate']
    : PAGES_SETTINGS.get(page)?.allowedQueryParams || []

