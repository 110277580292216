import Container from './Container'
import { useEffect, useState } from 'react'
import { IConfirmationType } from './types'
import { LIST_USERTYPES } from './graphql'
import { useQuery } from 'src/hooks/APIHooks'

export const initialValues = {
  name: '',
  description: '',
  authUserTypes: []
}

export const UserTypeManagement = () => {
  const [mode, setMode] = useState(null)
  const [reloadEvents, setReloadEvents] = useState(false)
  const [input, setInput] = useState(initialValues)
  const [rows, setRows] = useState([])
  const [deleteId, setDeleteId] = useState<string>('')
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [confirmationType, setConfirmationType] =
    useState<IConfirmationType>('')

  const {
    data: data1,
    refetch: refetchData1,
    responseTime: responseTime1
  } = useQuery({
    query: LIST_USERTYPES,
    errorPolicy: 'all',
    dataPath: 'data.listUserTypes.items'
  })

  useEffect(() => {
    if (reloadEvents) {
      setReloadEvents(false)
      refetchData1()
    }
  }, [reloadEvents])

  useEffect(() => {
    setRows(
      data1
        ? data1.map((d) => ({
            ...d,
            authUserTypesDisplay: d.authUserTypes.map(d => {
              const index = data1.findIndex(d1 => d === d1.id)
              if (index !== -1) {
                return data1[index].name
              } else {
                return d
              }
            }).join(', ')
          }))
        : []
    )
  }, [data1])

  return (
    <Container
      setMode={(e) => setMode(e)}
      mode={mode}
      rows={rows}
      responseTime={responseTime1}
      reloadEvents={reloadEvents}
      setReloadEvents={(e) => setReloadEvents(e)}
      deleteId={deleteId}
      setDeleteId={(e) => setDeleteId(e)}
      setShowDeleteDialog={(e) => setShowDeleteDialog(e)}
      showDeleteDialog={showDeleteDialog}
      input={input}
      setInput={(e) => setInput(e)}
      showConfirmation={showConfirmation}
      confirmationType={confirmationType}
      setConfirmationType={(e) => setConfirmationType(e)}
      setShowConfirmation={(e) => setShowConfirmation(e)}
      initialValues={initialValues}
    />
  )
}
