import styled from 'styled-components'

export const StatusEditWrapper = styled.section`
  .statuses {
    label {
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
    & > div {
      flex-flow: row wrap;
    }
  }
`
