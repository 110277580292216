export const parseUserData = (userData) => {
  if (!userData) {
    return {}
  }
  return {
    ...userData,
    // locationIds:[],
      // [...(userData.adminBuildingIds || []), ...(userData.buildingIds || [])] ||
      // [],
    // organizationIds:[],
      // [...(userData.adminAccountIds || []), ...(userData.accountIds || [])] ||
      // [],
    // salesOfficeIds:[],
      // [
      //   ...(userData.adminSalesOfficeIds || []),
      //   ...(userData.salesOfficeIds || [])
      // ] || [],
    // adminLocationIds: userData.buildingIds || [],
    // adminSalesOfficeIds: userData.adminSalesOfficeIds || [],
    // adminOrganizationIds: userData.adminAccountIds || [],
    // superAdminLocationIds: userData.adminBuildingIds || [],
    // superAdminOrganizationIds: userData.adminAccountIds || [],
    // superAdminSalesOfficeIds: userData.adminSalesOfficeIds || [],
    // roles: userData.roles || [],
    // adminRoles: userData.roles || [],
    // superAdminRoles: userData.roles || [],
    // adminLocationIds: [],
    // adminSalesOfficeIds:[],
    // adminOrganizationIds: [],
    // superAdminLocationIds:[],
    // superAdminOrganizationIds: [],
    // superAdminSalesOfficeIds: [],
    // roles: [],
    // adminRoles: [],
    // superAdminRoles: [],
    createdOn: userData.createdAt,
    defaultUniqueAssetId: userData?.defaultUniqueAssetId,
    type: {
      name: userData?.type?.name,
      id: userData?.type?.id,
      authUserTypes: userData?.type?.authUserTypes
    }
  }
}
