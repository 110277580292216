import React, { useState, Fragment, useEffect } from 'react'
import { func } from 'prop-types'
import * as Color from '@trane/trane-components/dist/color.js'
import * as Spacing from '@trane/trane-components/dist/spacing.js'
import { Block } from '@trane/trane-components/dist/simple/layout/index.js'
import {
  MdMoreVert,
  MdArrowDropDown,
  MdArrowDropUp
} from 'react-icons/md/index.esm.js'
import { Button } from '@trane/trane-components/dist/simple/button/index.js'
import { BiFilter, BiPlus, BiSearchAlt2 } from 'react-icons/bi/index.esm.js'
import { TextInput } from '@trane/trane-components/dist/simple/input/textinput.js'
import FirstIcon from '@trane/trane-components/dist/assets/icons/Arrow/First.svg'
import FirstDisabledIcon from '@trane/trane-components/dist/assets/icons/Arrow/First_Disabled.svg'
import BackIcon from '@trane/trane-components/dist/assets/icons/Arrow/Back.svg'
import BackDisabledIcon from '@trane/trane-components/dist/assets/icons/Arrow/Back_Disabled.svg'
import NextIcon from '@trane/trane-components/dist/assets/icons/Arrow/Next.svg'
import NextDisabledIcon from '@trane/trane-components/dist/assets/icons/Arrow/Next_Disabled.svg'
import LastIcon from '@trane/trane-components/dist/assets/icons/Arrow/Last.svg'
import LastDisabledIcon from '@trane/trane-components/dist/assets/icons/Arrow/Last_Disabled.svg'
import TableRowItem from './tableRow.js'
import { LoadTime } from 'src/components/loadTime'
import styles from 'src/denali-components/Table/table.module.scss'
import { TableHeader } from 'src/denali-components/Table/TableHeader'
import { Pagination } from 'src/denali-components/Table/Pagination'
import { Skeleton } from 'src/denali-components/Table/Skeleton'
import Selectrix from 'src/denali-ui/components/Selectrix'
import {
  NoTableData,
  NoTableDataTitle,
  NoTableDataAction
} from 'src/denali-components/Table/NoTableData'

import {
  TableHeadRow,
  TableHead,
  HeadControl,
  PaginationText,
  OrderArrow,
  TableStyles,
  Title,
  ActionIcon,
  HeaderDots,
  FiltersCount,
  FilterLabel,
  RowCount,
  ClearFilters,
  PaginationIcon,
  NoRows,
  TableContainer,
  ExtraInputStyles,
  Spinner,
} from './components.js'
import translate, { TranslateComponent } from 'src/common/translations'
import { sortBy } from 'lodash'
import TraneTheme from 'src/denali-ui/components/ThemeProvider'

export const Table = ({
  paginationOptions = [30, 60, 90],
  rows,
  rowAction,
  updateRows,
  title,
  action,
  clearFilters,
  actionText,
  tableControl,
  filtersList,
  search,
  searchPlaceholderText,
  header,
  rowControl,
  subDataOpenFunction,
  subDataCloseFunction,
  fixedLayout = true,
  loadTime,
  onUpdateControls,
  showSpinner,
  totalItems,
  rowCheckbox,
  checkboxClick,
  checkboxChecked,
  rowEndCheckbox,
  checkboxEndClick,
  checkboxEndChecked,
  tableWidth,
  columnOrderDown = true,
  isShowPagination = true,
  activeColumn = 0,
  tableRowOverflowHidden = true,
  isFilterChange = false,
  testName,
  tableClassName,
  disableTableScroll = true,
  isDenaliTable = false,
  isTranslateFilters = true,
  isGap = false,
  listDataIsLoading,
  noTableDataTitleText,
  noTableDataAction
}) => {
  // This used to show Pagination or not
  const MINIMUM_ROW_COUNT = 30
  const [tableControls, setTableControls] = useState({
    activeColumn: activeColumn,
    columnOrderDown: columnOrderDown,
    paginationCount: paginationOptions[0],
    page: 0,
    filters: filtersList,
    searchText: ''
  })
  const [showFilters, setShowFilters] = useState(false)
  const [rowPopup, setRowPopup] = useState(false)
  const rightAlignedColumns = []
  const pages = Math.ceil(
    (totalItems || rows?.length || 0) / tableControls.paginationCount
  )
  const optionsTranslated = {
    search: translate(searchPlaceholderText ? searchPlaceholderText : 'Search'),
    filters: translate('Filters'),
    items: translate('Items'),
    clearFilter: translate('Clear Filters'),
    view: translate('View'),
    noRecords: translate('No records found')
  }

  useEffect(() => {
    onUpdateControls?.(tableControls)
  }, [tableControls])

  useEffect(() => {
    updateRows?.(rows)
  }, [rows])

  // Returns the list of page options.
  const setPageOptions = () => {
    let pageOptions = []

    if (!isDenaliTable) {
      pageOptions = [
        {
          icon: FirstIcon,
          disabledicon: FirstDisabledIcon,
          action: () => setTableControls({ ...tableControls, page: 0 }),
          disabled: tableControls.page === 0
        },
        {
          icon: BackIcon,
          disabledicon: BackDisabledIcon,
          action: () =>
            setTableControls({
              ...tableControls,
              page: tableControls.page - 1
            }),
          disabled: tableControls.page === 0
        },
        {
          icon: NextIcon,
          disabledicon: NextDisabledIcon,
          action: () =>
            setTableControls({
              ...tableControls,
              page: tableControls.page + 1
            }),
          disabled: tableControls.page === pages - 1
        },
        {
          icon: LastIcon,
          disabledicon: LastDisabledIcon,
          action: () => setTableControls({ ...tableControls, page: pages - 1 }),
          disabled: tableControls.page === pages - 1
        }
      ]
    }

    return pageOptions
  }

  const pageOptions = setPageOptions()

  const ClickPaginationCount = (option) => {
    setTableControls({ ...tableControls, page: 0, paginationCount: option })
  }

  const OrderColumn = (callback, key, title, action) => {
    setTableControls({
      ...tableControls,
      activeColumn: key,
      columnOrderDown: !tableControls.columnOrderDown
    })
  }

  const handleDenaliSort = (activeColumnNumber, isColumnOrderDown) => {
    setTableControls({
      ...tableControls,
      activeColumn: activeColumnNumber,
      columnOrderDown: isColumnOrderDown
    })
  }

  const clearFilter = () => {
    const resetFilters = tableControls.filters.map((filter) => ({
      ...filter,
      selectedValue: null
    }))

    setTableControls({
      ...tableControls,
      filters: resetFilters,
      filtersCount: 0
    })

    clearFilters?.()
    setShowFilters(!showFilters)
  }

  const FilterChange = (id, value) => {
    const newFilters = [...tableControls.filters]
    const index = newFilters.findIndex((f) => f.id === id)
    const existingValue = newFilters[index].selectedValue
    newFilters[index].selectedValue = value
    // filtersCount may be initialized as null based on the entry point to this file
    // to increment/decrement the count correctly we have the fall back of 0 and 1 respectively

    if (
      value !== 'default' &&
      (!existingValue || existingValue === 'default')
    ) {
      setTableControls({
        ...tableControls,
        filters: newFilters,
        page: 0,
        filtersCount: (tableControls.filtersCount ?? 0) + 1
      })
    } else if (value === 'default') {
      setTableControls({
        ...tableControls,
        filters: newFilters,
        page: 0,
        filtersCount: (tableControls.filtersCount ?? 1) - 1
      })
    } else {
      setTableControls({ ...tableControls, filters: newFilters, page: 0 })
    }
  }

  useEffect(() => {
    if (isFilterChange) {
      setTableControls((state) => ({ ...state, filters: filtersList }))
    }
  }, [filtersList])

  return (
    <Block className={isDenaliTable ? styles.denaliTable : ''}>
      {!isDenaliTable && title ? (
        <Block display="flex" justify="space-between" align="center">
          <Title>{title}</Title>
          {action && (
            <Block width="auto" display="flex" align="center">
              <Button onClick={action}>
                <ActionIcon>
                  <BiPlus />
                  {actionText}
                </ActionIcon>
              </Button>
              {tableControl && (
                <Block
                  width="1px"
                  height="22px"
                  margin={`0 ${Spacing.SPACING_06}`}
                  background={Color.DISABLED_BG}
                />
              )}
              {tableControl && (
                <HeaderDots onClick={tableControl}>
                  <MdMoreVert />
                </HeaderDots>
              )}
            </Block>
          )}
        </Block>
      ) : (
        ''
      )}
      {!isDenaliTable && (tableControls.filters || search) && (
        <div data-testid={`${testName}-table-controls-container`}>
          <Block
            display="flex"
            justify="flex-start"
            align="center"
            margin={`0 0 ${Spacing.SPACING_04} 0`}
          >
            {search && (
              <Block width="auto" margin={`0 ${Spacing.SPACING_05} 0 0`}>
                <ExtraInputStyles dataTextid="search-textbox">
                  <TextInput
                    onChange={(e) =>
                      setTableControls({
                        ...tableControls,
                        page: 0,
                        searchText: e.target.value
                      })
                    }
                    testName={`${testName}-table-search`}
                    value={tableControls.searchText}
                    placeholder={optionsTranslated.search}
                    icon={<BiSearchAlt2 />}
                  />
                </ExtraInputStyles>
              </Block>
            )}
            {tableControls.filters && (
              <Fragment>
                <Button
                  type={showFilters ? 'primary' : 'tertiary'}
                  dataTestId={`${testName}-filter-select`}
                  onClick={() => setShowFilters(!showFilters)}
                >
                  <ActionIcon>
                    {tableControls.filtersCount > 0 ? (
                      <FiltersCount>{tableControls.filtersCount}</FiltersCount>
                    ) : (
                      <BiFilter />
                    )}
                    {optionsTranslated.filters}
                  </ActionIcon>
                </Button>
                <RowCount style={{ paddingLeft: isGap ? '20px' : '' }}>
                  {`${rows?.length > 9999 ? '9999+' : totalItems} ` +
                    optionsTranslated.items}
                </RowCount>
              </Fragment>
            )}
            <LoadTime loadTime={loadTime} />
          </Block>
        </div>
      )}
      {!isDenaliTable && showFilters && (
        <TraneTheme>
          <Block
            display="grid"
            columns="calc(20% - 13px) calc(20% - 13px) calc(20% - 12px) calc(20% - 13px) calc(20% - 13px)"
            columnGap={Spacing.SPACING_05}
            rowGap={Spacing.SPACING_04}
            margin={`0 0 ${Spacing.SPACING_04}`}
            style={{ zIndex: 99 }}
          >
            {tableControls.filters.map((filter, key) => {
              const options = sortBy(
                filter.options?.map((s) => ({
                  key: s.value,
                  value: s.name
                })),
                ['value']
              )

              return (
                <Block
                  key={`Table-Filter-${key}-${filter.label}-${filter.name}`}
                  display="flex"
                  flexDirection="column"
                >
                  {filter.label && (
                    <FilterLabel htmlFor={filter.id}>
                      <TranslateComponent>{filter.label}</TranslateComponent>:
                    </FilterLabel>
                  )}

                  <Selectrix
                    name={filter.name}
                    id={filter.id}
                    multiple={filter.multiple}
                    inputPlaceholder={'- Search -'}
                    placeholder={filter.defaultLabel}
                    sort={false}
                    fullWidth={true}
                    value={
                      filter?.selectedValue !== 'default'
                        ? filter?.selectedValue
                        : filter.defaultLabel
                    }
                    onChange={(e) => {
                      FilterChange(
                        filter.id,
                        e?.target?.value || filter.defaultLabel
                      )
                    }}
                    options={options}
                    searchable={true}
                    searchBoxInside={true}
                    searchIndex={false}
                    lifo={true}
                    allowCheckAll={true}
                  />
                </Block>
              )
            })}
          </Block>
        </TraneTheme>
      )}
      {isDenaliTable && (search || tableControls.filters) && (
        <TableHeader
          search={search}
          filters={tableControls.filters}
          onSearchChange={(event) => {
            setTableControls({
              ...tableControls,
              page: 0,
              searchText: event.target.value
            })
          }}
          onFilterChange={(event, filter, value) => {
            FilterChange(filter.id, value)
          }}
          header={header}
          selectedColumn={tableControls.activeColumn}
          isAscending={tableControls.columnOrderDown}
          handleSort={handleDenaliSort}
        />
      )}
      {!isDenaliTable && tableControls.filtersCount > 0 && (
        <ClearFilters onClick={clearFilter}>
          {optionsTranslated.clearFilter}
        </ClearFilters>
      )}

      <TableContainer
        data-testid={`${testName}-table`}
        disableScroll={disableTableScroll}
      >
        <TableStyles
          className={`${tableClassName} ${styles.table}`}
          tableWidth={tableWidth}
        >
          <thead data-testid={`${testName}-table-header`}>
            <TableHeadRow>
              {header?.map((head, headerkey) => {
                if (head.alignRight) {
                  rightAlignedColumns.push(headerkey)
                }
                return (
                  <TableHead
                    alignRight={head.alignRight && head.alignRight}
                    active={tableControls.activeColumn === headerkey}
                    key={`Table-Head-${headerkey}`}
                    maxWidth={head?.maxWidth}
                    width={head?.width}
                    fixedLayout={fixedLayout}
                    className={head.tableHeaderIcon}
                  >
                    {head?.customHeaderComponent
                      ? head.customHeaderComponent(head)
                      : head.title}

                    {!head.disabled && head?.canSort !== false && (
                      <HeadControl
                        className={head.tableControlIcon}
                        onClick={() =>
                          OrderColumn(
                            head.callback,
                            headerkey,
                            head.title,
                            head.action
                          )
                        }
                      >
                        <OrderArrow
                          active={
                            !tableControls.columnOrderDown &&
                            tableControls.activeColumn === headerkey
                          }
                        >
                          <MdArrowDropUp />
                        </OrderArrow>
                        <OrderArrow
                          active={
                            tableControls.columnOrderDown &&
                            tableControls.activeColumn === headerkey
                          }
                        >
                          <MdArrowDropDown />
                        </OrderArrow>
                      </HeadControl>
                    )}
                  </TableHead>
                )
              })}
              {rowControl && <TableHead />}
            </TableHeadRow>
          </thead>
          <tbody data-testid={`${testName}-table-body`}>
            {isDenaliTable && listDataIsLoading ? (
              <Skeleton />
            ) : showSpinner ? (
              <tr>
                <td colSpan={100} style={{ textAlign: 'center' }}>
                  <Spinner style={{ fontSize: '50px' }} />
                </td>
              </tr>
            ) : (
              rows?.map((row, rowkey) => {
                return (
                  <TableRowItem
                    id={`Table-Row-${tableControls.paginationCount}-${tableControls.page}-${rowkey}`}
                    key={`Table-RowItem-${tableControls.paginationCount}-${tableControls.page}-${rowkey}`}
                    header={header}
                    rowkey={`${tableControls.paginationCount}-${tableControls.page}-${rowkey}`}
                    rowCheckbox={rowCheckbox}
                    rowEndCheckbox={rowEndCheckbox}
                    row={row}
                    rowAction={rowAction}
                    subDataOpenFunction={subDataOpenFunction}
                    subDataCloseFunction={subDataCloseFunction}
                    rowControl={rowControl}
                    tableControls={tableControls}
                    setRowPopup={(p) => setRowPopup(p)}
                    rowPopup={rowPopup}
                    fixedLayout={fixedLayout}
                    checkboxClick={(r) => checkboxClick(r)}
                    checkboxChecked={(e) => checkboxChecked(e)}
                    checkboxEndClick={(r) => checkboxEndClick(r)}
                    checkboxEndChecked={(e) => checkboxEndChecked(e)}
                    tableRowOverflowHidden={tableRowOverflowHidden}
                    testName={testName}
                    isDenaliTable={isDenaliTable}
                  />
                )
              })
            )}
          </tbody>
        </TableStyles>
      </TableContainer>

      {!showSpinner && rows?.length === 0 && (
        <>
          {isDenaliTable && (
            <NoTableData>
              <NoTableDataTitle>
                {noTableDataTitleText ?? 'No records found'}
              </NoTableDataTitle>
              <NoTableDataAction
                isFilterActive={tableControls.filtersCount > 0}
                clearFilter={clearFilter}
              >
                {noTableDataAction}
              </NoTableDataAction>
            </NoTableData>
          )}
          {!isDenaliTable && (
            <NoRows>
              <span>{optionsTranslated.noRecords}</span>
            </NoRows>
          )}
        </>
      )}

      {!isDenaliTable && (
        <Block
          margin={`${Spacing.SPACING_06} 0 0 0`}
          display="flex"
          justify="space-between"
          flexDirection="row"
          align="center"
        >
          <Block width="auto" display="flex" flexDirection="row">
            {totalItems > MINIMUM_ROW_COUNT && (
              <>
                <PaginationText>{optionsTranslated.view}</PaginationText>
                {paginationOptions &&
                  paginationOptions.map((option, key) => (
                    <PaginationText
                      key={`Table-Pagination-Option-${option}-${key}`}
                      onClick={() => ClickPaginationCount(option)}
                      color={
                        tableControls.paginationCount === option
                          ? Color.PRIMARY_BLUE
                          : Color.GREY
                      }
                      selected={tableControls.paginationCount === option}
                      margin={`0 ${Spacing.SPACING_02}`}
                      padding="0 2px 6px 2px"
                      cursor="pointer"
                      weight={
                        tableControls.paginationCount === option ? '700' : '100'
                      }
                    >
                      {option}
                    </PaginationText>
                  ))}
              </>
            )}
          </Block>
          <Block width="auto" display="flex" flexDirection="row" align="center">
            {totalItems > MINIMUM_ROW_COUNT && (
              <>
                <PaginationText weight="100">
                  <TranslateComponent>{`Page ${
                    tableControls.page + 1
                  } of ${pages}`}</TranslateComponent>
                </PaginationText>
                {pageOptions.map((option, key) => {
                  if (!option.disabled) {
                    return (
                      <PaginationIcon
                        key={`Table-PageOption-${key}`}
                        onClick={option.action}
                      >
                        <img src={option.icon} alt={option.alt} />
                      </PaginationIcon>
                    )
                  }
                  return (
                    <PaginationIcon
                      key={`Table-PageOption-${key}`}
                      disabled={true}
                    >
                      <img src={option.disabledicon} alt="next" />
                    </PaginationIcon>
                  )
                })}
              </>
            )}
          </Block>
        </Block>
      )}

      {isDenaliTable && totalItems > MINIMUM_ROW_COUNT && (
        <Pagination
          totalItems={totalItems}
          MINIMUM_ROW_COUNT={MINIMUM_ROW_COUNT}
          tableControls={tableControls}
          paginationOptions={paginationOptions}
          ClickPaginationCount={ClickPaginationCount}
          loadTime={loadTime}
          pageOptions={pageOptions}
          pages={pages}
          setTableControls={setTableControls}
        />
      )}
    </Block>
  )
}

export default Table

Table.propTypes = {
  /** Internal property to allow accordion to be open or closed */
  action: func
}
