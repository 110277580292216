import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import validationSchema from './formValidator'
import SelectBuildings from "../../organizationManagement/createOrganization/select-buildings"
import { useQuery } from 'src/hooks/APIHooks'
import { ADD_SALES_OFFICE, ADD_CONTRACTOR_COMPANY, GET_BUILDINGS, UPDATE_SALES_OFFICE, UPDATE_CONTRACTOR_COMPANY, UPDATE_BUILDING, GET_SALES_OFFICE_BY_ID, GET_CONTRACTOR_COMPANY_BY_ID, DELETE_CONTRACTOR_COMPANY, DELETE_SALES_OFFICE, SEARCH_SALESOFFICES, SEARCH_CONTRACTOR_COMPANIES } from "./queries/queries"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Table from "src/components/Table/clientSideTable"
import styled from 'styled-components'
import { pages } from 'src/pages/pages.js'
import { useMutation } from '../../../hooks/APIHooks'
import { ACTIONS } from 'src/constants'
import IconSvg from "src/components/Icon"
import Select from 'src/denali-ui/components/Select'
import Option from 'src/denali-ui/components/Option'
import { NewStyles } from 'src/NewStyles'
import Modal from 'src/components/legacy/components/modal/modal'
import { AccessControl } from 'src/components/accessControl'



const ButtonWrapper = styled.span`
  padding-top: 24px;
`

const HeaderTypeSpan = styled.span`
  margin-left: 12px;
  font-size: 14px;
`

export const ServiceCompanyDetails = () => {
  const {id, type} = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [mode, setMode] = useState(location?.state?.mode ? location?.state?.mode : id ? ACTIONS.VIEW : ACTIONS.ADD)
  const [createdId, setCreatedId] = useState(null)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [navBack, setNavBack] = useState(false)
  const initialInput = {
    name: "",
    selectedBuildings: [],
    // Sales Office
    district: "",
    code: "",
    //Contractor Company
    type: "Light Commercial Controls Contractor (LC3)",
    entityType: type
  }

  useEffect(() => {
    if (createdId) {
      navigate(pages["Service Provider Management Details"].href.replace(":id", createdId).replace(":type", type))
    }
  }, [createdId])

  const [buildings, setBuildings] = useState([])

  const { data: buildingData = [], refetch } = useQuery({
    query: GET_BUILDINGS,
    variables: { filter: {
      salesOfficeId: {eq: id},
      // salesOfficeId: type === "SalesOffice" ? {eq: id} : undefined,
      // contractorCompanyId: type === "ContractorCompany" ? {eq: id} : undefined,
  }, limit: 1000 },
    errorPolicy: 'global',
    dataPath: 'data.searchBuildings.items'
  })

  const { refetch: refetchSalesOffices } = useQuery({
    query: SEARCH_SALESOFFICES,
    variables: { limit: 1000 },
    errorPolicy: 'global',
    dataPath: 'data.searchSalesOffices.items'
  })

  // const { refetch: refetchContractorCompanies } = useQuery({
  //   query: SEARCH_CONTRACTOR_COMPANIES,
  //   variables: { limit: 1000 },
  //   errorPolicy: 'global',
  //   dataPath: 'data.searchContractorCompanies.items'
  // })

  const { data: salesOfficeData, refetch: refetchSalesOffice } = useQuery({
    query: GET_SALES_OFFICE_BY_ID,
    disableInitialLoad: true,
    variables: { id: id},
    errorPolicy: 'global',
    dataPath: 'data.getSalesOffice'
  })

  // const { data: contractorCompanyData, refetch: refetchContractorCompany } = useQuery({
  //   query: GET_CONTRACTOR_COMPANY_BY_ID,
  //   disableInitialLoad: true,
  //   variables: { id: id},
  //   errorPolicy: 'global',
  //   dataPath: 'data.getContractorCompany'
  // })

  const { onSubmit: createSalesOffice } = useMutation({
      query: ADD_SALES_OFFICE
  })

  // const { onSubmit: createContractorCompany } = useMutation({
  //   query: ADD_CONTRACTOR_COMPANY
  // })

  const { onSubmit: updateSalesOffice } = useMutation({
    query: UPDATE_SALES_OFFICE
  })

  // const { onSubmit: updateContractorCompany } = useMutation({
  //   query: UPDATE_CONTRACTOR_COMPANY
  // })

  const { onSubmit: updateBuilding } = useMutation({
    query: UPDATE_BUILDING
  })

  // const { onSubmit: deleteContractorCompany } = useMutation({
  //   query: DELETE_CONTRACTOR_COMPANY,
  // })

  const { onSubmit: deleteSalesOffice } = useMutation({
    query: DELETE_SALES_OFFICE,
  })

  useEffect(() => {
    if (navBack) {
      navigate(pages["Service Provider Management"].href) 
    }
  }, [navBack])

  const getModalConfigDelete = () => ({
		gray: true,
		heading: "Delete Service Provider",
		buttons: [
			{
				text: "Confirm",
				handleClick: async () => {
					return await Promise.all(values.selectedBuildings.map(e => updateBuilding({input: {
            id: e.id, 
            // contractorCompanyId: type === "ContractorCompany" ? null : undefined, 
            // contractorCompanyName: type === "ContractorCompany" ? null : undefined, 
            // salesOfficeName: type === "SalesOffice" ? null : undefined, 
            // salesOfficeId: type === "SalesOffice" ? null : undefined
            salesOfficeName: null, 
            salesOfficeId: null
          }})))
            .then(async () => {
						// if (type === "ContractorCompany") {
            //   return await deleteContractorCompany({input: {
            //     id: id
            //   }}).then(e => {
            //     setTimeout(() => { 
            //       setNavBack(true)
            //      }, 1000);  
            //   })
            // } else {
              return await deleteSalesOffice({input: {
                id: id
              }}).then(e => {
                setTimeout(() => { 
                  setNavBack(true)
                 }, 1000);  
              })
            // }
					})
				},
				type: 'save',
			},
			{
				text: "Cancel",
				handleClick: () => setDeleteDialog(false),
				type: "cancel",
			},
		],
		handleClose: () => setDeleteDialog(false),
	})

  useEffect(() => {
    navigate(location.pathname, { replace: true })
  }, [])
  
  useEffect(() => {
    if (buildingData && buildingData.length) {
      const buildingsForSetting = buildingData.filter(b => Boolean(b)).map(b => ({...b, key: b.id, label: b.name}))
      setBuildings(buildingsForSetting)
      if (mode !== ACTIONS.ADD) {
        formik.setFieldValue("selectedBuildings", buildingsForSetting )
      }
    }
  }, [buildingData])

  useEffect(() => {
    if (type && id) {
      // if (type === "SalesOffice"){
        refetchSalesOffice({id: id})
      // } else if (type === "ContractorCompany") {
      //   refetchContractorCompany({id: id})
      // }
    }
  }, [type, id])

  useEffect(() => {
    if (/*contractorCompanyData ||*/ salesOfficeData) {
      // if (contractorCompanyData) {
      //   formik.setFieldValue("name", contractorCompanyData.name)
      //   formik.setFieldValue("type", contractorCompanyData.type)
      // } else {
        formik.setFieldValue("name", salesOfficeData.name)
        formik.setFieldValue("district", salesOfficeData.district)
        formik.setFieldValue("code", salesOfficeData.id)
        formik.setFieldValue("type", type === "ContractorCompany" ?  salesOfficeData.type === "ASC-Contractor" ? "ASC" : "LC3 ": undefined)

      // }
    }
  }, [/*contractorCompanyData,*/ salesOfficeData])

  const formik = useFormik({
    initialValues: initialInput,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values: any) => {
      if (id) {
        // if (type === "ContractorCompany") {
        //     await refetchContractorCompanies({filter: {and: [{name: {eq: values.name}}, {id: {ne: id}}]}}).then(async coRes => {
        //       if (coRes.length) {
        //         if (coRes[0].name === values.name) {
        //           formik.setErrors({...errors, name: "Name already exists"})
        //         }
        //       } else {
        //         if (values?.name !== contractorCompanyData.name || values?.type !== contractorCompanyData.type) {
        //           updateContractorCompany({
        //             input: {
        //               name: values.name,
        //               type: values.type,
        //               id: id
        //             }
        //           })
        //         }
        //         const existingBuildings = buildingData?.map(i => i.id) || []
        //         const newBuildings = values?.selectedBuildings?.map(b => b.id) || []
        //         const newBuildingsToAdd = buildingData?.[0]?.contractorCompanyName === values.name ? newBuildings.filter(nb => !existingBuildings.includes(nb)) : newBuildings
        //         await Promise.all(await newBuildingsToAdd.map(async b => await updateBuilding({input: {id: b, contractorCompanyId: id, contractorCompanyName: values.name}}))).then(() => {
        //           setTimeout(() => { 
        //             refetch({ filter: {
        //               contractorCompanyId: {eq: id},
        //           }, limit: 1000 })
        //           refetchContractorCompany({id: id})
        //             setMode(ACTIONS.VIEW)
        //           }, 2000);
        //         })
        //       }
        //     })
        // } else if (type === "SalesOffice") {
            await refetchSalesOffices({filter: {and: [{
              or: type === "ContractorCompany" ? [
                {name: {eq: values.name}}
              ]
              : [
              {name: {eq: values.name}}, {code: {eq: values.code}}
            ]
          }, {id: {ne: id}}]}}).then(async soRes => {
              if (soRes.length) {
                if (soRes[0].name === values.name) {
                  formik.setErrors({...errors, name: "Name already exists"})
                }
                if (soRes[0].code === values.code) {
                  formik.setErrors({...errors, code: "Code already exists"})
                }
              } else {
                if (values?.name !== salesOfficeData.name || values?.district !== salesOfficeData.district || (type === "ContractorCompany" && values?.type === "ASC" ? "ASC-Contractor" !== salesOfficeData.type : "LC3-Contractor" !== salesOfficeData.type)) {
                  updateSalesOffice({
                    input: {
                      name: values.name,
                      district: values.district,
                      code: values?.code ,
                      type: type === "ContractorCompany" ? values?.type === "ASC" ? "ASC-Contractor" : "LC3-Contractor": undefined,
                      id: id
                    }
                  })
                }

                const existingBuildings = buildingData?.map(i => i.id) || []
                const newBuildings = values?.selectedBuildings?.map(b => b.id) || []
                const newBuildingsToAdd = buildingData?.[0]?.salesOfficeName === values.name ? newBuildings.filter(nb => !existingBuildings.includes(nb)) : newBuildings
                await Promise.all(await newBuildingsToAdd.map(async b => await updateBuilding({input: {id: b, salesOfficeId: id, salesOfficeCode: id, salesOfficeDistrict: values.district, salesOfficeName: values.name}}))).then(() => {
                  setTimeout(() => { 
                    refetch({ filter: {
                      salesOfficeId: {eq: id}
                  }, limit: 1000 })
                  refetchSalesOffice({id: id})
                    setMode(ACTIONS.VIEW)
                  }, 2000);
                  })
             }
          })
          
        // }
      } 
      else {
        // if (type === "ContractorCompany") {
        //   await refetchContractorCompanies({filter: {or: [{name: {eq: values.name}}]}}).then(async coRes => {
        //     if (coRes.length) {
        //       if (coRes[0].name === values.name) {
        //         formik.setErrors({...errors, name: "Name already exists"})
        //       }
        //     } else {
        //       return await createContractorCompany({input: {name: values.name, type: values.type}}).then(async response => {
        //         return await Promise.all(await values.selectedBuildings.map(async b => await updateBuilding({input: {id: b.id, contractorCompanyId: response?.data?.createContractorCompany?.id, contractorCompanyName: values.name}}))).then(() => {
        //           setTimeout(() => {
        //             setMode(ACTIONS.VIEW)
        //             setCreatedId(response?.data?.createContractorCompany?.id)
        //            }, 1000);
        //         })
        //       })
        //     }
        //   })
          
        // } else if (type === "SalesOffice") {
          await refetchSalesOffices({filter: {or: [{name: {eq: values.name}}, {code: {eq: values.code}}]}}).then(async soRes => {
            if (soRes.length) {
              if (soRes[0].name === values.name) {
                formik.setErrors({...errors, name: "Name already exists"})
              }
              if (soRes[0].code === values.code) {
                formik.setErrors({...errors, code: "Code already exists"})
              }
            } else {
              if (type === "ContractorCompany") {
                return await createSalesOffice({input: {name: values.name, type: values?.type === "ASC" ? "ASC-Contractor" : "LC3-Contractor", district: "Contractor", code: values.type}}).then(async response => {
                  if (!response.error) {
                    return await Promise.all(await values.selectedBuildings.map(async b => await updateBuilding({input: {id: b.id, salesOfficeCode: values.code, salesOfficeDistrict: values.district, salesOfficeId: response?.data?.createSalesOffice?.id, salesOfficeName: values.name}}))).then(async (res) => {
                      await updateSalesOffice({
                        input: {
                          id: response?.data?.createSalesOffice?.id,
                          salesOfficeId: response?.data?.createSalesOffice?.id
                        }
                      }) 
                      setTimeout(() => {
                        setMode(ACTIONS.VIEW)
                        setCreatedId(response?.data?.createSalesOffice?.id)
                      }, 1000);
                    })
                  }
              })
              } else {
                return await createSalesOffice({input: {name: values.name, district: values.district, code: values.code, id: values.code, salesOfficeId: values.code}}).then(async response => {
                  if (!response.error) {
                    return await Promise.all(await values.selectedBuildings.map(async b => await updateBuilding({input: {id: b.id, salesOfficeCode: values.code, salesOfficeDistrict: values.district, salesOfficeId: response?.data?.createSalesOffice?.id, salesOfficeName: values.name}}))).then((res) => {
                      setTimeout(() => {
                        setMode(ACTIONS.VIEW)
                        setCreatedId(response?.data?.createSalesOffice?.id)
                      }, 1000);
                    })
                  }
              })
              }
          }
        })
        // }
      }
    }
  })

  const nameHeadings = [
    {
      name: 'name',
      title: 'Building Name',
      key: 'name',
      maxWidth: '120px',
      onDataClick: (rowData) => {
        navigate(
          pages['Building Setup/:id'].href.replace(':id', rowData.id)
        )
      },
    },
    {
      name: 'address',
      title: 'Address',
      key: 'address',
      maxWidth: '120px'
    },
    {
      name: 'city',
      title: 'City',
      key: 'city',
      maxWidth: '120px'
    },
    {
      name: 'state',
      title: 'State/Region',
      key: 'state',
      maxWidth: '120px'
    },
    {
      name: 'country',
      title: 'Country',
      key: 'country',
      maxWidth: '120px'
    },
    {
      name: "actions",
      title: "",
      key: "actions",
      sortField: false,
      disabled: true
    },
    {
      name: 'accountName',
      title: "Organization",
      key: 'accountName',
      maxWidth: '120px'
    },
  ]

  const { values, errors } = formik

  return (
    <NewStyles>
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
    {deleteDialog ? <Modal {...getModalConfigDelete()}>
				Are you sure you'd like to remove {values?.name || "the selected service provider"}? All associated buildings will be unassociated.
		</Modal> : ""}
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageHeader
          pageTitle={[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <>
              <TextInput
                style={{width: "260px"}}
                labelText={`${type === "ContractorCompany" ? "Contractor Company Name" : "Sales Office Name"}`}
                defaultValue={values?.name}
                name="name"
                onChange={(e => formik.setFieldValue("name", e.target.value))}
                hasError={errors?.['name']}
                errorMessage={errors?.['name']}
            />
            <HeaderTypeSpan>{`Service Provider Type: ${type === "ContractorCompany" ? "Contractor Company" : "Sales Office"}`}</HeaderTypeSpan>
            </> : <>{values?.name}<HeaderTypeSpan>{`${type === "ContractorCompany" ? "(Contractor Company)" : "(Sales Office)"}`}</HeaderTypeSpan></>
          }
          titleTools={[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ?
            <ButtonWrapper>
              <Button type="primary" onClick={() => {
                formik.handleSubmit()
              }
                }>
                Save
              </Button>
              <Button type="secondary" onClick={() => mode === ACTIONS.EDIT ? setMode(ACTIONS.VIEW) : navigate(pages["Service Provider Management"].href)}>
                Cancel
              </Button>
          </ButtonWrapper>
           : <span>
            <AccessControl id={type === "ContractorCompany" ? "tc.pages.service-provider-management.contractor-company-create" : "tc.pages.service-provider-management.sales-office-create"}>
            <IconSvg
              name={"edit"}
              margin="0px 0px 0px 10px"
              color="#666"
              hover="#666"
              width="15px"
              onClick={() => {
                setMode(ACTIONS.EDIT)
              }}
            />
            <IconSvg
              name={"trash2"}
              margin="0px 0px 0px 10px"
              color="#666"
              hover="#666"
              width="15px"
              onClick={() => {
                setDeleteDialog(true)
              }}
            />
            </AccessControl>
            </span>
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        {
        type === "ContractorCompany" ? [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <Select
            onChange={(e) => {
              formik.setFieldValue("type", e.target.value )
            }}
            placeholder="Select Provider Type"
            popoverConfig={{
              strategy: 'fixed'
            }}
            value={values?.type}
          >
            <Option value="Applied Systems Contractor (ASC)">ASC</Option>
            <Option value="Light Commercial Controls Contractor (LC3)">LC3</Option>
        </Select>
        : <div>Type: &nbsp;{values.type}</div>
        : ""
        }
        {
        type === "SalesOffice" ? [ACTIONS.ADD].includes(mode) ? <TextInput
        style={{width: "120px"}}
        labelText={"Sales Office Code"}
        defaultValue={values?.code}
        name="code"
        onChange={(e => formik.setFieldValue("code", e.target.value))}
        hasError={errors?.['code']}
        errorMessage={"The code is required and must be unique."}
    /> : <div>Code: &nbsp;{values.code}</div>
    : ""
        }
        {
        type === "SalesOffice" ? [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <TextInput
        style={{width: "160px"}}
        labelText={"District"}
        defaultValue={values?.district}
        name="district"
        onChange={(e => formik.setFieldValue("district", e.target.value))}
        hasError={errors?.['district']}
        errorMessage={errors?.['district']}
    /> : <div>District: &nbsp;{values.district}</div>
    : ""
        }
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {
        [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <div>
        <SelectBuildings
          initialValues={values?.selectedBuildings}
          onButtonClick={(e) => {
            const newSelectedBuildings = [...e]
            formik.setFieldValue("selectedBuildings", newSelectedBuildings )
            setBuildings(buildings.filter(b => !newSelectedBuildings.map(e => e.id).includes(b.id)))
          }}
          title={`Add Building to ${type === "ContractorCompany" ? "Contractor Company" : "Sales Office"}`}
          description={`You must have permissions for both ${type === "ContractorCompany" ? "contractor comapanies" : "sales offices"} to move buildings between ${type === "ContractorCompany" ? "contractor companies" : "sales offices"}, adding a building to this ${type === "ContractorCompany" ? "contractor company" : "sales office"} will remove it from other ${type === "ContractorCompany" ? "contractor companies" : "sales offices"}.`}
          sideText={<>Create new buildings in <Link to="/building-setup/add">Building Setup</Link></>}
          buttonTitle={"Add"}
        />
        </div>
        : ""
      }

      <Table
            key="locationDevicesTable"
            rows={values.selectedBuildings}
            header={nameHeadings}
            search={false}
          />
      </Content>
    </Container>
    </NewStyles>
  )
}
