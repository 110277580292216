import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './filterable-select.scss'
import Select from 'src/components/legacy/components/select/select'
import Label from 'src/components/legacy/components/label/label'
import Search from 'src/components/legacy/components/search/search'
import { search, sort as doSort } from 'src/components/legacy/common/helpers.js'
import PaginationPage from './pagination-page'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import i18next from 'i18next'
import { TranslateComponent } from 'src/common/translations'

const PANEL_THRESHOLD = 13
const RECORDS_PER_PAGE = 10

export default class FilterableSelect extends Component {
  static propTypes = {
    itemLabel: PropTypes.string,
    defaultItemText: PropTypes.string,
    noItemsText: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    selectedItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    disabledItems: PropTypes.array,
    showError: PropTypes.bool,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isLoading: PropTypes.bool,
    showButtons: PropTypes.bool,
    PANEL_THRESHOLD: PropTypes.number,
    disableDefaultSorting: PropTypes.bool,
    isItemLabelTranslated: PropTypes.bool
  }

  static defaultProps = {
    itemLabel: '',
    defaultItemText: '',
    noItemsText: '',
    searchPlaceholder: i18next.t('components:filterableSelect>FindItem'),
    selectedItem: null,
    options: {},
    className: '',
    disabled: false,
    disabledItems: [],
    showError: false,
    errorMessage: '',
    isLoading: false,
    disableDefaultSorting: false,
    PANEL_THRESHOLD,
    isItemLabelTranslated: false
  }

  static generateOffsetLabelObjects(offsetList) {
    return offsetList.map((offset) => ({
      offsetValue: offset,
      label: String(offset + 1)
    }))
  }

  static generateDisplayOffsets(
    currentOffset,
    totalPages,
    OFFSET_PLACEHOLDERS = 9,
    EXTREMA_ELEMENTS = 1
  ) {
    const RELATIVE_THRESHOLD = OFFSET_PLACEHOLDERS - EXTREMA_ELEMENTS
    const RELATIVE_CONTEXT_ELEMENTS = OFFSET_PLACEHOLDERS - EXTREMA_ELEMENTS * 2

    const totalPagesList = [...Array.from(Array(totalPages).keys())]
    let returnList = []

    if (OFFSET_PLACEHOLDERS >= totalPagesList.length) {
      returnList = returnList.concat(totalPagesList)
    } else {
      returnList = returnList.concat(totalPagesList.slice(0, EXTREMA_ELEMENTS))
      if (currentOffset >= totalPagesList.length - RELATIVE_THRESHOLD) {
        const END_CONTEXT_START =
          totalPagesList.length - EXTREMA_ELEMENTS - RELATIVE_CONTEXT_ELEMENTS
        returnList = returnList.concat(
          totalPagesList.slice(
            END_CONTEXT_START,
            END_CONTEXT_START + RELATIVE_CONTEXT_ELEMENTS
          )
        )
      } else if (currentOffset >= RELATIVE_THRESHOLD) {
        const CONTEXT_START_OFFSET =
          currentOffset - Math.floor(RELATIVE_CONTEXT_ELEMENTS / 2)
        returnList = returnList.concat(
          totalPagesList.slice(
            CONTEXT_START_OFFSET,
            CONTEXT_START_OFFSET + RELATIVE_CONTEXT_ELEMENTS
          )
        )
      } else {
        returnList = returnList.concat(
          totalPagesList.slice(EXTREMA_ELEMENTS, RELATIVE_THRESHOLD)
        )
      }
      returnList = returnList.concat(
        ...totalPagesList.slice(EXTREMA_ELEMENTS * -1)
      )
    }
    return returnList
  }

  constructor(props) {
    super(props)
    this.state = {
      showItemPanel: false,
      filterText: '',
      offset: 0
    }
  }

  /**
   * handleOutsideClick - Close the panel when user clicks outside
   */
  handleOutsideClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return
    }
    if (document.contains(e.target)) {
      this.togglePanel()
    }
  }

  togglePanel = () => {
    const { showItemPanel } = this.state
    const { disabled } = this.props

    if (disabled) {
      this.setState({
        showItemPanel: false,
        offset: 0,
        filterText: ''
      })
      document.removeEventListener('click', this.handleOutsideClick, false)
      return
    }
    if (!showItemPanel) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
    this.setState({
      showItemPanel: !showItemPanel,
      offset: 0,
      filterText: ''
    })
  }

  setFilter = (value) => {
    this.setState({
      filterText: value
    })
  }

  setOffset = (offset) => {
    this.setState({
      offset
    })
  }

  subtractOffset = (currentOfset) => {
    if (currentOfset > 0) {
      this.setState({
        offset: currentOfset - 1
      })
    }
  }

  addOffset = (currentOfset) => {
    this.setState({
      offset: currentOfset + 1
    })
  }

  setActiveItem = (id) => () => {
    const { onChange } = this.props

    onChange(id)
    this.togglePanel()
  }

  isActiveItem = (id) => {
    const { selectedItem } = this.props
    return (
      (typeof selectedItem === 'number' || typeof selectedItem === 'string') &&
      id == selectedItem
    )
  }

  isDisabledItem = (id) => {
    const { disabledItems } = this.props
    return disabledItems.includes(id)
  }

  renderSpinnerBlock = () => {
    const { itemLabel } = this.props

    return (
      <div>
        <Label key={1} text={itemLabel && `${itemLabel}:`} />
        <Spinner className="filterable-select-spinner" />
      </div>
    )
  }

  render() {
    const { showItemPanel, filterText, offset } = this.state
    const {
      disabled,
      itemLabel,
      defaultItemText,
      noItemsText,
      searchPlaceholder,
      selectedItem,
      onChange,
      options,
      className,
      errorMessage,
      showError,
      isLoading,
      showButtons,
      disabledItems,
      disableDefaultSorting,
      isItemLabelTranslated
    } = this.props

    const isOptionsAsArray = Array.isArray(options)

    const selectedItemText =
      typeof selectedItem === 'number' || typeof selectedItem === 'string'
        ? isOptionsAsArray
          ? (options.find((item) => item.key === selectedItem) || {}).value
          : options[selectedItem]
        : defaultItemText

    const availableItems = !isOptionsAsArray
      ? doSort(
          Object.entries(options).map(([key, value]) => ({ key, value })),
          'value'
        )
      : disableDefaultSorting
      ? options
      : doSort(options, 'value')

    const filteredItems = search(availableItems, ['value'], filterText)
    const totalPages = Math.ceil(filteredItems.length / RECORDS_PER_PAGE)
    const currentOffset = Math.min(offset, totalPages - 1)
    const visibleItems = filteredItems.slice(
      RECORDS_PER_PAGE * currentOffset,
      RECORDS_PER_PAGE * currentOffset + RECORDS_PER_PAGE
    )

    const displayOffsets = FilterableSelect.generateOffsetLabelObjects(
      FilterableSelect.generateDisplayOffsets(currentOffset, totalPages)
    )
    const displayOffsetsSmall = FilterableSelect.generateOffsetLabelObjects(
      FilterableSelect.generateDisplayOffsets(currentOffset, totalPages, 5, 1)
    )

    const optionsLength = isOptionsAsArray
      ? options.length
      : Object.keys(options).length

    return (
      <div
        className={classNames(
          'select-block',
          { picker: optionsLength >= this.props.PANEL_THRESHOLD },
          { disabled },
          { 'filterable-select-error': showError }
        )}
        ref={(node) => {
          this.node = node
        }}
      >
        {/* {isLoading ? this.renderSpinnerBlock() : Below code should be here once loader will be enabled  */}
        {optionsLength === 0 ? (
          <Label key={1} text={noItemsText} />
        ) : optionsLength === 1 && selectedItemText !== defaultItemText ? ( // this is because we think this functionality will exist in the future
          <>
            <Label
              key={1}
              text={<span>{itemLabel ? `${itemLabel}: ` : ''}</span>}
            />
            <span className="single-text-name">
              <ConditionalTooltip
                conditional={true}
                content={selectedItemText}
                children={selectedItemText}
              >
                {selectedItemText}
              </ConditionalTooltip>
            </span>
          </>
        ) : optionsLength < this.props.PANEL_THRESHOLD &&
          optionsLength > 0 &&
          !showButtons ? (
          [
            <Label key={1} text={itemLabel && `${itemLabel}:`} />,
            <Select
              key={2}
              options={options}
              selectedItem={selectedItemText}
              className={className}
              onChange={onChange}
              disabledItems={disabledItems}
            />
          ]
        ) : (
          <div className="active-item-container">
            <label>
              <span className="item-label">
                {isItemLabelTranslated ? <TranslateComponent>{itemLabel && `${itemLabel}: `}</TranslateComponent> : (itemLabel && `${itemLabel}: `)}
              </span>
              <span className={classNames('active-item-name')}>
                <ConditionalTooltip
                  conditional={true}
                  content={selectedItemText}
                  children={selectedItemText}
                >
                  {selectedItemText}
                </ConditionalTooltip>
              </span>
            </label>
            <div className="panel-container">
              <button
                type="button"
                className={classNames(
                  'panel-container__button',
                  'secondary',
                  'small',
                  'change',
                  { opened: showItemPanel },
                  { disabled },
                  { 'button-error': showError }
                )}
                onClick={this.togglePanel}
                style={{width: '100%'}}
              >
                <span><TranslateComponent>Change</TranslateComponent></span>
                <span className="icon icon-expand caret" />
              </button>
              <CSSTransition
                component="div"
                className="select-item-panel-parent"
                transitionName="slide-in"
                transitionEnter={true}
                transitionLeave={true}
                transitionEnterTimeout={200}
                transitionLeaveTimeout={200}
              >
                <>
                  {showItemPanel && (
                    <div className="select-item-panel">
                      <span className="heading">
                        <TranslateComponent>{`Select ${itemLabel}`}</TranslateComponent>
                      </span>
                      <hr />
                      <div className="item-filter">
                        <label><TranslateComponent>Filter:</TranslateComponent></label>
                        <div className="filter-container">
                          <Search
                            value={filterText}
                            placeholder={searchPlaceholder}
                            onChange={this.setFilter}
                          />
                          <div className="pagination">
                            {totalPages > 1 && (
                              <span>
                                {currentOffset > 0 && (
                                  <span
                                    className="pagination-control"
                                    onClick={() =>
                                      this.subtractOffset(currentOffset)
                                    }
                                  >
                                    &lt;
                                  </span>
                                )}
                                {/* <Trans
                                  i18nKey="components:filterableSelect>Pagination"
                                  values={{
                                    currentPage: (offset + 1).toString(),
                                    totalPages: totalPages.toString()
                                  }}
                                >
                                  Page <span className="current-page" /> of{' '}
                                  <span className="total-pages" />
                                </Trans> */}
                                <span>
                                 <TranslateComponent>{`Page ${(offset + 1).toString()} of ${totalPages.toString()}`}</TranslateComponent>
                                </span>
                                {currentOffset + 1 < totalPages && (
                                  <span
                                    className="pagination-control"
                                    onClick={() =>
                                      this.addOffset(currentOffset)
                                    }
                                  >
                                    &gt;
                                  </span>
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="table-container">
                        {visibleItems && visibleItems.length > 0 ? (
                          <table className="table-list">
                            <tbody className="table-list">
                              <tr>
                                <th colSpan="2">
                                  <div>
                                    <TranslateComponent>{`${itemLabel} Name`}</TranslateComponent>  
                                  </div>
                                </th>
                              </tr>
                              {visibleItems.map((item) => (
                                <tr
                                  key={item.key}
                                  className={classNames({
                                    active: this.isActiveItem(item.key)
                                  })}
                                >
                                  <td className="tooltip-container">
                                    <span
                                      className={classNames('item-name', {
                                        disabled: this.isDisabledItem(item.key)
                                      })}
                                    >
                                      <ConditionalTooltip
                                        conditional={false}
                                        content={item.value}
                                        children={item.value}
                                      >
                                        {item.value}
                                      </ConditionalTooltip>
                                    </span>
                                    <div>
                                      <div className="tooltip center-bottom small-offsets">
                                        <div className="message">
                                          {item.value}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="primary"
                                      disabled={
                                        this.isActiveItem(item.key) ||
                                        this.isDisabledItem(item.key)
                                      }
                                      onClick={this.setActiveItem(item.key)}
                                    >
                                      {/* <Trans
                                        i18nKey="components:filterableSelect>SelectLabel"
                                        values={{ label: itemLabel }}
                                      >
                                        <TranslateComponent>select</TranslateComponent>{' '}
                                        <span className="full-select-label">
                                          &nbsp;{itemLabel}
                                        </span>
                                      </Trans> */}
                                      <span>
                                        <TranslateComponent> SELECT </TranslateComponent>
                                      </span>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <table className="table-list">
                            <tbody className="table-list">
                              <tr>
                                <th>
                                  <div />
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <span className="no-results-text">
                                    {i18next.t(
                                      'components:filterableSelect>NoResults'
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </div>
                      {totalPages > 1 &&
                        visibleItems &&
                        visibleItems.length > 0 && (
                          <div className="page-label bottom-pagination-box">
                            {currentOffset > 0 && (
                              <span
                                className="pagination-control"
                                onClick={() =>
                                  this.subtractOffset(currentOffset)
                                }
                              >
                                {i18next.t('Common:Prev')}
                              </span>
                            )}
                            <PaginationPage
                              className="full-offsets"
                              currentOffset={currentOffset}
                              displayOffsets={displayOffsets}
                              onChange={this.setOffset}
                            />
                            <PaginationPage
                              className="small-offsets"
                              currentOffset={currentOffset}
                              displayOffsets={displayOffsetsSmall}
                              onChange={this.setOffset}
                            />
                            {currentOffset + 1 < totalPages && (
                              <span
                                className="pagination-control"
                                onClick={() => this.addOffset(currentOffset)}
                              >
                                {i18next.t('Common:Next')}
                              </span>
                            )}
                          </div>
                        )}
                    </div>
                  )}
                </>
              </CSSTransition>
            </div>
          </div>
        )}

        {showError && (
          <div className="select-error-message">{errorMessage}</div>
        )}
      </div>
    )
  }
}
