import { useState } from 'react'

import { useBuildingDetails } from './useBuildingDetails'
import { useIssuesFindinsgDetails } from './useIssuesFindinsgDetails'
import { useOpportunityDetails } from './useOpportunityDetails'
import { useUserDetails } from './useUserDetails'

export function useNotifications() {
  const [notificationList, setNotificationsList] = useState([])

  let _notifications = []

  const buildingDetail = useBuildingDetails()
  const userDetail = useUserDetails()
  const issueFindingsDetail = useIssuesFindinsgDetails()
  const opportunityDetails = useOpportunityDetails()

  /**
   * function to fulfill each notifcation details required for template
   *
   * @param notifications
   * @param reset
   */
  async function handleNotifications(notifications = [], reset: boolean) {
    _notifications = notifications

    notifications.forEach((notification) => {
      buildingDetail.findNewBuildingIdsForDataLoss(notification)
      userDetail.findNewAssigneeAndAssignorIdsForIssuesAndFindings(notification)
      issueFindingsDetail.findNewIssueIds(notification)
      opportunityDetails.findNewOpportunityIds(notification)
    })

    // Api for each sub types
    const buildingPromise = buildingDetail.apiGetBuildingByIds()
    const userPromise = userDetail.apiGetUserByIds()
    const issuePromise = issueFindingsDetail.apiGetIssueByIds()
    const opportunityPromise = opportunityDetails.apiGetOpportunityByIds()

    const responses = await Promise.all([
      buildingPromise,
      userPromise,
      issuePromise,
      opportunityPromise
    ])

    // [CodeGuru suggestion on security check] Additional check to ensure response length is <= input promises above
    if (responses.length <= 10) {
      // Update the response to notifications for each sub types
      for (const response of responses) {
        const { requestFrom, data } = response || {}
        if (requestFrom === 'BUILDING_DATA') {
          _notifications =
            buildingDetail.addBuildingNamePropertyToNotificationsForDataLoss(
              _notifications,
              data
            )
        } else if (requestFrom === 'USER_DATA') {
          _notifications =
            userDetail.addAssigneeAndAssignorNamePropertyToNotificationsForIssuesAndFindings(
              _notifications,
              data
            )
        } else if (requestFrom === 'ISSUE_DATA') {
          _notifications =
            issueFindingsDetail.addIssueNamePropertyToNotificationsForIssuesAndFindings(
              _notifications,
              data
            )
        } else if (requestFrom === 'OPPORTUNITY_DATA') {
          _notifications =
            opportunityDetails.addOpportunityNamePropertyToNotificationsForOpportunity(
              _notifications,
              data
            )
        }
      }
    }

    // Update state once notification details are updated
    buildingDetail.updateState(reset)
    userDetail.updateState(reset)
    issueFindingsDetail.updateState(reset)
    opportunityDetails.updateState(reset)

    updateState(reset)
  }

  function updateState(reset: boolean) {
    const data = _notifications.slice()

    setNotificationsList((notificationList) => [
      ...(!reset ? notificationList : []),
      ...data
    ])

    _notifications = []
  }

  return {
    notificationList,
    handleNotifications
  }
}
