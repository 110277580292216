import React from 'react'
import PropTypes from 'prop-types'
import './header.scss'

const Header = ({
  children,
  pageTitle,
  titleTools,
  hideToolBar,
  hideTitleBar
}) => (
  <div className="header-wrapper">
    <div className="title-wrapper">
      <div className="title">{pageTitle}</div>
      {titleTools}
    </div>
    {!hideTitleBar && <hr />}
    <div className={hideToolBar ? 'hidden toolbar' : 'toolbar'}>
      <div className="filter-div">{children}</div>
    </div>
  </div>
)

Header.propTypes = {
  children: PropTypes.node,
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleTools: PropTypes.node,
  hideToolBar: PropTypes.bool,
  hideTitleBar: PropTypes.bool
}

export default Header
