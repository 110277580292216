import { useEffect, useState } from 'react'
import { useAttachment } from 'src/hooks/attachment'
import { PanelLabel, BuildingContainer, ParaName,StyledImg } from './style'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import Icon from 'src/denali-ui/components/Icon'
import moment from 'moment'
import { TEXT_DATE_TIME_FORMAT } from 'src/components/legacy/common/time-helpers'
import translate, { TranslateComponent } from 'src/common/translations'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'

const ImageTextWidgetPreview = (widget) => {
  const { downloadFileURL } = useAttachment()
  const [loading, setLoading] = useState(true)
  const [imageInfoData, setImageInfoData] = useState(null)
  const [buildingName, setBuildingName] = useState('')
  const fetchImage = async (item, imageText, imageAdditionalText) => {
    if (item && item?.name && item?.buildingId) {
      let imageInfoo = {
        imageTitle: '',
        imageDescription: '',
        imageUrl: null
      }
      await downloadFileURL(item?.name, item?.buildingId)
        .then((res) => {
          imageInfoo = {
            imageTitle: imageText || '',
            imageDescription: imageAdditionalText || '',
            imageUrl: res
          }
          setImageInfoData(imageInfoo)
          setLoading(false)
          item.original = res
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }
  const getImageDetails = async (item, imageText, imageAdditionalText) => {
    await fetchImage(item, imageText, imageAdditionalText)
  }

  useEffect(() => {
    const buildingDetails = widget?.widgetDetails?.building
    if (buildingDetails) {
      setBuildingName(buildingDetails?.name)
    }
    if (widget?.widgetDetails?.widgetImage?.items?.length) {
      const item = widget?.widgetDetails
      setLoading(true)
      getImageDetails(
        item?.widgetImage?.items[0],
        item?.imageText,
        item?.imageAdditionalText
      )
    } else {
      setLoading(true)
      setImageInfoData({
        imageTitle: widget?.widgetDetails?.imageText,
        imageDescription: widget?.widgetDetails?.imageAdditionalText,
        imageUrl: null
      })
      setLoading(false)
    }
  }, [widget?.widgetDetails])

  const preview = translate("Preview")
  const dataUpdated = translate('Data Updated')
  const lDateFormatbyLocale = getDateFormatByLocale(TEXT_DATE_TIME_FORMAT) //'MMM D, YYYY h:mm A'

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <BuildingContainer>
            <div className="location-content">
              <span className="icon-location"></span>
              <div className="location-label">{buildingName}</div>
            </div>

            <ConditionalTooltip
              type={ConditionalTooltip.TYPE.ICON}
              content={`${dataUpdated} : ${moment().format(
                lDateFormatbyLocale ? lDateFormatbyLocale : TEXT_DATE_TIME_FORMAT
              )}`}
            >
              <Icon
                width="15px"
                height="15px"
                name="infocircle2"
                color="#bfbfbf"
              />
            </ConditionalTooltip>
          </BuildingContainer>
          {
            imageInfoData && imageInfoData?.imageTitle && (
              <ParaName><TranslateComponent>{imageInfoData?.imageTitle}</TranslateComponent></ParaName>
            )
          }
          
          {imageInfoData && imageInfoData?.imageUrl && (
            <StyledImg
              width={220}
              height={100}
              alt={preview}
              src={imageInfoData?.imageUrl}
              has-title={imageInfoData && imageInfoData?.imageTitle}
            />
          )}
          {
            imageInfoData && imageInfoData?.imageDescription && (
            <PanelLabel>
              <TranslateComponent>{imageInfoData?.imageDescription}</TranslateComponent>
            </PanelLabel>
            )
          }
          
        </>
      )}
    </>
  )
}

export default ImageTextWidgetPreview
