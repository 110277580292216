import clsx from 'clsx'
import { Legend, LegendsGroup } from '../types'
import translate, { TranslateComponent } from 'src/common/translations'

interface ClickEvent {
  onClick: (args: any) => void
}

export function SimpleLegendGroupTemplate(props: LegendsGroup): any {
  const { items = [], onClick = () => null } = props

  return items.map((item) => {
    return (
      <SimpleLegendItemTemplate key={item.uuid} {...item} onClick={onClick} />
    )
  })
}

export function SimpleLegendItemTemplate(props: Legend & ClickEvent) {
  const {
    clickable,
    containerClassName,
    containerStyle,
    disabled,
    hide,
    Icon,
    iconProps,
    iconContainerClassName,
    isGroup,
    onClick,
    Template,
    text,
    textClassName,
    textStyle
  } = props

  if (hide) return null

  if (Template)
    return (
      <div
        onClick={() => onClick({ ...props })}
        className={clsx('t-chart-simple-legend-item', {
          't-chart-legend-item-group-title': isGroup,
          't-chart-disabled-item': disabled,
          't-chart-clickable-item-true': clickable,
          't-chart-clickable-item-false': !clickable
        })}
      >
        <Template {...props} />
      </div>
    )

  return (
    <div
      className={clsx('t-chart-simple-legend-item', containerClassName, {
        't-chart-disabled-item': disabled,
        't-chart-legend-item-group-title': isGroup
      })}
      style={containerStyle}
    >
      {!isGroup && Icon && (
        <div
          className={clsx('t-chart-legend-item-icon', iconContainerClassName)}
        >
          {Icon && <Icon {...iconProps} />}
        </div>
      )}
      <div
        onClick={() => onClick({ ...props })}
        className={clsx('t-chart-legend-item-text', textClassName, {
          't-chart-clickable-item-true': clickable,
          't-chart-clickable-item-false': !clickable
        })}
        style={textStyle}
        title={typeof text === 'object' ? '' : translate(text)}
      >
        {typeof text === 'object' ? text : <TranslateComponent>{text}</TranslateComponent>}
      </div>
    </div>
  )
}
