import { API } from 'aws-amplify'


export const GET_USER_SETTINGS_BY_USERID = `
  query getUserSettingByUserId($userId: ID!, $filter: ModelUserSettingFilterInput) {
    getUserSettingByUserId(userId: $userId, filter: $filter) {
      items {
        accountId
        id
        type
        userId
        settings
        enableIS
        enableDataLoss
      }
    }
  }
`

export const CREATE_USER_SETTINGS_BY_USERID = `
  mutation createUserSetting($input: CreateUserSettingInput!) {
    createUserSetting(input: $input) {
      accountId
      id
      type
      userId
      settings
    }
  }
`

export const UPDATE_USER_SETTINGS_BY_USERID = `
mutation updateUserSetting($input: UpdateUserSettingInput!) {
  updateUserSetting(input: $input) {
    accountId
    id
    type
    userId
    settings
  }
}
`

const DELETE_USER_SETTINGS_BY_USERID = `
mutation deleteUserSetting($input: DeleteUserSettingInput!) {
  deleteUserSetting(input: $input) {
    id
  }
}
`

export const createUserSettings = async (type, userId, settings, accountId) => {
  try {
    const input = {
      type,
      settings: JSON.stringify(settings),
      userId
    }
    if (accountId) {
      input.accountId = accountId
    }
    const result = await API.graphql({
      query: CREATE_USER_SETTINGS_BY_USERID,
      variables : { input }
    })
    const userSettings = result?.data?.createUserSetting
    return userSettings
  } catch (e) {
    console.log(e)
  }
}


export const updateUserSettings = async (id, settings) => {
  try {
    const input = {
      id,
      settings: JSON.stringify(settings)
    }
    const result = await API.graphql({
      query: UPDATE_USER_SETTINGS_BY_USERID,
      variables : { input }
    })
    const userSettings = result?.data?.updateUserSetting
    return userSettings
  } catch (e) {
    console.log(e)
  }
}


export const deleteUserSettings = async (id) => {
  try {
    if (id) {
      const input = {
        id
      }
      const result = await API.graphql({
        query: DELETE_USER_SETTINGS_BY_USERID,
        variables : { input }
      })
      const userSettings = result?.data?.deleteUserSetting
      return userSettings
    }
  } catch (e) {
    console.log(e)
  }
}


export const getUserSettingByUserId = async (userId, type, accountId) => {
  try {
    const filter = {}
    if (type) {
      filter['type'] = { eq: type }
    }
    if (accountId) {
      filter['accountId'] = { eq: accountId }
    }
    const result = await API.graphql({
      query: GET_USER_SETTINGS_BY_USERID,
      variables : { userId, filter }
    })
    const userSettings = result?.data?.getUserSettingByUserId?.items?.[0] || {}
    const parsedSettings = userSettings?.settings  && JSON.parse(userSettings?.settings)
    return {
      ...userSettings,
      settings: parsedSettings
    }
  } catch (e) {
    console.log(e)
  }
}


export const updateUserDefaultDashboard = async (dashboardLists = [], dashboardId, isDefault, userId) => {
  if (dashboardId && isDefault) {
    // find the existing user setting for dashboard
    const userSettingsId = dashboardLists?.find(
      (x) => x?.userSettingsId !== null
    )?.userSettingsId
    // update newly created dashboard as default one
    const settings = { id: dashboardId }
    if (userSettingsId) {
      // updates default
      userSettingsId && (await updateUserSettings(userSettingsId, settings))
    } else {
      // created default dashboard for this user
      userId && await createUserSettings('Dashboard', userId, settings)
    }
  }
}
