import * as d3 from 'd3'
import { flatten } from 'ramda'
import {
  SCALE_LINEAR,
  SCALE_TIME
} from 'src/chart-library/Utils/Scales/constant'
import { createScale } from 'src/chart-library/Utils/Scales/createScale'
import { calculateTickCountBasedOnDimension } from '../../PaddingSetup/helper'
import { adjustScaleLinearTickValues } from 'src/chart-library/Utils/Scales/ScaleLinear/utils'
import { diff } from 'just-diff'
import { emptyArrayifNotArray } from '../../helper'
import { isNumeric } from 'src/chart-library/CommonComponents/Bar/helper'

export const generateScaleHelper = (
  state: any = {},
  axisObj: any = {},
  newCanvasDetails: any,
  calculate = true
) => {
  const key = axisObj?.key
  const scale = axisObj?.scale || {}
  const canvasIndex = state.axisCanvas?.[key]?.canvasIndex || 0
  const cDimen = newCanvasDetails
    ? newCanvasDetails?.[`${canvasIndex}`]
    : state.canvasDetails?.[`${canvasIndex}`]
  const domain = calculate
    ? getCalculatedDomain(state, axisObj, scale, cDimen)
    : state?.generatedScales?.[key]?.domain

  if (cDimen?.width > 0 && cDimen?.height > 0) {
    const range = key?.includes('x') ? [0, cDimen?.width] : [cDimen?.height, 0]
    const newDomain =
      scale?.props?.type === SCALE_TIME
        ? [new Date(domain?.[0]), new Date(domain?.[1])]
        : domain
    const newObj: any = {
      range,
      domain,
      scaleType: scale?.props?.type,
      gScale:
        scale?.props?.type &&
        newDomain &&
        range &&
        createScale(scale?.props?.type, newDomain, range, scale?.scaleSettings)
    }
    if (scale?.props?.type === SCALE_TIME) {
      const fValue = new Date(scale?.categories?.[0])?.getTime()
      const sValue = new Date(scale?.categories?.[1])?.getTime()
      const stepInSeconds = (sValue - fValue) / 1000
      newObj['stepInSeconds'] = stepInSeconds
    }
    return newObj
  }
}

export const getCalculatedDomain = (
  state: any = {},
  axisObj: any = {},
  scale: any = {},
  cDim: any = {}
) => {
  const key = axisObj?.key
  let domain = []
  if (scale?.props?.type === SCALE_LINEAR) {
    if (scale?.props?.defaultRange) {
      domain = scale?.props?.defaultRange
    } else {
      let numbers: any = []
      if (key?.includes('x')) {
        if (scale?.categories) {
          numbers = Array.isArray(scale?.categories) ? scale?.categories : []
        }
      } else {
        const seriesData = state?.seriesData || {}
        const types = state?.seriesTypes || []
        const filteredSeriesTypes = types?.filter(
          (seriesObj: any) => {
            const show = state?.internalLegend?.isInternal ? !(state?.internalLegend?.hiddenLegends?.[seriesObj?.id]) : seriesObj?.show
            return (seriesObj?.yAxisKey === key && show) || (seriesObj?.axisKey === key && show)
          }
        )
        const groupedByType = d3.group(filteredSeriesTypes, (d: any) => d?.type)
        groupedByType?.forEach((types: any, key: any) => {
          if (
            [
              'line',
              'area',
              'areaRange',
              'column',
              'columnRange',
              'columnNew'
            ]?.includes(key)
          ) {
            types?.forEach((obj: any) => {
              let data =
                (seriesData
                  ? seriesData?.[obj?.seriesKey]?.data
                  : state?.seriesData?.[obj?.seriesKey]?.data) || []
              if (data?.length > scale?.categories?.length) {
                data = data?.slice(0, scale?.categories?.length)
              }
              const series = ['areaRange', 'columnRange']?.includes(key)
                ? flatten(data)
                : data
              numbers.push(...(series || []))
            })
          }
          if (key === 'stackedColumn') {
            // Development Pending
          }
          if (key === 'comparisonBar') {
            types?.forEach((obj: any) => {
              const data =
                (seriesData
                  ? seriesData?.[obj?.seriesKey]?.data
                  : state?.seriesData?.[obj?.seriesKey]?.data) || []
              const series =
                flatten(data?.map((m) => m?.values)?.map((v) => v?.value)) ?? []
              numbers.push(...(series || []))
            })
          }
          if (key === 'plotLine') {
            types?.forEach((obj: any) => {
              if (obj?.considerPlotLine) {
                numbers.push(obj?.value)
              }
            })
          }
        })
      }
      const extentValues: any = d3.extent(numbers)
      const dBasedTickCount: any = calculateTickCountBasedOnDimension(cDim?.height, scale?.props?.type)
      const tickCount = axisObj?.axis?.tickCount ? axisObj?.axis?.tickCount : (dBasedTickCount < 2 ? 1 : dBasedTickCount - 1)
      domain = (isFinite(extentValues?.[0]) && isFinite(extentValues?.[1])) ? adjustScaleLinearTickValues(
        extentValues?.[0],
        extentValues?.[1],
        tickCount,
        scale?.props?.paddingNotRequired,
        scale?.props?.padding
      ) : [null, null]
    }
  } else if (scale?.props?.type === SCALE_TIME) {
    if (scale?.props?.defaultRange) {
      domain = scale?.props?.defaultRange
    } else {
      domain = d3.extent(scale?.categories)
    }
  } else {
    domain = scale?.categories
  }

  return domain
}

export const shouldUpdateScale = (pAxisObj: any = {}, cAxisObj: any = {}) => {
  const type = cAxisObj?.scale?.props?.type !== pAxisObj?.scale?.props?.type
  const padding =
    cAxisObj?.scale?.props?.padding !== pAxisObj?.scale?.props?.padding
  const paddingNotRequired =
    cAxisObj?.scale?.props?.paddingNotRequired !==
    pAxisObj?.scale?.props?.paddingNotRequired
  const defaultRange =
    diff(
      emptyArrayifNotArray(cAxisObj?.scale?.props?.defaultRange),
      emptyArrayifNotArray(pAxisObj?.scale?.props?.defaultRange)
    )?.length > 0
  const categories =
    diff(
      emptyArrayifNotArray(cAxisObj?.scale?.categories),
      emptyArrayifNotArray(pAxisObj?.scale?.categories)
    )?.length > 0
  const visibleAt =
    cAxisObj?.axis?.visibleAt?.[0] !== pAxisObj?.axis?.visibleAt?.[0]

  return (
    type ||
    padding ||
    paddingNotRequired ||
    defaultRange ||
    categories ||
    visibleAt
  )
}
