import styles from './card-vertical.module.scss'

export const CardVertical = ({
  color=CardVerticalColor.default,
  icon=null,
  image=null,
  backgroundImage=null,
  backgroundSize='contain',
  backgroundColor=null,
  backgroundPosition=null,
  gradient=null,
  button=null,
  children=null,
  testId=''
}) => {
  return (
    <div
      className={styles.cardVertical}
      data-color={color}
      data-testid={testId !== '' ? "cardVertical" : testId}
      style={{
        background: gradient
      }}
    >
      <div
        className={styles.inner}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize,
          backgroundColor,
          backgroundPosition,
        }}
      >
        { (icon || image) &&
          <div className={styles.media}>
            { icon && <div className={styles.icon}>{icon}</div> }
            { image && <div className={styles.image}>{image}</div> }
          </div>
        }
        <div>
          {children}
        </div>
        {button}
      </div>
    </div>
  )
}

export enum CardVerticalColor {
  default='default',
  darkGreen='darkGreen'
}