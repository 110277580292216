import React from 'react'
import styled from 'styled-components'
import Radio from 'src/denali-ui/components/Radio'
import { Block } from '../../../components/layouts/index'
//import { Container, Content } from "../../../components/layouts"
import { Card } from '../../../components/layouts/card'
import { TranslateComponent } from '../../../common/translations'

const InformationLabel = styled.label`
  font-size: 20px;
`

const FieldDiv = styled.div`
  margin-top: 20px;
`

const ErrorDiv = styled.div`
  padding-bottom: 10px;
  color: #991909;
`

//TODO: REMOVE BELOW AFTER USER DETAILS PAGE
export const USERTYPES = {
  TRANE_OR_INDEPENDENT: 'Trane Employee',
  BUILDING_OWNER: 'Building Employee',
  CONTRACTOR: 'Contractor'
}

export const UserType = ({
  userInfoObject,
  userTypesList,
  setUserInfoObject,
  validateFields
}) => (
  <Card type="elevated">
    <Block padding="20px 20px 40px 20px" width="100%">
      <InformationLabel><TranslateComponent>User Type</TranslateComponent></InformationLabel>
      {userInfoObject.userType.error && (
        <ErrorDiv><TranslateComponent>Please select a user type.</TranslateComponent></ErrorDiv>
      )}
      <>
        {userTypesList.map(u => <FieldDiv key={u.id}>
            <span style={{ height: '100%', verticalAlign: 'middle' }}>
              <Radio
                checked={
                  userInfoObject.userType.value === u.id
                }
                label={<TranslateComponent>{u.name}</TranslateComponent>}
                onChange={() => {
                  if (
                    userInfoObject.userType.value !== u.id
                  ) {
                    const clonedVal = { ...userInfoObject }
                    clonedVal.userType.value = u.id
                    // In case modifying userType becomes important
                    // clonedVal.userType.modified = true
                    clonedVal.userAsset.value = ''
                    clonedVal.userAsset.error = false
                    clonedVal.applications = {}
                    setUserInfoObject(validateFields(clonedVal, ['userType']))
                  }
                }}
              />
            </span>
        </FieldDiv>
        )}
      </>
    </Block>
  </Card>
)

export default UserType
