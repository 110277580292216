import { createContext, useContext } from 'react'

/**
 * Default chart config.
 *
 *  - by default properties will return null value until chart is initialized with valid values
 *  - if return value is null, don't perform any action and just render empty value on component
 */
export const defaultChartConfig: ChartConfig = {
  axis: {
    lastUpdatedAt: null,
    getAxis: (diM: AxisDimention, axisIndex: number) => undefined
  },
  scale: {
    lastUpdatedAt: null,
    getScale: (diM: AxisDimention, axisIndex: number) => undefined,
    setDomain: (diM: AxisDimention, axisIndex: number, domain: any[]) => {}
  },
  grid: {
    lastUpdatedAt: null,
    getGrid: (gridIndex: number) => undefined
  }
}

/**
 * Context Provider for `chart` to share config details with subscribed components
 *
 */
export const ChartContext = createContext<ChartConfig>(defaultChartConfig)

export function useChartContext(): ChartConfig {
  return useContext<ChartConfig>(ChartContext)
}
