import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnglesUpDown, faBuildings } from "../lib/pro-solid-svg-icons"
import styles from "./building-selector.module.scss"
import { TranslateComponent } from "src/common/translations"

export const BuildingSelectorInput = ({ sidebarIsExpanded, selected=false, icon=faBuildings, children=null }) => {
  return (
    <button className={`${styles.buildingSelectorInput} ${sidebarIsExpanded ? '' : styles.collapsed} ${selected ? styles.selected : ''}`}>
      
      <div className={styles.inputIcon}>
        <FontAwesomeIcon icon={icon} />
      </div>

      { sidebarIsExpanded && children }
      { !children && sidebarIsExpanded &&
        <div className={styles.placeholder}>
          <TranslateComponent>Select Organization</TranslateComponent>
        </div>
      }

      { sidebarIsExpanded &&
        <div>
          <FontAwesomeIcon icon={faAnglesUpDown} />
        </div>
      }

    </button>
  )
}