import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import ContainerWrapper from 'src/components/Container'
import PageHeader from '../../components/pageHeaderNew/header'
import { PAGE_TITLE } from './constants'
import {
  ToolBarWrapper,
  ContentWrap,
  ElectricalDemandMainContent,
  ElectricalDemandChartSection,
  ChartAreaWrapper,
  ChartHeaderWrapper,
  NoDataMessage
} from './styles'
import { getSearchParams } from '../../common/helperFunctions.js'
import LoadAnalysisChart from './LoadAnalysisChart.jsx'
import {
  WarningContainer,
  EnergyConsumptionContainerDiv
} from 'src/pages/energyConsumption/styles.js'
import { EquipmentListSelector } from 'src/components/equipmentsListSelector'
import { Block } from 'src/components/layouts'
import colors from 'src/components/layouts/colors.json'
import ChartDateControls from 'src/components/chartDateControls/chartdatecontrols'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import Icon from 'src/denali-ui/components/Icon/index'
import { TranslateComponent } from 'src/common/translations'
import { useGetChartData } from 'src/hooks/chartDataHook'
import Select from 'src/components/legacy/components/select/select'
import ChartSettings from 'src/components/chart-settings-sc/chart-settings'
import { useQuery } from 'src/hooks/APIHooks'
import { GET_BUILDING_BY_ID } from 'src/common/queries/datasource'
import { isValidUOMForGetChartData } from '../dashboards/helper'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import  withFeatureRestrictions from 'src/common/featureRestrictionHOC'


const unitsOfMeasure = {
  kW: 'kW',
  kBTUs: 'kBTUs'
}

const LoadAnalysisContainer = () => {
  const {
    getChartData,
    getChartDataByTimeSlider,
    chartData,
    chartDataByTimeSlider,
    resetChartData,
    loadingChartData
  } = useGetChartData()
  const { width } = useBreakPoint()
  const [selectedEquipment, setSelectedEquipment] = useState(null)
  const [unit, setUnit] = useState('kW')
  const [loadAnalysisChartData, setLoadAnalysisChartData] = useState(null)
  const [timeSliderStartTime, setTimeSliderStartTime] = useState(null)
  const [timeSliderEndTime, setTimeSliderEndTime] = useState(null)
  const [searchParams] = useSearchParams()
  const { buildingId, organizationId } = getSearchParams()

  const [meterAvailability, setMeterAvailability] = useState(true)

  const [chartDates, setChartDates] = useState({
    chartStartDate: moment().subtract(1, 'months').format(BACKEND_DATE_FORMAT),
    chartEndDate: moment().format(BACKEND_DATE_FORMAT),
    dateError: false,
    activeRange: '1M'
  })

  const { data: buildingData, refetch: refetchBuildingData } = useQuery({
    query: GET_BUILDING_BY_ID,
    disableInitialLoad: true,
    dataPath: 'data.getBuilding'
  })

  const onEquipmentChange = (equipmentDetails) => {
    setSelectedEquipment(equipmentDetails)
  }

  useEffect(() => {
    // reset the state
    setMeterAvailability(true)
    setSelectedEquipment(null)
    setLoadAnalysisChartData(null)
    setUnit('kW')
    setTimeSliderStartTime(null)
    setTimeSliderEndTime(null)
    refetchBuildingData({ id: buildingId })
  }, [buildingId, organizationId, searchParams])

  useEffect(() => {
    if (selectedEquipment) {
      setTimeSliderStartTime(chartDates?.chartStartDate)
      setTimeSliderEndTime(chartDates?.chartEndDate)
      const selectedEquipmentType =
        selectedEquipment.type === 'VirtualMeter'
          ? 'InstantaneousPower'
          : 'RealPower'
      const chartDataRequest = {
        startDate: chartDates?.chartStartDate,
        endDate: chartDates?.chartEndDate,
        category: 'Equipment', // equipment or building level data needed
        chartType: 'Line',
        weatherProps: [],
        chartDetails: [
          {
            buildingId: buildingId,
            equipmentDetails: [
              {
                type: selectedEquipment.type,
                eId: [{ id: selectedEquipment.key }],
                pId: [selectedEquipmentType]
              }
            ]
          }
        ]
      }
      const uom = getUOMValue(unitsOfMeasure[unit])
      if (isValidUOMForGetChartData(uom)) {
        chartDataRequest.chartDetails[0].equipmentDetails[0]['uom'] = {
          [selectedEquipmentType]: uom
        }
      }
      getChartData(chartDataRequest)
    }
  }, [selectedEquipment, chartDates, unit])

  const getUOMValue = (uom) => {
    switch (uom) {
      case 'kBTUs':
        return 'kiloBtu'
      case 'kW':
        return 'kiloWatt'
      default:
        return ''
    }
  }

  useEffect(() => {
    if (chartData) {
      setLoadAnalysisChartData(chartData)
    }
  }, [chartData])

  // Called when there is no meters in the building
  const equipmentsAvailableCheck = (check) => {
    setMeterAvailability(check)
  }

  useEffect(() => {
    const startTime = new Date(timeSliderStartTime).getTime()
    const endTime = new Date(timeSliderEndTime).getTime()
    if (startTime && endTime) getChartDataByTimeSlider(startTime, endTime)
  }, [timeSliderStartTime, timeSliderEndTime])

  useEffect(() => {
    if (chartDataByTimeSlider?.data?.length > 0)
      setLoadAnalysisChartData(chartDataByTimeSlider)
  }, [chartDataByTimeSlider])

  const onChartControlUpdate = ({
    startDate,
    endDate,
    endError,
    startError,
    activeRange
  }) => {
    setChartDates({
      chartStartDate: startDate,
      chartEndDate: endDate,
      dateError: startError || endError,
      activeRange
    })
  }

  const updateUOM = (item) => {
    setUnit(unitsOfMeasure[item])
    trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CHANGE_CHART_UNITS, {
      uom: unitsOfMeasure[item]
    })
  }

  return (
    <ContainerWrapper
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding="0"
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <PageHeader
          pageTitle={<TranslateComponent>{PAGE_TITLE}</TranslateComponent>}
          titleTools={
            <ToolBarWrapper>{/* ActionToolBar Here */}</ToolBarWrapper>
          }
        />
      </Content>

      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        {buildingId ? (
          meterAvailability ? (
            <ElectricalDemandMainContent>
              <EnergyConsumptionContainerDiv>
                <ContentWrap>
                  <div className="pd-equip">
                    <EquipmentListSelector
                      testName="load-analysis"
                      equipmentTypes={['ElectricMeter', 'VirtualMeter']}
                      firstSortValue="Building Meter Total"
                      buildingId={buildingId}
                      equipmentsAvailableCheck={equipmentsAvailableCheck}
                      onChange={onEquipmentChange}
                    />
                  </div>
                </ContentWrap>
              </EnergyConsumptionContainerDiv>
              <ChartAreaWrapper>
                <ChartHeaderWrapper>
                  <ChartDateControls
                    //@ts-ignore
                    testName="load-analysis"
                    entity="load-analysis"
                    onUpdate={onChartControlUpdate}
                    rangesList={[
                      '2D',
                      '3D',
                      '5D',
                      '1W',
                      '1M',
                      '3M',
                      '6M',
                      'YTD',
                      '1Y'
                    ]}
                  />
                  <ChartSettings testName="load-analysis">
                    <div className="control-group">
                      <div className="unit-picker inline">
                        <label>{'Units'}:</label>
                        <Select
                          options={unitsOfMeasure}
                          onChange={updateUOM}
                          selectedItem={unit}
                        />
                      </div>
                    </div>
                  </ChartSettings>
                </ChartHeaderWrapper>
                <ElectricalDemandChartSection>
                  <LoadAnalysisChart
                    loadingChartData={loadingChartData}
                    chartData={loadAnalysisChartData}
                    timeSliderChartData={chartData}
                    chartDates={chartDates}
                    unit={unit}
                    activeObject={selectedEquipment}
                    activeLocation={buildingData}
                    setTimeSliderStartTime={setTimeSliderStartTime}
                    setTimeSliderEndTime={setTimeSliderEndTime}
                    selectedEquipment={selectedEquipment}
                  />
                </ElectricalDemandChartSection>
              </ChartAreaWrapper>
            </ElectricalDemandMainContent>
          ) : (
            <NoDataMessage>
              No meters available in the selected building.
            </NoDataMessage>
          )
        ) : (
          <ContentWrap>
            <Block display="flex" justify="center">
              {organizationId && (
                <WarningContainer>
                  <Icon
                    name="Warning"
                    width="25px"
                    height="25px"
                    margin="0px 10px"
                    color={colors.RED}
                  />
                  <p>
                    {
                      <TranslateComponent>
                        You have chosen an organization, please specify a
                        location.
                      </TranslateComponent>
                    }
                  </p>
                </WarningContainer>
              )}
            </Block>
          </ContentWrap>
        )}
      </Content>
    </ContainerWrapper>
  )
}

export default withFeatureRestrictions(
  LoadAnalysisContainer
)
