import { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSearchParams } from '../../common/helperFunctions'
import { usePrevious } from 'src/hooks'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { useDimensionsHook } from 'src/hooks/dimensionHook'
import {  useParams} from 'react-router-dom'

const WidgetsContext = createContext<any>(null)

export const WidgetsContextProvider = ({ children }: any) => {
  const [mode, setMode] = useState(null)
  const [reloadEvents, setReloadEvents] = useState(false)
  const [isFindingAdding, setIsFindingAdding] = useState(false)
  const {buildingId, organizationId} = getSearchParams()
  const [openWidgetPicker, setOpenWidgetPicker] = useState(false)
  const [handleFormSubmit, setHandleFormSubmit] = useState(false)
  const [selectedWidget, setSelectedWidget] = useState(null)
  const [widgetBuilding, setWidgetBuilding] = useState(null)
  const [widgetSalesOfficeId, setWidgetSalesOfficeId] = useState(null)
  const [widgetBuildingDetails, setWidgetBuildingDetails] = useState(null)
  const userInfo = useSelector(selectUserInfo)
  const [isLoading, setIsLoading] = useState(false)
  const [isBuildingChange, setIsBuildingChange] = useState(false)
  const [isOpenCreateWidget, setIsOpenCreateWidget] = useState(false)
  const [widgetDetails, setWidgetDetails] = useState(null)
  const [isLocationLoading, setIsLocationLoading] = useState(false)
  const [objectPickerError, setObjectPickerError] = useState(false)
  const [objectPickerSelectedValue, setObjectPickerSelectedValue] = useState(null)
  const previousObjectPickerSelectedValue = usePrevious(objectPickerSelectedValue)
  const [modalType, setModalType] = useState('')
  const [formikErrors, setFormikErrors] = useState({})
  const [isShowColorPicker, setIsShowColorPicker] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isFormValidationAlone, setIsFormValidationAlone] = useState(false)  
  const [selectedBaseLine, setSelectedBaseLine] = useState(null)
  const [widgetId, setWidgetId] = useState(null)
  const [showWidgetPreview, setShowWidgetPreview] = useState(false)
  const [selectedWidgetDetails, setSelectedWidgetDetails] = useState(null)
  const [widgetResizeDimension, setWidgetResizeDimension] = useState([])
  const params = useParams();
  const dashboardID = params?.id;
  const [isFooterChecked, setIsFooterChecked] = useState(!!dashboardID)
  const [selectedBuildingChart, setSelectedBuildingChart] = useState([])

    // Dimension hook to get all Dimensions
    const { defaultDimensionGroup, allDimensions,  getAllDimensions} = useDimensionsHook()

    useEffect(() => {
      getAllDimensions()
    },[])

  return (
    <WidgetsContext.Provider
      value={{
        mode,
        setMode,
        reloadEvents,
        setReloadEvents,
        isFindingAdding,
        setIsFindingAdding,
        buildingId,
        organizationId,
        openWidgetPicker,
        setOpenWidgetPicker,
        handleFormSubmit,
        setHandleFormSubmit,
        selectedWidget,
        setSelectedWidget,
        widgetBuilding,
        setWidgetBuilding,
        widgetSalesOfficeId,
        setWidgetSalesOfficeId,
        userInfo,
        isLoading,
        setIsLoading,
        isOpenCreateWidget,
        setIsOpenCreateWidget,
        widgetDetails,
        setWidgetDetails,
        modalType,
        setModalType,
        widgetBuildingDetails,
        setWidgetBuildingDetails,
        isLocationLoading,
        setIsLocationLoading,
        objectPickerError,
        setObjectPickerError,
        formikErrors,
        setFormikErrors,
        isShowColorPicker,
        setIsShowColorPicker,
        objectPickerSelectedValue,
        setObjectPickerSelectedValue,
        isSubmitted,
        setIsSubmitted,
        isFormValidationAlone,
        setIsFormValidationAlone,
        selectedBaseLine,
        setSelectedBaseLine,
        previousObjectPickerSelectedValue,
        widgetId, 
        setWidgetId,
        isBuildingChange,
        setIsBuildingChange,
        defaultDimensionGroup,
        allDimensions,
        showWidgetPreview,
        setShowWidgetPreview,
        selectedWidgetDetails,
        setSelectedWidgetDetails,
        widgetResizeDimension, 
        setWidgetResizeDimension,
        isFooterChecked,
        setIsFooterChecked,
        selectedBuildingChart, 
        setSelectedBuildingChart
      }}
    >
      {children}
    </WidgetsContext.Provider>
  )
}

export const useWidgetsContext = () => useContext(WidgetsContext)
