import { ticksTextPaddingPicker } from './constants'

export const ticksTextPadding = (obj: any = {}, scaleType: any) => {
  const pickedFunction = ticksTextPaddingPicker?.[scaleType]
  if (pickedFunction) {
    const returnObject = pickedFunction(obj)
    return returnObject
  } else {
    return {}
  }
}
