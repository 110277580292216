import { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { useTooltipContext } from './TooltipProvider'

import { GroupAndClip } from '../../../Clip'
import { useTooltipLinkPointerContext } from './TooltipLinkPointerProvider'

export default function TooltipCanvas({
  children,
  canvasWidth,
  canvasHeight,
  triggerBy = 'axis',
  hide = false,
  linkPointer = false,
  crosslineProps,
  disableClip = false
}) {
  const [linePos, setLinePos] = useState(null)
  const rectRef = useRef(null)
  const uuid = useId()
  const linkCursor = useTooltipLinkPointerContext()

  const tooltip = useTooltipContext()

  const handleMouseOver = useCallback(
    (event) => {
      const { isInRange, x } = isPointerWithInRectArea(
        event,
        rectRef,
        canvasWidth,
        canvasHeight
      )

      if (isInRange) {
        linkPointer &&
          linkCursor.setCursorStatus({ event, isIn: true, x, name: uuid })
        tooltip.mouseIn(event, x)
      }
      setLinePos(x)
    },
    [
      tooltip,
      canvasWidth,
      canvasHeight,
      linkPointer,
      linkCursor.setCursorStatus
    ]
  )

  const handleMouseLeave = (event) => {
    const { isInRange } = isPointerWithInRectArea(
      event,
      rectRef,
      canvasWidth,
      canvasHeight
    )

    if (!isInRange) {
      linkPointer &&
        linkCursor.setCursorStatus({
          event,
          isIn: false,
          x: null,
          name: null
        })
      tooltip.mouseOut()
    }
    setLinePos((s) => (isInRange ? s : null))
  }

  const clipProps = useMemo(
    () =>
      hide
        ? {}
        : triggerBy === 'axis'
        ? {}
        : {
            ref: rectRef,
            onMouseMove: handleMouseOver,
            onMouseLeave: handleMouseLeave
          },
    [triggerBy, !hide]
  )

  const crossline = !hide && linePos !== null && triggerBy === 'axis'
  const hoverArea = !hide && triggerBy === 'axis'

  useEffect(() => {
    if (
      !hide &&
      linkPointer &&
      linkCursor.event !== null &&
      linkCursor.name !== uuid
    ) {
      if (linkCursor.isIn === true) {
        tooltip.mouseIn(linkCursor.event, linkCursor.x)
      }

      if (linkCursor.isIn === false) {
        tooltip.mouseOut()
      }

      setLinePos(() => (linkCursor.isIn ? linkCursor.x : null))
    }
  }, [hide, linkCursor, linkPointer])

  return (
    <GroupAndClip
      clipName="rbt-tooltip-play-area"
      gx={0}
      gy={0}
      rectClipProps={{
        width: canvasWidth,
        height: canvasHeight
      }}
      disableClip={disableClip}
      // {...clipProps}
    >
      {children}
      {crossline && (
        <line
          y1={0}
          y2={canvasHeight}
          x1={linePos}
          x2={linePos}
          stroke="#61ae34"
          opacity={0.6}
          strokeWidth={1}
          {...crosslineProps}
        />
      )}
      {hoverArea && (
        <rect
          ref={rectRef}
          onMouseMove={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          id="data-g-rect"
          x="0"
          y="0"
          width={canvasWidth}
          height={canvasHeight}
          fill="transparent"
        ></rect>
      )}
    </GroupAndClip>
  )
}

function isPointerWithInRectArea(event, rectRef, canvasWidth, canvasHeight) {
  const rectDimensions = rectRef.current.getBoundingClientRect()

  const { clientX, clientY } = event
  const rectX = Math.floor(rectDimensions?.x)
  const rectY = Math.ceil(rectDimensions?.y)

  const isInRange =
    clientX >= rectX &&
    clientX <= rectX + +canvasWidth &&
    clientY >= rectY &&
    clientY <= rectY + +canvasHeight

  const x = clientX - rectX
  const y = clientY - rectX

  return { isInRange, x, y }
}
