import styled from 'styled-components'
export const StyledFieldSet = styled.fieldset`
  border: none;
  display: flex;
 &.radio-group{
    padding-left:0px;
  }
  
   legend {
    font-weight: 600;
  }

`
export const StyledFieldSetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`
