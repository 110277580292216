import React, { useState, useCallback, useEffect } from 'react'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import _get from 'lodash/get'
import _set from 'lodash/set'

import { ASSOCIATION_TYPES } from './constants'
import {
  GET_CHILD_ASSOCIATION_EQUIPMENTLIST,
  GET_PARENT_ASSOCIATION_EQUIPMENTLIST,
  GET_DATA_CHARACTERISTICS
} from '../../graphql/queries'
import { UPDATE_EQUIPMENT_ASSOCIATION } from '../../graphql/mutations'

import Modal from '../../../../components/legacy/components/modal/modal'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import ErrorDialog from 'src/components/legacy/components/error-dialog/error-dialog.jsx'
import EquipmentTypeAssociation from './equipmentTypeAssociation.json'
import { transformAssociationPossibleEQName } from '../EquipmentDetailsParser'
import { ACTIONS } from 'src/constants/actions'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { DETAILS_TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import translate from 'src/common/translations'
import { TranslateComponent } from '../../../../common/translations'

AssociationDialog.propTypes = {
  currentEquipment: PropTypes.object,
  mode : PropTypes.string,
  details: PropTypes.object,
  initialValues: PropTypes.object,
  toggleAssociationModal: PropTypes.func,
  PropTypes,
  refetchAssociation: PropTypes.any
}

export default function AssociationDialog({
  currentEquipment,
  mode,
  details,
  initialValues,
  toggleAssociationModal,
  refetchAssociation,
  refetchEquipmentById,
  tableData
}) {
  const [t] = useTranslation()

  const parentChildTisEquipmentTypes =
    EquipmentTypeAssociation.parentChildUnitTypes

  const [eqType, setEQType] = useState('')
  const [meterType,setMeterType] = useState(null)
  const [eqAssociation, setEQAssocaition] = useState('')

  const {
    data: EQChildList,
    loading: childLoading,
    refetch: refetchchildDetails,
    error: getPossibleAssociatedEquipmentChildListError
  } = useQuery({
    query: GET_CHILD_ASSOCIATION_EQUIPMENTLIST,
    disableInitialLoad: true,
    variables: {
      parentId: currentEquipment?.id,
      buildingId: currentEquipment?.building?.id,
      type: eqType
    },
    dataPath: 'data.searchEquipment.items'
  })

  const {
    data: characteristicsListData
  } = useQuery({
    query: GET_DATA_CHARACTERISTICS,
    disableInitialLoad: ACTIONS.DELETE === mode || currentEquipment.type !== "VirtualMeter",
    errorPolicy: 'ignore',
    variables: { id: currentEquipment?.id, propertyType: 'Characteristic' },
    dataPath: 'data.getPropertyMappingByEquipmentID.items'
  })

  useEffect(() => {
    if (eqType && eqAssociation === ASSOCIATION_TYPES.CHILD) {
      refetchchildDetails()
    }
    if (eqType && eqAssociation === ASSOCIATION_TYPES.PARENT) {
      refetchParentDetails()
    }
  }, [eqAssociation,eqType])


  useEffect(() => {
    const {value} = characteristicsListData?.find(data => data.targetKey === "MeterType") || {}
    setMeterType(value)
  },[characteristicsListData])

  const {
    data: EQParentList,
    loading: parentLoading,
    refetch: refetchParentDetails,
    error: getPossibleAssociatedEquipmentParentListError
  } = useQuery({
    query: GET_PARENT_ASSOCIATION_EQUIPMENTLIST,
    disableInitialLoad: true,
    variables: {
      buildingId: currentEquipment?.building?.id,
      type: eqType
    },
    dataPath: 'data.searchEquipment.items'
  })

  let PossibleAssociatedEquipmentList = []

  const getPossibleAssociatedEquipmentList = useCallback(() => {
    if (
      (eqAssociation === ASSOCIATION_TYPES.CHILD ||
        details.association === ASSOCIATION_TYPES.CHILD) &&
      EQChildList?.length > 0
    ) {
      return transformAssociationPossibleEQName(EQChildList)
        ?.EquipmentAssociations
    } else if (
      (eqAssociation === ASSOCIATION_TYPES.PARENT ||
        details.association === ASSOCIATION_TYPES.PARENT) &&
      EQParentList?.length > 0
    ) {
      return transformAssociationPossibleEQName(EQParentList)
        ?.EquipmentAssociations
    }
  }, [EQChildList, EQParentList, eqAssociation, ACTIONS.EDIT === mode])

  const { onSubmit: updateEquipmentAssociation } = useMutation({
    query: UPDATE_EQUIPMENT_ASSOCIATION,
    dataPath: 'data.updateEquipment'
  })

  const [isErrorDialogOpened, toggleErrorDialog] = useState(false)

  const getTisEquipmentTypesByAssociation = useCallback(
    (association) => {
      if (!association) {
        return []
      }

      const isParentAssociation = association === ASSOCIATION_TYPES.PARENT

      const filterPredicate = isParentAssociation
        ? ({ childUnitType }) => childUnitType === currentEquipment.type
        : ({ parentUnitType }) => parentUnitType === currentEquipment.type
      const mapPredicate = isParentAssociation
        ? ({ parentUnitType, parentUnitTypePresentationName }) => ({
            key: parentUnitType,
            value: parentUnitTypePresentationName
          })
        : ({ childUnitType, childUnitTypePresentationName }) => ({
            key: childUnitType,
            value: childUnitTypePresentationName
          })

      return parentChildTisEquipmentTypes
        ?.filter(filterPredicate)
        ?.map(mapPredicate)
    },
    [parentChildTisEquipmentTypes]
  )

  const getModalConfig = ({ isValid, handleSubmit, values }) => ({
    gray: true,
    heading: `${mode} Association`,
    className: 'association-dialog-modal',
    buttons: [
      {
        text: ACTIONS.DELETE === mode ? t('common:Yes') : t('common:Save'),
        handleClick: handleSubmit,
        type: 'confirm',
        disabled:
          ACTIONS.DELETE === mode
            ? false
            : !PossibleAssociatedEquipmentList?.find(
                ({ objectId }) => objectId === values?.equipmentId
              )?.objectName
      },
      {
        text: ACTIONS.DELETE === mode ? t('common:No') : t('common:Cancel'),
        handleClick: toggleAssociationModal,
        type: 'cancel'
      }
    ],
    handleClose: toggleAssociationModal,
    loading: childLoading
  })

  const replaceParentId = (id = '', idToBeRemoved) => {
    if (id.indexOf(idToBeRemoved) === 0) {
      return id?.replace(`${idToBeRemoved}#`, '') || 'NA'
    }
    return id?.replace(`#${idToBeRemoved}`, '') || 'NA'
  }
// For Delete Equip Association
  const handleDelete = async () => {
    let updateParentId = null
    let isMultipleParents = null
    let modifiedParentId
      if (details?.association === ASSOCIATION_TYPES.CHILD) {
        isMultipleParents = details?.parentId?.includes('#')

        updateParentId = await updateEquipmentAssociation({
          input: {
            id: details?.objectId,
            parentId: isMultipleParents
              ? replaceParentId(details?.parentId, currentEquipment?.id)
              : 'NA'
          }
        })
      }
      if (details?.association === ASSOCIATION_TYPES.PARENT) {
        isMultipleParents = currentEquipment?.parentId?.includes('#')

        if (isMultipleParents) {
          modifiedParentId = replaceParentId(
            currentEquipment?.parentId,
            details?.objectId
          )

          updateParentId = await updateEquipmentAssociation({
            input: { id: currentEquipment?.id, parentId: modifiedParentId }
          })
        } else {
          updateParentId = await updateEquipmentAssociation({
            input: { id: currentEquipment?.id, parentId: 'NA' }
          })
        }
      }
      
      const isParent = details?.association === ASSOCIATION_TYPES.PARENT;
  refetchAssociation(modifiedParentId, details?.objectId, isParent, mode)
  refetchEquipmentById()
        toggleAssociationModal()
    
  }

  // For ADD Equip Association
  // isRefetchNeeded we would like to block refetch for Edit use case
  const handleAdd = async(values, isRefetchNeeded=true) =>{
    let updateIsParent = null
    let updateParentId = null

      if (
        values?.association === ASSOCIATION_TYPES.CHILD ||
        details?.association === ASSOCIATION_TYPES.CHILD
      ) {
        let isParentExists = false
        let exixtingParentId
  
        EQChildList?.some((equipment) => {
          if (
            equipment.id === values?.equipmentId ||
            equipment.id === details?.objectId
          ) {
            if (equipment.parentId !== 'NA') {
              isParentExists = true
              exixtingParentId = equipment.parentId
            }
          }
        })
  
        updateParentId = await updateEquipmentAssociation({
          input: {
            id: values?.equipmentId,
            parentId: isParentExists
              ? exixtingParentId + '#' + currentEquipment?.id
              : currentEquipment?.id
          }
        })
  
        if (updateParentId?.id) {
          updateIsParent = await updateEquipmentAssociation({
            input: { id: currentEquipment?.id, isParent: true }
          })
        }

      } else if (
        values?.association === ASSOCIATION_TYPES.PARENT ||
        details?.association === ASSOCIATION_TYPES.PARENT
      ) {
        updateParentId = await updateEquipmentAssociation({
          input: {
            id: currentEquipment?.id,
            parentId:
              currentEquipment?.parentId === 'NA'
                ? values?.equipmentId
                : currentEquipment?.parentId + '#' + values?.equipmentId
          }
        })
        if (updateParentId?.id) {
          updateIsParent = await updateEquipmentAssociation({
            input: { id: values?.equipmentId, isParent: true }
          })
        }
        
        
      }
      if (isRefetchNeeded) {
       
          toggleAssociationModal()
          const isParent =
          values?.association === ASSOCIATION_TYPES.PARENT ||
          details?.association === ASSOCIATION_TYPES.PARENT
            refetchAssociation(values?.equipmentId, details?.association,  isParent,mode)
            refetchEquipmentById()
      }
      
  }

// For Edit Equip Association

  const handleEdit = async (values) =>{
    let updateParentId = null
    let updateIsParent = null
    
      let modifiedParentId = ''
      if (details?.association === ASSOCIATION_TYPES.CHILD) {
        const isMultipleParents = details?.parentId?.includes('#')  
        handleAdd(values, false);
        updateParentId = await updateEquipmentAssociation({
          input: {
            id: details?.objectId,
            parentId: isMultipleParents
              ? replaceParentId( details?.parentId, currentEquipment?.id,'')
              : 'NA'
          }
        })
        
      }
     
        if (details?.association === ASSOCIATION_TYPES.PARENT) {
          const isMultipleParents = currentEquipment?.parentId?.includes('#')
          if (isMultipleParents) {
            modifiedParentId = currentEquipment?.parentId?.replace(
              details?.objectId,
              values.equipmentId
            )
          } else {
            modifiedParentId = values.equipmentId
          }
          updateEquipmentAssociation({
            input: {
              id: values.equipmentId,
              isParent : true
            }
          })
          updateParentId = await updateEquipmentAssociation({
            input: { id: currentEquipment?.id, parentId: modifiedParentId }
          })

        }
       
      

      const isParent = values?.association === ASSOCIATION_TYPES.PARENT || details?.association === ASSOCIATION_TYPES.PARENT;
        refetchAssociation(values.equipmentId, details?.objectId, isParent,mode)
        refetchEquipmentById()
        toggleAssociationModal()
      
   
  }
  
  const handleSubmit = async (values) => {
    
    switch(mode) {
      case ACTIONS.DELETE:
        handleDelete();
        trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.DELETE_ASSOCIATION)
        return;
      case ACTIONS.ADD:
        handleAdd(values);
        trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.SAVE_EQUIPMENT_ASSOCIATION, {mode:"ADD"})
        return;
      case ACTIONS.EDIT:
        handleEdit(values);
        trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.SAVE_EQUIPMENT_ASSOCIATION, {mode:"EDIT"})
        return;
      default:
        toggleErrorDialog(!isErrorDialogOpened)
        return;
    }
  
  }
  useEffect(() => {
      if (ACTIONS.EDIT === mode){
      if (details.association === ASSOCIATION_TYPES.PARENT) {
        refetchParentDetails({
          buildingId: currentEquipment?.building?.id,
          type: details.unitType
        })
      } else {
        refetchchildDetails({
          parentId: currentEquipment?.id,
          buildingId: currentEquipment?.building?.id,
          type: details.unitType
        })
      }
    }   

  }, [])

  useEffect(() => {
    if (
      getPossibleAssociatedEquipmentParentListError &&
      getPossibleAssociatedEquipmentChildListError
    ) {
      toggleErrorDialog(!isErrorDialogOpened)
    }
  }, [
    getPossibleAssociatedEquipmentParentListError,
    getPossibleAssociatedEquipmentChildListError
  ])

  const getEquimentOptions = (params) => {
    const mappedData = params?.map((item) => ({
      key: item.objectId,
      value: item.objectName
    }))
    const filteredData = mappedData?.filter(
      (item) => !tableData?.find((it) => it.id === item.key)
    )

    return filteredData
  }

  const translatedValues = Object.values(ASSOCIATION_TYPES).map((value) => ({
    key: value,
    value : translate(value)
  }))
  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnChange={true}
      >
        {({ values, isValid, handleSubmit }) => {
          let tisEquipmentTypes = []
          if(currentEquipment.type === "VirtualMeter"){
            if(meterType){
              const alltTisEquipmentTypes = getTisEquipmentTypesByAssociation(
                values.association
              )
                tisEquipmentTypes = alltTisEquipmentTypes.filter(type => 
                  /Electric/i.test(meterType) ? /Electric/i.test(type.key) : 
                  /Gas/i.test(meterType) ? /Gas/i.test(type.key) : true
                )
            }
          }else{
            tisEquipmentTypes = getTisEquipmentTypesByAssociation(
              values.association
            )
          }
          PossibleAssociatedEquipmentList = getPossibleAssociatedEquipmentList()
          const equipmentOptions = getEquimentOptions(
            PossibleAssociatedEquipmentList
          )

          return (
            <Modal testName={DETAILS_TEXT_NAME_PREFIX("assocDialog")} {...getModalConfig({ isValid, handleSubmit, values })}>
              {mode === ACTIONS.DELETE ? (
                <div>
                  <TranslateComponent>Are you sure you want to delete association between</TranslateComponent>{' '}
                  {details.equipmentName} <TranslateComponent>and</TranslateComponent> {currentEquipment.name}?
                </div>
              ) : (
                <Form>
                  <EditableView
                    type={EditableView.COMPONENT_TYPE.SELECT}
                    className="association-type"
                    labelName={<div><TranslateComponent>Is this a parent or child of </TranslateComponent>{currentEquipment.name}</div>}
                    readOnly={mode === ACTIONS.EDIT}
                    name="association"
                    value={
                      mode === ACTIONS.EDIT
                        ? details.association
                        : values.association
                    }
                    options={translatedValues}
                    onChange={(association, { values, setValues }) => {
                      setValues({
                        ...values,
                        association,
                        equipmentType: undefined,
                        equipmentId: undefined
                      })
                      setEQAssocaition(association)
                    }}
                    isUserdefinedOptions={false}
                  />
                  <EditableView
                    type={EditableView.COMPONENT_TYPE.SELECT}
                    className="equipment-type"
                    labelName="Equipment Type"
                    readOnly={mode === ACTIONS.EDIT}
                    name="equipmentType"
                    value={
                      mode === ACTIONS.EDIT
                        ? details.unitTypePresentationName
                        : _get(
                            tisEquipmentTypes.find(
                              ({ key }) => key === values.equipmentType
                            ),
                            'value'
                          )
                    }
                    options={tisEquipmentTypes}
                    onChange={(equipmentType, { values, setValues }) => {
                      setValues({
                        ...values,
                        equipmentType,
                        equipmentId: undefined
                      })
                      setEQType(equipmentType)
                    }}
                  />
                  <EditableView
                    type={EditableView.COMPONENT_TYPE.SELECT}
                    className="equipment-name"
                    labelName="TIS Equipment Name"
                    name="equipmentId"
                    value={
                      PossibleAssociatedEquipmentList?.find(
                        ({ objectId }) => objectId === values?.equipmentId
                      )?.objectName
                    }
                    options={equipmentOptions}
                    disabled={parentLoading || childLoading}
                  />
                </Form>
              )}
            </Modal>
          )
        }}
      </Formik>
      {isErrorDialogOpened && (
        <ErrorDialog
          title={t('errors:Error')}
          handleClose={() => toggleErrorDialog(!isErrorDialogOpened)}
          text={t('errors:unexpected-error')}
        />
      )}
    </>
  )
}
