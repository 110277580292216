import {
  getIcon,
  getGoalType,
  getValuesCurrentBg,
  GOAL_TYPE_TREND,
  WIDGET_STYLES,
  colorAndText
} from './helper'
import { CustomKPIWidgetTopSection, CustomKPIWidgetColorSection } from '../../styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import translate, { TranslateComponent } from 'src/common/translations'

export const CustomKPITrendTopPreview = ({ widgetDetails, goal }) => {
  const [t] = useTranslation()
  const currentBG = getValuesCurrentBg(
    widgetDetails?.previousPeroid,
    widgetDetails?.currentPeriod,
    widgetDetails?.goalCurrentPeriod,
    widgetDetails?.trendUpIsGood
  )

  const lengthOfTimeStamp = widgetDetails?.chartData?.timestamp?.length - 1
  const getLastTimeStamp =  (widgetDetails?.chartData && widgetDetails?.chartData?.timestamp) && widgetDetails?.chartData?.timestamp[lengthOfTimeStamp]
  const month = getLastTimeStamp ? moment(getLastTimeStamp.split('T')[0]).format('MMM') : moment().format('MMM')
  const year = getLastTimeStamp ? moment(getLastTimeStamp.split('T')[0]).format('YYYY') :  moment().format('YYYY')

  const GOALS = {
    meeting: translate('MEETING GOAL'),
    beating: translate('BEATING GOAL'),
    missing: translate('MISSING GOAL'),
    met: translate("MET"),
    beat: translate("BEAT"),
    missed: translate("MISSED")
  }
  const KPITranslate = {
    truckRolls: translate("TRUCK ROLLS"),
    energyStarScore: translate("ENERGY STAR SCORE"),
    hotColdCalls: translate("HOT/COLD CALLS"),
    downtime: translate("DOWNTIME"),
    carbonFootprint: translate("CARBON FOOTPRINT")
  }

  return (
    <>
    <CustomKPIWidgetTopSection>
      <div className="item1">
          <span className={`icon icon-${getIcon(widgetDetails?.selectedKpiMeasurement)}`} />
      </div>
      <div className="item2">
        <span>
          {KPITranslate[widgetDetails?.selectedKpiMeasurement]}
        </span>
      </div>
      <div className="item3" style={{
          background:
            goal === GOAL_TYPE_TREND.missed
              ? WIDGET_STYLES.goalBlockBgMissed
              : WIDGET_STYLES.goalBlockBg,
          color:  currentBG === WIDGET_STYLES.valuesBlockBgYellow
          ? WIDGET_STYLES.textDark
          : WIDGET_STYLES.textLight
        }}>
        <span>{GOALS[goal]}</span>
      </div>
      <div className="item4">
        <span><TranslateComponent>{month}</TranslateComponent></span>
        <span>{year}</span>
      </div>
    </CustomKPIWidgetTopSection>
    <CustomKPIWidgetColorSection>
      {
        colorAndText?.map((content) => {
          return (
            <div key={content?.name} className="main-div">
            <div className='commonBox' style={{backgroundColor: `${content?.color}`}}></div>
            <div className='commonText'><TranslateComponent>{content?.name}</TranslateComponent></div>
            </div>
          )
        })
      }

    </CustomKPIWidgetColorSection>
    </>
  )
}
