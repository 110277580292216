import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation, Trans } from 'react-i18next'
import _omit from 'lodash/omit'
import _isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'
import { NotificationContext } from 'src/components/legacy/providers/notification-provider'
import Header from 'src/components/legacy/components/header/header'
import InlineHelp from 'src/components/legacy/components/inline-help/inline-help'
import Toolbar from 'src/components/legacy/components/toolbar/toolbar'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import TargetPathView from 'src/components/target-path/TargetPathView'
import {
  statusOptionList,
  nextStepOptionList
} from 'src/components/legacy/common/finding'
import KPI from 'src/components/legacy/components/kpi/kpi'
import LabelBlank from 'src/components/LabelBlank/index'
import HeadlineView from 'src/components/legacy/components/headline-view'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import Priority from 'src/components/Priority/Priority'
import TextEditor from 'src/components/text-editor/index'
import AlertNotification from 'src/components/legacy/components/alert-notification'
import File from 'src/components/file-download/file'
import ImageGalleryWrapper from 'src/components/legacy/components/image-gallery-wrapper'
import {
  getField,
  getUrlSearchParamValue
} from 'src/components/legacy/common/helpers'
import {
  DATE_FORMAT,
  BACKEND_DATE_FORMAT
} from 'src/components/legacy/common/time-helpers.js'
import PAGE_NAMES, { NOTIFICATIONS } from 'src/components/legacy/common/pages'
import EditableField from 'src/pages/issuesFindings/blocks/editable-field'
import { Container, Content } from 'src/components/layouts/index'
import COLORS from 'src/components/legacy/common/colors.json'
import { MAX_PAGE_WIDTH } from 'src/components/legacy/common/opportunity.js'
import { AssignedTo } from 'src/components/AssignedTo/index'
import NotifyContent from 'src/pages/opportunities/OpportunityDetail/notify-content/index'
import { ACTIONS } from 'src/constants'
import {
  newRefreshToken,
  selectUserInfo,
  // selectUserPermissions
} from 'src/redux/slicers/appData'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { FindingViewWrapper } from '../styles'
import { OpportunityViewWrapper } from 'src/pages/opportunities/styles'
import { useMutation } from 'src/hooks/APIHooks'
import { DELETE_FINDING, UPDATE_FINDING } from '../graphql'
import AddToOpportunityModal from '../add-opportunities-to-finding/index'
import {
  CREATE_ISSUE_OPPORTUNITY,
  CREATE_OPPORTUNITY,
  DELETE_ISSUE_OPPORTUNITY
} from 'src/pages/opportunities/graphql'
import _difference from 'lodash/difference'
import { CREATE_ENTRY_WITH_ATTACHMENT } from 'src/common/queries/attachment'
import _set from 'lodash/set'
import PdfDownloadDetailView from '../../../components/print-components/pdf-download-detail-view/pdf-download-detail-view'
import { PdfDownloadDetailViewPages } from '../../../components/print-components/helpers'
import translate from 'src/common/translations'
import { AccessControl } from 'src/components/accessControl'
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import {  GET_BUILDING_BY_ID,  } from "../../utilityBillData/graphql"
import { useQuery } from 'src/hooks/APIHooks'
import { useDispatch } from 'react-redux'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { accessControlFunc } from 'src/components/accessControl'

let images = []
const FindingDetailViewPage = ({ finding, notificationContext }) => {
  const { width } = useBreakPoint()
  const [pdfFinding, setPdfFinding] = useState(null)
  const navigate = useNavigate()
  const userInfo = useSelector(selectUserInfo)
  const [navigateState, setNavigateState] = useState(null)
  const userAccess = useSelector(selectUserAccess)
  const userName = `${userInfo?.lastName} ${userInfo?.firstName}`
  const dispatch = useDispatch()
  // const permissions = useSelector(selectUserPermissions)
  // TODO permissions should be reworked. The following way that is commented now is incorrect.
  const notificationsOn = true //permissions?.isPermittedAction(NOTIFICATIONS)
  const hasCRUDpermissions = true // permissions.isPermittedAction(
  //   PAGE_NAMES.FINDINGS,
  //   'config'
  // )
  const [t] = useTranslation()

  const optionsTranslated = {
    clickHere: translate('Click here to create a new finding.'),
    createNew: translate('Create New Finding'),
    created: translate("Created By"),
  }

  const [dialogTypes, setDialogTypes] = useState({
    confirm: null,
    notification: null,
    error: null
  })
  const [showOpportunityModal, setShowOpportunityModal] = React.useState(false)
  const [isOpportunityModalLoading, setIsOpportunityModalLoading] =
    React.useState(false)
  const [showInlineSpinner, setShowInlineSpinner] = React.useState({
    status: false,
    nextStep: false,
    assignedTo: false
  })
  const { onSubmit: deleteS3AttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })
  const { onSubmit: deleteIssueOpportunity } = useMutation({
    query: DELETE_ISSUE_OPPORTUNITY,
    onError: (error) => {
      setDialogTypes({ ...dialogTypes, error })
      setShowOpportunityModal(false)
      setIsOpportunityModalLoading(false)
    }
  })
  const { onSubmit: insertOpportunityMutation } = useMutation({
    query: CREATE_OPPORTUNITY,
    onError: (error) => {
      setDialogTypes({ ...dialogTypes, error })
    }
  })
  const { onSubmit: createIssueOpportunity } = useMutation({
    query: CREATE_ISSUE_OPPORTUNITY,
    onSuccess: () => {
      addFindingsWithOpportunitySuccessMessage()
    },
    onError: (error) => {
      setDialogTypes({ ...dialogTypes, error })
      setShowOpportunityModal(false)
      setIsOpportunityModalLoading(false)
    }
  })
  const { onSubmit: deleteFindingData } = useMutation({
    query: DELETE_FINDING,
    onSuccess: () => {
      (async () => {
        const attachmentBody = {
          modelType: 'Issue',
          modelData: {
            delete: {
              id: finding?.findingId
            }
          },
          attachments: {}
        }
        await deleteS3AttachmentMutation({
          input: JSON.stringify(attachmentBody)
        })
      })()
      const locationId = finding?.location?.locationId
      const orgId = finding?.organization?.organizationId
      const search =
        locationId && !orgId
          ? `?location=${locationId}`
          : !locationId && orgId
          ? `?organization=${orgId}`
          : locationId && orgId
          ? `?location=${locationId}&organization=${orgId}`
          : ''
      setNavigateState({page: `/${PAGE_NAMES.FINDINGS}${search}`, extra: { state: { mode: '' } } })
    }
  })

  const { onSubmit: upsertOpportunityMutation } = useMutation({
    query: UPDATE_FINDING,
    onError: (error) => {
      setDialogTypes({ ...dialogTypes, error })
    }
  })

  const errorMessageCode = null
  const errorMessage = null
  const description = getField(finding, 'settings.description')
  const settings = getField(finding, 'settings') || {}
  const targetDate = getField(finding, 'settings.targetDate')
    ? moment(finding.settings.targetDate).format(DATE_FORMAT)
    : null
  useEffect(() => {
    images = finding?.settings?.images
  }, [finding?.settings?.images])

  useEffect(() => {
    setPdfFinding(finding)
  }, [finding])
  const { data: buildingDetails, refetch: fetchBuildingDetails, loading: buildingDetailsLoading } = useQuery({
    query: GET_BUILDING_BY_ID,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.getBuilding',
  })
  useEffect(() => {
    if (finding?.location.locationId) {
      fetchBuildingDetails({ id:finding?.location.locationId})
    }
  }, [finding])
  

  const handleOpportunityModalClose = useCallback(
    () => setShowOpportunityModal(false),
    []
  )


  const textBlockIsVisible =
    (!_isEmpty(description) && description.blocks?.some(({ text }) => text)) ||
    (typeof description === 'string' && !_isEmpty(description.trim())) ||
    settings.chartLink ||
    settings.descriptionLink ||
    Boolean(finding?.opportunities && finding.opportunities.length)

  const onCloseDialog = (field) => () => {
    setDialogTypes({ ...dialogTypes, [field]: null })
  }

  const getRemoveMessage = (name, opportunities, t) => (
    <div>
      <p>{t('common:DeleteConfirm', { item: name })}</p>
      {Boolean(opportunities.length) && (
        <p>
          {t('opportunities:DeleteWarning', {
            name:
              opportunities.length === 1
                ? t('opportunities:Opportunity')
                : t('opportunities:Opportunities'),
            items: opportunities.map(({ name }) => name).join(', ')
          })}
        </p>
      )}
    </div>
  )

  const deleteFinding = ({ findingId, location, name, opportunities }) => {
    setDialogTypes({
      ...dialogTypes,
      confirm: {
        title: t('common:Delete', { item: t('opportunities:Issue/Finding') }),
        text: getRemoveMessage(name, opportunities, t),
        confirmText: t('common:Yes'),
        cancelText: t('common:No'),
        handleConfirm: async () => {
          trackEvent(USER_EVENTS.ISSUES_AND_FINDINGS.events.DELETE_ISSUE_FINDING, {
            "organization id": finding.organizationId.organizationId,
            "building id": finding.location.locationId
          })
          await dispatch<any>(
            newRefreshToken(
              [finding.location.locationId],
              null,
              null,
              ["IssueAdmin", "AttachmentAdmin"]
            )
          )
          deleteFindingData({ input: { id: finding?.findingId } })
        },
        handleCancel: () => {
          setDialogTypes({ ...dialogTypes, confirm: null })
        }
      }
    })
  }

  useEffect(() => {
    if (navigateState) {
      navigate(navigateState.page, navigateState.extra)
    }

  }, [navigateState])
  const handleAddingFindingToExistingOpportunity = async (opportunities) => {
    const opportunityIds = opportunities.map(({ key }) => key)
    const findingOpportunityIds =
      finding?.opportunities?.map((o) => o?.opportunityId) ?? []
    const createIssueOpportunityList =
      opportunities?.filter((o) => !findingOpportunityIds?.includes(o?.key)) ??
      []
    const deleteIssueOpportunityList =
      finding?.opportunities?.filter(
        (f) => !opportunityIds?.includes(f?.opportunityId)
      ) ?? []
    let isCreateOpportunityWithIssueDone = false
    if (finding?.findingId && createIssueOpportunityList?.length > 0) {
      isCreateOpportunityWithIssueDone = true
      createIssueOpportunityList?.map(async (opportunity) => {
        await createIssueOpportunity({
          input: {
            buildingId: finding?.location?.locationId,
            opportunityId: opportunity?.key,
            issueId: finding?.findingId
          }
        }).then((resIssueOpportunity) => {
          finding?.opportunities?.push({
            issueOpportunityId: resIssueOpportunity?.data?.createIssueOpportunity?.id,
            opportunityId: opportunity?.key,
            name: opportunity?.value
          })
        })
      })
    }
    if (deleteIssueOpportunityList?.length > 0) {
      deleteIssueOpportunityList?.map((data) => {
        deleteIssueOpportunity({ input: { id: data?.issueOpportunityId } })
      })
      finding.opportunities = _difference(
        finding.opportunities,
        deleteIssueOpportunityList
      )
      if (!isCreateOpportunityWithIssueDone) {
        addFindingsWithOpportunitySuccessMessage()
      }
    }
  }
  const addFindingsWithOpportunitySuccessMessage = () => {
    setIsOpportunityModalLoading(false)
    setShowOpportunityModal(false)
    setDialogTypes({
      ...dialogTypes,
      notification: {
        title: t('opportunities:AddFindingToOpportunity'),
        text: t('opportunities:SuccessfullyAddedFindingToOpportunity')
      }
    })
    trackEvent(USER_EVENTS.ISSUES_AND_FINDINGS.events.ADD_TO_OPPORTUNITY,
      {
        "building id": finding?.location?.locationId,
        "finding id": finding?.findingId,
        "organization id": finding?.opportunities?.organization?.organizationId,
        mode: "Existing opportunity"
      })
  }
  const handleAddingFindingToNewOpportunity = async (name) => {
    if (name) {
      const inputs = {
        title: name,
        accountId: finding?.organization?.organizationId,
        buildingId: finding?.location?.locationId,
        createdBy: userName,
        creationDate: moment().format(BACKEND_DATE_FORMAT)
      }
      await insertOpportunityMutation({ input: inputs }).then(async (res) => {
        const opportunityId = res.data?.createOpportunity?.id
        if (opportunityId) {
          await createIssueOpportunity({
            input: { buildingId: finding?.location?.locationId, opportunityId: opportunityId, issueId: finding?.findingId }
          }).then((resIssueOpportunity) => {
            finding?.opportunities?.push({
              issueOpportunityId: resIssueOpportunity?.data?.createIssueOpportunity?.id,
              opportunityId: opportunityId,
              name
            })
          })
        }
      })
    }
    trackEvent(USER_EVENTS.ISSUES_AND_FINDINGS.events.ADD_TO_OPPORTUNITY,
      {
        "opportunity name": name,
        "finding id": finding?.findingId,
        "organization id": finding?.organization?.organizationId,
        "building id": finding?.location?.locationId,
        "created by": userName,
        "creation date": moment().format(BACKEND_DATE_FORMAT),
        mode: "New opportunity"
      })
  }

  const onAddToOpportunity = () => {
    setShowOpportunityModal(true)
    trackEvent(USER_EVENTS.ISSUES_AND_FINDINGS.events.CLICK_ADD_TO_OPPORTUNITY)
  }

  const handleAssignedToFieldChanging = async (finding, fieldName, value) => {
    let notificationMessage = t('notifications:ThisEntityAssignedTo', {
      entity: t('opportunities:Issue/Finding')
    })
    setDialogTypes({
      ...dialogTypes,
      confirm: {
        title: t('notifications:SaveAndNotify'),
        text: (
          <NotifyContent
            name={finding.assignedTo?.key}
            entity={t('opportunities:Issue/Finding')}
            defaultValue={notificationMessage}
            onChangeMessage={(event) => {
              notificationMessage = event.target.value
            }}
          />
        ),
        confirmText: t('common:Send'),
        cancelText: t('notifications:NoNotification'),
        handleConfirm: () => {
          updatePropertyValues(fieldName, value)
          setDialogTypes({ ...dialogTypes, confirm: null })
        },
        handleCancel: async () => {
          updatePropertyValues(fieldName, value)
          setDialogTypes({ ...dialogTypes, confirm: null })
        }
      }
    })
  }

  const editableViewChangeHandler = (fieldName, finding) => async (value) => {
    finding = _omit(finding, ['opportunities', 'settings.image'])
    finding[fieldName] = value
    setShowInlineSpinner({
      ...showInlineSpinner,
      [fieldName]: true
    })
    if (fieldName.includes('assignedTo') && notificationsOn) {
      await handleAssignedToFieldChanging(finding, fieldName, value)
    }else{
      updatePropertyValues(fieldName, value)
    }
  }

  const updatePropertyValues = async (fieldName, value) => {
    const inputs = {
      "id": finding?.findingId,
      [fieldName]: fieldName === "assignedTo" ? value?.key ?? '' : value
    }
    trackEvent(fieldName === "assignedTo" 
    ? USER_EVENTS.ISSUES_AND_FINDINGS.events.CHANGE_ISSUE_FINDING_ASSIGNEE 
    : fieldName === "nextStep" 
    ?  USER_EVENTS.ISSUES_AND_FINDINGS.events.CHANGE_ISSUE_FINDING_NEXT_STEP 
    : fieldName === "status"
    ? USER_EVENTS.ISSUES_AND_FINDINGS.events.CHANGE_ISSUE_FINDING_STATUS 
    : null, {
      "finding id": finding?.findingId,
      "assigned to": fieldName === "assignedTo" ? value.key: null,
      "next step": fieldName === "nextStep" ? value : null,
      "status": fieldName === "status" ? value : null,
      "organization id": finding.organizationId.organizationId,
      "building id": finding.location.locationId
    })
    await upsertOpportunityMutation({ input: inputs }).then((res) => {
      setShowInlineSpinner({
        ...showInlineSpinner,
        [fieldName]: false
      })
      if (res?.error?.errors?.length > 0) return
      _set(finding, fieldName, value)
    })
  } 

  const inlineHelpText = [
    {
      heading: t('opportunities:Issues/Findings'),
      body: t('opportunities:FindingsDescription')
    }
  ]

  const handleOpportunityModalLoading = useCallback(
    (isOpportunityModalLoading) =>
      setIsOpportunityModalLoading(isOpportunityModalLoading),
    []
  )

  const handleDownloadImage = (image) => {
    setPdfFinding(null)
    setTimeout(() => {
      const pdfFindingDetail = pdfFinding
      pdfFindingDetail.settings.images = pdfFindingDetail?.settings?.images?.map((i) =>
        i?.name === image?.name
          ? { ...i, original: image?.original }
          : i
      )
      setPdfFinding(pdfFindingDetail)
    }, 10)
  }
  return (
    <OpportunityViewWrapper>
      <FindingViewWrapper>
        <div className="detail-view-page">
          {finding ? (
            <Container
              sColumns={12}
              mColumns={12}
              lColumns={12}
              xlColumns={12}
              width={width}
              maxWidth={`${MAX_PAGE_WIDTH}px`}
            >
              {/* <TitleComponent title="issues-findings-name" params={{ findingName: finding.name }}
							ignoreForcedUpdating={true} /> */}
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                border={'none'}
                width={width}
              >
                <Header
                  hideToolBar={false}
                  pageTitle={finding.name}
                  titleTools={
                    <>
                      <InlineHelp inlineHelpText={inlineHelpText} />
                      {hasCRUDpermissions && (
                        <span
                          className={`icon-visible ${
                            finding.visibleToCustomer ? 'green' : ''
                          } tooltip-container`}
                        >
                          <div className="tooltip center-top">
                            {finding.visibleToCustomer
                              ? t('opportunities:Visible')
                              : t('opportunities:NotVisibleToCustomer')}
                          </div>
                        </span>
                      )}
                    </>
                  }
                >
                  <Toolbar>
                    <AccessControl id="tc.pages.findings.add">
                      <Link
                        to={{
                          pathname: `/${PAGE_NAMES.FINDINGS}/add`,
                          search: getUrlSearchParamValue(
                            finding?.location?.locationId,
                            finding?.organization?.organizationId
                          )
                        }}
                        state={{ mode: ACTIONS.ADD }}
                      >
                        <button className="action small" data-testid="create-new">
                          {t('components:toolbar>CreateNew')}
                        </button>
                      </Link>
                    </AccessControl>
                   
                    {pdfFinding && <PdfDownloadDetailView downloadData={pdfFinding} type={PdfDownloadDetailViewPages.FINDING} />}

                    <AccessControl id="tc.pages.findings.add">
                      <span
                        data-testid="copy"
                        className="icon icon-duplicate tooltip-container"
                        onClick={() => {
                          navigate(
                            `/${PAGE_NAMES.FINDINGS}/${finding.findingId}`,
                            { state: { mode: ACTIONS.COPY } }
                          )
                        }}
                      >
                        <div className="tooltip dark center-top">
                          {t('components:toolbar>Copy', {
                            item: t('opportunities:Finding')
                          })}
                        </div>
                      </span>
                    </AccessControl>
                    <AccessControl id="tc.pages.findings.edit">
                      <span
                        data-testid="edit"
                        className="icon icon-edit tooltip-container"
                        onClick={() => {
                          navigate(
                            `/${PAGE_NAMES.FINDINGS}/${finding.findingId}`,
                            { state: { mode: ACTIONS.EDIT } }
                          )
                        }}
                      >
                        <div className="tooltip dark left-top">
                          {t('components:toolbar>Edit', {
                            item: t('opportunities:Finding')
                          })}
                        </div>
                      </span>
                    </AccessControl>
                    <AccessControl id="tc.pages.findings.delete">
                      <span
                        data-testid='delete'
                        className={classNames(
                          'icon',
                          'icon-trash2',
                          'tooltip-container',
                        )}
                        onClick={() => deleteFinding(finding)}
                      >
                        <div className="tooltip dark left-top">
                          {t('components:toolbar>Delete', {
                            item: t('opportunities:Finding')
                          })}
                        </div>
                      </span>
                    </AccessControl>
                    
                  </Toolbar>
                </Header>
              </Content>
              <Content
                xlColumn={8}
                lColumn={7}
                mColumn={7}
                sColumn={12}
                width={width}
                border={'none'}
              >
               <TargetPathView
                  organizationName={finding.organization.organizationName?finding.organization.organizationName:buildingDetails&&buildingDetails.accountName}
                  locationName={
                    buildingDetails&&buildingDetails.name
                  }
                  tisObjects={finding.tisObjects}
                />
              </Content>
              <Content
                xlColumn={4}
                lColumn={5}
                mColumn={5}
                sColumn={12}
                width={width}
                border={'none'}
                className="created-info"
              >
                {finding.settings.proposedBy && (
                  <LabelBlank
                    className="created-info-item"
                    labelText={`${optionsTranslated.created}:`}
                    value={finding.settings.proposedBy}
                  />
                )}
                {finding.settings.proposalDate && (
                  <LabelBlank
                    className="created-info-item"
                    labelText={`${t('opportunities:CreatedDate')}:`}
                    value={finding.settings.proposalDate}
                  />
                )}
                <LabelBlank
                  className="created-info-item"
                  labelText={`${t('opportunities:Finding/FoundDuring')}:`}
                  value={
                    t(`components:findingIssueFound>${finding.foundDuring}`) ||
                    ''
                  }
                />
              </Content>
              {settings && settings.kpi && (
                <Content
                  xlColumn={12}
                  lColumn={12}
                  mColumn={12}
                  sColumn={12}
                  width={width}
                  border="none"
                >
                  <KPI kpi={settings.kpi} containerWidth={width} />
                </Content>
              )}
              {images.length ? (
                <Content
                  className="detail-view-image-block"
                  xlColumn={6}
                  lColumn={8}
                  mColumn={12}
                  sColumn={12}
                  width={width}
                  border="none"
                >
                  <ImageGalleryWrapper
                    items={images}
                    showPlayButton={false}
                    showBullets={true}
                    showThumbnails={false}
                    isLazyLoading={true}
                    handleDownloadImage = {handleDownloadImage}
                  />
                </Content>
              ) : null}
              {textBlockIsVisible && (
                <Content
                  className="description"
                  xlColumn={images.length ? 6 : 12}
                  lColumn={images.length ? 4 : 12}
                  mColumn={12}
                  sColumn={12}
                  width={width}
                  background={COLORS.WHITE}
                >
                  <TextEditor content={description} isViewMode={true} />
                  {(settings.chartLink || settings.descriptionLink) && (
                    <HeadlineView
                      title={`${t('opportunities:ChartLink')}:`}
                      className="detail-headline-view"
                    >
                      {settings.chartLink ? (
                        <a href={settings.chartLink}>
                          {settings.descriptionLink || settings.chartLink}
                        </a>
                      ) : (
                        <span>{settings.descriptionLink || ''}</span>
                      )}
                    </HeadlineView>
                  )}
                  {Boolean(
                    finding.opportunities && finding.opportunities.length
                  ) && (
                    <HeadlineView
                      title={`${t('opportunities:OpportunityLink')}:`}
                      className="detail-headline-view"
                    >
                      {finding.opportunities.map(
                        ({ name, opportunityId }, i) => (
                          <Link
                            key={i}
                            state={{ mode: ACTIONS.VIEW, id: opportunityId }}
                            to={`/${PAGE_NAMES.OPPORTUNITIES}/${opportunityId}`}
                          >
                            {name}
                          </Link>
                        )
                      )}
                    </HeadlineView>
                  )}
                </Content>
              )}
              <Content
                className="detail-view-page-footer"
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                width={width}
              >
                <Container
                  xlColumns={12}
                  lColumns={12}
                  mColumns={12}
                  sColumns={12}
                  width={width}
                  className="more-opportunity-info"
                >
                  <Content
                    xlColumn={3}
                    lColumn={4}
                    mColumn={12}
                    sColumn={12}
                    width={width}
                    border="none"
                  >
                    {finding.priority && (
                      <Priority priority={finding.priority} />
                    )}
                  </Content>
                  <Content
                    xlColumn={5}
                    lColumn={4}
                    mColumn={6}
                    sColumn={12}
                    width={width}
                    border="none"
                    className="file-container"
                  >
                    {settings && settings.file && settings.file.id && (
                      <File
                        fileId={settings.file && settings.file.id}
                        fileFullName={settings?.file?.displayName}
                        description={settings.file.description}
                        name={settings.file.name}
                        buildingId={settings.file.buildingId}
                        label={`${t('opportunities:SupportingDocument')}:`}
                      />
                    )}
                  </Content>
                  <Content
                    xlColumn={4}
                    lColumn={4}
                    mColumn={6}
                    sColumn={12}
                    width={width}
                    border="none"
                    className="footer-info-block"
                  >
                    {finding.status && (
                      <div className="footer-info-item" data-testid="footer-info-status">
                        <label>{t('opportunities:Status')}:</label>
                        <div className="finding-row">
                          {showInlineSpinner.status && <Spinner />}
                          <EditableField
                            fieldName="status"
                            fieldKey={finding.status}
                            value={t(
                              `components:findingStatuses>${finding.status}`
                            )}
                            label={`${t('opportunities:Status')}:`}
                            options={statusOptionList(t)}
                            onChange={editableViewChangeHandler(
                              'status',
                              finding
                            )}
                          />
                        </div>
                      </div>
                    )}
                    {finding.nextStep && (
                      <div className="footer-info-item" data-testid="footer-info-nextStep">
                        <label>{t('opportunities:NextStep')}:</label>
                        <div className="finding-row">
                          {showInlineSpinner.nextStep && <Spinner />}
                          <EditableField
                            fieldName="nextStep"
                            fieldKey={finding.nextStep}
                            value={t(
                              `components:nextStepsFinding>${finding.nextStep}`
                            )}
                            label={`${t('opportunities:NextStep')}:`}
                            options={nextStepOptionList(t)}
                            onChange={editableViewChangeHandler(
                              'nextStep',
                              finding
                            )}
                          />
                        </div>
                      </div>
                    )}
                    <div className="footer-info-item" data-testid="footer-info-assignedTo">
                      {showInlineSpinner.assignedTo ? (
                        <Spinner />
                      ) :
                        <AssignedTo
                          defaultValue={finding.assignedTo?.key}
                          readOnlyValue={finding.assignedTo?.key}
                          onChange={editableViewChangeHandler(
                            'assignedTo',
                            finding
                          )}
                          locationId={
                            (finding.location && finding.location.locationId) ||
                            finding.locationId
                          }
                          isToggleMode={true}
                          // readOnlyValue={assignedToUser || ""}
                          page="finding-view"
                        />
                      }
                    </div>
                    {targetDate && (
                      <LabelBlank
                        className="footer-info-item"
                        labelText={`${t('opportunities:TargetDate')}:`}
                        value={targetDate}
                      />
                    )}
                  </Content>
                </Container>
              </Content>
              <Content
                xlColumn={12}
                lColumn={12}
                mColumn={12}
                sColumn={12}
                width={width}
                border="none"
                className="detail-view-page-pdf"
              >
                <Toolbar>
                  <AccessControl id="tc.pages.opportunities.add">
                    <button className="action small" onClick={onAddToOpportunity}>
                      {t('opportunities:AddToOpportunity')}
                    </button>
                  </AccessControl>
                </Toolbar>
              </Content>
              <StandardDialogs
                confirm={dialogTypes.confirm}
                notification={dialogTypes.notification}
                error={dialogTypes.error}
                onCloseDialog={onCloseDialog}
              />
              {showOpportunityModal && (
                <AddToOpportunityModal
                  handleClose={handleOpportunityModalClose}
                  opportunities={finding.opportunities}
                  findingOrganizationId={finding.organization.organizationId}
                  onHandleAddFindingToExistingOpportunity={
                    handleAddingFindingToExistingOpportunity
                  }
                  onHandleAddFindingToNewOpportunity={
                    handleAddingFindingToNewOpportunity
                  }
                  isLoading={isOpportunityModalLoading}
                  handleLoading={handleOpportunityModalLoading}
                  accountId={finding?.organization?.organizationId}
                  buildingId={finding?.location?.locationId}
                />
              )}
            </Container>
          ) : (
            <>
              {errorMessage ? (
                <section className="not-found">
                  <AlertNotification
                    icon="error"
                    message={
                      finding === null || errorMessageCode === 404 ? (
                        <Trans i18nKey={'opportunities:FindingDoesNotExist'}>
                          The finding you are looking for does not exist. Select
                          a different finding from the
                          <Link to={`/${PAGE_NAMES.FINDINGS}`}>
                            Issues/Findings List
                          </Link>
                          .
                        </Trans>
                      ) : (
                        errorMessage
                      )
                    }
                  />
                  <h2>{optionsTranslated.clickHere}</h2>
                  <Link to={`/${PAGE_NAMES.FINDINGS}`}>
                    <button className="action">
                      {optionsTranslated.createNew}
                    </button>
                  </Link>
                </section>
              ) : (
                <Spinner />
              )}
            </>
          )}
        </div>
      </FindingViewWrapper>
    </OpportunityViewWrapper>
  )
}

const withNotificationContext = (Component) => (props) =>
  (
    <NotificationContext.Consumer>
      {(context) => <Component notificationContext={context} {...props} />}
    </NotificationContext.Consumer>
  )

FindingDetailViewPage.propTypes = {
  findingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasCRUDpermissions: PropTypes.bool,
  organizationId: PropTypes.number,
  location: PropTypes.shape({
    state: PropTypes.object
  }),
  userName: PropTypes.string.isRequired,
  width: PropTypes.number,
  notificationsOn: PropTypes.bool,
  notificationContext: PropTypes.object,
  userFirstAndLastName: PropTypes.string
}

export default withNotificationContext(React.memo(FindingDetailViewPage))
