import { useEffect, useState } from 'react'
import _keys from 'lodash/keys'
import _every from 'lodash/every'
import Highlights from './EUHightlightsBlock'
import { useTranslation } from 'react-i18next'
import { useBreakPoint } from 'src/hooks/breakPoint'
import TextEditor from 'src/components/text-editor'
import {
    SectionContainerWrap,
    SectionToggleWrapper,
    BarChartToggleWrapper,
    SectionHighlight,
    StyledButton,
    ToolbarWrapper,
    PlotHolder,
    MainBlock,
    TextEditorWrapperDiv,
    DivSpacer,
    BarChartColumn,
    BarChartLabelColumn
} from '../styles'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import {
    MAX_LENGTH_OF_NAME,
    TEXT_EDITOR_TOOLBAR_OPTIONS
} from '../helper'
import { useNavigate } from 'react-router'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { HR, LegendWrapper, LegendContainer, LegendItem } from './EUHightlightsBlock/highlights'
import EnergyUsageReportChart from '../../../../src/chart-library/Charts/EnergyUsageReportChart'
import PieChart from '../../../chart-library/Charts/EnergyUsageReportChart/pieChart/pie-chart'
import { formatDollarLabel } from '../../../chart-library/Charts/EnergyUsageReportChart/pieChart/helperFunctions'
import { ACTIONS } from 'src/constants'

const EUReportBodySection = (props) => {
    const {
        handleFormSubmit,
        isSubmit,
        handleToggle,
        barChartData,
        pieChartData,
        handleSubSectionVisbility,
        subSectionVisbility,
        report,
        benchMarksOptionList,
        onChangeBenchMark,
        selectedBenchMark,
        pieChartSummarySetter,
        dataError,
        noDataError,
        reloadCount,
        setReloadCount,
        setIsTriggerValidation,
        mode
    } = props || {}
    const { values, setFieldValue, errors, setFieldTouched, touched, isSubmitting } = props?.formik
    const { width } = useBreakPoint()
    const navigate = useNavigate()
    const [t] = useTranslation()
    const [isValid, setIsValid] = useState(false)

    const { highlights } = values || {}

    const { summary } = values || {}
    const summaryDetails = summary && (typeof summary === "string" ? summary : values?.reportId ? (typeof summary === "string" ? JSON.parse(summary) : summary) : summary)
    const textContent = summaryDetails || "";

    const onUpdateFields = (fields) => (values) => {
        setFieldValue(fields, values)
    }

    useEffect(() => {
        setIsValid(_keys(props?.errors)?.length)
    }, [props])




    const toolbar = () => (
        <ToolbarWrapper className="toolbar-buttons">
            <button className={`primary ${isSubmitting ? 'wait' : ''}`} disabled={isValid || dataError || noDataError || !pieChartData?.pieData || _every(pieChartData?.pieData, { value: 0 })} onClick={handleFormSubmit}>
                {isSubmitting ? <div className="spinner" /> : t('common:Save')}
            </button>
            <button
                className="secondary"
                onClick={() => {
                    navigate('/reports')
                }}
            >
                {t('common:Cancel')}
            </button>
        </ToolbarWrapper>
    )

    const getBarchartFormattedData = (bData) => {
        return {
            barData: [...bData]
        }
    }

    const EUBarChartxtickLabelProps = {
        euxtickLabel: {
            prefix: false,            
            isShowFormatterSymbol: false,
            label: "kw"
        },
        ecxtickLabel: {
            prefix: true,
            isShowFormatterSymbol: true,
            label: "$"
        }
    }

    const updatedBarChartData = (mode === ACTIONS.ADD)
    ? barChartData
    : (mode === ACTIONS.EDIT)
        ? {
            ...barChartData,
            barData: {
                ...barChartData?.barData,
                ecChart: barChartData?.barData['ecChart']?.map(it => ({
                    ...it,
                    chartType: barChartData?.eciTitle
                }))
            }
        }
        : barChartData;

    return (
        <>
            <div className="text-section column">
                <header>
                    <SectionContainerWrap >
                        <div>
                            <TextInput
                                labelText={`${'Section Title'}`}
                                value={values?.summaryTitle}
                                name="summaryTitle"
                                placeholder='The Summary Title'
                                onChange={(e) => {
                                    setIsTriggerValidation(true)
                                    setFieldTouched('summaryTitle')
                                    setFieldValue('summaryTitle', e?.target?.value)
                                }}
                                hasError={errors?.['summaryTitle'] && touched?.summaryTitle}
                                isRequired={true}
                                errorMessage={errors?.['summaryTitle'] && touched?.summaryTitle ? errors?.['summaryTitle'] : ''}
                                maxLength={MAX_LENGTH_OF_NAME}
                                customLabelClass={'sub-title'}
                            />
                        </div>
                        <div />
                        <SectionToggleWrapper>
                            <label>{'Visible'}</label>
                            <ToggleButtonHorizontal
                                name="visiblePieChart"
                                checked={subSectionVisbility.showPieChart}
                                onChange={(e) => {
                                    setIsTriggerValidation(true)
                                    handleSubSectionVisbility({
                                        ...subSectionVisbility,
                                        showPieChart: e?.target?.checked
                                    })
                                    setFieldValue('pieChartData', { ...pieChartData, showPieChart: e?.target?.checked })

                                }}
                            />
                        </SectionToggleWrapper>
                    </SectionContainerWrap>
                </header>
                <>
                    {!noDataError &&
                        <MainBlock>
                            <TextEditorWrapperDiv>
                                {summary &&
                                    <TextEditor key={reloadCount}
                                        content={textContent}
                                        editorStyle={{ "fontSize": "14px", "height": "250px" }}
                                        textEditorToolbarOptions={TEXT_EDITOR_TOOLBAR_OPTIONS}
                                        changeContent={onUpdateFields('summary')}
                                        isStripPastedStyles={true}
                                        toolbarCustomButtons={[
                                            <StyledButton
                                                type="submit"
                                                key="refresh-text"
                                                onClick={() => {
                                                    setReloadCount(reloadCount + 1)
                                                    pieChartSummarySetter(pieChartData, report)
                                                }
                                                }
                                            >
                                                Refresh Text
                                            </StyledButton>
                                        ]}
                                    />
                                }
                            </TextEditorWrapperDiv>
                            <PlotHolder>

                                {
                                    pieChartData?.pieData && !_every(pieChartData?.pieData, { value: 0 }) &&
                                    <PieChart
                                        data={pieChartData?.pieData}
                                        valueFormatter={d => formatDollarLabel(d)}
                                    />
                                }
                            </PlotHolder>

                        </MainBlock>
                    }
                </>
            </div>
            {!dataError && !noDataError &&
                <BarChartToggleWrapper>
                    <label>{'Visible'}</label>
                    <ToggleButtonHorizontal
                        name="visibleShowBarChart"
                        checked={subSectionVisbility.showBarChart}
                        onChange={(e) => {
                            setIsTriggerValidation(true)
                            handleSubSectionVisbility({
                                ...subSectionVisbility,
                                showBarChart: e?.target?.checked
                            })
                            setFieldValue('barChartData', { ...barChartData, showBarChart: e?.target?.checked })
                        }
                        }
                    />
                </BarChartToggleWrapper>
            }
            {
                !dataError && !noDataError && <><DivSpacer>
                    <BarChartColumn>
                        <header className='flex-container'>
                            <BarChartLabelColumn className='item'>
                                <TextInput
                                    labelText={`${'Section Title'}`}
                                    value={values?.euiTitle}
                                    name="euiTitle"
                                    placeholder='Energy Usage Index'
                                    onChange={(e) => {
                                        setIsTriggerValidation(true)
                                        setFieldTouched('euiTitle')
                                        setFieldValue('euiTitle', e?.target?.value)
                                    }}
                                    hasError={errors?.['euiTitle'] && touched?.euiTitle}
                                    isRequired={true}
                                    errorMessage={errors?.['euiTitle'] && touched?.euiTitle ? errors?.['euiTitle'] : ''}
                                    maxLength={MAX_LENGTH_OF_NAME}
                                    inputClass={"section-title"}

                                />
                            </BarChartLabelColumn>
                            {benchMarksOptionList() && benchMarksOptionList()?.length
                                ? <BarChartLabelColumn className='item'>

                                    <label>{'BenchMark'}</label>
                                    <SelectrixWrapper
                                        className={
                                            'selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select'
                                        }
                                        customKeys={{ key: 'key', label: 'value' }}
                                        defaultValue={selectedBenchMark}
                                        inputPlaceholder={"-Select Two-"}
                                        onChange={onChangeBenchMark}
                                        multiple={false}
                                        options={benchMarksOptionList()}
                                        placeholder={"-Select One-"}
                                        searchable={false}
                                    />
                                </BarChartLabelColumn>
                                : ""}
                        </header>
                        <>
                            <EUChartComponent
                                id="eui"
                                barChartData={barChartData}
                                getBarchartFormattedData={getBarchartFormattedData}
                                handleToggle={handleToggle}
                                chartKey="euChart"
                                xtickLabel={EUBarChartxtickLabelProps.euxtickLabel}
                                width={width}
                                axisLabel={{ xAxis: () => <>kBTU/ft<sup>2</sup>/year</> }}
                            />
                        </>
                    </BarChartColumn>
                    <BarChartColumn>
                        <header>
                            <BarChartLabelColumn className='eui-title'>
                                <TextInput
                                    labelText={`${'Section Title'}`}
                                    value={values?.eciTitle}
                                    name="eciTitle"
                                    placeholder='Energy Cost Index'
                                    onChange={(e) => {
                                        setIsTriggerValidation(true)
                                        setFieldTouched('eciTitle')
                                        setFieldValue('eciTitle', e?.target?.value)
                                    }}
                                    hasError={errors?.['eciTitle'] && touched?.eciTitle}
                                    isRequired={true}
                                    errorMessage={errors?.['eciTitle'] && touched?.eciTitle ? errors?.['eciTitle'] : ''}
                                    maxLength={MAX_LENGTH_OF_NAME}
                                    inputClass={"section-title"}
                                />
                            </BarChartLabelColumn>
                        </header>
                        <>
                            <EUChartComponent
                                id="eci"
                               barChartData={updatedBarChartData}
                                getBarchartFormattedData={getBarchartFormattedData}
                                handleToggle={handleToggle}
                                chartKey="ecChart"
                                xtickLabel={EUBarChartxtickLabelProps.ecxtickLabel}
                                width={width}
                                axisLabel={{ xAxis: () => <>$/ft<sup>2</sup>/year</> }}
                            />
                        </>
                    </BarChartColumn>
                </DivSpacer>
                </>
            }
            <div className="text-section column">
                <header>
                    <SectionContainerWrap sm-gap={10}>
                        <div>
                            <TextInput
                                labelText={`${'Section Title'}`}
                                value={values?.highlightTitle}
                                name="highlightTitle"
                                placeholder='Highlight Title'
                                onChange={(e) => {
                                    setIsTriggerValidation(true)
                                    setFieldTouched('highlightTitle')
                                    setFieldValue('highlightTitle', e?.target?.value)
                                }}
                                hasError={errors?.['highlightTitle'] && touched?.highlightTitle}
                                isRequired={true}
                                errorMessage={errors?.['highlightTitle'] && touched?.highlightTitle ? errors?.['highlightTitle'] : ''}
                                maxLength={MAX_LENGTH_OF_NAME}
                                customLabelClass={'sub-title'}
                            />
                        </div>
                        <div />
                        <SectionToggleWrapper>
                            <label>{'Visible'}</label>
                            <ToggleButtonHorizontal
                                name="showHighlightVisibility"
                                checked={values?.showHighlights}
                                onChange={(e) => {
                                    setIsTriggerValidation(true)
                                    setFieldValue('showHighlights', e?.target?.checked)
                                }}
                            />
                        </SectionToggleWrapper>
                    </SectionContainerWrap>
                </header>
                <SectionHighlight>
                    <Highlights
                        fieldName="impactText"
                        highlights={highlights}
                        onChange={(field) => {
                            setIsTriggerValidation(true)
                            setFieldValue(`highlights`, field)
                        }}
                        errors={errors}
                        highlightTitlePlaceholder="Enter Impact Name"
                        highlightContentPlaceholder="Example: The equivalent of <enter impact value> for one year."
                    />
                </SectionHighlight>
            </div>
            {toolbar()}
        </>
    )
}

const EUChartComponent = ({ id, barChartData, chartKey, getBarchartFormattedData, xtickLabel, handleToggle, width = 0, axisLabel = null }) => {
    return (
        <div className="Chart">
            {
                barChartData && barChartData?.barData?.[chartKey] &&
                <><EnergyUsageReportChart
                    data={getBarchartFormattedData(barChartData?.barData?.[chartKey])}
                    className={width > 700 ? 'horizontal-bar-chart-dimention' : 'small-view-horizontal-bar-chart-dimention'}
                    xtickLabel={xtickLabel}
                    isSmallWindow={width < 700}
                />
                    <p style={{ textAlign: "center", fontSize:"12px",fontWeight:"bold"}}>{axisLabel?.xAxis()}</p>
                </>
            }
            {
            barChartData && barChartData?.barData?.[chartKey] &&
            <LegendContainer className="legend-container">
                {
                    barChartData?.barData?.[chartKey]?.map(((item, i) => item?.name && <LegendItem className="legend-item" key={item?.name}>
                        <div className={`${i === 1 ? 'benchmark-checkbox-color' : 'bar-checkbox-color'}`}>
                            <input type="checkbox" id={`${id}-${item.name}`} checked={item.value} onClick={(e) => handleToggle(e, chartKey, i)} />
                            <LegendWrapper className="legend-wrapper" htmlFor={`${id}-${item.name}`}>
                                {item.name.replace("Index", "")}
                            </LegendWrapper>
                        </div>
                    </LegendItem>
                    ))
                }
            </LegendContainer>
        }
        </div>
    )
}

export default EUReportBodySection

