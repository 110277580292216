import { useState, useMemo, useEffect } from 'react'
import { useLegendApiContext } from '../Legend/provider/LegendApiProvider'

// Create a Model mapper for each series
// This fn will map properties from each series to legend Model
function stackLegendModelMapper(data: any = {}, gen) {
  return data?.name
    ? gen({
        text: data.name,
        ...data?.legend,
        iconProps: {
          fill: data.color,
          symbol: 'symbolSquare',
          ...data?.legend?.iconProps
        },
        groupBy: data?.legendGroupName || 'DefaultGroupBy'
      })
    : null
}

export default function useStackLegend(data, isDisconnectedFromLegendContext) {
  // Legend Context that provides required Api to communicate with legend state
  const legendApi = useLegendApiContext()

  const legendState = useMemo(() => legendApi.init(), [legendApi])

  // Create all callback using useState to store all selected / clicked legend details
  // Using this, we can filter data that are not selected and display them in UI
  const [clickedItems, setClickedItems] = useState([])

  // following fn is responsible for creating legends from data
  // This will run whenever new data is passed and create legend model for each item
  const legends = useMemo(() => {
    // Items - store each lenged
    // keys - for look-up purpose to check the availability of legends
    const initial = { items: [], keys: {}, group: {} }

    // isWrappedByLegendProvider is false means the chart is used without legend wrapper
    // if false - don't perform anything
    if (
      !legendApi.isWrappedByLegendProvider ||
      isDisconnectedFromLegendContext
    ) {
      return data
    }

    return data.reduce(
      (av, d) => {
        const l = stackLegendModelMapper(d, legendApi.createLegendModel)
        if (l) {
          av.items.push(l)
          av.keys[l.text] = true
          av.group[l.groupBy] = { ...d.legendGroup }
        }
        return av
      },

      initial
    )
  }, [legendApi, data])

  // following fn is reponsibile for filtering out unselected data to display in chart
  const stackData = useMemo(() => {
    // isWrappedByLegendProvider is false means the chart is used without legend wrapper
    // if false - don't perform anything
    if (
      !legendApi.isWrappedByLegendProvider ||
      isDisconnectedFromLegendContext
    ) {
      return data
    }

    const selected = clickedItems.reduce(
      (av, cv) => ((av[cv.text] = true), av),
      {}
    )
    return data.filter((d) => {
      // if data doesn't have name property, it wont be controlled by legends
      if (!d.name) return true

      // Below condition
      // returns true - if legend is not clicked and visible
      // returns false - if legend is clicked and grayed out
      return (legends as any)?.keys[d.name] && !selected[d.name]
    })
  }, [legends, clickedItems])

  useEffect(() => {
    if (legends.items) {
      // Create group of legends with title if provided and post to legendApi
      const group = Object.entries(legends.group)

      const _clickedItems = []

      const legend = group.map(([name, options]: any[]) => {
        const groupItem =
          name !== 'DefaultGroupBy'
            ? {
                text: name,
                ...options
              }
            : null

        const legendItems = legends.items.filter((i) => i.groupBy === name)

        // check a legend item is disabled / clicked already. if so, mark it as disable and store it
        legendItems.forEach((item) => {
          const isClicked = clickedItems.some(
            (clickedItem) => clickedItem.text === item.text
          )
          item.disabled = isClicked

          isClicked && _clickedItems.push({ ...item })
        })

        return legendApi.createLegendGroupModel(legendItems, groupItem)
      })

      // Check existing clicked items are in new legend data
      const isAllItemsAvaiable = clickedItems.every(
        (clickedItem) => legends.keys[clickedItem.text]
      )

      // if any of clicked item is not in new legend data, replace the old clicked items
      if (!isAllItemsAvaiable) {
        setClickedItems(_clickedItems)
      }

      legendState.postLegendDetails(legend)
    }
  }, [legends])

  useEffect(() => {
    // Method to listen on all selected items
    !isDisconnectedFromLegendContext &&
      legendState.listenToGetAllSelectedItem(setClickedItems)
  }, [legendState, isDisconnectedFromLegendContext])

  // free up memory when stacked component unmounds
  useEffect(() => {
    const legendStateId = legendState.id
    return () => legendApi.unmount(legendStateId)
  }, [])

  return stackData
}
