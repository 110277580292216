import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { Formik } from 'formik'
import styled from 'styled-components'
import Spinner from 'src/components/legacy/components/spinner/spinner'
// components
import CharacteristicsForm from './characteristics-form'
import Label from 'src/components/legacy/components/label/label'
import Modal from 'src/components/legacy/components/modal/modal'
import _find from 'lodash/find'
import _orderBy from 'lodash/orderBy'
import _uniqBy from 'lodash/uniqBy'
import {
  DATA_TYPES,
  CONFIRMATION_POP_UP,
  EQUIPMENT_TYPES,
  CHARACTERISTIC_TYPES
} from './constants'
import {
  UPDATE_EQUIPMENT_PROPERTY,
  RESET_DATA_MAPPING
} from '../../graphql/mutations'
import {
  isNumberResolutionCorrect,
  deepDiff
} from 'src/components/legacy/common/helpers.js'
import {
  isBeta,
  isProduction
} from 'src/components/legacy/common/config/index.js'
import './style'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { DETAILS_TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import { resetToDefaultsByProperties } from '../helper'
import translate, { TranslateComponent } from '../../../../common/translations'
import store from 'src/redux/store'
import { siUnitMapping, convertUnits } from '../../../../../src/common/UOMConversions'

export const ModalText = styled.div`
  font-style: normal;
`

export const StyledLabel = styled.label`
  height: 20px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.24px;
  line-height: 20px;
  align-self: flex-end;
  margin-bottom: 10px;
  display: block;
`

export const Characteristics = ({
  equipmentDetails,
  equipmentType,
  isAssociatedEquipment,
  readOnly,
  atsPage = false,
  refetchCharacteristics,
  onSaveCharacteristicsATSChangesHandler,
  equipmentCharacteristics = [],
  loadingCharacteristics,
  atsTestCharacteristics,
  refetchBuildingTotalMeterEquipment,
  refetchCharacteristicsPossibleState,
  defaultDimensionGroup = {}
}) => {
  const { equipment: characteristics_equipmentId } = getSearchParams()
  const [viewConfirmation, setViewConfirmation] = useState(false)
  const [characteristicsList, setCharacteristicsList] = useState([])
  const [uomSymbolPairs, setUomSymbolPairs] = useState({})
  const profileSettings = store?.getState().appData?.userInfo?.data?.profileSettings;
  const UOM = profileSettings ? JSON.parse(profileSettings)?.unitOfMeasurement === "si" ? "SI" : "IP" : "IP"

  const isChiller = equipmentType == EQUIPMENT_TYPES.CHILLER
  const isBetaFeature = (isBeta() || !isProduction()) && isChiller

  const [t] = useTranslation()

  const equipmentId = isAssociatedEquipment
    ? equipmentDetails?.id
    : characteristics_equipmentId

  const { onSubmit: updateEquipmentProperty, loadingCharacteristics: saving } =
    useMutation({
      query: UPDATE_EQUIPMENT_PROPERTY,
      dataPath: 'data.updateEquipmentProperty'
    })

  const { onSubmit: onResetDataMapping } = useMutation({
    query: RESET_DATA_MAPPING
  })

  useEffect(() => {
    try {
      const uomSymbolPairs = {}
      const units = defaultDimensionGroup
      for (const unitType in units) {
        const unitArray = units[unitType]
        for (const unit of unitArray) {
          const unitValue = unit?.value
          if (unitValue?.UoM && unitValue?.Symbol) {
            uomSymbolPairs[unitValue?.UoM] = unitValue?.Symbol
          }
        }
      }
      setUomSymbolPairs(uomSymbolPairs)
    } catch (error) {}
  }, [defaultDimensionGroup])

  useEffect(() => {
    trackEvent(
      USER_EVENTS.EQUIPMENT_SETUP.events.VIEW_EQUIPMENT_CHARACTERISTICS
    )
  }, [])
  // Called when the charactertistics list changes, mostly on undefined selection from edit dialog
  useEffect(() => {
    atsPage &&
      atsTestCharacteristics &&
      setCharacteristicsList(atsTestCharacteristics)
  }, [atsTestCharacteristics])

  useEffect(() => {
    if (equipmentCharacteristics?.length > 0) {
      setCharacteristicsList(equipmentCharacteristics)
    }
  }, [equipmentCharacteristics])

  useEffect(() => {
    if (equipmentId) {
      refetchCharacteristics?.()
    }
  }, [equipmentId])

  const initialValues = useMemo(() => {
    if (loadingCharacteristics || !characteristicsList) {
      return {}
    }

    return characteristicsList?.reduce(
      (acc, item) => ({
        ...acc,
        [item.targetKey]: {
          ...item,
          UOM,          
          // Add UoM symbol to each item to display on UI
          uomSymbol: item?.targetMeasureType === 'noUnit' ? ''
          : UOM === "SI" ? siUnitMapping.get(item?.targetMeasureType) ? uomSymbolPairs[siUnitMapping.get(item?.targetMeasureType)] : uomSymbolPairs[item?.unitOfMeasurement] : uomSymbolPairs[item?.unitOfMeasurement]
        }
      }),
      {}
    )
  }, [characteristicsList, loadingCharacteristics, uomSymbolPairs])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleUpdateProperty = async (valuesToSave) => {
    try {
      for (const object of valuesToSave) {
        await updateEquipmentProperty({
          input: {
            id: object?.propertyId,
            tisDisplayName: object?.characteristicDisplayName,
            value: object?.stateValue,
            creationType: 'User'
          }
        })
      }
    } catch (error) {}
  }
  const handleSaveDisable = (values) => {
    const valuesToSave = Object.entries(values)
      .filter(([key]) =>
        Object.keys(
          deepDiff(
            values,
            isBetaFeature ? initialValuesForChiller : initialValues
          )
        ).includes(key)
      )
      .reduce(
        (
          acc,
          [
            ,
            {
              characteristicDisplayName,
              dataType,
              possibleStatesList,
              propertyId
            }
          ]
        ) => {
          const defaultObj = {
            characteristicDisplayName,
            propertyId: propertyId
          }
          if (
            characteristicDisplayName === CHARACTERISTIC_TYPES.POSSIBLE_STATES
          ) {
            possibleStatesList.forEach(
              ({
                propertyStateName: stateName = '',
                propertyStateValue: stateValue = ''
              }) => {
                acc.push({
                  ...defaultObj,
                  stateName,
                  stateValue
                })
              }
            )
          } else {
            const {
              propertyStateName: stateName = '',
              propertyStateValue: stateValue = ''
            } =
              (dataType === DATA_TYPES.ENUMERATED ||
              possibleStatesList?.length > 1
                ? _find(possibleStatesList, 'selected')
                : possibleStatesList?.[0]) ?? {}
            acc.push({
              ...defaultObj,
              stateName,
              stateValue
            })
          }
          return acc
        },
        []
      )
    return valuesToSave.length ? true : false
  }

  const onSubmit = async (values) => {
    const valuesToSave = Object.entries(values)
      .filter(([key]) =>
        Object.keys(
          deepDiff(
            values,
            isBetaFeature ? initialValuesForChiller : initialValues
          )
        ).includes(key)
      )
      .reduce(
        (
          acc,
          [
            ,
            {
              characteristicDisplayName,
              dataType,
              possibleStatesList,
              propertyId
            }
          ]
        ) => {
          const defaultObj = {
            characteristicDisplayName,
            propertyId: propertyId
          }
          if (
            characteristicDisplayName === CHARACTERISTIC_TYPES.POSSIBLE_STATES
          ) {
            possibleStatesList.forEach(
              ({
                propertyStateName: stateName = '',
                propertyStateValue: stateValue = ''
              }) => {
                acc.push({
                  ...defaultObj,
                  stateName,
                  stateValue
                })
              }
            )
          } else {
            const {
              propertyStateName: stateName = '',
              propertyStateValue: stateValue = ''
            } =
              (dataType === DATA_TYPES.ENUMERATED ||
              possibleStatesList?.length > 1
                ? _find(possibleStatesList, 'selected')
                : possibleStatesList?.[0]) ?? {}
            acc.push({
              ...defaultObj,
              stateName,
              stateValue
            })
          }
          return acc
        },
        []
      )
    await handleUpdateProperty(valuesToSave)

    if (atsPage) {
      onSaveCharacteristicsATSChangesHandler?.()
    } else {
      await refetchCharacteristics?.(true)
    }
    setViewConfirmation(true)
  }

  //    Reset All to Defaults --->
  const resetTisEquipmentCharacteristics = async () => {
    if (atsPage) {
      await resetToDefaultsByProperties(onResetDataMapping, {
        equipmentId: characteristics_equipmentId,
        dataMappingEquipmentId: equipmentDetails?.dataMappingEquipmentId,
        deviceId: equipmentDetails?.deviceId,
        propertyType: 'Characteristics',
        propertyId: 'None',
        propertyIds: Object.values(initialValues || {}).reduce((av, char) => {
          if (char.targetKey && char.readOnly === false) av.push(char.targetKey)
          return av
        }, [])
      })

      onSaveCharacteristicsATSChangesHandler?.()

      return
    }

    const resetPropertyBody = {
      data: {
        equipmentId: characteristics_equipmentId,
        dataMappingEquipmentId: equipmentDetails?.dataMappingEquipmentId,
        deviceId: equipmentDetails?.deviceId,
        propertyId: 'None',
        propertyType: 'Characteristics'
      }
    }

    await onResetDataMapping({
      input: JSON.stringify(resetPropertyBody)
    })

    await refetchCharacteristics(true)
    refetchCharacteristicsPossibleState?.()
  }

  const translatedOptions = {
    confirmationText : translate('Changes made may take up to 30 minutes to be reflected in Command Center 4. Thank you for your patience.')
  }

  return loadingCharacteristics ? (
    <Spinner /> // TO-DO - !loadingCharacteristics && && !saving
  ) : (
    <>
      <div className="equipment-characteristics">
        {viewConfirmation && (
          <Modal
            testName={`${DETAILS_TEXT_NAME_PREFIX('characteristicsModal')}`}
            autoClose={5000}
            heading={"Confirmation"}
            handleClose={() => setViewConfirmation(false)}
          >
            <ModalText>{translatedOptions.confirmationText}</ModalText>
          </Modal>
        )}

        {loadingCharacteristics ? (
          <></>
        ) : !characteristicsList || !characteristicsList.length ? (
          <>
            {atsPage && (
              <StyledLabel className="ats-title"><TranslateComponent>Characteristics</TranslateComponent></StyledLabel>
            )}
            <Label
              className={atsPage ? 'ats-no-ch' : ''}
              text={t('equipment-setup:NoEditableCharacteristics')}
            />
          </>
        ) : (
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
            validate={(values) => {
              const errors = {}
              characteristicsList.forEach(({ targetKey }) => {
                const {
                  dataType,
                  resolution,
                  lowerRange,
                  upperRange,
                  possibleStatesList,
                  unitOfMeasurement,
                  targetMeasureType,
                  possibleStatesList: [{ propertyStateValue } = {}]
                } = values[targetKey]

                if (
                  dataType === DATA_TYPES.NUMBER &&
                  possibleStatesList.length <= 1
                ) {
                  if (propertyStateValue === null) {
                    return
                  }
                  if (isNaN(propertyStateValue)) {
                    errors[targetKey] = t('equipment-setup:ValueIsNotANumber')
                    return
                  }
                  const IsMinAndMaxZero = (lowerRange === 0 && upperRange === 0)
                  const convertedLowerRange = (UOM === "SI" && !IsMinAndMaxZero) ? parseFloat(convertUnits(lowerRange,unitOfMeasurement,siUnitMapping.get(targetMeasureType),targetMeasureType)).toFixed(2) : lowerRange
                  const covertedUpperRange = (UOM === "SI" && !IsMinAndMaxZero) ? parseFloat(convertUnits(upperRange,unitOfMeasurement,siUnitMapping.get(targetMeasureType),targetMeasureType)).toFixed(2) : upperRange
                  const convertedPropertyStateValue  = UOM === "SI" ? parseFloat(convertUnits(propertyStateValue,unitOfMeasurement,siUnitMapping.get(targetMeasureType),targetMeasureType)).toFixed(2) : propertyStateValue
                 if (
                    (convertedLowerRange && convertedLowerRange > Number(convertedPropertyStateValue)) ||
                    (covertedUpperRange && covertedUpperRange < Number(convertedPropertyStateValue))
                   ) {
                    errors[targetKey] = t(
                      'equipment-setup:EnterTheValueWithinTheRange',
                      {lower : convertedLowerRange, upper : covertedUpperRange}
                    )
                  } else if (
                    resolution &&
                    !isNumberResolutionCorrect(
                      propertyStateValue,
                      resolution?.toString()
                    )
                  ) {
                    errors[targetKey] = t(
                      'equipment-setup:ValueHasWrongResolution',
                      { resolution }
                    )
                  }
                }
              })
              return errors
            }}
            validateOnChange={true}
          >
            {({
              values,
              dirty,
              isValid,
              errors,
              setFieldValue,
              handleSubmit,
              resetForm
            }) => (
              <CharacteristicsForm
                testName={`${DETAILS_TEXT_NAME_PREFIX('characteristicsForm')}`}
                refetchBuildingTotalMeterEquipment={
                  refetchBuildingTotalMeterEquipment
                }
                equipmentDetails={equipmentDetails}
                values={values}
                dirty={dirty}
                errors={errors}
                isValid={handleSaveDisable(values)}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
                readOnly={readOnly}
                equipmentType={equipmentType}
                resetTisEquipmentCharacteristics={
                  resetTisEquipmentCharacteristics
                }
                atsPage={atsPage}
              />
            )}
          </Formik>
        )}
      </div>
    </>
  )
}

Characteristics.propTypes = {
  equipmentId: PropTypes.number,
  equipmentType: PropTypes.string,
  readOnly: PropTypes.bool
}

Characteristics.defaultProps = {
  readOnly: false
}

export default Characteristics
