import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import './search.scss'

const DELAY = 300

export default class Search extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onKeyPress: PropTypes.func,
    testName: PropTypes.string
  }

  static defaultProps = {
    value: '',
    placeholder: '',
    disabled: false
  }

  constructor(props) {
    super(props)
    // Copying value from prop to state
    // for some reason makes performance of component better
    // maybe needs to be investigated
    this.state = {
      value: this.props.value
    }
    this.sendTextChange = debounce(this.sendTextChange, DELAY)
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props

    if (prevProps.value !== value) {
      this.setState({ value })
    }
  }
  componentWillUnmount() {
    this.props.onUnMount && this.props.onUnMount()
  }

  clear = () => {
    const { onChange } = this.props
    this.setState({ value: '' })
    onChange('')
  }

  sendTextChange = (text) => {
    const { onChange } = this.props
    onChange(text)
  }

  onClear = () => {
    this.setState({ value: '' })
    this.props.onClear()
  }

  onChange = (e) => {
    this.setState({ value: e.target.value })
    this.sendTextChange(e.target.value)
  }

  render() {
    const { placeholder, disabled, onClear, onKeyPress, testName } = this.props
    const { value } = this.state
    const classes = {
      'not-empty': value,
      'search-box--disabled': disabled
    }
    return (
      <div className={classNames('search-box', classes)}>
        <input
          data-testid={`${testName || 'tc'}_input`}
          type="text"
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={this.onChange}
          onKeyPress={onKeyPress}
        />
        <div
          className="clear icon-close-circle"
          onClick={onClear ? this.onClear : this.clear}
        />
      </div>
    )
  }
}
