import TextInput from 'src/components/legacy/components/modal-text-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import { useFormik } from 'formik'
import { ChangeEvent } from 'react'
import { useMutation } from 'src/hooks/APIHooks'
import { ERROR_MESSAGES } from "./formValidator"
// components
import Modal from 'src/components/legacy/components/modal/modal'

import { ACTIONS } from 'src/constants'
import { useTranslation } from 'react-i18next'

import validationSchema from './formValidator'
import { CREATE_USERTYPE, UPDATE_USERTYPE } from '../graphql'
import styled from 'styled-components'
import CheckBox from 'src/denali-ui/components/Checkbox'
import uuid4 from "uuid4"


const ModalDivSpacer = styled.div`
`

const ACTION_HEADERS = {
  [ACTIONS.ADD]: 'Create User Type',
  [ACTIONS.EDIT]: 'Edit User Type',
  [ACTIONS.VIEW]: 'View User Type',
  [ACTIONS.COPY]: 'Copy User Type'
}

const PageDetail = ({
  mode,
  setMode,
  input,
  setInput,
  rows,
  initialValues,
  setReloadEvents
}) => {
  const [t] = useTranslation()
  const getModalHeading = () => {
    const heading =
      mode === ACTIONS.ADD || mode === ACTIONS.COPY
        ? ``
        : input?.name && `: ${input?.name}`
    return `${ACTION_HEADERS[mode]}${heading}`
  }

  const { onSubmit: createMutation } = useMutation({
    query: CREATE_USERTYPE,
    onSuccess: () => {
      closeDialog()
      setReloadEvents(true)
    }
  })

  const { onSubmit: updateMutation } = useMutation({
    query: UPDATE_USERTYPE,
    onSuccess: () => {
      closeDialog()
      setReloadEvents(true)
    }
  })
  const formik = useFormik({
    initialValues: input,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const {
        name,
        description,
        authUserTypes
      } = values
      const newId = uuid4()
      mode === ACTIONS.EDIT
        ? updateMutation({
            input: {
              id: input.id,
              userTypeId: input.id,
              name,
              description,
              authUserTypes
            }
          })
        : createMutation({
            input: {
              id: newId,
              userTypeId: newId,
              name,
              description,
              authUserTypes
            }
          })
    }
  })

  const { values, errors } = formik

  const getButtons = (submitForm, isFormValid = false) => {
    return mode === ACTIONS.VIEW
      ? [{ text: t('common:Close'), handleClick: closeDialog }]
      : [
          {
            text: t('common:Save'),
            type: 'submit',
            handleClick: submitForm,
            disabled: !isFormValid
          },
          {
            text: t('common:Cancel'),
            type: 'cancel',
            handleClick: closeDialog
          }
        ]
  }

  const closeDialog = () => {
    setMode(null)
    setInput(initialValues)
  }

  const modalConfig = {
    gray: true,
    className: 'wide-modal',
    heading: getModalHeading(),
    buttons: getButtons(formik.handleSubmit, formik.isValid),
    handleClose: closeDialog
  }

  const handleInputChange = (name: string, value: any) => {
    formik.setFieldValue(name, value)
  }

  return (
    <Modal {...modalConfig}>
      <ModalDivSpacer>
        <div>
          <form onSubmit={formik.handleSubmit} noValidate>
            <TextInput
              labelText="User Type Name"
              defaultValue={values?.name}
              name="name"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(name, value)
              }
              hasError={errors?.['name']}
              errorMessage={ERROR_MESSAGES["name"]}
            />
            <TextAreaInput
              labelText="User Type Description"
              defaultValue={values?.description}
              onChange={({
                target: { value }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange('description', value)
              }
              name="description"
              isRequired={false}
            />
            Authorized User Types
            {
              rows.map(r => <div key={r.id}>
                  <CheckBox
                    onClick={() => {
                      const index = values.authUserTypes.findIndex(e => e === r.id)
                      if (index === -1) {
                        handleInputChange('authUserTypes', [...values.authUserTypes, ...[r.id]])
                      } else {
                        const newAuthTypes = [...values.authUserTypes]
                        newAuthTypes.splice(index, 1)
                        handleInputChange('authUserTypes', newAuthTypes)
                      }
                    }}
                  checked={values.authUserTypes.findIndex(e => e === r.id) !== -1}
                  label={r.name || r.id}
                  />
              </div>
            )}
          </form>
        </div>
      </ModalDivSpacer>
    </Modal>
  )
}
export default PageDetail
