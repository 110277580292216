import PropTypes from 'prop-types'

export const LabelBlank = ({ labelText, value, className, onClick, testName='' }) => (
  <div className={className} data-testid={`${testName} text`}>
    <label>{labelText}</label>
    <span onClick={onClick}> {value}</span>
  </div>
)

LabelBlank.propTypes = {
  labelText: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default LabelBlank
