import { API } from 'aws-amplify'
import { useState } from 'react'
import { GET_BUILDING_BY_ID_func } from '../../../graphql'
import { NOTIFICATION_SUB_TYPE } from '../constants'
import { isValidNotificationType } from '../helpers'

export function useBuildingDetails() {
  const [uniqueBuildingIds, setUniqueBuildingIds] = useState({})

  const newBuildingIds = []

  // find New building ids
  // ====================================================================================================
  function findNewBuildingIdsForDataLoss(notification: any) {
    const isValid = isValidNotificationType(notification, [
      NOTIFICATION_SUB_TYPE.DATA_LOSS
    ])

    if (!isValid) return

    const buildingId = notification.buildingId

    const isExisting = uniqueBuildingIds[buildingId] !== undefined

    // Exit if id exists
    if (isExisting && buildingId) return

    uniqueBuildingIds[buildingId] = true

    newBuildingIds.push(buildingId)
  }

  // Api to get new buildings details
  // ====================================================================================================
  async function apiGetBuildingByIds() {
    let data = null

    if (newBuildingIds.length) {
      try {
        const response = (await API.graphql({
          query: GET_BUILDING_BY_ID_func(newBuildingIds)
        })) as any

        data = response?.data || {}
      } catch (error) {
        console.log('API Rejection error', error)
      }
    }

    // Resolve the function regardless of success or failure of request
    return await Promise.resolve({
      requestFrom: 'BUILDING_DATA',
      data
    })
  }

  // Update the response with new properties
  // ====================================================================================================
  function addBuildingNamePropertyToNotificationsForDataLoss(
    notifications = [],
    buildingNames = {}
  ) {
    if (!buildingNames || Object.keys(buildingNames).length === 0) {
      return notifications
    }

    const buildings = Object.values(buildingNames).reduce((acc, val: any) => {
      if(val?.id){
      acc[val.id] = val.name
      }
      return acc
    }, {})

    return notifications.map((notification) => ({
      ...notification,
      buildingName: buildings[notification.buildingId] || ''
    }))
  }

  // Reset interal state
  // ====================================================================================================
  function updateState(reset: boolean) {
    const data = newBuildingIds.slice()

    // Reset or update stored building list
    setUniqueBuildingIds((buildingIds) => ({
      ...(!reset ? buildingIds : {}),
      ...data
    }))

    newBuildingIds.length = 0
  }

  return {
    findNewBuildingIdsForDataLoss,
    apiGetBuildingByIds,
    addBuildingNamePropertyToNotificationsForDataLoss,
    updateState
  }
}
