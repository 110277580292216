const configs = {
  DATE_DISPLAY_FORMAT: 'MM/DD/YYYY',
  DATETIME_DISPLAY_FORMAT: 'M/D/YYYY h:mm A',
  DATE_PARSE_FORMAT: 'M/D/YYYY',
  DATE_DATABASE_FORMAT: 'YYYY-MM-DD',
  ISO8601_DAY_FLOOR: 'YYYY-MM-DDT00:00:00.000',
  ISO8601_DAY_CEIL: 'YYYY-MM-DDT23:59:59.999',
  ISO8601_CURRENT_TIME_NO_TZ: 'YYYY-MM-DDTHH:mm:ss.SSS',
  pikadayConfig: {
    previousMonth: 'Previous',
    nextMonth: 'Next',
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    weekdays: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ],
    weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  },
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
}

export default configs
