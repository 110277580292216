import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment-timezone'
import DatePickerWeek from './datepicker-week'

class DatePickerCalendarView extends PureComponent {
  static propTypes = {
    displayBase: PropTypes.instanceOf(moment),
    initialRange: PropTypes.shape({
      start: PropTypes.instanceOf(moment),
      end: PropTypes.instanceOf(moment)
    }),
    timezone: PropTypes.string,
    handleRangeChange: PropTypes.func,
    changeBaseDate: PropTypes.func,
    rangeSelection: PropTypes.shape({
      past: PropTypes.bool,
      future: PropTypes.bool
    }),
    toggleCalendar: PropTypes.func.isRequired,
    shouldShow: PropTypes.bool,
    disableBeforeGivenDate: PropTypes.object,
    disableAfterGivenDate: PropTypes.object
  }

  static defaultProps = {
    timezone: 'UTC',
    displayBase: moment.tz('UTC'),
    handleRangeChange: () => {},
    rangeSelection: {
      past: true,
      future: true
    },
    toggleCalendar: () => {}
  }

  state = {
    selectedRange: {
      start:
        this.props.initialRange && this.props.initialRange.start
          ? this.props.initialRange.start.clone()
          : moment.tz(this.props.timezone).startOf('day'),
      end:
        this.props.initialRange && this.props.initialRange.end
          ? this.props.initialRange.end.clone()
          : moment.tz(this.props.timezone).endOf('day')
    }
  }

  componentDidUpdate = (prevProps) => {
    const { initialRange = {} } = prevProps
    const { initialRange: nextRange = {} } = this.props

    if (
      (!initialRange.start && nextRange.start) ||
      (initialRange.start &&
        nextRange.start &&
        !initialRange.start.isSame(nextRange.start)) ||
      (!initialRange.end && nextRange.end) ||
      (initialRange.end &&
        nextRange.end &&
        !initialRange.end.isSame(nextRange.end))
    ) {
      this.setState({ selectedRange: nextRange })
      this.props.changeBaseDate(nextRange.start)
    }
  }

  /**
   * buildCalendar - Returns array of weeks, containing array of days in week, based on current range
   * @returns {Array}
   */
  buildCalendar = () => {
    const { displayBase } = this.props
    const calendar = []
    const startDay = displayBase.clone().startOf('month').startOf('week')
    const endDay = displayBase.clone().endOf('month').endOf('week')
    const date = startDay.clone().subtract(1, 'day')

    while (date.isBefore(endDay, 'day')) {
      calendar.push(
        Array(7)
          .fill(0)
          .map(() => date.add(1, 'day').clone())
      )
    }

    return calendar
  }

  /**
   * handleDateClick - The first click should set the start and clear the end,
   * 																		the second click should set the end of the range
   *
   * @param {String} date Stringified date being selected
   */
  handleDateClick = (date) => {
    const today = moment.tz(this.props.timezone).startOf('day')
    const dateClickedMoment = moment.tz(date, this.props.timezone)
    const newRangeState = { ...this.state.selectedRange }

    // If an end has been set, we will clear that, because we are setting a new range
    if (newRangeState.end) {
      newRangeState.end = null
      newRangeState.start = dateClickedMoment.clone().startOf('day')

      // If range selection is disabled for past or future dates, we will set the range end to the end of the selected day
      if (
        (!this.props.rangeSelection.future &&
          (dateClickedMoment.isAfter(today, 'day') ||
            dateClickedMoment.isSame(today, 'day'))) ||
        (!this.props.rangeSelection.past &&
          (dateClickedMoment.isBefore(today, 'day') ||
            dateClickedMoment.isSame(today, 'day')))
      ) {
        newRangeState.end = dateClickedMoment.clone().endOf('day')
        this.props.handleRangeChange(
          newRangeState.start.valueOf(),
          newRangeState.end.valueOf()
        )
        this.props.toggleCalendar()
      }

      this.setState({ selectedRange: newRangeState })
      // Otherwise, we have a start and are setting the end of the new range, but we should not allow the range to pass today
      // if the rangeSelection option for future dates is disabled
    } else if (
      (dateClickedMoment.isAfter(newRangeState.start, 'day') ||
        dateClickedMoment.isSame(newRangeState.start, 'day')) &&
      (this.props.rangeSelection.future ||
        (!this.props.rangeSelection.future &&
          dateClickedMoment.isBefore(today, 'day')))
    ) {
      newRangeState.end = dateClickedMoment.clone().endOf('day')

      // Update range with function from props, because we have a new, full range.
      this.props.handleRangeChange(
        newRangeState.start.valueOf(),
        newRangeState.end.valueOf()
      )
      this.setState({ selectedRange: newRangeState })
      this.props.toggleCalendar()
    }
  }

  /**
   * handleBaseDateChange - Changes the base display date of the DatePicker component
   *
   * @param {Event} e JavaScript click event
   */
  handleBaseDateChange = (e) => {
    const direction = e.target.getAttribute('name')
    const delta = direction === 'prev' ? -1 : 1

    this.props.changeBaseDate(
      this.props.displayBase.clone().add(delta, 'months').startOf('day')
    )
  }

  handleChangeView = () => this.props.changeView('month')

  render = () => {
    const { selectedRange } = this.state
    const {
      displayBase,
      rangeSelection,
      shouldShow,
      timezone,
      disablePast,
      disableBeforeGivenDate,
      disableAfterGivenDate
    } = this.props
    const weeks = this.buildCalendar()
    const today = moment.tz(timezone).format('YYYY-MM-DD')

    return (
      <div
        className={classNames('calendar-view-container', {
          'is-active': shouldShow
        })}
      >
        <div className="heading-box">
          <span
            name="prev"
            className="icon icon-left-caret range-changer range-previous"
            onClick={this.handleBaseDateChange}
          />
          <span
            className="datepicker-view-selector"
            onClick={this.handleChangeView}
          >
            {displayBase.format('MMMM YYYY')}
          </span>
          <span
            name="next"
            className="icon icon-right-caret range-changer range-next"
            onClick={this.handleBaseDateChange}
          />
        </div>
        <div className="bounding-box">
          <table>
            <thead>
              <tr className="range-table-header">
                {weeks.length > 0 &&
                  weeks[0].map((day) => (
                    <th key={`head-${day.format('dd')}`}>{day.format('dd')}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr className="datepicker-spacer-row" />
              {shouldShow &&
                weeks.length > 0 &&
                weeks.map((week) => (
                  <DatePickerWeek
                    key={`week-${week[0].format('YYYY-MM-DD')}`}
                    days={week}
                    today={today}
                    displayBase={displayBase}
                    range={selectedRange}
                    rangeSelection={rangeSelection}
                    handleDateClick={this.handleDateClick}
                    disablePast={disablePast}
                    disableBeforeGivenDate={disableBeforeGivenDate}
                    disableAfterGivenDate={disableAfterGivenDate}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default DatePickerCalendarView
