import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment-timezone'
import DatePickerSelectorRow from './datepicker-selector-row'
import {
  DATE_FORMAT_DASH,
  BACKEND_DATE_FORMAT
} from '../../common/time-helpers'

class DatePickerSelector extends PureComponent {
  static propTypes = {
    changeBaseDate: PropTypes.func,
    view: PropTypes.oneOf(['month', 'year', 'day']),
    shouldShow: PropTypes.bool,
    selectMonth: PropTypes.bool,
    inputDate: PropTypes.string,
    dateType: PropTypes.string
  }

  static defaultProps = {
    dateType: ''
  }

  static YEAR_PADDING = {
    BEFORE: 6,
    AFTER: 5
  }

  /**
   * getMonths - Returns array of months in current base year, separated by quarter
   * @returns {Array}
   */
  getMonths = () => {
    const { displayBase } = this.props
    const calendar = [[], [], [], []]
    const date = displayBase
      .clone()
      .startOf('year')
      .subtract(1, 'month')
      .startOf('month')

    while (date.month() < 11 || !calendar[0].length) {
      const newDate = date.add(1, 'month').clone().startOf('month')
      const quarterIndex = newDate.quarter() - 1
      calendar[quarterIndex].push(newDate)
    }

    return calendar
  }

  /**
   * getYears - Returns array of years surrounding current base year
   * @returns {Array}
   */
  getYears = () => {
    const { displayBase } = this.props
    const calendar = [[], [], [], []]
    const date = displayBase
      .clone()
      .startOf('year')
      .subtract(DatePickerSelector.YEAR_PADDING.BEFORE, 'years')
    const targetDate = displayBase
      .clone()
      .startOf('year')
      .add(DatePickerSelector.YEAR_PADDING.AFTER + 1, 'years')
    let currentRowIndex = 0

    while (date.isBefore(targetDate)) {
      calendar[currentRowIndex].push(
        date.add(1, 'year').startOf('year').clone()
      )

      if (calendar[currentRowIndex].length === 3) {
        ++currentRowIndex
      }
    }

    return calendar
  }

  getMonth = (month, year, inputDate, dateType) => {
    const selectedMonth = month + 1
    let selectedDate = moment(
      `${year}/${selectedMonth}/01}`,
      BACKEND_DATE_FORMAT
    )
    selectedDate =
      inputDate === 'startDate' || (dateType && dateType === 'startDate')
        ? moment(selectedDate).startOf('month').format(BACKEND_DATE_FORMAT)
        : moment(selectedDate).endOf('month').format(BACKEND_DATE_FORMAT)
    if (document.getElementById(`${inputDate}`)) {
      document.getElementById(`${inputDate}`).value =
        moment(selectedDate).format(DATE_FORMAT_DASH)
    }
    this.props.changeBaseDate(moment.utc(selectedDate))
    this.props.update(selectedDate)
    this.props.toggleCalendar()
  }

  handleChangeView = () => this.props.changeView('year')

  handleSelection = (selectedDate) => {
    const { selectMonth, inputDate, dateType } = this.props
    if (this.props.view === 'year') {
      this.props.changeView(
        'month',
        this.props.displayBase.clone().year(selectedDate.year())
      )
    } else if (this.props.view === 'month') {
      if (selectMonth) {
        this.getMonth(
          selectedDate.month(),
          selectedDate.year(),
          inputDate,
          dateType
        )
      } else {
        this.props.changeView(
          'day',
          this.props.displayBase.clone().month(selectedDate.month())
        )
      }
    }
  }

  /**
   * handleBaseDateChange - Changes the base display date of the DatePicker component
   *
   * @param {Event} e JavaScript click event
   */
  handleBaseDateChange = (e) => {
    const direction = e.target.getAttribute('name')
    const delta =
      direction === 'prev'
        ? this.props.view === 'year'
          ? -10
          : -1
        : this.props.view === 'year'
        ? 10
        : 1

    this.props.changeBaseDate(
      this.props.displayBase.clone().add(delta, 'years').startOf('day')
    )
  }

  render = () => {
    const { displayBase, shouldShow, view } = this.props
    const today = moment.tz(displayBase.tz())
    const rows = view === 'month' ? this.getMonths() : this.getYears()

    return (
      <div
        className={classNames('datepicker-selector', {
          'is-active': shouldShow
        })}
      >
        <div className="heading-box">
          <span
            name="prev"
            className="icon icon-left-caret range-changer range-previous"
            onClick={this.handleBaseDateChange}
          />
          <span
            className={`datepicker-view-selector ${view}-view`}
            onClick={this.handleChangeView}
          >
            {view === 'month'
              ? displayBase.format('YYYY')
              : `${rows[0][0].format('YYYY')} - ${rows[3][2].format('YYYY')}`}
          </span>
          <span
            name="next"
            className="icon icon-right-caret range-changer range-next"
            onClick={this.handleBaseDateChange}
          />
        </div>
        <div className="bounding-box">
          <table>
            <tbody>
              <tr className="datepicker-spacer-row" />
              {shouldShow &&
                rows.map((cols) => (
                  <DatePickerSelectorRow
                    key={`row-${cols[0].format('MM-DD-YYYY')}`}
                    columns={cols}
                    today={today}
                    displayBase={displayBase}
                    view={view}
                    handleSelection={this.handleSelection}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default DatePickerSelector
