
export const DEFAULT_SORT = {
  field: 'none',
  ascending: true
}

export const STATUS = new Map([
  ['inProgress', 'In Progress'],
  ['collectingData', 'Collecting Data'],
  ['readyForReview', 'Ready for Review'],
  ['archive', 'Archive'],
  ['complete', 'Complete'],
  ['draft', 'Draft']
])

export const STATUS_SORT_ORDER = new Map([
  ['inProgress', 0],
  ['collectingData', 1],
  ['readyForReview', 2],
  ['archive', 3]
])

export const STATUS_OPTION_LIST = Array.from(STATUS, ([key, value]) => ({
  key,
  value
}))
export const STATUS_OPTION_FILETER_LIST = Array.from(STATUS, ([, value]) => ({
  name: value,
  value
}))

export function statusOptionList(translateFunc) {
  return STATUS_OPTION_LIST.map(({ key, value }) => ({
    key,
    value: translateFunc ? translateFunc(`reports:statuses>${key}`) : value
  }))
}

export function formatStatus(id) {
  return STATUS.get(id)
}

export const TYPES = new Map([
  ['IntelligentServicesConsultation', 'Intelligent Services Consultation'],
  ['VRFReport', 'VRF Report'],
  ['BuildingHealthReport', 'Building Health Report'],
  ['EnergyUsageReport', 'Energy Usage Report'],
  ['Document', 'Document']
])

export function formatTypes(id) {
  return TYPES.get(id)
}

export const TYPE_OPTION_LIST = Array.from(TYPES, ([key, value]) => ({
  key,
  value
}))
export const REPORT_FILTER_TYPE_LIST = Array.from(TYPES, ([, value]) => ({
  name: value,
  value
}))

export function typeOptionList(translateFunc) {
  return TYPE_OPTION_LIST.map(({ key, value }) => ({
    key,
    value: translateFunc ? translateFunc(`reports:types>${key}`) : value
  }))
}

export const TRACK_CHANGED_FIELDS = new Map([
  ['contact', 'Contact'],
  ['email', 'Email'],
  ['endDate', 'End Date'],
  ['startDate', 'Start Date'],
  ['status', 'Status'],
  ['title', 'Title'],
  ['phone', 'Phone'],
  ['settings.summaryTitle', 'Summary Title'],
  ['settings.summary', 'Summary'],
  ['settings.highlight', 'Highlights'],
  ['settings.diagnostic', 'Diagnostics'],
  ['visibleToCustomer', 'Visible To Customer']
])

export const TRACK_CHANGED_FIELDS_LIST = [...TRACK_CHANGED_FIELDS.keys()]

export function formatTrackChangedFields(id) {
  return TRACK_CHANGED_FIELDS.get(id) || id
}
