import React, { memo } from 'react'
import PropTypes from 'prop-types'

/** ui-system */
import spacing from '../../ui-system/space'
import typography from '../../ui-system/typography'
import color from '../../ui-system/color'
import layout from '../../ui-system/layout'
import border from '../../ui-system/border'
import flexbox from '../../ui-system/flexbox'
import background from '../../ui-system/background'

import { shouldForwardProp } from '../../utils'

const BoxRoot = shouldForwardProp('div')`
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${spacing}
  ${typography}
`
const Box = React.forwardRef(function Box(inProps, ref) {
  return <BoxRoot ref={ref} {...inProps} />
})

Box.propTypes = {
  /**
   * To change background color of Box
   */
  background: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change background image of Box
   */
  backgroundImage: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change background size of Box
   */
  backgroundSize: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change background position of Box
   */
  backgroundPosition: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change background repeat of Box
   */
  backgroundRepeat: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Apply border color to Box
   */
  border: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border width of Box
   */
  borderWidth: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Apply border style to Box
   */
  borderStyle: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Apply border color to Box
   */
  borderColor: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border radius of Box
   */
  borderRadius: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border top of Box
   */
  borderTop: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border top width  of Box
   */
  borderTopWidth: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border top style of Box
   */
  borderTopStyle: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border top color of Box
   */
  borderTopColor: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border top-left radius of Box
   */
  borderTopLeftRadius: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border top-right radius of Box
   */
  borderTopRightRadius: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border right of Box
   */
  borderRight: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border-right width of Box
   */
  borderRightWidth: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border right style of Box
   */
  borderRightStyle: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border right color of Box
   */
  borderRightColor: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border bottom of Box
   */
  borderBottom: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border bottom width of Box
   */
  borderBottomWidth: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border bottom style of Box
   */
  borderBottomStyle: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border bottom color of Box
   */
  borderBottomColor: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border bottom-left radius of Box
   */
  borderBottomLeftRadius: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border bottom-right radius of Box
   */
  borderBottomRightRadius: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border left of Box
   */
  borderLeft: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border left width of Box
   */
  borderLeftWidth: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border left style of Box
   */
  borderLeftStyle: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border left color of Box
   */
  borderLeftColor: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border-left && border-right of Box
   */
  borderX: PropTypes.oneOfType([PropTypes.any]),
  /**
   * To change border-left && border-right of Box
   */
  borderY: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Align Items of Box
   */
  alignItems: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Align Items of Box
   */
  alignContent: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Justify the Items of Box
   */
  justifyItems: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Justify the content of Box
   */
  justifyContent: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Flex wrap the content of Box
   */
  flexWrap: PropTypes.oneOfType([PropTypes.any]),
  /**
   * Flex direction of Box
   */
  flexDirection: PropTypes.oneOfType([PropTypes.any]),
  /**
   * flex will be  Box
   */
  flex: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Defines the ability for a flex item to grow
   */
  flexGrow: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Shrink the flex item
   */
  flexShrink: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Sets the initial main size of a flex item
   */
  flexBasis: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Sets  the box justified inside the  alignment container
   */
  justifySelf: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  To Overrides a grid or flex items align item
   */
  alignSelf: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Set the order to lay out an item in a flex or grid container
   */
  order: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Sets width of the box
   */
  width: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Sets height of the box
   */
  height: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Decide the element is treated as a block or inline element
   */
  display: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Sets min width of the box
   */
  minWidth: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Sets min height of the box
   */
  minHeight: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Sets max width of the box
   */
  maxWidth: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Sets max height of the box
   */
  maxHeight: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Defines the size and orientation of the box
   */
  size: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Sets vertical alignment of an inline, inline-block or table-cell box
   */
  verticalAlign: PropTypes.oneOfType([PropTypes.any]),
  /**
   *   Desired behavior for an element's overflow
   */
  overflow: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Sets what shows when content overflows a block-level element's left and right edges
   */
  overflowX: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Sets what shows when content overflows a block-level element's top and bottom edges
   */
  overflowY: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Sets margin of box
   */
  margin: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Sets margin top of box
   */
  marginTop: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Sets margin right of box
   */
  marginRight: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Sets margin bottom of box
   */
  marginBottom: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Sets margin left of box
   */
  marginLeft: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Sets margin left-right of box
   */
  marginX: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Sets margin top-bottom of box
   */
  marginY: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set margin of box
   */
  m: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set margin top of box
   */
  mt: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set margin right of box
   */
  mr: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set margin bottom of box
   */
  mb: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set margin left of box
   */
  ml: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set margin left-right of box
   */
  mx: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set margin top-bottom of box
   */
  my: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Set padding of box
   */
  padding: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Set padding top of box
   */
  paddingTop: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Set padding right of box
   */
  paddingRight: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Set padding bottom of box
   */
  paddingBottom: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Set padding left of box
   */
  paddingLeft: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Set padding left-right of box
   */
  paddingX: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Set padding top-bottom of box
   */
  paddingY: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set padding of box
   */
  p: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set padding top of box
   */
  pt: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set padding right of box
   */
  pr: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set padding bottom of box
   */
  pb: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set padding left of box
   */
  pl: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set padding left-right of box
   */
  px: PropTypes.oneOfType([PropTypes.any]),
  /**
   *  Shothand prop to set padding top-bottom of box
   */
  py: PropTypes.oneOfType([PropTypes.any]),
  textAlign: PropTypes.oneOf([
    'left',
    'right',
    'center',
    'justify',
    'initial',
    'inherit',
    'Responsive object'
  ]),
  fontFamily: PropTypes.oneOfType([
    PropTypes.oneOf(['primary', 'secondary', 'Responsive object']),
    PropTypes.string
  ]),
  fontSize: PropTypes.oneOfType([PropTypes.any]),
  fontStyle: PropTypes.oneOfType([PropTypes.string]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  letterSpacing: PropTypes.oneOfType([PropTypes.string]),
  noMargin: PropTypes.bool,
  noWrap: PropTypes.bool
}

export default memo(Box)
