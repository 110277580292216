import { createGlobalStyle } from 'styled-components'
import '../../styles/common/font-style.css'

// TODO : REMOVE

// This file can be removed & should move logics to ThemeProvider compoment
// if no more styles in global styles in future

const GlobalStyle = createGlobalStyle` 
  body {
    position: relative;
  }
`
export default GlobalStyle
