import React, { memo, useState } from 'react'
import { isBoolean, shouldForwardProp } from '../../utils'
import { FormControlContext } from './formControlContext'
import { typographyFontFamily } from '../../ui-system/typography'

const FormControlRoot = shouldForwardProp('div', ['fontFamily'])`
  ${typographyFontFamily}
  font-size: 1rem;
  border: 0;
  display: inline-flex;
  flex-direction: column;
  margin: 0;
  min-width: 0;
  padding: 0;
  position: relative;
  vertical-align: top;
  width:  ${({ rootStyleProps }) =>
    rootStyleProps.fullWidth ? '100%' : '250px'};
  .input-section {
    align-items: stretch;
    display: flex;
    flex:1;
  }
`

const FromControl = React.forwardRef(function FormControl(inProps, ref) {
  const {
    children,
    className,
    as = 'div',
    disabled = false,
    error = false,
    focused: visuallyFocused,
    fullWidth = false,
    required = false,
    variant = 'outlined',
    onBlur = () => null,
    onFocus = () => null,
    ...inputProps
  } = inProps

  const [focusedState, setFocused] = useState(false)

  const focused =
    isBoolean(visuallyFocused) && !disabled ? visuallyFocused : focusedState

  const rootStyleProps = {
    disabled,
    error,
    fullWidth,
    noStyle: false,
    variant
  }

  const formFieldContext = {
    ...inputProps,
    disabled,
    error,
    focused,
    onBlur: (e) => {
      setFocused(false)
      onBlur(e)
    },

    onFocus: (e) => {
      setFocused(true)
      onFocus(e)
    },
    required
  }

  return (
    <FormControlContext.Provider value={formFieldContext}>
      <FormControlRoot
        as={as}
        fontFamily="primary"
        rootStyleProps={rootStyleProps}
        className={className}
        ref={ref}
      >
        {children}
      </FormControlRoot>
    </FormControlContext.Provider>
  )
})

export default memo(FromControl)
