import { API } from 'aws-amplify'
import moment from 'moment-timezone'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import { booleanToString, stringToBoolean } from 'src/utils/commonMethods'

export const mutation = async (params) => {
  try {
    const apiData = await API.graphql(params)
    return apiData
  } catch (error) {
    return error
  }
}

export const CELSIUS = 'Celsius'

export const getStartAndEndDates = (
  type,
  previousTimePeriod,
  requiredFormat,
  tZone
) => {
  const timeZone = tZone || moment.tz.guess()
  let currentWeekDay = moment().weekday()
  switch (type) {
    case 'current-day':
    case '1-day':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(1, 'day')
            .startOf('day')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(1, 'day')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .startOf('day')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .startOf('day')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'previous-day':
    case 'compare-day':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'day')
          .startOf('day')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'day')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'current-week':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(1, 'week')
            .startOf('isoWeek')  //this isoWeek returns the first day of this week according to ISO 8601. The first day of the week according to the international standard ISO 8601 is Monday. So we don't need that add('days')
            //.add(1, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(1, 'week')
            .endOf('isoWeek')
            //.add(1, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .startOf('isoWeek')
            //.add(1, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'previous-week':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'week')
          .startOf('week')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'week')
          .endOf('week')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'current-month':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(1, 'months')
            .startOf('month')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(1, 'months')
            .endOf('month')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .startOf('month')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'previous-month':
    case 'compare-month':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'months')
          .startOf('month')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'months')
          .endOf('month')

          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'current-quarter':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(1, 'quarter')
            .startOf('quarter')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(1, 'quarter')
            .endOf('quarter')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .startOf('quarter')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'previous-quarter':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'quarter')
          .startOf('quarter')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'quarter')
          .endOf('quarter')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'current-year':
    case 'compare-year':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(1, 'year')
            .startOf('year')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(1, 'year')
            .endOf('year')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .startOf('year')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-hour':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(1, 'hours')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(1, 'hours')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-4-hours':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(4, 'hours')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'past-12-hours':
      return {
        startDate: moment()
          .tz(timeZone)
          .startOf('hour')
          .subtract(12, 'hours')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .startOf('hour')
          .subtract(1, 'hours')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'past-24-hours':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .startOf('hour')
            .subtract(47, 'hours')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .startOf('hour')
            .subtract(23, 'hours')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .startOf('hour')
            .subtract(24, 'hours')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .startOf('hour')
            .subtract(1, 'hours')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-3-days':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(6, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(3, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(2, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-7-days':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(14, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(7, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(6, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-14-days':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(28, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(14, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(13, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-30-days':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(60, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(30, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(29, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-90-days':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(180, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(90, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(89, 'days')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-12-months':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(24, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(12, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(12, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(1, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-3-months':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(6, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(3, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .startOf('month')
            .subtract(2, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .endOf('month')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-6-months':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(12, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(6, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .startOf('month')
            .subtract(5, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .endOf('month')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'past-11-months':
      if (previousTimePeriod) {
        return {
          startDate: moment()
            .tz(timeZone)
            .subtract(22, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .subtract(11, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      } else {
        return {
          startDate: moment()
            .tz(timeZone)
            .startOf('month')
            .subtract(12, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
          endDate: moment()
            .tz(timeZone)
            .endOf('month')
            .subtract(1, 'months')
            .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
        }
      }
    case 'same-day-previous-week':
      currentWeekDay = moment().weekday()
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'weeks')
          .startOf('week')
          .add(currentWeekDay, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'weeks')
          .startOf('week')
          .add(currentWeekDay, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-previous-month':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'months')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'months')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-previous-year': {
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'year')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'year')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    }
    case 'same-day-previous-week-3':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(9, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'weeks')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-previous-month-3':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'months')
          .subtract(2, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'months')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-previous-year-3':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'years')
          .subtract(2, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'years')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-previous-week-7':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'weeks')
          .subtract(6, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'weeks')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-previous-month-7':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'months')
          .subtract(6, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'months')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-previous-year-7':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'years')
          .subtract(6, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'years')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-compare-week':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(7, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(7, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-compare-month':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'months')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'months')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-compare-year':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'year')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'year')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-compare-week-3':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(10, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(7, 'year')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-day-compare-week-3':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(10, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(7, 'year')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-month-previous-year':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'year')
          .startOf('month')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'year')
          .endOf('month')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }

    case 'past-3-months-year-compared':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(14, 'months')
          .startOf('month')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'year')
          .endOf('month')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'past-6-months-year-compared':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(17, 'months')
          .startOf('month')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'year')
          .endOf('month')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'previous-previous-quarter':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(2, 'quarter')
          .startOf('quarter')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(2, 'quarter')
          .endOf('quarter')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'same-quarter-previous-year':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(5, 'quarter')
          .startOf('quarter')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(5, 'quarter')
          .endOf('quarter')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'previous-year':
    case 'previous-year-full':
      return {
        startDate: moment()
          .tz(timeZone)
          .subtract(1, 'year')
          .startOf('year')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment()
          .tz(timeZone)
          .subtract(1, 'year')
          .endOf('year')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    default:
      return {
        startDate: moment().tz(timeZone).format(BACKEND_DATE_FORMAT),
        endDate: moment().tz(timeZone).format(BACKEND_DATE_FORMAT)
      }
  }
}

const getUserName = (userInfo) => {
  return `${userInfo?.lastName} ${userInfo?.firstName}`
}

export const frameWidgetsInput = (data, userInfo, values) => {
  const inputs = data?.map((item, index) => {
    if (index === data.length - 1) {
      return {
        name: item.widget?.name,
        description: '',
        widgetLink: item.widget?.widgetLink,
        widgetType: item.widget?.widgetType,
        isNewWindow: true,
        widgetBuildingId: values?.building,
        weatherWidget: {
          metricConversion: CELSIUS
        },
        widgetTargetType: 'location',
        creationDate: moment().format(BACKEND_DATE_FORMAT),
        createdBy: getUserName(userInfo),
        userId: userInfo?.id,
        accountId: values?.organization,
        buildingId: values?.building,
        salesOfficeId: values?.salesOfficeId
      }
    } else {
      return {
        name: item.widget?.name,
        description: '',
        widgetLink: item.widget?.widgetLink,
        widgetType: item.widget?.widgetType,
        isNewWindow: true,
        widgetBuildingId: values?.building,
        widgeEquipment1Id: null,
        widgetTargetType: 'equipment',
        energyConsumptionWidget: {
          colourConfig: item.widget?.energyConsumptionWidget?.colourConfig,
          benchMark: item.widget?.energyConsumptionWidget?.benchMark,
          uom: item.widget?.energyConsumptionWidget?.uom,
          showTemperature:
            item.widget?.energyConsumptionWidget?.showTemperature === 'FALSE'
              ? false
              : true,
          timePeriod: item.widget?.energyConsumptionWidget?.timePeriod,
          comparedTo: item.widget?.energyConsumptionWidget?.comparedTo
        },
        creationDate: moment().format(BACKEND_DATE_FORMAT),
        createdBy: getUserName(userInfo),
        userId: userInfo?.id,
        accountId: values?.organization,
        buildingId: values?.building,
        salesOfficeId: values?.salesOfficeId
      }
    }
  })
  return inputs
}

export const frameInputForKeyMetricUpdate = (values, data) => {
  if (!stringToBoolean(values?.isKeyMetric)) return data

  const keyMetricsStripList = values?.keyMetricsStripList || []

  const keyMetric1 = keyMetricsStripList[0]
  const keyMetric2 = keyMetricsStripList[1]
  const keyMetric3 = keyMetricsStripList[2]
  const keyMetric4 = keyMetricsStripList[3]
  const keyMetric5 = keyMetricsStripList[4]

  const input = {
    ...data,
    isKeyMetric: values?.isKeyMetric,
    keyMetricaccountId: values?.accountId,
    keyMetricbuildingId: values?.buildingId,
    keyMetricComparedTo: values?.keyMetricComparedTo,
    keyMetricTimePeriod: values?.keyMetricTimePeriod,
    keyMetric1Display: keyMetric1?.isEnabled,
    keyMetric1Name: keyMetric1?.keyMetric,
    keyMetric1UoM: keyMetric1?.uom,
    keyMetric2Display: keyMetric2?.isEnabled,
    keyMetric2Name: keyMetric2?.keyMetric,
    keyMetric2UoM: keyMetric2?.uom,
    keyMetric3Display: keyMetric3?.isEnabled,
    keyMetric3Name: keyMetric3?.keyMetric,
    keyMetric3UoM: keyMetric3?.uom,
    keyMetric4Display: keyMetric4?.isEnabled,
    keyMetric4Name: keyMetric4?.keyMetric,
    keyMetric4UoM: keyMetric4?.uom,
    keyMetric5Display: keyMetric5?.isEnabled,
    keyMetric5Name: keyMetric5?.keyMetric,
    keyMetric5UoM: keyMetric5?.uom
  }
  return input
}

export const getCopyDashboardInputData = (dashboard, copyofTranslate) => {
  const {
    name,
    Description,
    isKeyMetric,
    isPublic,
    buildingDataCharts,
    widgetOrder
  } = dashboard
  const data = {
    name: `${copyofTranslate} ${name}`,
    Description,
    isDefault: '0',
    isKeyMetric: booleanToString(isKeyMetric),
    isPublic: booleanToString(isPublic),
    buildingDataCharts,
    widgetOrder
  }
  let copyDashboardData = null
  if (isKeyMetric) {
    const {
      keyMetricaccountId,
      keyMetricbuildingId,
      keyMetric1Display,
      keyMetric1Name,
      keyMetric1UoM,
      keyMetric2Display,
      keyMetric2Name,
      keyMetric2UoM,
      keyMetric3Display,
      keyMetric3Name,
      keyMetric3UoM,
      keyMetric4Display,
      keyMetric4Name,
      keyMetric4UoM,
      keyMetric5Display,
      keyMetric5Name,
      keyMetric5UoM,
      keyMetricComparedTo,
      keyMetricTimePeriod
    } = dashboard
    copyDashboardData = {
      ...data,
      keyMetricaccountId,
      keyMetricbuildingId,
      keyMetric1Display,
      keyMetric1Name,
      keyMetric1UoM,
      keyMetric2Display,
      keyMetric2Name,
      keyMetric2UoM,
      keyMetric3Display,
      keyMetric3Name,
      keyMetric3UoM,
      keyMetric4Display,
      keyMetric4Name,
      keyMetric4UoM,
      keyMetric5Display,
      keyMetric5Name,
      keyMetric5UoM,
      keyMetricComparedTo,
      keyMetricTimePeriod
    }
  } else {
    copyDashboardData = data
  }
  return copyDashboardData
}

export const getValidWidgetOrderValue = (order) => {
  return ['string'].includes((typeof order).toString())
    ? JSON.parse(order)
    : order
}

export const SampleKEyMetricMockData = {
  dashboardId: 1177611,
  name: 'Test2-Dashboard',
  description: '',
  isPublic: false,
  widgets: [
    {
      cols: 1,
      rows: 1,
      targetWidgetName: 'Test-SM-Weather-1',
      widgetId: 1588441
    },
    {
      cols: 1,
      rows: 1,
      targetWidgetName: 'Test-SM-Weather-1',
      widgetId: 1588441
    }
  ],
  owned: true,
  preferences: {
    kpiStrip: {
      enabled: true,
      timePeriod: ':stp/current-month',
      list: []
    }
  }
}

export const isValidUOMForGetChartData = (uom) => {
  return (
    uom && uom !== ':none' && uom !== 'none' && uom !== '' && uom !== undefined
  )
}
