// Default Options.

import { useEffect, useState } from "react";
import { getBarDataCategory, getMaxValue, getMinValue } from "../../CommonComponents/Bar/helper";

export function useOptions(barChartData,isSmallWindow): Options {
  const data = barChartData?.barData
  const minValue = getMinValue(data)
  const maxValue = getMaxValue(data)
  const[gridConfig,setGridConfig] = useState([{ left: "15px", right: "30px", top: "20px", bottom: "20px" }])
  useEffect(() => {
    setGridConfig([{ left: "15px", right: "30px", top: "20px", bottom: isSmallWindow ? "50px" : "20px" }])
  }, [isSmallWindow])
  
  return {
    xAxis: {
      type: "scaleLinear",
      min: minValue > 0 ? 0 : minValue,
      max: maxValue > 0 ? maxValue : 1000
    },
    yAxis: {
      type: "scaleBand",
      data: getBarDataCategory(data)
    },
    grid: gridConfig
  };
}
