export enum VALIDATOR_RULE {
  NOT_APPLICABLE = -1,
  NOT_OK = 0,
  OK = 1
}

export enum WHEN_OPERATOR {
  AND = 'and',
  OR = 'or'
}

export enum TargetKeyType {
  PROPERTIES = 'properties',
  CHARACTERISTICS = 'characteristics',
  GROUPS = 'groups'
}

export enum PROPERTY_COMBINATION {
  OR = 'or',
  AND = 'and'
}
