import Table from '../../components/Table/clientSideTable'
import { ACTIONS } from 'src/constants'
import { IMarker } from './types'
import { useEffect } from 'react'
import { TranslateComponent } from 'src/common/translations'

const nameHeadings = [
  {
    title: <TranslateComponent>Marker Name</TranslateComponent>,
    key: 'name',
    maxWidth: '200px'
  },
  {
    title: '',
    key: 'descriptionIcon',
    type: 'icon',
    disabled: true
  },
  {
    title: <TranslateComponent>Building Name</TranslateComponent>,
    key: 'buildingNames',
    maxWidth: '120px'
  },
  {
    title: <TranslateComponent>Marker Date</TranslateComponent>,
    key: 'markerDate',
    maxWidth: '120px',
    sortField: 'markerDateSort'
  },
  {
    title: <TranslateComponent>Marker Type</TranslateComponent>,
    key: 'type',
    maxWidth: '120px'
  }
]

const EventMarkers = ({
  responseTime,
  rows,
  setMode,
  markerTypes,
  buildingId,
  accountId,
  setMarkerInput,
  reloadEvents,
  setReloadEvents,
  setDeleteMarkerId,
  setShowDeleteDialog,
  showSpinner
}) => {
  useEffect(() => {
    if (reloadEvents) {
      setReloadEvents(false)
    }
  }, [reloadEvents])
    return (
    <Table
      key={`eventMarkersTable-${buildingId}`}
      rows={rows}
      header={nameHeadings}
      loadTime={responseTime}
      search={true}
      showSpinner={showSpinner}
      searchFields={['name', 'building.name']}
      rowControl={[
        {
          text: 'Copy Marker',
          action: (data: IMarker) => {
            const { name, markerDate, description, type,id, buildingIds} = data
            setMode(ACTIONS.COPY)
            setMarkerInput({
              accountId,
              markerDate,
              name: 'Copy of ' + name,
              type,
              description,
              id,
              buildingsList: buildingIds,
              buildingIds: buildingIds,
          })
          }
        },
        {
          text: 'Edit',
          action: (data: IMarker) => {
            const { name, markerDate, description, type, id, buildingIds } = data
            setMode(ACTIONS.EDIT)
            setMarkerInput({
              id,
              buildingsList: buildingIds,
              accountId,
              buildingIds: buildingIds,
              markerDate,
              name,
              type,
              description
            })
          }
        },
        {
          text: 'Delete',
          action: (data: IMarker) => {
            if (data?.id) {
              setDeleteMarkerId(data?.id)
              const { name, markerDate, description, type, buildingIds } = data
              setMarkerInput({
                buildingIds: buildingIds,
                accountId,
                markerDate,
                name,
                type,
                description
              })
              setShowDeleteDialog(true)
            }
            // to be handled when id is missing and show loader?
          }
        }
      ]}
      filtersList={[
        {
          label: 'All Marker Types',
          key: 'type',
          id: 'typeSelectorFilter',
          defaultLabel: 'All Types',
          selectedValue: 'default',
          options: markerTypes
        }
      ]}
    />
  )
}

export default EventMarkers
