import React, { useState, useCallback } from "react"
import { NotificationsSettingsDialog } from "./notifications-settings/notification-settings-dialog"
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import "./index.scss"

export const NotificationItem = ({ categoryKey, index, showSettingsLink, disableChange, onToggleSwitchChange, linkText, enabled, title, text, onSave, values }) => {
	const [isDialogOpened, setIsDialogOpened] = useState(false)
	const toggleNotificationsSettingsDialog = useCallback(() => setIsDialogOpened(!isDialogOpened), [isDialogOpened])
	const onSwitch = useCallback(({ target: { checked } }) => onToggleSwitchChange(index, checked, categoryKey), [index, onToggleSwitchChange])
	return (
		<div className="notification-item">
			<div className="notification-description">
				<h3 className="title">{title}</h3>
				<p className="text">{text}</p>
			</div>
			<div className="notification-actions">
				<ToggleButtonHorizontal
					on={"ON"}
					off={"OFF"}
					disabled={disableChange}
					checked={enabled
					}
					onChange={onSwitch}
				/>
				{showSettingsLink && enabled && <a className="link" onClick={toggleNotificationsSettingsDialog}>{linkText}</a>}
			</div>

			{isDialogOpened && <NotificationsSettingsDialog
				categoryKey={categoryKey}
				values={values}
				onClose={toggleNotificationsSettingsDialog}
				onSave={onSave}
			/>}
		</div>
	)
}

NotificationItem.propTypes = {
}

export default NotificationItem
