import clsx from 'clsx'
import { useMemo, useRef } from 'react'
import Svg from '../../../../../chart-library/CommonComponents/Svg'
import Gauge from '../../../../../chart-library/CommonComponents/Gauge'
import { Tooltip } from '../../../../../chart-library/CommonComponents/Tooltip'
import BaseWrapper from '../../../../../chart-library/CommonComponents/BaseWrapper'
import { useChartContext } from '../../../../../chart-library/CommonComponents/BaseWrapper'
import { clipUUID } from '../../../../../chart-library/CommonComponents/Clip'

import './styles.scss'

// Need to pass some options to basewrapper to instanciate it, so then it will take care of resize
// This confid does nothing with the chart. Dont consider.
const options = {
  xAxis: {
    type: 'scaleTime'
  },
  yAxis: {
    type: 'scaleTime'
  }
}

const mockData = {
  minlimit: 5,
  maxlimit: 100,
  colorRange: [
    {
      limit: 20,
      color: 'green',
      label: '0 - 60',
      d3Symbol: 'symbolSquare',
      labelElProps: {
        color: 'black'
      }
    },
    {
      limit: 90,
      color: 'yellow',
      label: '60 - 90 ',
      d3Symbol: 'symbolSquare',
      labelElProps: {
        color: 'black'
      }
    },
    {
      limit: 100,
      color: 'red',
      label: '90 - 100',
      d3Symbol: 'symbolSquare',
      labelElProps: {
        color: 'black'
      }
    }
  ],
  pointer: {
    value: 80,
    label: ['80 ', 'kW'],
    labelProps: [
      // style for value at label[0]
      {
        fontWeight: 'bold',
        fontFamily: 'Arial'
      },
      // style for value at label[1]
      {
        dy: '1.5em',
        fontFamily: 'Arial'
      }
    ]
  }
}

/**
 *
 * @param param0
 *     data - data required to render gauge
 *     isLargeSize - make it true when widget size is larger size to increaes font size
 *
 * @returns
 */
export default function GaugeChart({ data = mockData, isLargeSize }: any) {
  const eventName = useMemo(() => clipUUID('gauge-chart-tooltip'), [])
  const svgRef: any = useRef(null)

  return (
    <BaseWrapper options={options} className={clsx('gauge-chart')}>
      <Svg ref={svgRef}>
        <ResizableGauge
          data={data}
          isLargeSize={isLargeSize}
          tooltipDispatchEventName={eventName}
        />
      </Svg>
      <Tooltip
        tooltipClassName="gauge-chart-tooltip-bg"
        listenEventName={eventName}
        boundary={svgRef}
        offset={0}
        autoPlacementProps={{
          allowedPlacements: ['top-start', 'top-end']
        }}
      />
    </BaseWrapper>
  )
}

// Component that will resize the gauage on chart size
function ResizableGauge({
  data = {},
  isLargeSize = false,
  tooltipDispatchEventName
}: any) {
  const chart = useChartContext()

  const grid = chart.grid

  if (grid.lastUpdatedAt === null) return null

  const canvas = grid.getGrid(0)

  const x = (canvas.width + canvas.x) / 2
  const y = (canvas.height + canvas.y) / 1.5

  const wR = canvas.width / 2
  let hR = canvas.height / 2
  hR += hR / 2.5

  const r = Math.min(wR, hR)

  const { colorRange, pointer, minlimit, maxlimit } = data

  return (
    <Gauge
      className={clsx({ 'gauge-chart-label': isLargeSize })}
      min={minlimit}
      max={maxlimit}
      labelGap={isLargeSize ? 20 : 10}
      x={x}
      y={y}
      disableAutoPosition
      radius={r}
      data={pointer}
      colorRange={colorRange}
      hideLabels
      showEndLabel
      showStartLabel
      enableTooltip
      tooltipDispatchEventName={tooltipDispatchEventName}
    />
  )
}
