import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { StyledSpinner, NoImage, ImageChildren } from './image-preview.sc.js'
import i18next from 'i18next'
import ImageGalleryWrapper from '../image-gallery-wrapper'

export default class ImagePreview extends PureComponent {
  static propTypes = {
    src: PropTypes.string,
    caption: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.node
  }
  static defaultProps = {
    loading: false
  }

  render() {
    const { src, caption, loading, children } = this.props
    const isChildren = children && Boolean(children)
    const image = loading ? (
      <StyledSpinner />
    ) : src ? (
      <ImageGalleryWrapper
        items={[
          { original: src, originalTitle: '', description: caption ?? '' }
        ]}
        showPlayButton={false}
        showBullets={false}
        showThumbnails={false}
      />
    ) : (
      <NoImage>
        <span>{i18next.t('components:imagePreview>NoImage')}</span>
      </NoImage>
    )

    return (
      <>
        {image}
        {isChildren && <ImageChildren>{children}</ImageChildren>}
      </>
    )
  }
}
