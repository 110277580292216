import Modal from 'src/components/legacy/components/modal/modal'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import {
  OPTIONS,
  TIS_OBJECT,
  SITE_OBJECT,
  TOOLTIP_HORIZONTAL_POSITION_CENTER,
  SUMMIT_TYPES,
  NAX_TYPES,
  NONE,
  AUTOMATION_ID_LENGTH,
  EQUIPMENT_TYPES,
  CREATION_TYPE,
  EMPTY_ARRAY
} from '../../equipmentSetup/constants'
import Label from 'src/components/legacy/components/label/label'
import Tooltip from 'src/denali-ui/components/Tooltip'
import Checkbox from '../../../components/legacy/components/checkbox/checkbox'
import SelectrixWrapper from 'src/components/legacy/components/selectrix-wrapper/selectrix-wrapper'
import './create-equipment-modal.scss'
import RenderSuccessModalView from './render-success-modal'
import { useDimensionsHook } from 'src/hooks/dimensionHook'
import {
  GET_CREATE_EQUIPMENT_DROPDOWN_DETAILS,
  GET_PARENT_TIS_EQUIPMENT,
  LIST_EQUIPMENTS_BY_DEVICE_AND_BY_EQUIPMENT_TYPE,
  GET_DEFAULT_DATA_SOURCE_PROPERTY,
  CONFIG_BY_TYPE
} from '../graphql/queries'
import {
  CREATE_EQUIPMENT,
  UPDATE_EQUIPMENT_ASSOCIATION,
  COPY_CHARACTER_DATA_MAP,
  CREATE_EQUIPMENT_PROPERTY,
  UPDATE_EQUIPMENT
} from '../graphql/mutations'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { useEffect, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _uniqBy from 'lodash/uniqBy'
import _some from 'lodash/some'
import {
  EquipmentTypeList,
  SORT_TYPE,
  PROPERTY_VALUE,
  LoadValveAppendOptions,
  SORT_BY,
  EMPTY,
  getOptionsForDropDowns,
  getCompressorName,
  getCompressorCount,
  getCondenserName,
  getCompressor
} from './constants'
import { getValidationSchema } from './validation-schema'
import { useNavigate } from 'react-router-dom'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import { isDefined, isNum } from 'src/components/legacy/common/helpers'
import UnitOfMeasurementSelect from './unit-of-measurement-select'
import DefaultDataSourcePropertySelect from './default-data-source-property-select'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import translate, { TranslateComponent } from 'src/common/translations'
import { useAPIPagination } from 'src/hooks/APIPaginationHook'

const analog = 'Analog'

export const CreateEquipment = ({
  buildingId,
  handleCreateEquipmentClose,
  equipmentsList,
  optionsTranslated
}) => {
  const [t] = useTranslation()
  const [isNaxConnectivity, setIsNaxConnectivity] = useState(false)
  const [isSummitConnectivity, setIsSummitConnectivity] = useState(false)
  const [equipmentTypeList, setEquipmentTypeList] = useState([])
  const [automationList, setAutomationList] = useState([])
  const [dataSourceTypeList, setDataSourceTypeList] = useState([])
  const [dataSourceList, setdataSourceList] = useState([])
  const [defaultDataSourceList, setDefaultDataSourceList] = useState([])
  const [defaultPropertyList, setDefaultPropertyList] = useState([])
  const [dataSourcePropertyList, setDataSourcePropertyList] = useState([])
  const [defaultDataSourcePropertyList, setDefaultDataSourcePropertyList] =
    useState([])
  const [selectedProperty, setSelectedProperty] = useState('')
  const [selectedSubProperty, setSelectedSubProperty] = useState('')
  const [selectedDimension, setSelectedDimension] = useState('')
  const [dimensionList, setDimensionList] = useState([])
  const [sourceUOMList, setSourceUOMList] = useState([])
  const [targetUOMList, setTargetUOMList] = useState([])
  const [isSaveAndContinue, setIsSaveAndContinue] = useState(false)
  const [subPropertyOptions, setSubPropertyOptions] = useState([])
  const [parentTISEquipmentTypeList, setParentTISEquipmentTypeList] = useState(
    []
  )
  const [parentTISEquipmentsList, setParentTISEquipmentsList] = useState([])
  const [copyCharastersticsList, setCopyCharastersticsList] = useState([])
  const [equipmentType, setEquipmentType] = useState('')
  const [dataSourceType, setDataSourceType] = useState('')
  const [selectedEquipmentType, setSelectedEquipmentType] = useState('')
  const [selectedAutomationName, setSelectedAutomationName] = useState('')
  const [modalOpen, setModelOpen] = useState(false)
  const { condenserTypes, compressorTypes } = getOptionsForDropDowns()
  // Dimension hook to get all Dimensions
  const { defaultDimensionGroup, getAllDimensions } = useDimensionsHook()
  const refToHoldFormikValues = useRef(null)

  const navigate = useNavigate()
  const ACTIVE_STATUS = 1
  const PARENT_ID = 'NA'
  const IS_PARENT = false

  const getEquipmentTypeName = (values) => (
    equipmentTypeList.find(({ key }) => key === values.equipmentTypeName),
    'label'
  )
  const getDeviceId = (deviceName) => {
    const selectedDeviceId = automationList?.filter(
      (automationName) => automationName?.value === deviceName
    )
    return selectedDeviceId[0]?.id
  }

  const { data: buildingData } = useQuery({
    query: GET_CREATE_EQUIPMENT_DROPDOWN_DETAILS,
    variables: { buildingId: buildingId },
    errorPolicy: 'all',
    dataPath: 'data.getBuilding.devices.items',
    disableInitialLoad: false
  })

  const [loadingDataSourceProperties, getDefaultDataSourceProperty,defaultDataSourceProperties] =
    useAPIPagination(
      GET_DEFAULT_DATA_SOURCE_PROPERTY,
      'data.getPropertyMappingByEquipmentID'
    )

  const { data: parentTISEquipments, refetch: getParentTISEquipments } =
    useQuery({
      query: GET_PARENT_TIS_EQUIPMENT,
      errorPolicy: 'all',
      dataPath: 'data.listEquipmentsByBuildingAndActiveAndType.items',
      disableInitialLoad: true
    })

  const {
    data: copyCharacteristicsData,
    refetch: getCopyCharactersticsAndDataMap
  } = useQuery({
    query: LIST_EQUIPMENTS_BY_DEVICE_AND_BY_EQUIPMENT_TYPE,
    disableInitialLoad: true,
    dataPath: 'data.listEquipmentsByDeviceAndByEquipmentType.items'
  })
  const isMultipleEquipmentAllowed = useMemo(
    () => copyCharacteristicsData?.length > EMPTY_ARRAY,
    [copyCharacteristicsData]
  )

  const { onSubmit: onUpdateEquipment } = useMutation({
    query: UPDATE_EQUIPMENT_ASSOCIATION,
    dataPath: 'data.updateEquipment'
  })
  const { onSubmit: onCreateEquipment } = useMutation({
    query: CREATE_EQUIPMENT,
    dataPath: 'data.createEquipment'
  })
  const { onSubmit: onUpdateEquipmentUri } = useMutation({
    query: UPDATE_EQUIPMENT,
    dataPath: 'data.updateEquipment'
  })
  const { onSubmit: onCreateEquipmentProperty } = useMutation({
    query: CREATE_EQUIPMENT_PROPERTY,
    dataPath: 'data.createEquipmentProperty'
  })
  const { onSubmit: onCopyEquipmentDataMapping } = useMutation({
    query: COPY_CHARACTER_DATA_MAP,
    dataPath: 'data. copyEquipmentDataMapping'
  })

  useEffect(() => {
    const propertyList = [];
  const filteredData = EQUIPMENT_TYPES.ANALOG_POINT === equipmentType ? dataSourcePropertyList.filter(
    property => property.sourceDataGroup === analog
  ) : dataSourcePropertyList
  const uniqueData = removeDuplicatesFromObjArray(
    filteredData,
    'sourceDisplayName'
  ) 

  uniqueData.map((defaultData) => {
    if (defaultData?.sourceDisplayName) {
      propertyList.push({
        key: defaultData?.sourceDisplayName,
        value: defaultData?.sourceDisplayName
      })
    }
  })
  setDefaultPropertyList(
    _orderBy(
      _uniqBy(propertyList, (obj) => obj?.key),
      [valueSorter],
      SORT_TYPE.asc
    )
  )
  setDefaultDataSourcePropertyList(data => [...data,...filteredData])

  },[dataSourcePropertyList])

  useEffect(() => {
  setDataSourcePropertyList(data => [...data,...defaultDataSourceProperties])
  },[defaultDataSourceProperties])

  const removeDuplicatesFromObjArray = (arr, field) => {
    return arr.reduce((acc, val) => {
      if(!acc.some(accVal => accVal[field] === val[field])) {
        acc.push(val)
      }
      return acc
    }, [])
  }
  const handleGetDefaultPropertyList = async () => {
    setDataSourcePropertyList([])
    setDefaultDataSourcePropertyList([])
    Promise.all(defaultDataSourceList.map(source => getDefaultDataSourceProperty({
      equipmentId: source?.id
    })))
  }

  const handleSubPropertyList = (selectedPropertyName) => {
    const subPropertyArray = []
    if (selectedProperty) {
      const filterSubPropertyList = dataSourcePropertyList.filter(
        (propertyData) =>
          propertyData.sourceDisplayName === selectedPropertyName
      )
      for (const obj in filterSubPropertyList) {
        if (filterSubPropertyList[obj].subPropertyDisplayName) {
          subPropertyArray.push({
            key: filterSubPropertyList[obj]?.subPropertyDisplayName,
            value: filterSubPropertyList[obj]?.subPropertyDisplayName
          })
        }
      }
    }
    setSubPropertyOptions(
      _orderBy(
        _uniqBy(subPropertyArray, (obj) => obj?.key),
        [valueSorter],
        SORT_TYPE.asc
      )
    )
  }

  const handleDimensionList = (selectedPropertyName) => {
    try {
      const dimensions = []
      if (selectedProperty) {
        for (const obj in defaultDataSourcePropertyList) {
          if (
            defaultDataSourcePropertyList[obj]?.sourceDisplayName ===
            selectedPropertyName
          ) {
            if (
              defaultDataSourcePropertyList[obj]?.sourceMeasureType &&
              defaultDataSourcePropertyList[obj]?.sourceMeasureType !== 'none'
            ) {
              dimensions.push({
                key: defaultDataSourcePropertyList[obj]?.sourceMeasureType,
                value: defaultDataSourcePropertyList[obj]?.sourceMeasureType
              })
            } else {
              Object.keys(defaultDimensionGroup).map((data) => {
                try {
                  const defaultDimensionId =
                    defaultDimensionGroup[data][0]?.value
                  dimensions.push({
                    key: data,
                    value: data,
                    dimensionId: defaultDimensionId['Dimension']
                  })
                } catch (error) {}
              })
            }
          } else {
            setSelectedDimension('')
          }
        }
      }
      setDimensionList(
        _orderBy(
          _uniqBy(dimensions, (obj) => obj?.key),
          [valueSorter],
          SORT_TYPE.asc
        )
      )
    } catch (error) {}
  }

  const handleTargetUOMList = (selectedDimension) => {
    if(!selectedDimension){
      return ;
    }
    const dimension = defaultDimensionGroup[selectedDimension]?.[0].value;
    if(dimension){
    const targetUOMs = [
      {
        key: dimension['DefaultUoM'],
        value: dimension['DefaultUoM'],
        description: dimension['DefaultUoMDisplay'],
        dimensionId: dimension['Dimension']
      }
    ]
    setTargetUOMList(targetUOMs)
  }
  }

  const handleSourceUOMList = (selectedPropertyName) => {
    try {
      const sourceUOMs = []
      defaultDataSourcePropertyList.map((property) => {
        if (property?.sourceDisplayName === selectedPropertyName) {
          if (
            property?.sourceMeasureUnit &&
            property?.sourceMeasureType !== 'none'
          ) {
            sourceUOMs.push({
              key: property?.sourceMeasureUnit,
              value: property?.sourceMeasureUnit
            })
          } else {
            defaultDimensionGroup[selectedDimension]?.map((dimensionData) => {
              try {
                const value = dimensionData?.value
                sourceUOMs.push({
                  key: value['UoM'],
                  value: value['UoM'],
                  description: value['Name'],
                  dimensionId: value['Dimension']
                })
              } catch (error) {}
            })
          }
        }
      })
      setSourceUOMList(
        _orderBy(
          _uniqBy(sourceUOMs, (obj) => obj?.key),
          [valueSorter],
          SORT_TYPE.asc
        )
      )
    } catch (error) {}
  }
  const handleDefaultDimensionFun = async () => {
    try {
      if (
        equipmentType === 'Analog Point' &&
        !Object(defaultDimensionGroup)?.keys?.length
      ) {
        getAllDimensions()
      }
    } catch (error) {}
  }

  useEffect(() => {
    console.log(defaultDimensionGroup)
  }, [defaultDimensionGroup])

  useEffect(() => {
    if (defaultDataSourceList.length) {
      handleGetDefaultPropertyList()
    }
  }, [defaultDataSourceList])

  useEffect(() => {
    handleDefaultDimensionFun()
  }, [equipmentType])

  useEffect(() => {
    handleSubPropertyList(selectedProperty)
    handleDimensionList(selectedProperty)
  }, [selectedProperty])

  useEffect(() => {
    handleSourceUOMList(selectedProperty)
    handleTargetUOMList(selectedDimension)
  }, [selectedDimension])

  useEffect(() => {
    if (selectedEquipmentType && dataSourceType && selectedAutomationName) {
      getCopyCharactersticsAndDataMap({
        deviceId: getDeviceId(selectedAutomationName),
        type: selectedEquipmentType,
        dataSourceType: dataSourceType
      })
    }
  }, [selectedEquipmentType, dataSourceType, selectedAutomationName])

  const handleClose = (isRefresh) => {
    handleCreateEquipmentClose(isRefresh)
  }

  const getSelectedEquipmentTypeObject = (equipmentTypeName) => {
    let selectedObject = {}
    for (const obj in EquipmentTypeList) {
      if (obj === equipmentTypeName) {
        selectedObject = EquipmentTypeList[obj]
      }
    }
    return selectedObject
  }
  const filterParentEquipmentList = (equipmentLists) => {
    const duplicateList = []
    for (const obj in equipmentLists) {
      duplicateList.push(equipmentLists[obj])
    }
    return duplicateList
  }
  const filterDataSourceEquipment = (selectedDataSourceName) => {
    const selectedDataSourceEquipment = dataSourceList?.filter(
      (dataSource) => dataSource.label === selectedDataSourceName
    )
    return selectedDataSourceEquipment[0]?.id
  }
  const getDataSourceURI = (selectedDataSourceName) => {
    const selectedDataSourceEquipment = dataSourceList?.filter(
      (dataSource) => dataSource.label === selectedDataSourceName
    )
    return selectedDataSourceEquipment[0]?.sourceUri
  }
  const getDataSourceKey = (selectedDataSourceName) => {
    const selectedDataSourceProperty = defaultDataSourcePropertyList?.filter(
      (dataSourceProperty) => dataSourceProperty.sourceDisplayName === selectedDataSourceName
    )
    return selectedDataSourceProperty[0]?.sourceKey
  }

  const handleNavigateToListPage = (equipmentId) => {
    if (equipmentId) {
      setModelOpen(true)
      if (isSaveAndContinue) {
        navigate(
          `/equipment-setup/details?location=${buildingId}&equipment=${equipmentId}&tab=${'2'}`
        )
        return
      }
    }
  }

  const handleCreateEquipmentFun = async (
    inputs,
    parentTisEquipmentId,
    copyCharacteristicsFromEquipmentName,
    copyCharacteristics,
    ChillerFields,
    equipmentTypeName,
    analogInputs,
    arrayIndex
  ) => {
    const filterData = buildingData?.filter((deviceData) => {
      return deviceData.name === inputs.automationName
    })
    //const automationSerialNum = filterData[0].serial
    const deviceId = filterData[0]?.id
    await onCreateEquipment({ input: inputs }).then((data) => {
      if (parentTisEquipmentId) {
        let chillerRequestProps = {}
        let compressorProps = {}
        if (equipmentTypeName === 'Chiller') {
           compressorProps = ChillerFields?.compressors?.reduce((acc,compressorData) => {
              const data = getCompressorName(compressorData)
              acc =  {
                ...acc,
                [data.paramPresentName] : compressorData.value,
                [data.paramValueName] : "Yes"
              }
              return acc
          },{})
          chillerRequestProps = {
            CompressorCountCKT1: ChillerFields.circuit1CompressorCount,
            CompressorCountCKT2: ChillerFields.circuit2CompressorCount,
            CircuitCount: ChillerFields.circuitCount,
            CompressorCount: ChillerFields.compressorCount,
            CompressorType: ChillerFields.compressorType,
            CondenserType: ChillerFields.condenserType,
          }
        }
        onUpdateEquipment({
          input: {
            id: parentTisEquipmentId,
            isParent: true
          }
        }).then((updateData) => {
          let mutationBody
          if (updateData?.id) {
            mutationBody = {
              Type: 'Create',
              data: {
                sourceEquipmentId: copyCharacteristicsFromEquipmentName
                  ? copyCharacteristicsFromEquipmentName
                  : 'NA',
                destinationEquipmentId: data.id,
                equipmentType: inputs.type,
                sourceDeviceId: deviceId || '',
                buildingId: buildingId,
                defaultEquipmentId:
                  filterDataSourceEquipment(inputs?.dataSourceName) || '',
                  ...(Object.keys(chillerRequestProps)?.length > 0
                  ? { parameters: {...chillerRequestProps,...compressorProps} }
                  : {})
              }
            }
            onUpdateEquipmentUri({
              input: {
                id: data?.id,
                uri: `/virtual/${data?.id}`
              }
            })
            if (equipmentTypeName === 'Analog Point') {
              let analogPointMutationBody
              if (arrayIndex) {
                analogPointMutationBody = {
                  ...analogInputs,
                  equipmentId: data?.id,
                  sourceDeviceId: deviceId,
                  sourceUri: getDataSourceURI(inputs?.dataSourceName),
                  subPropertyDisplayName: arrayIndex
                }
              } else {
                analogPointMutationBody = {
                  ...analogInputs,
                  equipmentId: data?.id,
                  sourceDeviceId: deviceId,
                  sourceUri: getDataSourceURI(inputs?.dataSourceName)
                }
              }

              onCreateEquipmentProperty({ input: analogPointMutationBody })
            }
            onCopyEquipmentDataMapping({
              input: JSON.stringify(mutationBody)
            })
            handleNavigateToListPage(data?.id)
          }
        })
      }
      if (!parentTisEquipmentId) {
        let chillerRequestProps = {}
        let compressorProps = {}
        if (equipmentTypeName === 'Chiller') {
           compressorProps = ChillerFields?.compressors?.reduce((acc,compressorData) => {
              const data = getCompressorName(compressorData)
              acc =  {
                ...acc,
                [data.paramPresentName] : compressorData.value,
                [data.paramValueName] : "Yes"
              }
              return acc
          },{})
          chillerRequestProps = {
            CompressorCountCKT1: ChillerFields.circuit1CompressorCount,
            CompressorCountCKT2: ChillerFields.circuit2CompressorCount,
            CircuitCount: ChillerFields.circuitCount,
            CompressorCount: ChillerFields.compressorCount,
            CompressorType: ChillerFields.compressorType,
            CondenserType: ChillerFields.condenserType,
          }
        }
        const mutationBody = {
          Type: 'Create',
          data: {
            sourceEquipmentId: copyCharacteristicsFromEquipmentName
              ? copyCharacteristicsFromEquipmentName
              : 'NA',
            destinationEquipmentId: data.id,
            equipmentType: inputs.type,
            sourceDeviceId: deviceId || '',
            buildingId: buildingId,
            defaultEquipmentId:
              filterDataSourceEquipment(inputs?.dataSourceName) || '',
            ...(Object.keys(chillerRequestProps)?.length > 0
              ? { parameters: {...chillerRequestProps,...compressorProps} }
              : {})
          }
        }
        if (equipmentTypeName === 'Analog Point') {
          let analogPointMutationBody
          if (arrayIndex) {
            analogPointMutationBody = {
              ...analogInputs,
              equipmentId: data?.id,
              sourceDeviceId: deviceId,
              sourceUri: getDataSourceURI(inputs?.dataSourceName),
              subPropertyDisplayName: arrayIndex
            }
          } else {
            analogPointMutationBody = {
              ...analogInputs,
              equipmentId: data?.id,
              sourceDeviceId: deviceId,
              sourceUri: getDataSourceURI(inputs?.dataSourceName)
            }
          }
          onCreateEquipmentProperty({ input: analogPointMutationBody })
        }
        onCopyEquipmentDataMapping({
          input: JSON.stringify(mutationBody)
        })
        onUpdateEquipmentUri({
          input: {
            id: data?.id,
            uri: `/virtual/${data?.id}`
          }
        })
        handleNavigateToListPage(data?.id)
      }
    })
  }
  const handleCreateEquipment = async (values) => {
    const {
      equipmentName,
      equipmentTypeName,
      defaultAutomationSystemNames,
      defaultDataSourceTypeName,
      defaultDataSourceEquipmentNames,
      defaultDataSourceEquipments,
      parentEquipmentName,
      overrideParentEquipment,
      appendPrependText,
      appendPrependOptionValue,
      copyCharacteristicsFromEquipmentName,
      copyCharacteristics,
      defaultDataSourcePropertyId,
      dimension,
      sourceUOM,
      targetUOM,
      arrayIndex
    } = values
    const selectedObject = getSelectedEquipmentTypeObject(equipmentTypeName)
    const type = selectedObject['type'] ?? ''
    const family = selectedObject['family'] ?? ''
    let equipmentDetail
    let inputs = {}
    let analogPointInput = {}
    let newEquipmentName
    const ChillerFields =
      equipmentTypeName === 'Chiller'
        ? getChillerSpecificValuesIfSelected(values)
        : null
    const copyCharacteristicField = copyCharacteristicsFromEquipmentName && {
      copyMappingEquipmentId: copyCharacteristicsFromEquipmentName
    }
    if (defaultDataSourceEquipments.length > 1) {
      defaultDataSourceEquipments.forEach(async ({deviceId : defaultDataSourceId,name : sourceName}) => {
        equipmentDetail = getDropDownDataOrEquipmentDetail(
          defaultAutomationSystemNames[0],
          EMPTY,
          defaultDataSourceTypeName,
          sourceName,
          type
        )
        newEquipmentName =
          appendPrependOptionValue === OPTIONS.APPEND
            ? `${sourceName} ${appendPrependText}`
            : `${appendPrependText} ${sourceName}`
        if (equipmentType === 'Analog Point') {
          analogPointInput = {
            buildingId: buildingId,
            equipmentType: 'Points',
            creationType: CREATION_TYPE.User,
            sourceKey: getDataSourceKey(defaultDataSourcePropertyId),
            startDateTime: new Date(),
            propertyType: 'Property',
            sourceDisplayName: defaultDataSourcePropertyId,
            targetMeasureType: dimension,
            sourceMeasureType: dimension,
            sourceMeasureUnit: sourceUOM,
            targetMeasureUnit: targetUOM,
            targetKey: 'PresentValue',
            targetDataGroup: 'Analog',
            tisDisplayName: 'Present Value',
            targetDataType: 'float',
            maxValue: -9999,
            minValue: -9999,
            priority: 1
          }
        }
        if (ChillerFields) {
          inputs = {
            automationName: defaultAutomationSystemNames[0],
            buildingId,
            creationType: CREATION_TYPE.User,
            controllerType: equipmentDetail?.controllerType,
            dataSourceName: sourceName,
            dataSourceType: defaultDataSourceTypeName,
            family: family,
            isActive: ACTIVE_STATUS,
            name: newEquipmentName,
            type,
            uri: 'TestURI',
            isParent: IS_PARENT,
            parentId: PARENT_ID,
            compressorCount: ChillerFields.compressorCount,
            compressorType: getCompressor(ChillerFields.compressorType),
            condenserType: getCondenserName(ChillerFields.condenserType),
            circuitCount: ChillerFields.circuitCount,
            dataMappingEquipmentId: filterDataSourceEquipment(sourceName),
            isTIS: 1,
            ...copyCharacteristicField
          }
        } else {
          inputs = {
            automationName: defaultAutomationSystemNames[0],
            buildingId,
            creationType: CREATION_TYPE.User,
            controllerType: equipmentDetail?.controllerType,
            dataSourceName: sourceName,
            dataSourceType: defaultDataSourceTypeName,
            family: family,
            isActive: ACTIVE_STATUS,
            name: newEquipmentName,
            type,
            uri: 'TestURI',
            isParent: IS_PARENT,
            parentId: PARENT_ID,
            isTIS: 1,
            dataMappingEquipmentId: filterDataSourceEquipment(sourceName),
            ...copyCharacteristicField
          }
        }
        if (defaultDataSourceTypeName && defaultDataSourceTypeName !== NONE) {
          inputs['deviceId'] = defaultDataSourceId
        }
        if (overrideParentEquipment) {
          inputs['parentId'] =
            parentTISEquipments?.find((p) => p?.name === parentEquipmentName)
              ?.id ?? PARENT_ID
        } else {
          inputs['parentId'] = PARENT_ID
        }
        const parentTisEquipmentId = parentTISEquipments?.find(
          (p) => p?.name === parentEquipmentName
        )?.id
        await handleCreateEquipmentFun(
          inputs,
          parentTisEquipmentId,
          copyCharacteristicsFromEquipmentName,
          copyCharacteristics,
          ChillerFields,
          equipmentTypeName,
          analogPointInput,
          arrayIndex
        )
      })
    } else {
      equipmentDetail = getDropDownDataOrEquipmentDetail(
        defaultAutomationSystemNames[0],
        EMPTY,
        defaultDataSourceTypeName,
        defaultDataSourceEquipmentNames[0],
        type
      )
      if (equipmentType === 'Analog Point') {
        analogPointInput = {
          buildingId: buildingId,
          equipmentType: 'Points',
          creationType: CREATION_TYPE.User,
          sourceKey: getDataSourceKey(defaultDataSourcePropertyId),
          startDateTime: new Date(),
          propertyType: 'Property',
          sourceDisplayName: defaultDataSourcePropertyId,
          targetMeasureType: dimension,
          sourceMeasureType: dimension,
          sourceMeasureUnit: sourceUOM,
          targetMeasureUnit: targetUOM,
          targetKey: 'PresentValue',
          targetDataGroup: 'Analog',
          tisDisplayName: 'Present Value',
          targetDataType: 'float',
          maxValue: -9999,
          minValue: -9999,
          priority: 1
        }
      }
      if (ChillerFields) {
        inputs = {
          automationName: defaultAutomationSystemNames[0],
          buildingId,
          creationType: CREATION_TYPE.User,
          controllerType: equipmentDetail?.controllerType,
          dataSourceName: defaultDataSourceEquipmentNames[0],
          dataSourceType: defaultDataSourceTypeName,
          family: family,
          isActive: ACTIVE_STATUS,
          name: equipmentName,
          type,
          uri: 'TestURI',
          isParent: IS_PARENT,
          parentId: PARENT_ID,
          compressorCount: ChillerFields.compressorCount,
          compressorType: getCompressor(ChillerFields.compressorType),
          condenserType: getCondenserName(ChillerFields.condenserType),
          circuitCount: ChillerFields.circuitCount,
          isTIS: 1,
          dataMappingEquipmentId: filterDataSourceEquipment(
            defaultDataSourceEquipmentNames[0]
          ),
          ...copyCharacteristicField
        }
      } else {
        inputs = {
          automationName: defaultAutomationSystemNames[0],
          buildingId,
          creationType: CREATION_TYPE.User,
          controllerType: equipmentDetail?.controllerType,
          dataSourceName: defaultDataSourceEquipmentNames[0],
          dataSourceType: defaultDataSourceTypeName,
          family: family,
          isActive: ACTIVE_STATUS,
          name: equipmentName,
          type,
          uri: 'TestURI',
          isParent: IS_PARENT,
          parentId: PARENT_ID,
          isTIS: 1,
          dataMappingEquipmentId: filterDataSourceEquipment(
            defaultDataSourceEquipmentNames[0]
          ),
          ...copyCharacteristicField
        }
      }
      if (defaultDataSourceTypeName && defaultDataSourceTypeName !== NONE) {
        inputs['deviceId'] = defaultDataSourceEquipments?.[0]?.deviceId
      }
      if (overrideParentEquipment) {
        inputs['parentId'] =
          parentTISEquipments?.find((p) => p?.name === parentEquipmentName)
            ?.id ?? PARENT_ID
      } else {
        inputs['parentId'] = PARENT_ID
      }
      const parentTisEquipmentId = parentTISEquipments?.find(
        (p) => p?.name === parentEquipmentName
      )?.id
      await handleCreateEquipmentFun(
        inputs,
        parentTisEquipmentId,
        copyCharacteristicsFromEquipmentName,
        copyCharacteristics,
        ChillerFields,
        equipmentTypeName,
        analogPointInput,
        arrayIndex
      )
    }
  }
  const getChillerSpecificValuesIfSelected = (values) => {
    if (!values.condenserType) {
      return null
    }

    const {
      condenserType,
      compressorType,
      compressor1ANominalCapacity,
      compressor1BNominalCapacity,
      compressor1CNominalCapacity,
      compressor2ANominalCapacity,
      compressor2BNominalCapacity,
      compressor2CNominalCapacity
    } = values
    const getCorrectChillerSpecificValue = (value) =>
      value === NONE ? null : value
    const chillerSpecificValues = {
      condenserType: getCorrectChillerSpecificValue(condenserType),
      compressorType: getCorrectChillerSpecificValue(compressorType),
      circuitCount: 0,
      compressorCount: 0,
      compressors: []
    }

    ;[
      [
        [compressor1ANominalCapacity, 'A'],
        [compressor1BNominalCapacity, 'B'],
        [compressor1CNominalCapacity, 'C']
      ],
      [
        [compressor2ANominalCapacity, 'A'],
        [compressor2BNominalCapacity, 'B'],
        [compressor2CNominalCapacity, 'C']
      ]
    ].reduce((chillerSpecificValues, circuitCompressorInstances, index) => {
      const circuitInstanceNumber = index + 1
      const circuitCompressorInstancesFiltered =
        circuitCompressorInstances.filter(
          ([compressorNominalCapacity]) => compressorNominalCapacity
        )
      chillerSpecificValues[`circuit${circuitInstanceNumber}CompressorCount`] =
        circuitCompressorInstancesFiltered.length
      chillerSpecificValues.compressorCount =
        chillerSpecificValues['circuit1CompressorCount'] +
        chillerSpecificValues['circuit2CompressorCount']
      chillerSpecificValues.circuitCount =
        circuitCompressorInstancesFiltered.length
          ? ++chillerSpecificValues.circuitCount
          : chillerSpecificValues.circuitCount

      circuitCompressorInstancesFiltered.forEach(
        ([compressorNominalCapacity, compressorInstanceLetter]) => {
          chillerSpecificValues.compressors.push({
            name: `compressor${circuitInstanceNumber}${compressorInstanceLetter}NominalCapacity`,
            value: compressorNominalCapacity
          })
        }
      )
      return chillerSpecificValues
    }, chillerSpecificValues)

    return chillerSpecificValues
  }
  const valueSorter = (item) => item?.value?.toLowerCase()
  const labelSorter = (item) => item?.label?.toLowerCase()
  useEffect(() => {
    if (_isEmpty(buildingData)) return
    const automationSystemList = []
    const isNAX = _some(buildingData, (obj) => NAX_TYPES.includes(obj?.type))
    const isSummit = _some(buildingData, (obj) =>
      SUMMIT_TYPES.includes(obj?.type)
    )
    buildingData?.forEach((device) => {
      const equipmentsPresent = device?.equipments?.items?.length > 0
      const automationName = device?.name ?? ''
      if (
        equipmentsPresent &&
        !_isEmpty(automationName) &&
        device?.isActive &&
        device?.type !== 'ES' &&
        device?.type !== 'BCU'
      ) {
        automationSystemList.push({
          key: automationName,
          value: automationName,
          id: device?.id
        })
      }
    })
    setAutomationList(
      _orderBy(
        _uniqBy(automationSystemList, (obj) => obj?.key),
        [valueSorter],
        SORT_TYPE.asc
      )
    )
    setIsNaxConnectivity(isNAX)
    setIsSummitConnectivity(isSummit)
    handleGetEquipmentTypeList()
  }, [buildingData])

  useEffect(() => {
    if (equipmentType) {
      const parentEquipmentTypeList = EquipmentTypeList[equipmentType]?.parent
      const parentEquipmentTypes = filterParentEquipmentList(
        parentEquipmentTypeList
      )?.map((parent) => {
        return { key: parent?.type, value: parent?.family }
      })
      setParentTISEquipmentTypeList(
        _orderBy(
          _uniqBy(parentEquipmentTypes, SORT_BY.VALUE),
          [valueSorter],
          SORT_TYPE.asc
        )
      )
    }
  }, [equipmentType])

  useEffect(() => {
    if (parentTISEquipments) {
      const parentEquipments = parentTISEquipments?.map((parent) => {
        return { key: parent?.name, value: parent?.name }
      })
      setParentTISEquipmentsList(
        _orderBy(
          _uniqBy(parentEquipments, SORT_BY.VALUE),
          [valueSorter],
          SORT_TYPE.asc
        )
      )
    }
  }, [parentTISEquipments])

  useEffect(() => {
    if (copyCharacteristicsData) {
      const copyCharacteristicsSorted = copyCharacteristicsData?.map((item) => {
        return { key: item?.id, label: item?.name }
      })
      setCopyCharastersticsList(
        _orderBy(
          _uniqBy(copyCharacteristicsSorted, SORT_BY.LABEL),
          [labelSorter],
          SORT_TYPE.asc
        )
      )
    }
  }, [copyCharacteristicsData])

  const LoadValveAppendOptionsTranslate = LoadValveAppendOptions.map((items)=>{
    return{...items, value: translate(items.value)}
  })

  const handleGetEquipmentTypeList = () => {
    const keyNames = Object.keys(EquipmentTypeList)
    const equipmentTypeList = keyNames?.map((name) => ({
      key: name,
      label: name
    }))
    setEquipmentTypeList(
      _orderBy(
        _uniqBy(equipmentTypeList, SORT_BY.LABEL),
        [labelSorter],
        SORT_TYPE.asc
      )
    )
  }

  const getDropDownDataOrEquipmentDetail = (
    automationName: string,
    propertyName: string = EMPTY,
    dataSourceType: string = EMPTY,
    dataSourceName: string = EMPTY,
    isIncludeUnmapped = false,
    defaultDataSource = false
  ) => {
    const equipmentDetail = {
      dropDownList: [],
      uri: EMPTY,
      deviceId: EMPTY,
      controllerType: EMPTY
    }
    const autoCreatedEquipments = []
    const userCreatedEquipments = []
    const automationsListByName = buildingData?.filter(
      (a) => a?.name === automationName
    )
    automationsListByName?.forEach((device) => {
      const equipments = device?.equipments?.items ?? []
      if (equipments?.length > 0) {
        equipments?.forEach((equipment) => {
          if (!_isEmpty(propertyName) && equipment?.isActive === 1) {
            const propertyValue = equipment[propertyName] ?? ''
            if (!_isEmpty(propertyValue)) {
              if (
                equipment.dataSourceType === dataSourceType &&
                dataSourceType !== EMPTY
              ) {
                equipment?.creationType && equipment?.creationType === 'Auto'
                  ? autoCreatedEquipments.push(equipment)
                  : userCreatedEquipments.push(equipment)
              }
              if (dataSourceType === EMPTY)
                equipmentDetail.dropDownList.push({
                  key: propertyValue,
                  value: propertyValue
                })
            }
          } else {
            if (
              equipment?.dataSourceName === dataSourceName &&
              equipment?.dataSourceType === dataSourceType
            ) {
              equipmentDetail.uri = equipment?.uri
              equipmentDetail.deviceId = equipment?.deviceId
              equipmentDetail.controllerType = equipment?.controllerType
            }
          }
        })
      }
    })
    if (defaultDataSource) {
      const userCreatedDSNList = (
        userCreatedEquipments ? userCreatedEquipments : []
      )?.map((obj) => obj?.dataSourceName)
      const isIncludeCheckList = autoCreatedEquipments?.filter(
        (obj) => !userCreatedDSNList?.includes(obj?.dataSourceName)
      )
      let dropDownList = isIncludeUnmapped
        ? isIncludeCheckList
        : autoCreatedEquipments
      dropDownList = dropDownList?.map((obj) => {
        const propertyValue = obj[propertyName] ?? ''
        return {
          key: propertyValue,
          label: propertyValue,
          id: obj.id,
          deviceId:obj.deviceId,
          sourceUri: obj?.uri
        }
      })
      equipmentDetail.dropDownList = dropDownList
    }
    return equipmentDetail
  }

  const handleGetDataSourceTypeList = (automationName: string) => {
    if (_isEmpty(automationName)) return
    const dataSourceTypeList = getDropDownDataOrEquipmentDetail(
      automationName,
      PROPERTY_VALUE.DATA_SOURCE_TYPE
    )?.dropDownList
    const dataSourceTypeListWithNone = [{ key: NONE, value: NONE }].concat(
      _orderBy(
        _uniqBy(dataSourceTypeList, (obj) => obj?.key),
        [valueSorter],
        SORT_TYPE.asc
      )
    )
    setDataSourceTypeList(dataSourceTypeListWithNone)
  }
  const handleGetDataSourceList = (
    automationName: string,
    dataSourceType: string,
    isIncludeUnmapped: boolean
  ) => {
    if (_isEmpty(automationName)) return
    const dataSourceList = getDropDownDataOrEquipmentDetail(
      automationName,
      PROPERTY_VALUE.NAME,
      dataSourceType,
      EMPTY,
      isIncludeUnmapped,
      true
    )?.dropDownList
    setdataSourceList(
      _orderBy(
        _uniqBy(dataSourceList, (obj) => obj?.key),
        [labelSorter],
        SORT_TYPE.asc
      )
    )
  }
  const initialValues = useMemo(
    () => ({
      equipmentTypeName: undefined,
      defaultAutomationSystemNames: [],
      parentEquipmentTypeName: undefined,
      parentEquipmentName: undefined,
      copyCharacteristicsFromEquipmentName: undefined,
      defaultDataSourceEquipmentNames: [],
      defaultDataSourceTypeName: '',
      equipmentTypeId: '',
      includeUnmappedDataSourceOnly: true,
      overrideParentEquipment: false,
      equipmentName: '',
      overrideEquipmentName: false,
      appendPrependText: '',
      appendPrependOptionValue: OPTIONS.APPEND,
      copyCharacteristics: false,
      // Chiller specific values
      condenserType: undefined,
      compressorType: undefined,
      compressor1ANominalCapacity: undefined,
      compressor1BNominalCapacity: undefined,
      compressor1CNominalCapacity: undefined,
      compressor2ANominalCapacity: undefined,
      compressor2BNominalCapacity: undefined,
      compressor2CNominalCapacity: undefined,
      // Analog Point and Load Valve specific values
      defaultDataSourcePropertyId: undefined,
      arrayIndex: undefined,
      // Analog Point specific values
      dimension: undefined,
      sourceUOM: undefined,
      targetUOM: undefined,
      // Load Valve specific values
      sourceTypeId: SITE_OBJECT,
      ...(refToHoldFormikValues.current ? refToHoldFormikValues.current : {}),
      copyCharastersticsList
    }),
    [copyCharastersticsList]
  )

  const validationSchema = getValidationSchema({
    t,
    isNaxConnectivity,
    equipmentsList,
    equipmentTypeList,
    dataSourcePropertyList
  })

  const defaultDataSourceComponent = ({
    formik: {
      values,
      values: {
        defaultDataSourceTypeName,
        defaultDataSourceEquipmentNames,
        includeUnmappedDataSourceOnly,
        defaultAutomationSystemNames
      },
      setFieldValue,
      setValues,
      setFieldTouched
    }
  }) => {
    const options = dataSourceList
    return (
      <div className="selectrix-container">
        <div>
          <Label text={"Default Data Source"} required={true}/>
          <i>
          {"("}{defaultDataSourceEquipmentNames?.length > 0 ? 1 : 0} : <TranslateComponent>{"Selected"}</TranslateComponent>{")"}

          </i>
          <Tooltip
            horizontalPosition={TOOLTIP_HORIZONTAL_POSITION_CENTER}
            content={optionsTranslated.toolTipContent}
            SpanOrDiv="span"
            width="250px"
          >
            <span className="icon icon-infocircle" />
          </Tooltip>
        </div>
        <div>
          <Checkbox
            label={"Include unmapped Data Sources only"}
            isChecked={includeUnmappedDataSourceOnly}
            id="data-source-only"
            toggleCheck={(event) => {
              setFieldValue(
                'includeUnmappedDataSourceOnly',
                event.target.checked
              )
              handleGetDataSourceList(
                defaultAutomationSystemNames[0],
                defaultDataSourceTypeName,
                event.target.checked
              )
            }}
            disabled={
              EQUIPMENT_TYPES.VIRTUAL_METER === equipmentType ||
              _isEmpty(defaultDataSourceTypeName) ||
              defaultDataSourceTypeName === NONE
            }
          />
          <Tooltip
            horizontalPosition={TOOLTIP_HORIZONTAL_POSITION_CENTER}
            content={optionsTranslated.toolTip}
            SpanOrDiv="span"
            width="230px"
          >
            <span className="icon icon-infocircle" />
          </Tooltip>
        </div>
        {isMultipleEquipmentAllowed ? (
          <SelectrixWrapper
            testName={TEXT_NAME_PREFIX()}
            className="selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select default-data-source"
            multiple={true}
            onChange={(selectedItems) => {
              setFieldTouched('equipmentName')
              setValues({
                ...values,
                appendPrependOptionValue: OPTIONS.APPEND,
                overrideEquipmentName: false,
                appendPrependText: '',
                defaultDataSourceEquipments: Array.isArray(
                  selectedItems
                )
                  ? selectedItems.map((item) => ({name : item.key,deviceId : item.deviceId}))
                  : [{name : selectedItems.key,deviceId : selectedItems.deviceId}],
                defaultDataSourceEquipmentNames: Array.isArray(selectedItems)
                  ? selectedItems.map((item) => item.key)
                  : [selectedItems.key],
                equipmentName: Array.isArray(selectedItems)
                  ? selectedItems.length === 1
                    ? selectedItems[0]?.label
                    : ''
                  : selectedItems.label
              })
              setDefaultDataSourceList(selectedItems)
              if (selectedItems.length > 1) {
                setFieldValue('copyCharacteristics', true)
              }
            }}
            options={options}
            // placeholder={optionsTranslated.selectPlaceholder}
            inputPlaceholder={optionsTranslated.findSource}
            searchable={true}
            searchBoxInside={true}
            searchIndex={false}
            stayOpen={true}
            defaultValue={defaultDataSourceEquipmentNames}
            disabled={
              !isDefined(defaultDataSourceTypeName) ||
              defaultDataSourceTypeName === NONE
            }
            onClose={() => {
              setFieldTouched('defaultDataSourceEquipmentIds')
            }}
          />
        ) : (
          <div>
            <SelectrixWrapper
              testName={TEXT_NAME_PREFIX()}
              className="selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select default-data-source"
              onChange={(selectedItems) => {
                const singleDataSourceList = []
                setTimeout(() => {
                  setFieldTouched('equipmentName')
                  setValues({
                    ...values,
                    appendPrependOptionValue: OPTIONS.APPEND,
                    overrideEquipmentName: false,
                    appendPrependText: '',
                    defaultDataSourceEquipments: Array.isArray(
                      selectedItems
                    )
                      ? selectedItems.map((item) => ({name : item.key,deviceId : item.deviceId}))
                      : [{name : selectedItems.key,deviceId : selectedItems.deviceId}],
                    defaultDataSourceEquipmentNames: Array.isArray(
                      selectedItems
                    )
                      ? selectedItems.map((item) => item.key)
                      : [selectedItems.key],
                    equipmentName: Array.isArray(selectedItems)
                      ? selectedItems.length === 1
                        ? selectedItems[0]?.label
                        : ''
                      : selectedItems.label
                  })
                }, 5)
                singleDataSourceList.push(selectedItems)
                setDefaultDataSourceList(singleDataSourceList)
                if (selectedItems.length > 1) {
                  setFieldValue('copyCharacteristics', true)
                }
              }}
              options={options}
              placeholder={optionsTranslated.selectPlaceholder}
              inputPlaceholder={optionsTranslated.findSource}
              searchable={true}
              searchBoxInside={true}
              searchIndex={false}
              stayOpen={true}
              defaultValue={defaultDataSourceEquipmentNames}
              disabled={
                _isEmpty(defaultDataSourceTypeName) ||
                defaultDataSourceTypeName === NONE
              }
              onClose={() => {
                setFieldTouched('defaultDataSourceEquipmentNames')
              }}
            />
          </div>
        )}
      </div>
    )
  }

  const copyFromComponent = ({
    name,
    formik: {
      values: {
        copyCharacteristics,
        equipmentTypeName,
        copyCharacteristicsFromEquipmentName,
        defaultAutomationSystemNames,
        defaultDataSourceEquipmentNames
      },
      setFieldValue,
      setFieldTouched
    }
  }) => (
    <div className="selectrix-container">
      <Checkbox
        label={"Copy characteristics & data map"}
        isChecked={copyCharacteristics}
        id="copy-characteristics"
        toggleCheck={(event) => {
          setFieldValue('copyCharacteristics', event.target.checked)
        }}
        disabled={
          EQUIPMENT_TYPES.VIRTUAL_METER === equipmentType ||
          !equipmentTypeName ||
          defaultAutomationSystemNames?.length > AUTOMATION_ID_LENGTH ||
          defaultDataSourceEquipmentNames?.length > AUTOMATION_ID_LENGTH
        }
      />
      <div className="from-equipment">
        <Label
          text={"From Equipment"}
          disabled={!copyCharacteristics}
        />
        <SelectrixWrapper
          testName={TEXT_NAME_PREFIX()}
          className="selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select copy-from"
          onChange={({ key }) => setFieldValue(name, key)}
          options={copyCharastersticsList}
          disabled={!equipmentTypeName || !copyCharacteristics}
          placeholder={optionsTranslated.selectPlaceholder}
          inputPlaceholder={optionsTranslated.findEquipment}
          searchable={true}
          searchBoxInside={true}
          searchIndex={false}
          disableStateVal={true}
          defaultValue={copyCharacteristicsFromEquipmentName}
          onClose={() => setFieldTouched(name)}
        />
      </div>
    </div>
  )
  return (
    <>
      <Formik
        onSubmit={handleCreateEquipment}
        initialValues={initialValues}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnChange={true}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {({ values, isValid, handleSubmit, setValues, errors }) => {
          refToHoldFormikValues.current = values
          const isDisableSave = Object.keys(errors).length
          const multipleDataSources =
            values.defaultDataSourceEquipmentNames?.length >
              AUTOMATION_ID_LENGTH ||
            values.defaultAutomationSystemNames?.length > AUTOMATION_ID_LENGTH
          return (
            <Modal
              testName={TEXT_NAME_PREFIX('createEqModal')}
              gray={!modalOpen}
              heading={
                !modalOpen
                  ? optionsTranslated.create
                  : optionsTranslated.created
              }
              className={'create-equipment-dialog'}
              buttons={
                !modalOpen
                  ? [
                      {
                        text: "Save",
                        handleClick: () => {
                          handleSubmit()
                          trackEvent(
                            USER_EVENTS.EQUIPMENT_SETUP.events.CREATE_EQUIPMENT
                          )
                        },
                        type: 'confirm',
                        disabled:
                          (isDisableSave && !isValid) ||
                          (values.sourceTypeId === TIS_OBJECT &&
                            (!values.loadValvepropertyOption ||
                              values.selectedLoadValeEquip.length == 0))
                      },
                      {
                        text: t('common:SaveAndContinue', 'SaveAndContinue'),
                        handleClick: () => {
                          setIsSaveAndContinue(true)
                          handleSubmit()
                          trackEvent(
                            USER_EVENTS.EQUIPMENT_SETUP.events
                              .CREATE_SAVE_EQUIPMENT
                          )
                        },
                        type: 'confirm',
                        disabled:
                          (isDisableSave && !isValid) ||
                          multipleDataSources ||
                          values?.equipmentTypeName ===
                            EQUIPMENT_TYPES.LOAD_VALVE
                      },
                      {
                        text: t('common:Cancel'),
                        handleClick: () => handleClose(false),
                        type: 'cancel'
                      }
                    ]
                  : [
                      {
                        text: t('common:Close'),
                        handleClick: () => handleClose(true)
                      }
                    ]
              }
              handleClose={() => handleClose(false)}
              formHasRequiredFields={!modalOpen}
            >
              {modalOpen ? (
                <RenderSuccessModalView
                  values={values}
                  multipleDataSources={multipleDataSources}
                  copyCharastersticsList={copyCharastersticsList}
                  isNaxConnectivity={isNaxConnectivity}
                  equipmentsList={equipmentsList}
                  getEquipmentTypeName={getEquipmentTypeName}
                />
              ) : (
                <Form>
                  <div className="rx-grid rx-grid-small-spacing">
                    <div className="rx-col-auto left-column">
                      <EditableView
                        testName={TEXT_NAME_PREFIX('eqName')}
                        type={EditableView.COMPONENT_TYPE.SELECTRIX}
                        className="equipment-type required selectrix-wrapper--single-select"
                        labelName={"TIS Equipment Type"}
                        name="equipmentTypeName"
                        placeholder={optionsTranslated.selectPlaceholder}
                        inputPlaceholder={optionsTranslated.findEquipment}
                        defaultValue={
                          values.equipmentTypeName
                            ? values.equipmentTypeName
                            : false
                        }
                        onChange={(
                          { key: equipmentTypeName },
                          { setValues, values }
                        ) => {
                          setValues({
                            ...values,
                            defaultAutomationSystemNames: isNaxConnectivity
                              ? []
                              : values.defaultAutomationSystemNames,
                            equipmentName: isNaxConnectivity
                              ? ''
                              : values.equipmentName,
                            overrideEquipmentName: isNaxConnectivity
                              ? false
                              : values.overrideEquipmentName,
                            copyCharacteristics: false,
                            equipmentTypeName: equipmentTypeName,
                            parentEquipmentTypeName: undefined,
                            parentEquipmentName: undefined,
                            copyCharacteristicsFromEquipmentName: undefined
                          })
                          const selectedObject =
                            getSelectedEquipmentTypeObject(equipmentTypeName)
                          const type = selectedObject['type'] ?? ''
                          setEquipmentType(equipmentTypeName)
                          setSelectedEquipmentType(type)
                          setParentTISEquipmentsList([])
                        }}
                        options={equipmentTypeList}
                      />
                      {
                        // Chiller specific fields
                        equipmentType === EQUIPMENT_TYPES.CHILLER ? (
                          <>
                            <EditableView
                              testName={TEXT_NAME_PREFIX('condenserType')}
                              type={EditableView.COMPONENT_TYPE.SELECT}
                              className="chiller-condenser-type required"
                              labelName={"Condenser Type"}
                              showErrors={false}
                              name="condenserType"
                              disabled={
                                EQUIPMENT_TYPES.CHILLER !== equipmentType
                              }
                              value={
                                condenserTypes.find(
                                  ({ key }) => key === values.condenserType
                                )?.value
                              }
                              options={condenserTypes}
                              onChange={(
                                condenserType,
                                { values, setValues }
                              ) => {
                                setTimeout(() => {
                                  setValues({
                                    ...values,
                                    condenserType,
                                    compressorType: undefined,
                                    chillerType: undefined
                                  })
                                })
                              }}
                            />
                            <EditableView
                              testName={TEXT_NAME_PREFIX('compressorType')}
                              type={EditableView.COMPONENT_TYPE.SELECT}
                              className="chiller-compressor-type required"
                              labelName={"Compressor Type"}
                              name="compressorType"
                              disabled={!values.condenserType}
                              value={
                                compressorTypes.find(
                                  ({ key }) => key === values.compressorType
                                )?.value
                              }
                              options={compressorTypes}
                              onChange={(
                                compressorType,
                                { values, setValues, setTouched, touched }
                              ) => {
                                setTimeout(() => {
                                  setValues({
                                    ...values,
                                    compressorType,
                                    chillerType: undefined
                                  })
                                  setTouched({
                                    ...touched,
                                    compressor1BNominalCapacity: true,
                                    compressor1CNominalCapacity: true,
                                    compressor2ANominalCapacity: true,
                                    compressor2BNominalCapacity: true,
                                    compressor2CNominalCapacity: true
                                  })
                                })
                              }}
                            />
                            <EditableView
                              testName={TEXT_NAME_PREFIX('Circuit1')}
                              type={EditableView.COMPONENT_TYPE.GROUP}
                              className="chiller-circuit-1 required"
                              labelName={"Circuit 1"}
                              showErrors={false}
                              disabled={!values.compressorType}
                              component={() => (
                                <div className="rx-grid rx-direction-row rx-grid-bleed-y rx-grid-fit-x rx-no-wrap">
                                  <EditableView
                                    testName={TEXT_NAME_PREFIX('Comp1A')}
                                    className="rx-col-auto circuit-name"
                                    placeholder={"Comp 1A"}
                                    type={EditableView.COMPONENT_TYPE.INPUT}
                                    inputType={EditableView.INPUT_TYPE.NUMBER}
                                    name="compressor1ANominalCapacity"
                                    value={values.compressor1ANominalCapacity}
                                    onChange={(
                                      compressor1ANominalCapacity,
                                      { setValues }
                                    ) => {
                                      setTimeout(() => {
                                        if (!compressor1ANominalCapacity) {
                                          setValues({
                                            ...values,
                                            compressor1ANominalCapacity,
                                            compressor2ANominalCapacity: '',
                                            compressor2BNominalCapacity: '',
                                            compressor2CNominalCapacity: ''
                                          })
                                        }
                                      })
                                    }}
                                  />
                                  <EditableView
                                    testName={TEXT_NAME_PREFIX('Comp1B')}
                                    className="rx-col-auto"
                                    placeholder={"Comp 1B"}
                                    type={EditableView.COMPONENT_TYPE.INPUT}
                                    inputType={EditableView.INPUT_TYPE.NUMBER}
                                    name="compressor1BNominalCapacity"
                                    value={values.compressor1BNominalCapacity}
                                    onChange={(_, { setTouched, touched }) => {
                                      setTimeout(() => {
                                        setTouched({
                                          ...touched,
                                          compressor1ANominalCapacity: true
                                        })
                                      })
                                    }}
                                  />
                                  <EditableView
                                    testName={TEXT_NAME_PREFIX()}
                                    className="rx-col-auto"
                                    placeholder={"Comp 1C"}
                                    type={EditableView.COMPONENT_TYPE.INPUT}
                                    inputType={EditableView.INPUT_TYPE.NUMBER}
                                    name="compressor1CNominalCapacity"
                                    value={values.compressor1CNominalCapacity}
                                    onChange={(_, { setTouched, touched }) => {
                                      setTimeout(() => {
                                        setTouched({
                                          ...touched,
                                          compressor1ANominalCapacity: true
                                        })
                                      })
                                    }}
                                  />
                                </div>
                              )}
                            />
                            <EditableView
                              testName={TEXT_NAME_PREFIX('Circuit2')}
                              type={EditableView.COMPONENT_TYPE.GROUP}
                              className="chiller-circuit-2"
                              labelName={"Circuit 2"}
                              showErrors={false}
                              disabled={
                                !values.compressorType ||
                                !isNum(values.compressor1ANominalCapacity)
                              }
                              component={() => (
                                <div className="rx-grid rx-direction-row rx-grid-bleed-y rx-grid-fit-x rx-no-wrap">
                                  <EditableView
                                    testName={TEXT_NAME_PREFIX('Comp2A')}
                                    className="rx-col-auto circuit-name"
                                    placeholder={"Comp 2A"}
                                    type={EditableView.COMPONENT_TYPE.INPUT}
                                    inputType={EditableView.INPUT_TYPE.NUMBER}
                                    name="compressor2ANominalCapacity"
                                    value={values.compressor2ANominalCapacity}
                                  />
                                  <EditableView
                                    testName={TEXT_NAME_PREFIX('Comp2B')}
                                    className="rx-col-auto"
                                    placeholder={"Comp 2B"}
                                    type={EditableView.COMPONENT_TYPE.INPUT}
                                    inputType={EditableView.INPUT_TYPE.NUMBER}
                                    name="compressor2BNominalCapacity"
                                    value={values.compressor2BNominalCapacity}
                                  />
                                  <EditableView
                                    testName={TEXT_NAME_PREFIX('Comp2C')}
                                    className="rx-col-auto"
                                    placeholder={"Comp 2C"}
                                    type={EditableView.COMPONENT_TYPE.INPUT}
                                    inputType={EditableView.INPUT_TYPE.NUMBER}
                                    name="compressor2CNominalCapacity"
                                    value={values.compressor2CNominalCapacity}
                                  />
                                </div>
                              )}
                            />
                          </>
                        ) : null
                      }

                      <EditableView
                        testName={TEXT_NAME_PREFIX('dfltAutoSystNames')}
                        type={EditableView.COMPONENT_TYPE.SELECT}
                        className="default-automation-system required"
                        labelName={"Default Automation System"}
                        name="defaultAutomationSystemNames"
                        disabled={
                          EQUIPMENT_TYPES.VIRTUAL_METER === equipmentType
                        }
                        placeholder={optionsTranslated.selectPlaceholder}
                        value={_get(
                          automationList.find(({ key }) =>
                            values.defaultAutomationSystemNames?.includes(key)
                          ),
                          'value'
                        )}
                        options={automationList}
                        onChange={(
                          defaultAutomationSystem,
                          { values, setValues }
                        ) => {
                          setValues({
                            ...values,
                            defaultAutomationSystemNames: [
                              defaultAutomationSystem
                            ],
                            defaultDataSourceTypeName: '',
                            defaultDataSourceEquipmentNames: [],
                            equipmentName: '',
                            overrideEquipmentName: false
                          })
                          setSelectedAutomationName(defaultAutomationSystem)
                          handleGetDataSourceTypeList(defaultAutomationSystem)
                        }}
                      />
                      <EditableView
                        testName={TEXT_NAME_PREFIX('defaultDataSrcType')}
                        type={EditableView.COMPONENT_TYPE.SELECT}
                        className="default-data-source-type required"
                        labelName={"Default Data Source Type"}
                        name="defaultDataSourceTypeName"
                        placeholder={optionsTranslated.selectPlaceholder}
                        value={values?.defaultDataSourceTypeName}
                        options={dataSourceTypeList}
                        onChange={(
                          defaultDataSourceTypeName,
                          { values, setValues }
                        ) => {
                          setDataSourceType(defaultDataSourceTypeName)
                          setSelectedProperty('')
                          handleGetDataSourceList(
                            values?.defaultAutomationSystemNames[0],
                            defaultDataSourceTypeName,
                            values?.includeUnmappedDataSourceOnly
                          )
                          setTimeout(() => {
                            setValues({
                              ...values,
                              defaultDataSourceTypeName,
                              defaultDataSourceEquipmentNames: [],
                              equipmentName: '',
                              overrideEquipmentName: false
                            })
                          })
                        }}
                        disabled={
                          EQUIPMENT_TYPES.VIRTUAL_METER === equipmentType ||
                          (!values.defaultAutomationSystemNames &&
                            !isSummitConnectivity)
                        }
                      />
                      <EditableView
                        testName={TEXT_NAME_PREFIX('defaultDataSrcEpNames')}
                        name="defaultDataSourceEquipmentNames"
                        className="default-data-source"
                        component={defaultDataSourceComponent}
                      />
                      {!isNaxConnectivity &&
                      EQUIPMENT_TYPES.ANALOG_POINT === equipmentType ? (
                        <DefaultDataSourcePropertySelect
                          defaultDataSourceEquipmentNames={
                            values.defaultDataSourceEquipmentNames
                          }
                          defaultDataSourcePropertyId={
                            values.defaultDataSourcePropertyId
                          }
                          dataSourceProperties={defaultPropertyList}
                          setSelectedProperty={setSelectedProperty}
                          subPropertyOptions={subPropertyOptions}
                          setSelectedSubProperty={setSelectedSubProperty}
                          selectedSubProperty={selectedSubProperty}
                        />
                      ) : null}
                      {!isNaxConnectivity &&
                      // Analog Point specific fields
                      EQUIPMENT_TYPES.ANALOG_POINT === equipmentType ? (
                        <UnitOfMeasurementSelect
                          dimensionList={dimensionList}
                          targetUOMList = {targetUOMList}
                          unitOfMeasureList={sourceUOMList}
                          dataSourceProperty={defaultDataSourcePropertyList.find(
                            (data) => {
                              return (
                                data?.sourceDisplayName ===
                                values.defaultDataSourcePropertyId
                              )
                            }
                          )}
                          values={values}
                          setValues={setValues}
                          setSelectedDimension={setSelectedDimension}
                          selectedProperty={selectedProperty}
                          selectedDimension={selectedDimension}
                        />
                      ) : null}
                    </div>
                    <div className="rx-col-auto right-column">
                      <div className="info-text">
                        <Label text={optionsTranslated.choices} />
                        <EditableView
                          testName={TEXT_NAME_PREFIX('overridePrntEqGrp')}
                          type={EditableView.COMPONENT_TYPE.GROUP}
                          className="override-parent-equipment-name-group"
                          showErrors={false}
                          component={({
                            formik: {
                              values: {
                                overrideParentEquipment,
                                equipmentTypeName
                              },
                              setFieldValue
                            }
                          }) => (
                            <div className="rx-grid rx-grid-bleed-y rx-grid-fit-x">
                              <EditableView
                                testName={TEXT_NAME_PREFIX(
                                  'ParentTISEquipmentType'
                                )}
                                type={EditableView.COMPONENT_TYPE.SELECT}
                                className="parent-equipment-name rx-col-auto"
                                labelName={"Parent TIS Equipment Type"}
                                name="parentEquipmentTypeName"
                                disabled={!overrideParentEquipment}
                                value={_get(
                                  parentTISEquipmentTypeList.find(
                                    ({ key }) =>
                                      key === values.parentEquipmentTypeName
                                  ),
                                  'value'
                                )}
                                options={parentTISEquipmentTypeList}
                                onChange={(
                                  parentEquipmentTypeName,
                                  { values, setValues, setFieldValue }
                                ) => {
                                  getParentTISEquipments({
                                    buildingId,
                                    type: parentEquipmentTypeName
                                  })
                                  setTimeout(() => {
                                    setFieldValue(
                                      'parentEquipmentName',
                                      undefined
                                    )
                                    setValues({
                                      ...values,
                                      parentEquipmentTypeName
                                    })
                                  })
                                }}
                              />
                              <Checkbox
                                testName={TEXT_NAME_PREFIX(
                                  'overridePrntEqName'
                                )}
                                customClassName="rx-col-nogrow"
                                label={"Override"}
                                isChecked={overrideParentEquipment}
                                id="override-parent-equipment-name"
                                disabled={
                                  EQUIPMENT_TYPES.VIRTUAL_METER ===
                                    equipmentType || !equipmentTypeName
                                }
                                toggleCheck={(event) =>
                                  setFieldValue(
                                    'overrideParentEquipment',
                                    event.target.checked
                                  )
                                }
                              />
                            </div>
                          )}
                        />
                        <EditableView
                          testName={TEXT_NAME_PREFIX('parentEquipmentName')}
                          type={EditableView.COMPONENT_TYPE.SELECT}
                          className="parent-equipment"
                          disabled={!values.overrideParentEquipment}
                          labelName={"Parent TIS Equipment"}
                          name="parentEquipmentName"
                          value={_get(
                            parentTISEquipmentsList.find(
                              ({ key }) => key === values.parentEquipmentName
                            ),
                            'value'
                          )}
                          options={parentTISEquipmentsList}
                          onChange={(parentEquipmentName, { values }) => {
                            setTimeout(() => {
                              setValues({
                                ...values,
                                parentEquipmentName
                              })
                            })
                          }}
                        />
                        {(EQUIPMENT_TYPES.LOAD_VALVE !==
                          getEquipmentTypeName(values) ||
                          (isNaxConnectivity &&
                            values.sourceTypeId === SITE_OBJECT)) &&
                          ((isNaxConnectivity && multipleDataSources) ||
                          !multipleDataSources ? (
                            <EditableView
                              testName={TEXT_NAME_PREFIX(
                                'overridePrntEqNameGrp'
                              )}
                              className="override-equipment-name-group"
                              type={EditableView.COMPONENT_TYPE.GROUP}
                              showErrors={false}
                              component={({
                                formik: {
                                  values: {
                                    equipmentName,
                                    overrideEquipmentName,
                                    defaultDataSourceEquipmentNames,
                                    defaultDataSourceTypeName
                                  },
                                  setFieldValue
                                }
                              }) => (
                                <div className="rx-grid rx-direction-row rx-grid-bleed-y rx-grid-fit-x rx-no-wrap">
                                  <EditableView
                                    testName={TEXT_NAME_PREFIX(
                                      'TISEquipmentName'
                                    )}
                                    type={EditableView.COMPONENT_TYPE.INPUT}
                                    className={`equipment-name rx-col-auto`}
                                    labelName={"TIS Equipment Name"}
                                    name="equipmentName"
                                    disabled={
                                      !(
                                        EQUIPMENT_TYPES.VIRTUAL_METER ===
                                        equipmentType
                                      ) &&
                                      defaultDataSourceTypeName !== NONE &&
                                      !overrideEquipmentName
                                    }
                                    value={equipmentName}
                                  />
                                  {defaultDataSourceTypeName !== NONE && (
                                    <Checkbox
                                      testName={TEXT_NAME_PREFIX(
                                        'overrideEpName'
                                      )}
                                      customClassName="rx-col-nogrow"
                                      label={"Override"}
                                      isChecked={overrideEquipmentName}
                                      id="override-equipment-name"
                                      disabled={
                                        EQUIPMENT_TYPES.VIRTUAL_METER ===
                                          equipmentType ||
                                        (!defaultDataSourceEquipmentNames &&
                                          (!isNaxConnectivity ||
                                            defaultDataSourceEquipmentNames.length >
                                              AUTOMATION_ID_LENGTH))
                                      }
                                      toggleCheck={(event) =>
                                        setFieldValue(
                                          'overrideEquipmentName',
                                          event.target.checked
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              )}
                            />
                          ) : (
                            <>
                              <EditableView
                                testName={TEXT_NAME_PREFIX(
                                  'AppendToTisEquipmentName'
                                )}
                                type={EditableView.COMPONENT_TYPE.INPUT}
                                className={`equipment-name rx-col-auto parent-equipment`}
                                labelName={"Append to TIS Equipment Name"}
                                name="appendPrependText"
                                value={values.appendPrependText}
                                disabled={
                                  !values.defaultDataSourceEquipmentNames
                                }
                                showErrors={false}
                                onChange={(
                                  appendPrependText,
                                  { values, setValues }
                                ) => {
                                  setTimeout(() => {
                                    setValues({
                                      ...values,
                                      appendPrependText: appendPrependText,
                                      equipmentName: appendPrependText
                                    })
                                  })
                                }}
                              />
                              <EditableView
                                testName={TEXT_NAME_PREFIX('AppendLocation')}
                                type={EditableView.COMPONENT_TYPE.SELECT}
                                labelName={"Append Location"}
                                className={`equipment-name rx-col-auto parent-equipment`}
                                name="appendPrependOptionValue"
                                value={_get(
                                  LoadValveAppendOptionsTranslate.find(
                                    ({ key }) =>
                                      key === values.appendPrependOptionValue
                                  ),
                                  'value'
                                )}
                                options={LoadValveAppendOptionsTranslate}
                              />
                            </>
                          ))}
                        <EditableView
                          testName={TEXT_NAME_PREFIX('copy-from')}
                          name="copyCharacteristicsFromEquipmentName"
                          className={`copy-from ${
                            values.defaultDataSourceEquipmentNames?.length >
                              1 && 'required'
                          } `}
                          component={copyFromComponent}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Modal>
          )
        }}
      </Formik>
    </>
  )
}
