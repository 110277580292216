import _get from 'lodash/get'
import CONFIGURATION from './default'
// import isBetaImport from "stores/modules/beta.js"

/**
 * Master configuration file, which will return properties respective to each given environment.
 * These can still be overridden by using the server-side config.js
 */

/**
 * getConfig - Gets a configuration option, first from the window if available, then based on environment defaults
 *
 * @param {String} config The name of the configuration to lookup
 * @returns {*}
 */
export const getConfig = (config) => {
  const fileConfig = _get(CONFIGURATION, config)
  return typeof _get(window, 'config') !== 'undefined'
    ? _get(window, `config.${config}`) || fileConfig
    : fileConfig
}

export function isProduction() {
  return (
    typeof window !== 'undefined' &&
    window &&
    window.config &&
    window.config.environment &&
    (window.config.environment === 'production' ||
      window.config.environment === 'prod' ||
      window.config.environment === 'prd')
  )
}

export function isStaging() {
  return (
    typeof window !== 'undefined' &&
    window &&
    window.config &&
    window.config.environment &&
    (window.config.environment === 'staging' ||
      window.config.environment === 'stage' ||
      window.config.environment === 'stg')
  )
}

// note: prod !== staging
export const isProductionOrStaging = () =>
  typeof window !== 'undefined' &&
  window &&
  window.config &&
  window.config.environment &&
  (window.config.environment === 'production' ||
    window.config.environment === 'prod' ||
    window.config.environment === 'prd' ||
    window.config.environment === 'staging' ||
    window.config.environment === 'stage' ||
    window.config.environment === 'stg')

export const isBeta = () => false
