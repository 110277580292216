import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTheme } from '@aws-amplify/ui-react'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { isUndefined } from 'src/components/legacy/common/helpers'
import { useSelector } from 'react-redux'
import { selectUserInfo } from 'src/redux/slicers/appData'

export const StyledPermissionDenied = styled.div<{ colors: any }>`
  padding: 20px;
  background-color: ${(props) => props.colors.TBA_ALMOST2_WHITE};
  min-height: 100 %;
  .error-message {
    display: inline-flex;
    background: ${(props) => props.colors.WHITE};
    padding: 10px;
    border: 2px solid ${(props) => props.colors.RED};

    & .error-text {
      margin-left: 9px;
      margin-top: 4px;
      text-align: left;
      color: ${(props) => props.colors.RED};
    }
    & .icon {
      flex-shrink: 0;
      color: ${(props) => props.colors.RED};
      font-size: 22px;
    }
  }
`

export const AccessControl = (props) => {
  const userInfo = useSelector(selectUserInfo)
  const [error, setError] = useState<boolean>()
  const {
    tokens: { colors }
  } = useTheme()
  useEffect(() => {
    if (userInfo) setError(!Boolean(userInfo?.id))
  }, [userInfo])
  return isUndefined(error) ? (
    <Spinner />
  ) : error ? (
    <StyledPermissionDenied colors={colors}>
      <div className="error-message">
        <span className="icon icon-error" />
        <span className="error-text">
          Access denied. Access to the requested page has been denied. Please
          contact your Trane representative if you think there has been an
          error.
        </span>
      </div>
    </StyledPermissionDenied>
  ) : (
    <>{props.children}</>
  )
}
