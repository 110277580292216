import { useFormik } from 'formik'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import {
  DATE_FORMAT,
  BACKEND_DATE_FORMAT,
  BACKEND_DATE_TIME_FORMAT_TZ
} from 'src/components/legacy/common/time-helpers'
import { getField } from 'src/components/legacy/common/helpers'
import moment from 'moment'
import _sortBy from 'lodash/sortBy'
import { useTranslation } from 'react-i18next'
import { ACCEPTED_FILE_TYPES } from './constants'
import { useNavigate, useParams } from 'react-router-dom'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { Content } from 'src/components/layouts'
import { useBreakPoint } from 'src/hooks/breakPoint'
import Header from 'src/components/pageHeader/header'
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal'
import Label from 'src/components/legacy/components/label/label'
import AttachedFiles from 'src/components/multi-file-upload-with-table/attached-files.sc'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import { useState, useEffect, useCallback } from 'react'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import validationSchema from './formValidator'
import _isEmpty from 'lodash/isEmpty'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'

import {
  Column,
  HrTag,
  Input,
  ToggleWrapper,
  InformationPanel,
  PanelItem,
  PanelLabel,
  ToolbarWrapper,
  InlineEditForm,
  CustomContainer,
  SelectWrapper,
  DateWrapper,
  ColumnDateContainer,
  CustomHeader
} from './styles'
import Select from 'src/components/legacy/components/select/select'
import {
  documentTypeList,
  documentStatusList,
  getAttachedDetails,
  getDocTestName
} from './helper'
import {
  CREATE_DOCUMENT,
  GET_PRE_SIGNED_URL,
  CREATE_ATTACHMENT,
  GET_BUILDINGS_BY_ACCOUNT_ID,
  GET_BUILDINGS_BY_SALESOFFICE_ID,
  UPDATE_DOCUMENT,
  UPDATE_ATTACHMENT,
  GET_DOCUMENT_BY_ID,
  CREATE_ENTRY_WITH_ATTACHMENT,
  DELETE_ATTACHMENT
} from './graphql'
import { useAppSelector } from 'src/redux/store'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { newRefreshToken, selectUserInfo } from 'src/redux/slicers/appData'
import { useAttachment } from 'src/hooks/attachment'
import { ACTIONS } from 'src/constants'
import translate from 'src/common/translations'
import { useDispatch } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

const initialValues = {
  documentName: '',
  documentType: '',
  documentStatus: '',
  visibility: false,
  location: {},
  createdBy: '',
  createdDate: '',
  note: '',
  attachments: [],
  description: '',
  startDate: ''
}

const Documents = (props) => {
  const [t] = useTranslation()

  const optionsTranslated = {
    cancel: translate('Cancel'),
    save: translate('Save'),
    deleteFile: translate('Delete File'),
    confirmDelete: translate(`Are you sure you want to delete `),
    yes: translate('Yes'),
    no: translate('No'),
    replaceAttach: translate('Replace Existing Attachments'),
    uploadMultiple: translate(
      'You are attempting to upload one or more files that will replace existing attachments. Do you want to replace the existing attachments?'
    ),
    replace: translate('Replace'),
    replaceExist: translate(
      'You are attempting to upload one or more files that will replace existing attachments.'
    ),
    editDoc: translate('Edit Document'),
    addDoc: translate('Add Document'),
    visibility: translate('Visibility'),
    docType: translate('Document Type'),
    selectOne: translate('Select One'),
    docStatus: translate('Document Status'),
    findBuilding: translate(`Find building...`),
    noResults: translate('No results. Change or clear your text above.'),
    noFiles: translate('No files attached')
  }

  const navigate = useNavigate()
  const { width } = useBreakPoint()
  const dispatch = useDispatch()
  const userInfo: any = useAppSelector(selectUserInfo)
  const params = useParams()
  const MAX_LENGTH_OF_PROPOSED = 50
  const {
    values = initialValues,
    downloadFileFunc = () => {},

    mode
  } = props || {}

  const {
    organizationId,
    buildingId: location,
    salesOfficeId
  } = getSearchParams()
  const [locationId, setLocationId] = useState(location)
  const [buildings, setBuildings] = useState([])
  const [storedBuildingId, setStoredBuildingId] = useState('')
  const [confirm, setConfirm] = useState(null)
  const [deleteAttach, setDeleteAttach] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { copyFileFunc } = useAttachment()

  const goBack = useCallback(() => {
    navigate(`/reports?organization=${organizationId}&location=${location}`)
  }, [organizationId, location])

  const { data: buildingsList, refetch: refetchBuildingsList } = useQuery({
    query: GET_BUILDINGS_BY_ACCOUNT_ID,
    variables: { id: organizationId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listBuildingsByAccount.items'
  })

  const {
    data: salesOfficeBuildingsList,
    refetch: refetchSalesOfficeBuildingsList
  } = useQuery({
    query: GET_BUILDINGS_BY_SALESOFFICE_ID,
    variables: {
      id: salesOfficeId
    },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listBuildingsBySalesOffice.items'
  })

  const id = params?.id

  const { data: fetchedDocument, refetch: refetchDocument } = useQuery({
    query: GET_DOCUMENT_BY_ID,
    variables: { id },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getDocument'
  })

  const modifiedValues = {
    ...values,
    documentName: fetchedDocument?.title,
    documentType: fetchedDocument?.type,
    documentStatus: fetchedDocument?.status,
    visibility: fetchedDocument?.visibility,
    location: { locationId: fetchedDocument?.building?.id },
    createdBy: fetchedDocument?.contact,
    attachments: fetchedDocument?.attachments?.items,
    createdDate: moment(fetchedDocument?.createdAt).format(DATE_FORMAT),
    note: fetchedDocument?.note,
    description: fetchedDocument?.description,
    startDate: fetchedDocument?.startDate
      ? moment(fetchedDocument?.startDate).format('MM/DD/YYYY')
      : moment(fetchedDocument?.createdAt).format('MM/DD/YYYY')
  }
  const formik = useFormik({
    initialValues: modifiedValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const previousBuildingId = storedBuildingId
      const newBuildingId = formik?.values?.location?.locationId
      const bId = [...new Set([location, newBuildingId])]
      if (isSubmitting) return
      setIsSubmitting(true)
      if (_isEmpty(attachments)) return
      if (mode === ACTIONS.ADD) {
        const {
          documentStatus,
          documentType,
          documentName,
          visibility,
          location,
          createdDate,
          note,
          startDate
        } = values
        const input = {
          status: documentStatus,
          visibility,
          title: documentName,
          type: documentType,
          documentType,
          note,
          contact: `${userInfo?.firstName} ${userInfo?.lastName}`,
          buildingId: location?.locationId,
          accountId: organizationId || '',
          creationDate: moment(createdDate).format(BACKEND_DATE_FORMAT),
          startDate: startDate
            ? moment(startDate).format(BACKEND_DATE_FORMAT)
            : moment(createdDate).format(BACKEND_DATE_FORMAT),
          userId: userInfo?.id
        }
        try {
          await dispatch<any>(
            newRefreshToken(bId, null, null, [
              'AttachmentAdmin',
              'DocumentAdmin',
              'EnergyUsageReportAdmin'
            ])
          ).then(async () => {
            const response = await createDocument({ input })
            if (response?.id) {
              await Promise.all(
                values?.attachments
                  ?.filter((a) => !a.id)
                  .map((a) =>
                    createAttachmentMutation({
                      input: {
                        attachedAt: moment().format(
                          BACKEND_DATE_TIME_FORMAT_TZ
                        ),
                        buildingId: formik?.values?.location?.locationId,
                        description: a?.description,
                        name: a?.fileName,
                        title: a?.title,
                        documentId: response?.id,
                        sizeInBytes: a?.fileSize,
                        userId: values?.currentUserId,
                        type: a?.type
                      }
                    })
                  )
              )
            }
            updateAttachMents()
          })
        } catch (error) {
          console.log('error', error)
        }
        goBack()
      }
      if (mode === ACTIONS.EDIT) {
        const {
          documentStatus,
          documentType,
          documentName,
          visibility,
          location,
          createdDate,
          note,
          description,
          startDate
        } = values
        const input = {
          status: documentStatus,
          visibility,
          title: documentName,
          type: documentType,
          documentType,
          note,
          id: params?.id,
          contact: `${userInfo?.firstName} ${userInfo?.lastName}`,
          buildingId: location?.locationId,
          accountId: organizationId || '',
          creationDate: moment(createdDate).format(BACKEND_DATE_FORMAT),
          startDate: moment(startDate).format(BACKEND_DATE_FORMAT),
          description
        }
        try {
          await dispatch<any>(
            newRefreshToken(
              [formik?.values?.location?.locationId, storedBuildingId],
              null,
              null,
              ['AttachmentAdmin', 'DocumentAdmin', 'EnergyUsageReportAdmin']
            )
          )
          const previousAttach = fetchedDocument?.attachments?.items
          const newAttachment = []
          const attachmentBody = {
            modelType: 'Documents',
            attachments: {}
          }
          const response = await onUpdateDocument({ input })
          /** Create Attachments For Different building  */
          if (previousBuildingId !== newBuildingId) {
            if (response?.id) {
              await Promise.all(
                values?.attachments?.map(async (a) => {
                  if (a?.id) {
                    await copyFileFunc(
                      a?.name,
                      storedBuildingId,
                      formik?.values?.location?.locationId
                    )
                  }
                  const res = await createAttachmentMutation({
                    input: {
                      attachedAt: moment().format(BACKEND_DATE_TIME_FORMAT_TZ),
                      buildingId: formik?.values?.location?.locationId,
                      description: a?.description,
                      name: a?.fileName || a?.name,
                      title: a?.title,
                      documentId: response?.id,
                      sizeInBytes: a?.fileSize || a?.sizeInBytes,
                      userId: values?.currentUserId,
                      type: a?.type
                    }
                  })
                  newAttachment.push(res?.data?.createAttachment)
                })
              )
              setFieldValue('attachments', newAttachment)
              newAttachment.forEach((item) => {
                attachmentBody.attachments['create'] = [
                  ...(attachmentBody?.attachments?.['create'] || []),
                  {
                    buildingId: formik?.values?.location?.locationId,
                    name: item?.name || item?.fileName || item?.title
                  }
                ]
              })
              /** Delete attachment from old Building */
              previousAttach
                .filter((item) => item?.id)
                .forEach(async (item) => {
                  if (item?.id) {
                    await deleteAttachmentMutation({
                      input: {
                        id: item?.id
                      }
                    })
                    attachmentBody.attachments['delete'] = [
                      ...(attachmentBody?.attachments?.['delete'] || []),
                      {
                        attachmentId: item?.id,
                        buildingId: storedBuildingId,
                        name: item?.name
                      }
                    ]
                  }
                })

              //Update S3
              if (!_isEmpty(attachmentBody?.attachments)) {
                await createEntryWithAttachmentMutation({
                  input: JSON.stringify(attachmentBody)
                })
              }
            }
          } else {
            /** Create Attachments For Same building  */
            await Promise.all(
              values?.attachments?.map((a) => {
                const func = a.id
                  ? updateAttachmentMutation
                  : createAttachmentMutation
                func({
                  input: {
                    attachedAt: moment().format(BACKEND_DATE_TIME_FORMAT_TZ),
                    buildingId: formik?.values?.location?.locationId,
                    description: a?.description,
                    name: a?.fileName,
                    title: a?.title,
                    documentId: response?.id,
                    sizeInBytes: a?.fileSize,
                    userId: values?.currentUserId,
                    type: a?.type,
                    ...(a?.id && { id: a?.id })
                  }
                })
              })
            )
            if (deleteAttach?.length) {
              for (const item of deleteAttach) {
                if (item)
                  await deleteAttachmentMutation({ input: { id: item } })
              }
            }
            updateAttachMents()
          }
          // navigate(-1)
          goBack()
        } catch (error) {
          console.log('error', error)
        }
      }
      setIsSubmitting(false)
    }
  })

  useEffect(() => {
    if (mode === ACTIONS.EDIT) {
      setStoredBuildingId(fetchedDocument?.building?.id)
      refetchDocument()
    }
  }, [mode, fetchedDocument?.building?.id])

  const { onSubmit: createDocument } = useMutation({
    query: CREATE_DOCUMENT,
    dataPath: 'data.createDocument',
    onSuccess: () => {
      trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.SAVE_DOCUMENT, {
        mode: 'CREATE',
        'number of attachments': formik?.values?.attachments?.length,
        'building id': formik?.values?.location?.locationId
      })
    }
  })

  const { onSubmit: onUpdateDocument } = useMutation({
    query: UPDATE_DOCUMENT,
    dataPath: 'data.updateDocument',
    onSuccess: () => {
      trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.SAVE_DOCUMENT, {
        mode: 'EDIT',
        'number of attachments': formik?.values?.attachments?.length,
        'building id': formik?.values?.location?.locationId
      })
    }
  })

  const { onSubmit: getPreSignedUrlMutation } = useMutation({
    query: GET_PRE_SIGNED_URL
  })
  const { onSubmit: createAttachmentMutation } = useMutation({
    query: CREATE_ATTACHMENT
  })
  const { onSubmit: updateAttachmentMutation } = useMutation({
    query: UPDATE_ATTACHMENT
  })

  const { onSubmit: createEntryWithAttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })
  const { onSubmit: deleteAttachmentMutation } = useMutation({
    query: DELETE_ATTACHMENT
  })

  const updateAttachMents = async () => {
    // Upsert on S3 Bucket
    const createDocuments = formik?.values?.attachments
      ?.filter((a) => !a.id)
      .map((a) => ({
        buildingId: formik?.values?.location?.locationId,
        name: a?.fileName
      }))

    const deleteDocuments =
      formik?.values?.attachments
        ?.filter((a) => !a.id)
        .map((a) => ({
          attachmentId: a?.id,
          buildingId: formik?.values?.location?.locationId,
          name: a?.title
        })) ?? []

    const attachmentBody = {
      modelType: 'Documents',
      attachments: {}
    }

    if (createDocuments?.length)
      attachmentBody.attachments['create'] = createDocuments
    if (deleteDocuments?.length)
      attachmentBody.attachments['delete'] = deleteDocuments

    // Upsert on S3 bucket
    if (createDocuments?.length || deleteDocuments?.length) {
      await createEntryWithAttachmentMutation({
        input: JSON.stringify(attachmentBody)
      })
    }
  }

  const {
    setFieldValue,
    values: {
      documentName,
      visibility,
      attachments = [],
      createdDate,
      documentType,
      documentStatus,
      note,
      startDate
    } = {}
  } = formik

  // Get the building detail if individual location is selected in building picker
  const getLocationBuilding = (buildingListData) =>
    buildingListData?.find((building) => building?.key === locationId)

  // Set BuildingsList and individual building based on query response by org/salesoffice level
  const onChangeBuildingsData = (arrayList) => {
    const list = arrayList?.map((building) => ({
      key: building?.key,
      value: building?.value,
      searchableValue: building?.value
    }))
    setBuildings(_sortBy(list, 'value'))
    if (locationId) {
      const building = getLocationBuilding(arrayList)
      onBuildingChangeSelect(building || '')
    }
  }

  useEffect(() => {
    const listData = Array.isArray(buildingsList)
      ? buildingsList
      : Array.isArray(salesOfficeBuildingsList)
      ? salesOfficeBuildingsList
      : []

    if (listData.length > 0) {
      onChangeBuildingsData(listData)
    }
  }, [buildingsList, locationId, salesOfficeBuildingsList])

  useEffect(() => {
    if (organizationId) {
      refetchBuildingsList({ id: organizationId })
    }
  }, [organizationId])

  useEffect(() => {
    if (salesOfficeId) {
      refetchSalesOfficeBuildingsList({
        id: salesOfficeId
      })
    }
  }, [salesOfficeId])

  const toolbar = () => (
    <ToolbarWrapper className="toolbar-buttons">
      <button
        className="secondary"
        disabled={isSubmitting}
        type="reset"
        data-testid={getDocTestName('cancel-button')}
        onClick={() => {
          goBack()
        }}
      >
        {optionsTranslated.cancel}
      </button>
      <button
        className={`primary ${isSubmitting ? 'wait' : ''}`}
        type="submit"
        data-testid={getDocTestName('save-button')}
        disabled={isSubmitting}
      >
        {isSubmitting ? <div className="spinner" /> : optionsTranslated.save}
      </button>
    </ToolbarWrapper>
  )

  const onBuildingChangeSelect = ({ key = '', value = '' } = {}) => {
    if (key) {
      setLocationId(key)
      setFieldValue('location', {
        locationName: value,
        locationId: key
      })
    }
  }

  const onDescriptionChange = (e) => {
    setFieldValue(
      'attachments',
      attachments?.map((attachment) =>
        attachment?.title === e?.fileName
          ? { ...attachment, description: e?.value, descriptionChanged: true }
          : attachment
      )
    )
  }
  const handleFormSubmit = (e) => {
    e?.preventDefault()
    formik?.handleSubmit()
  }

  const addFileFunc = async (e) => {
    try {
      const res = await getPreSignedUrlMutation({
        input: `{"action": "UPLOAD", "key": "${e?.name}", "buildingId": "${formik?.values?.location?.locationId}" }`
      })
      const requestOptions: RequestInit = {
        method: 'PUT',
        body: e,
        redirect: 'follow',
        headers: { 'Content-Type': '' }
      }
      const response = await fetch(
        JSON.parse(res?.data?.getPreSignedUrl).body,
        requestOptions
      )
      return response
    } catch (error) {
      console.log('error', error)
    }
  }

  const onDeleteAttachment = (fileId, fileName) => {
    const updatedAttachments = attachments?.filter(
      (attachment) => attachment?.title !== fileName
    )
    setFieldValue('attachments', updatedAttachments)
    if (fileId) {
      const deleteAttachments = [...deleteAttach]
      deleteAttachments.push(fileId)
      setDeleteAttach(deleteAttachments)
    }
  }
  const showConfirm = (fileId, fileName) => {
    setConfirm({
      title: optionsTranslated.deleteFile,
      text: optionsTranslated.confirmDelete + `${fileName}?`,
      className: 'confirm-delete-dialog',
      confirmText: optionsTranslated.yes,
      cancelText: optionsTranslated.no,
      handleConfirm: () => {
        setConfirm(null)
        onDeleteAttachment(fileId, fileName)
      }
    })
  }
  const warnBeforeReplace = (callback) => {
    setConfirm({
      title: optionsTranslated.replaceAttach,
      text: optionsTranslated.uploadMultiple,
      confirmText: optionsTranslated.replace,
      cancelText: optionsTranslated.cancel,
      handleConfirm: () => {
        callback()
        setConfirm(null)
      },
      handleCancel: () => {
        callback(optionsTranslated.replaceExist)
        setConfirm(null)
      }
    })
  }

  const onChangeUploadStatus = (newFiles) => {
    const attachmentCopy = cloneDeep(attachments)
    const newFilesCopy = cloneDeep(newFiles)
    const locationIdCopy = cloneDeep(formik?.values?.location?.locationId)

    const attachFiles = getAttachedDetails(
      newFilesCopy,
      attachmentCopy,
      locationIdCopy
    )
    formik?.setFieldValue('attachments', attachFiles)
  }
  const onChangeDate = (path) => (date) => {
    date = date.target ? date.target.value : moment(date).format(DATE_FORMAT)
    setFieldValue(path, date)
    setFieldValue('startDate', date)
    setFieldValue('createdDate', date)
  }
  const { errors, touched } = formik
  return (
    <InlineEditForm data-testid="document-new">
      <form onSubmit={handleFormSubmit} className="inline-edit-form">
        <CustomContainer
          sColumns={12}
          mColumns={12}
          lColumns={12}
          xlColumns={12}
        >
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <CustomHeader data-testid="document-new-header">
              <Header
                hideToolBar={true}
                pageTitle={
                  mode === ACTIONS.EDIT
                    ? optionsTranslated.editDoc
                    : optionsTranslated.addDoc
                }
              />
            </CustomHeader>
          </Content>

          {/* first section */}
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <Column columns="1fr 1fr">
              <div
                data-testid="document-name"
                className={`doc-name ${
                  errors?.documentName && touched?.documentName
                    ? 'invalid'
                    : undefined
                }`}
              >
                <label>{`${t('reports:DocName')}*`}</label>
                <br />
                <Input
                  id="doc-name"
                  type="text"
                  maxLength={MAX_LENGTH_OF_PROPOSED}
                  value={documentName}
                  onChange={(event) =>
                    setFieldValue('documentName', event?.target?.value)
                  }
                />
                {errors?.documentName && touched?.documentName && (
                  <p className="error">{t('reports:DocNameEmpty')}</p>
                )}
              </div>
              <ToggleWrapper className="toggle-button">
                <label>{optionsTranslated.visibility}:</label>
                <ToggleButtonHorizontal
                  id="doc-visibility"
                  name="visibleToCustomer"
                  checked={visibility}
                  testId="doc-visibility"
                  onChange={(e) =>
                    setFieldValue('visibility', e?.target?.checked)
                  }
                />
              </ToggleWrapper>
            </Column>
          </Content>

          {/* second section */}
          <Content
            xlColumn={9}
            lColumn={9}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <ColumnDateContainer columns="0fr 0fr 0fr">
              <DateWrapper
                width={width}
                className={
                  getField(errors, 'startDate')
                    ? 'proposal-date error'
                    : 'proposal-date'
                }
              >
                <ModalDatePickerInput
                  date={
                    startDate ??
                    (moment(createdDate).isValid()
                      ? moment(createdDate).format(DATE_FORMAT)
                      : '')
                  }
                  onChange={onChangeDate('createdDate')}
                  isRequired={false}
                  labelText={`${t('opportunities:CreatedDate')}*`}
                  hasError={Boolean(getField(errors, 'createdDate'))}
                  errorMessage={getField(errors, 'createdDate')}
                />
              </DateWrapper>

              <SelectWrapper width={width}>
                <label>{`${optionsTranslated.docType}*`}</label>
                <Select
                  testName={getDocTestName('documentType')}
                  placeholder={'— ' + optionsTranslated.selectOne + ' —'}
                  selectedItem={documentTypeList(t)[documentType]}
                  onChange={(event) => setFieldValue('documentType', event)}
                  options={documentTypeList(t)}
                  className="dropdown-select"
                />
                {errors?.documentType && touched?.documentType && (
                  <p className="error">{t('reports:DocTypeEmpty')}</p>
                )}
              </SelectWrapper>
              <SelectWrapper width={width}>
                <label>{`${optionsTranslated.docStatus}*`}</label>
                <Select
                  testName={getDocTestName('documentStatus')}
                  placeholder={'— ' + optionsTranslated.selectOne + ' —'}
                  selectedItem={documentStatusList(t)[documentStatus]}
                  onChange={(value) => setFieldValue('documentStatus', value)}
                  options={documentStatusList(t)}
                  className="dropdown-select"
                />
                {errors?.documentStatus && touched?.documentStatus && (
                  <p className="error">{t('reports:DocStatusEmpty')}</p>
                )}
              </SelectWrapper>
            </ColumnDateContainer>
          </Content>

          {/* Third section */}
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <Content xlColumn={9} lColumn={9} border="none" width={width}>
              <Column columns="1fr 1fr">
                <div className="doc-name">
                  <label>{t('opportunities:Building')}:*</label>
                  <SelectrixWrapper
                    testName={getDocTestName('location')}
                    arrow={true}
                    className="location selectrix-wrapper--type-autocomplete"
                    customKeys={{ key: 'key', label: 'value' }}
                    defaultValue={
                      mode && mode === ACTIONS.EDIT
                        ? formik?.values?.location?.locationId
                        : locationId
                    }
                    disableStateVal={true}
                    inputPlaceholder={optionsTranslated.findBuilding}
                    notFoundPrompt={optionsTranslated.noResults}
                    onChange={onBuildingChangeSelect}
                    options={buildings}
                    placeholder={'— ' + optionsTranslated.selectOne + ' —'}
                    searchable={true}
                    searchBoxInside={true}
                    searchIndex={false}
                    value={formik?.values?.location?.locationId}
                  />
                  {touched?.location &&
                    getField(errors, 'location.locationId') && (
                      <p className="error">{t('reports:Locations')}</p>
                    )}
                </div>
                <div>
                  <InformationPanel>
                    <PanelItem>
                      <PanelLabel>Created By</PanelLabel>
                      <div
                        data-testid={getDocTestName('created-by')}
                      >{`${userInfo?.firstName} ${userInfo?.lastName}`}</div>
                    </PanelItem>
                  </InformationPanel>
                </div>
              </Column>
            </Content>
            <HrTag className="doc-seperator-two" />
          </Content>

          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            width={width}
            border="none"
          >
            <div
              className="attached-files"
              data-testid={getDocTestName('attach-files')}
            >
              <Label
                text={`${t('notes:AttachedFiles')}* ${
                  formik?.values?.attachments?.length
                    ? `(${formik?.values?.attachments?.length})`
                    : ''
                }`}
              />
              <AttachedFiles
                attachments={attachments?.map((item) => ({
                  ...item,
                  isEditable: true,
                  fileName: item?.title,
                  fileId: item?.id,
                  fileSize: item?.sizeInBytes
                }))}
                onBeforeReplaceAttachment={(e) => warnBeforeReplace(e)}
                onDeleteAttachment={(id, name) => showConfirm(id, name)}
                onDescriptionChange={(e) => onDescriptionChange(e)}
                onChangeUploadStatus={(files) => {
                  onChangeUploadStatus(files)
                }}
                addFile={(e) => addFileFunc(e)}
                downloadFileFunc={(e) => downloadFileFunc(e)}
                proposalFileTypes={ACCEPTED_FILE_TYPES}
              />
              {errors?.attachments &&
                touched?.attachments &&
                _isEmpty(attachments) && (
                  <p className="error">{optionsTranslated.noFiles}</p>
                )}
            </div>

            <TextAreaInput
              labelText="Supporting Notes"
              value={note}
              isRequired={false}
              maxLength={2000}
              placeholder=""
              onChange={(event) => {
                setFieldValue('note', event?.target?.value)
              }}
              wrapperClassName="wrapperClass"
              testName={getDocTestName('supporting-notes-')}
            />

            <HrTag className="doc-seperator-two" />
          </Content>

          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            width={width}
            border="none"
          >
            {toolbar()}
          </Content>
        </CustomContainer>
      </form>
      <StandardDialogs
        confirm={confirm}
        onCloseDialog={() => setConfirm(null)}
      />
    </InlineEditForm>
  )
}
export default Documents
