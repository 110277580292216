import app from './app'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { getField } from 'src/components/legacy/common/helpers'
import i18n from 'i18next'
import {
  STATUS_LIST,
  NEXT_STEPS_LIST,
  KPI_LIST,
  COST_SAVINGS_LIST
} from '../components/legacy/common/opportunity'

const DEFAULT_TIME_TO_IMPLEMENT_TAG = 'weeks'
const DEFAULT_PRIORITY = 'Not Prioritized'
const DEFAULT_NEXT_STEP = 'traneOfficeReview'

const DEFAULT_SETTINGS = {
  internalInfo: {
    opened: false,
    timeToFix: {
      estimated: {
        tag: DEFAULT_TIME_TO_IMPLEMENT_TAG
      },
      actual: {
        tag: DEFAULT_TIME_TO_IMPLEMENT_TAG
      }
    },
    note: ''
  },
  images: [],
  file: {
    id: null,
    description: ''
  },
  priority: DEFAULT_PRIORITY,
  nextStep: NEXT_STEPS_LIST[0],
  assignedTo: {
    key: '',
    firstName: '',
    lastName: ''
  },
  costSavings: COST_SAVINGS_LIST,
  kpi: KPI_LIST.map(({ name, priority, custom }) => ({
    name,
    value: false,
    priority,
    custom
  })),
  proposalDate: '',
  proposedBy: '',
  description: {
    blocks: [],
    entityMap: {}
  }
}
interface IOrganization {
  organizationId: string
  organizationName: string
}
interface ILocation {
  locationId: string
  locationName: string
  tisTraneOfficeId: string
}
interface IAssignedTo {
  key: string
}
class Opportunity {
  opportunityId: string
  organization: IOrganization
  location: ILocation
  tisObjects: any
  name: string
  comment: string
  isPublic: boolean
  publishedTimestamp: any
  lastChangeTimestamp: any
  status: string
  nextStep: string
  priority: string
  settings: any
  modifiedByUserName: string
  findings: any
  createdBy: string
  assignedTo: IAssignedTo

  constructor(resource: any = {}) {
    const userCredentials =
      app && app.userInfo
        ? {
            firstName: app.userInfo.firstName,
            lastName: app.userInfo.lastName
          }
        : {}

    const {
      opportunityId,
      organizationId,
      organizationName,
      locationId,
      proposedBy,
      proposalDate,
      locationName,
      tisObjects = [],
      name = '',
      isPublic = true,
      publishedTimestamp = null,
      lastChangeTimestamp = null,
      status = STATUS_LIST[0],
      nextStep = DEFAULT_NEXT_STEP,
      priority = DEFAULT_PRIORITY,
      settings = {},
      modifiedByUserName,
      findings = [],
      createdBy = userCredentials,
      comment,
      assignedTo = {},
      file = {}
    } = resource

    DEFAULT_SETTINGS.proposedBy = proposedBy
    DEFAULT_SETTINGS.proposalDate = proposalDate
    DEFAULT_SETTINGS.file = file
    this.assignedTo = assignedTo
    this.opportunityId = opportunityId
    this.organization = {
      organizationId:
        getField(resource, 'organization.organizationId') || organizationId,
      organizationName:
        getField(resource, 'organization.organizationName') || organizationName
    }
    this.location = {
      locationId: getField(resource, 'location.locationId') || locationId,
      locationName: getField(resource, 'location.locationName') || locationName,
      tisTraneOfficeId: getField(resource, 'location.tisTraneOfficeId')
    }
    this.tisObjects = tisObjects
    this.name = name
    this.comment = comment
    this.isPublic = Boolean(isPublic)
    this.publishedTimestamp = publishedTimestamp
    this.lastChangeTimestamp = lastChangeTimestamp
    this.status = status
    this.nextStep = nextStep
    this.priority = priority
    this.settings = { ...DEFAULT_SETTINGS, ...pickBy(settings, identity) }
    this.modifiedByUserName = modifiedByUserName
    this.findings = findings
    this.createdBy = createdBy
  }

  clone() {
    try {
      const opportunityCopy = JSON.parse(JSON.stringify(this))
      opportunityCopy.name = i18n.t('common:CopyOf', {
        label: opportunityCopy.name
      })
      opportunityCopy.opportunityId = null
      opportunityCopy.publishedTimestamp = new Date()
      opportunityCopy.lastChangeTimestamp = null
      opportunityCopy.proposedBy = DEFAULT_SETTINGS.proposedBy
  
      return new Opportunity(opportunityCopy)
    } catch(error){
      
    }
    
  }
}

export default Opportunity
