import { Fragment, useMemo } from 'react'
import {
  selectAxes,
  selectCanvasDetails,
  selectGeneratedScales,
  selectScales,
  selectSeriesData,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
import { getBandWidth } from '../helper'
import { isNumber } from 'lodash'

const ColumnRenderer = (props: any) => {
  const { types = [], canvasIndex = '' } = props
  const generatedScales = useSelector(selectGeneratedScales)
  const axes = useSelector(selectAxes)
  const seriesData = useSelector(selectSeriesData)
  const column = types?.[0]
  const xObject =  (axes || [])?.find((obj: any) => obj?.key === column?.xAxisKey) 
  const xData = xObject?.scale?.categories
  const gXScaleObj = generatedScales?.[column?.xAxisKey]
  const gYScaleObj = generatedScales?.[column?.yAxisKey]
  const xScale = gXScaleObj?.gScale
  const yScale = gYScaleObj?.gScale
  const yData = seriesData?.[column?.seriesKey]?.data
  const xscaleType = xObject?.scale?.props?.type
  const canvasDetails: any = useSelector(selectCanvasDetails)
  const { paddingHeight } = useMemo(() => {
    const canvasDim = canvasDetails?.[canvasIndex]
    return {
      paddingHeight: canvasDim?.height
    }
  }, [canvasDetails, canvasIndex])

  return (
    <>
      {xData?.map((val: any, i: number) => {
        const x =  xScale ? xScale(xscaleType === 'scaleTime' ? new Date(val) : val) - (xscaleType === 'scaleTime' ? (10/2) : 0) : null
        const y =  yScale ? yData?.[i] < 0 ?  yScale(0) : yScale(yData?.[i]) : null
        const height = yData?.[i] < 0 ? paddingHeight - y : yScale(0) - y
        const width = xscaleType === 'scaleTime' ? 10 : (getBandWidth(xScale) || 10)

        if (isNumber(x) && isNumber(y)) {
          return (
            <rect
              key={i}
              x={x}
              y={y}
              height={height}
              width={width}
              fill={column?.elementProps?.fill}
            />
          )
        } else {
          return <Fragment key={i}></Fragment>
        }
      })}
    </>
  )
}

export default ColumnRenderer
