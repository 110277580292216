import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import CheckboxCustom from "./custom-checkbox"

export const InputWrapper = styled.div`
	margin: ${props => props.marginWrapper || "10px 0;"};
	width: 100%;
`
export const Label = styled.span`
	font-weight: ${props => props.labelBold ? "bold" : "normal"};
  font-size: 1.083em;
  line-height: 1.25em;
	letter-spacing: 0.022em;
	padding-left: 5px;
`

export default class Checkbox extends Component {
	static propTypes = {

		/** Sets the checked value of the input, not checked by default */
		isChecked: PropTypes.bool,

		/** Text for the checkbox label */
		label: PropTypes.string,

		/** Id for input */
		id: PropTypes.string.isRequired,

		/** Sets the margin for the whole control. Use CSS short-hand for setting margin, i.e. "0 10px 0 10px" */
		marginWrapper: PropTypes.string,

		/** Makes label text bold */
		labelBold: PropTypes.bool,

		/** Sets the look of the checkbox ## NEEDS TO BE ADDED */
		status: PropTypes.string,

		/** Function to be passed into the React input's onChange functionality */
		action: PropTypes.func,

		disableChange: PropTypes.bool,

		checkedColor: PropTypes.string,
	}

	static defaultProps = {
		labelBold: true,
		status: "active"
	}

	state = {
		checked: false
	}

	static getDerivedStateFromProps(nextProps) {
		return {
			checked: nextProps.isChecked ? nextProps.isChecked : false
		}
	}

	handleCheckboxChange = event => {
		if	(!this.props.disableChange) {
			this.setState({ checked: event.target.checked }, () => this.props.action && this.props.action())
		}
	}

	render() {
		const { label, id, disableChange } = this.props
		const { checked } = this.state
		return (
			<InputWrapper marginWrapper={this.props.marginWrapper}>
				<label>
					<CheckboxCustom
						id={id}
						checked={checked}
						onChange={this.handleCheckboxChange}
						status={this.props.status}
						disableChange={disableChange}
						checkedColor={this.props.checkedColor}
					/>
					<Label labelBold={this.props.labelBold} status={this.props.status}>{label}</Label>
				</label>
			</InputWrapper>
		)
	}
}
