/**
 * Form Control :
 *
 */
import { css } from 'styled-components'
import { SystemThemeProps } from '../../utils'

// ---------------------------------------------- Styles ----------------------------------------------

const formControlState = ({ disabled, error, focused, ...rest }) => css`
  &.disabled {
    ${disabled}
  }
  &.error {
    ${error}
  }
  &.focused {
    ${focused}
  }
  ${{ ...rest }}
`

// default base styles of form control
const onBase = ({ disabled, error, focused, ...rest }) => css`
  align-items: center;
  background: #f9fafc;
  background: #f9fafc;
  border-radius: 6px;
  border: 0;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  color: #262626;
  display: inline-flex;
  min-width: 0;
  position: relative;
  ${formControlState({ disabled, error, focused, ...rest })}
`

const input = css`
  color: inherit;
  background: none;
  border: none;
  box-sizing: border-box;
  display: block;
  font: inherit;
  letter-spacing: 0.32px;
  line-height: 15px;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 8px 10px;
  width: 100%;
  min-height: 36px;
`

const icon = css`
  cursor: pointer;
  display: inline-flex;
  align-self: stretch;
  align-items: center;
  padding: 6px;
  user-select: none;
`

// ---------------------------------------------- Style Creator ----------------------------------------------

/**
 * Method to create styles dynamically for form control elements
 *
 * provides options to disable styles that are not required
 *
 * @export
 * @param {boolean} [
 * requiredStyle={
 *    base: false,
 *    helperText: false,
 *    icon: false,
 *    input: false,
 *    labelText: false
 * }]
 * @return {*}
 */
export function createFormControlStyle(requiredStyle) {
  requiredStyle = {
    base: false,
    helperText: false,
    icon: false,
    input: false,
    inputLabel: false,
    ...requiredStyle
  }
  return function formControlStyleFn({ theme }) {
    const fcTheme = theme[SystemThemeProps.FORM_CONTROL]

    const { base, label, helperText } = fcTheme

    const baseCss = requiredStyle.base && onBase(base)

    const helperTextCss =
      requiredStyle.helperText && formControlState(helperText)

    const iconCss = requiredStyle.icon && icon

    const inputCss = requiredStyle.input && input

    const inputLabelCss = requiredStyle.inputLabel && formControlState(label)

    return css`
      ${baseCss}
      ${helperTextCss}
      ${iconCss}
      ${inputCss}
      ${inputLabelCss}
    `
  }
}
