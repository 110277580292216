import { useRef, useState } from 'react'
import {
  ResizeMenuContainer,
  MenuItem,
  ResizeIcon,
  WidgetsWrapper
} from './styles'
import { useEffect } from 'react'
import ClickOutside from 'src/components/legacy/components/decorators/click-outside'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

const resizeData = {
  width: 300,
  height: 300
}

const WidgetResizeMenu = ({
  rows = 3,
  columns = 3,
  onUpdate,
  selectedRow,
  selectedColumn,
  id,
  handleResize,
  refFunc,
  open,
  toggleOpen
}) => {
  const ref = useRef(null)
  const [displayContent, setDisplayContent] = useState([])

  useEffect(()=>{
    refFunc(ref.current)
  },[])

  const toggleMenuSelection = () => {
    trackEvent(USER_EVENTS.DASHBOARDS.events.CLICK_RESIZE_WIDGET)
    toggleOpen(!open)
  }

  const onMouseEnter = (currentCell) => {
    try {
      const [hoveringRow, hoveringColumn] = currentCell?.split('x')
      setMenuItems(Number(hoveringRow), Number(hoveringColumn))
    } catch (error) {}
  }

  const onMouseLeave = () => {
    setMenuItems()
  }

  const onSelectionHanlder = (content) => {
    const width = content.split('x')[0]
    const height = content.split('x')[1]
    handleResize({id: id, 
        width: resizeData.width * width,
        height: resizeData.height * height
    })
    onUpdate?.({selectedRow:Number(width),selectedColumn:Number(height)})
    toggleMenuSelection()
    trackEvent(USER_EVENTS.DASHBOARDS.events.RESIZE_WIDGET, {width:width, height:height})
  }

  useEffect(() => {
    setMenuItems()
  }, [])

  const setMenuItems = (hoveringRow, hoveringColumn) => {
    const items = [];
   
    for (let i = 1; i <= rows; i++) {
      for (let j = 1; j <= columns; j++) {
        const isSelectedCell = selectedRow === j && selectedColumn === i
        const isHovering = j <= hoveringRow && i <= hoveringColumn
        const isCurrentCell = hoveringRow === j && hoveringColumn === i;
        let isHighlighted =  false;
        if(selectedRow>=j && selectedColumn>=i){
          isHighlighted = true;
        }
        const item = {
          content: `${j}x${i}`,
          isHovering,
          isSelectedCell,
          isCurrentCell,
          isHighlighted
        }
        items.push(item)
      }
    }
    return setDisplayContent(items)
  }

  return (
    <WidgetsWrapper>
      <ResizeIcon
        menuShown={open}
        onClick={toggleMenuSelection}
        className={`icon-resize2`}
       
      />
      {
        <ResizeMenuContainer
          show={open}
          className={
            open ? 'open widget-resize-container' : 'close hidden'
          }
          open={open}
          onMouseLeave={onMouseLeave}
          columns={columns}
          ref={ref}
        >
          {displayContent.map(
            ({ content, isHovering, isSelectedCell, isCurrentCell, isHighlighted }) => (
              <MenuItem
                onClick={(e) =>{
                  e.preventDefault();
                  e.stopPropagation()
                   onSelectionHanlder(content)}}
                isHovering={isHovering}
                isSelectedCell={isSelectedCell}
                key={content}
                isCurrentCell={isCurrentCell}
                onMouseEnter={() => onMouseEnter(content)}
                isHighlighted={isHighlighted}
              >
                {content}
              </MenuItem>
            )
          )}
        </ResizeMenuContainer>
      }
    </WidgetsWrapper>
  )
}
export default ClickOutside(WidgetResizeMenu)
