import { node, func, string, bool } from 'prop-types'
import { StyledButton } from './style'
import { useTheme } from '@aws-amplify/ui-react'

export const Button: any = (props) => {
  const {
    type,
    mode,
    status,
    size,
    children,
    onClick,
    width,
    disabled,
    testName,
    
  } = props
  const { tokens } = useTheme()
  return (
    <StyledButton
      data-testid={`${testName || 'tc'}_button`}
      type={type}
      theme={tokens}
      className={`${type} ${mode} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  /** React onClick property to be passed to button element */
  onClick: func.isRequired,
  /** Type of Button, example: "primary", "secondary", "tertiary", "ghost", "text" */
  type: string,
  /** Mode of app, example: "light", "dark" */
  mode: string,
  /** Status of Button, example: "enabled", "disabled", "destructive" */
  status: string,
  /** CSS Width of button */
  width: string,
  /** Overall size of Buton, example: "small", "default", "large" */
  size: string,
  /** Node to appear inside of button element */
  children: node,
  disabled: bool
}

Button.defaultProps = {
  type: '',
  mode: 'light',
  status: '',
  width: '100%',
  size: 'default',
  disabled: false
}
