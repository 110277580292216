import { useTranslation } from 'react-i18next'
import { ChangeEvent, useEffect, useState } from 'react'
import { DataComparisonBarChartConfigStyles, PanelLabel } from '../../styles'
import WidgetPropertyUom from '../widgetsPropertyUom/WidgetPropertyUom'
import { useFormikContext } from '../MultiTabTorm'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import {
  MaxCalculated,
  MaxConstant,
  getBenchMarkCalculatedOptionsList,
  getCalculatedOptionsList,
  getDisplayItems,
  getMaximumOptionsList,
  getNeedleOptionsList,
  getTimePeriodOptionsList,
  listComparisonPickerValues
} from '../../helpers'
import { convertStringToValidNumber } from 'src/components/legacy/common/helpers.js'
import { formatOverlayTimePeriod } from 'src/common/overlay-time-period'
import {
  TARGET_MARKERS,
  TARGET_MARKERS_CALCULATED,
  TARGET_MARKERS_CONSTANT,
  TARGET_MARKERS_NONE,
  formatTargetMarkers
} from 'src/common/target-markers'
import * as Yup from 'yup'
import i18next from 'i18next'
import translate, { TranslateComponent } from 'src/common/translations'
import { useWidgetsContext } from '../../WidgetsContextProvider'

export const DataComparisonBarChartConfigurationSchema = Yup.object().shape({
  leftProperty: Yup.string().test('testRequiredValue',"Property is required.",testRequiredValue),
  leftUnitsOfMeasure: Yup.string().test('testRequiredValue',"Uom is required.",testRequiredValue),
  rightProperty: Yup.string().test('testRequiredValue',"Property is required.",testRequiredValue),
  rightUnitsOfMeasure: Yup.string().test('testRequiredValue',"Uom is required.",testRequiredValue),
  timePeriod: Yup.string().required("Time Period is required."),
  value: Yup.string().required("This value is required."),
  barMin: Yup.string().test('testBarMinValue', '', testBarMinValue),
  barConstantValue: Yup.string().test('testBarMaxValue', '', testBarMaxValue),
  barCalculatedValue: Yup.string().when('barMax', {
    is: (barMax) => barMax === MaxCalculated,
    then: () => Yup.string().required("This value is required.")
  }),
  leftMarkerConstant: Yup.string().when('leftTargetMarker', {
    is: (leftTargetMarker) => leftTargetMarker === TARGET_MARKERS_CONSTANT,
    then: () => Yup.string().required("This value is required.")
  }),
  rightMarkerConstant: Yup.string().when('rightTargetMarker', {
    is: (rightTargetMarker) => rightTargetMarker === TARGET_MARKERS_CONSTANT,
    then: () => Yup.string().required("This value is required.")
  })
})
function testRequiredValue(value){
  return !(!value)
}
function testBarMinValue(value) {
  const {barConstantValue} = this.parent
  const {
    createError
  } = this
  if (!value) {
    return createError({ message: "This value is required." });
  }
  if (Number(value) > Number(barConstantValue)) {
    return createError({ message: `This value should be lower than Max`  });
  }

  return true;
}
function testBarMaxValue(value) {
  const {barMin} = this.parent
  const {
    createError
  } = this
  if (!value) {
    return createError({ message: "This value is required." });
  }
  if (Number(value) < Number(barMin)) {
    return createError({ message: `This value should be greater than Min` });
  }

  return true;
}
export const DataComparisonBarChartConfiguration = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, errors, setFieldValue } = formik
  const { objectPickerSelectedValue, setObjectPickerSelectedValue } = useWidgetsContext()
  const [selectedEquipments, setSelectedEquipments] = useState([])

  const optionsTranslated = {
    selectObject: translate("Select Object")
  }

  useEffect(() => {
    if (objectPickerSelectedValue && objectPickerSelectedValue.length > 0) {
      const selectedEquipments = listComparisonPickerValues(
        objectPickerSelectedValue
      )
      setSelectedEquipments(selectedEquipments)
      let leftBarEquipmentName = values?.leftBarEquipment ?? ''
      const rightBarEquipmentName = values?.rightBarEquipment ?? ''
      if (objectPickerSelectedValue.length === 1) {
        setFieldValue('leftBarEquipment', selectedEquipments[0].equipmentName)
        setFieldValue('rightBarEquipment', selectedEquipments[0].equipmentName)
      } else {
        leftBarEquipmentName = selectedEquipments[0].equipmentName === rightBarEquipmentName ? selectedEquipments[1].equipmentName : selectedEquipments[0].equipmentName
        setFieldValue('leftBarEquipment', leftBarEquipmentName || selectedEquipments[0].equipmentName)
        setFieldValue('rightBarEquipment', rightBarEquipmentName || selectedEquipments[1].equipmentName)
      }
    } else {
      setSelectedEquipments([])
    }
  }, [objectPickerSelectedValue])

  useEffect(() => {
    if (objectPickerSelectedValue?.length === 2 && values?.leftBarEquipment) {
      const rightBarEquipmentTemp = selectedEquipments.filter(
        (eq) => eq.equipmentName !== values?.leftBarEquipment
      )[0]
      if (rightBarEquipmentTemp) {
        setFieldValue('rightBarEquipment', rightBarEquipmentTemp.equipmentName)
      }
    }
  }, [values?.leftBarEquipment])

  return (
    <DataComparisonBarChartConfigStyles>
      <div className="tab-page-content">
        <SelectFormInput
          fieldsetClass="inline-2"
          labelName={"Time Period"}
          optionsList={getTimePeriodOptionsList(t)}
          placeholder={"— Select One —"}
          update={(value) => {
            setFieldValue('timePeriod', value)
          }}
          errorItem={errors?.timePeriod}
          selectedItem={t(`time>${values?.timePeriod}`, {
            nsSeparator: '>'
          })}
        />

        <SelectFormInput
          fieldsetClass="inline-2"
          labelName={"Value"}
          optionsList={getNeedleOptionsList(t)}
          placeholder={"— Select One —"}
          update={(value) => {
            setFieldValue('value', value)
          }}
          errorItem={errors?.value}
          selectedItem={t(`time>${values?.value}`, {
            nsSeparator: '>'
          })}
        />

        <fieldset>
          <PanelLabel><TranslateComponent>Bar Minimum</TranslateComponent>*</PanelLabel>
          <div className="row-half-container">
            <span><TranslateComponent>Constant</TranslateComponent></span>
            <input
              value={convertStringToValidNumber(values?.barMin)}
              maxLength={80}
              className={errors?.barMin ? 'error-text-box' : ''}
              onChange={({
                target: { value }
              }: ChangeEvent<HTMLInputElement>) => {
                const re = /^[0-9\b]+$/
                if (value === '' || re.test(value)) {
                  setFieldValue('barMin', value)
                }
              }}
            />
          </div>
          <p className="error">{ errors?.barMin && <TranslateComponent>{errors?.barMin}</TranslateComponent>}</p>
        </fieldset>
        <fieldset>
          <div className="row-half-container half-items">
            <div className="max-select">
              <SelectFormInput
                fieldsetClass="inline-2"
                labelName={`Bar Maximum`}
                optionsList={getMaximumOptionsList(t)}
                placeholder={"— Select One —"}
                update={(value) => {
                  setFieldValue('barMax', value)
                }}
                errorItem={errors?.barMax}
                selectedItem={t(`widget-builder>${values?.barMax}`, {
                  nsSeparator: '>'
                })}
              />
            </div>
            <div className="input-max">
              {values?.barMax === MaxConstant && (
                <>
                  <input
                    value={convertStringToValidNumber(values?.barConstantValue)}
                    maxLength={80}
                    className={errors?.barConstantValue ? 'error-text-box' : ''}
                    onChange={({
                      target: { value }
                    }: ChangeEvent<HTMLInputElement>) => {
                      const re = /^[0-9\b]+$/
                      if (value === '' || re.test(value)) {
                        setFieldValue('barConstantValue', value)
                      }
                    }}
                  />
                  <p className="error">{errors?.barConstantValue && <TranslateComponent>{errors?.barConstantValue}</TranslateComponent>}</p>
                </>
              )}
              {values?.barMax !== MaxConstant && (
                <SelectFormInput
                  fieldsetClass="inline-2"
                  labelName={``}
                  optionsList={getCalculatedOptionsList(t)}
                  placeholder={"— Select One —"}
                  update={(value) => {
                    setFieldValue('barCalculatedValue', value)
                  }}
                  errorItem={errors?.barCalculatedValue}
                  selectedItem={t(`time>${values?.barCalculatedValue}`, {
                    nsSeparator: '>'
                  })}
                />
              )}
            </div>
          </div>
        </fieldset>

        <div className="inline-2-container">
          <fieldset>
            <PanelLabel><TranslateComponent>Left Bar</TranslateComponent></PanelLabel>
          </fieldset>
          <fieldset>
            <PanelLabel className='target-label'><TranslateComponent>Targeted Object</TranslateComponent>*</PanelLabel>
          </fieldset>
            {objectPickerSelectedValue?.length === 2 ? (
                <SelectFormInput
                  fieldsetClass="inline-2 select-more-equipment"
                  labelName={''}
                  optionsList={selectedEquipments}
                  placeholder={"— Select One —"}
                  update={(value) => {
                    const selectedEquipment = objectPickerSelectedValue?.filter(f => f?.equipmentName === value)
                    const exceptSelectedEquipments = objectPickerSelectedValue?.filter(f => f?.equipmentName !== value)
                    setObjectPickerSelectedValue([...selectedEquipment, ...exceptSelectedEquipments])
                    // Rearrange Values Based On Equipment Type Change
                    const leftUnit = values?.leftUnitsOfMeasure
                    const rightUnit = values?.rightUnitsOfMeasure
                    const rightSymbol = values?.rightSymbol
                    const leftSymbol = values?.leftSymbol
                    const leftProp = values?.leftProperty
                    const rightProp = values?.rightProperty
                    setTimeout(() => {
                      setFieldValue('leftBarEquipment', value)
                      setFieldValue('leftProperty',rightProp)
                      setFieldValue('rightProperty',leftProp)
                      setFieldValue('rightUnitsOfMeasure',leftUnit)
                      setFieldValue('leftUnitsOfMeasure',rightUnit)
                      setFieldValue('rightSymbol',leftSymbol)
                      setFieldValue('leftSymbol',rightSymbol)
                    }, 2000);
                   
                  }}
                  errorItem={errors?.leftBarEquipment}
                  selectedItem={values?.leftBarEquipment}
                />
            ) : (
                
          <fieldset>
              <PanelLabel className='single-object'>
                {objectPickerSelectedValue
                  ? values?.leftBarEquipment
                  : optionsTranslated.selectObject}
              </PanelLabel>
          </fieldset>
            )}
          <fieldset className="inline-2 property-uom">
            <WidgetPropertyUom
              transFunc={t}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              propertyValue="leftProperty"
              uomValue="leftUnitsOfMeasure"
              symbolValue="leftSymbol"
            />
          </fieldset>
          <fieldset className="inline-2">
            <PanelLabel>
              <TranslateComponent>Benchmark/Target Marker</TranslateComponent>
            </PanelLabel>
            <div className="thirds-items row-thirds-container">
              <div className="two-third-items">
                <SelectFormInput
                  fieldsetClass="benchmark"
                  labelName={''}
                  optionsList={getDisplayItems(
                    TARGET_MARKERS,
                    formatTargetMarkers
                  )}
                  placeholder={"— Select One —"}
                  update={(value) => {
                    setFieldValue('leftTargetMarker', value)
                  }}
                  errorItem={errors?.leftTargetMarker}
                  selectedItem={formatOverlayTimePeriod(
                    values.leftTargetMarker
                  )}
                />
              </div>

              <div className="row-thirds-container one-third-items benchmark">
                {values?.leftTargetMarker === TARGET_MARKERS_CONSTANT && (
                  <>
                    <input
                      value={convertStringToValidNumber(
                        values?.leftMarkerConstant
                      )}
                      className={errors?.leftMarkerConstant ? 'error' : ''}
                      onChange={({
                        target: { value }
                      }: ChangeEvent<HTMLInputElement>) => {
                        const re = /^[0-9\b]+$/
                        if (value === '' || re.test(value)) {
                          setFieldValue('leftMarkerConstant', value)
                        }
                      }}
                    />
                    <p className="error">{errors?.leftMarkerConstant && <TranslateComponent>{errors?.leftMarkerConstant}</TranslateComponent>}</p>
                  </>
                )}
                {values?.leftTargetMarker === TARGET_MARKERS_CALCULATED && (
                  <SelectFormInput
                    labelName={''}
                    optionsList={getBenchMarkCalculatedOptionsList(t)}
                    placeholder={"— Select One —"}
                    update={(value) => {
                      setFieldValue('leftTargetMarkerCalculate', value)
                    }}
                    errorItem={errors?.leftTargetMarkerCalculate}
                    selectedItem={t(
                      `time>${values?.leftTargetMarkerCalculate}`,
                      {
                        nsSeparator: '>'
                      }
                    )}
                  />
                )}
              </div>
            </div>
          </fieldset>
          {values?.leftTargetMarker !== TARGET_MARKERS_NONE && (
            <fieldset className="inline-2 benchmark-input">
              <PanelLabel>
              <TranslateComponent>Benchmark/Target Label</TranslateComponent>
              </PanelLabel>
              <input
                maxLength={32}
                value={convertStringToValidNumber(values?.leftMarkerLabel)}
                className={errors?.leftMarkerLabel ? 'error' : ''}
                onChange={({
                  target: { value }
                }: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('leftMarkerLabel', value)
                }}
              />
            </fieldset>
          )}
        </div>

        <div className="inline-2-container">
          <fieldset>
            <PanelLabel><TranslateComponent>Right Bar</TranslateComponent></PanelLabel>
          </fieldset>
          <fieldset>
            <PanelLabel className='target-label'><TranslateComponent>Targeted Object</TranslateComponent>*</PanelLabel>
          </fieldset>
          <fieldset>
            <PanelLabel className={objectPickerSelectedValue?.length === 2 ? 'multiple-right-object' : 'single-object'}>
              {objectPickerSelectedValue
                ? values?.rightBarEquipment
                : optionsTranslated.selectObject}
            </PanelLabel>
          </fieldset>

          <fieldset className={objectPickerSelectedValue?.length === 2 ? 'inline-2 multiple-object-property-uom' : 'inline-2 property-uom'}>
            <WidgetPropertyUom
              transFunc={t}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              propertyValue="rightProperty"
              uomValue="rightUnitsOfMeasure"
              symbolValue="rightSymbol"
            />
          </fieldset>
          <fieldset className="inline-2">
            <PanelLabel>
             <TranslateComponent>Benchmark/Target Marker</TranslateComponent>
            </PanelLabel>
            <div className="thirds-items row-thirds-container">
              <div className="two-third-items">
                <SelectFormInput
                  fieldsetClass="benchmark"
                  labelName={''}
                  optionsList={getDisplayItems(
                    TARGET_MARKERS,
                    formatTargetMarkers
                  )}
                  placeholder={"— Select One —"}
                  update={(value) => {
                    setFieldValue('rightTargetMarker', value)
                  }}
                  errorItem={errors?.rightTargetMarker}
                  selectedItem={formatOverlayTimePeriod(
                    values.rightTargetMarker
                  )}
                />
              </div>

              <div className="row-thirds-container one-third-items benchmark">
                {values?.rightTargetMarker === TARGET_MARKERS_CONSTANT && (
                  <>
                    <input
                      value={convertStringToValidNumber(
                        values?.rightMarkerConstant
                      )}
                      className={errors?.rightMarkerConstant ? 'error' : ''}
                      onChange={({
                        target: { value }
                      }: ChangeEvent<HTMLInputElement>) => {
                        const re = /^[0-9\b]+$/
                        if (value === '' || re.test(value)) {
                          setFieldValue('rightMarkerConstant', value)
                        }
                      }}
                    />
                    <p className="error">{errors?.rightMarkerConstant && <TranslateComponent>{errors?.rightMarkerConstant}</TranslateComponent>}</p>
                  </>
                )}
                {values?.rightTargetMarker === TARGET_MARKERS_CALCULATED && (
                  <SelectFormInput
                    labelName={''}
                    optionsList={getBenchMarkCalculatedOptionsList(t)}
                    placeholder={"— Select One —"}
                    update={(value) => {
                      setFieldValue('rightTargetMarkerCalculate', value)
                    }}
                    errorItem={errors?.rightTargetMarkerCalculate}
                    selectedItem={t(
                      `time>${values?.rightTargetMarkerCalculate}`,
                      {
                        nsSeparator: '>'
                      }
                    )}
                  />
                )}
              </div>
            </div>
          </fieldset>
          {values?.rightTargetMarker !== TARGET_MARKERS_NONE && (
            <fieldset className="inline-2 benchmark-input">
              <PanelLabel>
                <TranslateComponent>Benchmark/Target Label</TranslateComponent>
              </PanelLabel>
              <input
                maxLength={32}
                value={convertStringToValidNumber(values?.rightMarkerLabel)}
                className={errors?.rightMarkerLabel ? 'error' : ''}
                onChange={({
                  target: { value }
                }: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('rightMarkerLabel', value)
                }}
              />
            </fieldset>
          )}
        </div>
      </div>
    </DataComparisonBarChartConfigStyles>
  )
}

export default DataComparisonBarChartConfiguration
