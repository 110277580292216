/**
 * This much-simplified app model is just the data object. All the actions
 * that were formerly here are now found in models/app-helpers.js.
 *
 * The impetus for splitting this up is so that all the functions that were
 * part of this model are not stored in the redux store, since this is now
 * used in both React and Ractive. Also to eliminate a circular dependency
 * between models/app and stores/modules/app (they both depend on this object)
 */

/**
 * Defaults for query params collection. This is used by a helper to reset the
 * collection, so it needs to be exported and not just defaulted in the below
 * model object.
 */
export const possibleQueryParams = {
  startDate: null,
  endDate: null,
  object: null,
  location: null,
  tag: null,
  organization: null,
  automatedTest: null,
  diagnostic: null,
  salesOffice: null
}

/**
 * App model.
 *
 * NOTE: This object should never be modified directly, and should always be
 * updated through the "app" redux store.
 */
export default {
  page: null,
  breadcrumb: null,
  buildingNavigation: null,
  section: null,
  userInfo: null,
  userSalesOffices: null,
  organizationsList: [],
  explicitOrganizationsList: [],
  locationIds: [],
  activeElectricMeter: null,
  activeLocation: null,
  activeTag: null,
  activeOrganization: null,
  activeSalesOffice: null,
  activeExplicitOrganization: null,
  activeDiagnostic: null,
  epSearchObject: null,
  baselineId: null,
  tisObjectId: null,
  opportunityId: null,
  locationId: null,
  gridProgramId: null,
  gridProgramEventId: null,
  dashboardSelectedId: null,
  dashboardList: null,
  userData: {},
  hasBaselineEditPermissions: null,
  queryParams: Object.assign({}, possibleQueryParams),
  uiVersion: typeof VERSION !== 'undefined' ? VERSION : 'development',
  cookiesChecked: false
}
