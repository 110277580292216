import PropTypes from 'prop-types'
import React, { memo } from 'react'
import styled from 'styled-components'

import { createFormControlStyle } from '../../styles/styleCreators'
import { useFormFieldState } from '../FromControl'

const InputLabelRoot = styled.label`
  color: #555555;
  display: block;
  font-size: 0.875rem;
  margin-bottom: 3px;
  max-width: calc(100% - 10px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${createFormControlStyle({ inputLabel: true })}
`

const InputLabel = React.forwardRef(function InputLabel(inProps, ref) {
  const { formControlStateClass, children, className, InputLbaelProps } =
    useFormFieldState(inProps)

  return (
    <InputLabelRoot
      {...InputLbaelProps}
      className={`${className ? `${className} ` : ''}${
        formControlStateClass ? formControlStateClass : ''
      }`}
      ref={ref}
    >
      {children}
    </InputLabelRoot>
  )
})

InputLabel.propTypes = {
  /**
   *  Children / text content of the component.
   */
  children: PropTypes.node,

  /**
   * If true, color of the element will indicate disabled variant.
   */
  disabled: PropTypes.bool,

  /**
   * If true, color of the element will indicate an error variant.
   */
  error: PropTypes.bool,

  /**
   * If true, color of the element will indicate an focused variant.
   */
  focused: PropTypes.bool
}

InputLabel.defaultProps = {
  disabled: false,
  error: false,
  focused: false
}

export default memo(InputLabel)
