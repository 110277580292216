import { Header, Breadcrumbs, Footer, Sidebar } from 'src/components'
import { Outlet, useLocation } from 'react-router-dom'
import { Block } from './components/layouts'
import { AccessControl } from 'src/pages/AccessControl'
import CreateDashboard from './pages/dashboards/components/CreateDashboard'
import { selectShowCreateDashboard } from 'src/redux/slicers/dashboard'
import { Sidebar as DenaliSidebar } from './denali-components/Sidebar/Sidebar'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { selectUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'
import styles from './layout.module.scss'
import { setRateStructureSaleOfficeId } from 'src/redux/slicers/buildingPicker'
import { useDispatch } from 'react-redux'
import { DialogWelcome } from './denali-components/Dialog/DialogWelcome'

export function Layout({ signOut, termsUrl }) {
  const showDashboard = useSelector(selectShowCreateDashboard)
  const location = useLocation()
  const dispatch = useDispatch()
  const uiMode = useSelector(selectUiMode)
  const isDenaliTheme = (uiMode === UiMode.denali)
  useEffect(() => {
    // Rate structure page needs some special selection in building picker sales office selection
    // Whenever the location changes, check if it is from rate structure or not
    // based on that, decide to keep or remove saleofficeid from redux
    //@ts-ignore
    if (location && !location.pathname?.includes('rate-structure-library')) {
      dispatch(
        setRateStructureSaleOfficeId({
          type: 'Success',
          salesOfficeId: null
        })
      )
    }
  }, [location])

  if(isDenaliTheme) {
    return (
      <div className={styles.layout}>
        <DenaliSidebar signOut={signOut} />
        <div className={styles.mainContent}>
          <AccessControl>
            <Outlet />
            {showDashboard && <CreateDashboard />}
          </AccessControl>
        </div>
        <DialogWelcome />
      </div>
    )
  }

  return (
    <Block display={'flex'} flexWrap={'wrap'}>
      <Block flex={'100%'}>
        <Header signOut={signOut} />
      </Block>
      <Block flex={'100%'}>
        <Breadcrumbs />
      </Block>
      <Sidebar />
      <Block
         display={'flex'}
         width={'calc(100% -  233px)'}
         flex={'calc(100% -  233px)'}
         flexDirection={'column'}
         background="#f5f5f5">
        <Block flex={1}>
          <AccessControl>
            <Outlet />
            {showDashboard && <CreateDashboard />}
          </AccessControl>
        </Block>
        <Footer termsUrl={termsUrl}/>
      </Block>
    </Block>
  )
}