import React from "react"
import PropTypes from "prop-types"
// import { dateInput, subdomainInput } from "react/screens/contracts-and-offerings/offering-dialogs/common.js"
import { useTranslation } from "react-i18next"

export function ActivateTenantServices({ handleSubmitForm, values: { startDate, endDate, subdomain }, touched, errors, setFieldValue, setFieldTouched }) {
	const [t] = useTranslation()
	return (
		<form onSubmit={handleSubmitForm}>
			<div className="datepickers">
				{dateInput({ name: "startDate", label: t("common:StartDate"), value: startDate, touched: touched.startDate || touched.all, setFieldValue, setFieldTouched, errors })}
				{dateInput({ name: "endDate", label: t("common:ExpirationDate"), value: endDate, touched: touched.endDate || touched.all, setFieldValue, setFieldTouched, errors })}
			</div>
			{subdomainInput({ name: "subdomain", label: t("common:Subdomain"), value: subdomain, touched: touched.subdomain || touched.all, setFieldValue, setFieldTouched, errors })}
		</form>
	)
}

ActivateTenantServices.propTypes = {
	values: PropTypes.shape({
		startDate: PropTypes.string,
		endDate: PropTypes.string,
		subdomain: PropTypes.string,
	}).isRequired,
	handleSubmitForm: PropTypes.func.isRequired,
	touched: PropTypes.boolean,
	setFieldTouched: PropTypes.func,
	setFieldValue: PropTypes.func.isRequired,
	errors: PropTypes.object
}
