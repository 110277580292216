// Default Options.

import { useMemo } from 'react'
import { stackDataGenerator } from '../../../chart-library/CommonComponents/StackedBar'

// Developer can set min & max / category data later, once it receives from API using setDomian
export function useOptions(data = [], chartGoalData): Options {
  return useMemo(() => {
    const [, minMax] = stackDataGenerator(data)

    return {
      xAxis: {
        type: 'scaleLinear',
        min: minMax.min || 0,
        max: Math.max(minMax.max, chartGoalData.goal || 0)
      },
      yAxis: {
        type: 'scaleBand',
        data: ['Lower', 'Upper', 'HiddenExtraSpaceInCateogryAxis']
      },
      grid: [{ left: '40px', right: '30px', top: '30px', bottom: '30px' }]
    }
  }, [data, chartGoalData])
}
