import * as Yup from 'yup'
import { useFormikContext } from '../MultiTabTorm'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import { useTranslation } from 'react-i18next'
import {
    getDisplayItems,
    getTimePeriodOptionsList,
    getValueOptionsList
  } from '../../helpers'
import translate from 'src/common/translations'
import {
    DataMonitoringConfigurationWrapper,
} from '../../styles'
import { formatOverlayTimePeriod, overlayTimePeriods } from 'src/common/overlay-time-period'
import WidgetPropertyUom from '../widgetsPropertyUom/WidgetPropertyUom'
import i18next from 'i18next'
export const TrendLineChartConfigurationSchema = Yup.object().shape({
    property: Yup.string().test('testRequiredValue','Property is required',testRequiredValue),
    unitsOfMeasure: Yup.string().test('testRequiredValue','Uom is required',testRequiredValue),
    timePeriod: Yup.string().required(i18next.t('Time Period is required.')),
})
function testRequiredValue(value){
  return !(!value)
}
    
export const TrendLineChartConfiguration = () => {
    const [t] = useTranslation()
    const formik = useFormikContext()
    const { values, errors, setFieldValue } = formik

    return (
        <DataMonitoringConfigurationWrapper>
            <div className="configuration-tab-main">
                <div>
                    <WidgetPropertyUom transFunc = {t} setFieldValue={setFieldValue} values={values} errors={errors}/>
                        <SelectFormInput
                            labelName={'Time Period'}
                            optionsList={getTimePeriodOptionsList(t)}
                            placeholder={'— Select One —'}
                            
                            update={(value)=> {
                                setFieldValue('timePeriod', value)
                              }}
                            errorItem={errors?.timePeriod}
                            selectedItem={t(`time>${values?.timePeriod}`, {
                                nsSeparator: '>'
                            })}
                        />
                        <SelectFormInput
                            labelName={"Value"}
                            optionsList={getValueOptionsList(t)}
                            placeholder={'— Select One —'}
                            update={(value)=> {
                                setFieldValue('value', value)
                              }}
                            errorItem={errors?.value}
                            selectedItem={t(`time>${values?.value}`, {
                                nsSeparator: '>'
                            })}
                        />
                        <SelectFormInput
                            labelName={"Compare Point to"}
                            optionsList={getDisplayItems(overlayTimePeriods, formatOverlayTimePeriod)}
                            placeholder={'— Select One —'}
                            update={(value)=> {
                                setFieldValue('comparePointTo', value)
                              }}
                            errorItem={errors?.comparePointTo}
                            selectedItem={formatOverlayTimePeriod(values.comparePointTo)}                        
                        />
                </div>
            </div>
        </DataMonitoringConfigurationWrapper>
    )
}