import Select from 'src/components/legacy/components/select/select'
import { SeasonDateWrapper } from './styles.js'
import { dayTypes } from './timeOfUsePeriodTable'


export const months = [
  {key: "Dec",
  value: "Dec"},
  {key: "Nov",
  value: "Nov"},
  {key: "Oct",
  value: "Oct"},
  {key: "Sep",
  value: "Sep"},
  {key: "Aug",
  value: "Aug"},
  {key: "Jul",
  value: "Jul"},
  {key: "Jun",
  value: "Jun"},
  {key: "May",
  value: "May"},
  {key: "Apr",
  value: "Apr"},
  {key: "Mar",
  value: "Mar"},
  {key: "Feb",
  value: "Feb"},
  {key: "Jan",
  value: "Jan"},
]

export const days = [
  {key: "01",
  value: "01"},
  {key: "02",
  value: "02"},
  {key: "03",
  value: "03"},
  {key: "04",
  value: "04"},
  {key: "05",
  value: "05"},
  {key: "06",
  value: "06"},
  {key: "07",
  value: "07"},
  {key: "08",
  value: "08"},
  {key: "09",
  value: "09"},
  {key: "10",
  value: "10"},
  {key: "11",
  value: "11"},
  {key: "12",
  value: "12"},
  {key: "13",
  value: "13"},
  {key: "14",
  value: "14"},
  {key: "15",
  value: "15"},
  {key: "16",
  value: "16"},
  {key: "17",
  value: "17"},
  {key: "18",
  value: "18"},
  {key: "19",
  value: "19"},
  {key: "20",
  value: "20"},
  {key: "21",
  value: "21"},
  {key: "22",
  value: "22"},
  {key: "23",
  value: "23"},
  {key: "24",
  value: "24"},
  {key: "25",
  value: "25"},
  {key: "26",
  value: "26"},
  {key: "27",
  value: "27"},
  {key: "28",
  value: "28"},
  {key: "Last Day",
  value: "Last Day"},
]

export const SeasonDatePicker = ({season, values, setSeason}) => {
  const newMonths = [...months].reverse()
  const datesStart = new Date(new Date().getFullYear() , newMonths.findIndex(e => e.key === values?.seasons?.[season]?.startMonth) + 1, 0).getDate()
  const dateArrayStart = new Array(datesStart).fill("").map((e, i) => ({key: `${i < 9 ? "0" : ""}${i+1}`, value: `${i < 9 ? "0" : ""}${i+1}`}))
  dateArrayStart[dateArrayStart.length - 1] = {key: "Last Day", value: "Last Day"}
  const datesEnd = new Date(new Date().getFullYear(), newMonths.findIndex(e => e.key === values?.seasons?.[season]?.endMonth) + 1, 0).getDate()
  const dateArrayEnd = new Array(datesEnd).fill("").map((e, i) => ({key: `${i < 9 ? "0" : ""}${i+1}`, value: `${i < 9 ? "0" : ""}${i+1}`}))
  dateArrayEnd[dateArrayEnd.length - 1] = {key: "Last Day", value: "Last Day"}
return <> 
    <SeasonDateWrapper>
      Start Date: &nbsp;
    <Select
      className="small select-wrapper"
      options={months}
      onChange={(index) => {
        const seasons = {...values.seasons}
        seasons[season].startMonth = index
        seasons[season].startDate = "01"
        setSeason(seasons)
      }}
      selectedItem={values?.seasons?.[season]?.startMonth}
    />
    <Select
      className="small select-wrapper"
      options={dateArrayStart}
      onChange={(index) => {
        const seasons = {...values.seasons}
        seasons[season].startDate = index
        setSeason(seasons)
      }}
      selectedItem={values?.seasons?.[season]?.startDate}
    />
    </SeasonDateWrapper>
    <SeasonDateWrapper>
    End Date: &nbsp;
    <Select
      className="small select-wrapper"
      options={months}
      onChange={(index) => {
        const seasons = {...values.seasons}
        seasons[season].endMonth = index
        seasons[season].endDate = "Last Day"
        setSeason(seasons)
      }}
      selectedItem={values?.seasons?.[season]?.endMonth}
    />
    <Select
      className="small select-wrapper"
      options={dateArrayEnd}
      onChange={(index) => {
        const seasons = {...values.seasons}
        seasons[season].endDate = index
        setSeason(seasons)
      }}
      selectedItem={values?.seasons?.[season]?.endDate}
    />
    </SeasonDateWrapper>
  </>}

export const emptyDayTypes = dayTypes.reduce((accum, curr) => {
  if (!accum[curr]) {
    accum[curr] = {
      "1am": "Off-Peak",
      "2am": "Off-Peak",
      "3am": "Off-Peak",
      "4am": "Off-Peak",
      "5am": "Off-Peak",
      "6am": "Off-Peak",
      "7am": "Off-Peak",
      "8am": "Off-Peak",
      "9am": "Off-Peak",
      "10am": "Off-Peak",
      "11am": "Off-Peak",
      "12pm": "Off-Peak",
      "1pm": "Off-Peak",
      "2pm": "Off-Peak",
      "3pm": "Off-Peak",
      "4pm": "Off-Peak",
      "5pm": "Off-Peak",
      "6pm": "Off-Peak",
      "7pm": "Off-Peak",
      "8pm": "Off-Peak",
      "9pm": "Off-Peak",
      "10pm": "Off-Peak",
      "11pm": "Off-Peak",
      "12am": "Off-Peak",
    }
  }
  return accum
}, {})