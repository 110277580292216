import { AutomatedTestSuppressionsListContainer } from '../styles'
import { getSearchParams } from 'src/common/helperFunctions.js'
import Table from 'src/components/Table/clientSideTable'
import translate, { TranslateComponent } from 'src/common/translations'
import { formatActiveSuppressions, getEquipmentName } from '../helper'
import { useEffect, useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { getTestActiveSuppressions, ListBuildingEquipments } from '../graphql'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import moment from 'moment'
import { useAPIPagination } from 'src/hooks/APIPaginationHook'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/RootState'
import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
import { useAppDispatch } from 'src/redux/store'

const ActiveSuppressionsList = () => {
  const { buildingId } = getSearchParams()
  const [activeSuppressionsList, setActiveSuppressionsList] = useState([])
  const [activeSuppressionsLists, setActiveSuppressionsLists] = useState([])
  const [equipmentData, setEquipmentData] = useState([])
  const [buildingName, setBuildingName] = useState('')
  const appDispatch = useAppDispatch()
  const [activeSuppressionsListToTranslate, setActiveSuppressionsListToTranslate] = useState([])

  const { translatedJSON } = useSelector((state : RootState) => state?.translationCombinedSlice)

  useEffect(() => {
    appDispatch(translateJSON({ json: activeSuppressionsListToTranslate }) as any);
  }, [activeSuppressionsListToTranslate])

  useEffect(() => {
    if (translatedJSON) {
      const translatedFinalSuppressionsList = activeSuppressionsList.map( (suppression, index) => {
        return ({
          ...suppression,
          displayName: translatedJSON[index]?.displayName,
          equipTypeUI: translatedJSON[index]?.equipType
        })
      })
      setActiveSuppressionsList(translatedFinalSuppressionsList?.sort((a, b) => a?.displayName?.localeCompare(b?.displayName)))
    }
  }, [translatedJSON, activeSuppressionsListToTranslate])

  const optionsTranslated = {
    testName: translate('Test Name'),
    equipmentType: translate('Equipment Type'),
    name: translate('Name'),
    suppressionStart: translate('Suppression Start'),
    suppressionEnd: translate('Suppression End'),
    suppressionLevel: translate('Suppression Level')
  }

  const [loadingEquipmentData, refetchEquipmentsList,  equipmentsList ] = useAPIPagination(
    ListBuildingEquipments,
    'data.getBuilding.equipments'
  )


  useEffect(() => {
      if (equipmentsList && equipmentsList?.length) {
        setEquipmentData(equipmentsList)
        setBuildingName(equipmentsList[0]?.building?.name)
      }
  }, [equipmentsList])

  const {
    data: automatedSettingsSummary,
    refetch: refetchActiveSuppressionList,
    loading: loadingActiveSuppression
  } = useQuery({
    query: getTestActiveSuppressions,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getATSettings'
  })

  useEffect(() => {
    setEquipmentData([])
  }, [buildingId])

  const getSettingsByBuilding = async (buildingId) => {
    try {
      const input = {
        req_bid: buildingId,
        req_type: 'sp',
        isSummary: 0
      }
      const summaryData = await refetchActiveSuppressionList({
        body: JSON.stringify(input)
      })
      const parsedData = JSON.parse(summaryData)
      const finalSummary = formatActiveSuppressions(parsedData?.settings || {})
      // filter active suppression based on current date
      const filterActiveSuppressionsByDate = finalSummary?.filter((x) => {
        return (
          moment(x.endDate,'MMM DD, YYYY').isAfter(new Date()) || x?.endDate === 'Indefinitely'
        )
      })
      const filterActiveSuppressionsToTranslate = filterActiveSuppressionsByDate.map((item)=> ({
        displayName: item?.displayName,
        equipType: item?.equipmentTypeUIName
      }))
      setActiveSuppressionsList(filterActiveSuppressionsByDate)
      setActiveSuppressionsListToTranslate(filterActiveSuppressionsToTranslate)
    } catch (e) {}
  }
  const addingEquipmentName = () => {
    let testObject;
    const testArray = []
    activeSuppressionsList?.map((x) => {
      if (x?.suppressionLevel === "Equipment") {
        testObject = {
          ...x,
          Name: getEquipmentName(equipmentData, x?.equipmentId)
        }
      } else {
        testObject = {
          ...x,
          Name: buildingName ? buildingName : '--'
        }
      }
      testArray.push(testObject)
    })
    setActiveSuppressionsLists(testArray)
  }

  useEffect( () => {
      addingEquipmentName()
  }, [activeSuppressionsList, equipmentData])


    const getSettingsQuery = async () => {
    setEquipmentData([])
    await Promise.all([refetchEquipmentsList({id: buildingId}), getSettingsByBuilding(buildingId)])
  }

  useEffect(() => {
      getSettingsQuery()
      setActiveSuppressionsLists([])
  }, [buildingId])


  const nameHeadings = [
    {
      title: optionsTranslated.testName,
      key: 'displayName',
      maxWidth: '200px'
    },
    {
      title: optionsTranslated.equipmentType,
      key: 'equipTypeUI',
      maxWidth: '150px'
    },
    {
      title: optionsTranslated.name,
      key: 'Name',
      maxWidth: '200px'
    },
    {
      title: optionsTranslated.suppressionStart,
      key: 'startDate',
      maxWidth: '200px',
      customComponent: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>{row?.startDate}</p>
          </div>
        )
      }
    },
    {
      title: optionsTranslated.suppressionEnd,
      key: 'endDate',
      maxWidth: '200px',
      customComponent: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>{row.endDate === 'Indefinitely' ? <TranslateComponent>{row?.endDate}</TranslateComponent> : row.endDate}</p>
          </div>
        )
      }
    },
    {
      title: optionsTranslated.suppressionLevel,
      key: 'suppressionLevel',
      maxWidth: '200px',
      customComponent: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p><TranslateComponent>{row?.suppressionLevel}</TranslateComponent></p>
          </div>
        )
      }
    }
  ]

  return (loadingActiveSuppression || loadingEquipmentData) ? (
    <Spinner />
  ) : (
    <AutomatedTestSuppressionsListContainer>
      <div className="automated-tests-suppressions-list">
        <Table
          key={`AutomatedTestSuppressionsTable-${buildingId}`}
          header={nameHeadings || []}
          rows={activeSuppressionsLists || []}
          search={true}
          searchFields={['displayName']}
          rowControls={[]}
          fixedLayout={false}
        />
      </div>
    </AutomatedTestSuppressionsListContainer>
  )
}
export default ActiveSuppressionsList
