import ContainerWrapper from 'src/components/Container'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import BaseLineComparisonForm from './BaseLineComparisonForm'
import BaseLineComparisonInput from './BaseLineComparisonInput'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { useWidgetsContext } from '../../WidgetsContextProvider'

const BaseLineComparison = ({ setWidgetFormik }) => {
  const { width } = useBreakPoint()  
  const {
    isLoading
  } = useWidgetsContext()

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (<ContainerWrapper
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        background="#eee"
      >
        <Content
          xlColumn={4}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
          background="#fff"
        >
          <BaseLineComparisonInput />
        </Content>
        <Content
          xlColumn={8}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <BaseLineComparisonForm setWidgetFormik={setWidgetFormik} />
        </Content>
      </ContainerWrapper>)}</>
  )
}

export default BaseLineComparison
