import { isFunction } from "lodash";
import { lineGenerator } from "./Line/line";
import { areaGenerator } from "./Area/area";
import { areaDualGenerator } from "./Area/areaDualGenerator";

export const shapeGeneratorFunctions: any = {
  line: lineGenerator,
  area: areaGenerator,
  areaRange: areaGenerator,
  areaDual: areaDualGenerator
};

export const generatorFunction = (type = "", props = {}) => {
  const shapeGenetor = shapeGeneratorFunctions[type];
  if (isFunction(shapeGenetor)) {
    return shapeGenetor(props);
  } else {
    console.log(`Shape: ${type} is not configured`);
  }
};
