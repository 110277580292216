import Svg from '../../../../../chart-library/CommonComponents/Svg'
import CircleWithText from '../../../../../chart-library/CommonComponents/CricleWithText'
import './styles.scss'
import BaseWrapper from 'src/chart-library/CommonComponents/BaseWrapper'
import { useChartContext } from '../../../../../chart-library/CommonComponents/BaseWrapper'
import clsx from 'clsx'

// Need to pass some options to basewrapper to instanciate it, so then it will take care of resize
// This confid does nothing with the chart. Dont consider.
const options = {
  xAxis: {
    type: 'scaleTime'
  },
  yAxis: {
    type: 'scaleTime'
  }
}

// Mock data. This can be in any fomrat
// so developer can change the OrbTextTemplate component based on the data format provided
const defaultData = {
  orb: 0.0,
  orbUnit: 'kWh'
}

export default function OrbChart({
  data = defaultData,
  color,
  isLargeSize = false
}: {
  data: any
  color?: string
  isLargeSize?: boolean
}) {
  return (
    // toggle .font-size-large css class when widget size is expanded to large
    <BaseWrapper
      options={options}
      className={clsx('orb-circle', { 'font-size-large': isLargeSize })}
    >
      <Svg>
        <ResizableCircle textData={data} color={color} />
      </Svg>
    </BaseWrapper>
  )
}

// Component that will resize the circle based on chart size
function ResizableCircle({
  textData = {},
  color
}: {
  textData: any
  color?: string
}) {
  const chart = useChartContext()
  const grid = chart.grid

  if (grid.lastUpdatedAt === null) return null

  const canvas = grid.getGrid(0)

  const x = (canvas.width + canvas.x) / 2
  const y = (canvas.height + canvas.y) / 2
  const radius = Math.min(canvas.width, canvas.height) / 2

  return (
    <CircleWithText
      x={x}
      y={y}
      radius={radius}
      color={color}
      text={<OrbTextTemplate data={textData} />}
    />
  )
}

// Custom template for Orb widget
function OrbTextTemplate({ data = {} }: any) {
  return (
    <div className="orb-circle-content">
      <span className="title">{data.orb}</span>
      <span className="sub-title">{data.orbUnit}</span>
    </div>
  )
}
