import { getNumberIfUndefined } from 'src/chart-library/BaseSetup/helper'

export const tickSize = (obj: any = {}) => {
  return !obj?.hideTick ? getNumberIfUndefined(obj?.tickSize, 10) : 0
}

export const ticksPadding = (obj: any = {}) => {
  const padding = tickSize(obj)
  const paddingObject: any = {
    tickSize: padding
  }
  return paddingObject
}
