// React/Redux Libraries \\
import React from 'react'
import { string, func, bool, elementType } from 'prop-types'

import styled from 'styled-components'
import IconSet from './selection'

export const StyledSVG = styled.svg`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:hover path {
    fill: ${(props) => (props.hover ? props.hover : props.color)};
  }
  ${(props) => props.height && `height:${props.height}`};
  ${(props) => props.width && `width:${props.width}`};
  ${(props) => props.padding && `padding:${props.padding}`};
  ${(props) => props.margin && `margin:${props.margin}`};
`

export const getSvg = (icon, extraProps) => {
  const find = (iconEl) =>
    iconEl.properties.name
      .toLowerCase()
      .split('Icons__')
      .includes(icon.toLowerCase()) ||
    iconEl.icon.tags
      ?.map((icon) => icon.toLowerCase().split('icons__')[1])
      .includes(icon)
  const currentIcon = IconSet.icons.find(find)
  const renderPath = (iconObj) => (path, index) => {
    const attrs = (iconObj.attrs && iconObj.attrs[index]) || {}
    return <path {...extraProps} key={index} d={path} {...attrs} />
  }
  if (currentIcon) {
    return currentIcon.icon.paths.map(renderPath(currentIcon.icon))
  }
  // eslint-disable-next-line no-console
  console.warn(`icon ${icon} does not exist.`)
  return null
}
const IconSvg = React.forwardRef(
  (
    {
      name,
      title,
      width,
      height,
      padding,
      margin,
      className,
      color,
      onClick,
      disabled,
      hover,
      component: Component = 'span',
      viewBox = '0 0 1024 1024'
    },
    ref
  ) => {
    const cursor = onClick ? (!disabled ? 'pointer' : 'not-allowed') : 'arrow'
    const onClickHandler = !disabled ? onClick : undefined

    const get = () => (
      <StyledSVG
        name={name}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        color={color}
        cursor={cursor}
        hover={disabled ? '#CCCCCC' : hover}
        height={height}
        width={width}
        padding={padding}
        margin={margin}
      >
        {title && <title>{title}</title>}
        {getSvg(name, { fill: disabled ? '#CCCCCC' : color })}
      </StyledSVG>
    )
    const getSpan = () => {
      return (
        <Component
          onClick={onClickHandler && onClickHandler}
          className={className && className}
          ref={ref}
        >
          {get()}
        </Component>
      )
    }
    return getSpan()
  }
)

IconSvg.defaultProps = {
  color: '#666666',
  height: '18px',
  width: '18px',
  className: '',
  attr: undefined,
  onClick: undefined,
  name: '',
  disabled: false,
  hover: '#999999'
}

IconSvg.propTypes = {
  /** color of icon */
  color: string,

  /** height of icon */
  height: string,

  /** width of icon */
  width: string,

  /** className of icon */
  className: string,

  /** action of icon */
  onClick: func,

  /** disabled icon */
  disabled: bool,

  /** hover of icon */
  hover: string,

  /** component of icon */
  component: elementType,

  /** title of icon */
  title: string
}

export default IconSvg
