import { v4 as uuidv4 } from 'uuid'

import { ATModel } from './model'
import { staticApplicabilityCheckConfg } from './chiller/staticApplicabilityConfig'

export enum EQUIPMENT_COMPONENT {
  CIRCUIT = 'Circuit',
  COMPRESSOR = 'Compressor',
  DEFAULT = 'None'
}

class BasicATDetails extends ATModel {
  constructor(
    uiATName,
    instacne = '',
    instanceName = '',
    component,
    getConditionDetails,
    validationObject,
    source
  ) {
    super()

    this.id = uuidv4()

    this.instance = instacne

    this.displayName = uiATName.replace(
      'XX',
      instanceName ? ' ' + instanceName : instanceName
    )

    this.uiATName = uiATName

    this.component = component

    this.getConditionDetails = getConditionDetails

    this.validationObject = validationObject

    this.sourceATConfig = { ...source }
  }
}

abstract class EquipmentComponentATs {
  protected instanceModel(at, component) {
    const { uiATName, statusCalc = {} } = at
    const {
      instance,
      type,
      instanceName,
      getConditionDetails = () => []
    } = component
    return new BasicATDetails(
      uiATName,
      instance,
      instanceName,
      type,
      getConditionDetails,
      statusCalc,
      {
        ...at
      }
    )
  }
}

abstract class AnalyticConfig extends EquipmentComponentATs {
  protected getApplicabilityCheckConfig(config) {
    // use static config for scenario based applicability check
    const staticConfig =
      config.scenarioAppCheck ?? staticApplicabilityCheckConfg[config?.name]
        ? JSON.stringify(staticApplicabilityCheckConfg[config?.name])
        : null

    return staticConfig || config?.applicabilityCheck
  }

  protected getEquipmentComponent(config: any) {
    return config?.equipmentComponent ?? EQUIPMENT_COMPONENT.DEFAULT
  }

  protected getATMappings(config) {
    let aumatedTestStatusValidationProperties = {}

    try {
      aumatedTestStatusValidationProperties =
        JSON.parse(config?.statusCalc) || {}
    } catch (error) {
      console.log('Unable to JSON.parse statusCalc details', error)
    }

    return aumatedTestStatusValidationProperties
  }

  protected getATs(config) {
    const mappings = this.getATMappings(config)

    // load automated tests from config
    return (config?.exceptionInputs || []).reduce((av, cv) => {
      try {
        const d = { ...JSON.parse(cv) }

        // Assigining statusCalc to each AT to handle in AT model
        // So Every AT will have own statusCalc
        if (d?.name && mappings[d.name]) {
          d['statusCalc'] = mappings[d.name]
        }

        // is_ui - some tests are being used by backend to calculate the analytic values which are not required for UI to display
        // 0 - hide from UI, other than 0 display on UI
        d.is_ui !== 0 && av.push(d)
      } catch (error) {
        console.log('Unable to JSON.parse a Automated Test config', error)
      }
      return av
    }, [])
  }
}

export class ATManager<T = any> extends AnalyticConfig {
  #equipmentDetails: any

  #atCreators = {
    [EQUIPMENT_COMPONENT.CIRCUIT]: this.#createCircuitATs.bind(this),
    [EQUIPMENT_COMPONENT.COMPRESSOR]: this.#createCompressorATs.bind(this),
    [EQUIPMENT_COMPONENT.DEFAULT]: this.#createEquipmentAT.bind(this)
  }

  constructor(equipmentInst: T) {
    super()
    this.#equipmentDetails = equipmentInst ?? {}
  }

  init(configs: any, checkIsApplicable = (applicabilityConfig) => true) {
    const automatedTests: any[] = []

    // loop starts
    for (const config of configs) {
      const applicabilityConfig = this.getApplicabilityCheckConfig(config)

      const isApplicable = checkIsApplicable(applicabilityConfig)

      // if not applicable, skip the test
      if (!isApplicable) continue

      const equipemtComponent = this.getEquipmentComponent(config)

      const canSkip = this.#skipWhen(
        equipemtComponent,
        this.#equipmentDetails?.equipment
      )

      if (canSkip) continue

      const ats = this.getATs(config)

      const atWithDetails = this.#atCreators[equipemtComponent]?.(ats) ?? []

      automatedTests.push(...atWithDetails)
    }

    return automatedTests
  }

  checkATStatus(automatedTests = [], validator = (automatedTest: any) => null) {
    return automatedTests.map((automatedTest) => {
      const { validationObject } = automatedTest

      if (!validationObject) {
        return automatedTest
      }
      return validator({ ...automatedTest })
    })
  }

  sortByDisplayName(automatedTests) {
    // Sort by display name
    return automatedTests.sort(function (a, b) {
      const nameA = String(a.displayName).toLowerCase(),
        nameB = String(b.displayName).toLowerCase()
      //sort string ascending
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0 //default return value (no sorting)
    })
  }

  getResult(automatedTests) {
    const activeTests = automatedTests.filter((t) => !!t.mappingStatus) || []

    return {
      inActiveTests: activeTests,
      inActiveTestsCount: activeTests.length,
      automatedTests: automatedTests,
      totalTestsCount: automatedTests.length
    }
  }

  #skipWhen(equipemtComponent, equipment): boolean {
    if (equipemtComponent === EQUIPMENT_COMPONENT.COMPRESSOR)
      return !equipment.hasCompressors
    else if (equipemtComponent === EQUIPMENT_COMPONENT.CIRCUIT)
      return !equipment.hasCircuits
    else return false
  }

  #createCompressorATs(automatedTests) {
    const compressors = this.#equipmentDetails?.compressors || []

    return this.#createATByComponent(
      automatedTests,
      compressors,
      this.instanceModel
    )
  }

  #createCircuitATs(automatedTests) {
    const circuits = this.#equipmentDetails?.circuits || []

    return this.#createATByComponent(
      automatedTests,
      circuits,
      this.instanceModel
    )
  }

  #createEquipmentAT(automatedTests) {
    const equipment = this.#equipmentDetails?.equipment

    return this.#createATByComponent(
      automatedTests,
      equipment ? [equipment] : [],
      this.instanceModel
    )
  }

  #createATByComponent(automatedTests, components, initModel: any) {
    const allAutomatedTests = []

    components.forEach((component) => {
      automatedTests.forEach((at) => {
        const model = initModel(at, component)
        allAutomatedTests.push(model)
      })
    })

    return allAutomatedTests
  }
}
