import { useTranslation } from 'react-i18next'
import { Container } from 'src/components/layouts'
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { Content } from 'src/components/layouts'
import COLORS from 'src/components/legacy/common/colors.json'
import ContactInfo from '../ContactInfo'
import {
  ContentWrapper,
  DivWrapper,
  ErrorContent,
  OrganizationWrap,
  HeaderToggleWrapper,
  ToolbarWrapper,
  SelectWrapper,
  LabelWrapper,
  PageTitleWrapper
} from '../styles'
import Select from 'src/components/legacy/components/select/select'
import moment from 'moment/moment'
import { useNavigate } from 'react-router'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import { MAX_LENGTH_OF_NAME, getStatusOptionList } from '../helper'
import { useEffect, useState } from 'react'
import { ContactDivWrap } from '../styles'
import TextInput from 'src/components/legacy/components/modal-text-input'
import _keys from 'lodash/keys'
import _setWith from 'lodash/setWith'
import { useQuery } from 'src/hooks/APIHooks'
import { GET_BUILDING_BY_ID } from 'src/common/queries/datasource'
import { isValid as isDateValid } from 'date-fns'
import _every from 'lodash/every'
import { accessControlFunc } from 'src/components/accessControl'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'

const EUReportHeadSection = (props) => {
  const {
    buildingId,
    data,
    setBarChartData,
    frameBarChartData,
    framePieChartData,
    setPieChartData,
    handleFormSubmit,
    pieChartSummarySetter,
    dataError,
    noDataError,
    setNoDataError,
    getFilteredData,
    setIsTriggerValidation,
    pieChartData
  } = props || {}

  const {
    isValid: isFormValid,
    values,
    setFieldValue,
    errors,
    setFieldTouched,
    touched,
    isSubmitting
  } = props?.formik

  const [t] = useTranslation()
  const navigate = useNavigate()
  const { width } = useBreakPoint()
  const [isValid, setIsValid] = useState(false)
  const userAccess = useSelector(selectUserAccess)

  const { data: buildingDetails, refetch: fetchBuildingDetails } = useQuery({
    query: GET_BUILDING_BY_ID,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.getBuilding'
  })
  useEffect(() => {
    if (buildingId) {
      fetchBuildingDetails({ id: buildingId })
    }
  }, [buildingId])

  useEffect(() => {
    setIsValid(_keys(props?.errors)?.length)
  }, [props])

  useEffect(() => {
    // Set the date values if it is not set
    // We have to use this form values in the API input variables
    if (values === null) return
    if (!values?.startDate) {
      setFieldValue(
        'startDate',
        moment(moment().format(DATE_FORMAT)).format(DATE_FORMAT)
      )
    }
    if (!values?.endDate) {
      setFieldValue(
        'endDate',
        moment(moment().format(DATE_FORMAT)).format(DATE_FORMAT)
      )
    }
    if (!values?.status) {
      setFieldValue('status', getStatusOptionList(t)[0]?.value)
    }
  }, [values])

  const pageTitle = () => {
    return (
      <HeaderToggleWrapper>
        <ToggleButtonHorizontal
          name="visibleToCustomer"
          checked={values?.visibility}
          onChange={(e) => {
            setIsTriggerValidation(true)
            setFieldValue('visibility', e?.target?.checked)
          }}
        />
        <label>{'Externally Visible'}</label>
      </HeaderToggleWrapper>
    )
  }

  const toolbar = () => (
    <ToolbarWrapper className="toolbar-buttons">
      <button
        className={`primary ${isSubmitting ? 'wait' : ''}`}
        disabled={isValid || dataError || noDataError || !isFormValid}
        onClick={handleFormSubmit}
      >
        {isSubmitting ? <div className="spinner" /> : t('common:Save')}
      </button>
      <button
        className="secondary"
        onClick={() => {
          navigate('/reports')
        }}
      >
        {t('common:Cancel')}
      </button>
    </ToolbarWrapper>
  )
  useEffect(() => {
    updateReportData()
  }, [values?.startDate, values?.endDate])

  const updateReportData = () => {
    if (
      !data ||
      !values?.startDate ||
      !values?.endDate ||
      !isDateValid(new Date(values?.startDate)) ||
      !isDateValid(new Date(values?.endDate))
    )
      return
    // Get the filtered data
    const updatedData = getFilteredData(
      values?.startDate,
      values?.endDate,
      data
    )

    // Update the Bar and Piechart local state and in formik
    const barChartData = frameBarChartData(updatedData, values?.endDate)
    const pieChartData = framePieChartData(updatedData)
    setBarChartData(barChartData)
    setPieChartData(pieChartData)
    setFieldValue('barChartData', barChartData)
    setFieldValue('pieChartData', pieChartData)

    // Update the PieChart summary text
    pieChartSummarySetter(pieChartData, updatedData)
  }

  const onChangeDate = (path) => (date) => {  
    setNoDataError(!data)
    setIsTriggerValidation(true)
    date = date.target ? date.target.value : moment(date).format(DATE_FORMAT)
    setFieldValue(path, date)
  }

  return (
    <Container
      xlColumn={12}
      lColumn={12}
      mColumn={12}
      sColumn={12}
      width={width}
    >
      <PageTitleWrapper>
        {accessControlFunc({
          id: 'tc.pages.documents-and-reports.show-public-toggle',
          userAccess
        }) && pageTitle()}
        {toolbar()}
      </PageTitleWrapper>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        background={COLORS.WHITE}
      >
        <ContentWrapper>
          <div>
            <div style={{ width: '100%' }}>
              <TextInput
                id={'report-name'}
                labelText={`${'Report Title'}`}
                value={values?.reportTitle}
                placeholder="EU Report"
                name="reportTitle"
                onChange={(e) => {
                  setIsTriggerValidation(true)
                  setFieldTouched('reportTitle')
                  setFieldValue('reportTitle', e?.target?.value)
                }}
                hasError={errors?.['reportTitle'] && touched?.reportTitle}
                isRequired={true}
                errorMessage={
                  errors?.['reportTitle'] && touched?.reportTitle
                    ? errors?.['reportTitle']
                    : ''
                }
                maxLength={MAX_LENGTH_OF_NAME}
                customLabelClass={'sub-title'}
              />
            </div>
            <OrganizationWrap>
              <LabelWrapper>
                <label
                  className={`${errors?.organization ? 'error-text' : ''}`}
                >{`${t('common:Organization')}`}</label>
                <span className="account-details">
                  {buildingDetails?.accountName}
                </span>
              </LabelWrapper>
              <LabelWrapper>
                <label
                  className={`${errors?.building ? 'error-text' : ''}`}
                >{`${t('opportunities:Building')} Name`}</label>
                <span className="account-details">{buildingDetails?.name}</span>
              </LabelWrapper>
            </OrganizationWrap>
            <DivWrapper>
              <div>
                <label>{'StartDate'}:*</label>
                <ModalDatePickerInput
                  selectMonth={true}
                  date={values?.startDate ? values?.startDate : ''}
                  onChange={onChangeDate('startDate')}
                  isRequired={true}
                  hasError={Boolean(errors?.startDate)}
                  errorMessage={errors?.startDate}
                />
              </div>
              <div>
                <span className="date-picker-separator">to</span>
              </div>
              <div>
                <label>{'EndDate'}:*</label>
                <ModalDatePickerInput
                  selectMonth={true}
                  date={values?.endDate ? values?.endDate : ''}
                  onChange={onChangeDate('endDate')}
                  isRequired={true}
                  hasError={Boolean(errors?.endDate)}
                  errorMessage={errors?.endDate}
                />
              </div>
            </DivWrapper>
            {!errors?.startDate && !errors?.endDate && (
              <>
                {!pieChartData?.pieData ||
                _every(pieChartData?.pieData, { value: 0 }) ? (
                  <ErrorContent>
                    There is no data for the date range provided. Please select
                    another date range or upload additional utility bill data.
                  </ErrorContent>
                ) : (
                    (dataError || noDataError) && (
                    <ErrorContent>
                      There is insufficient data to calculate the EUI and ECI
                      data for the date range provided. Please select another
                      date range or upload additional utility bill data.
                    </ErrorContent>
                  )
                )}
              </>
            )}

            <SelectWrapper width={width}>
              <label>{'Status'}</label>
              <Select
                selectedItem={getStatusOptionList(t)?.find((option)=>option.key===values?.status)?.value||values?.status}
                placeholder={t('common:SelectOne')}
                className="dropdown-select"
                options={getStatusOptionList(t)}
                onChange={(event) => {
                  setIsTriggerValidation(true)
                  setFieldValue('status', event)
                }}
              />
            </SelectWrapper>
          </div>
          <ContactDivWrap>
            <ContactInfo
              values={values}
              errors={errors}
              setFieldValue={(name, value) => {
                setFieldValue(name, value)
                setIsTriggerValidation(true)
              }}
            />
          </ContactDivWrap>
        </ContentWrapper>
      </Content>
    </Container>
  )
}

export default EUReportHeadSection
