import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import {
  containerCss,
  inputRootCss,
  inputCss,
  svgCss,
  colorResolver
} from '../../styles/radio-checkbox'
import { typographyFontFamily } from '../../ui-system/typography'

// --------------------------------------------- Styled Component ---------------------------------------------

const inputStatesDefaultColorInitFn = ({ checked, disabled, error, hover }) => {
  return css`
    // on focus
    &:focus ~ svg {
      .input-stroke {
        stroke-width: 2px;
        stroke: ${checked};
      }
    }

    // on hover
    &:hover:not(:disabled) ~ svg {
      .input-stroke {
        stroke-width: 2px;
      }
    }

    // on hover but not checked & disabled
    &:hover:not(:checked):not(:disabled) ~ svg {
      .input-stroke {
        stroke: ${hover};
      }
      .input-circle {
        fill: ${hover};
      }
    }

    // on checked
    &:checked ~ svg {
      .input-circle {
        fill: ${checked};
      }
    }

    // on disabled
    &:disabled ~ svg {
      cursor: initial;
      .input-stroke {
        fill: #f9fafc;
        stroke: ${disabled};
      }
      .input-circle {
        fill: ${disabled};
      }
    }

    // on disabled but not checked
    &:disabled:not(:checked) ~ svg {
      cursor: initial;
      .input-stroke {
        fill: ${disabled};
        stroke: ${disabled};
      }
    }

    // on error
    &.error ~ svg {
      .input-stroke {
        stroke: ${error};
      }
    }
  `
}

const svgDefaultColorInitFn = ({ unchecked }) => {
  return css`
    .input-stroke {
      stroke: ${unchecked};
    }
  `
}

const RadioRoot = styled.label`
  ${typographyFontFamily}
  ${containerCss}
`

const RadioInputRoot = styled.span(inputRootCss)

const RadioInput = styled.input(
  inputCss,
  colorResolver(inputStatesDefaultColorInitFn)
)

const Svg = styled.svg(svgCss, colorResolver(svgDefaultColorInitFn))

// --------------------------------------------- Component ---------------------------------------------

const Radio = React.forwardRef(function Radio(inProps, ref) {
  const {
    className,
    disabled,
    error,
    inputStateColors,
    label,
    labelPosition,
    size,
    testName
  } = inProps

  return (
    <RadioRoot
      fontFamily="primary"
      size={size}
      className={className}
      disabled={disabled}
    >
      {label && labelPosition === 'left' && <span>{label}</span>}

      <RadioInputRoot style={labelPosition === 'left' ? {marginLeft: "5px"} : {marginRight: "5px"}}>
        <RadioInput
        data-testid={`${testName || 'tc'}_radio`}
          {...inProps}
          className={`${error ? 'error' : ''}`}
          type="radio"
          ref={ref}
        />

        <Svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          inputStateColors={inputStateColors}
        >
          <circle
            cx="50%"
            cy="50%"
            r="44%"
            fill="transparent"
            strokeWidth="1"
            className="input-stroke"
          />
          <circle
            cx="50%"
            cy="50%"
            r="20%"
            fill="transparent"
            stroke="transparent"
            strokeWidth="1"
            className="input-circle"
          />
        </Svg>
      </RadioInputRoot>

      {label && labelPosition === 'right' && <span>{label}</span>}
    </RadioRoot>
  )
})

Radio.propTypes = {
  /**
   * The option element to populate within select.
   */
  checked: PropTypes.bool,

  /**
   * If true, the option is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * If true, the input will indicate an error.
   */
  error: PropTypes.bool,

  /**
   * Customize the states (checked, disabled, ...) of input color
   */
  inputStateColors: PropTypes.shape({
    checked: PropTypes.string,
    disabled: PropTypes.string,
    error: PropTypes.string,
    hover: PropTypes.string,
    unchecked: PropTypes.string
  }),

  /**
   * The label content.
   */
  label: PropTypes.any,

  /**
   * The label postion.
   */
  labelPosition: PropTypes.oneOf(['left', 'right']),

  /**
   * The size of the component. It uses font-size for the size (default 15px).
   * It accepts any font-size value with units.
   */

  size: PropTypes.string,

  /**
   * The value of the component.
   */
  value: PropTypes.any,

  testName: PropTypes.string
}

Radio.defaultProps = {
  disabled: false,
  error: false,
  labelPosition: 'right'
}

export default React.memo(Radio)
