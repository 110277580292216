export const STATUS = new Map([
  ['recommended', 'Recommended'],
  ['dismissed', 'Dismissed'],
  ['inProgress', 'In Progress'],
  ['remoteResolved', 'Remote Resolved'],
  ['onSiteResolved', 'On-Site Resolved'],
  ['hold', 'Hold'],
  ['validation', 'Validation'],
  ['complete', 'Complete'],
  ['archive', 'Archive']
])
export const STATUS_OTHER_KEY = 'other'
export const CALCULATION_PREREADY_FINDING_STATUSES = [
  'recommended',
  'hold',
  'deffered'
]
export const CALCULATION_READY_FINDING_STATUSES = [
  'inProgress',
  'remoteResolved',
  'onSiteResolved',
  'validation',
  'complete'
]

export const FINDING_ISSUE_FOUND = new Map([
  ['OEAssessment', 'OE Assessment'],
  ['onSiteVisit', 'On-Site Visit'],
  ['RSSInvestigation', 'RSS Investigation'],
  [
    'customerRequestedRemoteInvestigation',
    'Customer Requested Remote Investigation'
  ]
])

export const SORT_BY_ENUM = [
  { key: 'creationDate', value: 'Creation Dates' },
  { key: 'status', value: 'Status' },
  { key: 'priority', value: 'Priority' },
  { key: 'nextStep', value: 'Next Step' }
]

export const FILTER_BY_DATE = [
  { key: 'last-week', value: 'In the last week' },
  { key: 'last-month', value: 'In the last month' },
  { key: 'last-3-months', value: 'In the last 3 months' },
  { key: 'last-6-months', value: 'In the last 6 months' },
  { key: 'last-year', value: 'In the last year' }
]

export const STATUS_OPTION_LIST = Array.from(STATUS, ([key, value]) => ({
  key,
  value
}))

export function statusOptionList(translateFunc) {
  return STATUS_OPTION_LIST.map(({ key, value }) => ({
    key,
    value: translateFunc
      ? translateFunc(`components:findingStatuses>${key}`)
      : value
  }))
}
export function statusOptionListForFilters(translateFunc) {
  return STATUS_OPTION_LIST.map(({ key, value }) => ({
    key,
    value: translateFunc(`components:findingStatuses>${key}`),
    name: translateFunc(`components:findingStatuses>${key}`)
  }))
}

export function statusOptionListForTree(translateFunc) {
  return STATUS_OPTION_LIST.map(({ key, value }) => ({
    value: key,
    label: translateFunc
      ? translateFunc(`components:findingStatuses>${key}`)
      : value
  }))
}

export const STATUS_LIST = [...STATUS.keys()]

export const NEXT_STEPS = new Map([
  ['buildingPersonnelReview', 'Building Personnel Review'],
  ['remoteJDI', 'Remote JDI'],
  ['BASTechOnSite', 'BAS Tech On-Site'],
  ['mechTechOnSide', 'Mech Tech On-Site'],
  ['buildingPersonnelToHandle', 'Building Personnel to Handle'],
  ['complete', 'Complete'],
  ['archive', 'Archive']
])

export const NEXT_STEPS_OPTION_LIST = Array.from(
  NEXT_STEPS,
  ([key, value]) => ({ key, value })
)

export function nextStepOptionList(translateFunc) {
  return NEXT_STEPS_OPTION_LIST.map(({ key, value }) => ({
    key,
    value: translateFunc
      ? translateFunc(`components:nextStepsFinding>${key}`)
      : value
  }))
}

export function formatNextStep(id) {
  return NEXT_STEPS.get(id)
}

export const FINDING_ISSUE_FOUND_LIST = [...FINDING_ISSUE_FOUND.keys()]
export const FINDING_ISSUE_FOUND_OPTION_LIST = Array.from(
  FINDING_ISSUE_FOUND,
  ([key, value]) => ({ key, value })
)

export function findingIssueFoundOptionList(translateFunc) {
  return FINDING_ISSUE_FOUND_OPTION_LIST.map(({ key, value }) => ({
    key,
    value: translateFunc
      ? translateFunc(`components:findingIssueFound>${key}`)
      : value
  }))
}

export function findingIssueFoundFilterOptionList(translateFunc) {
  return FINDING_ISSUE_FOUND_OPTION_LIST.map(({ key, value }) => ({
    key,
    value: key,
    name: translateFunc
      ? translateFunc(`components:findingIssueFound>${key}`)
      : value
  }))
}

export function filterByDateOptionList(translateFunc) {
  return FILTER_BY_DATE.map(({ key, value }) => ({
    key,
    value: translateFunc
      ? translateFunc(`components:filterByDate>${key}`)
      : value
  }))
}

export function formatStatus(id) {
  return STATUS.get(id)
}

export function getSortValueByKey(key) {
  const item = SORT_BY_ENUM.find((s) => s.key === key)

  return item ? item.value : key
}
export function formatFindingIssueFound(id, translateFunc) {
  return translateFunc
    ? translateFunc(`components:findingIssueFound>${id}`)
    : id
}

export const PRIORITY_SORT_ORDER = new Map([
  ['URGENT!', 4],
  ['High', 3],
  ['Medium', 2],
  ['Low', 1],
  ['Not Proritized', 0]
])

export const STATUS_SORT_ORDER = new Map([
  ['recommended', 1],
  ['dismissed', 2],
  ['inProgress', 3],
  ['remoteResolved', 4],
  ['onSiteResolved', 5],
  ['hold', 6],
  ['validation', 7],
  ['complete', 8]
])

export const NEXT_STEP_SORT_ORDER = new Map([
  ['buildingPersonnelReview', 1],
  ['remoteJDI', 2],
  ['BASTechOnSite', 3],
  ['mechTechOnSide', 4],
  ['buildingPersonnelToHandle', 5],
  ['complete', 6],
  ['archive', 7]
])

export const TRACK_CHANGED_FIELDS = new Map([
  ['name', 'Name'],
  ['visibleToCustomer', 'Visible to Customer'],
  ['organization.organizationId', 'Organization'],
  ['location', 'Building(s)'],
  ['tisObjects', 'Equipment(s)'],
  ['foundDuring', 'Issue found during'],
  ['settings.kpi', 'KPIs'],
  ['settings.headlines', 'Headlines'],
  ['settings.chartLink', 'Chart link'],
  ['settings.descriptionLink', 'Link description'],
  ['priority', 'Priority level'],
  ['settings.file', 'Upload file'],
  ['settings.image', 'Image'],
  ['status', 'Status'],
  ['nextStep', 'Next Step'],
  ['assignedTo', 'Assigned To']
])

export const TRACK_CHANGED_FIELDS_LIST = [...TRACK_CHANGED_FIELDS.keys()]

export function formatTrackChangedFields(id) {
  return TRACK_CHANGED_FIELDS.get(id) || id
}

export const FINDING_CSV_MAP = new Map([
  ['locationId', 'Location ID (Required)'],
  ['organizationId', 'Organization ID (Required)'],
  ['name', 'Issue/Findings Title (Required)'],
  ['creationDate', 'Identification Date (Optional)'],
  ['title', 'About This Finding (Optional)'],
  ['description', 'Recommendation/Most Likely Causes (Optional)'],
  ['priority', 'Priority (Optional)'],
  ['status', 'Status (Required)'],
  ['nextStep', 'Next Step (Optional)'],
  ['foundDuring', 'Found During (Optional)'],
  ['energyImpact', 'Energy Impact (Optional)'],
  ['performanceImpact', 'Performance Impact (Optional)'],
  ['comfortImpact', 'Comfort Impact (Optional)'],
  ['reliabilityImpact', 'Reliability Impact (Optional)'],
  ['complianceImpact', 'Compliance Impact (Optional)']
])

export function formatFindingCSV(id) {
  return FINDING_CSV_MAP.get(id) || id
}

export const DEFAULT_CSV_FINDING = [
  [...FINDING_CSV_MAP.values()],
  [
    'e.g. 33432',
    'e.g. 66556',
    'e.g. Example Name of Finding (max 200 letters)',
    'DD/MM/YYYY (e.g. 12/05/2018)',
    'e.g. Some text about the Finding',
    'e.g. Some text',
    `Options: ${Array.from(PRIORITY_SORT_ORDER.keys()).join(', ')}`,
    `Options: ${Array.from(STATUS.keys()).join(', ')}`,
    `Options: ${Array.from(NEXT_STEPS.keys()).join(', ')}`,
    `Options: ${Array.from(FINDING_ISSUE_FOUND.keys()).join(', ')}`,
    'Options: high, medium, low',
    'Options: high, medium, low',
    'Options: high, medium, low',
    'Options: high, medium, low',
    'Options: high, medium, low'
  ]
]

export const DEFAULT_DESCRIPTION_BLOCK = {
  data: {},
  depth: 0,
  entityRanges: [],
  text: '',
  type: 'unstyled',
  key: '',
  inlineStyleRanges: []
}
