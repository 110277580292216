import initMergeThemeFn, {
  mergeLightAndDarkTheme,
  mergeSize
} from '../common/themeMapper'

// ---------------------------------------------- Default Button Styles ----------------------------------------------

export const defaultStyle = {
  primary: {
    light: {
      backgroundColor: 'rgb(32, 111, 207)',
      color: '#ffffff',
      hover: {
        backgroundColor: 'rgb(0, 50, 108)'
      },
      disabled: {
        backgroundColor: 'rgb(226, 226, 226)',
        color: 'rgb(153, 153, 153)'
      }
    },
    dark: {
      backgroundColor: 'rgb(32, 111, 207)',
      color: '#ffffff',
      hover: {
        backgroundColor: 'rgb(0, 50, 108)'
      },
      disabled: {
        backgroundColor: 'rgb(226, 226, 226)',
        color: 'rgb(153, 153, 153)'
      }
    }
  },
  secondary: {
    light: {
      backgroundColor: 'rgb(238, 238, 238)',
      color: 'rgb(32, 111, 207)',
      hover: {
        backgroundColor: 'rgb(204, 204, 204)'
      },
      disabled: {
        backgroundColor: 'rgb(226, 226, 226)',
        color: 'rgb(153, 153, 153)'
      }
    },
    dark: {
      backgroundColor: 'rgb(112, 112, 112)',
      color: 'rgb(255, 255, 255)',
      hover: {
        backgroundColor: 'rgb(102, 102, 102)'
      },
      disabled: {
        backgroundColor: 'rgb(112, 112, 112)',
        color: 'rgb(153, 153, 153)'
      }
    }
  },
  tertiary: {
    light: {
      backgroundColor: 'rgb(255, 255, 255)',
      color: 'rgb(112, 112, 112)',
      hover: {
        backgroundColor: 'rgb(244, 244, 244)'
      },
      disabled: {
        backgroundColor: 'rgb(226, 226, 226)',
        color: 'rgb(153, 153, 153)'
      }
    },
    dark: {
      backgroundColor: 'rgb(255, 255, 255)',
      color: 'rgb(112, 112, 112)',
      hover: {
        backgroundColor: 'rgb(244, 244, 244)'
      },
      disabled: {
        backgroundColor: 'rgb(226, 226, 226)',
        color: 'rgb(153, 153, 153)'
      }
    }
  },
  outlined: {
    light: {
      border: '1px solid rgb(32,111,207)',
      backgroundColor: '#ffffff',
      color: 'rgb(32, 111, 207)',
      hover: {
        backgroundColor: 'rgb(0, 50, 108)',
        color: '#ffffff'
      },
      disabled: {
        backgroundColor: '#e2e2e2',
        borderColor: '#e2e2e2',
        color: '#999999'
      }
    },
    dark: {
      backgroundColor: 'inherit',
      border: '1px solid #ffffff',
      color: '#ffffff',
      hover: {
        backgroundColor: '#ffffff',
        color: 'rgb(32, 111, 207)'
      },
      disabled: {
        backgroundColor: '#e2e2e2',
        border: '1px solid #e2e2e2',
        color: 'rgb(32 111 207 / 0.5)'
      }
    }
  },
  text: {
    light: {
      backgroundColor: '#ffffff',
      color: 'rgb(32, 111, 207)',
      hover: {
        backgroundColor: 'rgb(245, 246, 250)'
      },
      disabled: {
        color: '#00000042'
      }
    },
    dark: {
      backgroundColor: 'transparent',
      color: '#ffffff',
      hover: {
        backgroundColor: 'rgb(245, 246, 250)',
        color: 'rgb(32, 111, 207)'
      },
      disabled: {
        color: 'rgb(153,153,153)'
      }
    }
  },
  link: {
    light: {
      backgroundColor: 'transparent',
      color: 'rgb(32, 111, 207)',
      padding: 'initial',
      hover: {
        textDecoration: 'underline'
      }
    },
    dark: {
      backgroundColor: 'transparent',
      color: '#555555',
      padding: 'initial',
      hover: {
        textDecoration: 'underline'
      }
    }
  },
  size: {
    small: {
      fontSize: 14,
      padding: '6px 15px'
    },
    medium: {
      fontSize: 16
    },
    large: {
      fontSize: 18,
      padding: '14px 19px'
    }
  }
}

// ---------------------------------------------- Theme Mapper ----------------------------------------------

export const mergeTheme = initMergeThemeFn({
  defaultStyle,
  // {style-key (size, etc...) : resolver (mergeSize, etc...) }
  propResolver: {
    default: mergeLightAndDarkTheme,
    size: mergeSize
  }
})
