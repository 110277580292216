import {
  BOTTOM_BOTTOM,
  LEFT_LEFT,
  RIGHT_RIGHT,
  SPACE_BEFORE_AXIS_NAME,
  SPACE_BEFORE_SYMBOL,
  SPACE_BEFORE_TICK_TEXT,
  TOP_TOP
} from 'src/chart-library/CommonComponents/Axis/constants'
import { getNumberIfUndefined, newDateString } from '../../helper'
import { updateCanvasDetails } from '../Canvas/update'
import { determinePadding } from './helper'
import { max } from 'lodash'

export const updateAfterPadding = (state: any = {}, config: any = {}) => {
  // create new state
  let newState = {
    ...state
  }

  const paddingObject = determinePadding(newState?.generatedScales, newState)
  const axisPadding: any = {}
  Object.keys(paddingObject)?.forEach((key) => {
    const spaceDetails = paddingObject?.[key]
    const isY = key?.includes('y')
    const spaceInBet =
      (spaceDetails?.textSize > 0 ? SPACE_BEFORE_TICK_TEXT : 0) +
      (spaceDetails?.axisName > 0 ? SPACE_BEFORE_AXIS_NAME : 0)
    let padding =
      getNumberIfUndefined(spaceDetails?.tickSize) +
      getNumberIfUndefined(spaceDetails?.textSize) +
      getNumberIfUndefined(spaceDetails?.axisName) +
      spaceInBet
    // Pending: Need to work on symbol padding

    if (isY) {
      const visibleAt = state?.axes?.find((obj: any) => obj?.key === key)?.axis
        ?.visibleAt?.[0]

      const isLeftOrRight = visibleAt === LEFT_LEFT || visibleAt === RIGHT_RIGHT

      if (isLeftOrRight) {
        const symbolPaddingRequired =
          (spaceDetails?.width > 0 && spaceDetails?.height > 0
            ? SPACE_BEFORE_SYMBOL
            : 0) + getNumberIfUndefined(spaceDetails?.symbol?.width)
        padding += (symbolPaddingRequired > padding ? symbolPaddingRequired - padding : 0)
        // Space required for Perpendicular axis
        const canvasIndex = state?.axisCanvas?.[key]?.canvasIndex
        const axes = newState?.axes?.filter((obj) => {
          const cI = obj?.canvasIndex || '0'
          return cI === canvasIndex && obj?.axis?.visibleAt?.[0]
        })
        const topKey = axes?.find(
          (obj: any) => obj?.axis?.visibleAt?.[0] === TOP_TOP
        )?.key
        const perpendicularAxisPadding =
          (spaceDetails?.width > 0 && spaceDetails?.height > 0
            ? SPACE_BEFORE_SYMBOL
            : 0) + getNumberIfUndefined(spaceDetails?.symbol?.height)
        if (topKey) {
          axisPadding[topKey] =
            getNumberIfUndefined(axisPadding[topKey]) + perpendicularAxisPadding
        } else {
          axisPadding['top'] = perpendicularAxisPadding
        }
      }
    }

    axisPadding[key] = padding
  })
  const paddingDetails: any = {}

  Object.keys(newState?.canvasDetails)?.forEach((canvasIndex) => {
    const axes = newState?.axes?.filter((obj) => {
      const cI = obj?.canvasIndex || '0'
      return cI === canvasIndex && obj?.axis?.visibleAt?.[0]
    })

    const leftKey = axes?.find(
      (obj: any) => obj?.axis?.visibleAt?.[0] === LEFT_LEFT
    )?.key
    const leftPadding = axisPadding?.[leftKey] || axisPadding?.['left'] || 10

    const rightKey = axes?.find(
      (obj: any) => obj?.axis?.visibleAt?.[0] === RIGHT_RIGHT
    )?.key
    const rightPadding = axisPadding?.[rightKey] || axisPadding?.['right'] || 10

    const topKey = axes?.find(
      (obj: any) => obj?.axis?.visibleAt?.[0] === TOP_TOP
    )?.key
    const topPadding = axisPadding?.[topKey] || (getNumberIfUndefined(axisPadding?.['top']) + 10) || 10

    const bottomKey = axes?.find(
      (obj: any) => obj?.axis?.visibleAt?.[0] === BOTTOM_BOTTOM
    )?.key
    const bottomPadding =
      axisPadding?.[bottomKey] || axisPadding?.['bottom'] || 10

    paddingDetails[canvasIndex] = {
      top: topPadding,
      right: rightPadding,
      bottom: bottomPadding,
      left: leftPadding
    }
  })
  
  const leftPaddingValues: any = Object.values(paddingDetails || {})?.map((obj: any) => parseFloat(obj?.left)) || []
  const maxLeftPadding = max(leftPaddingValues)
  const rightPaddingValues: any = Object.values(paddingDetails || {})?.map((obj: any) => parseFloat(obj?.right)) || []
  const maxRightPadding = max(rightPaddingValues)

  Object?.keys(paddingDetails || {})?.forEach((key: any) => {
    paddingDetails[key].left = maxLeftPadding
    paddingDetails[key].right = maxRightPadding
  })
  const newStateUpdated =
    updateCanvasDetails(newState, config, paddingDetails) || {}
  newState = {
    ...newStateUpdated
  }

  const newGeneratedScales: any = {}

  Object.keys(newState?.generatedScales)?.forEach((key) => {
    const canvasIndex = newState?.axisCanvas?.[key]?.canvasIndex || '0'
    const canvasDetails = newState?.canvasDetails?.[canvasIndex]
    const gObj = newState?.generatedScales?.[key] || {}
    const newRange = key?.includes('x')
      ? [0, canvasDetails?.width]
      : [canvasDetails?.height, 0]
    newGeneratedScales[key] = {
      ...gObj,
      range: newRange,
      gScale: gObj && gObj?.gScale?.range(newRange)
    }
  })

  newState = {
    ...newState,
    paddingDetails: paddingObject,
    paddingDetailsT: newDateString(),
    generatedScales: newGeneratedScales,
    generatedScalesT: newDateString()
  }

  return newState
}
