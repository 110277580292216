import styled from 'styled-components'

export const AccordionStyles = styled.section`
  width: 100%;
`

export const AccordionArticle = styled.article``

export const ArticleHeader = styled.header`
  display: flex;
  padding: 8px 20px;
  border-bottom: 1px solid #ccc;
  align-items: center;
`

export const ArticleHeaderText = styled.p`
  color: ${(props) => props.colors.MED_GREY};
  margin: 0;
  padding: 0;
  flex: 1 0 auto;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.26px;
`

export const ArticleContent = styled.div`
  margin-top: 10px;
  padding: 8px 20px;
`
