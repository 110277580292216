export const LIST_NOTES_BY_BUILDING_ID = `query LIST_NOTES_BY_BUILDING_ID($id: ID!, $filter: ModelNoteFilterInput) {
  listNotesByBuilding(buildingId: $id, filter: $filter, limit: 1000) {
          items {
            id
            name
            type
            updatedAt
            userId
            user {
              firstName
              lastName
            }
            equipmentId
            createdBy
            createdAt
            buildingId
            building {
              name
            }
            analyticServiceAdvisoryId
            histories {
              items {
                attachments {
                  items {
                    attachedAt
                    buildingId
                    createdAt
                    description
                    id
                    historyId
                    name
                    type
                    title
                    sizeInBytes
                    opportunityId
                    url
                    updatedAt
                  }
                }
                buildingId
                comment
                createdAt
                createdBy
                userId
                user {
                  firstName
                  lastName
                  id
                }
                id
                updatedAt
              }
            }
            attachments {
              items {
                attachedAt
                historyId
                buildingId
                createdAt
                description
                id
                issueId
                name
                noteId
                opportunityId
                sizeInBytes
                type
                title
                updatedAt
                url
              }
            }
            equipment {
              id
              name
            }
          }
      }
    }
`
