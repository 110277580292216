import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

export const NotificationContext = React.createContext({
  notification: null,
  addNotification: () => {},
  removeNotification: () => {}
})

function NotificationProvider({ children }) {
  const [notification, setNotification] = useState(null)

  const contextValue = {
    notification,
    addNotification: useCallback((notification) => {
      setNotification(notification)
    }, []),
    removeNotification: useCallback(() => {
      setNotification(null)
    }, [])
  }

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  )
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default NotificationProvider
