export const ListBuildingDevices = /* GraphQL */ `
  query listBuildingEquipments($id: ID!) {
    getBuilding(id: $id) {
      devices(filter: { isActive: { eq: 1 } }) {
        items {
          id
          name
        }
      }
    }
  }
`

export const SearchBuildingEquipments = /* GraphQL */ `
  query searchBuildingEquipments(
    $filter: SearchableBuildingFilterInput
    $equipmentFilter: ModelEquipmentFilterInput
  ) {
    searchBuildings(filter: $filter) {
      items {
        equipments(filter: $equipmentFilter) {
          total
          items {
            id
            name
            isParent
            parentId
            type
            family
            # dataSourceName
            # dataSourceType
            uri
            device {
              type
            }
          }
        }
      }
    }
  }
`

export const searchBuildings = /* GraphQL */ `
  query SearchBuildings(
    $filter: SearchableBuildingFilterInput
    $sort: [SearchableBuildingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBuildingAggregationInput]
  ) {
    searchBuildings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        address
      }
    }
  }
`

export const getEquipmentDetailsbyId = /* GraphQL */ `
  query getEquipmentDetailsbyId($id: ID!) {
    getEquipment(id: $id) {
      id
      name #TIS EQUIPMENT  DATA ASSOCISTION
      family # EQUIPMENT TYPE
      type
      uri
      imageUrl
      isParent
      parentId
      dataSourceName
      dataSourceType
      copyMappingEquipmentId
      dataMappingEquipmentId
      equipmentProperties(
        filter: { targetKey: { eq: "UnitSerialNumber" }}, limit: 1000
      ) {
        items {
          id
          equipmentId
          targetKey
          value
        }
      }
      deviceId
      device {
        id
        name
        type
        serial
      }
      building {
        id
        name
      }
    }
  }
`

export const getAutomatedTestResultsByEquipmentType = /* GraphQL */ `
  query GetEquipmentAutomatedTests($equipmentType: String!) {
    getByAnalyticByEquipmentType(equipmentType: $equipmentType) {
      items {
        id
        name
        description
        properties
        characteristics
        conditionCheck
        exceptionInputs
        applicabilityCheck
        equipmentComponent
        statusCalc
      }
    }
  }
`

//To fetch data for Equipment Mapping Properties
export const GET_DATA_MAPPING_PROPERTIES = `query GET_DATA_MAPPING_PROPERTIES($id: ID!) {
  getPropertyMappingByEquipmentID(equipmentId: $id, propertyType: {eq: "Property"},limit: 1000) {
    items {
      id
      sourceDisplayName
      sourceEquipment {
        name
        automationSystem
        dataSourceType
        dataSourceName
        device {
          name
        }
      }
      targetKey
      tisDisplayName
      sourceMeasureUnit
      targetMeasureUnit
      targetMeasureType
      propertyType
      creationType 
      isMappable
      value
      targetDataGroup
      sourceKey
      sourceUri
      sourceDeviceId
      sourceDataGroup
      polarityNormal
      sourceMeasureType
      sourceEquipmentId
      maxValue
      minValue
      subPropertyDisplayName
    }
  }
}

`

//To fetch data for Equipment Mapping Properties
export const GET_DATA_MAPPING_PROPERTIES_PAGINATED = `query GET_DATA_MAPPING_PROPERTIES_PAGINATED($id: ID!,$nextToken: String) {
  getPropertyMappingByEquipmentID(equipmentId: $id, propertyType: {eq: "Property"},nextToken: $nextToken,limit: 1000) {
    items {
      id
      sourceDisplayName
      sourceEquipment {
        name
        automationSystem
        dataSourceType
        dataSourceName
        device {
          name
        }
      }
      targetKey
      tisDisplayName
      sourceMeasureUnit
      targetMeasureUnit
      targetMeasureType
      propertyType
      creationType 
      isMappable
      value
      targetDataGroup
      sourceKey
      sourceUri
      sourceDeviceId
      sourceDataGroup
      polarityNormal
      sourceMeasureType
      sourceEquipmentId
      maxValue
      minValue
      subPropertyDisplayName
    }
    nextToken
  }
}

`
//To fetch data for Equipment Mapping Calculated  // ID FOR Mutation
export const GET_DATA_MAPPING_CALCULATED = `query GET_DATA_MAPPING_CALCULATED($id: ID!) {
  getPropertyMappingByEquipmentID(equipmentId: $id,  propertyType: {eq: "Calculated"},limit: 1000) {
    items {
      id
      sourceDisplayName
      sourceEquipment {
        name
        automationSystem
        dataSourceType
        dataSourceName
        device {
          name
        }
      }
      targetKey
      tisDisplayName
      sourceMeasureUnit
      targetMeasureUnit
      targetMeasureType
      propertyType
      creationType 
      isMappable
      value
      sourceKey
      sourceUri
      sourceDeviceId
      targetDataGroup
      sourceDataGroup
      polarityNormal
      sourceMeasureType
      sourceEquipmentId
      maxValue
      minValue
      subPropertyDisplayName
    }
  }
}

`

//To fetch data for Equipment Mapping Calculated  // ID FOR Mutation
export const GET_DATA_MAPPING_CALCULATED_PAGINATED = `query GET_DATA_MAPPING_CALCULATED($id: ID!) {
  getPropertyMappingByEquipmentID(equipmentId: $id,  propertyType: {eq: "Calculated"},limit: 1000) {
    items {
      id
      sourceDisplayName
      sourceEquipment {
        name
        automationSystem
        dataSourceType
        dataSourceName
        device {
          name
        }
      }
      targetKey
      tisDisplayName
      sourceMeasureUnit
      targetMeasureUnit
      targetMeasureType
      propertyType
      creationType 
      isMappable
      value
      sourceKey
      sourceUri
      sourceDeviceId
      targetDataGroup
      sourceDataGroup
      polarityNormal
      sourceMeasureType
      sourceEquipmentId
      maxValue
      minValue
      subPropertyDisplayName
    }
      nextToken
  }
}

`

export const GET_PROPERTIES_BY_EQ_ID = `query GET_PROPERTIES_BY_EQ_ID($id: ID!,$nextToken: String) {
  getPropertyMappingByEquipmentID(filter: {targetKey: {attributeExists: false}}, equipmentId: $id,nextToken:$nextToken) {
    items {
      id
      sourceKey
      sourceUri
      sourceDeviceId
      sourceDataGroup
      sourceDisplayName
      sourceMeasureUnit
      sourceMeasureType
      sourceEquipmentId
      subPropertyDisplayName
    }
    nextToken
  }
}
`

//To fetch data for Equipment Mapping Characteristic
export const GET_DATA_CHARACTERISTICS = `query GET_DATA_CHARACTERISTICS($id: ID!) {
  getPropertyMappingByEquipmentID(equipmentId: $id, propertyType: {eq: "Characteristic"},limit: 1000) {
    items {
      id
      sourceDisplayName
      sourceUri
      sourceDeviceId
      tisDisplayName
      sourceMeasureUnit
      targetMeasureUnit
      targetMeasureType
      propertyType
      creationType 
      value
      targetKey
      targetDataGroup
      targetDataType
      maxValue
      minValue
      sourceDataGroup
    }
  }
}

`
//To fetch data for Equipment Mapping Characteristic
export const GET_DATA_CHARACTERISTICS_PAGINATED = `query GET_DATA_CHARACTERISTICS($id: ID!) {
  getPropertyMappingByEquipmentID(equipmentId: $id, propertyType: {eq: "Characteristic"},limit: 1000) {
    items {
      id
      sourceDisplayName
      sourceUri
      sourceDeviceId
      tisDisplayName
      sourceMeasureUnit
      targetMeasureUnit
      targetMeasureType
      propertyType
      creationType 
      value
      targetKey
      targetDataGroup
      targetDataType
      maxValue
      minValue
      sourceDataGroup
    }
    nextToken
  }
}

`

//To fetch data for Child Equipment Details
export const GET_CHILD_EQUIPMENTDETAILS = `query GET_CHILD_EQUIPMENTDETAILS($parentId: ID  ) {
  searchEquipment(filter: {parentId: {matchPhrase: $parentId},isActive: {eq: 1}}) {
    items {
          id
          name #TIS EQUIPMENT  DATA ASSOCISTION
          family # EQUIPMENT TYPE  
          type
          uri
          isParent
          parentId 
    }
  }
}

`

//To fetch data for Child Equipment Details
export const LIST_CHILD_EQUIPMENT_DETAILS = `query listChildEquiipmentDetails($buildingId: ID!,$parentId:ID,$nextToken:String) {
  listEquipmentsByBuildingAndActiveAndType(buildingId: $buildingId, 
    isActiveIsTISType: {beginsWith: {isActive: 1, isTIS: 1}}, 
    filter: {parentId: {contains: $parentId}},
    nextToken:$nextToken
    limit:1000
    ) {
    items {
      id
      name #TIS EQUIPMENT  DATA ASSOCISTION
      family # EQUIPMENT TYPE  
      type
      uri
      isParent
      parentId 
    }
    nextToken
  }
}

`
export const GET_EQUIPMENT_LIST_NIAGARA = `
query GET_EQUIPMENT_LIST($deviceId: ID!,$nextToken: String) {
  searchEquipment(
    filter: {
    and: [
      {deviceId: {eq:$deviceId}},
      {isTIS: {eq: 0}},
      {isActive: {eq: 1}},
      {dataSourceType: {ne: "Station"}},
      {dataSourceType: {ne:"Analog Input"}},
      {dataSourceType: {ne:"Binary Input"}},
      {dataSourceType: {ne:"Multi State Input"}},
      {dataSourceType: {ne:"Analog Output"}},
      {dataSourceType: {ne:"Binary Output"}},
      {dataSourceType: {ne:"Multi State Output"}},
      {dataSourceType: {ne:"Analog Value"}},
      {dataSourceType: {ne:"Binary Value"}},
      {dataSourceType: {ne:"Multi State Value"}},
]
    },
    nextToken: $nextToken
    limit: 1000
    ){
    items {
      id  
      name
      uri
      dataSourceName
      dataSourceType
      controllerType
    }
    nextToken
    total
  }
}`

export const GET_NIAGARA_EQUIPMENT_LIST_BY_BUILDING_ID = `
query GET_NIAGARA_EQUIPMENT_LIST($buildingId: ID!,$nextToken: String) {
  searchEquipment(
    filter: {
    and: [
      {buildingId: {eq: $buildingId}},
      {isActive: {eq: 1}},
      {isTIS: {eq: 0}},
      {dataSourceType: {ne: "Station"}},
      {dataSourceType: {ne:"Analog Input"}},
      {dataSourceType: {ne:"Binary Input"}},
      {dataSourceType: {ne:"Multi State Input"}},
      {dataSourceType: {ne:"Analog Output"}},
      {dataSourceType: {ne:"Binary Output"}},
      {dataSourceType: {ne:"Multi State Output"}},
      {dataSourceType: {ne:"Analog Value"}},
      {dataSourceType: {ne:"Binary Value"}},
      {dataSourceType: {ne:"Multi State Value"}},
]
    },
    sort: {field: name, direction: asc},
    nextToken: $nextToken
    limit: 1000
    ){
    items {
      id  
      name
      uri
      dataSourceName
      dataSourceType
    }
    nextToken
    total
  }
}`

export const GET_CREATE_EQUIPMENT_DROPDOWN_DETAILS = `query GET_CREATE_EQUIPMENT_DROPDOWN_DETAILS($buildingId: ID!) {
  getBuilding(id: $buildingId) {
    devices(filter: { isActive: { eq: 1 } }) {
      items {
        name
        type
        isActive
        serial
        id
        equipments(filter: { isActive: { eq: 1 } }, limit: 10000) {
          items {
            id
            name
            type
            dataSourceType
            dataSourceName
            uri
            controllerType
            deviceId
            isActive
            creationType
          }
        }
      }
    }
  }
}

`

export const GET_PARENT_TIS_EQUIPMENT_TYPES = `query GetParent($subType: String) {
  configByTypeAndSubtype(type: "Parent", subType: {eq: $subType}) {
    items {
      name
      subType
      type
      value
    }
  }
}

`

export const GET_PARENT_TIS_EQUIPMENT = `query GetParent($buildingId: ID!, $type:String) {
  listEquipmentsByBuildingAndActiveAndType(buildingId: $buildingId, isActiveIsTISType: {eq: {isActive: 1, isTIS: 1, type: $type}}) {
    items {
      family
      type
      name,
      id
    }
  }
}

`

//To fetch data to check if equipment name is already exist or not
export const GET_SEARCH_EQUIPMENTS = `query GET_SEARCH_EQUIPMENTS($buildingId: ID, $name: String, $limit: Int) {
  searchEquipment(
    filter: {
      buildingId: {
        eq: $buildingId},
      name: {
        eq: $name}
    }, limit: $limit) {
    items {
      name
    }
    total
  }
}

`

//update equipment name mutation
export const UPDATE_EQUIPMENT = `mutation UPDATE_EQUIPMENT(
  $input:  UpdateEquipmentInput!
) {
  updateEquipment(input: $input) {
  id
}
}`

// To fetch possible Equipment Child list name for Equipment Association
export const GET_CHILD_ASSOCIATION_EQUIPMENTLIST = `query GET_CHILD_ASSOCIATION_EQUIPMENTLIST($parentId: ID, $buildingId: ID, $type: String ) {
  searchEquipment(filter: {and: {buildingId: {eq: $buildingId}, type: {eq: $type},  isActive: { eq: 1}, isTIS: {eq: 1} } ,
       not : { parentId: {matchPhrase: $parentId}}}, limit: 1000, sort: {field: name, direction: asc}) {
     items {
           id
           name  
           family
           type
           uri
           isParent
           parentId 
     }
     total
   }
 }
 
 `
// Condition check if parent exists already	for Equipment Association
export const CHECK_PARENT_ASSOCIATION_EQUIPMENTLIST = `query CHECK_PARENT_ASSOCIATION_EQUIPMENTLIST($buildingId: ID, $id: ID, $parentId: ID  ) {
   searchEquipment(filter: {buildingId: {eq: $buildingId}, id: {eq: $id}, parentId: {matchPhrase: $parentId}}) {
     items {
           id
           name            
     }
     total
   }
 }
 
 `

// To fetch possible Equipment Parent list name for Equipment Association
export const GET_PARENT_ASSOCIATION_EQUIPMENTLIST = `query GET_PARENT_ASSOCIATION_EQUIPMENTLIST($buildingId: ID, $type: String ) {
   searchEquipment(filter: {buildingId: {eq: $buildingId}, type: {eq: $type}, isActive: { eq: 1}, isTIS: {eq: 1}}, limit: 1000, sort: {field: name, direction: asc}) {
     items {
           id
           name  
           family
           type
           uri
           isParent
           parentId 
     }
     total
   }
 }
 `
// Query for copy characteristic and data mapping -
export const LIST_EQUIPMENTS_BY_DEVICE_AND_BY_EQUIPMENT_TYPE = `query LIST_EQUIPMENTS_BY_DEVICE_AND_BY_EQUIPMENT_TYPE($deviceId: ID!, $type: String, $dataSourceType: String) {

  listEquipmentsByDeviceAndByEquipmentType(deviceId: $deviceId, filter: {dataSourceType: {eq: $dataSourceType}, isActive: {eq: 1}, type: {eq: $type}}, limit: 1000) {
    nextToken
    items {
      id
      name
    }
  }
}
`
// Query for copy characteristic and data mapping without filtering by dataSourceType
export const LIST_EQUIPMENTS_BY_DEVICE_AND_BY_EQUIPMENT_TYPE_NIAGARA = `query LIST_EQUIPMENTS_BY_DEVICE_AND_BY_EQUIPMENT_TYPE($deviceId: ID!, $type: String) {
  listEquipmentsByDeviceAndByEquipmentType(deviceId: $deviceId, filter: {isActive: {eq: 1}, type: {eq: $type}}, limit: 1000) {
    nextToken
    items {
      id
      name
    }
  }
}

`
export const GET_BUILDING_EQUIPMENT_TYPES = /* GraphQL */ `
  query getBuildingEquipmentTypes($requestBody: AWSJSON!) {
    getBuildingEquipmentTypes(body: $requestBody)
  }
`

export const GET_AUTOMATION_SYSTEM_DETAILS = `query GET_AUTOMATION_SYSTEM_DETAILS($buildingId: ID!) {
  getBuilding(id: $buildingId) {
    devices(filter: { isActive: { eq: 1 } }) {
      items {
        id
        name
        type
        isActive
        serial
        equipments(filter: { isActive: { eq: 1 } }, limit: 10000) {
          items {
            id
            name
            type
            dataSourceType
            dataSourceName
            uri
            controllerType
            deviceId
            isActive
            creationType
          }
        }
      }
    }
  }
}

`
//To fetch data for Constant PropertyMetadata
export const SEARCH_PROPERTY_METADATA = `query SEARCH_PROPERTY_METADATA($equipmentType: String, $tisDisplayName: String  ) {
  searchPropertyMetadata(filter: {isRDR: {eq: 1}, equipmentType: {eq: $equipmentType}, tisDisplayName: {eq: $tisDisplayName}}) {
    items {
      possibleState
      maxValue
      minValue
      targetMeasureUnit
      targetMeasureType
      targetDataGroup
    }
  }
}

`
export const GET_BY_EQUIPMENT_META_DATA = `query GET_BY_EQUIPMENT_TYPE ($equipmentType: String!, $nextToken: String) {
  getByEquipmentType(equipmentType: $equipmentType, filter: {isRDR: {eq: 1}}, limit: 100000,
  nextToken: $nextToken) {
   nextToken
    items {
      maxValue
      minValue
      possibleState
      tisDisplayName
      targetKey
      isMappable   
      propertyType      
      targetResolution 
    }
  }
}
`
export const GET_BUILDING_TOTAL_METER_EQUIPMENT = `query getBuildingTotalMeterEquipment($body:AWSJSON) {
  getBuildingTotalMeterEquipment(body: $body)
}
`

// Condition check if parent exists already	for Equipment Association
export const GET_BY_EQUIPMENT_TYPE = `query GET_BY_EQUIPMENT_TYPE ($equipmentType: String!) {
  getByEquipmentType(equipmentType: $equipmentType, filter: {isRDR: {eq: 1}}, propertyType: {eq: "Characteristic"}, limit: 1000) {
    items {
      possibleState
      tisDisplayName
      isMappable          
    }
  }
}
`

export const GET_DEFAULT_DATA_SOURCE_PROPERTY = `query GET_DEFAULT_DATA_SOURCE_PROPERTY ($equipmentId: ID!,$nextToken: String) {
  getPropertyMappingByEquipmentID(equipmentId: $equipmentId,nextToken:$nextToken) {
    nextToken
    items {
      sourceDisplayName 
      sourceMeasureType
      targetMeasureUnit
      sourceMeasureUnit
      sourceKey
      sourceUri
      sourceDeviceId
      subPropertyDisplayName
      sourceDataGroup
    }
  }
}

`
// getting default dimensions list for create analog point equipment
export const CONFIG_BY_TYPE = `query CONFIG_BY_TYPE ($type: String!, $nextToken: String) {
  configByType(type: $type, nextToken: $nextToken, limit: 100000) {
    nextToken
    items {
      value
      type
      subType
    }
  }
}

`

// getting equipment list
export const GET_EQUIPMENT_LIST = `query GET_EQUIPMENT_LIST($id: ID!, $nextToken: String) {
  searchEquipment(
    filter: {
      buildingId: {eq: $id},
      isActive: {eq: 1}
    },
    nextToken: $nextToken
    limit: 1000
    ){
    items {
      id
      name
      isTIS
      automationParentId
      isParent
      parentId
      type
      family
      dataSourceName
      dataSourceType
      creationType
      controllerType
      uri
      deviceId
      equipmentProperties(filter: {or: [{targetKey: {eq: "UnitSerialNumber"}},{targetKey: {eq: "UnitModelNumber"}}]}, limit: 1000) {
        items {
         equipmentId
         targetKey
         value
        }
      }
    }
    nextToken
    total
  }
}
`
export const GET_TIS_EQUIPMENT_LIST = `query GET_EQUIPMENT_LIST($id: ID!, $nextToken: String) {
  searchEquipment(
    filter: {
      buildingId: {eq: $id},
      isActive: {eq: 1}
      isTIS: {eq: 1}
    },
    nextToken: $nextToken
    limit: 1000
    ){
    items {
      id
      name
      isTIS
      automationParentId
      isParent
      parentId
      type
      family
      dataSourceName
      dataSourceType
      creationType
      controllerType
      uri
      deviceId
      equipmentProperties(filter: {or: [{targetKey: {eq: "UnitSerialNumber"}},{targetKey: {eq: "UnitModelNumber"}}]}, limit: 1000) {
        items {
         equipmentId
         targetKey
         value
        }
      }
    }
    nextToken
    total
  }
}
`

export const GET_NIAGARA_EQUIPMENT_LIST_WITH_DATA_SOURCE_FILTER = `query GET_NIAGARA_EQUIPMENT_LIST_WITH_DATA_SOURCE_FILTER($buildingId: ID!, $deviceId: ID!, $defaultDataSourceName: String, $nextToken: String) {
  searchEquipment(
    filter: {
      buildingId: {eq: $buildingId},
      deviceId: {eq: $deviceId},
      isActive: {eq: 1},
      isTIS: {eq: 0},
      name: {matchPhrasePrefix: $defaultDataSourceName}
    },
    nextToken: $nextToken
    limit: 1000
    ){
    items {
      id
      name
      dataSourceName
      dataSourceType
    }
    total
    nextToken
  }
}
`

export const GET_CHARACTERISTIC_CURVES = `query GET_CHARACTERISTIC_CURVES($id: ID!) {
  listCharacteristicCurveByEquipment(equipmentId: $id) {
    items {
      name
      createdAt
      createdBy
      updatedBy
      curveType
      instance
      xAxisTargetKey
      yAxisTargetKey
      id
      isActive
      runAnalytic
      parentCurveId
    }
  }
}
`
export const GET_CIRCUIT_COUNT = `query GET_CIRCUIT_COUNT($id: ID!) { 
  getPropertyMappingByEquipmentID(equipmentId: $id, propertyType: {eq: "Characteristic"}, filter: {targetKey: {eq: "CircuitCount"}}) {
    items {
      value
    }
  }
}
`

export const GET_CHARACTERISTIC_CURVE = /* GraphQL */ `
  query getCharacteristicCurve($id: ID!) {
    getCharacteristicCurve(id: $id) {
      characteristicCurveDatas
      createdAt
      createdBy
      updatedBy
      curveType
      equipmentId
      id
      instance
      isActive
      maxValueXAxis
      maxValueYAxis
      minValueXAxis
      minValueYAxis
      name
      runAnalytic
      userId
      xAxisTargetKey
      yAxisTargetKey
      changedValue
    }
  }
`

// List all devices associated with device id
export const GET_DEVICES_BY_DEVICE_ID = `
query listDevicesByParent($deviceId: ID!) {
  listDevicesByParent(deviceId: $deviceId, limit: 10000) {
    items {
      id
      deviceId
      isActive
      isAutomationSystem
      name
    }
  }
}`

export const getPropsByEquipmentType = /* GraphQL */ `
  query PropertiesSearch($filter: SearchablePropertyMetadataFilterInput) {
    searchPropertyMetadata(limit: 1000, filter: $filter) {
      items {
        tisDisplayName
        targetKey
        propertyType
        id
        sourceKey
        targetDataType
        targetDataGroup
        targetMeasureType
        targetMeasureUnit
        targetMeasureUnitDescription
        tisDisplayName
      }
      total
    }
  }
`

export const GET_EQUIPMENT_PROPERTIES_BY_EQUIPMENT_ID = `
query GET_EQUIPMENT_PROPERTIES($equipmentId: ID!, $nextToken: String) {
  getPropertyMappingByEquipmentID(equipmentId: $equipmentId, nextToken: $nextToken) {
    nextToken
    items {
      sourceUri
      sourceMeasureUnit
      sourceMeasureType
      sourceKey
      sourceEquipmentId
      sourceDisplayName
      sourceDeviceId
      sourceDataGroup
      id
      equipmentId
      buildingId
      targetKey
    }
  }
}
`

