import styled, { css, keyframes } from 'styled-components'
import COLORS from 'src/components/layouts/colors.json'

const dropAnimationClose = keyframes`
    0% {
        max-height:100px;
    }
    100% {
        height: 0px;
    }
`;


const dropAnimation = keyframes`
    0% {
        height:0px;
    }
    100% {
        max-height: 100px;
    }
`;



export const ResizeMenuContainer = styled.div`
    display: grid;
    background: ${COLORS.TBA_ALMOST2_WHITE};
    border: 1px solid ${COLORS.TBA_DARK_GREY};
    border-radius: 5px;
    width: fit-content;
    z-index:10;
    font-size:14px;
    padding:10px;
    overflow: hidden;
    font-weight:700;
    cursor:pointer;
    position: absolute;
    transition: all 0.4s ease;

    &.close {
        height: 0px;
        opacity: 0;
    }

    &.open {
        height: 154px;
        opacity: 1;
    }
    
    grid-template-columns: ${(props) => {
        let styles = ''
        for(let i=0;i < props.columns; i++){
            styles += '41px '
        }
        return styles
     }
    };`


export const ResizeIcon = styled.span`
    color: ${COLORS.TBA_DARK_GREEN}
    ${(props) => 
        props.menuShown &&
        css`
        color:${COLORS.WHITE};
        border: none;
        `
    }
    `


export const MenuItem = styled.div`
    height: auto;
    width: 41px;
    boder-radius: 3px;
    width: 41px;
    color: rgb(97, 174, 52);
    display:flex;
    align-items: center;
    justify-content: center;
    background: ${COLORS.TBA_ALMOST2_WHITE};
    border: 0.5px solid ${COLORS.TBA_LIGHTER_GREY};
    color: ${COLORS.BLACK};
    ${(props) =>
        props.isHovering &&
        css`
          background: ${COLORS.TBA_GREEN};
          color: ${COLORS.TBA_LIGHT_GREY};
          border: none;

        `}
    ${(props) => 
            props.isSelectedCell && 
            css`
            border-radius: 3px;
            background: ${COLORS.WHITE};
            color:${COLORS.TBA_GREEN};`
    }

    ${(props) => 
        props.isSelectedCell && props.isHovering && 
        css`
        border-radius: 0px;
        background: ${COLORS.TBA_GREEN};
        color:${COLORS.TBA_DARK_GREEN};`
    }

    ${(props) => 
        props.isCurrentCell &&
        css`
        color:${COLORS.WHITE};`
    }
    ${(props) => 
        props.isHighlighted &&
        css`
        color:${COLORS.BALCK};
        background: ${COLORS.WHITE};
        border: none;
        `
    }

    ${(props) => 
        props.isHighlighted && props.isHovering && 
        css`
        border-radius: 0px;
        background: ${COLORS.TBA_GREEN};
        color:${COLORS.TBA_DARK_GREEN};`
    }
    



    
    `
    export const WidgetsWrapper = styled.div`
    position: relative;
    padding-right: 10px;
    cursor: pointer;
    font-size: 10px;
    .widget-resize-container {
        position: absolute;
        border-top: green;
        top: 0;
        left: 0;
        margin-left: -105px;
        margin-top: 19px;
        overflow: hidden;
        transition: height 0.4s ease;
        height: 154px;
        z-index: 1;
    }

    .widget-resize-container.hidden {
        height: 23px;
        & .resize-tab {
            opacity: 0;
        }
    }
    
    `