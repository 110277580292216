import XYChart from 'src/chart-library/Charts/XYChart'
import moment from 'moment'
import { SCALE_LINEAR } from 'src/chart-library/Utils/Scales/constant'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import colors from 'src/components/layouts/colors.json'
import { chartYAxisPosition } from 'src/common/chartHelperFunctions'
import { format } from 'date-fns'
import { PREVIEW_BUILDING_DATA_PAGE_NAMES } from './BuildingDataContainer'
import TimelineWithChart from './Timeline'
import './styles.scss'
import { useMemo } from 'react'
import { TranslateComponent } from '../../common/translations'

export const BuildingDataChart = ({
  chartData,
  timeSliderChartData,
  chartDates,
  propertiesList,
  loadingChartData,
  pageName = PREVIEW_BUILDING_DATA_PAGE_NAMES?.buildingData,
  isWidget = false,
  height,
  setTimeSliderStartTime,
  setTimeSliderEndTime
}) => {
  const createXAxis = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    return [
      {
        key: 'x1',
        scale: {
          props: {
            type: 'scaleTime'
          },
          categories: lineChartData?.timeStamps || [],
          scaleSettings: {}
        },
        axis: {
          visibleAt: ['bottomBottom'],
          hideTicks: false,
          hideLine: false
          // notD3: true
        },
        grid: [
          // {
          //   tickCount: 11,
          //   stroke: 'gray',
          //   opacity: 0.5,
          //   includeAxis: false
          // }
        ]
      }
    ]
  }

  const createYAxis = (isTimeSliderChartConfig) => {
    const propertyUOMs = propertiesList
      ?.map((x) => {
        return x?.symbol
      })
      .filter((x) => x !== '')
    // Max of 4 UOM only allowed to plot in Y-Axis
    const uniqueUOMs = [...new Set(propertyUOMs.map((x) => x?.Symbol))]

    const axises = uniqueUOMs.map((uom, index) => {
      return {
        key: `y${index + 1}`,
        scale: {
          props: {
            rangeFixed: true,
            type: SCALE_LINEAR,
            ...(uom === '%' && { defaultRange: [0, 100] })
          }
        },
        axis: {
          name: {
            symbol: uom,
            text: ' ',
            alignment: 'start'
          },
          hideTicks: false,
          notD3: true,
          // format: (value) => parseFloat(value)?.toFixed(0),
          // format:(value) => (uom === "%" ? parseFloat(value)?.toFixed(0) : parseFloat(value)?.toFixed(2)), //Is this condition required
          visibleAt: [chartYAxisPosition[`y${index + 1}`]] // Optional,
        },
        grid: [
          {
            stroke: 'gray',
            opacity: 0.3,
            includeAxis: true
          }
        ]
      }
    })
    return axises
  }

  const getData = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    const data = {}
    lineChartData &&
      lineChartData.data &&
      lineChartData?.data?.map((element, index) => {
        data[index + 1] = {
          data: element.values[0].map((x) =>
            x?.value === null ? null : Number(x?.value)
          )
        }
      })
    return data
  }

  const getLegends = (isTimeSliderChartConfig) => {
    const lineChartData = isTimeSliderChartConfig
      ? timeSliderChartData
      : chartData
    const propertyUOMs = propertiesList
      ?.map((x) => {
        return x?.symbol
      })
      .filter((x) => x !== '')
    const uniqueUOMs = [...new Set(propertyUOMs.map((x) => x?.Symbol))]

    const getIndexOfDOM = (propertyName) => {
      const propertyDetails = propertiesList.find((x) => x.propertyKey === propertyName)
      const uomIndex = uniqueUOMs.findIndex(
        (x) => x === propertyDetails?.symbol?.Symbol
      )
      return uomIndex === -1 ? 1 : uomIndex + 1
    }

    return lineChartData?.data?.map((x, index) => {
      const propertyDetails = propertiesList?.find(
        (property) =>
          property.propertyKey === x?.propertyKey &&
          property.equipmentId === x?.equipmentId
      )

      const legendName = `${propertyDetails?.propertyNameWithSymbol}${propertyDetails?.otherDetails}`

      return {
        id: index + 1,
        name:
          pageName !== PREVIEW_BUILDING_DATA_PAGE_NAMES?.dashboard
            ? legendName || x?.propertyKey
            : '',
        seriesKey: index + 1,
        elementProps: {
          stroke: propertyDetails?.color,
          strokeWidth: 2
        },
        xAxisKey: 'x1',
        yAxisKey: `y${getIndexOfDOM(x.propertyKey)}`,
        canvasIndex: '0',
        dashType: 'solid',
        properties: {},
        settings: {
          curve: {
            type: 'curveCardinal',
            settings: {
              tension: 0.5
            }
          }
        },
        shape: 'Line',
        show: true,
        visible: true,
        type: 'line',
        tooltipDataModelFormatter: ({ label, value, xValue }) => ({
          label: isWidget ? null : label,
          value: `${Number(value).toFixed(2)}`,
          title: format(new Date(xValue), 'EEEE, MMM do yyyy, h:mm a')
        })
      }
    })
  }

  const chartConfigData = (isTimeSliderChartConfig = false) => {
    return {
      canvas: {
        // If multi Chart
        // type: "column", //column, (row, absolute) Not yet supported
        // share: [70, 30],
        canvasDetails: {
          0: {
            renderAreaShade: {
              fill: colors.WHITE,
              opacity: '1'
            },
            tooltip: {
              type: 'x', // x, xy - xy not yet supported
              referenceLineV: true,
              tooltipTemplateProps: {
                className: isWidget
                  ? 'dashboard-widget-tooltip-line-series'
                  : ''
              }
            }
          }
        }
      },
      x: createXAxis(isTimeSliderChartConfig),
      y: createYAxis(isTimeSliderChartConfig),
      series: {
        types: getLegends(isTimeSliderChartConfig),
        data: getData(isTimeSliderChartConfig)
      },
      width: '100%',
      height: height ? height+'px' : '98%',
      // Required to set below config when displaying timeline
      // svgPadding: { bottom: 80 },
      // container: {
      //   className: 'building-data-line-chart-wrapper'
      // },
      dataLastUpdatedAt: moment().unix(),
      ...(!isWidget && {
        legends: {
          className: 'building-data-chart-legend'
        }
      })
    }
  }

  // Integration team has to pass start & end date and a listener to revice the current selected date time from timeline
  // Based on recevied date range from timeline, need to filter the data and pass to main chart.
  // timeline chart data should not be filtered based on timeline date range.
  const timelineProps = useMemo(() => {
    return {
      trackStartTime: '01-01-2023',
      trackEndTime: '06-30-2023',
      getBrushPosition: (d) => {}
    }
  }, [])

  // Integration team has to pass required data that needs to be rendered in timeline chart
  // Display only lines, area charts, hide other elements like axis, tooltip, legend, markers etc...
  const timelineChartConfigData = useMemo(
    () => ({
      ...{ ...chartConfigData(true) },
      height: 55,
      legends: null,
      svgPadding: { top: 0, right: 0, bottom: 0, left: 0 },
      container: {},
      canvas: {
        canvasDetails: {
          0: {
            renderAreaShade: {
              fill: 'transparent',
              opacity: '0'
            },
            tooltip: {
              type: 'x', // x, xy - xy not yet supported
              referenceLineV: true,
              hide: true
            }
          }
        }
      }
    }),
    [timeSliderChartData]
  )

  return loadingChartData ? (
    <Spinner />
  ) : chartConfigData()?.series.types?.length > 0 ? (
    <>
      <XYChart {...chartConfigData()}>
        {!isWidget && (
          <TimelineWithChart
            // hideChart
            startTime={chartDates?.chartStartDate}
            endTime={chartDates?.chartEndDate}
            getSelctedRange={(d) => {
              setTimeSliderStartTime(d?.startTime)
              setTimeSliderEndTime(d?.endTime)
            }}
            xAxis={timelineChartConfigData.x ?? []}
            yAxis={timelineChartConfigData.y ?? []}
            series={timelineChartConfigData.series ?? {}}
            className={'building-data-time-slider'}
          />
        )}
      </XYChart>
    </>
  ) : (
    <p><TranslateComponent>No Data</TranslateComponent></p>
  )
}
