import PropTypes from "prop-types"
import { Text, View } from "@react-pdf/renderer"
import { withTranslation } from "react-i18next"

import { styles } from "./pdf-equipment-block.styles"

const { equipmentsWrapper, equipment } = styles

const PDFEquipmentBlock = ({ equipments = [], t }) => (equipments && equipments.length) ? <View style={equipmentsWrapper}>
		<Text style={equipment}>{t("opportunities:PDF>equipmentAffected")}: </Text>
		{equipments
			.map(({ tisObjectName }, i) => <View key={i}>
				<Text>{tisObjectName}{i === equipments.length - 1 ? " " : ", "}</Text>
			</View>)}
	</View> : null

PDFEquipmentBlock.propTypes = {
	equipments: PropTypes.array
}

export default withTranslation()(PDFEquipmentBlock)


