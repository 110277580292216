import React from "react"
import PropTypes from "prop-types"
import { Text, View } from "@react-pdf/renderer"
import { titleStyles } from "./pdf-report-title.styles"

const PDFReportTitle = ({ name, dates, equipments }) => {
	const { sDates, sTitle, titleWrapper, titleStyle, equipmentsWrapper, equipmentsBody } = titleStyles

	return	(
		<View style={titleWrapper}>
			<View style={titleStyle}>
				<Text style={sTitle}>{name}</Text>
				<Text style={sDates}>Reporting Period: {dates}</Text>
			</View>
			{equipmentsBody && equipments ? <View style={equipmentsWrapper}>
				<Text>Equipment Affected:</Text>
				<Text style={equipmentsBody}>{equipments}</Text>
			</View> : null}
		</View>
	)
}

PDFReportTitle.propTypes = {
	name: PropTypes.string.isRequired,
	dates: PropTypes.string.isRequired,
	equipments: PropTypes.string,
}

export default PDFReportTitle
