import ApiError from '../common/api-error.js'
import app from '../../../models/app.js'

// HTTP Methods
export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const DELETE = 'DELETE'

let token = null

// Used not to show API errors when user hits refresh
let isPageBeingRefreshed = false
// Added the undefined check to prevent tests from failing when window is undefined
if (typeof window !== 'undefined') {
  window.onbeforeunload = function () {
    isPageBeingRefreshed = true
  }
}
export const getQueryString = (query) =>
  Object.keys(query)
    .reduce(
      (queryStr, key) =>
        query[key] !== undefined
          ? queryStr.concat(`${key}=${query[key]}`)
          : queryStr,
      []
    )
    .join('&')

function resource(url, params, onprogress) {
  let currentXHR = null
  url = `/ext_api${url}`
  function createXHR(method, url, headers, resolve, reject) {
    const xhr = new XMLHttpRequest()
    const token = getUserToken()

    xhr.open(method, url)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.setRequestHeader('Authorization', token)

    if (method === 'GET') {
      xhr.setRequestHeader(
        'Cache-Control',
        'no-cache, no-store, must-revalidate'
      )
      xhr.setRequestHeader('Pragma', 'no-cache')
    }
    Object.keys(headers).forEach((name) =>
      xhr.setRequestHeader(name, headers[name])
    )

    xhr.onload = function () {
      const response = processResponse(this.response)
      if (this.status >= 200 && this.status < 300) {
        resolve(response)
      } else {
        app.resourceCache && app.resourceCache.clearValue(url) // avoid caching failed requests
        if (
          this.status == 401 &&
          (!url.includes('securityContext') || method == 'DELETE') &&
          resource.callbacks.unauthorized
        ) {
          resource.callbacks.unauthorized()
          return
        }
        reject(
          new ApiError({
            statusCode: this.status,
            statusText: this.statusText,
            reason: response && response.singleReason,
            method,
            url
          })
        )
      }
    }
    xhr.onerror = function () {
      if (!isPageBeingRefreshed) {
        reject(new ApiError({ method, url }))
      }
    }
    if (onprogress) {
      // Assume GET and DELETE watch download progress otherwise watch upload progress
      if (method === 'GET' || method === 'DELETE') {
        xhr.onprogress = onprogress
      } else {
        xhr.upload.onprogress = onprogress
      }
    }
    return xhr
  }
  function request(method, url, data, headers) {
    return new Promise((resolve, reject) => {
      // let browser to add this header by itself, so that it has correct `boundary` parameter
      if (headers['Content-Type'] == 'multipart/form-data') {
        delete headers['Content-Type']
      }

      currentXHR = createXHR(method, url, headers, resolve, reject)
      if (
        headers['Content-Type'] &&
        headers['Content-Type'].startsWith('application/json')
      ) {
        currentXHR.send(JSON.stringify(data))
      } else {
        currentXHR.send(data)
      }
    })
  }
  return {
    get(headers = {}) {
      if (app.resourceCache && params && params.cache === true) {
        const getCacheKey = params.extraCacheKeyData
          ? url.concat(`&${getQueryString(params.extraCacheKeyData)}`)
          : url
        if (app.resourceCache.hasValue(getCacheKey)) {
          return app.resourceCache.getValue(getCacheKey)
        } else {
          const promise = request(
            'GET',
            url,
            null,
            Object.assign(
              { 'Content-Type': 'application/json;charset=UTF-8' },
              headers
            )
          )
          return app.resourceCache.addValue(getCacheKey, promise)
        }
      }
      return request(
        'GET',
        url,
        null,
        Object.assign(
          { 'Content-Type': 'application/json;charset=UTF-8' },
          headers
        )
      )
    },
    create(data, headers = {}) {
      return request(
        'POST',
        url,
        data,
        Object.assign(
          { 'Content-Type': 'application/json;charset=UTF-8' },
          headers
        )
      )
    },
    update(data, headers = {}) {
      return request(
        'PUT',
        url,
        data,
        Object.assign(
          { 'Content-Type': 'application/json;charset=UTF-8' },
          headers
        )
      )
    },
    delete(headers = {}) {
      return request(
        'DELETE',
        url,
        null,
        Object.assign(
          { 'Content-Type': 'application/json;charset=UTF-8' },
          headers
        )
      )
    },
    abort() {
      if (currentXHR) {
        currentXHR.abort()
      }
    }
  }
}
resource.callbacks = {}


export function getUserToken() {
  try {
    // localStorage.getItem causes errors when cookies disabled in browser
    if (!token) {
      const tokenExpireTime = localStorage.getItem('tokenExpireTime')
      if (
        !tokenExpireTime ||
        Number(tokenExpireTime) < new Date().valueOf() / 1000
      ) {
        localStorage.removeItem('userToken')
        localStorage.removeItem('tokenExpireTime')
        return null
      } else {
        token = localStorage.getItem('userToken')
      }
    }
    return token ? `token ${token}` : null
  } catch (e) {
    return null
  }
}

export function removeUserToken() {
  try {
    // localStorage.removeItem causes errors when cookies disabled in browser
    localStorage.removeItem('userToken')
  } catch (e) {
    /* Do Nothing */
  }
  token = null
}


export function removeEbizToken() {
  document.cookie =
    '40CCSessionID=; path=/; domain=.trane.com; expires=Thu, 01 Jan 1970 00:00:01 GMT'
  document.cookie =
    '41CCSessionID=; path=/; domain=.trane.com; expires=Thu, 01 Jan 1970 00:00:01 GMT'
  localStorage.removeItem('ebizToken')
}

export function getEbizToken() {
  try {
    return localStorage.getItem('ebizToken')
  } catch (e) {
    return null
  }
}

function processResponse(str) {
  try {
    return JSON.parse(str)
  } catch (e) {
    return ''
  }
}

export default resource
