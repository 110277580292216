interface Validators {
  contains: (operatorValues: Array<string | number>, value: string) => boolean
  notContains: (
    operatorValues: Array<string | number>,
    value: string
  ) => boolean
  eq: (operatorValue: string, value: string) => boolean
  gt: (operatorValue: string, value: string) => boolean
  lt: (operatorValue: string, value: string) => boolean
  le: (operatorValue: string, value: string) => boolean
  ge: (operatorValue: string, value: string) => boolean
}

type Operators = Array<keyof Validators>

interface PropertySchema {
  mandatoryConditional: 'mandatory' | 'conditional'
  operator: {
    [k in keyof Validators]: any
  }
  depsCheck?: ApplicabilityCheckSchema
}

interface ApplicabilityCheckSchema {
  And?: {
    [k: string]: PropertySchema
  }
  Or?: {
    [k: string]: PropertySchema
  }
}

interface Conditions {
  [k: string]: PropertySchema
}

interface AllCharacteristics {
  [targetKey: string]: {
    value: string
    [k: string]: any
  }
}

export class ApplicabilityCheck {
  isApplicable = true

  constructor(applicability: string, allCharacteristics: any[]) {
    if (applicability) {
      const { conditions = null, characteristicsList = {} } = this.preprocess(
        applicability,
        allCharacteristics
      )

      if (conditions)
        this.isApplicable = this.checkTestApplicability(
          conditions,
          characteristicsList
        )
    }
  }

  /**
   * Method that process required data before handling the test logic
   *
   * @param applicability
   * @param allCharacteristics
   * @returns
   */
  private preprocess(
    applicability: string,
    allCharacteristics: any[]
  ): {
    conditions: ApplicabilityCheckSchema
    characteristicsList: AllCharacteristics
  } {
    let conditions: ApplicabilityCheckSchema = null
    let characteristicsList: AllCharacteristics = null

    try {
      characteristicsList = allCharacteristics.reduce((av, cv) => {
        av[cv.targetKey] = { ...cv }

        return av
      }, {})

      conditions = JSON.parse(applicability)
    } catch (e) {
      console.log('Unable to prase applicabilityCheck value', e)
    } finally {
      return {
        conditions,
        characteristicsList
      }
    }
  }

  /**
   * Property that holds all validators with accessor fn
   *
   * @private
   * @type {Validators}
   * @memberof ApplicabilityCheck
   */
  private get validators(): Validators {
    return {
      contains: (optrValues = [], value) =>
        optrValues.some((optrValue) => String(optrValue) === value),
      notContains: (optrValues = [], value) =>
        !optrValues.some((optrValue) => String(optrValue) === value),
      eq: (optrValue, value) => value === optrValue,
      gt: (optrValue, value) => value > optrValue,
      lt: (optrValue, value) => value < optrValue,
      le: (optrValue, value) => value <= optrValue,
      ge: (optrValue, value) => value >= optrValue
    }
  }

  private operators = Object.keys(this.validators) as Operators

  /**
   * Method that executes given input against validators defined
   *
   * @param operator
   * @param value
   * @returns
   */
  private checkOperatorsInput(operator: any, value: string | number): boolean {
    let isApplicable = true

    for (const optr of this.operators) {
      const optrValue = operator[optr]

      if (optrValue === undefined) {
        continue
      }

      const fn = this.validators[optr] || (() => true)

      const isValid = fn(
        Array.isArray(optrValue) ? optrValue : (String(optrValue) as any),
        String(value)
      )

      if (!isValid) {
        isApplicable = false
        break
      }
    }

    return isApplicable
  }

  /**
   * Method that extracts required details and calls validatiors with required input
   *
   * @param conditions
   * @param isMultipleConditions
   * @param targetKey
   * @param allCharacteristics
   * @returns
   */
  private checkByMandatoryConditional(
    conditions: PropertySchema[],
    isMultipleConditions = false,
    targetKey: string,
    allCharacteristics: AllCharacteristics
  ): boolean {
    let isApplicable = true

    const characteristicsDetails = allCharacteristics[targetKey]

    const { value } = characteristicsDetails ?? {}

    for (const condition of conditions) {
      // if no conditions are available, let applicable status true
      if (Object.keys(condition).length === 0) {
        continue
      }

      const { mandatoryConditional, operator = {}, depsCheck } = condition

      if (
        mandatoryConditional === 'mandatory' ||
        mandatoryConditional === 'conditional'
      ) {
        let isValid = null

        // In condition type, don't perform the test if characteristics details are not available.
        // It will be considering as optional
        if (mandatoryConditional === 'conditional') {
          if (!characteristicsDetails) continue

          // when characteristics are present, return true if the value is either undefined or null or empty
          // bz value may not be initialized while equipment creation
          // Therefore, check only when value is present
          isValid =
            value === undefined || value === null || value === ''
              ? true
              : this.checkOperatorsInput(operator, value)
        } else {
          // when characteristicsDetails is not available, return false as we it's not able perform any check futher
          isValid = !!characteristicsDetails
            ? this.checkOperatorsInput(operator, value)
            : false
        }

        // Check dependency conditions by calling recurcivly as data structure is same for depsCheck
        if (isValid && depsCheck) {
          isValid = this.checkTestApplicability(depsCheck, allCharacteristics)
        }

        if (isMultipleConditions) {
          isApplicable = isValid
          // When multiple conditions are present, need any of a condition to be true. So break the loop if any true
          if (isValid) {
            break
          }
        } else if (!isValid && !isMultipleConditions) {
          isApplicable = false
          break
        }
      }
    }

    return isApplicable
  }

  /**
   * Entry point of applicability test
   *
   * @param conditions
   * @param allCharacteristics
   * @returns
   */
  private checkTestApplicability(
    conditions: ApplicabilityCheckSchema,
    allCharacteristics: AllCharacteristics
  ): boolean {
    let isApplicable = true

    const andCondition = conditions['And'] || {}
    const orCondition = conditions['Or'] || {}

    const allConditions: Conditions = { ...andCondition, ...orCondition }

    for (const targetKey in allConditions) {
      let condition: PropertySchema | any = allConditions[targetKey] ?? {}

      const hasMultipleConditions = Array.isArray(condition)

      condition = hasMultipleConditions ? condition : [condition]

      const isValid = this.checkByMandatoryConditional(
        condition,
        hasMultipleConditions,
        targetKey,
        allCharacteristics
      )

      // Break if any of test is not statified the rules
      if (!isValid) {
        isApplicable = false
        break
      }
    }

    return isApplicable
  }
}
