import RenderFieldSet from './RenderFieldSet'
import { useEffect, useMemo, useState } from 'react'
import { useDashboardContext } from './CreateDashBoardProvider'
import { useDispatch, useSelector } from 'react-redux'
import { selectDashboards, setDashboard } from 'src/redux/slicers/dashboard'
import translate from 'src/common/translations'

const DashboardOptions = ({ preConfigDashboardOptions }) => {
  const optionsTranslated = {
    preconfig: translate('Start with a pre-configured dashboard'),
    fewMin: translate('This operation may take a few minutes to complete.'),
    copyDashboard: translate('Copy one of my dashboards:'),
    publicDash: translate('Create a copy of a public dashboard:'),
    blankDash: translate('Create a new blank dashboard')
  }

  const {
    setDashboardType,
    dashboardType,
    publicDashboards,
    setPreConfigSelection,
    preConfigSelection
  } = useDashboardContext()
  const { data: dashboards } = useSelector(selectDashboards)
  const dashboardLists = localStorage.getItem('dashboards')
    ? JSON.parse(localStorage.getItem('dashboards'))['dashboards']
    : dashboards
  const dispatch = useDispatch()
  const myDashboardOptions = dashboardLists?.map(
    ({ id: key, id, name: description, name }) => ({
      key,
      id,
      description,
      name
    })
  )
  const publicDashboardOptions = publicDashboards?.map(
    ({ id: key, name: description }) => ({
      key,
      description
    })
  )
  const selectHandler = (ev: any) => {
    const preConfig = { preConfiguredDashboardId: "", myDashboardId: "", publicDashboardId: "" }
    switch (ev?.name) {
      case "preConfiguredDashboardId":
        preConfig.preConfiguredDashboardId = ev?.id
        break;
      case "myDashboardId":
        preConfig.myDashboardId = ev?.id
        break;
      case "publicDashboardId":
        preConfig.publicDashboardId = ev?.id
        break;
    }
    setPreConfigSelection(preConfig)
  }
  const handleOption = (type) => {
    setDashboardType(type)
    dispatch(
      setDashboard({
        actionType: 'Create'
      })
    )
  }

  const fieldOptions = useMemo(() => {
    return [
      {
        radioProps: {
          label: optionsTranslated.preconfig,
          name: 'create-from-pre-configured',
          value: '1',
          id: 'create-from-pre-configured',
          handleChange: handleOption,
          selectedValue: dashboardType
        },
        options: preConfigDashboardOptions,
        optionName: 'preConfiguredDashboardId',
        selectedOption: preConfigSelection['preConfiguredDashboardId'],
        caption: optionsTranslated.fewMin,
        isDisabled: dashboardType !== '1'
      },
      {
        radioProps: {
          label: optionsTranslated.copyDashboard,
          name: 'create-from-copy-dashboard',
          value: '2',
          id: 'create-from-copy-dashboard',
          handleChange: handleOption,
          selectedValue: dashboardType,
          disabled: Array.isArray(myDashboardOptions) && myDashboardOptions.length === 0
        },
        options: myDashboardOptions,
        optionName: 'myDashboardId',
        selectedOption: preConfigSelection['myDashboardId'],
        isDisabled: dashboardType !== '2' 
      },
      {
        radioProps: {
          label: optionsTranslated.publicDash,
          name: 'copy-from-shared',
          value: '3',
          id: 'copy-from-shared',
          handleChange: handleOption,
          selectedValue: dashboardType
        },
        optionName: 'publicDashboardId',
        selectedOption: preConfigSelection['publicDashboardId'],
        options: publicDashboardOptions,
        isDisabled: dashboardType !== '3'
      },
      {
        radioProps: {
          label: optionsTranslated.blankDash,
          name: 'create-from-new-dashboard',
          value: '4',
          id: 'create-from-new-dashboard',
          handleChange: handleOption,
          selectedValue: dashboardType
        }
      }
    ]
  }, [dashboardType, preConfigSelection, publicDashboards, dashboardLists])
  return (
    <>
      {fieldOptions?.map((option: any, key: number) => (
        <RenderFieldSet
          radioProps={option.radioProps}
          caption={option.caption}
          options={option.options}
          key={key}
          optionName={option.optionName}
          selectHandler={selectHandler}
          selectedOption={option.selectedOption}
          isDisabled={option.isDisabled}
        />
      ))}
    </>
  )
}
export default DashboardOptions
