import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUserInfo } from 'src/redux/slicers/appData'
import classNames from 'classnames'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { InputWrapper, InputEmailWrapper } from './styles'
import 'react-phone-number-input/style.css'
import { Button } from 'src/components/inputs/button'
import Modal from 'src/components/legacy/components/modal/modal'
import { useFormik } from 'formik'
import { useAlarmContext } from './AlarmContextProvider'
import {
  SEARCH_CONTACTS,
  SAVE_SUBSCRIBER,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  GET_SUBSCRIBERS
} from './graphql'
import { getSchema } from './formValidator'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { validateEmail } from 'src/common/helperFunctions'

import {
  ADD_SUBSCRIBER_SUCCESS,
  UPDATE_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_ERROR,
  DUPLICATE_CONTACT_FOUND
} from './AlarmContainer'
import { ACTIONS, MODAL_TYPE } from 'src/constants'
import translate, { TranslateComponent } from 'src/common/translations'
const InputField = (props) => {
  const { labelText, errorText, type = 'text', ...rest } = props
  return (
    <InputWrapper>
      <label>{labelText}*</label>
      <input type={type} {...rest} />
      {errorText && <p className="error">{errorText}</p>}
    </InputWrapper>
  )
}

const SubscriberDialog = () => {
  const optionsTranslated = {
    transSearch: translate('Search'),
    transEditSub: translate('Edit Subscriber'),
    transAddSub: translate('Add Subscriber'),
    transCancel: 'Cancel',
    transUpdate: 'Update',
    transSave: 'Save',
    transAddAnother: translate('Save & Add Another'),
    transEmail: translate('Email'),
    transFirstName: translate('First Name'),
    transLastName: translate('Last Name'),
    transPhoneNum: translate('Phone Number')
  }

  const {
    subscriber,
    setMode,
    buildingId,
    setConfirmationModalType,
    setSubscriber,
    mode,
    testName
  } = useAlarmContext()
  const user = useSelector(selectUserInfo)
  const [createAnother, setCreateAnother] = useState(false)
  const isEdit = mode === ACTIONS.EDIT
  const [fillData, setFillData] = useState(!isEdit)
  const [lastSearch, setLastSearch] = useState('')
  const [valid, setValid] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [subscriberIds, setSubscriberIds] = useState([])
  const validationSchema = getSchema()
  const formik = useFormik({
    initialValues: subscriber,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      console.log(values)
    }
  })
  const renderEmailField = (props) => {
    const { labelText, errorText, type = 'text', ...rest } = props
    return (
      <InputEmailWrapper>
        <label>{labelText}*</label>
        <div>
          <input type={type} {...rest} />
          {errorText && <span className="icon icon-error" />}
          {!isEdit && (
            <Button
              type="primary"
              mode="dark"
              size="small"
              disabled={!isEmailValid}
              onClick={() => {
                if (!isEmailValid) return
                getContacts({
                  filter: { emailId: { eq: formik.values.emailId?.trim() } }
                })
              }}
            >
              {optionsTranslated.transSearch}
            </Button>
          )}
        </div>
        {errorText && <p className="error"><TranslateComponent>{errorText}</TranslateComponent></p>}
      </InputEmailWrapper>
    )
  }
  const { loading: loadingUniq, refetch: getContacts } = useQuery({
    query: SEARCH_CONTACTS,
    dataPath: 'data.searchContacts',
    disableInitialLoad: true,
    onSuccess: (data: any) => {
      setLastSearch(formik?.values?.emailId)
      if (data?.items?.length > 0) {
        const {
          notificationSettings = {
            items: []
          },
          emailId = '',
          firstName = '',
          lastName = '',
          phoneNumber = '',
          id: contactId
        } = data?.items?.[0]
        setSubscriberIds(
          notificationSettings?.items?.map((item) => item?.buildingId)
        )
        setFillData(true)
        formik.setValues({
          emailId,
          firstName,
          lastName,
          phoneNumber,
          contactId
        })
        formik.setFieldTouched('emailId')
      } else {
        setFillData(false)
        setSubscriberIds([])
        formik.handleChange('emailId')
      }
    },
    onError: () => {
      setFillData(false)
      setSubscriberIds([])
      setLastSearch('')
      formik.handleChange('emailId')
    }
  })
  const { refetch: searchNotifications } = useQuery({
    query: GET_SUBSCRIBERS,
    dataPath: 'data.listNotificationSettingByBuilding',
    disableInitialLoad: true,
    onSuccess: (data: any) => {
      const { contactId, firstName, lastName, phoneNumber, emailId } = formik.values
      if (data?.items?.findIndex(e => e?.contact?.emailId === emailId) !== -1 && !isEdit) {
        setMode(null)
        setConfirmationModalType(DUPLICATE_CONTACT_FOUND)
      } else if (isEdit) {
        if ((subscriber?.emailId !== emailId) && data?.items?.findIndex(e => e?.contact?.emailId === emailId) !== -1 ) {
          setMode(null)
          setConfirmationModalType(DUPLICATE_CONTACT_FOUND)
        } else {
          const input = {
            id: subscriber?.contactId,
            firstName,
            lastName,
            phoneNumber,
            emailId
          }
          updateContact({ input })
        }
        } else {
            if (fillData) {
              const input = {
                contactId,
                buildingId,
                type: "alarm",
                userId:user.id
              }
              // Need to save just subscriber
              saveSubscriber({ input })
            } else {
              // Save contact and subscriber
              const input = {
                firstName,
                lastName,
                phoneNumber,
                emailId
              }
              saveContact({ input }).then(e => {
                const notificationInput = {
                  contactId: e.id,
                  buildingId,
                  userId:user.id,
                  type: "alarm"
                }
                saveSubscriber({ input: notificationInput })
              })
            }
          
        }
    }
  })
  const { onSubmit: saveSubscriber } = useMutation({
    query: SAVE_SUBSCRIBER,
    dataPath: 'data.createNotificationSetting',
    onSuccess: () => {
      formik.setSubmitting(false)
      if (!createAnother) {
        closeSubscriberModal()
        setConfirmationModalType(ADD_SUBSCRIBER_SUCCESS)
        setSubscriber(formik.values)
      }
    },
    onError: () => {
      setMode(null)
      setConfirmationModalType(MODAL_TYPE.ERROR)
      formik.setSubmitting(false)
    }
  })

  const { onSubmit: updateContact } = useMutation({
    query: UPDATE_CONTACT,
    dataPath: 'data.updateContact',
    onSuccess: () => {
      setMode(null)
      formik.setSubmitting(false)
      closeSubscriberModal()
      setConfirmationModalType(UPDATE_SUBSCRIBER_SUCCESS)
    },
    onError: () => {
      setConfirmationModalType(MODAL_TYPE.ERROR)
      formik.setSubmitting(false)
      setMode(null)
    }
  })

  const { onSubmit: saveContact } = useMutation({
    query: CREATE_CONTACT,
    dataPath: 'data.createContact',
    onSuccess: (data) => {
      const input = {
        contactId: data.id,
        buildingId,
        userId:user.id
      }
      saveSubscriber({ input })
    },
    onError: () => {
      formik.setSubmitting(false)
      setMode(null)
      setConfirmationModalType(MODAL_TYPE.ERROR)
    }
  })

  const handleSaveSubscriber = async (isAnother = false) => {
    setCreateAnother(isAnother)
    formik.setSubmitting(true)
    searchNotifications({
      buildingId,
      limit: 300,
      type: { eq: "alarm" },
    })
  }
  const handleUpdateSubscriber = () => {
    if (subscriberIds.includes(buildingId)) {
      setMode(null)
      setConfirmationModalType(ADD_SUBSCRIBER_ERROR)
      return
    }
    formik.setSubmitting(true)
    searchNotifications({
      buildingId,
      limit: 300,
      type: { eq: "alarm" },
    })
  }
  const closeSubscriberModal = () => {
    setMode(null)
  }
  const addSubscriberConfig = useMemo(
    () => ({
      heading: (() => {
        return isEdit
          ? "Edit Subscriber"
          : "Add Subscriber"
      })(),
      buttons: (() => {
        return isEdit
          ? [
              {
                text: optionsTranslated.transCancel,
                handleClick: closeSubscriberModal,
                type: 'cancel',
                disabled: formik.isSubmitting
              },
              {
                text: optionsTranslated.transUpdate,
                handleClick: () => handleUpdateSubscriber(),
                type: 'confirm',
                disabled: !valid || formik.isSubmitting
              }
            ]
          : [
              {
                text: "Cancel",
                handleClick: closeSubscriberModal,
                type: 'cancel',
                disabled: formik.isSubmitting
              },
              {
                text: "Save",
                handleClick: () => handleSaveSubscriber(false),
                type: 'confirm',
                disabled: !valid || formik.isSubmitting
              },
              {
                text: "Save & Add another",
                handleClick: () => handleSaveSubscriber(true),
                type: 'confirm',
                disabled: !valid || formik.isSubmitting
              }
            ]
      })(),
      handleClose: closeSubscriberModal
    }),
    [formik, isEdit, isEmailValid, valid]
  )

  const hasError = (fieldName) => {
    return formik.touched?.[fieldName] && formik.errors?.[fieldName]
  }

  const validateEmailAddress = () => {
    const isSame = formik.values.emailId === lastSearch
    if (isEmailValid && !isEdit) {
      getContacts({
        filter: { emailId: { eq: formik.values.emailId } }
      })
    }
  }
  const handleEmailChange = (e) => {
    setFillData(false)
    formik.setFieldValue('emailId', e.target.value.trim())
  }
  
  useEffect(() => {
    const {
      emailId = '',
      lastName = '',
      phoneNumber = '',
      firstName = ''
    } = formik.values
    const isPhoneValid = phoneNumber ? isValidPhoneNumber(phoneNumber) : true
    const isValid = validateEmail(emailId)
    setIsEmailValid(isValid)
    if (!emailId || !lastName || !firstName || !isValid || (isEdit && !isPhoneValid)) {
      setValid(false)
    } else {
      setValid(true)
    }
  }, [formik.values])
  return (
    <Modal
    testName={testName}
      {...addSubscriberConfig}
      className="grey layout-2-columns subscriber-dialog"
    >
      {formik.isSubmitting || loadingUniq ? (
        <Spinner />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          {renderEmailField({
            labelText: optionsTranslated.transEmail,
            defaultValue: formik?.values?.emailId,
            name: 'emailId',
            onChange: handleEmailChange,
            onBlur: validateEmailAddress,
            errorText:
              !fillData && hasError('emailId')
                ? formik.errors?.['emailId']
                : '',
            className: classNames({ error: !fillData && hasError('emailId') })
          })}
          <InputField
            labelText={optionsTranslated.transFirstName}
            defaultValue={formik?.values?.firstName}
            name="firstName"
            disabled={fillData || loadingUniq}
            onChange={formik.handleChange}
            errorText={
              hasError('firstName')
                ? !fillData && formik.errors?.['firstName']
                : ''
            }
            className={classNames({
              error: !fillData && hasError('firstName')
            })}
          />
          <InputField
            labelText={optionsTranslated.transLastName}
            name="lastName"
            value={formik?.values?.lastName}
            disabled={fillData || loadingUniq}
            onChange={formik.handleChange}
            errorText={
              !fillData && hasError('lastName')
                ? formik.errors?.['lastName']
                : ''
            }
            className={classNames({
              error: !fillData && hasError('lastName')
            })}
          />
          <div>
            <label>{optionsTranslated.transPhoneNum}</label>
            <PhoneInput
              placeholder="+99 999 999 9999"
              value={formik.values.phoneNumber || ''}
              disabled={fillData || loadingUniq}
              onChange={(value) => {
                formik.setFieldValue('phoneNumber', value)
                formik.setFieldTouched('phoneNumber')
              }}
              errorText={
                hasError('phoneNumber') ? formik.errors?.['phoneNumber'] : ''
              }
              className={classNames({
                error: hasError('phoneNumber')
              })}
            />
          </div>
        </form>
      )}
    </Modal>
  )
}
export default SubscriberDialog
