import { useState, useCallback, useEffect } from 'react'
import MultiTabForm from '../MultiTabTorm'
import { TabsWrapper } from '../../styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import {
  WidgetBuildingSchema,
  WidgetOverview,
  WidgetOverviewSchema
} from '../WidgetOverview'
import {
  TrendLineChartConfiguration,
  TrendLineChartConfigurationSchema
} from './TrendLineChartConfiguration'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import {
  MODAL_TYPE,
  STD_CALC_GROUP_2_INITIAL,
  TimePeriodCurrentDay,
  WIDGET_TABS_NAME,
  getTrendLineWidgetUpsertMutationInputs
} from '../../helpers'
import { ACTIONS } from 'src/constants/actions'
import TrendLineChartColors from './TrendLineChartColors'
import { OVERLAY_TIME_PERIOD_NONE } from 'src/common/overlay-time-period'
import { LINE1_COLOR, LINE2_COLOR } from '../../colors'
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../graphql'
import { useMutation } from 'src/hooks/APIHooks'
import { TrendLinePreviewWrapper } from './TrendLinePreviewWrapper'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate from 'src/common/translations'

const tabsConfig = (errors) => [
  {
    title: WIDGET_TABS_NAME.OVERVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.OVERVIEW,
    formikErrors: errors,
    validationSchema: WidgetOverviewSchema,
    component: <WidgetOverview />
  },
  {
    title: WIDGET_TABS_NAME.CONFIGURATION,
    disabled: false,
    key: WIDGET_TABS_NAME.CONFIGURATION,
    formikErrors: errors,
    validationSchema: TrendLineChartConfigurationSchema,
    component: <TrendLineChartConfiguration />
  },
  {
    title: WIDGET_TABS_NAME.COLORS,
    disabled: false,
    key: WIDGET_TABS_NAME.COLORS,
    formikErrors: errors,
    component: <TrendLineChartColors />
  },
  {
    title: WIDGET_TABS_NAME.PREVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.PREVIEW,
    formikErrors: errors,
    component: <TrendLinePreviewWrapper />
  },
]

const TrendLineChartForm = ({ setWidgetFormik }) => {
  const {
    widgetBuilding,
    setOpenWidgetPicker,
    userInfo,
    setReloadEvents,
    setIsLoading,
    mode,
    widgetDetails,
    setWidgetDetails,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setModalType,
    formikErrors,
    objectPickerSelectedValue,
    setWidgetBuilding,
    setIsFormValidationAlone,
    setIsSubmitted,
    isFormValidationAlone,
    setWidgetId,
    isFooterChecked
  } = useWidgetsContext()
  const [activeTab, setActiveTab] = useState(0)
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const onSelectTab = useCallback((selectedTab: any, selectedTitle: any) => {
    setActiveTab(selectedTab)
    if (selectedTitle === WIDGET_TABS_NAME.PREVIEW){
      setIsFormValidationAlone(true)
      setIsSubmitted(false)
    }
  }, [])
  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: [ACTIONS.ADD,ACTIONS.COPY].includes(mode) ? CREATE_WIDGET : UPDATE_WIDGET,
    refreshTokenBuildingVariable: "input.buildingId",
    onError: (error) => {
      setError(error)
      setIsLoading(false)
    },
    onSuccess: (success) => {
      if([ACTIONS.ADD].includes(mode))
        {
          trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Create", type: "Trends Line Chart"})
        }
      else if([ACTIONS.COPY].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Copy", type: "Trends Line Chart"})
      }
      else{
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Edit", type: "Trends Line Chart"})
      }
    }
  })

  const submitForm = async (values) => {
    if (objectPickerSelectedValue?.length === 0 || !widgetBuilding?.id || isFormValidationAlone) return
    setIsLoading(true)
    const inputs = getTrendLineWidgetUpsertMutationInputs(
      values,
      userInfo,
      objectPickerSelectedValue,
      mode
    )
    await upsertWidgetMutation({ input: inputs }).then((response) => {
      const widgetId =
      [ACTIONS.ADD,ACTIONS.COPY].includes(mode)
          ? response.data?.createWidget?.id
          : response.data?.updateWidget?.id
      if (widgetId) {
        setIsLoading(false)
        setWidgetDetails(inputs)
        setOpenWidgetPicker(false)
        setReloadEvents(true)
        setModalType(MODAL_TYPE.SUCCESS)
        setSelectedWidget(null)
        setIsOpenCreateWidget(isFooterChecked ?? false)
        setWidgetId(widgetId)
      }
    })
  }
  const copyof = translate("Copy of")
  useEffect(() => {
    switch (mode) {
      case ACTIONS.ADD:
        setFormValues({
          id: '',
          name: '',
          description: '',
          link: '',
          isNewWindow: true,
          widgetBuilding: '',
          property: '',
          unitsOfMeasure: '',
          symbol: '',
          lineColor: LINE1_COLOR,
          overlayLineColor: LINE2_COLOR,
          timePeriod: TimePeriodCurrentDay,
          value: STD_CALC_GROUP_2_INITIAL,
          comparePointTo: OVERLAY_TIME_PERIOD_NONE
        })
        break
      case ACTIONS.EDIT:
      case ACTIONS.COPY:
        setFormValues({
          id: mode === ACTIONS.EDIT ? widgetDetails?.id : '',
          name: mode === ACTIONS.EDIT ? widgetDetails?.name : `${copyof} ${widgetDetails?.name}`,
          description: widgetDetails?.description ?? '',
          link: widgetDetails?.widgetLink,
          isNewWindow: widgetDetails?.isNewWindow,
          property: widgetDetails?.equipmentTrendLineWidget?.property?.property,
          unitsOfMeasure:
            widgetDetails?.equipmentTrendLineWidget?.property?.uom,
          symbol: widgetDetails?.equipmentTrendLineWidget?.property?.symbol,
          value: widgetDetails?.equipmentTrendLineWidget?.value,
          timePeriod: widgetDetails?.equipmentTrendLineWidget?.timePeriod,
          comparePointTo: widgetDetails?.equipmentTrendLineWidget?.comparePoint,
          lineColor:
            widgetDetails?.equipmentTrendLineWidget?.colourConfig?.line,
          overlayLineColor:
            widgetDetails?.equipmentTrendLineWidget?.colourConfig?.overlayLine
        })
        setWidgetBuilding({ id: widgetDetails?.buildingId })
        break
    }
  }, [mode, copyof])

  const displayForm = () => {
    return mode !== ACTIONS.ADD ? formValues?.name : true
  }

  return (
    <>
      {displayForm() && (
        <MultiTabForm
          initialValues={formValues}
          onSubmit={submitForm}
          schemas={[
            WidgetOverviewSchema,
            WidgetBuildingSchema,
            TrendLineChartConfigurationSchema
          ]}
          setWidgetFormik={setWidgetFormik}
        >
          <TabsWrapper>
            <Tabs
              defaultActiveTab={activeTab}
              activeTab={activeTab}
              onSelect={onSelectTab}
            >
              {tabsConfig(formikErrors).map((item: any, key: number) => {
                return (
                  <Tab
                    title={item.title}
                    disabled={item.disabled}
                    key={key}
                    formikErrors={item?.formikErrors}
                    validationSchema={item?.validationSchema}
                  >
                    {item.component}
                  </Tab>
                )
              })}
            </Tabs>
          </TabsWrapper>
          <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
        </MultiTabForm>
      )}
    </>
  )
}

export default TrendLineChartForm
