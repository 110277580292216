import colors from 'src/components/layouts/colors.json'
import styled from 'styled-components'

export const ChartDateControlsDiv = styled.div.attrs((props: any) => ({
  'data-testid': `${props?.testName}-chart-controls`
}))`
  font-size: 90%;
  width: 100%;
  .ChartDateControlsClass {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .ranges {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .range-level {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        border-radius: 3px;
        text-align: center;
        display: inline-block;
        margin-right: 4px;
        width: 40px;
        height: 20px;
        background: rgb(247, 247, 247);
        cursor: pointer;
        .content {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          background: rgb(230, 230, 230);
        }

        &.active {
          background: rgb(212, 214, 218);
          font-weight: 700;
        }
      }
    }

    .dates {
      text-align: right;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .date-picker-section {
        margin-top: 15px;
        display: flex;
        justify-content:center;
        & .date-picker-center {
          display: flex;
          justify-content: center;
          margin: 5px;
        }
      }

      
      .modal-datepicker-wrapper {
        text-align: left;
        display: inline-block;

        .date-container {
          width: 150px;
          input {
            width: 120px;
          }
          .datepicker-react .datepicker-trigger.icon {
            margin-top: 0px;
          }
        }
      }
    }
  }

  .date-error-text {
    display: block;
    text-align: end;
    // padding-top: 4px;
    // padding-right: 45px;
    font-size: 14px;
    font-style: italic;
    color: ${colors.RED};
  }
`
