import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'formik'
import { useTranslation } from 'react-i18next'
import _orderBy from 'lodash/orderBy'
import _chunk from 'lodash/chunk'

import {
  ACTIONS,
  CHARACTERISTIC_TYPES,
  CHARACTERISTIC_TYPES_KEY
} from './constants' // done
import CharacteristicItem from './characteristic-item' // done
import CharacteristicState from './charateristic-state' // done
import Tooltip from 'src/denali-ui/components/Tooltip'
import StandardDialogs from '../../../../components/legacy/components/standard-dialogs'
import { getDiscardChangesPromptConfig } from '../helper'
import './style'
import { StyledLabel } from './characteristics'
import translate from 'src/common/translations'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { isEmpty } from 'lodash'
import { ErrorText } from 'src/pages/equipmentSetup/EquipmentDetails/style.ts'
import { TranslateComponent } from '../../../../common/translations'

export const CharacteristicsForm = ({
  equipmentDetails,
  values,
  dirty,
  isValid,
  errors,
  setFieldValue,
  handleSubmit,
  resetForm,
  readOnly,
  equipmentType,
  resetTisEquipmentCharacteristics,
  atsPage = false,
  refetchBuildingTotalMeterEquipment,
  testName
}) => {
  const [t] = useTranslation()
  const [mode, setMode] = useState(ACTIONS.VIEW)
  const [confirm, setConfirm] = useState(null)
  const [characteristicsList, setCharacteristicsList] = useState(values)
  const [chunkedCharacteristics, setChunkedCharacteristics] = useState([])
  const [rowCharacteristics, setRowCharacteristics] = useState([])

  useEffect(() => {
    setCharacteristicsList(values)
  }, [values])

  useEffect(() => {
    const characteristicsReadOnlyOrder = _orderBy(
      Object.entries(characteristicsList),
      [
        ([, { readOnly }]) => readOnly,
        ([, { tisDisplayName }]) => tisDisplayName //**RAAJAN */
      ],
      ['desc', 'asc']
    )

    // Move model number to top for GCPM
    const characteristics = characteristicsReadOnlyOrder
    const modelIndex = characteristics.findIndex(
      (x) => x[0] === 'UnitModelNumber'
    )
    if (modelIndex !== -1)
      characteristics.unshift(...characteristics.splice(modelIndex, 1))

    const rowCharacteristics = characteristics?.reduce((acc, charateristic) => {
      try {
        acc[0] = acc[0] || []
        acc[1] = acc[1] || []
        CHARACTERISTIC_TYPES_KEY.POSSIBLE_STATES === charateristic[0]
          ? acc[1].push(charateristic)
          : acc[0].push(charateristic)
        return acc
      } catch (error) {
        return []
      }
    }, [])
    const chunkedCharacteristics =
      rowCharacteristics !== null && rowCharacteristics?.length > 0
        ? _chunk(
            rowCharacteristics?.[0],
            Math.ceil(rowCharacteristics?.[0].length / 2)
          )
        : null
    setRowCharacteristics(rowCharacteristics)
    setChunkedCharacteristics(chunkedCharacteristics)
  }, [characteristicsList])

  const hideConfirmDialog = () => setConfirm(null)

  const handleCancel = () => {
    resetForm()
    setMode(ACTIONS.VIEW)
  }

  const translatedOptions = {
   text: translate('You have made changes which have not been saved. Do you wish to Continue?')
  }

 const getDiscardChangesPromptConfig = ({
    handleConfirm,
    handleCancel
  }) => ({
    title: "Warning",
    text: translatedOptions.text,
    confirmText: 'Yes',
    cancelText: 'No',
    handleConfirm,
    handleCancel
  })

  const showDiscardChangesPrompt = () => {
    setConfirm(
      getDiscardChangesPromptConfig({
        handleConfirm: () => {
          hideConfirmDialog()
          handleCancel()
        },
        handleCancel: () => {
          hideConfirmDialog()
        }
      })
    )
  }

  const onCancel = () => {
    if (dirty) {
      showDiscardChangesPrompt()
    } else {
      handleCancel()
    }
  }

  const onSubmit = () => {
    handleSubmit()
    setMode(ACTIONS.VIEW)
    trackEvent(
      USER_EVENTS.EQUIPMENT_SETUP.events.SAVE_EQUIPMENT_CHARACTERISTICS,
      { mode: 'EDIT' }
    )
  }

  const resetOptions = {
    subTitle : translate("Are you sure want to Reset All to Defaults?"),
    description : translate("This action will reset all current values to its default setting and cannot be undone.")
  }

  const resetAllToDefaults = () => {
    setConfirm({
      title: "Reset All to Defaults",
      text: resetOptions.subTitle,
      description: resetOptions.description,
      confirmText: "Yes",
      cancelText: "No",
      handleConfirm: () => {
        hideConfirmDialog()
        resetTisEquipmentCharacteristics()
        setMode(ACTIONS.VIEW)
      },
      handleCancel: () => {
        hideConfirmDialog()
      }
    })
  }

  const beforEdit = async () => {
    trackEvent(
      USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_EDIT_EQUIPMENT_CHARACTERISTICS
    )
    const _characteristicsList = Object.values(characteristicsList)
    if (
      equipmentDetails?.type === 'VirtualMeter' &&
      _characteristicsList?.length > 0
    ) {
      const isCharacteristicsUpdatedWithBuildingTotal =
        _characteristicsList.some(
          (char) =>
            (char.targetKey === 'Building Total' ||
              char.targetKey === 'BuildingTotal') &&
            char.buildingTotalPropertyUpdated
        )
      if (!isCharacteristicsUpdatedWithBuildingTotal) {
        const {
          data: { getBuildingTotalMeterEquipment }
        } = await refetchBuildingTotalMeterEquipment({
          body: JSON.stringify({ bId: equipmentDetails?.building?.id })
        })
        const parseData = JSON.parse(getBuildingTotalMeterEquipment)
        let updatedcharacteristicsList = _characteristicsList
        if (parseData?.body?.equipments?.length > 0) {
          const meterId = parseData.body.equipments[0].id
          const meterName = parseData.body.equipments[0].name
          const isNotCurrentEquipment = equipmentDetails.id !== meterId
          updatedcharacteristicsList = _characteristicsList.map((char) => {
            if (
              char.targetKey === 'Building Total' ||
              char.targetKey === 'BuildingTotal'
            ) {
              char.readOnly = isNotCurrentEquipment
              if (isNotCurrentEquipment) {
                char.tooltip = `${meterName} is currently being used for Building Total`
                char.showMode = ACTIONS.EDIT
              }
              char.buildingTotalPropertyUpdated = true
            }
            return char
          })
          setMode(ACTIONS.EDIT)
        } else {
          updatedcharacteristicsList = _characteristicsList.map((char) => {
            char.buildingTotalPropertyUpdated = true
            return char
          })

          setMode(ACTIONS.EDIT)
        }
        setCharacteristicsList(
          updatedcharacteristicsList?.reduce(
            (acc, item) => ({
              ...acc,
              [item.characteristicDisplayName]: { ...item }
            }),
            {}
          )
        )
      } else {
        setMode(ACTIONS.EDIT)
      }
    } else {
      setMode(ACTIONS.EDIT)
    }
  }

  // const context = useContext(Context)

  // useEffect(() => {
  // 	context?.onChangeFormData(dirty)
  // }, [dirty, context?.onChangeFormData])

  const optionsTranslated = {
    edit : translate('Edit'),
    cancel : translate("Cancel"),
    save: translate("Save")
  }
  return (
    <Form>
      {/* RAAJAN - COMMENTED OUT SINCE ITS VIEW ONLY FOR NOW */}
      {!readOnly && (
        <>
          <div className={`toolbar-buttons ${atsPage ? 'atsPage' : 'left'}`}>
            {atsPage && (
              <StyledLabel className="ats-title"><TranslateComponent>Characteristics</TranslateComponent></StyledLabel>
            )}
            {mode === ACTIONS.VIEW ? (
              <button
                type="button"
                className="edit editButton"
                onClick={() => beforEdit()}
              >
                {optionsTranslated.edit}
              </button>
            ) : (
              <div>
                {!isValid ||
                  (!isEmpty(errors) && (
                    <ErrorText>
                      <TranslateComponent>Please correct the errors below to enable save</TranslateComponent>
                    </ErrorText>
                  ))}
                <button
                  data-testid={`${testName}_cancelButton`}
                  type="button"
                  className="cancel secondary"
                  style={{ textTransform: 'inherit' }}
                  onClick={onCancel}
                >
                  {optionsTranslated.cancel}
                </button>
                <button
                  data-testid={`${testName}_edit_save_Button`}
                  type="submit"
                  className={
                    !isValid || !isEmpty(errors)
                      ? 'disabled'
                      : 'edit editButton'
                  }
                  onClick={onSubmit}
                  disabled={!isValid || !isEmpty(errors)}
                >
                  {optionsTranslated.save}
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <div className="rx-grid ats-ch">
        <div className="rx-col-6">
          <div className="rx-grid">
            {(chunkedCharacteristics?.[0] || []).map(([key, item]) => (
              <div className="rx-col-12" key={key}>
                <Tooltip
                  className="tooltip-overflow"
                  position="right-top"
                  content={item.tooltip}
                  disabled={
                    !item.tooltip ||
                    (item.showMode ? item.showMode !== mode : false)
                  }
                >
                  <CharacteristicItem
                    mode={mode}
                    characteristic={item}
                    setFieldValue={setFieldValue}
                    equipmentType={equipmentDetails?.family}
                    errorMessage={errors?.[key]}
                  />
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
        <div className="rx-col-6">
          <div className="rx-grid">
            {(chunkedCharacteristics?.[1] || []).map(([key, item]) => (
              <div className="rx-col-12" key={key}>
                <Tooltip
                  className="tooltip-overflow"
                  content={item.tooltip}
                  disabled={
                    !item.tooltip ||
                    (item.showMode ? item.showMode !== mode : false)
                  }
                  position="left-top"
                >
                  <CharacteristicItem
                    mode={mode}
                    characteristic={item}
                    setFieldValue={setFieldValue}
                    errorMessage={errors?.[key]}
                    testName={testName}
                    equipmentType={equipmentDetails?.family}
                  />
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      </div>
      {(rowCharacteristics?.[1] || []).map(([key, item]) => (
        <CharacteristicState
          testName={testName}
          key={key}
          mode={mode}
          characteristic={item}
          setFieldValue={setFieldValue}
          state={chunkedCharacteristics?.[1]?.[0]}
          equipmentType={equipmentDetails?.family}
          errorMessage={errors?.[key]}
        />
      ))}
      <div className={`toolbar-buttons ${atsPage ? 'btn-right' : ''}`}>
        {mode === ACTIONS.EDIT && (
          <button
            type="button"
            className="reset secondary"
            onClick={resetAllToDefaults}
          >
           <TranslateComponent>Reset All to Defaults</TranslateComponent>
          </button>
        )}
      </div>
      <StandardDialogs confirm={confirm} />
    </Form>
  )
}

CharacteristicsForm.propTypes = {
  values: PropTypes.object,
  isValid: PropTypes.bool,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  equipmentType: PropTypes.string,
  readOnly: PropTypes.bool
}

CharacteristicsForm.defaultProps = {
  readOnly: false
}

export default CharacteristicsForm
