/* eslint-disable @typescript-eslint/no-non-null-assertion */
import clsx from 'clsx'
import { GroupAndClip } from '../../../chart-library/CommonComponents/Clip'
import { StackedBar } from '../../../chart-library/CommonComponents/StackedBar'
import { savingsRect } from './savingsRect'
import { useConfig } from './useConfig'

export default function Series({
  series = [],
  savingsData = {} as any,
  tooltipEventName,
  ...rest
}) {
  const {
    isInitialized,
    canvas,
    xScale,
    yScale,
    getSavingsXYposition,
    gridUpdatedAt
  } = useConfig()

  const { goalX, totalX, y } = getSavingsXYposition(savingsData)

  if (!isInitialized) {
    return null
  }

  return (
    <GroupAndClip clipName="stacked-bar-clip" gx={canvas.x} gy={canvas.y}>
      <StackedBar
        {...rest}
        data={series}
        isHorizontal
        xScale={xScale.fn}
        yScale={yScale.fn}
        textFormater={(value) => `$ ${value}`}
        tooltipDispatchEventName={tooltipEventName}
        tooltipTriggerPoint="item"
        enableTooltip
        tooltipFormatter={(data) => [
          {
            items: [
              {
                content: (
                  <div className="stacked-tt-item">
                    {data?.active?.name}
                    <p>$ {data?.active?.value}</p>
                  </div>
                )
              }
            ]
          }
        ]}
      />
      <SavingsLine
        height={canvas.height - y}
        xPosition={goalX - 1}
        yPosition={y}
        lastUpdatedAt={gridUpdatedAt}
      />
      <DrawSavingRect
        fill="#fb8f0d"
        width={120}
        height={50}
        xPosition={goalX}
        yPosition={y}
        parentWidth={canvas.width}
        lastUpdatedAt={gridUpdatedAt}
      >
        <div className="savings-text">
          <p>SAVINGS GOAL</p>
          <span>$ {savingsData.goal}</span>
        </div>
      </DrawSavingRect>
      <SavingsLine
        height={canvas.height - y}
        xPosition={totalX - 1}
        yPosition={y}
        lastUpdatedAt={gridUpdatedAt}
      />
      <DrawSavingRect
        fill="#fb8f0d"
        width={120}
        height={50}
        xPosition={totalX}
        yPosition={y}
        parentWidth={canvas.width}
        lastUpdatedAt={gridUpdatedAt}
      >
        <div className="savings-text">
          <p>TOTAL SAVINGS</p>
          <span>$ {savingsData.total.toFixed(2)}</span>
        </div>
      </DrawSavingRect>
    </GroupAndClip>
  )
}

function SavingsLine({ height, xPosition, yPosition }: any) {
  return (
    <rect
      className="savings-line"
      width={2}
      height={height}
      x={xPosition}
      y={yPosition}
    />
  )
}

function DrawSavingRect({
  width,
  height,
  xPosition,
  yPosition,
  parentWidth,
  children,
  xOffset = 0,
  yOffset = 0,
  ...props
}: any) {
  const d = savingsRect(xPosition, yPosition, width, height, parentWidth)
  return (
    <g
      className={clsx('shape-rect-with-arrow', props?.className)}
      transform={`translate(${xOffset} ${yOffset})`}
    >
      <path d={d.path} {...props} />;
      <foreignObject
        width={width}
        height={height}
        transform={`translate(${d.x} ${d.y})`}
      >
        {children}
      </foreignObject>
    </g>
  )
}
