import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Select from 'src/components/legacy/components/select/select'
import Label from 'src/components/legacy/components/label/label'
import Filter from 'src/components/legacy/components/filter/filter'
import { withTranslation } from 'react-i18next'
import { FILTER_TYPE_CHECKBOX } from 'src/components/legacy/components/filter/filter'
import './page-list-filter.scss'
import { TranslateComponent } from 'src/common/translations'

class PageListFilter extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
    filteredCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    searchText: PropTypes.string,
    searchFilter: PropTypes.string,
    totalCountLabel: PropTypes.string,
    searchTextLabel: PropTypes.string,
    searchLabel: PropTypes.string,
    sortBy: PropTypes.string,
    sortByEnum: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ),
    filterItems: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ),
    allItemsLabel: PropTypes.string,
    isDisabledFilter: PropTypes.bool,
    isDisabledSort: PropTypes.bool,
    isDisabledSearch: PropTypes.bool,
    customComponent: PropTypes.node,
    infoText: PropTypes.string,
    filterType: PropTypes.string,
    testName: PropTypes.string,
    t: PropTypes.func.isRequired
  }

  static defaultProps = {
    searchFilter: null,
    totalCountLabel: '',
    searchTextLabel: '',
    sortBy: '',
    sortByEnum: [],
    searchText: '',
    filterItems: [],
    allItemsLabel: 'All',
    infoText: null,
    isDisabledSort: false,
    isDisabledFilter: false,
    isDisabledSearch: false
  }

  handleChange = (fieldName) => (value) => {
    const { onChange, searchText, searchFilter, sortBy } = this.props

    onChange({
      searchText,
      searchFilter,
      sortBy,
      [fieldName]: value
    })
  }

  handleChangeFilter = (value) => {
    const { filterItems, filterType, onChange } = this.props

    if (filterType === FILTER_TYPE_CHECKBOX) {
      onChange({
        searchFilter: value
      })
    } else {
      const searchFilterItem = filterItems?.find(
        (i) => i?.key === value || i?.value === value
      )
      onChange({
        searchFilter: searchFilterItem ? searchFilterItem?.key : 'all'
      })
    }
  }
  getCustomComponent = () => {
    return <div className="custom-filter">{this.props?.customComponent}</div>
  }

  render() {
    const {
      totalCount,
      totalCountLabel,
      filteredCount,
      searchFilter,
      sortBy,
      searchText,
      searchTextLabel,
      searchLabel,
      sortByEnum,
      allItemsLabel,
      filterItems,
      isDisabledSort,
      isDisabledFilter,
      isDisabledSearch,
      customComponent,
      infoText,
      filterType,
      t,
      testName,
      displayControls,
      showSortBlock = true,
      showFilterBlock = true,
      showCounterBlock = true,
      showCustomComponentBlock = true,
      showInfoBlock = true,
      isCustomComponentBeforeFilter = false,
      isCustomComponentAfterFilter = false,
      isShowSearchLabel = true
    } = this.props
    const FILTER_ITEMS = [
      ...(filterType !== FILTER_TYPE_CHECKBOX
        ? [{ key: 'all', value: allItemsLabel }]
        : []),
      ...filterItems
    ]
    const sortByItem = sortByEnum?.find((i) => i?.key === sortBy)
    const fromCount = totalCount > 0 ? displayControls?.from + 1 : 0
    const totalPageCount = displayControls?.limit * (displayControls?.page + 1)
    const toCount = totalPageCount <= totalCount ? totalPageCount : totalCount
    return (
      <div className="toolbar-search">
        {Boolean(sortByEnum.length) && showSortBlock && (
          <div className="sort-block">
            <Label text={`${t('common:Sort')}:`} />
            <Select
              selectedItem={
                sortByItem ? sortByItem?.value : sortByEnum[0]?.value
              }
              onChange={this.handleChange('sortBy')}
              options={sortByEnum}
              isDisabled={isDisabledSort}
            />
          </div>
        )}

        {customComponent &&
          showCustomComponentBlock &&
          isCustomComponentBeforeFilter &&
          this.getCustomComponent()}

        {showFilterBlock && (
          <div className={isCustomComponentBeforeFilter ? 'filter-block' : ''}>
            <Filter
              selectedFilter={searchFilter}
              filterOptions={FILTER_ITEMS?.length === 1 ? [] : FILTER_ITEMS}
              filterType={filterType}
              searchText={searchText}
              searchPlaceholder={searchTextLabel}
              searchLabel={searchLabel}
              isShowSearchLabel={isShowSearchLabel}
              disabled={isDisabledFilter || isDisabledSearch}
              onChangeFilter={this.handleChangeFilter}
              onChangeSearch={this.handleChange('searchText')}
              testName={testName}
            />
          </div>
        )}

        {customComponent &&
          showCustomComponentBlock &&
          isCustomComponentAfterFilter &&
          this.getCustomComponent()}

        {totalCount > 0 && showCounterBlock && (
          <span className="counter">
            {' '}
            {`${fromCount} - ${toCount}`} <TranslateComponent>Of</TranslateComponent> {totalCount} <TranslateComponent>{`${totalCountLabel} shown`}</TranslateComponent>
          </span>
        )}
        {infoText && showInfoBlock && (
          <span className="icon icon-infocircle tooltip-container">
            <div
              className="tooltip center-top"
              dangerouslySetInnerHTML={{ __html: infoText }}
            />
          </span>
        )}
      </div>
    )
  }
}

export default withTranslation()(PageListFilter)
