import styled from "styled-components"
import ColorPicker from "../colors-picker/ColorPicker"
import { useState } from "react"
import translate, { TranslateComponent } from "src/common/translations"

const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

const PanelLabel = styled.label`
  display: block;
  // height: 16px;
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 20px;
`
const ColorInputDiv = styled.div<{ color: any }>`
  border: 1px solid #999;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  height: 26px;
  width: 40px;
  background-color:${(props) => props.color}
`
const ColorInput = ({ backgroundColor, update, label='',pickerLabel='', isRequired=false}) => {
    const [isShowColorPicker, setIsShowColorPicker] = useState(false)
    const handleColorInputClick = () =>{
        setIsShowColorPicker(true)
    }
    const labelTranslate = label && isRequired===true? translate(label)+"*": translate(label)
    return (
      <ColorWrapper>
      <PanelLabel>{labelTranslate}</PanelLabel>
        <ColorInputDiv color={backgroundColor} onClick={handleColorInputClick}>
        </ColorInputDiv>
        {
          isShowColorPicker && <ColorPicker 
                                  name={pickerLabel} 
                                  selectedColor={backgroundColor} 
                                  handleSelectColorPicker={update} 
                                  handleClose={()=> setIsShowColorPicker(false)}/>
        }
       </ColorWrapper>
    )
  }
  
export default ColorInput


