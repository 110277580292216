export const PAGE_TITLE = 'Energy Usage / Cost Intensity'

export const EUI_CHART_INITIAL_VIEW = {
    series: {
      eui: {
        color: "rgb(44,85,126)",
        title: "EUI",
        axisPositionHint: 0,
        line: {
          shape: "spline",
        },
        min: null,
        max: null
      },
      eci: {
        color: "rgb(6,225,225)",
        title: "ECI",
        axisPositionHint: 1,
        line: {
          shape: "spline",
        },
        min: null,
        max: null
  
      },
    },
    shapes: [],
    annotations: [],
  }


export const MARKER_OPTIONS = {
  BENCHMARK: "benchmark",
  EVENTMAREKR: "EventMarker"
}

export type IMarkerTypes = {
    name?: string
    key?: string
    value: string
}
  
export type IConfirmationType = 'delete' | 'create' | 'update' | ''
 
export const markerTypes: IMarkerTypes[] = [
    { key: 'Comment', value: 'Comment', name: 'Comment' },
    {
      key: 'Energy Conservation Measure (ECM)',
      value: 'Energy Conservation Measure (ECM)',
      name: 'ECM'
    }
  ]