import { SCALE_BAND } from 'src/chart-library/Utils/Scales/constant'
import {
  calculateTickCountBasedOnDimension,
  calculateTiltedTextHeight,
  filterArray,
  getTextDimensions
} from '../../helper'

export const scaleBandPadding = (obj: any = {}) => {
  let paddingObject: any = {}
  const { categories = [], scale, isX, spaceOfRenderArea, canvasDetails } = obj

  let ticks = categories
  let tilted = false
  let dimensionsOfCategories = categories?.map((text) => {
    const textDimensions = getTextDimensions(text)
    const tiltedHeight = calculateTiltedTextHeight(textDimensions?.width)
    return {
      text: text,
      tiltedHeight,
      textDimensions
    }
  })
  if (isX) {
    const returnObj: any = scaleBandHelper(
      scale,
      categories,
      dimensionsOfCategories,
      spaceOfRenderArea
    )
    ticks = returnObj?.dimensionsOfCategories
    ? returnObj?.dimensionsOfCategories
    : dimensionsOfCategories
    tilted = returnObj?.tilted ? returnObj?.tilted : tilted
    dimensionsOfCategories = returnObj?.dimensionsOfCategories
      ? returnObj?.dimensionsOfCategories
      : dimensionsOfCategories
  } else {
    // Need to define
  }

  if (tilted) {
    const allTiltedHeightsArray = dimensionsOfCategories?.map(
      (obj) => obj?.tiltedHeight
    )
    const maxTiltedHeight = Math.max(...allTiltedHeightsArray)
    const axisPadding =
      maxTiltedHeight > 80
        ? 80
        : maxTiltedHeight > (canvasDetails?.height / 2) * 0.5
        ? (canvasDetails?.height / 2) * 0.5
        : maxTiltedHeight

    paddingObject = {
      textSize: axisPadding,
      tilted: true,
      tickValues: ticks
    }
  } else {
    const allHeightsArray = dimensionsOfCategories?.map((obj) => obj?.textDimensions?.height) || []
    const maxHeight = Math.max(...allHeightsArray)

    const axisPadding =
      maxHeight > 80
        ? 80
        : maxHeight > (canvasDetails?.height / 2) * 0.5
        ? (canvasDetails?.height / 2) * 0.5
        : maxHeight

    paddingObject = {
      textSize: axisPadding,
      tickValues: ticks
    }
  }
  return paddingObject
}

export const scaleBandHelper = (
  scale,
  categories,
  dimensionsOfCategories,
  spaceOfRenderArea
) => {
  const step = scale?.step() - scale?.step() * 0.1

  // STEP 1 ------------------------------------------------------
  // Check if every text fits in available space - horizontal
  // Each should be inside scale step
  // STEP 1 ------------------------------------------------------

  const check1 = sBcheck1(categories, dimensionsOfCategories, step)

  if (check1) {
    return {
      ...check1
    }
  }

  // STEP 2 ------------------------------------------------------
  // If any one of text width is > step tilt to 45.
  // To determine that need to check if the height is <= step
  // STEP 2 ------------------------------------------------------

  const check2 = sBcheck2(categories, dimensionsOfCategories, step)

  if (check2) {
    return {
      ...check2
    }
  }

  // STEP 3 ------------------------------------------------------
  // If any one one of them height > step, reduce the tick
  // count based on available width
  // Trim the categories count based on interval
  // STEP 3 ------------------------------------------------------

  const check3 = dimensionsOfCategories?.some(
    (obj) => obj?.textDimensions?.height > step
  )
  if (check3) {
    const dimensionTickCount = calculateTickCountBasedOnDimension(
      spaceOfRenderArea,
      SCALE_BAND
    )

    const filteredCat = filterArray(categories, dimensionTickCount)
    const filteredD = dimensionsOfCategories?.filter((obj) =>
      filteredCat?.find((text) => text === obj?.text)
    )
    // STEP 4 ------------------------------------------------------
    // Check if every text fits in available step space
    // Each should be inside scale step
    // STEP 4 ------------------------------------------------------

    const check4 = sBcheck1(filteredCat, filteredD, step)

    if (check4) {
      return {
        ...check4
      }
    } else {
      return {
        categories: filteredCat,
        dimensionsOfCategories: filteredD,
        tilted: true
      }
    }
  }
}

const sBcheck1 = (
  categories: any = [],
  dimensionsOfCategories: any = [],
  step
) => {
  const check = dimensionsOfCategories?.every(
    (obj) => obj?.textDimensions?.width <= step
  )

  if (check) {
    return {
      categories: categories,
      tilted: false,
      dimensionsOfCategories
    }
  }
}

const sBcheck2 = (
  categories: any = [],
  dimensionsOfCategories: any = [],
  step
) => {
  const check = dimensionsOfCategories?.every(
    (obj) => obj?.textDimensions?.height <= step
  )
  if (check) {
    return {
      categories: categories,
      tilted: true,
      dimensionsOfCategories
    }
  }
}
