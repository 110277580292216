import PropTypes from 'prop-types'
import styled from 'styled-components'

const screen_grid_small = '599px'
const screen_grid_medium = '839px'
const Wrapper = styled.div`
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.31px;
`

const TitleWrapper = styled.div`
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
`
const Title = styled.div`
  font-size: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
  .tooltip-anchor {
    display: inline-flex;
    margin-left: 0.825rem;
    font-size: 0.825rem;
  }
`

const Line = styled.hr`
  height: 2px;
  border: none;
  margin: 0;
  background-color: #666666;
  @media (max-width: ${screen_grid_small}) {
    margin-top: 45px;
  }
  @media (max-width: ${screen_grid_medium}) {
    margin-top: 45px;
  }
`

const Header = ({ pageTitle, titleTools, hideLine }) => (
  <Wrapper>
    <TitleWrapper>
      <Title>{pageTitle}</Title>
      {titleTools}
    </TitleWrapper>
    {!hideLine && <Line />}
  </Wrapper>
)

Header.propTypes = {
  children: PropTypes.node,
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleTools: PropTypes.node,
  hideToolBar: PropTypes.bool,
  hideTitleBar: PropTypes.bool,
  hideLine: PropTypes.bool
}

export default Header
