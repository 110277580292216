import { StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

export const contactStyles = StyleSheet.create({
	contactWrapper: {
		marginTop: 20,
		fontSize: 10,
		color: colors.GREY_7,
		position: "absolute",
		bottom: 70,
		left: 30,
	},
	contractTitle: {
		fontStyle: "italic",
	},
	contactName: {
		fontWeight: "bold",
		color: colors.BLACK,
	},
})
