import { USER_EVENTS as UTILITY_BILLS_USER_EVENTS, PROPERTIES_TO_SEND_BY_EVENT as UTILITY_BILLS_PROPERTIES_TO_SEND_BY_EVENT } from "./utility-bill-data/user-events"
import { USER_EVENTS as ISSUES_AND_FINDINGS, PROPERTIES_TO_SEND_BY_EVENT as ISSUES_AND_FINDINGS_PROPERTIES_TO_SEND_BY_EVENT } from "./findings/user-events"
import { USER_EVENTS as BASELINE_LIBRARY, PROPERTIES_TO_SEND_BY_EVENT as BASELINE_LIBRARY_PROPERTIES_TO_SEND_BY_EVENT} from "./findings/user-events"
import { USER_EVENTS as BUILDING_DATA, PROPERTIES_TO_SEND_BY_EVENT as BUILDING_DATA_PROPERTIES_TO_SEND_BY_EVENT} from "./findings/user-events"
import { USER_EVENTS as OPPORTUNITIES , PROPERTIES_TO_SEND_BY_EVENT as OPPORTUNITIES_PROPERTIES_TO_SEND_BY_EVENT } from "./findings/user-events"
import { USER_EVENTS as NOTES, PROPERTIES_TO_SEND_BY_EVENT as NOTES_PROPERTIES_TO_SEND_BY_EVENT} from "./findings/user-events"
import { USER_EVENTS as EVENT_MARKERS, PROPERTIES_TO_SEND_BY_EVENT as EVENT_MARKERS_PROPERTIES_TO_SEND_BY_EVENT} from "./findings/user-events"
import { USER_EVENTS as DOCUMENTS_REPORTS, PROPERTIES_TO_SEND_BY_EVENT as DOCUMENTS_PROPERTIES_TO_SEND_BY_EVENT} from "./findings/user-events"
import { USER_EVENTS as ENERGY_PERFORMANCE} from "./findings/user-events"
import { USER_EVENTS as UTILITY_MANAGEMENT} from "./findings/user-events"
import { USER_EVENTS as BUILDING_CONFIGURATION} from "./findings/user-events"
import { USER_EVENTS as DASHBOARDS} from "./findings/user-events"
import { USER_EVENTS as NAVIGATION} from "./findings/user-events"
import { USER_EVENTS as REMOTE_ACCESS} from "./findings/user-events"
import { USER_EVENTS as USER_LIST} from "./findings/user-events"
import { USER_EVENTS as CHARTS} from "./findings/user-events"

export const USER_EVENTS = {
	...DASHBOARDS,
	...UTILITY_BILLS_USER_EVENTS,
	...ISSUES_AND_FINDINGS,
	...BASELINE_LIBRARY,
	...BUILDING_DATA,
	...OPPORTUNITIES,
	...NOTES,
	...EVENT_MARKERS,
	...DOCUMENTS_REPORTS,
	...ENERGY_PERFORMANCE,
	...UTILITY_MANAGEMENT,
	...BUILDING_CONFIGURATION,
	...NAVIGATION,
	...REMOTE_ACCESS,
	...USER_LIST,
	...CHARTS,
}

export const PROPERTIES_TO_SEND_BY_EVENT = {
	...UTILITY_BILLS_PROPERTIES_TO_SEND_BY_EVENT,
	...ISSUES_AND_FINDINGS_PROPERTIES_TO_SEND_BY_EVENT,
	...BASELINE_LIBRARY_PROPERTIES_TO_SEND_BY_EVENT,
	...BUILDING_DATA_PROPERTIES_TO_SEND_BY_EVENT,
	...OPPORTUNITIES_PROPERTIES_TO_SEND_BY_EVENT,
	...NOTES_PROPERTIES_TO_SEND_BY_EVENT,
	...EVENT_MARKERS_PROPERTIES_TO_SEND_BY_EVENT,	
	...DOCUMENTS_PROPERTIES_TO_SEND_BY_EVENT,
}
