import WidgetSampleImagePreview from '../../widget-picker/WidgetSampleImagePreview'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from '../MultiTabTorm'
import PreviewWithData from './PreviewWithData'
import { ImagePreviewWrapper } from '../../styles'
import isEmpty from 'lodash/isEmpty'
import translate, { TranslateComponent } from 'src/common/translations'

const WidgetPreview = () => {
  const { selectedWidget } = useWidgetsContext()
  const formik = useFormikContext()
  const { errors } = formik
  const [t] = useTranslation()

  return (
    <ImagePreviewWrapper>
      <div className="tab-preview">
        {!isEmpty(errors) ? (
          <>
            <div className="error-message">
              <span className="icon icon-error"></span>
              <span className="error-text">
               <TranslateComponent>A sample image is shown. Please correct the indicated errors to see a preview.</TranslateComponent> 
              </span>
            </div>
            <div className="img-box">
              <WidgetSampleImagePreview type={selectedWidget?.type} />
            </div>
          </>
        ) : (
          <>
            <PreviewWithData />
          </>
        )}
      </div>
    </ImagePreviewWrapper>
  )
}

export default WidgetPreview
