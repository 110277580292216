export const transformData = (data) => {
  const EquipmentAssociations = []

  data?.forEach((item) => {
    const EquipmentAssociation = {}
    EquipmentAssociation.association = item?.relation || ''
    EquipmentAssociation.unitTypePresentationName = item?.family || ''
    EquipmentAssociation.unitType = item?.type || ''
    EquipmentAssociation.equipmentName = item?.name || ''
    EquipmentAssociation.objectId = item?.id || null
    EquipmentAssociation.__typename = 'EquipmentAssociation'
    EquipmentAssociation.parentId = item?.parentId || ''
    EquipmentAssociations.push(EquipmentAssociation)
  })

  return { EquipmentAssociations }
}

export const transformEQViewDetailsData = (data) => {
  const EQDetails = {
    equipmentName: data?.name || '',
    equipmentType: data?.family || '',
    automationSystem: data?.device?.name || '',
    dataSourceType: data?.dataSourceType || '',
    dataSourceName: data?.dataSourceName || '',
    id: data?.id || '',
    parentId: data?.parentId || '',
    type: data?.type || '',
    buildingId: data?.building?.id || '',
    //serial: data?.device?.serial,
    serial: data?.device?.id || '',
    deviceId: data?.deviceId || '',
    copyMappingEquipmentId: data?.copyMappingEquipmentId || ''
  }
  return { EQDetails }
}

export const transformAssociationPossibleEQName = (data) => {
  const EquipmentAssociations = []

  data?.forEach((item) => {
    const EquipmentAssociation = {}
    EquipmentAssociation.objectName = item?.name || ''
    EquipmentAssociation.unitType = item?.type || ''
    EquipmentAssociation.objectId = item?.id || ''
    EquipmentAssociation.unitTypePresentationName = item?.family || ''
    EquipmentAssociation.parentId = item?.prentId || ''
    EquipmentAssociations.push(EquipmentAssociation)
  })

  return { EquipmentAssociations }
}

export const handleData = (data, possibleStateLists) => {
  let result = []
  let originalObject = {}
  let defaultObject = {
    possibleState: null
  }
  data?.map((characteristic) => {
    let possibleList
    possibleList = possibleStateLists?.filter(
      (possibleStateData) =>
        possibleStateData.tisDisplayName === characteristic.tisDisplayName
    )

    if (possibleList.length) {
      const { possibleState, isMappable, minValue, maxValue } =
        possibleList[0] ?? {}

      defaultObject = {
        possibleState: possibleState,
        readOnly: isMappable === 0,
        minValue,
        maxValue
      }
    } else {
      defaultObject = {
        possibleState: null,
        readOnly: false
      }
    }
    originalObject = {
      ...characteristic,
      ...defaultObject
    }
    result.push(originalObject)
  })
  return result
}

const possibleStateObject = (possibleState, value, dataType) => {
  try {
    let possibleStateValue =
      possibleState !== null && JSON?.parse(possibleState)
    let possibleStateObject =
      possibleStateValue !== '' ? JSON?.parse(possibleStateValue) : ''
    let response = []
    let formNewObject = {}

    if (possibleStateObject && dataType !== 'integer' && dataType !== 'float') {
      let result = Object.keys(possibleStateObject)?.map((key) => ({
        id: key,
        name: possibleStateObject[key]
      }))
      if (result.length > 1) {
        result?.map((test) => {
          if (Array.isArray(test.name)) {
            test.name.map((nameData) => {
              formNewObject = {
                propertyStateName: nameData === value ? value : nameData,
                propertyStateValue: nameData === value ? value : nameData,
                default: false,
                selected: nameData === value
              }
              response.push(formNewObject)
            })
          }
        })
        return response
      } else {
        result?.map((test) => {
          if (Array.isArray(test.name)) {
            test.name.map((nameData) => {
              formNewObject = {
                propertyStateName: nameData === value ? value : nameData,
                propertyStateValue: nameData === value ? value : nameData,
                default: false,
                selected: nameData === value
              }
              response.push(formNewObject)
            })
          }
        })
        return response
      }
    } else {
      formNewObject = {
        propertyStateName: value,
        propertyStateValue: value,
        default: false,
        selected: false
      }
      response?.push(formNewObject)
    }
    return response
  } catch (error) {
    return []
  }
}

const getDataType = (dataType, possibleState) => {
  if (dataType === 'float') {
    return 'Numeric'
  }
  if (dataType === 'integer') {
    return 'Numeric'
  }
  // For date type
  if (dataType === 'timestamp') {
    return 'Date'
  }
  if (
    dataType === 'varchar' &&
    possibleState !== null &&
    possibleState !== '""'
  ) {
    return 'Enumerated'
  }
  if (
    dataType === 'varchar' &&
    (possibleState === null || possibleState === '""')
  ) {
    return 'String'
  }
}

export const transformCharacteristicsListData = (data, characteristicMetaData) => {
  const characteristicsLists = []
  data?.forEach((item) => {
    const characteristicsList = {}
    const metaDataDetails = characteristicMetaData?.find(x => x?.targetKey === item?.targetKey)
    characteristicsList.propertyId = item?.id
    characteristicsList.characteristicDisplayName = item?.tisDisplayName ?? ''
    characteristicsList.targetKey = item?.targetKey ?? ''
    // will uncomment the below code once confirmation from PO
    // characteristicsList.resolution = metaDataDetails?.targetResolution ?? ''
    characteristicsList.resolution = item?.targetResolution ?? ''
    characteristicsList.lowerRange = item?.minValue ?? 0
    characteristicsList.upperRange = item?.maxValue ?? 0
    characteristicsList.unitOfMeasurement =
      (item?.targetMeasureUnit === 'noUnit' ? '' : item.targetMeasureUnit) ?? ''
    characteristicsList.possibleStatesList = possibleStateObject(
      item?.possibleState,
      item?.value,
      item?.targetDataType
    )
    characteristicsList.targetMeasureType = item?.targetMeasureType
    characteristicsList.possibleStates = item?.possibleState
    characteristicsList.readOnly = item?.readOnly
    characteristicsList.isFactorySetting = item?.creationType === 'Factory'
    characteristicsList.userDefinedIndicator = item?.creationType === 'User'
    characteristicsList.dataType =
      getDataType(item?.targetDataType, item?.possibleState) || ''
    characteristicsList.value = item?.value || ''
    characteristicsLists.push(characteristicsList)
  })
  return { characteristicsLists }
}
