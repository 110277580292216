import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ClickOutside from 'src/components/legacy/components/decorators/click-outside'
import classNames from 'classnames'

import './dropdown.scss'

export default class Dropdown extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    toggleOpen: PropTypes.func.isRequired,
    styleNames: PropTypes.string,
    customClassNames: PropTypes.string,
    testName: PropTypes.string
  }

  static defaultProps = {
    open: false,
    styleNames: 'nav-dropdown',
    testName: 'drop-down'
  }

  static Box = (props) => {
    const Box = ClickOutside(Dropdown)
    return <Box {...props} />
  }

  // eslint-disable-next-line no-constant-condition
  static Item = ({
    onClick,
    testName = '',
    styleNames = '',
    children,
    disabled
  }) => (
    <div
      data-testid={`${testName}-dropdown-item`}
      className={classNames('menuitem', styleNames)}
      onClick={disabled ? () => {} : onClick}
    >
      {' '}
      {children}
    </div>
  )

  static ItemHeader = ({ testName = '', children }) => (
    <Dropdown.Item testName={testName} styleNames="itemheader">
      {' '}
      {children}{' '}
    </Dropdown.Item>
  )

  static Button = React.forwardRef(
    (
      {
        onClick,
        title,
        icon,
        isCaretDisplayed = true,
        isBuildingHomePage = false,
        testName = 'drop-down'
      },
      ref
    ) => (
      <button
        data-testid={`${testName}-button`}
        ref={ref}
        type="menu"
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
      >
        {/* There should be div container here as
				not possible to use flex on button element because of issue in FF
				https://github.com/philipwalton/flexbugs/issues/16 */}
        {isBuildingHomePage ? (
          <div>
            <span className={classNames(icon)} />
          </div>
        ) : (
          <div data-testid={`${testName}-header`}>
            <span>{title}</span>
            <span className={classNames('icon', icon)} />
            {isCaretDisplayed && <span className="icon icon-expand caret" />}
          </div>
        )}
      </button>
    )
  )

  renderChildren() {
    const { toggleOpen, open = false, children } = this.props

    return React.Children.map(children, (child, index) => {
      if (index === 0) {
        // first child is element used to toggle dropdown
        return React.cloneElement(child, {
          onClick: () => {
            toggleOpen()
            child?.props?.onClick?.(!open)
          }
        })
      } else {
        // second child is container to toggle
        return React.cloneElement(child, {
          opened: open,
          children: React.Children.map(
            Array.isArray(child.props.children)
              ? child.props.children.filter(Boolean)
              : [child.props.children].filter(Boolean),
            (menuitem) => {
              const modifiedItem = React.cloneElement(menuitem, {
                onClick: () => {
                  menuitem.props.onClick && menuitem.props.onClick()
                  toggleOpen()
                }
              })
              return modifiedItem
            }
          )
        })
      }
    })
  }

  render() {
    const { open, styleNames, customClassNames, testName } = this.props

    return (
      <div
        data-testid={`${testName}-dropdown-container`}
        className={classNames(customClassNames, styleNames, { opened: open })}
      >
        {this.renderChildren()}
      </div>
    )
  }
}
