// Pages
export const WIDGET_LIBRARY = 'widget-library'
export const DASHBOARDS = 'dashboards'
export const CONNECT_DEVICE = 'connect-device'
export const ELECTRICAL_DEMAND = 'electrical-demand'
export const ENERGY_HEAT_MAP = 'energy-heat-map'
export const ENERGY_COST_SUMMARY = 'energy-cost-summary'
export const ELECTRIC_RATES = 'electric-rates'
export const ELECTRIC_CHARGES = 'electric-charges'
export const ENERGY_CONSUMPTION = 'energy-consumption'
export const LOAD_ANALYSIS = 'load-analysis'
export const BUILDING_DATA = 'building-data'
export const OPPORTUNITIES = 'opportunities'
export const FINDINGS = 'findings'
export const SERVICE_COMPANIES = 'service-companies'
export const EVENT_DETAILS = 'event-details'
export const CC_4_USER_MANAGEMENT = 'cc4-user-management'
export const PROGRAMS = 'programs'
export const DR_PROGRAM_DETAIL = 'dr-program-detail'
export const DAY_AHEAD_PROGRAM_DETAILS = 'day-ahead-program-details'
export const DR_TERM_DETAIL = 'dr-term-detail'
export const DAY_AHEAD_OFFERING = 'day-ahead-offering'
export const CREATE_DAO_PROGRAM = 'create-dao-program'
export const CREATE_DR_PROGRAM = 'create-dr-program'
export const ADD_DEMAND_RESPONSE_LOCATION = 'add-demand-response-location'
export const EDIT_DEMAND_RESPONSE_LOCATION = 'edit-demand-response-location'
export const ADD_DAY_AHEAD_LOCATION = 'add-day-ahead-location'
export const CREATE_DR_TERM = 'create-dr-term'
export const DEVICE_MANAGEMENT = 'device-management'
export const PORTFOLIO_SCORECARD = 'portfolio-scorecard'
export const GRIDFLEX_EVENT_DETAILS = 'gridflex/event-details'
export const GRIDFLEX_PROGRAMS = 'gridflex/programs'
export const GRIDFLEX_PARTICIPATING_BUILDINGS =
  'gridflex/participating-buildings'
export const GRIDFLEX_SHARE_MY_DATA = 'gridflex/shareMydata'
export const SHARE_MY_DATA = 'shareMydata'
export const GRIDFLEX_DR_PROGRAM_DETAIL = 'gridflex/dr-program-detail'
export const GRIDFLEX_DAY_AHEAD_PROGRAM_DETAILS =
  'gridflex/day-ahead-program-details'
export const GRIDFLEX_DR_TERM_DETAIL = 'gridflex/dr-term-detail'
export const GRIDFLEX_DAY_AHEAD_OFFERING = 'gridflex/day-ahead-offering'
export const GRIDFLEX_CREATE_DAO_PROGRAM = 'gridflex/create-dao-program'
export const GRIDFLEX_CREATE_DR_PROGRAM = 'gridflex/create-dr-program'
export const GRIDFLEX_ADD_DEMAND_RESPONSE_LOCATION =
  'gridflex/add-demand-response-location'
export const GRIDFLEX_EDIT_DEMAND_RESPONSE_LOCATION =
  'gridflex/edit-demand-response-location'
export const GRIDFLEX_ADD_DAY_AHEAD_LOCATION = 'gridflex/add-day-ahead-location'
export const GRIDFLEX_CREATE_DR_TERM = 'gridflex/create-dr-term'
export const GRIDFLEX_DEVICE_MANAGEMENT = 'gridflex/device-management'
export const MANAGE_DEVICES = 'manage-devices'
export const MANAGE_DEVICES_2 = 'manage-devices2'
export const DEVICE_DETAILS = 'device-details'
export const BASELINE_LIBRARY = 'baseline-library'
export const BASELINE = 'baseline'
export const INTELLIGENT_SERVICE_CONTRACTS = 'intelligent-service-contracts'
export const CONTRACTS_AND_OFFERINGS = 'contracts-and-offerings'
export const USER_MANAGEMENT = 'user-management'
export const USER_MANAGEMENT_FULL = 'user-management-full'
export const USER_MANAGEMENT_NEW = 'user-management-new'
export const USER_MANAGEMENT_SUPPORT = 'user-management-support'
export const USER_MANAGEMENT_CREATE_USER = 'user-management-create-user'
export const USER_MANAGEMENT_EDIT_USER = 'user-management-edit-user'
export const USER_MANAGEMENT_USER_DETAILS_PAGE = 'user-details'
export const METER_SETTINGS_NEW = 'meter-settings-new'
export const AUTOMATED_TEST_SETTINGS = 'automated-test-settings'
export const AUTOMATED_TEST_SETTINGS_HISTORY = 'automated-test-settings/history'
export const AUTOMATED_TEST_SUPPRESSIONS = 'automated-test-suppressions'
export const EVENT_MARKERS = 'event-markers'
export const BUILDING_SETUP = 'building-setup'
export const ALARM_NOTIFICATION = 'alarm-notification'
export const ORGANIZATION_MANAGEMENT = 'organization-management'
export const EQUIPMENT_SETUP = 'equipment-setup'
export const NOTES = 'notes'
export const TENANT_SERVICES = 'tenant-services'
export const VIDEO_LIBRARY = 'video-library'
export const BUILDING_EVALUATION = 'building-evaluation'
export const BUILDING_EVALUATION_ADMIN = 'building-evaluation-admin'
export const EQUIPMENT_LEVEL_AGGREGATION = 'equipment-level-aggregation'
export const KPI_OPPORTUNITY_REPORT = 'kpi-opportunity-report'
export const RESPONSIVE_CONTROLS = 'responsive-controls'
export const RESPONSIVE_CONTROLS_DB = 'responsive-controls-db'
export const DIGITAL_MAINTENANCE = 'spectacle/digital-maintenance'
export const CSV_UPLOADER = 'csv-uploader'
export const NOTIFICATION_SETTINGS = 'notification-settings'
export const UTILITY_BILL_DATA = 'utility-bill-data'
export const ENERGY_INTENSITY = 'energy-intensity'
export const REPORTS = 'reports'
export const AUTOMATED_INSPECTIONS = 'automated-inspections'
export const AUTOMATED_INSPECTION_DETAILS = 'automated-inspection-details'
export const PRODUCT_ANNOUNCEMENTS = 'product-announcements'
export const BUILDING_HOME_PAGE = 'building-home-page'
export const RATE_STRUCTURE_LIBRARY = 'rate-structure-library'

// Features
export const RC_CUSTOMER_DASHBOARD = 'rc-customer-dashboard'
export const RC_ADMINISTRATION = 'rc-administration'
export const RC_AGENT_CONFIGURATION = 'rc-agent-configuration'
export const BUILDING_NAV = 'Building Nav'
export const COMMAND_CENTER = 'Command Center'
export const NOTIFICATIONS = 'Notifications'
export const IMAGE_EDITOR = 'image-editor'
export const RC_DATA_VALIDATION = 'rc-data-validation'
export const REPORT_EXCEPTION_ISSUE = Symbol()

// Applications
const GRIDFLEX = 'gridflex'
const SPECTACLE = 'spectacle'
export const APPLICATIONS = [GRIDFLEX, SPECTACLE]

const PAGE_NAMES = {
  WIDGET_LIBRARY,
  DASHBOARDS,
  CONNECT_DEVICE,
  ELECTRICAL_DEMAND,
  ENERGY_HEAT_MAP,
  ENERGY_COST_SUMMARY,
  ELECTRIC_RATES,
  ELECTRIC_CHARGES,
  ENERGY_CONSUMPTION,
  LOAD_ANALYSIS,
  BUILDING_DATA,
  OPPORTUNITIES,
  FINDINGS,
  SERVICE_COMPANIES,
  EVENT_DETAILS,
  CC_4_USER_MANAGEMENT,
  PROGRAMS,
  DR_PROGRAM_DETAIL,
  DAY_AHEAD_PROGRAM_DETAILS,
  DR_TERM_DETAIL,
  DAY_AHEAD_OFFERING,
  CREATE_DAO_PROGRAM,
  CREATE_DR_PROGRAM,
  ADD_DEMAND_RESPONSE_LOCATION,
  EDIT_DEMAND_RESPONSE_LOCATION,
  ADD_DAY_AHEAD_LOCATION,
  CREATE_DR_TERM,
  DEVICE_MANAGEMENT,
  PORTFOLIO_SCORECARD,
  GRIDFLEX_EVENT_DETAILS,
  GRIDFLEX_PROGRAMS,
  GRIDFLEX_PARTICIPATING_BUILDINGS,
  GRIDFLEX_SHARE_MY_DATA,
  SHARE_MY_DATA,
  GRIDFLEX_DR_PROGRAM_DETAIL,
  GRIDFLEX_DAY_AHEAD_PROGRAM_DETAILS,
  GRIDFLEX_DR_TERM_DETAIL,
  GRIDFLEX_DAY_AHEAD_OFFERING,
  GRIDFLEX_CREATE_DAO_PROGRAM,
  GRIDFLEX_CREATE_DR_PROGRAM,
  GRIDFLEX_ADD_DEMAND_RESPONSE_LOCATION,
  GRIDFLEX_EDIT_DEMAND_RESPONSE_LOCATION,
  GRIDFLEX_ADD_DAY_AHEAD_LOCATION,
  GRIDFLEX_CREATE_DR_TERM,
  GRIDFLEX_DEVICE_MANAGEMENT,
  MANAGE_DEVICES,
  MANAGE_DEVICES_2,
  DEVICE_DETAILS,
  BASELINE_LIBRARY,
  BASELINE,
  INTELLIGENT_SERVICE_CONTRACTS,
  CONTRACTS_AND_OFFERINGS,
  USER_MANAGEMENT,
  USER_MANAGEMENT_FULL,
  USER_MANAGEMENT_NEW,
  USER_MANAGEMENT_SUPPORT,
  USER_MANAGEMENT_CREATE_USER,
  USER_MANAGEMENT_EDIT_USER,
  USER_MANAGEMENT_USER_DETAILS_PAGE,
  METER_SETTINGS_NEW,
  AUTOMATED_TEST_SETTINGS,
  AUTOMATED_TEST_SETTINGS_HISTORY,
  AUTOMATED_TEST_SUPPRESSIONS,
  EVENT_MARKERS,
  BUILDING_SETUP,
  ALARM_NOTIFICATION,
  ORGANIZATION_MANAGEMENT,
  EQUIPMENT_SETUP,
  NOTES,
  TENANT_SERVICES,
  VIDEO_LIBRARY,
  BUILDING_EVALUATION,
  BUILDING_EVALUATION_ADMIN,
  EQUIPMENT_LEVEL_AGGREGATION,
  KPI_OPPORTUNITY_REPORT,
  RESPONSIVE_CONTROLS,
  RESPONSIVE_CONTROLS_DB,
  DIGITAL_MAINTENANCE,
  CSV_UPLOADER,
  NOTIFICATION_SETTINGS,
  UTILITY_BILL_DATA,
  ENERGY_INTENSITY,
  REPORTS,
  AUTOMATED_INSPECTIONS,
  AUTOMATED_INSPECTION_DETAILS,
  PRODUCT_ANNOUNCEMENTS,
  BUILDING_HOME_PAGE,
  RC_CUSTOMER_DASHBOARD,
  RC_ADMINISTRATION,
  RC_AGENT_CONFIGURATION,
  COMMAND_CENTER,
  RC_DATA_VALIDATION,
  RATE_STRUCTURE_LIBRARY
}
export default PAGE_NAMES

export const ROLES = {
  REMOTE_ACCESS: 'RemoteAccess',
  GENERAL_USER: 'GeneralUser',
  GS_PRIMARY_USER: 'GS-PrimaryUser',
  GS_TECHNICIAN: 'GS-Technician',
  GS_VIEWER: 'GS-Viewer',
  GS_EXTERNAL_TECHNICIAN: 'GS-External-Technician',
  TECHNICIAN: 'Technician',
  TECH_SUPPORT: 'TechSupport',
  SUPER_ADMIN: 'SuperAdmin',
  TCC4_USER: 'TCC4',
  TCC_TECHNICIAN: 'TCC_Technician',
  ANALYTICS_TECHNICIAN: 'AnalyticsTechnician',
  CUSTOMER_TECHNICIAN: 'CustomerTechnician',
  CUSTOMER_ASSETS_TECHNICIAN: 'CustomerAssetsTechnician',
  KIOSK: 'Kiosk',
  DEVICEMANAGER: 'DeviceManager',
  ENSEMBLE_ADMINISTRATOR: 'Ensemble-Administrator',
  ENSEMBLE_ADVANCE_BUILDING_OPERATOR: 'Ensemble-AdvanceBuildingOperator',
  ENSEMBLE_BUILDING_OPERATOR: 'Ensemble-BuildingOperator',
  ENSEMBLE_VIEW: 'Ensemble-View',
  ENSEMBLE_TENANT_EMPLOYEE: 'Ensemble-TenantEmployee',
  RC_TECHNICIAN: 'RC-Technician',
  RC_ADMIN: 'RC_ADMIN',
  RC_DATABASE_ADMIN: 'RC_DB_ADMIN',
  CONTRACTOR_TECHNICIAN: 'ContractorTechnician',
  SERVICE_COMPANY_ADMIN: 'ServiceCompanyAdmin',
  DMS_BUILDINGOWNER: 'DMS_BUILDINGOWNER',
  DMS_SERVICER: 'DMS_SERVICER',
  CONTRACT_MANAGER: 'ContractManager',
  OFFERING_MANAGER: 'OfferingManager'
}
