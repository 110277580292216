import clsx from 'clsx'
import { arc, pie, pointRadial, scaleLinear } from 'd3'
import { useEffect, useId, useMemo, useRef } from 'react'
import ShadowDrop from './ShadowDrop'
import { useTooltip } from '../Tooltip'

import { ColorRange, GauageProps, pointerData } from './types'
import {
  defaultTootipFormatter,
  deg2rad,
  fillColorRange,
  renderNeedle
} from './handlers'

export default function Gauge({
  className,
  colorRange = [],
  data = null,
  disableAutoPosition = false,
  enableTooltip,
  endAngle = 115,
  height = 300,
  hideLabels = false,
  hideTicks = false,
  labelElProps = {},
  labelGap = 20,
  max,
  min = 0,
  padAngle = 0.2 / 90,
  radius,
  showEndLabel = false,
  showStartLabel = false,
  tooltipDispatchEventName = '',
  tooltipFormatter = defaultTootipFormatter,
  startAngle = -115,
  width = 300,
  x,
  y
}: GauageProps) {
  // For d3 to render needle
  const pointerRef = useRef(null)
  const uuid = useId()

  let isInvalidMinMax = false

  // --------------------- validate props ---------------------

  if (
    max === undefined ||
    max === null ||
    typeof max === 'string' ||
    min === max ||
    min > max
  ) {
    isInvalidMinMax = true
  }

  const startAngleInRad = deg2rad(startAngle)
  const endAngleInRad = deg2rad(endAngle)

  const minlimit = isInvalidMinMax ? 0 : min
  const maxlimit = isInvalidMinMax ? 1 : max

  // Ticks
  const guageMinMaxScale = useMemo(
    () => scaleLinear([minlimit, maxlimit], [startAngleInRad, endAngleInRad]),
    [startAngleInRad, endAngleInRad, minlimit, maxlimit]
  )

  const tickValues = guageMinMaxScale.ticks()

  let sliceStartsAt = 1
  let sliceEndsAt = -1

  // Condition to find first and last tick values
  if (tickValues.length > 1) {
    const firstValue = tickValues[0]
    const secondValue = tickValues[1]

    const diff = secondValue - firstValue

    const lastValue = tickValues[tickValues.length - 1]

    const avg = diff / 2

    if (minlimit !== firstValue) {
      sliceStartsAt = avg <= firstValue - minlimit ? undefined : 1
    }

    if (maxlimit !== lastValue) {
      sliceEndsAt = avg <= maxlimit - lastValue ? undefined : -1
    }
  }

  const scaleTicks = [
    minlimit,
    ...tickValues.slice(sliceStartsAt, sliceEndsAt),
    maxlimit
  ]

  let ticks = hideTicks ? [] : scaleTicks
  let tickLabels = hideLabels ? [] : scaleTicks

  if (hideLabels) {
    showStartLabel && scaleTicks.length && tickLabels.push(scaleTicks[0])
    showEndLabel &&
      scaleTicks.length > 1 &&
      tickLabels.push(scaleTicks[scaleTicks.length - 1])
  }

  if (isInvalidMinMax) {
    ticks = []
    tickLabels = []
    data = { ...data, value: 0 }
  }

  // --------------------- define radius and x, y positions of gauge ---------------------

  let gaugeX = 0
  let gaugeY = 0

  if (radius === undefined) {
    radius = Math.min(width, height) / 2
  }

  radius -= labelGap * 2
  gaugeX = gaugeY = radius + labelGap * 2

  const outerRadius = radius
  const innerRadius = radius * 0.45
  const labelRadius = radius + labelGap
  const pointerDialRadius = outerRadius * 0.1

  // --------------------- init pie & arc generator ---------------------

  const pieGenerator = useMemo(() => {
    return pie<ColorRange>()
      .value((d: ColorRange) => (d.limit === null ? 1 : d.limit))
      .sortValues(null)
      .padAngle(padAngle)
      .startAngle(startAngleInRad)
      .endAngle(endAngleInRad)
  }, [padAngle, startAngleInRad, endAngleInRad])

  const arcs = useMemo(() => {
    const cRange = fillColorRange(minlimit, colorRange, isInvalidMinMax)

    return pieGenerator(cRange)
  }, [pieGenerator, colorRange, isInvalidMinMax])

  const pieArcGenerator = arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)

  const pointerDialGenerator = pie()

  const pointerDialArcs = useMemo(() => pointerDialGenerator([1]), [])

  const pointerDialArcGenerator = arc().outerRadius(pointerDialRadius)

  const pointDial: any = pointerDialArcs[0]

  const [dataLabelX, dataLabelY] = pointRadial(
    pointDial.endAngle / 2,
    pointerDialRadius
  )

  const pointerColor = data ? data.color || '#000000' : '#000000'

  const labels = data?.label || []
  const isInlineLabel = !!data?.isInlineLabel
  const labelProps: pointerData['labelProps'] | any[] = data?.labelProps || []!

  // --------------------- handle pointer needle ---------------------

  useEffect(() => {
    if (data === null) return () => {}

    renderNeedle(pointerRef.current, [data], {
      pointerColor,
      guageMinMaxScale,
      startAngle,
      endAngle,
      uuid,
      outerRadius,
      pointerDialRadius,
      minlimit,
      maxlimit
    })
  }, [
    data,
    pointerColor,
    startAngle,
    endAngle,
    outerRadius,
    pointerDialRadius,
    minlimit,
    maxlimit
  ])

  // --------------------- handle tooltip ---------------------

  const [tooltipDispatch] = useTooltip({
    dispatchEventName: tooltipDispatchEventName,
    disable: !enableTooltip || isInvalidMinMax,
    formatter: tooltipFormatter
  })

  // --------------------- render phase ---------------------
  return (
    <g
      className={clsx('t-charts-gauge', className)}
      filter={`url(#drop-shadow-${uuid})`}
      transform={`translate(${disableAutoPosition ? x : gaugeX} ${
        disableAutoPosition ? y : gaugeY
      })`}
    >
      {/* Main Arc */}
      {arcs.map(function (d, indexAsKey) {
        return (
          <g
            filter={`url(#drop-shadow-${uuid})`}
            key={'_main-arc_' + indexAsKey}
          >
            <path
              d={pieArcGenerator(d as any) as any}
              fill={d.data.color}
              onMouseMove={(event) => {
                tooltipDispatch(
                  event,
                  '_tooltip-main-arc_' + indexAsKey,
                  true,
                  d.data as any
                )
              }}
              onMouseLeave={(event) => {
                tooltipDispatch(
                  event,
                  '_tooltip-main-arc_' + indexAsKey,
                  false,
                  []
                )
              }}
            />
          </g>
        )
      })}

      {/* Guage ticks */}
      <g>
        {ticks.map((t, indexAskey) => {
          const d = `M${pointRadial(
            guageMinMaxScale(t),
            innerRadius + outerRadius / 2
          )} L${pointRadial(guageMinMaxScale(t), outerRadius)}`

          if (indexAskey === 0 || ticks.length - 1 === indexAskey) {
            return null
          }

          return (
            <path
              d={d}
              stroke="#fff"
              strokeOpacity="1"
              strokeWidth="2"
              key={'_tick_' + indexAskey}
            />
          )
        })}

        {/* Guage tick labels */}
        {tickLabels.map((t, indexAskey) => {
          const [xPos, yPos] = pointRadial(guageMinMaxScale(t), labelRadius)

          return (
            <text
              key={'_tick-label_' + indexAskey}
              x={xPos}
              y={yPos}
              textAnchor="middle"
              alignmentBaseline="middle"
              {...labelElProps}
            >
              {t}
            </text>
          )
        })}
      </g>

      {/*  Pointer Circle  implemented using Arc  */}
      <g transform={`translate(${dataLabelX} ${dataLabelY * 2})`}>
        {isInlineLabel && (
          <text alignmentBaseline="hanging" dx="0" dy="1em" textAnchor="middle">
            {labels.map((label, indexAsKey) => (
              <tspan
                {...(labelProps as any)[indexAsKey]}
                key={'_data-label-text_' + indexAsKey}
              >
                {label}
              </tspan>
            ))}
          </text>
        )}
        {!isInlineLabel &&
          labels?.map(function (d, indexAsKey) {
            return (
              <text
                alignmentBaseline="hanging"
                dx="0"
                dy={`${indexAsKey}em`}
                key={'_data-label-text_' + indexAsKey}
                textAnchor="middle"
                {...(labelProps as any)[indexAsKey]}
              >
                {d}
              </text>
            )
          })}
      </g>

      {/* Gauage pointer */}
      <g ref={pointerRef} filter={`url(#drop-shadow-${uuid})`}></g>

      {/*  Pointer Circle  implemented using Arc  */}
      {pointerDialArcs.map(function (d, indexAsKey) {
        return (
          <path
            d={pointerDialArcGenerator(d as any) as any}
            fill={pointerColor}
            key={'_pointer-dial-arc_' + indexAsKey}
          />
        )
      })}

      {/* Gauage shadow */}
      <ShadowDrop uuid={uuid} />
    </g>
  )
}
