import { fontFamily } from '../../styles/common/font-style'
import { getTheme, isNumber } from '../../utils'
import { system, get } from '../core'

// --------------------------------------------- default typography theme props ---------------------------------------------

const defaults = {
  fontSizes: [12, 14, 16, 18, 20, 24, 28, 32, 42],
  fontSizeUnitType: 'px',
  fonts: {
    ...fontFamily
  }
}

// --------------------------------------------- transform fn ---------------------------------------------

const getFontSize = (n, scale, _props) => {
  if (!isNumber(n)) return n

  const unit = getTheme('fontSizeUnitType', defaults.fontSizeUnitType)(_props)

  return get(scale, n, n) + unit
}

// --------------------------------------------- config ---------------------------------------------

const config = {
  fontFamily: {
    property: 'fontFamily',
    scale: 'fonts',
    defaultScale: defaults.fonts
  },
  fontSize: {
    property: 'fontSize',
    scale: 'fontSizes',
    transform: getFontSize,
    defaultScale: defaults.fontSizes
  },
  fontWeight: {
    property: 'fontWeight',
    scale: 'fontWeights'
  },
  lineHeight: {
    property: 'lineHeight',
    scale: 'lineHeights'
  },
  letterSpacing: {
    property: 'letterSpacing',
    scale: 'letterSpacings'
  },
  textAlign: true,
  fontStyle: true
}

export const props = Object.keys(config)
export const typography = system(config)
export default typography

const fontFamilyconfig = {
  fontFamily: {
    property: 'fontFamily',
    scale: 'fonts',
    defaultScale: defaults.fonts
  }
}
export const typographyFontFamily = system(fontFamilyconfig)

// ---------------------------------------------- Theme Mapper ----------------------------------------------

export const mergeTheme = (_componentTheme, _userTheme = {}, newTheme) => {
  newTheme['fontSizes'] = _userTheme['fontSizes'] || defaults.fontSizes
  newTheme['fonts'] = { ...defaults.fonts, ..._userTheme['fonts'] }
  return null
}
