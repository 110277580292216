export const PLACEHOLDER = '--'

export const ACTIONS = {
  VIEW: 'view',
  EDIT: 'edit'
}

export const DATA_TYPES = {
  ENUMERATED: 'Enumerated',
  STRING: 'String',
  NUMBER: 'Numeric',
  DATE: 'Date',
  STATES: 'States'
}

export const INPUT_TYPE_MAP = {
  [DATA_TYPES.STRING]: 'text',
  [DATA_TYPES.NUMBER]: 'number'
}

export const DEFAULT_POSSIBLE_STATE = {
  propertyStateName: null,
  propertyStateValue: null,
  selected: true,
  default: false,
  __typename: 'PossibleStatesListType'
}

export const STATE_OPTIONS = [
  {
    propertyStateName: 'Display Communicated Value',
    propertyStateValue: 'Default',
    selected: true,
    default: true
  },
  {
    propertyStateName: 'Converted Communicated Value',
    propertyStateValue: 'Converted',
    selected: false,
    default: false
  }
]
export const CONVERTED = 'Converted'

export const CHARACTERISTIC_TYPES = {
  STATE_OPTION: 'State Option',
  POSSIBLE_STATES: 'Possible States'
}
export const CHARACTERISTIC_TYPES_KEY = {
  STATE_OPTION: 'StateOption',
  POSSIBLE_STATES: 'PossibleStates'
}
export const MULTISTATEPOINT = 'MultiState Point'
export const STATE_TYPES = {
  propertyStateName: 'Communicated Value',
  propertyStateValue: 'Displayed Value'
}
export const DATACOLLECTIONRATE = 'Data Collection Rate'
export const CONFIRMATION_POP_UP = {
  headerText: 'Confirmation',
  body: 'Changes made may take up to 30 minutes to be reflected in Command Center 4. Thank you for your patience.'
}
export const EQUIPMENT_TYPES = {
	AIR_HANDLING_UNIT: "Air Handling Unit",
	ANALOG_POINT: "Analog Point",
	CHILLER: "Chiller",
	ELECTRIC_METER: "Electric Meter",
	ENVIRONMENT_METER: "Environment Meter",
	GAS_METER: "Gas Meter",
	LIQUID_FLOW_METER: "Liquid Flow Meter",
	LOAD_VALVE: "Load Valve",
	THERMAL_ENERGY_METER: "Thermal Energy Meter",
	VIRTUAL_METER: "Virtual Meter",
}
