import colors from 'src/components/layouts/colors.json'
import styled from 'styled-components'
const screen_medium_min_width = '914px'
const screen_grid_small = '599px'
import { Link } from 'react-router-dom'
import { InlineEditFormWrapper } from '../opportunities/styles'
import { Container } from 'src/components/layouts'
import grid from 'src/denali-ui/legacy/common_file/styles/grid'

export const screen_max_extra_small_max_width = '633px'

export const FilterIcon = styled.div`
  /* color: ${colors.WHITE};
  background-color: ${colors.TBA_DARKER_GREY}; */
  float: right;
  margin-left: 10px;
`
export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  > div: first-child {
    width: 100%;
  }
  > div: last-child {
    margin-right: 3%;
  }
  
`

export const Button = styled.button`
  font-size: 14px;
  color: ${colors.WHITE};
  background-color: ${colors.TBA_DARKER_GREY};
  padding: 10px 10px 8px 12px;
  margin-right: 5px;
  border-radius: 5px;
`

export const StyledCreatDropdown = styled.div<{ disabled: boolean }>`
  > div {
    height: 30px;
    > div {
      padding-right: 0 !important;
      .menuitem {
        font-size: 13px;
        #create-electric-cost,
        #create-gas-cost {
          text-decoration: none;
        }
        #create-electric-cost:hover,
        #create-gas-cost:hover {
          text-decoration: underline;
        }
      }
    }
  }
  button {
    background-color: #7d7d7d !important;
    border-radius: 7px;
    padding: 0 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px !important;
    font-weight: 600;
  }
  ${(props) =>
    props.disabled &&
    `
            button {
                background-color: #eeeeee !important;
                border-color: #cccccc !important;
                color: #cccccc !important;
                pointer-events: none;
            }`}
`

export const TitleToolsWrapper = styled.div`
  display: flex;
  column-gap: 5px;
`

export const DocumentDetailsWrapper = styled.div`
  && .document-details {
    min-width: ${grid('screen-medium')};
  }
`
export const PanelItem = styled.div`
  width: 25%;
`
export const PanelLabel = styled.label`
  display: block;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 15px;
`
export const PanelText = styled.div`
  margin-top: 5px;
`
export const PanelDiv = styled.div`
  display: flex;
`
export const InformationPanel = styled.div`
  display: flex;
  width: 100%;
`

export const DocumentDetails = styled.div`
  .document-field {
    padding: 10px !important;
  }
  & .document-edit {
    margin: 0 2px;
    width: 22px;
    font-size: 16px;
    background-color: transparent;
    padding: 10px !important;

    &[disabled] {
      color: ${colors.TBA_LIGHT_GREY};
      pointer-events: none;
      margin-left: -20px;
    }
    > .icon-edit,
    .icon-trash2 {
      color: ${colors.TBA_DARKER_GREY};
      border: none;
      font-size: 16px;
    }
  }
`

export const ConsultationViewWrapper = styled.div`
  && .detail-view-page {
    min-height: 100%;
    padding: 20px 20px 0 20px;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;

    & .section-spinner {
      background: transparent;
      border: none;
    }
    & .header-wrapper {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;
      border-bottom: 2px solid ${colors.TBA_DARKER_GREY};

      & .icon-visible {
        color: ${colors.TBA_DARKER_GREY};
        font-size: 18px;
        background: transparent;
        border: transparent;
        vertical-align: middle;
        margin-left: 10px;
        height: 21px;
        line-height: 22px;

        &.green {
          color: ${colors.GREEN};
        }
      }
      & .icon-hidden {
        color: ${colors.TBA_DARKER_GREY};
        font-size: 18px;
        background: transparent;
        border: transparent;
        vertical-align: middle;
        margin-left: 6px;
        height: 21px;
        line-height: 22px;

        &.green {
          color: ${colors.GREEN};
        }
      }

      & .icon-help {
        margin-left: 6px;
      }

      & .title-wrapper {
        display: flex;
        align-items: center;
        padding: 0;

        & .title {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        button,
        a {
          line-height: 10px;
          border: none;
        }
      }

      & .wrapper {
        &.expanded {
          width: 294px;
        }
      }

      & .toolbar {
        justify-content: space-between;

        & .toolbar-buttons {
          max-width: 330px;
          display: flex;
          align-items: center;
          & > * {
            margin-left: 10px;

            &:hover {
              cursor: pointer;
            }
          }
        }

        & .tooltip-container {
          font-size: 18px;
          line-height: 22px;
          color: ${colors.TBA_DARKER_GREY};
          &.disabled {
            color: ${colors.TBA_LIGHT_GREY};
            pointer-events: none;
          }
        }
        & .image-preview {
          & .section-spinner {
            height: 30px;
            min-width: 80px;
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    & .error-text {
      a {
        color: ${colors.RED};
        text-decoration: underline;
      }
    }
    & .consultation-highlights {
      align-items: flex-start;
      justify-content: space-between;
      padding: 0;
      @media (min-width: ${screen_medium_min_width}) {
        display: flex;
      }
      /* @include screen-medium {
			display: flex;
		} */
    }
    & .created-info {
      /* text-align: right; */
      & .created-info-item {
        /* height: 26px; */
        padding-bottom: 10px;
        max-width: 400px;
        /* label {
          padding-right: 10px;
        } */
      }
      label {
        font-weight: normal;
      }
      span {
        font-weight: bold;
      }
      @media (max-width: ${screen_grid_small}) {
        text-align: left;
      }
    }
    & .created-user-info {
      text-align: right;
      p {
        font-weight: bold;
        margin: 0;
        height: 26px;
      }
      span {
        display: block;
      }
      /* @include  screen-max-extra-small {
			text-align: left;
		} */
    }
    & .detail-sub-footer {
      display: flex;
      flex-flow: column;
      padding: 16px 0;

      & .toolbar-buttons {
        max-width: 300px;
        & > * {
          margin-left: 10px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    & .description {
      padding: 20px 10px;
    }
    &
      .image-gallery-wrapper
      .image-gallery-content:not(.fullscreen)
      .image-gallery-slides {
      height: 350px;
    }
  }

  && .detail-view-page-footer {
    background: ${colors.WHITE};
    padding: 16px;

    & .section-spinner {
      height: 26px;
      margin: 0;

      & .icon.spin-wheel {
        font-size: 18px;
      }
    }

    & .priority {
      font-size: 14px;
      max-width: 200px;

      h3 {
        margin: 0;
      }
    }

    & .file-view {
      align-items: flex-start;
    }

    & .file-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: row;
      margin-bottom: 10px;
    }

    & .footer-info-block {
      text-align: right;
      & .footer-info-item {
        display: flex;
        justify-content: flex-end;
        min-height: 26px;
        align-items: center;
        & .editable-field-wrapper {
          display: flex;
          align-items: center;
        }
        & .editable-field .editable-field-wrapper:not(.read-only) {
          position: inherit;
        }
        &
          .editable-field-wrapper
          .editable-field
          .edit-view.read-mode
          .edit-view__value {
          line-height: 15px;
          font-weight: bold;
          &:after {
            font-weight: normal;
          }
        }
        label {
          padding-right: 10px;
        }
        & .finding-row {
          display: flex;
        }
      }
      label {
        font-weight: normal;
      }
      span {
        font-weight: bold;
      }
      & .assigned-to {
        label {
          margin-left: 0;
          width: 90px;
          margini-top: 2px;
        }
        & .icon {
          align-self: center;
          padding-left: 5px;
          font-weight: normal;
        }
        & .react-selectrix {
          width: 200px;
          .rs-toggle {
            text-align: left;
          }
        }
        & .section-spinner {
          width: 200px;
          padding: 0;
        }
        & .icon-close {
          background: ${colors.TBA_LIGHTEST_GREY};
          border: 1px solid ${colors.TBA_DARK2_GREY};
          border-left: none;
          padding: 6px;
        }
      }
      @media (max-width: ${screen_grid_small}) {
        text-align: left;
      }
    }
  }

  && .detail-view-page-pdf {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;
  }
  && .consultation-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    & > *:not(:last-child) {
      margin-right: 8px;
    }
    @media (max-width: ${screen_max_extra_small_max_width}) {
      flex-wrap: wrap;
      & > * {
        flex: 100%;
        max-width: 190px;
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 6px;
        }
      }
      & .pdf-generator-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`
export const Column = styled.div<{ columns?: string; width?: string }>`
  grid-template-columns: ${(props) => props.columns || '1fr 1fr '};
  display: grid;
  gap: 20px;
  & .doc-name {
    max-width: 420px;
  }
  ${grid('screen-max-extra-small')} {
    grid-template-columns: ${(props) => '1fr'};
  }
`

export const Input = styled.input`
  width: 100%;
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const HrTag = styled.hr`
  margin-top: 25px;
`
export const ToolbarWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  width: 100%;
  grid-template-columns: 80px 80px;
  justify-content: end;
`

export const LinkButton = styled(Link)`
  height: 100%;
  color: white;
  text-decoration: none;
  padding: 7px 0px;
`
export const InlineEditForm = styled(InlineEditFormWrapper)`
  width: 50%;
  @media (min-width: 990px) {
    width: 900px;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`

export const CustomContainer = styled(Container)`
  gap: 20px;
`

interface Props {
  width?: number
}

export const LabelWrapper = styled.div`
  display: grid;
`

export const SelectWrapper = styled.div<{ width: any, isMarginLeftAdjust?: boolean }>`
  ${(props) =>
    props.width <= 633 &&
    `
  & .select-wrapper{
    align-self: center;
    max-height: 50px;
    min-width: 200px;
    max-width: none;
    position: relative;
   }
      `}
      @media  (max-width: ${screen_grid_small}) {
        & .select-wrapper{
          align-self: center;
          max-height: 50px;
          min-width: 200px;
          max-width: none;
          position: relative;
         }
      }
      &.sc-margin{
        margin-top: 15px
        }
      & .rs-wrapper{
        width:200px;
      }
      .error-text{
        color:${colors.RED};
      }
  
        ${(props) => ((props.width > 400 && props.width < 500) && props.isMarginLeftAdjust) && `margin-left: auto;`}
        ${(props) => ((props.width > 600 && props.width < 1200) && props.isMarginLeftAdjust) && `margin-left: 90px;`}
        ${(props) => ((props.width > 1200 && props.width < 1500) && props.isMarginLeftAdjust) && `margin-left: 90px;`}
        ${(props) => (props.width > 1400 && props.isMarginLeftAdjust) && `margin-left: 30px;`}
`

export const DateWrapper = styled.div<Props>`
  ${(props) =>
    props.width <= 633 &&
    `
  & .date-container{
    width: 100%;
    & .modal-date-input {
      align-self: center;
      width: 86%!important;
      position: relative;
     }
  }

      `}
`

export const ColumnDateContainer = styled.div<{
  columns?: string
  width?: string
}>`
  grid-template-columns: ${(props) => props.columns || '1fr 1fr '};
  display: grid;
  gap: 20px;
  @media (max-width: 742px) {
    grid-template-columns: 1fr repeat(1, 200px);
  }
  ${grid('screen-max-extra-small')} {
    grid-template-columns: ${(props) => '1fr'};
  }
`

export const CustomHeader = styled.div`
  & .header-wrapper {
    margin-top: 20px;
  }
`

export const ErrorText = styled.label`
     {
         color:${colors.RED};
         font-size: 10px;
         font-style: italic;
         height: 16px;
         letter-spacing: 0.2px;
         line-height: 16px;
         width: 100%
     }
 `
export const ButtonWrapper = styled(Link)`
  button {
    height: 100%;
  }
`
// custom layout
export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  #consult-name{
    max-width: 435px
  }
  @media  (max-width: ${screen_grid_small}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
 & .modal-text-wrapper .custom-label{
  padding: 15px 0 0 0px !important;
  }
`
export const DivWrap = styled.div`
  justify-content: flex-end;
  display: grid;
  gap: 25px;
  align-self: flex-end;

  > div {
    display: grid;
  }
`
export const DivStyl = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0;
  margin-top: 20px;
`
export const SectionStyl = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  margin-top: 20px;
`
export const DivWrapper = styled.div`
  gap: 10px;
  align-items: center;
  margin-top: 20px;
  display: flex;
  @media  (max-width: ${screen_grid_small}) { 
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    align-items: unset;
    gap: 0px;
  }
}
`
export const ErrorContent = styled.span`
  color: #d52b23;
`

export const Div = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const Wrapper = styled.div`
  width: calc(100% - 165px);
  > section > article {
    padding: 20px;
  }
  @media  (max-width: ${screen_grid_small}) {
    width:100%!important;
  }
  @media  (max-width: 1400px) {
    width: 900px
  }
`

export const EUWrapper = styled.div`
  width: calc(100%);
  > section > article {
    padding: 20px;
  }
  @media  (max-width: ${screen_grid_small}) {
    width:100% !important;
    width:100% !important;
  }
`

export const sectionWrap = styled.div`
  padding: 20px;
`

export const PageTitleWrapper = styled.div`
  display: grid;
  grid-column: span 12 / span 12;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`

export const VisibilityWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ToggleButtonWrapper = styled.div`
  display: grid;
  grid-column: span 12 / span 12;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`
export const ToggleButtonWrapperEnd = styled.div`
  display: grid;
  grid-column: span 12 / span 12;
`
export const BarChartToggleWrapper = styled.div`
  justify-content: end;
  display: flex;
  align-items: center;
  grid-column: 1 / -1;
  margin-bottom: 0px;
}
`
export const SectionToggleWrapper = styled.div`
  justify-content: end;
  display: flex;
  align-items: center;
}
`

export const HeaderToggleWrapper = styled.div`
    display: flex;
    align-items: center;
}
`
export const EUHeaderToggleWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
}
`

export const SectionHighlight = styled.div`
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  margin-top: 20px;
  .carousel-container {
    position: unset !important;
    width: 100% !important;
  }
  .cs-highlight-box .text-editor > div{
    padding:20px 0px 0px 0px;
  }
  .cs-highlight-currency { 
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
  }
  @media  (max-width: ${screen_grid_small}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap : ${props => props["sm-gap"] || 0}px;
    .cs-highlight-currency{
      width:100%
      input {
        -webkit-appearance: none;
        -moz-appearance: textfield;

      }
    }
  
  }
`

export const AssumptionWrapper = styled.div`
  margin-top: 20px;
  span {
    font-size: 13px;
    font-weight: 700;
    margin-right: 20px;
  }
`
export const TextWrapper = styled.div`
  margin-top: 20px;
  span {
    font-size: 13px;
    font-weight: 700;
    margin-right: 20px;
  }
`

export const StyledButton = styled.button`
  height: 30px;
  padding: 0 10px;
  background-color: ${(props) => "#467e26"};
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.36px;
  text-transform: uppercase;
	color: #ffffff;
	float: right;
  margin: inherit;
	margin-left: auto;
  margin-right: 0;
`

export const ContactDivWrap = styled.div`
justify-content: flex-end;
display: grid;
gap: 25px;
align-self: flex-end;
& label{
  margin-left:0px;
}
& input {
  margin-left:0px;

}
> div {
  display: grid;
}
@media  (max-width: ${screen_grid_small}) {
  justify-content: normal;

}

`

export const OrganizationWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  /* display: grid; */
  grid-template-columns: repeat(${props => props["template-col"] || 3}, minmax(0, 1fr));
  gap : ${props => props["md-gap"] || 0}px;
  margin-top: 20px;
  .org-dropdown {
    width: 50%
  }
  .building-dropdown {
    margin-top: 20px;
    width:${props => props["building-width"]};
    .selectrix-wrapper{
    width:${props => props["building-width"]};
    }
     .selectrix-wrapper.selectrix-wrapper--type-autocomplete.hideArrow
    .react-selectrix
    .rs-body {
    display: none;
    cursor: none !important;
  }
  .selectrix-wrapper.selectrix-wrapper--type-autocomplete.hideArrow
    .react-selectrix
    .rs-header
    .rs-arrow-wrapper {
    display: none;
    cursor: none !important;
  }
  }
  @media  (max-width: ${screen_grid_small}) {
    grid-template-columns: repeat(1, minmax(0, 1fr))!important;
    gap : ${props => props["sm-gap"] || 0}px
  }
  
  @media  (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
  & .modal-text-wrapper .custom-label{
    padding: 15px 0 0 0px !important;
    }
`
export const SectionContainerWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props["template-col"] || 3}, minmax(0, 1fr));
  gap : ${props => props["md-gap"] || 0}px;
  @media  (max-width: ${screen_grid_small}) {
    grid-template-columns: repeat(1, minmax(0, 1fr))!important;
    gap : ${props => props["sm-gap"] || 0}px
  }
  
  @media  (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
`

export const TextEditorWrap = styled.div`
>div{
padding: 20px 0px 0px 0px;
}
`
export const TableWrapper = styled.div`
.note{
  color: #757575;
  font-style: italic;
}
height: 500px;
overflow: scroll;
`;

export const ConsultationOpportunityError = styled.div`
.error-message {
	display: inline-flex;
	background: ${colors.WHITE};
	padding: 10px;
	border: 2px solid ${colors.RED};

	& .error-text {
		margin-left: 9px;
		margin-top: 4px;
		text-align: left;
		font-weight: bold;
    font-style: italic;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.26px;
    color: ${colors.RED};
}
	}
	& .icon {
		flex-shrink: 0;
		color: ${colors.RED};
		font-size : 22px;
	}
}
`

export const DisableDiv = styled.div<{ isDisable?: boolean }>`
 ${(props) =>
    props.isDisable &&
    `
   pointer-events: none;
   opacity: 0.7;
 `}
 `

 export const SpanWrapper = styled.span`
.account-details {
  font-size: 14px;
}
>b {
  font-weight: 300px;
}
 `
 export const WarningContainer = styled.div`
  border: 2px solid ${colors.RED};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  color: ${colors.RED};
`

export const PlotHolder = styled.div`
 display: block;
 width: 55%;
 height:100%;
 justify-content: center;
 .Responsive-wrapper{
  height:100%;
 }
 #pie-container{
  height:100%;
 }
 margin-left: 100px;
 
@media (min-width: 1200px) {
   margin-top: 40px;
}
@media (min-width: 1400px) {
  margin-top: 40px;
}

 @media (max-width: 1300px) {
   margin-left: 150px;
   margin-right: 200px;
 }
 @media (max-width: 810px) {
  margin-left: 150px;
  margin-right: 200px;
  margin-top: 30px; 
}
 @media (max-width: 634px) {
  margin-left: 150px;
   margin-right: 200px;
   margin-top: -20px; 
 }
`

export const ReportAddPage = styled.div`
.report-add-page {
	background: ${colors.TBA_ALMOST2_WHITE};
	margin-bottom: 50px;
	padding: 20px 0px 0;
	display: flex;
	flex-direction: column;

	.row { display: flex }

	.flex,
	.column {
		display: flex;
		flex-direction: column;
	}

	.top-section {
		width: 100%;
		justify-content: space-between;

		.visible {
			align-items: center;
			margin-top: 10px;
		}
	}

	.company-section,
	.text-section,
	.impact {
		margin: 10px 0;
		border-radius: 4px;
		border: 1px solid ${colors.TBA_LIGHT2_GREY};
		background: ${colors.WHITE};
		justify-content: space-between;
	}

	.company-section{
		padding: 20px;

		&.no-border {
			border: none;
			background: none;
			padding: 0;
		}

		.details {
			padding-right: 50px;
			font-size: 14px;

			.status {
				.select-wrapper { align-self: self-start}

				input {
					width: 200px;
					margin-bottom: 10px;
				}
			}
		}

		.contacts {
			font-size: 14px;      
			padding-top: 10px;
      .contact-email{
        padding-top: 10px;
      }
      @media (max-width: 634px) {
        .PhoneInput{
          width:88%;
        }
        input {
          width:86%;
        }
      }
			span, strong { text-align: right }
			strong { margin-bottom: 10px }
			label { margin-bottom: 20px; margin-top:20px; }

			.phone-input {
				input {
					margin-left: 0;
				}
			}
		}
	}

	.text-section {
		header {
			border-bottom: 1px solid ${colors.TBA_LIGHT2_GREY};
			padding: 15px 20px;
      h2{
        font-size: 16px;
      }
		}

		label { padding: 5px 0}

		main { padding: 20px }

		h2,p { margin: 0; }

		p { font-size: 14px }
	}

	.impact {
		padding: 20px 20px 0;

		&:empty {
			display: none;
		}

		.impact-item {
			width: 100%;
			border-radius: 4px;
			border: 1px solid ${colors.TBA_LIGHT2_GREY};
			border-bottom-color: ${colors.BLUE};
			border-bottom-width: 8px;
			margin-bottom: 20px;

			h2 {
				min-height: 48px;
				text-align: center;
				background: ${colors.TBA_LIGHTER_GREY_3};
				margin: 0;
				padding: 14px 0;
			}

			.text-editor {
				margin: 15px 20px;
			}
		}
	}
  
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid ${colors.TBA_DARKER_GREY};
    & .icon-visible {
      color: ${colors.TBA_DARKER_GREY};
      font-size: 18px;
      background: transparent;
      border: transparent;
      vertical-align: middle;
      margin-left: 10px;
      height: 21px;
      line-height: 22px;

      &.green {
        color: ${colors.GREEN};
      }
    }
    & .icon-hidden {
      color: ${colors.TBA_DARKER_GREY};
      font-size: 18px;
      background: transparent;
      border: transparent;
      vertical-align: middle;
      margin-left: 6px;
      height: 21px;
      line-height: 22px;

      &.green {
        color: ${colors.GREEN};
      }
    }
    & .icon-help {
      margin-left: 10px;
    }

    & .title-wrapper {
      display: flex;
      align-items: center;
      padding: 0;

      & .title {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      button,
      a {
        line-height: 10px;
        border: none;
      }
    }

    & .wrapper {
      &.expanded {
        width: 294px;
      }
    }

    & .toolbar {
      justify-content: space-between;

      & .toolbar-buttons {
        max-width: 330px;
        display: flex;
        align-items: center;
        & > * {
          margin-left: 10px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      & .tooltip-container {
        font-size: 18px;
        line-height: 22px;
        color: ${colors.TBA_DARKER_GREY};
        &.disabled {
          color: ${colors.TBA_LIGHT_GREY};
          pointer-events: none;
        }
      }
    }
  }

  .toolbar{
    align-self: end;
  }

	.highlight-boxes {
		.text-section {
			padding: 20px;
			width: 100%;

			label { margin-bottom: 20px }
		}
	}

	@media (min-width: 634px) {
		.flex { flex-direction: row }

		.top-section { align-items: center }

		.company-section {
			.contacts {
				&, input { width: 300px }
			}
		}

		.diagnostic-section { margin: 10px 0 }
	}

	@media (min-width: 914px) {
		.impact {
			justify-content: space-around;
			flex-direction: row;

			.impact-item {
				width: calc(100%/3.1);
				margin-right: 20px;

				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}

		.highlight-boxes {
			justify-content: space-between;
			flex-direction: row;

			.text-section {
				width: calc((100% - 40px)/3);
			}
		}
	}
}

`

export const MainBlock = styled.div`
display: flex;
padding: 15px 20px;
gap: 20px;
@media (min-width: 915px) {
  height: 800px;
}
@media (max-width: 1150px) {
  margin-top: 25px;
}
@media (min-width: 1300px) {
  height: 420px;
}
.text-area {
	height: 270px !important;
}
@media (max-width: 1300px) {
	flex-wrap: wrap;
> * {
	&:first-child {
		width: 100%
	}
}
}
`
export const TextEditorWrapperDiv = styled.div`
	width: 60%;
`

export const DivSpacer = styled.div`
	margin: 10px 0;
	border-radius: 4px;
	justify-content: space-between;
	display: inline-flex;
	width: 100%;
  @media (max-width: 634px) {
      display: flex;
      flex-wrap: wrap;
  }
  @media (max-width: 915px) {
      display: flex;
      flex-wrap: wrap;
  }
  @media (max-width: 1150px) {
    display: flex;
    flex-wrap: wrap;
}
`

export const BarChartColumn = styled.div`
	margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
    background: #ffffff;
	width: 49%;
  @media (max-width: 634px) {
    width: 100%;
  }
  @media (max-width: 915px) {
    width: 100%;
  }
  @media (max-width: 1150px) {
    width: 100%;
  }
	header {
		border-bottom: 1px solid #d2d2d2;
		padding: 15px 20px;
		display: inline-flex;
		justify-content: space-between;
		width: 100%;
    h2{
      font-size: 16px;
    }
	}
  .section-title{
    width: 80% !important;
    margin: 0;
    
  }
  @media (max-width: 634px) {
    .section-title{
      width: 80% !important;
      margin: 0;
      
    }
  }
  .custom-label{
    padding: 0;
  }
  .eui-title{ 
    width: 50% !important;
    @media (max-width: 634px) {
      width: 100% !important;
    }
  }
  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
    flex: 250px;
  }
  
`
export const BarChartLabelColumn = styled.label`
	display: inline-flex;
	flex-direction: column;
	font-weight: bold;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.26px;
	width: 45%;
`

export const LegendWrapper = styled.div`
	width: 100%;
	display: flex;
    flex-wrap: wrap;
	margin: 2%;
	gap: 15px 5px;
	padding: 8px;
	`
export const BoldText = styled.span`
	font-weight: bold;
`

export const BarChartBlock = styled.div`
	padding: 15px 20px;
	.text-area {
		height: 270px !important;
	}
`
export const BarChartContainer = styled.div`
width: 600px;
padding-right: 10px;
div[class*="legend-container"] {
  gap: 15px 25px;
  div[class*="legend-item"] {
    margin-left: 10px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    label[class*="legend-wrapper"] {
      white-space: nowrap;
    }
  }
}
`
export const DateRangeContainer = styled.div`
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 5px;
    .label-dateRange{
      margin-bottom: 5px;
      font-size: 13px;
    font-weight: 700;
    letter-spacing: .26px;
    line-height: 15px;
    }
    &.label-container{
      margin-left: 0px !important;
      margin-bottom: 10px;
    }
`