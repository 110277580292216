import BaseLinesContainer from './BaseLinesContainer'
import { BaseLinesContextProvider } from './BaseLinesContextProvider'

export const BaseLines = () => {
  return (
    <BaseLinesContextProvider>
      <BaseLinesContainer />
    </BaseLinesContextProvider>
  )
}
