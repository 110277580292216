import moment from 'moment-timezone'

// CONSTANTS
export const DATE_FORMAT = 'MM/DD/YYYY'
export const DATE_FORMAT_DASH = 'MM-DD-YYYY'
export const LONG_DATE_FORMAT = 'MMMM D, YYYY'
export const SHORT_DATE_FORMAT = 'MMM DD, YYYY'
export const TIME_FORMAT = 'HH:mm'
export const TIME_FORMAT_WITH_TZ = 'H:mm (z)'
export const TIME_FORMAT_TWELVE_HOUR = 'H:mm'
export const TIME_FORMAT_TWELVE_HOUR_1 = 'h:mm A'
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD'
export const BACKEND_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'
export const BACKEND_DATE_TIME_FORMAT_TZ = 'YYYY-MM-DDTHH:mm:ssZ'
export const DEFAULT_DATE = '00/00/00'
export const DEFAULT_TIME = '00:00'
export const TEXT_DATE_FORMAT = 'MMM D, YYYY'
export const TEXT_DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A'
export const TEXT_DATE_TIME_FORMAT_WITH_SECONDS = 'MMM D, YYYY, h:mm:ss A'
export const WHOLE_MONTH_FORMAT = 'MMM YYYY'
export const TEXT_DATE_FORMAT_WITH_TIMEZONE = 'MMM-D-YYYY hh:mm A zz'

export const removeTimeZoneFromDate = (date) => date?.slice(0, 19)

export function isoStringToUTC(date = '') {
  // Removing the timezone from date string
  return moment.utc(removeTimeZoneFromDate(date)) // Slicing the first 19 characters would prevent date to be invalid for 2021-01-28T00:00:00Z , 2021-02-08T12:59:00+05:30.
}

export function sliceDateOnly(date = '') {
  if (date === '') {
    return
  }
  return moment.utc(date.slice(0, 10))
}

export function utcTimestampToStringDate(timestamp) {
  return moment.utc(timestamp).format(BACKEND_DATE_FORMAT)
}

export function stringDateToUtcTimestamp(date = '') {
  return moment.utc(date, BACKEND_DATE_FORMAT).valueOf()
}

export function utcTimestampToDisplayDate(timestamp) {
  return moment.utc(timestamp).format(TEXT_DATE_FORMAT)
}

/**
 * Returns valid formatted moment date or null
 * @param {string|number|moment} date
 * @param {string} format
 * @return {string|null} - formatted date or null
 */
export const isValid = (date, format) =>
  moment(date).isValid() ? moment(date).format(format) : null

/**
 * Check if date ranges overlap
 * @param {Object[]} dateRanges - Array of date ranges
 * @param {Timestamp} dateRanges[].start - Start date
 * @param {Timestamp} dateRanges[].end - End date
 *
 * @returns {Object}
 */
export function overlap(dateRanges) {
  return dateRanges
    .sort((previous, current) => previous.start - current.start)
    .reduce(
      (result, current, index, arr) => {
        if (index === 0) {
          return result
        }

        // get the previous range
        const previous = arr[index - 1]

        // check for any overlap
        const previousEnd = previous.end
        const currentStart = current.start
        const overlap = previousEnd >= currentStart

        // store the result
        if (overlap) {
          // yes, there is overlap
          result.overlap = true
          // store the specific ranges that overlap
          result.ranges.push({
            previous,
            current
          })
        }

        return result
      },
      { overlap: false, ranges: [] }
    )
}

/**
 * Combine any overlapping date ranges
 * @param {Object[]} dateRanges - Array of date ranges
 *
 * @returns {Object[]}
 */
export function combineDateRanges(dateRanges = []) {
  if (dateRanges.length < 2) {
    return dateRanges
  }

  const combine = (dateArray) => {
    const combinedArray = []
    let changed = false
    for (let i = 0; i < dateArray.length - 1; i++) {
      if (dateArray[i].endDate >= dateArray[i + 1].startDate) {
        // If date ranges overlap, combine them
        changed = true
        dateArray[i].included = true
        dateArray[i + 1].included = true
        combinedArray.push({
          startDate: dateArray[i].startDate,
          endDate:
            dateArray[i].endDate >= dateArray[i + 1].endDate
              ? dateArray[i].endDate
              : dateArray[i + 1].endDate
        })
      } else {
        if (!dateArray[i].included) {
          // If date ranges don't overlap and current range hasn't already been included, add it
          combinedArray.push({
            startDate: dateArray[i].startDate,
            endDate: dateArray[i].endDate
          })
        }
        if (i === dateArray.length - 2) {
          // If this is the last comparison, include the last date range in the array
          combinedArray.push({
            startDate: dateArray[i + 1].startDate,
            endDate: dateArray[i + 1].endDate
          })
        }
      }
    }
    if (changed && combinedArray.length > 1) {
      return combine(combinedArray)
    } else if (changed) {
      return combinedArray
    } else {
      return dateArray
    }
  }

  dateRanges.sort((previous, current) => previous.startDate - current.startDate)
  return combine(dateRanges)
}
