import { useState, useCallback, useEffect } from 'react'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { OverviewSchema } from './Validations'
import { BaselineForm } from './BaseLineForm'
// import { BaselineTimeFrameAndExclusions } from "./BaselineTimeframeAndExclusions"
import { TabsWrapper } from './styles'
import Spinner from 'src/components/legacy/components/spinner/spinner'

// You might be thinking, why same component used for two tabs
// On every tab change, object picker is reinitialized, instead on avoiding the rereder,
// change same component used and change handled inside that component
// React compose and Reder Child props not useful in this
const tabsConfig = (mode, activeTab, formik, setIsTriggerValidation) => [
  {
    title: 'Overview',
    key: 'overview',
    component: (
      <BaselineForm formik={formik} mode={mode} activeTab={activeTab} setIsTriggerValidation={setIsTriggerValidation} />
    )
  },
  {
    title: 'Timeframe & Exclusions',
    key: 'timeFrameExclusions',
    component: (
      <BaselineForm formik={formik} mode={mode} activeTab={activeTab} />
    )
  }
]

export const BaselineFormTabs = ({ formik, mode, loading, setIsTriggerValidation }) => {
  const [activeTab, setActiveTab] = useState(0)

  const onSelectTab = useCallback((selectedTab) => {
    setActiveTab(selectedTab)
  }, [])

  return loading ? (
    <Spinner />
  ) : (
    <TabsWrapper>
      <Tabs
        defaultActiveTab={activeTab}
        activeTab={activeTab}
        onSelect={onSelectTab}
      >
        {tabsConfig(mode, activeTab, formik, setIsTriggerValidation).map((item, key) => {
          return (
            <Tab
              title={item.title}
              key={key}
              validationSchema={OverviewSchema()}
            >
              {item.component}
            </Tab>
          )
        })}
      </Tabs>
    </TabsWrapper>
  )
}
