import styled from 'styled-components'
/**
 * Method to customize the prop forwarding to styled components
 *
 * @export
 * @param {string} [component="div"]  A custom component name (ex: div, MyReactComponent, MyStyledComponent)
 * @param {*} [inProps=[]]  Array of Props that are not allowed to forward
 * @return {*} styled component
 */
export default function shouldForwardProp(component = 'div', inProps = []) {
  return styled(component).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !inProps.includes(prop) && defaultValidatorFn(prop)
  })
}
