import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import ContainerWrapper from 'src/components/Container'
import { PAGE_TITLE } from './constants'
import {
  PortfolioContainer,
  PortfolioHeader,
  EPTitle,
  Title,
  PortfolioContentWrapper,
  ChartHeaderWrapper,
  EnergyPerformanceHeaderTiles,
  PortfolioTicks,
  DataAvailableInfo
} from './styles'
import { allTypes, colorForType, titleForType } from './helpers'
import Comparator from './Comparator.jsx'
import { useQuery } from 'src/hooks/APIHooks'
import { getSearchParams } from '../../common/helperFunctions.js'
import ChartDateControls from 'src/components/chartDateControls/chartdatecontrols'
import ColorLegend from 'src/components/color-legend'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import { TranslateComponent } from 'src/common/translations'
import { GET_BUILDINGS_BY_ACCOUNT_ID, GET_BUILDING_BY_ID } from './graphql'
import PortfolioBuildings from './PortfolioBuildings'
import { formatCurrencyPrecision } from 'src/common/currency'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import translate from 'src/common/translations'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { usePortfolioScorecardContext } from './PortfolioScorecardContextProvider'
import isEqual from 'lodash/isEqual'

const PortfolioScorecardContainer = () => {
  const { width } = useBreakPoint()
  const [selectedUnit, setSelectedUnit] = useState({ key: '$', value: '$' })
  const [totalEnergyCost, setTotalEnergyCost] = useState(0)
  const [theHighestTotal, setTheHighestTotal] = useState(0)
  const costInfoRef = useRef<any>({})
  const [buildingsList, setBuildingsList] = useState([])
  const [buildingsOrder, setBuildingsOrder] = useState({})
  const [disabledTypes, setDisabledTypes] = useState([])
  const [searchParams] = useSearchParams()

  const { setMeterTotalAllbuilding } = usePortfolioScorecardContext() || {}

  const optionsTranslated = {
    totalEnergy: translate('Total Energy Cost'),
    data: translate('Data Available')
  }

  // Currently we are kind of hard coding date available as per GEN4
  // We may fully remove this or need modifications
  const [dataAvailabilityDates, setDataAvailablityDates] = useState({
    startDate: moment().subtract(12, 'months').format('MMM YYYY'),
    endDate: moment().subtract(1, 'month').format('MMM YYYY')
  })

  const { organizationId, buildingId } = getSearchParams()
  const [chartDates, setChartDates] = useState({
    chartStartDate: moment()
      .subtract(1, 'year')
      .startOf('month')
      .format(BACKEND_DATE_FORMAT),
    chartEndDate: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format(BACKEND_DATE_FORMAT),
    dateError: false,
    activeRange: '1Y'
  })

  const {
    data: accountData,
    refetch: refetchAccount,
    loading: buildingsLoading
  } = useQuery({
    query: GET_BUILDINGS_BY_ACCOUNT_ID,
    dataPath: 'data.listBuildingsByAccount.items',
    disableInitialLoad: true
  })

  const {
    data: buildingDetails,
    refetch: fetchBuildingDetails,
    loading: buildingDetailsLoading
  } = useQuery({
    query: GET_BUILDING_BY_ID,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.getBuilding'
  })

  const setBuilings = (list = []) => {
    const cost = list?.reduce((av, building) => {
      if (building?.id && !av[building?.id]) {
        av[building?.key] = 0
      }
      return av
    }, {})

    costInfoRef.current = cost
    setBuildingsList(list)
  }

  const resetCostInfo = () => (costInfoRef.current = {})

  useEffect(() => {
    resetCostInfo()
    setTotalEnergyCost(0)
    setMeterTotalAllbuilding([])
    setBuilings([])

    if (buildingId) {
      fetchBuildingDetails({ id: buildingId })
    } else if (organizationId) {
      refetchAccount({ id: organizationId })
    }
  }, [organizationId, buildingId, searchParams])

  useEffect(() => {
    const buidlings = !Array.isArray(accountData)
      ? []
      : accountData
          ?.sort((a, b) => a?.value?.localeCompare(b?.value))
          .slice()
          .map((building) => {
            return {
              ...building,
              totalEnergyCost: 0
            }
          })

    setBuilings(buidlings)
  }, [accountData])

  useEffect(() => {
    setBuilings(buildingDetails ? [buildingDetails] : [])
  }, [buildingDetails])

  const onChartControlUpdate = ({
    startDate,
    endDate,
    endError,
    startError,
    activeRange
  }) => {
    const nextChartDate = {
      chartStartDate: startDate,
      chartEndDate: endDate,
      dateError: startError || endError,
      activeRange
    }

    // Don't update when date is equal to previous one. It reduces multiple renders which causes race conditions
    if (isEqual(chartDates, nextChartDate)) {
      return
    }

    setTotalEnergyCost(0)
    setChartDates(nextChartDate)
  }

  const onUnitChange = (unit) => {
    // Don't update when unit is equal to previous one. It reduces multiple renders which causes race conditions
    if (isEqual(selectedUnit, { key: unit, value: unit })) {
      return
    }

    setSelectedUnit({ key: unit, value: unit })
    trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CHANGE_PORTFOLIO_UNITS, {
      'selected unit': unit
    })
  }

  const setBuildingListByUsage = (totalEnergyCost, buildingId) => {
    try {
      const indexOfBuilding = buildingsList.findIndex(
        (building) => building?.key === buildingId
      )

      if (indexOfBuilding === -1) return

      buildingsList[indexOfBuilding].totalEnergyCost = totalEnergyCost

      const sortedBuilidgList = buildingsList
        .slice()
        .sort((a, b) => b?.totalEnergyCost - a?.totalEnergyCost)
        .reduce((av, building, idx) => {
          av[building.key] = idx + 1
          return av
        }, {})

      setBuildingsOrder(sortedBuilidgList || {})
    } catch (e) {
      console.log(e)
    }
  }

  const updateTotalCost = (total, buildingId) => {
    const nextCost = { ...costInfoRef.current }

    const value = +total

    nextCost[buildingId] = value

    const { totalCost = 0, values = [] }: any = Object.values(nextCost).reduce(
      (av: any, value: number) => {
        av.totalCost += value
        av.values.push(value)
        return av
      },
      { totalCost: 0, values: [] }
    )

    setTotalEnergyCost(totalCost ? +parseFloat(totalCost) : 0)

    setTheHighestTotal(Math.max(...values))

    setBuildingListByUsage(total, buildingId)

    costInfoRef.current = nextCost
  }

  const toggleDisabledTypes = (disabledTypes) => {
    setDisabledTypes(disabledTypes)
  }

  return (
    <ContainerWrapper
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding="0"
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        border="none"
      >
        <>
          <EPTitle>
            <Title>
              <TranslateComponent>{PAGE_TITLE}</TranslateComponent>
            </Title>
          </EPTitle>
          <PortfolioContainer>
            <PortfolioHeader>
              <div className="energyPerformance_header_actions">
                <div
                  data-testid="energyPerformance_header_actions_list"
                  className="energyPerformance_header_actions_list"
                >
                  <Comparator
                    testName="porfolio-score-card-unit"
                    labelText="Units:"
                    comparators={[
                      { key: '$', value: '$' },
                      { key: '$/ft²', value: '$/ft²' },
                      { key: '$/m²', value: '$/m²' }
                    ]}
                    onChange={onUnitChange}
                    selected={selectedUnit}
                  />
                </div>
              </div>
              <EnergyPerformanceHeaderTiles>
                <div
                  data-testid="portfolio-score-value"
                  className="chartTiles_tile"
                >
                  <div className="chartTiles_tile_heading">
                    {optionsTranslated.totalEnergy}
                  </div>
                  <div className="chartTiles_tile_reading">
                    <span>
                      $
                      {totalEnergyCost
                        ? formatCurrencyPrecision(
                            totalEnergyCost
                          ).toLocaleString()
                        : 0}
                    </span>
                  </div>
                </div>
              </EnergyPerformanceHeaderTiles>
            </PortfolioHeader>
            <PortfolioContentWrapper>
              <ChartHeaderWrapper>
                <ChartDateControls
                  // @ts-ignore
                  startDate={chartDates?.chartStartDate}
                  testName="portfolio-score"
                  endDate={chartDates?.chartEndDate}
                  activeRange={chartDates?.activeRange}
                  onUpdate={onChartControlUpdate}
                  endDisableAfterGivenDate={moment().format(
                    BACKEND_DATE_FORMAT
                  )}
                  startDisableAfterGivenDate={moment().format(
                    BACKEND_DATE_FORMAT
                  )}
                  rangesList={['1M', '3M', '6M', 'YTD', '1Y']}
                  entity="energy-cost"
                />
                <DataAvailableInfo>
                  <span>
                    <strong>{optionsTranslated.data}:</strong>
                  </span>
                  <div>
                    <span>{`${dataAvailabilityDates?.startDate} - ${dataAvailabilityDates?.endDate}`}</span>
                  </div>
                </DataAvailableInfo>
              </ChartHeaderWrapper>
              {allTypes &&
                buildingsList?.length > 10 &&
                Boolean(theHighestTotal) && (
                  <div
                    className="legendContainer"
                    style={{ marginBottom: '10px' }}
                  >
                    <ColorLegend
                      onToggle={toggleDisabledTypes}
                      items={allTypes.map((t) => ({
                        key: t,
                        color: colorForType(t),
                        label: titleForType(t)
                      }))}
                      disabledItems={disabledTypes}
                    />
                  </div>
                )}
              <TickMarks
                highestTotal={theHighestTotal}
                selectedUnitKey={selectedUnit.key}
              />
              <>
                {buildingsLoading || buildingDetailsLoading ? (
                  <Spinner />
                ) : (
                  <PortfolioBuildings
                    chartDates={chartDates}
                    buildingsList={buildingsList}
                    buildingsOrder={buildingsOrder}
                    updateTotalCost={updateTotalCost}
                    selectedUnit={selectedUnit}
                    theHighestTotal={theHighestTotal}
                    disabledTypes={disabledTypes}
                  />
                )}
                {allTypes && (
                  <div
                    className="legendContainer"
                    style={{ margin: '15px auto 0 auto' }}
                  >
                    <ColorLegend
                      onToggle={toggleDisabledTypes}
                      items={allTypes.map((t) => ({
                        key: t,
                        color: colorForType(t),
                        label: titleForType(t)
                      }))}
                      disabledItems={disabledTypes}
                    />
                  </div>
                )}
              </>
            </PortfolioContentWrapper>
          </PortfolioContainer>
        </>
      </Content>
    </ContainerWrapper>
  )
}

function TickMarks({ highestTotal = 0, selectedUnitKey = '' }) {
  const tickInterval = highestTotal / 10

  const allTicks = [0]

  for (let i = 1; i < 10; ++i) {
    allTicks.push(formatCurrencyPrecision(tickInterval * i))
  }
  const suffixUnits = selectedUnitKey.replace('$', '')

  return (
    highestTotal > 0 && (
      <PortfolioTicks>
        {allTicks.map((tick, index) => (
          <div className="tick" key={tick + index}>
            ${tick.toLocaleString()}
            {suffixUnits}
          </div>
        ))}
        <div className="tickSpacer">
          ${formatCurrencyPrecision(highestTotal)}
          {suffixUnits}
        </div>
      </PortfolioTicks>
    )
  )
}

export default PortfolioScorecardContainer
