import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import Modal from '../../../../../components/legacy/components/modal/modal'
import { SEARCH_AGGREGATE } from '../../../../../pages/buildingSetup/graphql/queries'
import {
  ENERGY_METRIC_BUILDER_CONFIGS,
  TIME_PERIODS,
  COMPARED_TO,
  DEFAULT_TIME_PERIOD,
  DEFAULT_COMPARED_TO,
  validationSchemaKeyMetricConfigurator
} from './helpers'
import { ErrorText, SelectWrapper, KpiBuilderWrapper } from '../../../../../pages/dashboards/styles'
import { NewStyles } from '../../../../../NewStyles'
import { useQuery } from '../../../../../hooks/APIHooks'
import Select from '../../../../../components/legacy/components/select/select'
import Selectrix from '../../../../../denali-ui/components/Selectrix'
import ToggleButtonHorizontal from '../../../../../components/legacy/components/toggle-button-horizontal'
import { useTranslation } from 'react-i18next'
import { SEARCH_BUILDINGS_BY_ACCOUNT_ID } from '../../../../../pages/documentsAndReports/graphql.ts'
import KpiEnergyMetricBuilder from './KpiEnergyMetricBuilder'
import { clone } from '../../../../../components/legacy/common/helpers'
import { GET_BUILDING_TOTAL_METER_EQUIPMENT } from "../../../../../pages/equipmentSetup/graphql/queries";
import { stringToBoolean } from '../../../../../utils/commonMethods'
import { GET_BUILDING_DETAILS } from "../graphql";
import translate, { TranslateComponent } from '../../../../../common/translations.tsx'

const KeyMetricConfigurator = ({
  setKeyMetricConfig,
  dashboardValues,
  handleDashboardUpdate
}) => {
  const [t] = useTranslation()
  const [buildings, setBuildings] = useState([])
	const [buildingTotalVirtualMeterId, setBuildingTotalVirtualMeterId] = useState(null)
	const [isInitialEditMode, setIsInitialEditMode] = useState(true)
  const initialValues = {
    isKeyMetric: stringToBoolean(dashboardValues?.isKeyMetric),
    organization: '',
    organizationName: '',
    buildingName: '',
    building: '',
    timePeriod: DEFAULT_TIME_PERIOD,
    comparedTo: DEFAULT_COMPARED_TO,
    energyMetricBuilderConfigs: clone(ENERGY_METRIC_BUILDER_CONFIGS)
  }
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchemaKeyMetricConfigurator,
    onSubmit: (values) => {
      handleDashboardUpdate(values)
    }
  })
  const { values, errors, touched, setFieldValue, setValues } = formik
  const { data: buildingsSearchList, refetch: refetchSearchBuildingsList } =
    useQuery({
      query: SEARCH_BUILDINGS_BY_ACCOUNT_ID,
      disableInitialLoad: true,
      dataPath: 'data.searchBuildings.items',
      onSuccess: (d) => {
        if (d?.length) {
          setFieldValue('organizationName', d[0].accountName)
        }
      }
    })
  const { refetch: refetchBuildingTotalMeterEquipment } = useQuery({
    query: GET_BUILDING_TOTAL_METER_EQUIPMENT,
    variables: {
      body: JSON.stringify({ bId: values?.building })
    },
    disableInitialLoad: true
  })
  const {
		data: buildingDetails,
		refetch: getBuildingDetails,
		loading: loadingBuildingDetails
	  } = useQuery({
		query: GET_BUILDING_DETAILS,
		variables: { id: dashboardValues?.buildingId },
		disableInitialLoad: true,
		errorPolicy: 'all',
		dataPath: 'data.getBuilding'
	})
  const timePeriodsToOptionsArray = TIME_PERIODS.map((key) => ({
    key,
    value: t(`kpi>${key}`, { nsSeparator: '>' })
  }))
  const comparedToOptions = COMPARED_TO[values?.timePeriod]
  const comparedToOptionsArray = comparedToOptions.map((key) => ({
    key,
    value: t(`kpi>${key}`, { nsSeparator: '>' })
  }))

  const onOrganizationChange = (e, child) => {
    if (e.target.value !== values.organization) {
      setBuildingTotalVirtualMeterId(null)
      setBuildings([])
      setValues((value)=>({
        ...value,
        organization: e.target.value,
        organizationName: child.props.children,
        building: '',
        buildingName: ''
      }))
      setIsInitialEditMode(false)
    }
  }

  const onLocationChange = (e,key, child) => {
    if (e.target.value !== values.building) {
      const buildingId = buildings?.filter(f=>f.value?.toLowerCase() === e?.target?.value?.toLowerCase())?.[0]?.key
      setValues((value)=>({
        ...value,
        building:buildingId,
        buildingName: e.target.value
      }))
    }
  }

  const getOrgsQueryResponseMap = (response, organizationId) => {
    const idListIndex = response.findIndex((e) => e.name === 'accountIds')
    const nameTermsIndex = response.findIndex((e) => e.name === 'accountTerms')
    const results =
      response &&
      idListIndex !== -1 &&
      nameTermsIndex !== -1 &&
      response[nameTermsIndex].result.buckets.map((e, i) => ({
        searchableValue: e.key,
        value: response[idListIndex].result.buckets[i].key,
        children: e.key,
        selected: response[idListIndex].result.buckets[i].key === organizationId
      }))

    return results.sort((a, b) => {
      const nameA = a.searchableValue.toUpperCase()
      const nameB = b.searchableValue.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
  }

  useEffect(() => {
    if (values.organization) {
      refetchSearchBuildingsList({
        filter: { accountId: { eq: values.organization } },
        limit: 1000
      })
    }
  }, [values.organization])  

  useEffect(() => {
    if (Array.isArray(buildingsSearchList)) {
      const list = buildingsSearchList?.map((building) => ({
        key: building?.key,
        value: building?.value,
        searchableValue: building?.value
      }))
      setBuildings(list)
      if(isInitialEditMode){
        setValues((value) => ({
          ...value,
          buildingName: buildingDetails?.name,
          building: buildingDetails?.buildingId
        }))
      }
    }
  }, [buildingsSearchList])

  const toggleKpiStrip = () => {
    setFieldValue('isKeyMetric', !values?.isKeyMetric)
  }

  const updateFieldValue = (fieldName, value) => {
    setFieldValue(fieldName, value)
  }

  const handleClose = () => {
    setKeyMetricConfig(false)
    setIsInitialEditMode(false)
  }

  const handleKpiEnergyMetricBuilderChange = (newMetric, index) => {
    const energyMetricBuilderConfigs = clone(values.energyMetricBuilderConfigs)
    energyMetricBuilderConfigs[index] = {
      ...energyMetricBuilderConfigs[index],
      ...newMetric
    }
    setFieldValue('energyMetricBuilderConfigs', energyMetricBuilderConfigs)
  }

  const getBuildingTotalVirtualEquipmentId = async () =>{
    const { data: { getBuildingTotalMeterEquipment } } = await refetchBuildingTotalMeterEquipment()
    const parseData = JSON.parse(getBuildingTotalMeterEquipment)
    if (parseData?.body?.equipments?.length > 0) {
      setBuildingTotalVirtualMeterId(parseData.body.equipments[0].id)
    }else{
      setBuildingTotalVirtualMeterId(null)
    }
  }
  useEffect(() => {
    (async () => {
      const isEnabled = stringToBoolean(dashboardValues?.isKeyMetric)
      if (isEnabled) {
        if (dashboardValues.buildingId) {
          await getBuildingDetails().then((res)=>{
            setValues((value)=>({
              ...value,
              organization: res?.accountId,
              organizationName: res?.accountName,
              timePeriod: dashboardValues?.keyMetricTimePeriod || DEFAULT_TIME_PERIOD,
              comparedTo: dashboardValues?.keyMetricComparedTo || DEFAULT_COMPARED_TO,
              energyMetricBuilderConfigs: dashboardValues?.keyMetricsStripList || clone(ENERGY_METRIC_BUILDER_CONFIGS)
            }))
          })
          
        }
      }
    })()
  }, [dashboardValues.buildingId, dashboardValues.isKeyMetric])

  useEffect(() => {
    (async () => {
      if (values?.building) {
        await getBuildingTotalVirtualEquipmentId()
      }
    })()
  }, [values.building])
  
  return (
    <Modal
      heading={translate("Dashboard Key Metrics Configurator")}
      handleClose={handleClose}
      buttons={[
        {
          type: 'confirm',
          text: <TranslateComponent>Save</TranslateComponent>,
          handleClick: formik.handleSubmit
          // disabled: !formik.isValid
        },
        { type: 'cancel', text: <TranslateComponent>Cancel</TranslateComponent>, handleClick: handleClose }
      ]}
      gray={true}
      formHasRequiredFields={true}
      loading={false}
      className="kpi-builder layout-3-columns additional"
    >
      <form>
        <NewStyles>
          <KpiBuilderWrapper>
            <div className="kpi-header">
              <span className="kpi-header-title">
                <TranslateComponent>Display Key Metrics</TranslateComponent>:
              </span>
              <ToggleButtonHorizontal
                id="toggle-kpi-strip"
                checked={values?.isKeyMetric}
                onChange={toggleKpiStrip}
              />
              <span className="kpi-header-subtitle">
                <TranslateComponent>Can be turned back on in the dashboard settings</TranslateComponent>
              </span>
            </div>
            <div className="kpi-selectors">
              <SelectWrapper>
                <label
                  className={`${errors?.organization ? 'error-text' : ''}`}
                >{<TranslateComponent>Organization</TranslateComponent>}*</label>
                <Selectrix
                  onChange={(value, child) =>
                    onOrganizationChange(value, child)
                  }
                  query={SEARCH_AGGREGATE}
                  fullWidth={true}
                  multiple={false}
                  value={values?.organizationName}
                  dataPath={'data.searchBuildings.aggregateItems'}
                  containerWidth={true}
                  getQueryResponseMap={(values) =>
                    getOrgsQueryResponseMap(values, values.organization)
                  }
                  selectClassName={'orgSelectInput'}
                  searchPlaceHolder={translate("Type to find an organization...")}
                  testName={`consultations_organizationdetails_addorganization`}
                  querySearchField={['accountName']}
                  aggregates={[
                    {
                      field: 'accountName',
                      type: 'terms',
                      name: 'accountTerms'
                    },
                    { field: 'accountId', type: 'terms', name: 'accountIds' }
                  ]}
                  showCutomOption={true}
                  showNoResultsMessage={false}
                  disabled={false}
                />
                {errors?.organization && (
                  <ErrorText><TranslateComponent>{errors?.organization}</TranslateComponent></ErrorText>
                )}
              </SelectWrapper>
              <SelectWrapper isMarginLeftAdjust={true}>
                <label
                  className={`${errors?.building ? 'error-text' : ''}`}
                >{<TranslateComponent>Building</TranslateComponent>}*</label>
                <Selectrix
                  onChange={(value, child) =>
                    onLocationChange(value, child)
                  }
                  options={buildings || []}
                  fullWidth={true}
                  multiple={false}
                  value={values?.buildingName}
                  containerWidth={true}
                  selectClassName={'orgSelectInput'}
                  searchPlaceHolder={translate("Type to find an building...")}
                  testName={`key-metric-building`}
                  placeHolder={translate("- Select One -")}
                  querySearchField={['buildingName']}
                  showCutomOption={true}
                  showNoResultsMessage={false}
                  disabled={false}
                />
                {errors?.building && <ErrorText><TranslateComponent>{errors?.building}</TranslateComponent></ErrorText>}
              </SelectWrapper>
              <SelectWrapper>
                <span className="custom-label"><TranslateComponent>Time Period</TranslateComponent>*</span>
                <Select
                  selectedItem={t(`kpi>${values?.timePeriod}`, {
                    nsSeparator: '>'
                  })}
                  options={timePeriodsToOptionsArray}
                  onChange={(value) => {
                    updateFieldValue('timePeriod', value)
                    updateFieldValue('comparedTo', ':none')
                  }}
                />
              </SelectWrapper>
              <SelectWrapper>
                <span className="custom-label">
                  <TranslateComponent>Compared To</TranslateComponent>*
                </span>
                <Select
                  selectedItem={t(`kpi>${values?.comparedTo}`, {
                    nsSeparator: '>'
                  })}
                  options={comparedToOptionsArray}
                  onChange={(value) => updateFieldValue('comparedTo', value)}
                />
                <span className="custom-hint">
                  <TranslateComponent>Only used for % change metrics</TranslateComponent>
                </span>
              </SelectWrapper>
            </div>
            <div className="kpi-strip">
              <div className="list-container placeholder">
                {values?.energyMetricBuilderConfigs.map((el, i) => (
                  <div className="cell" key={i}>
                    <KpiEnergyMetricBuilder
                      metric={el}
                      values={values}
                      totalVirtualMeterId={buildingTotalVirtualMeterId}
                      handleChange={(newMetric) =>
                        handleKpiEnergyMetricBuilderChange(newMetric, i)
                      }
                      handleClose={handleClose}
                      stripNumber={i}
                    />
                  </div>
                ))}
              </div>
            </div>
          </KpiBuilderWrapper>
        </NewStyles>
      </form>
    </Modal>
  )
}

export default KeyMetricConfigurator
