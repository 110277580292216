import { ticksTextPadding } from './Ticks/text'
import { ticksPadding } from './Ticks/ticks'
import { axisNamePadding } from './Name/name'
import { symbolPadding } from './Symbol/symbol'

export const paddingCalculator = (obj: any = {}) => {
  const scaleType = obj?.scaleType

  // Padding required for ticks text
  const ticksTextObject = ticksTextPadding(obj, scaleType)
  
  // Padding required for ticks
  const ticksObject = ticksPadding(obj)

  // Padding required for axis name
  const axisNameObject = axisNamePadding(obj?.axisName)

  // Padding required for Symbol
  const symbolObj = symbolPadding(obj?.symbol);

  return {
    ...ticksTextObject,
    ...ticksObject,
    ...axisNameObject,
    ...symbolObj
  }
}
