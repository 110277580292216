import React, { useCallback, useState } from "react"
import { func } from "prop-types"
import { Divider, Icon, StyledSelectButton } from "./styles"
import { TranslateComponent } from 'src/common/translations'

const ICON_MAP = {
	line: "linewidget",
	scatter: "scatterplot",
	polar: "orbwidget",
	bar: "bar3widget",
}

const SelectButton = ({ type, selectType = () => null, selected = false }) => (
	<StyledSelectButton onClick={selectType} selected={selected}>
		<Icon className={`icon icon-${ICON_MAP[type]}`} />
	</StyledSelectButton>
)

const ChartTypeSelector = ({ viewConfig, setViewConfig, types = ["line"], series = [], onChangeChartViewType }) => {
	const [selectedType, setSelectedType] = useState(viewConfig?.series?.[series[0]]?.type ?? types[0])
	const selectType = useCallback(type => {
		if (onChangeChartViewType && typeof onChangeChartViewType === "function") {
			onChangeChartViewType(type)
		}
		setSelectedType(type)
		setViewConfig(v => ({
			...v,
			series: {
				...(v.series ?? {}),
				...series.reduce((moddedSeries, seriesName) => ({
					...moddedSeries,
					[seriesName]: {
						...(v.series?.[seriesName] ?? {}),
						type: type === "line" ? "scatter" : type,
					},
				}), {}),
			},
		})
		)
	}, [series, onChangeChartViewType, setViewConfig])

	return (
		<div> <TranslateComponent>View</TranslateComponent> 
			<Divider />
			{types.map(t => (
				<SelectButton key={t} type={t} selected={t === selectedType} selectType={() => selectType(t)} />
			))}
		</div>
	)
}

ChartTypeSelector.propTypes = {
	onChangeChartViewType: func
}

export default ChartTypeSelector
