/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { format } from 'd3'
import Axis from '../../../../../../chart-library/CommonComponents/AxisNative'
import Grid from '../../../../../../chart-library/CommonComponents/Grids/Grid'
import { useConfig } from './useConfig'

export default function Axes() {
  const {
    isInitialized,
    canvas,
    AxisDiM,
    xAxis,
    xScale,
    yAxis,
    yScale,
    axisUpdatedAt,
    scaleUpdatedAt
  } = useConfig()

  if (!isInitialized) {
    return null
  }

  return (
    <>
      <Axis
        dimention={AxisDiM.x}
        axisPosition={xAxis.type}
        axisScale={xScale.fn}
        xPosition={xAxis.x}
        yPosition={xAxis.y}
        lastUpdatedAt={axisUpdatedAt + scaleUpdatedAt}
      />
      <Axis
        hideAxisLine
        hideAxisTickLine
        tickFormat={format('$,')}
        dimention={AxisDiM.y}
        axisPosition={yAxis.type}
        tickCount={7}
        axisScale={yScale.fn}
        xPosition={yAxis.x}
        yPosition={yAxis.y}
        lastUpdatedAt={axisUpdatedAt + scaleUpdatedAt}
      />
      <Grid
        scale={yScale.fn}
        width={canvas.width}
        includeAxis={true}
        tickCount={7}
        opacity={0.2}
        strokeWidth={0.5}
        type={'y'}
        transform={`translate(${canvas.x} ${canvas.y})`}
      />
    </>
  )
}
