import PropTypes from "prop-types"
import _get from "lodash/get"
import { Text } from "@react-pdf/renderer"
import colors from 'src/components/layouts/colors.json'
import { KPI_SETTINGS } from "../../../../../components/legacy/components/kpi/kpi"
import { FIELD_TYPES } from "../../../../../components/legacy/common/pdf"
import PDFTable from "../../../../../components/print-components/pdf-table"
import PDFBadge from "src/components/print-components/pdf-badge"
import PDFBlockWrapper from "../pdf-block-wrapper"
import { formatCurrency } from "src/components/legacy/common/currency"
import { withTranslation } from "react-i18next"


const headings = t => [
	{
		heading: "opportunities:Opportunity",
		field: "name",
		fieldType: FIELD_TYPES.STRING,
		width: "16%",
	},
	{
		heading: "opportunities:ROI",
		field: "settings.costSavings.lifecycle",
		fieldType: FIELD_TYPES.COMPONENT,
		width: "16%",
		component: (heading, columns) => {
			const fieldValue = _get(columns, "settings.costSavings.lifecycle.value", null)
			const fieldTag = _get(columns, "settings.costSavings.lifecycle.tag", null)

			return <Text>{fieldValue > 0 ? `${fieldValue} ${fieldTag}` : ""}</Text>
		}
	},
	{
		heading: "opportunities:Price",
		canSort: false,
		field: "settings.costSavings.costToImplement.value",
		fieldType: FIELD_TYPES.COMPONENT,
		customClasses: "price",
		width: "16%",
		component: (heading, columns) => {
			const fieldValue = _get(columns, heading.field, null)
			const showCustomerPrice = _get(columns, "settings.costSavings.costToImplement.enabled", false)
			const value = !showCustomerPrice ? t("opportunities:NotEstimated") : formatCurrency(fieldValue)
			return <Text>{value}</Text>
		}
	},
	{
		heading: "components:kpi>Impact",
		canSort: false,
		field: "settings.kpi",
		fieldType: FIELD_TYPES.COMPONENT,
		customClasses: "impact",
		width: "52%",
		component: (heading, columns) => {
			const fieldValue = _get(columns, heading.field, []) || []

			return fieldValue
				.filter(km => km.value)
				.map(({ name, custom }, index) => {
					const { name: kpiName, iconChar, color } = KPI_SETTINGS(t).get(name) || {}

					return <PDFBadge
						key={index + name}
						text={!custom ? kpiName.toUpperCase() : name.toUpperCase().slice(0, 30)}
						icon={!custom ? iconChar : null}
						color={!custom ? color : colors.CUSTOM}
					/>
				})
		}
	},
]

const PdfWhereWeGoFromHereTable = ({ columns = [], title, visible, t }) => {
	if (!columns.length || !visible) {
		return null
	}
	return <PDFBlockWrapper title={title || "WhereWeGoFromHere"}>
		<PDFTable
			columns={columns}
			headings={headings(t)}
			// listName="opportunities:Finding"
			// pluralListName="opportunities:Findings"
			showHeader={false}
		/>
	</PDFBlockWrapper>
}

PdfWhereWeGoFromHereTable.propTypes = {
	title: PropTypes.string,
	columns: PropTypes.array,
	visible: PropTypes.bool
}

export default withTranslation()(PdfWhereWeGoFromHereTable)

