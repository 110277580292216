
import {  useState } from 'react'
import { UploadContainer } from "./styles"
import AttachedFiles from 'src/components/multi-file-upload-with-table/attached-files.sc'
import colors from 'src/components/legacy/common/colors.json'
import { useTranslation } from 'react-i18next'
import Label from 'src/components/legacy/components/label/label'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { convertSizeToUnits } from 'src/components/legacy/components/file-select-sc/file-select.sc.jsx'
import Icon from 'src/denali-ui/components/Icon/index'
import { GET_PRE_SIGNED_URL } from '../queries'
import { useMutation } from 'src/hooks/APIHooks'
import { saveAs } from 'file-saver'


const AttachFiles = ({
	organizationId,
	onDeleteAttachment,
	setFieldValue,
	values,
	mode,
	proposalFileTypes
}:any) => {

	const [t] = useTranslation()
    const [fileData, updateFileData] = useState([])
	const [errors, setErrors] = useState({})

	const searchParams = getSearchParams()
  	const buildingId = searchParams?.buildingId

	const resetStates = () => {
		updateFileData([])
		//setFileName(null)
		setErrors({})
	}


    // const onRemoveFile = () => {
	// 	resetStates()
	// }


	const { onSubmit: getPreSignedUrlMutation } = useMutation({
		query: GET_PRE_SIGNED_URL
	  })

	  const downloadFileFunc = async (name) => {
		try {
		  return await getPreSignedUrlMutation({
			input: `{"action": "DOWNLOAD", "key": "${name}", "buildingId": "${organizationId}" }`
		  }).then(async (res) => {
			const requestOptions: RequestInit = {
			  method: 'GET',
			  redirect: 'follow',
			  headers: { 'Content-Type': '' }
			}
			return await fetch(
			  JSON.parse(res.data.getPreSignedUrl).body,
			  requestOptions
			)
			  .then(async (response) => {
				const responseBlob = await response.blob()
				return await saveAs(responseBlob, name)
			  })
			  .catch((error) => console.log('error', error))
		  })
		} catch (error) {}
	  }
	
	

	const addFileFunc = async (e) => {
		return await getPreSignedUrlMutation({
		  input: `{"action": "UPLOAD", "key": "${e.name}", "buildingId": "${organizationId}" }`
		}).then(async (res) => {
		  const requestOptions: RequestInit = {
			method: 'PUT',
			body: e,
			redirect: 'follow',
			headers: { 'Content-Type': '' }
		  }
		  return await fetch(
			JSON.parse(res.data.getPreSignedUrl).body,
			requestOptions
		  ).catch((error) => console.log('error', error))
		})
	  }


	const onChangeUploadStatus = (newFiles) => {
		const attachments = [...values.attachments]
		for (let i = 0; i < newFiles.length; i++) {
		  const { file, loading, error } = newFiles[i]
		  if (attachments.findIndex((a) => a.title === file.title) !== -1) {
			const index = attachments.findIndex((a) => a.title === file.title)
			attachments[index] = {
			  ...file,
			  id: attachments[index].id,
			  timestamp: new Date().valueOf(),
			  buildingId: buildingId,
			  fileName: file.name,
			  title: file.title,
			  filePath: `${buildingId}/${file.name}`,
			//   fileSize: file?.size ? convertSizeToUnits(file?.size) : '',
			fileSize:file?.size,
			  type: file.type,
			  isNew: true,
			  loading,
			  ...(error && { error: String(error) }),
			  customClasses: loading ? 'loading' : error ? 'error' : ''
			}
		  } else {
			attachments.push({
			  ...file,
			  timestamp: new Date().valueOf(),
			  buildingId: buildingId,
			  fileName: file.name,
			  title: file.title,
			  filePath: `${buildingId}/${file.name}`,
			  fileSize: file.size,
			  type: file.type,
			  isNew: true,
			  loading,
			  ...(error && { error: String(error) }),
			  customClasses: loading ? 'loading' : error ? 'error' : ''
			})
		  }
		}
	
		setFieldValue('attachments', [...attachments])
	  }

	  const onDescriptionChange = (e) => {
		setFieldValue("attachments",
			values.attachments.map(a => ({
				...a,
				...(a.fileName === e.fileName ? {description:e.value} : "")
			}))
			)
	  }

    return (
		<div>
					<UploadContainer>
							<div className="attached-files">
								<Label
									text={t('Attach Files')} 
								/>
								<AttachedFiles
								proposalFileTypes={proposalFileTypes}
								mode ={mode}
								allowMultipleFiles={true}
									attachments={values?.attachments?.map((item) => ({
									...item,
									isEditable: true,	
									description: item.description,
									fileId: item?.id,
									fileSize: item?.fileSize
									}))}
									onChangeUploadStatus={(files) => {
											onChangeUploadStatus(files)
									}}
									onDeleteAttachment={(id, name) => onDeleteAttachment(id, name)}
									onDescriptionChange={(e) => onDescriptionChange(e)}
									addFile={(e) => addFileFunc(e)}
									downloadFileFunc={(e) => downloadFileFunc(e)}
									showOnlyAcceptableFileDesc={true}
									fieldsToDisplay={['fileName', 'fileSize', 'description']}
								/>
								
								{/* {errors?.attachments && (
									<p className="error">{t('reports:AttachmentEmpty')}</p>
								)} */}
							</div>
					</UploadContainer>
		</div>
	)
}


export default AttachFiles