import { useMemo } from "react"
import { useDashboardContext } from "./CreateDashBoardProvider"
import { CREATE_DASHBOARD_SUCCESS, UPDATE_DASHBOARD_SUCCESS } from "./constant"
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'
import { setDashboard } from "src/redux/slicers/dashboard"
import { useDispatch } from "react-redux"
import { MODAL_TYPE } from "src/constants"
import translate, { TranslateComponent } from "src/common/translations"
import { useNavigate } from 'react-router-dom'
export const MODALTYPES = [CREATE_DASHBOARD_SUCCESS, UPDATE_DASHBOARD_SUCCESS, MODAL_TYPE.ERROR]
const ConfirmationModal = () => {
    const {modalType, setModalType} = useDashboardContext()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const optionsTranslated = {
      createSuccess: translate('You have successfully created your dashboard.'),
      updateSuccess: translate('You have successfully updated your dashboard.'),
      success: "Success"
    }

    const hideDialog = () => {
        setModalType(null)
        dispatch(setDashboard({
          actionType: "Close"
        }))
    }
    const modalConfig = useMemo(
        () => ({
          heading: (() => {
            switch (modalType) {
              case CREATE_DASHBOARD_SUCCESS:
              case UPDATE_DASHBOARD_SUCCESS:
                return optionsTranslated.success
              default:
                return "Error"
            }
          })(),
          buttons: (() => {
            switch (modalType) {
              default:
                return [
                  { text: <TranslateComponent>CLOSE</TranslateComponent>, handleClick: hideDialog, type: 'cancel' }
                ]
            }
          })(),
          handleClose: hideDialog,
          ...([CREATE_DASHBOARD_SUCCESS, UPDATE_DASHBOARD_SUCCESS].includes(modalType) && { })
        }),
        [modalType]
    )
    function renderConfirmationText(modalType: any) {
        switch (modalType) {
            case CREATE_DASHBOARD_SUCCESS:
              return optionsTranslated.createSuccess
            case UPDATE_DASHBOARD_SUCCESS:
                return optionsTranslated.updateSuccess
            default:
                return optionsTranslated.createSuccess
          }
    }

    return(
        MODALTYPES.includes(modalType) ? (
            <Modal {...modalConfig}>
              <ConfirmationText>
                {renderConfirmationText(modalType)}
              </ConfirmationText>
            </Modal>
          ) : null
    )
}


export default ConfirmationModal