import styled from "styled-components";
import COLORS from 'src/components/layouts/colors.json'
export const Wrapper = styled.div`

.hamburger-menu {
	position: relative;
	z-index: 100;
		> div {
			position: absolute;
			right: 0;
			overflow: hidden;
			border: 1px solid ${COLORS.TBA_DARK_GREY};
			border-radius: 6px 0 6px 6px;
			box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.1);
			visibility: hidden;
			transition: all 0.4s ease;
			max-height: 0;
		}

		ul {
			margin: 0;
			padding: 10px 0;
			background: ${COLORS.TBA_ALMOST2_WHITE};
			width: 98px;
			li{
				display: flex;
				padding: 0 20px;
				height: 24px;
				align-items: center;
			}
		}

		li {
			display: block;
			max-width: 200px;
			height: 24px;
			padding: 0 20px;
			@include text-list;
			line-height: 24px;
			color: ${COLORS.BLACK};
			cursor: pointer;
			@include text-ellipsis;
			@include no-select;

			.icon {
				margin-right: 9px;
				color: ${COLORS.TBA_DARKER_GREY};
			}

			&:hover {
				color: ${COLORS.TBA_DARKER_GREY};

				.icon {
					color: ${COLORS.TBA_DARKER_GREY};
				}
			}

			&:active {
				color: ${COLORS.TBA_DARK_GREY};

				.icon {
					color: ${COLORS.TBA_MEDIUM_GREY};
				}
			}
		}
	&.opened {
		> div {
			visibility: visible;
		}
	}
}

`