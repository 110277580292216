import styled, { css } from 'styled-components'
import COLORS from 'src/components/legacy/common/colors.json'
import { WidgetNames } from 'src/pages/widgets/helpers'

const getFooterFontSize = (dimension) => {
  switch (dimension) {
    case '300X300':
    case '600X300':
    case '900X300':
    case '300X600':
    case '300X900':
      return 13
    case '600X600':
    case '900X300':
    case '900X600':
    case '600X900':
    case '900X900':
      return 17
    default:
      return 13
  }
}

export const PanelLabel = styled.label`
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 15px;
`

// General comment for size and space
// All the bar charts have class based controls
// all line charts have code based controls
export const WidgetCardStylesContainer = styled.div`
  height: ${(props: any) => props?.cardHeight}px;
  flex: 0 0 auto;
  padding: 10px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  min-width: 300px;

  .has-link {
    cursor: pointer;
    height: 100%;
  }

  a:link {
    color: black;
  }

  a:visited {
    color: black;
  }

  a:hover {
    color: black;
  }

  a:active {
    color: black;
  }

  a:link,
  a:visited {
    text-decoration: none;
  }

  .widget-chart-preview-footer {
    ${(props: any) => {
      if (props?.cardHeight === 600) {
        return css`
          margin: 5px;
        `
      } else if (props?.cardHeight === 900) {
        return css`
          margin: 5px;
        `
      } else {
        return css`
          margin-top: 5px;
        `
      }
    }}
  }

  .group-bar-chart-dimention,
  .group-bar-chart-dimention-axis-text,
  .engery-cost-chart-comp {
    ${(props: any) => {
      if (
        props?.showLegends === true &&
        (WidgetNames?.EnergyConsumption === props?.widgetType ||
          WidgetNames?.EnergyCost === props?.widgetType)
      ) {
        return css`
          height: calc(100% - 81px);
        `
      } else {
        return css`
          height: calc(100% - 67px);
        `
      }
    }}
  }

  .bar-chart-dimention,
  .bar-chart-dimention-axis-text {
    ${(props: any) => {
      if (WidgetNames?.TimeComparisonBarChart === props?.widgetType) {
        return css`
          height: calc(100% - 10px);
        `
      } else {
        return css`
          height: calc(100% - 67px);
          width: inherit;
        `
      }
    }}
  }

  .bar-chart-dimention-comparison-bar {
    height: calc(100% - 115px);
    width: inherit;
  }

  .no-data-icon {
    ${(props: any) => {
      if (props?.cardHeight === 600) {
        return css`
          font-size: 420px;
        `
      } else if (props?.cardHeight === 900) {
        return css`
          font-size: 700px;
        `
      } else {
        return css`
          font-size: 130px;
        `
      }
    }}
  }

  ${(props: any) => {
    if (props?.cardWidth === 300) {
      return css`
        width: 20%;
      `
    } else if (props?.cardWidth === 600) {
      return css`
        width: 40%;
      `
    } else {
      return css`
        width: 60%;
      `
    }
  }}
`

export const WidgetCardStyles = styled.div`
  height: 100%;
  margin: 0;
  overflow: hidden;
  width: 100%;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.TBA_DARK_GREY};
  background: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: 3px 4px 4px #00000033;
  box-sizing: content-box;
  overflow: hidden;

  .widget-chart-preview-footer {
    ${(props: any) => `font-size:${getFooterFontSize(props?.dimension)}px;`}
  }
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 10px 0 16px;
  cursor: grab;
  background: ${COLORS.TBA_MEDIUM_GREEN};
  color: ${COLORS.WHITE};
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid ${COLORS.TBA_DARK_GREY};
`
export const ParaName = styled.div`
  margin-right: auto;
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  line-height: 20px;
  font-size: 16px;
`

export const StyledImg = styled.img`
  width: 100%;
  height: ${(props) =>
    props['has-title'] ? 'calc(100% - 122px)' : 'calc(100% - 65px)'};
  object-fit: contain;
`

export const ListItem = styled.li`
  color: black;
`

export const WeatherImageContainer = styled.div`
height: calc(100% - 62px);
  text-align: center;
  margin-bottom: 10px;
  background-color: ${COLORS.TBA_LIGHT2_GREY};
  position: relative;

  .current-label {
    margin-top: 50px;
    font-size: 14px;
    font-weight: 700;
  }
  .image-section {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
      margin-left: 10px;
    }

    .metric-value {
      font-size: 32px;
      font-weight: bold;
    }
    .metric {
      font-size: 16px;
      margin-top: 10px;
      font-weight: bold;
    }
  }
`

export const WidgetCardContentStyle = styled.div`
  ${(props: any) => {
    if (props.widgetType === WidgetNames?.Orb) {
      return css`
        height: calc(100% - 30px);
      `
    } else {
      return css`
        height: calc(100% - 35px);
      `
    }
  }}
  box-sizing: border-box;
  padding: 3px 8px;
  position: relative;
`

export const WidgetCardWrapper = styled.div`
  flex-wrap: wrap;
  margin-top: 10px;
  .widget {
    position: relative;
    margin: 5px;
    height: 100%;
    background: ${COLORS.WHITE};
    border-radius: 6px;
    box-shadow: 3px 4px 4px #00000033;
    box-sizing: content-box;
    overflow: hidden;
  }
`

export const BuildingDataWidgetChartContainer = styled.div`
  ${(props: any) => {
    return css`
      min-height: 190px;
    `
  }}
`

// General comment for size and space
// All the bar charts have class based controls
// all line charts have code based controls
export const WidgetPreviewChartParentContainer = styled.div`
  ${(props: any) => {
    switch (props?.widgetType) {
      case WidgetNames?.BuildingData:
        return css`
          height: calc(100% - 10px);
        `
      case WidgetNames?.EnergyDemand:
      case WidgetNames?.EnergyConsumption:
      case WidgetNames?.TrendLineChart:
      case WidgetNames?.EnergyCost:
      case WidgetNames?.TimeComparisonBarChart:
        if (props?.showLegends) {
          return css`
            height: calc(100% - 83px);
          `
        } else {
          return css`
            height: calc(100% - 65px);
          `
        }
      default:
        return css`
          height: calc(100% - 39px);
        `
    }
  }}
`

export const BuildingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  /* padding: 5px; */
  & .location-content {
    display: flex;
    align-items: center;
    & .location-label {
      /* display: flex; */
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 700;
      max-width: 180px;
    }
  }
`
export const BaselineSavingsOrOverageInfo = styled.div`
  color: ${COLORS.WHITE};
  text-align: center;
  font-weight: 700;
  padding: 8px;
  margin-top: 2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  span {
    display: block;
  }
  .energy-value {
    font-size: 20px;
  }
  background: ${(props: any) =>
    props.consumption === 'savings' ? COLORS.GREEN : COLORS.RED};
`

export const BuildingDataPreviewHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: space-between;
`

// Energy cost legends

export const EnergyCostLegendsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
`

export const EnergyCostLegend = styled.div`
  display: flex;
  align-items: center;
`

export const SimpleColorComponent = styled.div`
  height: 15px;
  width: 7px;
  background: ${(props) => props.color};
`

export const LegendColorContainer = styled.div`
  display: flex;
  margin-right: 2px;
  margin-left: 2px;
`

export const EnergyCostLegendLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
