import { ChangeEvent } from 'react'
import {
  CustomKPIConfigurationWrapper,
  PanelLabel,
  CustomKPIRadioButtonWrapper
} from '../../styles'
import { RadioGroup } from 'src/components'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from '../MultiTabTorm'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import Icon from 'src/denali-ui/components/Icon'
import {
  KPI_MEASUREMENTS_DATA,
  getDisplayTimeDateList,
  getKpiMeasurementsList,
  getTimePeriodList
} from '../../helpers'
import { TruckRollKPITable } from 'src/components/truck-roll-kpi-table'
import CustomKPISelect from './KPIConfigSelect'
import CustomTextbox from './KPIConfigTextbox'
import { TranslateComponent } from 'src/common/translations'

const CustomKPIConfiguration = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, errors, setFieldValue } = formik
  const getOptions = (options = []) => {
    const radioFields = [
      { label: "Point in time", value: '1' },
      { label: "Trend", value: '0' }
    ].map((option: any, index: number) => ({
      ...option,
      htmlFor: options?.[index]
    }))
    return radioFields
  }
  const getShowPointLabel = () => {
    return (
      <div className="show-point-label">
        <legend><TranslateComponent>Show point in time or plot a trend?</TranslateComponent></legend>
        <ConditionalTooltip
          multiline={true}
          type={ConditionalTooltip.TYPE.ICON}
          content={<TranslateComponent>The KPI widget shows progress toward the goal on your chosen metric. The Point in Time view allows you to highlight status at a single point in time. The Trend view lets you enter in goals and actual readings for each month, and plots that in a chart to show a trend over several months.</TranslateComponent>}
        >
          <Icon width="12px" height="12px" name="infocircle2" color="#bfbfbf" />
        </ConditionalTooltip>
      </div>
    )
  }
  const handleSetFieldValueChange = (key, value) => {
    setFieldValue(key, value)
  }

  const getIcon = (kpiMeasurement) => {
    return KPI_MEASUREMENTS_DATA.get(kpiMeasurement)?.icon
  }
  
  return (
    <CustomKPIConfigurationWrapper>
      <div className="kpi-configuration-main">
        <div>
          <CustomKPISelect
            selectedItem={values?.selectedKpiMeasurement}
            labelName={"KPI Measurement"}
            optionsList={getKpiMeasurementsList(t)}
            placeholder={"— Select One —"}
            setFieldValue={setFieldValue}
            fieldName={'selectedKpiMeasurement'}
            errorItem={errors?.selectedKpiMeasurement}
            selectedTransName={'widgets'}
            transFunc={t}
          />
          <CustomKPIRadioButtonWrapper>
            <RadioGroup
              labelComponent={getShowPointLabel()}
              handleSelection={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                handleSetFieldValueChange(name, value)
              }}
              radioKey="isPointInTime"
              options={getOptions(['new-point-in-time', 'new-trend'])}
              selectedValue={values?.isPointInTime}
            />
          </CustomKPIRadioButtonWrapper>
          {Boolean(JSON.parse(values?.isPointInTime)) ? (
            <>
              <CustomKPISelect
                selectedItem={values?.selectedTimePeriod}
                labelName={`Time Period`}
                isRequired={true}
                optionsList={getTimePeriodList(t)}
                placeholder={"— Select One —"}
                setFieldValue={setFieldValue}
                fieldName={'selectedTimePeriod'}
                errorItem={errors?.selectedTimePeriod}
                selectedTransName={'time'}
                transFunc={t}
              />
            </>
          ) : (
            <>
              <CustomKPISelect
                selectedItem={values?.selectedDisplayTimeDate}
                labelName={"Display Time Date"}
                optionsList={getDisplayTimeDateList(t)}
                placeholder={"— Select One —"}
                setFieldValue={setFieldValue}
                fieldName={'selectedDisplayTimeDate'}
                errorItem={errors?.selectedDisplayTimeDate}
                selectedTransName={'time'}
                transFunc={t}
              />
            </>
          )}
        </div>
        <div>
          {values?.selectedKpiMeasurement && (
            <div className="icon-container">
              <PanelLabel><TranslateComponent>{'KPI Icon'}</TranslateComponent></PanelLabel>
              <div className="upload-bounding-area">
                <div>
                  <span
                    className={`icon icon-${getIcon(
                      values?.selectedKpiMeasurement
                    )}`}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <>
        {Boolean(JSON.parse(values?.isPointInTime)) ? (
          <div className="period-inputs">
            <CustomTextbox
              errorItem={errors?.previousPeroid}
              fieldName={'previousPeroid'}
              labelName={`KPI in Previous Period`}
              isRequired={true}
              setFieldValue={setFieldValue}
              selectedItem={values?.previousPeroid}
            />

            <CustomTextbox
              errorItem={errors?.currentPeriod}
              fieldName={'currentPeriod'}
              labelName={`KPI in Current Period`}
              isRequired={true}
              setFieldValue={setFieldValue}
              selectedItem={values?.currentPeriod}
            />

            <CustomTextbox
              errorItem={errors?.goalCurrentPeriod}
              fieldName={'goalCurrentPeriod'}
              labelName={`KPI Goal for Current Period`}
              isRequired={true}
              setFieldValue={setFieldValue}
              selectedItem={values?.goalCurrentPeriod}
            />
          </div>
        ) : (
          <>
            <div className="kpi-table">
              <TruckRollKPITable
                onChange={(values) => {
                  handleSetFieldValueChange('trendData', values)
                }}
                values={values?.trendData}
              />
            </div>
          </>
        )}
      </>
    </CustomKPIConfigurationWrapper>
  )
}

export default CustomKPIConfiguration
