import { useFormikContext } from '../MultiTabTorm'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { TimeComparisionBarWidgetPreview } from 'src/pages/dashboards/components/DashboardCardWidget/TimeComparisionBarWidgetPreview'
import { WidgetPreviewWrapper } from './../widgetPreviewWrapper'
import { WidgetChartContainer } from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { WidgetNames } from 'src/pages/widgets/helpers'
import { useMemo } from 'react'


export const TimeComparisionBarPreviewWrapper = () => {
  const formik = useFormikContext()
  const { values } = formik
  const { objectPickerSelectedValue, formikErrors } = useWidgetsContext()

  const getEquipmentList = () => {
    return (
      objectPickerSelectedValue &&
      objectPickerSelectedValue?.map((selectedValue) => {
        return {
          buildingName: selectedValue?.buildingName,
          equipmentName: selectedValue?.equipmentName,
          accountName: selectedValue?.accountName
        }
      })
    )
  }

  const getPreviewDetails = useMemo(() => ({
    widgetName: values?.name,
    widgetDescription: values?.description ?? '',
    buildingId: values?.widgetBuilding,
    equipmentList: getEquipmentList(),
    equipmentId: objectPickerSelectedValue?.[0]?.equipmentId,
    property: values?.property,
    selectedTimePeriod: values?.timePeriod?.split('/')?.[1] || '',
    equipmentType: objectPickerSelectedValue?.[0].equipmentType,
    unitsOfMeasure: values?.unitsOfMeasure,
    symbol: values?.symbol || '',
    selectedAggrValue: values?.value?.split('/')?.[1] || '',
    buildingName: objectPickerSelectedValue?.[0].buildingName,
    equipmentName: objectPickerSelectedValue?.[0].equipmentName,
    outerBarColor: values?.outerBarColor,
    innerBarColor: values?.innerBarColor,
    leftBarColor: values?.outerBarColor,
    leftBenchmarkColor: values?.leftBenchmarkColor,
    leftMarkerConstant: values?.leftMarkerConstant,
    leftMarkerLabel: values?.leftMarkerLabel,
    leftTargetMarker: values?.leftTargetMarker?.split('/')?.[1] || '',
    leftTargetMarkerCalculate: values?.leftTargetMarkerCalculate,
    rightBarColor: values?.rightBarColor,
    rightBenchmarkColor: values?.rightBenchmarkColor,
    rightMarkerConstant: values?.rightMarkerConstant,
    rightMarkerLabel: values?.rightMarkerLabel,
    rightTargetMarker: values?.rightTargetMarker?.split('/')?.[1] || '',
    rightTargetMarkerCalculate: values?.rightTargetMarkerCalculate,
    benchmarkColor: values?.benchmarkColor,
    timeInterval: values?.timeInterval?.split('/')?.[1] || '',
    barMin: values?.barMin,
    barMax: values?.barMax?.split('/')?.[1],
    barCalculatedValue: values?.barCalculatedValue?.split('/')?.[1],
    barMaxConstantValue: values?.barConstantValue,
    timeZone: objectPickerSelectedValue?.[0]?.timeZone,
  }), [objectPickerSelectedValue, values])

  return (
    <WidgetPreviewWrapper
      widgetName={values?.name}
      error={Object.keys(formikErrors)?.length > 0}
      chartType="time-comparision-bar-chart"
    >
      <WidgetChartContainer
        widgetType={WidgetNames?.TimeComparisonBarChart}
        showLegends={true}
      >
        <TimeComparisionBarWidgetPreview widgetDetails={getPreviewDetails} />
      </WidgetChartContainer>
    </WidgetPreviewWrapper>
  )
}
