import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Header from '../../../components/pageHeader/header'
import { Container, Content, Block } from '../../../components/layouts'
import { useSelector } from 'react-redux';
import './user-management.scss'
import Button from 'src/denali-ui/components/Button'
import ProgressIndicator from './create-user-progress-indicator'
import Notification from 'src/denali-ui/components/Notification'
import Checkbox from 'src/denali-ui/components/Checkbox'
import translate, { TranslateComponent } from '../../../common/translations'



const FieldDiv = styled.div`
  margin-top: 20px;
`

const ButtonContainer = styled.div`
  text-align: end;
`

const getTranslatedHeader = (name) => {
const selectedLanguage = useSelector((state) => state?.appData?.selectedLanguage);
  if (selectedLanguage == 'en'){
    return name;
  }
  const wordToTranslate = 'Sustainability'
  const splitText = name.split(new RegExp(`(${wordToTranslate})`, 'i'))
 
  return splitText.map((word, index) => {
    if (word.toLowerCase() === wordToTranslate.toLowerCase()) {
      return <TranslateComponent key={index}>{word}</TranslateComponent>
    }
    return <span key={index}>{word}</span>
  })
}
export const AddUserStage = ({
  userInfoObject,
  setUserInfoObject,
  width,
  prev,
  next,
  edit,
  resourceControl,
  applicationList
}) => {
  const [error, setError] = useState(false)
  const notificationRefs = useRef(null)
  const showError = () => {
    const notification = notificationRefs.current
    notification.showToast({
      type: 'error',
      title: <TranslateComponent>Error</TranslateComponent>,
      position: 'bottom',
      message: <TranslateComponent>Please select at least one role.</TranslateComponent>
    })
  }
  useEffect(() => {
    error && showError()
  }, [error])

  return (
    <>
      <Notification ref={notificationRefs} autoHideDuration={5000} />
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        width={width}
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <Header hideToolBar={false} pageTitle={<TranslateComponent>Applications</TranslateComponent>} />
        </Content>
          <Content
            xlColumn={9}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <Block margin="0 0 46px 0">
              <ProgressIndicator stage={1} />
            </Block>
          </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <TranslateComponent>Choose one or more of the following applications.</TranslateComponent>
        </Content>
        <Content
          xlColumn={12}
          lColumn={6}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
          style={{ gridRowStart: 4, gridRowEnd: 6 }}
        >
          <FieldDiv>
            {applicationList?.map(app => {
              return <div key={`app-${app.id}`}>
                <Checkbox
                  key={`application${app.id}`}
                  checked={Boolean(userInfoObject.applications[app.id])}
                  onClick={() => {
                    const objectToSet = {
                      ...userInfoObject, 
                      applications: {
                        ...userInfoObject.applications
                      }
                    }
                    if (objectToSet.applications[app.id]) {
                      delete objectToSet.applications[app.id]
                      const newResourceControl = {...resourceControl[0]}
                      Object.keys(resourceControl[0]).forEach(e => {
                        Object.keys(resourceControl[0][e]).forEach(x => {
                          if (resourceControl[0][e][x].applications[app.id]) {
                            delete newResourceControl[e][x].applications[app.id]
                            if (!Object.keys(newResourceControl[e][x].applications).length) {
                              delete newResourceControl[e][x]
                              if (!Object.keys(newResourceControl[e]).length) {
                                delete newResourceControl[e]
                              }
                            }
                          }
                        })
                      })
                      resourceControl[1](newResourceControl)
                    } else {
                      objectToSet.applications[app.id] = {
                        name: app.name,
                        roles: {}
                      } 
                    }        
                    
                    
                    setUserInfoObject({
                    ...objectToSet
                  })}}
                  testName={`${app.id}_checkbox`}
                />
               {getTranslatedHeader(app.name)}
            </ div>
            })}
        </FieldDiv>
        </Content>
        <Content
          xlColumn={12}
          lColumn={6}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <ButtonContainer>
            <Button
              variant="secondary"
              size="medium"
              onClick={() => prev()}
              style={{ marginRight: '14px' }}
            >
              <TranslateComponent>{`${edit ? 'Cancel' : 'Back'}`}</TranslateComponent>
            </Button>
            <Button
              variant="primary"
              size="medium"
              onClick={() => {
                next()
              }}
            >
              <TranslateComponent>Next</TranslateComponent>
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </>
  )
}
AddUserStage.propTypes = {}

export default AddUserStage
