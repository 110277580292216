import PropTypes from 'prop-types'
import CostSaving from '../cost-savings-view'
import { SelectItemWrapper } from '../../../styles'
import { TranslateComponent } from 'src/common/translations'

export function SimpleItem({
  title,
  description,
  isActive,
  value,
  period,
  infoText,
  children
}) {

  return (
    <SelectItemWrapper>
      {' '}
      <div className="cost-savings-item">
        {!isActive ? (
          <>
            <p className="title">{title}</p>
            <p className="deactive">
              <span className="main-label">
                {<TranslateComponent>Provide details to calculate</TranslateComponent>}
              </span>
              <span className="desc">{description}</span>
            </p>
          </>
        ) : (
          <CostSaving
            label={title}
            value={value}
            period={period}
            isEditMode={true}
            infoText={infoText}
          />
        )}
        {children}
      </div>
    </SelectItemWrapper>
  )
}

SimpleItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isActive: PropTypes.bool,
  value: PropTypes.any,
  period: PropTypes.string,
  infoText: PropTypes.string
}

export default SimpleItem
