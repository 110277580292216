import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { NOTIFCATION_STATUS, NOTIFICATION_SUB_TYPE } from '../constants'
import { replaceTextBySubType } from '../helpers'
import './notification.scss'

const PROTOCOLS = {
  http: 'http://',
  https: 'https://',
  relative: '//'
}

export const isExternalURL = (endpoint) =>
  Object.keys(PROTOCOLS).some((key) => endpoint.startsWith(PROTOCOLS[key]))

export const TEXT_DATE_FORMAT_WITH_TIMEZONE = 'MMM-D-YYYY hh:mm A zz'

export const Notification = React.forwardRef(
  ({ notification, onRemove, onChange, onActionLink, size }, ref) => {
    const [t] = useTranslation()
    const {
      id,
      ntype,
      nSubType = NOTIFICATION_SUB_TYPE.DATA_LOSS,
      app,
      status,
      dataSource,
      nBody,
      createdAt,
      actionLinkText = t('notifications:Action'),
      actionLinkUrl
    } = notification
    const {
      header: title,
      type = 'info',
      message: userMessage,
      text
    } = nBody ? JSON.parse(nBody) : {}
    const isRead = status === NOTIFCATION_STATUS.READ
    const isSmallScreen = false
    const external = actionLinkUrl ? isExternalURL(actionLinkUrl) : false

    return (
      <div
        ref={ref}
        className={`notification ${isRead && 'read'}`}
        onClick={() => {
          if (!isRead) {
            onChange(id, ntype, app, createdAt)
          }
        }}
      >
        {!isSmallScreen && (
          <div className={`type ${type}`}>
            <span className={`icon icon-${type === 'info' && 'notification'}`}>
              {type === 'error' && '!'}
            </span>
          </div>
        )}
        <div className="notification-body">
          <div className="notification-header">
            <div className="title">
              {isSmallScreen && (
                <div className={`type ${type}`}>
                  <span
                    className={`icon ${type === 'info' && 'icon-notification'}`}
                  >
                    {type === 'error' && '!'}
                  </span>
                </div>
              )}
              <p>{title}</p>
              {dataSource && !isSmallScreen && (
                <span className="datasource">
                  ${t('notifications:DataFromDevice')}: {dataSource}
                </span>
              )}
            </div>
            <span
              className="icon icon-close"
              onClick={(e) => {
                e.stopPropagation()
                onRemove(id, ntype, app, createdAt)
              }}
            />
          </div>
          {dataSource && isSmallScreen && (
            <span className="datasource">
              ${t('notifications:DataFromDevice')}: {dataSource}
            </span>
          )}
          <p className="text">
            {replaceTextBySubType(nSubType, text, notification)}
          </p>
          {userMessage && (
            <p className="text">
              {t('common:Message')}: {userMessage}
            </p>
          )}
          <div className="notification-footer">
            {actionLinkUrl && actionLinkText !== 'Data Collection Alert' ? (
              external ? (
                <a
                  href={actionLinkUrl}
                  onClick={() => onActionLink(id)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {actionLinkText}
                </a>
              ) : (
                <Link to={actionLinkUrl} onClick={() => onActionLink(id)}>
                  {actionLinkText}
                </Link>
              )
            ) : (
              <span>{''}</span>
            )}
            <span className="date">
              {moment(createdAt)
                .tz(moment.tz.guess())
                .format(TEXT_DATE_FORMAT_WITH_TIMEZONE)}
            </span>
          </div>
        </div>
      </div>
    )
  }
)

Notification.propTypes = {}

export default Notification
