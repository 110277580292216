import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Dropdown from 'src/components/legacy/components/dropdown'
import DropdownContainer from 'src/components/legacy/components/dropdown/dropdown-container'
import TopNavigationDropdown from './components/navigation-dropdown'
import { EXTRA_SMALL, TINY } from 'src/hooks/breakPoint'
import { AccessControl } from 'src/components/accessControl'
import {
  // NOTIFICATIONS,
  VIDEO_LIBRARY
} from 'src/components/legacy/common/pages'
import { useSelector } from 'react-redux'
// import { selectUserPermissions } from 'src/redux/slicers/appData'
import './index.scss'
import translate, { TranslateComponent } from '../../common/translations'
import { zendeskHelper } from 'src/common/zendesk-helper'
import { NotificationsDisplay } from 'src/denali-components/Sidebar/NotificationsDisplay.tsx'

const TopMenu = ({
  title,
  toggleMyProfileDialog,
  navigateToGen4TraneConnect,
  logout,
  user = {},
  showNewUserIntroduction,
  size
}) => {
  const optionsTranslated = {
    support: translate('Support'),
    feedback: translate('Feedback'),
    notifications: translate('Notifications'),
    about: translate('About'),
    help: translate('Help Video Library'),
    reqHelp: translate('Request Help'),
    giveFeed: translate('Give Feedback')
  }

  // Notification logic moved to src/denali-components/Sidebar/NotificationsDisplay.tsx
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)

  useEffect(() => {
    if (!user.id) return

    // Prefill the user details on the Zendesk Request/feeback support Bot
    zendeskHelper.identify(
      user.firstName ?? '',
      user.lastName ?? '',
      user.email ?? ''
    )
  }, [user])

  return (
    <>
      <div className="menu" id="header-menus">
        <TopNavigationDropdown>
          <Dropdown.Button
            icon="icon-feedback-circle"
            title={
              <>
                {optionsTranslated.support} / {optionsTranslated.feedback}
              </>
            }
          />
          <DropdownContainer>
            <Dropdown.ItemHeader>
              {optionsTranslated.support} / {optionsTranslated.feedback}
            </Dropdown.ItemHeader>
            {/* <Dropdown.Item onClick={showNewUserIntroduction}>
            {optionsTranslated.about} Trane Connect
            </Dropdown.Item> */}
            {/* <Dropdown.Item>
              <Link to={`/${VIDEO_LIBRARY}`}>
              {optionsTranslated.help}
              </Link>
            </Dropdown.Item> */}
            <Dropdown.Item onClick={() => zendeskHelper.openWidget()}>
              <strong>
                {optionsTranslated.reqHelp} / {optionsTranslated.giveFeed}
              </strong>
            </Dropdown.Item>
          </DropdownContainer>
        </TopNavigationDropdown>
        <span className="icon icon-div desktop-only" />
        <TopNavigationDropdown
          open={isNotificationsOpen}
          customClassNames="notification-list-dropdown"
        >
          <Dropdown.Button
            icon="icon-announcement"
            title={optionsTranslated.notifications}
            isCaretDisplayed={[EXTRA_SMALL, TINY].includes(size)}
            onClick={(open) => setIsNotificationsOpen(open)}
          />
        </TopNavigationDropdown>
        <div
          className={`notification-list-dropdown nav-dropdown ${
            isNotificationsOpen && 'opened'
          }`}
        >
          <NotificationsDisplay
            isOpen={isNotificationsOpen}
            setIsOpen={setIsNotificationsOpen}
          />
        </div>
        <TopNavigationDropdown>
          <Dropdown.Button icon="icon-profile" title={title} />
          <DropdownContainer>
            <Dropdown.ItemHeader>{title}</Dropdown.ItemHeader>
            <Dropdown.Item onClick={toggleMyProfileDialog}>
              <TranslateComponent>My Profile</TranslateComponent>
            </Dropdown.Item>
            <AccessControl id="tc.header.gen4link">
              <Dropdown.Item onClick={navigateToGen4TraneConnect}>
                <TranslateComponent>Trane Connect Gen-4</TranslateComponent>
              </Dropdown.Item>
            </AccessControl>
            <Dropdown.Item onClick={logout}>
              <TranslateComponent>Logout</TranslateComponent>
            </Dropdown.Item>
            <Dropdown.Item styleNames="divider-item-menu">
              <hr className="menu-item-divider" />
            </Dropdown.Item>
            <Dropdown.Item styleNames="version-text">
              &#169; Trane {new Date().getFullYear()} |{' '}
              <TranslateComponent>Build ID</TranslateComponent>{' '}
              {process.env?.REACT_APP_AWS_JOB_ID
                ? process.env?.REACT_APP_AWS_JOB_ID.replace(/^0+/, '')
                : ''}
            </Dropdown.Item>
            <Dropdown.Item styleNames="version-text">
              <TranslateComponent>Version:</TranslateComponent>{' '}
              {process.env?.REACT_APP_AWS_COMMIT_ID
                ? process.env?.REACT_APP_AWS_COMMIT_ID.substring(0, 7)
                : ''}
            </Dropdown.Item>
          </DropdownContainer>
        </TopNavigationDropdown>
      </div>
    </>
  )
}

TopMenu.propTypes = {
  title: PropTypes.string,
  toggleMyProfileDialog: PropTypes.func,
  navigateToGen4TraneConnect: PropTypes.func,
  logout: PropTypes.func,
  showNewUserIntroduction: PropTypes.func,
  size: PropTypes.string
}

export default TopMenu
