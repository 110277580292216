import {
  PROPERTY_COMBINATION,
  TargetKeyType,
  VALIDATOR_RULE,
  WHEN_OPERATOR
} from './constants'

import type { RuleInput, TargetkeyEquipmentType } from './type'
import {
  CharacteristicsMappingDetials,
  PropertyMappingDetails
} from './equipmentPropertiesAndCharacteristics'

import { EQUIPMENT_COMPONENT } from './atManager'

export abstract class PropertyModel {
  /**
   * Property targetkey
   *
   * @type {string}
   * @memberof PropertyModel
   */
  targetKey: string = null

  /**
   * Type of targetkey such as Property, Characteristics or Grouped Properties
   *
   * @type {TargetKeyType}
   * @memberof PropertyModel
   */
  targetkeyType: TargetKeyType = null

  /**
   * Indicates Property has valid mapping or not
   *
   * @memberof PropertyModel
   */
  mappingStatus = false

  /**
   * Display name of a property
   *
   * @memberof PropertyModel
   */
  displayName = ''

  /**
   * Contains rules assigned to the property, else undefined
   *
   * @type {PropertyRuleModel}
   * @memberof PropertyModel
   */
  ruleDetails: PropertyRuleModel = null

  /**
   * Conaints soure data such as property data or characteristics data
   *
   * @type {*}
   * @memberof PropertyModel
   */
  sourceDetails: any

  /**
   * Indicates whether given property has valid source data in equipment
   *
   * @memberof PropertyModel
   */
  hasSourceDetails = false

  /**
   * Contains what kind of value is mapped to a property such as Constant, NPS or Other property
   *
   * @type {(PropertyMappingDetails | CharacteristicsMappingDetials)}
   * @memberof PropertyModel
   */
  valueMappingDetails: PropertyMappingDetails | CharacteristicsMappingDetials =
    null
}

export abstract class GroupPropertyModel {
  /**
   * Contains each targetKey details of the group
   *
   * @type {Array<GroupPropertyDetailsModel>}
   * @memberof GroupPropertyModel
   */

  targetKeyDetails: Array<GroupPropertyDetailsModel> = []
  /**
   * targetKeys in the group
   *
   * @type {any}
   * @memberof GroupPropertyModel
   */
  groupTargetKey: any = null

  /**
   * Mapping status of the group
   *
   * @memberof GroupPropertyModel
   */
  mappingStatus = true
}

export abstract class GroupPropertyDetailsModel extends PropertyModel {
  /**
   * targetKeys in current group
   *
   * @type {string}
   * @memberof GroupPropertyDetailsModel
   */
  groupTargetKeys: string

  /**
   * indicates comparetion type of this targety with other targetKeys in the same group
   *
   * @type {PROPERTY_COMBINATION}
   * @memberof GroupPropertyDetailsModel
   */
  condition: PROPERTY_COMBINATION

  /**
   * current postion of targetkey details in current group
   *
   * which is useful for UI to determine the starting postion of each group such
   *
   * Example : whenever a property position is 0 means, a new group is started
   *
   * @type {number}
   * @memberof GroupPropertyDetailsModel
   */
  targetKeyPositionAtGroup: number

  /**
   * indicates the grouping depth level of this property in the grouped properties
   *
   * @memberof GroupPropertyDetailsModel
   */
  depth = 0
}

export abstract class PropertyRuleModel {
  /**
   * Property targetkey
   *
   * @type {string}
   * @memberof PropertyRuleModel
   */
  targetKey: string = null

  /**
   * Property targetkey type
   *
   * @type {TargetKeyType}
   * @memberof PropertyRuleModel
   */
  targetKeyType: TargetKeyType = TargetKeyType.PROPERTIES

  /**
   * Equipment type of targetkey
   *
   * @type {TargetkeyEquipmentType}
   * @memberof PropertyRuleModel
   */
  equipment: TargetkeyEquipmentType = 'Active'

  /**
   * Whether the rule is defined for optional in the source.
   *
   * Because dependency properties may have specific rules that need to be checked. (e.g., to check NPS, only NPS is defined, others will be omitted)
   *
   * @type {boolean}
   * @memberof PropertyRuleModel
   */
  isOptionalRuleDefined = false

  /**
   * Whether the rule is defined for constant in the source.
   *
   * Because dependency properties may have specific rules that need to be checked. (e.g., to check NPS, only NPS is defined, others will be omitted)
   *
   * @type {boolean}
   * @memberof PropertyRuleModel
   */
  isConstantRuleDefined = false

  /**
   * Whether the rule is defined for NPS (No Property Selected) in the source.
   *
   * Because dependency properties may have specific rules that need to be checked. (e.g., to check NPS, only NPS is defined, others will be omitted)
   *
   * @type {boolean}
   * @memberof PropertyRuleModel
   */
  isNoPropertySelectedRuleDefined = false

  /**
   * Rule Value of Optional and allows to mark the property / characteristics is optional
   *
   * @type {VALIDATOR_RULE | Rule | null}
   * @memberof PropertyRuleModel
   */
  optionalRule: RuleInput = null

  /**
   * Rule Value of Constant
   *
   * @type {VALIDATOR_RULE | Rule | null}
   * @memberof PropertyRuleModel
   */
  constantRule: RuleInput = null

  /**
   * Rule Value of NPS (No Property Selected)
   *
   * @type {VALIDATOR_RULE |Rule | null}
   * @memberof PropertyRuleModel
   */
  noPropertySelectedRule: RuleInput = null

  /**
   * Whether the rule defined is valid
   *
   * @memberof PropertyRuleModel
   */
  hasAnyValidRule = false

  /**
   * Whether the property is dependent on any other property
   *
   * @type {boolean}
   * @memberof PropertyRuleModel
   */
  isDependent = false

  /**
   * Whether the  optional (Optional) rule has any denpendency
   *
   * @type {boolean}
   * @memberof PropertyRuleModel
   */
  hasOptionalDepedendency = false

  /**
   * Whether the  cst (Constant) rule has any denpendency
   *
   * @type {boolean}
   * @memberof PropertyRuleModel
   */
  hasConstantDepedendency = false

  /**
   * Whether the NPS (NoPropertySelected) rule has any denpendency
   *
   * @type {boolean}
   * @memberof PropertyRuleModel
   */
  hasNoPropertySelectedDepedendency = false

  /**
   * Whether the property is a dependency of other property
   *
   * @type {boolean}
   * @memberof PropertyRuleModel
   */
  isDependency = false

  /**
   * Operator ` and | or ` that will be used against denpendecy properties during the validation
   *
   * @type {WHEN_OPERATOR | null}
   * @memberof PropertyRuleModel
   */
  whenOperator: WHEN_OPERATOR = null

  /**
   * Use rules value in `eq` if `when` returns true
   *
   * @type {VALIDATOR_RULE | null}
   * @memberof PropertyRuleModel
   */
  eq: VALIDATOR_RULE = null

  /**
   * Use rules value in `nEq` if `when` returns false
   *
   * @type {VALIDATOR_RULE | null}
   * @memberof PropertyRuleModel
   */
  nEq: VALIDATOR_RULE = null

  /**
   * Source rule config of a property from the API response for dubgging purpose
   *
   * @type {any | null}
   * @memberof PropertyRuleModel
   */
  sourceDefinition = null

  /**
   * Indicates whether a calculated property should use rules or not
   *
   * By default, `isApply` is false for all characteristics / properties / calculated properties
   *
   * For a calculated property,
   *  mapping status is true by default and doesn't apply any rule
   *  To skip this behaviour, below flag is introduced and applies the rules when the`isApply` is true in the given property rule
   *
   * It does't apply (and always isApply=false) for characteristics and property
   *
   * @type {boolean}
   * @memberof PropertyRuleModel
   */
  isApply = false
}

export abstract class ATModel {
  /**
   * UUID of Model
   *
   * @type {string}
   * @memberof ATModel
   */
  id: string

  /**
   * Display name of AT
   *
   * @type {string}
   * @memberof ATModel
   */
  displayName: string

  /**
   * Indicates a AT belongs to which component
   *
   * @type {EQUIPMENT_COMPONENT}
   * @memberof ATModel
   */
  component?: EQUIPMENT_COMPONENT = null

  /**
   * Indicates a AT belongs to which instance of a component
   *
   * @memberof ATModel
   */
  instance? = null

  /**
   * Contains all targetkey details for UI such as properties, grouped properties  and characteristics deatils
   *
   * @type {*}
   * @memberof ATModel
   */
  uiProperties?: any = {}

  /**
   * Indicates mapping status of a AT means whether it's valid or invalid (warning)
   *
   * @memberof ATModel
   */
  mappingStatus? = false

  /**
   * Actaul AT name from the Config
   *
   * @type {string}
   * @memberof ATModel
   */
  uiATName?: string

  /**
   * Method allows to get details of a property that passed through the function
   *
   * @memberof ATModel
   */
  getConditionDetails: (k: string) => []

  /**
   * Contains validation config details only from the source config
   *
   * @type {*}
   * @memberof ATModel
   */
  validationObject: any

  /**
   * Contains all config details from the response
   *
   * @memberof ATModel
   */
  sourceATConfig?: any
}

/**
 * This function procudes a model with set of default properties for grouped properties in AT Model
 *
 * @param content : ;
 * @param tooltip
 * @param className
 * @param isTooltip
 * @returns
 */
export function GroupedPropertyUiModel(
  content,
  tooltip,
  className,
  isTooltip = true,
  textIndentToRight = 0
) {
  return {
    textIndentToRight,
    type: 'ats-group',
    dataForDisplayPurpose: 'ui',
    colSpan: 6, // At max in future
    tooltip,
    isTooltip,
    className,
    content
  }
}
