import { ROLES } from '../pages'

// add permissions checks to the { isAvailable } (bool) attribute(s)
// user (global redux) roles prop should be passed in when calling this video function to ensure user permissions are checked
// ***Note that many of the video names in this file cannot be altered without regressions-- due to the way this was initially built, it uses the URL/page hash for getting correct video name/content

// Trane Connect product details
export const TraneConnectProductInfo = {
  name: 'videos:ProductsName>TraneConnect',
  // relates to filter functionality in video-library
  optionsKey: '1'
}
const descriptionDefaultText = 'videos:DescriptionDefaultText'

export default function (roles = []) {
  return {
    overview: {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'videos:OverviewTitle',
      length: '2:29',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    dashboards: {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'dashboard:Dashboard_plural',
      length: '2:39',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'widget-library': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'dashboard:WidgetLibrary',
      length: '2:41',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    opportunities: {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'opportunities:Opportunities',
      length: '2:23',
      tags: [],
      isAvailable: roles.length && roles.includes(ROLES.TECHNICIAN) && false,
      description: descriptionDefaultText
    },
    'connect-device': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'videos:ConnectVideos>ConnectDevice>Title',
      length: '1:26',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'building-data': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'building-performance:BuildingData',
      length: '2:31',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'energy-consumption': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'energy-performance:EnergyConsumption',
      length: '5:34',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'energy-cost-estimator': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'energy-performance:GasCostLibrary',
      length: '1:20',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'gas-cost-library': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'energy-performance:GasCostLibrary',
      length: '1:20',
      tags: [],
      isAvailable: false,
      description: descriptionDefaultText
    },
    'energy-cost-summary': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'energy-performance:EnergyCostSummary',
      length: '1:41',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'electrical-demand': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'energy-performance:ElectricalDemand',
      length: '1:16',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'energy-heat-map': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'energy-performance:EnergyHeatMap',
      length: '1:27',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'electric-intensity': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'energy-performance:ElectricIntensity',
      length: '1:03',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'baseline-library': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'building-configuration:BaselineLibrary',
      length: '0:29',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'location-markers': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'videos:ConnectVideos>LocationMarkers>Title',
      length: '0:50',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'location-meter-settings': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'videos:ConnectVideos>LocationMeterSettings>Title',
      length: '0:55',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'user-management': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'users:UserManagement',
      length: '2:41',
      tags: [],
      isAvailable: true,
      description: descriptionDefaultText
    },
    'building-setup': {
      product: TraneConnectProductInfo.optionsKey,
      productName: TraneConnectProductInfo.name,
      title: 'building-configuration:BuildingSetup',
      length: '0:00',
      tags: [],
      isAvailable: false,
      description: descriptionDefaultText
    }
  }
}

/** platform overview inline help video specs are hard-coded in the feature-list.jsx file */
