// TODO : change width and max width to numbers to enable tooltip
// But need cross verify it.
export const columnsForAllTests = [
  {
    title: 'Date And Time',
    key: 'dateAndTime',
    maxWidth: '120px',
  },
  {
    title: 'Automated Test',
    key: 'automatedTest',
    maxWidth: '120px',
  },
  {
    title: 'Updated By',
    key: 'updatedBy',
    maxWidth: '120px',
  },
  {
    title: 'Organization',
    key: 'organization',
    maxWidth: '120px',
  },
  {
    title: 'Building',
    key: 'building',
    maxWidth: '120px',
  },
  {
    title: 'Equipment',
    key: 'equipment',
    maxWidth: '120px',
  },
  {
    title: 'Setting Level',
    key: 'settingLevel',
    maxWidth: '120px',
  },
  {
    title: 'Comments',
    key: 'comments',
    maxWidth: '120px',
  },
  {
    title: 'View',
    key: 'viewDetails',
    maxWidth: '120px',
    alignRight: true
  }
]

export const columnForTestLevel = [
  {
    title: 'Date And Time',
    key: 'DateAndTime',
    maxWidth: '120px',
  },
  {
    title: 'Updated By',
    key: 'UpdatedBy',
    maxWidth: '120px',
  },
  {
    title: 'Organization',
    key: 'Organization',
    maxWidth: '120px',
  },
  {
    title: 'Building',
    key: 'Building',
    maxWidth: '120px',
  },
  {
    title: 'Equipment',
    key: 'Equipment',
    maxWidth: '120px',
  },
  {
    title: 'Setting Level',
    key: 'SettingLevel',
    maxWidth: '120px',
  },
  {
    title: 'Comments',
    key: 'Comments',
    maxWidth: '120px',
  },
  {
    title: 'View',
    key: 'ViewDetails',
    maxWidth: '120px',
  }
]

export const mockTableData = [
  {
    dateAndTime: 'February 5, 2024, 7:06:21 PM',
    automatedTest: 'Simultaneous Heating and Cooling',
    updatedBy: 'analyticsr',
    organization: '',
    building: '',
    equipment: '',
    settingLevel: '',
    comments: '',
    viewDetails: ''
  }
]

export const columnForViewTestModal = [
  {
    title: 'PARAMETER NAME',
    key: 'displayName',
    maxWidth: '120px',
  },
  {
    title: 'PREVIOUS VALUE',
    key: 'value',
    maxWidth: '120px',
  },
  {
    title: 'CURRENT VALUE',
    key: 'checkPtValue',
    maxWidth: '120px',
  }
]
export const mockViewTableData = [
  {
    ParameterName: 'ParameterName',
    PreviousValue: 'PreviousValue',
    CurrentValue: 'CurrentValue'
  }
]



export const getCSVForATHistory = history => {
  return [
  [
...(history.keys?.map(h => h.title) || [])
  ],
    ...(history.values?.map(vh => 
      (history.keys?.map(h => vh[h.key]))
    ) || [])
]}
  