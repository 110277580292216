// React Libraries
import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { typographyFontFamily } from '../../ui-system/typography'
import {
  ModalContainer,
  CloseButtonContainer,
  ModalHeaderContainer,
  ModalContentContainer,
  ModalFooterButtonRowContainer,
  ModalTitle,
  AlertBar,
  StyledButton,
  ModalHeader
} from './styles'
import { DialogContainer } from './dialog'
import Icon from '../Icon'

const DialogContainerRoot = styled(DialogContainer)`
  ${typographyFontFamily}
`

const Modal = (props) => {
  const {
    heading,
    isAlert = false,
    children,
    buttons = [],
    gray = false,
    footer,
    modal = true,
    autoClose = 0,
    className = '',
    animationType = 'slide',
    eventNames = [{ eventName: 'keyup', eventCode: 27 }],
    modalWidth = '260px',
    handleClose
  } = props

  const modelRefs = useRef(null)
  const dialogRefs = useRef(null)
  const autoFocusRefs = useRef(null)
  let autoCloseFunc = null

  const escapeClose = (e) => {
    if (modelRefs && modelRefs.current) {
      const modalRef = modelRefs.current
      if (e.which === 27 && handleClose) {
        handleClose(e)
      } else if (
        eventNames.filter((elem) => elem.eventCode === e.which).length &&
        !modalRef.contains(e.target) &&
        handleClose
      ) {
        handleClose(e)
      }
    }
  }

  useEffect(() => {
    //*** Did mount code */
    const elementDialog = dialogRefs.current

    if (isAlert) {
      const buttonRefs = autoFocusRefs.current
      buttonRefs['autofocus'] = true
    }

    eventNames.forEach((elem) => {
      window.addEventListener(elem.eventName, escapeClose)
    })

    // DialogPolyfill.registerDialog(el)
    if (modal) {
      elementDialog.showModal()
    } else {
      elementDialog.show()
    }
    if (autoClose > 0) {
      autoCloseFunc = setTimeout(() => {
        handleClose && handleClose()
      }, parseInt(autoClose))
    }

    // click outside of modal to handle close
    elementDialog.addEventListener('click', (e) => {
      if (e.target === elementDialog && handleClose) {
        handleClose(e)
      }
    })

    //** Will un-mount code */
    return () => {
      eventNames.forEach((elem) => {
        window.removeEventListener(elem.eventName, escapeClose)
      })
      if (props.modal) {
        elementDialog.classList.add('hide')
      }
    }
  }, [])

  return ReactDOM.createPortal(
    <DialogContainerRoot
      id="model_dialog"
      className={`${modal ? `modal ${className}` : ''} ${animationType}`}
      ref={dialogRefs}
      modalWidth={modalWidth}
      fontFamily="primary"
    >
      <ModalContainer className="modal-container" isGray={gray} ref={modelRefs}>
        <ModalHeader>
          {isAlert && <AlertBar />}
          <CloseButtonContainer
            name="Close"
            cursor="pointer"
            height="20px"
            width="20px"
            onClick={() => {
              clearTimeout(autoCloseFunc)
              handleClose && handleClose()
            }}
          />

          <ModalHeaderContainer isGray={gray}>
            <ModalTitle>
              {isAlert && (
                <Icon
                  name="Error"
                  height="20px"
                  width="20px"
                  margin="6px 20px 0 0"
                  color="#991909"
                />
              )}
              {heading}
            </ModalTitle>
          </ModalHeaderContainer>
        </ModalHeader>

        <ModalContentContainer className="modal-body-container" isGray={gray}>
          {children}
        </ModalContentContainer>

        <ModalFooterButtonRowContainer>
          {footer || (
            <React.Fragment>
              {buttons.length > 0 &&
                buttons.map((button, key) => {
                  return (
                    <StyledButton
                      size={button?.size}
                      key={key}
                      disabled={button?.disabled}
                      variant={button?.variant}
                      onClick={(e) => {
                        clearTimeout(autoCloseFunc)
                        button?.handleClick(e)
                      }}
                      isAlert={isAlert}
                      ref={autoFocusRefs}
                    >
                      {button.text}
                    </StyledButton>
                  )
                })}
            </React.Fragment>
          )}
        </ModalFooterButtonRowContainer>
      </ModalContainer>
    </DialogContainerRoot>,
    document.body
  )
}

Modal.propTypes = {
  /** animation type of modal  */
  animationType: PropTypes.string,

  /** buttons to display in the footer of modal  */
  buttons: PropTypes.array,

  /** close action  */
  handleClose: PropTypes.func,

  /** header title  */
  heading: PropTypes.string,

  /** content of modal window  */
  children: PropTypes.node,

  /** isModal dialog validation  */
  modal: PropTypes.bool,

  /** display background of modal as gray or white */
  gray: PropTypes.bool,

  /** autoClose the modal after specific time  */
  autoClose: PropTypes.number,

  /** to show footer of modal or not */
  footer: PropTypes.element,

  /** add custom style wrapper  */
  className: PropTypes.string,

  /** event name and code to close the modal  */
  eventNames: PropTypes.arrayOf(
    PropTypes.shape({
      eventName: PropTypes.string,
      eventCode: PropTypes.number
    })
  ),

  /** custom modalwidth size */
  modalWidth: PropTypes.string
}

export default Modal
