import styled from 'styled-components'
import Checkbox from 'src/denali-ui/components/Checkbox'
import IconSVG from 'src/components/Icon/index.js'
import { useEffect, useState } from 'react'
// import { useDebounce } from '../../../../../src/denali-ui/utils'

const Wrapper = styled.div`
    background-color: #f4f4f4;
    border: ${(props) =>
      props.error ? '2px solid #d52b23;' : '1px solid #999;'}
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 300px;
    margin-top: 7px;
    min-height: 300px;
    min-width: 200px;
    position: relative;
    z-index: 9;
`

const HeaderLabel = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 900;
`

const PickerHeader = styled.div`
  ${(props) => props.error && 'color: #d52b23;'}
  background-color: #f4f4f4;
  font-size: 13px;
  font-weight: 700;
  height: 26px;
  letter-spacing: 0.26px;
  line-height: 26px;
  overflow: hidden;
  padding: 0 25px 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

const SearchDiv = styled.div`
  align-items: center;
  background-color: #ccc;
  display: flex;
  height: 26px;
  padding: 0 10px;
`

const SearchInput = styled.input`
    background: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 9px;
    font-family: Archivo Narrow,sans-serif;
    font-size: 12px;
    font-weight: 700;
    height: 18px;
    letter-spacing: .24px;
    line-height: 14px;
    padding: 0 6px;
    width: 100%;
}
`

const DataHeader = styled.span`
  ${(props) => props.error && `background-color: #fea7aa; color: #d52b23;`}
  display: flex;
  border-bottom: 1px solid #ccc;
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  font-weight: 900;
  height: 20px;
  letter-spacing: 0.24px;
  line-height: 19px;
  margin: 0;
  padding: 0 3px;
  text-transform: uppercase;
`

const List = styled.ul`
  flex: 1;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
`

const ListItem = styled.li`
  padding-left: 4px;
  background-color: #fff;
  box-sizing: border-box;
  color: #000;
  display: flex;
  font-family: Lato, sans-serif;
  font-size: 12px;
  height: 20px;
  line-height: 16px;
  list-style-type: disc;
  min-height: 20px;
  text-align: left;
  user-select: none;
  width: 100%;
`

const ListItemText = styled.span`
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

const BottonSection = styled.section`
  display: block;
  flex: none;
  flex: 1 0 0px;
  min-height: 0;
  position: relative;
  z-index: 99;
`

const BottomList = styled.ul`
  background-color: #f4f4f4;
  padding: 0 0 16px;
  flex: 1;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;
  line-height: 20px;
`

const BottomListItem = styled.li`
  background-color: #f4f4f4;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  width: 100%;
  display: flex;
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  height: 18px;
  letter-spacing: 0.24px;
  line-height: 19px;
  line-height: normal;
  padding: 0 10px 0 8px;
  position: relative;
`

const BottomHead = styled.h3`
  background-color: #f4f4f4;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.24px;
  line-height: 19px;
  margin: 0;
  padding: 0 10px;
`

const BottomText = styled.span`
  line-height: 18px;
  max-width: calc(100% - 18px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledDiv = styled.div`
  height: inherit;
  overflow-y: scroll;
`

const StyledDivFoot = styled.div`
  overflow-y: scroll;
  max-height: 25%;
`

const cleanData = (
  values = [],
  selectedBuildings,
  selectedOrganizations,
  selectedSalesOffices
) =>
  values?.map((e) => {
    if (e.type === 'building') {
      return {
        ...e,
        selected: Boolean(
          selectedBuildings.findIndex((x) => e.id === x.id) !== -1
        )
      }
    } else if (e.type === 'organization') {
      return {
        ...e,
        selected: Boolean(
          selectedOrganizations.findIndex((x) => e.id === x.id) !== -1
        )
      }
    } else {
      return {
        ...e,
        selected: Boolean(
          selectedSalesOffices.findIndex((x) => e.id === x.id) !== -1
        )
      }
    }
  }) || []

export const ObjectPicker = ({
  values,
  onCheckboxClick,
  onCheckboxUnclick,
  refetch,
  error,
  label = 'Marker Target',
  dataHeader = 'BUILDINGS',
  headerText = 'Buildings',
  selectedBuildings = [],
  selectedOrganizations = [],
  selectedSalesOffices = [],
  intersectionRef,
  setSearchText
}) => {
  const [subDataView, setSubDataView] = useState(null)
  const [searchTextValue, setSearchTextValue] = useState('')
  const [filteredValues, setFilteredValues] = useState(
    cleanData(
      subDataView ? subDataView : values,
      selectedBuildings,
      selectedOrganizations,
      selectedSalesOffices
    )
  )
  const checkedValues = [
    ...selectedSalesOffices,
    ...selectedOrganizations,
    ...selectedBuildings
  ]

  useEffect(() => {
    setFilteredValues(
      cleanData(
        subDataView ? subDataView : values,
        selectedBuildings,
        selectedOrganizations,
        selectedSalesOffices
      )
    )
  }, [
    values,
    subDataView,
    selectedSalesOffices,
    selectedOrganizations,
    selectedBuildings
  ])

  useEffect(() => {
    const refetchFunc = async () => {
      await refetch(searchTextValue)
    }

    let timer = setTimeout(() => {
      refetchFunc()
    }, 250)
    return () => clearTimeout(timer)
  }, [searchTextValue])

  return (
    <>
      <HeaderLabel>{label}</HeaderLabel>
      <Wrapper error={error}>
        <StyledDiv>
          <PickerHeader error={error}>{headerText}</PickerHeader>
          <SearchDiv>
            <SearchInput
              type={'text'}
              placeholder={'Enter text to search for a resource...'}
              disabled={subDataView}
              onChange={(e) => {
                setSearchText?.(e.target.value)
                setFilteredValues(
                setSearchTextValue(e.target.value)
                )
              }}
            ></SearchInput>
          </SearchDiv>
          {subDataView && (
            <DataHeader error={error}>
              <IconSVG
                name={'left-caret'}
                margin="0px 4px"
                color={'black'}
                hover={'black'}
                width="10px"
                onClick={() => {
                  setSubDataView(null)
                }}
              />
              <span>{dataHeader}</span>
            </DataHeader>
          )}
          <List>
            {filteredValues?.map((v, i) => (
              <ListItem
                key={v.id}
                ref={i === filteredValues.length - 1 ? intersectionRef : null}
              >
                <Checkbox
                  checked={v.selected}
                  onClick={
                    !v.selected
                      ? () => onCheckboxClick(v)
                      : () => onCheckboxUnclick(v)
                  }
                />
                <ListItemText
                  onClick={
                    v.subData
                      ? () => {
                          setSubDataView(v.subData)
                        }
                      : undefined
                  }
                >{`${v.name} ${
                  v?.subData ? `(${v.subData.length} buildings)` : ''
                }`}</ListItemText>
              </ListItem>
            ))}
          </List>
        </StyledDiv>
        <StyledDivFoot>
          {checkedValues?.length ? (
            <BottonSection>
              <BottomHead>Selected:</BottomHead>
              <BottomList>
                {checkedValues.map((c, i) => (
                  <BottomListItem key={`close-${i}`}>
                    <IconSVG
                      onClick={() => {
                        onCheckboxUnclick(c)
                      }}
                      name="close-circle"
                      color="#666"
                      margin="2px 4px 0px 0px"
                      width="14px"
                      height="14px"
                    />
                    <BottomText>{c.name}</BottomText>
                  </BottomListItem>
                ))}
              </BottomList>
            </BottonSection>
          ) : (
            ''
          )}
        </StyledDivFoot>
      </Wrapper>
    </>
  )
}

export default ObjectPicker
