import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import UserManagementTable from '../components/user-management-table'
import { NewStyles } from 'src/NewStyles'
import { t } from 'i18next'
// import UserManagementOrgTable from '../components/user-management-org-table'
// import UserManagementLocTable from '../components/user-management-loc-table'
// import { useTranslation } from "react-i18next";
import Select from 'src/denali-ui/components/Select'
import Option from 'src/denali-ui/components/Option'
import Button from 'src/denali-ui/components/Button'
import Header from '../../../components/pageHeader/header'
import Icon from 'src/denali-ui/components/Icon'
import { useNavigate } from 'react-router-dom'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import Modal from 'src/denali-ui/components/Modal'
// import { STATUS_DESCRIPTION } from "../components/userStatus";
import { pages } from 'src/pages/pages.js'
import { AccessControl } from 'src/components/accessControl'
import { TranslateComponent } from '../../../common/translations'
import { useMutation } from 'src/hooks/APIHooks'
import { updateUser } from '../graphql/mutations'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

const FieldDiv = styled.span`
  margin-right: 16px;
`

const ButtonContainer = styled.div`
  text-align: end;
`

export const UserManagement = () => {
  // const [t] = useTranslation();

  const [error, setError] = useState(false)
  const [deactivateUserDialog, setDeactivateUserDialog] = useState(false)
  const [reactivateUserDialog, setReactivateUserDialog] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('Users by Name')
  const [traneUserError, setTraneUserError] = useState(false)
  const [refetch, setRefetch] = useState(false)

  const { onSubmit: editUser } = useMutation({
    query: updateUser,
    dataPath: 'data',
    onSuccess: (data) => {
      // Need to have timeout due to delay in data actually being updated.
      setTimeout(() => {
        setRefetch(true)
      }, 1000);
    }
  })

  useEffect(() => {
    if (refetch) {
      setRefetch(false)
    }
  }, [refetch])
  
  const navigate = useNavigate()

  const renderError = (error) => {
    return (
      <Modal
        //heading={t("errors:Error")}
        handleClose={() => {
          setError(false)
        }}
        buttons={[
          {
            variant: 'cancel',
            text: <TranslateComponent>Close</TranslateComponent>,
            handleClick: () => {
              setError(false)
            }
          }
        ]}
        isAlert={true}
      ></Modal>
    )
  }

  const renderTable = () => {
    switch (selectedFilter) {
      case 'Users by Name':
        return <UserManagementTable refetch={refetch} setDeactivateUserDialog={(e) => setDeactivateUserDialog(e)} setReactivateUserDialog={(e) => setReactivateUserDialog(e)} />
      // case 'Users by Organization':
      //   return <UserManagementOrgTable />
      // case 'Users by Building':
      //   return <UserManagementLocTable />
    }
  }
  return (
    <>
      <NewStyles>
        {deactivateUserDialog ? <Modal
					heading={<TranslateComponent>Deactivate User</TranslateComponent>}
					handleClose={() => {
						setDeactivateUserDialog(false)
					}}
					buttons={[
						{
							variant: "primary",
							text: <TranslateComponent>Confirm</TranslateComponent>,
							handleClick: () => {
								setDeactivateUserDialog(false)
                editUser({input: {
                  id: deactivateUserDialog?.id,
                  isActive: 0
                }})
                trackEvent(USER_EVENTS.ADMIN_TOOLS.events.SAVE_USER, {mode: "DEACTIVATE"})
							}
						},
						{
							variant: "secondary",
							text: <TranslateComponent>Cancel</TranslateComponent>,
							handleClick: () => {
								setDeactivateUserDialog(false)
							}
						},
					]}
					isAlert={false}
				>
					<p><TranslateComponent>{`Are you sure you wish to deactivate ${deactivateUserDialog.firstName} ${deactivateUserDialog.lastName}'s account?`}</TranslateComponent></p>
				</Modal> : ""}
        {reactivateUserDialog ? <Modal
					heading={<TranslateComponent>Activate User</TranslateComponent>}
					handleClose={() => {
						setReactivateUserDialog(false)
					}}
					buttons={[
						{
							variant: "primary",
							text: <TranslateComponent>Confirm</TranslateComponent>,
							handleClick: () => {
								setReactivateUserDialog(false)
                editUser({input: {
                  id: reactivateUserDialog?.id,
                  isActive: 1
                }})
                trackEvent(USER_EVENTS.ADMIN_TOOLS.events.SAVE_USER, {mode: "ACTIVATE"})
							}
						},
						{
							variant: "secondary",
							text: <TranslateComponent>Cancel</TranslateComponent>,
							handleClick: () => {
								setReactivateUserDialog(false)
							}
						},
					]}
					isAlert={false}
				>
					<p><TranslateComponent>{`Are you sure you wish to activate ${reactivateUserDialog.firstName} ${reactivateUserDialog.lastName}'s account?`}</TranslateComponent></p>
				</Modal> : ""}

        {traneUserError ? (
          <Modal
            heading={t('users:SendResetPasswordEmail')}
            handleClose={() => {
              setTraneUserError(false)
            }}
            buttons={[
              {
                variant: 'cancel',
                text: <TranslateComponent>Close</TranslateComponent>,
                handleClick: () => {
                  setTraneUserError(false)
                }
              }
            ]}
            isAlert={true}
          >
            <p>{t('errors:reset-user-password-invalid-email')}</p>
          </Modal>
        ) : (
          ''
        )}
        {error ? renderError() : ''}
        <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <Header
              hideToolBar={true}
              pageTitle={<TranslateComponent>User Management</TranslateComponent>}
            />
          </Content>
          <AccessControl id="tc.pages.user-management.add">
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <ButtonContainer>
                  <Button
                    variant="primary"
                    size="medium"
                    onClick={() =>{
                      navigate(pages['User Management/add'].href)
                      trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_CREATE_USER)
                    }
                    }
                    startIcon={
                      <Icon
                        name="Add"
                        padding="2px 0px 0px 0px"
                        color="#ffffff"
                      />
                    }
                  >
                    <TranslateComponent>Create User</TranslateComponent>
                  </Button>
              </ButtonContainer>
            </Content>
          </AccessControl>
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            {renderTable()}
          </Content>
        </Container>
      </NewStyles>
    </>
  )
}

export const mapStateToProps = ({ dimensions: { width } }) => ({
  width
})

export default connect(mapStateToProps, {})(UserManagement)
