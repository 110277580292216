import { useFormikContext } from '../MultiTabTorm'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { WidgetPreviewWrapper } from '../widgetPreviewWrapper'
import styled from 'styled-components'
import { GAUGE } from 'src/constants'
import { DataMonitoringGaugeWidgetPreview } from '../../../dashboards/components/DashboardCardWidget/DataMonitoringGaugeWidgetPreview'
import { useMemo } from 'react'


const WidgetContainer = styled.div`
height: 100%; `

export const DataMonitoringGaugePreviewWrapper = () => {
  const formik = useFormikContext()
  const { values } = formik
  const { objectPickerSelectedValue, formikErrors } = useWidgetsContext()

  const getEquipmentList = () => {
    return (
      objectPickerSelectedValue &&
      objectPickerSelectedValue?.map((selectedValue) => {
        return {
          buildingName: selectedValue?.buildingName,
          equipmentName: selectedValue?.equipmentName,
          accountName: selectedValue?.accountName
        }
      })
    )
  }

  const getPreviewDetails = useMemo(() => ({
    buildingId: values?.widgetBuilding,
    widgetDiscription: values?.description,
    equipmentId: objectPickerSelectedValue?.[0]?.equipmentId,
    equipmentList: getEquipmentList(),
    property: values?.property,
    comparePointTo: values?.comparePointTo,
    selectedTimePeriod: values?.timePeriod?.split('/')?.[1] || '',
    equipmentType: objectPickerSelectedValue?.[0].equipmentType,
    unitsOfMeasure: values?.unitsOfMeasure,
    symbol: values?.symbol || '',
    selectedAggrValue: values?.needleValue?.split('/')?.[1] || '',
    baselineColorValue: values?.baselineColorValue,
    threshold1ConstantValue: values?.threshold1ConstantValue,
    threshold1Value: values?.threshold1Value,
    threshold2ConstantValue: values?.threshold2ConstantValue,
    threshold2Value: values?.threshold2Value,
    thresholdsValue: values?.thresholdsValue,
    gaugeMin:values?.gaugeMin,
    gaugeMax:values?.gaugeConstantValue,
    guageMaximumType: values?.gaugeMax?.split('/')?.[1] || '',
    gaugeMaxTimePeriod: values?.gaugeCalculatedValue?.split('/')?.[1] || '',
    buildingName: objectPickerSelectedValue?.[0]?.buildingName,
    equipmentName: objectPickerSelectedValue?.[0]?.equipmentName,
    timeZone: objectPickerSelectedValue?.[0]?.timeZone,
  }), [objectPickerSelectedValue, values])

  return (
    <WidgetPreviewWrapper
      widgetName={values?.name}
      error={Object.keys(formikErrors)?.length > 0}
      chartType={GAUGE}
    >
      <WidgetContainer>
        <DataMonitoringGaugeWidgetPreview widgetDetails={getPreviewDetails} />
      </WidgetContainer>
    </WidgetPreviewWrapper>
  )
}
