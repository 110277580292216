import { paddingCalculator } from '../../PaddingSetup/Axis/axis'
import { calculateTickCountBasedOnDimension } from '../../PaddingSetup/helper'

// Base setup function which will collect all the required details and
// pass it to axisPadding
export const determinePadding = (scales, state) => {
  const paddingDetails: any = {}
  Object.keys(scales)?.forEach((key: any) => {
    const obj = scales?.[key]
    const axisObject = state?.axes?.find((axisObj: any) => axisObj?.key === key)
    const canvasIndex = axisObject?.canvasIndex || '0'
    const canvasDetails = state?.canvasDetails?.[canvasIndex]
    const isX = key?.includes('x')
    const spaceOfRenderArea = isX ? obj?.range?.[1] : obj?.range?.[0]
    const dimensionTickCount = calculateTickCountBasedOnDimension(
      spaceOfRenderArea,
      obj?.scaleType
    )

    const axisPaddingParams = {
      scale: obj?.gScale,
      scaleType: axisObject?.scale?.props?.type,
      tickCount: axisObject?.axis?.tickCount,
      notD3: axisObject?.axis?.notD3,
      dimensionTickCount: dimensionTickCount,
      format: axisObject?.axis?.format,
      isX,
      hideTick: axisObject?.axis?.hideTick,
      tickSize: axisObject?.axis?.tickSize,
      axisName: axisObject?.axis?.name?.text,
      symbol: axisObject?.axis?.name?.symbol,
      categories: axisObject?.scale?.categories,
      d3Ticks: axisObject?.scale?.d3Ticks,
      adjustmentScale: axisObject?.scale?.adjustmentScale,
      spaceOfRenderArea, // Width or height based on axis
      availableSpace: {
        height: canvasDetails?.height,
        width: canvasDetails?.width
      }
    }

    const paddingObject = paddingCalculator(axisPaddingParams)

    paddingDetails[key] = paddingObject
  })

  return paddingDetails
}
