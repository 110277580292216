import { useEffect, useState } from 'react'
import Checkbox from 'src/denali-ui/components/Checkbox'
import IconSVG from 'src/components/Icon/index.js'
import colors from 'src/components/legacy/common/colors.json'
import { useTranslation } from 'react-i18next'
import {
  HeaderLabel,
  StyledDiv,
  PickerHeader,
  SearchDiv,
  SearchInput,
  DataHeader,
  NavigateBack,
  NoObjectsAvailable,
  ObjectPickerHeaderWrapper,
  List,
  ListItem,
  ListItemText,
  FilterByText,
  HeadWrapper,
  SearchInputWrapper,
  IconSpan,
  VirtualIcon
} from './styles'
import { getType } from '../../pages/widgets/helpers'
import Icon from 'src/components/Icon'
import translate, { TranslateComponent, fetchTranslate } from '../../common/translations'
import { useSelector } from 'react-redux'

export const ObjectPicker = ({
  values,
  update,
  error,
  navigationHeader,
  label = '',
  header = 'Select an object',
  dataHeader,
  buildingName,
  isFilteredBy = false,
  selectedDivRef,
  type=''
}) => {
  const [filteredValues, setFilteredValues] = useState(values)
  const [val, setVal] = useState('')
  const [t] = useTranslation()
  const [errors, setErrors] = useState(error)
  const [translatedValues, setTranslatedValues] = useState([])
  let selectedLanguage = useSelector((state) => state?.appData?.selectedLanguage)
  selectedLanguage = selectedLanguage ? selectedLanguage : 'en'

  useEffect(() => {
    try{
      const fetchTranslations = async () => {
        const translations = await Promise.all(values.map(async (v) => {
          if (selectedLanguage !== 'en' && v?.name) {
            const translatedName = await fetchTranslate(v?.name, selectedLanguage);
            return { ...v, translatedName };
          }
          return { ...v, translatedName: v.name };
        }));

        const regex = new RegExp(val.replace(/([.?*+^$[\]\\(){}|-])/gi, "\\$1"), 'gi');
        const filtered = translations?.filter((v) => {
          return val && val !== '' ? v?.translatedName?.match(regex) : true;
        });

        setFilteredValues(filtered);
        setTranslatedValues(filtered);
      }
      if(selectedLanguage!=='en' && (type==='EquipmentTypes' || type==='Properties'))
        fetchTranslations()
      else
      {
        const filtered = values.filter((v) => {
          var regex = new RegExp(val.replace(/([.?*+^$[\]\\(){}|-])/gi, "\\$1"), 'gi')
          return val && val !== '' ? v.name.match(regex) : true
        })
        setFilteredValues(filtered)
        setTranslatedValues(filtered)
      }
    } catch {}
  }, [values, val, selectedLanguage])

  const removeSearch = () => {
    setVal('')
  }

  useEffect(()=>{
    setErrors(filteredValues.filter((item)=>item.checked===true))
  },[filteredValues])

  

  return (
    <>
      <HeaderLabel>{label}</HeaderLabel>
      <>
        <ObjectPickerHeaderWrapper>
          <PickerHeader error={error && (errors[0]?.checked !=undefined && errors[0]?.checked==true? false: true)}> <TranslateComponent>{header}</TranslateComponent>:</PickerHeader>
          <SearchDiv>
            {isFilteredBy && (
              <HeadWrapper>
                <p className="subHead"><TranslateComponent>Filtered by</TranslateComponent>:</p>
                <FilterByText title={buildingName}>{buildingName}</FilterByText>
              </HeadWrapper>
            )}
            <SearchInputWrapper>
              <SearchInput
                type={'text'}
                placeholder={translate("Find an object")+ "..."}
                onChange={(e) => {
                  setVal(e.target.value)
                }}
                value={val}
              ></SearchInput>
              {val && (
                <IconSVG
                  onClick={() => {
                    removeSearch()
                  }}
                  name="close-circle"
                  color={colors.TBA_DARKER_GREY}
                  margin="2px 4px 0px 0px"
                  width="14px"
                  height="14px"
                />
              )}
            </SearchInputWrapper>
          </SearchDiv>
          <NavigateBack>{navigationHeader}</NavigateBack>
          <DataHeader error={error && (errors[0]?.checked !=undefined && errors[0]?.checked==true? false: true)}>{dataHeader}</DataHeader>
        </ObjectPickerHeaderWrapper>
        <StyledDiv ref={selectedDivRef}>
          {translatedValues?.length ? (
            <List>
              {translatedValues.map((v, i) => {
                const targetIcon = getType(v?.familyType)?.icon
                return (
                  <ListItem key={i}>
                    <Checkbox
                      checked={v.checked}
                      onClick={() => update(v, i)}
                      disabled={v.disabled}
                    />
                    {targetIcon !== 'other' &&
                      targetIcon !== 'virtual-meter' && (
                        <IconSpan
                          className={`icon icon-${targetIcon}`}
                        ></IconSpan>
                      )}
                    {targetIcon === 'virtual-meter' && (
                      <VirtualIcon>
                        <Icon
                          name="virtual-meter"
                          color={colors?.TBA_DARKER_GREY}
                          isViewBox={false}
                          width="13px"
                          height="13px"
                        />
                      </VirtualIcon>
                    )}
                    <ListItemText title={selectedLanguage !== 'en' && (type === 'EquipmentTypes' || type === 'Properties') ? v.translatedName : v.name}>{selectedLanguage !== 'en' && (type === 'EquipmentTypes' || type === 'Properties') ? v.translatedName : v.name}</ListItemText>
                  </ListItem>
                )
              })}
            </List>
          ) : (
            <NoObjectsAvailable>
              <p><TranslateComponent>No objects available.</TranslateComponent></p>
              <p>
                <TranslateComponent>Navigate to a higher level or change your filters.</TranslateComponent>
              </p>
            </NoObjectsAvailable>
          )}
        </StyledDiv>
      </>
    </>
  )
}

export default ObjectPicker
