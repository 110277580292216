/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo } from 'react'
import { useChartContext } from '../../../../../../chart-library/CommonComponents/BaseWrapper'
import { AxisDiM } from '../../../../../../chart-library/Utils/defaults'
import GroupBarChart from '../../../../../../chart-library/CommonComponents/Bar/groupBar'
import * as d3 from 'd3'
import {
  getAreaPoints,
  getAreaRangeMaxValue,
  getAreaRangeMinValue,
  getGroupBarMaxValue,
  getGroupBarMinValue,
  getMaxValue,
  getMinValue
} from '../../../../../../chart-library/CommonComponents/Bar/helper'
import PlotLine from '../../../../../../chart-library/CommonComponents/PlotLine/PlotLine'
import BarChart from '../../../../../../chart-library/CommonComponents/Bar'
import Grid from 'src/chart-library/CommonComponents/Grids/Grid'
import AreaChart from './areaChart'

export default function Series(props: any) {
  const chart = useChartContext()
  const enableTooltip = true
  const grid = chart?.grid
  const axis = chart?.axis
  const scale = chart?.scale
  const groupBarSeries = props?.series?.groupBarData ?? []
  const groupInsideBarSeries2 = props?.series?.groupInsideBarData ?? []
  const barSeries = props?.series?.barData ?? []
  const areaSeries = props?.series?.areaData ?? []
  const insideBarSeries2 = props?.series?.insideBarData ?? []
  const measurement = props?.series?.measurement ?? null
  const plotLineConfigData = props?.options?.plotLineConfig ?? null
  const plotLineValue = plotLineConfigData?.value ?? 0
  const plotLineAxisType = props?.options?.plotAxisType ?? ''

  // API flow - 2
  // Once Data is recevied from API. set x axis min & max value to render ticks based on API reponse
  // or you can use min & max value config in useOptions to set x axis values
  useEffect(() => {
    const minValue = groupBarSeries?.length > 0 ? getGroupBarMinValue(groupBarSeries) : getMinValue(barSeries)
    const maxValue = groupBarSeries?.length > 0 ? getGroupBarMaxValue(groupBarSeries) : getMaxValue(barSeries)
    if (groupBarSeries?.length || barSeries?.length) {
      scale?.setDomain(
        AxisDiM?.y,
        0,
        [minValue > 0 ? 0 : minValue, plotLineValue > maxValue ? plotLineValue : maxValue > 0 ? maxValue : 100],
        true
      )
    }
  }, [groupBarSeries, barSeries])

  useEffect(() => {
    if(areaSeries?.length === 0) return
    const minValue = getAreaRangeMinValue(areaSeries) ?? 0
    const maxValue = getAreaRangeMaxValue(areaSeries) ?? 0
    if (areaSeries?.length) {
      scale?.setDomain(
        AxisDiM?.y,
        1,
        [minValue > 0 ? 0 : minValue, maxValue > 0 ? maxValue : 100],
        true
      )
    }
  }, [areaSeries])

  const canvas = useMemo(() => grid?.getGrid(0), [grid?.lastUpdatedAt])!

  const xScale = useMemo(() => {
    const x = scale?.getScale(AxisDiM.x, 0)
    x?.fn?.padding(0.2)
    return x
  }, [scale?.lastUpdatedAt])!

  const xScale2 = useMemo(() => {
    const x2 = scale?.getScale(AxisDiM?.x, 1)
    x2?.fn?.domain([
      0,
      d3.max(areaSeries, function (d: any) {
        return d.x
      })
    ])
    x2?.fn?.range([0, canvas.width])
    return x2
  }, [scale?.lastUpdatedAt])!

  const yScale = useMemo(
    () => scale?.getScale(AxisDiM?.y, 0),
    [scale?.lastUpdatedAt]
  )!

  const yScale2 = useMemo(
    () => scale?.getScale(AxisDiM?.y, 1),
    [scale?.lastUpdatedAt]
  )!

  if (!grid?.lastUpdatedAt || !scale?.lastUpdatedAt || !axis?.lastUpdatedAt) {
    return null
  }
  const area = getAreaPoints(xScale2?.fn, yScale2?.fn, areaSeries)
  return (
    <>
      {measurement && <text x={"10%"} x1={'100%'} y={"99.5%"}>{`(${measurement})`}</text>}
      <g transform={`translate(${canvas?.x} ${canvas?.y})`}>
      <Grid
          scale={yScale?.fn}
          width={canvas?.width}
          height={canvas?.height}
          includeAxis={true}
          opacity={0.3}
          type={'y'}
          tickCount={3}
        />
      
        {areaSeries?.length && <AreaChart measurement={measurement} xScale={xScale2?.fn} yScale={yScale2?.fn} data={areaSeries} area={area} enableTooltip={enableTooltip} setTooltip={props?.setTooltip} />}
       
        {groupBarSeries?.length > 0 &&
          <>
            <GroupBarChart
              measurement={measurement}
              barData={groupBarSeries}
              canvasHeight={canvas?.height}
              xScale={xScale?.fn}
              yScale={yScale?.fn}
              enableToolTip={enableTooltip}
              setTooltip={props?.setTooltip}
            />
          {groupInsideBarSeries2?.length > 0 &&
            <GroupBarChart
              measurement={measurement}
              barData={groupInsideBarSeries2}
              isInsideBar={true}
              canvasHeight={canvas?.height}
              xScale={xScale?.fn}
              yScale={yScale?.fn}
              enableToolTip={enableTooltip}
              setTooltip={props?.setTooltip}
            />
          }
          </>
        }
        {barSeries?.length > 0 &&
          <>
            <BarChart measurement={measurement} barData={barSeries} xScale={xScale} yScale={yScale} enableToolTip={true} setTooltip={props?.setTooltip} />
            <BarChart measurement={measurement} barData={insideBarSeries2} xScale={xScale} yScale={yScale} isInsideBar={true} enableToolTip={true} setTooltip={props?.setTooltip} />
          </>
        }
        {plotLineConfigData && (          
          <>
           <PlotLine
              axisType={plotLineAxisType}
              scale={yScale.fn}
              width={canvas.width}
              height={canvas.height}
              plotObj={plotLineConfigData}
              onMouseEnter = {(event)=>{
                if (!enableTooltip) return
                props?.setTooltip({
                  x: event?.clientX,
                  y: event?.clientY,
                  index: 1,
                  item: {
                    name: plotLineConfigData?.name,
                    value: plotLineConfigData?.value,
                    color: plotLineConfigData?.stroke,
                    measurement,
                    isPlot: true
                  }
                })
              }}
              onMouseLeave={() => {
                if (!enableTooltip) return                
                props?.setTooltip(null)
              }}
            />
          </>
        )}
      </g>
    </>
  )
}
