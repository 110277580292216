import { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/legacy/components/modal/modal'
import CustomPicker from '../../../components/legacy/components/custom-picker'
import OpportunityTablePicker from '../opportunity-table-picker/index'
import _uniqBy from 'lodash/uniqBy'
import { OpportunityAddModalWrapper } from '../styles'
import { deriveTestName } from '../helpers'

const MODAL_TYPE = {
  NEW: 'new',
  EXISTING: 'existing',
  INITIAL: 'initial'
}
let selectedOpportunities = []

const AddToOpportunityModal = (props: any) => {
  const [t] = useTranslation()
  const [modalType, setModalType] = useState(MODAL_TYPE.INITIAL)
  const [modalConfig, setModalConfig] = useState({})
  const [opportunityName, setOpportunityName] = useState('')
  const [optionType, setOptionType] = useState(MODAL_TYPE.NEW)
  const [selectedItems, setSelectedItems] = useState([])

  const {
    opportunities,
    handleClose,
    isLoading,
    onHandleAddFindingToExistingOpportunity,
    onHandleAddFindingToNewOpportunity,
    handleLoading,
    accountId,
    buildingId
  } = props
  useEffect(() => {
    const selectedValues = _uniqBy(opportunities.map(({ opportunityId, name }) => ({
      key: opportunityId,
      value: name
    })), "key")
    setSelectedItems(selectedValues)
  }, [])

  useEffect(() => {
    setUpStateValues(modalType)
  }, [optionType])

  useEffect(() => {
    selectedOpportunities = selectedItems
  }, [selectedItems])

  const setUpStateValues = (modalTypeValue, value = '') => {
    const config = getModalTypeConfig(modalTypeValue, value)
    setModalConfig(config?.modalConfig)
    setOpportunityName(config?.opportunityName ?? value)
    setModalType(config?.modalType ?? MODAL_TYPE.INITIAL)
  }

  const getModalTypeConfig = (modalType, name = undefined) => {
    switch (modalType) {
      case MODAL_TYPE.INITIAL:
        return {
          modalConfig: {
            heading: t('opportunities:AddToAnOpportunity'),
            buttons: [
              {
                type: 'confirm',
                text: t('common:Next'),
                handleClick: () => {
                  setUpStateValues(optionType)
                }
              },
              {
                type: 'cancel',
                text: t('common:Cancel'),
                handleClick: props.handleClose
              }
            ],
            gray: true
          }
        }
      case MODAL_TYPE.NEW:
        return {
          modalConfig: {
            heading: t('opportunities:CreateANewOpportunity'),
            buttons: [
              {
                type: 'confirm',
                text: t('common:Create'),
                handleClick: () => {createOpportunity(name)},//the last character of the opportunity name is getting trimmed.
                disabled: Boolean(!name)
              },
              {
                type: 'cancel',
                text: t('common:Cancel'),
                handleClick: handleClose
              }
            ],
            gray: true
          },
          opportunityName: name,
          modalType
        }
      case MODAL_TYPE.EXISTING:
        return {
          modalConfig: {
            heading: 'Add to an Existing Opportunity',
            buttons: [
              {
                type: 'confirm',
                text: t('common:Save'),
                handleClick: updateOpportunity,
                disabled: false
              },
              {
                type: 'cancel',
                text: t('common:Cancel'),
                handleClick: handleClose
              }
            ],
            gray: false
          },
          modalType
        }
    }
  }

  const switchModalType = (optionType) => setOptionType(optionType)

  const isOpportunityNameValid = (name) => {
    return name === undefined || Boolean(name)
  }

  const createOpportunity = (name) => {
    onHandleAddFindingToNewOpportunity(name)
  }
  const updateOpportunity = () => {
    onHandleAddFindingToExistingOpportunity(selectedOpportunities)
  }

  const displayCurrentModalContent = (modalType) => {
    switch (modalType) {
      case MODAL_TYPE.INITIAL:
        return (
          <div className="add-opportunity-stage-1">
            <div>
              <input
                id="opportunity-type-new"
                name="opportunity-type"
                type="radio"
                checked={optionType === MODAL_TYPE.NEW}
                onChange={() => switchModalType(MODAL_TYPE.NEW)}
              />
              <label htmlFor="opportunity-type-new" className="secondary">
                {t('opportunities:CreateANewBlankOpportunity')}
              </label>
            </div>
            <div className="opportunity-existing">
              <input
                type="radio"
                id="opportunity-type-existing"
                checked={optionType === MODAL_TYPE.EXISTING}
                onChange={() => switchModalType(MODAL_TYPE.EXISTING)}
              />
              <label htmlFor="opportunity-type-existing" className="secondary">
                {t('opportunities:AddToAnExistingOpportunity')}
              </label>
            </div>
          </div>
        )
      case MODAL_TYPE.NEW:
        return (
          <fieldset
            className={`create-opportunity ${
              !isOpportunityNameValid(opportunityName) ? 'invalid' : ''
            }`}
          >
            <label htmlFor="opportunity-name">
              {t('opportunities:OpportunityName')}*
            </label>
            <input
              id="opportunity-name"
              type="text"
              value={opportunityName || ''}
              onChange={({ target: { value } }) =>
                setUpStateValues(modalType, value)
              }
            />
            {!isOpportunityNameValid(opportunityName) && (
              <p className="error">{t('errors:TheNameIsRequired')}</p>
            )}
          </fieldset>
        )
      case MODAL_TYPE.EXISTING:
        return (
          <CustomPicker
            selectedItems={selectedItems}
            onChange={(items) => {
              setSelectedItems(items)
            }}
            handleLoading={(isLoading) => handleLoading(isLoading)}
          >
            <OpportunityTablePicker selectedItems={selectedItems} {...props} />
          </CustomPicker>
        )
    }
  }

  return (
    <Modal
      testName={deriveTestName()+'_Add_To_Opportunity'+ modalType}
      {...modalConfig}
      isWideModal={modalType === MODAL_TYPE.EXISTING}
      handleClose={handleClose}
      loading={isLoading}
    >
      <OpportunityAddModalWrapper>
        <div className="add-to-opportunity-modal">
          {displayCurrentModalContent(modalType)}
        </div>
      </OpportunityAddModalWrapper>
    </Modal>
  )
}

AddToOpportunityModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onHandleAddFindingToExistingOpportunity: PropTypes.func.isRequired,
  onHandleAddFindingToNewOpportunity: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleLoading: PropTypes.func,
  opportunities: PropTypes.array,
  findingOrganizationId: PropTypes.number,
  accountId: PropTypes.string,
  buildingId: PropTypes.string
}

export default memo(AddToOpportunityModal)
