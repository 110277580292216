export const CREATE_OPPORTUNITY = `mutation CreateOpportunity($input: CreateOpportunityInput!) {
  createOpportunity(input: $input)
  {
    id
  }
}
`

export const UPDATE_OPPORTUNITY = /* GraphQL */ `
  mutation updateOpportunity($input: UpdateOpportunityInput!) {
    updateOpportunity(input: $input) {
      id
    }
  }
`


export const GET_OPPORTUNITIES_BY_ACCOUNT_ID = `
query opportunitiesByAccount($accountId: ID!) {
  listBuildingsByAccount(accountId: $accountId) {
    items {
      id
      name
    }
  }
  listOpportunitiesByAccount(accountId: $accountId, limit:5000) {
    items {
      buildingId
      accountId
      title
      paybackType
      paybackDisplay
      paybackTerm
      paybackUnit
      impactComfort
      impactComfortDisplay
      impactCompliance
      impactComplianceDisplay
      impactCustom1
      impactCustom1Display
      impactCustom1Name
      impactCustom2
      impactCustom2Display
      impactCustom2Name
      impactEnergy
      impactEnergyDisplay
      impactPerformance
      impactPerformanceDisplay
      impactReliability
      impactReliabilityDisplay
      showCostOfWaiting
      startingFrom
      status
      nextStep
      id
      price
      priority
      priceDisplay
      assignedTo
      creationDate
      createdBy
      updatedAt
      energySavings
      energySavingsDisplay
      energySavingsUnit
    }
  }
}`

export const SEARCH_OPPORTUNITIES_BY_ACCOUNT_ID = `
query opportunitiesByAccount($accountId: ID!) {
  searchBuildings(filter: {accountId: {eq: $accountId}}, limit: 1000) {
    items {
      id
      name
    }
  }
  searchOpportunities(filter: {accountId: {eq: $accountId}}, limit: 1000) {
    items {
      buildingId
      accountId
      title
      paybackType
      paybackDisplay
      paybackTerm
      paybackUnit
      impactComfort
      impactComfortDisplay
      impactCompliance
      impactComplianceDisplay
      impactCustom1
      impactCustom1Display
      impactCustom1Name
      impactCustom2
      impactCustom2Display
      impactCustom2Name
      impactEnergy
      impactEnergyDisplay
      impactPerformance
      impactPerformanceDisplay
      impactReliability
      impactReliabilityDisplay
      showCostOfWaiting
      startingFrom
      status
      nextStep
      id
      price
      priority
      priceDisplay
      assignedTo
      creationDate
      createdBy
      updatedAt
      energySavings
      energySavingsDisplay
      energySavingsUnit
    }
  }
}`


export const GET_OPPORTUNITIES_BY_BUILDING_ID = `
query opportunitiesByBuilding($buildingId: ID!) {
  getBuilding(id: $buildingId) {
    accountId
    accountName
    id
    name
  }
  listOpportunitiesByBuilding(buildingId: $buildingId, limit:5000) {
    items{
      buildingId
      accountId
      title
      paybackType
      paybackDisplay
      paybackTerm
      paybackUnit
      impactComfort
      impactComfortDisplay
      impactCompliance
      impactComplianceDisplay
      impactCustom1
      impactCustom1Display
      impactCustom1Name
      impactCustom2
      impactCustom2Display
      impactCustom2Name
      impactEnergy
      impactEnergyDisplay
      impactPerformance
      impactPerformanceDisplay
      impactReliability
      impactReliabilityDisplay
      showCostOfWaiting
      startingFrom
      status
      nextStep
      isVisible
      id
      price
      priority
      priceDisplay
      assignedTo
      creationDate
      createdBy
      updatedAt
      energySavings
      energySavingsDisplay
      energySavingsUnit
      equipments(limit: 1000) {
        items {
          equipment {
            id
            name
            type
          }
        }
      }
  }
  }
}`

export const SEARCH_OPPORTUNITIES_BY_BUILDING_ID = `
query opportunitiesByBuilding($buildingId: ID!) {
  searchBuildings(filter: {buildingId: {eq: $buildingId}}) {
      items {
      id
      name
      accountId
      accountName
    }
  }
  searchOpportunities(filter: {buildingId: {eq: $buildingId}}, limit:1000) {
    items{
      buildingId
      accountId
      title
      paybackType
      paybackDisplay
      paybackTerm
      paybackUnit
      impactComfort
      impactComfortDisplay
      impactCompliance
      impactComplianceDisplay
      impactCustom1
      impactCustom1Display
      impactCustom1Name
      impactCustom2
      impactCustom2Display
      impactCustom2Name
      impactEnergy
      impactEnergyDisplay
      impactPerformance
      impactPerformanceDisplay
      impactReliability
      impactReliabilityDisplay
      showCostOfWaiting
      startingFrom
      status
      nextStep
      isVisible
      id
      price
      priority
      priceDisplay
      assignedTo
      creationDate
      createdBy
      updatedAt
      energySavings
      energySavingsDisplay
      energySavingsUnit
      equipments(limit: 1000) {
        items {
          equipment {
            id
            name
            type
          }
        }
      }
  }
  }
}`


export const GET_OPPORTUNITIES_BY_ACCOUNT_BUILDING_ID = `
query buildingOpportunities($id : accountId!) {
  getBuilding( id: $id ) {
    opportunities(limit: 1000) {
      items {
        title
        paybackType
        paybackDisplay
        paybackTerm
        paybackUnit
        impactComfort
        impactComfortDisplay
        impactCompliance
        impactComplianceDisplay
        impactCustom1
        impactCustom1Display
        impactCustom1Name
        impactCustom2
        impactCustom2Display
        impactCustom2Name
        impactEnergy
        impactEnergyDisplay
        impactPerformance
        impactPerformanceDisplay
        impactReliability
        impactReliabilityDisplay
        showCostOfWaiting
        startingFrom
        status
        nextStep
        id
        price
        priority
        priceDisplay
        assignedTo
        creationDate
        createdBy
        updatedAt
        energySavings
        energySavingsDisplay
        energySavingsUnit
      }
    }
    accountId
    accountName
    name
  }
}`


export const DELETE_OPPORTUNITY = /* GraphQL */ `
  mutation deleteOpportunity($input: DeleteOpportunityInput!) {
    deleteOpportunity(input: $input) {
      id
    }
  }
`

export const GET_OPPORTUNITY_BY_ID = /* GraphQL */ `
  query getOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      accountId
      assignedTo
      buildingId
      comment
      createdBy
      creationDate
      energySavingsUnit
      energySavingsDisplay
      energySavings
      id
      impactComfort
      impactComfortDisplay
      impactCompliance
      impactComplianceDisplay
      impactCustom1
      impactCustom1Display
      impactCustom1Name
      impactCustom2
      impactCustom2Display
      impactCustom2Name
      impactEnergy
      impactEnergyDisplay
      impactPerformance
      impactPerformanceDisplay
      impactReliability
      impactReliabilityDisplay
      isVisible
      nextStep
      paybackDisplay
      paybackTerm
      paybackType
      paybackUnit
      price
      priority
      priceUnit
      priceDisplay
      salesOfficeId
      showCostOfWaiting
      startingFrom
      status
      title
      updatedAt
      userId
      equ : equipments(limit:10000) {
        items {
          id
          equipment {
            id
            name
            type
          }
        }
      }
      issues {
        items {
          id
          issue {
            id
            title
            attachments {
              items {
                id
                buildingId
                name
                displayName
                type
                description
                title
                category
                attachmentOrder
              }
            }
          }
        }
      }
      attachments {
        items {
          id
          buildingId
          name
          displayName
          type
          description
          title
          category
          attachmentOrder
        }
      }
    }
  }
`

export const CREATE_BULK_EQUIPMENT_OPPORTUNITY = /* GraphQL */ `
  mutation createEquipmentAssociation(
    $input: AWSJSON!
  ) {
    createEquipmentAssociation(body: $input)
  }
`

export const CREATE_ISSUE_OPPORTUNITY = /* GraphQL */ `
  mutation createIssueOpportunity($input: CreateIssueOpportunityInput!) {
    createIssueOpportunity(input: $input) {
      id
    }
  }
`

export const DELETE_ISSUE_OPPORTUNITY = /* GraphQL */ `
  mutation deleteIssueOpportunity($input: DeleteIssueOpportunityInput!) {
    deleteIssueOpportunity(input: $input) {
      id
    }
  }
`

export const DELETE_EQUIPMENT_OPPORTUNITY = /* GraphQL */ `
  mutation deleteEquipmentOpportunity(
    $input: DeleteEquipmentOpportunityInput!
  ) {
    deleteEquipmentOpportunity(input: $input) {
      id
    }
  }
`
export const GET_ISSUES_BY_BUILDING_ID = `
query issuesByBuilding($buildingId: ID!) {
  getBuilding(id: $buildingId) {
    accountId
    accountName
    name
  }
  listIssuesByBuilding(buildingId: $buildingId) {
    items {
      accountId
      buildingId
      creationDate
      createdBy
      foundDuring
      id
      impactComfort
      impactComfortDisplay
      impactCompliance
      impactComplianceDisplay
      impactCustom1
      impactCustom1Display
      impactCustom1Name
      impactCustom2
      impactCustom2Display
      impactCustom2Name
      impactEnergy
      impactEnergyDisplay
      impactPerformance
      impactPerformanceDisplay
      impactReliability
      impactReliabilityDisplay
      impactComplianceValueType
      impactComplianceValue
      impactComfortValue
      impactComfortValueType
      impactCustom1Value
      impactCustom1ValueType
      impactCustom2Value
      impactCustom2ValueType
      impactEnergyValue
      impactEnergyValueType
      impactPerformanceValue
      impactPerformanceValueType
      impactReliabilityValue
      impactReliabilityValueType
      isVisible
      priority
      status
      title
      updatedAt
      userId
      opportunities {
        items {
          id
          opportunity {
            id
            title
          }
        }
      }
    }
  }
}`


export const SEARCH_OPPORTUNITIES_BY_BUILDING_IDS = `
  query SearchOpportunties($filter: SearchableOpportunityFilterInput, $sort: [SearchableOpportunitySortInput], $limit: Int, $nextToken: String, $from: Int, $aggregates: [SearchableOpportunityAggregationInput]) {
    searchOpportunities(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from, aggregates: $aggregates) {
      items {
        title
        isVisible
        paybackType
        paybackDisplay
        paybackTerm
        paybackUnit
        impactComfort
        impactComfortDisplay
        impactCompliance
        impactComplianceDisplay
        impactCustom1
        impactCustom1Display
        impactCustom1Name
        impactCustom2
        impactCustom2Display
        impactCustom2Name
        impactEnergy
        impactEnergyDisplay
        impactPerformance
        impactPerformanceDisplay
        impactReliability
        impactReliabilityDisplay
        showCostOfWaiting
        startingFrom
        status
        nextStep
        id
        buildingId
        price
        priority
        priceDisplay
        assignedTo
        creationDate
        createdBy
        updatedAt
        energySavings
        energySavingsDisplay
        energySavingsUnit
      }
      nextToken
      total
    }
  }`

export const SEARCH_ISSUES_BY_BUILDING_IDS = `
  query SearchIssues($filter: SearchableIssueFilterInput, $sort: [SearchableIssueSortInput], $limit: Int, $nextToken: String, $from: Int, $aggregates: [SearchableIssueAggregationInput]) {
    searchIssues(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from, aggregates: $aggregates) {    
    items {
        accountId
        buildingId
        creationDate
        createdBy
        foundDuring
        id
        impactComfort
        impactComfortDisplay
        impactCompliance
        impactComplianceDisplay
        impactCustom1
        impactCustom1Display
        impactCustom1Name
        impactCustom2
        impactCustom2Display
        impactCustom2Name
        impactEnergy
        impactEnergyDisplay
        impactPerformance
        impactPerformanceDisplay
        impactReliability
        impactReliabilityDisplay
        impactComplianceValueType
        impactComplianceValue
        impactComfortValue
        impactComfortValueType
        impactCustom1Value
        impactCustom1ValueType
        impactCustom2Value
        impactCustom2ValueType
        impactEnergyValue
        impactEnergyValueType
        impactPerformanceValue
        impactPerformanceValueType
        impactReliabilityValue
        impactReliabilityValueType
        isVisible
        priority
        status
        title
        updatedAt
        userId
        opportunities {
          items {
            id
            opportunity {
              id
              title
            }
          }
        }
      }
  }
}`