
import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'
const screen_max_extra_small = '633px'
const screen_medium_min_width = '914px'
const screen_max_small_max_width = '913px'
const screen_max_extra_small_max_width = '633px'
const screen_max_medium_max_width = '1193px'

export const HighlightSection = styled.div`
/* && .cs-highlight { */
    width: 100%;
    align-self: stretch;

	& :last-child {
		margin-right: 0;
	}
	@media (max-width: ${screen_max_extra_small}) {
        margin-right: 0;
      }

	&& .cs-highlight-box {
		display: flex;
		flex-direction: column;
		padding: 5px 5px;
		position: relative;

		& .box-title {
			font-size: 16px;
			font-weight: bold;
			line-height: 20px;
		}

		& .rdw-editor-main {
			height: 80px;
			// width: calc(100% - 100px);
		}

		input {
			margin-left: 0;
		}
		&.invalid {
			label {
				color: red;
			}
		}
		
		& .error {
			visibility: visible;
			height: 2px;
		}
	}

	& .cs-highlight-currency {
		display: flex;
		flex-direction: row-reverse;
		gap: 10px;
		justify-content: space-between;
		@media (max-width: ${screen_max_extra_small_max_width}) {
			width: calc(100% - 88px);
		}
		// @include screen-max-extra-small {
		// 	input {
		// 		width: calc(100% - 88px);
		// 	}
		// }

		& .select-wrapper {
			min-width: 88px;
			margin-left: 2px;

			& .control {
				max-height: 26px;

				& .control-label {
					height: auto;
				}
			}
		}
		@media (min-width: ${screen_medium_min_width}) && (max-width: ${screen_max_medium_max_width}) {
		display: block;

		 	input {
				width: 100%;
				margin-bottom: 10px;
			} 

			& .select-wrapper {
			width: 100%;
			margin-left: 0;
			max-width: initial;
			}
		}
		// @include screen-medium-to-max-medium {
		// 	display: block;

		// 	input {
		// 		width: 100%;
		// 		margin-bottom: 10px;
		// 	}

		// 	.select-wrapper {
		// 		width: 100%;
		// 		margin-left: 0;
		// 		max-width: initial;
		// 	}
		// }

		@media (min-width: ${screen_max_small_max_width}) {
		input {
				width: 100%;
			margin-right: 10px;
			}

		& .select-wrapper {
			min-width: 150px;
			}
		}

		// @include screen-max-small {
		// 	input {
		// 		width: 100%;
		// 		margin-right: 10px;
		// 	}

		// 	.select-wrapper {
		// 		min-width: 150px;
		// 	}
		// }
		@media (min-width: ${screen_medium_min_width}) {
		input {
			width: 100%;
			margin-right: 10px;
		}

		& .select-wrapper {
			min-width: 150px;
			}
		}
		// @include screen-medium
		// {
		// 	input {
		// 		width: 100%;
		// 		margin-right: 10px;
		// 	}

		// 	.select-wrapper {
		// 		min-width: 150px;
		// 	}
		// }
	}

	&& .cs-highlight-name,
	&& .cs-highlight-view-name {
		justify-content: center;
	}

	&& .cs-highlight-view-name {
		text-align: center;
		min-height: 50px;
		padding: 0 6px;
		font-size: 18px;
		font-weight: bold;
		line-height: 1.2;
	}

	& .cs-highlight-view-box {
		min-height: 20px;
		padding: 2px 0;
		text-align: center;
	}

	& .cs-highlight-view-body {
		padding-top: 15px;
		& .rdw-editor-main {
			overflow: hidden;
		}
	}

	& .cs-highlight-view-amount {
		color: black;
		font-size: 24px;
		font-weight: bold;
	}
	@media (max-width: ${screen_max_small_max_width}) {
 	&:not(:last-child) {
			margin-bottom: 20px;
	 	}
	}
	// @include screen-max-small {
	// 	&:not(:last-child) {
	// 		margin-bottom: 20px;
	// 	}
	// }

	& .carousel-container {
		position: absolute;
		bottom: 5px;
		right: 5px;
		width: 100px;
		border: solid darkgray 1px;
		// border-left: none;
		height: 80px;

		& .icons-wrapper {
			display: inline-flex;
			align-items: center;
			height: 100%;
			justify-content: space-evenly;
			width: 100%;
			svg {
				margin-top: 0;
			}
		}
	}

	&.view_mode {
		padding: 20px;
		background: white;
		border-radius: 6px;
		position: relative;
		border: 1px solid lightgray;

		&:not(:last-child) {
			margin-right: 20px;
		}
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 10.65px;
			bottom: 0;
			left: 0;
			border-radius: 0 0 6px 6px;
			background: gray;
		}

		svg {
			width: 100%;
		}

		& .rdw-editor-main {
			color: darkgray;
		}
	}
`
/* } */
export const Test = styled.div`
& .highlight-overflow-text {
	font-size: 16px;
	font-weight: normal;

	& .tooltip {
		font-size: 12px;
		font-weight: 400;
		line-height: 19px;
		text-transform: initial;
	}
}
`


