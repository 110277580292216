import { useEffect, useState } from 'react'
import TrendsBarWidgetChart from 'src/pages/widgets/widget-library/trendBarChartWidget/TrendsBarWidgetChart'
import { useWidgetChartDataHook } from 'src/hooks/widgetChartDataHook'
import {
  getAggreationTime,
  formatDataForWidgetChart
} from 'src/common/chartHelperFunctions'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  WidgetChartTitleComponent,
  WidgetChartFooterComponent,
  NoWidgetDataAvailable
} from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { isValidUOMForGetChartData } from "../../helper";

export const TrendBarWidgetPreview = ({ widgetDetails }) => {
  const selectedPeriod = widgetDetails?.selectedTimePeriod

  const selectedAggrValue = widgetDetails?.selectedAggrValue

  const dataAggregation = getAggreationTime(widgetDetails?.timeInterval)

  const [barChartData, setBarChartData] = useState({})

  const { widgetChartData, getWidgetChartData, loadingWidgetChartData } =
    useWidgetChartDataHook(
      selectedPeriod,
      widgetDetails?.comparePointTo || ':none',
      dataAggregation,
      widgetDetails?.timeZone
    )

  const getAverageValue = (data) => {
    try {
      const sumValue = data?.reduce((sum, { value = 0 }) => {
        const timeValue = value === null ? 0 : value
        return sum + Number(parseFloat(timeValue).toFixed(2))
      }, 0)
      return Number(sumValue / data?.length || 0).toFixed(2)
    } catch (error) {
      return 0
    }
  }

  const getPlotLinesData = (valuesArray) => {
    if (widgetDetails?.targetMarker?.includes('constant')) {
      return {
        name: widgetDetails?.benchMarkTargetLabel || '',
        plotLineValue: widgetDetails?.markerConstantValue || 0,
        plotLineColor: widgetDetails?.benchmarkColor
      }
    } else if (widgetDetails?.targetMarker?.includes('calculated')) {
      return {
        name: 'Calculated',
        plotLineValue: getAverageValue(valuesArray),
        plotLineColor: widgetDetails?.benchmarkColor
      }
    } else {
      return null
    }
  }

  const formatBarChartData = (formattedData) => {
    const plotlineData = getPlotLinesData(formattedData)
    const barData = {
      measurement: widgetDetails?.symbol || '',
      barData: formattedData?.map((x) => {
        return {
          name: x.timeStamp,
          value: x?.aggrValue || x?.value,
          color: widgetDetails?.barColor
        }
      }),
      ...(plotlineData && { plotLineData: plotlineData })
    }
    setBarChartData(barData)
  }

  useEffect(() => {
    if (
      widgetChartData &&
      widgetChartData?.data?.[0]?.isDataAvailable &&
      widgetChartData?.data?.[0]?.values?.[0]
    ) {
      const formattedData = formatDataForWidgetChart(
        widgetChartData?.data?.[0]?.values?.[0] || [],
        selectedAggrValue,
        dataAggregation,
        selectedPeriod,
        widgetDetails?.timeZone
      )
      formattedData?.length > 0 && formatBarChartData(formattedData)
    }
  }, [widgetChartData])

  const initializeData = async () => {
    const widgetInfo = {
      comparePointTo: widgetDetails?.comparePointTo || ':none',
      chartType: 'Line',
      interval:
        getAggreationTime(widgetDetails?.timeInterval) === 'hourly' ? 15 : 1440, // 1 day
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: selectedAggrValue,
      timeInterval: widgetDetails?.timeInterval,
      buildingId: widgetDetails?.buildingId,
      propertiesList: [widgetDetails?.property]
    }
    if (isValidUOMForGetChartData(widgetDetails?.unitsOfMeasure)) {
      widgetInfo["uomDetails"] = { [widgetDetails?.property]: widgetDetails?.unitsOfMeasure }
    }
    getWidgetChartData(widgetInfo)
  }

  useEffect(() => {
    if (widgetDetails?.property) {
      initializeData()
    }
  }, [widgetDetails])

  return loadingWidgetChartData ? (
    <Spinner />
  ) : barChartData && Object.keys(barChartData)?.length > 0 ? (
    <>
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName={widgetDetails?.equipmentName}
        equipmentType={widgetDetails?.equipmentType}
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDescription}
        widgetProperty={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      <TrendsBarWidgetChart data={barChartData} />
      <WidgetChartFooterComponent
        aggregatorName={selectedAggrValue}
        selectedPeriod={selectedPeriod}
        comparisonBar={true}
        aggragatorInterval={dataAggregation}
        isDefaultForText={true}
        isFullMonthYearName={true}
      />
    </>
  ) : (
    <NoWidgetDataAvailable
      buildingName={widgetDetails?.buildingName}
      equipmentName={widgetDetails?.equipmentName}
      equipmentType={widgetDetails?.equipmentType}
      aggregatorName={selectedAggrValue}
      selectedPeriod={selectedPeriod}
      dataAggregation={dataAggregation}
      widgetType="icon-linewidget"
      equipmentDetails={widgetDetails?.equipmentList}
      widgetDescription={widgetDetails?.widgetDescription}
      timeZone={widgetDetails?.timeZone}
      accountName={widgetDetails?.accountName}
      aggragatorInterval={dataAggregation}
      isDefaultForText={true}
      isFullMonthYearName={true}
    />
  )
}
