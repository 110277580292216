import React, { useEffect, useState } from 'react'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { useQuery } from 'src/hooks/APIHooks'
import uniqBy from 'lodash/uniqBy'
import { GET_EQUIPMENT_PROPERTIES } from '../../graphql'
import _get from 'lodash/get'
import { WidgetPropertyUomWrapper } from '../../styles'
import { useAPIPagination } from 'src/hooks/APIPaginationHook'

const WidgetPropertyUom = ({
  transFunc,
  setFieldValue,
  values,
  errors,
  propertyValue = 'property',
  symbolValue = 'symbol',
  uomValue = 'unitsOfMeasure'
}) => {
  const [propertiesData, setPropertiesData] = useState([])
  const [uomData, setUomData] = useState([])
  const [loadingProperties, getEquipmentProperties, equipmentProperties] =
    useAPIPagination(
      GET_EQUIPMENT_PROPERTIES,
      'data.getPropertyMappingByEquipmentID'
    )
  const [
    loadingRightProperties,
    getRightEquipmentProperties,
    rightPropertiesData
  ] = useAPIPagination()

  const { objectPickerSelectedValue, defaultDimensionGroup } =
    useWidgetsContext()

  useEffect(() => {
    if (equipmentProperties?.length > 0) {
      setPropertiesData([])
      setUomData([])
      const responseData =
        uniqBy(equipmentProperties, (obj) => obj?.tisDisplayName) ?? []
      const properitiesData = responseData
        .filter(
          (x) =>
            x.targetDataType !== 'varchar' && x?.propertyType === 'Property'
        )
        ?.map((r) => ({
          key: r?.targetKey,
          value: r?.tisDisplayName || r?.tagetKey,
          uom: r?.targetMeasureUnit || r?.sourceMeasureUnit,
          type: r?.targetMeasureType || r?.sourceMeasureType,
          propertyKey: r?.targetKey
        }))
        .sort((a, b) => a?.value?.localeCompare(b?.value))
      setPropertiesData(properitiesData)
    }
  }, [equipmentProperties])

  const handleGetEquipmentProperties = async (equipmentId) => {
    await getEquipmentProperties({
      equipmentId
    })
  }

  useEffect(() => {
    (async () => {
      let objectPickerValue = null
      const objectPickerSelectedLength = objectPickerSelectedValue?.length || 0
      if (objectPickerSelectedLength !== 0) {
        objectPickerValue =
          propertyValue === 'rightProperty'
            ? objectPickerSelectedValue[
                objectPickerSelectedLength > 1 ? 1 : 0
              ] ?? null
            : objectPickerSelectedValue[0] ?? null
        if (objectPickerValue) {
          await handleGetEquipmentProperties(objectPickerValue?.equipmentId)
        }
      } else {
        setPropertiesData([])
      }
    })()
  }, [objectPickerSelectedValue])

  useEffect(() => {
    const propValue = values[propertyValue]
    if (propValue) handleUOMDataLoad(propValue)
  }, [defaultDimensionGroup, propertiesData])

  const handleUOMDataLoad = (value) => {
    try {
      const selectedProperty = propertiesData?.find((p) => p?.key === value)
      const uomType = propertiesData?.find((p) => p?.key === value)?.type ?? ''
      // insert '_' before capital letters to inline with UOM from property meta data
      // for some property dimension source has wrong dimesion, so we have this replace code
      // Added specifically while testing presentValue in Analog 
      const uomTypeFormatted = uomType
        ?.replace(/([A-Z])/g, '_$1')
        ?.toLowerCase()
      const unitArray = _get(defaultDimensionGroup, uomTypeFormatted) ?? []
      const unitList = []
      if (selectedProperty?.uom === 'noUnit') {
        unitList.push({ key: 'Default', value: 'Default', symbol: '' })
      } else {
        for (const unit of unitArray) {
          const unitName = unit?.value?.Name ?? ''
          unitList.push({
            key: unitName,
            value: unitName,
            symbol: unit?.value?.Symbol || ''
          })
        }
      }
      setUomData(unitList)
    } catch (error) {
      console.log(error)
    }
  }
  const handlePropertyChange = (data) => {
    setFieldValue(uomValue, '')
    if (data) handleUOMDataLoad(data)
  }
  return (
    <WidgetPropertyUomWrapper propertyValue={propertyValue}>
      <SelectFormInput
        fieldsetClass="inline-2"
        labelName={'Property'}
        optionsList={propertiesData}
        placeholder={'— Select One —'}
        update={(value) => {
          handlePropertyChange(value)
          setFieldValue(propertyValue, value)
        }}
        search={true}
        searchPlaceholder="Search Property"
        errorItem={errors[propertyValue]}
        selectedItem={
          propertiesData?.find((x) => x.key === values[propertyValue])?.value ||
          ''
        }
        loading={loadingProperties}
      />
      <SelectFormInput
        fieldsetClass="inline-2"
        labelName={'Unit of Measure'}
        optionsList={uomData}
        placeholder={'— Select One —'}
        update={(value) => {
          const symbol = uomData?.find((x) => x.key === value)?.symbol || ''
          setFieldValue(symbolValue, symbol)
          setFieldValue(uomValue, value)
        }}
        errorItem={errors[uomValue]}
        selectedItem={values[uomValue]}
      />
    </WidgetPropertyUomWrapper>
  )
}

export default WidgetPropertyUom
