import TextInput from 'src/components/legacy/components/modal-text-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import { RadioGroup } from 'src/components'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import Select from 'src/components/legacy/components/select/select'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectDashboard,
  selectDashboardPath,
  selectDashboards
} from 'src/redux/slicers/dashboard'
import translate from 'src/common/translations'
import {
  DashboardCreateWrapper,
  DashboardWrapper,
  SelectHolder
} from './styles'
import { useDashboardContext } from './CreateDashBoardProvider'
import DashboardObjectPicker from './DashboardObjectPicker'
import { useParams } from 'react-router'

const CreateBlankDashboard = ({
  formik,
  isDefault,
  setNonSelectedDashboard,
  nonSelectedDashboard
}: any) => {
  const { loading: loadingDashboards, data: dashboards } =
    useSelector(selectDashboards)
  const dashboardLists = localStorage.getItem('dashboards')
    ? JSON.parse(localStorage.getItem('dashboards'))['dashboards']
    : dashboards
  const { actionType } = useSelector(selectDashboard)

  const { id } = useParams()

  const dashboardPath = useSelector(selectDashboardPath)

  const dashboardId = dashboardPath ? dashboardPath?.split('/')?.pop() : id

  const [nonSelectedDashboardList, setNonSelectedDashboardList] = useState([])
  const [nonSelectedDashboardListOption, setNonSelectedDashboardListOption] = useState([])

  const { modalType, dashboardType, preConfigSelection } = useDashboardContext()

  useEffect(() => {
    const filteredList = dashboards
      ?.filter((das: any) => das.id !== dashboardId)
      

      const dashboardListOption = filteredList.map((das: any) => ({
        key: das?.id,
        description: das?.name
      }))
      ?.sort((a, b) => (a?.description > b?.description ? 1 : -1))

    setNonSelectedDashboardList(filteredList)

    setNonSelectedDashboardListOption(dashboardListOption)

    if (filteredList?.length && setNonSelectedDashboard) {
      setNonSelectedDashboard(filteredList?.[0])
    }
  }, [dashboards, dashboardId])

  const optionsTranslated = {
    yes: 'Yes',
    no: 'No',
    default: 'Make this my default dashboard?',
    newDefault: translate('Select a new default dashboard'),
    public: 'Make this dashboard public, allowing others to copy it?',
    keyMetric: 'Enable Key Metric Strip?',
    dashName: 'Dashboard Name',
    dashDesc: 'Dashboard Description'
  }

  const getOptions = (options = []) => {
    const radioFields = [
      { label: optionsTranslated.yes, value: '1' },
      { label: optionsTranslated.no, value: '0' }
    ].map((option: any, index: number) => ({
      ...option,
      htmlFor: options?.[index]
    }))
    return radioFields
  }
  const { values, errors, setFieldValue, setValues, touched, setFieldTouched } =
    formik
  const handleInputChange = (key, value: string) => {
    setFieldValue(key, value)
  }

  const radioOptions = useMemo(
    () => [
      {
        options: getOptions(['default-dashboard-yes', 'default-dashboard-no']),
        label: optionsTranslated.default,
        radioKey: 'isDefault',
        selectedValue: values.isDefault,
        disabled:
          dashboardLists?.length === 0 ||
          (actionType === 'Edit' && dashboardLists?.length <= 1),
        hasNewDashboard:
          isDefault === '1' &&
          values?.isDefault === '0' &&
          nonSelectedDashboardList?.length > 1
      },
      {
        options: getOptions(['public-dashboard', 'private-dashboard']),
        label: optionsTranslated.public,
        radioKey: 'isPublic',
        selectedValue: values.isPublic
      },
      {
        options: getOptions(['enable-key-metric-yes', 'enable-key-metric-no']),
        label: optionsTranslated.keyMetric,
        radioKey: 'isKeyMetric',
        selectedValue: values.isKeyMetric
      }
    ],
    [values, dashboardLists, isDefault, nonSelectedDashboardList]
  )
  if (loadingDashboards) return <Spinner />
  return (
    <DashboardCreateWrapper>
      <DashboardObjectPicker
        dashboardType={dashboardType}
        actionType={actionType}
        formik={formik}
      />
      <DashboardWrapper>
        <TextInput
          labelText={optionsTranslated.dashName}
          value={values?.name}
          name="name"
          onChange={({
            target: { value, name }
          }: ChangeEvent<HTMLInputElement>) => {
            handleInputChange(name, value)
          }}
          hasError={Boolean(errors?.['name'])}
          isRequired={true}
          errorMessage={errors?.['name']}
        />
        <TextAreaInput
          labelText={optionsTranslated.dashDesc}
          defaultValue={values?.Description}
          name="Description"
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            handleInputChange('Description', value)
          }}
          isRequired={false}
        />
        {radioOptions.map((option: any, key: number) => (
          <>
            <RadioGroup
              label={option.label}
              handleSelection={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) => {
                handleInputChange(name, value)
              }}
              radioKey={option.radioKey}
              options={option.options}
              key={key}
              selectedValue={option.selectedValue}
              disabled={option.disabled}
            />
            {option?.hasNewDashboard && (
              <SelectHolder>
                <label>{optionsTranslated.newDefault}</label>
                <Select
                  options={nonSelectedDashboardListOption}
                  onChange={(value) => {
                    setNonSelectedDashboard(
                      nonSelectedDashboardList.find(
                        (das: any) => das.id === value
                      )
                    )
                  }}
                  selectedItem={nonSelectedDashboard?.name}
                />
              </SelectHolder>
            )}
          </>
        ))}
      </DashboardWrapper>
    </DashboardCreateWrapper>
  )
}

export default CreateBlankDashboard
