export const SEARCH_CONTRACTOR_COMPANIES = `query SEARCH_CONTRACTOR_COMPANIES(
    $filter: SearchableContractorCompanyFilterInput
    $limit: Int
    $from: Int
    $nextToken: String
    $sort: [SearchableContractorCompanySortInput]
) {
    searchContractorCompanies(
        filter: $filter
        limit: $limit
        from: $from
        nextToken: $nextToken
        sort: $sort
    ) {
      total
      nextToken
      items {
        __typename
        name
        id
      }
    }
  }
  `

  export const SEARCH_SALES_OFFICE_CONTRACTOR_COMPANIES = `query SEARCH_SALES_OFFICE_CONTRACTOR_COMPANIES(
    $filter: SearchableSalesOfficeFilterInput
    $limit: Int
    $from: Int
    $nextToken: String
    $sort: [SearchableSalesOfficeSortInput]
) {
    searchSalesOffices(
        filter: $filter
        limit: $limit
        from: $from
        nextToken: $nextToken
        sort: $sort
    ) {
      total
      nextToken
      items {
        __typename
        name
        id
        type
      }
    }
  }
  `

  export const SEARCH_SALES_OFFICES = `query SEARCH_SALES_OFFICES(
    $filter: SearchableSalesOfficeFilterInput
    $limit: Int
    $from: Int
    $nextToken: String
    $sort: [SearchableSalesOfficeSortInput]
) {
    searchSalesOffices(
        filter: $filter
        limit: $limit
        from: $from
        nextToken: $nextToken
        sort: $sort
    ) {
      total
      nextToken
      items {
        __typename
        name
        id
        type
      }
    }
  }
  `

export const SEARCH_BUILDINGS = `query SEARCH_BUILDINGS(
    $filter: SearchableBuildingFilterInput
    $limit: Int
    $from: Int
    $nextToken: String
    $sort: [SearchableBuildingSortInput]
  ) {
    searchBuildings(
      filter: $filter
      limit: $limit
      from: $from
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        country
        accountName
        id
        name
        address
        state
      }
      nextToken
      total
    }
  }
`
