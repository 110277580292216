import { Formik, Form } from 'formik'
import ToggleBox from 'src/components/legacy/components/toggle-box'
import { getField } from 'src/components/legacy/common/helpers'
import Findings from 'src/models/findings'
import { NewStyles } from 'src/NewStyles'
import QuickAddForm from './QuickAddForm'
import { MAX_LENGTH_OF_NAME } from 'src/components/legacy/common/opportunity.js'
import { Trans } from 'react-i18next'
import { useMutation } from 'src/hooks/APIHooks'
import { CREATE_FINDING_QUICK_ADD, CREATE_BULK_EQUIPMENT_ISSUES } from '../graphql'
import { useIssuesFindingsContext } from '../IssuesFindingsContextProvider'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import { getSearchParams } from "src/common/helperFunctions.js"
import { TranslateComponent } from 'src/common/translations'
import { FormatComment, deriveTestName } from '../helpers'

const QuickAdd = () => {
  const {buildingId: locationId, organizationId} = getSearchParams()
  const { setReloadEvents } = useIssuesFindingsContext()
  const [t] = useTranslation()
  const userInfo = useSelector(selectUserInfo)
  const getUserName = () => {
    return `${userInfo?.lastName} ${userInfo?.firstName}`
  }
  const userName = getUserName()

  const { onSubmit: createFindingQuickAddMutation } = useMutation({
    query: CREATE_FINDING_QUICK_ADD
  })

  const { onSubmit: createBulkEquipmentIssue } = useMutation({
    query: CREATE_BULK_EQUIPMENT_ISSUES
  })

  const validateForm = (values) => {
    const errors: any = {}
    const organization = getField(values, 'organization.organizationId')
    const location = getField(values, 'location.locationId')

    if (
      !values.name ||
      typeof values?.name !== 'string' ||
      values.name?.length === 0 ||
      (values.name?.length > 0 && values.name.trim().length === 0)
    ) {
      errors.name = <Trans i18nKey={'errors:TheNameIsRequired'} />
    }
    if (values.name.length > MAX_LENGTH_OF_NAME) {
      errors.name = (
        <Trans
          i18nKey={'errors:TooLongName'}
          values={{ number: MAX_LENGTH_OF_NAME }}
        />
      )
    }
    if (!location) {
      errors.location = <Trans i18nKey={'errors:buildingIsRequired'} />
    }
    if (!organization) {
      errors.organization = <Trans i18nKey={'errors:organizationIsRequired'} />
    }

    return errors
  }

  const onSubmit = async (values, { resetForm }) => {
    const {
      name,
      comment,
      priority,
      status,
      foundDuring,
      nextStep,
      location,
      organization
    } = values
     await createFindingQuickAddMutation({
      input: {
        accountId: organization?.organizationId,
        buildingId: location?.locationId,
        title: name,
        comment: FormatComment(comment),
        priority,
        status,
        nextStep,
        foundDuring,
        createdBy: userName,
        creationDate: moment().format(BACKEND_DATE_FORMAT)
      }
    }).then(async (res) => {
      const issueID = res.data?.createIssue?.id
      const insertInput = {
        equipmentList: values?.tisObjects?.map(e => e?.tisObjectId),
        opportunityList: [],
        modelType: 'Issue',
        modelId: issueID,
        action: 'Create',
        accountId: organization?.organizationId,
        buildingId: location?.locationId
      }
      await createBulkEquipmentIssue({
        input: JSON.stringify(insertInput)
      })
      setReloadEvents(true)
    })
    resetForm()
  }

  return (
    <NewStyles>
      <ToggleBox
        label={<TranslateComponent>QUICK ADD ISSUE/FINDING</TranslateComponent>}
        showOnlyLabel={true}
        className="findings-quick-add-box"
        opened={true}
        testName={deriveTestName() + '_quick-add'}
      >
        <Formik
          initialValues={new Findings({ organizationId, locationId })}
          onSubmit={onSubmit}
          validate={validateForm}
          validateOnBlur={true}
          validateOnChange={true}
          enableReinitialize={true}
          validateOnMount={true}
        >
          {(props) => (
            <Form>
              <QuickAddForm {...props} />
            </Form>
          )}
        </Formik>
      </ToggleBox>
    </NewStyles>
  )
}

export default QuickAdd
