import { Content } from 'src/components/layouts/'
import QuickAdd from './quickAdd/QuickAdd'
import IssuesFindingsList from './IssuesFindingsList'
import Header from 'src/components/pageHeader/header'
import { useTranslation } from 'react-i18next'
import FindingsListButtons from './findings-list-buttons'
import { useBreakPoint } from 'src/hooks/breakPoint'
import ContainerWrapper from 'src/components/Container'
import { HeaderWrapper, QuickAddWrapper } from './styles'
import { useIssuesFindingsContext } from './IssuesFindingsContextProvider'

import { MODAL_TYPE } from 'src/constants'
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { DELETE_FINDING } from './graphql'
import { CREATE_ENTRY_WITH_ATTACHMENT } from 'src/common/queries/attachment'
import { getSearchParams } from "src/common/helperFunctions.js"
import { GET_BUILDING_BY_ID } from 'src/common/queries/datasource'
import { AccessControl } from 'src/components/accessControl'
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { TranslateComponent } from 'src/common/translations'
import { deriveTestName } from './helpers'
import { useDispatch } from 'react-redux'
import { newRefreshToken } from 'src/redux/slicers/appData'

const IssuesFindingsContainer = () => {
  const [t] = useTranslation()
  const { width } = useBreakPoint()
  const [findings,setFindings] = useState([])
  const {buildingId: locationId, organizationId} = getSearchParams()  
  const dispatch = useDispatch()
  const { setReloadEvents, modalType, setModalType, finding } =
    useIssuesFindingsContext()
  const renderConfirmationText = (modalType) => {
    switch (modalType) {
      case MODAL_TYPE.CONFIRMATION:
        return t('common:DeleteConfirm', { item: finding?.title })
      default:
        return t('common:DeleteNotification', { name: finding?.title })
    }
  }
  const hideDeleteDialog = () => {
    setModalType('')
  }
  const { onSubmit: deleteS3AttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })
  const { data: buildingData, refetch: refetchBuildingData, loading } = useQuery({
    query: GET_BUILDING_BY_ID,
    disableInitialLoad: true,
    dataPath: 'data.getBuilding'
  })
  const { onSubmit: deleteFinding } = useMutation({
    query: DELETE_FINDING,
    onSuccess: () => {
      (async () => {
        const attachmentBody = {
          modelType: 'Issue',
          modelData: {
            delete: {
              id: finding?.id
            }
          },
          attachments: {}
        }
        await deleteS3AttachmentMutation({
          input: JSON.stringify(attachmentBody)
        })
      })()
      setReloadEvents(true)
      setModalType(MODAL_TYPE.SUCCESS)
    }
  })
  const deleteFindingHandler = async () => {
    await dispatch<any>(
      newRefreshToken(
        [finding.buildingId],
        null,
        null,
        ["IssueAdmin", "AttachmentAdmin"]
      )
    )
    trackEvent(USER_EVENTS.ISSUES_AND_FINDINGS.events.DELETE_ISSUE_FINDING, {
      organizationId,
      buildingId: finding?.buildingId
    })
    deleteFinding({ input: { id: finding?.id } })
  }
  const modalConfig = useMemo(
    () => ({
      heading: t("common:Delete", { item: t("opportunities:Issue/Finding") }),
      buttons:
        modalType === MODAL_TYPE.CONFIRMATION
          ? [
              {
                text: t('common:Yes'),
                handleClick: deleteFindingHandler,
                type: 'valid'
              },
              {
                text: t('common:No'),
                handleClick: hideDeleteDialog,
                type: 'cancel'
              }
            ]
          : [
              {
                text: t('common:Close'),
                handleClick: hideDeleteDialog,
                type: 'cancel'
              }
            ],
      handleClose: hideDeleteDialog
    }),
    [modalType]
  )

  useEffect(() => {
    const getAccountDataFunc = async () => {
      if (locationId) {
        await refetchBuildingData({id: locationId})
      }
    }
    getAccountDataFunc()
  }, [locationId,organizationId])  

  const getOrganizationName = useMemo(()=>{
    return buildingData?.accountName ?? ''
  },[buildingData])

  const getLocationName = useMemo(()=>{
    return locationId ? buildingData?.name ?? '' : ''
  },[buildingData])
  
  const handleGetFindingRecords = (rows) =>{
    setFindings(rows)
  }
  
  return (
    <ContainerWrapper
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding="0"
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <HeaderWrapper>
          <Header
            hideToolBar={false}
            pageTitle={<TranslateComponent>Issues/Findings</TranslateComponent>}
          >
            <FindingsListButtons
              loading={loading}
              locationName={getLocationName}
              organizationName={getOrganizationName}
              allOrganizations={false}
              findings={findings} />
          </Header>
        </HeaderWrapper>
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
        style={{ marginTop: '-90px' }}
      >
        <AccessControl id="tc.pages.findings.add">
          <QuickAddWrapper width={width}>
            <QuickAdd />
          </QuickAddWrapper>
        </AccessControl>
        
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <IssuesFindingsList handleGetFindingRecords={handleGetFindingRecords}/>
      </Content>
      {[MODAL_TYPE.CONFIRMATION, MODAL_TYPE.SUCCESS].includes(modalType) ? (
        <Modal 
          testName ={deriveTestName(modalType===MODAL_TYPE.CONFIRMATION ? "delete_confirmation" : "delete_success")}
          {...modalConfig}
        >
          <ConfirmationText>
            {renderConfirmationText(modalType)}
          </ConfirmationText>
        </Modal>
      ) : null}
    </ContainerWrapper>
  )
}

export default IssuesFindingsContainer
