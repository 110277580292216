import { useEffect } from 'react'
import { clsx } from 'clsx'

import useInitChart from './useInitChart'
import { ChartContext } from './chartContext'

import './style.scss'

export default function BaseWrapper({
  className = '',
  children,
  options = {},
  disableUpdateOnResize = false,
  containerProps = {},
  getConfig = () => null,
  testName = ''
}: BaseWrapperCompProps) {
  const { config, chartCSSclass } = useInitChart(options, disableUpdateOnResize)

  useEffect(() => {
    getConfig(config)
  }, [config])

  return (
    <ChartContext.Provider value={config}>
      <div
        data-testid={testName}
        className={clsx('t-chart-svg-container', chartCSSclass, className)}
        {...containerProps}
      >
        {children}
      </div>
    </ChartContext.Provider>
  )
}

// Re-export context
export {
  ChartContext,
  useChartContext,
  defaultChartConfig
} from './chartContext'
