export const isBoolean = (val) =>
  val === 'true' || val === 'false' || val === true || val === false

export function hasKey(obj = {}, key = '') {
  return Object(obj).hasOwnProperty(key)
}

export function isEqual(a, b) {
  if (typeof b === 'object' && b !== null) {
    // two might not be true all times
    return a === b
  }

  return String(a) === String(b)
}

export function isFunction(val) {
  return Object.prototype.toString.call(val) === '[object Function]'
}

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function isObject(val) {
  return Object.prototype.toString.call(val) === '[object Object]'
}
