import { useEffect, useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import AutomatedTestSettingsList from './AutomatedTestSettingsList'
import { Block } from '@trane/trane-components/dist/simple/layout/index.js'
import FilterableSelect from 'src/components/legacy/components/filterable-select/filterable-select'
import { useSearchParams } from 'react-router-dom'
import { getSearchParams } from '../../common/helperFunctions'
import { ListBuildingEquipments, ListBuiildingsByAccount } from './graphql'
import { AtsSearchAndFilter } from './style'
import _sortBy from 'lodash/sortBy'
import _uniqBy from 'lodash/uniqBy'
import { ALL_EQUIPMENT_ITEM, ALL_EQUIPMENT_ITEM_ID } from './constants'
import translate, { TranslateComponent } from 'src/common/translations'
import { useDispatch } from 'react-redux'
import { setAutomatedSettingEquipment } from 'src/redux/slicers/buildingPicker'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import  withFeatureRestrictions from 'src/common/featureRestrictionHOC'



const AutomatedTestSettingsContainer = () => {
  const [equipments, setEquipments] = useState([])
  const [uniqEquipmentGroups, setUniqEquipmentGroups] = useState([])
  const [sortedbuildingList, setSortedBuildingList] = useState([])

  const optionsTranslated = {
    findEquipment: translate("Find an Equipment..."),
    noEquipSelected: translate("No Equipment Selected"),
    noEquipAvailable: translate("No Equipment Available"),
    allEquipmentValue: translate(ALL_EQUIPMENT_ITEM.value)
    }

  const [selectedEquipment, setSelectedEquipment] = useState(null)

  const [, setSearchParams] = useSearchParams()
  const {
    organizationId,
    buildingId,
    equipment: equipmentId
  } = getSearchParams()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const {
    data: equipmentsList,
    refetch: refetchEquipmentsList,
    loading: loadingEquipmentsList
  } = useQuery({
    query: ListBuildingEquipments,
    variables: { id: buildingId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchEquipment.items'
  })

  const {
    data: buildingsList,
    refetch: refetchBuildingsList,
    loading: loadingBuildingsList
  } = useQuery({
    query: ListBuiildingsByAccount,
    variables: { id: buildingId },
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listBuildingsByAccount.items'
  })

  useEffect(() => {
    if (buildingId) {
      setEquipments([])
      setUniqEquipmentGroups([])
      refetchEquipmentsList({ id: buildingId })
      refetchBuildingsList({ id: organizationId })
    }
  }, [buildingId, organizationId, searchParams])

  useEffect(() => {
    if (equipmentsList) {
      setEquipments([
        ...(equipmentsList.length > 1 ? [{key: ALL_EQUIPMENT_ITEM.key, value: optionsTranslated.allEquipmentValue}] : []),
        ...equipmentsList?.sort((a, b) =>
          a?.value?.localeCompare(b?.value, undefined, { numeric: true })
        )
      ])
      if (equipmentsList?.length > 0) {
        setUniqEquipmentGroups(
          _sortBy(
            _uniqBy(equipmentsList, (equipment) => equipment.family),
            'family'
          )
        )
      } else {
        setUniqEquipmentGroups([])
      }
    }
  }, [equipmentsList,optionsTranslated.allEquipmentValue])

  useEffect(() => {
    if (buildingsList?.length > 0) {
      setSortedBuildingList(
        buildingsList.sort((a, b) =>
          a?.value?.localeCompare(b?.value, undefined, { numeric: true })
        )
      )
    } else {
      setSortedBuildingList([])
    }
  }, [buildingsList])

  useEffect(() => {
    const equipmentDetails = equipments?.find((x) => x?.key === equipmentId)
    setSelectedEquipment(equipmentDetails || null)
    dispatch(setAutomatedSettingEquipment(equipmentDetails || null))
  }, [equipmentId, equipments])

  const handleEquipmentChange = (equipmentId) => {
    if (equipmentId === ALL_EQUIPMENT_ITEM_ID) {
      setSelectedEquipment(null)
      setSearchParams((prevParams) => {
        if (prevParams.has('equipment')) {
          prevParams.delete('equipment')
        }
        return prevParams
      })

      //setSearchParams({ location: buildingId })
    } else {
      setSearchParams({
        organization: organizationId,
        equipment: equipmentId,
        location: buildingId
      })
    }
  }

  return (
    <>
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        padding="0"
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <PageHeader
            pageTitle={
              <TranslateComponent>Automated Test Settings</TranslateComponent>
            }
            // titleTools={
            //   <>
            //     <Button mode={'disabled'} onClick={() => console.log('create')}>
            //       {<TranslateComponent>Exclude Equipment</TranslateComponent>}
            //     </Button>
            //     <Button mode={'disabled'} onClick={() => console.log('create')}>
            //       {<TranslateComponent>Remove Settings</TranslateComponent>}
            //     </Button>
            //   </>
            // }
          />
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {loadingEquipmentsList || loadingBuildingsList ? (
            <Spinner />
          ) : buildingId ? (
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <Block display="flex" justify="space-between" align="left" z={1}>
                <AtsSearchAndFilter>
                  {buildingId && equipmentsList && (
                    <FilterableSelect
                      itemLabel={'Equipment'}
                      defaultItemText={optionsTranslated.noEquipSelected}
                      noItemsText={optionsTranslated.noEquipAvailable}
                      searchPlaceholder={optionsTranslated.findEquipment}
                      selectedItem={
                        !selectedEquipment
                          ? ALL_EQUIPMENT_ITEM_ID
                          : selectedEquipment?.key
                      }
                      onChange={handleEquipmentChange}
                      options={equipments}
                      disableDefaultSorting={true}
                      isItemLabelTranslated={true}
                    />
                  )}
                </AtsSearchAndFilter>
              </Block>
              <AutomatedTestSettingsList
                uniqEquipmentGroups={uniqEquipmentGroups}
                selectedEquipment={selectedEquipment}
                equipmentsList={equipmentsList}
                buildingsList={sortedbuildingList}
                loadingContainer={loadingEquipmentsList || loadingBuildingsList}
              />
            </Content>
          ) : (
            <Block display="flex" justify="space-between" align="left" z={10}>
              {<TranslateComponent>Please select building</TranslateComponent>}
            </Block>
          )}
        </Content>
      </Container>
    </>
  )
}

export default withFeatureRestrictions(
  AutomatedTestSettingsContainer
)
