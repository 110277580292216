import { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import capitalize from 'lodash/capitalize'
import ClickOutside from 'src/components/legacy/components/decorators/click-outside'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import classNames from 'classnames'
import { EditableFieldWrapper } from '../styles'
import translate from 'src/common/translations'

const EditableField = (props) => {
  const {
    fieldName,
    className,
    label,
    fieldKey,
    value,
    options,
    toggleOpen,
    open
  } = props
  const tooltipContent = `${translate("Edit")} ${capitalize(label.toLowerCase())}`
  const [current, setCurrent] = useState(false)

  const clickHandler = () => {
    const { toggleOpen, open = false } = props
    open ? setCurrent(true) : toggleOpen()
  }

  const changeHandler = (setFieldValue) => (formValue) => {
    const { toggleOpen, fieldKey, onChange, fieldName } = props
    if (formValue !== fieldKey) {
      setFieldValue(fieldName, formValue)
      onChange(formValue)
    }
    toggleOpen()
  }

  return (
    <EditableFieldWrapper>
      <div
        className={classNames('editable-field-wrapper', { 'read-only': !open })}
      >
        <Formik
          onSubmit={() => {
            console.log('')
          }}
          initialValues={{ [fieldName]: fieldKey, readOnly: !open }}
          enableReinitialize={true}
        >
          {({ setFieldValue }) => (
            <Form
              onClick={clickHandler}
              className="editable-field tooltip-container"
            >
              <EditableView
                type={EditableView.COMPONENT_TYPE.SELECT}
                className={className}
                readOnly={!open}
                value={value}
                options={options}
                name={fieldName}
                onChange={changeHandler(setFieldValue)}
              />
              {!open && <span className="icon icon-edit edit-icon-style" />}

              {open ? (
                <span className="icon-close" onClick={toggleOpen} />
              ) : (
                <div className="tooltip dark center-top">{tooltipContent}</div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </EditableFieldWrapper>
  )
}

EditableField.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  fieldKey: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool,
  toggleOpen: PropTypes.func
}

EditableField.defaultProps = {
  className: ''
}
export default memo(ClickOutside(EditableField)) as any
