// React/Redux Libraries \\
import React, { Component } from 'react'
import { string, func } from 'prop-types'

// Components \\
import { IconContainer } from './styles'

export class Icon extends Component {
  static propTypes = {
    /** icon type of icon */
    type: string.isRequired,

    /** margin around text */
    margin: string,

    /** padding around text */
    padding: string,

    /** color of text */
    color: string,

    /** hover color of text */
    hover: string,

    /** size of text */
    size: string,

    /** cursor of text */
    cursor: string,

    /** onClick action */
    action: func,

    /** text decoration on text */
    decoration: string,

    /** transform on the icon */
    transform: string,

    /** display of the icon */
    display: string,

    /** additional classnames on the icon */
    className: string,

    /** hardcode the height if need be */
    height: string,

    /** hardcode the height if need be */
    width: string,

    /** float of icon */
    float: string,

    /** line height of icon */
    line: string
  }

  static defaultProps = {
    margin: '0px',
    padding: '0px',
    color: '#666666',
    hover: '#7d7d7d',
    size: '14px',
    cursor: 'default',
    decoration: 'none',
    transform: 'none',
    display: 'block'
  }

  render() {
    const {
      type,
      margin,
      padding,
      color,
      hover,
      size,
      cursor,
      action,
      decoration,
      transform,
      display,
      className = '',
      height,
      width,
      float,
      line,
      transition
    } = this.props

    return (
      <IconContainer
        className={`icon-${type} ${className}`}
        margin={margin}
        padding={padding}
        color={color}
        hover={hover}
        size={size}
        cursor={cursor}
        onClick={action}
        decoration={decoration}
        transform={transform}
        transition={transition}
        display={display}
        height={height}
        width={width}
        float={float}
        line={line}
      />
    )
  }
}

export default Icon
