import { compose, defaultTo, groupBy, is, isEmpty, values } from 'ramda'

import {
  BarsInACategory,
  StackItem,
  StackItemDetail,
  StackMinMax,
  Stacks,
  StackSeries
} from './types'

/**
 *  Defines Stack Items detail
 *
 * @param name
 * @param value
 * @param rest
 * @returns
 */
function stackItemDetail(name = '', value: number, rest): StackItemDetail {
  return {
    name,
    value,
    ...rest
  }
}

/**
 *  Creates Stack Item with it's detail
 *
 * @param index
 * @param min
 * @param max
 * @param value
 * @param valueProps
 * @param name
 * @param color
 * @param series
 * @returns
 */
function stackItemModel(
  index: number,
  dataIndex: number,
  stackOrder: number,
  min: number,
  max: number,
  value: number,
  valueProps: any,
  name: string,
  color: string,
  series: any
): StackItem {
  return {
    dataIndex,
    stackOrder,
    categoryIndex: index,
    min,
    max,
    active: stackItemDetail(name, value, { ...valueProps }),
    color,
    series
  }
}

/**
 * Extracts value either from primitive value or object
 *
 * @param cv
 * @returns
 */
function extractValue(cv: any = {}) {
  if (is(Object, cv)) {
    const { value, ...valueProps } = cv
    return { value: +value, valueProps }
  }

  return { value: +cv, valueProps: null }
}

/**
 * Calculates min & max range each stack items
 *
 * @param series
 * @param categoryTotal
 * @param minMax
 * @returns
 */
function calcStackItems(
  series: any = {},
  dataIndex,
  stackOrder,
  categoryTotal,
  minMax
) {
  const data = series.data || []
  const name = series.name || []
  const color = series.color || 'steelblue'

  return data.reduce((av, cv, index) => {
    const cateogryKey = 'c-' + index

    const min = categoryTotal[cateogryKey] || 0
    if (minMax.min > min || minMax.min === null) {
      minMax.min = min
    }

    const { value, valueProps } = extractValue(cv)
    const max = min + (value || 0)

    av.push(
      stackItemModel(
        index,
        dataIndex,
        stackOrder,
        min,
        max,
        value,
        valueProps,
        name,
        color,
        series
      )
    )

    categoryTotal[cateogryKey] = max

    if (minMax.max < max || minMax.max === null) {
      minMax.max = max
    }
    return av
  }, [])
}

const stackBy = groupBy(function (data: any) {
  const score = data?.stackBy
  return score ? score : 'stack-key: ' + Math.random()
})

//@ts-ignore
const stackGroupBy = compose(
  defaultTo([]),
  values,
  stackBy
  // filter(propEq('stackedbar', 'type'))
)

/**
 * Generates data in a format required to display in UI
 *
 * @param stackSeries
 * @returns stack data, minMax value and no of bars that can be renderend in a category (for comparision) given data
 */
export default function stackDataGenerator(
  stackSeries: StackSeries = []
): [Stacks[], StackMinMax, BarsInACategory] {
  const seriesSize = stackSeries.length
  if (!seriesSize) return [[], { min: null, max: null }, 0]

  const collections = stackGroupBy(stackSeries) as Stacks[]

  const stackBucket: Stacks[] = []

  const minMax: StackMinMax = {
    min: null,
    max: null
  }

  collections.forEach((collection, dataIndex) => {
    const categoryTotal = {}

    collection.forEach((data, index) => {
      const isArray = is(Array, data)

      const d = isArray ? data[index] : data

      const isNotEmptyData = !isEmpty(data)
      if (isNotEmptyData) {
        const stackItem = calcStackItems(
          d,
          dataIndex,
          index,
          categoryTotal,
          minMax
        )
        stackBucket.push(stackItem)
      }
    })
  })

  return [stackBucket, minMax, collections.length]
}
