export const BUILDING = "building"
export const EQUIPMENT_TYPE = "equipment-type"
export const EQUIPMENT = "equipment"
export const PROPERTY = "property"


export const resizePaneDrag = (firstRef, secondRef, separator, checkedValues) => {
  var md; // remember mouse down info

  const first = firstRef?.current;
  const second = secondRef?.current;
  const element = separator?.current;
  const direction = "V";
  

  if (!element) {
    second.style.overflow = "hidden";

    if (checkedValues?.length === 4) {
      first?.removeAttribute('style');
      second?.style?.setProperty('height', '260px');
    } else if (second?.clientHeight < 260 && checkedValues?.length !== 0) {
      second?.style?.setProperty('height', ((checkedValues?.length + 68) + second?.clientHeight) + "px");
    } else if (checkedValues?.length === 0) {
      second?.style?.setProperty('height', '0px');
    }

    return;
  }

  second?.style?.setProperty('overflow-y', 'scroll');

  if (element) {
    element.onmousedown = onMouseDown;
  }

  function onMouseDown(e) {
    md = {
      e,
      offsetLeft: element?.offsetLeft ?? 0,
      offsetTop: element?.offsetTop ?? 0,
      firstWidth: first?.offsetWidth ?? 0,
      secondWidth: second?.offsetWidth ?? 0,
      firstHeight: first?.offsetHeight ?? 0,
      secondHeight: second?.offsetHeight ?? 0,
    };

    document.onmousemove = onMouseMove;
    document.onmouseup = () => {
      document.onmousemove = document.onmouseup = null;
    };
  }

  function onMouseMove(e) {
    if (direction === "H") {
      var delta = {
        x: e.clientX - (md?.e?.clientX ?? 0),
        y: e.clientY - (md?.e?.clientY ?? 0),
      };

      // Horizontal
      // Prevent negative-sized elements
      delta.x = Math.min(
        Math.max(delta.x, -md?.firstWidth ?? 0),
        md?.secondWidth ?? 0
      );

      element.style.left = (md?.offsetLeft ?? 0) + delta.x + "px";
      first.style.width = (md?.firstWidth ?? 0) + delta.x + "px";
      second.style.width = (md?.secondWidth ?? 0) - delta.x + "px";
    }

    if (direction === "V") {
      var delta = {
        x: e.clientX - (md?.e?.clientX ?? 0),
        y: e.clientY - (md?.e?.clientY ?? 0),
      };

      // Vertical
      // Prevent negative-sized elements
      delta.y = Math.min(
        Math.max(delta.y, -md?.firstHeight ?? 0),
        md?.secondHeight ?? 0
      );

      first.style.height = (md?.firstHeight ?? 0) + delta.y + "px";
      second.style.height = (md?.secondHeight ?? 0) - delta.y + "px";
    }
  }
}
