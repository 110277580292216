export const calRectWithAndColumns = (
  data = [],
  height,
  rectHeight,
  rectVPadding,
  rectHPadding,
  scale,
  verticalMargin
) => {
  const totalDataObjects = data?.length
  const availableHeight = height - verticalMargin

  const size: any =
    (totalDataObjects * (rectHeight + rectVPadding)) / availableHeight

  const numColumns = Number.isInteger(size) ? size : parseInt(size) + 1

  const rectWidth = scale?.bandwidth() / numColumns - rectHPadding
  const rectsPerColumn = Math.floor(
    availableHeight / (rectHeight + rectVPadding)
  )

  const columnsPosition = Array.from(
    { length: numColumns },
    (_, columnIndex) => {
      const xPos = columnIndex * (rectWidth + rectHPadding)
      return {
        x: xPos
      }
    }
  )

  return {
    totalDataObjects,
    columnsPosition,
    rectsPerColumn,
    rectWidth,
    numColumns
  }
}

export const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  // maximumFractionDigits: 0,
});