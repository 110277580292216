import React from "react"
import { useTranslation } from 'react-i18next'
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Container } from 'src/components/layouts/index'
import IconSvg from "src/components/Icon"
import { formatCurrency } from 'src/components/legacy/common/currency'
// import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import COLORS from 'src/components/legacy/common/colors.json'
import {
	formatSavingsView,
	formatPrice,
	showCorrectPriority
  } from 'src/components/legacy/common/opportunity.js'
import { KPI_SETTINGS } from 'src/components/legacy/components/kpi/kpi'
// import './styles.scss'
import { WhereWeGoFromHereWrapper } from './styles'
import { ACTIONS } from 'src/constants'



const TextBlock = ({ value = "", label = "" }) => <div className="text-block">
	<p>{value}</p>
	<span className="label">{label}</span>
</div>

const KPIBlock = ({ kpi = []}) => {
	const [t] = useTranslation()
	const kpiSettings = KPI_SETTINGS(t)
	const numberOfKPIs = kpi.filter(({ value }) => value).length
	const renderKPIName = (name, custom) => !custom
		&& name && `${kpiSettings.get(name).name} ${t("components:kpi>Impact")}`
		// : <ConditionalTooltip
		// 	type={ConditionalTooltip.TYPE.ICON}
		// 	conditional={true}
		// 	content={name.slice(0, 30)}
		// 	align={ConditionalTooltip.ALIGN.START}
		// 	multiline={true}>
		// 	{name && `${name} ${t("components:kpi>Impact")}`}
		// </ConditionalTooltip>

	return numberOfKPIs ? <div className="kpi-block">
		{kpi.map(({ name, custom, priority, savings, value }, index) => value && <div key={index}>
				<div className="kpi-icon-block">
					<IconSvg
						name={custom ? "truck-rolls-impact" : kpiSettings.get(name).icon}
						color={COLORS.ORANGE_6}
						hover={COLORS.ORANGE_6}
						width="35px"
						height="35px"
						viewBox="0 0 65 65"
						className="kpi-icons"
						cursor="auto"
					/>
				</div>
				<div className="kpi-name-block">
					<TextBlock label={renderKPIName(name, custom)} value={showCorrectPriority(priority, savings, t)}/>
				</div>
		</div>
		)}
	</div> : null
}

const estimatedValue = settings => {
	const { enabled: showCustomerPrice = false, value: fieldValue = null } = settings?.costSavings?.costToImplement || {}
	return !showCustomerPrice ? "-" : formatCurrency(fieldValue)
}

const estimatedLabel = (settings, t) => {
	const { priceType: fieldValue = "" } = settings?.costSavings?.costToImplement || {}
	return fieldValue ? `${formatPrice(fieldValue, t)} ${t("opportunities:Price")}` : ""
}

const savingValue = settings => {
	const { value: fieldValue = null } = settings?.costSavings?.savings || {}
	return fieldValue ? formatCurrency(fieldValue) : "-"
}

const savingLabel = (settings, t) => {
	const { tag: fieldValue = "" } = settings?.costSavings?.savings || {}
	return fieldValue
		? `Savings ${formatSavingsView(fieldValue, t).toLowerCase()}` : ""
}

const WhereWeGoFromHereView = ((props) => {
	const {containerWidth, opportunities = []} = props;
	const [t] = useTranslation()
	return (
		<>
			{!opportunities || !opportunities.length
				? <div>
					{t("opportunities:NotOpportunitiesDisplay")}
				</div>
				: <WhereWeGoFromHereWrapper data-testid="opportunities-block">
					<Container xlColumns={5} lColumns={4} mColumns={3} sColumns={1} width={containerWidth}>
					{(opportunities || [])
              .filter((opportunity) => opportunity && opportunity.name && opportunity.opportunityId && opportunity.settings)
              .map(({ opportunityId = '', name = '', settings }) => (
						<div className="opportunity-block123" key={opportunityId} data-testid={opportunityId}>
							<div className="title">
								<span className="label">Opportunity</span>
								<Link to={`/opportunities/${opportunityId}`} state={{ mode: ACTIONS.VIEW, id: opportunityId }}>{name}</Link>
							</div>
							<TextBlock label={estimatedLabel(settings, t)} value={estimatedValue(settings)}/>
							<TextBlock label={savingLabel(settings, t)} value={savingValue(settings)}/>
							<KPIBlock kpi={settings?.kpi}/>
						</div>
					))}
				</Container>
				</WhereWeGoFromHereWrapper>
			}
			</>
	)
})

WhereWeGoFromHereView.prototype = {
	containerWidth: PropTypes.number,
	opportunities: PropTypes.array.isRequired,
}

export default React.memo(WhereWeGoFromHereView)

