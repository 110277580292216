import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class DropdownContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      height: 0
    }
  }

  static propTypes = {
    opened: PropTypes.bool
  }

  static defaultProps = {
    opened: false
  }

  updateStyles = () => {
    // toggle maxHeight for slidedown transition effect
    const height =
      this.myRef && this.myRef.current ? this.myRef.current.offsetHeight : 0
    if (height !== this.state.height) {
      this.setState({ height })
    }
  }

  componentDidMount() {
    this.updateStyles()
    window.addEventListener('resize', this.updateStyles)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateStyles)
  }

  render() {
    const { opened, children } = this.props
    const { height } = this.state

    const style = {
      maxHeight: opened ? `${height}px` : ''
    }

    return (
      <div style={style}>
        <div className="menu" ref={this.myRef}>
          {children}
        </div>
      </div>
    )
  }
}
