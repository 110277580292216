export const CREATE_DASHBOARD = `
  mutation createDashboard($input:  CreateDashboardInput!) {
    createDashboard(input: $input) {
      id
    }
  }
`

export const GET_DASHBOARDS = `
query listDashboardsByUser($userId: ID!, $limit: Int) {
  listDashboardsByUser(userId:$userId, limit: $limit) {
    items {
      Description
      id
      isDefault
      isPublic
      isKeyMetric
      isVisible
      name
      userId
      keyMetricaccountId
      keyMetricbuildingId
      keyMetric1Display
      keyMetric1Name
      keyMetric1UoM
      keyMetric2Display
      keyMetric2Name
      keyMetric2UoM
      keyMetric3Display
      keyMetric3Name
      keyMetric3UoM
      keyMetric4Display
      keyMetric4Name
      keyMetric4UoM
      keyMetric5Display
      keyMetric5Name
      keyMetric5UoM
      keyMetricComparedTo
      keyMetricTimePeriod
      widgetOrder
      buildingDataCharts
    }
  }
}
`
export const GET_PUBLIC_DASHBOARDS = `query listDashboards {
    listDashboards(filter: {isPublic: {eq: true}}) {
      items {
        id
        name
        isDefault
        isPublic
        isKeyMetric
        Description
        keyMetricaccountId
        keyMetricbuildingId
        keyMetric1Display
        keyMetric1Name
        keyMetric1UoM
        keyMetric2Display
        keyMetric2Name
        keyMetric2UoM
        keyMetric3Display
        keyMetric3Name
        keyMetric3UoM
        keyMetric4Display
        keyMetric4Name
        keyMetric4UoM
        keyMetric5Display
        keyMetric5Name
        keyMetric5UoM
        keyMetricComparedTo
        keyMetricTimePeriod
        widgetOrder
      }
    }
  }
`
export const GET_PRE_CONFIGURED_DASHBOARD = `query preConfiguredDashboards {
  configByTypeAndSubtype(type: "Dashboard", subType: {eq: "Template"}) {
    items {
      value
      name
      type
      subType
    }
  }
}`

export const GET_BUILDING_DETAILS = /* GraphQL */ `
  query getBuildingDetails($id: ID!) {
    getBuilding(id: $id) {
      buildingId
      name
      accountId
      accountName
    }
  }
`

export const SEARCH_DASHBOARDS = `
query SEARCH_DASHBOARDS($filter: SearchableDashboardFilterInput
  $sort: [SearchableDashboardSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDashboardAggregationInput]) {
    searchDashboards(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      name
    }
    nextToken
  }
}
`