import styled from 'styled-components'

const comp = (theme) => theme.components._button

export const StyledButton = styled.button`
font-family:inherit;
color: ${({ theme }) => comp(theme).color};
border-radius:6px;
padding:4px 10px;
border: ${({ theme }) => ` 1px solid ${comp(theme).borderColor}`};
cursor:pointer;
background-color:  ${({ theme }) => comp(theme).backgroundColor};
    font-size: 0.75rem;
    font-weight: 900;
    line-height: 0.75rem;
    text-transform: uppercase;
    &:hover {
        color: ${({ theme }) => comp(theme)._hover.color};
        background-color:  ${({ theme }) =>
          comp(theme)._hover.backgroundColor};  
    }
    &:active {
        color: ${({ theme }) => comp(theme)._active.color};
        background-color:  ${({ theme }) =>
          comp(theme)._active.backgroundColor};  
    }
    &.disabled{
        color: ${({ theme }) => comp(theme)._disabled.color};
        background-color:  ${({ theme }) =>
          comp(theme)._disabled.backgroundColor}; 
        border-color:  ${({ theme }) => comp(theme)._disabled.borderColor}; 
        pointer-events:none;
    }
    &.link {
        background: none;
   border: none;
    color: ${(props) => props.theme.colors.WHITE};
    color: ${(props) => props.theme.colors.TBA_DARKER_GREY};
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 900;
    height: 100%;
    letter-spacing: .36px;
    line-height: 14px;
    position: relative;
    text-decoration: underline;
    text-transform: uppercase;
    }
`
