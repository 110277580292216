import styled from "styled-components";
import colors from 'src/components/legacy/common/colors.json'

const screen_grid_small = '599px'

export const NoDashboard = styled.div`
    display:flex;
    flex-direction:column;
    padding:2rem;
    background:#fff;
    margin-top:1rem;
`

export const CreateBtn = styled.button`
    background-color: #666;
    border-radius: 6px;
    color: #fff;
    font-size: 12px;
    font-weight: 900;
    height: 30px;
    letter-spacing: .36px;
    line-height: 14px;
    min-width: 80px;
    padding: 0 10px;
    text-transform: uppercase;
`

// key metric styles
export const ErrorText = styled.label`
  {
    color:${colors.RED};
    font-size: 10px;
    font-style: italic;
    height: 16px;
    letter-spacing: 0.2px;
    line-height: 16px;
    width: 100%
  }
`

 export const OrganizationWrap = styled.div`
    display: grid;
    width: 800px;
    grid-template-columns: repeat(${props => props["template-col"] || 3}, minmax(0, 1fr));
    gap : ${props => props["md-gap"] || 0}px;
    margin-top: 20px;
    @media  (max-width: ${screen_grid_small}) {
    grid-template-columns: repeat(1, minmax(0, 1fr))!important;
    gap : ${props => props["sm-gap"] || 0}px
    }
    
    @media  (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
    }
    & .modal-text-wrapper .custom-label{
    padding: 15px 0 0 0px !important;
    }
`

export const SelectWrapper = styled.div<{ width: any, isMarginLeftAdjust?: boolean }>`
  ${(props) =>
    props.width <= 633 &&
    `
  & .select-wrapper{
    align-self: center;
    max-height: 50px;
    min-width: 200px;
    max-width: none;
    position: relative;
   }
      `}
      @media  (max-width: ${screen_grid_small}) {
        & .select-wrapper{
          align-self: center;
          max-height: 50px;
          min-width: 200px;
          max-width: none;
          position: relative;
         }
      }
      &.sc-margin{
        margin-top: 15px
        }
      & .rs-wrapper{
        width:200px;
      }
      .error-text{
        color:${colors.RED};
      }
  
        ${(props) => ((props.width > 400 && props.width < 500) && props.isMarginLeftAdjust) && `margin-left: auto;`}
        ${(props) => ((props.width > 600 && props.width < 1200) && props.isMarginLeftAdjust) && `margin-left: 90px;`}
        ${(props) => ((props.width > 1200 && props.width < 1500) && props.isMarginLeftAdjust) && `margin-left: 90px;`}
        ${(props) => (props.width > 1400 && props.isMarginLeftAdjust) && `margin-left: 30px;`}
`

export const KpiBuilderWrapper = styled.div`
  margin: 0px 20px;
  .kpi-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin: 10px 0px;
    border-bottom: 2px solid ${colors.BLACK};

    &-title {
      font-size: 14px;
      font-weight: bold;
    }
    &-subtitle {
      font-size: 12px;
      font-style: italic;
    }

  }
  .kpi-selectors {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 20px;
  }
  .kpi-strip {
    position: relative;
    background: ${colors.WHITE};
  
    .additional {
      height: auto;
      min-height: 22px;
      line-height: 20px;
      margin-right: 15px;
    }
  
    .icon-edit {
      color: ${colors.TBA_DARK_GREY};
    }
  
    .edit {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 16px;
      padding: 10px 10px 5px 5px;
    }
  
    .list-container {
      position: relative;
      display: flex;
      margin-right: -20px;
      flex-wrap: wrap;
    }
  
    .cell {
      padding: 0 20px 0 0;
      margin-top: 14px;
      min-height: 84px;
      height: auto;
      max-width: 260px;
    }
  
    @include screen-max-large {.cell-5 {display: none}}
    @include screen-max-medium {.cell-4 {display: none}}
    @include screen-max-small {.cell-3 {display: none}}
    @include screen-max-extra-small {.cell-2 {display: none}}
  }
  
  .kpi-metric-toggle-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .kpi-metric-toggle-title {
      font-size: 14px;
      font-weight: bold;
      word-break: keep-all;
    }
  }
`

export const KpiEnergyMetricBuilderWrapper = styled.div`
  border: 1px solid ${colors.TBA_DARK_GREY};
  border-radius: 6px;

  .metric-display{
    text-align: center;
  }
  .kpi-energy-metric-builder{
    .kpi-energy-metric-display{
      width: 240px;
      .end-cap{
        display: none;
      }
      .data{
        overflow: hidden;
        .missing-data-block{
          margin-top: -10px;
          padding-left: 20px;
        }
      } 
      .missing-data-block{
        [class^="icon-"] {
          padding-left: 20px;          
          transform: translateX(-8%) translateY(30%);
        }
      }
      .toggle-off{
        width: 240px;
        [class^="icon-"] {
          transform: translateX(30%) translateY(30%);
        }
      }
    }
  }

  .kpi-energy-metric-builder-form {
    padding: 15px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 30px;
      right: 30px;
      border-top: 1px solid ${colors.TBA_DARK_GREY};
    }

    .form-group {
      padding-bottom: 12px;

      &:last-child {
        padding-bottom: 0;
      }

      &.disabled {
        color: ${colors.TBA_DARK_GREY};
      }
    }
    .toggle-group{
      display: flex;
      align-items: center;
    }

    .select-wrapper {
      min-width: 100px;

      .control, .select-dropdown {
        width: 100%;
        max-width: 200px;
      }
    }
  }

  .no-options {
    text-align: center;
    padding: 20px;
    height: 166px;
    font-weight: bold;
    font-size: 15px;
  }

  .kpi-error {
    border-top: 1px solid ${colors.TBA_DARK_GREY};
    padding: 15px;
    font-size: 15px;
    font-weight: bold;

    a {
      text-decoration: underline;
      color: ${colors.BLACK};
    }
  }
  .kpi-error-data-description {
    display: flex;
    margin: 10px;
    padding: 12px 10px 12px 0;
    border: 1px solid ${colors.RED};
    font-size: 13px;
    font-weight: bold;
    color: $red;
    font-style: italic;
    line-height: 1.2;
  }
`