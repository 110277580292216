import React from 'react'
import Table from '../../../components/Table/graphQLTable'
import { useNavigate } from 'react-router-dom'
import { GET_USERS, GET_USERS_LAMBDA } from '../graphql/queries'
import { pages } from 'src/pages/pages.js'
import { AccessControl, accessControlFunc } from 'src/components/accessControl'
import moment from 'moment'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { useSelector } from 'react-redux'
import { selectUserAccess, selectUserInfo } from '../../../redux/slicers/appData'
import translate, { TranslateComponent } from '../../../common/translations'

export const UserManagementTable = ({setDeactivateUserDialog, setReactivateUserDialog, refetch}) => {
  const navigate = useNavigate()
  const userAccess = useSelector(selectUserAccess)
  const userInfo = useSelector(selectUserInfo)

  const nameHeadings = [
    {
      title: <TranslateComponent>Last Name</TranslateComponent>,
      key: 'lastName',
      onDataClick: (rowData) => {
        navigate(
          pages['User Management/user-details/:id'].href.replace(':id', rowData.id)
        )
      },
      maxWidth: '120px'
    },
    {
      title: <TranslateComponent>First Name</TranslateComponent>,
      key: 'firstName',
      onDataClick: (rowData) => {
        navigate(
          pages['User Management/user-details/:id'].href.replace(':id', rowData.id)
        )
      },
      maxWidth: '120px'
    },
    {
      title: <TranslateComponent>Email</TranslateComponent>,
      key: 'email',
      onDataClick: (rowData) => {
        navigate(
          pages['User Management/user-details/:id'].href.replace(':id', rowData.id)
        )
      },
      maxWidth: '150px'
    },
    {
      title: <TranslateComponent>Created On</TranslateComponent>,
      key: 'createdAt',
      formatData: (e) => {
          return moment(e, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD")
      },
      maxWidth: '80px'
    },
    {
      title: <TranslateComponent>Status</TranslateComponent>,
      key: 'isActive',
      sortField: "isActive",
      disabled: true,
      formatData: (e) => {
        if (e === 1) {
          return "Active"
        } else if (e === 0) {
          return "Disabled"
        }
      },
      maxWidth: '80px',
      customComponent: (row)=>{
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p><TranslateComponent>{row?.isActive}</TranslateComponent></p>
          </div>
        )
      }
    },
    {
      title: <TranslateComponent>User Type</TranslateComponent>,
      key: 'type.name',
      maxWidth: '100px',
      alignRight: true,
      disabled: true,
      sortable: false,
      customComponent: (row)=>{
        return (
          <div style={{  alignItems: 'center' }}>
            <p><TranslateComponent>{row?.type.name}</TranslateComponent></p>
          </div>
        )
      }
    },
    {
      name: "actions",
      title: "",
      key: "actions",
      sortable: false,
      disabled: true,
      hoverOnlyVisible: true,
    }
  ]

  const filters = [
    {
      label: <TranslateComponent>All Types</TranslateComponent>,
      key: 'userType',
      id: 'userType',
      defaultLabel:  <TranslateComponent>All Types</TranslateComponent>,
      options: [
        { name: translate('Trane Employee'), value: 'TRANE_OR_INDEPENDENT' },
        { name: translate('Building Employee'), value: 'BUILDING_OWNER' },
        { name: translate('Contractor'), value: 'CONTRACTOR' }
      ]
    }
  ]

  return (
    <Table
      key="nameUserTable"
      header={nameHeadings}
      query={{ query: GET_USERS_LAMBDA, dataPath: 'data.listUsersByAccesses', variables: {filter: {or: userInfo?.type?.authUserTypes.map(e => ({userTypeId: {eq: e}}))}} }}
      search={true}
      showSpinner={true}
      searchFields={['lastName', 'firstName', 'email', ["firstName", "lastName"]]}
      fixedLayout={false}
      refetch={refetch}
      stringifyFields={{
        sort: true,
        filter: true
      }}
      rowControl={(e) => {
        const buttons = []
        if (accessControlFunc({id: [`tc.pages.user-management.activate`], userAccess})) {
          if (e.isActive === "Active") {
            buttons.push({
              text: <TranslateComponent>Deactivate</TranslateComponent>,
              action: (data) => {
                setDeactivateUserDialog(data)
                trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_DEACTIVATE_USER, {"location":"User List"})
              }
            })
          } else {
            buttons.push({
              text: <TranslateComponent>Activate</TranslateComponent>,
              action: (data) => {
                setReactivateUserDialog(data)
                trackEvent(USER_EVENTS.ADMIN_TOOLS.events.CLICK_ACTIVATE_USER, {"location":"User List"})
              }
            })
          }
        }
        
        return buttons
      }}
    />
  )
}

UserManagementTable.propTypes = {}

UserManagementTable.defaultProps = {}

export default UserManagementTable
