import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './label.scss'

const Label = ({ text, forElementId, disabled }) => {
  const customProps = {}
  const classes = { 'custom-label--disabled': disabled }

  if (forElementId) {
    customProps.htmlFor = forElementId
    customProps.role = 'label'
  }

  return (
    <span className={classNames('custom-label', classes)} {...customProps}>
      {text}
    </span>
  )
}

Label.propTypes = {
  forElementId: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool
}

Label.defaultProps = {
  disabled: false
}

export default Label
