// Components \\
import styled from 'styled-components'

export const IconContainer = styled.span`
  color: ${(props) => props.color && props.color};
  font-size: ${(props) => props.size && props.size};
  margin: ${(props) => props.margin && props.margin};
  padding: ${(props) => props.padding && props.padding};
  cursor: ${(props) => props.cursor && props.cursor};
  text-decoration: ${(props) => props.decoration && props.decoration};
  transform: ${(props) => props.transform && props.transform};
  transition: ${(props) => props.transition && props.transition};
  display: ${(props) => props.display && props.display};
  height: ${(props) => props.height && props.height};
  width: ${(props) => props.width && props.width};
  float: ${(props) => props.float && props.float};
  line-height: ${(props) => props.line && props.line};
  &:hover {
    color: ${(props) => props.hover && props.hover};
  }
`
