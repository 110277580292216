import AlarmsList from './AlarmsList'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import { useAlarmContext } from './AlarmContextProvider'
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  DELETE_SUBSCRIBER,
  GET_ALARM_SUBSCRIBER_BY_BUILDINGID,
  UPDATE_ALARM_SUBSCRIBER_BY_BUILDINGID
} from './graphql'
import { useEffect, useMemo, useState } from 'react'
import { MODAL_TYPE, ACTIONS } from 'src/constants'
import { AlertNotification } from 'src/components'
import Tooltip, {
  TOOLTIP_HORIZONTAL_POSITION_LEFT,
  TOOLTIP_HORIZONTAL_POSITION_RIGHT
} from 'src/components/legacy/components/tooltip/tooltip'
import AddSubscriber from './AddSubscriber'
import SetNotification from './SetNotification'
import { ToggleButton, AccountExistError } from './styles'
import IconSVG from 'src/components/Icon'
import translate from 'src/common/translations'
import { TranslateComponent } from 'src/common/translations'
const ALARM_DEACTIVATE_CONFRIMATION = 'alarm-deactivate-confirmation'
const ALARM_ACTIVATE_CONFRIMATION = 'alarm-activate-confirmation'
export const ADD_NOTIFICATION = 'add-notification'
export const ADD_SUBSCRIBER_SUCCESS = 'add-subscriber-success'
export const ADD_SUBSCRIBER_ERROR = 'add-subscriber-error'
export const DUPLICATE_CONTACT_FOUND = 'duplicate-contact-error'
export const UPDATE_SUBSCRIBER_SUCCESS = 'update-subscriber-success'
export const DELETE_SUBSCRIBER_CONFIRMATION = 'delete-subscriber-confirmation'
export const DELETE_SUBSCRIBER_SUCCESS = 'delete-subscriber-success'
const MODALTYPES = [
  MODAL_TYPE.CONFIRMATION,
  ALARM_ACTIVATE_CONFRIMATION,
  ALARM_DEACTIVATE_CONFRIMATION,
  MODAL_TYPE.ERROR,
  ADD_SUBSCRIBER_SUCCESS,
  ADD_SUBSCRIBER_ERROR,
  UPDATE_SUBSCRIBER_SUCCESS,
  DELETE_SUBSCRIBER_CONFIRMATION,
  DELETE_SUBSCRIBER_SUCCESS,
  DUPLICATE_CONTACT_FOUND
]
const AlarmContainer = () => {
    const {
    mode,
    subscriber,
    subscribersRawData,
    confirmationModalType,
    setConfirmationModalType,
    buildingId,
    getNotifications,
    testName
  } = useAlarmContext()
  const [alarmVisible, setAlarmVisible] = useState(false)
  const [buildingName, setBuildingName] = useState(null)
  const [tooltipContent, setTooltipContent] = useState('')
  const hideDeleteDialog = () => {
    if (DELETE_SUBSCRIBER_SUCCESS) getNotifications()
    setConfirmationModalType('')
  }
  const { onSubmit: deleteSubscriber, loading: deletingSubscriber } = useMutation({
    query: DELETE_SUBSCRIBER
  })

  const optionsTranslated = {
    selectBuidingMessage: translate('Please select a specific building to update Alarm notification settings.'),
    transAlarmActivate: translate('Alarm Notification Activation'),
    transAlarmDeactivate: 'Alarm Notification Deactivation',
    transAddSub: translate('Add Subscriber'),
    transEdit: translate('Edit Subscriber'),
    transDeleteSub: 'Delete Subscriber',
    transYes:'Yes',
    transNo: 'No',
    transActivate: 'Activate',
    accountExist: translate('This subscriber detail already exists with this building'),
    titleTrans: translate('Alarm Notifications (Building Contacts)'),
    tooltipTrans: translate("This page sets up routing for alarms from buildings to contact email addresses. (It is not yet fully integrated into Trane Connect notifications.)"),
    transCancel: 'CANCEL',
    transDeactivate: 'DeActivate',
    transDeleteWarn: translate('This will delete this subscriber from the current building. Do you want to continue?'),
    transSubSuccess: translate("The subscriber has been deleted."),
    transOn: translate("On"),
    transOff: translate("Off"),
    transSwitchOn: translate("Clicking this switch will turn on Alarm Notification for this building."),
    transSwitchOff: translate("Clicking this switch will turn off Alarm Notification for this building."),
    transDeactivateDesc: <><TranslateComponent>By deactivating alarm notification, the subscribers below will no longer be notified of alarms coming from</TranslateComponent> {buildingName}</>,
    transActivationDesc: <><TranslateComponent> You have activated alarm notification for</TranslateComponent> {buildingName}. <TranslateComponent>Please add subscribers and choose the alarms you wish to send.</TranslateComponent></>,
    transSubscriberAdded:  <><TranslateComponent>Subscriber </TranslateComponent> {subscriber?.firstName} {subscriber?.lastName} <TranslateComponent> has been added.</TranslateComponent></>,
    transSubscriberUpdate: <><TranslateComponent>Subscriber  </TranslateComponent> {subscriber?.firstName} {subscriber?.lastName} <TranslateComponent> has been updated successfully.</TranslateComponent></>,
    unexpectedError: translate("Sorry, something went wrong. An unexpected error has occurred."),
    error: "Error"
  }



  const { refetch: getAlarmInfo } = useQuery({
    query: GET_ALARM_SUBSCRIBER_BY_BUILDINGID,
    variables: { id: buildingId },
    disableInitialLoad: true,
    dataPath: 'data.getBuilding',
    onSuccess: (data: any) => {
      const isEnabled = Boolean(data?.alarmVisible)
      setAlarmVisible(isEnabled)
      setTooltipContent(`
       ${isEnabled ? optionsTranslated.transSwitchOff : optionsTranslated.transSwitchOn}`
      )
      setBuildingName(data?.name)
    }
  })

  const { onSubmit: updateAlarm } = useMutation({
    query: UPDATE_ALARM_SUBSCRIBER_BY_BUILDINGID,
    onSuccess: () => {
      const updatedValue = !alarmVisible
      setAlarmVisible(updatedValue)
      setTooltipContent(
        (`${updatedValue ? optionsTranslated.transSwitchOff : optionsTranslated.transSwitchOn}`)
      )
    },
    onError: () => {
      setConfirmationModalType(MODAL_TYPE.ERROR)
    }
  })

  const deleteSubscriberHandler = () => {
    if (subscriber?.id) {
      const allSettingsForContact = subscribersRawData.filter(raw => raw.contactId === subscriber.contactId)
      Promise.all(allSettingsForContact.map(a => a.id && deleteSubscriber({ input: { id: a.id } })))
      .then(()  => setConfirmationModalType(DELETE_SUBSCRIBER_SUCCESS),
      () => setConfirmationModalType(MODAL_TYPE.ERROR)
      )
    } 
  }

  const updateAlarmConfirmationHanldler = () => {
    setConfirmationModalType(
      alarmVisible ? ALARM_DEACTIVATE_CONFRIMATION : ALARM_ACTIVATE_CONFRIMATION
    )
  }

  const updateAlarmHandller = () => {
    updateAlarm({
      input: { alarmVisible: alarmVisible ? 0 : 1, id: buildingId }
    })
    setConfirmationModalType('')
  }

  const modalConfig = useMemo(
    () => ({
      heading: (() => {
        switch (confirmationModalType) {
          case ALARM_ACTIVATE_CONFRIMATION:
            return optionsTranslated.transAlarmActivate
          case ALARM_DEACTIVATE_CONFRIMATION:
            return optionsTranslated.transAlarmDeactivate
          case ADD_SUBSCRIBER_SUCCESS:
          case ADD_SUBSCRIBER_ERROR:
            return optionsTranslated.transAddSub
          case UPDATE_SUBSCRIBER_SUCCESS:
            return optionsTranslated.transEdit
          case MODAL_TYPE.ERROR:
          case DUPLICATE_CONTACT_FOUND:
              return optionsTranslated.error
          case DELETE_SUBSCRIBER_CONFIRMATION:
          case DELETE_SUBSCRIBER_SUCCESS:
            return "Delete Subscriber"
          default:
            return ''
        }
      })(),
      buttons: (() => {
        switch (confirmationModalType) {
          case DELETE_SUBSCRIBER_CONFIRMATION:
            return [
              {
                text: optionsTranslated.transYes,
                handleClick: deleteSubscriberHandler,
                type: 'valid',
                disabled: deletingSubscriber
              },
              {
                text: "CANCEL",
                handleClick: hideDeleteDialog,
                type: 'cancel',
                disabled: deletingSubscriber
              }
            ]
          case ALARM_ACTIVATE_CONFRIMATION:
            return [
              {
                text: optionsTranslated.transActivate,
                handleClick: updateAlarmHandller,
                type: 'valid'
              },
              {
                text: "CANCEL",
                handleClick: hideDeleteDialog,
                type: 'cancel'
              }
            ]
          case ALARM_DEACTIVATE_CONFRIMATION:
            return [
              {
                text: optionsTranslated.transDeactivate,
                handleClick: updateAlarmHandller,
                type: 'valid'
              },
              {
                text: optionsTranslated.transCancel,
                handleClick: hideDeleteDialog,
                type: 'cancel'
              }
            ]
          case DELETE_SUBSCRIBER_SUCCESS:
          case DUPLICATE_CONTACT_FOUND:
          case ADD_SUBSCRIBER_ERROR:
            return [
              { text: 'Close', handleClick: hideDeleteDialog, type: 'cancel' }
            ]
          default:
              return []
        }
      })(),
      handleClose: hideDeleteDialog,
      ...((DELETE_SUBSCRIBER_SUCCESS || ADD_SUBSCRIBER_SUCCESS || UPDATE_SUBSCRIBER_SUCCESS || DUPLICATE_CONTACT_FOUND) && { autoClose: 8000 })
    }),
    [confirmationModalType, deletingSubscriber]
  )

  const renderConfirmationText = (modalType) => {
    switch (modalType) {
      case DELETE_SUBSCRIBER_CONFIRMATION:
        return optionsTranslated.transDeleteWarn
      case DELETE_SUBSCRIBER_SUCCESS:
        return optionsTranslated.transSubSuccess
      case ALARM_DEACTIVATE_CONFRIMATION:
        return optionsTranslated.transDeactivateDesc
      case ALARM_ACTIVATE_CONFRIMATION:
        return optionsTranslated.transActivationDesc
      case ADD_SUBSCRIBER_SUCCESS:
        return optionsTranslated.transSubscriberAdded
      case ADD_SUBSCRIBER_ERROR:
        return (<AccountExistError><IconSVG name="error" width="18px" height="18px" />{optionsTranslated.accountExist}</AccountExistError>)
      case DUPLICATE_CONTACT_FOUND:
          return (<AccountExistError><IconSVG name="error" width="18px" height="18px" /> <TranslateComponent>The contact details already found with this email id</TranslateComponent> </AccountExistError>)
      case UPDATE_SUBSCRIBER_SUCCESS:
        return optionsTranslated.transSubscriberUpdate
      case MODAL_TYPE.ERROR:
        return optionsTranslated.unexpectedError
    }
  }

  useEffect(() => {
    if (buildingId) getAlarmInfo()
  }, [buildingId])

  return (
    <Container
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding={'0'}
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageHeader
          pageTitle={
            <div>
              {optionsTranslated.titleTrans}
              <Tooltip
                horizontalPosition={TOOLTIP_HORIZONTAL_POSITION_LEFT}
                content={optionsTranslated.tooltipTrans}
              >
                <span className="icon icon-infocircle" />
              </Tooltip>
            </div>
          }
          titleTools={
            buildingId ? (
              <Tooltip
                className="toggle-tooltip"
                horizontalPosition={TOOLTIP_HORIZONTAL_POSITION_RIGHT}
                content={alarmVisible ? optionsTranslated.transSwitchOff: optionsTranslated.transSwitchOn}
              >
                <ToggleButton data-testid = {`${testName}_toggle`} className="toggle-button">
                  <label>{optionsTranslated.transOff}</label>
                  <ToggleButtonHorizontal
                    name="alarmVisible"
                    checked={alarmVisible}
                    onChange={updateAlarmConfirmationHanldler}
                  />
                  <label>{optionsTranslated.transOn}</label>
                </ToggleButton>
              </Tooltip>
            ) : null
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        {buildingId ? (
          <AlarmsList />
        ) : (
          <AlertNotification icon="error" message={optionsTranslated.selectBuidingMessage} />
        )}
      </Content>
      {[ACTIONS.ADD,ACTIONS.EDIT].includes(mode) && <AddSubscriber />}
      {mode === ADD_NOTIFICATION && <SetNotification buildingId = {buildingId} />}
      {MODALTYPES.includes(confirmationModalType) ? (
        <Modal testName={testName} {...modalConfig}>
          {
            deletingSubscriber ? <Spinner /> : (
              <ConfirmationText>
                {renderConfirmationText(confirmationModalType)}
              </ConfirmationText>
            )
          }
          
        </Modal>
      ) : null}
    </Container>
  )
}

export default AlarmContainer
