import { PRIMARY_OFFERINGS } from "../common/primary-offerings"
import { PRIMARY_OFFERING } from "../common/properties"
import { DEFAULT_PROPERTIES_TO_SEND_BY_EVENT } from "../common"

export const USER_EVENTS = {
	DASHBOARDS: {
		categoryName: "Dashboards",
		events: {
		VIEW_DASHBOARD: "View Dashboard",
		SAVE_DASHBOARD: "Save Dashboard",
		CLICK_CREATE_DASHBOARD: "Click Create Dashboard",		
		DELETE_DASHBOARD: "Delete Dashboard",
		ADD_WIDGET: "Add Widget",
		VIEW_WIDGET_LIBRARY: "View Widget Library",
		SAVE_WIDGET: "Save Widget",
		DELETE_WIDGET: "Delete Widget",
		REMOVE_WIDGET: "Remove Widget",
		CLICK_COPY_WIDGET: "Click Copy Widget",
		CLICK_EDIT_WIDGET: "Click Edit Widget",
		CLICK_REMOVE_WIDGET: "Click Remove Widget",
		CLICK_DELETE_WIDGET: "Click Delete Widget",
		SET_DEFAULT_DASHBOARD: "Set Default Dashboard",
		CLICK_COPY_DASHBOARD: "Click Copy Dashboard",
		CLICK_DELETE_DASHBOARD: "Click Delete Dashboard",
		CLICK_EDIT_DASHBOARD: "Click Edit Dashboard",
		CLICK_ADD_WIDGET: "Click Add Widget",
		CLICK_CREATE_WIDGET: "Click Create Widget",
		RESIZE_WIDGET: "Resize Widget",
		CLICK_RESIZE_WIDGET: "Click Resize Widget",
		REPOSITION_WIDGET: "Reposition Widget"			
	}
	},
	LOGIN : {
		categoryName: "Login",
		events: {
			USER_LOGIN: "User Login",
		}
	},
	ISSUES_AND_FINDINGS: {
		categoryName: "Issues/Findings",
		events: {
			VIEW_ISSUES_AND_FINDINGS_LIST_PAGE: "View Findings List",
			VIEW_ISSUE_FINDING: "View Finding",
			CLICK_COPY_ISSUE_FINDING_BUTTON: "Click Copy Finding",
			CLICK_EDIT_ISSUE_FINDING_BUTTON: "Click Edit Finding",
			CLICK_CREATE_FINDING_BUTTON: "Click Create Finding",
			DOWNLOAD_FINDING_LIST: "Download Finding List", //Still need to handle this
			DOWNLOAD_FINDING: "Download Finding", //Still need to handle this
			DELETE_ISSUE_FINDING: "Delete Finding",
			CHANGE_ISSUE_FINDING_ASSIGNEE: "Change Finding Assignee",
			CHANGE_ISSUE_FINDING_STATUS: "Change Finding Status",
			CHANGE_ISSUE_FINDING_NEXT_STEP: "Change Finding Next Step",
			ADD_TO_OPPORTUNITY: "Add to Opportunity", //Still need to handle this
			SAVE_FINDING: "Save Finding",
			CLICK_ADD_TO_OPPORTUNITY: "Click Add to Opportunity",
		}
	},
	BASELINE_LIBRARY: {
		categoryName: "Baseline Library",
		events : {
			VIEW_BASELINE_LIBRARY_PAGE : "View Baseline Library",
			CLICK_CREATE_BASELINE: "Click Create Baseline",
			CLICK_VIEW_CHART: "Click View Chart",
			CLICK_EDIT_BASELINE: "Click Edit Baseline",
			CLICK_DELETE_BASELINE: "Click Delete Baseline",
			DELETE_BASELINE: "Delete Baseline",
			SAVE_BASELINE: "Save Baseline",
		}
	},
	BUILDING_DATA: {
		categoryName: "Building Data",
		events : {
			VIEW_BUILDING_DATA_PAGE : "View Building Data",
			CLICK_EDIT_AUTOMATED_TEST: "Click Edit Automated Test",
			VIEW_HISTORY_AUTOMATED_TEST: "View Automated Test History",
			SAVE_AUTOMATED_TEST: "Save Automated Test",
			RESET_AUTOMATED_TEST_GLOBAL: "Click Reset Automated Test To Global",
			EXPORT_AUTOMATED_TEST_SETTINGS: "Export Automated Test Settings",
			VIEW_ACTIVE_SUPPRESSIONS: "View Active Suppressions",
			VIEW_SUPPRESSION_HISTORY: "View Suppression History",
		}
	},
	OPPORTUNITIES: {
		categoryName: "Opportunities",
		events : {
			VIEW_OPPORTUNITIES_PAGE : "View Opportunity List",
			VIEW_OPPORTUNITY : "View Opportunity",
			DELETE_OPPORTUNITY : "Delete Opportunity",
			DOWNLOAD_OPPORTUNITY : "Download Opportunity",
			DOWNLOAD_OPPORTUNITY_LIST : "Download Opportunity List",
			ADD_FINDING: "Add Finding",
			CHANGE_OPPORTUNITY_ASSIGNEE : "Change Opportunity Assignee",
			CHANGE_OPPORTUNITY_NEXT_STEP: "Change Opportunity Next Step",
			CHANGE_OPPORTUNITY_STATUS: "Change Opportunity Status",
			CLICK_CREATE_OPPORTUNITY: "Click Create Opportunity",
			CLICK_COPY_OPPORTUNITY: "Click Copy Opportunity",
			CLICK_EDIT_OPPORTUNITY: "Click Edit Opportunity",
			CLICK_DELETE_OPPORTUNITY: "Click Delete Opportunity",
			SAVE_OPPORTUNITY: "Save Opportunity",
		}
	},
	NOTES: {
		categoryName: "Notes",
		events : {
			VIEW_NOTES_PAGE : "View Notes List",
			VIEW_NOTE : "View Note",
			SAVE_NOTE : "Save Note",
			DELETE_NOTE : "Delete Note",
		}
	},
	NAVIGATION: {
		categoryName: "Navigation",
		events : {
			EXPAND_DASHBOARDS : "Expand Dashboards",
			EXPAND_SERVICE : "Expand Service",
			EXPAND_ENERGY_PERFORMANCE : "Expand Energy Performance",
			EXPAND_UTILITY_MANAGEMENT : "Expand Utility Management",
			EXPAND_REPORTS: "Expand Reports",
			EXPAND_BUILDING_PERFORMANCE : "Expand Building Performance",
			EXPAND_REMOTE_ACCESS : "Expand Remote Access",
			EXPAND_BUILDING_CONFIGURATION : "Expand Building Configuration",
			EXPAND_ADMIN_TOOLS : "Expand Admin Tools",
			CLICK_CHANGE_UI: "Click Change User Interface",
		}
	},
	EVENT_MARKERS: {
		categoryName: "Event Markers",
		events : {
			VIEW_EVENT_MARKERS : "View Event Marker List",
			VIEW_EVENT_MARKER : "View Event Marker",
			DELETE_EVENT_MARKER : "Delete Event Marker",
			SAVE_EVENT_MARKER: "Save Event Marker",
		}
	},
	DOCUMENTS_REPORTS: {
		categoryName: "Documents and Reports",
		events : {
			VIEW_DOCUMENTS_REPORTS : "View Document and Report List",
			VIEW_DOCUMENT : "View Document",
			DELETE_DOCUMENT : "Delete Document",
			DOWNLOAD_DOCUMENT : "Download Document",
			SAVE_DOCUMENT: "Save Document",
			VIEW_REPORT: "View Report",
			SAVE_REPORT: "Save Report",
			SAVE_CONSULTATION: "Save Consultation",
			DELETE_REPORT : "Delete Report",
		}
	},
	ENERGY_PERFORMANCE: {
		categoryName: "Energy Performance",
		events : {
			VIEW_COST : "View Energy Cost Summary",
			VIEW_CONSUMPTION : "View Energy Consumption",
			VIEW_DEMAND : "View Electrical Demand",
			VIEW_LOAD : "View Load Analysis",
			CHANGE_CHART_UNITS: "Change Chart Units",
			CHANGE_CHART_COMPARE: "Change Chart Compared To",
			VIEW_RATE_LIST : "View Rate Structure List",
			VIEW_RATE : "View Rate Structure",
			VIEW_PORTFOLIO: "View Portfolio Scorecard",
			CHANGE_PORTFOLIO_UNITS: "Change Portfolio Units",
			CLICK_ADD_CHARGES: "Click Add Charges",
			SAVE_CHARGES: "Save Charges",
			SAVE_RATE_STRUCTURE: "Save Rate Structure",
			CLICK_CREATE_RATE_STRUCTURE: "Click Create Rate Structure",
			CLICK_EDIT_RATE_STRUCTURE: "Click Edit Rate Structure",
			CLICK_DELETE_RATE_STRUCTURE: "Click Delete Rate Structure",
			DELETE_RATE_STRUCTURE: "Delete Rate Structure",
			CLICK_DELETE_CHARGE: "Click Delete Charge",
			DELETE_CHARGE: "Delete Charge",
		}
	},
	UTILITY_MANAGEMENT: {
		categoryName: "Utility Management",
		events : {
			VIEW_BILL : "View Utility Bill",
			VIEW_ENERGY : "View Energy Intensity",
			SAVE_BUILDING_DATA_CHART: "Save Building Data Chart",
			CREATE_CALCULATED_PROP: "Create Calculated Property",
			UPLOAD_UTILITY_BILL_DATA: "Upload Utility Bill Data",
			CLICK_UPLOAD_UTILITY_BILL_DATA: "Click Upload Utility Bill Data",
			DELETE_UTILITY_BILL_DATA: "Delete Utility Bill Data",
			CLICK_DELETE_UTILITY_BILL_DATA: "Click Delete Utility Bill Data",
			DOWNLOAD_UTILITY_BILL_DATA: "Download Utility Bill Data",
			VIEW_AUTOMATED_TEST_SETTINGS: "View Automated Test Settings",
			VIEW_AUTOMATED_TEST_SUPPRESION: "View Automated Test Suppresion",
			NAVIGATE_TO_COMMAND_CENTER: "Navigate to Command Center",
			UTILITY_DATA_SWITCH: "Switch Utility Bill Data",
		}
	},
	BUILDING_SETUP: {
		categoryName: "Building Setup",
		events : {
			VIEW_BUILDING_SETUP : "View Building Setup",
			VIEW_BUILDING_LIST : "View Building List",
			CLICK_CREATE_BUILDING: "Click Create Building",
			CREATE_BUILDING: "Create Building",
			CLICK_EDIT_BUILDING: "Click Edit Building",
			ADD_DEVICE: "Add Device",
			REMOVE_DEVICE: "Remove Device",
			VIEW_ALARM_NOTIFICATION : "View Alarm Notifications",
			SAVE_BUILDING: "Save Building",
		},
	},
	EQUIPMENT_SETUP: {
		categoryName: "Equipment Setup",
		events : {
			VIEW_EQUIPMENT_LIST: "View Equipment List",
			VIEW_EQUIPMENT_SETUP: "View Equipment Setup",
			VIEW_TIS_EQUIPMENT_LIST: "View TIS Equipment List",
			VIEW_AUTOMATION_SYSTEM_LIST: "View Automation System List",
			CLICK_CREATE_TIS_EQUIPMENT: "Click Create TIS Equipment",
			CREATE_SAVE_EQUIPMENT: "Create and Save TIS Equipment",
			CREATE_EQUIPMENT: "Create TIS Equipment",
			DELETE_EQUIPMENT: "Delete Equipment",
			VIEW_EQUIPMENT_DEFINITION: "View Equipment Definition",
			VIEW_EQUIPMENT_CHARACTERISTICS: "View Equipment Characteristics",
			EDIT_EQUIPMENT_DEFINITION: "Edit Equipment Definition",
			VIEW_EQUIPMENT_DATA_MAPPING: "View Equipment Data Mapping",
			VIEW_ASSOCIATED_EQUIPMENT: "View Associated Equipment",
			VIEW_CHARACTERISTICS_CURVE: "View Characteristics Curve",
			CLICK_EDIT_ASSOCIATION: "Click Edit Association",
			CLICK_DELETE_ASSOCIATION: "Click Delete Association",
			DELETE_ASSOCIATION: "Delete Association",
			CLICK_ADD_ASSOCIATION: "Click Add Equipment Association",
			SAVE_EQUIPMENT_ASSOCIATION: "Save Equipment Association",
			CLICK_EDIT_EQUIPMENT_CHARACTERISTICS: "Click Edit Equipment Characteristics",
			SAVE_EQUIPMENT_CHARACTERISTICS: "Save Equipment Characteristics",
			CLICK_EDIT_EQUIPMENT_DATA_MAPPING: "Click Edit Equipment Data Mapping",
			SAVE_EQUIPMENT_DATA_MAPPING: "Save Equipment Data Mapping",
			CLICK_CREATE_CHARACTERTISTICS_CURVE: "Click Create Characteristics Curve",
			SAVE_CHARACTERISTICS_CURVE: "Save Characteristics Curve",
			CLICK_COPY_CHARACTERISTIC: "Click Copy Characteristic Curve",
			CLICK_EDIT_CHARACTERISTIC: "Click Edit Characteristic Curve",
			CLICK_DELETE_CHARACTERISTIC: "Click Delete Characteristic Curve",
			CLICK_HISTORY_CHARACTERISTIC: "Click History Characteristic Curve",
			DELETE_CHARACTERISTIC: "Delete Characteristic Curve",
		}
	},
	REMOTE_ACCESS: {
		categoryName: "Remote Access",
		events : {
			VIEW_DEVICE_LIST: "View Remote Access Device List",
			CLICK_CONNECT_DEVICE: "Click Connect To Device",
			CLICK_CONNECT_SERVICE_TOOLS:"Click Connect To Service Tools",
			VIEW_TRACER_SC_WEB_UI_CONNECTION_SUCCESS: "Trane SC WEB UI remote connection successful",
			VIEW_TRACER_SERVICE_TOOL_CONNECTION_SUCCESS: "Service Tools remote connection successful",
			VIEW_TRACER_SC_WEB_UI_CONNECTION_FAILURE: "Trane SC WEB UI remote connection failed",
			VIEW_TRACER_SERVICE_TOOL_CONNECTION_FAILURE: "Service Tools remote connection failed"
		}
	},
	ADMIN_TOOLS: {
		categoryName: "Admin Tools",
		events : {
			VIEW_USER_LIST: "View User List",
			VIEW_USER: "View User",
			CLICK_CREATE_USER: "Click Create User",
			CLICK_EDIT_USER: "Click Edit User",
			CLICK_DEACTIVATE_USER: "Click Deactivate User",
			CLICK_ACTIVATE_USER: "Click Activate User",
			SAVE_USER: "Save User",
			VIEW_ORGANIZATION_MANAGEMENT: "View Organization Management",
			VIEW_SERVICE_PROVIDER: "View Service Provider Management",
			VIEW_CONTRACTS_OFFERINGS: "View Contracts and Offerings",
			VIEW_ROLE_MANAGEMENT: "View Role Management",
			VIEW_PERMISSION_MANAGEMENT: "View Permission Management",
			VIEW_APPLICATION_MANAGEMENT: "View Application Management",
			VIEW_USER_TYPE_MANAGEMENT: "View User Type Management",
			VIEW_GROUP_MANAGEMENT: "View Group Management",
			VIEW_DATA_REPROCESS: "View Data Reprocess",
		}
	},
	CHARTS: {
		categoryName: "CHARTS",
		events : {
			CHANGE_DATE_RANGE: "Change Date Range",
			CHANGE_DATE: "Change Date",
			CHANGE_CHART: "Change Chart Selection",
		}
	},
}

export const PROPERTIES_TO_SEND_BY_EVENT = {
	[USER_EVENTS.ISSUES_AND_FINDINGS.events.VIEW_ISSUES_AND_FINDINGS_LIST_PAGE]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT},
	[USER_EVENTS.BASELINE_LIBRARY.events.VIEW_BASELINE_LIBRARY_PAGE]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT},
	[USER_EVENTS.BUILDING_DATA.events.VIEW_BUILDING_DATA_PAGE]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT},
	[USER_EVENTS.OPPORTUNITIES.events.VIEW_OPPORTUNITIES_PAGE]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT},
	[USER_EVENTS.NOTES.events.VIEW_NOTES_PAGE]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT},
	[USER_EVENTS.EVENT_MARKERS.events.VIEW_EVENT_MARKERS]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT},
	[USER_EVENTS.DOCUMENTS_REPORTS.events.VIEW_DOCUMENTS_REPORTS]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT},
}
