import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import validationSchema from './formValidator'
import SelectBuildings from "./select-buildings"
import { useQuery } from 'src/hooks/APIHooks'
import { ADD_ACCOUNT, GET_ACCOUNT_BY_ID, GET_BUILDINGS, UPDATE_ACCOUNT, UPDATE_BUILDING } from "./queries/queries"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Table from "src/components/Table/clientSideTable"
import styled from 'styled-components'
import { pages } from 'src/pages/pages.js'
import { useMutation } from '../../../hooks/APIHooks'
import { ACTIONS } from 'src/constants'
import IconSvg from "src/components/Icon"
import { v4 as uuid4 } from 'uuid'
import { TranslateComponent } from 'src/common/translations'


const ButtonWrapper = styled.span`
  padding-top: 24px;
`

export const OrgManagementAdd = () => {
  const {id} = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [mode, setMode] = useState(location?.state?.mode ? location?.state?.mode : id ? ACTIONS.VIEW : ACTIONS.ADD)
  const [createdId, setCreatedId] = useState(null)

  const orgInput = {
    orgName: "",
    selectedBuildings: []
  }

  useEffect(() => {
    if (createdId) {
      navigate(pages["Organization Management/:id"].href.replace(":id", createdId))
    }
  }, [createdId])

  const [buildings, setBuildings] = useState([])

  const { data: buildingData = [], refetch } = useQuery({
    query: GET_BUILDINGS,
    variables: { filter: {accountId: {eq: id}}, limit: 1000 },
    errorPolicy: 'global',
    dataPath: 'data.searchBuildings.items'
  })


  const { onSubmit: createAccount } = useMutation({
      query: ADD_ACCOUNT
  })

  const { onSubmit: updateAccount } = useMutation({
    query: UPDATE_ACCOUNT
  })

  const { onSubmit: updateBuilding } = useMutation({
    query: UPDATE_BUILDING
  })

  useEffect(() => {
    navigate(location.pathname, { replace: true })
  }, [])
  


  useEffect(() => {
    if (buildingData && buildingData.length) {
      const buildingsForSetting = buildingData.filter(b => Boolean(b)).map(b => ({...b, key: b.id, label: b.name}))
      setBuildings(buildingsForSetting)
      if (mode !== ACTIONS.ADD) {
        formik.setFieldValue("selectedBuildings", buildingsForSetting )
        formik.setFieldValue("orgName", buildingData[0].accountName)
      }
    }
  }, [buildingData])

  const formik = useFormik({
    initialValues: orgInput,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values: any) => {
      if (id) {
        if (values?.orgName !== buildingData[0]?.accountName) {
          updateAccount({
            input: {
              name: values.orgName,
              id: id
            }
          })
        }
        const existingBuildings = buildingData?.map(i => i.id) || []
        const newBuildings = values?.selectedBuildings?.map(b => b.id) || []
        const newBuildingsToAdd = buildingData[0].accountName === values.orgName ? newBuildings.filter(nb => !existingBuildings.includes(nb)) : newBuildings
        await Promise.all(await newBuildingsToAdd.map(async b => await updateBuilding({input: {id: b, accountId: id, accountName: values.orgName}}))).then(() => {
          setTimeout(() => { 
            refetch({ filter: {accountId: {eq: id}}, limit: 1000 })
            setMode(ACTIONS.VIEW)
           }, 1000);
        })
      } else {
        const uuid = uuid4();
          await createAccount({input: {name: values.orgName,id : uuid,accountId:uuid}}).then(async response => {
            await Promise.all(await values.selectedBuildings.map(async b => await updateBuilding({input: {id: b.id, accountId: response?.data?.createAccount?.id, accountName: values.orgName}}))).then(() => {
              setTimeout(() => {
                setMode(ACTIONS.VIEW)
                setCreatedId(response?.data?.createAccount?.id)
               }, 1000);
            })
          })
      }
    }
  })

  const nameHeadings = [
    {
      name: 'name',
      title: <TranslateComponent>Building Name</TranslateComponent>,
      key: 'name',
      maxWidth: '120px',
      onDataClick: (rowData) => {
        navigate(
          pages['Building Setup/:id'].href.replace(':id', rowData.id)
        )
      },
    },
    {
      name: 'address',
      title: <TranslateComponent>Address</TranslateComponent>,
      key: 'address',
      maxWidth: '120px'
    },
    {
      name: 'city',
      title:<TranslateComponent>City</TranslateComponent> ,
      key: 'city',
      maxWidth: '120px'
    },
    {
      name: 'state',
      title: <TranslateComponent>State/Region</TranslateComponent>,
      key: 'state',
      maxWidth: '120px'
    },
    {
      name: 'country',
      title: <TranslateComponent>Country</TranslateComponent>,
      key: 'country',
      maxWidth: '120px'
    },
    {
      name: "actions",
      title: "",
      key: "actions",
      sortField: false,
      disabled: true
    }
  ]

  const { values, errors } = formik

  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageHeader
          pageTitle={[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <>
              <TextInput
                style={{width: "260px"}}
                labelText="Organization Name"
                defaultValue={values?.orgName}
                name="name"
                onChange={(e => formik.setFieldValue("orgName", e.target.value))}
                hasError={errors?.['orgName']}
                errorMessage={"The name is required."}
            />
            </> : values?.orgName
          }
          titleTools={[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ?
            <ButtonWrapper>
              <Button type="primary" onClick={() => {
                formik.handleSubmit()
                //Navigate to the details page here
              }
                }>
                <TranslateComponent>Save</TranslateComponent>
              </Button>
              <Button type="secondary" onClick={() => mode === ACTIONS.EDIT ? setMode(ACTIONS.VIEW) : navigate(pages["Organization Management"].href)}>
               <TranslateComponent>Cancel</TranslateComponent> 
              </Button>
          </ButtonWrapper>
           : <IconSvg
              name={"edit"}
              margin="0px 0px 0px 10px"
              color="#666"
              hover="#666"
              width="15px"
              onClick={() => {
                // formik.handleSubmit()
                //Navigate to the details page here
                setMode(ACTIONS.EDIT)
              }}
            />
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {
        [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <div>
        <SelectBuildings
          initialValues={values?.selectedBuildings}
          onButtonClick={(e) => {
            const newSelectedBuildings = [...e]
            formik.setFieldValue("selectedBuildings", newSelectedBuildings )
            setBuildings(buildings.filter(b => !newSelectedBuildings.map(e => e.id).includes(b.id)))
          }}
          title={"Add Building to Organization"}
          description={"You must have permissions for both organizations to move buildings between organizations, adding a building to this organization will remove it from other organizations."}
          sideText={<><TranslateComponent>Create new buildings in</TranslateComponent> <Link to="/building-setup/add"><TranslateComponent>Building Setup</TranslateComponent></Link></>}
          buttonTitle={"Add"}
        />
        </div>
        : ""
      }

      <Table
            key="locationDevicesTable"
            rows={values.selectedBuildings}
            header={nameHeadings}
            search={false}
          />
      </Content>
    </Container>
  )
}
