export const SEARCH_BUILDINGS = `query SEARCH_BUILDINGS(
    $filter: SearchableBuildingFilterInput
    $limit: Int
    $from: Int
    $nextToken: String
    $sort: [SearchableBuildingSortInput]
  ) {
    searchBuildings(
      filter: $filter
      limit: $limit
      from: $from
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        accountId
        id
        name
        isActive
        createdAt
        address
        state
        deviceConnectivity
        salesOfficeDistrict
        salesOfficeCode
        salesOfficeName
        offeringCodes
      }
      nextToken
      total
    }
  }
`


export const SEARCH_BUILDINGS_SALES_OFFICE_DETAILS = /* GraphQL */ `
query SearchBuildings(
  $filter: SearchableBuildingFilterInput
) {
  searchBuildings(filter: $filter, limit: 10) {
    items {
      salesOfficeCode
      salesOfficeDistrict
      salesOfficeId
      salesOfficeName
    }
  }
}
`

export const GET_BUILDING_BY_ID = `query GET_BUILDING_BY_ID($id: ID!) {
    getBuilding(id: $id) {
      salesOfficeCity
      salesOfficeCode
      salesOfficeName
      salesOfficeDistrict
      offeringCodes
          address
          country
          lat
          long
          crmCustId
          floorArea
          postalCode
          state

          city
          name
          id
          accountId
          accountName
          salesOfficeId
          groupAdmin
          devices(filter: {isActive: {eq: 1}}) {
            items {
                name
                type
                uiDisplayName
                serial
                connectionStatus
                id
                deviceId
                isActive
            }
          }
        }
      }
  `

export const SEARCH_AGGREGATE = `query SEARCH_AGGREGATE(
      $aggregates: [SearchableBuildingAggregationInput]
      $filter: SearchableBuildingFilterInput
      $limit: Int
      $nextToken: String
      $sort: [SearchableBuildingSortInput]
    ) {
      searchBuildings(
        filter: $filter
        aggregates: $aggregates
        limit: $limit
        nextToken: $nextToken
        sort: $sort
      ) {
          aggregateItems {
            result {
              ... on SearchableAggregateBucketResult {
                __typename
                buckets {
                  doc_count
                  key
                }
              }
            }
            name
        }
      }
      }`

export const GET_LOCATION_TIMEZONE = `query GET_LOCATION_TIMEZONE($lat: String, $long: String, $timestamp: String) {
  getLocationTimezone(lat: $lat, long: $long, timestamp: $timestamp)
  }
`

export const SEARCH_SALES_OFFICES = `query SEARCH_SALES_OFFICES(
  $filter: SearchableSalesOfficeFilterInput
  $limit: Int
  $nextToken: String
  $sort: [SearchableSalesOfficeSortInput]
) {
    searchSalesOffices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        code
        name
        district
        id
      }
      nextToken
      total
    }
  }`

export const SEARCH_CONTRACTOR_COMPANIES = `query SEARCH_SALES_OFFICES(
    $filter: SearchableContractorCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sort: [SearchableContractorCompanySortInput]
  ) {
      searchContractorCompanies(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sort: $sort
      ) {
        items {
          name
          id
        }
        nextToken
        total
      }
    }`

export const GET_ALL_COUNTRIES = `query GET_ALL_COUNTRIES {
    configByType(type: "World",limit: 2000) {
      items {
            name
                        value    }
    }
  }`

export const GET_ALL_STATE_BY_COUNTRY = `query GET_ALL_STATE_BY_COUNTRY($type:String!) {
    configByTypeAndSubtype(type: $type, subType: {eq: "State"}) {
      items {  
          type  
              value 
            name
            }
            nextToken
    }
  }`

export const GET_DEVICE_ASSOCIATION = `query GET_DEVICE_ASSOCIATION($deviceId: String) {
    getDeviceAssociation(deviceId: $deviceId)
    }
  `

export const CHECK_FOR_DUPLICATE_BUILDING_NAME = `query CHECK_FOR_DUPLICATE_BUILDING_NAME($accountId:String,$buildingName:String) {
    checkDuplicateBuildingName(accountId:$accountId,buildingName:$buildingName)
  }`

export const GET_CONNECTION_TYPES = `query configByType($type:String!) {
    configByType(type: $type) {
      items{
        value
        type
        name
      }
    }
  }`
