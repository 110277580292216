import Select from 'src/components/legacy/components/select/select'
import { StyledFieldSet, StyledFieldSetWrapper } from './styles'
import { useEffect, useMemo, useState } from 'react'
import Selectrix from 'src/denali-ui/components/Selectrix'
import { NewStyles } from 'src/NewStyles'
import { SEARCH_DASHBOARDS } from './graphql'
import translate from 'src/common/translations'

const RenderFieldSet = (props) => {
  const { caption = '',  options = [],  radioProps, isDisabled, selectHandler, optionName, selectedOption} = props
  const {label,name, value, id,handleChange, selectedValue, disabled} = radioProps;
  const [publicDashboardSelectedValue, setPublicDashboardSelectedValue] = useState({ id: "", name: "" })
  const checked = useMemo(() => {
    return selectedValue == value
  },[selectedValue])

  const getPublicDashboardQueryResponseMap = (response, locationId) => {
    return response.map((e) => ({
      searchableValue: e.name,
      value: e.id,
      children: e.name,
      selected: e.id === locationId
    }))
  }

  useEffect(() => {
    if(['copy-from-shared'].includes(id))
      setPublicDashboardSelectedValue({ id: "", name: "" })
  }, [isDisabled])
  
  return (
    <NewStyles>
      <StyledFieldSet>
        <StyledFieldSetWrapper>
          <div>
            <input
              id={id}
              type="radio"
              name={name}
              checked={checked}
              value={value}
              onChange={(ev: any) => {
                handleChange(ev.target?.value)
              }}
              disabled={disabled}
            />
            <label htmlFor={id} className="secondary">
              {label}
            </label>
            {caption && <div className="note">{caption}</div>}
          </div>
          {id !== "create-from-new-dashboard" && (
            <>
              {
                ['create-from-pre-configured', 'create-from-copy-dashboard'].includes(id) ? <>
                  <Select
                    options={options}
                    onChange={(id) => {
                      selectHandler({
                        name: optionName,
                        id
                      })
                    }}
                    isDisabled={isDisabled}
                    name={optionName}
                    selectedItem={options?.find(i => i?.id === selectedOption)?.name}
                    isUserdefinedOptions={true} 
                  />

                </> : <>
                  {['copy-from-shared'].includes(id) &&
                    <Selectrix
                      placeHolder=" "
                      key={`dashboardSelectrix-${"12345"}`}
                      onChange={(value, child) => {
                        selectHandler({
                          name: "publicDashboardId",
                          id: value?.target?.value,
                          dashboardName:child.props.children 
                        })
                        setPublicDashboardSelectedValue({ id: value?.target?.value, name: child.props.children })
                      }}
                      query={SEARCH_DASHBOARDS}
                      graphQLInputs={{ filter: { isPublic: { eq: true } } }}
                      fullWidth={false}
                      multiple={false}
                      disabled={isDisabled}
                      value={publicDashboardSelectedValue?.name}
                      containerWidth={true}
                      getQueryResponseMap={(values) =>
                        getPublicDashboardQueryResponseMap(values, publicDashboardSelectedValue?.id)
                      }
                      selectClassName={'dashboardSelectInput'}
                      searchPlaceHolder={translate('Type to search for a public dashboard...')}
                      testName={`publicdashboard_dashboardsdetails_adddashboard`}
                      querySearchField={['name']}
                      showCutomOption={true}
                      showNoResultsMessage={false}

                      dataPath={'data.searchDashboards.items'}
                      nextTokenPath={'data.searchDashboards.nextToken'}
                    />
                  }
                </>
              }

            </>


          )}
        </StyledFieldSetWrapper>
      </StyledFieldSet>
    </NewStyles>
  )
}
export default RenderFieldSet
