import { WidgetPreivewStyleWrapper } from "../styles"


const WidgetSampleImagePreview = ({type}) => {
  return (
    <WidgetPreivewStyleWrapper>
        <div className="sample-image">
            <img alt="preview" src={require(`src/assets/images/${type}-sample.png`)} />
        </div>
    </WidgetPreivewStyleWrapper>
  )
}

export default WidgetSampleImagePreview