import { Input } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import styles from './table.module.scss'

export const InnerSearch = ({
  filter,
  popup,
  handleInnerScrollFade,
  setActiveScroll,
  searchTerm,
  setSearchTerm
}) => {
  return (
    <div className={styles.searchInner}>
      <FontAwesomeIcon icon={faMagnifyingGlass} />
      <Input
        id={`Search ${filter.key}`}
        placeholder="Search..."
        aria-description={`Search ${filter.key}`}
        type="search"
        onChange={(event) => {
          onInnerSearchChange(
            event,
            filter,
            popup,
            setActiveScroll,
            searchTerm,
            setSearchTerm
          )
        }}
      />
    </div>
  )

  function onInnerSearchChange(
    event,
    filter,
    popup,
    setActiveScroll,
    searchTerm,
    setSearchTerm
  ) {
    searchTerm = event.target.value.toLowerCase()
    if (searchTerm.length > 0) {
      filter.searchOptions = filter.options.filter((option) =>
        option?.name?.toLowerCase().includes(searchTerm)
      )
    } else {
      filter.searchOptions = undefined
    }

    setTimeout(() => {
      handleInnerScrollFade(popup, setActiveScroll)
    }, 0) // 0 allows the popup to render before checking if the scroll is needed

    setSearchTerm(searchTerm)
  }
}
