import { useMemo, useState } from 'react'
import clsx from 'clsx'
import './styles.scss'
import { LegendGroupTemplate } from './Template'

import { LegendCompProps, LegendsGroup } from '../types'
import { useLegendUiContext } from '../provider/LegendProvider'
import { useLegendApiContext } from '../provider/LegendApiProvider'

export default function Legend(props: LegendCompProps) {
  const { legendDetails, legendComponentProps } = useLegendUiContext()
  const legendApi = useLegendApiContext()

  const {
    legends = [],
    openAlways = false,
    hideAlways = false,
    initialOpen = false,
    title,
    className
  } = useMemo(() => {
    return {
      openAlways: false,
      hideAlways: false,
      initialOpen: true,
      title: 'Chart Legend',
      ...props,
      ...legendComponentProps,
      legends: Object.values(legendDetails).flat()
    }
  }, [props, legendComponentProps, legendDetails])

  const [isOpened, setOpen] = useState<boolean | null>(null)

  const hide = useMemo(() => {
    if (openAlways) return false

    if (hideAlways) return true

    return isOpened === null ? !initialOpen : isOpened
  }, [openAlways, initialOpen, hideAlways, isOpened])

  const condition = (s: boolean | null) => {
    const isHidden =
      openAlways || hideAlways || (s === null && initialOpen) ? true : !s
    legendApi.notifyComponentToggleStatus(!isHidden)
    return isHidden
  }

  return (
    <aside
      className={clsx('t-chart-aside-legend-container', className, {
        't-chart-minimize': hide,
        't-chart-maxmize': !hide
      })}
    >
      <div className="t-chart-legend-wrapper">
        <div className="t-chart-legend-header">
          <div className="t-chart-legend-toggle-wrapper">
            <div className="t-chart-add-on-cuve-el"></div>
            <div
              className="t-chart-legend-toggle"
              onClick={() => setOpen(condition)}
            >
              <i
                className={clsx('t-chart-arrow', {
                  't-chart-left': hide,
                  't-chart-right': !hide
                })}
              ></i>
            </div>
          </div>
          <div className="t-chart-legend-title">
            <b>{title}</b>
          </div>
        </div>
        <div className="t-chart-legend-content">
          {legends.map((legendsGroup: LegendsGroup) => {
            return (
              <LegendGroupTemplate {...legendsGroup} key={legendsGroup.uuid} />
            )
          })}
        </div>
      </div>
    </aside>
  )
}
