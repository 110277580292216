import { memo, useMemo } from 'react'
import { gridLinePosition } from './helper'
import clsx from 'clsx'
import { ticksGenerator } from '../Axis/helper'
import { calculateTickCountBasedOnDimension } from 'src/chart-library/BaseSetup/PaddingSetup/helper'

const Grid = (props: any) => {
  const {
    stroke = 'black',
    strokeWidth = 1,
    opacity = 1,
    scale,
    dataTestId,
    tickCount = 0,
    width,
    height,
    includeAxis,
    type,
    tickValues,
    scaleType,
    notD3,
    d3Ticks,
    adjustmentScale,
    ...rest
  } = props

  const ticks = useMemo(() => {
    const dimensionBasedTickCount: any = calculateTickCountBasedOnDimension(height, scaleType)
    return tickValues ? tickValues : ticksGenerator(scale, tickCount, scaleType, notD3, tickValues, dimensionBasedTickCount, d3Ticks, adjustmentScale)
  }, [scale, tickCount, tickValues, scaleType, notD3])

  return (
    <g className={clsx('trane-grid')} data-testid={dataTestId} {...rest}>
      {ticks?.map((val: any, index: number) => {
        const show =
          index === 0 || index === ticks?.length - 1
            ? includeAxis
              ? true
              : false
            : true
        if (show) {
          const { x2, y2, transform } = gridLinePosition(
            type,
            scale,
            val,
            width,
            height
          )
          return (
            <g key={index} transform={transform}>
              <line
                stroke={stroke}
                opacity={opacity}
                strokeWidth={strokeWidth}
                x2={x2}
                y2={y2}
              />
            </g>
          )
        }
      })}
    </g>
  )
}

export default memo(Grid)
