export const dark = {
  background: '{colors.white}',
  foreground: '{colors.neutral.50}',
  headline: '{colors.neutral.90}',
  iconBackground: '{colors.black}',

  darkGreen: {
    background: '#192805',
    foreground: 'rgba(255, 255, 255, .5)',
    headline: '{colors.white}',
    iconBackground: 'linear-gradient(225deg, #67ED67 -0.15%, #4FC44F 100.15%)',
  }
};

export const light = dark;