import { createContext, useContext, useEffect, useState } from 'react'
import { IAlarm } from './types'
import { IModalActionType } from 'src/constants'
import { useQuery } from 'src/hooks/APIHooks'
import { GET_SUBSCRIBERS,GET_DEVICE_BY_ID } from './graphql'
import { getSearchParams } from "src/common/helperFunctions.js"
import {getUniqueItems} from "src/common/helperFunctions"
import {isSCGType,isConnectivityModule} from "./helpers"
import {EVENT_CLASSES} from "./constants"
import Priority from 'src/components/Priority/Priority'
import { useSearchParams } from 'react-router-dom'

export const initialValues = {
  emailId: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  contactId: ''
}
const NONE = "None";
const AlarmContext = createContext<any>(null)

export const AlarmContextProvider = ({ children }: any) => {
  const [mode, setMode] = useState(null)  
  const {buildingId} = getSearchParams()
  const [searchParams] = useSearchParams()
  const [reloadEvents, setReloadEvents] = useState(false)
  const [confirmationModalType, setConfirmationModalType] =
    useState<IModalActionType>('')
  const [subscriber, setSubscriber] = useState<IAlarm>(initialValues)
  const [subscribers, setSubscribers] = useState([])
  const [subscribersRawData, setSubscribersRawData] = useState([])
  const [selectedSubscribers, setSelectedSubscribers]: any[] = useState([])

  const {refetch:getDevice} = useQuery({
    query:GET_DEVICE_BY_ID,
    disableInitialLoad:true,
    dataPath:"data.getDevice"
  })

  const addDeviceDetails = async data => {
    if(!data || data?.length === 0){
      return data
    }
    const deviceIds = getUniqueItems(data.reduce((acc,alarm) => {
      acc = acc.concat(alarm.subscribedToObj.map(sub => sub.deviceId)?.filter(a => a))
      return acc
    }
      ,[]))
    const deviceData = await Promise.all(deviceIds.map(id => getDevice({id})))
    return data.map(alarm => {  
          let subscribedTo = [];
        subscribedTo = alarm.subscribedToObj.map(sub => {
          const device = deviceData.find(device => device.id === sub.deviceId)
          const deviceName = device ? (device.name || device.serial) : "" 
          return sub.deviceId ? sub.priorities?.split(",")?.
          map(priority => 
            `${deviceName ? `${deviceName} - ` : ""}${isSCGType(device.type) || isConnectivityModule(device.type)  ? 
              (!isNaN(priority) ? EVENT_CLASSES[priority - 1 ] : priority)
              :
              priority}`)?.join(", ")
             :
              []
        },"")
      return {
        ...alarm,
        subscribedTo  : subscribedTo?.filter(a => a)?.flat().join(", "),
        subscribedToObj : alarm.subscribedToObj.map(sub => {
          const device = deviceData.find(device => device.id === sub.deviceId)
          return {
            ...sub,
            deviceData : device
          }
        } ) 
      }
     
    })
  } 

  const {
    refetch: getNotifications,
    loading: loadingSubscribers,
    responseTime
  } = useQuery({
    query: GET_SUBSCRIBERS,
    variables: {
      buildingId,
      limit: 300,
    },
    dataPath: 'data.listNotificationSettingByBuilding',
    disableInitialLoad: true,
    onSuccess: async (data: any) => {
      setSubscribersRawData(data?.items)
      const response = data?.items?.reduce((acc,item) => {
        if(acc[item.contactId]){
          const {
            settings
          } = item
          acc[item.contactId] = {
            ...acc[item.contactId] ,
            subscribedToObj: [
              ...acc[item.contactId].subscribedToObj,
              (settings?.[0]?.nPriority?.length > 0 && {
                deviceId:item.deviceId,
                priorities : settings?.[0]?.nPriority?.join(",")
            })
          ]
          }
          return acc;
        }else{
          const {
            id,
            buildingId,
            deviceId,
            settings,
            contact,
            contactId
          } = item
          acc[contactId] =  {
            subscribedToObj: [
              (settings?.[0]?.nPriority?.length > 0 && {
                deviceId:deviceId,
              priorities : settings?.[0]?.nPriority?.join(","),
              criteriaType: settings.criteriaType
            })
            ],
            buildingId,
            id,
            emailId: contact?.emailId,
            firstName: contact?.firstName,
            lastName: contact?.lastName,
            phoneNumber: contact?.phoneNumber,
            contactId
          }
          return acc;
        }
      },{})
      setSelectedSubscribers([])
      const subscribers  = await  addDeviceDetails(Object.values(response))
      setSubscribers(subscribers)
    }
  })
  useEffect(() => {
    if (buildingId) getNotifications()
  }, [buildingId, searchParams])
  return (
    <AlarmContext.Provider
      value={{
        mode,
        setMode,
        buildingId,
        reloadEvents,
        setReloadEvents,
        confirmationModalType,
        setConfirmationModalType,
        setSubscriber,
        subscriber,
        subscribers,
        subscribersRawData,
        setSubscribers,
        getNotifications,
        loadingSubscribers,
        responseTime,
        selectedSubscribers,
        setSelectedSubscribers,
        testName : "alarms-events"
      }}
    >
      {children}
    </AlarmContext.Provider>
  )
}

export const useAlarmContext = () => useContext(AlarmContext)
