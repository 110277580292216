import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import "./range-selector.scss"
import classNames from "classnames"
import { getProperValueBetween, isNum } from "src/components/legacy/common/helpers"
import i18next from "i18next"

/**
 *Example usage
 *<RangeSelector style={{width:"500px"}} precision={2} sensitivity={10} minValue={15} maxValue={45} bottomValue={20} upperValue={40} onChange={this.onChangeRange}/>
 *
 * "sensitivity" define minimal distance (as percent from the control width) to set pointer as active
 *
 * return data using the following format
 * {bottomValue, upperValue}
 */

export default class RangeSelector extends PureComponent {
	static propTypes = {
		minValue: PropTypes.number,
		maxValue: PropTypes.number,
		leftCriticalValue: PropTypes.number,
		leftCautionaryValue: PropTypes.number,
		rightCautionaryValue: PropTypes.number,
		rightCriticalValue: PropTypes.number,
		precision: PropTypes.number,
		sensitivity: PropTypes.number,
		isDisabled: PropTypes.bool,
		style: PropTypes.object,
		onChange: PropTypes.func,
	}

	static defaultProps = {
		precision: 2,
		sensitivity: 5,
		style: {},
		isDisabled: false,
		onChange: () => {},
	}

	static errorTypes = {
		minUndefined: i18next.t("components:rangeSelector>ErrorMinUndefined"),
		maxUndefined: i18next.t("components:rangeSelector>ErrorMaxUndefined"),
		leftCriticalLess: i18next.t("components:rangeSelector>ErrorLeftCriticalLess"),
		leftCautionaryLess: i18next.t("components:rangeSelector>ErrorLeftCautionaryLess"),
		rightCautionaryLess: i18next.t("components:rangeSelector>ErrorRightCautionaryLess"),
		rightCautionaryMore: i18next.t("components:rangeSelector>ErrorRightCautionaryMore"),
		rightCriticalMore: i18next.t("components:rangeSelector>ErrorRightCriticalMore"),
	}

	static externalValToPercent = (min, max, value) => {
		if (typeof value !== "undefined") {
			return 100 * ((value - min) / (max - min))
		}
	}

	static percentValToExternal = (min, max, value, precision) => {
		if (typeof value !== "undefined") {
			return Number((min + (value / 100) * (max - min)).toFixed(precision))
		}
	}

	state = {
		errors: []
	}

	getAdoptedRangeValues = () => {
		const { minValue, maxValue, leftCriticalValue, leftCautionaryValue, rightCautionaryValue, rightCriticalValue } = this.props
		const { x1, x2, x3, x4 } = this.state

		let newX1 = RangeSelector.externalValToPercent(minValue, maxValue, leftCriticalValue)
		let newX2 = RangeSelector.externalValToPercent(minValue, maxValue, leftCautionaryValue)
		let newX3 = RangeSelector.externalValToPercent(minValue, maxValue, rightCautionaryValue)
		let newX4 = RangeSelector.externalValToPercent(minValue, maxValue, rightCriticalValue)

		newX1 = (newX1 !== undefined) ? getProperValueBetween(newX1, newX2, newX3, newX4, 0, 100, "x1") : undefined
		newX2 = (newX2 !== undefined) ? getProperValueBetween(newX1, newX2, newX3, newX4, 0, 100, "x2") : undefined
		newX3 = (newX3 !== undefined) ? getProperValueBetween(newX1, newX2, newX3, newX4, 0, 100, "x3") : undefined
		newX4 = (newX4 !== undefined) ? getProperValueBetween(newX1, newX2, newX3, newX4, 0, 100, "x4") : undefined

		newX1 = (x1 !== undefined) ? x1 : newX1
		newX2 = (x2 !== undefined) ? x2 : newX2
		newX3 = (x3 !== undefined) ? x3 : newX3
		newX4 = (x4 !== undefined) ? x4 : newX4

		return {
			newX1,
			newX2,
			newX3,
			newX4
		}
	}


	componentDidUpdate(prevProps, prevState) {
		const { precision, onChange, minValue, maxValue, leftCriticalValue, leftCautionaryValue, rightCautionaryValue, rightCriticalValue } = this.props
		const {
			minValue: prevMinValue,
			maxValue: prevMaxValue,
			leftCriticalValue: prevLeftCriticalValue,
			leftCautionaryValue: prevLeftCautionaryValue,
			rightCautionaryValue: prevRightCautionaryValue,
			rightCriticalValue: prevRightCriticalValue,
		} = prevProps
		const { x1: prevX1, x2: prevX2, x3: prevX3, x4: prevX4 } = prevState
		const { newX1: x1, newX2: x2, newX3: x3, newX4: x4 } = this.getAdoptedRangeValues()
		const realX1 = RangeSelector.percentValToExternal(minValue, maxValue, x1, precision)
		const realX2 = RangeSelector.percentValToExternal(minValue, maxValue, x2, precision)
		const realX3 = RangeSelector.percentValToExternal(minValue, maxValue, x3, precision)
		const realX4 = RangeSelector.percentValToExternal(minValue, maxValue, x4, precision)

		if ((isNum(x1) && x1 !== prevX1 && realX1 !== leftCriticalValue) || (isNum(x2) && x2 !== prevX2 && realX2 !== leftCautionaryValue)
			|| (isNum(x3) && x3 !== prevX3 && realX3 !== rightCautionaryValue) || (isNum(x4) && x4 !== prevX4 && realX4 !== rightCriticalValue)) {
			onChange({
				leftCriticalValue: realX1,
				leftCautionaryValue: realX2,
				rightCautionaryValue: realX3,
				rightCriticalValue: realX4,
			})
		} else if (minValue !== prevMinValue || maxValue !== prevMaxValue
			|| (leftCriticalValue !== prevLeftCriticalValue && leftCriticalValue !== realX1)
			|| (leftCautionaryValue !== prevLeftCautionaryValue && leftCautionaryValue !== realX2)
			|| (rightCautionaryValue !== prevRightCautionaryValue && rightCautionaryValue !== realX3)
			|| (rightCriticalValue !== prevRightCriticalValue && rightCriticalValue !== realX4)) {
			// have to reset state after props have been changed
			this.setState({// eslint-disable-line
				x1: undefined,
				x2: undefined,
				x3: undefined,
				x4: undefined,
			})
		}
	}

	createElementRef = element => {
		this.rangeDiv = element
	}

	onMouseMove = e => {
		const { active } = this.state
		const { newX1: x1, newX2: x2, newX3: x3, newX4: x4 } = this.getAdoptedRangeValues()
		const position = this.rangeDiv.getBoundingClientRect()
		const x = (e.nativeEvent.offsetX / position.width) * 100
		if (active && active.length) {
			if (typeof this.prevX !== "undefined") {
				const activeItem = (this.prevX - x > 0) ? active[0] : active[active.length - 1]
				if (activeItem === "leftCritical") {
					this.setState({ x1: getProperValueBetween(x, x2, x3, x4, 0, 100, "x1") })
				} else if (activeItem === "leftCautionary") {
					this.setState({ x2: getProperValueBetween(x1, x, x3, x4, 0, 100, "x2") })
				} else if (activeItem === "rightCautionary") {
					this.setState({ x3: getProperValueBetween(x1, x2, x, x4, 0, 100, "x3") })
				} else if (activeItem === "rightCritical") {
					this.setState({ x4: getProperValueBetween(x1, x2, x3, x, 0, 100, "x4") })
				}
				if (this.firstX && Math.abs(x - this.firstX) > 1) {
					this.setState({ active: [activeItem] })
					this.firstX = undefined
				}
			} else {
				this.firstX = x
			}
			this.prevX = x
		}
	}

	onMouseDown = e => {
		const { sensitivity, leftCriticalValue, leftCautionaryValue, rightCautionaryValue, rightCriticalValue } = this.props
		const { newX1: x1, newX2: x2, newX3: x3, newX4: x4 } = this.getAdoptedRangeValues()
		const position = this.rangeDiv.getBoundingClientRect()
		const x = (e.nativeEvent.offsetX / position.width) * 100
		const x1Distance = Math.abs(x - x1)
		const x2Distance = Math.abs(x - x2)
		const x3Distance = Math.abs(x - x3)
		const x4Distance = Math.abs(x - x4)
		const active = []
		if (x1Distance <= sensitivity && typeof leftCriticalValue !== "undefined") {
			active.push("leftCritical")
		}
		if (x2Distance <= sensitivity && typeof leftCautionaryValue !== "undefined") {
			active.push("leftCautionary")
		}
		if (x3Distance <= sensitivity && typeof rightCautionaryValue !== "undefined") {
			active.push("rightCautionary")
		}
		if (x4Distance <= sensitivity && typeof rightCriticalValue !== "undefined") {
			active.push("rightCritical")
		}
		this.setState({ active })
	}

	onMouseUp = () => {
		this.setState({ active: undefined })
		this.prevX = undefined
		this.firstX = undefined
	}

	onMouseLeave = () => {
		this.setState({ active: undefined })
		this.prevX = undefined
		this.firstX = undefined
	}

	/**
	* On TouchStart we need to set active pointer base on the distance from the current touch point ans sensibility
	* if there is another touch point registered, ignore event
	* */
	onTouchStart = e => {
		const { sensitivity } = this.props
		const { identifier } = this.state
		const { newX1: x1, newX2: x2, newX3: x3, newX4: x4 } = this.getAdoptedRangeValues()
		if (typeof identifier === "undefined") {
			const position = this.rangeDiv.getBoundingClientRect()
			const { clientX, identifier } = e.nativeEvent.changedTouches[0]
			const x = ((clientX - position.x) / position.width) * 100
			const x1Distance = Math.abs(x - x1)
			const x2Distance = Math.abs(x - x2)
			const x3Distance = Math.abs(x - x3)
			const x4Distance = Math.abs(x - x4)
			const active = []
			if (x1Distance <= sensitivity && typeof leftCriticalValue !== "undefined") {
				active.push("leftCritical")
			}
			if (x2Distance <= sensitivity && typeof leftCautionaryValue !== "undefined") {
				active.push("leftCautionary")
			}
			if (x3Distance <= sensitivity && typeof rightCautionaryValue !== "undefined") {
				active.push("rightCautionary")
			}
			if (x4Distance <= sensitivity && typeof rightCriticalValue !== "undefined") {
				active.push("rightCritical")
			}
			this.setState({ active, identifier })
		}
	}

	/**
	 * if we remove "Active" finger we need to unregister active pointer
	 * */
	onTouchEnd = e => {
		const { identifier: setIdentifier } = this.state
		const { identifier: endIdentifier } = e.nativeEvent.changedTouches[0]
		if (setIdentifier === endIdentifier) {
			this.setState({ active: undefined, identifier: undefined })
		}
		this.prevX = undefined
		this.firstX = undefined
	}

	/**
	 * Moving active pointer if there is only one touch point present (we can use only one finger to control selector)
	 * */
	onTouchMove = e => {
		if (e.nativeEvent.changedTouches.length === 1) {
			const { active, identifier: setIdentifier } = this.state
			const { newX1: x1, newX2: x2, newX3: x3, newX4: x4 } = this.getAdoptedRangeValues()
			const { clientX, identifier: currentIdentifier } = e.nativeEvent.changedTouches[0]
			if (setIdentifier === currentIdentifier) {
				const position = this.rangeDiv.getBoundingClientRect()
				const x = ((clientX - position.x) / position.width) * 100
				if (active && active.length) {
					if (typeof this.prevX !== "undefined") {
						const activeItem = (this.prevX - x > 0) ? active[0] : active[active.length - 1]
						if (activeItem === "leftCritical") {
							this.setState({ x1: getProperValueBetween(x, x2, x3, x4, 0, 100, "x1") })
						} else if (activeItem === "leftCautionary") {
							this.setState({ x2: getProperValueBetween(x1, x, x3, x4, 0, 100, "x2") })
						} else if (activeItem === "rightCautionary") {
							this.setState({ x3: getProperValueBetween(x1, x2, x, x4, 0, 100, "x3") })
						} else if (activeItem === "rightCritical") {
							this.setState({ x4: getProperValueBetween(x1, x2, x3, x, 0, 100, "x4") })
						}
						if (this.firstX && Math.abs(x - this.firstX) > 1) {
							this.setState({ active: [activeItem] })
							this.firstX = undefined
						}
					} else {
						this.firstX = x
					}
					this.prevX = x
				}
			}
		}
	}


	render() {
		const { style, precision, isDisabled, minValue, maxValue, leftCriticalValue, leftCautionaryValue, rightCautionaryValue, rightCriticalValue } = this.props
		const { newX1, newX2, newX3, newX4 } = this.getAdoptedRangeValues()
		const realX1 = RangeSelector.percentValToExternal(minValue, maxValue, newX1, precision)
		const realX2 = RangeSelector.percentValToExternal(minValue, maxValue, newX2, precision)
		const realX3 = RangeSelector.percentValToExternal(minValue, maxValue, newX3, precision)
		const realX4 = RangeSelector.percentValToExternal(minValue, maxValue, newX4, precision)
		return (
			<div ref={this.createElementRef}
				className={`range-selector ${isDisabled ? "range-selector--disabled" : ""}`}
				style={style}
				onMouseLeave={(!isDisabled) ? this.onMouseLeave : undefined}
				onMouseMove={(!isDisabled) ? this.onMouseMove : undefined}
				onMouseDown={(!isDisabled) ? this.onMouseDown : undefined}
				onMouseUp={(!isDisabled) ? this.onMouseUp : undefined}
				onTouchStart={(!isDisabled) ? this.onTouchStart : undefined}
				onTouchEnd={(!isDisabled) ? this.onTouchEnd : undefined}
				onTouchMove={(!isDisabled) ? this.onTouchMove : undefined}>

				<div className="max left">
					<div>{minValue}</div>
				</div>
				<div className="max right">
					<div>{maxValue}</div>
				</div>
				<div className="range-bar-base">
					{(typeof newX2 === "undefined") ? "" : <div className="range-bar-left cautionary" style={{ right: `${100 - newX2}%` }} />}
					{(typeof newX1 === "undefined") ? "" : <div className="range-bar-left critical" style={{ right: `${100 - newX1}%` }} />}
					{(typeof newX3 === "undefined") ? "" : <div className="range-bar-right cautionary" style={{ left: `${newX3}%` }} />}
					{(typeof newX4 === "undefined") ? "" : <div className="range-bar-right critical" style={{ left: `${newX4}%` }} />}
				</div>
				{(typeof newX1 === "undefined") ? ""
					: <div className="pointer-block left down" style={{ left: `${newX1}%` }}>
						<div className="pointer critical">▼</div>
						<div className={classNames({ "has_error": Number(realX1) !== Number(leftCriticalValue) })}>{realX1}</div>
					</div>
				}
				{(typeof newX2 === "undefined") ? ""
					: <div className="pointer-block left up" style={{ left: `${newX2}%` }}>
						<div className="pointer cautionary">▲</div>
						<div className={classNames({ "has_error": Number(realX2) !== Number(leftCautionaryValue) })}>{realX2}</div>
					</div>
				}
				{(typeof newX3 === "undefined") ? ""
					: <div className="pointer-block right down" style={{ left: `${newX3}%` }}>
						<div className="pointer cautionary">▼</div>
						<div className={classNames({ "has_error": Number(realX3) !== Number(rightCautionaryValue) })}>{realX3}</div>
					</div>
				}
				{(typeof newX4 === "undefined") ? ""
					: <div className="pointer-block right up" style={{ left: `${newX4}%` }}>
						<div className="pointer critical">▲</div>
						<div className={classNames({ "has_error": Number(realX4) !== Number(rightCriticalValue) })}>{realX4}</div>
					</div>
				}
			</div>)
	}
}
