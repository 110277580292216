import moment from 'moment/moment'

export const endDateDecider = (st, en, format) => {
  const isSame = moment(st).isSame(moment(en))
  let endDate = moment(en).format(format)
  if (isSame) {
    endDate = moment(en).add(1, 'days').format(format)
  }
  return endDate
}

