import cloneDeep from 'lodash/cloneDeep'
import every from 'lodash/every'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import pickBy from 'lodash/pickBy'
import omitBy from 'lodash/omitBy'
import last from 'lodash/last'
import get from 'lodash/get'
import transform from 'lodash/transform'
import moment from 'moment'
//import { isProductionOrStaging, isBeta } from "common/config"
// import { getParams } from "common/history"

export const MIN_SEARCH_TEXT = 3
export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000

export const ACTIVE_STATUS_STATES = {
  ACTIVE: 'Active',
  SUSPENDED: 'Suspended',
  DISCONTINUED: 'Discontinued'
}

export function isTargetedElem(elem, className) {
  return elem && elem.classList.contains(className)
}

const FIXED_TCC_LINKS = {
  'traneconnect.tis-dev.net': 'tcc.tis-dev.net',
  localhost: 'tcc.tis-dev.net',
  'beta-stg.traneconnect.com': 'tcc.tis-stg.net',
  'beta.traneconnect.com': 'tcc.tis.trane.com'
}

const TRANECONNECT_DOMAINS = [
  'trane.com',
  'tis-dev.net',
  'tis-stg.net',
  'tis-prod.net'
]

const TRANECONNECT_PROD_LINK = 'https://connect.tis.trane.com'
const TRANECONNECT_STAGING_LINK = 'https://connect.tis-staging.trane.com'
const TRANECONNECT_DEV_LINK = 'https://connect.tis-dev.trane.com'

const TraneConnectLink = new Map([
  ['production', TRANECONNECT_PROD_LINK],
  ['prod', TRANECONNECT_PROD_LINK],
  ['prd', TRANECONNECT_PROD_LINK],
  ['staging', TRANECONNECT_STAGING_LINK],
  ['stage', TRANECONNECT_STAGING_LINK],
  ['stg', TRANECONNECT_STAGING_LINK],
  ['dev', TRANECONNECT_DEV_LINK],
  [undefined, TRANECONNECT_DEV_LINK]
])

export function getTraneConnectLink(env) {
  return TraneConnectLink.get(env)
}

export const isJsonString = (str) => {
  try {
    JSON.parse(str?.replace(/'/g, '"'))
  } catch (e) {
    return false
  }
  return true
}

export const isTraneDomainOrLocalhost = (url) => {
  const domain = url.split(/[/:]/)[3]
  return (
    TRANECONNECT_DOMAINS.some((item) => domain.endsWith(item)) ||
    ['localhost', '127.0.0.1'].includes(domain)
  )
}

function stopPropagation(e) {
  e.preventDefault()
  e.stopImmediatePropagation()
  return false
}

function fieldValue(obj, path, isNumeric = false) {
  const field = getField(obj, path)
  return field != null ? `${field}` : isNumeric ? -1 : ''
}

export function silenceKeyboard() {
  document.addEventListener('keydown', stopPropagation, true)
  document.addEventListener('keypress', stopPropagation, true)
  document.addEventListener('keyup', stopPropagation, true)
}

export function enableKeyboard() {
  document.removeEventListener('keydown', stopPropagation, true)
  document.removeEventListener('keypress', stopPropagation, true)
  document.removeEventListener('keyup', stopPropagation, true)
}

export function hexToRgba(hex, opacity) {
  const bigint = parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return `rgba(${r},${g},${b},${opacity})`
}

export function decimalPlaces(num) {
  const match = `${num}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
  return !match
    ? 0
    : Math.max(
        0,
        (match[1] ? match[1].length : 0) - (match[2] ? Number(match[2]) : 0)
      )
}

let handler

function outsideClick(event) {
  if (handler) {
    handler(event)
  }
}

export function addOutsideClick(fn) {
  if (handler) {
    handler()
  }
  handler = fn
}

export function removeOutsideClick() {
  handler = null
}

/*
 * Simulate click on touch devices for elements which can not be fired click on
 */
export function addOutsideClickEvent() {
  let startX, startY

  function getCoord(event, coord) {
    if (/touch/.test(event.type)) {
      return (event.originalEvent || event).changedTouches[0][`page${coord}`]
    } else {
      return event[`page${coord}`]
    }
  }

  const touchStartHandler = function (event) {
    startX = getCoord(event, 'X')
    startY = getCoord(event, 'Y')
  }
  const touchEndHandler = function (event) {
    // check if scroll is taking place
    if (
      handler &&
      Math.abs(getCoord(event, 'X') - startX) < 10 &&
      Math.abs(getCoord(event, 'Y') - startY) < 10
    ) {
      outsideClick(event)
    }
  }

  if ('ontouchstart' in window) {
    document.body.addEventListener('touchstart', touchStartHandler, false)
    document.body.addEventListener('touchend', touchEndHandler, false)
  } else {
    document.body.addEventListener('mousedown', outsideClick, true)
  }
}

export const chunks = (array, size) =>
  array.reduce((acc, _, index) => {
    if (index % size === 0) {
      acc.push(array.slice(index, index + size))
    }
    return acc
  }, [])

export function trim(input) {
  if (typeof input === 'object') {
    Object.keys(input).forEach((key) => {
      const field = input[key]
      if (typeof field == 'string') {
        input[key] = field.trim()
      }
    })
  } else if (typeof input === 'string') {
    input = input.trim()
  }
  return input
}

// returns a function that returns up to n items from an array
// n is decremented by the number of items and is remembered between calls
export function extract(n) {
  return function (array) {
    const result = array.slice(0, n > 0 ? n : 0)
    n -= array.length
    return result
  }
}

export function clone(obj) {
  try {
    return JSON.parse(JSON.stringify(obj))
  } catch (error) {}
}

export function sortByName(a, b) {
  return a.name.localeCompare(b.name)
}

export function sort(
  array,
  path,
  asc = true,
  isNumeric = false,
  customRules = false
) {
  return (
    array &&
    [...cloneDeep(array)].sort((a, b) => {
      const aPath = (isNumeric) => fieldValue(a, path, isNumeric)
      const bPath = (isNumeric) => fieldValue(b, path, isNumeric)
      const comparison = isNumeric
        ? aPath(isNumeric) - bPath(isNumeric)
        : customRules
        ? (customRules.get(aPath(true)) || 0) -
          (customRules.get(bPath(true)) || 0)
        : fieldValue(a, path).localeCompare(fieldValue(b, path), undefined, {
            numeric: true,
            sensitivity: 'base'
          })
      return comparison * (asc ? 1 : -1)
    })
  )
}

// Sort by two fields
// if base fields are the same, then sort by second field
export function sortByTwoFields(
  array,
  mainPath,
  extraPath,
  asc = true,
  isNumeric = false
) {
  if (extraPath) {
    return (
      array &&
      [...cloneDeep(array)].sort((a, b) => {
        const aMainPath = fieldValue(a, mainPath, isNumeric)
        const bMainPath = fieldValue(b, mainPath, isNumeric)
        const aExtraPath = fieldValue(a, extraPath, isNumeric)
        const bExtraPath = fieldValue(b, extraPath, isNumeric)
        const comparison = isNumeric
          ? aMainPath - bMainPath || aExtraPath - bExtraPath
          : aMainPath.localeCompare(bMainPath) ||
            aExtraPath.localeCompare(bExtraPath)
        return comparison * (asc ? 1 : -1)
      })
    )
  } else {
    return sort(array, mainPath, asc)
  }
}

// Returns a single object that matches the path. This DOES NOT handle arrays.
export function getField(obj, path) {
  if (path && typeof path.split === 'function') {
    return (
      path && path.split('.').reduce((obj, field) => obj && obj[field], obj)
    )
  }
}

// Returns an array of objects that match the path. This handles arrays.
export function getFields(obj, paths) {
  if (obj === undefined) {
    return []
  }
  if (paths && !Array.isArray(paths)) {
    paths = paths.split('.')
  }
  if (!paths || !paths.length) {
    return [obj]
  }

  const [next, ...rest] = paths
  const current = obj[next]
  if (Array.isArray(current)) {
    return current.reduce(
      (results, val) => results.concat(getFields(val, rest)),
      []
    )
  } else {
    return getFields(current, rest)
  }
}

/**
 * Check if string includes a certain value.
 *
 * @param {string} string - A string to be searched in.
 * @param {string} searchString - A string to be searched for within this string.
 * @returns {Boolean}
 */
export function stringIncludes(string = '', searchString = '') {
  return string.toLowerCase().includes(searchString.toLowerCase())
}

export function search(items = [], searchFields, searchValue) {
  const searchWords = searchValue
    .trim()
    .toLocaleLowerCase()
    .split(/\s+/)
    .filter((word) => word.length >= MIN_SEARCH_TEXT)

  function fitsSearchCriteria(string) {
    const text = string.toLocaleLowerCase()
    return searchWords.some((word) => text.includes(word))
  }

  return items.filter((item) => {
    if (searchWords.length) {
      return searchFields.some(
        (field) => item[field] && fitsSearchCriteria(item[field])
      )
    } else {
      return true
    }
  })
}

// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
export function round(number, precision) {
  const factor = Math.pow(10, precision)
  const tempNumber = number * factor
  const roundedTempNumber = Math.round(tempNumber)
  return roundedTempNumber / factor
}

export function initValidation(form) {
  const inputs = form.findAll('input')
  inputs.forEach((input) => {
    input.setAttribute('pristine', true)
    input.addEventListener('blur', blurHandler)
    input.addEventListener('keypress', keypressHandler)

    function blurHandler() {
      removePristine(input)
      input.removeEventListener('blur', blurHandler)
    }
    function keypressHandler(e) {
      if (e.keyCode === 13) {
        inputs.forEach((input) => {
          removePristine(input)
        })
      }
      input.removeEventListener('keypress', keypressHandler)
    }
  })

  function removePristine(input) {
    if (input.hasAttribute('pristine')) {
      input.removeAttribute('pristine')
    }
    if (input.value.hasOwnProperty('pristine')) {
      input.value.pristine = false
    }
  }
}

export function preloadImages(selector) {
  const PLACEHOLDER_IMAGE_CLASSNAME = '.placeholder-img'
  const elems = document.body.querySelectorAll(selector)
  for (const elem of elems) {
    elem.style.backgroundImage = 'none' // do not show original image until loaded;
    loadPlaceholderImage(elem) // show quickly lightweight placeholder image instead
    loadOriginalImage(elem) // load originals (possibly few for different screen sizes)
  }

  function loadPlaceholderImage(elem) {
    const placeholderElem = elem.querySelector(PLACEHOLDER_IMAGE_CLASSNAME)
    const img = new Image()
    const url = getImageUrl(placeholderElem)
    img.src = url
    img.onload = () => placeholderElem.classList.add('loaded')
  }
  function loadOriginalImage(elem) {
    let loadedAmount = 0
    const urls = elem.dataset.imgs.split(':')
    for (const url of urls) {
      const img = new Image()
      img.src = url
      img.onload = () => {
        if (++loadedAmount == urls.length) {
          showOriginalImage(elem)
        }
      }
    }
  }
  function showOriginalImage(elem) {
    elem.style.backgroundImage = ''
    elem.querySelector(PLACEHOLDER_IMAGE_CLASSNAME).style.opacity = '0'
  }
  function getImageUrl(elem) {
    const imgUrl = window.getComputedStyle(elem).backgroundImage
    return imgUrl.replace(/^url\(["']?/, '').replace(/["']?\)$/, '')
  }
}

/**
 * getImageDetail - get image name depend of typeOfDetail
 * imageName = "4545/cat.jpeg" or "cat.jpeg" typeOfDetail = "name" will get "cat"
 * imageName = "4545/cat.jpeg" or "cat.jpeg" typeOfDetail = "extension" will get "jpeg"
 * imageName = "4545/cat.jpeg" or "cat.jpeg" typeOfDetail = "" will get "cat.jpeg"
 * imageName = "Invalid Image Name"  will get the same string "Invalid Image Name"
 * @param imageName
 * @param typeOfDetail - "name", "extension"
 * @returns string
 */
export function getImageDetail(imageName, typeOfDetail = '') {
  const parsedImageName =
    imageName.match(/([^\/]+)\.(\w+)$/i) /* eslint-disable-line */

  switch (typeOfDetail) {
    case 'name':
      return parsedImageName ? parsedImageName[1] : imageName
    case 'extension':
      return parsedImageName ? parsedImageName[2] : imageName
    default:
      return parsedImageName ? parsedImageName[0] : imageName
  }
}

export function uri(strings, ...values) {
  return strings.reduce(
    (a, v, i) => a + v + (i in values ? encodeURIComponent(values[i]) : ''),
    ''
  )
}

export function numberWithCommas(x) {
  return Math.abs(x).toLocaleString()
}

export function updateTooltipsPosition(node) {
  // This feature update tooltips position in first 10 table rows. This height should be enough to show tooltips in table. Tooltips should not have been bigger.

  if (node) {
    if (
      node.parentElement.offsetTop - node.parentElement.offsetHeight <=
      node.offsetHeight
    ) {
      node.className = node.className.replace('top', 'bottom')
    }
  }

  return {
    teardown: () => {}
  }
}

/**
 * getIntHourFromDisplay - Gets the number value corresponding with an hour column header.
 * ***CURRENT STATUS: deprecated for the time being, as we have changed the key names throughout our application to reflect 1-24 counting for hours, rather than index-based 0-23 (which then requires this function for translation)
 * *** we are leaving in the helpers file, in case it is ever decided to revert this
 * @param {String} column 	String column header that corresponds with an hour (8 am, 4 pm, 1 pm, etc.)
 * @returns {Number}
 */
export const getIntHourFromDisplay = (column) =>
  column === '00:00' ? 23 : parseInt(column.replace(/:00/, '')) - 1

export function convertHeatMapHour(hourData) {
  const hour = hourData ? Math.floor(hourData / 4) : 0
  const minutes = (hourData % 4) * 15 || '00'

  if (!hour) {
    return `12:${minutes} AM`
  } else if (hour < 12) {
    return `${hour}:${minutes} AM`
  } else if (hour == 12) {
    return `${hour}:${minutes} PM`
  } else {
    return `${hour - 12}:${minutes} PM`
  }
}

export function convertBase64ToBlob(
  b64Data,
  contentType = 'image/png',
  sliceSize = 512
) {
  const byteCharacters = atob(b64Data)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    let byteNumbers = []
    byteNumbers = slice.split('').map((b) => b.charCodeAt())

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const buildTCC4Url = () => {
  const {
    location: { protocol, hostname, port }
  } = window
  const tccLink = FIXED_TCC_LINKS[hostname]
  if (tccLink) {
    return `${protocol}//${tccLink}`
  }
  return `${protocol}//${hostname.replace('connect', 'tcc')}${
    port ? `:${port}` : ''
  }`
}

export const formatArrayToCommaSeparatedList = (array) => {
  if (array.length > 2) {
    return `${array.slice(0, -1).join(', ')}, and ${array.slice(-1)}`
  } else if (array.length === 2) {
    return `${array[0]} and ${array[1]}`
  } else {
    return array[0] || ''
  }
}

/**
 * joinObjects - Join two or more objects together... like a SQL join
 * @param {Object} 			baseObject Object to merge other objects into
 * @param {...Object} joins 					Objects to merge into baseObject
 *																																- field: Name of the field to merge on (exclusive of Id)
 *																																- data: Data from object to merge in
 * @returns {Object}
 */
export const joinObjects = (baseObject, ...joins) => {
  const returnObject = { ...baseObject }
  joins.forEach((join = {}) => {
    returnObject[join.field] = join.data[returnObject[`${join.field}Id`]]
  })
  return returnObject
}

/**
 * normalizeList - Normalizes list by provided property
 *
 * @param list  - List to normalize
 * @param property  - Normalized key. ex: "key" or "key1.key2"
 * @param handler   - Handler is used to process list item. Not necessary.
 * @returns {Object} normalized object
 *
 * Example:
 *      const list = [{id: 10, name: 'a'}, {id: 20, name: 'b'}];
 *      const normalizedObject = normalizeList(list, 'id');
 *
 *      normalizedObject = {
 *          "10": {id: 10, name: 'a'},
 *          "20": {id: 20, name: 'b'}
 *      }
 *
 */
export const normalizeList = (list, property, handler = null) =>
  list.reduce(
    (accum, item) =>
      Object.assign(accum, {
        [getField(item, property)]: handler ? handler(item) : item
      }),
    {}
  )

export const deNormalizeList = (list) =>
  list ? Object.keys(list).map((key) => list[key]) : []

/**
 * isNum - return true if value is a non-infinite javascript number.
 * @param val - Any
 * @returns Boolean
 *
 * Example:
 *      const val = isNum("1");
 *      val = true;
 *      val = isNum(2);
 *      val = true;
 *      val = isNum("+");
 *      val = false;
 */
export const isNum = (val) => !isNaN(parseFloat(val)) && isFinite(val)

/**
 * maxWONulls - return maximum value
 * @param val1 - Numeric or null or undefined
 * @param val2 - Numeric or null or undefined
 * @param val3 - Numeric or null or undefined
 * @returns Numeric max value from (val1, val2, val3)
 *
 * Example:
 *      const vals = [1, 4, undefined, 10];
 *      const maxVal1 = maxWONulls(...vals);
 *      const maxVal2 = maxWONulls(2, 3, null);
 *      maxVal1 = 10;
 *      maxVal2 = 3;
 */
export const maxWONulls = (...values) =>
  values.length
    ? Math.max(...values.filter((value) => isNum(value)))
    : undefined

/**
 * minWONulls - return maximum value
 * @param val1 - Numeric or null or undefined
 * @param val2 - Numeric or null or undefined
 * @param val3 - Numeric or null or undefined
 * @returns Numeric min value from (val1, val2, val3)
 *
 * Example:
 *      const vals = [1, 4, undefined, 10];
 *      const maxVal1 = minWONulls(...vals);
 *      const maxVal2 = minWONulls(2, 3, null);
 *      maxVal1 = 1;
 *      maxVal2 = 2;
 */
export const minWONulls = (...values) =>
  values.length
    ? Math.min(...values.filter((value) => isNum(value)))
    : undefined

/**
 * getProperValueBetween - return min<x1<x2<x3<x4<max
 * @param x1-x4 - Numeric or null or undefined
 * @param min - Numeric
 * @param max - Numeric or null or undefined
 * @param Mode - String "x1", "x2", "x3", "x4"
 * @returns Numeric
 *
 * Example:
 *      const val = getProperValueBetween(10, 5, 30, 40, 0, 100, "x1")
 *      val = 10;
 *      val = getProperValueBetween(10, 35, 30, 40, 0, 100, "x1")
 *      val = 30;
 */
export const getProperValueBetween = (x1, x2, x3, x4, min, max, mode) => {
  let value = undefined
  switch (mode) {
    case 'x1':
      value = maxWONulls(min, minWONulls(x1, x2, x3, x4, max))
      break
    case 'x2':
      value = maxWONulls(min, x1, minWONulls(x2, x3, x4, max))
      break
    case 'x3':
      value = maxWONulls(min, x1, x2, minWONulls(x3, x4, max))
      break
    case 'x4':
      value = maxWONulls(min, x1, x2, x3, minWONulls(x4, max))
      break
  }
  return value
}

export const toTitleCase = (str) => {
  if (!str) {
    return
  }
  const smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i
  return str
    .replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, (match, index, title) => {
      if (
        index > 0 &&
        index + match.length !== title.length &&
        match.search(smallWords) > -1 &&
        title.charAt(index - 2) !== ':' &&
        (title.charAt(index + match.length) !== '-' ||
          title.charAt(index - 1) === '-') &&
        title.charAt(index - 1).search(/[^\s-]/) < 0
      ) {
        return match.toLowerCase()
      }
      if (match.substr(1).search(/[A-Z]|\../) > -1) {
        return match
      }
      return match.charAt(0).toUpperCase() + match.substr(1)
    })
    .replace(/-/g, ' ')
}

/**
 * setField - Set Nested Objects
 *
 * @param object
 * @param path
 * @param value
 * @returns {Object}
 *
 * Example:
 *      const obj = {}
 *      setField(obj, "prop1.prop2.prop3", "testProp")
 *
 *      obj = { prop1: { prop2: { prop3: "testProp" } }
 *
 */
export const setField = (object, path, value) => {
  const objectify = (splits, object) => {
    for (let i = 0, property; object && (property = splits[i]); i++) {
      object = property in object ? object[property] : (object[property] = {})
    }
    return object
  }
  const splits = path.split('.')
  const property = splits.pop()
  const result = objectify(splits, object)

  result && property && (result[property] = value)

  return object
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Boolean}       Return boolean value TRUE if object has different values
 */
export function isNotDifferent(object, base) {
  return every(object, (value, key) => {
    if (!isEqual(value, base[key])) {
      if (isObject(value) && isObject(base[key])) {
        return isNotDifferent(value, base[key])
      } else {
        return false
      }
    }
    return true
  })
}

/**
 * Deep pick from object which can use nested paths
 * Works like lodash pick but includes possibility to use keys like "a.b.c"
 * @param  {Object} object Object to pick from
 * @param  {Array} props   Paths
 * @return {Object}        Return new object with picked values
 */
export function pickDeep(obj, props) {
  const result = {}
  props.forEach((prop) => {
    if (typeof prop === 'string') {
      const path = prop.split('.')
      result[last(path)] = get(obj, prop)
    } else if (Array.isArray(prop)) {
      const [newKey, rawPath] = prop
      result[newKey] = get(obj, rawPath)
    }
  })
  return result
}

/**
 * Filter object by taking either includeKeys or excludeKeys value, doesn't work with nested keys
 * @param  {Object} obj Object filtered
 * @param  {Array} includeKeys Keys of obj that will remain
 * @param  {Array} excludeKeys Keys of obj that will be filtered out
 * @return {Object} Returns filtered object
 */

export function filterObjectFields({ obj, includeKeys, excludeKeys }) {
  if (includeKeys === undefined && Array.isArray(excludeKeys)) {
    return omitBy(obj, (value, key) => excludeKeys.includes(key))
  } else if (Array.isArray(includeKeys) && excludeKeys === undefined) {
    return pickBy(obj, (value, key) => includeKeys.includes(key))
  }
  return obj
}

export function addressStrFromLocationSummary(address) {
  const addressString = [
    `${address.city},`,
    address.region,
    address.postalCode
  ].join(' ')
  return addressString.replace(/,$/, '') // remove comma if exists at the end
}

/**
 * Call function and return result asynchronously as a Promise
 * @param {Function} f
 * @returns {Promise}
 */
export function promisifyResult(f) {
  // Ensure the argument is a function
  if (typeof f !== 'function') {
    return Promise.reject(
      new TypeError('Argument to promisify must be a function')
    )
  }

  let result, error

  try {
    result = f()
  } catch (e) {
    error = e
  }
  return new Promise((resolve, reject) => {
    error ? reject(error) : resolve(result)
  })
}

/**
 * Check if object has only empty properties
 * @param  {Object} obj Object checked
 * @return {Boolean} Returns true or false
 */

export function hasEmptyProperties(obj) {
  return Object.values(obj).every((val) => !val)
}

/**
 * Return object, which has clone deep properties by path and has changed ended value
 * @param  {Object} obj Object, which we copy
 * @param  {String} path Whole path to property's value
 * @param  {Any} value Ended value, which we'll change
 * @return {Object} Returns object with deep copied properties by path
 */

export function updateNestedValue(obj, path, value) {
  const pathArr = path.split('.')
  let link
  const newObj = (link = { ...obj })

  pathArr.forEach((key, i, arr) => {
    if (arr.length - 1 == i) {
      // last item
      link[key] = value
    } else {
      link = link[key] = { ...link[key] }
    }
  })
  return newObj
}

/**

 * Check id date in appropriate format
 * @param  {String} value Date in correct format
 * @param  {String} format Format of date
 * @return {Boolean} Returns true or false
 */

export function isDateFormatCorrect(value, format) {
  return (
    moment(value, format).format(format) === value ||
    !value ||
    value.length === 0
  )
}

export const isUndefined = (data) =>
  /undefined/.test(typeof data) || data === undefined

export const isNull = (data) => data === null

export const strToBool = (data) =>
  data.toLowerCase() === 'true'
    ? true
    : data.toLowerCase() === 'false'
    ? false
    : data

/*
 * Iterates over elements of map collection, returning a new map of all elements predicate returns truthy for.
 * @param {Function} f
 * @param  {Map} mapCollection The collection to iterate over
 * @param  {Function} filterFunc The function invoked per iteration.
 * @returns {Map} Returns the new filtered map.
 */

export function filterMap(mapCollection, filterFunc) {
  const newMap = new Map()

  for (const item of mapCollection) {
    if (filterFunc(item)) {
      newMap.set(...item)
    }
  }
  return newMap
}

/*
 * Convert string to a valid number. We want to avoid numbers like "0123"
 * @param {String} str - String, which will be convert to a valid number
 * @returns {Number} Valid number in string type (for inputs)
 */

export function convertStringToValidNumber(str) {
  // we need to avoid losing numbers after decimal point
  const decimal = str && str.toString().split('.')[1]
  return str
    ? decimal
      ? parseFloat(str).toFixed(decimal.length || 0)
      : str
    : ''
}

export function isNumberValue(value) {
  const re = /^[0-9\b]+$/
  return value === '' || re.test(value)
}

/**
 * Generating the alphabet
 * @returns {string[]}
 */
export function getAlphabetArray() {
  return '#abcdefghijklmnopqrstuvwxyz'.split('')
}

export const downloadFile = (url, fileName) => {
  const element = document.createElement('a')
  document.body.appendChild(element)
  element.style = 'display: none;'
  element.href = url
  element.download = fileName
  element.click()
  document.body.removeChild(element)

  /* for downloading file in Microsoft Edge */
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(url, fileName)
  }

  window.URL.revokeObjectURL(url)
}

export const isObjectUrl = (url) => url && url.startsWith('blob:')

/**
 * The method remove all keys into object
 * @param obj
 * @param key
 *@returns {*} objects without keys inside
 */
const removeDeepKeysFromTheOriginalObject = (obj, key) => {
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue
    }
    if (typeof obj[i] === 'object' && obj[i] !== null) {
      removeDeepKeysFromTheOriginalObject(obj[i], key)
    } else if (i === key && obj[i] !== undefined) {
      delete obj[i]
    }
  }
  return obj
}

export const removeDeepKeys = (obj, key) => {
  const newObj = cloneDeep(obj)
  return removeDeepKeysFromTheOriginalObject(newObj, key)
}

/**
 * The method return string with valid array' strings and join it by ', '
 * @param arrStrings
 * @returns {String}
 */
export const setStringFromStringItemsOfArray = (arrStrings) =>
  arrStrings
    .filter((a) => typeof a === 'string' || a instanceof String)
    .join(', ')

/**
 * Temporary check for offerings feature
 * Need to remove after implementing Offfering feature
 * TODO: REMOVE
 */
// export const temporaryCheckForOffering = () => !isProductionOrStaging()

// Only show VM baselines in dev, beta stage, and beta prod until we are ready to release VM baseline functionality
// export const allowVMBaselines = () => !isProductionOrStaging() || isBeta()

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object representing the diff
 */
export const deepDiff = (object, base) =>
  transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? deepDiff(value, base[key])
          : value
    }
  })

function _deepKeys(obj, stack, parent, intermediate) {
  Object.keys(obj).forEach((el) => {
    // Escape . in the element name
    const escaped = el.replace(/\./g, '\\.')
    // If it's a nested object
    if (
      isObject(obj[el]) &&
      !(obj[el] instanceof Date) &&
      !Array.isArray(obj[el])
    ) {
      // Concatenate the new parent if exist
      const p = parent ? `${parent}.${escaped}` : parent
      // Push intermediate parent key if flag is true
      if (intermediate) {
        stack.push(parent ? p : escaped)
      }
      _deepKeys(obj[el], stack, p || escaped, intermediate)
    } else {
      // Create and save the key
      const key = parent ? `${parent}.${escaped}` : escaped
      stack.push(key)
    }
  })

  return stack
}

/**
 * @description
 * Get an object, and return an array composed of it's properties names(nested too).
 * With intermediate equals to true, we include also the intermediate parent keys into the result
 * @param obj {Object}
 * @param intermediate {Boolean}
 * @returns {Array}
 * @example
 * deepKeys({ a:1, b: { c:2, d: { e: 3 } } }) ==> ["a", "b.c", "b.d.e"]
 * @example
 * deepKeys({ b: { c:2, d: { e: 3 } } }, true) ==> ["b", "b.c", "b.d", "b.d.e"]
 * @example
 * deepKeys({ 'a.': { b: 1 }) ==> ["a\..b"]
 */
export const deepKeys = (obj, intermediate = false) =>
  _deepKeys(obj, [], null, intermediate)

/**
 * Check if address bar includes all of passed params
 *
 * @return boolean
 */

// export function queryParamsAreIncluded(array) {
// 	const queryParams = getParams()
// 	return array.every(key => Object.keys(queryParams).includes(key))
// }

/**
 * Peforms reordering of element inside array
 * @param  {Array} list Array to reorder in
 * @param  {number} startIndex original position of element
 * @param  {number} endIndex desired position of element
 * @return {Array} Return array with reordered completed
 */
export function reorderInList(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * The method return keys where values is not match into firstObject and secondObject objects
 * @param firstObject - Object
 * @param secondObject - Object
 * @param fields - Array of keys
 * @returns {Array} Return array of keys which values not equal
 */
export function getNotEqualFieldsValue(firstObject, secondObject, fields) {
  firstObject = removeDeepKeys(firstObject, '__typename')
  secondObject = removeDeepKeys(secondObject, '__typename')
  return fields.filter(
    (f) => !isEqual(get(firstObject, f, null), get(secondObject, f, null))
  )
}

// Build a worker from anonymous function body
export function createWebWorker(funcObj) {
  if (
    typeof URL === 'undefined' ||
    typeof Blob === 'undefined' ||
    typeof Worker === 'undefined'
  ) {
    return
  }
  const blobURL = URL.createObjectURL(
    new Blob(['(', funcObj.toString(), ')()'], {
      type: 'application/javascript'
    })
  )
  const worker = new Worker(blobURL)
  URL.revokeObjectURL(blobURL)

  return worker
}

export function timeoutWorker() {
  const timers = {}

  function fireTimeout(id) {
    this.postMessage({ id })
    delete timers[id]
  }

  this.addEventListener('message', (evt) => {
    const { id, command, timeout = 0 } = evt.data
    const time = parseInt(timeout, 10)
    let timer

    switch (command) {
      case 'setTimeout':
        timer = setTimeout(fireTimeout.bind(null, id), time)
        timers[id] = timer
        break
      case 'clearTimeout':
        timer = timers[id]
        if (timer) {
          clearTimeout(timer)
        }
        delete timers[id]
    }
  })
}

export function intervalWorker() {
  const timers = {}

  function fireInterval(id) {
    this.postMessage({ id })
  }

  this.addEventListener('message', (evt) => {
    const { id, command, interval = 0 } = evt.data
    const time = parseInt(interval, 10)
    let timer

    switch (command) {
      case 'setInterval':
        timer = setInterval(fireInterval.bind(null, id), time)
        timers[id] = timer
        break
      case 'clearInterval':
        timer = timers[id]
        if (timer) {
          clearInterval(timer)
        }
        delete timers[id]
    }
  })
}

export function parseJwt(token) {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    )

    return JSON.parse(jsonPayload)
  } catch (error) {}
}

// Repeat the function n times with provided interval
export function setIntervalX(callback, delay, repetitions) {
  let x = 0
  if (
    [delay, repetitions].some((val) => !isNum(val) || val < 0) ||
    repetitions === 0
  ) {
    return
  }
  const intervalID = window.setInterval(() => {
    callback()
    if (++x === repetitions) {
      window.clearInterval(intervalID)
    }
  }, delay)
  return intervalID
}

export async function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

/**
 * The method sets all required Formik fields touched
 * @param field - Object
 * @param  setFieldTouched - Function
 * @returns void
 */
export function setRequiredFieldsTouched(fields, setFieldTouched) {
  Object.keys(fields).map((key) => {
    if (
      typeof fields[key] === 'object' &&
      Object.getOwnPropertyNames(fields[key]).length
    ) {
      setRequiredFieldsTouched(fields[key], setFieldTouched)
    } else {
      setFieldTouched(key, true)
    }
  })
}

export function getUniqueItems(collection) {
  return Array.from(new Set(collection).values())
}

export function copyTextToClipboard(str, el = document.body) {
  const tmp = document.createElement('textarea')
  tmp.value = str
  tmp.style.height = '0'
  tmp.style.overflow = 'hidden'
  tmp.style.position = 'fixed'
  el.appendChild(tmp)
  tmp.focus()
  tmp.select()
  document.execCommand('copy')
  el.removeChild(tmp)
}

/**
 * Get all URL without domain (pathname + query)
 * @returns {string}
 */
export function getAllURLWithoutDomain() {
  return window.location.href.replace(window.location.origin, '')
}

/**
 * Check if value is defined.
 * Returns false if value is one of the following: null | undefined | empty string "" | NaN | false.
 * @param value
 * @returns {Boolean}
 */
export function isDefined(value) {
  return Boolean(value) || value === 0
}

/**
 * Check if value resolution is correct.
 * @param {string} value
 * @param {string} resolution
 * @returns {Boolean}
 */
export function isNumberResolutionCorrect(value, resolution) {
  try {
    const hasValueDecimalPoint = value?.includes('.')
    const hasResolutionDecimalPoint = resolution?.includes('.')

    if (hasValueDecimalPoint && !hasResolutionDecimalPoint) {
      return false
    }

    const [, valueDecimals = ''] = value?.split('.') ?? []
    const [, resolutionDecimals = ''] = resolution?.split('.') ?? []

    // Allow the whole numbers or numbers with resolution lower or equal than resolution.
    if (valueDecimals.length > resolutionDecimals.length) {
      return false
    }

    return true
  } catch (e) {
    console.log(e)
    return false
  }
}

// Function which convert data-url to blob
export function dataURLtoBlob(dataUrl) {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export function dataURLtoFile(dataUrl) {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  const blob = new Blob([u8arr], { type: mime })
  return new File([blob], 'abcd.png', {
    type: 'image/png',
    lastModified: Date.now()
  })
}

/**
 * Call function with nested object and return result as flatten object
 * @param {object} data
 * @returns {object}
 */
export function flattenNestedObject(data) {
  const result = {}
  for (const i in data) {
    if (typeof data[i] === 'object') {
      const temp = flattenNestedObject(data[i])
      for (const j in temp) {
        result[`${i}_${j}`] = temp[j]
      }
    } else {
      result[i] = data[i]
    }
  }
  return result
}

export const promiseWithErrorHandler = async (
  promise,
  params = [],
  errorCallBack = () => {}
) => {
  if (!promise || typeof promise !== 'function') {
    return
  }
  return promise(...params).then(
    (data) => data,
    (err) => errorCallBack && errorCallBack(err)
  )
}

export const awaitWithErrorHandler = async (
  promise,
  params = [],
  errorCallBack = () => {}
) => {
  if (!promise || typeof promise !== 'function') {
    return
  }
  try {
    return await promise(...params)
  } catch (e) {
    errorCallBack && errorCallBack(e)
  }
}

export const getUrlSearchParamValue = (locationId, orgId) => {
  const search =
    locationId && !orgId
      ? `?location=${locationId}`
      : !locationId && orgId
      ? `?organization=${orgId}`
      : locationId && orgId
      ? `?location=${locationId}&organization=${orgId}`
      : ''
  return search ?? ''
}
