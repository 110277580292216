import Container from 'src/components/Container'
import Content from 'src/components/Content'
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'
import PageHeader from '../../components/pageHeaderNew/header'
import DocumentsAndReportsList from './DocumentsAndReportsList'
import { Button as LegacyButton } from 'src/components/inputs/button'
import {
  ButtonWrapper,
  FilterIcon,
  StyledCreatDropdown,
  TitleToolsWrapper
} from './styles'
import { useTranslation } from 'react-i18next'
import Dropdown from 'src/components/legacy/components/dropdown'
import DropdownContainer from 'src/components/legacy/components/dropdown/dropdown-container'
import Tooltip, {
  TOOLTIP_HORIZONTAL_POSITION_LEFT
} from 'src/components/legacy/components/tooltip/tooltip.jsx'
import { typeOptionList } from 'src/components/legacy/common/report.js'
import { useDocumentsAndReportsContext } from './DocumentsAndReportsContextProvider'
import { ACTIONS, MODAL_TYPE } from 'src/constants'
import { useMutation } from 'src/hooks/APIHooks'
import { CREATE_ENTRY_WITH_ATTACHMENT } from 'src/common/queries/attachment'
import {
  DELETE_DOCUMENT,
  DELETE_CONSULTATION,
  DELETE_CONSULTATION_VALUE_ASSUMPTIONS,
  DELETE_EUREPORT
} from './graphql'
import { useMemo, useCallback } from 'react'
import { PAGE_TYPE } from './constants'
import ConsultationView from './consultation/ConsultationView'
import ViewEnergyUsageReport from './EUReport/ViewEnergyUsageReport'
import {
  consultationType,
  documentType,
  energyUsageReportType,
  getDocTestName
} from './helper'
import { useNavigate } from 'react-router-dom'
import { getSearchParams } from 'src/common/helperFunctions.js'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import { getUrlSearchParamValue } from 'src/components/legacy/common/helpers'
import translate, { TranslateComponent } from 'src/common/translations'
import { useDispatch } from 'react-redux'
import { newRefreshToken, selectUiMode } from 'src/redux/slicers/appData'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { useSelector } from 'react-redux'
import { UiMode } from 'src/redux/types/AppTypes'
import { ReportsContainer } from 'src/denali-pages/Reports/ReportsContainer'
import { Dialog } from 'src/denali-components/Dialog/Dialog'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { accessControlFunc } from 'src/components/accessControl'
import { CreateReportButton } from 'src/denali-pages/Reports/CreateReportButton'

const ENERGY_USAGE_REPORT_TOOLTIP =
  'Energy Usage Reports can only be created for a single building'

const ORG_REPORTS = ['IntelligentServicesConsultation']

export const DocumentsAndReportsContainer = ({ action }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    setReloadEvents,
    modalType,
    setModalType,
    docReportRow,
    setDocumentModalView,
    mode,
    setMode
  } = useDocumentsAndReportsContext()

  const userAccess = useSelector(selectUserAccess)

  const { reportType, buildingId, organizationId } = getSearchParams()

  const getReportType = () => {
    switch (reportType) {
      case 'VariableRefrigerantFlowReport': // VRF Report
      // return <ViewVRFReport {...{ report, ...props }} setNotificationDialog={setNotificationDialog} />
      case 'EnergyUsageReport': // Energy Usage
        return <ViewEnergyUsageReport />
      default:
        return <ConsultationView />
    }
  }

  const renderConfirmationText = (modalType) => {
    switch (modalType) {
      case MODAL_TYPE.CONFIRMATION:
        return t('common:DeleteConfirm', { item: docReportRow?.title })
      default:
        return t('common:DeleteNotification', { name: docReportRow?.title })
    }
  }
  const hideDeleteDialog = (shouldNavigate = false) => {
    setModalType('');
    setDocumentModalView(false);
    if (shouldNavigate && mode === ACTIONS.DELETE) {
      setMode(null);
      navigate(
        `/${PAGE_NAMES.REPORTS}${getUrlSearchParamValue(
          docReportRow?.buildingIds?.length === 1 ? docReportRow?.buildingIds : null,
          docReportRow?.accountId
        )}`
      )
    }
  };
  
  const { onSubmit: deleteS3AttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })
  const { onSubmit: deleteDocument } = useMutation({
    query: DELETE_DOCUMENT,
    onSuccess: () => {
      (async () => {
        const attachmentBody = {
          modelType: 'Document',
          modelData: {
            delete: {
              id: docReportRow?.id
            }
          },
          attachments: {}
        }
        await deleteS3AttachmentMutation({
          input: JSON.stringify(attachmentBody)
        })
      })()
      setReloadEvents(true)
      setDocumentModalView(false)
      setModalType(MODAL_TYPE.SUCCESS)
      setMode(null)
      trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.DELETE_DOCUMENT)
    }
  })
  const { onSubmit: deleteConsultation } = useMutation({
    query: DELETE_CONSULTATION,
    onSuccess: () => {
      (async () => {
        const attachmentBody = {
          modelType: 'Consultation',
          modelData: {
            delete: {
              id: docReportRow?.id
            }
          },
          attachments: {}
        }
        await deleteS3AttachmentMutation({
          input: JSON.stringify(attachmentBody)
        })
      })()
      setReloadEvents(mode !== ACTIONS.VIEW)
      setModalType(MODAL_TYPE.SUCCESS)
      if (mode === ACTIONS.VIEW) {
        navigate(
          `/${PAGE_NAMES.REPORTS}${getUrlSearchParamValue(
            docReportRow?.buildingIds?.length !== 0 &&
              docReportRow?.buildingIds?.length === 1
              ? docReportRow?.buildingIds[0]
              : null,
            docReportRow?.accountId
          )}`
        )
      }
    }
  })

  const { onSubmit: deleteConsultationValueAssumption } = useMutation({
    query: DELETE_CONSULTATION_VALUE_ASSUMPTIONS,
    dataPath: 'data.deleteConsultationValueAssumption'
  })

  const { onSubmit: deleteEnergyUsageReport } = useMutation({
    query: DELETE_EUREPORT,
    onSuccess: () => {
      setReloadEvents(mode !== ACTIONS.VIEW)
      setModalType(MODAL_TYPE.SUCCESS)
    }
  })

  const deleteRowHandler = async () => {
    switch (docReportRow?.filterType) {
      case documentType:
        await dispatch<any>(
          newRefreshToken(
            [docReportRow?.buildingId],
            organizationId ? [organizationId] : null,
            null,
            [
              'AttachmentAdmin',
              'DocumentAdmin',
              'EnergyUsageReportAdmin',
              'ConsultationUser',
              'ConsultationAdmin'
            ]
          )
        )
        deleteDocument({ input: { id: docReportRow?.id } })
        break
      case consultationType:
        // If the consultaitons has assumption values, delete that too
        await dispatch<any>(
          newRefreshToken(
            buildingId ? [buildingId] : null,
            [docReportRow.accountId],
            null,
            [
              'AttachmentAdmin',
              'DocumentAdmin',
              'EnergyUsageReportAdmin',
              'ConsultationUser',
              'ConsultationAdmin'
            ]
          )
        )
        if (docReportRow?.assumptionValueId) {
          deleteConsultationValueAssumption({
            input: { id: docReportRow.assumptionValueId }
          })
        }
        deleteConsultation({ input: { id: docReportRow?.id } })
        trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.DELETE_REPORT)
        break
      case energyUsageReportType:
        await dispatch<any>(
          newRefreshToken(
            [docReportRow?.buildingId],
            organizationId ? [organizationId] : null,
            null,
            [
              'AttachmentAdmin',
              'DocumentAdmin',
              'EnergyUsageReportAdmin',
              'ConsultationUser',
              'ConsultationAdmin'
            ]
          )
        )
        deleteEnergyUsageReport({ input: { id: docReportRow?.id } })
        trackEvent(USER_EVENTS.DOCUMENTS_REPORTS.events.DELETE_REPORT)
        break
      default:
        break
    }
  }

  const modalConfig = useMemo(
    () => ({
      heading:
        docReportRow?.filterType === documentType
          ? 'Delete Document'
          : 'Delete Report',
      buttons:
        modalType === MODAL_TYPE.CONFIRMATION
          ? [
              {
                text: t('common:Yes'),
                handleClick: deleteRowHandler,
                type: 'valid'
              },
              {
                text: t('common:No'),
                handleClick: () => hideDeleteDialog(false),
                type: 'cancel'
              }
            ]
          : [
              {
                text: t('common:Close'),
                handleClick: () => hideDeleteDialog(true),
                type: 'cancel'
              }
            ],
            handleClose: () => modalType === MODAL_TYPE.CONFIRMATION ? hideDeleteDialog(false) : hideDeleteDialog(true)
    }),
    [modalType]
  )

  const handleDropClick = (value) => {
    if (value === 'Intelligent Services Consultation') {
      navigate({
        pathname: '/reports/Consultations/add',
        search: `?organization=${organizationId}${
          buildingId ? `&location=${buildingId}` : ''
        }`
      })
    } else if (value === 'Energy Usage Report') {
      navigate({
        pathname: `/reports/energy-reports/add`,
        search: `?organization=${organizationId}${
          buildingId ? `&location=${buildingId}` : ''
        }&reportType=EnergyUsageReport`
      })
    }
  }

  const checkDisableReport = (key, location = null) =>
    ORG_REPORTS.includes(key) ? true : location //Access can be included here

  const optionsList = useMemo(() => {
    const notAcceptedList = ['Document', 'VRFReport', 'BuildingHealthReport']
    const checkCreateConsultationAccess = accessControlFunc({
      id: 'tc.pages.documents-and-reports.consultation.add',
      userAccess
    })

    const checkCreateEUAccess = accessControlFunc({
      id: 'tc.pages.documents-and-reports.energy-usage.add',
      userAccess
    })

    if (!checkCreateConsultationAccess) {
      notAcceptedList.push('IntelligentServicesConsultation')
    }

    if (!checkCreateEUAccess) {
      notAcceptedList.push('EnergyUsageReport')
    }

    return (typeOptionList(t) || []).filter(
      ({ key }) => !notAcceptedList.includes(key)
    )
  }, [userAccess])

  const modalCloseCallback = useCallback(() => hideDeleteDialog(false), [mode, modalType]);

  const addButtonLabel = translate('Add Documents')

  const uiMode = useSelector(selectUiMode)
  if (uiMode === UiMode.denali && action === PAGE_TYPE.LIST) {
    return (
      <>
        <ReportsContainer
          organizationId={organizationId}
          buildingId={buildingId}
          checkDisableReport={checkDisableReport}
          addButtonLabel={addButtonLabel}
          optionsList={optionsList}
          handleDropClick={handleDropClick}
          uiMode={uiMode}
        />
        {modalType === MODAL_TYPE.CONFIRMATION && (
          <DialogConfirm
            onAgree={deleteRowHandler}
            title={
              docReportRow?.filterType === documentType
                ? 'Delete Document'
                : 'Delete Report'
            }
            onClose={modalCloseCallback}
          >
            {renderConfirmationText(modalType)}
          </DialogConfirm>
        )}
        {modalType === MODAL_TYPE.SUCCESS && (
          <Dialog
            title={
              docReportRow?.filterType === documentType
                ? 'Delete Document'
                : 'Delete Report'
            }
            onClose={modalCloseCallback}
          >
            {renderConfirmationText(modalType)}
          </Dialog>
        )}
      </>
    )
  }

  return (
    <>
      {action === PAGE_TYPE.LIST && (
        <>
          <Container
            sColumns={12}
            mColumns={12}
            lColumns={12}
            xlColumns={12}
            padding="0"
          >
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <PageHeader
                pageTitle={
                  <TranslateComponent>Documents & Reports</TranslateComponent>
                }
                titleTools={
                  <TitleToolsWrapper>
                    <ButtonWrapper
                      to={{
                        pathname: `/reports/add`,
                        search: `?organization=${organizationId}${
                          buildingId ? `&location=${buildingId}` : ''
                        }`
                      }}
                    >
                      <LegacyButton
                        title="Add Documents"
                        testName="add-documents"
                      >
                        {' '}
                        {addButtonLabel} <FilterIcon className="icon-add" />
                      </LegacyButton>
                    </ButtonWrapper>
                    <StyledCreatDropdown
                      disabled={false}
                      data-testid="create-report-dropdown"
                    >
                      <Dropdown.Box>
                        <Dropdown.Button
                          title={
                            <TranslateComponent>
                              Create Report
                            </TranslateComponent>
                          }
                        />
                        <DropdownContainer>
                          {optionsList.map(({ key, value }) => {
                            return (
                              <>
                                {checkDisableReport(key, buildingId) ? (
                                  <Dropdown.Item
                                    styleNames="report-item"
                                    key={key}
                                    onClick={() => handleDropClick(value)}
                                  >
                                    {value}
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    styleNames="report-item disabled tooltip-width"
                                    key={key}
                                  >
                                    <Tooltip
                                      content={ENERGY_USAGE_REPORT_TOOLTIP}
                                      horizontalPosition={
                                        TOOLTIP_HORIZONTAL_POSITION_LEFT
                                      }
                                      SpanOrDiv="span"
                                    >
                                      {value}
                                    </Tooltip>
                                  </Dropdown.Item>
                                )}
                              </>
                            )
                          })}
                        </DropdownContainer>
                      </Dropdown.Box>
                    </StyledCreatDropdown>
                  </TitleToolsWrapper>
                }
              />
            </Content>
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <DocumentsAndReportsList uiMode={uiMode}  
                noTableDataAction={
                  <CreateReportButton
                    key='create'
                    optionsList={optionsList}
                    handleDropClick={handleDropClick}
                    checkDisableReport={checkDisableReport}
                    buildingId={buildingId}
                  />
                }
              />
            </Content>
          </Container>
        </>
      )}

      {action !== PAGE_TYPE.LIST && <>{getReportType()}</>}
      {[MODAL_TYPE.CONFIRMATION, MODAL_TYPE.SUCCESS].includes(modalType) ? (
        <Modal
          testName={getDocTestName(
            modalType === MODAL_TYPE.CONFIRMATION
              ? 'delete_confirmation'
              : 'delete_success'
          )}
          {...modalConfig}
        >
          <ConfirmationText>
            {renderConfirmationText(modalType)}
          </ConfirmationText>
        </Modal>
      ) : null}
    </>
  )
}

export default DocumentsAndReportsContainer
