import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PDFGenerator from "src/components/print-components/pdf-generator"
import PdfOpportunityList from "src/pages/opportunities/OpportunityDetail/pdf/pdf-opportunity-list"
import { PDF_PAGE_TYPES } from "src/components/legacy/common/pdf"
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

function OpportunityListPdfButton(props) {
  const [t] = useTranslation()
  const { loading, locationName, allOrganizations, organizationName, activeSalesOffice, opportunities, uiMode } = props
  const name = useMemo(() => {
		if (allOrganizations) {
			return `${t("common:All")} ${t("opportunities:Findings")}`
		} else if (locationName) {
			return locationName
		} else if (activeSalesOffice) {
			return `${activeSalesOffice?.officeCode}, ${activeSalesOffice?.officeName}, ${activeSalesOffice?.district}`
		} else {
			return organizationName
		}
	}, [allOrganizations, locationName, activeSalesOffice, organizationName])

    const runAmplitudeLogic = () => {
        trackEvent(USER_EVENTS.OPPORTUNITIES.events.DOWNLOAD_OPPORTUNITY_LIST, {"format":"pdf"})
    }

  return (
      <>
          {!loading &&
              <PDFGenerator
                fileName={`${t("opportunities:Opportunities")}-${name}`}
                pageType={PDF_PAGE_TYPES.CUSTOM_PAGE}
                disabled={opportunities?.length === 0}
                onTrigger = {() =>
                  runAmplitudeLogic()
                }
                uiMode={uiMode}
              >
                  <PdfOpportunityList
                      columns={opportunities}
                      sortedBy={false}
                      filteredBy={false}
                      showNotes={false}
                      isFooterCount={true}
                      headerLocation={locationName ?? ''}
                      headerOrganization={organizationName ?? ''}
                  />
              </PDFGenerator>}
      </>
  )
}

export default React.memo(OpportunityListPdfButton)
