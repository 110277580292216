import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'

const UnitOfMeasurementSelect = ({ values, setValues, dimensionList, unitOfMeasureList,targetUOMList, dataSourceProperty, setSelectedDimension, selectedDimension, selectedProperty }) => {
	const [t] = useTranslation()
	const disabled = !dataSourceProperty
	const { dimension, sourceUOM } = values
	useEffect(() => {
		setTimeout(() => {
			const dimensionOptions = dimensionList.filter((data) => data?.dimensionId === "0")
			setValues({
				...values,
				// dimension: dataSourceProperty?.sourceMeasureType,
				dimension: (dataSourceProperty?.sourceMeasureType && dataSourceProperty?.sourceMeasureType !== "none")
				? dataSourceProperty?.sourceMeasureType
				: dimensionOptions.length > 0 && dimensionOptions[0]?.key,
				sourceUOM: undefined,
				targetUOM: undefined,
			})
			setSelectedDimension((dataSourceProperty?.sourceMeasureType && dataSourceProperty?.sourceMeasureType !== "none") 
				? dataSourceProperty?.sourceMeasureType
				: dimensionOptions.length > 0 && dimensionOptions[0]?.key
			)

		}, 5)
	}, [dataSourceProperty?.sourceDisplayName, dimensionList.length])

	useEffect(() => {
		if (unitOfMeasureList.length === 1 && sourceUOM !== unitOfMeasureList[0].key) {
			setTimeout(() => {
				setValues({
					...values,
					sourceUOM: unitOfMeasureList[0].key
				})
			}, 5)
		}
	})

	useEffect(() => {
		if(targetUOMList?.length > 0){
		setTimeout(() => {
			setValues({
				...values,
				targetUOM: targetUOMList[0].key,
			})
		}, 5)
	}
	},[targetUOMList])

	return (
		<>
			<EditableView
				type={EditableView.COMPONENT_TYPE.SELECT}
				labelName={t("equipment-setup:CreateEquipment>Dimension")}
				className="analog-point-dimension required"
				name="dimension"
				value={values?.dimension}
				options={dimensionList}
				disabled={!values?.defaultDataSourcePropertyId}
				onChange={dimension => {
					setTimeout(() => {
						setValues({
							...values,
							dimension,
							sourceUOM: undefined,
						})
					}, 5)
					setSelectedDimension(dimension)
				}}
			/>
			<EditableView
				type={EditableView.COMPONENT_TYPE.SELECT}
				labelName={t("equipment-setup:CreateEquipment>SourceUom")}
				className="analog-point-source-uom required"
				name="sourceUOM"
                value={unitOfMeasureList.find(uom => uom.key === values?.sourceUOM)?.description}
                options={unitOfMeasureList}
				onChange={sourceUOM => {
					setTimeout(() => {
						setValues({
							...values,
							sourceUOM,
						})
					}, 5)
					setSelectedDimension(dimension)
				}}
				disabled={!values?.dimension || unitOfMeasureList.length === 1}
			/>
			<EditableView
				type={EditableView.COMPONENT_TYPE.SELECT}
				labelName={t("equipment-setup:CreateEquipment>TargetUom")}
				className="analog-point-target-uom required"
				name="targetUOM"
                options={targetUOMList}
                value={targetUOMList.find(uom => uom.key === values?.targetUOM)?.description}
                disabled={true}
			/>
		</>
	)
}
export default UnitOfMeasurementSelect;
