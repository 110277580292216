export const GET_UTILITY_BILLING_DATA_BY_LOCATIONID = `
query getUtilityBillDataByLocationId(
    $buildingId: ID!, $nextToken: String
) {
    listUtilityDataByBuilding(buildingId: $buildingId, nextToken: $nextToken) {
        items {
          id
          createdBy
          creationDate
          eci
          eui
          electricUtilityData {
            consumption
            baseDemandRealPower
            baseDemandCost
            baseDemandApparentPower
            endDate
            consumptionCost
            fees
            intermediateDemandApparentPower
            intermediateDemandCost
            intermediateDemandRealPower
            peakDemandApparentPower
            peakDemandCost
            peakDemandRealPower
            powerFactor
            powerFactorCost
            startDate
            totalBill
            totalDemandCost
          }
          gasUtilityData {
            commodityCost
            consumptionCommodity
            consumptionTransportation
            endDate
            fees
            gasType
            startDate
            totalCost
            transportationCost
          }
          month
          year
          waterUtilityData {
            consumption
            consumptionCost
            endDate
            fees
            sewage
            sewageCost
            startDate
            totalCost
          }
        }
        nextToken
      }
  }  
`



export const GET_BUILDING_BY_ID = `query GET_BUILDING_BY_ID($id: ID!) {
  getBuilding(id: $id) {
        floorArea
        name
        id
        accountId
        accountName
      }
    }
`


export const UPDATE_BUILDING = `mutation UPDATE_BUILDING(
  $input: UpdateBuildingInput!
) {
  updateBuilding(input: $input) {
    floorArea
    name
    id
    accountId
    accountName
  }
}`


// Same Lamda is used for Both upload and delete, only action key will be different
export const UPDATE_UTILITY_BILL =  /* GraphQL */ `
mutation utilityDataUploader($requestBody: AWSJSON!) {
  utilityDataUploader(requestBody: $requestBody)
}
`