export const dark = {
  input: {
    background: '{colors.gradients.multiColor.90}',
    foreground: '{colors.gradients.multiColor.inverse}',
    border: '{colors.gradients.multiColor.50}',
    placeholder: '{colors.gradients.multiColor.hint}',
    iconBackground: '{colors.gradients.multiColor.70}',
    selected: '{colors.neutral.100}',
    selectedIconBackground: '{colors.gradients.accent.50}',
    selectedIconForeground: '{colors.gradients.accent.inverse}',
    shadow: '{rgba(123, 184, 255, 0.60)}'
  },

  search: {
    background: '{colors.neutral.60}',
    foreground: '{colors.white}',
    placeholder: '{colors.neutral.40}',
    submitBackground: '{colors.neutral.70}',
    submitForeground: '{colors.white}',
  },

  organizations: {
    background: '{colors.neutral.70}',
    foreground: '{colors.white}',
    focusBackground: '{colors.neutral.60}',
    iconBackground: '{colors.pink.50}',
    iconForeground: '{colors.pink.inverse}'
  },

  buildings: {
    background: '{colors.neutral.70}',
    foreground: '{colors.white}',
    focusBackground: '{colors.neutral.60}',
    iconBackground: '{colors.purple.50}',
    iconForeground: '{colors.purple.inverse}'
  },

  headlineIcon: {
    background: '{colors.neutral.90}',
    foreground: '{colors.white}',
  },

  selectionSubTitle: '{colors.neutral.40}',
};

export const light = dark;