import PropTypes from "prop-types"
import { Text, View, Image } from "@react-pdf/renderer"
import { KPI_SETTINGS } from "../../legacy/components/kpi/kpi"
import { showCorrectPriority } from "../../legacy/common/opportunity"

import { kpiStyles } from "./pdf-kpi-block.styles"
import { withTranslation } from "react-i18next"

const { kpiStyle, wrapper, icon, kpiPriority } = kpiStyles


const PDFKPIBlock = (props) => {
	const {kpi,t} = props
	const kpiSettings = KPI_SETTINGS(t)
	return (kpi && kpi?.length) ? <View style={wrapper}>
		{kpi
			.filter(item => item?.value)
			.map(({ name, custom, priority, savings }, i) => <View style={kpiStyle} key={i}>
				<Text>{!custom ? `${kpiSettings?.get(name)?.name} ${t("components:kpi>Impact")}` : name}</Text>
				<Image style={icon} src={require(`../../../assets/images/${custom ? "truck-rolls-impact" : kpiSettings?.get(name)?.icon}.png`)} />
				<Text style={kpiPriority}>{showCorrectPriority(priority, savings, t)}</Text>
			</View>)}
	</View> : null
}

PDFKPIBlock.propTypes = {
	kpi: PropTypes.array
}

export default withTranslation()(PDFKPIBlock)
