import { StyleSheet } from "@react-pdf/renderer"
import colors from 'src/components/layouts/colors.json'

export const styles = StyleSheet.create({
	dateWrapper: {
		fontSize: 10,
		textAlign: "right",
		fontWeight: "bold",
		color: colors.TBA_DARK_GREY
	}
})
