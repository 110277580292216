export const CREATE_CHARGE = `
mutation CREATE_CHARGE(
  $input: CreateChargeInput!
) {
  createCharge(input: $input) {
    id
  }
}
`

export const UPDATE_CHARGE = `
mutation UPDATE_CHARGE(
  $input: UpdateChargeInput!
) {
  updateCharge(input: $input) {
    id
  }
}
`

export const DELETE_CHARGE = `
mutation DELETE_CHARGE($input: DeleteChargeInput!) {
  deleteCharge(input: $input) {
    id
  }
}
`

export const GET_CHARGE_BY_ID = `
query GET_CHARGE_BY_ID(
  $id: ID!
) {
  getCharge(id: $id) {
    endDate
    id
    rateStructureId
    startDate
    salesOfficeId
    variableFees {
      name
      rate
      rateType
    }
    fixedFees {
      amount
      amountType
      name
    }
    rates {
      consumptionPrice
      demandMin
      demandPrice
      season
      timeOfUseScheduleType
    }
    rateStructure {
      id
      name
      salesOfficeId
      isSeasonalVariation
      isTimeSchedule
      type
      seasonalDefinitions {
        weekdaySchedule
        weekendSchedule
        enabled
        endDate
        endMonth
        name
        startDate
        startMonth
      }
    }
  }
}
`


export const GET_RATE_STRUCTURE_BY_ID = `
query GET_RATE_STRUCTURE_BY_ID(
  $id: ID!
) {
  getRateStructure(id: $id) {
    description
    id
    isSeasonalVariation
    isTimeSchedule
    name
    salesOfficeId
    type
    updatedAt
    utilityCompanyId
    seasonalDefinitions {
      enabled
      endDate
      endMonth
      name
      startDate
      startMonth
      weekdaySchedule
      weekendSchedule
    }
    offPeakRachet {
      demandPercent
      enabled
      timeFrame
    }
    onPeakRachet {
      demandPercent
      enabled
      timeFrame
    }
    salesOffice {
      id
      name
    }
    intermediateRachet {
      demandPercent
      enabled
      timeFrame
    }
  }
}
`




