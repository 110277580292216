import React, { useState, useEffect } from 'react'
import ObjectPickerWrapper from 'src/components/object-picker'
import { BUILDING } from 'src/components/object-picker/helpers.js'
import { DashboardObjectPickerWrapper } from './styles'
import Selectrix from 'src/denali-ui/components/Selectrix'
import { NewStyles } from 'src/NewStyles'
import { SEARCH_AGGREGATE } from 'src/pages/buildingSetup/graphql/queries'
import { GET_BUILDING_METER_TOTAL_EQUIPMENT } from 'src/common/queries/datasource.ts'
import { useQuery } from 'src/hooks/APIHooks'
import { ErrorText } from '../../styles'
import { isEmpty } from 'lodash'

const DashboardObjectPicker = ({ dashboardType, actionType, formik }) => {
  const { values, setValues, errors, touched, setFieldValue } = formik
  const {
    data: buildingMeterTotalEquipment,
    refetch: getBuildingTotalMeterEquipment,
    loading: loadingBuildingTotal
  } = useQuery({
    query: GET_BUILDING_METER_TOTAL_EQUIPMENT,
    dataPath: 'data.getBuildingTotalMeterEquipment',
    disableInitialLoad: true
  })
  const onOrganizationChange = (e, child) => {
    if (e.target.value !== values.organization) {
      setValues((value) => ({
        ...value,
        organization: e.target.value,
        organizationName: child?.props?.children ?? '',
        building: '',
        buildingName: ''
      }))
    }
  }
  const getOrgsQueryResponseMap = (response, organizationId) => {
    const idListIndex = response.findIndex((e) => e.name === 'accountIds')
    const nameTermsIndex = response.findIndex((e) => e.name === 'accountTerms')
    const results =
      response &&
      idListIndex !== -1 &&
      nameTermsIndex !== -1 &&
      response[nameTermsIndex].result.buckets.map((e, i) => ({
        searchableValue: e.key,
        value: response[idListIndex].result.buckets[i].key,
        children: e.key,
        selected: response[idListIndex].result.buckets[i].key === organizationId
      }))

    return results.sort((a, b) => {
      const nameA = a.searchableValue.toUpperCase()
      const nameB = b.searchableValue.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
  }

  useEffect(() => {
    if (values.building) {
      getBuildingTotalMeterEquipment({
        body: JSON.stringify({ bId: values.building })
      })
    }
  }, [values.building])

  useEffect(() => {
    if (buildingMeterTotalEquipment) {
      try {
        const res = JSON.parse(buildingMeterTotalEquipment)
        const buildingMeterTotalEquipmentList = res?.body?.equipments || []
        if (buildingMeterTotalEquipmentList?.length > 0) {
          setFieldValue('equipment', buildingMeterTotalEquipmentList[0]?.id)
        }
      } catch (err) {
        console.log(err)
      }
    }
  }, [buildingMeterTotalEquipment])

  return (
    <NewStyles>
      {dashboardType === '1' && actionType === 'Create-Blank' && (
        <DashboardObjectPickerWrapper>
          <Selectrix
            onChange={(value, child) => onOrganizationChange(value, child)}
            query={SEARCH_AGGREGATE}
            fullWidth={true}
            multiple={false}
            value={values?.organizationName}
            dataPath={'data.searchBuildings.aggregateItems'}
            containerWidth={true}
            getQueryResponseMap={(values) =>
              getOrgsQueryResponseMap(values, values.organization)
            }
            selectClassName={'orgSelectInput'}
            searchPlaceHolder="Type to find an organization..."
            testName={`consultations_organizationdetails_addorganization`}
            querySearchField={['accountName']}
            aggregates={[
              {
                field: 'accountName',
                type: 'terms',
                name: 'accountTerms'
              },
              { field: 'accountId', type: 'terms', name: 'accountIds' }
            ]}
            showCutomOption={true}
            showNoResultsMessage={false}
            disabled={false}
          />
          {errors?.organization && (
                  <ErrorText>{errors?.organization}</ErrorText>
                )}
           <ObjectPickerWrapper
            objects={['', BUILDING]}
            initialValues={{}}
            update={(items) => {
              if (items?.length > 0) {
                const pickerValue = items[0] ?? {}
                setValues((value) => ({
                  ...value,
                  building: pickerValue?.buildingId,
                  buildingName: pickerValue?.buildingName,
                  salesOfficeId: pickerValue?.salesOfficeId
                }))
              } else if (!isEmpty(items)){
                setValues((value) => ({
                  ...value,
                  building: null,
                  buildingName: null,
                  salesOfficeId: null
                }))           
              }
            }}
            buildingSelectLimit={1}
            orgIdFromParent={values?.organization}
            considerOrgIdFromParent={true}
            mode={'ADD'}
            error={errors?.building}
          />
          {errors?.building && <ErrorText>{errors?.building}</ErrorText>}
        </DashboardObjectPickerWrapper>
      )}
    </NewStyles>
  )
}

export default DashboardObjectPicker
