import { useTranslation } from 'react-i18next'
import { DataMonitoringConfigurationWrapper, PanelLabel } from '../../styles'
import { useFormikContext } from '../MultiTabTorm'
import { ChangeEvent } from 'react'
import { convertStringToValidNumber } from 'src/components/legacy/common/helpers.js'
import {
  MaxConstant,
  getCalculatedOptionsList,
  getMaximumOptionsList,
  getNeedleOptionsList,
  getTimePeriodOptionsList
} from '../../helpers'
import * as Yup from 'yup'
import i18next from 'i18next'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import WidgetPropertyUom from '../widgetsPropertyUom/WidgetPropertyUom'
import translate, { TranslateComponent } from 'src/common/translations'

export const GaugeConfigSchema = Yup.object().shape({
  property: Yup.string().test('testRequiredValue',"Property is required.",testRequiredValue),
  unitsOfMeasure: Yup.string().test('testRequiredValue',"Uom is required.",testRequiredValue),
  needleValue: Yup.string().required("required fields"),
  timePeriod: Yup.string().required("Time Period is required."),
  gaugeMin: Yup.string()
    .test(
      'testGaugeMinValue',
      '',
      testGaugeMinValue
    ),
  gaugeMax: Yup.string().required("This value is required."),
  gaugeConstantValue: Yup.string()
    .test(
      'testGaugeMaxValue',
      '',
      testGaugeMaxValue
    )
})

function testRequiredValue(value){
  return !(!value)
}

function testGaugeMinValue(value) {
  const {gaugeConstantValue} = this.parent
  const {
    createError
  } = this
  if (!value) {
    return createError({ message: "This value is required." });
  }
  if (Number(value) > Number(gaugeConstantValue)) {
    return createError({ message: `This value should be lower than Max` });
  }

  return true;
}
function testGaugeMaxValue(value) {
  const {gaugeMin,gaugeMax,gaugeCalculatedValue} = this.parent;
  if(gaugeMax?.includes("calculated")){
    return !!gaugeCalculatedValue;
  }
  const {
    createError
  } = this
  if (!value) {
    return createError({ message: "This value is required" });
  }
  if (Number(value) < Number(gaugeMin)) {
    return createError({ message: `This value should be greater than Min` });
  }

  return true;
}

const DataMonitoringGaugeConfiguration = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, errors, setFieldValue } = formik
  return (
    <DataMonitoringConfigurationWrapper>
      <div className="configuration-tab-main">
        <div>
          <WidgetPropertyUom
            transFunc = {t}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
          />
          <div className="inline-control">
            <SelectFormInput
              fieldsetClass="inline-2"
              labelName={"Needle Value"}
              optionsList={getNeedleOptionsList(t)}
              placeholder={'— Select One —'}
              update={(value) => {
                setFieldValue('needleValue', value)
              }}
              errorItem={errors?.needleValue}
              selectedItem={t(`time>${values?.needleValue}`, {
                nsSeparator: '>'
              })}
            />
            <SelectFormInput
              fieldsetClass="inline-2"
              labelName={"Time Period"}
              optionsList={getTimePeriodOptionsList(t)}
              placeholder={'— Select One —'}
              update={(value) => {
                setFieldValue('timePeriod', value)
              }}
              errorItem={errors?.timePeriod}
              selectedItem={t(`time>${values?.timePeriod}`, {
                nsSeparator: '>'
              })}
            />
          </div>
          <fieldset>
            <PanelLabel><TranslateComponent>Gauge minimum</TranslateComponent>*</PanelLabel>
            <div className="row-half-container">
              <span><TranslateComponent>Constant Value</TranslateComponent></span>
              <input
                value={convertStringToValidNumber(values?.gaugeMin)}
                maxLength={80}
                className={errors?.gaugeMin ? 'error-text-box' : ''}
                onChange={({
                  target: { value }
                }: ChangeEvent<HTMLInputElement>) => {
                  const re = /^[0-9\b]+$/
                  if (value === '' || re.test(value)) {
                    setFieldValue('gaugeMin', value)
                  }
                }}
              />
            </div>
            <p className="error">{errors?.gaugeMin && <TranslateComponent>{errors?.gaugeMin}</TranslateComponent>}</p>
          </fieldset>
          <div className="row-half-container half-items">
            <div className="inline-control">
              <SelectFormInput
                fieldsetClass="inline-2"
                labelName={`Gauge maximum`}
                optionsList={getMaximumOptionsList(t)}
                placeholder={'— Select One —'}
                update={(value) => {
                  setFieldValue('gaugeMax', value)
                }}
                errorItem={errors?.gaugeMax}
                selectedItem={t(`widget-builder>${values?.gaugeMax}`, {
                  nsSeparator: '>'
                })}
              />
              {values?.gaugeMax === MaxConstant ? (
                <div className="max-content">
                  <input
                    value={convertStringToValidNumber(
                      values?.gaugeConstantValue
                    )}
                    maxLength={80}
                    className={errors?.gaugeConstantValue ? 'error-text-box' : ''}
                    onChange={({
                      target: { value }
                    }: ChangeEvent<HTMLInputElement>) => {
                      const re = /^[0-9\b]+$/
                      if (value === '' || re.test(value)) {
                        setFieldValue('gaugeConstantValue', value)
                      }
                    }}
                  />
                  <p className="error">{errors?.gaugeConstantValue && <TranslateComponent>{errors?.gaugeConstantValue}</TranslateComponent>}</p>
                </div>
              ) : (
                <>
                  <SelectFormInput
                    fieldsetClass="inline-2"
                    labelName={``}
                    optionsList={getCalculatedOptionsList(t)}
                    placeholder={'— Select One —'}
                    update={(value) => {
                      setFieldValue('gaugeCalculatedValue', value)
                    }}
                    errorItem={errors?.gaugeCalculatedValue}
                    selectedItem={t(`time>${values?.gaugeCalculatedValue}`, {
                      nsSeparator: '>'
                    })}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </DataMonitoringConfigurationWrapper>
  )
}

export default DataMonitoringGaugeConfiguration
