import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Label from '../../../../../components/legacy/components/label/label'
import Select from '../../../../../components/legacy/components/select/select'
import ToggleButtonHorizontal from '../../../../../components/legacy/components/toggle-button-horizontal'
import uuid from 'react-uuid'
import {
  KEY_METRIC_OPTIONS,
  getUomOptions,
  getKeyMetricTitle,
  getUomSymbol,
  getAvailableUoms,
  NO_GAS_COST_ESTIMATE,
	NO_ELECTRIC_COST_ESTIMATE,
	NO_COST_ESTIMATE,
	NO_DATA_AVAILABLE,
	SERVER_ERROR
} from './helpers'
import { KpiEnergyMetricBuilderWrapper } from '../../../../../pages/dashboards/styles'
import KeyEnergyMetricDisplay from "./KeyEnergyMetricDisplay";
import { useDashboardContext } from "../../DashboardContextProvider"
import { TranslateComponent } from '../../../../../common/translations'

const KpiEnergyMetricBuilder = ({ metric, values, totalVirtualMeterId, handleChange, handleClose, stripNumber }) => {
  const { keyMetricsError } = useDashboardContext()
  const [t] = useTranslation()
  const showError = () => {
    const errorCode = keyMetricsError?.find(f => f?.stripNumber === stripNumber)?.errorCode
    switch (errorCode) {
      case NO_COST_ESTIMATE:
        return (
          <div className="kpi-error">
            <TranslateComponent>A cost estimate is necessary to complete this calculation. Click to create an </TranslateComponent>
             <Link
                to="/rate-structure-library"
                onClick={()=>handleClose()}
              >
                <TranslateComponent>electric cost estimate</TranslateComponent>
              </Link>  <TranslateComponent>or</TranslateComponent>  <Link
                to="/rate-structure-library"
                onClick={()=>handleClose()}
              >
                <TranslateComponent>gas cost estimate</TranslateComponent>
              </Link> <TranslateComponent>or contact your Trane representative.</TranslateComponent>
          </div>
        )
      case NO_ELECTRIC_COST_ESTIMATE:
        return (
          <div className="kpi-error">
             <TranslateComponent>An electric cost estimate is necessary to complete this calculation. Click to </TranslateComponent>
            <Link
                to="/rate-structure-library"
                onClick={()=>handleClose()}
            >
              <TranslateComponent>create an electric cost estimate</TranslateComponent>
            </Link> <TranslateComponent>or contact your Trane representative.</TranslateComponent>
          </div>
        )
      case NO_GAS_COST_ESTIMATE:
        return (
          <div className="kpi-error">
            <TranslateComponent>A gas cost estimate is necessary to complete this calculation. Click to</TranslateComponent> 
            <Link
                to="/rate-structure-library"
                onClick={()=>handleClose()}
            >
              <TranslateComponent>create a gas cost estimate</TranslateComponent> 
            </Link><TranslateComponent>or contact your Trane representative.</TranslateComponent>
          </div>
        )
      case NO_DATA_AVAILABLE:
        return (
          <div className="kpi-error">
            <TranslateComponent>There is no data associated with this selection. Please enter a different selection or contact your Trane representative.</TranslateComponent>
          </div>
        )
      case SERVER_ERROR:
        return (
          <div className="kpi-error-data-description">
            <span className="icon icon-error" />
            <span>
              <TranslateComponent>A problem occurred loading this data. Please try again or contact your Trane representative.</TranslateComponent>
            </span>
          </div>
        )
      default:
        return ''
    }
  }

  const handleUomChange = (uom) => {
    const newMetric = {
      ...metric,
      uom
    }
    handleChange(newMetric)
  }
  const onIsEnabledChange = (e) => {
    const { isEnabled } = metric
    const newMetric = {
      ...metric,
      isEnabled: !isEnabled
    }
    handleChange(newMetric)
  }
  const handleKeyMetricChange = (keyMetric) => {
    const availableUoms = getAvailableUoms(keyMetric)
    const newUom = availableUoms.includes(uom) ? uom : availableUoms[0]
    const newMetric = {
      ...metric,
      keyMetric,
      uom: newUom
    }
    handleChange(newMetric)
  }

  const { isEnabled, keyMetric, uom } = metric
  const uomOptions = getUomOptions(keyMetric)
  const selectedKeyMetric = getKeyMetricTitle(keyMetric)
  const selectedUom = getUomSymbol(uom)

  const isAllDataAvailable = () => {
		const { organization, building, timePeriod, comparedTo } = values
		return organization && building && timePeriod && comparedTo
	}

  return (
    <KpiEnergyMetricBuilderWrapper>
      <div className='kpi-energy-metric-builder'>
        <KeyEnergyMetricDisplay
          locationId={values.building}
          organizationId={values.organization}
          timePeriod={values.timePeriod}
          comparedTo={values.comparedTo}
          keyMetric={keyMetric}
          disabled={!isEnabled}
          isEnabled={isEnabled}
          uom={uom}
          totalVirtualMeterId={totalVirtualMeterId}
          t={t}
          stripNumber={stripNumber}
        />
      </div>
      {isAllDataAvailable() ? (
        <>
          <div className="kpi-energy-metric-builder-form">
            <div className={`form-group ${!isEnabled ? 'disabled' : ''}`}>
              <Label text={"Key Metric*"} />
              <Select
                selectedItem={selectedKeyMetric}
                onChange={handleKeyMetricChange}
                options={KEY_METRIC_OPTIONS.map(
                  ({ key, value, iconClass }) => ({
                    key,
                    value: value,
                    iconClass
                  })
                )}
                isDisabled={!isEnabled}
              />
            </div>

            <div className={`form-group ${!isEnabled && 'disabled'}`}>
              <Label text={`Unit of Measure*`} />
              <Select
                selectedItem={selectedUom}
                onChange={handleUomChange}
                options={uomOptions}
                isDisabled={!isEnabled}
              />
            </div>

            <div className="form-group toggle-group kpi-metric-toggle-wrapper">
              <span className="kpi-metric-toggle-title">
                <TranslateComponent>Show/Hide Metric</TranslateComponent>:
              </span>
              <ToggleButtonHorizontal
                id={`toggle-key-metric-${uuid()}`}
                checked={isEnabled}
                onChange={(e) => onIsEnabledChange(e)}
              />
            </div>
          </div>
          {keyMetricsError?.length > 0 && showError(keyMetricsError)}
        </>
      ) : (
        <div className="kpi-energy-metric-builder-form no-options">
          <TranslateComponent>Set options to see value</TranslateComponent>
        </div>
      )}
    </KpiEnergyMetricBuilderWrapper>
  )
}

export default KpiEnergyMetricBuilder
