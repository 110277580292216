import { isEmpty } from 'ramda'
import { Fragment, memo } from 'react'
import {
  selectAxes,
  selectGeneratedScales,
  selectSeriesData,
  selectSeriesTypes,
  useSelector,
  useTooltipContext
} from 'src/chart-library/BaseSetup/BaseSetup'
import Node from 'src/chart-library/CommonComponents/Node/Node'
import { SCALE_TIME } from 'src/chart-library/Utils/Scales/constant'
import { getBandWidth } from './helper'
import { isNumber } from 'lodash'

const ToolTipRenderer = (props: any) => {
  const { canvasIndex } = props;
  const tooltip = useTooltipContext()
  const { mousePosition } = tooltip || {}
  const axes = useSelector(selectAxes) || []
  const generatedScales = useSelector(selectGeneratedScales)
  const seriesTypes = useSelector(selectSeriesTypes)
  const seriesData = useSelector(selectSeriesData)

  return (
    <>
      {(!isEmpty(mousePosition) && mousePosition !== null && mousePosition !== undefined) && (
          <>
            {mousePosition?.refLineV && (
              <line
                y1={0}
                y2={mousePosition?.height}
                x1={mousePosition?.roundX}
                x2={mousePosition?.roundX}
                stroke="black"
                opacity={1}
                strokeWidth={2}
              />
            )}
            {seriesTypes
              ?.filter((obj: any) => obj?.show && obj?.visible)
              ?.map((obj, i) => {
                const xObject = (axes || [])?.find(
                  (axesObj: any) => axesObj?.key === obj?.xAxisKey
                )
                const xscaleType = xObject?.props?.type
                const gXScaleObj = generatedScales?.[obj?.xAxisKey]
                const gYScaleObj = generatedScales?.[obj?.yAxisKey]
                const xScale = gXScaleObj?.gScale
                const yScale = gYScaleObj?.gScale
                const yData = seriesData?.[obj?.seriesKey]?.data
                const cx = xScale ? xScale(
                  xscaleType === SCALE_TIME
                    ? new Date(mousePosition?.currentItem)
                    : mousePosition?.currentItem
                ) + (xscaleType === 'scaleBand' ? getBandWidth(xScale) / 2 : 0) : null
                const cy = yScale ? yScale(yData[mousePosition?.index]) : null

                if (isNumber(cx) && isNumber(cy)) {
                  return (
                    <Node
                      key={`${i}}`}
                      fill={obj?.elementProps?.stroke}
                      stroke={'white'}
                      cx={cx}
                      cy={cy}
                      visibility={obj?.visibility}
                      nodeSize={150}
                    />
                  )
                } else {
                  return <Fragment key={`${i}`}></Fragment>
                }
              })}
          </>
        )}
    </>
  )
}

export default memo(ToolTipRenderer)
