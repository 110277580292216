import React from 'react'
import PropTypes from 'prop-types'

export const Views = ({ children, onViewChange, currentView }) => {
  const _currentView = currentView[0]
  const move = (direction) => () => {
    const beforeFirstView =
      direction === 1 && _currentView === React.Children.count(children) - 1
    const afterLastView = direction === -1 && _currentView === 0
    if (!beforeFirstView && !afterLastView) {
      currentView[1]((view) => view + direction)
    }
  }

  return children.map(
    (child, index) =>
      index === _currentView &&
      React.cloneElement(child, {
        ...child.props,
        next: () => {
          const direction = 1
          onViewChange
            ? onViewChange({
                currentView: _currentView,
                move: move(direction),
                direction
              })
            : move(1)
        },
        prev: () => {
          const direction = -1
          onViewChange
            ? onViewChange({
                currentView: _currentView,
                move: move(direction),
                direction
              })
            : move(direction)
        }
      })
  )
}

Views.propTypes = {
  currentView: PropTypes.array,
  onViewChange: PropTypes.func
}
