export const listWidgetsOrganization = /* GraphQL */ `
  query listWidgetsByAccount($accountId: ID!) {
    listWidgetsByAccount(accountId: $accountId, limit: 1000) {
      items {
        accountId
        buildingId
        id
        name
        building {
          name
          city
          state
          postalCode
          accountName
        }
        description
        isKeyMetric
        isNewWindow
        widgetLink
        widgetType
        customUrl
        creationDate
        widgetTargetType
        widgetEquipment2Id
        widgeEquipment2Name
        widgeEquipment2Type
        widgeEquipment2Family
        widgeEquipment1Id
        widgeEquipment1Name
        widgeEquipment1Type
        widgeEquipment1Family
        widgetBuildingId
        widgetImage {
          items {
            displayName
            description
            buildingId
            id
            name
            title
            widgetId
            type
            sizeInBytes
          }
        }
        imageText
        imageAdditionalText
        weatherWidget {
          metricConversion
        }
        baselineId
        baselineComparision{
          timePeriod
          displayValue
          target{
            baselineId
            widgeEquipment1Id
            widgetBuildingId
            widgetTargetType
          }
        }
        equipmentCustomKpiWidget {
          kpiCurrentPeriod
          kpiDisplayDate
          kpiGoal
          kpiMeasurement
          kpiPlotType
          kpiPreviousPeriod
          timePeriod
          kpiTrend {
            actual
            date
            goal
          }
        }
        equipmentDataMonitorGuageWidget {
          guageMaximumCalculationType
          guageMaximumType
          guageMaximumValue
          guageMinimum
          needleValue
          timePeriod
          colourConfig {
            baseline
            threshold1
            threshold1Value
            threshold2
            threshold2Value
            thresholdOption
          }
          property {
            property
            uom
            symbol
          }
        }
        equipmentDataMonitorOrbWidget {
          Value
          timePeriod
          colourConfig {
            baseline
            threshold1
            threshold1Value
            threshold2
            threshold2Value
            thresholdOption
          }
          property {
            property
            uom
            symbol
          }
        }
        equipmentTrendLineWidget {
          value
          timePeriod
          comparePoint
          colourConfig {
            line
            overlayLine
          }
          property {
            property
            uom
            symbol
          }
        }
        equipmentTrendBarWidget{
          timeInterval
          timePeriod
          value
          colourConfig {
            leftBar
            leftBenchmark
          }
          property {
            property
            uom
            symbol
          }
          benchMark {
            benchmarkCalculation
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
          }
        }
        equipmentTimeComparisionBarWidget {
          barMaximumCalculationType
          barMaximumType
          barMaximumValue
          barMinimum
          timePeriod
          value
          property {
            property
            uom
            symbol
          }
          leftbarBenchmark {
            benchmarkCalculation
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
          }
          rightbarBenchmark {
            benchmarkCalculation
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
          }
          colourConfig {
            leftInnerBar
            leftOuterBar
            leftBenchmark
            rightBar
            rightBenchmark
          }
        }
        equipmentDataComparisionBarWidget {
          barMaximumCalculationType
          barMaximumType
          barMaximumValue
          barMinimum
          timePeriod
          value
          colourConfig {
            leftBar
            leftBenchmark
            rightBar
            rightBenchmark
          }
          leftBarProperty {
            benchmarkCalculation
            benchmarkLabel
            benchmarkValue
            benchmarkMarker
            property
            uom
            symbol
          }
          rightBarProperty {
            benchmarkCalculation
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
            property
            uom
            symbol
          }
        }  
        energyConsumptionWidget {
          comparedTo
          showTemperature
          timePeriod
          uom
          benchMark {
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
            benchmarkCalculation
          }
          colourConfig {
            leftBar
            rightBar
            rightBenchmark
            toDate
          }
        }
        energyCostWidget {
          comparedTo
          showEnergyCostSegmentation
          timePeriod
          benchMark {
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
            benchmarkCalculation
          }
          colourConfig {
            leftBar
            rightBar
            rightBenchmark
            toDate
          }
        }
        energyDemandWidget {
          colourConfig {
            currentDemand
            overlayDemand
            peakDemand
          }
          rateIncrease {
            name
            rateIncreaseEnd
            rateIncreaseStart
          }
          comparedTo
          isRateIncrease
          timePeriod
        }
        widgetBuildingTarget {
          id
          name
        }
      }
    }
  }
`

export const listWidgetsByBuilding = /* GraphQL */ `
  query listWidgetsByBuilding($buildingId: ID!) {
    listWidgetsByBuilding(buildingId: $buildingId, limit: 1000) {
      items {
        accountId
        buildingId
        building {
          name
          city
          state
          postalCode
          accountName
        }
        id
        name
        description
        isKeyMetric
        isNewWindow
        widgetLink
        widgetType
        customUrl
        creationDate
        widgetTargetType
        widgetBuildingId
        widgetEquipment2Id
        widgeEquipment2Name
        widgeEquipment2Type
        widgeEquipment2Family
        widgeEquipment1Id
        widgeEquipment1Name
        widgeEquipment1Type
        widgeEquipment1Family
        widgetImage {
          items {
            displayName
            description
            buildingId
            id
            name
            title
            widgetId
            type
            sizeInBytes
          }
        }
        imageText
        imageAdditionalText
        weatherWidget {
          metricConversion
        }
        baselineId
        baselineComparision{
          timePeriod
          displayValue
          target{
            baselineId
            widgeEquipment1Id
            widgetBuildingId
            widgetTargetType
          }
        }
        equipmentCustomKpiWidget {
          kpiCurrentPeriod
          kpiDisplayDate
          kpiGoal
          kpiMeasurement
          kpiPlotType
          kpiPreviousPeriod
          timePeriod
          kpiTrend {
            actual
            date
            goal
          }
        }
        equipmentDataMonitorGuageWidget {
          guageMaximumCalculationType
          guageMaximumType
          guageMaximumValue
          guageMinimum
          needleValue
          timePeriod
          colourConfig {
            baseline
            threshold1
            threshold1Value
            threshold2
            threshold2Value
            thresholdOption
          }
          property {
            property
            uom
            symbol
          }
        }
        equipmentDataMonitorOrbWidget {
          Value
          timePeriod
          colourConfig {
            baseline
            threshold1
            threshold1Value
            threshold2
            threshold2Value
            thresholdOption
          }
          property {
            property
            uom
            symbol
          }
        }
        equipmentTrendLineWidget {
          value
          timePeriod
          comparePoint
          colourConfig {
            line
            overlayLine
          }
          property {
            property
            uom
            symbol
          }
        }
        equipmentTrendBarWidget{
          timeInterval
          timePeriod
          value
          colourConfig {
            leftBar
            leftBenchmark
          }
          property {
            property
            uom
            symbol
          }
          benchMark {
            benchmarkCalculation
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
          }
        }
        equipmentTimeComparisionBarWidget {
          barMaximumCalculationType
          barMaximumType
          barMaximumValue
          barMinimum
          timePeriod
          value
          property {
            property
            uom
            symbol
          }
          leftbarBenchmark {
            benchmarkCalculation
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
          }
          rightbarBenchmark {
            benchmarkCalculation
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
          }
          colourConfig {
            leftInnerBar
            leftOuterBar
            leftBenchmark
            rightBar
            rightBenchmark
          }
        }
        equipmentDataComparisionBarWidget {
          barMaximumCalculationType
          barMaximumType
          barMaximumValue
          barMinimum
          timePeriod
          value
          colourConfig {
            leftBar
            leftBenchmark
            rightBar
            rightBenchmark
          }
          leftBarProperty {
            benchmarkCalculation
            benchmarkLabel
            benchmarkValue
            benchmarkMarker
            property
            uom
            symbol
          }
          rightBarProperty {
            benchmarkCalculation
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
            property
            uom
            symbol
          }
        }  
        energyConsumptionWidget {
          comparedTo
          showTemperature
          timePeriod
          uom
          benchMark {
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
            benchmarkCalculation
          }
          colourConfig {
            leftBar
            rightBar
            rightBenchmark
            toDate
          }
        }
        energyCostWidget {
          comparedTo
          showEnergyCostSegmentation
          timePeriod
          benchMark {
            benchmarkLabel
            benchmarkMarker
            benchmarkValue
            benchmarkCalculation
          }
          colourConfig {
            leftBar
            rightBar
            rightBenchmark
            toDate
          }
        }
        energyDemandWidget {
          colourConfig {
            currentDemand
            overlayDemand
            peakDemand
          }
          rateIncrease {
            name
            rateIncreaseEnd
            rateIncreaseStart
          }
          comparedTo
          isRateIncrease
          timePeriod
        }
        widgetBuildingTarget {
          id
          name
        }
      }
    }
  }
`

export const getWeatherDetails = /* GraphQL */ `
  query getWeatherDetails($buildingId: ID!) {
    getBuilding(id: $buildingId) {
      city
      state
      postalCode
    }
   listWeatherTimeSeriesData(buildingId: $buildingId, sortDirection: DESC, limit: 1) {
      items {
        data
        buildingId
        dateTime
      }
    }
  }
`

interface WeatherDetailsBuilding {
  city: string,
  postalCode: string,
  state: string,
}

interface WeatherDetailsTimeSeries {
  buildingId: string,
  data: string,
  dateTime: string,
}

export interface WeatherDetailsTimeSeriesData {
  Wind: number,
  OutdoorAirEnthalpy: number,
  Description: 'Cloudy'|'Isolated Storms'|'Rain Showers'|'Partly Cloudy'|'Sunny'|'Mostly Sunny'|'Mostly Cloudy'|'Light Rain'|'Clear'|'Scattered Showers'|'Isolated Showers'|'Drizzle',
  RelativeHumidity: number,
  TemperatureF: number,
  OutdoorAirWetBulbTemperature: number,
  Visibility: number,
  Icon: string,
  WindDirection: string,
  Pressure: number
}

interface ParsedWeatherDetailsTimeSeries extends Omit<WeatherDetailsTimeSeries, 'data'> {
  data: WeatherDetailsTimeSeriesData
}

export interface WeatherDetailsApiSuccess {
  getBuilding?: WeatherDetailsBuilding,
  listWeatherTimeSeriesData: {
    items: WeatherDetailsTimeSeries[]
  },
}

export interface ParsedWeatherDetails extends Omit<WeatherDetailsApiSuccess, 'listWeatherTimeSeriesData'> {
  listWeatherTimeSeriesData: {
    items: ParsedWeatherDetailsTimeSeries[]
  }
}

export const CREATE_WIDGET = `mutation CreateWidget($input: CreateWidgetInput!) {
  createWidget(input: $input)
  {
    id
  }
}
`

export const UPDATE_WIDGET = `mutation UpdateWidget($input: UpdateWidgetInput!) {
  updateWidget(input: $input)
  {
    id
  }
}
`

export const GET_WIDGET = `query getWidget($widgetId: ID!) {
  getWidget(id: $widgetId) {
    accountId
    buildingId
    salesOfficeId
    building {
      name
      city
      state
      postalCode
      tz
    }
    id
    name
    description
    isKeyMetric
    isNewWindow
    widgetLink
    widgetType
    customUrl
    widgetTargetType
    widgetBuildingId
    widgetEquipment2Id
    widgeEquipment2Name
    widgeEquipment2Type
    widgeEquipment2Family
    widgeEquipment1Id
    widgeEquipment1Name
    widgeEquipment1Type
    widgeEquipment1Family
    weatherWidget {
      metricConversion
    }
    baselineId
    baselineComparision{
      timePeriod
      displayValue
      target{
        baselineId
        widgeEquipment1Id
        widgetBuildingId
        widgetTargetType
      }
    }
    equipmentCustomKpiWidget {
      kpiCurrentPeriod
      kpiDisplayDate
      kpiGoal
      kpiMeasurement
      kpiPlotType
      kpiPreviousPeriod
      timePeriod
      kpiTrend {
        actual
        date
        goal
      }
    }
    equipmentDataMonitorGuageWidget {
      guageMaximumCalculationType
      guageMaximumType
      guageMaximumValue
      guageMinimum
      needleValue
      timePeriod
      colourConfig {
        baseline
        threshold1
        threshold1Value
        threshold2
        threshold2Value
        thresholdOption
      }
      property {
        property
        uom
        symbol
      }
    }
    equipmentDataMonitorOrbWidget {
      Value
      timePeriod
      colourConfig {
        baseline
        threshold1
        threshold1Value
        threshold2
        threshold2Value
        thresholdOption
      }
      property {
        property
        uom
        symbol
      }
    }
    equipmentTrendLineWidget {
      value
      timePeriod
      comparePoint
      colourConfig {
        line
        overlayLine
      }
      property {
        property
        uom
        symbol
      }
    }
    equipmentTrendBarWidget{
      timeInterval
      timePeriod
      value
      colourConfig {
        leftBar
        leftBenchmark
      }
      property {
        property
        uom
        symbol
      }
      benchMark {
        benchmarkCalculation
        benchmarkLabel
        benchmarkMarker
        benchmarkValue
      }
    }
    equipmentTimeComparisionBarWidget {
      barMaximumCalculationType
      barMaximumType
      barMaximumValue
      barMinimum
      timePeriod
      value
      property {
        property
        uom
        symbol
      }
      leftbarBenchmark {
        benchmarkCalculation
        benchmarkLabel
        benchmarkMarker
        benchmarkValue
      }
      rightbarBenchmark {
        benchmarkCalculation
        benchmarkLabel
        benchmarkMarker
        benchmarkValue
      }
      colourConfig {
        leftInnerBar
        leftOuterBar
        leftBenchmark
        rightBar
        rightBenchmark
      }
    }
    equipmentDataComparisionBarWidget {
      barMaximumCalculationType
      barMaximumType
      barMaximumValue
      barMinimum
      timePeriod
      value
      colourConfig {
        leftBar
        leftBenchmark
        rightBar
        rightBenchmark
      }
      leftBarProperty {
        benchmarkCalculation
        benchmarkLabel
        benchmarkValue
        benchmarkMarker
        property
        uom
        symbol
      }
      rightBarProperty {
        benchmarkCalculation
        benchmarkLabel
        benchmarkMarker
        benchmarkValue
        property
        uom
        symbol
      }
    }  
    energyConsumptionWidget {
      comparedTo
      showTemperature
      timePeriod
      uom
      benchMark {
        benchmarkLabel
        benchmarkMarker
        benchmarkValue
        benchmarkCalculation
      }
      colourConfig {
        leftBar
        rightBar
        rightBenchmark
        toDate
      }
    }
    energyCostWidget {
      comparedTo
      showEnergyCostSegmentation
      timePeriod
      benchMark {
        benchmarkLabel
        benchmarkMarker
        benchmarkValue
        benchmarkCalculation
      }
      colourConfig {
        leftBar
        rightBar
        rightBenchmark
        toDate
      }
    }
    energyDemandWidget {
      colourConfig {
        currentDemand
        overlayDemand
        peakDemand
      }
      rateIncrease {
        name
        rateIncreaseEnd
        rateIncreaseStart
      }
      comparedTo
      isRateIncrease
      timePeriod
    }
    widgetBuildingTarget {
      id
      name
    }
  }
}
`
export const GET_IMAGE_WIDGET = `query getWidget($widgetId: ID!) {
  getWidget(id: $widgetId) {
    accountId
    buildingId
    building {
      name
      city
      state
      postalCode
    }
    id
    name
    description
    isKeyMetric
    isNewWindow
    widgetLink
    widgetType
    widgetTargetType
    widgetBuildingId
    widgetImage {
      items {
        displayName
        description
        buildingId
        id
        name
        title
        widgetId
        type
        sizeInBytes
      }
    }
    imageText
    imageAdditionalText
  }
}
`

export const GET_EQUIPMENT_PROPERTIES = `query getPropertyMappingByEquipmentID($equipmentId: ID!, $nextToken: String ) {
  getPropertyMappingByEquipmentID(equipmentId: $equipmentId, nextToken: $nextToken) {
    items {
      id
      propertyType
      tisDisplayName
      targetKey
      targetDataType
      targetMeasureUnit
      targetMeasureType
      sourceMeasureUnit
      sourceMeasureType
    }
    nextToken
  }
}`

export const DELETE_WIDGET = /* GraphQL */ `
  mutation deleteWidget($input: DeleteWidgetInput!) {
    deleteWidget(input: $input) {
      id
    }
  }
`

export const getAllDashboardByWidget = /* GraphQL */ `
query getWidget($id: ID!) {
  getWidget(id: $id) {
    dashboards(limit: 10000) {
      items {
        dashboard {
          id
          name
        }
      }
      nextToken
    }  
    }
  }
`
