export const KNOWN_TYPES = {
  'application/pdf': 'pdf-icon',
  'text/csv': 'csv-icon',
  'image/jpeg': 'jpg-icon',
  'image/png': 'png-icon',
  'application/vnd.ms-excel': 'xls-icon',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'xls-icon',
  other: 'generic-format'
}

export const NOTE_TYPE = {
  BASELINE: 'Baseline',
  CALCULATION_SUPPRESSION: 'Calculation Suppression',
  COPY_EQUIPMENT: 'Copy Equipment',
  DELETE_BILLING_RECORD: 'Delete Billing Record',
  DELETE_ENERGY_SPACE_DATA_RECORD: 'Delete Energy Space Data Record',
  DELETE_EQUIPMENT: 'Delete Equipment',
  DELETE_EQUIPMENT_RECORD: 'Delete Equipment Record',
  DISPLAY_SUPPRESSION: 'Display Suppression',
  EQUIPMENT: 'Equipment',
  FACILITY: 'Building',
  MOVE_EQUIPMENT: 'Move Equipment',
  NOTIFICATION_SUPPRESSION: 'Notification Suppression',
  OPPORTUNITY: 'Opportunity',
  OPTIMIZATION_TASK: 'Optimization Task',
  PERFORMANCE: 'Performance',
  SERVICE_ADVISORY: 'Service Advisory',
  SITE_DISABLED: 'Site Disabled'
}

export const FILTER_OPTIONS = [
  { key: 'ALL_NOTES', value: 'All Note Types' },
  { key: 'FACILITY', value: 'Building' },
  { key: 'EQUIPMENT', value: 'Equipment' },
  { key: 'SERVICE_ADVISORY', value: 'Service Advisory' },
  { key: 'PERFORMANCE', value: 'Performance' }
]

export const TEST_NAME_PREFIX = (id?:string) =>   `notes${id ? `_${id}` : ""}`
