export const ALL_EQUIPMENT_ITEM_ID = 0
export const ALL_EQUIPMENT_TIS_OBJECT_TYPE_ITEM_NAME = 'ALL_EQUIPMENT'
export const ALL_EQUIPMENT = 'ALL EQUIPMENT'
export const ALL_EQUIPMENT_ITEM = {
  key: ALL_EQUIPMENT_ITEM_ID,
  value: 'ALL EQUIPMENT'
}

export const THRESHOLD_LABELS = {
	leftCritical: "Lower Critical Limit",
	rightCautionary: "Upper Cautionary Limit",
	leftCautionary: "Lower Cautionary Limit",
	rightCritical: "Upper Critical Limit",
}
export const THRESHOLD_LABELS_ONESIDE = {
	leftCritical: "Critical Limit",
	leftCautionary: "Cautionary Limit",
	rightCautionary: "Cautionary Limit",
	rightCritical: "Critical Limit",
}
export const AUTOMATED_TEST_LEVELS = {
	COMBINED: "Combined",
	INDIVIDUAL: "Individual",
	INTERMEDIATE: "Intermediate",
}
export const THRESHOLD_MATCH_KEYS_LEFT = {
	leftCritical: "LowerRedLimit|lower.red.limit",
	leftCautionary: "LowerYellowLimit|lower.yellow.limit",
}
export const THRESHOLD_MATCH_KEYS_RIGTH = {
	rightCautionary: "UpperYellowLimit|upper.yellow.limit",
	rightCritical: "UpperRedLimit|upper.red.limit",
}