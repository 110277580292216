import React from 'react'
import {
  transformCharacteristicsListData,
  handleData
} from './EquipmentDetailsParser'
import { SORT_BY, SORT_TYPE } from '../CreateEquipment/constants'
import _find from 'lodash/find'
import _uniqBy from 'lodash/uniqBy'
import _orderBy from 'lodash/orderBy'
import _differenceWith from 'lodash/differenceWith'
import _toPairs from 'lodash/toPairs'
import _isEqual from 'lodash/isEqual'
import { cloneDeep, filter } from 'lodash'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import {
  BACKEND_DATE_FORMAT,
  TEXT_DATE_FORMAT_WITH_MINUTES
} from 'src/components/legacy/common/time-helpers'
import { isJsonString } from 'src/components/legacy/common/helpers'
import { getPropsByEquipmentType } from '../graphql/queries'
import { API } from 'aws-amplify'
import { ACTIONS } from 'src/constants'
import { NO_PROPERTY_SELECTED_DEFAULT_VALUE_FOR_CALCULATED_PROPERTY } from './DataMapping/constants'
export const EQ_TYPE_CHILLER = 'Chiller'
export const CHARACTERISTIC = 'Characteristic'
export const Context = React.createContext({})

export const getDiscardChangesPromptConfig = ({
  t,
  handleConfirm,
  handleCancel
}) => ({
  title: t('common:Warning'),
  text: t(
    'equipment-setup:DataMapping:EditProperty>ChangePropertyConfirmMessage'
  ),
  confirmText: t('common:Yes'),
  cancelText: t('common:No'),
  handleConfirm,
  handleCancel
})

export const getResetAllPromptConfig = ({
  t,
  handleConfirm,
  handleCancel
}) => ({
  title: t('equipment-setup:ResetAllToDefaults'),
  text: t('equipment-setup:DataMapping>ResetDataMappingText'),
  description:
    'All the default values for these properties will be restored and any user selected properties will be lost.',
  confirmText: t('common:Yes'),
  cancelText: t('common:No'),
  handleConfirm,
  handleCancel
})

export const getResetSinglePropertyConfig = ({
  t,
  modalBody,
  modalSubBody,
  handleConfirm,
  handleCancel
}) => ({
  title: t('equipment-setup:ResetToDefault'),
  text: modalBody,
  description: modalSubBody,
  confirmText: t('common:Yes'),
  cancelText: t('common:No'),
  handleConfirm,
  handleCancel
})

// It used to parse the array with string "\"SupplyFanProvingStatus\"" like this
export const parseGraphQLReponse = (plainResponse) => {
  try {
    if (plainResponse && plainResponse.length > 0) {
      const parsedResponse = plainResponse
        ?.filter((x) => x !== '')
        ?.map((item) => {
          const removeSlaces = item?.replace(/['"]+/g, '')
          const finalResult = removeSlaces?.replace(/\"\//g, '')
          return finalResult
        })
      return parsedResponse
    } else {
      return []
    }
  } catch (error) {
    return []
  }
}

// Checks conditions for prop/characteris return list with required or not
export const checkCharacteriscticsConditions = (conditionCheck) => {
  try {
    const parsedConditions = JSON.parse(conditionCheck)
    const andCondition = parsedConditions['And'] || {}
    const orCondition = parsedConditions['Or'] || {}
    const mergedConditionsObject = { ...andCondition, ...orCondition }
    const propertiesKeys = Object.keys(mergedConditionsObject)
    const propCharacteristicsFinalList = propertiesKeys.map((item) => {
      const propConditionDetails = mergedConditionsObject[item]
      return {
        name: item,
        required: propConditionDetails?.mandatoryConditional === 'mandatory'
      }
    })
    return propCharacteristicsFinalList.filter((x) => x.name && x.name !== '')
  } catch (error) {
    return []
  }
}

const valueSorter = (item) => item?.characteristicDisplayName?.toLowerCase()

const noPropertySelected = 'No Property Selected'
const calculated = 'Calculated'
const applicationCalculated = 'Application Calculated'

export const isChillerTargetableProperty = (filterAttributes, property) =>
  filterAttributes.Circuits.some((c) => property.targetKey.indexOf(c) !== -1) ||
  filterAttributes.instances.some((i) => property.targetKey.indexOf(i) !== -1)

export const getChillerFilteredPropertyMetaData = async (
  characteristicsListData,
  equipmentType
) => {
  const filter = transformChillerProperties(characteristicsListData)
  const data = await getChillerApplicableProperties(filter)
  return data
}

export const transformChillerProperties = (propertyArray) => {
  const componentPropertyArray = propertyArray?.filter(
    ({ targetKey, value }) =>
      targetKey?.toLowerCase()?.includes('compressorcountckt') &&
      typeof value === 'string' &&
      value > 0
  )
  const filteredArray = Array?.from(
    new Set(componentPropertyArray?.map((item) => item?.targetKey))
  ).map((targetKey) => ({
    targetKey,
    value: Math?.max(
      ...componentPropertyArray
        ?.filter((item) => item?.targetKey === targetKey)
        ?.map((item) => parseInt(item?.value))
    )
  }))
  const circuitInstances =
    filteredArray?.length > 1
      ? filteredArray?.map(
          ({ targetKey }) => `Ckt${targetKey?.slice(targetKey?.length - 1)}`
        )
      : ['Ckt']
  const compressorInstances = []

  if (
    (filteredArray?.length === 1 && filteredArray[0]?.value <= 1) ||
    filteredArray?.length === 0
  ) {
    compressorInstances?.push('Comp')
  } else {
    filteredArray.forEach((item) => {
      const { targetKey, value } = item
      for (let i = 1; i <= value; i++) {
        const suffix = String?.fromCharCode(i + 96)?.toUpperCase()
        compressorInstances?.push(
          `${targetKey?.slice(targetKey?.length - 1)}${suffix}`
        )
      }
    })
  }
  const condenserType = propertyArray
    ?.filter(({ targetKey }) => targetKey === 'CondenserType')?.[0]
    ?.value?.trim()
    ?.replace(/\s/g, '')
  const compressorType = propertyArray
    ?.filter(({ targetKey }) => targetKey === 'CompressorType')?.[0]
    ?.value?.trim()
    ?.replace(/\s/g, '')

  return {
    instance: ['Chiller', ...circuitInstances, ...compressorInstances],
    compCondType:
      condenserType && compressorType
        ? `${condenserType}#${compressorType}`
        : ''
  }
}

export const dataMappingPropertiesFormatter = async (
  data_Properties,
  data_Calculated,
  equipmentPropertyMetaData,
  chillerEquipmentPropertyMetaData
) => {
  try {
    let data_DataMapping = []

    if (data_Properties?.length && data_Calculated?.length) {
      data_DataMapping = [...data_Properties, ...data_Calculated]
    } else if (data_Properties?.length) {
      data_DataMapping = [...data_Properties]
    } else if (data_Calculated?.length) {
      data_DataMapping = [...data_Calculated]
    }
    // TO mark as 'Application Calculated' && 'No Property Selected' for empty source details
    if (data_DataMapping?.length) {
      data_DataMapping?.map?.((obj) => {
        if (
          obj?.tisDislayName !== null &&
          (!obj?.sourceDisplayName ||
            obj?.sourceDisplayName ===
              NO_PROPERTY_SELECTED_DEFAULT_VALUE_FOR_CALCULATED_PROPERTY) &&
          !obj?.sourceKey &&
          obj?.propertyType === calculated
        ) {
          obj.sourceDisplayName =
            obj.value ===
            NO_PROPERTY_SELECTED_DEFAULT_VALUE_FOR_CALCULATED_PROPERTY
              ? noPropertySelected
              : applicationCalculated
        }
        if (
          obj?.tisDislayName !== null &&
          !obj?.sourceDisplayName?.length &&
          !obj?.sourceKey?.length &&
          obj?.propertyType !== calculated
        ) {
          obj.sourceDisplayName = noPropertySelected
          // TODO [REMOVE] : with the existing implentation, while mapping 'No property selected' as a source, the value was containing '-'.
          // Now these values are in DEV and may be in TESTING env DB's.
          // As per new approach value will be alway empty when mapping 'No property selected'. Only constant values are being stored in value prop.
          // It's an workaround to replace them with empty value.
          // ANYONE CAN DIRECTLY REMOVE BELOW LINE AFTER SOMETIME WITHOUT VERIFYING ANY LOGIC.
          obj.value = obj.value === '-' ? '' : obj.value
        }
      })

      const uniqueRows = []
      const duplicateTisDisplayNames = []

      // To Fetch Unique tisDisplayName (if it has duplicate entries need to check for sourceDisplayName value and display it)

      const filteredData = data_DataMapping
        ?.filter((item) => item?.tisDisplayName !== '')
        .filter((propertyMapping) => {
          // Filter only isMappable to 1 to show in data mapping page
          const propertyReadableDetails = equipmentPropertyMetaData?.find(
            (x) => x.targetKey === propertyMapping.targetKey
          )
          return propertyReadableDetails?.isMappable === 1
        })

      filteredData?.forEach((item) => {
        if (!duplicateTisDisplayNames.includes(item?.tisDisplayName)) {
          uniqueRows.push(item)
          item?.tisDisplayName &&
            duplicateTisDisplayNames.push(item?.tisDisplayName)
        } else {
          const existingIndex = uniqueRows.findIndex((row) =>
            item?.tisDisplayName
              ? row?.tisDisplayName === item?.tisDisplayName
              : false
          )
          if (
            (item?.sourceDisplayName &&
              !uniqueRows[existingIndex]?.sourceDisplayName) ||
            (item?.sourceKey && !uniqueRows[existingIndex]?.sourceKey)
          ) {
            uniqueRows[existingIndex] = item
          }
        }
      })
      if (chillerEquipmentPropertyMetaData?.length > 0) {
        return uniqueRows.reduce((acc, propertyMapping) => {
          const propertyReadableDetails =
            chillerEquipmentPropertyMetaData?.find(
              (x) => x.targetKey === propertyMapping.targetKey
            )
          if (propertyReadableDetails) {
            acc.push({
              ...propertyMapping,
              tisDisplayName: propertyReadableDetails.tisDisplayName
            })
          }
          return acc
        }, [])
      }
      return uniqueRows
    } else {
      return []
    }
  } catch (error) {
    return []
  }
}

export const characterticsFormatter = (
  characteristicsListData,
  equipmentCharacteristicMetaData,
  chillerFilteredPropertyMetaData
) => {
  try {
    const characteristicsFilteredData = _orderBy(
      _uniqBy(
        transformCharacteristicsListData(
          handleData(characteristicsListData, equipmentCharacteristicMetaData),
          equipmentCharacteristicMetaData
        ).characteristicsLists,
        SORT_BY.characteristicDisplayName
      ),
      [valueSorter],
      SORT_TYPE.asc
    )
    if (chillerFilteredPropertyMetaData?.length > 0) {
      return characteristicsFilteredData.reduce((acc, propertyMapping) => {
        const propertyReadableDetails = chillerFilteredPropertyMetaData?.find(
          (x) => x.targetKey === propertyMapping.targetKey
        )
        if (propertyReadableDetails) {
          acc.push({
            ...propertyMapping,
            characteristicDisplayName: propertyReadableDetails.tisDisplayName
          })
        }
        return acc
      }, [])
    }
    return characteristicsFilteredData || []
  } catch (error) {
    return []
  }
}

export const formCreateCharacteristicInput = (
  values,
  buildingId,
  equipmentId,
  userInfo,
  mode
) => {
  const characteristicCurveDatas = values?.characteristicCurvePointList?.filter(
    (point) => point.curveXAxisValue && point.curveYAxisValue
  )
  const xAxis =
    values?.xAxis.charAt(0) === '@' ? values?.xAxis : `@${values?.xAxis}`
  const input = {
    name: values?.name || '',
    buildingId,
    equipmentId,
    curveType: values?.curveType,
    isActive: 1,
    runAnalytic: values?.runAnalytic ? 1 : 0,
    xAxisTargetKey: xAxis,
    yAxisTargetKey: values?.curveType,
    minValueXAxis: values?.xAxisRange?.from,
    maxValueXAxis: values?.xAxisRange?.to,
    minValueYAxis: values?.yAxisRange?.from,
    maxValueYAxis: values?.yAxisRange?.to,
    instance: `CKT${values?.circuitNumber || 1}`,
    userId: userInfo?.id,
    characteristicCurveDatas: characteristicCurveDatas?.map((point) =>
      JSON.stringify(point)
    ),
    ...(mode === ACTIONS.EDIT
      ? { updatedBy: userInfo?.email }
      : {
          createdBy: userInfo?.email
        })

    // creationDate: String
  }
  return input
}

export const getEditDifferences = (newObj, oldObj) => {
  const newInput = cloneDeep(newObj)
  newInput['points'] = newObj.characteristicCurveDatas
    ?.map((point) => JSON.parse(point))
    ?.map((point) => {
      return { X: point.curveXAxisValue, Y: point.curveYAxisValue }
    })
  delete newInput.characteristicCurveDatas
  delete newInput.userId
  delete newInput.createdBy
  const oldInput = cloneDeep(oldObj)
  oldInput['points'] = oldObj.characteristicCurveDatas
    ?.map((point) => JSON.parse(point))
    ?.map((point) => {
      return { X: point.curveXAxisValue, Y: point.curveYAxisValue }
    })
  delete oldInput.characteristicCurveDatas
  delete oldInput.userId
  delete oldInput.createdBy
  const changes = _differenceWith(
    _toPairs(newInput),
    _toPairs(oldInput),
    _isEqual
  )
  let differences = {}
  changes.forEach((change) => {
    differences[
      change[0] === 'characteristicCurveDatas' ? 'points' : change[0]
    ] = {
      old: oldInput[change[0]],
      new: newInput[change[0]]
    }
  })
  return differences
}

export const frameHistoryChangesData = (changedValue) => {
  let tableData = []
  if (!_isEmpty(changedValue)) {
    tableData = Object.keys(changedValue)?.map((keyName) => {
      return {
        setting: keyName,
        previousValue: changedValue[keyName].old,
        currentValue: changedValue[keyName].new
      }
    })
  }
  return tableData
}

export const deriveRevertInputs = (
  curveEditedValue,
  ccCurveId,
  userInfo,
  buildingId
) => {
  const editInput = cloneDeep(curveEditedValue)
  const changedValue = JSON.parse(curveEditedValue.changedValue)
  delete editInput.userId
  delete editInput.changedValue
  delete editInput.createdAt
  delete editInput.id

  const changedData = {}
  const revertChangedValue = {}
  Object.keys(changedValue)?.forEach((keyName) => {
    revertChangedValue[keyName] = {
      new: changedValue[keyName].old,
      old: changedValue[keyName].new
    }
    if (keyName === 'points') {
      changedData['characteristicCurveDatas'] = changedValue?.points.old?.map(
        (point) => {
          const pointValue = {
            curveYAxisValue: point.Y,
            curveXAxisValue: point.X
          }
          return JSON.stringify(pointValue)
        }
      )
    } else {
      changedData[keyName] = changedValue[keyName].old
    }
  })
  const editQueryInput = {
    ...editInput,
    ...changedData,
    id: ccCurveId,
    isActive: 1,
    buildingId
  }
  const editHistoryInput = {
    ...editInput,
    ...changedData,
    parentCurveId: ccCurveId,
    createdBy: userInfo?.email,
    userId: userInfo?.id,
    buildingId,
    changedValue: JSON.stringify(revertChangedValue)
  }
  return { editQueryInput, editHistoryInput }
}

export const formatServerLocalTime = (lastCollectedLocalTime) => {
  try {
    const dateSplittedArray = lastCollectedLocalTime?.split('T')
    const date = dateSplittedArray?.[0] || new Date()
    const hourAndMinSplit = dateSplittedArray?.[1]?.split(':')
    const hour = hourAndMinSplit[0]
    const minutes = hourAndMinSplit[1]
    return moment(`${date} ${hour}:${minutes}`).format(
      TEXT_DATE_FORMAT_WITH_MINUTES
    )
  } catch (e) {
    return '--'
  }
}

const formatLatestValuesData = (data, properties) => {
  const latestValues = []
  const propertyPositions = data?.properties
  for (const date in data?.values) {
    const values = data?.values[date]
    for (const timestamp in values) {
      const dateAndTime = moment(`${date}`)
        .add('minutes', timestamp * 15)
        .format(TEXT_DATE_FORMAT_WITH_MINUTES)
      const propertyValuesArray = values[timestamp]
      properties?.map((propertyKey) => {
        const index = propertyPositions[propertyKey]?.index
        const propertyValue = propertyValuesArray[index]
        latestValues.push({
          timestamp: dateAndTime,
          value: propertyValue,
          propertyKey
        })
      })
    }
  }

  return latestValues || []
}

export const getLastestValuesOfProperties = async (
  dataMappingProperties,
  deviceDetails,
  equipmentDetails,
  getPropertyLatestValues
) => {
  try {
    if (dataMappingProperties?.length > 0 && deviceDetails) {
      const lastCollectedLocalTime =
        deviceDetails?.deviceConnection?.items?.[0]?.connectionTimeLocal
      const startDate =
        lastCollectedLocalTime?.split('T')?.[0] ||
        moment(new Date()).format(BACKEND_DATE_FORMAT)
      const endDate = startDate
      const pIds = dataMappingProperties?.map((x) => x.targetKey)
      if (pIds?.length > 0) {
        const chartDataRequestFormat = {
          startDate: startDate,
          endDate: endDate,
          category: 'equipment', // equipment or building level data needed
          chartType: 'line',
          building: {
            id: equipmentDetails?.building?.id,
            pId: [],
            type: ''
          },
          equipment: [
            {
              eId: [{ id: equipmentDetails.id }],
              type: equipmentDetails.type,
              pId: [
                {
                  ['latest']: pIds
                }
              ]
            }
          ],
          equipmentType: equipmentDetails.type,
          weather: {},
          settings: []
        }
        const requestBody = { body: JSON.stringify(chartDataRequestFormat) }
        const chartData = await getPropertyLatestValues(requestBody)
        const parsedChartData = JSON.parse(chartData)
        const lastestValues = formatLatestValuesData(
          parsedChartData?.body?.data?.[equipmentDetails.id],
          pIds
        )
        const dataMappingWithLastValue = dataMappingProperties?.map((x) => {
          const propertyLastestValue = lastestValues?.find(
            (y) => y?.propertyKey === x?.targetKey
          )
          // Last time stamp should be shown only for mapped properties
          const valueMapped =
            x?.value === undefined ||
            x?.value === null ||
            x?.value === '' ||
            x?.value === '-'
              ? ''
              : x?.value

          // For calculated properties, when mapping `Application Calculated` as a value for a property, sourceKey will be empty.
          // if Application Calculated is mapped, assigning some static value to mark sourceKey mapped
          const sourceKeyMapped =
            x?.sourceKey === '' ||
            x?.sourceKey === undefined ||
            x?.sourceKey === null
              ? 'Application Calculated' === x?.sourceDisplayName
                ? 'Application Calculated is mapped'
                : ''
              : x?.sourceKey

          // Last time stamp should be shown only for mapped properties
          const isPropertyMapped =
            valueMapped !== '' ||
            sourceKeyMapped !== '' ||
            x?.sourceDisplayName !== noPropertySelected

          return {
            ...x,
            lastCollectedValue: propertyLastestValue?.value,
            lastCollectedTime: isPropertyMapped
              ? propertyLastestValue?.timestamp
              : null
          }
        })
        return dataMappingWithLastValue
      }
    } else {
      return dataMappingProperties
    }
  } catch (e) {
    console.log(e)
  }
}

export const fetchPropertiesByEquipmentId = async (
  filterVariable = null,
  type = 'Chiller'
) => {
  try {
    const { data } = await API.graphql({
      query: getPropsByEquipmentType,
      variables: {
        filter:
          filterVariable && filterVariable?.length > 0
            ? {
                equipmentType: { eq: type },
                isRDR: { eq: 1 },
                sourceEquipmentType: { eq: 'NA' },
                sourceFamilyType: { eq: 'NA' },
                or: [...filterVariable]
              }
            : {
                equipmentType: { eq: type },
                isRDR: { eq: 1 },
                sourceEquipmentType: { eq: 'NA' },
                sourceFamilyType: { eq: 'NA' }
              }
      }
    })
    const uniqueProperties = _uniqBy(
      data?.searchPropertyMetadata?.items,
      'targetKey'
    )
    const newProperties = uniqueProperties?.map(
      ({ id, targetKey, propertyType, tisDisplayName }) => {
        let propName = targetKey
        try {
          propName =
            propertyType === CHARACTERISTIC
              ? `${tisDisplayName} ***`
              : tisDisplayName
        } catch (error) {
          console.warn(error)
        }
        return {
          name: propName ? propName : targetKey,
          targetKey,
          tisDisplayName,
          id,
          value: id,
          propertyType
        }
      }
    )
    return newProperties
  } catch (e) {
    console.error(e)
  }
}

export const getChillerApplicableProperties = async (selectedEquipment) => {
  const selectedEquipmentObjects = [selectedEquipment]
  const chillerFilter = []
  let compCondTypeFilter = []
  let instanceFilter = []
  selectedEquipmentObjects?.forEach(({ compCondType, instance }) => {
    const selectedChillerFilter = []

    if (compCondType && isJsonString(compCondType)) {
      compCondTypeFilter = JSON.parse(compCondType?.replace(/'/g, '"'))?.map(
        (item) => ({ compCondType: { matchPhrasePrefix: item } })
      )
    } else if (compCondType && compCondType?.includes('[')) {
      compCondTypeFilter = [
        {
          compCondType: {
            matchPhrasePrefix: compCondType.replace(/[\[\]]/g, '')
          }
        }
      ]
    } else if (compCondType && compCondType !== ' ') {
      compCondTypeFilter = [
        { compCondType: { matchPhrasePrefix: compCondType } }
      ]
    }
    if (instance && isJsonString(instance)) {
      instanceFilter = JSON.parse(instance?.replace(/'/g, '"'))?.map(
        (item) => ({ instance: { eq: item } })
      )
    } else if (instance && Array.isArray(instance)) {
      instanceFilter = instance?.map((item) => ({
        instance: { eq: item }
      }))
    }

    if (compCondTypeFilter?.length > 0) {
      selectedChillerFilter?.push({ or: compCondTypeFilter })
    }
    if (instanceFilter?.length > 0) {
      selectedChillerFilter?.push({ or: instanceFilter })
    }
    if (selectedChillerFilter?.length > 0) {
      chillerFilter.push({ and: selectedChillerFilter })
    }
  })
  try {
    const properties = await fetchPropertiesByEquipmentId(
      chillerFilter,
      selectedEquipment?.type
    )
    return properties
  } catch (e) {
    console.log(e)
    return []
  }
}

/**
 * An async function to reset given properties from characteristics and data mapping pages
 *
 * @param {*} asyncApifn
 * @param {*} param1
 */
export async function resetToDefaultsByProperties(
  asyncApifn,
  {
    equipmentId,
    dataMappingEquipmentId,
    deviceId,
    propertyId,
    propertyType,
    propertyIds = []
  }
) {
  if (
    !equipmentId ||
    !dataMappingEquipmentId ||
    !deviceId ||
    !propertyType ||
    !propertyIds.length
  ) {
    await Promise.resolve(
      'Any of the required inputs are missing to reset properties. Please check.'
    )
  }

  let promises = []

  if (propertyType === 'Characteristics') {
    const input = JSON.stringify({
      data: {
        equipmentId,
        dataMappingEquipmentId,
        deviceId,
        propertyId,
        characteristicId: propertyIds,
        propertyType
      }
    })

    promises.push(
      asyncApifn({
        input
      })
    )
  } else {
    promises = propertyIds.reduce((av, propertyId) => {
      if (!propertyId) return av

      const input = JSON.stringify({
        data: {
          equipmentId,
          dataMappingEquipmentId,
          propertyId,
          deviceId,
          propertyType
        }
      })

      av.push(
        asyncApifn({
          input
        })
      )

      return av
    }, [])
  }

  // handle Multiple reset call by properteis
  try {
    await Promise.all(promises)
  } catch (error) {
    console.log('Unable to reset to default by properties.', error)
  }
}
