import clsx from 'clsx'
import { Fragment, memo, MouseEvent, useEffect, useState } from 'react'

function HeatMapRect({
  data = [],
  enableHighlight = false,
  showText = false,
  timeout = 100,
  useLazyRenderingByIdleCallback = false,
  useLazyRenderingBySetTimeout = false,
  useNativeRendering = true,
  rectProps = {},
  textProps = {}
}: HeatMapRectComponentProps) {
  const [state, setState] = useState([])

  useEffect(() => {
    if (data.length === 0) {
      return () => null
    }
    // Renders given data at a time.
    if (useNativeRendering) {
      setState(data)
    }

    /**
     * In order to render huge amount of element (more than 1 L), Two techniques are implemeted to split the task into multiple chunks of taks
     *
     * 1. use setTimeout - avoid rendering huge amount of element at time.
     *
     * 2. use requestIdleCallback - let browser to use idle time to render the tasks. so task will render sequantially
     *
     */

    if (useLazyRenderingBySetTimeout) {
      setTimeout(() => {
        setState(data)
      }, timeout)
    }

    /**
     * BEWARE!—requestIdleCallback()
     *
     *   Schedules tasks at the lowest possible priority, and only during browser idle time
     *   When the main thread is congested, tasks scheduled with requestIdleCallback() may never get to run.
     *
     *   For more details : https://developer.chrome.com/blog/using-requestidlecallback
     *
     * TODO : requestIdleCallback can be replaced with postTask. Due to browser support, currently not implemented.
     *         In future, it can be implemented
     *
     *          For more details : https://developer.mozilla.org/en-US/docs/Web/API/Scheduler/postTask
     *
     */
    if (useLazyRenderingByIdleCallback) {
      window.requestIdleCallback(
        () => {
          setState(data)
        },
        { timeout: 0 }
      )
    }
  }, [data])

  if (!data.length) return null

  return (
    <>
      {state.map((r: HeatMapData) => {
        const key = '_heat_map_rect_' + r.uuid

        return (
          <Fragment key={key}>
            <rect
              className={clsx('t-chart-heatmap-rect', {
                highlight: enableHighlight
              })}
              x={r.x}
              y={r.y}
              width={r.width}
              height={r.height}
              key={key}
              fill={r.color as string}
              {...rectProps}
              onMouseOver={(event) =>
                rectProps.onMouseOver(
                  event as MouseEvent<SVGRectElement>,
                  r,
                  key
                )
              }
              onMouseLeave={(event) =>
                rectProps.onMouseLeave(
                  event as MouseEvent<SVGRectElement>,
                  r,
                  key
                )
              }
            ></rect>
            {showText && (
              <text
                className="t-chart-heatmap-rect-text"
                x={r.x}
                y={r.y}
                font-family="Verdana"
                fill="black"
                key={'_text_' + key + r.uuid}
                dominant-baseline="middle"
                text-anchor="middle"
                pointerEvents="none"
                {...textProps}
              >
                {r.value}
              </text>
            )}
          </Fragment>
        )
      })}
    </>
  )
}

export default memo(HeatMapRect, (prevProps, nextProps) => {
  return prevProps.data === nextProps.data
})
