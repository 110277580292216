export const CONNECTION_TYPES = [
  { key: 'no_property_selected', value: 'No Property Selected' },
  { key: 'application_calculated', value: 'Application Calculated' }
]

export const NO_PROPERTY_SELECTED = CONNECTION_TYPES[0]

export const APPLICATION_CALCULATED = CONNECTION_TYPES[1]

export const NO_PROPERTY_SELECTED_DEFAULT_VALUE_FOR_CALCULATED_PROPERTY = "-"

export const EMPTY_ROW = 'NULL99999'

export const NA = 'N/A'
export const INTERVAL_OPTIONS = [
  {
    key: '15',
    value: '15'
  }
]
export const POLARITIES = {
  NORMAL: 'Normal',
  REVERSED: 'Reversed'
}

export const PROPERTY_TYPES = {
  SYSTEM_PROPERTY: 'System Property',
  CONSTANT: 'Constant'
}

export const LOAD_VALVE_SOURCES = {
  SOURCE_OBJECT: 'Source Object',
  TIS_OBJECT: 'TIS Object'
}

export const DATA_TYPES = {
  ENUMERATED: 'Enumerated',
  NUMERIC: 'Numeric',
  STRING: 'String'
}

export const DATA_SOURCE_TYPES = {
  ANALOG: 'Analog Input',
  BINARY: 'Binary Input',
  MULTISTATE: 'Multi State Input'
}

export const ANALOGPOINT_TYPE = 'ANALOGPOINT'
export const NIAGARA_AX = 'Niagara AX'
export const GAS_METER = 'GMET'
export const ELECTRIC_METER = 'EMET'

export const getPolarityOptions = [
  { label: 'Normal', value: 1, htmlFor: 'normal' },
  { label: 'Reversed', value: 0, htmlFor: 'reversed' }
]

export const getPropertyTypeOptions = (t) => [
  {
    key: PROPERTY_TYPES.SYSTEM_PROPERTY,
    value: t(
      'equipment-setup:DataMapping:EditProperty:PropertyTypeOptions>SystemProperty'
    )
  },
  {
    key: PROPERTY_TYPES.CONSTANT,
    value: t(
      'equipment-setup:DataMapping:EditProperty:PropertyTypeOptions>Constant'
    )
  }
]

export const getLoadValveSourceOptions = (t) => [
  {
    key: LOAD_VALVE_SOURCES.SOURCE_OBJECT,
    value: t(
      'equipment-setup:DataMapping:EditProperty:LoadValveSourceOptions>SourceObject'
    )
  },
  {
    key: LOAD_VALVE_SOURCES.TIS_OBJECT,
    value: t(
      'equipment-setup:DataMapping:EditProperty:LoadValveSourceOptions>TISObject'
    )
  }
]
