import Table from '../../components/Table/clientSideTable'
import Content from 'src/components/Content'
import { LIST_PERMISSIONS_BY_APP_ID } from './queries'
import { useQuery } from 'src/hooks/APIHooks'
import { NewStyles } from 'src/NewStyles'
import translate from '../../common/translations'
import { pages } from 'src/pages/pages.js'
import { useNavigate } from 'react-router'
import { ACTIONS } from 'src/constants'


export const ApplicationPermissions = ({appId}) => {
  const navigate = useNavigate()

  const optionsTranslated = {
    name: translate('Name'),
    type: translate('Type'),
    resource: translate('Resource Types'),
    actions: translate('Actions')
  }

  const {data: applicationRoles, responseTime} = useQuery({
    query: LIST_PERMISSIONS_BY_APP_ID,
    variables: {
      id: appId,
      limit: 1000
    },
    errorPolicy: 'all',
    dataPath: 'data.listPermissionsByAppId.items'
  })


  const nameHeadings = [
    {
      name: 'name',
      title: optionsTranslated.name,
      key: 'name',
      maxWidth: '120px'
    },
    {
      name: 'type',
      title: optionsTranslated.type,
      key: 'type',
      maxWidth: '120px'
    },
    {
      name: 'resourceTypes',
      title: optionsTranslated.resource,
      key: 'resourceTypes',
      maxWidth: '120px'
    },
    {
      name: 'actions',
      title: optionsTranslated.actions,
      key: 'actions',
      disabled: true,
      alignRight: true
    }
  ]


  return (
    <NewStyles>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <Table
            key={`applicationRolesTable-${appId}`}
            rows={applicationRoles || []}
            header={nameHeadings}
            loadTime={responseTime}
            search={true}
            searchFields={['name']}
            rowControl={[{ text: 'Edit', action: (data) => navigate(
              pages['Permission Management/:id'].href.replace(":id", data.id),
              {state:{mode:ACTIONS.EDIT}}
            ) }]}
            filtersList={[{
              label: 'All Types',
              name: 'type',
              key: 'type',
              id: 'typeSelectorFilter',
              defaultLabel: 'All Types',
              selectedValue: 'default',
              options: [
                { name: "API", value: 'API' },
                { name: "UI", value: 'UI' },
              ]
            }]}
          />
        </Content>
    </NewStyles>
  )
}
