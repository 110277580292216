import React from 'react'
import Label from '../label/label.jsx'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './index.scss'
import i18next from 'i18next'
import { TranslateComponent } from 'src/common/translations'

const ModalTextAreaInput = ({
  value,
  defaultValue,
  hasError,
  labelText,
  isRequired,
  isDisabled,
  inputClass,
  onChange,
  wrapperClassName = "",
  errorMessage,
  testName='',
  hideErrorIcon = true,
  inputProps = {},
  maxLength = null,
}) => {
  const textareaProps = {
    className: classNames(`modal-textarea-input ${inputClass}`, {
      'has-error': hasError
    }),
    disabled: Boolean(isDisabled),
    onChange,
    maxLength: maxLength,
    ...((defaultValue && { defaultValue }) || { value }),
    ...inputProps
  }

  const defaultErrorText = `${i18next.t('errors:field-is-required', {
    label: labelText
  })}.`

  return (
    <div className={`modal-textarea-wrapper ${wrapperClassName}`} data-testid={testName + 'text-area'}>
      {labelText && (
        <Label
          text={
            <span className={classNames({ 'has-error': hasError })}>
              <TranslateComponent>{labelText}</TranslateComponent>
              {Boolean(isRequired) && '*'}
            </span>
          }
        />
      )}
      <div>
        <textarea data-testid={testName} {...textareaProps} />
        {hasError && hideErrorIcon && <span className="icon icon-error" />}
      </div>
      {hasError && (
        <span className="mini-error-text">
          {errorMessage || defaultErrorText}
        </span>
      )}
    </div>
  )
}

ModalTextAreaInput.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  hasError: PropTypes.bool,
  labelText: PropTypes.string,
  isRequired: PropTypes.bool,
  inputClass: PropTypes.string,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  errorMessage: PropTypes.string
}

ModalTextAreaInput.defaultProps = {
  hasError: false,
  labelText: '',
  isRequired: true,
  inputClass: '',
  defaultValue: '',
  value: ''
}

export default ModalTextAreaInput
