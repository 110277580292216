import { Fragment, useMemo } from 'react'

const PatternedRect = (props: any) => {
  const patternId = useMemo(() => {
    return (+new Date() + Math.random()).toString()
  }, [])
  const {
    x,
    y,
    patternWidth=10,
    patternHeight=10,
    width,
    height,
    fill,
    onMouseOver = () => null,
    onMouseLeave = () => null,
    patternUnits = 'userSpaceOnUse',
    patternTransform = 'rotate(45)',
    Pattern
  } = props

  return (
    <Fragment>
      {Pattern && (
        <defs>
          <pattern
            id={patternId}
            patternUnits={patternUnits}
            width={patternWidth}
            height={patternHeight}
            patternTransform={patternTransform}
          >
            <Pattern />
          </pattern>
        </defs>
      )}
      <g onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <rect x={x} y={y} width={width} height={height} fill={fill} />
        {Pattern && (
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            fill={`url(#${patternId})`}
          />
        )}
      </g>
    </Fragment>
  )
}

export default PatternedRect
