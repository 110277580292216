import * as yup from 'yup'

const REQUIRED = 'required'
const schema = () =>
  yup.object().shape({
    name: yup.string().required(REQUIRED),
    description: yup.string().notRequired(),
    clientIds: yup.array().transform(value => {
      try {
        return JSON.parse(value)
      } catch (e) {
        return false
      }
    }).required(REQUIRED)
  })

export const ERROR_MESSAGES = {
  name: 'Please enter a name',
  clientIds: "Client ids must be a list in array format: [\"ClientId1\", \"ClientId2\"]"
}

export default schema
