import { createContext, useContext, useEffect, useState } from 'react'
import LegendRegisterProvider from './LegendApiProvider'

export interface LegendUIContext {
  isContextEnabled: boolean
  legendComponentProps: any
  legendDetails: {
    [key: string]: any[]
  }
  selectedLegendItem?: {
    [key: string]: any
  }
}

const defaultUiContext: LegendUIContext = {
  isContextEnabled: false,
  legendComponentProps: {},
  legendDetails: {}
}

const LegendUiContext = createContext<LegendUIContext>(defaultUiContext)

export function useLegendUiContext() {
  return useContext(LegendUiContext)
}

export default function LegendProvider(props: any) {
  const [lengedState, setLegendState] = useState<LegendUIContext>({
    ...defaultUiContext,
    isContextEnabled: true
  })

  useEffect(() => {
    // returns disabled legend items to integration component
    const items = []
    const selectedItems = Object.values(lengedState?.legendDetails || {})?.map(
      (x) => {
        x?.map((legends) => {
          return legends?.items?.map((y) => {
            if(y?.disabled === true) {
              items.push(y)
            }
          })
        })
      }
    )
    props?.getSelectedItems?.(items)
    
  }, [lengedState?.legendDetails])
  return (
    <LegendUiContext.Provider value={lengedState}>
      <LegendRegisterProvider updateState={setLegendState}>
        {props.children}
      </LegendRegisterProvider>
    </LegendUiContext.Provider>
  )
}
