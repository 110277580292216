import ContainerWrapper from 'src/components/Container'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import ImageTextWidgetForm from './ImageTextWidgetForm'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import {BUILDING} from 'src/components/object-picker/helpers.js'
import ObjectPickerWrapper from 'src/components/object-picker'
import { getFormattedResponseData } from '../../helpers'
import { useSelector } from 'react-redux'

const ImageTextWidget = ({ setWidgetFormik }) => {
  const { width } = useBreakPoint()
  const { setWidgetBuilding, objectPickerError, setObjectPickerSelectedValue, widgetDetails, isLoading, mode, setWidgetSalesOfficeId } =
    useWidgetsContext()
  const organizationId = useSelector(
      (state: any) => state?.buildingPicker?.selectedOrganization
    )

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <ContainerWrapper
          sColumns={12}
          mColumns={12}
          lColumns={12}
          xlColumns={12}
          background="#eee"
        >
          <Content
            xlColumn={4}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
            background="#fff"
          >
             <ObjectPickerWrapper
                objects={["", BUILDING]}
                initialValues={getFormattedResponseData(mode, widgetDetails, true)}
                update={(items) => {
                  if (items?.length > 0) {
                    const pickerValue = items[0] ?? {}
                    setObjectPickerSelectedValue(items)
                    setWidgetBuilding({
                      id: pickerValue?.buildingId,
                      name: pickerValue?.buildingName
                    })
                    setWidgetSalesOfficeId(pickerValue?.salesOfficeId)
                  } else {
                    setObjectPickerSelectedValue(null)
                    setWidgetBuilding(null)
                    setWidgetSalesOfficeId(null)
                  }
                }}
                buildingSelectLimit={1}
                mode={mode}
                error={objectPickerError}
                orgIdFromParent={organizationId}
              />
          </Content>
          <Content
            xlColumn={8}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <ImageTextWidgetForm setWidgetFormik={setWidgetFormik} />
          </Content>
        </ContainerWrapper>
      )}
    </>
  )
}

export default ImageTextWidget
