import { useTranslation } from 'react-i18next'
import {
  DataMonitoringConfigurationWrapper
} from '../../styles'
import { useFormikContext } from '../MultiTabTorm'
import {
  getNeedleOptionsList,
  getTimePeriodOptionsList
} from '../../helpers'
import * as Yup from 'yup'
import i18next from 'i18next'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import WidgetPropertyUom from '../widgetsPropertyUom/WidgetPropertyUom'

export const OrbConfigSchema = Yup.object().shape({
  property: Yup.string().test('testRequiredValue', () => i18next.t('errors:PropertyRequired'),testRequiredValue),
  unitsOfMeasure: Yup.string().test('testRequiredValue', () => i18next.t('errors:UomRequired'),testRequiredValue),
  needleValue: Yup.string().required(i18next.t('errors:RequiredFields')),
  timePeriod: Yup.string().required(i18next.t('errors:TimePeriodRequired'))
})
function testRequiredValue(value){
  return !(!value)
}
const DataMonitoringOrbConfiguration = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, errors, setFieldValue } = formik
  return (
    <DataMonitoringConfigurationWrapper>
      <div className="configuration-tab-main">
        <div>
          <WidgetPropertyUom transFunc = {t} setFieldValue={setFieldValue} values={values} errors={errors} />
          <div style={{ display: 'flex' }}>
          <SelectFormInput
            fieldsetClass="inline-2"
            labelName={"Value"}
            optionsList={getNeedleOptionsList(t)}
            placeholder={"— Select One —"}
            update={(value)=> {
              setFieldValue('needleValue', value)
            }}
            errorItem={errors?.needleValue}
            selectedItem={t(`time>${values?.needleValue}`, {
              nsSeparator: '>'
            })}
          />
          </div>
          <div style={{ display: 'flex' }}>
          <SelectFormInput
            fieldsetClass="inline-2"
            labelName={"Time Period"}
            optionsList={getTimePeriodOptionsList(t)}
            placeholder={"— Select One —"}
            update={(value)=> {
              setFieldValue('timePeriod', value)
            }}
            errorItem={errors?.timePeriod}
            selectedItem={t(`time>${values?.timePeriod}`, {
              nsSeparator: '>'
            })}
          />
          </div>
        </div>
      </div>
    </DataMonitoringConfigurationWrapper>
  )
}

export default DataMonitoringOrbConfiguration
