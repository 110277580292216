export const startMapDownload = `mutation createCustomConfiguration ($equipmentId: String!) {
        createCustomConfiguration(equipmentId: $equipmentId)
  }
  `

export const mapDownloadStatus = `query listConfigurationMessageHistoryByDevice($deviceId: ID!, $configurationMessageGroupId: Int) {
  listConfigurationMessageHistoryByDevice(deviceId: $deviceId, limit: 1000, configurationMessageGroupId: {eq: $configurationMessageGroupId},  sortDirection: DESC) {
    nextToken
    items {
      configurationMessageGroupId
      messageStatus
      createdAt
    }
  }
 
}`

export const getDeviceMessageGroupId = `query getDevice($id: ID!) {
    getDevice(id: $id) {
        deviceId
        id
        recentConfigurationMessageStatus
        deviceConnection {
            items {
              connectionTimeLocal
              connectionTimeUTC
        }
      }
      building {
        tz
      }
}
  }`
