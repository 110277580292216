import { formatNumberToStandardPrecision } from "src/common/standard-precision-format"
import { formatCurrencyPrecision } from "src/common/currency"


export const formatUnitOfMeasurmentNumberFormat = d => formatNumberToStandardPrecision(d)


export const formatDollarLabel = d => d < 0 ? `-$${formatCurrencyPrecision(d).substring(1)}` : `$${formatCurrencyPrecision(d)}`


export const numberWithCommas = x => {
	const parts = x.toString().split(".")
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	return parts.join(".")
}
