import React, { PureComponent } from 'react'
import Modal from '../modal/modal'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import './index.scss'

class Notification extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.string,
    cancelText: PropTypes.string,
    handleCancel: PropTypes.func,
    autoClose: PropTypes.number,
    t: PropTypes.func.isRequired
  }

  static defaultProps = {
    autoClose: 3000
  }

  render() {
    const { title, text, description, cancelText, handleCancel, autoClose, t } =
      this.props
    return (
      <Modal
        heading={title}
        buttons={[
          {
            type: 'confirm',
            text: cancelText || t('common:Close'),
            handleClick: handleCancel
          }
        ]}
        handleClose={handleCancel}
        autoClose={autoClose}
      >
        <div className="notification-dialog">
          {/* {(typeof text === "string")
						? <span dangerouslySetInnerHTML={{ __html: text }} />
						: text
					} */}
          {/* In general, setting HTML from code is risky because it’s easy to inadvertently expose your users to a cross-site scripting (XSS) attack. */}
          {text}
          {description && <p className="description">{description}</p>}
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(Notification)
