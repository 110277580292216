import { useState } from "react"
import { newRefreshToken } from "../redux/slicers/appData"
import { useDispatch } from 'react-redux'
import _uniqBy from 'lodash/uniqBy'



export const useMultipleBuildingsAccess = () => {

    const [fetchingToken, setFetchingToken] = useState(false)
    const [hasUnAuthorizedBuildsingsSelected, setUnAuthorizedBuildingsSelected] = useState(false)
    const [userSelectedbuildings, setUserSelectedbuildings] = useState([])
    const [unAuthorizedBuildingsList, setUnAuthorizedBuildingsList] = useState([])
    const [refreshTokenError, setRefreshTokenError] = useState(false)
    // const userAccessBuildings = useSelector(selectUserAccessibleBuildingIds)

    const dispatch = useDispatch()

    const setSeletectedBuildings = async (selectedBuildings = [], claims=[]) =>  {
        if(selectedBuildings.length > 0) {
            try {
                const uniqBIds = _uniqBy(selectedBuildings, o => o)
                setFetchingToken(true)
                setRefreshTokenError(false)
                setUserSelectedbuildings(uniqBIds)
                await dispatch<any>(newRefreshToken(uniqBIds, null, null, claims))
            } catch(error) {
                setRefreshTokenError(true)
            } finally {
                setFetchingToken(false)
            }
        } 
    }

    // Just in case wanted to reset all states
    // This can be used
    const resetAll = () => {
        setFetchingToken(false)
        setUnAuthorizedBuildingsSelected(false)
        setUserSelectedbuildings([])
        setUnAuthorizedBuildingsList([])
        setRefreshTokenError(false)
    }

    // useEffect(()=>{
    //         // Token has buildings list selected in building drop down along with builidngs selected in picker
    //         if(userAccessBuildings.length > 0 && userAccessBuildings.length !== userSelectedbuildings.length + 1) {
    //             const notAccessibleBuildlings = []
    //             userSelectedbuildings.map(( selectedBuildingId ) => {
    //                 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //                 // @ts-ignore
    //                 const asscessibleBuildingId = userAccessBuildings?.filter((x) => x === selectedBuildingId) 
    //                 if(!asscessibleBuildingId) {
    //                     notAccessibleBuildlings.push(selectedBuildingId)
    //                 }
    //             })
    //             setUnAuthorizedBuildingsList(notAccessibleBuildlings)
    //             setUnAuthorizedBuildingsSelected(true)
    //         } else {
    //             setUnAuthorizedBuildingsList([])
    //             setUnAuthorizedBuildingsSelected(false)
    //         }
    // },[userAccessBuildings])

    return {
        fetchingToken,
        hasUnAuthorizedBuildsingsSelected,
        unAuthorizedBuildingsList,
        setSeletectedBuildings, 
        refreshTokenError,
        resetAll
    }
}