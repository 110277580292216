import colors from 'src/components/legacy/common/colors.json'
import typography from "src/denali-ui/legacy/common_file/typography"
import styled from "styled-components"


export const ObjectPickerHeaderWrapper = styled.section`
  
`

export const Wrapper = styled.div<{ error: any }>`
    background-color: #f4f4f4;
    border: ${(props) =>
      props.error ? '2px solid #d52b23;' : '1px solid #999;'}
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height:  ${(props: any)=> props?.fullHeight === true ? '100%' : '520px'} ;
    margin-top: 7px;
    min-height: ${(props: any)=> props?.fullHeight === true ? 'inherit' : '520px'} ;
    min-width: 200px;
    position: relative;
    z-index: 9;
`

export const HeaderLabel = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 900;
`

export const PickerHeader = styled.div<{ error: any }>`
  ${(props) => props.error && 'color: #d52b23;'}
  background-color: #f4f4f4;
  font-size: 13px;
  font-weight: 700;
  height: 26px;
  letter-spacing: 0.26px;
  line-height: 26px;
  overflow: hidden;
  padding: 0 25px 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const SearchDiv = styled.div`
  background-color: #ccc;
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
`

export const SearchInput = styled.input`
    background: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 9px;
    font-family: Archivo Narrow,sans-serif;
    font-size: 12px;
    font-weight: 700;
    height: 18px;
    letter-spacing: .24px;
    line-height: 14px;
    padding: 0 6px;
    width: 100%;
}
`

export const DataHeader = styled.span<{ error: any }>`
  ${(props) => props.error && `background-color: #fea7aa; color: #d52b23;`}
  display: block;
  border-bottom: 1px solid #ccc;
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  font-weight: 900;
  height: 20px;
  letter-spacing: 0.24px;
  line-height: 19px;
  margin: 0;
  padding: 0 3px;
  text-transform: uppercase;
`

export const NavigateBack = styled.div`
  & div {
    ${typography("text-list-condensed")}
    flex-wrap: bold;
    border: none;
    border-radius: 0;
    height: 20px;
    background-color: ${colors.TBA_DARKER_GREY};
    color: white;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0 10px 0 0;

    span {
      flex: 1;
      ${typography("text-ellipsis")};
      color: ${colors.WHITE};
    }

    &::before {
      width: 18px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Emerald;
      font-size: 12px;
    }

    &:hover {
      color: ${colors.TBA_LIGHT_GREY};
      cursor: default;
    }
  }
`

export const List = styled.ul`
  flex: 1;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
`

export const ListItem = styled.li`
  padding-left: 4px;
  background-color: #fff;
  box-sizing: border-box;
  color: #000;
  display: flex;
  font-family: Lato, sans-serif;
  font-size: 12px;
  height: 20px;
  line-height: 16px;
  list-style-type: disc;
  min-height: 20px;
  text-align: left;
  user-select: none;
  width: 100%;
  label {
    display: contents;
  }
`

export const ListItemText = styled.span`
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin-left: 5px;
`

export const BottonSection = styled.section`
  display: block;
  flex: none;
  flex: 1 0 0px;
  min-height: 0;
  position: relative;
  z-index: 99;
`

export const BottomList = styled.ul`
  background-color: #f4f4f4;
  padding: 0 0 16px;
  flex: 1;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;
  line-height: 20px;
`

export const BottomListItem = styled.li`
  background-color: #f4f4f4;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  width: 100%;
  display: flex;
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  height: 18px;
  letter-spacing: 0.24px;
  line-height: 19px;
  line-height: normal;
  padding: 0 10px 0 8px;
  position: relative;
`

export const BottomHead = styled.h3`
  background-color: #f4f4f4;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.24px;
  line-height: 19px;
  margin: 0;
  padding: 0 10px;
`

export const BottomText = styled.span`
  line-height: 18px;
  max-width: calc(100% - 18px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const FilterByText = styled.span`
  line-height: 18px;
  max-width: calc(100% - 18px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-size: 10px;
  letter-spacing: .24px;
  width: 100%;
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 5px;
`

export const StyledDiv = styled.div`
  border-bottom: 1px solid ${colors.TBA_LIGHT_GREY};
  height: inherit;
  overflow-y: scroll;
`

export const StyledDivFoot = styled.div`
  overflow-y: scroll;
 
`
export const NoObjectsAvailable = styled.section`
  padding: 10px;

  & p {
    margin: 0 0 10px 0;
    font-weight: bold;

    &:first-child {
      font-size: 14px;
      line-height: 18px;
    }

    &:last-child {
      font-weight: bolder;
      font-size: 12px;
      line-height: 14px;
    }
  }
`

export const HeadWrapper = styled.div`
  background-color: #ccc;
  display: flex;
  flex-direction: column;
  width:100%;
  cursor:pointer;
  .subHead{
    padding-bottom: 5px;
    display: block;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: .24px;
    line-height: 10px;
  }
`

export const ErrorText = styled.span`
    font-weight: bold;
    font-size: 13px;
    color: #D52B23;
`
export const SearchInputWrapper = styled.div`
display: flex;
background: ${colors.TBA_LIGHTEST_GREY};
border-radius: 9px;
   input{
    background :transparent!important;
    border:none !important;
    :focus{
      background :transparent!important;
      border:none!important;
      box-shadow: none!important;
    }
  }
  :focus-within {
    background: ${colors.WHITE};
    }
}
`


export const StyleSeperator = styled.div`
    height: 12px;
    justify-content: center;
    left: 50%;
    margin-left: -14px;
    top: -6px;
    width: 28px;
    z-index: 101;
    cursor: ns-resize	;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
    background-color: ${colors.TBA_DARKER_GREY};
    border: 1px solid ${colors.TBA_DARK_GREY};
    margin-right: auto;
    position: relative;
    padding: 2px;
  div{
    width: 20px;
    height: 1px;
    background-color: white;
    margin: 0.5px 0;
    display: flex;
    justify-self: center;
    align-items: center;
  }
`
export const IconSpan = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    color: #666;
`

export const VirtualIcon = styled.span`
margin-top: 3px;
`
