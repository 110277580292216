import React, { createContext, useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useWidgetsContext } from '../WidgetsContextProvider'
import { WidgetNames, isPresentObject } from '../helpers'
import isEqual from 'lodash/isEqual'

const FormikContext = createContext<any>(null)

const combinedSchema = (schemas) => {
  return schemas.reduce((combinedSchema, schema) => {
    return combinedSchema.concat(schema)
  }, Yup.object())
}
const WidgetBuildingFieldName = 'widgetBuilding'
const BaselineId = 'baselineId'

const MultiTabForm = ({
  initialValues,
  onSubmit,
  schemas,
  children,
  setWidgetFormik
}) => {
  const { widgetBuilding, setObjectPickerError, setFormikErrors, isSubmitted, isFormValidationAlone, objectPickerSelectedValue, selectedWidget, previousObjectPickerSelectedValue } =
    useWidgetsContext()
  const formik = useFormik({
    initialValues: { ...initialValues, activeTab: 0 },
    onSubmit,
    validationSchema: isSubmitted || isFormValidationAlone ? combinedSchema(schemas) : null,
    enableReinitialize: true
  })

  useEffect(() => {
    setWidgetFormik(formik)
  }, [])

  useEffect(() => {
    const { setFieldValue } = formik
    setFieldValue(
      WidgetBuildingFieldName,
      widgetBuilding ? widgetBuilding?.id : ''
    )
  }, [widgetBuilding])

  useEffect(() => {
    setFormikErrors(formik?.errors)
    setObjectPickerError(
      isPresentObject([WidgetBuildingFieldName,BaselineId], formik?.errors)
    )
  }, [formik?.errors])
  
  const [isObjectPickerValueChange, setIsObjectPickerValueChange] = useState(false)

  useEffect(() => {
    const { setFieldValue } = formik
    const isObjectChange = isEqual(previousObjectPickerSelectedValue, objectPickerSelectedValue)
    if(isObjectChange || !isObjectPickerValueChange) return
    if ([WidgetNames.TimeComparisonBarChart, WidgetNames.Orb, WidgetNames.Gauge, WidgetNames.TrendBarChart, WidgetNames.TrendLineChart, WidgetNames.DataComparisonBarChart].includes(selectedWidget?.type)) {
     if (WidgetNames.DataComparisonBarChart === selectedWidget?.type) {
          setFieldValue("leftProperty", '')
          setFieldValue("leftUnitsOfMeasure", '')
          setFieldValue("rightProperty", '')
          setFieldValue("rightUnitsOfMeasure", '')
      } else {
        if(!objectPickerSelectedValue){
          setFieldValue("property", '')
          setFieldValue("unitsOfMeasure", '')
        }
      }
    }
  }, [objectPickerSelectedValue])

  useEffect(() => {
    setIsObjectPickerValueChange(true)
  }, [previousObjectPickerSelectedValue])
  

  return (
    <FormikContext.Provider value={formik}>
      <form onSubmit={formik.handleSubmit}>{children}</form>
    </FormikContext.Provider>
  )
}

export const useFormikContext = () => {
  return useContext(FormikContext)
}

export default React.memo(MultiTabForm)
