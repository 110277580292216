import { memo, useEffect, useMemo } from 'react'
import Line from '../../CommonComponents/Line/Line'
import { getTextSpaceDetails } from 'src/chart-library/Utils/TextSpace/TextSpace'
import usePlotlineTooltip from './usePlotlineTooltip'

const PlotLine = (props: any) => {
  const {
    axisType = '',
    scale = null,
    width,
    height,
    plotObj,
    defaultFrom = null,
    defaultTo = null,
    id,
    onMouseEnter = null,
    onMouseLeave = null
  } = props

  const {
    from,
    to,
    textAnchor,
    textX,
    textY,
    rectX,
    rectY,
    dominantBaseline,
    fontSize,
    fontColor,
    textWidth,
    textHeight
  } = useMemo(() => {
    const from: any = { x: 0, y: 0 }
    const to: any = { x: 0, y: 0 }
    const textPosition = plotObj?.textPosition
    let scaledValue = scale && scale(plotObj?.value)
    //TODO : workaround for time being. - Interanlly scale is not being created if there is no domain values provide

    // Plot lines depends on the scale of axis, It works fine when scale is created with domain values
    // But scale is returning NaN when there is no domain values. So assigning 0 by default when values is NaN
    scaledValue = isNaN(scaledValue) ? 0 : scaledValue

    const fontSize = plotObj?.text?.fontSize || 14
    const fontColor = plotObj?.text?.fontColor || 'black'
    const textAnchor = 'middle'
    let textX
    let textY
    const strokeWidth = plotObj?.strokeWidth > 0 ? plotObj?.strokeWidth : 0
    let dominantBaseline = plotObj?.text?.dominantBaseline || 'auto'
    const startP = plotObj?.text?.customPosPer || 5
    const endP = plotObj?.text?.customPosPer || 5
    if (axisType === 'y') {
      from.y = scaledValue
      to.y = scaledValue
      to.x = width
      textX = textPosition?.x
        ? textPosition?.x
        : plotObj?.text?.alignment === 'start'
        ? width * (startP / 100)
        : plotObj?.text?.alignment === 'end'
        ? width * ((100 - endP) / 100)
        : width / 2
      textY =
        dominantBaseline === 'auto'
          ? scaledValue - strokeWidth
          : dominantBaseline === 'hanging'
          ? scaledValue + strokeWidth
          : scaledValue
    }
    if (axisType === 'x' && scaledValue) {
      from.x = scaledValue
      to.x = scaledValue
      to.y = height
      textY = textPosition?.y
        ? textPosition?.y
        : plotObj?.text?.alignment === 'start'
        ? height * (startP / 100)
        : plotObj?.text?.alignment === 'end'
        ? height * ((100 - endP) / 100)
        : height / 2
      textX = scaledValue
      dominantBaseline = 'middle'
    }
    let textWidth = 0
    let textHeight = 0

    if (plotObj?.text?.background) {
      const { width = 0, height = 0 } = getTextSpaceDetails({
        fontSize,
        content: plotObj?.name
      })
      textHeight = height
      textWidth = width
    }

    let rectX
    let rectY
    if (axisType === 'x') {
      rectX = textX - (textWidth + textWidth * 0.1) / 2
      rectY = textY - (textHeight + textHeight * 0.1) / 2
    }
    if (axisType === 'y') {
      rectX =
        plotObj?.text?.alignment === 'start'
          ? textX - (textWidth * 0.1) / 2
          : plotObj?.text?.alignment === 'end'
          ? textX - textWidth - (textWidth * 0.1) / 2
          : textX - textWidth / 2 - (textWidth * 0.1) / 2
      rectY =
        dominantBaseline === 'auto'
          ? textY - (textHeight - (textHeight * 0.1) / 2)
          : dominantBaseline === 'middle'
          ? textY - (textHeight / 2 + (textHeight * 0.1) / 2)
          : textY - textHeight * 0.1
    }

    return {
      from,
      to,
      textAnchor,
      textX,
      textY,
      rectX,
      rectY,
      dominantBaseline,
      fontSize,
      fontColor,
      textWidth,
      textHeight
    }
  }, [scale, plotObj, axisType, width, height, plotObj?.textPosition])

  usePlotlineTooltip({
    from: defaultFrom ? defaultFrom : from,
    to: defaultTo ? defaultTo : to,
    props: { ...plotObj }
  })

  return (
    <g>
      <Line
        from={defaultFrom ? defaultFrom : from}
        to={defaultTo ? defaultTo : to}
        stroke={plotObj?.stroke}
        strokeWidth={plotObj?.strokeWidth}
        dashType={plotObj?.dashType}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <g id={id}>
      {!plotObj?.text?.hide && plotObj?.name && (
        <>
          {plotObj?.text?.background && rectX && rectY && (
            <rect
              x={rectX}
              y={rectY}
              width={textWidth + textWidth * 0.1}
              height={textHeight + textHeight * 0.1}
              fill={plotObj?.text?.background?.fill}
              stroke={plotObj?.text?.background?.stroke}
              strokeWidth={plotObj?.text?.background?.strokeWidth}
              fillOpacity={plotObj?.text?.background?.fillOpacity || 0.3}
            />
          )}
          <text
            fill={fontColor}
            fontSize={fontSize}
            x={textX}
            y={textY}
            textAnchor={textAnchor}
            dominantBaseline={dominantBaseline}
          >
            {plotObj?.name}
          </text>
        </>
      )}
      </g>
    </g>
  )
}

export default memo(PlotLine)
