import { get } from '../ui-system/core'

/**
 * Method to get a value from theme
 *
 * @param {*} path path of a key in theme object (ex : colors.primary)
 * @param {*} [fallback=null] fallback value if given key or value does not exists
 * @return {*} value against given key
 */
function getTheme(path, fallback = null) {
  return (props) => get(props.theme, path, fallback)
}

export default getTheme
