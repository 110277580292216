import { StyledBreadcrumbHeader } from './style'
import { useLocation, useParams, matchRoutes, useNavigate } from 'react-router-dom'
import IconSvg from '../Icon/index.js'
import { pageRoutes, pages, indexPage } from 'src/pages/pages'
import { sections } from 'src/pages/sections'
import styled from 'styled-components'
import { TranslateComponent } from 'src/common/translations'

const BreadCrumbSection = styled.h2`
  display: flex;
  align-items: center;
  width: 95%;
  margin: 0 20px 0 64px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
`

const TitleSpan = styled.span`
  color: #fff;
  margin-left: 9px;
  font-size: 13px;
`

const BreadcrumbLink = styled.a`
  color: #fff;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
`

export const Breadcrumbs = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const routes = matchRoutes(pageRoutes, location)
  let currentPage
  if (routes?.length > 0) {
    const [{ route }] = routes
    currentPage =
      route.path === '/'
        ? indexPage
        : pages[Object.keys(pages).find((p) => pages[p].href === route.path)]
  }
  const params = useParams()
  return (
    currentPage && (
      <header>
        <StyledBreadcrumbHeader>
          <BreadCrumbSection>
            <IconSvg
              name={sections[currentPage.section].icon}
              margin="0px 0px 0px 10px"
              color="#fff"
              hover="#fff"
              width="15px"
            />
            <TitleSpan><TranslateComponent>{sections[currentPage.section].name}</TranslateComponent></TitleSpan>
            {currentPage.breadcrumbs
              ? currentPage.breadcrumbs.map((b) => {
                  let href = b.href
                  for (let i = 0; i < Object.keys(params).length; i++) {
                    href = href
                      ? href.replace(
                          `:${Object.keys(params)[i]}`,
                          `${params[Object.keys(params)[i]]}`
                        )
                      : undefined
                  }
                  return (
                    <span key={b.name}>
                      <IconSvg
                        name={'right-caret'}
                        margin="0px 0px 0px 10px"
                        color="#fff"
                        hover="#fff"
                        width="15px"
                      />
                      <BreadcrumbLink onClick={() => navigate(href)}>
                       <TranslateComponent>{b.name}</TranslateComponent>
                        </BreadcrumbLink>
                    </span>
                  )
                })
              : ''}
          </BreadCrumbSection>
        </StyledBreadcrumbHeader>
      </header>
    )
  )
}

export default Breadcrumbs
