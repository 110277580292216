import { StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

export const footerStyles = StyleSheet.create({
	footer: {
		position: "absolute",
		bottom: 30,
		left: 30,
		right: 30,
		borderTop: `4 solid ${colors.GREY_6}`
	},
	wrapper: {
		position: "relative",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-end",
		flexDirection: "row",
		flexWrap: "nowrap",
		lineHeight: 1.2,
		marginLeft: "1px"
	},
	copyRight:{
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "row",
		lineHeight: 1.2,
	},
	imageWrapper: {
		width: '10px',
		height: '10px',
	},
	textWrap:{
		marginLeft: "2px"
	},
	textWrapper:{
        marginLeft: "-7px"
	},
	logo: {
		width: 65,
		height: 23,
		position: "absolute",
		right: 0,
		bottom: 0
	},
	licenseWrapper: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		paddingTop: 6,
		fontSize: 8,
		color: colors.GREY_4,
	},
	pageCount: {
		marginRight: 6,
		color: colors.BLACK,
		fontWeight: "bold",
	},
})
