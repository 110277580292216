import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './toggle-box.scss'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import { TranslateComponent , translate} from 'src/common/translations'

export default class ToggleBox extends PureComponent {
  static propTypes = {
    label: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    opened: PropTypes.bool,
    showOnlyLabel: PropTypes.bool,
    customAction: PropTypes.node,
    errors: PropTypes.array,
    testName: PropTypes.string
  }

  static defaultProps = {
    opened: false,
    showOnlyLabel: false,
    errors: [],
    testName:''
  }

  state = {
    opened: this.props.opened,
    height: this.props.opened ? null : 0,
    overflow: this.props.opened ? null : 'hidden'
  }

  animationFrame = null
  resetHeightTimeout = null

  customActionRef = React.createRef()

  toggleBox = () => {
    clearTimeout(this.resetHeightTimeout)

    if (this.state.height === null) {
      this.setInitialHeight(this.slideToggle)
    } else {
      this.slideToggle()
    }
  }

  slideToggle = () => {
    const opened = !this.state.opened
    const height = opened ? this.toggleBoxBody.scrollHeight : 0

    this.animationFrame = requestAnimationFrame(() => {
      this.setState(
        { opened, height, overflow: 'hidden' },
        () => opened && this.resetHeight()
      )
    })
  }

  setInitialHeight = (callback) => {
    this.animationFrame = requestAnimationFrame(() => {
      this.setState({ height: this.toggleBoxBody.scrollHeight }, callback)
    })
  }

  resetHeight = () => {
    // resets Toggle Box height after animation finished (400ms), to make ToggleBox resizable
    this.resetHeightTimeout = setTimeout(() => {
      this.setState({ height: null, overflow: null })
    }, 400)
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationFrame)
    clearTimeout(this.resetHeightTimeout)
  }

  render() {
    const { label, children, className, showOnlyLabel, customAction, errors, testName } =
      this.props
    const { opened, height, overflow } = this.state

    return (
      <section
        data-testid={`${testName}_container`}
        className={`toggle-box-container ${opened ? 'expanded' : ''} ${
          className ? className : ''
        }`}
      >
        <label
          className="toggle-box-label"
          onClick={(e) => {
            if (
              e.currentTarget.contains(e.target) &&
              !this.customActionRef.current.contains(e.target)
            ) {
              e.preventDefault()
              this.toggleBox()
            }
          }}
        >
          <div className="icon-expand icon-toggle" />
          <div className="label">
            {showOnlyLabel
              ? ''
              : translate(`${opened ? 'Hide' : 'Show'}`)}
              <TranslateComponent>{label}</TranslateComponent>
            
            {errors.map((err, key) => (
              <div className="error-message" key={key}>
                <span className="icon icon-alarm-bell" />
                <span className="error-text">
                  <Trans i18nKey={err} />
                </span>
              </div>
            ))}
          </div>
          <div className="custom-action" ref={this.customActionRef}>
            {customAction}
          </div>
        </label>

        <fieldset
          style={{ height, overflow }}
          ref={(el) => (this.toggleBoxBody = el)}
          className="toggle-box-body"
        >
          <div className="toggle-box-body-inner">{children}</div>
        </fieldset>
      </section>
    )
  }
}
