import styled, { css } from 'styled-components'
import colors from 'src/components/layouts/colors.json'
const screen_medium_min_width = '914px'
const screen_grid_small = '599px'
import { Button } from 'src/components/inputs/button'
import { PREVIEW_BUILDING_DATA_PAGE_NAMES } from 'src/pages/buildingData/BuildingDataContainer'
import { CSVLink } from 'react-csv'

export const BuildingDataHeader = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  label {
    margin-right: 10px;
  }
`

export const NewChartText = styled.span`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 700;
  margin-left: 20px;
  cursor: pointer;
`

export const ChartObjectPickerArea = styled.div`
  height: calc(100% - ${(props) => props.calcPointsHeight}px);
`

export const ObjectPickerArea = styled.div`
  padding: 20px 10px;
  min-width: 220px;
  min-height: 550px;
  height: 100%;
  border: 1px solid ${colors.TBA_DARKER_GREY};
  background: ${colors.TBA_LIGHTER_GREY};
  z-index: 1;
  position: relative;
  height: ${(props) => props.height}px;
  ${(props) =>
    props.calculateModal &&
    css`
      border: 1px solid ${colors.WHITE};
      background: ${colors.WHITE};
      padding: 10px 10px;
    `}

  button {
    margin-top: 10px;
  }

  p {
    font-weight: 800;
    font-size: 14px;
  }

  .calculated-points-list {
    width: 100%;
    margin-top: 10px;
    border: 1px solid ${colors.TBA_LIGHTER_GREY};
    .calculated-points-header {
      padding: 3px;
      border: 1px solid ${colors.TBA_DARKER_GREY};
      background: ${colors.TBA_LIGHTER_GREY};
      font-weight: 700;
      font-size: 13px;
    }

    .points-list-container {
      max-height: 69px;
      overflow: scroll;
      scroll: auto;
    }

    .points-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: ${colors.WHITE};
      border: 1px solid ${colors.TBA_DARKER_GREY};
      font-size: 14px;
      padding: 3px 6px;
      font-weight: 500;
      border-top: none;
    }
  }
`

export const CSVLinkDownload = styled(CSVLink)`
  font-size: 18px;
  color: ${colors.TBA_DARKER_GREY};
  margin: 5px;
  cursor: pointer;
`

export const ActionIcons = styled.span`
  font-size: 18px;
  color: ${(props) =>
    props.isSelected ? colors.TBA_DARK_GREEN : colors.TBA_DARKER_GREY};
  margin: 5px;
  cursor: pointer;
`

export const ToolBarWrapper = styled.div`
  align-items: center;
  display: flex;
  a {
    margin-right: 8px;
  }
`

export const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const PickerButtonsWrapper = styled.div`
  height: 55px;

  button[data-testid='formula_button'] {
    border-radius: 0px;
    width: 150px;
    color: ${colors.WHITE};
    height: 27px;
    background-color: ${colors.TBA_DARK_GREEN};
  }

  button[data-testid='plot_button'] {
    margin-top: 10px;
    float: right;
    border-radius: 0px;
    width: 70px;
    height: 27px;
    background-color: ${colors.TBA_DARK_GREEN};
  }

  button[data-testid='disabled_button'] {
    margin-top: 10px;
    border-radius: 0px;
    width: 150px;
    color: ${colors.BLACK};
    height: 27px;
    background-color: ${colors.TBA_LIGHTER_GREY};
    pointer-events: none;
    cursor: not-allowed;
  }
`

export const PlotButton = styled(Button)`
  color: red;
  margin: 0 auto;
`

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 250px;

  ${(props) =>
    props.width <= 633 &&
    `
  & .select-wrapper {
    align-self: center;
    max-height: 50px;
    min-width: 250px;
    max-width: none;
    position: relative;
   
    
   }
      `}
  @media  (max-width: ${screen_grid_small}) {
    & .select-wrapper {
      align-self: center;
      max-height: 50px;
      min-width: 250px;
      max-width: none;
      position: relative;
    }
  }

  & .rs-wrapper {
    width: 250px;
  }
  .error-text {
    color: ${colors.RED};
  }

  .selected-item-end-icon {
    color: #61ae34;
  }
  .select-menu-item:not(:hover) .select-menu-item-end-icon {
    color: #61ae34;
  }
`

export const FormulaEditorWrapper = styled.div`
  .modal-edit-textarea-wrapper {
    .mini-error-text {
      font-size: 10px;
      font-style: italic;
      color: #d52b23;
    }

    .modal-calc-formula-input {
      width: 100%;
      display: inline-block;
      background: ${colors.WHITE};
      border: 1px solid ${colors.TBA_DARKER_GREY};
      border-radius: 2px;
      font-weight: bold;
      padding: 2px;
      outline: none;

      div[contenteditable] {
        min-height: 150px;
      }

      :focus {
        border: 1px solid ${colors.TBA_DARKER_GREY};
      }

      p {
        font-weight: bold;
        font-size: 15px;
        font-style: normal;
        line-height: 26px;
      }

      .calcPropToken {
        display: inline-block;
        vertical-align: baseline;
        margin: 0 4px 0 4px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        &.ProseMirror-selectednode > span > span {
          background: $tba-light-green;
        }

        > span {
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }

        > span > span {
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          color: rgb(66, 82, 110);
          cursor: pointer;
          white-space: nowrap;
          line-height: 16px;
          font-size: 12px;
          font-weight: bold;
          background: ${colors.WHITE};
          border-width: 1px;
          border-style: solid;
          border-color: ${colors.TBA_DARKER_GREY};
          border-image: initial;
          border-radius: 5px;
          padding: 2px 5px 2px 5px;
        }
      }
      &.has-error {
        border-width: 2px;
        border-color: #d52b23;
        background-color: #fea7aa;
      }
    }
  }
`

export const CalculatedPointsFormWrapper = styled.div`
  background: white;
  padding: 20px;
  height: 95%;
  .calc-directions-text {
    color: #999;
    font-size: 14px;
    font-style: italic;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .calc-tip-text {
    font-style: italic;
    font-weight: 100;
    margin-top: 40px;
  }
  & .modal-text-wrapper .custom-label {
    padding: 0px !important;
  }
  .calc-uom-text {
    color: ${colors.RED};
  }
`

export const UOMWrapper = styled.div`
  display: flex;
  align-items-center;
  justtify-content:space-around;

`

export const Input = styled.input`
  width: 100%;
  ${({ hasError }) =>
    hasError &&
    css`
      color: #262626;
      background: ${colors.RED_1};
      border-color: ${colors.RED};
      font-size: 16px;
      letter-spacing: 0.32px;
      line-height: 15px;
    `}
`

export const TabText = styled.span`
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 900;
  line-height: 36px;
  padding: 9px 39px 17px 17px;
`

export const SaveChartModalContainer = styled.div`
  padding: 10px;

  & .modal-text-wrapper .custom-label {
    padding: 15px 0 0 0px !important;
  }
`
export const DashboardModalContainer = styled.div`
  & .modal-text-wrapper .custom-label {
    padding: 15px 0 0 0px !important;
  }
`
export const LegendLine = styled.hr`
  width: 25px;
  height: 3px;
  margin: 5px;
  border-bottom: 3px solid ${(props) => props?.color};
`

export const ChartLegendsContainer = styled.div`
  ${(props) =>
    props?.pageName === PREVIEW_BUILDING_DATA_PAGE_NAMES?.dashboard
      ? css`
          display: flex;
          flex-wrap: wrap;
          padding: 0px 5px;
          margin-top: 10px;
          height: ${props?.height};
        `
      : css`
          display: grid;
          padding: 10px 5px;
          margin: 0px 20px;
          margin-top: 20px;
          min-height: ${(props) => props?.height};
          grid-template-columns: 50% 50%;
        `}
`

export const NoDataMessage = styled.p`
  font-weight: 700;
  font-size: 14px;
  padding: 20px;
  border: 1px solid lightgray;
`
