import { Dispatch, SetStateAction, useState } from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  flip,
  useInteractions,
  useDismiss,
  useRole,
  useHover,
  useFocus,
  type FloatingContext
} from '@floating-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft
} from 'src/denali-components/lib/pro-solid-svg-icons'
import styles from './sidebar.module.scss'
import { TranslateComponent } from 'src/common/translations'
import { useTranslation } from 'react-i18next'

export const MenuToggle = ({
  menuIsOpen,
  setMenuIsOpen
}: {
  menuIsOpen: boolean
  setMenuIsOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { refs, floatingStyles, context } = setupFloating(isOpen, setIsOpen)
  const { getReferenceProps, getFloatingProps } = setupInteractions(context)
  const [t] = useTranslation()

  return (
    <>
      <button
        className={styles.toggle}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        ref={refs.setReference}
        {...getReferenceProps()}
        aria-label={t('common:ToggleMenu')}
        data-testid="menuToggle"
      >
        {menuIsOpen ? (
          <FontAwesomeIcon icon={faChevronLeft} />
        ) : (
          <FontAwesomeIcon icon={faChevronRight} />
        )}
      </button>
      {isOpen && (
        <div
          className={styles.tooltip}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <TranslateComponent>
            {menuIsOpen ? 'Collapse Menu' : 'Expand Menu'}
          </TranslateComponent>
        </div>
      )}
    </>
  )
}

function setupFloating(
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
) {
  return useFloating({
    placement: 'right',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(18), flip(), shift()]
  })
}

function setupInteractions(context: FloatingContext) {
  const hover = useHover(context)
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)
  return useInteractions([hover, focus, dismiss, role])
}
