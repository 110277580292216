import {CRITERIA_TYPE } from './constants'

export const isSelectedDeviceNax = (deviceData,id) =>
/NAX/i.test(deviceData.find((d) => d.id === id)?.type || '')

export const isNaxType = (type) => /NAX/i.test(type)

export const isSCGType = (type) => type === 'SCG'

export const isConnectivityModule = (type) => type === 'CM'

export const isNaxCriteriaType = (type) => /priority/i.test(type)

export const getCriteriaType = (type) =>
isNaxType(type)
  ? CRITERIA_TYPE.PRIORITY
  : isSCGType(type) || isConnectivityModule(type)
  ? CRITERIA_TYPE.CLASS
  : CRITERIA_TYPE.CATEGORY
