import Table from '../../components/Table/clientSideTable'
import { useEffect, useState } from 'react'
import { LIST_NOTES_BY_BUILDING_ID } from './graphql/queries'
import { GET_PRE_SIGNED_URL, CREATE_ENTRY_WITH_ATTACHMENT } from './graphql'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import PageHeader from '../../components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import { useLocation, useSearchParams } from 'react-router-dom'
import NoteDetails from './NoteDetails/NoteDetails'
import { ACTIONS } from 'src/constants'
import {
  newRefreshToken,
  selectUserAccess,
  selectUserInfo
} from 'src/redux/slicers/appData'
import { GET_BUILDING_BY_ID } from '../buildingSetup/graphql/queries'
import Modal from 'src/components/legacy/components/modal/modal'
import { ConfirmationText } from 'src/components/layouts'
import moment from 'moment/moment'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip.jsx'
import { KNOWN_TYPES } from './constants'
import Icon from 'src/denali-ui/components/Icon'
import { saveAs } from 'file-saver'
import { useSelector } from 'react-redux'
import { AccessControl, accessControlFunc } from 'src/components/accessControl'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { TEST_NAME_PREFIX } from 'src/pages/notes/constants'
import { validate as isValidUUID } from 'uuid'

import translate, { TranslateComponent } from 'src/common/translations'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

const PanelLabel = styled.span`
  display: block;
  height: 16px;
  width: 100%;
  font-size: 18px !important;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 15px;
`

const attachmentsComponent = (attachmentList, downloadFileFunc) => {
  try {
    const attachments = attachmentList?.filter((file) => !file?.fileError)
    return (
      <div style={{ display: 'flex' }}>
        {attachments?.map((a) => (
          <ConditionalTooltip
            key={a.id}
            type={ConditionalTooltip.TYPE.DEFAULT}
            align={ConditionalTooltip.ALIGN.CENTER}
            content={a.name}
          >
            <Icon
              name={KNOWN_TYPES[a.type] || KNOWN_TYPES['other']}
              onClick={() => downloadFileFunc(a.name)}
            />
          </ConditionalTooltip>
        ))}
      </div>
    )
  } catch (error) {}
}

export const Notes = () => {
  const { buildingId: location, equipment } = getSearchParams()
  const [searchParams] = useSearchParams()
  const [mode, setMode] = useState<any>(null)
  const [deleteNote, setDeleteNote] = useState(null)
  const [data, setData] = useState([])
  const [initialValues, setInitialValues] = useState({
    name: '',
    addComment: '',
    id: null,
    type: 'Building',
    visibleToCustomer: false,
    allowCustomerContribution: false,
    equipment: null,
    histories: null,
    attachments: [],
    userId: '',
    currentUserId: '',
    createdBy: '',
    createdByName: '',
    createdAtDetailTimestamp: null
  })
  const userInfo = useSelector(selectUserInfo)
  const userAccess = useSelector(selectUserAccess)
  const { pathname } = useLocation()
  const keys = pathname?.split('/')
  const [notesInput, setNotesInput] = useState(initialValues)
  const [initial, setInitial] = useState(1)
  const optionsTranslated = {
    createdOn: translate('Created On'),
    lastUpdated: translate('Last Updated'),
    noteName: translate('Note Name'),
    createdBy: translate('Created by'),
    noteType: translate('Note Type'),
    equipment: translate('Equipment'),
    attachments: translate('Attachments'),
    viewNotesWarningMsg: translate('Select a building to view notes')
  }
  const dispatch = useDispatch()

  useEffect(() => {
    if (keys?.includes('add') && initial) {
      // This check is not to show add modal, if the location id is GEN4 one
      // we may rmeove this check after sometime
      if (location && isValidUUID(location)) {
        setInitial(0)
        setMode(ACTIONS.ADD)
        setNotesInput({
          ...initialValues,
          ...{
            type: equipment ? 'Equipment' : 'Building',
            currentUserId: userInfo?.id,
            userId: userInfo?.id,
            createdByName: `${userInfo?.firstName ? userInfo.firstName : ''} ${
              userInfo?.lastName ? userInfo.lastName : ''
            }`
          }
        })
      }
    }
  }, [keys])

  const {
    data: buildingData,
    loading: loading2,
    refetch: refetchBuildingById
  } = useQuery({
    query: GET_BUILDING_BY_ID,
    errorPolicy: 'all',
    dataPath: 'data.getBuilding',
    disableInitialLoad: true
  })

  const {
    data: data1,
    refetch: refetchData1,
    loading: loading1,
    responseTime: responseTime1
  } = useQuery({
    query: LIST_NOTES_BY_BUILDING_ID,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.listNotesByBuilding.items'
  })

  const { onSubmit: getPreSignedUrlMutation } = useMutation({
    query: GET_PRE_SIGNED_URL
  })

  const { onSubmit: notesDeletionMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT,
    onSuccess: () => {
      refetchData1({ id: location })
      setDeleteNote(null)
    }
  })

  const downloadFileFunc = async (name) => {
    try {
      return await getPreSignedUrlMutation({
        input: `{"action": "DOWNLOAD", "key": "${name}", "buildingId": "${location}" }`
      }).then(async (res) => {
        const requestOptions: RequestInit = {
          method: 'GET',
          redirect: 'follow',
          headers: { 'Content-Type': '' }
        }
        return await fetch(
          JSON.parse(res.data.getPreSignedUrl).body,
          requestOptions
        )
          .then(async (response) => {
            const responseBlob = await response.blob()
            return await saveAs(responseBlob, name)
          })
          .catch((error) => console.log('error', error))
      })
    } catch (error) {}
  }

  const addFileFunc = async (e) => {
    return await getPreSignedUrlMutation({
      input: `{"action": "UPLOAD", "key": "${e.name}", "buildingId": "${location}" }`
    }).then(async (res) => {
      const requestOptions: RequestInit = {
        method: 'PUT',
        body: e,
        redirect: 'follow',
        headers: { 'Content-Type': '' }
      }
      return await fetch(
        JSON.parse(res.data.getPreSignedUrl).body,
        requestOptions
      ).catch((error) => console.log('error', error))
    })
  }

  useEffect(() => {
    if (location && !loading1) {
      refetchData1({ id: location })
    }
    if (location && !loading2) {
      refetchBuildingById({ id: location })
    }
  }, [location, searchParams])

  useEffect(() => {
    try {
      if (data1) {
        setData(
          // Some data from backend comes as null, so checking for null before map
          data1
            ?.filter((x) => x !== null)
            ?.map((e) => ({
              ...e,
              type: e.type,
              equipmentName: e?.equipment?.name ?? '',
              createdAt: moment(e.createdAt).format('ll'),
              createdAtEpoch: `${new Date(e.createdAt).valueOf()}`,
              createdAtDetailTimestamp: moment(e.createdAt).format('lll'),
              createdByName: `${e?.user?.firstName ? e.user.firstName : ''} ${
                e?.user?.lastName ? e.user.lastName : ''
              }`,
              updatedAt: moment(e.updatedAt).format('ll'),
              updatedAtEpoch: `${new Date(e.updatedAt).valueOf()}`,
              attachmentLength: `${e?.attachments?.items?.length || 0}`,
              attachmentNames: attachmentsComponent(
                e?.attachments?.items,
                (e) => downloadFileFunc(e)
              )
            }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }, [data1])

  const nameHeadings = [
    {
      name: 'createdAt',
      title: optionsTranslated.createdOn,
      key: 'createdAt',
      sortField: 'createdAtEpoch',
      maxWidth: '120px',
      onDataClick: (data) => {
        setMode(ACTIONS.VIEW)
        setNotesInput({
          ...initialValues,
          id: data.id,
          name: data.name,
          type: data?.type,
          userId: data?.user?.id,
          currentUserId: userInfo?.id,
          equipment: data?.equipment,
          createdAtDetailTimestamp: data?.createdAtDetailTimestamp,
          createdByName: `${data?.user?.firstName ? data.user.firstName : ''} ${
            data?.user?.lastName ? data.user.lastName : ''
          }`,
          histories: data.histories.items,
          attachments: data.attachments.items.map((item) => ({
            ...item,
            fileName: item.name,
            filePath: `${item.buildingId}/${item.name}`,
            fileSize: item.sizeInBytes,
            title: item.title,
            timestamp: item.attachedAt,
            buildingId: item.buildingId,
            description: item.description
          }))
        })
        trackEvent(USER_EVENTS.NOTES.events.VIEW_NOTE, {
          'note id': data.id,
          'number of attachments': data.attachments.items.length
        })
      }
    },
    {
      name: 'updatedAt',
      title: optionsTranslated.lastUpdated,
      key: 'updatedAt',
      maxWidth: '120px',
      sortField: 'updatedAtEpoch',
      onDataClick: (data) => {
        setMode(ACTIONS.VIEW)
        setNotesInput({
          ...initialValues,
          id: data.id,
          name: data.name,
          userId: data?.user?.id,
          currentUserId: userInfo?.id,
          createdAtDetailTimestamp: data?.createdAtDetailTimestamp,
          createdByName: `${data?.user?.firstName ? data.user.firstName : ''} ${
            data?.user?.lastName ? data.user.lastName : ''
          }`,
          histories: data.histories.items,
          attachments: data.attachments.items.map((item) => ({
            ...item,
            fileName: item.name,
            filePath: `${item.buildingId}/${item.name}`,
            fileSize: item.sizeInBytes,
            title: item.title,
            timestamp: item.attachedAt,
            buildingId: item.buildingId,
            description: item.description
          }))
        })
        trackEvent(USER_EVENTS.NOTES.events.VIEW_NOTE, {
          "note id": data.id,
          "number of attachments": data.attachments.items.length,
        })
      }
    },
    {
      name: 'name',
      title: optionsTranslated.noteName,
      key: 'name',
      maxWidth: '120px',
      onDataClick: (data) => {
        setMode(ACTIONS.VIEW)
        setNotesInput({
          ...initialValues,
          id: data.id,
          name: data.name,
          userId: data?.user?.id,
          currentUserId: userInfo?.id,
          createdAtDetailTimestamp: data?.createdAtDetailTimestamp,
          createdByName: `${data?.user?.firstName ? data.user.firstName : ''} ${
            data?.user?.lastName ? data.user.lastName : ''
          }`,
          histories: data.histories.items,
          attachments: data.attachments.items.map((item) => ({
            ...item,
            fileName: item.name,
            filePath: `${item.buildingId}/${item.name}`,
            fileSize: item.sizeInBytes,
            title: item.title,
            timestamp: item.attachedAt,
            buildingId: item.buildingId,
            description: item.description
          }))
        })
        trackEvent(USER_EVENTS.NOTES.events.VIEW_NOTE, {
          'note id': data.id,
          'number of attachments': data.attachments.items.length
        })
      }
    },
    {
      name: 'createdByName',
      title: optionsTranslated.createdBy,
      key: 'createdByName',
      maxWidth: '120px'
    },
    {
      name: 'type',
      title: optionsTranslated.noteType,
      key: 'type',
      maxWidth: '120px',
      customComponent: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>
              <TranslateComponent>{row?.type}</TranslateComponent>
            </span>
          </div>
        )
      }
    },
    {
      name: 'equipmentName',
      title: optionsTranslated.equipment,
      key: 'equipmentName',
      maxWidth: '120px'
    },
    {
      name: 'attachmentNames',
      title: optionsTranslated.attachments,
      key: 'attachmentNames',
      maxWidth: '120px',
      sortField: 'attachmentLength'
    }
  ]

  const deleteModalConfig = {
    heading: 'Delete Note',
    buttons: [
      {
        text: 'Yes',
        handleClick: async () => {
          trackEvent(USER_EVENTS.NOTES.events.DELETE_NOTE, {
            'note id': deleteNote?.['id']
          })
          await dispatch<any>(
            newRefreshToken([deleteNote?.buildingId], null, null, [
              'NoteUser',
              'NoteAdmin',
              'HistoryUser',
              'HistoryAdmin',
              'AttachmentAdmin'
            ])
          )

          notesDeletionMutation({
            input: JSON.stringify({
              modelType: 'Note',
              modelData: {
                delete: {
                  id: deleteNote?.['id']
                }
              }
            })
          })
        }
      },
      { text: 'No', handleClick: () => setDeleteNote(null), type: 'cancel' }
    ],
    handleClose: () => setDeleteNote(null)
  }

  const tableHandlerOptions = {
    edit: translate('Edit'),
    delete: translate('Delete'),
    notedDropdownLabel: translate('All Note Types')
  }

  return (
    <>
      {mode && (
        <NoteDetails
          testName={TEST_NAME_PREFIX()}
          refetchList={() => refetchData1({ id: location })}
          userInfo={userInfo}
          buildingId={location}
          setNotesInput={(i) => setNotesInput(i)}
          initialValues={initialValues}
          notesInput={notesInput}
          buildingName={buildingData?.name}
          currentUser={userInfo?.id}
          mode={mode}
          setMode={(mode) => setMode(mode)}
          downloadFileFunc={(e) => downloadFileFunc(e)}
          addFileFunc={(e) => addFileFunc(e)}
        />
      )}
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        padding="0"
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <PageHeader
            pageTitle={<TranslateComponent>Notes</TranslateComponent>}
            titleTools={
              <>
                {location ?? null ? (
                  <AccessControl id={'tc.pages.notes.add'}>
                    <Button
                      testName={TEST_NAME_PREFIX()}
                      onClick={() => {
                        setMode(ACTIONS.ADD)
                        setNotesInput({
                          ...initialValues,
                          ...{
                            currentUserId: userInfo?.id,
                            userId: userInfo?.id,
                            createdByName: `${
                              userInfo?.firstName ? userInfo.firstName : ''
                            } ${userInfo?.lastName ? userInfo.lastName : ''}`
                          }
                        })
                      }}
                    >
                      <TranslateComponent>Create Note</TranslateComponent>
                    </Button>
                  </AccessControl>
                ) : (
                  <></>
                )}{' '}
              </>
            }
          />
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {location ?? null ? (
            <Table
              testName={TEST_NAME_PREFIX()}
              key="notesTable"
              header={nameHeadings}
              rows={data}
              defaultSortColumn={1}
              columnOrderDown={false}
              loadTime={responseTime1}
              search={true}
              showSpinner={loading1}
              searchFields={['name', 'createdByName']}
              rowControl={(control) => {
                const controlOutput = []
                if (
                  userInfo?.id === control.userId &&
                  accessControlFunc({ id: 'tc.pages.notes.edit', userAccess })
                ) {
                  controlOutput.push({
                    text: tableHandlerOptions.edit,
                    action: (data) => {
                      setMode(ACTIONS.EDIT)
                      setNotesInput({
                        ...initialValues,
                        id: data.id,
                        name: data.name,
                        histories: data.histories.items,
                        userId: data?.user?.id,
                        currentUserId: userInfo?.id,
                        equipment: data?.equipment,
                        type: data?.type,
                        createdAtDetailTimestamp:
                          data?.createdAtDetailTimestamp,
                        createdByName: `${
                          data?.user?.firstName ? data.user.firstName : ''
                        } ${data?.user?.lastName ? data.user.lastName : ''}`,
                        attachments: data.attachments.items.map((item) => ({
                          ...item,
                          fileName: item.name,
                          filePath: `${item.buildingId}/${item.name}`,
                          fileSize: item.sizeInBytes,
                          title: item.title,
                          timestamp: item.attachedAt,
                          buildingId: item.buildingId,
                          description: item.description
                        }))
                      })
                    }
                  })
                }
                if (
                  userInfo?.id === control.userId &&
                  accessControlFunc({ id: 'tc.pages.notes.delete', userAccess })
                ) {
                  controlOutput.push({
                    text: tableHandlerOptions.delete,
                    action: (data) => {
                      setDeleteNote(data)
                    }
                  })
                }
                return controlOutput
              }}
              filtersList={[
                {
                  label: 'All Note Types',
                  key: 'type',
                  id: 'typeSelectorFilter',
                  defaultLabel: 'All Note Types',
                  selectedValue: 'default',
                  options: [
                    // TODO: We need enums to prevent adding random strings to types on the backend. These will reflect the enums when done
                    { name: 'Building', value: 'Building' },
                    { name: 'Equipment', value: 'Equipment' },
                    { name: 'Service Advisory', value: 'Service Advisory' },
                    { name: 'Performance', value: 'Performance' }
                  ]
                }
              ]}
            />
          ) : (
            <PanelLabel>{optionsTranslated.viewNotesWarningMsg}</PanelLabel>
          )}

          {deleteNote ? (
            <Modal testName={TEST_NAME_PREFIX('delete')} {...deleteModalConfig}>
              <ConfirmationText>
                <TranslateComponent>
                  Are you sure you want to delete
                </TranslateComponent>{' '}
                {deleteNote?.['name']}?
              </ConfirmationText>
            </Modal>
          ) : null}
        </Content>
      </Container>
    </>
  )
}

export default Notes
