import PropTypes from 'prop-types'
import { PageTitleWrapper } from './styles'

export const PageTitleInput = ({
  error,
  value,
  name,
  label,
  disabled,
  onChange
}) => (
  <PageTitleWrapper>
    <label
      className={error ? 'page-title-wrapper invalid' : 'page-title-wrapper'}
    >
      {label}*
      <textarea
        className="page-title"
        itemType="text"
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {error && <p className="error">{error}</p>}
    </label>
  </PageTitleWrapper>
)

PageTitleInput.propTypes = {
  error: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

PageTitleInput.defaultProps = {
  disabled: false
}
