export const GET_PRE_SIGNED_URL = /* GraphQL */ `
  mutation GET_PRE_SIGNED_URL($input: AWSJSON!) {
    getPreSignedUrl(body: $input)
  }
`

export const CREATE_ATTACHMENT = /* GraphQL */ `
  mutation CREATE_ATTACHMENT($input: CreateAttachmentInput!) {
    createAttachment(input: $input) {
      id
    }
  }
`

export const CREATE_ENTRY_WITH_ATTACHMENT = /* GraphQL */ `
  mutation CREATE_ENTRY_WITH_ATTACHMENT($input: AWSJSON!) {
    createEntryWithAttachment(body: $input)
  }
`

export const DELETE_ATTACHMENT = /* GraphQL */ `
  mutation DELETE_ATTACHMENT($input: DeleteAttachmentInput!) {
    deleteAttachment(input: $input) {
      id
    }
  }
`

export const UPDATE_ATTACHMENT = /* GraphQL */ `
  mutation UPDATE_ATTACHMENT($input: UpdateAttachmentInput!) {
    updateAttachment(input: $input) {
      id
    }
  }
`