import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import SelectedItems from '../custom-picker/selected-items'

import './custom-picker.scss'

export default class CustomPicker extends PureComponent {
  static propTypes = {
    selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    handleLoading: PropTypes.func,
    customClasses: PropTypes.string,
    children: PropTypes.node,
    limit: PropTypes.number,
    showSelectedItems: PropTypes.bool
  }

  static defaultProps = {
    selectedItems: [],
    customClasses: '',
    showSelectedItems: true,
    limit: 0
  }

  state = {
    error: null
  }

  handleChange = (item) => {
    const { selectedItems, onChange, limit } = this.props

    const updatedSelectedItems = [...selectedItems]

    const index = updatedSelectedItems.findIndex(({ key }) => key === item.key)
    index !== -1
      ? updatedSelectedItems.splice(index, 1)
      : updatedSelectedItems.push(item)

    const isLimitationError = Boolean(
      updatedSelectedItems.length > limit && limit
    )

    this.setState({ error: isLimitationError })

    onChange(updatedSelectedItems)
  }

  render() {
    const {
      selectedItems,
      children,
      handleLoading,
      customClasses,
      showSelectedItems
    } = this.props
    const { error } = this.state
    const pickerContent = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        ...child.props,
        onSelect: this.handleChange,
        pickerClassName: 'table-picker',
        selectedItems,
        handleLoading: (isLoading) => handleLoading(isLoading)
      })
    )

    return (
      <div
        className={`custom-picker ${error ? 'invalid' : ''} ${customClasses}`}
      >
        {pickerContent}
        {showSelectedItems && (
          <SelectedItems
            items={selectedItems}
            onRemoveItem={this.handleChange}
          />
        )}
      </div>
    )
  }
}
