
import styled from "styled-components"
import colors from 'src/components/layouts/colors.json'
import TextInput from 'src/components/legacy/components/modal-text-input'


export const ChartSettingsContainer = styled.span`
	margin-right: 17px;
	.chart-link{
		color: rgb(102, 102, 102);
		padding-left: 5px;
		padding-right: 5px;
		cursor: pointer;
	}
`
export const InputMessage = styled.div`
	font-style: italic;
	color: rgb(102, 102, 102);
	font-size: 12px;
	font-weight: bold;
	height: 12px;
`
export const SectionHeading = styled.div`
	font-size: 16px;
	font-weight: bold;
	padding-bottom: 15px;
`
export const InputHeading = styled.legend`
	font-size: 13px;
	font-weight: bold;
`
export const Unit = styled.div`
	font-size: 13px;
	font-weight: bold;
	margin-left: 10px;
	span {
		position: relative;
		font-size: 8px;
		line-height: 13px;
		bottom: 5px;
	}
`
export const InputRow = styled.div`
	display: flex;
	margin-bottom: 0px;
	.unit-select{
		min-width: 100px;
		max-height: 26px;
	}
	${TextInput} input{
		width: 73px;
	}
	input {
		margin-left: 0;
		
	}
	.rx-col-auto{
		 width:69px;
		 margin-left:25px;
		
	}
`

export const InputField = styled.input`
	margin-right: 15px;
`
export const Section = styled.div`
	//border-top: 1px solid rgb(204, 204, 204);
	margin-top: 30px;
	${SectionHeading} {
		padding-top: 0px;
	}
`
export const SectionName = styled.div`
	width: 120px;
	font-size: 13px;
	padding-top: 16px;
	p {
		font-weight: bold;
	}
`

export const InputContainer = styled.div`
	display: flex;
  align-items: flex-end;
`

export const Error = styled.span`
	font-weight : bold;
	font-style : italic;
	font-size : 12px;
	line-height : 15px;
	letter-spacing : 0.26px;
	color : ${colors.RED};
	margin-left: 120px;
`

export const StyledAnchor = styled.a`
    cursor: pointer;
    text-decoration: underline;
`
