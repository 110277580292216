import styled from 'styled-components'

export const TimeIntervalWrapper = styled.div`
  && .time-interval-selector {
    display: flex;
    flex-flow: column;

    > div {
      display: flex;
    }

    &.view > div {
      flex-direction: column;

      span {
        text-align: right;
      }
    }

    label {
      margin-bottom: 3px;
      word-break: break-word;
    }

    input {
      width: 50px;
      height: 30px;
      margin: 0 8px 0 0;
    }

    & .select-wrapper {
      min-width: 140px;
    }

    & .control {
      width: 100%;
    }
  }
`
