import * as d3 from 'd3'
import moment from 'moment'

import {
  axisTopTickHeight,
  axisBottomTickHeight,
  axisTickStrokeWidth,
  HEIGHT,
  axisLabelHeight
} from './constants'
import { convertRefToEl } from './helpers'
import localeConfig from './locale'

const locale_en = localeConfig['en']

const locale = d3.timeFormatLocale(locale_en)

export function initalizeTrackSettings(
  axisTickTopRef,
  axisTickBottomRef,
  axisLabelRef
) {
  const rangeX = d3.scaleTime()

  const axisTickTopEl = convertRefToEl(axisTickTopRef.current)
  const axisTickBottomEl = convertRefToEl(axisTickBottomRef.current)
  const axisLabelEl = convertRefToEl(axisLabelRef.current)

  function configureTrackSize(from, to, svgWidth) {
    rangeX
      .domain([moment(from).toDate(), moment(to).toDate()])
      .range([0, svgWidth])

    axisTickBottomEl.attr('transform', 'translate(0, ' + (HEIGHT + 1) + ')')
  }

  function configureTrackAxisLabel(selectedDateRange, startTime, endTime) {
    const isPeriodDay = selectedDateRange === 1
    const axisTickTopCount = isPeriodDay ? 24 : 28
    const axisTickBottomCount = isPeriodDay ? 8 : 7

    axisTickTopEl
      .call(
        d3
          .axisBottom(rangeX)
          .ticks(axisTickTopCount)
          .tickSize(axisTopTickHeight)
          .tickFormat(() => '')
      )
      .selectAll('line')
      .attr('y1', axisTickStrokeWidth)
      .attr('display', function (d) {
        if (d.getTime() === startTime || d.getTime() === endTime) {
          return 'none'
        }
      })

    axisTickBottomEl
      .call(
        d3
          .axisTop(rangeX)
          .ticks(axisTickBottomCount)
          .tickSize(axisBottomTickHeight)
          .tickFormat(() => '')
      )
      .selectAll('line')
      .attr('display', function (d) {
        if (d.getTime() === startTime || d.getTime() === endTime) {
          return 'none'
        }
      })

    const formatMinute = locale.format(locale_en.minutes)
    const formatHour = locale.format(locale_en.timeShort)
    const formatDateShort = locale.format(locale_en.dateShort)

    function multiFormat(date) {
      return (
        d3.timeHour(date) < date
          ? formatMinute
          : d3.timeDay(date) < date
          ? formatHour
          : formatDateShort
      )(date)
    }

    axisLabelEl.selectAll('*').remove()
    axisLabelEl.call(
      d3
        .axisBottom(rangeX)
        .ticks(axisTickBottomCount)
        .tickFormat(multiFormat)
        .tickSize(0)
    )

    updateRangeLabelPos(startTime, endTime)
  }

  function updateRangeLabelPos(startTime, endTime) {
    axisLabelEl
      .selectAll('text')
      .attr('dy', axisLabelHeight)
      .attr('class', 'range-text')
      .attr('display', function (d) {
        if (d.getTime() === startTime || d.getTime() === endTime) {
          return 'none'
        }
      })
  }

  return {
    setTrackSize: (from, to, svgWidth) =>
      configureTrackSize(from, to, svgWidth),
    updateAxisLabel: (selectedDateRange, from, to) => {
      configureTrackAxisLabel(selectedDateRange, from, to)
    }
  }
}
