import ContainerWrapper from 'src/components/Container'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import DataMonitoringOrbWidgetForm from './DataMonitoringOrbWidgetForm'
import ObjectPickerWrapper from 'src/components/object-picker/index'
import {BUILDING,EQUIPMENT_TYPE,EQUIPMENT} from 'src/components/object-picker/helpers'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { getFormattedResponseData } from './../../helpers'
import { useSelector } from 'react-redux'

const DataMonitoringOrbWidget = ({ setWidgetFormik }) => {
  const { width } = useBreakPoint()
  const {
    setWidgetBuilding,
    isLoading,
    widgetDetails,
    mode,
    objectPickerError,
    setObjectPickerSelectedValue,
    setWidgetSalesOfficeId
  } = useWidgetsContext()

  const organizationId = useSelector(
    (state: any) => state?.buildingPicker?.selectedOrganization
  )

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <ContainerWrapper
          sColumns={12}
          mColumns={12}
          lColumns={12}
          xlColumns={12}
          background="#eee"
        >
          <Content
            xlColumn={4}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
            background="#fff"
          >
            <ObjectPickerWrapper
            objects={["",BUILDING,EQUIPMENT_TYPE,EQUIPMENT]}
            initialValues = {getFormattedResponseData(mode, widgetDetails)}
            update={(items)=>{
              if(items?.length > 0){
                const pickerValue = items[0] ?? {}
                setObjectPickerSelectedValue(items)
                setWidgetBuilding({ id: pickerValue?.buildingId, name: pickerValue?.buildingName })
                setWidgetSalesOfficeId(pickerValue?.salesOfficeId)
              }else{
                setObjectPickerSelectedValue(null)
                setWidgetBuilding(null)                
                setWidgetSalesOfficeId(null)
              }
            }}
            buildingSelectLimit={1}
            equipmentSelectLimit={1}
            error={objectPickerError}
            mode={mode}
            orgIdFromParent={organizationId}
            />
          </Content>
          <Content
            xlColumn={8}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <DataMonitoringOrbWidgetForm setWidgetFormik={setWidgetFormik} />
          </Content>
        </ContainerWrapper>
      )}
    </>
  )
}

export default DataMonitoringOrbWidget
