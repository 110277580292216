import { sortDataByName } from 'src/utils'
import _isEmpty from 'lodash/isEmpty'
import {
  DATA_SOURCE_TYPES,
  GROUP_BY,
  CREATION_TYPE,
  CHILLER_GROUP_CATEGORIES,
  SORT_TYPE
} from 'src/pages/equipmentSetup/constants';
import _uniq from 'lodash/uniqBy'
import _orderBy from 'lodash/orderBy'

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item)
    return groups
  }, {} as Record<K, T[]>)

export const getEquipmentsTreeData = (data: any, translatedStandalone: string, translatedParentName: any): any => {
  if (!data || data.length === 0) return []

  const formattedData = data.map((d) => ({
    ...d,
    deviceName: d?.device?.name,
    name: d?.name
  }))

  const totalEquipmentsList = formattedData
    .filter(
      (el: any) =>
        el.parentId === '(null)' || el.parentId === '' || el.parentId === 'NA' || !Boolean(el.parentId)
    )
    .map((el: any) => {
      el.deviceName = el?.device?.name
      el.equipmentType = el.isParent ? 'Root' : 'StandAloneC'
      return el
    })
  const equipmentsList = totalEquipmentsList.filter(
    (eq: any) => eq.equipmentType === 'Root'
  )
  const standAlone = totalEquipmentsList.filter(
    (eq: any) => eq.equipmentType === 'StandAloneC'
  )

  const equipmentListData = formattedData
    ?.filter(
      (el) => el.parentId !== null && el.parentId !== '' && el.parentId !== 'NA'
    )
    ?.map((el) => {
      return {
        ...el,
        parentId: el?.parentId?.split('#')
      }
    })


    const eqWithParentIdButNoActiveParent = equipmentListData.filter(e => 
       e.parentId && !(data.find(parent => e.parentId.includes(parent.id) )))

  const standAloneGroup = groupBy([...eqWithParentIdButNoActiveParent,...standAlone], (item: any) => item.family)

  const standAloneP = Object.entries(standAloneGroup).map((s) => {
    const [parentName, data] = [...s]
    return {
      name: `Standalone ${parentName}`,
      family: `Standalone ${parentName}`,
      familyUI: `${translatedStandalone} ${translatedParentName?.[parentName] ?? parentName}`,
      isParent: true,
      equipmentType: 'StandAlone',
      analyticsResult: {
        items: []
      },
      subData: sortDataByName('name', 'Asc', [...data])
    }
  })

  const formSubComponents = (parents: any, rootLevel: boolean) => {
    parents.forEach((parent: any) => {
      const child = equipmentListData.filter((el: any) =>
        el.parentId?.includes(parent.id)
      )
      if (child.length !== 0) {
        if (!rootLevel) {
          parent.equipmentType = 'P'
        }
        parent.subData = sortDataByName('name', 'Asc', [...child])
        formSubComponents(parent.subData, false)
      }else if(parent.equipmentType === "StandAlone" && parent.subData) {
        formSubComponents(parent.subData, false)
      }
    })
  }
  const allEquipments = equipmentsList.concat(standAloneP || [])
  formSubComponents(allEquipments, true)
  return sortDataByName('name', 'Asc', allEquipments)
}

export const getAutomationEquipmentsTreeData = (data: any): any => {
  if (!data || data.length === 0) return []

  const formattedData = data.map((d) => ({
    ...d,
    deviceName: d?.device?.name,
    name: d?.name
  }))

  const totalEquipmentsList = formattedData
    .filter(
      (el: any) =>
        el.automationParentId === '(null)' || el.automationParentId === '' || el.automationParentId === 'NA' || !Boolean(el.automationParentId)
    )
    .map((el: any) => {
      el.deviceName = el?.device?.name
      el.equipmentType = el.isParent ? 'Root' : 'StandAloneC'
      return el
    })
  let equipmentsListRootParents = totalEquipmentsList.filter(
    (eq: any) => eq.equipmentType === 'Root'
  )
  const standAlone = totalEquipmentsList.filter(
    (eq: any) => eq.equipmentType === 'StandAloneC'
  )

  const equipmentsListWithParents = formattedData
    ?.filter(
      (el) => el.automationParentId !== null && el.automationParentId !== '' && el.automationParentId !== 'NA'
    )
    ?.map((el) => {
      return {
        ...el,
        automationParentId: el?.automationParentId?.split('#')
      }
    })


    const eqWithParentIdButNoActiveParent = equipmentsListWithParents.filter(e => 
       e.automationParentId && !(data.find(parent => e.automationParentId?.includes(parent.id ) )))

  const AllStandAlone = [...eqWithParentIdButNoActiveParent,...standAlone]
const eqParentsWithoutChildren = [];
  const formSubComponents = (parents: any, rootLevel: boolean) => {
    parents.forEach((parent: any) => {
      const child = equipmentsListWithParents.filter((el: any) =>
        el.automationParentId?.includes(parent.id)
      )
      if (child.length !== 0) {
        if (!rootLevel) {
          parent.equipmentType = 'P'
        }
        parent.subData = sortDataByName('name', 'Asc', [...child])
        parent.subData.forEach(child => {
          child.defaultCount = 
           CHILLER_GROUP_CATEGORIES.includes(child.dataSourceType) ? 
          child?.length || 0
          : getDefaultCount(
            data,
            child.dataSourceType,
            child.dataSourceName
          )
        })
        parent.dataSourceName = parent.dataSourceName || parent.name
        formSubComponents(parent.subData, false)
      }else{
        equipmentsListRootParents = equipmentsListRootParents.filter(e => e.id !== parent.id)
        parent.isParent && eqParentsWithoutChildren.push(parent)
      }
    })
  }
  formSubComponents(equipmentsListRootParents, true)
  return {grouped : sortDataByName('name', 'Asc', equipmentsListRootParents),AllStandAlone:[...eqParentsWithoutChildren,...AllStandAlone]}
}

export const getEquipmentsList = (tisListData, deviceList) => {
  // Formed automationSystem property
  return tisListData?.map((element) => {
    element.automationSystem =
      deviceList?.find((d) => d?.id === element?.deviceId)?.name ?? ''
    return element
  })
}

const getAutomationGroupListByType = (data, type) => {
  const filteredData =
    data?.filter(
      (e) =>
        (e?.parentId === '' || e?.parentId === 'NA') &&
        e?.dataSourceType === type
    ) ?? []
  return groupByBasedOnProperties(filteredData, (item) => {
    return [item[GROUP_BY?.DataSourceName]]
  })
}

const getDefaultCount = (data, type, name) => {
  const count = (
    data?.filter(
      (p) => p?.dataSourceName === name && p?.dataSourceType === type
    ) ?? []
  )?.length
  return count
}
const getAutomationFormedElement = (elementData, parentIds, data) => {
  const element = {
    id: elementData?.id,
    dataSourceName: elementData?.dataSourceName ?? '',
    dataSourceType: elementData?.dataSourceType ?? '',
    dataSourceTypeUI: elementData?.dataSourceTypeUI ?? '',
    automationSystem: elementData?.automationSystem ?? '',
    controllerType: elementData?.controllerType ?? '',
    defaultCount: getDefaultCount(
      data,
      elementData?.dataSourceType,
      elementData?.dataSourceName
    )
  }
  if (parentIds?.length > 0) {
    const subChildren = getAutomationSubChildren(parentIds, data)
    if (subChildren?.length > 0) element['subData'] = subChildren
  }
  return element
}

const isAutoTypeNotExist = (data) => {
  return (
    (data?.filter((c) => c?.creationType === CREATION_TYPE?.Auto) ?? [])
      ?.length === 0
  )
}
const getDataApartFromVASAndVRF = (parentIds, data) => {
  let exceptIds = []
  const subChildren = data?.filter((c) => parentIds?.includes(c?.id)) ?? []
  for (const element of subChildren) {
    exceptIds.push({
      id: element?.id,
      dataSourceType: element?.dataSourceType,
      dataSourceTypeUI: element?.dataSourceTypeUI,
      dataSourceName: element?.dataSourceName
    })
    const subChildrenIds =
      data?.filter((c) => c?.parentId === element?.id)?.map((m) => m?.id) ?? []
    exceptIds = [
      ...exceptIds,
      ...getDataApartFromVASAndVRF(subChildrenIds, data)
    ]
  }
  return exceptIds
}

export const getAutomationSystemListData = (allData: any): any => {
  // Group by VAS
  if(!allData.length){
    return [];
  }
  const autoCreatedData = allData.filter(e =>  e.creationType === CREATION_TYPE?.Auto)
  const {grouped,AllStandAlone} = getAutomationEquipmentsTreeData(autoCreatedData || [])
  const data = [...(AllStandAlone || [])]
  const vasAutomationData = getAutomationGroupListByType(
    data,
    DATA_SOURCE_TYPES?.VAS
  )
  // Group by VRF
  const vrfAutomationData = getAutomationGroupListByType(
    data,
    DATA_SOURCE_TYPES?.VRF
  )
  // Get data except VAS & VRF
  const exceptParentIds = data
    ?.filter(
      (e) =>
        e?.dataSourceType === DATA_SOURCE_TYPES?.VAS ||
        e?.dataSourceType === DATA_SOURCE_TYPES?.VRF
    )
    ?.map((m) => m?.id)
  // Get data except VAS and VRF data source types
  const exceptData = getDataApartFromVASAndVRF(exceptParentIds, data)
  // Get all id's which is present under VAS & VRF categories
  const exceptIds = exceptData?.map((i) => i?.id)
  // Get all data source types which is present under VAS & VRF categories
  const exceptDataSourceTypes = exceptData?.map((i) => i?.dataSourceType)
  // Get all data source names which is present under VAS & VRF categories
  const exceptDataSourceNames = exceptData?.map((i) => i?.dataSourceName)
  // Filter data based on non parent elements
  const dataApartFromVASAndVRF = data?.filter(
    (e) => !exceptIds?.includes(e?.id)
  )
  // Filter by "Chilled Water System" Or "Chiller Plant"
  const chillerGroup =
    dataApartFromVASAndVRF?.filter((e) =>
      CHILLER_GROUP_CATEGORIES?.includes(e?.dataSourceType)
    ) ?? []
  // Group by chiller
  const chillerAutomationData = groupByBasedOnProperties(
    chillerGroup,
    (item) => {
      return [item[GROUP_BY?.DataSourceType], item[GROUP_BY?.DataSourceName]]
    }
  )
  // Filter by (not related to "Chilled Water System" Or "Chiller Plant") and ("DataSourceType" should not empty)
  const standAloneGroup =
    dataApartFromVASAndVRF?.filter(
      (e) =>
        !_isEmpty(e?.dataSourceType) &&
        !CHILLER_GROUP_CATEGORIES?.includes(e?.dataSourceType)
    ) ?? []
    
  // Filter by (not related to "Chilled Water System" Or "Chiller Plant") and ("ControllerType" || "DataSourceType" should empty)
    const standAloneGroupWithoutDataSourceType =
    dataApartFromVASAndVRF?.filter(
      (e) =>
        (_isEmpty(e?.controllerType) || !_isEmpty(e?.controllerType)) &&
        _isEmpty(e?.dataSourceType) &&
        !CHILLER_GROUP_CATEGORIES?.includes(e?.dataSourceType)
    ) ?? []
  // Group by stand alone
  const standAloneAutomationData = groupByBasedOnProperties(
    standAloneGroup,
    (item) => {
      return [item[GROUP_BY?.DataSourceType]]
    }
  )

  // Make VAS group data list
  const vasAutomationGroupedData = []
  vasAutomationData?.forEach((vasElement) => {
    if (vasElement?.length === 0 || isAutoTypeNotExist(vasElement)) return
    const parentIds = vasElement?.filter((c) => c?.isParent)?.map((m) => m?.id)
    const element = getAutomationFormedElement(vasElement[0], parentIds, data)
    vasAutomationGroupedData.push(element)
  })
  // Make chiller data list
  const chillerAutomationGroupedData = []
  chillerAutomationData?.forEach((chillerElement) => {
    if (chillerElement?.length === 0) return
    const chillerChildren = getAutomationChillerChildren(
      chillerElement[0],
      chillerElement?.length
    )
    if (chillerChildren?.length === 0) return
    const element = {
      dataSourceName: `Standalone Chiller`,
      dataSourceType: '',
      automationSystem: '',
      controllerType: '',
      defaultCount: '',
      subData: chillerChildren
    }
    chillerAutomationGroupedData.push(element)
  })
  // Make Stand Alone data list
  const standAloneAutomationGroupedData = []
  const standAloneChildrenWithoutDataSourceType = []
  standAloneGroupWithoutDataSourceType?.forEach((element) => {
    const autoElement = {
      id: element?.id || '',
      dataSourceName: element?.dataSourceName ?? '',
      dataSourceType: element?.dataSourceType ?? '',
      dataSourceTypeUI: element?.dataSourceTypeUI ?? '',
      automationSystem: element?.automationSystem ?? '',
      controllerType: element?.controllerType ?? '',
      defaultCount: getDefaultCount(
        data,
        element?.dataSourceType,
        element?.dataSourceName
      )
    }
    standAloneChildrenWithoutDataSourceType.push(autoElement)
  });
  const standAloneAutomationGroupedDataWithoutDataSourceType = standAloneChildrenWithoutDataSourceType.length > 0 ? [
    {
      dataSourceName: `Standalone`,
      dataSourceType: '',
      automationSystem: '',
      controllerType: '',
      defaultCount: '',
      subData: standAloneChildrenWithoutDataSourceType,
    }
  ] : [];

  
  standAloneAutomationData?.forEach((standAloneElement) => {
    if (standAloneElement?.length === 0 || isAutoTypeNotExist(standAloneElement)) return
    const standAloneChildren = getAutomationStandAloneChildren(
      standAloneElement,
      autoCreatedData,
      exceptDataSourceTypes,
      exceptDataSourceNames
    )
    if (standAloneChildren?.length === 0) return
    const element = {
      dataSourceName: `Standalone ${
        standAloneElement[0]?.dataSourceType ?? ''
      }`,
      dataSourceType: '',
      automationSystem: '',
      controllerType: '',
      defaultCount: '',
      subData: standAloneChildren
    }
    standAloneAutomationGroupedData.push(element)
  })

  // Make VRF group data list
  const vrfAutomationGroupedData = []
  vrfAutomationData?.forEach((vrfElement) => {
    if (vrfElement?.length === 0 || isAutoTypeNotExist(vrfElement)) return
    const parentIds = vrfElement?.filter((c) => c?.isParent)?.map((m) => m?.id)
    const element = getAutomationFormedElement(vrfElement[0], parentIds, data)
    vrfAutomationGroupedData.push(element)
  })
  return sortDataByName(GROUP_BY?.DataSourceName, 'Asc', [
    ...(grouped || []),
    ...vasAutomationGroupedData,
    ...chillerAutomationGroupedData,
    // ...standAloneAutomationGroupedDataWithoutDataSourceType,
    ...standAloneAutomationGroupedData,
    ...vrfAutomationGroupedData
  ])
}
export const getAutomationSubChildren = (parentIds: any, data: any): any => {
  let combinedChildernResults = []
  // Get children based on parent ids
  const subChildren =
    data?.filter((s) => parentIds?.includes(s?.parentId)) ?? []
  // Get parent type children and grouped by datasourcetype & datasourcename
  const parentChildrenResult = []
  const parentChildren = groupByBasedOnProperties(
    subChildren?.filter((p) => p?.isParent) ?? [],
    (item) => {
      return [item[GROUP_BY?.DataSourceType], item[GROUP_BY?.DataSourceName]]
    }
  )
  // Make parent type data list
  parentChildren?.forEach((parent) => {
    if (parent?.length === 0 || isAutoTypeNotExist(parent)) return
    const childrenParentIds = parent
      ?.filter((c) => c?.isParent)
      ?.map((m) => m?.id)
    const element = getAutomationFormedElement(
      parent[0],
      childrenParentIds,
      data
    )
    parentChildrenResult.push(element)
  })
  // Get non parent type children and grouped by datasourcetype & datasourcename
  const nonParentChildrenResult = []
  const nonParentChildren = groupByBasedOnProperties(
    subChildren?.filter((p) => !p?.isParent) ?? [],
    (item) => {
      return [item[GROUP_BY?.DataSourceType], item[GROUP_BY?.DataSourceName]]
    }
  )
  // Make non parent type data list
  nonParentChildren?.forEach((nonParent) => {
    if (nonParent?.length === 0 || isAutoTypeNotExist(nonParent)) return
    const element = getAutomationFormedElement(nonParent[0], [], data)
    nonParentChildrenResult.push(element)
  })
  // Oreder by datasourcename
  combinedChildernResults = sortDataByName(GROUP_BY?.DataSourceName, 'Asc', [
    ...parentChildrenResult,
    ...nonParentChildrenResult
  ])
  return combinedChildernResults
}
const getAutomationChillerChildren = (chillerData: any, defaultCount: any) => {
  const autoChillerChildrenList = []
  const autoElement = {
    dataSourceName: chillerData?.dataSourceName ?? '',
    dataSourceType: chillerData?.dataSourceType,
    dataSourceTypeUI: chillerData?.dataSourceTypeUI,
    automationSystem: chillerData?.automationSystem ?? '',
    controllerType: chillerData?.controllerType ?? '',
    defaultCount: defaultCount
  }
  autoChillerChildrenList?.push(autoElement)
  return autoChillerChildrenList
}
const getAutomationStandAloneChildren = (
  standAloneData: any,
  data: any,
  exceptTypes: any,
  exceptNames: any
) => {
  const independentStandAloneList = []
  if (standAloneData?.length === 0) return
  const independentAutoStandAloneList = groupByBasedOnProperties(
    standAloneData ?? [],
    (item) => {
      return [item.dataSourceType, item.dataSourceName]
    }
  )
  independentAutoStandAloneList?.forEach((element) => {
    const isExistingInVASorVRF =
      exceptNames?.includes(element[0]?.dataSourceName) &&
      exceptTypes?.includes(element[0]?.dataSourceType)
    if (element?.length === 0 || isExistingInVASorVRF) return
    const autoElement = {
      id: element[0]?.id,
      dataSourceName: element[0]?.dataSourceName ?? '',
      dataSourceType: element[0]?.dataSourceType ?? '',
      dataSourceTypeUI: element[0]?.dataSourceTypeUI ?? '',
      automationSystem: element[0]?.automationSystem ?? '',
      controllerType: element[0]?.controllerType ?? '',
      defaultCount: getDefaultCount(
        data,
        element[0]?.dataSourceType,
        element[0]?.dataSourceName
      )
    }
    independentStandAloneList?.push(autoElement)
  })
  return sortDataByName(
    GROUP_BY?.DataSourceName,
    'Asc',
    independentStandAloneList
  )
}

function arrayFromObject(obj) {
  const arr = []
  for (const i in obj) {
    arr.push(obj[i])
  }
  return arr
}

// Group by one property or multiple property
export const groupByBasedOnProperties = (list, fn) => {
  const groups = {}
  for (let i = 0; i < list?.length; i++) {
    const group = JSON.stringify(fn(list[i]))
    if (group in groups) {
      groups[group].push(list[i])
    } else {
      groups[group] = [list[i]]
    }
  }
  return arrayFromObject(groups)
}

export const getDataFromNestedArray =(data,result=[])=>{
  for (const item of data) {
    const { subData, ...rest } = item
    result.push(rest)
    if (subData) {
      getDataFromNestedArray(subData,result)
    }
  }
  return result
}

export const sanitaizeDropDownData=(data=[])=>{
  return data.map((item) => ({ name: item, value: item }))
}

export const orderWithUniq=(data)=>{
 return _orderBy(_uniq(data),[(item) => item?.toLowerCase()],SORT_TYPE.ASC)
}