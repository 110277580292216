class ApiError extends Error {
  constructor({
    statusText = '',
    statusCode = '',
    reason = '',
    method = '',
    url = ''
  } = {}) {
    super()
    this.name = 'ApiError'
    this.type = getErrorType(statusCode)
    this.statusText = statusText
    this.statusCode = statusCode
    this.reason = reason
    this.method = method
    this.url = url
  }
}

function getErrorType(code) {
  switch (code) {
    case '':
    case 400:
      return 'unexpected-error'

    case 401:
      return 'unauthorized'

    case 403:
    case 405:
      return 'forbidden'

    case 422:
      return 'unprocessable'

    case 500:
      return 'internal-server-error'
    case 504:
      return 'gateway-timeout-error'
    // not specified errors
    case code > 400 && code < 500:
      return 'unexpected-error'
    case code > 500:
      return 'server-error'
  }
}

export default ApiError
