export default function TooltipRenderer({ data }: { data: any[] }) {
  const tooltipItems = Object.values(data).sort(
    (a, b) => b?.stackOrder - a?.stackOrder
  )

  const { category, items, total } = tooltipItems.reduce(
    (av, cv) => {
      if (!av.category && cv.category) {
        av.category = cv.category
      }

      av.total += +cv.active.value

      av.items.push({ ...cv.active, color: cv.color })

      return av
    },
    { category: '', items: [], total: 0 }
  )

  return (
    <div className="ecs-tooltip">
      <p className="title">{category}</p>
      <p className="total">
        Total Cost: <span>${total?.toFixed(2)}</span>
      </p>
      {items.map((item: any) => (
        <div className="item" key={item.name + '-' + item.value?.toFixed(2)}>
          <span className="icon" style={{ background: item.color }}></span>
          {item.name}: <span className="value">$ {item?.value?.toFixed(2)}</span>
        </div>
      ))}
    </div>
  )
}
