const targetTypeNames = {
	"ACIR": { icon: "solar-arrayobject", name: "Air Compressor" },
	"AHU": { icon: "ahu", name: "Air Handling Unit" },
	"BinaryPoints": { icon: "binarypoint", name: "Binary Point" },
	"Boiler": { icon: "boiler", name: "Boiler" },
	"CDWP": { icon: "condwaterplant", name: "Condenser Water Plant" },
	"Chiller": { icon: "chiller", name: "Chiller" },
	"Circuit": { icon: "circuit", name: "Refrigerant Circuit" },
	"Compressor": { icon: "vavbox", name: "Refrigerant Compressor" },
	"CP": { icon: "chillerplant", name: "Chiller Plant" },
	"CT": { icon: "coolingtower", name: "Cooling Tower" },
	"CTC": { icon: "coolingtowercell", name: "Cooling Tower Cell" },
	"CWS": { icon: "chilledwatersys", name: "Chilled Water System" },
	"DL": { icon: "distributionloop", name: "Distribution Loop" },
	"ElectricMeter": { icon: "electric", name: "Electric Meter" },
	"EnergyMeter": { icon: "general-meter1object", name: "Energy Meter" },
	"EnvironmentMeter": { icon: "environment-meter", name: "Environment Meter" },
	"FAN": { icon: "fan", name: "Fan" },
	"GAS": { icon: "gas", name: "Gas Meter" },
	"HP": { icon: "heating-plant", name: "Heating Plant" },
	"HS": { icon: "heating-system", name: "Heating System" },
	"HX": { icon: "heat-exchanger", name: "Heat Exchanger" },
	"LoadValve": { icon: "loadvalves", name: "Load Valves" },
	"MultiStatePoints": { icon: "multistatepoint", name: "MultiState Point" },
	"Points": { icon: "analogpoint", name: "Analog Point" },
	"Pump": { icon: "pump", name: "Pump" },
	"PumpBank": { icon: "pumpbank", name: "Pump Bank" },
	"SCU": { icon: "spacecomfortunit", name: "Space Comfort Unit" },
	"VAS": { icon: "vas", name: "VAV Air System" },
	"VAV-BOX": { icon: "vavbox", name: "VAV Box" },
	"VFD": { icon: "varfreqdrive", name: "Variable Frequency Drive" },
	"VirtualMeter": { icon: "virtual-meter", name: "Virtual Meter" },
	"WaterFlow": { icon: "water", name: "Water Flow" },
	"WeatherStation": { icon: "weatherwidget", name: "Weather Station" },
	"organization": { icon: "organization", name: "Organization" },
	"location": { icon: "location", name: "Location" },
	"none": { icon: "" }
}

export default {
	getType(type) {
		if (targetTypeNames.hasOwnProperty(type)) {
			return targetTypeNames[type]
		} else {
			return { icon: "other", name: type }
		}
	}
}
