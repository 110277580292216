import styled, { css } from 'styled-components'

//variables
const size = {
  'column-width': '260px',
  'gutter-width': '20px'
}

/* Modal Containter */
export const DialogContainer = styled.dialog`
  ${(props) =>
    props.modalWidth &&
    css`
      width: calc((${props.modalWidth} + ${size['gutter-width']}) * ${2});
    `}
  margin: auto;
  padding: 0;
  border: 0;
  opacity: 1;
  background: transparent;
  overflow: visible;

  & + .backdrop,
  &::backdrop {
    background: #000000;
    opacity: 0.4;
    transition: opacity 0.4s ease;
  }
`
