export const ThemeMode = {
  DARK: 'dark',
  LIGHT: 'light'
}

export const SystemThemeProps = {
  BOX: 'box',
  BREAKPOINTS: 'breakpoints',
  BUTTON: 'button',
  FORM_CONTROL: 'formControl',
  ICON_BUTTON: 'iconButton',
  OPTION: 'option',
  TYPOGRPAHY: 'typography'
}
