export const formatCurrencyToStandardPrecision = (num) => {
  const signCapture =
    Math.sign(num) === 1 || Math.sign(num) === 0 ? 'postive' : 'negative'
  const sign = signCapture !== 'postive' ? 1 : Math.sign(num)
  const absoluteVal = Math.abs(Number(num))
  const roundVal = Math.round(num)
  const floorVal = Math.floor(Number(num))
  let total
  switch (true) {
    // num is 0, then the number format should be "0"
    case (sign * absoluteVal).toFixed(2) === '0.00' || absoluteVal === '0':
      total = Math.abs(roundVal)
      break
    // num is 1,000,000 or more, then the number format should be "###.## M"
    // num is negative 1,000,000 or less, then the number format should be "-###.## M"
    case sign * absoluteVal >= sign * 1.0e6:
      total = `${(sign * (absoluteVal / 1.0e6)).toFixed(2)} M`
      break
    // num is between 999,999 and 10,000, then the number format should be "###.## k"
    // num is between negative 10,000 and negative 999,999, then the number format should be "-###.## k"
    case sign * absoluteVal >= sign * 1.0e4 && sign * floorVal <= sign * 999999:
      total = `${((sign * absoluteVal) / 1.0e3).toFixed(2)} k`
      break
    // num is between 1,000 and 9,999, then the number format should be "#,###"
    // num is between negative 1,000 and negative 9,999, then the number format should be "#,###"
    case sign * absoluteVal >= sign * 1.0e3 && sign * floorVal <= sign * 9999:
      total = (sign * absoluteVal.toFixed()).toLocaleString('en-US')
      break
    // num is between 1 and 999, then the number format should be "##.##"
    // num is between negative 1 and 999, then the number format should be "##.##"
    case sign * absoluteVal >= Number(sign) * 1 &&
      sign * floorVal <= sign * 999:
      total = (sign * absoluteVal).toFixed(2)
      break
    // num is less 1 and more than 0, then the number format should be "0.##"
    // num is less 0 and more than negative 1, then the number format should be "-0.##"
    case (sign * absoluteVal).toFixed(2) > 0 &&
      sign * floorVal < Number(sign) * 1:
      total = (sign * absoluteVal).toFixed(2)
      break
    // Default
    default:
      total = absoluteVal
      break
  }
  return signCapture !== 'postive' && total !== 0 ? `-${total}` : total
}

export const formatNumberToStandardPrecision = (num) => {
  const signCapture =
    Math.sign(num) === 1 || Math.sign(num) === 0 ? 'postive' : 'negative'
  const sign = signCapture !== 'postive' ? 1 : Math.sign(num)
  const absoluteVal = Math.abs(Number(num))
  const roundVal = Math.round(num)
  const floorVal = Math.floor(Number(num))
  let total
  switch (true) {
    // num is 0, then the number format should be "0"
    case (sign * absoluteVal).toFixed(2) === '0.00' || absoluteVal === '0':
      total = Math.abs(roundVal)
      break
    // num is 1,000,000 or more, then the number format should be "###.## M"
    // num is negative 1,000,000 or less, then the number format should be "-###.## M"
    case sign * absoluteVal >= sign * 1.0e6:
      total = `${(sign * (absoluteVal / 1.0e6)).toFixed(2)} M`
      break
    // num is between 999,999 and 10,000, then the number format should be "###.## k"
    // num is between negative 10,000 and negative 999,999, then the number format should be "-###.## k"
    case sign * absoluteVal >= sign * 1.0e4 && sign * floorVal <= sign * 999999:
      total = `${((sign * absoluteVal) / 1.0e3).toFixed(2)} k`
      break
    // num is between 1,000 and 9,999, then the number format should be "#,###"
    // num is between negative 1,000 and negative 9,999, then the number format should be "#,###"
    case sign * absoluteVal >= sign * 1.0e3 && sign * floorVal <= sign * 9999:
      total = (sign * absoluteVal.toFixed()).toLocaleString('en-US')
      break
    // num is between 1 and 999, then the number format should be "##.##"
    // num is between negative 1 and 999, then the number format should be "##.##"
    case sign * absoluteVal >= Number(sign) * 1 && sign * floorVal <= sign * 99:
      total = (sign * absoluteVal).toFixed(3)
      break
    case sign * absoluteVal >= Number(sign) * 100 &&
      sign * floorVal <= sign * 999:
      total = (sign * absoluteVal).toFixed(2)
      break
    // num is less 1 and more than 0, then the number format should be "0.##"
    // num is less 0 and more than negative 1, then the number format should be "-0.##"
    case (sign * absoluteVal).toFixed(2) > 0 &&
      sign * floorVal < Number(sign) * 1:
      total = (sign * absoluteVal).toFixed(4)
      break
    // Default
    default:
      total = absoluteVal
      break
  }
  return signCapture !== 'postive' && total !== 0 ? `-${total}` : total
}
