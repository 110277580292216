import BuildingDataContainer from './BuildingDataContainer'
import { BuildingDataContextProvider } from './BuildingDataContextProvider'

export const BuildingData = () => {
  return (
    <BuildingDataContextProvider>
      <BuildingDataContainer />
    </BuildingDataContextProvider>
  )
}
