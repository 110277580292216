import React, { memo, Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TabLink, SpinnerContainer as Spinner, CustomIcon } from './styles'
import { isPresentObject } from '../../../pages/widgets/helpers'
import translate, { TranslateComponent } from 'src/common/translations'

// eslint-disable-next-line react/display-name
const Tab = memo((props) => {
  const [isTabError, setTabError] = useState(false)
  const {
    active,
    invalid,
    disabled,
    loading,
    vertical,
    contextualTabs,
    tabIndex,
    title,
    icon,
    onClick,
    customStyle,
    formikErrors = {},
    validationSchema = null,
    testName
  } = props
  const handleClick = (event) => {
    event.preventDefault();
    !disabled && !loading && onClick(tabIndex, title)
  }
  useEffect(() => {
    if (!formikErrors || !validationSchema) return
    const validationFields = Object.keys(validationSchema?.fields)
    setTabError(isPresentObject(validationFields, formikErrors))
  }, [formikErrors])

  const tabLinkProps = {
    active: active,
    invalid: invalid,
    disabled: disabled,
    loading: loading,
    vertical: vertical,
    contextualTabs: contextualTabs,
    customStyle: customStyle
  }

  return (
    <TabLink
      {...tabLinkProps}
      onClick={handleClick}
      data-testid={`${testName || 'tc'}_tab`}
      className={
        tabLinkProps?.active
          ? `${isTabError ? 'active error' : 'active'}`
          : `${isTabError ? 'error' : ''}`
      }
    >
      {/* {loading ? (
        <Spinner />
      ) : (
        <></>
        // Frgament part code should be here once loader will enabled
      )} */}
      <Fragment>
        {invalid ? (
          <CustomIcon
            name="Warning"
            color="#991909"
            width="15px"
            height="15px"
          />
        ) : (
          icon && (
            <CustomIcon
              name={icon}
              color={active || disabled ? '#ffffff' : '#1e1e1e'}
              width="20px"
              height="20px"
            />
          )
        )}
        {translate(title)}
      </Fragment>
    </TabLink>
  )
})

Tab.propTypes = {
  /** Tab title */
  title: PropTypes.string.isRequired,

  /** current Tab index */
  tabIndex: PropTypes.number,

  /**
   * on Click handler
   * @param {number} current Tab index
   */
  onClick: PropTypes.func,

  /** is Tab active */
  active: PropTypes.bool,

  /** is Tab invalid, show warning icon and Tab title becomes red */
  invalid: PropTypes.bool,

  /** is Tab disabled, unable to select Tab */
  disabled: PropTypes.bool,

  /** is Tab invalid, show spinner instead of Tab title */
  loading: PropTypes.bool,

  /** is Tab aligned vertically */
  vertical: PropTypes.bool
}

export default Tab
