export const CONNECTION_TYPES_OBJ = {
  ES: { title: 'Ensemble Serial Number' },
  NAX: { title: 'Host Id' }
}

export const FROZEN_CONNECTION_TYPES = ['CM', 'SC', 'NAX']

export const ALONE_CONNECTION_TYPES = ['CM', 'SCG', 'NAX']

export const CONNECTION_TYPE_COEXISTENCE = {
  CM: [],
  SCG: [],
  SC: ['SC', 'SEC', 'LEC', 'ES'],
  NAX: [],
  SEC: ['SC', 'SEC', 'LEC', 'ES'],
  LEC: ['SC', 'SEC', 'LEC', 'ES'],
  ES: ['SC', 'SEC', 'LEC']
}

export function getConnectionTypeObj(key, connectionTypesList) {
  const connectionTypesObj = connectionTypesList?.find(
    (type) => type?.key === key
  )
  return key && CONNECTION_TYPES_OBJ[key]
    ? {
        key,
        title: CONNECTION_TYPES_OBJ[key]?.title,
        value: connectionTypesObj?.value || key
      }
    : {
        key,
        title: 'Hardware Serial Number',
        value: connectionTypesObj?.value || key
      }
}

export const DEVICES_WITH_UPPERCASE_SERIAL = ['SC', 'SEC', 'LEC', 'SCG']
