import styled from 'styled-components'
import { TEXT_HOVER_2 } from 'src/components/color'
export const StyledFooter = styled.footer`
  position: relative;
  margin: auto 20px 0;
  padding: 20px;
  bottom: 0;
  color: ${TEXT_HOVER_2};
  border-top: 2px solid ${TEXT_HOVER_2};
  & .inner-footer > small,
  .language-picker {
    display: inline-block;
  }
  & .inner-footer .language-picker {
    margin-right: 1rem;
  }
  & .inner-footer a {
    color: ${TEXT_HOVER_2};
  }

  .language-picker-hp {
    .select-wrapper {
      min-width: 85px;
      .control {
        border-radius: 4px;
        .control-label {
          font-weight: normal;
        }
        .control-icon {
          flex: 0 0 25px;
          justify-content: flex-start;
          .icon-expand {
            top: 1px;
            color: $tba-darker-grey;
          }
        }
      }
    }
  }

  .language-picker-hp-tablet-view {
    order: 2;
    margin-bottom: 10px;
    margin-right: 50px !important;
  }

  .inner-footer-hp-tablet-view {
    width: 250px;
    order: 1;
    margin-bottom: 10px;
  }

  .footer-logo-hp-tablet-view {
    order: 3 !important;
    position: initial !important;
  }
`
export const StyledFooterLogo = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
`
