import { API } from 'aws-amplify'
import { isJsonString } from 'src/components/legacy/common/helpers'
import {getPropsByEquipmentType} from './queries'
import _uniqBy from 'lodash/uniqBy'

export const TYPES = {
  CHILLER : "Chiller"
}

export const CHARACTERISTIC = 'Characteristic'

export const getFilteredPropertyMetaData = async (
  characteristicsListData,
  equipmentType
) => {
  const filter = getFilter(equipmentType,characteristicsListData)
  const data = await getApplicableProperties(filter)
  return data
}

export const getFilter = (type,characteristicsListData) => {
  return type === TYPES.CHILLER && transformChillerProperties(characteristicsListData)
}

export const getApplicableProperties = async filter=> {
  return filter && await getChillerApplicableProperties(filter)
}



export const transformChillerProperties = (propertyArray) => {
  const componentPropertyArray = propertyArray?.filter(
    ({ targetKey, value }) =>
      targetKey?.toLowerCase()?.includes('compressorcountckt') &&
      typeof value === 'string' &&
      value > 0
  )
  const filteredArray = Array?.from(
    new Set(componentPropertyArray?.map((item) => item?.targetKey))
  ).map((targetKey) => ({
    targetKey,
    value: Math?.max(
      ...componentPropertyArray
        ?.filter((item) => item?.targetKey === targetKey)
        ?.map((item) => parseInt(item?.value))
    )
  }))
  const circuitInstances =
    filteredArray?.length > 1
      ? filteredArray?.map(
          ({ targetKey }) => `Ckt${targetKey?.slice(targetKey?.length - 1)}`
        )
      : ['Ckt']
  const compressorInstances = []

  if (
    (filteredArray?.length === 1 && filteredArray[0]?.value <= 1) ||
    filteredArray?.length === 0
  ) {
    compressorInstances?.push('Comp')
  } else {
    filteredArray.forEach((item) => {
      const { targetKey, value } = item
      for (let i = 1; i <= value; i++) {
        const suffix = String?.fromCharCode(i + 96)?.toUpperCase()
        compressorInstances?.push(
          `${targetKey?.slice(targetKey?.length - 1)}${suffix}`
        )
      }
    })
  }
  const condenserType = propertyArray
    ?.filter(({ targetKey }) => targetKey === 'CondenserType')?.[0]
    ?.value?.trim()
    ?.replace(/\s/g, '')
  const compressorType = propertyArray
    ?.filter(({ targetKey }) => targetKey === 'CompressorType')?.[0]
    ?.value?.trim()
    ?.replace(/\s/g, '')

  return {
    instance: ['Chiller', ...circuitInstances, ...compressorInstances],
    compCondType:
      condenserType && compressorType
        ? `${condenserType}#${compressorType}`
        : ''
  }
}

export const getChillerApplicableProperties = async (selectedEquipment) => {
  const selectedEquipmentObjects = [selectedEquipment]
  const chillerFilter = []
  let compCondTypeFilter = []
  let instanceFilter = []
  selectedEquipmentObjects?.forEach(({ compCondType, instance }) => {
    const selectedChillerFilter = []

    if (compCondType && isJsonString(compCondType)) {
      compCondTypeFilter = JSON.parse(compCondType?.replace(/'/g, '"'))?.map(
        (item) => ({ compCondType: { matchPhrasePrefix: item } })
      )
    } else if (compCondType && compCondType?.includes('[')) {
      compCondTypeFilter = [
        {
          compCondType: {
            matchPhrasePrefix: compCondType.replace(/[\[\]]/g, '')
          }
        }
      ]
    } else if (compCondType && compCondType !== ' ') {
      compCondTypeFilter = [
        { compCondType: { matchPhrasePrefix: compCondType } }
      ]
    }
    if (instance && isJsonString(instance)) {
      instanceFilter = JSON.parse(instance?.replace(/'/g, '"'))?.map(
        (item) => ({ instance: { eq: item } })
      )
    } else if (instance && Array.isArray(instance)) {
      instanceFilter = instance?.map((item) => ({
        instance: { eq: item }
      }))
    }

    if (compCondTypeFilter?.length > 0) {
      selectedChillerFilter?.push({ or: compCondTypeFilter })
    }
    if (instanceFilter?.length > 0) {
      selectedChillerFilter?.push({ or: instanceFilter })
    }
    if (selectedChillerFilter?.length > 0) {
      chillerFilter.push({ and: selectedChillerFilter })
    }
  })
  try {
    const properties = await fetchPropertiesByEquipmentId(
      chillerFilter,
      selectedEquipment?.type
    )
    return properties
  } catch (e) {
    console.log(e)
    return []
  }
}

export const fetchPropertiesByEquipmentId = async (
  filterVariable = null,
  type = 'Chiller'
) => {
  try {
    const { data } = await API.graphql({
      query: getPropsByEquipmentType,
      variables: {
        filter:
          filterVariable && filterVariable?.length > 0
            ? {
                equipmentType: { eq: type },
                isRDR: { eq: 1 },
                sourceEquipmentType: { eq: 'NA' },
                sourceFamilyType: { eq: 'NA' },
                or: [...filterVariable]
              }
            : {
                equipmentType: { eq: type },
                isRDR: { eq: 1 },
                sourceEquipmentType: { eq: 'NA' },
                sourceFamilyType: { eq: 'NA' }
              }
      }
    })
    const uniqueProperties = _uniqBy(
      data?.searchPropertyMetadata?.items,
      'targetKey'
    )
    const newProperties = uniqueProperties?.map(
      ({ id, targetKey, propertyType, tisDisplayName }) => {
        let propName = targetKey
        try {
          propName =
            propertyType === CHARACTERISTIC
              ? `${tisDisplayName} ***`
              : tisDisplayName
        } catch (error) {
          console.warn(error)
        }
        return {
          name: propName ? propName : targetKey,
          targetKey,
          tisDisplayName,
          id,
          value: id,
          propertyType
        }
      }
    )
    return newProperties
  } catch (e) {
    console.error(e)
  }
}

export const filterPropertiesBasedOnMetaData = (allData,metaData) => {
  if(!metaData){
    return allData
  }
  return allData.reduce((acc, propertyMapping) => {
    const propertyReadableDetails =
    metaData?.find(
        (x) => x.targetKey === propertyMapping.targetKey
      )
    if (propertyReadableDetails) {
      acc.push({
        ...propertyMapping,
        tisDisplayName: propertyReadableDetails.tisDisplayName
      })
    }
    return acc
  }, [])
}