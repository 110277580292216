import OpportunityList from './OpportunityList'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import { PAGE_TITLE, PAGE_TYPE } from './constants'
import { useNavigate, Link, useSearchParams } from 'react-router-dom'
import { useOpportunityContext } from './OpportunityContextProvider'
import { useTranslation } from 'react-i18next'
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { DELETE_OPPORTUNITY } from './graphql'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ACTIONS, MODAL_TYPE } from 'src/constants'
import OpportunityCreateEditCopy from './OpportunityCreateEditCopy'
import PAGE_NAMES from 'src/components/legacy/common/pages'
import { getUrlSearchParamValue } from 'src/components/legacy/common/helpers'
import { CREATE_ENTRY_WITH_ATTACHMENT } from 'src/common/queries/attachment'
import { ToolBarWrapper } from './styles'
import { CSVLink } from 'react-csv'
import { CSVLinkWrapper } from '../../pages/opportunities/styles'
import { GET_BUILDING_BY_ID } from 'src/common/queries/datasource'
import OpportunityListPdfButton from 'src/pages/opportunities/OpportunityDetail/pdf/pdf-button'
import translate, { TranslateComponent } from 'src/common/translations'
import { getSearchParams } from "src/common/helperFunctions.js"
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { selectUiMode } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import { UiMode } from 'src/redux/types/AppTypes'
import OpportunitiesContainer from 'src/denali-pages/Opportunities/OpportunitiesContainer'
import { AccessControl } from 'src/components/accessControl'
import { Button } from 'src/components/inputs/button'
import { Dialog } from 'src/denali-components/Dialog/Dialog'
import { DialogConfirm } from 'src/denali-components/Dialog/DialogConfirm'
import ErrorAlert from 'src/components/ErrorAlert'

const OpportunityContainer = ({ action }) => {
  const {
    setMode,
    setReloadEvents,
    modalType,
    setModalType,
    setIsDeletedDone,
    buildingId,
    accountId,
    setOpportunityId,
    mode,
    opportunityInputValues,
    dataList,
    filteredData,
    error
  } = useOpportunityContext()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const hideDeleteDialog = () => {
    setModalType('')
    setIsDeletedDone(false)
  }

  const optionsTranslated = {
    create: translate('Create Opportunity'),
    delete: translate("Delete Opportunity"),
    confirmDelete: translate("Are you sure you want to delete "),
  }

  const [opportunities, setOpportunities] = useState([])
  const {buildingId: locationId, organizationId} = getSearchParams()
  const [searchParams] = useSearchParams()

  const {
    data: buildingData,
    refetch: refetchBuildingData,
    loading
  } = useQuery({
    query: GET_BUILDING_BY_ID,
    disableInitialLoad: true,
    dataPath: 'data.getBuilding'
  })
  const { onSubmit: deleteS3AttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })
  const getCSVOpportunitiesData = useCallback(
    (opportunities) => [
      [
        'Location ID',
        'Organization ID',
        'Link',
        'Opportunity Title',
        'Creation Date',
        'Priority',
        'Status',
        'Next Step',
        'Created By',
        'Assigned To',
        'Energy Savings',
        'Price',
        'Energy Impact',
        'Performance Impact',
        'Reliability Impact',
        'Comfort Impact',
        'Compliance Impact',
        'Last Update'
      ],
      ...opportunities.map((opportunity) => [
        opportunity?.buildingId,
        opportunity?.accountId,
        `${window.location.origin}/${PAGE_NAMES.OPPORTUNITIES}/${opportunity?.id}`,
        opportunity?.title,
        opportunity?.creationDate,
        opportunity?.priority,
        opportunity?.status,
        opportunity?.nextStep,
        opportunity?.createdBy,
        opportunity?.assignedTo,
        opportunity?.energySavings,
        opportunity?.priceDisplay
          ? opportunity?.price
          : t('opportunities:NotEstimated'),
        opportunity?.impactEnergyDisplay ? opportunity?.impactEnergy : '',
        opportunity?.impactPerformanceDisplay
          ? opportunity?.impactPerformance
          : '',
        opportunity?.impactReliabilityDisplay
          ? opportunity?.impactReliability
          : '',
        opportunity?.impactComfortDisplay ? opportunity?.impactComfort : '',
        opportunity?.impactComplianceDisplay
          ? opportunity?.impactCompliance
          : '',
        opportunity?.updatedAt
      ])
    ],
    [dataList]
  )
  const { onSubmit: deleteOpportunity } = useMutation({
    query: DELETE_OPPORTUNITY,
    onSuccess: () => {
      (async () => {
        const attachmentBody = {
          modelType: 'Opportunity',
          modelData: {
            delete: {
              id: opportunityInputValues?.id
            }
          },
          attachments: {}
        }
        await deleteS3AttachmentMutation({
          input: JSON.stringify(attachmentBody)
        })
      })()
      setReloadEvents(true)
      setModalType(MODAL_TYPE.SUCCESS)
      if (mode === ACTIONS.VIEW) {
        navigate(
          `/${PAGE_NAMES.OPPORTUNITIES}${getUrlSearchParamValue(
            opportunityInputValues?.location?.locationId,
            opportunityInputValues?.organization?.organizationId
          )}`
        )
      } else {
        setIsDeletedDone(true)
      }
    trackEvent(USER_EVENTS.OPPORTUNITIES.events.DELETE_OPPORTUNITY, {"opportunity id": opportunityInputValues?.id, "opportunity name":opportunityInputValues?.title})
    }
  })
  const deleteOpportunityHandler = () => {
    deleteOpportunity({ input: { id: opportunityInputValues?.id } })
  }

  const modalConfig = useMemo(
    () => ({
      heading:
        modalType === MODAL_TYPE.OPPORTUNITY_SUCCESS
          ? t(
              `opportunities:${
                mode === ACTIONS.EDIT ? 'EditOpportunity' : 'CreateOpportunity'
              }`
            )
          : optionsTranslated.delete,
      buttons:
        modalType === MODAL_TYPE.CONFIRMATION
          ? [
              {
                text: 'Yes',
                handleClick: deleteOpportunityHandler,
                type: 'valid'
              },
              { text: 'No', handleClick: hideDeleteDialog, type: 'cancel' }
            ]
          : [{ text: 'Close', handleClick: hideDeleteDialog, type: 'cancel' }],
      handleClose: hideDeleteDialog
    }),
    [modalType]
  )

  const renderConfirmationText = (modalType, content = '') => {
    switch (modalType) {
      case MODAL_TYPE.CONFIRMATION:
        return `${optionsTranslated.confirmDelete} ${content}`
      case MODAL_TYPE.OPPORTUNITY_SUCCESS:
        return t(
          `opportunities:${
            mode === ACTIONS.EDIT ? 'EditedSuccessfully' : 'CreatedSuccessfully'
          }`,
          { opportunityName: content }
        )
      default:
        return t('common:DeleteNotification', { name: content })
    }
  }

  useEffect(() => {
    const getAccountDataFunc = async () => {
      if (locationId) {
        await refetchBuildingData({ id: locationId })
      }
    }
    getAccountDataFunc()
  }, [locationId, organizationId, searchParams])

  const getOrganizationName = useMemo(() => {
    return buildingData?.accountName ?? ''
  }, [buildingData])

  const getLocationName = useMemo(() => {
    return locationId
      ? buildingData?.name ?? ''
      : ''
  }, [buildingData])

  const handleGetOpportunitiesRecords = (rows) => {
    setOpportunities(rows)
  }

  const handleCreateOpportunityClicked = () => {
    setMode(ACTIONS.ADD)
    setOpportunityId(null)
    trackEvent(USER_EVENTS.OPPORTUNITIES.events.CLICK_CREATE_OPPORTUNITY)
  }

  const isHasNoData = filteredData ? !filteredData?.length : !dataList?.length

  const uiMode = useSelector(selectUiMode)
  if (uiMode === UiMode.denali && action === PAGE_TYPE.LIST) {
    return (
      <>
        <OpportunitiesContainer
          accountId={accountId}
          buildingId={buildingId}
          dataList={dataList}
          getCSVOpportunitiesData={getCSVOpportunitiesData}
          getLocationName={getLocationName}
          getOrganizationName={getOrganizationName}
          handleCreateOpportunityClicked={handleCreateOpportunityClicked}
          handleGetOpportunitiesRecords={handleGetOpportunitiesRecords}
          loading={loading}
          locationId={locationId}
          locationName={getLocationName}
          opportunities={opportunities}
          organizationName={getOrganizationName}
          uiMode={uiMode}
        />
        {modalType === MODAL_TYPE.CONFIRMATION && (
          <DialogConfirm
            onAgree={deleteOpportunityHandler}
            title={'Delete Opportunity'}
            onClose={hideDeleteDialog}
          >
            {renderConfirmationText(modalType, 'this Opportunity')}
          </DialogConfirm>
        )}
        {modalType === MODAL_TYPE.SUCCESS && (
          <Dialog
            onAgree={deleteOpportunityHandler}
            title={'Delete Opportunity'}
            onClose={hideDeleteDialog}
          >
            {renderConfirmationText(modalType, 'this Opportunity')}
          </Dialog>
        )}
      </>
    )
  }

  return (
    <>
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        padding="0"
      >
        {action === PAGE_TYPE.LIST && (
          <>
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <PageHeader
                pageTitle={<TranslateComponent>{PAGE_TITLE}</TranslateComponent>}
                titleTools={
                  <ToolBarWrapper>
                    <AccessControl id="tc.pages.opportunities.add">
                    <Link
                      to={{
                        pathname: '/Opportunities/add',
                        search: getUrlSearchParamValue(buildingId, accountId)
                      }}
                      state={{ mode: ACTIONS.ADD, id: null }}
                    >
                      <Button
                        onClick={() => {
                          setMode(ACTIONS.ADD)
                          setOpportunityId(null)
                          trackEvent(USER_EVENTS.OPPORTUNITIES.events.CLICK_CREATE_OPPORTUNITY)
                        }}
                      >
                        {optionsTranslated.create}
                      </Button>
                    </Link>
                    </AccessControl>
                    <CSVLinkWrapper no-data={isHasNoData}>
                      <CSVLink
                        data={getCSVOpportunitiesData(
                          filteredData ? filteredData : dataList
                        )}
                        filename={`Opportunities-${
                          locationId ? getLocationName : getOrganizationName
                        }.csv`}
                        className="csv-link"
                        onClick={() => {
                          trackEvent(
                            USER_EVENTS.OPPORTUNITIES.events
                              .DOWNLOAD_OPPORTUNITY_LIST,
                            { format: 'csv' }
                          )
                        }}
                      >
                        <span className="icon icon-csv-icon" />
                      </CSVLink>
                    </CSVLinkWrapper>
                    <OpportunityListPdfButton
                      loading={loading}
                      locationName={getLocationName}
                      organizationName={getOrganizationName}
                      allOrganizations={false}
                      opportunities={opportunities} 
                    />
                  </ToolBarWrapper>
                }
              />
            </Content>
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <OpportunityList
                handleGetOpportunitiesRecords={handleGetOpportunitiesRecords}
              />
            </Content>
          </>
        )}
      </Container>

      {action !== PAGE_TYPE.LIST && (
        <>
        {
          error?.isError ?(
            <ErrorAlert errorMsg={error?.errorMsg} /> 
          ):(
            <OpportunityCreateEditCopy />
          )
        }
        </>
      )}
      {[
        MODAL_TYPE.CONFIRMATION,
        MODAL_TYPE.SUCCESS,
        MODAL_TYPE.OPPORTUNITY_SUCCESS
      ].includes(modalType) ? (
        <Modal {...modalConfig}>
          <ConfirmationText>
            {renderConfirmationText(modalType, opportunityInputValues?.title)}
          </ConfirmationText>
        </Modal>
      ) : null}
    </>
  )

}

export default OpportunityContainer
