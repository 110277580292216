import React from 'react'

export const SelectrixSelection = ({ label }) => (
  <div tabIndex="0" className="rs-toggle rs-selected">
    {label}
  </div>
)

export default {
  SelectrixSelection
}
