import { StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

export const imagesStyles = StyleSheet.create({
	image: {
		objectFit: "scale-down"
	},
	imageBlock: {
		borderWidth: 1,
		borderColor: colors.GREY_6,
		padding: 10,
		marginTop: 10,
	},
	imageBlockWithoutBorder: {
		marginTop: 10,
	},
	imageContainer: {
		maxWidth: "595px",
		maxHeight: "450px"
	},
	caption: {
		fontSize: 10,
		marginTop: 10,
		fontStyle: "italic"
	},
	imageCaption: {
		fontSize: 10,
		color: colors.TBA_DARK_GREY
	},
	imageTitle: {
		fontWeight: "bold"
	},
})
