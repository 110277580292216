import { useEffect, useState, useMemo } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { BuildingDataChart } from 'src/pages/buildingData/BuildingDataChart'
import { useGetChartData } from 'src/hooks/chartDataHook'
import { getStartAndEndDates } from 'src/pages/dashboards/helper'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { ListBuildingEquipmentsByFilter } from 'src/pages/buildingData/graphql'
import ExpressionUtil from 'src/pages/buildingData/CalculationPointUtil'
import {
  ChartLegends,
  PREVIEW_BUILDING_DATA_PAGE_NAMES
} from 'src/pages/buildingData/BuildingDataContainer'
import {
  BuildingDataWidgetChartContainer,
  BuildingDataPreviewHeader
} from './style'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import Icon from 'src/denali-ui/components/Icon'
import moment from 'moment'
import { TEXT_DATE_TIME_FORMAT } from 'src/components/legacy/common/time-helpers'
import { Link } from 'react-router-dom'
import { setPageReduxObject } from 'src/redux/slicers/buildingPicker'
import {
  groupByPropertykey,
  formatMigratedProperties,
  formatMigratedCalcProperties
} from 'src/pages/buildingData/util'
import { useDispatch } from 'react-redux'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'

import {
  SimpleLegend,
  createSimpleLegendModel
} from '../../../../chart-library/CommonComponents/Legend'
import translate, { TranslateComponent } from 'src/common/translations'

const getBuildingDataChartData = `query getBuildingDataChart($id: ID!) {
    getBuildingDataChart(id: $id) {
        name
        id
        equipmentId
        accountId
        properties
    }
  }`

export const BuildingDataChartPreview = ({ widgetDetails, dimension }) => {
  const dispatch = useDispatch()

  const [propertiesListForPlot, setPropertiesListForPlot] = useState([])

  const [buildingDataChartData, setBuildingDataChartData] = useState(null)

  const [calcPropsWithEquipmentNames, setCalcPropsWithEquipmentNames] =
    useState()
  const [propertiesWithEquipmentNames, setPropertiesWithEquipmentNames] =
    useState([])

  const selectedTimePeriod =
    widgetDetails?.timePeriod?.split('/')?.[1] || 'current-month'

  const { getChartData, chartData, loadingChartData } = useGetChartData()

  const {
    data: buildingDataChartDetails,
    refetch: getBuildingDataChartDetails
  } = useQuery({
    query: getBuildingDataChartData,
    disableInitialLoad: true,
    variables: { id: widgetDetails?.chartId },
    dataPath: 'data.getBuildingDataChart'
  })
  const { startDate, endDate } = useMemo(
    () =>
      getStartAndEndDates(
        selectedTimePeriod,
        false,
        null,
        widgetDetails?.timeZone
      ),
    [selectedTimePeriod]
  )

  const {
    refetch: fetchEquipmentsByBuildingId,
    loading: loadingEquipmentsByBuildingId
  } = useQuery({
    query: ListBuildingEquipmentsByFilter,
    dataPath: 'data.getBuilding',
    disableInitialLoad: true
  })

  const getTimePeriodInfo = () => {
    try {
      const lDateFormatByLocale = getDateFormatByLocale('MMM DD,YYYY')
      const timeFormat = 'hh:mm A MMM DD,YYYY'
      const monthDateFormat = lDateFormatByLocale
        ? lDateFormatByLocale
        : 'MMM DD,YYYY'
      const monthYearFormat = 'MMM,YYYY'
      switch (selectedTimePeriod) {
        case 'current-day':
          return moment().format(monthDateFormat)
        case 'current-week':
          const startDate = moment().startOf('week').format(monthDateFormat)
          const endDate = moment().format(monthDateFormat)
          return `${startDate}-${endDate}`
        case 'current-month':
          return moment().format('MMM,YYYY')
        case 'current-quarter':
          const quarter = moment()?.quarter()
          const year = moment().format('YYYY')
          return `Q${quarter}-${year}`
        case 'current-year':
          return moment().format('YYYY')
        case 'past-hour':
          const startHour = moment().subtract(1, 'hours').format(timeFormat)
          const endHour = moment().format(timeFormat)
          return `${startHour}-${endHour}`
        case 'past-24-hours':
          const star24Hours = moment().subtract(24, 'hours').format(timeFormat)
          const end24Hours = moment().format(timeFormat)
          return `${star24Hours}-${end24Hours}`
        case 'past-7-days':
        case 'past-14-days':
        case 'past-30-days':
        case 'past-90-days':
          const days = selectedTimePeriod.match(/\d+/)?.[0] || 7
          const startDays = moment()
            .subtract(days, 'days')
            .format(monthDateFormat)
          const endDays = moment().format(monthDateFormat)
          return `${startDays}-${endDays}`
        case 'past-12-months':
          const starMonth = moment()
            .subtract(12, 'months')
            .format(monthYearFormat)
          const endMonth = moment().format(monthYearFormat)
          return `${starMonth}-${endMonth}`
        default:
          return ''
      }
    } catch (error) {
      return ''
    }
  }

  useEffect(() => {
    if (widgetDetails?.chartId) {
      getBuildingDataChartDetails()
    }
  }, [selectedTimePeriod])

  const calcPointsValuesCalculations = (formula, bindings) => {
    return ExpressionUtil.execute(formula, bindings)
  }

  useEffect(() => {
    if (chartData) {
      // check all any one property has data
      const isDataAvailable = chartData?.data?.some((x) => x?.isDataAvailable)

      if (!isDataAvailable) {
        return
      }
      const calculatedPropertiesArray = []
      const calcPoints = calcPropsWithEquipmentNames
      if (calcPoints?.length > 0) {
        calcPoints.forEach((calcFormula) => {
          const properties = calcFormula?.propertiesList
          const chartValues = {}
          properties?.forEach((property) => {
            const propertyIdentifier = `${property?.propertyName}_${property?.identifier}`
            const chartPropertyData =
              chartData?.data?.find(
                (x) =>
                  x.equipmentId === property?.equipmentId &&
                  x?.propertyKey === property?.propertyName
              )?.values?.[0] || []
            chartValues[propertyIdentifier] = chartPropertyData?.map((x) => {
              return { value: x?.value, timestamp: x?.timeStamp }
            })
          })
          const values = calcPointsValuesCalculations(
            calcFormula?.formula,
            chartValues
          )
          const valuesArray = values === false ? [[0]] : [values]
          calculatedPropertiesArray.push({
            equipmentId: calcFormula?.propertiesList?.[0]?.equipmentId,
            propertyKey: calcFormula?.name,
            propertyName: calcFormula?.name,
            values: valuesArray
          })
        })
      }

      const finalDataArray = []
      finalDataArray?.push(...calculatedPropertiesArray)

      allSelectedPropertiesForPlot
        ?.filter((x) => !x.calcProperty)
        ?.forEach((properties) => {
          const dataArray = chartData?.data?.find(
            (x) =>
              properties?.equipmentId === x.equipmentId &&
              properties.propertyKey === x?.propertyKey
          )
          if (dataArray) {
            finalDataArray?.push(dataArray)
          }
        })

      chartData.data = finalDataArray

      setBuildingDataChartData(chartData)
    }
  }, [chartData])

  const formatPickerDataToChartRequest = (
    objectPickerSelection,
    calcProperties
  ) => {
    try {
      const calculatedpropertiesList = []
      calcProperties?.map((calcPoint) => {
        const properties = calcPoint.propertiesList?.map((x) => {
          return {
            ...x,
            propertyKey: x?.propertyName,
            equipmentType: x?.equipmentFamily
          }
        })
        calculatedpropertiesList.push(...properties)
      })
      const objectPickerProperties = [
        ...objectPickerSelection,
        ...calculatedpropertiesList
      ]
      const chartFormatDetails = []
      const propertiesByBuildings = groupByPropertykey(
        objectPickerProperties,
        'buildingId'
      )

      propertiesByBuildings.forEach((building) => {
        const equipmentDetails = []

        const groupByEquipmentId = groupByPropertykey(
          objectPickerProperties || [],
          'equipmentId'
        )
        groupByEquipmentId?.forEach((equipment) => {
          const propertiesByEquipment = equipment?.properties
          propertiesByEquipment?.length > 0 &&
            equipmentDetails.push({
              type: propertiesByEquipment?.[0].equipmentType,
              equipmentName: propertiesByEquipment?.[0]?.equipmentName,
              eId: [{ id: propertiesByEquipment?.[0].equipmentId }],
              pId: [...propertiesByEquipment?.map((x) => x?.propertyKey)]
            })
        })

        chartFormatDetails.push({
          buildingId: building?.buildingId,
          equipmentDetails: equipmentDetails
        })
      })
      return chartFormatDetails
    } catch (error) {
      return []
    }
  }

  const initializePropertiesData = async () => {
    const chartDetails = buildingDataChartDetails
    const parsedResponse = JSON.parse(chartDetails?.properties)
    const propertiesList = parsedResponse?.properties
    const propertiesWithEquipmentNames = await formatMigratedProperties(
      propertiesList,
      fetchEquipmentsByBuildingId
    )
    setCalcPropsWithEquipmentNames()
    const calcPropertiesList = parsedResponse?.calculatedProperties
    const calcPropWithEquipmentNames = await formatMigratedCalcProperties(
      calcPropertiesList,
      fetchEquipmentsByBuildingId
    )
    const formatResponse = formatPickerDataToChartRequest(
      propertiesWithEquipmentNames || [],
      calcPropWithEquipmentNames || []
    )

    setCalcPropsWithEquipmentNames(calcPropWithEquipmentNames)
    setPropertiesWithEquipmentNames(propertiesWithEquipmentNames)

    // Chart request format
    setPropertiesListForPlot(formatResponse)
  }

  useEffect(() => {
    try {
      if (buildingDataChartDetails) {
        initializePropertiesData()
      }
    } catch (error) {}
  }, [buildingDataChartDetails])

  const allSelectedPropertiesForPlot = useMemo(() => {
    try {
      const calcPropertiesList = calcPropsWithEquipmentNames
      const selectedProperties = propertiesWithEquipmentNames
      const allPropertiesList = []

      selectedProperties?.forEach((property) => {
        allPropertiesList.push({
          ...property,
          propertyNameWithSymbol: `${property?.propertyName}${
            property?.symbol ? ' (' + property?.symbol + ')' : ''
          }`,
          otherDetails: ` in ${property?.equipmentName} (${property?.buildingName})`
        })
      })

      calcPropertiesList?.forEach((calcPoints) => {
        const propertiesList = calcPoints?.propertiesList || []
        const uom =
          calcPoints?.uom === 'custom' ? calcPoints?.customUOM : calcPoints.uom
        const property = propertiesList?.[0] || {}
        allPropertiesList.push({
          ...property,
          color: calcPoints?.color,
          propertyKey: calcPoints?.name,
          name: calcPoints?.name,
          calcProperty: true,
          symbol: uom,
          propertyNameWithSymbol: `${calcPoints?.name}(${uom || ''})`,
          otherDetails: ``
        })
      })

      return allPropertiesList
    } catch (error) {
      return []
    }
  }, [propertiesListForPlot])

  const chartArea = useMemo(() => {
    try {
      // This method dynamically finds space required for Legends
      // We create a container and append legends, find height and removes container
      let containerHeight = dimension?.height - 100 // date and info container height
      let occupancyHeight = 10
      let showTooltip = false
      // These are all based on height, fixed legends count
      if (allSelectedPropertiesForPlot?.length > 0) {
        if (dimension?.height === 300) {
          if (allSelectedPropertiesForPlot?.length > 2) {
            occupancyHeight = 30
            showTooltip = true
          } else {
            occupancyHeight = allSelectedPropertiesForPlot?.length * 16
            showTooltip = false
          }
        } else if (dimension?.height === 600) {
          if (allSelectedPropertiesForPlot?.length > 8) {
            occupancyHeight = 70
            showTooltip = true
          } else {
            occupancyHeight = (allSelectedPropertiesForPlot?.length / 2) * 16
            showTooltip = false
          }
        } else {
          if (allSelectedPropertiesForPlot?.length > 15) {
            occupancyHeight = 95
            showTooltip = true
          } else {
            occupancyHeight = (allSelectedPropertiesForPlot?.length / 2) * 16
            showTooltip = false
          }
        }
      }
      let chartHeight = containerHeight - occupancyHeight
      return {
        legendsHeight: occupancyHeight,
        chartHeight: chartHeight <= 170 ? 170 : chartHeight,
        showTooltip
      }
    } catch (error) {}
  }, [allSelectedPropertiesForPlot, dimension?.height, dimension?.width])

  useEffect(() => {
    if (propertiesListForPlot?.length > 0) {
      const chartData = propertiesListForPlot

      const chartDataRequest = {
        startDate: startDate,
        endDate: endDate,
        category: 'Equipment', // equipment or building level data needed
        chartType: 'Line',
        chartDetails: chartData
      }

      getChartData(chartDataRequest)
    }
  }, [propertiesListForPlot])

  const setChartIdToRedux = (chartId) => {
    dispatch(
      setPageReduxObject({
        type: 'Success',
        chartId
      })
    )
  }
  const dataUpdated = translate('Data Updated')
  const lDateFormatbyLocale = getDateFormatByLocale(TEXT_DATE_TIME_FORMAT) //'MMM D, YYYY h:mm A'

  return loadingChartData ? (
    <Spinner />
  ) : allSelectedPropertiesForPlot?.length > 0 ? (
    <div className="has-link">
      <Link
        onClick={() =>
          buildingDataChartDetails?.accountId &&
          setChartIdToRedux(widgetDetails?.chartId)
        }
        to={
          buildingDataChartDetails?.accountId
            ? `/building-data?organization=${buildingDataChartDetails?.accountId}`
            : '#'
        }
      >
        <BuildingDataWidgetChartContainer
          legendHeight={chartArea?.legendsHeight}
        >
          <BuildingDataPreviewHeader>
            <div style={{ 'text-transform': 'capitalize' }}>
              {getTimePeriodInfo()}
            </div>
            <div className="icon-content">
              <ConditionalTooltip
                type={ConditionalTooltip.TYPE.ICON}
                content={`${dataUpdated} : ${moment().format(
                  lDateFormatbyLocale
                    ? lDateFormatbyLocale
                    : TEXT_DATE_TIME_FORMAT
                )}`}
              >
                <Icon
                  width="15px"
                  height="15px"
                  name="infocircle2"
                  color="#bfbfbf"
                />
              </ConditionalTooltip>
            </div>
          </BuildingDataPreviewHeader>
          <BuildingDataChart
            isWidget
            propertiesList={allSelectedPropertiesForPlot}
            chartData={buildingDataChartData}
            width={dimension?.width || 300} // for safer side not to overlap, 100px added
            height={chartArea?.chartHeight || 170} // for safer side not to overlap, 100px added
            loadingChartData={loadingChartData}
            pageName={PREVIEW_BUILDING_DATA_PAGE_NAMES?.dashboard}
          />
        </BuildingDataWidgetChartContainer>

        <WidgetLegends
          properties={allSelectedPropertiesForPlot || []}
          isWidget
          containerWidth={dimension?.width || 170}
          showLegendTooltip={chartArea?.showTooltip}
        />
      </Link>
    </div>
  ) : (
    <p>No Data</p>
  )
}

const getWidgetLegendColumnsClassName = (containerWidth) => {
  if (containerWidth === 300) {
    return 'one-column'
  } else if (containerWidth === 600) {
    return 'two-column'
  } else {
    return 'three-column'
  }
}

function WidgetLegends({
  properties = [],
  isWidget = false,
  containerWidth,
  showLegendTooltip
}) {
  const simpleLegends = properties.map((prop) =>
    createSimpleLegendModel({
      text: (
        <div>
          <b>
            <TranslateComponent>
              {prop.propertyNameWithSymbol}
            </TranslateComponent>
          </b>
          {prop.widgetlegendLabelMoreDetail ?? ''}
        </div>
      ),
      iconProps: {
        symbol: 'symbolSquare',
        fill: prop?.color || 'steelblue'
      }
    })
  )

  return (
    <SimpleLegend
      isStandaloneLegend
      legends={simpleLegends}
      className={`dashboard-widget-building-data-simple-legend ${getWidgetLegendColumnsClassName(
        containerWidth
      )}`}
      showTooltipFlag={showLegendTooltip}
    />
  )
}
