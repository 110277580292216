import PropTypes from "prop-types"
import { MODES } from "../../constants"
import ConsultationToggleBox from '../consultationToggleBox/index'
import WhereWeGoFromHereView from './where-we-go-from-here-view'

const WhereWeGoFromHere = (props) => {
		const { mode, width, opportunities, settings: { enabled, title }, errors, onUpdateFields } = props
		if (mode !== MODES.EDIT && enabled === false) {
			return null
		}

		return	<ConsultationToggleBox
			onChangeToggleButton={onUpdateFields ? onUpdateFields("settings.whereWeGoFromHere.enabled") : undefined}
			onChangeTitle={onUpdateFields ? onUpdateFields("settings.whereWeGoFromHere.title") : undefined}
			checkedToggleButton={enabled}
			translationLabel="consultations:WhereWeGoFromHere"
			title={title}
			errors={errors}
		>
			<WhereWeGoFromHereView containerWidth={width} opportunities={opportunities}/>
		</ConsultationToggleBox>
}

WhereWeGoFromHere.propTypes = {
	mode: PropTypes.string,
	organizationId: PropTypes.number,
	locationIds: PropTypes.array,
	width: PropTypes.number,
	opportunities: PropTypes.array,
	onUpdateFields: PropTypes.func,
	settings: PropTypes.shape({
		enabled: PropTypes.bool,
		title: PropTypes.string,
	}).isRequired,
	errors: PropTypes.object,
}
WhereWeGoFromHere.defaultProps = {
	mode: MODES.EDIT,
	opportunities: [],
}

export default WhereWeGoFromHere;
