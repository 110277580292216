import React, { useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

export function DebounceInput({
  value,
  debounceTimeout,
  onChange,
  type,
  testName,
  ...props
}) {
  const [stateValue, setStateValue] = useState(value)
  const prevValueRef = useRef()
  const debouncedOnChange = useCallback(
    debounce((event) => {
      onChange(event)
    }, debounceTimeout),
    [onChange]
  )

  const innerOnChange = useCallback(
    (event) => {
      event.persist()
      setStateValue(event.target.value)
      debouncedOnChange(event)
    },
    [debouncedOnChange]
  )

  useEffect(() => {
    prevValueRef.current = value
  })

  if (
    typeof value !== 'undefined' &&
    prevValueRef.current !== value &&
    stateValue !== value
  ) {
    setStateValue(value)
  }

  if (type !== 'textarea') {
    return (
      <input
        data-testid={`${testName || 'tc'}_input`}
        type={type}
        value={stateValue}
        onChange={innerOnChange}
        {...props}
      />
    )
  } else {
    return <textarea value={stateValue} onChange={innerOnChange} {...props} />
  }
}

DebounceInput.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  debounceTimeout: PropTypes.number
}

DebounceInput.defaultPropTypes = {
  type: 'text',
  value: '',
  debounceTimeout: 300
}
