import { useEffect } from 'react'
import { useSyncSeriesPointsForTooltip } from '../TooltipNew'


function roudOff(value) {
  return parseFloat(Number(value).toFixed(2))
}

function plotBandTooltipModel(
  data,
  width,
  props,
  modelCreator = (...args: any) => null
) {
  const label = props?.text
  const value = props?.value
  const x = roudOff(data.x)
  const y = roudOff(data.y)
  const color = props?.name?.fontColor || props?.fill || 'steelblue'

  const defaultProps = {
    // Label of the data point
    label,
    // value that will be displayed against the label
    value,
    // title by default x value (usually date time )
    title: false,
    // x position
    x,
    y,
    // by default 'title' means based on x axis date will be grouped and displays it
    groupBy: false,
    color,
    // if position is optional, tooltip will not consider XY postion of the plotBand. 
    // Instead use other series xy to position the tooptip
    // Incase if no other XY available, this will be used for the positioning
    tooltipPositon: 'optional'
  }

  const tooltipDataModelFormatter =
    typeof props?.tooltipDataModelFormatter === 'function'
      ? props.tooltipDataModelFormatter
      : () => null

  const userformattedValues = tooltipDataModelFormatter({
    ...defaultProps,
    ...props
  })

  return modelCreator(
    roudOff(data.x),
    {
      // Default model values
      ...defaultProps,

      // user modified values
      ...userformattedValues,
      higlightDots: false
    },
    {
      x: x,
      y: 0,
      maxX: x+width,
      maxY: 0
    }
  )
}

export default function usePlotBandTooltip({ from, to, props }: any) {
  const { addPoints, deletePoints, createTooltipPointModel } =
    useSyncSeriesPointsForTooltip(true)

  useEffect(() => {
    
    if (from.x < 0 || from.y < 0 || to.x < 0 || to.y < 0 || props.hideTooltip) return () => null


    const width = roudOff(to.x) - roudOff(from.x)
    const fromPoint = plotBandTooltipModel(
      from,
      width,
      props,
      createTooltipPointModel
    )
    const toPoint = plotBandTooltipModel(to, 0, props, createTooltipPointModel)

    const inputModel = [fromPoint]

    inputModel.length && addPoints(inputModel)

    return () => {
      inputModel.length && deletePoints(inputModel)
    }
  }, [from, to, props])
}
