import React, { PureComponent } from 'react'
import Modal from '../../../components/legacy/components/modal/modal'
import Checkbox from '../../../components/legacy/components/checkbox/checkbox'
import PropTypes from 'prop-types'
import './location-management-terms-and-conditions.scss'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { TranslateComponent } from '../../../common/translations'

export class TermsConditionsBuildingSetup extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func,
    handleSubmit: PropTypes.func,
    formData: PropTypes.any,
    pageName: PropTypes.string,
    termsandconditionsText : PropTypes.any
  }
  state = {
    termscondn: false
  }
  render() {
    const { closeModal, handleSubmit, formData, pageName, termsandconditionsText } = this.props
    return (
      <div>
        <Modal
          gray={true}
          className="terms-and-conditions-dialog"
          testName={`${pageName}_termsandconditionmodal_close`}
          handleClose={closeModal}
          buttons={[
            {
              text: termsandconditionsText.Cancel,
              handleClick: closeModal,
              type: 'cancel',
              testName: `${pageName}_termsandconditionmodal_cancel`
            },
            {
              text: termsandconditionsText.Save,
              testName: `${pageName}_termsandconditionmodal_save`,
              handleClick: () => {
                handleSubmit(formData)
                closeModal()
                trackEvent(USER_EVENTS.BUILDING_SETUP.events.SAVE_BUILDING, {"location name":formData.locationName, "account name": formData.accountName, "sales office": formData.salesOffice.officeFullName, mode:"Create"})
              },
              type: 'confirm',
              disabled: !this.state.termscondn
            }
          ]}
          heading={termsandconditionsText.Title}
        >
          <div className="terms-conditions-wrapper">
            <h4>
              {termsandconditionsText.Subtitle}
            </h4>
            <ul>
              <li>
                {termsandconditionsText.Term1}
              </li>
              <li>
               <TranslateComponent> The Building Automation System being used on this site is Trane
                Tracer SC v4.1 or earlier. I have downloaded the</TranslateComponent> <br />
                <TranslateComponent> supplemental </TranslateComponent>
                <a
                  data-testid={`${pageName}_termsandconditionmodal_termsandcondition_link`}
                  href="https://denali-staticfiles-yceuhyn4mreaxg5b7bcr2yjqqy-devds-us-east-1.s3.us-east-1.amazonaws.com/traneconnect/Resources/TIS2/Agreement/Trane_Remote_Monitoring_General_Data_Access_Agreement_May2015.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TranslateComponent>terms and conditions</TranslateComponent>
                </a>
                , <TranslateComponent>the customer has agreed to them, and I have submitted them to</TranslateComponent>
                <a
                  data-testid={`${pageName}_termsandconditionmodal_email_link`}
                  href="mailto:traneintellegentservices@trane.com"
                >
                  traneintellegentservices@trane.com
                </a>{' '}
                <TranslateComponent>as proof the customer of this facility authorizes the use of
                Remote Access and Trane to collect/store data from this site.</TranslateComponent>
                {/* </Trans> */}
              </li>
            </ul>
            <Checkbox
              label={termsandconditionsText.ConfirmText}
              id="termscondn"
              isChecked={this.state.termscondn}
              toggleCheck={(event) =>
                this.setState({ termscondn: event.target.checked })
              }
              testName={`${pageName}_termsandconditionmodal_acceptcondition`}
            />
          </div>
        </Modal>
      </div>
    )
  }
}

export default TermsConditionsBuildingSetup
