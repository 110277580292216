import * as d3 from 'd3'
import { isFunction, isNumber, reverse, isNaN } from 'lodash'
import { ScaleType } from '../../Types/scale'
import { SCALE_BAND, SCALE_TIME } from '../../Utils/Scales/constant'
import { d3Picker, d3Properties } from '../../Utils/common'
import { getBandWidth } from 'src/chart-library/Charts/XYChart/helper'

export const lineGenerator = (generatorObj: any = {}) => {
  const {
    xscaleType,
    yscaleType,
    xData = [],
    yData = [],
    xScale,
    yScale,
    invert = false,
    settings = {},
    properties = {}
  } = generatorObj

  let bandWidth = 0
  if (xscaleType === 'scaleBand') {
    bandWidth += getBandWidth(xScale) / 2
  }
  const defaultSettings: any = {
    ...settings
  }
  let xValues = xData;
  let yValues = yData;
  if (properties?.noBreak) {
    const newXValues = []
    const newYValues = []
    xData?.forEach((value: any, i: number) => {
      const yVal = yData?.[i]
      if (isNumber(yVal) && !isNaN(yVal)) {
        newXValues.push(value)
        newYValues.push(yVal)
      }
    })
    xValues = newXValues
    yValues = newYValues
  }
  if (!defaultSettings?.['defined']?.value) {
    defaultSettings.defined = {
      value: (d: any, i: number) => isNumber(yValues[i]) && !isNaN(yValues[i])
    }
  } else {
    const definedFunction = defaultSettings.defined.value
    const customDefinedFunction = definedFunction.bind(null, yValues, xValues)
    defaultSettings.defined = {
      value: customDefinedFunction
    }
  }
  if (isFunction(yScale) && isFunction(xScale)) {
    let dataPoints: any = [
      dataPointFunction(xscaleType, xScale, xValues, bandWidth),
      dataPointFunction(yscaleType, yScale, yValues)
    ]
    if (invert) {
      dataPoints = reverse(dataPoints)
    }
    const line: any = d3.line(...dataPoints)
    const newLine: any = applyAdditionalProperties(line, defaultSettings)
    return newLine(xValues)
  } else {
    return ''
  }
}

const dataPointFunction = (
  scaleType: ScaleType,
  scale: any,
  data: any = [],
  bandWidth = 0
) => {
  if (scaleType === SCALE_TIME) {
    return (d: any, i: number) => scale(new Date(data[i]))
  } else if (scaleType === SCALE_BAND) {
    return (d: any, i: number) => {
      return scale(data[i]) + bandWidth
    }
  } else {
    return (d: any, i: number) => scale(isNumber(data[i]) ? data[i]: null)
  }
}

const applyAdditionalProperties = (line: any, settings: any) => {
  Object.keys(settings)?.forEach((prop: any) => {
    if (line[prop]) {
      if (settings[prop]?.value) {
        line[prop](settings[prop]?.value)
      } else {
        const type: d3Properties = settings[prop]?.type
        const additionalProp: any = d3Picker(type)
        if (settings[prop]?.settings) {
          Object.keys(settings[prop]?.settings)?.forEach((prop: any) => {
            if (additionalProp[prop]) {
              additionalProp[prop](additionalProp[prop])
            }
          })
        }
        line[prop](additionalProp)
      }
    }
  })
  return line
}
