export const LIST_APPLICATIONS = /* GraphQL */ `
  query LIST_APPLICATIONS {
    listApplications {
      items {
        id
        description
        createdAt
        clientIds
        appId
        name
        updatedAt
      }
    }
  }
`

export const CREATE_APPLICATION = /* GraphQL */ `
  mutation CREATE_APPLICATION($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      id
    }
  }
`

export const UPDATE_APPLICATION = /* GraphQL */ `
  mutation UPDATE_APPLICATION($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      id
    }
  }
`

export const DELETE_APPLICATION = /* GraphQL */ `
  mutation DELETE_APPLICATION($input: DeleteApplicationInput!) {
    deleteApplication(input: $input) {
      id
    }
  }
`


