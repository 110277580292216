import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignalStream, faSignalStreamSlash } from 'src/denali-components/lib/pro-solid-svg-icons'
import { Flex } from "@aws-amplify/ui-react";
import styles from './device-connect.module.scss'
import { TranslateComponent } from 'src/common/translations';

export const DeviceAvailability = ({device}) => {
  if(!device) return;

  if(!device?.remoteAccessStatus) {
    return (
      <Flex className={styles.notAvailable} alignItems='center' justifyContent='flex-start' gap={2}>
        <FontAwesomeIcon icon={faSignalStreamSlash} />
        <TranslateComponent>Not Available</TranslateComponent>
      </Flex>
    );
  }

  return (
    <Flex className={styles.available} alignItems='center' justifyContent='flex-start' gap={2}>
      <FontAwesomeIcon icon={faSignalStream}/>
      <TranslateComponent>Available</TranslateComponent>
    </Flex>
  );
}