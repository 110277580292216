import colors from "../legacy/common/colors.json"

import styled from "styled-components"

export const Icon = styled.div`
	float: right;
	padding: 5px;
	border: 1px solid #999999;
	height: 26px;
	border-left: white;
`

export const Wrapper = styled.div`
background-color: ${colors.TBA_DARKER_GREY};
border-radius: 2px;
display: inline-block;
white-space: nowrap;
${props => props.disabled && "pointer-events: none; cursor: not-allowed;"}
cursor: pointer;
.clockbutton {
	padding-left: 3px;
	padding-right: 3px;
	font-size: 18px;
	vertical-align: middle;
	color: ${colors.WHITE};
	justify-content: center;
	align-items: center;
}
.rc-time-picker {
	display: inline-block;
	box-sizing: border-box;
	flex-grow: 1;
	background: #F4F4F4;
	border-radius: 2px;
	${props => props.error && "border: 2px solid red"};
}
.rc-time-picker * {
	box-sizing: border-box;
}
.rc-time-picker-input {
    width:100px;
	position: relative;
	display: inline-block;
	padding: 0px 8px 0px 8px;
	height: 26px;
	cursor: text;
	font-size: 12px;
	line-height: 1.5;
	color: black;
	background-color:${props => props.error ? colors.RED_5 : props.backgroundColor ? props.backgroundColor : "#F4F4F4"};
	background-image: none;
	${props => props.textAlign && `text-align: ${props.textAlign};`}
	${props => props.width && `width: ${props.width};`}
	transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	${props => props.information && "border-right: none"};
}

${props => props.disabled && `.rc-time-picker-input[disabled] {
	background: ${colors.TBA_LIGHT_GREY};
		border-color: ${colors.TBA_MEDIUM_GREY};
		color: ${colors.TBA_DARK_GREY};
		cursor: not-allowed;
	}`}
.rc-time-picker-panel {
	z-index: 1070;
	position: absolute;
	box-sizing: border-box;
	${props => props.width && `width: calc(${props.width} + 2px);`}
}
.rc-time-picker-panel * {
	box-sizing: border-box;
}
.rc-time-picker-panel-inner {
	display: inline-block;
	position: relative;
	outline: none;
	list-style: none;
	font-size: 12px;
	text-align: left;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 1px 5px #999999;
	background-clip: padding-box;
	border: 1px solid #999999;
	line-height: 1.5;
}

.rc-time-picker-panel-input {
	margin: 0;
	padding: 0;
	width: 100%;
	cursor: auto;
	line-height: 1.5;
	outline: 0;
	border: 1px solid transparent;
	${props => props.textAlign && `text-align: ${props.textAlign};`}
}
.rc-time-picker-panel-input-wrap {
	box-sizing: border-box;
	position: relative;
	padding: 6px;
	border-bottom: 1px solid #e9e9e9;
}
.rc-time-picker-panel-input-invalid {
	border-color: red;
}
.rc-time-picker-panel-clear-btn {
	position: absolute;
	right: 6px;
	cursor: pointer;
	overflow: hidden;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	top: 6px;
	margin: 0;
}
.rc-time-picker-panel-clear-btn:after {
	content: "x";
	font-size: 12px;
	color: #aaa;
	display: inline-block;
	line-height: 1;
	width: 20px;
	transition: color 0.3s ease;
}
.rc-time-picker-panel-clear-btn:hover:after {
	color: #666;
}
.rc-time-picker-panel-select {
	float: left;
	font-size: 12px;
	border: 1px solid #e9e9e9;
	border-width: 0 1px;
	margin-left: -1px;
	box-sizing: border-box;
	width: 50%;
	max-height: 144px;
	overflow-y: hidden;
	overflow-x: hidden;
	position: relative;
}
.rc-time-picker-panel-select-active {
	overflow-y: auto;
}
.rc-time-picker-panel-select:first-child {
	border-left: 0;
	margin-left: 0;
}
.rc-time-picker-panel-select:last-child {
	border-right: 0;
}
.rc-time-picker-panel-select ul {
	list-style: none;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: 100%;
}
.rc-time-picker-panel-select li {
	list-style: none;
	box-sizing: content-box;
	margin: 0;
	padding: 0 0 0 16px;
	width: 100%;
	height: 24px;
	line-height: 24px;
	text-align: left;
	cursor: pointer;
	color: black;
}
.rc-time-picker-panel-select li:hover {
	background: #edfaff;
}
li.rc-time-picker-panel-select-option-selected {
	background: #f7f7f7;
	font-weight: bold;
	color: black;
}
li.rc-time-picker-panel-select-option-disabled {
	color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
	background: transparent;
	cursor: not-allowed;
}
.timepicker-icon-container {
	display: inline-block;
	min-width: 30px;
	max-height: 26px;
	text-align: center;
	cursor: pointer;
}

`