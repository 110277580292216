import { KPI_MEASUREMENTS_DATA } from '../../helpers'
import colors from 'src/components/layouts/colors.json'
import moment from 'moment/moment'
import _last from 'lodash/last'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'

export const POINT_MODE = 'point'
export const TREND_MODE = 'trend'

export const GOAL_TYPE_POINT = {
  met: 'met',
  beat: 'beat',
  missed: 'missed'
}

export const GOAL_TYPE_TREND = {
  met: 'meeting',
  beat: 'beating',
  missed: 'missing'
}

export const getIcon = (kpiMeasurement) => {
  return KPI_MEASUREMENTS_DATA.get(kpiMeasurement)?.icon
}

export const notEmpty = (value) => {
  return (
    value !== '' && value !== undefined && value !== null && isFinite(value)
  )
}

export const getGoalType = (
  trendUpIsGood,
  current,
  goal,
  type = POINT_MODE
) => {
  if (notEmpty(current) && notEmpty(goal)) {
    const GOAL_TYPE = type === TREND_MODE ? GOAL_TYPE_TREND : GOAL_TYPE_POINT

    if (current === goal) {
      return GOAL_TYPE.met
    }

    const beat =
      (trendUpIsGood && Number(current) > Number(goal)) ||
      (!trendUpIsGood && Number(current) < Number(goal))

    return beat ? GOAL_TYPE.beat : GOAL_TYPE.missed
  }

  return null
}

export const getValuesCurrentBg = (previous, current, goal, trendUpIsGood) => {
  if (
    (trendUpIsGood && current >= goal) ||
    (!trendUpIsGood && current <= goal)
  ) {
    return WIDGET_STYLES.valuesBlockBgGreen
  }

  if (
    (trendUpIsGood && current >= previous && current < goal) ||
    (!trendUpIsGood && current <= previous && current > goal)
  ) {
    return WIDGET_STYLES.valuesBlockBgYellow
  }

  return WIDGET_STYLES.valuesBlockBgRed
}

export const WIDGET_STYLES = {
  textLight: colors.WHITE,
  textDark: colors.BLACK,
  iconBlockBg: colors.BLUE_9,
  goalBlockBg: colors.GREEN_5,
  statusBg: colors.GREEN_5,
  monthBg: colors.GREEN_5,
  goalBlockBgMissed: colors.RED,
  valuesBlockBg: colors.TBA_LIGHTEST_GREY,

  valuesBlockBgGreen: colors.GREEN_5,
  valuesBlockBgYellow: colors.YELLOW,
  valuesBlockBgRed: colors.RED,
  valuesBlockDefault: colors.BLUE_9,

  // used in custom-kpi-trend only
  actualLine: colors.BLUE,
  goalLine: colors.GREEN,

  beatGoalArea: colors.TBA_LIGHT_GREEN,
  missedGoalArea: colors.RED_1
}

export const colorAndText = [
  {
    name: 'Actual',
    color: colors.BLUE
  },
  {
    name: 'Goal',
    color: colors.GREEN_5
  },
  {
    name: 'Beat Goal',
    color: colors.TBA_LIGHT_GREEN
  },
  {
    name: 'Missed Goal',
    color: colors.RED_1
  }
]

export const getTrendDataBasedOnTimePeriod = (
  trendData,
  selectedTimePeriod
) => {
  const currentMonth = moment().month()
  const currentYear = moment().year().toString()
  const last12MonthY = moment().subtract(12, 'months').format('YYYY')
  const last12MonthM = moment().subtract(12, 'months').format('MM')
  const trendChartData = trendData
  const chartData = {
    timestamp: [],
    data: {}
  }
  const timeStampData = []
  const cData = []
  switch (selectedTimePeriod) {
    case 'all-time-available':
      let lastAvailableIndex = 0
      let startAvailableIndex = 0
      const findLastAvailableYear = Object.keys(trendChartData)
      const lastAvaliableYear =
        findLastAvailableYear[findLastAvailableYear?.length - 1]
      const startAvailableYear = findLastAvailableYear[0]
      const lastAvailableYearData = trendChartData[lastAvaliableYear]
      const startAvailableYearData = trendChartData[startAvailableYear]

      // finding last index of having data in the array of object
      lastAvailableYearData?.map((da, index) => {
        if (da?.actual !== undefined || da?.goal !== undefined) {
          lastAvailableIndex = index
        }
      })
      // finding index for having data in the array of object
      for (const j in startAvailableYearData) {
        if (
          startAvailableYearData[j]?.actual !== undefined ||
          startAvailableYearData[j]?.goal !== undefined
        ) {
          startAvailableIndex = Number(j)
          break
        }
      }

      for (const i in trendChartData) {
        if (
          i === startAvailableYear &&
          startAvailableYear !== lastAvaliableYear
        ) {
          const key = startAvailableYear
          const trendData = trendChartData[startAvailableYear]
          trendData?.map((tData, index) => {
            if (startAvailableIndex <= index) {
              const ind = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`
              timeStampData.push(`${key}-${ind}-01T00:00:00`)
              cData.push([Number(tData?.actual), Number(tData?.goal)])
            }
          })
        }
        if (i !== lastAvaliableYear && i !== startAvailableYear) {
          const key = i
          const trendData = trendChartData[i]
          trendData?.map((tData, index) => {
            const ind = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`
            timeStampData.push(`${key}-${ind}-01T00:00:00`)
            cData.push([Number(tData?.actual), Number(tData?.goal)])
          })
        }
        if (
          i === lastAvaliableYear ||
          startAvailableYear === lastAvaliableYear
        ) {
          const key = lastAvaliableYear
          const trendData = trendChartData[lastAvaliableYear]
          trendData?.map((tData, index) => {
            if (lastAvailableIndex >= index) {
              const ind = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`
              timeStampData.push(`${key}-${ind}-01T00:00:00`)
              cData.push([Number(tData?.actual), Number(tData?.goal)])
            }
          })
        }
      }

      chartData['timestamp'] = timeStampData
      chartData['data'] = cData
      return chartData
    case 'last-12-months':
      let currentYearData = []
      let pastYearData = []
      let currentKey
      let pastKey
      if (trendChartData[last12MonthY] !== undefined) {
        pastKey = last12MonthY
        pastYearData = trendChartData[last12MonthY]
        pastYearData?.map((tData, index) => {
          if (Number(last12MonthM) <= index) {
            const ind = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`
            timeStampData.push(`${pastKey}-${ind}-01T00:00:00`)
            cData.push([Number(tData?.actual), Number(tData?.goal)])
          }
        })
      } else {
        pastKey = last12MonthY
        let start = Number(last12MonthM) + 1
        const end = 12
        while (start <= end) {
          const ind = start < 10 ? `0${start}` : `${start}`
          timeStampData.push(`${pastKey}-${ind}-01T00:00:00`)
          cData.push([null, null])
          start = start + 1
        }
      }

      if (trendChartData[currentYear] !== undefined) {
        currentKey = currentYear
        currentYearData = trendChartData[currentYear]
        currentYearData?.map((tData, index) => {
          if (index <= currentMonth) {
            const ind = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`
            timeStampData.push(`${currentKey}-${ind}-01T00:00:00`)
            cData.push([Number(tData?.actual), Number(tData?.goal)])
          }
        })
      }
      chartData['timestamp'] = timeStampData
      chartData['data'] = cData

      return chartData
    case 'year-to-date':
      let filterData = []
      const key = currentYear
      filterData = trendChartData[currentYear]
      filterData?.map((tData, index) => {
        if (index <= currentMonth) {
          const ind = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`
          timeStampData.push(`${key}-${ind}-01T00:00:00`)
          cData.push([Number(tData?.actual), Number(tData?.goal)])
        }
      })
      chartData['timestamp'] = timeStampData
      chartData['data'] = cData

      return chartData
    default:
      return []
  }
}

function getStartDateByPeriod(timePeriod, data) {
  if (timePeriod == ':stp/last-12-months') {
    return moment().subtract(11, 'month').startOf('month')
  } else if (timePeriod == ':stp/year-to-date') {
    return moment().startOf('year')
  } else if (timePeriod == ':stp/all-time-available') {
    return data.length && data[0].date
  }
}

function getEndDateByPeriod(timePeriod, data) {
  const endDate = data.length ? _last(data).date : null
  const currentMonth = moment().startOf('month').toDate()

  // for this period we can show values in future
  if (timePeriod == ':stp/all-time-available') {
    return currentMonth > endDate ? currentMonth : endDate
  } else {
    return currentMonth
  }
}

export const getValuesForTrend = (values, timePeriod) => {
  let data = []
  for (const key in values) {
    const serie = values[key]
      ?.map((value: any, index) => ({
        date: moment()
          .year(Number(key))
          .month(index + 1)
          .startOf('month')
          .toDate(),
        value
      }))
      .filter(({ value }) => notEmpty(value.actual) || notEmpty(value.goal))
    data.push(...serie)
  }
  const startDate = getStartDateByPeriod(timePeriod, data)
  const endDate = getEndDateByPeriod(timePeriod, data)

  data = data.filter(({ date }) => date >= startDate && date <= endDate)
  const lastDataWithBothActualAndGoal = getDataWithBothActualAndGoal(data)
  const actualData = data
    .map(({ value }) => ({ data: value.actual }))
    .filter(({ data }) => notEmpty(data))
  const goalData = data
    .map(({ value }) => ({ data: value.goal }))
    .filter(({ data }) => notEmpty(data))

  return {
    data: {
      actualData,
      goalData
    },
    lastDataWithBothActualAndGoal,
    startDate: startDate ? startDate.valueOf() : null,
    endDate: endDate ? endDate.valueOf() : null
  }
}

function getDataWithBothActualAndGoal(data) {
  const filteredData = data.filter(
    ({ value }) => notEmpty(value.actual) && notEmpty(value.goal)
  )
  return _last(filteredData) || {}
}

export const getStartAndEndDate = (type, requiredFormat) => {
  switch (type) {
    case 'past-30-days':
      return {
        startDate: moment()
          .subtract(30, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment().format(
          requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT
        )
      }
    case 'past-90-days':
      return {
        startDate: moment()
          .subtract(90, 'days')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment().format(
          requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT
        )
      }
    case 'past-12-months':
      return {
        startDate: moment()
          .subtract(12, 'months')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment().format(
          requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT
        )
      }
    case 'current-year':
      return {
        startDate: moment
          .utc()
          .startOf('year')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment
          .utc()
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'current-month':
      return {
        startDate: moment
          .utc()
          .startOf('month')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment
          .utc()
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    case 'current-quarter':
      return {
        startDate: moment
          .utc()
          .startOf('quarter')
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT),
        endDate: moment
          .utc()
          .format(requiredFormat ? requiredFormat : BACKEND_DATE_FORMAT)
      }
    default:
      return {
        startDate: moment().format(BACKEND_DATE_FORMAT),
        endDate: moment().format(BACKEND_DATE_FORMAT)
      }
  }
}
