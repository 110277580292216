// Shared styles for Radio & Checkbox and color resolver

import { css } from 'styled-components'

export const containerCss = css`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-size: ${(props) => (props.size ? props.size : '15px')};
  color: ${(props) => (props.disabled ? '#bbbbbb' : 'unset')};
`

export const inputRootCss = css`
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  justify-content: center;
  position: relative;
`

export const inputCss = css`
  background-color: transparent;
  cursor: inherit;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`

export const svgCss = css`
  font-size: inherit;
  width: 1em;
  height: 1em;
`

// default colors
const checkedColor = '#206fcf'
const disabledColor = '#e2e2e2'
const errorColor = '#991909'
const hoverColor = '#a5c2e7'
const uncheckedColor = '#949494'

export function colorResolver(fn) {
  return function ({ inputStateColors }) {
    const {
      checked = checkedColor,
      disabled = disabledColor,
      error = errorColor,
      hover = hoverColor,
      unchecked = uncheckedColor
    } = inputStateColors || {}
    return fn({ checked, disabled, error, hover, unchecked })
  }
}
