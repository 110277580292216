import { StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

export const styles = StyleSheet.create({
	info: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		marginTop: 20,
	},
	infoLine: {
		display: "flex",
		flexDirection: "row",
		color: colors.TBA_DARK_GREY,
	},
	infoTitle: {
		fontSize: 10
	},
	infoValue: {
		marginLeft: 10,
		fontSize: 10,
		fontWeight: "bold",
	},
	priorityStyle: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		marginRight: 12,
		paddingTop: 8,
		paddingBottom: 8,
		paddingHorizontal: 18,
		fontSize: 12,
		color: colors.TBA_DARK_GREY,
		border: `1 solid ${colors.TBA_DARK_GREY}`,
		borderBottom: `4 solid ${colors.TBA_DARK_GREY}`,
	},
	priorityStatus: {
		color: colors.BLACK,
		fontWeight: "bold",
	}
})
