import { OPTIONS } from '../../equipmentSetup/constants'

export const EquipmentTypeList = {
  'VAV Air System': {
    type: 'VAS',
    family: 'VAV Air System',
    child: {
      'Air Handling Unit': {
        type: 'AHU',
        family: 'Air Handler'
      },
      'Space Comfort Unit': {
        type: 'SCU',
        family: 'Space Comfort Unit'
      },
      'VAV Box': {
        type: 'VAV-BOX',
        family: 'Vav Box'
      },
    }
  },
  'Air Handling Unit': {
    type: 'AHU',
    family: 'Air Handler',
    parent: {
      'VAV Air System': {
        type: 'VAS',
        family: 'VAV Air System'
      }
    },
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Environment Meter': {
        type: 'EnvironmentMeter',
        family: 'Environment Meter'
      },
      'Liquid Flow Meter': {
        type: 'WaterFlow',
        family: 'Liquid Flow Meter'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    }
  },
  'Heating Plant': {
    type: 'HP',
    family: 'Heating Plant',
    child: {
      Boiler: {
        type: 'Boiler',
        family: 'Boiler'
      },
      Chiller: {
        type: 'Chiller',
        family: 'Chiller'
      },
      'Heat Exchanger': {
        type: 'HX',
        family: 'Heat Exchanger'
      },
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      }
    },
    parent: {
      'Heating System': {
        type: 'HS',
        family: 'Heating System'
      }
    }
  },
  Boiler: {
    type: 'Boiler',
    family: 'Boiler',
    parent: {
      'Heating Plant': {
        type: 'HP',
        family: 'Heating Plant'
      }
    },
    child: {
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      }
    }
  },
  'Chiller Plant': {
    type: 'CP',
    family: 'Chiller Plant',
    child: {
      Chiller: {
        type: 'Chiller',
        family: 'Chiller'
      },
      'Condenser Water Plant': {
        type: 'CDWP',
        family: 'Condenser Water Plant'
      },
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Environment Meter': {
        type: 'EnvironmentMeter',
        family: 'Environment Meter'
      },
      'Heat Exchanger': {
        type: 'HX',
        family: 'Heat Exchanger'
      },
      'Liquid Flow Meter': {
        type: 'WaterFlow',
        family: 'Liquid Flow Meter'
      },
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    },
    parent: {
      'Chilled Water System': {
        type: 'CWS',
        family: 'Chilled Water System'
      }
    }
  },
  Chiller: {
    type: 'Chiller',
    family: 'Chiller',
    parent: {
      'Chiller Plant': {
        type: 'CP',
        family: 'Chiller Plant'
      },
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      },
      'Heating Plant': {
        type: 'HP',
        family: 'Heating Plant'
      }
    },
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Environment Meter': {
        type: 'EnvironmentMeter',
        family: 'Environment Meter'
      },
      'Liquid Flow Meter': {
        type: 'WaterFlow',
        family: 'Liquid Flow Meter'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    }
  },
  'Pump Bank': {
    type: 'PumpBank',
    family: 'Pump Bank',
    child: {
      Chiller: {
        type: 'Chiller',
        family: 'Chiller'
      },
      'Distribution Loop': {
        type: 'DL',
        family: 'Distribution Loop'
      },
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Liquid Flow Meter': {
        type: 'WaterFlow',
        family: 'Liquid Flow Meter'
      },
      Pump: {
        type: 'Pump',
        family: 'Pump'
      },
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      }
    },
    parent: {
      'Chilled Water System': {
        type: 'CWS',
        family: 'Chilled Water System'
      },
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      },
      'Chiller Plant': {
        type: 'CP',
        family: 'Chiller Plant'
      },
      'Condenser Water Plant': {
        type: 'CDWP',
        family: 'Condenser Water Plant'
      },
      'Heat Exchanger': {
        type: 'HX',
        family: 'Heat Exchanger'
      },
      Boiler: {
        type: 'Boiler',
        family: 'Boiler'
      },
      'Heating System': {
        type: 'HS',
        family: 'Heating System'
      },
      'Heating Plant': {
        type: 'HP',
        family: 'Heating Plant'
      }
    }
  },
  'Chilled Water System': {
    type: 'CWS',
    family: 'Chilled Water System',
    child: {
      'Chiller Plant': {
        type: 'CP',
        family: 'Chiller Plant'
      },
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    }
  },
  'Condenser Water Plant': {
    type: 'CDWP',
    family: 'Condenser Water Plant',
    parent: {
      'Chiller Plant': {
        type: 'CP',
        family: 'Chiller Plant'
      }
    },
    child: {
      'Cooling Tower': {
        type: 'CT',
        family: 'Cooling Tower'
      },
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Environment Meter': {
        type: 'EnvironmentMeter',
        family: 'Environment Meter'
      },
      'Liquid Flow Meter': {
        type: 'WaterFlow',
        family: 'Liquid Flow Meter'
      },
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    }
  },
  'Cooling Tower': {
    type: 'CT',
    family: 'Cooling Tower',
    parent: {
      'Condenser Water Plant': {
        type: 'CDWP',
        family: 'Condenser Water Plant'
      }
    },
    child: {
      'Cooling Tower Cell': {
        type: 'CTC',
        family: 'Cooling Tower Cell'
      },
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    }
  },
  'Cooling Tower Cell': {
    type: 'CTC',
    family: 'Cooling Tower Cell',
    parent: {
      'Cooling Tower': {
        type: 'CT',
        family: 'Cooling Tower'
      }
    },
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      Fan: {
        type: 'FAN',
        family: 'Fan'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    }
  },
  'Distribution Loop': {
    type: 'DL',
    family: 'Distribution Loop',
    parent: {
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      }
    },
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Liquid Flow Meter': {
        type: 'WaterFlow',
        family: 'Liquid Flow Meter'
      },
      'Load Valve': {
        type: 'LoadValve',
        family: 'Load Valve Object'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    }
  },
  'Electric Meter': {
    type: 'ElectricMeter',
    family: 'Electric Meter',
    parent: {
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      },
      'VAV Box': {
        type: 'VAV-BOX',
        family: 'Vav Box'
      },
      Chiller: {
        type: 'Chiller',
        family: 'Chiller'
      },
      'Air Handling Unit': {
        type: 'AHU',
        family: 'Air Handler'
      },
      'Chilled Water System': {
        type: 'CWS',
        family: 'Chilled Water System'
      },
      'Condenser Water Plant': {
        type: 'CDWP',
        family: 'Condenser Water Plant'
      },
      'Chiller Plant': {
        type: 'CP',
        family: 'Chiller Plant'
      },
      'Variable Frequency Drive': {
        type: 'VFD',
        family: 'Variable Frequency Drive'
      },
      Fan: {
        type: 'FAN',
        family: 'Fan'
      },
      Pump: {
        type: 'Pump',
        family: 'Pump'
      },
      'Distribution Loop': {
        type: 'DL',
        family: 'Distribution Loop'
      },
      'Cooling Tower Cell': {
        type: 'CTC',
        family: 'Cooling Tower Cell'
      },
      'Cooling Tower': {
        type: 'CT',
        family: 'Cooling Tower'
      },
      'Space Comfort Unit': {
        type: 'SCU',
        family: 'Space Comfort Unit'
      },
      'Analog Point': {
        type: 'Points',
        family: 'Analog Point'
      },
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      }
    },
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      }
    }
  },
  'VAV Box': {
    type: 'VAV-BOX',
    family: 'Vav Box',
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      }
    },
    parent: {
      'VAV Air System': {
        type: 'VAS',
        family: 'VAV Air System'
      }
    }
  },
  'Variable Frequency Drive': {
    type: 'VFD',
    family: 'Variable Frequency Drive',
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      }
    },
    parent: {
      Fan: {
        type: 'FAN',
        family: 'Fan'
      },
      Pump: {
        type: 'Pump',
        family: 'Pump'
      }
    }
  },
  Fan: {
    type: 'FAN',
    family: 'Fan',
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Variable Frequency Drive': {
        type: 'VFD',
        family: 'Variable Frequency Drive'
      }
    },
    parent: {
      'Cooling Tower Cell': {
        type: 'CTC',
        family: 'Cooling Tower Cell'
      }
    }
  },
  Pump: {
    type: 'Pump',
    family: 'Pump',
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Liquid Flow Meter': {
        type: 'WaterFlow',
        family: 'Liquid Flow Meter'
      },
      'Variable Frequency Drive': {
        type: 'VFD',
        family: 'Variable Frequency Drive'
      }
    },
    parent: {
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      }
    }
  },
  'Space Comfort Unit': {
    type: 'SCU',
    family: 'Space Comfort Unit',
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Environment Meter': {
        type: 'EnvironmentMeter',
        family: 'Environment Meter'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    },
    parent: {
      'VAV Air System': {
        type: 'VAS',
        family: 'VAV Air System'
      }
    }
  },
  'Analog Point': {
    type: 'Points',
    family: 'Analog Point',
    child: {
      'Electric Meter': {
        type: 'ElectricMeter',
        family: 'Electric Meter'
      },
      'Environment Meter': {
        type: 'EnvironmentMeter',
        family: 'Environment Meter'
      },
      'Gas Meter': {
        type: 'GAS',
        family: 'Gas Meter'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    }
  },
  'Binary Point': {
    type: 'BinaryPoints',
    family: 'Binary Point',
  },
  'Virtual Meter': {
    type: 'VirtualMeter',
    family: 'Virtual Meter',
  },
  'Ice Bank': {
    type: 'IB',
    family: 'Ice Bank',
  },
  'MultiState Point': {
    type: 'MultiStatePoints',
    family: 'MultiState Point',
  },
  'Environment Meter': {
    type: 'EnvironmentMeter',
    family: 'Environment Meter',
    parent: {
      'Air Handling Unit': {
        type: 'AHU',
        family: 'Air Handler'
      },
      Chiller: {
        type: 'Chiller',
        family: 'Chiller'
      },
      'Condenser Water Plant': {
        type: 'CDWP',
        family: 'Condenser Water Plant'
      },
      'Chiller Plant': {
        type: 'CP',
        family: 'Chiller Plant'
      },
      'Space Comfort Unit': {
        type: 'SCU',
        family: 'Space Comfort Unit'
      },
      'Analog Point': {
        type: 'Points',
        family: 'Analog Point'
      }
    }
  },
  'Gas Meter': {
    type: 'GAS',
    family: 'Gas Meter',
    parent: {
      'Analog Point': {
        type: 'Points',
        family: 'Analog Point'
      },
      'Gas Meter': {
        type: 'GAS',
        family: 'Gas Meter'
      }
    },
    child: {
      'Gas Meter': {
        type: 'GAS',
        family: 'Gas Meter'
      }
    }
  },
  'Heat Exchanger': {
    type: 'HX',
    family: 'Heat Exchanger',
    parent: {
      'Chiller Plant': {
        type: 'CP',
        family: 'Chiller Plant'
      },
      'Heating Plant': {
        type: 'HP',
        family: 'Heating Plant'
      }
    },
    child: {
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      }
    }
  },
  'Heating System': {
    type: 'HS',
    family: 'Heating System',
    child: {
      'Heating Plant': {
        type: 'HP',
        family: 'Heating Plant'
      },
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      }
    }
  },
  'Liquid Flow Meter': {
    type: 'WaterFlow',
    family: 'Liquid Flow Meter',
    parent: {
      'Pump Bank': {
        type: 'PumpBank',
        family: 'Pump Bank'
      },
      'Distribution Loop': {
        type: 'DL',
        family: 'Distribution Loop'
      },
      Pump: {
        type: 'Pump',
        family: 'Pump'
      },
      Chiller: {
        type: 'Chiller',
        family: 'Chiller'
      },
      'Liquid Flow Meter': {
        type: 'WaterFlow',
        family: 'Liquid Flow Meter'
      },
      'Air Handling Unit': {
        type: 'AHU',
        family: 'Air Handler'
      },
      'Condenser Water Plant': {
        type: 'CDWP',
        family: 'Condenser Water Plant'
      },
      'Chiller Plant': {
        type: 'CP',
        family: 'Chiller Plant'
      }
    },
    child: {
      'Liquid Flow Meter': {
        type: 'WaterFlow',
        family: 'Liquid Flow Meter'
      }
    }
  },
  'Load Valve': {
    type: 'LoadValve',
    family: 'Load Valve Object',
    parent: {
      'Distribution Loop': {
        type: 'DL',
        family: 'Distribution Loop'
      }
    },
    child: {
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    }
  },
  'Thermal Energy Meter': {
    type: 'EnergyMeter',
    family: 'Energy Meter',
    parent: {
      Chiller: {
        type: 'Chiller',
        family: 'Chiller'
      },
      'Air Handling Unit': {
        type: 'AHU',
        family: 'Air Handler'
      },
      'Load Valve': {
        type: 'LoadValve',
        family: 'Load Valve Object'
      },
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      },
      'Analog Point': {
        type: 'Points',
        family: 'Analog Point'
      },
      'Space Comfort Unit': {
        type: 'SCU',
        family: 'Space Comfort Unit'
      },
      'Cooling Tower': {
        type: 'CT',
        family: 'Cooling Tower'
      },
      'Cooling Tower Cell': {
        type: 'CTC',
        family: 'Cooling Tower Cell'
      },
      'Distribution Loop': {
        type: 'DL',
        family: 'Distribution Loop'
      },
      'Chiller Plant': {
        type: 'CP',
        family: 'Chiller Plant'
      },
      'Condenser Water Plant': {
        type: 'CDWP',
        family: 'Condenser Water Plant'
      },
      'Chilled Water System': {
        type: 'CWS',
        family: 'Chilled Water System'
      }
    },
    child: {
      'Thermal Energy Meter': {
        type: 'EnergyMeter',
        family: 'Energy Meter'
      }
    }
  }
}

export const SORT_TYPE = { ASC: 'asc', DESC: 'desc' }
export const SITE_OBJECT = 'Site Object'
export const TIS_OBJECT = 'Tis Object'

export const CONDENSER_TYPES = ['Air Cooled', 'Water Cooled']
export const COMPRESSOR_TYPES = ['Centrifugal', 'Helical Rotary', 'Scroll']
export const SOURCE_TYPES = [
  { key: SITE_OBJECT, value: SITE_OBJECT },
  { key: TIS_OBJECT, value: 'TIS Object' }
]

export const CHILLER_TYPES_BY_COMPRESSOR_TYPE = {
  Centrifugal: ['CVH', 'CVG', 'CVR', 'CDH'],
  'Helical Rotary': ['RTA', 'RTH', 'RTW', 'RTX', 'RTU'],
  Scroll: ['CGA', 'CGW', 'CCA', 'CXA', 'CCU', 'RAU']
}

function mapItemsToKeyValueObjects(items) {
  return items.map((item) => ({ key: item, value: item }))
}

export const getOptionsForDropDowns = () => {
  const condenserTypes = mapItemsToKeyValueObjects(CONDENSER_TYPES)
  const compressorTypes = mapItemsToKeyValueObjects(COMPRESSOR_TYPES)

  return { condenserTypes, compressorTypes }
}
export const PROPERTY_VALUE = {
  NAME: 'name',
  DATA_SOURCE_TYPE: 'dataSourceType',
  DATA_SOURCE_NAME: 'dataSourceName',
  NAME: 'name'
}
export const SORT_BY = {
  VALUE: 'value',
  LABEL: 'label'
}
export const LoadValveAppendOptions = [
  {
    value: 'End of Name',
    key: OPTIONS.APPEND
  },
  {
    key: OPTIONS.PREPEND,
    value: 'Start of Name'
  }
]
export const EMPTY = ''
export const getCompressorName = (compressor) => {
  let chillerSpecificFields = {
      propertyType: 'Characteristic',
      minValue: '0',
      maxValue: '5000',
      priority: '1',
      targetDataGroup: "Binary",
      targetDataType: "Varchar",
      targetMeasureType: "0",
      targetMeasureUnit: "noUnit",
      startDateTime: new Date(),
  }
  switch (compressor.name) {
    case 'compressor1ANominalCapacity':
      return {
        paramPresentName:'CompressorCapacityNominal1A',
        paramValueName:'CompressorPresent1A',
        targetKey: 'CompressorPresent1A',
        tisDisplayName: 'Comp 1A Present',
        value: `${compressor.value}`,
        ...chillerSpecificFields,
      }
    case 'compressor1BNominalCapacity':
      return {
        paramPresentName:'CompressorCapacityNominal1B',
        paramValueName:'CompressorPresent1B',
        targetKey: 'CompressorPresent1B',
        tisDisplayName: 'Comp 1B Present',
        value: `${compressor.value}`,
        ...chillerSpecificFields
      }
    case 'compressor1CNominalCapacity':
      return {
        paramPresentName:'CompressorCapacityNominal1C',
        paramValueName:'CompressorPresent1C',
        targetKey: 'CompressorPresent1C',
        tisDisplayName: 'Comp 1C Present',
        value: `${compressor.value}`,
        ...chillerSpecificFields
      }
    case 'compressor2ANominalCapacity':
      return {
        paramPresentName:'CompressorCapacityNominal2A',
        paramValueName:'CompressorPresent2A',
        targetKey: 'CompressorPresent2A',
        tisDisplayName: 'Comp 2A Present',
        value: `${compressor.value}`,
        ...chillerSpecificFields
      }
    case 'compressor2BNominalCapacity':
      return {
        paramPresentName:'CompressorCapacityNominal2B',
        paramValueName:'CompressorPresent2B',
        targetKey: 'CompressorPresent2B',
        tisDisplayName: 'Comp 2B Present',
        value: `${compressor.value}`,
        ...chillerSpecificFields
      }
    case 'compressor2CNominalCapacity':
      return {
        paramPresentName:'CompressorCapacityNominal2C',
        paramValueName:'CompressorPresent2C',
        targetKey: 'CompressorPresent2C',
        tisDisplayName: 'Comp 2C Present',
        value: `${compressor.value}`,
        ...chillerSpecificFields
      }
  }
}
export const getCompressorCount = (compressor) => {
 return {
      propertyType: 'Characteristic',
      minValue: '0',
      maxValue: '5000',
      priority: '1',
      targetDataGroup: "Analog",
      targetDataType: "Varchar",
      targetMeasureType: "0",
      targetMeasureUnit: "noUnit",
      targetKey: 'CompressorCount',
      tisDisplayName: 'Compressor Count',
      startDateTime: new Date(),
      value: `${compressor}`
  }
}

export const getCondenserName = (name) => {
  return name === 'Air Cooled' ? 'Air_Cooled' : 'Water_Cooled'
}

export const getCompressor = (name) => {
  switch(name) {
    case 'Centrifugal': 
      return 'Centrifugal'
    case 'Helical Rotary': 
      return 'Helical_Rotary'
    case 'Scroll':
      return 'Scroll'
  }
}

