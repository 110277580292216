// import { Fragment } from 'react/jsx-runtime'
import { Fragment } from 'react'
import {
  selectAxes,
  selectGeneratedScales,
  selectSeriesData,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
import Path from 'src/chart-library/CommonComponents/LinePath/LinePath'
import { generatorFunction } from 'src/chart-library/Shapes/picker'
import { v4 as uuid4 } from 'uuid'

const AreaDualRenderer = (props: any) => {
  const { types = [] } = props
  const axes = useSelector(selectAxes)
  const generatedScales = useSelector(selectGeneratedScales)
  const seriesData = useSelector(selectSeriesData)

  return (
    <>
      {types?.map((obj: any, i: number) => {
        const xObject = (axes || [])?.find(
          (axesObj: any) => axesObj?.key === obj?.xAxisKey
        )
        const yObject = (axes || [])?.find(
          (axesObj: any) => axesObj?.key === obj?.yAxisKey
        )
        const gXScaleObj = generatedScales?.[obj?.xAxisKey]
        const gYScaleObj = generatedScales?.[obj?.yAxisKey]
        const yData = seriesData?.[obj?.seriesKey]?.data
        const xscaleType = xObject?.scale?.props?.type
        const yscaleType = yObject?.scale?.props?.type
        const xScale = gXScaleObj?.gScale
        const yScale = gYScaleObj?.gScale
        const xData = xObject?.scale?.categories
        const d = generatorFunction(obj?.type, {
          xscaleType,
          yscaleType,
          xData,
          yData,
          xScale,
          yScale,
          invert: false,
          settings: obj?.settings,
          properties: obj?.properties,
          type: obj?.type,
          min: gYScaleObj?.domain?.[0],
          gYScaleObj,
          series: obj
        })
        const clipPathD = generatorFunction(obj?.type, {
          xscaleType,
          yscaleType,
          xData,
          yData,
          xScale,
          yScale,
          invert: false,
          settings: obj?.settings,
          type: obj?.type,
          properties: obj?.properties,
          min: gYScaleObj?.domain?.[0],
          gYScaleObj,
          series: obj,
          clipPath: true
        })
        const uniqueID = uuid4()
        const name = (obj?.name || '').replaceAll(/\s/g, '')
        const areaID = name + '-' + uniqueID
        return (
          <Fragment key={i}>
            <clipPath id={`${areaID}`}>
              <path d={clipPathD} />
            </clipPath>
            <Path
              dashType={obj?.dashType}
              {...obj?.elementProps}
              d={d}
              clipPath={`url(#${areaID})`}
            />
          </Fragment>
        )
      })}
    </>
  )
}

export default AreaDualRenderer
