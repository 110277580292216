import  TisObject  from "../../../../../components/legacy/common/TisObject";
import * as yup from 'yup'
import i18next from 'i18next'
export const TIS_OBJECT_TYPES = "9,13"

import { format } from "d3-format"
import moment from 'moment'
import { formatDataWithIndex,sortByDates,formatData  } from 'src/common/chartHelperFunctions'
import _isEmpty from 'lodash/isEmpty'

export const RELOAD_DATA_INTERVAL = 60 * 60 * 1000

export const KEY_METRIC_OPTIONS = [
	{ key: "totalEnergyConsumption", value: "Total Energy Consumption", iconClass: "icon-energy-roi" },
	{ key: "totalEnergyCost", value: "Total Energy Cost", iconClass: "icon-energy-roi" },
	{ key: "totalEnergyPercentChange", value: "Total Energy % Change", iconClass: "icon-energy-roi" },
	{ key: "totalElectricConsumption", value: "Total Electric Consumption", iconClass: "icon-electric" },
	{ key: "totalElectricCost", value: "Total Electric Cost", iconClass: "icon-electric" },
	{ key: "totalElectricPercentChange", value: "Total Electric % Change", iconClass: "icon-electric" },
	{ key: "totalGasConsumption", value: "Total Gas Consumption", iconClass: "icon-gas" },
	{ key: "totalGasCost", value: "Total Gas Cost", iconClass: "icon-gas" },
	{ key: "totalGasPercentChange", value: "Total Gas % Change", iconClass: "icon-gas" },
	{ key: "peakElectricDemand", value: "Peak Electric Demand", iconClass: "icon-electric" },
	{ key: "peakElectricDemandPercentChange", value: "Peak Electric Demand % Change", iconClass: "icon-electric" },
]

export const KEY_METRIC_UOMS = {
	totalEnergyConsumption: ["kiloWattHour", "megaWattHour", "btu", "therm"],
	totalEnergyCost: ["usd"],
	totalEnergyPercentChange: ["percent"],
	totalElectricConsumption: ["kiloWattHour", "megaWattHour", "btu", "therm"],
	totalElectricCost: ["usd"],
	totalElectricPercentChange: ["percent"],
	totalGasConsumption: ["btu", "kiloBtu", "megaBtu", "therm"],
	totalGasCost: ["usd"],
	totalGasPercentChange: ["percent"],
	peakElectricDemand: ["kiloWatt"],
	peakElectricDemandPercentChange: ["percent"],
}

export const UOMS = [
	{ key: "kiloWatt", value: "kW" },
	{ key: "kiloWattHour", value: "kWh" },
	{ key: "megaWattHour", value: "MWh" },
	{ key: "btu", value: "BTU" },
	{ key: "kiloBtu", value: "kBTU" },
	{ key: "megaBtu", value: "MBTU" },
	{ key: "therm", value: "Therm" },
	{ key: "usd", value: "$" },
	{ key: "percent", value: "%" },
]

export const TIME_PERIODS = [
	":stp/current-month",
	":stp/current-quarter",
	":stp/current-year"
]

export const DEFAULT_TIME_PERIOD = ":stp/current-month"

export const COMPARED_TO = {
	":stp/current-month": [
		":none",
		":stp/comparison-month",
		":stp/current-month-previous-year"
	],
	":stp/current-quarter": [
		":none",
		":stp/previous-quarter-to-date",
		":stp/current-quarter-previous-year"
	],
	":stp/current-year": [
		":none",
		":stp/comparison-year"
	]
}

export const DEFAULT_COMPARED_TO = ":none"

export const NO_COST_ESTIMATE = "NO_COST_ESTIMATE"
export const NO_ELECTRIC_COST_ESTIMATE = "NO_ELECTRIC_COST_ESTIMATE"
export const NO_GAS_COST_ESTIMATE = "NO_GAS_COST_ESTIMATE"
export const NO_DATA_AVAILABLE = "NO_DATA_AVAILABLE"
export const SERVER_ERROR = "SERVER_ERROR"

export const ENERGY_METRIC_BUILDER_CONFIGS = [
	{
		keyMetric: "totalEnergyConsumption",
		uom: "kiloWattHour",
		isEnabled: false,
	},
	{
		keyMetric: "totalEnergyCost",
		uom: "usd",
		isEnabled: false,
	},
	{
		keyMetric: "totalEnergyPercentChange",
		uom: "percent",
		isEnabled: false,
	},
	{
		keyMetric: "totalGasConsumption",
		uom: "btu",
		isEnabled: false,
	},
	{
		keyMetric: "peakElectricDemand",
		uom: "kiloWatt",
		isEnabled: false,
	},
]

export function getKeyMetricTitle(keyMetric) {
	return (KEY_METRIC_OPTIONS.find(o => o.key === keyMetric) || { value: keyMetric }).value
}

export function getKpiClass(keyMetric) {
	return (KEY_METRIC_OPTIONS.find(o => o.key === keyMetric) || KEY_METRIC_OPTIONS[0]).iconClass
}

export function getUomSymbol(uomKey) {
	return (UOMS.find(o => o.key === uomKey) || { value: uomKey }).value
}

export function getAvailableUoms(keyMetric) {
	return KEY_METRIC_UOMS[keyMetric] || []
}

export function getUomOptions(keyMetric) {
	const availableUoms = getAvailableUoms(keyMetric)

	return UOMS.filter(o => !availableUoms.length || availableUoms.includes(o.key))
}

export const MONEY_UOM = {
	name: "usd",
	displayName: "Usd",
	symbol: "$"
}

export function cubicFeetConversionIndex({ name }) {
	const { feetCubedToBtu, kwhToBtu } = TisObject.RATIOS
	const { KWH, MWH, BTU, KBTU, MBTU, THERM } = TisObject.UOM

	switch (name) {
		case BTU.name:
			return feetCubedToBtu
		case KBTU.name:
			return feetCubedToBtu / 1000
		case THERM.name:
			return feetCubedToBtu / 100000
		case MBTU.name:
			return feetCubedToBtu / 1000000
		case KWH.name:
			return feetCubedToBtu / kwhToBtu
		case MWH.name:
			return feetCubedToBtu / kwhToBtu / 1000
		default:
			return 1
	}
}
export function valueResolution(value, resolution = "1") {
	const exp = Math.pow(10, -Math.round(Math.log10(Number(resolution))))
	const splits = resolution.split(".")
	const precision = splits.length == 2 ? splits[1].length : 0
	return format(`,${`.${precision}f`}`)(Math.round(value * exp) / exp)
}

export const getEnergyDataAPIParams = (startDate, endDate, buildingId, equipmentId, properties,uomDetails, isCostRequest = false) => {
	const equipmentType = 'VirtualMeter'
	const chartDataRequestFormat = {
		startDate: startDate,
		endDate: endDate,
		category: 'equipment', // equipment or building level data needed
		chartType: 'line',
		building: {
			id: buildingId,
			pId: [],
			type: ''
		},
		equipment: !isCostRequest
			? [
				{
					eId: [{ id: equipmentId }],
					type: equipmentType,
					pId: properties
				}
			]
			: [],
		...(isCostRequest
			? {
				consumption: {
					eId: equipmentId,
					pId: properties
				}
			}
			: {}),
		equipmentType: equipmentType,
		weather: {},
		settings: [],
		buildingDateTime: '2024-01-08 23:26:43'
	}
	if(!isCostRequest)
		chartDataRequestFormat.equipment[0]["uom"] = uomDetails
	else
		chartDataRequestFormat.consumption["uom"] = uomDetails
	return { body: JSON.stringify(chartDataRequestFormat) }
}

export const getFormatDataWithIndex = (data,propertyName,isEnergyCost = true) =>{
	const parsedChartData = JSON.parse(data)?.body || {}
	const dataWithProperIndex = formatDataWithIndex(
		parsedChartData[`${propertyName}`] , isEnergyCost
	)
	return dataWithProperIndex
}

export const findMaxValueObject = data => {
    if(data.length === 0) return null;

    let maxValueObject = data[0];
    let maxValue = +maxValueObject.value;
    for(let i=1; i<data.length; i++){
        const currentValue = +(data[i].value);
        if(currentValue > maxValue){
            maxValue = currentValue
            maxValueObject = data[i]
        }
    }
    return maxValueObject;
}

const getPeakDemand = (chartData) => {
    let maxPeakObject = {
      timeStamp: moment(new Date()).format('YYYY-MM-DD HH:mm'),
      value: null
    }

    if (chartData?.length > 0) {
      const filterData = chartData?.filter((property) => {
        if (['InstantaneousPower'].includes(property?.propertyKey)) {
          return property
        }
      })
      if (filterData.length) {
        maxPeakObject = findMaxValueObject(filterData[0]?.values[0])
      }
    }
    return maxPeakObject
  }

export const getFormat15MinsData = (data,startDt,endDt) => {
	const consumptionDataCurrent = formatData(
        data || [],
        startDt,
        endDt,
        moment.tz.guess(),
        '15m',
		null,
		true
      )
	  return consumptionDataCurrent
}

export const formatMonthlyDataForEnergyConsumption = (data) => {
	try {
		for (const equipmentId in data) {
			const electricalConsumption = {
				equipmentId: equipmentId,
				propertyKey: 'TotalElectricConsumption',
				propertyName: 'TotalElectricConsumption',
				values: [],
				isDataAvailable: false
			}

			const gasConsumption = {
				equipmentId: equipmentId,
				propertyKey: 'TotalGasConsumption',
				propertyName: 'TotalGasConsumption',
				values: [],
				isDataAvailable: false
			}

			const equipmentData = data[equipmentId]
			const equipmentProperties = equipmentData?.properties
			const electricalConsumptionIndex =
				equipmentProperties['TotalElectricConsumption']?.index?.index
			const gasConsumptionIndex =
				equipmentProperties['TotalGasConsumption']?.index?.index

			// Checking Whether we have calculation Or Not
			const isNoElectricalCosumptionEstimate = _isEmpty(equipmentProperties['TotalElectricConsumption']?.index) 
			const isNoGasCosumptionEstimate = _isEmpty(equipmentProperties['TotalGasConsumption']?.index)
			for (const year in equipmentData?.values) {
				const values = equipmentData?.values[year]
					const electricalConsumptionMonthlyValue =
						values[electricalConsumptionIndex] || 0
					const gasConsumptionMonthlyValue =
						values[gasConsumptionIndex] || 0
					const timeStamp = moment(year).format("MM-DD-YYYY")

					// push values to array
					electricalConsumption.values.push({
						timeStamp,
						value: electricalConsumptionMonthlyValue
					})
					gasConsumption.values.push({
						timeStamp,
						value: gasConsumptionMonthlyValue
					})
			}
			electricalConsumption.values = sortByDates(electricalConsumption)
			gasConsumption.values = sortByDates(gasConsumption)

			const isDataAvailable = electricalConsumption?.values?.some((x) => x?.value !== 0) || gasConsumption?.values?.some((x) => x?.value !== 0)

			return ({
				electricalConsumption,
				gasConsumption,
				isDataAvailable,
				electricalConsumptionError: { isNoEstimate: isNoElectricalCosumptionEstimate, isNoDataAvailable: electricalConsumption?.values?.length === 0 },
				gasConsumptionError: { isNoEstimate: isNoGasCosumptionEstimate, isNoDataAvailable: gasConsumption?.values?.length === 0 },
			})
		}
	} catch (error) {
		console.log(error)
	}
}

export const formatMonthlyDataForTotalEnergyConsumption = (data) => {
	try {
			const equipmentValuesData = Object.values(data?.values ?? [])
			// Checking Whether we have calculation Or Not
			const isNoTotalConsumptionEstimate = _isEmpty(data?.properties['TotalConsumption']?.index) 
			const totalConsumption = equipmentValuesData?.reduce((accum, curr) => accum + Number(curr?.reduce((a, c) => a + Number(c),0)),0)
			const isDataAvailable = equipmentValuesData?.length > 0
            return ({
				totalConsumption,
				isDataAvailable,
				totalConsumptionError: { isNoEstimate: isNoTotalConsumptionEstimate, isNoDataAvailable: equipmentValuesData?.length === 0 }
			})
	} catch (error) {
		console.log(error)
	}
}
export const formatMonthlyDataForPeakDemand = (data) => {
	try {
		const equipmentValuesData = data?.length > 0 ? data[0].values : []
		// Checking Whether we have calculation Or Not
		const isNoTotalConsumptionEstimate = _isEmpty(data?.find(f => f?.propertyKey === "InstantaneousPower")?.propertyName) 
		const peakDemand = getPeakDemand(data)
		const isDataAvailable = equipmentValuesData?.length > 0
		return ({
			peakDemand : peakDemand?.value,
			isDataAvailable,
			peakDemandError: { isNoEstimate: isNoTotalConsumptionEstimate, isNoDataAvailable: equipmentValuesData?.length === 0 }
		})
} catch (error) {
	console.log(error)
}
}

export const validationSchemaKeyMetricConfigurator = () =>
  yup.object().shape({
    organization: yup
      .string()
      .required(i18next.t('errors:organizationIsRequired')),
    building: yup.string().required(i18next.t('errors:buildingIsRequired'))
  })

  export const frameInputForKeyMetricUpdate = (values, dashboardId) => {
	const keyMetric1 = values.energyMetricBuilderConfigs[0]
	const keyMetric2 = values.energyMetricBuilderConfigs[1]
	const keyMetric3 = values.energyMetricBuilderConfigs[2]
	const keyMetric4 = values.energyMetricBuilderConfigs[3]
	const keyMetric5 = values.energyMetricBuilderConfigs[4]
	const input = {
	  id: dashboardId,
	  isKeyMetric: values.isKeyMetric,
	  keyMetricaccountId: values.organization,
	  keyMetricbuildingId: values.building,
	  keyMetricComparedTo: values.comparedTo,
	  keyMetricTimePeriod: values.timePeriod,
	  keyMetric1Display: keyMetric1.isEnabled,
	  keyMetric1Name: keyMetric1.keyMetric,
	  keyMetric1UoM: keyMetric1.uom,
	  keyMetric2Display: keyMetric2.isEnabled,
	  keyMetric2Name: keyMetric2.keyMetric,
	  keyMetric2UoM: keyMetric2.uom, keyMetric3Display: keyMetric3.isEnabled,
	  keyMetric3Name: keyMetric3.keyMetric,
	  keyMetric3UoM: keyMetric3.uom, keyMetric4Display: keyMetric4.isEnabled,
	  keyMetric4Name: keyMetric4.keyMetric,
	  keyMetric4UoM: keyMetric4.uom,
	  keyMetric5Display: keyMetric5.isEnabled,
	  keyMetric5Name: keyMetric5.keyMetric,
	  keyMetric5UoM: keyMetric5.uom
	}
	return input
  }
  


