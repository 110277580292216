import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import SimpleItem from '../cost-savings/simple-item/simple-item'
import {
  formatSavingsView,
  SAVINGS_LIST,
  formatPrice,
  PRICE_SAVINGS_LIST,
  COST_SAVINGS_LIST,
  costOfWaitingValue,
  convertTime
} from 'src/components/legacy/common/opportunity.js'
import { formatCurrency } from 'src/components/legacy/common/currency'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import Select from 'src/components/legacy/components/select/select'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import {
  round,
  updateNestedValue,
  getField,
  isDateFormatCorrect,
  convertStringToValidNumber,
  isNumberValue
} from 'src/components/legacy/common/helpers.js'
import PaybackROIItem from '../cost-savings/payback-roi-item/index'
import moment from 'moment'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers.js'
import { Container, Content } from 'src/components/layouts'

import { CostSavingsEditWrapper } from '../../styles'
import translate, { TranslateComponent } from 'src/common/translations'

const TOTAL = 'total'
const YEAR = 'year'

export function CostSavingsEdit({
  costSavings,
  onChange,
  t,
  errors,
  containerWidth
}) {
  const update = (value, field) => {
    const editedCostSavings = updateNestedValue(costSavings, field, value)

    //  if some changes were done
    // update roiCalculation and paybackCalculation fields
    if (!field.includes('roi') && !field.includes('payback')) {
      const modifiedCostSavings = {
        ...editedCostSavings,
        ...calculateSavings(editedCostSavings)
      }
      onChange(modifiedCostSavings)
    } else {
      onChange(editedCostSavings)
    }
  }

  const calculateSavings = (editedCostSavings) => {
    const roi = calculateROI(
      editedCostSavings.costToImplement.value,
      editedCostSavings.savings,
      editedCostSavings.lifecycle
    )
    const payback = calculatePayback(
      editedCostSavings.costToImplement.value,
      editedCostSavings.savings,
      editedCostSavings.timeToUse
    )

    const modifiedCostSavings = {
      roiCalculation: {
        value: roi,
        enabled: editedCostSavings.roiCalculation.enabled
      },
      paybackCalculation: {
        value: payback,
        enabled: editedCostSavings.paybackCalculation.enabled
      }
    }
    return { ...modifiedCostSavings }
  }

  const calculateROI = (costToImplement, savings, timeToUse) => {
    if (costToImplement && savings.value !== undefined) {
      const totalSavings =
        savings.tag == TOTAL
          ? savings.value
          : getTotalSavings(savings, timeToUse)
      if (totalSavings !== undefined) {
        return Math.ceil((totalSavings / costToImplement) * 100)
      }
    }
  }

  const calculatePayback = (costToImplement, savings, timeToUse) => {
    if (savings.value && costToImplement !== undefined) {
      const annualSavings =
        savings.tag == YEAR
          ? savings.value
          : getAnnualSavings(savings, timeToUse)
      if (annualSavings) {
        return costToImplement / annualSavings
      }
    }
  }

  const getTotalSavings = (savings, timeToUse) => {
    // calculate total savings with 'Time to Use' adjusted to the 'Savings' time period
    if (timeToUse.value !== undefined) {
      return (
        savings.value *
        timeToUse.value *
        convertTime(`${timeToUse.tag}-${savings.tag}`)
      )
    }
  }

  const getAnnualSavings = (savings, timeToUse) => {
    // convert entered savings amount to annual savings
    if (savings.tag == TOTAL) {
      return timeToUse.value
        ? savings.value /
            (timeToUse.value * convertTime(`${timeToUse.tag}-years`))
        : undefined
    } else {
      // need to hardcode "years", because timeSelector was added
      return savings.value * convertTime(`years-${savings.tag}`)
    }
  }

  const {
    savings,
    costToImplement,
    paybackCalculation,
    timeToUse,
    roiCalculation,
    lifecycle,
    costOfWaiting
  } = costSavings
  const savingsOptionsList = SAVINGS_LIST.map((key) => ({
    key,
    description: formatSavingsView(key, t)
  }))
  const priceOptionsList = PRICE_SAVINGS_LIST.map((key) => ({
    key,
    description: formatPrice(key, t)
  }))
  const costOfWaitingVal =
    isDateFormatCorrect(costOfWaiting.startDate, DATE_FORMAT) &&
    costOfWaitingValue(costSavings)

  return (
    <CostSavingsEditWrapper>
      <Container
        className="cost-savings-edit"
        xlColumns={4}
        lColumns={2}
        mColumns={2}
        sColumns={1}
        width={containerWidth}
      >
        <Content border="none" width={containerWidth}>
          <SimpleItem
            title={translate("Energy Savings")}
            isActive={Boolean(savings.enabled && savings.value)}
            {...(savings.enabled &&
              savings.value && {
                value: formatCurrency(savings.value),
                period: formatSavingsView(savings.tag, t)
              })}
          >
            <div className="custom-content">
              <Checkbox
                label={`${translate('Show Energy Savings')}`}
                isChecked={savings.enabled}
                id="savings"
                toggleCheck={() => update(!savings.enabled, 'savings.enabled')}
              />
              <div
                className={`custom-input ${
                  getField(errors, 'savings') ? 'invalid' : ''
                } `}
              >
                <label htmlFor="savings">
                  {<TranslateComponent>Estimated Savings</TranslateComponent>}
                </label>
                <input
                  value={convertStringToValidNumber(savings.value)}
                  name="savings"
                  step="any"
                  onChange={({target}) => {
                    const value = target.value
                    if (isNumberValue(value)) {
                      update(Number(value), 'savings.value')
                    }
                  }
                  }
                />
                {getField(errors, 'savings') && (
                  <p className="error">{errors.savings}</p>
                )}
              </div>
              <Select
                options={savingsOptionsList}
                onChange={(value) => update(value, 'savings.tag')}
                selectedItem={formatSavingsView(savings.tag, t)}
              />
            </div>
          </SimpleItem>
        </Content>
        <Content border="none" width={containerWidth}>
          <SimpleItem
            title={translate('Price')}
            isActive={Boolean(costToImplement.enabled && costToImplement.value)}
            {...(costToImplement.enabled &&
              costToImplement.value && {
                value: formatCurrency(costToImplement.value),
                period: formatPrice(costToImplement.priceType, t)
              })}
          >
            <div className="custom-content">
              <Checkbox
                label={`${translate('Show Customer Price')}`}
                isChecked={costToImplement.enabled}
                id="price"
                toggleCheck={() =>
                  update(!costToImplement.enabled, 'costToImplement.enabled')
                }
              />
              <div
                className={`custom-input ${
                  getField(errors, 'costToImplement') ? 'invalid' : ''
                } `}
              >
                <input
                  value={convertStringToValidNumber(costToImplement.value)}
                  name="price"
                  step="any"
                  onChange={({ target }) => {
                    const value = target.value
                    if (isNumberValue(value)) {
                      update(Number(value), 'costToImplement.value')
                    }
                  }}
                />
                {getField(errors, 'costToImplement') && (
                  <p className="error">{errors.costToImplement}</p>
                )}
              </div>
              <Select
                options={priceOptionsList}
                onChange={(value) => update(value, 'costToImplement.priceType')}
                selectedItem={formatPrice(costToImplement.priceType, t)}
              />
            </div>
          </SimpleItem>
        </Content>
        <Content border="none" width={containerWidth}>
          <PaybackROIItem
            paybackCalculation={paybackCalculation}
            costToImplement={costToImplement}
            roiCalculation={roiCalculation}
            costSavings={costSavings}
            timeToUse={timeToUse}
            lifecycle={lifecycle}
            update={update}
            errors={errors}
          />
        </Content>
        <Content border="none" width={containerWidth}>
          <SimpleItem
            title={
              costOfWaiting.value && costOfWaitingVal
                ? t('opportunities:IfImplementedOn', {
                    date: costOfWaitingVal.date
                  })
                : <TranslateComponent>Potential Savings By Now</TranslateComponent>
            }
            isActive={Boolean(costOfWaiting.value && costOfWaitingVal)}
            {...(Boolean(costOfWaiting.value && costOfWaitingVal) && {
              value: formatCurrency(round(costOfWaitingVal.value, 0)),
              period: t('opportunities:PotentialSavingsByNow')
            })}
          >
            <div className="custom-content">
              <Checkbox
                label={`${translate('Show Cost of Waiting')}`}
                isChecked={Boolean(costOfWaiting.value)}
                id="cost-of-waiting"
                toggleCheck={() =>
                  update(!costOfWaiting.value, 'costOfWaiting.value')
                }
              />
              <div className="custom-input">
                <ModalDatePickerInput
                  labelText={translate('Starting from')}
                  date={costOfWaiting.startDate ? costOfWaiting.startDate : ''}
                  onChange={(date) =>
                    update(
                      date.target
                        ? date.target.value
                        : moment(date).format(DATE_FORMAT),
                      'costOfWaiting.startDate'
                    )
                  }
                  isRequired={Boolean(costOfWaiting.value)}
                  hasError={Boolean(
                    getField(errors, 'costOfWaiting.startDate')
                  )}
                  errorMessage={
                    getField(errors, 'costOfWaiting.startDate') || ''
                  }
                />
              </div>
            </div>
          </SimpleItem>
        </Content>
      </Container>
    </CostSavingsEditWrapper>
  )
}

CostSavingsEdit.propTypes = {
  costSavings: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  errors: PropTypes.object,
  containerWidth: PropTypes.number
}

CostSavingsEdit.defaultProps = {
  costSavings: COST_SAVINGS_LIST,
  errors: null
}

export default withTranslation()(CostSavingsEdit)
