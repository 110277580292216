import { CheckBoxWrapper, WidgetCreateWrapper } from '../styles'
import ComponentSelectForWidget from './ComponentSelectForWidget'
import Modal from 'src/components/legacy/components/modal/modal'
import { useEffect, useState } from 'react'
import { useWidgetsContext } from '../WidgetsContextProvider'
import { WidgetNames } from '../helpers'
import { ACTIONS } from 'src/constants'
import translate, { TranslateComponent } from 'src/common/translations'
import { LabeledCheckbox } from 'src/components/inputs/checkbox'
import { useSelector } from 'react-redux'
import {  selectDashboards } from 'src/redux/slicers/dashboard'
import { CREATE_DASHBOARD_WIDGET } from '../.././dashboards/graphql'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useMutation } from 'src/hooks/APIHooks'
import { setDashboardWidgetAddedState } from 'src/redux/slicers/dashboard'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"


const WidgetCreate = ({handleModalClose}:any) => {
  const [widgetFormik, setWidgetFormik] = useState(null)
  const {
    selectedWidget,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setWidgetDetails,
    setWidgetBuildingDetails,
    setIsLoading,
    isLoading,
    setIsLocationLoading,
    setFormikErrors,
    formikErrors,
    setIsShowColorPicker,
    setObjectPickerSelectedValue,
    mode,
    setIsSubmitted,
    isSubmitted,
    setIsFormValidationAlone,
    isFormValidationAlone,
    setSelectedBaseLine,
    isBuildingChange,
    setWidgetId,
    isFooterChecked,
    setIsFooterChecked,
    widgetId
  } = useWidgetsContext()
  const submitForm = () => {
    setIsFormValidationAlone(false)
    setIsSubmitted(false)
    setTimeout(() => {      
      setIsSubmitted(true)
    }, 30)
  }

  const params = useParams();
  const dashboardID = params?.id;
  const dispatch = useDispatch()

  const { onSubmit: createDashboardWidget, loading } = useMutation({
    query: CREATE_DASHBOARD_WIDGET,
    dataPath: 'data.createDashboardWidget'
  })

  useEffect(() => {
    if(widgetId && isFooterChecked && dashboardID && ACTIONS.ADD === mode){
      handleAddWidget(widgetId)
    }else{
      setWidgetId(null)
      window.scrollTo(0, 0)
    }
  },[widgetId])
 

  const handleAddWidget = async (widgetID) => {
    setIsOpenCreateWidget(false);
    try {
      const response = await createDashboardWidget({ input: { dashboardID: dashboardID, widgetID } });
      dispatch(setDashboardWidgetAddedState({ loading: true }));
      trackEvent(USER_EVENTS.DASHBOARDS.events.ADD_WIDGET, { "widget id": widgetID });
      setWidgetId(null);
     
      if (handleModalClose && response) {
        handleModalClose();
      }
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
    }
  };

  const { loading: loadingDashboards, data: dashboards } =
    useSelector(selectDashboards)
    
    const filterDashboard = dashboards.find(item=>item?.id === params?.id)
  
  const optionsTranslated = {
    image: "Image/Text Link",
    weather: 'Weather',
    customKPI: 'Custom KPI',
    trendLine: 'Trends Line Chart',
    trendBar: 'Trends Bar Chart',
    timeComp: 'Time Comparison Bar Chart',
    dataComp: 'Data Comparison Bar Chart',
    dataMonitorGauge: 'Data Monitoring Gauge',
    dataMonitorOrb: 'Data Monitoring Orb',
    baseline: 'Baseline Comparison',
    energyCon: 'Energy Consumption',
    energyCost: "Energy Cost",
    energyDem: 'Energy Demand',
    create: translate('CREATE'),
    save: translate('Save'),
    cancel: translate('CANCEL'),
  }

  useEffect(() => {
    if (isSubmitted || isFormValidationAlone) {
      widgetFormik?.handleSubmit()
    }
  }, [isSubmitted, isFormValidationAlone])
  
  const getHeadingName = () => {
    switch (selectedWidget?.type) {
      case WidgetNames.ImageText:
        return optionsTranslated.image
      case WidgetNames.Weather:
        return optionsTranslated.weather
      case WidgetNames.CustomKPI:
        return optionsTranslated.customKPI
      case WidgetNames.TrendLineChart:
        return optionsTranslated.trendLine
      case WidgetNames.TrendBarChart:
        return optionsTranslated.trendBar
      case WidgetNames.TimeComparisonBarChart:
        return optionsTranslated.timeComp
      case WidgetNames.DataComparisonBarChart:
        return optionsTranslated.dataComp
      case WidgetNames.Gauge:
          return optionsTranslated.dataMonitorGauge
      case WidgetNames.Orb:
        return optionsTranslated.dataMonitorOrb
      case WidgetNames.BaseLineComparison:
        return optionsTranslated.baseline
      case WidgetNames.EnergyConsumption:
        return optionsTranslated.energyCon
      case WidgetNames.EnergyCost:
        return optionsTranslated.energyCost
      case WidgetNames.EnergyDemand:
        return optionsTranslated.energyDem
    }
  }
  const getHeadingIcon = () => {
    switch (selectedWidget?.type) {
      case WidgetNames.ImageText:
        return "text-image-link"
      case WidgetNames.Weather:
        return "weatherwidget"
      case WidgetNames.CustomKPI:
        return "orbwidget"
      case WidgetNames.TrendLineChart:
        return "linewidget"
      case WidgetNames.TrendBarChart:
        return "bar3widget"
      case WidgetNames.TimeComparisonBarChart:
        return "bar1widget"
      case WidgetNames.DataComparisonBarChart:
        return "bar2widget"
      case WidgetNames.Gauge:
          return "dialwidget"
      case WidgetNames.Orb:
        return "orbwidget"
      case WidgetNames.BaseLineComparison:
        return "enegy-consumption"
      case WidgetNames.EnergyConsumption:
        return "bar3widget"
      case WidgetNames.EnergyCost:
        return "bar3widget"
      case WidgetNames.EnergyDemand:
        return "linewidget"
    }
  }
  const handleCreateWidgetClose = () => {
    setSelectedWidget(null)
    setIsOpenCreateWidget(false)
    setWidgetDetails(null)
    setWidgetBuildingDetails(null)
    setIsLoading(false)
    setIsLocationLoading(false)
    setFormikErrors({})
    setIsShowColorPicker(false)
    setObjectPickerSelectedValue([])
    setIsSubmitted(false)
    setIsFormValidationAlone(false)
    setSelectedBaseLine(null)
  }

  const renderButton = (button, index) => {
    return (
      <button
        key={`${button.text}_${index}`}
        className={`modal-button type-${button.type || 'confirm'}`}
        onClick={(e) => {
          button.handleClick(e);
        }}
        disabled={button.disabled}
        type={button.buttonType || 'button'}
        data-testid={`${button.testName}_${button.text}_button`}
      >
        {button.text}
      </button>
    );
  };
  
   useEffect(() => {
    setIsFooterChecked(!!dashboardID);
  }, [dashboardID]);

  const handleChange = () => {
    setIsFooterChecked(!isFooterChecked);
  };
 
  const buttonArr = [
    {
      text: [ACTIONS.ADD, ACTIONS.COPY].includes(mode) ? optionsTranslated.create : optionsTranslated.save,
      handleClick: () => submitForm(),
      type: 'save',
      disabled: Object.keys(formikErrors)?.length > 0 || isBuildingChange,
    },
    {
      text: optionsTranslated.cancel,
      handleClick: handleCreateWidgetClose,
      type: 'cancel',
    },
  ];
  const add = translate("Add to")
  const dashboard = translate("dashboard")
  const openCreateWidgetModalConfig = {
    gray: true,
    className: 'wide-modal',
    isHideWhiteBackground: true,
    heading: `${getHeadingName()} ${'Widget Builder'}`, //this shall be widget.type/label
    headingIcon: getHeadingIcon(),
    handleClose: handleCreateWidgetClose,
    formIsErrored: Object.keys(formikErrors)?.length > 0,
    footer: (
      <>
        {dashboardID && ACTIONS.ADD === mode  ? (
          <CheckBoxWrapper>
          <div className='checkbox-group'>
            <LabeledCheckbox
              onChange={handleChange}
              checked={isFooterChecked }
              className=""
              label={`${add} ${filterDashboard?.name} ${dashboard}`}
            />
            </div>
          </CheckBoxWrapper>
        ) : null}
        {buttonArr.map((button, index) => renderButton(button, index))}
      </>
    ) 
  };
  

  return (
    <Modal {...openCreateWidgetModalConfig}>
      <WidgetCreateWrapper>
        <ComponentSelectForWidget setWidgetFormik={setWidgetFormik} />
      </WidgetCreateWrapper>
    </Modal>
  )
}

export default WidgetCreate
