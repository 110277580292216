import styles from "./building-selector.module.scss"
import { Chip, ChipColor } from "../Table/Chip";
import { Chips } from "../Table/Chips";
import { TranslateComponent } from "src/common/translations";
import { BuildingIcon } from "./BuildingIcon";

export const SelectedBuildingDisplay = ({organization, building=null, sidebarIsExpanded}) => {
  const totalBuildings = organization?.buildings?.length;
  const buildingLabel = totalBuildings === 1 ? `building` : `buildings`;
  const orgSummary = `${totalBuildings}/${totalBuildings} ${buildingLabel}`;
  const buildingSummary = `1/${totalBuildings} ${buildingLabel}`;

  return (
    <>
      {sidebarIsExpanded &&
        <div className={styles.buildingDisplay}>
          {building &&
            <>
              <div className={styles.buildingChips} data-testid={`selectedBuilding-${building?.id}`}>
                <Chips size="small">
                  <Chip color={ChipColor.darkGreen} size="small">
                    {building?.secondary}
                  </Chip>
                </Chips>
              </div>
              <div className={styles.inputSelectionSubTitle}>
                <TranslateComponent>{buildingSummary}</TranslateComponent>
              </div>
            </>
          }
          {!building &&
            <>
              <BuildingChips buildings={organization?.buildings} maxCount={4} />
              <div className={styles.inputSelectionSubTitle}>
                <TranslateComponent>{orgSummary}</TranslateComponent>
              </div>
            </>
          }
        </div>
      }
      {
        !sidebarIsExpanded && building &&
        <BuildingIcon name={building?.primary} /> 
      }
    </>
  )
}

const BuildingChips = ({buildings, maxCount}) => {
  if(!buildings) return;
  const buildingsSubset = buildings.slice(0, maxCount);
  const remainder = buildings.length > maxCount
    ? buildings.length - maxCount
    : 0;

  return (
    <div>
      <Chips size="small">
        {
          buildingsSubset.map(building =>
            <Chip color={ChipColor.darkGreen} key={building.id} size="small">
              { building?.secondary || building.primary }
            </Chip>
          )
        }
        {remainder > 0 &&
          <Chip color={ChipColor.darkGreen} size="small">
            +{ remainder }
          </Chip>
        }
      </Chips>
    </div>
  )
}