import React from "react"
import styled from "styled-components"
import colors from 'src/components/layouts/colors.json'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  background: ${props => (props.checked && !props.disableChange ? `${props.checkedColor ?? colors.TBA_GREEN}` : props.checked && props.disableChange ? `${colors.TBA_MEDIUM_GREY}` : `${colors.TBA_LIGHTEST_GREY}`)};
  border-radius: 3px;
	transition: all 150ms;
	border:1px solid ${colors.TBA_DARK_GREY};
	border-radius: 2px;
	cursor:pointer;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px green;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")}
  }
`


const Checkbox = ({ disableChange, className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} id={props.id} />
    <StyledCheckbox checked={checked} {...props} disableChange={disableChange}>
      <Icon viewBox="0 0 24 24" disableChange={disableChange} {...props}>
      <polyline points="20 6 9 17 4 12" />
    </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default Checkbox
