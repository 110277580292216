export const INVALID = 'invalid'
export const VALID = 'valid'
export const PENDING = 'pending'
export const CHECKING = 'checking'

export function required(errorMessage) {
  return function (value) {
    return {
      errorMessage,
      isValid:
        value !== null &&
        value !== undefined &&
        Boolean(value.toString().trim())
    }
  }
}

export function emailValidation(email) {
  // eslint is disabled here because it's complaining about unnecessary escape
  // characters, which is probably true, but i don't want to touch this regex
  // -Dan
  let regex =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i // eslint-disable-line
  const isValid = regex.test(email) || email === '' || !email
  const errorMessage = translate('The email address is not valid.')
  return function () {
    return {
      errorMessage,
      isValid
    }
  }
}

export function createField(value, isEnabled, ...validators) {
  if (!isEnabled) {
    return {
      status: PENDING
    }
  }

  const result = {
    status: VALID
  }

  for (let i = 0; i < validators.length; i++) {
    const { errorMessage, isValid } = validators[i](value)

    if (!isValid) {
      return {
        status: INVALID,
        error: errorMessage
      }
    }
  }

  return result
}

export function createFieldset(config) {
  const hasInvalidChild = Object.keys(config).some(
    (name) => config[name].status === INVALID
  )
  const hasCheckingChild = Object.keys(config).some(
    (name) => config[name].status === CHECKING
  )
  const hasPendingChild = Object.keys(config).some(
    (name) => config[name].status === PENDING
  )

  let status

  if (hasInvalidChild) {
    status = INVALID
  } else if (hasCheckingChild) {
    status = CHECKING
  } else if (hasPendingChild) {
    status = PENDING
  } else {
    status = VALID
  }

  return Object.assign({ status }, config)
}

const RESERVED_KEYS = ['status', 'error']
const LEAF = Symbol('LEAF')

export function getAllFieldNames(form) {
  const additionalKeys = Object.keys(form).filter(
    (key) => !RESERVED_KEYS.includes(key)
  )
  const isFieldset = additionalKeys.length > 0

  if (isFieldset) {
    return additionalKeys.reduce((result, key) => {
      const childResult = getAllFieldNames(form[key])
      return result.concat(childResult === LEAF ? key : childResult)
    }, [])
  } else {
    return LEAF
  }
}

export function listValidation(errorMessage) {
  return function (noOfSelections) {
    return {
      errorMessage,
      isValid: noOfSelections > 0
    }
  }
}

export function maxLengthValidation(field, maxLength, errorMessage) {
  const isValid = field.length <= maxLength

  return function () {
    return {
      errorMessage,
      isValid
    }
  }
}

export function numberValidation(field, min, max, errorMessage) {
  const isValid =
    (max !== undefined ? field <= max : true) &&
    (min !== undefined ? field >= min : true)

  return function () {
    return {
      errorMessage,
      isValid
    }
  }
}

export function phoneNumberValidation(phoneNumber) {
  const regex = /^\+?[1-9]\d{10,14}$/
  const isValid = regex.test(phoneNumber) || phoneNumber === '' || !phoneNumber
  const errorMessage = translate('Phone number is not valid.')
  return function () {
    return {
      errorMessage,
      isValid
    }
  }
}
