import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef
} from 'react'
import PropTypes from 'prop-types'
import {
  PageWrapper,
  PageNotification,
  NotificationHeader,
  HeaderTitle,
  CloseEvent,
  NotificationText,
  StyledIcon,
  StyledDiv
} from './styles'

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

const Snackbar = forwardRef(function Snackbar(props, ref) {
  const { autoHideDuration = 2000, position = 'top' } = props
  const [toggleText, setToggleText] = useState({})
  const [toastList, setToastList] = useState([])
  let interval = null
  const notificationRef = useRef()

  const iconName = {
    info: 'Info',
    success: 'Success',
    error: 'Error',
    warning: 'Warning'
  }
  const iconColor = {
    info: '#206fcf',
    success: '#1f7d19',
    error: '#991909',
    warning: '#be5104'
  }

  useImperativeHandle(ref, () => ({
    showToast: (toastData) => {
      const id = getRandomInt(100000)
      const newToast = { ...toastData, id }

      if (newToast && !newToast.disableAutoHide) {
        interval = setTimeout(() => {
          setToastList((prevState) => {
            const toastListItem = prevState.filter((toast) => toast.id !== id)
            return toastListItem
          })

          setToggleText((prevState) => {
            delete prevState[id]
            return { ...prevState }
          })
        }, autoHideDuration)
      }
      setToastList([...toastList, newToast])
    }
  }))

  const deleteToast = (id) => {
    setToastList((prevState) => {
      const toastListItem = prevState.filter((toast) => toast.id !== id)
      return toastListItem
    })
    setToggleText((prevState) => {
      delete prevState[id]
      return { ...prevState }
    })
  }

  useEffect(() => {
    return () => {
      clearTimeout(interval)
    }
  }, [interval])

  return (
    <PageWrapper vertical={position} ref={notificationRef}>
      {toastList.map((toast, i) => {
        const toastType = toast?.type || ''
        return (
          <PageNotification
            key={i}
            type={toastType}
            animation={toast?.animation || 'slide'}
          >
            {toast.customAlert ? (
              <>
                <CloseEvent
                  name="Close"
                  color="black"
                  height="18px"
                  width="18px"
                  onClick={() => deleteToast(toast.id)}
                />
                {toast.customAlert}
              </>
            ) : (
              <>
                <NotificationHeader>
                  <StyledDiv>
                    <StyledIcon
                      type={toastType}
                      name={iconName[toastType] ? iconName[toastType] : ''}
                      height="20px"
                      width="20px"
                      color={iconColor[toastType] ? iconColor[toastType] : ''}
                      padding="3px 6px 0 0"
                    />
                    <HeaderTitle type={toastType}>{toast.title}</HeaderTitle>
                  </StyledDiv>
                  <CloseEvent
                    name="Close"
                    color="black"
                    height="18px"
                    width="18px"
                    onClick={() => deleteToast(toast.id)}
                  />
                </NotificationHeader>
                <NotificationText
                  slide={toggleText?.[toast.id]}
                  onClick={() => {
                    setToggleText((prevState) => ({
                      ...prevState,
                      [toast.id]: !toggleText?.[toast.id]
                    }))
                  }}
                >
                  {toast.message}
                </NotificationText>
              </>
            )}
          </PageNotification>
        )
      })}
    </PageWrapper>
  )
})

Snackbar.propTypes = {
  /**
   * The showToast used for the toast available in page.
   */
  showToast: PropTypes.any,
  /**
   * Position of the component.
   */
  position: PropTypes.oneOf(['top', 'bottom']),

  /**
   * Animation of the component.
   */
  animation: PropTypes.oneOf(['slide', 'slide-right', 'slide-bottom'])
}
export default Snackbar
