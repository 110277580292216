import { Container, Content } from 'src/components/layouts'
import IconSvg from 'src/components/legacy/components/icon-svg/icon'
import colors from 'src/components/legacy/common/colors.json'
import { KPIImpact } from './KPIImpact'
import { AssumptionValuesFormContainer, ToolBarContainer } from "./style"
import Tooltip from 'src/denali-ui/components/Tooltip'
import _set from "lodash/set"
import { formatCurrency } from 'src/components/legacy/common/currency'

export const ValueAssumptionsView = ({valueAssumptions, width, addAssumptionsToConsultation, setShowAssumtions }) => {
    const { kpi = [], goalAmount = {}, avoidedTruckRollAmount = {}} = valueAssumptions

    // const width = window.innerWidth

    const customField = (name, value, timePeriod = "") => (
        <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12} width={width} maxWidth={`widthpx`} className="custom-field">
            <Content xlColumn={6} lColumn={6} mColumn={6} sColumn={12} width={width} className="title-value field" border={"none"}>
                <IconSvg
                    name={"truck-rolls-impact"}
                    color={colors.ORANGE_6}
                    hover={colors.ORANGE_6}
                    width="30px"
                    height="30px"
                    viewBox="0 0 65 65"
                    className="kpi-icons"
                    cursor="auto"
                />
                <span>{name}</span>
            </Content>
            <Content xlColumn={6} lColumn={6} mColumn={6} sColumn={12} width={width} border={"none"} className="select-impact select">
                {`${formatCurrency(value)} ${timePeriod}`}
            </Content>
        </Container>
    )

    const renderToolBar = () => {
       // const { userName, cancelValueAssumption } = {}
        return <ToolBarContainer>
            <div className="toolbar-buttons">
                <div>
                    <Tooltip horizontalPosition="center" content={<span>Save Assumption</span>}>
                        <span className="icon icon-infocircle" />
                    </Tooltip>
                    <button
                        className="primary"
                        onClick={addAssumptionsToConsultation}
                    >
                        APPLY TO CONSULTATION
                    </button>
                </div>
                <button
                    className="secondary va-cancel-btn"
                    type="button"
                    onClick={()=>{setShowAssumtions(false)}}
                >
                    CANCEL
                </button>
            </div>
        </ToolBarContainer>
    }

    return (
        <AssumptionValuesFormContainer >
            <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12} width={width}  className="value-assumption-edit">
                <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} border={"none"} width={width}>
                        <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12} width={width}>
                            <Content sColumns={12} xlColumn={7} lColumn={8} mColumn={12} sColumn={12} width={width}>
                            {customField(
                            "Goal",
                            goalAmount,
                            "Per Year",
                        )}
                            </Content>
                            <Content sColumns={12} xlColumn={7} lColumn={8} mColumn={12} sColumn={12} width={width}>
                            {customField(
                            "AvoidedTruckRolls",
                            avoidedTruckRollAmount,
                        )}
                            </Content>
                            <Content className="title-impacts" xlColumn={12} lColumn={12} mColumn={12} sColumn={12} width={width} border={"none"}>
                            <p>Low</p>
                            <p>Medium</p>
                            <p>High</p>
                    </Content>
                    {kpi.map((k, i) => <Content key={i} xlColumn={12} lColumn={12} mColumn={12} sColumn={12} width={width} border={"none"}>
                        <KPIImpact kpiImpact={k} key={i} mode="view"/>
                    </Content>)}
                        </Container>
                    <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} width={width} border={"none"}>
                        {renderToolBar()}
                    </Content>
                </Content>
            </Container>
        </AssumptionValuesFormContainer> )
}


export default ValueAssumptionsView