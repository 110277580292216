import styled from 'styled-components'
import COLORS from 'src/components/legacy/common/colors.json'

// TODO move color codes to variables once this UX is good to go live.
export const BuildingNavigationBody = styled.div`
  && {
    background-color: #ffffff;
    border: 1px solid #999999;
    border-radius: 0;
  }
`

export const BuildingNavigationBodyItem = styled.div`
  ${(props) => props.allowDisabledLocations && 'max-height: 440px;'}
  && .item {
    &.even {
      background-color: #f4f4f4;
    }
    &:not(.inactive) {
      &:active,
      &.selected {
        color: ${COLORS.WHITE};
      }
      &:hover:active {
        color: ${COLORS.WHITE};
      }
      &:hover,
      &:focus {
        background-color: #e6efff;
        color: ${COLORS.BLACK};
      }
      &:active {
        background-color: #6c97bf;
      }
      &.selected {
        background-color: #6c97bf;
      }
    }
  }
`

export const RecentSearchBuildingNavigationBodyItem = styled(
  BuildingNavigationBodyItem
)`
  && {
    border-bottom: 1px solid #999999;
  }
`

export const Label = styled.div`
  span.hover-tooltip {
    opacity: 0;
  }
  :hover span.hover-tooltip {
    opacity: 1;
    transition: opacity ease-in;
    transition-delay: 1.5s;
  }
  .tooltip-container {
    position: absolute;
    min-width: 200px;
    min-height: 30px;
    z-index: 1000;
  }
`

export const RecentlyViewedLabel = styled.span`
  font-weight: bold;
  padding: 4px 0 4px 15px;
  display: inline-block;
`
