import { useWidgetsContext } from 'src/pages/widgets/WidgetsContextProvider'
import { useQuery } from 'src/hooks/APIHooks'
import {
  Title,
  WidgetCardStyles,
  ParaName,
  ListItem,
  WidgetCardContentStyle,
  WidgetCardStylesContainer
} from './style'
import WidgetsPreview from './WidgetsPreview'
import WidgetResizeMenu from 'src/components/widgetResizeMenu/index.jsx'
import Hamburger from '../Hamburger'
import { GET_WIDGET, GET_IMAGE_WIDGET } from 'src/pages/widgets/graphql.ts'
import { WidgetNames } from 'src/pages/widgets/helpers'
import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setPageReduxObject } from 'src/redux/slicers/buildingPicker'
import { WIDGET_TYPES } from 'src/constants'
import { GET_BUILDING_METER_TOTAL_EQUIPMENT } from 'src/common/queries/datasource'
import _isEmpty from 'lodash/isEmpty'
import { TranslateComponent } from '../../../../common/translations'

const REFRESH_DATA_TIME = 15 * 60 * 1000 // 15-minutes interval

export const WidgetCard = ({
  widgetDetails,
  selectedDimen,
  handleResize,
  handleUpdate,
  height,
  width,
  menuItems = []
}) => {
  const interval = useRef()

  const dispatch = useDispatch()

  const [error, setError] = useState(false)

  const { widgetId, setWidgetId, selectedBuildingChart } =
    useWidgetsContext() || {}
  const [widgetDetailState, setWidgetDetailsState] = useState(
    widgetDetails || {}
  )

  const { refetch: getBuildingTotalMeterEquipment } = useQuery({
    query: GET_BUILDING_METER_TOTAL_EQUIPMENT,
    dataPath: 'data.getBuildingTotalMeterEquipment',
    disableInitialLoad: true
  })

  const { refetch: refetchGetWidget } = useQuery({
    query:
      widgetDetailState?.widget?.widgetType === WidgetNames.ImageText
        ? GET_IMAGE_WIDGET
        : GET_WIDGET,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getWidget',
    onSuccess: async (widgetData) => {
      try {
        if (widgetData) {
          if (
            (widgetData.widgetType === WidgetNames.EnergyConsumption ||
              widgetData.widgetType === WidgetNames.EnergyCost || widgetData.widgetType === WidgetNames.EnergyDemand) &&
             _isEmpty(widgetData?.widgeEquipment1Id) &&
             !_isEmpty(widgetData?.buildingId)
          ) {
            // if the equipment id is null, means widget created for Building meter total
            const totalMeter = await getBuildingTotalMeterEquipment({
              body: JSON.stringify({ bId: widgetData?.buildingId })
            })
            const res = await JSON.parse(totalMeter)
            const totalMeterList = res?.body?.equipments || []
            if (totalMeterList?.length > 0) {
              const meterDetails = totalMeterList?.[0]
              widgetData.widgeEquipment1Name = meterDetails?.name
              widgetData.widgeEquipment1Id = meterDetails.id
              widgetData.widgeEquipment1Type = 'VirtualMeter'
            }
            setWidgetDetailsState({ ...widgetDetailState, widget: widgetData })
          } else {
            setWidgetDetailsState({ ...widgetDetailState, widget: widgetData })
          }
        }
      } catch {}
    },
    onError: (error) => {
      setError(error)
    }
  })

  const startRefreshDataInterval = () => {
    interval.current = setInterval(() => {
      const widgetData = { ...widgetDetailState?.widget }
      setWidgetDetailsState({ ...widgetDetailState, widget: widgetData })
    }, REFRESH_DATA_TIME)
  }

  useEffect(() => {
    if (widgetId && widgetId === widgetDetailState?.widget?.id) {
      if (
        widgetDetailState?.widget?.widgetType === WIDGET_TYPES.MULTIPLE_LINES
      ) {
        setWidgetDetailsState({ ...widgetDetails })
      }
    }
  }, [widgetDetails])

  useEffect(() => {
    if (widgetId && widgetId === widgetDetailState?.widget?.id) {
      if (
        widgetDetailState?.widget?.widgetType === WIDGET_TYPES.MULTIPLE_LINES
      ) {
        const widgetData = { ...widgetDetailState?.widget }
        setWidgetDetailsState({ ...widgetDetailState, widget: widgetData })
      } else {
        refetchGetWidget({ widgetId: widgetDetailState?.widget?.id })
      }
      setWidgetId(null)
    }
  }, [widgetId])

  useEffect(() => {
    if (
      selectedBuildingChart &&
      selectedBuildingChart?.id === widgetDetailState?.id
    ) {
      const widgetData = { ...selectedBuildingChart?.widget }
      setWidgetDetailsState({ ...widgetDetailState, widget: widgetData })
    }
  }, [selectedBuildingChart])

  useEffect(() => {
    try {
      // Fetch Widget Details
      refetchGetWidget({ widgetId: widgetDetailState?.widget?.id })
      setWidgetId(null)
      // Starts refresh timers
      startRefreshDataInterval()
      // Clears the set timer when ummount
      return () => {
        clearInterval(interval?.current)
        interval.current = null
      }
    } catch (error) {}
  }, [])

  // we need this only for Energy consumption and Energy cost
  // Only bar charts which has Tooltip
  let comparedTo = ':none'

  if (
    widgetDetailState?.widget?.widgetType === WidgetNames?.EnergyConsumption
  ) {
    comparedTo =
      widgetDetailState?.widget?.energyConsumptionWidget?.comparedTo?.split(
        '/'
      )?.[1] || ':none'
  }
  if (widgetDetailState?.widget?.widgetType === WidgetNames?.EnergyCost) {
    comparedTo =
      widgetDetailState?.widget?.energyCostWidget?.comparedTo?.split(
        '/'
      )?.[1] || ':none'
  }

  const getPreviewComponent = () => {
    return (
      <WidgetCardContentStyle
        widgetType={widgetDetailState?.widget?.widgetType}
      >
        <WidgetsPreview
          dimension={{
            height,
            width
          }}
          widget={widgetDetailState?.widget}
        />
      </WidgetCardContentStyle>
    )
  }

  const getWidgetLink = (link, accountId, buildingId, equipmentId) => {
    const commonParams = `organization=${accountId}&location=${buildingId}&equipment=${equipmentId}`
    switch (link) {
      case 'ElectricalDemandChart':
        return `/electrical-demand?${commonParams}`
      case 'EnergyConsumptionChart':
        return `/energy-consumption?${commonParams}`
      case 'EnergyCostChart':
        return `/energy-cost-summary?${commonParams}`
      default:
        return link
    }
  }

  const setChartIdToRedux = (chartId) => {
    dispatch(
      setPageReduxObject({
        type: 'Success',
        chartId
      })
    )
  }

  const getWidgetContentWithLink = () => {
    const {
      widget: {
        widgetLink,
        isNewWindow,
        widgetLinkType,
        widgetType,
        customUrl,
        accountId,
        widgeEquipment1Id,
        chartId = '',
        buildingId
      }
    } = widgetDetailState

    const isEnergyWidget = [
      WidgetNames.EnergyConsumption,
      WidgetNames.EnergyCost,
      WidgetNames.EnergyDemand
    ].includes(widgetType)

    if (widgetType === WidgetNames?.BuildingData) {
      return getPreviewComponent()

      // TODO : [REMOVE] backup purpose
      // for building data chart
      /*   return (
        <div className="has-link">
          <Link
            onClick={() => setChartIdToRedux(chartId)}
            to={`/building-data?organization=${accountId}&location=${buildingId}`}
          >
            {getPreviewComponent()}
          </Link>
        </div>
      ) */
    } else if (widgetType === WidgetNames?.BaseLineComparison) {
      // baseline widgets comes here
      if(isNewWindow) {
        return (
          <div className="has-link">
            <Link
              to={`/baseline-library?organization=${accountId}&location=${buildingId}`}
            >
              {getPreviewComponent()}
            </Link>
          </div>
        )
      } else {
        return getPreviewComponent()
      }
    } else if (isEnergyWidget) {
      if (widgetLink === 'None') {
        return getPreviewComponent()
      } else {
        const link = getWidgetLink(
          widgetLink === 'CustomURL' ? customUrl : widgetLink,
          accountId,
          buildingId,
          widgeEquipment1Id
        )
        if (isNewWindow) {
          return (
            <div className="has-link">
              <Link to={link} target="_blank" rel="noopener noreferrer">
                {getPreviewComponent()}
              </Link>
            </div>
          )
        } else {
          return (
            <div className="has-link">
              <Link to={link}>{getPreviewComponent()}</Link>
            </div>
          )
        }
      }
    } else {
      if (
        widgetLink === 'None' ||
        widgetLink === null ||
        widgetLink === '' ||
        widgetLinkType === 'None'
      ) {
        return getPreviewComponent()
      } else {
        if (isNewWindow) {
          return (
            <div className="has-link">
              <Link to={widgetLink} target="_blank" rel="noopener noreferrer">
                {getPreviewComponent()}
              </Link>
            </div>
          )
        } else {
          return (
            <div className="has-link">
              <Link to={widgetLink}>{getPreviewComponent()}</Link>
            </div>
          )
        }
      }
    }
  }

  return (
    <WidgetCardStylesContainer
      showLegends={comparedTo !== ':none'}
      key={widgetDetailState?.id}
      widgetType={widgetDetailState?.widget?.widgetType}
      dimension={`${height}X${width}`}
      cardWidth={width || 300}
      cardHeight={height || 300}
    >
      <WidgetCardStyles
        key={widgetDetailState?.id}
        dimension={`${height}X${width}`}
      >
        <Title className="selectable-drag-and-drop">
          <ParaName>{widgetDetailState?.widget?.name}</ParaName>
          <WidgetResizeMenu
            id={widgetDetailState?.id}
            handleResize={handleResize}
            onUpdate={handleUpdate}
            {...selectedDimen}
          />
          <Hamburger>
            {menuItems
              // Building data charts widgets shouldn't have copy option, so filter here
              ?.filter(
                ({ actionName }) =>
                  !(
                    widgetDetailState?.widget?.widgetType ===
                      WidgetNames.BuildingData && actionName === 'Copy'
                  )
              )
              .map(({ actionName, actionIcon, onclick }, key) => (
                <ListItem key={key} onClick={() => onclick(widgetDetailState)}>
                  <span className={`icon  ${actionIcon}`} />
                 <TranslateComponent>{actionName}</TranslateComponent> 
                </ListItem>
              ))}
          </Hamburger>
        </Title>
        {getWidgetContentWithLink()}
      </WidgetCardStyles>
    </WidgetCardStylesContainer>
  )
}
