import { Parser } from "expr-eval"

const ExpressionUtil = {
	validate(expression, allowedVariables) {
		if (!allowedVariables.length) {
			return false
		}

		const bindings = {}
		allowedVariables.forEach(v => bindings[v] = 1)

		try {
			Parser.evaluate(expression, bindings)
			return true
		} catch (e) {
			return false
		}
	},

	isEmptyDataSet(bindings, keys = []) {
		return keys.some(v => bindings[v].length === 0)
	},

	execute(expression, bindings = {}) {
		const vars = Object.keys(bindings)

		if (!ExpressionUtil.validate(expression, vars) || ExpressionUtil.isEmptyDataSet(bindings, vars)) {
			return false
		}

		const { max, min } = Object.entries(bindings).reduce((a, c) => {
			const parsedMin = Date.parse(c[1][0].timestamp)
			const parsedMax = Date.parse(c[1][c[1].length - 1].timestamp)
			a.min = a.min >= parsedMin && a.min !== 0 ? a.min : parsedMin
			a.max = a.max <= parsedMax && a.max !== 0 ? a.max : parsedMax
			return a
		}, { max: 0, min: 0 })

		bindings = Object.entries(bindings).map(e => {
			e[1] = e[1].filter(d => {
				const parsed = Date.parse(d.timestamp)
				return parsed >= min && parsed <= max
			})
			return e
		}).reduce((a, c) => {
			a[c[0]] = c[1]
			return a
		}, {})

		expression = Parser.parse(expression)
		const bind = bindings[vars[0]].map((val, index) => {
			const vals = vars.reduce((acc, item) => {
				acc[item] = bindings[item][index].value
				return acc
			}, {})
			const countOfCalcPoints = vals.length
			if (countOfCalcPoints === 1 || countOfCalcPoints === undefined) {
				return {
					timestamp: val.timestamp,
					value: Object.keys(vals).filter(key => vals[key] === null).length
						? null
						: Object.keys(vals).filter(key => vals[key] === void 0).length
							? void 0
							: expression.evaluate(vals)
				}
			}
			const countOfGapPoints = Object.keys(vals).filter(key => vals[key] === null).length
			return {
				timestamp: val.timestamp,
				value: countOfGapPoints === countOfCalcPoints
					? null
					: expression.evaluate(vals)
			}
		})
		return bind
	}
}

export default ExpressionUtil
