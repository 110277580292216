import { ClientSideTable } from 'src/components/Table/clientSideTable'
import { Chip } from 'src/denali-components/Table/Chip'
import { Chips } from 'src/denali-components/Table/Chips'
import { ChipStatus } from 'src/denali-components/Table/ChipStatus'
import { ChipPriority } from 'src/denali-components/Table/ChipPriority'
import { ChipNextStep } from 'src/denali-components/Table/ChipNextStep'
import { Flex } from '@aws-amplify/ui-react'
import { TranslateComponent } from 'src/common/translations'
import styles from './opportunities.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDollar,
  faBuilding
} from 'src/denali-components/lib/pro-solid-svg-icons'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from 'src/denali-components/Tooltip/Tooltip'
import { NEXT_STEPS_OPTION_LIST } from 'src/components/legacy/common/opportunity'

/**
 * OpportunitiesList
 * View replacement for src/pages/opportunities/OpportunityList.tsx
 */
export const OpportunitiesList = ({
  rows,
  nameHeadings,
  optionsTranslated,
  getListRowControl,
  handleGetOpportunitiesRecords,
  statusOptions,
  listDataIsLoading,
  noTableDataAction
}) => {
  const filtersList = getFiltersList(statusOptions)


  return (
      <ClientSideTable
        rows={rows}
        rowAction={rowActionCallback(nameHeadings)}
        header={getHeadersWithRowRenderOverride(optionsTranslated)}
        search={true}
        searchFields={[
          'title',
          'keyMetrics',
          'buildingName',
          'status',
          'priority'
        ]}
        rowControl={getListRowControl}
        filtersList={filtersList}
        isDenaliTable={true}
        testName="OpportunitiesPage"
        handleUpdatedTableRows={handleGetOpportunitiesRecords}
        listDataIsLoading={listDataIsLoading}
        noTableDataTitleText={'No opportunities found'}
        noTableDataAction={noTableDataAction}
      />
  )
}

const getFiltersList = (statusOptions) => {
  return [
    {
      key: 'status',
      filterName: 'status',
      filterLabel: 'Status',
      id: 'statusSelectorFilter',
      defaultLabel: 'Status',
      selectedValue: 'default',
      options: statusOptions.map((s) => ({
        ...s,
        name: s?.value
      }))
    },
    {
      key: 'priority',
      filterName: 'priority',
      filterLabel: 'Priority',
      id: 'prioritySelectorFilter',
      defaultLabel: 'Priority',
      selectedValue: 'default',
      options: [
        {
          key: 'urgent!',
          value: 'URGENT!',
          name: 'URGENT!',
        },
        {
          key: 'high',
          value: 'High',
          name: 'High',
        },
        {
          key: 'medium',
          value: 'Medium',
          name: 'Medium',
        },
        {
          key: 'low',
          value: 'Low',
          name: 'Low',
        },
        {
          key: 'notPrioritized',
          value: 'Not Prioritized',
          name: 'Not Prioritized',
        },
      ]
    },
    {
      key: 'nextStep',
      filterName: 'nextStep',
      filterLabel: 'Next Step',
      id: 'nextStepSelectorFilter',
      defaultLabel: 'Next Step',
      selectedValue: 'default',
      options: NEXT_STEPS_OPTION_LIST.map((step) => ({
        ...step,
        name: step.value
      }))
    },
  ]
}

const rowActionCallback = (nameHeadings) => {
  return (event, row) => {
    if (event?.target?.tagName === 'BUTTON') return
    nameHeadings.find((heading) => heading.key === 'title').onDataClick(row)
    event?.preventDefault()
  }
}

const getHeadersWithRowRenderOverride = (optionsTranslated) => {
  return [
    {
      title: optionsTranslated.opportunity,
      key: 'title',
      width: '100%',
      customComponent: titleCell
    },
    {
      title: optionsTranslated.price,
      key: 'price',
      width: '275px',
      customComponent: priceAndRoiCell
    },
    {
      title: optionsTranslated.status,
      key: 'status',
      width: '150px',
      customComponent: statusAndNextStepCell
    }
  ]
}

const getKeyMetrics = (keyMetrics) => {
  const metrics = keyMetrics.split(',').filter(Boolean)
  const metricsToolTip = metrics.join(', ')
  return (
    <>
      <Tooltip>
        <TooltipTrigger>
          <Chip text={metrics[0]} />
        </TooltipTrigger>
        <TooltipContent>
          <TranslateComponent>Key Metrics</TranslateComponent>
        </TooltipContent>
      </Tooltip>
      {metrics.length > 1 && (
        <Tooltip>
          <TooltipTrigger>
            <Chip text={'+ ' + (metrics.length - 1)} />
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>{metricsToolTip}</TranslateComponent>
          </TooltipContent>
        </Tooltip>
      )}
    </>
  )
}

const titleCell = (row) => {
  return (
    <>
      <div className={styles.title} data-test="reportsRowTitle">
        {row.title}
      </div>
      <Chips>
        {row.buildingName && <Chip text={row.buildingName} icon={faBuilding} />}
        {row.keyMetrics && getKeyMetrics(row.keyMetrics)}
        {row.priority && (
          <Tooltip>
            <TooltipTrigger>
              <ChipPriority priorityText={row.priority} />
            </TooltipTrigger>
            <TooltipContent>
              <TranslateComponent>Priority</TranslateComponent>
            </TooltipContent>
          </Tooltip>
        )}
      </Chips>
    </>
  )
}

const priceAndRoiCell = (row) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <Flex gap={16} alignItems="center">
          <div className={styles.priceIcon}>
            <FontAwesomeIcon icon={faDollar} color="white" />
          </div>
          <Flex direction="column" gap={4}>
            <div className="body-lg no-wrap">{row.price}</div>
            <div className="body-md no-wrap">ROI: {row.paybackTerm}</div>
          </Flex>
        </Flex>
        </TooltipTrigger>
      <TooltipContent>
        <TranslateComponent>Price</TranslateComponent>
      </TooltipContent>
    </Tooltip>
  )
}

const statusAndNextStepCell = (row) => {
  return (
    <Flex>
      {row.status && (
        <Tooltip>
          <TooltipTrigger>
            <ChipStatus statusText={row.status} />
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>Status</TranslateComponent>
          </TooltipContent>
        </Tooltip>
      )}
      {row.nextStep && (
        <Tooltip>
          <TooltipTrigger>
            <ChipNextStep nextStepText={row.nextStep} />
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>Next Step</TranslateComponent>
          </TooltipContent>
        </Tooltip>
      )}
    </Flex>
  )
}

export default OpportunitiesList
