import styled from 'styled-components'
import COLORS from 'src/components/legacy/common/colors.json'


export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 10px 0 16px;
  background: ${COLORS.TBA_MEDIUM_GREEN};
  color: ${COLORS.WHITE};
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid ${COLORS.TBA_DARK_GREY};
`
export const ParaName = styled.p`
  margin-right: auto;
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  line-height: 20px;
  font-size: 16px;
`

export const ListItem = styled.li`
  color: black;
`
export const RowCount = styled.p`
  color: ${COLORS.TBA_DARK_GREY};
  font-family: ArialMT;
  font-size: 12px;
  margin-top: 20px;
`

export const WidgetFilters = styled.div`
  display: flex;
  padding: 10px 10px 10px 20px;
  margin-bottom: -20px;
  margin-top: -40px;
  input{
    margin-top:14px;
	height:33px;
  }
  svg{
    margin-top:16px;
  }
  .select-wrapper {
    align-self: flex-start;
    min-width: 100%;
    width: 100%;

  }
	& .widgetSearch{
		width: 100%
	}
`
export const WidgetLibrary = styled.div`
.widget-library {
	padding: 20px 20px 90px 20px;
	background-color: #f5f5f5;
    border: 1px solid #999;
	overflow:auto;

	display: flex;
    flex-wrap: wrap;

  a{
    text-decoration: none;
    cursor:pointer;
    color:black;
  }

	& .grid {

		& .item {
			height: 344px;
			position: relative;
			width: 300px;

			&.generic-widget::after {
				content: "";
				position: absolute;
				top: 36px;
				left: 0;
				width: 100%;
				height: calc(100% - 76px);
				background: transparent url(assets/images/widget-sample-label.png) center center no-repeat;
			}
		}

		& .block {
			position: relative;
			width: 100%;
			height: 280px;
			background-color: ${COLORS.WHITE};
			border: 1px solid ${COLORS.TBA_DARK_GREY};
			border-radius: 6px;
			box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.2);
		}

		& h2 {
			overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
			width: 100%;
			height: 36px;
			line-height: 36px;
			margin: 0;
			padding: 0 10px 0 16px;
			background: ${COLORS.TBA_MEDIUM_GREEN};
			color: ${COLORS.WHITE};
		}

		& .buttons {
			display: flex;
			height: 40px;
			margin: 0 16px 0 16px;
			justify-content: space-around;
			align-items: center;
			background-color: ${COLORS.WHITE};
			border: 1px solid ${COLORS.TBA_DARK_GREY};
			border-top: none;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
			width: 250px;
			position: absolute;
			bottom: 12px;
			left: 10px;
		}

		& button:not(.primary) {
			border: none;
			font-size: 22px;
			color: ${COLORS.TBA_DARKER_GREY};
			background-color: transparent;

			&:hover {
				color: ${COLORS.TBA_DARK_GREY};
			}
			&:active {
				color: ${COLORS.TBA_MEDIUM_GREY};
			}
		}

		& .cell {
			width: 100%;
			height: 280px;
			padding: 0;

      & .widget {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;
        background: ${COLORS.WHITE};
        border: 1px solid ${COLORS.TBA_DARK_GREY};
        border-radius: 6px;
        box-shadow: 3px 4px 4px #00000033;
        box-sizing: content-box;
        overflow: hidden;
    
        .widget-content {
          height: calc(100% - 62px);
          box-sizing: border-box;
          padding: 8px 16px;
          overflow: hidden;
          position: relative;
    
          
          
        }
      }

			& .widget-header {
				padding: 0;
			}
		}
	}
}
`
