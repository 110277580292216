import { useState, useCallback, useEffect } from 'react'
import MultiTabForm from '../MultiTabTorm'
import { TabsWrapper } from '../../styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import {
  WidgetBuildingSchema,
  WidgetOverview,
  WidgetOverviewSchema
} from '../WidgetOverview'
import {
  DataComparisonBarChartConfigurationSchema
} from './DataComparisonBarChartConfiguration'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import {
  LATEST_VALUE,
  MODAL_TYPE,
  MaxConstant,
  TimePeriodCurrentDay,
  WIDGET_TABS_NAME,
  getDataComparisonWidgetUpsertMutationInputs,
} from '../../helpers'
import { ACTIONS } from 'src/constants/actions'
import {
  BAR1_COLOR,
  BAR2_COLOR,
  TARGET_COLOR
} from '../../colors'
import { TARGET_MARKERS_NONE } from 'src/common/target-markers'
import DataComparisonBarChartColors from './DataComparisonBarChartColors'
import { useMutation } from 'src/hooks/APIHooks'
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../graphql'
import DataComparisonBarChartConfiguration from './DataComparisonBarChartConfiguration'
import DataComparisonBarPreviewWrapper from './DataComparisonBarPreviewWrapper'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate from 'src/common/translations'

const tabsConfig = (errors) => [
  {
    title: WIDGET_TABS_NAME.OVERVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.OVERVIEW,
    formikErrors: errors,
    validationSchema: WidgetOverviewSchema,
    component: <WidgetOverview />
  },
  {
    title: WIDGET_TABS_NAME.CONFIGURATION,
    disabled: false,
    key: WIDGET_TABS_NAME.CONFIGURATION,
    formikErrors: errors,
    validationSchema: DataComparisonBarChartConfigurationSchema,
    component: <DataComparisonBarChartConfiguration />
  },
  {
    title: WIDGET_TABS_NAME.COLORS,
    disabled: false,
    key: WIDGET_TABS_NAME.COLORS,
    formikErrors: errors,
    component: <DataComparisonBarChartColors />
  },
  {
    title: WIDGET_TABS_NAME.PREVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.PREVIEW,
    formikErrors: errors,
    component: <DataComparisonBarPreviewWrapper />
  }
]

const DataComparisonBarChartForm = ({ setWidgetFormik }) => {
  const {
    widgetBuilding,
    setOpenWidgetPicker,
    userInfo,
    setReloadEvents,
    setIsLoading,
    mode,
    widgetDetails,
    setWidgetDetails,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setModalType,
    formikErrors,
    objectPickerSelectedValue,
    setWidgetBuilding,
    setIsFormValidationAlone,
    setIsSubmitted,
    isFormValidationAlone,
    setWidgetId,
    isFooterChecked
  } = useWidgetsContext()
  const [activeTab, setActiveTab] = useState(0)
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const onSelectTab = useCallback((selectedTab: any, selectedTitle: any) => {
    setActiveTab(selectedTab)
    if (selectedTitle === WIDGET_TABS_NAME.PREVIEW) {
      setIsFormValidationAlone(true)
      setIsSubmitted(false)
    }
  }, [])
  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: [ACTIONS.ADD,ACTIONS.COPY].includes(mode) ? CREATE_WIDGET : UPDATE_WIDGET,
    refreshTokenBuildingVariable: "input.buildingId",
    onError: (error) => {
      setError(error)
      setIsLoading(false)
    },
    onSuccess: (success) => {
      if([ACTIONS.ADD].includes(mode))
        {
          trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Create", type: "Data Comparison Bar Chart"})
        }
      else if([ACTIONS.COPY].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Copy", type: "Data Comparison Bar Chart"})
      }
      else{
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Edit", type: "Data Comparison Bar Chart"})
      }
    }
  })

  const submitForm = async (values) => {
    if (objectPickerSelectedValue?.length === 0 || !widgetBuilding?.id || isFormValidationAlone) return
    setIsLoading(true)
    const inputs = getDataComparisonWidgetUpsertMutationInputs(
      values,
      userInfo,
      objectPickerSelectedValue,
      mode
    )
    await upsertWidgetMutation({ input: inputs }).then((response) => {
      const widgetId =
      [ACTIONS.ADD,ACTIONS.COPY].includes(mode)
          ? response.data?.createWidget?.id
          : response.data?.updateWidget?.id
      if (widgetId) {
        setIsLoading(false)
        setWidgetDetails(inputs)
        setOpenWidgetPicker(false)
        setReloadEvents(true)
        setModalType(MODAL_TYPE.SUCCESS)
        setSelectedWidget(null)
        setIsOpenCreateWidget(isFooterChecked ?? false)
        setWidgetId(widgetId)
      }
    })
  }
  const copyof = translate("Copy of")
  useEffect(() => {
    switch (mode) {
      case ACTIONS.ADD:
        setFormValues({
          id: '',
          name: '',
          description: '',
          link: '',
          isNewWindow: true,
          widgetBuilding: '',
          leftBarEquipment: '',
          leftTargetMarker: TARGET_MARKERS_NONE,
          leftTargetMarkerCalculate:'',
          leftMarkerConstant: '',
          leftMarkerLabel: '',
          leftProperty: '',
          leftUnitsOfMeasure: '',
          rightBarEquipment: '',
          rightTargetMarker: TARGET_MARKERS_NONE,
          rightTargetMarkerCalculate:'',
          rightMarkerConstant: '',
          rightMarkerLabel: '',
          rightProperty: '',
          rightUnitsOfMeasure: '',
          barMax: MaxConstant,
          barConstantValue: '',
          barCalculatedValue: '',
          barMin: '0',
          value: LATEST_VALUE,
          timePeriod: TimePeriodCurrentDay,
          leftBarColor: BAR1_COLOR,
          rightBarColor: BAR2_COLOR,
          leftBenchmarkColor: TARGET_COLOR,
          rightBenchmarkColor: TARGET_COLOR,
          leftSymbol:'',
          rightSymbol:'',
        })
        break
      case ACTIONS.EDIT:
      case ACTIONS.COPY:
        setFormValues({
          id: mode === ACTIONS.EDIT ? widgetDetails?.id : '',
          name: mode === ACTIONS.EDIT ? widgetDetails?.name : `${copyof} ${widgetDetails?.name}`,
          description: widgetDetails?.description ?? '',
          link: widgetDetails?.widgetLink,
          isNewWindow: widgetDetails?.isNewWindow,
          leftBarEquipment: widgetDetails?.widgeEquipment1Name,
          leftTargetMarker:
            widgetDetails?.equipmentDataComparisionBarWidget?.leftBarProperty
              ?.benchmarkMarker,
          leftTargetMarkerCalculate:
            widgetDetails?.equipmentDataComparisionBarWidget?.leftBarProperty
              ?.benchmarkCalculation,
          leftMarkerConstant:
            widgetDetails?.equipmentDataComparisionBarWidget?.leftBarProperty
              ?.benchmarkValue ?? '',
          leftMarkerLabel:
            widgetDetails?.equipmentDataComparisionBarWidget?.leftBarProperty
              ?.benchmarkLabel,
          leftProperty:
            widgetDetails?.equipmentDataComparisionBarWidget?.leftBarProperty
              ?.property,
          leftUnitsOfMeasure:
            widgetDetails?.equipmentDataComparisionBarWidget?.leftBarProperty
              ?.uom,
          leftSymbol:
            widgetDetails?.equipmentDataComparisionBarWidget?.leftBarProperty
              ?.symbol,
          rightBarEquipment: widgetDetails?.widgeEquipment2Name,
          rightTargetMarker:
            widgetDetails?.equipmentDataComparisionBarWidget?.rightBarProperty
              ?.benchmarkMarker,
          rightMarkerConstant:
            widgetDetails?.equipmentDataComparisionBarWidget?.rightBarProperty
              ?.benchmarkValue ?? '',
          rightMarkerLabel:
            widgetDetails?.equipmentDataComparisionBarWidget?.rightBarProperty
              ?.benchmarkLabel,
          rightTargetMarkerCalculate:
              widgetDetails?.equipmentDataComparisionBarWidget?.rightBarProperty
                ?.benchmarkCalculation,
          rightProperty:
            widgetDetails?.equipmentDataComparisionBarWidget?.rightBarProperty
              ?.property,
          rightUnitsOfMeasure:
            widgetDetails?.equipmentDataComparisionBarWidget?.rightBarProperty
              ?.uom,
          rightSymbol:
            widgetDetails?.equipmentDataComparisionBarWidget?.rightBarProperty
              ?.symbol,
          barMax:
            widgetDetails?.equipmentDataComparisionBarWidget?.barMaximumType,
          barConstantValue:
            widgetDetails?.equipmentDataComparisionBarWidget?.barMaximumValue,
          barCalculatedValue:
            widgetDetails?.equipmentDataComparisionBarWidget
              ?.barMaximumCalculationType ?? '',
          barMin: widgetDetails?.equipmentDataComparisionBarWidget?.barMinimum,
          value: widgetDetails?.equipmentDataComparisionBarWidget?.value,
          timePeriod:
            widgetDetails?.equipmentDataComparisionBarWidget?.timePeriod,
          leftBarColor:
            widgetDetails?.equipmentDataComparisionBarWidget?.colourConfig
              ?.leftBar,
          rightBarColor:
            widgetDetails?.equipmentDataComparisionBarWidget?.colourConfig
              ?.rightBar,
          leftBenchmarkColor:
            widgetDetails?.equipmentDataComparisionBarWidget?.colourConfig
              ?.leftBenchmark ?? TARGET_COLOR,
          rightBenchmarkColor:
            widgetDetails?.equipmentDataComparisionBarWidget?.colourConfig
              ?.rightBenchmark ?? TARGET_COLOR
        })
        setWidgetBuilding({ id: widgetDetails?.buildingId })
        break
    }
  }, [mode, copyof])

  const displayForm = () => {
    return mode !== ACTIONS.ADD ? formValues?.name : true
  }

  return (
    <>
      {displayForm() && (
        <MultiTabForm
          initialValues={formValues}
          onSubmit={submitForm}
          schemas={[
            WidgetOverviewSchema,
            WidgetBuildingSchema,
            DataComparisonBarChartConfigurationSchema
          ]}
          setWidgetFormik={setWidgetFormik}
        >
          <TabsWrapper>
            <Tabs
              defaultActiveTab={activeTab}
              activeTab={activeTab}
              onSelect={onSelectTab}
            >
              {tabsConfig(formikErrors).map((item: any, key: number) => {
                return (
                  <Tab
                    title={item.title}
                    disabled={item.disabled}
                    key={key}
                    formikErrors={item?.formikErrors}
                    validationSchema={item?.validationSchema}
                  >
                    {item.component}
                  </Tab>
                )
              })}
            </Tabs>
          </TabsWrapper>
          <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
        </MultiTabForm>
      )}
    </>
  )
}

export default DataComparisonBarChartForm
