import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import ImageBlock from '../image-block/image-block'
import Finding from '../../../../../pages/opportunities/OpportunityDetail/cost-savings/finding-view'
import FindingsDialog from '../../../../../pages/opportunities/OpportunityDetail/findings-dialog'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './image-block-list.scss'
import i18next from 'i18next'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { TranslateComponent } from '../../../../../common/translations'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
export default class ImageBlockList extends PureComponent {
  static defaultProps = {
    images: [],
    maxSize: 6,
    findings: [],
    showAddFindingButton: false,
    onFindingsUpdate: () => {},
    label: 'components:images>Images/Findings',
    annotationsEnabled: false,
    testName:''
  }

  static propTypes = {
    images: PropTypes.array,
    onUpdate: PropTypes.func.isRequired,
    maxSize: PropTypes.number,
    findings: PropTypes.array,
    showAddFindingButton: PropTypes.bool,
    onFindingsUpdate: PropTypes.func,
    label: PropTypes.string,
    testName: PropTypes.string,
    annotationsEnabled: PropTypes.bool
  }

  state = {
    showFindingsDialog: false,
    findingsDialogLoading: false
  }

  handleFindingDialogModal = (isEnabled) => {
    this.setState({ showFindingsDialog: isEnabled})
  }

  update = (index) => (values) => {
    const images = [...this.props.images]
    images[index] = { ...images[index], attachmentOrder: index, ...values }

    this.props.onUpdate(images)
  }

  remove = (index) => () => {
    const images = this.props.images.filter((item, i) => index !== i)
    const updatedImages = images.map((img, index) => (img.attachmentOrder != index) ? { ...img, attachmentOrder: index } : img)

    this.props.onUpdate(updatedImages)
  }

  addBlock = (e) => {
    e.preventDefault()
    const { images, maxSize } = this.props
    if (maxSize && images.length === maxSize) {
      return
    }

    this.props.onUpdate([...images, ...[{ key: uuidv4() }]])
  }

  removeFinding = (id) => () => {
    const { onFindingsUpdate, findings } = this.props
    const filteredFindings = findings.filter(
      ({ findingId }) => id !== findingId
    )

    onFindingsUpdate(filteredFindings, false)
  }

  showFindingsDialog = (e) => {
    e.preventDefault()
    this.setState({ showFindingsDialog: true })
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const images = reorder(
      this.props.images,
      result.source.index,
      result.destination.index
    )

    this.props.onUpdate(images)
  }

  render() {
    const {
      images,
      maxSize,
      findings,
      showAddFindingButton,
      onFindingsUpdate,
      label,
      annotationsEnabled,
      organizationId,
      locationId,
      testName=''
    } = this.props
    const findingsBlocks = findings.map((finding) => (
      <Finding
        key={finding.findingId}
        finding={finding}
        remove={this.removeFinding(finding.findingId)}
      />
    ))

    return (
      <div className="block images-list" data-testid={`${testName} images-list`}>
        <header>
          <legend>{<TranslateComponent>Supporting Images</TranslateComponent>}</legend>
          <div className="tool-buttons" data-testid="header-tool-buttons">
            {showAddFindingButton && (
              <button
                data-testid="add-finding"
                className="action add-finding"
                onClick={this.showFindingsDialog}
              >
                {<TranslateComponent>Add Finding</TranslateComponent>}
              </button>
            )}
            <button
              data-testid="add-image"
              className="action"
              onClick={this.addBlock}
              disabled={images.length === maxSize || !locationId}
            >
              {<TranslateComponent>Add Image</TranslateComponent>}
            </button>
          </div>
        </header>
        {Boolean(findingsBlocks.length || images.length) && (
          <section>
            {Boolean(findingsBlocks.length) && findingsBlocks}
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(droppableProvided) => (
                  <div ref={droppableProvided.innerRef}>
                    {images.map((image, index) => (
                      <Draggable
                        key={image.key || image.imageId}
                        draggableId={`draggable-${image.imageId}`}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <ImageBlock
                              key={image.key || image.imageId}
                              image={image}
                              imageUrl={image?.imageUrl}
                              imageName={image?.displayName}
                              update={this.update(index)}
                              remove={this.remove(index)}
                              annotationsEnabled={annotationsEnabled}
                              locationId={this.props.locationId}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </section>
        )}
        {images.length >= 2 ? (
          <button
            className="action bottom"
            onClick={this.addBlock}
            disabled={images.length === maxSize}
          >
            {i18next.t('components:images>AddImage')}
          </button>
        ) : null}
        {this.state.showFindingsDialog && (
          <FindingsDialog
            organizationId={organizationId}
            handleClose={() => this.setState({ showFindingsDialog: false })}
            handleDialogModal={this.handleFindingDialogModal}
            onSubmit={(updatedFindings) => {
              onFindingsUpdate(updatedFindings, true)
              trackEvent(USER_EVENTS.OPPORTUNITIES.events.ADD_FINDING, {"number of findings":updatedFindings.length})
            }}
            handleLoading={(findingsDialogLoading) =>
              this.setState({ findingsDialogLoading })
            }
            isLoading={this.state.findingsDialogLoading}
            maxSize={maxSize}
            selectedFindings={findings}
          />
        )}
      </div>
    )
  }
}
