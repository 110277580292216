import styled from 'styled-components'
import Checkbox from 'src/denali-ui/components/Checkbox'
import IconSVG from 'src/components/Icon/index.js'
import { useEffect, useState } from 'react'
// const _ = require("lodash");

const Wrapper = styled.div`
    background-color: #f4f4f4;
    border: ${(props) =>
      props.error ? '2px solid #d52b23;' : '1px solid #999;'}
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 520px;
    margin-top: 7px;
    min-height: 520px;
    min-width: 200px;
    position: relative;
    z-index: 9;
`

const HeaderLabel = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 900;
`

const PickerHeader = styled.div`
  ${(props) => props.error && 'color: #d52b23;'}
  background-color: #f4f4f4;
  font-size: 13px;
  font-weight: 700;
  height: 26px;
  letter-spacing: 0.26px;
  line-height: 26px;
  overflow: hidden;
  padding: 0 25px 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

const SearchDiv = styled.div`
  align-items: center;
  background-color: #ccc;
  display: flex;
  height: 26px;
  padding: 0 10px;
`

const SearchInput = styled.input`
    background: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 9px;
    font-family: Archivo Narrow,sans-serif;
    font-size: 12px;
    font-weight: 700;
    height: 18px;
    letter-spacing: .24px;
    line-height: 14px;
    padding: 0 6px;
    width: 100%;
}
`

const DataHeader = styled.span`
  ${(props) => props.error && `background-color: #fea7aa; color: #d52b23;`}
  display: block;
  border-bottom: 1px solid #ccc;
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  font-weight: 900;
  height: 20px;
  letter-spacing: 0.24px;
  line-height: 19px;
  margin: 0;
  padding: 0 3px;
  text-transform: uppercase;
`

const List = styled.ul`
  flex: 1;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
`

const ListItem = styled.li`
  padding-left: 4px;
  background-color: #fff;
  box-sizing: border-box;
  color: #000;
  display: flex;
  font-family: Lato, sans-serif;
  font-size: 12px;
  height: 20px;
  line-height: 16px;
  list-style-type: disc;
  min-height: 20px;
  text-align: left;
  user-select: none;
  width: 100%;
`

const ListItemText = styled.span`
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

const ListItemWithSubFlex = styled.span`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const BottonSection = styled.section`
  display: block;
  flex: none;
  flex: 1 0 0px;
  min-height: 0;
  position: relative;
  z-index: 99;
`

const BottomList = styled.ul`
  background-color: #f4f4f4;
  padding: 0 0 16px;
  flex: 1;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;
  line-height: 20px;
`

const BottomListItem = styled.li`
  background-color: #f4f4f4;
  cursor: pointer;
  display: flex;
  min-height: 20px;
  width: 100%;
  display: flex;
  font-family: Archivo Narrow, sans-serif;
  font-size: 12px;
  height: 18px;
  letter-spacing: 0.24px;
  line-height: 19px;
  line-height: normal;
  padding: 0 10px 0 8px;
  position: relative;
`

const BottomHead = styled.h3`
  background-color: #f4f4f4;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.24px;
  line-height: 19px;
  margin: 0;
  padding: 0 10px;
`

const BottomText = styled.span`
  line-height: 18px;
  max-width: calc(100% - 18px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledDiv = styled.div`
  height: inherit;
  overflow-y: scroll;
`

const StyledDivFoot = styled.div`
  overflow-y: scroll;
  max-height: 25%;
`

const returnCheckedDataFunc = (d) => {
  let checkedArr = []
  if (d.checked) {
    const {subData, ...rest} = d
    checkedArr.push(rest)
  }
  if (d.subData) {    
    const subDataVals = d.subData.reduce((accum, curr) => {
      return accum.concat(returnCheckedDataFunc(curr))
    }, [])
    checkedArr = checkedArr.concat(subDataVals)
  }
  return checkedArr
}

export const ObjectPicker = ({
  values,
  onCheckboxClick,
  onCheckboxUnclick,
  error,
  label = 'Marker Target',
  header = 'Select Building',
  dataHeader = 'BUILDINGS'
}) => {
  const [filteredValues, setFilteredValues] = useState(values)
  const [activeSub, setActiveSub] = useState(null)
  const checkedValues = values.reduce((accum, curr) => {
    return accum.concat(returnCheckedDataFunc(curr))
  }, [])

  useEffect(() => {
    setFilteredValues(values)
  }, [values])

  return (
    <>
      <HeaderLabel>{label}</HeaderLabel>
      <Wrapper error={error}>
        <StyledDiv>
          <PickerHeader error={error}>{header}</PickerHeader>
          <SearchDiv>
            <SearchInput
              type={'text'}
              placeholder={'Find an object...'}
              onChange={(e) =>
                setFilteredValues(
                  values.filter((v) => {
                    var regex = new RegExp(e.target.value?.replace(/([.?*+^$[\]\\(){}|-])/gi, "\\$1"), 'gi')
                    return e.target.value && e.target.value !== ''
                      ? v.name.match(regex)
                      : true
                  })
                )
              }
            ></SearchInput>
          </SearchDiv>
          {/* TODO: STYLE THIS */}
          {activeSub !== null ? <span onClick={() => setActiveSub(null)}>{"< All Buildings"}</span> : ""} 
          <DataHeader error={error}>{dataHeader}</DataHeader>
          <List>
            {/* NOTE/TODO: IF WE WANT TO EXPAND THIS AND MAKE THE SUBDATA RECURSIVE, WE CAN USE _.GET BY LODASH TO TAKE THE ACTIVE SUB STRING AND MAKE IT RECURSIVE SOMEHOW */}
            {activeSub !== null
            ? filteredValues[activeSub].subData.map((v, i) => (
              <ListItem key={i}>
                <Checkbox
                  disabled={Boolean(v.disabled)}
                  checked={v.checked}
                  onClick={() => onCheckboxClick(v)}
                />
                <ListItemText onClick={(v.subData ? setActiveSub(i) : undefined)}>{v.name}</ListItemText>
              </ListItem>
            ))
            : filteredValues.map((v, i) => (
              <ListItem key={i}>
                <Checkbox
                  disabled={Boolean(v.disabled)}
                  checked={v.checked}
                  onClick={() => onCheckboxClick(v)}
                />
                {/* TODO: Make this an icon */}
                <ListItemText onClick={() => v.subData ? setActiveSub(i) : undefined}><ListItemWithSubFlex><span>{v.name}</span><span>{">"}</span></ListItemWithSubFlex></ListItemText>
              </ListItem>
            ))}
          </List>
        </StyledDiv>
        <StyledDivFoot>
          {checkedValues?.length ? (
            <BottonSection>
              <BottomHead>Selected:</BottomHead>
              <BottomList>
                {checkedValues.map((c, i) => (
                  <BottomListItem key={`close-${i}`}>
                    <IconSVG
                      onClick={() => {
                        onCheckboxUnclick(c)
                      }}
                      name="close-circle"
                      color="#666"
                      margin="2px 4px 0px 0px"
                      width="14px"
                      height="14px"
                    />
                    <BottomText>{c.name}</BottomText>
                  </BottomListItem>
                ))}
              </BottomList>
            </BottonSection>
          ) : (
            ''
          )}
        </StyledDivFoot>
      </Wrapper>
    </>
  )
}

export default ObjectPicker
