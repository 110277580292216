import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import { isDateFormatCorrect } from './helper'

import * as yup from 'yup'
const validationSchema = () => {
  const schema = yup.object().shape({
    documentName: yup.string().required(),
    documentType: yup.string().required(),
    documentStatus: yup.string().required(),
    createdDate: yup
    .string()
    .required('Date is required')
    .test('valid-date', `Please enter a valid date in the format ${DATE_FORMAT}`, function(value: string) {

      const isDateCorrect = isDateFormatCorrect(
        value,
        DATE_FORMAT
      )
      return isDateCorrect;
    }),

    attachments:  yup.array().required(),
    location: yup.object().shape({
      locationId: yup.string().required()
    })
  })
  return schema
}
export default validationSchema
