import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

const ownerDocument = (node) => (node && node.ownerDocument) || document

export default function useClickAway({
  active = false,
  keyCodes = [27],
  onClickAway = () => null
}) {
  // Utils
  const useEventCallback = (fn) => {
    const fnRef = useRef(fn)
    useLayoutEffect(() => {
      fnRef.current = fn
    })
    return useCallback((...args) => (0, fnRef.current)(...args), [])
  }

  // Init setup
  const nodeRef = useRef(null)
  const activatedRef = useRef(false)
  const syntheticEventRef = useRef(false)

  useEffect(() => {
    if (!active) {
      return undefined
    }

    function activatedListener() {
      activatedRef.current = true
    }

    document.addEventListener('mousedown', activatedListener, true)
    document.addEventListener('keydown', activatedListener, true)

    return () => {
      document.removeEventListener('mousedown', activatedListener, true)
      document.removeEventListener('keydown', activatedListener, true)
      activatedRef.current = false
    }
  }, [active])

  // Let developers to cancel onClickAway fn on other synthatic events
  const handleClickAway = useEventCallback((event) => {
    if (!activatedRef.current) {
      return
    }

    const insideReactTree = syntheticEventRef.current
    syntheticEventRef.current = false

    const doc = ownerDocument(nodeRef.current)

    let insideDOM

    if (event.composedPath) {
      insideDOM = event.composedPath().indexOf(nodeRef.current) > -1
    } else {
      insideDOM =
        !doc.documentElement.contains(event.target) ||
        nodeRef.current.contains(event.target)
    }

    if (!insideDOM && !insideReactTree) {
      onClickAway(event)
    }
  })

  const handleEscape = useEventCallback((event) => {
    if (!activatedRef.current) {
      return
    }

    syntheticEventRef.current = false

    if (keyCodes.includes(event.keyCode)) onClickAway(event)
  })

  const handleSynthetic = () => {
    syntheticEventRef.current = true
  }

  useEffect(() => {
    if (active) {
      const doc = ownerDocument(nodeRef.current)

      doc.addEventListener('click', handleClickAway)
      doc.addEventListener('keydown', handleEscape)

      return () => {
        doc.removeEventListener('click', handleClickAway)
        doc.removeEventListener('keydown', handleEscape)
      }
    }
    return undefined
  }, [active, handleClickAway, handleEscape])

  return [nodeRef, handleSynthetic]
}
