/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AxisDiM } from '../../../chart-library/Utils/defaults'
import { useChartContext } from '../../../chart-library/CommonComponents/BaseWrapper'
import { useCallback, useMemo } from 'react'

export function useConfig() {
  const chart = useChartContext()

  const axis = chart.axis
  const scale = chart.scale
  const canvas = chart.grid.getGrid(0)

  return {
    canvas,
    isInitialized: !(!scale.lastUpdatedAt || !axis.lastUpdatedAt)
  }
}
