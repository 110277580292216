import { TranslateComponent } from 'src/common/translations';
import { TitleSection } from './Styled'

interface Props {
  organizationName?: string
  buildingName?: string
  equipmentDetail?: {
    key: string;
    value: string;
    label: string;
    family: string;
  }
}


export default function HistoryTitleSection({
  organizationName = '',
  buildingName = '',
  equipmentDetail
}: Props) {
  return (
    <TitleSection className="gap-start-end">
      <div className="title-block">
        <h4><TranslateComponent>Organization:</TranslateComponent></h4>
        <span>{organizationName}</span>
      </div>
      <div className="title-block">
        <h4><TranslateComponent>Building:</TranslateComponent></h4>
        <span>{buildingName}</span>
      </div>
      {equipmentDetail?.value != null ? (
       <div className="title-block">
        <h4><TranslateComponent>Equipment:</TranslateComponent></h4>
       <span>{equipmentDetail.value}</span>
       </div>
        ) : null}

    </TitleSection>
  )
}
