import * as yup from 'yup'
import moment from 'moment'

const schema = () =>
  yup.object().shape({
    startDate: yup.string().nullable().test((value, context) => {
      if (value && context.parent.endDate && moment(value).isSameOrAfter(moment(context.parent.endDate))) {
        return context.createError({
              message: `Please make sure your start date comes before your end date by at least one day`,
            })
      }
      return true
    }),
    variableFees: yup.array().test((value, { createError }) => {
      if (!value.every(e => (e.unit && e.rate && e.name) || (!e.unit && !e.rate && !e.name))) {
        return createError({
          message: `Please enter all fields for variable fees.`,
        })
      }
      return true
    }),
    fixedFees: yup.array().test((value, { createError }) => {
      if (!value.every(e => (e.unit && e.rate && e.name) || (!e.unit && !e.rate && !e.name))) {
        return createError({
          message: `Please enter all fields for fixed fees.`,
        })
      }
      return true
    }),
  })

export default schema
