import debounce from 'lodash.debounce'
import { useState, useRef, useEffect } from 'react'
import ChartBase from '../../base'
import { uuid } from '../../Utils/helpers'
import { defaultChartConfig } from './chartContext'

/**
 * hook to initalize charts and update based on given options
 *
 * @param options
 * @param disableUpdateOnResize
 * @returns
 */
export default function useInitChart(
  options: any,
  disableUpdateOnResize: boolean
) {
  const [config, setConfig] = useState(defaultChartConfig)

  const chartClassRef = useRef(`chart-` + uuid())

  const chartRef = useRef<ChartBase>(
    new ChartBase({ root: '.' + chartClassRef.current })
  )

  chartRef.current.setListener(setConfig)

  useEffect(() => {
    chartRef.current.setOptions(options)
  }, [options])

  useEffect(() => {
    const listener = debounce(() => {
      chartRef.current.resize()
    }, 150)
    const resizeObserver = new ResizeObserver(listener)

    const withClassSelector = document.querySelector(
      `.${chartClassRef.current}.t-chart-svg-container  svg.t-chart-svg`
    )
    const edgeCases = document.querySelector(`.${chartClassRef.current} > svg`)

    const rootEl = withClassSelector || edgeCases

    if (!disableUpdateOnResize && rootEl) {
      resizeObserver.observe(rootEl)
    }

    return () => resizeObserver.disconnect()
  }, [disableUpdateOnResize, chartClassRef.current])

  return { config, chartCSSclass: chartClassRef.current }
}
