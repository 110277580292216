import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  getEquipmentDetailsbyId,
  GET_DATA_MAPPING_PROPERTIES,
  GET_DATA_MAPPING_CALCULATED,
  GET_DATA_CHARACTERISTICS,
  GET_BY_EQUIPMENT_META_DATA
} from '../../graphql/queries'
import { useQuery } from 'src/hooks/APIHooks'
// components
import Modal from '../../../../components/legacy/components/modal/modal'
import ToggleBox from 'src/components/legacy/components/toggle-box/'
import AssociationDetailsCharacteristics from './association-details-characteristics'
import AssociationDetailsProperties from './association-details-properties'
import AssociationDetailsAssociatedEquipment from './association-details-associated-equipment'
import { transformEQViewDetailsData } from '../EquipmentDetailsParser'
import {
  dataMappingPropertiesFormatter,
  characterticsFormatter
} from '../helper'
import { DETAILS_TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import { useAPIPagination } from 'src/hooks/APIPaginationHook'
import translate from 'src/common/translations'
import { TranslateComponent } from '../../../../common/translations'

AssociationDetails.propTypes = {
  associatedEquipmentId: PropTypes.any,
  toggleDetailsModal: PropTypes.func
}

export default function AssociationDetails({
  associatedEquipmentId,
  toggleDetailsModal,
  defaultDimensionGroup,
}) {
  const [dataMappingProperties, setDataDappingProperties] = useState([])
  const [characteristicsList, setCharacteristicsList] = useState([])

  // TO GET EQUIPMENTDETAILS
  const {
    data,
    loading,
    refetch: refetchEquipmentDetails
  } = useQuery({
    query: getEquipmentDetailsbyId,
    disableInitialLoad: true,
    variables: { id: associatedEquipmentId },
    dataPath: 'data.getEquipment'
  })

  const [
    loadingEquipmentMetaData,
    refetchEquipmentPropertiesMetaData,
    equipmentMetaData
  ] = useAPIPagination(GET_BY_EQUIPMENT_META_DATA, 'data.getByEquipmentType')

  const equipmentCharacteristicMetaData = useMemo(
    () =>
      equipmentMetaData?.filter((x) => x.propertyType === 'Characteristic') ||
      [],
    [equipmentMetaData]
  )

  const equipmentPropertyMetaData = useMemo(
    () =>
      equipmentMetaData?.filter((x) => x.propertyType !== 'Characteristic') ||
      [],
    [equipmentMetaData]
  )

  const {
    data: data_Properties = [],
    refetch: refetchDataProperties,
    loading: loadingDataMappingProperties
  } = useQuery({
    query: GET_DATA_MAPPING_PROPERTIES,
    variables: { id: associatedEquipmentId },
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data.getPropertyMappingByEquipmentID.items'
  })

  const {
    data: data_Calculated = [],
    refetch: refetchDataCalculated,
    loading: loadingDataMappingCalculatedProperties
  } = useQuery({
    query: GET_DATA_MAPPING_CALCULATED,
    variables: { id: associatedEquipmentId },
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data.getPropertyMappingByEquipmentID.items'
  })

  const {
    data: characteristicsListData,
    loading: loadingCharacteristicsLoading,
    refetch: refetchCharacteristicsData
  } = useQuery({
    query: GET_DATA_CHARACTERISTICS,
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    variables: { id: associatedEquipmentId, propertyType: 'Characteristic' },
    dataPath: 'data.getPropertyMappingByEquipmentID.items'
  })

  useEffect(() => {
    try {
      const filteredData = dataMappingPropertiesFormatter(
        data_Properties,
        data_Calculated,
        equipmentPropertyMetaData
      )
      setDataDappingProperties(filteredData)
    } catch (error) {
      setDataDappingProperties([])
    }
  }, [data_Properties, data_Calculated, equipmentPropertyMetaData])

  useEffect(() => {
    try {
      if (
        !loadingCharacteristicsLoading &&
        characteristicsListData &&
        equipmentCharacteristicMetaData?.length > 0
      ) {
        const formattedCharactertiscs = characterticsFormatter(
          characteristicsListData,
          equipmentCharacteristicMetaData
        )
        setCharacteristicsList(formattedCharactertiscs)
      }
    } catch (error) {
      setCharacteristicsList([])
    }
  }, [
    loadingCharacteristicsLoading,
    characteristicsListData,
    equipmentCharacteristicMetaData
  ])

  useEffect(() => {
    if (associatedEquipmentId) {
      refetchEquipmentDetails()
      refetchCharacteristicsData()
      //refetchDataCalculated()
      // refetchDataProperties()
    }
    // eslint-disable-next-line
  }, [associatedEquipmentId])

  useEffect(() => {
    if (data?.type)
      refetchEquipmentPropertiesMetaData({
        equipmentType: data?.type
      })
  }, [data?.type])

  const associatedEquipment = transformEQViewDetailsData(data)

  const modalConfig = {
    gray: true,
    heading: 'Associated Equipment Details',
    className: 'wide-modal associated-details-modal',
    buttons: [
      { text: 'Cancel', handleClick: toggleDetailsModal, type: 'cancel' }
    ],
    handleClose: toggleDetailsModal
  }

  const translatedOptions = {
    detailTitle :"Equipment Details",
    sourceTitle : translate("TIS Equipment Name"),
    characteristics: translate("Characteristics"),
    property: translate("Properties"),
    assoicatedEquipment : translate("Associated Equipment")
  }

  return (
    <Modal testName={DETAILS_TEXT_NAME_PREFIX('assocDetails')} {...modalConfig}>
      <input hidden className="input-focus-kludge" />
      {/*  This input is kludge to fix autofocus in showModal polifill
			line 449 https://github.com/GoogleChrome/dialog-polyfill/blob/v0.5.6/dist/dialog-polyfill.js
			*/}
      <ToggleBox
        label={translatedOptions.detailTitle}
        className="transparent"
        showOnlyLabel
        opened
      >
        <div className="rx-grid rx-grid-bleed">
          <div className="rx-col-3" key="Equipment Type">
            <label><TranslateComponent>{"Equipment Type"}</TranslateComponent></label>
            <div className="param__value">
              <TranslateComponent>{associatedEquipment?.EQDetails?.equipmentType}</TranslateComponent>
            </div>
          </div>
          <div className="rx-col-3" key="TIS Equipment Name">
          <label><TranslateComponent>{"TIS Equipment Name"}</TranslateComponent></label>
            <div className="param__value">
              <TranslateComponent>{associatedEquipment?.EQDetails?.equipmentName}</TranslateComponent>
            </div>
          </div>
        </div>
      </ToggleBox>

      <ToggleBox label={translatedOptions.sourceTitle} className="transparent" showOnlyLabel opened>
        <div className="rx-grid rx-grid-bleed">
          <div className="rx-col-4" key="Default Automation Source">
          <label><TranslateComponent>{"Default Automation Source"}</TranslateComponent></label>
            <div className="param__value">
              {associatedEquipment?.EQDetails?.automationSystem}
            </div>
          </div>
          <div className="rx-col-4" key="Default Data Source Type">
          <label><TranslateComponent>{"Default Data Source Type"}</TranslateComponent></label>
            <div className="param__value">
              {associatedEquipment?.EQDetails?.dataSourceType}
            </div>
          </div>
          <div className="rx-col-4" key="Default Data Source Name">
          <label><TranslateComponent>{"Default Data Source Name"}</TranslateComponent></label>
            <div className="param__value">
              {associatedEquipment?.EQDetails?.dataSourceName}
            </div>
          </div>
        </div>
      </ToggleBox>

      <AssociationDetailsCharacteristics
        equipmentDetails={associatedEquipment?.EQDetails}
        equipmentCharacteristics={characteristicsList}
        refetchCharacteristics={refetchCharacteristicsData}
        loadingCharacteristics={loadingCharacteristicsLoading}
      />
      <AssociationDetailsProperties
        equipmentId={associatedEquipmentId}
        datamapping_properties={dataMappingProperties || []}
        defaultDimensionGroup={defaultDimensionGroup}
        loadingDataMappingProperties={
          loadingDataMappingCalculatedProperties ||
          loadingDataMappingProperties ||
          loadingEquipmentMetaData
        }
      />
      {loading ? (
        <></>
      ) : (
        <AssociationDetailsAssociatedEquipment
          equipmentDetails={associatedEquipment}
        />
      )}
    </Modal>
  )
}
