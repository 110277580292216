import { faBuilding } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./building-selector.module.scss"
import { getColor } from "src/denali-components/helpers";

export const BuildingIcon = ({name=""}) => {
  const color = getColor(name);
  return (
    <div className={styles.buildingIcon} data-color={color}>
      <FontAwesomeIcon icon={faBuilding} />
    </div>
  )
}