// // React/Redux Libraries \\

import styled, { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import 'src/denali-ui/styles/common/font-style.css'

export const GlobalStyle = createGlobalStyle`
	${reset}
	body {
	font-family: "Lato", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
	color: #000000;
	}
	label + input {
		margin-left: 0px;
	}
	input:disabled, textarea:disabled {
		background: transparent;
		color: black;
	}
	strong {
		font-weight: bold;
	}
	dialog {
		.chart-note {
			margin: 20px 0;
			font-style: italic;
		}
		fieldset {
		 	legend {
				font-size: 1.2em;
				font-weight:bold;
				padding-bottom:10px;
			}
			&.location {
				font-weight:bold;
				margin-bottom: 20px;
			}
		}
	}
`

export const NewGlobalStyles = styled.div`
  font-family: Arial;
  font-size: initial;
  line-height: initial;
  color: initial;

  label {
    font-weight: normal;
    letter-spacing: initial;
  }
`

export const NewerGlobalStyles = styled.div`
  font-family: 'NeueHaasUnica-Regular';
  font-size: initial;
  line-height: initial;
  color: initial;

  label {
    font-weight: normal;
    letter-spacing: initial;
  }
  input {
    height: inherit;
    line-height: inherit;
    font-weight: inherit;
    vertical-align: inherit;
    box-sizing: initial;
  }
`

export default GlobalStyle
