import XYChart from '../../../chart-library/Charts/XYChart'
import Timeline from './Timeline'
import { useTimelineChartConfig } from './useTimelineChartConfig'

export default function TimelineWithChart({
  hide = false,
  hideChart = false,
  startTime = null,
  endTime = null,
  getSelctedRange = () => null,
  xAxis = [],
  yAxis = [],
  series,
  ...rest
}) {
  const chartConfig = useTimelineChartConfig({ xAxis, yAxis, series, ...rest })

  if (hide) {
    return null
  }

  return (
    <Timeline
      trackStartTime={startTime}
      trackEndTime={endTime}
      getBrushPosition={getSelctedRange}
      {...rest}
    >
      {!hideChart && <XYChart {...chartConfig} />}
    </Timeline>
  )
}
