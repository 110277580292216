import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import TextInput from 'src/components/legacy/components/modal-text-input'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import validationSchema from './formValidator'
import SelectPermissions from "./select-permissions"
import SelectUserType from "./select-user-types"
import SelectedUserRoles from "./select-user-roles"
import { useQuery } from 'src/hooks/APIHooks'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import styled from 'styled-components'
import { pages } from 'src/pages/pages.js'
import { useMutation } from '../../../hooks/APIHooks'
import { ACTIONS } from 'src/constants'
import IconSvg from "src/components/Icon"
import Select from 'src/components/legacy/components/select/select'
import { CREATE_ROLE_PERMISSION, DELETE_ROLE_PERMISSION, LIST_APPLICATIONS } from '../queries'
import { CREATE_ROLE, GET_ROLE_BY_ID, UPDATE_ROLE } from '../queries'
import ClientSideTable from 'src/components/Table/clientSideTable'



const ButtonWrapper = styled.span`
  padding-top: 24px;
`

export const RoleManagementAdd = () => {
  const {id} = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [mode, setMode] = useState(location?.state?.mode ? location?.state?.mode : id ? ACTIONS.VIEW : ACTIONS.ADD)
  const { data: applicationData } = useQuery({
    query: LIST_APPLICATIONS,
    errorPolicy: 'all',
    dataPath: 'data.listApplications.items'
  })
  const { data: roleData, refetch: refetchRoleData } = useQuery({
    query: GET_ROLE_BY_ID,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getRole'
  })
  const { onSubmit: createRole } = useMutation({
    query: CREATE_ROLE
  })

  const { onSubmit: updateRole } = useMutation({
    query: UPDATE_ROLE
  })

  const { onSubmit: createRolePermission } = useMutation({
    query: CREATE_ROLE_PERMISSION
  })

  const { onSubmit: deleteRolePermission } = useMutation({
    query: DELETE_ROLE_PERMISSION
  })

  useEffect(() => {
    const getRoleDataFunc = async () => {
      if (!roleData && id) {
        await refetchRoleData({id: id})
      }
    }
    getRoleDataFunc()
  }, [id])

  useEffect(() => {
    if (roleData) {
      formik.setFieldValue("name", roleData.name)
      formik.setFieldValue("appId", roleData.appId )
      formik.setFieldValue("type", roleData.type )
      formik.setFieldValue("description", roleData.description )
      formik.setFieldValue("tasks", JSON.stringify(roleData?.tasks || "") )
      const permissions = roleData?.permissions?.items?.map(i => ({...i.permission}) )
      formik.setFieldValue("selectedPermissions", permissions )
      formik.setFieldValue("resourceTypes", JSON.stringify(roleData?.resourceTypes || "") )
      formik.setFieldValue("selectedUserTypes", roleData?.userTypes?.map(e => ({id: e})) || [] )
      formik.setFieldValue("selectedRoleAssignedRoles", roleData?.allowedRoles?.map(e => ({id: e})) || [])
    }
  }, [roleData])

  const {data: applicationList} = useQuery({
    query: LIST_APPLICATIONS,
    errorPolicy: 'all',
    dataPath: 'data.listApplications.items'
  })

  const roleInput = {
    appId: null, 
    description: "", 
    name: "", 
    tasks: "", 
    type: "MULTI",
    selectedPermissions: [],
    resourceTypes: "",
    selectedUserTypes: [],
    selectedRoleAssignedRoles: []
  }

  const headings = [
    {
      name: 'name',
      title: "Name",
      key: 'name',
      maxWidth: '120px'
    },
    {
      name: 'type',
      title: "Type",
      key: 'type',
      maxWidth: '120px',
      disabled: true
    },
    {
      name: 'resourceTypes',
      title: "Resource Types",
      key: 'resourceTypes',
      maxWidth: '120px',
      disabled: true
    },
    {
      name: 'actions',
      title: "",
      key: 'actions',
      maxWidth: '120px',
      disabled: true
    }
  ]

  useEffect(() => {
    navigate(location.pathname, { replace: true })
  }, [])

  const formik = useFormik({
    initialValues: roleInput,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: async (values: any) => {
      let resourceTypes = []
      try {
        resourceTypes = JSON.parse(values.resourceTypes);
      } catch (e) {
        // Just won't save tasks currently. Would like to change the component of tasks to be more fitting.
      }
      let tasks = []
      try {
       tasks = JSON.parse(values.tasks);
      } catch (e) {
        // Just won't save tasks currently. Would like to change the component of tasks to be more fitting.
      }
      if (mode === ACTIONS.ADD) {
        await createRole({
          input: {
            appId: values.appId,
            description: values.description,
            name: values.name,
            isActive: 1,
            // TODO: Handle selected permissions separate (RolePermission mutations)
            // selectedPermissions: values.selectedPermissions
            tasks: tasks,
            type: "MULTI",
            resourceTypes,
            userTypes: values.selectedUserTypes.map(e => e.id) || [],
            allowedRoles: values.selectedRoleAssignedRoles.map(e => e.id) || []
          }
        }).then(async res => {
            if (res?.data?.createRole?.id) {
              await Promise.all(values?.selectedPermissions.map(p => createRolePermission({input: {
                permissionID: p.id, 
                roleID: res.data.createRole.id
              }}))).then(res2 => {
                if (res2) {
                  setMode(ACTIONS.VIEW)
                  refetchRoleData({id: id})
                  navigate(pages["Role Management/:id"].href.replace(":id", res?.data?.createRole?.id))
                }
              })
            }
        })
      } else {
        await updateRole({input: {
          id: roleData.id,
          appId: values.appId,
          description: values.description,
          name: values.name,
          tasks: tasks,
          type: "MULTI",
          resourceTypes,
          userTypes: values.selectedUserTypes.map(e => e.id) || [],
          allowedRoles: values.selectedRoleAssignedRoles.map(e => e.id) || []
        }
        }).then(async res => {
          if (res?.data?.updateRole?.id) {
            const permissionsToRemove = roleData?.permissions?.items?.filter(i => values?.selectedPermissions?.findIndex(e => e.id === i.permissionID) === -1)
            const permissionsToAdd = values?.selectedPermissions?.filter(sp => !roleData?.permissions?.items?.some(i => i.permissionID === sp.id))

            await Promise.all([
              ...permissionsToAdd.map(p => createRolePermission({input: {
                permissionID: p.id, 
                roleID: res.data.updateRole.id
              }})),
              ...permissionsToRemove.map(p => deleteRolePermission({input: {
                id: p.id, 
              }})),
            ]).then(res2 => {
              if (res2) {
                setMode(ACTIONS.VIEW)
                refetchRoleData({id: id})
                navigate(pages["Role Management/:id"].href.replace(":id", res?.data?.updateRole?.id))
              }
            })
          }
      })
      }
    }
  })

  const { values, errors } = formik

  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageHeader
          pageTitle={[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <>
              <TextInput
                style={{width: "260px"}}
                labelText="Role Name"
                defaultValue={values?.name}
                name="name"
                onChange={(e => formik.setFieldValue("name", e.target.value))}
                hasError={errors?.['name']}
                errorMessage={"The role name is required."}
            />
            </> : values?.name
          }
          titleTools={[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ?
            <ButtonWrapper>
              <Button type="primary" onClick={() => {
                formik.handleSubmit()
              }
                }>
                Save
              </Button>
              <Button type="secondary" onClick={() => {
                if (mode === ACTIONS.EDIT) {
                  setMode(ACTIONS.VIEW)
                  formik.setFieldValue("name", roleData.name)
                  formik.setFieldValue("appId", roleData.appId )
                  formik.setFieldValue("type", roleData.type )
                  formik.setFieldValue("description", roleData.description )
                  formik.setFieldValue("tasks", JSON.stringify(roleData?.tasks || "") )
                  const permissions = roleData?.permissions?.items?.map(i => ({...i.permission}))
                  formik.setFieldValue("selectedPermissions", permissions )
                  formik.setFieldValue("resourceTypes", JSON.stringify(roleData?.resourceTypes || "") )
                  formik.setFieldValue("selectedUserTypes", roleData?.userTypes?.map(e => ({id: e})) || [])
                  formik.setFieldValue("selectedRoleAssignedRoles", roleData?.allowedRoles?.map(e => ({id: e})) || [])
                } else { 
                  navigate(pages["Role Management"].href)
                }
              }}>
                Cancel
              </Button>
          </ButtonWrapper>
           : <IconSvg
              name={"edit"}
              margin="0px 0px 0px 10px"
              color="#666"
              hover="#666"
              width="15px"
              onClick={() => {
                setMode(ACTIONS.EDIT)
              }}
            />
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <div>Application: </div>
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <Select
        placeholder={"- Select Application -"}
        selectedItem={applicationList?.filter(i => i.id === values?.appId)?.length ? applicationList.find(i => i.id === values?.appId).name : values.appId }
        onChange={(value) => {
          formik.setFieldValue("selectedPermissions", [])
          formik.setFieldValue(
            'appId',
            value
          )
        }}
        options={applicationList?.map(app => ({
          key: app.id,
          value: app.name
        }) || []
        )}
      /> 
      : applicationList?.filter(i => i.id === values?.appId)?.length ? applicationList.find(i => i.id === values?.appId).name : values.appId }
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      <div>Role tasks in array format: </div>
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <TextInput
                style={{width: "260px"}}
                defaultValue={values?.tasks}
                onChange={(e => formik.setFieldValue("tasks", e.target.value))}
                isRequired={false}
                hasError={false}
            />
      : values.tasks }
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <div>Resource types in array format: </div>
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <TextInput
                style={{width: "260px"}}
                defaultValue={values?.resourceTypes}
                onChange={(e => formik.setFieldValue("resourceTypes", e.target.value))}
                isRequired={false}
                hasError={false}
            />
      : values.resourceTypes }
      
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      <div>Role description: </div>
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <TextInput
                style={{width: "260px"}}
                defaultValue={values?.description}
                onChange={(e => formik.setFieldValue("description", e.target.value))}
                isRequired={false}
                hasError={false}
            />
      : values.description }
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {
        [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <div>
        <SelectUserType
          key={`userTypeSelector`}
          initialValues={values?.selectedUserTypes}
          onButtonClick={(e) => {
            const newSelectedUserTypes = [...e]
            formik.setFieldValue("selectedUserTypes", newSelectedUserTypes )
          }}
          title={"Role User Types"}
          description={"Select one or more user types for this role."}
          sideText={<></>}
          buttonTitle={"Add"}
        />
        </div>
        : ""
      }
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {
        [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <div>
        <SelectedUserRoles
          key={`userRoleSelector`}
          initialValues={values?.selectedRoleAssignedRoles}
          applicationData={applicationData}
          onButtonClick={(e) => {
            const newSelectedRoleAssignedRoles = [...e]
            formik.setFieldValue("selectedRoleAssignedRoles", newSelectedRoleAssignedRoles )
          }}
          title={"Role Accesses"}
          description={"Select user roles this role has access to grant/view."}
          sideText={<></>}
          buttonTitle={"Add"}
        />
        </div>
        : ""
      }
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {
        [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <div>
        <SelectPermissions
          key={`permissions-${values.appId}-${values.type}`}
          initialValues={values?.selectedPermissions}
          graphQLInputs={{id: values?.appId}}
          onButtonClick={(e) => {
            const newSelectedPermissions = [...e]
            formik.setFieldValue("selectedPermissions", newSelectedPermissions )
          }}
          title={"Add Permissions to Role"}
          description={"Select one or more permissions to add to this role. Note: permissions are based on application and role type."}
          sideText={<>Create new permissions in <Link to="not configured">Permission Setup</Link></>}
          buttonTitle={"Add"}
        />
        </div>
        : ""
      }
      {values.selectedPermissions && <ClientSideTable
        key={`${mode}table${values?.selectedPermissions?.length}`}
        header={headings}
        rows={values.selectedPermissions}
        rowControl={mode === ACTIONS.EDIT ? [
          {text: "Delete", action: (data) => {
            const indexToRemove = values.selectedPermissions.findIndex(e => e.id === data.id)
            formik.setFieldValue("selectedPermissions", values.selectedPermissions.filter((e, k) => k !== indexToRemove))
          }}
      ] : undefined}
      /> }
      </Content>
    </Container>
  )
}
