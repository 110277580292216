import { useState, useEffect } from 'react'
import { GET_ALL_COUNTRIES, GET_ALL_STATE_BY_COUNTRY } from '../graphql/queries'
import { useQuery } from 'src/hooks/APIHooks'
import CommonDetails from './EditableGeoLocation'
import { parsePlace, parseRegionCode } from '../helpers'

export const EditableGeoLocationWithData = ({
  regions,
  selectedCountryCode,
  pageName,
  setValues,
  values,
  ...props
}) => {
  const [selectedCountryRegions, setSelectedCountryRegions] = useState(
    regions?.[selectedCountryCode] || []
  )
  const [isEmptyRegions, setisEmptyRegions] = useState(false)
  useQuery({
    query: GET_ALL_COUNTRIES,
    dataPath: 'data.configByType.items',
    disableInitialLoad: props.countries.length > 0,
    onSuccess: (data) => {
      try{
        const countries = data.map((country, index) => ({
          countryId: index,
          countryName: parsePlace(country.value),
          countryCode: country.name,
          countryDescription: parsePlace(country.value)
        }))
        props.setCountries(countries)
      } catch(error) {
        console.log(error)
      }
     
    }
  })

  useEffect(() => {
    setSelectedCountryRegions(regions?.[selectedCountryCode] || [])
  }, [regions, selectedCountryCode])

  const { refetch: refetchRegionsByCountry } = useQuery({
    query: GET_ALL_STATE_BY_COUNTRY,
    dataPath: 'data.configByTypeAndSubtype.items',
    disableInitialLoad: true,
    onSuccess: (data) => {
      const regions = data.map((region, index) => ({
        regionId: index,
        regionName: parsePlace(region.value),
        regionCode: parseRegionCode(region.name),
        countryCode: region.type
      }))
      setisEmptyRegions(!regions.length)
      if (regions && !values?.address?.regionFullName) {
        const regionNameIndex = regions.findIndex(e => e.regionCode === values?.address?.region)
        if (regionNameIndex !== -1) {
          setValues({
            ...values,
            address: {
              ...values?.address || {},
              regionFullName: regions[regionNameIndex]?.regionName
            }
          })
        }
      } else if (regions && values?.address?.regionFullName && !values?.address?.region) {
        const regionCodeIndex = regions.findIndex(e => e.regionName === values?.address?.regionFullName)
        if (regionCodeIndex !== -1) {
          setValues({
            ...values,
            address: {
              ...values?.address || {},
              region: regions[regionCodeIndex]?.regionCode
            }
          })
        }
      }
      regions.length > 0 &&
        props.setRegions((_regions) => ({
          ..._regions,
          [regions[0].countryCode]: regions
        }))
      
    }
  })

  const updateRegions = async (type) => {
    setisEmptyRegions(false)
    if (type) {
      props.setSelectedCountryCode(type)
      const cachedRegions = regions?.[type]
      if (cachedRegions) {
        setSelectedCountryRegions(cachedRegions)
        setisEmptyRegions(!cachedRegions.length)
        if (!values?.address?.regionFullName) {
          const regionNameIndex = cachedRegions.findIndex(e => e.regionCode === values?.address?.region)
          if (regionNameIndex !== -1) {
            setValues({
              ...values,
              address: {
                ...values?.address || {},
                regionFullName: cachedRegions[regionNameIndex]?.regionName
              }
            })
          }
        } else if (values?.address?.regionFullName && !values?.address?.region) {
          const regionCodeIndex = cachedRegions.findIndex(e => e.regionName === values?.address?.regionFullName)
          if (regionCodeIndex !== -1) {
            setValues({
              ...values,
              address: {
                ...values?.address || {},
                region: cachedRegions[regionCodeIndex]?.regionCode
              }
            })
          }
        }
      } else {
        await refetchRegionsByCountry({ type })
      }
    }
  }

  return (
    <CommonDetails
      pageName={pageName}
      isEmptyRegions={isEmptyRegions}
      updateRegions={updateRegions}
      {...props}
      countries={props.countries}
      setValues={(e) => setValues(e)}
      values={values}
      regions={selectedCountryRegions || []}
    />
  )
}
