import BaseWrapper from '../../../chart-library/CommonComponents/BaseWrapper'
import Svg from '../../../chart-library/CommonComponents/Svg'
import { Timeline as TimelineLibraryComp } from '../../../chart-library/CommonComponents/Timeline'
import { useConfig } from './useConfig'

const options = {
  xAxis: {
    type: 'scaleTime'
  },
  grid: [{ left: 0, right: 0, top: 0, bottom: 0 }]
}

export default function Timeline({ children, ...props }) {
  return (
    <BaseWrapper options={options} className="ec-timeline-component-max-height">
      <Svg>
        <TimelineComp {...props}>{children}</TimelineComp>
      </Svg>
    </BaseWrapper>
  )
}

function TimelineComp({ children, ...props }) {
  const { isInitialized, canvas } = useConfig()

  if (!isInitialized) {
    return null
  }

  return (
    <TimelineLibraryComp y={canvas.y} {...(props as any)}>
      {children}
    </TimelineLibraryComp>
  )
}
