
export const initialState: any = {
  chartInstanceId: '',
  svgDimensions: {},
  dataLastUpdatedAt: '',
  axisCanvas: {},
  canvasDetails: null,
  canvas: null,
  seriesData: {},
  seriesTypes: [],
  axes: [],
  generatedScales: {},
  mousePosition: null,
  tooltipDetails: null,
  internalLegend: {
    isInternal: false,
    hiddenLegends: {}
  },

}