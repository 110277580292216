import { StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

export const costSavingStyles = StyleSheet.create({
	wrapper: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		marginTop: 10,
		marginBottom: 10,
	},
	costSaving: {
		flex: 1,
		flexBasis: "25%",
		paddingTop: 2,
		paddingBottom: 1,
		paddingHorizontal: 7,
		fontSize: 10,
		lineHeight: 1.4,
		borderLeft: `1 solid ${colors.GREY_6}`,
	},
	potentialCostSaving: {
		width: "24%",
		paddingTop: 2,
		paddingBottom: 1,
		paddingHorizontal: 7,
		fontSize: 10,
		lineHeight: 1.4,
		borderLeft: `1 solid ${colors.GREY_6}`,
	},
	costSavingLabel: {
		fontSize: 12,
	},
	costSavingValue: {
		fontSize: 12,
		fontWeight: "bold",
		color: colors.BLACK,
	},
	costSavingPeriod: {
		fontStyle: "italic",
		color: colors.TBA_DARK_GREY,
	},
	costSavingDate: {
		fontWeight: "bold",
		color: colors.TBA_DARK_GREY,
	},
})
