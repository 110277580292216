import styled from 'styled-components'
import COLORS from 'src/components/legacy/common/colors.json'
import { TreeView } from "src/components/legacy/components/tree-view-sc"
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import { DATA_SOURCE_TYPES } from '../constants'

export const StyledTree = styled(TreeView)`
  overflow: auto;
  width: auto;
`

export const StyledSpinner = styled.div`
 position:relative;
 left:50%;
 margin-top:20px;
`

export const DataSourceEditableView = styled(EditableView)`
  .edit-view__field {
    height: auto;
  }
`

export const PointStatusStyled = styled.div`
  border-radius: 50%;
  background: ${({ color }) => {
    switch (color) {
      case "Gray":
        return COLORS.GREY
      case "Yellow":
        return COLORS.YELLOW
      case "Green":
        return COLORS.GREEN_5
      case "Purple":
        return COLORS.PURPLE
      case "Orange":
        return COLORS.ORANGE
      default:
        return color
    }
  }};
  margin-right: 10px;
  height: 16px;
  width: 16px;
`

export const LabelFormat = props => {
  return <div style={{ display: "flex", alignItems: "center" }}>
    {
      !props.treeChildren?.length && (
        <PointStatusStyled color={props.color} />
      )
    }
    <div>{props.label}</div>
  </div>
}

export const colorFromDataSourceType = {
  [DATA_SOURCE_TYPES.ANALOG]: "Purple",
  [DATA_SOURCE_TYPES.BINARY]: "Green",
  [DATA_SOURCE_TYPES.MULTISTATE]: "Orange",
}