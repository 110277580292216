import { min, max } from 'lodash'
import * as d3 from 'd3'

export const getBarDataCategory = (data) => {
  return data?.map((m) => m?.name)
}

export const getMinValue = (data) => {
  return min(data?.map((m) => m?.value ?? 0))
}

export const getMaxValue = (data) => {
  return max(data?.map((m) => m?.value ?? 0))
}

export const getGroupBarMaxValue = (data) => {
  const values = data?.map(({ values }) => values?.map((m) => m?.value ?? 0)).flat()
  return max(values)
}

export const getGroupBarMinValue = (data) => {
  const values = data?.map(({ values }) => values?.map((m) => m?.value ?? 0)).flat()
  return min(values)
}
export const getAreaRangeMaxValue = (data) => {
  const values = data?.map(({ values }) => values?.map((m) => m ?? 0)).flat()
  return max(values)
}

export const getAreaRangeMinValue = (data) => {
  const values = data?.map(({ values }) => values?.map((m) => m ?? 0)).flat()
  return min(values)
}
export const getElementKey = () => {
  return Math.floor(Math.random() * 10000)
}

export const getAreaPoints = (xScale, yScale, data) => {
  const area = d3
    .area()
    .x(function (d: any) {
      return xScale(d.x)
    })
    .y0(function (d: any) {
      return yScale(d.values[0] ?? 0)
    })
    .y1(function (d: any) {
      return yScale(d.values[1] ?? 0)
    })
  return area(data)
}

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
}