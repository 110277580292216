import React, { useEffect, useState } from 'react'
import Table from 'src/components/Table/clientSideTable'
import {
  listWidgetsByBuilding,
  listWidgetsOrganization
} from 'src/pages/widgets/graphql'
import moment from 'moment'
import { useWidgetsContext } from 'src/pages/widgets/WidgetsContextProvider'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import WidgetTemplate from 'src/pages/widgets/widget-template'
import { WidgetsIcons, getLocationOrOrganizationIdByRedux } from 'src/pages/widgets/helpers'
import { StyledText, WidgetListWrappers } from './style'
import { TargetTypeNames } from 'src/pages/widgets/helpers'
import { CREATE_DASHBOARD_WIDGET } from '../../graphql'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setDashboardWidgetAddedState } from 'src/redux/slicers/dashboard'
import WidgetsTileList from 'src/pages/widgets/WidgetTileView/WidgetsTileList'
import { WidgetPreviewModal } from '../../../widgets/WidgetPreviewModal/index'
import _sortBy from 'lodash/sortBy'
import _uniqBy from 'lodash/uniqBy'
import { getWidgetType, widgetListFilteringOptionList } from '../../../widgets/helpers'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { WIDGET_TYPES } from 'src/constants'
import translate, { TranslateComponent } from 'src/common/translations'
import {
  selectBuilding
} from 'src/redux/slicers/buildingPicker'


const AddWidgetsList = (props) => {
  const { isTileView, handleClose } = props
  const [rows, setRows] = useState([])  
  const selected = useSelector(selectBuilding) || null
  const [buildingId, setBuildingId] = useState(null)
  const [organizationId, setOrganizationId] = useState(null)
  const [filterList, setFilterList] = useState([])
  const {
    reloadEvents,
    setReloadEvents,
    setMode,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setWidgetDetails,
    setModalType,
    setIsLoading,
    setOpenWidgetPicker,
    setWidgetBuilding,
    setHandleFormSubmit,
    setWidgetBuildingDetails,
    showWidgetPreview,
    setShowWidgetPreview,
    selectedWidgetDetails,
    setSelectedWidgetDetails
  } = useWidgetsContext()
  const [t] = useTranslation()
  const optionsTranslated = {
    preview: translate("PREVIEW"),
    add: translate("Add"),
    widName: translate('Widget Name + Targeted Object/Type'),
  }
  const [error, setError] = useState(null)
  const handleWidgetInitialize = () => {
    setMode('')
    setIsLoading(false)
    setIsOpenCreateWidget(false)
    setOpenWidgetPicker(false)
    setWidgetBuilding(null)
    setSelectedWidget(null)
    setWidgetDetails(null)
    setModalType('')
    setHandleFormSubmit(false)
    setWidgetDetails(null)
    setWidgetBuildingDetails(null)
  }

  const widgetListStatic =[
    {originalName:"Time Comparison Bar Chart",  transName: 'singleComparisonBarWidget', widgetType: "single-comparison-bar" },
    {originalName:"Energy Cost",  transName: 'energyCostWidget', widgetType: "energy-cost" },
    {originalName:"Energy Consumption",  transName: 'energyConsumptionNewWidget', widgetType: "energy-consumption-new" },
    {originalName:"Energy Demand",  transName: 'energyDemandWidget', widgetType: "energy-demand" },
    {originalName:"Data Comparison Bar Chart",  transName: 'dualComparisonBarWidget', widgetType: "dual-comparison-bar" },
    {originalName:"Baseline Comparison",  transName: 'energyConsumptionWidget', widgetType: "energy-consumption" },
    {originalName:"Data Monitoring Gauge",  transName: 'gaugeWidget', widgetType: "gauge" },
    {originalName:"Data Monitoring Orb",  transName: 'orbWidget', widgetType: "orb" },
    {originalName:"Image/Text Link",  transName: 'imageTextWidget', widgetType: "image-text" },
    {originalName:"Weather",  transName: 'weatherWidget', widgetType: "weather" },
    {originalName:"Trends Bar Chart",  transName: 'barWidget', widgetType: "bar" },
    {originalName:"Trends Line Chart",  transName: 'lineWidget', widgetType: "line" },
    {originalName:"Custom KPI",  transName: 'customKPIWidget', widgetType: "custom-kpi" },
    {originalName:"Energy Star Score",  transName: 'energyStarScoreWidget', widgetType: "energy-star-score" }
  ]

  const {
    loading: isWidgetLoading,
    data: WidgetsList,
    refetch: refetchBuildingsList,
    responseTime
  } = useQuery(
    !buildingId
      ? {
          query: listWidgetsOrganization,
          dataPath: 'data.listWidgetsByAccount.items',
          variables: { accountId: organizationId },
          errorPolicy: 'ignore',
          disableInitialLoad: true,
          onSuccess: () => {
            handleWidgetInitialize()
          },
          onError: (error) => {
            setError(error)
            handleWidgetInitialize()
          }
        }
      : {
          query: listWidgetsByBuilding,
          dataPath: 'data.listWidgetsByBuilding.items',
          variables: { buildingId },
          disableInitialLoad: true,
          onSuccess: () => {
            handleWidgetInitialize
          },
          onError: (error) => {
            setError(error)
            handleWidgetInitialize()
          }
        }
  )

  const params = useParams()
  const dispatch = useDispatch()

  const { onSubmit: createDashboardWidget, loading } = useMutation({
    query: CREATE_DASHBOARD_WIDGET,
    dataPath: 'data.createDashboardWidget'
  })

  useEffect(() => {
    if(selected){
      const { buildingId, organizationId } = getLocationOrOrganizationIdByRedux(selected)
      setBuildingId(buildingId)
      setOrganizationId(organizationId)
    }
  }, [selected])
  

  useEffect(() => {
    if (loading) {
      dispatch(setDashboardWidgetAddedState({ loading }))
      handleClose()
    }
  }, [loading])

  useEffect(() => {
    if (buildingId) {
      refetchBuildingsList({ buildingId })
    } else if (organizationId) {
      refetchBuildingsList({ accountId: organizationId })
    }
  }, [buildingId, organizationId])

  useEffect(() => {
    if (reloadEvents) {
      setReloadEvents(false)
      if (buildingId) {
        refetchBuildingsList({ buildingId })
      } else if (organizationId) {
        refetchBuildingsList({ accountId: organizationId })
      }
    }
  }, [reloadEvents])

  useEffect(() => {
    const widgetTypesList = !_isEmpty(WidgetsList)
    ? WidgetsList?.map((widget) => ({
          name: widget?.widgetType,
          value: widget?.widgetType
      }))
    : []

      const uniqueTypeList = _uniqBy(widgetTypesList,"name")?.map((x) => {
        return {
          name: x?.name,
          value: x?.name
        }
      })
      setFilterList(uniqueTypeList)
      const sortedRows =   !_isEmpty(WidgetsList) ? WidgetsList?.filter(x => x?.widgetType !== WIDGET_TYPES.MULTIPLE_LINES).map((widget) => ({
        ...widget,
        type: getWidgetType(t, widget?.widgetType),
        creationDate: widget?.creationDate
          ? moment(widget?.creationDate)?.format('YYYY-MM-DD')
          : '',
        preview: optionsTranslated.preview
      }))?.sort((a, b) => {
          return b["name"]?.localeCompare(a["name"], undefined, {
            sensitivity: 'base',
            numeric: true
          })
        }
      ) : []
    setRows(
      sortedRows
    )
  }, [WidgetsList])

  const getType = (type) => {
    if (TargetTypeNames?.hasOwnProperty(type)) {
      return TargetTypeNames[type]
    } else {
      return { icon: 'other', name: type }
    }
  }

  const handleAddWidget = async (widgetID) => {
    const response = await createDashboardWidget({ input: { dashboardID: params?.id, widgetID } }).then(()=>{
      dispatch(setDashboardWidgetAddedState({ loading:true }))
      trackEvent(USER_EVENTS.DASHBOARDS.events.ADD_WIDGET, {"widget id": widgetID})
    });
  }

  const toolbar = (widgetID) => (
    <button
      className="primary"
      onClick={() => {
        handleAddWidget(widgetID)
      }}
    >
      {optionsTranslated.add}
    </button>
  )

  const nameHeadings = [
    {
      title: '',
      key: '',
      alignRight: false,
      disabled: true,
      customComponent: ({ id }) => {
        return <>{toolbar(id)}</>
      }
    },
    {
      title: optionsTranslated.widName,
      key: 'name',
      disabled: true,
      customComponent: (data) => {
        const widgetIcon = WidgetsIcons[data?.widgetType]
        const targetIcon = getType(data?.widgetTargetType)?.icon
        const { building, widgeEquipment1Name, widgeEquipment2Name } =
          data || {}
        return (
          <>
            {data?.name && (
              <div className="custom-component">
                <span className={`icon ${widgetIcon}`}></span>
                <span className="data-span">{data?.name}</span>

                {!(
                  widgeEquipment1Name || widgeEquipment2Name
                ) &&
                  building?.name && (
                    <StyledText className={`data-span icon icon-${targetIcon}`}>
                      {' '}
                      {building?.name}{' '}
                    </StyledText>
                  )}
                {widgeEquipment1Name && (
                  <StyledText
                    className={`icon icon-${
                      getType(data?.widgeEquipment1Type)?.icon
                    }`}
                  >
                    {widgeEquipment1Name}
                  </StyledText>
                )}
                {widgeEquipment2Name && (
                  <StyledText
                    className={`icon icon-${
                      getType(data?.widgeEquipment2Type)?.icon
                    }`}
                  >
                    {widgeEquipment2Name}
                  </StyledText>
                )}
              </div>
            )}
          </>
        )
      }
    },
    {
      title: '',
      disabled: true,
      maxWidth: '150px',
      customComponent: (row) => {
        return (
          <>
            <span className="icon icon-infocircle tooltip-container">
              <div className="tooltip center-top"><TranslateComponent>{row.description}</TranslateComponent></div>
            </span>
          </>
        )
      }
    },
    {
      title: '',
      key: 'preview',
      disabled: false,
      onDataClick: (data) => {
        setShowWidgetPreview(true)
        setSelectedWidgetDetails(data)
      }
    }
  ]

  const filtersToApply = WidgetTemplate?.getSelectBoxList(t)
  return (
    <>
      <WidgetListWrappers>
        {isWidgetLoading ? (
          <div className="loader">
            <span className="spinner" />
          </div>
        ) : (
          <>
            {rows?.length === 0 && (
              <h2>
                <TranslateComponent> There are no widgets.</TranslateComponent>
                <br /> <TranslateComponent>Click the</TranslateComponent>{' '}
                <span
                  className="action"
                  onClick={() => props?.handleAddNewWidget()}
                >
                <TranslateComponent>  Create new widget</TranslateComponent>
                </span>{' '}
                <TranslateComponent>button to create one now.</TranslateComponent>
              </h2>
            )}
            {rows.length > 0 && !isTileView && (
              <Table
                isShowPagination={true}
                key={`WidgetsTable-${organizationId} ${buildingId}`}
                rows={rows}
                header={nameHeadings}
                loadTime={responseTime}
                search={true}
                searchFields={['name', 'widgetTarget']}
                isFilterChange={true}
                isGap={true}
                filtersList={[
                  {
                    key: 'type',
                    filterName: 'widgetTypeFilter',
                    id: 'widgetTypeSelectorFilter',
                    defaultLabel: 'All',
                    selectedValue: 'default',
                    options: widgetListFilteringOptionList(t, filterList, widgetListStatic)
                  }
                ]}
              />
            )}
            {rows?.length > 0 && isTileView && (
              <WidgetsTileList
                WidgetsList={rows}
                isDashboardWidgetsTile={true}
                handleAddWidget={handleAddWidget}
              />
            )}
            {showWidgetPreview && (
              <WidgetPreviewModal widgetDetails={selectedWidgetDetails} />
            )}
          </>
        )}
      </WidgetListWrappers>
    </>
  )
}

export default React.memo(AddWidgetsList)
