import PortfolioScorecardContainer from './PortfolioScorecardContainer'
import { PortfolioScorecardContextProvider } from './PortfolioScorecardContextProvider'

export const PortfolioScorecard = () => {
  return (
    <PortfolioScorecardContextProvider>
      <PortfolioScorecardContainer />
    </PortfolioScorecardContextProvider>
  )
}
