// Default Options.


import { min,max } from "lodash";
import { getAreaRangeMaxValue, getAreaRangeMinValue, getBarDataCategory, getGroupBarMaxValue, getGroupBarMinValue, getMaxValue, getMinValue } from "../../../../../../chart-library/CommonComponents/Bar/helper";
import { SOLID_LINE } from "../../../../../../chart-library/CommonComponents/LinePath/constants";
import _isEmpty from 'lodash/isEmpty'

// Developer can set min & max / category data later, once it receives from API using setDomian
export function useOptions(barData): Options {
  const data = barData?.groupBarData?.length > 0 ? barData?.groupBarData : barData?.barData
  const maxValue = barData?.groupBarData?.length > 0 ? getGroupBarMaxValue(data) ?? 0 : getMaxValue(data) ?? 0
  const minValue = barData?.groupBarData?.length > 0 ? getGroupBarMinValue(data) ?? 0 : getMinValue(data) ?? 0
  const areaRangeMaxValue = getAreaRangeMaxValue(barData?.areaData ?? []) ?? 0
  const areaRangeMinValue = getAreaRangeMinValue(barData?.areaData ?? []) ?? 0
  const plotLineData = barData?.plotLineData
  const plotLineValue = Number(plotLineData?.plotLineValue ?? 0)
  const areaChartData = barData?.areaData ?? []
  const plotLineDetails = plotLineData && {
    "stroke": plotLineData?.plotLineColor,
    "strokeWidth": 5,
    "value": plotLineValue,
    "dashType": SOLID_LINE,
    "name": !_isEmpty(plotLineData?.plotLableName) ? plotLineData?.plotLableName : 'Benchmark',
    text:{
      hide: plotLineData?.plotHeaderLabelHide ?? true
    }
  }
  return {
    xAxis: [{
      type: "scaleBand",
      data: getBarDataCategory(data)
    },{
      type: "scaleLinear",
      min: areaChartData?.length ? min(areaChartData?.map(a => a?.x)) : 0,
      max: areaChartData?.length ? max(areaChartData?.map(a => a?.x)) : 0,
    }],    
    yAxis: [{
      type: "scaleLinear",
      min: minValue > 0 ? 0 : minValue,
      max: plotLineValue > maxValue ? plotLineValue : maxValue > 0 ? maxValue : 100,
    },{
      type: "scaleLinear",
      min: areaRangeMinValue > 0 ? 0 : areaRangeMinValue,
      max: areaRangeMaxValue > 0 ? areaRangeMaxValue : 100,
    }],
    plotAxisType: "y",
    plotLineConfig: plotLineDetails,
    grid: [{ left: "60px", right: "0px", top: "20px", bottom: "20px" }]
  };
}
