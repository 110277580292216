import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { getField } from 'src/components/legacy/common/helpers'
import app from './app.js'

const DEFAULT_PRIORITY = 'Not Prioritized'
const DEFAULT_ISSUE_FOUND = 'OEAssessment'
const DEFAULT_STATUS = 'recommended'
const DEFAULT_NEXT_STEP = 'buildingPersonnelReview'
const DEFAULT_VISIBILITY_TO_CUSTOMER = true

const KPI_SORT_ORDER = new Map([
  ['energyUsage', 1],
  ['performance', 2],
  ['reliability', 3],
  ['comfort', 4],
  ['compliance', 5]
])

// KPI_LIST and KPI_LEVEL_LIST are coming from common/opportunity. Shall decide to place at a common file
const KPI_LEVEL_LIST = ['high', 'medium', 'low']
const KPI_LIST = [
  { name: 'energyUsage', value: false, priority: KPI_LEVEL_LIST[2] },
  { name: 'comfort', value: false, priority: KPI_LEVEL_LIST[2] },
  { name: 'reliability', value: false, priority: KPI_LEVEL_LIST[2] },
  { name: 'performance', value: false, priority: KPI_LEVEL_LIST[2] },
  { name: 'compliance', value: false, priority: KPI_LEVEL_LIST[2] },
  { name: '', value: false, priority: KPI_LEVEL_LIST[2], custom: 1 },
  { name: '', value: false, priority: KPI_LEVEL_LIST[2], custom: 2 }
]

const kpiOrder = (a, b) =>
  KPI_SORT_ORDER.get(a.name) - KPI_SORT_ORDER.get(b.name)

const DEFAULT_SETTINGS = {
  kpi: KPI_LIST.map(({ name, priority, custom }) => ({
    name,
    priority,
    value: false,
    custom
  })),
  description: {
    blocks: [],
    entityMap: {}
  },
  images: [],
  file: {},
  chartLink: '',
  descriptionLink: ''
}

class Findings {
  constructor(resource = {}) {
    const userCredentials =
      app && app.userInfo
        ? {
            firstName: app.userInfo.firstName,
            lastName: app.userInfo.lastName
          }
        : {}

    const {
      findingId,
      name = '',
      status = DEFAULT_STATUS,
      priority = DEFAULT_PRIORITY,
      nextStep = DEFAULT_NEXT_STEP,
      proposedBy,
      proposalDate,
      creationDate,
      changeAuthor = false,
      createdBy = userCredentials,
      assignedTo = {},
      foundDuring = DEFAULT_ISSUE_FOUND,
      opportunities = [],
      visibleToCustomer = DEFAULT_VISIBILITY_TO_CUSTOMER,
      settings,
      organizationId,
      organizationName,
      locationId,
      locationName,
      comment,
      tisObjects = []
    } = resource

    this.findingId = findingId
    this.name = name
    this.status = status
    this.priority = priority
    this.nextStep = nextStep
    this.creationDate = creationDate
    this.createdBy = changeAuthor ? userCredentials : createdBy
    this.assignedTo = assignedTo
    this.foundDuring = foundDuring
    this.opportunities = opportunities
    this.comment = comment
    this.visibleToCustomer = Boolean(visibleToCustomer)

    DEFAULT_SETTINGS.proposedBy = proposedBy
    DEFAULT_SETTINGS.proposalDate = proposalDate

    this.settings = {
      ...DEFAULT_SETTINGS,
      ...pickBy(settings, identity)
    }

    this.settings.kpi = [...this.settings.kpi].sort(kpiOrder)

    this.organization = {
      organizationId:
        getField(resource, 'organization.organizationId') || organizationId,
      organizationName:
        getField(resource, 'organization.organizationName') || organizationName
    }
    this.location = {
      locationId: getField(resource, 'location.locationId') || locationId,
      locationName: getField(resource, 'location.locationName') || locationName,
      tisTraneOfficeId: getField(resource, 'location.tisTraneOfficeId')
    }
    this.tisObjects = tisObjects
  }

  get organizationId() {
    return this.organization.organizationId
  }
  set organizationId(id) {
    this.organization.organizationId = id
  }
}

export default Findings
