import styled from "styled-components"

export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled.span`
  padding-top: 24px;
`

export const SeasonSelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
`

export const SeasonDateWrapper = styled.div`
  margin-top: 6px;
  margin-right: 20px;
  display: flex;
  vertical-align: middle;
  align-items: center;
`

export const CheckboxContainer = styled.div`
  width: 15%;
  align-self: flex-start;
  margin: 10px 0px;
`

export const DateDL = styled.dl`
  display: flex;
  flex-flow: wrap;
  width: 35em;
  margin: 0px 1em;
`

export const DateDT = styled.dt`
  flex-basis: 20%;
  padding: 2px 4px;
  font-weight: bold;
  text-transform: capitalize;
`

export const DateDD = styled.dd`
  flex-basis: 70%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin: 0px;
  padding: 2px 4px;
`

export const TimeOfUseDesc = styled.div`
  width: 50%;
`
export const CustomLabelWrapper = styled.div`
& .modal-text-wrapper .custom-label{
  padding: 0px !important;
    }
`