
export const GET_BUILDINGS_BY_ACCOUNTS = `query GET_BUILDINGS_BY_ACCOUNTS($id: ID!) {
  listBuildingsByAccount(accountId: $id,limit:200) {
    items {
    accountId
    id
    name
    crmCustId
        }
    }
  }
`
export const SEARCH_BUILDINGS = `query SEARCH_BUILDINGS(
  $filter: SearchableBuildingFilterInput
  $limit: Int
  $from: Int
  $nextToken: String
  $sort: [SearchableBuildingSortInput]
) {
  searchBuildings(
    filter: $filter
    limit: $limit
    from: $from
    nextToken: $nextToken
    sort: $sort
  ) {
    items {
      id
      name
      crmCustId
      address
    }
    nextToken
  }
}
`




export const CREATE_CONTRACT = `
mutation CREATE_CONTRACT(
  $input: CreateContractInput!
) {
  createContract(input: $input) {
    id
  }
}
`

export const UPDATE_CONTRACT = `
mutation UPDATE_CONTRACT(
  $input: UpdateContractInput!
) {
  updateContract(input: $input) {
    id
  }
}
`

export const UPDATE_OFFERING = `
mutation UPDATE_OFFERING(
  $input: UpdateOfferingInput!
) {
updateOffering(input: $input) {
  id
}
}
`

export const DELETE_OFFERING = `
mutation DELETE_OFFERING(
  $input: DeleteOfferingInput!
) {
deleteOffering(input: $input) {
  id
}
}
`


export const CREATE_OFFERING = `
mutation CREATE_OFFERING(
  $input: CreateOfferingInput!
) {
  createOffering(input: $input) {
    id
  }
}
`

export const GET_OFFERINGS_BY_BUILDING_func = ids => `
query listOfferingsByBuilding{
  ${ids.map((id,key) => `b${key}:listOfferingsByBuilding(buildingId: "${id}",filter: {status: {ne: Deleted}}, limit: 1000) {
    items {
      code
      id
      name: subscriptionCode
      buildingId
    }
  }`)}
}`


