import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'
const screen_max_extra_small = '633px'
export const FormWrapper = styled.section<{ width: number }>`
  input,
  textarea {
    width: 100%;
  }
  
  legend{
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.26px;
  }

  legend.invalid {
    color: ${colors.RED};
  }
  .buttons-group {
    display: flex;
    justify-content: flex-end;
    margin: 0 -3px 0 auto;
    flex-wrap: wrap;
    > button {
      min-width: 95px;
      margin: 3px;
    }
    ${(props) =>
      props.width < 599 &&
      `width:202px;
			button:first-child {
				flex: 0 1 50%;
			}
			`}
  }
`
export const ErrorText = styled.label`
   {
    color: ${colors.RED};
    font-size: 10px;
    font-style: italic;
    font-weight: bold;
    height: 16px;
    letter-spacing: 0.2px;
    line-height: 16px;
    width: 100%;
  }
`
export const QuickAddWrapper = styled.div<{ width: number }>`
  .toggle-box-container {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .toggle-box-body {
      width: 100%;
      background-color: #fff;
      border: 1px solid #ccc;
      border-top: none;
      border-radius: 0 0 8px 8px;
    }
    .toggle-box-label {
      background-color: #fff;
      padding: 0;
      width: 250px;
      height: 40px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      text-align: center;
      border-radius: 8px 8px 0 0;
      color: black;
      border: 1px solid #ccc;
      border-bottom: none;
      > .label {
        border-right: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 1px;
      }
      > .icon-expand {
        padding: 13px;
        margin: 0;
      }
      &:not(.expanded) {
        .toggle-box-body {
          border: none;
        }
      }
      ${(props) =>
        props.width < 599 &&
        `margin-top:70px;
                width:100%;
                border: 1px solid grey;
                                > .label {
                                    padding: 11px;
                                    margin-bottom: 0;
                                }
            `}
    }
  }
`

export const HeaderWrapper = styled.div`
.header-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    
    hr {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 0;
    }

    .toolbar-buttons {
        max-width: 300px;
        margin-right:260px;
        a:first-child {
            margin-right: 10px;
        }
        button, a {
            line-height: 10px;
        }

        @include screen-medium {
            margin-right: 350px;
        }
    }
`

export const KPIEditWrapper = styled.section`
  margin: auto 10px;
`

export const EditableFieldWrapper = styled.div`
  && .editable-field-wrapper {
    position: relative;
    display: block;
    width: 100%;
    min-height: 26px;

    & .edit-icon-style {
      padding-left: 5px;
      padding-top: 2px;
      font-weight: normal !important;
    }

    &.read-only & .editable-field {
      display: inline-block;
      position: relative;
    }

    &:not(.read-only) & .editable-field {
      position: absolute;
      top: -5px;
      left: -5px;
      padding: 4px;
      border-radius: 4px;
      background-color: ${colors.TBA_LIGHT3_GREY};
      box-shadow: 1px 1px 1px 0 rgba(${colors.BLACK}, 0.25);
      z-index: 10;
    }

    & .editable-field {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;

      > .edit-view.read-mode {
        > .edit-view__value {
          height: auto;
          line-height: 19px;
          white-space: normal;

          &:after {
            display: inline;
            font-family: Emerald;
            font-size: 12px;
            padding-left: 5px;

            @media print {
              display: none;
            }
          }
        }
      }

      &:hover & .edit-view.read-mode {
        color: ${colors.TBA_MEDIUM_DARK_GREY};
      }

      & .edit-view {
        padding: 0;
        flex: 0 0 auto;
        > .edit-view__error {
          display: none;
        }
      }
      & .select-wrapper {
        min-width: 120px;
        > .control {
          width: auto;
          > .control-label {
            padding: 0 15px 0 8px;
          }
        }
      }
      & .icon-close {
        align-self: center;
        padding: 0 4px;

        &:hover {
          color: ${colors.TBA_MEDIUM_DARK_GREY};
        }
      }
    }
  }
`

export const FindingViewWrapper = styled.div`
  && .detail-view-page {
    & .header-wrapper {
      & .toolbar-buttons {
        button,
        a {
          line-height: 10px;
        }
      }
      & .wrapper {
        &.expanded {
          width: 294px;
        }
      }
    }
    & .detail-headline-view {
      & > div {
        display: flex;
        flex-flow: column;
      }
      a {
        color: $black;
        text-decoration: underline;
      }
    }
    & .detail-view-page-pdf {
      & .toolbar-buttons {
        max-width: initial;
        & .pdf-generator-wrapper {
          margin-left: 10px;
        }
      }
    }
  }
`

export const OpportunityTablePicketWrapper = styled.div`
  && .custom-table.opportunities-table {
    overflow: auto;
    overscroll-behavior-x: contain;
    & .custom-picker {
      height: 1500px;
    }
    & .opportunities-table {
      height: 250px;
      padding: 15px 5px 5px 5px;
    }
    & .checkbox {
      width: 50px;
    }
    & .tooltip-anchor {
      > .tooltip-base {
        color: ${colors.BLACK};
        font-weight: normal;
      }
    }
    & .finding-info {
      width: 50px;
      > .tooltip-base {
        width: 200px;
        padding: 6px;
        line-height: 1.3;
        text-align: left;
        color: ${colors.BLACK};
        font-weight: normal;
        left: 8px;

        span {
          &:after {
            content: ' | ';
          }
          &:last-child:after {
            content: '';
          }
        }
      }
      @media (max-width: ${screen_max_extra_small}) {
        width: 20%;
      }
    }
    & .info-finding-about {
      display: none;
      @media (max-width: ${screen_max_extra_small}) {
        display: block;
      }
    }
  }
`

export const OpportunityAddModalWrapper = styled.div`
  && .add-to-opportunity-modal {
    & .add-opportunity-stage-1 {
      display: flex;
      flex-flow: column;

      div:first-child {
        margin-bottom: 8px;
      }
    }
    & .create-opportunity {
      input {
        margin-left: 0;
      }
      > .error {
        font-size: 10px;
        color: ${colors.RED};
      }
    }
  }
`
