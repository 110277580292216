// Default Options.

import { toDates } from './helpers'
import { yAxis15MinsTicks } from './axisTickValues'
import { useMemo } from 'react'

// Default Axis and layout Config to initail state
// Developer can set min & max / category data later, once it receives from API using setDomian in Api wrapper.ts
export function useOptions(xAxisData): Options {
  return useMemo(() => {
    return {
      xAxis: [
        {
          data: xAxisData,
          type: 'scaleBand'
        }
      ],
      yAxis: [
        {
          type: 'scaleBand',
          data: toDates(yAxis15MinsTicks) //
        }
      ],
      grid: [{ left: '100px', right: '30px', top: '30px', bottom: '140px' }]
    }
  }, [xAxisData])
}
