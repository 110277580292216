import './styles.scss'
import { getColorScale, heatMapDataTransformer } from './dataTransformer'
import { useEffect, useMemo, useRef, useState } from 'react'

import clsx from 'clsx'
import HeatMapRect from './HeatMapRect'

export default function HeatMap({
  colors,
  colorScale,
  data = [],
  disableRenderingOptimization = false,
  enableHighlight,
  maxValueForColorScale = 1,
  minValueForColorScale = 0,
  rectProps,
  showText,
  textProps,
  timeout,
  useLazyRenderingByIdleCallback = false,
  useLazyRenderingBySetTimeout = false,
  useNativeRendering = false,
  xScale,
  xScaleType,
  yScale,
  yScaleType
}: HeatMapComponentProps) {
  const [state, setState] = useState<AggregatedHeatMap>({})

  const optimization = useRef({
    disableRenderingOptimization,
    useLazyRenderingByIdleCallback,
    useLazyRenderingBySetTimeout,
    useNativeRendering
  })

  const scaleColor = useMemo(
    () =>
      getColorScale(
        colorScale,
        colors,
        minValueForColorScale,
        maxValueForColorScale
      ),
    [colorScale, colors, minValueForColorScale, maxValueForColorScale]
  )

  useEffect(() => {
    const length = (data || []).length

    // optimization
    if (!optimization.current.disableRenderingOptimization) {
      // > 70k - use requestIdleCallback
      if (length > 70000 || useLazyRenderingByIdleCallback) {
        optimization.current.useLazyRenderingByIdleCallback = true

        optimization.current.useLazyRenderingBySetTimeout =
          optimization.current.useNativeRendering = false

        // > 10k - use setTimeout
      } else if (length > 10000 || useLazyRenderingBySetTimeout) {
        optimization.current.useLazyRenderingBySetTimeout = true

        optimization.current.useLazyRenderingByIdleCallback =
          optimization.current.useNativeRendering = false
      } else {
        // < 10k - render directly
        optimization.current.useNativeRendering = true

        optimization.current.useLazyRenderingBySetTimeout =
          optimization.current.useLazyRenderingByIdleCallback = false
      }
    }

    const hasDomainValues = xScale.domain().length && yScale.domain().length

    const result = hasDomainValues
      ? heatMapDataTransformer({
          data,
          xScale,
          xScaleType,
          yScale,
          yScaleType,
          colorScale: scaleColor
        })
      : {}

    setState(result)
  }, [data, xScale, yScale, scaleColor])

  return (
    <g className={clsx('t-chart-heatmap')}>
      {Object.keys(state || {}).map((x: string) => {
        const s = state[x]
        return (
          <g data-key={s?.key} key={s?.key} className="t-chart-heatmap-group">
            <HeatMapRect
              data={s.value}
              timeout={timeout !== undefined ? timeout : s?.timeout}
              useLazyRenderingByIdleCallback={
                optimization.current.useLazyRenderingByIdleCallback
              }
              useLazyRenderingBySetTimeout={
                optimization.current.useLazyRenderingBySetTimeout
              }
              useNativeRendering={optimization.current.useNativeRendering}
              enableHighlight={enableHighlight}
              showText={showText}
              rectProps={rectProps}
              textProps={textProps}
            />
          </g>
        )
      })}
    </g>
  )
}
