import { isEmpty } from 'ramda'
import BaseOptions from './BaseOptions'

export default class ChartBase extends BaseOptions {
  private callback = (config: any) => null

  private config: ChartConfig = {
    axis: {
      lastUpdatedAt: null,
      getAxis: (diM: AxisDimention, axisIndex: number) =>
        this.getAxis(diM, axisIndex)
    },
    scale: {
      lastUpdatedAt: null,
      getScale: (diM: AxisDimention, axisIndex: number) =>
        this.getScale(diM, axisIndex),
      setDomain: (
        diM: AxisDimention,
        axisIndex: number,
        domain: any[],
        force?: boolean
      ) => {
        this.setDomainBySeries(diM, axisIndex, domain || [], force)
        this.changeDetection()
      }
    },
    grid: {
      lastUpdatedAt: null,
      getGrid: (gridIndex: number) => this.getGrid(gridIndex)
    }
  }

  setOptions(options: any) {
    this.prepareAndInitalizeOptions(options)
    this.changeDetection()
  }

  setListener(fn: any) {
    this.callback = fn
  }

  resize() {
    this.handleGridResize()
    this.handleScaleResize()
    this.handleAxisResize()
    this.changeDetection()
  }

  private changeDetection() {
    const gridChangelog = this.getAllGridChangeLog()
    const scaleChangelog = this.getAllScaleChangeLog()
    const axisChangelog = this.getAllAxisChangeLog()

    // console.log({ gridChangelog, scaleChangelog, axisChangelog });

    if (!isEmpty(gridChangelog)) {
      this.gridChanged()
    } else if (!isEmpty(scaleChangelog)) {
      this.scaleChanged()
    } else if (!isEmpty(axisChangelog)) {
      this.axisChanged()
    }

    // reset logs to track new changes
    this.resetChangeLogs()
  }

  private resetChangeLogs() {
    this.resetGridChangeLog()
    this.resetScaleChangeLog()
    this.resetAxisChangeLog()
  }

  // Grid is changed by the size of canvas. So this cause other config as well.
  private gridChanged() {
    const lastUpdatedAt = +new Date()

    this.config.axis.lastUpdatedAt = lastUpdatedAt as any
    this.config.scale.lastUpdatedAt = lastUpdatedAt as any
    this.config.grid.lastUpdatedAt = lastUpdatedAt as any

    this.callback({ ...this.config })
  }

  // Scale is changed by domain value or range. So axis need to update accordingly
  private scaleChanged() {
    const lastUpdatedAt = +new Date()

    this.config.scale.lastUpdatedAt = lastUpdatedAt as any
    this.config.axis.lastUpdatedAt = lastUpdatedAt as any

    this.callback({ ...this.config })
  }

  // axis is affected on multiple cases
  // - show / hide axis
  // - axis side and orientation
  private axisChanged() {
    const lastUpdatedAt = +new Date()

    this.config.axis.lastUpdatedAt = lastUpdatedAt as any

    this.callback(this.config)
  }

  // Usefull to free-up memory when resuing the same react component with different config
  // Not much reqired as options are initalized properlty. Its additional step
  resetAll() {
    this.resetOptions()
    this.resetAxis()
    this.resetScale()
    this.resetGrid()
  }

  // Not finalized
  private destory() {
    this.resetAll()
    // further config can be added to release memory
  }
}
