import styled from "styled-components"
import COLORS from 'src/components/legacy/common/colors.json'

export const TreeViewStyled = styled("div")`
	border: 1px solid  ${COLORS.GREY};
	background: ${COLORS.WHITE};
	height: 400px;
	width: 400px;
	user-select: none;
	overflow: auto;
	padding: 8px 4px;
	position: relative;
	
	&>.tree-view__node {
		padding-left: 0; 
	}
`

export const TreeViewNodeStyled = styled("div")`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	user-select: none;
	
	& .tree-view__node {
		padding-left: 24px;
	}
`

export const TreeViewNodeHeaderStyled = styled("div")`
	height: 22px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	cursor: ${({ multiSelect }) => !multiSelect ? "pointer" : "default"};
	z-index: 0;
	&:after {
		content: "";
		background: ${({ selected, multiSelect }) => selected && !multiSelect ? COLORS.TBA_MEDIUM_GREEN : "none"};
		height: 22px;
		width: 100%;
		position: absolute;
		z-index: -1;
		left: 0;
	}
`

export const TreeViewNodeContentStyled = styled("div")`
`

export const TreeViewNodeIconStyled = styled("div")`
	flex: 0 0 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.TBA_DARKER_GREY};
`

export const TreeViewNodeHeaderLabelStyled = styled("div")`
	flex: 1 0 0%;
	overflow: hidden;
	font-weight: ${({ multiSelect }) => !multiSelect ? "inherit" : "bold"};
	.checkbox-container{
		&:after {
			content: "" !important;
		}
	}	
`

export const ExpandIconStyled = styled("div")`
	height: 12px;
	width: 12px;
	transform: rotate(${({ expanded }) => expanded ? "0deg" : "-90deg"});
	transition: all 0.4s ease;
`

export default {
	ExpandIconStyled,
	TreeViewStyled,
	TreeViewNodeContentStyled,
	TreeViewNodeHeaderLabelStyled,
	TreeViewNodeHeaderStyled,
	TreeViewNodeIconStyled,
	TreeViewNodeStyled
}
