import styled, { css } from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'
import Icon from 'src/components/legacy/components/icon/icon.js'
import Modal from 'src/components/legacy/components/modal/modal'
import Filter from 'src/components/legacy/components/filter/filter'

export const FloorAreaNote = styled.div`
  padding-right: 10px;
`

export const TabsWrapper = styled.div`
  margin: 0 auto;
  li {
    margin: 0 5px;
    border: none;
    background-color: #ccc;
    color: #292323;
    font-weight: 600;
    border-radius: 5px 5px 0 0 !important;
    justify-content: flex-start;
    font-size: 13px;
    padding-left: 15px;
  }
  .active {
    background-color: #fff;
    color: #000;
  }
`

export const UtilityBillContainer = styled.div`
  height: 100%;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  ul {
    max-width: 70%;
    margin-top: 20px;
  }

  li {
    max-width: 20%;
  }

  .spacer {
    padding-left: 10px;
    display: inline;
  }

  .tab-list .tab-link {
    max-width: 20%;
  }

  .tab-list {
    max-width: 70%;
  }

  .tab-group {
    margin-top: 24px;
  }

  .ytd-container {
    float: right;
    margin-top: 20px;
  }

  .ytd {
    color: #000;
    padding: 10px;
    font-size: 1.2em;
    font-weight: bold;
  }

  .tab-total {
    font-size: 130%;
    font-weight: bold;
    padding-right: 5px;
  }

  .utility-bill-toolbar {
    align-items: center;
    display: flex;
    .disabled {
      cursor: not-allowed;
    }
    .tooltip-base.default {
      height: 40px !important;
    }
  }

  .tooltip-anchor .tooltip-base.default,
  .tooltip-anchor + .tooltip-base.default {
    height: 150px;

    &.center {
      top: 100% !important;
    }
  }
`
export const UploadContainer = styled.div`
  .instructions {
    width: 100%;
    margin-bottom: 20px;
  }

  .upload-instructions {
    width: 80%;
  }

  .button-container {
    width: 20%;
    .download {
      float: right;
    }
  }

  span .link {
    margin-top: 10px;
  }

  .duplicate-error {
    display: flex;
    border: 2px solid ${colors.RED};
    width: 70%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    p {
      color: ${colors.RED};
      font-weight: 700;
      font-sizr: 12px;
      margin: 5px;
    }
  }
`

export const ChooseBuildingMessage = styled.p`
  padding: 50px;
  font-weight: 800;
  font-size: 15px;
`

export const UtilityBillTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid #999999;
  border-radius: 2px;
`

export const UtilityBillTotalContainer = styled.div`
  border: 1px solid #999999;
  border-radius: 2px;
  vertical-align: top;
`

export const ErrorContainer = styled.div`
  margin-bottom: 10px;
`
export const Error = styled.div`
  background: ${colors.WHITE};
  border-style: Solid;
  border-color: ${colors.RED};
  border-width: 2px;
  padding: 10px 10px 10px 10px;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  width: 100%;
`

export const TextWrapper = styled.span`
  font-weight: bold;
  font-style: italic;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.26px;
  color: ${colors.RED};
  margin-left: 9px;
`

export const ErrorIcon = styled.span`
  font-size: 22px;
  color: ${colors.RED};
`
export const EnergyIntensityContainer = styled.div`
  height: 100%;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;

  h1 {
    margin-bottom: 10px !important;
  }
`
export const DownloadInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 13px;

  a {
    text-decoration: none;
  }
`

export const DownloadLabel = styled.span`
  font-size: 1.15em;
  font-weight: 600;
`

export const UploadTemplateInfo = styled.span`
  margin: 2px 0px 6px 0px;
  font-size: 1.05em;
`

export const DownloadTemplateButton = styled.button`
  width: 135px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 1em;
  font-weight: 600;
  &:hover {
    background: aliceblue;
  }
`

export const TemplateFileIcon = styled(Icon)`
  font-size: 1rem;
  margin-right: 5px;
  cursor: pointer;
`

export const UtilityLocationLabel = styled.span`
  font-size: 1.05em;
  margin-bottom: 6px;
`

export const AttachFileInfoContainer = styled.div`
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const UploadIcon = styled(Icon)``

export const DropFileLabel = styled.h4``

export const ChooseFileLabel = styled.span`
  border: 1px solid #537898;
  background: #fff;
  width: 95px;
  font-size: 0.8rem;
  height: 28px;
  color: #537898;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 5px;
`

export const FileFormatLabel = styled.label`
  color: gray;
`

export const StyledAttachFile = styled.div`
  > section {
    border: none;
    padding-top: 5px;
  }
  > section > label {
    background-color: #f8fbff;
    border: 2px dashed lightgray;
    border-radius: 4px;
  }
`

export const AttachFileLabel = styled.strong`
  margin-left: 15px;
`

export const NoFileAttached = styled.div`
  border: 1px solid lightgray;
  color: gray;
  padding-left: 10px;
  margin-top: 5px;
`

export const FileListContainer = styled.div``

export const FileListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
`

export const FileName = styled.div`
  font-weight: 600;
  margin-left: 15px;
  max-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const FileSize = styled.div`
  color: gray;
  margin-left: 25px;
`

export const FileDate = styled.div`
  color: gray;
`

export const RemoveAttachedFile = styled.div``

export const RemoveFileIcon = styled(Icon)`
  position: relative !important;
  top: 0 !important;
  right: 25px !important;
  font-size: 9px;
`

export const UploadCloudIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -10px;
`

export const FileListLeftDiv = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 55%;
  align-items: center;
`

export const FileListRightDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25%;
  align-items: center;
`
export const StyledUploadModel = styled(Modal)`
  &.modal {
    width: 800px;
  }
  .modal-button-row {
    .modal-button {
      text-transform: capitalize !important;
      border-radius: 5px;
    }
    .modal-button.type-cancel {
      background-color: #bbbbbb !important;
      &:hover {
        background-color: #666666 !important;
      }
    }
  }
`

export const TabToolbar = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  position: relative;
  justify-content: flex-end;
  .toolbar-icons {
    margin-left: 10px;
    cursor: pointer;
  }
`

export const UploadButton = styled.button.attrs((props) => ({
  'data-testid': props?.testName
}))`
  margin-left: auto;
  margin-left: 10px;
`

export const TableDeleteButton = styled.button`
  border: none;
  color: ${colors.TBA_DARKER_GREY};
  background-color: transparent;
`

export const ToolbarButton = styled(TableDeleteButton)`
  font-size: 16px;
  width: 30px;
  ${(props) => props.clickDisabled && 'padding-top: 22%;'}
  color: ${(props) =>
    props.clickDisabled ? colors.TBA_DARK_GREY : colors.TBA_DARKER_GREY};
  ${(props) => props.clickDisabled && 'cursor: not-allowed;'}
`

export const ConfirmationText = styled.p`
  font-weight: bold;
  font-style: italic;
`

export const ActionContainer = styled.span`
  padding-left: 10px;
  display: flex;
`

export const TableFilter = styled(Filter)`
  min-width: 14%;
  .control {
    height: 26px;
    max-width: 100%;
  }
  .select-wrapper {
    min-width: 75px;
    max-width: 28%;
    width: 100px;

    .select-dropdown {
      width: 100%;
    }
  }
  .search-box {
    width: 218px;
  }
`

export const CustomTableCell = styled.span`
  width: 20px;
  ${(props) =>
    props.textAlign &&
    css`
      text-align: center;
    `}
`
