import { useState,useEffect } from "react"
import { Trans } from "react-i18next"
import "./key-strip-block.scss"
import KeyEnergyMetricDisplay from "./KeyEnergyMetricDisplay";
import { DEFAULT_TIME_PERIOD } from "./helpers.js"
import { ChartLink, KeyStripBlockStyle, MissingDataText, StyledIcon } from "./styles"
import { getStpDateRange } from "src/common/stp-converter.js"
import { GET_BUILDING_DETAILS } from "../graphql";
import { useQuery } from "src/hooks/APIHooks";
import { GET_BUILDING_TOTAL_METER_EQUIPMENT } from "src/pages/equipmentSetup/graphql/queries";
import { stringToBoolean } from "src/utils/commonMethods";
import { useDashboardContext } from "../../DashboardContextProvider";
import translate, { TranslateComponent } from "src/common/translations";
const DATE_FORMAT = "M/D/YYYY"


const KeyStripBlock = (props: any) => {
	const { isKeyMetric, buildingId, accountId  } = props?.dashboard
	const { t,handleOpenKpiStripBuilder } = props
	const kpiEnergyMetricList = props?.dashboard?.keyMetricsStripList || []
	const timePeriod = props?.dashboard?.keyMetricTimePeriod || DEFAULT_TIME_PERIOD
	const comparedTo = props?.dashboard?.keyMetricComparedTo
	const hasEnabledMetric = kpiEnergyMetricList.some(el => el.isEnabled)

	const stpPeriodRange = getStpDateRange(timePeriod || DEFAULT_TIME_PERIOD)
	const stpComparedPeriodRange = comparedTo ? getStpDateRange(comparedTo) : null
	const { isMissingDataStatuses } = useDashboardContext()
	const [accountName, setAccountName] = useState("")
	const [buildingName, setBuildingName] = useState("")
	const [buildingTotalVirtualMeterId, setBuildingTotalVirtualMeterId] = useState(null)
	const {
		data: buildingDetails,
		refetch: getBuildingDetails,
		loading: loadingBuildingDetails
	  } = useQuery({
		query: GET_BUILDING_DETAILS,
		variables: { id: buildingId },
		disableInitialLoad: true,
		errorPolicy: 'all',
		dataPath: 'data.getBuilding'
	  })
	  
	  const { refetch: refetchBuildingTotalMeterEquipment } = useQuery({
		query: GET_BUILDING_TOTAL_METER_EQUIPMENT,
		variables: {
		  body: JSON.stringify({ bId: buildingId })
		},
		disableInitialLoad: true
	  })

	useEffect(() => {
		(async ()=>{
			if (buildingId){
				await getBuildingDetails()
				const { data: { getBuildingTotalMeterEquipment } } = await refetchBuildingTotalMeterEquipment()
				const parseData = JSON.parse(getBuildingTotalMeterEquipment)
				if(parseData?.body?.equipments?.length > 0 ){
					setBuildingTotalVirtualMeterId(parseData.body.equipments[0].id)
				}
			}
		})()
		
	}, [buildingId])

	useEffect(() => {
		if (buildingDetails) {
			setAccountName(buildingDetails?.accountName)
			setBuildingName(buildingDetails?.name)
		}
	}, [buildingDetails])


	return (<>{!stringToBoolean(isKeyMetric) || loadingBuildingDetails 
		? ""
		:
		<KeyStripBlockStyle>
			<div className="kpi-strip-block1">
				<div className="description">
					{kpiEnergyMetricList.length && hasEnabledMetric
						? <div>
							<div className="settings">
								<div className="organization">
									{buildingName
										? <>
											<span className="icon-location" /> {buildingName} (<TranslateComponent>in</TranslateComponent> <span className="icon-organization" /> {accountName})
										</>
										: <>
											<span className="icon-organization" /> {accountName}
										</>
									}
								</div>

								<div className="period">
								<TranslateComponent>Time</TranslateComponent>: <span>{`${stpPeriodRange.startDate.format(DATE_FORMAT)} - ${stpPeriodRange.endDate.format(DATE_FORMAT)}`}</span>
									{stpComparedPeriodRange && (
										<> <TranslateComponent>vs</TranslateComponent>. <span>
											{`${stpComparedPeriodRange.startDate.format(DATE_FORMAT)} - ${stpComparedPeriodRange.endDate.format(DATE_FORMAT)}`}
										</span>
										</>
									)}
								</div>
								{isMissingDataStatuses && <MissingDataText><StyledIcon className="icon-error" /><TranslateComponent>Please check that the </TranslateComponent><ChartLink href="/building-data"><TranslateComponent>Building Data Chart</TranslateComponent></ChartLink><TranslateComponent> has meter data and contact your Trane Representative if any is missing.</TranslateComponent></MissingDataText>}
							</div>
							<div className="energy-metric-list-container">
								{kpiEnergyMetricList.filter(el => el.isEnabled).map((el, i) => (
									<div className="cell" key={i}>
										<KeyEnergyMetricDisplay
											locationId={buildingId}
											organizationId={accountId}
											timePeriod={timePeriod}
											comparedTo={comparedTo}
											keyMetric={el.keyMetric}
											disabled={!el.isEnabled}
											isEnabled={el.isEnabled}
											uom={el.uom}
											stripMode={true}
											totalVirtualMeterId = {buildingTotalVirtualMeterId}
											t={t}
											stripNumber={i}
										/>
									</div>
								))}
							</div>
						</div>
						: <div>
							<button className="action additional" onClick={()=>handleOpenKpiStripBuilder()}>
							  <TranslateComponent>You can configure this dashboard strip to display your site's key energy metrics. Click on the</TranslateComponent> <i className="icon-edit" /> <TranslateComponent>in the upper right corner or just click</TranslateComponent> <span><TranslateComponent>here</TranslateComponent></span>
							</button>

							<div className="energy-metric-list-container placeholder">
								<div className="cell"><div className="item">
									<div className="icon-energy-roi" />
									<div className="text">
										<TranslateComponent>ENERGY METRIC</TranslateComponent>
									</div>
								</div>
								</div>
								<div className="cell cell-2"><div className="item">
									<div className="icon-energy-roi" />
									<div className="text">
										<TranslateComponent>ENERGY METRIC</TranslateComponent>
									</div>
								</div>
								</div>
								<div className="cell cell-3"><div className="item">
									<div className="icon-energy-roi" />
									<div className="text">
										<TranslateComponent>ENERGY METRIC</TranslateComponent>
									</div>
								</div>
								</div>
								<div className="cell cell-4"><div className="item">
									<div className="icon-energy-roi" />
									<div className="text">
										<TranslateComponent>ENERGY METRIC</TranslateComponent>
									</div>
								</div>
								</div>
								<div className="cell cell-5"><div className="item">
									<div className="icon-energy-roi" />
									<div className="text">
										<TranslateComponent>ENERGY METRIC</TranslateComponent>
									</div>
								</div>
								</div>
							</div>
						</div>
					}

					<button className="edit icon-edit transparent" onClick={()=>handleOpenKpiStripBuilder()} />
				</div>
			</div>
		</KeyStripBlockStyle>}</>
	)
}

export default KeyStripBlock
