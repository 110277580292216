import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'
const screen_grid_medium = '839px'
const screen_grid_small = '599px'
const screen_grid_large = '1219px'
const screen_max_extra_small = '633px'
const screen_max_small = '913px'
const screen_max_large = '1473px'

export const ConfirmationText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
`
export const CostSavingsWrapper = styled.div`
  && .payback-energy-block{
    height: 76px;
  }   
  && .energy-block {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 82px;
    min-width: 20%;
    flex: auto;
    position: relative;
    padding: 0 10px 0 0px;
    border-left: 1px solid ${colors.TBA_DARK_GREY};
    &.edit {
      border-left: none;
    }
    &.view {
      padding: 0 10px 0 20px !important;
    }

    .energy-label {
      color: ${colors.BLACK};
      font-size: 16px !important;
      font-weight: 500 !important;
      text-wrap: nowrap;
    }

    & .value {
      font-size: 26px;
      font-weight: bold;
      width: 170px;
      line-height: 26px;
      white-space: nowrap;
      text-align: left;

      > .conditional-tooltip {
        display: flex;
      }
    }
    & .payback-roi-value {
      margin-bottom: 25px;
    }

    & .period {
      margin: 0;
      letter-spacing: 0;
      font-size: 14px;
      font-style: italic;
      font-weight: normal;
      color: ${colors.TBA_DARKER_GREY};
    }
    & .payback-roi-period {
      margin-top: 4px;
    }
  }

  @media (max-width: ${screen_grid_medium}) {
    && .energy-block-container:nth-child(2n) {
      border-left: none;
    }
  }

  @media (max-width: ${screen_grid_small}) {
    && .energy-block {
      border-left: none;
      border-bottom: 1px solid ${colors.TBA_DARK_GREY};
      height: 102px;
    }
  }

  && .energy-block.edit {
    width: 200px;
    border-right: none;
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: none;
    height: 90px;
    max-height: 90px;

    & .icon {
      margin-left: 6px;
    }

    & .tooltip {
      width: 200px;
      word-break: normal;
      white-space: inherit;
    }
  }
`

export const CostSavingsEditWrapper = styled.div`
  && .cost-savings-edit {
    padding: 20px 16px;
    & .custom-content {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      @media (max-width: ${screen_grid_small}) {
        width: 100%;
      }
      > .checkbox-container {
        margin-bottom: 8px;
      }
      .checkbox-label{
        font-weight: 700;
      }
      > .custom-input {
        display: flex;
        flex-flow: column;
        width: 200px;
        label {
          margin-bottom: 3px;
          word-break: break-word;
          font-weight: 700;
        }
        input {
          margin: 0 0 8px;
          // width: 100%;
        }
        > .icon-error {
          left: 0;
          top: 51px;
        }
        > .mini-error-text {
          padding-left: 20px;
          padding-top: 5px;
        }
      }
      > .invalid {
        input {
          margin-bottom: 0;
        }
      }
      > .select-wrapper {
        min-width: auto;
        flex: 1;
        > .control {
          width: 200px;
        }
        @media (max-width: ${screen_grid_small}) {
          width: 100%;
          > .control {
            width: 100%;
          }
        }
      }
      > .modal-datepicker-wrapper {
        > .custom-label {
          padding: 0;
        }
        > .date-container {
          width: 100%;

          input {
            margin: 0;
          }
        }
      }
    }
    & .time-interval-selector {
      width: 200px;
      > .select-wrapper {
        flex: 1;
      }
      @media (max-width: ${screen_grid_small}) {
        width: 100%;
      }
      label{
        text-wrap: nowrap;
        font-weight: 700;
      }
    }
    article {
      &:last-child {
        & .cost-savings-item {
          border-right: none;
        }
      }
    }
    @media (max-width: ${screen_grid_large}) {
      article:nth-child(2n) {
        & .cost-savings-item {
          border-right: none;
        }
      }
    }
    @media (max-width: ${screen_grid_small}) {
      article {
        & .cost-savings-item {
          border-right: none;
          border-bottom: 1px solid ${colors.TBA_DARK_GREY};
        }
        &:last-child {
          & .cost-savings-item {
            border-bottom: none;
          }
        }
      }
    }
  }
`

export const SelectItemWrapper = styled.div`
  && .cost-savings-item {
    padding: 10px 25px;
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    border-right: 1px solid ${colors.TBA_DARK_GREY};
    min-width: 230px;
    position: relative;
    min-height: 240px;
    & .title {
      margin: 3px 0;
      color: ${colors.BLACK};
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.26px;
    }
    & .deactive {
      color: ${colors.TBA_DARKER_GREY};
      font-size: 16px;
      font-style: italic;
      font-weight: 600;
      width: 200px;
      margin-bottom: 16px;
      display: flex;
      flex-flow: column;
      word-break: break-word;
      height: 50px;

      > .main-label {
        margin-bottom: 6px;
        padding-top: 10px;
      }
    }
    & .desc {
      font-size: 12px;
      margin-bottom: 6px;
      text-wrap: nowrap;
    }
    & .select-wrapper {
      align-self: flex-start;
    }
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  && .title-text {
    width: 450px;
  }
  && .title-toggle-label {
    margin-top: 20px;
  }
  && .title-toggle {
    margin-top: 15px;
  }
  @media (max-width: ${screen_grid_large}) {
    && .title-toggle {
      background-color: ${colors.TBA_DARK_GREY};
    }
  }
`

export const InlineEditFormWrapper = styled.div`
  && .inline-edit-form {
    margin-bottom: 0px;  

    legend{
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.26px;
    }
    
    & .found-during{
      margin-top:-15px;
    }

    & .error {
      color: ${colors.RED};
    }
    
    label + input {
      margin-left: 0px;
    }

    .block {
      background-color: ${colors.WHITE};
      border: 1px solid ${colors.TBA_LIGHT_GREY};
      border-radius: 6px;
    }

    .header-wrapper {
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid ${colors.TBA_DARKER_GREY};

      .title-wrapper{
        align-items:center;
        display:flex;
        padding:0px;
      }
  
      .title-wrapper .title {
        overflow: unset;
      }
  
      fieldset.name {
        width: 500px;
        padding: 0;
        textarea {
          height: 25px;
          overflow: hidden;
        }
      }
      .title {
        display: flex;
        align-items: flex-end;
  
        .page-title-wrapper {
          margin-right: 10px;
        }
  
        .toggle-button {
          margin-bottom: 0;
        }
      }
      @media (max-width: ${screen_max_extra_small}) {
        display: flex;
        flex-flow: column-reverse;
  
        hr {
          display: none;
        }
  
        .toolbar .filter-div {
          justify-content: flex-end;
        }
  
        .page-title {
          height: 2em;
        }
      }
    }

    .toolbar-buttons {
      margin: 10px 0 0 auto;
      text-align: right;
      display: flex;
      justify-content: flex-end;

      & > * {
        margin-left: 10px;
      }
    }

    & .tip {
      margin: 0;
      color: ${colors.TBA_DARK_GREY};
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.2px;
    }
    & .chart-link {
      display: flex;
      flex-direction: column;
      border: none;
    }

    & .toggle {
      margin-top: 0;
    }

    & .toggle-button {
      align-items: center;
      display: flex;
      margin-bottom: 15px;
      height: 30px;
      margin-top:10px;
      margin-left: 15px;

      span {
        text-transform: uppercase;
      }
    }


    input:focus::-webkit-input-placeholder {
      color: transparent;
    }
    input:focus::-moz-placeholder {
      color: transparent;
    }
    & .proposal-date {
      position: relative;
      &.error {
        padding-bottom: 32px;
      }

      > .modal-datepicker-wrapper {
        display: flex;
        flex-flow: column;

        input.modal-date-input {
          width: 200px;
        }
      }
      > .date-container {
        width: 100%;
      }
      > .icon-error {
        position: absolute;
        left: 0;
        top: 100%;
        line-height: 20px;
      }
      > .mini-error-text {
        position: absolute;
        left: 20px;
        top: 100%;
      }
    }
    .target-date {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 10px 0;
  
      .modal-datepicker-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right:28px;
        input.modal-date-input {
          width: 170px;
        }
      }
      .date-container {
        width: auto;
      }
      .icon-error {
        position: absolute;
        left: 0;
        top: 100%;
      }
      .mini-error-text {
        position: absolute;
        left: 20px;
        top: 100%;
      }
    }

    & .sub-footer {
      display: flex;
      flex-flow: row;
      align-items: flex-start;

      @media (max-width: ${screen_grid_large}) {
        flex-flow: column;
      }

      .priority {
        margin-right: 16px;
      }
    }

    & .proposal-info {
      display: flex;
      flex-flow: column;
      align-items: flex-end;

      input {
        width: 200px;
        margin-left: 0;
        margin-bottom: 8px;
      }

      > div {
        display: flex;
        flex-flow: column;
      }
    }

    & .data-source-pickers {
      margin: 0;
      align-items: center;
      > .select-wrapper {
        align-self: flex-start;
      }

      > .select-block {
        flex-flow: column;
        align-items: flex-start;
      }
    }

    & .inline-edit-header {
      padding: 16px 16px 32px 16px;
    }

    & .priority {
      max-width: 240px;
      width: 240px;
      background: ${colors.TBA_LIGHTEST_GREY};
      @media (max-width: ${screen_max_large}) {
        margin-bottom: 10px;
      }
    }

    & .uploading-file {
      @media (max-width: ${screen_max_large}) {
        margin-bottom: 10px;
      }
    }

    & .horizontal-control-block {
      display: flex;
      align-items: center;
      label + * {
        margin-left: 6px;
      }

      &.right {
        justify-content: flex-end;
      }

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    & .workflow-edit {
      padding: 16px;
      .rs-header {
        min-width: 200px;
      }
      @media (max-width: ${screen_grid_large}) {
         .bottom-options {
            .horizontal-control-block.right {
            justify-content: flex-start;
            label {
              width: 80px;
            }
          }
        }
        .target-date {
            justify-content: flex-start;
            .custom-label {
              width: 80px;
              padding-right: 0;
              margin-right: 6px;
            }
        }
      }
      @media (max-width: ${screen_grid_medium}) {
        max-width: unset;
				.horizontal-control-block.right {
					justify-content: flex-start;
					label {
						width: 80px;
					}
				}
				.target-date {
					justify-content: flex-start;
					.custom-label {
						width: 80px;
						padding-right: 0;
						margin-right: 6px;
					}
				}
      }
      @media (max-width: ${screen_grid_medium}) {
        > .uploading-file > div > div {
          align-items: flex-start;
          > * {
            margin-left: 6px;
          }
        }
        input[name='description'] {
          flex-basis: unset;
        }
      }
      @media (max-width: ${screen_grid_small}) {
          .horizontal-control-block.right {
            flex-flow: column;
            align-items: flex-start;
            .select-wrapper {
              align-self: flex-start;
              margin-left: 0;
            }
            .selectrix-wrapper {
              max-width: 200px;
              margin-left: 0;
            }
          }
          .target-date {
            .modal-datepicker-wrapper {
              flex-flow: column;
              align-items: flex-start;
            }
          }
        }
      }
    }

    @media (max-width: ${screen_max_small}) {
      & .header-wrapper {
        flex-wrap: wrap;
        .toolbar .toolbar-buttons button:first-child {
          margin: 0;
        }
        .page-title-wrapper {
          width:100%;
        }
      }

      & .proposal-info {
         .select-wrapper {
          align-self: auto;
        }
      }

      & .additional-content {
        flex-flow: row wrap;
        > .headlines-edit-list {
          width: 100%;
        }
      }
    }

    @media (max-width: ${screen_max_extra_small}) {
      & .header-wrapper {
        padding: 0 16px 16px;
        > .title-wrapper,
        .title {
          width: 100%;
        }
        fieldset.name {
          width: 100%;
          textarea {
            height: 95px;
          }
        }
        .page-title-wrapper {
          width:100%;
        }
      }
      & .data-source-pickers {
        max-width: 100%;
        & > * {
          margin-right: 0;
        }

        > .select-block {
          flex-flow: column;
          align-items: flex-start;
        }

        fieldset {
          width: 100%;
          padding-right: 0;
        }
      }
      & .proposal-info {
        flex-flow: column;
        & > div,
        .modal-datepicker-wrapper {
          width: 100%;
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-start;

          > .date-container {
            width: 170px;
          }

          input,
          .date-container input {
            margin-left: 0;
            max-width: 100%;
            width: 100%;
          }
        }

        & > div:first-child {
          margin-right: 0;
          margin-bottom: 6px;
        }
      }
    }
    @media (max-width: ${screen_grid_small}) {
      & .data-source-pickers {
        > .organization-picker {
          flex: 100%;
        }
      }
    }
    @media (max-width: ${screen_grid_small}) {
      & .toggle-button {
        align-items: center;
        display:flex;
        height:30px;
      }
    }
  }
`
export const FindingHeadLineWrapper = styled.div`
  &&.finding-headlines {
    display: flex;

    & .finding-headline {
      flex: 1;
      padding: 0 20px 0 0;

      h4 {
        margin: 0 0 4px 0;
      }

      p {
        margin: 0;
      }
    }

    @media (max-width: ${screen_max_extra_small}) {
      flex-direction: column;

      & .finding-headline {
        padding-bottom: 16px;
      }
    }
  }
`

export const FindingViewWrapper = styled.div`
  && .finding {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-content: start;
    justify-content: flex-start;
    padding: 10px;
    margin-top: 8px;
    border: 1px solid ${colors.TBA_LIGHTEST_GREY};
    border-radius: 2px;
    height: 80px;

    & .finding-preview {
      margin-right: 16px;
      position: relative;
      width: 106px;
      height: 106px;
      white-space: none !important;

      > .image-gallery-wrapper
        .image-gallery-content:not(.fullscreen)
        .image-gallery-slides {
        height: 100%;
        min-height: 100%;
      }
    }

    & .finding-info {
      word-break: normal;
      max-width: 284px;
      flex: 1 0;

      h3 {
        margin-top: 0;
        margin-bottom: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @media (max-width: ${screen_max_small}) {
          flex: 100%;
        }
      }

      p {
        overflow: hidden;
        word-break: break-word;
      }

      @media (max-width: ${screen_max_extra_small}) {
        .finding-link {
          display: none;
        }
      }
    }

    & .finding-link,
    .finding-link-small {
      margin-top: 0;
      font-size: 14px;
      a {
        font-size: 14px;
        color: $black;
        text-decoration: underline;
      }
      @media (max-width: ${screen_max_extra_small}) {
        flex: 100%;
      }
    }

    & .finding-link-small {
      display: none;

      @media (max-width: ${screen_max_extra_small}) {
        display: block;
        flex: 1 0 100%;
        max-width: 175px;
      }
    }

    @media (max-width: ${screen_max_extra_small}) {
      & .finding-info {
        flex: 100%;
      }
    }

    button.remove-block {
      position: absolute;
      right: 5px;
      top: 8px;
      z-index: 2;
      font-size: 14px;
      height: 25px;
      min-width: 23px;
    }
  }
`

export const FindingsTablePicketWrapper = styled.div`
  && .custom-table.findings-table {
    overflow: auto;
    overscroll-behavior-x: contain;
    & .custom-picker {
      height: 1500px;
    }
    & .finding-table {
      height: 250px;
      padding: 15px 5px 5px 5px;
    }
    & .checkbox {
      width: 50px;
    }
    & .tooltip-anchor {
      > .tooltip-base {
        color: ${colors.BLACK};
        font-weight: normal;
      }
    }
    & .finding-info {
      width: 50px;
      > .tooltip-base {
        width: 200px;
        padding: 6px;
        line-height: 1.3;
        text-align: left;
        color: ${colors.BLACK};
        font-weight: normal;
        left: 8px;

        span {
          &:after {
            content: ' | ';
          }
          &:last-child:after {
            content: '';
          }
        }
      }
      @media (max-width: ${screen_max_extra_small}) {
        width: 20%;
      }
    }
    & .info-finding-about {
      display: none;
      @media (max-width: ${screen_max_extra_small}) {
        display: block;
      }
    }
  }
`


export const ImagesFindingsSectionWrapper = styled.section`
  && .images-finding-section {
    & .image-gallery-description {
      & .finding-link {
        color: ${colors.BLACK};
        text-decoration: underline;
      }
      p {
        margin: 5px 0 0;
      }
    }
  }
`
export const NotifyContentWrapper = styled.section`
  && .notify-content {
    p {
      padding-bottom: 20px;
    }

    textarea {
      width: 100%;
    }
  }
`

export const OpportunityViewWrapper = styled.div`
  && .detail-view-page {
    min-height: 100%;
    padding: 20px 20px 0 20px;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;

    & .section-spinner {
      background: transparent;
      border: none;
    }
    & .header-wrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid ${colors.TBA_DARKER_GREY};

      & .icon-visible {
        color: ${colors.TBA_DARKER_GREY};
        font-size: 18px;
        background: transparent;
        border: transparent;
        vertical-align: middle;
        margin-left: 10px;
        height: 21px;
        line-height: 22px;

        &.green {
          color: ${colors.GREEN};
        }
      }

      & .icon-help {
        margin-left: 10px;
      }

      & .title-wrapper {
        display: flex;
        align-items: center;
        padding: 0;

        & .title {
          text-overflow: ellipsis;
          overflow: hidden;
        }

        button,
        a {
          line-height: 10px;
          border: none;
        }
      }

      & .wrapper {
        &.expanded {
          width: 294px;
        }
      }

      & .toolbar {
        justify-content: space-between;

        & .toolbar-buttons {
          max-width: 330px;
          display: flex;
          align-items: center;
          & > * {
            margin-left: 10px;

            &:hover {
              cursor: pointer;
            }
          }
        }

        & .tooltip-container {
          font-size: 18px;
          line-height: 22px;
          color: ${colors.TBA_DARKER_GREY};
          &.disabled {
            color: ${colors.TBA_LIGHT_GREY};
            pointer-events: none;
          }
        }
        & .image-preview {
          & .section-spinner {
            height: 30px;
            min-width: 80px;
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    & .error-text {
      a {
        color: ${colors.RED};
        text-decoration: underline;
      }
    }
    & .created-info {
      text-align: right;
      & .created-info-item {
        height: 26px;
        label {
          padding-right: 10px;
        }
      }
      label {
        font-weight: normal;
      }
      span {
        font-weight: bold;
      }
      @media (max-width: ${screen_grid_small}) {
        text-align: left;
      }
    }
    & .detail-sub-footer {
      display: flex;
      flex-flow: column;
      padding: 16px 0;

      & .toolbar-buttons {
        max-width: 300px;
        & > * {
          margin-left: 10px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    & .description {
      padding: 20px 10px;
    }
    &
      .image-gallery-wrapper
      .image-gallery-content:not(.fullscreen)
      .image-gallery-slides {
      height: 350px;
    }
  }

  && .detail-view-page-footer {
    background: ${colors.WHITE};
    padding: 16px;

    & .section-spinner {
      height: 26px;
      margin: 0;

      & .icon.spin-wheel {
        font-size: 18px;
      }
    }

    & .priority {
      font-size: 14px;
      max-width: 200px;

      h3 {
        margin: 0;
      }
    }

    & .file-view {
      align-items: flex-start;
    }

    & .file-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: row;
      margin-bottom: 10px;
    }

    & .footer-info-block {
      text-align: right;
      & .footer-info-item {
        display: flex;
        justify-content: flex-end;
        min-height: 26px;
        align-items: center;
        & .editable-field-wrapper {
          display: flex;
          align-items: center;
        }
        & .editable-field .editable-field-wrapper:not(.read-only) {
          position: inherit;
        }
        &
          .editable-field-wrapper
          .editable-field
          .edit-view.read-mode
          .edit-view__value {
          line-height: 15px;
          font-weight: bold;
          &:after {
            font-weight: normal;
          }
        }
        label {
          padding-right: 10px;
        }
        & .finding-row {
          display: flex;
        }
      }
      label {
        font-weight: normal;
      }
      span {
        font-weight: bold;
      }
      & .assigned-to {
        label {
          margin-left: 0;
          width: 90px;
          margini-top: 2px;
        }
        & .icon {
          align-self: center;
          padding-left: 5px;
          font-weight: normal;
        }
        & .react-selectrix {
          width: 200px;
          .rs-toggle {
            text-align: left;
          }
        }
        & .section-spinner {
          width: 200px;
          padding: 0;
        }
        & .icon-close {
          background: ${colors.TBA_LIGHTEST_GREY};
          border: 1px solid ${colors.TBA_DARK2_GREY};
          border-left: none;
          padding: 6px;
        }
      }
      @media (max-width: ${screen_grid_small}) {
        text-align: left;
      }
    }
  }

  && .detail-view-page-pdf {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;
  }
`
export const ToolBarWrapper = styled.div`
  display: flex;
  a {
    margin-right: 8px;
  }
`
export const CSVLinkWrapper = styled.div`
  cursor: ${(props) => (props['no-data'] ? 'not-allowed' : 'pointer')};
  .csv-link {
    pointer-events: ${(props) => (props['no-data'] ? 'none' : 'all')};
  }
`