import COLORS from 'src/components/legacy/common/colors.json'

export const allTypes = [
  'ConsumptionCost',
  'DemandCost',
  'GasCost',
  'MonthlyFee'
] // shall we dynamically determine the types?

export const pointFormat = (
  content,
  useName = true,
  name = '{series.name}',
  description = '{series.description}',
  color = '{point.color}'
) =>
  useName
    ? `<tr><td style="padding:2px">
			<div style="display: flex;">
				<div style="background-color:${color}; width: 14px; height: 14px; margin-right: 5px"></div>
				<div style="overflow-wrap: break-word; word-break: break-all;">${name}:</div>
			</div>
		</td><td style="text-align: right">${content}</span></td></tr>`
    : `<tr><td style="padding:2px">
			<div style="display: flex; overflow-wrap: break-word;">
				<div style="background-color:${color}; width: 14px; height: 14px; margin-right: 5px"></div>
				${description}:
			</div>
		</td><td style="text-align: right; font-weight: bold;">${content}</span></td></tr>`

export const titleForType = (type) =>
  type.replace(/(.)([A-Z])/g, '$1 $2').replace(/^([^GT])/, 'Electric $1') // add "Electric " to all but gas and total

export const colorForType = (type) =>
  type === 'MonthlyFee'
    ? COLORS.TBA_GREEN
    : type === 'DemandCost'
    ? COLORS.BLUE
    : type === 'ConsumptionCost'
    ? COLORS.ORANGE
    : type === 'GasCost'
    ? COLORS.YELLOW
    : COLORS.GREY
