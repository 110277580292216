import i18next from "i18next"

export const PERIODS_LINK_TO_INTERVALS = new Map([
	[":stp/current-day", "day"],
	[":stp/current-week", "week"],
	[":stp/current-month", "month"],
	[":stp/current-quarter", "quarter"],
	[":stp/current-year", "year"],

	[":stp/past-hour", "hour"],
	[":stp/past-24-hours", "day"],
	[":stp/past-7-days", "week"],
	[":stp/past-14-days", "week"],
	[":stp/past-30-days", "month"],
	[":stp/past-90-days", "quarter"],
	[":stp/past-12-months", "year"],

	[":stp/previous-hour", "hour"],
	[":stp/previous-day", "day"],
	[":stp/previous-week", "week"],
	[":stp/previous-month", "month"],
	[":stp/previous-quarter", "quarter"],
	[":stp/previous-year", "year"],
	[":stp/previous-year-full", "year"],

	[":stp/comparison-day", "day"],
	[":stp/comparison-week", "week"],
	[":stp/comparison-month", "month"],
	[":stp/comparison-quarter", "quarter"],
	[":stp/comparison-year", "year"]
])

export const TIME_INTERVAL = new Map([
	["hour", [":ti/15-minutes"]],
	["day", [":ti/1-hour"]],
	["week", [":ti/1-day"]],
	["month", [":ti/1-day"]],
	["quarter", [":ti/1-month"]],
	["year", [":ti/1-quarter", ":ti/1-month"]]
])

export const formatStdTimeInterval = (id) => {
	return i18next.t(`time>${id}`, { nsSeparator: ">" }) || id
}
