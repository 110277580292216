import { GET_DASHBOARDS } from 'src/pages/dashboards/components/CreateDashboard/graphql'
import store from '../redux/store'
import { API } from 'aws-amplify'
import { useLayoutEffect, useState } from 'react'
import { Navigate } from 'react-router'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { accessControlFunc } from 'src/components/accessControl'
import { useSelector } from 'react-redux'
import { selectUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'
import { getUserSettingByUserId } from 'src/common/userSettingAPI'

const DashboardWrapper = () => {
  const hasAccessToRemoteAccess = accessControlFunc({
    id: 'tc.pages.connect-to-a-device'
  })
  const [dashboardData, setDashboardData] = useState<any>(null)
  const uiMode = useSelector(selectUiMode)

  if (uiMode === UiMode.denali) {
    return <Navigate to={`/home`} />
  }

  const getDashboard = async () => {
    try {
      const state: any = store.getState()
      const userId = state.appData.userInfo?.data?.id
      const variables = {
        userId,
        limit: 100
      }

      if (userId) {
        // fetches user default dashboard from user settings table
        const userSettings = await getUserSettingByUserId(userId, 'Dashboard')
        const userDefaultDashboardId = userSettings?.settings?.id
        const apiData: any = await API.graphql({
          query: GET_DASHBOARDS,
          variables
        })

        const data = apiData?.data?.listDashboardsByUser?.items?.find(
          (item: any) => item?.id === userDefaultDashboardId
        )

        if (data?.id) {
          setDashboardData(data)
        } else {
          setDashboardData(data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useLayoutEffect(() => {
    getDashboard()
  }, [])

  if (dashboardData === null) {
    return <Spinner />
  }

  return (
    <>
      {dashboardData?.id ? (
        <Navigate to={`/dashboard/${dashboardData?.id}`} />
      ) : (
        <Navigate
          to={hasAccessToRemoteAccess ? '/connect-device' : '/widget-library'}
        />
      )}
    </>
  )
}

export default DashboardWrapper
