import styled from 'styled-components'
import colors from 'src/components/layouts/colors.json'

export const AlertWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 10px;
  background: ${colors.WHITE};
  border: 2px solid ${colors.RED};
`

export const AlertIcon = styled.span`
  color: ${colors.RED};
  font-size: 22px;
`
export const AlertMessage = styled.span`
  margin-left: 9px;
  text-align: left;
  font-weight: bold;
  font-style: italic;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.26px;
  color: ${colors.RED};

  a {
    color: ${colors.RED};
    text-decoration: underline;
  }
`
