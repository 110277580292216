import { useFormikContext } from '../../MultiTabTorm'
import { useWidgetsContext } from '../../../WidgetsContextProvider'
import { EnergyCostWidgetPreview } from 'src/pages/dashboards/components/DashboardCardWidget/EnergyCostWidgetPreview'
import { WidgetPreviewWrapper } from '../../widgetPreviewWrapper'
import { WidgetChartContainer } from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { WidgetNames } from '../../../helpers'
import { useMemo } from 'react'

export const EnergyCostWidgetPreviewWrapper = () => {
  const formik = useFormikContext()
  const { values } = formik
  const { formikErrors, objectPickerSelectedValue } = useWidgetsContext()

  const getEquipmentList = () => {
    return [
      {
        buildingName: objectPickerSelectedValue?.[0]?.building?.name,
        equipmentName: objectPickerSelectedValue?.[0]?.equipmentName,
        accountName: objectPickerSelectedValue?.[0]?.accountName
      }
    ]
  }

  const getPreviewDetails = useMemo(
    () => ({
      widgetName: values?.name,
      widgetDescription: values?.description ?? '',
      buildingId: objectPickerSelectedValue?.[0]?.buildingId,
      equipmentId: objectPickerSelectedValue?.[0]?.equipmentId,
      equipmentType: objectPickerSelectedValue?.[0]?.equipmentType,
      buildingName: objectPickerSelectedValue?.[0]?.buildingName,
      equipmentName: objectPickerSelectedValue?.[0]?.equipmentName,
      benchMarker: values?.benchMarker?.split('/')?.[1] || '',
      benchMarkerLabel: values?.benchMarkerLabel ?? '',
      benchMarkerValue: values?.benchMarkerValue ?? '',
      benchmarkColor: values?.benchmarkColor,
      comparedTo: values?.comparedTo?.split('/')?.[1] || ':none',
      leftBarColor: values?.leftBarColor,
      timePeriod: values?.timePeriod,
      rightBarColor: values?.rightBarColor,
      toDateBarColor: values?.toDateBarColor,
      equipmentList: getEquipmentList(),
      timeZone: objectPickerSelectedValue?.[0]?.timeZone,
      isShowEnergyCostSegmentation:
        values?.isShowEnergyCostSegmentation === '0' ||
        values?.isShowEnergyCostSegmentation === false
          ? false
          : true
    }),
    [objectPickerSelectedValue, values]
  )

  return (
    <WidgetPreviewWrapper
      widgetName={values?.name}
      error={Object.keys(formikErrors)?.length > 0}
      chartType="energy-cost"
    >
      <WidgetChartContainer
        widgetType={WidgetNames?.EnergyCost}
        showLegends={getPreviewDetails?.comparedTo !== ':none'}
      >
        <EnergyCostWidgetPreview widgetDetails={getPreviewDetails} />
      </WidgetChartContainer>
    </WidgetPreviewWrapper>
  )
}
