/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GroupAndClip } from '../../../chart-library/CommonComponents/Clip'
import HeatMap from '../../../chart-library/CommonComponents/HeatMap'
import { useTooltip } from '../../../chart-library/CommonComponents/Tooltip'
import { initTooltipDataFormatter } from './tooltip'
import { timeFormat } from 'd3'
import { useConfig } from './useConfig'
import VisualLegend from '../../../chart-library/CommonComponents/VisualLegend'
import { useMemo, useState } from 'react'

const chartColor = ['#61AE34', '#FFF10B', '#D52B1E']

const visualLegendStyleProps: any = {
  xOffset: 20,
  colors: chartColor,
  axisProps: {
    className: 'heap-map-visual-legend-axis',
    tickFormat: (v) => v + ' kW',
    hideAxisLine: true,
    hideAxisTickLine: true,
    textProps: {
      textRotate: '340deg',
      textAnchor: 'end'
    }
  },
  gridProps: {
    opacity: 1
  },
  indicatorProps: {
    fill: '#666666'
  }
}

interface HeatMapSeries {
  data: HeatMap[]
  minValue: number
  maxValue: number
  enableTooltip: boolean
  tooltipDispatchEventName?: string
  tooltipFormatter?: (data: HeatMapData) => TooltipData[]
  unit: string
}

export default function Sereis({
  minValue = 0,
  maxValue = 0,
  data = [],
  tooltipDispatchEventName = '',
  enableTooltip = false,
  unit,
  tooltipFormatter
}: HeatMapSeries) {
  const { xAxis, xScale, yScale, canvas, isInitialized } = useConfig()

  const [indicatorValue, setIndicatorValue] = useState(null)

  const getVisualLegendStyleProps = (): any => {
    return {
      xOffset: 80,
      colors: chartColor,
      axisProps: {
        className: 'heap-map-visual-legend-axis',
        tickFormat: (v) => v + `${unit}`,
        tickCount: 15,
        hideAxisLine: true,
        hideAxisTickLine: true,
        textProps: {
          textRotate: '340deg',
          textAnchor: 'end'
        }
      },
      gridProps: {
        opacity: 1
      },
      indicatorProps: {
        fill: '#666666'
      }
    }
  }

  const callbackFns = useMemo(
    () => ({
      onMouseOver: (event, data, key) => {
        setIndicatorValue(isNaN(+data.value) ? null : +data.value)
        tooltipDispatch(event, key, true, data as any)
      },
      onMouseLeave: (event, _, key) => {
        setIndicatorValue(null)
        tooltipDispatch(event, key, false, null as any)
      }
    }),
    []
  )

  const [tooltipDispatch] = useTooltip({
    dispatchEventName: tooltipDispatchEventName,
    disable: !enableTooltip,
    formatter:
      tooltipFormatter ??
      initTooltipDataFormatter(timeFormat('%A,  %_B  %_d,  %_Y, %_I:%M %p'))
  })

  if (!isInitialized) {
    return null
  }

  const startAt = canvas.width / 5

  return (
    <GroupAndClip
      clipName={'heat-map-clip'}
      gx={canvas.x}
      gy={canvas.y}
      className="series"
    >
      <HeatMap
        xScale={xScale.fn}
        xScaleType={xScale.type}
        yScale={yScale.fn}
        yScaleType={yScale.type}
        minValueForColorScale={minValue}
        maxValueForColorScale={maxValue}
        data={data}
        enableHighlight
        colors={chartColor}
        rectProps={callbackFns}
      />
      <VisualLegend
        indicatorValue={indicatorValue}
        min={minValue}
        max={maxValue}
        x={startAt - 80}
        y={xAxis.y + 20}
        width={startAt * 3}
        height={15}
        {...getVisualLegendStyleProps()}
      />
    </GroupAndClip>
  )
}
