import BarRect from './BarRect'
import { GroupBarCompProps, GroupBars } from '../../Types/component/bar'
import * as d3 from 'd3'
import { useMemo } from 'react'

const defaultColor = 'steelblue'

const defaultData: GroupBars[] = []

export default function GroupBarChart(props: GroupBarCompProps) {
  const {
    className,
    barData = defaultData,
    isHorizontal = false,
    isInsideBar = false,
    xScale,
    yScale,
    yScale2 = null,
    yScale2BarPosition = null,
    enableToolTip = true,
    setTooltip,
    canvasHeight,
    measurement = null
  } = props

  const sublabels = Object.keys(barData?.[0]?.values?.map(({ value }) => value))
  const subscaleX = useMemo(() => {
    return isHorizontal ? d3
      .scaleBand()
      .domain(sublabels)
      .range([0, yScale?.bandwidth()])
      .padding(0.05)
      :
      d3
        .scaleBand()
        .domain(sublabels)
        .range([0, xScale?.bandwidth()])
        .padding(0.05)
  }, [xScale, yScale, barData])!

  // --------------------- render phase ---------------------

  return (
    <>
      {barData?.map(({ name, values, displayName }, groupIndex) => {
        return (
          <g
            key={`rect-group-${groupIndex}`}
            transform={isHorizontal ? `translate(0, ${yScale(name)})` : `translate(${xScale(name)}, 0)`}
          >
            {values?.map((barItem, barIndex) => {
              let xValue = 0
              let yValue = 0
              let heightValue = 0
              let widthValue = 0
              if (isHorizontal) {
                xValue = barItem?.value > 0 ? xScale(0) : xScale(0) - (xScale(0) - xScale(barItem?.value))
                yValue = subscaleX(String(barIndex)) || 0
                heightValue = subscaleX?.bandwidth()
                widthValue = barItem?.value > 0 ? xScale(barItem?.value) - xScale(0) : xScale(0) - xScale(barItem?.value)
              } else {
                if (isInsideBar) {
                  const xValueOffset =
                    (subscaleX?.bandwidth() - subscaleX?.bandwidth() / 2) / 2
                  xValue = subscaleX(String(barIndex)) + xValueOffset || 0
                  yValue = barItem?.value > 0 ? yScale(0) - (yScale(0) - yScale(barItem?.value)) : yScale(0)
                  heightValue = barItem?.value > 0 ? yScale(0) - yScale(barItem?.value) : yScale(barItem?.value) - yScale(0)
                  widthValue = subscaleX?.bandwidth() / 2
                } else {
                  xValue = subscaleX(String(barIndex)) || 0
                  yValue = yScale2 && barIndex === yScale2BarPosition ? barItem?.value > 0
                    ? yScale2(0) -
                    (yScale2(0) - yScale2(barItem?.value))
                    : yScale2(0)
                    :
                    barItem?.value > 0
                      ? yScale(0) -
                      (yScale(0) - yScale(barItem?.value))
                      : yScale(0)
                  heightValue = yScale2 && barIndex === yScale2BarPosition ? barItem?.value > 0
                    ? yScale2(0) - yScale2(barItem?.value)
                    : yScale2(barItem?.value) - yScale2(0)
                    : barItem?.value > 0
                      ? yScale(0) - yScale(barItem?.value)
                      : yScale(barItem?.value) - yScale(0)
                  widthValue = subscaleX?.bandwidth()
                }
              }
              return (
                <BarRect
                  key={`rect-${barIndex}`}
                  x={xValue}
                  y={yValue}
                  width={widthValue}
                  height={heightValue}
                  color={barItem?.color ?? defaultColor}
                  onMouseEnter={(event) => {
                    if (!enableToolTip) return
                    setTooltip({
                      x: event?.clientX,
                      y: event?.clientY,
                      index: groupIndex,
                      item: {
                        ...barItem, ...{
                          isSingleBarTooltip: barItem?.isSingleBarTooltip ?? false,
                          name: barItem?.name && barItem?.isSingleBarTooltip ? barItem?.name : name,
                          displayName: barItem?.name && barItem?.isSingleBarTooltip ? barItem?.name : displayName,
                          values,
                          measurement: barItem?.measurement ? barItem?.measurement : measurement ? measurement : ""
                        }
                      }
                    })
                  }}
                  onMouseLeave={() => {
                    if (!enableToolTip) return
                    props?.setTooltip(null)
                  }}
                />
              )
            })}
          </g>
        )
      })}
    </>
  )
}
