import { useEffect, useMemo, useRef } from "react";
import debounce from "lodash/debounce"

export {
  isClickOrKeyboardActivate,
  handleKeyboardActivate,
  getColor,
}

function handleKeyboardActivate(event, callback, params=[]) {
  if (isClickOrKeyboardActivate(event)) {
    callback(event, ...params);
  }
}

function isClickOrKeyboardActivate(event) {
  return (!event?.code || (event?.code === "Enter") || (event?.code === "Space"));
}

function getColor(key:string) {
  if(!key || (key === '')) return 'green'
  const colorMap = {
    'a': 'orange',
    'b': 'orange',
    'c': 'orange',
    'd': 'orange',
    'e': 'red',
    'f': 'red',
    'g': 'red',
    'h': 'red',
    'i': 'green',
    'j': 'green',
    'k': 'green',
    'l': 'green',
    'm': 'turquoise',
    'n': 'turquoise',
    'o': 'turquoise',
    'p': 'blue',
    'q': 'blue',
    'r': 'blue',
    's': 'blue',
    't': 'blue',
    'u': 'purple',
    'v': 'purple',
    'w': 'purple',
    'x': 'purple',
    'y': 'pink',
    'z': 'pink',
  }
  const color = colorMap[key[0]?.toLocaleLowerCase()];
  return color || 'turquoise';
}

export const useDebounce = ( callback:()=>void ) => {
  const ref = useRef(()=>undefined);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 300);
  }, []);

  return debouncedCallback;
};