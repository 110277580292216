import { EquipmentTypeList } from 'src/pages/equipmentSetup/CreateEquipment/constants'
export const getCSVForATHistory = history => {
    return [
    [
  ...(history.keys?.map(h => h.title) || [])
    ],
      ...(history.values?.map(vh => 
        (history.keys?.map(h => vh[h.key]))
      ) || [])
  ]}
  

const SETTING_LEVELS = {
    "B": "Building",
    "G": "Global",
    "E": "Equipment"
}

export const getSettingLevel = value => SETTING_LEVELS[value] || value


export const getEquipmentTypeDetails = () => {
  const equipmentsList = [
    { equipmentType: 'VRF-IDU', uiName: 'VRF Indoor Unit' },
    { equipmentType: 'VRF-ODU', uiName: 'VRF Outdoor Unit' },
    { equipmentType: 'VRF-System', uiName: 'VRF System' }
  ]

  for (const key in EquipmentTypeList) {
    const equipment = EquipmentTypeList[key]
    equipmentsList.push({
      equipmentType: equipment?.type,
      uiName: key
    })
  }
  return equipmentsList
}
