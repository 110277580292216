import {
  useEffect,
  useRef,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle
} from 'react'

import './style.scss'
import { Brush } from './Brush'
import { Track } from './Track'
import {
  convertRefToEl,
  dateTimeInMS,
  isDaysSameOrAfter,
  isInvalidEl,
  isValidDate
} from './helpers'
import { HEIGHT, MARGIN } from './constants'

import debounce from 'lodash.debounce'
import clsx from 'clsx'

type TimelinePostion = {
  startTime: string
  endTime: string
  startEndPosition: number[]
  domain: number[]
}

type GetTimelinePostionFunction = (postion: TimelinePostion) => void

type TimelineProps = {
  x?: number
  y?: number
  width?: number | string
  trackStartTime: string
  trackEndTime: string
  canSetBrushTimeByProps?: boolean
  brushStartTime?: string
  brushEndTime?: string
  getBrushPosition?: GetTimelinePostionFunction
  disableContext?: boolean
  multipleChart?: React.ReactNode[]
  children?: any
  [k: string]: any
}

export default forwardRef(function Timeline(
  {
    x = 0,
    y = 0,
    width = '100%',
    trackStartTime,
    trackEndTime,
    canSetBrushTimeByProps,
    brushStartTime,
    brushEndTime,
    getBrushPosition = () => null,
    disableContext = false,
    multipleChart = [],
    children = null,
    className
  }: TimelineProps,
  ref
) {
  const rectElRef = useRef(null)
  const [resize, setSize] = useState(null)
  const [position, setPosition] = useState(null)
  const [svgWidth, setSvgWidth] = useState(null)
  const [reset, setReset] = useState('')

  // attch reset fn on ref to allow the parent to use it
  useImperativeHandle(
    ref,
    () => ({
      reset() {
        setReset('' + Math.random())
      }
    }),
    []
  )

  // set svg width once references are initalized
  useEffect(() => {
    if (isInvalidEl(rectElRef)) {
      return () => null
    }

    const elWidth = setSvgSize(rectElRef)

    if (elWidth !== svgWidth) {
      setSvgWidth(elWidth)
    }
  }, [rectElRef.current !== null, resize])

  // Track component props
  const trackProps = useMemo(() => {
    return {
      startTime: dateTimeInMS(trackStartTime),
      endTime: dateTimeInMS(trackEndTime, 1)
    }
  }, [trackStartTime, trackEndTime])

  // Brush component props
  const brushProps = useMemo(() => {
    let activeProps = {}

    const brushStart =
      canSetBrushTimeByProps && isValidDate(brushStartTime)
        ? dateTimeInMS(brushStartTime)
        : null

    if (brushStart) activeProps['activeStartTime'] = brushStart

    const brushEnd =
      canSetBrushTimeByProps && isValidDate(brushEndTime)
        ? dateTimeInMS(brushEndTime)
        : null

    if (brushEnd) activeProps['activeEndTime'] = brushEnd

    const isValidDay = isDaysSameOrAfter(brushEndTime, brushStartTime)

    activeProps = isValidDay ? activeProps : null

    return {
      defaultStartTime: trackStartTime ? dateTimeInMS(trackStartTime) : null,
      defaultEndTime: trackEndTime ? dateTimeInMS(trackEndTime) : null,
      ...activeProps
    }
  }, [
    trackStartTime,
    trackEndTime,
    brushStartTime,
    brushEndTime,
    canSetBrushTimeByProps
  ])

  // pass the position to parent handler
  useEffect(() => {
    if (position !== null) {
      getBrushPosition(position)
    }
  }, [position])

  // set resize event listener on window
  useEffect(() => {
    // on resize, set random value to trigger render
    const handleResize = debounce(function () {
      setSize(Math.random())
    }, 150)

    const resizeObserver = new ResizeObserver(handleResize)

    if (rectElRef.current) {
      resizeObserver.observe(rectElRef.current)
    }
    return () => resizeObserver.disconnect()
  }, [])

  const brushComp = useMemo(() => {
    return (
      <Brush
        {...brushProps}
        getBrushPosition={setPosition}
        svgWidth={svgWidth}
        resetBrush={reset}
      />
    )
  }, [brushProps, svgWidth])

  return (
    <svg
      width={width}
      height={HEIGHT}
      x={x}
      y={y}
      className={clsx('t-chart-timeline', className)}
    >
      <rect ref={rectElRef} width="100%" height="100%" fill="transparent" />
      {svgWidth && (
        <Track {...trackProps} svgWidth={svgWidth} brush={brushComp}>
          <foreignObject width={svgWidth} height={HEIGHT}>
            {children}
          </foreignObject>
          {multipleChart.map((c, i) => (
            <foreignObject width={svgWidth} height={HEIGHT} key={i}>
              {c}
            </foreignObject>
          ))}
        </Track>
      )}
    </svg>
  )
})

function setSvgSize(rectElRef) {
  const svgEl = convertRefToEl(rectElRef.current)
  const width = svgEl?.node()?.getBoundingClientRect()?.width - 2 * MARGIN.left

  return width || 0
}
