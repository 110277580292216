// React/Redux Libraries \\
import React, { Fragment } from 'react'
import { string, node, bool } from 'prop-types'
import { TooltipContainer, StyledTooltip, TooltipArrow } from './styles'

const Tooltip = (props) => {
  const {
    type = '',
    content,
    position = 'center-bottom',
    icon,
    marginWrapper,
    size,
    htmlTag = 'button',
    paddingWrapper,
    textWrap = false,
    disabled = false,
    width,
    maxWidth,
    minWidth,
    children,
    className
  } = props
  return !disabled ? (
    <TooltipContainer
      className={className}
      size={size}
      htmlTag={htmlTag}
      marginWrapper={marginWrapper}
      paddingWrapper={paddingWrapper}
      type={type}
      width={width}
      icon={icon}
      disabled={disabled}
    >
      {children}
      <StyledTooltip
        type={type}
        position={position}
        textWrap={textWrap}
        width={width}
        maxWidth={maxWidth}
        minWidth={minWidth}
      >
        {content}
        <TooltipArrow type={type} position={position} />
      </StyledTooltip>
    </TooltipContainer>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

Tooltip.propTypes = {
  type: string,
  position: string,
  htmlTag: string,
  iconSize: string,
  color: string,
  textWrap: bool,
  disabled: bool,
  children: node
}

export default Tooltip
