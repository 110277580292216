var d3Locale = {}

d3Locale['en_US'] = d3Locale['en'] = {
  decimal: '.',
  thousands: ',',
  grouping: [3],
  currency: ['$', ''],
  dateTime: '%x %I:%M%p',
  date: '%m/%d/%Y',
  time: '%I:%M:%S%p',
  timeShort: '%I%p',
  minutes: '%I:%M',
  minutesLong: '%I:%M%p',
  dateTimeShort: '%d %b, %I:%M%p',
  dateLong: '%d %B, %Y',
  dateShort: '%d %b',
  dateShortMonthYear: '%m/%Y',
  periods: [' AM', ' PM'],
  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ],
  shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
}

export default d3Locale
