import React from 'react'
import PortfolioBuilding from './PortfolioBuilding'
import { PortfolioBuildingsContainer } from './styles'

const PortfolioBuildings = ({
  buildingsList,
  buildingsOrder,
  chartDates,
  updateTotalCost,
  selectedUnit,
  theHighestTotal,
  disabledTypes
}) => {
  return (
    <PortfolioBuildingsContainer data-testid="portfolio-scrore-locations-list">
      {buildingsList &&
        buildingsList?.map((building) => (
          <PortfolioBuilding
            chartDates={chartDates}
            building={building}
            buildingsOrder={buildingsOrder}
            key={building.key}
            updateTotalCost={updateTotalCost}
            selectedUnit={selectedUnit}
            theHighestTotal={theHighestTotal}
            disabledTypes={disabledTypes}
          />
        ))}
    </PortfolioBuildingsContainer>
  )
}

export default React.memo(PortfolioBuildings)
