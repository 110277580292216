import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import { useLocation, useNavigate } from 'react-router-dom'
import { pages } from 'src/pages/pages.js'
import {
  LIST_RATE_STRUCTURES_BY_SALESOFFICE_ID,
  CREATE_CHARGE_EQUIPMENT,
  DELETE_CHARGE_EQUIPMENT,
  SEARCH_BUILDINGS_FOR_EQUIPMENT_BY_SALESOFFICE_ID_EQUIPMENTSUB
} from './queries'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { useEffect, useState } from 'react'
import Table from 'src/components/Table/clientSideTable.js'
import translate, { TranslateComponent } from 'src/common/translations'
import { ACTIONS } from 'src/constants'
import { DELETE_RATE_STRUCTURE } from './createRateStructure/queries'
import { DELETE_CHARGE } from './createCharge/queries'
import IconSvg from 'src/components/Icon/index.js'
import Modal from 'src/components/legacy/components/modal/modal'
import { ConfirmationText } from 'src/components/layouts'
import Dropdown from 'src/components/legacy/components/dropdown'
import DropdownContainer from 'src/components/legacy/components/dropdown/dropdown-container'
import { StyledCreatDropdown } from 'src/pages/documentsAndReports/styles'
import { Button } from 'src/components/inputs/button'
import moment from 'moment'
import ObjectPicker from 'src/pages/eventMarkers/EventMarkerDetail/objectPickerNew'
import styled from 'styled-components'
import Icon from 'src/components/Icon'
import { useSelector } from 'react-redux'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { accessControlFunc } from 'src/components/accessControl'
import Spinner from 'src/components/Spinner'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

const StyledListItem = styled.li``

const StyledUnorderedList = styled.ul`
  width: 280px;
  padding: 12px;
`

const StyledFlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const TextSpan = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`

export const RateStructureLibrary = () => {
  const navigate = useNavigate()
  const userAccess = useSelector(selectUserAccess)
  const { salesOfficeId } = getSearchParams()
  const location = useLocation()
  const [tableRows, setTableRows] = useState([])
  const [modalBaseData, setModalBaseData] = useState(null)
  const [modalBaseDataId, setModalBaseDataId] = useState(null)
  const [rateStructureToDelete, setRateStructureToDelete] = useState(null)
  const [createRateStructureChargeId, setCreateRateStructureChargeId] =
    useState(null)
  const [chargeToDelete, setChargeToDelete] = useState(null)
  const [chargeToModifyId, setChargeToModifyId] = useState(null)
  const [modalData, setModalData] = useState(null)
  const [createdRateStructureEntity, setCreatedRateStructureEntity] = useState(
    location?.state?.createdRateStructureEntity
  )
  const [updatedRateStructureEntity, setUpdatedRateStructureEntity] = useState(
    location?.state?.updatedRateStructureEntity
  )
  const [deletedRateStructureEntity, setDeletedRateStructureEntity] = useState(
    location?.state?.deletedRateStructureEntity
  )
  const [
    createdRateStructureChargeEntity,
    setCreatedRateStructureChargeEntity
  ] = useState(location?.state?.createdRateStructureChargeEntity)
  const [
    updatedRateStructureChargeEntity,
    setUpdatedRateStructureChargeEntity
  ] = useState(location?.state?.updatedRateStructureChargeEntity)
  const [
    deletedRateStructureChargeEntity,
    setDeletedRateStructureChargeEntity
  ] = useState(location?.state?.deletedRateStructureChargeEntity)

  const optionsTranslated = {
    rateStructure: translate('Rate Structure & Charges'),
    buildingMeter: translate('Building / Meter'),
    status: translate('Status'),
    type: translate('Type'),
    create: translate('CREATE'),
    createERS: translate('Create Electric Rate Structure'),
    createGRS: translate('Create Gas Rate Structure')
  }

  // Made this use effect because a direct function calling navigate would change url but not actually modify the component.
  useEffect(() => {
    if (createRateStructureChargeId) {
      const page = pages['Create Rate Structure Charge'].href.replace(
        ':rateStructureId',
        createRateStructureChargeId
      )
      navigate(page, {
        state: { mode: ACTIONS.ADD }
      })
    }
  }, [createRateStructureChargeId])

  const { onSubmit: deleteRateStructureMutation } = useMutation({
    query: DELETE_RATE_STRUCTURE,
    onSuccess: async () => {
      setDeletedRateStructureEntity(
        JSON.parse(JSON.stringify(rateStructureToDelete))?.name
      )
      setRateStructureToDelete(null)
      refetchData({ id: salesOfficeId })
      trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.DELETE_RATE_STRUCTURE)
    }
  })

  const { onSubmit: deleteChargeeMutation } = useMutation({
    query: DELETE_CHARGE,
    onSuccess: async () => {
      setDeletedRateStructureChargeEntity(
        JSON.parse(JSON.stringify(chargeToDelete))?.name
      )
      setChargeToDelete(null)
      refetchData({ id: salesOfficeId })
      trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.DELETE_CHARGE, {name:chargeToDelete.name})
    }
  })

  const { onSubmit: createChargeEquipmentMutation } = useMutation({
    query: CREATE_CHARGE_EQUIPMENT
  })

  const { onSubmit: deleteChargeEquipmentMutation } = useMutation({
    query: DELETE_CHARGE_EQUIPMENT
  })

  const tableHeadings = [
    {
      name: 'name',
      title: optionsTranslated.rateStructure,
      key: 'name',
      maxWidth: '120px',
      onDataClick: (data) => {
        trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.VIEW_RATE, {name:data.name, id:data.id})
        if (
          data.typeSort === 'Electric Charge' ||
          data.typeSort === 'Gas Charge'
        ) {
          navigate(
            pages['Edit Rate Structure Charge'].href.replace(
              ':chargeId',
              data.id
            ),
            { state: { mode: ACTIONS.VIEW } }
          )
        } else {
          navigate(
            pages['Edit Rate Structure'].href.replace(
              ':rateStructureId',
              data.id
            ),
            { state: { mode: ACTIONS.VIEW } }
          )
        }
      }
    },
    {
      name: 'equipment',
      title: optionsTranslated.buildingMeter,
      key: 'equipment',
      maxWidth: '120px',
      disabled: true
    },
    {
      name: 'status',
      title: optionsTranslated.status,
      key: 'status',
      sortField: 'statusSort',
      maxWidth: '120px',
      disabled: true
    },
    {
      name: 'type',
      title: optionsTranslated.type,
      key: 'type',
      disabled: true
    },
    {
      name: 'addCharges',
      title: '',
      key: 'addCharges',
      disabled: true,
      alignRight: true
    }
  ]

  const {
    data: rateStructureData,
    refetch: refetchData,
    responseTime: responseTime
  } = useQuery({
    query: LIST_RATE_STRUCTURES_BY_SALESOFFICE_ID,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.listRateStructureBySalesOffice.items',
    onSuccess: (res) => {
      setChargeToDelete(null)
      setTableRows(
        res?.length
          ? res.map((e) => ({
              name: e.name,
              id: e.id,
              typeSort: e.type === 'electric' ? 'Electric Rate' : 'Gas Rate',
              type:
                e.type === 'electric' ? (
                  <>
                    <IconSvg
                      name="energy-roi"
                      margin="0px 4px -2px 0px"
                      padding="0px 0px 0px 0px"
                      color="rgb(85, 85, 85)"
                      hover="rgb(85, 85, 85)"
                      width="16px"
                      height="16px"
                    />
                    Electric Rate
                  </>
                ) : (
                  <>
                    <IconSvg
                      name="gas"
                      margin="0px 4px -2px 0px"
                      padding="0px 0px 0px 0px"
                      color="rgb(85, 85, 85)"
                      hover="rgb(85, 85, 85)"
                      width="16px"
                      height="16px"
                    />
                    Gas Rate
                  </>
                ),
              addCharges: accessControlFunc({
                id: 'tc.pages.rate-structure-library.charge.add',
                userAccess
              }) ? (
                <Button
                  testName={`${e?.id}-add-charges`}
                  key={`${e.id}-AddCharge`}
                  onClick={() => {
                    setCreateRateStructureChargeId(e.id)
                    trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CLICK_ADD_CHARGES)
                  }}
                >
                  ADD CHARGES
                </Button>
              ) : (
                ''
              ),
              subData: e.charges?.items?.map((x) => {
                const status =
                  (!x.startDate && !x.endDate) ||
                  (!x.startDate && moment(x.endDate).isAfter(moment())) ||
                  (moment().isAfter(x.startDate) && !x.endDate) ||
                  (moment().isAfter(x.startDate) &&
                    moment().isBefore(x.endDate))
                    ? 'Active'
                    : x.startDate && moment().isBefore(moment(x.startDate))
                    ? 'Future'
                    : 'Past'
                const newData = {
                  startDate: x.startDate
                    ? moment(x.startDate).format('MM-DD-YYYY')
                    : 'INDEFINITE',
                  endDate: x.endDate
                    ? moment(x.endDate).format('MM-DD-YYYY')
                    : 'INDEFINITE',
                  name: `${
                    x.startDate
                      ? moment(x.startDate).format('MM-DD-YYYY')
                      : 'INDEFINITE'
                  } - ${
                    x.endDate
                      ? moment(x.endDate).format('MM-DD-YYYY')
                      : 'INDEFINITE'
                  }`,
                  status:
                    status === 'Active' ? (
                      <span style={{ color: 'rgb(97, 174, 52)' }}>
                        {status}
                      </span>
                    ) : (
                      status
                    ),
                  statusSort: status,
                  id: x.id,
                  typeSort:
                    e.type === 'electric' ? 'Electric Charge' : 'Gas Charge',
                  type:
                    e.type === 'electric' ? (
                      <>
                        <IconSvg
                          name="electric"
                          margin="0px 4px -2px 0px"
                          padding="0px 0px 0px 0px"
                          color="rgb(85, 85, 85)"
                          hover="rgb(85, 85, 85)"
                          width="16px"
                          height="16px"
                        />
                        Electric Charge
                      </>
                    ) : (
                      <>
                        <IconSvg
                          name="gas"
                          margin="0px 4px -2px 0px"
                          padding="0px 0px 0px 0px"
                          color="rgb(85, 85, 85)"
                          hover="rgb(85, 85, 85)"
                          width="16px"
                          height="16px"
                        />
                        Gas Charge
                      </>
                    ),
                  meters: x?.meters?.items
                }

                return {
                  ...newData,
                  equipment:
                    modalBaseData &&
                    modalBaseDataId === salesOfficeId &&
                    !loadingQueryEquip ? (
                      newData?.meters.length ? (
                        <StyledFlexDiv>
                          <StyledUnorderedList>
                            {newData.meters
                              .map((o) => {
                                const index = modalBaseData.findIndex(
                                  (e) => e.id === o.buildingId
                                )
                                if (index !== -1) {
                                  const subDataIndex = modalBaseData[
                                    index
                                  ].subData.findIndex(
                                    (x) => x.id === o.equipmentId
                                  )
                                  if (subDataIndex !== -1) {
                                    return {
                                      id: o.id,
                                      name: `${
                                        modalBaseData[index].name
                                      } / ${modalBaseData[index].subData?.[
                                        subDataIndex
                                      ]?.name.toString()}`
                                    }
                                  } else {
                                    return null
                                  }
                                }
                              })
                              .filter((e) => e)
                              .sort((a, b) => {
                                a = a.name.toLowerCase()
                                b = b.name.toLowerCase()
                                if (a == b) return 0
                                if (a > b) return 1
                                return -1
                              })
                              .map((o) => (
                                <StyledListItem key={`${e.id}-${x.id}-${o.id}`}>
                                  <TextSpan>{o.name}</TextSpan>
                                </StyledListItem>
                              ))}
                          </StyledUnorderedList>
                          <Icon
                            name="edit"
                            color="#1E1E1E"
                            margin="auto"
                            onClick={() => {
                              setChargeToModifyId(newData)
                            }}
                            height="100%"
                            width="15px"
                          />
                        </StyledFlexDiv>
                      ) : accessControlFunc({
                          id: 'tc.pages.rate-structure-library.charge.edit',
                          userAccess
                        }) ? (
                        <Button
                          key={`${e.id}-AddEquipment`}
                          onClick={() => {
                            setChargeToModifyId(newData)
                          }}
                        >
                          ADD METER(S)
                        </Button>
                      ) : (
                        ''
                      )
                    ) : (
                      <Spinner style={{ padding: '12px 0px 0px 12px' }} />
                    )
                }
              })
            }))
          : []
      )
    }
  })

  const { refetch: refetchMeterDataEquipmentSub, loading: loadingQueryEquip } =
    useQuery({
      query: SEARCH_BUILDINGS_FOR_EQUIPMENT_BY_SALESOFFICE_ID_EQUIPMENTSUB,
      disableInitialLoad: true,
      errorPolicy: 'global',
      dataPath: 'data.searchBuildings'
    })

  useEffect(() => {
    if (modalBaseData && tableRows.length) {
      const updatedRows = tableRows.map((e) => {
        return {
          ...e,
          subData: e.subData.map((s) => {
            return {
              ...s,
              equipment: s?.meters?.length ? (
                <StyledFlexDiv>
                  <StyledUnorderedList>
                    {s.meters.map((o) => {
                      const index = modalBaseData.findIndex(
                        (e) => e.id === o.buildingId
                      )
                      if (index !== -1) {
                        const subDataIndex = modalBaseData[
                          index
                        ].subData.findIndex((x) => x.id === o.equipmentId)
                        if (subDataIndex !== -1) {
                          return (
                            <StyledListItem key={`${e.id}-${s.id}-${o.id}`}>
                              <TextSpan>{`${
                                modalBaseData[index].name
                              } / ${modalBaseData[index].subData?.[
                                subDataIndex
                              ]?.name.toString()}`}</TextSpan>
                            </StyledListItem>
                          )
                        } else {
                          return <></>
                        }
                      } else {
                        return <></>
                      }
                    })}
                  </StyledUnorderedList>
                  <Icon
                    name="edit"
                    color="#1E1E1E"
                    margin="auto"
                    onClick={() => {
                      setChargeToModifyId(s)
                    }}
                    height="100%"
                    width="15px"
                  />
                </StyledFlexDiv>
              ) : accessControlFunc({
                  id: 'tc.pages.rate-structure-library.charge.edit',
                  userAccess
                }) ? (
                <Button
                  key={`${e.id}-AddEquipment`}
                  onClick={() => {
                    setChargeToModifyId(s)
                  }}
                >
                  ADD METER(S)
                </Button>
              ) : (
                ''
              )
            }
          })
        }
      })

      setTableRows(updatedRows)
    }
  }, [modalBaseData])

  useEffect(() => {
    const fetchBuildingFunc = async (token) => {
      if (token) {
        return await Promise.all([
          refetchMeterDataEquipmentSub({
            filter: {
              and: [
                { salesOfficeId: { eq: salesOfficeId } },
                { isActive: { eq: 1 } }
              ]
            },
            limit: 1000,
            nextToken: token
          })
        ]).then(async (blah) => {
          const res = blah[0]
          if (res.items.length === 1000 && res.nextToken) {
            const blah2 = JSON.parse(JSON.stringify(res.items))
            return await fetchBuildingFunc(res.nextToken).then((res2) => {
              return [...blah2, ...(res2 || [])]
            })
          } else {
            return res.items
          }
        })
      } else {
        return await Promise.all([
          refetchMeterDataEquipmentSub({
            filter: {
              and: [
                { salesOfficeId: { eq: salesOfficeId } },
                { isActive: { eq: 1 } }
              ]
            },
            limit: 1000
          })
        ]).then(async (blah) => {
          const res = blah[0]
          if (res.items.length === 1000 && res.nextToken) {
            const blah2 = JSON.parse(JSON.stringify(res.items))
            return await fetchBuildingFunc(res.nextToken).then((res2) => {
              return [...blah2, ...(res2 || [])]
            })
          } else {
            return res.items
          }
        })
      }
    }

    const triggerFunc = async () => {
      if (salesOfficeId) {
        fetchBuildingFunc(null).then(async (res) => {
          await Promise.all(
            res.map(async (e) => {
              return {
                name: e.name,
                id: e.id,
                checked: false,
                disabled: true,
                subData: [
                  ...(e?.electricMeters?.items
                    ?.map((x) => ({
                      id: x.id,
                      parentId: e.id,
                      name: x.name,
                      checked: false,
                      type: x.type,
                      family: x.family
                    }))
                    .sort((a, b) => {
                      a = a.name.toLowerCase()
                      b = b.name.toLowerCase()
                      if (a == b) return 0
                      if (a > b) return 1
                      return -1
                    }) || []),
                  ...(e?.gasMeters?.items
                    ?.map((x) => ({
                      id: x.id,
                      parentId: e.id,
                      name: x.name,
                      checked: false,
                      type: x.type,
                      family: x.family
                    }))
                    .sort((a, b) => {
                      a = a.name.toLowerCase()
                      b = b.name.toLowerCase()
                      if (a == b) return 0
                      if (a > b) return 1
                      return -1
                    }) || [])
                ]
              }
            })
          ).then((res) => {
            const responseToSave = res
              .sort((a, b) => {
                a = a.name.toLowerCase()
                b = b.name.toLowerCase()
                if (a == b) return 0
                if (a > b) return 1
                return -1
              })
              .filter((e) => e.subData.length > 0)
            setModalBaseData(responseToSave)
            setModalBaseDataId(salesOfficeId)
          })
        })
        refetchData({ id: salesOfficeId })
      }
    }
    triggerFunc()
  }, [salesOfficeId])

  useEffect(() => {
    if (chargeToModifyId !== null) {
      const allCharges = rateStructureData.reduce((accum, curr) => {
        return accum.concat(curr?.charges?.items || [])
      }, [])

      const currCharge =
        allCharges[allCharges.findIndex((e) => e.id === chargeToModifyId.id)]
      const startDate = currCharge?.startDate
        ? new Date(currCharge.startDate).valueOf()
        : 0
      const endDate = currCharge?.endDate
        ? new Date(currCharge.endDate).valueOf()
        : Infinity
      const otherCharges = allCharges.reduce((accum, curr) => {
        if (curr.id == chargeToModifyId.id) {
          return accum
        } else {
          const tempStartDate = curr?.startDate
            ? new Date(curr.startDate).valueOf()
            : 0
          const tempEndDate = curr?.endDate
            ? new Date(curr.endDate).valueOf()
            : Infinity
          if (
            (endDate === Infinity && startDate === 0) ||
            (tempEndDate === Infinity && tempStartDate === 0) ||
            (startDate >= tempStartDate && startDate <= tempEndDate) ||
            (endDate >= tempStartDate && endDate <= tempEndDate) ||
            (startDate <= tempStartDate && startDate && endDate >= tempEndDate)
          ) {
            return accum.concat(curr)
          } else {
            return accum
          }
        }
      }, [])

      const currChargeEquipIds = currCharge.meters.items.reduce(
        (accum, curr) => {
          accum[curr.equipmentId] = true
          return accum
        },
        {}
      )

      const otherChargeEquipIds = otherCharges.reduce((accum, curr) => {
        curr?.meters?.items?.forEach((e) => {
          accum[e.equipmentId] = true
        })
        return accum
      }, {})

      const modalBaseDataClone = JSON.parse(JSON.stringify(modalBaseData))

      for (let i = 0; i < modalBaseDataClone.length; i++) {
        for (let j = 0; j < modalBaseDataClone[i].subData.length; j++) {
          if (currChargeEquipIds[modalBaseDataClone[i].subData[j].id]) {
            modalBaseDataClone[i].subData[j].checked = true
          } else if (otherChargeEquipIds[modalBaseDataClone[i].subData[j].id]) {
            modalBaseDataClone[i].subData[j].disabled = true
          }
        }
      }
      setModalData(modalBaseDataClone)
    }
  }, [chargeToModifyId])

  return (
    <>
      <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
        {chargeToModifyId && modalData && (
          <Modal
            heading="Connect Charges with Meters"
            buttons={[
              {
                text: 'Save',
                handleClick: async () => {
                  const allCharges = rateStructureData.reduce((accum, curr) => {
                    return accum.concat(curr?.charges?.items || [])
                  }, [])
                  const currCharge =
                    allCharges[
                      allCharges.findIndex((e) => e.id === chargeToModifyId.id)
                    ]
                  const allCurrentEquipments = currCharge.meters.items.map(
                    (e) => e.equipmentId
                  )

                  const selectedEquipment = modalData.reduce((accum, curr) => {
                    return accum.concat(
                      curr?.subData.filter((e) => e.checked && !e.disabled) ||
                        []
                    )
                  }, [])

                  const selectedEquipmentToAdd = selectedEquipment.filter(
                    (e) => !allCurrentEquipments.includes(e.id)
                  )

                  const selectedEquipmentToRemove =
                    currCharge.meters.items.filter((e) => {
                      return !selectedEquipment.some(
                        (x) => x.id === e.equipmentId
                      )
                    })
                  return Promise.all([
                    ...selectedEquipmentToAdd.map((e) =>
                      createChargeEquipmentMutation({
                        input: {
                          chargeId: chargeToModifyId.id,
                          equipmentId: e.id,
                          salesOfficeId: salesOfficeId,
                          buildingId: e.parentId
                        }
                      })
                    ),
                    ...selectedEquipmentToRemove.map((e) =>
                      deleteChargeEquipmentMutation({ input: { id: e.id } })
                    )
                  ]).then(async (res) => {
                    if (res?.length) {
                      await refetchData({ id: salesOfficeId })
                      setChargeToModifyId(null)
                      setModalData(null)
                    }
                  })
                },
                type: 'valid'
              },
              {
                text: 'Cancel',
                handleClick: () => {
                  setChargeToModifyId(null)
                  setModalData(JSON.parse(JSON.stringify(modalData)))
                },
                type: 'cancel'
              }
            ]}
            handleClose={() => {
              setChargeToModifyId(null)
              setModalData(JSON.parse(JSON.stringify(modalData)))
            }}
          >
            <ObjectPicker
              label=""
              values={modalData.reduce((accum, curr) => {
                if (chargeToModifyId.typeSort === 'Electric Charge') {
                  const subData = curr.subData.filter(
                    (e) => e.type === 'ElectricMeter'
                  )
                  if (subData.length) {
                    accum.push({ ...curr, subData })
                  }
                } else {
                  const subData = curr.subData.filter((e) => e.type === 'GAS')
                  if (subData.length) {
                    accum.push({ ...curr, subData })
                  }
                }
                return accum
              }, [])}
              header={'Select objects:'}
              onCheckboxUnclick={(e) => {
                if (e?.parentId) {
                  const newModalData = JSON.parse(JSON.stringify(modalData))
                  const index = newModalData.findIndex(
                    (x) => e.parentId === x.id
                  )
                  const subIndex = newModalData[index].subData.findIndex(
                    (x) => e.id === x.id
                  )
                  newModalData[index].subData[subIndex].checked =
                    !newModalData[index].subData[subIndex].checked
                  setModalData(newModalData)
                } else {
                  const newModalData = JSON.parse(JSON.stringify(modalData))
                  const index = newModalData.findIndex((x) => e.id === x.id)
                  newModalData[index].checked = !newModalData[index].checked
                  setModalData(newModalData)
                }
              }}
              onCheckboxClick={(e) => {
                if (e?.parentId) {
                  const newModalData = JSON.parse(JSON.stringify(modalData))
                  const index = newModalData.findIndex(
                    (x) => e.parentId === x.id
                  )
                  const subIndex = newModalData[index].subData.findIndex(
                    (x) => e.id === x.id
                  )
                  newModalData[index].subData[subIndex].checked =
                    !newModalData[index].subData[subIndex].checked
                  setModalData(newModalData)
                } else {
                  const newModalData = JSON.parse(JSON.stringify(modalData))
                  const index = newModalData.findIndex((x) => e.id === x.id)
                  newModalData[index].checked = !newModalData[index].checked
                  setModalData(newModalData)
                }
              }}
              error={false}
            />
          </Modal>
        )}
        {rateStructureToDelete && (
          <Modal
            heading="Delete Rate Structure"
            buttons={[
              {
                text: 'Yes',
                handleClick: () =>
                  deleteRateStructureMutation({
                    input: { id: rateStructureToDelete?.id }
                  }),
                type: 'valid'
              },
              {
                text: 'No',
                handleClick: () => setRateStructureToDelete(null),
                type: 'cancel'
              }
            ]}
            handleClose={() => setRateStructureToDelete(null)}
          >
            <ConfirmationText>
              Are you sure you would like to delete the{' '}
              {rateStructureToDelete.name} rate structure?
            </ConfirmationText>
          </Modal>
        )}
        {createdRateStructureEntity && (
          <Modal
            heading="Success"
            buttons={[
              {
                text: 'Close',
                handleClick: () => setCreatedRateStructureEntity(null),
                type: 'cancel'
              }
            ]}
            handleClose={() => setCreatedRateStructureEntity(null)}
          >
            <ConfirmationText>
              {`The rate structure ${createdRateStructureEntity} has been created.`}
            </ConfirmationText>
          </Modal>
        )}
        {updatedRateStructureEntity && (
          <Modal
            heading="Success"
            buttons={[
              {
                text: 'Close',
                handleClick: () => setUpdatedRateStructureEntity(null),
                type: 'cancel'
              }
            ]}
            handleClose={() => setUpdatedRateStructureEntity(null)}
          >
            <ConfirmationText>
              {`The rate structure ${updatedRateStructureEntity} has been updated.`}
            </ConfirmationText>
          </Modal>
        )}
        {deletedRateStructureEntity && (
          <Modal
            heading="Success"
            buttons={[
              {
                text: 'Close',
                handleClick: () => setDeletedRateStructureEntity(null),
                type: 'cancel'
              }
            ]}
            handleClose={() => setDeletedRateStructureEntity(null)}
          >
            <ConfirmationText>
              {`The rate structure ${deletedRateStructureEntity} has been deleted.`}
            </ConfirmationText>
          </Modal>
        )}

        {createdRateStructureChargeEntity && (
          <Modal
            heading="Success"
            buttons={[
              {
                text: 'Close',
                handleClick: () => setCreatedRateStructureChargeEntity(null),
                type: 'cancel'
              }
            ]}
            handleClose={() => setCreatedRateStructureChargeEntity(null)}
          >
            <ConfirmationText>
              {`The rate structure charge for dates ${createdRateStructureChargeEntity} has been created.`}
            </ConfirmationText>
          </Modal>
        )}
        {updatedRateStructureChargeEntity && (
          <Modal
            heading="Success"
            buttons={[
              {
                text: 'Close',
                handleClick: () => setUpdatedRateStructureChargeEntity(null),
                type: 'cancel'
              }
            ]}
            handleClose={() => setUpdatedRateStructureChargeEntity(null)}
          >
            <ConfirmationText>
              {`The rate structure charge for dates ${updatedRateStructureChargeEntity} has been updated.`}
            </ConfirmationText>
          </Modal>
        )}
        {deletedRateStructureChargeEntity && (
          <Modal
            heading="Success"
            buttons={[
              {
                text: 'Close',
                handleClick: () => setDeletedRateStructureChargeEntity(null),
                type: 'cancel'
              }
            ]}
            handleClose={() => setDeletedRateStructureChargeEntity(null)}
          >
            <ConfirmationText>
              {`The rate structure charge for dates ${deletedRateStructureChargeEntity} has been deleted.`}
            </ConfirmationText>
          </Modal>
        )}

        {chargeToDelete && (
          <Modal
            heading="Delete Charge"
            buttons={[
              {
                text: 'Yes',
                handleClick: () =>
                  deleteChargeeMutation({ input: { id: chargeToDelete?.id } }),
                type: 'valid'
              },
              {
                text: 'No',
                handleClick: () => setChargeToDelete(null),
                type: 'cancel'
              }
            ]}
            handleClose={() => setChargeToDelete(null)}
          >
            <ConfirmationText>
              {`Are you sure you would like to delete this charge for the time period of ${chargeToDelete.startDate} to ${chargeToDelete.endDate}?`}
            </ConfirmationText>
          </Modal>
        )}
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <PageHeader
            pageTitle={
              <TranslateComponent>Rate Structure Library</TranslateComponent>
            }
            titleTools={
              accessControlFunc({
                id: 'tc.pages.rate-structure-library.add',
                userAccess
              }) ? (
                <StyledCreatDropdown disabled={!salesOfficeId}>
                  <Dropdown.Box>
                    <Dropdown.Button title={optionsTranslated.create} />
                    <DropdownContainer>
                      <>
                        <Dropdown.Item
                          testName="rate-electric-create"
                          styleNames="report-item tooltip-width"
                          onClick={() =>
                            {navigate(
                              pages['Create Rate Structure'].href
                                .replace(':type', 'electric')
                                .replace(':salesOfficeId', salesOfficeId)
                            )
                            trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CLICK_CREATE_RATE_STRUCTURE, {type:"electric"})
                            }
                          }
                        >
                          {optionsTranslated.createERS}
                        </Dropdown.Item>
                        <Dropdown.Item
                          testName="rate-gas-create"
                          styleNames="report-item tooltip-width"
                          onClick={() =>
                            {navigate(
                              pages['Create Rate Structure'].href
                                .replace(':type', 'gas')
                                .replace(':salesOfficeId', salesOfficeId)
                            )
                            trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CLICK_CREATE_RATE_STRUCTURE, {type:"gas"})
                          }
                          }
                        >
                          {optionsTranslated.createGRS}
                        </Dropdown.Item>
                      </>
                    </DropdownContainer>
                  </Dropdown.Box>
                </StyledCreatDropdown>
              ) : (
                ''
              )
            }
          />
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <Table
            rows={tableRows}
            header={tableHeadings}
            loadTime={responseTime}
            search={true}
            testName="rate-structure"
            searchFields={['name']}
            rowControl={(e) => {
              const buttons = []
              if (
                e.typeSort === 'Electric Charge' ||
                (e.typeSort === 'Gas Charge' &&
                  accessControlFunc({
                    id: 'tc.pages.rate-structure-library.charge.edit',
                    userAccess
                  }))
              ) {
                buttons.push({
                  text: 'Edit',
                  action: (data) => {
                    navigate(
                      pages['Edit Rate Structure Charge'].href.replace(
                        ':chargeId',
                        data.id
                      ),
                      { state: { mode: ACTIONS.EDIT } }
                    )
                    trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CLICK_EDIT_RATE_STRUCTURE,{from:"List View"})
                  }
                })
              } else if (
                accessControlFunc({
                  id: 'tc.pages.rate-structure-library.edit',
                  userAccess
                })
              ) {
                buttons.push({
                  text: 'Edit',
                  action: (data) => {
                    navigate(
                      pages['Edit Rate Structure'].href.replace(
                        ':rateStructureId',
                        data.id
                      ),
                      { state: { mode: ACTIONS.EDIT } }
                    )
                    trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CLICK_EDIT_RATE_STRUCTURE,{from:"List View"})
                  }
                })
              }

              if (
                e.typeSort === 'Electric Charge' ||
                (e.typeSort === 'Gas Charge' &&
                  accessControlFunc({
                    id: 'tc.pages.rate-structure-library.charge.delete',
                    userAccess
                  }))
              ) {
                buttons.push({
                  text: 'Delete',
                  action: (data) => {
                    setChargeToDelete(data)
                    trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CLICK_DELETE_CHARGE)
                  }
                })
              } else if (
                accessControlFunc({
                  id: 'tc.pages.rate-structure-library.delete',
                  userAccess
                })
              ) {
                buttons.push({
                  text: 'Delete',
                  action: (data) => {
                    setRateStructureToDelete(data)
                    trackEvent(USER_EVENTS.ENERGY_PERFORMANCE.events.CLICK_DELETE_RATE_STRUCTURE,{from:"Page View"})
                  }
                })
              }
              return buttons
            }}
            filtersList={[
              {
                label: 'All Rate Types',
                key: 'typeSort',
                filterName: 'typeFilter',
                id: 'typeSelectorFilter',
                defaultLabel: 'All Rate Types',
                selectedValue: 'default',
                options: [
                  { name: 'Electric Rate', value: 'Electric Rate' },
                  { name: 'Gas Rate', value: 'Gas Rate' }
                ]
              }
            ]}
          />
        </Content>
      </Container>
    </>
  )
}

export default RateStructureLibrary
