import * as Yup from 'yup'
import i18next from 'i18next'

export const MAX_NAME_LENGTH = 80
export const MAX_FORMULA_LENGTH = 250

export const CalculatedPointsFormValidation  = () => Yup.object().shape({
    name: Yup.string().required(i18next.t("errors:TheNameIsRequired")).max(MAX_NAME_LENGTH, i18next.t("errors:maxAllowedFieldLength", {
      field: i18next.t("Name"),
      number: MAX_NAME_LENGTH
    })),
    formula: Yup.string().required("The formula is required").max(MAX_FORMULA_LENGTH, i18next.t("errors:maxAllowedFieldLength", {
      field: i18next.t("formula"),
      number: MAX_FORMULA_LENGTH
    })),
    customUOM: Yup.string().when('uom', {
        is: 'custom',
        field: 'customUOM',
        then:() => Yup.string().required('Custom UOM is required')
      })
  })


