import { BuildingId } from "src/components/buildingNavigation/graphql"


export const listDocumentsAndReportsOrganization = `
query documentsByAccount($accountId: ID!) {
  listBuildingsByAccount(accountId: $accountId, limit: 5000) {
    items {
      id
      name
      accountName
    }
  }
  listDocumentsByAccount(accountId: $accountId, limit: 5000) {
    items {
      id
      buildingId
      contact
      endDate
      startDate
      status
      title
      type
      createdAt
      visibility
      attachments {
        items {
          name
          buildingId
        }
      }
      userId
    }
  }
}`

export const listDocumentsAndReportsByBuilding = `
query documentsByBuilding($buildingId: ID!) {
  getBuilding(id: $buildingId) {
    id
    accountId
    accountName
    name
  }
  listDocumentsByBuilding(buildingId: $buildingId, limit: 5000) {
    items {
      id
      buildingId
      contact
      endDate
      startDate
      status
      title
      type
      createdAt
      visibility
      note
      attachments {
        items {
          name
          buildingId
        }
      }
      userId
    }
  }
}`

export const listConsultations = /* GraphQL */ `
  query SearchConsultations(
    $accountId: ID!
    $filter: SearchableConsultationFilterInput
    $limit: Int
  ) {
    listBuildingsByAccount(accountId: $accountId, limit: 5000) {
      items {
        id
        name
        accountName
      }
    }
    searchConsultations(filter: $filter, limit: $limit) {
      items {
        id
        title
        accountId
        buildingIds
        createdAt
        contact
        status
        startDate
        endDate
        visibility
        assumptionValueId
        assumptionValue {
          id
          buildingIds
          createdAt
          updatedAt
        }
        attachments {
          items {
            id
            opportunityId
            buildingId
            createdBy
            description
            displayName
            name
            title
            attachedAt
            updatedAt
            sizeInBytes
            type
            historyId
            url
          }
        }
      }
    }
  }
`

export const listEnergyUsageReportsByBuilding = `
query listEnergyUsageReportsByBuilding($buildingId: ID!) {
  getBuilding(id: $buildingId) {
    id
    accountId
    accountName
    name
  }
  listEnergyusageReportByBuilding(buildingId: $buildingId, limit: 5000) {
    items {
      buildingId
      chartSection
      contact
      createdAt
      email
      endDate
      highlightSection
      id
      phoneNumber
      startDate
      status
      summarySection
      title
      updatedAt
      visibility
    }
  }
}`

export const listEnergyusageReportByAccount = `
query listEnergyusageReportByAccount($accountId: ID!) {
  listBuildingsByAccount(accountId: $accountId, limit: 5000) {
    items {
      id
      name
      accountName
    }
  }
  listEnergyusageReportByAccount(accountId: $accountId, limit: 5000) {
    items {
      buildingId
      chartSection
      contact
      createdAt
      email
      endDate
      highlightSection
      id
      phoneNumber
      startDate
      status
      summarySection
      title
      updatedAt
      visibility
    }
  }
}`

export const CREATE_DOCUMENT = `mutation CREATE_DOCUMENT($input: CreateDocumentInput!) {
  createDocument(input: $input) {
    id
  }
}`
export const GET_PRE_SIGNED_URL = /* GraphQL */ `
  mutation GET_PRE_SIGNED_URL($input: AWSJSON!) {
    getPreSignedUrl(body: $input)
  }
`
export const DELETE_DOCUMENT = /* GraphQL */ `
  mutation deleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      id
    }
  }
`

export const DELETE_CONSULTATION = /* GraphQL */ `
  mutation deleteConsultation($input: DeleteConsultationInput!) {
    deleteConsultation(input: $input) {
      id
    }
  }
`

export const DELETE_EUREPORT = /* GraphQL */ `
  mutation deleteEnergyUsageReport($input: DeleteEnergyUsageReportInput!) {
    deleteEnergyUsageReport(input: $input) {
      id
    }
  }
`

export const CREATE_ATTACHMENT = /* GraphQL */ `
  mutation CREATE_ATTACHMENT($input: CreateAttachmentInput!) {
    createAttachment(input: $input) {
      attachedAt
      buildingId
      createdAt
      description
      historyId
      id
      issueId
      name
      documentId
      opportunityId
      consultationId
      sizeInBytes
      title
      type
      updatedAt
      url
    }
  }
`
export const GET_BUILDINGS_BY_ACCOUNT_ID = `query GET_BUILDINGS_BY_ACCOUNT_ID($id: ID!, $filter: ModelBuildingFilterInput) {
  listBuildingsByAccount(accountId: $id, filter: $filter, limit: 1000) {
    items {
      key: id
      value: name
      accountName
      }
  }
  }`

export const SEARCH_BUILDINGS_BY_ACCOUNT_ID = `
  query SEARCH_BUILDINGS($filter: SearchableBuildingFilterInput
    $sort: [SearchableBuildingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBuildingAggregationInput]) {
    searchBuildings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        key: id
        value: name
        accountName
      }
      nextToken
    }
  }
  `

export const GET_CONSULTATION_ASSUMPTION_VALUES = `query GET_CONSULTATION_ASSUMPTION_VALUES($id: ID!) {
    getConsultationValueAssumption(id: $id) {
        accountId
        avoidedTruckRollAmount
        buildingIds
        consultationValueAssumptionConsultationId
        createdAt
        goalAmount
        id
        impactHighComfortUnit
        impactHighComfortValue
        impactHighComplianceUnit
        impactHighComplianceValue
        impactHighCustom1Unit
        impactHighCustom1Value
        impactHighCustom2Unit
        impactHighCustom2Value
        impactHighEnergyUnit
        impactHighEnergyValue
        impactHighPerformanceUnit
        impactHighPerformanceValue
        impactHighReliabilityUnit
        impactHighReliabilityValue
        impactLowComfortUnit
        impactLowComfortValue
        impactLowComplianceUnit
        impactLowComplianceValue
        impactLowCustom1Unit
        impactLowCustom1Value
        impactLowCustom2Unit
        impactLowCustom2Value
        impactLowEnergyUnit
        impactLowEnergyValue
        impactLowPerformanceUnit
        impactLowPerformanceValue
        impactLowReliabilityUnit
        impactLowReliabilityValue
        impactMediumComfortUnit
        impactMediumComfortValue
        impactMediumComplianceUnit
        impactMediumComplianceValue
        impactMediumCustom1Unit
        impactMediumCustom1Value
        impactMediumCustom2Unit
        impactMediumCustom2Value
        impactMediumEnergyUnit
        impactMediumEnergyValue
        impactMediumPerformanceUnit
        impactMediumPerformanceValue
        impactMediumReliabilityUnit
        impactMediumReliabilityValue
        updatedAt
      }
    }
  `

export const DELETE_CONSULTATION_VALUE_ASSUMPTIONS = `mutation DELETE_CONSULTATION_VALUE_ASSUMPTIONS($input: DeleteConsultationValueAssumptionInput!){
    deleteConsultationValueAssumption(input:$input) {
      id
    }
  }`

export const GET_BUILDINGS_BY_SALESOFFICE_ID = `query GET_BUILDINGS_BY_SALESOFFICE_ID($id: ID!) {
    listBuildingsBySalesOffice(salesOfficeId: $id, limit: 1000) {
      items {
        key: id
        value: name
        }
    }
    }`

export const UPDATE_DOCUMENT = `mutation UPDATE_DOCUMENT(
    $input: UpdateDocumentInput!
  ) {
    updateDocument(input: $input) {
        id
    }
  }`

export const UPDATE_ATTACHMENT = /* GraphQL */ `
  mutation UPDATE_ATTACHMENT($input: UpdateAttachmentInput!) {
    updateAttachment(input: $input) {
      id
      attachedAt
      buildingId
      createdAt
      description
      id
      issueId
      name
      documentId
      opportunityId
      sizeInBytes
      title
      type
      updatedAt
      url
    }
  }
`

export const GET_DOCUMENT_BY_ID = /* GraphQL */ `
  query getDocument($id: ID!) {
    getDocument(id: $id) {
      title
      status
      visibility
      createdAt
      startDate
      contact
      type
      note
      building {
        name
        id
        accountName
      }
      attachments {
        items {
          id
          buildingId
          name
          displayName
          type
          sizeInBytes
          description
          title
          category
          attachmentOrder
          attachedAt
        }
      }
    }
  }
`
type DateTimeString = string;
type DateString = string;
type DocumentId = string;

export type DocumentAttachment = {
  id: DocumentId,
  buildingId: BuildingId,
  name: string,
  displayName: string|null,
  type: string,
  sizeInBytes: number,
  description: string|null,
  title: string,
  category: string|null,
  attachmentOrder: number|null,
  attachedAt: DateTimeString,
}

export type FullDocument = {
  title: string,
  status: 'In Progress'
    | 'inProgress'
    | 'Ready for Review'
    | 'Collecting Data'
    | 'Complete'
    | 'Recommended'
    | 'Validation'
    | 'Train Deferred'
    | 'Customer Deferred',
  visibility: boolean|null,
  createdAt: DateTimeString,
  startDate: DateString,
  contact: string,
  type: string,
  note: string,
  building: {
    name: string,
    id: BuildingId,
    accountName: string
  },
  attachments: {
    items: DocumentAttachment[]
  }
}

export const CREATE_ENTRY_WITH_ATTACHMENT = /* GraphQL */ `
  mutation CREATE_ENTRY_WITH_ATTACHMENT($input: AWSJSON!) {
    createEntryWithAttachment(body: $input)
  }
`
export const DELETE_ATTACHMENT = /* GraphQL */ `
  mutation DELETE_ATTACHMENT($input: DeleteAttachmentInput!) {
    deleteAttachment(input: $input) {
      id
    }
  }
`

export const GET_ENERGY_USAGE_REPORT = /* GraphQL */ `
  query getEnergyUsageReport($id: ID!) {
    getEnergyUsageReport(id: $id) {
      buildingId
      chartSection
      contact
      createdAt
      email
      endDate
      highlightSection
      id
      phoneNumber
      startDate
      status
      summarySection
      title
      visibility
    }
  }
`
export const GET_BUILDING_DETAILS = /* GraphQL */`
  query getBuilding($id: ID!) {
    getBuilding(id: $id) {
      accountName,
      accountId,
      id,
      name
    }
  }
`

export const GET_CONSULTATION = /* GraphQL */ `
  query getConsultation($id: ID!) {
    getConsultation(id: $id) {
      accountId
      id
      contact
      email
      endDate
      assumptionValueId
      assumptionValue {
        accountId
        avoidedTruckRollAmount
        buildingIds
        consultationValueAssumptionConsultationId
        createdAt
        goalAmount
        id
        impactHighComfortUnit
        impactHighComfortValue
        impactHighComplianceUnit
        impactHighComplianceValue
        impactHighCustom1Unit
        impactHighCustom1Value
        impactHighCustom2Unit
        impactHighCustom2Value
        impactHighEnergyUnit
        impactHighEnergyValue
        impactHighPerformanceUnit
        impactHighPerformanceValue
        impactHighReliabilityUnit
        impactHighReliabilityValue
        impactLowComfortUnit
        impactLowComfortValue
        impactLowComplianceUnit
        impactLowComplianceValue
        impactLowCustom1Unit
        impactLowCustom1Value
        impactLowCustom2Unit
        impactLowCustom2Value
        impactLowEnergyUnit
        impactLowEnergyValue
        impactLowPerformanceUnit
        impactLowPerformanceValue
        impactLowReliabilityUnit
        impactLowReliabilityValue
        impactMediumComfortUnit
        impactMediumComfortValue
        impactMediumComplianceUnit
        impactMediumComplianceValue
        impactMediumCustom1Unit
        impactMediumCustom1Value
        impactMediumCustom2Unit
        impactMediumCustom2Value
        impactMediumEnergyUnit
        impactMediumEnergyValue
        impactMediumPerformanceUnit
        impactMediumPerformanceValue
        impactMediumReliabilityUnit
        impactMediumReliabilityValue
        updatedAt
      }
      highlight1 {
        impactAmount
        impactAmountType
        impactText
        impactType
        title
      }
      highlight2 {
        impactAmount
        impactAmountType
        impactText
        impactType
        title
      }
      highlight3 {
        impactAmount
        impactAmountType
        impactText
        impactType
        title
      }
      phoneNumber
      showHighlight
      startDate
      status
      theBigPictureDetailsJson
      theBigPictureDisplay
      theBigPictureTitle
      title
      visibility
      whereWeGoFromHereTitle
      whereWeGoFromHereDisplay
      whatWeHaveDoneTitle
      whereHaveYouBeenTitle
      whereHaveYouBeenDisplay
      whatWeHaveSavedTitle
      whatWeHaveSavedDisplay
      whatWeHaveDoneDisplay
      whatWeHaveSavedType
      whatWeHaveDoneType
      highlightTitle
      attachments {
        items {
          category
          displayName
          buildingId
          name
          createdAt
          description
          sizeInBytes
          title
          id
          type
        }
      }
      buildingIds
      opportunities {
        items {
          opportunity {
            id
            buildingId
            title
            energySavings
            energySavingsDisplay
            energySavingsUnit
            impactComfort
            impactComfortDisplay
            impactCompliance
            impactComplianceDisplay
            impactCustom1
            impactCustom1Display
            impactCustom1Name
            impactCustom2
            impactCustom2Display
            impactCustom2Name
            impactEnergy
            impactEnergyDisplay
            impactPerformance
            impactPerformanceDisplay
            impactReliability
            impactReliabilityDisplay
            price
            priceDisplay
            priceUnit
          }
        }
      }
    }
  }
`
export const CREATE_CONSULTATION = `mutation CREATE_CONSULTATION($input:CreateConsultationInput!) {
  createConsultation(input: $input) {
    id
  }
}`

export const CREATE_CONSULTATION_ASSUMPTIONS = `mutation CREATE_CONSULTATION_VALUE_ASSUMPTION($input:CreateConsultationValueAssumptionInput!){
  createConsultationValueAssumption(input: $input) {
    id
  }
}`

export const UPDATE_CONSULTATION_ASSUMPTIONS = `mutation UPDATE_CONSULTATION_VALUE_ASSUMPTION($input:UpdateConsultationValueAssumptionInput!){
  updateConsultationValueAssumption(input: $input) {
    id
  }
}`

export const UPDATE_CONSULTATION = `mutation UPDATE_CONSULTATION(
  $input: UpdateConsultationInput!
) {
  updateConsultation(input: $input) {
      id
  }
}`

export const CREATE_ENERGY_USAGE_REPORT = `mutation CREATE_ENERGY_USAGE_REPORT($input:CreateEnergyUsageReportInput!) {
  createEnergyUsageReport(input: $input) {
    id
  }
}`

export const UPDATE_ENERGY_USAGE_REPORT = `mutation UPDATE_ENERGY_USAGE_REPORT($input: UpdateEnergyUsageReportInput!) {
  updateEnergyUsageReport(input: $input) {
    id
  }
}`

export const GET_BUILDINGDETAILS_BY_ID_func = (ids) => `
query getBuildingByIds{
  ${ids.map(
    (id, key) => `b${key}:getBuilding(id: "${id}") {
    address
    crmCustId
    name
    id
  }`
  )}
}`