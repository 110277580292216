import { Container, Content } from 'src/components/layouts'
import IconSvg from 'src/components/legacy/components/icon-svg/icon'
import colors from 'src/components/legacy/common/colors.json'
import { Formik } from "formik"
import { KPIImpact } from './KPIImpact'
import { AssumptionValuesFormContainer, ToolBarContainer } from "./style"
import Tooltip from 'src/denali-ui/components/Tooltip'
import { VALUE_ASSUMPTION_DEFAULT } from "./helpers"
import _set from "lodash/set"
import moment from "moment/moment"
import { getField } from 'src/components/legacy/common/helpers'
import { DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import { isFloat } from "src/components/legacy/common/validators"

export const ValueAssumptionsEdit = ({valueAssumptions, onAssumptionsSave, width, setShowAssumtions, handleSetDefaultValueAssumption}) => {

    const MAX_LENGTH_OF_NUMBERS = 9
    const MAX_SIZE_OF_NUMBERS = 10000000

    const customField = (name, fieldName, value, setFieldValue, error) => (
        <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12} width={width} maxWidth={`${width}px`} className="custom-field">
            <Content xlColumn={6} lColumn={6} mColumn={6} sColumn={12} width={width} className="title-value field" border={"none"}>
                <IconSvg
                    name={"truck-rolls-impact"}
                    color={colors.ORANGE_6}
                    hover={colors.ORANGE_6}
                    width="30px"
                    height="30px"
                    viewBox="0 0 65 65"
                    className="kpi-icons"
                    cursor="auto"
                />
                <span>{name}</span>
            </Content>
            <Content xlColumn={6} lColumn={6} mColumn={6} sColumn={12} width={width} border={"none"} className="select-impact select">
                <span className="currency">$</span>
                <input
                    type="number"
                    className={`${error ? "invalid" : ""}`}
                    onChange={({ target }) => setFieldValue(`${fieldName}`, target.value ? Number(target.value) : undefined)}
                    value={value}
                />
                {error && <p className="error">{error}</p>}
            </Content>
        </Container>
    )

    const valueAssumptionValidationMessages = (value, { allowEmpty = true } = {}) => {
        const notCorrectValue = allowEmpty ? value === undefined : !value
        if (notCorrectValue) {
            return "Enter correct Value"
        } else if (Number(value) > MAX_SIZE_OF_NUMBERS || String(value).length > MAX_LENGTH_OF_NUMBERS) {
            return "Enter valid numbers"
        } else if (Number(value) < 0) {
            return "Enter positive value"
        } else if (isFloat(value)) {
            return "Enter integer value"
        }
    }

    const validateValueAssumptionInputField = (errors, values, fieldName, value=undefined) => {
        value = value !== undefined ? value : getField(values, fieldName)
        const validationError = valueAssumptionValidationMessages(value)
        if (validationError) {
            _set(errors, fieldName, validationError)
        }
    }

    const onSave = (values) => {
        onAssumptionsSave(values)
    }


    const revertToDefault = (setValues) => {
        const values = VALUE_ASSUMPTION_DEFAULT()
        setValues({ ...values, ...{ lastEdited: moment().format(DATE_FORMAT), lastEditedBy: "" } })
       // handleSetDefaultValueAssumption()
    }


    const validate = (values = {}) => {
        const errors = {}
    
        validateValueAssumptionInputField(errors, values, "goalAmount")
        validateValueAssumptionInputField(errors, values, "avoidedTruckRollAmount")
    
        const kpiImpact = getField(values, "kpi")
        kpiImpact.map(({ low, medium, high }, i) => {
            validateValueAssumptionInputField(errors, values, `kpi[${i}].low`, low.value)
            validateValueAssumptionInputField(errors, values, `kpi[${i}].medium`, medium.value)
            validateValueAssumptionInputField(errors, values, `kpi[${i}].high`, high.value)
        })
    
        return errors
    }


    const renderToolBar = (errors, setValues, handleSubmit) => {
       // const { userName, cancelValueAssumption } = {}
        return <ToolBarContainer>
            <div className="toolbar-buttons">
                <div>
                    <Tooltip horizontalPosition="center" content={<span>Save Assumption</span>}>
                        <span className="icon icon-infocircle" />
                    </Tooltip>
                    <button
                        className="primary"
                        type="submit"
                        disabled={Boolean(Object.keys(errors).length)}
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                </div>
                <button
                    className="secondary va-cancel-btn"
                    type="button"
                    onClick={()=>{setShowAssumtions(false)}}
                >
                    Cancel
                </button>
                <button
                    className="secondary"
                    type="button"
                    onClick={() => revertToDefault(setValues) }
                >
                    Revert To Default
                </button>
            </div>
        </ToolBarContainer>
    }

    const renderForm = (formProps):any => {

        const {
            values,
            handleSubmit,
            setFieldValue,
            initialValues,
            setValues,
            errors
        } = formProps

        return (
        <AssumptionValuesFormContainer >
            <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12} width={width}  className="value-assumption-edit">
                <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} border={"none"} width={width}>
                    <form className="inline-edit-form" onSubmit={handleSubmit}>
                        <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12} width={width}>
                            <Content sColumns={12} xlColumn={7} lColumn={8} mColumn={12} sColumn={12} width={width}>
                                {customField(
                                    "Goal",
                                    "goalAmount",
                                    values.goalAmount,
                                    setFieldValue,
                                    errors.goalAmount
                                )}
                            </Content>
                            <Content sColumns={12} xlColumn={7} lColumn={8} mColumn={12} sColumn={12} width={width}>
                                {customField(
                                    "Avoided Truck Rolls",
                                    "avoidedTruckRollAmount",
                                    values.avoidedTruckRollAmount,
                                    setFieldValue,
                                    errors.avoidedTruckRollAmount
                                )}
                            </Content>
                            <Content className="title-impacts" xlColumn={12} lColumn={12} mColumn={12} sColumn={12} width={width} border={"none"}>
                            <p>Low</p>
                            <p>Medium</p>
                            <p>High</p>
                    </Content>
                    {(values?.kpi || initialValues?.kpi)?.map((kpi, i) =>
                            <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} width={width} border={"none"} key={`kpi.name`}>
                                    <KPIImpact
                                        type={kpi.name}
                                        kpiImpact={kpi}
                                        onChange={(val, field) => setFieldValue(`kpi[${i}][${field}]`, val)}
                                        key={i}
                                        error={errors.kpi && errors.kpi[i] || {}} />
                            </Content>
                )}
                        </Container>
                    </form>
                    <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} width={width} border={"none"}>
                        {renderToolBar(errors, setValues, handleSubmit)}
                    </Content>
                </Content>
            </Container>
        </AssumptionValuesFormContainer> )
    }





    return (
            <Formik
                enableReinitialize={true}
                initialValues={valueAssumptions}
                isInitialValid={true}
                onSubmit={onSave}
                render={renderForm}
                validate={validate}
            />
    )
}


export default ValueAssumptionsEdit