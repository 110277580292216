export default function ShadowDrop({ uuid = Math.random() }: any) {
  return (
    <defs>
      <filter id={`drop-shadow-${uuid}`}>
        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
        <feOffset dx="3" dy="3" result="offsetblur" />
        <feFlood floodColor="#000000" floodOpacity="0.2" />
        <feComposite in2="offsetblur" operator="in" />
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
  );
}
