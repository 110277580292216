import { css } from 'styled-components'

// Rest margins
export const noMargin = css`
  margin: 0;
`

// Text no wrap
export const noWrap = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const scrollbar = css`
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 3.5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 7px 10px 12px transparent;
  }
`
