import { useState, useCallback, useEffect } from 'react'
import MultiTabForm from '../MultiTabTorm'
import { TabsWrapper } from '../../styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import { WidgetBuildingSchema, WidgetOverview, WidgetOverviewSchema } from '../WidgetOverview'
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../graphql'
import { useMutation } from 'src/hooks/APIHooks'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import {
  MODAL_TYPE,
  WIDGET_TABS_NAME,
  getCustomKPIWidgetCreateMutationInputs,
  getKPITrendData
} from '../../helpers'
import { ACTIONS } from 'src/constants/actions'
import CustomKPIConfiguration from './CustomKPIConfiguration'
import { CustomKPITrendsPreviewWrapper } from './CustomKPITrendPreviewWrapper'
import * as Yup from 'yup'
import i18next from 'i18next'
const defaultKPIDisplayDate = ':stp/current-month'
const defaultKPITimePeriod = ':stp/all-time-available'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate from 'src/common/translations'

const tabsConfig = (errors) => {
  return [
    {
      title: WIDGET_TABS_NAME.OVERVIEW,
      disabled: false,
      key: WIDGET_TABS_NAME.OVERVIEW,
      formikErrors: errors,
      validationSchema: WidgetOverviewSchema,
      component: <WidgetOverview />
    },
    {
      title: WIDGET_TABS_NAME.CONFIGURATION,
      disabled: false,
      key: WIDGET_TABS_NAME.CONFIGURATION,
      formikErrors: errors,
      validationSchema: CustomKPIConfigurationSchema,
      component: <CustomKPIConfiguration />
    },
    {
      title: WIDGET_TABS_NAME.PREVIEW,
      disabled: false,
      key: WIDGET_TABS_NAME.PREVIEW,
      formikErrors: errors,
      component: <CustomKPITrendsPreviewWrapper />
    }
  ]
}
const MIN_VALUE = 0
const MAX_VALUE = 10000000
export const CustomKPIConfigurationSchema = Yup.object().shape({
  selectedKpiMeasurement: Yup.string().required(
    i18next.t('errors:KPIMeasurementRequired')
  ),
  previousPeroid: Yup.string().test('testKPIValues', '', testKPIValues),
  currentPeriod: Yup.string().test('testKPIValues', '', testKPIValues),
  goalCurrentPeriod: Yup.string().test('testKPIValues', '', testKPIValues)
})
function testKPIValues(value) {
  const {
    createError
  } = this
  const {
    parent: { isPointInTime }
  } = this
  if (!value && isPointInTime === '1') {
    return createError({ message: i18next.t("errors:ThisValueIsRequired") });
  }
  const isRange = Number(value) <= Number(MAX_VALUE) && Number(value) >= Number(MIN_VALUE)
  if (!isRange && isPointInTime === '1') {
    return createError({
      message: i18next.t('errors:minMaxNumberMessage', {
        min: MIN_VALUE,
        max: MAX_VALUE
      })
    });
  }
  return true
}

const CustomKPIForm = ({ setWidgetFormik }) => {
  const { formikErrors } = useWidgetsContext()
  const {
    widgetBuilding,
    setOpenWidgetPicker,
    buildingId,
    organizationId,
    userInfo,
    setReloadEvents,
    setIsLoading,
    mode,
    widgetDetails,
    setWidgetBuilding,
    setWidgetDetails,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setModalType,
    setIsFormValidationAlone,
    setIsSubmitted,
    isFormValidationAlone,
    setWidgetId,
    widgetSalesOfficeId,
    isFooterChecked
  } = useWidgetsContext()
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: [ACTIONS.ADD,ACTIONS.COPY].includes(mode) ? CREATE_WIDGET : UPDATE_WIDGET,
    refreshTokenBuildingVariable: "input.buildingId",
    onError: (error) => {
      setError(error)
      setIsLoading(false)
    },
    onSuccess: (success) => {
      if([ACTIONS.ADD].includes(mode))
        {
          trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Create", type: "Custom KPI"})
        }
      else if([ACTIONS.COPY].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Copy", type: "Custom KPI"})
      }
      else{
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Edit", type: "Custom KPI"})
      }
    }
  })

  const copyof = translate("Copy of") 
  
  const submitForm = async (values) => {
    if(!widgetBuilding || isFormValidationAlone) return
    setIsLoading(true)
    const inputs = getCustomKPIWidgetCreateMutationInputs(
      values,
      buildingId,
      organizationId,
      widgetSalesOfficeId,
      userInfo,
      widgetBuilding?.id,
      mode
    )
    await upsertWidgetMutation({ input: inputs }).then((response) => {
      const widgetId =
      [ACTIONS.ADD,ACTIONS.COPY].includes(mode)
          ? response.data?.createWidget?.id
          : response.data?.updateWidget?.id
      if (widgetId) {
        setIsLoading(false)
        setWidgetDetails(inputs)
        setOpenWidgetPicker(false)
        setReloadEvents(true)
        setModalType(MODAL_TYPE.SUCCESS)
        setSelectedWidget(null)
        setIsOpenCreateWidget(isFooterChecked ?? false)
        setWidgetId(widgetId)
      }
    })
  }
  const [activeTab, setActiveTab] = useState(0)
  const onSelectTab = useCallback((selectedTab: any, selectedTitle: any) => {
    setActiveTab(selectedTab)
    if (selectedTitle === WIDGET_TABS_NAME.PREVIEW) {
      setIsFormValidationAlone(true)
      setIsSubmitted(false)
    }
  }, [])

  useEffect(() => {
    switch (mode) {
      case ACTIONS.ADD:
        setFormValues({
          id: '',
          name: '',
          description: '',
          link: '',
          isNewWindow: '1',
          previousPeroid: '',
          currentPeriod: '',
          goalCurrentPeriod: '',
          isPointInTime: '1',
          selectedKpiMeasurement: null,
          selectedTimePeriod: defaultKPIDisplayDate,
          selectedDisplayTimeDate: defaultKPITimePeriod,
          trendData: {},
          widgetBuilding:''
        })
        break
      case ACTIONS.EDIT:
      case ACTIONS.COPY:
        setFormValues({
          id: mode === ACTIONS.EDIT ? widgetDetails?.id : '',
          name: mode === ACTIONS.EDIT ? widgetDetails?.name : `Copy of ${widgetDetails?.name}`,
          description: widgetDetails?.description ?? '',
          link: widgetDetails?.widgetLink,
          isNewWindow: widgetDetails?.isNewWindow ? '1' : '0',
          previousPeroid:
            widgetDetails?.equipmentCustomKpiWidget?.kpiPreviousPeriod ?? '',
          currentPeriod:
            widgetDetails?.equipmentCustomKpiWidget?.kpiCurrentPeriod ?? '',
          goalCurrentPeriod:
            widgetDetails?.equipmentCustomKpiWidget?.kpiGoal ?? '',
          isPointInTime: widgetDetails?.equipmentCustomKpiWidget?.kpiPlotType
            ? '1'
            : '0',
          selectedKpiMeasurement:
            widgetDetails?.equipmentCustomKpiWidget?.kpiMeasurement,
          selectedTimePeriod:
            widgetDetails?.equipmentCustomKpiWidget?.timePeriod ??
            defaultKPITimePeriod,
          selectedDisplayTimeDate:
            widgetDetails?.equipmentCustomKpiWidget?.kpiDisplayDate ??
            defaultKPIDisplayDate,
          trendData: getKPITrendData(
            widgetDetails?.equipmentCustomKpiWidget?.kpiTrend
          ),          
          widgetBuilding:widgetDetails?.widgetBuildingId
        })
        setTimeout(() => {
          setWidgetBuilding({ id: widgetDetails?.buildingId })
        }, 100)
        break
    }
  }, [mode])

  useEffect(() => {
    if (mode === ACTIONS.COPY) {
      setFormValues((prevValues) => ({
        ...prevValues,
        name: `${copyof} ${widgetDetails?.name}`,
      }));
    }
  }, [copyof]);

  const displayForm = () => {
    return mode !== ACTIONS.ADD ? formValues?.name : true
  }

  return (
    <>
      {displayForm() &&
        <MultiTabForm
          initialValues={formValues}
          onSubmit={submitForm}
          schemas={[WidgetOverviewSchema, WidgetBuildingSchema, CustomKPIConfigurationSchema]}
          setWidgetFormik={setWidgetFormik}
        >
          <TabsWrapper>
            <Tabs
              defaultActiveTab={activeTab}
              activeTab={activeTab}
              onSelect={onSelectTab}
            >
              {tabsConfig(formikErrors)?.map((item: any, key: number) => {
                return (
                  <Tab
                    title={item.title}
                    disabled={item.disabled}
                    key={key}
                    formikErrors={item?.formikErrors}
                    validationSchema={item?.validationSchema}
                  >
                    {item.component}
                  </Tab>
                )
              })}
            </Tabs>
          </TabsWrapper>
          <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
        </MultiTabForm>
      }
    </>
  )
}

export default CustomKPIForm
