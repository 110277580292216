import { Button } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TranslateComponent } from "src/common/translations";

export const DenaliPaginationButton = ({ icon=null, onClick=null, text=null, disabled=false, active=false, alt=null }) => {

  return (
    <Button
      title={alt ? alt : text}
      type='button'
      className={`amplify-button button-paginate button-icon button-gray  ${disabled ? 'amplify-button--disabled' : ''} ${active ? 'active' : ''}`}
      onClick={onClick}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      {text && <TranslateComponent>{`${text}`}</TranslateComponent>}
    </Button>
  )
}