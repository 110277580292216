import styled from "styled-components";
import COLORS from 'src/components/legacy/common/colors.json'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'


export const CharacteristicsCurveWrapper = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
        padding-bottom: 8px;

        .custom-label {
            margin: 0;
            line-height: 22px;
        }

        .go-back-link-button {
            margin-left: 30px;
            font-weight: 700;
            text-decoration: underline;
            background: none;
            border: none;
        }

        .toolbar-buttons {
            text-transform: uppercase;
        }
    }
`


export const EntryView = styled.div`
	height: 10%;
`
export const RadioGroupStyled = styled.div`
	&.radio-group{
		margin-left: 35px;
		padding: 10px 5px;
	}
	width: 70%;
	padding-bottom: 10px !important;
`

export const RadioButtonWrapper = styled.div`
  & > fieldset {
    padding: 0;
    margin-top: 16px;
  }
`

export const DataEntryViewList = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	font-weight: bold;
	
	th {
		padding: 8px;
	}

	td {
		padding: 0px;
	}
`
export const LabelConditionalTooltip = styled(ConditionalTooltip)`
	&.curve-icon-div{
		padding:1px 8px;
	}
`
export const EditableViewStyled = styled(EditableView)`
	&.edit-view {
		align-items: center;
		display: flex;
		flex-direction: row;
		
		.edit-view__label {
			flex: 0 0;
			margin-right: 10px;
		}
		
		.edit-view__field {
			width: 60px;
			.edit-view__input {
				text-align: center;
			}
		}
		
		.edit-view__error {
			bottom: -12px;
			position: absolute;
			text-align: end;
			white-space: nowrap;
			width: auto;
		}
	}
`
export const ActionAdd = styled.button`
	&.icon-button:not([disabled]) {
		color: ${COLORS.GREEN} !important;
	}
`
export const ActionDelete = styled.button`	
	&.icon-button:not([disabled]) {
		color: ${COLORS.RED} !important;
	}
`
export const DeletePointWrapper = styled.div`
	width: 95px;
	display: flex;
	align-self: center;
	letter-spacing: initial;
	margin-top: 20px;
	margin-left: 8px;
`
export const DeletePointLabel = styled.span`
	font: bold 12px/13px "Lato",sans-serif;
    letter-spacing: 0.36px;
	text-transform: capitalize;
`

export const ChartDeleteOption = styled(ActionDelete)`
	display: flex;
	border: 0px;
	background-color: white;
	&::before {
		margin-right: 5px;
	}
`
export const CurvesListWrapper = styled.div`
	li {
		background-color: rgb(249, 250, 252);
	}
`

export const TableWrapper = styled.div`
  .table-wrapper {
    /* height: calc(100vh - 255px);
        overflow: auto; */
    table {
      thead {
        /* position: sticky;
                top: 0;
                z-index: 1; */
        tr {
          th {
            &:first-child {
              width: 250px;
            }
          }
        }
      }
      tbody {
        tr {
          #no-records-row {
            text-align: center;
          }
        }
      }
    }
  }
`

export const CurveDialogWrapper = styled.div`
  &.curve-icon {
	padding: 3px;
  }
`

export const CurveTypeIconWrapper = styled.div`
  div {
	padding : 3px !important;
  }
`

export const AxisRangeWrapper = styled.div`
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}

	/* Firefox */
	input[type=number] {
	-moz-appearance: textfield;
	}
`

export const AnalyticsEnabledSymbolWrapper = styled.div`
	justify-content: center;
`
export const ViewDetailsWrapper = styled.div`
	button {
		background: none;
		border: none;
		color: #666;
		cursor: pointer;
		display: inline-block;
		font-size: 12px;
		font-weight: 900;
		height: 100%;
		letter-spacing: .36px;
		line-height: 14px;
		position: relative;
		text-decoration: underline;
		text-transform: uppercase;
	}
	button:hover {
		color: #999;
	}
`

export const HeaderItem = styled.div`
  min-width: 160px;
  margin-right: 10px;
`
export const HeaderBlock = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 17px;
  border-bottom: 1px solid ${COLORS.TBA_LIGHT_GREY};
  margin-bottom: 16px;
`