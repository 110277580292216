import Table from '../../components/Table/clientSideTable'
import { ACTIONS } from 'src/constants'
import { useEffect } from 'react'

const nameHeadings = [
  {
    title: 'User Type Name',
    key: 'name',
    maxWidth: '200px'
  },
  {
    title: 'Authorized User Types',
    key: 'authUserTypesDisplay',
    maxWidth: '120px'
  }
]

const ListPage = ({
  responseTime,
  rows,
  setMode,
  setInput,
  reloadEvents,
  setReloadEvents,
  setDeleteId,
  setShowDeleteDialog
}) => {
  useEffect(() => {
    if (reloadEvents) {
      setReloadEvents(false)
    }
  }, [reloadEvents])
  return (
    <Table
      key={`Table`}
      rows={rows}
      header={nameHeadings}
      loadTime={responseTime}
      search={true}
      searchFields={['name', 'building.name']}
      rowControl={[
        {
          text: 'Copy User Type',
          action: (data) => {
            const { name, description, authUserTypes } = data
            setMode(ACTIONS.COPY)
            setInput({
              name: 'Copy of ' + name,
              description,
              authUserTypes
            })
          }
        },
        {
          text: 'Edit',
          action: (data) => {
            const { name, description, id, authUserTypes } = data
            setMode(ACTIONS.EDIT)
            setInput({
              id,
              name,
              description, 
              authUserTypes
            })
          }
        },
        {
          text: 'Delete',
          action: (data) => {
            if (data?.id) {
              setDeleteId(data?.id)
              const { name, description, authUserTypes } = data
              setInput({
                name,
                description,
                authUserTypes
              })
              setShowDeleteDialog(true)
            }
          }
        }
      ]}
    />
  )
}

export default ListPage
