import styled from 'styled-components'
import colors from '../../../legacy/common/colors.json'
import Spinner from '../../../legacy/components/spinner/spinner'
import Dialog from '../../../legacy/components/dialog/index'

export const ImagePreviewDialog = styled(Dialog)`
  max-width: 80%;
  max-height: 80%;
  width: 100%;
  height: 100%;
  padding: 0 14px;
  border-radius: 6px;
  box-shadow: 0 0 30px ${colors.GREEN};
  background-color: ${colors.BLACK};
`

export const ImageCover = styled.div`
  height: 100%;
  ${({ isCaption }) => isCaption && 'height: calc(100% - 34px);'}
  ${({ isChildren }) => isChildren && 'height: calc(100% - 200px);'}
	${({ isCaption, isChildren }) =>
    isCaption && isChildren && 'height: calc(100% - 234px);'}
	position: relative;
  background-color: ${colors.TBA_LIGHTEST_GREY};

  img {
    max-width: calc(100% - 28px);
    max-height: calc(100% - 34px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const ImageChildren = styled.div`
  height: 200px;
  padding: 6px;
  overflow-y: auto;
  background-color: ${colors.WHITE};
`

export const CloseIcon = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid ${colors.TBA_MEDIUM_GREY};
  color: white;
  background: ${colors.GREEN};
  z-index: 1;
  text-align: center;
  padding-top: 7px;
  padding-left: 1px;
  cursor: pointer;

  &.icon-close {
    top: -10px;
    right: -10px;
  }

  &:hover {
    background-color: ${colors.TBA_MEDIUM_GREEN};
  }
`

export const ImageBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-fit: scale-down;
  }
`

export const MagnifyIcon = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 16px;
  background: ${colors.TBA_LIGHTEST_GREY};
  color: ${colors.TBA_DARKER_GREY};
  border: 2px solid ${colors.TBA_DARK_GREY};
  border-radius: 2px;
  cursor: pointer;
`

export const ImageCaption = styled.p`
  display: flex;
  align-items: center;
  height: 34px;
  margin: 0;
  padding: 6px;
  background-color: ${colors.WHITE};
  color: ${colors.BLACK};
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
`

export const StyledSpinner = styled(Spinner)`
  && {
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;

    .icon.spin-wheel {
      background: none;
      border: none;
      font-size: 80px;
      position: relative;
    }
  }
`

export const NoImage = styled.div`
  background: ${colors.TBA_LIGHTER_GREY};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  span {
    word-break: normal;
    font-weight: 900;
    font-size: 20px;
    color: ${colors.TBA_DARKER_GREY};
    text-align: center;
    text-transform: uppercase;
    line-height: 30px;
  }
`
