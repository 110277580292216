
import { useQuery } from 'src/hooks/APIHooks'
import {getFilteredPropertyMetaData,TYPES} from "./helpers"
import { GET_DATA_CHARACTERISTICS } from './queries'
import { useEffect, useState } from 'react'

type eqDetails = {
  id : string;
  type : string
}



export const useFilterEquipmentPropertiesQuery = (input) => {
  const {
    refetch: refetchCharacteristicsData
  } = useQuery({
    query: GET_DATA_CHARACTERISTICS,
    disableInitialLoad: true,
    errorPolicy: 'ignore',
    dataPath: 'data.getPropertyMappingByEquipmentID.items'
  })
  const [eqDetails,setEqDetails] = useState<eqDetails>(input)
  const [characteristicsListDataState,setCharacteristicsListData] = useState()
  const [FilteredPropertyMetaData,setFilteredPropertyMetaData] = useState()
  useEffect(() => {
    (async () => {
      if(characteristicsListDataState) {
      const FilteredPropertyMetaData =
      await getFilteredPropertyMetaData(
        characteristicsListDataState,
        eqDetails.type
      )
    setFilteredPropertyMetaData(FilteredPropertyMetaData)
      }
    })()
    
  },[characteristicsListDataState])

  useEffect(() => {
    if(eqDetails?.id){
      (async () => {
        const characteristicsListData = await refetchCharacteristicsData({ id: eqDetails.id, propertyType: 'Characteristic' })
        setCharacteristicsListData(characteristicsListData)
      })()   
    }
    
  },[eqDetails])

  useEffect(() => {
    input && Object.values(TYPES).includes(input?.type) && eqDetails.id !== input.id && setEqDetails(input)
  },[input,eqDetails])

  const getFilteredProperties =async (input : eqDetails) => {
    if(!input || !Object.values(TYPES).includes(input?.type)){
      return ;
    }
    const characteristicsListData = await refetchCharacteristicsData({ id: input.id, propertyType: 'Characteristic' })
    if(characteristicsListData) {
      const FilteredPropertyMetaData =
      await getFilteredPropertyMetaData(
        characteristicsListData,
        input.type
      )
    return FilteredPropertyMetaData
      }
      return [];
  }


  return {data:FilteredPropertyMetaData,setEqDetails,getFilteredProperties}
}

