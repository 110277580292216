import React from 'react'
import PropTypes from 'prop-types'

import FormControl from '../FromControl'
import SelectInput from '../SelectInput'
import InputLabel from '../InputLabel'
import FormHelperText from '../FormHelperText'
import Icon from '../Icon'

const defaultSelectProps = {
  enableMenuScroll: true,
  menuHeight: '174px'
}

const Select = React.forwardRef(function Select(inProps, ref) {
  const {
    children,
    helperText,
    HelperTextProps,
    id,
    InputLabelProps,
    label,
    error,
    errorText
  } = inProps
  return (
    <FormControl {...{ ...defaultSelectProps, ...inProps }} ref={ref}>
      {label && (
        <InputLabel htmlFor={id} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}
      <SelectInput className="input-section">{children}</SelectInput>
      {helperText && (
        <FormHelperText {...HelperTextProps}>{helperText}</FormHelperText>
      )}
      {error && errorText ? (
        <>
          <InputLabel error={true}>
            <Icon
              name="Warning"
              width="16px"
              height="16px"
              margin="4px 2px 0px 0px"
              color="#991909"
            />
            {errorText}
          </InputLabel>
        </>
      ) : (
        ''
      )}
    </FormControl>
  )
})

/* 
IconProps
DropDownProps
*/

Select.propTypes = {
  /**
   * If true, the width of the popover will automatically be set according to the items inside the menu,
   * otherwise it will be at least the width of the select input.
   */
  autoWidth: PropTypes.bool,

  /**
   * The Option compoent to populate the select with. Refer Option component.
   */
  children: PropTypes.node,

  /**
   * If true, the component is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * If true, the input will indicate an error.
   */
  error: PropTypes.bool,

  /**
   * If true, the input will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,

  /**
   * Props applied to the InputLabel element.
   */
  HelperTextProps: PropTypes.object,

  /**
   * The helper text content.
   */
  helperText: PropTypes.any,

  /**
   * The id of the input element.
   */
  id: PropTypes.string,

  /**
   * Dropdown Icon for this component.
   */
  icon: PropTypes.node,

  /**
   * Props applied to the InputLabel element.
   */
  InputLabelProps: PropTypes.object,

  /**
   * The label content.
   */
  label: PropTypes.any,

  /**
   * If true, value must be an array and the menu will support multiple selections.
   */
  multiple: PropTypes.bool,

  /**
   * Name attribute of the input element.
   */
  name: PropTypes.string,

  /**
   * Callback fired when the value is changed.
   */
  onChange: PropTypes.func,

  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: PropTypes.func,

  /**
   * Callback fired when the component requests to be opened.
   */
  onOpen: PropTypes.func,

  /**
   * Type of the input element.
   */
  open: PropTypes.bool,

  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder: PropTypes.string,

  /**
   * Popover config to place dropdown menu.
   * For more options, refer popper.js (https://popper.js.org/docs/v2/constructors/#options)
   */
  popoverConfig: PropTypes.object,

  /**
   * It prevents the user from changing the value of the field
   */
  readOnly: PropTypes.bool,

  /**
   * Props applied to the clickable div element where selected value displayed.
   */
  RenderValueProps: PropTypes.object,

  /**
   * Render the selected value.
   */
  renderValue: PropTypes.func,

  /**
   * The value of the input element, required for a controlled component.
   */
  value: PropTypes.any,

  /**
   * Determines component size. Can be either default or small. If needed, large can be added later.
   */
  size: PropTypes.string
}

Select.defaultProps = {
  autoWidth: false,
  disabled: false,
  error: false,
  fullWidth: false,
  multiple: false,
  readOnly: false,
  size: 'default'
}

export default Select

export { default as Option } from '../Option'
