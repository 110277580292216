/**
 * BUTTON :
 *
 * Generating Styles for BUTTON with default theme or customised user provided theme
 *
 * This allows to isolate styles and functionality of component
 *
 * Easy to re-use this styles for other components which required same look and feel of Button
 *
 * Easy to update it any changes without affecting functionalities
 *
 * It can be overriden with other styles by user using styled components
 *
 */
import { css } from 'styled-components'
import { isBoolean, SystemThemeProps, ThemeMode } from '../../utils'
import { onDisabled, onFocus, onHover } from '../common/element-actions'

// ---------------------------------------------- Styles ----------------------------------------------

// default shadow style
const shadowCssFn = css`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
`

// default base styles of button (applies primary theme)
const onBase = (base) => css`
  align-items: center;
  border: 0;
  border-radius: 24px;
  box-sizing: border-box;
  column-gap: 6px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  outline: 0;
  padding: 10px 15px;
  user-select: none;
  ${base}
`

const fullWidthCss = css`
  width: 100%;
`

// ---------------------------------------------- Others ----------------------------------------------

const applyShadowFor = {
  primary: true,
  secondary: true,
  tertiary: true,
  outlined: false,
  text: false,
  link: false
}

// ---------------------------------------------- Style Creator ----------------------------------------------

/**
 * Method to create styles dynamically for button
 *
 * provides options to disable styles that are not required
 *
 * @export
 * @param {boolean} [requiredStyle={ focus: true, shadow: true, hover: true, disabled: true }]
 * @return {*}
 */
export function createButtonStyle(requiredStyle) {
  requiredStyle = {
    base: true,
    disabled: true,
    focus: true,
    hover: true,
    shadow: true,
    size: true,
    ...requiredStyle
  }
  return function buttonStyleFn({ theme, dark, fullWidth, size, variant }) {
    theme = theme[SystemThemeProps.BUTTON]

    const mode = dark ? ThemeMode.DARK : ThemeMode.LIGHT

    // if variant is invalid value, fallback to default theme (primary) instead of throwing error
    const btnVarient = variant && theme[variant] ? variant : 'primary'

    // if size is invalid value or not present, fallback to default size (medium) instead of throwing error
    const btnSize = size && theme['size'][size] ? size : 'medium'

    // button theme
    const { disabled, hover, ...base } = theme[btnVarient][mode]

    const baseCss = requiredStyle.base && onBase(base)
    const hoverCss = requiredStyle.hover && onHover(hover)
    const focusCss = requiredStyle.focus && onFocus(hover)
    const disabledCss = requiredStyle.disabled && onDisabled(disabled)

    // button size
    const sizeCss = requiredStyle.size && theme['size'][btnSize]

    // check list to apply shadow
    const shadowCss = applyShadowFor[variant] ? shadowCssFn : false

    // button fullWidth
    fullWidth = (isBoolean(fullWidth) && fullWidth) || false

    return css`
      ${baseCss}
      ${sizeCss}
      ${requiredStyle.shadow && shadowCss}
      ${fullWidth && fullWidthCss}
      ${hoverCss}
      ${focusCss}
      ${disabledCss}
    `
  }
}
