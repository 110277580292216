import { useEffect, useState } from 'react'
import Table from 'src/components/layouts/Table'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { Block } from 'src/components/layouts'
import { TEXT_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import moment from 'moment'
import { StyledLink } from './style'
import translate from 'src/common/translations'

const pageName = 'BuildingSetup'

const ListView = ({ breakPoint, buildings, buildingId }) => {

  return !['TINY', 'EXTRA_SMALL'].includes(breakPoint) ? (
    <Block display={'flex'} justify={'space-between'}>
      <Block
        flex={'0 0 46%'}
        display={'flex'}
        align={'flex-start'}
        flexDirection={'column'}
        rowGap={'1rem'}
      >
        <Block display={'flex'}>
          <Block padding="6px 12px">
            <Block as="span" padding="0 0 1rem 0">
              Building Name:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Sales office:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              State:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Connection:{' '}
            </Block>
          </Block>
          <Block padding="6px 12px" textAlign="left">
            <Block as="span" padding="0 0 1rem 0">
              {
                <StyledLink
                  data-testid={`${pageName}_buildinglist_${
                    buildings[buildingId]['locationName'] || '--'
                  }_link`}
                  className={'buildingName'}
                  to={`${buildingId}?organization=${buildings[buildingId]?.accountId}`}
                >
                  {buildings[buildingId]['locationName'] || '-'}
                </StyledLink>
              }
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              {' '}
              {buildings[buildingId]['salesOffice'] || '-'}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              {' '}
              {buildings[buildingId]['state'] || '-'}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              {' '}
              {buildings[buildingId]['connectivityTypes'] || '-'}
            </Block>
          </Block>
        </Block>
      </Block>
      <Block
        flex={'0 0 46%'}
        display={'flex'}
        align={'flex-start'}
        flexDirection={'column'}
        rowGap={'1rem'}
      >
        <Block display={'flex'}>
          <Block padding="6px 12px">
            <Block as="span" padding="0 0 1rem 0">
              Weather:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Created By:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Created On:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Tags:{' '}
            </Block>
          </Block>
          <Block padding="6px 12px" textAlign="left">
            <Block padding="0 0 1rem 0">
              {buildings[buildingId]['weatherData'] || '-'}
            </Block>
            <Block padding="0 0 1rem 0">
              {' '}
              {moment(buildings[buildingId]['createdByTimestamp']).format(
                TEXT_DATE_FORMAT
              ) || '-'}
            </Block>
            <Block padding="0 0 1rem 0">
              {' '}
              {buildings[buildingId]['createdByUser'] || '-'}
            </Block>
            <Block padding="0 0 1rem 0">
              {' '}
              {buildings[buildingId]['tags'] || '-'}
            </Block>
          </Block>
        </Block>
      </Block>
    </Block>
  ) : (
    <Block display={'flex'}>
      <Block
        flex={'0 0 46%'}
        display={'flex'}
        align={'flex-start'}
        flexDirection={'column'}
        rowGap={'1rem'}
      >
        <Block display={'flex'}>
          <Block padding="6px 12px">
            <Block as="span" padding="0 0 1rem 0">
              Building Name:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Sales office:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              State:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Connection:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Weather:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Created By:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Created On:{' '}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              Tags:{' '}
            </Block>
          </Block>
          <Block padding="6px 12px" textAlign="left">
            <Block as="span" padding="0 0 1rem 0">
              {buildings[buildingId]['locationName'] || '-'}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              {' '}
              {buildings[buildingId]['salesOffice'] || '-'}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              {' '}
              {buildings[buildingId]['state'] || '-'}
            </Block>
            <Block as="span" padding="0 0 1rem 0">
              {' '}
              {buildings[buildingId]['connectivityTypes'] || '-'}
            </Block>
            <Block padding="0 0 1rem 0">
              {buildings[buildingId]['weatherData'] || '-'}
            </Block>
            <Block padding="0 0 1rem 0">
              {' '}
              {moment(buildings[buildingId]['createdByTimestamp']).format(
                TEXT_DATE_FORMAT
              ) || '-'}
            </Block>
            <Block padding="0 0 1rem 0">
              {' '}
              {buildings[buildingId]['createdByUser'] || '-'}
            </Block>
            <Block padding="0 0 1rem 0">
              {' '}
              {buildings[buildingId]['tags'] || '-'}
            </Block>
          </Block>
        </Block>
      </Block>
    </Block>
  )
}

export const BuildingsList = ({
  buildingsState,
  onSortChange,
  sortDirection,
  sortField,
  displayControls,
  total,
  buildings,
  setDisplayControls
}: any) => {
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const { point } = useBreakPoint()
  const optionsTranslated = {
    building: translate('Building name'),
    sales: translate('Sales Office'),
    state: translate('State'),
    connection: translate('Connection'),
    offerings: translate('Offerings'),
    weather: translate('Weather'),
    createdBy: translate('Created by'),
    createdOn: translate('Created On'),
    tags: translate('Tags')
  }
  const buildingInfos = [
    {
      displayName: optionsTranslated.building,
      sortable: true,
      name: 'locationName',
      key: 'locationNameMarkUp',
      fieldType: 'component',
      customClasses: 'facility-name',
      maxWidth: '120px'
    },
    {
      displayName: optionsTranslated.sales,
      sortable: true,
      name: 'salesOffice',
      key: 'salesOfficeMarkUp',
      fieldType: 'component',
      customClasses: 'sales-office',
      maxWidth: '90px'
    },
    {
      displayName: optionsTranslated.state,
      sortable: true,
      name: 'state',
      key: 'state',
      fieldType: 'component',
      customClasses: 'state',
      maxWidth: '80px'
    },
    {
      displayName: optionsTranslated.connection,
      sortable: true,
      name: 'connectivityTypes',
      key: 'connectivityTypesMarkUp',
      fieldType: 'component',
      customClasses: 'connection',
      maxWidth: '100px'
    },
    {
      displayName: optionsTranslated.offerings,
      sortable: true,
      name: 'offeringsString',
      key: 'offeringsStringMarkUp',
      fieldType: 'component',
      customClasses: 'offerings',
      maxWidth: '80px'
    },
    {
      displayName: optionsTranslated.weather,
      sortable: true,
      name: 'weatherData',
      key: 'weatherData',
      fieldType: 'component',
      customClasses: 'weather-data',
      maxWidth: '80px'
    },
    {
      displayName: optionsTranslated.createdBy,
      sortable: true,
      name: 'createdByUser',
      key: 'createdByUser',
      fieldType: 'component',
      customClasses: 'created created-by',
      maxWidth: '80px'
    },
    {
      displayName: optionsTranslated.createdOn,
      sortable: true,
      name: 'createdByTimestamp',
      key: 'dateMarkUp',
      fieldType: 'component',
      customClasses: 'created created-on',
      maxWidth: '80px'
    },
    {
      displayName: optionsTranslated.tags,
      sortable: true,
      name: 'tags',
      key: 'tags',
      fieldType: 'component',
      customClasses: 'tags',
      maxWidth: '120px'
    }
  ]

  useEffect(() => {
    let rows = [],
      columns = []
    if (!['TINY', 'EXTRA_SMALL', 'SMALL', 'MEDIUM'].includes(point)) {
      rows = Object.keys(buildings).map((id) => ({
        ...buildings[id],
        salesOfficeMarkUp: (
          <ConditionalTooltip
            type={ConditionalTooltip.TYPE.ICON}
            conditional={true}
            content={buildings[id]?.salesOffice}
          >
            {buildings[id]?.salesOffice}
          </ConditionalTooltip>
        ),
        locationNameMarkUp: (
          <StyledLink to={`${id}?organization=${buildings[id]?.accountId}`}>
            <ConditionalTooltip
              type={ConditionalTooltip.TYPE.ICON}
              conditional={true}
              content={buildings[id]?.locationName}
            >
              {buildings[id]?.locationName}
            </ConditionalTooltip>
          </StyledLink>
        ),
        offeringsStringMarkUp: (
          <ConditionalTooltip
            type={ConditionalTooltip.TYPE.ICON}
            conditional={true}
            content={buildings[id]?.offeringsString}
          >
            {buildings[id]?.offeringsString}
          </ConditionalTooltip>
        ),
        connectivityTypesMarkUp: (
          <ConditionalTooltip
            type={ConditionalTooltip.TYPE.ICON}
            conditional={true}
            content={buildings[id]?.connectivityTypes}
          >
            {buildings[id]?.connectivityTypes}
          </ConditionalTooltip>
        ),
        dateMarkUp: moment(buildings[id]?.createdByTimestamp).format(
          TEXT_DATE_FORMAT
        )
      }))
      columns = buildingInfos
    } else {
      rows = Object.keys(buildings).map((id) => ({
        buildingsetup: (
          <ListView breakPoint={point} buildings={buildings} buildingId={id} />
        )
      }))
      columns = [
        {
          sortable: false,
          key: 'buildingsetup',
          fieldType: 'component',
          displayName: 'Building Setup'
        }
      ]
    }
    setColumns(columns)
    setRows(rows)
  }, [buildings, point, optionsTranslated])

  // return <Table rows={rows} columns={columns} defaultSort="locationName" />
  const onPaginationChanges = ({ currentPage, selectedRowsPerPage }) => {
    setDisplayControls((state) => ({
      ...state,
      limit: selectedRowsPerPage,
      page: currentPage,
      from: currentPage * selectedRowsPerPage
    }))
  }

  return (
    <Table
      pageName={pageName}
      buildingsState={buildingsState}
      currentSortedOrder={sortDirection}
      onSortChange={onSortChange}
      currentSortedField={sortField}
      total={total}
      selectedPage={displayControls.page || 0}
      selectedItemsCountPerPage={displayControls?.limit}
      rows={rows}
      onPaginationChanges={onPaginationChanges}
      columns={columns}
      fixedLayout={false}
    />
  )
}
