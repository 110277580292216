import React, { useState } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import Selectrix from "src/denali-ui/components/Selectrix"
import "./select-service-provider-buildings.scss"
import { SEARCH_BUILDINGS } from "../queries/queries"
import NewStyles from "../../../../NewStyles"
import { useEffect } from "react"
import translate, { TranslateComponent } from 'src/common/translations'
import { Link } from "react-router-dom"

export function SelectBuildings({ title, initialValues, description, onButtonClick, sideText, queryResponseMap, sideTexts }) {
	// TODO: Style this via styled components
	const [selectedOptions, setSelectedOptions] = useState(initialValues)
	const getQueryResponseMap = (values) => (response) =>  response.map((res) => ({
		value: res.id,
		children: res.name,
		selected: values.some(e => e.id === res.id),
		disabled: values.some(e => e.id === res.id),
		...res
	}))


	useEffect(() => {
		setSelectedOptions(initialValues.map(i => ({...i, value: i.id})))
	}, [initialValues])

	return <div className="contractor-company-building-wrapper">
		<legend className="contractor-company-building-title"><TranslateComponent>{title}</TranslateComponent></legend>
		<div className="contractor-company-building-block">		
			<div className="contractor-company-selectrix-box">
				{
					<NewStyles>
						<Selectrix 
						onChange={(e, child) => {
							const selectedOptionsNew = [...selectedOptions]
							const index = selectedOptionsNew.findIndex(e => e.id === child.props.value)
							if (index === -1) {
								selectedOptionsNew.push({id: child.props.value, name: child.props.children, ...child.props})
							} else {
								selectedOptionsNew.splice(index, 1)
							}
							
							// setSelectedOptions(selectedOptionsNew)
							onButtonClick(selectedOptionsNew)
							return selectedOptionsNew
						  }}
						query={SEARCH_BUILDINGS}
						fullWidth={true}
						multiple={true}
						value={selectedOptions}
						allowCheckAll={false}
						getQueryResponseMap={typeof queryResponseMap === "function" ? queryResponseMap(selectedOptions) : getQueryResponseMap(selectedOptions)}
						sort={true}
						placeholder={translate("Find a Building...")}
						searchPlaceHolder={translate("Find Building...")}
						querySearchField='name'
						isRequiredQueryInputName={true}
					/>
					</NewStyles>
				}
				<p className="ccb-description"><TranslateComponent>{description}</TranslateComponent></p>
			</div>
			<div className="ccd-actions">
				<p className="ccd-create-new">
						{sideText} {sideTexts && <Link to="/building-setup/add"><TranslateComponent>{sideTexts}</TranslateComponent></Link>}
				</p>
			</div>
		</div>
	</div>
}

SelectBuildings.propTypes = {
	buildings: PropTypes.array,
	description: PropTypes.string.isRequired,
	buttonTitle: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
}

export default SelectBuildings
