import * as d3 from 'd3'
import {
  MAX_LABEL_PIXEL_LENGTH,
  X_AXIS_TILT_THRESHOLD,
  X_AXIS_VERTICAL_THRESHOLD,
  Y_AXIS_TILT_THRESHOLD,
  Y_AXIS_VERTICAL_THRESHOLD
} from './constant'
import { max } from 'lodash'

const legendss = [
  {
    id: '1',
    name: 'Return',
    color: '#6E86CF',
    xAxisKey: 'x1',
    yAxisKey: 'y1',
    shapeSize: 'Default',
    lineType: 'solid',
    shape: 'Dots',
    strokeWidth: 'Default',
    show: true,
    visible: true,
    position: 1,
    type: 'line'
  },
  {
    id: '2',
    name: 'Outdoor',
    color: '#CB4689',
    xAxisKey: 'x2',
    yAxisKey: 'y2',
    symbol: '%',
    shapeSize: 'Default',
    lineType: 'solid',
    shape: 'Dots',
    strokeWidth: 'Default',
    show: true,
    visible: true,
    position: 2,
    type: 'line'
  }
]
// This function will help us devide the text if required, decide svg padding, decide the tilt
export const calculateTextHeightAndLines = (legends = legendss) => {
  const body = d3.select('body')
  const svg = body
    .append('svg')
    .attr('id', 'barSvg')
    .style('position', 'absolute')
    .style('left', 0)
    .style('top', 0)

  let textHeight = MAX_LABEL_PIXEL_LENGTH

  const allHeights: any = []
  const lines = legends?.map((obj: any) => {
    const name = obj?.name || ''
    const words = name.split(/\s+/)
    let line = ''
    let lineHeight = MAX_LABEL_PIXEL_LENGTH
    const lines = []
    const newHeights: any = []

    words.forEach((word: string) => {
      const testLine = line.length === 0 ? word : `${line} ${word}`
      const hiddenText: any = svg
        .append('text')
        .attr('opacity', 0)
        .style('font-size', '11px')
        .style('font-weight', 'normal')
        .attr('dy', '0em')
        .style('text-anchor', 'end')
        .attr('transform', 'rotate(-90)')
        .text(testLine)

      const newTextHeight = hiddenText.node().getBBox().width
      if (newTextHeight < MAX_LABEL_PIXEL_LENGTH) {
        line = testLine
        newHeights.push(newTextHeight)
        lineHeight = newTextHeight
      } else {
        lines.push(line)
        newHeights.push(lineHeight)
        line = word
      }
    })

    lines.push(line)

    allHeights.push(...newHeights)
    return { id: obj?.id, name: obj?.name, lines }
  })

  textHeight =
    allHeights?.every((height: any) => height < MAX_LABEL_PIXEL_LENGTH) &&
    allHeights?.length > 0
      ? max([...allHeights])
      : MAX_LABEL_PIXEL_LENGTH

  const dd: any = legendss
  const rtc = calculateTextOrientation(dd, 600, 600, 'x', 2)
  console.log(rtc)
  svg.remove()
  return { textHeight, lines }
}

export const calculateTextOrientation = (
  legends = [],
  svgWidth = 0,
  svgHeight = 0,
  axisType = 'x',
  tickCount = 0
) => {
  const body = d3.select('body')
  const svg = body
    .append('svg')
    .attr('id', 'barSvg')
    .attr('width', svgWidth)
    .attr('height', svgHeight)
    .style('position', 'absolute')
    .style('left', 0)
    .style('top', 0)

  const testText: any = svg
    .append('text')
    .attr('opacity', 0)
    .style('font-size', '11px')
    .style('font-weight', 'normal')
    .attr('dy', '0em')
    .style('text-anchor', 'end')
    .text('T')

  const textWidth = testText.node().getBBox().width
  svg.remove()

  // Adjust orientation based on text length, SVG width/height, and tick count
  if (
    (axisType === 'x' && textWidth * legends.length > X_AXIS_TILT_THRESHOLD) ||
    (axisType === 'y' && textWidth > Y_AXIS_TILT_THRESHOLD) ||
    (axisType === 'y' && tickCount > Y_AXIS_VERTICAL_THRESHOLD) ||
    (axisType === 'x' && svgWidth < X_AXIS_VERTICAL_THRESHOLD) ||
    (axisType === 'y' && svgHeight < Y_AXIS_VERTICAL_THRESHOLD)
  ) {
    return 'tilt45'
  } else if (
    (axisType === 'x' && tickCount > X_AXIS_VERTICAL_THRESHOLD) ||
    (axisType === 'y' && svgWidth < Y_AXIS_VERTICAL_THRESHOLD)
  ) {
    return 'vertical'
  } else {
    return 'horizontal'
  }
}

export const getTextSpaceDetails = (obj: any) => {
  const hiddenSvg = d3
    .create('svg')
    .attr('width', 0)
    .attr('height', 0)
    .style('position', 'absolute')
    .style('opacity', 0)

  hiddenSvg
    .append('text')
    .attr('fill', 'transparent')
    .attr('font-size', obj?.fontSize || '12px')
    .text(obj?.content || '')

  document.body.appendChild(hiddenSvg.node())

  const textBoundingBox = (hiddenSvg.select('text').node() as any)?.getBBox()
  const width = textBoundingBox?.width || 0
  const height = textBoundingBox?.height || 0

  hiddenSvg.remove()

  return {
    width,
    height
  }
}
