import { useEffect, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { isIE } from "react-device-detect"
import { BlobProvider, Text } from "@react-pdf/renderer"
import { PDF_PAGE_TYPES } from "../../legacy/common/pdf"
import { downloadFile } from "../../legacy/common/helpers"
import PDFDocument from "../pdf-document/index"
import PDFTable from "../pdf-table/index"
import Spinner from "../../legacy/components/spinner/spinner"
import { Button } from "@aws-amplify/ui-react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from 'src/denali-components/lib/pro-solid-svg-icons'

import "./pdf-generator.scss"
import translate from "src/common/translations"

const PDFGenerator = (props) => {
	const[renderingPDF,setRenderingPDF] = useState(false)
	const[preloadingData,setPreloadingData] = useState(false)

	const optionsTranslated = {
		download: translate('Download')
	}

	useEffect(() => {
		if(props?.isAutoDownload) {
      onClickDownload()
		}
  }, [])

	const getContent = () => {
		switch (props.pageType) {
			case PDF_PAGE_TYPES.TABLE:
				return <PDFTable
					{...props[PDF_PAGE_TYPES.TABLE]}
				/>
			default:
				return <Text>{props.t("components:pdf>PageTypeDoesn'tExist")}</Text>
		}
	}

	const renderPDF = () => {
		setRenderingPDF(!renderingPDF)
	}

	const saveBlobPDF = ({url, loading}) => {
		if (!url) return
		const { fileName } = props

		url && downloadFile(url, `${fileName}.pdf`)

		!loading && setTimeout(() => {
			renderPDF()
		}, 0)

		return null // the method should return something (needs of BlobProvider)
	}

	const handlePreloadData = async () => {
		const { onPreloadData } = props

		if (onPreloadData) {
			setPreloadingData(true)
			await onPreloadData()
			setPreloadingData(false)
		}
	}

	const onClickDownload = async () => {
		await handlePreloadData()
		renderPDF()
	}

	const onClickDownload1 = async () => {
		props.handleDownloadClick ? await props.handleDownloadClick() : await handlePreloadData()
		renderPDF()
	}

  const downloadPDF = (disabled, onTrigger) => {
    if(disabled) return
    onClickDownload1()
    if (typeof onTrigger === 'function')
    {
      onTrigger()
    }
  }

	const { iconName, buttonType, disabled = false, children, t, onTrigger } = props
	const document = <PDFDocument>
		{children || getContent()}
	</PDFDocument>

	return <>
		{isIE ? <></> : (<>
			{!props?.isAutoDownload && <div className="pdf-generator-wrapper">
        {!props?.uiMode &&
          <button
            data-testid="download-pdf-button"
            className={classNames("action", buttonType, "download-pdf-button", { disabled })}
            onClick={ () => {
              downloadPDF(disabled, onTrigger)
            }}>
            {renderingPDF || preloadingData ? <Spinner /> : <span className={`icon icon-${iconName}`} />}
            <span className="download-pdf-download">{optionsTranslated.download}</span>
          </button>
        }
        {props?.uiMode &&
          <Button
            className='button-icon button-gray'
            data-testid="downloadPdfButton"
            ariaLabel={translate('Download Opportunities PDF')}
            onClick={ () => {
              downloadPDF(disabled, onTrigger)
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        }
			</div>}
			{renderingPDF && <BlobProvider document={document}>
				{/* {({ url, loading }) => {
					url && saveBlobPDF(url, loading)
				}} */}
				{saveBlobPDF}
			</BlobProvider>}
		</>)}
	</>
}


PDFGenerator.propTypes = {
	fileName: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
	onPreloadData: PropTypes.func,
	[PDF_PAGE_TYPES.TABLE]: PropTypes.object,
	pageType: PropTypes.string,
	disabled: PropTypes.bool,
	children: PropTypes.node,
	iconName: PropTypes.string,
	buttonType: PropTypes.string,
}

PDFGenerator.defaultProps = {
	disabled: false,
	children: null,
	iconName: "download",
	buttonType: "small",
}

export default withTranslation()(PDFGenerator)
