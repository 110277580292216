import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { createFormControlStyle } from '../../styles/styleCreators'
import { useFormFieldState } from '../FromControl'
import InputLabel from '../InputLabel'
import Icon from '../Icon'

const inputRootStyles = createFormControlStyle({ base: true })
const inputStyles = createFormControlStyle({ input: true })

const InputRoot = styled.div`
  ${inputRootStyles}
  ${(props) => props.fullWidth && 'width:100%'}
`

const NativeInput = styled.input`
  ${inputStyles}
`

// --------------------------------------------- Component ---------------------------------------------

const Input = React.forwardRef(function Input(inProps, ref) {
  const {
    className,
    endAdornment,
    formControlState,
    formControlStateClass,
    fullWidth,
    inputComponent,
    onBlur,
    onClick,
    onClickElementRef,
    onFocus,
    startAdornment,
    error,
    errorText,
    ...rest
  } = useFormFieldState(inProps)

  const setRef = (node) => {
    if (node) {
      ref && (ref.current = node)
      onClickElementRef.current = node
    }
  }

  return (
    <>
      <InputRoot
        className={`${className ? `${className} ` : ''}${
          formControlStateClass ? formControlStateClass : ''
        }`}
        fullWidth={fullWidth}
      >
        {startAdornment}
        <NativeInput
          ref={setRef}
          {...rest}
          error={error}
          as={inputComponent}
          disabled={formControlState.disabled}
          onBlur={onBlur}
          onClick={onClick}
          onFocus={onFocus}
        />
        {endAdornment}
      </InputRoot>
      {error && errorText ? (
        <>
          <InputLabel error={true}>
            <Icon
              name="Warning"
              width="16px"
              height="16px"
              margin="4px 2px 0px 0px"
              color="#991909"
            />
            {errorText}
          </InputLabel>
        </>
      ) : (
        ''
      )}
    </>
  )
})

Input.propTypes = {
  /**
   * If true, the component is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * End InputAdornment for this component.
   */
  endAdornment: PropTypes.node,

  /**
   * If true, the input will indicate an error.
   */
  error: PropTypes.bool,

  /**
   * If true, the input will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,

  /**
   * The id of the input element.
   */
  id: PropTypes.string,

  /**
   * The component used for the input element.
   */
  inputComponent: PropTypes.elementType,

  /**
   * Name attribute of the input element.
   */
  name: PropTypes.string,

  /**
   * Callback fired when the value is changed.
   */
  onChange: PropTypes.func,

  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder: PropTypes.string,

  /**
   * It prevents the user from changing the value of the field
   */
  readOnly: PropTypes.bool,

  /**
   * Start InputAdornment for this component.
   */
  startAdornment: PropTypes.node,

  /**
   * Type of the input element.
   */
  type: PropTypes.string,

  /**
   * The value of the input element, required for a controlled component.
   */
  value: PropTypes.any
}

Input.defaultProps = {
  disabled: false,
  error: false,
  fullWidth: false,
  inputComponent: 'input',
  readOnly: false,
  type: 'text'
}

export default memo(Input)
