import EnergyConsumptionContainer from './EnergyConsumptionContainer'
import { EnergyConsumptionContextProvider } from './EnergyConsumptionContextProvider'

export const EnergyConsumption = () => {
  return (
    <EnergyConsumptionContextProvider>
      <EnergyConsumptionContainer />
    </EnergyConsumptionContextProvider>
  )
}
