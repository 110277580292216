import { useEffect, useState } from 'react'
import Table from '../../components/Table/clientSideTable'
import { listBaselinesByBuilding, listBaselinesByAccount } from './graphql'
import moment from 'moment'
import { useBaseLinesContext } from './BaseLinesContextProvider'
import { useQuery } from 'src/hooks/APIHooks'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { ACTIONS, MODAL_TYPE } from 'src/constants'
import translate from 'src/common/translations'
import { utcTimestampToStringDate } from 'src/components/legacy/common/time-helpers.js'
import TARGET_TYPE_NAMES from 'src/common/targetTypeNamesIcons'
import IconSvg from 'src/components/legacy/components/icon-svg/icon'
import COLORS from 'src/components/legacy/common/colors.json'
import { getSearchParams } from 'src/common/helperFunctions.js'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { useNavigate } from 'react-router-dom'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { newRefreshToken } from 'src/redux/slicers/appData'
import { useDispatch } from 'react-redux'

const DATE_FORMAT = 'MM/DD/YYYY'

const BaseLinesList = ({ handleGetBaseLineRecords }) => {
  const { buildingId, organizationId } = getSearchParams()
  const [rows, setRows] = useState([])
  const {
    reloadEvents,
    setReloadEvents,
    setBaseLine,
    setModalType,
    setMode,
    setShowBaselineModal
  } = useBaseLinesContext()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const optionsTranslated = {
    baselineName: translate('Baseline Name'),
    target: translate('Target Object'),
    baselinePeriod: translate('Baseline Period'),
    description: translate('Description'),
    savings: translate('Savings'),
    exlusions: translate('EXCLUSIONS'),
    viewChart: translate('VIEW CHART'),
    edit: translate('Edit'),
    delete: translate('Delete')
  }
  const dispatch = useDispatch()

  const {
    data: baselinesList,
    loading,
    refetch: refetchBaselinesList,
    responseTime
  } = useQuery(
    !buildingId
      ? {
          query: listBaselinesByAccount,
          dataPath: 'data.listBaselinesByAccount.items',
          variables: { accountId: organizationId },
          disableInitialLoad: true,
          errorPolicy: 'ignore'
        }
      : {
          query: listBaselinesByBuilding,
          dataPath: 'data.listBaselinesByBuilding.items',
          variables: { buildingId },
          disableInitialLoad: true,
          errorPolicy: 'ignore'
        }
  )

  useEffect(() => {
    if (buildingId) {
      refetchBaselinesList({ buildingId })
    } else if (organizationId) {
      refetchBaselinesList({ accountId: organizationId })
    }
  }, [buildingId, organizationId])

  useEffect(() => {
    const func = async () => {
      setReloadEvents(false)
      if (buildingId) {
        await dispatch<any>(newRefreshToken([buildingId], null, null))
        refetchBaselinesList({ buildingId })
      } else if (organizationId) {
        refetchBaselinesList({ accountId: organizationId })
      }
    }
    if (reloadEvents) {
      func()
    }
  }, [reloadEvents])

  const handleBaselineEdit = (baselineData) => {
    setMode(ACTIONS.EDIT)
    setShowBaselineModal(true)
    setBaseLine(baselineData)
  }

  useEffect(() => {
    setRows(
      !_isEmpty(baselinesList)
        ? baselinesList.map((baseline) => ({
            ...baseline,
            creationDate: baseline?.creationDate
              ? moment(baseline?.creationDate).format(DATE_FORMAT)
              : '',
            period: `${moment(baseline?.startPeriod)?.format(
              DATE_FORMAT
            )} - ${moment(baseline?.endPeriod)?.format(DATE_FORMAT)}`
          }))
        : []
    )
    handleGetBaseLineRecords(baselinesList)
  }, [baselinesList])

  const exclusionComponents = (row) => {
    return <span>{row?.exclusions?.length || 0}</span>
  }

  const customMeterNameComponent = (row) => {
    const { baselineEquipment } = row
    const equipmentFamily = baselineEquipment?.family?.split(' ').join('') || ''
    const icon =
      TARGET_TYPE_NAMES.getType(equipmentFamily).icon === 'other'
        ? 'location'
        : TARGET_TYPE_NAMES.getType(equipmentFamily).icon
    const iconType = `icon-${icon}`

    return (
      <>
        {icon !== 'virtual-meter' ? (
          <span className={`icon ${iconType}`} />
        ) : (
          <IconSvg
            name={icon}
            color={COLORS.TBA_DARKER_GREY}
            hover={COLORS.TBA_DARKER_GREY}
            viewBox="0 0 12 12"
            height="14px"
            width="14px"
            margin="0px 4px 0px 0px"
          />
        )}{' '}
        {''} {baselineEquipment?.name}
      </>
    )
  }

  const nameHeadings = [
    {
      title: optionsTranslated.baselineName,
      key: 'name',
      maxWidth: '200px'
    },
    {
      title: optionsTranslated.target,
      key: 'baselineEquipment.name',
      maxWidth: '100px',
      customComponent: (row) => {
        return <>{customMeterNameComponent(row)}</>
      }
    },
    {
      title: optionsTranslated.baselinePeriod,
      key: 'period',
      maxWidth: '50px'
    },
    {
      title: optionsTranslated.description,
      key: 'description',
      maxWidth: '100px'
    },
    {
      title: optionsTranslated.savings,
      key: 'savings',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.exlusions,
      key: 'exclusions',
      maxWidth: '50px',
      customComponent: (row) => {
        return <>{exclusionComponents(row)}</>
      }
    }
  ]

  const handleUpdatedTableRows = (updatedRows) => {
    const updatedRowIds = updatedRows?.map((m) => m.id) ?? []
    handleGetBaseLineRecords(
      baselinesList?.filter((i) => updatedRowIds.includes(i?.id))
    )
  }

  return loading ? (
    <Spinner />
  ) : (
    <Table
      key={`BaseLinesTable-${organizationId} ${buildingId}`}
      rows={rows}
      header={nameHeadings}
      loadTime={responseTime}
      search={true}
      searchFields={['name']}
      handleUpdatedTableRows={(updatedRows) => handleUpdatedTableRows(updatedRows)}
      rowControl={[
        {
          text: optionsTranslated.viewChart,
          action: (data) => {
            navigate(
              `/energy-consumption?location=${data?.buildingId}&equipment=${data?.equipmentId}`
            )
            trackEvent(USER_EVENTS.BASELINE_LIBRARY.events.CLICK_VIEW_CHART)
          }
        },
        {
          text: optionsTranslated.edit,
          action: (data) => {
            handleBaselineEdit(data)
            trackEvent(USER_EVENTS.BASELINE_LIBRARY.events.CLICK_EDIT_BASELINE)
          }
        },
        {
          text: optionsTranslated.delete,
          action: (data) => {
            setBaseLine(data)
            setModalType(MODAL_TYPE.CONFIRMATION)
            trackEvent(
              USER_EVENTS.BASELINE_LIBRARY.events.CLICK_DELETE_BASELINE
            )
          }
        }
      ]}
    />
  )
}

export default BaseLinesList
