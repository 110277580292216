import React from 'react'
import { string, func, object, bool } from 'prop-types'
import TimePicker from 'rc-time-picker'
import classNames from 'classnames'
import {
  default as Tooltip,
  TOOLTIP_HORIZONTAL_POSITION_CENTER
} from '../../components/legacy/components/tooltip/tooltip'
import { Wrapper, Icon } from './styles'
import { useState } from 'react'
import { memo } from 'react'

const TP = (props) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(!open)
  }
  const getIcon = () => {
    const { disabled, information, tooltipPosition } = props
    return (
      <Icon>
        <Tooltip
          horizontalPosition={tooltipPosition}
          content={information}
          SpanOrDiv="span"
        >
          <span className={classNames('icon icon-infocircle', { disabled })} />
        </Tooltip>
      </Icon>
    )
  }
  const {
    minuteStep,
    onChange,
    format,
    defaultValue,
    use12Hours,
    focusOnOpen,
    allowEmpty,
    duration,
    disabled,
    information,
    noicon,
    className,
    textAlign,
    width,
    borderHidden,
    backgroundColor,
    error,
    ...other
  } = props
  const inputIcon = getIcon()
  return (
    <>
      <Wrapper
        disabled={disabled}
        textAlign={textAlign}
        width={width}
        borderHidden={borderHidden}
        backgroundColor={backgroundColor}
        error={error}
        icon={noicon}
        className={`timepicker-react ${className ? className : ''}`}
        information={information}
      >
        <TimePicker
          className="timePicker-main"
          minuteStep={parseInt(minuteStep)}
          showSecond={false}
          format={format}
          open={open}
          onOpen={toggleOpen}
          onClose={toggleOpen}
          onChange={onChange}
          defaultValue={defaultValue}
          focusOnOpen={focusOnOpen}
          allowEmpty={allowEmpty}
          getPopupContainer={(node) => node}
          disabled={disabled}
          use12Hours={use12Hours}
          inputIcon={information && inputIcon}
          {...other}
        />
        {noicon ? (
          <></>
        ) : (
          <div className="timepicker-icon-container">
            {!duration ? (
              <span
                className={classNames('icon icon-clock clockbutton', {
                  disabled
                })}
                onClick={toggleOpen}
              />
            ) : (
              <span
                className={classNames('icon icon-duration clockbutton', {
                  disabled
                })}
                onClick={toggleOpen}
              />
            )}
          </div>
        )}
      </Wrapper>
    </>
  )
}
TP.propTypes = {
  minuteStep: string,
  onChange: func,
  format: string,
  defaultValue: object,
  use12Hours: bool,
  information: string,
  disabled: bool,
  className: string,
  noicon: bool,
  duration: bool,
  allowEmpty: bool,
  focusOnOpen: bool,
  tooltipPosition: string
}
TP.defaultProps = {
  format: 'hh:mm',
  minuteStep: '1',
  use12Hours: false,
  focusOnOpen: true,
  disabled: false,
  allowEmpty: true,
  duration: false,
  information: '',
  tooltipPosition: TOOLTIP_HORIZONTAL_POSITION_CENTER,
  noicon: false
}
export default memo(TP)
