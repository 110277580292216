import PropTypes from "prop-types"
import { View, Text, StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

const styles = StyleSheet.create({
	badge: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		color: colors.WHITE,
		paddingHorizontal: 4,
		paddingVertical: 1,
		margin: 1,
		borderRadius: 2,
	},
	icon: {
		fontFamily: "Emerald",
		paddingRight: 3,
	}
})

export default function PDFBadge({ text, color, icon }) {
	return <View style={[styles.badge, { backgroundColor: color }]}>
		<Text style={styles.icon}>{icon}</Text>
		<Text>{text}</Text>
	</View>
}

PDFBadge.propTypes = {
	text: PropTypes.string,
	color: PropTypes.string,
	icon: PropTypes.string,
}
