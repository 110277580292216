export const CREATE_RATE_STRUCTURE = `
mutation CREATE_RATE_STRUCTURE(
  $input: CreateRateStructureInput!
) {
  createRateStructure(input: $input) {
    id
    name
  }
}
`

export const UPDATE_RATE_STRUCTURE = `
mutation UPDATE_RATE_STRUCTURE(
  $input: UpdateRateStructureInput!
) {
  updateRateStructure(input: $input) {
    id
    name
  }
}
`

export const DELETE_RATE_STRUCTURE = `
mutation DELETE_RATE_STRUCTURE($input: DeleteRateStructureInput!) {
  deleteRateStructure(input: $input) {
    id
  }
}
`



export const CREATE_UTILITY_COMPANY = `
mutation CREATE_UTILITY_COMPANY(
  $input: CreateUtilityCompanyInput!
) {
  createUtilityCompany(input: $input) {
    id
  }
}
`

export const LIST_UTILITY_COMPANIES_BY_SALESOFFICE_ID = `
query LIST_UTILITY_COMPANIES_BY_SALESOFFICE_ID(
  $id: ID!
) {
  listUtilityCompanyBySalesOffice(salesOfficeId: $id) {
    items {
      name
      id
      type
    }
  }
}
`


export const GET_RATE_STRUCTURE_BY_ID = `
query GET_RATE_STRUCTURE_BY_ID(
  $id: ID!
) {
  getRateStructure(id: $id) {
    description
    id
    isSeasonalVariation
    isTimeSchedule
    name
    salesOfficeId
    type
    updatedAt
    utilityCompanyId
    charges {
      items {
        id
      }
    }
    seasonalDefinitions {
      enabled
      endDate
      endMonth
      name
      startDate
      startMonth
      weekdaySchedule
      weekendSchedule
    }
    offPeakRachet {
      demandPercent
      enabled
      timeFrame
    }
    onPeakRachet {
      demandPercent
      enabled
      timeFrame
    }
    salesOffice {
      id
      name
    }
    intermediateRachet {
      demandPercent
      enabled
      timeFrame
    }
  }
}
`




