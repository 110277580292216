import { getNumberIfUndefined } from '../../helper'

// Helper Method
export const getPreviousOccupiedSpace = (
  obj: any = {},
  spaceInBetween = [],
  svgPadding: any = {},
  givenPaddingDetails: any = {},
  adjustedShareIndex: number
) => {
  const currentObject = givenPaddingDetails?.[adjustedShareIndex]
  let occupiedSpace =
    getNumberIfUndefined(svgPadding?.top) +
    getNumberIfUndefined(currentObject?.top)
  const canvasIndexes = Object.keys(obj) || []
  canvasIndexes?.forEach((key, index) => {
    const objKey = canvasIndexes?.[key]
    const canvasObject = obj?.[objKey]

    const inbetweenMargin =
      adjustedShareIndex === 0 ? 0 : spaceInBetween[adjustedShareIndex - 1]
    occupiedSpace +=
      getNumberIfUndefined(canvasObject?.top) +
      getNumberIfUndefined(canvasObject?.height) +
      getNumberIfUndefined(canvasObject?.bottom) +
      getNumberIfUndefined(inbetweenMargin)
  })
  return occupiedSpace
}
