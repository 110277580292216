import styled from 'styled-components'
import { Container, Content } from 'src/components/layouts'
import colors from 'src/components/layouts/colors.json'

export const AssumptionsDetailsParentContainer = styled(Container)`
    .created-info-item {
        max-width: 400px;
        padding-bottom: 10px;
    }

    .value-assumption-page {
        margin-top:-80px;
    }

    .consultation-assumption-value-header {
        display:flex;
    }

    .header-wrapper {
        width: calc(100%);
        
    }

    .toolbar-assumption-values {
        margin-left:-25px;
        font-size: medium;
    }
`

export const AssumptionValuesFormContainer = styled.div`
background-color:white;
width:1220px;

.value-assumption-edit,
.value-assumption-view {
    padding: 16px;

    .title-impacts {
        display: flex;
        width: 100%;
        flex-flow: row;
        justify-content: flex-end;
        p {
            width: 25%;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            font-style: italic;
        }
    }

    .created-info-item {
        max-width: 400px;
        padding-bottom: 10px;
    }

    .custom-field {
        border-radius: 4px;
        padding: 0;

        .title-value {
            padding: 16px 16px 16px 26px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: none;
            border-right: 1px solid grey;
            border-left: 17px solid grey;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            span {
                font-size: 14px;
                font-weight: bold;
                letter-spacing: -0.21px;
            }

            &:after {
                width: 19.32px;
            }

            svg {
                margin-top: 0;
                margin-right: 8px;
            }
        }

        input {
            flex: 45%;
            width: 45%;
            margin-right: 16px;
        
            &.invalid {
                border: 2px solid ${colors.RED};
                background: ${colors.RED-1};
            }

            & + p {
                position: absolute;
                bottom: 4px;
                left: 30px;
            }
        }

        .currency {
            display: flex;
            align-items: center;
            margin-right: 4px;
        }

        .select {
            position:relative;
            padding: 16px;
            justify-content: center;
            font-weight: bold;
            display: flex;
            align-items: center;
            input {
                margin-bottom: 0;
            }
        }
    }
}`



export const ToolBarContainer = styled(Container)`
    button.primary,
    button.secondary {
        margin-right: 0;
        margin-bottom: 6px;
    }
    .toolbar-buttons {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;
        & > div {
            display: flex;
            flex-flow: row;
            align-items: center;
        }
        .icon {
            font-size: 20px;
            color: $tba-darker-grey;
            margin-right: 8px;
        }
        .tooltip-base {
            text-align: left;
            span {
                font-size: 12px;
            }
        }
        > * {
            margin-left: 10px;
        }
}`

export const KPIImpactContainer = styled.div`
    display: grid;
    grid-template-areas: "title list";
    grid-template-columns: 25% 75%;
    grid-template-rows: 100%;
    width: 100%;
    border:1px solid grey;
    border-radius:4px;

    > * {
        width: 100%;
        display: flex;
        align-items: center;

        &.impact-list {
            grid-area: list;
            display: grid;
            grid-template-columns: 33.33% 33.33% 33.33%;
            grid-template-rows: 100%;
            grid-template-areas: "low medium high";
            background: white;
            border-radius: 0 4px 4px 0;
        }
    }

    .title-value {
        padding: 16px 16px 16px 26px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: none;
        border-right: 1px solid grey;
        border-left: 17px solid grey;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        

        span {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.21px;
        }

        &:after {
            width: 19.32px;
        }

        svg {
            margin-top: 0;
            margin-right: 8px;
        }
    }

    .view-impact {
        padding: 16px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid grey;
        height:100%;
        &.low {
            grid-area: low;
        }
        &.medium {
            grid-area: medium;
        }
        &.high {
            grid-area: high;
        }
    
        &:last-child {
            border-right: none;
        }
    
        .content-view {
            grid-area: content-view;
            font-weight: bold;
        }
    
        .title-view {
            display: none;
            grid-area: title-impact;
            font-size: 14px;
            font-weight: bold;
            font-style: italic;
            margin: 0;
            justify-self: flex-start;
        }
    }

    .select-impact {
        padding: 16px 20px;
        display: flex;
        align-items: center;
        border-right: 1px solid grey;
        position: relative;
        &.low {
            grid-area: low;
        }
        &.medium {
            grid-area: medium;
        }
        &.high {
            grid-area: high;
        }
        &:last-child {
            border-right: none;
        }

        .title-select {
            display: none;
            grid-area: title-impact;
            font-size: 14px;
            font-weight: bold;
            font-style: italic;
            margin: 0;
            justify-self: flex-start;
        }
    
        .currency {
            display: flex;
            align-items: center;
            margin-right: 4px;
            font-weight:700;           
        }
    
        fieldset {
            flex: 45%;
            width: 45%;
            grid-area: value;
            position: relative;
            margin-right: 16px;
            padding: 0;
            margin-top: 0;
            
        }
    
        input {
            flex: 45%;
            width: 45%;
            margin-right: 16px;
           
        
            &.invalid {
                border: 2px solid ${colors.RED};
                background: ${colors.RED-1};
            }

            & + p {
                position: absolute;
                bottom: 4px;
                left: 30px;
            }
        }
    
        .select-wrapper {
            min-width: 100px;
            flex: 45%;
            grid-area: select;
    
            .control {
                width: 100%;
            }
        }
    }

`

export const TitleValue = styled.span`
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -.21px;
    `

export const ValueAssumptionsEditContainer = styled.div`
`