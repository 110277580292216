export const CREATE_BUILDING = `mutation CREATE_BUILDING(
    $input: CreateBuildingInput!
) {
    createBuilding(input: $input) {
      id
      accountId
    }
  }`

export const CREATE_ACCOUNT = `mutation CREATE_ACCOUNT(
    $input:  CreateAccountInput!
) {
  createAccount(input: $input) {
    name
    id
  }
  }`

export const UPDATE_BUILDING = `mutation UPDATE_BUILDING(
    $input: UpdateBuildingInput!
) {
    updateBuilding(input: $input) {
        id
        accountId
    }
  }`

export const CREATE_DEVICE = `mutation CREATE_DEVICE(
    $input: CreateDeviceInput!
  ) {
    createDevice(input: $input){
      id
    }
  }`

export const UPDATE_DEVICE = `mutation UPDATE_DEVICE(
    $input: UpdateDeviceInput!
  ) {
    updateDevice(input: $input){
      id
    }
  }`


// Same Lamda is used for Both upload and delete, only action key will be different
export const UPDATE_UTILITY_BILL =  /* GraphQL */ `
mutation utilityDataUploader($requestBody: AWSJSON!) {
  utilityDataUploader(requestBody: $requestBody)
}
`