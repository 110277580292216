import { useState, useEffect } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import Checkbox from 'src/denali-ui/components/Checkbox'
import { Block } from 'src/components/layouts'
import { getUniqueItems } from 'src/common/helperFunctions'
import Label from 'src/components/legacy/components/label/label'
import { StepTitle,EditActiveStyledContainer } from './styles'
import { AddCRMSiteIDsModal } from './AddCRMSiteIDs'
import Selectrix from 'src/denali-ui/components/Selectrix'
import {SEARCH_BUILDINGS} from "./queries"
import { getOfferingName } from 'src/pages/contracts-and-offerings/common/contracts-and-offerings'
import TraneTheme from 'src/denali-ui/components/ThemeProvider'

export const AddBuildingModal = ({
  onConfirm,
  values,
  offeringDDLData,
  setOfferingDDLData,
  formik,
  handleCancel,
  loading,
  selectedBuildings,
  setSelectedBuildings,
  config
}) => {
  const [offeringsState, setOfferingsState] = useState([])
  const [buildingsState, setBuildingsState] = useState([])
  const [next, isNext] = useState(false)

  const handleCloseAddCRMSiteModal = () => {
    isNext(false)
  }

  const handleAddBuildings = () => {
    let mappings = []
    selectedBuildings.forEach((building) => {
      const newMappings = values.offerings.map((offering) => ({
        name: offering,
        buildingId: building,
        checkboxCheckedEnabled: offeringsState.includes(offering)
      }))
      mappings = [...mappings, ...newMappings]
    })

    formik.setValues({
      ...values,
      buildingIds: getUniqueItems([
        ...values.buildingIds,
        ...mappings.map((mapping) => mapping.buildingId)
      ]),
      offerings: getUniqueItems([
        ...values.offerings,
        ...mappings.map((mapping) => mapping.name)
      ]),
      newOfferingsToBuildinsMap: [
        ...values.newOfferingsToBuildinsMap,
        ...mappings
      ],
      selectedBuildings
    })
    onConfirm?.()
  }

  useEffect(() => {
    setBuildingsState([
      ...buildingsState,
      ...offeringDDLData.filter(
        ({ key }) =>
          !values.buildingIds.includes(key) &&
          !buildingsState.some((building) => key === building.key)
      )
    ])
  }, [offeringDDLData])


  const  onBuildinghangeSelect = () => {
    return (e) => {
      const newBuildingIds = e.target.value
 setSelectedBuildings(newBuildingIds)
    }
  }


  // const SelectedBuildingList = value => {
  //   return <BottomList>
  //   {value.map((c, i) => (
  //     <BottomListItem key={`close-${i}`}>
  //       <IconSVG
  //         onClick={() => {
  //           onBuildinghangeSelect()({target:{value}})
  //         }}
  //         name="close-circle"
  //         color={colors.TBA_DARKER_GREY}
  //         margin="2px 4px 0px 0px"
  //         width="14px"
  //         height="14px"
  //       />
  //       <BottomText>
  //         <span>
  //           <b>{offeringDDLData.find(data => data.key === c)?.label}</b>
  //         </span>
  //       </BottomText>
  //     </BottomListItem>
  //   ))}
  // </BottomList>
  // }

  const getBuildingsQueryResponseMap = (values) => (response) => {

    setOfferingDDLData(state  => 
      [...state,
    ...response.reduce((acc,b) => {
      const data =  {
        key:b.id,
        label:b.name,
        crmCustId:b.crmCustId,
        address:b.address
      }
      if(!(state.some(building => building.key === b.id))){
      acc.push(data)
      }
      return acc;
    }
   ,[] ) 
  ]
    )
  return response && Array.isArray(response) ?  response.
  filter(r => !(values.buildingIds.includes(r.id)) )?.map((e) =>  ({
        searchableValue: e.name,
        value: e.id,
        children: e.name,
        selected: values.buildingIds.includes(e.id)
      })
    ) : []
}


  return (
    <Modal
      className={`Addbuilding-dialog`}
      gray={true}
      heading={'Add Builings'}
      buttons={[
        { type: 'cancel', text: 'cancel', handleClick: handleCancel },
        {
          type: 'confirm',
          text: 'Next CRM Site IDs',
          disabled: !(
            offeringsState?.length > 0 && selectedBuildings?.length > 0
          ),
          handleClick: () => {
            isNext(true)
          }
        }
      ]}
      handleClose={handleCancel}
      loading={loading}
    >
       <TraneTheme>
        <StepTitle>Step 1 of 2: Select Buildings</StepTitle>
          <>
            <Label text={<span>Buildings</span>} />
            { selectedBuildings?.length > 0 && <EditActiveStyledContainer>
              <SelectrixWrapper
                className={'location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select editActive'}
                placeholder={""}
                placeHolderInside={true}
                defaultValue={getUniqueItems([...selectedBuildings])}
                inputPlaceholder="Find a building..."
                multiple={true}
                name="buildingIds"
                onChange={e => {
                  const newBuildingIds = e.map(e => e.key)
                  setSelectedBuildings(newBuildingIds)
                }
                }
                options={offeringDDLData}
                searchable={true}
                searchBoxInside={true}
                searchIndex={false}
                stayOpen={true}
              // tags={true}
              // validationWithRegisterCase={true}
              />
              </EditActiveStyledContainer>}
          <Selectrix
          allowCheckAll = {false}
          onChange={onBuildinghangeSelect()}
          value = {getUniqueItems([...selectedBuildings])}
          query={SEARCH_BUILDINGS}
          //customRenderValue = {SelectedBuildingList}
          graphQLInputs = {{filter : { accountId: { eq: values.accountId } }}}
          fullWidth={true}
          multiple={true}
          containerWidth={true}
          getQueryResponseMap={getBuildingsQueryResponseMap(values)}
          selectClassName={'orgSelectInput'}
          placeholder={"-- Select One or More --"}
          searchPlaceHolder="Type to find a Building..."
           showNoResultsMessage={false}
        />
            <Block margin={'16px 0 0 0 '}>
              <Label text={<span>Select Offerings</span>} />
              {values.offerings.map((offering) => (
                <div key={offering}>
                  <Checkbox
                  value = {offering}
                    label={getOfferingName(config,offering) || offering }
                    checked={offeringsState.includes(offering)}
                    onClick={() =>
                      offeringsState.includes(offering)
                        ? setOfferingsState(
                            offeringsState.filter(
                              (_offering) => _offering !== offering
                            )
                          )
                        : setOfferingsState((state) => [...state, offering])
                    }
                  />
                </div>
              ))}
            </Block>
          </>
      </TraneTheme>      
      {next && (
        <AddCRMSiteIDsModal
          handleCancel={handleCloseAddCRMSiteModal}
          selectedBuildings={selectedBuildings}
          offeringDDLData={offeringDDLData}
          handleAddBuildings={handleAddBuildings}
          loading={loading}
          setOfferingDDLData={setOfferingDDLData}
        />
      )}
    </Modal>
  )
}







