import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  TabList,
  ActiveTabContent,
  ActiveTabContentContext
} from './styles'

const Tabs = memo((props) => {
  const [activeTabIndex, setactiveTabIndex] = useState(props.defaultActiveTab)
  const {
    vertical,
    contextualTabs,
    tabListStyle,
    tabStyle,
    activeTabContentStyle
  } = props

  useEffect(() => {
    setactiveTabIndex(props.activeTab)
  }, [props.activeTab])

  const handleTabClick = (tabIndex, tabTitle, event) => {
    const { customFunction } = props
    customFunction && customFunction(tabIndex, tabTitle)
    if (tabIndex !== activeTabIndex) {
      // prevents to change tab when onSelect returns `false`
      if (props?.onSelect?.(tabIndex, tabTitle) !== false) {
        setactiveTabIndex(tabIndex)
      }
    }
  }

  const renderChildrenWithTabsApiAsProps = (vertical) => {
    return React.Children.map(props.children, (child, index) => (
      <>
        {React.cloneElement(child, {
          onClick: handleTabClick,
          tabIndex: index,
          active: index === activeTabIndex,
          vertical: vertical && vertical,
          customStyle: tabStyle && tabStyle,
          contextualTabs: contextualTabs && contextualTabs
        })}
      </>
    ))
  }

  const renderActiveTabContent = () => {
    const { children } = props
    return React.Children.toArray(children)[activeTabIndex].props.children
  }

  const ActiveContent = contextualTabs
    ? ActiveTabContentContext
    : ActiveTabContent

  return (
    <Container vertical={vertical}>
      <TabList
        contextualTabs={contextualTabs}
        testName={`${props?.testName || ''}-tabs-container`}
        vertical={vertical}
        customStyle={tabListStyle && tabListStyle}
      >
        {renderChildrenWithTabsApiAsProps(vertical)}
      </TabList>
      {
        <ActiveContent
          customStyle={activeTabContentStyle && activeTabContentStyle}
        >
          {renderActiveTabContent()}
        </ActiveContent>
      }
    </Container>
  )
})

Tabs.propTypes = {
  /** component should receive children, at least one Tab */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,

  /** default Tab index (uncontrolled) */
  defaultActiveTab: PropTypes.number,

  /** active tab index (controlled) */
  activeTab: PropTypes.number,

  /**
   * on Tab select handler
   * to prevent tab changing return `false`
   * @param {number} Tab index
   */
  onSelect: PropTypes.func,
  vertical: PropTypes.bool,

  /** To support any custom function like tracking click events */
  customFunction: PropTypes.func
}

Tabs.defaultProps = {
  defaultActiveTab: 0,
  vertical: false,
  activeTab: 0
}

export default Tabs
