import React from "react"
import PropTypes from "prop-types"
import { Text, View } from "@react-pdf/renderer"
import { summaryStyles } from "./pdf-summary.styles"


const PDFSummary = ({ summary: { text, title } }) => {
	const { summaryWrapper, summaryTitle } = summaryStyles
	if (Array.isArray(text)) {
		for (let i = 0; i < text.length; i++) {
			if (text[i].type === "ordered-list-item") {
				if (i === 0) {
					text[i].orderedNumber = 1
				} else if (text[i - 1].orderedNumber && text[i].depth === text[i - 1].depth) {
					text[i].orderedNumber = text[i - 1].orderedNumber + 1
				} else {
					const lastVal = [...text].reverse().find(e => e.orderedNumber && e.depth === text[i].depth)
					const lastNum = lastVal && lastVal.orderedNumber ? lastVal.orderedNumber : 0
					if (lastNum && text[i].depth < text[i - 1].depth) {
						text[i].orderedNumber = lastNum + 1
					} else {
						text[i].orderedNumber = 1
					}
				}
			}
		}
		for (let i = 0; i < text.length; i++) {
			if (text[i].inlineStyleRanges && text[i].inlineStyleRanges.length) {
				const ranges = text[i].inlineStyleRanges.map(e => ({
					start: e.offset,
					end: e.offset + e.length - 1,
					style: [e.style],
					styles: {}
				})).sort((a, b) => a.start < b.start ? -1 : a.start === b.start ? 0 : 1)
				const textArray = text[i].text.split("")
				const characterArray = textArray.reduce((accum, curr, index) => {
					const styles = ranges.filter(r => r.start <= index && r.end >= index).map(r => r.style).flat()
					if (accum.length && accum[accum.length - 1].style.sort().join(",") === styles.sort().join(",")) {
						accum[accum.length - 1].text = accum[accum.length - 1].text.concat(curr)
						return accum
					} else {
						return [...accum, {
							text: curr,
							style: ranges.filter(r => r.start <= index && r.end >= index).map(r => r.style).flat(),
							styles: {}
						}]
					}
				}, [])
				for (let l = 0; l < characterArray.length; l++) {
					if (characterArray[l].style.includes("ITALIC")) {
						characterArray[l].styles.fontStyle = "italic"
					}
					if (characterArray[l].style.includes("BOLD")) {
						characterArray[l].styles.fontWeight = "bold"
					}
					if (characterArray[l].style.includes("UNDERLINE") && characterArray[l].style.includes("STRIKETHROUGH")) {
						characterArray[l].styles.textDecoration = "underline line-through"
					} else if (characterArray[l].style.includes("UNDERLINE")) {
						characterArray[l].styles.textDecoration = "underline"
					} else if (characterArray[l].style.includes("STRIKETHROUGH")) {
						characterArray[l].styles.textDecoration = "line-through"
					}
					if (characterArray[l].style.includes("fontsize-8")) {
						characterArray[l].styles.fontSize = "8"
					}
					if (characterArray[l].style.includes("fontsize-9")) {
						characterArray[l].styles.fontSize = "9"
					}
					if (characterArray[l].style.includes("fontsize-10")) {
						characterArray[l].styles.fontSize = "10"
					}
					if (characterArray[l].style.includes("fontsize-11")) {
						characterArray[l].styles.fontSize = "11"
					}
					if (characterArray[l].style.includes("fontsize-12")) {
						characterArray[l].styles.fontSize = "12"
					}
					if (characterArray[l].style.includes("fontsize-14")) {
						characterArray[l].styles.fontSize = "14"
					}
					if (characterArray[l].style.includes("fontsize-16")) {
						characterArray[l].styles.fontSize = "16"
					}
					if (characterArray[l].style.includes("fontsize-18")) {
						characterArray[l].styles.fontSize = "18"
					}
					if (characterArray[l].style.includes("fontsize-24")) {
						characterArray[l].styles.fontSize = "24"
					}
				}
				text[i].segments = characterArray
			} else {
				text[i].segments = [{ text: text[i].text }]
			}
		}
		return <View style={summaryWrapper}>
			<Text style={summaryTitle}>{title}</Text>
			{text.map((t, i) => {
				switch (t.type) {
					case "unordered-list-item": {
						switch (t.depth) {
							case 0:
								return <View style={{
									display: "flex",
									flexDirection: "row"
								}}
								       >
									<Text
										key={i} style={{ marginLeft: t.depth * 15,
											display: "flex",
										}}
									>{"\u2022\u00A0\u00A0"}
									</Text>
									<Text>
										{t.segments.map((s, index) => <Text
											key={index}
											style={{
												display: "flex",
												...s.styles,
											}}
										                     >
											{s.text}
										</Text>
										)}
									</Text>
								</View>
							case 1:
								return <View style={{
									display: "flex",
									flexDirection: "row"
								}}
								       >
									<Text
										key={i} style={{ marginLeft: t.depth * 15,
											display: "flex",
										}}
									>{"\u25E6\u00A0\u00A0"}
									</Text>
									<Text>
										{t.segments.map((s, index) => <Text
											key={index}
											style={{
												display: "flex",
												...s.styles,
											}}
										                     >
											{s.text}
										</Text>
										)}
									</Text>
								</View>
							default:
								return <View style={{
									display: "flex",
									flexDirection: "row"
								}}
								       >
									<Text
										key={i} style={{ marginLeft: t.depth * 15,
											display: "flex",
										}}
									>{"\u25AA\u00A0\u00A0"}
									</Text>
									<Text>
										{t.segments.map((s, index) => <Text
											key={index}
											style={{
												display: "flex",
												...s.styles,
											}}
										                     >
											{s.text}
										</Text>
										)}
									</Text>
								</View>
						}
					}
					case "ordered-list-item": {
						return <View style={{
							display: "flex",
							flexDirection: "row"
						}}
						       >
							<Text
								key={i} style={{ marginLeft: t.depth * 15,
									display: "flex",
								}}
							>{`${t.orderedNumber}.\u00A0\u00A0`}
							</Text>
							{/* <Text
								style={{
									display: "flex",

								}} key={i}
							>{t.text}
							</Text> */}
							<Text>
								{t.segments.map((s, index) => <Text
									key={index}
									style={{
										display: "flex",
										...s.styles,
									}}
								                     >
									{s.text}
								</Text>
								)}
							</Text>

						</View>
					}
					default:

						// return <Text key={i} style={{ marginLeft: t.depth * 15 }}>{t.text === "" ? "\n" : t.text}</Text>

						return <View
							key={i} style={{
								marginLeft: t.depth * 15,
								display: "flex",
								flexDirection: "row",
								// flexWrap: "wrap",
								// flex: 1,
								// whiteSpace: "nowrap",
								// overflowWrap: "break-word",
								// wordWrap: "normal",
								// wordBreak: "normal"
							}}
						       >
							<Text>
								{t.text === "" ? <Text>{"\n"}</Text>
									  	: t.segments.map((s, index) => <Text
										  key={index}
											style={{
												alignItems: "flex-start",
												display: "inline-block",
												...s.styles,
											}}
									  	                      >
										{`${s.text}`}
									</Text>
									  	)
							}
							</Text>

             </View>
				}
			})}
		</View>
	} else {
		return	title.length || text.length
			? <View style={summaryWrapper}>
				<Text style={summaryTitle}>{title}</Text>
				<Text>{text}</Text>
			</View>
			: null
	}
}

PDFSummary.propTypes = {
	summary: PropTypes.shape({
		title: PropTypes.string.isRequired,
		text: PropTypes.string.isRequired,
	}),
}

export default PDFSummary
