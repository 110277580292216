import PropTypes from 'prop-types'
import PDFHeader from '../../../../components/print-components/pdf-header'
import PDFFooter from '../../../../components/print-components/pdf-footer'
import PDFConsultationTitle from './pdf-consultation-title/index'
import PDFBigPicture from './pdf-big-picture/index'
import PdfSavedBeenDoneBlocks from './pdf-saved-been-done-blocks/index'
import PdfWhereWeGoFromHereTable from './pdf-where-we-go-from-here-table/pdf-where-we-go-from-here-table'

const PdfConsultationDetailView = ({
  consultation,
  isFooterCount,
  chartImage,
  opportunities,
  headerLocation,
  headerOrganization
}) => {
  const {
    name,
    rangeFrom,
    rangeTo,
    theBigPictureTitle,
    theBigPictureValueDetails,
    highlight1,
    highlight2,
    highlight3,
    whatWeHaveSaved,
    whereWeHaveBeen,
    whatWeHaveDone,
    whereWeGoFromHereTitle,
    whereWeGoFromHereDisplay
  } = consultation
  const bigPicture = {
    title: theBigPictureTitle,
    value: theBigPictureValueDetails?.value
  }
  return (
    <>
      <PDFHeader
        isFixed={true}
        locationNames={[headerLocation]}
        organizationName={headerOrganization}
        itemName={name}
      />
      <PDFConsultationTitle title={name} dates={`${rangeFrom} - ${rangeTo}`} />
      <PDFBigPicture
        bigPicture={bigPicture}
        highlights={[{ ...highlight1 }, { ...highlight2 }, { ...highlight3 }]}
      />
      <PdfSavedBeenDoneBlocks
        mode={whatWeHaveSaved.mode}
        chartImage={chartImage}
        image={whatWeHaveSaved.original}
        title={whatWeHaveSaved.title}
        blockName="WhatWeHaveSaved"
      />
      <PdfSavedBeenDoneBlocks
        mode={whereWeHaveBeen.mode}
        chartImage={chartImage}
        image={whereWeHaveBeen.original}
        title={whereWeHaveBeen.title}
        blockName="WhereWeHaveBeen"
      />
      <PdfSavedBeenDoneBlocks
        mode={whatWeHaveDone.mode}
        chartImage={chartImage}
        image={whatWeHaveDone.original}
        title={whatWeHaveDone.title}
        blockName="WhatWeHaveDone"
      />
      <PdfWhereWeGoFromHereTable
        columns={opportunities}
        title={whereWeGoFromHereTitle}
        visible={whereWeGoFromHereDisplay}
      />
      <PDFFooter isCountPage={isFooterCount} />
    </>
  )
}

PdfConsultationDetailView.propTypes = {
  consultation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    rangeFrom: PropTypes.string.isRequired,
    rangeTo: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired
  }),
  isFooterCount: PropTypes.bool,
  opportunities: PropTypes.array,
  headerLocation: PropTypes.string.isRequired,
  chartImage: PropTypes.object
}

export default PdfConsultationDetailView
