import { useTheme } from '@aws-amplify/ui-react'
import { StyledHeader } from './style'
import { Logo } from './components/Logo'
import { useCallback, useMemo, useState } from 'react'
import TopMenu from '../top-menu'
import { useSessionStorage } from 'src/hooks/useSessionStorage'
import { addOutsideClickEvent } from 'src/components/legacy/common/helpers'
import { selectUiMode, selectUserInfo } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import Profile from './components/profile'
import { UiMode } from 'src/redux/types/AppTypes'
import config from 'src/aws-exports.js'
import { GEN4_DEV_URL } from './constants'
// import { trackEvent } from "src/amplitude.js"
// import { USER_EVENTS } from "src/amplitude-categories"

const Header = ({ signOut }) => {
  const user = useSelector(selectUserInfo)
  const { removeValue: removeSessionUser } = useSessionStorage(
    'loggrdInUser',
    null
  )
  const { tokens } = useTheme()
  const [showProfileDialog, setShowProfileDialog] = useState(false)
  addOutsideClickEvent()

  const logout = useCallback(() => {
    signOut()
    removeSessionUser()
  }, [signOut])

  const topMenuProps = useMemo(
    () => ({
      logout,
      title: user?.firstName || '',
      toggleMyProfileDialog: () => {
        setShowProfileDialog(true)
      },
      navigateToGen4TraneConnect: () => {
        const gen4TCURL =
          config?.appEnvironment?.externalLinks?.traneConnectGen4BaseURL ||
          GEN4_DEV_URL
        window.open(`${gen4TCURL}`, '_blank')
      },
      user
    }),
    [
      logout,
      user,
      config?.appEnvironment?.externalLinks?.traneConnectGen4BaseURL
    ]
  )

  const uiMode = useSelector(selectUiMode)

  return (
    <StyledHeader bg1={tokens.colors.TBA_GREEN} bg2={tokens.colors.GREEN_4}>
      {showProfileDialog && (
        <Profile
          closeModal={() => {
            setShowProfileDialog(false)
          }}
        />
      )}
      {uiMode === UiMode.denali ? <div /> : <Logo />}
      <TopMenu {...topMenuProps} />
    </StyledHeader>
  )
}

export default Header
