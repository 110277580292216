import React from 'react'
import styled, { keyframes } from 'styled-components'
import colors from '../../common_file/colors.json'
import { Icon } from '../icon'
import { string } from 'prop-types'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

//This styles will get added only for section spinners
const sectionStyles = () => {
  return `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width:100%;
  margin: 10px 0;
  font-size: 180px;
  padding: 16px;
  border: 1px solid ${colors.TBA_DARK_GREY};
  border-radius: 2px;
  background-color: ${colors.WHITE};
  cursor: wait;
  `
}

const SpinnerSection = styled.div`
  font-size: 16px;
  ${(props) => props.type === 'section' && sectionStyles()}
`

const SpinIcon = styled(Icon)`
  font-size: inherit;
  animation: ${rotate360} 1.2s infinite linear;
`

const Spinner = ({ className, type, spinnerColor }) => (
  <SpinnerSection type={type} className={className && className}>
    <SpinIcon
      color={spinnerColor ? spinnerColor : colors.TBA_DARK_GREY}
      type="loader"
    />
  </SpinnerSection>
)

Spinner.propTypes = {
  className: string,
  type: string
}

export default Spinner
