import { useState, useMemo, useEffect } from 'react'
import { useBreakPoint } from 'src/hooks/breakPoint'
import translate from 'src/common/translations'
import { useFormik } from 'formik'
import Modal from 'src/components/legacy/components/modal/modal'
import { ACTIONS, MODAL_TYPE } from 'src/constants'
import { ObjectPickerArea, PickerButtonsWrapper, TabText } from './styles'
import ContainerWrapper from 'src/components/Container'
import { Content } from 'src/components/layouts/'
import { Button } from 'src/components/inputs/button'
import { ChartObjectPicker } from 'src/components/object-picker/chartObjectPicker'
import { CalculatedPointsOverviewForm } from './CalculatedPointsOverviewForm'
import { CalculatedPointsFormValidation } from './CalculatedFormValidationSchema'
import { ConfirmationText } from 'src/components/layouts'
import {
  BUILDING,
  EQUIPMENT_TYPE,
  EQUIPMENT,
  PROPERTY
} from 'src/components/object-picker/helpers.js'
import moment from 'moment/moment'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'

const objectPickerConfig = ['', BUILDING, EQUIPMENT_TYPE, EQUIPMENT, PROPERTY]

export const CalculatedPointsModal = ({
  toggleCalculatedPointsModal,
  createOrUpdateCalculatedPoints,
  selectedCalculatedPoint,
  deleteCalculatedPoint,
  availableUniqueUOMs,
  showCalculatedPointModal
}) => {
  const { width } = useBreakPoint()
  const [formvalues, setFormvalues] = useState(null)
  const [formikErrors, setFormikErrors] = useState({})
  const [isPropertyAddedToFormula, setIsPropertyAddedToFormula] =
    useState(false)
  const [isFormulaValid, setIsFormulaValid] = useState(true)
  const [stagedObjects, setStagedObjects] = useState(
    selectedCalculatedPoint || []
  )
  const [modalType, setModalType] = useState('')
  const [selectedDeleteData, setSelectedDeleteData] = useState('')

  const [showDeleteCalculatedPointsModal, setShowDeleteCalculatedPointsModal] =
    useState(false)

  const [reloadingObjectPicker, setReloadingObjectPicker] = useState(false)

  const [objectPickerSelection, setObjectPickerSelection] = useState([])

  const optionsTranslated = {
    createCalc: translate('Create Calculated Point'),
    editCalc: translate('Edit Calculated Point'),
    create: translate('Create'),
    save: translate('Save'),
    cancel: translate('Cancel'),
    addPoints: translate('Add Points'),
    overview: translate('Overview'),
    addFormula: translate('ADD TO FORMULA'),
    yes: translate('Yes'),
    no: translate('No'),
    close: translate('Close')
  }

  const submitForm = () => {
    setFormikErrors(formik?.errors)
    setModalType(
      selectedCalculatedPoint ? MODAL_TYPE.UPDATE : MODAL_TYPE.CREATE
    )
    formik?.handleSubmit()
    trackEvent(USER_EVENTS.UTILITY_MANAGEMENT.events.CREATE_CALCULATED_PROP)
  }

  useEffect(() => {
    setFormvalues(selectedCalculatedPoint || null)
    reInitializeObjectPicker()
  }, [selectedCalculatedPoint])

  const handleSubmit = (formValue) => {
    createOrUpdateCalculatedPoints(formValue, true)
  }

  const formik = useFormik({
    initialValues: { ...formvalues },
    onSubmit: handleSubmit,
    validationSchema: CalculatedPointsFormValidation,
    enableReinitialize: true,
    validateOnMount: false,
    validateOnChange: true,
  });

  const handleObjectPickerChange = (selectedValues) => {
    if (selectedValues) {
      setObjectPickerSelection(selectedValues)
    }
  }

  const reInitializeObjectPicker = () => {
    // This is a work around to load data for edit
    // Have to revisit again in chart picker to make it work properly
    setReloadingObjectPicker(true)
    setTimeout(() => {
      setReloadingObjectPicker(false)
    }, 200)
  }

  const clearStagedObjects = () => {
    setStagedObjects([])
    reInitializeObjectPicker()
  }

  const propertiesList = useMemo(() => {
    try {
      const selectedProperties = objectPickerSelection || []
      const allPropertiesList = []

      selectedProperties?.filter(x => x?.id !== '' && x?.propertyKey !== '').forEach((property) => {
        allPropertiesList.push({
          propertyId: property?.id,
          propertyName: property?.propertyKey,
          identifier: moment().unix(),
          uom: property?.uom,
          symbol: property?.symbol || '',
          buildingId: property?.buildingId,
          equipmentId: property.equipmentId,
          buildingName: property?.buildingName,
          equipmentName: property.equipmentName,
          equipmentFamily: property?.equipmentType
        })
      })

      return allPropertiesList
    } catch (error) {
      return []
    }
  }, [objectPickerSelection])

  const addPropertyToFormula = (event) => {
    try {
      setStagedObjects(propertiesList)
      setIsPropertyAddedToFormula(true)
      event?.preventDefault()
      event?.stopPropagation()
    } catch (error) {
      return []
    }
  }

  const formatObjectPickerSelection = () => {
    const newObjectPickerSelection = [...objectPickerSelection]
    if (isPropertyAddedToFormula) {
      const objectPickerValues = []
      newObjectPickerSelection.forEach((property) => {
        objectPickerValues.push({
          ...property,
          propertyKey: '',
          id: ''
        })
      })
      return objectPickerValues
    } else {
      return newObjectPickerSelection
    }
  }

  const modifyObject = useMemo(() => {
    if (selectedCalculatedPoint) {
      return {
        ...selectedCalculatedPoint,
        calcFormula: selectedCalculatedPoint?.formula,
        properties: selectedCalculatedPoint?.propertiesList || []
      }
    } else {
      return {}
    }
  }, [selectedCalculatedPoint])

  const toggleCalculatedPointsDeleteModal = () => {
    setModalType(MODAL_TYPE.CONFIRMATION)
    setShowDeleteCalculatedPointsModal(!showDeleteCalculatedPointsModal)
  }

  const deleteCalcPointsHandler = () => {
    toggleCalculatedPointsModal()
    setSelectedDeleteData(selectedCalculatedPoint?.name)
    setModalType(MODAL_TYPE.DELETE_SUCCESS)
    deleteCalculatedPoint()
  }

  const closeDeleteCalcPointsModal = () => {
    setModalType('')
    setShowDeleteCalculatedPointsModal(false)
  }

  const calculatedPointsModalConfig = {
    gray: true,
    className: 'wide-modal',
    isHideWhiteBackground: true,
    heading:
      selectedCalculatedPoint !== null
        ? optionsTranslated.editCalc
        : optionsTranslated.createCalc,
    handleClose: toggleCalculatedPointsModal,
    formIsErrored: Object.keys(formik?.errors)?.length > 0,
    buttons: [
      {
        text:
          selectedCalculatedPoint !== null
            ? optionsTranslated.save
            : optionsTranslated.create,
        handleClick: submitForm,
        type: 'save',
        disabled: (Object.keys(formik?.values)?.length === 0) || Object.keys(formik?.errors)?.length > 0 || !isFormulaValid
      },
      ...(selectedCalculatedPoint !== null
        ? [
            {
              text: 'Delete',
              handleClick: toggleCalculatedPointsDeleteModal,
              type: 'decline',
              disabled:
                Object.keys(formik?.errors)?.length > 0 || !isFormulaValid
            }
          ]
        : []),
      {
        text: optionsTranslated.cancel,
        handleClick: toggleCalculatedPointsModal,
        type: 'cancel'
      }
    ]
  }

  const deleteCalcPointModalConfig = useMemo(
    () => ({
      heading: (() => {
        switch (modalType) {
          case MODAL_TYPE.DELETE_SUCCESS:
            return `Calculated Point Deleted`
          case MODAL_TYPE.CREATE:
            return `Calculated Point Created`
          case MODAL_TYPE.UPDATE:
            return `Calculated Point Updated`
          default:
            return `Delete calculated point`
        }
      })(),

      // 'Delete calculated point',
      buttons:
        modalType === MODAL_TYPE.CONFIRMATION
          ? [
              {
                text: optionsTranslated.yes,
                handleClick: deleteCalcPointsHandler,
                type: 'valid'
              },
              {
                text: optionsTranslated.no,
                handleClick: closeDeleteCalcPointsModal,
                type: 'cancel'
              }
            ]
          : [
              {
                text: optionsTranslated.close,
                handleClick: closeDeleteCalcPointsModal,
                type: 'cancel'
              }
            ],
      handleClose: closeDeleteCalcPointsModal
    }),
    [selectedCalculatedPoint, modalType]
  )

  const renderConfirmationText = (modalType) => {
    switch (modalType) {
      case MODAL_TYPE.CONFIRMATION:
        return `Are you sure you want to delete calculated point: ${selectedCalculatedPoint?.name}?`
      case MODAL_TYPE.CREATE:
        return ` You have successfully Created: ${formik?.values?.name}  `
      case MODAL_TYPE.UPDATE:
        return `You have successfully Updated: ${formik?.values?.name} `
      case MODAL_TYPE.DELETE_SUCCESS:
        return `You have successfully Deleted: ${selectedDeleteData} `
    }
  }

  return (
    <>
      {[
        MODAL_TYPE.CONFIRMATION,
        MODAL_TYPE.CREATE,
        MODAL_TYPE.UPDATE,
        MODAL_TYPE.DELETE_SUCCESS
      ].includes(modalType) ? (
        <Modal testName ={"deleteCalcPoint-events"} {...deleteCalcPointModalConfig}>
          <ConfirmationText>
            {renderConfirmationText(modalType)}
          </ConfirmationText>
        </Modal>
      ) : null}

      {showCalculatedPointModal ? (
        <Modal testName = {"calcPoints-events"} {...calculatedPointsModalConfig}>
          {
            <form>
              <ContainerWrapper
                sColumns={12}
                mColumns={12}
                lColumns={12}
                xlColumns={12}
                padding={'0px 10px'}
                background="#eee"
              >
                <Content
                  xlColumn={4}
                  lColumn={12}
                  mColumn={12}
                  sColumn={12}
                  border="none"
                  width={width}
                  background="#fff"
                >
                  <ObjectPickerArea height={600} calculateModal={true}>
                    <p>{optionsTranslated.addPoints}:</p>
                    {reloadingObjectPicker ? (
                      <Spinner />
                    ) : (
                      <ChartObjectPicker
                        objects={objectPickerConfig}
                        initialValues={formatObjectPickerSelection()}
                        update={handleObjectPickerChange}
                        equipmentSelectLimit={1}
                        error={false}
                        claims={["BuildingDataChartAdmin"]}
                        fullHeight={false}
                        availableUniqueUOMs={availableUniqueUOMs}
                        mode={ACTIONS.EDIT}
                        propertySelectLimit={10}
                      />
                    )}

                    <PickerButtonsWrapper>
                      <Button
                        onClick={addPropertyToFormula}
                        testName={
                          propertiesList?.length > 0 ? 'formula' : 'disabled'
                        }
                      >
                        {optionsTranslated.addFormula}
                      </Button>
                    </PickerButtonsWrapper>
                  </ObjectPickerArea>
                </Content>
                <Content
                  xlColumn={8}
                  lColumn={12}
                  mColumn={12}
                  sColumn={12}
                  border="none"
                  width={width}
                >
                  <TabText>{optionsTranslated.overview}</TabText>
                  <CalculatedPointsOverviewForm
                    modifyObject={modifyObject || {}}
                    formik={formik}
                    setIsFormulaValid={setIsFormulaValid}
                    stagedObjects={stagedObjects}
                    unstageObjects={clearStagedObjects}
                    availableUniqueUOMs={availableUniqueUOMs}
                  />
                </Content>
              </ContainerWrapper>
            </form>
          }
        </Modal>
      ) : null }
    </>
  )
}
