import { newDateString } from "../../helper"
import { generateScaleHelper } from "./helper"

// Update Method
export const updateScale = (
  state: any = {},
  scales,
  newCanvasDetails: any = null,
  domainUpdate = true
) => {
  let newState: any = {
    ...state
  }
  const newGeneratedScales: any = {}
  Object.keys(scales)?.forEach((key: string) => {
    const axisObj: any = scales?.[key] || {}

    const newObj = generateScaleHelper(
      newState,
      axisObj,
      newCanvasDetails,
      domainUpdate
    )
    if (newObj) {
      newGeneratedScales[key] = newObj
    }
  })
  newState = {
    ...newState,
    generatedScales: {
      ...(newState?.generatedScales || {}),
      ...newGeneratedScales
    },
    [`generatedScalesT`]: newDateString()
  }

  return newState
}
