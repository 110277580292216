import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete from 'react-places-autocomplete'
import { DebounceInput } from 'src/components/legacy/components/debounce-input'
import './places-autocomplete-wrapper.scss'
import classNames from 'classnames'
import poweredByGoogle from 'src/assets/images/powered_by_google_on_white.png'

export default class PlacesAutocompleteWrapper extends PureComponent {
  static propTypes = {
    onSelect: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    testName: PropTypes.string
  }

  static defaultProps = {
    onSelect: () => {},
    onBlur: () => {},
    onFocus: () => {}
  }

  constructor(props) {
    super(props)

    this.ref = React.createRef()

    this.state = {
      searchable: false
    }
  }

  componentDidMount() {
    const inputElement = this.getInputElement()

    inputElement.addEventListener('focus', this.props.onFocus)
    inputElement.addEventListener('blur', this.props.onBlur)
  }

  componentWillUnmount() {
    const inputElement = this.getInputElement()

    inputElement.removeEventListener('focus', this.props.onFocus)
    inputElement.removeEventListener('blur', this.props.onBlur)
  }

  onSelect = (value) => {
    const inputElement = this.getInputElement()

    inputElement.blur()

    this.props.onSelect(value)
  }

  getInputElement = () => {
    const wrapperElement = this.ref.current

    return wrapperElement.getElementsByClassName('places-search-input')[0]
  }

  prepareProps = () => {
    const props = { ...this.props }

    delete props.onSelect

    return props
  }

  placesAutocompleteRender = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps
  }) => (
    <div className="places-search-bar-container">
      <div className="places-search-input-container">
        <DebounceInput
          {...getInputProps()}
          placeholder="Search Address..."
          className="places-search-input"
          type="search"
          autoComplete="off"
          debounceTimeout={300}
          testName={this.props.testName}
        />
      </div>
      {suggestions.length > 0 && (
        <div className="address-autocomplete-container">
          {suggestions.map((suggestion) => {
            const className = classNames('address-suggestion-item', {
              'address-suggestion-item-active': suggestion.active
            })
            return (
              <div {...getSuggestionItemProps(suggestion, { className })}>
                <strong>{suggestion.formattedSuggestion.mainText}</strong>{' '}
                <small>{suggestion.formattedSuggestion.secondaryText}</small>
              </div>
            )
          })}
          <div className="address-autocomplete-footer">
            <div>
              <img
                src={poweredByGoogle}
                className="autocomplete-footer-image"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )

  render() {
    return (
      <div className="places-autocomplete-wrapper" ref={this.ref}>
        <PlacesAutocomplete {...this.prepareProps()} onSelect={this.onSelect}>
          {this.placesAutocompleteRender}
        </PlacesAutocomplete>
      </div>
    )
  }
}
