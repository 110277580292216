export const PAGE_TYPE = {
    LIST: 'List',
    VIEW: 'View'
  }

  export const ACCEPTED_FILE_TYPES = ["doc", "docx", "xls", "xlsx", "ppt", "pot", "pptx", "potx", "jpg", "png", "gif", "bmp", "mpg", "pdf", "txt", "csv", "jpeg", "nb", "cdf"]

  export const MODES = {
    ADD: "add",
    EDIT: "edit",
    COPY: "copy",
    VIEW: "view",
    DELETE: "delete",
    VALUE_ASSUMPTION: "value-assumption",
  }

export const imageCategory = ["whatWeHaveSaved", "whereWeHaveBeen", "whatWeHaveDone"]

export const ViewVisibility = {
  internal : "Internal View Only",
  external : "Externally Visible"
}
