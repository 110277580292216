import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import { MAX_LENGTH_OF_HIGHLIGHT } from "src/components/legacy/common/consultation"
import { MODES } from "../../constants"
import COLORS from 'src/components/legacy/common/colors.json'
import IconSvg from "src/components/Icon"
import TextEditor from 'src/components/text-editor'
import { HIGHLIGHTS_TEXT_EDITOR_TOOLBAR_OPTIONS } from '../../helper'
import { HighlightSection } from './highlights'
import translate from "src/common/translations"

const TITLE = "title"
const VALUE = "value"
const IMPACT_TYPE = "impactType"

const iconList = ["gasoline-consumed", "home-electricity-use", "incandescent-to-LED", "miles-driven", "smartphones-charged", "trash-recycled"]

const Highlights = (props) => {
	const[highlightsData,setHighlightsData] = useState([])
	const optionsTranslated = {
		transTitle: translate("Title")
	}
	const showHighlightFunc = ({ title, impactText }) => {
		return title?.length || impactText?.blocks?.map(({ text }) => text)?.join("")
	}
	const changeHighlights = (field, index, value) => {
		const { highlights, onChange } = props

		const newHighlights = [...highlights]
		newHighlights[index] = { ...newHighlights[index], ...{ [field]: value } }

		onChange(newHighlights)
	}


	const onChangeHighlights = (field, index) => ({ target: { value } }) => {
		changeHighlights(field, index, value)
	}



	   const { highlights, mode, errors, fieldName } = props

	   useEffect(() => {
		if(errors && errors["highlights"]){
			setHighlightsData(JSON.parse(errors["highlights"]))
		}
	   }, [errors])
	   
	   useEffect(() => {
			setHighlightsData(highlights)
	   }, [highlights])
	   
	   
		return <>
			{mode === MODES.VIEW
				? highlightsData?.filter(showHighlightFunc).map(({ title, impactText, impactType, error = '' }, i) => <HighlightSection className="cs-highlight view_mode" key={`highlight-${i}`} id={`highlight-${i}`}>
					<div className="cs-highlight-box cs-highlight-view-name">
						<div className="highlight-overflow-text">{title}</div>
					</div>
					<IconSvg
						name={impactType}
						color={COLORS.ORANGE_6}
						hover={COLORS.ORANGE_6}
						float="center"
						width="60px"
						height="60px"
						viewBox="0 0 60 70"
					/>
					<div className="cs-highlight-view-body">
						<div className="cs-highlight-view-box">
							<TextEditor
								content={impactText}
								isViewMode={true}
							/>
						</div>
					</div>
				</HighlightSection>)
				: highlightsData?.map(({ title, value, impactType, impactText, error = '' }, i) => {
					const order = i + 1

					return <HighlightSection className="cs-highlight" key={`highlight-${i}`}>
						<div className="cs-highlight-box">
							<div className="box-title">{`Highlight ${order}`}</div>
						</div>
						<div className="cs-highlight-box cs-highlight-name">
							<label>{optionsTranslated.transTitle}</label>
							<input
								name={`highlights[${order}].title`}
								type="text"
								value={title}
								onChange={onChangeHighlights(TITLE, i)}
								autoComplete="off"
								maxLength={MAX_LENGTH_OF_HIGHLIGHT}
								placeholder={props?.highlightTitlePlaceholder ?? ""}
							/>
						</div>
						<div className="cs-highlight-box">

							<div className="carousel-container">
								<div className="icons-wrapper">
									<IconSvg
										type="left-caret"
										name="left-caret"
										height="20px"
										width="20px"
										color={COLORS.GREY}
										hover={COLORS.GREY}
										onClick={() => {
											const oldVal = impactType
											const oldIndex = iconList.findIndex(e => e === oldVal)
											changeHighlights(IMPACT_TYPE, i, oldIndex === 0 ? iconList[iconList.length - 1] : iconList[oldIndex - 1])
										}}
									/>
									<IconSvg
										name={impactType}
										// cursor="auto"
										color={COLORS.ORANGE_6}
										hover={COLORS.ORANGE_6}
										width="50px"
										height="50px"
										viewBox="0 0 65 65"
									/>
									<IconSvg
										type="right-caret"
										name="right-caret"
										height="20px"
										width="20px"
										color={COLORS.GREY}
										hover={COLORS.GREY}
										onClick={() => {
											const oldVal = impactType
											const oldIndex = iconList.findIndex(e => e === oldVal)
											changeHighlights(IMPACT_TYPE, i, (oldIndex === (iconList.length - 1)) ? iconList[0] : iconList[oldIndex + 1])
										}}
									/>
								</div>
							</div>
							<div className="text-editor">
								<TextEditor
									content={fieldName ? impactText : value}
									textEditorToolbarOptions={HIGHLIGHTS_TEXT_EDITOR_TOOLBAR_OPTIONS}
									changeContent={value => changeHighlights(fieldName ? fieldName : VALUE, i, value)}
									placeholder={props?.highlightContentPlaceholder ?? ""}
								/>
							</div>
						</div>
					</HighlightSection>
				})}
		</>
}

Highlights.propTypes = {
	highlights: PropTypes.array,
	onChange: PropTypes.func,
	mode: PropTypes.string,
	fieldName: PropTypes.string,
	errors: PropTypes.array,
	highlightTitlePlaceholder: PropTypes.string,
	highlightContentPlaceholder: PropTypes.string
}

Highlights.defaultProps = {
	errors: null,
	fieldName: null,
	highlightTitlePlaceholder: '',
	highlightContentPlaceholder: ''
}

export default Highlights;
