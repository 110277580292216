export const SEARCH_BUILDINGS = `query SearchDeviceBuildings(
    $filter: SearchableBuildingFilterInput
    $sort: [SearchableBuildingSortInput]
    $limit: Int
    $nextToken: String
    $nextTokenSub: String
  ) { 
    searchBuildings(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        address
        country
        state
        city
        name
        id
        devices(limit: 1000, filter: {isActive: {eq: 1}}, nextToken: $nextTokenSub) {
              nextToken
              items {
                id
                name
                buildingId
                connectionStatus
                connectionTime
                creationType
                hardwarePartNumber
                hardwareSerialNumber
                hardwareType
                isActive
                isLinked
                kernelVersion
                productVersion
                remoteAccessStatus
                remoteAccessConnections {
                  status
                  typeOfConnection
                }
                serial
                type
                typeCode
                typeDescription
                tz
              }
            }
      }
    
      }
    }
`

export const GET_BUILDINGS_BY_ACCOUNTS = `query GET_BUILDINGS_BY_ACCOUNTS($id: ID!) {
  listBuildingsByAccount(accountId: $id,limit:200) {
    items {
    salesOfficeCity
    salesOfficeCode
    salesOfficeName
    salesOfficeDistrict
    offeringCodes
    address
    country
    lat
    long
    crmCustId
    floorArea
    postalCode
    state
    city
    name
    id
    accountId
    accountName
    salesOfficeId
    devices(filter: {isActive: {eq: 1}}) {
          items {
            id
            name
            buildingId
            connectionStatus
            connectionTime
            createdAt
            creationType
            hardwarePartNumber
            hardwareSerialNumber
            hardwareType
            isActive
            isLinked
            kernelVersion
            productVersion
            remoteAccessStatus
            remoteAccessConnections {
              status
              typeOfConnection
            }
            serial
            type
            typeCode
            typeDescription
            tz
            updatedAt
          }
        }
      }
    }
  }
`
