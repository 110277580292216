import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ErrorDialog from './error-dialog/error-dialog.jsx'
import ConfirmDialog from './confirm-dialog'
import Notification from './notification'
import _get from 'lodash/get'

// TODO: Feel free to add other standard dialogs if needed (Notification, Warning)

export const DIALOG_TYPES = {
  ERROR: 'error',
  CONFIRM: 'confirm',
  NOTIFICATION: 'notification'
}

export default function StandardDialogs({
  error,
  confirm,
  onCloseDialog,
  notification,
  isLoading
}) {
  return (
    <Fragment>
      {error &&
        _get(error, 'graphQLErrors.0.extensions.code') !== 'UNAUTHENTICATED' &&
        _get(error, 'networkError.statusCode') !== 401 && (
          <ErrorDialog
            {...error}
            text={error?.text}
            handleClose={() => onCloseDialog(DIALOG_TYPES.ERROR)}
          />
        )}

      {confirm && (
        <ConfirmDialog
          {...confirm}
          handleCancel={
            confirm.handleCancel
              ? confirm.handleCancel
              : () => onCloseDialog(DIALOG_TYPES.CONFIRM)
          }
          isLoading={isLoading}
        >
          {confirm.text}
          {confirm.description && (
            <p className="description">{confirm.description}</p>
          )}
        </ConfirmDialog>
      )}

      {notification && (
        <Notification
          {...notification}
          handleCancel={onCloseDialog(DIALOG_TYPES.NOTIFICATION)}
        >
          {notification.text}
        </Notification>
      )}
    </Fragment>
  )
}

StandardDialogs.propTypes = {
  error: PropTypes.object,
  confirm: PropTypes.shape({
    handleConfirm: PropTypes.func.isRequired,
    handleCancel: PropTypes.func,
    isLoading: PropTypes.bool
  }),
  notification: PropTypes.object,
  onCloseDialog: PropTypes.func
}
