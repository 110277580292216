import { useState, useEffect, useCallback } from "react"
import { Link } from "react-router-dom"
import LoadingPill from "src/components/legacy/components/loading-pill/loading-pill"
import {
	getKeyMetricTitle,
	getKpiClass,
	getUomSymbol,
	cubicFeetConversionIndex,
	valueResolution,
	getEnergyDataAPIParams,
	formatMonthlyDataForEnergyConsumption,
	formatMonthlyDataForTotalEnergyConsumption,
	formatMonthlyDataForPeakDemand,
	getFormatDataWithIndex,
	DEFAULT_TIME_PERIOD,
	NO_GAS_COST_ESTIMATE,
	NO_ELECTRIC_COST_ESTIMATE,
	NO_COST_ESTIMATE,
	NO_DATA_AVAILABLE,
	SERVER_ERROR,
	getFormat15MinsData
} from "./helpers"
import { ConditionalTooltip } from "src/components/legacy/components/tooltip/conditional-tooltip"
import classNames from "classnames"
import { Trans } from "react-i18next"
import { KeyEnergyMetricDisplayStyle } from "./styles"
import { aggreagatorSelection, formatMonthlyEnergyData } from 'src/common/chartHelperFunctions'
import { getStpDateRange } from "src/common/stp-converter.js"
import { useQuery } from "src/hooks/APIHooks"
import { useDashboardContext } from "../../DashboardContextProvider"
import translate, { TranslateComponent } from "src/common/translations"

const GET_CHART_DATA = /* GraphQL */ `
  query getChartData($body: AWSJSON) {
    getChartData(body: $body)
  }
`

const KeyEnergyMetricDisplay = (props) => {
	const { disabled, isEnabled, stripMode, organizationId, locationId, keyMetric, uom, comparedTo, timePeriod, t, totalVirtualMeterId, stripNumber } = props
	const { refetch: getEnergyData, loading: loadingEnergyData } =
		useQuery({
			query: GET_CHART_DATA,
			dataPath: 'data.getChartData',
			disableInitialLoad: true
		})
	
	const { setKeyMetricsError, keyMetricsError, setIsMissingDataStatuses } = useDashboardContext()

	const [timePeriodKeyMetricValue, setTimePeriodKeyMetricValue] = useState(null)
	const [comparedToKeyMetricValue, setComparedToKeyMetricValue] = useState(null)
	const [keyMetricValue, setKeyMetricValue] = useState(null)
	const [energyValue, setEnergyValue] = useState(null)
	const [isAPIError, setIsAPIError] = useState(false)
	const [noCostEstimate, setNoCostEstimate] = useState(false)
	const DATE_FORMAT = "YYYY-MM-DD"
	const TYPES = { GAS: 'Gas', ELECTRIC: 'Electrical', TOTAL: 'Total', NODATA: 'NoData', SERVER: 'Server' }
	const aggregateValue = (totalValues: any) => {
		return Number(
			aggreagatorSelection('sum', totalValues, 0, totalValues?.length)
		) || 0
	}

	const setErrorCode = (isNoEstimate, isNoData, type) => {
		let errorCode = ''
		switch (type) {
			case TYPES.GAS:
				errorCode = isNoEstimate ? NO_GAS_COST_ESTIMATE : ''
				break;
			case TYPES.ELECTRIC:
				errorCode = isNoEstimate ? NO_ELECTRIC_COST_ESTIMATE : ''
				break;
			case TYPES.TOTAL:
				errorCode = isNoEstimate? NO_COST_ESTIMATE : ''
				break;
			case TYPES.NODATA:
				errorCode = isNoData ? NO_DATA_AVAILABLE : ''
				break;
			case TYPES.SERVER:
				errorCode = !isNoData && !isNoEstimate? SERVER_ERROR : ''
				break;
			default:
				errorCode = '';
		}
		const existingErrors = keyMetricsError
		const existingKeyMetricError = existingErrors?.find(f=>f?.stripNumber === stripNumber) ?? null
		if(existingKeyMetricError){
			existingErrors?.forEach(element => {
				if(element?.stripNumber === stripNumber){
					element.errorCode = errorCode
				}
			});
		}
		setKeyMetricsError(existingErrors)
	}

	const optionTranslated = {
    NoDataAvailable: translate('No Data Available'),
    EnergyMetric: translate('Energy Metric')
  }

	useEffect(() => {
		if (!timePeriodKeyMetricValue && !comparedToKeyMetricValue) return
		let value = null
		let isNoEstimate = false
		let isNoData = false
		let type = ''
		switch (keyMetric) {
			case 'totalGasCost':
				isNoEstimate = timePeriodKeyMetricValue?.gasCostError?.isNoEstimate ?? false
				isNoData = timePeriodKeyMetricValue?.gasCostError?.isNoDataAvailable ?? false
				type = isNoEstimate ? TYPES.GAS : TYPES.NODATA
                value = aggregateValue(timePeriodKeyMetricValue?.gasConsumptionCost?.values)
				break;
			case 'totalElectricCost':
				isNoEstimate = timePeriodKeyMetricValue?.electricalCostError?.isNoEstimate ?? false
				isNoData = timePeriodKeyMetricValue?.electricalCostError?.isNoDataAvailable ?? false
				type = isNoEstimate ? TYPES.ELECTRIC : TYPES.NODATA
				value = aggregateValue(timePeriodKeyMetricValue?.consumptionCost?.values)
				break;
			case 'totalEnergyCost':
				isNoEstimate = timePeriodKeyMetricValue?.totalCostError?.isNoEstimate ?? false
				isNoData = timePeriodKeyMetricValue?.totalCostError?.isNoDataAvailable ?? false
				type = isNoEstimate ? TYPES.TOTAL : TYPES.NODATA
				value = aggregateValue(timePeriodKeyMetricValue?.totalCost?.values)
				break;
			case 'peakElectricDemand':
				isNoEstimate = timePeriodKeyMetricValue?.peakDemandError?.isNoEstimate ?? false
				isNoData = timePeriodKeyMetricValue?.peakDemandError?.isNoDataAvailable ?? false
				type = isNoEstimate ? TYPES.TOTAL : TYPES.NODATA
				value = timePeriodKeyMetricValue?.peakDemand
				break;
			case 'totalElectricConsumption':
				isNoEstimate = timePeriodKeyMetricValue?.electricalConsumptionError?.isNoEstimate ?? false
				isNoData = timePeriodKeyMetricValue?.electricalConsumptionError?.isNoDataAvailable ?? false
				type = isNoEstimate ? TYPES.ELECTRIC : TYPES.NODATA
				value = aggregateValue(timePeriodKeyMetricValue?.electricalConsumption?.values)
				break;
			case 'totalGasConsumption':
				isNoEstimate = timePeriodKeyMetricValue?.gasConsumptionError?.isNoEstimate ?? false
				isNoData = timePeriodKeyMetricValue?.gasConsumptionError?.isNoDataAvailable ?? false
				type = isNoEstimate ? TYPES.GAS : TYPES.NODATA
				value = aggregateValue(timePeriodKeyMetricValue?.gasConsumption?.values)
				value = value * cubicFeetConversionIndex({ name: uom })
				break;
			case 'totalEnergyConsumption':
				isNoEstimate = timePeriodKeyMetricValue?.totalConsumptionError?.isNoEstimate ?? false
				isNoData = timePeriodKeyMetricValue?.totalConsumptionError?.isNoDataAvailable ?? false
				type = isNoEstimate ? TYPES.TOTAL : TYPES.NODATA
				value = timePeriodKeyMetricValue?.totalConsumption
				break;
			case "peakElectricDemandPercentChange":
				value = getPercentageEnergyValue(timePeriodKeyMetricValue?.peakDemand, comparedToKeyMetricValue?.peakDemand,true)
				break;
			case "totalEnergyPercentChange":
				value = getPercentageEnergyValue(timePeriodKeyMetricValue?.totalConsumption, comparedToKeyMetricValue?.totalConsumption,true)
				break;
			case "totalElectricPercentChange":
				value = getPercentageEnergyValue(timePeriodKeyMetricValue?.consumptionCost?.values, comparedToKeyMetricValue?.consumptionCost?.values)
				break;
			case "totalGasPercentChange":
				value = getPercentageEnergyValue(timePeriodKeyMetricValue?.gasConsumptionCost?.values, comparedToKeyMetricValue?.gasConsumptionCost?.values)
				break;
			default:
				setKeyMetricValue(null)
				break;
		}		
		setNoCostEstimate(isNoEstimate)
		setIsMissingDataStatuses(isNoData)
		setErrorCode(isNoEstimate,isNoData,type)
		setEnergyValue(value)
		const metricValue = getValue(value, uom)
		setKeyMetricValue(metricValue)
	}, [timePeriodKeyMetricValue, comparedToKeyMetricValue])

	const getPercentageEnergyValue = (timePeriodValue: any, comparedToValue: any, isNoAggregation = false) => {
		const value1 = isNoAggregation? timePeriodValue: aggregateValue(timePeriodValue)
		const value2 = isNoAggregation ? comparedToValue : aggregateValue(comparedToValue)
		let value = null
		if (!value1 || !value2) {
			value = null
		} else {
			if(value1 !== 0 || value2 !== 0 )
				value = (value1 - value2) / value2 * 100
		}
		return value
	}

	const getEnergyCostFormedData = async (periodRange, isTimePeriod = false) => {
		const startDt = periodRange.startDate.format(DATE_FORMAT)
		const endDt = periodRange.endDate.format(DATE_FORMAT)
		const properties = ['ConsumptionCost', 'GasConsumptionCost','DemandCost','AdditionalCost']
		const uomDetails = { "ConsumptionCost": uom, "GasConsumptionCost": uom, "DemandCost": uom, "AdditionalCost": uom }
		const request = getEnergyDataAPIParams(startDt, endDt, locationId, totalVirtualMeterId, properties, uomDetails, true)
		const response = await getEnergyData(request)
		const formatData = getFormatDataWithIndex(response,'consumptionData')
		const formedResponse = formatMonthlyEnergyData(formatData)
		if (isTimePeriod)
			setTimePeriodKeyMetricValue(formedResponse)
		else
			setComparedToKeyMetricValue(formedResponse)

	}

	const getTotalEnergyCosumptionFormedData = async (periodRange, isTimePeriod = false) => {
		const startDt = periodRange.startDate.format(DATE_FORMAT)
		const endDt = periodRange.endDate.format(DATE_FORMAT)
		const properties = [{"sum":['TotalConsumption']}] 
		const uomDetails = { "TotalConsumption": uom}
		const request = getEnergyDataAPIParams(startDt, endDt, locationId, totalVirtualMeterId, properties, uomDetails)
		const response = await getEnergyData(request)
		const responseData = JSON.parse(response)
		const formatedData= responseData?.body?.agg_data[totalVirtualMeterId]?.sum
		const formedResponse = formatMonthlyDataForTotalEnergyConsumption(formatedData)
		if (isTimePeriod)
			setTimePeriodKeyMetricValue(formedResponse)
		else
			setComparedToKeyMetricValue(formedResponse)

	}
	const getEnergyCosumptionFormedData = async (periodRange, isTimePeriod = false) => {
		const startDt = periodRange.startDate.format(DATE_FORMAT)
		const endDt = periodRange.endDate.format(DATE_FORMAT)
		const properties = [{ "sum": ['TotalElectricConsumption', 'TotalGasConsumption'] }]
		const uomDetails = { "TotalElectricConsumption": uom, "TotalGasConsumption": uom }
		const request = getEnergyDataAPIParams(startDt, endDt, locationId, totalVirtualMeterId, properties, uomDetails)
		const response = await getEnergyData(request)
		const formatData = getFormatDataWithIndex(response,'agg_data',false)
		const formedResponse = formatMonthlyDataForEnergyConsumption(formatData)
		if (isTimePeriod)
			setTimePeriodKeyMetricValue(formedResponse)
		else
			setComparedToKeyMetricValue(formedResponse)

	}

	const getElectricalDemandFormedData = async (periodRange, isTimePeriod = false) => {
		const startDt = periodRange.startDate.format(DATE_FORMAT)
		const endDt = periodRange.endDate.format(DATE_FORMAT)
		const properties = ['InstantaneousPower']
		const uomDetails = { "InstantaneousPower": uom }
		const request = getEnergyDataAPIParams(startDt, endDt, locationId, totalVirtualMeterId, properties, uomDetails)
		const response = await getEnergyData(request)
		const formatData = getFormatDataWithIndex(response,"data")
		const formed15MinsData = getFormat15MinsData(formatData , startDt, endDt)
		const formedResponse = formatMonthlyDataForPeakDemand(formed15MinsData)
		if (isTimePeriod)
			setTimePeriodKeyMetricValue(formedResponse)
		else
			setComparedToKeyMetricValue(formedResponse)

	}

	const loadData = useCallback(async () => {
		if (totalVirtualMeterId && isEnabled && isAllDataAvailable()) {
			try {
				setEnergyValue(null)
				setKeyMetricValue(null)
				setIsMissingDataStatuses(false)
				const stpPeriodRange = getStpDateRange(timePeriod || DEFAULT_TIME_PERIOD)
				const stpComparedPeriodRange = comparedTo ? getStpDateRange(comparedTo) : null
				if (stpPeriodRange) {
					switch (keyMetric) {
						case 'totalGasCost':
						case 'totalElectricCost':
						case 'totalEnergyCost':	
						case 'totalElectricPercentChange':
						case 'totalGasPercentChange':
							await getEnergyCostFormedData(stpPeriodRange, true)
							if(stpComparedPeriodRange && ['totalElectricPercentChange','totalGasPercentChange'].includes(keyMetric))
								await getEnergyCostFormedData(stpComparedPeriodRange)
							break;
						case 'totalElectricConsumption':
						case 'totalGasConsumption':
							await getEnergyCosumptionFormedData(stpPeriodRange, true)
							break;
						case 'totalEnergyConsumption':
						case 'totalEnergyPercentChange':
							await getTotalEnergyCosumptionFormedData(stpPeriodRange, true)
							if (stpComparedPeriodRange && ['totalEnergyPercentChange'].includes(keyMetric))
								await getTotalEnergyCosumptionFormedData(stpComparedPeriodRange)
							break;
						case 'peakElectricDemand':
						case 'peakElectricDemandPercentChange':
							await getElectricalDemandFormedData(stpPeriodRange, true)
							if(stpComparedPeriodRange && ['peakElectricDemandPercentChange'].includes(keyMetric))
								await getElectricalDemandFormedData(stpComparedPeriodRange)
							break;

						default:
							break;
					}
				}
				
			} catch (error) {
				setErrorCode(false,false,TYPES.SERVER)
				setIsAPIError(true)
			}
		} else {
			setErrorCode(false,false,'')
			setKeyMetricValue(null)
			setEnergyValue(null)
			setIsMissingDataStatuses(false)
		}
	}, [timePeriod, comparedTo, totalVirtualMeterId, keyMetric, uom, isEnabled])

	useEffect(() => {
		loadData()
	}, [loadData])



	const kpiClass = keyMetric ? getKpiClass(keyMetric) : null

	const isAllDataAvailable = () => {
		return organizationId && locationId && timePeriod && comparedTo
	}
	// return false if metric needs compareTo period and comparedTo == ":none"
	const checkComparedTo = () => {
		return uom !== "percent" || comparedTo !== ":none"
	}

	const getValue = (value, uom) => {
		const uomSymbol = uom ? getUomSymbol(uom) : null

		return (uom === "usd")
			? `${uomSymbol}${valueResolution(value, "1")}`
			: `${valueResolution(Math.abs(value), "1")}${uomSymbol}`
	}

	const renderDataBlock = () => {
		const title = keyMetric ? getKeyMetricTitle(keyMetric) : null
		const kpiClass = keyMetric ? getKpiClass(keyMetric) : null
		const comparedToLabel = t(`kpi>${comparedTo}`, { nsSeparator: ">" })
		const comparedToText = `vs. ${comparedToLabel}`
		return <>
			<span className="end-cap">
				<span className={kpiClass}>
					<span />
				</span>
			</span>
			<div className="data">
				<div className="title">
					<ConditionalTooltip
						type={ConditionalTooltip.TYPE.ICON}
						conditional={true}
						content={<TranslateComponent>{title}</TranslateComponent>}
						position={ConditionalTooltip.POSITION.BOTTOM}
						align={ConditionalTooltip.ALIGN.CENTER}
					>
						<TranslateComponent>{title}</TranslateComponent>
					</ConditionalTooltip>
				</div>

				{loadingEnergyData
					? (
						<div className="calculating-block">
							<LoadingPill />
							<div className="subtitle">{<TranslateComponent>Energy Metric</TranslateComponent>}</div>
						</div>
					)
					: energyValue !== null && energyValue !== undefined && !isNaN(Number(energyValue)) && energyValue !== 0
						? (
							<>
								<div className="value">
									<ConditionalTooltip
										type={ConditionalTooltip.TYPE.ICON}
										conditional={true}
										content={<TranslateComponent>{keyMetricValue}</TranslateComponent>}
										align={ConditionalTooltip.ALIGN.CENTER}
									>
										<TranslateComponent>{keyMetricValue}</TranslateComponent>
										{uom === "percent" && energyValue !== 0
											&& <i className={classNames("icon-indicator-arrow", { "down": energyValue < 0 })} />
										}
									</ConditionalTooltip>
								</div>
								<div className="bottom-text">
									<TranslateComponent>{uom === "percent" && comparedToText}</TranslateComponent>
								</div>
							</>
						)
						: renderMissingDataBlock(kpiClass, `No Data Available`, optionTranslated.NoDataAvailable)
				}
			</div>
		</>
	}

	const renderMissingDataBlock = (kpiClass, text, translatedText) => {

		if (noCostEstimate && stripMode && ["totalGasCost", "totalGasConsumption"].includes(keyMetric)) {
			return (
				<div className="missing-cost-estimator-block">
					<TranslateComponent>Click to create a</TranslateComponent> <Link to="/rate-structure-library"><TranslateComponent>gas</TranslateComponent></Link> <TranslateComponent>cost estimate to complete this calculation.</TranslateComponent>
				</div>
			)
		} else if (noCostEstimate && stripMode && ["totalElectricCost", "totalElectricConsumption"].includes(keyMetric)) {
			return (
				<div className="missing-cost-estimator-block">
					<TranslateComponent>Click to create an</TranslateComponent> <Link to="/rate-structure-library"><TranslateComponent>electric</TranslateComponent></Link> <TranslateComponent>cost estimate to complete this calculation.</TranslateComponent>
				</div>
			)
		} else if (noCostEstimate && stripMode && ["totalEnergyCost", "totalEnergyConsumption"].includes(keyMetric)) {
			return (
				<div className="missing-cost-estimator-block">
					<TranslateComponent>Click to create an</TranslateComponent> <Link to="/rate-structure-library"><TranslateComponent>electric</TranslateComponent></Link> <TranslateComponent>or</TranslateComponent> <Link to="#"><TranslateComponent>gas</TranslateComponent></Link> <TranslateComponent>cost estimate to complete this calculation.</TranslateComponent>
				</div>
			)
		} else if (isAPIError) {
			if (stripMode) {
				return renderServerErrorBlock("A problem occurred loading this data.")
			}
		} else if (locationId && !checkComparedTo()) {
			return (
				<div className={stripMode ? "missing-comparison-period-block-sm" : "missing-comparison-period-block"}>
					<TranslateComponent>Choose a comparison period to see % change</TranslateComponent>
				</div>
			)
		}
		return (
			<div className={`missing-data-block ${!isEnabled ? 'toggle-off' : ''}`}>
				{!stripMode && <div className={kpiClass} />}
				<div className="text" title={translatedText}><TranslateComponent>{text}</TranslateComponent></div>
			</div>
		)
	}

	const renderServerErrorBlock = (text) => {
		return (
			<div className="kpi-block-error">
				<span className="icon icon-error" />
				<div className="subtitle"><TranslateComponent>{text}</TranslateComponent></div>
			</div>
		)
	}

	return (
		<KeyEnergyMetricDisplayStyle>
			<div className={`kpi-energy-metric-display ${isAPIError ? "kpi-energy-metric-display-error" : ""}`}>
				{!disabled
					? renderDataBlock()
					: renderMissingDataBlock(kpiClass, "Energy Metric", optionTranslated.EnergyMetric)
				}
			</div>

		</KeyEnergyMetricDisplayStyle>
	)
}

export default KeyEnergyMetricDisplay