import { useEffect, useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import {useParams } from "react-router-dom"
import moment from 'moment'
import {GET_CONTRACT_BY_ID, LIST_OFFERINGS_BY_CONTRACT,LIST_ATTACHMENTS_BY_CONTRACT } from './queries'
import { ACTIONS } from 'src/constants'
import {CreateContract} from "./add"
import {EditActiveContract} from "./editActive"
import {getUniqueItems} from "src/common/helperFunctions"
import {
    getContractPeriodByExpirationDate
  } from "src/pages/contracts-and-offerings/common/contracts-and-offerings"
  import {
    utcTimestampToDateFormat
  } from 'src/components/legacy/common/time-helpers'



export const ViewOnAction = () => {
  const { id: contractId } = useParams()
  const [contractOfferings,setContractOfferings] = useState(null)
  const [initialValues,setInitialValues] = useState(null)


  const {
    refetch: refetchContractById,
    loading:loadingContractById
  } = useQuery({
    query:GET_CONTRACT_BY_ID,
    disableInitialLoad:true,
    dataPath:"data.getContract"
  })

  const {
    refetch: refetchOfferingsByContract
  } = useQuery({
    query:LIST_OFFERINGS_BY_CONTRACT,
    disableInitialLoad:true,
    dataPath:"data.listOfferingsByContract.items"
  })

  const {
		refetch: refetchAttachmentsByContract,
	  } = useQuery({
		query: LIST_ATTACHMENTS_BY_CONTRACT,
		disableInitialLoad: true,
		errorPolicy: 'global',
		dataPath: 'data.listAttachmentsByContract.items',
	  })



  useEffect(() => {
    if(contractId){
      (async () => {
       const contractAndOfferings = await Promise.all([
        refetchContractById({id:contractId}),
        refetchOfferingsByContract({id:contractId})
       ])
       setContractOfferings({contract : contractAndOfferings[0],offerings :contractAndOfferings[1]})
      })()
      
    }
  }, [contractId])

  useEffect(() => {
    (async() => {
      if(contractOfferings){
        const {contract,offerings} = contractOfferings
        const attachments  = await refetchAttachmentsByContract({id:contract.id})
        const {startDate,endDate,analyticsStartDate} = offerings[0]
        const {buildingIds,offeringNames} = offerings.reduce((acc,{buildingId,subscriptionCode}) => {
         acc["buildingIds"].push(buildingId)
         acc["offeringNames"].push(subscriptionCode)
         return acc
        },{"buildingIds" :[],"offeringNames":[]})
        const {mechanicalServiceAgreementNumber = null,pactContractNumber = null} = JSON.parse(contract.additionalContractNumbers || "{}")
        const buildingIdList = getUniqueItems(buildingIds)
        const buildingWithCRMList = buildingIdList?.map(m => ({ buildingId: m, crmId: "", isCRMIdEmpty: false, isCRMIDUpdated: false }))
       setInitialValues({
          contractId:contract.id,
          accountId:contract.accountId,
          status:contract.status,
          attachments:Array.isArray(attachments) ? attachments?.map(attachment => ({
            ...attachment,
            fileSize:attachment.sizeInBytes,
            fileName:attachment.name
          })): [],
          originalAttachments:attachments,
        buildingCount: contract.buildingCount,
        numberOfDevices: contract.deviceCount,
        buildingIds : buildingIdList,
        offeringDetails:offerings,
        offerings: getUniqueItems(offeringNames),
        startDate: utcTimestampToDateFormat(startDate),
        expirationDate: utcTimestampToDateFormat(endDate),
        analyticsStartDate: utcTimestampToDateFormat(analyticsStartDate),
        length: getContractPeriodByExpirationDate({contractStartDate:startDate,expirationDate:endDate}),
        serviceContractNumber: contract.serviceNumber,
        pactContractNumber,
        mechanicalServiceAgreementNumber,
        coachingServicesValue:contract.coachingServicesValue,
        totalBaselineReportCount:contract.totalBaselineReportCount,
        primaryBuilding:contract.primaryBuilding,
        supportingNotes:contract.notes,
        accountManager: contract.accountMgr,
        additionalRecipients:contract.receipients?.join(","),
        termsAccepted: contract.termsAccepted || true,
        businessStream: contract.stream,
        billingSalesOfficeCode:contract.billingSalesOfficeCode,
        offeringsToBuildinsMap : offerings?.map(offering => ({
          id: offering.id,
          name: offering.subscriptionCode,
          buildingId: offering.buildingId
      })),
        buildingsWithCRMIDs: buildingWithCRMList ?? [],
        csTermsAccepted: contract?.csTermsAccepted
       }
        )
      }
    })()
  }, [contractOfferings])




  return (
    initialValues && (
        /Requested|Declined/i.test(initialValues.status) ? 
        <CreateContract actionMode = {ACTIONS.EDIT} initialValues ={initialValues}/> :
<EditActiveContract initialValues ={initialValues}/>
    )
  )
}
