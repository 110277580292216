import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { round, hasEmptyProperties, getUrlSearchParamValue } from 'src/components/legacy/common/helpers'
import {
  formatStatus,
  formatSavingsView,
  formatPrice,
  formatNextStep,
  costOfWaitingValue,
  MAX_PAGE_WIDTH,
  nextStepOptionList
} from 'src/components/legacy/common/opportunity.js'
import { formatCurrency } from 'src/components/legacy/common/currency'
import KPI from 'src/components/legacy/components/kpi/kpi'
import File from 'src/components/file-download/file'
import Header from 'src/components/legacy/components/header/header'
import Toolbar from 'src/components/legacy/components/toolbar/toolbar'
import InlineHelp from 'src/components/legacy/components/inline-help/inline-help'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import TargetPathView from 'src/components/target-path/TargetPathView'
import LabelBlank from 'src/components/LabelBlank/index'
import Priority from 'src/components/Priority/Priority'
import ImagesFindingsSection from 'src/pages/opportunities/OpportunityDetail/images-findings-section/images-findings.section'
import CostSaving from 'src/pages/opportunities/OpportunityDetail/cost-savings/cost-savings-view'
import TextEditor from 'src/components/text-editor/index'
import classNames from 'classnames'
import { Container, Content } from 'src/components/layouts/index'
import COLORS from 'src/components/legacy/common/colors.json'
import _isEmpty from 'lodash/isEmpty'
import PAGE_NAMES, { NOTIFICATIONS } from 'src/components/legacy/common/pages'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import EditableField from 'src/pages/issuesFindings/blocks/editable-field'
import { statusOptionList } from 'src/components/legacy/common/opportunity'
import _set from 'lodash/set'
import { AssignedTo } from 'src/components/AssignedTo/index'
import NotifyContent from './OpportunityDetail/notify-content/index'
// import {
//   selectUserPermissions
// } from 'src/redux/slicers/appData'
import { useTranslation } from 'react-i18next'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { CostSavingsWrapper, OpportunityViewWrapper } from './styles'
import { useNavigate } from 'react-router'
import { ACTIONS } from 'src/constants'
import { MODAL_TYPE } from './constants'
import { useOpportunityContext } from './OpportunityContextProvider'
import { useMutation } from 'src/hooks/APIHooks'
import { UPDATE_OPPORTUNITY } from './graphql'
import PdfDownloadDetailView from '../../components/print-components/pdf-download-detail-view/pdf-download-detail-view'
import { PdfDownloadDetailViewPages } from '../../components/print-components/helpers'
import translate, { TranslateComponent } from 'src/common/translations'
import { AccessControl } from 'src/components/accessControl'
import {  GET_BUILDING_BY_ID,  } from ".././utilityBillData/graphql"
import { useQuery } from 'src/hooks/APIHooks'
import { SEARCH_BUILDINGS } from 'src/common/queries/datasource'
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import { ConfirmationText } from 'src/components/layouts'
import Modal from 'src/components/legacy/components/modal/modal'

export function OpportunityDetailView({ opportunity, setOpportunity, action }) {
  // Component States
  const { width } = useBreakPoint()
  const [isShowMessage, setIsShowMessage] = useState(false)
  const { setIsDeletedDone, setModalType, setOpportunityInputValues } =
    useOpportunityContext()
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [confirm, setConfirm] = useState(null)

  const { refetch: refetchBuildingAndAccount } = useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.searchBuildings.items',
    onSuccess: (data) => {
      if (data.length) {
        setOpportunity({...opportunity, organization: {organizationId: data[0].accountId , organizationName: data[0].accountName }, location: {locationId: data[0].id, locationName: data[0].name}})
      }
    }
  })

  useEffect(() => {
    if (opportunity.location.locationId && (!opportunity.location.locationName || !opportunity.organization.organizationName)) {
      refetchBuildingAndAccount({filter: {id: {eq: opportunity.location.locationId}}})
    }
  }, [opportunity])
  
  useEffect(() => {
    setIsShowMessage(action ? true : false)
  }, [action])
  

  //TODO: commented access control below because using userPermissions this way will no longer work.
  // const permissions = useSelector(selectUserPermissions)
  const notificationsOn = true //permissions?.isPermittedAction(NOTIFICATIONS)
  const [showInlineSpinner, setShowInlineSpinner] = useState({
    status: false,
    nextStep: false,
    assignedTo: false
  })
  const [error, setError] = useState(null)
  const [pdfOpportunity, setPdfOpportunity] = useState(null)

  // const formatDate = date => moment(date).format(DATE_FORMAT)
  const optionsTranslated = {
    createNew: translate("Create New"),
    copyOpp: translate('Copy Opportunity'),
    editOpp: translate('Edit Opportunity'),
    deleteOpp: translate('Delete Opportunity'),
    createdBy: translate('Created By'),
    createdDate: translate('Created Date'),
    energySavings: translate('Energy Savings'),
    price: translate('Price'),
    roi: translate('Simple ROI'),
    opp: translate('Opportunities'),
    oppBody: translate('The Opportunities feature lets Trane offices work with their customers to record and view details of different opportunities for building improvements.'),
    visible: translate('Visible'),
    notVisible: translate('Not visible to customer'),
    status: translate('Status'),
    nextStep: translate('Next step'),

  }

  const isAnyImageAdded = (images = []) =>
    images.some((image) => !hasEmptyProperties(image))

  const deleteOpportunity = () => {
    setOpportunityInputValues({
      ...opportunity,
      id: opportunity?.opportunityId,
      title: opportunity?.name
    })
    setModalType(MODAL_TYPE.CONFIRMATION)
    setIsDeletedDone(false)
  }

  const { onSubmit: upsertOpportunityMutation } = useMutation({
    query: UPDATE_OPPORTUNITY,
    onError: (error) => {
      setError(error)
      setConfirm(null)
    }
  })
  const { data: buildingDetails, refetch: fetchBuildingDetails, loading: buildingDetailsLoading } = useQuery({
    query: GET_BUILDING_BY_ID,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.getBuilding',
  })
  useEffect(() => {
    if(opportunity?.location.locationId){
      fetchBuildingDetails({id:opportunity?.location.locationId}) 
    }
   
  }, [opportunity])
  // Need to refactor if the field is not set properly
  const onCloseDialog = (field) => () => {
    switch (field) {
      case 'confirm':
        setConfirm(null)
        break
      case 'showInlineSpinner':
        setShowInlineSpinner(null)
        break
      case 'error':
        setError(null)
        break
    }
  }

  const displayCostSavings = (obj) => Boolean(obj && obj.enabled && obj.value)

  const displayEnergyList = (costSavings, costOfWaiting) => {
    const { savings, costToImplement, paybackCalculation, roiCalculation } =
      costSavings
    return (
      (costOfWaiting && costOfWaiting.value) ||
      [savings, costToImplement, paybackCalculation, roiCalculation].some(
        displayCostSavings
      )
    )
  }

  const displayPDFDownload = () => {
    return (
    pdfOpportunity && <PdfDownloadDetailView downloadData={pdfOpportunity} type={PdfDownloadDetailViewPages.OPPORTUNITY} />
    )
  }

  const closeNotifyModal = () => {
    setConfirm(null)
  }

  const editableViewChangeHandler =
    (fieldName, opportunityData) => async (value) => {
      const subFieldsList = fieldName.split('.')
      const updatePropertyName = [...subFieldsList].pop()
      setShowInlineSpinner({
        ...showInlineSpinner,
        [updatePropertyName]: true
      })
      let notificationMessage
      if (fieldName.includes('assignedTo') && notificationsOn) {
        notificationMessage = t('notifications:ThisEntityAssignedTo', {
          entity: t('opportunities:Opportunity')
        })
        setError(null)
        setConfirm({
          title: t('notifications:SaveAndNotify'),
          text: (
            <NotifyContent
              name={opportunityData.settings.assignedTo.key}
              entity={t('opportunities:Opportunity')}
              defaultValue={notificationMessage}
              onChangeMessage={(event) => {
                notificationMessage = event.target.value
              }}
            />
          ),
          confirmText: t('common:Send'),
          cancelText: t('notifications:NoNotification'),
          handleConfirm: () => {
            closeNotifyModal()
            updatePropertyValues(fieldName, updatePropertyName, value, opportunityData)
          },
          handleCancel: () => {
            closeNotifyModal()
            updatePropertyValues(fieldName, updatePropertyName, value, opportunityData)
          }
        })
      } else {
        updatePropertyValues(fieldName, updatePropertyName, value, opportunityData)
      }
    }
  
  const updatePropertyValues = async (fieldName, updatePropertyName, value, opportunityData) => {
    const inputs = {
      id: opportunityData?.opportunityId,
      [updatePropertyName]: updatePropertyName === "assignedTo" ? value?.key ?? '' : value
    }
    trackEvent(fieldName === "assignedTo" 
      ? USER_EVENTS.OPPORTUNITIES.events.CHANGE_OPPORTUNITY_ASSIGNEE 
      : fieldName === "settings.nextStep" 
      ? USER_EVENTS.OPPORTUNITIES.events.CHANGE_OPPORTUNITY_NEXT_STEP 
      : fieldName === "status" 
      ? USER_EVENTS.OPPORTUNITIES.events.CHANGE_OPPORTUNITY_STATUS 
      : null, {
        "opportunity id": opportunityData?.opportunityId,
        "assigned to": fieldName === "assignedTo" ? value.key: null,
        "next step": fieldName === "settings.nextStep" ? value : null,
        "status": fieldName === "status" ? value : null,
        "organization id": opportunity.organization.organizationId,
        "building id": opportunity.location.locationId,
      })
    await upsertOpportunityMutation({ input: inputs }).then((res) => {
      setShowInlineSpinner({
        ...showInlineSpinner,
        [updatePropertyName]: false
      })
      if (res?.error?.errors?.length > 0) return
      _set(opportunityData, fieldName, value)
    })
  } 
//TODO commented below lines because we shouldn't use permissions this way.
  const showConfig = true //permissions?.isPermittedAction(
    //PAGE_NAMES.OPPORTUNITIES,
    //'config'
  //)
  const costSavings = opportunity && opportunity.settings.costSavings
  const costOfWaiting = opportunity && costOfWaitingValue(costSavings)
  const showEnergyList =
    costSavings && displayEnergyList(costSavings, costOfWaiting)
  const hasImages = opportunity && isAnyImageAdded(opportunity.settings.images)
  const description = opportunity?.settings?.description
  const opportunityId = opportunity?.opportunityId ?? null

  const inlineHelpText = [
    {
      heading: optionsTranslated.opp,
      body: optionsTranslated.oppBody
    }
  ]


  const pageTitle = (
    <>
      <InlineHelp inlineHelpText={inlineHelpText} />
      {showConfig && (
        <span
          className={`icon-visible ${
            opportunity.isPublic ? 'green' : ''
          } tooltip-container`}
        >
          <div className="tooltip center-top">
            {opportunity.isPublic
              ? optionsTranslated.visible
              : optionsTranslated.notVisible}
          </div>
        </span>
      )}
    </>
  )

  const countOfCostSavingsBlocks = () => {
    const costSavingsFields = [
      'savings',
      'costToImplement',
      'paybackCalculation',
      'roiCalculation'
    ]
    let number = 0
    for (let i = 0; i < costSavingsFields.length; i++) {
      if (displayCostSavings(costSavings[costSavingsFields[i]])) {
        number++
      }
    }
    if (costOfWaiting && costOfWaiting.value) {
      number++
    }
    return number
  }

  const columnsForCostSavings = (number) => {
    switch (number) {
      case 4:
        return {
          xlColumn: 3,
          lColumn: 4,
          mColumn: 6,
          sColumn: 12
        }
      case 3:
        return {
          xlColumn: 4,
          lColumn: 4,
          mColumn: 6,
          sColumn: 12
        }
      case 2:
        return {
          xlColumn: 6,
          lColumn: 6,
          mColumn: 6,
          sColumn: 12
        }
      case 1:
        return {
          xlColumn: 12,
          lColumn: 12,
          mColumn: 12,
          sColumn: 12
        }
    }
  }

  useEffect(() => {
    setPdfOpportunity(opportunity)
  }, [opportunity])

  const handleDownloadImage = (image) => {
    setPdfOpportunity(null)
    setTimeout(() => {
      const pdfOpportunityDetail = pdfOpportunity
      pdfOpportunityDetail.settings.images = pdfOpportunityDetail?.settings?.images?.map((i) =>
        i?.name === image?.name
          ? { ...i, original: image?.original }
          : i
      )
      setPdfOpportunity(pdfOpportunityDetail)
    }, 10)
  }
  
  const costSavingsProps = columnsForCostSavings(countOfCostSavingsBlocks())

  const isTextBlockVisible = () =>
    !_isEmpty(description) && description.blocks?.some(({ text }) => text)

  const hideModalDialog = () => {
    setIsShowMessage(false)
  }
  const modalConfig = useMemo(
    () => ({
      heading:t(
        `opportunities:${action === ACTIONS.EDIT ? 'EditOpportunity' : [ACTIONS.ADD, ACTIONS.COPY].includes(action) ? 'CreateOpportunity' : ''
        }`
      ),
      buttons:[{ text: 'Close', handleClick: hideModalDialog, type: 'cancel' }],
      handleClose: hideModalDialog
    }),
    [action]
  )

  return (
    <>
      <OpportunityViewWrapper>
        <div className="detail-view-page">
          <Container
            sColumns={12}
            mColumns={12}
            lColumns={12}
            xlColumns={12}
            width={width}
            maxWidth={`${MAX_PAGE_WIDTH}px`}
          >
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              border="none"
              width={width}
            >
              <Header
                hideToolBar={false}
                titleTools={pageTitle}
                pageTitle={opportunity.name}
              >
                <Toolbar>
                <AccessControl id="tc.pages.opportunities.add">
                  <button
                    className="action small"
                    onClick={() =>{
                      const searchParams = getUrlSearchParamValue(
                        opportunity.location.locationId,
                        opportunity.organization.organizationId
                      )
                      navigate(`/${PAGE_NAMES.OPPORTUNITIES}/add${searchParams ? `?${searchParams}` : ''}`, {
                        state: { mode: ACTIONS.ADD, id: null }
                      })
                    }
                    }
                  >
                    {optionsTranslated.createNew}
                  </button>
                </AccessControl>
                  {displayPDFDownload()}
                <AccessControl id="tc.pages.opportunities.add">
                  <span
                    className={classNames(
                      'icon',
                      'icon-duplicate',
                      'tooltip-container',
                    )}
                    onClick={() => {
                      navigate(
                        `/${PAGE_NAMES.OPPORTUNITIES}/${opportunityId}`,
                        { state: { mode: ACTIONS.COPY, id: opportunityId } }
                      )
                    }}
                  >
                    <div className="tooltip dark center-top">
                      {optionsTranslated.copyOpp}
                    </div>
                  </span>
                </AccessControl>
                <AccessControl id="tc.pages.opportunities.edit">
                  <span
                    className={classNames(
                      'icon',
                      'icon-edit',
                      'tooltip-container',
                    )}
                    onClick={() => {
                      navigate(
                        `/${PAGE_NAMES.OPPORTUNITIES}/${opportunityId}`,
                        { state: { mode: ACTIONS.EDIT, id: opportunityId } }
                      )
                    }}
                  >
                    <div className="tooltip dark center-top">
                      {optionsTranslated.editOpp}
                    </div>
                  </span>
                </AccessControl>
                <AccessControl id="tc.pages.opportunities.delete">
                  <span
                    className={classNames(
                      'icon',
                      'icon-trash2',
                      'tooltip-container',
                    )}
                    onClick={() => deleteOpportunity()}
                  >
                    <div className="tooltip dark left-top">
                      {optionsTranslated.deleteOpp}
                    </div>
                  </span>
                </AccessControl>
                </Toolbar>
              </Header>
            </Content>
            <Content
              xlColumn={8}
              lColumn={7}
              mColumn={7}
              sColumn={12}
              width={width}
              border="none"
            >
              <TargetPathView
                organizationName={opportunity?.organization.organizationName?opportunity.organization.organizationName:buildingDetails&&buildingDetails.accountName}
                locationName={
                 buildingDetails&&buildingDetails.name
                }
                tisObjects={opportunity.tisObjects}
              />
            </Content>
            <Content
              xlColumn={4}
              lColumn={5}
              mColumn={5}
              sColumn={12}
              width={width}
              border="none"
              className="created-info"
            >
              {opportunity.settings.proposedBy && (
                <LabelBlank
                  className="created-info-item"
                  labelText={`${optionsTranslated.createdBy}:`}
                  value={opportunity.settings.proposedBy}
                />
              )}
              {opportunity.settings.proposalDate && (
                <LabelBlank
                  className="created-info-item"
                  labelText={`${optionsTranslated.createdDate}:`}
                  value={opportunity.settings.proposalDate}
                />
              )}
            </Content>
            {showEnergyList && (
              <>
                {displayCostSavings(costSavings.savings) && (
                  <Content
                    border="none"
                    width={width}
                    {...costSavingsProps}
                    className="energy-block-container"
                  >
                    <CostSaving
                      label={optionsTranslated.energySavings}
                      value={formatCurrency(costSavings.savings.value)}
                      period={formatSavingsView(costSavings.savings.tag, t)}
                      mode={ACTIONS.VIEW}
                    />
                  </Content>
                )}
                {displayCostSavings(costSavings.costToImplement) && (
                  <Content
                    {...costSavingsProps}
                    border="none"
                    width={width}
                    className="energy-block-container"
                  >
                    <CostSaving
                      label={optionsTranslated.price}
                      value={formatCurrency(costSavings.costToImplement.value)}
                      period={formatPrice(
                        costSavings.costToImplement.priceType,
                        t
                      )}
                      mode={ACTIONS.VIEW}
                    />
                  </Content>
                )}
                {displayCostSavings(costSavings.paybackCalculation) && (
                  <Content
                    {...costSavingsProps}
                    border="none"
                    width={width}
                    className="energy-block-container"
                  >
                    <CostSaving
                      label={t('opportunities:SimplePayback')}
                      value={`${round(
                        costSavings.paybackCalculation.value,
                        2
                      ).toLocaleString()} ${t('common:yrs')}`}
                      period={formatPrice(
                        costSavings.costToImplement.priceType,
                        t
                      )}
                      mode={ACTIONS.VIEW}
                    />
                  </Content>
                )}
                {displayCostSavings(costSavings.roiCalculation) && (
                  <Content
                    {...costSavingsProps}
                    border="none"
                    width={width}
                    className="energy-block-container"
                  >
                    <CostSaving
                      label={optionsTranslated.roi}
                      value={`${round(
                        costSavings.roiCalculation.value,
                        2
                      ).toLocaleString()} %`}
                      period={formatPrice(
                        costSavings.costToImplement.priceType,
                        t
                      )}
                      mode={ACTIONS.VIEW}
                    />
                  </Content>
                )}
                {costOfWaiting && costOfWaiting.value && (
                  <Content
                    {...costSavingsProps}
                    border="none"
                    width={width}
                    className="energy-block-container"
                  >
                    <CostSavingsWrapper>
                      <div className="energy-block view">
                        <label className='energy-label'>
                          {t('opportunities:IfImplementedOn', {
                            date: costOfWaiting.date
                          })}
                        </label>
                        <h4 className="period">
                          {<TranslateComponent>Potential Savings By Now</TranslateComponent>}
                        </h4>
                        <div className="value">
                          {formatCurrency(round(costOfWaiting.value, 0))}
                        </div>
                      </div>
                    </CostSavingsWrapper>
                  </Content>
                )}
              </>
            )}
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              width={width}
              border="none"
            >
              <KPI kpi={opportunity.settings.kpi} containerWidth={width} />
            </Content>
            {isTextBlockVisible() && (
              <Content
                className="description"
                xlColumn={6}
                lColumn={6}
                mColumn={12}
                sColumn={12}
                width={width}
                background={COLORS.WHITE}
              >
                <TextEditor content={description} isViewMode={true} />
              </Content>
            )}
            {opportunity.findings.length ||
            (opportunity.settings.images && hasImages) ? (
              <Content
                className="images"
                xlColumn={6}
                lColumn={6}
                mColumn={12}
                sColumn={12}
                width={width}
                border="none"
              >
                <ImagesFindingsSection
                  findings={opportunity.findings}
                  images={
                    opportunity.settings.images && hasImages
                      ? opportunity.settings.images
                      : []
                  }
                  isLazyLoading = {true}
                  handleDownloadImage = {handleDownloadImage}
                />
              </Content>
            ) : null}
            <Content
              className="detail-view-page-footer"
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              width={width}
            >
              <Container
                xlColumns={12}
                lColumns={12}
                mColumns={12}
                sColumns={12}
                width={width}
                className="more-opportunity-info"
              >
                <Content
                  xlColumn={3}
                  lColumn={4}
                  mColumn={12}
                  sColumn={12}
                  width={width}
                  border="none"
                >
                  {opportunity.settings.priority && (
                    <Priority priority={opportunity.settings.priority} />
                  )}
                </Content>
                <Content
                  xlColumn={5}
                  lColumn={4}
                  mColumn={6}
                  sColumn={12}
                  width={width}
                  border="none"
                >
                  {opportunity.settings.file &&
                    opportunity.settings.file.id && (
                      <File
                        fileId={
                          opportunity.settings.file &&
                          opportunity.settings.file.id
                        }
                        fileFullName={opportunity.settings.file.displayName}
                        description={opportunity.settings.file.description}
                        name={opportunity.settings.file.name}
                        buildingId={opportunity.settings.file.buildingId}
                        label="Supporting Document:"
                      />
                    )}
                </Content>
                <Content
                  xlColumn={4}
                  lColumn={4}
                  mColumn={6}
                  sColumn={12}
                  width={width}
                  border="none"
                  className="footer-info-block"
                >
                  {opportunity.status && (
                    <div className="footer-info-item">
                      <label>{optionsTranslated.status}:</label>
                      <div className="opportunity-row">
                        {showInlineSpinner.status ? (
                          <Spinner />
                        ) : (
                          <EditableField
                            fieldName="status"
                            fieldKey={opportunity.status}
                            value={formatStatus(opportunity.status)}
                            label={`${optionsTranslated.status}:`}
                            options={statusOptionList(t)}
                            onChange={editableViewChangeHandler(
                              'status',
                              opportunity
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {opportunity.settings.nextStep && (
                    <div className="footer-info-item">
                      <label>{optionsTranslated.nextStep}:</label>
                      <div className="opportunity-row">
                        {showInlineSpinner.nextStep ? (
                          <Spinner />
                        ) : (
                          <EditableField
                            fieldName="settings.nextStep"
                            fieldKey={opportunity.settings.nextStep}
                            value={formatNextStep(
                              opportunity.settings.nextStep
                            )}
                            label={`${optionsTranslated.nextStep}:`}
                            options={nextStepOptionList(t)}
                            onChange={editableViewChangeHandler(
                              'settings.nextStep',
                              opportunity
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <div className="footer-info-item">
                    {showInlineSpinner.assignedTo ? (
                      <Spinner />
                    ) :
                      <AssignedTo
                        defaultValue={opportunity.assignedTo.key || ''}
                        readOnlyValue={opportunity.assignedTo?.key}
                        onChange={editableViewChangeHandler(
                          'assignedTo',
                          opportunity
                        )}
                        locationId={
                          (opportunity.location &&
                            opportunity.location.locationId) ||
                          opportunity.locationId
                        }
                        isToggleMode={true}
                        page="opportunity-view"
                      />
                    }
                  </div>
                </Content>
              </Container>
            </Content>
            <Content
              xlColumn={12}
              lColumn={12}
              mColumn={12}
              sColumn={12}
              width={width}
              border="none"
              className="detail-view-page-pdf"
            >
              {displayPDFDownload()}
            </Content>
            <StandardDialogs error={error} confirm={confirm} onCloseDialog={onCloseDialog} />
          </Container>
        </div>
      </OpportunityViewWrapper>
      {isShowMessage && <Modal {...modalConfig}>
        <ConfirmationText>
          {t(
            `opportunities:${action === ACTIONS.EDIT ? 'EditedSuccessfully' : [ACTIONS.ADD,ACTIONS.COPY].includes(action) ? 'CreatedSuccessfully' : ''
            }`,
            { opportunityName: opportunity.name }
          )}
        </ConfirmationText>
      </Modal>
      }
    </>
  )
}

// Props types
OpportunityDetailView.propTypes = {
  opportunity: PropTypes.object
}

export default OpportunityDetailView
