import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Toolbar from 'src/components/legacy/components/toolbar/toolbar'
import styled from 'styled-components'
import { useBreakPoint } from 'src/hooks/breakPoint'
import { ACTIONS } from 'src/constants'
import { getUrlSearchParamValue } from 'src/components/legacy/common/helpers'
import PDFGenerator from "src/components/print-components/pdf-generator"
import PdfFindingList from "src/pages/issuesFindings/pdf/pdf-finding-list"
import { PDF_PAGE_TYPES } from "src/components/legacy/common/pdf"
import translate from 'src/common/translations'
import { AccessControl } from 'src/components/accessControl'
import { getSearchParams } from "src/common/helperFunctions.js"
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

const ToolbarWrapper = styled.section<{ width: number }>`
  ${(props) => props.width < 599 && `margin-top: -60px;`}
`
function FindingsListButtons(props) {
  const [t] = useTranslation()
  const { width } = useBreakPoint()
  const {buildingId: locationId, organizationId} = getSearchParams()
  const { loading, findings, locationName, allOrganizations, organizationName, activeSalesOffice } = props
  const name = useMemo(() => {
		if (allOrganizations) {
			return `${t("common:All")} ${t("opportunities:Findings")}`
		} else if (locationName) {
			return locationName
		} else if (activeSalesOffice) {
			return `${activeSalesOffice?.officeCode}, ${activeSalesOffice?.officeName}, ${activeSalesOffice?.district}`
		} else {
			return organizationName
		}
	}, [allOrganizations, locationName, activeSalesOffice, organizationName])

  const optionsTranslated = {
    createNew: translate('Create New Finding')
  }

  const runAmplitudeLogic = () => {
    trackEvent(USER_EVENTS.ISSUES_AND_FINDINGS.events.DOWNLOAD_FINDING_LIST,{"format":"pdf"})
  }

  return (
    <ToolbarWrapper width={width}>
      <Toolbar>
        <AccessControl id="tc.pages.findings.add">
          <Link
            to={{
              pathname: '/findings/add',
              search: getUrlSearchParamValue(locationId, organizationId)
            }}
            state={{ mode: ACTIONS.ADD }}
          >
            <button className="action small" data-testid="create-new-button">
              {optionsTranslated.createNew}
            </button>
          </Link>
        </AccessControl>
        
        {!loading &&
          <PDFGenerator
            fileName={`${t("opportunities:Findings")}-${name}`}
            pageType={PDF_PAGE_TYPES.CUSTOM_PAGE}
            disabled={findings?.length === 0}
            onTrigger = {() =>
              runAmplitudeLogic()
            }
          >
            <PdfFindingList
              columns={findings ?? []}
              sortedBy={false}
              filteredBy={false}
              showNotes={false}
              isFooterCount={true}
              headerLocation={locationName??''}
              headerOrganization={organizationName??''}
            />
          </PDFGenerator>}
      </Toolbar>
    </ToolbarWrapper>
  )
}

export default React.memo(FindingsListButtons)
