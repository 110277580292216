export const LIST_GROUPS = /* GraphQL */ `
  query LIST_GROUPS {
    listGroups {
      items {
        accessType
        createdAt
        description
        isActive
        name
        updatedAt
        resourceType
      }
    }
  }
`

export const CREATE_GROUP = /* GraphQL */ `
  mutation CREATE_GROUP($input: CreateGroupInput!) {
    createGroup(input: $input) {
      name
    }
  }
`

export const UPDATE_GROUP = /* GraphQL */ `
  mutation UPDATE_GROUP($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      name
    }
  }
`

export const DELETE_GROUP = /* GraphQL */ `
  mutation DELETE_GROUP($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      name
    }
  }
`

export const UPDATE_BUILDING_func = inputArray => `
    mutation CREATE_CUSTOM_USER_ACCESS {
    ${inputArray.map((e, i) => `mutation${i}: updateBuilding(input: {id: "${e.id}", groupAdmin: ${JSON.stringify(e.groupAdmin)}, groupUser: ${JSON.stringify(e.groupUser)}}) {
      id
    }`)} 
    }
`


