import Table from '../../components/Table/clientSideTable'
import Content from 'src/components/Content'
import { DELETE_ROLE, DELETE_ROLE_PERMISSION, LIST_ROLES_BY_APP_ID } from './queries'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { NewStyles } from 'src/NewStyles'
import translate from '../../common/translations'
import { pages } from 'src/pages/pages.js'
import { useNavigate } from 'react-router'
import { ACTIONS } from 'src/constants'
import { useState } from 'react'
import Modal from 'src/components/legacy/components/modal/modal'
import { ConfirmationText } from 'src/components/layouts'


export const ApplicationRoles = ({appId}) => {
  const navigate = useNavigate()
  const [deleteState, setDeleteState] = useState(null)

  const optionsTranslated = {
    name: translate('Name'),
    actions: translate('Actions')
  }

  const {data: applicationRoles, responseTime, refetch} = useQuery({
    query: LIST_ROLES_BY_APP_ID,
    variables: {
      id: appId
    },
    errorPolicy: 'all',
    dataPath: 'data.listRolesByAppId.items'
  })

  const { onSubmit: deleteRolePermissionMutation } = useMutation({
    query: DELETE_ROLE_PERMISSION
  })

  const { onSubmit: deleteRoleMutation } = useMutation({
    query: DELETE_ROLE,
    onSuccess: (res) => {
      res.data.deleteRole.permissions.items.map((e) => {
        deleteRolePermissionMutation({input: {id: e.id}})
      })
      refetch({ id: appId })
      setDeleteState(null)
    }
  })

  const nameHeadings = [
    {
      name: 'name',
      title: optionsTranslated.name,
      key: 'name',
      maxWidth: '120px'
    },
    {
      name: 'actions',
      title: optionsTranslated.actions,
      key: 'actions',
      disabled: true,
      alignRight: true
    }
  ]

  const deleteModalConfig = {
    heading: 'Delete Role',
    buttons: [
      {
        text: 'Yes',
        handleClick: () => {
          deleteRoleMutation({input: {id: deleteState.id}})
        }
      },
      { text: 'No', 
      handleClick: () => setDeleteState(null), 
      type: 'cancel' }
    ],
    handleClose: () => setDeleteState(null)
  }


  return (
    <NewStyles>
        {deleteState ? <Modal {...deleteModalConfig}>
          <ConfirmationText>
            Are you sure you want to delete {deleteState?.['name']}?
          </ConfirmationText>
        </Modal> : ""}
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          
          <Table
            key={`applicationRolesTable-${appId}`}
            rows={applicationRoles || []}
            header={nameHeadings}
            loadTime={responseTime}
            search={true}
            searchFields={['name']}
            rowControl={[
              { text: 'Edit', action: (data) => navigate(
              pages['Role Management/:id'].href.replace(":id", data.id),
              {state:{mode:ACTIONS.EDIT}}
            ) },
            { text: 'Delete', action: (data) => setDeleteState(data) }]}
          />
        </Content>
    </NewStyles>
  )
}
