/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo } from 'react'
import { useChartContext } from '../../../../../chart-library/CommonComponents/BaseWrapper'
import { AxisDiM } from '../../../../../chart-library/Utils/defaults'
import { getMinValue } from '../../../../../chart-library/CommonComponents/Bar/helper';
import BarChart from 'src/chart-library/CommonComponents/Bar';
import PlotLine from 'src/chart-library/CommonComponents/PlotLine/PlotLine';
import Grid from 'src/chart-library/CommonComponents/Grids/Grid';


export default function Series(props: any) {
  const chart = useChartContext()
  const enableTooltip = true
  const grid = chart?.grid
  const axis = chart?.axis
  const scale = chart?.scale
  const series = props?.series?.barData ?? []
  const leftMeasurement = props?.series?.barData?.[0]?.measurement ?? null
  const rightMeasurement = props?.series?.barData?.[1]?.measurement ?? null
  const plotLineConfigData = props?.options?.plotLineConfig ?? null
  const leftPlot = plotLineConfigData.length > 0 ? plotLineConfigData[0] : null
  const rightPlot = plotLineConfigData.length > 1 ? plotLineConfigData[1] : null
  const plotLineAxisType = props?.options?.plotAxisType ?? ""
  // API flow - 2
  // Once Data is recevied from API. set x axis min & max value to render ticks based on API reponse
  // or you can use min & max value config in useOptions to set x axis values
  useEffect(() => {
    const minValue = getMinValue(series)
    if (series?.length) {
      scale.setDomain(AxisDiM?.y, 0, [minValue > 0 ? 0 : minValue, Math.max(...series?.map(({ value }) => value))], true)
    }
  }, [series])

  const canvas = useMemo(() => grid?.getGrid(0), [grid?.lastUpdatedAt])!

  const xScale = useMemo(
    () => {
      const x = scale?.getScale(AxisDiM?.x, 0)
      x?.fn?.padding(0.5)
      return x
    },
    [scale?.lastUpdatedAt]
  )!

  const yScale = useMemo(
    () => scale?.getScale(AxisDiM?.y, 0)
    ,
    [scale?.lastUpdatedAt]
  )!

  if (!grid?.lastUpdatedAt || !scale?.lastUpdatedAt || !axis?.lastUpdatedAt) {
    return null
  }
  const getLineFromToValue = (plot,index) =>{
    const xWidth = canvas.width / (series?.length)
          const from: any = { x: 0, y: 0 };
          const to: any = { x: 0, y: 0 };
          from.x = xWidth * index;
          from.y = yScale.fn(plot?.value);
          to.y = yScale.fn(plot?.value);
          to.x = xWidth * (index + 1);

          return {from,to}
  }
  const leftFromTo = getLineFromToValue(leftPlot,0)
  const rightFromTo = getLineFromToValue(rightPlot,1)
  return (
    <>
      {leftMeasurement && <text x1={"100%"} y={"98%"} x={"5%"}>{`(${leftMeasurement})`}</text>}      
      <g transform={`translate(${canvas?.x} ${canvas?.y})`}>
        <Grid
          scale={yScale?.fn}
          width={canvas?.width}
          height={canvas?.height}
          includeAxis={true}
          opacity={0.3}
          type={'y'}
          tickCount={3}
        />
        <BarChart barData={series} xScale={xScale} yScale={yScale} enableToolTip={enableTooltip} setTooltip={props?.setTooltip} />
        {
          leftPlot && <>
            <PlotLine
              axisType={plotLineAxisType}
              width={canvas.width}
              height={canvas.height}
              plotObj={leftPlot}
              defaultFrom={leftFromTo?.from}
              defaultTo={leftFromTo?.to}
              onMouseEnter={(event) => {
                if (!enableTooltip) return
                props?.setTooltip({
                  x: event?.clientX,
                  y: event?.clientY,
                  index: 1,
                  item: { name: leftPlot?.name, value: leftPlot?.value, color: leftPlot?.stroke, measurement: leftPlot?.measurement }
                })
              }}
              onMouseLeave={() => {
                if (!enableTooltip) return
                props?.setTooltip(null)
              }}
            />
          </>            
        }
        {
          rightPlot && <>
            <PlotLine
              axisType={plotLineAxisType}
              width={canvas.width}
              height={canvas.height}
              plotObj={rightPlot}
              defaultFrom={rightFromTo?.from}
              defaultTo={rightFromTo?.to}
              onMouseEnter={(event) => {
                if (!enableTooltip) return
                props?.setTooltip({
                  x: event?.clientX,
                  y: event?.clientY,
                  index: 1,
                  item: { name: rightPlot?.name, value: rightPlot?.value, color: rightPlot?.stroke, measurement: rightPlot?.measurement }
                })
              }}
              onMouseLeave={() => {
                if (!enableTooltip) return
                props?.setTooltip(null)
              }}
            />
          </>
        }
      </g>
      {rightMeasurement && <text x1={"100%"} y={"98%"} x={"90%"}>{`(${rightMeasurement})`}</text>}      
    </>
  )
}

