export default class LocationDetails {
  constructor(options = {}) {
    Object.assign(
      this,
      {
        address: {
          addressTypeName: 'Location',
          line1: '',
          postalCode: '',
          city: '',
          region: '',
          regionFullName: '',
          country: '',
          countryCode: '',
          countryDescription: ''
        },
        locationTypeName: 'Facility',
        locationConnectivity: {
          devices: []
        },
        salesOffice: {},
        offeringSourceMaps: [],
        organizationId: '',
        locationName: '',
        locationTags: [],
        locationLatitude: '',
        locationLongitude: '',
        locationTimeZone: '',
        floorAreaUnits: '',
        floorArea: '',
        activeStatusState: 'Active'
      },
      options
    )
  }
}
