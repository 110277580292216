import {
  selectAxes,
  selectCanvasDetails,
  selectGeneratedScales,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
import PlotLine from 'src/chart-library/CommonComponents/PlotLine/PlotLine'
import {
  SCALE_LINEAR,
  SCALE_TIME
} from 'src/chart-library/Utils/Scales/constant'
import { Fragment, useMemo } from 'react'
import { getTextDimensions } from 'src/chart-library/BaseSetup/PaddingSetup/helper'
import { calculateTextPositions } from 'src/chart-library/CommonComponents/PlotLine/helper'

const PlotLineRenderer = (props: any) => {
  const { types = [], canvasIndex } = props
  const axes = useSelector(selectAxes)
  const generatedScales = useSelector(selectGeneratedScales)
  const canvasDetails: any = useSelector(selectCanvasDetails)

  const { paddingWidth, paddingHeight } = useMemo(() => {
    const canvasDim = canvasDetails?.[canvasIndex]
    return {
      paddingHeight: canvasDim?.height,
      paddingWidth: canvasDim?.width
    }
  }, [canvasDetails, canvasIndex])

  const typesWithPositions = useMemo(() => {
    const plots = []
    types?.forEach((plotObj) => {
      const { width: textWidth, height: textHeight } = getTextDimensions(plotObj?.name, 14)
      const type = plotObj?.axisKey?.includes('x') ? 'vertical' : 'horizontal'
      const attributePositionKey = type === 'vertical' ? 'x' : 'y'
      const name = plotObj?.name;
      const gScaleObj = generatedScales?.[plotObj?.axisKey]
      const scale = gScaleObj?.gScale
      const value = scale && scale(plotObj?.value)
      const obj = {
        id: plotObj?.id,
        name,
        type,
        [attributePositionKey]: value,
        textWidth: textWidth > 100 ? 100 : textWidth,
        textHeight: textHeight > 20 ? 20 : textHeight
      }
      if (!isNaN(parseFloat(value))) {
        plots.push(obj)
      }
    })
    const plotsWithTextPosition = calculateTextPositions(plots, paddingWidth, paddingHeight)
    const typesWithPositions = types?.map((obj) => {
      const textPosition = plotsWithTextPosition?.find((tObj) => tObj?.id === obj?.id) || {}
      return {
        ...(obj || {}),
        textPosition
      }
    })
    return typesWithPositions
  }, [types, paddingWidth, paddingHeight, axes, generatedScales])

  return (
    <>
      {typesWithPositions?.map((plotObj: any = {}, i: number) => {
        const type = plotObj?.axisKey?.includes('x') ? 'x' : 'y'

        const axisObj = axes?.find(
          (axisObj: any) => axisObj?.key === plotObj?.axisKey
        )
        const axisObject = axisObj?.scale
        const gScaleObj = generatedScales?.[plotObj?.axisKey]
        const scaleType = axisObject?.props?.type
        const scale = gScaleObj?.gScale

        let renderThis = false
        if (scaleType === SCALE_LINEAR) {
          const domain = scale?.domain && scale?.domain()
          if (plotObj?.value >= domain?.[0] && plotObj?.value <= domain?.[1]) {
            renderThis = true
          }
        } else if (scaleType === SCALE_TIME) {
          const domain = scale?.domain && scale?.domain()
          if (
            new Date(plotObj?.value) >= new Date(domain?.[0]) &&
            new Date(plotObj?.value) <= new Date(domain?.[1])
          ) {
            renderThis = true
          }
        } else {
          renderThis = false
        }
        if (renderThis && plotObj?.show) {
          return (
            <PlotLine
              key={`${i}`}
              id={plotObj?.id}
              axisType={type}
              scale={scale}
              width={paddingWidth}
              height={paddingHeight}
              plotObj={plotObj}
            />
          )
        } else {
          return <Fragment key={`${i}`}></Fragment>
        }
      })}
      {typesWithPositions?.map((plotObj, index) => {
        return <use key={index} href={`#${plotObj?.id}`}></use>
      })}
    </>
  )
}

export default PlotLineRenderer