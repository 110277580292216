import { useState, useCallback, useEffect } from 'react'
import MultiTabForm from '../MultiTabTorm'
import { TabsWrapper } from '../../styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import {
  BaselineTargetObjectSchema,
  WidgetOverview,
  WidgetOverviewSchema
} from '../WidgetOverview'
import {
  BaseLineComparisonConfiguration,
  BaseLineComparisonConfigurationSchema
} from './BaseLineComparisonConfiguration'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { BaselineComparisonWidgetPreviewWrapper } from './BaselineComparisonWidgetPreviewWrapper'
import {
  BASELINE_DEFAULT_TIME_PERIOD,
  MODAL_TYPE,
  WIDGET_TABS_NAME,
  getBaseLineWidgetUpsertMutationInputs
} from '../../helpers'
import { ACTIONS } from 'src/constants/actions'
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../graphql'
import { useMutation } from 'src/hooks/APIHooks'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate from 'src/common/translations'

const tabsConfig = (errors) => [
  {
    title: 'Overview',
    disabled: false,
    key: 'Overview',
    formikErrors: errors,
    validationSchema: WidgetOverviewSchema,
    component: <WidgetOverview />
  },
  {
    title: 'Configuration',
    disabled: false,
    key: 'Configuration',
    formikErrors: errors,
    validationSchema: BaseLineComparisonConfigurationSchema,
    component: <BaseLineComparisonConfiguration />
  },
  {
    title: 'Preview',
    disabled: false,
    key: 'Preview',
    formikErrors: errors,
    component: <BaselineComparisonWidgetPreviewWrapper/>
  }
]

const BaseLineComparisonForm = ({ setWidgetFormik }) => {
  const {
    setOpenWidgetPicker,
    userInfo,
    setReloadEvents,
    setIsLoading,
    mode,
    widgetDetails,
    setWidgetDetails,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setModalType,
    formikErrors,
    setIsFormValidationAlone,
    isFormValidationAlone,
    setIsSubmitted,
    setWidgetBuilding,
    selectedBaseLine,
    setWidgetId,
    isFooterChecked
  } = useWidgetsContext()
  const [activeTab, setActiveTab] = useState(0)
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const onSelectTab = useCallback((selectedTab: any, selectedTitle: any) => {
    setActiveTab(selectedTab)
    if (selectedTitle === WIDGET_TABS_NAME.PREVIEW){
      setIsFormValidationAlone(true)
      setIsSubmitted(false)
    }
  }, [])
  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: [ACTIONS.ADD,ACTIONS.COPY].includes(mode) ? CREATE_WIDGET : UPDATE_WIDGET,
    refreshTokenBuildingVariable: "input.buildingId",
    onError: (error) => {
      setError(error)
      setIsLoading(false)
    },
    onSuccess: (success) => {
      if([ACTIONS.ADD].includes(mode))
        {
          trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Create", type: "Baseline Comparison"})
        }
      else if([ACTIONS.COPY].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Copy", type: "Baseline Comparison"})
      }
      else{
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Edit", type: "Baseline Comparison"})
      }
    }
  })

  const submitForm = async (values) => {
    if (!selectedBaseLine || isFormValidationAlone) return
    setIsLoading(true)
    const inputs = getBaseLineWidgetUpsertMutationInputs(
      values,
      userInfo,
      selectedBaseLine,
      mode
    )
    await upsertWidgetMutation({ input: inputs }).then((response) => {
      const widgetId =
        [ACTIONS.ADD,ACTIONS.COPY].includes(mode)
          ? response.data?.createWidget?.id
          : response.data?.updateWidget?.id
      if (widgetId) {
        setIsLoading(false)
        setWidgetDetails(inputs)
        setOpenWidgetPicker(false)
        setReloadEvents(true)
        setModalType(MODAL_TYPE.SUCCESS)
        setSelectedWidget(null)
        setIsOpenCreateWidget(isFooterChecked ?? false)
        setWidgetId(widgetId)
      }
    })
  }

  const copyof = translate("Copy of")
  useEffect(() => {
    switch (mode) {
      case ACTIONS.ADD:
        setFormValues({
          id: '',
          name: '',
          description: '',
          isNewWindow: true,
          timePeriod: BASELINE_DEFAULT_TIME_PERIOD,
          baselineId:""
        })
        break
      case ACTIONS.EDIT:
      case ACTIONS.COPY:
        setFormValues({
          id: mode === ACTIONS.EDIT ? widgetDetails?.id : '',
          name: mode === ACTIONS.EDIT ? widgetDetails?.name : `${copyof} ${widgetDetails?.name}`,
          description: widgetDetails?.description ?? '',
          isNewWindow: widgetDetails?.isNewWindow,
          timePeriod:widgetDetails?.baselineComparision?.timePeriod,
          baselineId:widgetDetails?.baselineId,
        })
        setWidgetBuilding({ id: widgetDetails?.baselineEquipment?.building?.id })
        break
    }
  }, [mode, copyof])

  const displayForm = () => {
    return mode !== ACTIONS.ADD ? formValues?.name : true
  }

  return (
    <>
      {displayForm() && (
        <MultiTabForm
          initialValues={formValues}
          onSubmit={submitForm}
          schemas={[
            WidgetOverviewSchema,
            BaselineTargetObjectSchema,
            BaseLineComparisonConfigurationSchema
          ]}
          setWidgetFormik={setWidgetFormik}
        >
          <TabsWrapper>
            <Tabs
              defaultActiveTab={activeTab}
              activeTab={activeTab}
              onSelect={onSelectTab}
            >
              {tabsConfig(formikErrors).map((item: any, key: number) => {
                return (
                  <Tab
                    title={item.title}
                    disabled={item.disabled}
                    key={key}
                    formikErrors={item?.formikErrors}
                    validationSchema={item?.validationSchema}
                  >
                    {item.component}
                  </Tab>
                )
              })}
            </Tabs>
          </TabsWrapper>
          <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
        </MultiTabForm>
      )}
    </>
  )
}

export default BaseLineComparisonForm
