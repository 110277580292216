export const EVENT_CLASSES = [
    "System Print",
    "System Log",
    "System Alarm",
    "System Critical Alarm",
    "Event Alarm Class 5",
    "Event Alarm Class 6",
    "Event Alarm Class 7",
    "Event Alarm Class 8",
    "Event Alarm Class 9",
    "Event Alarm Class 10",
    "Event Alarm Class 11",
    "Event Alarm Class 12",
    "Event Alarm Class 13",
    "Event Alarm Class 14",
    "Event Alarm Class 15",
    "Event Alarm Class 16",
    "Event Alarm Class 17",
    "Event Alarm Class 18",
    "Event Alarm Class 19",
    "Event Alarm Class 20"
]

export const CRITERIA_TYPE = {
    PRIORITY:"device_priority",
    CLASS: "device_class",
    CATEGORY: "device_category"
}