// Default Options.

import { getBarDataCategory, getMaxValue, getMinValue } from "../../../../../chart-library/CommonComponents/Bar/helper";

export function useOptions(barChartData): Options {
  const data = barChartData?.barData
  const minValue = getMinValue(data)
  return {
    xAxis: {
      type: "scaleBand",
      data: getBarDataCategory(data)
    },
    yAxis: {
      type: "scaleLinear",
      min: minValue > 0 ? 0 : minValue,
      max: getMaxValue(data),
    },
    grid: [{ left: "60px", right: "0px", top: "20px", bottom: "30px" }]
  };
}
