import { handleAHUStatus } from './AutomatedTests/status/ahu'
import { handleChillerStatus } from './AutomatedTests/status/chiller'
import { handleOtherStatus } from './AutomatedTests/status/others'

export const CHILLER_TIS_OBJECT_TYPE_GROUP_NAME = 'Chiller'
export const CIRCUIT_TIS_OBJECT_TYPE_GROUP_NAME = 'Circuit'
export const COMPRESSOR_TIS_OBJECT_TYPE_GROUP_NAME = 'Compressor'
export const EQUIPMENT_NAME_MAX_LENGTH = 75

export const AT_STATUS_ENABLED_EQUIPMENTS = {
  Chiller: handleChillerStatus,
  AHU: handleAHUStatus,
  // For other equipments, need to display at counts
  Others: handleOtherStatus
}
