import { useMemo } from "react"
import { Text, View, Image } from "@react-pdf/renderer"
import PropTypes from "prop-types"

import { headerStyles } from "./pdf-header.styles"
import i18next from "i18next"

const MAX_LOCATION_NAMES = 3

const PDFHeader = ({ isFixed, locationNames, organizationName, itemName = null }) => {
	const { headerWrapper, logo, header, locationNameText, intelligentServices, info, name } = headerStyles
	const normalizedLocationNames = useMemo(
		() => {
			const leftoverLocationNames = locationNames.length - MAX_LOCATION_NAMES
			return leftoverLocationNames > 0
				? `${locationNames.slice(0, MAX_LOCATION_NAMES).join(", ")} + ${leftoverLocationNames} ${i18next.t("common:More").toLowerCase()}...`
				: locationNames.join(", ")
		}, [locationNames]
	)

	return	(
		<View style={headerWrapper} fixed={isFixed}>
			<View style={header}>
			<Image style={logo} src={require('../../../assets/images/TraneComm_horiz_black_rgb_lg.png')} />
				<View style={info} render={({ pageNumber }) => (
					<>
						<Text style={intelligentServices}>{organizationName || i18next.t("opportunities:IntelligentServices")}</Text>
						<Text style={locationNameText}>{normalizedLocationNames}</Text>
						{pageNumber !== 1 && itemName && <Text style={name}>{itemName}</Text>}
					</>
				)} />
			</View>
		</View>
	)
}

PDFHeader.propTypes = {
	isFixed: PropTypes.bool,
	locationNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

PDFHeader.defaultProps = {
	isFixed: false,
}

export default PDFHeader
