import { TargetKeyType } from './constants'

export type PropertyMappingDetails = {
  targetkeyType: TargetKeyType
  isConstantMapped: boolean
  isNoPropertySelectedMapped: boolean
  isSourceKeyMapped: boolean
  isCalculatedValue: boolean
  value: any
}

export type CharacteristicsMappingDetials = {
  targetkeyType: TargetKeyType
  isConstantMapped: boolean
  isValueMapped: boolean
  value: any
}

class CheckPropertyAndCharacteristicsValueMapping {
  protected getPropertyDataMappingInfo(property): PropertyMappingDetails {
    let isConstantMapped = false
    let isSourceKeyMapped = false
    let isNoPropertySelectedMapped = false

    const { sourceDisplayName, sourceKey, value, propertyType } = property || {}

    // For calculated properties, value is set to `-` to empty it
    const valueMapped =
      value === undefined || value === null || value === '' || value === '-'
        ? ''
        : value

    // For calculated properties, when mapping `Application Calculated` as a value for a property, sourceKey will be empty.
    // if Application Calculated is mapped, assigning some static value to mark sourceKey mapped
    const sourceKeyMapped =
      sourceKey === '' || sourceKey === undefined || sourceKey === null
        ? 'Application Calculated' === sourceDisplayName
          ? 'Application Calculated is mapped'
          : ''
        : sourceKey

    const noPropertySelectedMapped = sourceDisplayName ?? ''

    // is Constant
    if (valueMapped) {
      isConstantMapped = !!String(valueMapped).length
    }
    // is source key selected
    else if (sourceKeyMapped) {
      isSourceKeyMapped = !!String(sourceKeyMapped).length
    }
    // is no property selected
    else if (noPropertySelectedMapped) {
      const lowerCaseSourceDisplayName = String(noPropertySelectedMapped)
        .trim()
        .toLowerCase()

      isNoPropertySelectedMapped =
        lowerCaseSourceDisplayName.includes('no property selected') &&
        !sourceKeyMapped &&
        !valueMapped
    }

    return {
      targetkeyType: TargetKeyType.PROPERTIES,
      isConstantMapped,
      isNoPropertySelectedMapped,
      isSourceKeyMapped,
      isCalculatedValue: propertyType === 'Calculated',
      value
    }
  }

  protected getCharacteristicsDataMappingInfo(
    characteristics
  ): CharacteristicsMappingDetials {
    const { value } = characteristics

    const isValueMapped = value !== '' && value !== undefined && value !== null

    return {
      targetkeyType: TargetKeyType.CHARACTERISTICS,
      // By default, value of a characteristis is considered as constant
      // So isConstantMapped is true if any value is assigned to the characteristic
      isConstantMapped: isValueMapped,
      isValueMapped,
      value
    }
  }
}

export class EquipmentPropertyAndCharacteristics extends CheckPropertyAndCharacteristicsValueMapping {
  #activeEquipmentProperties = {}
  #associatedEquipmentsProperties = {}

  #activeEquipmentCharacteristics = {}
  #associatedEquipmentsCharacteristics = {}

  #listOfAssociatedEquipmentTypesForProperties = {}
  #listOfAssociatedEquipmentTypesForCharacteristics = {}

  setActiveEquipmentProperties(properties = []) {
    for (const property of properties) {
      const targetKey = property.targetKey
      if (!targetKey) continue

      const whatIsMapped = this.getPropertyDataMappingInfo(property)

      this.#activeEquipmentProperties[targetKey] = {
        ...property,
        valueMappingDetails: { ...whatIsMapped }
      }
    }
  }

  setAssociatedEquipmentProperties(equipmentType, properties = []) {
    for (const property of properties) {
      const targetKey = property.targetKey
      if (!targetKey || !equipmentType) continue

      if (!this.#associatedEquipmentsProperties[targetKey]) {
        this.#associatedEquipmentsProperties[targetKey] = {
          [equipmentType]: []
        }
      }

      if (!this.#associatedEquipmentsProperties[targetKey][equipmentType]) {
        this.#associatedEquipmentsProperties[targetKey][equipmentType] = []
      }

      this.#listOfAssociatedEquipmentTypesForProperties[equipmentType] = true

      const whatIsMapped = this.getPropertyDataMappingInfo(property)

      this.#associatedEquipmentsProperties[targetKey][equipmentType].push({
        ...property,
        valueMappingDetails: { ...whatIsMapped }
      })
    }
  }

  setActiveEquipmentCharacteristics(characteristics = []) {
    for (const characteristic of characteristics) {
      const targetKey = characteristic.targetKey
      if (!targetKey) continue

      const whatIsMapped =
        this.getCharacteristicsDataMappingInfo(characteristic)

      this.#activeEquipmentCharacteristics[targetKey] = {
        ...characteristic,
        valueMappingDetails: { ...whatIsMapped }
      }
    }
  }

  setAssociatedEquipmentCharacteristics(equipmentType, characteristics = []) {
    for (const characteristic of characteristics) {
      const targetKey = characteristic.targetKey
      if (!targetKey || !equipmentType) continue

      if (!this.#associatedEquipmentsCharacteristics[targetKey]) {
        this.#associatedEquipmentsProperties[targetKey] = {
          [equipmentType]: []
        }
      }

      if (
        !this.#associatedEquipmentsCharacteristics[targetKey][equipmentType]
      ) {
        this.#associatedEquipmentsCharacteristics[targetKey][equipmentType] = []
      }

      this.#listOfAssociatedEquipmentTypesForCharacteristics[equipmentType] =
        true

      const whatIsMapped =
        this.getCharacteristicsDataMappingInfo(characteristic)

      this.#associatedEquipmentsProperties[targetKey][equipmentType].push({
        ...characteristic,
        valueMappingDetails: { ...whatIsMapped }
      })
    }
  }

  getEquipmentPropertyDetailByEquipmentType(equipemtTye, targetKey): any[] {
    if (equipemtTye === 'Active') {
      return this.#activeEquipmentProperties[targetKey]
        ? [this.#activeEquipmentProperties[targetKey]]
        : []
    }

    return this.#listOfAssociatedEquipmentTypesForProperties[equipemtTye]
      ? this.#associatedEquipmentsProperties[targetKey] ?? []
      : []
  }

  getActiveEquiomentAllProperties() {
    return Object.assign({}, this.#activeEquipmentProperties)
  }
  getActiveEquiomentAllCharacteristics() {
    return Object.assign({}, this.#activeEquipmentCharacteristics)
  }

  getEquipmentCharacteristicsDetailByEquipmentType(
    equipemtTye,
    targetKey
  ): any[] {
    if (equipemtTye === 'Active') {
      return this.#activeEquipmentCharacteristics[targetKey]
        ? [this.#activeEquipmentCharacteristics[targetKey]]
        : []
    }

    return this.#listOfAssociatedEquipmentTypesForCharacteristics[equipemtTye]
      ? this.#activeEquipmentCharacteristics[targetKey] ?? []
      : []
  }

  getActiveEquipmentPropertyDetailByTargetKey(targetKey: string): any[] {
    return this.getEquipmentPropertyDetailByEquipmentType('Active', targetKey)
  }

  getAssociatedEqipmentPropertyDetailByTargetKey(
    equipmentType: string,
    targetKey: string
  ): any[] {
    return this.getEquipmentPropertyDetailByEquipmentType(
      equipmentType,
      targetKey
    )
  }

  getActiveEquipmentCharacteristicsDetailByTargetKey(targetKey: string): any[] {
    return this.getEquipmentCharacteristicsDetailByEquipmentType(
      'Active',
      targetKey
    )
  }

  getAssociatedEqipmentCharacteristicsDetailByTargetKey(
    equipmentType: string,
    targetKey: string
  ): any[] {
    return this.getEquipmentCharacteristicsDetailByEquipmentType(
      equipmentType,
      targetKey
    )
  }
}
