import styles from "./building-selector.module.scss"
import { useSelector } from "react-redux"
import { selectBuildings, selectBuildingsCount, selectSearchText, setBuildings, setBuildingsCount, setSearchText } from "src/redux/slicers/buildingPicker"
import { useEffect, useState } from "react"
import {
  SEARCH_BUILDINGS,
  SearchBuildingsQueryVariables,
} from 'src/components/buildingNavigation/graphql'
import { useDispatch } from "react-redux"
import { useQuery } from "src/hooks/APIHooks"
import { accountMapping } from "src/components/buildingNavigation/helper"
import { OrganizationsPopupItem } from "./OrganizationsPopupItem"
import { Heading, Input } from "@aws-amplify/ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import translate, { TranslateComponent } from "src/common/translations"
import { faBuildings } from "../lib/pro-solid-svg-icons"
import { useDebounce } from "../helpers"



export const OrganizationsPopup = ({ setOrganizationPopupOpen, selectedOrganization, orgGetFloatingProps, orgRefs, orgFloatingStyles }) => {
  const limit = 1000;

  // Store data
  const { data: organizations } = useSelector(selectBuildings);
  const searchText = useSelector(selectSearchText);
  const isSearchResultMaximum = useSelector(selectBuildingsCount);
  const { refetch } = setUpQuery();
  const dispatch = useDispatch();
  const [previousSearch, setPreviousSearch] = useState(null);
  const updateSearchResults = useDebounce(() => {
    setPreviousSearch(searchText);
    searchWithOptionalSearchTerm(refetch, limit, searchText, previousSearch);
  });

  useEffect(() => {
    updateSearchResults();
  }, [searchText]);

  return (
    <div
      className={styles.popupWrapper}
      {...orgGetFloatingProps()}
      ref={orgRefs.setFloating}
      data-testid="buildingSelectorOrgPopup"
      style={orgFloatingStyles}>
      <div className={`${styles.popup} ${styles.organizations}`}>
        <Heading level={2} className={styles.popupHeadline}>
          <span  className={styles.popupHeadlineIcon}><FontAwesomeIcon icon={faBuildings} /></span>
          <TranslateComponent>Organizations</TranslateComponent>
        </Heading>
        <form className={styles.searchForm} onSubmit={(event) => { event.preventDefault(); }}>
          <Input type="text"
            value={searchText}
            onChange={(event) => { dispatch(setSearchText(event.target.value)) }}
            placeholder={translate('Search...')}
            aria-label={translate('Search...')}
            data-testid="orgDenaliInput"
          />
          <button type="submit" aria-label={translate('Submit')}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
        <div className={styles.scrollContainer}>
          <ul>
            {
              organizations
                .map(organization =>
                  <OrganizationsPopupItem
                    key={organization.id}
                    organization={organization}
                    setOrganizationPopupOpen={setOrganizationPopupOpen}
                    showBuildingCount={isSearchResultMaximum !== limit}
                    selectedOrganization={selectedOrganization}
                  />
                ) 
            }
          </ul>
        </div>
      </div>
    </div>
  )
}

function setUpQuery() {
  const dispatch = useDispatch();

  return useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    dataPath: 'data.searchBuildings.items',
    onSuccess: (data: any) => {
      try {
        const accountsData = accountMapping(data, true);
        const finalData = Object.values(accountsData);
        dispatch(setBuildingsCount({ buildingsCount: data.length }))
        dispatch(
          setBuildings({
            type: 'Success',
            data: finalData
          })
        );
      } catch (e) {
        console.error(e)
      }
    }
  });
}

function searchWithOptionalSearchTerm(refetch, limit, searchText, previousSearch) {
  // Based on handleBodyFilterChange in src/components/buildingNavigation/building-navigation.tsx
  if(searchText.length < 2) {
    searchText = '';
  }
  if(previousSearch === searchText) return;

  const variables:SearchBuildingsQueryVariables = { limit }
  if(searchText.trim() !== '') {
    variables.filter = {
      or: [
        { accountName: { matchPhrasePrefix: `*${searchText}*` } },
        { name: { matchPhrasePrefix: `*${searchText}*` } },
        { address: { matchPhrasePrefix: `*${searchText}*` } },
      ]
    }
  }
  refetch(variables);
}