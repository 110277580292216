import { isEqual } from '../../utils'

export const defaultFieldStates = [
  {
    prop: 'disabled',
    value: true,
    className: 'disabled'
  },
  {
    prop: 'error',
    value: true,
    className: 'error'
  },
  {
    prop: 'focused',
    value: true,
    className: 'focused'
  }
]

export default function formControlStateFn({ props, states = [] }) {
  return states.reduce((av, state) => {
    if (!state) return av

    if (state.prop && props[state.prop] != null) {
      const className = state.className ?? state.prop
      const value = isEqual(props[state.prop], state.value)
      av[className] = value
    }

    return av
  }, {})
}
