import { useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { formatChartData } from 'src/common/chartHelperFunctions'
import { useDispatch } from 'react-redux'
import { newRefreshToken } from 'src/redux/slicers/appData'

const GET_CHART_DATA = /* GraphQL */ `
  query getChartData($body: AWSJSON) {
    getChartData(body: $body)
  }
`

export const useGetChartData = () => {
  const [fetchingChartData, setFetchingChartData] = useState(false)

  const [chartData, setChartData] = useState(null)
  const [chartDataByTimeSlider, setChartDataByTimeSlider] = useState(null)
  const dispatch = useDispatch()

  const { refetch: getChartDataForBuilding, loading: loadingChartData } =
    useQuery({
      query: GET_CHART_DATA,
      dataPath: 'data.getChartData',
      disableInitialLoad: true
    })

  const getTimeStamps = (finalData) => {
    try {
      //values from non aggr data
      const values = finalData?.data?.[0]?.values?.[0] || []

      if (values?.length > 0) {
        return values?.map((x) => x?.timeStamp)
      } else {
        const aggrValuesObject = finalData?.aggregatedData || {}
        //values from aggr data
        for (const aggrFunctionName in aggrValuesObject) {
          const aggrValues =
            aggrValuesObject[aggrFunctionName]?.[0]?.values?.[0] || []
          return aggrValues?.map((x) => x?.timeStamp) || []
        }
      }
    } catch (error) {
      return []
    }
  }

  const getChartData = async ({
    chartDetails = [],
    weatherProps = [],
    startDate,
    interval = 15, //minutes - used to generate timestamps for chart categories / X-Axis labels
    endDate,
    category,
    chartType,
    tz,
    aggrFunctionName = '' // Will get only on latest type
  }) => {
    try {
      const queryPromises = []
      const refreshIds = []

      const chartQueryDataFormat = chartDetails.map((chart) => {
        return {
          startDate: startDate,
          endDate: endDate,
          category: category, // equipment or building level data needed
          chartType: chartType,
          building: {
            id: chart.buildingId,
            pId: [],
            type: ''
          },
          equipment: chart.equipmentDetails,
          equipmentType: '',
          weather: weatherProps,
          settings: [],
          buildingDateTime: '2024-01-08 23:26:43'
        }
      })
      chartQueryDataFormat.forEach((element) => {
        if (element?.building?.id) {
          refreshIds.push(element.building.id)
        }
        const body = { body: JSON.stringify(element) }
        queryPromises.push(getChartDataForBuilding(body))
      })
      await dispatch(newRefreshToken(refreshIds, null, null,[]))
      Promise.all(queryPromises)
        .then((response) => {
          let allFinalData = {
            aggregatedData: {},
            data: []
          }
          if (response) {
            let parsedResult
            for (let i = 0; i < response?.length; i++) {
              parsedResult = JSON.parse(response[i])
              const finalData = formatChartData(
                parsedResult?.body,
                startDate,
                endDate,
                tz,
                interval === 15 ? '15m' : '1d',
                aggrFunctionName
              )
              allFinalData.aggregatedData = {
                ...allFinalData.aggregatedData,
                ...finalData.aggregatedData
              }
              allFinalData.data = [...allFinalData.data, ...finalData.data]
            }
            setChartData({
              ...allFinalData,
              timeStamps: getTimeStamps(allFinalData)
            })
          }
        })
        .catch((error) => {
          setChartData({
            data: [],
            aggregatedData: {}
          })
        })
    } catch (error) {
      setChartData({
        data: [],
        aggregatedData: {}
      })
    }
  }

  const getChartDataByTimeSlider = (startTime, endTime) => {
    try {
      const lineChartData = chartData
      if (lineChartData) {
        const data = lineChartData?.data ?? []

        const filteredata =
          data?.map((m) => ({
            ...m,
            values: m?.values?.map((v, i) =>
              v?.filter(
                (f) =>
                  new Date(f?.timeStamp).getTime() >= startTime &&
                  new Date(f?.timeStamp).getTime() <= endTime
              )
            )
          })) ?? []
        const timeSliderChartData = {
          data: filteredata,
          aggregatedData: lineChartData?.aggregatedData
        }
        setChartDataByTimeSlider({
          ...timeSliderChartData,
          timeStamps: getTimeStamps(timeSliderChartData)
        })
      } else {
        setChartDataByTimeSlider({
          data: [],
          aggregatedData: {}
        })
      }
    } catch (error) {
      setChartDataByTimeSlider({
        data: [],
        aggregatedData: {}
      })
    }
  }

  // Just in case wanted to reset all states
  // This can be used
  const resetChartData = () => {
    setFetchingChartData(false)
    setChartData(null)
    setChartDataByTimeSlider(null)
  }

  return {
    fetchingChartData,
    loadingChartData,
    getChartData,
    getChartDataByTimeSlider,
    chartData,
    chartDataByTimeSlider,
    resetChartData
  }
}
