import { createContext, useMemo, useRef, useContext } from 'react'
import { legendGroupModel, legendItemModel } from '../config'
import { Legend, LegendCompProps, LegendsGroup } from '../types'
import { LegendUIContext } from './LegendProvider'

export default class LegendApi {
  readonly isWrappedByLegendProvider = true

  readonly setState

  selectedItems: Legend[] = []

  charts: any = {}

  private _fnForListeningLegendCompToggleStatus = (s: boolean) => {}

  constructor(setState: any) {
    this.setState = setState || ((s: any) => null)
  }

  init() {
    const chartLegendState = new ChartLegendState(this)
    return (this.charts[chartLegendState.id] = chartLegendState)
  }

  unmount(id) {
    delete this.charts[id]
  }

  listenLegendComponentToggleStatus(callback: (status: boolean) => void) {
    typeof callback === 'function' &&
      (this._fnForListeningLegendCompToggleStatus = callback)
  }

  notifyComponentToggleStatus(isOpen: boolean) {
    this._fnForListeningLegendCompToggleStatus(isOpen)
  }

  postLegendComponentProps(props: LegendCompProps) {
    this.setState((state: LegendUIContext): LegendUIContext => {
      return {
        ...state,
        legendComponentProps: {
          ...props
        }
      }
    })
  }

  createLegendModel(props: Legend) {
    return legendItemModel(props)
  }

  createLegendGroupModel(
    legendItems: Legend[] = [],
    groupItem: Legend | null = null
  ) {
    return legendGroupModel(legendItems, groupItem)
  }
}

/**
 * fn to update selected legend status
 *
 * @param legends
 * @param selectedLegend
 * @returns
 */
function updateLegendStatus(
  legends: LegendsGroup[],
  selectedLegend: Legend,
  callback
) {
  const selectedItems = []

  const updated = legends.map((legend) => {
    if (legend.items)
      legend.items = checkItems(legend.items, selectedLegend, selectedItems)
    return legend
  })

  callback(selectedItems)

  return updated
}

export class ChartLegendState {
  readonly base: any

  readonly id = '__' + Math.round(Math.random() * 1000000)

  selectedItems = []

  private _legendOnClickFn = (s: any) => {}
  private _getAllSelectedItemFn = (s: any) => {}

  constructor(scope) {
    this.base = scope || { setState: () => null }
  }

  listenOnLegendClick(callback: (status: any) => void) {
    typeof callback === 'function' && (this._legendOnClickFn = callback)
  }

  listenToGetAllSelectedItem(callback: (legends: Legend[]) => void) {
    typeof callback === 'function' && (this._getAllSelectedItemFn = callback)
  }

  postLegendDetails(details: LegendsGroup[]) {
    const onClickFn = (selectedItem: Legend) =>
      this.onClikLegentItem(selectedItem)

    const legends = insertOnClickFn(details, onClickFn)

    const id = this.id

    this.base.setState((state: LegendUIContext): LegendUIContext => {
      return {
        ...state,
        legendDetails: {
          ...state.legendDetails,
          [id]: legends
        }
      }
    })
  }

  private onClikLegentItem(selectedLegendItem: Legend) {
    const callback = (allSelectedItems) => {
      this._legendOnClickFn({
        isSelected: !selectedLegendItem.disabled,
        item: { ...selectedLegendItem } // TODO :should not share all details
      })

      this._getAllSelectedItemFn(allSelectedItems.slice())
      this.selectedItems = allSelectedItems
    }

    const id = this.id

    this.base.setState((state: LegendUIContext): LegendUIContext => {
      const updated = updateLegendStatus(
        state.legendDetails[id],
        selectedLegendItem,
        callback
      )
      return {
        ...state,
        legendDetails: {
          ...state.legendDetails,
          [id]: updated
        },
        selectedLegendItem: {
          [id]: selectedLegendItem
        }
      }
    })
  }
}

function checkItems(
  items: Legend[],
  selectedLegend: Legend,
  selectedItems: Legend[]
) {
  return items.map((item) => {
    if (item.uuid === selectedLegend.uuid) {
      item.disabled = !selectedLegend.disabled
    }

    if (item.disabled && item.clickable) {
      selectedItems.push({ ...item })
    }
    return item
  })
}

function insertOnClickFn(details: LegendsGroup[], fn: any) {
  return details.map((lg: LegendsGroup) => {
    return { ...lg, onClick: fn }
  })
}
