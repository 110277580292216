import { useEffect, useMemo, useReducer, useRef } from 'react'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import * as d3 from 'd3'

import {
  selectAxes,
  selectCanvasDetails,
  selectGeneratedScales,
  selectSeriesData,
  useSelector
} from '../../../BaseSetup/BaseSetup'
import { useSyncSeriesPointsForTooltip } from '../../../CommonComponents/TooltipNew'
import '../XYChart.scss'

const isNil = (val) => val === undefined || val === null

const CHART_MARGIN = 0

function addDataEntry(index, point, { data, dispatch, maxAllowedPoints = 20 }) {
  const isSameValue = data.some(
    (d) =>
      d.curveXAxisValue === point.curveXAxisValue &&
      d.curveYAxisValue === point.curveYAxisValue
  )

  if (isSameValue) return

  if (data.length < maxAllowedPoints) {
    const dataPoints =
      index > 0
        ? [...data.slice(0, index), point]
        : [point, ...data.slice(index + 1)]
    dispatch({ payload: dataPoints, type: 'add' })
  }
}

function clamp(value, min, max) {
  return Math.max(Math.min(value, max), min)
}

function getCurveData(data, xScale, yScale) {
  const newData = _sortBy(
    data
      .filter(
        ({ curveXAxisValue, curveYAxisValue }) =>
          !isNaN(parseFloat(curveXAxisValue)) &&
          !isNaN(parseFloat(curveYAxisValue))
      )
      .map(({ curveXAxisValue, curveYAxisValue }, index) => ({
        x: xScale(curveXAxisValue),
        y: yScale(curveYAxisValue),
        index
      })),
    'x'
  )

  return {
    nodes: newData,
    links: newData.slice(0, newData.length - 1).map((p, i) => ({
      source: newData[i],
      target: newData[i + 1]
    }))
  }
}

function renderNodes(
  g,
  xScale,
  yScale,
  nodesData,
  {
    setSelectedPoint,
    data,
    dispatch,
    canvasWidth,
    canvasHeight,
    searchPointsOnMouseIn,
    hidePointsOnMouseOut,
    nodeStyle
  }
) {
  const symbol = d3
    .symbol()
    .size(nodeStyle?.size ?? 60)
    .type(nodeStyle?.symbol ?? d3.symbolSquare)

  return g
    .selectAll('.node')
    .data(nodesData)
    .join('path')
    .classed('node', true)
    .attr('cursor', nodeStyle?.cursor ?? 'pointer')
    .attr('stroke', nodeStyle?.stroke ?? 'steelblue')
    .attr('stroke-width', nodeStyle?.strokeWidth ?? '1px')
    .attr('fill', nodeStyle?.fill ?? 'transparent')
    .attr('d', symbol)
    .attr('transform', (d) => `translate(${d.x},${d.y})`)
    .on('mouseover', function (event, d) {
      const someDragged = d.drag ?? false

      if (someDragged) {
        return
      }

      searchPointsOnMouseIn(event, roundOff(d.x))
    })
    .on('mousedown', () => {
      hidePointsOnMouseOut()
    })
    .on('mouseleave', (event, d) => {
      const someDragged = d.drag ?? false

      if (someDragged) {
        return
      }

      hidePointsOnMouseOut()
    })
    .on('click', function (event) {
      // prevents adding new point on node click
      event.stopPropagation()
      // CSS class for highlighting selected point
      const nodeClicked = d3.select(this).node()
      g.selectAll('.node').classed('selected-node', false)
      const index = _get(nodeClicked, '__data__.index')
      setSelectedPoint(data[index], index)
      nodeClicked.classList.add('selected-node')
    })
    .call(
      d3
        .drag()
        .on('start', function (event, d: any) {
          // remove highlight when node changes
          g.selectAll('.node').classed('selected-node', false)

          d.drag = true

          d3.select(this).attr('fill', nodeStyle.fillOnDrag ?? 'steelblue')
        })
        .on('drag', (event, d: any) => {
          // setSelectedPoint(null)
          d.dragContinue = true

          const newData = [...data]
          const { index } = d

          // restricts new x and y coordinates by chart dimensions
          d.x = clamp(event.x, CHART_MARGIN, canvasWidth - CHART_MARGIN)
          d.y = clamp(event.y, CHART_MARGIN, canvasHeight - CHART_MARGIN)

          newData[index] = {
            curveXAxisValue: roundOff(xScale.invert(d.x)),
            curveYAxisValue: roundOff(yScale.invert(d.y))
          }

          const curveData = getCurveData(newData, xScale, yScale)
          const { links } = curveData

          g.selectAll('.node').attr(
            'transform',
            (d) => `translate(${d.x},${d.y})`
          )

          g.selectAll('.link').each(function (d, i) {
            d3.select(this)
              .attr('x1', links[i].source.x)
              .attr('y1', links[i].source.y)
              .attr('x2', links[i].target.x)
              .attr('y2', links[i].target.y)
          })
        })
        .on('end', function (event, d: any) {
          const { index, x, y } = d
          const newData = [...data]

          newData[index] = {
            curveXAxisValue: Number(xScale.invert(x).toFixed(2)),
            curveYAxisValue: Number(yScale.invert(y).toFixed(2))
          }

          d.drag &&
            d.dragContinue &&
            dispatch({ payload: newData, type: 'update', isDirtyCheck: true })

          d3.select(this).attr('fill', 'transparent')

          d.drag = false
          d.dragContinue = false
        })
    )
}

function renderLinks(g, linksData, linkStyle = {} as any) {
  return g
    .selectAll('.link')
    .data(linksData)
    .join('line')
    .classed('link', true)
    .attr('stroke', linkStyle?.stroke ?? 'steelblue')
    .attr('stroke-width', linkStyle?.strokeWidth ?? '1px')
    .attr('x1', (d) => d.source.x)
    .attr('y1', (d) => d.source.y)
    .attr('x2', (d) => d.target.x)
    .attr('y2', (d) => d.target.y)
}

function renderCurve(
  gEl,
  data,
  xScale,
  yScale,
  {
    setSelectedPoint,
    dispatch,
    canvasWidth,
    canvasHeight,
    searchPointsOnMouseIn,
    hidePointsOnMouseOut,
    maxAllowedPoints,
    linkStyle,
    nodeStyle
  }
) {
  const g = d3.select(gEl.current)

  g.selectAll('.node')
    .on('mouseover', null)
    .on('mouseleave', null)
    .on('click', null)
    .call(d3.drag().on('start', null).on('drag', null).on('end', null))

  g.selectAll('.curve').on('click', null)

  // inits curve nodes and links data
  const curveData = getCurveData(data, xScale, yScale)
  const curveUpdate = g
    .selectAll('.curve')
    .data([data])
    .join('g')
    .attr('fill', 'transparent')
    .classed('curve', true)
    .call((g) =>
      g
        .selectAll('.curve-rect')
        .data([0])
        .join('rect')
        .classed('curve-rect', true)
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', canvasWidth)
        .attr('height', canvasHeight)
    )
    .call((g) => renderLinks(g, curveData.links, linkStyle))
    .call((g) =>
      renderNodes(g, xScale, yScale, curveData.nodes, {
        setSelectedPoint,
        data,
        dispatch,
        canvasWidth,
        canvasHeight,
        searchPointsOnMouseIn,
        hidePointsOnMouseOut,
        nodeStyle
      })
    )
    .on('click', function (event) {
      // adds new point on the chart
      const [mouseX, mouseY] = d3.pointer(event)
      const curveData = getCurveData(data, xScale, yScale)
      const x = clamp(mouseX, CHART_MARGIN, canvasWidth - CHART_MARGIN)
      const y = clamp(mouseY, CHART_MARGIN, canvasHeight - CHART_MARGIN)

      // remove highlight when node changes
      g.selectAll('.node').classed('selected-node', false)

      let index = curveData.nodes.findIndex((point) => mouseX < point.x)
      index = data.findIndex(
        (d) =>
          Number(d['curveXAxisValue']) === 0 &&
          Number(d['curveYAxisValue']) === 0
      )
      // setSelectedPoint(null)
      addDataEntry(
        index,
        {
          curveXAxisValue: Number(xScale.invert(x).toFixed(2)),
          curveYAxisValue: Number(yScale.invert(y).toFixed(2))
        },
        { dispatch, data, maxAllowedPoints }
      )
    })

  return curveUpdate
}

function roundOff(value) {
  return parseFloat(Number(value).toFixed(2))
}

function reducer(state, action) {
  const type = action.type ?? ''
  const isDirtyCheck = action.isDirtyCheck ?? false
  const payload = action.payload ?? []
  const data = state.data ?? []

  let isChanged = true

  if (isDirtyCheck) {
    isChanged = data.length !== payload.length

    if (!isChanged)
      isChanged = payload.some((next, idx) => {
        const prev = data[idx] ?? {}
        return (
          prev.curveXAxisValue !== next.curveXAxisValue &&
          prev.curveYAxisValue !== next.curveYAxisValue
        )
      })
  }

  return { data: isChanged ? payload : data, type }
}

function dragLineTooltipModel(config, tooltipDataModel) {
  const {
    xScale = () => null,
    yScale = () => null,
    xscaleType,
    yscaleType,
    data = [],
    options = {}
  } = config

  const {
    name,
    nodeStyle: { stroke = 'steelblue' },
    tooltipDataModelFormatter = () => null
  } = options

  if (xscaleType === 'scaleBand' || yscaleType === 'scaleBand') return []

  return data.reduce((av, point) => {
    const xVal = point.curveXAxisValue
    const yVal = point.curveYAxisValue

    let x = xScale(xVal)
    let y = yScale(yVal)

    x = roundOff(x)
    y = roundOff(y)

    if (isNil(x) || isNil(y)) return av

    const defaultProps = {
      label: name ?? '',
      value: yVal,
      title: xVal,
      groupBy: 'title',
      color: stroke,
      x,
      y,
      xValue: xVal,
      yValue: yVal,
      higlightDots: false
    }

    const userformattedValues = tooltipDataModelFormatter({
      ...defaultProps,
      ...options
    })

    av.push(
      tooltipDataModel(
        x,
        {
          // Default model values
          ...defaultProps,

          // modified values by given formatter
          ...userformattedValues
        },
        {
          x,
          y,
          maxX: x + 6,
          maxY: y + 6
        }
      )
    )

    return av
  }, [])
}

function DraggableLine(props: any) {
  const {
    xScale,
    xscaleType,
    yScale,
    yscaleType,
    data,
    containerElRef,
    canvasWidth,
    canvasHeight,
    options
  } = props

  // define reducer with initaialState
  const [state, dispatch] = useReducer(reducer, {
    data: [],
    type: 'init'
  })

  // Tooltip API's
  const {
    addPoints,
    deletePoints,
    createTooltipPointModel,
    searchPointsOnMouseIn,
    hidePointsOnMouseOut
  } = useSyncSeriesPointsForTooltip(false)

  // Draw lines and feed data to tooltip layer
  useEffect(() => {
    const { data } = state
    const {
      onSelect = () => null,
      maxAllowedPoints,
      linkStyle = {},
      nodeStyle = {}
    } = options

    // Draw lines
    renderCurve(containerElRef, data, xScale, yScale, {
      setSelectedPoint: onSelect,
      dispatch,
      canvasHeight,
      canvasWidth,
      searchPointsOnMouseIn,
      hidePointsOnMouseOut,
      maxAllowedPoints,
      linkStyle,
      nodeStyle
    })

    const tooltipPoints = dragLineTooltipModel(
      {
        options,
        xscaleType,
        yscaleType,
        xScale,
        data,
        yScale
      },
      createTooltipPointModel
    )

    if (tooltipPoints.length) {
      addPoints(tooltipPoints)
    }

    return () => {
      tooltipPoints.length && deletePoints(tooltipPoints)
    }
  }, [state])

  // Post the data to user when points are changed
  useEffect(() => {
    if (state.type !== 'props-update') {
      options?.onChange?.([...state.data])
    }
  }, [state.data])

  // Initialize the data from props
  useEffect(() => {
    dispatch({ payload: [...data], type: 'props-update', isDirtyCheck: true })
  }, [data])

  return null
}

function DraggableLineRenderer(props) {
  const gElementRef = useRef(null)

  const { types = [], canvasIndex = 0 } = props

  const axes = useSelector(selectAxes)
  const generatedScales = useSelector(selectGeneratedScales)
  const seriesData = useSelector(selectSeriesData)
  const canvasDetails: any = useSelector(selectCanvasDetails)

  const { canvasWidth, canvasHeight } = useMemo(() => {
    const canvasDim = canvasDetails?.[canvasIndex]
    return {
      canvasHeight: canvasDim?.height,
      canvasWidth: canvasDim?.width
    }
  }, [canvasDetails, canvasIndex])

  return (
    <g ref={gElementRef}>
      {types?.map((obj: any, i: number) => {
        const xAxisObj = axes?.find(
          (axisObj: any) => axisObj?.key === obj?.xAxisKey
        )
        const yAxisObj = axes?.find(
          (axisObj: any) => axisObj?.key === obj?.yAxisKey
        )
        const xObject = xAxisObj?.scale
        const yObject = yAxisObj?.scale
        const gXScaleObj = generatedScales?.[obj?.xAxisKey]
        const gYScaleObj = generatedScales?.[obj?.yAxisKey]

        const data =
          seriesData?.[obj?.seriesKey]?.data?.map((v) => ({
            curveXAxisValue: v[0],
            curveYAxisValue: v[1]
          })) || []
        const xscaleType = xObject?.props?.type
        const yscaleType = yObject?.props?.type
        const xScale = gXScaleObj?.gScale
        const yScale = gYScaleObj?.gScale

        return (
          <DraggableLine
            key={'__dr_' + i}
            data={data}
            containerElRef={gElementRef}
            xScale={xScale}
            xscaleType={xscaleType}
            yScale={yScale}
            yscaleType={yscaleType}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
            options={obj}
          />
        )
      })}
    </g>
  )
}

export default DraggableLineRenderer
