
import { scaleBand, scaleLinear, scaleTime } from "d3"

export const SCALE_TIME = "scaleTime";
export const SCALE_LINEAR = "scaleLinear";
export const SCALE_BAND = "scaleBand";

export const scales: any = {
    [SCALE_TIME]: scaleTime,
    [SCALE_LINEAR]: scaleLinear,
    [SCALE_BAND]: scaleBand
}