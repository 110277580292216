import { useState,useEffect } from "react"

export const useInterval = ({
  interval
}) => {
const [tick,setTick] = useState(0)
useEffect(() => {
  if(!interval || isNaN(interval)){
    return 
  }
  const intervalLoop = setInterval(() => setTick(t => t + 1),interval)
  return () => clearInterval(intervalLoop)
},[interval])

return tick
}