import moment from "moment"
import styled from "styled-components"
import ToggleButtonHorizontal from 'src/components/legacy/components/toggle-button-horizontal/index'
import { ToggleButton } from './styles.js'
import Select from 'src/components/legacy/components/select/select'



const RatchetBody = styled.td`
  min-width: 880px;
  width: 80%;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
`

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  &::-webkit-inner-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  } 
  &::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`

const Input = styled.input`
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }    
`;



export const DemandRatchet = ({values, setValue, name, displayName, error}) => <RatchetBody>
    <div>
      {displayName ? displayName : name}
    </div>
    <ToggleButton>
      <label>{`Ratchet Clause On: `}</label>
        <ToggleButtonHorizontal 
          testName='rate-clause-on'
          name="ratchetClauseEnabler"
          checked={values.demandRatchet?.[name]?.active}
          onChange={() => setValue("demandRatchet", {...values.demandRatchet, [name]: {...values.demandRatchet[name], active: !values.demandRatchet?.[name]?.active }})}
        />
    </ToggleButton>
    <div>
      <FlexDiv>
      Ratchet Demand %
      &nbsp;&nbsp;
      <Input 
      disabled={!values.demandRatchet?.[name]?.active}
      onChange={({
        target: { value }
      }) =>
      setValue("demandRatchet", {...values.demandRatchet, [name]: {...values.demandRatchet[name], value: value}})
      }
      type="number"
      value={values.demandRatchet[name]?.value}
      style={{width: "40px"}}
      />
      &nbsp;&nbsp;
      of peak kW set within the last:
      &nbsp;&nbsp;
      <Select
        isDisabled={!values.demandRatchet?.[name]?.active}
        options={[
          {key: "Last 5 months", value: "Last 5 months"},
          {key: "Last 11 months", value: "Last 11 months"},
          values?.seasons?.summer?.active ? {key: "Summer months", value: "Summer months"} : null
      ].filter(e => e)}
        onChange={(index) => setValue("demandRatchet", {...values.demandRatchet, [name]: {...values.demandRatchet[name], type: index}})}
        selectedItem={values.demandRatchet?.[name]?.type}
      />
      </FlexDiv>
    </div>
  </RatchetBody>

export const DemandRatchetView = ({values, name, displayName}) => <div>
<label>
  {displayName ? displayName : name}: &nbsp;
</label>
<span>Ratchet is set to {values.demandRatchet[name]?.value ? values.demandRatchet[name]?.value : "undefined"}% of the peak set in the {values.demandRatchet?.[name]?.type ? values.demandRatchet?.[name]?.type : "undefined"}.</span>
</div>
  