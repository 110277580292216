export const SEARCH_SALESOFFICES = `
query SearchSalesOffices (
  $filter: SearchableSalesOfficeFilterInput
  $limit: Int
  $nextToken: String
  $from: Int
  $sort: [SearchableSalesOfficeSortInput]
)
{
searchSalesOffices  (
filter: $filter      
limit: $limit
nextToken: $nextToken
from: $from
sort: $sort
) {
    nextToken
    total
    items {
      id
      name
    }
  }
}
`

export const UPDATE_OWN_SELF = /* GraphQL */ `
  mutation UPDATE_OWN_SELF($requestBody: AWSJSON!) {
    mutateOwnUser(requestBody: $requestBody)
  }
`