import { isFunction } from '../../utils'
// ----------------------------------------------  merge props  ----------------------------------------------

// default
export const merge = (local = {}, outer = {}) => ({ ...local, ...outer })

// with light & dark object
export const mergeLightAndDarkTheme = (local = {}, outer = {}) => ({
  light: { ...local.light, ...outer.light },
  dark: { ...local.dark, ...outer.dark }
})

// based on size
export const mergeSize = (local = {}, outer = {}) => ({
  small: { ...local.small, ...outer.small },
  medium: { ...local.medium, ...outer.medium },
  large: { ...local.large, ...outer.large }
})

// ---------------------------------------------- init Theme Mapper ----------------------------------------------

export default function initMergeThemeFn({ defaultStyle, propResolver = {} }) {
  return function mergeTheme(componentTheme, _userTheme, _newTheme) {
    if (!componentTheme) return defaultStyle

    return Object.keys(defaultStyle).reduce(
      (av, cv) => {
        let fn = null
        fn = propResolver[cv]
          ? propResolver[cv]
          : propResolver['default']
          ? propResolver['default']
          : merge
        if (isFunction(fn))
          av[cv] = fn(defaultStyle[cv], componentTheme[cv], _newTheme)
        return av
      },
      { ...componentTheme }
    )
  }
}
