import {
  computePosition,
  offset as offsetApi,
  autoPlacement,
  arrow
} from '@floating-ui/dom'
import clsx from 'clsx'

import { useEffect, useMemo, useRef, useState } from 'react'
import { TooltipTemplate } from './templates'

import './styles.scss'
import { clone, isEmpty } from 'ramda'

export default function ToolTip({
  autoPlacementProps = {},
  boundary = 'clippingAncestors',
  listenEventName = '',
  offset = 10,
  replaceMiddlewares,
  TooltipRenderer,
  tooltipClassName,
  tooltipArrowClassName
}: TooltipCompProps) {
  const arrowRef: any = useRef(null)
  const isMouseIn = useRef(false)
  const tooltipInfo = useRef([])

  // Store hovered element selector i.e data-tootip-* attribute to query the element
  const [selector, setSelector] = useState<string | null>(null)

  // Store the data passed through events from component while hovering
  const dataRef = useRef<any | TooltipData[]>([])

  const ttClassName = `tt-${listenEventName}`
  const ttArrowClassName = `tt-arrow-${listenEventName}`

  const displayHoveredData = isMouseIn.current && !isEmpty(tooltipInfo.current)

  useEffect(() => {
    const eventHandler = (event: TooltipEvent) => {
      if (event?.detail) {
        dataRef.current = event.detail.selector ? event.detail.data || [] : []
        setSelector(event.detail.selector)
      }
    }

    // DON't set listener if event name is not provided
    if (!listenEventName) return () => null

    // To trigger the event Listener
    document.addEventListener(`tooltip-${listenEventName}`, eventHandler as any)

    return () => {
      // de-actiave all events
      document.removeEventListener(
        `tooltip-${listenEventName}`,
        eventHandler as any
      )
    }
  }, [])

  useEffect(() => {
    const button: any = document.querySelector(`[data-tooltip-${selector}]`)
    const tooltip: any = document.querySelector('.' + ttClassName)
    const arrowDOM: any = document.querySelector('.' + ttArrowClassName)

    const middleware = Array.isArray(replaceMiddlewares)
      ? replaceMiddlewares
      : [
          offsetApi(offset),
          autoPlacement({
            boundary: (boundary as any).current,
            crossAxis: true,
            alignment: 'start',
            autoAlignment: true,
            ...autoPlacementProps
          }),
          arrow({ element: arrowDOM })
        ]

    if (button && tooltip)
      computePosition(button, tooltip, {
        middleware
      }).then(({ x, y, middlewareData, placement }) => {
        const staticSide: any = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right'
        }[placement.split('-')[0]]

        const arrow: any = middlewareData.arrow

        // Reset TRBL properties
        arrowDOM.style.left = ''
        arrowDOM.style.top = ''
        arrowDOM.style.right = ''
        arrowDOM.style.bottom = ''

        Object.assign(arrowDOM.style, {
          left:
            arrow.x !== undefined && arrow.centerOffset === 0
              ? `${arrow.x + 2}px`
              : '',
          top:
            arrow.y !== undefined && arrow.centerOffset === 0
              ? `${arrow.y}px`
              : '',
          [staticSide]: '-4px'
        })

        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
          visibility: 'visible'
        })
      })
    else {
      if (!displayHoveredData) tooltip.style.visibility = 'hidden'
    }
  }, [selector])

  const data = useMemo(() => {
    if (displayHoveredData) {
      return tooltipInfo.current
    }

    tooltipInfo.current = clone(dataRef.current)

    return dataRef.current
  }, [dataRef.current])

  return (
    <div className="tt-container">
      <div
        className={clsx('tt', tooltipClassName, ttClassName)}
        onMouseEnter={() => {
          isMouseIn.current = true
          console.log('on enter', isMouseIn.current)
        }}
        onMouseLeave={() => {
          isMouseIn.current = false
          tooltipInfo.current = []
          setSelector('')
        }}
      >
        <div
          className={clsx('tt-arrow', tooltipArrowClassName, ttArrowClassName)}
          ref={arrowRef}
        ></div>
        <div className="tt-content">
          {TooltipRenderer ? (
            <TooltipRenderer data={data} />
          ) : (
            <TooltipTemplate data={data} />
          )}
        </div>
      </div>
    </div>
  )
}
