import TextInput from 'src/components/legacy/components/modal-text-input'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import { useFormik } from 'formik'
import { ChangeEvent } from 'react'
import { useMutation } from 'src/hooks/APIHooks'
import { ERROR_MESSAGES } from "./formValidator"
import uuid4 from "uuid4"

// components
import Modal from 'src/components/legacy/components/modal/modal'

import { ACTIONS } from 'src/constants'

import validationSchema from './formValidator'
import { CREATE_APPLICATION, UPDATE_APPLICATION } from '../graphql'
import styled from 'styled-components'
import translate from 'src/common/translations'

const ModalDivSpacer = styled.div`
`

const ACTION_HEADERS = {
  [ACTIONS.ADD]: 'Create Application',
  [ACTIONS.EDIT]: 'Edit Application',
  [ACTIONS.VIEW]: 'View Application',
  [ACTIONS.COPY]: 'Copy Application'
}

const PageDetail = ({
  mode,
  setMode,
  input,
  setInput,
  initialValues,
  setReloadEvents
}) => {
  const getModalHeading = () => {
    const heading =
      mode === ACTIONS.ADD || mode === ACTIONS.COPY
        ? ``
        : input?.name && `: ${input?.name}`
    return `${ACTION_HEADERS[mode]}${heading}`
  }

  const { onSubmit: createMutation } = useMutation({
    query: CREATE_APPLICATION,
    onSuccess: () => {
      closeDialog()
      setReloadEvents(true)
    }
  })

  const { onSubmit: updateMutation } = useMutation({
    query: UPDATE_APPLICATION,
    onSuccess: () => {
      closeDialog()
      setReloadEvents(true)
    }
  })
  const formik = useFormik({
    initialValues: input,
    validationSchema: validationSchema(),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const {
        name,
        description,
        clientIds
      } = values
      const newId = uuid4()
      mode === ACTIONS.EDIT
        ? updateMutation({
            input: {
              id: input.id,
              appId: input.id,
              name,
              description,
              clientIds: JSON.parse(clientIds)
            }
          })
        : createMutation({
            input: {
              id: newId,
              appId: newId,
              name,
              description,
              clientIds: JSON.parse(clientIds)
            }
          })
    }
  })

  const { values, errors } = formik
  const optionsTranslated = {
    close: translate('Close'),
    save: translate('Save'),
    cancel: translate('Cancel')
  }
  const getButtons = (submitForm, isFormValid = false) => {
    return mode === ACTIONS.VIEW
      ? [{ text: optionsTranslated.close, handleClick: closeDialog }]
      : [
          {
            text: optionsTranslated.save,
            type: 'submit',
            handleClick: submitForm,
            disabled: !isFormValid
          },
          {
            text: optionsTranslated.cancel,
            type: 'cancel',
            handleClick: closeDialog
          }
        ]
  }

  const closeDialog = () => {
    setMode(null)
    setInput(initialValues)
  }

  const modalConfig = {
    gray: true,
    className: 'wide-modal',
    heading: getModalHeading(),
    buttons: getButtons(formik.handleSubmit, formik.isValid),
    handleClose: closeDialog
  }

  const handleInputChange = (name: string, value: any) => {
    formik.setFieldValue(name, value)
  }

  return (
    <Modal {...modalConfig}>
      <ModalDivSpacer>
        <div>
          <form onSubmit={formik.handleSubmit} noValidate>
            <TextInput
              labelText="Application Name"
              defaultValue={values?.name}
              name="name"
              onChange={({
                target: { value, name }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(name, value)
              }
              hasError={errors?.['name']}
              errorMessage={ERROR_MESSAGES["name"]}
            />
            <TextAreaInput
              labelText="Application Description"
              defaultValue={values?.description}
              onChange={({
                target: { value }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange('description', value)
              }
              name="description"
              isRequired={false}
            />
            <TextInput
              labelText="Application Client Ids"
              defaultValue={values?.clientIds}
              onChange={({
                target: { value }
              }: ChangeEvent<HTMLInputElement>) =>
                handleInputChange('clientIds', value)
              }
              name="clientIds"
              isRequired={false}
              hasError={errors?.["clientIds"]}
              errorMessage={ERROR_MESSAGES["clientIds"]}
            />
          </form>
        </div>
      </ModalDivSpacer>
    </Modal>
  )
}
export default PageDetail
