import BaseWrapper from '../../../../chart-library/CommonComponents/BaseWrapper'
import Svg from '../../../../chart-library/CommonComponents/Svg'
import { StackedBar } from '../../../../chart-library/CommonComponents/StackedBar'

import { useConfig } from '../useConfig'
import { useOptions } from './useOptions'

export function TimelineStackedChart({
  series = [],
  categories = [],
  yAxisMin = 0,
  yAxisMax = 0
}) {
  const options = useOptions(categories, yAxisMin, yAxisMax)

  return (
    <BaseWrapper options={options} className="ecs-timeline-chart">
      <Svg>
        <StackedChart series={series} />
      </Svg>
    </BaseWrapper>
  )
}

function StackedChart({ series }) {
  const { isInitialized, xScale, yScale } = useConfig()

  if (!isInitialized) {
    return null
  }

  return (
    <StackedBar
      disconnectFromLegendContext
      barGap={0.8}
      hideBarText
      data={series}
      xScale={xScale.fn}
      yScale={yScale.fn}
    />
  )
}
