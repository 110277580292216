import { useTranslation } from 'react-i18next'
import { DataMonitoringColorsWrapper } from '../../styles'
import { useFormikContext } from '../MultiTabTorm'
import { convertStringToValidNumber } from 'src/components/legacy/common/helpers.js'
import {
  getOrbThresholdsList,
  getThresholdValue,
  THRESHOLD_CONSTANT,
  THRESHOLD_NONE,
  THRESHOLD_PERCENTAGE
} from '../../helpers'
import * as Yup from 'yup'
import { ChangeEvent } from 'react'
import i18next from 'i18next'
import ColorInput from 'src/components/color-input/ColorInput'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import { TranslateComponent } from 'src/common/translations'

export const OrbColorsSchema = Yup.object().shape({
  baselineColorValue: Yup.string().required(
    "Baseline is required"
  ),
  thresholdsValue: Yup.string().required('This field is required.'),
  threshold1Value: Yup.string().required('Threshold 1 is required.'),
  threshold2Value: Yup.string().required('Threshold 2 is required.'),
  threshold1ConstantValue: Yup.string()
    .required('Threshold 1 is required.'),
  threshold2ConstantValue: Yup.string()
    .required('Threshold 2 is required.')
    .test(
      'testValuesComparison',
      'Threshold 2 should be larger or equal to Threshold 1',
      testThreshholdValuesComparison
    )
})

function testThreshholdValuesComparison(value) {
  const {
    parent: { threshold1ConstantValue }
  } = this
  return !(threshold1ConstantValue > value)
}

const DataMonitoringOrbColors = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, errors, setFieldValue } = formik


  const handleGetThreshholdLabelValue = () => {
    switch (values?.thresholdsValue) {
      case THRESHOLD_CONSTANT:
        return `Constant Value`
      case THRESHOLD_PERCENTAGE:
        return `Percentage of Max`
      default:
        return ''
    }
  }
  const handleSelectColorPicker = (fieldName: string, color: any) => {
    setFieldValue(fieldName, color)
  }
  return (
    <DataMonitoringColorsWrapper>
      <div className="colors-tab-main">
        <div className="tab-page-content">
          <ColorInput
            label={`Baseline Color`}
            isRequired={true}
            pickerLabel={`Baseline`}
            backgroundColor={values?.baselineColorValue}
            update={(color) => {
              handleSelectColorPicker('baselineColorValue', color)
            }}
          />

          <SelectFormInput
            fieldsetClass="inline-2"
            labelName={"Thresholds"}
            optionsList={getOrbThresholdsList()}
            placeholder={'— Select One —'}
            update={(value)=> {
              setFieldValue('thresholdsValue', value)
            }}
            errorItem={errors?.thresholdsValue}
            selectedItem={getThresholdValue(values?.thresholdsValue)}
          />
          {values?.thresholdsValue !== THRESHOLD_NONE && (
            <>
              <div className="threshold-item">
                <ColorInput
                  label={`Threshold 1`}
                  isRequired={true}
                  backgroundColor={values?.threshold1Value}
                  update={(color) => {
                    handleSelectColorPicker('threshold1Value', color)
                  }}
                />
                <input
                  value={convertStringToValidNumber(
                    values?.threshold1ConstantValue
                  )}
                  maxLength={80}
                  className={errors?.threshold1ConstantValue ? 'error' : ''}
                  onChange={({
                    target: { value }
                  }: ChangeEvent<HTMLInputElement>) => {
                    const re = /^[0-9\b]+$/
                    if (value === '' || re.test(value)) {
                      setFieldValue('threshold1ConstantValue', value)
                    }
                  }}
                />
                <span><TranslateComponent>{handleGetThreshholdLabelValue()}</TranslateComponent></span>
              </div>
              <p className="error">{errors?.threshold1ConstantValue}</p>
              <div className="threshold-item">
                <ColorInput
                  label={`Threshold 2`}
                  isRequired={true}
                  backgroundColor={values?.threshold2Value}
                  update={(color) => {
                    handleSelectColorPicker('threshold2Value', color)
                  }}
                />
                <input
                  value={convertStringToValidNumber(
                    values?.threshold2ConstantValue
                  )}
                  maxLength={80}
                  className={errors?.threshold2ConstantValue ? 'error' : ''}
                  onChange={({
                    target: { value }
                  }: ChangeEvent<HTMLInputElement>) => {
                    const re = /^[0-9\b]+$/
                    if (value === '' || re.test(value)) {
                      setFieldValue('threshold2ConstantValue', value)
                    }
                  }}
                />
                <span><TranslateComponent>{handleGetThreshholdLabelValue()}</TranslateComponent></span>
              </div>
              <p className="error">{errors?.threshold2ConstantValue}</p>
            </>
          )}
        </div>
      </div>
    </DataMonitoringColorsWrapper>
  )
}

export default DataMonitoringOrbColors
