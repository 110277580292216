import moment from 'moment'
import { BACKEND_DATE_FORMAT } from 'src/components/legacy/common/time-helpers'
import widgetTemplate from './widget-template'
import { ACTIONS } from 'src/constants/actions'
import _groupBy from 'lodash/groupBy'
import {
  TARGET_MARKERS_CALCULATED,
  TARGET_MARKERS_CONSTANT,
  TARGET_MARKERS_NONE
} from 'src/common/target-markers'
import { TARGET_COLOR } from './colors'
import _sortBy from 'lodash/sortBy'
import {
  TIME_FORMAT,
  overlap
} from 'src/components/legacy/common/time-helpers'
import { isExternalURL } from 'src/components/top-menu/components/notification-list/notification'
import { MULTIPLE_LINES, WIDGET_TYPES } from 'src/constants'


const { 
  WEATHER,
  IMAGE_TEXT,
  CUSTOM_KPI,
  LINE,
  GAUGE,
  BAR,
  SINGLE_COMPARISON_BAR,
  DUAL_COMPARISON_BAR,
  ORB,
  ENERGY_CONSUMPTION,
  ENERGY_CONSUMPTION_NEW,
  ENERGY_COST,
  ENERGY_DEMAND,
  ENERGY_STAR_SCORE
} = WIDGET_TYPES

export const NO_IMAGE = "No-Image"

export const WidgetNames = {
  Weather: WEATHER,
  ImageText: IMAGE_TEXT,
  CustomKPI: CUSTOM_KPI,
  TrendLineChart: LINE,
  Gauge: GAUGE,
  TrendBarChart: BAR,
  TimeComparisonBarChart: SINGLE_COMPARISON_BAR,
  DataComparisonBarChart: DUAL_COMPARISON_BAR,
  Orb: ORB,
  BaseLineComparison: ENERGY_CONSUMPTION,
  EnergyConsumption: ENERGY_CONSUMPTION_NEW,
  EnergyCost: ENERGY_COST,
  EnergyDemand: ENERGY_DEMAND,
  BuildingData: MULTIPLE_LINES
}
export const widgetLoctionDefaultValue = (t) => t('widget-builder:CityStateZip')
export const CONFIRMATION = 'confirmation'
export const SUCCESS = 'success'
export const DELETE_SUCCESS = 'delete_success'
export const ERROR = 'error'
export const MODAL_TYPE = {
  CONFIRMATION,
  SUCCESS,
  DELETE_SUCCESS,
  ERROR
}
export const CELSIUS = "Celsius"
export const FAHRENHEIT = "Fahrenheit"
export const GAS_METER = "Gas Meter"
export const getWeatherWidgetUpsertMutationInputs = (
  values,
  buildingId,
  organizationId,
  widgetSalesOfficeId,
  userInfo,
  widgetBuildingId,
  mode
) => {
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.link,
    widgetType: WidgetNames.Weather,
    widgetBuildingId,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    weatherWidget: { 
      metricConversion: values?.metricConversion === '0' || values?.metricConversion === false ? CELSIUS : FAHRENHEIT
    },
    widgetTargetType: 'location',
    userId: userInfo?.id,
    accountId: organizationId,
    buildingId: widgetBuildingId,
    salesOfficeId: widgetSalesOfficeId
  }
  if ([ACTIONS.ADD,ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().locale('en').format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}
export const getGaugeWidgetUpsertMutationInputs = (
  values,
  userInfo,
  objectPickerSelectedValue,
  mode
) => {
  const objectPickerValue = objectPickerSelectedValue[0]
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.link,
    widgetType: WidgetNames.Gauge,
    widgetBuildingId: objectPickerValue?.buildingId,
    widgeEquipment1Id: objectPickerValue?.equipmentId ?? null,
    widgeEquipment1Name: objectPickerValue?.equipmentName,
    widgeEquipment1Type: objectPickerValue?.equipmentType,
    widgeEquipment1Family: objectPickerValue?.equipmentFamily,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetTargetType: 'location',
    equipmentDataMonitorGuageWidget: {
      colourConfig: {
        baseline: values?.baselineColorValue,
        thresholdOption: values?.thresholdsValue,
        threshold1: values?.threshold1Value,
        threshold1Value: values?.threshold1ConstantValue,
        threshold2: values?.threshold2Value,
        threshold2Value: values?.threshold2ConstantValue
      },
      property: {
        property: values?.property,
        uom: values?.unitsOfMeasure,
        symbol: values?.symbol
      },
      needleValue: values?.needleValue,
      timePeriod: values?.timePeriod,
      guageMinimum: values?.gaugeMin,
      guageMaximumType: values?.gaugeMax,
      guageMaximumValue:
        values?.gaugeMax === MaxConstant ? values?.gaugeConstantValue : '',
      guageMaximumCalculationType:
        values?.gaugeMax !== MaxConstant ? values?.gaugeCalculatedValue : ''
    },
    userId: userInfo?.id,
    accountId: objectPickerValue?.accountId,
    buildingId: objectPickerValue?.buildingId,
    salesOfficeId: objectPickerValue?.salesOfficeId
  }
  if ([ACTIONS.ADD,ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}
export const getTrendLineWidgetUpsertMutationInputs = (
  values,
  userInfo,
  objectPickerSelectedValue,
  mode
) => {
  const objectPickerValue = objectPickerSelectedValue[0]
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.link,
    widgetType: WidgetNames.TrendLineChart,
    widgetBuildingId: objectPickerValue?.buildingId,
    widgeEquipment1Id: objectPickerValue?.equipmentId ?? null,
    widgeEquipment1Name: objectPickerValue?.equipmentName,
    widgeEquipment1Type: objectPickerValue?.equipmentType,
    widgeEquipment1Family: objectPickerValue?.equipmentFamily,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetTargetType: 'location',
    equipmentTrendLineWidget: {
      colourConfig: {
        line: values?.lineColor,
        overlayLine: values?.overlayLineColor
      },
      property: {
        property: values?.property,
        uom: values?.unitsOfMeasure,
        symbol: values?.symbol
      },
      value: values?.value,
      timePeriod: values?.timePeriod,
      comparePoint: values?.comparePointTo
    },
    userId: userInfo?.id,
    accountId: objectPickerValue?.accountId,
    buildingId: objectPickerValue?.buildingId,
    salesOfficeId: objectPickerValue?.salesOfficeId
  }
  if ([ACTIONS.ADD,ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}
export const getBaseLineWidgetUpsertMutationInputs = (
  values,
  userInfo,
  selectedBaseline,
  mode
) => {
  const type = selectedBaseline?.baselineEquipment?.type
  const uom = type === GAS_METER ? UOMS.KBTU.symbol : UOMS.KWH.symbol
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetType: WidgetNames.BaseLineComparison,
    widgetBuildingId: selectedBaseline?.baselineEquipment?.building?.id,
    widgeEquipment1Id: selectedBaseline?.equipmentId,
    widgeEquipment1Name: selectedBaseline?.baselineEquipment?.name,
    widgeEquipment1Type: selectedBaseline?.baselineEquipment?.type,
    widgeEquipment1Family: selectedBaseline?.baselineEquipment?.family,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetTargetType: 'location',
    baselineId:selectedBaseline?.id,
    baselineComparision: {
      displayValue: uom,
      timePeriod: values?.timePeriod
    },
    userId: userInfo?.id,
    accountId: selectedBaseline?.baselineEquipment?.building?.accountId,
    buildingId: selectedBaseline?.baselineEquipment?.building?.id,
    salesOfficeId: selectedBaseline?.baselineEquipment?.building?.salesOfficeId
  }
  if ([ACTIONS.ADD,ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}
export const getEnergyConsumptionWidgetUpsertMutationInputs = (
  values,
  userInfo,
  objectPickerSelectedValue,
  mode
) => {
  const objectPickerValue = objectPickerSelectedValue[0]
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.widgetLinkType,
    customUrl: values?.widgetLinkType === 'CustomURL' ? values?.link : '',
    widgetType: WidgetNames.EnergyConsumption,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetBuildingId: objectPickerValue?.buildingId,
    widgeEquipment1Id: objectPickerValue?.equipmentId ?? null,
    widgeEquipment1Name: objectPickerValue?.equipmentName,
    widgeEquipment1Type: objectPickerValue?.equipmentType,
    widgeEquipment1Family: objectPickerValue?.equipmentFamily,
    widgetTargetType: 'equipment',
    energyConsumptionWidget: {
      colourConfig: {
        leftBar: values?.leftBarColor,
        toDate: values?.toDateBarColor,
        rightBar: values?.rightBarColor,
        rightBenchmark: values?.benchmarkColor
      },
      benchMark: {
        benchmarkMarker: values?.benchMarker,
        benchmarkValue: values?.benchMarker === TARGET_MARKERS_CONSTANT
        ? values?.benchMarkerValue
        : '',
        benchmarkLabel: values?.benchMarker === TARGET_MARKERS_CONSTANT
        ? values?.benchMarkerLabel
        : ''
      },
      uom: values?.unitsOfMeasure,
      timePeriod: values?.timePeriod,
      comparedTo: values?.comparedTo,
      showTemperature: [TIME_PAST_3_DAYS, TIME_PAST_7_DAYS].includes(values?.timePeriod) ? values?.isShowTemperature === '1' || values?.isShowTemperature === true ? true : false : false
    }
  }
  if ([ACTIONS.ADD, ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
    input['userId'] = userInfo?.id
    input['accountId'] = objectPickerValue?.accountId
    input['buildingId'] = objectPickerValue?.buildingId
    input['salesOfficeId'] = objectPickerValue?.salesOfficeId
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}
export const getEnergyCostWidgetUpsertMutationInputs = (
  values,
  userInfo,
  objectPickerSelectedValue,
  mode
) => {
  const objectPickerValue = objectPickerSelectedValue[0]
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.widgetLinkType,
    customUrl: values?.widgetLinkType === 'CustomURL' ? values?.link : '',
    widgetType: WidgetNames.EnergyCost,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetBuildingId: objectPickerValue?.buildingId,
    widgeEquipment1Id: objectPickerValue?.equipmentId ?? null,
    widgeEquipment1Name: objectPickerValue?.equipmentName,
    widgeEquipment1Type: objectPickerValue?.equipmentType,
    widgeEquipment1Family: objectPickerValue?.equipmentFamily,
    widgetTargetType: 'equipment',
    energyCostWidget: {
      colourConfig: {
        leftBar: values?.leftBarColor,
        toDate: values?.toDateBarColor,
        rightBar: values?.rightBarColor,
        rightBenchmark: values?.benchmarkColor
      },
      benchMark: {
        benchmarkMarker: values?.benchMarker,
        benchmarkValue: values?.benchMarker === TARGET_MARKERS_CONSTANT
        ? values?.benchMarkerValue
        : '',
        benchmarkLabel: values?.benchMarker === TARGET_MARKERS_CONSTANT
        ? values?.benchMarkerLabel
        : ''
      },
      timePeriod: values?.timePeriod,
      comparedTo: values?.comparedTo,
      showEnergyCostSegmentation: ![TIME_CURRENT_DAY, TIME_PAST_3_DAYS, TIME_PAST_7_DAYS].includes(values?.timePeriod) ? values?.isShowEnergyCostSegmentation === '1' ? true : false : false
    }
  }
  if ([ACTIONS.ADD, ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
    input['userId'] = userInfo?.id
    input['accountId'] = objectPickerValue?.accountId
    input['buildingId'] = objectPickerValue?.buildingId
    input['salesOfficeId'] = objectPickerValue?.salesOfficeId
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input

}
export const getEnergyDemandWidgetUpsertMutationInputs = (
  values,
  userInfo,
  objectPickerSelectedValue,
  mode
) => {
  const objectPickerValue = objectPickerSelectedValue[0]
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.widgetLinkType,
    customUrl: values?.widgetLinkType === 'CustomURL' ? values?.link : '',
    widgetType: WidgetNames.EnergyDemand,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetBuildingId: objectPickerValue?.buildingId,
    widgeEquipment1Id: objectPickerValue?.equipmentId ?? null,
    widgeEquipment1Name: objectPickerValue?.equipmentName,
    widgeEquipment1Type: objectPickerValue?.equipmentType,
    widgeEquipment1Family: objectPickerValue?.equipmentFamily,
    widgetTargetType: 'equipment',
    energyDemandWidget: {
      colourConfig: {
        currentDemand: values?.currentDemandColor,
        peakDemand: values?.peakDemandColor,
        overlayDemand: values?.rateIncreaseOverlayColor
      },
      rateIncrease: getRateIncreaseList(values),
      timePeriod: values?.timePeriod,
      comparedTo: values?.comparedTo,
      isRateIncrease: values?.isRateIncrease === '1' ? true : false 
    }
  }
  if ([ACTIONS.ADD, ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
    input['userId'] = userInfo?.id
    input['accountId'] = objectPickerValue?.accountId
    input['buildingId'] = objectPickerValue?.buildingId
    input['salesOfficeId'] = objectPickerValue?.salesOfficeId
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input

}
const getRateIncreaseList = (data) => {
  if (data?.isRateIncrease === false || data?.isRateIncrease === '0') return []
  const rateIncreaseList = []
  data?.rateIncrease?.forEach(rate => {
    if (rate?.endTime && rate?.startTime) {
      rateIncreaseList.push({ name: rate?.name, rateIncreaseStart: rate?.startTime, rateIncreaseEnd: rate?.endTime })
    }
  });
  return rateIncreaseList
}
export const getTimeComparisonWidgetUpsertMutationInputs = (
  values,
  userInfo,
  objectPickerSelectedValue,
  mode
) => {
  const objectPickerValue = objectPickerSelectedValue[0]
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.link,
    widgetType: WidgetNames.TimeComparisonBarChart,
    widgetBuildingId: objectPickerValue?.buildingId,
    widgeEquipment1Id: objectPickerValue?.equipmentId ?? null,
    widgeEquipment1Name: objectPickerValue?.equipmentName,
    widgeEquipment1Type: objectPickerValue?.equipmentType,
    widgeEquipment1Family: objectPickerValue?.equipmentFamily,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetTargetType: 'location',
    equipmentTimeComparisionBarWidget: {
      colourConfig: {
        leftOuterBar: values?.outerBarColor,
        leftInnerBar: values?.innerBarColor,
        leftBenchmark:
          values?.leftTargetMarker !== TARGET_MARKERS_NONE
            ? values?.leftBenchmarkColor
            : TARGET_COLOR,
        rightBar: values?.rightBarColor,
        rightBenchmark:
          values?.rightTargetMarker !== TARGET_MARKERS_NONE
            ? values?.rightBenchmarkColor
            : TARGET_COLOR
      },
      property: {
        property: values?.property,
        uom: values?.unitsOfMeasure,
        symbol: values?.symbol
      },
      leftbarBenchmark: {
        benchmarkCalculation:
          values?.leftTargetMarker === TARGET_MARKERS_CALCULATED ? values?.leftTargetMarkerCalculate : '',
        benchmarkLabel:
          values?.leftTargetMarker === TARGET_MARKERS_CONSTANT
            ? values?.leftMarkerLabel
            : '',
        benchmarkMarker: values?.leftTargetMarker,
        benchmarkValue:
          values?.leftTargetMarker === TARGET_MARKERS_CONSTANT
            ? values?.leftMarkerConstant
            : ''
      },
      rightbarBenchmark: {
        benchmarkCalculation:
          values?.rightTargetMarker === TARGET_MARKERS_CALCULATED ? values?.rightTargetMarkerCalculate : '',
        benchmarkLabel:
          values?.rightTargetMarker === TARGET_MARKERS_CONSTANT
            ? values?.rightMarkerLabel
            : '',
        benchmarkMarker: values?.rightTargetMarker,
        benchmarkValue:
          values?.rightTargetMarker === TARGET_MARKERS_CONSTANT
            ? values?.rightMarkerConstant
            : ''
      },
      value: values?.value,
      timePeriod: values?.timePeriod,
      barMaximumCalculationType:
        values?.barMax === MaxCalculated ? values?.barCalculatedValue : '',
      barMaximumType: values?.barMax,
      barMaximumValue:
        values?.barMax === MaxConstant ? values?.barConstantValue : '',
      barMinimum: values?.barMin
    },
    userId: userInfo?.id,
    accountId: objectPickerValue?.accountId,
    buildingId: objectPickerValue?.buildingId,
    salesOfficeId: objectPickerValue?.salesOfficeId
  }
  if ([ACTIONS.ADD,ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}
export const getDataComparisonWidgetUpsertMutationInputs = (
  values,
  userInfo,
  objectPickerSelectedValue,
  mode
) => {
  const objectPickerValue1 = objectPickerSelectedValue?.find(o=>o?.equipmentName === values?.leftBarEquipment)
  const objectPickerValue2 = objectPickerSelectedValue?.find(o=>o?.equipmentName === values?.rightBarEquipment)
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.link,
    widgetType: WidgetNames.DataComparisonBarChart,
    widgetBuildingId: objectPickerValue2?.buildingId,
    widgeEquipment1Id: objectPickerValue1?.equipmentId ?? null,
    widgeEquipment1Name: objectPickerValue1?.equipmentName,
    widgeEquipment1Type: objectPickerValue1?.equipmentType,
    widgeEquipment1Family: objectPickerValue1?.equipmentFamily,
    widgetEquipment2Id: objectPickerValue2?.equipmentId ?? null,
    widgeEquipment2Name: objectPickerValue2?.equipmentName,
    widgeEquipment2Type: objectPickerValue2?.equipmentType,
    widgeEquipment2Family: objectPickerValue2?.equipmentFamily,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetTargetType: 'location',
    equipmentDataComparisionBarWidget: {
      colourConfig: {
        leftBar: values?.leftBarColor,
        rightBar: values?.rightBarColor,
        leftBenchmark:
          values?.leftTargetMarker !== TARGET_MARKERS_NONE
            ? values?.leftBenchmarkColor
            : TARGET_COLOR,
        rightBenchmark:
          values?.rightTargetMarker !== TARGET_MARKERS_NONE
            ? values?.rightBenchmarkColor
            : TARGET_COLOR
      },
      leftBarProperty: {
        benchmarkCalculation:
          values?.leftTargetMarker === TARGET_MARKERS_CALCULATED ? values?.leftTargetMarkerCalculate : '',
        benchmarkLabel:
          values?.leftTargetMarker === TARGET_MARKERS_CONSTANT
            ? values?.leftMarkerLabel
            : '',
        benchmarkMarker: values?.leftTargetMarker,
        benchmarkValue:
          values?.leftTargetMarker === TARGET_MARKERS_CONSTANT
            ? values?.leftMarkerConstant
            : '',
        property: values?.leftProperty,
        uom: values?.leftUnitsOfMeasure,
        symbol: values?.leftSymbol
      },
      rightBarProperty: {
        benchmarkCalculation:
          values?.rightTargetMarker === TARGET_MARKERS_CALCULATED ? values?.rightTargetMarkerCalculate : '',
        benchmarkLabel:
          values?.rightTargetMarker === TARGET_MARKERS_CONSTANT
            ? values?.rightMarkerLabel
            : '',
        benchmarkMarker: values?.rightTargetMarker,
        benchmarkValue:
          values?.rightTargetMarker === TARGET_MARKERS_CONSTANT
            ? values?.rightMarkerConstant
            : '',
        property: values?.rightProperty,
        uom: values?.rightUnitsOfMeasure,
        symbol: values?.rightSymbol
      },
      value: values?.value,
      timePeriod: values?.timePeriod,
      barMaximumCalculationType:
        values?.barMax === MaxCalculated ? values?.barCalculatedValue : '',
      barMaximumType: values?.barMax,
      barMaximumValue:
        values?.barMax === MaxConstant ? values?.barConstantValue : '',
      barMinimum: values?.barMin
    },
    userId: userInfo?.id,
    accountId: objectPickerValue1?.accountId,
    buildingId: objectPickerValue1?.buildingId,
    salesOfficeId: objectPickerValue1?.salesOfficeId
  }
  if ([ACTIONS.ADD,ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}
export const getTrendBarChartWidgetUpsertMutationInputs = (
  values,
  userInfo,
  objectPickerSelectedValue,
  mode
) => {
  const objectPickerValue = objectPickerSelectedValue[0]
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.link,
    widgetType: WidgetNames.TrendBarChart,
    widgetBuildingId: objectPickerValue?.buildingId,
    widgeEquipment1Id: objectPickerValue?.equipmentId ?? null,
    widgeEquipment1Name: objectPickerValue?.equipmentName,
    widgeEquipment1Type: objectPickerValue?.equipmentType,
    widgeEquipment1Family: objectPickerValue?.equipmentFamily,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetTargetType: 'location',
    equipmentTrendBarWidget: {
      colourConfig: {
        leftBar: values?.barColor,
        leftBenchmark: values?.benchmarkColor
      },
      property: {
        property: values?.property,
        uom: values?.unitsOfMeasure,
        symbol: values?.symbol
      },
      timeInterval: values?.timeInterval,
      timePeriod: values?.timePeriod,
      value: values?.value,
      benchMark: {
        benchmarkMarker: values?.targetMarker,
        benchmarkValue:
          values?.targetMarker === TARGET_MARKERS_CONSTANT
            ? values?.markerConstantValue
            : '',
        benchmarkLabel:
          values?.targetMarker === TARGET_MARKERS_CONSTANT
            ? values?.benchMarkTargetLabel
            : ''
      }
    },
    userId: userInfo?.id,
    accountId: objectPickerValue?.accountId,
    buildingId: objectPickerValue?.buildingId,
    salesOfficeId: objectPickerValue?.salesOfficeId
  }
  if ([ACTIONS.ADD,ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}
export const getOrbWidgetUpsertMutationInputs = (
  values,
  userInfo,
  objectPickerSelectedValue,
  mode
) => {
  const objectPickerValue = objectPickerSelectedValue[0]
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.link,
    widgetType: WidgetNames.Orb,
    widgetBuildingId: objectPickerValue?.buildingId,
    widgeEquipment1Id: objectPickerValue?.equipmentId ?? null,
    widgeEquipment1Name: objectPickerValue?.equipmentName,
    widgeEquipment1Type: objectPickerValue?.equipmentType ,
    widgeEquipment1Family: objectPickerValue?.equipmentFamily,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetTargetType: 'location',
    equipmentDataMonitorOrbWidget: {
      colourConfig: {
        baseline: values?.baselineColorValue,
        thresholdOption: values?.thresholdsValue,
        threshold1: values?.threshold1Value,
        threshold1Value: values?.threshold1ConstantValue,
        threshold2: values?.threshold2Value,
        threshold2Value: values?.threshold2ConstantValue
      },
      property: {
        property: values?.property,
        uom: values?.unitsOfMeasure,
        symbol: values?.symbol
      },
      Value: values?.needleValue,
      timePeriod: values?.timePeriod
    },
    userId: userInfo?.id,
    accountId: objectPickerValue?.accountId,
    buildingId: objectPickerValue?.buildingId,
    salesOfficeId: objectPickerValue?.salesOfficeId
  }
  if ([ACTIONS.ADD,ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}
const getUserName = (userInfo) => {
  return `${userInfo?.lastName} ${userInfo?.firstName}`
}

export const getWidgetDetails = (t, widgetType) => {
  const widgets = widgetTemplate
    .getAll(t)
    .map(({ title, description, name, widgetCategory }) => ({
      title,
      widgetCategory,
      type: name,
      value: title,
      key: name,
      name,
      desc: description
    }))
  const details = widgets?.find((w) => w?.name === widgetType)
  return details
}

export const getImageWidgetCreateMutationInputs = (
  values,
  buildingId,
  organizationId,
  salesOfficeId,
  userInfo,
  mode
) => {
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.link,
    widgetType: WidgetNames.ImageText,
    buildingId,
    widgetBuildingId: buildingId,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    widgetTargetType: 'location',
    imageText: values?.imageTitle,
    imageAdditionalText: values?.imageDescription,
    userId: userInfo?.id,
    accountId: organizationId,
    salesOfficeId
  }
  if ([ACTIONS.ADD,ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}
const getCustomKPIDetails = (values) => {
  const customKPIConfig = {
    kpiMeasurement: values?.selectedKpiMeasurement,
    kpiPlotType: values?.isPointInTime === '1' ? true : false
  }
  if (customKPIConfig.kpiPlotType) {
    (customKPIConfig['kpiPreviousPeriod'] = values?.previousPeroid),
      (customKPIConfig['kpiCurrentPeriod'] = values?.currentPeriod),
      (customKPIConfig['kpiGoal'] = values?.goalCurrentPeriod),
      (customKPIConfig['timePeriod'] = values?.selectedTimePeriod)
  } else {
    (customKPIConfig['kpiDisplayDate'] = values?.selectedDisplayTimeDate),
      (customKPIConfig['kpiTrend'] = getKPITrend(values?.trendData))
  }
  return customKPIConfig
}
const getKPITrend = (trendData) => {
  if (!trendData) return
  const trendList = []
  for (const key in trendData) {
    const trend = trendData[key]
    trend?.forEach((item) => {
      const currentDt = moment().year(Number(key)).format(BACKEND_DATE_FORMAT)
      item['date'] = currentDt
      trendList.push(item)
    })
  }
  return trendList
}
export const getKPITrendData = (trend) => {
  const trendData = {}
  const trendDetails = _groupBy(trend, 'date') ?? []
  for (const key in trendDetails) {
    const year = moment(key).year()
    trendData[year] = trendDetails[key]?.map((t) => ({
      goal: t?.goal?.toString(),
      actual: t?.actual?.toString()
    }))
  }
  return trendData
}
export const getCustomKPIWidgetCreateMutationInputs = (
  values,
  buildingId,
  organizationId,
  salesOfficeId,
  userInfo,
  widgetBuildingId,
  mode
) => {
  const input = {
    name: values?.name,
    description: values?.description ?? '',
    widgetLink: values?.link,
    widgetType: WidgetNames.CustomKPI,
    widgetBuildingId,
    isNewWindow: (values?.isNewWindow === true || values?.isNewWindow === '1') ? true : false,
    equipmentCustomKpiWidget: getCustomKPIDetails(values),
    widgetTargetType: 'location',
    userId: userInfo?.id,
    accountId: organizationId,
    buildingId: widgetBuildingId,
    salesOfficeId
  }
  if ([ACTIONS.ADD,ACTIONS.COPY].includes(mode)) {
    input['creationDate'] = moment().format(BACKEND_DATE_FORMAT)
    input['createdBy'] = getUserName(userInfo)
  }
  if (mode === ACTIONS.EDIT) input['id'] = values?.id
  return input
}

export const KPI_MEASUREMENTS_DATA = new Map([
  ['energyStarScore', { icon: 'energy-star' }],
  ['truckRolls', { icon: 'truck' }],
  ['hotColdCalls', { icon: 'comfort' }],
  ['downtime', { icon: 'performance' }],
  ['carbonFootprint', { icon: 'emissions' }]
])

export const STD_TIME_PERIOD_GROUP_5 = [
  ':stp/current-month',
  ':stp/current-quarter',
  ':stp/current-year',
  ':stp/past-30-days',
  ':stp/past-90-days',
  ':stp/past-12-months'
]

export const STD_TIME_PERIOD_GROUP_6 = [
  ':stp/all-time-available',
  ':stp/last-12-months',
  ':stp/year-to-date'
]
export function getKpiMeasurementsList(translateFunc) {
  const kpiMeasurementsList = []
  KPI_MEASUREMENTS_DATA?.forEach((element, index) => {
    kpiMeasurementsList.push({
      key: index,
      value: translateFunc(`widgets:${index}`)
    })
  })
  return kpiMeasurementsList
}
export function getTimePeriodList(translateFunc) {
  const timePeriodList = []
  STD_TIME_PERIOD_GROUP_5?.forEach((element) => {
    timePeriodList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return timePeriodList
}
const ENERGY_CONSUMPTION_WIDGET_LINK =(widgetType) => [
  'None',
  widgetType === WidgetNames.EnergyConsumption ? 'EnergyConsumptionChart' :
  widgetType === WidgetNames.EnergyCost ? 'EnergyCostChart':
  widgetType === WidgetNames.EnergyDemand ? 'ElectricalDemandChart' : '' ,
  'CustomURL'
]
export function getEnergyWidgetLinkList(translateFunc,type) {
  const widgetLinkList = []
  ENERGY_CONSUMPTION_WIDGET_LINK(type)?.forEach((element) => {
    widgetLinkList.push({
      key: element,
      value: translateFunc(`widget-builder:${element}`)
    })
  })
  return widgetLinkList
}

export function getDisplayTimeDateList(translateFunc) {
  const displayTimeDateList = []
  STD_TIME_PERIOD_GROUP_6?.forEach((element) => {
    displayTimeDateList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return displayTimeDateList
}
export const DEFAULT_TIME_INTERVAL = ':ti/1-hour'
export function getDisplayItems(
  list: any[],
  formatFunc: { (id: any): any; (arg0: any): any }
) {
  return list.map((el) => {
    return {
      key: el,
      value: formatFunc(el)
    }
  })
}
export const CALCULATED_OPTIONS = [
  ':sc/peak-last-30-days',
  ':sc/peak-last-12-months',
  ':sc/peak-mtd',
  ':sc/peak-ytd',
  ':sc/sum-mtd',
  ':sc/sum-today'
]
export function getCalculatedOptionsList(translateFunc) {
  const calculatedList = []
  CALCULATED_OPTIONS?.forEach((element) => {
    calculatedList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return calculatedList
}

export const TARGET_MARKERS_CALCULATED_OPTION = [
	":sc/avg-last-30-days",
	":sc/avg-30-days-same-time"
]
export function getBenchMarkCalculatedOptionsList(translateFunc) {
  const calculatedList = []
  TARGET_MARKERS_CALCULATED_OPTION?.forEach((element) => {
    calculatedList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return calculatedList
}
export const MaxConstant = ':dataRange/constant'
export const MaxCalculated = ':dataRange/calculated'

export const MAXIMUM_OPTIONS = [MaxConstant, MaxCalculated]
export function getMaximumOptionsList(translateFunc) {
  const maxList = []
  MAXIMUM_OPTIONS?.forEach((element) => {
    maxList.push({
      key: element,
      value: translateFunc(`widget-builder>${element}`, { nsSeparator: '>' })
    })
  })
  return maxList
}
export const LATEST_VALUE = ':sc/latest'
export const STD_CALC_GROUP_2_INITIAL = ':sc/average'
export const STD_CALC_GROUP_1 = [
  ':sc/latest',
  ':sc/average',
  ':sc/peak',
  ':sc/sum',
  ':sc/median',
  ':sc/min'
]

export const STD_CALC_GROUP_2 = [
  ':sc/average',
  ':sc/peak',
  ':sc/sum',
  ':sc/median',
  ':sc/min'
]
export function getNeedleOptionsList(translateFunc) {
  const needleList = []
  STD_CALC_GROUP_1?.forEach((element) => {
    needleList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return needleList
}
export function getValueOptionsList(translateFunc) {
  const valueList = []
  STD_CALC_GROUP_2?.forEach((element) => {
    valueList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return valueList
}
export const TimePeriodCurrentDay = ':stp/current-day'
export const CpTimePeriod = ':cp/today-yesterday'
export const STD_TIME_PERIOD_GROUP_1 = [
  ':stp/current-day',
  ':stp/current-week',
  ':stp/current-month',
  ':stp/current-quarter',
  ':stp/current-year',
  ':stp/past-hour',
  ':stp/past-24-hours',
  ':stp/past-7-days',
  ':stp/past-14-days',
  ':stp/past-30-days',
  ':stp/past-90-days',
  ':stp/past-12-months'
]
export const BASELINE_DEFAULT_TIME_PERIOD = ":stp/total"
export const STD_TIME_PERIOD_GROUP_4 = [
	":stp/current-week",
	":stp/current-month",
	":stp/current-quarter",
	":stp/current-year",
	":stp/previous-week",
	":stp/previous-month",
	":stp/previous-quarter",
	":stp/previous-year-full",
	":stp/total"
]

export const CP_TIME_PERIOD = [
	":cp/today-yesterday",
	":cp/this-week-last-week",
	":cp/this-month-last-month",
	":cp/this-quarter-last-quarter",
	":cp/this-year-last-year"
]
export function getTimePeriodOptionsList(translateFunc) {
  const timePeriodList = []
  STD_TIME_PERIOD_GROUP_1?.forEach((element) => {
    timePeriodList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return timePeriodList
}
export function getBaselineTimePeriodOptionsList(translateFunc) {
  const timePeriodList = []
  STD_TIME_PERIOD_GROUP_4?.forEach((element) => {
    timePeriodList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return timePeriodList
}
export function getTimePeriodOptionsListForTimeComparison(translateFunc) {
  const timePeriodList1 = []
  CP_TIME_PERIOD?.forEach((element) => {
    timePeriodList1.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return timePeriodList1
}
export const THRESHOLD_CONSTANT = ':std-threshold/constant'
export const THRESHOLD_PERCENTAGE = ':std-threshold/percentage'
export const THRESHOLD_NONE = ':std-threshold/no-threshold'

export const COLORPICKER_PRIMARY_COLORS = [
  '#61AE34',
  '#FFF10B',
  '#D52B1E',
  '#00B9E4',
  '#FB9A09',
  '#501C8B',
  '#703915',
  '#959595'
]
export const COLORPICKER_SECONDARY_COLORS = [
  '#DAFFA7',
  '#FFF787',
  '#FEA7AA',
  '#CFD5E8',
  '#FFEEC1',
  '#D5C5E3',
  '#EFD4B8',
  '#EFEFEF',
  '#B7FF5B',
  '#E1D47B',
  '#FD575D',
  '#6C97BF',
  '#FEDE87',
  '#AB90CB',
  '#C89146',
  '#BABABA',
  '#89C506',
  '#AB9A1F',
  '#E10018',
  '#326089',
  '#FECF54',
  '#865EB0',
  '#A06D30',
  '#6C6C6C',
  '#367326',
  '#817B14',
  '#8C0000',
  '#203854',
  '#D7770C',
  '#432762',
  '#6D3415',
  '#3C3C3C'
]

export const THRESHOLDS = new Map([
  [THRESHOLD_CONSTANT, 'Constant Values'],
  [THRESHOLD_PERCENTAGE, 'Percentage of Max'],
  [THRESHOLD_NONE, 'None']
])

export const THRESHOLDS_OPTION_LIST = Array.from(
  THRESHOLDS,
  ([key, value]) => ({
    key,
    value
  })
)
export function getThresholdsList() {
  return THRESHOLDS_OPTION_LIST.map(({ key, value }) => ({
    key,
    value
  }))
}
export function getOrbThresholdsList() {
  return THRESHOLDS_OPTION_LIST?.filter(
    (k) => k?.key !== THRESHOLD_PERCENTAGE
  ).map(({ key, value }) => ({
    key,
    value
  }))
}

export function getThresholdValue(value) {
  return THRESHOLDS.get(value)
}
export const getFileForUpsert = (existingFile, currentFile, mode) => {
  // Getting new file
  const newFile =
    mode === ACTIONS.COPY
      ? currentFile
      : existingFile?.name !== currentFile?.name
      ? currentFile
      : null
  // Getting deleting file
  const deleteFile =
    mode === ACTIONS.COPY
      ? null
      : existingFile?.name !== currentFile?.name
      ? existingFile
      : null
  // Getting updating file
  const updateFile =
    mode === ACTIONS.COPY
      ? null
      : currentFile?.name !== newFile?.name &&
        currentFile?.name !== deleteFile?.name
      ? currentFile
      : null
  return { newFile, deleteFile, updateFile }
}

export const isPresentObject = (arr, obj) => {
  let isPresent = false
  for (const k in obj) if (arr.includes(k)) isPresent = true
  return isPresent
}

export const WidgetsIcons = {
  [ORB]: 'icon-orbwidget',
  [LINE]: 'icon-linewidget',
  [WEATHER]: 'icon-weatherwidget',
  [GAUGE]: 'icon-dialwidget',
  [BAR]: 'icon-bar3widget',
  [DUAL_COMPARISON_BAR]: 'icon-bar2widget',
  [SINGLE_COMPARISON_BAR]: 'icon-bar1widget',
  [IMAGE_TEXT] : 'icon-text-image-link',
  [ENERGY_CONSUMPTION]: 'icon-enegy-consumption',
  [ENERGY_DEMAND]: 'icon-linewidget',
  [ENERGY_STAR_SCORE]: 'icon-energy-star',
  [CUSTOM_KPI]: 'icon-customkpiwidget',
  defaultIcon: 'icon-widget-type'
}

export const TargetTypeNames = {
  ACIR: { icon: 'solar-arrayobject', name: 'Air Compressor' },
  AHU: { icon: 'ahu', name: 'Air Handling Unit' },
  BinaryPoint: { icon: 'binarypoint', name: 'Binary Point' },
  'Binary Point': { icon: 'binarypoint', name: 'Binary Point' },
  Boiler: { icon: 'boiler', name: 'Boiler' },
  CDWP: { icon: 'condwaterplant', name: 'Condenser Water Plant' },
  Chiller: { icon: 'chiller', name: 'Chiller' },
  Circuit: { icon: 'circuit', name: 'Refrigerant Circuit' },
  Compressor: { icon: 'vavbox', name: 'Refrigerant Compressor' },
  CP: { icon: 'chillerplant', name: 'Chiller Plant' },
  CT: { icon: 'coolingtower', name: 'Cooling Tower' },
  CTC: { icon: 'coolingtowercell', name: 'Cooling Tower Cell' },
  CWS: { icon: 'chilledwatersys', name: 'Chilled Water System' },
  DL: { icon: 'distributionloop', name: 'Distribution Loop' },
  ElectricMeter: { icon: 'electric', name: 'Electric Meter' },
  EnergyMeter: { icon: 'general-meter1object', name: 'Energy Meter' },
  ENMET: { icon: 'general-meter1object', name: 'Energy Meter' },
  EnvironmentMeter: { icon: 'environment-meter', name: 'Environment Meter' },
  FAN: { icon: 'fan', name: 'Fan' },
  GAS: { icon: 'gas', name: 'Gas Meter' },
  HP: { icon: 'heating-plant', name: 'Heating Plant' },
  HS: { icon: 'heating-system', name: 'Heating System' },
  HX: { icon: 'heat-exchanger', name: 'Heat Exchanger' },
  LoadValve: { icon: 'loadvalves', name: 'Load Valves' },
  MultiStatePoints: { icon: 'multistatepoint', name: 'MultiState Point' },
  Points: { icon: 'analogpoint', name: 'Analog Point' },
  Pump: { icon: 'pump', name: 'Pump' },
  PumpBank: { icon: 'pumpbank', name: 'Pump Bank' },
  SCU: { icon: 'spacecomfortunit', name: 'Space Comfort Unit' },
  VAS: { icon: 'vas', name: 'VAV Air System' },
  'VAV-BOX': { icon: 'vavbox', name: 'VAV Box' },
  VFD: { icon: 'varfreqdrive', name: 'Variable Frequency Drive' },
  VirtualMeter: { icon: 'virtual-meter', name: 'Virtual Meter' },
  WaterFlow: { icon: 'water', name: 'Water Flow' },
  WeatherStation: { icon: 'weatherwidget', name: 'Weather Station' },
  organization: { icon: 'organization', name: 'Organization' },
  location: { icon: 'location', name: 'Location' },
  none: { icon: '' }
}

export const getType = (type) => {
  if (TargetTypeNames.hasOwnProperty(type)) {
    return TargetTypeNames[type]
  } else {
    return { icon: 'other', name: type }
  }
}

export const listComparisonPickerValues = (picker) => {
  const pickerValues = _sortBy(picker, 'equipmentName')
  return pickerValues.map((pickerValue) => {
    return {
      ...pickerValue,
      key: pickerValue.equipmentName,
      value: pickerValue.equipmentName
    }
  })
}

// This method used to format the widget response to inline with object picker data
// Object picker accepts this data structure 
export const getFormattedResponseData = (mode, widgetDetails, isBuildingAlone = false) => {
  const objectPickerData = []
  if ([ACTIONS.EDIT, ACTIONS.COPY].includes(mode) && widgetDetails) {
    const formatEquipment1Data = {
      accountId: widgetDetails.accountId,
      accountName: "",
      buildingId: widgetDetails.buildingId,
      buildingName: widgetDetails?.building?.name,
      salesOfficeId: widgetDetails.salesOfficeId
    }
    let formatEquipment2Data = null
    if (widgetDetails.widgetEquipment2Id) {
      formatEquipment2Data = {
        accountId: widgetDetails?.accountId,
        accountName: "",
        buildingId: widgetDetails?.widgetBuildingId,
        buildingName: widgetDetails?.widgetBuildingTarget?.name,
        salesOfficeId: widgetDetails?.salesOfficeId
      }
    }
    if (!isBuildingAlone) {
      formatEquipment1Data["checked"] = true,
        formatEquipment1Data["equipmentFamily"] = widgetDetails?.widgeEquipment1Family ,
        formatEquipment1Data["equipmentId"] = widgetDetails?.widgeEquipment1Id,
        formatEquipment1Data["equipmentName"] = widgetDetails?.widgeEquipment1Name,
        formatEquipment1Data["equipmentType"] = widgetDetails?.widgeEquipment1Type
      objectPickerData.push(formatEquipment1Data)
      if (widgetDetails.widgetEquipment2Id &&  widgetDetails?.widgeEquipment1Id !==  widgetDetails?.widgetEquipment2Id) {
        formatEquipment2Data["checked"] = true,
          formatEquipment2Data["equipmentFamily"] = widgetDetails?.widgeEquipment2Family,
          formatEquipment2Data["equipmentId"] = widgetDetails?.widgetEquipment2Id,
          formatEquipment2Data["equipmentName"] = widgetDetails?.widgeEquipment2Name,
          formatEquipment2Data["equipmentType"] = widgetDetails?.widgeEquipment2Type  
        objectPickerData.push(formatEquipment2Data)
      }
    }else{
      objectPickerData.push(formatEquipment1Data)
    }
    // returning as array to inline with rootdata from object-picker
    return objectPickerData
  }
}

export const WIDGET_TABS_NAME = {
  OVERVIEW:'Overview',
  CONFIGURATION:'Configuration',
  COLORS:'Colors',
  PREVIEW:'Preview',
} 

export const ENERGY_CONSUMPTION_UOMS = [
	{
		"name": "kiloWattHour",
		"displayName": "Kilo Watt Hour",
		"symbol": "kWh"
	},
	{
		"name": "megaWattHour",
		"displayName": "Mega Watt Hour",
		"symbol": "MWh"
	},
	{
		"name": "btu",
		"displayName": "Btu",
		"symbol": "btu"
	},
	{
		"name": "kiloBtu",
		"displayName": "Kilo Btu",
		"symbol": "kbtu"
	},
	{
		"name": "megaBtu",
		"displayName": "Mega Btu",
		"symbol": "Mbtu"
	}
]

export function getEnergyUOMList(translateFunc) {
  const uomList = []
  ENERGY_CONSUMPTION_UOMS?.forEach((element) => {
    uomList.push({
      key: element?.displayName,
      value: element?.displayName
    })
  })
  return uomList
}

export const UOM_DEFAULT_VALUE = 'Kilo Watt Hour'
export const TIME_CURRENT_DAY = ':stp/current-day'
export const TIME_PAST_3_DAYS = ':stp/past-3-days'
export const TIME_PAST_7_DAYS = ':stp/past-7-days'
export const TIME_PAST_3_MONTHS = ':stp/past-3-months'
export const TIME_PAST_6_MONTHS = ':stp/past-6-months'
export const NONE = ':none'

export const STD_TIME_COMPARED_TO = new Map([
	[":stp/current-day", [
		":none",
		":stp/today-previous-day-full-compared",
		":stp/today-previous-week-full-compared",
		":stp/today-previous-month-full-compared",
		":stp/today-previous-year-full-compared",
	]],
	[":stp/past-3-days", [
		":none",
		":stp/past-3-days-week-full-compared",
		":stp/past-3-days-month-full-compared",
		":stp/past-3-days-year-full-compared",
	]],
	[":stp/past-7-days", [
		":none",
		":stp/past-7-days-week-full-compared",
		":stp/past-7-days-month-full-compared",
		":stp/past-7-days-year-full-compared",
	]],
	[":stp/current-month", [
		":none",
		":stp/previous-month",
		":stp/current-month-previous-year-full",
	]],
	[":stp/previous-quarter", [
		":none",
		":stp/previous-previous-quarter-full-compared",
		":stp/previous-quarter-year-full-compared",
	]],
	[":stp/past-3-months", [":none", ":stp/past-3-months-year-full-compared"]],
	[":stp/past-6-months", [":none", ":stp/past-6-months-year-full-compared"]],
	[":stp/current-year", [":none", ":stp/previous-year-full"]]
])

export const getTimeCompareList = (translateFunc) => Array.from(
  STD_TIME_COMPARED_TO,
  ([key, value]) => ({
    key,
    value: translateFunc(`time>${key}`, { nsSeparator: '>' }) || key
  })
)

export const getTimeComparedToList = (translateFunc,value) => {
  const comparedToList = STD_TIME_COMPARED_TO.get(value)
  const timeComparedList = []
  comparedToList?.forEach((element) => {
    timeComparedList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return timeComparedList
 }

 export const DAY_TIME_PERIOD_GROUP = [
	":stp/past-hour",
	":stp/past-4-hours",
	":stp/past-12-hours",
	":stp/past-24-hours"
]
export const TIME_PAST_HOUR = ':stp/past-hour'
export const getEnergyDemandTimePeriodList = (translateFunc) => {
  const periodList = []
  DAY_TIME_PERIOD_GROUP?.forEach((element) => {
    periodList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return periodList
 }

 export const DAY_TIME_PEAK_PERIOD_GROUP = [
	":none",
	":peak-past-30-days",
	":peak-current-month",
	":peak-past-11-months"
]

export const getEnergyDemandCompareToList = (translateFunc) => {
  const compareToList = []
  DAY_TIME_PEAK_PERIOD_GROUP?.forEach((element) => {
    compareToList.push({
      key: element,
      value: translateFunc(`time>${element}`, { nsSeparator: '>' })
    })
  })
  return compareToList
 }

 export const hasEmptyRangeItem = (rateIncreases) => {
  return rateIncreases?.some(
    (i) => i.startTime === null || i.endTime === null
  )
}

export const hasEndTimeBeforeStart = (rateIncreases) => {
  const validRateIncreases = getValidRangePeriods(rateIncreases)
  return validRateIncreases?.some((i) => i.start >= i.end)
}

export const overlappingRangePeriods = (rateIncreases = []) => {
  const validRateIncreases = getValidRangePeriods(rateIncreases)
  const { overlap: isOverlap } = overlap(validRateIncreases)
  return isOverlap
}

const getValidRangePeriods = (rateIncreases) => {
  return rateIncreases?.reduce((data, current) => {
    const { startTime, endTime } = current
    if (startTime === null || endTime === null) {
      return data
    }

    const start = moment.utc(startTime, TIME_FORMAT)
    const end = moment.utc(endTime, TIME_FORMAT)

    data.push({
      ...current,
      start: start.valueOf(),
      end: end.valueOf()
    })

    return data
  }, [])
}

export const isValidRateIncrease = (rateIncrease,isRateIncrease, setFieldValue) => {
  let isNotValid = false
  const isEndTimeBeforeStart = hasEndTimeBeforeStart(rateIncrease)
  const isEmptyRangeItem = hasEmptyRangeItem(rateIncrease)
  const isOverlappingRangePeriods = overlappingRangePeriods(rateIncrease)
  let fieldValue = ''
  if (isRateIncrease === true || isRateIncrease === '1') {
    if (isEmptyRangeItem || isOverlappingRangePeriods || isEndTimeBeforeStart) {
      isNotValid = true
      if (isEmptyRangeItem) {
        fieldValue='timeRequired'
      }
      if (isOverlappingRangePeriods) {
        fieldValue='periodsOverlapping'
      }
      if (isEndTimeBeforeStart) {
        fieldValue='endTimeBeforeStart'
      }
    } 
    setTimeout(() => {
      setFieldValue('rateIncreaseErrorMsg', fieldValue)
    }, 10)
  }
  return isNotValid
}

export const isCorrectUrl = (text) => {
	const urlPattern = /^((http|https):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-\]\[]*[\w@?^=%&amp;\/~+#-\]\[])?$/i; // eslint-disable-line
	return urlPattern.test(text)
}

export const openLink = (link) => {
  let url = link

  if (isCorrectUrl(url)) {
    if (!isExternalURL(url)) {
      url = `//${url}`
    }

    window.open(url, "_blank")
  }
}

export const UOMS = {
	KWH: {
		name: "kiloWattHour",
		symbol: "kWh",
	},
	BTU: {
		name: "BTUs",
		symbol: "BTUs",
	},
	MMBTU: {
		name: "mmBTUs",
		symbol: "mmBTUs",
	},
	KBTU: {
		name: "kBTUs",
		symbol: "kBTUs",
	},
	DOLLAR: {
		name: "$ (Dollars)",
		symbol: "$"
	},
	CUBICFEET: {
		name: "cubicFeet",
		symbol: "ft³",
	},
}

export const WIDGETS_TILE_SORTING_LIST = [
  'widgetName',
  'description',
  'targetedObjectType',
  'oldestToNewest',
  'newestToOldest'
]

export function widgetTileSortingOptionList(translateFunc) {
  return WIDGETS_TILE_SORTING_LIST?.map((item) => ({
    key: item,
    value: translateFunc(`widgets:${item}`)
  }))
}

export const WIDGETS_TILE_FILTERING_LIST = [
  { transName: 'singleComparisonBarWidget', widgetType: "single-comparison-bar" },
  { transName: 'energyCostWidget', widgetType: "energy-cost" },
  { transName: 'energyConsumptionNewWidget', widgetType: "energy-consumption-new" },
  { transName: 'energyDemandWidget', widgetType: "energy-demand" },
  { transName: 'dualComparisonBarWidget', widgetType: "dual-comparison-bar" },
  { transName: 'energyConsumptionWidget', widgetType: "energy-consumption" },
  { transName: 'gaugeWidget', widgetType: "gauge" },
  { transName: 'orbWidget', widgetType: "orb" },
  { transName: 'imageTextWidget', widgetType: "image-text" },
  { transName: 'weatherWidget', widgetType: "weather" },
  { transName: 'barWidget', widgetType: "bar" },
  { transName: 'lineWidget', widgetType: "line" },
  { transName: 'customKPIWidget', widgetType: "custom-kpi" },
  { transName: 'energyStarScoreWidget', widgetType: "energy-star-score" }
]

const tileFilterWidgetTypes = (translateFunc, typeLists) => {
  const types = []
  typeLists?.map((data) => {
    const filteredTypes = WIDGETS_TILE_FILTERING_LIST?.filter((item) => {
      return item?.widgetType === data?.name
    })
    if (filteredTypes?.length !== 0) {
      types?.push({
        key: filteredTypes[0]?.transName,
        value: translateFunc(`widgets:${filteredTypes[0]?.transName}`)
      })
    }
  })
  return types?.sort((a, b) => a?.value?.localeCompare(b?.value))
}

const listFilterWidgetTypes = (translateFunc, typeLists, staticList) => {
  const types = []
  typeLists?.map((data) => {
    const filteredTypes = staticList?.filter((item) => {
      return item?.widgetType === data?.name
    })
    if (filteredTypes?.length !== 0) {
      types?.push({
        name: filteredTypes[0]?.originalName,
        value: filteredTypes[0]?.originalName
      })
    }
  })
  return types?.sort((a, b) => a?.name?.localeCompare(b?.name))
}
export function widgetTileFilteringOptionList(translateFunc, filterList) {
  return [...[{ key: "All", value: "All" }], ...tileFilterWidgetTypes(translateFunc, filterList)]
}

export function widgetListFilteringOptionList(translateFunc, filterList, staticList) {
  return [...listFilterWidgetTypes(translateFunc, filterList, staticList)]
}

export function getWidgetType(translateFunc, type) {
  const filteredTypes = WIDGETS_TILE_FILTERING_LIST?.filter((item) => {
    return item?.widgetType === type
  })
  if (filteredTypes?.length) {
    return translateFunc(`widgets:${filteredTypes[0]?.transName}`)
  } else {
    return type
  }
}

export const getLocationOrOrganizationIdByRedux = (selected) => {
  const isOrgType = selected?.type === 'organization'
  const isLocType = selected?.type === 'location'
  const buildingId = isLocType ? selected?.id : ''
  const organizationId = isOrgType ? selected?.id : ''
  return { buildingId, organizationId }
}

//Adding this to handle the translation of Widget Time Dropdown(EMEA branch changes)
export const comparisonList = [
  {
    value : "Current Date",
    key : ":stp/current-day",
    dependencies : [
      {
        value : "None",
        key : ":none"
      },
      {
        value : "Previous Day",
        key : ":stp/today-previous-day-full-compared"
      },
      {
        value : "Same Day Previous Week",
        key : ":stp/today-previous-week-full-compared"
      },
      {
        value : "Same Day Previous Month",
        key : ":stp/today-previous-month-full-compared"
      },
      {
        value : "Same Day Previous Year",
        key : ":stp/today-previous-year-full-compared"
      }
    ]
  },
  {
    value : "Past 3 Days",
    key : ":stp/past-3-days",
    dependencies : [
      {
        value : "None",
        key : ":none"
      },
      {
        value : "Same days previous weeks",
        key : ":stp/past-3-days-week-full-compared"
      },
      {
        value : "Same days previous months",
        key : ":stp/past-3-days-month-full-compared"
      },
      {
        value : "Same days previous Year",
        key : ":stp/past-3-days-year-full-compared"
      }
    ]
  },
  {
    value : "Past 7 Days",
    key : ":stp/past-7-days",
    dependencies : [
      {
        value : "None",
        key : ":none"
      },
      {
        value : "Same Days Previous weeks",
        key : ":stp/past-7-days-week-full-compared"
      },
      {
        value : "Same Days Previous months",
        key : ":stp/past-7-days-month-full-compared"
      },
      {
        value : "Same Days Previous Year",
        key : ":stp/past-7-days-year-full-compared"
      }
    ]
  },
  {
    value : "Current Month",
    key : ":stp/current-month",
    dependencies : [
      {
        value : "None",
        key : ":none"
      },
      {
        value : "Previous Month",
        key : ":stp/previous-month"
      },
      {
        value : "Same Month Previous Year",
        key : ":stp/current-month-previous-year-full"
      },
      
    ]
  },
  {
    value : "Previous Quarter",
    key : ":stp/previous-quarter",
    dependencies : [
      {
        value : "None",
        key : ":none"
      },
      {
        value : "Previous Previous Quarter",
        key : ":stp/previous-previous-quarter-full-compared"
      },
      {
        value : "Same Quarter Previous Year",
        key : ":stp/previous-quarter-year-full-compared"
      },
      
    ]
  },
  {
    value : "Past 3 months",
    key : ":stp/past-3-months",
    dependencies : [
      {
        value : "None",
        key : ":none"
      },
      {
        value : "Same Months Previous Year",
        key : ":stp/past-3-months-year-full-compared"
      }
    ]
  },
  {
    value : "Past 6 months",
    key : ":stp/past-6-months",
    dependencies : [
      {
        value : "None",
        key : ":none"
      },
      {
        value : "Same Months Previous Year",
        key : ":stp/past-6-months-year-full-compared"
      }
    ]
  },
  {
    value : "Current Year",
    key : ":stp/current-year",
    dependencies : [
      {
        value : "None",
        key : ":none"
      },
      {
        value : "Previous Year",
        key : ":stp/previous-year-full"
      }
    ]
  },
]

export const getTimePeriodListComparator = (list)=>{
  const listArray = list.map(el=>{
    return {
      key : el.key,
      value: el.value
    }
  })
    return listArray
}