import React, { useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { typographyFontFamily } from '../../ui-system/typography'

const PopoverRoot = styled.div`
  ${typographyFontFamily}
  z-index: 1;
`

const Popover = React.forwardRef(function Popover(inProps, ref) {
  const { referenceElementState, config = {}, children } = inProps
  const [popperElementState, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(
    referenceElementState,
    popperElementState,
    config
  )

  return (
    <PopoverRoot
      {...inProps}
      fontFamily="primary"
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      {children}
    </PopoverRoot>
  )
})

export default Popover
