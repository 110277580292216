import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Label from '../label/label'
import classNames from 'classnames'
import './index.scss'
import moment from 'moment-timezone'
import DatePicker from '../datepicker/datepicker'
import { TranslateComponent } from '../../../../common/translations'

export default class ModalDatePickerInput extends PureComponent {
  static propTypes = {
    hasError: PropTypes.bool,
    labelText: PropTypes.string,
    isRequired: PropTypes.bool,
    inputClass: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
    timezone: PropTypes.string,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    disabledTextBox: PropTypes.bool,
    isLoading: PropTypes.bool,
    selectMonth: PropTypes.bool,
    dateType: PropTypes.string,
    disableComponentErrorMessage: PropTypes.bool
  }

  static defaultProps = {
    disableComponentErrorMessage: false,
    hasError: false,
    labelText: '',
    isRequired: true,
    inputClass: '',
    timezone: 'America/Chicago',
    disabled: false,
    disabledTextBox: false,
    isLoading: false,
    dateType: ''
  }

  state = {
    calendarDate: moment()
  }

  render() {
    // REVISIT hasErrorOnlyTextbox and disabledTextBox
    const {
      hasError,
      labelText,
      isRequired,
      inputClass,
      onChange,
      date,
      timezone,
      errorMessage,
      disabled,
      disabledTextBox,
      hasErrorOnlyTextbox,
      isLoading,
      selectMonth,
      dateType,
      disableComponentErrorMessage,
      ...props
    } = this.props
    const { calendarDate } = this.state || {}

    return (
      <div className="modal-datepicker-wrapper" data-testid="datepicker-wrapper">
        {Boolean(labelText.length) && (
          <Label
            text={
              <span className={classNames({ 'has-error': hasError })}>
                <TranslateComponent>{labelText}</TranslateComponent>
                {Boolean(isRequired) && '*'}
              </span>
            }
          />
        )}
        <div className="date-container">
          <input
            data-testid="date-input"
            className={classNames(`modal-date-input ${inputClass}`, {
              'has-error': hasError || hasErrorOnlyTextbox
            })}
            type="text"
            value={date}
            onChange={onChange}
            disabled={disabled || disabledTextBox}
          />{' '}
          <div>
            {isLoading ? (
              <div className="icon spinner is-loading" />
            ) : selectMonth ? (
              <DatePicker
                update={(date) => {
                  this.setState({ calendarDate: date })
                  onChange(date)
                }}
                initialRange={{
                  start: moment(moment(date).isValid() ? date : calendarDate),
                  end: moment(moment(date).isValid() ? date : calendarDate)
                }}
                rangeSelection={{ past: false, future: false }}
                timezone={timezone}
                inputDate={date}
                selectMonth={selectMonth}
                dateType={dateType}
              />
            ) : (
              <DatePicker
                handleRangeChange={(date) => {
                  this.setState({ calendarDate: date })
                  onChange(date)
                }}
                initialRange={{
                  start: moment(moment(date).isValid() ? date : calendarDate),
                  end: moment(moment(date).isValid() ? date : calendarDate)
                }}
                rangeSelection={{ past: false, future: false }}
                timezone={timezone}
                inputDate={date}
                {...props}
              />
            )}
          </div>
        </div>
        <div className='combine-error-icon-message' data-testid="error-message">
          {hasError && !disableComponentErrorMessage &&<span className="icon icon-error" />}
          {hasError && (
            <span className="mini-error-text date-error-text">
              {errorMessage && <TranslateComponent>{errorMessage}</TranslateComponent> }
            </span>
          )}
        </div>
      </div>
    )
  }
}
