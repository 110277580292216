import { useState, useCallback, useEffect } from 'react'
import MultiTabForm from '../MultiTabTorm'
import { TabsWrapper } from '../../styles'
import { Tabs, Tab } from 'src/denali-ui/components/Tabs'
import {
  WidgetBuildingSchema,
  WidgetOverview,
  WidgetOverviewSchema
} from '../WidgetOverview'
import {
  TimeComparisonBarChartConfigurationSchema
} from './TimeComparisonBarChartConfiguration'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { TimeComparisionBarPreviewWrapper } from './TimeComparisionBarPreviewWrapper'
import {
  LATEST_VALUE,
  MODAL_TYPE,
  MaxConstant,
  CpTimePeriod ,
  WIDGET_TABS_NAME,
  getTimeComparisonWidgetUpsertMutationInputs
} from '../../helpers'
import { ACTIONS } from 'src/constants/actions'
import { TARGET_MARKERS_NONE } from 'src/common/target-markers'
import TimeComparisonBarChartColors from './TimeComparisonBarChartColors'
import {
  BAR1_COLOR,
  BAR2_COLOR,
  BAR2_TODATE_COLOR,
  TARGET_COLOR
} from '../../colors'
import { useMutation } from 'src/hooks/APIHooks'
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../graphql'
import { TimeComparisonBarChartConfiguration } from './TimeComparisonBarChartConfiguration'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import translate from 'src/common/translations'

const tabsConfig = (errors) => [
  {
    title: WIDGET_TABS_NAME.OVERVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.OVERVIEW,
    formikErrors: errors,
    validationSchema: WidgetOverviewSchema,
    component: <WidgetOverview />
  },
  {
    title: WIDGET_TABS_NAME.CONFIGURATION,
    disabled: false,
    key: WIDGET_TABS_NAME.CONFIGURATION,
    formikErrors: errors,
    validationSchema: TimeComparisonBarChartConfigurationSchema,
    component: <TimeComparisonBarChartConfiguration />
  },
  {
    title: WIDGET_TABS_NAME.COLORS,
    disabled: false,
    key: WIDGET_TABS_NAME.COLORS,
    formikErrors: errors,
    component: <TimeComparisonBarChartColors />
  },
  {
    title: WIDGET_TABS_NAME.PREVIEW,
    disabled: false,
    key: WIDGET_TABS_NAME.PREVIEW,
    formikErrors: errors,
    component: <TimeComparisionBarPreviewWrapper />
  }
]

const TimeComparisonBarChartForm = ({ setWidgetFormik }) => {
  const {
    widgetBuilding,
    setOpenWidgetPicker,
    userInfo,
    setReloadEvents,
    setIsLoading,
    mode,
    widgetDetails,
    setWidgetDetails,
    setSelectedWidget,
    setIsOpenCreateWidget,
    setModalType,
    formikErrors,
    objectPickerSelectedValue,
    setWidgetBuilding,
    setIsFormValidationAlone,
    setIsSubmitted,
    isFormValidationAlone,
    setWidgetId,
    isFooterChecked
  } = useWidgetsContext()
  const [activeTab, setActiveTab] = useState(0)
  const [error, setError] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const onSelectTab = useCallback((selectedTab: any, selectedTitle: any) => {
    setActiveTab(selectedTab)
    if (selectedTitle === WIDGET_TABS_NAME.PREVIEW){
      setIsFormValidationAlone(true)
      setIsSubmitted(false)
    }
  }, [])
  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: [ACTIONS.ADD,ACTIONS.COPY].includes(mode) ? CREATE_WIDGET : UPDATE_WIDGET,
    refreshTokenBuildingVariable: "input.buildingId",
    onError: (error) => {
      setError(error)
      setIsLoading(false)
    },
    onSuccess: (success) => {
      if([ACTIONS.ADD].includes(mode))
        {
          trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Create", type: "Time Comparison Bar Chart"})
        }
      else if([ACTIONS.COPY].includes(mode))
      {
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Copy", type: "Time Comparison Bar Chart"})
      }
      else{
        trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_WIDGET, {mode: "Edit", type: "Time Comparison Bar Chart"})
      }
    }
  })

  const submitForm = async (values) => {
    if (objectPickerSelectedValue?.length === 0 || !widgetBuilding?.id || isFormValidationAlone) return
    setIsLoading(true)
    const inputs = getTimeComparisonWidgetUpsertMutationInputs(
      values,
      userInfo,
      objectPickerSelectedValue,
      mode
    )
    await upsertWidgetMutation({ input: inputs }).then((response) => {
      const widgetId =
      [ACTIONS.ADD,ACTIONS.COPY].includes(mode)
          ? response.data?.createWidget?.id
          : response.data?.updateWidget?.id
      if (widgetId) {
        setIsLoading(false)
        setWidgetDetails(inputs)
        setOpenWidgetPicker(false)
        setReloadEvents(true)
        setModalType(MODAL_TYPE.SUCCESS)
        setSelectedWidget(null)
        setIsOpenCreateWidget(isFooterChecked ?? false)
        setWidgetId(widgetId)
      }
    })
  }
  const copyof = translate("Copy of")
  useEffect(() => {
    switch (mode) {
      case ACTIONS.ADD:
        setFormValues({
          id: '',
          name: '',
          description: '',
          link: '',
          isNewWindow: true,
          widgetBuilding: '',
          property: '',
          unitsOfMeasure: '',
          symbol: '',
          leftTargetMarker: TARGET_MARKERS_NONE,
          leftTargetMarkerCalculate:'',
          leftMarkerConstant: '',
          leftMarkerLabel: '',
          rightTargetMarker: TARGET_MARKERS_NONE,
          rightTargetMarkerCalculate:'',
          rightMarkerConstant: '',
          rightMarkerLabel: '',
          barMax: MaxConstant,
          barConstantValue: '',
          barCalculatedValue: '',
          barMin: '0',
          value: LATEST_VALUE,
          timePeriod: CpTimePeriod ,
          outerBarColor: BAR2_COLOR,
          innerBarColor: BAR2_TODATE_COLOR,
          rightBarColor: BAR1_COLOR,
          leftBenchmarkColor: TARGET_COLOR,
          rightBenchmarkColor: TARGET_COLOR
        })
        break
      case ACTIONS.EDIT:
      case ACTIONS.COPY:
        setFormValues({
          id: mode === ACTIONS.EDIT ? widgetDetails?.id : '',
          name: mode === ACTIONS.EDIT ? widgetDetails?.name : `${copyof} ${widgetDetails?.name}`,
          description: widgetDetails?.description ??'',
          link: widgetDetails?.widgetLink,
          isNewWindow: widgetDetails?.isNewWindow,
          property: widgetDetails?.equipmentTimeComparisionBarWidget?.property?.property,
          unitsOfMeasure: widgetDetails?.equipmentTimeComparisionBarWidget?.property?.uom,
          symbol: widgetDetails?.equipmentTimeComparisionBarWidget?.property?.symbol,
          leftTargetMarker: widgetDetails?.equipmentTimeComparisionBarWidget?.leftbarBenchmark?.benchmarkMarker,
          leftTargetMarkerCalculate: widgetDetails?.equipmentTimeComparisionBarWidget?.leftbarBenchmark?.benchmarkCalculation,
          leftMarkerConstant: widgetDetails?.equipmentTimeComparisionBarWidget?.leftbarBenchmark?.benchmarkValue,
          leftMarkerLabel: widgetDetails?.equipmentTimeComparisionBarWidget?.leftbarBenchmark?.benchmarkLabel,
          rightTargetMarker: widgetDetails?.equipmentTimeComparisionBarWidget?.rightbarBenchmark?.benchmarkMarker,
          rightTargetMarkerCalculate: widgetDetails?.equipmentTimeComparisionBarWidget?.rightbarBenchmark?.benchmarkCalculation,
          rightMarkerConstant: widgetDetails?.equipmentTimeComparisionBarWidget?.rightbarBenchmark?.benchmarkValue,
          rightMarkerLabel: widgetDetails?.equipmentTimeComparisionBarWidget?.rightbarBenchmark?.benchmarkLabel,
          barMax: widgetDetails?.equipmentTimeComparisionBarWidget?.barMaximumType,
          barConstantValue: widgetDetails?.equipmentTimeComparisionBarWidget?.barMaximumValue,
          barCalculatedValue: widgetDetails?.equipmentTimeComparisionBarWidget?.barMaximumCalculationType,
          barMin: widgetDetails?.equipmentTimeComparisionBarWidget?.barMinimum,
          value: widgetDetails?.equipmentTimeComparisionBarWidget?.value,
          timePeriod: widgetDetails?.equipmentTimeComparisionBarWidget?.timePeriod,
          outerBarColor: widgetDetails?.equipmentTimeComparisionBarWidget?.colourConfig?.leftOuterBar,
          innerBarColor: widgetDetails?.equipmentTimeComparisionBarWidget?.colourConfig?.leftInnerBar,
          rightBarColor: widgetDetails?.equipmentTimeComparisionBarWidget?.colourConfig?.rightBar,
          leftBenchmarkColor: widgetDetails?.equipmentTimeComparisionBarWidget?.colourConfig?.leftBenchmark ?? TARGET_COLOR,
          rightBenchmarkColor: widgetDetails?.equipmentTimeComparisionBarWidget?.colourConfig?.rightBenchmark ?? TARGET_COLOR,
        })
        setWidgetBuilding({ id: widgetDetails?.buildingId })
        break
    }
  }, [mode, copyof])

  const displayForm = () => {
    return mode !== ACTIONS.ADD ? formValues?.name : true
  }

  return (
    <>
      {displayForm() && (
        <MultiTabForm
          initialValues={formValues}
          onSubmit={submitForm}
          schemas={[
            WidgetOverviewSchema,
            WidgetBuildingSchema,
            TimeComparisonBarChartConfigurationSchema
          ]}
          setWidgetFormik={setWidgetFormik}
        >
          <TabsWrapper>
            <Tabs
              defaultActiveTab={activeTab}
              activeTab={activeTab}
              onSelect={onSelectTab}
            >
              {tabsConfig(formikErrors).map((item: any, key: number) => {
                return (
                  <Tab
                    title={item.title}
                    disabled={item.disabled}
                    key={key}
                    formikErrors={item?.formikErrors}
                    validationSchema={item?.validationSchema}
                  >
                    {item.component}
                  </Tab>
                )
              })}
            </Tabs>
          </TabsWrapper>
          <StandardDialogs error={error} onCloseDialog={() => setError(null)} />
        </MultiTabForm>
      )}
    </>
  )
}

export default TimeComparisonBarChartForm
