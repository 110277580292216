import * as d3 from 'd3'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { HEIGHT } from './constants'

import { isInvalidEl, convertRefToEl, formatDateByMoment } from './helpers'
import {
  IndicatorMarkup,
  attachIndicator,
  attachHoverEvents,
  hideIndicator,
  updateIndicator
} from './Indicator'
import localeConfig from './locale'

const locale_en = localeConfig['en']

type BrushPostion = {
  startTime: string
  endTime: string
  startEndPosition: number[]
  domain: number[]
}
type GetBrushPostionFunction = (postion: BrushPostion) => void

type BrushProps = {
  defaultStartTime: number
  defaultEndTime: number
  svgWidth: number
  activeStartTime?: number
  activeEndTime?: number
  resetBrush?: any
  getBrushPosition: GetBrushPostionFunction
}

export function Brush({
  defaultStartTime,
  defaultEndTime,
  activeStartTime,
  activeEndTime,
  resetBrush = '',
  svgWidth,
  getBrushPosition = () => null
}: BrushProps) {
  const brushContainerRef = useRef(null)
  const brushContainerElRef = useRef(null)

  const brushScaleRef = useRef(d3.scaleTime())
  const brushXRef = useRef(d3.brushX())
  const initialWidthRef = useRef(null)

  const lastPostionRef = useRef(null)

  const intervalRef = useRef(null)

  // Track brush activity (start / brusing / end) to toggle indicator
  const dateTimeDisplayRef = useRef(null)

  const dateRange = moment(defaultEndTime).diff(
    moment(defaultStartTime),
    'days'
  )
  const isMoreThanADay = dateRange > 1

  intervalRef.current =
    dateRange > 14 ? d3.timeDay.every(1) : d3.timeMinute.every(15)

  // let indicaotr use this locale via attr
  const currentLocale = isMoreThanADay
    ? locale_en.dateTimeShort
    : locale_en.minutesLong

  // svg defs should have uuid to improve performance
  const defsUUID = useMemo(() => {
    return +new Date() + Math.random()
  }, [])

  const handleBrushEnd = useCallback(
    function brushed(event) {
      if (!event.selection) return

      const selection = event.selection
      const [x0, x1] = selection.map(brushScaleRef.current.invert)

      lastPostionRef.current &&
        getBrushPosition({
          startTime: formatDateByMoment(x0),
          endTime: formatDateByMoment(x1),
          // with below properties, consumer of this component can find the values in the range
          startEndPosition: [...selection],
          domain: [0, svgWidth]
        })

      lastPostionRef.current = {
        start: x0,
        end: x1
      }

      // hide indicator if brush ends
      if (lastPostionRef.current) {
        dateTimeDisplayRef.current = 'end'
        hideIndicator(this)
      }
    },
    [brushScaleRef.current !== null, svgWidth]
  )

  let lastRange = []
  let direction = null

  const handleBrushMove = useCallback(
    function brushed(event) {
      if (!event.selection) return

      dateTimeDisplayRef.current = 'brush'

      if (event.sourceEvent) {
        const d0 = event.selection.map(brushScaleRef.current.invert)
        const d1 = d0.map(intervalRef.current.round)

        // finding out the current direction of the brush
        const isLeftMoved = +lastRange[0] !== +d1[0]
        const isRightMoved = +lastRange[1] !== +d1[1]
        const isBothMoved = isLeftMoved && isRightMoved

        direction = isBothMoved
          ? null
          : isLeftMoved
          ? 'left'
          : isRightMoved
          ? 'right'
          : direction

        // If empty when rounded, use floor instead.
        if (d1[0] >= d1[1]) {
          d1[0] = intervalRef.current.floor(d0[0])
          d1[1] = intervalRef.current.offset(d1[0])
        }

        const range = handleBrushGrabberMinMaxRange(
          event.selection,
          brushScaleRef.current.range(),
          d1,
          intervalRef.current,
          brushScaleRef.current
        )

        d3.select(this).call(
          brushXRef.current.move,
          range.map(brushScaleRef.current)
        )
        lastRange = d1
      }

      const updatedSelection = d3.brushSelection(this)

      d3.select(this).call(
        handleBrushGrabber,
        updatedSelection,
        defsUUID,
        '' + direction
      )
      const dateRange = updatedSelection.map(
        brushScaleRef.current.invert as any
      )

      d3.select(this).call(
        updateIndicator,
        updatedSelection,
        dateRange,
        direction
      )
    },
    [brushScaleRef.current !== null]
  )

  //----------------------------------- On Comp load, initialize brush and scale -----------------------------------

  useEffect(() => {
    // brush element reference is required. if not available, don't perform any other actions defined below

    if (
      isInvalidEl(brushContainerRef) ||
      !defaultStartTime ||
      !defaultEndTime
    ) {
      console.log(
        'Brush -> Init Reference -> Check : brushContainerRef property is missing'
      )
      return () => null
    }

    brushContainerElRef.current = convertRefToEl(brushContainerRef.current)

    // Define d3 scale fn to set min & max value for the brush

    const startDateInMS = +new Date()
    const endDateInMS = +new Date()

    brushScaleRef.current
      .domain([startDateInMS, endDateInMS])
      .range([0, svgWidth])

    // Define min & max area of drawable area of the brush

    brushXRef.current
      .extent([
        [brushScaleRef.current.range()[0], 0],
        [brushScaleRef.current.range()[1], HEIGHT]
      ])
      .on('start', () => {
        dateTimeDisplayRef.current = 'start'
      })
      .on('brush', handleBrushMove)
      .on('end', handleBrushEnd)

    // Attach the brush to container element (g) and postion it

    brushContainerElRef.current
      .call(brushXRef.current)
      .call(brushXRef.current.move, brushScaleRef.current.range())

    // hide the overlay element to disable draw functionality in the brush for the user

    brushContainerElRef.current
      .select('.overlay')
      .style('visibility', 'hidden')
      .style('pointer-events', 'none')

    // Attach indicator el
    attachIndicator(brushContainerElRef.current, defsUUID)

    // register events
    attachHoverEvents(brushContainerElRef.current, dateTimeDisplayRef)

    // Listen properties
  }, [defaultStartTime, defaultEndTime])

  //----------------------------------- On default start and end time change - update the brush -----------------------------------

  useEffect(() => {
    updateBrushAndPositionIt(defaultStartTime, defaultEndTime, true)

    // reset active start & end time when default time chanes
    if (
      defaultStartTime &&
      defaultEndTime &&
      activeStartTime &&
      activeEndTime
    ) {
      activeStartTime = null
      activeEndTime = null
    }

    // Listen properties
  }, [defaultStartTime, defaultEndTime, resetBrush])

  //----------------------------------- On active start and end time change - update the brush -----------------------------------

  useEffect(() => {
    updateBrushAndPositionIt(activeStartTime, activeEndTime)

    // Listen properties
  }, [activeStartTime, activeEndTime])

  //----------------------------------- On resize - update the range and brush drawable area. -----------------------------------

  useEffect(() => {
    if (!lastPostionRef.current) return () => null

    if (initialWidthRef.current === null) {
      initialWidthRef.current = svgWidth
      return () => null
    }

    if (initialWidthRef.current === svgWidth) return () => null

    updateBrushAndPositionIt(
      lastPostionRef.current.start,
      lastPostionRef.current.end,
      true
    )

    initialWidthRef.current = svgWidth

    // Listen properties
  }, [svgWidth])

  /**
   * function to update the brush and re-poistion it if required
   *
   * @param start
   * @param end
   * @param enableReposition
   * @returns void
   */
  function updateBrushAndPositionIt(start, end, enableReposition = false) {
    if (
      !start ||
      !end ||
      !brushScaleRef.current ||
      !brushXRef.current ||
      !brushContainerElRef.current
    ) {
      return
    }

    const startDate = formatDateByMoment(+start)
    const endDate = formatDateByMoment(+end)

    const startDateInMS = +new Date(startDate)
    const endDateInMS = +new Date(endDate)

    if (enableReposition) {
      brushScaleRef.current
        .domain([defaultStartTime, defaultEndTime])
        .range([0, svgWidth])

      brushXRef.current.extent([
        [0, 0],
        [svgWidth, HEIGHT]
      ])

      // Re-register brushXRef to update size of brush
      brushContainerElRef.current.call(brushXRef.current)

      lastPostionRef.current.svgWidth = svgWidth
    }

    brushContainerElRef.current.call(brushXRef.current.move, [
      brushScaleRef.current(startDateInMS),
      brushScaleRef.current(endDateInMS)
    ])
  }

  // ,
  /**
   * To render brush
   *  start & end time is must required value
   *  if not, dont render
   *
   *  */
  if (!defaultStartTime || !defaultEndTime) {
    return null
  }

  return (
    <g
      ref={brushContainerRef}
      className="brush"
      fill="url(#brushGradient)"
      data-time-format={currentLocale}
    >
      <defs>
        <linearGradient x1="0%" y1="0%" x2="0" y2="100%" id="brushGradient">
          <stop className="stop1" offset="0%"></stop>
          <stop className="stop2" offset="54%"></stop>
          <stop className="stop3" offset="100%"></stop>
        </linearGradient>

        <linearGradient x1="0%" y1="0%" x2="0" y2="100%" id="buttonGradient">
          <stop className="stop1" offset="0%"></stop>
          <stop className="stop2" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="0%" y1="0%" x2="0" y2="100%" id="timeGradient">
          <stop className="stop1" offset="0%"></stop>
          <stop className="stop2" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="0%" y1="0%" x2="0" y2="100%" id="grabberGradient">
          <stop className="stop1" offset="0%"></stop>
          <stop className="stop2" offset="100%"></stop>
        </linearGradient>

        {/* Grabber */}
        <g
          id={'leftgrabber' + defsUUID}
          className="grabber-w"
          transform="translate(0,0)"
          style={{ cursor: 'ew-resize' }}
        >
          <rect
            x="-3"
            width="6"
            height={HEIGHT}
            y="1"
            style={{ visibility: 'hidden' }}
          ></rect>
          <rect
            transform={`translate(0, ${HEIGHT / 2 - 12})`}
            width="8"
            height="24"
            className="grabber left "
            x="0"
            y="1"
            stroke="#206fcf"
            strokeOpacity="0.7"
          ></rect>
          <line x1="3" y1="23" x2="5" y2="23" stroke="#206fcf"></line>
          <line x1="3" y1="28" x2="5" y2="28" stroke="#206fcf"></line>
          <line x1="3" y1="33" x2="5" y2="33" stroke="#206fcf"></line>
        </g>

        <g
          id={'rightgrabber' + defsUUID}
          className="grabber-e"
          transform="translate(0,0)"
          style={{ cursor: 'ew-resize' }}
        >
          <rect
            x="-3"
            width="6"
            height={HEIGHT}
            y="1"
            style={{ visibility: 'hidden' }}
          ></rect>
          <rect
            transform={`translate(0, ${HEIGHT / 2 - 12})`}
            width="8"
            height="24"
            className="grabber right"
            x="-8"
            y="1"
            stroke="#206fcf"
            strokeOpacity="0.7"
          ></rect>
          <line x1="-5" y1="23" x2="-3" y2="23" stroke="#206fcf"></line>
          <line x1="-5" y1="28" x2="-3" y2="28" stroke="#206fcf"></line>
          <line x1="-5" y1="33" x2="-3" y2="33" stroke="#206fcf"></line>
        </g>
      </defs>
      <IndicatorMarkup defsUUID={defsUUID} />
    </g>
  )
}

// ------------------------------------- internal helpers -------------------------------------

/**
 * function to update grabber position
 * @param g
 * @param selection
 * @returns
 */
function handleBrushGrabber(g, selection, defsUUID, direction) {
  return g
    .selectAll('.grabber-block')
    .data([{ type: 'w' }, { type: 'e' }])
    .join('use')
    .attr('class', 'grabber-block')
    .attr(
      'href',
      (d) => (d.type === 'w' ? '#leftgrabber' : '#rightgrabber') + defsUUID
    )
    .attr('data-grabber-position', (d, i) =>
      d.type === 'w' ? 'left' : 'right'
    )
    .attr('y', 0)
    .attr('x', (d, i) => selection[i])
    .attr(
      'data-active',
      (d) =>
        (d.type == 'w' && direction === 'left') ||
        (d.type == 'e' && direction === 'right') ||
        (direction === null && false)
    )
    .attr('fill', function () {
      const isActive = d3.select(this).attr('data-active') == 'true'
      return isActive ? 'url(#grabberGradient)' : 'url(#buttonGradient)'
    })
}

/**
 * function to resolve overlapping when both brush handler at an end of the brush
 *
 * @param selection
 * @param range
 * @param d
 * @param interval
 * @returns
 */
function handleBrushGrabberMinMaxRange(
  selection,
  range,
  d,
  interval,
  brushScale
) {
  const [xStart, xEnd] = selection
  const [rStart, rEnd] = range
  const [rStartDate, rEndDate] = range.map(brushScale.invert)

  if (rStart >= xEnd) return [rStartDate, interval.offset(rStartDate, 1)]
  else if (rEnd <= xStart) return [interval.offset(rEndDate, -1), rEndDate]
  return d
}
