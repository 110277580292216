import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectSelectedBuildingOffering,
  selectBuilding
} from 'src/redux/slicers/buildingPicker'
import { getSearchParams } from './helperFunctions.js'
import colors from 'src/components/legacy/common/colors.json'
import { Block } from 'src/components/layouts'
import Icon from 'src/denali-ui/components/Icon/index'
import { TranslateComponent } from 'src/common/translations'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PageHeader from '../components/pageHeaderNew/header'
import { selectUiMode } from 'src/redux/slicers/appData'
import { UiMode } from 'src/redux/types/AppTypes'

const CAP_OFFERING = 'CAP'
const EP_OFFERING = 'EP'
const DI_AHU = 'DI-AHU'
const DI_CH = 'DI-CH'
const BP_OFFERING = 'BP'

const offeringDetails = [
  {
    name: 'Energy Cost Summary',
    href: '/energy-cost-summary',
    validOfferings: [CAP_OFFERING, EP_OFFERING]
  },
  {
    name: 'Energy Consumption',
    href: '/energy-consumption',
    validOfferings: [CAP_OFFERING, EP_OFFERING]
  },
  {
    name: 'Electrical Demand',
    href: '/electrical-demand',
    validOfferings: [CAP_OFFERING, EP_OFFERING]
  },
  {
    name: 'Load Analysis',
    href: '/load-analysis',
    validOfferings: [CAP_OFFERING, EP_OFFERING]
  },
  {
    name: 'Baseline Library',
    href: '/baseline-library',
    validOfferings: [CAP_OFFERING, EP_OFFERING]
  },
  {
    name: 'Automated Test Settings',
    href: '/automated-test-settings',
    validOfferings: [CAP_OFFERING, BP_OFFERING, DI_AHU, DI_CH]
  },
  {
    name: 'Automated Test Suppressions',
    href: '/automated-test-suppressions',
    validOfferings: [CAP_OFFERING, BP_OFFERING, DI_AHU, DI_CH]
  }
]

const WarningContainer = styled.div`
  border: 2px solid ${colors.RED};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  margin-top: 20px;
  color: ${colors.RED};
`

const BuildingLink = styled.span`
  text-decoration: underline;
  a {
    color: ${colors.RED};
  }
  color: ${colors.RED};
  cursor: pointer;
`

const withFeatureRestrictions = (
  WrappedComponent,
  props,
  needsofferingCheck = true
) => {
  const Message = ({ pageName }) => {
    const { buildingId, organizationId } = getSearchParams()
    const organization = organizationId ? `organization=${organizationId}` : ''
    const location = buildingId ? `location=${buildingId}` : ''
    let buildingSetupURL = '/building-setup'
    if (organization && location) {
      buildingSetupURL = `/building-setup?${organization}&${location}`
    }

    return (
      <div>
        <div>
          <PageHeader
            pageTitle={<TranslateComponent>{pageName}</TranslateComponent>}
          />
        </div>
        <Block display="flex" justify="center">
          <WarningContainer>
            <Icon
              name="Warning"
              width="25px"
              height="25px"
              margin="0px 10px"
              color={colors.RED}
            />
            <p>
              {
                <TranslateComponent>
                  {pageName} is not currently subscribed to for this building.
                  Enabled offerings can be seen in{' '}
                  <BuildingLink>
                    <Link to={buildingSetupURL}>Building Setup.</Link>
                  </BuildingLink>
                </TranslateComponent>
              }
            </p>
          </WarningContainer>
        </Block>
      </div>
    )
  }

  return function checkFeatureAccess() {
    const buildingOfferings = useSelector(selectSelectedBuildingOffering)
    const selectBuildingDetails = useSelector(selectBuilding)
    const uiMode = useSelector(selectUiMode)

    const isDenaliTheme = (uiMode === UiMode.denali)
    const pathname = window?.location?.pathname || ''
    const pageDetails = offeringDetails?.find((x) => x?.href === pathname) || []
    const featureOfferings = pageDetails?.validOfferings || []
    const pageName = pageDetails?.name || ''

    const { buildingId } = getSearchParams()

    if (
      featureOfferings.length === 0 ||
      pathname === '' ||
      needsofferingCheck === false ||
      !buildingId || 
      selectBuildingDetails?.type !== 'location' ||
      isDenaliTheme
    ) {
      return <WrappedComponent />
    } else {
      // if building offering has any one of feature offerings return component
      // else return message text
      let buildingHasOffering = false
      for (let i = 0; i < buildingOfferings?.length; i++) {
        if (featureOfferings?.includes(buildingOfferings[i])) {
          buildingHasOffering = true
          break
        }
      }
      if (buildingHasOffering) {
        return <WrappedComponent />
      } else {
        return <Message pageName={pageName} />
      }
    }
  }
}

export default withFeatureRestrictions
