import { useEffect, useMemo } from 'react'
import stackDataGenerator from './stackDataGenerator'
import StackedBarUi from './StackedBarUi'

import { StackedBarCompProps } from './types'
import useStackLegend from './useStackLegend'

export default function StackedBarDataProcessor({
  data = [],
  barGap = 0,
  disconnectFromLegendContext = false,
  getMinMax = (minMax) => null,
  ...props
}: StackedBarCompProps) {
  const stackData = useStackLegend(data, disconnectFromLegendContext)

  // Stack specific logic. Nothing related with legend
  // It will run on each clicked item to process the data
  const [series, minMax, barsInACategory] = useMemo(
    () => stackDataGenerator(stackData),
    [stackData]
  )

  useEffect(() => {
    getMinMax([minMax.min, minMax.max])
  }, [minMax.min, minMax.max])

  const barGapVal =
    typeof barGap === 'function' ? barGap(barsInACategory) : barGap

  return (
    <StackedBarUi
      data={series}
      barsInACategory={barsInACategory}
      {...props}
      barGap={barGapVal}
    />
  )
}
