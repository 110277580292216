import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'

import createTheme from './createTheme'
import GlobalStyle from '../GlobalStyle'

// ---------------------------------------------- Expected Default Structure ----------------------------------------------

/* 
  const defaultTheme = {
    breakpoints : {
      xs : 0,
      sm : 600,
      md : 840,
      lg : 1220,
    },
    button : {
      primary : {
        light : {
            backgroundColor: "rgb(32, 111, 207)",
            color: "#ffffff",
            hover: {
              backgroundColor: "rgb(0, 50, 108)"
            },
            disabled: {
              backgroundColor: "rgb(226, 226, 226)",
              color: "rgb(153, 153, 153)"
            }
            ...
            ...
        },
        dark : {
           backgroundColor: "rgb(32, 111, 207)",
           color: "#ffffff",
           hover: {
              backgroundColor: "rgb(0, 50, 108)"
            },
           disabled: {
              backgroundColor: "rgb(226, 226, 226)",
              color: "rgb(153, 153, 153)"
          }
          ...
          ...
        }
      },
      secondary : {
          light : {
            ...
            ...
          },
          dark : {
            ...
            ...
          }
      },
      customButtonStyle : {
        light : {
          ...
        },
        dark : {
          ...
          ...
        }
      }
    },
    colors : {
      ...
      ...
    },
    fontSizes: [12, 14, 16, 18, 20, 24, 28, 32, 42],
    fontSizeUnitType: "px",
    fonts: {
      pirmary : "Segoui",
      secondary : "Verdan",
      "sans": "sans-serif"
      // custom font families can be added
    },
    iconButton : {
      small : {
        ...
        ...
      },
      medium : {
        ...
        ...
      },
      large : {
        ...
        ...
      }
    },
    space: [0, 2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 64, 80]
  }


*/

// ---------------------------------------------- Theme Provider ----------------------------------------------

function ThemeProvider({ theme: userTheme, children }) {
  const theme = createTheme(userTheme)

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  )
}

ThemeProvider.propTypes = {
  /**
   * Your component tree.
   */

  children: PropTypes.node,
  /**
   * A theme object.
   */
  theme: PropTypes.object
}

export default ThemeProvider
