import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// components
import ToggleBox from 'src/components/legacy/components/toggle-box/'
import Characteristics from '../Characteristics/characteristics'

class AssociationDetailsCharacteristics extends PureComponent {
  static propTypes = {
    equipmentDetails: PropTypes.any
  }

  render() {
    const { equipmentDetails, equipmentCharacteristics, refetchCharacteristics, loadingCharacteristics } = this.props

    return (
      <ToggleBox
        label="Characteristics"
        className="transparent"
        showOnlyLabel
        opened
      >
        <Characteristics
          isAssociatedEquipment={true}
          equipmentDetails={equipmentDetails}
          equipmentCharacteristics={equipmentCharacteristics}
          loadingCharacteristics={loadingCharacteristics}
          refetchCharacteristics={refetchCharacteristics}
          readOnly
        />
      </ToggleBox>
    )
  }
}

export default AssociationDetailsCharacteristics
