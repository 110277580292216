import { Button } from "@aws-amplify/ui-react"
import { Dialog } from "./Dialog"
import { useTranslation } from "react-i18next";

export const DialogConfirm = ({isOpen=true, onClose, title, children, onAgree}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      actions={<ConfirmActions onAgree={onAgree} onClose={onClose}/>}>

      {children}
      
    </Dialog>
  )
}

function ConfirmActions({onAgree, onClose}) {
  const [t] = useTranslation();
  return (
    <>
      <Button onClick={onClose} variation="link" data-testid="dialogConfirmNo">{t('common:No')}</Button>
      <Button onClick={onAgree} data-testid="dialogConfirmYes">{t('common:Yes')}</Button>
    </>
  )
}