import React from 'react'
import { number } from 'prop-types'

import './progress-bar.scss'

function ProgressBar(props) {
  return (
    <div className="progressBar">
      <div
        className="progressProgress"
        style={{ width: `${Math.round(props.progress * 10000) / 100}%` }}
      />
    </div>
  )
}

ProgressBar.propTypes = {
  progress: number
}

export default ProgressBar
