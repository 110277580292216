// Default Options.

import { useMemo } from 'react'

export function useOptions(axisData = [], yAxisMin, yAxisMax): Options {
  return useMemo<Options>(
    () => ({
      xAxis: {
        type: 'scaleBand',
        data: axisData
      },
      yAxis: {
        type: 'scaleLinear',
        min: yAxisMin,
        max: yAxisMax
      },
      grid: [{ left: '40px', right: '10px', top: '30px', bottom: '30px' }]
    }),
    [axisData, yAxisMin, yAxisMax]
  )
}
