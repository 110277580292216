import React from 'react'
import styled from 'styled-components'
import { func, object, string, number } from 'prop-types'
import { Label } from './style.js'
import { Highlighter } from 'src/components/utilities/Highlighter'
import BuildingName from './building-name.jsx'
import { Trans } from 'react-i18next'
import IconSVG from 'src/components/Icon'
import colors from 'src/components/legacy/common/colors.json'
import { LOCATION} from './../constants'
import { TranslateComponent } from 'src/common/translations'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`
export const NodeContainer = styled.div`
  width: 95%;
  position: absolute;
`
export const IconContainer = styled.div`
	width: 5%;
	background-color: ${colors.TBA_LIGHTEST_GREY};
	&.odd {
		background-color: ${colors.WHITE};
	}
	&.even{
		background-color: #f4f4f4;
	}
	&:not(.inactive) {
		 &:active, &.selected {
			 color: ${colors.WHITE};
		 }
		 &:hover:active{
			 color: ${colors.WHITE};
		 }
		 &:hover, &:focus {
			 background-color: #e6efff;
			 color: ${colors.BLACK};
		 }
		 &:active {
			 background-color: #6c97bf;
		 }
		 &.selected {
			 background-color: #6c97bf;
		 }
	 }
	}
`
export const ArrowOpen = styled.div`
  font-size: 10px;
  padding: 5px 5px 0 6px;
  margin-top: 5px;
`
export const ArrowClose = styled.div`
  font-size: 10px;
  padding: 5px 5px 0 6px;
  margin-top: 10px;
  transform: rotate(-90deg);
`
const BodyItem = (props) => {
  const {
    data: { isLeaf, item, onClick, searchText, index },
    isOpen,
    style,
    toggle
  } = props
  const {
    id,
    selected,
    level,
    subtitle,
    showHoverLabel,
    icon,
    secondary,
    type,
    disabled
  } = item
  const withIcon = Boolean(item.icon)
  const clsParity = index % 2 === 0 ? 'odd' : 'even'
  const clsLevel = `level-${level}`
  const clsSelected = selected ? 'selected' : ''
  const clsActive = disabled ? 'inactive' : ''
  const clsDisabled = disabled ? 'disabled' : ''
  const allItemActive = id === 'all' ? 'all' : ''
  return (
    <Container style={style} data-test={`buildingSelectorTreeItem-${id}`}>
      <IconContainer
        className={`${clsParity} ${clsSelected} ${clsActive} ${allItemActive} ${clsDisabled}`}
      >
        {!isLeaf &&
          (isOpen ? (
            <ArrowOpen className="icon icon-arrow-down" onClick={toggle} />
          ) : (
            <ArrowClose className="icon icon-arrow-down" onClick={toggle} />
          ))}
      </IconContainer>
      <NodeContainer
        className={`item ${clsParity} ${clsLevel} ${clsSelected} ${clsActive} ${allItemActive} ${clsDisabled}`}
        key={`level-${level}-item-${id}`}
        onClick={() => onClick(item)}
      >
        <Label className={`label ${!withIcon ? 'no-icon' : ''}`}>
          {showHoverLabel ? (
            <span className="tooltip-container">
              <span
                className={`tooltip hover-tooltip center-${
                  index === 0 ? 'bottom' : 'top'
                }`}
              >
                <BuildingName item={item} textToHighlight={searchText} />
              </span>
            </span>
          ) : (
            <></>
          )}
          {withIcon && icon !== 'virtual-meter' ? (
            <i className={`icon icon-${item.icon}`} />
          ) : (
            <IconSVG
              name={icon}
              color={colors.BLACK}
              hover={colors.BLACK}
              viewBox="0 -1 12 12"
              height="16px"
              width="16px"
              margin="1px 6px 0px -22px"
            />
          )}
          <BuildingName item={item} textToHighlight={searchText} />
        </Label>
        {secondary && (
          type === LOCATION ? (<div className="secondary">
            <Highlighter text={secondary} textToHighlight={searchText} isTranslate={secondary?.includes("Organization") ? true: false}/>
          </div>) : (<div className="secondary"><TranslateComponent>{secondary}</TranslateComponent></div>)
        )}
        {allItemActive && (
          <div className="subtitle">
            {' '}
            <Trans i18nKey={subtitle} />
          </div>
        )}
      </NodeContainer>
    </Container>
  )
}

BodyItem.propTypes = {
  onClick: func,
  item: object,
  searchText: string,
  index: number,
  style: object
}

export default BodyItem
