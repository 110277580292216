
import { createPortal } from "react-dom"
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { Button, ButtonGroup, Flex, Heading, Grid } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHand, faPlay, faXmark } from "@fortawesome/free-solid-svg-icons";
import translate, { TranslateComponent } from "src/common/translations";
import { Dialog } from "src/denali-components/Dialog/Dialog"
import styles from 'src/denali-components/Dialog/dialog.module.scss';
import { VimeoEmbed, VimeoEmbedStyles } from "src/denali-components/Media/VimeoEmbed";
import { openHelp } from "../Sidebar/UserMenu";
import { videoOnboardingData } from "src/denali-pages/VideoOnboarding/VideoOnboardingData";

export const DialogVideoPlayer = ({ isOpen, onClose, setIsOpen, videoData, currentIndex})  => {
  const [player, setPlayer] = useState(null);
  const navigate = useNavigate();

  const handlePlayNext = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < videoOnboardingData.length) {
      navigate(`/video-onboarding?openDialog=true&cardIndex=${nextIndex}`);
    } else {
      navigate(`/video-onboarding?openDialog=true&cardIndex=0`);
    }
  };

  handleKeyDownEffect(isOpen, onClose);

  return (
    <>
      {isOpen &&
        createPortal(
          <Dialog isOpen={isOpen} showHeader={false} className={styles.video} data-testid="videoDialog">
            <Flex direction='column' gap={31}>
              <div className={styles.player}>
                <VimeoEmbed
                  id={videoData.id}
                  title={videoData.title}
                  style={VimeoEmbedStyles.backgroundCoverPreview}
                  setPlayer={setPlayer}
                  urlParams={videoData.urlParams}
                />
              </div>
              <Grid className={styles.content}>
                <Flex className={styles.text}>  
                  <p className="body-lg">
                    <TranslateComponent>{videoData.episode}</TranslateComponent>
                  </p>
                  <Heading level={1} className="title-md">
                  <TranslateComponent>{videoData.title}</TranslateComponent> 
                  </Heading>
                  <p className="body-lg">          
                    <TranslateComponent>{videoData.description}</TranslateComponent> 
                  </p>
                </Flex>
                <Flex className={styles.buttons}>  
                  <ButtonGroup direction='column'gap={8}>
                    <Button className="amplify-button" data-testid="playNext" onClick={handlePlayNext}><FontAwesomeIcon icon={faPlay} /><span><TranslateComponent>Play Next Episode</TranslateComponent></span></Button>
                    <Button className="button-gray" data-testid="openHelp" onClick={openHelp(setIsOpen)} ><FontAwesomeIcon icon={faHand} /><span><TranslateComponent>Request Help</TranslateComponent></span></Button>
                  </ButtonGroup>
                </Flex>
              </Grid>
            </Flex>
            <div className={styles.backdrop} onClick={onClose} > 
              <Button className={styles.closeButton} data-testid="closeDialog" onClick={onClose} aria-label={translate('Close')}><FontAwesomeIcon icon={faXmark} /></Button>
            </div>
          </Dialog>
   
          , document.getElementById('app')
        )
      }
    </>
  )
}

const handleKeyDownEffect = (isOpen, onClose) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);
};

