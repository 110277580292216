import { diff } from 'just-diff'
import { initialState } from './constant'
import { isNumeric } from '../CommonComponents/Bar/helper'

export const isEqual = (prev: any, next: any) => {
  return diff(prev, next).length === 0
}

export const haveKeys = (obj: any = {}) => {
  return Object.keys(obj)?.length > 0
}

export const findNearestIndex = (
  mousePositionX,
  containerWidth,
  categoryLength
) => {
  // Calculate the interval size based on the container width and array length
  const intervalSize = containerWidth / (categoryLength - 1)

  // Determine the index based on the mouse position
  const index = Math.round(mousePositionX / intervalSize)

  // Ensure the index is within the bounds of the array
  const validIndex = Math.min(Math.max(index, 0), categoryLength - 1)

  return validIndex
}

export const newDateString = () => {
  return new Date()?.toISOString()
}

export const getInitailState = () => {
  const state = {
    ...initialState
  }

  Object.keys(initialState)?.forEach((key) => {
    state[`${key}T`] = newDateString()
  })

  return state
}

export const deleteUnwantedKeysFromObject = (object = {}, wantedKeys = []) => {
  const newObject: any = {}

  Object.keys(object)?.forEach((key) => {
    if (wantedKeys?.includes(key)) {
      newObject[key] = object?.[key]
    }
  })

  return newObject
}

export const emptyArrayifNotArray = (array: any) => {
  return Array.isArray(array) ? array : []
}

export const emptyObjectifNotObject = (obj: any) => {
  return !Array.isArray(obj) && obj !== null && typeof obj === 'object'
    ? obj
    : {}
}

export const getNumberIfUndefined = (value: any, num = 0) => {
  return isNumeric(value) ? value : num
}
