import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@react-pdf/renderer'
import i18next from 'i18next'
import moment from 'moment'
import PDFHeader from '../../../../components/print-components/pdf-header'
import PDFTitle from '../../../../components/print-components/pdf-header/pdf-title'
import PDFFooter from '../../../../components/print-components/pdf-footer'
import RichText from '../../../../components/print-components/pdf-rich-text'
import { DATE_FORMAT } from '../../../../components/legacy/common/time-helpers'
import PDFKPIBlock from '../../../../components/print-components/pdf-kpi-block'
import PDFPriorityStatusBlock from '../../../../components/print-components/pdf-priority-status-block'
import PDFCostSavingBlock from '../../../../components/print-components/pdf-cost-saving-block'
import PDFFindingsBlock from '../../../../components/print-components/pdf-findings-block'
import PDFEquipmentBlock from '../../../../components/print-components/pdf-equipment-block'
import PDFImagesBlock from '../../../../components/print-components/pdf-images-block'
import { styles } from './pdf-opportunity-detail-view.styles'


const PDFOpportunityDetailView = ({
	downloadData,
	isFooterCount,
	headerLocation,
	headerOrganization
  }) => {
	const { settings, findings, name, status, tisObjects, assignedTo, nextStep = "" } = downloadData
	const { description = {}, kpi = [], costSavings, priority, images = [], proposalDate } = settings
	return (
		<>
		<PDFHeader
			isFixed={true}
			locationNames={[headerLocation]}
			organizationName={headerOrganization}
		/>
		<PDFTitle
			pageTitle="Opportunity"
			itemTitle={name}
		/>
		{proposalDate && <Text fixed={true} style={styles.dateWrapper}>
			<Text>{moment(proposalDate).format(DATE_FORMAT)}</Text>
		</Text>}
		<PDFEquipmentBlock equipments={tisObjects} />
		<PDFCostSavingBlock costSavings={costSavings} />
		<PDFKPIBlock kpi={kpi} />
		<RichText note={description} />
		<PDFPriorityStatusBlock nextStep={i18next.t(`components:nextSteps>${nextStep}`)} priority={priority} status={i18next.t(`components:statuses>${status}`)} assignedTo={assignedTo?.key} />
		{images.length >= 1 && <PDFImagesBlock images={images} />}
		<PDFFindingsBlock findings={findings} />
		<PDFFooter isCountPage={isFooterCount} />
	</>
	)
}


PDFOpportunityDetailView.propTypes = {
	downloadData: PropTypes.shape({
		name: PropTypes.string
	}),
	isFooterCount: PropTypes.bool,
	headerLocation: PropTypes.string.isRequired
}

export default PDFOpportunityDetailView