import * as Yup from 'yup'
import { useFormikContext } from '../MultiTabTorm'
import SelectFormInput from 'src/components/select-form-input/SelectFormInput'
import { useTranslation } from 'react-i18next'
import {
  getDisplayItems,
  getTimePeriodOptionsList,
  getValueOptionsList
} from '../../helpers'
import i18next from 'i18next'
import {
  BenchMarkStyleWrapper,
  DataMonitoringConfigurationWrapper,
  PanelLabel
} from '../../styles'
import { formatOverlayTimePeriod } from 'src/common/overlay-time-period'
import {
  TARGET_MARKERS,
  TARGET_MARKERS_CONSTANT,
  formatTargetMarkers
} from 'src/common/target-markers'
import { ChangeEvent, useEffect, useState } from 'react'
import { convertStringToValidNumber } from 'src/components/legacy/common/helpers.js'
import {
  PERIODS_LINK_TO_INTERVALS,
  TIME_INTERVAL,
  formatStdTimeInterval
} from 'src/common/time-interval'
import WidgetPropertyUom from '../widgetsPropertyUom/WidgetPropertyUom'
import translate, { TranslateComponent } from 'src/common/translations'

export const TrendBarChartConfigurationSchema = Yup.object().shape({
  property: Yup.string().test(
    'testRequiredValue',() => 
    "Property is required.",
    testRequiredValue
  ),
  unitsOfMeasure: Yup.string().test(
    'testRequiredValue',() => 
    "Uom is required.",
    testRequiredValue
  ),
  timePeriod: Yup.string().required("Time Period is required.")
})
function testRequiredValue(value) {
  return !!value
}

export const TrendBarChartConfiguration = () => {
  const [t] = useTranslation()
  const formik = useFormikContext()
  const { values, errors, setFieldValue } = formik
  const [stdTimeIntervalList, setStdTimeIntervalList] = useState([])

  useEffect(() => {
    const interval = PERIODS_LINK_TO_INTERVALS.get(values.timePeriod)
    if (interval) {
      const timeValue = TIME_INTERVAL.get(interval)
      setStdTimeIntervalList([...timeValue])
      setFieldValue('timeInterval', timeValue[0])
    }
  }, [values.timePeriod])

  return (
    <DataMonitoringConfigurationWrapper>
      <div className="configuration-tab-main">
        <div>
          <WidgetPropertyUom
            transFunc={t}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
          />
          <div className="inline-control">
            <SelectFormInput
              labelName={"Time Period"}
              optionsList={getTimePeriodOptionsList(t)}
              placeholder={'— Select One —'}
              update={(value) => {
                setFieldValue('timePeriod', value)
              }}
              errorItem={errors?.timePeriod}
              selectedItem={t(`time>${values?.timePeriod}`, {
                nsSeparator: '>'
              })}
            />
            <SelectFormInput
              labelName={"Time Interval"}
              optionsList={getDisplayItems(
                stdTimeIntervalList,
                formatStdTimeInterval
              )}
              placeholder={'— Select One —'}
              update={(value) => {
                setFieldValue('timeInterval', value)
              }}
              errorItem={errors?.timeInterval}
              selectedItem={t(`time>${values?.timeInterval}`, {
                nsSeparator: '>'
              })}
            />
          </div>
          <SelectFormInput
            labelName={"Value"}
            optionsList={getValueOptionsList(t)}
            placeholder={'— Select One —'}
            update={(value) => {
              setFieldValue('value', value)
            }}
            errorItem={errors?.value}
            selectedItem={t(`time>${values?.value}`, {
              nsSeparator: '>'
            })}
          />
          <BenchMarkStyleWrapper>
            <SelectFormInput
              fieldsetClass="benchmark"
              labelName={"Benchmark/Target Marker"}
              optionsList={getDisplayItems(TARGET_MARKERS, formatTargetMarkers)}
              placeholder={'— Select One —'}
              update={(value) => {
                setFieldValue('targetMarker', value)
              }}
              errorItem={errors?.targetMarker}
              selectedItem={formatOverlayTimePeriod(values?.targetMarker)}
            />
            <>
              {values?.targetMarker === TARGET_MARKERS_CONSTANT && (
                <fieldset className="benchmark">
                  <input
                    value={convertStringToValidNumber(
                      values?.markerConstantValue
                    )}
                    className={errors?.markerConstantValue ? 'error' : ''}
                    onChange={({
                      target: { value }
                    }: ChangeEvent<HTMLInputElement>) => {
                      const re = /^[0-9\b]+$/
                      if (value === '' || re.test(value)) {
                        setFieldValue('markerConstantValue', value)
                      }
                    }}
                  />
                </fieldset>
              )}
            </>
            <>
              {values?.targetMarker === TARGET_MARKERS_CONSTANT && (
                <fieldset className="benchmark">
                  <PanelLabel
                    className={errors?.benchMarkTargetLabel ? 'error' : ''}
                  >
                    <TranslateComponent>Benchmark/TargetLabel</TranslateComponent>
                  </PanelLabel>
                  <input
                    value={values?.benchMarkTargetLabel}
                    className={errors?.benchMarkTargetLabel ? 'error' : ''}
                    onChange={({
                      target: { value }
                    }: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('benchMarkTargetLabel', value)
                    }}
                  />
                </fieldset>
              )}
            </>
          </BenchMarkStyleWrapper>
        </div>
      </div>
    </DataMonitoringConfigurationWrapper>
  )
}
