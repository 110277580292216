export const ACTIONS = {
  CREATE: 'create',
  EDIT: 'edit'
}

export const ERROR_HEADERS = {
  API_ERROR: 'API error'
}

export const ERROR_CODES = {
  LOCATION_SUMMARY_CREATION_ERROR: 'LOCATION_SUMMARY_CREATION_ERROR',
  LOCATION_SUMMARY_UPDATE_ERROR: 'LOCATION_SUMMARY_UPDATE_ERROR',
  LOCATION_CONNECTIVITY_UPDATE_ERROR: 'LOCATION_CONNECTIVITY_UPDATE_ERROR',
  LOCATION_FLOOR_AREA_UPDATE_ERROR: 'LOCATION_FLOOR_AREA_UPDATE_ERROR',
  REMOTE_PROFILE_CREATION_ERROR: 'REMOTE_PROFILE_CREATION_ERROR',
  REMOTE_PROFILE_UPDATE_ERROR: 'REMOTE_PROFILE_UPDATE_ERROR'
}

export const CONNECTIVITY_TYPE_TO_MSG = {
  NAX: 'Niagara AX',
  SCG: 'SC/BCU'
}

export const getMessage = (code, type, params = {}, actionType) => {
  if (
    code === 'LOCATION_SUMMARY_CREATION_ERROR' &&
    type === 'buildingNameExists' &&
    actionType === ACTIONS.CREATE
  ) {
    return `Could not create facility with connectivity: ${
      CONNECTIVITY_TYPE_TO_MSG[params.connectivity] || 'SC'
    } error: Facility already exists with the same name in the account  ${
      params.account
    }. Please Enter a different name`
  }
  if (
    code === 'LOCATION_SUMMARY_CREATION_ERROR' &&
    type === 'buildingNameExists' &&
    actionType === ACTIONS.EDIT
  ) {
    return `Could not update facility with connectivity: ${
      CONNECTIVITY_TYPE_TO_MSG[params.connectivity] || 'SC'
    } error: Facility already exists with the same name in the account  ${
      params.account
    }. Please Enter a different name`
  }
  if (
    code === 'LOCATION_SUMMARY_CREATION_ERROR' &&
    type === 'deviceAssociatedWithDifferentBuilding' && 
    actionType === ACTIONS.CREATE
  ) {
    return `Could not create facility with connectivity: SC error: All Devices entered are already present as part of another Automation System. Hence the site is not created. Please contact admin if you want to move these devices : ${params.buildingName}: ${params.serialNumber}`
  }
  if (
    code === 'LOCATION_SUMMARY_CREATION_ERROR' &&
    type === 'deviceAssociatedWithDifferentBuilding' &&
    actionType === ACTIONS.EDIT
  ) {
    return `Could not update facility with connectivity: SC error: The below devices are part of another Automation System.Please contact admin if you want to move these devices : ${params.buildingName}: ${params.serialNumber}`
  }
  if (
    code === 'LOCATION_SUMMARY_CREATION_ERROR' &&
    type === 'deviceAssociatedWithSameBuilding'
  ) {
    return `Device Serial Number ${params.serialNumber} is already registered for this location`
  }
}

export const BUILDING_STATUS_GEN3 = {
  Active: 'enable',
  Discontinued: 'disable'
}


export const SERIAL_NUMBER_SUFFIX = "-local"