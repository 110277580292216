import React, { useEffect, useState, useRef } from 'react'
import Notification from './notification'
import { Link } from 'react-router-dom'
import _cloneDeep from 'lodash/cloneDeep'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'
import useClickOutside from 'src/denali-ui/legacy/common_file/customHooks/clickOutsideHook'
import {
  UPDATE_NOTIFICATIONS_ACTIVE_func,
  CLEAR_ALL_NOTIFICATIONS
} from '../../graphql'
import { useQuery } from 'src/hooks/APIHooks'
import { API } from 'aws-amplify'
import { NOTIFCATION_STATUS } from './constants'
import { typeSpecificProperties } from './helpers'
import translate from "src/common/translations"

import './notification-list.scss'

const MAX_NOTIFICATION_NUMBER = 10000

export const NotificationList = ({
  onCloseDropdown,
  userId,
  notificationCount,
  onToggle,
  notifications = [],
  nextNotificationsToken,
  refetchNextNotifications,
  selectedNotificationStatus,
  setSelectedNotificationStatus,
  previousSelectedNotificationStatus,
  itemsPerPage = 50
}) => {
  const { ref: intersectionRef, inView } = useInView()

  const [sortedNotifications, setSortedNotifications] = useState(null)
  const [updatedNotificationCount, setUpdatedNotificationCount] =
    useState(notificationCount)
  const [error, setError] = useState(false)
  const listRef = useRef(null)

  const optionsTranslated = {
    notif: translate("Notifications"),
    noNewNotif: translate("No new notifications at this time. Please try again later."),
    settings: translate("Settings")
  }

  const handleClickOutside = () => {
    onCloseDropdown()
  }

  const { refetch: clearAll } = useQuery({
    query: CLEAR_ALL_NOTIFICATIONS,
    disableInitialLoad: true,
    dataPath: 'data.clearNotification'
  })

  useClickOutside(listRef, handleClickOutside)

  useEffect(() => {
    Array.isArray(notifications) && setSortedNotifications(notifications)
  }, [notifications])

  useEffect(() => {
    setUpdatedNotificationCount(notificationCount)
  }, [notificationCount])

  useEffect(() => {
    if (inView && nextNotificationsToken !== null) {
      if (
        selectedNotificationStatus &&
        selectedNotificationStatus !== NOTIFCATION_STATUS.ALL
      ) {
        refetchNextNotifications({
          nextToken: nextNotificationsToken,
          filter: { status: { eq: selectedNotificationStatus } }
        })
      } else {
        refetchNextNotifications({ nextToken: nextNotificationsToken })
      }
    }
  }, [inView])

  useEffect(() => {
    if (
      selectedNotificationStatus &&
      previousSelectedNotificationStatus !== selectedNotificationStatus
    ) {
      if (selectedNotificationStatus !== NOTIFCATION_STATUS.ALL) {
        refetchNextNotifications({
          filter: { status: { eq: selectedNotificationStatus } },
          reset: true
        })
      } else {
        refetchNextNotifications({ reset: true })
      }
    }
  }, [selectedNotificationStatus, previousSelectedNotificationStatus])

  const closeNotificationList = () => {
    onCloseDropdown()
  }

  return (
    <div
      ref={listRef}
      className={classNames('notification-list', {
        empty: !sortedNotifications?.length
      })}
    >
      {Boolean(updatedNotificationCount) && (
        <span className="notification-count" onClick={onToggle}>
          {`${
            updatedNotificationCount < MAX_NOTIFICATION_NUMBER
              ? updatedNotificationCount
              : `${MAX_NOTIFICATION_NUMBER - 1}+`
          }`}
        </span>
      )}
      <div className="notifications-header">
        <div>
          <p>{optionsTranslated.notif}</p>
          {/* {<section className="notification-filter">
			 <Select
            selectedItem={NOTIFCATION_STATUS_DESC[selectedNotificationStatus] || NOTIFCATION_STATUS.ALL}
            onChange={(value) => {
              setSelectedNotificationStatus(value)
            }}
            options={Object.keys(NOTIFCATION_STATUS).map((key) => ({key:NOTIFCATION_STATUS[key],value:NOTIFCATION_STATUS[key],description:NOTIFCATION_STATUS_DESC[NOTIFCATION_STATUS[key]] || NOTIFCATION_STATUS[key]}))}
          />
		  {sortedNotifications && <span className="filterCount">{`${sortedNotifications?.length || 0 < MAX_NOTIFICATION_NUMBER ? sortedNotifications?.length : `${MAX_NOTIFICATION_NUMBER - 1}+`}`}</span>}
		  </section>} */}
        </div>
        <span onClick={() => closeNotificationList()}>
          <Link to={`/notification-settings`}>{optionsTranslated.settings}</Link>
        </span>
      </div>
      {sortedNotifications?.length ? (
        <div className="notifications">
          {sortedNotifications.map((notification, i) => (
            <Notification
              ref={
                (nextNotificationsToken ||
                  sortedNotifications.length >= itemsPerPage) &&
                i === sortedNotifications.length - 1
                  ? intersectionRef
                  : null
              }
              key={notification.id}
              notification={typeSpecificProperties(notification)}
              onRemove={async (id, ntype, app, createdAt) =>
                await API.graphql({
                  query: UPDATE_NOTIFICATIONS_ACTIVE_func({
                    id,
                    ntype,
                    app,
                    createdAt,
                    notificationStatus: NOTIFCATION_STATUS.CLOSED,
                    isActive: 0
                  })
                }).then(
                  (res) => {
                    if (!res.errors) {
                      const isAlreadyRead =
                        sortedNotifications.find((x) => x.id === id)?.status ===
                        NOTIFCATION_STATUS.READ
                      setSortedNotifications(
                        sortedNotifications.filter((x) => x.id !== id)
                      )
                      !isAlreadyRead &&
                        setUpdatedNotificationCount((count) => count - 1)
                    }
                  },
                  (err) => setError(err)
                )
              }
              onChange={async (id, ntype, app, createdAt) => {
                await API.graphql({
                  query: UPDATE_NOTIFICATIONS_ACTIVE_func({
                    id,
                    ntype,
                    app,
                    createdAt,
                    notificationStatus: NOTIFCATION_STATUS.READ
                  })
                }).then(
                  (res) => {
                    if (!res.errors) {
                      const newSortedNotifications = [...sortedNotifications]
                      newSortedNotifications[
                        newSortedNotifications.findIndex((x) => x.id === id)
                      ].status = NOTIFCATION_STATUS.READ
                      setSortedNotifications(newSortedNotifications)
                      setUpdatedNotificationCount((count) => count - 1)
                    }
                  },
                  (err) => setError(err)
                )
              }}
              onActionLink={() => console.log('handle reading and close')}
            />
          ))}
        </div>
      ) : (
        <div className="no-notifications">
          <span>
          {optionsTranslated.noNewNotif}
          </span>
        </div>
      )}
      {Boolean(sortedNotifications?.length) && (
        <div className="clear-all">
          <a
            onClick={async () => {
              const data = await clearAll({
                input: JSON.stringify({ userId })
              })
              if (data && JSON.parse(data)?.statusCode === 200) {
                setSortedNotifications([])
                setUpdatedNotificationCount(null)
              }
            }}
          >
            Clear All
          </a>
        </div>
      )}
    </div>
  )
}

NotificationList.propTypes = {}

export default NotificationList
