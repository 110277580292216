import styled from 'styled-components'
import colors from 'src/components/layouts/colors.json'

export const ActionButton = styled.button.attrs((props: any) => ({
  'data-testid': props?.testName
}))`
  margin-left: auto;
  margin-left: 10px;
`

export const ToolBarWrapper = styled.div.attrs((props: any) => ({
  'data-testid': props?.testName
}))`
  display: flex;
  align-items: center;
  a {
    margin-right: 8px;
  }
`

export const EnergyIntensityContainerWrapper = styled.div.attrs(
  (props: any) => ({
    'data-testid': props?.testName
  })
)`
  margin-top: 5px;
  h1 {
    padding-bottom: 5px;
  }
  div[class*='ChartPageContent'] {
    div[class*='DrawerContainer'] {
      div[class*='SectionContainer'] {
        height: 345px;
        div[class*='MarkersContainer'] {
          max-height: 72%;
        }
      }
    }
  }
  .engery-intensity-chart-legend {
    max-width: 200px;
  }
`

export const MarkersContainer = styled.div`
  margin-bottom: 30px;
  max-height: 500px;
  overflow-y: scroll;
  padding-top: 10px;
  width: 231px;
  padding-right: 20px;
  position: relative;
`
export const Marker = styled.div`
  display: flex;
  Label {
    span {
      overflow-wrap: anywhere;
    }
  }
`
export const Alter = styled.div`
  cursor: pointer;
  padding-top: 13px;
  padding-left: 10px;
`
export const AddContainer = styled.div.attrs((props: any) => ({
  'data-testid': 'bench-mark-buttons-container'
}))`
  padding-right: 30px;
`
export const Add = styled.div`
  margin-top: 10px;
  text-align: right;
  cursor: pointer;
  color: darkblue;
  text-transform: uppercase;
`

export const SectionContainer = styled.div`
  height: 500px;
`

export const EIContainer = styled.div.attrs(
  (props: any) => ({
    'data-testid': props?.testName
  })
)`
  display: flex;
  position: relative;
  width: 100%;
`

export const VerticalLine = styled.div`
  border-left: 1px solid #999;
  height: 100%;
  position: absolute;
  left: 25%;
`

export const MarkerDIV = styled.div`
  width: '30%';
  transition: width 0.3s ease;
  background-color: #f0f0f0;
  padding: 8px;
  position: relative;
`

export const ChartDIV = styled.div.attrs(
  (props: any) => ({
    'data-testid': props?.testName
  })
)`
  flex: 1;
  background-color: ${colors.WHITE};
  padding: 20px;
  width: 100%;
`

export const PopUpSup = styled.sup`
  top: -0.5em;
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
`
