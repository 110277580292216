import * as yup from 'yup'

const REQUIRED = 'required'
const schema = () =>
  yup.object().shape({
    name: yup.string().required(REQUIRED),
    description: yup.string().notRequired(),
    value: yup.number().min(0, "Benchmark must be greater than zero").required("Please enter a benchmark value"),
  })

export const ERROR_MESSAGES = {
  duplicateDate: 'Each location can only have one marker per date',
  invalidDate: 'Please enter a valid date',
  futureDate: 'Please enter a date on or before today',
  markerName: 'Please enter a marker name'
}

export default schema
