import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { SimpleLegendGroupTemplate } from './SimpleLegendTemplate'

import type { LegendsGroup, SimpleLegend, SimpleLegendProps } from '../types'
import { useLegendUiContext } from '../provider/LegendProvider'
import { useLegendApiContext } from '../provider/LegendApiProvider'
import { legendItemModel } from '../config'

import './styles.scss'

export const createSimpleLegendModel = (data: SimpleLegend) =>
  legendItemModel(data)

function groupModel(api, ...args) {
  const group = api(...args)
  return [group]
}

export default function SimpleLegend(props: SimpleLegendProps) {
  const { legendDetails, legendComponentProps, isContextEnabled } =
    useLegendUiContext()
  const legendApi = useLegendApiContext()
  const [tooltipState, setTooltipState] = useState(false)

  const {
    legends = [],
    hide = false,
    className
  } = useMemo(() => {
    if (props.isStandaloneLegend) {
      return {
        ...props,
        legends: groupModel(
          legendApi.createLegendGroupModel,
          props.legends,
          null
        )
      }
    }

    return {
      ...props,
      ...legendComponentProps,
      legends: isContextEnabled
        ? Object.values(legendDetails).flat()
        : groupModel(legendApi.createLegendGroupModel, props.legends, null)
    }
  }, [props, isContextEnabled, legendComponentProps, legendDetails])

  if (hide) return null;

  const showTooltip = () => {
    if(props?.showTooltipFlag){
    setTooltipState(!tooltipState)
    }
  }

  return (
    <div className={clsx('t-chart-simple-legend', className)} onMouseEnter={showTooltip} onFocus={showTooltip} onMouseLeave={showTooltip}>
      <div className={clsx('t-chart-simple-legend-content')} >
        {legends.map((legendsGroup: LegendsGroup) => {
          return (
            <SimpleLegendGroupTemplate
              {...legendsGroup}
              key={legendsGroup.uuid}
            />
          )
        })}
      </div>
      {tooltipState &&
        <div className='tooltip-legend'>
          {legends?.map((legendsGroup: LegendsGroup) => {
            return (
              <SimpleLegendGroupTemplate
                {...legendsGroup}
                key={legendsGroup?.uuid}
              />
            )
          })}
        </div>
      }
    </div>
  )
}
