import { useFormikContext } from '../MultiTabTorm'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { WidgetPreviewWrapper } from '../widgetPreviewWrapper'
import styled from 'styled-components'
import { CustomKPITrendsWidgetPreview } from 'src/pages/dashboards/components/DashboardCardWidget/CustomKPITrendsWidgetPreview'
import { getGoalType, POINT_MODE, TREND_MODE, getTrendDataBasedOnTimePeriod } from './helper'

const CustomKPITrendContainer = styled.div`
  height: calc(100%-64px);
`

export const CustomKPITrendsPreviewWrapper = () => {
  const formik = useFormikContext()
  const { values } = formik
  const { formikErrors, objectPickerSelectedValue } = useWidgetsContext()
  const trendUpIsGood = values?.selectedKpiMeasurement === 'energyStarScore'
  const type = values?.isPointInTime === '1' ? POINT_MODE : TREND_MODE
  const selectedTimePeriod =
    type === POINT_MODE
      ? values?.selectedTimePeriod?.split('/')?.[1] || ''
      : values?.selectedDisplayTimeDate?.split('/')?.[1] || ''

  const getPreviewDetails = () => ({
    name: values?.name,
    widgetDiscription: values?.description,
    widgetName: values?.name,
    trendData: values?.trendData,
    chartData: values?.trendData ? getTrendDataBasedOnTimePeriod(values?.trendData, selectedTimePeriod) : [],
    buildingName: objectPickerSelectedValue?.[0]?.buildingName || '',
    equipmentList: [
      {
        buildingName: objectPickerSelectedValue?.[0]?.buildingName || '',
        equipmentName: '',
        accountName: objectPickerSelectedValue?.[0]?.accountName || ''
      }
    ],
    selectedKpiMeasurement: values?.selectedKpiMeasurement,
    isPointInTime: values?.isPointInTime,
    previousPeroid: values?.previousPeroid,
    currentPeriod: values?.currentPeriod,
    goalCurrentPeriod: values?.goalCurrentPeriod,
    type: type,
    selectedTimePeriod: selectedTimePeriod,
    trendUpIsGood: trendUpIsGood,
    goalType: getGoalType(trendUpIsGood, values?.currentPeriod, values?.goalCurrentPeriod),
    timePeriod: values?.selectedDisplayTimeDate,
    timeZone: objectPickerSelectedValue?.[0]?.timeZone,
  })

  return (
    <WidgetPreviewWrapper
      widgetName={values?.name}
      error={Object.keys(formikErrors)?.length > 0}
      chartType="custom-kpi"
    >
      <CustomKPITrendContainer>
        <CustomKPITrendsWidgetPreview widgetDetails={getPreviewDetails()} />
      </CustomKPITrendContainer>
    </WidgetPreviewWrapper>
  )
}
