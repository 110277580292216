import { TranslateComponent } from "src/common/translations";
import { IconCell } from "src/denali-components/Table/IconCell";
import { type FullDocument, type DocumentAttachment } from "src/pages/documentsAndReports/graphql"
import styles from "./reports.module.scss"
import moment from "moment";
import { Button, Flex } from "@aws-amplify/ui-react";

// downloadFile shared from src/pages/documentsAndReports/DocumentsAndReportsList.tsx
export const DocumentAttachments = ({document, downloadFile}: {document: FullDocument, downloadFile:Promise<(name:string) => void>}) => {
  if(!document) return <></>;

  return (
    <div className={styles.documentModalAttachments}>
      <h2 className={styles.attachmentsHeadline}><TranslateComponent>Attached Files</TranslateComponent></h2>
      <ul className={styles.attachmentsList}>
        {
          document?.attachments?.items?.map(attachment =>
            <li key={attachment.id}>
              <Attachment attachment={attachment} downloadFile={downloadFile} />
            </li>
          )
        }
      </ul>
    </div>
  )
}

const Attachment = ({attachment, downloadFile}: {attachment: DocumentAttachment, downloadFile}) => {
  return (
    <>
      <div className={styles.titleContainer}>
        <IconCell iconType="unknown">
          <div className={`${styles.attachmentName} body-lg`} data-test="reportsRowTitle">{attachment.title}</div>
          <div className="body-md">
            { moment(attachment.attachedAt).format('LLL') } · {convertBytesToString(attachment.sizeInBytes)}
          </div>
        </IconCell>
      </div>
      {attachment.description &&
        <div className={`${styles.attachmentDescription} body-md`}>{attachment.description}</div>
      }
      <div className={styles.attachmentActions}>
        <Button onClick={() => { downloadFile(attachment.name) }}>Download File</Button>
      </div>
    </>
  )
}

const convertBytesToString = (bytes:number, iterations=1) => {
  if(iterations > 4) return `1000+ GB`;

  // Technically this is converting to Kibibyte (KiB) by dividing using 1024 (base 2) not Kilobyte (KB) using 1000 for base 10
  // This is very common and doesn't need to be precise so matching the old component's formula.
  const reduce = bytes / 1024;

  const suffixes = ['Bytes', 'KB', 'MB', 'GB'];
  return reduce < 1
    ? `${Math.round(bytes)} ${suffixes[iterations-1]}`
    : convertBytesToString(reduce, iterations+1)
}