import { useMemo } from "react";
import { shapeGenerator } from "./helper";
import clsx from "clsx";
import { AddSVGProps } from "../../Types/common";
import { isNumber } from "lodash";

const Node = (props: AddSVGProps<any, SVGRectElement>) => {
  const {
    nodeSize = 30,
    nodeShape = "symbolCircle",
    dataTestId,
    className,
    fill = "none",
    stroke = "black",
    cx,
    cy,
    ...elementProps
  } = props;

  const d = useMemo(() => {
    return shapeGenerator(nodeSize, nodeShape);
  }, [nodeSize, nodeShape]);
  const transform = useMemo(() => {
    if (isNumber(cx) && isNumber(cy)) {
      return `translate(${cx}, ${cy})`;
    }
  }, [cx, cy]);
  
  return (
    <path
      className={clsx(className)}
      transform={transform}
      d={d}
      data-testid={dataTestId}
      stroke={stroke}
      fill={fill}
      {...elementProps}
    />
  );
};

export default Node;
