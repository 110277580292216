import React, { useEffect } from "react"
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import { useTranslation } from "react-i18next"

const RunAnalyticsCheckBox = props => {
	const {
		values,
		setFieldValue,
		validateEnableAutomatedTestoption
	} = props

	const { circuitNumber, curveType, runAnalytic } = values
	const [t] = useTranslation()

	return (
		<>
			<Checkbox
				label={t("equipment-setup:CharacteristicsCurves>EnableAutomatedTest")}
				isChecked={runAnalytic}
				id="run-analytics"
				disabled={curveType === "" || circuitNumber === ""}
				name="runAnalytic"
				value={runAnalytic}
				toggleCheck={event => validateEnableAutomatedTestoption(event, values, setFieldValue)}
			/>
		</>
	)
}

export default RunAnalyticsCheckBox
