import styled from 'styled-components'
import colors from 'src/components/layouts/colors.json'

export const Icon = styled.div`
	font-size: 12px;
	align: center;
	display: inline-block;
	padding-left: 3px;
`

export const Divider = styled.span`
	border-right: 1px solid ${colors.TBA_DARKER_GREY};
	height: 20px;
	margin-left: 8px;
	margin-right: 5px;
`

export const StyledSelectButton = styled.button<{ selected: boolean }>`
	align: center;
	width: 20px;
	height: 20px;
	border-radius: 2px;
	margin: 0 5px 0 5px;

	${({ selected }) => selected ? `
		background: ${colors.TBA_DARKER_GREY};
		color: ${colors.WHITE};
		cursor: not-allowed;
	` : `
		background: ${colors.WHITE};
		color: ${colors.TBA_DARKER_GREY};
		cursor: pointer;
	`}

	&:active, &:hover {
		background: ${colors.TBA_DARKER_GREY};
		color: ${colors.WHITE};
	}
`