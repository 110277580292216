import styled from "styled-components"

export const SeasonDateWrapper = styled.div`
  margin-top: 6px;
  margin-right: 20px;
  display: flex;
  vertical-align: middle;
  align-items: center;
`

export const FlexDivHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DateDivBox = styled.div`
  display: flex;
  input {
    color: black;
  }
`

export const DateLabel = styled.div`
  font-weight: normal;
  font-size: 16px;
  font-style: italic;
  align-self: center;
  padding: 0px 10px;
`

export const DateCheckbox = styled.div`
  margin-left: 5px;
`

export const RateSectionLabelSubLabels = styled.span`
  display: flex;
  justify-content: space-between;

  & div.firstLabel {
        justify-content: flex-end;
        border-bottom: 1px solid rgb(191, 191, 191);
        padding-bottom: 9px;
        height: 50px;
        padding: 10px;
        align-items: center;
      }
  & div.firstLabelHeader {
    justify-content: flex-end;
    padding-bottom: 9px;
    height: 50px;
    padding: 10px;
    align-items: center;
  }
  & div.lastLabel{
        height: 50px;
        padding: 10px;
        align-items: center;
      }

  p {
    font-size: 13px;
    font-weight: bold;
  }
`

export const RateFormWrapper = styled.div`
  justify-content: center;
  flex-direction: row;
  align-items: center;
`

export const RateFormLine = styled.div`
  display: flex;
  justify-content: end;
  align-items: flex-end;
  ${props => !props.bottom ? "border-bottom: 1px solid rgb(191, 191, 191);" : ""}
  padding: 10px;
  min-height: 50px;
  input {
    &::-webkit-inner-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    } 
    &::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
  }
`

export const BoldUnitLabel = styled.span`
  font-weight: bold;
`

export const DollarInputSpanWrapper = styled.span`
display: inline-block;
position: relative;
&:before {
  content: "$";
  font-size: 1em;
  font-weight: 400;
  position: absolute;
  top: 5px;
  left: 10px;
}
input {
  max-width: 75px;
  padding-left: 15px;
  text-align: right;
}
`

export const ViewDateLabel = styled.div`
  font-weight: normal;
  padding: 0px 10px;
`

export const RateNameStyledSpan = styled.span`
    align-self: flex-end;
    padding: 0px 10px;
    margin-bottom: 6px;
    font-size: 16px;
    font-style: italic;
`

export const StyledTable = styled.table`
  border: none;
  border-collapse: collapse; 
  cellspacing: 0; 
  table-layout: fixed;

  th {
    text-align: center;
    background-color: rgb(115, 172, 72);
    text-transform: uppercase;
    min-width: 160px;
    color: rgb(255, 255, 255);
    padding: 10px 5px;
    font-weight: 200;
    border: none;
    border-collapse: collapse; 
    cellspacing: 0; 
  }

  td {
    border: none;
    border-collapse: collapse; 
    cellspacing: 0;
  }

`

export const StyledTr = styled.tr`
  background-color: ${props => !(props.index % 2) ? "rgb(237, 237, 237);" : "white;"}
`