/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GroupAndClip } from '../../../../../../chart-library/CommonComponents/Clip'
import { StackedBar } from '../../../../../../chart-library/CommonComponents/StackedBar'
import { useConfig } from './useConfig'
import PlotlineWithTooltip from './PlotLineWithTooltip'
import _isEmpty from 'lodash/isEmpty'

export default function Series({
  series = [],
  costToDateSeries = [],
  tooltipEventName,
  plotlineTooltipEventName,
  plotLine,
  hidePlotLine = true
}) {
  const { isInitialized, xScale, yScale, canvas } = useConfig()

  if (!isInitialized) {
    return null
  }

  const plotLineData = { ...plotLine, name: { ...plotLine?.name, text: !_isEmpty(plotLine?.name?.text) ? plotLine?.name?.text : 'Benchmark' } }
  return (
    <GroupAndClip clipName="stacked-bar-clip" gx={canvas.x} gy={canvas.y}>
      <StackedBar
        barGap={(count) => (count > 1 ? 0.1 : 0.01)} // use this to control the space between bars for comparison charts
        data={series}
        xScale={xScale.fn}
        yScale={yScale.fn}
        enableTooltip
        tooltipDispatchEventName={tooltipEventName}
        tooltipFormatter={null}
        tooltipTriggerPoint={'axis'}
        hideBarText
      />
      <StackedBar
        barGap={(count) => (count > 1 ? 0.25 : 0.2)} // use this to control the space between bars for comparison charts
        data={costToDateSeries}
        xScale={xScale.fn}
        yScale={yScale.fn}
        enableTooltip
        tooltipDispatchEventName={tooltipEventName}
        tooltipFormatter={null}
        tooltipTriggerPoint={'axis'}
        hideBarText
      />

      {!hidePlotLine && (
        <PlotlineWithTooltip tooltipEventName={plotlineTooltipEventName} plotLine={plotLineData} />
      )}
    </GroupAndClip>
  )
}
