import { useState } from 'react'
import {
  PropertyMetaDataContainer,
  CreateButton,
  ExtraInputStyles
} from './styles'
import { Button } from 'src/components/inputs/button'
import {
  GET_PROPERTY_META_DATA,
  DELETE_PROPERTY_META_DATA,
  SEARCH_PROPERTY_META_DATA
} from './graphql'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { useEffect } from 'react'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import Table from 'src/components/Table/clientSideTable'
import { tableHeadings } from './util'
import { CreateEditPropertyMetadata } from './CreateEditModal'
import { ACTIONS } from 'src/constants'
import { TextInput } from '@trane/trane-components/dist/simple/input/textinput.js'
import { Block } from '@trane/trane-components/dist/simple/layout/index.js'
import { BiSearchAlt2 } from 'react-icons/bi/index.esm.js'

export const PropertyMetadataManagement = () => {
  const [showCreateEditPropertyModal, setShowCreateEditPropertyModal] =
    useState(false)

  const [propertyMetadataList, setPropertyMetadataList] = useState([])

  const [searchText, setSearchText] = useState('')

  const [selectedPropertyMetadata, setSelectedPropertyMetadata] = useState(null)

  const {
    data: listPropertyMetadata,
    refetch: refetchPropertyMetadata,
    loading: loadingPropertyMetadata
  } = useQuery({
    query: GET_PROPERTY_META_DATA,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.listPropertyMetadata.items'
  })

  const {
    data: searchPropertyMetaDataList,
    refetch: searachPropertyMetadataList,
    loading: loadingPropertyMetadataSerachList
  } = useQuery({
    query: SEARCH_PROPERTY_META_DATA,
    disableInitialLoad: true,
    errorPolicy: 'global',
    dataPath: 'data.searchPropertyMetadata.items'
  })

  const { onSubmit: deletePropertyMetadata } = useMutation({
    query: DELETE_PROPERTY_META_DATA,
    dataPath: 'data.deletePropertyMetadata'
  })

  useEffect(() => {
    refetchPropertyMetadata()
  }, [])

  const updateListOnCreateUpdate = (updatedData) => {
    try {
      const list = [...propertyMetadataList]
      const index = list.findIndex((x) => x?.id === updatedData?.id)
      if (index === -1) {
        list.push(updatedData)
        setPropertyMetadataList(list)
      } else {
        list[index] = updatedData
        setPropertyMetadataList(list)
      }
      toggleCreateEditModal()
    } catch (error) {}
  }

  useEffect(() => {
    const filters = []
    if (searchText?.length > 2) {
      filters.push({
        tisDisplayName: { matchPhrasePrefix: `*${searchText}*` }
      })

      filters.push({
        sourceKey: { matchPhrasePrefix: `*${searchText}*` }
      })

      filters.push({
        targetKey: { matchPhrasePrefix: `*${searchText}*` }
      })

      const variables = {
        filter: {
          or: filters
        }
      }
      searachPropertyMetadataList(variables)
    } else {
      setPropertyMetadataList(listPropertyMetadata || [])
    }
  }, [searchText])

  useEffect(() => {
    setPropertyMetadataList(searchPropertyMetaDataList || [])
  }, [searchPropertyMetaDataList])

  useEffect(() => {
    setPropertyMetadataList(listPropertyMetadata || [])
  }, [listPropertyMetadata])

  const deleteItemHandler = async (data) => {
    const input = {
      id: data?.id,
      tisDisplayName: data?.tisDisplayName
    }
    //deletePropertyMetadata({input})
  }

  const editItemHandler = (data) => {
    setSelectedPropertyMetadata(data)
    setShowCreateEditPropertyModal(true)
  }

  const toggleCreateEditModal = () => {
    setSelectedPropertyMetadata(null)
    setShowCreateEditPropertyModal(false)
  }

  const createNewPropertyMetadataHandler = () => {
    setSelectedPropertyMetadata(null)
    setShowCreateEditPropertyModal(true)
  }

  const getListRowControl = (control) => {
    return [
      {
        text: ACTIONS.EDIT,
        action: editItemHandler
      },
      {
        text: ACTIONS.DELETE,
        action: deleteItemHandler
      }
    ]
  }

  return (
    <PropertyMetaDataContainer>
      <h1 style={{ fontSize: 17, fontWeight: 700 }}>
        Property Metadata Management
      </h1>
      <hr></hr>
      <div
        style={{
          width: '100%',
          height: 50
        }}
      >
        <div
          style={{
            margin: '10px 0px',
            display: 'flex'
          }}
        >
          <Block width="auto">
            <ExtraInputStyles>
              <TextInput
                onChange={(e) => setSearchText(e.target.value)}
                testName="property-meta-data-table-search"
                value={searchText}
                placeholder="Search for a property"
                icon={<BiSearchAlt2 />}
              />
            </ExtraInputStyles>
          </Block>
          <Button
            variant="primary"
            width="100%"
            onClick={createNewPropertyMetadataHandler}
          >
            Create New Property
          </Button>
        </div>
      </div>

      {loadingPropertyMetadata ? (
        <Spinner />
      ) : (
        <div>
          <Table
            testName="property-meta-data-list"
            header={tableHeadings}
            rows={propertyMetadataList || []}
            rowControl={getListRowControl}
          />
        </div>
      )}
      {showCreateEditPropertyModal && (
        <CreateEditPropertyMetadata
          propertyMetadataDetails={selectedPropertyMetadata}
          toggleCreateEditModal={toggleCreateEditModal}
          updateListOnCreateUpdate={updateListOnCreateUpdate}
        />
      )}
    </PropertyMetaDataContainer>
  )
}
