export function tooltipEventDispatcher(
  event: React.MouseEvent<any>,
  triggerEventName: string,
  elementUniqueKey: string,
  active: boolean,
  data?: TooltipData[]
) {
  elementUniqueKey = (elementUniqueKey || '').replaceAll('.', '-')

  const currentEl = event.currentTarget

  if (active) currentEl.setAttribute(`data-tooltip-${elementUniqueKey}`, '')
  else currentEl.removeAttribute(`data-tooltip-${elementUniqueKey}`)

  const customEvent = new CustomEvent<TooltipEvent>(
    `tooltip-${triggerEventName}`,
    {
      detail: {
        selector: active ? elementUniqueKey : null,
        data
      }
    } as any
  )

  // To trigger the Event
  document.dispatchEvent(customEvent)
}
