import ClientSideTable from 'src/components/Table/clientSideTable'
import { GET_ACCOUNTS } from '../userManagement/graphql/queries'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import translate, { TranslateComponent } from '../../common/translations'
import { useNavigate } from 'react-router'
import { pages } from 'src/pages/pages.js'
import { ACTIONS } from 'src/constants'
import { AccessControl, accessControlFunc } from 'src/components/accessControl'
import { useSelector } from 'react-redux'
import { selectUserAccess } from 'src/redux/slicers/appData'
import { SEARCH_BUILDINGS } from 'src/common/queries/datasource'
import { useQuery } from 'src/hooks/APIHooks'
import { getSearchParams } from "src/common/helperFunctions.js"
import { useEffect, useState } from 'react'


export const OrgManagement = () => {
  const userAccess = useSelector(selectUserAccess)
  const navigate = useNavigate()

  const optionsTranslated = {
    organization: translate('Organization Management'),
    createOrg: translate("Create Organization")
  }

  const {salesOfficeId} = getSearchParams()
  const [rowData, setRowData] = useState([])
  const { refetch: fetchOrgBuildings } = useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    dataPath: 'data.searchBuildings',
  })

  useEffect(() => {
    const reload = async (nextToken) => {
      return await fetchOrgBuildings({filter: {salesOfficeId: {eq: salesOfficeId}}, limit: 1000, nextToken}).then(async e => {
        if (e.nextToken && e?.items.length === 1000) {
          return await reload(e.nextToken).then(res => {
            return [...e.items, ...res || []]
          })
        } else {
          return e.items
        }
      })
    }

    const loadData = async () => {
      await reload(null).then(e => {
        setRowData(
          e?.reduce((accum, curr) => {
            if (accum.findIndex(e => e.id == curr.accountId) === -1) {
              accum.push({
                id: curr.accountId,
                name: curr.accountName
              })
            }
            return accum
          }, [])
        )
      })
    }

    if (salesOfficeId) {
      loadData()

    }
  }, [salesOfficeId])


  const nameHeadings = [
    {
      name: 'name',
      title: optionsTranslated.organization,
      key: 'name',
      onDataClick: (rowData) => {
        navigate(pages["Organization Management/:id"].href.replace(":id", rowData.id), {state:{mode:ACTIONS.VIEW}})
      },
      maxWidth: '120px'
    },
    {
      name: 'actions',
      title: '',
      key: 'actions',
      disabled: true
    }
  ]
  
  return (
    <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <PageHeader
          pageTitle={
            <TranslateComponent>Organization Management</TranslateComponent>
          }
          titleTools={
            <AccessControl id={"tc.pages.organization-management.add"}>
              <Button onClick={() => navigate(
                pages['Organization Management/Add'].href,
                {state:{mode:ACTIONS.ADD}}
               )}>
              {optionsTranslated.createOrg}
            </Button>
            </AccessControl>
            
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
      >
        <ClientSideTable
          header={nameHeadings}
          rows={rowData}
          search={true}
          searchFields={['name']}
          rowControl={ accessControlFunc({id: "tc.pages.organization-management.edit" , userAccess}) ? [{ text: <TranslateComponent>Edit</TranslateComponent>, action: (data) => navigate(
            pages['Organization Management/:id'].href.replace(":id", data.id),
            {state:{mode:ACTIONS.EDIT}}
          )
            }] : undefined
          }
          fixedLayout={false}
      />
      </Content>
    </Container>
  )}
