/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Grid from '../../../chart-library/CommonComponents/Grids/Grid'
import Axis from '../../../chart-library/CommonComponents/AxisNative'
import { format } from 'd3'
import { useConfig } from './useConfig'

export default function Axes() {
  const {
    isInitialized,
    canvas,
    xAxis,
    yAxis,
    xScale,
    yScale,
    axisUpdatedAt,
    scaleUpdatedAt,
    AxisDiM
  } = useConfig()

  if (!isInitialized) {
    return null
  }

  return (
    <>
      <Axis
        key={'x'}
        dimention={AxisDiM.x}
        tickCount={8}
        axisPosition={xAxis.type}
        axisScale={xScale.fn}
        xPosition={xAxis.x}
        yPosition={xAxis.y}
        lastUpdatedAt={axisUpdatedAt + scaleUpdatedAt}
        tickFormat={format('$,')}
      />
      <Axis
        key={'y'}
        hideAixsTickLabel
        hideAxisTick
        dimention={AxisDiM.y}
        axisPosition={yAxis.type}
        axisScale={yScale.fn}
        xPosition={yAxis.x}
        yPosition={yAxis.y}
        lastUpdatedAt={axisUpdatedAt + scaleUpdatedAt}
      />
      {axisUpdatedAt + scaleUpdatedAt && (
        <Grid
          scale={xScale.fn}
          tickCount={8}
          height={canvas.height}
          includeAxis={true}
          opacity={0.3}
          type={'x'}
          transform={`translate(${canvas.x} ${canvas.y})`}
        />
      )}
    </>
  )
}
