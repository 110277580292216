import { assocPath, clone, pathOr } from 'ramda'
// import { AxisDimention } from "../defaults";
import BaseScale from './BaseScale'
import { axisCacheModel } from './model'

const axisPositionType: any = {
  x: 'axisBottom',
  xInside: 'axisTop',
  xOpposite: 'axisTop',
  xOppositeInside: 'axisBottom',
  y: 'axisLeft',
  yInside: 'axisRight',
  yOpposite: 'axisRight',
  yOppositeInside: 'axisLeft'
}

const axisPosition: any = {
  x: ({ x, y, height }: any) => ({ x, y: y + height }),
  xInside: ({ x, y, height }: any) => ({ x, y: y + height }),
  xOpposite: ({ x, y }: any) => ({ x, y }),
  xOppositeInside: ({ x, y }: any) => ({ x, y }),
  y: ({ x, y }: any) => ({ x, y }),
  yInside: ({ x, y }: any) => ({ x, y }),
  yOpposite: ({ x, y, width }: any) => ({ x: x + width, y }),
  yOppositeInside: ({ x, y, width }: any) => ({ x: x + width, y })
}

export default class BaseAxis extends BaseScale {
  private axisCache: BaseCache<AxisDetail> = {}

  private activeAxisKey: BaseActiveKey = {}

  private axes: Axes = {
    xAxis: [],
    yAxis: []
  }

  private axisChangeLog: BaseLog = {}

  protected getAxis(diM: AxisDimention, index: number): AxisDetail | undefined {
    return this.axisCache[diM + index]
  }

  protected getAllAxis(): BaseCache<AxisDetail> {
    return this.axisCache
  }

  protected getAxisChangeLog(
    diM: AxisDimention,
    index: number
  ): string | undefined {
    return this.axisChangeLog[diM + index]
  }

  protected getAllAxisChangeLog(): BaseLog {
    return this.axisChangeLog
  }

  protected resetAxisChangeLog(): void {
    this.axisChangeLog = {}
  }

  protected prepareAxis(axesFromOptions: Axes) {
    const isEqual = this.isConfigEqual(this.axes, axesFromOptions)

    // console.log("prepare axis:", isEqual);
    if (!isEqual) {
      this.axes = clone(axesFromOptions)
      this.initAxes()
    } else {
      this.axisChangeLog = {}
    }
    // console.log("Base Axis : axisCache -", this.axisCache);
  }

  protected handleAxisResize() {
    const reCheckPosition = (axis: InputAxis, index: number) => {
      const key: string = axis.diM + index
      this.setAxisPosition(key, axis)
    }

    this.axes.xAxis.forEach(reCheckPosition)
    this.axes.yAxis.forEach(reCheckPosition)
  }

  private initAxes() {
    this.activeAxisKey = {}

    this.checkEachAxis(this.axes.xAxis)
    this.checkEachAxis(this.axes.yAxis)

    this.refineAxis()
  }

  private checkEachAxis(axes: InputAxis[] = []) {
    axes.forEach((axis, index) => {
      const key: string = axis.diM + index

      this.activeAxisKey[key] = true
      this.setPropToAxisCache(key, 'gridIndex', axis.gridIndex)
      this.setPropToAxisCache(key, 'axis', clone(axis))

      this.setAxisGenerator(key, axis)
      this.setAxisPosition(key, axis)
    })
  }

  private setAxisGenerator(key: string, axis: InputAxis) {
    const position = this.findAxisPosition(axis)
    this.setPropToAxisCache(key, 'type', axisPositionType[position])
  }

  private setAxisPosition(key: string, axis: InputAxis) {
    const grid = this.getGrid(axis.gridIndex)

    if (!grid) {
      // console.log(
      //   'No grid position found to calculate x and y position for axis.'
      // )
      return
    }

    const position = this.findAxisPosition(axis)

    const xyPosition = axisPosition[position](grid)

    this.setPropToAxisCache(key, 'x', xyPosition.x)
    this.setPropToAxisCache(key, 'y', xyPosition.y)
    this.logAxis(key, 'changed')
  }

  private findAxisPosition(axis: InputAxis) {
    let postion = ''

    postion = axis.diM
    postion += axis.opposite ? 'Opposite' : ''
    postion += axis.tickInside ? 'Inside' : ''

    return postion
  }

  private refineAxis() {
    const keys = Object.keys(this.axisCache)
    keys.forEach((key) => {
      if (this.activeAxisKey[key]) return

      delete this.axisCache[key]
      this.logAxis(key, 'removed')
    })
  }

  private setPropToAxisCache(axisKey: string, propKey: string, propValue: any) {
    if (!this.axisCache[axisKey]) {
      this.axisCache = assocPath([axisKey], axisCacheModel(), this.axisCache)
    }

    this.axisCache = assocPath([axisKey, propKey], propValue, this.axisCache)
  }

  private getPropFromAxisCache(axisKey: string, propKey: string) {
    return pathOr(null, [axisKey, propKey], this.axisCache)
  }

  private logAxis(axisKey: string, actionType: string) {
    this.axisChangeLog[axisKey] = actionType
  }

  protected resetAxis() {
    this.axisCache = {}

    this.activeAxisKey = {}

    this.axes = {
      xAxis: [],
      yAxis: []
    }

    this.axisChangeLog = {}
  }
}
