import * as yup from 'yup'
const REQUIRED = 'required'

export const blankDashboardSchema = () =>
  yup.object().shape({
    name: yup.string().required('Please enter dashboard name').test('len', 'Dashboard name is too long. Please shorten it to 200 characters or less.', val => val.length <= 200),
    description: yup.string().notRequired(),
    isDefault: yup.mixed().oneOf(['0', '1']).required(REQUIRED),
    isPublic: yup.mixed().oneOf(['0', '1']).required(REQUIRED),
    isKeyMetric: yup.mixed().oneOf(['0', '1']).required(REQUIRED),
  })

export const preConfiguredDashboardSchema = () =>
  yup.object().shape({
    name: yup.string().required('Please enter dashboard name'),
    description: yup.string().notRequired(),
    isDefault: yup.mixed().oneOf(['0', '1']).required(REQUIRED),
    isPublic: yup.mixed().oneOf(['0', '1']).required(REQUIRED),
    isKeyMetric: yup.mixed().oneOf(['0', '1']).required(REQUIRED),
    organization: yup.string().required('The organization is required'),
    building: yup.string().required('The building is required')
  })


// export default schema
