import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Text, View } from "@react-pdf/renderer"
import { contactStyles } from "./pdf-contact.styles"

const PDFContact = ({ contact, phone, email }) => {
	const { contactWrapper, contactName, contractTitle } = contactStyles
	const isEmpty = useMemo(() => !contact?.length && !phone?.length && !email?.length, [contact, phone, email])

	return	!isEmpty ? <View style={contactWrapper} wrap={false}>
		<Text style={contractTitle}>Contact your Trane Representative</Text>
		<Text style={contactName}>{contact}</Text>
		<Text>{phone}</Text>
		<Text>{email}</Text>
	</View> : null
}

PDFContact.propTypes = {
	contact: PropTypes.string,
	phone: PropTypes.string,
	email: PropTypes.string,
}

PDFContact.defaultProps = {
	contact: "",
	phone: "",
	email: "",
}

export default PDFContact
