import styled from 'styled-components'

export const StyledBreadcrumbHeader = styled.div`
  background: linear-gradient(90deg, #666 64px, #666) 63px no-repeat,
    linear-gradient(-240deg, transparent 50%, #666 0) 54px 15px/10px 14px
      no-repeat,
    linear-gradient(240deg, #666 50%, transparent 0) 54px 0/10px 14px no-repeat,
    linear-gradient(90deg, #666 12.8px, #999 64px);
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  height: 27px;
`
