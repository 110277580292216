import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const PageContainer = styled.div`
align-items: center;
display: flex;
flex-direction: column;
justify-content: center;
min-height: calc(100vh - 201px);
`

export const SorryMessage = styled.h3`
color: #88974f;
float: left;
margin-right: 10px;
font-size: 2.625rem;
font-weight: 700;
margin:0;
line-height: 3.125rem;`

export const MessageTextContainer = styled.div`
margin:16px;`


export const MessageText = styled.p`
font-size: 1rem;
margin:5px`


export const SpanBack = styled.span`
cursor:pointer;
color:black;
text-decoration:underline`


export const NavigationLink = styled(Link)`
cursor:pointer;
color:black;
text-decoration:underline`
