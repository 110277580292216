import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Predictions } from "aws-amplify";
import { isEmpty } from "ramda";

interface TranslationState {
    originalJSON: Record<string, any>;
    translatedJSON: Record<string, any>;
    translationsMap: Record<string, string>; // Stores translation by language
    // translationsMap: Record<string, Record<string, string>>; // Stores translation by language
    currentLanguage: string;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: TranslationState = {
    originalJSON: {},
    translatedJSON: {},
    translationsMap: {}, // Stores only the current language translations
    currentLanguage: 'en', // Default to English
    status: 'idle',
    error: null,
}

// Collect Texts (Removes duplicates, but keep original paths)
const collectTexts = (obj: Record<string, any>, path = ''): [string, string][] => {
    let texts: [string, string][] = []

    for (const key in obj) {
        const fullPath = path ? `${path}.${key}` : key
        if (typeof obj[key] === 'object') {
            texts = texts.concat(collectTexts(obj[key], fullPath))
        } else {
            if (!texts.some(([_, value]) => value === obj[key]))
                texts.push([fullPath, obj[key]])
        }
    }
    return texts
}

// Set Texts based on the original JSON path and translated values
const setTexts = (obj: Record<string, any>, texts: [string, string][]) => {
    const textMap = new Map(texts)
    const setNestedTexts = (obj: Record<string, any>, path = '') => {
        for (const key in obj) {
            const fullPath = path ? `${path}.${key}` : key
            if (typeof obj[key] === 'object') {
                setNestedTexts(obj[key], fullPath)
            } else if (textMap.has(fullPath)) {
                const translatedValue = textMap.get(fullPath)
                if (translatedValue) {
                    obj[key] = translatedValue
                }
            }
        }
    }
    setNestedTexts(obj)
}

export const translateJSON = createAsyncThunk(
    'translation/translateJSON',
    async ({ json }: { json: Record<string, any> }, thunkAPI) => {
        const state = thunkAPI.getState() as any;
        const targetLanguage = state.appData?.selectedLanguage || 'en'
        const { translationCombinedSlice: { translationsMap, currentLanguage } } = state

        if (targetLanguage == "en") {
            return json
        }

        //return json if it is empty
        if (isEmpty(json)) {
            return json
        }

        if (targetLanguage !== 'en') {
            const texts = collectTexts(json)
            const textsToTranslateStr = texts.map(([, value]) => value).join('\n')

            try {
                const result = await Predictions.convert({
                    translateText: {
                        source: {
                            text: textsToTranslateStr,
                            language: 'en',
                        },
                        targetLanguage: targetLanguage,
                    }
                })

                const transResult = result.text.split('\n')

                // Save new translations in the state (reset the translationsMap for the new language)
                const newTranslations: Record<string, string> = {}
                texts.forEach(([, value], index) => {
                    newTranslations[value] = transResult[index]
                })

                // Reset translationsMap to store only the current language translations
                const updatedTranslationsMap = {
                    ...newTranslations // Only store translations for the current language
                }

                /** Reset translationsMap to store the transltions based on the language
                const updatedTranslationsMap = {
                    ...translationsMap,
                    [targetLanguage]: {
                        ...translationsMap[targetLanguage],
                        ...newTranslations
                    },
                }
                */

                const translatedObj = JSON.parse(JSON.stringify(json))
                setTexts(translatedObj, texts.map(([path], index) => [path, transResult[index]] as [string, string]))

                // Update the state with the new translations and current language
                thunkAPI.dispatch(updateTranslationsMap(updatedTranslationsMap))
                thunkAPI.dispatch(setCurrentLanguage(targetLanguage))

                return translatedObj
            } catch (error) {
                console.log(error)
                const message = error.message;
                return thunkAPI.rejectWithValue(message);
            }
        }

        // Use cached translations if the language hasn't changed
        const texts = collectTexts(json)

        /** set language based on targetLanuage
        const existingTranslations = texts.map(([path, value]) => [path, translationsMap[targetLanguage]?.[value] || value] as [string, string])
        */

        const existingTranslations = texts.map(([path, value]) => [path, translationsMap[value] || value] as [string, string])
        const translatedObj = JSON.parse(JSON.stringify(json))
        setTexts(translatedObj, existingTranslations)

        return translatedObj
    }
);

export const translationCombinedSlice = createSlice({
    name: 'translation',
    initialState,
    reducers: {
        setOriginalJSON: (state, action: PayloadAction<Record<string, any>>) => {
            state.originalJSON = action.payload;
        },
        updateTranslationsMap: (state, action: PayloadAction<Record<string, string>>) => {
            state.translationsMap = action.payload // Overwrite with the new language translations
        },
        setCurrentLanguage: (state, action: PayloadAction<string>) => {
            state.currentLanguage = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(translateJSON.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(translateJSON.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.translatedJSON = action.payload;
            })
            .addCase(translateJSON.rejected, (state, action: PayloadAction<any>) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { setOriginalJSON, updateTranslationsMap, setCurrentLanguage } = translationCombinedSlice.actions;
export default translationCombinedSlice.reducer;
