import { createContext, useMemo, useContext } from 'react'
import { legendGroupModel, legendItemModel } from '../config'
import { Legend, LegendCompProps, LegendsGroup } from '../types'
import LegendApi, { ChartLegendState } from './LengendApi'

export interface LegendApiContext {
  isWrappedByLegendProvider: boolean
  notifyComponentToggleStatus: (isOpen: boolean) => void
  listenLegendComponentToggleStatus: (
    listener: (isOpen: boolean) => void
  ) => void
  postLegendComponentProps: (props: LegendCompProps) => void
  init: () => ChartLegendState
  unmount: (localChartStateId) => void

  createLegendModel: (legendItem: Legend) => Legend
  createLegendGroupModel: (
    legendItems: Legend[],
    groupItem: Legend | null
  ) => LegendsGroup
}

const defaultApiContext: LegendApiContext = {
  isWrappedByLegendProvider: false,
  notifyComponentToggleStatus: () => null,
  listenLegendComponentToggleStatus: () => null,
  postLegendComponentProps: () => null,
  init: () => new ChartLegendState(null),
  unmount: (id) => {},
  createLegendModel: (legendItem: Legend) => legendItemModel(),
  createLegendGroupModel: (legendItems: Legend[], groupItem: Legend | null) =>
    legendGroupModel(legendItems, groupItem)
}

const LegendApiContext = createContext<LegendApiContext>(defaultApiContext)

export function useLegendApiContext() {
  return useContext<LegendApiContext>(LegendApiContext)
}

export default function LegendApiProvider({ children, updateState }: any) {
  const registry = useMemo<LegendApiContext>(
    () => new LegendApi(updateState),
    [updateState]
  )
  return (
    <LegendApiContext.Provider value={registry}>
      {children}
    </LegendApiContext.Provider>
  )
}
