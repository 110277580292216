import Modal from 'src/components/legacy/components/modal/modal'
import { getUniqueItems } from 'src/common/helperFunctions'
import Label from 'src/components/legacy/components/label/label'
import { StepTitle, CRMSiteIdDiv, CRMSiteIDDescription, BuildingCRMSiteIdDiv, StyledErrorDiv } from './styles'
import { useEffect, useState } from 'react'
import { UPDATE_BUILDING } from 'src/pages/organizationManagement/createOrganization/queries/queries'
import { useMutation } from 'src/hooks/APIHooks'

export const AddCRMSiteIDsModal = ({
  handleCancel,
  selectedBuildings,
  offeringDDLData,
  handleAddBuildings,
  loading,
  setOfferingDDLData
}) => {
  const { onSubmit: updateBuilding } = useMutation({
    query: UPDATE_BUILDING
  })

  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (offeringDDLData && offeringDDLData?.length) {
      const updatedData = offeringDDLData.map(obj => {
          return {
            ...obj,
            isCRMIdEmpty: obj?.crmCustId && (obj?.crmCustId && obj?.crmCustId !== '0') ? true : false
          };
      })
      setOfferingDDLData(updatedData)
    }
  }, [])

  useEffect(() => {
    let buildingCountWithoutCRM = []
    selectedBuildings.map((building) => {
      const getCRMSiteID = offeringDDLData.find(({ key }) => key === building)
      if (!getCRMSiteID?.crmCustId || getCRMSiteID?.crmCustId === '0') {
        buildingCountWithoutCRM.push(getCRMSiteID) 
      }
      return buildingCountWithoutCRM
    })
    if(buildingCountWithoutCRM?.length > 0) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  },[offeringDDLData])

  return (
    <Modal
      className={`Addcrmsiteids-dialog`}
      gray={true}
      heading={'Add Builings'}
      buttons={[
        { type: 'cancel', text: 'cancel', handleClick: handleCancel },
        {
          type: 'confirm',
          text: `Add ${selectedBuildings.length} Buildings`,
          disabled: showError,
          handleClick: async() => {
            // Update Building CRM IDs Value
            const updatedCRMIdBuildings = offeringDDLData?.filter(f => f?.isCRMSiteIdUpdated) ?? []
            for (const building of updatedCRMIdBuildings) {
            await updateBuilding({ input: { id: building?.key, crmCustId: building.crmCustId } })
          }
            handleAddBuildings()
          }
        }
      ]}
      handleClose={handleCancel}
      loading={loading}
    >
      <>
      <StepTitle>Step 2 of 2: Confirm CRM Site IDs</StepTitle>
      <CRMSiteIDDescription>Enter or validate the CRM site IDs if they are known. CRM site IDs can also be added by building technicians at the time of Building Setup.</CRMSiteIDDescription>
      {selectedBuildings.map((building) => {
            const getCRMSiteID = offeringDDLData.find(({ key }) => key === building)
              return <BuildingCRMSiteIdDiv key={building}>
                <Label text={<span>{getCRMSiteID?.label || ""}</span>}></Label>
                <CRMSiteIdDiv>
                <Label text={
                  <span>
                    CRM Site ID*
                  </span>
                } />
                <input value={getCRMSiteID?.crmCustId || ''}
                className={!getCRMSiteID?.crmCustId ? "input_errro_border" : ""}
                disabled={getCRMSiteID?.isCRMIdEmpty}
                onChange={(e) => {
                  const updatedOfferingDDLData = offeringDDLData.map(obj => {
                    if (obj.key === getCRMSiteID?.key) {
                      return {
                        ...obj,
                        crmCustId: e.target.value,
                        isCRMSiteIdUpdated: true,
                      };
                    } else {
                    return {...obj, isCRMSiteIdUpdated: false}
                    }
                  });
                  setOfferingDDLData(updatedOfferingDDLData)
                }} />
                </CRMSiteIdDiv>
              </BuildingCRMSiteIdDiv>
            })}
      </>
      {showError && <StyledErrorDiv>All buildings must have a CRM Site ID before requesting activation</StyledErrorDiv>}
    </Modal>
  )
}
