import React from 'react'
import PropTypes from 'prop-types'
import {
  ACTIONS,
  PLACEHOLDER,
  DEFAULT_POSSIBLE_STATE,
  CONVERTED,
  STATE_TYPES,
  MULTISTATEPOINT
} from './constants'

const minimumStateLength = 2
const incrementState = 1

export const CharacteristicStates = ({
  mode,
  state,
  characteristic,
  setFieldValue,
  equipmentType,
  errorMessage,
  testName
}) => {
  const [selectedState] = (state?.[1]?.possibleStatesList || []).filter(
    ({ propertyStateValue, selected }) =>
      selected && propertyStateValue === CONVERTED
  )
  characteristic.possibleStatesList =
    characteristic.possibleStatesList?.length > 1
      ? characteristic.possibleStatesList
      : Array(2).fill(DEFAULT_POSSIBLE_STATE)
  const { targetKey, readOnly, possibleStatesList } =
    characteristic
  if (!selectedState) {
    return null
  }
  const addState = () =>
    setFieldValue(targetKey, {
      ...characteristic,
      userDefinedIndicator: true,
      possibleStatesList: [...possibleStatesList, ...[DEFAULT_POSSIBLE_STATE]]
    })
  const removeState = (key) =>
    setFieldValue(targetKey, {
      ...characteristic,
      userDefinedIndicator: true,
      possibleStatesList: [
        ...possibleStatesList.filter((_, index) => index !== key)
      ]
    })
  const getCharacteristicInput = ({ characteristicValue, key, name }) => (
    <>
      <label> {name} </label>
      {mode === ACTIONS.VIEW || readOnly ? (
        <div>{characteristicValue || PLACEHOLDER}</div>
      ) : (
        <div className="param__value">
          <div className="fields-group">
            <div className="input-group state-input-group">
              <input
              testName = {`${testName}_${name}`}
                className="input-field"
                value={characteristicValue || ''}
                onChange={({ target: { value } }) => {
                  const stateKey = Object.keys(STATE_TYPES).find(
                    (key) => STATE_TYPES[key] === name
                  )
                  const inputValue = value === '' ? null : value
                  setFieldValue(targetKey, {
                    ...characteristic,
                    userDefinedIndicator: true,
                    possibleStatesList: [
                      ...possibleStatesList.map((item, index) => ({
                        ...item,
                        [stateKey]: index === key ? inputValue : item[stateKey]
                      }))
                    ]
                  })
                }}
              />
            </div>
            <label className="value-type">
              {mode === ACTIONS.EDIT &&
                equipmentType === MULTISTATEPOINT &&
                name === STATE_TYPES.propertyStateValue && (
                  <div className="state-btn-wrapper">
                    <button
                    testName = {`${testName}_removeBtn`}
                      type="button"
                      disabled={possibleStatesList.length <= minimumStateLength}
                      className="icon-trash2"
                      onClick={() => removeState(key)}
                    />
                    <button
                     testName = {`${testName}_addBtn`}
                      type="button"
                      className="icon-add"
                      onClick={addState}
                    />
                  </div>
                )}
            </label>
          </div>
        </div>
      )}
    </>
  )
  return (
    <>
      {(possibleStatesList || []).map((state, key) => (
        <div key={key}>
          <div className="state-title"> State {key + incrementState} </div>
          <div className="rx-grid">
            <div className="rx-col-6">
              <div className="rx-grid">
                <div className="rx-col-12">
                  {getCharacteristicInput({
                    characteristicValue: state.propertyStateName,
                    key,
                    name: STATE_TYPES.propertyStateName
                  })}
                </div>
              </div>
            </div>
            <div className="rx-col-6">
              <div className="rx-grid">
                <div className="rx-col-12">
                  {getCharacteristicInput({
                    characteristicValue: state.propertyStateValue,
                    key,
                    name: STATE_TYPES.propertyStateValue
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </>
  )
}

CharacteristicStates.propTypes = {
  characteristic: PropTypes.object,
  mode: PropTypes.string,
  setFieldValue: PropTypes.func,
  errorMessage: PropTypes.string
}

export default CharacteristicStates
