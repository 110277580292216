/* eslint-disable @typescript-eslint/no-non-null-assertion */
import PlotLine from '../../../../../../chart-library/CommonComponents/PlotLine/PlotLine'
import { useConfig } from './useConfig'
import { useTooltip } from '../../../../../../chart-library/CommonComponents/Tooltip'

function defaultTootipFormatter(data: any): TooltipData[] {
  return [
    {
      items: [
        {
          content: `${data?.name || ''} - ${data?.value}`,
          contentOptions: {
            color: data?.text?.fontColor,
            size: data?.text?.fontSize
          },
          icon: true,
          iconOptions: {
            color: data?.stroke
          }
        }
      ]
    }
  ]
}

export default function PlotlineWithTooltip({
  tooltipEventName,
  tooltipFormatter = defaultTootipFormatter,
  plotLine,
}) {

  const { isInitialized, yScale, canvas } = useConfig()

  const [tooltipDispatch] = useTooltip({
    dispatchEventName: tooltipEventName,
    disable: plotLine?.hideTooltip,
    formatter: tooltipFormatter
  })


  if (!isInitialized) {
    return null
  }


  return (
      <PlotLine
        axisType={'y'}
        scale={yScale.fn}
        width={canvas.width}
        plotObj={plotLine}
        onMouseOver={(event) => tooltipDispatch(event, tooltipEventName, true, plotLine)}
        onMouseLeave={(event) => tooltipDispatch(event, tooltipEventName, false, [])}
      />
  )
}
