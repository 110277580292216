import * as yup from 'yup'

const REQUIRED = 'required'
const schema = () =>
  yup.object().shape({
    orgName: yup.string().required(REQUIRED),
    selectedBuildings: yup.array().required(REQUIRED)
  })

export default schema
