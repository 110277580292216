import RBush from 'rbush'

type RItem<D = any> = {
  rId: string | number | null | undefined
  data?: D
  isBox?: boolean
  type: 'Point' | 'Box' // Default : Point
}

export interface RBox<D = any> extends RItem<D> {
  x: number
  y: number
  maxX: number
  maxY: number
}

interface RPoint extends RItem {
  x: number
  y: number
}

function equalFn<T extends RPoint | RBox>(a: T, b: T): boolean {
  return a.rId === b.rId
}

export default class Rbush<T extends RPoint | RBox> extends RBush<T> {
  constructor(public isAxis = true) {
    super()
  }

  toBBox(item: T): any {
    return {
      minX: item.x,
      minY: 0, //item.y,
      maxX: (item as RBox).maxX,
      maxY: 0 //(item as RBox).maxY
    }
  }

  compareMinX(a: T, b: T): number {
    return a.x - b.x
  }

  compareMinY(a: T, b: T): number {
    return a.y - b.y
  }

  remove(item: T, equals: (a: T, b: T) => boolean = equalFn): RBush<T> {
    return super.remove(item, equals)
  }

  insert(items: T | T[]): RBush<T> {
    const isArray = Array.isArray(items)
    return isArray ? super.load(items) : super.insert(items)
  }
}
