// Default Options.
import { useMemo } from 'react'

export function useOptions(categories, yAxisMin, yAxisMax): Options {
  return useMemo<Options>(
    () => ({
      xAxis: {
        type: 'scaleBand',
        data: categories
      },
      yAxis: {
        type: 'scaleLinear',
        min: yAxisMin,
        max: yAxisMax
      },
      grid: [{ left: 0, right: 0, top: 0, bottom: 0 }]
    }),
    [categories, yAxisMin, yAxisMax]
  )
}
