export const light = {
  color: '{colors.neutral.90}',

  row: {
    borderColor: '{colors.neutral.20}',
    hover: {
      borderColor: '{colors.neutral.30}',
    },
  },

  chip: {
    backgroundColor: '{colors.neutral.10}',
    color: '{colors.neutral.60}',

    blue: {
      backgroundColor: '{colors.blue.lightest}',
      color: '{colors.blue.50}',
    },
    orange: {
      backgroundColor: '{colors.orange.lightest}',
      color: '{colors.orange.50}',
    },
    purple: {
      backgroundColor: '{colors.purple.lightest}',
      color: '{colors.purple.50}',
    },
    green: {
      backgroundColor: '{colors.green.lightest}',
      color: '{colors.green.60}',
    },
    darkGreen: {
      backgroundColor: '{colors.green.80}',
      color: '{colors.green.50}',
    },
    black: {
      backgroundColor: '{colors.black}',
      color: '{colors.white}',
    },
    transparentBlack: {
      backgroundColor: 'rgba(63, 63, 63, 0.63)',
      color: '{colors.white}',
    },
    transparentGray: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    gold: {
      backgroundColor: '{colors.gold.50}',
      color: '{colors.gold.100}',
    },
    pink: {
      backgroundColor: '{colors.pink.lightest}',
      color: '{colors.pink.50}',
    },
    red: {
      backgroundColor: '{colors.red.lightest}',
      color: '{colors.red.50}',
    },
    redInverse: {
      backgroundColor: '{colors.red.50}',
      color: '{colors.white}',
    }
  },

  visibilityIcon: {
    backgroundColor: '{colors.neutral.10}',
    color: '{colors.neutral.60}',
    border: '{colors.neutral.90}',
  },

  filterBackground: '{colors.neutral.10}',
  filterColor: '{colors.neutral.90}',
  filterScroll: '{colors.gradients.scroll.fadeUp}',

  rowActionColor: '{colors.neutral.90}',

  hover: {
    filterBackground: '{colors.neutral.10}',
    filterColor: '{colors.neutral.90}'
  },

  filterPopup: {
    background: '{colors.white}',
  },
}