import DocumentsAndReportsContainer from './DocumentsAndReportsContainer'
import { DocumentsAndReportsContextProvider } from './DocumentsAndReportsContextProvider'

export const DocumentsAndReports = ({ action }) => {
  return (
    <DocumentsAndReportsContextProvider>
      <DocumentsAndReportsContainer action={action} />
    </DocumentsAndReportsContextProvider>
  )
}
