import { useEffect } from 'react'
import {
  selectAxes,
  selectGeneratedScales,
  selectSeriesData,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
import Path from 'src/chart-library/CommonComponents/LinePath/LinePath'
import { getBandWidth } from '../helper'

import { generatorFunction } from '../../../Shapes/picker'
import { useSyncSeriesPointsForTooltip } from '../../../CommonComponents/TooltipNew'
import { SCALE_BAND, SCALE_TIME } from '../../../Utils/Scales/constant'
import { isNumber } from 'lodash'

const isNil = (val) => val === undefined || val === null

const dataPointFunction = (
  scaleType,
  scale: any,
  d: any = [],
  bandWidth = 0
) => {
  if (scaleType === SCALE_TIME) {
    return scale(new Date(d))
  } else if (scaleType === SCALE_BAND) {
    return scale(d) + bandWidth
  } else {
    return scale(isNumber(d) ? d : null)
  }
}

function lineTooltipModel(data, tooltipDataModel) {
  const {
    xScale = () => null,
    yScale = () => null,
    xscaleType,
    yscaleType,
    xData = [],
    yData = [],
    obj = {}
  } = data

  const {
    name,
    elementProps: { stroke = 'steelblue' },
    tooltipDataModelFormatter = () => null
  } = obj

  const bandWidth = xscaleType === 'scaleBand' ? getBandWidth(xScale) / 2 : 0
  return xData.reduce((av, xVal, i) => {
    const yVal = yData[i]

    const x = dataPointFunction(xscaleType, xScale, xVal, bandWidth)
    const y = dataPointFunction(yscaleType, yScale, yVal, bandWidth)
    const convertedN = parseFloat(x?.toFixed(3))
    if (isNil(x) || isNil(y)) return av
    const defaultProps = {
      label: name,
      value: yVal,
      title: xVal,
      groupBy: 'title',
      color: stroke,
      x: convertedN,
      y,
      xValue: xVal,
      yValue: yVal,
      higlightDots: true,
      // no of dots of a data point
      highlightDots: [
        {
          x,
          y,
          color: stroke
        }
      ]
    }

    const formatter = tooltipDataModelFormatter({
      ...defaultProps,
      ...obj
    })

    av.push(
      tooltipDataModel(x, {
        // Default model values
        ...defaultProps,

        // user modified values
        ...formatter
      })
    )

    return av
  }, [])
}

const LineRenderer = (props: any) => {
  const { types = [] } = props
  const axes = useSelector(selectAxes)
  const generatedScales = useSelector(selectGeneratedScales)
  const seriesData = useSelector(selectSeriesData)

  const { addPoints, deletePoints, createTooltipPointModel } =
    useSyncSeriesPointsForTooltip()

  useEffect(() => {
    const inputModel = types?.reduce((av = [], obj: any, i: number) => {
      const xAxisObj = axes?.find((axisObj: any) => axisObj?.key === obj?.xAxisKey)
      const yAxisObj = axes?.find((axisObj: any) => axisObj?.key === obj?.yAxisKey)
      const xObject = xAxisObj?.scale
      const yObject = yAxisObj?.scale
      const gXScaleObj = generatedScales?.[obj?.xAxisKey]
      const gYScaleObj = generatedScales?.[obj?.yAxisKey]
      const yData = seriesData?.[obj?.seriesKey]?.data
      const xscaleType = xObject?.props?.type
      const yscaleType = yObject?.props?.type
      const xScale = gXScaleObj?.gScale
      const yScale = gYScaleObj?.gScale
      const xData = xObject?.categories

      const lineModel2 = lineTooltipModel(
        {
          obj,
          xscaleType,
          yscaleType,
          xData,
          yData,
          xScale,
          yScale,
          invert: false,
          settings: obj?.settings,
          type: obj?.type,
          min: gYScaleObj?.domain?.[0]
        },
        createTooltipPointModel
      )

      return av.concat(lineModel2)
    }, [])

    if (inputModel.length) {
      addPoints(inputModel)
    }

    return () => {
      inputModel.length && deletePoints(inputModel)
    }
  }, [generatedScales, types])

  return (
    <>
      {types?.map((obj: any, i: number) => {
        const xAxisObj = axes?.find((axisObj: any) => axisObj?.key === obj?.xAxisKey)
        const yAxisObj = axes?.find((axisObj: any) => axisObj?.key === obj?.yAxisKey)
        const xObject = xAxisObj?.scale
        const yObject = yAxisObj?.scale
        const gXScaleObj = generatedScales?.[obj?.xAxisKey]
        const gYScaleObj = generatedScales?.[obj?.yAxisKey]
        const yData = seriesData?.[obj?.seriesKey]?.data
        const xscaleType = xObject?.props?.type
        const yscaleType = yObject?.props?.type
        const xScale = gXScaleObj?.gScale
        const yScale = gYScaleObj?.gScale
        const xData = xObject?.categories
        const d = generatorFunction(obj?.type, {
          xscaleType,
          yscaleType,
          xData,
          yData,
          xScale,
          yScale,
          invert: false,
          settings: obj?.settings,
          properties: obj?.properties,
          type: obj?.type,
          min: gYScaleObj?.domain?.[0]
        })

        return (
          <Path
            key={i}
            dashType={obj?.dashType}
            stroke={obj?.elementProps?.stroke}
            strokeWidth={obj?.elementProps?.strokeWidth}
            d={d}
          />
        )
      })}
    </>
  )
}

export default LineRenderer
