import Select from 'src/components/legacy/components/select/select'
import { Container, FilterSection, Line } from './Styled'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import { useNavigate } from 'react-router'
import { useQuery } from 'src/hooks/APIHooks'
import { getTestSettingsSummary } from 'src/pages/automatedTestSettings/graphql'
import { formatSettingSummary } from 'src/pages/automatedTestSettings/util'
import { useEffect, useState } from 'react'
import { utcTimestampToDateFormat } from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import {
  BACKEND_DATE_FORMAT
} from 'src/components/legacy/common/time-helpers'
import { setAutomatedSettingEquipment } from 'src/redux/slicers/buildingPicker'
import { TranslateComponent } from 'src/common/translations'

export default function HistoryFilterSection({
  setSelectedKey,
  selectedKey,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  buildingId,
  equipmentDetail
}) {
  const [viewportTests, setViewportTests] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<any>({})

  const {
    data: automatedSettingsSummary,
    refetch: refetchAutomatedSettingsSummary,
    loading: loadingAutomatedSettingsSummary
  } = useQuery({
    query: getTestSettingsSummary,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getATSettings'
  })

  const getSettingsByBuilding = async (buildingId) => {
    try {
      const input = {
        req_bid: buildingId,
        req_type: 'st',
        ht_type: 'all',
        isSummary: 0,
        histStDt: moment(startDate).format(BACKEND_DATE_FORMAT),
        histEndDt: moment(endDate).add(1, 'days').format(BACKEND_DATE_FORMAT)
      }
      const summaryData = await refetchAutomatedSettingsSummary({
        body: JSON.stringify(input)
      })
      const parsedData = JSON.parse(summaryData)
      const finalSummary = formatSettingSummary(parsedData?.settings || {})
      setViewportTests(finalSummary)
    } catch (e) {}
  }

  useEffect(() => {
    if (buildingId) {
      getSettingsByBuilding(buildingId)
    }
  }, [buildingId])

  const handleAutomatedTestHistory = () => {
    dispatch(setAutomatedSettingEquipment(null))
    navigate({
      pathname: '/automated-test-settings'
    })
  }

  const validateDates = (start, end) => {
    if (start && end && moment(start) > moment(end)) {
      setErrors({ endPeriod: `End Date can't be earlier than Start Date` });
    } else {
      setErrors({});
    }
  };
  const onEndChange = (date) => {
    setEndDate(utcTimestampToDateFormat(date))
    validateDates(startDate, date);
  }

  const onStartChange = (date) => {
    setStartDate(utcTimestampToDateFormat(date))
    validateDates(date, endDate);
  }
  
  const testHistoryList = () => {
    let viewPortData = viewportTests;
    if(equipmentDetail?.family){
      viewPortData = viewPortData?.filter(it=>it.equipmentType === equipmentDetail?.family)
    }
    const arr = viewPortData.map((it) => ({
      key: it?.key,
      description: `${it?.displayName}(${it?.equipmentType})`
    }))
    arr.unshift({ key: 'allTests', description: 'All Automated Tests' })
    return arr
  }

  return (
    <Container className="row-gap">
      {/* Choose test and back to all test block */}
      <FilterSection className="gap-start-end">
        <div className="filter-item">
          <span><TranslateComponent>{"Test"}</TranslateComponent></span>:
          <Select
            options={testHistoryList()}
            placeholder=""
            className="dropdown-select"
            selectedItem={
              testHistoryList()?.find((it) => it?.key === selectedKey)
                ?.description || ''
            }
            onChange={(e) => {
              setSelectedKey(e)
            }}
          />
        </div>
        <div className="back-to-all-tests">
          <a onClick={() => handleAutomatedTestHistory()}><TranslateComponent>Back to all tests</TranslateComponent></a>
        </div>
      </FilterSection>
      <Line />

      {/* Filter by options */}
      <FilterSection className="gap-start-end">
        <div className="filter-item">
          <span><TranslateComponent>From</TranslateComponent>:</span>
          <ModalDatePickerInput
            date={startDate}
            className="date-picker"
            onChange={onStartChange}
            hasError={Boolean(errors?.endPeriod)}
            errorMessage={errors?.endPeriod}
          />
        </div>
        <div className="filter-item">
          <span><TranslateComponent>To</TranslateComponent>:</span>
          <ModalDatePickerInput
            date={endDate}
            className="date-picker"
            onChange={onEndChange}
            hasError={Boolean(errors?.endPeriod)}
            errorMessage={errors?.endPeriod}
          />
        </div>
      </FilterSection>
    </Container>
  )
}
