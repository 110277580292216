export type SalesOfficeId = string;
export type OrganizationId = string;
export type BuildingId = string;

export const SEARCH_BUILDINGS = /* GraphQL */ `
  query SearchBuildings(
    $filter: SearchableBuildingFilterInput
    $sort: [SearchableBuildingSortInput]
    $limit: Int
  ) {
    searchBuildings(filter: $filter, sort: $sort, limit: $limit) {
      items {
        name
        id
        address
        isActive
        accountName
        accountId
        salesOfficeId
        salesOfficeName
      }
    }
  }
`

export interface SearchableBuildingFilterElement {
  accountName?
  name?
  address?
  accountId?
  or?: SearchableBuildingFilterElement[]
  and?: SearchableBuildingFilterElement[]
}

export interface SearchableBuildingFilterInput {
  or?: SearchableBuildingFilterElement[]
  and?: SearchableBuildingFilterElement[]
}

export type SearchableBuildingSortInput = any;

export interface SearchBuildingsQueryVariables {
  id?
  filter?: SearchableBuildingFilterInput
  sort?: SearchableBuildingSortInput[]
  limit: number
}

export type SearchResultBuilding = {
  accountId: string,
  accountName: string,
  address: string,
  id: BuildingId,
  isActive: 0|1,
  name: string,
  salesOfficeId: string,
  salesOfficeName: string,
}

export const SEARCH_SALES_OFFICE = /* GraphQL */ `
  query searchSalesOffices(
    $filter: SearchableSalesOfficeFilterInput
    $sort: [SearchableSalesOfficeSortInput]
    $limit: Int
  ) {
    searchSalesOffices(filter: $filter, sort: $sort, limit: $limit) {
      items {
        name
        id
        district
      }
    }
  }
`

export const SAVE_USER_HISTORY = `
  mutation createUserHistory($input: CreateUserHistoryInput!) {
    createUserHistory(input: $input) {
      id
    }
  }
`
export const DELETE_USER_HISTORY = `
  mutation deleteUserHistory($input: DeleteUserHistoryInput!) {
    deleteUserHistory(input: $input) {
      id
    }
  }
`
export const UPDATE_USER_HISTORY = `
mutation updateUserHistory($input: UpdateUserHistoryInput!) {
  updateUserHistory(input: $input) {
    id
  }
}
`

export const GET_USER_HISTORY = `
query listUserHistoryByOwner($owner: String!) {
  listUserHistoryByOwner(owner: $owner, limit: 10, sortDirection: DESC) {
      items {   
        buildingId
        type
        id
        userId
        salesOfficeId
        accountId
        eTime
        building{
          name
          address
          isActive
        }
        account{
          name
          id
        }
        salesOffice{
          name
          id
        }
        createdAt
      }
    }
  }
`

export type UserHistorySalesOffice = {
  account: null
  accountId: null
  building: null
  buildingId: null
  createdAt: string
  eTime: number
  id: SalesOfficeId
  salesOffice: {
    name: string,
    id: SalesOfficeId
  }
  salesOfficeId: string 
  type: "sales-office"
  userId: null
}

export type UserHistoryOrganization = {
  account: {
    name: string
    id: OrganizationId
  }
  accountId: OrganizationId,
  building: null,
  buildingId: null,
  createdAt: string
  eTime: number,
  id: string,
  salesOffice: null,
  salesOfficeId: null,
  type: "organization",
  userId: null
}

export type UserHistoryBuilding = {
  account: {
    name: string
    id: OrganizationId
  }
  accountId: OrganizationId,
  building: {
    name: string,
    address: string,
    isActive: 1|0
  },
  buildingId: BuildingId,
  createdAt: string
  eTime: number,
  id: BuildingId,
  salesOffice: null,
  salesOfficeId: null,
  type: "location",
  userId: null
}

export type UserHistoryItem = UserHistorySalesOffice|UserHistoryOrganization|UserHistoryBuilding

export type UserHistoryItems = UserHistoryItem[]
