import { useEffect, useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { getWeatherDetails } from 'src/pages/widgets/graphql'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { TEXT_DATE_TIME_FORMAT } from 'src/components/legacy/common/time-helpers'
import moment from 'moment'
import CONFIGURATION from 'src/config'
import { PanelLabel } from 'src/components/select-form-input/SelectFormInput'
import { WeatherImageContainer, BuildingContainer } from './style'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import Icon from 'src/denali-ui/components/Icon'
import { newRefreshToken } from 'src/redux/slicers/appData'
import { useDispatch } from 'react-redux'
import { Block } from 'src/components/layouts'
import translate, { TranslateComponent } from 'src/common/translations'
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'

const WeatherPreview = (widget) => {
  const [weatherInfo, setWeatherInfo] = useState(null)
  const [data, setData] = useState(null)
  const [buildingName, setBuildingName] = useState('')
  const dispatch = useDispatch()
  const { refetch: refetchGetWeatherDetails, loading } = useQuery({
    query: getWeatherDetails,
    dataPath: 'data',
    disableInitialLoad: true,
    onSuccess: (response) => {
      try {
        if (response) {
          const listWeatherTimeSeriesData =
            response?.listWeatherTimeSeriesData?.items ?? []
          const weatherData = JSON.parse(
            listWeatherTimeSeriesData?.[0]?.data ?? ''
          )
          const weatherUpdatedAt = listWeatherTimeSeriesData?.[0]?.dateTime
          const lDateFormat = getDateFormatByLocale(TEXT_DATE_TIME_FORMAT) //'MMM D, YYYY h:mm A'
          setData({ temperature_f: weatherData?.TemperatureF })
          setWeatherInfo({
            imgURL: `${CONFIGURATION?.weatherIconS3Url}/${weatherData?.Icon}`,
            imgTitle: weatherData?.Description,
            updatedDate: moment(
              weatherUpdatedAt,
              'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
            ).format(lDateFormat ? lDateFormat : TEXT_DATE_TIME_FORMAT)
          })
        }
      } catch (error) {}
    }
  })

  useEffect(() => {
    const runFunc = async () => {
      const buildingDetails = widget?.widgetDetails?.building
      if (buildingDetails) {
        setBuildingName(
          `${buildingDetails?.city}, ${buildingDetails?.state}, ${buildingDetails?.postalCode}`
        )
      }
      const id = widget?.widgetDetails?.buildingId
      if (id) {
        await dispatch<any>(newRefreshToken([id], null, null))
        refetchGetWeatherDetails({ buildingId: id })
      }
    }
    runFunc()
  }, [widget?.widgetDetails?.building])

  const getMetricValue = (value) => {
    return `${
      isFahrenheit() ? Math.round(value) : Math.round(((value - 32) * 5) / 9)
    }°`
  }

  const isFahrenheit = () => {
    const metric = widget?.widgetDetails?.weatherWidget?.metricConversion
    return metric === 'Fahrenheit'
  }
  const dataUpdated =translate("Data Updated")
  const titleTranslate = translate(weatherInfo?.imgTitle)
  const lDateFormatbyLocale = getDateFormatByLocale(TEXT_DATE_TIME_FORMAT) //'MMM D, YYYY h:mm A'
  return (
    <Block height = "100%">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <BuildingContainer>
            <div className="location-content">
              <span className="icon-location"></span>
              <PanelLabel className="location-label">{buildingName}</PanelLabel>
            </div>

            <ConditionalTooltip
              type={ConditionalTooltip.TYPE.ICON}
              content={`${dataUpdated} : ${moment().format(
                lDateFormatbyLocale ? lDateFormatbyLocale : TEXT_DATE_TIME_FORMAT
              )}`}
            >
              <Icon
                width="15px"
                height="15px"
                name="infocircle2"
                color="#bfbfbf"
              />
            </ConditionalTooltip>
          </BuildingContainer>
          <WeatherImageContainer>
            <span className="current-label"><TranslateComponent>CURRENTLY</TranslateComponent> :</span>
            <div className="image-section">
              <img
                width={150}
                height={150}
                alt="preview"
                src={weatherInfo?.imgURL}
                title={titleTranslate}
              />
              <span className="metric-value">
                {getMetricValue(data?.temperature_f)}
              </span>
              <span className="metric">{`${isFahrenheit() ? 'F' : 'C'}`}</span>
            </div>
          </WeatherImageContainer>
        </>
      )}
      <PanelLabel className="updated-label"><TranslateComponent>Updated </TranslateComponent><div>{` : ${weatherInfo?.updatedDate}`}</div></PanelLabel>
    </Block>
  )
}

export default WeatherPreview
