import { BarProps } from '../../Types/component/bar'

const BarRect = ({
  x,
  y,
  width,
  height,
  color,
  radius = 0,
  onMouseEnter,
  onMouseLeave
}: BarProps) => {
  return (
    <path
      d={`
        m${x},${y + radius}
        a${radius},${radius} 0 0 1 ${radius},${-radius}
        h${width - 2 * radius}
        a${radius},${radius} 0 0 1 ${radius},${radius}
        v${height - radius}
        h-${width}
        z
      `}
      fill={color}
      onMouseEnter={(event) => {
        if (onMouseEnter) return onMouseEnter(event)
      }}
      onMouseLeave={() => {
        {
          if (onMouseEnter) return onMouseLeave()
        }
      }}
    />
  )
}

export default BarRect
