import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
// import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import i18next from "i18next"
import _isEmpty from "lodash/isEmpty"
import _find from "lodash/find"
import CheckBox from 'src/denali-ui/components/Checkbox'
// import Checkbox from "react/shared/checkbox/checkbox"
// import MultiFileUploaderWithTable from "react/shared/multi-file-upload-with-table"
// import { ACTIONS } from "react/shared/multi-file-upload-with-table/common"
// import { SelectrixWrapper } from "react/shared/selectrix-wrapper/selectrix-wrapper"
// import TextArea from "react/shared/text-area-input/index.js"
import {
	commonInput,
	emailInput,
	dateInput,
	filterContractPeriods,
	getLocationOption,
	BUSINESS_STREAM,
	OFFERING_LIST,
	CONTRACT_PERIODS,
	ACCEPTED_FILE_TYPES,
	inputFieldValidation
} from "./common"
// import { GET_USER_LOCATIONS_WITH_ADDRESS } from "graphql/user"
// import { FETCH_ALL_OFFERING_SUBSCRIPTIONS } from "graphql/offering"

const CLEAR_ALL = { key: "clear", label: i18next.t("common:ClearAll") }

const BUILDING_LENGTH_WITH_CLEAR_ALL = 4

const isOfferingDisabled = (selectedOfferings, offeringSubscriptionName) => {
	if (!selectedOfferings.length) {
		return false
	} else {
		return (selectedOfferings.some(sub => !OFFERING_LIST.get(sub.name).allowedSubscriptions.length))
			|| (selectedOfferings.length === 1
				? !OFFERING_LIST.get(selectedOfferings[0].name).allowedSubscriptions.includes(offeringSubscriptionName)
				: !selectedOfferings.every(sub => OFFERING_LIST.get(sub.name).allowedSubscriptions.includes(offeringSubscriptionName)))
	}
}

const filterSubscription = (selectedOfferings, allOfferingSubscriptions, t) => allOfferingSubscriptions
	.map(({ name, offeringSubscriptionId }) => ({
		key: name,
		name,
		offeringSubscriptionId,
		label: t(`building-configuration:BuildingSetupPage>Subscription>${name}`),
		fullLabel: t(`building-configuration:BuildingSetupPage>SubscriptionFull>${name}`),
		disabled: isOfferingDisabled(selectedOfferings, name)
	}))
	.sort((a, b) => (OFFERING_LIST.has(a.name) && OFFERING_LIST.has(b.name)) && (OFFERING_LIST.get(a.name).order - OFFERING_LIST.get(b.name).order))

export function BPEPPerformanceRequestDialog({
	values: { contractStartDate, expirationDate, contractPeriod, serviceContractNumber, purchaseOrderNumber, agreement, subscriptions, locations, businessStream, primaryContact, settings, denialReason, pactContractNumber, mechanicalServiceAgreementNumber, notes },
	locationInfo,
	organizationId,
	salesOfficeId,
	setFieldValue,
	setFieldTouched,
	handleSubmitForm,
	touched,
	errors,
	dialogClassName,
	modalElement,
	type,
	setExistingCRMLocations
}) {
	const [t] = useTranslation()
	const currentLocationOrganizationId = locationInfo ? locationInfo.organizationId : ""
	// const { loading: locationsLoading, data: { User: { locations: buildings = [] } = {} } = {} } = useQuery(GET_USER_LOCATIONS_WITH_ADDRESS)
	// const { loading: offeringSubscriptionsLoading, data: { OfferingSubscriptions = [] } = {} } = useQuery(FETCH_ALL_OFFERING_SUBSCRIPTIONS)
	const subscriptionsOptionList = useMemo(() => filterSubscription(subscriptions, OfferingSubscriptions, t), [subscriptions, OfferingSubscriptions, t])
	const contractPeriodList = useMemo(() => filterContractPeriods(subscriptions, setFieldValue), [subscriptions])
	const isCustomContractLength = useMemo(() => contractPeriod === "custom", [contractPeriod])
	const buildingsOptionList = useMemo(() => {
		let updatedBuildingsList = []

		if (salesOfficeId) {
			updatedBuildingsList = buildings && buildings.filter(building => salesOfficeId === building.salesOfficeId)
		} else {
			updatedBuildingsList = buildings && buildings.filter(building => currentLocationOrganizationId ? Number(currentLocationOrganizationId) === building.organizationId : organizationId === building.organizationId)
		}
		return updatedBuildingsList.map(getLocationOption)
	}, [buildings])

	const defaultBuildings = useMemo(() => locations
		? locations.map(({ key, locationId }) => locationId || key) : "", [locations])

	const defaultSubscriptions = useMemo(() => subscriptions.map(s => s.name), [subscriptions])

	const attachments = useMemo(() => settings.attachments && settings.attachments.map(attachment => ({
		...attachment,
		isEditable: true
	})), [settings.attachments])

	// Setting some defaults
	useEffect(() => {
		if (!locations.length && !_isEmpty(locationInfo)) {
			setFieldValue("locations", [getLocationOption(locationInfo)])
		}
	}, [locationInfo, setFieldValue])

	// Reset related fields if change businessStream
	useEffect(() => {
		if (businessStream === "Independent") {
			if (![null, ""].includes(serviceContractNumber)) {
				setFieldValue("serviceContractNumber", "")
			}
		} else if (![null, ""].includes(purchaseOrderNumber)) {
			setFieldValue("purchaseOrderNumber", "")
		}
	}, [businessStream])

	// expirationDate got enabled when custom contractPeriod selected
	useEffect(() => {
		if (contractPeriod === "custom") {
			setFieldValue("expirationDate", expirationDate)
		}
	}, [contractPeriod])

	// Update the locations list to disable existing one to auto populated field
	useEffect(() => {
		if (type != "BPEPDialog" && !locationsLoading && buildingsOptionList) {
			setExistingCRMLocations(buildingsOptionList)
		}
	}, [locationsLoading])

	return (
		<form onSubmit={handleSubmitForm} className={`offering-form ${dialogClassName}`}>
			{denialReason && <p className="error">{t("building-configuration:BuildingSetupPage>Offerings>DeclineRequestReason")} {denialReason}</p>}
			<div className="form-header">{t("contracts-and-offerings:offeringSourceMaps>requestActivation")}</div>
			<hr className="form-header-line" />
			<div className="form-sub-header">{`${t("contracts-and-offerings:offeringSourceMaps>step")} 1 ${t("contracts-and-offerings:offeringSourceMaps>of")} 2 : ${t("contracts-and-offerings:offeringSourceMaps>contractInfo")}`}</div>
			<div className="locations-devices">
				<fieldset className={(errors.locations && touched.locations) ? "invalid" : undefined}>
					{(Boolean(buildingsOptionList.length) || locationsLoading)
						&& <>
							<legend>{t("building-configuration:BuildingSetupPage>Offerings>ChooseBuildings")}</legend>
							{/* <SelectrixWrapper
								className="buildings selectrix-wrapper selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select"
								placeholder={t("common:SelectOneOrMore")}
								searchable={true}
								searchBoxInside={true}
								defaultValue={defaultBuildings.length >= BUILDING_LENGTH_WITH_CLEAR_ALL ? [...defaultBuildings, "clear"] : defaultBuildings}
								options={locations.length >= BUILDING_LENGTH_WITH_CLEAR_ALL ? [...buildingsOptionList, CLEAR_ALL] : buildingsOptionList}
								onChange={list => {
									if (list.length >= BUILDING_LENGTH_WITH_CLEAR_ALL && locations.length >= BUILDING_LENGTH_WITH_CLEAR_ALL && !list.some(({ key }) => key === "clear")) {
										list = []
									} else {
										list.length && (list = list.filter(({ key }) => key !== "clear"))
									}
									setFieldTouched("locations")
									setFieldValue("locations", list)
								}}
								stayOpen={true}
								multiple={true}
								selectAllButton={true}
								isLoading={locationsLoading}
								notFoundPrompt={t("common:NoResult")}
							/> */}
						</>}
					{Boolean(errors.locations && touched.locations)
						&& <p className="error">{errors.locations}</p>}
				</fieldset>
				<fieldset className="device-count">
					{commonInput({
						label: t("building-configuration:BuildingSetupPage>Offerings>SC/BCU/BAS"),
						name: "settings.deviceCount",
						value: settings.deviceCount,
						touched: touched.deviceCount,
						type: "number",
						onKeyDown: inputFieldValidation,
						maxLength: 2,
						setFieldValue,
						setFieldTouched,
						errors,
						min: 0
					})}
					<span className="input-tip">{t("building-configuration:BuildingSetupPage>Offerings>ForActiveMonitoring")}</span>
				</fieldset>
			</div>
			<fieldset className="building-note">
				<p>
					<span>{t("building-configuration:BuildingSetupPage>Offerings>BuildingCount")} = {locations.length}</span>
					<span>{t("building-configuration:BuildingSetupPage>Offerings>DeviceCount")} = {settings.deviceCount || 0}</span>
				</p>
				<span>{t("building-configuration:BuildingSetupPage>Offerings>BuildingNote", {
					buildings: t("building-configuration:BuildingSetupPage>Offerings>BuildingNoteCount", {
						count: locations.length
					}),
					devices: settings.deviceCount == 1
						? t("building-configuration:BuildingSetupPage>Offerings>DeviceNoteCount")
						: t("building-configuration:BuildingSetupPage>Offerings>DeviceNoteCount_plural", {
							count: settings.deviceCount || 0
						})
				})}</span>
			</fieldset>
			<div className="subscriptions">
				<fieldset className={(errors.subscriptions && touched.subscriptions) ? "invalid" : undefined}>
					<legend>{t("building-configuration:BuildingSetupPage>Offerings>Offering-s")}*</legend>
					{/* <SelectrixWrapper
						className="subscription selectrix-wrapper selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select"
						placeholder={t("common:SelectOneOrMore")}
						searchable={false}
						options={subscriptionsOptionList}
						defaultValue={defaultSubscriptions}
						onChange={list => {
							setFieldTouched("subscriptions")
							setFieldValue("subscriptions", [...list])

							for (const subscription of list) {
								if (["CAP TestDrive", "CAPxPRO", "CAP Data", "CAPxM&V", "Digital Inspections - Chiller"].includes(subscription.key)) {
									const defaultContractPeriod = OFFERING_LIST.get(subscription.key).defaultContractPeriod
									const defaultContractPeriodKey = CONTRACT_PERIODS.find(({ days }) => days.includes(defaultContractPeriod))?.key
									defaultContractPeriodKey && setFieldValue("contractPeriod", defaultContractPeriodKey)
								}
							}
						}}
						isLoading={offeringSubscriptionsLoading}
						multiple={true}
					/> */}
					{Boolean(errors.subscriptions && touched.subscriptions)
						&& <p className="error">{errors.subscriptions}</p>}
				</fieldset>
				<fieldset className="inline-3">
					<legend>{t("building-configuration:BuildingSetupPage>Offerings>BusinessStream")}*</legend>
					{/* <SelectrixWrapper
						className="selectrix-wrapper selectrix-wrapper--type-autocomplete"
						searchable={false}
						defaultValue={businessStream}
						options={BUSINESS_STREAM}
						onChange={item => {
							setFieldTouched("businessStream")
							setFieldValue("businessStream", item.key)
						}}
					/> */}
				</fieldset>
			</div>

			<div className={`dates-set ${isCustomContractLength ? "space-between" : undefined}`}>
				<fieldset className="inline-3">
					{dateInput({
						name: "contractStartDate",
						label: `${t("building-configuration:BuildingSetupPage>Offerings>Contract")} ${t("common:StartDate")}*`,
						value: contractStartDate,
						touched: touched.contractStartDate,
						setFieldValue,
						setFieldTouched,
						errors
					})}
				</fieldset>
				<fieldset className={`inline-3 contract-length ${(errors.contractPeriod && touched.contractPeriod) ? "invalid" : undefined}`}>
					<legend>{t("building-configuration:BuildingSetupPage>Offerings>Length")}*</legend>
					{/* <SelectrixWrapper
						className="selectrix-wrapper selectrix-wrapper--type-autocomplete"
						placeholder={t("common:SelectOne")}
						searchable={false}
						defaultValue={contractPeriod}
						options={contractPeriodList}
						onChange={item => {
							setFieldTouched("contractPeriod")
							setFieldValue("contractPeriod", item.key)
						}}
					/> */}
					{Boolean(errors.contractPeriod && touched.contractPeriod)
						&& <p className="error">{errors.contractPeriod}</p>}
				</fieldset>
				{isCustomContractLength && <fieldset className="inline-3">
					{dateInput({
						name: "expirationDate",
						label: `${t("common:ExpirationDate")}*`,
						value: expirationDate,
						touched: touched.expirationDate,
						setFieldValue,
						setFieldTouched,
						errors,
						disablePast: true
					})}
				</fieldset>}
			</div>
			<div className="text-container">
				<div className="contract-ids">
					{businessStream === "Independent"
						? <fieldset>
							{commonInput({
								label: t("building-configuration:BuildingSetupPage>Offerings>PurchaseOrderNumber"),
								name: "purchaseOrderNumber",
								value: purchaseOrderNumber,
								touched: touched.purchaseOrderNumber,
								setFieldValue,
								key: "PurchaseOrderNumber",
								setFieldTouched,
								errors,
							})}
						</fieldset>
						: <>
							{!(_find(subscriptions, { name: "DI-CH" }) && subscriptions.length == 1)
								&& <fieldset>
									{commonInput({
										label: t("building-configuration:BuildingSetupPage>Offerings>ServiceContractNumber"),
										name: "serviceContractNumber",
										value: serviceContractNumber,
										touched: touched.serviceContractNumber,
										setFieldValue,
										setFieldTouched,
										key: "ServiceContractNumber",
										errors,
										isRequired: !(subscriptions.length == 1 && _find(subscriptions, { name: "DI-CH" }))
									})}
								</fieldset>
							}
						</>}
				</div>
				{
					(!(_find(subscriptions, { name: "DI-CH" }) && subscriptions.length == 1) || businessStream === "Independent")
					&& <div className="inline-2 bp-ep-dialog-tip-text">
						{t("building-configuration:BuildingSetupPage>Offerings>BP/EPDialogTipText")}
					</div>
				}
			</div>
			{_find(subscriptions, { name: "CAPxM&V" })
				&& <div className="text-container">
					<div className="contract-ids">
						<fieldset>
							{commonInput({
								label: "PACT Contract Number",
								name: "pactContractNumber",
								value: pactContractNumber,
								touched: touched.pactContractNumber,
								setFieldValue,
								setFieldTouched,
								key: "pactContractNumber",
								errors
							})}
						</fieldset>
					</div>
				</div>
			}
			{_find(subscriptions, { name: "DI-CH" })
				&& subscriptions.length == 1 && <div className="text-container">
					<div className="msa-id">
						<fieldset>
							{commonInput({
								label: "Mechanical Service Agreement Number",
								name: "mechanicalServiceAgreementNumber",
								value: mechanicalServiceAgreementNumber,
								touched: touched.mechanicalServiceAgreementNumber,
								setFieldValue,
								setFieldTouched,
								key: "mechanicalServiceAgreementNumber",
								errors
							})}
						</fieldset>
					</div>
				</div>
			}
			<fieldset>
				<legend>{t("building-configuration:BuildingSetupPage>Offerings>AttachFile")}</legend>
				<legend className="input-tip input-tip-files">{t("building-configuration:BuildingSetupPage>Offerings>OtherServiceOrderForms")} or supporting documents.</legend>
				{/* <MultiFileUploaderWithTable
					mode={ACTIONS.EDIT}
					attachments={attachments || []}
					onChangeAttachments={items => setFieldValue("settings.attachments", items)}
					fieldsToDisplay={["fileName", "fileSize", "fileDescription"]}
					tooltipRenderNode={modalElement}
					proposalFileTypes={ACCEPTED_FILE_TYPES}
				/> */}
			</fieldset>
			<fieldset>
				<legend>Supporting Notes</legend>
				{/* <TextArea
					name="notes"
					initialValue={notes ? notes : ""}
					isRequired={false}
					maxLength={2000}
					placeholder=""
					onChange={(name, value) => {
						setFieldTouched("notes")
						setFieldValue("notes", value)
					}}
				/> */}
			</fieldset>
			<fieldset>
				<>
					<legend>{t("user:jobTitlesTraneEmployee>accountManager")}</legend>
					<fieldset className="account-manager-text">
						{emailInput({
							name: "primaryContact",
							type: "email",
							touched: touched.primaryContact,
							value: primaryContact.username,
							setFieldValue,
							setFieldTouched,
							key: "primaryContact",
							isRequired: false,
							placeholder: "Email ID",
							errors
						})}
						<span className="input-tip">Email address of the person who sold the contract</span>
					</fieldset>
				</>
			</fieldset>
			<CheckBox
				id="agreement"
				label={t("building-configuration:BuildingSetupPage>Offerings>CheckboxLabel")}
				toggleCheck={() => {
					setFieldTouched("agreement")
					setFieldValue("agreement", !agreement)
				}}
				isChecked={agreement}
				{...errors.agreement && touched.agreement && { customClassName: "has-error" }}
			/>
		</form>
	)
}

BPEPPerformanceRequestDialog.propTypes = {
	handleSubmitForm: PropTypes.func.isRequired,
	type: PropTypes.string,
	touched: PropTypes.object,
	setFieldTouched: PropTypes.func,
	setFieldValue: PropTypes.func.isRequired,
	errors: PropTypes.object,
	locationInfo: PropTypes.object,
	values: PropTypes.object
}
