import AlarmContainer from './AlarmContainer'
import { AlarmContextProvider } from './AlarmContextProvider'

const Alarms = () => {
  return (
    <AlarmContextProvider>
      <AlarmContainer />
    </AlarmContextProvider>
  )
}
export default Alarms
