import React from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-number-input'
import { ErrorText } from './styles'
import { MAX_LENGTH_OF_PROPOSED } from './helper'

const ContactInfo = ({
  values,
  setFieldValue,
  testName='',
  errors  
}) => (
  <>
    <div data-testid={testName+'contact'}>
      <label className="column">{`Contact`}</label>
      <input
        type="text"
        name="contact"
        maxLength={MAX_LENGTH_OF_PROPOSED}
        value={values?.contact || ''}
        onChange={(e) => {
          setFieldValue('contact', e.target.value)
        }}        
      />
    </div>
    <div data-testid={testName+'phone'}>
      <label className="column">{'Phone'} </label>
      <PhoneInput
        placeholder="+99 999 999 9999"
        value={values?.phoneNumber?.toString() || ''}
        onChange={(value) => {
          setFieldValue('phoneNumber', value)
        }}
      />
        {errors?.phoneNumber && <ErrorText>
          {errors?.phoneNumber}
        </ErrorText>
        }
    </div>
    <div data-testid={testName+'email'}>
      <label className="column">{'Email'}</label>
      <input
        type="email"
        name="email"
        value={values?.email || ''}
        onChange={(e) => {
          setFieldValue('email', e.target.value)
        }}
      />
      {errors?.email && <ErrorText>
        {errors?.email}
      </ErrorText>
      }
    </div>
  </>
)

ContactInfo.propTypes = {
  values: PropTypes?.object,
  setFieldValue: PropTypes?.func?.isRequired
}

export default ContactInfo
