import { Button, Radio, RadioGroupField } from '@aws-amplify/ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import styles from './table.module.scss'
import React, { useState, useRef } from 'react'
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole
} from '@floating-ui/react'
import { TranslateComponent } from 'src/common/translations'
import { InnerSearch } from './InnerSearch'

export const Filter = ({ filter, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = React.useState(filter.selectedValue)
  const [activeScroll, setActiveScroll] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const popup = useRef(null)
  const { refs, floatingStyles, context } = setupFloating(isOpen, setIsOpen, filter, popup, setActiveScroll)
  const { getReferenceProps, getFloatingProps } = setupInteractions(context)
  const filterOptions = filter.searchOptions !== undefined ? filter.searchOptions : filter.options

  return (
    <div key={filter.defaultLabel}>
      {filter.selectedValue !== 'default' ? (
        <Button
          size="small"
          onClick={(event) => {
            setValue('default')
            onChange(event, filter, 'default')
          }}
        >
          <TranslateComponent>{filter.selectedValue}</TranslateComponent>
          <FontAwesomeIcon icon={faCircleXmark} />
        </Button>
      ) : (
        <Button
          size="small"
          ref={refs.setReference}
          {...getReferenceProps()}
          data-testid={`tableFilterButton-${filter?.filterLabel}`}
        >
          <TranslateComponent>{filter?.filterLabel}</TranslateComponent>
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      )}

      {isOpen && (
        <FloatingFocusManager context={context} closeOnFocusOut={true}>
          <div
            className={styles.menu}
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={floatingStyles}
          >
            {activeScroll && <div className={styles.scrollOverlay}></div>}
            {filter.includeSearch && (
              <InnerSearch
                filter={filter}
                popup={popup}
                handleInnerScrollFade={handleInnerScrollFade}
                setActiveScroll={setActiveScroll}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            )}
            <div className={styles.filterOptionsWrapper} ref={popup} onScroll={() => {handleInnerScrollFade(popup, setActiveScroll)}}>
              <RadioGroupField
                label={filter.defaultLabel}
                labelHidden={true}
                name={filter.defaultLabel}
                value={value}
                onChange={(event) => {
                  setValue(event.target.value)
                  onChange(event, filter, event.target.value)
                  setIsOpen(false)
                }}
                className={`options_${filter.key}`}
              >
                {!searchTerm && <Radio
                  value="default"
                  data-testid={`tableFilterRadioDefault-${filter?.filterLabel}`}
                  className={filter.selectedValue === 'default' ? styles.selected : ''}
                >
                  <TranslateComponent>{filter.defaultLabel}</TranslateComponent>
                </Radio>}
                {filterOptions.map(
                  (option: { value: string; name: string }) =>
                    option.name && (
                      <Radio
                        key={option.value}
                        value={option.value}
                        data-testid={`tableFilterRadio-${option.value}-${filter?.filterLabel}`}
                        className={value === option.value ? styles.selected : ''}
                      >
                        {filter?.translateOptions === false ? (
                          option.name
                        ) : (
                          <TranslateComponent>{option.name}</TranslateComponent>
                        )}
                      </Radio>
                    )
                )}
              </RadioGroupField>
            </div>
          </div>
        </FloatingFocusManager>
      )}
    </div>
  )
}

function setupFloating(isOpen, setIsOpen, filter, popup, setActiveScroll) {
  return useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange(nextOpen, reason) {
      setIsOpen(nextOpen)
      setTimeout(() => {
        handleInnerScrollFade(popup, setActiveScroll)
      }, 0) // 0 allows the popup to render before checking if the scroll is needed
      if (reason.type === 'pointerdown') {
        resetSearch(filter);
      }
    },
    whileElementsMounted: autoUpdate,
    middleware: [offset(12), flip(), shift()]
  })
}

function setupInteractions(context) {
  const click = useClick(context)
  // const focus = useFocus(context);
  const dismiss = useDismiss(context)
  const role = useRole(context)
  return useInteractions([
    click,
    // focus,
    dismiss,
    role
  ])
}

function handleInnerScrollFade(popup, setActiveScroll) {
  const optionsWrapper = popup?.current;
  const optionsList = optionsWrapper?.firstElementChild;
  const optionsBottom = optionsList?.clientHeight - optionsWrapper?.scrollTop;

  setActiveScroll(optionsBottom - 10 >= optionsWrapper?.clientHeight);
}

function resetSearch(filter) {
  filter.searchOptions = undefined;
}
