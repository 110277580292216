import { createSlice } from "@reduxjs/toolkit";

interface ErrorState {
  error: string | null;
}

const initialState = {
  error: null,
} as ErrorState;

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null
    },
    setGlobalError: (state, action) => {
      state.error = action.payload
    }
  }
});

export const { clearErrors, setGlobalError } = errorSlice.actions
export const selectError = (state) => {
  return state.errorSlice.error
}
export default errorSlice.reducer;