import _isEmpty from 'lodash/isEmpty'
import Modal from 'src/components/legacy/components/modal/modal'
import { selectUserInfo } from 'src/redux/slicers/appData'
import {
  selectDashboardStep,
  selectDashboard,
  selectDashboards,
  setDashboard,
  setDashboardIdForWidget,
  getDashboards,
  setDashboardPath
} from 'src/redux/slicers/dashboard'
import { useDispatch, useSelector } from 'react-redux'
import CreateBlankDashboard from './CreateBlankDashboard'
import DashboardOptions from './DashboardOptions'
import { useDashboardContext } from './CreateDashBoardProvider'
import {
  CREATE_DASHBOARD_SUCCESS,
  STEPS,
  UPDATE_DASHBOARD_SUCCESS
} from './constant'
import { useFormik } from 'formik'
import {
  blankDashboardSchema,
  preConfiguredDashboardSchema
} from './formValidator'
import { stringToBoolean } from 'src/utils/commonMethods'
import ConfirmationModal, { MODALTYPES } from './ConfirmationModal'
import { useEffect, useMemo, useState } from 'react'
import {
  frameWidgetsInput,
  getCopyDashboardInputData,
  getValidWidgetOrderValue,
  mutation
} from '../../helper'
import { CREATE_DASHBOARD } from './graphql'
import { useNavigate } from 'react-router'
import {
  CREATE_DASHBOARD_WIDGET,
  GET_DASHBOARD,
  GET_DASHBOARD_WIDGET_IDS,
  UPDATE_DASHBOARD
} from '../../graphql'
import { MODAL_TYPE } from 'src/constants'
import translate, { TranslateComponent } from 'src/common/translations'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { GET_PRE_CONFIGURED_DASHBOARD } from './graphql'
import { useQuery, useMutation } from 'src/hooks/APIHooks'
import { CREATE_WIDGET } from 'src/pages/widgets/graphql'
import { updateUserDefaultDashboard } from 'src/common/userSettingAPI'
import _uniqBy from 'lodash/uniqBy'
import './styles.scss'

const CreateDashboard = () => {
  const optionsTranslated = {
    create: <TranslateComponent>CREATE</TranslateComponent>,
    cancel: <TranslateComponent>CANCEL</TranslateComponent>,
    next: <TranslateComponent>NEXT</TranslateComponent>,
    save: <TranslateComponent>SAVE</TranslateComponent>
  }
  const context = useDashboardContext()
  const { data: dashboards } = useSelector(selectDashboards)
  const [modalClass, setModalClass] = useState('create-dashbaord-dialog')
  const dashboardLists = localStorage.getItem('dashboards')
    ? JSON.parse(localStorage.getItem('dashboards'))['dashboards']
    : dashboards
  const navigate = useNavigate()
  const defaultDashboard: any = dashboardLists?.find(
    (dashboard: any) => dashboard?.isDefault === '1'
  )
  const [nonSelectedDashboard, setNonSelectedDashboard] = useState<any>({})
  const {
    modalType,
    dashboardType,
    preConfigSelection,
    setModalType,
    publicDashboards
  } = context
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)
  const stepType = useSelector(selectDashboardStep)
  const {
    values: dashboardInitValues,
    selectedDashboardId,
    actionType
  } = useSelector(selectDashboard)

  const [preConfiguredWidgets, setPreConfiguredWidgets] = useState([])
  const [preConfigDashboardOptions, setPreConfigDashboardOptions] =
    useState<any>()
  const { refetch: getPreConfiguredDashboard } = useQuery({
    query: GET_PRE_CONFIGURED_DASHBOARD,
    disableInitialLoad: true,
    variables: { type: 'Dashboard', subType: { eq: 'Template' } },
    dataPath: 'data'
  })

  const { refetch: getDashboardWidgetIds } = useQuery({
    query: GET_DASHBOARD_WIDGET_IDS,
    disableInitialLoad: true,
    dataPath: 'data.getDashboard',
    errorPolicy: 'all'
  })
  const { refetch: getDashboard } = useQuery({
    query: GET_DASHBOARD,
    disableInitialLoad: true,
    dataPath: 'data.getDashboard',
    errorPolicy: 'all'
  })

  const { onSubmit: upsertWidgetMutation } = useMutation({
    query: CREATE_WIDGET,
    refreshTokenBuildingVariable: 'input.buildingId'
  })

  const { onSubmit: createDashboardWidget, loading } = useMutation({
    query: CREATE_DASHBOARD_WIDGET,
    dataPath: 'data.createDashboardWidget'
  })

  useEffect(() => {
    getPreConfiguredDashboard().then((updatedDashboardList) => {
      const ConfigDashboardList =
        updatedDashboardList?.configByTypeAndSubtype?.items[0]?.value
      const parsedData = ConfigDashboardList
        ? JSON?.parse(ConfigDashboardList)
        : []
      setPreConfiguredWidgets(parsedData?.data)
      setPreConfigDashboardOptions([
        {
          description: `${parsedData?.name} Dashboard`,
          name: `${parsedData?.name} Dashboard`,
          key: 'preConfig',
          id: 'preConfig'
        }
      ]) //right now only one
    })
  }, [])

  const initValues = useMemo(() => {
    const {
      name,
      Description,
      isDefault,
      isKeyMetric,
      isPublic,
      widgetOrder,
      buildingDataCharts
    } = dashboardInitValues
    const input = {
      name,
      Description,
      isDefault: dashboardLists?.length === 0 ? '1' : isDefault,
      isKeyMetric,
      isPublic,
      widgetOrder,
      buildingDataCharts
    }
    return input
  }, [dashboardInitValues, dashboardLists])
  const preConfigInitValues = {
    name: '',
    Description: '',
    isDefault: dashboardLists?.length === 0 ? '1' : '0',
    isKeyMetric: '0',
    isPublic: '0',
    organization: '',
    organizationName: '',
    buildingName: '',
    building: '',
    equipment: null,
    salesOfficeId: null,
    widgetOrder: null,
    buildingDataCharts: null
  }
  const formik = useFormik({
    initialValues:
      dashboardType === '1' && actionType === 'Create-Blank'
        ? preConfigInitValues
        : initValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validationSchema:
      dashboardType === '1' && actionType === 'Create-Blank'
        ? preConfiguredDashboardSchema
        : blankDashboardSchema,
    onSubmit: async () => {}
  })

  const updateDashboard = async (variables, isDefault) => {
    try {
      const { data } = await mutation({
        query: UPDATE_DASHBOARD,
        variables
      })
      const id = data?.updateDashboard?.id

      await updateUserDefaultDashboard(dashboardLists, id, isDefault, userInfo?.id)

      dispatch<any>(getDashboards())
      setModalType(UPDATE_DASHBOARD_SUCCESS)
    } catch {
      setModalType(MODAL_TYPE.ERROR)
    }
  }
  const getInput = (variables, isKeyMetric) => {
    let input = variables?.input
    if (actionType === 'Copy' && isKeyMetric) {
      const {
        keyMetricaccountId,
        keyMetricbuildingId,
        keyMetric1Display,
        keyMetric1Name,
        keyMetric1UoM,
        keyMetric2Display,
        keyMetric2Name,
        keyMetric2UoM,
        keyMetric3Display,
        keyMetric3Name,
        keyMetric3UoM,
        keyMetric4Display,
        keyMetric4Name,
        keyMetric4UoM,
        keyMetric5Display,
        keyMetric5Name,
        keyMetric5UoM,
        keyMetricComparedTo,
        keyMetricTimePeriod
      } = dashboardInitValues
      input = {
        ...variables?.input,
        keyMetricaccountId,
        keyMetricbuildingId,
        keyMetric1Display,
        keyMetric1Name,
        keyMetric1UoM,
        keyMetric2Display,
        keyMetric2Name,
        keyMetric2UoM,
        keyMetric3Display,
        keyMetric3Name,
        keyMetric3UoM,
        keyMetric4Display,
        keyMetric4Name,
        keyMetric4UoM,
        keyMetric5Display,
        keyMetric5Name,
        keyMetric5UoM,
        keyMetricComparedTo,
        keyMetricTimePeriod
      }
    }
    return input
  }

  const createDashboard = async (variables, isDefault, isKeyMetric) => {
    try {
      const { data } = await mutation({
        query: CREATE_DASHBOARD,
        variables: { input: getInput(variables, isKeyMetric) }
      })
      const id = data?.createDashboard?.id

      await updateUserDefaultDashboard(dashboardLists, id, isDefault, userInfo?.id )

      // Copy Pre Configured Dashboard Widgets
      if (dashboardType === '1' && actionType === 'Create-Blank') {
        const inputs = frameWidgetsInput(
          preConfiguredWidgets,
          userInfo,
          formik?.values
        )
        await Promise.all(
          inputs?.map((input) =>
            upsertWidgetMutation({ input }).then(async (response) => {
              const widgetId = response.data?.createWidget?.id
              await createDashboardWidget({
                input: { dashboardID: id, widgetID: widgetId }
              })
            })
          )
        )
      }
      // Copy Widget While Copy Dashboard
      if (actionType === 'Copy') {
        await getDashboardWidgetIds({ id: selectedDashboardId }).then(
          async (res) => {
            const items = res?.widgets.items ?? []
            const buildingCharts =
              JSON.parse(getValidWidgetOrderValue(res?.buildingDataCharts)) ??
              []
            const widgets =
              _uniqBy(
                items?.filter((f) => f.id),
                'widgetID'
              ) ?? []
            const copyDashboardWidgtIds = await Promise.all(
              widgets?.map(async (w) => {
                let mapId = null
                if (w?.widgetID) {
                  await createDashboardWidget({
                    input: { dashboardID: id, widgetID: w?.widgetID }
                  }).then((res) => {
                    mapId = { id: res.id, widgetId: w?.widgetID }
                  })
                }
                return mapId
              })
            )
            // Update Widget Order For New Copy Dashboard
            const widgetOrderValue = JSON.parse(
              getValidWidgetOrderValue(variables.input.widgetOrder)
            )
            if (widgetOrderValue?.length > 0) {
              const oldWidgetOrder = widgetOrderValue?.map((o) => {
                const widget = items?.find((i) => i?.id === o?.id)
                return { ...o, widgetId: widget?.widgetID }
              })
              await updateWidgetOrder(
                copyDashboardWidgtIds,
                buildingCharts,
                oldWidgetOrder,
                id
              )
            }
          }
        )
      }
      setModalType(CREATE_DASHBOARD_SUCCESS)
      dispatch(
        setDashboardIdForWidget({
          dashboardId: id
        })
      )
      const path = `dashboard/${id}`
      dispatch(
        setDashboardPath({
          dashboardPath: path
        })
      )
      dispatch<any>(getDashboards())
      navigate(path)
    } catch {
      setModalType(MODAL_TYPE.ERROR)
    }
  }
  // Update widget order for new copy dashboard
  const updateWidgetOrder = async (
    copyDashboardWidgtIds,
    buildingCharts,
    widgetOrder,
    dashBoardId
  ) => {
    if (widgetOrder?.length > 0) {
      // Widget & Building Order Details
      const newWidgetOrder = widgetOrder?.map((order) => {
        // Widget Order
        let orderDetail = copyDashboardWidgtIds?.find(
          (f) => f?.widgetId === order?.widgetId
        )
        if (!orderDetail) {
          // Building Order
          orderDetail = buildingCharts?.find((f) => f?.id === order?.id)
        }
        return {
          id: orderDetail?.id ?? null,
          width: order?.width,
          height: order?.height
        }
      })
      if (newWidgetOrder?.length > 0) {
        await mutation({
          query: UPDATE_DASHBOARD,
          variables: {
            input: {
              id: dashBoardId,
              widgetOrder: JSON.stringify(newWidgetOrder)
            }
          }
        })
      }
    }
  }

  const updateExistingDashboard = async (variables) => {
    try {
      const response = await mutation({
        variables,
        query: UPDATE_DASHBOARD
      })
      return response
    } catch {}
  }

  const handleClose = () => {
    dispatch(
      setDashboard({
        actionType: 'Close'
      })
    )
  }

  const isNextDisabled = useMemo(() => {
    if (stepType === STEPS[0]) {
      if (dashboardType === '1')
        return (
          dashboardLists.length === 0 ||
          !preConfigSelection.preConfiguredDashboardId
        )
      else if (dashboardType === '2')
        return dashboardLists.length === 0 || !preConfigSelection.myDashboardId
      else if (dashboardType === '3')
        return (
          publicDashboards.length === 0 || !preConfigSelection.publicDashboardId
        )
      else return false
    } else {
      const { name } = formik.values
      return !_isEmpty(formik.errors) || formik.isSubmitting || !name
    }
  }, [dashboardType, stepType, formik, preConfigSelection])

  const modalTitle = useMemo(() => {
    switch (actionType) {
      case 'Copy':
        return 'Copy Dashboard'
      case 'Edit':
        return 'Edit Dashboard'
      default:
        return 'Create Dashboard'
    }
  }, [actionType])

  const buttonName = useMemo(() => {
    if (stepType === STEPS[0]) return optionsTranslated.next
    switch (actionType) {
      case 'Copy':
        return optionsTranslated.save
      case 'Edit':
        return optionsTranslated.save
      default:
        return optionsTranslated.create
    }
  }, [stepType, actionType])

  useEffect(() => {
    const theModalClass =
      stepType === STEPS[3] && dashboardType === '1'
        ? 'wide-modal'
        : 'create-dashbaord-dialog'
    setModalClass(theModalClass)
  }, [stepType, dashboardType])

  const updateDefultDashboard = (dashboard: any) => {
    const { isKeyMetric, isPublic } = dashboard

    const input: any = {
      ...dashboard,
      isDefault: true,
      isKeyMetric: stringToBoolean(isKeyMetric),
      isPublic: stringToBoolean(isPublic),
      userId: userInfo?.id,
      dashboardAccessType: stringToBoolean(isPublic) ? 'PublicDashboard' : null
    }

    // deletes the id, schema will fail
    delete input.userSettingsId
    updateDashboard({ input }, true)
  }
  const copyofTranslate = translate('Copy of')
  return (
    <>
      {MODALTYPES.includes(modalType) ? (
        <ConfirmationModal />
      ) : (
        <Modal
          gray={true}
          heading={modalTitle}
          className={'wide-modal'}
          formIsErrored={Object.keys(formik?.errors)?.length > 0}
          testName={'dashboardDataId'}
          buttons={[
            {
              text: buttonName,
              handleClick: async () => {
                if (stepType === STEPS[0]) {
                  if (['2', '3'].includes(dashboardType)) {
                    const dashboardId =
                      dashboardType === '2'
                        ? preConfigSelection['myDashboardId']
                        : preConfigSelection['publicDashboardId']
                    if (dashboardId) {
                      await getDashboard({ id: dashboardId }).then(
                        (dashboard) => {
                          if (dashboard) {
                            const inputData = getCopyDashboardInputData(
                              dashboard,
                              copyofTranslate
                            )
                            dispatch(
                              setDashboard({
                                actionType: 'Copy',
                                data: inputData,
                                dashboardId: dashboard?.id
                              })
                            )
                          }
                        }
                      )
                    }
                  } else {
                    dispatch(
                      setDashboard({
                        actionType: 'Create-Blank'
                      })
                    )
                  }
                } else {
                  const {
                    name,
                    Description,
                    isDefault,
                    isKeyMetric,
                    isPublic,
                    widgetOrder,
                    buildingDataCharts
                  } = formik.values
                  const input: any = {
                    name,
                    Description,
                    isDefault: stringToBoolean(isDefault),
                    isKeyMetric: stringToBoolean(isKeyMetric),
                    isPublic: stringToBoolean(isPublic),
                    userId: userInfo?.id,
                    dashboardAccessType: stringToBoolean(isPublic)
                      ? 'PublicDashboard'
                      : null,
                    widgetOrder,
                    buildingDataCharts
                  }
                  formik.setSubmitting(true)
                  if (actionType === 'Edit') {
                    dispatch(
                      setDashboardIdForWidget({
                        dashboardId: selectedDashboardId
                      })
                    )
                    input.id = selectedDashboardId

                    if (nonSelectedDashboard && !stringToBoolean(isDefault)) {
                      updateDefultDashboard(nonSelectedDashboard)
                    }
                    // deletes the id, schema will fail
                    delete input.userSettingsId
                    updateDashboard({ input }, stringToBoolean(isDefault))
                    trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_DASHBOARD, {
                      mode: 'EDIT',
                      name: name,
                      default: stringToBoolean(isDefault),
                      description: Description,
                      'is public': stringToBoolean(isPublic),
                      'enable key metrics': stringToBoolean(isKeyMetric)
                    })
                  } else {
                    const isFound = dashboardLists?.find(
                      ({ name: dashbaordName }: any) =>
                        dashbaordName?.trim() === name?.trim()
                    )
                    if (isFound) {
                      formik.setFieldError(
                        'name',
                        'Dashboard with the same name already exists.'
                      )
                      formik.setSubmitting(false)
                      return
                    }
                    createDashboard(
                      { input },
                      stringToBoolean(isDefault),
                      stringToBoolean(isKeyMetric)
                    )
                    if (actionType === 'Copy') {
                      trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_DASHBOARD, {
                        mode: 'COPY',
                        name: name,
                        default: stringToBoolean(isDefault),
                        description: Description,
                        'is public': stringToBoolean(isPublic),
                        'enable key metrics': stringToBoolean(isKeyMetric)
                      })
                    } else {
                      trackEvent(USER_EVENTS.DASHBOARDS.events.SAVE_DASHBOARD, {
                        mode: 'CREATE',
                        name: name,
                        default: stringToBoolean(isDefault),
                        description: Description,
                        'is public': stringToBoolean(isPublic),
                        'enable key metrics': stringToBoolean(isKeyMetric)
                      })
                    }
                  }
                }
              },
              type: 'confirm',
              disabled: isNextDisabled
            },
            {
              text: optionsTranslated.cancel,
              handleClick: () => {
                handleClose()
              },
              type: 'cancel',
              disabled: false
            }
          ]}
          handleClose={() => handleClose()}
          formHasRequiredFields={true}
        >
          {stepType === STEPS[3] ? (
            <CreateBlankDashboard
              formik={formik}
              isDefault={dashboardInitValues?.isDefault}
              nonSelectedDashboard={nonSelectedDashboard}
              setNonSelectedDashboard={setNonSelectedDashboard}
            />
          ) : (
            <DashboardOptions
              preConfigDashboardOptions={preConfigDashboardOptions}
            />
          )}
        </Modal>
      )}
    </>
  )
}
export default CreateDashboard
