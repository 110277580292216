import colors from './colors.json'

const typographyList = {
  'text-button': `font-weight: 900;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.36px;
                    text-transform: uppercase;
                    color: ${colors.WHITE};`,
  'text-button-small': `
                    font-weight: 900;
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 0.27px;
                    text-transform: uppercase;
                    color: ${colors.WHITE};`,
  'button-link': `
                    font-weight: 900;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.36px;
                    text-transform: uppercase;
                    color: ${colors.WHITE};
                    text-decoration: underline;
                    color: ${colors.TBA_DARKER_GREY};
                    &:hover {
                        color: ${colors.TBA_DARK_GREY};
                    }`,
  'text-field-label': `
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 15px;
                    letter-spacing: 0.26px;`,
  'text-field-label-secondary': `
                    font-weight: bold;
                    font-size : 12px;
                    line-height : 14px;
                    letter-spacing : 0.36px;`,
  'text-field-label-inactive': `
	"TBA_DARK_GREY": "#999999",
	                color: ${colors.TBA_DARK_GREY};`,
  'check-box-inactive': `
	                background: ${colors.TBA_MEDIUM_GREY};`,
  'text-field-label-error': `
	                color: ${colors.RED};`,
  'text-field-input': `
	                font-weight: bold;
                    font-size: 12px;
                    line-height: 18px;`,
  'text-field-input-inactive': `
                    color: ${colors.TBA_DARK_GREY};
                    background-color: ${colors.TBA_LIGHT_GREY};
                    border-color: ${colors.TBA_MEDIUM_GREY};`,
  'text-field-input-error': `
                    background: ${colors.PINK};
                    border: 2px solid ${colors.RED};`,
  'text-field-item': `
                    font-weight: bold;
                    font-style: italic;
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 0.20px;`,
  'text-form-error': `
                    font-weight: bold;
                    font-style: italic;
                    font-size: 13px;
                    line-height: 15px;
                    letter-spacing: 0.26px;
                    color: ${colors.RED};`,
  'text-drop-down': `
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 26px;`,
  'text-drop-down-small': `
                    font-weight: bold;
                    font-size: 10px;
                    line-height: 22px;`,
  'text-list': `
                    font-size: 12px;
                    line-height: 19px;
                    letter-spacing: 0.24px;`,
  'text-list-condensed': `
                    font-family: "Archivo Narrow", sans-serif;
                    font-size: 12px;
                    line-height: 19px;
                    letter-spacing: 0.24px;`,
  'text-search-item': `
                    font-family: "Archivo Narrow", sans-serif;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.24px;`,
  'small-table-header': `
                    font-weight: 900;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.36px;
                    text-transform: uppercase;
                    color: ${colors.BLACK};`,
  'text-ellipsis': `
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;`,
  'text-menuitem': `
                    font-size: 14px;
                    line-height: 18px;`,
  'no-select': `
                    -webkit-touch-callout: none; /* iOS Safari */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none;`
}
export default typographyList
