import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'src/hooks/APIHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Icon from 'src/denali-ui/components/Icon/index'
import colors from 'src/components/legacy/common/colors.json'
import {
  ListBuildingDevices,
  GET_TIS_EQUIPMENT_LIST,
  GET_EQUIPMENT_LIST,
  GET_BUILDING_EQUIPMENT_TYPES,
  GET_NIAGARA_EQUIPMENT_LIST_BY_BUILDING_ID
} from '../graphql/queries'
import {
  getEquipmentsTreeData,
  getAutomationSystemListData,
  getEquipmentsList,
  getDataFromNestedArray,
  sanitaizeDropDownData,
  orderWithUniq
} from './helper'
import { TableWrapper, NoLocation, SerialWarningComponent } from './style'
import { TIS_EQUIPMENT_LIST, NONE, DATA_SOURCE_NAME, NAME } from '../constants'
import TableNew from 'src/components/Table/clientSideTable'
import DeleteEquipmentDialog from '../DeleteEquipmentDialogue/DeleteEquipmentDialog.jsx'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import _uniq from 'lodash/uniqBy'
import _orderBy from 'lodash/orderBy'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { getSearchParams } from '../../../common/helperFunctions.js'
import translate, { TranslateComponent } from 'src/common/translations'
import { accessControlFunc } from 'src/components/accessControl'
import { useSelector } from 'react-redux'
import { selectUserAccess } from 'src/redux/slicers/appData'
import WarningsDialog from 'src/components/legacy/components/error-dialog/error-dialog'
import { TEXT_NAME_PREFIX } from 'src/pages/equipmentSetup/constants'
import { translateJSON } from 'src/redux/slicers/translationCombinedSlice'
import { useAppDispatch } from 'src/redux/store'
import { RootState } from 'src/redux/RootState'

const EquipmentSetupList = (props: any) => {
  const optionsTranslated = {
    equipmentName: translate('Equipment Name'),
    equipmentType: translate('Equipment Type'),
    automation: translate('Automation System'),
    dataName: translate('Data Source Name'),
    dataType: translate('Data Source Type'),
    controller: translate('Controller Type'),
    serialNumber: translate('Serial Number'),
    modelNumber: translate('Model Number'),
    delete: translate('Delete'),
    standAlone: translate('Standalone')
  }
  const [t] = useTranslation()
  const {
    equipmentListType,
    setLoadtime,
    isRefreshEquipmentList,
    getEquipments,
    isNAX
  } = props

  const userAccess = useSelector(selectUserAccess)

  const [tisListData, settisListData] = useState([])
  const [allTisListData, setAllTisListData] = useState([])
  const [deviceList, setDeviceList] = useState([])
  const [activeColumn, setActiveColumn] = useState(0)
  const navigate = useNavigate()
  const searchParams = getSearchParams()
  const buildingId = searchParams?.buildingId
  const [equipmentToDelete, setEquipmentToDelete] = useState(null)
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false)
  const [error, setError] = useState(null)
  const [eqType, setEqType] = useState({ buildingId: null, data: [] })
  const [autoSystem, setAutoSystem] = useState([])
  const [objType, setObjType] = useState([])
  const [loadingAllEquipments, setLoadingAllEquipments] = useState(false)
  const [niagaraEquipmentData, setNiagaraEquipmentData] = useState([])
  const appDispatch = useAppDispatch()
  const { translatedJSON } = useSelector(
    (state: RootState) => state.translationCombinedSlice
  )
  const [eqListForDataSourceTypes, setEqListForDataSourceTypes] = useState([])
  const [translatedEquipTypes, setTranslatedEquipTypes] = useState([])
  const [equipmentData, setEquipmentData] = useState([])
  const [translatedObjTypes, settranslatedObjTypes] = useState([])
  const [isFilterChange, setFilterChange] = useState(false)

  const [selectedAutomation, setSelectedAutomation] = useState({
    isWarningDialogOpen: false,
    warningHeader: '',
    warningMessage: ''
  })

  const {
    data: responseEquipmentData,
    refetch: refetchEquipmentsData,
    loading
  } = useQuery({
    query: GET_EQUIPMENT_LIST,
    dataPath: 'data.searchEquipment',
    disableInitialLoad: true,
    variables: { id: buildingId },
    errorPolicy: 'all'
  })

  const {
    data: responseData,
    refetch: refetchBuildingDevices,
    responseTime
  } = useQuery({
    query: ListBuildingDevices,
    dataPath: 'data.getBuilding',
    disableInitialLoad: true,
    variables: { id: buildingId }
  })

  const {
    data: equipmentTypes,
    refetch: refetchEquipmentTypes,
    loading: loadingEquipmentTypes
  } = useQuery({
    query: GET_BUILDING_EQUIPMENT_TYPES,
    disableInitialLoad: true,
    variables: { requestBody: JSON.stringify([buildingId]) },
    dataPath: 'data.getBuildingEquipmentTypes'
  })

  const {
    data: responseNiagaraEquipment,
    refetch: refetchNiagaraEquipmentList
  } = useQuery({
    query: GET_NIAGARA_EQUIPMENT_LIST_BY_BUILDING_ID,
    errorPolicy: 'all',
    dataPath: 'data.searchEquipment',
    disableInitialLoad: true,
    variables: { buildingId: buildingId }
  })

  useEffect(() => {
    try {
      if (responseNiagaraEquipment && responseNiagaraEquipment?.items.length) {
        setNiagaraEquipmentData([
          ...niagaraEquipmentData,
          ...responseNiagaraEquipment?.items
        ])
      }
      if (
        isNAX &&
        responseNiagaraEquipment &&
        responseNiagaraEquipment.nextToken !== null
      ) {
        refetchNiagaraEquipmentList({
          buildingId: buildingId,
          nextToken: responseNiagaraEquipment?.nextToken
        })
      }
    } catch (error) {
      console.log(error)
    }
  }, [responseNiagaraEquipment, isNAX])

  useEffect(() => {
    if (buildingId) {
      // resets all data
      setEquipmentData([])
      setActiveColumn(0)
      setEquipmentToDelete(null)
      setEqType({ buildingId: null, data: [] })
      setError(null)
      refetchBuildingDevices()
      refetchEquipmentsData()
      refetchEquipmentTypes()
    }
    // eslint-disable-next-line
  }, [buildingId])

  // add unit serial number and model number for GCPM
  const formatEquipmentListWithSerialNumber = (equipmentData) => {
    return equipmentData?.map((x) => {
      const equipmentProps = x?.equipmentProperties?.items || []
      if (equipmentProps?.length > 0) {
        if (equipmentProps[0]?.equipmentId === x?.id) {
          const unitSerialNumber =
            equipmentProps?.find((x) => x?.targetKey === 'UnitSerialNumber')
              ?.value || ''
          const unitModelNumber =
            equipmentProps?.find((x) => x?.targetKey === 'UnitModelNumber')
              ?.value || ''
          return {
            ...x,
            unitSerialNumber,
            unitModelNumber
          }
        } else {
          return { ...x }
        }
      } else {
        return { ...x }
      }
    })
  }

  useEffect(() => {
    if (buildingId && isNAX) {
      refetchNiagaraEquipmentList()
    }
  }, [buildingId, isNAX])

  useEffect(() => {
    setFilterChange(true)
  }, [translatedObjTypes?.length])

  useEffect(() => {
    try {
      setLoadingAllEquipments(true)
      if (responseEquipmentData && responseEquipmentData?.items.length) {
        const dataWithSerialNumber = formatEquipmentListWithSerialNumber(
          responseEquipmentData?.items || []
        )
        setEquipmentData([...equipmentData, ...dataWithSerialNumber])
      }
      if (responseEquipmentData && responseEquipmentData.nextToken !== null) {
        refetchEquipmentsData({
          id: buildingId,
          nextToken: responseEquipmentData?.nextToken
        })
        setLoadingAllEquipments(false)
      } else {
        setLoadingAllEquipments(false)
      }
    } catch (error) {
      console.log(error)
      setLoadingAllEquipments(false)
    }
  }, [responseEquipmentData])

  useEffect(() => {
    if (buildingId && isRefreshEquipmentList) {
      setEquipmentData([])
      refetchBuildingDevices()
      refetchEquipmentsData()
      isNAX && refetchNiagaraEquipmentList()
    }
  }, [isRefreshEquipmentList])

  useEffect(() => {
    if (!loadingEquipmentTypes && equipmentTypes) {
      const res = JSON.parse(equipmentTypes)
      if (res && res.statusCode === 200) {
        const equipmentTypes = Object.keys(res.body?.EquipmentType)
        setEqType({ buildingId, data: sanitaizeDropDownData(equipmentTypes) })
        setTranslatedEquipTypes(sanitaizeDropDownData(equipmentTypes))
      } else {
        setEqType({ buildingId, data: [] })
      }
    }
  }, [equipmentTypes, loadingEquipmentTypes])

  useEffect(() => {
    if (responseData && equipmentData) {
      const tisData = equipmentData.filter((eq) => eq?.id)
      const filteredTisData = tisData?.filter(
        (ele) => ele?.type != NONE && ele?.isTIS === 1
      )
      const deviceData = responseData?.devices?.items
      const automationList = getEquipmentsList(tisData, deviceData)
      const automationRowData = getAutomationSystemListData(automationList)

      setLoadtime(responseTime)
      setDeviceList(deviceData)
      settisListData(filteredTisData)
      setAllTisListData(tisData)
      setEqListForDataSourceTypes(tisData)
      getEquipments(tisData)

      if (tisData) {
        // set tis equipment dropdown data
        //const filteredEqData = filteredTisData?.map(it => it?.family)
        //const eqTypes = orderWithUniq(filteredEqData)
        // setEqType(sanitaizeDropDownData(eqTypes));

        // set automation system- object type dropdown data
        const objectsTypes = getDataFromNestedArray(automationRowData)
          .filter((it) => it?.dataSourceType)
          ?.map((it) => it.dataSourceType)
        const objTyp = orderWithUniq(objectsTypes)
        setObjType(sanitaizeDropDownData(objTyp))
      }

      if (deviceData) {
        // set automation system dropdown data
        const automationSystems = deviceData
          .filter((item) => tisData?.find((ele) => ele?.deviceId == item?.id))
          ?.map((it) => it?.name)
        const systemAuto = orderWithUniq(automationSystems)
        setAutoSystem(sanitaizeDropDownData(systemAuto))
      }
    }
    // eslint-disable-next-line
  }, [responseData, equipmentData])

  const onCloseDialog = () => () => {
    setError({ error: null })
  }
  const toggleDeleteDialog = (equipmentToDelete = null, isError = null) => {
    setIsDeleteDialogShown(!isDeleteDialogShown)
    setEquipmentToDelete(equipmentToDelete)
    isError &&
      setError({
        title: t('errors:Error'),
        text: t('errors:unexpected-error')
      })
  }

  const onClickEquipment = useCallback(
    (equipmentId: string) => {
      navigate(
        `/equipment-setup/details?location=${buildingId}&equipment=${equipmentId}`
      )
    },
    [navigate, buildingId]
  )

  const getHyperLinkNameNew = useCallback(
    (row) => {
      return (
        !row.name?.includes('Standalone') && row?.id && onClickEquipment(row.id)
      )
    },
    [onClickEquipment]
  )

  const handleNameClick = () => {
    setSelectedAutomation({
      isWarningDialogOpen: true,
      warningHeader: 'Warning',
      warningMessage: t(
        'equipment-setup:DataMappingIsNotAvailableForSiteObject'
      )
    })
  }

  const closeWarnigDialog = () => {
    setSelectedAutomation({
      isWarningDialogOpen: false,
      warningHeader: '',
      warningMessage: ''
    })
  }

  const tisListColumnNew = [
    {
      title: optionsTranslated.equipmentName,
      key: 'name',
      name: 'name',
      onDataClick: (row) => getHyperLinkNameNew(row)
    },
    { title: optionsTranslated.equipmentType, key: 'familyUI', name: 'family' },
    {
      title: optionsTranslated.serialNumber,
      key: 'unitSerialNumber',
      name: 'unitSerialNumber',
      customComponent: function (props) {
        return (
          <WarningComponent
            family={props?.family}
            number={props?.unitSerialNumber}
          />
        )
      }
    },
    {
      title: optionsTranslated.modelNumber,
      key: 'unitModelNumber',
      name: 'unitModelNumber',
      customComponent: function (props) {
        return (
          <WarningComponent
            family={props?.family}
            number={props?.unitModelNumber}
          />
        )
      }
    },
    {
      title: optionsTranslated.automation,
      key: 'automationSystem',
      name: 'automationSystem'
    },
    {
      title: optionsTranslated.dataName,
      key: 'dataSourceName',
      name: 'dataSourceName'
    },
    {
      title: optionsTranslated.dataType,
      key: 'dataSourceType',
      name: 'dataSourceType'
    },
    {
      title: optionsTranslated.controller,
      key: 'controllerType',
      name: 'controllerType'
    }
  ]

  const automationColumnNew = [
    {
      title: translate('Object Name'),
      key: 'dataSourceName',
      name: 'dataSourceName',
      onDataClick: () => {
        handleNameClick()
      }
    },
    {
      title: translate('Automation System'),
      key: 'automationSystem',
      name: 'automationSystem'
    },
    {
      title: translate('Object Type'),
      key: 'dataSourceTypeUI',
      name: 'dataSourceType'
    },
    {
      title: translate('Controller Type'),
      key: 'controllerType',
      name: 'controllerType'
    },
    {
      title: translate('Default Count'),
      key: 'defaultCount',
      name: 'defaultCount'
    }
  ]

  const unstructureSubLists = (data) => {
    return data?.reduce((accum, curr) => {
      if (!curr?.subData?.length) {
        return [...accum, curr]
      } else {
        const currWithoutSubData = { ...curr }
        delete currWithoutSubData['subData']
        return [
          ...accum,
          currWithoutSubData,
          ...unstructureSubLists(curr.subData)
        ]
      }
    }, [])
  }
  // Call the function to extract subdata recursively

  const extractSubdata = (data, b, result = [], _index) => {
    for (const item of data) {
      const { subData, ...rest } = item
      result.push(rest)
      if (subData) {
        extractSubdata(subData, b, result, _index)
      }
    }

    const filteredData = result.filter((ele) => {
      let flag = -1
      for (let i = 0; i <= _index; i++) {
        if (Array.isArray(result[i].selectedValue)) {
          if (result[i].selectedValue?.includes(ele[`${result[i].name}`])) {
            flag++
          }
        } else {
          if (ele[`${result[i].name}`] == result[i].selectedValue) {
            flag++
          }
        }
      }
      return flag == _index
    })
    return filteredData
  }

  const getSearchValue = (obj, field) => {
    const arrayOfKeys = field?.split('.')
    return arrayOfKeys?.reduce((accum, curr) => {
      accum = accum?.[curr]
      return accum
    }, obj)
  }

  const searchAction = (searchRows, searchText, searchFields, result = []) => {
    searchRows?.forEach((item) => {
      const { subData, ...rest } = item
      result.push(rest)
      if (subData) {
        searchAction(subData, searchText, searchFields, result)
      }
    })
    return result.reduce((acc, val) => {
      const exists = searchFields?.some((f) =>
        getSearchValue(val, f)
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase())
      )
      if (exists && !acc.some((accVal) => accVal.id === val.id)) {
        acc.push(val)
        return acc
      } else {
        return acc
      }
    }, [])
  }

  const WarningComponent = ({ number, family }) => {
    return number ? (
      <p>
        <strong>{number}</strong>
      </p>
    ) : family === 'Chiller' ? (
      <SerialWarningComponent>
        <Icon
          name="Warning"
          width="15px"
          height="15px"
          margin="4px 4px 0px 0px"
          color={colors.RED}
        />
        <span style={{ marginTop: 5 }}>Undefined</span>
      </SerialWarningComponent>
    ) : null
  }

  const rowData = useMemo(() => {
    const equipmentsList = getEquipmentsList(tisListData, deviceList)
    const automationList = getEquipmentsList(allTisListData, deviceList)
    if (equipmentListType === TIS_EQUIPMENT_LIST) {
      if (activeColumn === 0) {
        const equipmentsListScreened = equipmentsList?.map((obj) => {
          if (obj?.parentId?.includes(obj?.id)) {
            return {
              ...obj,
              parentId: obj?.parentId
                ?.split('#')
                ?.filter((i) => i !== obj?.id)
                ?.join('#')
            }
          } else {
            return obj
          }
        })
        const equipTreeData = getEquipmentsTreeData(
          equipmentsListScreened,
          optionsTranslated.standAlone,
          translatedJSON
        )
        return equipTreeData
      } else {
        return equipmentsList
      }
    } else if (isNAX) {
      const tableRowData: { dataSourceName: string; subData: JSON[] }[] = [
        { dataSourceName: 'Standalone Device', subData: [] }
      ]
      const subData = []
      const tisEquipmentList = equipmentData?.filter(
        (ele) => ele?.type !== NONE && ele?.isTIS === 1
      )
      niagaraEquipmentData.forEach((point) => {
        let comps = point.name.split('/')
        const compEnd = comps.indexOf('points')
        if (compEnd > -1) {
          comps = comps.slice(0, compEnd)
        } else {
          comps = comps.slice(0, comps.length - 1)
        }
        const truncatedName = comps.join('/')
        const newChild = {
          dataSourceName: truncatedName,
          id: point.id,
          automationSystem: truncatedName,
          dataSourceType: 'Device',
          controllerType: point.controllerType ?? 'None',
          defaultCount: tisEquipmentList?.filter(
            (equipment) => equipment.dataSourceName === truncatedName
          )?.length
        }
        subData.push(newChild)
      })
      tableRowData[0].subData = _uniq(subData, 'dataSourceName')
      return tableRowData
    } else {
      const automationRowData = getAutomationSystemListData(automationList)

      return activeColumn === 0
        ? automationRowData
        : automationRowData?.reduce((accum, curr) => {
            if (!curr?.subData?.length) {
              return [...accum, curr]
            } else {
              const currWithoutSubData = { ...curr }
              if (curr.subData) {
                delete currWithoutSubData['subData']
                return [...accum, ...unstructureSubLists(curr.subData)]
              } else {
                return [...accum, currWithoutSubData]
              }
            }
          }, [])
    }
  }, [
    tisListData,
    equipmentListType,
    activeColumn,
    niagaraEquipmentData,
    isNAX
  ])

  useEffect(() => {
    if (
      eqListForDataSourceTypes.length !== 0 &&
      eqType?.data.length !== 0 &&
      objType.length !== 0
    ) {
      const allTypesToTranslate = [
        ...eqListForDataSourceTypes,
        ...eqType.data,
        ...objType
      ].reduce((acc, item) => {
        if (item.dataSourceType && !acc.hasOwnProperty(item.dataSourceType)) {
          acc[item.dataSourceType] = item.dataSourceType
        }
        if (item.family && !acc.hasOwnProperty(item.family)) {
          acc[item.family] = item.family
        }
        if (item.name && item.value && !acc.hasOwnProperty(item.name)) {
          acc[item.name] = item.value
        }
        return acc
      }, {})

      if (Object.keys(allTypesToTranslate).length !== 0)
        appDispatch(translateJSON({ json: allTypesToTranslate }) as any)
    }
  }, [eqListForDataSourceTypes, eqType?.data, objType])

  useEffect(() => {
    if (translatedJSON) {
      const translatedTisList = tisListData?.map((eq) => {
        const translatedEquipmentTypes = translatedJSON
        return {
          ...eq,
          familyUI: translatedEquipmentTypes[eq?.family]
        }
      })
      settisListData(translatedTisList)

      const translatedAllTisList = allTisListData?.map((eq) => {
        const translatedObjTypes = translatedJSON
        return {
          ...eq,
          dataSourceTypeUI: translatedObjTypes[eq?.dataSourceType]
        }
      })
      setAllTisListData(translatedAllTisList)

      const translatedEqTypes = eqType?.data.reduce((acc, item) => {
        const translatedValue = translatedJSON[item.name]
        if (translatedValue) {
          acc.push({ name: translatedValue, value: item.name })
        }
        return acc
      }, [])
      setTranslatedEquipTypes(translatedEqTypes)

      const translatedObjTypes = objType.reduce((acc, item) => {
        const translatedValue = translatedJSON[item.name]
        if (translatedValue) {
          acc.push({ name: translatedValue, value: item.name })
        }
        return acc
      }, [])
      settranslatedObjTypes(translatedObjTypes)
    }
  }, [translatedJSON])

  const automationSystemDropDown = {
    filterName: 'typeFilter',
    id: 'automationSelectorFilter',
    defaultLabel: 'All Automation System',
    selectedValue: 'default',
    options: autoSystem,
    name: 'automationSystem',
    action: (a, b, c, d) =>
      extractSubdata(rowData?.length > 0 ? rowData : a, b, c, d)
  }

  const selectedFieldFilter =
    equipmentListType === TIS_EQUIPMENT_LIST
      ? [
          {
            filterName: 'typeFilterOne',
            id: 'typeSelectorFilter',
            defaultLabel: 'All Equipment Type',
            selectedValue: 'default',
            options: translatedEquipTypes,
            name: 'family',
            action: (a, b, c, d) =>
              extractSubdata(rowData?.length > 0 ? rowData : a, b, c, d)
          },
          automationSystemDropDown
        ]
      : [
          {
            filterName: 'typeFilter',
            id: 'automationSystemFilter',
            defaultLabel: 'All Object Type',
            selectedValue: 'default',
            options: translatedObjTypes,
            name: 'dataSourceType',
            action: (a, b, c, d) =>
              extractSubdata(rowData?.length > 0 ? rowData : a, b, c, d)
          },
          automationSystemDropDown
        ]
  if (!buildingId) {
    return (
      <NoLocation>
        <h3>
          <TranslateComponent>
            Select a building above to view equipment
          </TranslateComponent>
        </h3>
      </NoLocation>
    )
  }

  if (
    loadingAllEquipments ||
    loadingEquipmentTypes ||
    eqType.buildingId === null
  ) {
    return <Spinner />
  }
  return (
    <>
      <TableWrapper>
        <TableNew
          testName={TEXT_NAME_PREFIX()}
          onUpdateControlsFunc={(controls) =>
            setActiveColumn(controls.activeColumn || 0)
          }
          rows={rowData}
          defaultSortColumn={activeColumn}
          header={
            equipmentListType === TIS_EQUIPMENT_LIST
              ? tisListColumnNew
              : automationColumnNew
          }
          search={true}
          searchFields={
            equipmentListType === TIS_EQUIPMENT_LIST
              ? [NAME]
              : [DATA_SOURCE_NAME]
          }
          filtersList={selectedFieldFilter}
          isFilterChange={isFilterChange}
          searchAction={searchAction}
          showSpinner={loading}
          rowControl={
            equipmentListType === TIS_EQUIPMENT_LIST
              ? (row) => {
                  const isStandalone = row?.equipmentType === 'StandAlone'
                  const isCreattionTypeUser = row?.creationType === 'User'
                  const isShowRowControl =
                    !isStandalone &&
                    isCreattionTypeUser &&
                    accessControlFunc({
                      id: 'tc.pages.equipment-setup.delete',
                      userAccess
                    })
                  if (isShowRowControl) {
                    return [
                      {
                        text: optionsTranslated.delete,
                        action: (data: any) => {
                          if (data?.id)
                            toggleDeleteDialog(
                              {
                                equipmentName: data.name,
                                equipmentId: data.id
                              },
                              null
                            )
                        }
                      }
                    ]
                  } else {
                    return []
                  }
                }
              : undefined
          }
          key={
            equipmentListType === TIS_EQUIPMENT_LIST
              ? 'equipment-list'
              : 'automation-list'
          }
          fixedLayout={false}
        />
      </TableWrapper>
      {isDeleteDialogShown && (
        <DeleteEquipmentDialog
          equipmentToDelete={equipmentToDelete}
          toggleDeleteDialog={toggleDeleteDialog}
          refetchEquipments={(status, equipmentId) => {
            if (status) {
              setEquipmentData((equipmentData) =>
                equipmentData.filter((data) => data.id !== equipmentId)
              )
            } else {
              setEquipmentData([])
              refetchEquipmentsData()
            }
          }}
        />
      )}
      {selectedAutomation?.isWarningDialogOpen && (
        <WarningsDialog
          title={selectedAutomation?.warningHeader}
          handleClose={closeWarnigDialog}
          text={selectedAutomation?.warningMessage}
        />
      )}
      <StandardDialogs error={error} onCloseDialog={onCloseDialog} />
    </>
  )
}

export default EquipmentSetupList
