import { useRef } from 'react'
import Table from '../../components/Table/clientSideTable'
import { useTranslation } from 'react-i18next'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import PageHeader from '../../components/pageHeaderNew/header'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { useEffect, useMemo, useState } from 'react'
import { SEARCH_BUILDINGS } from './graphql/queries'
import { useQuery } from 'src/hooks/APIHooks'
import { Button } from 'src/components/inputs/button'
import { NewStyles } from 'src/NewStyles'
import translate, { TranslateComponent } from '../../common/translations'
import { Auth } from 'aws-amplify'
import IconSVG from 'src/components/Icon'
import colors from 'src/components/legacy/common/colors.json'
import Modal from 'src/denali-ui/components/Modal'
import { isWindows, isMobile } from 'react-device-detect'
import { OpenVPNModals } from './openVPNModals'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { AvailabilityIcon } from './styles'
import {
  getHostName,
  connectToDevice,
  getConnectedHost,
  disconnectUser
} from './open-vpn-api'
import { selectUiMode } from 'src/redux/slicers/appData'
import { selectUserInfo } from 'src/redux/slicers/appData'
import { useSelector } from 'react-redux'
import { UiMode } from 'src/redux/types/AppTypes'
import { DeviceConnectList } from 'src/denali-pages/DeviceConnect/DeviceConnectList'
import { remoteAccessURL } from '../../aws-exports'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import { AvailTooltip, AvailHelpTooltip } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignalStream } from 'src/denali-components/lib/pro-solid-svg-icons'
import { IconColor, Page } from 'src/denali-components/Page/Page'
import { useSearchParams } from 'react-router-dom'
import { Dialog } from 'src/denali-components/Dialog/Dialog'

export const DEVICE_CONNECTION = {
  deviceNotConnected: 'deviceNotConnected',
  tunnelNotEstablished: 'tunnelNotEstablished',
  tunnelEstablished: 'tunnelEstablished',
  download: 'download-installer',
  userConnectedDifferentServer: 'userConnectedDifferentServer',
  error: 'error'
}

export const ConnectToADevice = () => {
  const deviceTypes = {
    SC: `SC/SC+`,
    CM: `CM`,
    SEC: `Symbio 700`,
    ES: `Ensemble`,
    LEC: `Symbio 800`,
    SCG: `SCG`
  }

  const openVPNAllowedTypes = ['SC', 'SCG']

  const remoteAccessNotAllowed = ['NAX', 'BCU']

  const optionsTranslated = {
    name: translate('Name'),
    type: translate('Type'),
    building: translate('Building'),
    address: translate('Address'),
    state: translate('State'),
    serial: translate('Serial') + ' #',
    avail: translate('Availability '),
    mobileApp: translate('Mobile App'),
    connectButton: translate('CONNECT'),
    errorModalHeading: translate('Error')
  }

  const [t] = useTranslation()

  const userInfo = useSelector(selectUserInfo)

  const { salesOfficeId, organizationId, buildingId } = getSearchParams()

  const [searchParams] = useSearchParams()

  const [openVPNDeviceDetails, setOpenVPNDeviceDetails] = useState(null)

  const [openVPNAPILoading, setOpenVPNAPILoading] = useState(false)

  const [connectedTimestamp, setConnectedTimestamp] = useState(null)

  const [deviceConnectionStatus, setDeviceConnectionStatus] = useState(null)

  const [rows, setRows] = useState([])

  const openVPNDeviceDetailsRef = useRef('')

  const [selectedConnect, setSelectedConnect] = useState(null)

  const [filters, setFilters] = useState([
    {
      label: 'All Types',
      name: 'type',
      key: 'type',
      id: 'typeSelectorFilter',
      defaultLabel: 'All Types',
      selectedValue: 'default',
      options: Object.keys(deviceTypes).map((e) => ({
        name: deviceTypes[e],
        value: deviceTypes[e]
      }))
    }
  ])
  const [isShowOpenVPNModal, setIsShowOpenVPNModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [buildingData, setBuildingData] = useState([])
  const [error, setError] = useState(false)

  const issueEncounteredError = "We've encountered an error connecting to the selected device. Please contact support if the issue persists."

  const downloadInstallerHandler = () => {
    setDeviceConnectionStatus(DEVICE_CONNECTION?.download)
  }

  const disconnectDevice = async () => {
    try {
      const hostName =
        deviceConnectionStatus ===
        DEVICE_CONNECTION?.userConnectedDifferentServer
          ? openVPNDeviceDetails?.userConnectedHostname
          : openVPNDeviceDetails?.hostName
      await disconnectUser(userInfo?.email, hostName)
      setIsShowOpenVPNModal(false)
    } catch (e) {}
  }

  const refreshOpenVPNButtonClick = () => {
    openVPNDeviceDetailsRef.current = openVPNDeviceDetails
    // call connect after 10s, API will take some time to get the IP address
    // after the tunnel is established
    setOpenVPNAPILoading(true)
    setTimeout(() => {
      openVPNConnectDetails()
    }, 10000)
  }

  const openVPNConnectDetails = async () => {
    try {
      if (
        openVPNDeviceDetails &&
        openVPNDeviceDetails?.hostName !== '' &&
        openVPNConnectDetails?.ipAddress !== ''
      ) {
        const openVPNConnectDetails = openVPNDeviceDetailsRef.current
        // user connected host
        const userTunnelDetails = await getConnectedHost(
          userInfo?.email?.toUpperCase()
        )
        if (userTunnelDetails && userTunnelDetails?.hostName !== '') {
          if (userTunnelDetails?.hostName === openVPNDeviceDetails?.hostName) {
            setDeviceConnectionStatus(DEVICE_CONNECTION?.tunnelEstablished)
            setConnectedTimestamp(
              userTunnelDetails?.duration
                ? Number(userTunnelDetails?.duration)
                : 0
            )
          } else {
            setDeviceConnectionStatus(
              DEVICE_CONNECTION?.userConnectedDifferentServer
            )
            setOpenVPNDeviceDetails({
              ...openVPNConnectDetails,
              userConnectedHostname: userTunnelDetails?.hostName
            })
          }
        }
      } else {
        // this block won't execute, but just a safer side
        setDeviceConnectionStatus(DEVICE_CONNECTION?.tunnelNotEstablished)
      }
      setOpenVPNAPILoading(false)
    } catch (e) {
      setOpenVPNAPILoading(false)
      setDeviceConnectionStatus(DEVICE_CONNECTION?.error)
    }
  }

  const serviceToolButtonHandler = async (deviceDetails) => {
    try {
      if (deviceDetails) {
        const buildingName =
          buildingData?.find((x) => x?.id === deviceDetails?.buildingId)
            ?.name || ''

        setIsShowOpenVPNModal(true)
        if (
          openVPNConnectDetails &&
          openVPNConnectDetails?.serialNumber?.replace('-local', '') ===
            deviceDetails?.serial?.replace('-local', '') &&
          deviceConnectionStatus === DEVICE_CONNECTION?.tunnelEstablished
        ) {
          // if the device is already connected and tunnel is established, just return
          return
        } else {
          setOpenVPNDeviceDetails(null)
        }

        setOpenVPNAPILoading(true)

        // checks the user has any active tunnel connections
        const userTunnel = await getConnectedHost(
          userInfo?.email?.toUpperCase()
        )

        if (userTunnel?.hostName && userTunnel?.hostName !== '') {
          // every time user connect a new device, disconnect if already tunnel is there
          await disconnectUser(userInfo?.email, userTunnel?.hostName)
        }

        const serialNumber = deviceDetails?.serial?.replace('-local', '')

        // checks the device connected to any open VPN servers
        const deviceHostName = await getHostName(serialNumber)
        let ipAddress = ''
        if (deviceHostName && deviceHostName !== '') {
          const connectionDetails = await connectToDevice(
            serialNumber,
            deviceHostName
          )
          if (connectionDetails && connectionDetails.ipAddress !== '') {
            setDeviceConnectionStatus(DEVICE_CONNECTION?.tunnelNotEstablished)
            const deviceDetails = connectionDetails?.devices?.find(
              (x) => x?.uri?.replace('-local', '') === serialNumber
            )
            ipAddress = deviceDetails?.ipAddress || ''
          } else {
            setDeviceConnectionStatus(DEVICE_CONNECTION?.deviceNotConnected)
          }
        } else {
          setDeviceConnectionStatus(DEVICE_CONNECTION?.deviceNotConnected)
        }

        setOpenVPNDeviceDetails({
          deviceName: deviceDetails?.name,
          buildingId: deviceDetails?.buildingId,
          serialNumber: deviceDetails?.serial?.replace('-local', ''),
          buildingName: buildingName,
          userMail: userInfo?.email || '',
          ipAddress: ipAddress || '',
          deviceType: deviceDetails?.type,
          hostName: deviceHostName
        })
        setOpenVPNAPILoading(false)
      }
    } catch (e) {
      setOpenVPNAPILoading(false)
      setDeviceConnectionStatus(DEVICE_CONNECTION?.error)
    }
  }

  const toggleOpenVPNModal = () => {
    setIsShowOpenVPNModal(!isShowOpenVPNModal)
  }

  const showServiceToolsOptions = useMemo(() => {
    return !isMobile && isWindows
  }, [])

  const nameHeadings = [
    {
      name: 'name',
      title: optionsTranslated.name,
      key: 'name',
      maxWidth: '150px'
    },
    {
      name: 'type',
      title: optionsTranslated.type,
      key: 'type',
      maxWidth: '120px'
    },
    {
      name: 'building',
      title: optionsTranslated.building,
      key: 'building',
      maxWidth: '120px'
    },
    {
      name: 'address',
      title: optionsTranslated.address,
      key: 'address',
      maxWidth: '120px'
    },
    {
      name: 'state',
      title: optionsTranslated.state,
      key: 'state',
      maxWidth: '120px'
    },
    {
      name: 'serial',
      title: optionsTranslated.serial,
      key: 'serial',
      width: 100,
      maxWidth: '140px'
    },
    {
      name: 'availability',
      title: optionsTranslated.avail,
      key: 'availability',
      sortField: 'availabilitySort',
      maxWidth: '50px',
      tableHeaderIcon: 'customHederIcon',
      tableControlIcon: 'customControlIcon',
      customHeaderComponent: ({ title }) => (
        <AvailTooltip>
          {title}
          <ConditionalTooltip
            position="bottom"
            multiline={true}
            width="250px"
            content={
              <div className="availability-help-tooltip">
                <AvailHelpTooltip>
                  <div>
                    <IconSVG
                      name="available"
                      color={colors.GREEN}
                      hover={colors.GREEN}
                      height="18px"
                      width="18px"
                      margin="2px 13px 0px 3px"
                      right="5px"
                    />
                  </div>
                  <div className="content">
                    <span>
                      <TranslateComponent>
                        Tracer SC device is available for a connection.
                      </TranslateComponent>
                    </span>
                  </div>
                </AvailHelpTooltip>
                <AvailHelpTooltip>
                  <div>
                    <IconSVG
                      name={'unavailable'}
                      color={'rgb(85, 85, 85)'}
                      hover={'rgb(85, 85, 85)'}
                      height="18px"
                      width="18px"
                      margin="2px 13px 0px 3px"
                      right="5px"
                    />
                  </div>
                  <div className="content">
                    <span>
                      <TranslateComponent>
                        Tracer SC device is setup in Remote Access, but is not
                        available for a connection.
                      </TranslateComponent>
                    </span>
                  </div>
                </AvailHelpTooltip>
                <AvailHelpTooltip>
                  <div>
                    <IconSVG
                      name="unknown"
                      color={colors.BLACK}
                      hover={colors.BLACK}
                      viewBox="0 0 80 120"
                      height="30px"
                      width="30px"
                      margin="2px 0px 0px 3px"
                      right="5px"
                    />
                  </div>
                  <div className="content">
                    <span>
                      <TranslateComponent>
                        This device may be available for a connection but its
                        connection status is unknown.
                      </TranslateComponent>
                    </span>
                  </div>
                </AvailHelpTooltip>
              </div>
            }
          >
            <div>
              <i className={`icon icon-help`} />
            </div>
          </ConditionalTooltip>
        </AvailTooltip>
      )
    },
    {
      name: 'actions',
      title: <TranslateComponent>Trane SC Web UI</TranslateComponent>,
      key: 'actions',
      disabled: true,
      alignRight: true
    },
    ...(showServiceToolsOptions
      ? [
          {
            name: 'serviceToolActions',
            title: <TranslateComponent>Service Tools</TranslateComponent>,
            key: 'serviceToolActions',
            disabled: true,
            alignRight: true
          }
        ]
      : [])
  ]

  const { refetch: refetchBuilding, responseTime: buildingResponseTime } =
    useQuery({
      query: SEARCH_BUILDINGS,
      dataPath: 'data.searchBuildings',
      disableInitialLoad: true
    })

  useEffect(() => {
    const getAllBaseData = async (data, filter) => {
      if (data.nextToken && data.items.length === 1000) {
        return await refetchBuilding({
          limit: 1000,
          nextToken: data.nextToken,
          filter: { and: [...filter, { isActive: { eq: 1 } }] }
        }).then(async (res) => {
          if (res.nextToken) {
            return [...(await getAllBaseData(res, filter)), ...data.items]
          } else {
            return [...data.items, ...res.items]
          }
        })
      } else {
        return [...(data?.items || [])]
      }
    }

    const loadData = async () => {
      if (salesOfficeId) {
        setIsLoading(true)
        await refetchBuilding({
          limit: 1000,
          filter: {
            and: [
              { salesOfficeId: { eq: salesOfficeId } },
              { isActive: { eq: 1 } }
            ]
          }
        }).then(async (res) => {
          const baseData = await getAllBaseData(res, [
            { salesOfficeId: { eq: salesOfficeId } }
          ])
          setBuildingData(baseData)
          setIsLoading(false)
        })
      } else if (buildingId && organizationId) {
        setIsLoading(true)
        refetchBuilding({
          limit: 1000,
          filter: {
            and: [{ id: { eq: buildingId } }, { isActive: { eq: 1 } }]
          }
        }).then(async (res) => {
          const baseData = await getAllBaseData(res, [
            { id: { eq: buildingId } }
          ])
          setBuildingData(baseData)
          setIsLoading(false)
        })
      } else if (organizationId && !buildingId) {
        setIsLoading(true)
        refetchBuilding({
          limit: 1000,
          filter: {
            and: [
              { accountId: { eq: organizationId } },
              { isActive: { eq: 1 } }
            ]
          }
        }).then(async (res) => {
          const baseData = await getAllBaseData(res, [
            { accountId: { eq: organizationId } }
          ])
          setBuildingData(baseData)
          setIsLoading(false)
        })
      }
    }
    loadData()
  }, [organizationId, buildingId, salesOfficeId, searchParams])

  const amplitudeTrackingForConnectivity = (device, connectionStatus) => {
    const failureDetails = {
      serial: device.serial,
      failureText: issueEncounteredError
    }
    if (device && device.type) {
      if (device.type === 'SCG'){
        if(connectionStatus){
          trackEvent(USER_EVENTS.REMOTE_ACCESS.events.VIEW_TRACER_SC_WEB_UI_CONNECTION_SUCCESS)
        } else {
          trackEvent(USER_EVENTS.REMOTE_ACCESS.events.VIEW_TRACER_SC_WEB_UI_CONNECTION_FAILURE, failureDetails)
        }
      } else {
        if(connectionStatus){
          trackEvent(USER_EVENTS.REMOTE_ACCESS.events.VIEW_TRACER_SERVICE_TOOL_CONNECTION_SUCCESS)
        } else {
          trackEvent(USER_EVENTS.REMOTE_ACCESS.events.VIEW_TRACER_SERVICE_TOOL_CONNECTION_FAILURE, failureDetails)
        }
      }
    }
  }

  const nonSecClick = async (d) => {
    setSelectedConnect(d.id)
    trackEvent(USER_EVENTS.REMOTE_ACCESS.events.CLICK_CONNECT_DEVICE)
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()
    await cognitoUser.refreshSession(
      currentSession['refreshToken'],
      async (err, session) => {
        const {
          idToken: { jwtToken },
          accessToken: { jwtToken: accessJWT }
        } = session
        return await fetch(remoteAccessURL, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `${accessJWT}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            // only SCG have -local, remove before dynamic proxy call
            deviceId: d.serial?.replace('-local', ''),
            deviceType: d.type,
            buildingId: d?.buildingId,
            clientType: 'DenaliTraneConnect',
            idToken: jwtToken
          })
        })
          .then(async (res) => {
            amplitudeTrackingForConnectivity(d, true)
            await res.text().then((res2) => {
              setSelectedConnect(null)
              if (d.type === 'ES') {
                window.open(`https://${res2}/TracerEnsemble/`, '_blank')
              } else {
                window.open(`https://${res2}`, '_blank')
              }
            })
          })
          .catch((e) => {
            setSelectedConnect(null)
            setError(true)
            amplitudeTrackingForConnectivity(d, false)
            console.log(e)
          })
      }
    )
  }

  const getOpenVPNStatus = (deviceDetails) => {
    try {
      // if the device has openVPN status, take that for button status
      // else fallback to remoteAccessStatus
      const openVPNStatus = deviceDetails?.remoteAccessConnections?.find(
        (x) => x?.typeOfConnection === 'openvpn'
      )
      if (openVPNStatus) {
        return openVPNStatus?.status || false
      } else {
        return deviceDetails?.remoteAccessStatus
      }
    } catch (e) {
      return deviceDetails?.remoteAccessStatus
    }
  }

  useEffect(() => {
    const runDataSetup = async () => {
      if (buildingData) {
        const data = buildingData?.reduce(
          (accum, curr) => [
            ...accum,
            ...curr.devices.items.map((d) => {
              const openVPNStatus =  getOpenVPNStatus(d)
              return {
                ...d,
                building: curr.name,
                address: curr.address,
                city: curr.city,
                state: curr.state,
                typeFilter: d.type,
                serial: d.serial.replace('-local', ''),
                type: deviceTypes[d.type] || d.type,
                availabilitySort: d.remoteAccessStatus
                  ? 'Available'
                  : 'Not Available',
                availability: d.remoteAccessStatus ? (
                  <div
                    style={{
                      width: 90,
                      justifyContent: 'center',
                      display: 'flex'
                    }}
                  >
                    <ConditionalTooltip
                      position="top"
                      multiline={true}
                      width="80px"
                      content={
                        <TranslateComponent>Available</TranslateComponent>
                      }
                    >
                      <AvailabilityIcon>
                        <IconSVG
                          key={`connectivity-icon-${d.id}`}
                          name="available"
                          color={colors.GREEN}
                          hover={colors.GREEN}
                          height="18px"
                          width="18px"
                        />
                      </AvailabilityIcon>
                    </ConditionalTooltip>
                  </div>
                ) : (
                  <div
                    style={{
                      width: 90,
                      justifyContent: 'center',
                      display: 'flex'
                    }}
                  >
                    <ConditionalTooltip
                      position="top"
                      multiline={true}
                      width="100px"
                      content={
                        <TranslateComponent>Not Available</TranslateComponent>
                      }
                    >
                      <AvailabilityIcon>
                        <IconSVG
                          key={`connectivity-icon-${d.id}`}
                          name={'unavailable'}
                          color={'rgb(85, 85, 85)'}
                          hover={'rgb(85, 85, 85)'}
                          height="18px"
                          width="18px"
                        />
                      </AvailabilityIcon>
                    </ConditionalTooltip>
                  </div>
                ),
                actions: (
                  <span>
                    {d.remoteAccessStatus ? (
                      d.type === 'SEC' ? (
                        <Button
                          type="primary"
                          disabled={true}
                          onClick={() => null}
                        >
                          <TranslateComponent>Mobile App</TranslateComponent>
                        </Button>
                      ) : (
                        <Button
                          key={`${d.id}-connect-button-${
                            selectedConnect === d.id ? 'active' : 'inactive'
                          }`}
                          type="primary"
                          onClick={() => nonSecClick(d)}
                        >
                          <TranslateComponent>
                            {selectedConnect === d.id
                              ? 'Connecting...'
                              : 'CONNECT'}
                          </TranslateComponent>
                        </Button>
                      )
                    ) : (
                      // We are yet to get requiremnet for Mobile App button click, it doesn't do anything as of now.
                      <Button
                        type="primary"
                        disabled={true}
                        onClick={() => null}
                      >
                        {d.type === 'SEC'
                          ? optionsTranslated.mobileApp
                          : optionsTranslated.connectButton}
                      </Button>
                    )}
                  </span>
                ),
                device: d,
                serviceToolActions: (
                  <span>
                    {openVPNAllowedTypes?.includes(d?.type) ? (
                      openVPNStatus ? (
                        <Button
                          type="primary"
                          onClick={() => {
                            serviceToolButtonHandler(d)
                            trackEvent(
                              USER_EVENTS.REMOTE_ACCESS.events
                                .CLICK_CONNECT_SERVICE_TOOLS
                            )
                          }}
                        >
                          {optionsTranslated.connectButton}
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          disabled={true}
                          onClick={() => {}}
                        >
                          {optionsTranslated.connectButton}
                        </Button>
                      )
                    ) : null}
                  </span>
                )
              }
            })
          ],
          []
        )

        const remoteAccessTypes = data?.filter(
          (x) => !remoteAccessNotAllowed.includes(x?.type)
        )

        setRows(remoteAccessTypes)
      }
    }
    runDataSetup()
  }, [buildingData, filters, selectedConnect, optionsTranslated.connectButton])

  const uiMode = useSelector(selectUiMode)
  if (uiMode === UiMode.denali) {
    return (
      <>
        <Page
          title={translate('Connect to a Device')}
          icon={<FontAwesomeIcon icon={faSignalStream} />}
          iconColor={IconColor.blue}
          testid="remote-access-page"
        >
          <DeviceConnectList
            isLoading={isLoading}
            rows={rows}
            optionsTranslated={optionsTranslated}
            deviceTypes={deviceTypes}
            selectedConnect={selectedConnect}
            showServiceToolsOptions={showServiceToolsOptions}
            openVPNAllowedTypes={openVPNAllowedTypes}
            serviceToolButtonHandler={serviceToolButtonHandler}
            nonSecClick={nonSecClick}
          />
        </Page>
        {isShowOpenVPNModal && (
          <OpenVPNModals
            connectivityDetails={openVPNDeviceDetails}
            toggleOpenVPNModal={toggleOpenVPNModal}
            connectedTimestamp={connectedTimestamp}
            deviceConnectionStatus={deviceConnectionStatus}
            downloadInstallerHandler={downloadInstallerHandler}
            loading={openVPNAPILoading}
            refreshOpenVPNButtonClick={refreshOpenVPNButtonClick}
            disconnectDevice={disconnectDevice}
          />
        )}
        <Dialog
          isOpen={error}
          onClose={() => setError(false)}
          title={optionsTranslated.errorModalHeading}
        >
          <TranslateComponent>
            {issueEncounteredError}
          </TranslateComponent>
        </Dialog>
      </>
    )
  }

  return (
    <NewStyles data-testid="remote-access-page">
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        padding="0"
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <PageHeader
            pageTitle={
              <TranslateComponent>Connect to a Device</TranslateComponent>
            }
          />
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {error ? (
            <Modal
              heading={optionsTranslated.errorModalHeading}
              handleClose={() => {
                setError(false)
              }}
              buttons={[
                {
                  variant: 'cancel',
                  text: <TranslateComponent>Close</TranslateComponent>,
                  handleClick: () => {
                    setError(false)
                  }
                }
              ]}
              isAlert={true}
            >
              <p data-testid="remote-access-page-error">
                <TranslateComponent>
                  We've encountered an error connecting to the selected device.
                  Please contact support if the issue persists.
                </TranslateComponent>
              </p>
            </Modal>
          ) : (
            <></>
          )}
          <Table
            testName="remote-access-page-device-list"
            key={`locationDevicesTable-${buildingId}`}
            rows={rows}
            header={nameHeadings}
            loadTime={buildingResponseTime}
            search={true}
            searchFields={['name', 'building', 'address', 'serial', 'state']}
            filtersList={filters}
            isTranslateFilters={false}
            showSpinner={isLoading}
          />
        </Content>
      </Container>
      {isShowOpenVPNModal && (
        <OpenVPNModals
          connectivityDetails={openVPNDeviceDetails}
          toggleOpenVPNModal={toggleOpenVPNModal}
          connectedTimestamp={connectedTimestamp}
          deviceConnectionStatus={deviceConnectionStatus}
          downloadInstallerHandler={downloadInstallerHandler}
          loading={openVPNAPILoading}
          refreshOpenVPNButtonClick={refreshOpenVPNButtonClick}
          disconnectDevice={disconnectDevice}
        />
      )}
    </NewStyles>
  )
}
