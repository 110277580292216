export const listBaselinesByAccount = /* GraphQL */ `
  query listBaselinesByAccount($accountId: ID!) {
    listBaselinesByAccount(accountId: $accountId, limit: 1000) {
      items {
        description
        name
        id
        startPeriod
        target
        updatedAt
        equipmentId
        buildingId
        exclusions {
          name
          startPeriod
          endPeriod
        }
        endPeriod
        savings
        baselineEquipment {
          family
          name
          type
          building {
            accountName
            name
            id
            accountId
          }
        }   
      }
    }
  }
`

export const listBaselinesByBuilding = /* GraphQL */ `
  query listBaselinesByBuilding($buildingId: ID!) {
    listBaselinesByBuilding(buildingId: $buildingId, limit: 1000) {
      items {
        description
        name
        id
        startPeriod
        target
        updatedAt
        equipmentId
        buildingId
        exclusions {
          name
          startPeriod
          endPeriod
        }
        endPeriod
        savings
        baselineEquipment {
          family
          name
          type
          building {
            accountName
            name
            id
            accountId
          }
        }        
      }
    }
  }
`

export const DELETE_BASELINE = /* GraphQL */ `
  mutation deleteBaseline($input: DeleteBaselineInput!) {
    deleteBaseline(input: $input) {
      id
    }
  }
`

export const CREATE_BASELINE = `
        mutation createBaseline($input: CreateBaselineInput!) {
          createBaseline(input: $input) {
            id
          }
        }
`

export const UPDATE_BASELINE = `
        mutation updateBaseline($input: UpdateBaselineInput!) {
          updateBaseline(input: $input) {
            id
          }
        }
`


