import React from 'react'
import * as d3  from "d3";
import moment from 'moment';
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'
import store from 'src/redux/store';
function AreaChart({ data, measurement, area, enableTooltip, setTooltip, xScale, yScale }) {  
  const areaColor = "#fff787"
  const lDateFormat = getDateFormatByLocale("MM/DD")
  const profileSettings = store?.getState().appData?.userInfo?.data?.profileSettings;
  const UOMbyProfile = profileSettings ? JSON.parse(profileSettings)?.unitOfMeasurement === "si" ? "SI" : "IP" : "IP"
  const UnitSymbol = UOMbyProfile === "SI" ? '°C' : '°F'
  return (
    <>
      <path fill={areaColor} d={area}  onMouseEnter={(event) => {
             const pointers = d3?.pointer(event) ?? [0, 0]
             let dateValue = null
             let toolTipValue = ""
             let isInRange = false
             data.forEach(element => {
              const point1 = yScale(element?.values[0])
              const point2 = yScale(element?.values[1])
              if(Number(point1) <= pointers[1] && Number(point2) >= pointers[1]){
                dateValue = element?.tempDate ? moment(element?.tempDate)?.format(lDateFormat ? lDateFormat : "MM/DD") : ''
                toolTipValue = `${element?.values[0]}${UnitSymbol} / ${element?.values[1]}${UnitSymbol}`
                isInRange = true
              }
             });
              if (!enableTooltip || !isInRange) return
              setTooltip({
                x: event?.clientX,
                y: event?.clientY,
                index: 1,
                item: {
                  name: `Temperature, ${dateValue}`,
                  value: toolTipValue,
                  color: areaColor,
                  measurement,
                  isPlot: true
                }
              })
            }}
            onMouseLeave={() => {
              if (!enableTooltip) return
              setTooltip(null)
            }}/>
    </>
  )
}

export default AreaChart