export const LIST_EVENT_MARKERS_BY_ACCOUNT = /* GraphQL */ `
  query listEventMarkersByAccount(
    $accountId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventMarkerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventMarkersByAccount(
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        markerDate
        buildingIds
      }
      nextToken
    }
  }
`

export const GET_BUILDINGS_BY_ACCOUNT_ID = `query GET_BUILDINGS_BY_ACCOUNT_ID($id: ID!) {
  listBuildingsByAccount(accountId: $id, limit: 10000) {
    items {
      id
      name
      }
    }
  }
`

export const CREATE_EVENT_MARKER = /* GraphQL */ `
  mutation createEventMarker($input: CreateEventMarkerInput!) {
    createEventMarker(input: $input) {
      id
    }
  }
`

export const UPDATE_EVENT_MARKER = /* GraphQL */ `
  mutation updateEventMarker($input: UpdateEventMarkerInput!) {
    updateEventMarker(input: $input) {
      id
    }
  }
`

export const DELETE_EVENT_MARKER = /* GraphQL */ `
  mutation deleteEventMarker($input: DeleteEventMarkerInput!) {
    deleteEventMarker(input: $input) {
      id
    }
  }
`
