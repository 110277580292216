import { select } from 'd3'
import Base from './Base'

const error_msg_no_root_selector =
  'Unable to create a svg. please provide the root element selecot / DOM refernce'

export class BaseSvg extends Base {
  svgContainer: any

  get svgSize(): SvgSize {
    return this.calculateSvgSize()
  }

  constructor({ root }: BaseSvgConstructor) {
    super()

    if (!root) {
      throw new Error(error_msg_no_root_selector)
    } else {
      this.svgContainer = root
    }
  }

  private calculateSvgSize(): SvgSize {
    const svg: any = select(this.svgContainer).select('svg').node()

    const isSvgInDOM = !!svg

    const clientRect = isSvgInDOM ? svg.getBoundingClientRect() : {}

    return {
      width: clientRect.width || 0,
      height: clientRect.height || 0
    }
  }
}
