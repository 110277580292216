export const NOTIFCATION_STATUS = {
  ALL: 'all',
  NEW: 'new',
  READ: 'read',
  CLOSED: 'closed'
}

export const NOTIFCATION_STATUS_DESC = {
  all: 'All',
  new: 'Unread',
  read: 'Read',
  closed: 'Closed'
}

export const NOTIFICATION_SUB_TYPE = {
  ISSUE: 'issue',
  DATA_LOSS: 'data loss',
  OPPORTUNITY: 'opportunity',
  OFFERING_REQUEST: 'offering_request'
}
