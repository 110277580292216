import _get from "lodash/get"
import { FIELD_TYPES } from "src/components/legacy/common/pdf"
import PropTypes from "prop-types"
import { StyleSheet, Text, View } from "@react-pdf/renderer"
import {
	formatNextStep,
	formatStatus
} from "src/components/legacy/common/opportunity.js"
import { formatCurrency } from "src/components/legacy/common/currency"
import { Trans, withTranslation } from "react-i18next"
import PDFTable from "src/components/print-components/pdf-table"
import PDFHeader from "src/components/print-components/pdf-header"
import PDFTitle from "src/components/print-components/pdf-header/pdf-title"
import PDFFooter from "src/components/print-components/pdf-footer"
import colors from "src/components/legacy/common/colors.json"
import _minBy from "lodash/minBy"
import _maxBy from "lodash/maxBy"
import { DATE_FORMAT } from "src/components/legacy/common/time-helpers"
import { KPI_SETTINGS } from "src/components/legacy/components/kpi/kpi"
import { getKPI } from "../../helpers"
import moment from "moment"

export const opportunityStyles = StyleSheet.create({
	kpiWrapper: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	kpiValue: {
		fontWeight: "bold",
		color: colors.BLACK
	},
	additional: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		padding: 8,
		margin: 5,
		width: "85%",
		backgroundColor: colors.WHITE,
		border: `1 solid ${colors.TBA_DARK_GREY}`,
		borderBottom: `4 solid ${colors.TBA_DARK_GREY}`,
	},
	additionalValue: {
		fontWeight: "bold",
		color: colors.BLACK
	},
	name: {
		fontWeight: "bold",
		color: colors.TBA_DARK_GREY
	},
    equipment: {
		fontStyle: "italic"
	},
	price: {
		fontWeight: "bold",
		color: colors.BLACK
	},
	saving: {
		fontWeight: "bold",
		color: colors.BLACK
	},
	totalEquipment: {
		paddingTop: 10,
		fontStyle: "italic"
	},
	dateWrapper: {
		fontSize: 10,
		color: colors.TBA_DARK_GREY,
		textAlign: "right",
		fontWeight: "bold"
	}
})

export const headings = t => [
	{
		heading: "opportunities:Opportunity",
		field: "title",
		width: "20%",
		fieldType: FIELD_TYPES.COMPONENT,
		component: (heading, column) => {
			const fieldValue = _get(column, heading.field, "")

			return <Text style={opportunityStyles.name}>{fieldValue}</Text>
		}
	},
	{
		heading: "opportunities:Date",
		field: "creationDate",
		width: "10%",
	},
	{
		heading: "opportunities:PotentialSavings",
		field: "energySavings",
		width: "9%",
		fieldType: FIELD_TYPES.COMPONENT,
		component: (heading, column) => {
			const fieldValue = _get(column, heading.field, "")
			const value = fieldValue != null && formatCurrency(fieldValue)
			return <Text style={opportunityStyles.saving}>{value}</Text>
		}
	},
	{
		heading: "opportunities:Price",
		field: "price",
		width: "10%",
		fieldType: FIELD_TYPES.COMPONENT,
		component: (heading, column) => {
			const fieldValue = _get(column, heading.field, null)
			const showCustomerPrice = _get(column, "priceDisplay", false)
			const value = !showCustomerPrice ? <Trans i18nKey={"opportunities:NotEstimated"} /> : formatCurrency(fieldValue)

			return <Text style={opportunityStyles.price}>{value}</Text>
		}
	},
	{
		heading: "opportunities:KeyMetrics",
		width: "16%",
		fieldType: FIELD_TYPES.COMPONENT,
		component: (heading, column) => {
			const kpiDetails = getKPI(column) ?? []			
			const kpiSettings = KPI_SETTINGS(t)

			return kpiDetails?.filter(f => !f?.custom).map((item, index) => {
				const { name, priority, value } = item
				return <>
					{value && <View style={opportunityStyles.kpiWrapper} key={index}>
						<Text>{kpiSettings.has(name) ? kpiSettings.get(name).name : name}: </Text>
						<Text style={opportunityStyles.kpiValue}>{priority}</Text>
					</View>}
				</>
			})
		}
	},
	{
		heading: "opportunities:Building",
		width: "16%",
		fieldType: FIELD_TYPES.COMPONENT,
        component: (headings, columns) => {
            const tisObjects = columns?.equipments?.items?.map(e => e?.equipment) ?? []
            const { name, postalCode, address, address2 } = columns?.building ?? {}
            return <View>
                <Text>{name} {(address || address2) && `${postalCode??''} ${address ?? ''} ${address2 ?? ''}`}</Text>
                {tisObjects?.slice(0, 3)?.map(({ type, name }, index) => <Text style={opportunityStyles.equipment} key={index}>{`${type} > ${name}`}</Text>)}
                {tisObjects?.length > 3 && <Text style={opportunityStyles.totalEquipment}>({tisObjects.length}) {t("common:TotalPiecesOfEquip")}</Text>}
            </View>
        }
	},
	{
		heading: "",
		width: "19%",
		hideHeading: true,
		fieldType: FIELD_TYPES.COMPONENT,
		component: (heading, column) => {
			const status = _get(column, "status", false)
			const nextStep = _get(column, "nextStep", false)
			const priority = _get(column, "priority", false)
			const assignedTo = _get(column, "assignedTo", false)

			return <>
				{(!status && !nextStep && !priority && !assignedTo) ? <></> :
					<View style={opportunityStyles.additional}>
						{priority && <View>
							<Text>{t("opportunities:Priority")}:</Text>
							<Text style={opportunityStyles.additionalValue}>{priority}</Text>
						</View>}
						{formatStatus(status) && <View>
							<Text>{t("opportunities:Status")}:</Text>
							<Text style={opportunityStyles.additionalValue}>{formatStatus(status)}</Text>
						</View>}
						{formatNextStep(nextStep) && <View>
							<Text>{t("opportunities:NextStep")}:</Text>
							<Text style={opportunityStyles.additionalValue}>{formatNextStep(nextStep)}</Text>
						</View>}
						{assignedTo && <View>
							<Text>{t("opportunities:AssignedTo")}:</Text>
							<Text style={opportunityStyles.additionalValue}>{assignedTo}</Text>
						</View>}
					</View>
				}
			</>
		}
	},
]

const PdfOpportunityList = ({ headerLocation, headerOrganization, isFooterCount, columns, sortedBy, filteredBy, showNotes, t }) => <>
	<PDFHeader
		isFixed={true}
		locationNames={[headerLocation]}
		organizationName={headerOrganization}
	/>
	<Text fixed={true} style={opportunityStyles.dateWrapper}>
			<Text>{`${moment(_minBy(columns, "creationDate")?.creationDate).format(DATE_FORMAT)} - ${moment(_maxBy(columns, "creationDate")?.creationDate).format(DATE_FORMAT)}`}</Text>
		</Text>
	<PDFTitle
		pageTitle={"Opportunities List"}
	/>
	<PDFFooter isCountPage={isFooterCount} />
    <PDFTable columns={columns}
        headings={headings(t)}
        sortedBy={sortedBy}
        filteredBy={filteredBy}
        showNotes={showNotes}
        withTranslation={true}
    />
</>

PdfOpportunityList.propTypes = {
	headerLocation: PropTypes.string.isRequired,
	isFooterCount: PropTypes.bool,
	columns: PropTypes.array.isRequired,
	sortedBy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
	filteredBy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
	showNotes: PropTypes.bool.isRequired,
}

export default withTranslation()(PdfOpportunityList)
