import { useFormikContext } from '../MultiTabTorm'
import ColorInput from 'src/components/color-input/ColorInput'
import { TabWrapper } from '../../styles'
import { OVERLAY_TIME_PERIOD_PREVIOUS } from 'src/common/overlay-time-period'
import translate from 'src/common/translations'


const TrendLineChartColors = () => {
  const formik = useFormikContext()
  const { values, setFieldValue } = formik
  const handleSelectColorPicker = (fieldName: string, color: any) => {
    setFieldValue(fieldName, color)
  }

  return (
    <TabWrapper>
       <ColorInput
            label={"Line Color"}
            isRequired={true}
            backgroundColor={values?.lineColor}
            update={(color)=> handleSelectColorPicker('lineColor', color)}
        />
        {
          values?.comparePointTo === OVERLAY_TIME_PERIOD_PREVIOUS && <ColorInput
            label={`Overlay Line Color`}
            isRequired={true}
            backgroundColor={values?.overlayLineColor}
            update={(color)=> handleSelectColorPicker('overlayLineColor', color)}
          />
        }
    </TabWrapper>
  )
}

export default TrendLineChartColors
