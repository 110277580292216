/* eslint-disable jsx-a11y/click-events-have-key-events */
import Spinner from 'src/components/legacy/components/spinner/spinner'
import { withTranslation } from 'react-i18next'
import IconSVG from 'src/components/Icon'
import COLORS from 'src/components/legacy/common/colors.json'
import translate, { TranslateComponent } from 'src/common/translations'

type Iheader = {
  loading: boolean
  label: string
  onClick: () => void
}
function Header({ loading, label, onClick }: Iheader) {
  return (
    <div className="bn-header" onClick={onClick} data-test="buildingSelector" data-loading={loading ? 'true' : 'false'}>
      <div className="label">{label}</div>
      <div className="arrow">
        {loading ? <Spinner /> : <i className="icon icon-expand" />}
      </div>
    </div>
  )
}

// const BuildingTotalLabel = styled.span`
// 	float: right;
// `
export const selectLabel = (selected, t, /* missingRecent, loading*/) => {
  if (!selected) return <></>
  const allSelected = selected?.id === 'all'
  if (selected?.type === 'select') {
    const title = `- ${t('common:Select')} -`
    return (
      <div title={title}>
        <div className="title-block empty">
          <div>{title}</div>
        </div>
      </div>
    )
  }

  const title = allSelected ? t(selected?.primary) : selected?.primary
  let subtitle = selected?.subtitle || selected?.secondary
  if (allSelected) {
    subtitle = subtitle
  }

  return (
    <div title={title}>
      {selected?.icon !== 'virtual-meter' ? (
        <i className={`icon icon-${selected?.icon}`} />
      ) : (
        <IconSVG
          name={selected.icon}
          color={COLORS.BLACK}
          hover={COLORS.BLACK}
          viewBox="0 0.5 12 12"
          height="16px"
          width="16px"
          margin="0px 5px 0px 0px"
        />
      )}
      <div className="title-block">
        {/* {buildingMeterTotalId && selected.id === buildingMeterTotalId
					? <>
						<BuildingTotalLabel> (building total)</BuildingTotalLabel>
						<div>*{title}</div>
					</>
					: <div>{title}</div>
				} */}
        {/* {title || subtitle ? <> */}
        <div>{title}</div>
        <div className="subtitle">{subtitle?.includes("Organization") ? <TranslateComponent>{subtitle}</TranslateComponent>: subtitle}</div>
        {/* </> : missingRecent && !loading ? "Select a building or account." : ""} */}
      </div>
    </div>
  )
}

export default withTranslation()(Header)
