import { useMemo } from 'react'
import { useFormikContext } from '../../MultiTabTorm'
import { useWidgetsContext } from '../../../WidgetsContextProvider'
import { EnergyConsumptionWidgetPreview } from 'src/pages/dashboards/components/DashboardCardWidget/EnergyConsumptionWidgetPreview'
import { WidgetPreviewWrapper } from '../../widgetPreviewWrapper'
import { WidgetChartContainer } from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import { WidgetNames } from '../../../helpers'

export const EnergyConsumptionWidgetPreviewWrapper = () => {
  const formik = useFormikContext()
  const { values } = formik
  const { objectPickerSelectedValue, formikErrors, allDimensions } = useWidgetsContext()

  const energyConsumptionPropertyName =
  objectPickerSelectedValue?.[0]?.equipmentType === 'VirtualMeter'
      ? 'TotalConsumption'
      : 'ConsumptionInterval'

  const comparedTo =
    values?.comparedTo === ':none'
      ? values?.comparedTo
      : values?.comparedTo?.split('/')?.[1]

  const getPreviewDetails = useMemo(
    () => ({
      widgetName: values?.name,
      buildingId: values?.widgetBuilding,
      equipmentId: objectPickerSelectedValue?.[0]?.equipmentId,
      property: energyConsumptionPropertyName,
      selectedTimePeriod: values?.timePeriod?.split('/')?.[1] || '',
      equipmentType: objectPickerSelectedValue?.[0].equipmentType,
      unitsOfMeasure: values?.unitsOfMeasure, 
      symbol:
      allDimensions?.find(
        (x) => x?.UoM?.toLowerCase() === values?.unitsOfMeasure?.toLowerCase() || 
        x?.Name?.toLowerCase() === values?.unitsOfMeasure?.toLowerCase()
      )?.Symbol || '',
      selectedAggrValue: 'sum',
      buildingName: objectPickerSelectedValue?.[0].buildingName,
      equipmentName: objectPickerSelectedValue?.[0].equipmentName,
      accountName: objectPickerSelectedValue?.[0].accountName,
      toDateBarColor: values?.toDateBarColor,
      leftBarColor: values?.leftBarColor,
      rightBarColor: values?.rightBarColor,
      benchMarker: values?.benchMarker?.split('/')?.[1] || '',
      benchmarkColor: values?.benchmarkColor,
      benchMarkerLabel: values?.benchMarkerLabel ?? '',
      benchMarkerValue: values?.benchMarkerValue ?? '',
      isShowTemperature: values?.isShowTemperature,
      comparedTo: comparedTo || ':none',
      widgetDescription: values?.description ?? '',
      timeZone: objectPickerSelectedValue?.[0]?.timeZone,
    }),
    [values, objectPickerSelectedValue]
  )

  return (
    <WidgetPreviewWrapper
      widgetName={values?.name}
      error={Object.keys(formikErrors)?.length > 0}
      chartType="time-comparision-bar-chart"
    >
      <WidgetChartContainer
        widgetType={WidgetNames?.EnergyConsumption}
        showLegends={getPreviewDetails?.comparedTo !== ':none'}
      >
        <EnergyConsumptionWidgetPreview widgetDetails={getPreviewDetails} />
      </WidgetChartContainer>
    </WidgetPreviewWrapper>
  )
}
