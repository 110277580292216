import { createContext, useContext } from 'react'

export const FormControlContext = createContext()

export const useFormControl = () => {
  return useContext(FormControlContext)
}

export const useConnectPropsWithContext = (inProps = null) => {
  const contextProps = useFormControl()

  return { ...inProps, ...contextProps }
}
