import clsx from 'clsx'
import * as d3 from 'd3'

type IsChild = {
  isChild?: boolean
}

export function TooltipTemplate({
  data: tooltips = []
}: TooltipTemplateCompProps): any {
  return tooltips.map((tooltip, index) => {
    const isGroup = tooltip.group || false
    const items = tooltip.items || []

    return (
      <div key={index} className="tt-group">
        {isGroup && (
          <TooltipItem
            key={(tooltip.groupDetail || {}).content + '-' + index}
            {...tooltip.groupDetail}
          />
        )}
        {items.length &&
          items.map((item, index) => (
            <TooltipItem key={item.content + '-' + index} {...item} />
          ))}
      </div>
    )
  })
}

function TooltipItem(props: TooltipDataItem & IsChild) {
  const { content, icon, iconOptions, isChild } = props

  return (
    <div className={clsx('tt-item', { 'tt-child': isChild })}>
      {icon && <TooltipIcon {...iconOptions} />}
      {content && <TooltipContent {...props} />}
    </div>
  )
}

function TooltipIcon(iconOptions: TooltipDataItem['iconOptions']) {
  const {
    color,
    size = 64,
    svgProps,
    d3Symbol = 'symbolCircle',
    ...pathOptions
  } = iconOptions || {}

  const d = d3
    .symbol()
    .type((d3 as any)[d3Symbol])
    .size(size)()
  return (
    <svg
      width={10}
      height={10}
      {...svgProps}
      className={clsx('tt-icon-svg', svgProps?.className)}
    >
      <path
        transform="translate(5 5)"
        width="100%"
        height="100%"
        d={d || ''}
        fill={color}
        {...pathOptions}
      />
    </svg>
  )
}

function TooltipContent(props: TooltipDataItem) {
  const { content, contentOptions } = props

  const { color = '#fff', size = 12, style, ...rest } = contentOptions || {}
  return (
    <div
      {...rest}
      style={{ color: color, fontSize: size, ...style }}
      className={clsx('tt-content-text', rest?.className)}
    >
      {content}
    </div>
  )
}
