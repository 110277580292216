import { sum } from 'ramda'
import { getNumberIfUndefined, newDateString } from '../../helper'
import { updateAfterPadding } from '../Padding/update'
import { updateScale } from '../Scale/update'
import { isNumber } from 'lodash'
import { getPreviousOccupiedSpace } from './helper'

// Update Method
// Canvas Dimensions change Detected - Will update State
export const canvasChangeUpdateScale = (setState) => (config) => {
  setState((state) => {
    let newState = {
      ...state
    }
    const pCanvasDetails = JSON.parse(
      JSON.stringify(state?.canvasDetails || {})
    )
    const newStateUpdated = updateCanvasDetails(newState, config) || {}
    newState = {
      ...newStateUpdated
    }
    const cCanvasDetails = newState?.canvasDetails || {}

    const isCanvasDimensionsChanged = Object.keys(cCanvasDetails || {})?.some(
      (canvasIndex: any) => {
        const current = cCanvasDetails?.[canvasIndex]
        const previous = pCanvasDetails?.[canvasIndex]
        return (
          current?.width !== previous?.width ||
          current?.height !== previous?.height
        )
      }
    )

    if (isCanvasDimensionsChanged) {
      const axes: any = {}

      state?.axes?.forEach((obj: any) => {
        axes[obj?.key] = obj
      })
      const scaleState = updateScale(newState, axes, cCanvasDetails, false)
      newState = {
        ...newState,
        ...scaleState
      }

      const updatedStateAfterPadding = updateAfterPadding(newState, config)

      newState = {
        ...updatedStateAfterPadding
      }
    }

    return newState
  })
}

// Update Method
export const updateCanvasDetails = (
  state: any = {},
  payload: any = {},
  paddingDetails: any = null
) => {
  const givenPaddingDetails = paddingDetails
    ? paddingDetails
    : state?.canvasDetails || {}
  const { canvas = {} } = payload
  const { type = '', share, spaceInBetween = [], svgPadding = {} } = canvas
  const canvasDetails: any = {}

  const spaceInBetweenAll = sum(spaceInBetween)
  const svgHeight = state.svgDimensions?.height
  const svgWidth = state.svgDimensions?.width

  if (type === 'column' || type === '') {
    const defaultShare = [100]
    const adjustedShare = share?.length > 1 ? share : defaultShare

    const availableHeightForAllCanvas =
      svgHeight -
      spaceInBetweenAll -
      getNumberIfUndefined(svgPadding?.top) -
      getNumberIfUndefined(svgPadding?.bottom)
    const availableWidthForAllCanvas =
      svgWidth -
      getNumberIfUndefined(svgPadding?.left) -
      getNumberIfUndefined(svgPadding?.right)

    adjustedShare?.forEach((percentage: string | number, index: number) => {
      const adjustedPercent = isNumber(percentage) ? Number(percentage) : 100
      const availableHeight =
        (adjustedPercent / 100) * availableHeightForAllCanvas
      const availableWidth = availableWidthForAllCanvas
      const transformX =
        getNumberIfUndefined(givenPaddingDetails?.[index]?.left) +
        getNumberIfUndefined(svgPadding?.left)
      const transformY = getPreviousOccupiedSpace(
        canvasDetails,
        spaceInBetween,
        svgPadding,
        givenPaddingDetails,
        index
      )
      canvasDetails[`${index}`] = {
        availableHeight,
        availableWidth,
        height:
          availableHeight -
          getNumberIfUndefined(givenPaddingDetails?.[index]?.top) -
          getNumberIfUndefined(givenPaddingDetails?.[index]?.bottom),
        width:
          availableWidth -
          getNumberIfUndefined(givenPaddingDetails?.[index]?.left) -
          getNumberIfUndefined(givenPaddingDetails?.[index]?.right),
        left: givenPaddingDetails?.[index]?.left,
        right: givenPaddingDetails?.[index]?.right,
        top: givenPaddingDetails?.[index]?.top,
        bottom: givenPaddingDetails?.[index]?.bottom,
        transformX,
        transformY
      }
    })
  }
  return {
    ...state,
    [`canvasT`]: newDateString(),
    [`canvasDetailsT`]: newDateString(),
    canvas: canvas,
    canvasDetails: canvasDetails
  }
}
