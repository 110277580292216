import { useMemo, useRef, useState } from 'react'
import { clipUUID } from '../../../chart-library/CommonComponents/Clip'
import { Tooltip } from '../../../chart-library/CommonComponents/Tooltip'
import BaseWrapper from '../../../chart-library/CommonComponents/BaseWrapper'
import Svg from '../../../chart-library/CommonComponents/Svg'
import TooltipRenderer from './tooltipTemplate'
import { useOptions } from './useOptions'
import Canvas from './Canvas'

import './styles.scss'
import {
  Legend,
  LegendProvider
} from '../../../chart-library/CommonComponents/Legend'
import Timeline from './Timeline'
import { data as mockData, xAxisMockData } from './mockData'

const xAxisData = [
  'Jan 2023',
  'Feb 2023',
  'March 2023',
  'April 2023',
  'May 2023',
  'June 2023'
]

export default function EngeryCostSummaryChart({
  startTime,
  endTime,
  data = [],
  axisData = xAxisData,
  yAxisMin = 0,
  yAxisMax = 6000,
  getSelectedItems
}) {
  const eventName = useMemo(
    () => clipUUID('engery-cost-summary-stacked-bar'),
    []
  )

  // Required to set below config when displaying timeline
  const [series, setSeries] = useState<{ data: any[]; categories: any[] }>({
    data,
    categories: axisData
  })

  const options = useOptions(series.categories, yAxisMin, yAxisMax)

  // const options = useOptions(axisData, yAxisMin, yAxisMax)

  // provide boundary of tooltip to position properly
  const svgRef: any = useRef(null)

  return (
    <BaseWrapper
      testName="energy-cost-summary-chart"
      options={options}
      className="engery-cost-summary-chart"
    >
      <div className="ecs-x-label-block">
        <div className="ecs-x-label">
          <b>Monthly use </b> <small>(Dollars)</small>
        </div>
      </div>
      <LegendProvider getSelectedItems={getSelectedItems}>
        <Svg ref={svgRef}>
          <Canvas tooltipEventName={eventName} series={series.data} />
          <Timeline
            startTime={startTime}
            endTime={endTime}
            series={data}
            categories={axisData}
            yAxisMin={yAxisMin}
            yAxisMax={yAxisMax}
            getSeriesDataInSelectedRnage={setSeries}
            className={'energy-cost-summary-time-slider'}
          />
        </Svg>
        <Tooltip
          tooltipClassName="ecs-tooltip-bg"
          listenEventName={eventName}
          boundary={svgRef}
          TooltipRenderer={TooltipRenderer}
        />
        <Legend className="engery-cost-summary-chart-legend" />
      </LegendProvider>
    </BaseWrapper>
  )
}
