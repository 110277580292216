import { StyledSpinner, StyledDiv } from './style'
import { useTheme } from '@aws-amplify/ui-react'

const Spinner = ({ size = 'large', page = false, ...props }) => {
  const { tokens } = useTheme()
  return page ? (
    <StyledDiv>
      <StyledSpinner page size={size} colors={tokens.colors} />
    </StyledDiv>
  ) : (
    <StyledSpinner size={size} colors={tokens.colors} {...props} />
  )
}

export default Spinner
