import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { NotifyContentWrapper } from '../../styles'
export const NotifyContent = ({
  name,
  entity,
  defaultValue = '',
  onChangeMessage
}) => {
  const [t] = useTranslation()

  return (
    <NotifyContentWrapper>
      <div className="notify-content">
        <p>{t('notifications:SendNotificationTo', { entity, name })}</p>
        <label>{t('common:Message')}</label>
        <textarea onChange={onChangeMessage} defaultValue={defaultValue} />
      </div>
    </NotifyContentWrapper>
  )
}

NotifyContent.propTypes = {
  name: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChangeMessage: PropTypes.func.isRequired
}

export default NotifyContent
