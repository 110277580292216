
import colors from 'src/components/layouts/colors.json'

export const BASE_COLOR = colors.TBA_GREEN

export const THRESHOLD1_COLOR = colors.YELLOW
export const THRESHOLD2_COLOR = colors.RED

export const LINE1_COLOR = colors.BLUE
export const LINE2_COLOR = colors.PURPLE

export const BAR1_COLOR = colors.BLUE
export const BAR2_COLOR = colors.PURPLE
export const BAR2_TODATE_COLOR = colors.PURPLE_3
export const BAR3_COLOR = colors.GREY_2
export const BAR3_TO_DATE_COLOR = colors.TBA_LIGHTER_GREY
export const ELECTRIC_CONSUMPTION_COLOR = colors.ORANGE
export const ELECTRIC_DEMAND_COLOR = colors.BLUE
export const ELECTRIC_FEE_COLOR = colors.GREEN
export const GAS_CONSUMPTION_COLOR = colors.YELLOW
export const STRATIFICATION_BENCHMARK_COLOR = colors.BLUE_6

export const TARGET_COLOR = colors.ORANGE

export const HIGH_EFFICIENCY_COLOR = colors.GREEN
export const MEDIUM_EFFICIENCY_COLOR = colors.YELLOW
export const LOW_EFFICIENCY_COLOR = colors.RED

export const AREA_RANGE_COLOR = colors.BLUE
export const AREA_RANGE_PEAK_COLOR = colors.ORANGE
export const AREA_RATE_INCREASES_COLOR = colors.GREY_2

export const RATCHET_DEMAND_COLOR = colors.RED_2
