import initMergeThemeFn, { mergeSize } from '../common/themeMapper'
// ---------------------------------------------- Default Button Styles ----------------------------------------------

export const defaultStyle = {
  size: {
    small: {
      borderRadius: '50%',
      fontSize: 10,
      padding: 5
    },
    medium: {
      borderRadius: '50%',
      fontSize: 12,
      padding: 7
    },
    large: {
      borderRadius: '50%',
      fontSize: 16,
      padding: 10
    }
  }
}

// ---------------------------------------------- Theme Mapper ----------------------------------------------

export const mergeTheme = initMergeThemeFn({
  defaultStyle,
  propResolver: {
    default: mergeSize
  }
})
