import { scaleLinear, symbol, symbolTriangle } from 'd3'
import { useMemo } from 'react'
import { clipUUID, GroupAndClip } from '../Clip'
import Axis from '../AxisNative'
import Grid from '../Grids/Grid'

interface VisualLegendCompProps {
  x?: number
  y?: number
  width?: number
  height?: number
  colors?: string[]
  min?: number
  max?: number
  xOffset?: number
  yOffset?: number
  gridProps?: any
  axisProps?: Omit<
    AxisComponentProps,
    'axisScale' | 'axisPosition' | 'dimention' | 'lastUpdatedAt'
  >
  rangeStart?: number
  rangeEnd?: number
  indicatorValue?: number | undefined | null
  indicatorProps?: {
    size?: number
    symbolFn?: any
    symbolPathStr?: string
    rotate?: string
    [k: string]: any
  }
}

function configByOrientation(isVertical) {
  if (isVertical) {
    return {
      axisPosition: 'axisLeft',
      dimention: 'y',
      xPosition: 0,
      yPosition: 0
    }
  }

  return {
    axisPosition: 'axisBottom',
    dimention: 'x',
    xPosition: 0,
    yPosition: 0
  }
}

export default function VisualLegend({
  x = 0,
  y = 0,
  width = 100,
  height = 15,
  colors,
  min = 0,
  max = 0,
  xOffset = 10,
  yOffset = 10,
  gridProps = {},
  axisProps = {},
  rangeStart = 0,
  rangeEnd,
  indicatorValue,
  indicatorProps
}: VisualLegendCompProps) {
  const length = colors.length - 1

  const { scale, lastUpdatedAt } = useMemo(() => {
    return {
      scale: scaleLinear([min, max], [rangeStart, rangeEnd ?? width]),
      lastUpdatedAt: +new Date()
    }
  }, [min, max, rangeStart, rangeEnd, width])

  const {
    size = 25,
    symbolFn = symbolTriangle,
    symbolPathStr,
    rotate = '180deg',
    ...rest
  } = indicatorProps || {}

  const lGradientId = useMemo(() => clipUUID('visual-legend'), [])

  const symbolPath = symbolPathStr || symbol(symbolFn).size(size)()

  const value = typeof indicatorValue === 'number' ? indicatorValue : null

  return (
    <GroupAndClip
      clipName="visual-map"
      gx={x}
      gy={y}
      rectClipProps={{
        width: width + (xOffset + xOffset),
        height: height + (yOffset + 50)
      }}
    >
      <defs>
        <linearGradient id={lGradientId}>
          {colors.map((color, idx) => (
            <stop key={color + idx} offset={idx / length} stopColor={color} />
          ))}
        </linearGradient>
      </defs>

      <rect
        fill={`url('#${lGradientId}')`}
        width={width}
        height={height}
        x={xOffset}
        y={yOffset}
      />
      {value !== null && (
        <path
          d={symbolPath}
          style={{
            transform: `translate(${xOffset + scale(indicatorValue) ?? 0}px, ${
              yOffset - 5
            }px) rotate(${rotate})`
          }}
          {...rest}
        />
      )}

      <Axis
        className="axis-visual-legend"
        lastUpdatedAt={lastUpdatedAt}
        axisScale={scale}
        dimention={'x'}
        axisPosition={'axisBottom'}
        xPosition={xOffset}
        yPosition={height + yOffset}
        {...axisProps}
      />
      <Grid
        scale={scale}
        height={height}
        tickCount={axisProps.tickCount ?? axisProps.tickValues}
        opacity={0.5}
        strokeWidth={1}
        stroke={'#fff'}
        type={'x'}
        transform={`translate(${xOffset} ${yOffset})`}
        {...gridProps}
      />
    </GroupAndClip>
  )
}
