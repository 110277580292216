export default function TooltipRenderer({ data }: { data: any }) {
  const {
    name = null,
    inactive = null,
    potentialSavingsMonth = null,
    potentialSavingsTotal = null,
    creationDate = '',
    link = null,
    isExceeding = false,
    exceedCount = 0
  } = data || {}

  return (
    <div className="action-tracking-tooltip">
      {isExceeding && (
        <>
          <div>Too many items in chart: {exceedCount}</div>
          <div>
            <a rel="noreferrer" target="_blank" href={link}>
              Click here to see all
            </a>
          </div>
        </>
      )}
      {!isExceeding && (
        <>
          {name !== null && (
            <div className="name">
              {link !== null ? (
                <a rel="noreferrer" target="_blank" href={link}>
                  {name}
                </a>
              ) : (
                name
              )}
            </div>
          )}
          {inactive !== null && <div>{inactive}</div>}
          {potentialSavingsMonth !== null && <div>{potentialSavingsMonth}</div>}
          {potentialSavingsTotal !== null && <div>{potentialSavingsTotal}</div>}
          {creationDate !== null && <div>{creationDate}</div>}
        </>
      )}
    </div>
  )
}
