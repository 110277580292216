import { ReactNode } from 'react'
import { TranslateComponent } from 'src/common/translations'
import { getColor } from '../helpers'
import { Tooltip, TooltipTrigger, TooltipContent } from '../Tooltip/Tooltip'
import styles from './user-name-bubble.module.scss'

interface UserNameBubbleProps {
  firstName: string
  lastName: string
  subLine: ReactNode
  showFullName: boolean
  onDark?: boolean
  isSmall?: boolean
  showTooltip?: boolean
}

export const UserNameBubble = ({
  firstName,
  lastName,
  subLine,
  showFullName,
  onDark,
  isSmall,
  showTooltip
}: UserNameBubbleProps) => {
  const initials = lastName ? `${firstName[0]}${lastName[0]}` : firstName.toUpperCase().split(' ').map((part) => part[0]).join('').slice(0, 2);
  const color = getColor(initials)

  return (
    <div
      className={`${styles.userNameBubble} ${onDark && styles.onDark} ${
        isSmall && styles.isSmall
      }`}
    >
      {showTooltip ? (
        <Tooltip>
          <TooltipTrigger testName="userNameBubbleTrigger">
            <div className={styles.icon} data-color={color}>
              {initials}
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <TranslateComponent>Contact</TranslateComponent>
          </TooltipContent>
        </Tooltip>
      ) : (
        <div className={styles.icon} data-color={color}>
          {initials}
        </div>
      )}
      {showFullName && (
        <div className={styles.text}>
          <div className={styles.name}>{`${firstName} ${lastName}`}</div>
          <div className={styles.subLine}>{subLine}</div>
        </div>
      )}
    </div>
  )
}
