export const parseSalesOffice = (building) => {
  return [
    building.salesOfficeCode,
    building.salesOfficeName,
    building.salesOfficeDistrict
  ]
    .filter(Boolean)
    .join(', ')
}

export const parseOfferingsString = (building) =>
  building.offeringCodes !== null
    ? Array.from(new Set(building.offeringCodes.split(', '))).join(', ')
    : ''

export const parsePlace = (place) => JSON.parse(place)?.Name

export const parseRegionCode = (code) => code.split('_')[1]

const SEARCH_FIELDS_MAP = {
  locationName: 'name',
  offeringsString: 'offeringCodes',
  connectivityTypes: 'deviceConnectivity',
  createdByTimestamp: 'createdAt',
  salesOffice: 'salesOfficeCode'
}

export const parseSeachFieldsReq = (field) => SEARCH_FIELDS_MAP[field] || field

export const parseSeachFieldsRes = (field) =>
  Object.keys(SEARCH_FIELDS_MAP).find(
    (key) => SEARCH_FIELDS_MAP[key] === field
  ) || field
