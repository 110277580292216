import { useEffect, useState, useMemo } from 'react'
import TimeComparisionBarWidgetChart from 'src/pages/widgets/widget-library/timeComparisonBarChartWidget/TimeComparisonBarWidgetChart'
import { useWidgetChartDataHook } from 'src/hooks/widgetChartDataHook'
import {
  aggreagatorSelection,
  aggregationDisplayName,
  getLatestDisplayName
} from 'src/common/chartHelperFunctions'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  WidgetChartTitleComponent,
  WidgetChartFooterComponent,
  NoWidgetDataAvailable
} from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import moment from 'moment/moment'
import { getStartAndEndDates } from 'src/pages/dashboards/helper'
import { WidgetPreviewChartParentContainer } from './style'
import {
  SimpleLegend,
  createSimpleLegendModel
} from '../../../../chart-library/CommonComponents/Legend'
import { WidgetNames } from 'src/pages/widgets/helpers'
import { isValidUOMForGetChartData } from "../../helper";
import { getDateFormatByLocale } from 'src/common/chartHelperFunctions'

const timeComparisionAggreagation = {
  'current-day': '15-minutes',
  'current-week': 'daily',
  'current-month': 'monthly',
  'current-quarter': 'quarterly',
  'current-year': 'yearly'
}

const timeComparisionTimePeriod = {
  'today-yesterday': 'current-day',
  'this-week-last-week': 'current-week',
  'this-month-last-month': 'current-month',
  'this-quarter-last-quarter': 'current-quarter',
  'this-year-last-year': 'current-year'
}

const barMaximumTimeAndAggr = (selectedTime) => {
  switch (selectedTime) {
    case 'peak-last-30-days':
      return { timePeriod: 'past-30-days', aggregation: 'max' }
    case 'peak-last-12-months':
      return { timePeriod: 'past-30-days', aggregation: 'max' }
    case 'peak-mtd':
      return { timePeriod: 'current-month', aggregation: 'max' }
    case 'peak-ytd':
      return { timePeriod: 'current-year', aggregation: 'max' }
    case 'sum-mtd':
      return { timePeriod: 'current-month', aggregation: 'sum' }
    case 'sum-today':
      return { timePeriod: 'current-day', aggregation: 'sum' }
    default:
      return { timePeriod: null, aggregation: null }
  }
}

export const TimeComparisionBarWidgetPreview = ({ widgetDetails }) => {
  const selectedPeriod =
    timeComparisionTimePeriod[widgetDetails?.selectedTimePeriod] ||
    'current-month'

  const {
    timePeriod: barMaxSelectedTime,
    aggregation: barMaxSelectedAggregationValue
  } = barMaximumTimeAndAggr(widgetDetails?.barCalculatedValue)

  const selectedAggrValue = widgetDetails?.selectedAggrValue

  const dataAggregation = timeComparisionAggreagation[selectedPeriod]

  const [barChartData, setBarChartData] = useState({})

  const [isDataAvailable, setIsDataAvailable] = useState(false)

  const [barMaxCalculatedValue, setBarMaxCalculatedValue] = useState(0)

  const { widgetChartData, getWidgetChartData, loadingWidgetChartData } =
    useWidgetChartDataHook(selectedPeriod, ':previous', dataAggregation, widgetDetails?.timeZone)

  const {
    widgetChartData: maxBarWidgetData,
    getWidgetChartData: getMaxbarWidgetData,
    loadingWidgetChartData: maxBarWidgetDataLoading
  } = useWidgetChartDataHook(barMaxSelectedTime, ':none', 'daily', widgetDetails?.timeZone)

  // Get avg past 30 days of data for Bench Marker Target
  const {
    widgetChartData: markerWidgetData,
    getWidgetChartData: getMarkerWidgetData,
    loadingWidgetChartData: markerWidgetDataLoading
  } = useWidgetChartDataHook('past-30-days', ':none', 'daily', widgetDetails?.timeZone)

  const getAverageValue = (data) => {
    try {
      const sumValue = data?.reduce((sum, { value = 0 }) => {
        const timeValue = value === null ? 0 : value
        return sum + Number(parseFloat(timeValue).toFixed(2))
      }, 0)
      return Number(sumValue / data?.length || 0).toFixed(2)
    } catch (error) {
      return 0
    }
  }

  const getPlotLinesCurrentData = (values) => {
    const targetMarker = widgetDetails?.rightTargetMarker
    if (targetMarker?.includes('constant')) {
      return {
        name: widgetDetails?.rightMarkerLabel || '',
        plotLineValue: Number(widgetDetails?.rightMarkerConstant || 0),
        plotLineColor: widgetDetails?.rightBenchmarkColor
      }
    } else if (targetMarker?.includes('calculated')) {
      const data = markerWidgetData?.data?.[0]?.values?.[0] || []
      const rightMarkerTarget = Number(
        aggreagatorSelection('avg', data || [], 0, data?.length - 1)
      ).toFixed(2)
      return {
        name: widgetDetails?.rightMarkerLabel || '',
        plotLineValue: Number(rightMarkerTarget),
        plotLineColor: widgetDetails?.rightBenchmarkColor
      }
    } else {
      return null
    }
  }

  const getPlotLinesPreviousData = (values) => {
    const targetMarker = widgetDetails?.leftTargetMarker
    if (targetMarker?.includes('constant')) {
      return {
        name: widgetDetails?.leftMarkerLabel || '',
        plotLineValue: Number(widgetDetails?.leftMarkerConstant || 0),
        plotLineColor: widgetDetails?.leftBenchmarkColor
      }
    } else if (targetMarker?.includes('calculated')) {
      const data = markerWidgetData?.data?.[0]?.values?.[0]
      const leftMarkerTarget = Number(
        aggreagatorSelection('avg', data || [], 0, data?.length - 1)
      ).toFixed(2)
      return {
        name: widgetDetails?.leftMarkerLabel || '',
        plotLineValue: Number(leftMarkerTarget),
        plotLineColor: widgetDetails?.leftBenchmarkColor
      }
    } else {
      return null
    }
  }

  const getXLabelName = (pastPeriod = false) => {
    const selectedPeriod =
      timeComparisionTimePeriod[widgetDetails?.selectedTimePeriod] ||
      'current-month'
    switch (selectedPeriod) {
      case 'current-day': {
        const { startDate, endDate } = getStartAndEndDates(
          selectedPeriod,
          pastPeriod,
          'DD/MM',
          widgetDetails?.timeZone
        )
        return startDate
      }
      case 'current-week': {
        const { startDate, endDate } = getStartAndEndDates(
          selectedPeriod,
          pastPeriod,
          'DD/MM',
          widgetDetails?.timeZone
        )
        return `${startDate}-${endDate}`
      }
      case 'current-month': {
        const { startDate } = getStartAndEndDates(
          selectedPeriod,
          pastPeriod,
          'MMM',
          widgetDetails?.timeZone
        )
        return startDate
      }
      case 'current-quarter': {
        const { startDate } = getStartAndEndDates(selectedPeriod, pastPeriod, null, widgetDetails?.timeZone)
        const currentQuarter = `Q${moment(startDate)?.quarter()}-${moment(
          startDate
        ).format('YYYY')}`
        return currentQuarter
      }
      case 'current-year': {
        const { startDate } = getStartAndEndDates(
          selectedPeriod,
          pastPeriod,
          'YYYY',
          widgetDetails?.timeZone
        )
        return startDate
      }
      default:
        return moment().format('DD/MM')
    }
  }

  const aggrBarDataValues = (data) => {
    const finalValue = Number(
      aggreagatorSelection(selectedAggrValue, data, 0, data?.length - 1)
    ).toFixed(2)
    return Number(finalValue)
  }

  const formatTimebarData = (previousData, currentData) => {
    return [
      {
        name: getXLabelName(true),
        displayName:getLatestDisplayName(previousData,selectedAggrValue),
        value: aggrBarDataValues(previousData),
        color: widgetDetails?.leftBarColor
      },
      {
        name: getXLabelName(),
        displayName:getLatestDisplayName(currentData,selectedAggrValue),
        value: aggrBarDataValues(currentData),
        color: widgetDetails?.rightBarColor
      }
    ]
  }

  const getMaximumBarValue = () => {
    if (widgetDetails?.barMax?.includes('calculated')) {
      if (maxBarWidgetData?.data?.length > 0) {
        const data = maxBarWidgetData?.data?.[0]?.values?.[0] || []
        const maxBarValue = Number(
          aggreagatorSelection(
            barMaxSelectedAggregationValue,
            data || [],
            0,
            data?.length - 1
          )
        ).toFixed(2)
        return Number(maxBarValue)
      }
    } else {
      return Number(widgetDetails?.barMaxConstantValue)
    }
  }

  const getInsideBarDataValue = (previousData, currentData) => {
    // Inner bar data, current period data of previous data
    // Example we are reuqesting data Lastmonth vs Current month
    // Last month have 31 data, since it is Jan for example
    // But current month today date is 20, we will have only 20 data
    // Inner data is Jan 1 - Jan 20 data
    const innerBarData = previousData?.slice(0, currentData?.length)
    return [
      {
        name: getXLabelName(true),
        displayName:getLatestDisplayName(innerBarData,selectedAggrValue),
        value: aggrBarDataValues(innerBarData),
        color: widgetDetails?.innerBarColor
      }
    ]
  }

  const formatBarChartData = (previousData, currentData) => {
    const barData = {
      measurement: widgetDetails?.symbol,
      maximumBarValue: getMaximumBarValue(),
      barData: formatTimebarData(previousData, currentData),
      insideBarData: getInsideBarDataValue(previousData, currentData),
      barPlotLinesData: [
        getPlotLinesPreviousData(previousData),
        getPlotLinesCurrentData(currentData)
      ]
    }
    setBarChartData(barData)
  }

  useEffect(() => {
    try {
      // if either of data fetch is true, just returns -> no plotting until we get all required data
      if (
        loadingWidgetChartData ||
        maxBarWidgetDataLoading ||
        markerWidgetDataLoading
      ) {
        return
      }

      if (widgetChartData && widgetChartData?.data?.length > 0) {
        const isDataAvailable = widgetChartData?.data?.some(
          (x) => x?.isDataAvailable
        )
        if (isDataAvailable) {
          setIsDataAvailable(true)
        } else {
          setIsDataAvailable(true)
          return
        }
        const chartDataPrevious =
          widgetChartData?.data?.filter(
            (x) => x.isPreviousPeriod === true
          )?.[0] || {}
        const chartDataCurrent =
          widgetChartData?.data?.filter(
            (x) => x.isPreviousPeriod !== true
          )?.[0] || {}

        formatBarChartData(
          chartDataPrevious?.values?.[0],
          chartDataCurrent?.values?.[0]
        )
      }
    } catch (error) {}
  }, [
    widgetChartData,
    maxBarWidgetData,
    markerWidgetData,
    loadingWidgetChartData,
    maxBarWidgetDataLoading,
    markerWidgetDataLoading
  ])

  const initializeData = async () => {
    const widgetInfo = {
      comparePointTo: ':previous',
      chartType: 'Line',
      interval: 1440,
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: selectedAggrValue,
      buildingId: widgetDetails?.buildingId,
      propertiesList: [widgetDetails?.property]
    }
    if (isValidUOMForGetChartData(widgetDetails?.unitsOfMeasure)) {
      widgetInfo["uomDetails"] = { [widgetDetails?.property]: widgetDetails?.unitsOfMeasure }
    }
    getWidgetChartData(widgetInfo)
  }

  const initializeDataForBarMax = async () => {
    const widgetInfo = {
      comparePointTo: ':none',
      chartType: 'Line',
      interval: 1440,
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: barMaxSelectedAggregationValue,
      buildingId: widgetDetails?.buildingId,
      propertiesList: [widgetDetails?.property]
    }
    if (isValidUOMForGetChartData(widgetDetails?.unitsOfMeasure)) {
      widgetInfo["uomDetails"] = { [widgetDetails?.property]: widgetDetails?.unitsOfMeasure }
    }
    getMaxbarWidgetData(widgetInfo)
  }

  const intervalAndFooterInfo = useMemo(() => {
    try {
      let intervalInfo = ''
      let footerInfo = ''
      const selectedPeriod =
        timeComparisionTimePeriod[widgetDetails?.selectedTimePeriod] ||
        'current-month'
      switch (selectedPeriod) {
        case 'current-day': {
          const lDateFormat = getDateFormatByLocale('MMM DD, YYYY')
          const { startDate, endDate } = getStartAndEndDates(
            selectedPeriod,
            false,
            lDateFormat ? lDateFormat : 'MMM DD, YYYY',
            widgetDetails?.timeZone
          )
          intervalInfo = `${startDate} vs ${endDate}`
          footerInfo = 'Yesterday vs Today'
          break
        }
        case 'current-week': {
          const lDateFormat = getDateFormatByLocale('DD/MM')
          const { startDate: currentWeekStart, endDate: currentWeekEnd } =
            getStartAndEndDates(selectedPeriod, false, lDateFormat ? lDateFormat : 'DD/MM', widgetDetails?.timeZone)
          const { startDate: pastWeekStart, endDate: pastWeekEnd } =
            getStartAndEndDates(selectedPeriod, true, lDateFormat ? lDateFormat : 'DD/MM', widgetDetails?.timeZone)
          intervalInfo = `${pastWeekStart}-${pastWeekEnd} vs ${currentWeekStart}-${currentWeekEnd}`
          footerInfo = 'Last Week vs Current Week'
          break
        }
        case 'current-month': {
          const lDateFormat = getDateFormatByLocale('MMM, YYYY')
          const { startDate: currentMonth } = getStartAndEndDates(
            selectedPeriod,
            false,
            lDateFormat ? lDateFormat : 'MMM, YYYY',
            widgetDetails?.timeZone
          )
          const { startDate: pastMonth } = getStartAndEndDates(
            selectedPeriod,
            true,
            lDateFormat ? lDateFormat : 'MMM, YYYY',
            widgetDetails?.timeZone
          )
          intervalInfo = `${pastMonth} vs ${currentMonth}`
          footerInfo = 'Last Month vs Current Month'
          break
        }
        case 'current-quarter': {
          const { startDate: currentStart } = getStartAndEndDates(
            selectedPeriod,
            false,
            null,
            widgetDetails?.timeZone
          )
          const { startDate: pastStart } = getStartAndEndDates(
            selectedPeriod,
            true,
            null,
            widgetDetails?.timeZone
          )
          const currentQuarter = `Q${moment(currentStart)?.quarter()}-${moment(
            currentStart
          ).format('YYYY')}`
          const pastQuarter = `Q${moment(pastStart)?.quarter()}-${moment(
            pastStart
          ).format('YYYY')}`
          intervalInfo = `${pastQuarter} vs ${currentQuarter}`
          footerInfo = 'Last Quarter vs Current Quarter'
          break
        }
        case 'current-year': {
          const { startDate: currentYear } = getStartAndEndDates(
            selectedPeriod,
            false,
            'YYYY',
            widgetDetails?.timeZone
          )
          const { startDate: pastYear } = getStartAndEndDates(
            selectedPeriod,
            true,
            'YYYY',
            widgetDetails?.timeZone
          )
          intervalInfo = `${pastYear} vs ${currentYear}`
          footerInfo = 'Last Year vs Current Year'
          break
        }
        default:
          intervalInfo = `${startDate} vs ${endDate}`
          footerInfo = ''
      }
      return {
        intervalInfo,
        compareTime: footerInfo,
        footerInfo: `${aggregationDisplayName[selectedAggrValue]} for ${footerInfo}`
      }
    } catch (error) {
      return ''
    }
  }, [selectedPeriod])

  const initializeDataForMarker = async () => {
    const widgetInfo = {
      comparePointTo: ':none',
      chartType: 'Line',
      interval: 1440,
      equipmentType: widgetDetails?.equipmentType,
      equipmentId: widgetDetails?.equipmentId,
      selectedAggrValue: 'average',
      buildingId: widgetDetails?.buildingId,
      propertiesList: [widgetDetails?.property]
    }
    if (isValidUOMForGetChartData(widgetDetails?.unitsOfMeasure)) {
      widgetInfo["uomDetails"] = { [widgetDetails?.property]: widgetDetails?.unitsOfMeasure }
    }
    getMarkerWidgetData(widgetInfo)
  }

  useEffect(() => {
    if (widgetDetails?.property) {
      initializeData()

      // Max bar calculations proeprty data
      if (widgetDetails?.barMax?.includes('calculated')) {
        initializeDataForBarMax()
      } else {
        setBarMaxCalculatedValue(widgetDetails?.barMaxConstantValue)
      }

      // Target marker property data
      if (
        widgetDetails?.leftTargetMarker?.includes('calculated') ||
        widgetDetails?.rightTargetMarker?.includes('calculated')
      ) {
        initializeDataForMarker()
      }
    }
  }, [widgetDetails])

  const simpleLegends = useMemo(() => {
    const footerInfo = intervalAndFooterInfo?.compareTime
    const legendText = footerInfo?.split('vs')
    if (widgetDetails?.comparePointTo !== ':none') {
      return [
        createSimpleLegendModel({
          text: legendText?.[0]?.trim(),
          iconProps: {
            symbol: 'symbolSquare',
            fill: widgetDetails?.leftBarColor,
            size: 92
          }
        }),
        createSimpleLegendModel({
          text: legendText?.[1]?.trim(),
          iconProps: {
            symbol: 'symbolSquare',
            fill: widgetDetails?.rightBarColor,
            size: 92
          }
        })
      ]
    }

    return []
  }, [widgetDetails?.comparePointTo])

  return loadingWidgetChartData || maxBarWidgetDataLoading ? (
    <Spinner />
  ) : barChartData &&
    Object.keys(barChartData)?.length > 0 &&
    isDataAvailable ? (
    <>
      <WidgetChartTitleComponent
        buildingName={widgetDetails?.buildingName}
        equipmentName={widgetDetails?.equipmentName}
        equipmentType={widgetDetails?.equipmentType}
        equipmentDetails={widgetDetails?.equipmentList}
        widgetDescription={widgetDetails?.widgetDescription}
        widgetProperty={widgetDetails?.property}
        accountName={widgetDetails?.accountName}
      />
      <WidgetPreviewChartParentContainer
        widgetType={WidgetNames.TimeComparisonBarChart}
        showLegends={true}
      >
        <TimeComparisionBarWidgetChart data={barChartData} />
      </WidgetPreviewChartParentContainer>
      <SimpleLegend
        className="dashboard-widget-simple-legend"
        isStandaloneLegend
        legends={simpleLegends}
      />
      <WidgetChartFooterComponent
        aggregatorName={selectedAggrValue}
        selectedPeriod={selectedPeriod}
        comparisonBar={true}
        aggragatorInterval={dataAggregation}
        intervalInfo={intervalAndFooterInfo?.intervalInfo}
        footerInfo={intervalAndFooterInfo?.footerInfo}
      />
    </>
  ) : (
    <NoWidgetDataAvailable
      buildingName={widgetDetails?.buildingName}
      equipmentName={widgetDetails?.equipmentName}
      equipmentType={widgetDetails?.equipmentType}
      aggregatorName={selectedAggrValue}
      selectedPeriod={selectedPeriod}
      dataAggregation={dataAggregation}
      widgetType="icon-linewidget"
      equipmentDetails={widgetDetails?.equipmentList}
      widgetDescription={widgetDetails?.widgetDescription}
      intervalInfo={intervalAndFooterInfo?.intervalInfo}
      footerInfo={intervalAndFooterInfo?.footerInfo}
      timeZone={widgetDetails?.timeZone}
      accountName={widgetDetails?.accountName}
    />
  )
}
