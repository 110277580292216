import * as yup from 'yup'

const REQUIRED = 'required'
const schema = () =>
  yup.object().shape({
    name: yup.string().required(REQUIRED),
    description: yup.string().notRequired(),
    authUserTypes: yup.array().required(REQUIRED)
  })

export const ERROR_MESSAGES = {
  name: 'Please enter a name'
}

export default schema
