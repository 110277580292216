import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _set from 'lodash/set'
import { shortMonthKeys } from '../../components/legacy/common/date-helper'

import TruckRollKPITableRow from './truck-roll-kpi-table-row'
import TruckRollKPITableHeader from './truck-roll-kpi-table-header'

import './truck-roll-kpi-table.scss'

export function TruckRollKPITable({ values = {}, onChange }) {
  const valueKeys = Object.keys(values)
  const [year, setYear] = useState(
    valueKeys.length ? Number(valueKeys[0]) : moment().year()
  )
  const secondColumnStart = shortMonthKeys.length / 2

  if (!valueKeys.includes(year.toString())) {
    values[year] = shortMonthKeys.map(() => ({
      actual: undefined,
      goal: undefined
    }))
  }

  const filterAndUpdateValues = (v) => {
    const filtered = Object.keys(v).reduce((obj, key) => {
      const a = v[key].some(
        ({ actual, goal }) => !isNaN(actual) || !isNaN(goal)
      )
      if (a || key === year.toString()) {
        obj[key] = v[key]
      }

      return obj
    }, {})

    onChange(filtered)
  }

  const onChangeRow = async ({ year, monthIndex, field, value }) => {
    filterAndUpdateValues({
      ..._set(values, `${year}[${monthIndex}].${field}`, value)
    })
  }

  return (
    <div className="truck-roll-kpi-table">
      <header className="truck-roll-kpi-table-header">
        <span
          className="icon icon-left-caret"
          onClick={() => setYear(year - 1)}
        />
        <span className="truck-roll-kpi-table-year">{year}</span>
        <span
          className="icon icon-right-caret"
          onClick={() => setYear(year + 1)}
        />
      </header>
      <div className='section-duplicate'>
        <TruckRollKPITableHeader />
        <TruckRollKPITableHeader />
      </div>
      <section className="truck-roll-kpi-table-wrapper">
        <div className="month-group">
          {(values[year] || []).slice(0, 6).map(({ actual, goal }, i) => (
            <React.Fragment key={shortMonthKeys[i]}>
              <TruckRollKPITableRow
                month={shortMonthKeys[i]}
                year={year}
                actual={actual}
                goal={goal}
                onChange={onChangeRow}
                shortMonthKeys={shortMonthKeys}
              />
            </React.Fragment>
          ))}
        </div>
        <div className="month-group">
          {(values[year] || []).slice(6).map(({ actual, goal }, i) => (
            <React.Fragment key={shortMonthKeys[i + 6]}>
              <TruckRollKPITableRow
                month={shortMonthKeys[i + 6]}
                year={year}
                actual={actual}
                goal={goal}
                onChange={onChangeRow}
                shortMonthKeys={shortMonthKeys}
              />
            </React.Fragment>
          ))}
        </div>
      </section>
    </div>
  )
}

TruckRollKPITable.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
}

export default TruckRollKPITable
