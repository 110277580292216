import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './image-block.scss'
import ImageUploader from 'src/components/imageuploader/imageuploader'
import ConfirmDialog from '../../confirm-dialog/index'
import i18next from 'i18next'

export default class ImageBlock extends PureComponent {
  static propTypes = {
    image: PropTypes.shape({
      imageId: PropTypes.string,
      title: PropTypes.string,
      caption: PropTypes.string
    }).isRequired,
    fieldMaxLength: PropTypes.number,
    imageName:PropTypes.string,
    update: PropTypes.func.isRequired,
    remove: PropTypes.func,
    annotationsEnabled: PropTypes.bool
  }

  static defaultProps = {
    fieldMaxLength: 140,
    annotationsEnabled: false
  }
  constructor(props) {
    super(props)

    this.state = {
      confirm: null
    }
  }

  removeBlock = () => {
    const {
      remove,
      image: { title = '' }
    } = this.props
    this.setState({
      confirm: {
        title: i18next.t('components:images>RemoveImageBlock'),
        confirmText: i18next.t(
          'components:images>RemoveImageBlockConfirmation',
          { image: title ? title : i18next.t('components:images>thisImage') }
        ),
        ok: () => {
          remove()
          this.setState({ confirm: null })
        }
      }
    })
  }

  closeConfirmDialog = () => {
    this.setState({ confirm: null })
  }

  handleChange =
    (fieldName) =>
    ({ target: { value } }) =>
      value.length <= this.props.fieldMaxLength &&
      this.props.update({ [fieldName]: value })

  render() {
    const { imageId = null, title, caption, imageUrl } = this.props.image
    const { remove, update, annotationsEnabled, imageName } = this.props
    const { confirm } = this.state
    const isRemoveButtonVisible = imageId || title || caption
    // If 'remove' prop is passed -> show remove button and styles for container,
    // used in image list component where we can have multiple ImageBlock components
    return (
      <div className={remove && 'image-block-container'} data-testid="image-block-container">
        {remove && isRemoveButtonVisible && (
          <button
            data-testid="remove-icon"
            type="button"
            className="icon-btn red remove-icon remove-block"
            onClick={this.removeBlock}
          >
            <span className="icon-trash2" />
          </button>
        )}
        <>
          <legend>{i18next.t('components:images>ImageTitle')}</legend>
          <input
            type="text"
            name="title"
            onChange={this.handleChange('title')}
            value={title || ''}
          />
        </>
        <div className="image-control" data-testid="image-uploader-wrapper">
          <ImageUploader
            imageId={imageId}
            imageName={imageName}
            update={update}
            imageUrl={imageUrl}
            annotationsEnabled={annotationsEnabled}
            locationId={this.props.locationId}
          />
        </div>
        <div className="image-control" data-testid="image-caption">
          <legend>{i18next.t('components:images>ImageCaption')}</legend>
          <input
            type="text"
            name="caption"
            onChange={this.handleChange('caption')}
            value={caption || ''}
          />
        </div>
        {confirm && (
          <ConfirmDialog
            title={confirm.title}
            handleConfirm={confirm.ok}
            handleCancel={this.closeConfirmDialog}
            confirmText={i18next.t('common:Yes')}
            cancelText={i18next.t('common:No')}
          >
            {' '}
            {confirm.confirmText}{' '}
          </ConfirmDialog>
        )}
      </div>
    )
  }
}
