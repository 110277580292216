import PropTypes from "prop-types"
import { StyleSheet, Text, View } from "@react-pdf/renderer"
import colors from 'src/components/legacy/common/colors.json'

const styles = StyleSheet.create({
	container: {
		marginVertical: 10,
	},
	title: {
		color: colors.GREY_7,
		fontSize: 15,
		fontWeight: "bold",
	},
	content: {
		margin: 10,
	},
})

export default function PDFBlockWrapper({ title, children }) {
	return <View style={styles.container} wrap={false}>
		<Text style={styles.title}>{title}</Text>
		<View style={styles.content}>{children}</View>
	</View>
}

PDFBlockWrapper.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
}
