import memoizeOne from 'memoize-one'
import _get from 'lodash/get'
import { MIN_SEARCH_TEXT } from '../helpers.js'

/**
 * @typedef {String} Operators
 * @enum {Operators}
 */
export const OPERATORS_ENUM = {
  OR: 'OR',
  AND: 'AND'
}

function getWordsArray(string, minWordLength) {
  return string
    .trim()
    .split(/\s+/)
    .filter((word) => word.length >= minWordLength)
    .map((word) => word.toLowerCase())
}

const getWordsArrayMemoized = memoizeOne(getWordsArray)

/**
 * Helper function to search trough object fields items
 *
 * @param {String[]} searchFields - Fields of array item
 * @param {String} searchValue - Search value
 * @param {Operators} operator - Enum operators
 * @param {Number} minWordLength - Min word length to search for
 * @returns {Function}
 */
export function searchObject(
  searchFields = [],
  searchValue = '',
  operator = OPERATORS_ENUM.OR,
  minWordLength = MIN_SEARCH_TEXT
) {
  const searchWords = getWordsArrayMemoized(searchValue, minWordLength)

  return (object) =>
    searchFields.find((path) => {
      let fieldValue = (_get(object, path) || '').toString().toLowerCase()

      if (operator === OPERATORS_ENUM.OR) {
        return searchWords.some((word) => fieldValue.includes(word))
      } else if (operator === OPERATORS_ENUM.AND) {
        return searchWords.every((word) => {
          if (!fieldValue.includes(word)) {
            return false
          }

          fieldValue = fieldValue.replace(word, '')
          return true
        })
      }
    })
}

/**
 * Helper function to search trough array of objects
 *
 * @param {Object[]} array - Items where to search
 * @param {String[]} searchFields - Fields of array item
 * @param {String} searchValue - Search value
 * @param {Operators} operator - Enum operators
 * @param {Number} minWordLength - Min word length to search for
 * @returns {Object[]} - Items filtered after search
 */
export function search(
  array = [],
  searchFields = [],
  searchValue = '',
  operator = OPERATORS_ENUM.OR,
  minWordLength = MIN_SEARCH_TEXT
) {
  const searchWords = getWordsArrayMemoized(searchValue, minWordLength)

  if (!array.length || !searchFields.length || !searchWords.length) {
    return array
  }

  return array.filter(
    searchObject(searchFields, searchValue, operator, minWordLength)
  )
}
