export const VALIDATION_ERRORS = {
  locationName: 'Please enter a building name',
  trim: 'Please remove leading and trailing spaces',
  address: {
    line1: 'Please enter a valid Street Address',
    countryCode: 'Please enter a valid Country',
    region: 'Please enter a valid State/Province',
    postalCode: 'Please enter a valid Zip/Postal Code',
    city: 'Please enter a valid City'
  },
  floorArea: 'Please enter a valid Building Area',
  organizationId: 'Please enter an Organization/Customer Name',
  salesOffice: {
    tisTraneOfficeId: 'Please enter a valid sales office'
  },
  customerCRMSiteId: 'Please enter a valid Customer CRM Site ID',
  offeringSourceMaps: {
    subdomain: {
      invalid:
        'Subdomains can use characters a-z, A-Z, 0-9 and hyphen ( - ). It can be at most 63 characters in length'
    },
    startDate: {
      empty: 'Please enter start date',
      earlier: 'Start date should be earlier than Expiration date',
      earlierAnalytics: 'Start date should be earlier than Analytics date'
    },
    expirationDate: {
      empty: 'Please enter expiration date'
    },
    required: 'required',
    duplicateCRMSiteID: 'CRM Site ID should be unique',
    crmSiteRequiredMsg:
      'All buildings must have a CRM Site ID before requesting activation',
    subscriptions: 'Please choose at least one option',
    locations: 'Please choose at least one option',
    contractPeriod: {
      empty: 'Contract Length is required',
      notAppropriate: 'Choose an appropriate Contract Length'
    },
    deviceCountError: 'Device Count should be a number from 1 to 99',
    invalidEmail: 'This email address is not valid'
  }
}

export const reSubDomain = /^[a-zA-Z0-9][a-zA-Z0-9-]*$/i
export const MAX_SUBDOMAIN_LENGTH = 63

export const POSTAL_CODE_REGEXPS_BY_COUNTRY_CODE = {
  DEFAULT: /.*/,
  CAN: /^([A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d)$/,
  HKG: null,
  IND: /^\d{6}$/,
  JPN: /^\d{3}[ -]?\d{4}$/,
  MEX: /^\d{5}$/,
  PRI: /^\d{5}(-\d{4})?$/,
  SGP: /^\d{6}$/,
  USA: /^\d{5}(-\d{4})?$/
}
