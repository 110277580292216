export const listUtilityDataByBuilding = /* GraphQL */ `
  query listUtilityDataByBuilding($buildingId: ID!, $filter: ModelBuildingUtilityDataFilterInput, $year: ModelIntKeyConditionInput) {
    listUtilityDataByBuilding(filter: $filter, buildingId: $buildingId, year: $year) {
        items {
            eci
            eui
            month
            year
            electricUtilityData {
              startDate
              endDate
              totalDemandCost
              consumptionCost
              fees
            }
            gasUtilityData {
              startDate
              endDate
              totalCost
            }
          }
    }
  }
`

export const listBenchMarkersByBuilding = /* GraphQL */ `
  query listBenchMarkersByBuilding($buildingId: ID!) {
    listBenchMarkersByBuilding(buildingId: $buildingId) {
      items {
        buildingId
        description
        id
        markerDate
        name
        type
        value
        updatedAt
      }
    }
  }
`

export const CREATE_BENCH_MARK = /* GraphQL */ `
  mutation createBenchMarker($input: CreateBenchMarkerInput!) {
    createBenchMarker(input: $input) {
      id
    }
  }
`

export const UPDATE_BENCH_MARK = /* GraphQL */ `
  mutation updateBenchMarker($input: UpdateBenchMarkerInput!) {
    updateBenchMarker(input: $input) {
      id
    }
  }
`

export const DELETE_BENCH_MARK = /* GraphQL */ `
  mutation deleteBenchMarker($input: DeleteBenchMarkerInput!) {
    deleteBenchMarker(input: $input) {
      id
    }
  }
`