import * as d3 from 'd3'
import { HEIGHT } from './constants'
import localeConfig from './locale'

const locale_en = localeConfig['en']
const locale = d3.timeFormatLocale(locale_en)

const indicatorYpos = HEIGHT / 2 - 7 // 7 - height of indicator / 2

/**
 * function to attach hidden indicator block into ui
 *
 * @param node
 */
export function attachIndicator(node, defsUUID) {
  node
    .selectAll('.indicator-block')
    .data([{ position: 'left' }, { position: 'right' }])
    .join('use')
    .attr('class', (d) => `indicator-block ${d.position}`)
    .classed('hide-el', true)
    .attr('href', (d) => `#${d.position}-indicator${defsUUID}`)
}

/**
 * function to toggle indicator on hover events
 *
 * @param node
 * @param mouseOutRef
 */
export function attachHoverEvents(node, mouseOutRef: any = {}) {
  node
    .selectAll('.grabber-block')
    .on('mouseover mousedown', function () {
      const brushG = d3.select(this.parentNode)
      const grabberBlockEl = d3.select(this)

      grabberBlockEl.attr('data-active', true)
      grabberBlockEl.attr('fill', 'url(#grabberGradient)')

      const selectedGrabberSide = grabberBlockEl.attr('data-grabber-position')

      const selector = '.indicator-block.' + selectedGrabberSide

      brushG.selectAll(selector).classed('hide-el', false)
    })
    .on('mouseout', function () {
      if (mouseOutRef.current === 'end') {
        hideIndicator(this.parentNode)
      }
    })
}

/**
 * function to hide the indicator
 *
 * @param node
 */
export function hideIndicator(node, { hideGradient = true } = {}) {
  hideGradient &&
    d3
      .select(node)
      .selectAll('.grabber-block')
      .attr('fill', 'url(#buttonGradient)')
  d3.select(node).selectAll('.grabber-block').attr('data-active', false)
  d3.select(node).selectAll('.indicator-block').classed('hide-el', true)
}

/**
 * Handle postion & data of indicator
 *
 * @param g
 * @param selection
 * @param dateRange
 * @returns
 */
export function updateIndicator(g, selection, dateRange, direction) {
  const localeformat = g.attr('data-time-format')
  const dateTimeFormatter = locale.format(localeformat)

  const totalRange = +g.selectAll('.overlay').attr('width')

  g.selectAll('.indicator').each(function (p, j) {
    const dateTime = dateTimeFormatter(dateRange[j])

    const node = d3.select(this)

    let width = 0
    node
      .selectAll('text.date-time-block')
      .text(dateTime)
      .attr('width', function () {
        width = (this as any).getComputedTextLength() + 10
        return width
      })

    node.selectAll('.date-time-background').attr('width', width)
    const side = node.attr('data-indicator-side')

    const xStart = selection[0]
    const xEnd = selection[1]

    const spaceBetweenRange = xEnd - xStart

    let position = selection[j]
    const hasEnoughSpace = spaceBetweenRange > width

    if (!hasEnoughSpace) {
      if (side === 'left') {
        position =
          xStart > width
            ? position - 12 - width
            : totalRange - xEnd > width
            ? xEnd + 12
            : position
      } else {
        position =
          totalRange - xEnd > width
            ? position + 12
            : xStart > width
            ? xStart - 12 - width
            : position
      }
    } else {
      position += side === 'right' ? -(width + 12) : 12
    }

    node.attr('transform', `translate(${position}, ${indicatorYpos})`)
  })

  // show the appropriate indicator when a grabber overlapping the other
  // ex: xStart moves from left to right side after overlapping xEnd grabber
  hideIndicator(g.node(), { hideGradient: false })
  const selector = '.indicator-block.' + direction
  g.selectAll(selector).classed('hide-el', false)

  return g
}

/**
 * React Component which has indicator markups
 *
 * @returns
 */
export function IndicatorMarkup({ defsUUID }) {
  return (
    <defs>
      <g
        className="left indicator"
        id={'left-indicator' + defsUUID}
        transform={`translate(0, ${indicatorYpos})`}
        data-indicator-side="left"
      >
        <rect
          height="15"
          fill="url(#timeGradient)"
          ry="2"
          width="0"
          className="date-time-background"
        ></rect>
        <text
          className="date-time-block"
          y="11"
          dx="5"
          fill="#000"
          width="0"
        ></text>
      </g>
      <g
        className="right indicator"
        id={'right-indicator' + defsUUID}
        transform={`translate(0, ${indicatorYpos})`}
        data-indicator-side="right"
      >
        <rect
          height="15"
          fill="url(#timeGradient)"
          ry="2"
          width="0"
          className="date-time-background"
        ></rect>
        <text
          className="date-time-block"
          y="11"
          dx="5"
          fill="#000"
          width="0"
        ></text>
      </g>
    </defs>
  )
}
