// React Libraries
import React from 'react'
import { string, func, oneOfType, bool } from 'prop-types'

// Components
import { IconContainer, GlobalFontFace } from './styles'

const Icon = (props) => {
  const {
    type = '',
    disabled = false,
    margin = '0px',
    padding = '0px',
    color = '#666666',
    hover = '',
    size = '14px',
    cursor = 'default',
    action,
    decoration = 'none',
    transform = 'none',
    display = 'inline-block',
    className = '',
    height,
    width,
    float,
    line,
    transition,
    backgroundColor,
    borderRadius,
    fontWeight,
    verticalAlign,
    textAlign,
    lineHeight
  } = props
  const cursorinput = action
    ? !disabled
      ? 'pointer'
      : 'not-allowed'
    : cursor
    ? cursor
    : 'arrow'
  const onClickHandler = !disabled ? action : undefined
  return (
    <IconContainer
      {...props}
      className={`I_${type} ${className}`}
      type={type}
      margin={margin}
      padding={padding}
      color={color}
      hover={hover}
      size={size}
      disabled={disabled}
      cursor={cursorinput}
      onClick={onClickHandler && onClickHandler}
      decoration={decoration}
      transform={transform ? transform : 'none'}
      transition={transition && transition}
      display={display}
      height={height && height}
      width={width && width}
      float={float && float}
      line={line && line}
      backgroundColor={backgroundColor && backgroundColor}
      borderRadius={borderRadius && borderRadius}
      fontWeight={fontWeight && fontWeight}
      verticalAlign={verticalAlign && verticalAlign}
      textAlign={textAlign && textAlign}
      lineHeight={lineHeight && lineHeight}
    >
      <GlobalFontFace />
    </IconContainer>
  )
}

Icon.propTypes = {
  /** icon type of icon */
  type: string,

  /** margin around text */
  margin: string,

  /** padding around text */
  padding: string,

  /** color of text */
  color: string,

  /** hover color of text */
  hover: string,

  /** size of text */
  size: string,

  /** cursor of text */
  cursor: string,

  /** action action */
  action: func,

  /** disabled icon */
  disabled: bool,

  /** text decoration on text */
  decoration: string,

  /** transform on the icon */
  transform: oneOfType([string, bool]),

  /** display of the icon */
  display: string,

  /** additional classnames on the icon */
  className: string,

  /** hardcode the height if need be */
  height: string,

  /** hardcode the height if need be */
  width: string,

  /** float of icon */
  float: string,

  /** line height of icon */
  line: string,

  /** backgroundcolor of icon */
  backgroundColor: string,

  /** borderRadius of icon */
  borderRadius: string,

  /** fontWeight of icon */
  fontWeight: string,

  /** verticalAlign of icon */
  verticalAlign: string,

  /** textAlign of icon */
  textAlign: string,

  /** lineHeight of the icon */
  lineHeight: string
}

export default React.memo(Icon)
