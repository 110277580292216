import styled from 'styled-components'
import colors from 'src/components/legacy/common/colors.json'

const tba_dark_grey = colors['TBA_DARK_GREY']
const tba_lightest_grey = colors['TBA_LIGHTEST_GREY']
const tba_light2_grey = colors['TBA_LIGHT2_GREY']

export const TextEditorWrapper = styled.div`
  padding: 20px 10px;
  && .text-editor {
    & .text-area {
      background: ${tba_lightest_grey};
      border: 1px solid ${tba_dark_grey};
      border-radius: 2px;
      outline: none;
      padding: 0 10px;
      height: 170px;
    }

    & .toolbar {
      border-color: ${tba_light2_grey};

      > .rdw-option-wrapper {
        padding: 0;

        &,
        &:hover {
          border: none;
        }
      }
    }

    && .DraftEditor-editorContainer,
    .DraftEditor-root,
    .public-DraftEditor-content {
      height: auto;
    }
  }
`
