import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _get from 'lodash/get'
import Modal from 'src/components/legacy/components/modal/modal'
import {
  SEARCH_ACCOUNTS,
  SEARCH_BUILDINGS,
  SEARCH_SALES_OFFICES,
  generatePreLoadSelectedBuildingsQuery
} from './graphql'
import {
  DELETE_SUBSCRIBER,
  SAVE_SUBSCRIBER,
  UPDATE_SUBSCRIBER
} from './mutations'
import './index.scss'
import isEqual from 'lodash/isEqual'
import Select from 'src/components/legacy/components/select/select'
import ObjectPicker from './objectPicker'
import { useMutation, useQuery } from '../../../hooks/APIHooks'
import Label from 'src/components/legacy/components/label/label'
import Checkbox from 'src/components/legacy/components/checkbox/checkbox'
import { selectUserInfo } from '../../../redux/slicers/appData'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import { StyledFlexDiv, SecondDivContainer } from './components'
import { createWildCardFilter } from 'src/components/layouts/Table/wildCardFilter'

export const getQueryResponseMap = (response, type) => {
  return response.map((e) => ({
    name: e.name,
    children: e.name,
    id: e.id,
    value: e.id,
    accountName: e?.accountName,
    accountId: e?.accountId,
    salesOfficeId: e?.salesOfficeId,
    salesOfficeName: e?.salesOfficeName,
    disabled: false,
    type: type
  }))
}

export const IntelligentServicesNotificationsSettingsDialog = ({
  onClose,
  onSave,
  values
}) => {
  const [t] = useTranslation()
  const [selectedFilter, setSelectedFilter] = useState('Building')
  const [pickerInputData, setInputData] = useState([])
  const [selectedBuildings, setSelectedBuildings] = useState([])
  const [selectedSalesOffices, setSelectedSalesOffices] = useState([])
  const [selectedOrganizations, setSelectedOrganizations] = useState([])
  const [deliveryMethod, setDeliveryMethod] = useState({
    email: true,
    tc: true,
    sms: false
  })
  const userInfo = useSelector(selectUserInfo)

  const { data: buildingData, refetch: buildingDataRefetch } = useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    dataPath: 'data.searchBuildings.items'
  })

  const { data: accountData, refetch: accountDataRefetch } = useQuery({
    query: SEARCH_ACCOUNTS,
    disableInitialLoad: true,
    dataPath: 'data.searchAccounts.items'
  })

  const { data: salesOfficeData, refetch: salesOfficeDataRefetch } = useQuery({
    query: SEARCH_SALES_OFFICES,
    disableInitialLoad: true,
    dataPath: 'data.searchSalesOffices.items'
  })

  const { onSubmit: saveNotificationSetting } = useMutation({
    query: SAVE_SUBSCRIBER,
    dataPath: 'data'
  })

  const { onSubmit: updateNotificationSetting } = useMutation({
    query: UPDATE_SUBSCRIBER,
    dataPath: 'data'
  })

  const { onSubmit: deleteNotificationSetting } = useMutation({
    query: DELETE_SUBSCRIBER,
    dataPath: 'data'
  })

  useEffect(() => {
    if (selectedFilter) {
      if (selectedFilter === 'Building') {
        buildingDataRefetch({ limit: 30 })
      } else if (selectedFilter === 'Organization') {
        accountDataRefetch({ limit: 30 })
      } else {
        salesOfficeDataRefetch({ limit: 30 })
      }
    }
  }, [selectedFilter])

  useEffect(() => {
    if (buildingData) {
      setInputData(getQueryResponseMap(buildingData, 'building'))
    }
  }, [buildingData])

  useEffect(() => {
    if (accountData) {
      setInputData(getQueryResponseMap(accountData, 'organization'))
    }
  }, [accountData]),
    useEffect(() => {
      if (salesOfficeData) {
        setInputData(getQueryResponseMap(salesOfficeData, 'salesOffice'))
      }
    }, [salesOfficeData])

  useEffect(() => {
    if (values?.length) {
      setDeliveryMethod({
        email: Boolean(
          values?.some(
            (e) => e.settings.findIndex((e) => e.nType === 'email') !== -1
          )
        ),
        tc: Boolean(
          values?.some(
            (e) => e.settings.findIndex((e) => e.nType === 'tc') !== -1
          )
        ),
        sms: Boolean(
          values?.some(
            (e) => e.settings.findIndex((e) => e.nType === 'sms') !== -1
          )
        )
      })
      const callLoadBuildingsFunc = async () => {
        const buildingIds = values
          ?.filter((e) => e.buildingId)
          .map((e) => e.buildingId)
        const salesOfficeIds = values
          ?.filter((e) => e.salesOfficeId)
          .map((e) => e.salesOfficeId)
        const accountIds = values
          ?.filter((e) => e.accountId)
          .map((e) => e.accountId)
        const queries = generatePreLoadSelectedBuildingsQuery(
          buildingIds,
          salesOfficeIds,
          accountIds
        )
        await Promise.all(
          queries.map(async (e) => {
            if (e) {
              return await API.graphql({
                query: e
              })
            } else {
              return null
            }
          })
        ).then((res) => {
          if (res[0]) {
            setSelectedBuildings(
              res[0].data.searchBuildings.items.map((e) => ({
                disabled: false,
                name: e.name,
                children: e.name,
                value: e.id,
                id: e.id,
                type: 'building'
              }))
            )
          }
          if (res[1]) {
            setSelectedSalesOffices(
              res[1].data.searchSalesOffices.items.map((e) => ({
                disabled: false,
                name: e.name,
                children: e.name,
                value: e.id,
                id: e.id,
                type: 'salesOffice'
              }))
            )
          }
          if (res[2]) {
            setSelectedOrganizations(
              res[2].data.searchAccounts.items.map((e) => ({
                disabled: false,
                name: e.name,
                children: e.name,
                value: e.id,
                id: e.id,
                type: 'organization'
              }))
            )
          }
        })
      }
      callLoadBuildingsFunc()
    }
  }, [values])

  const getModalConfig = () => ({
    gray: true,
    formHasRequiredFields: true,
    heading: 'IS Notification Parameters',
    isWideModal: true,
    buttons: [
      {
        text: t('common:Save'),
        handleClick: async () => {
          const enabledNotificationTypes = Object.keys(deliveryMethod)
            .filter((e) => deliveryMethod[e])
            .sort()
          const dataToSave = []
          const dataToUpdate = []
          const selectedSalesOfficeIds = selectedSalesOffices.map((e) => e.id)
          const selectedOrganizationIds = selectedOrganizations.map((e) => e.id)
          const selectedBuildingIds = selectedBuildings.map((e) => e.id)
          const newSaveSalesOfficeIds =
            selectedSalesOfficeIds?.filter(
              (e) => !values?.some((x) => x.salesOfficeId === e)
            ) || []
          const newSaveOrganizationIds =
            selectedOrganizationIds?.filter(
              (e) => !values?.some((x) => x.accountId === e)
            ) || []
          const newSaveBuildingIds =
            selectedBuildingIds?.filter(
              (e) => !values?.some((x) => x.buildingId === e)
            ) || []
          const deleteSaveSalesOfficeIds =
            values
              ?.filter(
                (v) =>
                  v.salesOfficeId &&
                  !selectedSalesOfficeIds.includes(v.salesOfficeId)
              )
              .map((v) => v.id) || []
          const deleteSaveOrganizationIds =
            values
              ?.filter(
                (v) =>
                  v.accountId && !selectedOrganizationIds.includes(v.accountId)
              )
              .map((v) => v.id) || []
          const deleteSaveBuildingIds =
            values
              ?.filter(
                (v) =>
                  v.buildingId && !selectedBuildingIds.includes(v.buildingId)
              )
              .map((v) => v.id) || []
          const editSaveSalesOfficeVals =
            values?.filter((v) =>
              selectedSalesOfficeIds.includes(v.salesOfficeId)
            ) || []
          const editSaveOrganizationVals =
            values?.filter((v) =>
              selectedOrganizationIds.includes(v.accountId)
            ) || []
          const editSaveBuildingVals =
            values?.filter((v) => selectedBuildingIds.includes(v.buildingId)) ||
            []

          const editSaveSalesOfficeIds = editSaveSalesOfficeVals.reduce(
            (accum, curr) => {
              if (
                !isEqual(
                  curr?.settings?.map((e) => e.nType).sort(),
                  enabledNotificationTypes
                )
              ) {
                accum.push(curr.id)
              }
              return accum
            },
            []
          )

          const editSaveOrganizationIds = editSaveOrganizationVals.reduce(
            (accum, curr) => {
              if (
                !isEqual(
                  curr?.settings?.map((e) => e.nType).sort(),
                  enabledNotificationTypes
                )
              ) {
                accum.push(curr.id)
              }
              return accum
            },
            []
          )

          const editSaveBuildingIds = editSaveBuildingVals.reduce(
            (accum, curr) => {
              if (
                !isEqual(
                  curr?.settings?.map((e) => e.nType).sort(),
                  enabledNotificationTypes
                )
              ) {
                accum.push(curr.id)
              }
              return accum
            },
            []
          )

          const allEdits = [
            ...editSaveSalesOfficeIds,
            ...editSaveOrganizationIds,
            ...editSaveBuildingIds
          ]
          const allDeletes = [
            ...deleteSaveSalesOfficeIds,
            ...deleteSaveOrganizationIds,
            ...deleteSaveBuildingIds
          ]

          newSaveOrganizationIds.forEach((e) => {
            dataToSave.push({
              contactId: userInfo.id,
              userId: userInfo.id,
              equipmentId: undefined,
              accountId: e,
              buildingId: undefined,
              salesOfficeId: undefined,
              deviceId: undefined,
              type: 'is',
              settings: enabledNotificationTypes.map((x) => ({
                nType: x,
                nStatus: true
              }))
            })
          })
          newSaveSalesOfficeIds.forEach((e) => {
            dataToSave.push({
              contactId: userInfo.id,
              userId: userInfo.id,
              equipmentId: undefined,
              accountId: undefined,
              buildingId: undefined,
              salesOfficeId: e,
              deviceId: undefined,
              type: 'is',
              settings: enabledNotificationTypes.map((x) => ({
                nType: x,
                nStatus: true
              }))
            })
          })
          newSaveBuildingIds.forEach((e) => {
            dataToSave.push({
              contactId: userInfo.id,
              userId: userInfo.id,
              equipmentId: undefined,
              accountId: undefined,
              buildingId: e,
              salesOfficeId: undefined,
              deviceId: undefined,
              type: 'is',
              settings: enabledNotificationTypes.map((x) => ({
                nType: x,
                nStatus: true
              }))
            })
          })

          allEdits.forEach((e) => {
            dataToUpdate.push({
              id: e,
              settings: enabledNotificationTypes.map((x) => ({
                nType: x,
                nStatus: true
              }))
            })
          })

          await Promise.all([
            ...allDeletes.map(
              async (e) => await deleteNotificationSetting({ input: { id: e } })
            ),
            ...dataToSave.map(
              async (e) => await saveNotificationSetting({ input: e })
            ),
            ...dataToUpdate.map(
              async (e) => await updateNotificationSetting({ input: e })
            )
          ]).then(() => {
            onSave()
            onClose()
          })
        },
        type: 'confirm'
      },
      {
        text: t('common:Close'),
        handleClick: onClose,
        type: 'cancel'
      }
    ],
    handleClose: onClose,
    className: 'notifications-settings-dialog'
  })

  return (
    <Modal {...getModalConfig()}>
      <div className="rx-grid rx-grid-fit-y">
        <div className="rx-col-12">
          <h2>For These Organizations/Buildings</h2>
          <span className="description-text grey">
            Select all buildings you want to receive notifications from.
          </span>
        </div>
      </div>
      <section className="rx-grid">
        <br />
        <br />
        <br />
        {buildingData ? (
          <StyledFlexDiv>
            <div>
              <Select
                options={[
                  { key: 'Building', value: 'Building' },
                  { key: 'Organization', value: 'Organization' },
                  { key: 'Sales Office', value: 'Sales Office' }
                ]}
                onChange={(e) => {
                  setSelectedFilter(e)
                }}
                selectedItem={selectedFilter}
                dropDownType="top"
              />
              <ObjectPicker
                label=""
                values={pickerInputData}
                headerText={`${selectedFilter}s`}
                refetch={
                  selectedFilter === 'Building'
                    ? (e) =>
                        buildingDataRefetch({
                          limit: 1000,
                          filter:
                            e && e !== ''
                              ? createWildCardFilter({
                                  attrName: 'name',
                                  value: e,
                                  operator: 'and'
                                })
                              : undefined
                        })
                    : selectedFilter === 'Organization'
                    ? (e) =>
                        accountDataRefetch({
                          limit: 1000,
                          filter:
                            e && e !== ''
                              ? createWildCardFilter({
                                  attrName: 'name',
                                  value: e,
                                  operator: 'and'
                                })
                              : undefined
                        })
                    : (e) =>
                        salesOfficeDataRefetch({
                          limit: 1000,
                          filter:
                            e && e !== ''
                              ? createWildCardFilter({
                                  attrName: 'name',
                                  value: e,
                                  operator: 'and'
                                })
                              : undefined
                        })
                }
                onCheckboxUnclick={(e) => {
                  if (e.type === 'salesOffice') {
                    setSelectedSalesOffices(
                      selectedSalesOffices.filter((x) => x.id !== e.id)
                    )
                  } else if (e.type === 'organization') {
                    setSelectedOrganizations(
                      selectedOrganizations.filter((x) => x.id !== e.id)
                    )
                  } else {
                    setSelectedBuildings(
                      selectedBuildings.filter((x) => e.id !== x.id)
                    )
                  }
                }}
                onCheckboxClick={(e) => {
                  if (e.type === 'salesOffice') {
                    setSelectedSalesOffices([...selectedSalesOffices, e])
                  } else if (e.type === 'organization') {
                    setSelectedOrganizations([...selectedOrganizations, e])
                  } else {
                    setSelectedBuildings([...selectedBuildings, e])
                  }
                }}
                selectedBuildings={selectedBuildings}
                selectedOrganizations={selectedOrganizations}
                selectedSalesOffices={selectedSalesOffices}
                error={false}
              />
            </div>
            <SecondDivContainer>
              <Label text={`Notify me via:`} />
              <Checkbox
                id="ui-notification-checkbox"
                customClassName="delivery-method-checkbox"
                label={'In Trane Connect'}
                isChecked={deliveryMethod.tc}
                toggleCheck={({ target: { checked } }) =>
                  setDeliveryMethod({
                    ...deliveryMethod,
                    tc: checked
                  })
                }
              />
              <Checkbox
                id="email-checkbox"
                customClassName="delivery-method-checkbox"
                label={'Email'}
                isChecked={deliveryMethod.email}
                toggleCheck={({ target: { checked } }) =>
                  setDeliveryMethod({ ...deliveryMethod, email: checked })
                }
              />
            </SecondDivContainer>
          </StyledFlexDiv>
        ) : (
          <></>
        )}
      </section>
      <span className="description-text grey">
        Tip: by selecting an organization or sales office, you will receive
        notifications for any new building created in that organization or sales
        office.
      </span>
      {/* </section> */}
    </Modal>
  )
}

IntelligentServicesNotificationsSettingsDialog.propTypes = {}
