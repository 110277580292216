import Select from 'src/components/legacy/components/select/select'
import React, { useEffect, useMemo } from 'react'
import { setSelectedLanguage, setUserInfo, selectUserInfo } from 'src/redux/slicers/appData'
import { useSelector, useDispatch } from 'react-redux'
import { clearTranslations } from 'src/redux/slicers/translationSlice'
import { useMutation, useQuery } from 'src/hooks/APIHooks'
import { UPDATE_OWN_SELF } from '../Header/components/queries'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { GET_USER_BY_ID } from 'src/graphql/userQueries'
import { parseUserData } from 'src/parseUserData'
import { setMomentLocale } from "src/utils/MomentUtils"
const LANGUAGES:any = [
    {key: 'en', value: 'English'},
    {key: 'af', value: 'Afrikaans'},
    {key: 'sq', value: 'Albanian'},
    {key: 'am', value: 'Amharic'},
    {key: 'ar', value: 'Arabic'},
    {key: 'hy', value: 'Armenian'},
    {key: 'az', value: 'Azerbaijani'},
    {key: 'bn', value: 'Bengali'},
    {key: 'bs', value: 'Bosnian'},
    {key: 'bg', value: 'Bulgarian'},
    {key: 'ca', value: 'Catalan'},
    {key: 'zh', value: 'Chinese (Simplified)'},
    {key: 'zh-TW', value: 'Chinese (Traditional)'},
    {key: 'hr', value: 'Croatian'},
    {key: 'cs', value: 'Czech'},
    {key: 'da', value: 'Danish'},
    {key: 'fa-AF', value: 'Dari'},
    {key: 'nl', value: 'Dutch'},
    //{key: 'en', value: 'English'},
    {key: 'et', value: 'Estonian'},
    {key: 'fa', value: 'Farsi (Persian)'},
    {key: 'tl', value: 'Filipino, Tagalog'},
    {key: 'fi', value: 'Finnish'},
    {key: 'fr', value: 'French'},
    {key: 'fr-CA', value: 'French (Canada)'},
    {key: 'ka', value: 'Georgian'},
    {key: 'de', value: 'German'},
    {key: 'el', value: 'Greek'},
    {key: 'gu', value: 'Gujarati'},
    {key: 'ht', value: 'Haitian Creole'},
    {key: 'ha', value: 'Hausa'},
    {key: 'he', value: 'Hebrew'},
    {key: 'hi', value: 'Hindi'},
    {key: 'hu', value: 'Hungarian'},
    {key: 'is', value: 'Icelandic'},
    {key: 'id', value: 'Indonesian'},
    {key: 'ga', value: 'Irish'},
    {key: 'it', value: 'Italian'},
    {key: 'ja', value: 'Japanese'},
    {key: 'kn', value: 'Kannada'},
    {key: 'kk', value: 'Kazakh'},
    {key: 'ko', value: 'Korean'},
    {key: 'lv', value: 'Latvian'},
    {key: 'lt', value: 'Lithuanian'},
    {key: 'mk', value: 'Macedonian'},
    {key: 'ms', value: 'Malay'},
    {key: 'ml', value: 'Malayalam'},
    {key: 'mt', value: 'Maltese'},
    {key: 'mr', value: 'Marathi'},
    {key: 'mn', value: 'Mongolian'},
    {key: 'no', value: 'Norwegian (Bokmål)'},
    {key: 'ps', value: 'Pashto'},
    {key: 'pl', value: 'Polish'},
    {key: 'pt', value: 'Portuguese (Brazil)'},
    {key: 'pt-PT', value: 'Portuguese (Portugal)'},
    {key: 'pa', value: 'Punjabi'},
    {key: 'ro', value: 'Romanian'},
    {key: 'ru', value: 'Russian'},
    {key: 'sr', value: 'Serbian'},
    {key: 'si', value: 'Sinhala'},
    {key: 'sk', value: 'Slovak'},
    {key: 'sl', value: 'Slovenian'},
    {key: 'so', value: 'Somali'},
    {key: 'es', value: 'Spanish'},
    {key: 'es-MX', value: 'Spanish (Mexico)'},
    {key: 'sw', value: 'Swahili'},
    {key: 'sv', value: 'Swedish'},
    {key: 'ta', value: 'Tamil'},
    {key: 'te', value: 'Telugu'},
    {key: 'th', value: 'Thai'},
    {key: 'tr', value: 'Turkish'},
    {key: 'uk', value: 'Ukrainian'},
    {key: 'ur', value: 'Urdu'},
    {key: 'uz', value: 'Uzbek'},
    {key: 'vi', value: 'Vietnamese'},
    {key: 'cy', value: 'Welsh'}
]


const LanguagePicker = ({hpFooterStyle = ""}) =>  {
    const dispatch = useDispatch()
    const changeLanguage = async(language) => {
        await setMomentLocale(language)
        dispatch(setSelectedLanguage(language))
        localStorage.setItem("selectLanguage", language)
    };
    const { user: cognitoUser } = useAuthenticator()

    const { refetch: refetchUser } = useQuery({
        query: GET_USER_BY_ID,
        variables: { id: cognitoUser?.attributes?.["custom:userId"] || '' },
        disableInitialLoad: true,
        onSuccess: (rawUserInfo) => {
          const data = parseUserData(rawUserInfo)
          dispatch(
            setUserInfo({
              data
            })
          )
        },
        onError: (error) => {
          console.log(error)
        },
        errorPolicy: 'ignore',
        dataPath: 'data.getUser'
      })

    const { onSubmit: updateOwnUser } = useMutation({
        query: UPDATE_OWN_SELF,
        dataPath: 'data',
        onSuccess: (data) => {
          refetchUser({ id: cognitoUser?.attributes?.["custom:userId"] || '' })
        }
      })
    
    const profileSetttings = JSON.parse(useSelector(selectUserInfo).profileSettings)
    const userData = useSelector(selectUserInfo)
    const language =  profileSetttings !== null ? (profileSetttings.language != "" ? profileSetttings.language : "en"): "en"
    useEffect(()=> {
        dispatch(clearTranslations())
        changeLanguage(language)
    }, [language])
    const languageDesc = useMemo(() => {
        const selectedLanguage = LANGUAGES.find((lan) => (lan.key === language))
        return selectedLanguage?.value || ""
    },[language, profileSetttings])
    return (
        <div className={`${hpFooterStyle}`}>
            <Select
            dropDownType="top"
            options={LANGUAGES}
            onChange={(language:any) => {
                const data = {...userData, profileSettings: {...profileSetttings, language:language} }
                updateOwnUser({requestBody: JSON.stringify(data)})
                // dispatch(clearTranslations())
                // changeLanguage(language)
            }}
            selectedItem={languageDesc} 
            isUserdefinedOptions={true} />
        </div>
    )
}

export default React.memo(LanguagePicker)
