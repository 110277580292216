import {
  findNearestIndex,
  newDateString
} from '../helper'
import { isEmpty } from 'ramda'
import { updateScale } from './Scale/update'

// Legend Interaction - Will update update
export const legendInteractedUpdate = (setState) => (payload) => {
  const { selectedLegendItem } = payload || {}

  setState((state) => {
    const axes = state?.axes?.find(
      (obj: any) => obj?.key === (selectedLegendItem?.legendObject?.type === 'plotLine' ? selectedLegendItem?.legendObject?.axisKey : selectedLegendItem?.legendObject?.yAxisKey) 
    )

    let newState = {
      ...state
    }

    const hiddenObj = {
      ...(newState?.internalLegend?.hiddenLegends || {})
    }

    if (!selectedLegendItem?.disabled) {
      if (newState?.internalLegend?.hiddenLegends) {
        if (!(newState?.internalLegend?.hiddenLegends?.[selectedLegendItem?.legendObject?.id])) {
          hiddenObj[selectedLegendItem?.legendObject?.id] = true
        }
      }
    } else {
      if (hiddenObj?.[selectedLegendItem?.legendObject?.id]) {
        delete hiddenObj[selectedLegendItem?.legendObject?.id]
      }
    }

    newState = {
      ...newState,
      internalLegend: {
        isInternal: true,
        hiddenLegends: hiddenObj
      }
    }

    if (axes) {
      const obj = {
        [axes?.key]: axes
      }
      const scaleState = updateScale(newState, obj, null, true)
      newState = {
        ...newState,
        ...scaleState,
      }
    }
    newState = {
      ...newState,
      [`internalLegendT`]: newDateString(),
    }

    return newState
  })
}

export const updateMousePosition = (setState) => (payload) => {
  // Work in progress
  setState((state) => {
    const mousePosition = timeseriesTooltipHelper(state, payload)

    return {
      ...state,
      [`mousePositionT`]: newDateString(),
      mousePosition: mousePosition
    }
  })
}

export const updateTooltipDetails = (setState) => (payload) => {
  setState((state) => {
    return {
      ...state,
      [`tooltipDetailsT`]: newDateString(),
      tooltipDetails: payload
    }
  })
}

export const updateChartInstanceID = (setState) => (payload) => {
  setState((state) => {
    return {
      ...state,
      chartInstanceId: payload,
      [`chartInstanceIdT`]: newDateString()
    }
  })
}

export const updateSVGDimensions = (setState) => (payload) => {
  const { svgDimensions } = payload || {}
  setState((state) => {
    return {
      ...state,
      [`svgDimensionsT`]: newDateString(),
      svgDimensions: svgDimensions
    }
  })
}

export const updateSeriesTypes = (state: any = {}, types = []) => {
  const newTypes = types?.map((obj: any) => {
    return {
      ...obj,
      lastUpdatedAt: new Date().toDateString()
    }
  })
  const newObj = {
    ...state,
    seriesTypes: newTypes,
    [`seriesTypesT`]: newDateString()
  }
  return newObj
}

export const updateSeriesData = (state: any = {}, payload: any = {}) => {
  const { data = {}, dataLastUpdatedAt = '' } = payload || {}
  return {
    ...state,
    [`seriesDataT`]: newDateString(),
    [`dataLastUpdatedAtT`]: newDateString(),
    seriesData: data,
    dataLastUpdatedAt: dataLastUpdatedAt
  }
}

const timeseriesTooltipHelper = (state, payload) => {
  const { coordinates = {}, canvas = '' } = payload || {}
  let mousePosition: any = {}
  const canvasDetails = state?.canvasDetails?.[`${canvas}`]
  if (Boolean(payload)) {
    const crntCanvas = state.canvas?.canvasDetails?.[canvas]?.tooltip
    // const toolTipType = crntCanvas?.type || "x" // Not yet implemented
    const canvasXKey = Object.keys(state.axes || {})
      ?.filter((key: string) => key?.includes('x'))
      ?.find((key: string) => {
        const cPos = state?.axes?.[key]?.canvasPosition

        if (canvas === '0') {
          if (cPos === '0' || cPos === undefined) {
            return true
          } else {
            return false
          }
        } else {
          return cPos === canvas
        }
      })
    const canvasXScale = state?.generatedScales?.[canvasXKey]
    const canvasXScaleDetails = state?.scales?.[canvasXKey]

    const nearestIndex = findNearestIndex(
      coordinates.x,
      canvasDetails?.width,
      canvasXScaleDetails?.categories?.length
    )

    const currentItem = canvasXScaleDetails?.categories?.[nearestIndex]
    const roundedX =
      Math.round(
        canvasXScale?.gScale(
          canvasXScaleDetails?.props?.type === 'scaleTime'
            ? new Date(currentItem)
            : currentItem
        )
      ) +
      (canvasXScaleDetails?.props?.type === 'scaleBand'
        ? canvasXScale?.gScale?.bandwidth() / 2
        : 0)
    mousePosition = isEmpty(payload)
      ? {}
      : {
          ...(payload || {}),
          index: nearestIndex,
          currentItem: currentItem,
          refLineV: crntCanvas?.refLineV,
          width: canvasDetails?.width,
          height: canvasDetails?.height,
          roundX: roundedX
        }
  } else {
    mousePosition = {}
  }
  return mousePosition
}

