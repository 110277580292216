import { useDispatch, useSelector } from 'react-redux'
import { useMatches } from 'react-router-dom'
import { useQuery } from 'src/hooks/APIHooks'
import { selectBuilding } from 'src/redux/slicers/buildingPicker'
import { SEARCH_BUILDINGS } from 'src/components/buildingNavigation/graphql'
import { useEffect, useState, type ReactNode } from 'react'
import { DenaliRouteHandle } from '../Sidebar/MainNavigation'
import { refreshTokenFromSelection, selectUiMode } from 'src/redux/slicers/appData'
import { Loading } from '../Table/Loading'
import { UiMode } from 'src/redux/types/AppTypes'

type RouteTokenWrapperProps = {
  children: ReactNode
}

export const RouteTokenWrapper = ({ children }: RouteTokenWrapperProps) => {
  const selectedFromRedux = useSelector(selectBuilding)
  const matches = useMatches()
  const dispatch = useDispatch()
  const uiMode = useSelector(selectUiMode)

  const { refetch: fetchBuildings } = getBuildingQuery()
  const navigationProps = getNavigationProps(matches)
  const isTokenNeeded = checkIsTokenNeeded(navigationProps, uiMode)

  const [isLoading, setIsLoading] = useState(isTokenNeeded)

  useEffect(() => {
    if (!isTokenNeeded) return setIsLoading(false)

    // If we don't have a selection yet, the building picker is still loading the fallbacks
    if (!selectedFromRedux) return
    // skipTokensWhileLoading is set to true when another update is coming quickly after to prevent 
    // a race condition in the Cognito token refresh
    if (selectedFromRedux?.skipTokensWhileLoading === true) return
    // Anything besides org/building is not supported in the new UI.
    // Building picker is responsible for replacing it with an org/building selection.
    // For example, saved sales office is replaced. 
    if (
      (selectedFromRedux?.type !== 'organization')
      && (selectedFromRedux?.type !== 'location')
    ) return

    refreshTokenFromSelection(
      selectedFromRedux,
      dispatch,
      fetchBuildings,
      navigationProps?.claims,
      navigationProps?.updateTokenOrg,
      navigationProps?.updateTokenOrgBuildings,
      false
    ).then(() => {
      setIsLoading(false)
    })
  },
   // When wrapped in <RouteUrlParamWrapper> using key= a rerender is triggered on building change and searchParams is not needed as a dependency.
   // If both are used, two token calls are made.
  []
)

  return (
    <div data-testid="RouteTokenWrapper">
      {isLoading && <Loading />}
      {!isLoading && children}
    </div>
  )
}

const getBuildingQuery = () =>
  useQuery({
    query: SEARCH_BUILDINGS,
    disableInitialLoad: true,
    dataPath: 'data.searchBuildings.items'
  })

const getNavigationProps = (matches) => {
  if (!matches) return
  const config = matches?.[matches.length - 1]?.handle as DenaliRouteHandle
  return config?.page?.navigationProps
}

const checkIsTokenNeeded = (navigationProps, uiMode:UiMode): boolean => {
  // Old UI always lets the old picker control the tokens
  if(uiMode !== UiMode.denali) return false

  // If we're using the old picker, do not load tokens before rendering the route, the old picker will
  if (navigationProps?.denaliBuildingPicker !== true) return false

  // With the new picker, these may or may not be present and require new tokens with these values
  if (navigationProps?.claims?.length > 0) return true
  if (navigationProps?.updateTokenOrg === true) return true
  if (navigationProps?.updateTokenOrgBuildings === true) return true

  // If new tokens aren't required, let the page render immediately
  return false
}
