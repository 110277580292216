import { useMemo, useRef } from 'react'

import './styles.scss'
import { Tooltip } from '../../../chart-library/CommonComponents/Tooltip'
import { useOptions } from './useOptions'
import Axes from './Axes'
import BaseWrapper from '../../../chart-library/CommonComponents/BaseWrapper'
import Svg from '../../../chart-library/CommonComponents/Svg'
import { clipUUID } from '../../../chart-library/CommonComponents/Clip'
import Series from './Series'
import {
  Legend,
  LegendProvider
} from '../../../chart-library/CommonComponents/Legend'

export default function WhatWeSaved({ chartData, chartGoalData }) {
  const eventName = useMemo(() => clipUUID('consultation-stacked-bar'), [])

  const options = useOptions(chartData, chartGoalData)

  // provide boundary of tooltip to position properly
  const svgRef: any = useRef(null)

  return (
    <BaseWrapper options={options} className="what-we-saved-chart" data-testid="what-we-saved-chart">
      <LegendProvider>
        <Svg ref={svgRef}>
          <Axes />
          <Series
            tooltipEventName={eventName}
            savingsData={chartGoalData}
            series={chartData}
          />
        </Svg>
        <Legend className="what-we-saved-chart-legend" />
      </LegendProvider>
      <Tooltip listenEventName={eventName} boundary={svgRef} />
    </BaseWrapper>
  )
}
