import styled from 'styled-components'
import COLORS from 'src/components/layouts/colors.json'

export const ToolBarWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  border-bottom: 4px solid ${COLORS.TBA_DARKER_GREY};
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.4rem;
`
export const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const WidgetLink = styled.a`
  padding: 0.25rem 1rem;
  border: 1px solid ${COLORS.TBA_LIGHT_GREY};
  color: ${COLORS.TBA_DARKER_GREY};
  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  span {
    font-weight: bold;
    text-decoration: underline;
  }
`
export const WidgetsLink = styled.a`
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  span {
    font-weight: bold;
    text-decoration: underline;
  }
`

export const IconButton = styled.button`
  background-color: transparent;
  border: 0;
  font-size: 18px;
  color: ${COLORS.TBA_DARKER_GREY};
  .icon-shared {
    font-size: 28px;
    top: 3px;
    position: relative;
  }
  :disabled {
    opacity: 1;
  }
  .default {
    color: ${COLORS.GREEN};
  }
`

export const DashboardTitle = styled.h1`
  font-size: 21px;
  max-width: 60%;
  word-break: break-all;
  line-height: 1.5rem;
  button {
    margin-left: 10px;
  }
`
export const IconButtons = styled.button<{ isGrey: boolean }>`
  width: 40px;
  font-size: 25px;
  ${(props) => (props?.isGrey ? 'color:#666' : '')};
  border: none;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between
`
export const BuildingNavigationWrapper = styled.div`
min-width: 330px !important;
`
export const IconWrapper = styled.div`
align-self: flex-end
`
export const WidgetListWrappers = styled.div`
  height: 70vh;
  overflow: auto;
  overscroll-behavior-x: contain;
  .action{
    cursor: pointer;
    text-decoration: underline;
  }
  .spinner{
		height: 100px;
		font-size: 100px;
  }
  .loader{
    display: inline-block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200px;
    height: 100px;
    margin: auto;
    text-align: center;
  }
  tbody td:nth-child(3) {
    width: 50px;
    text-align: center;
    overflow:visible;
  }

  & .custom-component {
    .data-span {
      margin-left: 5px;
    }
  }
`

export const StyledText = styled.p`
  margin-top: 0px !important;
  padding-left: 35px !important;
  :before {
    padding-right: 10px;
  }
`
