import Container from 'src/components/Container'
import {StyledContainer,FormSubHeader,
  StyledButtonsContent,
  StyledTagContainer,
  InputField,
  MinInputField,
  } from './styles'
import Content from 'src/components/Content'
import PageHeader from 'src/components/pageHeaderNew/header'
import { Button } from 'src/components/inputs/button'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {validate} from './formValidator.js'
import { useQuery } from 'src/hooks/APIHooks'
import {  useNavigate } from "react-router-dom"
import { pages } from 'src/pages/pages.js'
import { useMutation } from '../../../hooks/APIHooks'
import { ACTIONS } from 'src/constants'
import AttachFiles from './AttachFiles'
import { SelectrixWrapper } from 'src/components/legacy/components/selectrix-wrapper'
import TextAreaInput from 'src/components/legacy/components/modal-textarea-input'
import Label from "src/components/legacy/components/label/label"
import Checkbox from 'src/denali-ui/components/Checkbox'
import moment from 'moment'
import {UPDATE_CONTRACT,UPDATE_OFFERING, CREATE_CONTRACT, 
  CREATE_OFFERING, 
  GET_OFFERINGS_BY_BUILDING_func,SEARCH_BUILDINGS } from './queries'
  import {GET_BUILDINGDETAILS_BY_ID_func} from "../queries"
import { getSearchParams } from 'src/common/helperFunctions.js'
import ModalDatePickerInput from 'src/components/legacy/components/modal-datepicker-input'
import {
  filterContractPeriods,
  filterOfferings,
  getDefautContractPeriod,
  getDefautInitialContractPeriod,
  getExpirationDateByContractPeriod,
  isOfferingDisabled,
  getOfferingDetailsBySubscriptionName,
  STATUS_TO_SAVE,
  ACCEPTED_FILE_TYPES,
  hasCMSA
} from "src/pages/contracts-and-offerings/common/contracts-and-offerings"
import{useConfig} from "src/pages/contracts-and-offerings/common/config"
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import { useTranslation } from 'react-i18next'
import {
  utcTimeEndOfTheDay,
  utcTimeStartOfTheDay
} from 'src/components/legacy/common/time-helpers'
import Tooltip from 'src/components/legacy/components/tooltip/tooltip'
import { Block } from 'src/components/layouts'
import Selectrix from 'src/denali-ui/components/Selectrix'
import TraneTheme from 'src/denali-ui/components/ThemeProvider'
import {getUniqueItems} from "src/common/helperFunctions"
import { UPDATE_BUILDING } from 'src/pages/organizationManagement/createOrganization/queries/queries'
import { CREATE_ATTACHMENT,CREATE_ENTRY_WITH_ATTACHMENT,
  DELETE_ATTACHMENT,UPDATE_ATTACHMENT } from '../queries'
  import { useSelector } from 'react-redux'
  import { selectUserInfo } from 'src/redux/slicers/appData'
  import {valueWithinMinMax} from "../editActive/helpers";

export const CreateContract = ({initialValues = null,actionMode}) => {
  const {organizationId} = getSearchParams()
  const navigate = useNavigate()
  const [mode, setMode] = useState(actionMode || ACTIONS.ADD)
  const {offerings:offeringsConfig,contracts:contractsConfig} = useConfig()
  const [doneSaving, setDoneSaving] = useState(false)
  // const [type, setType] = useState(paramType)
  const [offeringDDLData, setOfferingDDLData] = useState([])
  const [next,isNext] = useState(true)
  const [confirm, setConfirm] = useState(null)
  const [dialogTypes,setDialogTypes] = useState(null)
  const user = useSelector(selectUserInfo)
  const [isCreateSuccess, setIsCreateSuccess] = useState(false)
  useEffect(() => {
    if (doneSaving) {
      navigate(pages["Contracts & Offerings"].href)
    }
  }, [doneSaving])
  const [t] = useTranslation()



  useEffect(() => {
    if(offeringDDLData?.length > 0 && !next){
      const updateBuildingsWithCRMIDs = values?.buildingsWithCRMIDs?.map(m => {
        const crmIdValue = offeringDDLData?.find(({ key }) => key === m?.buildingId)?.crmCustId ?? ""
        return ({
          ...m,
          crmId: crmIdValue,
          isCRMIdEmpty: crmIdValue && (crmIdValue && crmIdValue !== '0') ? true : false
        })
      }) ?? []
      formik.setFieldValue("buildingsWithCRMIDs",updateBuildingsWithCRMIDs)
    }
  }, [offeringDDLData, next])
  

  const { onSubmit: createContract, loading:isLoadingCreateContract } = useMutation({
    query: CREATE_CONTRACT,
    dataPath: 'data.createContract'
  })

  const { onSubmit: updateContract, loading:isLoadingUpdateContract } = useMutation({
    query: UPDATE_CONTRACT,
    dataPath: 'data.updateContract'
  })

  const { onSubmit: updateOffering,  loading: isLoadingUpdateOffering } = useMutation({
    query: UPDATE_OFFERING,
    dataPath: 'data.updateOffering'
  })



  const { onSubmit: createOffering, loading: isLoadingCreateOffering } = useMutation({
    query: CREATE_OFFERING,
    dataPath: 'data.createOffering'
  })

  const {refetch:refetchOfferingsByBuilding,  loading: isLoadingRefetchOfferings } = useQuery({
    query:null
  })

  const {refetch:refetchBuildingsByIds} = useQuery({
    query:null
  })


  const { onSubmit: updateBuilding } = useMutation({
    query: UPDATE_BUILDING
  })

  const combinedLoading = 
    isLoadingCreateContract || isLoadingUpdateContract || isLoadingUpdateOffering || isLoadingCreateOffering || isLoadingRefetchOfferings
  

  useEffect(() => {
    if(mode === ACTIONS.EDIT && initialValues){
      refetchBuildingsByIds(null,null, GET_BUILDINGDETAILS_BY_ID_func(values.buildingIds)).then(res => {
        if(res?.data && !res.data.errors){
        const buildings = Object.values(res.data)
        

        setOfferingDDLData(state  => 
          [...state,
        ...buildings.reduce((acc:[any],b:any):[any] => {
          const data =  {
            key:b.id,
            label:b.name,
            crmCustId:b.crmCustId,
            address:b.address
          }
          if(!(state.some(building => building.key === b.id))){
          acc.push(data)
          }
          return acc;
        }
       ,[] ) as any[]
      ]
        )


    }
        })
      }
    },[mode,initialValues]
    
    )

    window.moment = moment;

  const contractInput = {
    buildingIds: [],
    buildingCount: 0,
    numberOfDevices: 0,
    offerings: [],
    startDate: moment().format("MM/DD/YYYY"),
    length: getDefautInitialContractPeriod(),
    serviceContractNumber: null,
    pactContractNumber: null,
    mechanicalServiceAgreementNumber: null,
    coachingServicesValue:null,
    totalBaselineReportCount:null,
    supportingNotes: "",
    accountManager: null,
    additionalRecipients: "",
    termsAccepted: false,
    businessStream: "Service",
    activateForLaterDate : false,
    additionalContractNumbers: null,
    analyticsStartDate: moment().format("MM/DD/YYYY"),
    endDate: moment().add(1,'y').format("MM/DD/YYYY"),
    billingSalesOfficeCode: null,
    primaryBuilding: null,
    attachments:[]
  }

  const getCurrentAccountId = mode => mode === ACTIONS.EDIT ? values.accountId : organizationId 


  const { onSubmit: createAttachmentMutation } = useMutation({
    query: CREATE_ATTACHMENT,
    refreshTokenAccountVariable:"input.buildingId"
  })

  const { onSubmit: deleteAttachmentMutation } = useMutation({
    query: DELETE_ATTACHMENT
  })

  const { onSubmit: updateAttachmentMutation } = useMutation({
    query: UPDATE_ATTACHMENT
  })

  const { onSubmit: createEntryWithAttachmentMutation } = useMutation({
    query: CREATE_ENTRY_WITH_ATTACHMENT
  })

  // useEffect(() => {
  //   if (salesOfficeId && !utilityCompanyData) {
  //     refetchUtilityCompanyData({id: salesOfficeId}).then(res => {
  //       setUtilityCompanyDDLData([...utilityCompanyDDLData, ...res?.map(e => ({label: e.name, key: e.id})) || []])
  //     })
  //   }
  // }, [salesOfficeId])

  // useEffect(() => {
  //   //placeholder for edit
  //   const callFunc = async () => {

  //   }
  //   if (id && !rateStructureData) {
  //     callFunc()
  //   }
  // }, [id])


  const isOfferingsOrBuildingsUpdated = ({
    offeringDetails,
    buildingIds,
    offerings
  }) => {
    if(mode !== ACTIONS.EDIT){
      return false
    }
    let _isOfferingsOrBuildingsUpdated = false
    const existingOfferings = offeringDetails.reduce((acc,offeringDetail) => {
      !acc["buildingIds"].includes(offeringDetail.buildingId) && acc["buildingIds"].push(offeringDetail.buildingId)
      !acc["offeringIds"].includes(offeringDetail.name) && acc["offeringIds"].push(offeringDetail.name)
      return acc
    },{buildingIds:[],offeringIds:[]})
    if(!(existingOfferings.buildingIds.length === buildingIds.length)
      ||
      !(existingOfferings.buildingIds.every(id => buildingIds.includes(id)))
      ){
        _isOfferingsOrBuildingsUpdated = true
      }
      if(!(existingOfferings.offeringIds.length === offerings.length)
        ||
        !(existingOfferings.offeringIds.every(id => offerings.includes(id)))
        ){
          _isOfferingsOrBuildingsUpdated = true
        }
        return _isOfferingsOrBuildingsUpdated
     }

  const onCloseDialog = field => {
    setConfirm(null)
    setDialogTypes({
      [field] : null
    })
    if (isCreateSuccess) {
      setDoneSaving(true)
    }
	}


  const formik = useFormik({
    initialValues: initialValues ? {...initialValues,
      newOfferingsToBuildinsMap:[...initialValues.offeringsToBuildinsMap]} 
      : contractInput,
    enableReinitialize: true,
    validate,
    validateOnChange:true,
    validateOnBlur:true,
    onSubmit: async (values: any) => {
      if (mode === ACTIONS.ADD) {
        const {data : existingOfferingsOfSelectedBuildingsData} = 
        await refetchOfferingsByBuilding(null,null, GET_OFFERINGS_BY_BUILDING_func(values.buildingIds))        

        const existingOfferingsOfSelectedBuildings:any = Object.values(existingOfferingsOfSelectedBuildingsData).reduce((acc:any,off:any) => 
        {
          const data = off.items.map((_off:any) =>  ({name : _off.name,
            buildingId: _off.buildingId
          })
          )
          acc = acc.concat(data)
         return acc
        }
          ,[]) 

          const duplicateOfferingsforBuildings = values.newOfferingsToBuildinsMap.reduce((acc, value) => {
            const currentBuildingOfferings = existingOfferingsOfSelectedBuildings.filter((off: any) => off.buildingId === value.buildingId)
            const duplicates = currentBuildingOfferings.reduce((dupAcc,off) => {
              if (!['CS', 'BR'].includes(off?.name)){
             if( off.name === value.name ||   isOfferingDisabled(currentBuildingOfferings.map(off => off.name), value.name)){
             !(dupAcc.some(a => a.name === value.name && a.buildingId === value.buildingId)) && dupAcc.push(value)
              return dupAcc
             }
            }
            return dupAcc
              
            },[])
              acc = duplicates?.length > 0 ? acc.concat(
                duplicates
              ) : acc;
            return acc
          }, [])
        if (duplicateOfferingsforBuildings?.length > 0) {
          const errorText = <>
            Duplicate active offering found for the following locations:
            <ul >
              {duplicateOfferingsforBuildings?.map(
                duplicateOfferingsforBuilding =>
                (<li key={duplicateOfferingsforBuilding.buildingId}>{`${offeringDDLData.find(off => off.key === duplicateOfferingsforBuilding.buildingId)?.label} - ${duplicateOfferingsforBuilding.name}`}</li>)
                )
              }
                
            </ul>
            Please review the building and offering selections on Step 1.
          </>
          setDialogTypes({
            error: {
              text: errorText
            },
            onCloseDialog: () => () => setDialogTypes(null)
          })
          return;
        }

        // Update Building CRM IDs Value
        const updatedCRMIdBuildings = values?.buildingsWithCRMIDs?.filter(f => f?.isCRMIDUpdated) ?? []
        for (const building of updatedCRMIdBuildings) {
          await updateBuilding({ input: { id: building?.buildingId, crmCustId: building.crmId } })
        }
      }

      let contractAction = createContract,
        offeringAction = createOffering;
        const inputData = {
          ...( mode === ACTIONS.EDIT ? {id:values.contractId}  : {userId:user.id}),
          accountId: getCurrentAccountId(mode),
          deviceCount: parseInt(values.numberOfDevices),
          buildingCount: parseInt(values.buildingCount),
          stream: values.businessStream,
          serviceNumber: values.serviceContractNumber,
          notes: values.supportingNotes,
          accountMgr: values.accountManager,
          receipients: values.additionalRecipients === "" ? undefined : values.additionalRecipients?.split(","),
          status: STATUS_TO_SAVE.REQUESTED,
          termsAccepted: values.termsAccepted,
          ...( mode === ACTIONS.ADD ? {analyticsStartDate: utcTimeStartOfTheDay(values.startDate)}  : {}),
          startDate: utcTimeStartOfTheDay(values.startDate),
          endDate: utcTimeEndOfTheDay(getExpirationDateByContractPeriod({contractStartDate:values.startDate,
            contractPeriod : values.length,
            expDate:values.expirationDate
          })),
          billingSalesOfficeCode: values.billingSalesOfficeCode,
          coachingServicesValue: values.coachingServicesValue,
          csTermsAccepted: values.csTermsAccepted,
          primaryBuilding: values.primaryBuilding,
          totalBaselineReportCount: values.totalBaselineReportCount,
          additionalContractNumbers:JSON.stringify({
            mechanicalServiceAgreementNumber : values.mechanicalServiceAgreementNumber,
            pactContractNumber: values.pactContractNumber
          })

      }
      if (mode === ACTIONS.EDIT) {
        contractAction = updateContract
        offeringAction = updateOffering

       }
        await contractAction({input: inputData}).then(async res => {  
       
          if(!res.error){
            setIsCreateSuccess(true)
            setDialogTypes({
              notification: {
                title: "Success",
                text: 'Your request for activation has been sent to Trane Intelligent Services to verify the contract and bill your sales office. You will receive a notification when the request has been processed.'
              }
            })
            const saveAttachements  = () => values.attachments?.length > 0  ? values.attachments
            .filter((a) => !a.id)
            .map((a) =>
              createAttachmentMutation({
                input: {
                  attachedAt: new Date(a.timestamp).toISOString(),
                  buildingId: getCurrentAccountId(mode),
                  contractId:res.id,
                  description: a.description,
                  name: a.fileName,
                  title: a.title,
                  sizeInBytes: a.fileSize,
                  userId: values.currentUserId,
                  type: a.type
                }
              })
              ) : []

            const updateAttachements  = () => mode !== ACTIONS.EDIT ? [] : values.attachments
            .filter((a) => a.id)
            .reduce((acc,value) => {
              const existingAttachmnent  = values.originalAttachments.find(a => a.id === value.id)
              if(existingAttachmnent?.description !== value.description)
             acc.push( updateAttachmentMutation({
                input: {
                  description: value.description,
                  id:value.id
                }
              })
             )
             return acc;
            },[]
            )

            const deleteAttachements  = () => mode !== ACTIONS.EDIT ? [] : values.originalAttachments
            .filter((oa) => !(values.attachments.find(a => a.id === oa.id)))
            .map(attachment  => deleteAttachmentMutation({
              input: {
                id:attachment.id
              }
            }))

          const offeringsToSave = []
          const {offeringDetails,offerings,buildingIds,newOfferingsToBuildinsMap,offeringsToBuildinsMap} = values
          const _isOfferingsOrBuildingsUpdated = isOfferingsOrBuildingsUpdated({
            offeringDetails,offerings,buildingIds
          })
          if(  mode === ACTIONS.EDIT  && _isOfferingsOrBuildingsUpdated){
            const offeringsToDelete = offeringsToBuildinsMap.filter(mapping => 
              !(newOfferingsToBuildinsMap.some(newMapping => newMapping.buildingId === mapping.buildingId &&
                newMapping.name === mapping.name)
                )
              );
            const offeringsToUpdate = offeringsToBuildinsMap.filter(mapping => 
              (newOfferingsToBuildinsMap.some(newMapping => newMapping.buildingId === mapping.buildingId &&
                newMapping.name === mapping.name)
                )
              )?.map(offering => ({
                id:offering.id,
                startDate: utcTimeStartOfTheDay(values.startDate),
                endDate: utcTimeEndOfTheDay(getExpirationDateByContractPeriod({contractStartDate:values.startDate,
                  contractPeriod : values.length,
                  expDate:values.expirationDate
                }))
              }))
            const offeringsToCreate = newOfferingsToBuildinsMap.filter(newMapping => 
              !(offeringsToBuildinsMap.some(mapping => newMapping.buildingId === mapping.buildingId &&
                newMapping.name === mapping.name)
                )
              )?.map(offering => {
                const offeringDetails = getOfferingDetailsBySubscriptionName(contractsConfig,offeringsConfig,offering.name)
                return {
                buildingId: offering.buildingId,
                accountId: getCurrentAccountId(mode),
                contractId: res.id,
                name:  offeringDetails?.Name || offering.name,
                code: offeringDetails?.Code || offering.name,
                subscriptionCode:offering.name,
                description: offeringDetails?.Description || "",
                analyticsStartDate: utcTimeStartOfTheDay(values.startDate),
                startDate: utcTimeStartOfTheDay(values.startDate),
                endDate: utcTimeEndOfTheDay(getExpirationDateByContractPeriod({contractStartDate:values.startDate,
                  contractPeriod : values.length,
                  expDate:values.expirationDate
                })),
                gracePeriod: offeringDetails?.GracePeriod || 30, //Unsure on this
                status: STATUS_TO_SAVE.REQUESTED,
                type: "Building",
                typeId: "NA"
              }
            }
              )
            Promise.all([ 
              ...(offeringsToCreate.map(async e => await createOffering({input: e}))),
              ...(offeringsToDelete.map(offeringDelete => updateOffering({input: {id: offeringDelete.id,status:STATUS_TO_SAVE.DELETED}}))),
              ...(offeringsToUpdate.map(offeringInput => updateOffering({input:offeringInput}))),
            ]
            ).then(pres => {
              Promise.all([...saveAttachements(),
                ...updateAttachements(),
                ...deleteAttachements()
              ]).then((response) =>
              Promise.all(
                response.map((r) => {
                  return r?.data?.createAttachment?.id
                    ? createEntryWithAttachmentMutation({
                        input: JSON.stringify({
                          modelType: 'Contract',
                          attachments: {
                            create: [
                              {
                                buildingId:
                                  r.data.createAttachment.buildingId,
                                  contractId:
                                  r.data.createAttachment.contractId,
                                name: r.data.createAttachment.name
                              }
                            ]
                          }
                        })
                      })
                    : ''
                })
              ).then(() => {
                console.log('')
              })
              )
              
            })
          } else{
          values.buildingIds.forEach(bid => {
            values?.offerings.forEach(offeringName => {
              const offeringDetails = getOfferingDetailsBySubscriptionName(contractsConfig,offeringsConfig,offeringName)
              offeringsToSave.push({
                ...(mode === ACTIONS.EDIT ? {
                  id: values.offeringDetails.find(
                    offering => offering.buildingId === bid
                      &&
                      offering.name === offeringName
                  )?.id
                } : {
                  description: offeringDetails?.Description || "",
                }),
                buildingId: bid,
                accountId: getCurrentAccountId(mode),
                contractId: res.id,
                name:  offeringDetails?.Name || offeringName,
                code: offeringDetails?.Code || offeringName,
                subscriptionCode:offeringName,
                description: offeringDetails?.Description || "",
                analyticsStartDate: utcTimeStartOfTheDay(values.startDate),
                startDate: utcTimeStartOfTheDay(values.startDate),
                endDate: utcTimeEndOfTheDay(getExpirationDateByContractPeriod({
                  contractStartDate: values.startDate,
                  contractPeriod: values.length,
                  expDate: values.expirationDate
                })),
                gracePeriod: offeringDetails?.GracePeriod || 30, //Unsure on this
                status: STATUS_TO_SAVE.REQUESTED,
                type: "Building",
                typeId: "NA"
              })
            })
          })
          await Promise.all(await offeringsToSave.map(async e => await offeringAction({ input: e }))).then(pres => {
            Promise.all([...saveAttachements(),
              ...updateAttachements(),
              ...deleteAttachements()
            ]).then((response) =>
            Promise.all(
              response.map((r) => {
                return r?.data?.createAttachment?.id
                  ? createEntryWithAttachmentMutation({
                      input: JSON.stringify({
                        modelType: 'Contract',
                        attachments: {
                          create: [
                            {
                              buildingId:
                                  r.data.createAttachment.buildingId,
                                  contractId:
                                  r.data.createAttachment.contractId,
                                name: r.data.createAttachment.name
                            }
                          ]
                        }
                      })
                    })
                  : ''
              })
            ).then(() => {
              console.log('')
            })
            )
            
          })
        }
    }})
      }
      })

  const { values, errors,validateForm ,setTouched,touched, dirty} = formik
  
 const  onBuildinghangeSelect = () => {
    return (e) => {
      setTouched({...touched,"buildingIds": true })
      const newBuildingIds = e.target.value
      const updateBuildingsWithCRMIDs = newBuildingIds?.map(m => {
        const crmIdValue = offeringDDLData?.find(({ key }) => key === m)?.crmCustId ?? ""
        return ({
          buildingId: m,
          crmId: crmIdValue,
          isCRMIdEmpty: crmIdValue ? true : false,
          isCRMIDUpdated: false
        })
      }) ?? []
      let newOfferingsToBuildinsMap = [];
      newBuildingIds?.forEach(buildingId => {
        const mappings = values.offerings.map(name => ({
          name,
          buildingId
        }))
        newOfferingsToBuildinsMap = newOfferingsToBuildinsMap.concat(mappings)
      }
      )
      formik.setValues(values => ({
        ...values, "buildingIds": newBuildingIds,
        "buildingsWithCRMIDs":updateBuildingsWithCRMIDs,
        "buildingCount": newBuildingIds.length,
        "newOfferingsToBuildinsMap": newOfferingsToBuildinsMap
      }))
    }
  }


  // const SelectedBuildingList = value => {
  //   return <BottomList>
  //   {value.map((c, i) => (
  //     <BottomListItem key={`close-${i}`}>
  //       <IconSVG
  //         onClick={() => {
  //           onBuildinghangeSelect()({target:{value}})
  //         }}
  //         name="close-circle"
  //         color={colors.TBA_DARKER_GREY}
  //         margin="2px 4px 0px 0px"
  //         width="14px"
  //         height="14px"
  //       />
  //       <BottomText>
  //         <span>
  //           <b>{offeringDDLData.find(data => data.key === c)?.label}</b>
  //         </span>
  //       </BottomText>
  //     </BottomListItem>
  //   ))}
  // </BottomList>
  // }

  const getBuildingsQueryResponseMap = (values) => (response) => {

    setOfferingDDLData(state  => 
      [...state,
    ...response.reduce((acc:[any],b:any):[any] => {
      const data =  {
        key:b.id,
        label:b.name,
        crmCustId:b.crmCustId,
        address:b.address
      }
      if(!(state.some(building => building.key === b.id))){
      acc.push(data)
      }
      return acc;
    }
   ,[] ) as any[]
  ]
    )
  return response && Array.isArray(response) ?  response.map((e, i) =>  ({
        searchableValue: e.name,
        value: e.id,
        children: e.name,
        selected: values.buildingIds.includes(e.id)
      })
    ) : []
}



  const onDeleteAttachment = (fileId, fileName) => {
      formik.setFieldValue(
        'attachments',
        values.attachments.filter(
          (attachment) => !(attachment.fileName === fileName)
        )
      )
    }


  const showConfirm = (fileId, fileName) => {
    setConfirm({
      title: t('notes:DeleteFile'),
      text: t('notes:DeleteFileConfirmation', { fileName }),
      className: 'confirm-delete-dialog',
      confirmText: t('common:confirmText'),
      cancelText: t('common:cancelText'),
      handleConfirm: () => {
        setConfirm(null)
        onDeleteAttachment(fileId, fileName)
      }
    })
  }

  return (
    <TraneTheme>
    <StyledContainer>
      {next ? <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <PageHeader
            pageTitle={"Request Activation"}
          />
          <span>
            For additional resources on the Digital Services ordering process and how to order the correct offerings, please visit <a href="https://hub.tranetechnologies.com/community/services/intelligent-services" target="_blank" rel="noreferrer">The Hub</a>.
          </span>
        </Content>
        <Content
          xlColumn={9}
          lColumn={9}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {<h3>Step 1 of 2 : Contract Information</h3>}

        </Content>


        <Content
          xlColumn={9}
          lColumn={9}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
            <span className={touched.buildingIds && errors.buildingIds && "error"}>
              Choose Buildings
            </span>
          } />
          <div>
            {values.buildingIds?.length > 0 &&
          <div>
            <StyledTagContainer>
              <SelectrixWrapper
                className={'location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select'}
                placeholder={""}
                placeHolderInside={true}
                defaultValue={getUniqueItems(values.buildingIds)}
                inputPlaceholder="Find a building..."
                multiple={true}
                name="buildingIds"
                onChange={e => {
                  setTouched({...touched,"buildingIds": true })
                  const newBuildingIds = e.map(e => e.key)
                  const updateBuildingsWithCRMIDs = e?.map(m => {
                    const crmIdValue = offeringDDLData?.find(({ key }) => key === m?.key)?.crmCustId ?? ""
                    return ({
                      buildingId: m?.key,
                      crmId: crmIdValue,
                      isCRMIdEmpty: crmIdValue ? true : false,
                      isCRMIDUpdated: false
                    })
                  }) ?? []
                  let newOfferingsToBuildinsMap = [];
                  e.map(e => e.key).forEach(buildingId => {
                    const mappings = values.offerings.map(name => ({
                      name,
                      buildingId
                    }))
                    newOfferingsToBuildinsMap = newOfferingsToBuildinsMap.concat(mappings)
                  }
                  )
                  formik.setValues(values => ({
                    ...values, "buildingIds": newBuildingIds,
                    "buildingsWithCRMIDs":updateBuildingsWithCRMIDs,
                    "buildingCount": e.length,
                    "newOfferingsToBuildinsMap": newOfferingsToBuildinsMap
                  }))
                }
                }
                options={offeringDDLData}
                searchable={true}
                searchBoxInside={true}
                searchIndex={false}
                stayOpen={true}
              // tags={true}
              // validationWithRegisterCase={true}
              />
              </StyledTagContainer>
            </div>
}
          <Selectrix
          allowCheckAll = {false}
          onChange={onBuildinghangeSelect()}
          value = {values.buildingIds}
          query={SEARCH_BUILDINGS}
          //customRenderValue = {SelectedBuildingList}
          graphQLInputs = {{filter : { accountId: { eq: mode === ACTIONS.EDIT ? initialValues?.accountId  : organizationId } }}}
          fullWidth={true}
          multiple={true}
          containerWidth={true}
          getQueryResponseMap={getBuildingsQueryResponseMap(values)}
          selectClassName={'orgSelectInput'}
          placeholder={"-- Select One or More --"}
          searchPlaceHolder="Type to find a Building..."
           showNoResultsMessage={false}
        />
        {touched.buildingIds && errors.buildingIds && <p className="error"><>{errors.buildingIds}</></p>}
          </div>
          </>
            : <><label>
              {/* {`Utility Company: `} */}
            </label>
              {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
          }
          <Label text={
            <span>
              {`Device Count = ${values.numberOfDevices}`}
            </span>
          } />
        </Content>
        <Content
          xlColumn={3}
          lColumn={3}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <><Label text={
            <span>
              # SC/BCU/BAS*
            </span>
          } />
            <div>
              <input type="number"  value={values.numberOfDevices} onChange={e => {
                const value = valueWithinMinMax(e.target.value)
                formik.setFieldValue("numberOfDevices", value)
              }} />
            </div>
          </>
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <><Label text={
            <>
            <span>
              Building Count
            </span>
            <Tooltip
              horizontalPosition="left"
              content="This is the number of buildings that the customer will be invoiced for. Building count will default to the number of buildings selected above. Trane connect buildings are one to one with Command center Tiles. If you have multiple buildings set up in Command Center as a single tile or multiple Command Center tiles for one building, you can override the building count to the correct number."
              SpanOrDiv="span"
              width="200px"
              size="12px"
            >
            <span className="icon icon-infocircle" />
            </Tooltip>
            </>
          } />
            <div>
              <input type="number"   value={values.buildingCount} onChange={e => {
                const value = valueWithinMinMax(e.target.value)
                formik.setFieldValue("buildingCount", value)
              }} />
            </div>
          </>
        </Content>
        <Content
          xlColumn={9}
          lColumn={9}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
            <span className={touched.offerings && errors.offerings && "error"}>
              Offering(s)*
            </span>
          } />
            <div>
              <SelectrixWrapper
                className={'location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select'}
                placeHolderInside={true}
                defaultValue={values.offerings}
                multiple={true}
                name="offerings"
                onBlur={() => setTouched({...touched,"offerings": true })}
                onChange={e => {
                  const newOfferingNames = e.map(e => e.key)
                  let newOfferingsToBuildinsMap = [];
                  newOfferingNames.forEach(newOffering => {
                    const mappings = values.buildingIds.map(buildingId => ({
                      name: newOffering,
                      buildingId
                    }))
                    newOfferingsToBuildinsMap = newOfferingsToBuildinsMap.concat(mappings)
                  }
                  )
                  let defaultContractPeriodKey = values.length
                  for (const offering of e) {
                     defaultContractPeriodKey = getDefautContractPeriod(contractsConfig,offering.key,values.length)
                  }
                  formik.setValues({
                    ...values,
                    "offerings": newOfferingNames,
                    "length": defaultContractPeriodKey,
                    "newOfferingsToBuildinsMap": newOfferingsToBuildinsMap
                  })
                }
                }
                options={filterOfferings(contractsConfig,values.offerings)}
                searchable={false}
                searchBoxInside={false}
                searchIndex={false}
                stayOpen={true}
              />
              {touched.offerings && errors.offerings && <p className="error"><>{errors.offerings}</></p>}
            </div>
          </>
            : <><label>
              {/* {`Utility Company: `} */}
            </label>
              {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
          }
        </Content>
        <Content
        xlColumn={3}
        lColumn={3}
        mColumn={12}
        sColumn={12}
        border="none"
      >
      {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
              <span>
                Business Stream
              </span>
            }/>
        <div>
          <SelectrixWrapper
						// appendTagPrompt="Create Utility Company “{searchtext}”"
						className={`location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select`}
						placeholder={"-Select One or More-"}
						placeHolderInside={false}
            defaultValue={values.businessStream}
						// inputPlaceholder="Find a building..."
						multiple={false}
						onChange={e => {
              formik.setValues({
                ...values,
                "businessStream": e.key,
                "serviceContractNumber":null
              })
            }
            }
						options={[{key: "Service", label: "Service"}, {key: "Contracting", label: "Contracting"}, {key: "Equipment", label: "Equipment"}, {key: "Independent Office", label: "Independent Office"}]}
						searchable={false}
						searchBoxInside={false}
						searchIndex={false}
						stayOpen={false}
						// tags={true}
						// validationWithRegisterCase={true}
					/>
          {/* {<p className="error"><>{errors.utilityId && "Utility Company is required."}</></p> } */}
          </div>
          </>
            : <><label>
              {/* {`Utility Company: `} */}
            </label>
              {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
          }
        </Content>


        <Content
          xlColumn={4}
          lColumn={4}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
            <span>
              Contract Start Date*
            </span>
          } />
            <div>
              <ModalDatePickerInput
                date={moment(values.startDate).format("MM/DD/YYYY")}
                onChange={e => {
                  formik.setFieldValue("startDate", moment(e).format("MM/DD/YYYY"))
                }}
                // isRequired={false}
                // labelText={`${t('opportunities:CreatedDate')}*`}
                hasError={Boolean(errors.startDate)}
                errorMessage={errors.startDate}
              />
            </div>
          </>
            : <><label>
              {/* {`Utility Company: `} */}
            </label>
              {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
          }
        </Content>
        <Content
          xlColumn={4}
          lColumn={4}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
            <span>
              Length*
            </span>
          } />
            <div>
              <SelectrixWrapper
                // appendTagPrompt="Create Utility Company “{searchtext}”"
                className={`location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select`}
                // placeholder={"-Select One or More-"}
                placeHolderInside={false}
                defaultValue={values.length}
                // inputPlaceholder="Find a building..."
                multiple={false}
                onChange={e => {
                  formik.setFieldValue("length", e.key)
                 if (/custom/i.test(e.key)){
                  formik.setFieldValue("expirationDate", moment(values.startDate).add(1,'y'))
                 }     
                }
                }
                options={filterContractPeriods(values.offerings)}
                searchable={false}
                searchBoxInside={false}
                searchIndex={false}
                stayOpen={false}
              // tags={true}
              // validationWithRegisterCase={true}
              />
              {/* {<p className="error"><>{errors.utilityId && "Utility Company is required."}</></p> } */}
            </div>
          </>
            : <><label>
              {/* {`Utility Company: `} */}
            </label>
              {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
            </>
          }
        </Content>
        <Content
          xlColumn={4}
          lColumn={4}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          {/custom/i.test(values.length) && <><Label text={
            <span>
              Expiration Date*
            </span>
          } />
            <div>
              <ModalDatePickerInput
                date={moment(values.expirationDate).format("MM/DD/YYYY")}
                onChange={e => {
                  formik.setFieldValue("expirationDate", moment(e).format("MM/DD/YYYY"))
                }}
                // isRequired={false}
                // labelText={`${t('opportunities:CreatedDate')}*`}
                hasError={Boolean(errors.expirationDate)}
                errorMessage={errors.expirationDate}
              />
            </div>
          </>}
        </Content>
        {/independent/i.test(values.businessStream) ? <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <><Label text={
            <span className={touched.serviceContractNumber && errors.serviceContractNumber && "error"}>
              Purchase Order Number*
            </span>
          } />
            <div>
              <input maxLength={50} value={values.serviceContractNumber || ""}
              onBlur={() => setTouched({...touched,"serviceContractNumber": true })}
              onChange={e => {
                formik.setFieldValue("serviceContractNumber", e.target.value)
              }} />
            </div>
          </>
          {touched.serviceContractNumber && errors.serviceContractNumber && <p className="error"><>{errors.serviceContractNumber}</></p>}
        </Content> :
          !(values.offerings?.length === 1 && hasCMSA(values.offerings)) ?
            <Content
              xlColumn={5}
              lColumn={5}
              mColumn={12}
              sColumn={12}
              border="none"
            >
              <><Label text={
                <span className={touched.serviceContractNumber && errors.serviceContractNumber && "error"}>
                  Service Contract Number *
                </span>
              } />
                <div>
                  <input  maxLength={50} value={values.serviceContractNumber || ""} 
                  onBlur={() => setTouched({...touched,"serviceContractNumber": true })}
                  onChange={e => {
                    formik.setFieldValue("serviceContractNumber", e.target.value)
                  }} />
                </div>
                {touched.serviceContractNumber && errors.serviceContractNumber && <p className="error"><>{errors.serviceContractNumber}</></p>}
              </></Content>
            : null}
        {(/independent/i.test(values.businessStream) || !(values.offerings?.length === 1 && hasCMSA(values.offerings))) &&
          <Content
            xlColumn={7}
            lColumn={7}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            Please enter the appropriate Service Contract Number or Project ID (CAP Pro only). The form will not be approved until validated. For Independent offices, Purchase Order document is required below.
          </Content>}
        {values.offerings.includes("CAPxM&V") &&
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <><Label text={
              <span className={touched.pactContractNumber && errors.pactContractNumber && "error"}>
                PACT Contract Number *
              </span>
            } />
              <div>
                <input maxLength={50} value={values.pactContractNumber || ""}
                onBlur={() => setTouched({...touched,"pactContractNumber": true })}
                onChange={e => {
                  formik.setFieldValue("pactContractNumber", e.target.value)
                }} />
              </div>
              {touched.pactContractNumber && errors.pactContractNumber && <p className="error"><>{errors.pactContractNumber}</></p>}
            </></Content>}
        {(values.offerings?.length === 1 && hasCMSA(values.offerings)) &&
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <><Label text={
              <span className={touched.mechanicalServiceAgreementNumber && errors.mechanicalServiceAgreementNumber && "error"}>
                Mechanical Service Agreement Number *
              </span>
            } />
              <MinInputField>
                <input value={values.mechanicalServiceAgreementNumber || ""}
                className="input-field"
                onBlur={() => setTouched({...touched,"mechanicalServiceAgreementNumber": true })}
                onChange={e => {
                  formik.setFieldValue("mechanicalServiceAgreementNumber", e.target.value)
                }} />
              </MinInputField>
              {touched.mechanicalServiceAgreementNumber && errors.mechanicalServiceAgreementNumber && <p className="error"><>{errors.mechanicalServiceAgreementNumber}</></p>}
            </> </Content>}
        {
          (values.offerings.includes("BR") || values.offerings.includes("CS")) &&
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <FormSubHeader>Offering Details</FormSubHeader>
          </Content>
        }

        {values.offerings.includes("BR") &&
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <><Label text={
              <span className={touched.totalBaselineReportCount && errors.totalBaselineReportCount && "error"}>
                Total Baseline Report Count *
              </span>
            } />
              <div>
                <input type="number"   value={values.totalBaselineReportCount || ""} 
                onBlur={() => setTouched({...touched,"totalBaselineReportCount": true })}
                onChange={e => {
                  const value = valueWithinMinMax(e.target.value)
                  formik.setFieldValue("totalBaselineReportCount", value)
                }} />
              </div>
              {touched.totalBaselineReportCount && errors.totalBaselineReportCount && <p className="error"><>{errors.totalBaselineReportCount}</></p>}
            </></Content>}


        {values.offerings.includes("CS") &&
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <><Label text={
              <span className={touched.coachingServicesValue && errors.coachingServicesValue && "error"}>
                Coaching Service Value *
              </span>
            } />
              <div>
                <input maxLength={10} value={values.coachingServicesValue || ""}
                 onBlur={() => setTouched({...touched,"coachingServicesValue": true })}
                onChange={e => {
                  formik.setFieldValue("coachingServicesValue", e.target.value)
                }} />
              </div>
              {touched.coachingServicesValue && errors.coachingServicesValue && <p className="error"><>{errors.coachingServicesValue}</></p>}
            </>
            <Block padding={"4px 0 0 0"}>
            <>
            <Checkbox
              checked={values.csTermsAccepted}
              onClick={() => {
                formik.setFieldValue("csTermsAccepted", !values.csTermsAccepted)
              }}
              testName={`cs_accepted_checkbox`}
              label={<span className={touched.csTermsAccepted && errors.csTermsAccepted && "error"}>
                I Confirm that I have conducted a coaching services strategy call prior to submitting the offering request.
              </span>
              }
            />
          </>
          </Block>
            
            </Content>
        }
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <AttachFiles 
          setFieldValue ={formik.setFieldValue}
          values={formik.values}
          organizationId={getCurrentAccountId(mode)}
          onDeleteAttachment={(id, name) => showConfirm(id, name)}
          proposalFileTypes={ACCEPTED_FILE_TYPES}
          />
        </Content>

        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            {
              [ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><TextAreaInput
                labelText={"Supporting Notes"}
                defaultValue={values?.supportingNotes}
                maxLength={2000}
                name="Supporting Notes"
                onChange={({
                  target: { value }
                }) => {
                  formik.setFieldValue("supportingNotes", value)
                }}
                isRequired={false}
              />2000 characters max</> : values?.supportingNotes
            }
          </Content>

          {<p className="error"><>{errors.seasons && "Selected seasons must add up to one complete year, or, \"No Seasonal Variation\" must be selected."}</></p>}
          <br />
        </Content>

        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <><Label text={
            <span className={errors.accountManager && "error"}>
              Account Manager
            </span>
          } />
            <InputField>
              <input
                value={values.accountManager || ""}
                placeholder='Email ID'
                onChange={e => {
                  formik.setFieldValue("accountManager", e.target.value)
                }}
                className="input-field"
                />
            </InputField>
            <div>
              Email address of the person who sold the contract
            </div>
            {errors.accountManager && <p className="error"><>{errors.accountManager}</></p>}
          </>
        </Content>

        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <><Label text={
            <>
            <span>
              Billing Sales Office Code
            </span>
            <Tooltip
              horizontalPosition="left"
              content="If the contract sales office differs from the requester's default office, enter an Alternate Sales Office Code."
              SpanOrDiv="span"
              width="200px"
              size="12px"
            >
            <span className="icon icon-infocircle" />
            </Tooltip>
            </>
          } />
            <div>
              <input maxLength={10} value={values.billingSalesOfficeCode || ""}
                onChange={e => {
                  formik.setFieldValue("billingSalesOfficeCode", e.target.value)
                }} />
            </div>
          </>
        </Content>

        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <><Label text={
            <>
            <span className={errors.additionalRecipients && "error"}>
              Additional Notification Recipients
            </span>
            <Tooltip
              horizontalPosition="left"
              content="Any recipients thay you would like to receive notifications about this contract request. Emails must be from the Trane internal team."
              SpanOrDiv="span"
              width="200px"
              size="12px"
              >
              <span className="icon icon-infocircle" />
              </Tooltip>
            </>
          } />
            <InputField>
              <input value={values.additionalRecipients || ""}
                placeholder='Email ID'
                onChange={e => {
                  formik.setFieldValue("additionalRecipients", e.target.value)
                }}
                className="input-field"
                />
            </InputField>
            <div>
              Email addresses should be seperated by a comma
            </div>
            {errors.additionalRecipients && <p className="error"><>{errors.additionalRecipients}</></p>}
          </>
        </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
        >
          <>
            <Checkbox
              checked={values.termsAccepted}
              onClick={() => {
                formik.setFieldValue("termsAccepted", !values.termsAccepted)
              }}
              testName={`accepted_checkbox`}
              label={<span className={touched.termsAccepted && errors.termsAccepted && "error"}>
                I understand that by activating this offering on these buildings, the sales office associated with these building(s) will be invoiced.
              </span>
              }
            />
          </>
        </Content>
        <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} border={"none"}>
          <StyledButtonsContent>
            <Button type="secondary" onClick={() => {
              navigate(pages["Contracts & Offerings"].href)
            }}>
              Cancel
            </Button>
            <Button type="primary" onClick={async () => {
              await setTouched({...touched,
                ...(Object.keys(values).reduce((acc,val) => {
                  acc[val] =  true;
                  return acc
                },{}
                ))
              
              })
              const errors = await validateForm({...values,firstForm:true})
              if (Object.keys(errors)?.length > 0) {
                return
              }
              isNext(false)
            }}
            >
              Next
            </Button>
          </StyledButtonsContent>
        </Content>
      </Container> :
        <Container sColumns={12} mColumns={12} lColumns={12} xlColumns={12}>
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <PageHeader
              pageTitle={"Request Activation"}
            />
            <span>
              For additional resources on the Digital Services ordering process and how to order the correct offerings, please visit <a href="https://hub.tranetechnologies.com/community/services/intelligent-services" target="_blank" rel="noreferrer">The Hub</a>.
            </span>
          </Content>
          <Content
            xlColumn={9}
            lColumn={9}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            {<h3>Step 2 of 2 : Confirm CRM Site IDs</h3>}

          </Content>

          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            {[ACTIONS.ADD, ACTIONS.EDIT].includes(mode) ? <><Label text={
              <span className={errors.primaryBuilding && "error"}>
                Primary Building For Contract*
              </span>
            } />
              <div>
                <SelectrixWrapper
                  // appendTagPrompt="Create Utility Company “{searchtext}”"
                  className={`location selectrix-wrapper--type-autocomplete selectrix-wrapper-multi-select crm-select`}
                  placeholder={"-Select One-"}
                  placeHolderInside={true}
                  defaultValue={values.primaryBuilding}
                  inputPlaceholder="Find a building..."
                  multiple={false}
                  onChange={e => {
                    formik.setFieldValue("primaryBuilding", e.key)
                  }
                  }
                  options={offeringDDLData.filter(({ key }) => values.buildingIds.includes(key))}
                  searchable={true}
                  searchBoxInside={true}
                  searchIndex={false}
                  stayOpen={false}/>
                  {errors.primaryBuilding && <p className="error"><>{errors.primaryBuilding}</></p>}
              </div>
            </>
              : <><label>
                {/* {`Utility Company: `} */}
              </label>
                {/* &nbsp;{[]?.filter(e => e.id === values.utilityId).length && []?.filter(e => e.id === values.utilityId)[0]?.name} */}
              </>
            }
          </Content>
          {values.buildingsWithCRMIDs.map(e => {
            return <>
              <Content
                xlColumn={6}
                lColumn={6}
                mColumn={12}
                sColumn={12}
                border="none"
              >
                <div key={e?.buildingId}>
                  <span>
                    <Label text={
                      <span>
                        {offeringDDLData.find(x => x.key === e?.buildingId).label}
                      </span>
                    } />

                  </span>
                </div>
              </Content>
              <Content
                xlColumn={6}
                lColumn={6}
                mColumn={12}
                sColumn={12}
                border="none"
              >
                <div key={e?.buildingId}>
                  <Label text={
                    <span>
                      CRM Site Id*
                    </span>
                  } />
                  <input className={errors.buildingsWithCRMIDs && !e?.crmId ? "input_errro_border" : ""} id={e?.buildingId} value={e?.crmId || ""} onChange={(e) => {
                    const updateBuildingCRMIds = values?.buildingsWithCRMIDs?.map(m => {
                      if (m?.buildingId === e?.target?.id) {
                        m.crmId = e?.target?.value
                        m.isCRMIDUpdated = true
                      }
                      return m
                    })
                    formik.setFieldValue("buildingsWithCRMIDs", updateBuildingCRMIds)
                  }} disabled={e?.isCRMIdEmpty} />
                </div>
              </Content>
            </>
          })}
          
          <Content
            xlColumn={12}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
          >
            <span className="error"><>{errors.buildingsWithCRMIDs}</></span>
          </Content>
          <Content xlColumn={12} lColumn={12} mColumn={12} sColumn={12} border={"none"}>
            <StyledButtonsContent>
              <Button type="secondary"
                onClick={() => isNext(true)}
              >
                Back
              </Button>
              <Button disabled={!dirty || combinedLoading} type="primary" onClick={formik.handleSubmit}
              >
                Request Activation
              </Button>
            </StyledButtonsContent>
          </Content>
        </Container>}
        <StandardDialogs error={dialogTypes?.error} onCloseDialog = {onCloseDialog} confirm={confirm}  notification={dialogTypes?.notification}  />
    </StyledContainer>
    </TraneTheme>
  )
}
