// Default Options.

// In y Axis, hours and mins are being displayed
// So using random date (1990-01-11) to generate ticks for every 15  mins
// End developer can change this if they want.
export const yAxis15MinsTicks = [
  '1990-01-11T23:45:00',
  '1990-01-11T23:30:00',
  '1990-01-11T23:15:00',
  '1990-01-11T23:00:00',
  '1990-01-11T22:45:00',
  '1990-01-11T22:30:00',
  '1990-01-11T22:15:00',
  '1990-01-11T22:00:00',
  '1990-01-11T21:45:00',
  '1990-01-11T21:30:00',
  '1990-01-11T21:15:00',
  '1990-01-11T21:00:00',
  '1990-01-11T20:45:00',
  '1990-01-11T20:30:00',
  '1990-01-11T20:15:00',
  '1990-01-11T20:00:00',
  '1990-01-11T19:45:00',
  '1990-01-11T19:30:00',
  '1990-01-11T19:15:00',
  '1990-01-11T19:00:00',
  '1990-01-11T18:45:00',
  '1990-01-11T18:30:00',
  '1990-01-11T18:15:00',
  '1990-01-11T18:00:00',
  '1990-01-11T17:45:00',
  '1990-01-11T17:30:00',
  '1990-01-11T17:15:00',
  '1990-01-11T17:00:00',
  '1990-01-11T16:45:00',
  '1990-01-11T16:30:00',
  '1990-01-11T16:15:00',
  '1990-01-11T16:00:00',
  '1990-01-11T15:45:00',
  '1990-01-11T15:30:00',
  '1990-01-11T15:15:00',
  '1990-01-11T15:00:00',
  '1990-01-11T14:45:00',
  '1990-01-11T14:30:00',
  '1990-01-11T14:15:00',
  '1990-01-11T14:00:00',
  '1990-01-11T13:45:00',
  '1990-01-11T13:30:00',
  '1990-01-11T13:15:00',
  '1990-01-11T13:00:00',
  '1990-01-11T12:45:00',
  '1990-01-11T12:30:00',
  '1990-01-11T12:15:00',
  '1990-01-11T12:00:00',
  '1990-01-11T11:45:00',
  '1990-01-11T11:30:00',
  '1990-01-11T11:15:00',
  '1990-01-11T11:00:00',
  '1990-01-11T10:45:00',
  '1990-01-11T10:30:00',
  '1990-01-11T10:15:00',
  '1990-01-11T10:00:00',
  '1990-01-11T09:45:00',
  '1990-01-11T09:30:00',
  '1990-01-11T09:15:00',
  '1990-01-11T09:00:00',
  '1990-01-11T08:45:00',
  '1990-01-11T08:30:00',
  '1990-01-11T08:15:00',
  '1990-01-11T08:00:00',
  '1990-01-11T07:45:00',
  '1990-01-11T07:30:00',
  '1990-01-11T07:15:00',
  '1990-01-11T07:00:00',
  '1990-01-11T06:45:00',
  '1990-01-11T06:30:00',
  '1990-01-11T06:15:00',
  '1990-01-11T06:00:00',
  '1990-01-11T05:45:00',
  '1990-01-11T05:30:00',
  '1990-01-11T05:15:00',
  '1990-01-11T05:00:00',
  '1990-01-11T04:45:00',
  '1990-01-11T04:30:00',
  '1990-01-11T04:15:00',
  '1990-01-11T04:00:00',
  '1990-01-11T03:45:00',
  '1990-01-11T03:30:00',
  '1990-01-11T03:15:00',
  '1990-01-11T03:00:00',
  '1990-01-11T02:45:00',
  '1990-01-11T02:30:00',
  '1990-01-11T02:15:00',
  '1990-01-11T02:00:00',
  '1990-01-11T01:45:00',
  '1990-01-11T01:30:00',
  '1990-01-11T01:15:00',
  '1990-01-11T01:00:00',
  '1990-01-11T00:45:00',
  '1990-01-11T00:30:00',
  '1990-01-11T00:15:00',
  '1990-01-11T00:00:00'
]

export const yAxisVisibleTicks = [
  '1990-01-11T23:45:00',
  '1990-01-11T23:00:00',
  '1990-01-11T22:00:00',
  '1990-01-11T21:00:00',
  '1990-01-11T20:00:00',
  '1990-01-11T19:00:00',
  '1990-01-11T18:00:00',
  '1990-01-11T17:00:00',
  '1990-01-11T16:00:00',
  '1990-01-11T15:00:00',
  '1990-01-11T14:00:00',
  '1990-01-11T13:00:00',
  '1990-01-11T12:00:00',
  '1990-01-11T11:00:00',
  '1990-01-11T10:00:00',
  '1990-01-11T09:00:00',
  '1990-01-11T08:00:00',
  '1990-01-11T07:00:00',
  '1990-01-11T06:00:00',
  '1990-01-11T05:00:00',
  '1990-01-11T04:00:00',
  '1990-01-11T03:00:00',
  '1990-01-11T02:00:00',
  '1990-01-11T01:00:00',
  '1990-01-11T00:00:00'
]
