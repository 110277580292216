export const GET_BUILDINGS_BY_ACCOUNT_ID = `query GET_BUILDINGS_BY_ACCOUNT_ID($id: ID!) {
    listBuildingsByAccount(accountId: $id, limit: 10000) {
      items {
        key: id
        value: name
        }
      }
    }
  `

export const ListBuildingEquipments = /* GraphQL */ `
  query listBuildingEquipments($id: ID!) {
    getBuilding(id: $id) {
      equipments(filter: { isActive: { eq: 1 } }, limit: 10000) {
        items {
          key: id
          value: name
          type
        }
      }
    }
  }
`


export const GET_EQUIPMENT_LIST = `query GET_EQUIPMENT_LIST($id: ID!, $nextToken: String) {
  searchEquipment(
    filter: {
      buildingId: {eq: $id},
      isActive: {eq: 1},
      isTIS: {eq: 1}
    },
    nextToken: $nextToken
    limit: 1000
    ){
      items {
        key: id
        value: name
        type
      }
    nextToken
    total
  }
}

`


export const GET_ACCOUNT_BY_ID = `
query getAccountById($id: ID!) {
  getAccount(id: $id) {
    id
    name
    buildings {
      items {
        id
        name
        salesOfficeName
        salesOfficeId
        salesOfficeDistrict
        salesOfficeCity
        salesOfficeCode
      }
    }
  }
}
`

export const GET_BUILDING_BY_ID = `
query GET_BUILDING_BY_ID($id: ID!) {
  getBuilding(id: $id) {
    id
    name
    accountId
    accountName
  }
}
`

export const SEARCH_BUILDINGS = `
query SEARCH_BUILDINGS($filter: SearchableBuildingFilterInput
  $sort: [SearchableBuildingSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBuildingAggregationInput]) {
  searchBuildings(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      accountId
      accountName
      name
    }
    nextToken
  }
}
`

export const SEARCH_BUILDING_EQUIPMENT = `
  query SEARCH_EQUIPMENT_BY_BUILDING(
    $filter: SearchableEquipmentFilterInput
    $sort: [SearchableEquipmentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEquipmentAggregationInput]
    ) {
    searchEquipment(filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
      ) {
      total
      items {
        id
        name
      }
      nextToken
    }
  }
`

export const GET_BUILDING_METER_TOTAL_EQUIPMENT = `query GET_BUILDING_METER_TOTAL_EQUIPMENT($body: AWSJSON) {
  getBuildingTotalMeterEquipment(body: $body)
}
`
