import {
  scaleBand,
  scaleLinear,
  scaleOrdinal,
  scaleQuantize,
  scaleTime,
  scaleUtc
} from 'd3-scale'
import { assocPath, clone, isNil, isNotNil, pathOr } from 'ramda'
import { AxisDiM } from '../Utils/defaults'
import { BaseGrid } from './BaseGrid'
import { scaleCacheModel } from './model'

const scaleFns: ScaleFns = {
  scaleBand,
  scaleLinear,
  scaleOrdinal,
  scaleTime,
  scaleUtc,
  scaleQuantize
}

const categoryTypes: any = {
  scaleBand: true,
  scaleOrdinal: true
}

const valueTypes: any = {
  scaleLinear: true,
  scaleTime: true,
  scaleUtc: true
}

export default class BaseScale extends BaseGrid {
  private scaleCache: BaseCache<ScaleDetail> = {}

  private activeScaleKey: BaseActiveKey = {}

  private scaleAxes: Axes = {
    xAxis: [],
    yAxis: []
  }

  private scaleChangelog: BaseLog = {}

  protected getScale(
    diM: AxisDimention,
    index: number
  ): ScaleDetail | undefined {
    return this.scaleCache[diM + index]
  }

  protected getAllScale(): BaseCache<ScaleDetail> {
    return this.scaleCache
  }

  protected getScaleChangeLog(
    diM: AxisDimention,
    index: number
  ): string | undefined {
    return this.scaleChangelog[diM + index]
  }

  protected getAllScaleChangeLog(): BaseLog {
    return this.scaleChangelog
  }

  protected resetScaleChangeLog() {
    this.scaleChangelog = {}
  }

  protected prepareScales(axesFromOptions: Axes) {
    const isEqual = this.isConfigEqual(this.scaleAxes, axesFromOptions)

    // console.log("prepare scales:", isEqual);
    if (!isEqual) {
      this.scaleAxes = clone(axesFromOptions)
      this.initScales()
    } else {
      this.scaleChangelog = {}
    }
    // console.log("Base scale : scalecache -", this.scaleCache);
  }

  protected handleScaleResize() {
    const reCheckPosition = (axis: InputAxis, index: number) => {
      const key: string = axis.diM + index
      this.checkRange(key, axis.diM, axis.gridIndex)
    }

    this.scaleAxes.xAxis.forEach(reCheckPosition)
    this.scaleAxes.yAxis.forEach(reCheckPosition)
  }

  private initScales() {
    this.activeScaleKey = {}

    this.checkAxis(this.scaleAxes.xAxis)
    this.checkAxis(this.scaleAxes.yAxis)
    this.refineScales()
  }

  private checkAxis(axes: any[] = []) {
    axes.forEach((axis, index) => {
      const key: string = axis.diM + index

      this.activeScaleKey[key] = true

      this.checkScaleType(key, axis.type)
      this.checkDomain(key, axis.type, axis.min, axis.max, axis.data)
      this.checkRange(key, axis.diM, axis.gridIndex)
    })
  }

  private checkScaleType(key: string, scaleType: AxisType) {
    if (this.scaleCache[key]) {
      if (this.getPropFromScaleCache(key, 'type') === scaleType) return
    }

    const scaleFn = this.createScale(scaleType)

    if (scaleFn) {
      this.setPropToScaleCache(key, 'type', scaleType)
      this.setPropToScaleCache(key, 'fn', scaleFn)
      this.logScale(key, 'changed')
    }
  }

  private createScale(scaleType: AxisType) {
    const scaleFn = scaleFns[scaleType]
    if (scaleFn) {
      return scaleFns[scaleType]()
    }

    return null
  }

  private checkDomain(
    key: string,
    type: string | null = null,
    min: number | null = null,
    max: number | null = null,
    categoryData: any[]
  ) {
    let domain = null

    if (categoryTypes[type as any]) {
      const data: any = this.getPropFromScaleCache(key, 'data')
      const isEqual = this.isConfigEqual(data, categoryData)

      // console.log("domain type :", type, isEqual, categoryData, data);
      if (!isEqual) {
        domain = categoryData

        this.setPropToScaleCache(key, 'data', categoryData)
      }
    } else if (valueTypes[type as any]) {
      // Non category types

      const scaleMin = this.getPropFromScaleCache(key, 'min')
      const scaleMax = this.getPropFromScaleCache(key, 'max')
      const isEqual = this.isConfigEqual([min, max], [scaleMin, scaleMax])
      if (!isEqual) {
        domain = [min || 0, max || 1]

        this.setPropToScaleCache(key, 'min', min)
        this.setPropToScaleCache(key, 'max', max)
      }
    }

    const scale: any = this.getPropFromScaleCache(key, 'fn')
    if (domain && scale) {
      // Create new scale and replace old one, otherwise referene won't change
      const scaleFn = this.createScale(type as any)
      scaleFn.domain(domain)

      this.setPropToScaleCache(key, 'fn', scaleFn)

      this.logScale(key, 'changed')
    }
  }

  protected setDomainBySeries(
    diM: AxisDimention,
    index: number,
    domain: any[],
    force?: boolean
  ) {
    if (isNil(domain) || !Array.isArray(domain)) {
      // console.log('domain value from setDomainBySeries is invalid. pls check')
      return
    }

    const scale = this.getScale(diM, index)

    if (!scale) {
      // console.log('No scale for given axis. pls check')
      return
    }

    if (
      !force &&
      ((isNotNil(scale.min) && isNotNil(scale.max)) || scale.data.length)
    ) {
      // console.log(
      //   'domian (min & max or cateogry data) values are configured by setOptions.'
      // )
      return
    }

    const scaleFn = this.createScale(scale.type as any)

    scaleFn.domain(domain)

    if (scale.fn.range) scaleFn.range(scale.fn.range())

    scale.fn = scaleFn

    this.logScale(diM + index, 'changed')
  }

  private checkRange(key: string, diM: AxisDimention, gridIndex: number) {
    const scale: any = this.getPropFromScaleCache(key, 'fn')

    if (!scale) {
      // console.log('No scale found to set range.')
      return
    }

    const grid = this.getGrid(gridIndex)

    if (!grid) {
      // console.log('No grid found to set width and height of scale range.')
      return
    }

    // Range always will be relative (0,0) to parent. so it is assigned with [0, gridWdith] or [gridHeight, 0]
    // If we set absoulte position as range, it may not fit for grouping elements which has own offset values
    const range = AxisDiM.x === diM ? [0, grid.width] : [grid.height, 0]

    const isEqual = this.isConfigEqual(scale.range(), range)
    if (isEqual) return

    scale.range(range)
    this.logScale(key, 'changed')
  }

  private refineScales() {
    const keys = Object.keys(this.scaleCache)
    keys.forEach((key) => {
      if (this.activeScaleKey[key]) return

      delete this.scaleCache[key]
      this.logScale(key, 'removed')
    })
  }

  private setPropToScaleCache(
    axisKey: string,
    propKey: string,
    propValue: any
  ) {
    if (!this.scaleCache[axisKey]) {
      this.scaleCache = assocPath([axisKey], scaleCacheModel(), this.scaleCache)
    }

    this.scaleCache = assocPath([axisKey, propKey], propValue, this.scaleCache)
  }

  private getPropFromScaleCache(axisKey: string, propKey: string) {
    return pathOr(null, [axisKey, propKey], this.scaleCache)
  }

  private logScale(axisKey: string, actionType: string) {
    this.scaleChangelog[axisKey] = actionType
  }

  protected resetScale() {
    this.scaleCache = {}

    this.activeScaleKey = {}

    this.scaleAxes = {
      xAxis: [],
      yAxis: []
    }

    this.scaleChangelog = {}
  }
}
