import PropTypes from 'prop-types'
import { StyledCheckbox } from './style'
import { useTheme } from '@aws-amplify/ui-react'

export const Checkbox = ({
  onChange,
  checked,
  className,
  disabled,
  testName
}) => {
  const { tokens } = useTheme()

  return (
    <StyledCheckbox
      data-testid={`${testName || 'tc'}_checkbox`}
      theme={tokens}
      className={className}
      checked={checked}
      disabled={disabled}
      type="checkbox"
      onChange={onChange}
    />
  )
}

export const LabeledCheckbox = ({
  onChange,
  checked,
  className,
  wrapperClassName,
  disabled,
  label
}) => {
  const { tokens } = useTheme()
  return (
    <span className={wrapperClassName}>
      <StyledCheckbox
        theme={tokens}
        className={className}
        checked={checked}
        disabled={disabled}
        type="checkbox"
        onChange={onChange}
      />
      <span>{label}</span>
    </span>
  )
}

const checkboxPropTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  theme: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

Checkbox.defaultProps = {
  checked: false,
  theme: { bg: 'transparent' },
  className: '',
  disabled: ''
}

Checkbox.propTypes = {
  ...checkboxPropTypes
}

LabeledCheckbox.defaultProps = {
  checked: false,
  theme: { bg: 'transparent' },
  className: '',
  disabled: ''
}

LabeledCheckbox.propTypes = {
  ...checkboxPropTypes,
  label: PropTypes.any,
  wrapperClassName: PropTypes.any
}
