import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

export const BASE_LANGUAGE = 'en-US'

const loadPath = (lng, namespace) => {
  let path = `${process.env.PUBLIC_URL}/translations/locales/${lng}/trane-connect/${namespace[0]}.json` //`/locales/common/${lng}/translation.json`;

  /**
   * Add additional case stmts for new locale sub directories.
   * This allows for splitting up translation files into namespaces, namespace can
   * then be attached to a specific component or accessed through notation.
   */
  switch (namespace[0]) {
    case 'automated-tests':
      path = `${process.env.PUBLIC_URL}/translations/locales/${lng}/${namespace[0]}.json`
      break
    default:
      break
  }

  return path
}

const initLanguageSetup = () => {
  i18n
    // load translation using xhr
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use({
      type: 'postProcessor',
      name: 'trim',
      process(value) {
        return value && value.trim ? value.trim() : value
      }
    })
    // init i18next
    .init({
      fallbackLng: localStorage.getItem('locale')
        ? localStorage.getItem('locale')
        : BASE_LANGUAGE,
      returnEmptyString: false,
      debug: false,
      interpolation: {
        // not needed for react as it escapes by default
        escapeValue: false
      },
      // string or array of namespaces to load
      ns: [
        'login',
        'automated-test-settings',
        'automated-test-suppressions',
        'automated-test-suggestions',
        'errors',
        'common',
        'connect-device',
        'dashboard',
        'opportunities',
        'energy-performance',
        'equipment-setup',
        'alarm-notification',
        'building-performance',
        'grid-services',
        'facility-management',
        'building-configuration',
        'admin-tools',
        'user',
        'user-dialog',
        'users',
        'service-providers',
        'time',
        'tisobject',
        'kpi',
        'notes',
        'widget-builder',
        'welcome-dialog',
        'videos',
        'components',
        'building-evaluation',
        'widgets',
        'findings',
        'responsive-controls',
        'digital-maintenance',
        'organization-management',
        'notifications',
        'reports',
        'automated-inspections',
        'announcements-management',
        'contracts-and-offerings',
        'automated-tests'
      ],
      defaultNS: 'common',
      keySeparator: '>',
      load: 'currentOnly',
      backend: {
        // override the default path defined in backend plugin
        // second optional path is provided for Storybook purpose
        loadPath
      },
      parseMissingKeyHandler: () => '',
      postProcess: ['trim']
    })

  i18n.on('languageChanged', async (locale) => {
    //console.log(locale)
  })
}

/*
 ** Decided don't use LanguageDetector plugin as
 ** 1. We don't need usage of Local Storage as we have userData for user's preferences and it
 ** makes sense to be compatible in this case
 ** 2. LanguageDetector automatically saves detected language to local storage and if detected language
 ** is not available in app we have a bunch of 404 errors in console as it tries to download locale
 ** 3. LanguageDetector picks navigator.language, we can use full list of languages using navigator.languageS
 ** until find language which is supported
 */

export async function setCorrectLanguage() {
  const localeFromStorage = localStorage.getItem('locale')
  // If have preferred language saved in User Data or localStorage
  if (localeFromStorage) {
    if (
      LANGUAGES.get(localeFromStorage) &&
      i18n.language !== localeFromStorage
    ) {
      await changeLanguage(localeFromStorage)
    }
  } else {
    // Check if user preferred language is amoung supported ones
    // IE doesn't support window.navigator.languages so use fallback for it
    const preferredLocales = window.navigator.languages || [
      window.navigator.language
    ]
    for (let i = 0; i < preferredLocales.length; i++) {
      const curr = preferredLocales[i].split('-')[0]
      if (LANGUAGES.get(curr)) {
        await changeLanguage(curr)
        break
      }
    }
  }
}

export async function changeLanguage(locale) {
  locale = locale.replace('_', '-')
  await i18n.changeLanguage(locale)
}

// When add new languages do not forget to make updates here emerald-ui/src/common/zendesk-helper.js
export const LANGUAGES = new Map([
  [BASE_LANGUAGE, 'English'],
  ['es-LA', 'Español (Latin America)'],
  ['fr-CA', 'Français (Canada)'],
  ['pt-BR', 'Português (Brazil)'],
  ['es-ES', 'Español (Spain)'],
  ['fr-FR', 'Français']
])

export const LANGUAGES_ON_PRODUCTION = [...LANGUAGES.keys()]

export function formatLanguage(id) {
  id = id.replace('_', '-')
  return LANGUAGES.get(id) || id
}

export const LOCALES = new Map([
  [BASE_LANGUAGE, 'en-US'],
  ['fr-CA', 'fr-ca'],
  ['fr-FR', 'fr-fr'],
  ['es-LA', 'es-la'],
  ['es-ES', 'es-es'],
  ['pt-BR', 'pt-br']
])

export function formatLocale(id) {
  id = id.replace('_', '-')
  return LOCALES.get(id) || id
}

export default initLanguageSetup
