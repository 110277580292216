import { useEffect, useState, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'src/hooks/APIHooks'
import { Content } from 'src/components/layouts/'
import { useBreakPoint } from 'src/hooks/breakPoint'
import ContainerWrapper from 'src/components/Container'
import PageHeader from '../../components/pageHeaderNew/header'
import { PAGE_TITLE } from './constants'
import { ToolBarWrapper } from './styles.js'
import { getSearchParams } from '../../common/helperFunctions.js'
import { Block } from 'src/components/layouts'
import EngeryCostSummaryChart from './EngeryCostSummaryChart'
import { TranslateComponent } from 'src/common/translations'
import { useEnergyCostDataHook } from 'src/pages/energyCostSummary/EnergyCostDataHook'
import { EquipmentListSelector } from 'src/components/equipmentsListSelector'
import ChartSettings from 'src/components/chart-settings-sc/chart-settings'
import ChartDateControls from 'src/components/chartDateControls/chartdatecontrols'
import moment from 'moment'
import colors from 'src/components/legacy/common/colors.json'
import Icon from 'src/denali-ui/components/Icon/index'
import {
  BACKEND_DATE_FORMAT,
  TEXT_DATE_FORMAT
} from 'src/components/legacy/common/time-helpers'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import {
  EnergyCostContainerDiv,
  WarningContainer,
  HeaderResultBlock,
  ChartAreaWrapper,
  NoDataMessage,
  ChartControlContainer
} from './styles.js'
import { aggreagatorSelection } from 'src/common/chartHelperFunctions'
import { getEquipment } from './graphql'
import translate from 'src/common/translations'
import  withFeatureRestrictions from 'src/common/featureRestrictionHOC'

const stackedBarColors = {
  consumption: '#fb9a09',
  gasConsumption: '#fff10b',
  demand: '#00b9e4',
  monthlyFee: '#61ae34',
  compareConsumption: '#b06c06',
  compareDemand: '#0082a0',
  compareMonthlyFee: '#447a24',
  todateConsumption: '#eeeeee',
  todateDemand: '#acacac',
  todateMonthlyFee: '#838383'
}

const EnergyCostSummaryContainer = () => {
  const { width } = useBreakPoint()

  const [selectedEquipment, setSelectedEquipment] = useState(null)

  const [totalEnergyCost, setTotalEnergyCost] = useState(0)

  const [stackedBarData, setStackedBarData] = useState(null)

  const [meterAvailability, setMeterAvailability] = useState(true)

  const [disabledLegends, setDisabledLegends] = useState([])

  const [equipmentCreatedDate, setEquipmentCreatedDate] = useState()

  const [searchParams] = useSearchParams()

  const { buildingId, organizationId, equipment, startDate, endDate } =
    getSearchParams()

  const optionsTranslated = {
    totalEnergy: translate('Total Energy Cost'),
    data: translate('Data Available')
  }

  const [chartDates, setChartDates] = useState({
    chartStartDate: startDate
      ? startDate
      : moment()
          .subtract(12, 'months')
          .startOf('month')
          .format(BACKEND_DATE_FORMAT),
    chartEndDate: endDate
      ? endDate
      : moment()
          .subtract(1, 'months')
          .endOf('month')
          .format(BACKEND_DATE_FORMAT),
    ...(startDate ? {} : { activeRange: '1Y' }),
    dateError: false
  })

  const {
    loadingEnergyCostData,
    getEnergyCostData,
    energyCostData,
    isDataAvailable
  } = useEnergyCostDataHook()

  const { data: Equipment, refetch: refetchEquipment } = useQuery({
    query: getEquipment,
    disableInitialLoad: true,
    errorPolicy: 'all',
    dataPath: 'data.getEquipment'
  })

  useEffect(() => {
    if (selectedEquipment?.key) {
      refetchEquipment({
        id: selectedEquipment?.key
      })
    }
  }, [selectedEquipment])

  useEffect(() => {
    if (Equipment) {
      setEquipmentCreatedDate(
        moment(Equipment?.createdAt)?.format(TEXT_DATE_FORMAT)
      )
    }
  }, [Equipment])

  useEffect(() => {
    setMeterAvailability(true)
    setSelectedEquipment(null)
  }, [buildingId, organizationId, searchParams])

  const getEnergyCostFromQuery = () => {
    const request = {
      startDate: chartDates?.chartStartDate,
      endDate: chartDates?.chartEndDate,
      interval: '1m',
      buildingId,
      equipmentId: selectedEquipment?.key,
      equipmentType: selectedEquipment?.type
    }
    getEnergyCostData(request)
  }

  const findMaxBarValue = (barData) => {
    try {
      const dataLength = barData[0]?.data?.length
      const totalValuesArray = Array(dataLength).fill(0)
      for (let i = 0; i < barData?.length; i++) {
        for (let j = 0; j < barData[i].data?.length; j++) {
          totalValuesArray[j] = totalValuesArray[j] + barData[i]?.data[j]
        }
      }
      const maxValue = Math.max(...totalValuesArray)
      return maxValue
    } catch (error) {}
  }

  const formatData = (selectedItems = []) => {
    try {
      const stackedBarData = []

      const consumptionCost = energyCostData?.consumptionCost?.values || []
      const demandCost = energyCostData?.demandCost?.values || []
      const additionalCost = energyCostData?.additionalCost?.values || []
      const gasConsumption = energyCostData?.gasConsumptionCost?.values || []

      if (
        gasConsumption?.length > 0 &&
        gasConsumption?.some((x) => x?.value !== 0)
      ) {
        stackedBarData.push({
          type: 'stackedbar',
          name: 'Gas Consumption',
          color: stackedBarColors.gasConsumption,
          stackBy: 'energy-cost-monthly',
          stackOrder: 1,
          data: gasConsumption?.map((x) => Number(x?.value || 0))
        })
      }

      if (selectedEquipment?.type !== 'GAS') {
        stackedBarData.push(
          {
            type: 'stackedbar',
            name: 'Electric Monthly Fee',
            color: stackedBarColors.monthlyFee,
            stackBy: 'energy-cost-monthly',
            stackOrder: 3,
            data: additionalCost?.map((x) => Number(x?.value || 0))
          },
          {
            type: 'stackedbar',
            name: 'Electric Demand',
            color: stackedBarColors.demand,
            stackOrder: 2,
            stackBy: 'energy-cost-monthly',
            data: demandCost?.map((x) => Number(x?.value || 0))
          },
          {
            type: 'stackedbar',
            name: 'Electric Consumption',
            color: stackedBarColors.consumption,
            stackBy: 'energy-cost-monthly',
            stackOrder: 1,
            data: consumptionCost?.map((x) => Number(x?.value || 0))
          }
        )
      }

      if (selectedItems?.length === 0) {
        const totalValues = energyCostData?.totalCost?.values
        const totalEnergyConsumption =
          Number(
            aggreagatorSelection('sum', totalValues, 0, totalValues?.length)
          ).toFixed(2) || 0

        setTotalEnergyCost(totalEnergyConsumption)
      } else {
        let totalEnergyConsumption = 0
        if (!selectedItems?.includes('Electric Monthly Fee')) {
          totalEnergyConsumption +=
            Number(
              aggreagatorSelection(
                'sum',
                additionalCost,
                0,
                additionalCost?.length
              )
            ) || 0
        }

        if (!selectedItems?.includes('Electric Demand')) {
          totalEnergyConsumption +=
            Number(
              aggreagatorSelection('sum', demandCost, 0, demandCost?.length)
            ) || 0
        }

        if (!selectedItems?.includes('Electric Consumption')) {
          totalEnergyConsumption +=
            Number(
              aggreagatorSelection(
                'sum',
                consumptionCost,
                0,
                consumptionCost?.length
              )
            ) || 0
        }

        if (!selectedItems?.includes('Gas Consumption')) {
          totalEnergyConsumption +=
            Number(
              aggreagatorSelection(
                'sum',
                gasConsumption,
                0,
                gasConsumption?.length
              )
            ) || 0
        }
        setTotalEnergyCost(totalEnergyConsumption?.toFixed(2))
      }

      const stackedBarAxisData = consumptionCost?.map((x) => x?.timeStamp)

      const maxBarValue = findMaxBarValue(stackedBarData)

      setStackedBarData({
        data: stackedBarData,
        axisData: stackedBarAxisData,
        maxBarValue
      })
    } catch (error) {
      setStackedBarData(null)
    }
  }

  useEffect(() => {
    if (energyCostData) {
      formatData()
    }
  }, [energyCostData])

  useEffect(() => {
    if (chartDates?.dateError) {
      return
    }
    setStackedBarData(null)
    getEnergyCostFromQuery()
  }, [
    selectedEquipment,
    chartDates?.chartStartDate,
    chartDates?.chartEndDate,
    chartDates?.dateError,
    searchParams
  ])

  // Checks previous and current selected legends are same or not
  const checkPreviousSelectedLegends = (
    currentSelectedLegends,
    previousSelectedLegends
  ) => {
    return (
      currentSelectedLegends.length === previousSelectedLegends.length &&
      currentSelectedLegends.every(
        (value, index) => value === previousSelectedLegends[index]
      )
    )
  }

  useEffect(() => {
    formatData(disabledLegends)
  }, [disabledLegends])

  const onEquipmentChange = (equipmentDetails) => {
    setSelectedEquipment(equipmentDetails)
  }

  const getSelectedLegendItems = (items) => {
    const selectedItemNames = items?.map((x) => x?.text)
    if (!checkPreviousSelectedLegends(selectedItemNames, disabledLegends)) {
      setDisabledLegends(selectedItemNames)
    }
  }

  const onChartControlUpdate = ({
    startDate,
    endDate,
    endError,
    startError,
    activeRange
  }) => {
    setChartDates({
      chartStartDate: startDate,
      chartEndDate: endDate,
      dateError: startError || endError,
      activeRange
    })
  }

  // Called when there is no meters in the building
  const equipmentsAvailableCheck = (check) => {
    setMeterAvailability(check)
  }

  return (
    <ContainerWrapper
      sColumns={12}
      mColumns={12}
      lColumns={12}
      xlColumns={12}
      padding="0"
    >
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        border="none"
        width={width}
      >
        <PageHeader
          pageTitle={<TranslateComponent>{PAGE_TITLE}</TranslateComponent>}
          titleTools={
            <ToolBarWrapper>{/* ActionToolBar Here */}</ToolBarWrapper>
          }
        />
      </Content>
      <Content
        xlColumn={12}
        lColumn={12}
        mColumn={12}
        sColumn={12}
        width={width}
        border="none"
      >
        {buildingId ? (
          meterAvailability ? (
            <>
              <EnergyCostContainerDiv>
                <EquipmentListSelector
                  equipmentTypes={['GAS', 'ElectricMeter', 'VirtualMeter']}
                  buildingId={buildingId}
                  onChange={onEquipmentChange}
                  equipmentsAvailableCheck={equipmentsAvailableCheck}
                  defaultEquipmentId={equipment}
                  testName="energy-cost-summary"
                />
                <HeaderResultBlock>
                  <span className="title">{optionsTranslated.totalEnergy}</span>
                  <span
                    data-testid="total-energy-cost-summary-value"
                    className="value"
                  >
                    <span>${totalEnergyCost}</span>
                  </span>
                </HeaderResultBlock>
              </EnergyCostContainerDiv>
              <ChartAreaWrapper>
                <ChartControlContainer>
                  <ChartDateControls
                    testName="energy-cost-summary"
                    onUpdate={onChartControlUpdate}
                    rangesList={['3M', '6M', 'YTD', '1Y']}
                    startDate={chartDates.chartStartDate}
                    activeRange={chartDates?.activeRange}
                    endDate={chartDates.chartEndDate}
                    entity="energy-cost"
                  />
                  <div className="chart-settings">
                    <ChartSettings testName="energy-cost-summary">
                      <div className="control-group">
                        <div className="unit-picker bottom-space">
                          <ul className="energyPerformanceSummary">
                            <li>
                              <span>{optionsTranslated.data}:</span>
                              <span>{equipmentCreatedDate}</span>
                              &nbsp;-&nbsp;
                              <span>{moment().format(TEXT_DATE_FORMAT)}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </ChartSettings>
                  </div>
                </ChartControlContainer>
                {(loadingEnergyCostData || selectedEquipment === null) ? (
                  <Spinner />
                ) : energyCostData?.isDataAvailable ? (
                  <EngeryCostSummaryChart
                    startTime={chartDates?.chartStartDate}
                    endTime={chartDates?.chartEndDate}
                    data={stackedBarData?.data}
                    axisData={stackedBarData?.axisData}
                    yAxisMax={stackedBarData?.maxBarValue}
                    getSelectedItems={getSelectedLegendItems}
                  />
                ) : (
                  <NoDataMessage>
                    No data available in the selected date range.
                  </NoDataMessage>
                )}
              </ChartAreaWrapper>
            </>
          ) : (
            <NoDataMessage>
              No meters available in the selected building.
            </NoDataMessage>
          )
        ) : (
          organizationId && (
            <Block display="flex" justify="center">
              <WarningContainer>
                <Icon
                  name="Warning"
                  width="25px"
                  height="25px"
                  margin="0px 10px"
                  color={colors.RED}
                />
                <p>
                  {
                    <TranslateComponent>
                      You have chosen an organization, please specify a
                      location.
                    </TranslateComponent>
                  }
                </p>
              </WarningContainer>
            </Block>
          )
        )}
      </Content>
    </ContainerWrapper>
  )
}

export default withFeatureRestrictions(
  EnergyCostSummaryContainer
)


