import AutomatedTestSettingsContainer from './AutomatedTestSettingsContainer'
import { AutomatedTestSettingsContextProvider } from './AutomatedTestSettingsContext'

export const AutomatedTestSettings = () => {
  return (
    <AutomatedTestSettingsContextProvider>
      <AutomatedTestSettingsContainer />
    </AutomatedTestSettingsContextProvider>
  )
}
