import ElectricalDemandContainer from './ElectricalDemandContainer'
import { ElectricalDemandContextProvider } from './ElectricalDemandContextProvider'

export const ElectricalDemand = () => {
  return (
    <ElectricalDemandContextProvider>
      <ElectricalDemandContainer />
    </ElectricalDemandContextProvider>
  )
}
