export const SAVE_SUBSCRIBER = `
  mutation createNotificationSetting($input: CreateNotificationSettingInput!) {
    createNotificationSetting(input: $input) {
      id
    }
  }
`

export const DELETE_SUBSCRIBER = `
  mutation deleteNotificationSetting($input: DeleteNotificationSettingInput!) {
    deleteNotificationSetting(input: $input) {
      id
    }
  }
`

export const UPDATE_SUBSCRIBER = `
mutation updateNotificationSetting($input: UpdateNotificationSettingInput!) {
  updateNotificationSetting(input: $input) {
    id
  }
}`



