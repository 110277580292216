export const LIST_APPLICATIONS = `
query LIST_APPLICATIONS {
  listApplications {
    items {
      name
      id
    }
  }
}
`


export const LIST_ROLES_BY_APP_ID = `
query LIST_ROLES_BY_APP_ID(
  $id: ID!
) {
  listRolesByAppId(appId: $id) {
    items {
      appId
      description
      id
      allowedRoles
      name
      resourceTypes
      userTypes
      tasks
      type
      isActive
    }
  }
}
`

export const LIST_ALL_ROLES = `
query LIST_ROLES {
  listRoles(limit: 1000) {
    items {
      appId
      description
      id
      name
      allowedRoles
      resourceTypes
      userTypes
      tasks
      type
      isActive
    }
  }
}
`

export const CREATE_ROLE_PERMISSION = `
mutation CREATE_ROLE_PERMISSION(
  $input: CreateRolePermissionInput!
) {
  createRolePermission(input: $input) {
    id
  }
}
`

export const DELETE_ROLE_PERMISSION = `
mutation DELETE_ROLE_PERMISSION(
  $input: DeleteRolePermissionInput!
) {
  deleteRolePermission(input: $input) {
    id
  }
}
`

export const LIST_PERMISSIONS_BY_APP_ID = `query LIST_PERMISSIONS_BY_APP_ID(
  $id: ID!
  $filter: ModelPermissionFilterInput
  $limit: Int
  $nextToken: String
) {
  listPermissionsByAppId(appId: $id, filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      appId
      createdAt
      description
      id
      name
      type
      resourceTypes
      updatedAt
    }
    nextToken
  }
}`

export const LIST_USERTYPES = `query LIST_USERTYPES(
  $filter: ModelUserTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
    }
    nextToken
  }
}`

export const GET_ROLE_BY_ID = `query LIST_ROLE_BY_ROLE_ID(
  $id: ID!
) {
  getRole(id: $id) {
    appId
    createdAt
    description
    id
    isActive
    name
    tasks
    type
    allowedRoles
    resourceTypes
    userTypes
    updatedAt
    permissions(limit: 1000) {
      items {
        permissionID
        id
        roleID
        permission {
          id
          name
          type
          updatedAt
          resourceTypes
          description
          createdAt
          appId
        }
      }
    }
  }
}`

export const CREATE_ROLE = /* GraphQL */ `
    mutation createRole($input: CreateRoleInput!) {
        createRole(input: $input) {
            id
        }
  } 
`

export const UPDATE_ROLE = /* GraphQL */ `
    mutation updateRole($input: UpdateRoleInput!) {
        updateRole(input: $input) {
            id
        }
  } 
`

export const DELETE_ROLE = /* GraphQL */ `
    mutation deleteRole($input: DeleteRoleInput!) {
        deleteRole(input: $input) {
            id
            permissions {
              items {
                id
              }
            }
        }
  } 
`
