import { StyleSheet } from "@react-pdf/renderer"
import colors from "../../legacy/common/colors.json"

export const kpiStyles = StyleSheet.create({
	wrapper: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "row",
		justifyContent: "flex-start",
		marginTop: 10,
		marginBottom: 10,
	},
	kpiStyle: {
		justifyContent: "center",
		alignItems: "center",
		marginRight: 6,
		marginBottom: 6,
		paddingTop: 6,
		paddingBottom: 6,
		fontSize: 10,
		flex: 1,
		flexBasis: "18%",
		border: `1 solid ${colors.TBA_DARK_GREY}`,
		borderBottom: `4 solid ${colors.TBA_DARK_GREY}`,
	},
	kpiPriority: {
		fontWeight: "bold",
		display: "flex"
	},
	comfort: {
		backgroundColor: colors.COMFORT,
	},
	reliability: {
		backgroundColor: colors.RELIABILITY,
	},
	performance: {
		backgroundColor: colors.PERFORMANCE,
	},
	energyUsage: {
		backgroundColor: colors.ENERGY_USAGE,
	},
	compliance: {
		backgroundColor: colors.COMPLIANCE,
	},
	custom: {
		backgroundColor: colors.CUSTOM,
	},
	icon: {
		padding: "5px 0",
		maxHeight: 35,
	},
	uppercaseText: {
		textTransform: "uppercase",
	}
})
