import styled from 'styled-components'

export const AssignedToFieldWrapper = styled.section`
  .assigned-to {
    margin-top: 8px;
    position: relative;
    .error {
      margin-left: 8px;
      width: 200px;
    }
    .section-spinner {
      height: 20px;
      .icon {
        font-size: 14px;
      }
    }
  }
`
