import { StyleSheet } from "@react-pdf/renderer"
import colors from 'src/components/legacy/common/colors.json'

export const bigPictureStyles = StyleSheet.create({
	bigPictureWrapper: {
		marginTop: 20,
		marginBottom: 20,
	},
	consultationTexts: {
		display: "block",
		marginBottom: 20,
	},
	consultationHighLights: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between"
	},
	sHeadline: {
		marginBottom: 10,
		color: colors.GREY_7,
		fontSize: 15,
		fontWeight: "bold",
	},
	bigPictureEditorWrapper: {
		marginBottom: 10,
		fontSize: 10,
	},
	sHighlight: {
		marginLeft: 21,
		borderRadius: 0,
		borderWidth: 1,
		borderColor: colors.TBA_MEDIUM_GREY,
		fontSize: 10,
		textAlign: "center",
		width: 170,
	},
	sHighlightFirst: {
		marginLeft: 0,
	},
	sHighlightTitle: {
		minHeight: 50,
		paddingTop: 16,
		paddingBottom: 16,
		paddingLeft: 6,
		paddingRight: 6,
		backgroundColor: colors.BLUE_11,
		fontSize: 10,
		fontWeight: "bold",
		color: colors.WHITE,
	},
  sHighlightIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
	sCurrency: {
		fontSize: 20,
	},
	sHighlightContent: {
		padding: "10px 5px",
	}
})
