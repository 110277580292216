import React from 'react'
import { TranslateComponent } from 'src/common/translations'
import { Progress } from 'src/components'

const steps: any = [
  {
    name: <TranslateComponent>Create User</TranslateComponent>,
    percent: 0
  },
  {
    name: <TranslateComponent>Applications</TranslateComponent>,
    percent: 25
  },
  {
    name: <TranslateComponent>Roles</TranslateComponent>,
    percent: 50
  },
  {
    name: <TranslateComponent>Resources</TranslateComponent>,
    percent: 75
  },
  {
    name: <TranslateComponent>Resource Access</TranslateComponent>,
    percent: 100
  }
]

export const ProgressIndicatorComponent: any = ({ stage }) => (
  <Progress percent={steps[stage].percent} data={steps} />
)

export default ProgressIndicatorComponent
