const colors = {
  white: '#FFFFFF',
  tbaDarkerGrey: '#333333',
  tbaDarkGrey: '#666666',
  tbaMediumGrey: '#999999',
  tbaLightGrey: '#F5F5F5',
  red: '#FF0000',
  pink: '#FFC0CB',
  black: '#000000'
}

const mixins = {
  textButton: {
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.36px',
    textTransform: 'uppercase',
    color: colors.white
  },
  textButtonSmall: {
    fontWeight: 900,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.27px',
    textTransform: 'uppercase',
    color: colors.white
  }
  // Add other mixins here
  // ...
}

const styles = {
  buttonLink: {
    ...mixins.textButton,
    textDecoration: 'underline',
    color: colors.tbaDarkerGrey,
    '&:hover': {
      color: colors.tbaDarkGrey
    }
  },
  textFieldLabel: {
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '15px',
    letterSpacing: '0.26px'
  },
  textFieldLabelSecondary: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.36px'
  },
  textFieldLabelInactive: {
    color: colors.tbaDarkGrey
  },
  checkBoxInactive: {
    background: colors.tbaMediumGrey
  },
  textFieldLabelError: {
    color: colors.red
  },
  textFieldInput: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '18px'
  },
  textFieldInputInactive: {
    color: colors.tbaDarkGrey,
    backgroundColor: colors.tbaLightGrey,
    borderColor: colors.tbaMediumGrey
  },
  textFieldInputError: {
    background: colors.pink,
    border: `2px solid ${colors.red}`
  },
  textFieldItem: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.20px'
  },
  textFormError: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontSize: '13px',
    lineHeight: '15px',
    letterSpacing: '0.26px',
    color: colors.red
  },
  textDropDown: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '26px'
  },
  textDropDownSmall: {
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '22px'
  },
  textList: {
    fontSize: '12px',
    lineHeight: '19px',
    letterSpacing: '0.24px'
  },
  textListCondensed: {
    fontFamily: 'Archivo Narrow, sans-serif',
    fontSize: '12px',
    lineHeight: '19px',
    letterSpacing: '0.24px'
  },
  textSearchItem: {
    fontFamily: 'Archivo Narrow, sans-serif',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.24px'
  },
  smallTableHeader: {
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.36px',
    textTransform: 'uppercase',
    color: colors.black
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  textMenuItem: {
    fontSize: '14px',
    lineHeight: '18px'
  },
  noSelect: {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none'
  },
  userSelect: (string: string) => ({
    WebkitTouchCallout: string,
    WebkitUserSelect: string,
    KhtmlUserSelect: string,
    MozUserSelect: string,
    msUserSelect: string,
    userSelect: string
  })
}

export default { colors, mixins, styles }
