import { memo } from 'react'
import {
  selectCanvasDetails,
  selectGeneratedScales,
  useDynamicActions,
  useSelector
} from 'src/chart-library/BaseSetup/BaseSetup'
import { useTooltip } from 'src/chart-library/CommonComponents/Tooltip'
import { calRectWithAndColumns, priceFormatter } from './helper'
import cloneDeep from 'lodash/cloneDeep'
import PatternedRect from './PatternRect'

const Series = (props: any) => {
  const rectHeight = 11
  const rectVPadding = 4
  const rectHPadding = 4
  const verticalMargin = 10
  const { series = {}, tooltipDispatchEventName } = props
  // const actions = useDynamicActions()
  const canvasDetails = useSelector(selectCanvasDetails)
  const canvasObj = canvasDetails?.[`0`] || {}
  const { customData = {} } = series
  const generatedScales = useSelector(selectGeneratedScales)
  // const { updateTooltipDetails } = actions || {}

  // const handleRectMouseOver = (e, hoverColor, rectWidth, obj, rectHeight) => {
  //   e.target.style.fill = hoverColor
  //   const rect = e.target.getBoundingClientRect()
  //   const x = Number(rect.left + rectWidth / 2)
  //   const y = Number(rect.top + window.scrollY)
  //   const styles = {
  //     left: x,
  //     top: y - rectHeight
  //   }
  //   updateTooltipDetails({ styles, obj })
  // }
  // const handleRectMouseLeave = (e, color) => {
  //   e.target.style.fill = color
  //   updateTooltipDetails(null)
  // }

  // --------------------- handle tooltip ---------------------

  const [tooltipDispatch] = useTooltip({
    dispatchEventName: tooltipDispatchEventName,
    disable: canvasObj.tooltip?.hide ?? false,
    formatter: canvasObj.tooltip?.formatter
  })

  const handleLink=(obj)=>{
    let link;
    if(obj?.type === "finding"){
      link =  `/findings/${obj?.id}`
    }else if(obj?.type === "opportunity"){
      link = `/opportunities/${obj?.id}`
    }

    if(obj?.potentialSavingsMonth === 0 && obj?.potentialSavingsTotal === 0) {
      const copyData = cloneDeep(obj);
      delete copyData.potentialSavingsTotal;
      return ({...copyData, link, 
      potentialSavingsMonth:  "$0 (all KPIs disabled)"
      })}

    return ({
      ...obj,
       link, 
       potentialSavingsMonth: obj?.potentialSavingsMonth === 0 && obj?.potentialSavingsTotal === 0 ? "$0 (all KPIs disabled)" : priceFormatter.format(obj?.potentialSavingsMonth) + "/month",
       potentialSavingsTotal:  obj?.potentialSavingsTotal === 0 ? "$0 (all KPIs disabled)" : priceFormatter.format(obj?.potentialSavingsTotal) + " one time"

      })
  }

  return (
    <>
      {Object.keys(customData)?.map((key: string) => {
        const dataObj = customData?.[key]
        const { totalDataObjects, rectWidth, rectsPerColumn, columnsPosition } =
          calRectWithAndColumns(
            dataObj?.data,
            canvasObj?.height,
            rectHeight,
            rectVPadding,
            rectHPadding,
            generatedScales?.x?.gScale,
            verticalMargin
          )
        const catX = generatedScales?.x?.gScale(key)
        const textY =
          canvasObj?.height -
          Math.min(rectsPerColumn, totalDataObjects) *
            (rectHeight + rectVPadding) -
          verticalMargin
        return (
          <g key={key} transform={`translate(${catX}, 0)`}>
            {columnsPosition?.map((obj: any, i: number) => {
              return (
                <g key={i} transform={`translate(${obj?.x}, 0)`}>
                  {dataObj?.data
                    ?.slice(i * rectsPerColumn, (i + 1) * rectsPerColumn)
                    ?.map((obj: any, j: number, arr: any) => {
                      const count = Math.min(rectsPerColumn, arr.length)
                      return (
                        <PatternedRect
                          key={j}
                          Pattern={obj?.Pattern}
                          patternWidth={obj?.patternWidth}
                          patternHeight={obj?.patternHeight}
                          x={0}
                          y={
                            canvasObj?.height -
                            (count - j) * (rectHeight + rectVPadding)
                          }
                          width={rectWidth}
                          height={rectHeight}
                          fill={dataObj?.color}
                          onMouseOver={
                            (e) =>
                              tooltipDispatch(
                                e,
                                `action-tracker-${i}-${j}`,
                                true,
                                handleLink(obj)
                              )
                          }
                          onMouseLeave={
                            (e) =>
                              tooltipDispatch(
                                e,
                                `action-tracker-${i}-${j}`,
                                false,
                                obj
                              )
                          }
                        ></PatternedRect>
                      )
                    })}
                </g>
              )
            })}
            <text
              x={generatedScales?.x?.gScale?.bandwidth() / 2}
              y={textY}
              textAnchor="middle"
              fontWeight={'bold'}
              fontSize="13"
              fontFamily="Archivo Narrow, sans-serif"
            >{`${totalDataObjects} ${dataObj?.title || ''}`}</text>
          </g>
        )
      })}
    </>
  )
}

export default memo(Series)
