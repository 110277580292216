import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Predictions } from "aws-amplify";


export const getTranslation = createAsyncThunk<any, string>(
  "translations/getTranslation",
  async (data, thunkApi) => {
    const {translationSlice: {data: translationStateData}, appData} = thunkApi.getState() as any
    if (translationStateData[data]) {
        return translationStateData[data]
    } else {
        try {
      const response = await Predictions.convert({
            translateText: {
              source: {
                text: data
              },
              targetLanguage: appData.selectedLanguage || "en"
              // targetLanguage: navigator.language
            }
          })
      return response.text;
    } catch (error: any) {
      console.log(error)
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
    }
    
);

interface TranslationState {
  loading: boolean;
  error: string | null;
  data: object;
}

const initialState = {
  loading: false,
  error: null,
  data: {},
} as TranslationState;

export const translationSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    clearTranslations: (state) => {
      state.data = {}
    },
  },
    
  extraReducers(builder) {
    builder
      .addCase(getTranslation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTranslation.fulfilled, (state, action: any) => {
        state.loading = false;
        state.data[action.meta.arg] = action.payload;
      })
      .addCase(getTranslation.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      });
  },
});

export const { clearTranslations } = translationSlice.actions
export default translationSlice.reducer;
