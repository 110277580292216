import { useEffect, useState } from 'react'
import Table from 'src/components/Table/clientSideTable'
import moment from 'moment'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import PAGE_NAMES from 'src/components/legacy/common/pages.js'
import { TEXT_DATE_TIME_FORMAT } from 'src/components/legacy/common/time-helpers'
import { useNavigate } from 'react-router-dom'
import { ACTIONS } from 'src/constants'
import translate from 'src/common/translations'
import { getSearchParams } from 'src/common/helperFunctions.js'
import { AnalyticsEnabledSymbolWrapper, CurvesListWrapper } from './styles'
import successImage from 'src/assets/images/Success.svg'
import { trackEvent} from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"

const CharacteristicCurvesList = ({
  characteristicCurveslist,
  setMode,
  setRowData,
  setIsDeleteModalOpened,
  setShowCurveHistory
}) => {
  const searchParams = getSearchParams()
  const buildingId = searchParams?.buildingId
  const organizationId = searchParams?.organizationId
  const [rows, setRows] = useState([])
  const navigate = useNavigate()
  const [t] = useTranslation()

  const optionsTranslated = {
    name: translate('Name'),
    CurveType: translate('Curve Type'),
    xAxisTargetKey: translate('X Axis'),
    createdAt: translate('Created On'),
    createdBy: translate('Created By'),
    enableAnalytics: translate('Analytics Enabled'),
    rowEdit : translate("Edit"),
    rowCopy : translate("Copy"),
    rowDelete : translate("Delete"),
    rowHistory : translate("History") 
  }

  const AnalyticsEnabled = () => {
    return (
      <AnalyticsEnabledSymbolWrapper>
        <img alt="Yes" src={successImage} />
      </AnalyticsEnabledSymbolWrapper>
    )
  }

  useEffect(() => {
    if (characteristicCurveslist && characteristicCurveslist.length > 0) {
      setRows(
        characteristicCurveslist
          ? characteristicCurveslist
              ?.filter((d) => d.isActive && !d.parentCurveId)
              ?.map((d) => ({
                ...d,
                createdAt: d?.createdAt
                  ? moment(d.createdAt).format(TEXT_DATE_TIME_FORMAT)
                  : '',
                enableAnalytics: d?.runAnalytic ? <AnalyticsEnabled /> : <></>
              }))
          : []
      )
    } else {
      setRows([])
    }
  }, [characteristicCurveslist])

  const nameHeadings = [
    {
      title: optionsTranslated.name,
      key: 'name',
      maxWidth: '200px',
      onDataClick: (data) => {
        setRowData(data)
        setMode(ACTIONS.EDIT)
      }
    },
    {
      title: optionsTranslated.CurveType,
      key: 'curveType',
      maxWidth: '100px'
    },
    {
      title: optionsTranslated.xAxisTargetKey,
      key: 'xAxisTargetKey',
      maxWidth: '50px'
    },
    {
      title: optionsTranslated.createdAt,
      key: 'createdAt',
      maxWidth: '100px'
    },
    {
      title: optionsTranslated.createdBy,
      key: 'createdBy',
      maxWidth: '120px'
    },
    {
      title: optionsTranslated.enableAnalytics,
      key: 'enableAnalytics',
      maxWidth: '120px',
      canSort: false
    }
  ]

  const handleUpdatedTableRows = (updatedRows) => {
    //
  }

  return (
    <CurvesListWrapper>
      <Table
        key={`CharacteristicCurvesTable-${organizationId} ${buildingId}`}
        rows={rows}
        header={nameHeadings}
        search={true}
        searchFields={['name', 'curveType', 'xAxisTargetKey', 'createdBy']}
        handleUpdatedTableRows={(updatedRows)=>handleUpdatedTableRows(updatedRows)}
        rowControl={[
          {
            text: optionsTranslated.rowCopy,
            action: (data) => {
              setRowData(data)
              setMode(ACTIONS.COPY)
              trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_COPY_CHARACTERISTIC)
            }
          },
          {
            text: optionsTranslated.rowEdit,
            action: (data) => {
              setRowData(data)
              setMode(ACTIONS.EDIT)
              trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_EDIT_CHARACTERISTIC)
            }
          },
          {
            text: optionsTranslated.rowDelete,
            action: (data) => {
              setIsDeleteModalOpened(true)
              setRowData(data)
              trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_DELETE_CHARACTERISTIC)
            }
          },
          {
            text: optionsTranslated.rowHistory,
            action: (data) => {
              setRowData(data)
              setShowCurveHistory(true)
              trackEvent(USER_EVENTS.EQUIPMENT_SETUP.events.CLICK_HISTORY_CHARACTERISTIC)
            }
          }
        ]}
      />
    </CurvesListWrapper>
  )
}

export default CharacteristicCurvesList
