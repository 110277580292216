import styled from 'styled-components'

export const AutomatedTestSuppressionsListContainer = styled.div`
  .automated-tests-suppressions-list {
    tr th:last-child(-1) {
      background: red;
      text-align: center;
    }
  }
`
export const AutomatedTestSuppressionsHistoryListContainer = styled.div`
  .automated-tests-suppressions-history-list {
    tr th:last-child(-1) {
      background: red;
      text-align: center;
    }
  }
`

export const AutomatedTestSuppressionsDiv = styled.div`
  .table-top-content {
    display: flex;
    flex-wrap: wrap;
    .radio-option {
      display: flex;
    }
  }
`

export const SearchDates = styled.div`
  display: flex;
  padding: 10px;
  .date-input {
    display: flex;
    align-items: baseline;
    margin-right: 20px;

    .reactDatePickerInput {
      max-width: 160px;
    }
  }
`
