export const calculateTextPositions = (
  plotLines,
  renderingAreaWidth,
  renderingAreaHeight
) => {
  // Helper function to check for overlap
  function isOverlapping(pos1, size1, pos2, size2) {
    return !(pos1 + size1 <= pos2 || pos2 + size2 <= pos1)
  }

  // Helper function to find the next available position above or below
  function findNextAvailableY(
    existingPositions,
    targetY,
    height,
    renderingAreaHeight
  ) {
    let yAbove = targetY
    let yBelow = targetY
    const spacing = 10

    while (true) {
      yAbove -= height + spacing
      if (
        yAbove >= 0 &&
        !existingPositions.some((p) =>
          isOverlapping(p.y, p.height, yAbove, height)
        )
      ) {
        return yAbove
      }

      yBelow += height + spacing
      if (
        yBelow + height <= renderingAreaHeight &&
        !existingPositions.some((p) =>
          isOverlapping(p.y, p.height, yBelow, height)
        )
      ) {
        return yBelow
      }

      if (yAbove < 0 && yBelow + height > renderingAreaHeight) {
        break
      }
    }

    return null
  }

  // Helper function to find the next available position to the left or right
  function findNextAvailableX(
    existingPositions,
    targetX,
    width,
    renderingAreaWidth
  ) {
    let xLeft = targetX
    let xRight = targetX
    const spacing = 10

    while (true) {
      xLeft -= width + spacing
      if (
        xLeft >= 0 &&
        !existingPositions.some((p) =>
          isOverlapping(p.x, p.width, xLeft, width)
        )
      ) {
        return xLeft
      }

      xRight += width + spacing
      if (
        xRight + width <= renderingAreaWidth &&
        !existingPositions.some((p) =>
          isOverlapping(p.x, p.width, xRight, width)
        )
      ) {
        return xRight
      }

      if (xLeft < 0 && xRight + width > renderingAreaWidth) {
        break
      }
    }

    return null
  }

  const positions = []
  const stackedTop = []
  const stackedBottom = []

  plotLines.forEach((plotLine) => {
    const id = plotLine?.id
    if (plotLine.type === 'vertical') {
      const x = plotLine.x
      const width = plotLine.textWidth
      const height = plotLine.textHeight
      let y = renderingAreaHeight / 2 - height / 2

      if (
        positions.some(
          (p) =>
            isOverlapping(p.x, p.width, x, width) &&
            isOverlapping(p.y, p.height, y, height)
        )
      ) {
        y = findNextAvailableY(positions, y, height, renderingAreaHeight)

        if (y === null) {
          if (stackedTop.length <= stackedBottom.length) {
            y = stackedTop.length * (height + 10)
            stackedTop.push({ id, x, y, width, height })
          } else {
            y = renderingAreaHeight - (stackedBottom.length + 1) * (height + 10)
            stackedBottom.push({ id, x, y, width, height })
          }
        }
      }

      positions.push({ id, x, y, width, height })
    } else if (plotLine.type === 'horizontal') {
      const y = plotLine.y
      const width = plotLine.textWidth
      const height = plotLine.textHeight
      let x = renderingAreaWidth / 2 - width / 2

      if (
        positions.some(
          (p) =>
            isOverlapping(p.y, p.height, y, height) &&
            isOverlapping(p.x, p.width, x, width)
        )
      ) {
        x = findNextAvailableX(positions, x, width, renderingAreaWidth)

        if (x === null) {
          if (stackedTop.length <= stackedBottom.length) {
            x = stackedTop.length * (width + 10)
            stackedTop.push({ id, x, y, width, height })
          } else {
            x = renderingAreaWidth - (stackedBottom.length + 1) * (width + 10)
            stackedBottom.push({ id, x, y, width, height })
          }
        }
      }

      positions.push({ id, x, y, width, height })
    }
  })

  return positions.concat(stackedTop, stackedBottom)
}
