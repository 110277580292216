import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _get from 'lodash/get'
import classNames from 'classnames'
import EditableViewArray from 'src/components/legacy/buildingSetup/common/editable-view-array'
import EditableView from 'src/components/legacy/components/editable-view/editable-view'
import {
  getConnectionTypeObj,
  FROZEN_CONNECTION_TYPES,
} from 'src/components/legacy/common/connection-types'
import { ConditionalTooltip } from 'src/components/legacy/components/tooltip/conditional-tooltip'
import StandardDialogs from 'src/components/legacy/components/standard-dialogs'
import ToggleBox from 'src/components/legacy/components/toggle-box/'
import { FIELD_RESTRICTIONS } from '../../createBuilding/form'
import PAGE_NAMES from 'src/components/legacy/common/pages'
import { trackEvent } from 'src/amplitude.js'
import { USER_EVENTS } from 'src/amplitude-categories'
import { Button } from 'src/components/inputs/button'
import { DeviceMigrationModal } from './device-migration-modal'
import { AccessControl } from 'src/pages/AccessControl'
import { TranslateComponent } from 'src/common/translations'

export const NEW_DEVICE = {
  device: { deviceType: '', identifier: '', deviceName: '' },
  status: { connected: false, lastTimestamp: '' },
  new: true
}

const Important = styled.div`
  color: red;
`
export class Connectivity extends PureComponent {
  static propTypes = {
    connectionTypesList: PropTypes.array,
    locationConnectivity: PropTypes.array,
    readOnly: PropTypes.bool,
    permissions: PropTypes.func,
    hasNotNAXConnectionType: PropTypes.bool,
    pageName: PropTypes.string,
    setReadonly: PropTypes.func,
    removeModalHeader: PropTypes.string
  }

  static defaultProps = {
    locationConnectivity: [],
    readOnly: false
  }

  state = {
    confirmationDialogOptions: null,
    selectedDeviceDetails: null,
    showDeviceMigrationModal: false
  }

  onChangeField = (setFieldValue, name) => (key) => setFieldValue(name, key)

  onCloseDialog = () => {
    this.setState({ confirmationDialogOptions: null })
  }

  filterConnectivityType = (type) => {
    const { permissions } = this.props
    const { key } = type
    return permissions?.(PAGE_NAMES.CONNECT_DEVICE, key)
    //Removed CoExistence rule
    // && locationConnectivity.every(device => {
    // 	const deviceType = _get(device, "device.deviceType", "")
    // 	return deviceType === "" || CONNECTION_TYPE_COEXISTENCE[key].includes(deviceType)
    // })
  }

  get filteredConnectionTypes() {
    const { connectionTypesList } = this.props  
    const _filteredConnectionTypes = this.props.hasNotNAXConnectionType
      ? connectionTypesList?.filter((x) => x?.key !== 'NAX').filter(this.filterConnectivityType)
      : connectionTypesList.filter(this.filterConnectivityType)
    // new CM/CS device should not be added.
    return _filteredConnectionTypes?.filter((x) => x?.key !== 'CM')
  }

  ItemComponent = (props) => {
    const { item, index, arrayHelpers } = props
    const { readOnly, locationConnectivity, t, pageName } = this.props
    const CreateFlowText = () => (
      <div>
        <p>
          {
          item.device.deviceName.length === 0 ?  <TranslateComponent>{"Are you sure you want to remove unnamed device ?"}</TranslateComponent> : <>
          <TranslateComponent>{"Are you sure you want to remove device"}</TranslateComponent>{" "}
          {item.device.deviceName || item.device.identifier}?
          </>
          }
        </p>
        <p>
         <TranslateComponent>Data coming from this device will no longer be associated with this
         building.</TranslateComponent>
        </p>
      </div>
    )
    const EditFlowText = () => (
      <div>
        <p>
         <TranslateComponent>If this device is being replaced or upgraded, please select Cancel and
          submit a help ticket IMMEDIATELY AFTER the device has been physically
          replaced, using the replacement/upgrade process</TranslateComponent>:{' '}
          <a
            href="https://hub.tranetechnologies.com/docs/DOC-127320"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            https://hub.tranetechnologies.com/docs/DOC-127320{' '}
          </a>
          .
        </p>
        <Important>
          {' '}
          <TranslateComponent> Disabling the device without following this process will cause data
          loss.</TranslateComponent>
        </Important>
      </div>
    )
    const textMessage = item.new ? <CreateFlowText /> : <EditFlowText />
    const onDeleteHandler = () => {
      if (item.device.deviceType) {
        this.setState({
          confirmationDialogOptions: {
            title: this.props.removeModalHeader,
            text: textMessage,
            confirmText: item.new ? 'Yes' : 'Continue',
            cancelText: item.new ? 'No' : 'Cancel',
            handleConfirm: () => {
              locationConnectivity.length > 1
                ? arrayHelpers.remove(index)
                : arrayHelpers.replace(0, NEW_DEVICE)
              this.setState({ confirmationDialogOptions: null })
            }
          }
        })
      } else {
        locationConnectivity.length > 1
          ? arrayHelpers.remove(index)
          : arrayHelpers.replace(0, NEW_DEVICE)
      }
      trackEvent(USER_EVENTS.BUILDING_SETUP.events.REMOVE_DEVICE)
    }

    const connectionTypeObj =
      (item.device.deviceType &&
        getConnectionTypeObj(item.device.deviceType,this.props?.connectionTypesList)) ||
      {}
    const serialTitle =
      item.device.deviceType === 'NAX'
        ? 'Host ID'
        : connectionTypeObj.title || 'Hardware Serial Number'
    const deviceValue = connectionTypeObj.value || ''
    const isDeviceRegistered = !item.new
    const isDeviceActive =
      !item.new &&
      (item.status.active ||
        item.status.active === null ||
        item.status.active === undefined)
    const isEditingDisabled =
      isDeviceActive && FROZEN_CONNECTION_TYPES.includes(item.device.deviceType)
    // const numberOfOldDevices = locationConnectivity.filter(device => !device.new)
    // const canDeviceBeDeleted = locationConnectivity.length > 1

    const identifierNumber = (isDeviceActive) ? item.device.identifier.replace('-local', '') : item?.device?.identifier

    return (
      <div className="connectivity__row" key={index}>
        <EditableView
          type={EditableView.COMPONENT_TYPE.SELECT}
          className="connection-type"
          labelName="Connection Type"
          readOnly={readOnly}
          disabled={!item.new}
          name={`locationConnectivity.devices.${index}.device.deviceType`}
          placeholder={t?.('common:SelectOne')}
          includePlaceholderOption={true}
          showErrorsOnUntouched={true}
          value={deviceValue}
          options={this.filteredConnectionTypes}
          testName={`${pageName}_connecitvity_addconnectivitytype_row_${
            index + 1
          }`}
        />
        <EditableView
          type={EditableView.COMPONENT_TYPE.INPUT}
          className="serial-num"
          labelName={serialTitle}
          readOnly={readOnly}
          disabled={isDeviceActive}
          value={identifierNumber}
          name={`locationConnectivity.devices.${index}.device.identifier`}
          validateOnChange={true}
          validateOnRender={true}
          maxLength={FIELD_RESTRICTIONS.deviceIdentifier.maxLength + 1}
          testName={`${pageName}_connecitvity_addserialnumber_row_${index + 1}`}
        />

        <EditableView
          type={EditableView.COMPONENT_TYPE.INPUT}
          className="device-name"
          labelName={item.device.deviceType === 'ES' ? 'Name' : 'Device Name'}
          readOnly={readOnly}
          //disabled={isEditingDisabled || deviceValue === ''}
          value={item.device.deviceName}
          name={`locationConnectivity.devices.${index}.device.deviceName`}
          maxLength={FIELD_RESTRICTIONS.deviceName.maxLength + 1}
          testName={`${pageName}_connecitvity_adddevicename_row_${index + 1}`}
        />

        {readOnly && item.device.deviceType !== 'ES' && (
          <div className="reg-status">
            <div className="reg-status__label">Registration status</div>
            <div className="reg-status__message-wrapper">
              <span
                className={classNames(
                  `reg-status__message ${
                    isDeviceRegistered
                      ? 'reg-status__message--registered'
                      : 'reg-status__message--not-registered'
                  }`
                )}
              >
                {isDeviceRegistered ? 'Registered' : 'Not Registered'}
              </span>

              <ConditionalTooltip
                type={ConditionalTooltip.TYPE.DEFAULT}
                content={
                  <div style={{ width: '206px' }}>
                    {isDeviceRegistered
                      ? 'This device has been successfully registered with this building.'
                      : 'This device could not be registered with this building. Verify the Serial Number is correct.'}
                  </div>
                }
                align={ConditionalTooltip.ALIGN.CENTER}
                position={ConditionalTooltip.POSITION.BOTTOM}
                multiline={true}
                width="auto"
              >
                <span className="icon icon-infocircle" />
              </ConditionalTooltip>
            </div>
          </div>
        )}

        {!readOnly && isDeviceActive && (
          <AccessControl id={'tc.pages.device-migration.add'}>
            <Button
              onClick={() => this.toggleMigrationModal(item.device || null)}
              className="primary"
              type="button"
              testName="device-replace-button"
            >
              <TranslateComponent>REPLACE</TranslateComponent>
            </Button>
          </AccessControl>
        )}

        {!readOnly && (
          // && canDeviceBeDeleted
          <button
            type="button"
            className="delete-row icon-trash2"
            onClick={onDeleteHandler}
            data-testid={`${pageName}_connecitvity_removeconnectivity_button`}
          />
        )}
      </div>
    )
  }

  toggleMigrationModal = (deviceDetails) => {
    // once device is replace, set readonly to true
    if (this.state?.showDeviceMigrationModal) {
      this?.props?.setReadonly?.(true)
    }
    this.setState((state) => ({
      ...state,
      selectedDeviceDetails: deviceDetails,
      showDeviceMigrationModal: !state.showDeviceMigrationModal
    }))
  }

  AddItemButtonComponent = React.memo(({ arrayHelpers }) => {
    const { readOnly, locationConnectivity, pageName } = this.props
    const {
      form: { setValues, values }
    } = arrayHelpers

    const onClickHandler = () => {
      setValues({
        ...values,
        locationConnectivity: {
          ...values.locationConnectivity,
          devices: [
            ..._get(values, 'locationConnectivity.devices', []),
            NEW_DEVICE
          ]
        }
      })
      trackEvent(USER_EVENTS.BUILDING_SETUP.events.ADD_DEVICE)
    }
    return readOnly || !this.filteredConnectionTypes.length ? null : (
      <button
        type="button"
        className="action"
        onClick={onClickHandler}
        data-testid={`${pageName}_connectivity_${
          locationConnectivity.length ? 'Add another device' : 'Add device'
        }_button`}
      >
        <TranslateComponent>{locationConnectivity.length ? 'Add another device' : 'Add device'}</TranslateComponent>        
      </button>
    )
  })

  render() {
    const {
      readOnly,
      locationConnectivity,
      pageName,
      updateDeviceToBuilding,
      addDeviceToBuilding,
      refetchBuildingById
    } = this.props
    const { confirmationDialogOptions } = this.state
    return (
      <>
        <ToggleBox
          label="Connectivity"
          className="transparent"
          opened={true}
          showOnlyLabel={true}
        >
          <div className="connectivity">
            {!readOnly ||
            (locationConnectivity && locationConnectivity.length) ? (
              <EditableViewArray
                readOnly={readOnly}
                value={locationConnectivity}
                name="locationConnectivity.devices"
                ItemComponent={this.ItemComponent}
                AddItemButtonComponent={this.AddItemButtonComponent}
              />
            ) : (
              <div className="no-data-section">
                <TranslateComponent>No devices registered for this building.</TranslateComponent>               
              </div>
            )}
          </div>
        </ToggleBox>
        <StandardDialogs
          confirm={confirmationDialogOptions}
          onCloseDialog={this.onCloseDialog}
        />
        {this.state.showDeviceMigrationModal && (
          <DeviceMigrationModal
            deviceDetails={this.state.selectedDeviceDetails}
            updateDeviceToBuilding={updateDeviceToBuilding}
            addDeviceToBuilding={addDeviceToBuilding}
            refetchBuildingById={refetchBuildingById}
            toggleMigrationModal={this.toggleMigrationModal}
            buildingData={this.props?.buildingData}
            setupDeviceForOpenVPN={this.props?.setupDeviceForOpenVPN}
          />
        )}
      </>
    )
  }
}

export default Connectivity
