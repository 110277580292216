export const LIST_APPLICATIONS = `
query LIST_APPLICATIONS {
  listApplications {
    items {
      name
      id
    }
  }
}
`

export const LIST_PERMISSIONS_BY_APP_ID = `query LIST_PERMISSIONS_BY_APP_ID(
  $id: ID!
  $filter: ModelPermissionFilterInput
  $limit: Int
  $nextToken: String
) {
  listPermissionsByAppId(appId: $id, filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      appId
      createdAt
      description
      id
      name
      type
      updatedAt
      resourceTypes
    }
    nextToken
  }
}`


export const GET_PERMISSION_BY_ID = `query GET_PERMISSION_BY_ID(
  $id: ID!
) {
  getPermission(id: $id) {
    description
    id
    name
    type
    updatedAt
    appId
    createdAt
    resourceTypes
  }
}`

export const CREATE_PERMISSION = /* GraphQL */ `
    mutation createPermission($input: CreatePermissionInput!) {
        createPermission(input: $input) {
            id
        }
  } 
`

export const UPDATE_PERMISSION = /* GraphQL */ `
    mutation updatePermission($input: UpdatePermissionInput!) {
        updatePermission(input: $input) {
            id
        }
  } 
`