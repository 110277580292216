// import { getConfig } from "common/config"
// import i18next from "i18next"
// import isBeta from "stores/modules/beta.js"

import {zendeskWidgetKey} from "src/aws-exports.js"

const zendeskSettings = {
	webWidget: {
		position: {
			horizontal: "right",
			vertical: "bottom",
		},
		zIndex: 20,
		contactForm: {
      // https://developer.zendesk.com/api-reference/widget/settings/#ticketforms
      ticketForms: [
        // {id: 0}
      ]
    },
	}
}

const canUseDOM = () => !(typeof window === "undefined" || !window.document || !window.document.createElement)

class ZendeskHelper {
	constructor() {
		this.scriptLoaded = false
		this.createScript()
		// Wait for i18next to be initialized in order to provide title translations
		// It seems like title and other text strings can't be set via `updateSettings` after Zendesk widget is inserted
		// i18next.on("initialized", async () => {
			// The line below explicitly fetches common namespace translations for languages other than default (en-US)
			// We should put other locales here once we start supporting them
			// await Promise.all([
			// 	i18next.reloadResources(["es-LA"], "common"),
			// 	i18next.reloadResources(["fr-CA"], "common"),
			// 	i18next.reloadResources(["pt-BR"], "common"),
			// 	i18next.reloadResources(["es-ES"], "common"),
			// 	i18next.reloadResources(["fr-FR"], "common"),
			// ])

			// zendeskSettings.webWidget.contactForm.title = {
			// 	"en-US": i18next.t("common:RequestHelp", { lng: "en-US" }),
			// 	"es-la": i18next.t("common:RequestHelp", { lng: "es-LA" }),
			// 	"fr-ca": i18next.t("common:RequestHelp", { lng: "fr-CA" }),
			// 	"pt-br": i18next.t("common:RequestHelp", { lng: "pt-BR" }),
			// 	"es-es": i18next.t("common:RequestHelp", { lng: "es-ES" }),
			// 	"fr-fr": i18next.t("common:RequestHelp", { lng: "fr-FR" }),
			// }
			window.zESettings = zendeskSettings
			this.insertScript(zendeskWidgetKey)
		// })
	}

	createScript(cb) {
		this.script = document.createElement("script")
		this.script.defer = true
		this.script.id = "ze-snippet"
		this.script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskWidgetKey}`
		cb && (this.scriptLoaded && cb())
		this.script.addEventListener("load", () => {
			this.onScriptLoaded()
			this.scriptLoaded = true
			if (typeof cb === "function") {
				cb()
			}
		})
	}

	onScriptLoaded() {
		window.zE("webWidget", "hide")
		if (typeof this.onLoaded === "function") {
			this.onLoaded()
		}
	}

	clearZeWidget() {
		const zeSnippet = document.getElementById("ze-snippet")
		if (zeSnippet) {
			const removeZeWidget = () => {
				this.command("webWidget", "clear")
				this.command("webWidget", "logout")
				zeSnippet.removeEventListener("load", removeZeWidget)
			}
			zeSnippet.addEventListener("load", removeZeWidget)
		}
	}

	insertScript() {
		document.body.appendChild(this.script)
	}

	openWidget(denaliColors=false) {
		// WebWidget new command interface does not support hideOnClose param for "open" command
		// https://developer.zendesk.com/embeddables/docs/widget/core#open
		// So there will be an overkill to do the same as this one line of code does
    window.zE("webWidget", "updateSettings", {
      webWidget: {
        color: {
          theme: denaliColors ? '#000000' : '#4d912e',
        },
      }
    });
		window.zE.activate({ hideOnClose: true });
	}

	command(...args) {
		if (canUseDOM && window.zE) {
			window.zE.apply(null, args)
		} else {
			console.warn("Zendesk is not initialized yet") // eslint-disable-line
		}
	}

	identify(firstName, lastName, email) {
		this.command("webWidget", "identify", {
			name: `${firstName} ${lastName}`,
			email
		})
		this.command("webWidget", "prefill", {
			name: {
				value: `${firstName} ${lastName}`
			},
			email: {
				value: email
			}
		})
	}

	updateSettings(settings) {
		this.command("webWidget", "updateSettings", settings)
	}
}

export const zendeskHelper = new ZendeskHelper()

