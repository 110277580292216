import * as yup from 'yup'

const REQUIRED = 'required'
const schema = () =>
  yup.object().shape({
    name: yup.string().required('Name is required'),
    selectedBuildings: yup.array().required(REQUIRED),
    district: yup.string().test(
      "district",
      "District cannot be empty",
      (value, ctx) => {
        if (ctx.parent.entityType === "SalesOffice") {
          return value && value !== ""
        } else {
          return true
        }
      }
    ),
    code: yup.string().test(
      "code",
      "Code cannot be empty",
      (value, ctx) => {
        if (ctx.parent.entityType === "SalesOffice") {
          return value && value !== ""
        } else {
          return true
        }
      }
    )
  })

export default schema
