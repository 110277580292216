import React, { useEffect, useMemo, useRef, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import {
	TreeViewNodeContentStyled,
	TreeViewNodeHeaderLabelStyled,
	TreeViewNodeHeaderStyled,
	TreeViewNodeIconStyled,
	TreeViewNodeStyled,
} from "./styles"

import
{
	COMPONENTS,
	PROP_NAMES
} from "./constants"

import Checkbox from "src/components/legacy/components/checkbox/checkbox"
export const TreeViewNode = props => {
	const {
		components,
		data,
		children,
		propNames,
		expanded,
		selected,
		selectable,
		multiSelect,
		onToggle,
		onSelect
	} = props
	const [_isExpanded, _setIsExpanded] = useState(expanded)
	const [_isSelected, _setIsSelected] = useState(selected)
	const ref = useRef(null)
	useEffect(
		() => {
			_setIsExpanded(expanded)
		},
		[expanded]
	)

	useEffect(
		() => {
			_setIsSelected(selected)
		},
		[selected]
	)

	useEffect(
		() => {
			if (!multiSelect && selected) {
				ref?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
			}
		},
		[]
	)

	const _components = useMemo(
		() => ({
			...COMPONENTS,
			...components
		}),
		[components]
	)
	const { ExpandIcon, Label, IsExpandableNode } = _components
	const _propNames = useMemo(
		() => ({
			...PROP_NAMES,
			...propNames
		}),
		[propNames]
	)


	const treeNodeBag = useMemo(
		() => ({
			...props,
			data: data?.originalData,
			expanded: _isExpanded,
			selected: _isSelected,
			propNames: _propNames
		}),
		[props, _propNames, _isExpanded, _isSelected]
	)


	const expand = () => {
		const { data } = props

		if (!data[_propNames.children] && !children) {
			return
		}

		_setIsExpanded(!_isExpanded)

		onToggle && onToggle({
			data: {
				...data,
				expanded: !_isExpanded,
				selected: _isSelected
			},
		})
	}

	const select = () => {
		const { data } = props

		onSelect && onSelect({
			data: {
				...data,
				expanded: _isExpanded,
				selected: !_isSelected
			},
		})
	}

	const onClickHeader = () => {
		if (selectable) {
			select()
		} else {
			expand()
		}
	}

	const onClickExpandIcon = e => {
		if (selectable) {
			expand()

			e.stopPropagation()
		}
	}

	const classes = {
		"tree-view__node--expanded": _isExpanded,
		"tree-view__node--selected": _isSelected,
	}

	return (
		<TreeViewNodeStyled
			ref={ref}
			className={classNames("tree-view__node", classes)}
		>
			{!multiSelect ? (
				<TreeViewNodeHeaderStyled
					selected={_isSelected}
					className="tree-view__node-header"
					multiSelect={multiSelect}
					onClick={onClickHeader}
				>
					<TreeViewNodeIconStyled
						className="tree-view__node-expand-icon"
						onClick={onClickExpandIcon}
					>
						{
						ExpandIcon(treeNodeBag)
					}

					</TreeViewNodeIconStyled>
					<TreeViewNodeHeaderLabelStyled
						multiSelect={multiSelect}
						className="tree-view__node-header-label"
					>
						{
						Label(treeNodeBag)
					}
					</TreeViewNodeHeaderLabelStyled>
				</TreeViewNodeHeaderStyled>
			)
				: (
					<TreeViewNodeHeaderStyled
						selected={_isSelected}
						multiSelect={multiSelect}
						className="tree-view__node-header"
					>
						<TreeViewNodeIconStyled
							className="tree-view__node-expand-icon"
							onClick={onClickExpandIcon}
						>
							{
							ExpandIcon(treeNodeBag)
					}
						</TreeViewNodeIconStyled>
						{
						IsExpandableNode(treeNodeBag)
							? (
								<TreeViewNodeHeaderLabelStyled
									multiSelect={multiSelect}
									className="tree-view__node-header-label"
								>
									{
							Label(treeNodeBag)
							}
								</TreeViewNodeHeaderLabelStyled>
							)
							: (
								<TreeViewNodeHeaderLabelStyled
									multiSelect={multiSelect}
									className="tree-view__node-header-label"
								>
									<Checkbox
										isChecked={treeNodeBag.selected}
										id={treeNodeBag.data.objectName}
										toggleCheck={onClickHeader} label={Label(treeNodeBag)}
										labelToggle={true}
									/>
								</TreeViewNodeHeaderLabelStyled>
							)
					}
					</TreeViewNodeHeaderStyled>
				)
			}
			{
				_isExpanded && (
					<TreeViewNodeContentStyled className="tree-view__node-content">
						{
							children
								? children
								: data[_propNames?.children]?.map((childData, index) => (
									<TreeViewNode
										key={childData[_propNames?.id] ?? index}
										data={childData}
										propNames={_propNames}
										components={_components}
										expanded={childData.expanded}
										selected={childData.selected}
										selectable={selectable}
										multiSelect={multiSelect}
										onToggle={onToggle}
										onSelect={onSelect}
									/>
								))
						}
					</TreeViewNodeContentStyled>
				)
			}
		</TreeViewNodeStyled>
	)
}

/**
 * The Node Data
 * @typedef {object} Node
 * @property {string|number} id - unique id
 * @property {string} label - label
 * @property {Node[]} children
 */

/**
 * The Node Bag
 * Represents the object that contains the node data and some props
 * @typedef {object} Bag
 * @property bag.data {Node} - node data
 * @property bag.data.expanded {boolean} - whether the node is expanded
 * @property bag.data.selected {boolean} - whether the node is selected
 */

TreeViewNode.propTypes = {

	/**
	 * The content of the component
	 */
	children: PropTypes.node,

	/**
	 * Render components (controlled)
	 */
	components: PropTypes.shape({

		/**
		 * The component used to render expand/collapse/last icon
		 *
		 * @params {Bag}
		 * @returns {ReactNodeLike}
		 */
		ExpandIcon: PropTypes.func,

		/**
		 * The component used to render node label
		 *
		 * @params {Bag}
		 * @returns {ReactNodeLike}
		 */
		Label: PropTypes.func,

		/**
		 * The component used to render node label
		 *
		 * @params {Bag}
		 * @returns Boolean
		 */
		IsExpandableNode: PropTypes.func

	}),

	/**
	 * Node data
	 *
	 * By default takes an object like
	 * ```
	 * {
	 * 	id: string|number,
	 * 	label: string,
	 * 	children: [...]
	 * }
	 * ```
	 * Property names can be configured via `propNames` property
	 */
	data: PropTypes.object,

	/**
	 * Allows to select nodes
	 *
	 * The expanding behaviour will be available only for expand icon
	 */
	selectable: PropTypes.bool,

	/**
	 * Used to provide custom props names
	 */
	propNames: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		label: PropTypes.string,
		children: PropTypes.string,
	}),

	/**
	 * On toggle node callback

	 * Takes the bag object of expanded/collapsed node
	 * @params {Bag} bag - contains expanded/collapsed node props
	 */
	onToggle: PropTypes.func,

	/**
	 * On select node callback
	 *
	 * Takes the bag object of selected/unselected node
	 * @params {Bag} bag - contains selected/unselected node props
	 */
	onSelect: PropTypes.func,
}

TreeViewNode.defaultProps = {
	children: null,
	className: "",
	components: null,
	data: null,
	selectable: false,
	propNames: null,
	onSelect: null,
	onToggle: null,
}

export default TreeViewNode
