import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/pageHeader/header'
import i18next from 'i18next'
import './user-management.scss'
// import { Button } from "../../../components/inputs/buttonNew"
import Button from 'src/denali-ui/components/Button'
import ProgressIndicator from './create-user-progress-indicator'

import Input from 'src/denali-ui/components/Input'
import { UserType } from '../components/userType'
import { Block } from '../../../components/layouts/index'
import Container from 'src/components/Container'
import Content from 'src/components/Content'
import { Card } from '../../../components/layouts/card'
import Spinner from 'src/components/legacy/components/spinner/spinner'
import translate, { TranslateComponent } from '../../../common/translations'
//import { InlineSpinner } from "react/shared/spinner-sc"
//import { isTraneUser } from "stores/modules/user"

const InformationLabel = styled.label`
  font-size: 20px;
`
const FeildDivContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  &:nth-child(n + 1) {
  }
`

const FieldDiv = styled.div`
  width: calc(50% - 10px);
  position: relative;
`

const ButtonContainer = styled.div`
  text-align: end;
`
const StyledInlineSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: calc(100% - 24px);
`

export const AddUserStage = ({
  userTypesList,
  userInfoObject,
  userEmail,
  setUserInfoObject,
  prev,
  next,
  width,
  edit,
  validateFields,
  validateIfUserEmailExists,
  checkIfIsUserUnique
}) => {
  const [t] = useTranslation()

  return (
    <>
      <Container
        sColumns={12}
        mColumns={12}
        lColumns={12}
        xlColumns={12}
        width={width}
      >
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <Header
            hideToolBar={false}
            pageTitle={<TranslateComponent>{`${edit ? "Edit User" : "Create User"}`}</TranslateComponent>}
          />
        </Content>
          <Content
            xlColumn={8}
            lColumn={12}
            mColumn={12}
            sColumn={12}
            border="none"
            width={width}
          >
            <Block margin="0 0 46px 0">
              <ProgressIndicator stage={0} />
            </Block>
          </Content>
        <Content
          xlColumn={12}
          lColumn={12}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <TranslateComponent>Fill in the user's personal information and indicate type.</TranslateComponent>
        </Content>
        <Content
          xlColumn={8}
          lColumn={8}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
          style={{ gridRowStart: edit ? 3 : 4, gridRowEnd: edit ? 5 : 6 }}
        >
          <Card type="elevated">
            <Block padding="20px 20px 40px 20px" width="100%">
              <InformationLabel><TranslateComponent>Personal Information </TranslateComponent></InformationLabel>
              <FeildDivContainer>
                <FieldDiv>
                  <TranslateComponent>First Name</TranslateComponent>
                  <Input
                    disabled={Boolean(edit)}
                    name="firstName"
                    error={userInfoObject.firstName.error}
                    errorText={<TranslateComponent>First name cannot be empty.</TranslateComponent>}
                    fullWidth={true}
                    value={
                      userInfoObject.firstName.value === null
                        ? ''
                        : userInfoObject.firstName.value
                    }
                    onChange={(e) => {
                      const clonedVal = { ...userInfoObject }
                      clonedVal.firstName.value = e.target.value
                      setUserInfoObject(
                        validateFields(clonedVal, ['firstName'])
                      )
                    }}
                  />
                </FieldDiv>
                <FieldDiv>
                  <TranslateComponent>Last Name</TranslateComponent>
                  <Input
                    disabled={Boolean(edit)}
                    name="lastName"
                    error={userInfoObject.lastName.error}
                    errorText={<TranslateComponent>Last name cannot be empty.</TranslateComponent>}
                    fullWidth={true}
                    value={
                      userInfoObject.lastName.value === null
                        ? ''
                        : userInfoObject.lastName.value
                    }
                    onChange={(e) => {
                      const clonedVal = { ...userInfoObject }
                      clonedVal.lastName.value = e.target.value
                      setUserInfoObject(validateFields(clonedVal, ['lastName']))
                    }}
                  />
                </FieldDiv>
              </FeildDivContainer>
              <FeildDivContainer>
                <FieldDiv>
                  <TranslateComponent>Email</TranslateComponent>
                  <Input
                    disabled={Boolean(edit)}
                    name="email"
                    error={userInfoObject.email.error || !checkIfIsUserUnique()}
                    errorText={
                      !checkIfIsUserUnique() && !userInfoObject.email.error
                        ? translate('Account already exists.')
                        : translate('Email address is not valid.')
                    }
                    fullWidth={true}
                    value={
                      userInfoObject.email.value === null
                        ? ''
                        : userInfoObject.email.value
                    }
                    onChange={(e) => {
                      const clonedVal = { ...userInfoObject }
                      clonedVal.email.value = e.target.value
                      setUserInfoObject(validateFields(clonedVal, ['email']))
                    }}
                    onBlur={(e) => {
                      userEmail !== e.target.value &&
                        validateIfUserEmailExists(e.target.value)
                    }}
                  />
                  {/* Below code should be enabled while loader will enable */}
                  {/* {loadingUniq && <StyledInlineSpinner />} */}
                </FieldDiv>
                <FieldDiv>
                  <TranslateComponent>Secondary Email</TranslateComponent>
                  <Input
                    name="secondaryEmail"
                    disabled={Boolean(edit)}
                    error={userInfoObject.secondaryEmail.error}
                    errorText={<TranslateComponent>Email address is not valid</TranslateComponent>}
                    fullWidth={true}
                    value={
                      userInfoObject.secondaryEmail.value === null
                        ? ''
                        : userInfoObject.secondaryEmail.value
                    }
                    onChange={(e) => {
                      const clonedVal = { ...userInfoObject }
                      clonedVal.secondaryEmail.value = e.target.value
                      setUserInfoObject(
                        validateFields(clonedVal, ['secondaryEmail'])
                      )
                    }}
                  />
                </FieldDiv>
              </FeildDivContainer>
            </Block>
          </Card>
        </Content>
        <Content
          xlColumn={4}
          lColumn={4}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <UserType
            userTypesList={userTypesList}
            userInfoObject={userInfoObject}
            setUserInfoObject={setUserInfoObject}
            validateFields={validateFields}
          />
        </Content>
        <Content
          xlColumn={4}
          lColumn={4}
          mColumn={12}
          sColumn={12}
          border="none"
          width={width}
        >
          <ButtonContainer>
            <Button
              variant="secondary"
              size="medium"
              onClick={() => prev()}
              style={{ marginRight: '14px' }}
            >
              <TranslateComponent>Cancel</TranslateComponent>
            </Button>
            <Button
              variant="primary"
              size="medium"
              onClick={async () => {
                const fieldsToValidate = Object.keys(userInfoObject).filter(
                  (key) => key !== 'userAsset' && key !== "applications"
                )
                const validatedFields = validateFields(
                  userInfoObject,
                  fieldsToValidate
                )
                let _IsUserUnique = true
                if (!(Boolean(edit) )) {
                	if (userInfoObject.email.value !== userEmail) {
                		const result = await validateIfUserEmailExists();
                		const IsUserUnique = result?.length ? false : true;
                		_IsUserUnique = IsUserUnique
                	}
                }
                if (
                  fieldsToValidate.every((f) => !userInfoObject[f].error) && _IsUserUnique
                ) {
                  next()
                } else {
                  setUserInfoObject(validatedFields)
                }
              }}
            >
              <TranslateComponent>Next</TranslateComponent>
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </>
  )
}

AddUserStage.propTypes = {}

export default AddUserStage
