import { toInteger, isFinite } from 'lodash'
// Adjust scale Linear range, so that there wont be any decimals while creating a scale Linear Ticks
export const adjustScaleLinearTickValues = (
  pMin: any,
  pMax: any,
  pStep: any = 8,
  paddingNotRequired = false,
  padding: any = 0.5
) => {
    let min = pMin
    let max = pMax

    if (!paddingNotRequired) {
      const paddingNumMin = pMin * (padding / 100)
      const paddingNumMax = pMax * (padding / 100)
      const paddingNumber =
        paddingNumMax > paddingNumMin ? paddingNumMax : paddingNumMin
      min = pMin > 0 && pMin - paddingNumber < 0 ? 0 : pMin - paddingNumber
      max = pMax + paddingNumber
    }

    const dec = calculateDecimals(min, max, pStep)
    const multipleNum = Math.pow(10, dec)
    min = toFixedNum(min, dec) * multipleNum
    max = toFixedNum(max, dec) * multipleNum
  
    let diff = max - min
  
    min = Math.floor(min)
    max = Math.floor(max)
    diff = max - min
    const correctedDiff = nextNumberDivisble(diff, pStep)
    const d = correctedDiff - diff
    const divideNum2Parts = divideIntoTwoParts(d)
    let newMin = min - divideNum2Parts?.[0]
    let newMax = max + divideNum2Parts?.[1]
    newMin = pMin >= 0 && newMin < 0 ? 0 : newMin
    newMax = pMin >= 0 && newMin < 0 ? newMax + divideNum2Parts?.[0] : newMax
    newMin = newMin / multipleNum
    newMax = newMax / multipleNum
    return [newMin, newMax]
}

export const nextNumberDivisble = (pDividend: any, pDivisor: any) => {
  let dividend = pDividend
  const divisor = pDivisor > 0 && isFinite(pDivisor) ? toInteger(pDivisor) : 1
  let remainder = dividend % divisor
  while (remainder !== 0) {
    dividend++
    remainder = dividend % divisor
  }
  return dividend
}

export const divideIntoTwoParts = (number) => {
  const firstPart = Math.floor(number / 2)
  const secondPart = Math.ceil(number / 2)
  return [firstPart, secondPart]
}

// Will let us know how many decimals is required while generating the output of scale Linear ticks
export const calculateDecimals = (min: any, max: any, divisions: any) => {
  const range = max - min
  const step = range / (divisions - 1)
  let decimals = Math.max(0, -Math.floor(Math.log10(step)))
  decimals = isFinite(decimals) ? toInteger(decimals) : 0
  return decimals
}

export const toFixedNum = (x: any, fixedTo: number) => {
  return parseFloat(Number.parseFloat(x).toFixed(fixedTo))
}
