import { Flex, Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react'
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TranslateComponent } from 'src/common/translations'
import styles from './table.module.scss'
import { LoadTime } from './LoadTime'
import { DenaliPaginationButton } from './DenaliPaginationButton'

export const Pagination = ({
  totalItems,
  MINIMUM_ROW_COUNT,
  tableControls,
  paginationOptions,
  ClickPaginationCount,
  loadTime,
  pageOptions,
  pages,
  setTableControls
}) => {
  // Let's calculate how many visible pages we want to show. We need to show up to 3 pages; the page we are on and
  // one before and one after. Then we if have more than 3 pages remaining, we show an ellipsis and then the last 3 pages.
  // And finally if the page before our current page is is greater than 1, then we also show an ellipses at the beginning.
  //
  // We should calculate the total number of pages minus the current page and if that number is greater than 4 then we know we
  // will need the second ellipses. Then we create an array of page numbers that we will show; for example if we have 10 pages
  // and we are on page 3 the "pagesToDisplay" array will be [2, 3, 4, 8, 9, 10]. If we are on page 7 then we will show [6, 7, 8, 9, 10].
  const pagesToDisplay = []
  const currentPage = tableControls.page + 1 // it starts at 0 but we want to show the current page as 1
  const pagesRemaining = pages - currentPage
  const pagesBefore = currentPage - 1

  // If we have less than 6 total pages, then all we need to do is just render all the options.
  if (pages <= 6) {
    for (let i = 0; i < pages; i++) {
      pagesToDisplay.push(i + 1)
    }
  } else {
    // Now if pagesBefore is greater than 1 then we know we need to show the first ellipses.
    if (pagesBefore > 1) {
      pagesToDisplay.push('...')
    }

    // Now if pagesRemaining is greater than 5 then we know we will need the second ellipses.
    if (pagesRemaining > 5) {
      // Now show up to 3 pages, including the current page, the one before and the one after.
      for (let i = 0; i < 3; i++) {
        if (i === 0 && currentPage > 1) {
          pagesToDisplay.push(currentPage - 1)
        } else if (i === 1) {
          pagesToDisplay.push(currentPage)
        } else if (i === 2) {
          pagesToDisplay.push(currentPage + 1)
        }
      }

      // And now the last three pages.
      pagesToDisplay.push('...')
      for (let i = pages - 2; i <= pages; i++) {
        pagesToDisplay.push(i)
      }
    } else {
      // We only have 6 or fewer pages (including current page), so we always show the last 6 pages.
      const firstPageToShow = pages - 5
      for (let i = 0; i < 6; i++) {
        pagesToDisplay.push(firstPageToShow + i)
      }
    }
  }

  pageOptions = [
    {
      icon: faChevronLeft,
      alt: 'previous',
      action: () => {
        setTableControls({ ...tableControls, page: tableControls.page - 1 })
      },
      disabled: tableControls.page === 0
    },
    ...pagesToDisplay.map((page) => {
      return {
        text: page,
        action: () => {
          // If this an ellipse, then don't do anything.
          if (page === '...') {
            return
          }
          setTableControls({ ...tableControls, page: page - 1 })
        },
        disabled: false
      }
    }),
    {
      icon: faChevronRight,
      alt: 'next',
      action: () =>
        setTableControls({ ...tableControls, page: tableControls.page + 1 }),
      disabled: tableControls.page === pages - 1
    }
  ]

  return (
    <Flex
      className="pagination"
      justifyContent="space-between"
      margin={`20px 0 0 0`}
      alignItems="center"
    >
      <Flex alignItems="center">
        {totalItems > MINIMUM_ROW_COUNT && (
          <Flex direction="column">
            <Menu
              key="itemsPerPage"
              menuAlign="start"
              trigger={
                <MenuButton
                  data-testid="ItemsPerPage"
                  className="pagination-select"
                >
                  <TranslateComponent>
                    {`Show ${tableControls.paginationCount} per page`}
                  </TranslateComponent>
                  <FontAwesomeIcon icon={faChevronDown} color="gray" />
                </MenuButton>
              }
            >
              <>
                {paginationOptions &&
                  paginationOptions.map((option, key) => (
                    <MenuItem
                      key={`Table-Pagination-Option-${option}-${key}`}
                      onClick={() => ClickPaginationCount(option)}
                    >
                      <TranslateComponent>{`Show ${option} per page`}</TranslateComponent>
                    </MenuItem>
                  ))}
              </>
            </Menu>
          </Flex>
        )}
        <Flex>
          <span className={styles.totalItems}><TranslateComponent>{`${totalItems} items`}</TranslateComponent></span>
        </Flex>
        <Flex className={styles.loadTime}>
          <LoadTime loadTime={loadTime} />
        </Flex>
      </Flex>
      <Flex>
        {totalItems > MINIMUM_ROW_COUNT && (
          <>
            {pageOptions.map((option, key) => {
              if (!option.disabled) {
                return (
                  <DenaliPaginationButton
                    key={`Table-PageOption-${key}`}
                    icon={option.icon}
                    onClick={option.action}
                    text={option.text}
                    disabled={false}
                    active={tableControls.page + 1 === option.text}
                    alt={option.alt}
                  />
                )
              }
              return (
                <DenaliPaginationButton
                  key={`Table-PageOption-${key}`}
                  icon={option.icon}
                  text={option.text}
                  disabled={true}
                />
              )
            })}
          </>
        )}
      </Flex>
    </Flex>
  )
}
