import PropTypes from "prop-types"
import { Text, View, Image } from "@react-pdf/renderer"
import { imagesStyles } from "./pdf-images-block.styles"

const { caption, image, imageBlock, imageContainer, imageBlockWithoutBorder } = imagesStyles

const PDFImage = ({ img, withBorder = true }) => img.imageUrl
	? <View style={withBorder ? imageBlock : imageBlockWithoutBorder}>
		<View style={imageContainer}>
			<Image style={image} src={img.imageUrl} />
		</View>
	</View>
	: null

PDFImage.propTypes = {
	img: PropTypes.object,
	withBorder: PropTypes.bool
}

export default PDFImage
