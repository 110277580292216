import { IBuilding } from 'src/redux/types/BuildingPickerTypes'
import { EXTRA_SMALL, TINY } from 'src/hooks/breakPoint'
import { ORGANIZATION, LOCATION, SALES_OFFICE } from './constants'
import { BuildingId, OrganizationId } from './graphql'

export const salesOfficeMapping = (data: any, includeLocations = true) => {
  const salesOffices = data?.reduce((salesOffices: any, item: IBuilding) => {
    const { id } = item
    if (!id) return salesOffices
    if (!salesOffices[id])
      salesOffices[id] = inheritProperties(SALES_OFFICE, item)
    if (includeLocations) {
      salesOffices[item.id].buildings.push({
        id: item?.id,
        primary: item?.name,
        icon: SALES_OFFICE,
        type: SALES_OFFICE,
        secondary: item?.address,
        isActive: item?.isActive || 1,
        salesOfficeId: item.id,
        level: 1
      })
    }
    return salesOffices
  }, {})
  return salesOffices
}

export const accountMapping = (data: any, includeLocations = true):AccountMappingOrganization[] => {
  const accounts = data?.reduce((accounts: any, item: IBuilding) => {
    const { accountId } = item
    if (!accountId) return accounts
    if (!accounts[accountId])
      accounts[accountId] = inheritProperties(ORGANIZATION, item)
    if (includeLocations) {
      accounts[accountId].buildings.push(inheritProperties(LOCATION, item))
      const totalBuildings = accounts[accountId].buildings?.length
      accounts[
        accountId
      ].secondary = `Organization (${totalBuildings} buildings)`
    } else {
      accounts[accountId].secondary = `Organization`
    }
    return accounts
  }, {})
  return accounts
}
type InheritType = LOCATION | ORGANIZATION

export type AccountMappingOrganization = {
  buildings: AccountMappingBuilding[],
  defaultExpand: boolean
  icon: "organization"
  id: OrganizationId
  level: number
  primary: string
  secondary: string
  type: string
}

export type AccountMappingBuilding = {
  accountId: OrganizationId
  buildingId: BuildingId
  defaultExpand: boolean
  disabled: boolean
  icon: "location"
  id: string
  level: number
  primary: string
  secondary: string
  type: "location"
}

export const inheritProperties = (type: InheritType, item: any) => {
  const accountId = item?.accountId || item?.account?.id
  const { id, name, district = '' } = item
  switch (type) {
    case ORGANIZATION:
      return {
        primary: item?.accountName,
        icon: ORGANIZATION,
        type: ORGANIZATION,
        buildings: [],
        defaultExpand: true,
        level: 0,
        id: accountId
      }
    case LOCATION:
      const buildingId = item?.buildingId || item.id
      return {
        buildingId,
        primary: item?.name,
        icon: LOCATION,
        type: LOCATION,
        secondary: item?.address,
        disabled: item?.isActive == 0,
        accountId,
        defaultExpand: true,
        level: 1,
        id: buildingId
      }
    case SALES_OFFICE:
      return {
        id: id,
        primary: `${id}, ${name}, ${district}`,
        icon: 'salesoffice2',
        type: SALES_OFFICE,
        secondary: 'Sales Office',
        level: 0
      }
  }
}

export const itemFromHistory = (item: any, selected = false) => {
  const {
    historyType,
    account,
    id,
    salesOffice,
    building,
    buildingId,
    salesOfficeId,
    accountId,
    eTime
  } = item
  switch (historyType) {
    case LOCATION:
      return {
        primary: building?.name,
        id: buildingId,
        dbIdentifier: id,
        secondary: building?.address,
        type: LOCATION,
        icon: LOCATION,
        showHoverLabel: true,
        selected,
        accountId,
        eTime
      }
    case ORGANIZATION:
      return {
        primary: account?.name,
        secondary: 'Organization',
        id: accountId,
        dbIdentifier: id,
        type: ORGANIZATION,
        icon: ORGANIZATION,
        showHoverLabel: true,
        selected,
        eTime
      }
    case SALES_OFFICE:
      return {
        primary: salesOffice?.name,
        id: salesOfficeId,
        dbIdentifier: id,
        secondary: 'Sales Office',
        type: SALES_OFFICE,
        icon: SALES_OFFICE,
        showHoverLabel: true,
        selected,
        eTime
      }
  }
}

export const checkIfMobile = (breakpoint) =>
  Boolean(breakpoint && (breakpoint === EXTRA_SMALL || breakpoint === TINY))

export const checkIfFoundUserHistory = (items: any, selected: any) => {
  return items?.find((item: any) => {
    if (selected.type === ORGANIZATION) {
      return item.id === selected.id
    } else if (selected.type === LOCATION) {
      return item.id === selected.id
    } else if (selected.type === SALES_OFFICE) {
      return item.id === selected.id
    }
  })
}

export const getFormatVariables = (id, type) => {
  const filters = []
  if (type === ORGANIZATION) {
    filters.push({ accountId: { eq: id } })
  } else {
    filters.push({ id: { eq: id } })
  }
  const variables: any = {
    filter: {
      or: {}
    },
    limit: 10
  }
  variables.filter.or = filters
  return variables
}
