import { createContext, useContext, useState } from 'react'
import { IModalActionType } from 'src/constants'
const LoadAnalysisContext = createContext<any>(null)

export const LoadAnalysisContextProvider = ({ children }: any) => {
  const [mode, setMode] = useState(null)
  const [reloadEvents, setReloadEvents] = useState(false)
  const [isBaseLineAdding, setIsBaseLineAdding] = useState(false)
  const [modalType, setModalType] = useState<IModalActionType>('')

  return (
    <LoadAnalysisContext.Provider
      value={{
        mode,
        setMode,
        reloadEvents,
        setReloadEvents,
        isBaseLineAdding,
        setIsBaseLineAdding,
        setModalType,
        modalType,
      }}
    >
      {children}
    </LoadAnalysisContext.Provider>
  )
}

export const useLoadAnalysisContext = () => useContext(LoadAnalysisContext)
