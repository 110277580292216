import { useMemo, useRef, useState } from 'react'
import { useOptions } from './useOptions'
import Axes from './Axes'
import BaseWrapper from '../../../../../../chart-library/CommonComponents/BaseWrapper'
import Series from './Series'
import Svg from '../../../../../../chart-library/CommonComponents/Svg'
import ToolTip from '../../../../../../chart-library/CommonComponents/Bar/Tooltip/Tooltip'
import { Tooltip } from '../../../../../../chart-library/CommonComponents/Bar/constants'
import "./styles.scss";

export default function EnergyConsumptionChart(props) {

  // API flow - 1
  // Once Data is recevied from API. Pass this to this memo, which returns series data required for group bar
  const series = useMemo(
    () => props?.data ?? [],
    [props?.data,props?.data?.groupBarData,props?.data?.areaData,props?.data?.barData]
  )

  const options = useOptions(series)

  const [tooltip, setTooltip] = useState<Tooltip | null>(null);
  const svgRef: any = useRef(null)

  return (
    <BaseWrapper options={options} className={`${series?.groupBarData?.length > 6 || series?.barData?.length > 6 ? "group-bar-chart-dimention-axis-text" : "group-bar-chart-dimention"}`}>
      <Svg ref={svgRef}>
        {(series?.groupBarData?.length || series?.barData?.length) && <>
          <Axes data={series}/>
          <Series options={options} series={series} setTooltip={setTooltip} />
        </>}
      </Svg>
      {tooltip !== null ? <ToolTip isGroupBar={series?.groupBarData && series?.groupBarData?.length > 0} tooltip={tooltip} /> : null}
    </BaseWrapper>
  )
}
