import { useFormikContext } from '../MultiTabTorm'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { TrendBarWidgetPreview } from 'src/pages/dashboards/components/DashboardCardWidget/TrendBarWidgetPreview'
import { WidgetPreviewWrapper } from './../widgetPreviewWrapper'
import { WidgetChartContainer } from 'src/components/widgetChartComponents/widgetChartCommonComponents'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const TrendsBarWidgetContainer = styled.div`
  height: 280px;
`

export const TrendsBarPreviewWrapper = React.memo(() => {
  const formik = useFormikContext()
  const { values } = formik
  const { objectPickerSelectedValue, formikErrors } = useWidgetsContext()

  const getEquipmentList = () => {
    return (
      objectPickerSelectedValue &&
      objectPickerSelectedValue?.map((selectedValue) => {
        return {
          buildingName: selectedValue?.buildingName,
          equipmentName: selectedValue?.equipmentName,
          accountName: selectedValue?.accountName
        }
      })
    )
  }

  const getPreviewDetails = useMemo(
    () => ({
      widgetName: values?.name,
      widgetDescription: values?.description ?? '',
      buildingId: values?.widgetBuilding,
      equipmentList: getEquipmentList(),
      equipmentId: objectPickerSelectedValue?.[0]?.equipmentId,
      property: values?.property,
      symbol: values?.symbol,
      selectedTimePeriod: values?.timePeriod?.split('/')?.[1] || '',
      equipmentType: objectPickerSelectedValue?.[0].equipmentType,
      unitsOfMeasure: values?.unitsOfMeasure,
      selectedAggrValue: values?.value?.split('/')?.[1] || '',
      currentLineColor: values.lineColor,
      previousPeriodLineColor: values.overlayLineColor,
      buildingName: objectPickerSelectedValue?.[0].buildingName,
      equipmentName: objectPickerSelectedValue?.[0].equipmentName,
      barColor: values?.barColor,
      benchMarkTargetLabel: values?.benchMarkTargetLabel,
      markerConstantValue: values?.markerConstantValue,
      benchmarkColor: values?.benchmarkColor,
      timeZone: objectPickerSelectedValue?.[0]?.timeZone,
      targetMarker: values?.targetMarker?.split('/')[1] || '',
      timeInterval: values?.timeInterval?.split('/')?.[1] || ''
    }),
    [values]
  )

  return (
    <WidgetPreviewWrapper
      widgetName={values?.name}
      error={Object.keys(formikErrors)?.length > 0}
      chartType="trend-bar-chart"
      isWidth={true}
    >
      <WidgetChartContainer>
        <TrendsBarWidgetContainer>
          <TrendBarWidgetPreview widgetDetails={getPreviewDetails} />
        </TrendsBarWidgetContainer>
      </WidgetChartContainer>
    </WidgetPreviewWrapper>
  )
})
