import { createSlice } from '@reduxjs/toolkit'
import { BuildingPickerTypes } from '../types'
import { TStore } from '../store'
import { API } from 'aws-amplify'
import { useQuery } from 'src/hooks/APIHooks'
import {
  SEARCH_BUILDINGS,
} from 'src/components/buildingNavigation/graphql'
import { accountMapping } from 'src/components/buildingNavigation/helper'
import { getPropByPath } from 'src/utils'

const initialState: BuildingPickerTypes = {
  open: false,
  searchText: '',
  buildings: {
    loading: false,
    data: [],
    error: false
  },
  buildingsCount: null,
  recentSearchItems: {
    loading: false,
    data: [],
    error: false
  },
  baseBuildingData: {
    loading: false,
    data: [],
    error: false
  },
  selectedbuildingOfferings: [],
  selectedBuilding: null,
  recentUserHistoryFetched: false,
  pageReduxObject: {},
  selctedAtHistory: "",
  automatedSettingEquipment : {},
  rateStructureSaleOfficeId: null,
  selectedOrganization: null
}

export const buildingPickerData = createSlice({
  name: 'buildingPicker',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload
    },
    setRecentUserHistoryFetched: (state, action) => {
      state.recentUserHistoryFetched = action.payload
    },
    // Can be used to set page level redux variables, use this to store all page level objects in different pages
    // As of now, going to store equipment id and chart id for energy pages and Building data chart respectively
    setPageReduxObject: (state, action) => {
      state.pageReduxObject = action.payload
    },
    // Used in rate structure library pages to load the sales office in builidng picker
    setRateStructureSaleOfficeId : (state, action) => {
      state.rateStructureSaleOfficeId = action?.payload?.salesOfficeId || null
    },
    setBuildings: (state, action) => {
      const { type = 'Sucesss', data = [] } = action.payload
      switch (type) {
        case 'Loading':
          state.buildings = {
            loading: true,
            data: [],
            error: false
          }
          break
        case 'Success':
          state.buildings = {
            loading: false,
            data,
            error: false
          }
          break
        case 'Failure':
          state.buildings = {
            loading: false,
            data,
            error: true
          }
          break
      }
    },
    setBuildingsCount : (state, action) => {
      state.buildingsCount = action?.payload?.buildingsCount || null
    },
    setBaseBuildingData: (state, action) => {
      const { type = 'Sucesss', data = [] } = action.payload
      switch (type) {
        case 'Loading':
          state.baseBuildingData = {
            loading: true,
            data: [],
            error: false
          }
          break
        case 'Success':
          state.baseBuildingData = {
            loading: false,
            data,
            error: false
          }
          break
        case 'Failure':
          state.baseBuildingData = {
            loading: false,
            data: [],
            error: true
          }
          break
      }
    },
    setRecentSearchedItems: (state, action) => {
      const { type = 'Sucesss', data = [] } = action.payload
      switch (type) {
        case 'Loading':
          state.recentSearchItems = {
            loading: true,
            data: [],
            error: false
          }
          break
        case 'Success':
          state.recentSearchItems = {
            loading: false,
            data,
            error: false
          }
          break
        case 'Failure':
          state.recentSearchItems = {
            loading: false,
            data: [],
            error: true
          }
          break
      }
    },
    setSelectedbuildingOfferings : (state, action) => {
      state.selectedbuildingOfferings = action.payload
    },
    setSelectedBuilding: (state, action) => {
      state.selectedBuilding = action.payload
    },
    setAutomatedTestHistory: (state, action) => {
      state.selctedAtHistory = action.payload
    },
    setAutomatedSettingEquipment: (state, action) => {
      state.automatedSettingEquipment = action.payload
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload
    }
  }
})

export const refetchBuildingOrganizationResults = (search='') => async (dispatch) => {
  try {
    const apiData = await API.graphql({
      query: SEARCH_BUILDINGS,
      variables: {
        filter: {
          or: [
            { accountName: { matchPhrasePrefix: `*${search}*` } },
            { name: { matchPhrasePrefix: `*${search}*` } },
            { address: { matchPhrasePrefix: `*${search}*` } },
          ]
        },
        limit: 1000
      }
    });

    const pathData = getPropByPath(apiData, 'data.searchBuildings.items', null);
    const accountsData = accountMapping(pathData, true);
    const finalData = Object.values(accountsData);
    dispatch(
      setBuildings({
        type: 'Success',
        data: finalData
      })
    );
  }catch(error) {
    console.error(error);
  }
}

export const {
  setOpen,
  setSearchText,
  setBuildings,
  setBuildingsCount,
  setBaseBuildingData,
  setRecentSearchedItems,
  setSelectedBuilding,
  setRecentUserHistoryFetched,
  setRateStructureSaleOfficeId,
  setPageReduxObject,
  setAutomatedTestHistory,
  setAutomatedSettingEquipment,
  setSelectedOrganization,
  setSelectedbuildingOfferings
} = buildingPickerData.actions

export const selectOpen = (state: TStore) => state.buildingPicker.open
export const selectSearchText = (state: TStore) =>
  state.buildingPicker.searchText

export const selectBuildings = (state: TStore) => state.buildingPicker.buildings
export const selectBuildingsCount = (state: TStore) => state.buildingPicker.buildingsCount
export const selectBuildingBaseData = (state: TStore) => state.buildingPicker.baseBuildingData
export const selectUserHistory = (state: TStore) =>
  state.buildingPicker.recentSearchItems
export const selectBuilding = (state: TStore) =>
  state.buildingPicker.selectedBuilding
export const selectRecentUserHistoryFetched = (state: TStore) =>
  state.buildingPicker.recentUserHistoryFetched
export const selectPageReduxObject = (state: TStore) =>
  state.buildingPicker.pageReduxObject
export const selectRateStructureSalesOfficeId = (state: TStore) => 
  state.buildingPicker.rateStructureSaleOfficeId
export const selectSelectedBuildingOffering = (state: TStore) => 
state.buildingPicker.selectedbuildingOfferings
 
