import styled from 'styled-components'

export const AutomatedTestesContainer = styled.div`
  label {
    width: 65px;
    height: 18px;
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.21px;
    line-height: 18px;
  }
  a {
    color: inherit;
    width: 277px;
    height: 16px;
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
    text-decoration: none;
    &:hover {
      color: #000000;
      text-decoration: underline;
    }
  }

  .rx-col-10 {
    box-sizing: border-box;
    letter-spacing: normal;
    white-space: normal;
    word-spacing: normal;
    width: 83.3333%;
  }

  .rx-col-2 {
    box-sizing: border-box;
    letter-spacing: normal;
    white-space: normal;
    word-spacing: normal;
    width: 16.6666%;
  }

  .rx-col-3 {
    box-sizing: border-box;
    letter-spacing: normal;
    white-space: normal;
    word-spacing: normal;
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
  }
`

export const AutomatedTestsSettingsEditContainer = styled.div`
  .modal-container {
    overflow: auto;
    background: #eeeeee;
    box-shadow: 3px 4px 6px 0px #0000001a;
    border-radius: 6px;

    .modal-content {
      padding: 10px 20px 20px 20px;
    }

    .edit-view {
      padding: 0px;
    }

    .modal-header {
      border-bottom: 1px solid #cccccc;
    }

    .modal-button-row {
      border-top: 1px solid #cccccc;
    }
  }

  thead tr th {
    background: #467e26;
    color: #ffffff;
  }

  .checkbox-container {
    height: 20px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.36px;
    line-height: 14px;
    margin-bottom: 10px;

    label {
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.36px;
      line-height: 14px;
    }
  }

  .esdp-data-mapping-tab {
    .generic-table-wrapper__scroll-body {
      max-height: 370px;
    }
  }

  .edit-view--type-custom {
    width: 293px;
  }

  .property-wrapper {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .generic-table-wrapper .generic-table .table-header th .table-heading {
    display: flex;
    gap: 5px;
  }

  .modal-container .modal-button-row {
    .modal-button {
      // width: 69px;
      padding-left:20px;
      padding-right:20px;
      height: 30px;
      background: #467e26;
      border-radius: 2px;

      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.36px;
      line-height: 14px;
      text-transform: none;

      &.type-cancel {
        background: #7d7d7d;
      }
    }

    [disabled].modal-button {
      background-color: #eeeeee;
      border-color: #cccccc;
      color: #cccccc;
    }
  }

  @media (min-width: 1050px) {
    width: 1090px;
  }
`

export const InfoText = styled.label`
  display: block;
  height: 15px;
  color: #d52b1e;
  font-size: 13px;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.34px;
  line-height: 15px;
  ${(props) => props.marginBottom && 'margin-bottom: 5px'};
`
export const ChillerCircuitCountErrorText = styled(InfoText)`
  margin: 8px 0 0 19px;
`

export const CurveNotDefinedText = styled(InfoText)`
  margin-bottom: 12px;
`

export const StyledLabel = styled.label`
  height: 20px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.24px;
  line-height: 20px;
  align-self: flex-end;
  margin-top: 10px;
`
