import { useFormikContext } from '../MultiTabTorm'
import { useWidgetsContext } from '../../WidgetsContextProvider'
import { TrendLineWidgetPreview } from 'src/pages/dashboards/components/DashboardCardWidget/TrendLineWidgetPreview'
import { WidgetPreviewWrapper } from './../widgetPreviewWrapper'
import styled from 'styled-components'
import { useMemo } from 'react'

const TrendlineContainer = styled.div`
  height: 275px;
`

export const TrendLinePreviewWrapper = () => {
  const formik = useFormikContext()
  const { values } = formik
  const { objectPickerSelectedValue, formikErrors } = useWidgetsContext()

  const getEquipmentList = () => {
    return (
      objectPickerSelectedValue &&
      objectPickerSelectedValue?.map((selectedValue) => {
        return {
          buildingName: selectedValue?.buildingName,
          equipmentName: selectedValue?.equipmentName,
          accountName: selectedValue?.accountName
        }
      })
    )
  }

  const getPreviewDetails = useMemo(
    () => ({
      buildingId: values?.widgetBuilding,
      equipmentId: objectPickerSelectedValue?.[0]?.equipmentId,
      equipmentList: getEquipmentList(),
      property: values?.property,
      comparePointTo: values?.comparePointTo,
      selectedTimePeriod: values?.timePeriod,
      equipmentType: objectPickerSelectedValue?.[0].equipmentType,
      unitsOfMeasure: values?.unitsOfMeasure,
      symbol: values?.symbol || '',
      selectedAggrValue: values?.value,
      currentLineColor: values.lineColor,
      previousPeriodLineColor: values.overlayLineColor,
      buildingName: objectPickerSelectedValue?.[0]?.buildingName,
      equipmentName: objectPickerSelectedValue?.[0]?.equipmentName,
      timeZone: objectPickerSelectedValue?.[0]?.timeZone,
      widgetDescription: values?.description ?? ''
    }),
    [values, objectPickerSelectedValue]
  )

  return (
    <WidgetPreviewWrapper
      widgetName={values?.name}
      error={Object.keys(formikErrors)?.length > 0}
      chartType="trend-line-chart"
    >
      <TrendlineContainer>
        <TrendLineWidgetPreview widgetDetails={getPreviewDetails} />
      </TrendlineContainer>
    </WidgetPreviewWrapper>
  )
}
