import { KPI_LIST } from './opportunity'
import isArray from 'lodash/isArray'
import _get from 'lodash/get'

export const OPPORTUNITIES_LIMIT = 5
export const MAX_LENGTH_OF_HEADING = 255
export const MAX_LENGTH_OF_SUMMARY = 5000
export const MAX_LENGTH_OF_BULLET = 140
export const MAX_LENGTH_OF_HIGHLIGHT = 30
export const MAX_SIZE_OF_NUMBERS = 10000000
export const MAX_LENGTH_OF_IMPACT_AMOUNT = 11
export const MAX_LENGTH_OF_NUMBERS = 9
export const MAX_LENGTH_OF_NAME = 200
export const MAX_PAGE_WIDTH = 1220


export const DEFAULT_SORT = {
  field: 'none',
  ascending: true
}

export const HIGHLIGHT = {
  name: '',
  topImpactAmount: '',
  currency: 'usd' /* default value */,
  value: {
    blocks: [],
    entityMap: {}
  },
  icon: 'comfort-impact'
}

export const FILTER_BY_END_DATE = [
  { key: 'last-week', value: 'Ended in the last week' },
  { key: 'last-month', value: 'Ended in the last month' },
  { key: 'last-3-months', value: 'Ended in the last 3 months' },
  { key: 'last-6-months', value: 'Ended in the last 6 months' },
  { key: 'last-year', value: 'Ended in the last year' }
]

const STATUS = new Map([
  ['inProgress', 'In Progress'],
  ['collectingData', 'Collecting Data'],
  ['readyForReview', 'Ready for Review'],
  ['archive', 'Archive']
])

export const STATUS_SORT_ORDER = new Map([
  ['inProgress', 0],
  ['collectingData', 1],
  ['readyForReview', 2],
  ['archive', 3]
])

export function formatStatus(id, translateFunc) {
  return translateFunc ? translateFunc(`components:statuses>${id}`) : id
}

export const STATUS_OPTION_LIST = Array.from(STATUS, ([key, value]) => ({
  key,
  value
}))

export function getStatusOptionList(t) {
  if (!t) {
    return STATUS_OPTION_LIST
  }

  return Array.from(STATUS.keys(), (key) => ({
    key,
    value: t(`components:statuses>${key}`) || key
  }))
}

export const TIME_PERIOD_OPTION_LIST = [
  {
    key: 'one-time',
    value: 'consultations:ValueAssumption:TimePeriod>OneTime'
  },
  {
    key: 'per-month',
    value: 'consultations:ValueAssumption:TimePeriod>PerMonth'
  },
  { key: 'per-year', value: 'consultations:ValueAssumption:TimePeriod>PerYear' }
]

export const TIME_PERIOD_OPTION_LIST_FOR_KPI = [
  {
    key: 'one-time',
    value: 'consultations:ValueAssumption:TimePeriod>OneTime'
  },
  { key: 'per-year', value: 'consultations:ValueAssumption:TimePeriod>PerYear' }
]

export const TIME_PERIOD_OPTION_LIST_FOR_KPI_DROP_DOWN = [
  {
    key: 'one-time',
    value: 'one time'
  },
  { key: 'per-year', value: 'per year' }
]

const VALUE_ASSUMPTION_KPI_IMPACTS = {
  low: {
    value: undefined,
    timePeriod: 'one-time'
  },
  medium: {
    value: undefined,
    timePeriod: 'one-time'
  },
  high: {
    value: undefined,
    timePeriod: 'one-time'
  }
}

const KPI_ORDER = new Map([
  ['energyUsage', 0],
  ['performance', 1],
  ['reliability', 2],
  ['comfort', 3],
  ['compliance', 4],
  ['', 5]
])

export const VALUE_ASSUMPTION_KPI = KPI_LIST.map(({ name, custom }) => ({
  name,
  custom,
  ...VALUE_ASSUMPTION_KPI_IMPACTS
})).sort((a, b) => KPI_ORDER.get(a.name) - KPI_ORDER.get(b.name))

export const VALUE_ASSUMPTION_DEFAULT = {
  lastEdited: '',
  lastEditedBy: '',
  avoidedTruckRolls: 500,
  kpi: VALUE_ASSUMPTION_KPI.map(({ name, low, medium, high, custom }) => {
    const isEnergyUsage = name === 'energyUsage'

    return {
      name,
      low: {
        value: isEnergyUsage ? 100 : 500,
        timePeriod: isEnergyUsage ? 'per-year' : low.timePeriod
      },
      medium: {
        value: isEnergyUsage ? 300 : 1000,
        timePeriod: isEnergyUsage ? 'per-year' : medium.timePeriod
      },
      high: {
        value: isEnergyUsage ? 500 : 1500,
        timePeriod: isEnergyUsage ? 'per-year' : high.timePeriod
      },
      custom
    }
  }),
  savingsGoal: 10000
}

export function customMergeMethod(objValue, srcValue) {
  if (isArray(objValue) && isArray(srcValue)) {
    return srcValue
  }
}

export function filterByDateOptionList(translateFunc) {
  return FILTER_BY_END_DATE.map(({ key, value }) => ({
    key,
    value: translateFunc
      ? translateFunc(`components:filterByEndDate>${key}`)
      : value
  }))
}

export function formatOrganizationOrBuildings(consultation) {
  const organizationName = _get(
    consultation,
    'account.name',
    ''
  )
  const locationName = (_get(consultation, 'locations', []) || []).map(
    (l) => l.locationName
  )
  return `${organizationName}, ${locationName.join(', ')}`
}

export const TEXT_EDITOR_TOOLBAR_OPTIONS = {
  options: ['blockType', 'inline', 'textAlign', 'list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline', 'superscript', 'subscript']
  },
  link: {
    options: ['link']
  },
  list: {
    options: ['unordered', 'ordered', 'indent', 'outdent']
  },
  textAlign: {
    options: ['left', 'center', 'right']
  }
}
