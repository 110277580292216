import { useCallback, useEffect, useMemo } from 'react'
import { useTooltipContext } from './TooltipProvider'

import { Point, BoxPoint } from '../../algorithm/RBT'
import { TooltipDataModel, createTooltipDataModel } from './TooltipTemplate'

export function useSyncSeriesPointsForTooltip<
  KEY = number,
  VALUE = TooltipDataModel
>(isExtent = false) {
  const tInstance = useTooltipContext<KEY, VALUE>()

  const tooltip = useMemo(() => tInstance.init(isExtent), [])

  const addPoints = useCallback(
    (points: Point<KEY, VALUE>[]) => tooltip.insertAll(points),
    [tooltip]
  )
  const deletePoints = useCallback(
    (points: Point<KEY, VALUE>[]) => tooltip.removeAll(points),
    [tooltip]
  )
  const clearAllPoints = useCallback(() => tooltip.clear(), [tooltip])

  const showSeriesPointsInTooltip = useCallback(() => tooltip.show(), [tooltip])
  const hideSeriesPointsInTooltip = useCallback(() => tooltip.hide(), [tooltip])
  const createTooltipPointModel = useCallback(
    (key, data: VALUE, box: any): BoxPoint<KEY, VALUE> | Point<KEY, VALUE> => ({
      key,
      value: createTooltipDataModel<VALUE>(data),
      box
    }),
    []
  )

  const searchPointsOnMouseIn = useCallback(
    (event: any, positionX: any) => tInstance.mouseIn(event, positionX),
    [tooltip]
  )
  const hidePointsOnMouseOut = useCallback(
    () => tInstance.mouseOut(),
    [tooltip]
  )

  // clean up the current series data while unmount
  useEffect(() => () => tooltip.destroy(), [])

  return useMemo(
    () => ({
      addPoints,
      deletePoints,
      clearAllPoints,
      showSeriesPointsInTooltip,
      hideSeriesPointsInTooltip,
      createTooltipPointModel,
      searchPointsOnMouseIn,
      hidePointsOnMouseOut
    }),
    [tooltip]
  )
}
